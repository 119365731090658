import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import { addCommas } from '../../../../../module';
import useKeycloak from '../../../../../hooks/keycloak.module';

const akContext = createContext({
    akState: {}, akAction: {}
});

export function AKProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const { doGet, doPost, wait } = useFetch();
    const location = useLocation();

    const [err, setErr] = useState(true);
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [formData, setFormData] = useState({
        "nominal": 0,
        "nomor_referensi": "",
        "keterangan": "",
        "kode_valuta": "IDR",
        "info_transaksi": {
            "saldo_teller_before": 0,
            "saldo_teller_after": 0
        },
        "denominasi": [
           
        ]
    })


    const { keycloak } = useKeycloak()
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams()
    const [otorDesc, setOtorDesc] = useState("")

    async function getPenerima(id_user = null, kode_cabang = null ) {
        const userInfo = await keycloak.loadUserInfo();
        if(id_user === null ) id_user = userInfo.preferred_username;
        if(kode_cabang === null ) kode_cabang = userInfo.branch_code;
        // // console.log(userInfo)
        openLoading()
        var url = `/kasteller/get-data-info-teller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    id_user,
                    kode_cabang
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }

    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            getDataDetail()
            // getDataPerorangan(location.state?.dbId);
        } else {
            getDenom();
            getSaldo();
        }
        // } 
        setFormEditable(paramUrl.mode !== "otorisasi");
    }, [paramUrl.mode])

    // useEffect(function(){
    //     let saldo = parseInt(formData.info_transaksi?.saldo_teller_before || 0) + parseInt(formData?.nominal || 0) || 0;
    //     changeForm(saldo, "saldo_teller_after", "info_transaksi")
    // },[formData])

    function sortingDenom(dataDenom = [], namaDenom) {
        return dataDenom.filter(({ jenis = "" }) => (
            jenis.toUpperCase() === namaDenom.toUpperCase()
        )).sort(function (a, b) {
            return b.nilai_denominasi - a.nilai_denominasi;
        })
    }

    async function getDenom() {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/brangkas`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userInfo.preferred_username,
                    kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    const denominasi = ( [ 
                        ...( sortingDenom(data.InfoDenominasiBrangkas || [], "lembar") || []),
                        
                        ...( sortingDenom(data.InfoDenominasiBrangkas || [], "koin") || []),
                        
                        ...( sortingDenom(data.InfoDenominasiBrangkas || [], "lembar (utle)") || []),
                    ] ).map(({
                        nilai_denominasi = 0, jumlah_lembar_koin = 0, jenis = ""
                    }) => ({
                        nilai_denominasi,
                        jenis,
                        jumlah_lembar_koin: 0
                    }))

                    setFormData(p => ({
                        ...p,
                        denominasi
                    }))
                    // // // // console.log(data)
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    // setFormData(prevState => ({
                    //     ...prevState,
                    //     info_transaksi: {
                    //         saldo_teller_after: parseInt(data.saldo),
                    //         saldo_teller_before: parseInt(data.saldo)
                    //     }
                    // }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }

    async function getSaldo() {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/kasteller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userInfo.preferred_username,
                    kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    setFormData(prevState => ({
                        ...prevState,
                        info_transaksi: {
                            // saldo_teller_after: parseFloat(data?.saldo|| "0").toFixed(2),
                            // saldo_teller_before: parseFloat(data.saldo|| "0").toFixed(2)
                            saldo_teller_after: parseFloat(data.saldo).toFixed(2),
                            saldo_teller_before: parseFloat(data.saldo).toFixed(2)
                        }
                    }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }
    function changeJumlahLembar(jumlah_lembar_koin, key) {

        if (isNaN(jumlah_lembar_koin)) return false;
        let nominal = 0;
        const de = formData.denominasi
        const denominasi = de.map((d, i) => {

            if (key === i) {
                nominal += (parseInt(jumlah_lembar_koin) * d.nilai_denominasi)
                return {
                    ...d,
                    jumlah_lembar_koin,
                }

            } else {
                nominal += (d.jumlah_lembar_koin * d.nilai_denominasi)
                return d
            }
        });

        if (isNaN(nominal)) {
            nominal = 0
        }
        let saldo_teller_after = parseFloat(formData.info_transaksi.saldo_teller_before) + parseFloat(nominal);
        // // console.log(parseFloat(formData.info_transaksi.saldo_teller_before).toFixed(2),  parseFloat(nominal).toFixed(2), saldo_teller_after)
        if (nominal > 0) setErr(false)
        else {
            setErr(true)
            // setDataAlert({ show: true, body: `Jumlah saldo ${addCommas(parseFloat(formData.info_transaksi.saldo_teller_before).toFixed(2))}, Jumlah amnil kas tidak bisa kurang dari sama dengan 0`, title: `Setoran masih 0`, header: "Info", callBack: () => null });
        }
        setFormData(prevState => ({
            ...prevState,
            info_transaksi: {
                ...prevState.info_transaksi,
                saldo_teller_after
            },
            nominal,
            denominasi
        }));
    }

    // post ambil kas
    async function doTrx() {
        
    closeConfirm();
        // // // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: { ... formData, uid : keycloak.uid},
                url: "/ambilkhasanah", // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: remark, title: ``, header: "Success", callBack: () => {
                        
                        keycloak.updateUID()
                        closeConfirm();
                        resetForm();
                        getSaldo();
                    }
                });
                else if(status === "05"){ 
                    closeConfirm();
                    resetForm();
                    getSaldo();
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function resetForm() {
        setFormData({
            "nomor_rekening": "",
            "nominal": 0,
            "nomor_referensi": "",
            "keterangan": "",
            "kode_valuta": "IDR",
            "info_transaksi": {
                "saldo_teller_before": 0,
                "saldo_teller_after": 0
            },
            "denominasi": [
               
            ]
        })
        getDenom();
    }

    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            // fieldRequired = ["nomorCIF", "nama_produk", "nama_rekening"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    async function getDataDetail(dbId = location.state?.dbId) {

        let url = "/rekening/local";
        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/ambilkhasanah/${dbId}`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    // // // console.log(data)
                    setFormData({
                        "nominal": parseInt(data.nominal),
                        "nomor_referensi": data.nomor_referensi,
                        "keterangan": data.keterangan,
                        "kode_valuta": data.kode_valuta,
                        "info_transaksi": {
                            "saldo_teller_before": parseFloat(data.info_transaksi?.saldo_teller_before).toFixed(2),
                            "saldo_teller_after": parseFloat(data.info_transaksi?.saldo_teller_after).toFixed(2)
                        },
                        "denominasi": data.denominasi,
                        "nomor_rekening": data.nomor_rekening
                    });
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
        // // // // console.log(data)
    }

    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        value = value.toUpperCase()
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    function confirmTrx() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }


    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); setDataAlert({ show: true, body: remark, title: '', header: "Info", callBack: () => {
                        resetForm();
                        closeLoading();
                        closeAlert();
                        closeConfirmCustom();
                        closeConfirm();
                        history.goBack()
                    } });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }



    return (
        <akContext.Provider value={{
            akState: {
                formData,
                formEditable,
                err,
                otorDesc
            },
            akAction: {
                changeForm,
                changeJumlahLembar,
                confirmTrx,
                responseOtor,
                setOtorDesc

            }
        }}>
            {children}
        </akContext.Provider>
    )
}

export default function useAmbilKas() { return useContext(akContext) }