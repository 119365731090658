import React from 'react';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistration } from '../../../../../controls';
import useDeposito from '../useDeposito';
import Select from 'react-select';
import moment from 'moment';
import { addCommas, terbilang } from '../../../../../../module';

const DetilBilyetDeposito = () => {
    const { rekState, rekAction } = useDeposito();
    const { rekSelected = {}, formEditable = false } = rekState;

    return ( 
        <>
            <FormRegistration
                itemsForm={[
                    // Data Bilyet Deposito
                    {
                        // name: "Data Nasabah",
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-2">
                                                <label>Nomor Rekening Deposito</label>
                                                {/* <span className="badge badge-secondary">
                                                    Wajib
                                                </span> */}
                                                <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        type="text"
                                                        // required
                                                        className="form-control form-control-sm"
                                                        placeholder="Otomatis terisi dari sistem"
                                                        readOnly
                                                        value={rekSelected?.nomor_rekening}
                                                    />
                                                    {/* <div className="input-group-append col-3 pl-2 pr-0">
                                                        <button className="btn btn-outline-danger">
                                                            Cari Nasabah . . . 
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: 'Nomor Bilyet',
                                type: 'number',
                                placeholder: 'Nomor Bilyet',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.nomor_bilyet,
                                // onChange: (value) => fdAction.changeForm(value, "namaNasabah"),
                                disabled: !rekState.formEditable || rekState.isOtor || rekState.rekSelected?.is_boleh_cetak_bilyet === "T",
                               
                            },
                            {
                                rows: 1,
                                label: 'Nama Rekening',
                                type: 'text',
                                placeholder: 'Masukan Rekening',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.nama_lengkap,
                                // onChange: (value) => fdAction.changeForm(value, "namaNasabah"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Tanggal Jatuh Tempo',
                                type: 'text',
                                placeholder: 'Masukan Tanggal Jatuh Tempo',
                                disabled: true,
                                required: false,
                                value: rekState.rekSelected?.tanggal_jatuh_tempo_berikutnya,
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Informasi Produk',
                                type: 'text',
                                placeholder: 'Informasi Produk',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.nama_produk,
                                // onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Alamat',
                                type: 'text',
                                placeholder: 'Masukan alamat',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.alamat,
                                // onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Tanggal Buka Deposito',
                                type: 'text',
                                placeholder: 'Masukan Tanggal Buka Deposito',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.tanggal_buka,
                                // onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Terbilang',
                                type: 'textarea',
                                placeholder: 'Masukan',
                                required: false,
                                readOnly:true,
                                value: terbilang(rekState.rekSelected?.saldo_deposito || 0)+" RUPIAH",
                                // onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Nama Rekening Deposito',
                                type: 'text',
                                placeholder: 'Masukan Nomor Rekening Deposito',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.nama_rekening,
                                // onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Tanggal Cetak Bilyet',
                                type: 'text',
                                placeholder: 'Tanggal Hari ini',
                                required: false,
                                readOnly:true,
                                value: moment(Date.now()).format("YYYY/MM/DD"),
                                // onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Informasi Cabang',
                                type: 'text',
                                placeholder: 'Masukan Informasi Cabang',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.nama_cabang,
                                // onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col-4">
                                        {
                                            createForm({
                                                label: 'Jangka Waktu',
                                                type: 'text',
                                                placeholder: 'Masukan',
                                                value: rekState.rekSelected?.masa_perjanjian,
                                                required: false,
                                                disabled: true
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: <>&nbsp;</>,
                                                type: 'text',
                                                placeholder: 'Masukan',
                                                value: rekState.rekSelected?.periode_perjanjian,
                                                disabled: true,
                                                required: false
                                            })
                                        }
                                    </div>
                                </div>)
                            },
                            {
                                rows: 2,
                                label: 'Nominal',
                                type: 'text',
                                placeholder: 'Masukan Nominal',
                                required: false,
                                readOnly:true,
                                value: addCommas( parseFloat( rekState.rekSelected?.saldo_deposito || 0).toFixed(2)),
                                // onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Nisbah',
                                                type: 'number',
                                                required: false,
                                                placeholder: '%',
                                                value: rekState.rekSelected?.nisbah_bagi_hasil,
                                                // onChange: (value) => fdAction.changeForm(value, "nisbahCounter", "_informasiLain"),
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Zakat Bagi Hasil',
                                                type: 'number',
                                                required: false,
                                                placeholder: '%',
                                                readOnly:true,
                                                value: rekState.rekSelected?.persentase_zakat_bagi_hasil,
                                                // onChange: (value) => fdAction.changeForm(value, "nisbahZakat", "_informasiLain"),
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col-6">
                                        {
                                            createForm({
                                                rows: 1,
                                                label: 'Pajak Bagi Hasil',
                                                type: 'number',
                                                placeholder: '0',
                                                required: false,
                                                readOnly:true,
                                                value: rekState.rekSelected?.tarif_pajak,
                                                // onChange: (value) => fdAction.changeForm(value, "nisbahAkhir", "_informasiLain"),
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Pejabat 1',
                                                type: 'text',
                                                required: false,
                                                value: rekState.pejabat.pejabat1,
                                                //onChange: (e) => rekAction.sutPejabatUp( p => ({
                                                 //   ...p,
                                                  //  pejabat1: e
                                                //}))
                                                onChange: (value) => rekAction.sutPejabatUp(value, "pejabat1"),
                                                disabled: rekState.isOtor || rekState.rekSelected?.is_boleh_cetak_bilyet === "T"
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Pejabat 2',
                                                type: 'text',
                                                required: false,
                                                value: rekState.pejabat.pejabat2, 
                                                
                                                //onChange: (e) => rekAction.sutPejabatUp( p => ({
                                                //    ...p,
                                                 //   pejabat2: e
                                                //}))
                                                onChange: (value) => rekAction.sutPejabatUp(value, "pejabat2"),
                                                disabled: rekState.isOtor || rekState.rekSelected?.is_boleh_cetak_bilyet === "T"
                                                // disabled: !rek
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            // {
                            //     rows: 2,
                            //     label: 'Jenis Printer',
                            //     type: 'select',
                            //     placeholder: 'Pilih Jenis Printer',
                            //     // options: rekState.disposisiPokok,
                            //     display: "nama_produk",
                            //     key:"kode_produk",
                            //     required: false,
                            //     // value: rekState.rekSelected?.kode_produk,
                            //     // onChange: (value) => fdAction.changeForm(value, "instruksi_bagi_hasil","_rekeningDeposito"),
                            //     // disabled: !rekState.formEditable
                            // },
                    ]},
                    //End Bilyet Deposito
                ]}
            />
        </>
     );
}
 
export default DetilBilyetDeposito;