import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { dasboardData } from '../../../../../../appConfig';
import { addCommas } from '../../../../../../module';
import { createForm } from '../../../../../../module/createFormList';
import { TableList } from '../../../../../controls';
import useRekening from '../useRekening';


const ModalTutupAccount = () => {
    const { rekState, rekAction } = useRekening();

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalTutupAccount} onHide={rekAction.closeTutupAccount} >
                <Modal.Header closeButton>
                    <Modal.Title>Tutup Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box mb-3">
                        <div className="row">
                            <div className="col">
                                <h4>{rekState.loadingAktDor ? <Skeleton width={"50%"} height={30} /> : <>{rekState.dataTutupAccount?.nama_rekening}</>}</h4>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Produk </div>
                                            <div className="col-8">{rekState.loadingAktDor ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataTutupAccount?.produk_rekening : rekState.dataTutupAccount?.nama_produk}`}</div>
                                        </div><div className="row">
                                            <div className="col-4"> No. Rek </div>
                                            <div className="col-8">{rekState.loadingAktDor ? <Skeleton height={20} /> : `: ${rekState.dataTutupAccount?.nomor_rekening}`} </div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Cabang </div>
                                            <div className="col-8">{rekState.loadingAktDor ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataTutupAccount?.cabang_rekening : rekState.dataTutupAccount?.cabang}`}</div>
                                        </div><div className="row">
                                            <div className="col-4">Kode Valuta </div>
                                            <div className="col-8">{rekState.loadingAktDor ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataTutupAccount?.valuta_rekening : rekState.dataTutupAccount?.valuta}`}</div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Saldo Rek </div>
                                            <div className="col-8">
                                                {
                                                    rekState.loadingAktDor ?
                                                        <Skeleton height={20} /> :
                                                        <> : <span className="float-right">{addCommas(rekState.dataTutupAccount?.saldo || "0")} </span></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </Modal.Body>{
                 !rekState.otorMode && <Modal.Title>
                    <div className="alert-warning tw-p-3 tw-mx-4 tw-mb-4 tw-rounded-md tw-text-16">
                        <h5>Notes :</h5>
                        {
                            (rekState.dataTutupAccount?.listinfo || []).map((v, i)=>(
                                <div key={i}>
                                    {v}
                                </div>
                            ))
                        }
                        {/* {rekState.loadingAktDor ? <Skeleton width={"50%"} height={90} /> : `${rekState.otorMode ? rekState.dataTutupAccount?.listinfo :}`} */}
                    </div>
                </Modal.Title>}
                <Modal.Footer>
                <div className="row">
                    <div className="col">
                        <button
                            onClick={() => rekAction.closeTutupAccount()}
                            className="btn btn-outline-success float-right">
                            Batal
                        </button>
                        {
                                !rekState.otorMode ? <>
                                  {
                                      rekState.dataTutupAccount?.listinfo.length === 0 &&
                                      <button
                                          onClick={() => rekAction.confirmTutupAccount()}
                                          className="btn btn-danger mr-2">
                                          Tutup Account
                                      </button>
                                  }
                                </> : <>

                                    <button
                                        onClick={() => rekAction.responseOtor("APPROVED")}
                                        className="btn btn-success mr-2">
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => rekAction.responseOtor("REJECTED")}
                                        className="btn btn-danger mr-2">
                                        Reject
                                    </button>
                                </>

                            }
                    </div>
                </div>
                </Modal.Footer>
            </Modal>
            {/* end modal utama */}
        </>
    );
}

export default ModalTutupAccount;