import React, { useEffect } from 'react';
// import { useKeycloak } from '@react-keycloak/web';
import Skeleton from 'react-loading-skeleton';
import { DoughnatChart, TableList } from '../../controls';
import useDashboard, { DashProvider } from './useDashboard';
import { addCommas } from '../../../module';
import moment from 'moment';

const CifDashboard = () => {
    const { dbState, dbAction } = useDashboard()
    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 5
            }}
        />
    );

    useEffect(function () {
        dbAction.getDataCard()
        dbAction.getChartCif()
        dbAction.getTasedo()
    }, [])


    useEffect(function () {
        dbAction.getDataList()
    }, [dbState.dashshow])



    const collumn = {
        1: [
            {
                name: 'No',
                field: '#',
                maxWidth: '50px',
            },
            {
                name: 'Tgl Buka',
                field: 'tanggal_buka',
                maxWidth: '200px',
            },
            {
                name: 'Nomor Nasabah',
                field: 'nomor_nasabah',
                maxWidth: '200px',
            },
            {
                name: 'Nama Nasabah',
                field: 'nama_nasabah',
                maxWidth: '200px',
            },
            {
                name: 'Alamat',
                field: 'alamat',
                maxWidth: '400px',
            },
            {
                name: 'Jenis Nasabah',
                field: 'jenis_nasabah',
                maxWidth: '400px',
            },
            {
                name: 'Kode Cabang Input',
                field: 'kode_cabang_input',
                maxWidth: '200px',
            },
        ],
        2: [
            {
                name: 'No',
                field: '#',
                maxWidth: '50px',
            },
            {
                name: 'Nomor Nasabah',
                field: 'cif',
                maxWidth: '200px',
            },
            {
                name: 'Nama Lengkap',
                field: 'account_no',
                maxWidth: '200px',
            },
            {
                name: 'Alamat',
                field: 'ch_sms',
                maxWidth: '400px',
            },
            {
                name: 'Nomor Idetitas',
                field: 'cr_min',
                maxWidth: '200px',
            }
        ],
        3: [
            {
                name: 'No',
                field: '#',
                maxWidth: '50px',
            },
            {
                name: 'Tgl Buka',
                field: 'tanggal_buka',
                maxWidth: '200px',
            },
            {
                name: 'Nomor Nasabah',
                field: 'nomor_nasabah',
                maxWidth: '200px',
            },
            {
                name: 'Nama Nasabah',
                field: 'nama_nasabah',
                maxWidth: '200px',
            },
            {
                name: 'Alamat',
                field: 'alamat',
                maxWidth: '400px',
            },
            {
                name: 'Jenis Nasabah',
                field: 'jenis_nasabah',
                maxWidth: '200px',
            },
            {
                name: 'Kode Cabang Input',
                field: 'kode_cabang_input',
                maxWidth: '200px',
            }
        ],
        4: [
            {
                name: 'No',
                field: '#',
                maxWidth: '50px',
            },
            {
                name: 'Nomor Nasabah',
                field: 'cif',
                maxWidth: '200px',
            },
            {
                name: 'Nama Lengkap',
                field: 'account_no',
                maxWidth: '200px',
            },
            {
                name: 'Alamat',
                field: 'ch_sms',
                maxWidth: '400px',
            },
            {
                name: 'Jenis Nasabah',
                field: 'db_min',
                maxWidth: '200px',
            },
            {
                name: 'Nomor Idetitas',
                field: 'cr_min',
                maxWidth: '200px',
            }
        ],
        5: [
            {
                name: 'No',
                field: '#',
                maxWidth: '50px',
            },
            {
                name: 'Tgl Buka',
                field: 'tanggal_buka',
                maxWidth: '200px',
            },
            {
                name: 'Nomor Nasabah',
                field: 'nomor_nasabah',
                maxWidth: '200px',
            },
            {
                name: 'Nomor Rekening',
                field: 'nomor_rekening',
                maxWidth: '200px',
            },
            {
                name: 'Nama Lengkap',
                field: 'nama_nasabah',
                maxWidth: '200px',
            },
            {
                name: 'Kode Jenis',
                field: 'kode_jenis',
                maxWidth: '400px',
            },
            {
                name: 'Kode Produk',
                field: 'kode_produk',
                maxWidth: '400px',
            },
            {
                name: 'Nama Produk',
                field: 'nama_produk',
                maxWidth: '400px',
            },
            {
                name: 'Saldo Minimum',
                field: 'saldo_minimum',
                maxWidth: '300px',
                className: "text-right",
                onRender: (item) => addCommas(parseFloat(item.saldo_minimum || 0).toFixed(2))
            },
            {
                name: 'User Input',
                field: 'user_input',
                maxWidth: '200px',
            },
            {
                name: 'User Otor',
                field: 'user_otorisasi',
                maxWidth: '200px',
            }
        ],
        6: [
            {
                name: 'No',
                field: '#',
                maxWidth: '50px',
            },
            {
                name: 'Nomor Nasabah',
                field: 'cif',
                maxWidth: '200px',
            },
            {
                name: 'Nama Lengkap',
                field: 'account_no',
                maxWidth: '200px',
            },
            {
                name: 'Alamat',
                field: 'ch_sms',
                maxWidth: '400px',
            },
            {
                name: 'Jenis Nasabah',
                field: 'db_min',
                maxWidth: '200px',
            },
            {
                name: 'Nomor Idetitas',
                field: 'cr_min',
                maxWidth: '200px',
            }
        ],
        7: [

        ]
    }

    const DashCard = ({ withHref = true, DashCardJudul, DashCardValue, DashIcon = "fa fa-address-card", index = null, color = "base", isLoading = null }) => {
        if(isLoading === null ) isLoading = dbState.loadingCard
        return (

        <div className="">
            <div className='row'>
                <div className="col-lg-12 col-12">
                    {
                        isLoading ?
                            <Skeleton height={60} count={1} />
                            :
                            <div
                                className={`small-box bg-${color} text-white`}>
                                <div className="inner">
                                    <h3>{DashCardValue}</h3>

                                    <p>{DashCardJudul}</p>
                                </div>
                                <div className="" style={{
                                    overflow: "hidden",
                                    height: "100%",
                                    position: "absolute",
                                    top: 0,
                                    right: 0
                                }}>
                                    <i className={DashIcon}
                                        style={{
                                            transform: "rotate(15deg)",
                                            width: "183px",
                                            height: "100 %",
                                            overflow: "hidden",
                                            color: "rgba(0, 0, 0, .15)",
                                            fontSize: "110pt"
                                        }}
                                    ></i>

                                </div>
                                {
                                    withHref && <a href="#" onClick={() => index !== null && dbAction.TableShow(index, DashIcon)} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    )};
    return (
        <>

            <div className="row">
                <div className="col-4"  >
                    <div className='row'>
                        <div className='col'>
                            <DashCard index={1} DashCardJudul="Nasabah Baru" color='danger' DashCardValue={
                                dbState?.dataCard?.jumlah_nasabah_baru || 0
                            }
                                DashIcon=
                                {
                                    "fa fa-users"
                                }
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <div className='card'>
                                <div className='card-body'>

                                    {/* 
                                    <div className="" style={{
                                        overflow: "hidden",
                                        height: "100%",
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        zIndex: 0
                                    }}>
                                        <i className="fa fa-users"
                                            style={{
                                                transform: "rotate(15deg)",
                                                width: "183px",
                                                height: "100 %",
                                                overflow: "hidden",
                                                color: "rgba(0, 0, 0, .15)",
                                                fontSize: "110pt"
                                            }}
                                        ></i>

                                    </div> */}
                                    <DoughnatChart
                                        isLoading={dbState.loadingChartcs}
                                        id="nasabah"
                                        colors={[
                                            "#004bbc",
                                            "#007bff"
                                        ]}
                                        fieldLabel='jenis_nasabah'
                                        fieldValue='jumlah'
                                        data={dbState.dataChartCif?.jumlah_nasabah_baru || []}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4" >

                    <div className='row'>
                        <div className='col'>

                            <DashCard index={3} DashCardJudul="WIC Baru" color='info' DashCardValue={
                                dbState?.dataCard?.jumlah_wic_baru || 0
                            }
                                DashIcon=
                                {
                                    "fa fa-user"
                                }

                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='card'>
                                <div className='card-body'>
                                    {/* <div className="" style={{
                                        overflow: "hidden",
                                        height: "100%",
                                        position: "absolute",
                                        top: 0,
                                        right: 0
                                    }}>
                                        <i className="fa fa-user"
                                            style={{
                                                transform: "rotate(15deg)",
                                                width: "183px",
                                                height: "100 %",
                                                overflow: "hidden",
                                                color: "rgba(0, 0, 0, .15)",
                                                fontSize: "110pt"
                                            }}
                                        ></i>

                                    </div> */}
                                    <DoughnatChart
                                        isLoading={dbState.loadingChartcs}
                                        id="wic"
                                        colors={[
                                            "#17a2b8",
                                            "#007bff"
                                        ]}

                                        fieldLabel='jenis_nasabah'
                                        fieldValue='jumlah'
                                        data={dbState.dataChartCif?.jumlah_wic_baru || []}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-4" >

                    <div className='row'>
                        <div className='col'>
                            <DashCard index={5} DashCardJudul="Rekening Baru" color='success' DashCardValue={
                                dbState?.dataCard?.jumlah_rekening_baru || 0
                            }
                                DashIcon=
                                {
                                    "fa fa-address-card"
                                }

                            />



                        </div>
                    </div>


                    <div className='row'>
                        <div className='col'>
                            <div className='card'>
                                <div className='card-body'>
                                    {/* <div className="" style={{
                                        overflow: "hidden",
                                        height: "100%",
                                        position: "absolute",
                                        top: 0,
                                        right: 0
                                    }}>
                                        <i className="fa fa-address-card"
                                            style={{
                                                transform: "rotate(15deg)",
                                                width: "183px",
                                                height: "100 %",
                                                overflow: "hidden",
                                                color: "rgba(0, 0, 0, .15)",
                                                fontSize: "110pt"
                                            }}
                                        ></i>

                                    </div> */}
                                    <DoughnatChart
                                        isLoading={dbState.loadingChartcs}
                                        colors={[
                                            "#28a745",
                                            "#007bff"
                                        ]}
                                        fieldLabel='jenis_rekening_liabilitas'
                                        fieldValue='jumlah'
                                        data={dbState.dataChartCif?.jumlah_rekening_baru || []}
                                        id="rek"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ColoredLine />

            <div className="card mt-4">

                <div className="card-header">
                    {dbState.loadingList ?
                        <Skeleton height={30} count={1} />
                        : <div className='card-title'>
                            {dbState.cardSelected?.title}
                        </div>}
                    {/* {
                        (
                            <div className="col-4">
                                {dbState.loadingList ?
                                    <Skeleton height={60} count={1} />
                                    : <DashCard
                                        DashCardJudul={
                                            dbState.cardSelected?.title || ""
                                        }
                                        DashCardValue={
                                            dbState?.cardSelected?.count || 0
                                        }
                                        DashIcon=
                                        {
                                            dbState?.cardSelected?.icon || <svg width="50" height="50" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.5 2.25H31.5C32.0967 2.25 32.669 2.48705 33.091 2.90901C33.5129 3.33097 33.75 3.90326 33.75 4.5V24.1928C34.5518 24.3724 35.3085 24.6728 36 25.0737V4.5C36 3.30653 35.5259 2.16193 34.682 1.31802C33.8381 0.474106 32.6935 0 31.5 0H4.5C3.30653 0 2.16193 0.474106 1.31802 1.31802C0.474106 2.16193 0 3.30653 0 4.5V31.5C0 32.6935 0.474106 33.8381 1.31802 34.682C2.16193 35.5259 3.30653 36 4.5 36H25.0737C24.3911 34.8226 24 33.4561 24 32C24 29.1428 25.5057 26.6306 27.7653 25.2152C25.6559 23.8295 22.5407 22.8 18 22.8C6.42857 22.8 4.11429 29.4857 4.11429 31.7143V33.7167C3.66074 33.6378 3.23892 33.4209 2.90901 33.091C2.48705 32.669 2.25 32.0967 2.25 31.5V4.5C2.25 3.90326 2.48705 3.33097 2.90901 2.90901C3.33097 2.48705 3.90326 2.25 4.5 2.25Z" fill="#CACCCF" />
                                                <path d="M22.9093 18.6132C21.6073 19.867 19.8414 20.5714 18 20.5714C16.1586 20.5714 14.3927 19.867 13.0907 18.6132C11.7886 17.3594 11.0571 15.6589 11.0571 13.8857C11.0571 12.1126 11.7886 10.412 13.0907 9.1582C14.3927 7.90439 16.1586 7.2 18 7.2C19.8414 7.2 21.6073 7.90439 22.9093 9.1582C24.2114 10.412 24.9429 12.1126 24.9429 13.8857C24.9429 15.6589 24.2114 17.3594 22.9093 18.6132Z" fill="#CACCCF" />
                                                <path d="M30 22C25.5888 22 22 25.5888 22 30C22 34.4112 25.5888 38 30 38C34.4112 38 38 34.4112 38 30C38 25.5888 34.4112 22 30 22ZM33.6923 30.6154H30.6154V33.6923H29.3846V30.6154H26.3077V29.3846H29.3846V26.3077H30.6154V29.3846H33.6923V30.6154Z" fill="#004bbc" />
                                            </svg>
                                        }
                                    />}
                            </div>
                        )
                    } */}
                </div>

                <div className="card-body">
                    <TableList
                        withCard={false}
                        isLoading={dbState.loadingList}
                        columns={collumn[dbState.dashshow]}
                        pagination={false}
                        data={dbState?.dataList || []}
                    />
                </div>

            </div>

            <div className='card'>
                <div className='card-header'>
                    {
                        dbState.loadingTasedo ? <Skeleton height={30} count={1} /> :
                            <div className='card-title'>
                                {dbState.dataTasedo?.title}
                            </div>
                    }
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-12 '>
                            <DashCard withHref={false}
                                isLoading={dbState.loadingTasedo}
                                DashCardJudul="Total"
                                color='base' DashCardValue={
                                    dbState?.dataTasedo?.count || 0
                                }
                                DashIcon=
                                {
                                    "fa fa-clock"
                                }

                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <TableList
                                withCard={false}
                                isLoading={dbState.loadingTasedo}
                                columns={[
                                    {
                                        name: 'No',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: 'Nomor Rekening',
                                        field: 'nomor_rekening',
                                    },
                                    {
                                        name: 'Nama Rekening',
                                        field: 'nama_rekening',
                                    },
                                    {
                                        name: 'Nomor Telepon',
                                        field: 'nomor_telepon',
                                    },
                                    {
                                        name: 'Tgl. Jatuh Tempo',
                                        field: 'cif',
                                        onRender: ({ tgl_jatuh_tempo_tasedo }) => moment(tgl_jatuh_tempo_tasedo).format("DD/MM/YYYY")
                                    },
                                ]}
                                withActions={false}
                                pagination={false}
                                data={dbState?.dataTasedo?.list || []}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CifDashboard;