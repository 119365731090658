import React, { memo, useEffect, useRef, useState } from 'react';
import { AppAction, AppInterfaces } from '../../../app.data';
import { createForm } from '../../../module/createFormList';
import { FormRegistrasi } from './formRegistrasi';
import moment from 'moment';
import { baseUrl } from '../../../app.config';
import { Modal} from 'react-bootstrap';
import { TableGrid, ActionMore, LoadingIcon } from '../../controls';
import { Button } from 'semantic-ui-react';
import { thousandSeparator } from '../../../../src/utils';
// import { Left } from 'react-bootstrap/lib/media';

const Step1 = AppInterfaces.appLoad(
    memo(({ editMode = false, store, ...props }) => {
        const { formData, loadingGet, productDetil } = store;
        const appAction = useRef(null);

        function changeForm(key, subkey, value) {
            if (subkey === 'nominalDeposito') {
                const newValue = thousandSeparator.format(value, { preventNegative: true });
                // if (newValue === 'NaN') return;

                appAction.current.setForm(key, {
                    ...formData[key],
                    [subkey]: newValue,
                });
            } else {
                const newValue = value.toUpperCase()
                appAction.current.setForm(key, {
                    ...formData[key],
                    [subkey]: newValue,
                });
            }
        }

        const [errDate, setErrDate] = useState(false);
        const [disabledForm, setDisabledForm] = useState(true);
        const dropdownDetil = [
            {
                key: 'eDisposisiDeposito',
                param: 'disposisi_deposito',
            },
            {
                key: 'eDisposisiBagiHasil',
                param: 'disposisi_bg_hasil',
            },
        ];
        useEffect(function () {
            editMode && getProduct();
            getBranch();
            dropdownDetil.forEach(({ key, param }) => {
                appAction.current.doGet({
                    url: '/dropdowns',
                    vari: param,
                    param: {
                        key,
                    },
                });
            });

            appAction.current.doGet({ url: '/dropdowns/gl', vari: 'gl_codes' });
        }, []);

        function getProduct(productType = '') {
            appAction.current.doGet({
                url: '/depositproducts',
                vari: 'deposito_products',
                param: {
                    detailsLevel: 'FULL',
                    productType,
                },
            });
        }

        // function searchNasabahBadanUsaha(noCIF) {
        function searchNasabahBadanUsaha() {
            appAction.current
                .doGet({
                    // url: `/nasabah-badan-usaha/find/${noCIF}`,
                    url: `/nasabah-badan-usaha/find/${formData._dataNasabah?.nomorCIF}`,
                    vari: 'nasabahDetil',
                    param: {
                        detailsLevel: 'FULL',
                    },
                    withAlert: false,
                })
                .then(({ statusCode, status, message_id, list }) => {
                    if (message_id === '00') {
                        changeForm('_rekening', 'tipeAkunNasabah', 'GROUP');
                        getProduct('BUSINESS_DEPOSIT');
                        appAction.current.setForm('_dataNasabah', {
                            ...formData._dataNasabah,
                            nama: list.groupName.toUpperCase(),
                            teleponRumah: list.homePhone || '',
                            teleponHP: list.mobilePhone || '',
                            namaIbuKandung: '',
                            tempatLahir: '',
                            tempatTanggalLahir: list._identitasNasabah.nasabahTanggalAktePendirian,
                            alamat: list._identitasNasabah.nasabahAlamat.toUpperCase(),
                            jenis_nasabah: 'GROUP',
                            npwp: list._identitasNasabah.nasabahNomorNpwp.toUpperCase(),
                        });

                        setDisabledForm(true);
                    } else {
                        let tipe = 'warning';
                        appAction.current.disp({
                            type: 'openAlert',
                            message: 'Data Not Found',
                            tipe,
                            // msgErr: resp.message
                        });

                        setDisabledForm(true);
                        appAction.current.setForm('_dataNasabah', {
                            ...formData._dataNasabah,
                            nama: '',
                            teleponRumah: '',
                            teleponHP: '',
                            namaIbuKandung: '',
                            tempatLahir: '',
                            tanggalLahir: '',
                            alamat: '',
                            nomorCIF: '',
                        });
                        appAction.current.wait(3000).then((resp) => appAction.current.closeAlert());
                    }
                });
        }

        function searchNasabah() {
            formData._dataNasabah?.nomorCIF !== '' &&
                formData._dataNasabah?.nomorCIF !== undefined &&
                formData._dataNasabah?.nomorCIF !== null &&
                appAction.current
                    .doGet({
                        url: `/nasabah/perorangan/find/${formData._dataNasabah?.nomorCIF}`,
                        vari: 'nasabahDetil',
                        withAlert: false,
                    })
                    .then(({ statusCode, status, message_id, list }) => {
                        if (message_id === '00') {
                            getProduct('PERSONAL_DEPOSIT');
                            changeForm('_informasiLain', 'statusRestriksi', '');
                            changeForm('_rekening', 'tipeAkunNasabah', 'CLIENT');
                            appAction.current.setForm('_dataNasabah', {
                                ...formData._dataNasabah,
                                nama: (list.firstName.toUpperCase() || '') + ' ' + (list.lastName.toUpperCase() || ''),
                                teleponRumah: list.homePhone,
                                teleponHP:
                                    list.mobilePhone === null || list.mobilePhone === 'NULL'
                                        ? ''
                                        : list.mobilePhone,
                                namaIbuKandung:
                                    list._customerData.personalMothersName === null ||
                                    list._customerData.personalMothersName === 'NULL'
                                        ? ''
                                        : list._customerData.personalMothersName.toUpperCase(),
                                tempatLahir: list._customerData.birthPlace.toUpperCase(),
                                tanggalLahir: list.birthDate,
                                alamat: list._ktpAddress.ktpAddress.toUpperCase(),
                                jenis_nasabah: 'CLIENT',
                                npwp: '',

                            });
                            setDisabledForm(true);
                        } else {
                            searchNasabahBadanUsaha();
                            setDisabledForm(true);
                        }
                    });
        }

        function changeProduct(value) {
            var produk = store.deposito_products?.find((v) => v.encodedKey === value)?.name;

            appAction.current
                .doGet({
                    url: `/depositproducts/find/${value}`,
                    vari: 'productDetil',
                    withAlert: false,
                })
                .then(({ statusCode, status, message_id, list }) => {
                    const { fee } = list;
                    if (message_id === '00') {
                        var tgl = moment(formData._rekening.tanggalBuka)
                            .add('month', fee.tenor)
                            .format('YYYY-MM-DD');
                        appAction.current.setForm('_rekening', {
                            ...formData._rekening,
                            nomorProduk: value,
                            produk,
                            tanggalJatuhTempo: tgl,
                        });
                        appAction.current.setForm('_bagiHasilDisposisi', {
                            ...formData._bagiHasilDisposisi,
                            nisbah: fee?.nisbah_nasabah || 0,
                            nisbahCounter: fee?.nisbah_nasabah || 0,
                            zakatBagiHasil: fee?.zakat || 0,
                            pajakBagiHasil: fee?.pajak || 0,
                        });
                    }
                });
        }

        function changeDate(key, date) {
            // var k = 'tanggalBuka'
            // var from = moment(formData._rekening?.[k])
            var to = moment(date);
            var x = productDetil
                ? to.add('month', productDetil.fee?.tenor).format('YYYY-MM-DD')
                : '';
            // changeForm("_rekening", "tanggalJatuhTempo", x)
            // if(key===k){
            //     k = "tanggalJatuhTempo"
            //     from = moment(date)
            //     to = moment(formData._rekening?.[k])
            // }
            // if(from >= to ){
            //     setErrDate(true)
            // }else{

            // setErrDate(false)
            appAction.current.setForm('_rekening', {
                ...formData._rekening,
                tanggalBuka: date,
                tanggalJatuhTempo: x,
            });
            // }
        }

        function getRekeningDebet(value) {
            appAction.current
                .doGet({
                    url: `/rekening/tabungan/detail/${value}`,
                    // vari: 'productDetil',
                    withAlert: false,
                })
                .then(({ statusCode, status, message_id, list }) => {
                    if (message_id === '00') {
                        appAction.current.setForm('_rekening', {
                            ...formData._rekening,
                            nomorRekeningDebet: value,
                            namaRekening: list.nama.toUpperCase(),
                        });
                    } else {
                        appAction.current.setForm('_rekening', {
                            ...formData._rekening,
                            nomorRekeningDebet: value,
                            namaRekening: '',
                        });
                    }
                });
        }

        function getRekeningDisposisi(value) {
            appAction.current
                .doGet({
                    url: `/rekening/tabungan/detail/${value}`,
                    withAlert: false,
                })
                .then(({ statusCode, status, message_id, list }) => {
                    if (message_id === '00') {
                        appAction.current.setForm('_bagiHasilDisposisi', {
                            ...formData._bagiHasilDisposisi,
                            nomorRekening: value,
                            nomorNama: list.nama.toUpperCase(),
                        });
                    } else {
                        appAction.current.setForm('_bagiHasilDisposisi', {
                            ...formData._bagiHasilDisposisi,
                            nomorRekening: value,
                            nomorNama: '',
                        });
                    }
                });
        }

        const [listNasabahSearch, setListSearchNasabah] = useState([]);
        const [jenisPencarian, setJenisPencarian] = useState('name');
        const [typeNasabah, setTypeNasabah] = useState('CLIENT');
        const [keyword, setKeyword] = useState('');
        
        const [modalSearchNasabah, setModalSearchNasabah] = useState({
            show: false
        })

        const [nomor_nasabah, setNomorNasabah] = useState('');

        const handleSearch = async () => {
            //http://localhost:8401/api/v1/nasabah/filter?searchBy=name&search=wadi&type=CLIENT
            const jenis_pencarian = jenisPencarian !== '' ? '&searchBy=' + jenisPencarian : '';
            const type_nasabah = typeNasabah !== '' ? '&type=' + typeNasabah : '';
            const pathname = `/nasabah/filter?search=${keyword}${jenis_pencarian}${type_nasabah}`;
            const res = await fetch(baseUrl + pathname, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
    
            const data = await res.json();
            setListSearchNasabah(data?.list);
        };

        const assignedBranchKey = '8a8e8fab786e635c0178863b7911431e'
        const [branch, setBranch] = useState({})

        const getBranch = () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };

            fetch(
                baseUrl + `/rekening/tabungan/kantor/cabang/${assignedBranchKey}/detail`,
                requestOptions
            )
            .then((response) => response.json())
            .then((data) => {
                if (data.message_id === '00') {
                    setBranch(data.list)
                } else {
                    setBranch({})
                }
            });
        }

        return (
            <>
                <AppAction ref={appAction} />
                {
                    <div
                        className="row"
                        style={{
                            border: '1pt solid #d2d4d7',
                            margin: '0px 10px',
                            borderRadius: '6px',
                            padding: '5px',
                            paddingTop: '20px',
                            marginBottom: '30px',
                        }}
                    >
                        <div className="col-md-6">
                            <div className="form-group form-group-sm col-md-6">
                                <label
                                    style={{
                                        fontSize: '10pt',
                                    }}
                                >
                                    Nomor Rekening Deposito
                                </label>
                                <input
                                    value={formData.nomorRekeningDeposito}
                                    disabled={true}
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <span
                                className="float-right"
                                style={{
                                    position: 'absolute',
                                    bottom: 10,
                                    right: 20,
                                }}
                            >
                                Kode Cabang : {`${branch?.id} - ${branch?.name}`}
                            </span>
                        </div>
                    </div>
                }
                <FormRegistrasi
                    itemsForm={[
                        {
                            name: 'Data Nasabah',
                            rows: [
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-8">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'CIF',
                                                    type: 'number',
                                                    placeholder: 'CIF',
                                                    disabled: true,
                                                    readonly: false,
                                                    required: true,
                                                    value: formData._dataNasabah?.nomorCIF,
                                                    onChange: (value) =>{
                                                        changeForm(
                                                            '_dataNasabah',
                                                            'nomorCIF',
                                                            value
                                                        );
                                                    }
                                                })}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-group-sm">
                                                    {/* <label>&nbsp;</label> */}
                                                    <button
                                                        // onClick={searchNasabah}
                                                        onClick={() => setModalSearchNasabah({
                                                            show: true
                                                        })}
                                                        style={{
                                                            marginTop: 30,
                                                            background: '#E6530D',
                                                        }}
                                                        className="btn btn-sm btn-primary col"
                                                    >
                                                        {loadingGet ? (
                                                            <LoadingIcon />
                                                        ) : (
                                                            <>Cari Nasabah</>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 1,
                                    label: 'Nama',
                                    type: 'text',
                                    placeholder: 'Nama',
                                    disabled: disabledForm || editMode,
                                    value: formData._dataNasabah?.nama,
                                    readonly: false,
                                    onChange: (value) => changeForm('_dataNasabah', 'nama', value),
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'No Telepon',
                                                    type: 'text',
                                                    placeholder: 'No Telepon',
                                                    value: formData._dataNasabah?.teleponRumah,
                                                    disabled: disabledForm || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_dataNasabah',
                                                            'teleponRumah',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'No Handphone',
                                                    type: 'text',
                                                    placeholder: 'No Handphone',
                                                    value: formData._dataNasabah?.teleponHP,
                                                    disabled: disabledForm || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_dataNasabah',
                                                            'teleponHP',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    placeholder: 'Nama Ibu Kandung ',
                                    type: 'text',
                                    options: [],
                                    label: formData._dataNasabah?.jenis_nasabah === 'GROUP'
                                        ? 'Nomor NPWP'
                                        : 'Nama Ibu Kandung'
                                    ,
                                    value: formData._dataNasabah?.jenis_nasabah === 'GROUP'
                                        ? formData._dataNasabah?.npwp
                                        : formData._dataNasabah?.namaIbuKandung
                                    ,
                                    disabled: disabledForm || editMode,
                                    readonly: false,
                                    onChange: (value) =>
                                        formData._dataNasabah?.jenis_nasabah === 'GROUP'
                                        ? changeForm('_dataNasabah', 'npwp', value)
                                        : changeForm('_dataNasabah', 'namaIbuKandung', value)
                                    ,
                                },
                                {
                                    rows: 2,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-7" hidden={formData._dataNasabah?.jenis_nasabah === 'GROUP'}>
                                                {createForm({
                                                    rows: 1,
                                                    label: formData._dataNasabah?.jenis_nasabah === 'GROUP'
                                                    ? ''
                                                    : 'Tempat Lahir'
                                                ,
                                                    type: 'text',
                                                    placeholder: 'Tempat',
                                                    value: formData._dataNasabah?.jenis_nasabah === 'GROUP'
                                                        ? '' 
                                                        : formData._dataNasabah?.tempatLahir
                                                    ,
                                                    disabled: disabledForm || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        formData._dataNasabah?.jenis_nasabah === 'CLIENT'
                                                        ? changeForm(
                                                            '_dataNasabah',
                                                            'tempatLahir',
                                                            value
                                                        )
                                                        :  console.log('')
                                                    ,
                                                    hidden: (formData._dataNasabah?.jenis_nasabah === 'GROUP')
                                                ,
                                                })}
                                            </div>
                                            <div className="col-md-5">
                                                {createForm({
                                                    rows: 1,
                                                    label: formData._dataNasabah?.jenis_nasabah === 'GROUP'
                                                    ? 'Tanggal Pendirian'
                                                    : 'Tanggal Lahir',
                                                    type:
                                                        disabledForm || editMode ? 'text' : 'date',
                                                    disabled: disabledForm || editMode,
                                                    value: formData._dataNasabah?.jenis_nasabah === 'CLIENT'
                                                        ? formData._dataNasabah?.tanggalLahir
                                                        : ''
                                                    ,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        formData._dataNasabah?.jenis_nasabah === 'CLIENT'
                                                        ? changeForm(
                                                            '_dataNasabah',
                                                            'tanggalLahir',
                                                            value
                                                        )
                                                        :  console.log('')
                                                    ,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    placeholder: 'Alamat',
                                    type: 'text',
                                    options: [],
                                    label: 'Alamat',
                                    disabled: disabledForm || editMode,
                                    readonly: false,
                                    value: formData._dataNasabah?.alamat,
                                    onChange: (value) =>
                                        changeForm('_dataNasabah', 'alamat', value),
                                },
                            ],
                        },
                        {
                            name: 'Rekening',
                            rows: [
                                {
                                    rows: 1,
                                    label: 'Nomor Rekening Debet',
                                    type: 'number',
                                    options: [],
                                    placeholder: ' Nomor Rekening Debet',
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._rekening?.nomorRekeningDebet,
                                    onChange: (value) =>{
                                        changeForm('_rekening', 'nomorRekeningDebet', value)
                                        getRekeningDebet(value);
                                    },
                                },
                                {
                                    rows: 1,
                                    label: 'Nama Rekening',
                                    type: 'text',
                                    options: [],
                                    placeholder: ' Nama Rekening',
                                    disabled: true || editMode,
                                    readonly: false,
                                    value: formData._rekening?.namaRekening,
                                    onChange: (value) =>
                                        changeForm('_rekening', 'namaRekening', value),
                                },
                                {
                                    rows: 1,
                                    label: 'Nomor Bilyet',
                                    type: 'number',
                                    options: [],
                                    placeholder: ' Nomor Bilyet',
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._rekening?.nomorBilyet,
                                    onChange: (value) =>
                                        changeForm('_rekening', 'nomorBilyet', value),
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tanggal Buka',
                                                    required: true,
                                                    type: 'date',
                                                    placeholder: 'Tanggal Buka',
                                                    disabled: false || editMode,
                                                    readonly: false,
                                                    value: formData._rekening?.tanggalBuka,
                                                    onChange: (value) =>
                                                        changeDate('tanggalBuka', value),
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tanggal Jatuh Tempo',
                                                    type: 'date',
                                                    placeholder: 'Tanggal Jatuh Tempo',
                                                    disabled: false || editMode,
                                                    readonly: false,
                                                    value: formData._rekening?.tanggalJatuhTempo,
                                                    // onChange: (value) => null, //changeDate("tanggalJatuhTempo", value)
                                                    onChange: (value) => changeForm('_rekening', 'tanggalJatuhTempo', value),
                                                })}
                                            </div>
                                            <div className="col-md-12">
                                                {errDate && (
                                                    <span style={{ color: 'red' }}>
                                                        Tanggal buka tidak bisa lebih dari tanggal
                                                        jatuh tempo
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    label: 'Produk Deposito',
                                    type: 'select',
                                    options: store.deposito_products,
                                    key: 'encodedKey',
                                    display: 'name',
                                    required: false,
                                    placeholder: 'Pilih produk deposito',
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._rekening?.nomorProduk,
                                    onChange: (value) => {
                                        changeProduct(value);
                                    },
                                },
                                {
                                    rows: 2,
                                    label: 'Valuta',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'valuta',
                                    disabled: true || editMode,
                                    readonly: true,
                                    value: formData._rekening?.valuta,
                                    onChange: (value) => changeForm('_rekening', 'valuta', value),
                                },
                                {
                                    rows: 2,
                                    label: 'Nominal Deposito',
                                    type: 'number',
                                    options: [],
                                    placeholder: 'Masukkan nominal deposito',
                                    disabled: false || editMode,
                                    readonly: false,
                                    required: false,
                                    // value: Number(
                                    //     formData._rekening?.nominalDeposito
                                    // ).toLocaleString(),
                                    value: formData._rekening?.nominalDeposito,
                                    onChange: (value) => {
                                        changeForm('_rekening', 'nominalDeposito', value)
                                    },
                                },
                                {
                                    rows: 2,
                                    label: 'Keterangan',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Keterangan',
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._rekening?.keterangan,
                                    onChange: (value) =>
                                        changeForm('_rekening', 'keterangan', value),
                                },
                            ],
                        },
                        {
                            name: 'Bagi Hasil & Disposisi',
                            rows: [
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Nisbah Counter              ( % )',
                                                    type: 'number',
                                                    placeholder: 'Nisbah',
                                                    value: formData._bagiHasilDisposisi
                                                        ?.nisbahCounter,
                                                    disabled: true || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_bagiHasilDisposisi',
                                                            'nisbahCounter',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Nisbah Akhir   ( % )',
                                                    type: 'number',
                                                    placeholder: 'Nisbah',
                                                    value: formData._bagiHasilDisposisi?.nisbah,
                                                    disabled: false || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_bagiHasilDisposisi',
                                                            'nisbah',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Pajak Bagi Hasil   ( % )',
                                                    type: 'number',
                                                    value: formData._bagiHasilDisposisi
                                                        ?.pajakBagiHasil,
                                                    placeholder: 'Pajak Bagi Hasil',
                                                    disabled: true || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_bagiHasilDisposisi',
                                                            'pajakBagiHasil',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Zakat Bagi Hasil   ( % )',
                                                    type: 'number',
                                                    value: formData._bagiHasilDisposisi
                                                        ?.zakatBagiHasil,
                                                    placeholder: 'Zakat Bagi Hasil',
                                                    disabled: true || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_bagiHasilDisposisi',
                                                            'zakatBagiHasil',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    // ARO, ARO & Bagi Hasil, Non ARO
                                    // Pindah Buku, Transfer SKN – GL, Transfer RTGS – GL
                                    rows: 2,
                                    label: 'Disposisi Pokok & Bagi Hasil',
                                    placeholder: 'Select Disposisi Pokok & Bagi Hasil',
                                    type: 'select',
                                    required: true,
                                    options: [
                                        {
                                            key: 'ARO',
                                            display: 'ARO',
                                        },
                                        {
                                            key: 'ARO & BAGI HASIL',
                                            display: 'ARO & BAGI HASIL',
                                        },
                                        {
                                            key: 'NON ARO',
                                            display: 'NON ARO',
                                        },
                                    ],
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._bagiHasilDisposisi?.disposisiPokok,
                                    onChange: (value) =>
                                        changeForm('_bagiHasilDisposisi', 'disposisiPokok', value),
                                },
                                {
                                    rows: 2,
                                    label: 'Instruksi Disposisi',
                                    type: 'select',
                                    placeholder: 'Select Instruksi Disposisi',
                                    options: [
                                        {
                                            key: 'PINDAH BUKU',
                                            display: 'PINDAH BUKU',
                                        },
                                        {
                                            key: 'TRANSFER SKN - GL',
                                            display: 'TRANSFER SKN - GL',
                                        },
                                        {
                                            key: 'TRANSFER RTGS - GL',
                                            display: 'TRANSFER RTGS - GL',
                                        },
                                    ],
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._bagiHasilDisposisi?.instruksiDisposisi,
                                    onChange: (value) =>
                                        appAction.current.setForm('_bagiHasilDisposisi', {
                                            ...formData._bagiHasilDisposisi,
                                            instruksiDisposisi: value,
                                            nomorRekening: '',
                                            nomorNama: '',
                                        }),
                                },
                                {
                                    rows: 2,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-5">
                                                {createForm({
                                                    rows: 1,
                                                    label:
                                                        formData._bagiHasilDisposisi
                                                            ?.instruksiDisposisi === 'PINDAH BUKU'
                                                            ? 'Nomor & Nama Rekening'
                                                            : 'Kode & Nama GL',
                                                    type:
                                                        formData._bagiHasilDisposisi
                                                            ?.instruksiDisposisi === 'PINDAH BUKU'
                                                            ? 'number'
                                                            : 'select',
                                                    placeholder:
                                                        formData._bagiHasilDisposisi
                                                            ?.instruksiDisposisi === 'PINDAH BUKU'
                                                            ? 'No Rekening'
                                                            : 'Select Kode GL',
                                                    options: store.gl_codes || [],
                                                    key: 'glCode',
                                                    display: 'glCode',
                                                    disabled: false || editMode,
                                                    readonly: false,
                                                    value: formData._bagiHasilDisposisi
                                                        ?.nomorRekening,
                                                    onChange: (value) => {
                                                        if (
                                                            formData._bagiHasilDisposisi
                                                                ?.instruksiDisposisi ===
                                                            'PINDAH BUKU'
                                                        ) {
                                                            changeForm(
                                                                '_bagiHasilDisposisi',
                                                                'nomorRekening',
                                                                value
                                                            );
                                                            getRekeningDisposisi(value);
                                                        } else {
                                                            var name = store.gl_codes?.find(
                                                                (v) => v.glCode === value.toUpperCase()
                                                            )?.name;
                                                            appAction.current.setForm(
                                                                '_bagiHasilDisposisi',
                                                                {
                                                                    ...formData._bagiHasilDisposisi,
                                                                    nomorRekening: value,
                                                                    nomorNama: name.toUpperCase(),
                                                                }
                                                            );
                                                        }
                                                    },
                                                })}
                                            </div>
                                            <div className="col-md-7">
                                                {createForm({
                                                    rows: 1,
                                                    label: <>&nbsp;</>,
                                                    type: 'text',
                                                    placeholder: 'Masukan nama',
                                                    // disabled:
                                                    //     (formData._bagiHasilDisposisi
                                                    //         ?.instruksiDisposisi === 'PINDAH BUKU'
                                                    //         ? false
                                                    //         : true) || editMode,
                                                    readonly: false,
                                                    value: formData._bagiHasilDisposisi?.nomorNama,
                                                    disabled: true || editMode,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_bagiHasilDisposisi',
                                                            'nomorNama',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                            ],
                        },
                    ]}
                />
                <Modal
                    show={modalSearchNasabah.show}
                    onHide={() => setModalSearchNasabah({
                        show: false
                    })}
                    size="xl"
                >
                    <Modal.Header>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Pencarian Data Nasabah</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-8">
                                <p>Cari Berdasarkan:</p>
                                <div className="form-row">
                                    <div className="form-group col-3">
                                        <select
                                            className="form-control"
                                            onChange={(e) => setJenisPencarian(e.target.value)}
                                            value={jenisPencarian}
                                        >
                                            <option hidden disabled selected value="">
                                                Jenis Pencarian
                                            </option>
                                            <option value="CIF">Nomor CIF</option>
                                            <option value="name">Nama Nasabah</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-3">
                                        <select
                                            className="form-control"
                                            onChange={(e) => setTypeNasabah(e.target.value)}
                                            value={typeNasabah}
                                        >
                                            <option hidden disabled selected value="">
                                                Type Nasabah
                                            </option>
                                            <option value="CLIENT">Perorangan</option>
                                            <option value="GROUP">Badan Usaha</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Keyword"
                                            onChange={(e) => setKeyword(e.target.value)}
                                            value={keyword}
                                        />
                                    </div>
                                    <div className="form-group col-2">
                                        <button className="btn btn-primary" onClick={handleSearch}>
                                            Cari
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TableGrid
                            onRenderField={(value, field, { className, data }) => {
                                if (field === 'action')
                                    return (
                                        <div key={`${value}-${field}`} className={className}>
                                            <ActionMore
                                                config={{
                                                    minWidth: '120px',
                                                }}
                                                actions={[
                                                    {
                                                        label: 'Pilih Nasabah',
                                                        onClick: () => {
                                                            formData._dataNasabah.nomorCIF = data?.nomor_cif
                                                            setModalSearchNasabah({show: false});
                                                            setNomorNasabah(data?.nomor_cif);
                                                            searchNasabah();
                                                        },
                                                    },
                                                ]}
                                            ></ActionMore>
                                        </div>
                                    );
                            }}
                            columns={[
                                {
                                    name: 'Nomor CIF',
                                    field: 'nomor_cif',
                                },
                                {
                                    name: 'Nama CIF',
                                    field: 'nama',
                                },
                                {
                                    name: 'Alamat',
                                    field: 'alamat',
                                },
                                {
                                    name: 'Jenis Nasabah',
                                    field: 'jenis_nasabah',
                                },
                                {
                                    name: '',
                                    field: 'action',
                                    width: '60px',
                                },
                            ]}
                            data={listNasabahSearch}
                            // isLoading={!isFetchedAfterMount}
                            config={{
                                height: '55vh',
                                overflowY: 'auto',
                                stickyHead: true,
                                //tableMinWidth: '300px',
                            }}
                        ></TableGrid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setModalSearchNasabah({show: false})}>Tutup</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);

export default Step1;
