import clsx from 'clsx';
import { Link } from 'react-router-dom';
import styles from './registrasi-rekening.module.css';

export default ({ heading, description, className, href, icon }) => {
   return (
      <Link
         to={href}
         className={clsx(
            'p-4 border border-gray d-flex flex-column justify-content-center align-items-center text-gray-2',
            className,
            styles.card
         )}
      >
         <div style={{ height: 60, width: 60}} className="mb-3">
            {icon}
         </div>
         <div className="f-14 font-weight-bold text-center mb-2">{heading}</div>
         <div className="f-12 text-center">{description}</div>
      </Link>
   );
};
