import React from "react";
import { Dropdown, Modal} from 'react-bootstrap';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormBadanUsaha from './useFormBadanUsaha';
import useRelasiNasabaah from './useRelasiNasabah';

const RelasiNasabah = () => {
const { fbAction, fbState } = useFormBadanUsaha();
const { formData = {}, formEditable = false } = fbState;
const { pRState, pRAction } = useRelasiNasabaah()
return ( 
    <>
        <div className="pb-4">
        {/* { (fbState.paramUrl?.mode === "registration" || fbState.paramUrl?.mode === "edit") && (
            <div className="body-relasi">
            <p className="global-text">Cari data berdasarkan :</p>
            <div className="row">
                <div className="col-2">
                    <div className="form-group">
                        <select name="" id="" className="form-control">
                            <option value="">Tipe Relasi</option>
                        </select>
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nomor Nasabah atau Nama"
                        />
                    </div>
                </div>
                <div className="col-1">
                    <button
                        className="btn btn-outline-primary"
                    >
                        Reset
                    </button>
                </div>
                <div className="col-3 p0">
                    <button className="btn btn-primary btn-block">Cari Nasabah</button>
                </div>
                <div className="col-3">
                    <button onClick={pRAction.openModalAdd} className="btn btn-outline-primary btn-block">
                        Tambah Relasi Nasabah
                    </button>
                </div>
            </div>
            </div>
        )} */}

            { (fbState.paramUrl?.mode === "registration" || fbState.paramUrl?.mode === "edit") &&  (
                <div className="row">
                    <div className="col-9">
                    </div>
                    <div className="col-3">
                        <button onClick={pRAction.openModalAdd} className="btn btn-outline-primary btn-block">
                            Tambah Relasi Nasabah
                        </button>
                    </div>
                </div>
            )}
            <p className="text-primary">Relasi Nasabah</p>
            <div className="row">
                <div className="col-12">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th>ID Relasi</th>
                                <th>Jabatan / Pangkat</th>
                                <th>Nomor Nasabah Relasi</th>
                                <th>Nama Nasabah</th>
                                <th>Jenis Nasabah</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                fbState.formData?._relasiNasabah?.map((item, key) => {
                                    if (item.action !== 'D') {
                                    return (
                                    <tr key={key}>
                                        <td>{item.IDRelasi}</td>
                                        <td>{
                                            (fbState.dataDropDown?.R_JABATAN_KORPORAT?.find(
                                                ({
                                                    reference_code
                                                })=>reference_code === item.RnJabatanAtauPangkat
                                            )?.reference_desc || '')
                                            }
                                        </td>
                                        <td>{item.RnNomorNasabah}</td>
                                        <td>{item.RnNamaIdentitas}</td>
                                        <td>{  
                                             (item.RnJenisNasabah === "I") ? "Individu"
                                            : "Korporat"
                                            }
                                        </td>
                                        <td>
                                        {
                                            fbState.formEditable &&
                                            <Dropdown>
                                                <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="14" cy="8" r="2" fill="#4F4F4F"/>
                                                    <circle cx="8" cy="8" r="2" fill="#4F4F4F"/>
                                                    <circle cx="2" cy="8" r="2" fill="#4F4F4F"/>
                                                </svg>
                                            </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => pRAction.openDetil(key)}><p>Detail Relasi Nasabah</p></Dropdown.Item>
                                                    <Dropdown.Item onClick={() => pRAction.openDetil(key, "EDIT")}><p>Ubah Relasi Nasabah</p></Dropdown.Item>
                                                    <hr/>
                                                    <Dropdown.Item onClick={() => pRAction.del(key)} ><p className="color-danger">Hapus Relasi Nasabah</p></Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        }
                                        </td>
                                    </tr>
                                )}})
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        {/* form relasi nasbaah */}
        <Modal size="xl" show={pRState.formModal} onHide={pRAction.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    Form Relasi Nasabah
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-2">
                    <div className="col-6">
                        {
                            createForm({
                                type: "select",
                                label: "Hubungan Dengan Nasabah",
                                placeholder: "Pilih hubungan dengan nasabah",
                                required: true,
                                options: (fbState.dataDropDown?.R_RELASI_NASABAH || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                value: pRState.formRelasi?.RnHubunganDenganNasabah,
                                onChange: (value) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnHubunganDenganNasabah: value})),
                                key: "reference_code",
                                display: "reference_desc",
                                disabled: fbState.mode ==="DETAIL",
                            })
                        }
                    </div>
                    <div className="col-6">
                        {
                            createForm({
                                type: "select",
                                label: "Jabatan / Pangkat",
                                placeholder: "Pilih Jabatan / Pangkat",
                                options: (fbState.dataDropDown?.R_JABATAN_KORPORAT || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: pRState.formRelasi?.RnJabatanAtauPangkat,
                                onChange: (value) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnJabatanAtauPangkat: value})),
                                disabled: fbState.mode ==="DETAIL",
                                required: true
                            })
                        }
                    </div>
                </div>
                <FormRegistration
                    itemsForm={[
                        // Data Diri
                        {
                            name: "Data Diri",
                            rows: []
                        }
                        // End Data Diri
                    ]}
                />
                <div className="row">
                    <div className="col">
                        <div 
                            className={pRState.formRelasi.RnNomorNasabah === "" || pRState.formRelasi.RnNomorNasabah === undefined || pRState.formRelasi.RnNomorNasabah === null ? "form-group form-group-sm col-6 text-danger" : "form-group form-group-sm col-6"}
                        >
                            <label>Nomor Nasabah</label>
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                            <div className="input-group input-group-sm">
                                <input
                                    className="form-control form-control-sm"
                                    placeholder="Masukkan nomor nasabah"
                                    value={pRState.formRelasi.RnNomorNasabah}
                                    onChange={(e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnNomorNasabah: e.target.value.replace(/[^0-9 \-]/g, '') }))}
                                />
                                <div className="input-group-append ml-2">
                                    <button 
                                        className="btn btn-outline-primary" 
                                        style={{ borderRadius: 5 }}
                                        onClick={pRAction.getCariNasabah}
                                    >
                                        Cari Nasabah
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                    {
                            createForm({
                                type: "text",
                                label: "Nama (sesuai Identitas)",
                                placeholder: "Masukkan Nama (sesuai Identitas)",
                                value: pRState.formRelasi.RnNamaIdentitas,
                                onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnNamaIdentitas: e })),
                                disabled: true
                                //disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                            })
                    }
                    </div>
                    <div className="col-6">
                    {
                            createForm({
                                type: "select",
                                label: "Jenis Nasabah",
                                placeholder: "Pilih Jenis Nasabah",
                                value: pRState.formRelasi.RnJenisNasabah,
                                onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnJenisNasabah: e })),
                                disabled: true,
                                //disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                                options : [
                                    {
                                        key: "I",
                                        display : "I - Individu"
                                    },
                                    {
                                        key: "K",
                                        display : "K - Korporat"
                                    },
                                ]
                            })
                    }
                    </div>
                </div>
                        {/* <div className="row">
                            <div className="col-6">
                                {
                                    createForm({
                                        label: 'Tempat/Tanggal Lahir',
                                        type: 'text',
                                        placeholder: "Masukkan tempat lahir",
                                        value: pRState.formRelasi.RnTempatLahir,
                                        onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnTempatLahir: e })),
                                        disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                                    })
                                }
                            </div>
                            <div className="col-6">
                                {
                                    createForm({
                                        label: <>&nbsp;</>,
                                        type: 'date',
                                        value: pRState.formRelasi.RnTglLahir,
                                        onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnTglLahir: e })),
                                        disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                                    })
                                }
                            </div>
                        </div>
                        {
                            createForm({
                                type: "select",
                                label: "Jenis Identitas",
                                placeholder: "Pilih jenis identitas",
                                options: fbState.dataDropDown?.eJenisIdentitas,
                                key: "reference_code",
                                display: "reference_desc",
                                value: pRState.formRelasi.RnJenisIdentitas,
                                onChange: (value) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnJenisIdentitas: value})),
                                disabled: fbState.mode ==="DETAIL",
                            })
                        }
                        {
                            createForm({
                                type: "number",
                                label: "Nomor Identitas",
                                placeholder: "Masukan Nomor identitas",
                                value: pRState.formRelasi.RnNomorIdentitas,
                                onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnNomorIdentitas: e })),
                                disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                            })
                        }
                        {
                            createForm({
                                type: "number",
                                label: "NPWP",
                                placeholder: "Masukan nomor NPWP",
                                value: pRState.formRelasi.RnNomorNpwp,
                                onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnNomorNpwp: e })),
                                disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                            })
                        }
                        <div className="row">
                            <div className="col pl-3">
                                <div className="form-group">
                                    <label
                                        style={{ fontSize: "12px" }}
                                    >
                                        Jenis Kelamin
                                    </label>
                                    <br />
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input
                                                type="radio"
                                                checked={pRState.formRelasi.RnJenisKelamin === "MALE"}
                                                className="form-check-input"
                                                name="RnJenisKelamin"
                                                onChange= {(e) => {
                                                    pRAction.setFormRelasi(prevState => ({ ...prevState, RnJenisKelamin: e.target.value }));
                                                }}
                                                value="MALE"
                                                disabled = {pRState.isNasabah || pRState.mode === "DETAIL"}
                                            />Laki-laki
                                        </label>
                                    </div>
                                    <div className="form-check-inline ml-3">
                                        <label className="form-check-label">
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                name="gender"
                                                checked={pRState.formRelasi.RnJenisKelamin === "FEMALE"}
                                                onChange= {(e) => {
                                                    pRAction.setFormRelasi(prevState => ({ ...prevState, RnJenisKelamin: e.target.value }));
                                                }}
                                                value="FEMALE"
                                                disabled = {pRState.isNasabah || pRState.mode === "DETAIL"}
                                            />Perempuan
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            createForm({
                                type: "select",
                                label: "Agama",
                                placeholder: "Pilih Agama",
                                value: pRState.formRelasi.RnAgama,
                                options: fbState.dataDropDown?.eAgama,
                                key: "reference_code",
                                display: "reference_desc",
                                onChange: (value) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnAgama: value})),
                                disabled: fbState.mode ==="DETAIL",
                            })
                        }
                        {
                            createForm({
                                type: "select",
                                label: "Kewaganegaraan",
                                placeholder: "Masukkan Kewaganegaraan",
                                value: pRState.formRelasi.RnKewarganegaraan,
                                options: fbState.dataDropDown?.eKewarganegaraan,
                                key: "reference_code",
                                display: "reference_desc",
                                onChange: (value) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnKewarganegaraan: value})),
                                disabled: fbState.mode ==="DETAIL",
                            })
                        }
                        {
                            createForm({
                                type: "select",
                                label: "Negara Asal",
                                placeholder: "Pilih negara asal",
                                value: pRState.formRelasi.RnNegaraAasal,
                                options: fbState.dataDropDown?.R_KODE_NEGARA,
                                key: "reference_code",
                                display: "reference_desc",
                                onChange: (value) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnNegaraAasal: value})),
                                disabled: fbState.mode ==="DETAIL",
                            })
                        }
                        {
                            createForm({
                                type: "select",
                                label: "Pekerjaan",
                                placeholder: "Pilih pekerjaan",
                                value: pRState.formRelasi.RnPekerjaan,
                                options: fbState.dataDropDown?.R_PEKERJAAN,
                                key: "reference_code",
                                display: "reference_desc",
                                onChange: (value) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnPekerjaan: value})),
                                disabled: fbState.mode ==="DETAIL",
                            })
                        }
                    </div>
                    <div className="col-6">
                        {
                            createForm({
                                type: "textarea",
                                label: "Alamat",
                                placeholder: "Masukkan Alamat",
                                value: pRState.formRelasi.RnAlamat,
                                onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnAlamat: e })),
                                disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                            })
                        }
                        <div className="row">
                            <div className="col-6">
                                {
                                    createForm({
                                        label: "Provinsi",
                                        type: "select",
                                        required: false,
                                        placeholder: "Masukan Provinsi",
                                        options: pRState.provinces,
                                        key: "province_code",
                                        display: "province_name",
                                        value: pRState.formRelasi.RnProvinsi || '',
                                        onChange: (value) => {
                                            // const data = pRState.provinces?.find(v => v.province_name === value);
                                            pRAction.getingCitiesNasabah(value)
                                            pRAction.changeForm(value, "RnProvinsi")
                                        },
                                        disabled: fbState.mode ==="DETAIL",
                                    })
                                }
                            </div>
                            <div className="col-6">
                                {
                                    createForm({
                                        label: "Kota/Kab",
                                        type: "select",
                                        options: pRState.cities,
                                        required: false,
                                        placeholder: "Masukan Kota/Kabupaten",
                                        key: "city_code",
                                        display: "city_name",
                                        value: pRState.formRelasi.RnKotaKabupaten || '',
                                        onChange: (value) => {
                                            // pRAction.getingDistrictNasabah(value)
                                            pRAction.changeForm(value, "RnKotaKabupaten")
                                        },
                                        disabled: fbState.mode ==="DETAIL",
                                    })
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                {
                                    createForm({
                                        label: "Kecamatan",
                                        type: "text",
                                        required: false,
                                        placeholder: "Masukan Kecamatan",
                                        //options: pRState.districts,
                                        value: pRState.formRelasi.RnKecamatan || '',
                                        //onChange: (value) => {
                                        //    pRAction.getingVillagesNasabah(
                                        //        pRState.formRelasi?.RnKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                        //        value
                                        //    )
                                        //    pRAction.changeForm(value, "RnKecamatan")
                                        //},
                                        onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnKecamatan: e })),
                                        disabled: fbState.mode ==="DETAIL",
                                    })
                                }
                            </div>
                            <div className="col-6">
                                {
                                    createForm({
                                        label: "Kelurahan",
                                        type: "text",
                                        required: false,
                                        //options: pRState.villages,
                                        placeholder: "Masukan Kelurahan",
                                        value: pRState.formRelasi.RnKelurahan || '',
                                        //onChange: (value) => {
                                        //    pRAction.getingPostalCodesNasabah(
                                        //        pRState.formRelasi?.RnKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                        //        pRState.formRelasi?.RnKecamatan, // ambil dari data Kota/Kab sebelumnya.
                                        //        value
                                        //    )
                                        //    pRAction.changeForm(value, "RnKelurahan")
                                        //},
                                        onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnKelurahan: e })),
                                        disabled: fbState.mode ==="DETAIL",
                                    })
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                {
                                    createForm({
                                        type: "number",
                                        label: "RT",
                                        placeholder: "RT",
                                        value: pRState.formRelasi.RnRT,
                                        onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnRT: e })),
                                        disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                                    })
                                }
                            </div>
                            <div className="col-6">
                                {
                                    createForm({
                                        type: "number",
                                        label: "RW",
                                        placeholder: "RW",
                                        value: pRState.formRelasi.RnRW,
                                        onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnRW: e })),
                                        disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                                    })
                                }
                            </div>
                        </div>
                        {
                            createForm({
                                type: "text",
                                label: "Kode Pos",
                                placeholder: "Masukkan Kode Pos",
                                //options: pRState.postalCodes,
                                value: pRState.formRelasi.RnKodePos,
                                onChange: (value) => pRAction.changeForm(value, "RnKodePos"),
                                disabled: fbState.mode ==="DETAIL",
                            })
                        }
                        <div className="row">
                            <div className="col-4 pl-2 pr-1">
                                {
                                    createForm({
                                        label: "Telepon Rumah",
                                        type: "number",
                                        placeholder: "000",
                                        value: pRState.formRelasi.RnTeleponRumahKodeArea1,
                                        onChange: (e) => pRAction.changeForm(prevState => ({ ...prevState, RnTeleponRumahKodeArea1: e })),
                                        disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                                        required: false
                                    })
                                }
                            </div>
                            <div className="col-8">
                                {
                                    createForm({
                                        label: <>&nbsp;</>,
                                        type: "number",
                                        placeholder: "000",
                                        value: pRState.formRelasi.RnTeleponRumah,
                                        onChange: (e) => pRAction.changeForm(prevState => ({ ...prevState, RnTeleponRumah: e })),
                                        disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                                        required: false
                                    })
                                }
                            </div>
                        </div>
                        {
                            createForm({
                                type: "number",
                                label: "Handphone",
                                placeholder: "Masukkan Nomor Handphone",
                                value: pRState.formRelasi.RnHandphone,
                                onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnHandphone: e })),
                                disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                            })
                        }
                        <div className="row">
                            <div className="col-3 pl-2 pr-1">
                                {
                                    createForm({
                                        label: "Fax",
                                        type: "number",
                                        placeholder: "000",
                                        value: pRState.formRelasi.RnCodeFax,
                                        onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnCodeFax: e })),
                                        disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                                        required: false
                                    })
                                }
                            </div>
                            <div className="col-9">
                                {
                                    createForm({
                                        label: <>&nbsp;</>,
                                        type: "number",
                                        placeholder: "000",
                                        value: pRState.formRelasi.RnFax,
                                        onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnFax: e })),
                                        disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                                        required: false
                                    })
                                }
                            </div>
                        </div>
                        {
                            createForm({
                                type: "text",
                                label: "Email",
                                placeholder: "Masukkan Email",
                                value: pRState.formRelasi.RnEmail,
                                onChange: (e) => pRAction.setFormRelasi(prevState => ({ ...prevState, RnEmail: e })),
                                disabled: pRState.isNasabah || pRState.mode === "DETAIL",
                            })
                        }
                    </div> */}
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col">
                        <button 
                            onClick={pRAction.save}
                            className="btn btn-sm float-right btn-success"
                        >
                            Simpan
                        </button>
                        <button onClick={pRAction.closeModal} className="btn btn-sm float-right mr-2 btn-outline-success">
                            Batal
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>

            {/* form cari nasabah */}
            
    </>
     );
}
 
export default RelasiNasabah;