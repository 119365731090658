import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useFetch from '../../../../hooks/useFetch';
import { useModalAlert } from '../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../../hooks/keycloak.module';
import { addCommas } from '../../../../module';

const cdContext = createContext({
    cdState: {}, cdAction: {}
});

export function CDProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const { doGet, doPost, wait } = useFetch();
    const location = useLocation();

    const [err, setErr] = useState(true);
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [formData, setFormData] = useState({
        "user_id": "",
        "kode_valuta": "",
        "keterangan_valuta": "",
        "total_saldo": 0,
        "denominasi": [
            // {
            //     "nilai_denominasi": 100000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 50000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 20000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 10000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 5000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 2000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 1000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 100,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 1000,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 500,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 200,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 100,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 50,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 25,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
        ]
    })


    const { keycloak } = useKeycloak()
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams()
    const [otorDesc, setOtorDesc] = useState("")
    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            getDataDetail()
            // getDataPerorangan(location.state?.dbId);
        } else {
            getSaldo();
        }
        // } 
        setFormEditable(paramUrl.mode !== "otorisasi");
    }, [paramUrl.mode])

    // useEffect(function(){
    //     let saldo = parseInt(formData.info_transaksi?.saldo_teller_before || 0) + parseInt(formData?.nominal || 0) || 0;
    //     changeForm(saldo, "saldo_teller_after", "info_transaksi")
    // },[formData])

    async function getSaldo() {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/kasteller/cetak-denominasi`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                // param: {
                //     user_id: userInfo.preferred_username,
                //     kode_valuta: formData.kode_valuta || "IDR"
                // },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {

                    // // console.log(data)
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    setFormData(prevState => ({
                        ...prevState,  
                        ...data,
                    }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }
    function changeJumlahLembar(jumlah_lembar_koin, key) {

        if (isNaN(jumlah_lembar_koin)) return false;
        let nominal = 0;
        const de = formData.denominasi
        const denominasi = de.map((d, i) => {

            if (key === i) {
                nominal += (parseInt(jumlah_lembar_koin) * d.nilai_denominasi)
                return {
                    ...d,
                    jumlah_lembar_koin,
                }

            } else {
                nominal += (d.jumlah_lembar_koin * d.nilai_denominasi)
                return d
            }
        });

        if (isNaN(nominal)) {
            nominal = 0
        }
        let saldo_teller_after = parseFloat(formData.info_transaksi.saldo_teller_before) + nominal;
        if (nominal > 0) setErr(false)
        else {
            setErr(true)
            setDataAlert({ show: true, body: `Jumlah saldo ${addCommas(parseFloat(formData.info_transaksi.saldo_teller_before).toFixed(2))}, Jumlah amnil kas tidak bisa kurang dari sama dengan 0`, title: `Setoran masih 0`, header: "Info", callBack: () => null });
        }
        setFormData(prevState => ({
            ...prevState,
            info_transaksi: {
                ...prevState.info_transaksi,
                saldo_teller_after
            },
            nominal,
            denominasi
        }));
    }

    // post ambil kas
    async function doTrx() {
        
    closeConfirm();
        // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: formData,
                url: "/ambilkhasanah", // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: remark, title: ``, header: "Success", callBack: () => {
                        closeConfirm();
                        resetForm();
                        getSaldo();
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function resetForm() {
        setFormData({
            "user_id": "",
            "kode_valuta": "",
            "keterangan_valuta": "",
            "total_saldo": 0,
            "denominasi": [
                // {
                //     "nilai_denominasi": 100000,
                //     "jenis": "Lembar",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 50000,
                //     "jenis": "Lembar",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 20000,
                //     "jenis": "Lembar",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 10000,
                //     "jenis": "Lembar",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 5000,
                //     "jenis": "Lembar",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 2000,
                //     "jenis": "Lembar",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 1000,
                //     "jenis": "Lembar",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 100,
                //     "jenis": "Lembar",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 1000,
                //     "jenis": "Koin",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 500,
                //     "jenis": "Koin",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 200,
                //     "jenis": "Koin",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 100,
                //     "jenis": "Koin",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 50,
                //     "jenis": "Koin",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
                // {
                //     "nilai_denominasi": 25,
                //     "jenis": "Koin",
                //     "jumlah_lembar_koin": 0,
                //     "total_nominal": 0
                // },
            ]
        })
    }

    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            // fieldRequired = ["nomorCIF", "nama_produk", "nama_rekening"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    async function getDataDetail(dbId = location.state?.dbId) {

        let url = "/rekening/local";
        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/kasteller/cetak-denominasi`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    // console.log(data)
                    setFormData(data);
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
        // // console.log(data)
    }

    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        value = value.toUpperCase()
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    function confirmTrx() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }


    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }



    return (
        <cdContext.Provider value={{
            cdState: {
                formData,
                formEditable,
                err,
                otorDesc
            },
            cdAction: {
                changeForm,
                changeJumlahLembar,
                confirmTrx,
                responseOtor,
                setOtorDesc

            }
        }}>
            {children}
        </cdContext.Provider>
    )
}

export default function useCetakDenom() { return useContext(cdContext) }