// import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
import { useModalAlert } from '../../../controls/modalAlert';
import useKeycloak from '../../../../hooks/keycloak.module';

const UseregitrasiTeller = () => {

    const { doGet , doPost } = useFetch();
    const [modalregitrasiTeller, setmodalregitrasiTeller] = useState(false);
    const { modalState, modalAction } = useModalAlert();
    const { keycloak } = useKeycloak();
    const [otorDesc, setOtorDesc] = useState("")
    // Data State
    const [dataOtor, setDataOtoe] = useState({
        kode_entry: "",
        dbId: "",
        callBackSuccess: () => null
    });

    const [otorMode, setOtorMode] = useState(false);

    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        openLoading,
        closeConfirmCustom,
        openAlertCustom
    } = modalAction;

    const [formData,setFormData] = useState({
            "name" : "",
            "id_user": "",
    });

    function openregistrasiTeller(data = {}, isOtor=false, callBackSuccess = () => null ) {
        if(isOtor){
            setDataOtoe({...data, callBackSuccess});
            getDataOtor(data.dbId);
        }
        setOtorMode(isOtor)
        setmodalregitrasiTeller(true);
    }

    function closeregisTeller() {setmodalregitrasiTeller(false);}

    //Fungsi Post Teller
    async function postTeller() {
        const userInfo = await keycloak.loadUserInfo();
        var url = `/kasteller/create_rekening_teller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "trx",
                queryParam: formData
            });
            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Create Teller Berhasil.Silahkan Lakukan Otorisasi`, title: ``, header: "Success", callBack: () => {
                    closeConfirmCustom();
                    closeLoading();
                    closeConfirm();
                    closeConfirm();
                    setmodalregitrasiTeller(false);
                }});
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
    }

    // getData otorisasi
    async function getDataOtor(dbId) {
        openLoading()
        let url = `/kasteller/request_rekening_teller?db_id=${dbId}`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "trx"
            });
            if (statusCode === 200) {
                if (status === "00") {
                    // // console.log(data)
                    setFormData(prevState => ({
                        ...prevState,
                        id_user: data.user_id,
                        name : data.kode_cabang
                    }));
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        closeLoading();
        closeConfirm();
    }

    // response otorisasi
    function responseOtor(status) {
    let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
    openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (dataOtor !== null && dataOtor !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "", message="" } = await doPost({
                    url: `/otorisasi/${dataOtor.kode_entry}/${dataOtor.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    // url: `/otorisasi/${dataOtor.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "acc",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            // resetForm();
                            dataOtor.callBack?.()
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            setOtorDesc("");
                            setmodalregitrasiTeller(true);
                            dataOtor.callBackSuccess()
                        }
                    }); else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    function changeForm(value, key, subkey = null, grandSubKey = null) {
        // value = value.toUpperCase()
        if (grandSubKey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [grandSubKey]: { ...prevState[subkey][grandSubKey], [key]: value } } }))
        else if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    return {
        rtState:{
            modalregitrasiTeller,
            formData,
            otorMode
        },
        rtAction:{
            closeregisTeller,
            openregistrasiTeller,
            changeForm,
            postTeller,
            responseOtor,
            
            // getDataOtor
        }
    }
}

export default UseregitrasiTeller;
