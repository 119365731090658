import Skeleton from 'react-loading-skeleton';
import { StatusBadge } from '../../../../controls';

const SummaryCard = ({
    namaLengkap,
    status = 'ACTIVE',
    cabang,
    nameProduct,
    valuta,
    saldo,
    nomorRekening,
    isLoading,
}) => {
    return (
        <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-mb-6">
            <div className="tw-col-span-2  tw-border tw-border-black-40 tw-py-5 tw-px-6 tw-border-solid tw-rounded-lg">
                <div className="tw-flex tw-items-center tw-mb-5">
                    <div className="tw-text-black tw-font-bold tw-mr-2 tw-text-16">
                        {isLoading ? <Skeleton width={150} /> : namaLengkap}
                    </div>
                    {isLoading ? (
                        <Skeleton width={80} />
                    ) : (
                        <StatusBadge
                            color={
                                status === 'LOCKED'
                                    ? 'grey'
                                    : status === 'CLOSED'
                                    ? 'red'
                                    : status === 'DORMANT'
                                    ? 'orange'
                                    : 'green'
                            }
                        >
                            {status}
                        </StatusBadge>
                    )}
                </div>
                <div className="tw-grid tw-grid-cols-6 tw-items-center tw-gap-x-2 tw-gap-y-1 tw-text-black tw-text-12">
                    <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                        <span>Produk</span>
                        <span>:</span>
                    </span>
                    <div className="tw-col-span-2">
                        {isLoading ? <Skeleton width={150} /> : nameProduct}
                    </div>
                    <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                        <span>Cabang</span>
                        <span>:</span>
                    </span>
                    <div className="tw-col-span-2">
                        {isLoading ? <Skeleton width={150} /> : cabang}
                    </div>
                    <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                        <span>No Rek</span>
                        <span>:</span>
                    </span>
                    <div className="tw-col-span-2">
                        {isLoading ? <Skeleton width={150} /> : nomorRekening}
                    </div>
                    <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                        <span>Kode Valuta</span>
                        <span>:</span>
                    </span>
                    <div className="tw-col-span-2">
                        {isLoading ? <Skeleton width={150} /> : valuta}
                    </div>
                </div>
            </div>
            <div className="tw-border tw-border-black-40 tw-py-5 tw-px-6 tw-border-solid tw-rounded-lg">
                <p className="tw-text-black-40 tw-mb-5 tw-text-12">Saldo</p>
                <p className="tw-text-primary tw-font-bold tw-text-20">
                    {isLoading ? (
                        <Skeleton width={150} />
                    ) : (
                        new Intl.NumberFormat('id-ID', {
                            currency: valuta || 'IDR',
                            style: 'currency',
                        }).format(saldo)
                    )}
                </p>
            </div>
        </div>
    );
};

export default SummaryCard;
