import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { TableList } from '../../../../../controls';
import Skeleton from 'react-loading-skeleton';
import useRekening from '../useRekening';
import { createForm } from '../../../../../../module/createFormList';
import useDropdowns from '../../../../../../hooks/useDropdowns';

const ModalJoinAccount = () => {
    const { rekState, rekAction, jAction, jState } = useRekening();

    const { R_RELASI_MULTICIF } = useDropdowns(["R_RELASI_MULTICIF"]);

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalJoinAccount} onHide={rekAction.closeHoldDana} >
                <Modal.Header closeButton>
                    <Modal.Title>Join Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box mb-3">
                        <div className="row">
                            <div className="col">
                                <h4>{jAction.isLoading ? <Skeleton width={"50%"} height={30} /> : <>{jState.form?.nama_rekening}</>}</h4>

                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-4"> Produk </div>
                                            <div className="col">{jAction.isLoading ? <Skeleton height={20} /> : `: ${jState.form?.nama_produk}`}  </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4"> No. Rek </div>
                                            <div className="col">{jAction.isLoading ? <Skeleton height={20} /> : `: ${jState.form?.nomor_rekening}`}   </div>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-4"> Cabang </div>
                                            <div className="col">{jAction.isLoading ? <Skeleton height={20} /> : `: ${jState.form?.kode_cabang} - ${jState.form?.nama_cabang}`}</div>
                                        </div><div className="row">
                                            <div className="col-4">Kode Valuta </div>
                                            <div className="col">{jAction.isLoading ? <Skeleton height={20} /> : `: ${jState.form?.kode_valuta}`}  </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Saldo Rek </div>
                                            <div className="col">
                                                {
                                                    jAction.isLoading ?
                                                        <Skeleton height={20} /> :
                                                        <> :  <span className="float-right">{rekState.otorMode ? jState.form?.saldo_rekening?.toLocaleString() : jState.form?.saldo?.toLocaleString()} </span></>
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="row mt-3">
                                    <div className="col-6">
                                        {
                                            createForm({
                                                type: "select",
                                                label: "Tipe Join Account",
                                                placeholder: "Pilih Tipe Join Account",
                                                value: jState.form.tipe_join_account,
                                                disabled: rekState.otorMode,
                                                options: R_RELASI_MULTICIF,
                                                key: "reference_code",
                                                display: "reference_desc",
                                                onChange: (e) => jAction.setData({
                                                    tipe_join_account: e
                                                })
                                            })
                                        }
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <>
                       {!rekState.otorMode && <div className="row">
                            <div className="col">
                                <span className="float-left text-primary" style={{
                                    position: "absolute",
                                    bottom: "2px",
                                    fontWeight: "bold"
                                }}> Tambah Join Account </span>
                                <button className="btn btn-outline-success float-right" onClick={jAction.openModalAdd}>+ Tambah CIF</button>
                            </div>
                        </div>}
                        {/* <div className="row mt-4">
                            <div className="col-md-4">
                               
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col">
                                <TableList
                                    withCard={false}
                                    isLoading={jAction.isLoading}
                                    data={jState.form?._nasabahjoin || []}
                                    columns={[
                                        {
                                            name: 'Nomor CIF',
                                            field: 'nomor_nasabah',
                                            maxWidth: '300px',
                                            // className: "text-right",
                                            // onRender: (item) => {
                                            //     return item.nominal_hold?.toLocaleString() + " "
                                            // }
                                        },
                                        {
                                            name: 'Nama',
                                            field: 'nama_nasabah',
                                            maxWidth: '300px',

                                            // onRender: (item) => {
                                            //     return moment(item.tanggal_hold, "YYYY/MM/DD").format("DD-MM-YYYY")
                                            // }
                                        },
                                        {
                                            name: 'Tipe Join Account',
                                            field: 'tipe_join_account',
                                            maxWidth: '300px',
                                            onRender: (item) => {
                                                return R_RELASI_MULTICIF?.find(
                                                    ({
                                                        reference_code
                                                    })=>reference_code === item.tipe_join_account
                                                )?. reference_desc;
                                            }
                                        },
                                    ]}
                                    pagination={false}
                                    filter={false}
                                    withAction={true}
                                    actionItems={[
                                        ... (!rekState.otorMode ?
                                       [ {
                                             name: 'Hapus Data Join',
                                            // onClick: (e, i) => {
                                            //     jAction.hapusJoin(i)
                                            // },
                                            onClick: (e, i) => {
                                                jAction.confirmClose(i)
                                            },
                                            //onClick:() => {jAction.confirmClose()},
                                            className: "text-danger"
                                        } ]:[]),
                                    ]}
                                />
                            </div>
                        </div>
                        {/* form Join Account */}
                        <Modal size="xl" show={jState.formModal} onHide={jAction.closeModal}>
                            <Modal.Header>
                                <Modal.Title>
                                    <strong>Pencarian Data Nasabah</strong>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="pb-4">
                                    <p className="global-text">Cari data berdasarkan :</p>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <select name="" id="" value={jState.filter.filterType} onChange={(e) => jAction.setFilter(prevState => ({ ...prevState, filterType: e.target.value }))} className="form-control">
                                                    <option value={0}>Perorangan</option>
                                                    <option value={1}>Badan Usaha</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <input
                                                    value={jState.filter.keyword}
                                                    onChange={(e) => jAction.setFilter(prevState => ({ ...prevState, keyword: e.target.value }))}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nama Nasabah"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-1 p0">
                                            <button onClick={jAction.searchNasabah} className="btn btn-primary btn-block">Cari</button>
                                        </div>
                                        {/* <div className="col-5 p0">
                            </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            {
                                                createForm(
                                                    {
                                                        type: "select",
                                                        label: "Tipe Join Account",
                                                        placeholder: "Pilih Tipe Join Account",
                                                        value: jState.form?.tipe_join_account,
                                                        disabled: rekState.otorMode,
                                                        options: R_RELASI_MULTICIF,
                                                        required: true,
                                                        key: "reference_code",
                                                        display: "reference_desc",
                                                        onChange: (e) => jAction.setData({
                                                            tipe_join_account: e
                                                        })
                                                    }
                                                        //console.log(jState.form.tipe_join_account)
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <TableList
                                                withCard={false}
                                                isLoading={jState.loadingSearch}
                                                data={jState.listNasabah}
                                                columns={[
                                                    {
                                                        name: 'Nomor CIF',
                                                        field: 'nomor_cif',
                                                        maxWidth: '150px',
                                                    },
                                                    {
                                                        name: 'Nama CIF',
                                                        field: 'nama',
                                                        maxWidth: '150px',
                                                    },
                                                    {
                                                        name: 'Alamat',
                                                        field: 'alamat',
                                                        maxWidth: '150px',
                                                    },
                                                    {
                                                        name: 'Jenis Nasabah',
                                                        field: 'jenis_nasabah',
                                                        maxWidth: '75px',
                                                    },
                                                ]}
                                                pagination={true}
                                                filter={false}
                                                withAction={true}
                                                actionItems={[
                                                   {
                                                        name: 'Pilih Nasabah',
                                                        onClick: async (e) => {
                                                            if ( ((jState.form?.tipe_join_account !== "") & (jState.form?.tipe_join_account !== undefined))){
                                                                const get = await rekAction.pilihNasabah(e, jState.filter.filterType);
                                                                if (get) jAction.closeModal()
                                                            }else 
                                                            jAction.popUpJenis()
                                                        },
                                                        className: "text-danger"
                                                    } 
                                                ]}
                                                onChangeShow={jAction.changeShow}
                                                onNextPage={jAction.nextPage}
                                                onPrevPage={jAction.prevPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="row">
                                    <div className="col">
                                        <button onClick={jAction.closeModal} className="btn btn-sm float-right mr-2 btn-outline-secondary">
                                            Batal
                                        </button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        {/* end form */}
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button
                                onClick={() => rekState.holdModalMode ? rekAction.toggleModalMode() : rekAction.closeHoldDana()}
                                className="btn btn-outline-secondary float-right mr-2">
                                Tutup
                            </button>
                            {
                                !rekState.otorMode ? <>
                                    <button
                                        onClick={() => jAction.confirmTrx()}
                                        className="btn btn-primary float-right mr-2">
                                        Process
                                    </button>
                                </> : <>

                                    <button
                                        onClick={() => rekAction.responseOtor("APPROVED")}
                                        className="btn btn-success mr-2">
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => rekAction.responseOtor("REJECTED")}
                                        className="btn btn-danger mr-2">
                                        Reject
                                    </button>
                                </>

                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal utama */}
        </>
    );
}

export default ModalJoinAccount;