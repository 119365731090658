import React from 'react';
import { baseUrl } from './app.config';
import { paymentCardData } from './appConfig';
import {
    ContextProviderHook,
    ContextConnector,
    renderActionObject,
    yieldEventLoop,
} from './module/com.ihsan/appcontext';

const PERSISTENCE_KEY = 'todoapp';

var AppState = {
    //============= initial state of Setting =============
    loginState: true,
    loginWaitState: false,
    loginErrState: false,
    loginErrReason: '',

    firstLogin: true,
    header: { title: 'Administrasi Loket' },
    pelanggan: {},

    // MODAL
    modal: {},

    // TRANSAKSI
    isProsess: false,
    isSukses: false,
    openPembayaranCard: false,
    paymentCardData: paymentCardData,
    nominalList: [],
    nominalSelected: {},
    formData: {},
    globals: {}, // global object for storing shared libaries and functions
    modules: {}, // loaded modules from server
    currentPage: 1,
    showingPage: 15,
    alert: {
        show: false,
    },
    loadingPost: false,
};

var AppReducers = {
    getState: (state, { ref }) => {
        ref.state = { ...state };
    },
    restoreState: (state, { newState }) => ({ ...state, ...newState }),
    setGlobal: (state, { key, value }) => ({
        ...state,
        globals: { ...state.globals, [key]: value },
    }),
    setModule: (state, { moduleName, imports }) => ({
        ...state,
        modules: { ...state.modules, [moduleName]: imports },
    }),
    setForm: (state, { key, value }) => ({
        ...state,
        formData: { ...state.formData, [key]: value },
    }),
    resetForm: (state) => ({ ...state, formData: {} }),
    setProcess: (state, { value }) => ({ ...state, isProsess: value }),
    setSuccess: (state, { value }) => ({ ...state, isSukses: value }),
    paySukses: (state) => ({ ...state, isSukses: true, isProsess: false }),
    setNominalSelected: (state, { nominalSelected }) => ({ ...state, nominalSelected }),
    setNominalList: (state, { nominalList }) => ({ ...state, nominalList }),
    openPembayaranCard: (state, { openPembayaranCard }) => ({ ...state, openPembayaranCard }),
    setPelanggan: (state, { pelanggan }) => ({ ...state, pelanggan }),
    setHeader: (state, { header }) => ({ ...state, header }),
    setDasboard: (state, { firstLogin }) => ({ ...state, firstLogin }),
    setModal: (state, { modal }) => ({ ...state, modal }),
    fetchSuccess: (state, { key, data, list }) => ({
        ...state,
        [key]: list,
        loadingGet: false,
        ...data,
    }),
    nextPage: (state) => ({ ...state, currentPage: state.currentPage + 1 }),
    prevPage: (state) => ({ ...state, currentPage: state.currentPage - 1 }),
    changeShow: (state, { showingPage }) => ({ ...state, showingPage }),
    resetPagination: (state, {showingPage}) => ({ ...state, currentPage: 1, showingPage }),
    doFetch: (state) => ({ ...state, loadingGet: true }),
    fetchFailed: (state, { key }) => ({ ...state, loadingGet: false, [key]: [] }),
    setDefaultForm: (state, { formData }) => ({ ...state, formData }),
    closeAlert: (state) => ({ ...state, alert: { show: false } }),
    openAlert: (state, { message, tipe }) => ({ ...state, alert: { show: true, message, tipe } }),
    doPost: (state) => ({ ...state, loadingPost: true }),
    donePost: (state) => ({ ...state, loadingPost: false }),
};

const AppContext = React.createContext(null);

const ProviderComponent = ContextProviderHook(AppContext, AppReducers, AppState);

class AppProvider extends React.Component {
    constructor(props) {
        super(props);

        this.disp = null;
        this.meth = null;
    }

    render() {
        return (
            <ProviderComponent
                refDispatch={(dispatch, method) => {
                    this.disp = dispatch;
                    this.meth = method;
                }}
            >
                {this.props.children}
            </ProviderComponent>
        );
    }
}

const persistState = (state) => {
    return {
        todos: state.todos,
    };
};

class AppAction extends React.PureComponent {
    render() {
        // console.log("rendered")
        return renderActionObject(AppContext, this, (state) => {});
    }

    async storeState() {
        var state = await this.getState();
        const pState = persistState(state);
        // console.log(`state to store ${JSON.stringify(pState)}`);
        localStorage.setItem(PERSISTENCE_KEY, JSON.stringify(pState));
    }

    async getState() {
        var ref = {};
        await yieldEventLoop();
        this.disp({ type: 'getState', ref });
        return ref.state;
    }

    async wait(n) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), n);
        });
    }

    resetForm() {
        this.disp({ type: 'resetForm' });
    }

    setForm(key, value) {
        this.disp({ type: 'setForm', key, value });
    }

    async pay() {
        this.disp({ type: 'setProcess', value: true });
        await this.wait(5000);
        this.disp({ type: 'setProcess', value: false });
        this.disp({ type: 'setSuccess', value: true });
    }

    resetPay() {
        this.disp({ type: 'setProcess', value: false });
        this.disp({ type: 'setSuccess', value: false });
        this.disp({ type: 'resetForm' });
        this.setOpenPembayaran(false);
    }

    setNominal(nominalSelected) {
        this.disp({
            type: 'setNominalSelected',
            nominalSelected,
        });
    }
    setOpenPembayaran(openPembayaranCard) {
        this.disp({ type: 'openPembayaranCard', openPembayaranCard });
    }
    async register(data) {
        this.disp({ type: 'setProcess', value: true });
        await this.wait(2000);
        this.disp({ type: 'setProcess', value: false });
        this.disp({ type: 'setSuccess', value: true });
        this.disp({ type: 'setPelanggan', pelanggan: data });
    }
    setShowModal(modal) {
        this.disp({ type: 'setModal', modal });
    }
    setCloseModal() {
        this.disp({ type: 'setProcess', value: false });
        this.disp({ type: 'setSuccess', value: false });
        this.disp({ type: 'setModal', modal: {} });
    }

    nextPage() {
        return this.disp({ type: 'nextPage' });
    }

    prevPage() {
        return this.disp({ type: 'prevPage' });
    }

    changeShow(showingPage) {
        return this.disp({ type: 'changeShow', showingPage });
    }

    resetPagination(showingPage) {
        return this.disp({
            type: 'resetPagination',
            showingPage
        });
    }

    setDefaultForm(formData) {
        return this.disp({
            type: 'setDefaultForm',
            formData,
        });
    }

    async doGet({ param = {}, vari, url, withAlert = true }) {
        this.disp({ type: 'doFetch' });
        var uri = url;

        if (param != null) {
            var keys = Object.keys(param);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + param[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }

        let resp = await this.fetchApi(uri);
        // .then(resp => {
        const { statusCode, status, message_id, list } = resp;
        if (statusCode === 200) {
            this.disp({
                type: 'fetchSuccess',
                list,
                key: vari,
            });
        } else {
            if (message_id !== '01') {
                let tipe = 'failed';
                if (message_id === '01') tipe = 'warning';

                withAlert &&
                    this.disp({
                        type: 'openAlert',
                        message: JSON.stringify(status),
                        tipe,
                        // msgErr: resp.message
                    });

                this.disp({
                    type: 'fetchFailed',
                    key: vari,

                    // msgErr: resp.message
                });

                this.wait(3000).then((resp) => this.closeAlert());
            } else {
                let tipe = 'failed';
                if (message_id === '01') tipe = 'warning';

                withAlert &&
                    this.disp({
                        type: 'openAlert',
                        message: JSON.stringify(status),
                        tipe,
                        // msgErr: resp.message
                    });

                this.disp({
                    type: 'fetchFailed',
                    key: vari,

                    // msgErr: resp.message
                });

                this.wait(3000).then((resp) => this.closeAlert());
            }
        }

        return resp;
        // this.closeNotifAfterN(5000)
        // });
    }

    async doPost(
        { uri, data, type = 0, paramImage = '', withAlert = true },
        callback = () => null
    ) {
        var opts = {
            body: type === 0 ? JSON.stringify(data) : data,
            method: 'POST',
        };
        this.disp({
            type: 'doPost',
        });
        if (type == 1) {
            var keys = Object.keys(data);
            var form = new FormData();

            keys.forEach((val, key) => {
                if (val === 'img' && val === 'image' && val === paramImage) {
                    form.append(val, data[val], data[val].name);
                } else {
                    form.append(val, data[val]);
                }
                // console.log(form.values())
            });
            opts = {
                body: form,
                method: 'POST',
                'Content-Type': 'multipart/form-data',
            };
        }
        if (type == 2) {
            opts = {
                body: JSON.stringify(data),
                method: 'POST',
                'Content-Type': 'application/json',
                Accept: 'application/json',
            };
        }

        const resp = await this.fetchApi(uri, opts);
        // .then(resp => {
        const { statusCode, status, message_id, list } = resp;
        this.disp({
            type: 'donePost',
        });
        let tipe = 'success';
        if (message_id === '01') tipe = 'warning';
        if (message_id === '02') tipe = 'success';
        withAlert &&
            this.disp({
                type: 'openAlert',
                message: status,
                tipe,
                // msgErr: resp.message
            });

        this.wait(3000).then((resp) => this.closeAlert());
        // this.closeNotifAfterN(5000)
        callback();
        return resp;
        // });
    }

    async doPut({ uri, data, type = 0, paramImage = '' }, callback = () => null) {
        var opts = {
            body: type === 0 ? JSON.stringify(data) : data,
            method: 'PUT',
        };
        this.disp({
            type: 'doPost',
        });
        if (type == 1) {
            var keys = Object.keys(data);
            var form = new FormData();

            keys.forEach((val, key) => {
                if (val === 'img' && val === 'image' && val === paramImage) {
                    form.append(val, data[val], data[val].name);
                } else {
                    form.append(val, data[val]);
                }
                // console.log(form.values())
            });
            opts = {
                body: form,
                method: 'PUT',
                'Content-Type': 'multipart/form-data',
            };
        }
        if (type == 2) {
            opts = {
                body: JSON.stringify(data),
                method: 'PUT',
                'Content-Type': 'application/json',
                Accept: 'application/json',
            };
        }

        const resp = await this.fetchApi(uri, opts);
        // .then(resp => {
        const { statusCode, status, message_id, list } = resp;
        this.disp({
            type: 'donePost',
        });
        let tipe = 'success';
        if (message_id === '01') tipe = 'warning';
        if (message_id === '02') tipe = 'success';
        this.disp({
            type: 'openAlert',
            message: status,
            tipe,
            // msgErr: resp.message
        });

        this.wait(3000).then((resp) => this.closeAlert());
        // this.closeNotifAfterN(5000)
        callback();
        return resp;
        // });
    }

    closeAlert() {
        this.disp({
            type: 'closeAlert',
        });
    }
    async fetchApi(
        url,
        opts = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        typeResponse = 'json'
    ) {
        // const abortController = new AbortController();
        // const signal = abortController;
        // const [token, isRefresh] = await getToken(signal)

        // if (isRefresh) {
        //     const authentication = {
        //         isAuthenticated: true,
        //         token: token,
        //         payload: jwt_decode(token)
        //     }
        //     this.disp({
        //         type: "loginSuccess",
        //         authentication
        //     })
        // }

        // !opts.headers && (opts.headers = {});
        // merge(opts.headers, {
        //     'Authorization': 'bearer ' + token
        // })

        var data = {};
        var fetchResponse = await fetch(`${baseUrl + url}`, opts);
        if (fetchResponse.status === 401 || fetchResponse.status === 201) {
            // this.doLogout();
            // return fetchResponse
        }

        if (fetchResponse.status !== 200) {
            data = await fetchResponse.json();
        } else {
            if (typeResponse == 'blob') {
                data = await fetchResponse.blob();
                // console.log("res")
            } else {
                data = await fetchResponse.json();
            }
        }

        // console.log(data)
        return {
            statusCode: fetchResponse.status,
            ...(Array.isArray(data) ? { list: data } : data),
        };
    }
}

// Only restore state if there's no deep link and we're not on web
const loadState = async () => {
    const savedStateString = localStorage.getItem(PERSISTENCE_KEY);
    return savedStateString ? JSON.parse(savedStateString) : undefined;
};

// standard "templates" for visual components to connect
const AppInterfaces = {
    appLoad: ContextConnector(
        AppContext,
        (state, props) => ({
            store: state,
        }),
        (disp) => ({
            loadStore: async () => {
                const state = await loadState();
                if (state !== undefined) {
                    disp({ type: 'restoreState', newState: state });
                }
            },

            saveStore: async () => {
                var ref = {};
                await yieldEventLoop();
                disp({ type: 'getState', ref });
                const pState = persistState(ref.state);
                localStorage.setItem(PERSISTENCE_KEY, JSON.stringify(pState));
            },
        })
    ),
};

export { AppProvider, AppContext, AppAction, AppInterfaces };
