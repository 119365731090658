import { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Label, Button } from 'semantic-ui-react';
import { baseUrl } from '../../../../../app.config';
import { getErrorEmptyValue } from '../../../../utils';
import { FormHeader } from '../../../../controls';

const SpecimenPejabatModal = ({ show, onHide, onSubmit, type = 'registrasi', initialState }) => {
    const [state, setState] = useState({ namaLengkap: '', keterangan: '', jabatan: '' });
    const [error, setError] = useState({});

    useEffect(() => {
        if (type === 'edit') setState(initialState);
    }, [type, initialState]);

    const { data: jabatanOptions } = useQuery('jabatan-options', async () => {
        const res = await fetch(baseUrl + '/dropdowns?key=R_JABATAN_PEKERJAAN');

        const data = await res.json();

        return data?.list || [];
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (error[name]) setError({ ...error, [name]: false });

        setState({
            ...state,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        const { err, isAnyEmptyError } = getErrorEmptyValue(state);

        setError(err);

        if (isAnyEmptyError) return;

        if (onSubmit) onSubmit(state);
        setState({ namaLengkap: '', keterangan: '', jabatan: '' });
        if (onHide) onHide();
    };

    const handleHide = () => {
        setError({});
        setState({ namaLengkap: '', keterangan: '', jabatan: '' });

        if (onHide) onHide();
    };

    return (
        <Modal size="xl" centered show={show}>
            <Modal.Header onHide={handleHide} style={{ padding: '16px 32px' }} closeButton>
                <Modal.Title>
                    <h5 className="tw-text-18 tw-font-bold">
                        {type === 'registrasi' ? 'Tambah' : 'Ubah'} Specimen Pejabat
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '16px 32px' }}>
                <FormHeader className="mb-4">Data Diri</FormHeader>
                <Form className="tw-grid tw-grid-cols-2 tw-gap-x-12">
                    <Form.Group>
                        <Form.Label className="font-weight-normal f-12">
                            Nama
                            <Label className="ml-2" size="mini">
                                Wajib
                            </Label>
                        </Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            value={state?.namaLengkap}
                            name="namaLengkap"
                            size="sm"
                            placeholder="Nama pejabat"
                            isInvalid={error?.namaLengkap}
                        />
                        <Form.Control.Feedback type="invalid">
                            Nama wajib diisi
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="tw-row-span-2">
                        <Form.Label className="font-weight-normal f-12">
                            Keterangan
                            <Label className="ml-2" size="mini">
                                Wajib
                            </Label>
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            size="sm"
                            rows={3}
                            placeholder="Keterangan"
                            value={state?.keterangan}
                            onChange={handleChange}
                            name="keterangan"
                            isInvalid={error?.keterangan}
                        />
                        <Form.Control.Feedback type="invalid">
                            Keterangan wajib diisi
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="font-weight-normal f-12">
                            Jabatan
                            <Label className="ml-2" size="mini">
                                Wajib
                            </Label>
                        </Form.Label>
                        <Form.Control
                            value={state?.jabatan}
                            name="jabatan"
                            onChange={handleChange}
                            as="select"
                            size="sm"
                            isInvalid={error?.jabatan}
                        >
                            <option value="" hidden selected disabled>
                                Pilih Jabatan
                            </option>
                            {jabatanOptions?.map(({ reference_desc, reference_code }) => (
                                <option
                                    key={reference_code}
                                    value={`${reference_code} - ${reference_desc}`}
                                >
                                    {reference_desc}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Jabatan wajib diisi
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleHide} className="mr-2 text-white" size="mini">
                    Batal
                </Button>
                <Button onClick={handleSubmit} color="blue" size="mini">
                    Simpan
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SpecimenPejabatModal;
