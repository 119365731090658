import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { addCommas } from '../../../../../module';
import { DateInput, NewLoading, TableList } from '../../../../controls';
import useModalDetilTrx, { ModalDetilTrxProv } from '../component/modalDetilTrx';
import useTrxHistori, { TrxHistoriProvider } from './useTrxHistori';

function TrxHistori() {
    const { historiState, historiAction } = useTrxHistori()
    
    const { mtAction, mtState } = useModalDetilTrx();
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Informasi Transaksi</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Histori
                    </li>
                </ol>
            </nav>

           
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                INFORMASI TRANSAKSI HISTORI
                            </div>
                        </div>
                        <div className="title">
                        <div className="mb-2 mt-2 ml-2">
                            <div className="row">
                                <div className="col-4 align-self-center bns-form">
                                    <div className="form-group">
                                    <label>Tanggal Awal</label>
                                        <DateInput
                                            className="form-control"
                                            type="date"
                                            placeholder="Tanggal Awal"
                                            value={historiState.filter.tgl_awal}
                                            onChange={(value) => {
                                                historiAction.setFilter(prevState => ({ ...prevState, tgl_awal: value }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 align-self-center bns-form">
                                    <div className="form-group">
                                    <label>Tanggal Akhir</label>
                                        <DateInput
                                            className="form-control"
                                            type="date"
                                            placeholder="Tanggal Akhir"
                                            value={historiState.filter.tgl_akhir}
                                            onChange={(value) => {
                                                historiAction.setFilter(prevState => ({ ...prevState, tgl_akhir: value }));
                                            }} 
                                        />
                                    </div>
                                </div>
                                <div className="col-4 align-self-center bns-form">
                                    <div className="form-group pt-4 mt-1">
                                        <button onClick={historiAction.getDataList} className="btn btn-outline-success">
                                            Cari
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                minHeight="450px"
                                maxHeight="500px"
                                onChangeShow={historiAction.changeShow}
                                onNextPage={historiAction.nextPage}
                                onPrevPage={historiAction.prevPage}
                                withActions={false}
                                columns={[
                                    {
                                        name: 'Jam Input',
                                        field: 'jam_input',
                                    },
                                    {
                                        name: 'Nilai Mutasi',
                                        field: 'nilai_mutasi',  
                                        className:"text-right",
                                        onRender: (item) => addCommas(parseFloat(item.nilai_mutasi))
                                    },
                                    {
                                        name: 'Referensi',
                                        field: 'nomor_referensi',
                                        width: '40px',
                                    },
                                    {
                                        name: 'Status Otorisasi',
                                        field: 'status_otorisasi',
                                    },
                                    {
                                        name: 'Status Reversal',
                                        field: 'is_reversal',
                                        onRender: ({is_reversal =""}) => (is_reversal === "T").toString().toUpperCase()
                                    },
                                    {
                                        name: 'User Input',
                                        field: 'user_input',
                                    },
                                    {
                                        name: 'User Otorisasi',
                                        field: 'user_otor',
                                    },
                                    {
                                        name: 'Status Webhook Bifast',
                                        field: 'sts_webhook_bifast_desc', 
                                    },
                                    {
                                        name: 'Keterangan',
                                        field: 'keterangan',
                                    },
                                    {
                                        name: "",
                                        onRender: (item) => item.id_transaksi_core !== null &&
                                        <>
                                            <button
                                                onClick={() => mtAction.openModal(parseInt(item.id_transaksi_core))}
                                                style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                                className="btn btn-sm btn-outline-success"
                                            >
                                                <b>Detail</b>
                                            </button>
                                        </>
                                    }
                                ]}
                                data={historiState.dataList}
                                isLoading={historiState.isLoading}
                                filter={false}
                                resetFilter={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default () => <ModalDetilTrxProv> <TrxHistoriProvider><TrxHistori /></TrxHistoriProvider></ModalDetilTrxProv>;