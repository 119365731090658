// import { useKeycloak } from "@react-keycloak/web";
import React, { useState, useEffect, createContext, useContext } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { useModalAlert } from "../../../../controls/modalAlert";
import { useLocalStorage } from '../../../../../module';
import useJoinAccount from "../../tabungan-giro/data_rekening/modal/useJoinAccount";
import moment from "moment";
import useKeycloak from "../../../../../hooks/keycloak.module";

const lapContext = createContext(null);

export const LaporanProvide = ({ children }) => {
  // const { jAction, jState } = useJoinAccount();

  // Modal State
  const [modalCetakLaporan, setModalCetakLaporan] = useState(false);
  const { modalAction, modalState } = useModalAlert();
  const { setDataAlert } = modalAction;

  // Function State
  const { doGet, doPost, wait } = useFetch();
  const { keycloak } = useKeycloak();
  const [pagination, setPagination] = useState({ page: 1, show: 15 });

  // Loading State
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCetakLaporan, setLoadingCetakLaporan] = useState(false);

  // Data State
  const [podDate, setPodDate] = useLocalStorage("POD_DATE")
  const [dataLaporan, setDataLaporan] = useState([]);
  const [jenisLaporan, setJenisLaporan] = useState("nama_report");
  const [keyLaporan, setKeyLaporan] = useState("");
  const [kode_cabang,setKodeCabang] = useState('000')
  const [listKodeCabang, setListKodeCabang] = useState([]);
  const [dataCetakLaporan, setDataCetakLaporan] = useState({
    start_date: moment(new Date()).format("YYYY-MM-DD"),
    end_date: moment(new Date()).format("YYYY-MM-DD"),
    branch_code: "",
  });

  // // // console.log(keycloak)

  const getDataLaporan = async () => {
    const userInfo = await keycloak.loadUserInfo();
    // // console.log(keycloak.realmAccess.roles)
    setIsLoading(true);
    try {
      const {
        statusCode,
        status,
        remark,
        statusText,
        data = {},
      } = await doPost({
        url: "/rpc/search",
        service: "rpc",
        data: { ...pagination, jenis: jenisLaporan, name: keyLaporan, id_peran : keycloak.realmAccess.roles },
        headers: {
          // 'Content-Type': 'application/json',
          Accept: "application/json",
          "x-user-id": userInfo.preferred_username,
        },
      });

      if (statusCode === 200) {
        if (status === "00") {
          setDataLaporan(data);
        } else
        setDataAlert({
          show: true,
          body: remark,
          title: "",
          header: "INFO",
          callBack: () => null,
        });
      } 
      setKodeCabang(userInfo.branch_code || "000")
    } catch (error) {
      setDataAlert({
        show: true,
        body: error.toString(),
        title: "Terjadi Kesalahan",
        header: "Error",
        callBack: () => null,
      });
    }
    setIsLoading(false);
  };

  function resetFilter() {
    setKeyLaporan("");
    getDataLaporan();
  }
  // next pagination
  function nextPage({ page, limit }) {
    setPagination({
      page,
      show: limit,
    });
  }

  // prev pagination
  function prevPage({ page, limit }) {
    setPagination({
      page,
      show: limit,
    });
  }
  // change show
  function changeShow({ page, limit }) {
    setPagination({
      page,
      show: limit,
    });
  }

  // open Modal Cetak laporan
  async function openCetakLaporan(data) {
    setDataCetakLaporan((prev) => ({
      ...prev,
      ...data,
      branch_code: keycloak.tokenParsed.branch_code,
      start_date:podDate,
      end_date:podDate
    })); 
    getListCabang();
    setModalCetakLaporan(true);
  }
  // close Modal Cetak Laporan
  async function closeCetakLaporan() {
    setModalCetakLaporan(false);
  }
  // get List Cabang
  async function getListCabang() {
    try {
      const {
        statusCode,
        status,
        remark,
        statusText,
        data = {},
      } = await doGet({
        url: "/rpc/list-kode-cabang",
        service: "rpc",
      });

      if (statusCode === 200) {
        if (status === "00") {
          setListKodeCabang([...data, { kode_cabang: "ALL", nama_cabang: "Semua Cabang" }]);
        } else
          setDataAlert({
            show: true,
            body: remark,
            title: `Status fetch data is ${statusCode}`,
            callBack: () => null,
          });
      } else if (statusCode !== 401)
        setDataAlert({
          show: true,
          body: remark,
          title: `${JSON.stringify(statusText)}`,
          header: "Error",
          callBack: () => null,
        });
    } catch (error) {
      setDataAlert({
        show: true,
        body: error.toString(),
        title: "Terjadi Kesalahan",
        header: "Error",
        callBack: () => null,
      });
    }
  }
  // get Data Cetak laporan
  async function cetakLaporan() {
    setLoadingCetakLaporan(true);
    let { kode_report, start_date, end_date, branch_code } = dataCetakLaporan;
    const userInfo = await keycloak.loadUserInfo();
    try {
      const {
        statusCode,
        status,
        remark,
        statusText,
        data = {},
      } = await doPost({
        url: "/rpc/cetak-report",
        service: "rpc",
        data: {
          code: kode_report,
          start_date,
          end_date,
          branch_code:branch_code ? branch_code : "",
          db_id: 0,
          user_id: keycloak.userInfo.preferred_username,
          limit: 0,
          page: 0,
          params: ["string"],
        },
        headers: {
          // 'Content-Type': 'application/json',
          Accept: "application/json",
          "x-user-id": userInfo.preferred_username,
        },
      });

      if (statusCode === 200) {
        if (status === "00") {
          await window.open(data.url);
          setModalCetakLaporan(false);
        } else
          setDataAlert({
            show: true,
            body: remark,
            title: `Status fetch data is ${statusCode}`,
            callBack: () => null,
          });
      } else if (statusCode !== 401)
        setDataAlert({
          show: true,
          body: remark,
          title: `${JSON.stringify(statusText)}`,
          header: "Error",
          callBack: () => null,
        });
    } catch (error) {
      setDataAlert({
        show: true,
        body: error.toString(),
        title: "Terjadi Kesalahan",
        header: "Error",
        callBack: () => null,
      });
    }
    setLoadingCetakLaporan(false);
  }

  useEffect(() => {
    getDataLaporan();
  }, [pagination, jenisLaporan]);
//Post Auditrail
async function auditTrail() {
  const userInfo = await keycloak.loadUserInfo();
  // console.log(userInfo)
  var url = `/useraudit/user-audit-trail`;
  try {
      const { statusCode, status, remark, statusText, data = {} } = await doPost({
          url: url,
          service: "acc",
          data: { 
                  user_id : userInfo.preferred_username,
                  app_id : "BDS",
                  terminal_ip: window.location.hostname,
                  operation_code: "Get Data List",
                  event_description: "Laporan/List Laporan",
                  info1: "-",
                  info2: "-"
          },
          headers: {
              "x-user-id": userInfo.preferred_username,
              method: "POST"
          }
      });

      if (statusCode === 200) {
          if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
          else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
      } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
  } catch (error) {
      setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
  }
  
}
  return (
    <lapContext.Provider
      value={{
        lapState: {
          dataCetakLaporan,
          dataLaporan,
          isLoading,
          jenisLaporan,
          // jState,
          keyLaporan,
          kode_cabang,
          listKodeCabang,
          loadingCetakLaporan,
          modalCetakLaporan,
          pagination,
        },
        lapAction: {
          cetakLaporan,
          changeShow,
          closeCetakLaporan,
          getDataLaporan,
          nextPage,
          openCetakLaporan,
          prevPage,
          resetFilter,
          setDataCetakLaporan,
          setJenisLaporan,
          setKeyLaporan,
          setLoadingCetakLaporan,
          setPagination,
          auditTrail
        },
        // jState,
        // jAction,
      }}>
      {children}
    </lapContext.Provider>
  );
};

export default function useLaporan() {
  return useContext(lapContext);
}
