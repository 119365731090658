import moment from 'moment';
import React from 'react';
import { forwardRef } from 'react';
import { useHistory, useParams } from 'react-router';
import useKeycloak from '../../../../../hooks/keycloak.module';
import { addCommas } from '../../../../../module';
import { TableList } from '../../../../controls';
import useInfoBerankas from '../useBerankas';


const PrintArea = forwardRef(({ }, ref) => {
    const { ibAction, ibState } = useInfoBerankas();
    const { mode } = useParams();
    const history = useHistory();

    function sortingDenom(dataDenom = [], namaDenom) {
        return dataDenom.filter(({ jenis = "" }) => (
            jenis.toUpperCase() === namaDenom.toUpperCase()
        )).sort(function (a, b) {
            return b.nilai_denominasi - a.nilai_denominasi;
        })
    }

    const { keycloak } = useKeycloak();
    return (
        < div className="print-hide">

            <div className="row" ref={ref}>
                <div className="col-12">
                    {/* <div className="card">
                        <div className="card-body"> */}
                    <div id="printArea">
                        {/* <h4 className="text-center"><u>BUKTI SERAH TERIMA KAS</u></h4> */}
                        <div className="row">
                            <div className="col-3"> Tanggal Cetak </div>
                            <div className="col"> : {moment(Date.now()).format("DD-MM-YYYY")} </div>
                        </div>
                        <div className="row">
                            <div className="col-3"> User ID </div>
                            <div className="col"> : {keycloak?.idTokenParsed?.preferred_username} - {keycloak?.idTokenParsed?.name}   </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-3"> Kode Cabang </div>
                            <div className="col"> : {keycloak?.idTokenParsed?.branch_code} </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-3"> Diserahkan Oleh </div>
                            <div className="col"> :  </div>
                        </div>
                        <div className="row">
                            <div className="col-3"> Diterima Oleh </div>
                            <div className="col"> :  </div>
                        </div> */}

                        <div className="row">
                            <div className="col-3"> Valuta </div>
                            <div className="col"> : IDR </div>
                        </div>

                        <div className="row">
                            <div className="col-3"> Total Saldo </div>
                            <div className="col"> : {addCommas(parseFloat(ibState.dataBerangkas?.saldo || "0").toFixed(2)) || 0}</div>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table table-bordered table-sm" >
                                <thead>
                                    <tr>
                                        <th colSpan={3} className="text-center">
                                            RINCIAN NOMINAL KAS
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="text-center">
                                            PECAHAN
                                        </th>

                                        <th className="text-center">
                                            JUMLAH
                                        </th>

                                        <th className="text-center">
                                            DENOM * JUMLAH
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={3} style={{
                                            fontWeight: "bold",
                                            fontSize: "12pt"

                                        }}>
                                            Uang Kertas
                                        </td>
                                    </tr>
                                    {
                                        (sortingDenom(ibState.denomList || [], "lembar") || []).map(({
                                            jenis = "",
                                            jumlah_lembar_koin = 0,
                                            nilai_denominasi = 5,
                                            total_nominal = 0
                                        }, i) =>
                                            <tr key={jenis + i}>
                                                <td className="text-right" >
                                                    {nilai_denominasi}
                                                </td>
                                                <td className="text-right">
                                                    {jumlah_lembar_koin}
                                                </td>
                                                <td className="text-right">
                                                    {addCommas(parseFloat(total_nominal).toFixed(2))}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td colSpan={2} className="text-right" style={{
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}>
                                            Jumlah
                                        </td>
                                        <td className="text-right" style={{
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}>
                                            {
                                                addCommas(parseFloat(
                                                    (sortingDenom(ibState.denomList || [], "lembar") || []).reduce((a, b) => {
                                                        // console.log(a, b.total_nominal)
                                                        return parseFloat(a) + parseFloat(b.total_nominal)
                                                    }, 0)
                                                ).toFixed(2))
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3} style={{
                                            fontWeight: "bold",
                                            fontSize: "12pt"

                                        }}>
                                            Uang Logam
                                        </td>
                                    </tr>
                                    {
                                        (sortingDenom(ibState.denomList || [], "koin") || []).map(({
                                            jenis = "",
                                            jumlah_lembar_koin = 0,
                                            nilai_denominasi = 5,
                                            total_nominal = 0
                                        }, i) =>
                                            <tr key={jenis + i}>
                                                <td className="text-right" >
                                                    {nilai_denominasi}
                                                </td>
                                                <td className="text-right">
                                                    {jumlah_lembar_koin}
                                                </td>
                                                <td className="text-right">
                                                    {addCommas(parseFloat(total_nominal).toFixed(2))}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td colSpan={2} className="text-right" style={{
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}>
                                            Jumlah
                                        </td>
                                        <td className="text-right" style={{
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}>
                                            {
                                                addCommas(parseFloat(
                                                    (sortingDenom(ibState.denomList || [], "koin") || []).reduce((a, b) => {
                                                        // console.log(a, b.total_nominal)
                                                        return parseFloat(a) + parseFloat(b.total_nominal)
                                                    }, 0)
                                                ).toFixed(2))
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3} style={{
                                            fontWeight: "bold",
                                            fontSize: "12pt"

                                        }}>
                                            Uang Lusuh
                                        </td>
                                    </tr>
                                    {
                                        (sortingDenom(ibState.denomList || [], "lembar (utle)") || []).map(({
                                            jenis = "",
                                            jumlah_lembar_koin = 0,
                                            nilai_denominasi = 5,
                                            total_nominal = 0
                                        }, i) =>
                                            <tr key={jenis + i}>
                                                <td className="text-right" >
                                                    {nilai_denominasi}
                                                </td>
                                                <td className="text-right">
                                                    {jumlah_lembar_koin}
                                                </td>
                                                <td className="text-right">
                                                    {addCommas(parseFloat(total_nominal).toFixed(2))}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td colSpan={2} className="text-right" style={{
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}>
                                            Jumlah
                                        </td>
                                        <td className="text-right" style={{
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}>
                                            {
                                                addCommas(parseFloat(
                                                    (sortingDenom(ibState.denomList || [], "lembar (utle)") || []).reduce((a, b) => {
                                                        // console.log(a, b.total_nominal)
                                                        return parseFloat(a) + parseFloat(b.total_nominal)
                                                    }, 0)
                                                ).toFixed(2))
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="row mt-5">
                            <div className="col-6">

                                <div style={{
                                    borderTop: "1px solid black",
                                    margin: "auto",
                                    marginTop: 90,
                                    width: "60%",
                                    textAlign: "center",
                                    paddingTop: 0
                                }}>
                                    {keycloak?.idTokenParsed?.preferred_username} - {keycloak?.idTokenParsed?.name}
                                </div>
                            </div>
                            <div className="col-6">
                                <div style={{
                                    borderTop: "1px solid black",
                                    margin: "auto",
                                    textAlign: "center",
                                    marginTop: 90,
                                    paddingTop: 0,
                                    width: "60%"
                                }}>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row mt-4">
                            <div className="col-6">

                                <div style={{
                                    borderBottom: "1px solid black",
                                    margin: "auto",
                                    marginTop: 90,
                                    width: "80%"
                                }}>

                                </div>
                            </div>
                            <div className="col-6">
                                <div style={{
                                    borderBottom: "1px solid black",
                                    margin: "auto",
                                    marginTop: 90,
                                    width: "80%"
                                }}>

                                </div>
                            </div>
                        </div> */}
                        {/* <TableList
                                    withCard={false}
                                    pagination={false}
                                    filter={false}
                                    withActions={false}
                                    maxHeight="1500px"
                                    columns={[
                                        {
                                            name: 'Nilai Denominasi',
                                            field: 'nilai_denominasi',
                                            className: "text-right",
                                            onRender: (item) => addCommas(item.nilai_denominasi)
                                        },
                                        {
                                            name: 'Jenis',
                                            field: 'jenis',
                                        },
                                        {
                                            name: 'Jumlah Lembar',
                                            field: 'jumlah_lembar_koin',
                                            className: 'text-right',
                                            maxWidth: "100px",
                                            onRender: (item, key) => (
                                                addCommas(item.jumlah_lembar_koin)
                                                // <input
                                                //     type="text"
                                                //     value={addCommas(item.jumlah_lembar_koin)}
                                                //     readOnly={!ibState.formEditable}
                                                //     className="form-control form-control-sm"
                                                //     onChange={(e) => cdAction.changeJumlahLembar(removeNonNumeric(e.target.value), key)}
                                                // />
                                            )
                                        },
                                        {
                                            name: 'Total Nominal',
                                            field: 'total_nominal',
                                            className: "text-right",
                                            onRender: (item) => <> {addCommas(item?.total_nominal || "0")}, -</>
                                        },
                                    ]}
                                    data={
                                        [
                                                   
                                            ...( sortingDenom( ibState.denomList || [], "lembar") || []),
                
                                            ...( sortingDenom( ibState.denomList || [], "koin") || []),
                                            
                                            ...( sortingDenom( ibState.denomList || [], "lembar (utle)") || []),
                                        ] 
                                    }

                                /> */}
                    </div>
                    {/* <div className="card-footer">
                                <div className="row">
                                    <div className="col-12">
                                        <button className="btn btn-primary float-right">
                                            <i className="fa fa-print"></i> Cetak
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                </div>
                {/* </div>
                </div> */}
            </div>
        </div >
    );
})

export default PrintArea;