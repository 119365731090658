import React from "react";
import { Modal } from "react-bootstrap";
import { FormRegistration } from "../../../../../controls";
import useRekening from "../useRekening";

const ModalKartuVirtual = () => {
    const { rekState, rekAction } = useRekening();
    return (
        <>
            <Modal size={"lg"}
            show={rekState.modalKartuVirtual}
            onHide={rekAction.closeModalKartuVirtual}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Kartu Virtual</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormRegistration
                        itemsForm={[
                            {
                                name:"Data Rekening Kartu Virtual",
                                rows:[
                                    {
                                        rows    : 1,
                                        label   : 'Nama Rekening',
                                        type    : 'text',
                                        value   : rekState.rekSelected?.nama_rekening,
                                        readOnly:true
                                    },
                                    {
                                        rows    : 1,
                                        label   : 'No. Rekening',
                                        type    : 'text',
                                        value   : rekState.rekSelected?.nomor_rekening,
                                        readOnly:true
                                    },
                                    {
                                        rows    : 1,
                                        label   : 'Cabang',
                                        type    : 'text',
                                        value   : rekState.rekSelected?.nama_cabang,
                                        readOnly:true
                                    },
                                    {
                                        rows    : 2,
                                        label   : 'Produk Rekening',
                                        type    : 'text',
                                        value   : rekState.rekSelected?.nama_produk,
                                        readOnly:true
                                    },
                                    {
                                        rows    : 2,
                                        label   : 'Kode Valuta',
                                        type    : 'text',
                                        value   : rekState.rekSelected?.valuta,
                                        readOnly:true
                                    },
                                    {
                                        rows    : 2,
                                        label   : 'Tanggal Buka Rekening',
                                        type    : 'text',
                                        value   : rekState.rekSelected?.tanggal_buka || `-`,
                                        readOnly:true
                                    }
                                ]
                            }
                        ]}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button
                                onClick={() => rekAction.closeModalKartuVirtual()}
                                className="btn btn-outline-danger float-right">
                                Batal
                            </button>
                            <button
                                onClick={() => rekAction.confirmKartuVirtual(rekState.rekSelected?.nomor_rekening)}
                                className="btn btn-outline-success mr-2">
                                Submit
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ModalKartuVirtual;