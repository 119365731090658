import { uniqueId } from 'lodash-es';
import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useNexusPrint from '../../../hooks/useNexusPrint';
import { addCommas, terbilang, useLocalStorage } from '../../../module';
import { useModalAlert } from '../../controls/modalAlert';

const setupContext = createContext({
    setupAction: {},
    setupState: {}
});

export function SetupProvider({ children }) {
    const [printServer, setPrintServer] = useLocalStorage("printServer", "http://localhost:998");
    const [serverNexus, setServerNexus] = useState("");
    const { modalState, modalAction } = useModalAlert();
    const { print } = useNexusPrint();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { wait } = useFetch()

    useState(function () {
        setServerNexus(printServer)
    }, [printServer])

    async function printDemo(data) {
        openLoading()
        try {
            const dataResponse = await print(data);
            if (dataResponse.statusCode === 500) {
                closeLoading()
                return setDataAlert({ show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Error", callBack: () => null });
            } else {
                closeLoading()
                setDataAlert({
                    show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Berhasil", callBack: () => {
                        closeAlert();
                        closeLoading();
                    }
                });
            }
        } catch (error) {
            closeLoading()
            return setDataAlert({
                show: true, body: "Gagal koneksi ke print server. Periksa kembali koneksi print server", title: ``, header: "Info", callBack: () => {
                    closeAlert();
                    closeLoading()
                }
            });
        }
    }

    function rtgs() {
        printDemo({
            command: "PRINT",
            medianame: "BJBS",
            formname: "Validasi RTGS-KLIRING",
            print: 1,
            history: 0,
            // baris 1
            nomorArsip: "NOMORARSIP", // new
            kodeCabang: "001",
            UserTeller: "DEM0",
            tglTransaksi: moment(Date.now()).format("DD-MM-YYYY"),
            userOtoSPV: "OTORDEMO",
            // baris 2
            kodeCabangKredit: "001",
            norekIAKredit: "214263842",
            kodeSetoran: "",
            nominalKredit: addCommas(parseFloat(900000 || "0").toFixed(2)),
            kredit: "K",
            //  baris 3
            kodeCabangDebit: "001",
            noRekIADebit: "4363682342",
            nominalDebit: addCommas(parseFloat(900000 || "0").toFixed(2)),
            // norekIAKredit: "", //new
            kodeDebit: "D",
            // baris 4
            namaRekeningKredit: "REK KREDIT",
            kodeTransaksiKirimUang: "KIRDEMO",
            keteranganTransaksi: "KET TRX",
            validasiTransfer: "VAL TRF",
            berita: "BERITA SKN DEMO",
            tglTransaksi: moment(Date.now()).format("DD-MM-YYYY"),
            jamTransaksi: moment(Date.now()).format("HH:mm:ss"),
            // baris 5
            kodeCabangKreditBiaya: "001",
            noRekJasaLayananTransfer: "REK JASA L TF", //new
            kodeTransaksiSetoran: "KDSTR", //new
            biayaJasaKirim:  addCommas(parseFloat(9000 || "0").toFixed(2)),
            kodeKredit: "K",
            // baris 6
            kodeCabangDebitBiaya: "001", //new
            noRekKasTeller: "rektel0",
            biayaJasaKliring: addCommas(parseFloat(2000 || "0").toFixed(2)),
            kodeDebit: "D",
            // baris 7
            namaRek: "PENERIMA DEMO",
            keteranganBiaya: "KETBIAYA", //new
            kodeTRF: "KODETRF",
            berita: "BERITA SKN DEMO",
            tglTransaksiBiaya: moment(Date.now()).format("DD-MM-YYYY"),
            jamTransaksiBiaya: moment(Date.now()).format("HH:mm:ss"),
        })
    }

    function demoSKN() {

        printDemo({
            command: "PRINT",
            medianame: "BJBS",
            formname: "Validasi RTGS-KLIRING",
            print: 1,
            history: 0,
            // baris 1
            nomorArsip: "NOMORARSIP", // new
            kodeCabang: "001",
            UserTeller: "DEM0",
            tglTransaksi: moment(Date.now()).format("DD-MM-YYYY"),
            userOtoSPV: "OTORDEMO",
            // baris 2
            kodeCabangKredit: "001",
            norekIAKredit: "214263842",
            kodeSetoran: "",
            nominalKredit: addCommas(parseFloat(900000 || "0").toFixed(2)),
            kredit: "K",
            //  baris 3
            kodeCabangDebit: "001",
            noRekIADebit: "4363682342",
            nominalDebit: addCommas(parseFloat(900000 || "0").toFixed(2)),
            // norekIAKredit: "", //new
            kodeDebit: "D",
            // baris 4
            namaRekeningKredit: "REK KREDIT",
            kodeTransaksiKirimUang: "KIRDEMO",
            keteranganTransaksi: "KET TRX",
            validasiTransfer: "VAL TRF",
            berita: "BERITA SKN DEMO",
            tglTransaksi: moment(Date.now()).format("DD-MM-YYYY"),
            jamTransaksi: moment(Date.now()).format("HH:mm:ss"),
            // baris 5
            kodeCabangKreditBiaya: "001",
            noRekJasaLayananTransfer: "REK JASA L TF", //new
            kodeTransaksiSetoran: "KDSTR", //new
            biayaJasaKirim:  addCommas(parseFloat(9000 || "0").toFixed(2)),
            kodeKredit: "K",
            // baris 6
            kodeCabangDebitBiaya: "001", //new
            noRekKasTeller: "rektel0",
            biayaJasaKliring: addCommas(parseFloat(2000 || "0").toFixed(2)),
            kodeDebit: "D",
            // baris 7
            namaRek: "PENERIMA DEMO",
            keteranganBiaya: "KETBIAYA", //new
            kodeTRF: "KODETRF",
            berita: "BERITA SKN DEMO",
            tglTransaksiBiaya: moment(Date.now()).format("DD-MM-YYYY"),
            jamTransaksiBiaya: moment(Date.now()).format("HH:mm:ss"),
        })
    }

    function trxPassbook() {
        let dc = {
            command: "PRINT",
            medianame: "BJBS",
            formname: "Passbook_BJBS",
            orientation: "1",
            print: 1,
            history: 0,
            id: uniqueId(),
        };
        ([{
            TANGGAL: "12/11/2021",
            KODE: "DEMO",
            NILAI_MUTASI_DEBET: "2000000",
            NILAI_MUTASI_KREDIT: "",
            SALDO: "30400000",
            USER: "ISI",
            BARIS: 1,
        },
        {
            TANGGAL: "12/11/2021",
            KODE: "DEMO",
            NILAI_MUTASI_KREDIT: "2000000",
            NILAI_MUTASI_DEBET: "",
            SALDO: "30400000",
            USER: "ISI",
            BARIS: 2,
        },
        {
            TANGGAL: "12/11/2021",
            KODE: "DEMO",
            NILAI_MUTASI_DEBET: "2000000",
            NILAI_MUTASI_KREDIT: "",
            SALDO: "30400000",
            USER: "ISI",
            BARIS: 3,
        },
        {
            TANGGAL: "12/11/2021",
            KODE: "DEMO",
            NILAI_MUTASI_KREDIT: "2000000",
            NILAI_MUTASI_DEBET: "",
            SALDO: "30400000",
            USER: "ISI",
            BARIS: 4,
        },]).map(function ({
            TANGGAL = "", KODE = "", NILAI_MUTASI_DEBET = "", NILAI_MUTASI_KREDIT = "", SALDO = "", USER = "", BARIS = 1
        }) {
            dc = {
                ...dc,
                [`GEN!PVHVFR${(BARIS.toString()).padStart(2, "0")}`]: TANGGAL,
                [`AVH!NA1${(BARIS.toString()).padStart(2, "0")}`]: KODE,
                [`GEN!PVHWID${(BARIS.toString()).padStart(2, "0")}`]: NILAI_MUTASI_DEBET,
                [`GEN!PVHDEP${(BARIS.toString()).padStart(2, "0")}`]: NILAI_MUTASI_KREDIT,
                [`GEN!PVHBAL${(BARIS.toString()).padStart(2, "0")}`]: SALDO,
                [`DZX!OPID${(BARIS.toString()).padStart(2, "0")}`]: USER,
            }});
        

        printDemo(dc)
    }
    function namaPassbook() {
        printDemo({
            command: "PRINT",
            formname: "PassbookCover_BJBS",
            medianame: "BJBS",
            id: `${uniqueId()}`,
            print: 1,
            history: 0,
            namaCover: "NAMA DEMO",
            alamat1: "JL. ALAMAT DEMO NO.36", //nm jalan
            keluarahanKecamatanKabupaten: "KEC. DEMO, KEL. DEMO", // kterngan
            kodePos: "BANDUNG, KOTA. 400000", // kab. pos
            kodeCabang: "001",
            norekeningCover: "0123456789",
            Cabang: "CABANG PUSAT",
            mataUang: "IDR",
            TanggalCover: moment(Date.now()).format("DD-MM-YYYY")
            
        })
    }
    function setorTunai() {
        printDemo({

            command: "PRINT",
            medianame: "BJBS",
            formname: "Validasi Setoran",
            print: 1,
            history: 0,
            id: 1,
            nomorArsipSetoran: "DEMOSETOR1",
            kodeTransaksi: "ST",
            nomorTransaksi: "DEMOSETOR1", //b
            kodeCabangSetoran: "532",
            userTeller: "dem00",
            tglBlnThnSetoran: moment((Date.now())).format("DD-MM-YYYY"),
            userOtorisasi: "DEM01",
            kodeCabangDebit: "001",
            kodeCabangKredit: "001",
            kodeKredit: "K",
            norekKredit: "1223123123",
            norekDebit: "543215243",
            kodeSetoran: "",
            mataUang: "IDR",
            nominalDebit: addCommas(parseFloat(900000 || "0").toFixed(2)),
            kodeDebit: "D",
            nominalKredit: addCommas(parseFloat(900000 || "0").toFixed(2)),
            kodeCabangKreditkodeCabangKredit: "001",
            namaPemilikNorek: "DEMO NAMA",
            // norekKreditKasTeller: data?.nomor_rekening_credit,
            norekDebitKasTeller: "TELLER123",
            berita: "DEMO BERITA",
            tanggalDebit: moment((Date.now())).format("DD-MM-YYYY"),
            waktuDebit: moment((Date.now())).format("HH:mm:ss"),
            penyetor: "PENGIRIM DEMO"
        })
    }

    function tarikTunai() {
        printDemo({

            command: "PRINT",
            medianame: "BJBS",
            formname: "Validasi Tarikan",
            print: 1,
            history: 0,
            id: 1,
            nomorArsipTarikan: "ARSIPTARIKANDEMO",
            nomorTransaksi: "DEMOTARIKAN",
            kodeCabangTarikan: "001",
            userTeller: "DEMOTELLER",
            tglBlnThnSetoran: moment((Date.now())).format("DD-MM-YYYY"),
            userOtorisasi: "DEMO OTOR",
            kodeCabangDebit: "001",
            kodeKredit: "K",
            norekDebit: "1235267241",
            kodeTarikan: "",
            kodeTransaksi: "TT",
            mataUang: "IDR",
            nominalDebit: addCommas(parseFloat(900000 || "0").toFixed(2)),
            kodeDebit: "D",
            norekKredit: "9867575435",
            nominalKredit: addCommas(parseFloat(900000 || "0").toFixed(2)),
            kodeCabangKredit: "532",
            // nominalDebit
            kodeTarikan: "",
            norekKreditKasTeller: "teller35628",
            berita: "DEMO BERITA",
            tanggalDebit: moment((Date.now())).format("DD-MM-YYYY"),
            waktuDebit: moment(Date.now()).format("HH:mm:ss"),
            namaPemilikNorek: "NAMA REK DEMO",
            penyetor: ""
        })
    }
    function bilyet() {
        printDemo({
            command: "PRINT",
            medianame: "BJBS",
            formname: "Bilyet Deposito - Korporasi",
            orientation: "1",
            print: 1,
            history: 0,
            id: uniqueId(),
            "kodeCabang-namaCabang": "001-CABANG DEMO",
            noRekDepositoNasabah: "53784625272",
            tglValuta: moment(Date.now()).format("DD-MM-YYYY"),
            tglJatuhTempo: moment(Date.now()).format("DD-MM-YYYY"),
            jangkaWaktu: `12 BULAN`,
            bagiHasilInvestor: 35,
            bagiHasilBank: 65,
            jumlahDeposito: addCommas(parseFloat(20000000 || 0).toFixed(2)),
            terbilang: terbilang(20000000)+" RUPIAH",
            norekIAJatuhTempo: "426468897",
            norekPerantaraBagiHasil: "56356251783",
            alamatInvestor: "DEMO NAMA ALAMAT INVESTOR RT01/RW02 KEC. DEMO, KEL. DEMO BANDUNG 40132" ,
            namaInvestor : "NAMA INVESTOR",
            namaSO: "DEMO PEJABAT 1",
            namaAO: "DEMO PEJABAT 2",
            alamatWilayahTtdTglBulanTahun: "Bandung, "+ moment(Date.now()).format("DD-MM-YYYY"),
            "kodeCabang-namaCabang" : "001-CABANG PUSAT"

        })
    }

    function saveServerNexus() {
        setLoading()
        setPrintServer(serverNexus);
        wait(500)
        closeLoading();
        alertSuccess({
            callBack: () => window.location.reload()
        })

    }


    function defaultServer() {
        setLoading()
        setPrintServer("http://localhost:998");
        setServerNexus("http://localhost:998")
        wait(500)
        closeLoading();
        alertSuccess({
            callBack: () => window.location.reload()
        });
    }
    function alertSuccess({
        callBack = () => null
    }) {
        setDataAlert({
            show: true,
            body: "Data saved",
            titile: ``,
            header: "Success",
            callBack
        })
    }




    return <setupContext.Provider value={{
        setupAction: {
            setServerNexus,
            rtgs,
            demoSKN,
            trxPassbook,
            namaPassbook,
            setorTunai,
            tarikTunai,
            bilyet,
            saveServerNexus,
            defaultServer
        },
        setupState: {
            serverNexus
        }
    }}>
        {children}
    </setupContext.Provider>
}
export default function useSetup() { return useContext(setupContext) }