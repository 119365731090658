import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { DateInput, NewLoading, TableList } from '../../../controls';
import useInfoBerankas, { InfoBerankasProvider } from './useBerankas';
import { Tab, Nav, Button } from 'react-bootstrap';
import { addCommas } from '../../../../module';
import PrintArea from './component/printArea';
import { useReactToPrint } from 'react-to-print';

function InfoBerankas() {
    const { ibState, ibAction } = useInfoBerankas()

    function sortingDenom(dataDenom = [], namaDenom) {
        return dataDenom.filter(({ jenis = "" }) => (
            jenis.toUpperCase() === namaDenom.toUpperCase()
        )).sort(function (a, b) {
            return b.nilai_denominasi - a.nilai_denominasi;
        })
    }

    const refPrint = useRef(null)
    const printF = useReactToPrint({
        content: () => refPrint?.current,
    })
    return (
        <>
            {/* <p>Nomor Rekening : {ibState.dataBerangkas?.nomor_rekening}</p> */}
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Peragaan Brankas</a>
                    </li>
                </ol>
            </nav>
            <div className="row mb-1">
                <div className="col-md-12">
                    <div className="card">
                        <div className="car-body m-4">
                            <h1 className="mb-4">Rekening Brankas</h1>
                            <p>Nomor Rekening :  {ibState.isLoading ? <Skeleton height="20px" width="35%" /> : ibState.dataBerangkas?.nomor_rekening}</p>
                            <p>Kantor Cabang :  {ibState.isLoading ? <Skeleton height="20px" width="35%" /> : ibState.dataBerangkas?.nama_cabang}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="car-body text-center m-4">
                            {ibState.isLoading ? <Skeleton height="30px" width="60%" /> : <h3>Total Saldo Khanasah : <span className="text-base">RP. {addCommas(parseFloat(ibState.dataBerangkas?.saldo || "0").toFixed(2)) || 0} </span></h3>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <Tab.Container defaultActiveKey="denom">
                        <Nav fill justify variant="pills" className="svs-nav">
                            <Nav.Item>
                                <Nav.Link eventKey="denom">Denominasi Valuta</Nav.Link>
                                {/* {ibState.denomList?.nomor_rekening} */}
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="riwayat">
                                    Riwayat Transaksi
                                </Nav.Link>
                            </Nav.Item>

                        </Nav>
                        <div className="mt-4">
                            <Tab.Content>
                                <Tab.Pane eventKey="riwayat">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2><strong>Riwayat Transaksi</strong></h2>
                                                    </div>
                                                </div>
                                                <div className="title">
                                                    <div className="mb-2 mt-2 ml-2">
                                                        <div className="row">
                                                            <div className="col-4 align-self-center bns-form">
                                                                <div className="form-group">
                                                                    <label>Tanggal Awal</label>
                                                                    <DateInput
                                                                        className="form-control"
                                                                        type="date"
                                                                        placeholder="Tanggal Awal"
                                                                        value={ibState.filter.start_date}
                                                                        onChange={(value) => {
                                                                            ibAction.setFilter(prevState => ({ ...prevState, start_date: value }));
                                                                        }}
                                                                    />
                                                                    {/* <input
                                                                        className="form-control"
                                                                        type="date"
                                                                        placeholder="Tanggal Awal"
                                                                        value={ibState.filter.start_date}
                                                                        onChange={(value) => {
                                                                            ibAction.setFilter(prevState => ({ ...prevState, start_date: value.target.value }));
                                                                        }}
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-4 align-self-center bns-form">
                                                                <div className="form-group">
                                                                    <label>Tanggal Akhir</label>
                                                                    <DateInput
                                                                        className="form-control"
                                                                        type="date"
                                                                        placeholder="Tanggal Akhir"
                                                                        value={ibState.filter.end_date}
                                                                        onChange={(value) => {
                                                                            ibAction.setFilter(prevState => ({ ...prevState, end_date: value }));
                                                                        }}
                                                                    />
                                                                    {/* <input
                                                                        className="form-control"
                                                                        type="date"
                                                                        placeholder="Tanggal Akhir"
                                                                        value={ibState.filter.end_date}
                                                                        onChange={(value) => {
                                                                            ibAction.setFilter(prevState => ({ ...prevState, end_date: value.target.value }));
                                                                        }}

                                                                    /> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-4 align-self-center bns-form">
                                                                <div className="form-group pt-4 mt-1">
                                                                    <button onClick={ibAction.getRiwayatTransaksi} className="btn btn-outline-success">
                                                                        Cari
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <TableList
                                                        withCard={false}
                                                        minHeight="450px"
                                                        maxHeight="500px"
                                                        onChangeShow={ibAction.changeShow}
                                                        onNextPage={ibAction.nextPage}
                                                        onPrevPage={ibAction.prevPage}
                                                        withActions={false}
                                                        isLoading={ibState.loadingRiwayatTRX}
                                                        columns={[
                                                            {
                                                                name: 'Tanggal',
                                                                field: 'tanggal_input',
                                                            },
                                                            {
                                                                name: 'Kode',
                                                                field: 'kode_transaksi',
                                                            },
                                                            {
                                                                name: 'No. Rekening',
                                                                field: 'nomor_rekening',
                                                            },
                                                            // {
                                                            //     name: 'Nama',
                                                            //     field: 'nama',
                                                            // },
                                                            {
                                                                name: 'Keterangan',
                                                                field: 'keterangan',
                                                            },
                                                            {
                                                                name: 'Mutasi',
                                                                field: 'mutasi',
                                                            },
                                                            {
                                                                name: 'Nominal',
                                                                field: 'nominal',
                                                                className: "text-right",
                                                                onRender: (item) => addCommas(parseFloat(item.nominal).toFixed(2))
                                                            },
                                                            {
                                                                name: 'Status',
                                                                field: 'status_otorisasi',
                                                            },
                                                            // {
                                                            //     name: 'Jam Input',
                                                            //     field: 'jam_input',
                                                            // },
                                                        ]}
                                                        data={ibState.dataTRX}
                                                        loadingRiwayatTRX={ibState.loadingRiwayatTRX}
                                                        filter={false}
                                                        resetFilter={false}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </Tab.Pane>
                                <Tab.Pane eventKey="denom">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2><strong>Denominasi</strong></h2>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <TableList
                                                        withCard={false}
                                                        pagination={false}
                                                        filter={false}
                                                        withActions={false}
                                                        maxHeight="1500px"
                                                        isLoading={ibState.isLoading}
                                                        columns={[
                                                            {
                                                                name: 'Nilai Denominasi',
                                                                field: 'nilai_denominasi',
                                                                className: "text-right",
                                                                maxWidth: "100px",
                                                                onRender: (item) => addCommas(item.nilai_denominasi)
                                                            },
                                                            {
                                                                name: 'Jenis',
                                                                field: 'jenis',
                                                            },
                                                            {
                                                                name: 'Jumlah Lembar',
                                                                field: 'jumlah_lembar_koin',
                                                                className: 'text-right',
                                                                maxWidth: "100px",
                                                                onRender: (item, key) => (
                                                                    addCommas(item.jumlah_lembar_koin)
                                                                    // <input
                                                                    //     type="text"
                                                                    //     value={addCommas(item.jumlah_lembar_koin)}
                                                                    //     readOnly={!cdState.formEditable}
                                                                    //     className="form-control form-control-sm"
                                                                    //     onChange={(e) => cdAction.changeJumlahLembar(removeNonNumeric(e.target.value), key)}
                                                                    // />
                                                                )
                                                            },
                                                            {
                                                                name: 'Total Nominal',
                                                                field: 'total_nominal',
                                                                className: "text-right",
                                                                onRender: (item) => <> {addCommas(item?.total_nominal || "0")}, -</>
                                                            },
                                                        ]}
                                                        data={
                                                            [
                                                                ...(sortingDenom(ibState.denomList || [], "lembar") || []),
                                                                ...(sortingDenom(ibState.denomList || [], "koin") || []),
                                                                ...(sortingDenom(ibState.denomList || [], "lembar (utle)") || []),
                                                            ]
                                                        }

                                                    />
                                                </div>
                                                <div className="card-footer">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <button className="btn btn-primary float-right" onClick={printF}>
                                                                <i className="fa fa-print"></i> Cetak
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
            <PrintArea ref={refPrint} />
        </>
    )
}
export default () => <InfoBerankasProvider><InfoBerankas /></InfoBerankasProvider>;