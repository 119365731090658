import React from 'react';
import { baseUrl } from './../../../../app.config';
import { Modal } from 'react-bootstrap';
import { thousandSeparator } from '../../../../utils';
import { withKeycloak } from '@react-keycloak/web';
import queryString from 'query-string';
import ModalInfoNegatifNasabah from '../../data_nasabah/info_negatif_modal';

class PindahBuku extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_user: 'teller-1',
            keterangan: null,
            no_referensi: null,
            nilai_transaksi: null,
            transaction_ch_id: 'null',
            transaction_encoded_key: 'null',
            nama_debit: null,
            nama_kredit: null,
            no_rekening_debit: null,
            no_rekening_kredit: null,
            no_cif_debit: null,
            no_cif_kredit: null,
            // nama_nasabah_kredit: '',
            jenis_referensi: 'aplikasi',
            no_referensi: null,
            valuta: 'IDR',
            nilai_transaksi: 0,
            nilai_ekuivalen: 0,
            biaya_ekuivalen: 0,
            kurs_beli: '1',
            kurs_jual: '1',
            kurs_buku: '1',
            biaya: 0,
            sumber_biaya: 'tunai',
            showModalConfirm: false,
            showModalSuccess: false,
            showModalError: false,
            showModalWarning: false,
            showModalSvs: false,
            showModalInfoNegatif: false,
            errors: {
                no_rekening_debit: false,
                no_rekening_kredit: false,
                nilai_transaksi: false,
            },
            messages: {
                no_rekening_debit: '',
                no_rekening_kredit: '',
                nilai_transaksi: '',
            },
            isLoading: false,
            svsData: [],
            svsParams: [
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
            ],
            formNegatifNasabah: {},
        };
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value,
        });
    };

    handleFormatNumber = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        const newValue = thousandSeparator.format(value);

        if (newValue !== 'NaN') {
            this.setState({
                [name]: newValue,
            });
        }
    };

    getNomorRekening = (value, jenis_tabungan) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };

        fetch(baseUrl + '/rekening/tabungan/detail/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    if (jenis_tabungan === 'debit') {
                        this.setState({
                            nama_produk_debit: dataDetail.produk,
                            valuta_debit: dataDetail.valuta,
                            nama_nasabah_debit: dataDetail.nama,
                            nama_debit: dataDetail.nama,
                            no_cif_debit: dataDetail.detail_nasabah.nomor_cif,
                            cabang_rekening_debit: dataDetail.cabang,
                            errors: {
                                ...this.state.errors,
                                no_rekening_debit: false,
                            },
                            messages: {
                                ...this.state.messages,
                                no_rekening_debit: '',
                            },
                        });
                    }
                    if (jenis_tabungan === 'kredit') {
                        this.setState({
                            nama_produk_kredit: dataDetail.produk,
                            valuta_kredit: dataDetail.valuta,
                            nama_nasabah_kredit: dataDetail.nama,
                            nama_kredit: dataDetail.nama,
                            no_cif_kredit: dataDetail.nomor_cif,
                            cabang_rekening_kredit: dataDetail.cabang,
                            errors: {
                                ...this.state.errors,
                                no_rekening_kredit: false,
                            },
                            messages: {
                                ...this.state.messages,
                                no_rekening_kredit: '',
                            },
                        });
                    }
                } else {
                    if (jenis_tabungan === 'debit') {
                        this.setState({
                            nama_produk_debit: '',
                            valuta_debit: '',
                            nama_nasabah_debit: '',
                            nama_debit: '',
                            cabang_rekening_debit: '',
                            errors: {
                                ...this.state.errors,
                                no_rekening_debit: true,
                            },
                            messages: {
                                ...this.state.messages,
                                no_rekening_debit: 'Nomor rekening debit tidak ditemukan',
                            },
                        });
                    }
                    if (jenis_tabungan === 'kredit') {
                        this.setState({
                            nama_produk_kredit: '',
                            valuta_kredit: '',
                            nama_nasabah_kredit: '',
                            nama_kredit: '',
                            cabang_rekening_kredit: '',
                            errors: {
                                ...this.state.errors,
                                no_rekening_kredit: true,
                            },
                            messages: {
                                ...this.state.messages,
                                no_rekening_kredit: 'Nomor rekening kredit tidak ditemukan',
                            },
                        });
                    }
                }
            });

        // console.log(this.state.nama_nasabah_kredit)
    };

    handleValidation = () => {
        const { no_rekening_debit, no_rekening_kredit, nilai_transaksi, errors, messages } =
            this.state;
        let isValid = true;

        if (!no_rekening_debit) {
            isValid = false;
            errors['no_rekening_debit'] = true;
            messages['no_rekening_debit'] = 'Harus diisi';
        }

        if (!no_rekening_kredit) {
            isValid = false;
            errors['no_rekening_kredit'] = true;
            messages['no_rekening_kredit'] = 'Harus diisi';
        }

        if (!nilai_transaksi || nilai_transaksi === 0) {
            isValid = false;
            errors['nilai_transaksi'] = true;
            messages['nilai_transaksi'] = 'Harus diisi';
        }

        this.setState({ errors: errors });
        return isValid;
    };

    handleProcess = () => {
        if (this.handleValidation()) {
            this.setState({ showModalConfirm: true });
        }
    };

    postData = async () => {
        let body = {
            id_user: this.props.keycloak.idTokenParsed.preferred_username,
            keterangan: this.state.keterangan,
            no_referensi: this.state.no_referensi,
            nilai_transaksi: thousandSeparator.unformat(this.state.nilai_transaksi),
            transaction_ch_id: this.state.transaction_ch_id,
            transaction_encoded_key: this.state.transaction_encoded_key,
            nama_debit: this.state.nama_debit,
            nama_kredit: this.state.nama_kredit,
            no_rekening_debit: this.state.no_rekening_debit,
            no_rekening_kredit: this.state.no_rekening_kredit,
            jenis_referensi: this.state.jenis_referensi,
            valuta: this.state.valuta,
            kurs_beli: thousandSeparator.unformat(this.state.kurs_beli),
            kurs_jual: thousandSeparator.unformat(this.state.kurs_jual),
            biaya: thousandSeparator.unformat(this.state.biaya),
            sumber_biaya: this.state.sumber_biaya,
            kurs_buku: this.state.kurs_buku,
            nilai_ekuivalen: thousandSeparator.unformat(this.state.nilai_ekuivalen),
            biaya_ekuivalen: thousandSeparator.unformat(this.state.biaya_ekuivalen),
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };
        fetch(baseUrl + '/teller/transaksi/pindah-buku/create', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message_id === '00') {
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                    });
                }
            });
    };

    getData = () => {
        const queryParams = queryString.parse(this.props.location.search);
        let id = this.state.no_cif_debit
        let url =
            queryParams.from === 'local'
                ? `/nasabah/svs/local/find-all/${id}`
                : `/nasabah/svs/mambu/find/${id}`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let svsParams = [...this.state.svsParams];
                let dataList = queryParams.from === 'local' ? data.list : data.list?.db?.list;
                if (dataList && dataList.length > 0) {
                    dataList.map(
                        (value, index) =>
                        (svsParams[index] = {
                            data: {
                                nomor_cif: value.nomor_cif,
                                encoded_key_document: value.encoded_key_document,
                                filename: value.filename,
                                signed_url: value.signed_url,
                                image_tag: value.image_tag,
                                remark: value.remark,
                                type: value.type,
                            },
                            options: {
                                isChoosedFile: false,
                                selectedFile: null,
                                selectedFileUrl: null,
                            },
                        })
                    );
                }
                this.setState({
                    svsData: dataList,
                    svsParams: svsParams,
                });
            });
    }

    getDataNegatif = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/nasabah/informasi/negatif/find/' + this.state.no_rekening_debit, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    let {nasabahAlamat, nasabahRt, nasabahRw, nasabahKelurahan, nasabahKecamatan, nasabahKotaKabupaten, nasabahProvinsi, nasabahKodePos} = dataDetail._identitasNasabah;
                    this.setState({
                        formNegatifNasabah: {
                            _negatifNasabah: {
                                keterangan: dataDetail._negatifNasabah.negatifKeterangan,
                                status_data: dataDetail._negatifNasabah.negatifStatus,
                                nama_lengkap: dataDetail.groupName,
                                alamat: `${nasabahAlamat}, RT ${nasabahRt} RW ${nasabahRw}, ${nasabahKelurahan}, ${nasabahKecamatan}, ${nasabahKotaKabupaten}, ${nasabahProvinsi}, ${nasabahKodePos}`,
                                id: dataDetail.id,
                                tgl_approve: dataDetail?.negatifTanggalApprove ?? null,
                            }
                        },
                        showModalInfoNegatif: true
                    });
                } else {
                    this.setState({
                        formNegatifNasabah: {},
                        showModalInfoNegatif: false
                    });
                }
            });
    };

    render() {
        return (
            <>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Transfer</li>
                        <li className="breadcrumb-item">
                            <a href="#">Pindah Buku</a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto">Rekening Debit</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="bns-form">
                                    <div className="form-group">
                                        <label
                                            className={`${
                                                this.state.errors.no_rekening_debit
                                                    ? 'text-danger'
                                                    : ''
                                            }`}
                                        >
                                            No. Rekening
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${
                                                this.state.errors.no_rekening_debit
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            value={this.state.no_rekening_debit}
                                            name="no_rekening_debit"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.handleInputChange(e);
                                                this.getNomorRekening(e.target.value, 'debit');
                                            }}
                                        />
                                        <div className="invalid-feedback">
                                            {this.state.messages.no_rekening_debit}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Nama Nasabah</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            value={this.state.nama_nasabah_debit}
                                            name="nama_nasabah_debit"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="col-5">
                                                <label htmlFor="">Valuta Rekening</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    readOnly
                                                    value={this.state.valuta_debit}
                                                    name="valuta_debit"
                                                />
                                            </div>
                                            <div className="col-7">
                                                <label htmlFor="">Cabang Rekening</label>
                                                <div className="form-row">
                                                    <div className="col-4 pr-0">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            readOnly
                                                            value={this.state.cabang_rekening_debit}
                                                        />
                                                    </div>
                                                    <div className="col-8 pl-0">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.cabang_rekening_debit}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Produk</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            value={this.state.nama_produk_debit}
                                            name="nama_produk_debit"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button 
                                            className="btn btn-block btn-outline-secondary"
                                            onClick={() => {
                                                this.getData()
                                                this.setState({ showModalSvs: true })
                                            }}
                                            disabled={!this.state.nama_nasabah_debit}
                                        >
                                            Lihat Data Grafis/Tanda Tangan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto">Rekening Kredit</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="bns-form">
                                    <div className="form-group">
                                        <label
                                            className={`${
                                                this.state.errors.no_rekening_kredit
                                                    ? 'text-danger'
                                                    : ''
                                            }`}
                                        >
                                            No. Rekening
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${
                                                this.state.errors.no_rekening_kredit
                                                    ? 'is-invalid'
                                                    : ''
                                            }`}
                                            value={this.state.no_rekening_kredit}
                                            name="no_rekening_kredit"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.handleInputChange(e);
                                                this.getNomorRekening(e.target.value, 'kredit');
                                            }}
                                        />
                                        <div className="invalid-feedback">
                                            {this.state.messages.no_rekening_kredit}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Nama Nasabah</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            value={this.state.nama_nasabah_kredit}
                                            name="nama_nasabah_kredit"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="col-5">
                                                <label htmlFor="">Valuta Rekening</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    readOnly
                                                    value={this.state.valuta_kredit}
                                                    name="valuta_kredit"
                                                />
                                            </div>
                                            <div className="col-7">
                                                <label htmlFor="">Cabang Rekening</label>
                                                <div className="form-row">
                                                    <div className="col-2 pr-0">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.cabang_kredit}
                                                            name="cabang_kredit"
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-10 pl-0">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.cabang_kredit}
                                                            name="cabang_kredit"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Produk</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            value={this.state.nama_produk_kredit}
                                            name="nama_produk_kredit"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h5 className="card-title">PINDAH BUKU</h5>
                            </div>
                            <div className="card-body">
                                <div className="bns-form">
                                    <div className="row">
                                        <div className="col-3 form-group">
                                            <label htmlFor="">Jenis No. Referensi</label>
                                            <select
                                                className="form-control"
                                                value={this.state.jenis_referensi}
                                                name="jenis_referensi"
                                                onChange={(e) => {
                                                    this.handleInputChange(e);
                                                }}
                                            >
                                                <option value="">Aplikasi</option>
                                            </select>
                                        </div>
                                        <div className="col-3 form-group">
                                            <label htmlFor="">No. Referensi/Warkat</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.no_referensi}
                                                name="no_referensi"
                                                onChange={(e) => {
                                                    this.handleInputChange(e);
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 form-group">
                                            <label htmlFor="">Valuta Transaksi</label>
                                            <div className="form-row">
                                                <div className="col-2 pr-0">
                                                    <select
                                                        className="form-control"
                                                        value={this.state.valuta}
                                                        name="valuta"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    >
                                                        <option value="IDR">IDR</option>
                                                    </select>
                                                </div>
                                                <div className="col-10 pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value="INDONESIAN RUPIAH"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3 form-group">
                                            <label
                                                className={`${
                                                    this.state.errors.nilai_transaksi
                                                        ? 'text-danger'
                                                        : ''
                                                }`}
                                            >
                                                Nominal Transaksi
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${
                                                    this.state.errors.nilai_transaksi
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                value={this.state.nilai_transaksi}
                                                name="nilai_transaksi"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    this.handleFormatNumber(e);
                                                    
                                                    const value = e.target.value.replace('.', '')
                                                    const kurs_buku = this.state.kurs_buku
        
                                                    this.setState({
                                                        nilai_ekuivalen:
                                                            value * kurs_buku
                                                    });
                                                }}
                                            />
                                            <div className="invalid-feedback">
                                                {this.state.messages.nilai_transaksi}
                                            </div>
                                        </div>
                                        <div className="col-6 form-group">
                                            <div class="form-row">
                                                <div class="col-4">
                                                    <label htmlFor="">Kurs Buku</label>
                                                    <input
                                                        type="number"
                                                        class="form-control"
                                                        value={this.state.kurs_buku}
                                                        name="kurs_buku"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                        readOnly
                                                    />
                                                </div>
                                                <div class="col-4">
                                                    <label htmlFor="">Kurs Jual</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        value={this.state.kurs_jual}
                                                        name="kurs_jual"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            this.handleFormatNumber(e);
                                                        }}
                                                    />
                                                </div>
                                                <div class="col-4">
                                                    <label htmlFor="">Kurs Beli</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        value={this.state.kurs_beli}
                                                        name="kurs_beli"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            this.handleFormatNumber(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 form-group">
                                            <label htmlFor="">Nilai ekuivalen</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                readOnly
                                                value={thousandSeparator.format(this.state.nilai_ekuivalen)}
                                                readOnly 
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3 form-group">
                                            <label htmlFor="">Biaya (Rp)</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                value={this.state.biaya}
                                                name="biaya"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    this.handleFormatNumber(e);
                                                    const value = e.target.value.replace('.', '')
                                                    const kurs_buku = this.state.kurs_buku

                                                    this.setState({
                                                        biaya_ekuivalen:
                                                            value * kurs_buku
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="col-3 form-group">
                                            <label htmlFor="">Biaya ekuivalen</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                readOnly
                                                value={thousandSeparator.format(this.state.biaya_ekuivalen)}
                                                readOnly 
                                            />
                                        </div>
                                        <div className="col-3 form-group">
                                            <label htmlFor="">Sumber Biaya</label>
                                            <select
                                                className="form-control"
                                                value={this.state.sumber_biaya}
                                                name="sumber_biaya"
                                                onChange={(e) => {
                                                    this.handleInputChange(e);
                                                }}
                                            >
                                                <option value="tunai">Tunai</option>
                                            </select>
                                        </div>
                                        <div className="col-3 form-group">
                                            <label htmlFor="">Keterangan</label>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                value={this.state.keterangan}
                                                name="keterangan"
                                                onChange={(e) => {
                                                    this.handleInputChange(e);
                                                }}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-6">
                                        <button 
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                this.getDataNegatif()
                                            }}
                                            disabled={!this.state.nama_nasabah_debit}
                                        >
                                            Informasi Negatif Nasabah
                                        </button>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button className="btn btn-outline-secondary">Batal</button>
                                        <button
                                            className="btn btn-primary ml-3 px-5"
                                            onClick={() => {
                                                this.handleProcess();
                                            }}
                                        >
                                            Proses
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.showModalConfirm}
                    onHide={() => this.setState({ showModalConfirm: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Lakukan proses Pindah Buku?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirm: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.postData();
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSuccess}
                    onHide={() => this.setState({ showModalSuccess: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Selesai</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Proses Pindah Buku berhasil dilakukan</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalSuccess: false });
                                this.props.history.push(
                                    '/informasi-transaksi/informasi-transaksi-hari-ini'
                                );
                            }}
                        >
                            Selesai
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalError}
                    onHide={() => this.setState({ showModalError: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageError}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalError: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalWarning}
                    onHide={() => this.setState({ showModalWarning: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageWarning}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalWarning: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalSvs}
                    onHide={() => this.setState({ showModalSvs: false })}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Data Grafis / Tanda Tangan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-body p0">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Image Tag</th>
                                            <th>Taq Sequence</th>
                                            <th>Remark</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.svsData && this.state.svsData.length > 0
                                            ? this.state.svsData.map((data, index) => {
                                                return (
                                                    <tr
                                                        key={`listsaccountimages${index}`}
                                                    >
                                                        <td>{data?.image_tag}</td>
                                                        <td>{index + 1}</td>
                                                        <td>{data?.remark}</td>
                                                        <td>{data?.type}</td>
                                                    </tr>
                                                );
                                            })
                                            : null}
                                    </tbody>
                                </table>
                                <div className="p-3">
                                    <div className="row">
                                        { }
                                        {this.state.svsData && this.state.svsData.length > 0
                                            ? this.state.svsData.map((data, index) => {
                                                return (
                                                    <div
                                                        className="col-4"
                                                        key={`listsaccountimagessign${index}`}
                                                    >
                                                        <p>
                                                            Image {index + 1} -{' '}
                                                            {data?.image_tag}
                                                        </p>
                                                        <div className="svs-box">
                                                            <div className="mt-5 text-center">
                                                                <img
                                                                    src={data?.signed_url}
                                                                    height={110}
                                                                />
                                                            </div>
                                                        </div>
                                                        <p className="border p-2 mt-2 fs-12">
                                                            {data?.remark}
                                                        </p>
                                                    </div>
                                                );
                                            })
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalSvs: false })}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
                <ModalInfoNegatifNasabah
                    show={this.state.showModalInfoNegatif}
                    form={this.state.formNegatifNasabah}
                    onHide={() => {
                        this.setState({showModalInfoNegatif: false})
                    }}
                    type='peragaan'
                    mode='view'
                />
            </>
        );
    }
}

export default withKeycloak(PindahBuku);