import React from 'react';
import useSetup, { SetupProvider } from './useSetup';
import useKeycloak from '../../../hooks/keycloak.module';

const SetupPage = () => {
    const { setupAction, setupState } = useSetup();
    const { keycloak } = useKeycloak()
    return (<>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb mt-2 mb-3">
                <li className="breadcrumb-item color-primary">Home </li>
                <li className="breadcrumb-item">
                    <a href="#"><i className="fa fa-cog mr-1"></i> Setup</a>
                </li>
                {/* <li className="breadcrumb-item">{capitalize(fdState.paramUrl?.mode)}</li> */}
            </ol>
        </nav>

        <div className="row">
            <div className="col-12">
                <div className="card" style={{
                    width: "80%",
                    margin: "auto"
                }}>
                    <div className='card-header'>
                        <div className='card-title'>
                            Setup
                        </div>
                    </div>
                    <div className='card-body'>
                        <div style={{

                            marginBottom: "20px",
                            marginTop: "20px"
                        }}>
                            <div className="row" style={{
                                borderTop: "1pt solid #d2d4d7",
                                // margin: "0px 5px",
                            }}>
                                <div className="col">
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "-15px",
                                            background: "white",
                                            padding: "0px 10px",
                                            left: "0px",
                                            color: "#004bbc",
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}
                                    >Printer</span>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group form-group-sm'>

                                    <label>Print Server Nexus</label>
                                    <div className='input-group input-groupt-sm'>
                                        <input
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                if (typeof val === "string") val = val.toLocaleLowerCase();
                                                setupAction.setServerNexus(val)
                                            }}
                                            value={setupState.serverNexus}
                                            type="text" className='form-control form-control-sm' />
                                        <div className='input-group-append'>
                                            <button
                                                onClick={setupAction.saveServerNexus}
                                                className="btn btn-sm btn-primary">
                                                Simpan Perubahan
                                            </button>
                                            <button
                                                onClick={setupAction.defaultServer}
                                                className="btn btn-sm btn-danger">
                                                Reset Default
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{

                            marginBottom: "20px",
                            marginTop: "20px"
                        }}>
                            <div className="row" style={{
                                borderTop: "1pt solid #d2d4d7",
                                // margin: "0px 5px",
                            }}>
                                <div className="col">
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "-15px",
                                            background: "white",
                                            padding: "0px 10px",
                                            left: "0px",
                                            color: "#004bbc",
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}
                                    >Demo Printer Nexus</span>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <button
                                    onClick={setupAction.namaPassbook}
                                    className='btn btn-primary mr-2 mb-md-2 mb-sm-2'>
                                    Nama Passbook
                                </button>
                                <button
                                    onClick={setupAction.trxPassbook}
                                    className='btn btn-primary mr-2  mb-md-2 mb-sm-2'>
                                    Transaksi Passbook
                                </button>
                                <button
                                    onClick={setupAction.rtgs}
                                    className='btn btn-primary mr-2  mb-md-2 mb-sm-2'>
                                    Validasi RTGS
                                </button>
                                <button
                                    onClick={setupAction.demoSKN}
                                    className='btn btn-primary mr-2  mb-md-2 mb-sm-2'>
                                    Validasi SKN
                                </button>

                                <button
                                    onClick={setupAction.tarikTunai}
                                    className='btn btn-primary mr-2  mb-md-2 mb-sm-2'>
                                    Validasi Tarik Tunai
                                </button>

                                <button
                                    onClick={setupAction.setorTunai}
                                    className='btn btn-primary mr-2  mb-md-2 mb-sm-2'>
                                    Validasi Setor Tunai
                                </button>

                                <button
                                    onClick={setupAction.bilyet}
                                    className='btn btn-primary mr-2  mb-md-2 mb-sm-2'>
                                    Bilyet Deposito
                                </button>
                            </div>
                        </div>


                        <div style={{

                            marginBottom: "20px",
                            marginTop: "20px"
                        }}>
                            <div className="row" style={{
                                borderTop: "1pt solid #d2d4d7",
                                // margin: "0px 5px",
                            }}>
                                <div className="col">
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "-15px",
                                            background: "white",
                                            padding: "0px 10px",
                                            left: "0px",
                                            color: "#004bbc",
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}
                                    >File Pendukung</span>
                                </div>
                            </div>
                        </div>

{/* 
                        <div className='row mb-3'>
                            <div className='col'>
                                <div style={{
                                    padding: "10px",
                                    border: "1px solid #d2d4d7",
                                    borderRadius: "6px"
                                }}>
                                    <a 
                                        download={true}
                                        href='/download/template-nexus.zip'
                                        className='btn btn-sm btn-info text-white mr-3'>
                                        Download
                                    </a>
                                    <span>Template Nexus</span>

                                </div>
                            </div>
                        </div> */}

                        <div className='row mb-3'>
                            <div className='col'>
                                <div style={{
                                    padding: "10px",
                                    border: "1px solid #d2d4d7",
                                    borderRadius: "6px"
                                }}>
                                    <a 
                                        download={true}
                                        href='/download/new-template-nexus.zip'
                                        className='btn btn-sm btn-info text-white mr-3'>
                                        Download
                                    </a>
                                    <span>New Template Nexus</span>

                                </div>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col'>
                                <div style={{
                                    padding: "10px",
                                    border: "1px solid #d2d4d7",
                                    borderRadius: "6px"
                                }}>
                                    <a 
                                        download={true}
                                        href='/download/nexus-app.zip'
                                        className='btn btn-sm btn-info text-white mr-3'>
                                        Download
                                    </a>
                                    <span>
                                        Nexus Tools
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col'>
                                <div style={{
                                    padding: "10px",
                                    border: "1px solid #d2d4d7",
                                    borderRadius: "6px"
                                }}>
                                    <a 
                                        download={true}
                                        href='/download/driver-aten-232a.zip'
                                        className='btn btn-sm btn-info text-white mr-3'>
                                        Download
                                    </a>
                                    <span>
                                        Driver 232A
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col'>
                                <div style={{
                                    padding: "10px",
                                    border: "1px solid #d2d4d7",
                                    borderRadius: "6px"
                                }}>
                                    <button 
                                        onClick={()=>keycloak.doRefreshToken()}
                                        className='btn btn-sm btn-info text-white mr-3'>
                                        Test refresh token
                                    </button>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default () => <SetupProvider>
    <SetupPage />
</SetupProvider>;