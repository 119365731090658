import React, { useState, useEffect, memo } from 'react';
import { TableList } from '../../../../controls';
import CetakLaporan from './modal/cetakLaporan';
import useLaporan, { LaporanProvide } from './useLaporan';

const Laporan = memo((props) => {
    const { lapState, lapAction } = useLaporan();
    useEffect(function () {
        lapAction.auditTrail()
    }, [])
    return (<>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb mt-2 mb-3">
                <li className="breadcrumb-item color-primary">Laporan </li>
                <li className="breadcrumb-item">
                    <a href="#">List Laporan</a>
                </li>
            </ol>
        </nav>
        <div className="card">
            <div className="card-header bg-white">
                <h5>Data Laporan</h5>
            </div>
            <div className="card-body">
                <TableList
                    withCard={false}
                    navtable={"DATA REKENING TABUNGAN & GIRO"}
                    onChangeShow={lapAction.changeShow}
                    onNextPage={lapAction.nextPage}
                    onPrevPage={lapAction.prevPage}
                    withActions={false}
                    columns={[
                        {
                            name: 'Kode Laporan',
                            field: 'kode_report',
                            maxWidth: '150px',
                        },
                        {
                            name: 'Nama Laporan',
                            field: 'nama_report',
                            maxWidth: '250px',
                        },
                        {
                            name: '',
                            field: 'btn',
                            maxWidth: '250px',
                            onRender: (item) => 
                                <span className="pointer text-orange-primary" onClick={() => lapAction.openCetakLaporan(item)}>
                                    <i class="fas fa-print"></i> Cetak Laporan
                                </span>
                        }
                    ]}
                    data={lapState.dataLaporan}
                    isLoading={lapState.isLoading}
                    filter={true}
                    formsFilter={[
                        {
                            type: 'select',
                            options: [
                                {key: "nama_report", display: "Nama Laporan"},
                                {key: "kode_report", display: "Kode Laporan"}
                            ],
                            value: lapState.jenisLaporan,
                            placeholder: 'Jenis Laporan',
                            onChange: (val) => {
                                lapAction.setKeyLaporan("")
                                lapAction.setJenisLaporan(val)
                            }
                        },
                        {
                            type: 'text',
                            placeholder: 'Cari Keyword ',
                            disabled: false,
                            readonly: false,
                            value: lapState.keyLaporan,
                            onChange: (val) => lapAction.setKeyLaporan(val)
                        },
                    ]}
                    onFilter={() => lapAction.getDataLaporan()}
                    onResetFilter={() => lapAction.resetFilter()}
                    resetFilter={false}
                    submitButton="Submit"
                />
            </div>
        </div>

        <CetakLaporan />
    </>)
})

export default () => <LaporanProvide><Laporan /></LaporanProvide> ;