import { capitalize } from 'lodash';
import React from 'react';
import { useParams } from 'react-router';
import useFormTransfer, { FTProvider } from './useFormTransfer';
import ModalDataNasabah from './modal/modalDataNasabah';
import { createForm } from '../../../../../module/createFormList';
import DataPengirim from './dataPengirim';
import RekeningDebit from './rekeningDebit';
import moment from 'moment';
import Select from 'react-select';
import useDropdowns from '../../../../../hooks/useDropdowns'
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import { addCommas } from '../../../../../module';
import { PrintModal } from '../../../../controls';
import useNexusPrint from '../../../../../hooks/useNexusPrint';
import { usePrintModal } from '../../../../controls/modalPrint';
import { Modal } from 'react-bootstrap';
// import useFormTransfer from './useFormTransfer';

function FormTransfer() {
    const { ftState, ftAction } = useFormTransfer();
    const { mode, jenistf, type } = useParams()
    const { eJenisPendudukTransfer, eJenisAplikasi, eSumberBiaya } = useDropdowns(["eJenisPendudukTransfer", "eJenisAplikasi", "eSumberBiaya"])
    // const { testPrint } = useNexusPrint();
    const { mpAction, mpState } = usePrintModal()

    function getLastUrl() {
        // if(mode === undefined || mode === null) mode = ""
        const types = mode.split("-");
        if (types.length <= 1) return mode;
        else return types.join(' ')
    }
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-2 mb-3">
                    <li className="breadcrumb-item color-primary">Transaksi Nasabah</li>
                    <li className="breadcrumb-item">
                        <a href="#">Transfer {type.toUpperCase()} {capitalize(jenistf)}</a>
                    </li>
                    <li className="breadcrumb-item">{capitalize(ftState.paramUrl?.mode)}</li>
                </ol>
            </nav>
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        TRANSFER {type.toUpperCase()} {jenistf.toUpperCase()}
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="form-group col-6">
                                    <label> Tanggal Efektif</label>
                                    <input
                                        disabled
                                        type="text"
                                        value={moment(Date.now()).format("DD MMMM YYYY")}
                                        className="form-control form-contol-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" >


                        {/* parents col 6 1 */}
                        <div className="col-md-6" >


                            <h4 className="text-base">Tujuan Transfer</h4>
                            <div className="row">
                                <div className="col-12" style={{ padding: "0px 15px" }}>
                                    {
                                        createForm({
                                            type: "select",
                                            label: "Kode Bank",
                                            placeholder: "Pilih Bank",
                                            options: ftState.dataBank?.map(v => ({ value: v.bic, label: `${v.bic} - ${v.nama}` })),
                                            key: "value",
                                            display: "label",
                                            value: ftState.infoTrx?.kode_bank_kliring,
                                            disabled: !ftState.formEditable,
                                            onChange: (d) => {
                                                var kodeBank = d;
                                                var namaBank = ftState.dataBank.find(({bic}) => bic === d)?.nama;
                                                ftAction.setInfoTrx(infoT => ({
                                                    ...infoT,
                                                    kode_bank_kliring:kodeBank,
                                                    nama_bank_kliring:namaBank
                                                }))

                                            }

                                        })
                                    }
                                    {/* <div className="form-group form-group-sm">
                                        <label>Kode Bank </label>
                                        <Select
                                            placeholder="Pilih Bank"
                                            onChange={(e) => {
                                                const namaBank = ftState.dataBank.find(v => v.bic === e.value)?.nama;
                                                ftAction.setInfoTrx(trx => ({
                                                    ...trx,
                                                    kode_bank_kliring: e.value,
                                                    nama_bank_kliring: namaBank
                                                }));
                                                // fdAction.changeForm(e.value, "marketingReferensi", "_informasiLain")
                                            }}
                                            value={
                                                ftState.dataBank?.map(v => (
                                                    { value: v.bic, label: `${v.bic} - ${v.nama}` }
                                                )).find(c => c.value === ftState.infoTrx?.kode_bank_kliring)
                                            }
                                            isDisabled={!ftState.formEditable}
                                            options={ftState.dataBank?.map(v => ({ value: v.bic, label: `${v.bic} - ${v.nama}` }))}
                                        />
                                    </div> */}
                                </div>
                                {/* <div className="col-6">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "Nama Bank",
                                            disabled: !ftState.formEditable,
                                            value: ftState?.infoTrx?.nama_bank_kliring,
                                            onChange: (nama_bank_kliring) => ftAction.setInfoTrx(p => ({ ...p, nama_bank_kliring: nama_bank_kliring.toUpperCase() }))
                                        })
                                    }
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    {
                                        createForm({
                                            type: "number",
                                            label: "No. Rekening Penerima",
                                            disabled: !ftState.formEditable,
                                            placeholder: "No Rek",
                                            value: ftState?.formData?.nomor_rekening,
                                            onChange: (e) => ftAction.changeForm(e, "nomor_rekening"),
                                            // onEnter: (e) => ftAction.cekNasabah(e)
                                        })
                                    }
                                </div>
                                <div className="col">
                                    {
                                        createForm({
                                            type: "text",
                                            label: <>&nbsp;</>,
                                            disabled: !ftState.formEditable,
                                            placeholder: "Nama Penerima",
                                            value: ftState?.infoTrx?.nama_rekening_penerima,
                                            onChange: (e) => ftAction.setInfoTrx(p => ({ ...p, nama_rekening_penerima: e.toUpperCase() })),
                                        })
                                    }
                                </div>
                            </div>


                            <div className="row">
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "Atas Permintaan",
                                            disabled: !ftState.formEditable,
                                            value: ftState?.infoTrx?.ats_permintaan,
                                            onChange: (ats_permintaan) => ftAction.setInfoTrx(p => ({ ...p, ats_permintaan: ats_permintaan.toUpperCase() }))
                                        })
                                    }
                                </div>
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "select",
                                            label: "Jenis Penduduk",
                                            placeholder: "Pilih jenis penduduk",
                                            options: eJenisPendudukTransfer,
                                            key: "reference_code",
                                            display: "reference_desc",
                                            disabled: !ftState.formEditable,
                                            value: ftState?.infoTrx?.jenis_penduduk,
                                            onChange: (jenis_penduduk) => ftAction.setInfoTrx(p => ({ ...p, jenis_penduduk }))
                                        })
                                    }
                                </div>
                            </div>


                            <div className="row">
                                {/* <div className=" col-6">
                                    {
                                        createForm({
                                            type: "select",
                                            label: "Jenis Nomor Referensi",
                                            disabled: !ftState.formEditable,
                                            placeholder: "Pilih jenis nomor referensi",
                                            options: eJenisAplikasi,
                                            key: "reference_code",
                                            display: "reference_desc",
                                            value: ftState?.infoTrx?.jenis_nomor_referensi,
                                            onChange: (jenis_nomor_referensi) => ftAction.setInfoTrx(p => ({ ...p, jenis_nomor_referensi }))
                                        })
                                    }
                                </div> */}
                                <div className=" col-12">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "No. Referensi",
                                            disabled: !ftState.formEditable || true,
                                            placeholder: "No Rek",
                                            value: ftState?.formData?.nomor_referensi,
                                            onChange: (nomor_referensi) => ftAction.changeForm(nomor_referensi, "nomor_referensi")
                                        })
                                    }
                                </div>
                            </div>


                            <div className="row">
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "currency",
                                            label: "Nilai Transaksi",
                                            disabled: !ftState.formEditable,
                                            value: ftState?.formData?.nominal,
                                            onChange: (nominal) => ftAction.changeForm(nominal, "nominal")
                                        })
                                    }
                                </div>
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "select",
                                            label: "Sumber Dana",
                                            placeholder: "Pilih sumber dana",
                                            options: eSumberBiaya,
                                            key: "reference_code",
                                            display: "reference_desc",
                                            disabled: true,
                                            value: ftState?.infoTrx?.sumber_biaya,
                                            onChange: (sumber_biaya) => ftAction.setInfoTrx(p => ({ ...p, sumber_biaya }))
                                        })
                                    }
                                    {/* {
                                        createForm({
                                            type: "number",
                                            label: "Nilai Ekuivalen",
                                            disabled: true,
                                            value: ftState?.infoTrx?.nominal_ekuivalen,
                                            onChange: (nominal_ekuivalen) => ftAction.setInfoTrx(p => ({ ...p, nominal_ekuivalen }))
                                        })
                                    } */}
                                </div>
                            </div>

{/* 
                            <div className="row">
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "select",
                                            label: "Sumber Dana",
                                            placeholder: "Pilih sumber dana",
                                            options: eSumberBiaya,
                                            key: "reference_code",
                                            display: "reference_desc",
                                            disabled: true,
                                            value: ftState?.infoTrx?.sumber_biaya,
                                            onChange: (sumber_biaya) => ftAction.setInfoTrx(p => ({ ...p, sumber_biaya }))
                                        })
                                    }                                    
                                </div>
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "number",
                                            label: "Kurs Beli",
                                            disabled: true,
                                            value: ftState?.infoTrx?.kurs_beli,
                                            onChange: (kurs_beli) => ftAction.setInfoTrx(p => ({ ...p, kurs_beli }))
                                        })
                                    }
                                </div>
                            </div> */}


                            <div className="row">
                                <div className=" col-12">
                                    {
                                        createForm({
                                            className:"input-bg-grey",
                                            type: "currency",
                                            label: "Biaya",
                                            disabled: !ftState.formEditable,
                                            value: ftState?.infoTrx?.biaya,
                                            onFocusOut: (biaya) => biaya === "" ? ftAction.setInfoTrx(p => ({ ...p, biaya:0 })) : false,
                                            onChange: (biaya) => ftAction.setInfoTrx(p => ({ ...p, biaya }))
                                        })
                                    }
                                </div>
                                {/* <div className=" col-6">
                                    {
                                        createForm({
                                            type: "currency",
                                            label: "Biaya Ekuivalen",
                                            disabled: true,
                                            value: ftState?.infoTrx?.biaya_ekuivalen,
                                            onChange: (biaya_ekuivalen) => ftAction.setInfoTrx(p => ({ ...p, biaya_ekuivalen }))
                                        })
                                    }
                                </div> */}
                            </div>


                            <div className="row">
                                <div className=" col">
                                    {
                                        createForm({
                                            type: "textarea",
                                            label: "Keterangan",
                                            maxLength: 100,
                                            disabled: !ftState.formEditable,
                                            value: ftState?.formData?.keterangan,
                                            onChange: (keterangan) => ftAction.changeForm(keterangan, "keterangan")

                                        })
                                    }
                                </div>
                            </div>


                        </div>
                        {/* end parents col 6 1 */}

                        <div className="col-md-6">
                            {
                                jenistf === "debit" ?
                                    <RekeningDebit /> : (
                                        jenistf === "tunai" ?
                                            <DataPengirim /> : null
                                    )
                            }
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    {
                        mode === "otorisasi" && (
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        onClick={() => ftAction.responseOtor("APPROVED")}
                                        className="float-right btn btn-success btn-sm ml-3">
                                        Approve
                                    </button>


                                    <button
                                        onClick={() => ftAction.responseOtor("REJECTED")}
                                        className="float-right btn btn-danger btn-sm ml-3">
                                        Reject
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    {
                        ftState.formEditable && <div className="row">
                            <div className="col-12">
                                <button onClick={ftAction.confirmTrx} className="float-right btn btn-success btn-sm ml-3">
                                    Process
                                </button>


                                <button onClick={ftAction.resetForm} className="float-right btn btn-outline-secondary btn-sm ml-3">
                                    Reset
                                </button>
                            </div>

                        </div>
                    }
                </div>
            </div>
            <ModalDataNasabah />
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => ftAction.setOtorDesc(e.target.value)}
                    value={ftState.otorDesc}
                />
            </ModalConfirmCustom>

            <PrintModal
                title="Cetak Transaksi"
                modalSize="md"
                id="modalTrx"
                data={{
                    kode_transaksi: ftState.dataPrint?.transaksi?.kode_transaksi,
                    tanggal_input: moment(ftState.dataPrint?.tanggal_input).format("DD-MM-YYYY HH:mm:ss"),
                    noref: ftState.dataPrint?.transaksi?.nomor_referensi,
                    norek: ftState.dataPrint?.transaksi?.nomor_rekening_debet,
                    nominal: addCommas((ftState.dataPrint?.transaksi?.nominal || "0")),
                    biaya: addCommas((ftState.dataPrint?.info_transaksi?.biaya || "0")),
                    userInput: ftState.dataPrint?.transaksi?.user_input
                }}
                footer={false}
                footerComponent={
                    <Modal.Footer>
                        <div className="row">
                            <div className="col">
                                <button
                                    onClick={() => ftAction.cetakFunction()}
                                    className="btn btn-sm btn-primary ml-3 float-right">
                                    <i className="fa fa-print"></i> Cetak
                                </button>
                                {<button onClick={() => {
                                    mpAction.closeModal("modalTrx")
                                    mpState.modalDetil?.callBack?.()
                                    // callBackClose()
                                }} className="btn btn-sm btn-outline-secondary float-right ml-3">
                                    Tutup
                                </button>}
                            </div>
                        </div>
                    </Modal.Footer>
                }
                dataLabel={[
                    {
                        name: "Kode Transaksi",
                        fieldName: "kode_transaksi"
                    },
                    {
                        name: "Waktu Transaksi",
                        fieldName: "tanggal_input"
                    },
                    {
                        name: "No. Referensi",
                        fieldName: "noref"
                    },
                    {
                        name: "Nominal",
                        fieldName: "nominal",
                        className: "text-right"
                    },
                    {
                        name: "Biaya",
                        fieldName: "biaya",
                        className: "text-right"
                    },
                    {
                        name: "User Input",
                        fieldName: "userInput",
                    }

                ]}
            />
        </>
    )
}
export default () => <FTProvider><FormTransfer /></FTProvider>;