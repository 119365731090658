import React from "react";
import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { createForm } from "../../../../../../module/createFormList";
import { TableList } from "../../../../../controls";
import { numberToCurrency } from "../../../../../../utils/formatNumber";
import useRekening from "../useRekening";
import moment from "moment";

const ModalWarkat = () => {
  const { rekState, rekAction } = useRekening();

  const renderModalType = (data) => {
    if (rekState.warkatDataModalMode) {
      return <RegisterDataWarkat />;
    } else if (rekState.warkatBukuModalMode) {
      return <RegisterBukuWarkat />;
    } else if (rekState.warkatLihatModalMode) {
      return <LihatWarkat />;
    } else if (rekState.warkatAktivasiModalMode) {
      return <AktivasiWarkat />;
    } else if (rekState.warkatEditModalMode) {
      return <UbahStatusWarkat />;
    } else {
      return <Warkat />;
    }
  };

  const renderCloseButton = () => {
    if (rekState.otorMode) {
      return (
        <>
          <button
            className="btn btn-success mr-3"
            onClick={() => rekAction.responseOtor("APPROVED")}>
            Approve
          </button>
          <button
            className="btn btn-danger mr-3"
            onClick={() => rekAction.responseOtor("REJECTED")}>
            Reject
          </button>
          <button
            className="btn btn-outline-success"
            onClick={() => {
              rekAction.toggleModalWarkat();
              rekAction.closeWarkat();
            }}>
            Tutup
          </button>
        </>
      );
    } else {
      if (rekState.warkatDataModalMode) {
        return (
          <>
            <button
              className="btn btn-outline-danger mr-3"
              onClick={() => {
                rekAction.toggleModalWarkat("data")
                rekState.formWarkat.listForm?.map((e) => {
                  rekAction.updateHoldWarkat(e.id_barang, "2")
                })
              }
              }>
              Batal
            </button>
            <button
              className="btn btn-success"
              onClick={() => rekAction.tambahWarkatCounter("data")}>
              Simpan
            </button>
          </>
        );
      } else if (rekState.warkatBukuModalMode) {
        return (
          <>
            <button
              className="btn btn-outline-danger mr-3"
              onClick={() => {
                rekAction.toggleModalWarkat("buku")
                rekState.formWarkat.listForm?.map((e) => {
                  rekAction.updateHoldWarkat(e.id_barang, "2")
                })
              }}>
              Batal
            </button>
            <button className="btn btn-success" onClick={() => rekAction.tambahWarkat("buku")}>
              Simpan
            </button>
          </>
        );
      } else if (rekState.warkatLihatModalMode) {
        return (
          <>
            <button
              className="btn btn-outer-danger mr-3"
              onClick={() => rekAction.toggleModalWarkat("lihat")}>
              Tutup
            </button>
          </>
        );
      } else if (rekState.warkatAktivasiModalMode) {
        return (
          <>
            <button
              className="btn btn-outer-danger mr-3"
              onClick={() => rekAction.toggleModalWarkat("aktivasi")}>
              Batal
            </button>
            <button className="btn btn-success" onClick={() => rekAction.aktivasiWarkat()}>
              Aktivasi
            </button>
          </>
        );
      } else if (rekState.warkatEditModalMode) {
        return (
          <>
            <button
              className="btn btn-outer-danger mr-3"
              onClick={() => rekAction.toggleModalWarkat("edit")}>
              Batal
            </button>
            <button className="btn btn-success" onClick={() => rekAction.ubahWarkat()}>
              Simpan
            </button>
          </>
        );
      } else {
        return (
          <>
            <button className="btn btn-outer-danger mr-3" onClick={() => rekAction.closeWarkat()}>
              Tutup
            </button>
          </>
        );
      }
    }
  };

  return (
    <>
      {/* modal utama */}
      <Modal size={"xl"} show={rekState.modalWarkat} onHide={rekAction.closeWarkat} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Pemeliharaan Warkat</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2rem" }}>
          <div className="border rounded mb-5 p-4">
            <div className="row">
              <div className="col">
                <h3>{rekState.dataWarkat.nama_rekening}</h3>

                {/* <div className="row mb-3">
                  <div className="col-4">Produk</div>
                  <div className="col-8">: Tabungan Mudharabah</div>
                </div> */}
                <div className="row">
                  <div className="col-2">No. Rek</div>
                  <div className="col-8">{rekState.dataWarkat.nomor_rekening}</div>
                </div>
              </div>
            </div>
          </div>
          {renderModalType()}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end align-items-center">{renderCloseButton()}</div>
        </Modal.Footer>
      </Modal>
      {/* end modal utama */}
    </>
  );
};

function Warkat() {
  const { rekState, rekAction } = useRekening();

  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex justify-content-between align-items-center">
          <span
            className="text-primary"
            style={{
              fontWeight: "bold",
            }}>
            Data Buku Warkat
          </span>
          <div>
            <button
              className="btn btn-outline-orange-primary mr-3"
              onClick={() => rekAction.toggleModalWarkat("buku")}>
              Tambah Buku Warkat
            </button>
            <button
              className="btn btn-outline-orange-primary"
              onClick={() => rekAction.toggleModalWarkat("data")}>
              Tambah Data Warkat
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">

          <div className="row">
            <div className="col-4">
              <input value={rekState.wFilter?.keyword} onChange={(e)=>rekAction.setWFilter( p=> ({ keyword: e.target.value }))} type="text" placeholder="keyword" className="form-control form-control-sm" />
            </div>
            <div className="col">
            <button onClick={()=>rekAction.getWarkat(rekState.dataWarkat.nomor_rekening)} className="btn btn-primary btn-sm">Cari</button>
            </div>
          </div>
          <TableList
            withCard={false}
            isLoading={rekState.loadingWarkat || false}
            data={rekState.dataWarkat.list || []}

            columns={[
              {
                name: "Kode Warkat",
                field: "kode_warkat",
                maxWidth: "150px",
                // onRender: (item) => {
                //   return item.nominal_hold?.toLocaleString() + " ";
                // },
              },
              {
                name: "Jenis Warkat",
                field: "jenis_warkat",
                maxWidth: "150px",
                // onRender: (item) => {
                //   return item.nominal_hold?.toLocaleString() + " ";
                // },
              },
              {
                name: "No. Seri Awal",
                field: "nomor_seri_awal",
                // onRender: (item) => {
                //   return item.nominal_hold?.toLocaleString() + " ";
                // },
              },
              {
                name: "No. Seri Akhir",
                field: "nomor_seri_akhir",
                // onRender: (item) => {
                //   return moment(item.tanggal_hold, "YYYY/MM/DD").format("DD-MM-YYYY");
                // },
              },
              {
                name: "Lbr. Tersedia",
                field: "jumlah_lembar",
                maxWidth: "150px",
              },
              {
                name: "Lbr. Terpakai",
                field: "lembar_terpakai",
                maxWidth: "150px",
              },
              {
                name: "User Input",
                field: "user_input",
                maxWidth: "150px",
              },
              {
                name: "User Otorisasi",
                field: "user_otorisasi",
                maxWidth: "150px",
              },
              {
                name: "Status Buku (Resi)",
                field: "status_buku",
                maxWidth: "150px",
              },
            ]}
            pagination={false}
            filter={false}

            withAction={true}
            actionItems={[
              {
                name: "Lihat Detail",
                onClick: (e) => {
                  rekAction.getDetailWarkat(e.id_bukuwarkat);
                  rekAction.toggleModalWarkat("lihat");
                },
              },
              {
                name: "Aktivasi",
                withDivider: true,
                onClick: (e) => {
                  rekAction.setDataDetailWarkat((prev) => ({ ...prev, selected: e }));
                  rekAction.toggleModalWarkat("aktivasi");
                },
              },
              // {
              //   name: "Aktivasi",
              //   onClick: (e) => {},
              //   className: "text-danger",
              // },
            ]}
          />
        </div>
      </div>
    </>
  );
}

function RegisterBukuWarkat() {
  const { rekState, rekAction } = useRekening();

  React.useEffect(() => rekAction.setFormWarkat(rekState.initFormWarkat), []);

  return (
    <div className="mt-3">
      <h4 className="text-primary">Tambah Buku Warkat</h4>

      {rekState.otorMode ? null : (
        <div className="row">
          <div className="col-12 justify-content-end">
            <button
              className="btn btn-sm btn-primary"
              style={{ margin: "0.5rem" }}
              onClick={() => {
                rekAction.setModalWarkatList(true);
              }}>
              Cari Buku Warkat
            </button>
          </div>
        </div>
      )}

      <div className="row mt-4">
        <div className="col-6">
          {createForm({
            type: "text",
            required: true,
            placeholder: "Kode Warkat",
            label: "Kode Warkat",
            value: rekState.otorMode
              ? rekState.dataDetailWarkat.selected.kode_warkat
              : rekState.formWarkat.listForm[0]?.kode_warkat,
            disabled: true,
            onChange: (e) => {
              let tmp = rekState.formWarkat.listForm;
              tmp[0].kode_warkat = e;
              rekAction.setFormWarkat((prevState) => ({ ...prevState, listForm: tmp }));
            },
          })}
        </div>
        <div className="col-6">
          {createForm({
            type: "select",
            required: true,
            placeholder: "Pilih Jenis Warkat",
            label: "Jenis Warkat",
            value: rekState.otorMode
              ? rekState.dataDetailWarkat.selected.jenis_warkat
              : rekState.formWarkat.listForm[0]?.jenis_warkat,
            disabled: true,
            options: rekState.eJenisWarkat,
            key: "reference_code",
            display: "reference_desc",
            onChange: (e) => {
              let tmp = rekState.formWarkat.listForm;
              tmp[0].jenis_warkat = e;
              rekAction.setFormWarkat((prevState) => ({ ...prevState, listForm: tmp }));
            },
          })}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          {createForm({
            type: "text",
            required: true,
            placeholder: "",
            label: "No. Seri Awal",
            value: rekState.otorMode
              ? rekState.dataDetailWarkat.selected.nomor_seri_awal
              : rekState.formWarkat.listForm[0]?.nomor_seri_awal,
            disabled: true,
            onChange: (e) => {
              let tmp = rekState.formWarkat.listForm;
              tmp[0].nomor_seri_awal = e;
              rekAction.setFormWarkat((prevState) => ({ ...prevState, listForm: tmp }));
            },
          })}
        </div>
        <div className="col-6">
          {createForm({
            type: "text",
            required: true,
            label: "No. Seri Akhir",
            value: rekState.otorMode
              ? rekState.dataDetailWarkat.selected.nomor_seri_akhir
              : rekState.formWarkat.listForm[0]?.nomor_seri_akhir,
            disabled: true,
            onChange: (e) => {
              let tmp = rekState.formWarkat.listForm;
              tmp[0].nomor_seri_akhir = e;
              rekAction.setFormWarkat((prevState) => ({ ...prevState, listForm: tmp }));
            },
          })}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          {createForm({
            type: "select",
            required: true,
            placeholder: "Pilih Jenis Materai",
            label: "Jenis Materai",
            value: rekState.otorMode
              ? rekState.dataDetailWarkat.selected.jenis_materai
              : rekState.formWarkat.listForm[0]?.jenis_materai,
            disabled: rekState.otorMode || rekState.formWarkat.listForm.length === 0,
            options: rekState.eJenisMaterai,
            key: "reference_code",
            display: "reference_desc",
            onChange: (e) => {
              let listForm = rekState.formWarkat.listForm;

              listForm[0].jenis_materai = e;
              if (e === "G") {
                listForm[0].biaya = parseFloat(listForm[0].harga_jual || 0) + parseFloat(listForm[0].materai_giling || 0) + parseFloat(listForm[0].fee || 0)
              } else if (e === "T") {
                listForm[0].biaya = parseFloat(listForm[0].harga_jual || 0) + parseFloat(listForm[0].materai_tempel || 0) + parseFloat(listForm[0].fee || 0)
              }

              rekAction.setFormWarkat((prevState) => ({
                ...prevState,
                listForm: listForm,
                total_biaya: listForm[0].biaya,
              }));
            },
          })}
        </div>
        <div className="col-6">
          {createForm({
            type: "number",
            required: true,
            placeholder: "0",
            label: "Jumlah Lembar",
            value: rekState.otorMode
              ? rekState.dataDetailWarkat.selected.jumlah_lembar
              : rekState.formWarkat.listForm[0]?.jumlah_lembar,
            disabled: true,
            onChange: (e) => {
              let tmp = rekState.formWarkat.listForm;
              tmp[0].jumlah_lembar = e;
              rekAction.setFormWarkat((prevState) => ({ ...prevState, listForm: tmp }));
            },
          })}
        </div>
      </div>

      {rekState.otorMode ? null : (
        <>
          <h4>Detail Biaya Warkat</h4>
          <div className="row mt-3">
            <div className="col-12">
              <table className="table table-borderless table-striped">
                <tr>
                  <th style={{ background: "#e9ecef" }}>Keterangan</th>
                  <th className="text-right" style={{ background: "#e9ecef" }}>
                    Biaya
                  </th>
                </tr>
                <tr>
                  <td>Harga Jual</td>
                  <td className="text-right">{numberToCurrency(rekState.formWarkat.listForm[0]?.harga_jual || 0)}</td>
                </tr>
                <tr>
                  <td>Harga Materai</td>
                  <td className="text-right">{rekState.formWarkat.listForm[0]?.jenis_materai === "G" ? numberToCurrency(rekState.formWarkat.listForm[0]?.materai_giling || 0) : numberToCurrency(rekState.formWarkat.listForm[0]?.materai_tempel || 0)}</td>
                </tr>
                <tr>
                  <td>Fee</td>
                  <td className="text-right">{numberToCurrency(rekState.formWarkat.listForm[0]?.fee || 0)}</td>
                </tr>
              </table>
              <hr className="my-1" />
              <div className="text-right" style={{ padding: ".75rem" }}>
                Total Biaya :{" "}
                <b className="ml-5">{numberToCurrency(rekState.formWarkat.total_biaya)}</b>
              </div>
            </div>
          </div>
        </>
      )}

      {rekState.otorMode ? (
        <>
          <h4>Detail Biaya Warkat</h4>
          <div className="row mt-3">
            <div className="col-12">
              <table className="table table-borderless table-striped">
                <tr>
                  <th style={{ background: "#e9ecef" }}>Keterangan</th>
                  <th className="text-right" style={{ background: "#e9ecef" }}>
                    Biaya
                  </th>
                </tr>
                <tr>
                  <td>Harga Jual</td>
                  <td className="text-right">{numberToCurrency(rekState.dataDetailWarkat.selected.harga_beli || 0)}</td>
                </tr>
                <tr>
                  <td>Harga Materai</td>
                  <td className="text-right">{numberToCurrency(rekState.dataDetailWarkat.selected.harga_materai || 0)}</td>
                </tr>
                <tr>
                  <td>Fee</td>
                  <td className="text-right">{numberToCurrency(rekState.dataDetailWarkat.selected.nominal_fee || 0)}</td>
                </tr>
              </table>
              <hr className="my-1" />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

function RegisterDataWarkat() {
  const { rekState, rekAction } = useRekening();

  React.useEffect(() => rekAction.setFormWarkat(rekState.initFormWarkat), []);
  return (
    <div className="mt-3">
      <h4 className="text-primary">Tambah Data Warkat</h4>

      {rekState.otorMode ? null : (
        <div className="row">
          <div className="col-12 justify-content-end">
            <button
              className="btn btn-sm btn-primary"
              style={{ margin: "0.5rem" }}
              onClick={() => {
                rekAction.setModalWarkatList(true);
              }}>
              Cari Buku Warkat
            </button>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col">
          <TableList
            withCard={false}
            isLoading={rekState.loadingWarkat || false}
            data={
              rekState.otorMode
                ? rekState.dataDetailWarkat.selected.data_warkat_counter
                : rekState.formWarkat.listForm || []
            }
            columns={[
              {
                name: "Kode Warkat",
                field: "kode_warkat",
                className: "align-middle",
                maxWidth: "150px",
              },
              {
                name: "Jenis Warkat",
                field: "jenis_warkat",
                className: "align-middle",
                maxWidth: "150px",
              },
              {
                name: "No. Seri Awal",
                className: "align-middle",
                field: "nomor_seri_awal",
              },
              {
                name: "No. Seri Akhir",
                className: "align-middle",
                field: "nomor_seri_akhir",
              },
              {
                name: "Jumlah Lembar",
                field: "jumlah_lembar",
                className: "align-middle",
                maxWidth: "150px",
              },
              {
                name: "Jenis Materai",
                field: "jenis_materai",
                className: "align-middle",
                onRender: (e, i) => {
                  return createForm({
                    type: "select",
                    required: true,
                    placeholder: "Pilih Jenis Materai",
                    value: e.jenis_materai,
                    disabled: rekState.otorMode,
                    options: rekState.eJenisMaterai,
                    key: "reference_code",
                    display: "reference_desc",
                    onChange: (e) => {
                      let listForm = rekState.formWarkat.listForm;

                      listForm[i].jenis_materai = e;
                      if (e === "G") {
                        listForm[i].biaya = parseFloat(listForm[i].harga_jual || 0) + parseFloat(listForm[i].materai_giling || 0) + parseFloat(listForm[i].fee || 0)
                        listForm[i].biaya_materai = parseFloat(listForm[i].materai_giling || 0)
                      } else if (e === "T") {
                        listForm[i].biaya = parseFloat(listForm[i].harga_jual || 0) + parseFloat(listForm[i].materai_tempel || 0) + parseFloat(listForm[i].fee || 0)
                        listForm[i].biaya_materai = parseFloat(listForm[i].materai_tempel || 0)
                      }

                      rekAction.setFormWarkat((prevState) => ({
                        ...prevState,
                        listForm: listForm,
                        total_materai: sum(listForm, "biaya_materai"),
                        total_biaya: sum(listForm, "biaya"),
                      }));
                    },
                  });
                },
              },
            ]}
            pagination={false}
            filter={false}
            withAction={false}
          />
        </div>
      </div>

      {rekState.otorMode ? null : (
        <>
          <h4>Detail Biaya Warkat</h4>
          <div className="row mt-3">
            <div className="col-12">
              <table className="table table-borderless table-striped">
                <tr>
                  <th style={{ background: "#e9ecef" }}>Keterangan</th>
                  <th className="text-right" style={{ background: "#e9ecef" }}>
                    Biaya
                  </th>
                </tr>
                <tr>
                  <td>Total Harga Jual</td>
                  <td className="text-right">{numberToCurrency(rekState.formWarkat.total_jual || 0)}</td>
                </tr>
                <tr>
                  <td>Total Materai</td>
                  <td className="text-right">{numberToCurrency(rekState.formWarkat.total_materai || 0)}</td>
                </tr>
                <tr>
                  <td>Total Fee</td>
                  <td className="text-right">{numberToCurrency(rekState.formWarkat.total_fee || 0)}</td>
                </tr>
              </table>
              <hr className="my-1" />

              <table class="table table-borderless">
                <tr>
                  <td class="text-right w-100">Total Keseluruhan</td>
                  <td class="text-right" style={{ width: "auto" }}>
                    <b className="ml-5">{numberToCurrency(rekState.formWarkat.total_biaya)}</b>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </>
      )}

      {rekState.otorMode ? (
        <>
          <h4>Detail Biaya Warkat</h4>
          <div className="row mt-3">
            <div className="col-12">
              <table className="table table-borderless table-striped">
                <tr>
                  <th style={{ background: "#e9ecef" }}>Keterangan</th>
                  <th className="text-right" style={{ background: "#e9ecef" }}>
                    Biaya
                  </th>
                </tr>
                <tr>
                  <td>Harga Jual</td>
                  <td className="text-right">{numberToCurrency(rekState.dataDetailWarkat.selected.harga_beli || 0)}</td>
                </tr>
                <tr>
                  <td>Harga Materai</td>
                  <td className="text-right">{numberToCurrency(rekState.dataDetailWarkat.selected.harga_materai || 0)}</td>
                </tr>
                <tr>
                  <td>Fee</td>
                  <td className="text-right">{numberToCurrency(rekState.dataDetailWarkat.selected.nominal_fee || 0)}</td>
                </tr>
              </table>
              <hr className="my-1" />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

function LihatWarkat() {
  const { rekState, rekAction } = useRekening();
  return (
    <div className="mt-3">
      <h4 className="text-primary">Lihat Detail Warkat</h4>
      <div className="row mt-4">
        <div className="col-12">
          <TableList
            withCard={false}
            isLoading={rekState.loadingWarkat || false}
            data={rekState.dataDetailWarkat.list || []}
            columns={[
              {
                name: "Nomor Seri",
                field: "nomor_seri",
                // onRender: (item) => {
                //   return item.nominal_hold?.toLocaleString() + " ";
                // },
              },
              {
                name: "Jenis Warkat",
                field: "jenis_warkat",
                maxWidth: "150px",
                onRender: (item) => {
                  return item.jenis_warkat === "B"
                    ? "BILYET GIRO"
                    : item.jenis_warkat === "C"
                      ? "CHEQUE"
                      : "";
                },
              },
              {
                name: "Status",
                field: "status_warkat",
                // onRender: (item) => {
                //   return item.nominal_hold?.toLocaleString() + " ";
                // },
              },
              {
                name: "Tanggal Perubahan",
                field: "tanggal_aktivasi",
                maxWidth: "200px",
                onRender: (item) => {
                  return moment(item.tanggal_aktivasi).format("DD-MM-YYYY HH:mm");
                },
              },
              {
                name: "Tanggal Pakai Warkat",
                field: "tanggal_pakai_warkat",
                maxWidth: "200px",
                onRender: (item) => {
                  return item.tanggal_pakai_warkat
                    ? moment(item.tanggal_pakai_warkat).format("DD-MM-YYYY HH:mm")
                    : "-";
                },
              },
            ]}
            pagination={false}
            filter={false}
            withAction={true}
            actionItems={[
              {
                name: "Ubah Satus Warkat",
                onClick: (e) => {
                  if (e.status_warkat_kode === "C") {
                    rekAction.setDataAlert({
                      show: true,
                      body: "Tidak dapat merubah data dengan status CAIR",
                      header: "Perhatian!",
                      callBack: () => null,
                    });
                  } else {
                    rekAction.setDataDetailWarkat((prev) => ({ ...prev, selected: e }));
                    rekAction.toggleModalWarkat("edit");
                  }
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

function AktivasiWarkat() {
  const { rekState, rekAction } = useRekening();

  return (
    <div className="mt-3">
      <h4 className="text-primary">Aktivasi Warkat</h4>
      <div className="row mt-4">
        <table className="table table-striped table-borderless">
          <tr>
            <td width="200px">Kode Warkat</td>
            <td>
              {": " + rekState.dataDetailWarkat?.selected.kode_warkat || (
                <Skeleton height="18px" width="150px" />
              )}
            </td>
          </tr>
          <tr>
            <td width="200px">Jenis Warkat</td>
            <td>
              {": " + rekState.dataDetailWarkat?.selected.jenis_warkat || (
                <Skeleton height="18px" width="150px" />
              )}
            </td>
          </tr>
          <tr>
            <td width="200px">No. Seri Awal</td>
            <td>
              {": " + rekState.dataDetailWarkat?.selected.nomor_seri_awal || (
                <Skeleton height="18px" width="150px" />
              )}
            </td>
          </tr>
          <tr>
            <td>No. Seri Akhir</td>
            <td>
              {": " + rekState.dataDetailWarkat?.selected.nomor_seri_akhir || (
                <Skeleton height="18px" width="150px" />
              )}
            </td>
          </tr>
          <tr>
            <td>Jumlah Lembar</td>
            <td>
              {": " + rekState.dataDetailWarkat?.selected.jumlah_lembar || (
                <Skeleton height="18px" width="150px" />
              )}
            </td>
          </tr>
          {!rekState.otorMode ? (
            <>
              <tr>
                <td>Lembar Terpakai</td>
                <td>
                  {": " + rekState.dataDetailWarkat?.selected.lembar_terpakai || (
                    <Skeleton height="18px" width="150px" />
                  )}
                </td>
              </tr>
              <tr>
                <td>User Input</td>
                <td>
                  {": " + rekState.dataDetailWarkat?.selected.user_input || (
                    <Skeleton height="18px" width="150px" />
                  )}
                </td>
              </tr>
              <tr>
                <td>User Otorisasi</td>
                <td>
                  {": " + rekState.dataDetailWarkat?.selected.user_otorisasi || (
                    <Skeleton height="18px" width="150px" />
                  )}
                </td>
              </tr>
              <tr>
                <td>Status Buku</td>
                <td>
                  {": " + rekState.dataDetailWarkat?.selected.status_buku || (
                    <Skeleton height="18px" width="150px" />
                  )}
                </td>
              </tr>
            </>
          ) : null}
        </table>
      </div>
    </div>
  );
}

function UbahStatusWarkat() {
  const { rekState, rekAction } = useRekening();
  // console.log(rekState.eStatusWarkat);

  return (
    <>
      <div className="row mt-3">
        <div className="col-6">
          {createForm({
            type: "select",
            required: true,
            placeholder: "Pilih Jenis Warkat",
            label: "Jenis Warkat",
            value: rekState.dataDetailWarkat.selected.jenis_warkat,
            disabled: true,
            options: rekState.eJenisWarkat,
            key: "reference_code",
            display: "reference_desc",
          })}
        </div>
        <div className="col-6">
          {createForm({
            type: "text",
            required: true,
            placeholder: "0",
            label: "Kode Warkat",
            value: rekState.dataDetailWarkat.selected.kode_warkat,
            disabled: true,
          })}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          {createForm({
            type: "text",
            required: true,
            placeholder: "0",
            label: "Nomor Seri",
            value: rekState.dataDetailWarkat.selected.nomor_seri,
            disabled: true,
          })}
        </div>
        <div className="col-6">
          {createForm({
            type: "text",
            required: true,
            placeholder: "0",
            label: "Nomor Lembar",
            value: rekState.dataDetailWarkat.selected.nomor_lembar,
            disabled: true,
          })}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          {createForm({
            type: "select",
            required: true,
            label: "Status",
            value: rekState.dataDetailWarkat.selected.status_warkat_kode,
            options: rekState.eStatusWarkat.filter((e) => e.reference_code !== "C" && e.reference_code !== "P"),
            disabled: rekState.otorMode,
            key: "reference_code",
            display: "reference_desc",
            onChange: (e) =>
              rekAction.setDataDetailWarkat((prevState) => ({
                ...prevState,
                selected: { ...prevState.selected, status_warkat_kode: e },
              })),
          })}
        </div>
      </div>
    </>
  );
}

function _setBiaya(item, listForm, listBiaya, index, type = "buku") {
  let form = listForm;
  let biaya = listBiaya;

  if (biaya[index]?.id_barang !== form[index].id_barang) {
    biaya.push({
      id_barang: form[index].id_barang,
      biaya: [
        {
          keterangan: "Harga Jual",
          biaya: parseFloat(form[index].harga_jual) || 0,
        },
        {
          keterangan: item === "T" ? "Materai Tempel" : "Materai Giling",
          biaya:
            item === "T"
              ? parseFloat(form[index].materai_tempel) || 0
              : parseFloat(form[index].materai_giling) || 0,
        },
        {
          keterangan: "Fee",
          biaya: parseFloat(form[index].fee) || 0,
        },
      ],
    });
    return biaya;
  } else {
    biaya[index].biaya[1] = {
      keterangan: item === "T" ? "Materai Tempel" : "Materai Giling",
      biaya:
        item === "T"
          ? parseInt(form[index].materai_tempel)
          : parseInt(form[index].materai_giling),
    };
    return biaya;
  }
}

// sum key in array
function sum(array, key) {
  return array.reduce((a, b) => a + parseFloat(b[key]), 0);
}

// function _sumBiaya(array) {
//   let total_sum = 0;
//   array.forEach((e) => {
//     let sum = 0;
//     sum = sum + e.biaya.reduce((a, b) => a + b.biaya, 0);
//     total_sum = total_sum + sum;
//   });
//   return total_sum;
// }

// function _sumMaterai(array) {
//   let total_sum = 0;
//   array.forEach((e) => {
//     let sum = 0;
//     if(e.jenis_materai === "G"){
//       sum = sum + e.biaya.reduce((a, b) => a + b.biaya, 0);

//     }
//     total_sum = total_sum + sum;
//   });
//   return total_sum;
// }

export default ModalWarkat;
