import React from 'react';
import dayjs from 'dayjs';
import { Modal } from 'react-bootstrap';
import { withKeycloak } from '@react-keycloak/web';
import { baseUrl } from './../../../../../app.config';
import { ActionMore, TableGrid } from './../../../../controls';
import { thousandSeparator } from '../../../../../utils';
import queryString from 'query-string';
import ModalInfoNegatifNasabah from '../../../data_nasabah/info_negatif_modal';

class RtgsDebitPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalInfoNegatif: false,
            showModalInformation: false,
            id_user: '1',
            keterangan: null,
            no_referensi: null,
            nilai_transaksi: 0,
            nilai_ekuivalen: 0,
            no_rekening_penerima: null,
            atas_permintaan: null,
            jenis_penduduk: 'P',
            kode_status_pengirim_penerima: null,
            jenis_no_referensi: null,
            sumber_biaya: 'R',
            kurs_beli: 1,
            biaya: 0,
            cabang_rekening: '',
            productName: '',
            biaya_ekuivalen: 0,
            kode_member: null,
            nama_bank: null,
            no_rekening_debit: null,
            nama_nasabah: null,
            showModalConfirm: false,
            showModalSuccess: false,
            showModalError: false,
            showModalWarning: false,
            showModalMember: false,
            showModalSvs: false,
            showModalBatal: false,
            member: null,
            valuta: '',
            nama_penerima: '',
            error: {},
            nama_rekening: '',
            nomor_cif_debit: '',
            svsData: [],
            svsParams: [
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
            ],
        };
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value,
        });
        if (name === 'nilai_transaksi') {
            this.setState({
                nilai_ekuivalen: value * this.state.kurs_beli,
            });
        }
        if (name === 'biaya') {
            this.setState({
                biaya_ekuivalen: value,
            });
        }
    };

    getNomorRekening = (value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/rekening/tabungan/detail/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    this.setState({
                        productName: dataDetail.produk,
                        valuta: dataDetail.valuta,
                        nama_rekening: dataDetail.nama,
                        cabang_rekening: dataDetail.cabang,
                        nomor_cif_debit: dataDetail.detail_nasabah.nomor_cif,
                    });
                } else {
                    this.setState({
                        productName: '',
                        valuta: '',
                        nama_rekening: '',
                        cabang_rekening: '',
                        nomor_cif_debit: '',
                    });
                    alert('Nomor rekening tidak ditemukan');
                }
            });
    };

    getMember = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/bank/all/', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    this.setState({
                        member: data.list,
                    });
                } else {
                    alert('Member tidak ditemukan');
                }
            });
    };

    getDataNegatif = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(
            baseUrl + '/nasabah/informasi/negatif/find/' + this.state.no_rekening_debit,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    let {
                        nasabahAlamat,
                        nasabahRt,
                        nasabahRw,
                        nasabahKelurahan,
                        nasabahKecamatan,
                        nasabahKotaKabupaten,
                        nasabahProvinsi,
                        nasabahKodePos,
                    } = dataDetail._identitasNasabah;
                    this.setState({
                        formNegatifNasabah: {
                            _negatifNasabah: {
                                keterangan: dataDetail._negatifNasabah.negatifKeterangan,
                                status_data: dataDetail._negatifNasabah.negatifStatus,
                                nama_lengkap: dataDetail.groupName,
                                alamat: `${nasabahAlamat}, RT ${nasabahRt} RW ${nasabahRw}, ${nasabahKelurahan}, ${nasabahKecamatan}, ${nasabahKotaKabupaten}, ${nasabahProvinsi}, ${nasabahKodePos}`,
                                id: dataDetail.id,
                                tgl_approve: dataDetail?.negatifTanggalApprove ?? null,
                            },
                        },
                        showModalInfoNegatif: true,
                    });
                } else {
                    this.setState({
                        formNegatifNasabah: {},
                        showModalInfoNegatif: false,
                        showModalInformation: true,
                    });
                }
            });
    };

    handleMember = ({ bic, nama }) => {
        //const value = event.target.value;
        //const name = event.target.name;
        this.setState({
            kode_member: bic,
            nama_bank: nama,
            showModalMember: false,
        });
    };

    postTransferRTGS = async () => {
        if (this.state.no_rekening_debit === null) {
            this.setState({
                showModalWarning: true,
                messageWarning: 'Nomor rekening harus diisi',
            });
            return false;
        }

        if (this.state.kode_member === null) {
            this.setState({
                showModalWarning: true,
                messageWarning: 'Kode Member belum dipilih',
            });
            return false;
        }

        let body = {
            id_user: this.props.keycloak.idTokenParsed.preferred_username,
            keterangan: this.state.keterangan,
            no_referensi: this.state.no_referensi,
            nilai_transaksi: thousandSeparator.unformat(this.state.nilai_transaksi),
            nilai_ekuivalen: thousandSeparator.unformat(this.state.nilai_ekuivalen),
            no_rekening_penerima: this.state.no_rekening_penerima,
            atas_permintaan: this.state.atas_permintaan,
            jenis_penduduk: this.state.jenis_penduduk,
            kode_status_pengirim_penerima: this.state.kode_status_pengirim_penerima,
            jenis_no_referensi: this.state.jenis_no_referensi,
            sumber_biaya: this.state.sumber_biaya,
            kurs_beli: this.state.kurs_beli,
            biaya: thousandSeparator.unformat(this.state.biaya),
            biaya_ekuivalen: thousandSeparator.unformat(this.state.biaya_ekuivalen),
            kode_member: this.state.kode_member,
            nama_bank: this.state.nama_bank,
            no_rekening_debit: this.state.no_rekening_debit,
            nama_nasabah: this.state.nama_rekening,
            valuta: this.state.valuta || 'IDR',
            nama_penerima: this.state.nama_penerima,
        };
        // console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };
        fetch(baseUrl + '/teller/transaksi/rtgs/debit/create', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                    });
                }
            });
    };

    handleFormatNumber = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        const newValue = thousandSeparator.format(value);

        if (newValue !== 'NaN') {
            this.setState({
                [name]: newValue,
            });
        }
        if (name == 'nilai_transaksi') {
            this.setState({
                ['nilai_ekuivalen']: newValue,
            });
        }
        if (name == 'biaya') {
            this.setState({
                ['biaya_ekuivalen']: newValue,
            });
        }
    };
    getData = () => {
        const queryParams = queryString.parse(this.props.location.search);
        let id = this.state.nomor_cif_debit;
        let url =
            queryParams.from === 'local'
                ? `/nasabah/svs/local/find-all/${id}`
                : `/nasabah/svs/mambu/find/${id}`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let svsParams = [...this.state.svsParams];
                // console.log(data);
                let dataList = queryParams.from === 'local' ? data.list : data.list?.db?.list;
                if (dataList && dataList.length > 0) {
                    dataList.map(
                        (value, index) =>
                            (svsParams[index] = {
                                data: {
                                    nomor_cif: value.nomor_cif,
                                    encoded_key_document: value.encoded_key_document,
                                    filename: value.filename,
                                    signed_url: value.signed_url,
                                    image_tag: value.image_tag,
                                    remark: value.remark,
                                    type: value.type,
                                },
                                options: {
                                    isChoosedFile: false,
                                    selectedFile: null,
                                    selectedFileUrl: null,
                                },
                            })
                    );
                }
                this.setState({
                    svsData: dataList,
                    svsParams: svsParams,
                });
            });
    };
    resetFormInput = () => {
        this.setState({
            kode_member: '',
            nama_bank: '',
            no_rekening_penerima: '',
            nama_penerima: '',
            atas_permintaan: '',
            jenis_penduduk: 'B',
            jenis_no_referensi: 'A',
            no_referensi: '',
            nilai_transaksi: 0,
            nilai_ekuivalen: 0,
            sumber_biaya: 'T',
            kurs_beli: '1',
            no_rekening_biaya: 0,
            biaya: 0,
            biaya_ekuivalen: 0,
            keterangan: '',
            no_rekening_debit: '',
            nama_nasabah: '',
            valuta: '',
            cabang_rekening: '',
            productName: '',
        });
    };

    render() {
        // console.log(this.state, 'state');
        return (
            <>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Transfer</li>
                        <li className="breadcrumb-item">
                            <a href="#">Transfer RTGS Debit Rekening</a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h5 className="card-title tw-text-18 tw-font-bold">
                                    Transfer RTGS Debit Rekening
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="bns-form">
                                            <div className="form-group">
                                                <div className="form-row">
                                                    {/* <div className="form-group col-6">
                                                        <label htmlFor="">Sumber Dana</label>
                                                        <select
                                                            disabled
                                                            name=""
                                                            id=""
                                                            className="form-control"
                                                        >
                                                            <option value="" selected disabled>
                                                                Tunai
                                                            </option>
                                                        </select>
                                                    </div> */}
                                                    <div className="form-group col-6">
                                                        <label htmlFor="">Tanggal Efektif</label>
                                                        <input
                                                            disabled
                                                            type="date"
                                                            className="form-control"
                                                            value={dayjs().format('YYYY-MM-DD')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6"></div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="bns-form">
                                            <div className="text-primary mt-2 mb-4">
                                                Tujuan Transfer
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-4">
                                                    <label htmlFor="">Kode Member</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.kode_member}
                                                        name="kode_member"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    ></input>
                                                </div>
                                                <div className="form-group col-5">
                                                    <label htmlFor="">Nama Bank</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.nama_bank}
                                                        name="nama_bank"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    ></input>
                                                </div>
                                                <div className="form-group col-3">
                                                    <button
                                                        className="btn btn-outline-secondary tw-mt-8 tw-text-12"
                                                        onClick={() => {
                                                            this.getMember();
                                                            this.setState({
                                                                showModalMember: true,
                                                            });
                                                        }}
                                                    >
                                                        Cari Bank Member
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">No. Rekening Penerima</label>
                                                <span className="badge badge-secondary ml-1">
                                                    Wajib
                                                </span>
                                                <div className="form-row">
                                                    <div className="col-4 pr-0">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="REKENING PENERIMA"
                                                            value={this.state.no_rekening_penerima}
                                                            name="no_rekening_penerima"
                                                            onChange={(e) => {
                                                                this.handleInputChange(e);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-8 pl-0">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="NAMA PENERIMA"
                                                            value={this.state.nama_penerima}
                                                            name="nama_penerima"
                                                            onChange={(e) => {
                                                                this.handleInputChange(e);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <label htmlFor="">Atas Permintaan</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.atas_permintaan}
                                                        name="atas_permintaan"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-6">
                                                    <label htmlFor="">Jenis Penduduk</label>
                                                    <select
                                                        name="jenis_penduduk"
                                                        id="jenis_penduduk"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    >
                                                        <option value="B">Bukan Penduduk</option>
                                                        <option value="P" selected>
                                                            Penduduk
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <label htmlFor="">Jenis No. Referensi</label>
                                                    <select
                                                        name="jenis_no_referensi"
                                                        id="jenis_no_referensi"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    >
                                                        <option value="A" selected>
                                                            Aplikasi
                                                        </option>
                                                        <option value="B">Bilyet Giro</option>
                                                        <option value="C">Cheque</option>
                                                        <option value="S">System</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label htmlFor="">No. Referensi/Warkat</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.no_referensi}
                                                        name="no_referensi"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <label htmlFor="">Nilai Transaksi</label>
                                                    <span className="badge badge-secondary ml-1">
                                                        Wajib
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.nilai_transaksi}
                                                        name="nilai_transaksi"
                                                        onChange={(e) => {
                                                            this.handleFormatNumber(e);
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-6">
                                                    <label htmlFor="">Nilai Ekuivalen</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.nilai_ekuivalen}
                                                        name="nilai_ekuivalen"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <label htmlFor="">Sumber Biaya</label>
                                                    <select
                                                        name="sumber_biaya"
                                                        id="sumber_biaya"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    >
                                                        <option value="R" selected>
                                                            DEBET REKENING
                                                        </option>
                                                        <option value="T">TUNAI</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-4">
                                                    <label htmlFor="">Kurs Beli</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={this.state.kurs_beli}
                                                        name="kurs_beli"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <label htmlFor="">Biaya (Rp)</label>

                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.biaya}
                                                        name="biaya"
                                                        onChange={(e) => {
                                                            this.handleFormatNumber(e);
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-6">
                                                    <label htmlFor="">Biaya Ekuivalen</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.biaya_ekuivalen}
                                                        name="biaya_ekuivalen"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Keterangan</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="3"
                                                    value={this.state.keterangan}
                                                    name="keterangan"
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                    }}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="bns-form">
                                            <div className="text-primary mt-2 mb-4">
                                                Rekening Debit
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="">No. Rekening</label>
                                                <span className="badge badge-secondary ml-1">
                                                    Wajib
                                                </span>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value={this.state.no_rekening_debit}
                                                    name="no_rekening_debit"
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorRekening(e.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Nama Nasabah</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    readOnly
                                                    value={this.state.nama_rekening}
                                                />
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-4">
                                                    <label htmlFor="">Valuta Rekening</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        readOnly
                                                        value={this.state.valuta}
                                                    />
                                                </div>
                                                <div className="form-group col-8">
                                                    <label htmlFor="">Cabang Rekening</label>
                                                    <div className="form-row">
                                                        <div className="col-3 pr-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                readOnly
                                                                value={this.state.cabang_rekening}
                                                            />
                                                        </div>
                                                        <div className="col-9 pl-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Produk</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    readOnly
                                                    value={this.state.productName}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <button
                                                    className="btn btn-block btn-outline-secondary"
                                                    onClick={() => {
                                                        this.getData();
                                                        this.setState({ showModalSvs: true });
                                                    }}
                                                    disabled={this.state.nama_rekening === ''}
                                                >
                                                    LIhat Data Grafis/Tanda Tangan
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-6 text-center">
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                this.setState({ showModalBatal: true });
                                            }}
                                        >
                                            Batal
                                        </button>
                                        <button
                                            className="btn btn-primary ml-3 px-5"
                                            onClick={() => {
                                                this.setState({ showModalConfirm: true });
                                            }}
                                            disabled={
                                                this.state.no_rekening_penerima === null ||
                                                this.state.nama_penerima === null ||
                                                this.state.nilai_transaksi === 0 ||
                                                this.state.no_rekening_debit === null
                                            }
                                        >
                                            Proses
                                        </button>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                this.getDataNegatif();
                                            }}
                                            disabled={this.state.no_rekening_debit === null}
                                        >
                                            Informasi Negatif Nasabah
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.showModalConfirm}
                    onHide={() => this.setState({ showModalConfirm: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Lakukan proses Transfer RTGS?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirm: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.postTransferRTGS();
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSuccess}
                    onHide={() => this.setState({ showModalSuccess: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Selesai</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Proses Transfer RTGS berhasil dilakukan</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalSuccess: false });
                                this.props.history.push(
                                    '/informasi-transaksi/informasi-transaksi-hari-ini'
                                );
                            }}
                        >
                            Selesai
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalError}
                    onHide={() => this.setState({ showModalError: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageError}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalError: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalWarning}
                    onHide={() => this.setState({ showModalWarning: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageWarning}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalWarning: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal centered show={this.state.showModalMember} size="xl">
                    <Modal.Header>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Daftar Member RTGS</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="tw-px-0">
                        <TableGrid
                            //isLoading={!isFetchedAfterMount}
                            columns={[
                                {
                                    name: 'Kode Member',
                                    field: 'bic',
                                },
                                {
                                    name: 'Nama Bank',
                                    field: 'nama_singkat',
                                },
                                {
                                    name: '',
                                    field: 'action',
                                    width: '40px',
                                },
                            ]}
                            data={this.state.member}
                            onRenderField={(value, field, { data }) => {
                                if (field === 'bic')
                                    return (
                                        <div key={`${field}-${value}`} className="tw-text-black">
                                            {value}
                                        </div>
                                    );

                                if (field === 'nama_singkat')
                                    return (
                                        <div key={`${field}-${value}`} className="tw-text-black">
                                            {value}
                                        </div>
                                    );

                                if (field === 'action')
                                    return (
                                        <ActionMore
                                            key={`${field}-${value}`}
                                            actions={[
                                                {
                                                    label: 'Pilih',
                                                    onClick: () => {
                                                        // console.log(data);
                                                        this.handleMember({
                                                            bic: data?.bic,
                                                            nama: data?.nama_singkat,
                                                        });
                                                    },
                                                },
                                            ]}
                                        />
                                    );
                            }}
                            config={{
                                height: '250px',
                                overflowY: 'auto',
                                stickyHead: true,
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={() => {
                                this.setState({ showModalMember: false });
                            }}
                            size="mini"
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalSvs}
                    onHide={() => this.setState({ showModalSvs: false })}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Data Grafis / Tanda Tangan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-body p0">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Image Tag</th>
                                            <th>Taq Sequence</th>
                                            <th>Remark</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.svsData && this.state.svsData.length > 0
                                            ? this.state.svsData.map((data, index) => {
                                                  return (
                                                      <tr key={`listsaccountimages${index}`}>
                                                          <td>{data?.image_tag}</td>
                                                          <td>{index + 1}</td>
                                                          <td>{data?.remark}</td>
                                                          <td>{data?.type}</td>
                                                      </tr>
                                                  );
                                              })
                                            : null}
                                    </tbody>
                                </table>
                                <div className="p-3">
                                    <div className="row">
                                        {}
                                        {this.state.svsData && this.state.svsData.length > 0
                                            ? this.state.svsData.map((data, index) => {
                                                  return (
                                                      <div
                                                          className="col-4"
                                                          key={`listsaccountimagessign${index}`}
                                                      >
                                                          <p>
                                                              Image {index + 1} - {data?.image_tag}
                                                          </p>
                                                          <div className="svs-box">
                                                              <div className="mt-5 text-center">
                                                                  <img
                                                                      src={data?.signed_url}
                                                                      height={110}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <p className="border p-2 mt-2 fs-12">
                                                              {data?.remark}
                                                          </p>
                                                      </div>
                                                  );
                                              })
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalSvs: false })}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalBatal}
                    onHide={() => this.setState({ showModalBatal: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Batalkan proses RTGS Debit?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalBatal: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.resetFormInput();
                                this.setState({ showModalBatal: false });
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalInformation}
                    onHide={() => this.setState({ showModalInformation: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Informasi Negatif Nasabah tidak ditemukan!</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalInformation: false })}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <ModalInfoNegatifNasabah
                    show={this.state.showModalInfoNegatif}
                    form={this.state.formNegatifNasabah}
                    onHide={() => {
                        this.setState({ showModalInfoNegatif: false });
                    }}
                    type="peragaan"
                    mode="view"
                />
            </>
        );
    }
}

export default withKeycloak(RtgsDebitPage);
