import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { dasboardData } from '../../../../../../appConfig';
import { addCommas } from '../../../../../../module';
import { createForm } from '../../../../../../module/createFormList';
import { TableList } from '../../../../../controls';
import useRekening from '../useRekening';


const ModalAktifasiDormant = () => {
    const { rekState, rekAction } = useRekening();

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalAktifasiDormant} onHide={rekAction.closeAktifasiDormant} >
                <Modal.Header closeButton>
                    <Modal.Title>Aktifasi Rekening Dormant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box mb-3">
                        <div className="row">
                            <div className="col">
                                <h4>{rekState.loadingAktDor ? <Skeleton width={"50%"} height={30} /> : <>{rekState.dataAktifasiDormant?.nama_rekening}</>}</h4>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Produk </div>
                                            <div className="col-8">{rekState.loadingAktDor ? <Skeleton height={20} /> : `: ${ rekState.dataAktifasiDormant?.nama_produk}`}</div>
                                        </div><div className="row">
                                            <div className="col-4"> No. Rek </div>
                                            <div className="col-8">{rekState.loadingAktDor ? <Skeleton height={20} /> : `: ${rekState.dataAktifasiDormant?.nomor_rekening}`} </div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Cabang </div>
                                            <div className="col-8">{rekState.loadingAktDor ? <Skeleton height={20} /> : `: ${rekState.dataAktifasiDormant?.cabang}`}</div>
                                        </div><div className="row">
                                            <div className="col-4">Kode Valuta </div>
                                            <div className="col-8">{rekState.loadingAktDor ? <Skeleton height={20} /> : `: ${rekState.dataAktifasiDormant?.valuta}`}</div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Saldo Rek </div>
                                            <div className="col-8">
                                                {
                                                    rekState.loadingAktDor ?
                                                        <Skeleton height={20} /> :
                                                        <> : <span className="float-right">{ addCommas(rekState.dataAktifasiDormant?.saldo)} </span></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="row">
                    <div className="col">
                    {
                            createForm({
                            //label:"Keterangan Blokir",
                            //type:"text",
                            //required: true,
                            //placeholder: "Keterangan...",
                        })
                    }
                    </div>
                    <div className="col">
                    
                    </div>
                </div>
                <Modal.Footer>
                <div className="row">
                        {
                            !rekState.otorMode ? 
                            <div className="col">
                                { 
                                <button 
                                    onClick={rekAction.confirmRegisDormant}
                                    // disabled={true}
                                    className="btn btn-outline-info float-right ml-4">
                                    
                                    Aktifasi Rekening Dormant
                                </button>
                                }
                                <button
                                    onClick={() => rekState.aktifasiDormantModalMode ? rekAction.toggleModalMode() : rekAction.closeAktifasiDormant()}
                                    className="btn btn-outline-success float-right">
                                    Batal
                                </button>
                            </div> : (
                                <div className="col">
                                    <button
                                        onClick={() => rekAction.closeAktifasiDormant()}
                                        className="btn btn-outline-success float-right">
                                        Tutup
                                    </button>

                                    <button
                                        onClick={() => rekAction.responseOtor("APPROVED")}
                                        className="btn btn-success mr-2">
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => rekAction.responseOtor("REJECTED")}
                                        className="btn btn-danger mr-2">
                                        Reject
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal utama */}
        </>
    );
}

export default ModalAktifasiDormant;