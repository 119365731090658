import React from "react"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { IconExcel } from '../../../icon';

class ExportCsv extends React.Component {
    constructor(props) {
        super(props)
        this.fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        this.fileExtension = '.xlsx';
    }

    exportToCSV = (csvData, fileName) => {
        
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }

    render() {


        return (
            
            <button
                className="border-0 tw-bg-transparent tw-mr-3"
                onClick={() => this.exportToCSV(this.props.csvData, this.props.fileName)}>
                <IconExcel className="tw-mr-2" />
                <span className="tw-text-green">Export</span>
            </button>
        )
    }
}

export default ExportCsv;