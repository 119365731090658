import React, { memo } from 'react';

const BasePagePengirimPenerima = memo((props) => {    
    return(<>
        <h5 className="text-capitalize">Data {props.type}</h5>
        <table className="table table-sm table-borderless">
            <thead></thead>
            <tbody>
                <tr>
                    <td className="f-14 py-1">Nama*</td>
                    <td className="f-14 py-1">
                        <div className="row align-items-center">
                            <div className={`${props.id === "wesel" ? "col-12" : "col-7 pr-0"} `}>
                                <input name={`${props.type}.nama`} ref={props.register({ required: true })} className="form-control form-control-sm" placeholder={`Masukkan nama ${props.type}`} disabled={props.disable}/></div>
                            <div className={props.id === "wesel" ? "d-none" :"col-5"}>
                                <div className="form-check align-self-center">
                                    <input name={`${props.type}.kolektif`} ref={props.register()} className="form-check-input" type="checkbox" value="" id={`flexCheckDefault${props.type}`} />
                                    <label className="form-check-label" for={`flexCheckDefault${props.type}`}>Kolektif</label>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="f-12 py-1">No Hp*</td>
                    <td className="f-14 py-1">
                        <div className="row align-items-center">
                            <div className="col-5 pr-0"><input name={`${props.type}.noHp`} ref={props.register({ required: true })} placeholder={`Masukkan no hp ${props.type}`} className="form-control form-control-sm" /></div>
                            <div className="col-2 border-0 px-0 text-center">Email</div>
                            <div className="col-5 pl-0"><input name={`${props.type}.email`} ref={props.register({ required: true })} placeholder={`Masukkan email ${props.type}`} className="form-control form-control-sm" /></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="f-14 py-1">Alamat*</td>
                    <td className="f-14 py-1"><input name={`${props.type}.alamat`} ref={props.register({ required: true })} className="form-control form-control-sm" placeholder={`Masukkan alamat ${props.type}`} /></td>
                </tr>                                        
                <tr>
                    <td className="f-14 py-1">Negara</td>
                    <td className="f-14 py-1">
                        <div className="row align-items-center">
                            <div className="col-5 pr-0">
                                <select name={`${props.type}.negara`} ref={props.register({ required: true })} class="form-select form-control form-control-sm" aria-label="Default select example">
                                    <option value="" selected>Pilih </option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="col-2 border-0 px-0 text-center">Propinsi</div>
                            <div className="col-5 pl-0">
                                <select name={`${props.type}.provinsi`} ref={props.register({ required: true })} class="form-select form-control form-control-sm" aria-label="Default select example">
                                    <option value="" selected>Pilih </option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="f-14 py-1">Kota</td>
                    <td className="f-14 py-1">
                        <div className="row align-items-center">
                            <div className="col-5 pr-0">
                                <select name={`${props.type}.kota`} ref={props.register({ required: true })} class="form-select form-control form-control-sm" aria-label="Default select example">
                                    <option value="" selected>Pilih </option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="col-2 border-0 px-0 text-center">Kodepos</div>
                            <div className="col-5 pl-0">
                                <select name={`${props.type}.kodePos`} ref={props.register({ required: true })} class="form-select form-control form-control-sm" aria-label="Default select example">
                                    <option value="" selected>Pilih </option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </>)
})

export default BasePagePengirimPenerima;