import React from 'react';
import { Modal } from 'react-bootstrap';
import { addCommas } from '../../../module';
import { TableList, DateRangeInput } from '../../controls';
import useInfoAtm from './useInfoAtm';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

const ModalDetil = () => {
    const { iAtmAction, iAtmState } = useInfoAtm()
    return (
        <Modal backdrop="static" size='xl' show={iAtmState.showDetil}>
            <Modal.Header closeButton onHide={iAtmAction.closeDetail}>
                <Modal.Title>Detil ATM</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-2'>
                        <label>Nomor Rekening</label>
                    </div>
                    <div className='col-3'>
                        : {iAtmState.dataSelected?.nomor_rekening || ""}
                    </div>
                </div>

                <div className='row '>
                    <div className='col-2'>
                        <label>Nama Rekening</label>
                    </div>
                    <div className='col-3'>
                        : {iAtmState.dataSelected?.nama_rekening || ""}
                    </div>
                </div>

                <div className='row '>
                    <div className='col-2'>
                        <label>Cabang</label>
                    </div>
                    <div className='col-3'>
                        : {iAtmState.dataSelected?.kode_cabang || ""} -  {iAtmState.dataSelected?.nama_cabang || ""}
                    </div>
                </div>

                <div className='row '>
                    <div className='col-2'>
                        <label>Valuta</label>
                    </div>
                    <div className='col-3'>
                        : {iAtmState.dataSelected?.kode_valuta || ""} -  {iAtmState.dataSelected?.nama_valuta || ""}
                    </div>
                </div>


                <div className='row '>
                    <div className='col-2'>
                        <label>Saldo</label>
                    </div>
                    <div className='col-3'>
                        : {addCommas(parseFloat(iAtmState.dataSelected?.saldo || 0).toFixed(2))}
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-md-5'>
                        <DateRangeInput size='s'
                            dateFormat='dd-MM-yyyy'
                            onChange={({
                                startDate,
                                endDate
                            }) => {
                                iAtmAction.setFilter(val => ({
                                    ...val,
                                    tanggal_awal: startDate,
                                    tanggal_akhir: endDate
                                }))
                            }}
                            value={
                                {
                                    startDate: iAtmState.filter.tanggal_awal,
                                    endDate: iAtmState.filter.tanggal_akhir,
                                }
                            }
                            maxDate={moment(Date.now()).format("DD-MM-YYYY")}
                        />
                    </div>
                    <div className='col-3'>
                        <button onClick={iAtmAction.doFilter} className='btn btn-sm btn-primary'>
                            Tampilkan Mutasi
                        </button>
                    </div>
                    <div className='col-2 text-right'>
                        <label> Saldo awal Periode</label>
                    </div>
                    <div className='col-2 text-right' style={{
                        fontWeight:"bold"
                    }}>
                        {
                            iAtmState.loadingDetail ? <Skeleton style={{ margin: "auto" }} height={25} /> : addCommas(parseFloat(iAtmState.dataDetail?.saldo_awal || 0).toFixed(2))
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <TableList
                            withCard={false}
                            isLoading={iAtmState.loadingDetail}
                            pagination={true}
                            data={iAtmState.dataDetail?.list || []}
                            onChangeShow={iAtmAction.changeShow}
                            onNextPage={iAtmAction.nextPage}
                            onPrevPage={iAtmAction.prevPage}
                            columns={[
                                {
                                    name: "Tanggal Transaksi",
                                    field: "tanggal_transaksi",
                                    onRender:({tanggal_transaksi}) => tanggal_transaksi ? moment(tanggal_transaksi).format("DD-MM-YYYY HH:mm:SS") :""
                                },

                                // {
                                //     name: "Kode Jurnal",
                                //     field: "kode_jurnal"
                                // },

                                {
                                    name: "Kode Transaksi",
                                    field: "kode_transaksi"
                                },

                                {
                                    name: "Keterangan",
                                    field: "keterangan"
                                },


                                {
                                    name: "D/C",
                                    field: "jenis_mutasi",
                                    maxWidth: "50px"
                                },


                                {
                                    name: "Jumlah",
                                    field: "",
                                    className: "text-right",
                                    onRender:({nilai_mutasi}) => addCommas(parseFloat(nilai_mutasi || 0).toFixed(2))
                                },


                                {
                                    name: "Saldo",
                                    field: "",
                                    className: "text-right",
                                    onRender:({saldo}) => addCommas(parseFloat(saldo || 0).toFixed(2))
                                },


                                {
                                    name: "No. Referensi",
                                    field: "nomor_referensi"
                                },
                                // {
                                //     name: "Status Otorisasi",
                                //     field: "",
                                //     onRender:({status_otorisasi}) => status_otorisasi === 1 ? "APPROVED" : "REJECTED"
                                // },

                                {
                                    name: "User Input",
                                    field: "user_input"
                                },

                                {
                                    name: "User Otorisasi",
                                    field: "user_otorisasi"
                                },

                                {
                                    name: "Kode Cabang",
                                    field: "kode_cabang_transaksi"
                                },

                            ]}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='row'>
                    <div className='col'>
                        <button onClick={iAtmAction.closeDetail} className='float-right btn btn-default'>
                            Close
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalDetil;