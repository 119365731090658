import React, { useEffect } from 'react';
// import { useKeycloak } from '@react-keycloak/web';
import Skeleton from 'react-loading-skeleton';
import { TableList, DoughnatChart } from '../../controls';
import useDashboard, { DashProvider } from './useDashboard';
import { addCommas } from '../../../module';
import useModalDetilTrx, { ModalDetilTrxProv } from '../teller/informasi_transaksi/component/modalDetilTrx';

const TrxDashboard = () => {
    const { dbState, dbAction } = useDashboard()

    const { mtAction, mtState } = useModalDetilTrx();
    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 5
            }}
        />
    );

    useEffect(function () {
        dbAction.getDataDashboardtrx()
    }, [dbState.trxShow])

    useEffect(function () {
        dbAction.getCountTrx()
        dbAction.getDataChartTrx()
    }, [])

    const DashCard = ({ DashCardJudul, DashCardValue, DashIcon = "fa fa-address-card", index = null, color = "base" }) => (
        // <div className="card"
        //     onClick={() => index !== null && dbAction.changeCardTrx(index, DashIcon)}

        //     style={{
        //         cursor: "pointer"
        //     }}>
        <div className="">
            {
                dbState.loadingCountTrx ?
                    <Skeleton height={60} count={1} />
                    :
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            <div
                                className={`small-box bg-${color} text-white`}>
                                <div className="inner">
                                    <h3>{DashCardValue}</h3>

                                    <p>{DashCardJudul}</p>
                                </div>
                                <div className="" style={{
                                    overflow: "hidden",
                                    height: "100%",
                                    position: "absolute",
                                    top: 0,
                                    right: 0
                                }}>
                                    <i className={DashIcon}
                                        style={{
                                            transform: "rotate(15deg)",
                                            width: "183px",
                                            height: "100 %",
                                            overflow: "hidden",
                                            color: "rgba(0, 0, 0, .15)",
                                            fontSize: "110pt"
                                        }}
                                    ></i>

                                </div>
                                <a href="#" onClick={() => index !== null && dbAction.changeCardTrx(index, DashIcon)} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                // <div className="row" >
                //     <div className="col-2" >
                //         {DashIcon}
                //     </div>
                //     <div className="col-10" >
                //         {DashCardJudul}<br />
                //         <p className="text-primary-light mt-2 fs-14">
                //             {DashCardValue}
                //         </p>
                //     </div>
                // </div>
            }
        </div>
        // </div>
    );
    return (
        <>


            <div className="row">
                <div className="col-3"  >
                    <div className='row'>
                        <div className='col'>

                            <DashCard index={1} DashCardJudul="Jumlah Transaksi" DashCardValue={
                                dbState?.dataCountTrx?.jumlah_trx || 0
                            }
                                color='danger'
                                DashIcon='fa fa-hand-holding-usd'
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <div className='card'>
                                <div className='card-body'>
                                    <DoughnatChart id="jmlTrx"
                                        colors={[
                                            "#004bbc",
                                            "#007bff"
                                        ]}
                                        isLoading={dbState.loadingChartTrx}
                                        data={dbState.dataChartTrx?.jumlah_trx || []}
                                        fieldLabel='keterangan'
                                        fieldValue='jumlah'
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3" >

                    <div className='row'>
                        <div className='col'>

                            <DashCard index={2} DashCardJudul="Transaksi Atas Limit" DashCardValue={
                                dbState?.dataCountTrx?.jumlah_trx_al || 0
                            }
                                DashIcon="fa fa-chevron-circle-up"
                                color='info'
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>

                            <div className='card'>
                                <div className='card-body'>

                                    <DoughnatChart id="trxal"
                                        isLoading={dbState.loadingChartcs}
                                        colors={[
                                            "#17a2b8",
                                            "#007bff"
                                        ]}
                                        isLoading={dbState.loadingChartTrx}
                                        data={dbState.dataChartTrx?.jumlah_trx_al || []}
                                        fieldLabel='keterangan'
                                        fieldValue='jumlah'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3" >

                    <div className='row'>
                        <div className='col'>

                            <DashCard index={3} DashCardJudul="Transaksi Bawah Limit"
                                DashCardValue={
                                    dbState?.dataCountTrx?.jumlah_trx_bl || 0
                                }
                                DashIcon="fa fa-chevron-circle-down"
                                color='success'

                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>

                            <div className='card'>
                                <div className='card-body'>

                                    <DoughnatChart
                                        colors={[
                                            "#28a745",
                                            "#007bff"
                                        ]}
                                        id="trxbl"
                                        isLoading={dbState.loadingChartTrx}
                                        data={dbState.dataChartTrx?.jumlah_trx_bl || []}
                                        fieldLabel='keterangan'
                                        fieldValue='jumlah'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-3" >

                    <div className='row'>
                        <div className='col'>

                            <DashCard index={4} DashCardJudul="Transaksi Reversal" DashCardValue={
                                dbState?.dataCountTrx?.jumlah_trx_rv || 0
                            }
                                DashIcon='fa fa-undo'
                                color='warning'
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>

                            <div className='card'>
                                <div className='card-body'>

                                    <DoughnatChart
                                        id="trxrv"
                                        colors={[
                                            "#ffc107",
                                            "#007bff"
                                        ]}
                                        isLoading={dbState.loadingChartTrx}
                                        data={dbState.dataChartTrx?.jumlah_trx_rv || []}
                                        fieldLabel='keterangan'
                                        fieldValue='jumlah'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ColoredLine />

            <div className="card mt-4">

                <div className="card-header">
                    {dbState.loadingListTrx ?
                        <Skeleton height={30} count={1} />
                        : <div className='card-title'>
                            {dbState.cardTrxSelected?.title}
                        </div>}
                    {/* {
                        (
                            <div className="col-4">
                                {dbState.loadingListTrx ?
                                    <Skeleton height={60} count={1} />
                                    : 
                                    // <DashCard
                                    //     DashCardJudul={
                                    //         dbState.cardTrxSelected?.title || ""
                                    //     }
                                    //     DashCardValue={
                                    //         dbState?.cardTrxSelected?.count || 0
                                    //     }
                                    //     DashIcon=
                                    //     {
                                    //         dbState?.cardTrxSelected?.icon || <svg width="50" height="50" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    //             <path d="M4.5 2.25H31.5C32.0967 2.25 32.669 2.48705 33.091 2.90901C33.5129 3.33097 33.75 3.90326 33.75 4.5V24.1928C34.5518 24.3724 35.3085 24.6728 36 25.0737V4.5C36 3.30653 35.5259 2.16193 34.682 1.31802C33.8381 0.474106 32.6935 0 31.5 0H4.5C3.30653 0 2.16193 0.474106 1.31802 1.31802C0.474106 2.16193 0 3.30653 0 4.5V31.5C0 32.6935 0.474106 33.8381 1.31802 34.682C2.16193 35.5259 3.30653 36 4.5 36H25.0737C24.3911 34.8226 24 33.4561 24 32C24 29.1428 25.5057 26.6306 27.7653 25.2152C25.6559 23.8295 22.5407 22.8 18 22.8C6.42857 22.8 4.11429 29.4857 4.11429 31.7143V33.7167C3.66074 33.6378 3.23892 33.4209 2.90901 33.091C2.48705 32.669 2.25 32.0967 2.25 31.5V4.5C2.25 3.90326 2.48705 3.33097 2.90901 2.90901C3.33097 2.48705 3.90326 2.25 4.5 2.25Z" fill="#CACCCF" />
                                    //             <path d="M22.9093 18.6132C21.6073 19.867 19.8414 20.5714 18 20.5714C16.1586 20.5714 14.3927 19.867 13.0907 18.6132C11.7886 17.3594 11.0571 15.6589 11.0571 13.8857C11.0571 12.1126 11.7886 10.412 13.0907 9.1582C14.3927 7.90439 16.1586 7.2 18 7.2C19.8414 7.2 21.6073 7.90439 22.9093 9.1582C24.2114 10.412 24.9429 12.1126 24.9429 13.8857C24.9429 15.6589 24.2114 17.3594 22.9093 18.6132Z" fill="#CACCCF" />
                                    //             <path d="M30 22C25.5888 22 22 25.5888 22 30C22 34.4112 25.5888 38 30 38C34.4112 38 38 34.4112 38 30C38 25.5888 34.4112 22 30 22ZM33.6923 30.6154H30.6154V33.6923H29.3846V30.6154H26.3077V29.3846H29.3846V26.3077H30.6154V29.3846H33.6923V30.6154Z" fill="#004bbc" />
                                    //         </svg>
                                    //     }
                                    // />}
                            </div>
                        )
                    } */}
                </div>

                <div className="card-body">
                    <TableList
                        withActions={true}
                        actionItems={[
                            {
                                name: 'Lihat Detail',
                                onClick: (item) => {
                                    mtAction.openModal(parseInt(item.id_transaksi))
                                },
                            },
                        ]
                        }
                        columns={[
                            {
                                name: 'Jam Input',
                                field: 'jam_input',
                            },
                            {
                                name: 'Kode Transaksi',
                                field: 'kode_transaksi',
                            },
                            {
                                name: 'Jenis Transaksi',
                                field: 'kode_transaksi',
                            },
                            // {
                            //     name: 'Nilai Mutasi',
                            //     field: 'nilai_mutasi',
                            //     className: "text-right",
                            //     onRender: (item) => parseInt(item.nilai_mutasi)?.toLocaleString()
                            // },
                            {
                                name: 'Referensi',
                                field: 'nomor_referensi',
                                width: '40px',
                            },
                            {
                                name: 'User',
                                field: 'user_input',
                            },
                            {
                                name: 'User Otor',
                                field: 'user_otorisasi',
                            },
                            {
                                name: 'Keterangan',
                                field: 'keterangan',
                            },
                        ]}
                        withCard={false}
                        isLoading={dbState.loadingListTrx}
                        //columns={collumn[dbState.dashshow]}
                        pagination={false}
                        data={dbState?.dataListTrx || []}
                    />
                </div>

            </div>
        </>
    );
}

export default () => <ModalDetilTrxProv><TrxDashboard /></ModalDetilTrxProv>;