import React, { memo, useRef } from 'react';
import './style.css';
import { Modal, Dropdown } from 'react-bootstrap';
import { baseUrl } from './../../../app.config';
import { Link } from 'react-router-dom';
import { Gap, Stepper, FormHeader, TableGrid, ActionMore } from '../../controls';
import queryString from 'query-string';
import moment from "moment";
import { withRouter } from "react-router-dom";

class RegistrasiBadanUsaha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            isStep0Completed: false,
            isStep1Completed: false,
            isStep2Completed: false,
            isStep3Completed: false,
            isStep4Completed: false,
            isStep5Completed: false,
            showModalTambahPejabat: false,
            showModalTambahRelasi: false,
            showModalConfirm: false,
            showModalSuccess: false,
            showModalError: false,
            showModalWarning: false,
            showModalRBA: false,
            showModalConfirmHapus: false,
            indexType: '',
            indexHapus: null,
            mode: 'create',
            id: 1,
            encodedKey: null,
            groupName: "tess",
            created_at: moment().format('YYYY-MM-DD'),
            emailAddress: null,
            notes: null,
            assignedBranchKey: '8a8e8fab786e635c0178863b7911431e',
            _identitasNasabah: {
                nasabahLokasi: "tes bandung",
                nasabahAsetPerusahaan: null,
                nasabahNamaPerusahaan: null,
                nasabahOmsetPerusahaan: null,
                nasabahPicNasabah: null,
                nasabahProvinsi: null,
                nasabahSektorEkonomi: null,
                nasabahNomorSkMenkumham: null,
                nasabahEmailAddress: null,
                nasabahTeleponKantorNomor3: null,
                nasabahNomorNpwp: null,
                nasabahKelurahan: null,
                nasabahAlamat: null,
                nasabahBidangUsaha: null,
                nasabahNasabahInduk: null,
                nasabahTanggalPengesahanSkdp: null,
                nasabahBadanUsaha: null,
                nasabahTanggalSkMenkumham: null,
                nasabahBranchCode: '8a8e879878806e75017881ec63f1043f',
                nasabahNomorSkdp: null,
                nasabahKotaKabupaten: null,
                nasabahKodePos: null,
                nasabahTempatAktePendirian: null,
                nasabahStatusKeterkaitan: null,
                nasabahTeleponKantorKodeArea1: null,
                nasabahTanggalAktePendirian: null,
                nasabahNomorAktePerubahan: null,
                nasabahTeleponKantorKodeArea2: null,
                nasabahTeleponKantorKodeArea3: null,
                nasabahFaxNomor: null,
                nasabahNomorAktePendirian: null,
                nasabahTanggalKadaluarsaSkdp: null,
                nasabahKodeMarketing: null,
                nasabahTanggalAktePerubahan: null,
                nasabahKepemilikanModal: null,
                nasabahJenisPenduduk: null,
                nasabahNomorSiupp: null,
                nasabahTanggalPengesahanSiupp: null,
                nasabahRw: null,
                nasabahNomorTDP: null,
                nasabahWilayahPemberiReferensi: null,
                nasabahTeleponKantorNomor2: null,
                nasabahRt: null,
                nasabahTeleponKantorNomor1: null,
                nasabahFaxKodeArea: null,
                nasabahKecamatan: null,
                nasabahBankNonBank: null,
                nasabahPemilik: null,
                nasabahKegiatanusaha: null,
            },
            _pejabatKorporat: [],
            _pejabatKorporatForm: {
                pejabatKecamatan: null,
                pejabatNomorNasabahPejabat: null,
                pejabatAlamat: null,
                pejabatNomorNpwp: null,
                pejabatKeterangan: null,
                pejabatNegaraAsal: null,
                pejabatKotaKabupaten: null,
                pejabatNomorHandphone: null,
                pejabatJabatan: null,
                pejabatNomorIdentitas: null,
                pejabatPekerjaan: null,
                pejabatTeleponKodeArea: null,
                pejabatJenisIdentitas: null,
                pejabatNama: null,
                pejabatEmailAddress: null,
                pejabatTeleponNomor: null,
                pejabatKelurahan: null,
                pejabatRw: null,
                pejabatPersentaseKepemilikan: null,
                pejabatProvinsi: null,
                pejabatFaxKodeArea: null,
                pejabatFaxNomor: null,
                pejabatRt: null,
                pejabatKewarganegaraan: null,
                pejabatKodePos: null,
            },
            _pejabatKorporatFormMode: 'create',
            _pejabatKorporatFormModeDisabled: {
                pejabatNama: false,
                pejabatNomorHandphone: false,
                pejabatNomorNasabahPejabat: false,
                pejabatEmailAddress: false,
                pejabatAlamat: false,
                pejabatRt: false,
                pejabatRW: false,
                pejabatProvinsi: false,
                pejabatKotaKabupaten: false,
                pejabatKecamatan: false,
                pejabatKelurahan: false,
                pejabatKodePos: false,
                pejabatNomorIdentitas: false,
                pejabatJenisIdentitas: false,
                pejabatKewarganegaraan: false,
            },
            _pejabatKorporatSearch: '',
            _cdd: {
                cddFaktorResiko: "resiko tes",
                cddStatusResiko: null,
                cddStrukturKepemilikan: null,
                cddJumlahTransaksi: null,
                cddSumberDana: null,
                cddLimitFrekuensiSetorTunai: null,
                cddLimitFrekuensiSetorNonTunai: null,
                cddSumberPenghasilan: null,
                cddTujuanPenggunaanDana: null,
                cddLimitNominalSetorTunai: null,
                cddIdentitasNasabah: null,
                cddProfilNasabah: null,
                cddTujuanBukaRekening: null,
                cddLokasiUsaha: null,
                cddKegiatanUsaha: null,
                cddGolonganNasabah: null,
                cddLimitNominalSetorNonTunai: null,
                cddPenghasilanKotorPerTahun: null,
                cddInformasiLainnya: null,
            },
            _relasiNasabah: [],
            _relasiNasabahForm: {
                relasiNomorNasabah: null,
                relasiFaxNomor: null,
                relasiKelurahan: null,
                relasiPekerjaan: null,
                relasiFaxKodeArea: null,
                relasiAlamat: null,
                relasiNomorIdentitas: null,
                relasiTeleponRumahNomor: null,
                relasiEmailAddress: null,
                relasiNomorNpwp: null,
                relasiKewarganegaraan: null,
                relasiRw: null,
                relasiNegaraAsal: null,
                relasiRt: null,
                relasiJenisIdentitas: null,
                relasiNomorHandphone: null,
                relasiJabatanPangkat: null,
                relasiKotaKabupaten: null,
                relasiTeleponRumahKodeArea: null,
                relasiNama: null,
                relasiProvinsi: null,
                relasiHubunganDenganNasabah: null,
                relasiKecamatan: null,
            },
            _relasiNasabahFormMode: 'create',
            _relasiNasabahFormButtonMode: 'create',
            _relasiNasabahSearch: '',
            _beneficiaryOwnerType: 'badan_usaha',
            _beneficiaryOwner: {
                benefKecamatan: null,
                benefNamaPerusahaan: null,
                benefRw: null,
                benefKodePos: null,
                benefProvinsi: null,
                benefNomorSiupp: null,
                benefBentukUsaha: null,
                benefBidangUsaha: null,
                benefRt: null,
                benefKeterangan: null,
                benefSumberDana: null,
                benefAlamat: null,
                benefKelurahan: null,
                benefKotaKabupaten: null,
                benefTujuanPenggunaanDana: null,
            },
            _pihakLawan: {
                pihakLawanLembagaPemeringkatan: null,
                pihakLawanRating: null,
                pihakLawanTanggalPemeringkatan: null,
                pihakLawanIdGrup: null,
            },
            _alamatAlternatif: {
                alternatifKodePos: null,
                alternatifProvinsi: null,
                alternatifKotaKabupaten: null,
                alternatifRtRw: null,
                alternatifRt: null,
                alternatifRw: null,
                alternatifKecamatan: null,
                alternatifAlamat: null,
                alternatifTelepon: null,
                alternatifKelurahan: null,
            },
            _svsCorporate: null,
            _negatifNasabah: null,
            rba: {
                answer1: null,
                answer2: null,
                answer3: null,
                answer4: null,
                answer5: null,
                answer6: null,
                answer7: null,
                answer8: null,
                answer9: null,
            },
            rbaErrors: {
                answer1: null,
                answer2: null,
                answer3: null,
                answer4: null,
                answer5: null,
                answer6: null,
                answer7: null,
                answer8: null,
                answer9: null,
            },
            errors: {
                groupName: null,
                emailAddress: null,
                nasabahBadanUsaha: null,
                nasabahNomorNpwp: null,
                nasabahNomorNpwpRequired: null,
                nasabahNomorNpwpAlready: null,
                nasabahNomorAktePendirian: null,
                nasabahTanggalAktePendirian: null,
                nasabahLokasi: null,
                nasabahSektorEkonomi: null,
                nasabahAlamat: null,
                nasabahProvinsi: null,
                nasabahKotaKabupaten: null,
                nasabahKecamatan: null,
                nasabahKelurahan: null,
                nasabahRw: null,
                nasabahRt: null,
                nasabahTeleponKantorKodeArea1: null,
                nasabahTeleponKantorNomor1: null,
                cddPenghasilanKotorPerTahun: null,
                cddGolonganNasabah: null,
                cddSumberDana: null,
                cddSumberPenghasilan: null,
                cddTujuanPenggunaanDana: null,
                cddFaktorResiko: null,
                cddStatusResiko: null,
                relasiNomorNasabah: null,
                relasiHubunganDenganNasabah: null,
                relasiJabatanPangkat: null,
                benefNamaPerusahaan: null,
                benefNomorSiupp: null,
            },
            messages: {
                relasiNomorNasabah: null,
                relasiHubunganDenganNasabah: null,
                relasiJabatanPangkat: null,
            },
            _fields: {
                R_BADAN_HUKUM_KORPORAT: [],
                eStatusKeterkaitan: [],
                eStrukturModal: [],
                R_KL: [],
                R_SEKTOR_EKONOMI: [],
                R_BIDANG_USAHA: [],
                eJenisPenduduk: [],
                R_JABATAN_KORPORAT: [],
                eJenisIdentitas: [],
                eKewarganegaraan: [],
                R_PEKERJAAN: [],
                R_KODE_NEGARA: [],
                R_PENGELUARAN_PERTAHUN: [],
                ANTASENA_GOL_PIHAK_LAWAN: [],
                R_SUMBER_DANA_CIF_KORPORAT: [],
                R_TUJUAN_DANA_CIF_KORPORAT: [],
                R_FAKTOR_RESIKO: [],
                R_RELASI_NASABAH: [],
                ANTASENA_LEMBAGA_PEMERINGKATAN: [],
                RATING_PIHAK_LAWAN: [],
                R_SUMBER_PENGHASILAN: [],
                R_TUJUAN_BUKA_REKENING: [],
                _identitasNasabah: {
                    provinces: [],
                    cities: [],
                    subdistricts: [],
                    urbans: [],
                    postalscode: [],
                },
                _alamatAlternatif: {
                    provinces: [],
                    cities: [],
                    subdistricts: [],
                    urbans: [],
                    postalscode: [],
                },
                _pejabatKorporatForm: {
                    provinces: [],
                    cities: [],
                    subdistricts: [],
                    urbans: [],
                    postalscode: [],
                },
                _relasiNasabahForm: {
                    provinces: [],
                    cities: [],
                    subdistricts: [],
                    urbans: [],
                    postalscode: [],
                },
                _beneficiaryOwner: {
                    provinces: [],
                    cities: [],
                    subdistricts: [],
                    urbans: [],
                    postalscode: [],
                },
            },
            messageError: null,
            messageWarning: null,
            searchNasabahData: [],
            searchNasabahType: 'CLIENT',
            searchNasabahSearchBy: 'name',
            searchNasabahSearch: '',
            branch: {
                name: 'Head Office',
            },
        };
    }

    componentDidMount() {
        this.getDropdowns('R_BADAN_HUKUM_KORPORAT');
        this.getDropdowns('eStatusKeterkaitan');
        this.getDropdowns('eStrukturModal');
        this.getDropdowns('R_KL');
        this.getDropdowns('R_SEKTOR_EKONOMI');
        this.getDropdowns('R_BIDANG_USAHA');
        this.getDropdowns('eJenisPenduduk');
        this.getDropdowns('R_JABATAN_KORPORAT');
        this.getDropdowns('eJenisIdentitas');
        this.getDropdowns('eKewarganegaraan');
        this.getDropdowns('R_PEKERJAAN');
        this.getDropdowns('R_KODE_NEGARA');
        this.getDropdowns('R_PENGELUARAN_PERTAHUN');
        this.getDropdowns('ANTASENA_GOL_PIHAK_LAWAN');
        this.getDropdowns('R_SUMBER_DANA_CIF_KORPORAT');
        this.getDropdowns('R_TUJUAN_DANA_CIF_KORPORAT');
        this.getDropdowns('R_FAKTOR_RESIKO');
        this.getDropdowns('R_RELASI_NASABAH');
        this.getDropdowns('ANTASENA_LEMBAGA_PEMERINGKATAN');
        this.getDropdowns('R_SUMBER_PENGHASILAN');
        this.getDropdowns('R_TUJUAN_BUKA_REKENING');
        this.getProvinces();
        this.getBranch();
        this.getData();
    }

    getBranch = () => {
        const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };
            fetch(
                baseUrl + `/rekening/tabungan/kantor/cabang/${this.state.assignedBranchKey}/detail`,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.message_id === '00') {
                        this.setState({
                            branch: data.list,
                        });
                    } else {
                        this.setState({
                            branch: {
                                encodedKey: '8a8e8fab786e635c0178863b7911431e',
                                id: '1001',
                                created_at: '2021-03-31T12:49:42+07:00',
                                lastModifiedDate: '2021-03-31T12:49:42+07:00',
                                name: 'Head Office',
                                state: 'ACTIVE',
                                phoneNumber: '+62 21 39708008 ',
                                emailAddress: 'info@banknetsyariah.co.id',
                                notes: '',
                                branchHolidays: [],
                                addresses: [
                                    {
                                        encodedKey: '8a8e8fab786e635c0178863b7911431f',
                                        parentKey: '8a8e8fab786e635c0178863b7911431e',
                                        line1: 'Gedung Millennium Centennial Center, Lt 7, Jl. Jenderal Sudirman Kav. 25, , Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta, 12920, Indonesia',
                                        line2: '',
                                        city: 'Kota Jakarta Selatan',
                                        region: 'Jakarta',
                                        postcode: '12920',
                                        country: 'Indonesia',
                                        indexInList: -1,
                                    },
                                ],
                            },
                        });
                    }
                });
    }

    getData = () => {
        const queryParams = queryString.parse(this.props.location.search);
        if (queryParams.mode === 'info' || queryParams.mode === 'update') {
            let getUrl = `/nasabah-badan-usaha/local/${queryParams.db_id}`;
                // queryParams.from === 'lokal'
                //     ? '/nasabah-badan-usaha/lokal/nasabah/cari-db-id?db_id=' + queryParams.db_id
                //     : '/nasabah-badan-usaha/find/' + queryParams.db_id + '?detailsLevel=FULL';
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };
            fetch(baseUrl + getUrl, requestOptions)
                .then((response) => response.json())
                .then((resp) => {
                    let dataDetail = resp.status === "00" ? resp.data.nasabahBadanUsaha : {} ;
                    this.setState({
                        ...dataDetail,
                        isStep0Completed: true,
                        isStep1Completed: true,
                        isStep2Completed: true,
                        isStep3Completed: true,
                        isStep4Completed: true,
                        isStep5Completed: true,
                        mode: queryParams.mode,
                        id: dataDetail?.id,
                        encodedKey: dataDetail?.encodedKey,
                        created_at: dataDetail?.created_at,
                        groupName: dataDetail?.groupName,
                        nasabahNamaPerusahaan: dataDetail?.groupName,
                        emailAddress: dataDetail?.emailAddress,
                        assignedBranchKey: dataDetail?.assignedBranchKey,
                        _identitasNasabah: {
                            nasabahLokasi: dataDetail?.nasabahLokasi,
                            nasabahAsetPerusahaan: dataDetail?.nasabahAsetPerusahaan,
                            nasabahNamaPerusahaan: dataDetail?.nasabahNamaPerusahaan,
                            nasabahOmsetPerusahaan: dataDetail?.nasabahOmsetPerusahaan,
                            nasabahPicNasabah: dataDetail?.nasabahPicNasabah,
                            nasabahProvinsi: dataDetail?.nasabahProvinsi,
                            nasabahSektorEkonomi: dataDetail?.nasabahSektorEkonomi,
                            nasabahNomorSkMenkumham: dataDetail?.nasabahNomorSkMenkumham,
                            nasabahEmailAddress: dataDetail?.nasabahEmailAddress,
                            nasabahTeleponKantorNomor3: dataDetail?.nasabahTeleponKantorNomor3,
                            nasabahNomorNpwp: dataDetail?.nasabahNomorNpwp,
                            nasabahKelurahan: dataDetail?.nasabahKelurahan,
                            nasabahAlamat: dataDetail?.nasabahAlamat,
                            nasabahBidangUsaha: dataDetail?.nasabahBidangUsaha,
                            nasabahNasabahInduk: dataDetail?.nasabahNasabahInduk,
                            nasabahTanggalPengesahanSkdp: dataDetail?.nasabahTanggalPengesahanSkdp,
                            nasabahBadanUsaha: dataDetail?.nasabahBadanUsaha,
                            nasabahTanggalSkMenkumham: dataDetail?.nasabahTanggalSkMenkumham,
                            nasabahBranchCode: dataDetail?.nasabahBranchCode,
                            nasabahNomorSkdp: dataDetail?.nasabahNomorSkdp,
                            nasabahKotaKabupaten: dataDetail?.nasabahKotaKabupaten,
                            nasabahKodePos: dataDetail?.nasabahKodePos,
                            nasabahTempatAktePendirian: dataDetail?.nasabahTempatAktePendirian,
                            nasabahStatusKeterkaitan: dataDetail?.nasabahStatusKeterkaitan,
                            nasabahTeleponKantorKodeArea1: dataDetail?.nasabahTeleponKantorKodeArea1,
                            nasabahTanggalAktePendirian: dataDetail?.nasabahTanggalAktePendirian,
                            nasabahNomorAktePerubahan: dataDetail?.nasabahNomorAktePerubahan,
                            nasabahTeleponKantorKodeArea2: dataDetail?.nasabahTeleponKantorKodeArea2,
                            nasabahTeleponKantorKodeArea3: dataDetail?.nasabahTeleponKantorKodeArea3,
                            nasabahFaxNomor: dataDetail?.nasabahFaxNomor,
                            nasabahNomorAktePendirian: dataDetail?.nasabahNomorAktePendirian,
                            nasabahTanggalKadaluarsaSkdp: dataDetail?.nasabahTanggalKadaluarsaSkdp,
                            nasabahKodeMarketing: dataDetail?.nasabahKodeMarketing,
                            nasabahTanggalAktePerubahan: dataDetail?.nasabahTanggalAktePerubahan,
                            nasabahKepemilikanModal: dataDetail?.nasabahKepemilikanModal,
                            nasabahJenisPenduduk: dataDetail?.nasabahJenisPenduduk,
                            nasabahNomorSiupp: dataDetail?.nasabahNomorSiupp,
                            nasabahTanggalPengesahanSiupp: dataDetail?.nasabahTanggalPengesahanSiupp,
                            nasabahRw: dataDetail?.nasabahRw,
                            nasabahNomorTDP: dataDetail?.nasabahNomorTDP,
                            nasabahWilayahPemberiReferensi: dataDetail?.nasabahWilayahPemberiReferensi,
                            nasabahTeleponKantorNomor2: dataDetail?.nasabahTeleponKantorNomor2,
                            nasabahRt: dataDetail?.nasabahRt,
                            nasabahTeleponKantorNomor1: dataDetail?.nasabahTeleponKantorNomor1,
                            nasabahFaxKodeArea: dataDetail?.nasabahFaxKodeArea,
                            nasabahKecamatan: dataDetail?.nasabahKecamatan,
                            nasabahBankNonBank: dataDetail?.nasabahBankNonBank,
                            nasabahPemilik: dataDetail?.nasabahPemilik,
                            nasabahKegiatanusaha: dataDetail?.nasabahKegiatanusaha,
                        },
                        _beneficiaryOwner: {
                            benefKecamatan: dataDetail?.benefKecamatan,
                            benefNamaPerusahaan: dataDetail?.benefNamaPerusahaan,
                            benefRw: dataDetail?.benefRw,
                            benefKodePos: dataDetail?.benefKodePos,
                            benefProvinsi: dataDetail?.benefProvinsi,
                            benefNomorSiupp: dataDetail?.benefNomorSiupp,
                            benefBentukUsaha: dataDetail?.benefBentukUsaha,
                            benefBidangUsaha: dataDetail?.benefBidangUsaha,
                            benefRt: dataDetail?.benefRt,
                            benefKeterangan: dataDetail?.benefKeterangan,
                            benefSumberDana: dataDetail?.benefSumberDana,
                            benefAlamat: dataDetail?.benefAlamat,
                            benefKelurahan: dataDetail?.benefKelurahan,
                            benefKotaKabupaten: dataDetail?.benefKotaKabupaten,
                            benefTujuanPenggunaanDana: dataDetail?.benefTujuanPenggunaanDana,
                            nasabahKodePos: dataDetail?.nasabahKodePos
                        },
                        "_alamatAlternatif": {
                            "alternatifKodePos": dataDetail?.alternatifKodePos,
                            "alternatifProvinsi": dataDetail?.alternatifProvinsi,
                            "alternatifKotaKabupaten": dataDetail?.alternatifKotaKabupaten,
                            "alternatifRtRw": dataDetail?.alternatifRtRw,
                            "alternatifKecamatan": dataDetail?.alternatifKecamatan,
                            "alternatifAlamat": dataDetail?.alternatifAlamat,
                            "alternatifTelepon":dataDetail?.alternatifTelepon,
                            "alternatifKelurahan":dataDetail?.alternatifKelurahan
                        },
                        "_cdd": {
                            "cddFaktorResiko": dataDetail?.cddFaktorResiko,
                            "cddStatusResiko": dataDetail?.cddStatusResiko,
                            "cddSumberDana": dataDetail?.cddSumberDana,
                            "cddSumberPenghasilan": dataDetail?.cddSumberPenghasilan,
                            "cddTujuanPenggunaanDana": dataDetail?.cddTujuanPenggunaanDana,
                            "cddTujuanBukaRekening": dataDetail?.cddTujuanBukaRekening,
                            "cddGolonganNasabah": dataDetail?.cddGolonganNasabah,
                            "cddPenghasilanKotorPerTahun": dataDetail?.cddPenghasilanKotorPerTahun
                        },
                    //     _pejabatKorporat:
                    //         dataDetail._pejabatKorporat || dataDetail._pejabatKorporat === null
                    //             ? dataDetail._pejabatKorporat
                    //             : [],
                    //     _cdd: dataDetail?._cdd,
                    //     _relasiNasabah:
                    //         dataDetail._relasiNasabah || dataDetail._relasiNasabah === null
                    //             ? dataDetail._relasiNasabah
                    //             : [],
                    //     _beneficiaryOwner: dataDetail._beneficiaryOwner
                    //         ? dataDetail._beneficiaryOwner
                    //         : {
                    //               benefKecamatan: null,
                    //               benefNamaPerusahaan: null,
                    //               benefRw: null,
                    //               benefKodePos: null,
                    //               benefProvinsi: null,
                    //               benefNomorSiupp: null,
                    //               benefBentukUsaha: null,
                    //               benefBidangUsaha: null,
                    //               benefRt: null,
                    //               benefKeterangan: null,
                    //               benefSumberDana: null,
                    //               benefAlamat: null,
                    //               benefKelurahan: null,
                    //               benefKotaKabupaten: null,
                    //               benefTujuanPenggunaanDana: null,
                    //           },
                    //     _pihakLawan: dataDetail._pihakLawan ? dataDetail._pihakLawan : {
                    //         pihakLawanLembagaPemeringkatan: null,
                    //         pihakLawanRating: null,
                    //         pihakLawanTanggalPemeringkatan: null,
                    //         pihakLawanIdGrup: null,
                    //     },
                    //     _alamatAlternatif: {
                    //         alternatifKodePos: dataDetail?._alamatAlternatif?.alternatifKodePos
                    //             ? dataDetail._alamatAlternatif.alternatifKodePos
                    //             : null,
                    //         alternatifProvinsi: dataDetail?._alamatAlternatif?.alternatifProvinsi
                    //             ? dataDetail._alamatAlternatif.alternatifProvinsi
                    //             : null,
                    //         alternatifKotaKabupaten: dataDetail?._alamatAlternatif
                    //             ?.alternatifKotaKabupaten
                    //             ? dataDetail._alamatAlternatif.alternatifKotaKabupaten
                    //             : null,
                    //         alternatifRtRw: dataDetail?._alamatAlternatif?.alternatifRtRw
                    //             ? dataDetail._alamatAlternatif.alternatifRtRw
                    //             : null,
                    //         alternatifRt: dataDetail?._alamatAlternatif?.alternatifRtRw
                    //             ? dataDetail._alamatAlternatif.alternatifRtRw.split('/')[0]
                    //             : null,
                    //         alternatifRw: dataDetail?._alamatAlternatif?.alternatifRtRw
                    //             ? dataDetail._alamatAlternatif.alternatifRtRw.split('/')[1]
                    //             : null,
                    //         alternatifKecamatan: dataDetail?._alamatAlternatif?.alternatifKecamatan
                    //             ? dataDetail._alamatAlternatif.alternatifKecamatan
                    //             : null,
                    //         alternatifAlamat: dataDetail?._alamatAlternatif?.alternatifAlamat
                    //             ? dataDetail._alamatAlternatif.alternatifAlamat
                    //             : null,
                    //         alternatifTelepon: dataDetail?._alamatAlternatif?.alternatifTelepon
                    //             ? dataDetail._alamatAlternatif.alternatifTelepon
                    //             : null,
                    //         alternatifKelurahan: dataDetail?._alamatAlternatif?.alternatifKelurahan
                    //             ? dataDetail._alamatAlternatif.alternatifKelurahan
                    //             : null,
                    //     },
                    //     _svsCorporate: dataDetail?._svsCorporate,
                    //     _negatifNasabah: dataDetail?._negatifNasabah,
                    });
                });
        }
    };

    searchNasabah = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        let url = '';
        if (this.state.showModalSearchNasabah === '_pejabatKorporat') {
            url = `/nasabah/filter?limit=50&page=0&type=CLIENT&searchBy=${this.state.searchNasabahSearchBy}&search=${this.state.searchNasabahSearch}`;;
        } else {
            url = `/nasabah/filter?limit=50&page=0&type=${this.state.searchNasabahType}&searchBy=${this.state.searchNasabahSearchBy}&search=${this.state.searchNasabahSearch}`;;
        }
        fetch(
            baseUrl + url,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    this.setState({
                        searchNasabahData: dataDetail,
                    });
                } else {
                    alert(data.status);
                }
            });
    };

    getDropdowns = (key) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/dropdowns?key=' + key, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let dataList = data.data?.listDropdown||[];
                let newdatalist= dataList.map((item) => { 
                    return {
                        ...item,
                        reference_desc: item.reference_desc.toUpperCase()
                    }; 
                });
                // console.log(dataList);
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [key]: newdatalist,
                    },
                }));

                // let dataList = data.list;
                // let newdatalist= dataList.map((item) => { 
                //     return {
                //         ...item,
                //         reference_desc: item.reference_desc.toUpperCase()
                //     }; 
                // });
                // // console.log(dataList);
                // this.setState((prevState) => ({
                //     _fields: {
                //         ...prevState._fields,
                //         [key]: newdatalist,
                //     },
                // }));
            });
    };

    getRatingPihakLawan = (value) => {
        let splitValue = value.split(' - ');
        let key = splitValue[0];
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/dropdowns?key=' + key, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        RATING_PIHAK_LAWAN: data.list,
                    },
                }));
            });
    };

    getProvinces = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/locations/provinces', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        _identitasNasabah: {
                            provinces: data.data?.listProvinces||[],
                        },
                        _alamatAlternatif: {
                            provinces: data.data?.listProvinces||[],
                        },
                        _pejabatKorporatForm: {
                            provinces: data.data?.listProvinces||[],
                        },
                        _relasiNasabahForm: {
                            provinces: data.data?.listProvinces||[],
                        },
                        _beneficiaryOwner: {
                            provinces: data.data?.listProvinces||[],
                        },
                    },
                }));
            });
    };

    getCities = (field, province) => {
        // console.log(field);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/locations/cities?province_code=' + province, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [field]: {
                            ...prevState._fields[field],
                            cities: data.data?.listCities||[],
                        },
                    },
                }));
            });
    };

    getSubDistricts = (field, city) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/locations/subdistrict?city=' + city, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [field]: {
                            ...prevState._fields[field],
                            subdistricts: data.data?.listSubdistricts||[],
                        },
                    },
                }));
            });
    };

    getUrbans = (field, subdistrict, city) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(
            baseUrl + `/locations/urban?city=${city}&subdistrict=${subdistrict}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                // // console.log(data);
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [field]: {
                            ...prevState._fields[field],
                            urbans: data.data?.listUrbans||[],
                        },
                    },
                }));
            });
    };

    getPostalsCode = (field, city, subdistrict, urban) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(
            baseUrl +
                `/locations/postal-code?city=${city}&subdistrict=${subdistrict}&urban=${urban}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [field]: {
                            ...prevState._fields[field],
                            postalscode: data.data?.listUrbans||[],
                        },
                    },
                }));
            });
    };

    setStep = (step) => {
        this.setState({
            step,
        });
    };

    validate = () => {
        const {
            groupName,
            emailAddress,
            _identitasNasabah,
            _pejabatKorporatForm,
            _cdd,
            _relasiNasabahForm,
            _beneficiaryOwner,
        } = this.state;

        // initial errors
        let errors = {
            groupName: false,
            emailAddress: false,
            nasabahBadanUsaha: false,
            nasabahNomorNpwp: false,
            nasabahNomorAktePendirian: false,
            nasabahTanggalAktePendirian: false,
            nasabahLokasi: false,
            nasabahSektorEkonomi: false,
            nasabahAlamat: false,
            nasabahProvinsi: false,
            nasabahKotaKabupaten: false,
            nasabahKecamatan: false,
            nasabahKelurahan: false,
            // nasabahRw: false,
            // nasabahRt: false,
            nasabahTeleponKantorKodeArea1: false,
            nasabahTeleponKantorNomor1: false,
            cddPenghasilanKotorPerTahun: false,
            cddGolonganNasabah: false,
            cddSumberDana: false,
            cddSumberPenghasilan: false,
            cddTujuanPenggunaanDana: false,
            cddFaktorResiko: false,
            cddStatusResiko: false,
        };

        let isStep0Completed = this.state.isStep0Completed;
        let isStep1Completed = this.state.isStep1Completed;
        let isStep2Completed = this.state.isStep2Completed;
        let isStep3Completed = this.state.isStep3Completed;
        let isStep4Completed = this.state.isStep4Completed;
        let isStep5Completed = this.state.isStep5Completed;

        // validate step 0
        if (this.state.step === 0) {
            if (
                _identitasNasabah.nasabahBadanUsaha === '' ||
                _identitasNasabah.nasabahBadanUsaha === null
            ) {
                errors = { ...errors, nasabahBadanUsaha: true };
            }
            if (groupName === '' || groupName === null) {
                errors = { ...errors, groupName: true };
            }
            if (emailAddress === '' || emailAddress === null) {
                errors = { ...errors, emailAddress: true };
            }
            if (
                _identitasNasabah.nasabahNomorNpwp === '' ||
                _identitasNasabah.nasabahNomorNpwp === null
            ) {
                errors = { ...errors, nasabahNomorNpwp: true, nasabahNomorNpwpRequired: true };
            }
            if (
                _identitasNasabah.nasabahNomorNpwp.length < 15 ||
                _identitasNasabah.nasabahNomorNpwp.length > 15
            ) {
                errors = { ...errors, nasabahNomorNpwp: true, nasabahNomorNpwpLength: true };
            }
            if (
                _identitasNasabah.nasabahNomorAktePendirian === '' ||
                _identitasNasabah.nasabahNomorAktePendirian === null
            ) {
                errors = { ...errors, nasabahNomorAktePendirian: true };
            }
            if (
                _identitasNasabah.nasabahTanggalAktePendirian === '' ||
                _identitasNasabah.nasabahTanggalAktePendirian === null
            ) {
                errors = { ...errors, nasabahTanggalAktePendirian: true };
            }
            if (
                _identitasNasabah.nasabahLokasi === '' ||
                _identitasNasabah.nasabahLokasi === null
            ) {
                errors = { ...errors, nasabahLokasi: true };
            }
            if (
                _identitasNasabah.nasabahSektorEkonomi === '' ||
                _identitasNasabah.nasabahSektorEkonomi === null
            ) {
                errors = { ...errors, nasabahSektorEkonomi: true };
            }
            if (
                _identitasNasabah.nasabahAlamat === '' ||
                _identitasNasabah.nasabahAlamat === null
            ) {
                errors = { ...errors, nasabahAlamat: true };
            }
            if (
                _identitasNasabah.nasabahProvinsi === '' ||
                _identitasNasabah.nasabahProvinsi === null
            ) {
                errors = { ...errors, nasabahProvinsi: true };
            }
            if (
                _identitasNasabah.nasabahKotaKabupaten === '' ||
                _identitasNasabah.nasabahKotaKabupaten === null
            ) {
                errors = { ...errors, nasabahKotaKabupaten: true };
            }
            if (
                _identitasNasabah.nasabahKecamatan === '' ||
                _identitasNasabah.nasabahKecamatan === null
            ) {
                errors = { ...errors, nasabahKecamatan: true };
            }
            if (
                _identitasNasabah.nasabahKelurahan === '' ||
                _identitasNasabah.nasabahKelurahan === null
            ) {
                errors = { ...errors, nasabahKelurahan: true };
            }

            // if (_identitasNasabah.nasabahRw === '' || _identitasNasabah.nasabahRw === null) {
            //     errors = { ...errors, nasabahRw: true };
            // }

            // if (_identitasNasabah.nasabahRt === '' || _identitasNasabah.nasabahRt === null) {
            //     errors = { ...errors, nasabahRt: true };
            // }

            if (
                _identitasNasabah.nasabahTeleponKantorKodeArea1 === '' ||
                _identitasNasabah.nasabahTeleponKantorKodeArea1 === null
            ) {
                errors = { ...errors, nasabahTeleponKantorKodeArea1: true };
            }
            if (
                _identitasNasabah.nasabahTeleponKantorNomor1 === '' ||
                _identitasNasabah.nasabahTeleponKantorNomor1 === null
            ) {
                errors = { ...errors, nasabahTeleponKantorNomor1: true };
            }

            if (
                !errors.groupName &&
                !errors.nasabahBadanUsaha &&
                !errors.nasabahNomorNpwp &&
                !errors.nasabahNomorAktePendirian &&
                !errors.nasabahTanggalAktePendirian &&
                !errors.nasabahLokasi &&
                !errors.nasabahSektorEkonomi &&
                !errors.nasabahAlamat &&
                !errors.nasabahProvinsi &&
                !errors.nasabahKotaKabupaten &&
                !errors.nasabahKecamatan &&
                !errors.nasabahKelurahan &&
                // !errors.nasabahRw &&
                // !errors.nasabahRt &&
                !errors.emailAddress &&
                !errors.nasabahTeleponKantorKodeArea1 &&
                !errors.nasabahTeleponKantorNomor1
            ) {
                isStep0Completed = true;
            }

            this.setState({
                errors: errors,
                isStep0Completed: isStep0Completed,
            });
        }

        // validate step 1
        if (this.state.step === 1) {
            let _pejabatKorporat = this.state._pejabatKorporat;
            if (
                this.state._pejabatKorporatForm.pejabatNama === null ||
                this.state._pejabatKorporatForm.pejabatNama === ''
            ) {
                this.setState({
                    isStep1Completed: true,
                    step: this.state.step + 1,
                });
                return false;
            }
            _pejabatKorporat = [..._pejabatKorporat, this.state._pejabatKorporatForm];
            isStep1Completed = true;
            this.setState({
                errors: errors,
                isStep1Completed: isStep1Completed,
                _pejabatKorporat,
                _pejabatKorporatForm: {
                    pejabatKecamatan: null,
                    pejabatNomorNasabahPejabat: null,
                    pejabatAlamat: null,
                    pejabatNomorNpwp: null,
                    pejabatKeterangan: null,
                    pejabatNegaraAsal: null,
                    pejabatKotaKabupaten: null,
                    pejabatNomorHandphone: null,
                    pejabatJabatan: null,
                    pejabatNomorIdentitas: null,
                    pejabatPekerjaan: null,
                    pejabatTeleponKodeArea: null,
                    pejabatJenisIdentitas: null,
                    pejabatNama: null,
                    pejabatEmailAddress: null,
                    pejabatTeleponNomor: null,
                    pejabatKelurahan: null,
                    pejabatRw: null,
                    pejabatPersentaseKepemilikan: null,
                    pejabatProvinsi: null,
                    pejabatFaxKodeArea: null,
                    pejabatFaxNomor: null,
                    pejabatRt: null,
                    pejabatKewarganegaraan: null,
                },
                showModalTambahPejabat: false,
                _pejabatKorporatFormMode: 'create',
                _pejabatKorporatFormModeDisabled: {
                    pejabatNama: false,
                    pejabatNomorHandphone: false,
                    pejabatNomorNasabahPejabat: false,
                    pejabatEmailAddress: false,
                    pejabatAlamat: false,
                    pejabatRt: false,
                    pejabatRw: false,
                    pejabatProvinsi: false,
                    pejabatKotaKabupaten: false,
                    pejabatKecamatan: false,
                    pejabatKelurahan: false,
                    pejabatKodePos: false,
                    pejabatNomorIdentitas: false,
                    pejabatJenisIdentitas: false,
                    pejabatKewarganegaraan: false,
                },
            });
        }

        // validate step 2
        if (this.state.step === 2) {
            if (
                _cdd.cddPenghasilanKotorPerTahun === '' ||
                _cdd.cddPenghasilanKotorPerTahun === null
            ) {
                errors = { ...errors, cddPenghasilanKotorPerTahun: true };
            }
            if (_cdd.cddGolonganNasabah === '' || _cdd.cddGolonganNasabah === null) {
                errors = { ...errors, cddGolonganNasabah: true };
            }
            if (_cdd.cddSumberDana === '' || _cdd.cddSumberDana === null) {
                errors = { ...errors, cddSumberDana: true };
            }
            if (_cdd.cddSumberPenghasilan === '' || _cdd.cddSumberPenghasilan === null) {
                errors = { ...errors, cddSumberPenghasilan: true };
            }
            if (_cdd.cddTujuanPenggunaanDana === '' || _cdd.cddTujuanPenggunaanDana === null) {
                errors = { ...errors, cddTujuanPenggunaanDana: true };
            }
            // if (_cdd.cddLimitNominalSetorTunai === '' || _cdd.cddLimitNominalSetorTunai === null) {
            //     errors = { ...errors, cddLimitNominalSetorTunai: true };
            // }
            // if (
            //     _cdd.cddLimitFrekuensiSetorTunai === '' ||
            //     _cdd.cddLimitFrekuensiSetorTunai === null
            // ) {
            //     errors = { ...errors, cddLimitFrekuensiSetorTunai: true };
            // }
            // if (
            //     _cdd.cddLimitNominalSetorNonTunai === '' ||
            //     _cdd.cddLimitNominalSetorNonTunai === null
            // ) {
            //     errors = { ...errors, cddLimitNominalSetorNonTunai: true };
            // }
            // if (
            //     _cdd.cddLimitFrekuensiSetorNonTunai === '' ||
            //     _cdd.cddLimitFrekuensiSetorNonTunai === null
            // ) {
            //     errors = { ...errors, cddLimitFrekuensiSetorNonTunai: true };
            // }
            if (_cdd.cddFaktorResiko === '' || _cdd.cddFaktorResiko === null) {
                errors = { ...errors, cddFaktorResiko: true };
            }
            if (_cdd.cddStatusResiko === '' || _cdd.cddStatusResiko === null) {
                errors = { ...errors, cddStatusResiko: true };
            }

            if (
                !errors.cddPenghasilanKotorPerTahun &&
                !errors.cddGolonganNasabah &&
                !errors.cddSumberDana &&
                !errors.cddSumberPenghasilan &&
                !errors.cddTujuanPenggunaanDana &&
                !errors.cddFaktorResiko &&
                !errors.cddStatusResiko
            ) {
                isStep2Completed = true;
            }

            this.setState({
                errors: errors,
                isStep2Completed: isStep2Completed,
            });
        }

        // validate step 3
        if (this.state.step === 3) {
            let _relasiNasabah = this.state._relasiNasabah;
            isStep3Completed = true;
            if (this.state._relasiNasabahForm.relasiNomorNasabah !== null) {
                _relasiNasabah = [..._relasiNasabah, this.state._relasiNasabahForm];
                this.setState({
                    errors: errors,
                    isStep3Completed: isStep3Completed,
                    _relasiNasabah,
                    _relasiNasabahForm: {
                        relasiNomorNasabah: null,
                        relasiFaxNomor: null,
                        relasiKelurahan: null,
                        relasiPekerjaan: null,
                        relasiFaxKodeArea: null,
                        relasiAlamat: null,
                        relasiNomorIdentitas: null,
                        relasiTeleponRumahNomor: null,
                        relasiEmailAddress: null,
                        relasiNomorNpwp: null,
                        relasiKewarganegaraan: null,
                        relasiRw: null,
                        relasiNegaraAsal: null,
                        relasiRt: null,
                        relasiJenisIdentitas: null,
                        relasiNomorHandphone: null,
                        relasiJabatanPangkat: null,
                        relasiKotaKabupaten: null,
                        relasiTeleponRumahKodeArea: null,
                        relasiNama: null,
                        relasiProvinsi: null,
                        relasiHubunganDenganNasabah: null,
                        relasiKecamatan: null,
                    },
                    showModalTambahRelasi: false,
                    _relasiNasabahFormMode: 'create',
                    _relasiNasabahFormButtonMode: 'create',
                });
            } else {
                this.setState({
                    isStep3Completed: isStep3Completed,
                });
            }
        }

        // validate step 4
        if (this.state.step === 4) {
            isStep4Completed = true;
            this.setState({
                isStep4Completed: isStep4Completed,
            });
        }

        // validate step 5
        if (this.state.step === 5) {
            isStep5Completed = true;
            this.setState({
                isStep5Completed: isStep5Completed,
            });
        }
    };

    saveStep = () => {
        this.validate();
        const {
            isStep0Completed,
            isStep1Completed,
            isStep2Completed,
            isStep3Completed,
            isStep4Completed,
            isStep5Completed,
        } = this.state;
        let showModalConfirm = false;
        if (isStep0Completed && isStep2Completed) {
            let showModalConfirm = true;
        }
        this.setState({
            showModalConfirm: showModalConfirm,
        });
    };

    handleInputChange = (event, isObject = false, key = null) => {
        const value = event.target.value;
        const name = event.target.name;
        if (isObject) {
            this.setState((prevState) => ({
                [key]: {
                    ...prevState[key],
                    [name]: value.toUpperCase(), 
                },
            }));
        } else {
            this.setState({
                [name]: value.toUpperCase(),
            });
        }
    };

    saveRBA = () => {
        const { rba } = this.state;
        let rbaErrors = {
            answer1: false,
            answer2: false,
            answer3: false,
            answer4: false,
            answer5: false,
            answer6: false,
            answer7: false,
            answer8: false,
            answer9: false,
        };

        if (rba.answer1 === '' || rba.answer1 === null) {
            rbaErrors = { ...rbaErrors, answer1: true };
        }
        if (rba.answer2 === '' || rba.answer2 === null) {
            rbaErrors = { ...rbaErrors, answer2: true };
        }
        if (rba.answer3 === '' || rba.answer3 === null) {
            rbaErrors = { ...rbaErrors, answer3: true };
        }
        if (rba.answer4 === '' || rba.answer4 === null) {
            rbaErrors = { ...rbaErrors, answer4: true };
        }
        if (rba.answer5 === '' || rba.answer5 === null) {
            rbaErrors = { ...rbaErrors, answer5: true };
        }
        if (rba.answer6 === '' || rba.answer6 === null) {
            rbaErrors = { ...rbaErrors, answer6: true };
        }
        if (rba.answer7 === '' || rba.answer7 === null) {
            rbaErrors = { ...rbaErrors, answer7: true };
        }
        if (rba.answer8 === '' || rba.answer8 === null) {
            rbaErrors = { ...rbaErrors, answer8: true };
        }
        if (rba.answer9 === '' || rba.answer9 === null) {
            rbaErrors = { ...rbaErrors, answer9: true };
        }

        if (
            !rbaErrors.answer1 &&
            !rbaErrors.answer2 &&
            !rbaErrors.answer3 &&
            !rbaErrors.answer4 &&
            !rbaErrors.answer5 &&
            !rbaErrors.answer6 &&
            !rbaErrors.answer7 &&
            !rbaErrors.answer8 &&
            !rbaErrors.answer9
        ) {
            let cddStatusResiko;
            if (
                this.state.rba.answer1 == '1' &&
                this.state.rba.answer2 == '1' &&
                this.state.rba.answer3 == '1' &&
                this.state.rba.answer4 == '1' &&
                this.state.rba.answer5 == '1' &&
                this.state.rba.answer6 == '1' &&
                this.state.rba.answer7 == '1' &&
                this.state.rba.answer8 == '1' &&
                this.state.rba.answer9 == '1'
            ) {
                cddStatusResiko = 'Low';
            } else {
                cddStatusResiko = 'High';
            }
            this.setState((prevState) => ({
                _cdd: {
                    ...prevState._cdd,
                    cddStatusResiko: cddStatusResiko,
                },
                showModalRBA: false,
            }));
        } else {
            this.setState({
                rbaErrors,
            });
        }
    };

    savePejabat = () => {
        let isStep1Completed = this.state.isStep1Completed;
        let _pejabatKorporat = this.state._pejabatKorporat;
            if (
                this.state._pejabatKorporatForm.pejabatNama === null ||
                this.state._pejabatKorporatForm.pejabatNama === ''
            ) {
                this.setState({
                    isStep1Completed: true,
                    step: this.state.step + 1,
                });
                return false;
            }
            _pejabatKorporat = [..._pejabatKorporat, this.state._pejabatKorporatForm];
            isStep1Completed = true;
            this.setState({
                isStep1Completed: isStep1Completed,
                _pejabatKorporat,
                _pejabatKorporatForm: {
                    pejabatKecamatan: null,
                    pejabatNomorNasabahPejabat: null,
                    pejabatAlamat: null,
                    pejabatNomorNpwp: null,
                    pejabatKeterangan: null,
                    pejabatNegaraAsal: null,
                    pejabatKotaKabupaten: null,
                    pejabatNomorHandphone: null,
                    pejabatJabatan: null,
                    pejabatNomorIdentitas: null,
                    pejabatPekerjaan: null,
                    pejabatTeleponKodeArea: null,
                    pejabatJenisIdentitas: null,
                    pejabatNama: null,
                    pejabatEmailAddress: null,
                    pejabatTeleponNomor: null,
                    pejabatKelurahan: null,
                    pejabatRw: null,
                    pejabatPersentaseKepemilikan: null,
                    pejabatProvinsi: null,
                    pejabatFaxKodeArea: null,
                    pejabatFaxNomor: null,
                    pejabatRt: null,
                    pejabatKewarganegaraan: null,
                },
                showModalTambahPejabat: false,
                _pejabatKorporatFormMode: 'create',
                _pejabatKorporatFormModeDisabled: {
                    pejabatNama: false,
                    pejabatNomorHandphone: false,
                    pejabatNomorNasabahPejabat: false,
                    pejabatEmailAddress: false,
                    pejabatAlamat: false,
                    pejabatRt: false,
                    pejabatRw: false,
                    pejabatProvinsi: false,
                    pejabatKotaKabupaten: false,
                    pejabatKecamatan: false,
                    pejabatKelurahan: false,
                    pejabatKodePos: false,
                    pejabatNomorIdentitas: false,
                    pejabatJenisIdentitas: false,
                    pejabatKewarganegaraan: false,
                },
            });
    }

    detailPejabat = (index) => {
        const pejabatKorporat = this.state._pejabatKorporat[index];
        // // console.log(pejabatKorporat);
        this.setState({
            showModalTambahPejabat: true,
            _pejabatKorporatForm: pejabatKorporat,
            _pejabatKorporatFormMode: 'info',
        });
    };

    ubahPejabat = (index) => {
        const pejabatKorporat = this.state._pejabatKorporat[index];
        this.setState({
            showModalTambahPejabat: true,
            _pejabatKorporatForm: pejabatKorporat,
            _pejabatKorporatFormMode: 'update',
            _pejabatKorporatIndex: index,
        });
    };

    updatePejabat = () => {
        let _pejabatKorporat = [...this.state._pejabatKorporat];
        _pejabatKorporat[this.state._pejabatKorporatIndex] = this.state._pejabatKorporatForm;
        this.setState({
            _pejabatKorporat,
            _pejabatKorporatForm: {
                pejabatKecamatan: null,
                pejabatNomorNasabahPejabat: null,
                pejabatAlamat: null,
                pejabatNomorNpwp: null,
                pejabatKeterangan: null,
                pejabatNegaraAsal: null,
                pejabatKotaKabupaten: null,
                pejabatNomorHandphone: null,
                pejabatJabatan: null,
                pejabatNomorIdentitas: null,
                pejabatPekerjaan: null,
                pejabatTeleponKodeArea: null,
                pejabatJenisIdentitas: null,
                pejabatNama: null,
                pejabatEmailAddress: null,
                pejabatTeleponNomor: null,
                pejabatKelurahan: null,
                pejabatRw: null,
                pejabatPersentaseKepemilikan: null,
                pejabatProvinsi: null,
                pejabatFaxKodeArea: null,
                pejabatFaxNomor: null,
                pejabatRt: null,
                pejabatKewarganegaraan: null,
            },
            showModalTambahPejabat: false,
            _pejabatKorporatFormMode: 'create',
        });
    };

    removePejabat = (index) => {
        if (window.confirm('Apakah Anda yakin akan menghapus data ini?')) {
            let new_pejabatKorporat = this.state._pejabatKorporat;
            new_pejabatKorporat.splice(index, 1);
            this.setState({ _pejabatKorporat: new_pejabatKorporat });
        }
    };

    detailRelasiNasabah = (index) => {
        const relasiNasabah = this.state._relasiNasabah[index];
        this.checkNomorCif('_relasiNasabahForm', relasiNasabah?.relasiNomorNasabah);
        this.checkNomorCifGroup('_relasiNasabahForm', relasiNasabah?.relasiNomorNasabah);
        this.setState({
            showModalTambahRelasi: true,
            _relasiNasabahForm: relasiNasabah,
            _relasiNasabahFormMode: 'info',
        });
    };

    saveRelasiNasabah = () => {
        let isStep3Completed = this.state.isStep3Completed;
        let _relasiNasabah = this.state._relasiNasabah;
        isStep3Completed = true;
        if (this.state._relasiNasabahForm.relasiNomorNasabah !== null) {
            _relasiNasabah = [..._relasiNasabah, this.state._relasiNasabahForm];
            this.setState({
                isStep3Completed: isStep3Completed,
                _relasiNasabah,
                _relasiNasabahForm: {
                    relasiNomorNasabah: null,
                    relasiFaxNomor: null,
                    relasiKelurahan: null,
                    relasiPekerjaan: null,
                    relasiFaxKodeArea: null,
                    relasiAlamat: null,
                    relasiNomorIdentitas: null,
                    relasiTeleponRumahNomor: null,
                    relasiEmailAddress: null,
                    relasiNomorNpwp: null,
                    relasiKewarganegaraan: null,
                    relasiRw: null,
                    relasiNegaraAsal: null,
                    relasiRt: null,
                    relasiJenisIdentitas: null,
                    relasiNomorHandphone: null,
                    relasiJabatanPangkat: null,
                    relasiKotaKabupaten: null,
                    relasiTeleponRumahKodeArea: null,
                    relasiNama: null,
                    relasiProvinsi: null,
                    relasiHubunganDenganNasabah: null,
                    relasiKecamatan: null,
                },
                showModalTambahRelasi: false,
                _relasiNasabahFormMode: 'create',
                _relasiNasabahFormButtonMode: 'create',
            });
        } else {
            this.setState({
                isStep3Completed: isStep3Completed,
            });
        }
    }

    ubahRelasiNasabah = (index) => {
        const relasiNasabah = this.state._relasiNasabah[index];
        this.setState({
            showModalTambahRelasi: true,
            _relasiNasabahForm: relasiNasabah,
            _relasiNasabahFormMode: 'update',
            _relasiNasabahFormButtonMode: 'update',
            _relasiNasabahIndex: index,
        });
    };

    updateRelasiNasabah = () => {
        let _relasiNasabah = [...this.state._relasiNasabah];
        _relasiNasabah[this.state._relasiNasabahIndex] = this.state._relasiNasabahForm;
        this.setState({
            _relasiNasabah,
            _relasiNasabahForm: {
                relasiNomorNasabah: '',
                relasiFaxNomor: '',
                relasiKelurahan: '',
                relasiPekerjaan: '',
                relasiFaxKodeArea: '',
                relasiAlamat: '',
                relasiNomorIdentitas: '',
                relasiTeleponRumahNomor: '',
                relasiEmailAddress: '',
                relasiNomorNpwp: '',
                relasiKewarganegaraan: '',
                relasiRw: '',
                relasiNegaraAsal: '',
                relasiRt: '',
                relasiJenisIdentitas: '',
                relasiNomorHandphone: '',
                relasiJabatanPangkat: '',
                relasiKotaKabupaten: '',
                relasiTeleponRumahKodeArea: '',
                relasiNama: '',
                relasiProvinsi: '',
                relasiHubunganDenganNasabah: '',
                relasiKecamatan: '-',
            },
            showModalTambahRelasi: false,
            _relasiNasabahFormMode: 'create',
            _relasiNasabahFormButtonMode: 'create',
        });
    };

    removeRelasiNasabah = (index) => {
        if (window.confirm('Apakah Anda yakin akan menghapus data ini?')) {
            let new_relasiNasabah = this.state._relasiNasabah;
            new_relasiNasabah.splice(index, 1);
            this.setState({ _relasiNasabah: new_relasiNasabah });
        }
    };

    checkNpwp = (value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/nasabah-badan-usaha/mambu/npwp?nomor=' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // // console.log(data)
                if (data.message_id === '00') {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            nasabahNomorNpwp: true,
                            nasabahNomorNpwpAlready: true,
                        },
                    });
                } else {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            nasabahNomorNpwp: false,
                            nasabahNomorNpwpAlready: false,
                        },
                    });
                }
            });
    };

    checkNomorCifGroup = (field, value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/nasabah-badan-usaha/find/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    if (field === '_relasiNasabahForm') {
                        this.setState({
                            _relasiNasabahForm: {
                                ...this.state._relasiNasabahForm,
                                relasiNomorNasabah: dataDetail.id,
                                relasiEmailAddress:
                                    dataDetail.emailAddress,
                                relasiNomorHandphone:
                                    dataDetail._identitasNasabah?.nasabahTeleponKantorNomor1,
                                relasiJenisIdentitas: 'NPWP',
                                relasiNomorIdentitas:
                                    dataDetail._identitasNasabah?.nasabahNomorNpwp,
                                relasiNomorNpwp: dataDetail._identitasNasabah?.nasabahNomorNpwp,
                                relasiNama: dataDetail.groupName,
                                relasiAlamat: dataDetail._identitasNasabah?.nasabahAlamat,
                                relasiRt: dataDetail._identitasNasabah?.nasabahRt,
                                relasiRw: dataDetail._identitasNasabah?.nasabahRw,
                                relasiProvinsi: dataDetail._identitasNasabah?.nasabahProvinsi,
                                relasiKotaKabupaten: dataDetail._identitasNasabah?.nasabahKotaKabupaten,
                                relasiKecamatan: dataDetail._identitasNasabah?.nasabahKecamatan,
                                relasiKelurahan: dataDetail._identitasNasabah?.nasabahKelurahan,
                            },
                            // _relasiNasabahFormMode: 'info',
                            _relasiNasabahFormButtonMode: this.state._relasiNasabahFormButtonMode,
                            errors: {
                                ...this.state.errors,
                                relasiNomorNasabah: false,
                            },
                            messages: {
                                ...this.state.messages,
                                relasiNomorNasabah: '',
                            },
                        });
                    }
                } 
                // else {
                //     if (field === '_relasiNasabahForm') {
                //         this.setState({
                //             _relasiNasabahForm: {
                //                 ...this.state._relasiNasabahForm,
                //                 relasiEmailAddress: '',
                //                 relasiNomorHandphone: '',
                //                 relasiNama: '',
                //             },
                //             errors: {
                //                 ...this.state.errors,
                //                 relasiNomorNasabah: true,
                //             },
                //             messages: {
                //                 ...this.state.messages,
                //                 relasiNomorNasabah: 'Nomor Nasabah tidak ditemukan',
                //             },
                //         });
                //     }
                // }
            });
    };
    
    checkNomorCif = (field, value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/nasabah/perorangan/find/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    // // console.log(dataDetail);
                    if (field === '_pejabatKorporatForm') {
                        let rtRW = dataDetail._ktpAddress?.ktpRtRw.split('/');
                        this.setState({
                            _pejabatKorporatForm: {
                                ...this.state._pejabatKorporatForm,
                                pejabatNama: dataDetail.firstName + ' ' + dataDetail.lastName,
                                pejabatNomorHandphone: dataDetail.mobilePhone,
                                pejabatNomorNasabahPejabat: dataDetail.id,
                                pejabatEmailAddress: dataDetail.emailAddress,
                                pejabatAlamat: dataDetail._ktpAddress?.ktpAddress,
                                pejabatRt: rtRW[0],
                                pejabatRw: rtRW[1],
                                pejabatProvinsi: dataDetail._ktpAddress?.ktpProvince,
                                pejabatKotaKabupaten: dataDetail._ktpAddress?.ktpCity,
                                pejabatKecamatan: dataDetail._ktpAddress?.ktpSubDistrict,
                                pejabatKelurahan: dataDetail._ktpAddress?.ktpVillage,
                                pejabatKodePos: dataDetail._ktpAddress?.ktpPostalCode,
                                pejabatNomorIdentitas: dataDetail._personalIdData?.personalIdNumber,
                                pejabatJenisIdentitas: dataDetail._personalIdData?.personalIdType,
                                pejabatKewarganegaraan:
                                    dataDetail._customerData?.personalCitizenship,
                            },
                            _pejabatKorporatFormModeDisabled: {
                                ...this.state._pejabatKorporatFormModeDisabled,
                                pejabatNama: true,
                                pejabatNomorHandphone: true,
                                pejabatNomorNasabahPejabat: true,
                                pejabatEmailAddress: true,
                                pejabatAlamat: true,
                                pejabatRt: true,
                                pejabatRw: true,
                                pejabatProvinsi: true,
                                pejabatKotaKabupaten: true,
                                pejabatKecamatan: true,
                                pejabatKelurahan: true,
                                pejabatKodePos: true,
                                pejabatNomorIdentitas: true,
                                pejabatJenisIdentitas: true,
                                pejabatKewarganegaraan: true,
                            },
                            errors: {
                                ...this.state.errors,
                                pejabatNomorNasabahPejabat: false,
                            },
                            messages: {
                                ...this.state.messages,
                                pejabatNomorNasabahPejabat: '',
                            },
                        });
                    }

                    if (field === '_relasiNasabahForm') {
                        let relasiRtRw = dataDetail._ktpAddress?.ktpRtRw.split('/');
                        this.setState({
                            _relasiNasabahForm: {
                                ...this.state._relasiNasabahForm,
                                relasiNomorNasabah: dataDetail.id,
                                relasiEmailAddress: dataDetail.emailAddress,
                                relasiNomorHandphone: dataDetail.mobilePhone,
                                relasiJenisIdentitas: dataDetail._personalIdData?.personalIdType,
                                relasiNomorIdentitas: dataDetail._personalIdData?.personalIdNumber,
                                relasiNomorNpwp: dataDetail._personalIdData?.personalNpwpNumber,
                                relasiKewarganegaraan:
                                    dataDetail._customerData?.personalCitizenship,
                                relasiNama: dataDetail.firstName + ' ' + dataDetail.lastName,
                                relasiAlamat: dataDetail._ktpAddress?.ktpAddress,
                                relasiRt: dataDetail._ktpAddress?.ktpRtRw.split('/')[0],
                                relasiRw: dataDetail._ktpAddress?.ktpRtRw.split('/')[1],
                                relasiProvinsi: dataDetail._ktpAddress?.ktpProvince,
                                relasiKotaKabupaten: dataDetail._ktpAddress?.ktpCity,
                                relasiKecamatan: dataDetail._ktpAddress?.ktpSubDistrict,
                                relasiKelurahan: dataDetail._ktpAddress?.ktpVillage,
                            },
                            // _relasiNasabahFormMode: 'info',
                            _relasiNasabahFormButtonMode: this.state._relasiNasabahFormButtonMode,
                            errors: {
                                ...this.state.errors,
                                relasiNomorNasabah: false,
                            },
                            messages: {
                                ...this.state.messages,
                                relasiNomorNasabah: '',
                            },
                        });
                        // console.log(this.state._relasiNasabahForm);
                    }
                } 
                // else {
                //     if (field === '_pejabatKorporatForm') {
                //         this.setState({
                //             _pejabatKorporatForm: {
                //                 ...this.state._pejabatKorporatForm,
                //                 pejabatNama: '',
                //                 pejabatNomorHandphone: '',
                //                 pejabatEmailAddress: '',
                //                 pejabatAlamat: '',
                //                 pejabatRt: '',
                //                 pejabatProvinsi: '',
                //                 pejabatKotaKabupaten: '',
                //                 pejabatKecamatan: '',
                //                 pejabatKelurahan: '',
                //                 pejabatKodePos: '',
                //                 pejabatNomorIdentitas: '',
                //             },
                //             errors: {
                //                 ...this.state.errors,
                //                 pejabatNomorNasabahPejabat: true,
                //             },
                //             messages: {
                //                 ...this.state.messages,
                //                 pejabatNomorNasabahPejabat: 'Nomor Nasabah tidak ditemukan',
                //             },
                //         });
                //     }

                //     if (field === '_relasiNasabahForm') {
                //         this.setState({
                //             _relasiNasabahForm: {
                //                 ...this.state._relasiNasabahForm,
                //                 relasiEmailAddress: '',
                //                 relasiNomorHandphone: '',
                //                 relasiNama: '',
                //             },
                //             errors: {
                //                 ...this.state.errors,
                //                 relasiNomorNasabah: true,
                //             },
                //             messages: {
                //                 ...this.state.messages,
                //                 relasiNomorNasabah: 'Nomor Nasabah tidak ditemukan',
                //             },
                //         });
                //     }
                // }
            });
    };

    resetCheckNomorCif = (field) => {
        if (field === '_pejabatKorporatForm') {
            this.setState({
                _pejabatKorporatForm: {
                    pejabatNama: '',
                    pejabatNomorHandphone: '',
                    pejabatNomorNasabahPejabat: '',
                    pejabatEmailAddress: '',
                    pejabatAlamat: '',
                    pejabatRt: '',
                    pejabatProvinsi: '',
                    pejabatKotaKabupaten: '',
                    pejabatKecamatan: '',
                    pejabatKelurahan: '',
                    pejabatKodePos: '',
                    pejabatNomorIdentitas: '',
                },
            });
        }

        if (field === '_relasiNasabahForm') {
            this.setState({
                _relasiNasabahForm: {
                    ...this.state._relasiNasabahForm,
                    relasiNomorNasabah: '',
                    relasiEmailAddress: '',
                    relasiNomorHandphone: '',
                    relasiNama: '',
                    relasiAlamat: '',
                },
                _relasiNasabahFormMode: 'create',
            });
        }
    };

    resetStep = () => {
        if (window.confirm('Apakah Anda yakin akan reset tahap ini?')) {
            if (this.state.mode === 'create') {
                if (this.state.step === 0) {
                    this.setState({
                        isStep0Completed: false,
                        groupName: this.state.groupName !== null ? '' : null,
                        emailAddress: this.state.emailAddress !== null ? '' : null,
                        notes: this.state.notes !== null ? '' : null,
                        _identitasNasabah: {
                            nasabahLokasi:
                                this.state._identitasNasabah.nasabahLokasi !== null ? '' : null,
                            nasabahPicNasabah:
                                this.state._identitasNasabah.nasabahPicNasabah !== null ? '' : null,
                            nasabahProvinsi:
                                this.state._identitasNasabah.nasabahProvinsi !== null ? '' : null,
                            nasabahSektorEkonomi:
                                this.state._identitasNasabah.nasabahSektorEkonomi !== null ? '' : null,
                            nasabahNomorSkMenkumham:
                                this.state._identitasNasabah.nasabahNomorSkMenkumham !== null
                                    ? ''
                                    : null,
                            nasabahEmailAddress:
                                this.state._identitasNasabah.nasabahEmailAddress !== null ? '' : null,
                            nasabahTeleponKantorNomor3:
                                this.state._identitasNasabah.nasabahTeleponKantorNomor3 !== null
                                    ? ''
                                    : null,
                            nasabahNomorNpwp:
                                this.state.mode === 'create'
                                    ? this.state._identitasNasabah.nasabahNomorNpwp !== null
                                        ? ''
                                        : null
                                    : this.state._identitasNasabah.nasabahNomorNpwp,
                            nasabahKelurahan:
                                this.state._identitasNasabah.nasabahKelurahan !== null ? '' : null,
                            nasabahAlamat:
                                this.state._identitasNasabah.nasabahAlamat !== null ? '' : null,
                            nasabahBidangUsaha:
                                this.state._identitasNasabah.nasabahBidangUsaha !== null ? '' : null,
                            nasabahNasabahInduk:
                                this.state._identitasNasabah.nasabahNasabahInduk !== null ? '' : null,
                            nasabahTanggalPengesahanSkdp:
                                this.state._identitasNasabah.nasabahTanggalPengesahanSkdp !== null
                                    ? ''
                                    : null,
                            nasabahBadanUsaha:
                                this.state._identitasNasabah.nasabahBadanUsaha !== null ? '' : null,
                            nasabahTanggalSkMenkumham:
                                this.state._identitasNasabah.nasabahTanggalSkMenkumham !== null
                                    ? ''
                                    : null,
                            nasabahBranchCode: 'e879878806e75017881ec63f1043f',
                            nasabahNomorSkdp:
                                this.state._identitasNasabah.nasabahNomorSkdp !== null ? '' : null,
                            nasabahKotaKabupaten:
                                this.state._identitasNasabah.nasabahKotaKabupaten !== null ? '' : null,
                            nasabahKodePos:
                                this.state._identitasNasabah.nasabahKodePos !== null ? '' : null,
                            nasabahTempatAktePendirian:
                                this.state._identitasNasabah.nasabahTempatAktePendirian !== null
                                    ? ''
                                    : null,
                            nasabahStatusKeterkaitan:
                                this.state._identitasNasabah.nasabahStatusKeterkaitan !== null
                                    ? ''
                                    : null,
                            nasabahTeleponKantorKodeArea1:
                                this.state._identitasNasabah.nasabahTeleponKantorKodeArea1 !== null
                                    ? ''
                                    : null,
                            nasabahTanggalAktePendirian:
                                this.state._identitasNasabah.nasabahTanggalAktePendirian !== null
                                    ? ''
                                    : null,
                            nasabahNomorAktePerubahan:
                                this.state._identitasNasabah.nasabahNomorAktePerubahan !== null
                                    ? ''
                                    : null,
                            nasabahTeleponKantorKodeArea2:
                                this.state._identitasNasabah.nasabahTeleponKantorKodeArea2 !== null
                                    ? ''
                                    : null,
                            nasabahTeleponKantorKodeArea3:
                                this.state._identitasNasabah.nasabahTeleponKantorKodeArea3 !== null
                                    ? ''
                                    : null,
                            nasabahFaxNomor:
                                this.state._identitasNasabah.nasabahFaxNomor !== null ? '' : null,
                            nasabahNomorAktePendirian:
                                this.state._identitasNasabah.nasabahNomorAktePendirian !== null
                                    ? ''
                                    : null,
                            nasabahTanggalKadaluarsaSkdp:
                                this.state._identitasNasabah.nasabahTanggalKadaluarsaSkdp !== null
                                    ? ''
                                    : null,
                            nasabahKodeMarketing:
                                this.state._identitasNasabah.nasabahKodeMarketing !== null ? '' : null,
                            nasabahTanggalAktePerubahan:
                                this.state._identitasNasabah.nasabahTanggalAktePerubahan !== null
                                    ? ''
                                    : null,
                            nasabahKepemilikanModal:
                                this.state._identitasNasabah.nasabahKepemilikanModal !== null
                                    ? ''
                                    : null,
                            nasabahJenisPenduduk:
                                this.state._identitasNasabah.nasabahJenisPenduduk !== null ? '' : null,
                            nasabahNomorSiupp:
                                this.state._identitasNasabah.nasabahNomorSiupp !== null ? '' : null,
                            nasabahTanggalPengesahanSiupp:
                                this.state._identitasNasabah.nasabahTanggalPengesahanSiupp !== null
                                    ? ''
                                    : null,
                            nasabahRw: this.state._identitasNasabah.nasabahRw !== null ? '' : null,
                            nasabahNomorTDP:
                                this.state._identitasNasabah.nasabahNomorTDP !== null ? '' : null,
                            nasabahWilayahPemberiReferensi:
                                this.state._identitasNasabah.nasabahWilayahPemberiReferensi !== null
                                    ? ''
                                    : null,
                            nasabahTeleponKantorNomor2:
                                this.state._identitasNasabah.nasabahTeleponKantorNomor2 !== null
                                    ? ''
                                    : null,
                            nasabahRt: this.state._identitasNasabah.nasabahRt !== null ? null : null,
                            nasabahTeleponKantorNomor1:
                                this.state._identitasNasabah.nasabahTeleponKantorNomor1 !== null
                                    ? ''
                                    : null,
                            nasabahFaxKodeArea:
                                this.state._identitasNasabah.nasabahFaxKodeArea !== null ? '' : null,
                            nasabahKecamatan:
                                this.state._identitasNasabah.nasabahKecamatan !== null ? '' : null,
                            nasabahBankNonBank:
                                this.state._identitasNasabah.nasabahBankNonBank !== null ? '' : null,
                            nasabahPemilik:
                                this.state._identitasNasabah.nasabahPemilik !== null ? '' : null,
                        },
                    });
                }
    
                if (this.state.step === 1) {
                    this.setState({
                        isStep1Completed: false,
                        _pejabatKorporat: [],
                        _pejabatKorporatForm: {
                            pejabatKecamatan:
                                this.state._pejabatKorporatForm.pejabatKecamatan !== null ? '' : null,
                            pejabatNomorNasabahPejabat:
                                this.state._pejabatKorporatForm.pejabatNomorNasabahPejabat !== null
                                    ? ''
                                    : null,
                            pejabatAlamat:
                                this.state._pejabatKorporatForm.pejabatAlamat !== null ? '' : null,
                            pejabatNomorNpwp:
                                this.state._pejabatKorporatForm.pejabatNomorNpwp !== null ? '' : null,
                            pejabatKeterangan:
                                this.state._pejabatKorporatForm.pejabatKeterangan !== null ? '' : null,
                            pejabatNegaraAsal:
                                this.state._pejabatKorporatForm.pejabatNegaraAsal !== null ? '' : null,
                            pejabatKotaKabupaten:
                                this.state._pejabatKorporatForm.pejabatKotaKabupaten !== null
                                    ? ''
                                    : null,
                            pejabatNomorHandphone:
                                this.state._pejabatKorporatForm.pejabatNomorHandphone !== null
                                    ? ''
                                    : null,
                            pejabatJabatan:
                                this.state._pejabatKorporatForm.pejabatJabatan !== null ? '' : null,
                            pejabatNomorIdentitas:
                                this.state._pejabatKorporatForm.pejabatNomorIdentitas !== null
                                    ? ''
                                    : null,
                            pejabatPekerjaan:
                                this.state._pejabatKorporatForm.pejabatPekerjaan !== null ? '' : null,
                            pejabatTeleponKodeArea:
                                this.state._pejabatKorporatForm.pejabatTeleponKodeArea !== null
                                    ? ''
                                    : null,
                            pejabatJenisIdentitas:
                                this.state._pejabatKorporatForm.pejabatJenisIdentitas !== null
                                    ? ''
                                    : null,
                            pejabatNama:
                                this.state._pejabatKorporatForm.pejabatNama !== null ? '' : null,
                            pejabatEmailAddress:
                                this.state._pejabatKorporatForm.pejabatEmailAddress !== null
                                    ? ''
                                    : null,
                            pejabatTeleponNomor:
                                this.state._pejabatKorporatForm.pejabatTeleponNomor !== null
                                    ? ''
                                    : null,
                            pejabatKelurahan:
                                this.state._pejabatKorporatForm.pejabatKelurahan !== null ? '' : null,
                            pejabatRw: this.state._pejabatKorporatForm.pejabatRw !== null ? '' : null,
                            pejabatPersentaseKepemilikan:
                                this.state._pejabatKorporatForm.pejabatPersentaseKepemilikan !== null
                                    ? ''
                                    : null,
                            pejabatProvinsi:
                                this.state._pejabatKorporatForm.pejabatProvinsi !== null ? '' : null,
                            pejabatFaxKodeArea:
                                this.state._pejabatKorporatForm.pejabatFaxKodeArea !== null ? '' : null,
                            pejabatFaxNomor:
                                this.state._pejabatKorporatForm.pejabatFaxNomor !== null ? '' : null,
                            pejabatRt: this.state._pejabatKorporatForm.pejabatRt !== null ? '' : null,
                            pejabatKewarganegaraan:
                                this.state._pejabatKorporatForm.pejabatKewarganegaraan !== null
                                    ? ''
                                    : null,
                        },
                    });
                }
    
                if (this.state.step === 2) {
                    this.setState({
                        isStep2Completed: false,
                        _cdd: {
                            cddFaktorResiko: this.state._cdd.cddFaktorResiko !== null ? '' : null,
                            cddStatusResiko: this.state._cdd.cddStatusResiko !== null ? '' : null,
                            cddStrukturKepemilikan:
                                this.state._cdd.cddStrukturKepemilikan !== null ? '' : null,
                            cddJumlahTransaksi: this.state._cdd.cddJumlahTransaksi !== null ? '' : null,
                            cddSumberDana: this.state._cdd.cddSumberDana !== null ? '' : null,
                            cddLimitFrekuensiSetorTunai:
                                this.state._cdd.cddLimitFrekuensiSetorTunai !== null ? '' : null,
                            cddLimitFrekuensiSetorNonTunai:
                                this.state._cdd.cddLimitFrekuensiSetorNonTunai !== null ? '' : null,
                            cddSumberPenghasilan:
                                this.state._cdd.cddSumberPenghasilan !== null ? '' : null,
                            cddTujuanPenggunaanDana:
                                this.state._cdd.cddTujuanPenggunaanDana !== null ? '' : null,
                            cddLimitNominalSetorTunai:
                                this.state._cdd.cddLimitNominalSetorTunai !== null ? '' : null,
                            cddIdentitasNasabah:
                                this.state._cdd.cddIdentitasNasabah !== null ? '' : null,
                            cddProfilNasabah: this.state._cdd.cddProfilNasabah !== null ? '' : null,
                            cddTujuanBukaRekening:
                                this.state._cdd.cddTujuanBukaRekening !== null ? '' : null,
                            cddLokasiUsaha: this.state._cdd.cddLokasiUsaha !== null ? '' : null,
                            cddKegiatanUsaha: this.state._cdd.cddKegiatanUsaha !== null ? '' : null,
                            cddGolonganNasabah: this.state._cdd.cddGolonganNasabah !== null ? '' : null,
                            cddLimitNominalSetorNonTunai:
                                this.state._cdd.cddLimitNominalSetorNonTunai !== null ? '' : null,
                            cddPenghasilanKotorPerTahun:
                                this.state._cdd.cddPenghasilanKotorPerTahun !== null ? '' : null,
                            cddInformasiLainnya:
                                this.state._cdd.cddInformasiLainnya !== null ? '' : null,
                        },
                        rba: {
                            answer1: null,
                            answer2: null,
                            answer3: null,
                            answer4: null,
                            answer5: null,
                            answer6: null,
                            answer7: null,
                            answer8: null,
                            answer9: null,
                        },
                    });
                }
    
                if (this.state.step === 3) {
                    this.setState({
                        isStep3Completed: false,
                        _relasiNasabah: [],
                        _relasiNasabahForm: {
                            relasiNomorNasabah:
                                this.state._relasiNasabahForm.relasiNomorNasabah !== null ? '' : null,
                            relasiFaxNomor:
                                this.state._relasiNasabahForm.relasiFaxNomor !== null ? '' : null,
                            relasiKelurahan:
                                this.state._relasiNasabahForm.relasiKelurahan !== null ? '' : null,
                            relasiPekerjaan:
                                this.state._relasiNasabahForm.relasiPekerjaan !== null ? '' : null,
                            relasiFaxKodeArea:
                                this.state._relasiNasabahForm.relasiFaxKodeArea !== null ? '' : null,
                            relasiAlamat:
                                this.state._relasiNasabahForm.relasiAlamat !== null ? '' : null,
                            relasiNomorIdentitas:
                                this.state._relasiNasabahForm.relasiNomorIdentitas !== null ? '' : null,
                            relasiTeleponRumahNomor:
                                this.state._relasiNasabahForm.relasiTeleponRumahNomor !== null
                                    ? ''
                                    : null,
                            relasiEmailAddress:
                                this.state._relasiNasabahForm.relasiEmailAddress !== null ? '' : null,
                            relasiNomorNpwp:
                                this.state._relasiNasabahForm.relasiNomorNpwp !== null ? '' : null,
                            relasiKewarganegaraan:
                                this.state._relasiNasabahForm.relasiKewarganegaraan !== null
                                    ? ''
                                    : null,
                            relasiRw: this.state._relasiNasabahForm.relasiRw !== null ? '' : null,
                            relasiNegaraAsal:
                                this.state._relasiNasabahForm.relasiNegaraAsal !== null ? '' : null,
                            relasiRt: this.state._relasiNasabahForm.relasiRt !== null ? '' : null,
                            relasiJenisIdentitas:
                                this.state._relasiNasabahForm.relasiJenisIdentitas !== null ? '' : null,
                            relasiNomorHandphone:
                                this.state._relasiNasabahForm.relasiNomorHandphone !== null ? '' : null,
                            relasiJabatanPangkat:
                                this.state._relasiNasabahForm.relasiJabatanPangkat !== null ? '' : null,
                            relasiKotaKabupaten:
                                this.state._relasiNasabahForm.relasiKotaKabupaten !== null ? '' : null,
                            relasiTeleponRumahKodeArea:
                                this.state._relasiNasabahForm.relasiTeleponRumahKodeArea !== null
                                    ? ''
                                    : null,
                            relasiNama: this.state._relasiNasabahForm.relasiNama !== null ? '' : null,
                            relasiProvinsi:
                                this.state._relasiNasabahForm.relasiProvinsi !== null ? '' : null,
                            relasiHubunganDenganNasabah:
                                this.state._relasiNasabahForm.relasiHubunganDenganNasabah !== null
                                    ? ''
                                    : null,
                            relasiKecamatan:
                                this.state._relasiNasabahForm.relasiKecamatan !== null ? '' : null,
                        },
                    });
                }
    
                if (this.state.step === 4) {
                    this.setState({
                        isStep4Completed: false,
                        _beneficiaryOwner: {
                            benefKecamatan: this.state.benefKecamatan !== null ? '' : null,
                            benefNamaPerusahaan: this.state.benefNamaPerusahaan !== null ? '' : null,
                            benefRw: this.state.benefRw !== null ? '' : null,
                            benefKodePos: this.state.benefKodePos !== null ? '' : null,
                            benefProvinsi: this.state.benefProvinsi !== null ? '' : null,
                            benefNomorSiupp: this.state.benefNomorSiupp !== null ? '' : null,
                            benefBentukUsaha: this.state.benefBentukUsaha !== null ? '' : null,
                            benefBidangUsaha: this.state.benefBidangUsaha !== null ? '' : null,
                            benefRt: this.state.benefRt !== null ? '' : null,
                            benefKeterangan: this.state.benefKeterangan !== null ? '' : null,
                            benefSumberDana: this.state.benefSumberDana !== null ? '' : null,
                            benefAlamat: this.state.benefAlamat !== null ? '' : null,
                            benefKelurahan: this.state.benefKelurahan !== null ? '' : null,
                            benefKotaKabupaten: this.state.benefKotaKabupaten !== null ? '' : null,
                            benefTujuanPenggunaanDana:
                                this.state.benefTujuanPenggunaanDana !== null ? '' : null,
                        },
                    });
                }
            } else {
                this.getData();
            }
        }
    };

    postGroup = async () => {
        let body = {
            groupName: this.state.groupName,
            emailAddress: this.state.emailAddress,
            assignedBranchKey: this.state.assignedBranchKey,
            _identitasNasabah: this.state._identitasNasabah,
            _pejabatKorporat: this.state._pejabatKorporat,
            _cdd: this.state._cdd,
            _relasiNasabah: this.state._relasiNasabah,
            _beneficiaryOwner: this.state._beneficiaryOwner,
            _pihakLawan: this.state._pihakLawan,
            _alamatAlternatif: {
                alternatifKodePos: this.state._alamatAlternatif.alternatifKodePos,
                alternatifProvinsi: this.state._alamatAlternatif.alternatifProvinsi,
                alternatifKotaKabupaten: this.state._alamatAlternatif.alternatifKotaKabupaten,
                alternatifRtRw:
                    this.state._alamatAlternatif.alternatifRt !== null &&
                    this.state._alamatAlternatif.alternatifRw !== null
                        ? this.state._alamatAlternatif.alternatifRt +
                          '/' +
                          this.state._alamatAlternatif.alternatifRw
                        : null,
                alternatifKecamatan: this.state._alamatAlternatif.alternatifKecamatan,
                alternatifAlamat: this.state._alamatAlternatif.alternatifAlamat,
                alternatifTelepon: this.state._alamatAlternatif.alternatifTelepon,
                alternatifKelurahan: this.state._alamatAlternatif.alternatifKelurahan,
            },
        };
        // console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
                "x-user-id": "cs"
            },
            body: JSON.stringify(body),
        };
        fetch(baseUrl + '/nasabah-badan-usaha', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.status === '00') {
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                    });
                }
            });
    };

    putGroup = async () => {
        const queryParams = queryString.parse(this.props.location.search);
        let body = {
            id: this.state.id,
            encodedKey: this.state.encodedKey,
            groupName: this.state.groupName,
            emailAddress: this.state.emailAddress,
            assignedBranchKey: this.state.assignedBranchKey,
            _identitasNasabah: this.state._identitasNasabah,
            _pejabatKorporat: this.state._pejabatKorporat ? this.state._pejabatKorporat : null,
            _cdd: this.state._cdd,
            _relasiNasabah: this.state._relasiNasabah ? this.state._relasiNasabah : null,
            _beneficiaryOwner: this.state._beneficiaryOwner,
            _pihakLawan: this.state._pihakLawan,
            _alamatAlternatif: {
                alternatifKodePos: this.state._alamatAlternatif.alternatifKodePos,
                alternatifProvinsi: this.state._alamatAlternatif.alternatifProvinsi,
                alternatifKotaKabupaten: this.state._alamatAlternatif.alternatifKotaKabupaten,
                alternatifRtRw: this.state._alamatAlternatif.alternatifRt !== null && this.state._alamatAlternatif.alternatifRw !== null ? this.state._alamatAlternatif.alternatifRt + '/' + this.state._alamatAlternatif.alternatifRw : null,
                alternatifKecamatan: this.state._alamatAlternatif.alternatifKecamatan,
                alternatifAlamat: this.state._alamatAlternatif.alternatifAlamat,
                alternatifTelepon: this.state._alamatAlternatif.alternatifTelepon,
                alternatifKelurahan: this.state._alamatAlternatif.alternatifKelurahan,
            },
            _svsCorporate: this.state._svsCorporate,
            _negatifNasabah: this.state._negatifNasabah,
        };
        // console.log(body);
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };
        let params = queryParams.from === 'mambu' ? '?nomor_cif=' : '?db_id=';
        fetch(baseUrl + '/nasabah-badan-usaha' + params + queryParams.db_id, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                    });
                }
            });
    };

    render() {
        return (
            <div className="">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item text-primary-bds">Nasabah</li>
                        {this.state.mode != 'create' && 
                            <li className="breadcrumb-item">
                                <a className="text-primary-bds" href="#" onClick={()=> this.props.history.push('/data_nasabah/cif')}>Data Nasabah</a>
                            </li>
                        }
                        <li className="breadcrumb-item">
                            <a href="#">
                                {this.state.mode === 'create'
                                    ? 'Registrasi'
                                    : this.state.mode === 'update'
                                    ? 'Ubah'
                                    : 'Lihat'}{' '}
                                Nasabah
                            </a>
                        </li>
                        {/* <li className="breadcrumb-item">Nasabah Badan Usaha</li> */}
                    </ol>
                </nav>

                <div className="card">
                    <div className="card-header">
                        <div className="row mx-auto justify-content-center p-2">
                            <div
                                className={
                                    this.state.isStep0Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step === 0 ||
                                          this.state.step === 1 ||
                                          this.state.step === 2 ||
                                          this.state.step === 3 ||
                                          this.state.step === 4 ||
                                          this.state.step === 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(0)}
                            >
                                <div className="row">
                                    <div className="col p0"></div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep0Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Identitas Nasabah
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep1Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step === 1 ||
                                          this.state.step === 2 ||
                                          this.state.step === 3 ||
                                          this.state.step === 4 ||
                                          this.state.step === 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(1)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep1Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Pejabat Korporat
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep2Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step === 2 ||
                                          this.state.step === 3 ||
                                          this.state.step === 4 ||
                                          this.state.step === 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(2)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep2Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">CDD</div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep3Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step === 3 ||
                                          this.state.step === 4 ||
                                          this.state.step === 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(3)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep3Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Relasi Nasabah
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep4Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 4
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(4)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep4Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0"></div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">Beneficiary Owner</div>
                                </div>
                            </div>

                            {/* <div
                                className={
                                    this.state.isStep4Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 4 || this.state.step == 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(4)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep4Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Beneficiary Owner
                                    </div>
                                </div>
                            </div> */}
                            
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.step == 0 ? (
                            <div>
                                <div className="box">
                                    <div className="row">
                                        <div className="col-3 align-self-center bns-form">
                                            <div className="form-group">
                                                <label>Nomor CIF</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    readOnly
                                                    placeholder="Otomatis terisi dari sistem"
                                                    value={this.state.id}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3 align-self-center bns-form">
                                            <div className="form-group">
                                                <label>Kode Referal</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    readOnly
                                                    placeholder="Otomatis terisi dari sistem"
                                                    value={this.state.id}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 text-right align-self-end">
                                            <div>
                                                <span className="text-grey">Tgl Buka</span>
                                                <span className="text-grey2 ml-4">:</span>
                                                <span className="text-grey2 ml-1">
                                                    {this.state.created_at ? this.state.created_at.substring(0,10) : ''}
                                                </span>
                                                <span className="ml-5 text-grey">Kode Cabang</span>
                                                <span className="text-grey2 ml-4">:</span>
                                                <span className="text-grey2 ml-1">
                                                    {`${this.state.branch?.id} - ${this.state.branch?.name}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <FormHeader className="tw-my-4 mt-4">Data Nasabah Badan Usaha</FormHeader>
                                <div className="bns-form">
                                    <div className="row mt-2">
                                        <div className="col-5">
                                            <div className="form-group">
                                                <label
                                                    className={
                                                        this.state.errors.nasabahBadanUsaha
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Badan Usaha{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    className={`form-control ${
                                                        this.state.errors.nasabahBadanUsaha
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    name="nasabahBadanUsaha"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahBadanUsaha
                                                    }
                                                >
                                                    <option
                                                        value=""
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        Pilih tipe badan usaha
                                                    </option>
                                                    {this.state._fields.R_BADAN_HUKUM_KORPORAT &&
                                                    this.state._fields.R_BADAN_HUKUM_KORPORAT
                                                        .length > 0
                                                        ? this.state._fields.R_BADAN_HUKUM_KORPORAT.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={
                                                                              data.reference_code +
                                                                              ' - ' +
                                                                              data.reference_desc
                                                                          }
                                                                      >
                                                                          {data.reference_desc}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className={
                                                        this.state.errors.nasabahAsetPerusahaan
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Aset Perusahaan{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <input
                                                    placeholder="Rp"
                                                    className={`form-control ${
                                                        this.state.errors.nasabahAsetPerusahaan
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) => this.handleInputChange(e)}
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahAsetPerusahaan"
                                                    value={this.state.nasabahAsetPerusahaan}
                                                />
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className={
                                                        this.state.errors.nasabahNomorNpwp
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Nomor NPWP{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <input
                                                    minLength={15}
                                                    maxLength={15}
                                                    type="text"
                                                    placeholder="Masukkan Nomor NPWP"
                                                    className={`form-control ${
                                                        this.state.errors.nasabahNomorNpwp
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        );
                                                        this.checkNpwp(e.target.value);
                                                    }}
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahNomorNpwp"
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahNomorNpwp
                                                    }
                                                />
                                                {this.state.errors?.nasabahNomorNpwpLength ? (
                                                    <div className="invalid-feedback">
                                                        Nomor Npwp harus 15 karakter.
                                                    </div>
                                                ) : null}
                                                {this.state.errors.nasabahNomorNpwpRequired ? (
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                ) : null}
                                                {this.state.errors.nasabahNomorNpwpAlready ? (
                                                    <div className="invalid-feedback">
                                                        Nomor NPWP sudah terdaftar.
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label
                                                    className={
                                                        this.state.errors.nasabahNamaPerusahaan
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Nama Perusahaan{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <input
                                                    placeholder="Masukkan Nama Perusahaan"
                                                    className={`form-control ${
                                                        this.state.errors.nasabahNamaPerusahaan
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) => this.handleInputChange(e)}
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahNamaPerusahaan"
                                                    value={this.state.nasabahNamaPerusahaan}
                                                />
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className={
                                                        this.state.errors.nasabahOmsetPerusahaan
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Omset Perusahaan{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <input
                                                    placeholder="Rp"
                                                    className={`form-control ${
                                                        this.state.errors.nasabahOmsetPerusahaan
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) => this.handleInputChange(e)}
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahOmsetPerusahaan"
                                                    value={this.state.nasabahOmsetPerusahaan}
                                                />
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <FormHeader className="tw-my-4 mt-4">Data Perusahaan</FormHeader>
                                    <div className="row mt-2">
                                        <div className="col-5">
                                            <div className="form-group">
                                                <label
                                                    className={
                                                        this.state.errors.nasabahBidangUsaha
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Bidang Usaha{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    className={`form-control ${
                                                        this.state.errors.nasabahBidangUsaha
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    name="nasabahBidangUsaha"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahBidangUsaha
                                                    }
                                                >
                                                    <option
                                                        value=""
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        Pilih bidang usaha
                                                    </option>
                                                    {this.state._fields.R_BIDANG_USAHA &&
                                                    this.state._fields.R_BIDANG_USAHA
                                                        .length > 0
                                                        ? this.state._fields.R_BIDANG_USAHA.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={
                                                                              data.reference_code +
                                                                              ' - ' +
                                                                              data.reference_desc
                                                                          }
                                                                      >
                                                                          {data.reference_desc}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Lokasi</label>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <input
                                                            placeholder="Kode Lokasi"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ? true : false
                                                            }
                                                            name="nasabahPemilik"
                                                            value={
                                                                this.state._identitasNasabah?.nasabahPemilik
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-8">
                                                            <input
                                                            placeholder="Keterangan Lokasi"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled
                                                            // disabled={
                                                            //     this.state.mode === 'info' ? true : false
                                                            // }
                                                            name="nasabahPemilik"
                                                            value={
                                                                this.state._identitasNasabah?.nasabahPemilik
                                                            }
                                                        />
                                                    </div>
                                                </div>     
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nomor SIUPP</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Masukkan nomor SIUPP"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahNomorSiupp"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahNomorSiupp
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Tgl. Pengesahan Akte SIUPP</label>
                                                        <input
                                                            type="date"
                                                            placeholder="dd-mm-yyyy"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTanggalPengesahanSiupp"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTanggalPengesahanSiupp
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Nomor SKDP</label>
                                                <input
                                                    type="text"
                                                    placeholder="Masukan Nomor SKDP"
                                                    className="form-control"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahNomorSkdp"
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahNomorSkdp
                                                    }
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label
                                                            className={`${
                                                                this.state.errors
                                                                    .nasabahNomorAktePendirian
                                                                    ? 'text-danger'
                                                                    : ''
                                                            }`}
                                                        >
                                                            No. Akte Pendirian{' '}
                                                            <span className="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Masukkan nomor akte pendirian"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            className={`form-control ${
                                                                this.state.errors
                                                                    .nasabahNomorAktePendirian
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahNomorAktePendirian"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahNomorAktePendirian
                                                            }
                                                        />
                                                        <div className="invalid-feedback">
                                                            Bidang ini wajib diisi.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label
                                                            className={`${
                                                                this.state.errors
                                                                    .nasabahTanggalAktePendirian
                                                                    ? 'text-danger'
                                                                    : ''
                                                            }`}
                                                        >
                                                            Tgl. Pendirian{' '}
                                                            <span className="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            placeholder="dd-mm-yyyy"
                                                            className={`form-control ${
                                                                this.state.errors
                                                                    .nasabahTanggalAktePendirian
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            type="date"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            readOnly={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTanggalAktePendirian"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTanggalAktePendirian
                                                            }
                                                        />
                                                        <div className="invalid-feedback">
                                                            Bidang ini wajib diisi.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Tempat Pendirian</label>
                                                <input
                                                    placeholder="Marketing tempat pendirian"
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahTempatAktePendirian"
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahTempatAktePendirian
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label
                                                    className={`${
                                                        this.state.errors.nasabahSektorEkonomi
                                                            ? 'text-danger'
                                                            : ''
                                                    }`}
                                                >
                                                    Sektor Ekonomi{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    className={`form-control ${
                                                        this.state.errors.nasabahSektorEkonomi
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    name="nasabahSektorEkonomi"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahSektorEkonomi
                                                    }
                                                >
                                                    <option
                                                        value=""
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        Pilih Sektor Ekonomi
                                                    </option>
                                                    {this.state._fields.R_SEKTOR_EKONOMI &&
                                                    this.state._fields.R_SEKTOR_EKONOMI.length > 0
                                                        ? this.state._fields.R_SEKTOR_EKONOMI.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={
                                                                              data.reference_code +
                                                                              ' - ' +
                                                                              data.reference_desc
                                                                          }
                                                                      >
                                                                          {data.reference_desc}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Status Keterkaitan</label>
                                                        <select
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            className="form-control"
                                                            name="nasabahStatusKeterkaitan"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahStatusKeterkaitan
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih status keterkaitan
                                                            </option>
                                                            {this.state._fields
                                                                .eStatusKeterkaitan &&
                                                            this.state._fields.eStatusKeterkaitan
                                                                .length > 0
                                                                ? this.state._fields.eStatusKeterkaitan.map(
                                                                        (data, index) => {
                                                                            return (
                                                                                <option
                                                                                    disabled={
                                                                                        this.state
                                                                                            .mode ==
                                                                                        'info'
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    value={
                                                                                        data.reference_code +
                                                                                        ' - ' +
                                                                                        data.reference_desc
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        data.reference_desc
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )
                                                                : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Bank / Non-Bank</label>
                                                        <br />
                                                        <div className="form-check-inline mt-2">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="nasabahBankNonBank"
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_identitasNasabah'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={'T - BANK'}
                                                                    checked={
                                                                        this.state._identitasNasabah
                                                                            ?.nasabahBankNonBank ===
                                                                        'T - BANK'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                Bank
                                                            </label>
                                                        </div>
                                                        <div className="form-check-inline ml-3">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="nasabahBankNonBank"
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_identitasNasabah'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={'F - NONBANK'}
                                                                    checked={
                                                                        this.state._identitasNasabah
                                                                            ?.nasabahBankNonBank ===
                                                                        'F - NONBANK'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                Non-Bank
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state._identitasNasabah?.nasabahBankNonBank === 'T - BANK' ?
                                                    (
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                        <label>Kegiatan Usaha</label>
                                                        <br />
                                                        <div className="form-check-inline mt-2">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="nasabahKegiatanusaha"
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_identitasNasabah'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={'Syariah'}
                                                                    checked={
                                                                        this.state._identitasNasabah
                                                                            ?.nasabahKegiatanusaha ===
                                                                        'Syariah'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                Bank Syariah
                                                            </label>
                                                        </div>
                                                        <div className="form-check-inline mt-2">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="nasabahKegiatanusaha"
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_identitasNasabah'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={'Konvensional'}
                                                                    checked={
                                                                        this.state._identitasNasabah
                                                                            ?.nasabahKegiatanusaha ===
                                                                        'Konvensional'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                Bank Konvensional
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                    )
                                                : null
                                            }
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>No. Akte Perubahan</label>
                                                        <input
                                                            type="number"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Masukkan nomor akte perubahan"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahNomorAktePerubahan"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahNomorAktePerubahan
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>
                                                            Tgl. Pengesahan Akte Perubahan
                                                        </label>
                                                        <input
                                                            placeholder="dd-mm-yyyy"
                                                            type="date"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTanggalAktePerubahan"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTanggalAktePerubahan
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Tgl. Pengesahan SKDP</label>
                                                        <input
                                                            placeholder="dd-mm-yyyy"
                                                            className="form-control"
                                                            type="date"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTanggalPengesahanSkdp"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTanggalPengesahanSkdp
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Tgl. Kadaluarsa SKDP</label>
                                                        <input
                                                            placeholder="dd-mm-yyyy"
                                                            className="form-control"
                                                            type="date"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTanggalKadaluarsaSkdp"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTanggalKadaluarsaSkdp
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>No. SK MENKUMHAM</label>
                                                        <input
                                                            type="number"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Masukkan nomor SK MENKUMHAM"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahNomorSkMenkumham"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahNomorSkMenkumham
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Tgl. SK MENKUMHAM</label>
                                                        <input
                                                            placeholder="dd-mm-yyyy"
                                                            className="form-control"
                                                            type="date"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTanggalSkMenkumham"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTanggalSkMenkumham
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Nomor TDP</label>
                                                <input
                                                    type="number"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    placeholder="Masukan Nomor TDP"
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahNomorTDP"
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahNomorTDP
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <FormHeader className="tw-my-4 mt-4">Data Pemilik</FormHeader>
                                    <div className="row mt-2">
                                        <div className="col-5">
                                            <div className="form-group">
                                                <label>Pemilik</label>
                                                <input
                                                    placeholder="Masukkan nama pemilik"
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahPemilik"
                                                    value={
                                                        this.state._identitasNasabah?.nasabahPemilik
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Kepemilikan Modal</label>
                                                <select
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    className="form-control"
                                                    name="nasabahKepemilikanModal"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahKepemilikanModal
                                                    }
                                                >
                                                    <option value="">
                                                        Pilih Kepemilikan Modal
                                                    </option>
                                                    {this.state._fields.eStrukturModal &&
                                                    this.state._fields.eStrukturModal.length > 0
                                                        ? this.state._fields.eStrukturModal.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={
                                                                              data.reference_code +
                                                                              ' - ' +
                                                                              data.reference_desc
                                                                          }
                                                                      >
                                                                          {data.reference_desc}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <FormHeader className="tw-my-4 mt-4">Data Alamat & Kontak</FormHeader>
                                    <div className="row mt-2">
                                        <div className="col-5">
                                             <div className="form-group">
                                                <label
                                                    className={`${
                                                        this.state.errors.nasabahAlamat
                                                            ? 'text-danger'
                                                            : ''
                                                    }`}
                                                >
                                                    Alamat{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <textarea
                                                    rows="3"
                                                    className={`form-control ${
                                                        this.state.errors.nasabahAlamat
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    placeholder="Masukkan alamat"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahAlamat"
                                                    value={
                                                        this.state._identitasNasabah?.nasabahAlamat
                                                    }
                                                ></textarea>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className={`${
                                                        this.state.errors.nasabahRw ||
                                                        this.state.errors.nasabahRt
                                                            ? 'text-danger'
                                                            : ''
                                                    }`}
                                                >
                                                    RT / RW{' '}
                                                    {/* <span className="badge badge-secondary">
                                                        Wajib
                                                    </span> */}
                                                </label>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <input
                                                            type="number"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Masukkan RT"
                                                            className={`form-control ${
                                                                this.state.errors.nasabahRt
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahRt"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahRt ? this.state._identitasNasabah
                                                                    ?.nasabahRt : ''
                                                            }
                                                        />
                                                        <div className="invalid-feedback">
                                                            Bidang ini wajib diisi.
                                                        </div>
                                                    </div>
                                                    <div className="col-1">
                                                        <span>/</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <input
                                                            type="number"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Masukkan RW"
                                                            className={`form-control ${
                                                                this.state.errors.nasabahRw
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahRw"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahRw ? this.state._identitasNasabah
                                                                    ?.nasabahRw : ''
                                                            }
                                                        />
                                                        <div className="invalid-feedback">
                                                            Bidang ini wajib diisi.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label
                                                        className={`${
                                                            this.state.errors.nasabahProvinsi
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                    >
                                                        Provinsi{' '}
                                                        <span className="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <select
                                                        className={`form-control ${
                                                            this.state.errors.nasabahProvinsi
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_identitasNasabah'
                                                            );
                                                            this.getCities(
                                                                '_identitasNasabah',
                                                                e.target.value
                                                            );
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="nasabahProvinsi"
                                                        value={
                                                            this.state._identitasNasabah
                                                                ?.nasabahProvinsi
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Provinsi
                                                        </option>
                                                        {this.state._fields._identitasNasabah
                                                            .provinces &&
                                                        this.state._fields._identitasNasabah
                                                            .provinces.length > 0
                                                            ? this.state._fields._identitasNasabah.provinces.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.province_code
                                                                              }
                                                                          >
                                                                              {data.province_name}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label
                                                        className={`${
                                                            this.state.errors.nasabahKotaKabupaten
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                    >
                                                        Kota/Kab{' '}
                                                        <span className="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <select
                                                        className={`form-control ${
                                                            this.state.errors.nasabahKotaKabupaten
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_identitasNasabah'
                                                            );
                                                            this.getSubDistricts(
                                                                '_identitasNasabah',
                                                                e.target.value
                                                            );
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="nasabahKotaKabupaten"
                                                        value={
                                                            this.state._identitasNasabah
                                                                ?.nasabahKotaKabupaten
                                                        }
                                                    >
                                                        {this.state.mode === 'create' ? (
                                                            <option value="">
                                                                Pilih Kabupaten/Kota
                                                            </option>
                                                        ) : (
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    this.state._identitasNasabah
                                                                        ?.nasabahKotaKabupaten
                                                                }
                                                            >
                                                                {
                                                                    this.state._identitasNasabah
                                                                        ?.nasabahKotaKabupaten
                                                                }
                                                            </option>
                                                        )}
                                                        {this.state._fields._identitasNasabah
                                                            .cities &&
                                                        this.state._fields._identitasNasabah.cities
                                                            .length > 0
                                                            ? this.state._fields._identitasNasabah.cities.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={data}
                                                                          >
                                                                              {data}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label
                                                        className={`${
                                                            this.state.errors.nasabahKecamatan
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                    >
                                                        Kecamatan{' '}
                                                        <span className="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <select
                                                        placeholder="Masukkan Kecamatan"
                                                        className={`form-control ${
                                                            this.state.errors.nasabahKecamatan
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_identitasNasabah'
                                                            );
                                                            this.getUrbans(
                                                                '_identitasNasabah',
                                                                e.target.value,
                                                                this.state._identitasNasabah
                                                                    ?.nasabahKotaKabupaten
                                                            );
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="nasabahKecamatan"
                                                        value={
                                                            this.state._identitasNasabah
                                                                ?.nasabahKecamatan
                                                        }
                                                    >
                                                        {this.state.mode === 'create' ? (
                                                            <option value="">
                                                                Pilih Kecamatan
                                                            </option>
                                                        ) : (
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    this.state._identitasNasabah
                                                                        ?.nasabahKecamatan
                                                                }
                                                            >
                                                                {
                                                                    this.state._identitasNasabah
                                                                        ?.nasabahKecamatan
                                                                }
                                                            </option>
                                                        )}
                                                        {this.state._fields._identitasNasabah
                                                            .subdistricts &&
                                                        this.state._fields._identitasNasabah
                                                            .subdistricts.length > 0
                                                            ? this.state._fields._identitasNasabah.subdistricts.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={data}
                                                                          >
                                                                              {data}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label
                                                        className={`${
                                                            this.state.errors.nasabahKelurahan
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                    >
                                                        Kelurahan{' '}
                                                        <span className="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <select
                                                        placeholder="Masukkan Kelurahan"
                                                        className={`form-control ${
                                                            this.state.errors.nasabahKelurahan
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_identitasNasabah'
                                                            );
                                                            this.getPostalsCode(
                                                                '_identitasNasabah',
                                                                this.state._identitasNasabah
                                                                    .nasabahKotaKabupaten,
                                                                this.state._identitasNasabah
                                                                    .nasabahKecamatan,
                                                                e.target.value
                                                            );
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="nasabahKelurahan"
                                                        value={
                                                            this.state._identitasNasabah
                                                                ?.nasabahKelurahan
                                                        }
                                                    >
                                                        {this.state.mode === 'create' ? (
                                                            <option value="">
                                                                Pilih Kelurahan
                                                            </option>
                                                        ) : (
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    this.state._identitasNasabah
                                                                        ?.nasabahKelurahan
                                                                }
                                                            >
                                                                {
                                                                    this.state._identitasNasabah
                                                                        ?.nasabahKelurahan
                                                                }
                                                            </option>
                                                        )}
                                                        {this.state._fields._identitasNasabah
                                                            .urbans &&
                                                        this.state._fields._identitasNasabah.urbans
                                                            .length > 0
                                                            ? this.state._fields._identitasNasabah.urbans.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={data}
                                                                          >
                                                                              {data}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group">
                                                <label>Kode Pos</label>
                                                <select
                                                    placeholder="Masukkan Kode Pos"
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahKodePos"
                                                    value={
                                                        this.state._identitasNasabah?.nasabahKodePos
                                                    }
                                                >
                                                    {this.state.mode === 'create' ? (
                                                        <option value="">Pilih Kode Pos</option>
                                                    ) : (
                                                        <option
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahKodePos
                                                            }
                                                        >
                                                            {
                                                                this.state._identitasNasabah
                                                                    ?.nasabahKodePos
                                                            }
                                                        </option>
                                                    )}
                                                    {this.state._fields._identitasNasabah
                                                        .postalscode &&
                                                    this.state._fields._identitasNasabah.postalscode
                                                        .length > 0
                                                        ? this.state._fields._identitasNasabah.postalscode.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option value={data}>
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-6">
                                        <div className="form-group">
                                                <label
                                                    className={`${
                                                        this.state.errors
                                                            .nasabahTeleponKantorKodeArea1 ||
                                                        this.state.errors.nasabahTeleponKantorNomor1
                                                            ? 'text-danger'
                                                            : ''
                                                    }`}
                                                >
                                                    Telepon Kantor 1{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <input
                                                            type="number"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="000"
                                                            className={`form-control ${
                                                                this.state.errors
                                                                    .nasabahTeleponKantorKodeArea1
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTeleponKantorKodeArea1"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTeleponKantorKodeArea1
                                                            }
                                                        />
                                                        <div className="invalid-feedback">
                                                            Bidang ini wajib diisi.
                                                        </div>
                                                    </div>
                                                    <div className="col-1">
                                                        <span>-</span>
                                                    </div>
                                                    <div className="col-8">
                                                        <input
                                                            type="number"
                                                            placeholder="000"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            className={`form-control ${
                                                                this.state.errors
                                                                    .nasabahTeleponKantorNomor1
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTeleponKantorNomor1"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTeleponKantorNomor1
                                                            }
                                                        />
                                                        <div className="invalid-feedback">
                                                            Bidang ini wajib diisi.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Telepon Kantor 2</label>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <input
                                                            type="number"
                                                            placeholder="000"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTeleponKantorKodeArea2"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTeleponKantorKodeArea2
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-1">
                                                        <span>-</span>
                                                    </div>
                                                    <div className="col-8">
                                                        <input
                                                            type="number"
                                                            placeholder="000"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTeleponKantorNomor2"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTeleponKantorNomor2
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Telepon Kantor 3</label>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <input
                                                            type="number"
                                                            placeholder="000"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTeleponKantorKodeArea3"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTeleponKantorKodeArea3
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-1">
                                                        <span>-</span>
                                                    </div>
                                                    <div className="col-8">
                                                        <input
                                                            type="number"
                                                            placeholder="000"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTeleponKantorNomor3"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTeleponKantorNomor3
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Fax</label>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <input
                                                            type="number"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="000"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahFaxKodeArea"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahFaxKodeArea
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-1">
                                                        <span>-</span>
                                                    </div>
                                                    <div className="col-8">
                                                        <input
                                                            type="number"
                                                            placeholder="000"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahFaxNomor"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahFaxNomor
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className={`${
                                                        this.state.errors.emailAddress
                                                            ? 'text-danger'
                                                            : ''
                                                    }`}
                                                >
                                                    Email{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <input
                                                    placeholder="Masukkan alamat email"
                                                    className={`form-control ${
                                                        this.state.errors.emailAddress
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                    }}
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="emailAddress"
                                                    value={this.state.emailAddress}
                                                />
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-5">
                                            <FormHeader className="tw-my-4 mt-2">Informasi Lainnya</FormHeader>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    Nasabah Prioritas
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <label>Referal Nasabah</label>
                                                <input
                                                    placeholder="Bila ada"
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="nasabahPicNasabah"
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahPicNasabah
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Marketing</label>
                                                <div className="row">
                                                    <div className="col-4">
                                                    <input
                                                        placeholder="Kode Marketing"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_identitasNasabah'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info' ? true : false
                                                        }
                                                        name="nasabahKodeMarketing"
                                                        value={
                                                            this.state._identitasNasabah
                                                                ?.nasabahKodeMarketing
                                                        }
                                                    />
                                                    </div>
                                                    <div className="col-8">
                                                    <input
                                                        placeholder="Nama Marketing"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_identitasNasabah'
                                                            )
                                                        }
                                                        disabled
                                                        // disabled={
                                                        //     this.state.mode === 'info' ? true : false
                                                        // }
                                                        name="nasabahKodeMarketing"
                                                        value={
                                                            this.state._identitasNasabah
                                                                ?.nasabahKodeMarketing
                                                        }
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Wilayah Referensi</label>
                                                
                                                <input
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    className="form-control"
                                                    name="nasabahWilayahPemberiReferensi"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahWilayahPemberiReferensi
                                                    }
                                                />
                                            </div> */}
                                            <div className="form-group">
                                                <label>Keterangan</label>
                                                <textarea
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="notes"
                                                    rows="3"
                                                    className="form-control"
                                                    placeholder="bila ada"
                                                    value={this.state.notes}
                                                    onChange={(e) => this.handleInputChange(e)}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-6">
                                            <FormHeader className="tw-my-4 mt-2">Hirarki Nasabah</FormHeader>
                                            <div className="form-group">
                                                <label>Nasabah Induk</label>
                                                <div className="row">
                                                    <div className="col-4 mr-0">
                                                    <input
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    className="form-control"
                                                    name="nasabahNasabahInduk"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahNasabahInduk
                                                    }
                                                    />
                                                    </div>
                                                    <div className="col-8">
                                                    <input
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    className="form-control"
                                                    name="nasabahNasabahInduk"
                                                    disabled
                                                    placeholder="NAMA NASABAH"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_identitasNasabah'
                                                        )
                                                    }
                                                    value={
                                                        this.state._identitasNasabah
                                                            ?.nasabahNasabahInduk
                                                    }
                                                />
                                                    </div>
                                                </div>
                                               
                                                
                                            </div>

                                            {/* <div className="row mt-4 mb-2">
                                                <div className="col-auto text-primary">
                                                    Alamat Alternatif
                                                </div>
                                                <div className="col">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Alamat</label>
                                                <textarea
                                                    rows="3"
                                                    className="form-control"
                                                    placeholder="Masukkan alamat"
                                                    onChange={(e) => {
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_alamatAlternatif'
                                                        );
                                                    }}
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="alternatifAlamat"
                                                    value={
                                                        this.state._alamatAlternatif
                                                            ?.alternatifAlamat
                                                    }
                                                ></textarea>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label>Provinsi</label>
                                                    <select
                                                        className={`form-control`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_alamatAlternatif'
                                                            );
                                                            this.getCities(
                                                                '_alamatAlternatif',
                                                                e.target.value
                                                            );
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="alternatifProvinsi"
                                                        value={
                                                            this.state._alamatAlternatif
                                                                ?.alternatifProvinsi
                                                        }
                                                    >
                                                        {this.state.mode === 'create' ? (
                                                            <option value="">Pilih Provinsi</option>
                                                        ) : null}
                                                        {this.state._fields._alamatAlternatif
                                                            .provinces &&
                                                        this.state._fields._alamatAlternatif
                                                            .provinces.length > 0
                                                            ? this.state._fields._alamatAlternatif.provinces.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.province_name
                                                                              }
                                                                          >
                                                                              {data.province_name}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Kota/Kab </label>
                                                    <select
                                                        className={`form-control`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_alamatAlternatif'
                                                            );
                                                            this.getSubDistricts(
                                                                '_alamatAlternatif',
                                                                e.target.value
                                                            );
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="alternatifKotaKabupaten"
                                                        value={
                                                            this.state._alamatAlternatif
                                                                ?.alternatifKotaKabupaten
                                                        }
                                                    >
                                                        {this.state.mode === 'create' ? (
                                                            <option value="">
                                                                Pilih Kabupaten/Kota
                                                            </option>
                                                        ) : (
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    this.state._alamatAlternatif
                                                                        ?.alternatifKotaKabupaten
                                                                }
                                                            >
                                                                {
                                                                    this.state._alamatAlternatif
                                                                        ?.alternatifKotaKabupaten
                                                                }
                                                            </option>
                                                        )}
                                                        {this.state._fields._alamatAlternatif
                                                            .cities &&
                                                        this.state._fields._alamatAlternatif.cities
                                                            .length > 0
                                                            ? this.state._fields._alamatAlternatif.cities.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={data}
                                                                          >
                                                                              {data}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label>Kecamatan</label>
                                                    <select
                                                        placeholder="Masukkan Kecamatan"
                                                        className={`form-control`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_alamatAlternatif'
                                                            );
                                                            this.getUrbans(
                                                                '_alamatAlternatif',
                                                                e.target.value,
                                                                this.state._alamatAlternatif
                                                                    ?.alternatifKotaKabupaten
                                                            );
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="alternatifKecamatan"
                                                        value={
                                                            this.state._alamatAlternatif
                                                                ?.alternatifKecamatan
                                                        }
                                                    >
                                                        {this.state.mode === 'create' ? (
                                                            <option value="">
                                                                Pilih Kecamatan
                                                            </option>
                                                        ) : (
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    this.state._alamatAlternatif
                                                                        ?.alternatifKecamatan
                                                                }
                                                            >
                                                                {
                                                                    this.state._alamatAlternatif
                                                                        ?.alternatifKecamatan
                                                                }
                                                            </option>
                                                        )}
                                                        {this.state._fields._alamatAlternatif
                                                            .subdistricts &&
                                                        this.state._fields._alamatAlternatif
                                                            .subdistricts.length > 0
                                                            ? this.state._fields._alamatAlternatif.subdistricts.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={data}
                                                                          >
                                                                              {data}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Kelurahan</label>
                                                    <select
                                                        placeholder="Masukkan Kelurahan"
                                                        className={`form-control`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_alamatAlternatif'
                                                            );
                                                            this.getPostalsCode(
                                                                '_alamatAlternatif',
                                                                this.state._alamatAlternatif
                                                                    .alternatifKotaKabupaten,
                                                                this.state._alamatAlternatif
                                                                    .alternatifKecamatan,
                                                                e.target.value
                                                            );
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="alternatifKelurahan"
                                                        value={
                                                            this.state._alamatAlternatif
                                                                ?.alternatifKelurahan
                                                        }
                                                    >
                                                        {this.state.mode === 'create' ? (
                                                            <option value="">
                                                                Pilih Kelurahan
                                                            </option>
                                                        ) : (
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    this.state._alamatAlternatif
                                                                        ?.alternatifKelurahan
                                                                }
                                                            >
                                                                {
                                                                    this.state._alamatAlternatif
                                                                        ?.alternatifKelurahan
                                                                }
                                                            </option>
                                                        )}
                                                        {this.state._fields._alamatAlternatif
                                                            .urbans &&
                                                        this.state._fields._alamatAlternatif.urbans
                                                            .length > 0
                                                            ? this.state._fields._alamatAlternatif.urbans.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={data}
                                                                          >
                                                                              {data}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label>RT / RW</label>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <input
                                                            placeholder="Masukkan RT"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_alamatAlternatif'
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="alternatifRt"
                                                            value={
                                                                this.state._alamatAlternatif
                                                                    ?.alternatifRt
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-1 text-center">
                                                        <span className="mt-1">{'/'}</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <input
                                                            placeholder="Masukkan RW"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_alamatAlternatif'
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="alternatifRw"
                                                            value={
                                                                this.state._alamatAlternatif
                                                                    ?.alternatifRw
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Kode Pos</label>
                                                <select
                                                    placeholder="Masukkan Kode Pos"
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_alamatAlternatif'
                                                        )
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="alternatifKodePos"
                                                    value={
                                                        this.state._alamatAlternatif
                                                            ?.alternatifKodePos
                                                    }
                                                >
                                                    {this.state.mode === 'create' ? (
                                                        <option value="">Pilih Kode Pos</option>
                                                    ) : (
                                                        <option
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                this.state._alamatAlternatif
                                                                    ?.alternatifKodePos
                                                            }
                                                        >
                                                            {
                                                                this.state._alamatAlternatif
                                                                    ?.alternatifKodePos
                                                            }
                                                        </option>
                                                    )}
                                                    {this.state._fields._alamatAlternatif
                                                        .postalscode &&
                                                    this.state._fields._alamatAlternatif.postalscode
                                                        .length > 0
                                                        ? this.state._fields._alamatAlternatif.postalscode.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option value={data}>
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label>No Telepon</label>
                                                <input
                                                    placeholder="Masukkan nomor handphone"
                                                    className="form-control"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_alamatAlternatif'
                                                        );
                                                    }}
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="alternatifTelepon"
                                                    value={
                                                        this.state._alamatAlternatif
                                                            ?.alternatifTelepon
                                                    }
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        ) : null}

                        {this.state.step === 1 ? (
                            <div>
                                <p className="global-text">Cari data berdasarkan :</p>
                                <div className="row">
                                    <div className="col-2">
                                        <div className="form-group">
                                            <select name="" id="" className="form-control">
                                                <option value="">Jenis Nasabah</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-2">
                                        <div className="form-group">
                                            <select name="" id="" className="form-control">
                                                <option value="">Jenis Produk</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Nomor Nasabah atau Nama"
                                                onChange={(e) => {
                                                    this.setState({
                                                        _pejabatKorporatSearch: e.target.value,
                                                    });
                                                }}
                                                value={this.state._pejabatKorporatSearch}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <button
                                            className="btn btn-outline-primary"
                                            onClick={() =>
                                                this.setState({ _pejabatKorporatSearch: '' })
                                            }
                                        >
                                            Reset
                                        </button>
                                    </div>
                                    <div className="col-3 p0">
                                        <button className="btn btn-primary btn-block">Cari Nasabah</button>
                                    </div>
                                    <div className="col-3">
                                        {this.state.mode !== 'info' ? (
                                            <button
                                                onClick={() =>
                                                    this.setState({
                                                        showModalTambahPejabat: true,
                                                        _pejabatKorporatFormMode: 'create',
                                                    })
                                                }
                                                className="btn btn-outline-primary btn-block"
                                            >
                                                Tambah Pejabat Korporat
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                                <p className="text-primary">Pejabat Korporat</p>
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Jabatan</th>
                                                    <th>Nomor Nasabah</th>
                                                    <th>Nama</th>
                                                    <th>Alamat</th>
                                                    <th>Jenis Identitas</th>
                                                    <th>Nomor Identitas</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state._pejabatKorporat?.map((data, index) => {
                                                    if (
                                                        this.state._pejabatKorporatSearch !== '' &&
                                                        data?.pejabatNama
                                                            .toLowerCase()
                                                            .indexOf(
                                                                this.state._pejabatKorporatSearch.toLowerCase()
                                                            ) === -1 &&
                                                        data?.pejabatNomorNasabahPejabat
                                                            .toLowerCase()
                                                            .indexOf(
                                                                this.state._pejabatKorporatSearch.toLowerCase()
                                                            ) === -1
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <tr key={`tablePejabatKorporate_${index}`}>
                                                            <td>{data.pejabatJabatan}</td>
                                                            <td>
                                                                {data.pejabatNomorNasabahPejabat}
                                                            </td>
                                                            <td>{data.pejabatNama}</td>
                                                            <td>{data.pejabatAlamat}</td>
                                                            <td>{data.pejabatJenisIdentitas}</td>
                                                            <td>{data.pejabatNomorIdentitas}</td>
                                                            <td>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle
                                                                        variant="light"
                                                                        className="btn-sm"
                                                                        id="dropdown-basic"
                                                                    >
                                                                        <i className="fa fa-ellipsis-h"></i>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                            onClick={() =>
                                                                                this.detailPejabat(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            Detil Pejabat Korporat
                                                                        </Dropdown.Item>
                                                                        {this.state.mode !==
                                                                            'info' && (
                                                                            <>
                                                                                <Dropdown.Item
                                                                                    onClick={() =>
                                                                                        this.ubahPejabat(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Ubah Pejabat
                                                                                    Korporat
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item
                                                                                    onClick={() =>
                                                                                        this.removePejabat(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    className="text-danger"
                                                                                >
                                                                                    Hapus Pejabat
                                                                                    Korporat
                                                                                </Dropdown.Item>
                                                                            </>
                                                                        )}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <Modal
                                    show={this.state.showModalTambahPejabat}
                                    onHide={() => this.setState({ showModalTambahPejabat: false })}
                                    backdrop="static"
                                    keyboard={false}
                                    size="xl"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {this.state._pejabatKorporatFormMode === 'create'
                                                ? 'Tambah'
                                                : this.state._pejabatKorporatFormMode === 'update'
                                                ? 'Ubah'
                                                : 'Lihat'}{' '}
                                            Pejabat Korporat
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="bns-form">
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label
                                                        className={`${
                                                            this.state.errors.pejabatJabatan
                                                                ? 'text-danger'
                                                                : ''
                                                        }`}
                                                    >
                                                        Jabatan / Pangkat
                                                    </label>
                                                    <select
                                                        className={`form-control ${
                                                            this.state.errors.pejabatJabatan
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_pejabatKorporatForm'
                                                            );
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info' ||
                                                            this.state._pejabatKorporatFormMode ==
                                                                'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="pejabatJabatan"
                                                        value={
                                                            this.state._pejabatKorporatForm
                                                                .pejabatJabatan
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih jabatan pekerjaan
                                                        </option>
                                                        {this.state._fields.R_JABATAN_KORPORAT &&
                                                        this.state._fields.R_JABATAN_KORPORAT
                                                            .length > 0
                                                            ? this.state._fields.R_JABATAN_KORPORAT.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.reference_code +
                                                                                  ' - ' +
                                                                                  data.reference_desc
                                                                              }
                                                                          >
                                                                              {data.reference_desc}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                    {/* <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="row mt-4 mb-2">
                                                <div className="col-auto text-primary">
                                                    Data Diri
                                                </div>
                                                <div className="col">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label
                                                            className={`${
                                                                this.state.errors
                                                                    .pejabatNomorNasabahPejabat
                                                                    ? 'text-danger'
                                                                    : ''
                                                            }`}
                                                            htmlFor=""
                                                        >
                                                            Nomor Nasabah{' '}
                                                            {/* <span className="badge badge-secondary">
                                                                Wajib
                                                            </span> */}
                                                        </label>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Masukkan nomor nasabah"
                                                                    className={`form-control ${
                                                                        this.state.errors
                                                                            .pejabatNomorNasabahPejabat
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                    }`}
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_pejabatKorporatForm'
                                                                        );
                                                                        this.checkNomorCif(
                                                                            '_pejabatKorporatForm',
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    disabled={
                                                                        this.state.mode ===
                                                                            'info' ||
                                                                        this.state
                                                                            ._pejabatKorporatFormMode ==
                                                                            'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    name="pejabatNomorNasabahPejabat"
                                                                    value={
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            .pejabatNomorNasabahPejabat
                                                                    }
                                                                    readOnly
                                                                />
                                                                <div className="invalid-feedback">
                                                                    {
                                                                        this.state.messages
                                                                            .pejabatNomorNasabahPejabat
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-1">
                                                                {this.state.mode !== 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode !==
                                                                    'info' ? (
                                                                    <button
                                                                        className="btn btn-outline-secondary"
                                                                        onClick={() =>
                                                                            this.resetCheckNomorCif(
                                                                                '_pejabatKorporatForm'
                                                                            )
                                                                        }
                                                                    >
                                                                        Hapus
                                                                    </button>
                                                                ) : null}
                                                            </div> */}
                                                            <div className="col-3">
                                                                {this.state.mode !== 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode !==
                                                                    'info' ? (
                                                                    <button
                                                                        className="btn bg-orange-primary text-white btn-block"
                                                                        onClick={() =>
                                                                            // this.checkNomorCif(
                                                                            //     '_pejabatKorporatForm',
                                                                            //     this.state
                                                                            //         ._pejabatKorporatForm
                                                                            //         .pejabatNomorNasabahPejabat
                                                                            // )
                                                                            this.setState({
                                                                                showModalSearchNasabah:
                                                                                    '_pejabatKorporat',
                                                                                searchNasabahData:
                                                                                    [],
                                                                                searchNasabahType:
                                                                                    'CLIENT',
                                                                            })
                                                                        }
                                                                    >
                                                                    <p style={{ color: '#A0A4A8' }}><strong>Cari Nasabah</strong></p> 
                                                                    </button>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5">
                                                    <div className="form-group">
                                                        <label
                                                            className={`${
                                                                this.state.errors.pejabatNama
                                                                    ? 'text-danger'
                                                                    : ''
                                                            }`}
                                                        >
                                                            Nama (Sesuai Identitas){' '}
                                                            {/* <span className="badge badge-secondary">
                                                                Wajib
                                                            </span> */}
                                                        </label>
                                                        <input
                                                            placeholder="Nama nasabah sesuai identitas"
                                                            className={`form-control ${
                                                                this.state.errors.pejabatNama
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ===
                                                                    'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormModeDisabled
                                                                    .pejabatNama
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatNama"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatNama
                                                            }
                                                        />
                                                        {/* <div className="invalid-feedback">
                                                            Bidang ini wajib diisi.
                                                        </div> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Alamat</label>
                                                        <textarea
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Masukkan alamat"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormModeDisabled
                                                                    .pejabatAlamat
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatAlamat"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatAlamat
                                                            }
                                                        ></textarea>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label>Provinsi</label>
                                                            <select
                                                                placeholder="Masukkan provinsi"
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        '_pejabatKorporatForm'
                                                                    );
                                                                    this.getCities(
                                                                        '_pejabatKorporatForm',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                disabled={
                                                                    this.state.mode === 'info' ||
                                                                    this.state
                                                                        ._pejabatKorporatFormMode ==
                                                                        'info' ||
                                                                    this.state
                                                                        ._pejabatKorporatFormModeDisabled
                                                                        .pejabatProvinsi
                                                                        ? true
                                                                        : false
                                                                }
                                                                name="pejabatProvinsi"
                                                                value={
                                                                    this.state._pejabatKorporatForm
                                                                        .pejabatProvinsi
                                                                }
                                                            >
                                                                {!this.state._pejabatKorporatForm
                                                                    ?.pejabatProvinsi ? (
                                                                    <option
                                                                        value=""
                                                                        disabled={
                                                                            this.state.mode ===
                                                                            'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        Pilih Provinsi
                                                                    </option>
                                                                ) : (
                                                                    <option
                                                                        disabled={
                                                                            this.state.mode ===
                                                                            'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value={
                                                                            this.state
                                                                                ._pejabatKorporatForm
                                                                                ?.pejabatProvinsi
                                                                        }
                                                                    >
                                                                        {
                                                                            this.state
                                                                                ._pejabatKorporatForm
                                                                                ?.pejabatProvinsi
                                                                        }
                                                                    </option>
                                                                )}
                                                                {this.state._fields
                                                                    ._pejabatKorporatForm
                                                                    .provinces &&
                                                                this.state._fields
                                                                    ._pejabatKorporatForm.provinces
                                                                    .length > 0
                                                                    ? this.state._fields._pejabatKorporatForm.provinces.map(
                                                                          (data, index) => {
                                                                              return (
                                                                                  <option
                                                                                      disabled={
                                                                                          this.state
                                                                                              .mode ==
                                                                                          'info'
                                                                                              ? true
                                                                                              : false
                                                                                      }
                                                                                      value={
                                                                                          data.province_name
                                                                                      }
                                                                                  >
                                                                                      {
                                                                                          data.province_name
                                                                                      }
                                                                                  </option>
                                                                              );
                                                                          }
                                                                      )
                                                                    : null}
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label>Kota/Kab</label>
                                                            <select
                                                                placeholder="Masukkan kota/kabupaten"
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        '_pejabatKorporatForm'
                                                                    );
                                                                    this.getSubDistricts(
                                                                        '_pejabatKorporatForm',
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                disabled={
                                                                    this.state.mode === 'info' ||
                                                                    this.state
                                                                        ._pejabatKorporatFormMode ==
                                                                        'info' ||
                                                                    this.state
                                                                        ._pejabatKorporatFormModeDisabled
                                                                        .pejabatKotaKabupaten
                                                                        ? true
                                                                        : false
                                                                }
                                                                name="pejabatKotaKabupaten"
                                                                value={
                                                                    this.state._pejabatKorporatForm
                                                                        .pejabatKotaKabupaten
                                                                }
                                                            >
                                                                {!this.state._pejabatKorporatForm
                                                                    ?.pejabatKotaKabupaten ? (
                                                                    <option
                                                                        value=""
                                                                        disabled={
                                                                            this.state.mode ===
                                                                            'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        Pilih Kabupaten/Kota
                                                                    </option>
                                                                ) : (
                                                                    <option
                                                                        disabled={
                                                                            this.state.mode ===
                                                                            'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value={
                                                                            this.state
                                                                                ._pejabatKorporatForm
                                                                                ?.pejabatKotaKabupaten
                                                                        }
                                                                    >
                                                                        {
                                                                            this.state
                                                                                ._pejabatKorporatForm
                                                                                ?.pejabatKotaKabupaten
                                                                        }
                                                                    </option>
                                                                )}
                                                                {this.state._fields
                                                                    ._pejabatKorporatForm.cities &&
                                                                this.state._fields
                                                                    ._pejabatKorporatForm.cities
                                                                    .length > 0
                                                                    ? this.state._fields._pejabatKorporatForm.cities.map(
                                                                          (data, index) => {
                                                                              return (
                                                                                  <option
                                                                                      value={data}
                                                                                  >
                                                                                      {data}
                                                                                  </option>
                                                                              );
                                                                          }
                                                                      )
                                                                    : null}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label>Kecamatan</label>
                                                            <select
                                                                placeholder="Masukkan kota/kabupaten"
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        '_pejabatKorporatForm'
                                                                    );
                                                                    this.getUrbans(
                                                                        '_pejabatKorporatForm',
                                                                        e.target.value,
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            ?.pejabatKotaKabupaten
                                                                    );
                                                                }}
                                                                disabled={
                                                                    this.state.mode === 'info' ||
                                                                    this.state
                                                                        ._pejabatKorporatFormMode ==
                                                                        'info' ||
                                                                    this.state
                                                                        ._pejabatKorporatFormModeDisabled
                                                                        .pejabatKecamatan
                                                                        ? true
                                                                        : false
                                                                }
                                                                name="pejabatKecamatan"
                                                                value={
                                                                    this.state._pejabatKorporatForm
                                                                        .pejabatKecamatan
                                                                }
                                                            >
                                                                {!this.state._pejabatKorporatForm
                                                                    .pejabatKecamatan ? (
                                                                    <option
                                                                        value=""
                                                                        disabled={
                                                                            this.state.mode ===
                                                                            'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        Pilih Kecamatan
                                                                    </option>
                                                                ) : (
                                                                    <option
                                                                        disabled={
                                                                            this.state.mode ===
                                                                            'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value={
                                                                            this.state
                                                                                ._pejabatKorporatForm
                                                                                .pejabatKecamatan
                                                                        }
                                                                    >
                                                                        {
                                                                            this.state
                                                                                ._pejabatKorporatForm
                                                                                .pejabatKecamatan
                                                                        }
                                                                    </option>
                                                                )}
                                                                {this.state._fields
                                                                    ._pejabatKorporatForm
                                                                    .subdistricts &&
                                                                this.state._fields
                                                                    ._pejabatKorporatForm
                                                                    .subdistricts.length > 0
                                                                    ? this.state._fields._pejabatKorporatForm.subdistricts.map(
                                                                          (data, index) => {
                                                                              return (
                                                                                  <option
                                                                                      disabled={
                                                                                          this.state
                                                                                              .mode ==
                                                                                          'info'
                                                                                              ? true
                                                                                              : false
                                                                                      }
                                                                                      value={data}
                                                                                  >
                                                                                      {data}
                                                                                  </option>
                                                                              );
                                                                          }
                                                                      )
                                                                    : null}
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label>Kelurahan</label>
                                                            <select
                                                                placeholder="Masukkan kota/kabupaten"
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        '_pejabatKorporatForm'
                                                                    );
                                                                    this.getPostalsCode(
                                                                        '_pejabatKorporatForm',
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            .pejabatKotaKabupaten,
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            .pejabatKecamatan,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                disabled={
                                                                    this.state.mode === 'info' ||
                                                                    this.state
                                                                        ._pejabatKorporatFormMode ==
                                                                        'info' ||
                                                                    this.state
                                                                        ._pejabatKorporatFormModeDisabled
                                                                        .pejabatKelurahan
                                                                        ? true
                                                                        : false
                                                                }
                                                                name="pejabatKelurahan"
                                                                value={
                                                                    this.state._pejabatKorporatForm
                                                                        .pejabatKelurahan
                                                                }
                                                            >
                                                                {!this.state._pejabatKorporatForm
                                                                    .pejabatKelurahan ? (
                                                                    <option
                                                                        value=""
                                                                        disabled={
                                                                            this.state.mode ===
                                                                            'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        Pilih Kelurahan
                                                                    </option>
                                                                ) : (
                                                                    <option
                                                                        disabled={
                                                                            this.state.mode ===
                                                                            'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value={
                                                                            this.state
                                                                                ._pejabatKorporatForm
                                                                                .pejabatKelurahan
                                                                        }
                                                                    >
                                                                        {
                                                                            this.state
                                                                                ._pejabatKorporatForm
                                                                                .pejabatKelurahan
                                                                        }
                                                                    </option>
                                                                )}
                                                                {this.state._fields
                                                                    ._pejabatKorporatForm.urbans &&
                                                                this.state._fields
                                                                    ._pejabatKorporatForm.urbans
                                                                    .length > 0
                                                                    ? this.state._fields._pejabatKorporatForm.urbans.map(
                                                                          (data, index) => {
                                                                              return (
                                                                                  <option
                                                                                      disabled={
                                                                                          this.state
                                                                                              .mode ==
                                                                                          'info'
                                                                                              ? true
                                                                                              : false
                                                                                      }
                                                                                      value={data}
                                                                                  >
                                                                                      {data}
                                                                                  </option>
                                                                              );
                                                                          }
                                                                      )
                                                                    : null}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Kode Pos</label>
                                                        <select
                                                            placeholder="Masukkan Kode Pos"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormModeDisabled
                                                                    .pejabatKodePos
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatKodePos"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    ?.pejabatKodePos
                                                            }
                                                        >
                                                            {this.state.mode === 'create' ? (
                                                                <option value="">
                                                                    Pilih Kode Pos
                                                                </option>
                                                            ) : (
                                                                <option
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            ?.pejabatKodePos
                                                                    }
                                                                >
                                                                    {
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            ?.pejabatKodePos
                                                                    }
                                                                </option>
                                                            )}
                                                            {this.state._fields._pejabatKorporatForm
                                                                .postalscode &&
                                                            this.state._fields._pejabatKorporatForm
                                                                .postalscode.length > 0
                                                                ? this.state._fields._pejabatKorporatForm.postalscode.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option value={data}>
                                                                                  {data}
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>RT / RW</label>
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <input
                                                                    type="text"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    placeholder="Masukkan RT"
                                                                    className="form-control"
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_pejabatKorporatForm'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode ===
                                                                            'info' ||
                                                                        this.state
                                                                            ._pejabatKorporatFormMode ==
                                                                            'info' ||
                                                                        this.state
                                                                            ._pejabatKorporatFormModeDisabled
                                                                            .pejabatRt
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    name="pejabatRt"
                                                                    value={
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            .pejabatRt
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <span>/</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Masukkan RW"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_pejabatKorporatForm'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode ===
                                                                            'info' ||
                                                                        this.state
                                                                            ._pejabatKorporatFormMode ==
                                                                            'info' ||
                                                                        this.state
                                                                            ._pejabatKorporatFormModeDisabled
                                                                            .pejabatRw
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    name="pejabatRw"
                                                                    value={
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            .pejabatRw
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Telepon Rumah 1</label>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <input
                                                                    type="number"
                                                                    placeholder="000"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_pejabatKorporatForm'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode ===
                                                                            'info' ||
                                                                        this.state
                                                                            ._pejabatKorporatFormMode ==
                                                                            'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    name="pejabatTeleponKodeArea"
                                                                    value={
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            .pejabatTeleponKodeArea
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <span>-</span>
                                                            </div>
                                                            <div className="col-8">
                                                                <input
                                                                    type="number"
                                                                    placeholder="000"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_pejabatKorporatForm'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode ===
                                                                            'info' ||
                                                                        this.state
                                                                            ._pejabatKorporatFormMode ==
                                                                            'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    name="pejabatTeleponNomor"
                                                                    value={
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            .pejabatTeleponNomor
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>No Handphone</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Masukkan nomor handphone"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ===
                                                                    'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormModeDisabled
                                                                    .pejabatNomorHandphone
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatNomorHandphone"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatNomorHandphone
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Fax</label>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <input
                                                                    type="number"
                                                                    placeholder="000"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_pejabatKorporatForm'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode ===
                                                                            'info' ||
                                                                        this.state
                                                                            ._pejabatKorporatFormMode ===
                                                                            'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    name="pejabatFaxKodeArea"
                                                                    value={
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            .pejabatFaxKodeArea
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <span>-</span>
                                                            </div>
                                                            <div className="col-8">
                                                                <input
                                                                    type="number"
                                                                    placeholder="000"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_pejabatKorporatForm'
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        this.state.mode ===
                                                                            'info' ||
                                                                        this.state
                                                                            ._pejabatKorporatFormMode ===
                                                                            'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    name="pejabatFaxNomor"
                                                                    value={
                                                                        this.state
                                                                            ._pejabatKorporatForm
                                                                            .pejabatFaxNomor
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input
                                                            placeholder="Masukkan alamat email"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ===
                                                                    'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormModeDisabled
                                                                    .pejabatEmailAddress
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatEmailAddress"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatEmailAddress
                                                            }
                                                        />
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label>Jenis Alamat</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ===
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatJenisAlamat"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatJenisAlamat
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Negara Penerbit Pasport</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatNegaraPassport"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatNegaraPassport
                                                            }
                                                        >
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Negara Penerbit Pasport
                                                            </option>
                                                            {this.state._fields.R_KODE_NEGARA &&
                                                            this.state._fields.R_KODE_NEGARA
                                                                .length > 0
                                                                ? this.state._fields.R_KODE_NEGARA.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={
                                                                                      data.reference_code +
                                                                                      ' - ' +
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Status PEP</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatStatusPEP"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatStatusPEP
                                                            }
                                                        />
                                                    </div> 
                                                    <div className="form-group">
                                                        <label>Jenis Kontak</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatTipeKontak"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatTipeKontak
                                                            }
                                                        />
                                                    </div>*/}
                                                </div>
                                                <div className="col-1"></div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Jenis Identitas</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ===
                                                                    'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormModeDisabled
                                                                    .pejabatJenisIdentitas
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatJenisIdentitas"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatJenisIdentitas
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih jenis identitas
                                                            </option>
                                                            {this.state._fields.eJenisIdentitas &&
                                                            this.state._fields.eJenisIdentitas
                                                                .length > 0
                                                                ? this.state._fields.eJenisIdentitas.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Nomor Identitas</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Masukan Nomor Identitas"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ===
                                                                    'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormModeDisabled
                                                                    .pejabatNomorIdentitas
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatNomorIdentitas"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatNomorIdentitas
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Nomor NPWP</label>
                                                        <input
                                                            minLength={15}
                                                            maxLength={15}
                                                            type="text"
                                                            placeholder="Masukan Nomor NPWP"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatNomorNpwp"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatNomorNpwp
                                                            }
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Kewarganegaraan</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatKewarganegaraan"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatKewarganegaraan
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Kewarganegaraan
                                                            </option>
                                                            {this.state._fields.eKewarganegaraan &&
                                                            this.state._fields.eKewarganegaraan
                                                                .length > 0
                                                                ? this.state._fields.eKewarganegaraan.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={
                                                                                      data.reference_code +
                                                                                      ' - ' +
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Persentase Kepemilikan</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Masukkan Persentase Kepemilikan"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatPersentaseKepemilikan"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatPersentaseKepemilikan
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Negara Asal</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatNegaraAsal"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatNegaraAsal
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Negara Asal
                                                            </option>
                                                            {this.state._fields.R_KODE_NEGARA &&
                                                            this.state._fields.R_KODE_NEGARA
                                                                .length > 0
                                                                ? this.state._fields.R_KODE_NEGARA.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={
                                                                                      data.reference_code +
                                                                                      ' - ' +
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Pekerjaan</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatPekerjaan"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatPekerjaan
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Pekerjaan
                                                            </option>
                                                            {this.state._fields.R_PEKERJAAN &&
                                                            this.state._fields.R_PEKERJAAN.length >
                                                                0
                                                                ? this.state._fields.R_PEKERJAAN.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={
                                                                                      data.reference_code +
                                                                                      ' - ' +
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Keterangan</label>
                                                        <textarea
                                                            rows="3"
                                                            placeholder="Bila ada"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatKeterangan"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatKeterangan
                                                            }
                                                        ></textarea>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label>Gelar</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatGelar"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatGelar
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Negara Penerbit Identitas</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatNegaraTerbitIdentitas"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatNegaraTerbitIdentitas
                                                            }
                                                        >
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Negara Penerbit Identitas
                                                            </option>
                                                            {this.state._fields.R_KODE_NEGARA &&
                                                            this.state._fields.R_KODE_NEGARA
                                                                .length > 0
                                                                ? this.state._fields.R_KODE_NEGARA.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={
                                                                                      data.reference_code +
                                                                                      ' - ' +
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Provinsi Penerbit Identitas</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatTempatTerbitIdentitas"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatTempatTerbitIdentitas
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Jenis Kontak Domisili</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatTipeKontakDomisili"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatTipeKontakDomisili
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Nomor Kontak</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_pejabatKorporatForm'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info' ||
                                                                this.state
                                                                    ._pejabatKorporatFormMode ==
                                                                    'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="pejabatExtensionKontakPejabat"
                                                            value={
                                                                this.state._pejabatKorporatForm
                                                                    .pejabatExtensionKontakPejabat
                                                            }
                                                        />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {this.state.mode === 'info' ||
                                        this.state._pejabatKorporatFormMode == 'info' ? (
                                            <button
                                                onClick={() =>
                                                    this.setState({
                                                        showModalTambahPejabat: false,
                                                        _pejabatKorporatFormMode: 'create',
                                                    })
                                                }
                                                className="btn btn-secondary"
                                            >
                                                Tutup
                                            </button>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={() =>
                                                        this.setState({
                                                            showModalTambahPejabat: false,
                                                        })
                                                    }
                                                    className="btn btn-secondary"
                                                >
                                                    Batal
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        this.state._pejabatKorporatFormMode ==
                                                        'create'
                                                            ? this.savePejabat()
                                                            : this.updatePejabat()
                                                    }
                                                >
                                                    Simpan
                                                </button>
                                            </>
                                        )}
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        ) : null}

                        {this.state.step === 2 ? (
                            <div className="bns-form">
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Data Tambahan</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddPenghasilanKotorPerTahun
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Penghasilan Kotor Per Tahun{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                className={`form-control ${
                                                    this.state.errors.cddPenghasilanKotorPerTahun
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddPenghasilanKotorPerTahun"
                                                value={this.state._cdd?.cddPenghasilanKotorPerTahun}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Penghasilan Kotor Per Tahun
                                                </option>
                                                {this.state._fields.R_PENGELUARAN_PERTAHUN &&
                                                this.state._fields.R_PENGELUARAN_PERTAHUN.length > 0
                                                    ? this.state._fields.R_PENGELUARAN_PERTAHUN.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddGolonganNasabah
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Golongan Nasabah{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                className={`form-control ${
                                                    this.state.errors.cddGolonganNasabah
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddGolonganNasabah"
                                                value={this.state._cdd?.cddGolonganNasabah}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Golongan Nasabah (BI)
                                                </option>
                                                {this.state._fields.ANTASENA_GOL_PIHAK_LAWAN &&
                                                this.state._fields.ANTASENA_GOL_PIHAK_LAWAN.length >
                                                    0
                                                    ? this.state._fields.ANTASENA_GOL_PIHAK_LAWAN.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_code} - {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddSumberDana
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Sumber Dana{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                className={`form-control ${
                                                    this.state.errors.cddSumberDana
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddSumberDana"
                                                value={this.state._cdd?.cddSumberDana}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Sumber Dana
                                                </option>
                                                {this.state._fields.R_SUMBER_DANA_CIF_KORPORAT &&
                                                this.state._fields.R_SUMBER_DANA_CIF_KORPORAT
                                                    .length > 0
                                                    ? this.state._fields.R_SUMBER_DANA_CIF_KORPORAT.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_code} -{' '}
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddSumberPenghasilan
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Sumber Penghasilan{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                className={`form-control ${
                                                    this.state.errors.cddSumberPenghasilan
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="cddSumberPenghasilan"
                                                value={this.state._cdd?.cddSumberPenghasilan}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Sumber Dana
                                                </option>
                                                {this.state._fields.R_SUMBER_PENGHASILAN &&
                                                this.state._fields.R_SUMBER_PENGHASILAN.length > 0
                                                    ? this.state._fields.R_SUMBER_PENGHASILAN.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={data.reference_code}
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                            {/* <input
                                                placeholder="Masukkan Sumber Penghasilan"
                                                className={`form-control ${
                                                    this.state.errors.cddSumberPenghasilan
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddSumberPenghasilan"
                                                value={this.state._cdd?.cddSumberPenghasilan}
                                            /> */}
                                            {/* <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div> */}
                                        </div>
                                        <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddTujuanPenggunaanDana
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Tujuan Penggunaan Dana{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                className={`form-control ${
                                                    this.state.errors.cddTujuanPenggunaanDana
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddTujuanPenggunaanDana"
                                                value={this.state._cdd?.cddTujuanPenggunaanDana}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Tujuan Penggunaan Dana
                                                </option>
                                                {this.state._fields.R_TUJUAN_DANA_CIF_KORPORAT &&
                                                this.state._fields.R_TUJUAN_DANA_CIF_KORPORAT
                                                    .length > 0
                                                    ? this.state._fields.R_TUJUAN_DANA_CIF_KORPORAT.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                            {/* <input
                                                placeholder="Masukkan Tujuan Buka Rekening"
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddTujuanBukaRekening"
                                                value={this.state._cdd?.cddTujuanBukaRekening}
                                            /> */}
                                        {/* <div className="form-group">
                                            <label>Tujuan Buka Rekening</label>
                                            <select
                                                className={`form-control`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddTujuanBukaRekening"
                                                value={this.state._cdd?.cddTujuanBukaRekening}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Tujuan Buka Rekening
                                                </option>
                                                {this.state._fields.R_TUJUAN_BUKA_REKENING &&
                                                this.state._fields.R_TUJUAN_BUKA_REKENING
                                                    .length > 0
                                                    ? this.state._fields.R_TUJUAN_BUKA_REKENING.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Status Pajak & SKB Pajak</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className ="row">
                                    <div className="form-group col-md-5">
                                        <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddPenghasilanKotorPerTahun
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Status Kena Pajak{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                className={`form-control ${
                                                    this.state.errors.cddPenghasilanKotorPerTahun
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddPenghasilanKotorPerTahun"
                                                value={this.state._cdd?.cddPenghasilanKotorPerTahun}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Status Kena Pajak
                                                </option>
                                                {this.state._fields.R_PENGELUARAN_PERTAHUN &&
                                                this.state._fields.R_PENGELUARAN_PERTAHUN.length > 0
                                                    ? this.state._fields.R_PENGELUARAN_PERTAHUN.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddPenghasilanKotorPerTahun
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Keterangan Bebas Pajak{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukkan Keterangan Bebas Pajak"
                                                className={`form-control ${
                                                    this.state.errors.cddSumberPenghasilan
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddSumberPenghasilan"
                                                value={this.state._cdd?.cddSumberPenghasilan}
                                            />
                                            {/* <select
                                                className={`form-control ${
                                                    this.state.errors.cddPenghasilanKotorPerTahun
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddPenghasilanKotorPerTahun"
                                                value={this.state._cdd?.cddPenghasilanKotorPerTahun}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Ket Bebas Pajak
                                                </option>
                                                {this.state._fields.R_PENGELUARAN_PERTAHUN &&
                                                this.state._fields.R_PENGELUARAN_PERTAHUN.length > 0
                                                    ? this.state._fields.R_PENGELUARAN_PERTAHUN.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select> */}
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddSumberPenghasilan
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Nomor Surat{' '}
                                                {/* <span className="badge badge-secondary">Wajib</span> */}
                                            </label>
                                            <input
                                                placeholder="Masukkan Nomor Surat"
                                                className={`form-control ${
                                                    this.state.errors.cddSumberPenghasilan
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddSumberPenghasilan"
                                                value={this.state._cdd?.cddSumberPenghasilan}
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="form-group">
                                           <div className="row">
                                           <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Tgl. Terbit</label>
                                                        <input
                                                            placeholder="dd-mm-yyyy"
                                                            className="form-control"
                                                            type="date"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTanggalPengesahanSkdp"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTanggalPengesahanSkdp
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Tgl. Berakhir</label>
                                                        <input
                                                            placeholder="dd-mm-yyyy"
                                                            className="form-control"
                                                            type="date"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_identitasNasabah'
                                                                )
                                                            }
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="nasabahTanggalKadaluarsaSkdp"
                                                            value={
                                                                this.state._identitasNasabah
                                                                    ?.nasabahTanggalKadaluarsaSkdp
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                           </div>
                                        </div>
                                            {/* <input
                                                placeholder="Masukkan Tujuan Buka Rekening"
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddTujuanBukaRekening"
                                                value={this.state._cdd?.cddTujuanBukaRekening}
                                            /> */}
                                        {/* <div className="form-group">
                                            <label>Tujuan Buka Rekening</label>
                                            <select
                                                className={`form-control`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddTujuanBukaRekening"
                                                value={this.state._cdd?.cddTujuanBukaRekening}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Tujuan Buka Rekening
                                                </option>
                                                {this.state._fields.R_TUJUAN_BUKA_REKENING &&
                                                this.state._fields.R_TUJUAN_BUKA_REKENING
                                                    .length > 0
                                                    ? this.state._fields.R_TUJUAN_BUKA_REKENING.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Limit Transaksi</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-5">
                                        <label
                                            className={
                                                this.state.errors.cddLimitNominalSetorTunai
                                                    ? 'text-danger'
                                                    : ''
                                            }
                                        >
                                            Nominal Setor Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    type="number"
                                                    placeholder="Masukkan Nominal Setor Tunai"
                                                    className={`form-control ${
                                                        this.state.errors.cddLimitNominalSetorTunai
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, true, '_cdd')
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="cddLimitNominalSetorTunai"
                                                    value={
                                                        this.state._cdd?.cddLimitNominalSetorTunai
                                                    }
                                                />
                                            </div>
                                            <div className="col-2 mt-1">
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.errors
                                                            .cddLimitFrekuensiSetorTunai
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Frekuensi
                                                </label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    type="number"
                                                    placeholder="0"
                                                    className={`form-control ${
                                                        this.state.errors
                                                            .cddLimitFrekuensiSetorTunai
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, true, '_cdd')
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="cddLimitFrekuensiSetorTunai"
                                                    value={
                                                        this.state._cdd?.cddLimitFrekuensiSetorTunai
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="invalid-feedback">
                                            Bidang ini wajib diisi.
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="form-group col-md-6">
                                        <label
                                            className={
                                                this.state.errors.cddLimitNominalSetorNonTunai
                                                    ? 'text-danger'
                                                    : ''
                                            }
                                        >
                                            Nominal Tarik Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    type="number"
                                                    placeholder="Masukkan Nominal Tarik Tunai"
                                                    className={`form-control ${
                                                        this.state.errors
                                                            .cddLimitNominalSetorNonTunai
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, true, '_cdd')
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="cddLimitNominalSetorNonTunai"
                                                    value={
                                                        this.state._cdd
                                                            ?.cddLimitNominalSetorNonTunai
                                                    }
                                                />
                                            </div>
                                            <div className="col-2 mt-1">
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.errors
                                                            .cddLimitFrekuensiSetorNonTunai
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Frekuensi
                                                </label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    type="number"
                                                    placeholder="0"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    className={`form-control ${
                                                        this.state.errors
                                                            .cddLimitFrekuensiSetorNonTunai
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, true, '_cdd')
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="cddLimitFrekuensiSetorNonTunai"
                                                    value={
                                                        this.state._cdd
                                                            ?.cddLimitFrekuensiSetorNonTunai
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="invalid-feedback">
                                            Bidang ini wajib diisi.
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-5">
                                        <label
                                            className={
                                                this.state.errors.cddLimitNominalSetorTunai
                                                    ? 'text-danger'
                                                    : ''
                                            }
                                        >
                                            Nominal Setor Non Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    type="number"
                                                    placeholder="Masukkan Nominal Setor Non Tunai"
                                                    className={`form-control ${
                                                        this.state.errors.cddLimitNominalSetorTunai
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, true, '_cdd')
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="cddLimitNominalSetorTunai"
                                                    value={
                                                        this.state._cdd?.cddLimitNominalSetorTunai
                                                    }
                                                />
                                            </div>
                                            <div className="col-2 mt-1">
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.errors
                                                            .cddLimitFrekuensiSetorTunai
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Frekuensi
                                                </label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    type="number"
                                                    placeholder="0"
                                                    className={`form-control ${
                                                        this.state.errors
                                                            .cddLimitFrekuensiSetorTunai
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, true, '_cdd')
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="cddLimitFrekuensiSetorTunai"
                                                    value={
                                                        this.state._cdd?.cddLimitFrekuensiSetorTunai
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="invalid-feedback">
                                            Bidang ini wajib diisi.
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="form-group col-md-6">
                                        <label
                                            className={
                                                this.state.errors.cddLimitNominalSetorNonTunai
                                                    ? 'text-danger'
                                                    : ''
                                            }
                                        >
                                            Nominal Tarik Non Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    type="number"
                                                    placeholder="Masukkan Nominal Tarik Non Tunai"
                                                    className={`form-control ${
                                                        this.state.errors
                                                            .cddLimitNominalSetorNonTunai
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, true, '_cdd')
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="cddLimitNominalSetorNonTunai"
                                                    value={
                                                        this.state._cdd
                                                            ?.cddLimitNominalSetorNonTunai
                                                    }
                                                />
                                            </div>
                                            <div className="col-2 mt-1">
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.errors
                                                            .cddLimitFrekuensiSetorNonTunai
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    Frekuensi
                                                </label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    type="number"
                                                    placeholder="0"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    className={`form-control ${
                                                        this.state.errors
                                                            .cddLimitFrekuensiSetorNonTunai
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, true, '_cdd')
                                                    }
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="cddLimitFrekuensiSetorNonTunai"
                                                    value={
                                                        this.state._cdd
                                                            ?.cddLimitFrekuensiSetorNonTunai
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="invalid-feedback">
                                            Bidang ini wajib diisi.
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Faktor Resiko</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="from-group">
                                            <label
                                                className={
                                                    this.state.errors.cddStatusResiko
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Status Resiko{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                readOnly
                                                className={`form-control ${
                                                    this.state.errors.cddStatusResiko
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                name="cddStatusResiko"
                                                value={this.state._cdd?.cddStatusResiko}
                                            />
                                        </div>
                                        {/* <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddFaktorResiko
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Faktor Resiko{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                className={`form-control ${
                                                    this.state.errors.cddFaktorResiko
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_cdd')
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="cddFaktorResiko"
                                                value={this.state._cdd?.cddFaktorResiko}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Faktor Resiko
                                                </option>
                                                {this.state._fields.R_FAKTOR_RESIKO &&
                                                this.state._fields.R_FAKTOR_RESIKO.length > 0
                                                    ? this.state._fields.R_FAKTOR_RESIKO.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.cddStatusResiko
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Status Resiko{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <div className="form-row">
                                                <div className="form-group col-7">
                                                    <input
                                                        readOnly
                                                        className={`form-control ${
                                                            this.state.errors.cddStatusResiko
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) =>
                                                            this.handleInputChange(e, true, '_cdd')
                                                        }
                                                        name="cddStatusResiko"
                                                        value={this.state._cdd?.cddStatusResiko}
                                                    />
                                                </div>
                                                <div className="form-group col-5">
                                                    {this.state.mode !== 'info' ? (
                                                        <button
                                                            onClick={() =>
                                                                this.setState({
                                                                    showModalRBA: true,
                                                                })
                                                            }
                                                            className="btn btn-outline-orange-primary btn-block"
                                                        >
                                                            Isi Quesioner
                                                        </button>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="alert alert-warning">
                                            Status Resiko akan dikalkulasi berdasar input pada
                                            daftar indikator resiko disamping
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-6">
                                        <table className="table table-bordered text-center">
                          <thead className="thead-light">
                            <tr>
                              <th>Item</th>
                              <th>Tingkat Resiko</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Identitas Masalah</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>Lokasi Usaha</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>Profil Nasabah</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>Jumlah Transaksi</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>Kegiatan Usaha</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>Struktur Kepemilikan</td>
                              <td>Low</td>
                            </tr>
                            <tr>
                              <td>Informasi Lainnya</td>
                              <td>Low</td>
                            </tr>
                          </tbody>
                        </table>
                                    </div>
                                </div>
                                <Modal
                                    show={this.state.showModalRBA}
                                    onHide={() => this.setState({ showModalRBA: false })}
                                    backdrop="static"
                                    keyboard={false}
                                    size="xl"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Risk Based Approach (RBA) Form</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="bns-form">
                                            <div
                                                className={`form-group ${
                                                    this.state.rbaErrors.answer1 ? 'has-error' : ''
                                                }`}
                                            >
                                                <label
                                                    className={
                                                        this.state.rbaErrors.answer1
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                    htmlFor=""
                                                >
                                                    <strong>1.</strong> Dokumen pendukung identitas
                                                    Perusahaan/Badan Usaha lengkap sesuai prosedur
                                                    Bank*
                                                </label>
                                                <div className="ml-3">
                                                    <div className="form-check-inline mt-2">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className={`form-check-input ${
                                                                    this.state.rbaErrors.answer1
                                                                        ? 'is-invalid'
                                                                        : ''
                                                                }`}
                                                                value={'1'}
                                                                name="answer1"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer1 == '1'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Ya
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className={`form-check-input ${
                                                                    this.state.rbaErrors.answer1
                                                                        ? 'is-invalid'
                                                                        : ''
                                                                }`}
                                                                value={'0'}
                                                                name="answer1"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer1 == '0'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Tidak
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 fs-12">
                                                        <span className="text-grey">
                                                            Tingkat Resiko :{' '}
                                                        </span>
                                                        <strong>
                                                            {this.state.rba.answer1 == '0'
                                                                ? 'High'
                                                                : 'Low'}
                                                        </strong>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div
                                                className={`form-group ${
                                                    this.state.rbaErrors.answer2 ? 'has-error' : ''
                                                }`}
                                            >
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.rbaErrors.answer2
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    <strong>2.</strong> Perusahaan/Badan Usaha yang
                                                    tidak dimiliki, dikelola atau memiliki hubungan
                                                    dengan PEP
                                                </label>
                                                <div className="ml-3">
                                                    <div className="form-check-inline mt-2">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'1'}
                                                                name="answer2"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer2 == '1'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Ya
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'0'}
                                                                name="answer2"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer2 == '0'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Tidak
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 fs-12">
                                                        <span className="text-grey">
                                                            Tingkat Resiko :{' '}
                                                        </span>
                                                        <strong>
                                                            {this.state.rba.answer2 == '0'
                                                                ? 'High'
                                                                : 'Low'}
                                                        </strong>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div
                                                className={`form-group ${
                                                    this.state.rbaErrors.answer3 ? 'has-error' : ''
                                                }`}
                                            >
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.rbaErrors.answer3
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    <strong>3.</strong> Lokasi usaha Nasabah berada
                                                    di wilayah negara yang bukan merupakan zona
                                                    perdagangan bebas atau wilayah tax haven dan
                                                    dikenal memiliki tingkat good governance yang
                                                    tinggi.
                                                </label>
                                                <div className="ml-3">
                                                    <div className="form-check-inline mt-2">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'1'}
                                                                name="answer3"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer3 == '1'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Ya
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'0'}
                                                                name="answer3"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer3 == '0'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Tidak
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 fs-12">
                                                        <span className="text-grey">
                                                            Tingkat Resiko :{' '}
                                                        </span>
                                                        <strong>
                                                            {this.state.rba.answer3 == '0'
                                                                ? 'High'
                                                                : 'Low'}
                                                        </strong>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div
                                                className={`form-group ${
                                                    this.state.rbaErrors.answer4 ? 'has-error' : ''
                                                }`}
                                            >
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.rbaErrors.answer4
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    <strong>4.</strong> Perusahaan/Badan Usaha dan
                                                    Pihak terkait tidak tercantum daftar terduga
                                                    teroris dan Organisasi Teroris, Daftar Pendanaan
                                                    Proliferasi Senjata Pemusnah Massal atau daftar
                                                    lainnya yang dikeluarkan oleh organisasi
                                                    internasional.{' '}
                                                </label>
                                                <div className="ml-3">
                                                    <div className="form-check-inline mt-2">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'1'}
                                                                name="answer4"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer4 == '1'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Ya
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'0'}
                                                                name="answer4"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer4 == '0'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Tidak
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 fs-12">
                                                        <span className="text-grey">
                                                            Tingkat Resiko :{' '}
                                                        </span>
                                                        <strong>
                                                            {this.state.rba.answer4 == '0'
                                                                ? 'High'
                                                                : 'Low'}
                                                        </strong>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div
                                                className={`form-group ${
                                                    this.state.rbaErrors.answer5 ? 'has-error' : ''
                                                }`}
                                            >
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.rbaErrors.answer5
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    <strong>5.</strong> Perusahaan/Badan Usaha tidak
                                                    termasuk Bidang Usaha Berisiko Tinggi
                                                </label>
                                                <div className="ml-3">
                                                    <div className="form-check-inline mt-2">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'1'}
                                                                name="answer5"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer5 == '1'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Ya
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'0'}
                                                                name="answer5"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer5 == '0'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Tidak
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 fs-12">
                                                        <span className="text-grey">
                                                            Tingkat Resiko :{' '}
                                                        </span>
                                                        <strong>
                                                            {this.state.rba.answer5 == '0'
                                                                ? 'High'
                                                                : 'Low'}
                                                        </strong>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div
                                                className={`form-group ${
                                                    this.state.rbaErrors.answer6 ? 'has-error' : ''
                                                }`}
                                            >
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.rbaErrors.answer6
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    <strong>6.</strong>{' '}
                                                    {
                                                        'Setoran Awal < 25 kali dari omset Perusahaan/Badan Usaha'
                                                    }
                                                </label>
                                                <div className="ml-3">
                                                    <div className="form-check-inline mt-2">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'1'}
                                                                name="answer6"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer6 == '1'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Ya
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'0'}
                                                                name="answer6"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer6 == '0'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Tidak
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 fs-12">
                                                        <span className="text-grey">
                                                            Tingkat Resiko :{' '}
                                                        </span>
                                                        <strong>
                                                            {this.state.rba.answer6 == '0'
                                                                ? 'High'
                                                                : 'Low'}
                                                        </strong>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div
                                                className={`form-group ${
                                                    this.state.rbaErrors.answer7 ? 'has-error' : ''
                                                }`}
                                            >
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.rbaErrors.answer7
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    <strong>7.</strong> Perusahaan/Badan Usaha tidak
                                                    terdapat informasi yang negatif
                                                </label>
                                                <div className="ml-3">
                                                    <div className="form-check-inline mt-2">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'1'}
                                                                name="answer7"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer7 == '1'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Ya
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'0'}
                                                                name="answer7"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer7 == '0'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Tidak
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 fs-12">
                                                        <span className="text-grey">
                                                            Tingkat Resiko :{' '}
                                                        </span>
                                                        <strong>
                                                            {this.state.rba.answer7 == '0'
                                                                ? 'High'
                                                                : 'Low'}
                                                        </strong>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div
                                                className={`form-group ${
                                                    this.state.rbaErrors.answer8 ? 'has-error' : ''
                                                }`}
                                            >
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.rbaErrors.answer8
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    <strong>8.</strong> Perusahaan/Badan Usaha tidak
                                                    menggunakan Produk berkategori tinggi
                                                </label>
                                                <div className="ml-3">
                                                    <div className="form-check-inline mt-2">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'1'}
                                                                name="answer8"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer8 == '1'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Ya
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'0'}
                                                                name="answer8"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer8 == '0'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Tidak
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 fs-12">
                                                        <span className="text-grey">
                                                            Tingkat Resiko :{' '}
                                                        </span>
                                                        <strong>
                                                            {this.state.rba.answer8 == '0'
                                                                ? 'High'
                                                                : 'Low'}
                                                        </strong>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                            <div
                                                className={`form-group ${
                                                    this.state.rbaErrors.answer9 ? 'has-error' : ''
                                                }`}
                                            >
                                                <label
                                                    htmlFor=""
                                                    className={
                                                        this.state.rbaErrors.answer9
                                                            ? 'text-danger'
                                                            : ''
                                                    }
                                                >
                                                    <strong>9.</strong> Perusahaan didirikan
                                                    dan/atau dimiliki oleh badan hukum yang
                                                    berdasarkan hukum di negara-negara tax haven
                                                </label>
                                                <div className="ml-3">
                                                    <div className="form-check-inline mt-2">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'1'}
                                                                name="answer9"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer9 == '1'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Ya
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={'0'}
                                                                name="answer9"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        'rba'
                                                                    )
                                                                }
                                                                checked={
                                                                    this.state.rba.answer9 == '0'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            Tidak
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 fs-12">
                                                        <span className="text-grey">
                                                            Tingkat Resiko :{' '}
                                                        </span>
                                                        <strong>
                                                            {this.state.rba.answer9 == '0'
                                                                ? 'High'
                                                                : 'Low'}
                                                        </strong>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-between">
                                        <div className="row w-100">
                                            <div className="col-6 mt-1">
                                                Status Resiko :{' '}
                                                <strong>
                                                    {this.state.rba.answer1 == '1' &&
                                                    this.state.rba.answer2 == '1' &&
                                                    this.state.rba.answer3 == '1' &&
                                                    this.state.rba.answer4 == '1' &&
                                                    this.state.rba.answer5 == '1' &&
                                                    this.state.rba.answer6 == '1' &&
                                                    this.state.rba.answer7 == '1' &&
                                                    this.state.rba.answer8 == '1' &&
                                                    this.state.rba.answer9 == '1'
                                                        ? 'Low'
                                                        : 'High'}
                                                </strong>
                                            </div>
                                            <div className="col-6 text-right">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => this.saveRBA()}
                                                >
                                                    Simpan Hasil Quesioner
                                                </button>
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        ) : null}

                        {this.state.step === 3 ? (
                            <div>
                                <p className="global-text">Cari data berdasarkan :</p>
                                <div className="row">
                                    <div className="col-2">
                                        <div className="form-group">
                                            <select name="" id="" className="form-control">
                                                <option value="">Jenis Nasabah</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-2">
                                        <div className="form-group">
                                            <select name="" id="" className="form-control">
                                                <option value="">Jenis Produk</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Nomor Nasabah atau Nama"
                                                onChange={(e) => {
                                                    this.setState({
                                                        _relasiNasabahSearch: e.target.value,
                                                    });
                                                }}
                                                value={this.state._relasiNasabahSearch}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <button
                                            className="btn btn-outline-primary"
                                            onClick={() =>
                                                this.setState({ _relasiNasabahSearch: '' })
                                            }
                                        >
                                            Reset
                                        </button>
                                    </div>
                                    <div className="col-3 p0">
                                        <button className="btn btn-primary btn-block">Cari Nasabah</button>
                                    </div>
                                    <div className="col-3">
                                        {this.state.mode !== 'info' ? (
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        showModalTambahRelasi: true,
                                                        _relasiNasabahFormButtonMode: 'create',
                                                        _relasiNasabahFormMode: 'create',
                                                    });
                                                }}
                                                className="btn btn-outline-primary btn-block"
                                            >
                                                Tambah Relasi Nasabah
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                                <p className="text-primary">Relasi Nasabah</p>
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Tipe Relasi Nasabah</th>
                                                    <th>Nomor Nasabah</th>
                                                    <th>Nama</th>
                                                    <th>Jabatan / Pangkat</th>
                                                    <th>Alamat</th>
                                                    <th>Jenis Identitas</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state._relasiNasabah?.map((data, index) => {
                                                    if (
                                                        this.state._relasiNasabahSearch !== '' &&
                                                        data?.relasiNomorNasabah
                                                            .toString()
                                                            .toLowerCase()
                                                            .indexOf(
                                                                this.state._relasiNasabahSearch.toLowerCase()
                                                            ) === -1
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <tr key={`tablePejabatKorporate_${index}`}>
                                                            <td>
                                                                {data.relasiHubunganDenganNasabah}
                                                            </td>
                                                            <td>{data.relasiNomorNasabah}</td>
                                                            <td>{data.relasiNama}</td>
                                                            <td>{data.relasiJabatanPangkat}</td>
                                                            <td>{data.relasiAlamat}</td>
                                                            <td>{data.relasiJenisIdentitas}</td>
                                                            <td>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle
                                                                        variant="light"
                                                                        className="btn-sm"
                                                                        id="dropdown-basic"
                                                                    >
                                                                        <i className="fa fa-ellipsis-h"></i>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                            onClick={() =>
                                                                                this.detailRelasiNasabah(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            Detil Relasi Nasabah
                                                                        </Dropdown.Item>
                                                                        {this.state.mode !==
                                                                            'info' && (
                                                                            <>
                                                                                <Dropdown.Item
                                                                                    onClick={() =>
                                                                                        this.ubahRelasiNasabah(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Ubah Relasi
                                                                                    Nasabah
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item
                                                                                    onClick={() =>
                                                                                        this.removeRelasiNasabah(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    className="text-danger"
                                                                                >
                                                                                    Hapus Relasi
                                                                                    Nasabah
                                                                                </Dropdown.Item>
                                                                            </>
                                                                        )}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <Modal
                                    show={this.state.showModalTambahRelasi}
                                    onHide={() => this.setState({ showModalTambahRelasi: false })}
                                    backdrop="static"
                                    keyboard={false}
                                    size="xl"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {this.state._relasiNasabahFormMode === 'create'
                                                ? 'Tambah'
                                                : this.state._relasiNasabahFormMode === 'update'
                                                ? 'Ubah'
                                                : 'Lihat'}{' '}
                                            Relasi Nasabah
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="bns-form">
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label
                                                        className={`${
                                                            this.state.errors
                                                                .relasiHubunganDenganNasabah
                                                                ? 'text-danger'
                                                                : ''
                                                        }`}
                                                    >
                                                        Hubungan Dengan Nasabah{' '}
                                                        <span className="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <select
                                                        className={`form-control ${
                                                            this.state.errors
                                                                .relasiHubunganDenganNasabah
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_relasiNasabahForm'
                                                            );
                                                            this.setState({
                                                                errors: {
                                                                    ...this.state.errors,
                                                                    relasiHubunganDenganNasabah: false,
                                                                },
                                                            });
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info' ||
                                                            this.state._relasiNasabahFormMode ==
                                                                'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="relasiHubunganDenganNasabah"
                                                        value={
                                                            this.state._relasiNasabahForm
                                                                ?.relasiHubunganDenganNasabah
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Hubungan Dengan Nasabah
                                                        </option>
                                                        {this.state._fields.R_RELASI_NASABAH &&
                                                        this.state._fields.R_RELASI_NASABAH.length >
                                                            0
                                                            ? this.state._fields.R_RELASI_NASABAH.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.reference_code +
                                                                                  ' - ' +
                                                                                  data.reference_desc
                                                                              }
                                                                          >
                                                                              {data.reference_desc}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        {
                                                            this.state.messages
                                                                .relasiHubunganDenganNasabah
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label
                                                        className={`${
                                                            this.state.errors.relasiNomorNasabah
                                                                ? 'text-danger'
                                                                : ''
                                                        }`}
                                                    >
                                                        Jabatan / Pangkat{' '}
                                                        <span className="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <select
                                                        className={`form-control ${
                                                            this.state.errors.relasiJabatanPangkat
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_relasiNasabahForm'
                                                            );
                                                            this.setState({
                                                                errors: {
                                                                    ...this.state.errors,
                                                                    relasiJabatanPangkat: false,
                                                                },
                                                            });
                                                        }}
                                                        disabled={
                                                            this.state.mode === 'info' ||
                                                            this.state._relasiNasabahFormMode ==
                                                                'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="relasiJabatanPangkat"
                                                        value={
                                                            this.state._relasiNasabahForm
                                                                ?.relasiJabatanPangkat
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih jabatan pekerjaan
                                                        </option>
                                                        {this.state._fields.R_JABATAN_KORPORAT &&
                                                        this.state._fields.R_JABATAN_KORPORAT
                                                            .length > 0
                                                            ? this.state._fields.R_JABATAN_KORPORAT.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.reference_code +
                                                                                  ' - ' +
                                                                                  data.reference_desc
                                                                              }
                                                                          >
                                                                              {data.reference_desc}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        {this.state.messages.relasiJabatanPangkat}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4 mb-2">
                                                <div className="col-auto text-primary">
                                                    Data Diri
                                                </div>
                                                <div className="col">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label
                                                            className={`${
                                                                this.state.errors.relasiNomorNasabah
                                                                    ? 'text-danger'
                                                                    : ''
                                                            }`}
                                                        >
                                                            Nomor Nasabah
                                                            <span className="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <input
                                                                    type="text"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    placeholder="Masukkan nomor nasabah"
                                                                    className={`form-control ${
                                                                        this.state.errors
                                                                            .relasiNomorNasabah
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                    }`}
                                                                    onChange={(e) => {
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_relasiNasabahForm'
                                                                        );
                                                                        this.checkNomorCif(
                                                                            '_relasiNasabahForm',
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    name="relasiNomorNasabah"
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiNomorNasabah
                                                                    }
                                                                    readOnly
                                                                />
                                                                <div className="invalid-feedback">
                                                                    {
                                                                        this.state.messages
                                                                            .relasiNomorNasabah
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-1">
                                                                {this.state.mode !== 'info' ||
                                                                this.state
                                                                    ._relasiNasabahFormMode !==
                                                                    'info' ? (
                                                                    <button
                                                                        className="btn btn-outline-secondary"
                                                                        onClick={() =>
                                                                            this.resetCheckNomorCif(
                                                                                '_relasiNasabahForm'
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            this.state.mode ===
                                                                                'info' ||
                                                                            this.state
                                                                                ._relasiNasabahFormMode ==
                                                                                'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        Hapus
                                                                    </button>
                                                                ) : null}
                                                            </div>
                                                            <div className="col-3">
                                                                {this.state.mode !== 'info' ||
                                                                this.state
                                                                    ._relasiNasabahFormMode !==
                                                                    'info' ? (
                                                                    <button
                                                                        className="btn bg-orange-primary text-white btn-block"
                                                                        onClick={() => {
                                                                            // this.checkNomorCif(
                                                                            //     '_relasiNasabahForm',
                                                                            //     this.state
                                                                            //         ._relasiNasabahForm
                                                                            //         .relasiNomorNasabah
                                                                            // )
                                                                            this.setState({
                                                                                showModalSearchNasabah:
                                                                                    '_relasiNasabah',
                                                                                searchNasabahData:
                                                                                    [],
                                                                                searchNasabahType:
                                                                                    'CLIENT',
                                                                            });
                                                                        }}
                                                                        disabled={
                                                                            this.state.mode ===
                                                                                'info' ||
                                                                            this.state
                                                                                ._relasiNasabahFormMode ==
                                                                                'info'
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        Cari Nomor Nasabah
                                                                    </button>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5">
                                                    <div className="form-group">
                                                        <label
                                                            className={`${
                                                                this.state.errors.relasiNama
                                                                    ? 'text-danger'
                                                                    : ''
                                                            }`}
                                                        >
                                                            Nama (Sesuai Identitas){' '}
                                                            {/* <span className="badge badge-secondary">
                                                                Wajib
                                                            </span> */}
                                                        </label>
                                                        <input
                                                            placeholder="Nama nasabah sesuai identitas"
                                                            className={`form-control ${
                                                                this.state.errors.relasiNama
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_relasiNasabahForm'
                                                                )
                                                            }
                                                            disabled
                                                            // disabled={
                                                            //     this.state.mode === 'info' ||
                                                            //     this.state._relasiNasabahFormMode ==
                                                            //         'info'
                                                            //         ? true
                                                            //         : false
                                                            // }
                                                            name="relasiNama"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiNama
                                                            }
                                                        />
                                                        {/* <div className="invalid-feedback">
                                                            Bidang ini wajib diisi.
                                                        </div> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Alamat</label>
                                                        <textarea
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Masukkan alamat"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_relasiNasabahForm'
                                                                )
                                                            }
                                                            disabled
                                                            name="relasiAlamat"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiAlamat
                                                            }
                                                        ></textarea>
                                                    </div>

                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label>Provinsi</label>
                                                            <input
                                                                className={`form-control`}
                                                                disabled
                                                                readOnly
                                                                value={
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiProvinsi
                                                                }
                                                            />
                                                            {/* <select
                                                                placeholder="Masukkan provinsi"
                                                                className="form-control"
                                                                disabled
                                                                name="relasiProvinsi"
                                                                value={
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiProvinsi
                                                                }
                                                            >
                                                                <option
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiProvinsi
                                                                    }
                                                                >
                                                                    {
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiProvinsi
                                                                    }
                                                                </option>
                                                            </select> */}
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label>Kota/Kab</label>
                                                            <input
                                                                className={`form-control`}
                                                                disabled
                                                                readOnly
                                                                value={
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiKotaKabupaten
                                                                }
                                                            />
                                                            {/* <select
                                                                placeholder="Masukkan kota/kabupaten"
                                                                className="form-control"
                                                                disabled
                                                                name="relasiKotaKabupaten"
                                                                value={
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiKotaKabupaten
                                                                }
                                                            >
                                                                <option
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiKotaKabupaten
                                                                    }
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                >
                                                                    {
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiKotaKabupaten
                                                                    }
                                                                </option>
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label>Kecamatan</label>
                                                            <input
                                                                className={`form-control`}
                                                                disabled
                                                                readOnly
                                                                value={
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiKecamatan
                                                                }
                                                            />
                                                            {/* <select
                                                                placeholder="Masukkan kecamatan"
                                                                className="form-control"
                                                                disabled
                                                                name="relasiKecamatan"
                                                                value={
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiKecamatan
                                                                }
                                                            >
                                                                <option
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiKecamatan
                                                                    }
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                >
                                                                    {
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiKecamatan
                                                                    }
                                                                </option>
                                                            </select> */}
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label>Kelurahan</label>
                                                            <input
                                                                className={`form-control`}
                                                                disabled
                                                                readOnly
                                                                value={
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiKelurahan
                                                                }
                                                            />
                                                            {/* <select
                                                                placeholder="Masukkan kelurahan"
                                                                className="form-control"
                                                                disabled
                                                                name="relasiKelurahan"
                                                                value={
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiKelurahan
                                                                }
                                                            >
                                                                <option
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiKelurahan
                                                                    }
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                >
                                                                    {
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiKelurahan
                                                                    }
                                                                </option>
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>RT / RW</label>
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <input
                                                                    type="number"
                                                                    placeholder="Masukkan RT"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_relasiNasabahForm'
                                                                        )
                                                                    }
                                                                    readOnly
                                                                    name="relasiRt"
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiRt
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <span>/</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <input
                                                                    type="number"
                                                                    placeholder="Masukkan RW"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_relasiNasabahForm'
                                                                        )
                                                                    }
                                                                    readOnly
                                                                    name="relasiRw"
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiRw
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label>Telepon Rumah 1</label>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <input
                                                                    type="number"
                                                                    placeholder="000"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_relasiNasabahForm'
                                                                        )
                                                                    }
                                                                    readOnly
                                                                    name="relasiTeleponRumahKodeArea"
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiTeleponRumahKodeArea
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <span>-</span>
                                                            </div>
                                                            <div className="col-8">
                                                                <input
                                                                    type="number"
                                                                    placeholder="000"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_relasiNasabahForm'
                                                                        )
                                                                    }
                                                                    readOnly
                                                                    name="relasiTeleponRumahNomor"
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiTeleponRumahNomor
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label>No Handphone</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Masukkan nomor handphone"
                                                            className="form-control"
                                                            readOnly
                                                            name="relasiNomorHandphone"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiNomorHandphone
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-1"></div>
                                                <div className="col-6">
                                                    {/* <div className="form-group">
                                                        <label>Fax</label>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <input
                                                                    type="number"
                                                                    placeholder="000"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_relasiNasabahForm'
                                                                        )
                                                                    }
                                                                    readOnly
                                                                    name="relasiFaxKodeArea"
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiFaxKodeArea
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <span>-</span>
                                                            </div>
                                                            <div className="col-8">
                                                                <input
                                                                    type="number"
                                                                    placeholder="000"
                                                                    className="form-control"
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !/[0-9]/.test(event.key)
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            true,
                                                                            '_relasiNasabahForm'
                                                                        )
                                                                    }
                                                                    readOnly
                                                                    name="relasiFaxNomor"
                                                                    value={
                                                                        this.state
                                                                            ._relasiNasabahForm
                                                                            ?.relasiFaxNomor
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input
                                                            placeholder="Masukkan alamat email"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_relasiNasabahForm'
                                                                )
                                                            }
                                                            readOnly
                                                            name="relasiEmailAddress"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiEmailAddress
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Jenis Identitas</label>
                                                        <input
                                                            className="form-control"
                                                            readOnly
                                                            name="relasiJenisIdentitas"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiJenisIdentitas
                                                            }
                                                        />
                                                        {/* <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_relasiNasabahForm'
                                                                )
                                                            }
                                                            readOnly
                                                            name="relasiJenisIdentitas"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiJenisIdentitas
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled
                                                            >
                                                                {
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiJenisIdentitas
                                                                }
                                                            </option>
                                                            {this.state._fields.eJenisIdentitas &&
                                                            this.state._fields.eJenisIdentitas
                                                                .length > 0
                                                                ? this.state._fields.eJenisIdentitas.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled
                                                                                  value={
                                                                                      data.reference_code +
                                                                                      ' - ' +
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Nomor Identitas</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Masukan Nomor Identitas"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_relasiNasabahForm'
                                                                )
                                                            }
                                                            readOnly
                                                            name="relasiNomorIdentitas"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiNomorIdentitas
                                                            }
                                                        />
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label>Nomor NPWP</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Masukan Nomor NPWP"
                                                            className="form-control"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_relasiNasabahForm'
                                                                )
                                                            }
                                                            readOnly
                                                            name="relasiNomorNpwp"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiNomorNpwp
                                                            }
                                                        />
                                                    </div> */}
                                                    {/* <div className="form-group">
                                                        <label>Kewarganegaraan</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_relasiNasabahForm'
                                                                )
                                                            }
                                                            readOnly
                                                            name="relasiKewarganegaraan"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiKewarganegaraan
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled
                                                                    this.state._relasiNasabahForm
                                                                        ?.relasiKewarganegaraan
                                                                }
                                                            </option>
                                                            {this.state._fields.eKewarganegaraan &&
                                                            this.state._fields.eKewarganegaraan
                                                                .length > 0
                                                                ? this.state._fields.eKewarganegaraan.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled
                                                                                  value={
                                                                                      data.reference_code +
                                                                                      ' - ' +
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div> */}
                                                    {/* <div className="form-group">
                                                        <label>Negara Asal</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_relasiNasabahForm'
                                                                )
                                                            }
                                                            readOnly
                                                            name="relasiNegaraAsal"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiNegaraAsal
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled
                                                            >
                                                                Pilih Negara Asal
                                                            </option>
                                                            {this.state._fields.R_KODE_NEGARA &&
                                                            this.state._fields.R_KODE_NEGARA
                                                                .length > 0
                                                                ? this.state._fields.R_KODE_NEGARA.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled
                                                                                  value={
                                                                                      data.reference_code +
                                                                                      ' - ' +
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div> */}
                                                    {/* <div className="form-group">
                                                        <label>Pekerjaan</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_relasiNasabahForm'
                                                                )
                                                            }
                                                            readOnly
                                                            name="relasiPekerjaan"
                                                            value={
                                                                this.state._relasiNasabahForm
                                                                    ?.relasiPekerjaan
                                                            }
                                                        >
                                                            <option
                                                                disabled
                                                            >
                                                                Pilih Pekerjaan
                                                            </option>
                                                            {this.state._fields.R_PEKERJAAN &&
                                                            this.state._fields.R_PEKERJAAN.length >
                                                                0
                                                                ? this.state._fields.R_PEKERJAAN.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled
                                                                                  value={
                                                                                      data.reference_code +
                                                                                      ' - ' +
                                                                                      data.reference_desc
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.reference_desc
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {this.state.mode === 'info' ||
                                        this.state._relasiNasabahFormMode === 'info' ? (
                                            <button
                                                onClick={() =>
                                                    this.setState({
                                                        showModalTambahRelasi: false,
                                                        _relasiNasabahFormMode: 'create',
                                                    })
                                                }
                                                className="btn btn-secondary"
                                            >
                                                Tutup
                                            </button>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={() =>
                                                        this.setState({
                                                            showModalTambahRelasi: false,
                                                            _relasiNasabahFormMode: 'create',
                                                        })
                                                    }
                                                    className="btn btn-secondary"
                                                >
                                                    Batal
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        this.state._relasiNasabahFormButtonMode ===
                                                        'create'
                                                            ? this.saveRelasiNasabah()
                                                            : this.updateRelasiNasabah()
                                                    }
                                                >
                                                    Simpan
                                                </button>
                                            </>
                                        )}
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        ) : null}

                        {this.state.step === 4 ? (
                            <div className="bns-form">
                                <div className="row">
                                    <div className="col-5">
                                        <div className="form-group">
                                            <label>Jenis Beneficiary Owner</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        false,
                                                        '_beneficiaryOwnerType'
                                                    )
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="_beneficiaryOwnerType"
                                                value={this.state._beneficiaryOwnerType}
                                            >
                                                <option
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    value="badan_usaha"
                                                >
                                                    Korporat / Badan Usaha
                                                </option>
                                                <option disabled={true} value="perorangan">
                                                    Perorangan
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {this.state._beneficiaryOwnerType === 'badan_usaha' ? (
                                    <>
                                        <div className="row mt-4 mb-2">
                                            <div className="col-auto text-primary">
                                                Data Beneficiary
                                            </div>
                                            <div className="col">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="form-group">
                                                    <label
                                                        className={
                                                            this.state.errors.benefNamaPerusahaan
                                                                ? 'text-danger'
                                                                : ''
                                                        }
                                                    >
                                                        Nama Perusahan{' '}
                                                        {/* <span className="badge badge-secondary">
                                                            Wajib
                                                        </span> */}
                                                    </label>
                                                    <input
                                                        placeholder="Nama Perusahan"
                                                        className={`form-control ${
                                                            this.state.errors.benefNamaPerusahaan
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefNamaPerusahaan"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefNamaPerusahaan ? this.state._beneficiaryOwner
                                                                ?.benefNamaPerusahaan : ""
                                                        }
                                                    />
                                                    {/* <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div> */}
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        className={
                                                            this.state.errors.benefNomorSiupp
                                                                ? 'text-danger'
                                                                : ''
                                                        }
                                                    >
                                                        Nomor Izin Usaha{' '}
                                                        {/* <span className="badge badge-secondary">
                                                            Wajib
                                                        </span> */}
                                                    </label>
                                                    <input
                                                        type="number"
                                                        placeholder="Masukkan Nomor Izin Usaha"
                                                        className={`form-control ${
                                                            this.state.errors.benefNomorSiupp
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefNomorSiupp"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefNomorSiupp ? this.state._beneficiaryOwner
                                                                ?.benefNomorSiupp : ""
                                                        }
                                                    />
                                                    {/* <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div> */}
                                                </div>
                                                <div className="form-group">
                                                    <label>Bidang Usaha</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefBidangUsaha"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefBidangUsaha ? this.state._beneficiaryOwner
                                                                ?.benefBidangUsaha : ""
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Bidang Usaha
                                                        </option>
                                                        {this.state._fields.R_BIDANG_USAHA &&
                                                        this.state._fields.R_BIDANG_USAHA.length > 0
                                                            ? this.state._fields.R_BIDANG_USAHA.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.reference_code +
                                                                                  ' - ' +
                                                                                  data.reference_desc
                                                                              }
                                                                          >
                                                                              {data.reference_desc}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Keterangan</label>
                                                    <textarea
                                                        rows="3"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefKeterangan"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefKeterangan ? this.state._beneficiaryOwner
                                                                ?.benefKeterangan : ""
                                                        }
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Bentuk Usaha</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefBentukUsaha"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefBentukUsaha
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Bentuk Usaha
                                                        </option>
                                                        {this.state._fields
                                                            .R_BADAN_HUKUM_KORPORAT &&
                                                        this.state._fields.R_BADAN_HUKUM_KORPORAT
                                                            .length > 0
                                                            ? this.state._fields.R_BADAN_HUKUM_KORPORAT.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.reference_code +
                                                                                  ' - ' +
                                                                                  data.reference_desc
                                                                              }
                                                                          >
                                                                              {data.reference_desc}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Sumber Dana</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefSumberDana"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefSumberDana
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Sumber Dana
                                                        </option>
                                                        {this.state._fields
                                                            .R_SUMBER_DANA_CIF_KORPORAT &&
                                                        this.state._fields
                                                            .R_SUMBER_DANA_CIF_KORPORAT.length > 0
                                                            ? this.state._fields.R_SUMBER_DANA_CIF_KORPORAT.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.reference_code +
                                                                                  ' - ' +
                                                                                  data.reference_desc
                                                                              }
                                                                          >
                                                                              {data.reference_desc}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Tujuan Penggunaan Dana</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefTujuanPenggunaanDana"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefTujuanPenggunaanDana
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Tujuan Penggunaan Dana
                                                        </option>
                                                        {this.state._fields
                                                            .R_TUJUAN_DANA_CIF_KORPORAT &&
                                                        this.state._fields
                                                            .R_TUJUAN_DANA_CIF_KORPORAT.length > 0
                                                            ? this.state._fields.R_TUJUAN_DANA_CIF_KORPORAT.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.reference_code +
                                                                                  ' - ' +
                                                                                  data.reference_desc
                                                                              }
                                                                          >
                                                                              {data.reference_desc}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-2">
                                            <div className="col-auto text-primary">
                                                Alamat Beneficiary
                                            </div>
                                            <div className="col">
                                                <hr />
                                            </div>
                                            {/* <div className="col-auto"><button className="btn btn-outline-danger btn-sm">Salin dari Alamat Identitas</button></div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="form-group">
                                                    <label>Alamat Domisili</label>
                                                    <textarea
                                                        rows="3"
                                                        className="form-control"
                                                        placeholder="Masukkan alamat"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefAlamat"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefAlamat
                                                        }
                                                    ></textarea>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>Provinsi</label>
                                                        <select
                                                            placeholder="Masukkan provinsi"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_beneficiaryOwner'
                                                                );
                                                                this.getCities(
                                                                    '_beneficiaryOwner',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="benefProvinsi"
                                                            value={
                                                                this.state._beneficiaryOwner
                                                                    ?.benefProvinsi
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Provinsi
                                                            </option>
                                                            {this.state._fields._beneficiaryOwner
                                                                .provinces &&
                                                            this.state._fields._beneficiaryOwner
                                                                .provinces.length > 0
                                                                ? this.state._fields._beneficiaryOwner.provinces.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={
                                                                                      data.province_name
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.province_name
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>Kota/Kab</label>
                                                        <select
                                                            placeholder="Masukkan kota/kabupaten"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_beneficiaryOwner'
                                                                );
                                                                this.getSubDistricts(
                                                                    '_beneficiaryOwner',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="benefKotaKabupaten"
                                                            value={
                                                                this.state._beneficiaryOwner
                                                                    ?.benefKotaKabupaten
                                                            }
                                                        >
                                                            {this.state.mode === 'create' ? (
                                                                <option value="">Pilih Kota</option>
                                                            ) : (
                                                                <option
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={
                                                                        this.state._beneficiaryOwner
                                                                            ?.benefKotaKabupaten
                                                                    }
                                                                >
                                                                    {
                                                                        this.state._beneficiaryOwner
                                                                            ?.benefKotaKabupaten
                                                                    }
                                                                </option>
                                                            )}
                                                            {this.state._fields._beneficiaryOwner
                                                                .cities &&
                                                            this.state._fields._beneficiaryOwner
                                                                .cities.length > 0
                                                                ? this.state._fields._beneficiaryOwner.cities.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={data}
                                                                              >
                                                                                  {data}
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>Kecamatan</label>
                                                        <select
                                                            placeholder="Masukkan kota/kabupaten"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_beneficiaryOwner'
                                                                );
                                                                this.getUrbans(
                                                                    '_beneficiaryOwner',
                                                                    e.target.value,
                                                                    this.state._beneficiaryOwner
                                                                        ?.benefKotaKabupaten
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="benefKecamatan"
                                                            value={
                                                                this.state._beneficiaryOwner
                                                                    ?.benefKecamatan
                                                            }
                                                        >
                                                            {this.state.mode === 'create' ? (
                                                                <option value="">
                                                                    Pilih Kecamatan
                                                                </option>
                                                            ) : (
                                                                <option
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={
                                                                        this.state._beneficiaryOwner
                                                                            ?.benefKecamatan
                                                                    }
                                                                >
                                                                    {
                                                                        this.state._beneficiaryOwner
                                                                            ?.benefKecamatan
                                                                    }
                                                                </option>
                                                            )}

                                                            {this.state._fields._beneficiaryOwner
                                                                .subdistricts &&
                                                            this.state._fields._beneficiaryOwner
                                                                .subdistricts.length > 0
                                                                ? this.state._fields._beneficiaryOwner.subdistricts.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={data}
                                                                              >
                                                                                  {data}
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>Kelurahan</label>
                                                        <select
                                                            placeholder="Masukkan kota/kabupaten"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_beneficiaryOwner'
                                                                );
                                                                this.getPostalsCode(
                                                                    '_beneficiaryOwner',
                                                                    this.state._beneficiaryOwner
                                                                        .benefKotaKabupaten,
                                                                    this.state._beneficiaryOwner
                                                                        .benefKecamatan,
                                                                    e.target.value
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="benefKelurahan"
                                                            value={
                                                                this.state._beneficiaryOwner
                                                                    ?.benefKelurahan
                                                            }
                                                        >
                                                            {this.state.mode === 'create' ? (
                                                                <option value="">
                                                                    Pilih Kelurahan
                                                                </option>
                                                            ) : (
                                                                <option
                                                                    disabled={
                                                                        this.state.mode === 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={
                                                                        this.state._beneficiaryOwner
                                                                            ?.benefKelurahan
                                                                    }
                                                                >
                                                                    {
                                                                        this.state._beneficiaryOwner
                                                                            ?.benefKelurahan
                                                                    }
                                                                </option>
                                                            )}
                                                            {this.state._fields._beneficiaryOwner
                                                                .urbans &&
                                                            this.state._fields._beneficiaryOwner
                                                                .urbans.length > 0
                                                                ? this.state._fields._beneficiaryOwner.urbans.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={data}
                                                                              >
                                                                                  {data}
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>RT / RW</label>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <input
                                                                type="number"
                                                                placeholder="Masukkan RT"
                                                                className="form-control"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        '_beneficiaryOwner'
                                                                    )
                                                                }
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                name="benefRt"
                                                                value={
                                                                    this.state._beneficiaryOwner
                                                                        ?.benefRt
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>/</span>
                                                        </div>
                                                        <div className="col-6">
                                                            <input
                                                                type="number"
                                                                placeholder="Masukkan RW"
                                                                className="form-control"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        '_beneficiaryOwner'
                                                                    )
                                                                }
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                name="benefRw"
                                                                value={
                                                                    this.state._beneficiaryOwner
                                                                        ?.benefRw
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Kode Pos</label>
                                                    <select
                                                        placeholder="Masukkan Kode Pos"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefKodePos"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefKodePos
                                                        }
                                                    >
                                                        {this.state.mode === 'create' ? (
                                                            <option value="">Pilih Kode Pos</option>
                                                        ) : (
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    this.state._beneficiaryOwner
                                                                        ?.benefKodePos
                                                                }
                                                            >
                                                                {
                                                                    this.state._beneficiaryOwner
                                                                        ?.benefKodePos
                                                                }
                                                            </option>
                                                        )}
                                                        {this.state._fields._beneficiaryOwner
                                                            .postalscode &&
                                                        this.state._fields._beneficiaryOwner
                                                            .postalscode.length > 0
                                                            ? this.state._fields._beneficiaryOwner.postalscode.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option value={data}>
                                                                              {data}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row mt-4 mb-2">
                                            <div className="col-auto text-primary">
                                                Data Beneficiary
                                            </div>
                                            <div className="col">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="form-group">
                                                    <label>Nama Lengkap</label>
                                                    <input
                                                        placeholder="Nama lengkap nasabah"
                                                        className="form-control"
                                                        value=""
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Tempat / Tanggal Lahir</label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <input
                                                                placeholder="Tempat Lahir"
                                                                className="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>/</span>
                                                        </div>
                                                        <div className="col-5">
                                                            <input
                                                                placeholder="Tanggal Lahir"
                                                                className="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Jenis Kelamin</label>
                                                    <br />
                                                    <div className="form-check-inline">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                name="optradio"
                                                            />
                                                            Laki-laki
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline ml-3">
                                                        <label className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                name="optradio"
                                                            />
                                                            Perempuan
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Agama</label>
                                                    <select
                                                        aria-disabled="false"
                                                        className="form-control"
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Agama
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Status Perkawinan</label>
                                                    <select
                                                        aria-disabled="false"
                                                        className="form-control"
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Status Perkawinan
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Telepon</label>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <input
                                                                placeholder="000"
                                                                className="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>-</span>
                                                        </div>
                                                        <div className="col-8">
                                                            <input
                                                                placeholder="000"
                                                                className="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>No Handphone</label>
                                                    <input
                                                        placeholder="Masukkan nomor handphone"
                                                        className="form-control"
                                                        value=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Alamat Identitas</label>
                                                    <textarea
                                                        rows="3"
                                                        className="form-control"
                                                        placeholder="Masukkan alamat"
                                                    ></textarea>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>
                                                            Provinsi{' '}
                                                            <span className="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan provinsi"
                                                            className="form-control"
                                                            value=""
                                                        />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>
                                                            Kota/Kab{' '}
                                                            <span className="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan kota/kabupaten"
                                                            className="form-control"
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>
                                                            Kecamatan{' '}
                                                            <span className="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan Kecamatan"
                                                            className="form-control"
                                                            value=""
                                                        />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>
                                                            Kelurahan{' '}
                                                            <span className="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan Kelurahan"
                                                            className="form-control"
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>RT / RW</label>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <input
                                                                placeholder="Masukkan RT"
                                                                className="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>/</span>
                                                        </div>
                                                        <div className="col-6">
                                                            <input
                                                                placeholder="Masukkan RW"
                                                                className="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Kode Pos</label>
                                                    <input
                                                        placeholder="Masukkan Kode Pos"
                                                        className="form-control"
                                                        value=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-2">
                                            <div className="col-auto text-primary">
                                                Alamat Domisili Beneficiary
                                            </div>
                                            <div className="col">
                                                <hr />
                                            </div>
                                            {/* <div className="col-auto">
                                                <button className="btn btn-outline-danger btn-sm">
                                                    Salin dari Alamat Identitas
                                                </button>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="form-group">
                                                    <label>Alamat Domisili</label>
                                                    <textarea
                                                        rows="3"
                                                        className="form-control"
                                                        placeholder="Masukkan alamat"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefAlamat"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefAlamat
                                                        }
                                                    ></textarea>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>Provinsi</label>
                                                        <select
                                                            placeholder="Masukkan provinsi"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_beneficiaryOwner'
                                                                );
                                                                this.getCities(
                                                                    '_beneficiaryOwner',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="benefProvinsi"
                                                            value={
                                                                this.state._beneficiaryOwner
                                                                    ?.benefProvinsi
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Provinsi
                                                            </option>
                                                            {this.state._fields._beneficiaryOwner
                                                                .provinces &&
                                                            this.state._fields._beneficiaryOwner
                                                                .provinces.length > 0
                                                                ? this.state._fields._beneficiaryOwner.provinces.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={
                                                                                      data.province_name
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      data.province_name
                                                                                  }
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>Kota/Kab</label>
                                                        <select
                                                            placeholder="Masukkan kota/kabupaten"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_beneficiaryOwner'
                                                                );
                                                                this.getSubDistricts(
                                                                    '_beneficiaryOwner',
                                                                    e.target.value
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="benefKotaKabupaten"
                                                            value={
                                                                this.state._beneficiaryOwner
                                                                    ?.benefKotaKabupaten
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Kota
                                                            </option>
                                                            {this.state._fields._beneficiaryOwner
                                                                .cities &&
                                                            this.state._fields._beneficiaryOwner
                                                                .cities.length > 0
                                                                ? this.state._fields._beneficiaryOwner.cities.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={data}
                                                                              >
                                                                                  {data}
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>Kecamatan</label>
                                                        <select
                                                            placeholder="Masukkan kota/kabupaten"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_beneficiaryOwner'
                                                                );
                                                                this.getUrbans(
                                                                    '_beneficiaryOwner',
                                                                    e.target.value,
                                                                    this.state._beneficiaryOwner
                                                                        .benefKotaKabupaten
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="benefKecamatan"
                                                            value={
                                                                this.state._beneficiaryOwner
                                                                    ?.benefKecamatan
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Kecamatan
                                                            </option>
                                                            {this.state._fields._beneficiaryOwner
                                                                .subdistricts &&
                                                            this.state._fields._beneficiaryOwner
                                                                .subdistricts.length > 0
                                                                ? this.state._fields._beneficiaryOwner.subdistricts.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={data}
                                                                              >
                                                                                  {data}
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>Kelurahan</label>
                                                        <select
                                                            placeholder="Masukkan kota/kabupaten"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_beneficiaryOwner'
                                                                );
                                                                this.getPostalsCode(
                                                                    '_beneficiaryOwner',
                                                                    this.state._beneficiaryOwner
                                                                        .benefKotaKabupaten,
                                                                    this.state._beneficiaryOwner
                                                                        .benefKecamatan,
                                                                    e.target.value
                                                                );
                                                            }}
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="benefKelurahan"
                                                            value={
                                                                this.state._beneficiaryOwner
                                                                    ?.benefKelurahan
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                Pilih Kelurahan
                                                            </option>
                                                            {this.state._fields._beneficiaryOwner
                                                                .urbans &&
                                                            this.state._fields._beneficiaryOwner
                                                                .urbans.length > 0
                                                                ? this.state._fields._beneficiaryOwner.urbans.map(
                                                                      (data, index) => {
                                                                          return (
                                                                              <option
                                                                                  disabled={
                                                                                      this.state
                                                                                          .mode ==
                                                                                      'info'
                                                                                          ? true
                                                                                          : false
                                                                                  }
                                                                                  value={data}
                                                                              >
                                                                                  {data}
                                                                              </option>
                                                                          );
                                                                      }
                                                                  )
                                                                : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>RT / RW</label>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <input
                                                                placeholder="Masukkan RT"
                                                                className="form-control"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        '_beneficiaryOwner'
                                                                    )
                                                                }
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                name="benefRt"
                                                                value={
                                                                    this.state._beneficiaryOwner
                                                                        ?.benefRt
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>/</span>
                                                        </div>
                                                        <div className="col-6">
                                                            <input
                                                                placeholder="Masukkan RW"
                                                                className="form-control"
                                                                onChange={(e) =>
                                                                    this.handleInputChange(
                                                                        e,
                                                                        true,
                                                                        '_beneficiaryOwner'
                                                                    )
                                                                }
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                name="benefRw"
                                                                value={
                                                                    this.state._beneficiaryOwner
                                                                        ?.benefRw
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Kode Pos</label>
                                                    <select
                                                        placeholder="Masukkan Kode Pos"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="nasabahKodePos"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefKodePos
                                                        }
                                                    >
                                                        {this.state.mode === 'create' ? (
                                                            <option value="">Pilih Kode Pos</option>
                                                        ) : (
                                                            <option
                                                                disabled={
                                                                    this.state.mode === 'info'
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    this.state._beneficiaryOwner
                                                                        ?.benefKodePos
                                                                }
                                                            >
                                                                {
                                                                    this.state._beneficiaryOwner
                                                                        ?.benefKodePos
                                                                }
                                                            </option>
                                                        )}
                                                        {this.state._fields._beneficiaryOwner
                                                            .postalscode &&
                                                        this.state._fields._beneficiaryOwner
                                                            .postalscode.length > 0
                                                            ? this.state._fields._beneficiaryOwner.postalscode.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={data}
                                                                          >
                                                                              {data}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Penghasilan</label>
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <input
                                                                placeholder="Masukkan nominal penghasilan"
                                                                className="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                        <div className="col-4">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Sumber Dana</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefSumberDana"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefSumberDana
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Sumber Dana
                                                        </option>
                                                        {this.state._fields
                                                            .R_SUMBER_DANA_CIF_KORPORAT &&
                                                        this.state._fields
                                                            .R_SUMBER_DANA_CIF_KORPORAT.length > 0
                                                            ? this.state._fields.R_SUMBER_DANA_CIF_KORPORAT.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.reference_code +
                                                                                  ' - ' +
                                                                                  data.reference_desc
                                                                              }
                                                                          >
                                                                              {data.reference_desc}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Tujuan Penggunaan Dana</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_beneficiaryOwner'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="benefTujuanPenggunaanDana"
                                                        value={
                                                            this.state._beneficiaryOwner
                                                                ?.benefTujuanPenggunaanDana
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            Pilih Tujuan Penggunaan Dana
                                                        </option>
                                                        {this.state._fields
                                                            .R_TUJUAN_DANA_CIF_KORPORAT &&
                                                        this.state._fields
                                                            .R_TUJUAN_DANA_CIF_KORPORAT.length > 0
                                                            ? this.state._fields.R_TUJUAN_DANA_CIF_KORPORAT.map(
                                                                  (data, index) => {
                                                                      return (
                                                                          <option
                                                                              disabled={
                                                                                  this.state
                                                                                      .mode ===
                                                                                  'info'
                                                                                      ? true
                                                                                      : false
                                                                              }
                                                                              value={
                                                                                  data.reference_code +
                                                                                  ' - ' +
                                                                                  data.reference_desc
                                                                              }
                                                                          >
                                                                              {data.reference_desc}
                                                                          </option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : null}

                        {this.state.step === 5 ? (
                            <div className="bns-form">
                                <h3 className="text-primary">Data Laporan</h3>
                                <div
                                    className="card p-4 pointer"
                                    onClick={() =>
                                        this.setState({
                                            showModalLaporanAntasena: true,
                                            laporan: 'antasena',
                                        })
                                    }
                                >
                                    <div className="row">
                                        <p className="col-6">Laporan ANTASENA</p>
                                        <div className="col-6 text-right">
                                            <input
                                                type="radio"
                                                checked={
                                                    this.state.laporan === 'antasena' ? true : false
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="card p-4 pointer"
                                    onClick={() =>
                                        this.setState({ showModalLaporanAML: true, laporan: 'aml' })
                                    }
                                >
                                    <div className="row">
                                        <p className="col-6">Laporan AML</p>
                                        <div className="col-6 text-right">
                                            <input
                                                type="radio"
                                                checked={
                                                    this.state.laporan === 'aml' ? true : false
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-2">
                                {this.state.mode !== 'info' ? (
                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={() => this.resetStep()}
                                    >
                                        Reset
                                    </button>
                                ) : null}
                            </div>
                            <div className="col-10 text-right">
                                <button
                                    disabled={this.state.step === 0 ? true : false}
                                    className={
                                        'btn mr-1 ' +
                                        (this.state.step === 0
                                            ? 'btn-secondary'
                                            : 'btn-orange-primary')
                                    }
                                    onClick={() => this.setState({ step: this.state.step - 1 })}
                                >
                                    Sebelumnya
                                </button>
                                <button
                                    disabled={this.state.step === 4 ? true : false}
                                    className={
                                        'btn mr-1 ' +
                                        (this.state.step === 4
                                            ? 'btn-secondary'
                                            : 'btn-orange-primary')
                                    }
                                    onClick={() => this.setState({ step: this.state.step + 1 })}
                                >
                                    Selanjutnya
                                </button>
                                {this.state.mode !== 'info' ? (
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => this.saveStep()}
                                    >
                                        Simpan
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.showModalConfirm}
                    onHide={() => this.setState({ showModalConfirm: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Lakukan proses {this.state.mode === 'create' ? 'Registrasi' : 'Ubah'}{' '}
                        Nasabah / CIF - Badan Usaha?
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirm: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.state.mode === 'create' ? this.postGroup() : this.putGroup();
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSuccess}
                    onHide={() => this.setState({ showModalSuccess: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Selesai</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Proses {this.state.mode === 'create' ? 'Registrasi' : 'Ubah'} Nasabah / CIF
                        - Badan Usaha berhasil
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalSuccess: false });
                                this.props.history.push('/data_nasabah/cif');
                            }}
                        >
                            Selesai
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalError}
                    onHide={() => this.setState({ showModalError: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageError}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalError: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalWarning}
                    onHide={() => this.setState({ showModalWarning: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageWarning}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalWarning: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalLaporanAntasena}
                    onHide={() => this.setState({ showModalLaporanAntasena: false })}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Laporan ANTASENA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-row">
                            {/* <div className="form-group col-6">
                                <label>Hubungan Pelapor</label>
                                <select className="form-control">
                                    <option>Pilih Hubungan pelapor</option>
                                </select>
                            </div>
                            <div className="form-group col-6">
                                <label>Golongan Pihak Lawan</label>
                                <select className="form-control">
                                    <option>Pilih Golongan Pihak Lawan</option>
                                </select>
                            </div> */}
                            <div className="form-group col-6">
                                <label>Lembaga Pemeringkat/Rating</label>
                                <select
                                    className="form-control"
                                    onChange={(e) => {
                                        this.handleInputChange(e, true, '_pihakLawan');
                                        this.getRatingPihakLawan(e.target.value);
                                    }}
                                    disabled={this.state.mode === 'info' ? true : false}
                                    name="pihakLawanLembagaPemeringkatan"
                                    value={this.state._pihakLawan?.pihakLawanLembagaPemeringkatan}
                                >
                                    <option disabled={this.state.mode === 'info' ? true : false}>
                                        Pilih Lembaga Pemeringkat/Rating
                                    </option>
                                    {this.state._fields.ANTASENA_LEMBAGA_PEMERINGKATAN &&
                                    this.state._fields.ANTASENA_LEMBAGA_PEMERINGKATAN.length > 0
                                        ? this.state._fields.ANTASENA_LEMBAGA_PEMERINGKATAN.map(
                                              (data, index) => {
                                                  return (
                                                      <option
                                                          disabled={
                                                              this.state.mode === 'info'
                                                                  ? true
                                                                  : false
                                                          }
                                                          value={
                                                              data.reference_code +
                                                              ' - ' +
                                                              data.reference_desc
                                                          }
                                                      >
                                                          {data.reference_desc}
                                                      </option>
                                                  );
                                              }
                                          )
                                        : null}
                                </select>
                            </div>
                            <div className="form-group col-6">
                                <label>Peringkat/Rating Pihak Lawan</label>
                                <select
                                    className="form-control"
                                    onChange={(e) => this.handleInputChange(e, true, '_pihakLawan')}
                                    disabled={this.state.mode === 'info' ? true : false}
                                    name="pihakLawanRating"
                                    value={this.state._pihakLawan?.pihakLawanRating}
                                >
                                    <option disabled={this.state.mode === 'info' ? true : false}>
                                        Pilih Peringkat/Rating Pihak Lawan
                                    </option>
                                    {this.state._fields.RATING_PIHAK_LAWAN &&
                                    this.state._fields.RATING_PIHAK_LAWAN.length > 0
                                        ? this.state._fields.RATING_PIHAK_LAWAN.map(
                                              (data, index) => {
                                                  return (
                                                      <option
                                                          disabled={
                                                              this.state.mode === 'info'
                                                                  ? true
                                                                  : false
                                                          }
                                                          value={
                                                              data.reference_code +
                                                              ' - ' +
                                                              data.reference_desc
                                                          }
                                                      >
                                                          {data.reference_desc}
                                                      </option>
                                                  );
                                              }
                                          )
                                        : null}
                                </select>
                            </div>
                            <div className="form-group col-6">
                                <label>Tanggal Lembaga Pemeringkat/Rating</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => this.handleInputChange(e, true, '_pihakLawan')}
                                    disabled={this.state.mode === 'info' ? true : false}
                                    name="pihakLawanTanggalPemeringkatan"
                                    value={this.state._pihakLawan?.pihakLawanTanggalPemeringkatan}
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>ID Grup</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.handleInputChange(e, true, '_pihakLawan')}
                                    disabled={this.state.mode === 'info' ? true : false}
                                    name="pihakLawanIdGrup"
                                    value={this.state._pihakLawan?.pihakLawanIdGrup}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalLaporanAntasena: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalLaporanAML}
                    onHide={() => this.setState({ showModalLaporanAML: false })}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Laporan AML</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className="text-primary">Data Perusahaan</h3>
                        <div className="bns-form">
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label>Jenis Nasabah/CIF</label>
                                    <select className="form-control" readOnly>
                                        <option value="group">Badan Usaha</option>
                                    </select>
                                </div>
                                <div className="form-group col-6">
                                    <label>Alamat Domisili</label>
                                    <textarea
                                        className="form-control"
                                        value={this.state._identitasNasabah?.nasabahAlamat}
                                        readOnly
                                    ></textarea>
                                </div>
                                <div className="form-group col-6">
                                    <label>Status Nasabah/CIF</label>
                                    <select
                                        readOnly
                                        className="form-control"
                                        value={
                                            this.state._identitasNasabah?.nasabahStatusKeterkaitan
                                        }
                                    >
                                        <option>
                                            {this.state._identitasNasabah?.nasabahStatusKeterkaitan}
                                        </option>
                                    </select>
                                </div>
                                <div className="form-group col-6">
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>Provinsi</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                value={
                                                    this.state._identitasNasabah?.nasabahProvinsi
                                                }
                                            >
                                                <option>
                                                    {this.state._identitasNasabah?.nasabahProvinsi}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-6">
                                            <label>Kota/Kab</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                value={
                                                    this.state._identitasNasabah
                                                        ?.nasabahKotaKabupaten
                                                }
                                            >
                                                <option>
                                                    {
                                                        this.state._identitasNasabah
                                                            ?.nasabahKotaKabupaten
                                                    }
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <label>Bidang Usaha</label>
                                    <select
                                        className="form-control"
                                        readOnly
                                        value={this.state._identitasNasabah?.nasabahBidangUsaha}
                                    >
                                        <option>
                                            {this.state._identitasNasabah?.nasabahBidangUsaha}
                                        </option>
                                    </select>
                                </div>
                                <div className="form-group col-6">
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>Kecamatan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                value={
                                                    this.state._identitasNasabah?.nasabahKecamatan
                                                }
                                            >
                                                <option>
                                                    {this.state._identitasNasabah?.nasabahKecamatan}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-6">
                                            <label>Kelurahan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                value={
                                                    this.state._identitasNasabah?.nasabahKelurahan
                                                }
                                            >
                                                <option>
                                                    {this.state._identitasNasabah?.nasabahKelurahan}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>Tempat diterbitkan SIUPP</label>
                                            <input
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_identitasNasabah'
                                                    )
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="nasabahTempatPembuatanSiupp"
                                                value={
                                                    this.state._identitasNasabah
                                                        ?.nasabahTempatPembuatanSiupp
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-6">
                                            <label>Tgl. Kadaluarsa Akte SIUPP</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_identitasNasabah'
                                                    )
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="nasabahTanggalKadaluarsaSiupp"
                                                value={
                                                    this.state._identitasNasabah
                                                        ?.nasabahTanggalKadaluarsaSiupp
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>RT</label>
                                            <input
                                                className="form-control"
                                                readOnly
                                                value={this.state._identitasNasabah?.nasabahRt}
                                            />
                                        </div>
                                        <div className="form-group col-6">
                                            <label>RW</label>
                                            <input
                                                className="form-control"
                                                readOnly
                                                value={this.state._identitasNasabah?.nasabahRw}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>Tempat diterbitkan SKDP</label>
                                            <input
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_identitasNasabah'
                                                    )
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="nasabahTempatPembuatanSkdp"
                                                value={
                                                    this.state._identitasNasabah
                                                        ?.nasabahTempatPembuatanSkdp
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-6">
                                            <label>Tgl. Kadaluarsa Akte SKDP</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                readOnly
                                                name="nasabahTanggalKadaluarsaSkdp"
                                                value={
                                                    this.state._identitasNasabah
                                                        ?.nasabahTanggalKadaluarsaSkdp
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6"></div>
                                <div className="form-group col-6">
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>Tempat diterbitkan Akte Perubahan</label>
                                            <input
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_identitasNasabah'
                                                    )
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="nasabahTempatAktePerubahan"
                                                value={
                                                    this.state._identitasNasabah
                                                        ?.nasabahTempatAktePerubahan
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-6">
                                            <label>Tgl. Kadaluarsa Akte Perubahan</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_identitasNasabah'
                                                    )
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="nasabahTanggalExpAktePerubahan"
                                                value={
                                                    this.state._identitasNasabah
                                                        ?.nasabahTanggalExpAktePerubahan
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6"></div>
                                <div className="form-group col-6">
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>Tempat diterbitkan SK MENKUMHAM</label>
                                            <input
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_identitasNasabah'
                                                    )
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="nasabahTempatSkMenkumham"
                                                value={
                                                    this.state._identitasNasabah
                                                        ?.nasabahTempatSkMenkumham
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-6">
                                            <label>Tgl. Kadaluarsa SK MENKUMHAM</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_identitasNasabah'
                                                    )
                                                }
                                                disabled={this.state.mode === 'info' ? true : false}
                                                name="nasabahTanggalExpSkMenkumham"
                                                value={
                                                    this.state._identitasNasabah
                                                        ?.nasabahTanggalExpSkMenkumham
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6"></div>
                            </div>
                        </div>
                        {/* <h3 className="text-primary">Data Pejabat Korporat</h3> */}
                        {/* <div className="bns-form">
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label>Gelar</label>
                                    <select className="form-control">
                                        <option>Pilih Gelar</option>
                                    </select>
                                </div>
                                <div className="form-group col-6">
                                    <label>Nomor Identitas Lainnya</label>
                                    <input className="form-control" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label>Nomor Passport</label>
                                    <input className="form-control" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Jenis Alamat</label>
                                    <select className="form-control">
                                        <option>Pilih jenis</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label>Negara Penerbit Pasport</label>
                                    <select className="form-control">
                                        <option>Pilih Negara Penerbit Pasport</option>
                                    </select>
                                </div>
                                <div className="form-group col-6">
                                    <label>Negara Penerbit Identitas</label>
                                    <select className="form-control">
                                        <option>Pilih Negara Penerbit Identitas</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label>Nomor Pajak</label>
                                    <input className="form-control" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Provinsi Penerbit Identitas</label>
                                    <select className="form-control">
                                        <option>Pilih Provinsi Penerbit Identitas</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label>Status PEP</label>
                                    <select className="form-control">
                                        <option>Pilih Status PEP</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label>Jenis Kontak Domisili</label>
                                    <select className="form-control">
                                        <option>Pilih Jenis Kontak Domisili</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Jenis Kontak</label>
                                    <select className="form-control">
                                        <option>Pilih Jenis Kontak</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Nomor Kontak</label>
                                    <input className="form-control" />
                                </div>
                            </div>
                        </div> */}
                        {/* <h3 className="text-primary">Kontak</h3>
                        <div className="bns-form">
                            <div className="col-6">
                                <div className="form-group">
                                    <label>Jenis Kontak Domisili</label>
                                    <select className="form-control">
                                        <option>Pilih Jenis Kontak Domisili</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Jenis Kontak</label>
                                    <select className="form-control">
                                        <option>Pilih Jenis Kontak</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Nomor Kontak</label>
                                    <input className="form-control" />
                                </div>
                            </div>
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalLaporanAML: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={
                        this.state.showModalSearchNasabah === '_pejabatKorporat' ||
                        this.state.showModalSearchNasabah === '_relasiNasabah'
                    }
                    onHide={() => this.setState({ showModalSearchNasabah: '' })}
                    centered
                    size={'xl'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Cari data nasabah</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bns-form">
                            <label>Cari berdasarkan:</label>
                            <div className="form-row">
                                {this.state.showModalSearchNasabah === '_relasiNasabah' && (
                                    <div className="form-group col">
                                        <select
                                            className="form-control"
                                            value={this.state.searchNasabahType}
                                            onChange={(e) =>
                                                this.setState({ searchNasabahType: e.target.value })
                                            }
                                        >
                                            <option value="CLIENT">Perorangan</option>
                                            <option value="GROUP">Badan Usaha</option>
                                        </select>
                                    </div>
                                )}
                                <div className="form-group col">
                                    <select
                                        className="form-control"
                                        value={this.state.searchNasabahSearchBy}
                                        onChange={(e) =>
                                            this.setState({ searchNasabahSearchBy: e.target.value })
                                        }
                                    >
                                        <option value="name">Nama Nasabah</option>
                                        <option value="CIF">Nomor CIF</option>
                                    </select>
                                </div>
                                <div className="form-group col">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="kata kunci..."
                                        value={this.state.searchNasabahSearch}
                                        onChange={(e) =>
                                            this.setState({ searchNasabahSearch: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="form-group col">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.searchNasabah()}
                                    >
                                        Cari
                                    </button>
                                </div>
                            </div>
                        </div>
                        <table className="table table-bordered table-stripped">
                            <thead>
                                <th>Nomor CIF</th>
                                <th>Nama</th>
                                <th>Alamat</th>
                                <th>#</th>
                            </thead>
                            <tbody>
                                {this.state.searchNasabahData.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{item?.nomor_cif}</td>
                                            <td>{item?.nama}</td>
                                            <td>{item?.alamat}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="light"
                                                        className="btn-sm"
                                                        id="dropdown-basic"
                                                    >
                                                        <i className="fa fa-ellipsis-h"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                this.state.searchNasabahType ===
                                                                'CLIENT'
                                                                    ? this.checkNomorCif(
                                                                          this.state
                                                                              .showModalSearchNasabah ===
                                                                              '_pejabatKorporat'
                                                                              ? '_pejabatKorporatForm'
                                                                              : '_relasiNasabahForm',
                                                                          item?.nomor_cif
                                                                      )
                                                                    : this.checkNomorCifGroup(
                                                                          '_relasiNasabahForm',
                                                                          item?.nomor_cif
                                                                      );
                                                                this.setState({
                                                                    showModalSearchNasabah: false,
                                                                });
                                                            }}
                                                        >
                                                            Pilih Nasabah
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalSearchNasabah: false })}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalConfirmHapus}
                    onHide={() => this.setState({ showModalConfirmHapus: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Apakah Anda yakin akan menghapus data ini?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirmHapus: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                if (this.state.indexType == 'pejabat') {
                                    this.removePejabat(this.state.indexHapus);
                                } else {
                                    this.removeRelasiNasabah(this.state.indexHapus);
                                }
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withRouter(RegistrasiBadanUsaha);
