import React from "react";
import { Modal } from "react-bootstrap";
import useSVS from "../useSvs";
import {NewLoading} from '../../../../controls/index'

const ModalAlert = () => {
  const { svsState, svsAction } = useSVS();
  return (
    <>
      {/* modal Alert */}
      <Modal
        show={svsState.dataAlert.show} //onHide={svsState.closeAlert}
        backdrop="static">
        {svsState.dataAlert.header !== null && svsState.dataAlert.header !== "" && (
          <Modal.Header>
            <Modal.Title>{svsState.dataAlert.header}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <h5
                style={{
                  textAlign: "center",
                  margin: "15px 5px 20px",
                  textTransform: "capitalize",
                }}>
                {svsState.dataAlert.title}
              </h5>
              <div className="p-4 border rounded">
                <p>{svsState.dataAlert.body}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-12">
              <button
                onClick={() => {
                  svsAction.closeAlert();
                  if (
                    svsState.dataAlert.callBack !== null &&
                    svsState.dataAlert.callBack !== undefined
                  )
                    svsState.dataAlert.callBack();
                }}
                className="btn btn-primary btn-sm">
                OK
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* end modal alert */}

      {/* modal confirm */}
      <Modal show={svsState.dataConfirm.show} backdrop="static">
        {svsState.dataConfirm.header !== null && svsState.dataConfirm.header !== "" && (
          <Modal.Header>
            <Modal.Title>{svsState.dataConfirm.header}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              {/* <h4 style={{ textAlign: "center" }}>{svsState.dataConfirm.title}</h4> */}

              {/* <div className="box"> */}
              <h5>{svsState.dataConfirm.body}</h5>
              {svsState.dataConfirm.witTextArea && (
                <div className="row">
                  <div className="col-12">
                    <div className="form-group form-group-sm">
                      <label>Keterangan :</label>
                      <textarea
                        className="form-control form-control-sm"
                        onChange={(e) => svsAction.setOtorDesc(e.target.value)}
                        value={svsState.otorDesc}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-12">
              {svsState.dataConfirm.closeButton && (
                <button
                  onClick={svsState.closeAlert}
                  className="btn btn-secondary btn-sm float-right mr-2">
                  Close
                </button>
              )}
              {svsState.dataConfirm.footerButton?.map(
                ({ className = "", onClick = () => null, style = {}, name = "Ok" }, i) => (
                  <button
                    key={i}
                    className={`${className} float-right mr-2`}
                    onClick={onClick}
                    style={style}>
                    {name}
                  </button>
                )
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* end modal confirm */}

      {/* mdoal loading */}
      <Modal show={svsState.loading.loadingPost?.show} backdrop="static">
        <Modal.Body>
          <div
            style={{
              margin: "auto",
              width: "fit-content",
              padding: "5% 0%",
              textAlign: "center",
            }}>
            <NewLoading width="100px" />
            <h5 className="text-base">{svsState.loading.loadingPost?.msg}</h5>
          </div>
        </Modal.Body>
      </Modal>
      {/*  */}
    </>
  );
};

export default ModalAlert;
