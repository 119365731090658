import React, { memo, useRef } from 'react';
import './style.css';
import { Modal, Dropdown } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { baseUrl } from '../../../../app.config';

class RegistrasiBadanUsaha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            isStep0Completed: false,
            isStep1Completed: false,
            isStep2Completed: false,
            isStep3Completed: false,
            isStep4Completed: false,
            showModalTambahPejabat: false,
            showModalTambahRelasi: false,
            showModalConfirm: false,
            showModalSuccess: false,
            mode: 'create',
            nama_perusahaan: '',
            email: '',
            telepon_kantor: '',
            keterangan: '',
            search: '',
            datatable: [],
            isSearch: false,
        };
    }

    state = {
        data: [],
        db_id: 0,
        search: '',
        datatable: [],
        isSearch: false,
    };

    setStep = (step) => {
        this.setState({
            step,
        });
    };

    saveStep = () => {
        let isStep0Completed = this.state.isStep0Completed;
        let isStep1Completed = this.state.isStep1Completed;
        let isStep2Completed = this.state.isStep2Completed;
        let isStep3Completed = this.state.isStep3Completed;
        let isStep4Completed = this.state.isStep4Completed;
        let showModalConfirm = false;

        if (this.state.step == 0) {
            isStep0Completed = true;
        }

        if (this.state.step == 1) {
            isStep1Completed = true;
        }

        if (this.state.step == 2) {
            isStep2Completed = true;
        }

        if (this.state.step == 3) {
            isStep3Completed = true;
        }

        if (this.state.step == 4) {
            isStep4Completed = true;
        }

        if (
            isStep0Completed &&
            isStep1Completed &&
            isStep2Completed &&
            isStep3Completed &&
            isStep4Completed
        ) {
            showModalConfirm = true;
        }

        this.setState({
            isStep0Completed: isStep0Completed,
            isStep1Completed: isStep1Completed,
            isStep2Completed: isStep2Completed,
            isStep3Completed: isStep3Completed,
            isStep4Completed: isStep4Completed,
            showModalConfirm: showModalConfirm,
        });
    };

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        this.setState({
            [name]: value,
        });
    };

    componentDidMount() {
        this.setState({ db_id: this.props.location.state.data.db_id });
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(
            baseUrl +
                `/nasabah-badan-usaha/lokal/nasabah/cari-db-id?db_id=${this.props.location.state.data.db_id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => this.setState({ data: data?.list }));
    }

    //Reset form
    handleReset = () => {
        document.getElementById('search').reset();
        this.setState({ search: '', datatable: [], isSearch: false });
    };

    //Search relasi nasabah
    handleSearchRelasi = (e) => {
        let data = this.state.data;
        let search = this.state.search;

        // let listSearch = data && data.map(item => {
        //   return data && data._relasiNasabah.map(item => {
        //     return {
        //       name: data && data.relasiNama,
        //       alamat: data && data.relasiAlamat,
        //       nomorNasabah: data && data.relasiNomorNasabah,
        //       jabatanPangkat: data && data.relasiJabatanPangkat,
        //       jenisIdentitas: data && data.relasiJenisIdentitas

        //     }
        //   })
        // })

        // let firstFilter = listSearch && listSearch[0].filter(function (item) {
        //   return item
        // })

        // let secondFilter = firstFilter && firstFilter.filter(function (item) {
        //   return data && data.name === search
        // })

        // this.setState({ datatable: secondFilter, isSearch: true })
    };

    //Search pejabat korporat
    handleSearchKorporat = (e) => {
        let data = this.state.data;
        let search = this.state.search;

        // let listSearch = data && data.map(item => {
        //   return data && data._pejabatKorporat.map(item => {
        //     return {
        //       name: data && data.pejabatNama,
        //       alamat: data && data.pejabatAlamat,
        //       jabatan: data && data.pejabatJabatan,
        //       nomorNasabah: data && data.pejabatNomorNasabahPejabat,
        //       nomorIdentitas: data && data.pejabatNomorIdentitas,
        //       jenisIdentitas: data && data.pejabatJenisIdentitas

        //     }
        //   })
        // })

        // let firstFilter = listSearch && listSearch[0].filter(function (item) {
        //   return item
        // })

        // let secondFilter = firstFilter && firstFilter.filter(function (item) {
        //   return data && data.name === search.toLowerCase()
        // })

        // this.setState({ datatable: secondFilter, isSearch: true })
    };

    render() {
        let data = this.state.data;
        let datatable = this.state.datatable;

        // console.log(data, 'data');
        return (
            <div className="">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Nasabah / CIF</li>
                        <li className="breadcrumb-item">Detail Nasabah</li>
                    </ol>
                </nav>

                <div className="card">
                    <div className="card-header">
                        <div className="row mx-auto justify-content-center p-2">
                            <div
                                className={
                                    this.state.isStep0Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 0 ||
                                          this.state.step == 1 ||
                                          this.state.step == 2 ||
                                          this.state.step == 3 ||
                                          this.state.step == 4 ||
                                          this.state.step === 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(0)}
                            >
                                <div className="row">
                                    <div className="col p0"></div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep0Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Identias Nasabah
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep1Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 1 ||
                                          this.state.step == 2 ||
                                          this.state.step == 3 ||
                                          this.state.step == 4
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(1)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep1Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Pejabat Korporat
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep2Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 2 ||
                                          this.state.step == 3 ||
                                          this.state.step == 4
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(2)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep2Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">CDD</div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep3Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 3 || this.state.step == 4
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(3)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep3Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Relasi Nasabah
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep4Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 4
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(4)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep4Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0"></div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Beneficiary Owner
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.step == 0 ? (
                            <div>
                                <>
                                    <div className="box">
                                        <div className="row">
                                            <div className="col-4 align-self-center bns-form">
                                                <div className="form-group">
                                                    <label>Nomor CIF</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        disabled
                                                        value={
                                                            data && data.id === null
                                                                ? 'Otomatis terisi dari sistem'
                                                                : data && data.id
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-8 text-right align-self-end">
                                                <div>
                                                    <span className="text-grey">Tgl Buka</span>
                                                    <span className="text-grey2 ml-4">:</span>
                                                    <span className="text-grey2">-</span>
                                                    <span className="ml-5 text-grey">
                                                        Kode Cabang
                                                    </span>
                                                    <span className="text-grey2 ml-4">:</span>
                                                    <span className="text-grey2">-</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bns-form">
                                        <div className="row mt-5">
                                            <div className="col-5">
                                                <div class="form-group">
                                                    <label>
                                                        Badan Usaha{' '}
                                                        <span class="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <select
                                                        disabled
                                                        aria-readonly="false"
                                                        class="form-control"
                                                    >
                                                        <option value="">
                                                            {data &&
                                                                data._identitasNasabah
                                                                    .nasabahBadanUsaha}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>
                                                        Nama Perusahaan{' '}
                                                        <span class="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan Nama Perusahaan"
                                                        class="form-control"
                                                        name="nama_perusahaan"
                                                        value={data && data.groupName}
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>
                                                        Nomor NWP{' '}
                                                        <span class="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan Nomor NWP"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._identitasNasabah.nasabahNomorNpwp
                                                        }
                                                    />
                                                </div>
                                                <div className="row">
                                                    <div className="col-7">
                                                        <div class="form-group">
                                                            <label>Status Keterkaitan</label>
                                                            <select
                                                                disabled
                                                                aria-readonly="false"
                                                                class="form-control"
                                                            >
                                                                <option value="">
                                                                    {data &&
                                                                        data._identitasNasabah
                                                                            .nasabahStatusKeterkaitan}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-5">
                                                        <div class="form-group">
                                                            <label>Bank / Non-Bank</label>
                                                            <br />
                                                            <div class="form-check-inline">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                        name="optradio"
                                                                    />
                                                                    Bank
                                                                </label>
                                                            </div>
                                                            <div class="form-check-inline ml-3">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                        name="optradio"
                                                                    />
                                                                    Non-Bank
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>Nomor SIUPP</label>
                                                            <input
                                                                disabled
                                                                placeholder="Masukkan nomor SIUPP"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahNomorSiupp
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>
                                                                Tgl. Pengesahan Akte SIUPP
                                                            </label>
                                                            <input
                                                                disabled
                                                                placeholder="dd-mm-yyyy"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTanggalPengesahanSiupp
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Nomor SKDP</label>
                                                    <input
                                                        disabled
                                                        placeholder="Marketing Nomor SKDP"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._identitasNasabah.nasabahNomorSkdp
                                                        }
                                                    />
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>Tgl. Pengesahan SKDP</label>
                                                            <input
                                                                disabled
                                                                placeholder="dd-mm-yyyy"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTanggalPengesahanSkdp
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>Tgl. Kadaluarsa SKDP</label>
                                                            <input
                                                                disabled
                                                                placeholder="dd-mm-yyyy"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTanggalKadaluarsaSkdp
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>
                                                                No. Akte Pendirian{' '}
                                                                <span class="badge badge-secondary">
                                                                    Wajib
                                                                </span>
                                                            </label>
                                                            <input
                                                                disabled
                                                                placeholder="Masukkan nomor akte pendirian"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahNomorAktePendirian
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>
                                                                Tgl. Pendirian{' '}
                                                                <span class="badge badge-secondary">
                                                                    Wajib
                                                                </span>
                                                            </label>
                                                            <input
                                                                disabled
                                                                placeholder="dd-mm-yyyy"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTanggalAktePendirian
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Tempat Pendirian</label>
                                                    <input
                                                        disabled
                                                        placeholder="Marketing tempat pendirian"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._identitasNasabah
                                                                .nasabahTempatAktePendirian
                                                        }
                                                    />
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>No. Akte Perubahan</label>
                                                            <input
                                                                disabled
                                                                placeholder="Masukkan nomor akte perubahan"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahNomorAktePerubahan
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>
                                                                Tgl. Pengesahan Akte Perubahan
                                                            </label>
                                                            <input
                                                                disabled
                                                                placeholder="dd-mm-yyyy"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTanggalAktePerubahan
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>No. SK MENKUMHAM</label>
                                                            <input
                                                                disabled
                                                                placeholder="Masukkan nomor SK MENKUMHAM"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahNomorSkMenkumham
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div class="form-group">
                                                            <label>Tgl. SK MENKUMHAM</label>
                                                            <input
                                                                disabled
                                                                placeholder="dd-mm-yyyy"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTanggalSkMenkumham
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Nomor TDP</label>
                                                    <input
                                                        disabled
                                                        placeholder="Marketing Nomor TDP"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._identitasNasabah.nasabahNomorTDP
                                                        }
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>Kepemilikan Modal</label>
                                                    <select
                                                        disabled
                                                        aria-readonly="false"
                                                        class="form-control"
                                                    >
                                                        <option value="">
                                                            {data &&
                                                                data._identitasNasabah
                                                                    .nasabahKepemilikanModal}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>
                                                        Lokasi{' '}
                                                        <span class="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan lokasi"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._identitasNasabah.nasabahLokasi
                                                        }
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>
                                                        Sektor Ekonomi{' '}
                                                        <span class="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <select
                                                        disabled
                                                        aria-readonly="false"
                                                        class="form-control"
                                                    >
                                                        <option value="">
                                                            {data &&
                                                                data._identitasNasabah
                                                                    .nasabahSektorEkonomi}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>Bidang Usaha</label>
                                                    <select
                                                        disabled
                                                        aria-readonly="false"
                                                        class="form-control"
                                                    >
                                                        <option value="">
                                                            {data &&
                                                                data._identitasNasabah
                                                                    .nasabahBidangUsaha}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>Pemilik</label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan nama pemilik"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._identitasNasabah.nasabahPemilik
                                                        }
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>Jenis Penduduk</label>
                                                    <select
                                                        disabled
                                                        aria-readonly="false"
                                                        class="form-control"
                                                    >
                                                        <option value="">
                                                            {data &&
                                                                data._identitasNasabah
                                                                    .nasabahJenisPenduduk}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col-6">
                                                <div class="form-group">
                                                    <label>
                                                        Alamat{' '}
                                                        <span class="badge badge-secondary">
                                                            Wajib
                                                        </span>
                                                    </label>
                                                    <textarea
                                                        disabled
                                                        rows="3"
                                                        className="form-control"
                                                        placeholder="Masukkan alamat"
                                                        value={
                                                            data &&
                                                            data._identitasNasabah.nasabahAlamat
                                                        }
                                                    ></textarea>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>
                                                            Provinsi{' '}
                                                            <span class="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            disabled
                                                            placeholder="Masukkan provinsi"
                                                            class="form-control"
                                                            value={
                                                                data &&
                                                                data._identitasNasabah
                                                                    .nasabahProvinsi
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>
                                                            Kota/Kab{' '}
                                                            <span class="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            disabled
                                                            placeholder="Masukkan kota/kabupaten"
                                                            class="form-control"
                                                            value={
                                                                data &&
                                                                data._identitasNasabah
                                                                    .nasabahKotaKabupaten
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label>
                                                            Kecamatan{' '}
                                                            <span class="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            disabled
                                                            placeholder="Masukkan Kecamatan"
                                                            class="form-control"
                                                            value={
                                                                data &&
                                                                data._identitasNasabah
                                                                    .nasabahKecamatan
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>
                                                            Kelurahan{' '}
                                                            <span class="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                        </label>
                                                        <input
                                                            disabled
                                                            placeholder="Masukkan Kelurahan"
                                                            class="form-control"
                                                            value={
                                                                data &&
                                                                data._identitasNasabah
                                                                    .nasabahKelurahan
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>RT / RW</label>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <input
                                                                disabled
                                                                placeholder="Masukkan RT"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah.nasabahRt
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>/</span>
                                                        </div>
                                                        <div className="col-6">
                                                            <input
                                                                disabled
                                                                placeholder="Masukkan RW"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah.nasabahRw
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Kode Pos</label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan Kode Pos"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._identitasNasabah.nasabahKodePos
                                                        }
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>Telepon Kantor 1</label>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <input
                                                                disabled
                                                                placeholder="000"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTeleponKantorKodeArea1
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>-</span>
                                                        </div>
                                                        <div className="col-8">
                                                            <input
                                                                disabled
                                                                placeholder="000"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTeleponKantorNomor1
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Telepon Kantor 2</label>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <input
                                                                disabled
                                                                placeholder="000"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTeleponKantorKodeArea2
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>-</span>
                                                        </div>
                                                        <div className="col-8">
                                                            <input
                                                                disabled
                                                                placeholder="000"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTeleponKantorNomor2
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Telepon Kantor 3</label>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <input
                                                                disabled
                                                                placeholder="000"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTeleponKantorKodeArea3
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>-</span>
                                                        </div>
                                                        <div className="col-8">
                                                            <input
                                                                disabled
                                                                placeholder="000"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahTeleponKantorNomor3
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Fax</label>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <input
                                                                disabled
                                                                placeholder="000"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahFaxKodeArea
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>-</span>
                                                        </div>
                                                        <div className="col-8">
                                                            <input
                                                                disabled
                                                                placeholder="000"
                                                                class="form-control"
                                                                value={
                                                                    data &&
                                                                    data._identitasNasabah
                                                                        .nasabahFaxNomor
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan alamat email"
                                                        class="form-control"
                                                        value={data && data.emailAddress}
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>PIC Nasabah</label>
                                                    <input
                                                        disabled
                                                        placeholder="Bila ada"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._identitasNasabah.nasabahPicNasabah
                                                        }
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>Wilayah Referensi</label>
                                                    <select
                                                        disabled
                                                        aria-readonly="false"
                                                        class="form-control"
                                                    >
                                                        <option
                                                            value={
                                                                data &&
                                                                data._identitasNasabah
                                                                    .nasabahWilayahPemberiReferensi
                                                            }
                                                        >
                                                            {data &&
                                                                data._identitasNasabah
                                                                    .nasabahWilayahPemberiReferensi}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>Keterangan</label>
                                                    <textarea
                                                        disabled
                                                        name=""
                                                        id=""
                                                        rows="3"
                                                        className="form-control"
                                                        placeholder="bila ada"
                                                    ></textarea>
                                                </div>
                                                <div class="form-group">
                                                    <label>Marketing</label>
                                                    <input
                                                        disabled
                                                        placeholder="Marketing Nasabah"
                                                        class="form-control"
                                                        value=""
                                                    />
                                                </div>
                                                <div className="row mt-4 mb-2">
                                                    <div className="col-auto text-primary">
                                                        Hirarki Nasabah
                                                    </div>
                                                    <div className="col">
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Nasabah Induk</label>
                                                    <select
                                                        disabled
                                                        aria-readonly="false"
                                                        class="form-control"
                                                    >
                                                        <option value="">
                                                            {data &&
                                                                data._identitasNasabah
                                                                    .nasabahNasabahInduk}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        ) : null}

                        {this.state.step == 1 ? (
                            <div>
                                <>
                                    <p className="global-text">Cari data berdasarkan :</p>
                                    <div className="row">
                                        <div className="col-3">
                                            <form
                                                // onSubmit={handleSearch}
                                                className="tw-w-full mr-4"
                                                id="search"
                                            >
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Cari nama..."
                                                        onChange={(e) =>
                                                            this.setState({
                                                                search: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-1">
                                            <button
                                                className="btn btn-outline-primary"
                                                onClick={() => this.handleReset()}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                        <div className="col-1 p0">
                                            <button
                                                className="btn btn-primary btn-block"
                                                onClick={(e) => this.handleSearchKorporat(e)}
                                            >
                                                Cari
                                            </button>
                                        </div>
                                    </div>
                                    <p className="text-primary">Pejabat Korporat</p>

                                    <div className="row">
                                        <div className="col-12">
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Jabatan</th>
                                                        <th>Nomor Nasabah</th>
                                                        <th>Nama</th>
                                                        <th>Alamat</th>
                                                        <th>Jenis Identitas</th>
                                                        <th>Nomor Identitas</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                {data.length !== 0 &&
                                                this.state.isSearch === false ? (
                                                    data._pejabatKorporat.map((item) => {
                                                        return (
                                                            <tbody>
                                                                <tr>
                                                                    <td>{item.pejabatJabatan}</td>
                                                                    <td>
                                                                        {
                                                                            item.pejabatNomorNasabahPejabat
                                                                        }
                                                                    </td>
                                                                    <td>{item.pejabatNama}</td>
                                                                    <td>{item.pejabatAlamat}</td>
                                                                    <td>
                                                                        {item.pejabatJenisIdentitas}
                                                                    </td>
                                                                    <td>
                                                                        {item.pejabatNomorIdentitas}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        );
                                                    })
                                                ) : (
                                                    <tbody>
                                                        {datatable &&
                                                            datatable.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {data && data.jabatan}
                                                                        </td>
                                                                        <td>
                                                                            {data &&
                                                                                data.nomorNasabah}
                                                                        </td>
                                                                        <td>{data && data.name}</td>
                                                                        <td>
                                                                            {data && data.alamat}
                                                                        </td>
                                                                        <td>
                                                                            {data &&
                                                                                data.jenisIdentitas}
                                                                        </td>
                                                                        <td>
                                                                            {data &&
                                                                                data.nomorIdentitas}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                )}
                                            </table>
                                        </div>
                                    </div>
                                </>
                            </div>
                        ) : null}

                        {this.state.step == 2 ? (
                            <div className="bns-form">
                                <>
                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">Data Tambahan</div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col-5">
                                            <div class="form-group">
                                                <label>
                                                    Penghasilan Kotor Per Tahun{' '}
                                                    <span class="badge badge-secondary">Wajib</span>
                                                </label>
                                                <select
                                                    disabled
                                                    aria-readonly="false"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._cdd.cddPenghasilanKotorPerTahun}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    Golongan Nasabah{' '}
                                                    <span class="badge badge-secondary">Wajib</span>
                                                </label>
                                                <select
                                                    disabled
                                                    aria-readonly="false"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        {data && data._cdd.cddGolonganNasabah}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    Sumber Dana{' '}
                                                    <span class="badge badge-secondary">Wajib</span>
                                                </label>
                                                <select
                                                    disabled
                                                    aria-readonly="false"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        {data && data._cdd.cddSumberDana}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-6">
                                            <div class="form-group">
                                                <label>
                                                    Sumber Penghasilan{' '}
                                                    <span class="badge badge-secondary">Wajib</span>
                                                </label>
                                                <input
                                                    disabled
                                                    placeholder="Masukkan Sumber Penghasilan"
                                                    class="form-control"
                                                    value={data && data._cdd.cddSumberPenghasilan}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    Tujuan Penggunaan Dana{' '}
                                                    <span class="badge badge-secondary">Wajib</span>
                                                </label>
                                                <select
                                                    disabled
                                                    aria-readonly="false"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        {data && data._cdd.cddSumberPenghasilan}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Tujuan Buka Rekening</label>
                                                <input
                                                    disabled
                                                    placeholder="Masukkan Tujuan Buka Rekening"
                                                    class="form-control"
                                                    value={data && data._cdd.cddTujuanBukaRekening}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">Limit Transaksi</div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-5">
                                            <label>
                                                Transaksi Tunai{' '}
                                                <span class="badge badge-secondary">Wajib</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-7">
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan Nominal Setor Tunai"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._cdd.cddLimitNominalSetorTunai
                                                        }
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <label htmlFor="">Frekuensi</label>
                                                </div>
                                                <div className="col-3">
                                                    <input
                                                        disabled
                                                        placeholder="0"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._cdd.cddLimitFrekuensiSetorTunai
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div class="form-group col-md-6">
                                            <label>
                                                Transaksi Non Tunai{' '}
                                                <span class="badge badge-secondary">Wajib</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-7">
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan Transaksi Non Tunai"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._cdd.cddLimitNominalSetorNonTunai
                                                        }
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <label htmlFor="">Frekuensi</label>
                                                </div>
                                                <div className="col-3">
                                                    <input
                                                        disabled
                                                        placeholder="0"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._cdd.cddLimitFrekuensiSetorNonTunai
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">Faktor Resiko</div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <div class="form-group">
                                                <label>
                                                    Faktor Resiko{' '}
                                                    <span class="badge badge-secondary">Wajib</span>
                                                </label>
                                                <select
                                                    disabled
                                                    aria-readonly="false"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        {data && data._cdd.cddFaktorResiko}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    Status Resiko{' '}
                                                    <span class="badge badge-secondary">Wajib</span>
                                                </label>
                                                <input
                                                    placeholder="Low"
                                                    readOnly
                                                    class="form-control"
                                                    value=""
                                                />
                                            </div>
                                            <div className="alert alert-warning">
                                                Status Resiko akan dikalkulasi berdasar input pada
                                                daftar indikator resiko disamping
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-6">
                                            <table className="table table-bordered text-center">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Tingkat Resiko</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Identitas Masalah</td>
                                                        <td>Low</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lokasi Usaha</td>
                                                        <td>{data && data._cdd.cddLokasiUsaha}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Profil Nasabah</td>
                                                        <td>
                                                            {data && data._cdd.cddProfilNasabah}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jumlah Transaksi</td>
                                                        <td>
                                                            {data && data._cdd.cddJumlahTransaksi}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kegiatan Usaha</td>
                                                        <td>
                                                            {data && data._cdd.cddKegiatanUsaha}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Struktur Kepemilikan</td>
                                                        <td>
                                                            {data &&
                                                                data._cdd.cddStrukturKepemilikan}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Informasi Lainnya</td>
                                                        <td>
                                                            {data && data._cdd.cddInformasiLainnya}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            </div>
                        ) : null}

                        {this.state.step == 3 ? (
                            <div>
                                <p className="global-text">Cari data berdasarkan :</p>
                                <div className="row">
                                    <div className="col-3">
                                        <form
                                            // onSubmit={handleSearch}
                                            className="tw-w-full mr-4"
                                            id="search"
                                        >
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cari nama...."
                                                    onChange={(e) =>
                                                        this.setState({ search: e.target.value })
                                                    }
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-1">
                                        <button
                                            className="btn btn-outline-primary"
                                            onClick={() => this.handleReset()}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                    <div className="col-1 p0">
                                        <button
                                            className="btn btn-primary btn-block"
                                            onClick={(e) => this.handleSearchRelasi(e)}
                                        >
                                            Cari
                                        </button>
                                    </div>
                                </div>
                                <p className="text-primary">Relasi Nasabah</p>

                                <div className="row">
                                    <div className="col-12">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Tipe Relasi Nasabah</th>
                                                    <th>Nomor Nasabah</th>
                                                    <th>Nama</th>
                                                    <th>Jabatan / Pangkat</th>
                                                    <th>Alamat</th>
                                                    <th>Jenis Identitas</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length !== 0 &&
                                                this.state.isSearch === false ? (
                                                    data &&
                                                    data._relasiNasabah.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.relasiNomorNasabah}</td>
                                                                <td>{item.relasiNomorNasabah}</td>
                                                                <td>{item.relasiNama}</td>
                                                                <td>{item.relasiJabatanPangkat}</td>
                                                                <td>{item.relasiAlamat}</td>
                                                                <td>{item.relasiJenisIdentitas}</td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        {datatable &&
                                                            datatable.map((item) => {
                                                                return (
                                                                    <>
                                                                        <td>
                                                                            {data &&
                                                                                data.nomorNasabah}
                                                                        </td>
                                                                        <td>
                                                                            {data &&
                                                                                data.nomorNasabah}
                                                                        </td>
                                                                        <td>{data && data.name}</td>
                                                                        <td>
                                                                            {data &&
                                                                                data.jabatanPangkat}
                                                                        </td>
                                                                        <td>
                                                                            {data && data.alamat}
                                                                        </td>
                                                                        <td>
                                                                            {data &&
                                                                                data.jenisIdentitas}
                                                                        </td>
                                                                    </>
                                                                );
                                                            })}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <Modal
                                    show={this.state.showModalTambahRelasi}
                                    onHide={() => this.setState({ showModalTambahRelasi: false })}
                                    backdrop="static"
                                    keyboard={false}
                                    size="xl"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Tambah Relasi Nasabah</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="bns-form">
                                            <div className="row">
                                                <div class="form-group col-6">
                                                    <label>Hubungan Dengan Nasabah</label>
                                                    <select
                                                        aria-readonly="false"
                                                        class="form-control"
                                                    >
                                                        <option value="">
                                                            Pilih Hubungan Dengan Nasabah
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-6">
                                                    <label>Jabatan / Pangkat</label>
                                                    <select
                                                        aria-readonly="false"
                                                        class="form-control"
                                                    >
                                                        <option value="">
                                                            Pilih jabatan pekerjaan
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mt-4 mb-2">
                                                <div className="col-auto text-primary">
                                                    Data Diri
                                                </div>
                                                <div className="col">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="">Nomor Nasabah</label>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <input
                                                                    placeholder="Masukkan nomor nasabah"
                                                                    class="form-control"
                                                                    value=""
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <button className="btn btn-outline-secondary">
                                                                    Hapus
                                                                </button>
                                                            </div>
                                                            <div className="col-3">
                                                                <button className="btn bg-orange-primary text-white btn-block">
                                                                    Cari Nomor Nasabah
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5">
                                                    <div class="form-group">
                                                        <label>Nama (Sesuai Identitas)</label>
                                                        <input
                                                            placeholder="Nama nasabah sesuai identitas"
                                                            class="form-control"
                                                            value=""
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Alamat</label>
                                                        <textarea
                                                            rows="3"
                                                            className="form-control"
                                                            placeholder="Masukkan alamat"
                                                        ></textarea>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label>
                                                                Provinsi{' '}
                                                                <span class="badge badge-secondary">
                                                                    Wajib
                                                                </span>
                                                            </label>
                                                            <input
                                                                placeholder="Masukkan provinsi"
                                                                class="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label>
                                                                Kota/Kab{' '}
                                                                <span class="badge badge-secondary">
                                                                    Wajib
                                                                </span>
                                                            </label>
                                                            <input
                                                                placeholder="Masukkan kota/kabupaten"
                                                                class="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label>
                                                                Kecamatan{' '}
                                                                <span class="badge badge-secondary">
                                                                    Wajib
                                                                </span>
                                                            </label>
                                                            <input
                                                                placeholder="Masukkan Kecamatan"
                                                                class="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label>
                                                                Kelurahan{' '}
                                                                <span class="badge badge-secondary">
                                                                    Wajib
                                                                </span>
                                                            </label>
                                                            <input
                                                                placeholder="Masukkan Kelurahan"
                                                                class="form-control"
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>RT / RW</label>
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <input
                                                                    placeholder="Masukkan RT"
                                                                    class="form-control"
                                                                    value=""
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <span>/</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <input
                                                                    placeholder="Masukkan RW"
                                                                    class="form-control"
                                                                    value=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Telepon Rumah 1</label>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <input
                                                                    placeholder="000"
                                                                    class="form-control"
                                                                    value=""
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <span>-</span>
                                                            </div>
                                                            <div className="col-8">
                                                                <input
                                                                    placeholder="000"
                                                                    class="form-control"
                                                                    value=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>No Handphone</label>
                                                        <input
                                                            placeholder="Masukkan nomor handphone"
                                                            class="form-control"
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-1"></div>
                                                <div className="col-6">
                                                    <div class="form-group">
                                                        <label>Fax</label>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <input
                                                                    placeholder="000"
                                                                    class="form-control"
                                                                    value=""
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <span>-</span>
                                                            </div>
                                                            <div className="col-8">
                                                                <input
                                                                    placeholder="000"
                                                                    class="form-control"
                                                                    value=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <input
                                                            placeholder="Masukkan alamat email"
                                                            class="form-control"
                                                            value=""
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Jenis Identitas</label>
                                                        <select
                                                            aria-readonly="false"
                                                            class="form-control"
                                                        >
                                                            <option value="">
                                                                Pilih jenis identitas
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Nomor Identitas</label>
                                                        <input
                                                            placeholder="Masukan Nomor Identitas"
                                                            class="form-control"
                                                            value=""
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Nomor NPWP</label>
                                                        <input
                                                            placeholder="Masukan Nomor NPWP"
                                                            class="form-control"
                                                            value=""
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Kewarganegaraan</label>
                                                        <select
                                                            aria-readonly="false"
                                                            class="form-control"
                                                        >
                                                            <option value="">
                                                                Pilih Kewarganegaraan
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Negara Asal</label>
                                                        <select
                                                            aria-readonly="false"
                                                            class="form-control"
                                                        >
                                                            <option value="">
                                                                Pilih Negara Asal
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Pekerjaan</label>
                                                        <select
                                                            aria-readonly="false"
                                                            class="form-control"
                                                        >
                                                            <option value="">
                                                                Pilih Pekerjaan
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button
                                            onClick={() =>
                                                this.setState({ showModalTambahRelasi: false })
                                            }
                                            className="btn btn-secondary"
                                        >
                                            Batal
                                        </button>
                                        <button className="btn btn-primary">Simpan</button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        ) : null}

                        {this.state.step == 4 ? (
                            <div className="bns-form">
                                <>
                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">
                                            Data Beneficiary
                                        </div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col-5">
                                            <div class="form-group">
                                                <label>Nama Perusahan</label>
                                                <input
                                                    disabled
                                                    placeholder="Nama Perusahan"
                                                    class="form-control"
                                                    value={
                                                        data &&
                                                        data._beneficiaryOwner.benefNamaPerusahaan
                                                    }
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Nomor Izin Usaha</label>
                                                <input
                                                    disabled
                                                    placeholder="Masukkan Nomor Izin Usaha"
                                                    class="form-control"
                                                    value={
                                                        data &&
                                                        data._beneficiaryOwner.benefNomorSiupp
                                                    }
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Bidang Usaha</label>
                                                <select
                                                    disabled
                                                    aria-readonly="false"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._beneficiaryOwner.benefBidangUsaha}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Keterangan</label>
                                                <textarea
                                                    disabled
                                                    rows="3"
                                                    className="form-control"
                                                    value={
                                                        data &&
                                                        data._beneficiaryOwner.benefKeterangan
                                                    }
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-6">
                                            <div class="form-group">
                                                <label>Bentuk Usaha</label>
                                                <select
                                                    disabled
                                                    aria-readonly="false"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._beneficiaryOwner.benefBentukUsaha}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Sumber Dana</label>
                                                <select
                                                    disabled
                                                    aria-readonly="false"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._beneficiaryOwner.benefSumberDana}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Tujuan Penggunaan Dana</label>
                                                <select
                                                    disabled
                                                    aria-readonly="false"
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._beneficiaryOwner
                                                                .benefTujuanPenggunaanDana}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">
                                            Alamat Beneficiary
                                        </div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <div class="form-group">
                                                <label>Alamat Domisili</label>
                                                <textarea
                                                    disabled
                                                    rows="3"
                                                    className="form-control"
                                                    placeholder="Masukkan alamat"
                                                    value={
                                                        data && data._beneficiaryOwner.benefAlamat
                                                    }
                                                ></textarea>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label>Provinsi</label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan provinsi"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._beneficiaryOwner.benefProvinsi
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Kota/Kab</label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan kota/kabupaten"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._beneficiaryOwner
                                                                .benefKotaKabupaten
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label>Kecamatan</label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan Kecamatan"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._beneficiaryOwner.benefKecamatan
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Kelurahan</label>
                                                    <input
                                                        disabled
                                                        placeholder="Masukkan Kelurahan"
                                                        class="form-control"
                                                        value={
                                                            data &&
                                                            data._beneficiaryOwner.benefKelurahan
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>RT / RW</label>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <input
                                                            disabled
                                                            placeholder="Masukkan RT"
                                                            class="form-control"
                                                            value={
                                                                data &&
                                                                data._beneficiaryOwner.benefRt
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-1">
                                                        <span>/</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <input
                                                            disabled
                                                            placeholder="Masukkan RW"
                                                            class="form-control"
                                                            value={
                                                                data &&
                                                                data._beneficiaryOwner.benefRw
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Kode Pos</label>
                                                <input
                                                    disabled
                                                    placeholder="Masukkan Kode Pos"
                                                    class="form-control"
                                                    value={
                                                        data && data._beneficiaryOwner.benefKodePos
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        ) : null}
                    </div>
                    <div className="card-footer">
                        <div className="row"></div>
                    </div>
                </div>

                <Modal
                    show={this.state.showModalConfirm}
                    onHide={() => this.setState({ showModalConfirm: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Lakukan proses Registrasi Nasabah / CIF - Badan Usaha?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirm: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.postGroup();
                                this.setState({ showModalConfirm: false, showModalSuccess: true });
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSuccess}
                    onHide={() => this.setState({ showModalSuccess: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Selesai</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Proses Registrasi Nasabah / CIF - Badan Usaha berhasil dilakukan
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => this.setState({ showModalSuccess: false })}
                        >
                            Selesai
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default RegistrasiBadanUsaha;
