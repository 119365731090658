import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { TableList } from '../../controls'
import useCetak from './useCetak';

const PrintTransakasi = () => {
    const { cState, cAction } = useCetak();
    const { formData = {}, formEditable = false, dataCetakTransaksiPrint = {} } = cState;
    const refCetak = useRef(null);

    // const data = {
    //         "status": "00",
    //         "remark": "Succes",
    //         "data": "\u000f\n    2    250219       SI                                    25,000.00          50,000.00   SYSTEM  \n    3    250319       SI                                    25,000.00          75,000.00   SYSTEM  \n    4    260419       SI                                    25,000.00         100,000.00   SYSTEM  \n    5    280519       SI                                    25,000.00         125,000.00   SYSTEM  \n    6    250619       SI                                    25,000.00         150,000.00   SYSTEM  \n    7    250719       SI                                    25,000.00         175,000.00   SYSTEM  \n    8    150919       KAS                                  200,000.00         375,000.00   teller  \n"

    // }
    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={cState.modalCetakTransaksiPrint} onHide={cAction.closeCetakTransaksiPrint} backdrop="static" >
                <Modal.Header >
                    <Modal.Title>Cetak Transaksi Pasbook</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="row" ref={refCetak}>
                            <div className='col'>
                                <TableList
                                    data={dataCetakTransaksiPrint?.dttrx || []}
                                    minHeight="250px"
                                    maxHeight="450px"
                                    pagination={false}
                                    withCard={false}
                                    columns={[
                                        
                                        {
                                            field : "BARIS",
                                            name : "BARIS KE"
                                        },
                                        
                                        {
                                            field : "TANGGAL",
                                            name : "Tanggal"
                                        }
                                        ,
                                        {
                                            field : "KODE",
                                            name : "Tx"
                                        }
                                        ,
                                        {
                                            field : "NILAI_MUTASI_DEBET",
                                            name : "Mutasi Debit"
                                        }
                                        ,
                                        {
                                            field : "NILAI_MUTASI_KREDIT",
                                            name : "Mutasi Kredit"
                                        }
                                        ,
                                        {
                                            field : "SALDO",
                                            name : "Saldo"
                                        }
                                        ,
                                        {
                                            field : "USER",
                                            name : "OprId"
                                        }
                                    ]}
                                />
                       </div>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            {/* <button
                            onClick={() => cAction.closeCetakTransaksiPrint()}
                            className="btn btn-outline-danger float-right">
                            Batal
                        </button> */}
                            <button
                                onClick={() => cAction.doCetakTrxPassbook()}
                                className="btn btn-success float-right ml-3" >
                                Cetak
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal utama */}
        </>
    );
}

export default PrintTransakasi;