import React, { useEffect, useState } from 'react';
// import useFetch from '../../../../../hooks/useFetch';
import useFetch from '../../../../hooks/useFetch';
// import { useModalAlert } from '../../../../';
import { useModalAlert } from '../../../controls/modalAlert';

const useModalNasabah = () => {
    const [modalNasabah, setModalNasabah] = useState(false)
    const [loadingSearch, setLoadingSearch ] = useState(false);
    const { doGet, doPost, wait }  = useFetch();
    const [ pagination, setPagination ] = useState({
        page:1, show: 15
    });
    const [filter, setFilter] = useState({ filterBy: 0, filterType: 0, keyword:"" })
    const [listNasabah, setListNasabah] = useState([])
    const { modalState, modalAction } = useModalAlert();
    const { 
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom 
    } = modalAction;

    // close Modal
    function closeModal() { setModalNasabah(false) }
    // open Modal Add

    function openModal() { 
        setModalNasabah(true) 
    }

    // tombol cari
    async function searchNasabah(){
        setLoadingSearch(true)
        var url = `/nasabahumum/nasabah-wic-perorangan/search`;
        if(parseInt(filter.filterType) == 1) url = `/nasabah-badan-usaha/search`;
        try {
            const {statusCode, status, remark, statusText, data = {}} = await doPost({
                url: url,
                queryParam: { ... pagination,  name: filter.keyword}
               
            });

            if(statusCode === 200){
                if(status === "00"){
                    setListNasabah(data?.listNasabah)
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan' , header: "Error", callBack: () => null });
        }
        wait(3000);
        setLoadingSearch(false) 
    }

    useEffect(function(){
        searchNasabah()
    }, [pagination])

    //  reset
    useEffect(function(){
        setListNasabah([])
    },[filter])

    // next pagination
    function nextPage({page, limit}){
        // console.log(page)
        setPagination({
            page,
            show:limit
        })
    }

    // prev pagination
    function prevPage({page, limit}){
        setPagination({
            page,
            show:limit
        })
    }
// change show
    function changeShow({page, limit}){
        setPagination({
            page,
            show:limit
        })
    }

    return {
        pdrState: {
            filter,
            pagination,
            listNasabah,
            loadingSearch,
            modalNasabah
        },
        pdrAction: {
            closeModal,
            openModal,
            setFilter,
            searchNasabah,
            setPagination, 
            nextPage,
            prevPage,
            changeShow
        }
    };
}

export default useModalNasabah;