import React, { memo, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from 'react-bootstrap';
import { AppAction, AppInterfaces } from '../../../app.data';

const LoadingIcon = memo(()=>(
    <>
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </>
))

const WalkInCostumer_base =  memo(({store, ...props}) => {
    const appAction = useRef(null);
    const [cshow, setcShow] = useState(false);
    const [tshow, settShow] = useState(false);
    return (
        <React.Fragment>
            <AppAction ref={appAction} />
            <CariPelanggan show={cshow} setShow={(e)=>setcShow(e)} />
            <TambahPelanggan show={tshow} setShow={(e)=>settShow(e)} />
            <div className="card card-body">
                <div className="row">
                    <div className="col">
                        <h5>Data Pelanggan</h5>
                        <div className="mb-3 row">
                            <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Nama Pelanggan</label>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Cari untuk pelanggan terdaftar" aria-label="Cari untuk pelanggan terdaftar" disabled aria-describedby="basic-addon2" 
                                        value={store.pelanggan.nama} />
                                    <span className="input-group-text bg-gray-3 text-white pointer" id="basic-addon2" onClick={()=>setcShow(true)}><i className="fas fa-search"></i>&nbsp;Cari</span>
                                </div>
                            </div>
                            <div className="col-sm-4"><button className="btn bg-orange-primary text-white" onClick={()=>settShow(true)}><i className="fas fa-user-plus"></i>&nbsp;WIC Pelanggan Baru</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

const pelanggan = [
    {noHp: "09831281214", nama: "Asep Amarudin", alamat: "Komplek Bumi Sukagalih Pe...", WIC: "WIC Perorangan", ID: "KTP", noID: "3204371805960001"},
    {noHp: "18273164181", nama: "Asep Budiman", alamat: "Westheimer Rd. Santa Ana... ", WIC: "WIC Perorangan", ID: "KTP", noID: "3204371805960001"},
    {noHp: "18273164123", nama: "Asep Ujo", alamat: "Ranchview Dr. Richardson n...", WIC: "WIC Perorangan", ID: "KTP", noID: "3204371805960001"},
]

const CariPelanggan_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
    const [selected, setSelected] = useState(null);
    const { register, handleSubmit, setValue } = useForm();

    const cariPelanggan = (data) => {
        console.log("cari pelanggan", data)
    }

    const reset = () => {
        setValue("pelanggan", {nama: "", tglLahir: "", jenisWIC: "", namaIbu: "", noID: "", nohp: "",})
    }

    const buttonPilih = () => {
        appAction.current.disp({type: "setPelanggan", pelanggan: pelanggan[selected]});
        props.setShow(false);
    }

    return (
        <React.Fragment>
            <AppAction ref={appAction} />
            <Modal
                show={props.show}
                onHide={() => props.setShow(false)}
                backdrop={"static"}
                dialogClassName="w-100"
                aria-labelledby="example-custom-modal-styling-title"
                centered
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="align-self-center h5" id="example-custom-modal-styling-title h6">
                        Cari pelanggan berdasarkan :
                    </Modal.Title>                    
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(cariPelanggan)}>
                        <div className="row">
                            <div className="col-4">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Nama Pelanggan</label>
                                    <input type="text" ref={register} name="pelanggan.nama" className="form-control" id="name" placeholder="Masukkan Nama Anda" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="tglLahir" className="form-label">Tanggal Lahir</label>
                                    <input type="text" ref={register} name="pelanggan.tglLahir" className="form-control" id="tglLahir" placeholder="dd/mm/yyyy" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label htmlFor="jenisId" className="form-label">Jenis Pelanggan/WIC</label>
                                    <select id="jenisWIC" ref={register} name="pelanggan.jenisWIC" className="form-control form-select" aria-label="Default select example">
                                        <option value="" selected>Pilih Jenis Identitas</option>
                                        <option value="1">KTP</option>
                                        <option value="2">SIM</option>
                                        <option value="3">Passport</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="namaIbu" className="form-label">Nama Ibu kandung</label>
                                    <input type="text" ref={register} name="pelanggan.namaIbu" className="form-control" id="namaIbu" placeholder="Masukkan Nama Ibu Kandung Anda" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label htmlFor="noid" className="form-label">No. Identitas</label>
                                    <input type="text" ref={register} name="pelanggan.noID" className="form-control" id="noid" placeholder="Masukan No Identitas" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label">No Telepon</label>
                                    <input type="text" ref={register} name="pelanggan.nohp" className="form-control" id="phone" placeholder="Masukan No Telepon" />
                                </div>
                                <div className="row">
                                    <div className="col-4 pr-0">
                                        <button className="btn btn-outline-orange-primary form-control" onClick={()=>reset()}>Reset</button>
                                    </div>
                                    <div className="col-8">
                                        <button className="btn btn-orange-primary form-control">Cari Pelanggan</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <span className="b-border-gray mx-0 px-0 py-1" width="100%"></span>
                <ModalBody>
                    <div className="row align-middle my-0 py-0">
                        <div className="col-6 align-self-center">
                            <div className="f-14 text-orange-primary">Menampilkan 3 hasil pencarian</div>
                        </div>
                        <div className="col-6 clearfix" >
                            <div className="float-right">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="!#" style={{textDecoration: "none", color: "#E0E0E0"}}>Previous</a></li>
                                    <li className="page-item"><a className="page-link bg-gray-4" href="!#" style={{textDecoration: "none", color: "white", backgroundColor: "#BDBDBD"}}>1</a></li>
                                    <li className="page-item"><a className="page-link" href="!#" style={{textDecoration: "none", color: "#E0E0E0"}}>Next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <table className="table table-borderless table-md">
                        <thead className="b-border-gray bg-gray-1">
                            <th>#</th>
                            <th>No. Nasabah</th>
                            <th>Nama Lengkap</th>
                            <th>Alamat</th>
                            <th>Jenis Nasabah</th>
                            <th>Identitas</th>
                            <th>Nomor Identitas</th>
                        </thead>
                        <tbody style={{backgroundColor: "#F5F6F8"}}>
                            {pelanggan.map((data, id) => (
                                <tr key={id} className={`pointer b-border-gray ${selected === id && "bg-gray-univ"}`} onClick={()=>setSelected(id)}>
                                    <td>{id+1}</td>
                                    <td>{data.noHp}</td>
                                    <td>{data.nama}</td>
                                    <td>{data.alamat}</td>
                                    <td>{data.WIC}</td>
                                    <td>{data.ID}</td>
                                    <td>{data.noID}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="btn btn-primary float-right px-5" onClick={()=>buttonPilih()}>Pilih</button>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
})

const TambahPelanggan_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
    const { register, handleSubmit, setValue } = useForm();
    
    const tambahPelanggan = (data) => {
        console.log("Tambah Data", data);
        if(store.isSukses){    
            appAction.current.disp({type: "setSuccess", value: false});
            setValue("pelanggan", {WIC: "", ID: "", nama: "", noID: "", tglLahir: "", namaIbu: "", noHp: "", alamat: ""});
            props.setShow(false);      
        }else{
            appAction.current.register({...data.pelanggan});
            pelanggan.push({...data.pelanggan});
        }
    }

    const close = () => {
        appAction.current.disp({type: "setSuccess", value: false});
        props.setShow(false);
    }

    return(
        <React.Fragment>
            <AppAction ref={appAction} />
            <Modal
                show={props.show}
                backdrop={"static"}
                onHide={() => props.setShow(false)}
                dialogClassName="w-100"
                aria-labelledby="example-custom-modal-styling-title"
                centered
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title className="align-self-center" id="example-custom-modal-styling-title h6">
                        <h5>Registrasi WIC Pelanggan Baru</h5>
                    </Modal.Title>
                    <button type="button" className="close" onClick={()=>close()}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <ModalBody>
                    <div className="row g-3 mb-3">
                        <div className="col">
                            <label htmlFor="wic" className="form-label pb-0">Jenis Pelanggan/WIC<span className="text-danger"> *</span></label>
                            <select id="wic" className={`form-control form-select`} aria-label="Default select example" disabled={store.isSukses}
                                ref={register({required: true})} name="pelanggan.WIC">
                                <option value="" selected>Pilih Jenis WIC</option>
                                <option value="1">WIC Perorangan</option>
                                <option value="2">WIC Badan Usaha</option>
                            </select>
                        </div>  
                        <div className="col">
                            <label htmlFor="id" className="form-label">Jenis Identitas<span className="text-danger"> *</span></label>
                            <select id="id" className={`form-control form-select`} aria-label="Default select example" disabled={store.isSukses}
                                ref={register({required: true})} name="pelanggan.ID" >
                                <option value="" selected>Pilih Jenis Identitas</option>
                                <option value="1">KTP</option>
                                <option value="2">SIM</option>
                                <option value="3">Passport</option>
                            </select>
                        </div>                     
                    </div>
                    <div className="row g-3 mb-3">
                        <div className="col">
                            <label htmlFor="nama" className="form-label">Nama Pelanggan<span className="text-danger"> *</span></label>
                            <input type="text" id="nama" className={`form-control`} placeholder="Masukkan Nama" aria-label="Last name"
                                disabled={store.isSukses} ref={register({required: true})} name="pelanggan.nama" onFocus={(e)=>e.target.select()}/>
                        </div>
                        <div className="col">
                            <label htmlFor="noId" className="form-label">No Identitas<span className="text-danger"> *</span></label>
                            <input type="text" id="noId" className={`form-control`} placeholder="Masukkan No Identitas" aria-label="Last name" 
                                disabled={store.isSukses} ref={register({required: true})} name="pelanggan.noID" onFocus={(e)=>e.target.select()} />
                        </div>
                    </div>
                    <div className="row g-3 mb-3">
                        <div className="col">
                            <label htmlFor="nama" className="form-label">Tanggal Lahir<span className="text-danger"> *</span></label>
                            <input type="text" id="noId" className={`form-control`} placeholder="dd/mm/yyyy" aria-label="Last name" 
                                disabled={store.isSukses} ref={register({required: true})} name="pelanggan.tglLahir" onFocus={(e)=>e.target.select()} />
                        </div>
                        <div className="col">
                            <label htmlFor="noId" className="form-label">Nama Ibu kandung<span className="text-danger"> *</span></label>
                            <input type="text" id="noId" className={`form-control`} placeholder="Masukkan Nama Ibu" aria-label="Last name" 
                                disabled={store.isSukses} ref={register({required: true})} name="pelanggan.namaIbu" onFocus={(e)=>e.target.select()} />
                        </div>
                    </div>
                    <div className="row g-3 mb-3">                        
                        <div className="col">
                            <label htmlFor="telp" className="form-label">No Telepon<span className="text-danger"> *</span></label>
                            <input type="text" id="telp" className={`form-control`} placeholder="Masukkan No Telepon" aria-label="Last name"
                                disabled={store.isSukses} ref={register({required: true})} name="pelanggan.noHp" onFocus={(e)=>e.target.select()}/>
                        </div>
                        <div className="col">
                            <label htmlFor="alamat" className="form-label">Alamat</label>
                            <textarea className="form-control" id="alamat" rows="3" placeholder="Masukan Alamat" disabled={store.isSukses}
                                ref={register} name="pelanggan.alamat"></textarea>
                        </div>
                    </div>
                    <div className={`p-3 text-center rounded ${store.isSukses ? "alert-success" : "alert-warning"}`}>
                        {store.isSukses ? "Registrasi WIC Pelanggan baru berhasil" : <><i className="fas fa-exclamation-circle"></i> &nbsp;Pastikan data pelanggan yang Anda masukan sudah benar sebelum disimpan</>}
                    </div>
                </ModalBody>
                <span className="b-border-gray mb-3 px-0 py-1 mx-0" width="100%"></span>
                <ModalBody className="clearfix">
                    <form onSubmit={handleSubmit(tambahPelanggan)}>
                        <button className={`px-5 float-right btn ${store.isSukses ? "btn-outline-primary" : "btn-primary"}`} type="submit">
                            {store.isProsess ? <LoadingIcon /> : (store.isSukses ? "OK" : "Simpan")}
                        </button>
                    </form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    ) 
})

const WalkInCostumer = AppInterfaces.appLoad(WalkInCostumer_base);
const CariPelanggan = AppInterfaces.appLoad(CariPelanggan_base);
const TambahPelanggan = AppInterfaces.appLoad(TambahPelanggan_base);

export default WalkInCostumer;