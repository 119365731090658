import React, { useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import logo from '../../../img/logobns.png';
import loginBG from '../../../img/login_bg.png';
import { carousel } from '../../../appConfig.js';
// import { useKeycloak } from '@react-keycloak/web';
import useLocalStorage from '../../../module/useLocalStorage';
import { baseUrl } from '../../../app.config';
import useKeycloak from '../../../hooks/keycloak.module';
const Login = function (props) {
    const { initialized, keycloak } = useKeycloak();
    const [storage, setStorage] = useLocalStorage('auth', false);
    const [account, setAccount] = useLocalStorage('account', false);
    // // console.log(a)

    // useEffect(()=>{
    //     const a = keycloak.init({onLoad:"login-required"});
    //     // console.log(a)
    // },[])
    return (
        <Login_base
            keycloak={keycloak}
            storage={storage}
            setStorage={setStorage}
            account={account}
            setAccount={setAccount}
        />
    );
};

class Login_base extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            carouselId: 0,
            showPwd: false,
            idErr: false,
            pwdErr: false,
        };
    }

    showPassword = () => this.setState({ showPwd: !this.state.showPwd });

    doLogin = () => {
        let r = this.refs;
        let p = this.props;
        if (r.idPengguna.value === '' && r.password.value === '') {
            this.setState({ idErr: !this.state.idErr, pwdErr: !this.state.pwdErr });
            // setTimeout(() => this.setState({idErr: !this.state.idErr, pwdErr: !this.state.pwdErr}), 2000 ); // 3 secs
        } else if (r.idPengguna.value === '') {
            this.setState({ idErr: !this.state.idErr });
        } else if (r.password.value === '') {
            this.setState({ pwdErr: !this.state.pwdErr });
        } else {
            // p.doLogin({id: r.idPengguna.value, pwd: r.password.value});
            let body = {
                username: r.idPengguna.value,
                password: r.password.value,
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify(body),
            };
            fetch(baseUrl + '/account/user/login', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.message_id === '00') {
                        // console.log(data.list);
                        this.props.setStorage(true);
                        this.props.setAccount(data.list);
                        window.location.reload();
                    } else {
                        alert('username password salah');
                    }
                });
        }
    };

    selecthandler = (e) => {
        document.body.style.setProperty('--img-carousel', carousel[e].img);
        this.setState({ carouselId: e });
    };
    render() {
        let s = this.state;
        return (
            <div className="bg-gray-6">
                <main>
                    <div className="row p-0 m-0 bg-fff">
                        <div
                            className="col-8 p-0 m-0"
                            style={{
                                backgroundImage: `url(${loginBG})`,
                                backgroundSize: 'cover',
                                height: '100vh',
                            }}
                        >
                            <div className="">
                                <div className="card-logo text-right">
                                    <div className="row mx-0 align-content-center">
                                        <div className="col-1 px-5 py-4">
                                            <div className="card-logo text-right">
                                                <img src={logo} height="100%" />
                                            </div>
                                        </div>
                                        <div className="col-2 px-0 py-0 offset-9"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-0 m-0 align-items-center">
                                <div className="col-8 pb-10 px-5 text-left" style={{marginTop: 220}}>
                                    <h1 style={{fontSize: 58.9, color: 'white', fontWeight: 600}}>Branch Distribution System</h1>
                                    <p style={{color: 'white', fontSize: 18}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nec eu vitae sit et, tempus nulla et.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="p-2 card-logo text-right">
                                {/* Pengaturan <i className="fas fa-cog"></i> */}
                            </div>
                            <div className="row p-0 m-0 align-items-center card-form-login">
                                <div className="col pb-10 px-5 text-left ">
                                    <div className="h4 py-0">Login</div>
                                    <div className="h4 text-orange-primary">
                                        <h5>
                                            Selamat datang, selalu berikan pelayanan terbaik Anda.
                                        </h5>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <label for="idPengguna">ID Pengguna</label>
                                        <input
                                            type="text"
                                            placeholder="Masukan ID Pengguna"
                                            className={`form-control ${
                                                s.idErr ? 'is-invalid' : ''
                                            }`}
                                            ref="idPengguna"
                                            id="idPengguna"
                                            // value="ritelloket"
                                            aria-describedby="validationId"
                                            required
                                        />
                                        <div id="validationId" className="invalid-feedback">
                                            Silahkan masukkan ID pengguna Anda
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="password">Kata Sandi</label>
                                        <div className="input-group">
                                            <input
                                                placeholder="Masukan Kata Sandi"
                                                type={s.showPwd ? 'text' : 'password'}
                                                className={`form-control border-right-0 ${
                                                    s.pwdErr ? 'border-danger' : ''
                                                }`}
                                                // value="123456"
                                                ref="password"
                                                id="password"
                                                aria-describedby="validationpwd"
                                                required
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className={`form-control border-left-0 border-rad-left-0 ${
                                                        s.pwdErr ? 'border-danger' : ''
                                                    }`}
                                                    onMouseDown={this.showPassword}
                                                    onMouseUp={this.showPassword}
                                                >
                                                    <div className="pointer btn-input-eye">
                                                        <i className="fa fa-eye"></i>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        {s.pwdErr ? (
                                            <small className="text-danger">
                                                Silahkan masukkan password Anda
                                            </small>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            onClick={() => this.doLogin()}
                                            className="btn btn-primary form-control"
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="f-14 text-gray-3 text-center">Versi 1.0.0</div> */}
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

class LoginCarousel extends React.PureComponent {
    render() {
        let p = this.props;
        return (
            <Carousel activeIndex={p.index} onSelect={(e) => p.selecthandler(e)}>
                {p.data.map((val, id) => {
                    return (
                        <Carousel.Item key={id}>
                            <div
                                className="row align-items-center card-carousel"
                                style={{ minHeight: '94vh' }}
                            >
                                <div className="col p-15 pb-10">
                                    <h3
                                        style={{
                                            textAlign: 'center',
                                            color: 'white',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {val.title}
                                    </h3>
                                </div>
                            </div>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        );
    }
}

export default Login;
