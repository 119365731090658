import React from 'react';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistration } from '../../../../../controls';
import useRekening from '../useRekening';
import { addCommas } from '../../../../../../module';

const DetilRencana = () => {
    const { rekState, rekAction } = useRekening();
    return (
        <>
            <div className="row">
                <div className="col">
                    <h3 style={{ marginBottom: 35 }}>Detail Rekening</h3>

                    <div className="mt-3">
                        <FormRegistration
                            isLoading={rekState.loadingDetil}
                            itemsForm={[
                                {
                                    name: "Data Nasabah",
                                    rows: [
                                        {
                                            rows: 1,
                                            type: "custom",
                                            component: (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group form-group-sm pl-2">
                                                            <label>Nomor CIF</label>
                                                            <span className="badge badge-secondary">
                                                                Wajib
                                                            </span>
                                                            <div className="input-group input-group-sm mb-3">
                                                                <input
                                                                    type="text"
                                                                    // required
                                                                    className="form-control form-control-sm col-9"
                                                                    placeholder="Masukan nomor CIF"
                                                                    readOnly
                                                                    value={rekState.rekSelected?.nomor_nasabah}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 1,
                                            label: 'Nama',
                                            type: 'text',
                                            placeholder: 'Masukan nama',
                                            required: false,
                                            disabled: true,
                                            value: rekState.rekSelected?.nama_lengkap
                                        },
                                        {
                                            rows: 1,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: 'No Telepon',
                                                                type: 'number',
                                                                placeholder: 'masukan nomor telepon',
                                                                required: false,
                                                                disabled: true,
                                                                value: rekState.rekSelected?.telepon_rumah_nomor
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: 'No Handphone',
                                                                type: 'number',
                                                                placeholder: 'masukan no handphone',
                                                                disabled: true,
                                                                value: rekState.rekSelected?.telepon_hp_nomor
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            label: 'Nama Ibu Kandung',
                                            type: 'text',
                                            required: false,
                                            disabled: true,
                                            value: rekState.rekSelected?.nama_ibu_kandung
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col-4">
                                                        {
                                                            createForm({
                                                                label: 'Tempat & Tanggal Lahir',
                                                                type: 'text',
                                                                placeholder: '',
                                                                required: false,
                                                                disabled: true,
                                                                // value:rekState.rekSelected?.nama_cabang
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        {
                                                            createForm({
                                                                label: <>&nbsp;</>,
                                                                type: 'text',
                                                                placeholder: '',
                                                                disabled: true,
                                                                required: false,
                                                                value: rekState.rekSelected?.tanggal_lahir
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            label: 'Alamat',
                                            type: 'textarea',
                                            placeholder: 'Masukan alamat',
                                            required: false,
                                            disabled: true,
                                            value: rekState.rekSelected?.alamat
                                        },
                                    ]
                                },
                                // End Data Nasabah
                                // rekening
                                {
                                    name: "Rekening",
                                    rows: [
                                        {
                                            rows: 1,
                                            label: 'Produk Tabungan',
                                            type: 'text',
                                            placeholder: 'Pilih produk Tabungan',
                                            value: rekState.rekSelected?.nama_produk,
                                            disabled: true
                                        },
                                        {
                                            rows: 1,
                                            label: 'Nama Rekening',
                                            type: 'text',
                                            placeholder: 'Masukan nama rekening',
                                            disabled: true,
                                            value: rekState.rekSelected?.nama_rekening
                                        },
                                        {
                                            rows: 1,
                                            label: 'Valuta',
                                            type: 'text',
                                            placeholder: 'IDR - Rupiah',
                                            value: rekState.rekSelected?.valuta,
                                            disabled: true
                                        },
                                        {
                                            rows: 1,
                                            label: 'Keterangan',
                                            type: 'text',
                                            value: rekState.rekSelected?.keterangan,
                                            disabled: true
                                        },
                                        {
                                            rows: 1,
                                            label: 'Tanggal Jatuh Tempo',
                                            type: 'text',
                                            value: rekState.rekSelected?.tanggal_jatuh_tempo,
                                            disabled: true
                                        },

                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col">
                                                        {
                                                            createForm({
                                                                label: 'Jangka Waktu',
                                                                type: 'text',
                                                                value: rekState.rekSelected?.jangka_waktu,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        {
                                                            createForm({
                                                                label: 'Tanggal AutoDebet',
                                                                type: 'text',
                                                                value: rekState.rekSelected?.tanggal_setoran_rutin,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col">
                                                        {
                                                            createForm({
                                                                label: 'Target Saldo',
                                                                type: 'text',
                                                                value: addCommas(parseFloat(rekState.rekSelected?.target_nominal || 0).toFixed(2)),

                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: 'Rekening Auto Debet & Nama',
                                                                type: 'text',
                                                                value: rekState.rekSelected?.nomor_rekening_induk,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: <>&nbsp;</>,
                                                                type: 'text',
                                                                value: rekState.rekSelected?.nama_rekening_autodebet,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: 'Rekening Pencairan & Nama',
                                                                type: 'text',
                                                                value: rekState.rekSelected?.nomor_rekening_pencairan,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: <>&nbsp;</>,
                                                                type: 'text',
                                                                value: rekState.rekSelected?.nama_rekening_pencairan,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col pt-4 mt-2">
                                                        {
                                                            createForm({
                                                                label: <strong>Dapat Bonus / Bagi Hasil</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_dapat_bagi_hasil === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col">
                                                        {
                                                            createForm({
                                                                label: 'Nisbah Spesial',
                                                                type: 'number',
                                                                //placeholder: '%',
                                                                value: rekState.rekSelected?.nisbah_spesial || 0,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        {
                                                            createForm({
                                                                label: 'Zakat Bagi Hasil',
                                                                type: 'number',
                                                                //placeholder: '%',
                                                                value: rekState.rekSelected?.persentase_zakat_bagi_hasil || 0,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col">
                                                        {
                                                            createForm({
                                                                label: 'Pajak Bagi Hasil',
                                                                type: 'number',
                                                                //placeholder: '%',
                                                                value: rekState.rekSelected?.tarif_pajak || 0,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        {
                                                            createForm({
                                                                label: 'Nisbah Berdasarkan Produk',
                                                                type: 'number',
                                                                //placeholder: '%',
                                                                value: rekState.rekSelected?.nisbah_dasar || 0,
                                                                disabled: true
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },

                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col-6 pt-2">
                                                        {
                                                            createForm({
                                                                label: <strong>Blokir Debet</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_blokir_debet === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-6 pt-2">
                                                        {
                                                            createForm({
                                                                label: <strong>Blokir Kredit</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_blokir_kredit === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: <strong>Cetak Nota</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_cetak_nota === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: <strong>Status Passbook</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_status_passbook === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: <strong>Dormant</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_tidak_dormant === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: <strong>Biaya Rekening Dormant</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_biaya_rekening_dormant === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: <strong>Biaya Administrasi</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_kena_biayalayananumum === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: <strong>Biaya Saldo Minimum</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_biaya_saldo_minimum === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <div className="row">
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                label: <strong>Biaya ATM</strong>,
                                                                type: "checkbox",
                                                                checked: rekState.rekSelected?.is_biaya_atm === "T",
                                                                // value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                                // onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                                disabled: false
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                    ]
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetilRencana;