
import React from 'react';
import { layout } from '../../../app.config';
import LayoutExt from './layoutExt';
import LayoutMinimalis from './layoutMinimalis';
import useKeycloak from '../../../hooks/keycloak.module';
const Layout = ({children}) => {
    const { keycloak } = useKeycloak()
    return ( 
        <>
            {
                keycloak.authenticated ? layout.type === 'minimals' ? (
                    <>
                        <LayoutMinimalis>
                            {children}
                        </LayoutMinimalis>
                    </>
                ) : (
                    <>
                        <LayoutExt>
                            {children}
                        </LayoutExt>
                    </>
                ) : children
            }
        </>
     );
}
 
export default Layout;