
import React, { memo } from 'react';
import {BasePageMenu} from '../../controls';
import bannerIpos from '../../../img/Banner-ipos.png';
import kirimanPos from '../../../img/kirimanpos.png';
import refkodepos from '../../../img/referensikodepos.png';
import batallogo from '../../../img/batalpengiriman.png';
import PembatalanKiriman from "./pembatalanKiriman";

const menuIposPage = memo((props) => {
    const [modalShow, setModalShow] = React.useState(false);

    return (<>
        <BasePageMenu 
            icon={bannerIpos}
            title="Layanan Pengiriman"
            layanan={
                [
                    {title: "Kiriman Pos", link: "/iPos/kiriman-pos", icon: kirimanPos, linkBack: "/iPos", lastTitle: "IPOS"},
                    {title: "Referensi Kodepos & Tarif", link: "/iPos/referensi-kodepos", icon: refkodepos, linkBack: "/iPos", lastTitle: "IPOS"},
                    {title: "Pembatalan Pengiriman", type: "popUp", trigger: () => setModalShow(true), icon: batallogo, linkBack: "/iPos", lastTitle: "IPOS"},
                ]
            } />
        <PembatalanKiriman show={modalShow} onHide={() => setModalShow(false)} />
    </>)
});

export default menuIposPage;