import React, {useEffect} from 'react';
import { useHistory, useParams } from 'react-router';
import { createForm } from '../../../../../module/createFormList';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import useTab, { TabProvider } from './useTabungan';
import moment from 'moment';

function TabunganPage() {
    const { tabAction, tabState } = useTab();
    const paramUrl = useParams();
    let history = useHistory();
    useEffect(function () {
        tabAction.auditTrail()
    }, [])
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Tabungan & Giro</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Register Buku Tabungan
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        {/* Card header */}
                        <div className="card-header">
                            <div className="card-title">
                                Registrasi Buku Tabungan
                            </div>
                        </div>
                        {/* End Of Card header */}
                        {/* Card Body */}
                        <div className="card-body">
                            <h4 className="text-base">
                                Data Rekening
                            </h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <FormCreated
                                        type="custom"
                                    >
                                        <div className="row">
                                            <div className="col-6">
                                                <FormCreated
                                                    type="number"
                                                    label="Nomor Rekening"
                                                    placeholder= "Masukan nomor rekening"
                                                    required={true}
                                                    value={tabState.formData.nomor_rekening}
                                                    disabled={!tabState.formEditable}
                                                    onChange={(e) => tabAction.setFormData(prevState => ({ ...prevState, nomor_rekening: e }))}
                                                    onEnter={(e) => tabAction.cekNasabah(e)}
                                                    onFocusOut={(e) => tabAction.cekNasabah(e)}
                                                />
                                            </div> 
                                            <div className="col-6">
                                                <FormCreated
                                                    type="text"
                                                    label="Nama Rekening"
                                                    placeholder= "Masukan Nama Rekening"
                                                    required={false}
                                                    value={tabState.formData.nama_nasabah}
                                                    disabled={true}
                                                    onChange={(e) => tabAction.setFormData(prevState => ({ ...prevState, nama_nasabah: e }))} 
                                                />
                                            </div>
                                        </div>
                                    </FormCreated>
                                    <FormCreated
                                        type="custom"
                                    >
                                        <div className="row">
                                            <div className="col-6">
                                                <FormCreated
                                                    type="text"
                                                    label="Cabang Rekening"
                                                    placeholder= "Masukan Cabang Rekening"
                                                    required={false}
                                                    value={tabState.formData.cabang_rekening}
                                                    disabled={true}
                                                    onChange={(e) => tabAction.setFormData(prevState => ({ ...prevState, cabang_rekening: e }))}
                                                />
                                            </div> 
                                            <div className="col-6">
                                                <FormCreated
                                                        type="text"
                                                        label="Cabang User"
                                                        required={false}
                                                        placeholder= "Masukan Cabang User"
                                                        value={tabState.formData.cabang_user}
                                                        disabled={true}
                                                        onChange={(e) => tabAction.setFormData(prevState => ({ ...prevState, cabang_user: e }))}
                                                    />
                                            </div>
                                        </div>
                                    </FormCreated>
                                    <FormCreated
                                        type="custom"
                                    >
                                        <div className="row">
                                            <div className="col-6">
                                                <FormCreated
                                                    type="text"
                                                    label= {paramUrl.mode === "detil" ? "Regiter Buku Aktif" : "Register"} 
                                                    placeholder= "Masukan register"
                                                    required={true}
                                                    value={tabState.formData.regis}
                                                    onChange={(e) => tabAction.setFormData(prevState => ({ ...prevState, regis: e }))}
                                                    onEnter={(e) => tabAction.cekNomorBuku(e)}
                                                    onFocusOut={(e) => tabAction.cekNomorBuku(e)}                                                
                                                    disabled={!tabState.formEditable}
                                                />
                                            </div> 
                                            <div className="col-6">
                                                <FormCreated
                                                        type="text"
                                                        label="Register Buku Lama"
                                                        placeholder= "Masukan Register Buku Lama"
                                                        required={false}
                                                        value={tabState.formData.register_buku_lama}
                                                        onChange={(e) => tabAction.setFormData(prevState => ({ ...prevState, register_buku_lama: e }))}
                                                        disabled={true}
                                                    />
                                            </div>
                                        </div>
                                    </FormCreated>
                                    <FormCreated
                                        type="custom"
                                    >
                                        <div className="row">
                                            <div className="col-6">
                                                <FormCreated
                                                    type="date"
                                                    label="Tanggal Input"
                                                    required={false}
                                                    value={tabState.formData?.tgl_buku_rekening || moment(Date.now()).format("YYYY-MM-DD")}
                                                    disabled={true}
                                                    onChange={(e) => tabAction.setFormData(prevState => ({ ...prevState, tgl_buku_rekening: e }))}
                                                />
                                            </div> 
                                            <div className="col-6">
                                                <FormCreated
                                                        type="number"
                                                        label="Buku Ke"
                                                        placeholder= "Masukan Buku ke"
                                                        required={false}
                                                        value={tabState.formData.buku_ke}
                                                        disabled={true}
                                                        onChange={(e) => tabAction.setFormData(prevState => ({ ...prevState, buku_ke: e }))}
                                                    />
                                            </div>
                                        </div>
                                    </FormCreated>
                                    <FormCreated
                                        type="custom"
                                    >
                                        <div className="row">
                                            <div className="col-6">
                                            <FormCreated
                                                    type="select"
                                                    label="Keterangan"
                                                    placeholder= "Pilih Keterangan"
                                                    required={true}
                                                    value={tabState.formData.keterangan}
                                                    disabled={!tabState.formEditable}
                                                    onChange={(e) => tabAction.setFormData(prevState => ({ ...prevState, keterangan: e }))}
                                                    options={[
                                                                {
                                                                    key: "1",
                                                                    display: "1 - BARU"
                                                                },{
                                                                    key: "2",
                                                                    display: "2 - HABIS BUKU"
                                                                },{
                                                                    key: "3",
                                                                    display: "3 - RUSAK"
                                                                },{
                                                                    key: "4",
                                                                    display: "4 - HILANG"
                                                                },{
                                                                    key: "5",
                                                                    display: "5 - LAIN-LAIN"
                                                                }
                                                            ]}
                                                />
                                            </div> 
                                            <div className="col-6">
                                            
                                            </div>
                                        </div>
                                    </FormCreated>
                                   
                                </div>
                            </div>
                        </div>
                        {/* End Of Card Body */}
                        {/* Card Footer */}
                        <div className="card-footer">
                            <div className="row">

                                <div className="col">
                                    <button onClick={()=>history.goBack()} className="btn btn-secondary float-right ml-3">
                                        close
                                    </button>
                                    {
                                        paramUrl.mode === "otorisasi" && (
                                            <>
                                                <button onClick={()=>tabAction.responseOtor("APPROVED")} 
                                                    className="btn btn-success float-right ml-3">
                                                    Approve
                                                </button>
                                                <button onClick={()=>tabAction.responseOtor("REJECTED")}
                                                className="btn btn-danger float-right ml-3">
                                                    Reject
                                                </button>
                                            </>
                                        )
                                    }
                                    {
                                        tabState.formEditable &&
                                        <button onClick={tabAction.confirmTrx} className="btn btn-success float-right ml-3">
                                            Simpan
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* End Card Footer */}
                    </div>
                </div>
            </div>
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => tabAction.setOtorDesc(e.target.value)}
                    value={tabState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )
}

function FormCreated({ children, ...props }) {
    // // console.log(props)
    let option = props;
    if (props.type === "custom") option = { ...props, component: children, }
    return <>{
        createForm({ ...option,  key:props.keyData })
    }</>
}

export default () => <TabProvider><TabunganPage /></TabProvider>