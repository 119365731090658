// import { useKeycloak } from "@react-keycloak/web";
import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { useModalAlert } from "../../../../controls/modalAlert";
import moment from 'moment';
import { uniqueId } from "lodash";
import { addCommas, terbilang } from "../../../../../module";
import useNexusPrint from "../../../../../hooks/useNexusPrint";
import { useParams } from 'react-router';
import useDropdowns from '../../../../../hooks/useDropdowns';
import useKeycloak from "../../../../../hooks/keycloak.module";
import ModalBilyetDeposito from "./modal/modalBilyetDeposito";
import ModalUlangBilyet from "./modal/modalUlangBilyet";

const rekContext = createContext({
	rekState: {},
	rekAction: {},
});

export const RekeningProvider = ({ children, isOtor }) => {
	const [modeReqStatement, setReqStatement] = useState(true)
	const { modalAction, modalState } = useModalAlert();
	const { keycloak } = useKeycloak();
	const { doGet, doPost, wait } = useFetch();
	const paramUrl = useParams();
	const { print } = useNexusPrint()
	const { closeLoading, setLoading, closeConfirmCustom, openAlertCustom, openLoading } =
		modalAction;
	const [modalUbahNisbah, setModalUbahNisbah] = useState({
		show: false,
		mode: 1,
	});

	useEffect(function () {
		setOtorMode(isOtor)
	}, [isOtor])

	const [otorDesc, setOtorDesc] = useState("");
	const [dataOtor, setDataOtor] = useState({});
	const [otorMode, setOtorMode] = useState(false);
	const [formData, setFormData] = useState({
		nisbah_spesial: "",
	});
	const [formDataD, setFormDataD] = useState({
	});
	const dataDropDown = useDropdowns();
	const [modalCetakBilyet, setModalCetakBilyet] = useState(false);
	const [modalCetakBilyetPrint, setModalCetakBilyetPrint] = useState(false);
	const [modalCetakBilyetUlang, setModalCetakBilyetUlang] = useState(false);
	const [modalCetakBilyetUlangPrint, setModalCetakBilyetUlangPrint] = useState(false);
	const [modalDetailDeposito, setModalDetailDeposito] = useState(false);
	const [holdModalMode, setHoldModalMode] = useState(false);
	const [blokRekModalMode, setBlokRekMode] = useState(false);

	const [pagination, setPagination] = useState({ page: 1, show: 15 });
	const [filter, setFilter] = useState({ keyword: "" });
	const [dataListRekening, setListDataRekening] = useState([]);
	const [formCetak, setFormCetak] = useState({
		command: "PRINT",
		medianame: "Term Cash Withdrawal",
		formname: "BJBS TARIK TUNAI",
		print: 1,
		history: 0,
		id: null,
		kodeCabang_namaCabang: "",
		noRekDepositoNasabah: "",
		tglValuta: "",
		tglJatuhTempo: "",
		jangkaWaktu: "",
		bagiHasilInvestor: "",
		bagiHasilBank: "",
		jumlahDeposito: "",
		terbilang: "",
		norekIAJatuhTempo: "",
		norekPerantaraBagiHasil: "",
		namaAlamatInvestor: "",
		namaSO: "",
		namaAO: ""
	})
	const [dataAlert, setDataAlert] = useState({
		show: false,
		body: "",
		header: null,
		title: "",
		callBack: () => null,
	});
	const [dataConfirm, setDataConfirm] = useState({
		show: false,
		body: "",
		title: "",
		header: null,
		footerButton: [],
		closeButton: false,
		witTextArea: false,
	});
	const [isLoading, setIsLoading] = useState(true);
	const [loadingDetil, setLoadingDetil] = useState(true);
	const [isValid, setIsValid] = useState(false);
	const [rekSelected, setRekSelected] = useState({});
	const [dataProduk, setDataProduk] = useState([])
	const [dataMarketing, setDataMarketing] = useState([])
	// const { print } = useNexusPrint()
	const [pejabat, setPejabat] = useState({
		pejabat1: "",
		pejabat2: ""
	})

	const [formCetakUlang, setFormCetakUlang] = useState({
		alasan_cetak: "",
		keterangan: "",
		tanggal: "",
		nomor_surat_kehilangan: "",
		nomor_bilyet: ""
	})

	const [disableCetakUlang, setDisabledCetakUlang] = useState(true)

	function resetFormCetakUlang() {
		setFormCetakUlang({
			alasan_cetak: "",
			keterangan: "",
			tanggal: "",
			nomor_surat_kehilangan: "",
			nomor_bilyet: ""
		})
	}

	useEffect(function () {

		// if( modalCetakBilyetUlangPrint){
		// 	setRekSelected( rs => ({
		// 		...rs,
		// 		nomor_bilyet:formCetakUlang.nomor_bilyet
		// 	}))
		// }else{

		setFormCetakUlang(p => ({
			...p,
			nomor_bilyet: rekSelected.nomor_bilyet
		}))
		// }
		// // console.log(rekSelected)
	}, [rekSelected])


	useEffect(function () {
		setDisabledCetakUlang(formCetakUlang.alasan_cetak !== "C")
	}, [formCetakUlang.alasan_cetak])

	function sutPejabatUp(value, key, subkey = null) {
		if (typeof value === "string") value = value.toUpperCase()
		if (subkey !== null) setPejabat(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
		else setPejabat(prevState => ({ ...prevState, [key]: value }));
	}
	// useEffect GetData
	useEffect(
		function () {


			if (!isOtor) getDataMarketing();
		},
		[]
	);


	useEffect(function () {
		if (!isOtor) getDataList();
	}, [pagination])

	// open modal detail rekening
	function openDetailDeposito(dataRekening) {
		setRekSelected({});
		setModalDetailDeposito(true);
		getDetail(dataRekening.nomor_rekening, dataRekening.status);
	}
	//data instansi
    const [ dataInstansi, setDataInstansi] = useState([])
    useEffect(function () {
        (async ()=>{

            try {
                const { data = [], statusCode, status, remark, statusText } = await doGet({
                    url: `/rekening/list-instansi`,
                    service: "acc"
                });
                if (statusCode === 200) {
                    if (status === "00") setDataInstansi(data)
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data instansi`, header: "Error", callBack: () => null });
            }

        })()
    }, [])
	//  get data Mekerting
	async function getDataMarketing() {
		try {
			const { data, statusCode, status, remark, statusText } = await doGet({
				url: `/account-officer`,
				service: "cif"
			});
			if (statusCode === 200) {
				if (status === "00") setDataMarketing(data?.list)
				else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
			} else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
		} catch (error) {
			setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data produk`, header: "Error", callBack: () => null });
		}

	}
	// open modal bilyet
	function openCetakBilyet(dataRekening) {
		// // console.log(dataRekening);
		setPejabat({
			pejabat1: "",
			pejabat2: ""
		})
		setRekSelected({});
		setModalCetakBilyet(true);
		getDetail(dataRekening.nomor_rekening, dataRekening.status);
	}

	// close modal bilyet
	function closeCetakBilyet() {
		setModalCetakBilyet(false);
		setModalCetakBilyetPrint(false);
	}


	// open modal cetak bilyet print
	function openCetakBilyetPrint(idRekening) {

		if (pejabat.pejabat1 === "" || pejabat.pejabat1 === null || pejabat.pejabat1 === undefined) {
			return setDataAlert({ show: true, body: "Nama pejabat 1 harus diisi", title: `Nama Pejabat`, header: "Info", callBack: () => null });
		}
		else if (pejabat.pejabat2 === "" || pejabat.pejabat2 === null || pejabat.pejabat2 === undefined) {
			return setDataAlert({ show: true, body: "Nama pejabat 2 harus diisi", title: `Nama Pejabat`, header: "Info", callBack: () => null });
		} else {
			setModalCetakBilyet(false);
			setRekSelected({});
			setModalCetakBilyetPrint(true);
			getDetail(idRekening, 200);
		}
	}
	// open modal bilyet Ulang
	function openCetakBilyetUlang(dataRekening) {
		// // console.log(dataRekening);
		resetFormCetakUlang()
		setPejabat({
			pejabat1: "",
			pejabat2: ""
		})
		setRekSelected({});
		setModalCetakBilyetUlang(true);
		getDetail(dataRekening.nomor_rekening, dataRekening.status, false, true, true);
	}
	// close modal bilyet Ulang
	function closeCetakBilyetUlang() {
		setModalCetakBilyetUlang(false);
		setModalCetakBilyetUlangPrint(false);
	}
	// open modal cetak bilyet Ulang print
	function openCetakBilyetUlangPrint(idRekening) {

		// if (formCetakUlang.alasan_cetak !== "C") {
		// 	if (rekSelected.nomor_bilyet === formCetakUlang.nomor_bilyet)
		// 		return setDataAlert({
		// 			show: true,
		// 			body: "Nomor bilyet sama dengan sebelumnya, mohon ganti nomor bilyet dengan yang baru",
		// 			titile: `Info`,
		// 			callBack: () => null,
		// 		});
		// }

		// if (
		// 	formCetakUlang.alasan_cetak === "HILANG"
		// ) {
		// 	if (
		// 		formCetakUlang.nomor_surat_kehilangan === "" ||
		// 		formCetakUlang.nomor_surat_kehilangan === undefined ||
		// 		formCetakUlang.nomor_surat_kehilangan === null ||
		// 		formCetakUlang.tanggal === "" ||
		// 		formCetakUlang.tanggal === undefined ||
		// 		formCetakUlang.tanggal === null ||
		// 		formCetakUlang.keterangan === "" ||
		// 		formCetakUlang.keterangan === undefined ||
		// 		formCetakUlang.keterangan === null
		// 	) return setDataAlert({
		// 		show: true,
		// 		body: "Form cetak ulang belum lengkap.",
		// 		titile: `Info`,
		// 		callBack: () => null,
		// 	});

		// } else if (
		// 	formCetakUlang.alasan_cetak === "" ||
		// 	formCetakUlang.alasan_cetak === undefined ||
		// 	formCetakUlang.alasan_cetak === null ||
		// 	formCetakUlang.keterangan === "" ||
		// 	formCetakUlang.keterangan === undefined ||
		// 	formCetakUlang.keterangan === null
		// ) return setDataAlert({
		// 	show: true,
		// 	body: "Form cetak ulang belum lengkap.",
		// 	titile: `Info`,
		// 	callBack: () => null,
		// });
		// if (pejabat.pejabat1 === "" || pejabat.pejabat1 === null || pejabat.pejabat1 === undefined) {
		// 	return setDataAlert({ show: true, body: "Nama pejabat 1 harus diisi", title: `Nama Pejabat`, header: "Info", callBack: () => null });
		// }
		// else if (pejabat.pejabat2 === "" || pejabat.pejabat2 === null || pejabat.pejabat2 === undefined) {
		// 	return setDataAlert({ show: true, body: "Nama pejabat 2 harus diisi", title: `Nama Pejabat`, header: "Info", callBack: () => null });
		// } else {
		setModalCetakBilyetUlang(false);
		setRekSelected({});
		setModalCetakBilyetUlangPrint(true);
		getDetail(idRekening, 200, false, false);
		// }
	}

	// close modal detail rekening
	function closeDetailDeposito() {
		setModalDetailDeposito(false);
		// setHoldModalMode(false)
	}

	// toggle modal mode { false : data, true : register}
	function toggleModalMode() {
		setHoldModalMode((prevState) => !prevState);
		setBlokRekMode((prevState) => !prevState);
	}

	// close Alert
	function closeAlert() {
		setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null });
	}

	async function cekNomorBilyet(nomor_bilyet) {
		try {
			const resp = await doGet({
				// data: bodyData,
				url: `/deposito/validasi-deposito-account?nomor_bilyet=${nomor_bilyet}`,
				service: "acc"
			});
			const { statusCode, status, statusText, data, remark } = resp;
			if (statusCode === 200) {
				if (status === "00") {
					setDataAlert({ show: true, body: remark, title: ``, header: "Success", callBack: () => setDisabledCetakUlang(false) });
				} else
					setDataAlert({
						show: true, body: remark, title: ``, header: "Warning", callBack: () => {
							// console.log("sss")
							if (formCetakUlang.alasan_cetak !== "C") {
								// console.log(",as")
								setDisabledCetakUlang(true)
							}
							setFormCetakUlang(x => ({ ...x, nomor_bilyet: rekSelected.nomor_bilyet }))
						}
					});

			} else setDataAlert({
				show: true, body: remark, title: ``, header: "Warning", callBack: () => {
					// console.log("sss")

					if (formCetakUlang.alasan_cetak !== "C") {
						// console.log(",as")
						setDisabledCetakUlang(true)
					}
					setFormCetakUlang(x => ({ ...x, nomor_bilyet: rekSelected.nomor_bilyet }))
				}
			});
		} catch (error) {
			setDataAlert({
				show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => {
					// console.log("sss")

					if (formCetakUlang.alasan_cetak !== "C") {
						// console.log(",as")
						setDisabledCetakUlang(true)
					}
					setFormCetakUlang(x => ({ ...x, nomor_bilyet: rekSelected.nomor_bilyet }))
				}
			});
		}
		closeLoading();
	}

	async function updateBilyet(nomor_bilyet) {
		try {
			const resp = await doPost({
				// data: bodyData,
				url: `/deposito/update-cetak-bilyet`,
				service: "acc",
				queryParam: {
					nomor_rekening: rekSelected.nomor_rekening,
					nama_rekening: rekSelected.nama_rekening,
					pejabat1: pejabat.pejabat1,
					pejabat2: pejabat.pejabat2,
					nomor_bilyet
				},
			});
			const { statusCode, status, statusText, data, remark } = resp;
			if (statusCode === 200) {
				if (status === "00") {
					setDataAlert({
						show: true, body: remark, title: ``, header: "Info", callBack: () => {
							resetForm();
							resetFormCetakUlang();
							getDataList();
						}
					});
				} else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
			} else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
		} catch (error) {
			setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
		}
	}
	async function doCetak() {
		// doCetakNexus();
		openLoading("Please wait. Proses cek inventori")
		let url = `/deposito/detail-bilyet/${rekSelected.nomor_bilyet}`;
		try {
			const resp = await doGet({
				url,
				// queryParam: {
				// 	nomor_bilyet: rekSelected.nomor_bilyet,
				// },
				service: "acc",
			});

			const { statusCode, status, remark, statusText } = resp

			if (statusCode === 200) {
				if (status === "00") {
					setDataAlert({
						show: true,
						body: "Bilyet siap di cetak",
						titile: `Success`,
						callBack: () => doCetakNexus(() => {
							updateBilyet(rekSelected.nomor_bilyet)
							flaggingCetakBilyet(rekSelected.nomor_rekening)
						}),
					});
				} else
					setDataAlert({
						show: true,
						body: remark,
						titile: ``,
						callBack: () => null,
					});
			} else
				setDataAlert({
					show: true,
					body: remark,
					title: `${JSON.stringify(statusText)}`,
					header: "Error",
					callBack: () => null,
				});
		} catch (error) {
			setDataAlert({
				show: true,
				body: error.toString(),
				title: "Terjadi Kesalahan",
				header: "Error",
				callBack: () => null,
			});
		}
		closeLoading()

	}

	async function doCetakNexus(callBack = () => null) {
		openLoading()
		// postCetakBilyet()
		try {
			const dataResponse = await print({
				...formCetak, namaSO: pejabat.pejabat1 || "",
				namaAO: pejabat.pejabat2 || "",
			});
			if (dataResponse.statusCode === 500) {
				closeLoading()
				return setDataAlert({ show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Error", callBack: () => null });
			} else {
				closeLoading()
				setDataAlert({
					show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Berhasil", callBack: () => {
						// postCetakBilyet()
						closeConfirm();
						// mpAction.closeModal("modalTrx")
						getDataList()
						closeLoading();
						closeCetakBilyet();
						closeCetakBilyetUlang();
						callBack();

					}
				});
			}
		} catch (error) {
			closeLoading()
			return setDataAlert({
				show: true, body: "Gagal koneksi ke print server. Periksa kembali koneksi print server", title: ``, header: "Info", callBack: () => {
					closeAlert();
					// mpAction.closeModal();
					getDataList()
					closeLoading()
				}
			});
		}


	}
	async function doCetakUlang() {

		// doCetakNexus()
		openLoading("Please wait. Proses cek inventori")
		let url = "/produk-deposit/cetak-ulang-bilyet";
		// if(formCetakUlang.alasan_cetak==="C"){
		// 	closeLoading()
		// 	return setDataAlert({
		// 		show: true,
		// 		body: "Bilyet siap di cetak",
		// 		titile: `Success`,
		// 		callBack: () => doCetakNexus(() => flaggingCetakBilyet(rekSelected.nomor_rekening)),
		// 	});
		// }
		try {
			const resp = await doPost({
				url,
				method: "PUT",
				// data: {
				// 	nomor_bilyet: formCetakUlang.nomor_bilyet,
				// 	nomor_rekening: rekSelected.nomor_rekening,
				// 	nama_rekening: rekSelected.nama_rekening,
				// 	pejabat1: pejabat.pejabat1,
				// 	pejabat2: pejabat.pejabat2,
				// 	alasan_cetak_ulang: formCetakUlang.alasan_cetak,
				// 	keterangan_cetak_ulang: formCetakUlang.keterangan
				// },
				queryParam: {
					nomor_bilyet: formCetakUlang.nomor_bilyet,
					nomor_rekening: rekSelected.nomor_rekening,
					nama_rekening: rekSelected.nama_rekening,
					pejabat1: pejabat.pejabat1,
					pejabat2: pejabat.pejabat2,
					alasan_cetak_ulang: formCetakUlang.alasan_cetak,
					keterangan_cetak_ulang: formCetakUlang.keterangan
				},
				service: "cif",
			});

			const { statusCode, status, remark, statusText } = resp

			if (statusCode === 200) {
				if (status === "00") {
					setDataAlert({
						show: true,
						body: "Bilyet siap di cetak",
						titile: `Success`,
						callBack: () => doCetakNexus(() => flaggingCetakBilyet(rekSelected.nomor_rekening)),
					});
				} else
					setDataAlert({
						show: true,
						body: remark,
						titile: ``,
						callBack: () => null,
					});
			} else
				setDataAlert({
					show: true,
					body: remark,
					title: `${JSON.stringify(statusText)}`,
					header: "Error",
					callBack: () => null,
				});
		} catch (error) {
			setDataAlert({
				show: true,
				body: error.toString(),
				title: "Terjadi Kesalahan",
				header: "Error",
				callBack: () => null,
			});
		}
		closeLoading()


	}

	// closeConfirm
	function closeConfirm() {
		setDataConfirm({
			show: false,
			body: "",
			header: null,
			title: "",
			footerButton: [],
			closeButton: true,
			witTextArea: false,
		});
	}

	const [namaKota, setNamaKota] = useState("")

	// getDetail
	async function getDetail(
		nomor_rekening,
		rekStatus,
		pejabat = false,
		resetBilyet = true,
		isCetakUlang = false
	) {
		setLoadingDetil(true);
		var url = `/deposito/${nomor_rekening}`;
		try {
			const {
				statusCode,
				status,
				remark,
				statusText,
				nama_kota = "",
				data = {},
			} = await doGet({
				url: url,
				service: "acc",
			});

			if (statusCode === 200) {
				if (status === "00") {
					// // console.log(nama_kota)
					setRekSelected({ ...data, ...(!resetBilyet ? { nomor_bilyet: formCetakUlang.nomor_bilyet } : {}), status: rekStatus });
					setNamaKota(nama_kota)
					setDataBukaBlokir(c => ({
						"nomor_rekening": data.nomor_rekening,
						"nama_rekening": data.nama_rekening,
						"produk_rekening": data.nama_produk,
						"valuta_rekening": data.valuta,
						"cabang_rekening": data.nama_cabang,
						"saldo_rekening": data.saldo_deposito,
						"tanggal_kadaluarsa": data.tanggal_jatuh_tempo_terakhir,
						"alasan_blokir": data.keterangan_blokir,
						"jenis_blokir": data.jenis_blokir
					}));

					let rtrw = data.alamat_rumah_rt !== null && data.alamat_rumah_rw !== null
						&& data.alamat_rumah_rt !== "" && data.alamat_rumah_rw !== ""
						&& data.alamat_rumah_rt !== undefined && data.alamat_rumah_rw !== undefined
						?
						`RT${data.alamat_rumah_rt}/RW${data.alamat_rumah_rw}` :
						"";
					let kec = data.alamat_rumah_kecamatan !== null &&
						data.alamat_rumah_kecamatan !== "" &&
						data.alamat_rumah_kecamatan !== undefined ?
						`KEC. ${data.alamat_rumah_kecamatan}` : "";

					let kel = data.alamat_rumah_kelurahan !== null &&
						data.alamat_rumah_kelurahan !== "" &&
						data.alamat_rumah_kelurahan !== undefined ?
						`KEL. ${data.alamat_rumah_kelurahan}` : "";
					// // console.log(pejabat)
					setFormCetak(c => ({
						command: "PRINT",
						medianame: "BJBS",
						formname: "Bilyet Deposito - Korporasi",
						orientation: "1",
						print: 1,
						history: 0,
						id: uniqueId(data.nomor_rekening),
						"kodeCabang-namaCabang": data.nama_cabang || "",
						noRekDepositoNasabah: data.nomor_rekening || "",
						tglValuta: moment(data.tanggal_buka, "YYYY/MM/DD").format("DD-MM-YYYY"),
						tglJatuhTempo: moment(data.tanggal_jatuh_tempo_berikutnya, "YYYY/MM/DD").format("DD-MM-YYYY"),
						jangkaWaktu: ` ${data?.masa_perjanjian || ""} ${data?.periode_perjanjian === "H" ? "HARI" : (data?.periode_perjanjian === "B" ? "BULAN" : "")}`,
						bagiHasilInvestor: parseFloat(parseFloat(data?.nisbah_spesial || 0) + parseFloat(data?.nisbah_dasar || 0)).toFixed(2),
						bagiHasilBank: parseFloat(100 - (parseFloat(data?.nisbah_spesial || 0) + parseFloat(data?.nisbah_dasar || 0))).toFixed(2),
						jumlahDeposito: addCommas(parseFloat(data.saldo_deposito || 0).toFixed(2)),
						terbilang: `${terbilang(data.saldo_deposito || 0)} RUPIAH`,
						norekIAJatuhTempo: data.rekening_disposisi || "",
						norekPerantaraBagiHasil: data.nomor_rekening_bagihasil || "",
						namaInvestor: `${data.nama_rekening || ""}`,
						alamatInvestor: `${data.alamat || ""} ${rtrw}`,  // ${kec} ${kel} ${data.alamat_rumah_kode_pos}`,
						namaSO: pejabat.pejabat1 || "",
						namaAO: pejabat.pejabat2 || "",
						alamatWilayahTtdTglBulanTahun: `${nama_kota !== "" ? nama_kota + ", " : ""} ${moment(Date.now()).format("DD-MM-YYYY")}`
					})

					)
					if (pejabat || data.is_boleh_cetak_bilyet === "T") {

						setPejabat({
							pejabat1: data.pejabat1,
							pejabat2: data.pejabat2
						})
					}
					if (isCetakUlang && data.is_boleh_cetak_bilyet === "T") {
						setFormCetakUlang({
							alasan_cetak: data.alasan_cetak_ulang,
							keterangan: data.keterangan_cetak_ulang,
							nomor_surat_kehilangan: data.no_surat_kehilangan,
							nomor_bilyet: data.nomor_bilyet,
							tanggal: data.tgl_surat_kehilangan
						})
					}
				} else
					setDataAlert({
						show: true,
						body: remark,
						titile: `Status fetch data is ${statusCode}`,
						callBack: () => null,
					});
			} else
				setDataAlert({
					show: true,
					body: remark,
					title: `${JSON.stringify(statusText)}`,
					header: "Error",
					callBack: () => null,
				});
		} catch (error) {
			setDataAlert({
				show: true,
				body: error.toString(),
				title: "Terjadi Kesalahan",
				header: "Error",
				callBack: () => null,
			});
		}
		await wait(3000);
		setLoadingDetil(false);
	}

	// getData rekening list
	async function getDataList() {
		setIsLoading(true);
		var url = `/deposito/search-by-keyword`;
		try {
			const {
				statusCode,
				status,
				remark,
				statusText,
				data = {},
			} = await doGet({
				url: url,
				param: { ...pagination, ...filter },
				service: "acc",
			});

			if (statusCode === 200) {
				if (status === "00") {
					setListDataRekening(data?.list);
				} else
					setDataAlert({
						show: true,
						body: remark,
						titile: `Status fetch data is ${statusCode}`,
						callBack: () => null,
					});
			} else
				setDataAlert({
					show: true,
					body: remark,
					title: `${JSON.stringify(statusText)}`,
					header: "Error",
					callBack: () => null,
				});
		} catch (error) {
			setDataAlert({
				show: true,
				body: error.toString(),
				title: "Terjadi Kesalahan",
				header: "Error",
				callBack: () => null,
			});
		}

		setIsLoading(false);
	}

	// fungsi setDataForm
	function changeForm(value, key, subkey = null) {
		value = value.toUpperCase();
		if (subkey !== null) {
			setRekSelected((prevState) => ({
				...prevState,
				[subkey]: { ...prevState[subkey], [key]: value },
			}));
			if (key === "personalIdNumber" || key === "personalIdHolderName") {
				setIsValid(false);
			}
		} else {
			setRekSelected((prevState) => ({ ...prevState, [key]: value }));
			if (key === "personalIdNumber" || key === "personalIdHolderName") {
				setIsValid(false);
			}
		}
	}

	function resetFilter() {
		setFilter({
			keyword: "",
		});
	}
	// next pagination
	function nextPage({ page, limit }) {
		// // console.log(page)
		setPagination({
			page,
			show: limit,
		});
	}

	// prev pagination
	function prevPage({ page, limit }) {
		setPagination({
			page,
			show: limit,
		});
	}
	// change show
	function changeShow({ page, limit }) {
		setPagination({
			page,
			show: limit,
		});
	}
	//Ubah Nisbah
	function closeUbahNisbahDeposito() {
		// // console.log("tes")
		setModalUbahNisbah({
			show: false,
			mode: 0,
		});
	}
	//modal ubah nisbah
	function openUbahNisbahDeposito(mode, data, callBackSuccess = () => null) {
		if (mode === 1) {
			setOtorMode(true);
			setDataOtor({
				...data,
				callBackSuccess,
			});
		}
		getUbahDetail(mode === 1 ? data.dbId : data.nomor_rekening, mode);
		setModalUbahNisbah({
			show: true,
			mode,
		});
	}

	async function ubahNisbah() {
		// // console.log(formData);
		const userInfo = await keycloak.loadUserInfo();

		let url = "/deposito/ubahnisbah"; //belum ada URL
		try {
			const resp = await doPost({
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"x-user-id": userInfo.preferred_username,
				},
				data: {
					nama_rekening: formData.nama_rekening,
					nisbah_spesial: parseFloat(formData.nisbah_spesial || 0),
					nomor_bilyet: formData.nomor_bilyet,
					nomor_rekening: formData.nomor_rekening,
					nisbah_produk: formData.nisbah_dasar || 0,
					pajak_baghas: formData.tarif_pajak || 0,
					zakat_baghas: formData.persentase_zakat_bagi_hasil || 0,
					nisbah_akhir: formData.nisbah_bagi_hasil || 0
				},
				url, // ganti sesuai api
				service: "acc", //ganti sesuai service yang dipakai di api
			});
			const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
			if (statusCode === 200) {
				if (status === "00")
					setDataAlert({
						show: true,
						body: "Ubah Nisbah Berhasil. dibutuhkan otorisasi untuk melanjutkan",
						title: ``,
						header: "Success",
						callBack: () => {
							resetForm();
							closeConfirm();
							closeUbahNisbahDeposito();
						},
					});
				else
					setDataAlert({
						show: true,
						body: remark,
						title: `${statusText}`,
						header: "Error",
						callBack: () => null,
					});
			} else
				setDataAlert({
					show: true,
					body: remark,
					title: `${statusText}`,
					header: "Error",
					callBack: () => null,
				});
		} catch (error) {
			setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
		}
		closeLoading();
	}

	// getDetail
	async function getUbahDetail(nomor_rekening, mode) {
		// mode 1 = otorMode
		setLoadingDetil(true);
		var url = `/deposito/${nomor_rekening}`;
		if (mode === 1) url = `/deposito/ubahnisbah/otor-view/${nomor_rekening}`;
		try {
			const {
				statusCode,
				status,
				remark,
				statusText,
				data = {},
			} = mode === 1
					? await doPost({
						url: url,
						service: "acc",
					})
					: await doGet({
						url: url,
						service: "acc",
					});

			if (statusCode === 200 || statusCode === 201) {
				if (status === "00") {
					// // console.log(data);
					setFormData(data);
				} else
					setDataAlert({
						show: true,
						body: remark,
						titile: `Status fetch data is ${statusCode}`,
						callBack: () => null,
					});
			} else
				setDataAlert({
					show: true,
					body: remark,
					title: `${JSON.stringify(statusText)}`,
					header: "Error",
					callBack: () => null,
				});
		} catch (error) {
			setDataAlert({
				show: true,
				body: error.toString(),
				title: "Terjadi Kesalahan",
				header: "Error",
				callBack: () => null,
			});
		}
		await wait(3000);
		setLoadingDetil(false);
	}

	// response otorisasi
	function responseOtor(status) {
		let body = `Apakah anda yakin akan ${status} otorisasi ini ?`;
		openAlertCustom(
			{
				show: true,
				body,
				witTextArea: status === "REJECTED",
				header: "Confirmation",
				title: "",
				closeButton: false,
				footerButton: [
					{
						name: "Ya",
						onClick: () => fetchOtorisasi(status),
						className: "btn btn-sm btn-success",
					},
					{ name: "Tidak", onClick: closeConfirmCustom, className: "btn btn-sm btn-secondary" },
				],
			},
			"otorisasi"
		);
	}

	// fetch API response otorisasi
	async function fetchOtorisasi(statusApprove) {
		const userInfo = await keycloak.loadUserInfo();
		openLoading();
		closeConfirmCustom();
		let keterangan = otorDesc;
		if (keterangan === "" || keterangan === null) {
			if (statusApprove === "APPROVED") keterangan = "Approve";
			else keterangan = "Rejected";
		}
		// if (location.state !== null && location.state !== undefined) {
		try {
			const {
				statusCode,
				remark = "",
				data = null,
				status = "01",
				statusText = "",
			} = await doPost({
				url: `/otorisasi/${dataOtor.kode_entry}/${dataOtor.dbId}/${statusApprove}?keterangan=${keterangan}`,
				method: "PUT",
				service: "acc",
				data: { keterangan: otorDesc },
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"x-user-id": userInfo.preferred_username,
				},
			});
			if (statusCode === 200) {
				if (status === "00")
					setDataAlert({
						show: true,
						body: `Proses Otorisasi Berhasil`,
						title: ``,
						header: "Success",
						callBack: () => {
							// resetForm();
							closeLoading();
							closeAlert();
							closeConfirmCustom();
							closeConfirm();
							dataOtor.callBackSuccess();
							// history.goBack()s
						},
					});
				else
					setDataAlert({
						show: true,
						body: remark,
						title: `${statusText}`,
						header: "Info",
						callBack: () => null,
					});
			} else
				setDataAlert({
					show: true,
					body: remark,
					title: `${statusText}`,
					header: "Error",
					callBack: () => null,
				});
		} catch (error) {
			setDataAlert({
				show: true,
				body: error.toString(),
				title: ``,
				header: "Error",
				callBack: () => null,
			});
		}
		// } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
		closeLoading();
		// setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
	}

	function resetForm() {
		setOtorMode(false);
		setFormData({
			nisbah_spesial: "0",
		});
	}

	async function doTrx() {

		closeConfirm();
		// // console.log(formData);
		const userInfo = await keycloak.loadUserInfo();
		const { nisbah_spesial } = formData;
		let body = {
			nisbah_spesial,
		};
		let url = "/aft/nonaktifasi"; //belum ada URL
		openLoading();
		try {
			const resp = await doPost({
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"x-user-id": userInfo.preferred_username,
				},
				data: formData,
				url, // ganti sesuai api
				service: "acc", //ganti sesuai service yang dipakai di api
			});
			const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
			if (statusCode === 200) {
				if (status === "00")
					setDataAlert({
						show: true,
						body: "Nonaktifasi AFT (Automatic Funding Transfer) Berhasil. dibutuhkan otorisasi AFT untuk melanjutkan",
						title: ``,
						header: "Success",
						callBack: () => {
							resetForm();
							closeConfirm();
							closeUbahNisbahDeposito();
						},
					});
				else
					setDataAlert({
						show: true,
						body: remark,
						title: `${statusText}`,
						header: "Error",
						callBack: () => null,
					});
			} else
				setDataAlert({
					show: true,
					body: remark,
					title: `${statusText}`,
					header: "Error",
					callBack: () => null,
				});
		} catch (error) {
			setDataAlert({
				show: true,
				body: "Terjadi Kesalahan",
				title: ``,
				header: "Error",
				callBack: () => null,
			});
		}
		closeLoading();
	}

	function confirmRCB(isCetakUlang = false) {
		// let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
		if (isCetakUlang) {
			if (
				formCetakUlang.alasan_cetak === "HILANG"
			) {
				if (
					formCetakUlang.nomor_surat_kehilangan === "" ||
					formCetakUlang.nomor_surat_kehilangan === undefined ||
					formCetakUlang.nomor_surat_kehilangan === null ||
					formCetakUlang.tanggal === "" ||
					formCetakUlang.tanggal === undefined ||
					formCetakUlang.tanggal === null ||
					formCetakUlang.keterangan === "" ||
					formCetakUlang.keterangan === undefined ||
					formCetakUlang.keterangan === null
				) return setDataAlert({
					show: true,
					body: "Form cetak ulang belum lengkap.",
					titile: `Info`,
					callBack: () => null,
				});

			} else if (
				formCetakUlang.alasan_cetak === "" ||
				formCetakUlang.alasan_cetak === undefined ||
				formCetakUlang.alasan_cetak === null ||
				formCetakUlang.keterangan === "" ||
				formCetakUlang.keterangan === undefined ||
				formCetakUlang.keterangan === null
			) return setDataAlert({
				show: true,
				body: "Form cetak ulang belum lengkap.",
				titile: `Info`,
				callBack: () => null,
			});
		}
		// else{
		if (pejabat.pejabat1 === "" || pejabat.pejabat1 === null || pejabat.pejabat1 === undefined) {
			return setDataAlert({ show: true, body: "Nama pejabat 1 harus diisi", title: `Nama Pejabat`, header: "Info", callBack: () => null });
		}
		else if (pejabat.pejabat2 === "" || pejabat.pejabat2 === null || pejabat.pejabat2 === undefined) {
			return setDataAlert({ show: true, body: "Nama pejabat 2 harus diisi", title: `Nama Pejabat`, header: "Info", callBack: () => null });
		}
		// }
		let body = `Request cetak bilyet. Lanjutkan ?`;
		setDataConfirm({
			show: true,
			body,
			witTextArea: false,
			header: "Konfirmasi",
			title: "",
			closeButton: false,
			footerButton: [
				{ name: "Ya", onClick: () => requestCetak(isCetakUlang), className: "btn btn-sm btn-success" },
				{ name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" },
			],
		});
	}

	async function requestCetak(isCetakUlang = false) {

		openLoading()
		closeConfirm();
		try {
			const resp = await doPost({
				url: "/deposito/request-cetak-bilyet",
				service: "acc",
				data: {
					"nomor_rekening": rekSelected?.nomor_rekening,
					"nama_rekening": rekSelected?.nama_rekening,
					"nomor_bilyet": isCetakUlang ? formCetakUlang.nomor_bilyet : rekSelected?.nomor_bilyet,
					"tanggal_cetak": moment(Date.now()).format("YYYY/MM/DD"),
					"info_cabang": rekSelected?.nama_cabang,
					"tanggal_jatuh_tempo": rekSelected?.tanggal_jatuh_tempo_berikutnya,
					"jangka_waktu": rekSelected?.masa_perjanjian,
					"jenis_periode": rekSelected?.masa_perjanjian,
					"info_produk": rekSelected?.nama_produk,
					"nominal_deposito": rekSelected?.saldo_deposito || 0,
					"alamat": rekSelected?.alamat,
					"nisbah_akhir": rekSelected?.nisbah_bagi_hasil || 0,
					"pajak_baghas": rekSelected?.tarif_pajak || 0,
					"zakat_baghas": rekSelected?.persentase_zakat_bagi_hasil || 0,
					"tanggal_buka": rekSelected?.tanggal_buka,
					"terbilang": terbilang(rekSelected?.saldo_deposito || 0) + " RUPIAH",
					"pejabat1": pejabat.pejabat1,
					"pejabat2": pejabat.pejabat2,
					"no_surat_kehilangan": formCetakUlang.nomor_surat_kehilangan,
					"tgl_surat_kehilangan": formCetakUlang.tanggal,
					"alasan_cetak_ulang": formCetakUlang.alasan_cetak,
					"keterangan": formCetakUlang.keterangan
				}
			})

			if (resp.statusCode === 200 || resp.statusCode === 201) {

				if (resp.status === "00") {
					setDataAlert({
						show: true,
						body: resp.remark,
						title: ``,
						header: "Success",
						callBack: () => {
							closeCetakBilyet();
							closeCetakBilyetUlang()
						},
					});
				} else setDataAlert({
					show: true,
					body: resp.remark,
					title: ``,
					header: "INFO",
					callBack: () => {
						closeCetakBilyet();
						closeCetakBilyetUlang()
					},
				});
			} else setDataAlert({
				show: true,
				body: resp.remark,
				title: ``,
				header: "INFO",
				callBack: () => {
					closeCetakBilyet();
					closeCetakBilyetUlang()
				},
			});
		} catch (error) {
			setDataAlert({
				show: true,
				body: error.toString(),
				title: ``,
				header: "INFO",
				callBack: () => null,
			});
		}
		closeLoading()
	}


	async function flaggingCetakBilyet(nomor_rekening) {

		try {
			const resp = await doPost({
				url: "/deposito/flaging-is-boleh-cetak-bilyet",
				method: "PUT",
				service: "acc",
				data: {

					"nomor_rekening": nomor_rekening,
					"is_boleh_cetak": "F"
				}
			})
		} catch (error) {

		}
	}
	async function getDetailOtorRCB(data, callBackSuccess) {
		setDataOtor({
			...data,
			callBackSuccess,
		});
		openLoading();
		try {
			const resp = await doGet({
				url: `/deposito/rcb-otor-view/${data.dbId}`,
				service: "acc",
			})

			if (resp.statusCode === 200 || resp.statusCode === 201) {
				if (resp.status === "00") {
					setPejabat({
						pejabat1: resp.data.pejabat1,
						pejabat2: resp.data.pejabat2
					});
					setRekSelected(p => ({
						...p,
						// nomor_rekening : resp.data.nomor_rekening,
						// nama_rekening : resp.data.nama_rekening,
						nomor_bilyet: resp.data.nomor_bilyet,
						// "tanggal_cetak": moment(Date.now()).format("YYYY/MM/DD"),
						// nama_cabang : resp.data.info_cabang,
						// tanggal_jatuh_tempo_berikutnya : resp.data.tanggal_jatuh_tempo,
						// masa_perjanjian : resp.data.jangka_waktu,
						// masa_perjanjian : resp.data.jenis_periode,
						// nama_produk : resp.data.info_produk,
						// saldo_deposito: resp.data.nominal_deposito || 0,
						// alamat : resp.data.alamat,
						// nisbah_dasar : resp.data.nisbah_akhir || 0,
						// tarif_pajak : resp.data.pajak_baghas || 0,
						// persentase_zakat_bagi_hasil : resp.data.zakat_baghas || 0,
						// tanggal_buka : resp.data.tanggal_buka,
						// is_sudah_setor : "T"
					}))
					if (
						resp.data.alasan_cetak_ulang !== "" &&
						resp.data.alasan_cetak_ulang !== null &&
						resp.data.alasan_cetak_ulang !== undefined
					) {
						setFormCetakUlang({
							alasan_cetak: resp.data.alasan_cetak_ulang,
							keterangan: resp.data.keterangan,
							nomor_surat_kehilangan: resp.data.no_surat_kehilangan,
							nomor_bilyet:resp.data.nomor_bilyet,
							tanggal: resp.data.tgl_surat_kehilangan
						})
						setModalCetakBilyetUlang(true)
						// getDetail(resp.data.nomor_rekening, null, false, false, true);
					} else {
						setModalCetakBilyet(true);
						
					}
					getDetail(resp.data.nomor_rekening, null, false, false, false);


				} else setDataAlert({
					show: true,
					body: resp.remark,
					title: ``,
					header: "INFO",
					callBack: () => {
						closeCetakBilyet();
						closeCetakBilyetUlang()
					},
				});
			} else setDataAlert({
				show: true,
				body: resp.remark,
				title: ``,
				header: "INFO",
				callBack: () => {
					closeCetakBilyet();
					closeCetakBilyetUlang()
				},
			});
		} catch (error) {
			setDataAlert({
				show: true,
				body: error.toString(),
				title: ``,
				header: "INFO",
				callBack: () => null,
			});
		}
		closeLoading()
	}
	// fungsi setDataForm
	function changeForm(value, key, subkey = null) {
		if (typeof value === "string") value = value.toUpperCase();
		if (subkey !== null)
			setFormData((prevState) => ({
				...prevState,
				[subkey]: { ...prevState[subkey], [key]: value },
			}));
		else setFormData((prevState) => ({ ...prevState, [key]: value }));
	}

	//  get data produk
	async function getDataProduct() {
		setReqStatement(true)
		let kat = paramUrl.type;

		try {
			const { data, statusCode, status, remark, statusText } = await doGet({
				url: `/produk-deposit/deposito`,
				service: "cif"
			});
			if (statusCode === 200) {
				if (status === "00") {
					setReqStatement(false)
					setDataProduk(data?.list)
				}
				else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
			} else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
		} catch (error) {
			setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data produk`, header: "Error", callBack: () => null });
		}

	}
	function changeProduct(value) {
		// changeForm(value, "produkTabungan")
		const productDetil = dataProduk.find(v => v.kode_produk === value);
		setFormData(prevState => ({
			...prevState,
			produkTabungan: value,
			valuta: productDetil?.kode_valuta || "",
			_rekeningDeposito: {
				...prevState._rekeningDeposito,
			},
			_informasiLain: {
				...prevState._informasiLain,
				nisbahZakat: productDetil?.presentase_zakat_bagi_hasil || 0,
				nisbahPajak: productDetil?.tarif_pajak || 0,
				nisbahAkhir: productDetil?.nisbah_bonus_dasar || 0,
				nisbahInfo: productDetil?.nisbah_spesial_max || 0,

			}

		}))
	}
	async function cekNisbah() {
		setLoading(prevState => ({ msg: "Checking Nisbah Spesial ...", show: true }));
		const nisbahMsg = formData?.nisbah_spesial_max;
		setDataAlert({ show: true, body: "Maaf nilai nisbah melebihi dari nilai yang di tentukan...", title: `Nisbah spesial tidak valid max ${nisbahMsg}`, header: "Info", callBack: () => changeForm("0", "nisbah_spesial") });
		closeLoading();
	}

	async function alertNisbah(param) {
		setLoading(prevState => ({ msg: "Checking Nisbah Spesial ...", show: true }));
		const min = -formData?.nisbah_dasar
		const max = 100 - formData?.nisbah_dasar;

		if (param === "min") {
			setDataAlert({ show: true, body: "Maaf nilai nisbah kurang dari nilai yang di tentukan...", title: `Nisbah spesial tidak valid min ${min}`, header: "Info", callBack: () => changeForm("0", "nisbah_spesial") });
		} else {
			setDataAlert({ show: true, body: "Maaf nilai nisbah melebihi dari nilai yang di tentukan...", title: `Nisbah spesial tidak valid max ${max}`, header: "Info", callBack: () => changeForm("0", "nisbah_spesial") });
		}
		closeLoading();
	}



	const [modalBlokir, setModalBlokir] = useState(false);
	const [dataBlokir, setDataBlokir] = useState({

		"nomor_rekening": rekSelected.nomor_rekening,
		"nama_rekening": rekSelected.nama_rekening,
		"produk_rekening": rekSelected.produk_rekening,
		"valuta_rekening": rekSelected.valuta_rekening,
		"cabang_rekening": rekSelected.cabang_rekening,
		"saldo_rekening": rekSelected.saldo_rekening,
		"tanggal_kadaluarsa": rekSelected.tanggal_kadaluarsa,
		"alasan_blokir": "",
		"jenis_blokir": ""

	});

	function resetFormBlokir() {
		setDataBlokir({
			"nomor_rekening": "",
			"nama_rekening": "",
			"produk_rekening": "",
			"valuta_rekening": "",
			"cabang_rekening": "",
			"saldo_rekening": 0,
			"tanggal_kadaluarsa": "",
			"alasan_blokir": "",
			"jenis_blokir": ""
		})
	}

	function changeFormBlokir(value, key, subkey = null) {
		if (subkey !== null) setDataBlokir(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
		else setDataBlokir(prevState => ({ ...prevState, [key]: value }));
	}


	// open modal detail rekening
	function openBlokir(dataRekening) {
		setModalBlokir(true);
		getDetail(dataRekening.nomor_rekening, dataRekening.status);
	}

	function tutupBlokir() {
		setModalBlokir(false);
	}

	function confirmBlokir() {
		// let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
		let body = `Apakah anda yakin untuk blokir deposito nasabah ini, cek kembali form blokir. Lanjutkan ?`;
		setDataConfirm({
			show: true,
			body,
			witTextArea: false,
			header: "Konfirmasi",
			title: "",
			closeButton: false,
			footerButton: [
				{ name: "Ya", onClick: () => postBlokir(), className: "btn btn-sm btn-success" },
				{ name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
			]
		})
	}

	async function postBlokir() {
		// // console.log(dataBlokir)
		const userInfo = await keycloak.loadUserInfo();
		if (dataBlokir.jenis_blokir === "" || dataBlokir.jenis_blokir === null || dataBlokir.jenis_blokir === undefined) {
			return setDataAlert({ show: true, body: "Jenis Blokir belum di isi", title: `Form belum lengkap`, header: "Warning", callBack: () => null });
		}
		if (dataBlokir.alasan_blokir === "" || dataBlokir.alasan_blokir === null) {
			return setDataAlert({ show: true, body: "Alasan Blokir Belum di isi", title: `Form belum lengkap`, header: "Warning", callBack: () => null });
		}
		openLoading()
		var url = `/blokir`;
		try {

			const { statusCode, status, remark, statusText, data = {} } = await doPost({
				url: url,
				service: "acc",
				data:
				{
					...dataBlokir,
					alasan_blokir: dataBlokir.alasan_blokir,
					jenis_blokir: dataBlokir.jenis_blokir
				},
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					"x-user-id": userInfo.preferred_username,
				}
			});
			// // console.log(data);
			if (statusCode === 200) {
				if (status === "00") setDataAlert({
					show: true, body: `Proses Blokir Deposito Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => {
						resetFormBlokir();
						toggleModalMode();
						closeConfirm();
						tutupBlokir();
					}
				});
				else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
			} else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
		} catch (error) {
			setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
		}
		closeLoading()
	}

	const [modalBukaBlokir, setModalBukaBlokir] = useState(false);
	const [dataBukaBlokir, setDataBukaBlokir] = useState({

		"nomor_rekening": rekSelected.nomor_rekening,
		"nama_rekening": rekSelected.nama_rekening,
		"produk_rekening": rekSelected.produk_rekening,
		"valuta_rekening": rekSelected.valuta_rekening,
		"cabang_rekening": rekSelected.cabang_rekening,
		"saldo_rekening": rekSelected.saldo_rekening,
		"tanggal_kadaluarsa": rekSelected.tanggal_kadaluarsa,
		"alasan_blokir": rekSelected.keterangan_blokir,
		"jenis_blokir": ""

	});
	function resetFormBukaBlokir() {
		setDataBukaBlokir({
			"nomor_rekening": "",
			"nama_rekening": "",
			"produk_rekening": "",
			"valuta_rekening": "",
			"cabang_rekening": "",
			"saldo_rekening": 0,
			"tanggal_kadaluarsa": "",
			"alasan_blokir": "",
			"jenis_blokir": ""
		})
	}
	// function changeFormBukaBlokir(value, key, subkey = null) {
	// 	if (subkey !== null) setDataBukaBlokir(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
	//     else setDataBukaBlokir(prevState => ({ ...prevState, [key]: value }));
	// }
	// open modal detail rekening
	function openBukaBlokir(dataRekening) {
		setModalBukaBlokir(true);
		getDetail(dataRekening.nomor_rekening, dataRekening.status);
	}
	function closeBukaBlokir() {
		setModalBukaBlokir(false);
	}
	function confirmBukaBlokir() {
		// let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
		let body = `Apakah anda yakin untuk membuka blokir deposito nasabah ini, cek kembali form buka blokir. Lanjutkan ?`;
		setDataConfirm({
			show: true,
			body,
			witTextArea: false,
			header: "Konfirmasi",
			title: "",
			closeButton: false,
			footerButton: [
				{ name: "Ya", onClick: () => postBukaBlokir(), className: "btn btn-sm btn-success" },
				{ name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
			]
		})
	}
	async function postBukaBlokir() {
		// // console.log(dataBukaBlokir)
		const userInfo = await keycloak.loadUserInfo();
		// if (dataBukaBlokir.jenis_blokir === "" || dataBukaBlokir.jenis_blokir === null || dataBukaBlokir.jenis_blokir === undefined){
		//     return setDataAlert({ show: true, body: "Jenis Blokir belum di isi", title: `Form belum lengkap`, header: "Warning", callBack: () => null });
		// }
		// if (dataBukaBlokir.alasan_blokir === "" || dataBukaBlokir.alasan_blokir === null ) {
		//     return setDataAlert({ show: true, body: "Alasan Blokir Belum di isi", title: `Form belum lengkap`, header: "Warning", callBack: () => null });
		// }
		openLoading()
		var url = `/blokir/buka`;
		// // console.log(dataBukaBlokir)
		try {

			const { statusCode, status, remark, statusText, data = {} } = await doPost({
				url: url,
				service: "acc",
				data:
				{
					...dataBukaBlokir
				},
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					"x-user-id": userInfo.preferred_username,
				}
			});
			if (statusCode === 200) {
				if (status === "00") setDataAlert({
					show: true, body: `Proses Buka Blokir Deposito Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => {
						resetFormBukaBlokir();
						toggleModalMode();
						closeConfirm();
						closeBukaBlokir();
					}
				});
				else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
			} else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
		} catch (error) {
			setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
		}
		closeLoading()
	}

	const [listKodeCabang, setListKodeCabang] = useState([]);

	async function getListKodeCabang() {
		try {
			let resp = await doGet({
				url: `/rpc/list-kode-cabang`,
				service: "rpc",
			});
			const { statusCode, remark = "", data = [], status = "01", statusText = "", message = "" } = resp
			// // console.log(resp)
			if (statusCode === 200) {
				if (status === "00") setListKodeCabang(data)
				else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => dataOtor.callBackSuccess() });
			} else setDataAlert({ show: true, body: `${remark}. ${message}`, title: `${statusText}`, header: "Error", callBack: () => null });
		} catch (error) {
			setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => dataOtor.callBackSuccess() });
		}
	}
	//Post Auditrail
	async function auditTrail() {
		const userInfo = await keycloak.loadUserInfo();
		// // console.log(userInfo)
		var url = `/useraudit/user-audit-trail`;
		try {
			const { statusCode, status, remark, statusText, data = {} } = await doPost({
				url: url,
				service: "acc",
				data: {
					user_id: userInfo.preferred_username,
					app_id: "BDS",
					terminal_ip: window.location.hostname,
					operation_code: "Get Data List",
					event_description: "Deposito/Data Deposito",
					info1: "-",
					info2: "-"
				},
				headers: {
					"x-user-id": userInfo.preferred_username,
					method: "POST"
				}
			});

			if (statusCode === 200) {
				if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
				else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
			} else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
		} catch (error) {
			setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
		}

	}
	return (
		<rekContext.Provider
			value={{
				rekState: {
					blokRekModalMode,
					dataAlert,
					dataConfirm,
					dataListRekening,
					filter,
					formData,
					formDataD,
					holdModalMode,
					isLoading,
					isValid,
					loadingDetil,
					modalCetakBilyet,
					modalCetakBilyetPrint,
					modalCetakBilyetUlang,
					modalCetakBilyetUlangPrint,
					modalDetailDeposito,
					modalUbahNisbah,
					otorMode,
					rekSelected,
					pejabat,
					paramUrl,
					dataDropDown,
					dataMarketing,
					modalBlokir,
					dataBlokir,
					modalBukaBlokir,
					dataBukaBlokir,
					modeReqStatement,
					listKodeCabang,
					formCetakUlang,
					disableCetakUlang,
					isOtor,
					dataInstansi
				},
				rekAction: {
					changeForm,
					changeShow,
					closeAlert,
					closeCetakBilyet,
					closeCetakBilyetUlang,
					closeConfirm,
					closeDetailDeposito,
					closeUbahNisbahDeposito,
					getDataList,
					nextPage,
					openCetakBilyet,
					openCetakBilyetPrint,
					openCetakBilyetUlang,
					openCetakBilyetUlangPrint,
					openDetailDeposito,
					openUbahNisbahDeposito,
					prevPage,
					resetFilter,
					responseOtor,
					setDataConfirm,
					setFilter,
					setOtorDesc,
					toggleModalMode,
					ubahNisbah,
					doCetak,
					doCetakUlang,
					setPejabat,
					changeProduct,
					cekNisbah,
					getDataProduct,
					openBlokir,
					tutupBlokir,
					changeFormBlokir,
					confirmBlokir,
					openBukaBlokir,
					closeBukaBlokir,
					confirmBukaBlokir,
					getListKodeCabang,
					sutPejabatUp,
					auditTrail,
					cekNomorBilyet,
					setFormCetakUlang,
					alertNisbah,
					confirmRCB,
					getDetailOtorRCB
					// changeFormBukaBlokir
				},
			}}>
			{children}
			<ModalBilyetDeposito />
			<ModalUlangBilyet />
		</rekContext.Provider>
	);
};

export default function useDeposito() {
	return useContext(rekContext);
}
