// import { DatePicker } from '@material-ui/pickers';
import DatePicker from 'react-datepicker';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

export default function ({
    // className = "",
    size = "l",
    onChange = (e) => null,
    value = {
        endDate: "",
        startDate: ""
    },
    dateFormat = "yyyy-MM-dd",
    maxDate = "",
    minDate = "",
    disabled = false,
    style ={}
}) {
    // console.log(value)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    function validDate(d) {
        var timestamp = Date.parse(d)
        return !isNaN(timestamp)
    }
    useEffect(function(){
        if(typeof value !== "object" || Array.isArray(value)){
            throw "value must be object with key startDate and endDate";
        }

        if(value.endDate !== ""){
            if(!validDate( moment(value.endDate, dateFormat.toUpperCase()).format("YYYY-MM-DD"))){
                throw "endDate is not valid"
            }
            setEndDate(
                new Date( moment(value.endDate, dateFormat.toUpperCase()) .format("YYYY-MM-DD"))
            )
        }
        if(value.startDate !== ""){
            if(!validDate(moment(value.startDate, dateFormat.toUpperCase()).format("YYYY-MM-DD"))){
                throw "startDate is not valid"
            }
            setStartDate(
                new Date( moment(value.startDate, dateFormat.toUpperCase()) .format("YYYY-MM-DD"))
            )
        }
    },[value])


    // useEffect(function(){

    //     if(value.endDate !== ""){
    //         if(!validDate( moment(minDate, dateFormat.toUpperCase()).format("YYYY-MM-DD"))){
    //             throw "minDate is not valid"
    //         }
    //     }
    //     if(value.startDate !== ""){
    //         if(!validDate(moment(maxDate, dateFormat.toUpperCase()).format("YYYY-MM-DD"))){
    //             throw "maxDate is not valid"
    //         }
    //     }
    // }, [])
    // useEffect(function(){
    //     onChange({
    //         startDate : moment(startDate).format(dateFormat.toUpperCase()),
    //         endDate : moment(endDate).format(dateFormat.toUpperCase())
    //     })
    // }, [startDate, endDate])


    function hanldeChange(start, end){
        setStartDate(
            start
        )
        setEndDate(
            end
        )
        onChange({
            startDate : moment(start).format(dateFormat.toUpperCase()),
            endDate : moment(end).format(dateFormat.toUpperCase())
        }, {
            startDate : start,
            endDate : end
        })
    }
    return (
        <>
            <div style={{
                width: "100%",
                display: "inline-flex",
                justifyContent: "space-around",
                alignContent: "space-between",
                alignItems: "center",
                ...style
                // paddingLeft: 6,
                // paddingRight: 6


            }}>
                <DatePicker
                    className={`form-control ${size === "s" ? "form-control-sm" : ""}`}
                    selectsStart
                    onChange={(date) => hanldeChange(date, endDate)}
                    startDate={value.startDate !== "" ? new Date( moment(value.startDate, dateFormat.toUpperCase()).format("YYYY-MM-DD") ): value.startDate}
                    endDate={value.endDate !== "" ? new Date( moment(value.endDate, dateFormat.toUpperCase()).format("YYYY-MM-DD") ): value.endDate}
                    minDate={
                        minDate !== "" ? new Date( moment(minDate, dateFormat.toUpperCase()).format("YYYY-MM-DD") ): minDate
                    }
                    selected={value.startDate !== "" ? new Date( moment(value.startDate, dateFormat.toUpperCase()).format("YYYY-MM-DD") ): value.startDate}
                    maxDate={value.endDate !== "" ? endDate : ( maxDate === "" ? maxDate : new Date( moment(maxDate, dateFormat.toUpperCase()).format("YYYY-MM-DD")) )}
                    placeholderText="From"
                    dateFormat={dateFormat}
                    disabled={disabled}
                />
                <span
                    style={{
                        padding: size === "s" ? "3px 10px" : 10,
                        background: "#f6f5f7",
                        textAlign: "center",
                        border: "1px solid #e4e6ef",
                        borderRadius: "2px",
                        fontWeight: "bolder"

                    }}
                > s/d </span>
                <DatePicker
                    className={`form-control ${size === "s" ? "form-control-sm" : ""}`}
                    selectsEnd
                    onChange={(date) => hanldeChange(startDate, date)}
                    startDate={value.startDate !== "" ? new Date( moment(value.startDate, dateFormat.toUpperCase()).format("YYYY-MM-DD") ): value.startDate}
                    endDate={value.endDate !== "" ? new Date( moment(value.endDate, dateFormat.toUpperCase()).format("YYYY-MM-DD") ): value.endDate}
                    selected={ value.endDate !== "" ? new Date( moment(value.endDate, dateFormat.toUpperCase()).format("YYYY-MM-DD") ): value.endDate}
                    // minDate={value.endDate !== "" ? new Date( moment(value.endDate, dateFormat.toUpperCase()).format("YYYY-MM-DD") ): value.endDate}
                    maxDate={maxDate !== "" ? new Date( moment(maxDate, dateFormat.toUpperCase()).format("YYYY-MM-DD") ): maxDate}
                    minDate={value.startDate !== "" ? startDate : ( minDate === "" ? minDate : new Date( moment(minDate, dateFormat.toUpperCase()).format("YYYY-MM-DD")) )}
                    placeholderText="To"
                    dateFormat={dateFormat}
                    disabled={disabled}
                />
            </div>
            {/* <ReactDatePickersaya  */}
        </>
    )
}