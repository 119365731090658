import React from 'react';
import { TableList } from '../../controls';
import { BMProvider } from './useBranchMonitor';


function BranchMonitor() {


    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Branch Monitoring</a>
                    </li>
                </ol>
            </nav>
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <TableList
                                withCard={false}
                                customToolbar={
                                    <ul class="nav justify-content-end">
                                        {/* <li class="nav-item "> */}
                                            <a class="nav-link " href="#">Open Branch (1)</a>
                                            <a class="nav-link " href="#">Open All Branch</a>
                                            <a class="nav-link " href="#">Close Branch (1)</a>
                                            <a class="nav-link " href="#">Close All Branch</a>
                                        {/* </li> */}
                                    </ul>
                                }
                                // onNextPage={aftAction.nextPage}
                                // onPrevPage={aftAction.prevPage}
                                // onprevPage={aftAction.prevPage}
                                // onChangeShow={aftAction.changeShow}
                                // defaultSelection = {verState.viewCheckBox} 
                                // onSelection = {(e, item) => item.status_otorisasi !=="Waiting" && verAction.setViewCheckBox(e)}
                                
                                isLoading={false}
                                data={[]}
                                withActions={false}
                                // actionItems={[]}
                                filter={true}
                                formsFilter={[
                                    {
                                        type: "text",
                                        placeholder: "Keywords",
                                        // onChange:(e) => aftAction.setFilter(prevState => ({ ...prevState, nomor_rekening:e})),
                                        // value:aftState.filter?.nomor_rekening
                                    }
                                ]}
                                selectionMode={1}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: 'Kode Cabang',
                                        field: 'kode_cabang',
                                        maxWidth: '100px',
                                    },
                                    {
                                        name: 'Nama Cabang',
                                        field: 'nama_cabang',
                                        // maxWidth: '250px',
                                    },
                                    {
                                        name: 'Status Akses',
                                        field: 'status_akses',
                                        maxWidth: '100px',
                                    }
                                ]
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default () => <BMProvider>
    <BranchMonitor />
</BMProvider>