import React, { useRef } from 'react';
import useDeposito from '../useDeposito';
import { Tab, Nav, Modal, Button } from 'react-bootstrap';
import DataPrint from '../components/dataPrint';
import Skeleton from 'react-loading-skeleton';
import ReactToPrint from 'react-to-print';


const CetakUlangBilyetPrint = () => {
    const { rekState, rekAction } = useDeposito();
    const refCetak = useRef(null);
    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalCetakBilyetUlangPrint} onHide={rekAction.closeCetakBilyetUlang} >
                <Modal.Header closeButton>
                    <Modal.Title>Print Ulang Bilyet Deposito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box mb-3">
                        <div className="row" ref={refCetak}>
                            <DataPrint />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <div className="row">
                        <div className="col"> */}
                            <button
                                onClick={() => rekAction.doCetakUlang()}
                                className="btn btn-success float-right ml-3">
                                Cetak Ulang
                            </button>
                            <button
                                onClick={() => rekAction.closeCetakBilyetUlang()}
                                className="btn btn-outline-danger float-right">
                                Batal
                            </button>

                        {/* </div>
                    </div> */}
                </Modal.Footer>
            </Modal >
            {/* end modal utama */}
        </>

    );
}

export default CetakUlangBilyetPrint;