import React, { useState } from 'react';
import useFormBadanUsaha from './useFormBadanUsaha';
import useFetch from '../../../../../hooks/useFetch';
import moment from 'moment';
import useAlamat from '../../../../../hooks/useAlamat';

const useRelasiNasabaah = () => {
    const { fbAction, fbState } = useFormBadanUsaha()
    const [formModal, setFormModal] = useState(false)
    const [formRelasi, setFormRelasi] = useState({
        // "IDRelasi":0,
        // "RnHubunganDenganNasabah": "",
        // "RnJabatanAtauPangkat": "",
        // "RnNomorNasabah": "",
        // "relasiNama": "",
        // "relasiAlamat": "",
        // "relasiProvinsi": "",
        // "relasiKotaKabupaten": "",
        // "relasiKecamatan": "",
        // "relasiKelurahan": "",
        // "relasiRw": "",
        // "relasiRt": "",
        // "relasiKodePos": "",
        // "relasiTeleponRumahKodeArea1": "", 
        // "relasiTeleponRumah1": "",  
        // "relasiNomorHandphone": "",
        // "relasiFaxKodeArea": "", 
        // "relasiFaxNomor": "",  
        // "relasiEmailAddress": "",
        // "relasiJenisIdentitas": "",
        // "relasiNomorIdentitas": "",
        // "relasiNomorNpwp": "",
        // "relasiKewarganegaraan": "",
        // "relasiNegaraAasal": "",
        // "relasiPekerjaan": "",  
        "RnHubunganDenganNasabah": "",
        "RnJabatanAtauPangkat": "",
        "RnNomorNasabah": "",
        "RnNamaIdentitas": "",
        "RnJenisNasabah": "",
        // "RnJenisKelamin" : "",
        // "RnAgama" : "",
        // "RnTempatLahir" : "",
        // "RnTglLahir": "",
        // "RnAlamat": "",
        // "RnRT": "",
        // "RnRW": "",
        // "RnKelurahan": "",
        // "RnKecamatan": "",
        // "RnKotaKabupaten": "",
        // "RnProvinsi": "",
        // "RnKodePos": "",
        // "RnTeleponRumah": "",
        // "RnHandphone": "",
        // "RnFax": "",
        // "RnEmail": "",
        // "RnJenisIdentitas": "",
        // "RnNomorIdentitas": "",
        // "RnNomorNPWP": "",
        // "RnKewarganegaraan": "",
        // "RnNegaraAsal": "",
        // "RnPekerjaan": "",
        // "RnRw": "",
        // "RnCodeTeleponRumah": "",
        // "RnCodeFax": "",
        "action": "",
        
    })
    const [isNasabah, setIsNasabah] = useState(false)
    const [mode, setMode] = useState("CREATE")
    // alamat 1
    const [districts, setDistricts] = useState([])
    const [villages, setvillages] = useState([])
    const [cities, setCities] = useState([])
    const [postalCodes, setPostalCodes] = useState([])

    const {
        provinces,
        getCities,
        getVillages,
        getPostalsCode,
        getDistrics
    } = useAlamat();

    // resetForm
    function resetForm(){
        setFormRelasi({
            // "IDRelasi":0,
            // "RnHubunganDenganNasabah": "",
            // "RnJabatanAtauPangkat": "",
            // "RnNomorNasabah": "",
            // "relasiNama": "",
            // "relasiAlamat": "",
            // "relasiProvinsi": "",
            // "relasiKotaKabupaten": "",
            // "relasiKecamatan": "",
            // "relasiKelurahan": "",
            // "relasiRw": "",
            // "relasiRt": "",
            // "relasiKodePos": "",
            // "relasiTeleponRumahKodeArea1": "", 
            // "relasiTeleponRumah1": "",  
            // "relasiNomorHandphone": "",
            // "relasiFaxKodeArea": "", 
            // "relasiFaxNomor": "",  
            // "relasiEmailAddress": "",
            // "relasiJenisIdentitas": "",
            // "relasiNomorIdentitas": "",
            // "relasiNomorNpwp": "",
            // "relasiKewarganegaraan": "",
            // "relasiNegaraAasal": "",
            // "relasiPekerjaan": "", 
            "RnHubunganDenganNasabah": "",
            "RnJabatanAtauPangkat": "",
            "RnNomorNasabah": "",
            "RnNamaIdentitas": "",
            "RnJenisNasabah": "",
            // "RnJenisKelamin" : "",
            // "RnAgama" : "",
            // "RnTempatLahir" : "",
            // "RnTglLahir": "",
            // "RnAlamat": "",
            // "RnRT": "",
            // "RnRW": "",
            // "RnKelurahan": "",
            // "RnKecamatan": "",
            // "RnKotaKabupaten": "",
            // "RnProvinsi": "",
            // "RnKodePos": "",
            // "RnTeleponRumah": "",
            // "RnHandphone": "",
            // "RnFax": "",
            // "RnEmail": "",
            // "RnJenisIdentitas": "",
            // "RnNomorIdentitas": "",
            // "RnNomorNPWP": "",
            // "RnKewarganegaraan": "",
            // "RnNegaraAsal": "",
            // "RnPekerjaan": "",
            // "RnRw": "",
            // "RnCodeTeleponRumah": "",
            // "RnCodeFax": "",
            "action": "",
        })
    }

    const { doGet } = useFetch();

    // close Modal
    function closeModal() { setFormModal(false) }
    // open Modal Add
    function openModalAdd() {
        resetForm(); 
        setFormModal(true);
    }
    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        if (typeof value === "string") value = value.toUpperCase()
        if (subkey !== null) setFormRelasi(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormRelasi(prevState => ({ ...prevState, [key]: value }));
    }
    function del(key) {
        const currentData = fbState.formData?._relasiNasabah;
        currentData[key]['action'] = 'D'
        fbAction.changeForm(currentData, "_relasiNasabah");
    }

    function openDetil(key, m = "DETAIL") {
        setMode(m)
        let data = { ...fbState.formData?._relasiNasabah?.[key], key };
        setFormModal(true)
        setFormRelasi(data);
        if (!data)
            resetForm()
    }
    function cekNasabah(e) {
        setFormRelasi(prevState => ({
            ...prevState,
            RnNomorNasabah: ""
        }))
        setIsNasabah(e)
    }

    async function getCariNasabah() {
        const cif = formRelasi.RnNomorNasabah;
        try {
            const resp = await doGet({
                url: `/nasabahumum/${cif}`,
                service: "cif"
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    const {nasabahBadanUsaha} = data;
                    setFormRelasi(prevState => ({
                        ...prevState,
                            // "RnHubunganDenganNasabah": nasabahBadanUsaha.RnHubunganDenganNasabah,
                            // "RnJabatanAtauPangkat": nasabahBadanUsaha.RnJabatanAtauPangkat,
                            // "RnNomorNasabah": nasabahBadanUsaha.RnNomorNasabah,
                            "RnNamaIdentitas": nasabahBadanUsaha?.personalIdHolderName?.toUpperCase() || '',
                            "RnJenisNasabah": nasabahBadanUsaha?.personalType?.toUpperCase() || '',
                            // "RnTempatLahir": nasabahBadanUsaha?.birthPlace?.toUpperCase() || '',
                            // "RnTglLahir": moment(nasabahBadanUsaha?.birthDate, "DD-MM-YYYY").format("YYYY-MM-DD") || '',
                            // "RnJenisKelamin": nasabahBadanUsaha?.gender?.toUpperCase() || '',
                            // "RnAgama": nasabahBadanUsaha?.personalReligion?.toUpperCase() || '',
                            // "RnAlamat": nasabahBadanUsaha?.ktpAddress?.toUpperCase() || '',
                            // "RnProvinsi": nasabahBadanUsaha?.ktpProvince?.toUpperCase() || '',
                            // "RnKotaKabupaten": nasabahBadanUsaha?.ktpCity?.toUpperCase() || '',
                            // "RnKecamatan": nasabahBadanUsaha?.ktpSubDistrict?.toUpperCase() || '',
                            // "RnKelurahan": nasabahBadanUsaha?.ktpVillage?.toUpperCase() || '',
                            // "RnRT": nasabahBadanUsaha?.ktpRw?.toUpperCase() || '',
                            // "RnRW": nasabahBadanUsaha?.ktpRt?.toUpperCase() || '',
                            // "relasiTeleponRumahKodeArea1": nasabahBadanUsaha?.pejabatTeleponKodeArea || '', 
                            // "RnTeleponRumah": nasabahBadanUsaha?.homePhone || '',  
                            // "RnHandphone": nasabahBadanUsaha?.mobilePhone2 || '',
                            // "RnCodeFax": nasabahBadanUsaha?.relasiFaxKodeArea || '', 
                            // "RnFax": nasabahBadanUsaha?.pejabatFaxNomor || '',  
                            // "RnEmail": nasabahBadanUsaha?.emailAddress?.toUpperCase() || '',
                            // "RnJenisIdentitas": nasabahBadanUsaha?.personalIdType?.toUpperCase() || '',
                            // "RnNomorIdentitas": nasabahBadanUsaha?.personalIdNumber || '',
                            // "RnNomorNPWP": nasabahBadanUsaha?.personalNpwpNumber || '',
                            // "RnKewarganegaraan": nasabahBadanUsaha?.personalCitizenship?.toUpperCase() || '',
                            // "RnNegaraAsal": nasabahBadanUsaha?.ktpCountry?.toUpperCase() || '',
                            // "RnPekerjaan": nasabahBadanUsaha?.personalOccupation?.toUpperCase() || '', 
                    }));
                }
                else fbAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else fbAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            fbAction.setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        } 
    }

    function save() {
        if (formRelasi?.RnHubunganDenganNasabah === '' || formRelasi?.RnHubunganDenganNasabah === undefined 
            || formRelasi?.RnHubunganDenganNasabah === 'undefined' || formRelasi?.RnHubunganDenganNasabah === 0) {
            fbAction.setDataAlert({ show: true, body: "Hubungan Dengan Nasabah belum dipilih", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        } 
        if (formRelasi?.RnJabatanAtauPangkat === '' || formRelasi?.RnJabatanAtauPangkat === undefined 
            || formRelasi?.RnJabatanAtauPangkat === 'undefined' || formRelasi?.RnJabatanAtauPangkat === 0) {
            fbAction.setDataAlert({ show: true, body: "Jabatan / Pangkat belum dipilih", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        if (formRelasi?.RnNomorNasabah === '' || formRelasi?.RnNomorNasabah === undefined 
            || formRelasi?.RnNomorNasabah === 'undefined' || formRelasi?.RnNomorNasabah === 0) {
            fbAction.setDataAlert({ show: true, body: "Nomor Nasabah belum  diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else {
            const currentData = fbState.formData?._relasiNasabah;
            if (mode === "CREATE") {
                formRelasi['action']= 'C'
                if (currentData === undefined){
                    const newData = [formRelasi];
                    // const newData = [{
                    //     "action": 'C',
                    //     "RnHubunganDenganNasabah": formRelasi?.RnHubunganDenganNasabah,
                    //     "RnJabatanAtauPangkat": formRelasi?.RnJabatanAtauPangkat,
                    //     "RnNomorNasabah": formRelasi?.RnNomorNasabah
                    // }];
                    fbAction.changeForm(newData, "_relasiNasabah");
                } else {
                    const newData = [...currentData, formRelasi];
                    // const newData = [...currentData, {
                    //     "action": 'C',
                    //     "RnHubunganDenganNasabah": formRelasi?.RnHubunganDenganNasabah,
                    //     "RnJabatanAtauPangkat": formRelasi?.RnJabatanAtauPangkat,
                    //     "RnNomorNasabah": formRelasi?.RnNomorNasabah
                    // }];
                    fbAction.changeForm(newData, "_relasiNasabah");
                }
            }
            else if (mode === "EDIT") {
                formRelasi['action']= 'U'
                const newDataForm = { ...formRelasi }
                delete newDataForm.key;
                currentData[formRelasi.key] = newDataForm;
                fbAction.changeForm(currentData, "_relasiNasabah");
            }
            closeModal()
        }
    }
     // get province_code
     async function getProvinceCode(province_name) {
        if (provinces !== null && provinces !== undefined) {
            const data = provinces?.find(v => {
                return v.province_name === province_name
            });
            return data?.province_code || "";
        }
    }
    // get cities Relasi Nasabah
    async function getingCitiesNasabah(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCities(data);
    }
    // // get districts Relasi Nasabah
    // async function getingDistrictNasabah(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistricts(newData);
    // }
    // // get village Relasi Nasabah
    // async function getingVillagesNasabah(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillages(newData);
    // }
    // // get postalcode Relasi Nasabah
    // async function getingPostalCodesNasabah(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodes(newData);
    // }
    return {
        pRState: {
            formModal,
            formRelasi,
            provinces,
            districts,
            villages,
            cities,
            postalCodes,
            isNasabah
           
        },
        pRAction: {
            closeModal,
            openModalAdd,
            setFormRelasi,
            getCariNasabah,
            save,
            openDetil,
            cekNasabah,
            del,
            changeForm,
            getCities,
            getVillages,
            getPostalsCode,
            getDistrics,
            getProvinceCode,
            getingCitiesNasabah,
            // getingDistrictNasabah,
            // getingVillagesNasabah,
            // getingPostalCodesNasabah,
            
        }
    };
}

export default useRelasiNasabaah;