import React, { createContext, useContext, useState } from 'react';

const modalAlertContext = createContext({
    modalAction: {},
    modalState: {}
});

export const ModalAlertProvider = function ({ children }) {

    const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false });
    const [loading, setLoading] = useState({ show: false, msg: "Please Wait ..." });
    const [
        confirmCustom, 
        setConfirmCustom
    ] = useState({ 
        show: false, 
        body: "", title: "", 
        header: null, 
        footerButton: [], 
        closeButton: false,
    });

    
    // close Alert
    function closeAlert() { setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null }) }
    // closeConfirm
    function closeConfirm() { setDataConfirm({ show: false, body: "", header: null, title: "", footerButton: [], closeButton: true }) }
    // close Loading
    function closeLoading() { setLoading(prevState => ({ msg: "Please Wait ...", show: false })) }

    function openLoading(msg = "Please Wait ...") { setLoading({ show:true, msg }) }

    // close confirm custom
    function closeConfirmCustom(){
        setConfirmCustom({ 
            show: false, 
            body: "", 
            title: "", 
            header: null, 
            footerButton: [], 
            closeButton: false, 
        })
    }

    // open Alert Custom
    function openAlertCustom(alertProps, id="show"){
        setConfirmCustom(prevState => ({
            ...prevState.body,
            ...alertProps,
            [id] : true
        }))
    }
    return (
        <modalAlertContext.Provider value={{
            modalState: {
                dataAlert,
                dataConfirm,
                loading,
                confirmCustom
            },
            modalAction: {
                closeAlert,
                closeConfirm,
                closeLoading,
                setDataAlert,
                setDataConfirm,
                setLoading,
                closeConfirmCustom,
                openAlertCustom,
                openLoading
            },
        }}>{children}</modalAlertContext.Provider>
    )
}

export default function() { return useContext(modalAlertContext) }