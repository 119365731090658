import moment from 'moment';
import React from 'react';
import { createForm } from '../../../../../../src/module/createFormList';
import { FormRegistration } from '../../../../controls';
import usePembayaranAngsuran from './usePembayaranAngsuran';

const DataAngsuran = () => {

    const { paState, paAction } = usePembayaranAngsuran();
    const { formData = {}, formEditable = false } = paState;
    const {listDropdown} = ({
        
          "listDropdown": [
            {
              "id": "6229",
              "key": "eJenisPembayaran",
              "reference_code": "P",
              "reference_desc": "POKOK"
            },
            {
              "id": "6230",
              "key": "eJenisPembayaran",
              "reference_code": "M",
              "reference_desc": "MARGIN"
            },
            {
                "id": "6231",
                "key": "eJenisPembayaran",
                "reference_code": "A",
                "reference_desc": "ANGSURAN"
              }
          ]
        
      }) 
    return (
        <>
            <FormRegistration
                itemsForm={[
                    // Informasi Lain
                    {
                        name: "Informasi Rekening",
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'No Rekening',
                                                    type: 'number',
                                                    placeholder: 'masukan nomor rekening',
                                                    value: paState.formData?.nomor_rekening,
                                                    onChange: (value) => paAction.changeForm(value,"nomor_rekening"),
                                                    required: true,
                                                    disabled: !paState.formEditable,
                                                    // onEnter:(v) => paAction.cekNasabah(v),
                                                    onFocusOut:(v) => paAction.cekNasabah(v)
                                                })
                                            }
                                        </div>
                                        {/* <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nama Rekening',
                                                    type: 'text',
                                                    placeholder: 'Nama Nasabah',
                                                    value: paState.formData?.info_transaksi?.nama_rekening,
                                                    onChange: (value) => paAction.changeForm(value, "nama_rekening","info_transaksi"),
                                                    disabled: true,
                                                    required: false,
                                                })
                                            }
                                        </div> */}
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        {/* <div className="col-5">
                                            {
                                                createForm({
                                                    label: 'No Rekening',
                                                    type: 'number',
                                                    placeholder: 'masukan nomor rekening',
                                                    value: paState.formData?.nomor_rekening,
                                                    onChange: (value) => paAction.changeForm(value,"nomor_rekening"),
                                                    required: true,
                                                    disabled: !paState.formEditable,
                                                    // onEnter:(v) => paAction.cekNasabah(v),
                                                    onFocusOut:(v) => paAction.cekNasabah(v)
                                                })
                                            }
                                        </div> */}
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nama Rekening',
                                                    type: 'text',
                                                    placeholder: 'Nama Nasabah',
                                                    value: paState.formData?.info_transaksi?.nama_rekening,
                                                    onChange: (value) => paAction.changeForm(value, "nama_rekening","info_transaksi"),
                                                    disabled: true,
                                                    required: false,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: 'Nominal Angsuran',
                                type: 'currency',
                                placeholder: 'Masukan Nominal Angsuran',
                                required: true,
                                disabled: !paState.formEditable,
                                value: paState.formData?.nominal,
                                onChange: (value) => paAction.changeForm(value,"nominal"),
                            },
                            {
                                rows: 1,
                                label: "Jenis Pembayaran",
                                type: "select",
                                required: true,
                                placeholder: "Pilih Jenis Pembayaran",
                                options: listDropdown,
                                key : "reference_code",
                                display : "reference_desc",
                                value:paState.formData?.info_transaksi?.jenis_pembayaran,
                                onChange: (value) => paAction.changeForm(value, "jenis_pembayaran", "info_transaksi"),
                                disabled: !paState.formEditable 
                            },
                            {
                                rows: 2,
                                label: "Akad Pembayaran",
                                type: "text",
                                required: false,
                                placeholder: "Masukan Akad Pembayaran",
                                value:paState.formData?.info_transaksi?.akad,
                                onChange: (value) => paAction.changeForm(value, "akad","info_transaksi"),
                                disabled: true
                            },
                            {
                                rows: 2,
                                label: "Produk Pembayaran",
                                type: "text",
                                required: false,
                                placeholder: "Produk Pembayaran",
                                value:paState.formData?.info_transaksi?.prod_pembiayaan ,
                                onChange: (value) => paAction.changeForm(value, "prod_pembiayaan","info_transaksi"),
                                disabled: true
                            },
                            {
                                rows: 2,
                                label: "Angsuran ke-",
                                type: "text",
                                required: false,
                                placeholder: "Masukan Angsuran",
                                value: paState.formData?.info_transaksi?.sequence_number,
                                onChange: (value) => paAction.changeForm(value, "sequence_number","info_transaksi"),
                                disabled: true
                            },
                            {
                                rows: 2,
                                label: "Margin",
                                type: "currency",
                                required: false,
                                placeholder: "Nilai Margin",
                                value : parseFloat(paState.formData?.info_transaksi?.margin).toFixed(2),
                                onChange: (value) => paAction.changeForm(value, "margin","info_transaksi"),
                                disabled: true
                            },
                            {
                                rows: 2,
                                label: "Pokok",
                                type: "currency",
                                required: false,
                                placeholder: "Nilai Pokok",
                                value : parseFloat(paState.formData?.info_transaksi?.pokok).toFixed(2),
                                onChange: (value) => paAction.changeForm(value, "pokok","info_transaksi"),
                                disabled: true
                            },
                            {
                                rows: 2,
                                label: "Total Angsuran",
                                type: "currency",
                                required: false,
                                placeholder: "Masukan Total Pembiayaan",
                                value: (parseFloat(paState.formData?.info_transaksi?.pokok) + parseFloat(paState.formData?.info_transaksi?.margin)).toFixed(2),
                                // onChange: (value) => paAction.changeForm(value, "sequence_number","info_transaksi"),
                                disabled: true
                            },
                        ]
                    },
                ]}
            />
        </>
    );
}

export default DataAngsuran;