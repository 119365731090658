// import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { TableList } from "../../../../controls";
import useOtorTeller,{OTProvider} from "./useOtorTeller";
import { AKLProvider } from "../../kas_teller/ambil_kas_teller_lain/useAmbilKasLain"; 
import useKeycloak from "../../../../../hooks/keycloak.module";
import { addCommas } from "../../../../../module";
function OtorTeller() {

    const { otState,otAction } = useOtorTeller();
    const { keycloak } = useKeycloak()
    return(
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Informasi Otorisasi Teller</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Otorisasi Teller 
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-md-12">
                    <div className="card" >
                        <div className="card-header" >
                            <div className="card-title" >
                            Data Otorisasi Entry Data
                            </div>
                            {/* { */}
                                
                                    <button 
                                        // disabled={otState.dataCloseBranch !== null}
                                        onClick={()=>otAction.refresh()}
                                        style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                        className="btn btn-outline-secondary btn-sm float-right">
                                        <i className="fa fa-undo-alt" > </i> Refresh
                                    </button>
                            {/* } */}
                        </div>
                        <div className="card-body" >
                            <TableList
                                withCard={false}
                                minHeight="450px"
                                maxHeight="500px"
                                onChangeShow={otAction.changeShow}
                                onNextPage={otAction.nextPage}
                                onPrevPage={otAction.prevPage}
                                withActions={false}
                                actionItems={[]}
                                // selectionMode = {1}
                                // fieldSelectionKey = 'id_transaksi'
                                // onSelection = {(e, item) => item.status_otorisasi !=="Waiting" && otAction.setViewCheckBox(e)}
                                // defaultSelection = {otState.viewCheckBox}
                                columns={[
                                        {
                                            name: 'Id Transaksi',
                                            field: 'id_transaksi',
                                        },
                                        {
                                            name: 'User Id Pengirim',
                                            field: 'user_id_tlr_db',
                                        },
                                        {
                                            name: 'User Id Penerima',
                                            field: 'user_input',
                                        },
                                        {
                                            name: 'Nominal',
                                            field: 'nominal',
                                            onRender: (item) => addCommas(parseFloat(item.nominal).toFixed(2))
                                        },
                                        {
                                            name: 'Jenis Transaksi',
                                            field: 'jenis_transaksi',
                                        },
                                        {
                                            name: 'Tanggal',
                                            field: 'tanggal_input',
                                        },
                                        {
                                            name: 'Nomor Referensi',
                                            field: 'nomor_referensi',
                                        },
                                        {
                                            name: 'Rekening Debet',
                                            field: 'nomor_rekening_debet',
                                        },
                                        {
                                            name: 'Rekening Credit',
                                            field: 'nomor_rekening_credit',
                                        },
                                        {
                                            name: "",
                                            onRender: (item) => item.id_transaksi !== null &&
                                            <>
                                                <button
                                                    onClick={() => otAction.process(item.id_transaksi, item.jenis_transaksi, item)}
                                                    style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                                    className="btn btn-sm btn-outline-success"
                                                >
                                                    <b>Proses</b>
                                                </button>
                                                {/* <button
                                                    onClick={() => otAction.confirmPost(parseInt(item.id_transaksi),true)}
                                                    style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                                    className="btn btn-sm btn-outline-success"
                                                >
                                                    <b>Acceppt</b>
                                                </button>
                                                <button
                                                    onClick={() => otAction.confirmPost(parseInt(item.id_transaksi),false)}
                                                    style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                                    className="btn btn-sm btn-outline-danger"
                                                >
                                                    <b>Reject</b>
                                                </button> */}
                                            </>
                                        }
                                 ]}
                                 data={otState.dataList}
                                 isLoading={otState.isLoading}
                                 filter={false}
                                //  resetFilter={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default () =>
(
    <AKLProvider>
        <OTProvider>
            <OtorTeller/>
        </OTProvider>
    </AKLProvider>
) 
    