import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { NewLoading, TableList } from '../../../controls';
import useInfoTeller, { InfoTellerProvider } from './useInformasiTeller';
import Select from 'react-select';
import { useHistory } from 'react-router';
import ModalRegisTeller from './modal/modalRegistrasi';
// import { useKeycloak } from '@react-keycloak/web';
import { addCommas } from '../../../../module';
import useKeycloak from '../../../../hooks/keycloak.module';

function InfoTeller() {
    const { itState, itAction, rtState , rtAction } = useInfoTeller()
    const history = useHistory()
    const { keycloak } = useKeycloak()
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Informasi Teller</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Data Kas Teller
                    </li>
                </ol>
            </nav>

            {/* <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="car-body text-center m-4">
                            {itState.isLoading ? <Skeleton height="30px" width="60%"/> :<h3>Total Saldo Kas Teller : <span className="text-base">RP. {itState.saldoKas?.toLocaleString() || 0} </span></h3>}
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                               <strong>Data Teller</strong> 
                            </div>
                            {[

                                ...(keycloak.hasRealmRole("spv teller", "spv cs", "MANAGER OPERASIONAL")?[
                                    <button
                                    onClick={()=>rtAction.openregistrasiTeller()}
                                    style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                    className="btn btn-outline-info float-right"
                                    >
                                        <p>Registrasi Teller</p>
                                    </button>
                                ]:[])
                            ]}
                            
                        </div>
                        {/* <div className="title">
                        <div className="mt-2 ml-4">
                            <div className="row">
                                <div className="col-3 align-self-center bns-form">
                                    <div className="form-group">
                                        <label>Cari Data Berdasarkan :</label>
                                        <select name="" id="" value={itState.filter.kode_cabang} onChange={(e) => itAction.setFilter(prevState => ({ ...prevState, kode_cabang: e.target.value }))} className="form-control">
                                            <option>Pilih Kantor Cabang</option>
                                            <option value={'001'}>Pusat</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 align-self-center bns-form">
                                    <div className="form-group pt-4 mt-1">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Cari NIP, Nama..."
                                            // value={itState.filter.tgl_akhir}
                                            // onChange={(value) => {
                                            //     itAction.setFilter(prevState => ({ ...prevState, tgl_akhir: value.target.value }));
                                            // }}
                                            
                                        />
                                    </div>
                                </div>
                                <div className="col-3 align-self-center bns-form">
                                    <div className="form-group pt-4 mt-1">
                                        <button onClick={itAction.getDataList} className="btn btn-outline-success">
                                            Cari
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div> */}
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                minHeight="450px"
                                maxHeight="500px"
                                onChangeShow={itAction.changeShow}
                                onNextPage={itAction.nextPage}
                                onPrevPage={itAction.prevPage}
                                withActions={false}
                                columns={[
                                    {
                                        name: 'Kode Rekening',
                                        field: 'nomor_rekening',
                                    },
                                    {
                                        name: 'User ID',
                                        field: 'user_id',
                                    },
                                    {
                                        name: 'Nama Teller',
                                        field: 'nama_user',
                                    },
                                    {
                                        name: 'Kantor Cabang',
                                        field: 'nama_cabang',
                                    },
                                    {
                                        name: 'Kode Valuta',
                                        field: 'kode_valuta',
                                    },
                                    {
                                        name: 'Status',
                                        field: 'status_buka',
                                    },
                                    {
                                        name: 'Saldo Kas Teller (Rp)',
                                        field: 'saldo',
                                        className:"text-right",
                                        onRender: (item) => addCommas(parseFloat(item.saldo)?.toFixed(2))
                                        
                                    },
                                    {
                                        name: '',
                                        field: 'act',
                                        minWidth: '50px',
                                        className: "text-right pr-0",
                                        onRender: (item) => {
                                            return (
                                                <>
                                                    <button
                                                        onClick={() => history.push('/supervisor-teller/informasi-teller/'+item.user_id+'/'+item.kode_valuta+"/"+item.kode_cabang)}
                                                        
                                                        style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                                        className="btn btn-sm btn-outline-success mr-1"
                                                    >
                                                        <p>Lihat Info Kas Teller</p>
                                                    </button>
                                                    <button
                                                        onClick={()=>itAction.confirmTrx(item.nomor_rekening)}
                                                        style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                                        className="btn btn-sm btn-outline-info"
                                                    >
                                                        <p>Buka Kas</p>
                                                    </button>
                                                </>
                                            )
                                        }
                                    },
                                ]}
                                data={itState.dataList}
                                isLoading={itState.isLoading}
                                filter={false}
                                resetFilter={false}
                                pagination={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalRegisTeller/>
        </>
    )
}
export default () => <InfoTellerProvider><InfoTeller /></InfoTellerProvider>;