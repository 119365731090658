import React, { memo, useEffect, useState } from 'react';
import { useBasePageState } from './basePageContext';
import BodyPayment from './bodyPayment';
import CardPembayaran from './cardPayment';
import './css/loading.css'
import WalkInCostumer from './walkinCustomer';
import { useHistory } from 'react-router-dom';
import { AppInterfaces } from '../../../app.data';

const BasePagePay_Base = memo(({ options = [], title, openPembayaranCard, onClickHandler, store, ...props }) => {
    const [globalState, dispatch, actions] = useBasePageState();
    const [err, setErr] = useState(false);
    let history = useHistory();
    // console.log(props)
    useEffect(() => {
        const choice_ = options[0];
        actions.setState({
            choice: choice_.value,
            optionSelected: choice_,
            options
        })
    }, [])
    
    return (
        <React.Fragment>
            <div className="px-3 pt-3" >
                <WalkInCostumer />
                <div className="row">
                    <div className="col-md-8 pt-2">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className={`card m-0 card-body ${options.length > 1 ? "p-4" : ""}`}>
                                        <div className="row">
                                            {globalState.options.length > 1 && <div className="col">
                                                {
                                                    globalState.options.map((opt, key) => (
                                                        <div className="form-check form-check-inline" key={key}>
                                                            <input disabled={globalState.isSukses || globalState.isProses} className="form-check-input" checked={globalState.choice === opt.value} onChange={(e) => actions.setTipe(opt.value)} type="radio" name="choice" id={key} value={opt.value} />
                                                            <label className="form-check-label" for={key}>{opt.display}</label>
                                                        </div>
                                                    ))
                                                }

                                            </div>}
                                        </div>
                                        <div className={`row ${options.length > 1 ? "mt-3" : ""}`}>
                                            <div className="col">
                                                {globalState.optionSelected != null && <BodyPayment />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 pt-2">
                        { store.openPembayaranCard && (
                            <CardPembayaran paymentCardData={props.paymentCardData} onChangeCash={props.onChangeCash} onChangeRef={props.onChangeRef} />
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

const BasePagePay = AppInterfaces.appLoad(BasePagePay_Base)
export default BasePagePay;
