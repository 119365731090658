import React, { memo, useEffect, useRef, useState } from 'react';
import { AppAction, AppInterfaces } from '../../../app.data';
import { TableList } from '../../controls';
import { useLocation, useHistory } from 'react-router-dom';
import { thousandSeparator } from '../../../utils';
import useFetch from '../../../hooks/useFetch';
import { addCommas } from '../../../module';

export default AppInterfaces.appLoad(
    memo(function ({ store, methods, dispatch }) {
        const appAction = useRef(null);
        const location = useLocation();
        const { doGet } = useFetch();
        let history = useHistory();
        const [data, setData] = useState({isLoading: false, list: []});

        useEffect(async () => {
            // console.log(location)
            setData({...data, isLoading: true})
            let resp = await doGet({
                url: `/nasabah/portofolio/${location.state}`
            });
            setData({isLoading: false, ...resp, list: resp.status === "00" ? resp.data?.portfolio?.list : [], cust: resp.status === "00" ? resp.data?.portfolio?.cust :{}})
            },[]
        );

        return (
            <>
                <AppAction ref={appAction} />
                <nav aria-label="breadcrumb" className="mb-3">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a className="text-primary-bds" href="#">Nasabah</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a className="text-primary-bds" href="#" onClick={()=>history.push('/data_nasabah/cif')}>Data Nasabah</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Portofolio Nasabah
                        </li>
                    </ol>
                </nav>
                <div className="row card mx-1">
                    <div className="col-md-12 py-4 px-3">
                        <table>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td width="11%" className="py-2 font-weight-bold">Nomor Nasabah</td>
                                    <td width="60%">:&nbsp;&nbsp;{data.cust?.nomor_nasabah}</td>
                                    <td width="10%" className="py-2 font-weight-bold" >Jenis Nasabah</td>
                                    <td>:&nbsp;&nbsp;{data.cust?.jenis_nasabah === "I" ? "Individu" : (data.cust?.jenis_nasabah === "K" ? "Badan Usaha" : "")}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Nama Nasabah</td>
                                    <td>:&nbsp;&nbsp;{data.cust?.nama_nasabah}</td>
                                    <td className="font-weight-bold">Cabang</td>
                                    <td>:&nbsp;&nbsp;{data.cust?.kode_cabang}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12">
                        <TableList
                            pagination={false}
                            withActions={false}
                            withCard={false}
                            columns={[
                                {
                                    name: 'Nomor Rekening',
                                    field: 'nomor_rekening',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Nama Rekening',
                                    field: 'nama_rekening',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Kode Valuta',
                                    field: 'kode_valuta',
                                    maxWidth: '50px',
                                    className: "text-center"
                                },
                                {
                                    name: 'Kantor Cabang',
                                    field: 'nama_cabang',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Jenis Rekening',
                                    field: 'jenis_rekening',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Saldo',
                                    field: 'saldo',
                                    maxWidth: '50px',
                                    className: "text-right",
                                    onRender: (item) => addCommas(parseFloat(item.saldo).toFixed(2))
                                },{
                                    name: 'Status',
                                    field: 'status',
                                    maxWidth: '50px',
                                    className: "text-center",
                                    onRender: (item) => item.status &&
                                        <span class={`badge ${item.status === "Aktif" ? "badge-success" : "badge-danger"}`}>
                                            {item.status}
                                        </span>
                                }
                            ]}
                            data={data.list}
                            isLoading={data.isLoading}
                            filter={false}
                        />
                    </div>
                </div>
            </>
        );
    })
);
