// import { useKeycloak } from '@react-keycloak/web';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
import { useModalAlert } from '../../../controls/modalAlert';
import UseregitrasiTeller from './useregitrasiTeller';
import useKeycloak from '../../../../hooks/keycloak.module';

const infoTellerContext = createContext({
   itState:{}, itAction:{} 
});
 
export const InfoTellerProvider = ({ children, otorModeR = false }) => {
   

    // // console.log(otorModeR)
    // const initDataBukaKas = {
    //     user_id: null,
    // }

    const {rtState,rtAction} = UseregitrasiTeller();
    const { doGet, doPost, wait } = useFetch();
    const { keycloak } = useKeycloak()
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({kode_cabang: '' })
    const [dataList, setListDataRekening] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [loadingDetil, setLoadingDetil] = useState(true);
    const [rekSelected, setRekSelected] = useState({});
    const [saldoKas, setSaldoKas] = useState(0)
    const [otorMode, setOtorMode] = useState(false)


    // // console.log(rtState)
    const [dataBlokRekening, setdataBlokRekening] = useState({
        "user_id": "",
        "listbukakas": [
        ]
    });

    useFetch(function() {
        // // console.log(otorModeR)
        setOtorMode(otorModeR);
    }, [otorModeR])
    
    // useEffect GetData
    useEffect(function () {
        !otorModeR && getDataList()
    }, [pagination])
    
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    function confirmTrx(nomor_rekening) {
        let body = `Buka teller dengan kode rekening ${nomor_rekening}?. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => bukaKas(nomor_rekening), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    // getData rekening list
    async function getDataList() {
        setIsLoading(true)
        var url = `/informasi/rekening-kas-cabang`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                // param: { 
                //     ...pagination
                //     // kode_cabang : filter.kode_cabang
                //     //page:pagination.page, limit:pagination.show 
                // },
                service: "trx"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    setListDataRekening(data);
                    // setSaldoKas(data?.saldo_kas_teller)
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    function resetFilter() {
        setFilter({
            keyword: ''
        })
    }
    // next pagination
    function nextPage({ page, limit }) {
        // // console.log(page)
        setPagination({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
// function post buka kas
async function bukaKas(kode_rekening) {
    const userInfo = await keycloak.loadUserInfo();
    // // console.log(userInfo)
    var url = `/kasteller/buka-by-koderek`;
    try {
        const { statusCode, status, remark, statusText, data = {} } = await doPost({
            url: url,
            service: "trx",
            data: kode_rekening,
            headers: {
                "x-user-id": userInfo.preferred_username,
                method: "POST"
            }
        });

        if (statusCode === 200) {
            if (status === "00") setDataAlert({ show: true, body: `Proses Buka Kas Berhasil`, title: ``, header: "Success", callBack: () => {
                closeConfirmCustom();
                closeLoading();
                closeConfirm();
                closeConfirm();
                getDataList();
            }});
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
        } else  if(statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    } catch (error) {
        setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    }
}
    return <infoTellerContext.Provider
        value={{
            itState: {
                dataList,
                isLoading,
                filter,
                loadingDetil,
                rekSelected,
                saldoKas,
                otorMode
            },
            itAction: {
                getDataList,
                nextPage,
                prevPage,
                changeShow,
                closeAlert,
                closeConfirm,
                setFilter,
                resetFilter,
                bukaKas,
                confirmTrx
            },
            rtState,rtAction
        }}
    >
        {children}
    </infoTellerContext.Provider>;
}


export default function useInfoTeller() { return useContext(infoTellerContext) };