import React from 'react';
import { createForm } from '../../../../../module/createFormList';

const RekeningDebit = () => {
    return (
        <>
            <h4 className="text-base">Rekening Debit</h4>
            <div className="row">
                <div className="col-12">
                    {
                        createForm({
                            type: "text",
                            label: "No. Rekening",
                            required: true
                        })
                    }
                </div>
                <div className="col-12">
                    {
                        createForm({
                            type: "text",
                            label: "Nama Nasabah",
                            disabled: true
                        })
                    }
                </div>
                <div className="col-3">
                    {
                        createForm({
                            type: "text",
                            label: "Valuta Rekening",
                            disabled: true
                        })
                    }
                </div>
                <div className="col-9">
                    {
                        createForm({
                            type: "text",
                            label: "Cabang Rekening",
                            disabled: true
                        })
                    }
                </div>
                <div className="col-12">
                    {
                        createForm({
                            type: "text",
                            label: "Produk",
                            disabled: true
                        })
                    }
                </div>
                <div className="col-12 pl-3 pr-3">
                    <button className="btn btn-outline-danger btn-sm col">
                        Lihat Data Grafis/Tanda Tangan
                    </button>
                </div>
            </div>

        </>
    );
}

export default RekeningDebit;