import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { TableList } from "../../../../controls";
import useDataNotif, { NotifProvider } from './useDataNotif';
import ModalDetilNotif from './modal/detilModal';
import { addCommas } from '../../../../../module';

function DataNotifPage() {
    const {
        nfAction,
        nfState
    } = useDataNotif();
    useEffect(function () {
        nfAction.auditTrail()
    }, [])
    let history = useHistory();
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3" >
                <ol className="breadcrumb" >
                    <li className="breadcrumb-item" >
                        <a className="text-priamry-bds" href="#">Tabungan & Giro</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Data Registrasi Notifikasi
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-12">
                    <div className="card" >
                        <div className="card-body" >
                            <TableList 
                                withCard={false}
                                onNextPage={nfAction.nextPage}
                                onPrevPage={nfAction.prevPage}
                                onprevPage={nfAction.prevPage}
                                onChangeShow={nfAction.changeShow}
                                isLoading={nfState.isLoading}   
                                data={nfState.dataList}
                                withActions={true}
                                actionItems={
                                    [
                                        // {
                                        //     name: "Lihat Detail Notif",
                                        //     onClick:(item)=>nfAction.openModalDetil(0, item)
                                        // },
                                        // {
                                        //     name: "Ubah Data Notif",
                                        //     onClick:(item)=>nfAction.openModalDetil(2, item)
                                        // },
                                        {
                                            name: "Lihat Detail Notif",
                                            withDivider: true,
                                            onClick: (e) => {
                                                let state = { dbId: e.id_notif}
                                                history.push(`/tabungan-giro/form-notif/detil`, state);
                                            },
                                        }, 
                                        {
                                            name: "Ubah Data Notif",
                                            withDivider: true,
                                            onClick: (e) => {
                                                let state = { dbId: e.id_notif}
                                                history.push(`/tabungan-giro/form-notif/edit`, state);
                                            },
                                        },
                                        
                                        // {
                                        //     name: "Non-aktifkan SMS Notif",
                                        //     withDivider: true,
                                        //     onClick: ({account_no, id_notif}) => {
                                        //         nfAction.setStatus("F", id_notif, account_no)
                                        //     },
                                        // },
                                        // {
                                        //     name: "Aktifkan SMS Notif",
                                        //     withDivider: true,
                                        //     onClick: ({account_no, id_notif}) => {
                                        //         nfAction.setStatus("T", id_notif, account_no)
                                        //     },
                                        // },
                                    ]
                                }
                                filter={true}
                                formsFilter={[
                                    {
                                        type:"text",
                                        placeholder:"No Rekening",
                                        onChange:(e) => nfAction.setFilter(prevState => ({ ...prevState, no_rek:e})),
                                        value:nfState.filter?.no_rek
                                    }
                                ]}
                                maxHeight="400px"
                                onFilter={
                                    nfAction.resetPagination
                                }
                                onResetFilter={nfAction.resetFilter}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    // {
                                    //     name: 'Name',
                                    //     field: 'nama_sub',
                                    //     maxWidth: '150px',
                                    // },
                                    {
                                        name: 'Nomor Rekening',
                                        field: 'account_no',
                                        maxWidth: '200px',
                                    },   
                                    {
                                        name: 'No Handphone',
                                        field: 'ch_sms',
                                        maxWidth: '200px',
                                    },
                                    {
                                        name: 'Min Transaksi Db.',
                                        field: 'db_min',
                                        onRender: (item) => addCommas( parseFloat (item.db_min).toFixed(2)),
                                        maxWidth: '300px',
                                    },
                                    {
                                        name: 'Min Transakasi Cr.',
                                        field: 'cr_min',
                                        onRender: (item) => addCommas( parseFloat (item.cr_min).toFixed(2)),
                                        maxWidth: '300px',
                                    },
                                    // {
                                    //     name: 'Status',
                                    //     field: 'active',
                                    //     maxWidth: '100px',
                                    // },
                                    // {
                                    //     name: 'Filter Transaksi',
                                    //     field: 'flag',
                                    //     maxWidth: '300px',
                                    // },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalDetilNotif/>
        </>
    )
}

export default () =><NotifProvider><DataNotifPage/></NotifProvider> 