import clsx from 'clsx';

const FormHeader = ({ children, className, style }) => {
    return (
        <div style={style} className={clsx('d-flex align-items-center', className)}>
            <h1 className="f-18 text-primary-1 whitespace-nowrap mr-3 tw-mb-0">{children}</h1>
            <div style={{ height: 1 }} className="bg-black-40 w-100"></div>
        </div>
    );
};

export default FormHeader;
