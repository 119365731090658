import { capitalize } from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import useFormRegisTabunganRencana, { FtbProvider } from './useFormRegisTabunganRencana';
import DataRekening from './dataRekening';
import InformasiLain from './informasiLain';
import { FrameRegistration, NewLoading } from '../../../../../controls';


function FgeIndex() {

    const { ftbAction, ftbState } = useFormRegisTabunganRencana();
    const { mode } = useParams()
    const history = useHistory()
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-2 mb-3">
                    <li className="breadcrumb-item color-primary">Tabungan & Giro / Registrasi Rekening</li>
                    <li className="breadcrumb-item">
                        <a href="#">Tabungan Rencana</a>
                    </li>
                    {/* <li className="breadcrumb-item">{capitalize(ftbState.paramUrl?.mode)}</li> */}
                </ol>
            </nav>
            <FrameRegistration
                manualDone={(mode !== "registration")}
                steps={[
                    // {
                    //     name: 'Data Rekening',
                    //     id: '0',
                    //     whiteSpace: 'nowrap',
                    //     isDone: (mode !== "registration"),
                    // },
                    // {
                    //     name: 'Informasi Lain',
                    //     id: '1',
                    //     whiteSpace: 'nowrap',
                    //     isDone: (mode !== "registration"),
                    // },
                ]}
                activeStep={ftbState.step}
                onStepClick={(e) => { if (mode !== "registration") { ftbAction.clickStep(e) } }}
            >
                <div id="step" dataStep={0}>
                    <DataRekening />
                </div>
                <div id="step" dataStep={1}>
                    <InformasiLain />
                </div>
                <div id="footer">
                    <div className="row">
                        <div className="col">
                            {
                                mode === "otorisasi" && (
                                    <button onClick={() => history.goBack()} className="btn btn-sm btn-outline-primary">
                                        Back
                                    </button>
                                )
                            }
                            {
                                ftbState.formEditable &&
                                <button 
                                onClick={ftbAction.resetForm}
                                className="btn btn-outline-danger">
                                    Reset
                                </button>
                            }
                            {/* <button
                                onClick={ftbAction.nextStep}
                                className={`
                                    btn btn-${ftbState.step === 0 ? "secondary" :
                                        "orange-primary"
                                    } mr-2 float-right
                                `}
                            >
                                Selanjutnya
                            </button>


                            <button
                                onClick={ftbAction.prevState}
                                className={`
                                    btn btn-${ftbState.step === 0 ? "secondary" :
                                        "orange-primary"
                                    } mr-2 float-right
                                `}
                            >
                                Sebelumnya
                            </button> */}
                            {
                                ftbState.paramUrl?.mode === "otorisasi" && (
                                    <div style={{
                                        width: "fit-content",
                                        float: "right",
                                        borderRight: "1px sold gray",
                                        margin: "0px 5px"
                                    }}>
                                        <button
                                            onClick={() => ftbAction.responseOtor("APPROVED")}
                                            className="btn btn-success mr-2">
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => ftbAction.responseOtor("REJECTED")}
                                            className="btn btn-danger mr-2">
                                            Reject
                                        </button>
                                    </div>
                                )
                            }
                            {
                                ftbState.formEditable &&
                                ftbState.step === 0 &&
                                <button disabled={
                                    ftbAction.validasi()
                                } 
                                    onClick={ftbAction.confirmPostNasabah} 
                                    className="btn btn-primary float-right mr-2">
                                    Simpan
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </FrameRegistration>

            {/* modal Alert */}
            <Modal show={ftbState.dataAlert.show} //onHide={ftbAction.closeAlert} 
                backdrop="static"
            >
                {
                    ftbState.dataAlert.header !== null && ftbState.dataAlert.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {ftbState.dataAlert.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <h5 style={{ textAlign: "center", margin: "5px", textTransform: "capitalize" }}>{ftbState.dataAlert.title}</h5>
                            <div className="box">
                                <p>
                                    {ftbState.dataAlert.body}
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            <button onClick={() => {
                                ftbAction.closeAlert();
                                if (ftbState.dataAlert.callBack !== null && ftbState.dataAlert.callBack !== undefined) ftbState.dataAlert.callBack();
                            }
                            } className="btn btn-primary btn-sm">
                                OK
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal alert */}
            {/* modal confirm */}
            <Modal show={ftbState.dataConfirm.show}

                backdrop="static"
            >
                {
                    ftbState.dataConfirm.header !== null && ftbState.dataConfirm.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {ftbState.dataConfirm.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {/* <h4 style={{ textAlign: "center" }}>{ftbState.dataConfirm.title}</h4> */}

                            {/* <div className="box"> */}
                            <h5>
                                {ftbState.dataConfirm.body}
                            </h5>
                            {
                                ftbState.dataConfirm.witTextArea && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm">
                                                <label>Keterangan :</label>
                                                <textarea
                                                    className="form-control form-control-sm"
                                                    value={ftbState.otorDesc}
                                                    onChange={(e) => ftbAction.setOtorDesc(e.target.v)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {/* </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            {
                                ftbState
                                    .dataConfirm
                                    .closeButton && <button onClick={ftbAction.closeAlert} className="btn btn-secondary btn-sm float-right mr-2">
                                    Close
                                </button>
                            }
                            {
                                ftbState
                                    .dataConfirm
                                    .footerButton?.map(
                                        ({
                                            className = "",
                                            onClick = () => null,
                                            style = {},
                                            name = "Ok" },
                                            i) => <button
                                                key={i}
                                                className={`${className} float-right mr-2`}
                                                onClick={onClick}
                                                style={style}
                                            >
                                                {name}
                                            </button>
                                    )
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal confirm */}
            {/* mdoal loading */}
            <Modal show={ftbState.loading.loadingPost?.show}
                backdrop="static"
            >
                <Modal.Body>
                    <div style={{
                        margin: "auto",
                        width: "fit-content",
                        padding: "5% 0%",
                        textAlign: "center",

                    }}>
                        <NewLoading width="100px" />
                        <h5 className="text-base">{ftbState.loading.loadingPost?.msg}</h5>
                    </div>
                </Modal.Body>
            </Modal>
            {/*  */}
        </>
    )
}
export default () => <FtbProvider><FgeIndex /></FtbProvider>