import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../../../hooks/keycloak.module';
import { addCommas } from '../../../../../module';

const skKas = createContext({
    skState: {}, skAction: {}
});

export function SKProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const { doGet, doPost, wait } = useFetch();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const location = useLocation();

    const [formData, setFormData] = useState({
        "nominal": 0,
        "nomor_referensi": "",
        "keterangan": "",
        "kode_valuta": "IDR",
        "info_transaksi": {
            "saldo_teller_before": 0,
            "saldo_teller_after": 0
        },
        "denominasi": [
            // {
            //     "nilai_denominasi": 100000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 50000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 20000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 10000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 5000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 2000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 1000,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 100,
            //     "jenis": "Lembar",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 1000,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 500,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 200,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 100,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 50,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
            // {
            //     "nilai_denominasi": 25,
            //     "jenis": "Koin",
            //     "jumlah_lembar_koin": 0
            // },
        ]
    })


    const { keycloak } = useKeycloak()
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams();
    const [otorDesc, setOtorDesc] = useState("")

    const [err, setErr] = useState(true);
    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            getDataDetail()
            // getDataPerorangan(location.state?.dbId);
        }else{
            getSaldo();
            getDenom();
        }
        // } 
        setFormEditable(paramUrl.mode !== "otorisasi");
    }, [paramUrl.mode])

    function sortingDenom(dataDenom = [], namaDenom){
        return dataDenom.filter(({jenis = ""}) => (
            jenis.toUpperCase() === namaDenom.toUpperCase()
        )).sort(function(a,b){
            return b.nilai_denominasi - a.nilai_denominasi ;
        })
    }

    async function getDenom() {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/brangkas`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userInfo.preferred_username,
                    kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    const denominasi = ( [ 
                        ...( sortingDenom(data.InfoDenominasiBrangkas || [], "lembar") || []),
                        
                        ...( sortingDenom(data.InfoDenominasiBrangkas || [], "koin") || []),
                        
                        ...( sortingDenom(data.InfoDenominasiBrangkas || [], "lembar (utle)") || []),
                    ] ).map(({
                        nilai_denominasi = 0, jumlah_lembar_koin = 0, jenis = ""
                    }) => ({
                        nilai_denominasi,
                        jenis,
                        jumlah_lembar_koin : 0
                    }))

                    setFormData( p => ({
                        ...p,
                        denominasi
                    }))
                    // // // // // console.log(data)
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    // setFormData(prevState => ({
                    //     ...prevState,
                    //     info_transaksi: {
                    //         saldo_teller_after: parseInt(data.saldo),
                    //         saldo_teller_before: parseInt(data.saldo)
                    //     }
                    // }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else  if(statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }


    function changeJumlahLembar(jumlah_lembar_koin, key) {

        if (isNaN(jumlah_lembar_koin)) return false;
        let nominal = 0;
        const de = formData.denominasi
        const denominasi = de.map((d, i) => {

            if (key === i) {
                nominal += (parseInt(jumlah_lembar_koin) * d.nilai_denominasi)
                return {
                    ...d,
                    jumlah_lembar_koin,
                }

            } else {
                nominal += (d.jumlah_lembar_koin * d.nilai_denominasi)
                return d
            }
        });

        if (isNaN(nominal)) {
            nominal = 0
        }


        let saldo_teller_after = parseFloat(formData.info_transaksi.saldo_teller_before).toFixed(2) - nominal
        setFormData(prevState => ({
            ...prevState,
            info_transaksi: {
                ...prevState.info_transaksi,
                saldo_teller_after
            },
            nominal,
            denominasi
        }));

        if (saldo_teller_after < 0) {
            return setErr(true)
            // return setDataAlert({ show: true, body: `Jumlah saldo ${formData.info_transaksi.saldo_teller_before.toLocaleString()}, Setor tidak bisa melebihi jumlah saldo, saldo tidak cukup`, title: `Saldo Tidak Cukup`, header: "Info", callBack: () => null });
        } else if(nominal > 0) setErr(false)
        else {
            return setErr(true)
            // return setDataAlert({ show: true, body: `Jumlah saldo ${formData.info_transaksi.saldo_teller_before.toLocaleString()}, Jumlah setor tidak bisa kurang dari sama dengan 0`, title: `Setoran masih 0`, header: "Info", callBack: () => null });
        }

        setFormData(prevState => ({
            ...prevState,
            nominal,
            denominasi
        }));
    }

    async function getSaldo() {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/kasteller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userInfo.preferred_username,
                    kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    setFormData(prevState => ({
                        ...prevState,
                        info_transaksi: {
                            // saldo_teller_after: parseFloat(data?.saldo || "0").toFixed(2),
                            // saldo_teller_before: parseFloat(data?.saldo || "0").toFixed(2)
                            saldo_teller_after: parseFloat(data.saldo).toFixed(2),
                            saldo_teller_before: parseFloat(data.saldo).toFixed(2)
                        }
                    }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }

    // post ambil kas
    async function doTrx() {
        
    closeConfirm();
        // // // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        
        closeConfirm();
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: { ...formData, uid:keycloak.uid},
                url: "/setorkhasanah", // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: remark, title: ``, header: "Success", callBack: () => {
                        closeConfirm();
                        keycloak.updateUID()
                        resetForm();
                        getSaldo();
                    }
                });
                else if(status === "05"){ 
                    closeConfirm();
                    resetForm();
                    getSaldo();
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function resetForm() {
        setFormData({
            "nomor_rekening": "",
            "nominal": 0,
            "nomor_referensi": "",
            "keterangan": "",
            "kode_valuta": "IDR",
            "info_transaksi": {
                "saldo_teller_before": 0,
                "saldo_teller_after": 0
            },
            "denominasi": [
             
            ]
        })
        getDenom()
    }

    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            // fieldRequired = ["nomorCIF", "nama_produk", "nama_rekening"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    async function getDataDetail(dbId = location.state?.dbId) {

        let url = "/rekening/local";
        openLoading()
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/setorkhasanah/${dbId}`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    // // // console.log(data)
                    setFormData({
                        "nominal": parseInt(data.nominal),
                        "nomor_referensi": data.nomor_referensi,
                        "keterangan": data.keterangan,
                        "kode_valuta": data.kode_valuta,
                        "info_transaksi": {
                            "saldo_teller_before": parseInt(data.info_transaksi?.saldo_teller_before),
                            "saldo_teller_after": parseInt(data.info_transaksi?.saldo_teller_after)
                        },
                        "denominasi": data.denominasi,
                        "nomor_rekening": data.nomor_rekening
                    });
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        closeLoading()
        // // // // console.log(data)
    }


    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        value = value.toUpperCase()
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    function confirmTrx() {
        if (formData.info_transaksi.saldo_teller_after < 0) {
            setErr(true)
            return setDataAlert({ show: true, body: `Jumlah saldo ${addCommas(parseFloat(formData.info_transaksi.saldo_teller_before).toFixed(2))}, Setor tidak bisa melebihi jumlah saldo, saldo tidak cukup`, title: `Saldo Tidak Cukup`, header: "Info", callBack: () => null });
        } else if(formData.nominal > 0) setErr(false)
        else {
            setErr(true)
            return setDataAlert({ show: true, body: `Jumlah saldo ${addCommas(parseFloat(formData.info_transaksi.saldo_teller_before).toFixed(2))}, Jumlah setor tidak bisa kurang dari sama dengan 0`, title: `Setoran masih 0`, header: "Info", callBack: () => null });
        }
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    
    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        closeConfirmCustom("otorisasi")
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); setDataAlert({ show: true, body: remark, title: '', header: "Info", callBack: () => {
                        resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                    } });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }


    return (
        <skKas.Provider value={{
            skState: {
                formData,
                formEditable,
                err,
                otorDesc
            },
            skAction: {
                changeForm,
                changeJumlahLembar,
                confirmTrx,
                setOtorDesc,
                responseOtor

            }
        }}>
            {children}
        </skKas.Provider>
    )
}

export default function useSetorKas() { return useContext(skKas) }