import React, { memo, useEffect, useRef, useState } from 'react';
import { AppAction, AppInterfaces } from '../../../app.data';
import { TableList } from '../../controls';
import { useHistory } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import ModalInfoNegatifNasabah from './info_negatif_modal';
import { joinDefinedString } from '../../../utils';

// import { useKeycloak } from '@react-keycloak/web';
import { useModalAlert } from '../../controls/modalAlert';
import useKeycloak from '../../../hooks/keycloak.module';

export default AppInterfaces.appLoad(
    memo(function ({ store, methods, dispatch }) {
        const appAction = useRef(null);
        const mode = "core_service";
        const { doPost, doGet } = useFetch();
        const [jenisID, setJenisID] = useState([])
        const [data, setData] = useState({ isLoading: false });
        const [currentType, setCurrentType] = useState('I');
        const [stateStatus, setStateStatus] = useState('');
        // const [currentSearchKey, setCurrentSearchKey] = useState();
        const [filter, setFilter] = useState({
            status_data: "",
            keyword: ""
        });
        const [form, setForm] = useState({});
        const [modal, setModal] = useState({
            registrasi: false,
            peragaan: false,
            hapus: false,
        });
        const [typeInfoNegatifModal, setTypeInfoNegatifModal] = useState('registrasi');

        useEffect(async () => {
            getHandler({});
            getListKodeCabang();
            let resp = await doGet({ url: `/dropdowns?key=eJenisIdentitas` });
            setJenisID(resp.status === "00" ? resp.data.listDropdown : [])
        }, [currentType, stateStatus]);

        let history = useHistory();

        const getHandler = async ({ page = 1, limit = 15, body }) => {
            setData({ isLoading: true }); let resp;
            if (mode === "local")
                resp = await doGet({ url: `/${currentType === "I" ? "nasabah" : "nasabah-badan-usaha"}/local`, param: { page, limit, ...filter } });
            else
                resp = await doPost({ url: `/${currentType === "I" ? "nasabah" : "nasabah-badan-usaha"}/search`, queryParam: { page, limit, ...filter } });
            setData({ isLoading: false, list: resp.status === "00" ? resp.data.listNasabah : [] })
        };
        const { keycloak } = useKeycloak()
        const { modalState, modalAction } = useModalAlert();
        const {
            closeAlert,
            closeConfirm,
            closeLoading,
            setDataAlert,
            setDataConfirm,
            setLoading,
            closeConfirmCustom,
            openAlertCustom,
            openLoading
        } = modalAction


        const [listKodeCabang, setListKodeCabang] = useState([]);
        async function getListKodeCabang() {
            try {
                let resp = await doGet({
                    url: `/rpc/list-kode-cabang`,
                    service: "rpc",
                });
                const { statusCode, remark = "", data = [], status = "01", statusText = "", message = "" } = resp
                // // // console.log(resp)
                if (statusCode === 200) {
                    if (status === "00") setListKodeCabang(data)
                    else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: `${remark}. ${message}`, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
            }
        }
        return (
            <>
                <ModalInfoNegatifNasabah
                    show={modal.registrasi || modal.peragaan || modal.hapus}
                    form={form}
                    onHide={() => {
                        setModal({ ...modal, registrasi: false, hapus: false, peragaan: false });
                    }}
                    type={typeInfoNegatifModal}
                    onSuccess={() => {
                        getHandler({})
                        setForm({});
                    }}
                />
                <AppAction ref={appAction} />
                <nav aria-label="breadcrumb" className="mb-3">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a className="text-primary-bds" href="#">Nasabah</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Data Nasabah
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-md-12">
                        <TableList
                            maxHeight={'calc(100vh - 22rem)'}
                            minHeight={'calc(100vh - 22rem)'}
                            isLoading={data.isLoading}
                            onChangeShow={(detil) => getHandler({ ...detil })}
                            onNextPage={(detil) => getHandler({ ...detil })}
                            onPrevPage={(detil) => getHandler({ ...detil })}
                            withActions={true}
                            actionItems={[
                                {
                                    name: 'Lihat Detail Nasabah',
                                    onClick: (e) => {
                                        let state = { dbId: e.nomor_cif }
                                        currentType === 'K'
                                            ? history.push(`/nasabah-cif/form-badan-usaha/detil`, state)
                                            : history.push(`/nasabah-cif/form-perorangan/detil`, state);
                                    },
                                },
                                ...(keycloak.hasRealmRole("cs") ? [
                                    {
                                        name: 'Ubah Data Nasabah',
                                        onClick: (e) => {
                                            let state = { dbId: e.nomor_cif }
                                            currentType === 'K'
                                                ? history.push(`/nasabah-cif/form-badan-usaha/edit`, state)
                                                : history.push(`/nasabah-cif/form-perorangan/edit`, state);
                                        },
                                    },
                                    {
                                        name: 'Registrasi Info Negatif Nasabah',
                                        onClick: (e) => {
                                            setModal({ ...modal, registrasi: true });
                                            setForm(e);
                                            setTypeInfoNegatifModal('registrasi');
                                        },
                                        enabled: (e) => e?._negatifNasabah === null,
                                    },

                                    // {
                                    //     name: 'Info Negatif Nasabah',
                                    //     onClick: (e) => {
                                    //         setModal({ ...modal, peragaan: true });
                                    //         setForm(e);
                                    //         setTypeInfoNegatifModal('peragaan');
                                    //     },
                                    //     enabled: (e) => e?._negatifNasabah !== null,
                                    // },

                                    // {
                                    //     name: 'Non Aktifkan Nasabah',
                                    //     onClick: (e)=>// console.log(e)
                                    // },

                                ] : []),

                                // ...(keycloak.hasRealmRole("spv cs") || keycloak.hasRealmRole("teller") ? [
                                //     {
                                //         name: 'Signature Verification System',
                                //         onClick: (e) => {
                                //             // // console.log(e);
                                //             history.push(
                                //                 `/nasabah-cif/data/svs?id=${e.nomor_cif}`
                                //             );
                                //         },
                                //     },
                                // ] : [] ),


                                ...(
                                    (keycloak.hasRealmRole("inquiry") || ((keycloak.tokenParsed.realm_access?.roles || []).length <= 0 || keycloak.tokenParsed.realm_access?.roles === null)) ? [

                                        {
                                            name: 'Portofolio Nasabah',
                                            onClick: (e) => {
                                                history.push({
                                                    pathname: `/nasabah-cif/data/portofolio`,
                                                    state: e.nomor_cif
                                                });
                                            },

                                        },
                                    ] : [
                                        {
                                            name: 'Portofolio Nasabah',
                                            onClick: (e) => {
                                                history.push({
                                                    pathname: `/nasabah-cif/data/portofolio`,
                                                    state: e.nomor_cif
                                                });
                                            },

                                        },
                                        {
                                            name: 'Signature Verification System',
                                            onClick: (e) => {
                                                // // console.log(e);
                                                history.push(
                                                    `/nasabah-cif/data/svs/${e.nomor_cif}`
                                                );
                                            },
                                        },
                                    ]
                                ),

                            ]}
                            columns={[
                                {
                                    name: 'Nomor Nasabah',
                                    field: 'nomor_cif',
                                    minWidth: '150px',
                                },
                                {
                                    name: 'Kode Cabang',
                                    field: 'kode_cabang_input',
                                    minWidth: '150px',
                                },
                                {
                                    name: 'Nama Nasabah',
                                    field: mode === "local" ? 'name' : "nama",
                                    minWidth: '100px',
                                    onRender: (item) => {
                                        if (mode === "local") {
                                            if (currentType === 'I') {
                                                const { firstName, middleName, lastName } = item;
                                                return joinDefinedString(
                                                    firstName,
                                                    lastName,
                                                    middleName
                                                ).value();
                                            } else if (currentType === 'K') {
                                                return item?.groupName;
                                            }
                                        } else {
                                            return item.nama
                                        }
                                    }
                                },
                                // {
                                //     name: 'Alamat',
                                //     field: 'alamat',
                                //     minWidth: '150px',
                                //     maxWidth:'200px',
                                //     onRender: (item) => {
                                //         if(mode === "local"){
                                //             if (currentType === 'I'){
                                //                 const { ktpAddress, ktpRtRw, ktpVillage, ktpSubDistrict, ktpCity, ktpProvince } = item;
                                //                 return `${ktpAddress}, RT/RW: ${ktpRtRw}, ${ktpVillage}, ${ktpSubDistrict}, ${ktpCity}, ${ktpProvince}`
                                //             }else {
                                //                 const { nasabahAlamat, nasabahRt, nasabahRw, nasabahKelurahan, nasabahKotaKabupaten, nasabahProvinsi } = item;
                                //                 return `${nasabahAlamat}, RT: ${nasabahRt}/RW: ${nasabahRw}, ${nasabahKelurahan}, ${nasabahKotaKabupaten}, ${nasabahProvinsi}`
                                //             }
                                //         } else{
                                //             return item.alamat
                                //         }
                                //     }
                                // },
                                {
                                    name: 'Jenis Nasabah',
                                    field: 'jenis_nasabah',
                                    minWidth: '80px',
                                    onRender: function (item) {
                                        if (currentType === 'I') {
                                            return 'Perorangan';
                                        } else if (currentType == 'K') {
                                            return 'Badan Usaha';
                                        }
                                    }
                                },
                                // {
                                //     name: 'Jenis Identitas',
                                //     field: 'personalIdType',
                                //     className: "text-center",
                                //     onRender: (item) => {
                                //         if(mode === "local"){
                                //             return jenisID.find(d=>d.reference_code == item.personalIdType)?.reference_desc
                                //         }else{
                                //             return item.jenis_identitas
                                //         }
                                //     }
                                // },
                                {
                                    name: 'Nomor Identitas',
                                    field: mode === "local" ? 'personalIdNumber' : 'nomor_identitas',
                                },
                                // {
                                //     name: 'Profil Resiko',
                                //     field: 'profile_resiko',
                                //     className: "text-center",
                                //     onRender: function (item) {
                                //         return (<span class={`badge badge-${item.profile_resiko === "High" ? "success" : (item.profile_resiko === "Medium" ? "warning" : "danger")}`}>
                                //             {item.profile_resiko}</span>
                                //     )}
                                // },
                                {
                                    name: 'Status',
                                    field: 'state_mambu',
                                    onRender: (item) => {
                                        return (
                                            <span class={`badge ${item?.state_mambu === "ACTIVE" || item?.status === "Aktif" ? "badge-success" : "badge-danger"}`}>
                                                {item?.state_mambu || item?.status}
                                            </span>
                                        )
                                    }
                                }
                            ]}
                            data={data.list}
                            filter={true}
                            formsFilter={[
                                {
                                    type: 'select',
                                    options: (listKodeCabang || []).map(({ kode_cabang = "", nama_cabang = "" }, i) => ({
                                        key: kode_cabang,
                                        display: `${kode_cabang} - ${nama_cabang}`
                                    })),
                                    placeholder: 'Kode Cabang',
                                    disabled: false,
                                    readonly: false,
                                    value: filter.kode_cabang,
                                    onChange: (kode_cabang) => setFilter(f => ({ ...f, kode_cabang })),
                                },
                                {
                                    type: 'select',
                                    options: [
                                        {
                                            key: 'I',
                                            display: 'Perorangan',
                                        },
                                        {
                                            key: 'K',
                                            display: 'Badan Usaha',
                                        },
                                    ],
                                    placeholder: 'Jenis Nasabah',
                                    disabled: false,
                                    readonly: false,
                                    value: currentType,
                                    onChange: (value) => {
                                        setFilter({
                                            status_data: "",
                                            keyword: ""
                                        })
                                        if (value !== 'Pilih Jenis Nasabah') {
                                            setCurrentType(value);
                                            // if (value == 'CLIENT') {
                                            //     setStateStatus('PENDING_APPROVAL_EDITED');
                                            // }
                                        }
                                    },
                                },
                                {
                                    type: 'select',
                                    options: [
                                        {
                                            key: "A",
                                            display: "Aktif"
                                        },

                                        {
                                            key: "N",
                                            display: "Tidak Aktif"
                                        },
                                    ],
                                    placeholder: 'Pilih Status',
                                    disabled: false,
                                    readonly: false,
                                    value: filter.status_data,
                                    onChange: (status_data) => setFilter(f => ({ ...f, status_data })),
                                },
                                // {
                                //     type: 'text',
                                //     placeholder: 'Kode Cabang',
                                //     disabled: false,
                                //     readonly: false,
                                //     onChange: (value) => null,
                                // },
                                {                                                                                                                                                    
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Keyword',
                                    disabled: false,
                                    readonly: false,
                                    value: filter.keyword,
                                    onChange: (keyword) => setFilter(f => ({ ...f, keyword })),
                                },
                            ]}
                            onFilter={async () => {
                                setData({ isLoading: true }); let resp;
                                // // console.log(filter)
                                if (mode === "local")
                                    resp = await doGet({ url: `/${currentType === "I" ? "nasabah" : "nasabah-badan-usaha"}/search/local`, param: { page: 1, show: 15, ...filter } })
                                else
                                    resp = await doPost({ url: `/${currentType === "I" ? "nasabah" : "nasabah-badan-usaha"}/search`, queryParam: { page: 1, show: 15, ...filter } });
                                setData({ isLoading: false, list: resp.status === "00" ? resp.data.listNasabah : [] })
                            }}
                            resetFilter={true}
                            onResetFilter={() => {
                                setCurrentType('I');
                                setFilter({
                                    status_data: "",
                                    keyword: ""
                                })
                                getHandler({ page: 1, limit: 15 });
                            }}
                            submitButton="Submit"
                        />
                    </div>
                </div>
            </>
        );
    })
);
