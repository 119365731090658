import React, { memo, useRef, useState, useEffect } from 'react';
import PinInput from "react-pin-input";

const Pin = memo((props) => {
    let ele = useRef(null);
    const [pin, setPin] = useState([]);
    useEffect(() => {}, [ele]);
    
    return(
        <div className="mx-6" style={{border: "1px solid #BDBDBD"}}>
            <PinInput 
                length={6}
                secret
                values={pin}
                ref={(n) => ele.current=n}
                onChange={(val) => setPin(val)} 
                type="numeric" 
                inputMode="number"
                // style={{padding: '10px'}}  
                inputStyle={{borderColor: 'white'}}
                inputFocusStyle={{borderColor: '#BDBDBD'}}
                onComplete={() => {props.onComplete();ele.current && ele.current.clear();}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
        </div>
    )
})

export default Pin;