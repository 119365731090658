import React from 'react';
import useDeposito from '../useDeposito';
import { Tab, Nav, Modal, Button } from 'react-bootstrap';
import DetilBilyetDepositoUlang from '../components/detilCetakUlangBilyet';
import Skeleton from 'react-loading-skeleton';


const BilyetDepositoUlang = () => {
    const { rekState, rekAction } = useDeposito();
    const { rekSelected = {}, formEditable = false } = rekState;

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalCetakBilyetUlang} onHide={rekAction.closeCetakBilyetUlang} >
                <Modal.Header closeButton>
                    <Modal.Title>Cetak Ulang Bilyet Deposito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DetilBilyetDepositoUlang />
                </Modal.Body>
                <Modal.Footer>
                     {/* uncomment jika naek prod */}
                    {/* <div className="row">
                        <div className="col">
                            <button
                                onClick={() => rekAction.closeCetakBilyetUlang()}
                                className="btn btn-outline-danger float-right ml-2">
                                Batal
                            </button>
                            <button
                                // disabled={!(rekState.formCetakUlang?.alasan_cetak === "C" || !(rekState.rekSelected?.nomor_bilyet === rekState.formCetakUlang?.nomor_bilyet)) }
                                disabled={rekState.disableCetakUlang}
                                onClick={() => rekAction.openCetakBilyetUlangPrint(rekSelected?.nomor_rekening)}
                                className="btn btn-outline-success float-right">
                                Cetak Ulang
                            </button>
                        </div>
                    </div> */}
                    {/* uncomment sampau sini */}
                    {/* comment jika naek prod */}
                    {
                        !rekState.isOtor ? (
                            <div className="row">
                                <div className="col">
                                    <button
                                        onClick={() => rekAction.closeCetakBilyetUlang()}
                                        className="btn btn-outline-danger float-right ml-2">
                                        Batal
                                    </button>
                                    {rekState.rekSelected?.is_boleh_cetak_bilyet === "T" ? <button
                                        // disabled={!(rekState.formCetakUlang?.alasan_cetak === "C" || !(rekState.rekSelected?.nomor_bilyet === rekState.formCetakUlang?.nomor_bilyet)) }
                                        // disabled={rekState.disableCetakUlang}
                                        onClick={() => rekAction.openCetakBilyetUlangPrint(rekSelected?.nomor_rekening)}
                                        className="btn btn-outline-success float-right">
                                        Cetak Ulang
                                    </button> : <button
                                        onClick={() => rekAction.confirmRCB(true)}
                                        disabled={rekState.disableCetakUlang}
                                        className="btn btn-outline-success float-right">
                                        Request Cetak
                                    </button>}

                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="row">
                                    <div className="col">
                                        <button
                                            onClick={() => rekAction.responseOtor("APPROVED")}
                                            className="btn btn-outline-success float-right">
                                            Approve
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button
                                            onClick={() => rekAction.responseOtor("REJECTED")}
                                            className="btn btn-outline-danger float-right">
                                            Reject
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button
                                            onClick={() => rekAction.closeCetakBilyetUlang()}
                                            className="btn btn-link float-right">
                                            Tutup
                                        </button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {/* comment smpai sini */}
                </Modal.Footer>
            </Modal >
            {/* end modal utama */}
        </>

    );
}

export default BilyetDepositoUlang;