import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
import useModalNasabah from './useModalNasabah';
// import { useKeycloak } from '@react-keycloak/web';
import { usePrintModal } from '../../../../controls/modalPrint';
import useNexusPrint from '../../../../../hooks/useNexusPrint';
import moment from 'moment';
import { addCommas } from '../../../../../module';
import useCetak from '../../../../controls/cetak/useCetak';
import useKeycloak from '../../../../../hooks/keycloak.module';
const stContext = createContext({
    stState: {},
    stAction: {}
})

export const SetorTunaiProvider = ({ children }) => {
    const { cState, cAction } = useCetak()

    const { doGet, doPost, wait } = useFetch();
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ keyword: '' });
    const [dataListRekening, setListDataRekening] = useState([]);
    const [produk, setProduk] = useState("")
    // const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    // const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false });
    const [isLoading, setIsLoading] = useState(true)
    const [dataCetak, setDataCetak] = useState({});
    const [otorDesc, setOtorDesc] = useState("");
    const location = useLocation();
    // const history = useHistory();
    const [formData, setFormData] = useState({
        "nomor_rekening": "",
        "nominal": 0,
        "nomor_referensi": "",
        "keterangan": "",
        "kode_valuta": "IDR",
        "info_transaksi": {
            "nominal_ekuivalen": 0,
            "biaya": 0,
            "biaya_ekuivalen": 0,
            "sumber_biaya": "T",
            "kurs_jual": 1,
            "kurs_beli": 1,
            "kurs_buku": 1,
            "info_rekening": {
                "nama_rekening": "",
                "kode_valuta": "IDR",
                "keterangan_valuta": "INDONESIA RUPIAH",
                "produk": "",
                "kode_cabang": "",
                "nama_cabang": ""
            },
            "data_pengirim": {
                "nomor_nasabah": "",
                "nama": "",
                "alamat": "",
                "jenis_identitas": "",
                "nomor_identitas": "",
                "nomor_tlpn": "",
                "nasabah_wic": ""
            }
        }
    });
    // const [loadingDetil, setLoadingDetil] = useState(true);
    // const [rekSelected, setRekSelected] = useState({});
    const [jenisRekening, setJenisRekening] = useState(null)

    const { mpAction, mpState } = usePrintModal();
    const [dataPrint, setDataPrint] = useState({
        "transaksi": {
            "id_transaksi": "",
            "id_transaksi_core": "",
            "nomor_rekening_debet": "",
            "nomor_rekening_credit": "",
            "nominal": "",
            "nomor_referensi": "",
            "keterangan": "",
            "keterangan_otor": null,
            "kode_valuta": "",
            "kode_transaksi": "",
            "status_otorisasi": "",
            "user_input": "",
            "user_otorisasi": "",
            "tanggal_input": "",
            "tanggal_otor": "",
            "status_reversal": null
        },
        "info_transaksi": {
            "id_info": null,
            "id_transaksi": "",
            "kode_bank_kliring": null,
            "nama_bank_kliring": null,
            "nama_rekening_penerima": null,
            "ats_permintaan": null,
            "keterangan": null,
            "norek_biaya": null,
            "saldo_teller_before": "",
            "saldo_teller_after": null,
            "nominal_ekuivalen": "",
            "biaya": "",
            "biaya_ekuivalen": "",
            "sumber_biaya": "",
            "kurs_jual": "",
            "kurs_beli": "",
            "kurs_buku": "",
            "pengirim_nomor_nasabah": null,
            "pengirim_nama": null,
            "pengirim_alamat": null,
            "pengirim_jenis_identitas": null,
            "pengirim_nomor_identitas": null,
            "pengirim_nomor_tlpn": null,
            "pengirim_nasabah_wic": null,
            "jenis_ttr": null,
            "tutup_kartu_atm": null,
            "norek_pindah_buku": null,
            "nama_rekening": "",
            "kode_valuta": "",
            "keterangan_valuta": "",
            "produk": "",
            "kode_cabang": "",
            "nama_cabang": "",
            "jenis_penduduk": null,
            "jenis_nomor_referensi": null,
            "jenis_nasabah": null,
            "total_denom": null,
            "selisih_saldo": null,
            "jenis_transaksi": null,
            "kode_valuta_teller": null,
            "saldo_rekening": null,
            "nama_rekening_pinbuk": null,
            "cabang_rekening_pinbuk": null
        }
    });

    const { print } = useNexusPrint()

    const [formCetak, setFormCetak] = useState({
        command: "PRINT",
        medianame: "Term Cash Deposit",
        formname: "BJBS SETORAN",
        print: 1,
        history: 0,
        id: null,
        nomorTransaksi: "",
        kodeCabang: "",
        userTeller: "",
        tgl: "",
        userOtorisasi: "",
        kodeCabangDebit: "",
        norekDebit: "",
        kodeTarikan: "",
        jenisMataUang: "",
        nominalDebit: "",
        kodeDebit: "",
        kodeCabangKredit: "",
        norekKreditKasTeller: "",
        nominalKredit: "",
        kodeKredit: "",
        berita: "",
        tanggalTranskasi: "",
        jamTransaksi: ""
    })


    const modalNasabah = useModalNasabah()
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const { keycloak } = useKeycloak()
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams()
    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            setFormEditable(true);
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            getDataDetail();
            // getDataPerorangan(location.state?.dbId);
        } else {
            setFormEditable(false);
            resetForm();
        }
        // } 
        setFormEditable(paramUrl.mode !== "otorisasi");
    }, [paramUrl.mode])
    // useEffect GetData
    useEffect(function () {
        // getDataList()
    }, [pagination])

    async function getDataDetail(dbId = location.state?.dbId) {

        let url = "/rekening/local";
        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/setortunai/${dbId}`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    // // console.log(data)
                    setIsWic(data.info_transaksi?.wic_baru === "T")
                    setFormData({
                        "nomor_rekening": data.nomor_rekening,
                        "nominal": parseFloat(data.nominal).toFixed(2),
                        "nomor_referensi": data.nomor_referensi,
                        "keterangan": data.keterangan,
                        "kode_valuta": data.kode_valuta,
                        "info_transaksi": {
                            "nominal_ekuivalen": parseFloat(data.info_transaksi?.nominal_ekuivalen).toFixed(2),
                            "biaya": parseFloat(data.info_transaksi?.biaya).toFixed(2),
                            "biaya_ekuivalen": parseFloat(data.info_transaksi?.biaya_ekuivalen).toFixed(2),
                            "sumber_biaya": data.info_transaksi?.sumber_biaya,
                            "kurs_jual": parseFloat(data.info_transaksi?.kurs_jual).toFixed(2),
                            "kurs_beli": parseFloat(data.info_transaksi?.kurs_beli).toFixed(2),
                            "kurs_buku": parseFloat(data.info_transaksi?.kurs_buku).toFixed(2),
                            "info_rekening": {
                                "nama_rekening": data.info_transaksi?.nama_rekening,
                                "kode_valuta": data.info_transaksi?.kode_valuta,
                                "keterangan_valuta": data.info_transaksi?.keterangan_valuta,
                                "produk": data.info_transaksi?.produk,
                                "kode_cabang": data.info_transaksi?.kode_cabang,
                                "nama_cabang": data.info_transaksi?.nama_cabang
                            },
                            "data_pengirim": {
                                "nomor_nasabah": data.info_transaksi?.pengirim_nomor_nasabah,
                                "nama": data.info_transaksi?.pengirim_nama,
                                "alamat": data.info_transaksi?.pengirim_alamat,
                                "jenis_identitas": data.info_transaksi?.pengirim_jenis_identitas,
                                "nomor_identitas": data.info_transaksi?.pengirim_nomor_identitas,
                                "nomor_tlpn": data.info_transaksi?.pengirim_nomor_tlpn,
                                "nasabah_wic": data.info_transaksi?.pengirim_nasabah_wic
                            }
                        }
                    });
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
        // // // console.log(data)
    }

    function changeForm(value, key, subkey = null, grandSubKey = null) {
        if (typeof value === "string") value = value.toUpperCase()
        if (grandSubKey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [grandSubKey]: { ...prevState[subkey][grandSubKey], [key]: value } } }))
        else if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    async function cekNasabah(nomor_rekening) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/rekening/rek-for-trx/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {

                if (status === "00") {
                    let { jenis_rekening_transaksi = "", nama_lengkap = "", nama_rekening = "", nama_cabang = "", valuta = "", nama_produk = "", nomor_nasabah = "",
                        nama = "", alamat = "", jenis_identitas = "", nomor_identitas = "", nomor_tlpn = "", nasabah_wic = "", kode_jenis = "", is_sudah_disetor = "F",
                        saldo_deposito = 0, kode_cabang = "", jenis_rekening_liabilitas = null } = data;
                    let kode_valuta = "";
                    let keterangan_valuta = "";
                    const valutax = valuta.split("-")
                    let nominal = 0;
                    // // // console.log(valutax)
                    // // // console.log(data)

                    if (kode_jenis === "DEP") {
                        if (is_sudah_disetor === "T") {
                            closeLoading();
                            return setDataAlert({ show: true, body: `Rekening deposito (${nomor_rekening}) sudah pernah melakukan setoran.`, title: ``, header: "Info", callBack: () => changeForm("", "nomor_rekening") });
                        }
                        nominal = saldo_deposito;
                    }
                    is_sudah_disetor = "F"
                    if (valutax.length > 1) {
                        kode_valuta = valutax[0];
                        keterangan_valuta = valutax[1]
                    }
                    setJenisRekening(jenis_rekening_liabilitas)
                    setFormData(prevState => ({
                        ...prevState,
                        nominal,
                        jenis_rekening_transaksi,
                        info_transaksi: {
                            ...prevState.info_transaksi,
                            info_rekening: {
                                nama_rekening,
                                kode_valuta,
                                keterangan_valuta,
                                produk: nama_produk,
                                kode_cabang,
                                nama_cabang,
                                is_sudah_disetor,
                                jenis_rekening_tabungan: kode_jenis

                            },
                            data_pengirim: {
                                nomor_nasabah,
                                nama: nama_lengkap,
                                alamat,
                                jenis_identitas,
                                nomor_identitas,
                                nomor_tlpn,
                                nasabah_wic
                            },
                        }
                    }))
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "nomor_rekening") });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "nomor_rekening") });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => changeForm("", "nomor_rekening") });
        }
        closeLoading();
    }


    function resetForm() {
        setJenisRekening(null)
        setFormData({
            "nomor_rekening": "",
            "nominal": 0,
            "nomor_referensi": "",
            "keterangan": "",
            "kode_valuta": "IDR",
            "info_transaksi": {
                "nominal_ekuivalen": 0,
                "biaya": 0,
                "biaya_ekuivalen": 0,
                "sumber_biaya": "T",
                "kurs_jual": 1,
                "kurs_beli": 1,
                "kurs_buku": 1,
                "info_rekening": {
                    "nama_rekening": "",
                    "kode_valuta": "",
                    "keterangan_valuta": "",
                    "produk": "",
                    "kode_cabang": "",
                    "nama_cabang": ""
                },
                "data_pengirim": {
                    "nomor_nasabah": "",
                    "nama": "",
                    "alamat": "",
                    "jenis_identitas": "",
                    "nomor_identitas": "",
                    "nomor_tlpn": "",
                    "nasabah_wic": ""
                }
            }
        })
    }

    // fungsi post nasabah
    async function doTrx() {

        closeConfirm();
        // // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: {
                    ...formData,
                    uid:keycloak.uid,
                    wic_baru: isWic ? "T" : "F"
                },
                url: "/setortunai", // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            // // // console.log(resp)
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    
                    keycloak.updateUID()
                    if (data.transaksi?.id_transaksi_core !== null) {

                        setDataPrint(data)

                        setFormCetak(c => ({

                            command: "PRINT",
                            medianame: "BJBS",
                            formname: "Validasi Setoran",
                            print: 1,
                            history: 0,
                            id: data?.info_transaksi?.id_transaksi || "",
                            kodeTransaksi: "ST",
                            nomorArsipSetoran: data?.transaksi?.nomor_referensi || "",
                            kodeCabangSetoran: data?.transaksi?.kode_cabang_transaksi || "",
                            userTeller: data?.transaksi?.user_input || "",
                            tglBlnThnSetoran: moment((data?.transaksi?.tanggal_otor_server )).format("DD/MM/YYYY"),
                            userOtorisasi: data?.transaksi?.user_otorisasi || "",
                            kodeCabangKredit: data?.info_transaksi?.kode_cabang_kredit || "",
                            norekKredit: data?.transaksi?.nomor_rekening_credit || "",
                            kodeSetoran: data?.info_rekening?.map_kode_transaksi || "",
                            mataUang: data?.info_transaksi?.kode_valuta || "",
                            nominalKredit: addCommas(parseFloat(data?.transaksi?.nominal || "0").toFixed(2)),
                            kodeKredit: "K",
                            kodeCabangDebit: data?.info_transaksi?.kode_cabang_debet || "",
                            norekDebit: data?.transaksi?.nomor_rekening_debet || "",
                            nominalDebit: addCommas(parseFloat(data?.transaksi?.nominal || "0").toFixed(2)),
                            kodeDebit: "D",
                            namaPemilikNorek: data?.info_transaksi?.nama_rekening || "",
                            berita: data?.transaksi?.keterangan || "",
                            penyetor: data?.info_transaksi?.pengirim_nama || "",
                            tanggalDebit: moment((data?.transaksi?.tanggal_otor_server)).format("DD/MM/YYYY"),
                            waktuDebit: moment((data?.transaksi?.tanggal_otor_server)).format("HH:mm:ss"),
                        }))
                        mpAction.openModal("modalTrx", function () {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            keycloak.updateUID()
                            if (jenisRekening === "T" || jenisRekening === "R") {
                                // if(keycloak?.tokenParsed?.branch_code === "000") 
                                    confirmCetakPassbook({ nomor_rekening: data?.transaksi?.nomor_rekening_credit })
                            }
                        })
                    } else setDataAlert({
                        show: true, body: remark, title: `Transaksi Berhasil`, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            keycloak.updateUID()
                        }
                    });
                }
                
                else if(status === "05"){ 
                    resetForm();
                    closeLoading();
                    closeAlert();
                    closeConfirmCustom();
                    closeConfirm();
                    keycloak.updateUID()
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            fieldRequired = ["nama_rekening", "nomor_rekening"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    // pilih nasabah
    async function pilihNasabah(dataNasabah, tipe) {
        setLoading(prevState => ({ ...prevState, loading: true }));
        let url = `/nasabah/${dataNasabah.nomor_cif}`;
        if (parseInt(tipe) === 1) url = `/nasabah-badan-usaha/${dataNasabah.nomor_cif}`;

        try {
            const resp = await doGet({
                url,
                service: "cif"
            });
            const { status, statusText, data, statusCode, remark } = resp;
            if (statusCode === 200) {
                if (status === "00") {

                    // Sesuaikan fieldnya disini juga
                    const { nasabahBadanUsaha } = data
                    let homePhone = nasabahBadanUsaha?.homePhone;
                    let mobilePhone = nasabahBadanUsaha?.mobilePhone;
                    let alamat = dataNasabah.alamat.toUpperCase();
                    let nomor_identitas = nasabahBadanUsaha.personalIdNumber;
                    let nomor_nasabah = dataNasabah.nomor_cif


                    if (tipe === 1) {
                        homePhone = nasabahBadanUsaha?.nasabahTeleponKantorKodeArea1 + nasabahBadanUsaha?.nasabahTeleponKantorNomor1;
                        mobilePhone = "";
                    }

                    setFormData(prevState => ({
                        ...prevState,
                        info_transaksi: {
                            ...prevState.info_transaksi,
                            data_pengirim: {
                                ...prevState.info_transaksi.data_pengirim,
                                nomor_nasabah,
                                nama: dataNasabah.nama,
                                alamat,
                                jenis_identitas: nasabahBadanUsaha.personalIdType,
                                nomor_identitas,
                                nomor_tlpn: homePhone,
                            }
                        }
                    }));
                    return true;
                } else {
                    setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
                    setLoading(prevState => ({ ...prevState, loading: false }))
                    return false;
                }
            } else {
                setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
                setLoading(prevState => ({ ...prevState, loading: false }))
                return false;
            }
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            setLoading(prevState => ({ ...prevState, loading: false }))
            return false;
        }

    }
    function confirmTrx() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    function confirmCetakPassbook(data) {

        let body = `Apakah akan melakukan cetak transaksi passbook?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Ya", onClick: () => {
                        closeConfirm()
                        cAction.openCetakTransaksiPasbook(data)
                    }, className: "btn btn-sm btn-success"
                },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }


    async function cetakFunction() {

        openLoading()
        try {
            const res = await print(formCetak);
            if (res.statusCode === 500) {
                setDataAlert({ show: true, body: res.remark, title: `${res.statusText}`, header: "Error", callBack: () => cAction.openCetakTransaksiPasbook(dataPrint?.transaksi?.nomor_rekening_credit) })
            } else {
                setDataAlert({
                    show: true, body: res.remark, title: `${res.statusText}`, header: "Berhasil", callBack: () => {
                        closeConfirm();
                        resetForm();
                        mpAction.closeModal("modalTrx");
                        
                        if (jenisRekening === "T" || jenisRekening === "R") {
                            // if(keycloak?.tokenParsed?.branch_code === "000") 
                                confirmCetakPassbook({ nomor_rekening: dataPrint?.transaksi?.nomor_rekening_credit });
                        }
                    }
                });
            }
        } catch (err) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });

        }
        closeLoading()

    }


    const [isWic, setIsWic] = useState(false);

    async function cekIdentitas(noId) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/nasabah/cek_cif_by_no_identitas/${noId}`,
                service: "cif"
            });
            const { statusCode, status, statusText, data = [], remark } = resp;
            // // console.log(resp)

            if (statusCode === 200) {

                if (status === "00") {

                }
                else if (status === "01") {
                    let s = `Nomor Identitas sudah terdaftar sebagai nasabah / wic.`;
                    let r = ` Nama : ${data.length > 0 ? data[0]?.nama_nasabah : ""}. Nomor Nasabah : `;

                    data.forEach((v, i) => r = `${r} ${v.nomor_nasabah} ${i + 1 >= data.length ? "." : " ,"}`)
                    let f = <>
                        {s} <br />
                        {r}
                    </>
                    setDataAlert({ show: true, body: f, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "nomor_identitas", "info_transaksi", "data_pengirim") });
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }


    return <stContext.Provider
        value={{
            stState: {
                isLoading,
                filter,
                formData,
                formEditable,
                produk,
                otorDesc,
                dataPrint,
                isWic
            },
            stAction: {
                closeAlert,
                closeConfirm,
                changeForm,
                pilihNasabah,
                confirmTrx,
                setProduk,
                resetForm,
                cekNasabah,
                setOtorDesc,
                responseOtor,
                validasi,
                cetakFunction,
                setIsWic,
                cekIdentitas
            },
            ...modalNasabah
        }}
    >
        {children}
    </stContext.Provider>;
}


export default function useSetorTunai() { return useContext(stContext) };