import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { createForm } from '../../../module/createFormList';

export default memo(function ({
    itemsForm,
    totalRows = 2,
    isLoading = false
}) {
    return (
        <div className="mt-3">
            {
                itemsForm?.map((item, i) => {
                    return (
                        <div key={i} style={{

                            marginBottom: "20px"
                        }}>
                            <div className="row" style={{
                                borderTop: "1pt solid #d2d4d7",
                                // margin: "0px 5px",
                            }}>
                                <div className="col">
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "-15px",
                                            background: "white",
                                            padding: "0px 10px",
                                            left: "0px",
                                            color: "#004bbc",
                                            fontWeight: "bold",
                                            fontSize: "12pt"
                                        }}
                                    >{item.name}</span>

                                    {
                                        (item.toolbar !== null && item.toolbar !== undefined) && (
                                            <div style={{
                                                position: "absolute",
                                                top: "-15px",
                                                padding: "0px 10px",
                                                background: "white",
                                                right: "0px",
                                            }}>
                                                <button
                                                    onClick={item.toolbar?.onClick}
                                                    className="btn btn-sm btn-outline-primary"
                                                    style={{
                                                        color: "#004bbc",
                                                        background: "white",
                                                        borderRadius: "7px"
                                                    }}
                                                >
                                                    {item.toolbar?.name}

                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row mt-3" style={{
                                // marginLeft: "5px"
                            }}>
                                {
                                    totalRows === 2 ? (
                                        <>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {
                                                            item
                                                                .rows?.filter(v => v.rows === 1)
                                                                .map((form, j) => isLoading ? <div style={{width:"100%", marginBottom: 20}}> <Skeleton height={30} count={1} />  </div>
                                                                : createForm(form) )
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {
                                                            item
                                                                .rows?.filter(v => v.rows === 2)
                                                                .map((form, j) => isLoading ? <div style={{width:"100%", marginBottom: 20}}> <Skeleton height={30} count={1} />  </div>
                                                                :  createForm(form))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {
                                                        item
                                                            .rows?.filter(v => v.rows === 1)
                                                            .map((form, j) => isLoading ? <div style={{width:"100%", marginBottom: 20}}> <Skeleton height={30} count={1} />  </div>
                                                            :  createForm(form))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )

})