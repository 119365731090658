import React from 'react';
import Select from 'react-select';

export default function ({
    placeholder = "",
    onChange = () => null,
    display = "display",
    keyValue = "key",
    value = "",
    options = [],
    disabled = false,
    className = "",
    required = false,
    size = "md"
}) {


    const height = size === "sm" ? 28 : size === "md" ? 38 : 38;
    // sm = 30px,
    // md = 38px
    function getRequiredColor() {
        if (required) {
            if (value === "" || value === undefined || value === null) return "red"
            else return "#ced4da"
        } else return "#ced4da"
    }
    const styles = {
        control: styles => {
            // console.log(styles)
            return ({
                ...styles,
                minHeight: 10,
                borderColor: getRequiredColor(),
                height,
                // fontSize:10,
                // margin:4,
                padding: 0
            })
        },
        placeholder: styles => {
            return {
                ...styles,
                top: 13,
                // position:"static",
                // paddingTop:20
            }
        },
        input: styles => {
            // console.log(styles)
            return ({
                ...styles,
                paddingBottom: 10,
                position: "absolute"
                // height:10,
                // margin:3
            })
        },
        indicatorContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
            //console.log(isFocused)
            return {
                ...styles,
                padding: "4px 8px",
                height: height

                // position:"absolute"
                // position:"static",
                // paddingTop:20
            }
        },
        valueContainer: styles => ({
            ...styles,
            height: 20,
            minHeight: 10,
            position: "static"

        }),
        indicatorSeparator: style => ({
            display: "none"
        }),
        container: styles => ({
            ...styles,
            height,
            minHeight: 10
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: isFocused ? 'white' : 'black',
                cursor: disabled ? 'not-allowed' : 'default',
                padding: 1,
                paddingLeft: 10,
                backgroundColor: isFocused ? "#007bff" : "default",
                fontSize: 10,
                innerHeight: 6,
                // height: 20

            };
        },
    };


    return <Select
        // className={className}
        placeholder={placeholder}
        onChange={onChange}
        styles={styles}
        // maxMenuHeight={500}
        value={
            value !== null ? ([
                [
                    {
                        value: "",
                        label: placeholder
                    },
                    ...(
                        options?.map(v => (
                            { value: v[keyValue], label: `${v[display]}` }
                        )) || []
                    )
                ]
                    .find(c => c.value === value) || null
            ]) : null
        }
        isDisabled={disabled}
        options={
            [{
                value: "",
                label: placeholder
            }, ...(options?.map(v => ({ value: v[keyValue], label: `${v[display]}` })) || [])]
        }
    />
}