import React, { useEffect, useState } from 'react';
import useFetch from './useFetch';

const baseUrl = "/dropdowns"
export default function useDropdowns(keys = [
    "eJenisIdentitas",
    "eStatusPerkawinan",
    "eKewarganegaraan",
    "R_KODE_NEGARA",
    "ePendidikan",
    "eStatusKeterkaitan",
    "eJenisPenduduk",
    "personalOccupation",
    "R_SEKTOR_EKONOMI",
    "eBadanHukum",
    "R_BIDANG_USAHA",
    "R_PENGHASILAN_PERTAHUN",
    "R_BADAN_HUKUM_KORPORAT",
    "eStrukturModal",
    "R_KL",
    "R_JABATAN_KORPORAT",
    "R_PEKERJAAN",
    "R_PENGELUARAN_PERTAHUN",
    "ANTASENA_GOL_PIHAK_LAWAN",
    "R_SUMBER_DANA_CIF_KORPORAT",
    "R_TUJUAN_DANA_CIF_KORPORAT",
    "R_FAKTOR_RESIKO",
    "R_RELASI_NASABAH",
    "ANTASENA_LEMBAGA_PEMERINGKATAN",
    "R_SUMBER_PENGHASILAN",
    "R_TUJUAN_BUKA_REKENING",
    "R_LOKASI_USAHA",
    "eAgama",
    "R_STATUS_PAJAK",
    "R_SUMBER_DANA_REKENING",
    "R_GOLONGAN_PEMILIK",
    "R_GOLPEMILIK_XBRL",
    "R_GOLDEBITUR",
    "R_RELASI_NASABAH",
    "NONBANK",
    "eDisposisiBagiHasil",
    "eDisposisiDeposito",
    "eSumberBiaya",
    "eJenisWarkat",
    "R_STATUS_RUMAH",
    "GOL_PIHAK_LAWAN",
    "BIF_PURPOSE_CODE"
]) {
    const { doGetMultiple } = useFetch();
    const [dataDropDown, setDataDropdown] = useState(null)

    useEffect(function () {
        if(dataDropDown === null) getDropdownAll();
    }, [])

    async function getDropdownAll() {
        try {
            const resp = await doGetMultiple(
                keys.map(v => ({ param: { key: v }, url: baseUrl, responseVar: v }))
            );
            // // console.log(resp);
            let newState = { }
            keys.forEach(v => {
                let dataDropDown = [];
                const { statusCode, data = [], status, statusText, remark } = resp[v];
                if(status === "00") dataDropDown = data.listDropdown;
                newState = { ...newState, [v]: dataDropDown }
                
            });
            setDataDropdown( newState )
        } catch (error) {
            throw error;
        }
    }

    return dataDropDown || {};
}