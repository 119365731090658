import moment from 'moment';
// import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Modal} from 'react-bootstrap';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration, TableList } from '../../../../controls';
import useFormDeposito from './useFormDeposito';
import useDepositos from './useDataDeposito';
import Select from 'react-select';
import useKeycloak from '../../../../../hooks/keycloak.module';

const DataDeposito = () => {

    const { fdAction, fdState } = useFormDeposito();
    const { formData = {}, formEditable = false } = fdState;
    const { pddAction, pddState } = useDepositos()
    const { keycloak } = useKeycloak();
    const dataMassege = "gak bisa edit";
    const nilaiNisbah = fdState.formData?._informasiLain?.nisbahInfo;
    const minNisbah = -fdState.formData?._informasiLain?.nisbahAkhir;
    const maxNisbah = 100 - fdState.formData?._informasiLain?.nisbahAkhir;

    const {listDropdown} = ({
        "listDropdown": [
          {
            "id": "1",
            "key": "eSetoranAwal",
            "reference_code": "PB1",
            "reference_desc": "PINDAH BUKU"
          },
          {
              "id": "2",
              "key": "eSetoranAwal",
              "reference_code": "TN1",
              "reference_desc": "TUNAI"
          },
        ]
    });
    return (
        <>
            <div className="pb-4">
                <div className="box">
                    <div className="row">
                        <div className="col-4 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nomor Rekening Deposito</label>
                                <div className='input-group'>
                                    <input
                                        className="form-control"
                                        type="text"
                                        readOnly
                                        placeholder="Otomatis terisi dari sistem"
                                        value={formData?.nomorRekening}
                                        disabled={!fdState.formEditable || fdState.paramUrl?.mode === "edit"}
                                    />
                                     {/* {
                                        fdState.paramUrl?.mode === "registration" && <div class="input-group-append ml-3 mr-2">
                                            <button className={`btn btn-${ 
                                                    formData?.nomorRekening !== null && 
                                                    formData?.nomorRekening !== "" && 
                                                    formData?.nomorRekening !== undefined ? "default" : "primary"}`} 
                                                    onClick={  formData?.nomorRekening !== null && 
                                                        formData?.nomorRekening !== "" && 
                                                        formData?.nomorRekening !== undefined ? fdAction.resetNomorCantik : fdAction.openModalNC}>
                                                { 
                                                    formData?.nomorRekening !== null && 
                                                    formData?.nomorRekening !== "" && 
                                                    formData?.nomorRekening !== undefined ? "Reset" : "Request Nomor Cantik"
                                                }
                                            </button>
                                        </div>

                                    } */}
                                </div>
                                
                                {/* <span style={{fontSize:"9pt", color:"#007bff"}}>*Kosongkan jika rekening generate otomatis</span> */}
                            </div>
                        </div>
                        <div className="col-4 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nomor Akad</label>
                                <div className='input-group'>
                                    <input
                                        className="form-control"
                                        type="text"
                                        readOnly
                                        placeholder="Otomatis terisi dari sistem"
                                        value={formData?.nomor_akad || ""}
                                        disabled={true}
                                    />
                                     {/* {
                                        fdState.paramUrl?.mode === "registration" && <div class="input-group-append ml-3 mr-2">
                                            <button className={`btn btn-${ 
                                                    formData?.nomorRekening !== null && 
                                                    formData?.nomorRekening !== "" && 
                                                    formData?.nomorRekening !== undefined ? "default" : "primary"}`} 
                                                    onClick={  formData?.nomorRekening !== null && 
                                                        formData?.nomorRekening !== "" && 
                                                        formData?.nomorRekening !== undefined ? fdAction.resetNomorCantik : fdAction.openModalNC}>
                                                { 
                                                    formData?.nomorRekening !== null && 
                                                    formData?.nomorRekening !== "" && 
                                                    formData?.nomorRekening !== undefined ? "Reset" : "Request Nomor Cantik"
                                                }
                                            </button>
                                        </div>

                                    } */}
                                </div>
                                
                                {/* <span style={{fontSize:"9pt", color:"#007bff"}}>*Kosongkan jika rekening generate otomatis</span> */}
                            </div>
                        </div>
                        <div className="col-4 text-right align-self-end">
                            <div>
                                {/* <span className="text-grey">Tgl Buka</span>
                                <span className="text-grey2 ml-4">:</span>
                                <span className="text-grey2">{moment(formData.created_at).format("DD MMMM YYYY")}</span> */}
                                <span className="ml-5 text-grey">Kode Cabang</span>
                                <span className="text-grey2 ml-4">: </span>
                                <span className="text-grey2">{keycloak?.tokenParsed?.branch_code}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FormRegistration
                itemsForm={[
                    // Data deposito
                    {
                        name: "Data Nasabah",
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-2">
                                                <label>Nomor CIF</label>
                                                <span className="badge badge-secondary">
                                                    Wajib
                                                </span>
                                                <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        type="number"
                                                        required
                                                        className="form-control form-control-sm col-9"
                                                        placeholder="Masukan nomor CIF"
                                                        readOnly
                                                        onChange={(e) => {
                                                            fdAction.changeForm(e.target.value,"nomorCIF");
                                                            }}
                                                        value = {fdState.formData?.nomorCIF}
                                                        disabled={!fdState.formEditable || fdState.paramUrl?.mode === "edit"}
                                                        //contoh cara onBlur di custom
                                                        // onBlur={(e) => {
                                                        //     fdAction.changeForm(e.target.value,"nomorCIF");
                                                        //     }}
                                                    />
                                                    <div className="input-group-append col-3 pl-2 pr-0">
                                                        <button disabled={fdState.paramUrl?.mode === "otorisasi"|| fdState.paramUrl?.mode === "edit"} onClick={pddAction.openModalAdd} className="btn btn-outline-danger">
                                                            Cari Nasabah . . . 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: 'Nama',
                                type: 'text',
                                placeholder: 'Masukan nama',
                                required: false,
                                readOnly:true,
                                value: fdState.formData?.namaNasabah,
                                onChange: (value) => fdAction.changeForm(value, "namaNasabah"),
                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col-4">
                                        {
                                            createForm({
                                                label: 'No Telepon',
                                                type: 'number',
                                                placeholder: 'masukan nomor telepon',
                                                required: false,
                                                readOnly:true,
                                                value: fdState.formData?._dataNasabah?.homePhone,
                                                onChange: (value) => fdAction.changeForm(value, "homePhone","_dataNasabah"),
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit" 
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'No Handphone',
                                                type: 'number',
                                                placeholder: 'masukan handphone',
                                                readOnly:true,
                                                required: false,
                                                value: fdState.formData?._dataNasabah?.mobilePhone,
                                                onChange: (value) => fdAction.changeForm(value, "mobilePhone", "_dataNasabah"),
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },

                            {
                                rows: 2,
                                label: 'Alamat',
                                type: 'textarea',
                                placeholder: 'Masukan alamat',
                                value: fdState.formData?._dataNasabah?.alamat,
                                onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                required: false,
                                disabled: true
                            },
                    ]},
                    // End Data deposito
                    // rekening
                    {
                        name: "Rekening",
                        rows: [
                            {
                                rows: 1,
                                label: 'Nama Rekening',
                                type: 'text',
                                placeholder: 'Masukan nama rekening',
                                required: true,
                                value: fdState.formData?.namaRekening,
                                onChange: (value) => fdAction.changeForm(value, "namaRekening"),
                                disabled: !fdState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Nomor Bilyet',
                                type: 'text',
                                placeholder: 'Masukan Nomor bilyet',
                                required: true,
                                maxLength:20,
                                value: fdState.formData?._rekeningDeposito?.nomor_bilyet,
                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit",
                                onChange: (value) => fdAction.changeForm(value, "nomor_bilyet","_rekeningDeposito"),
                                onFocusOut:(value)=> fdAction.cekNomorBilyet(value, fdAction.changeForm(value, "nomor_bilyet","_rekeningDeposito")),
                                onEnter:(value)=> fdAction.cekNomorBilyet(value, fdAction.changeForm(value, "nomor_bilyet","_rekeningDeposito"))
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col">
                                        {
                                           
                                            createForm({
                                                label: 'Tanggal Buka',
                                                type: 'date',
                                                required: true,
                                                readOnly:true,
                                                value: fdState.formData?._rekeningDeposito?.tanggal_buka,
                                                onChange: (value) => {
                                                    if (value > moment(Date.now()).format("YYYY-MM-DD")){
                                                        {/* console.log('tidak bisa edit') */}
                                                    }else{
                                                        fdAction.changeDate({
                                                            from:value, 
                                                            key:"tanggal_buka", 
                                                            subkey:"_rekeningDeposito", 
                                                            toKey: "tanggal_jatuh_tempo", 
                                                            toSubkey:"_rekeningDeposito"
                                                        })
                                                    }
                                                },
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Tanggal Jatuh Tempo',
                                                type: 'date',
                                                required: false,
                                                readOnly:true,
                                                valueTBuka: fdState.formData?._rekeningDeposito?.tanggal_buka,
                                                valueProduct: fdState.formData?.produkTabungan,
                                                value: fdState.formData?._rekeningDeposito?.tanggal_jatuh_tempo,
                                                onChange: (value, valueProduct) => {
                                                    if (valueProduct === "SIMPANAN BERJANGKA BAROKAH 3 BULAN"){
                                                        {/* console.log("3bulan") */}
                                                    }
                                                },                                                
                                                //fdAction.changeForm(value, "tanggal_jatuh_tempo", "_rekeningDeposito")
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Produk Deposito',
                                type: 'select',
                                placeholder: 'Pilih produk deposito',
                                options: fdState.dataProduk,
                                display: "nama_produk",
                                key:"kode_produk",
                                required: true,
                                value: fdState.formData?.produkTabungan,
                                onChange: (value) => fdAction.changeProduct(value),
                                disabled: (fdState.paramUrl?.mode === "registration" && (
                                    formData?.nomorRekening !== "" &&
                                    formData?.nomorRekening !== null &&
                                    formData?.nomorRekening !== undefined
                                ) ) ||!fdState.formEditable || fdState.paramUrl?.mode === "edit"
                            },
                            {
                                rows: 2,
                                label: 'Valuta',
                                type: 'text',
                                placeholder: 'Otomatis terisi',
                                required: false,
                                readOnly:true,
                                value: fdState.formData?.valuta,
                                onChange: (value) => fdAction.changeForm(value, "valuta"),
                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                            },
                            {
                                rows: 2,
                                label: 'Nominal Deposito',
                                type: 'currency',
                                placeholder: 'Masukan nominal deposito',
                                required: true,
                                // readOnly:true,
                                value: fdState.formData?._rekeningDeposito?.nominal_deposito,
                                onChange: (value) => fdAction.changeForm(value, "nominal_deposito","_rekeningDeposito"),
                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                            },
                            {
                                rows: 2,
                                label: 'Keterangan',
                                type: 'text',
                                placeholder: 'contoh: Tabungan pertama',
                                required: false,
                                maxLength: 50,
                                value: fdState.formData?.keterangan,
                                onChange: (value) => fdAction.changeForm(value, "keterangan"),
                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                            },
                            // {
                            //     rows: 2,
                            //     label: 'Setoran Awal',
                            //     type: 'select',
                            //     placeholder: 'Pilih Setoran Awal',
                            //     options: listDropdown,
                            //     display: "reference_desc",
                            //     key:"reference_code",
                            //     required: true,
                            //     // value: fdState.formData?.produkTabungan,
                            //     // onChange: (value) => fdAction.changeProduct(value),
                            //     // disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                            // },
                    ]},
                    // End Rekening
                    // bagi hasil&desposisi
                    {
                        name: "Bagi Hasil & Disposisi",
                        rows: [
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Nisbah Spesial',
                                                type: 'float',
                                                required: false,
                                                placeholder: '%',
                                                readOnly:false,
                                                value: fdState.formData?._informasiLain?.nisbahCounter, 
                                                onChange: (value) => 
                                                {
                                                    if (value > maxNisbah){
                                                        fdAction.alertNisbah('max');
                                                      } else if(value < minNisbah){
                                                        fdAction.alertNisbah('min');
                                                      }
                                                   fdAction.changeForm(value, "nisbahCounter", "_informasiLain")
                                                },
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                rows: 1,
                                                label: 'Nisbah Berdasarkan Produk',
                                                type: 'currency',
                                                placeholder: '0',
                                                required: false,
                                                readOnly:true,
                                                value: fdState.formData?._informasiLain?.nisbahAkhir || 0,
                                                onChange: (value) =>parseFloat(value)<=100 && fdAction.changeForm(value, "nisbahAkhir", "_informasiLain"),
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col">
                                        {
                                            createForm({
                                                rows: 1,
                                                label: 'Pajak Bagi Hasil',
                                                type: 'currency',
                                                placeholder: '%',
                                                required: false,
                                                readOnly:true,
                                                value: fdState.formData?._informasiLain?.nisbahPajak || 0,
                                                onChange: (value) => parseFloat(value)<=100 && fdAction.changeForm(value, "nisbahPajak", "_informasiLain"),
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Zakat Bagi Hasil',
                                                type: 'currency',
                                                required: false,
                                                placeholder: '%',
                                                readOnly:true,
                                                value: fdState.formData?._informasiLain?.nisbahZakat || 0,
                                                onChange: (value) => parseFloat(value)<=100 && fdAction.changeForm(value, "nisbahZakat", "_informasiLain"),
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            // {
                            //     rows: 1,
                            //     type: 'custom',
                            //     component: (
                            //     // <div className="row" hidden={fdState.paramUrl?.mode !== "edit" || !keycloak.hasRealmRole("TRE")}>
                            //     <div className="row" hidden={fdState.paramUrl?.mode !== "edit" || !keycloak.hasRealmRole("cs")}>
                            //         <div className="col">
                            //             {
                            //                 createForm({
                            //                     rows: 1,
                            //                     label: 'Income Smoothing',
                            //                     type: 'checkbox',
                            //                     className: "pt-2",
                            //                     checked:fdState.formData?._informasiLain?.income_smoothing === "T",
                            //                     value: fdState.formData?._informasiLain?.income_smoothing === "T",
                            //                     onChange: (value) => {
                            //                         fdAction.changeForm(value ? "T" : "F", "income_smoothing", "_informasiLain")
                            //                     },
                            //                 })
                            //             }
                            //         </div>
                            //         <div className="col">
                                        
                            //         </div>
                            //     </div>
                            //     )
                            // },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                <>
                                    {
                                        (fdState.paramUrl?.mode === "registration" && fdState.isPrioritas === "T") || (fdState.paramUrl?.mode === "edit" && keycloak.hasRealmRole("cs")) ?
                                        <>
                                            <div className="row">
                                                <div className="col">
                                                    {
                                                        createForm({
                                                            rows: 1,
                                                            label: `Income Smoothing`,
                                                            type: 'checkbox',
                                                            className: "pt-2",
                                                            checked:fdState.formData?._informasiLain?.income_smoothing === "T",
                                                            value: fdState.formData?._informasiLain?.income_smoothing === "T",
                                                            onChange: (value) => {
                                                                fdAction.changeForm(value ? "T" : "F", "income_smoothing", "_informasiLain")
                                                            },
                                                        })
                                                    }
                                                </div>
                                                <div className="col">
                                                    
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    {
                                        fdState.paramUrl?.mode === "otorisasi" ?
                                        <>
                                            <div className="row">
                                                <div className="col">
                                                    {
                                                        createForm({
                                                            rows: 1,
                                                            label: 'Income Smoothing',
                                                            type: 'checkbox',
                                                            className: "pt-2",
                                                            disabled:true,
                                                            checked:fdState.formData?._informasiLain?.income_smoothing === "T",
                                                            value: fdState.formData?._informasiLain?.income_smoothing === "T",
                                                            onChange: (value) => {
                                                                fdAction.changeForm(value ? "T" : "F", "income_smoothing", "_informasiLain")
                                                            },
                                                        })
                                                    }
                                                </div>
                                                <div className="col">
                                                    
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                </>
                                )
                            },
                            // {
                            //     rows: 1,
                            //     type: 'custom',
                            //     component: (
                            //         <>
                            //             <div className="row">
                            //                 <div className="col-12">
                            //                     {
                            //                         createForm({
                            //                             label: 'Kena Pajak',
                            //                             type: 'checkbox',
                            //                             className: "pt-2",
                            //                             checked:fdState.formData?._informasiLain?.is_kena_pajak,
                            //                             value: fdState.formData?._informasiLain?.is_kena_pajak,
                            //                             onChange: (value) => {
                            //                                 fdAction.changeForm(value ? "T" : "F", "is_kena_pajak", "_informasiLain")
                            //                             },
                            //                             disabled: !fdState.formEditable
                            //                         })
                            //                     }
                            //                 </div>
                            //             </div>
                            //         </>
                            //     )
                            // },
                            {
                                rows: 2,
                                label: 'Instruksi Bagi Hasil',
                                type: 'select',
                                placeholder: 'Pilih Instruksi Bagi Hasil',
                                options: fdState.dataDropDown?.eDisposisiBagiHasil,
                                key: "reference_code",
                                display: "reference_desc",
                                required: true,
                                value: fdState.formData?._rekeningDeposito?.instruksi_bagi_hasil,
                                onChange: (value) => fdAction.changeForm(value, "instruksi_bagi_hasil","_rekeningDeposito"),
                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col-7">
                                        {
                                            createForm({
                                                label: 'Rekening Penerima Bagi Hasil', //'Nomor & Nama Rekening Pindah Buku',
                                                type: 'text',
                                                required: true,
                                                placeholder: 'No. Rekening',
                                                value: fdState.formData?._informasiLain?.qqRekening,
                                                onChange: (value) => fdAction.changeForm(value, "qqRekening","_informasiLain"),
                                                disabled: !fdState.formEditable, // || fdState.paramUrl?.mode === "edit",
                                                onFocusOut:(value)=> fdAction.cekNasabahPindah(value),
                                                onEnter:(value)=> fdAction.cekNasabahPindah(value)
                                            })
                                        }
                                    </div>
                                    <div className="col-5">
                                        {
                                            createForm({
                                                label: <>&nbsp;</>,
                                                type: 'text',
                                                required: false,
                                                placeholder: 'Masukan nama',
                                                readOnly: true,
                                                value: fdState.formData?._informasiLain?.qqName,
                                                onChange: (value) => fdAction.changeForm(value, "qqName","_informasiLain"),
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Instruksi Jatuh Tempo',
                                type: 'select',
                                placeholder: 'Pilih Instruksi Jatuh Tempo',
                                options: fdState.dataDropDown?.eDisposisiDeposito,
                                key: "reference_code",
                                display: "reference_desc",
                                required: true,
                                value: fdState.formData?._rekeningDeposito?.instruksi_jatuh_tempo,
                                onChange: (value) => fdAction.changeForm(value, "instruksi_jatuh_tempo","_rekeningDeposito"),
                                disabled: !fdState.formEditable 
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col-7">
                                        {
                                            createForm({
                                                label: 'Nomor & Nama Rekening Pencairan',
                                                type: 'text',
                                                required: true,
                                                placeholder: 'No. Rekening',
                                                value: fdState.formData?._rekeningDeposito?.no_rekening_pencairan,
                                                onChange: (value) => fdAction.changeForm(value, "no_rekening_pencairan","_rekeningDeposito"),
                                                disabled: !fdState.formEditable, // || fdState.paramUrl?.mode === "edit",
                                                onFocusOut:(value)=> fdAction.cekNasabah(value),
                                                onEnter:(value)=> fdAction.cekNasabah(value)
                                            })
                                        }
                                    </div>
                                    <div className="col-5">
                                        {
                                            createForm({
                                                label: <>&nbsp;</>,
                                                type: 'text',
                                                required: false,
                                                placeholder: 'Masukan nama',
                                                readOnly: true,
                                                value: fdState.formData?._rekeningDeposito?.nama_rekening_pencairan,
                                                onChange: (value) => fdAction.changeForm(value, "nama_rekening_pencairan","_rekeningDeposito"),
                                                disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                    ]},
                    // End bagi hasil&desposisi
                    // Informasi Lain
                    {
                        name: "Informasi Lain",
                        rows: [
                            {
                                rows: 1,
                                type: "select",
                                label: "Tujuan Pembukaan Rekening",
                                required: true,
                                placeholder: "Pilih tujuan pembukaan rekening",
                                options: fdState.dataDropDown?.R_TUJUAN_BUKA_REKENING,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fdState.formData?._informasiLain?.tujuanPembukaanRekening,
                                onChange: (value) => fdAction.changeForm(value, "tujuanPembukaanRekening", "_informasiLain"),
                                disabled: !fdState.formEditable 
                            },
                            {
                                rows: 1,
                                type: "select",
                                label: "Sumber Dana",
                                required: true,
                                placeholder: "Pilih sumber dana",
                                options: fdState.dataDropDown?.R_SUMBER_PENGHASILAN,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fdState.formData?._informasiLain?.sumberDana,
                                onChange: (value) => fdAction.changeForm(value, "sumberDana", "_informasiLain"),
                                disabled: !fdState.formEditable 
                            },
                            {
                                rows: 1,
                                type: "select",
                                label: "Data Instansi",
                                required: false,
                                placeholder: "Pilih data instansi",
                                options: (fdState.dataInstansi || []).map(ins => ({
                                    ...ins,
                                    nama_instansi: `${ins.kode_instansi} - ${ins.nama_instansi}`
                                })),
                                key: "kode_instansi",
                                display: "nama_instansi",
                                value: fdState.formData?.code_instansi,
                                onChange: (value) => fdAction.changeForm(value, "code_instansi"),
                                disabled: !fdState.formEditable 
                            },
                            {
                                rows: 2,
                                type: "select",
                                label: "Marketing Inisiasi",
                                required: true,
                                placeholder: "Pilih marketing inisiasi",
                                disabled: !fdState.formEditable,
                                key:"kode_ao",
                                display:"nama_ao",
                                options:fdState.dataMarketing,
                                value:fdState.formData?._informasiLain?.marketingPertama,
                                onChange:(e) =>fdAction.changeForm(e, "marketingPertama", "_informasiLain")
                            },
                            {
                                rows: 2,
                                type: "select",
                                label: "Marketing Maintenance",
                                required: true,
                                placeholder: "Pilih marketing maintenance",
                                disabled: !fdState.formEditable,
                                options:fdState.dataMarketing,
                                key:"kode_ao",
                                display:"nama_ao",
                                value:fdState.formData?._informasiLain?.marketingSaatIni,
                                onChange:(e) =>fdAction.changeForm(e, "marketingSaatIni", "_informasiLain")
                            },
                            // {
                            //     rows: 2,
                            //     type: "custom",
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-12 pl-3 pr-3">
                            //                 <div className="form-group form-group-sm">
                            //                     <label>Marketing inisiasi </label>
                            //                     <Select
                            //                         placeholder="Pilih marketing inisiasi"
                            //                         onChange={(e) =>{
                            //                             fdAction.changeForm(e.value, "marketingPertama", "_informasiLain")
                            //                         }}
                            //                         value={
                            //                             fdState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao })).find(c => c.value === fdState.formData?._informasiLain?.marketingPertama)
                            //                             }
                            //                         isDisabled={!fdState.formEditable}
                            //                         options={fdState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao }))}
                            //                     />
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            // {
                            //     rows: 2,
                            //     type: "custom",
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-12 pl-3 pr-3">
                            //                 <div className="form-group form-group-sm">
                            //                     <label>Marketing Maintenance </label>
                            //                     <Select
                            //                         placeholder="Pilih marketing Maintenance"
                            //                         onChange={(e) =>fdAction.changeForm(e.value, "marketingSaatIni", "_informasiLain")}
                            //                         value={ 
                            //                             fdState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao })).find(c => c.value === fdState.formData?._informasiLain?.marketingSaatIni) 
                            //                             }
                            //                         isDisabled={!fdState.formEditable }
                            //                         options={fdState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao }))}
                            //                     />
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     )
                            // },
                    ]},
                    // End Informasi Lain
                    // Alamat Alternatif
                    // {
                    //     name: "Alamat Alternatif",
                    //     rows: [
                    //         {
                    //             rows: 1,
                    //             type: "textarea",
                    //             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit",
                    //             label: "Alamat",
                    //             required: false,
                    //             placeholder: "Masukan alamat",
                    //             value: fdState.formData?._alamatAlternatif?.alamat,
                    //             onChange: (value) => fdAction.changeForm(value, "alamat", "_alamatAlternatif"),
                    //             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit",
                    //             rowsText: 4
                    //         },
                    //         {
                    //             rows: 1,
                    //             type: 'custom',
                    //             component: (
                    //             <div className="row">
                    //                 <div className="col">
                    //                     {
                    //                         createForm({
                    //                             label: 'Provinsi',
                    //                             type: 'select',
                    //                             required: false,
                    //                             placeholder: 'Pilih provinsi',
                    //                             options: fdState.provinces,
                    //                             key: "province_name",
                    //                             display: "province_name",
                    //                             value: fdState.formData?._alamatAlternatif?.provinsi,
                    //                             onChange: (value) => {
                    //                                 const data = fdState.provinces?.find(v => v.province_name === value);
                    //                                 fdAction.getingCities(data.province_code)
                    //                                 fdAction.changeForm(value, "provinsi", "_alamatAlternatif")
                    //                             },
                    //                             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                    //                         })
                    //                     }
                    //                 </div>
                    //                 <div className="col">
                    //                     {
                    //                         createForm({
                    //                             label: 'Kota/Kab',
                    //                             type: 'select',
                    //                             required: false,
                    //                             placeholder: 'Pilih Kota/Kabupaten',
                    //                             disabled: false,
                    //                             options: fdState.cities,
                    //                             value: fdState.formData?._alamatAlternatif?.kotaKabupaten,
                    //                             onChange: (value) => {
                    //                                 fdAction.getingDistrict(value)
                    //                                 fdAction.changeForm(value, "kotaKabupaten", "_alamatAlternatif")
                    //                             },
                    //                             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                    //                         })
                    //                     }
                    //                 </div>
                    //             </div>
                    //             )
                    //         },
                    //         {
                    //             rows: 1,
                    //             type: 'custom',
                    //             component: (
                    //             <div className="row">
                    //                 <div className="col">
                    //                     {
                    //                         createForm({
                    //                             label: 'Kecamatan',
                    //                             type: 'select',
                    //                             required: false,
                    //                             placeholder: 'Pilih kecamatan',
                    //                             options: fdState.districts,
                    //                             value: fdState.formData?._alamatAlternatif?.kecamatan,
                    //                             onChange: (value) => {
                    //                                 fdAction.getingVillages(
                    //                                     fdState.formData?._alamatAlternatif?.kotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                    //                                     value 
                    //                                 )
                    //                                 fdAction.changeForm(value, "kecamatan", "_alamatAlternatif")
                    //                             },
                    //                             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                    //                         })
                    //                     }
                    //                 </div>
                    //                 <div className="col">
                    //                     {
                    //                         createForm({
                    //                             label: 'Kelurahan',
                    //                             type: 'select',
                    //                             required: false,
                    //                             placeholder: 'Pilih Kelurahan',
                    //                             disabled: false,
                    //                             options: fdState.villages,
                    //                             value: fdState.formData?._alamatAlternatif?.kelurahan,
                    //                             onChange: (value) => {
                    //                                 fdAction.getingPostalCodes(
                    //                                     fdState.formData?._alamatAlternatif?.kotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                    //                                     fdState.formData?._alamatAlternatif?.kecamatan, // ambil dari data Kota/Kab sebelumnya.
                    //                                     value
                    //                                 )
                    //                                 fdAction.changeForm(value, "kelurahan", "_alamatAlternatif")
                    //                             },
                    //                             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                    //                         })
                    //                     }
                    //                 </div>
                    //             </div>
                    //             )
                    //         },
                    //         {
                    //             rows: 2,
                    //             type: 'custom',
                    //             component: (
                    //             <div className="row">
                    //                 <div className="col">
                    //                     {
                    //                         createForm({
                    //                             label: 'RT/RW',
                    //                             type: 'text',
                    //                             required: false,
                    //                             placeholder: 'RT',
                    //                             value: fdState.formData?._alamatAlternatif?.rt,
                    //                             onChange: (value) => fdAction.changeForm(value, "rt", "_alamatAlternatif"),
                    //                             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                    //                         })
                    //                     }
                    //                 </div>
                    //                 <div className="col">
                    //                     {
                    //                         createForm({
                    //                             label: <>&nbsp;</>,
                    //                             type: 'text',
                    //                             required: false,
                    //                             placeholder: 'RW',
                    //                             disabled: false,
                    //                             value: fdState.formData?._alamatAlternatif?.rw,
                    //                             onChange: (value) => fdAction.changeForm(value, "rw", "_alamatAlternatif"),
                    //                             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                    //                         })
                    //                     }
                    //                 </div>
                    //             </div>
                    //             )
                    //         },
                    //         {
                    //             rows: 2,
                    //             label: 'Kode Pos',
                    //             type: 'select',
                    //             placeholder: 'Pilih kode pos',
                    //             required: false,
                    //             options: fdState.postalCodes,
                    //             value: fdState.formData?._alamatAlternatif?.kodePos,
                    //             onChange: (value) => fdAction.changeForm(value, "kodePos", "_alamatAlternatif"),
                    //             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                    //         },
                    //         {
                    //             rows: 2,
                    //             type: 'custom',
                    //             component: (
                    //             <div className="row">
                    //                 <div className="col-3">
                    //                     {
                    //                         createForm({
                    //                             label: 'Telepon Rumah',
                    //                             type: 'number',
                    //                             required: false,
                    //                             placeholder: '000',
                    //                             value: fdState.formData?._alamatAlternatif?.kode_telepon_rumah,
                    //                             onChange: (value) => fdAction.changeForm(value, "kode_telepon_rumah", "_alamatAlternatif"),
                    //                             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                    //                         })
                    //                     }
                    //                 </div>
                    //                 <div className="col-9">
                    //                     {
                    //                         createForm({
                    //                             label: <>&nbsp;</>,
                    //                             type: 'number',
                    //                             required: false,
                    //                             placeholder: '000',
                    //                             disabled: false,
                    //                             value: fdState.formData?._alamatAlternatif?.telepon,
                    //                             onChange: (value) => fdAction.changeForm(value, "telepon", "_alamatAlternatif"),
                    //                             disabled: !fdState.formEditable || fdState.paramUrl?.mode === "edit"
                    //                         })
                    //                     }
                    //                 </div>
                    //             </div>
                    //             )
                    //         },
                            
                    // ]},
                    // End Alamat Alternatif
                ]}
            />
 {/* form relasi nasbaah */}
            <Modal size="xl" show={pddState.formModal} onHide={pddAction.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                      <strong>Pencarian Data Nasabah</strong>  
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="pb-4">
                {/* <p className="global-text">Cari data berdasarkan :</p> */}
                <div className="row">
                    {/* <div className="col-2">
                        <div className="form-group">
                            <select name="" id="" value={pddState.filter.filterBy} onChange={(e) => pddAction.setFilter(prevState => ({ ...prevState, filterBy: e.target.value }))} className="form-control">
                                <option value={0}>Nama Nasabah</option>
                                <option value="">Nomor CIF</option>
                            </select>
                        </div>
                    </div> */}
                    {/* <div className="col">
                        <div className="form-group">
                            <select name="" id="" value={pddState.filter.filterType} onChange={(e) => pddAction.setFilter(prevState => ({ ...prevState, filterType: e.target.value }))}  className="form-control">
                                <option value={0}>Perorangan</option>
                                <option value={1}>Badan Usaha</option>
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <input
                                value={pddState.filter.keyword}
                                onChange={(e) => pddAction.setFilter(prevState => ({ ...prevState, keyword: e.target.value }))}
                                type="text"
                                className="form-control"
                                placeholder="Keyword"
                            />
                        </div>
                    </div>
                    <div className="col-1 p0">
                        <button onClick={pddAction.searchNasabah} className="btn btn-primary btn-block">Cari</button>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-12">
                        <TableList
                            withCard={false}
                            isLoading={pddState.loadingSearch}
                            data={pddState.listNasabah}
                            columns={[
                                {
                                    name: 'Nomor CIF',
                                    field: 'nomor_cif',
                                    maxWidth: '150px',
                                },
                                {
                                    name: 'Nama CIF',
                                    field: 'nama',
                                    maxWidth: '150px',
                                },
                                {
                                    name: 'Kode Cabang',
                                    field: 'kode_cabang_input',
                                    maxWidth: '75px',
                                },
                                {
                                    name: 'Alamat',
                                    field: 'alamat',
                                    maxWidth: '150px',
                                },
                                {
                                    name: 'Jenis Nasabah',
                                    field: 'jenis_nasabah',
                                    maxWidth: '75px',
                                },
                            ]}
                            pagination={true}
                            withAction={true}
                            actionItems={[
                                {
                                    name: 'Pilih Nasabah',
                                    onClick: async (e) => {
                                        const get = await fdAction.pilihNasabah(e, pddState.filter.jenis_nasabah);
                                        if (get) pddAction.closeModal()
                                    },
                                    className: "text-danger"
                                },
                            ]}
                            onChangeShow={pddAction.changeShow}
                            onNextPage={pddAction.nextPage}
                            onPrevPage={pddAction.prevPage}
                            // filter={false}
                            filter={true}
                            formsFilter={[
                                {
                                    type: 'select',
                                    options: (pddState.listKodeCabang || []).map( ({kode_cabang = "", nama_cabang = ""}, i) => ({
                                        key:kode_cabang,
                                        display:`${kode_cabang} - ${nama_cabang}`
                                    })),
                                    placeholder: 'Kode Cabang',
                                    disabled: false,
                                    readonly: false,
                                    value: pddState?.filter?.kode_cabang,
                                    onChange: (kode_cabang) => pddAction.setFilter(f => ({ ...f, kode_cabang })),
                                },
                                {
                                    type: 'select',
                                    options: [
                                        {
                                            key: "0",
                                            display: 'Perorangan',
                                        },
                                        {
                                            key: "1",
                                            display: 'Badan Usaha',
                                        },
                                    ],
                                    placeholder: 'Jenis Nasabah',
                                    disabled: false,
                                    readonly: false,
                                    value: pddState?.filter?.jenis_nasabah,
                                    onChange: (jenis_nasabah) => pddAction.setFilter(f => ({ ...f, jenis_nasabah })),
                                },
                                {
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Keyword',
                                    disabled: false,
                                    readonly: false,
                                    value: pddState?.filter?.keyword,
                                    onChange: (keyword) => pddAction.setFilter(f => ({ ...f, keyword })),
                                },
                            ]}
                            onFilter={() => pddAction.resetPagination()}
                            onResetFilter={() => {pddAction.resetFilter()}}
                            resetFilter={true}
                            submitButton="Submit"
                        />
                    </div>
                </div>
            </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button onClick={pddAction.closeModal} className="btn btn-sm float-right mr-2 btn-outline-secondary">
                                Batal
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* form cari nasabah */}

        </>
    );
}

export default DataDeposito;