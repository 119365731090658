export const listDenoms = [
    { amount_i: 100000, amount_s: '100.000', type: 'Lembar', count: 0, amount_m: "" },
    { amount_i: 75000, amount_s: '75.000', type: 'Lembar', count: 0, amount_m: "" },
    { amount_i: 50000, amount_s: '50.000', type: 'Lembar', count: 0, amount_m: "" },
    { amount_i: 20000, amount_s: '20.000', type: 'Lembar', count: 0, amount_m: "" },
    { amount_i: 10000, amount_s: '10.000', type: 'Lembar', count: 0, amount_m: "" },
    { amount_i: 5000, amount_s: '5.000', type: 'Lembar', count: 0, amount_m: "" },
    { amount_i: 2000, amount_s: '2.000', type: 'Lembar', count: 0, amount_m: "" },
    { amount_i: 1000, amount_s: '1.000', type: 'Lembar', count: 0, amount_m: "" },
    { amount_i: 1000, amount_s: '1.000', type: 'Koin', count: 0, amount_m: "" },
    { amount_i: 500, amount_s: '500', type: 'Lembar', count: 0, amount_m: "" },
    { amount_i: 500, amount_s: '500', type: 'Koin', count: 0, amount_m: "" },
    { amount_i: 200, amount_s: '200', type: 'Koin', count: 0, amount_m: "" },
    { amount_i: 100, amount_s: '100', type: 'Koin', count: 0, amount_m: "" },
    { amount_i: 50, amount_s: '50', type: 'Koin', count: 0, amount_m: "" },
    { amount_i: 25, amount_s: '25', type: 'Koin', count: 0, amount_m: "" },
    // { amount_i: 10, amount_s: '10', type: 'Koin', count: 0, amount_m: "" },
    // { amount_i: 5, amount_s: '5', type: 'Koin', count: 0, amount_m: "" },
    // { amount_i: 1, amount_s: '1', type: 'Koin', count: 0, amount_m: "" }
];

export const formatCurrency = (numToFormat, prefix) => {
    var separator = ".";
    var number_string = parseInt((numToFormat.toString()).replace(/[^,\d]/g, "")).toString();
    var split = number_string.split(",");
    var rem = split[0].length % 3;
    var rupiah = split[0].substr(0, rem);
    var ribuan = split[0].substr(rem).match(/\d{3}/gi);

    if (ribuan) {
        separator = rem ? "." : "";
        rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
};

export const currencyToInt = (strCurrency) => {
    return parseInt(strCurrency.replace(/[^,\d]/g, ""))
}

export const formatNumber = (number) =>
    new Intl.NumberFormat('id-ID', {
        currency: 'IDR',
        style: 'currency',
    }).format(number);