import React, { memo, useRef } from 'react';
import { useForm } from "react-hook-form";
import { AppAction, AppInterfaces } from "../../../app.data";
import {WalkInCostumer, BasePagePengirimPenerima, BasePageKiriman } from '../../controls';

const KirimanPos_base = memo(({store, ...props})=> {
    const appAction = useRef(null);
    const { register, handleSubmit, setValue } = useForm();

    const reset = () => {
        setValue('pengirim', {nama:  "", kolektif: false, noHp: "", email: "", alamat: "", negara: "", provinsi: "", kota: "", kodePos: ""});
        setValue("penerima", {nama:  "", kolektif: false, noHp: "", email: "", alamat: "", negara: "", provinsi: "", kota: "", kodePos: ""} );
        setValue("dataKiriman0", {noresi: "", tujuan: "", kategori: "", isiKiriman: "", berat: ""});
        setValue("dataKiriman1", {jenisKiriman: "", jenisLayanan: "", barcode: "", nilaiBarang: ""});
        setValue("bea", {beaKirim: "", diskon: "", ppn: "", htnp: "", bayar: ""});
        setValue("berat", {volum: "", gram: ""});
    };
    
    const prosesKiriman = (data) =>{
        // console.log("Data Kiriman Pos", data)
        appAction.current.setShowModal({
            show: true,
            size: "sm",
            backdrop: "static",
            body: <div className="text-center">Apakah data kiriman sudah benar ?</div>,
            footer: <>
                <button className="btn btn-outline-secondary btn-sm px-3" onClick={()=>appAction.current.setCloseModal()}>TIDAK</button>
                <button className="btn btn-primary btn-sm px-4" onClick={()=>OkProses()}>OK</button>
            </>
        })
    };

    const OkProses = () => appAction.current.setShowModal({
        show: true,
        size: "sm",
        backdrop: "static",
        body: <p className="text-center">Kiriman dengan<br/>
            No. transaksi : 129879416423121<br/>
            Barcode : 8312731831<br/><br/>  
            telah diproses </p>,
        footer: <button className="btn btn-primary btn-sm px-4" onClick={()=>{appAction.current.setCloseModal(); reset();}}>OK</button>
    });

    return(
        <div className="p-3" >
            <AppAction ref={appAction} />
            <WalkInCostumer />
            <div className="row">
                <div className="col-12 pt-2">
                    <div className="card card-body px-3 py-0">
                        <div className="row">
                            <div className="col-5 px-4 pt-4">
                                <BasePagePengirimPenerima register={register} type="pengirim" disable={store.isSukses}/>
                                <BasePagePengirimPenerima register={register} type="penerima" disable={store.isSukses}/>
                            </div>
                            <div className="col-7 border-left px-4 pt-4">
                                <div className="row">
                                    <div className="col-6">
                                        <BasePageKiriman register={register} options={
                                            [
                                                {
                                                    title: <>Data Kiriman</>,
                                                    type: "dataKiriman0",
                                                    forms: [
                                                        {type: "text", name: "noresi", required: true, label: "No. Resi", disable: false, placeholder: "Generate no resi", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        {type: "select", name: "tujuan", required: true, label: "Tujuan", disable: false, options: [
                                                            {id: 1, name: "One"},{id: 2, name: "Two"},{id: 3, name: "Three", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        ]},
                                                        {type: "select", name:"kategori", required: true, label: "Kategori", disable: false, options: [
                                                            {id: 1, name: "One"},{id: 2, name: "Two"},{id: 3, name: "Three", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        ]},
                                                        {type: "text", name:"isiKiriman", required: true, label: "Isi Kiriman", disable: false, placeholder: "Apa isi kiriman...", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        {type: "text", name:"berat", required: true, label: "Berat Aktual (gram)", style: "text-right", disable: false, placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                    ],
                                                },
                                            ]
                                        }/>
                                        <h5>Volume Matrik</h5>
                                        <table className="table table-sm table-borderless">
                                            <thead></thead>
                                            <tbody>
                                                <tr>
                                                    <td className="f-14 py-1" colSpan="2">
                                                        <div className="row align-items-center">
                                                            <div className="col-1 text-left">P </div>
                                                            <div className="col-1 px-0">
                                                                <input className="form-control form-control-sm" disabled onFocus={(e)=>e.target.select()}
                                                                    // onChange={(e) => this.handleOnChange("p", e.target.value.replace(/[^0-9]/g, ''))} 
                                                                    />
                                                            </div>
                                                            <div className="col-1 px-0 text-center">L</div>
                                                            <div className="col-1 px-0">
                                                                <input className="form-control form-control-sm" disabled onFocus={(e)=>e.target.select()} 
                                                                    // onChange={(e) => this.handleOnChange("l", e.target.value.replace(/[^0-9]/g, ''))} 
                                                                    />
                                                            </div>
                                                            <div className="col-1 px-0 text-center">T</div>
                                                            <div className="col-1 px-0">
                                                                <input className="form-control form-control-sm" disabled onFocus={(e)=>e.target.select()} 
                                                                    // onChange={(e) => this.handleOnChange("t", e.target.value.replace(/[^0-9]/g, ''))} 
                                                                    />
                                                            </div>
                                                            <div className="col-1 px-0 text-center">=</div>
                                                            <div className="col-5 pl-0">
                                                                <input className="form-control form-control-sm" disabled onFocus={(e)=>e.target.select()} 
                                                                    // onChange={(e) => this.handleOnChange("t", e.target.value.replace(/[^0-9]/g, ''))} 
                                                                    />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <BasePageKiriman register={register} options={
                                            [
                                                {
                                                    type: "berat",
                                                    forms: [
                                                        {type: "text", name:"volum", required: true, label: "Berat Volume", style: "text-right", disable: false, placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        {type: "text", name:"gram", required: true, label: "Berat (gram)", style: "text-right", disable: false, placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                    ],
                                                },
                                            ]
                                        }/>
                                    </div>
                                    <div className="col-6">
                                        <BasePageKiriman register={register} options={
                                            [
                                                {
                                                    title: <>&nbsp;</>,
                                                    type: "dataKiriman1",
                                                    forms: [
                                                        {type: "select", name:"jenisKiriman", required: true, label: "Jenis Kiriman", disable: false, options: [
                                                            {id: 1, name: "One"},{id: 2, name: "Two"},{id: 3, name: "Three", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        ]},
                                                        {type: "select", name:"jenisLayanan", required: true, label: "Jenis Layanan", disable: false, options: [
                                                            {id: 1, name: "One"},{id: 2, name: "Two"},{id: 3, name: "Three", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        ]},
                                                        {type: "text", name:"barcode", required: true, label: "Barcode Internasional", disable: true, placeholder: "-", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        {type: "text", name:"nilaiBarang", required: true, label: "Nilai Barang (Rp)", disable: false, placeholder: "-", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                    ],
                                                },
                                                {
                                                    title: "Bea",
                                                    type: "bea",
                                                    forms: [
                                                        {type: "text", name:"beaKirim", required: true, label: "Bea Kirim (Rp)", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        {type: "text", name:"diskon", required: true, label: "Diskon", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        {type: "text", name:"ppn", required: true, label: "Ppn", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        {type: "text", name:"htnp", required: true, label: "HTNB", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                        {type: "text", name:"bayar", required: true, label: "Jumlah Bayar (Rp)", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)}
                                                    ],
                                                },
                                            ]
                                        } />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card card-body px-3">
                        <span className="clearfix">
                            <button className="btn btn-outline-primary px-5 mx-1 float-right">BAYAR</button>
                            <form onSubmit={handleSubmit(prosesKiriman)}>
                                <button className="btn btn-primary px-5 mx-1 float-right" type="submit">PROSES</button>
                            </form>
                            <button className="btn  px-5 mx-1 float-right" onClick={()=>reset()}>RESET</button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
})

const KirimanPos = AppInterfaces.appLoad(KirimanPos_base)
export default KirimanPos;