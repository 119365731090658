import React, { memo, useRef } from 'react';
import {TableList } from '../../../controls';

class SetoranKliring extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  render() {
      return(
        <>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mt-2 mb-3">
              <li className="breadcrumb-item color-primary">Kliring</li>
              <li className="breadcrumb-item"><a href="#">Daftar Setoran Kliring</a></li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-white">
                  <h5 className="card-title">DAFTAR SETORAN KLIRING</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <TableList
                        columns={[
                            {
                                name: "#",
                                field: "#",
                                maxWidth: "50px"
                            },
                            {
                                name: "Tanggal Input",
                                field: "no_nasabah",
                                maxWidth: "50px"
                            },
                            {
                                name: "No. Referensi",
                                field: "nama_lengkap",
                                maxWidth: "50px"
                            },
                            {
                                name: "Nama Bank",
                                field: "address",
                                maxWidth: "50px"
                            },
                            {
                                name: "Nama Pemilik Nota",
                                field: "gender",
                                maxWidth: "50px"
                            },
                            {
                                name: "Nominal",
                                field: "jenis_identitas",
                                maxWidth: "50px"
                            },
                            {
                                name: "Biaya",
                                field: "nomor_identitas",
                                maxWidth: "50px"
                            },
                            {
                                name: "No. Rekening",
                                field: "nomor_identitas",
                                maxWidth: "50px"
                            },
                            {
                                name: "Kode Valuta",
                                field: "nomor_identitas",
                                maxWidth: "50px"
                            },
                            {
                                name: "No. Aplikasi",
                                field: "nomor_identitas",
                                maxWidth: "50px"
                            },
                        ]}

                        data={[]}

                        filter={true}
                        formsFilter = {[
                            {
                                type:"select",
                                options:[],
                                placeholder:"Jenis Nasabah",
                                disabled:false,
                                readonly:false,
                                onChange:(value)=>null
                            },{
                                type:"text",
                                placeholder:"Kode Cabang",
                                disabled:false,
                                readonly:false,
                                onChange:(value)=>null
                            },{
                                type:"text",
                                placeholder:"Jenis Nasabah",
                                disabled:false,
                                readonly:false,
                                onChange:(value)=>null
                            },{
                                type:"text",
                                options:[],
                                placeholder:"search",
                                disabled:false,
                                readonly:false,
                                onChange:(value)=>null
                            }
                        ]}
                        onFilter={(e)=>null}
                        resetFilter={true}
                    />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
      )
  }
}

export default SetoranKliring;