import React, { createRef, useState } from "react";
import ImageCropper from "./modal/ImageCropper";
import useSvs from "./useSvs";

const NewImages = (props) => {
  const { svsAction, svsState } = useSvs();
  const [uploadRef, setUploadRef] = useState([]);
  const [idx, setIdx] = useState(0);

  const handleCallback = (ev) => {
    // console.log(ev)
    if (ev.mode === "nocrop") {
      svsAction.handleFileCropChange(ev.data, idx);
    } else {
      ev.data.then((e) => svsAction.handleFileCropChange(e, idx));
    }
  };

  React.useEffect(() => {
    let tmp = [];
    svsState.svsData.svsParams.forEach((element) => {
      tmp.push(createRef());
    });
    setUploadRef(tmp);
  }, [svsState.svsData.svsParams]);

  return (
    <>
      <div className="row" >
        {svsState.svsData.svsParams.map((data, index) => {
          return (
            <div className="col-lg-6 mb-3" key={index}>
              <div className="row px-3">
                <p className="mt-2">Image {index + 1}</p>
                <div className="col-12">
                  <div className="row p-0 svs-box-new-images">
                    <div className="col-md-6 p-0">
                      {data.options.selectedFileUrl ? (
                        <img
                          style={{
                            width: "100%",
                            height: "207px",
                            objectFit: "contain",
                            border: "none",
                          }}
                          src={data.options.selectedFileUrl ? data.options.selectedFileUrl : null}
                        />
                      ) : null}
                    </div>
                    <div className="col-md-6 p-3 svs-border-left">
                      <div className="form-group mt-2">
                        <label htmlFor="">Image Tag</label>
                        <select
                          name="image_tag"
                          className="form-control"
                          onChange={(e) => svsAction.handleTextChanged(e, index)}
                          value={data.data.image_tag}>
                          <option value="">Pilih Image Tag</option>
                          <option value="sign">Tanda Tangan</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Remark</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan remark"
                          name="remark"
                          value={data.data.remark}
                          onChange={(e) => {
                            svsAction.handleTextChanged(e, index);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-7">
                            <input
                              type="file"
                              style={{ display: "none" }}
                              ref={uploadRef[index]}
                              onChange={(e) => {
                                svsAction.handleFileChange(e, index);
                              }}
                            />
                            <button
                              onClick={() => {
                                setIdx(index);
                                uploadRef[index].current.click();
                              }}
                              className="btn btn-orange-primary btn-block">
                              Pilih File
                            </button>
                          </div>
                          <div className="col-5">
                            <button
                              className="btn btn-outline-secondary btn-block"
                              onClick={() => svsAction.handleFileRemove(index)}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <hr />
      <div className="row">
        <div className="col d-flex justify-content-end">
          <button
            className="btn btn-primary"
            onClick={() =>
              svsAction.setDataConfirm({
                show: true,
                body:"Apakah anda yakin ingin input SVS?",
                witTextArea: false,
                header: "Konfirmasi",
                title: "",
                closeButton: false,
                footerButton: [
                  {
                    name: "Ya",
                    onClick: () => {
                      svsAction.postSVS()
                      svsAction.closeConfirm()
                    },
                    className: "btn btn-sm btn-success",
                  },
                  {
                    name: "Tidak",
                    onClick: () => svsAction.closeConfirm(),
                    className: "btn btn-sm btn-secondary",
                  },
                ],
              })
            }>
            Submit
          </button>
        </div>
      </div>
      <ImageCropper
        show={svsState.modalCropper}
        data={svsState.svsData.svsParams[idx].options.originalFile}
        hide={() => svsAction.setModalCropper(false)}
        callBack={handleCallback}
      />
    </>
  );
};

export default NewImages;
