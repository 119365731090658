import React, { useEffect, useState } from 'react';
import useFetch from './useFetch';

const useAlamat = () => {
    const { doGet } = useFetch();
    const [provinces, setProvinces] = useState([])

    useEffect(function () {
        getProvinces();
    }, []);

    async function getProvinces() {
        let datax = []
        try {
            const resp = await doGet({ url: "/locations/provinces" });
            const { statusCode, status, statusText, data = {}, remark } = resp;
            // // console.log(resp);
            if (status === "00") {
                setProvinces(data.listProvinces)
                datax = data.listProvinces
            };
        } catch (error) {
            throw error
        }
        return datax
    }

    async function getCities(province_code) {
        let datax = []
        if(province_code !== null && province_code !== "" && province_code !== undefined)
        {
            try {
                const resp = await doGet({ url: "/locations/cities_by_prov", param: { province_code } });
                const { statusCode, status, statusText, data = {}, remark } = resp;
                // // console.log(data)
                if (status === "00") datax = data.listCities;
            } catch (error) {
                return []
            }
        }
        return datax;
    }

    async function getDistrics(city) {
        let datax = []
        try {
            const resp = await doGet({ url: "/locations/subdistrict", param : { city } });
            const { statusCode, status, statusText, data = {}, remark } = resp;
            // // console.log(resp);
            if (status === "00") datax = data.listSubdistricts;
        } catch (error) {
             return []
        }
        return datax;
    }

    async function getVillages(city, subdistrict) {
        let datax = []
        try {
            const resp = await doGet({ url: "/locations/urban", param : {city, subdistrict} });
            const { statusCode, status, statusText, data = { city, subdistrict}, remark } = resp;
            // // console.log(resp);
            if (status === "00") datax = data.listUrbans;
        } catch (error) {
             return []
        }

        return datax
    }

    async function getPostalsCode(city, subdistrict, urban) {
        // // console.log(city, subdistrict, urban)
        let datax = []
        try {
            const resp = await doGet({ url: "/locations/postal-code", param : { city, subdistrict, urban }});
            const { statusCode, status, statusText, data = {}, remark } = resp;
            // // console.log(resp);
            if (status === "00") datax = data.listUrbans;
        } catch (error) {
             return []
        }

        return datax;
    }

    return {
        provinces,
        getProvinces,
        getCities,
        getVillages,
        getPostalsCode,
        getDistrics
    };
}

export default useAlamat;