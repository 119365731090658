import React, { createContext, useContext, useEffect, useState } from 'react';
import useKeycloak from '../../../hooks/keycloak.module';
import useFetch from '../../../hooks/useFetch';
import { useModalAlert } from '../../controls/modalAlert';
import moment from 'moment';
import { useLocalStorage } from '../../../module';

const infoATMContext = createContext({
    iAtmState: {},
    iAtmAction: {}
})

export const IAtmProvider = ({ children }) => {

    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction
    const { doGet, doPost, wait } = useFetch();
    const [loadingGet, setLoadingGet] = useState(true);
    const [dataList, setDataList] = useState([])
    const { keycloak } = useKeycloak()

    const [ POD_DATE ] = useLocalStorage("POD_DATE",  moment(new Date()).format("YYYY-MM-DD") )
    const [filter, setFilter] = useState({ tanggal_awal: moment(POD_DATE, "YYYY-MM-DD").format("DD-MM-YYYY"), tanggal_akhir: moment(POD_DATE, "YYYY-MM-DD").format("DD-MM-YYYY") })
    function resetFilter(){
        setFilter({ tanggal_awal: moment(POD_DATE, "YYYY-MM-DD").format("DD-MM-YYYY"), tanggal_akhir: moment(POD_DATE, "YYYY-MM-DD").format("DD-MM-YYYY") })
    }
    async function getDataList() {
        setLoadingGet(true)
        try {
            let resp = await doGet({
                url: `/kasatm/informasi-kas-atm`,
                service: "acc",
                param: {
                    all_branch: (keycloak?.tokenParsed?.branch_code || "000") === "000" ? "T" : "F"
                }
            });
            const { statusCode, remark = "", data = [], status = "01", statusText = "", message = "" } = resp
            // // console.log(resp)
            if (statusCode === 200) {
                if (status === "00") setDataList(data || [])
                else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
            } else setDataAlert({ show: true, body: `${remark}. ${message}`, title: `${statusText}`, header: "Info", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        setLoadingGet(false)
    }
    const [pagination, setPagination] = useState({
        page: 1, limit: 15
    });


    // next pagination
    function nextPage({ page, limit }) {
        setPagination({
            page,
            limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            limit
        })
    }
    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            limit
        })
    }
  
    useEffect(function () {
        getDataList()
    }, [])
    const [showDetil, setShowDetil] = useState(false)
    const [dataSelected, setDataSelected] = useState({
        nama_rekening: "",
        saldo: 0,
        valuta: "",
        nama_valuta: "",
        kode_cabang: "",
        nama_cabang: "",
        nomor_rekening:""
    })
    function openDetail(item) {
        setDataSelected(item)
        setShowDetil(true)
    }
    function closeDetail() {
        setShowDetil(false)
    }
    const [loadingDetail, setLoadingDetail] = useState(false)
    const [ dataDetail, setDataDetail] = useState({
        saldo_awal : 0,
        list:[]
    })
    async function getDetailTrx() {
        setLoadingDetail(true)
        try {
            let resp = await doGet({
                url: `/cashatm/detil-transaksi`,
                service: "trx",
                param: {
                    ...pagination,
                    tanggal_awal: moment(filter.tanggal_awal, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    tanggal_akhir: moment(filter.tanggal_akhir, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    nomor_rekening: dataSelected.nomor_rekening
                }
            });
            const { statusCode, remark = "", data = [], status = "01", statusText = "", message = "" } = resp
            // // console.log(resp)
            if (statusCode === 200) {
                if (status === "00") setDataDetail(p=>({
                    saldo_awal: data?.saldo_awal_periode,
                    list: data?.data_transaksi || []
                }))
                else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
            } else setDataAlert({ show: true, body: `${remark}. ${message}`, title: `${statusText}`, header: "Info", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        setLoadingDetail(false)
    }
    useEffect(function(){
        if(showDetil && (dataSelected.nomor_rekening !== null && dataSelected.nomor_rekening !== undefined && dataSelected.nomor_rekening !== "" )){
            getDetailTrx()
        }
    },[showDetil, dataSelected, pagination])

    function doFilter(){
        setPagination( p=>({
            ...p,
            page:1
        }))
    }
    return <infoATMContext.Provider value={{
        iAtmState: {
            dataList,
            loadingGet,
            showDetil,
            dataSelected,
            pagination,
            filter,
            loadingDetail,
            dataDetail
        },
        iAtmAction: {
            openDetail,
            closeDetail,
            setFilter,
            nextPage,
            prevPage,
            changeShow,
            doFilter
        }
    }}>{children}</infoATMContext.Provider>
}


export default function useInfoAtm() {
    return useContext(infoATMContext);
}
