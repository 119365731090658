import React, { memo, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AppAction, AppInterfaces } from '../../../../../../app.data';
import Step1 from './detil_step1';
import Step2 from './detil_step2';
import { AlertConfirm, Stepper } from '../../../../../controls';

export default AppInterfaces.appLoad(
    memo(function ({ store, ...props }) {
        const location = useLocation();
        const [step, setStep] = useState(0);
        const appAction = useRef(null);
        const history = useHistory();

        // console.log(location);
        useEffect(function () {
            // console.log(history);
            appAction.current
                .doGet({
                    url: `/deposito/lokal/akun/cari-db-id?db_id=${location.state?.data}`,
                    vari: 'detil_deposito',
                })
                .then(({ status, message_id, list }) =>
                    message_id === '00' ? appAction.current.setDefaultForm(list) : history.goBack()
                );
        }, []);

        return (
            <>
                <AppAction ref={appAction} />
                <nav aria-label="breadcrumb" className="mb-3">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="#">Deposito</a>
                        </li>
                        {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                        <li className="breadcrumb-item active" aria-current="page">
                            Informasi Deposito
                        </li>
                    </ol>
                </nav>
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col p-3">
                                <div style={{ width: '200px', margin: 'auto' }}>
                                    <Stepper
                                        activeIdx={step}
                                        manualDone={false}
                                        // onChange = {(_activeIdx, _prevIdx) => {
                                        //     setStep(_activeIdx)
                                        // }}
                                        onStepClick={(_idx) => {
                                            setStep(_idx);
                                        }}
                                        steps={[
                                            {
                                                name: 'Data Deposito',
                                                id: '0',
                                                whiteSpace: 'nowrap',
                                                isDone: false,
                                            },
                                            {
                                                name: 'Informasi Lain',
                                                id: '1',
                                                whiteSpace: 'nowrap',
                                                isDone: false,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {step === 0 ? <Step1 mode={2} /> : <Step2 mode={2} />}
                    </div>
                    <div className="card-footer">
                        <div className="float-right">
                            <button
                                disabled={step === 0}
                                onClick={() => setStep((prevStep) => prevStep - 1)}
                                className="btn btn-sm btn-secondary mr-3"
                            >
                                Sebelumnya
                            </button>
                            <button
                                disabled={step === 1}
                                onClick={() => setStep((prevStep) => prevStep + 1)}
                                className="btn btn-sm btn-danger"
                            >
                                Selanjutnya
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    })
);
