import React, { memo, useEffect, useRef, useState } from 'react';
import { AppAction, AppInterfaces } from '../../../../app.data';
import { createForm } from '../../../../module/createFormList';
import { LoadingIcon } from '../../../controls';
import { FormRegistrasi } from './formRegistrasi';
import moment from 'moment';

const Step1 = AppInterfaces.appLoad(
    memo(({ editMode = false, store, ...props }) => {
        const { formData, loadingGet } = store;
        const appAction = useRef(null);

        function changeForm(key, subkey, value) {
            appAction.current.setForm(key, {
                ...formData[key],
                [subkey]: value,
            });
        }

        const [errDate, setErrDate] = useState(false);
        const [disabledForm, setDisabledForm] = useState(true);
        const dropdownDetil = [
            {
                key: 'eDisposisiDeposito',
                param: 'disposisi_deposito',
            },
            {
                key: 'eDisposisiBagiHasil',
                param: 'disposisi_bg_hasil',
            },
        ];
        useEffect(function () {
            dropdownDetil.forEach(({ key, param }) => {
                appAction.current.doGet({
                    url: '/dropdowns',
                    vari: param,
                    param: {
                        key,
                    },
                });
            });
        }, []);

        function getProduct(productType) {
            appAction.current.doGet({
                url: '/depositproducts',
                vari: 'deposito_products',
                param: {
                    detailsLevel: 'FULL',
                    productType,
                },
            });
        }

        function searchNasabahBadanUsaha() {
            appAction.current
                .doGet({
                    url: `/nasabah-badan-usaha/find/${formData._dataNasabah?.nomorCIF}`,
                    vari: 'nasabahDetil',
                    param: {
                        detailsLevel: 'FULL',
                    },
                    withAlert: false,
                })
                .then(({ statusCode, status, message_id, list }) => {
                    if (message_id === '00') {
                        changeForm('_rekening', 'tipeAkunNasabah', 'GROUP');
                        getProduct('BUSINESS_DEPOSIT');
                        appAction.current.setForm('_dataNasabah', {
                            ...formData._dataNasabah,
                            nama: list.groupName,
                            teleponRumah: list._identitasNasabah.nasabahTanggalAktePendirian,
                            teleponHP: list.mobilePhone,
                            namaIbuKandung: '',
                            tempatTanggalLahir: list._identitasNasabah.nasabahTanggalAktePendirian,
                            alamat: list._identitasNasabah.nasabahAlamat,
                        });

                        setDisabledForm(false);
                    } else {
                        let tipe = 'warning';
                        appAction.current.disp({
                            type: 'openAlert',
                            message: 'Data Not Found',
                            tipe,
                            // msgErr: resp.message
                        });

                        setDisabledForm(true);
                        appAction.current.setForm('_dataNasabah', {
                            ...formData._dataNasabah,
                            nama: '',
                            teleponRumah: '',
                            teleponHP: '',
                            namaIbuKandung: '',
                            tempatLahir: '',
                            tanggalLahir: '',
                            alamat: '',
                            nomorCIF: '',
                        });
                        appAction.current.wait(3000).then((resp) => appAction.current.closeAlert());
                    }
                });
        }

        function searchNasabah() {
            formData._dataNasabah?.nomorCIF !== '' &&
                formData._dataNasabah?.nomorCIF !== undefined &&
                formData._dataNasabah?.nomorCIF !== null &&
                appAction.current
                    .doGet({
                        url: `/nasabah/perorangan/find/${formData._dataNasabah?.nomorCIF}`,
                        vari: 'nasabahDetil',
                        withAlert: false,
                    })
                    .then(({ statusCode, status, message_id, list }) => {
                        if (message_id === '00') {
                            getProduct('PERSONAL_DEPOSIT');
                            changeForm('_rekening', 'tipeAkunNasabah', 'CLIENT');
                            appAction.current.setForm('_dataNasabah', {
                                ...formData._dataNasabah,
                                nama:
                                    (list.fistName || '') +
                                    ' ' +
                                    (list.middleName || '') +
                                    ' ' +
                                    (list.lastName || ''),
                                teleponRumah: list.homePhone,
                                teleponHP: list.mobilePhone === null ? '' : list.mobilePhone,
                                namaIbuKandung:
                                    list._customerData.personalMothersName === null
                                        ? ''
                                        : list._customerData.personalMothersName,
                                tempatLahir: list._customerData.birthPlace,
                                tanggalLahir: list._customerData.birthDate,
                                alamat: list._ktpAddress.ktpAddress,
                            });
                            setDisabledForm(false);
                            // appAction.current.setForm("_rekening", {
                            //     ...formData._rekening,
                            //     // nomorRekeningDebet:
                            // })
                        } else {
                            searchNasabahBadanUsaha();
                            setDisabledForm(true);
                        }
                    });
        }

        function changeProduct(value) {
            var produk = store.deposito_products?.find((v) => v.encodedKey === value)?.name;
            appAction.current.setForm('_rekening', {
                ...formData._rekening,
                nomorProduk: value,
                produk,
            });

            appAction.current
                .doGet({
                    url: `/depositproducts/find/${value}`,
                    vari: 'productDetil',
                    withAlert: false,
                })
                .then(({ statusCode, status, message_id, list }) => {
                    const { fee } = list;
                    if (message_id === '00') {
                        appAction.current.setForm('_bagiHasilDisposisi', {
                            ...formData._bagiHasilDisposisi,
                            nisbah: fee?.nisbah_nasabah || 0,
                            zakatBagiHasil: fee?.zakat || 0,
                            pajakBagiHasil: fee?.pajak || 0,
                        });
                    }
                });
        }

        function changeDate(key, date) {
            var k = 'tanggalBuka';
            var from = moment(formData._rekening?.[k]);
            var to = moment(date);
            if (key === k) {
                k = 'tanggalJatuhTempo';
                from = moment(date);
                to = moment(formData._rekening?.[k]);
            }
            // console.log(to, from);
            if (from >= to) {
                setErrDate(true);
            } else {
                setErrDate(false);
                changeForm('_rekening', key, date);
            }
        }
        // console.log(formData, 'formdata');
        return (
            <>
                <AppAction ref={appAction} />
                {
                    <div
                        className="row"
                        style={{
                            border: '1pt solid #d2d4d7',
                            margin: '0px 10px',
                            borderRadius: '6px',
                            padding: '5px',
                            paddingTop: '20px',
                            marginBottom: '30px',
                        }}
                    >
                        <div className="col-md-6">
                            <div className="form-group form-group-sm col-md-6">
                                <label
                                    style={{
                                        fontSize: '10pt',
                                    }}
                                >
                                    Nomor Rekening Deposito
                                </label>
                                <input
                                    value={formData.nomorRekeningDeposito}
                                    disabled={true}
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <span
                                className="float-right"
                                style={{
                                    position: 'absolute',
                                    bottom: 10,
                                    right: 20,
                                }}
                            >
                                Kode Cabang : 0000
                            </span>
                        </div>
                    </div>
                }
                <FormRegistrasi
                    itemsForm={[
                        {
                            name: 'Data Nasabah',
                            rows: [
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-8">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'CIF',
                                                    type: 'number',
                                                    placeholder: 'CIF',
                                                    disabled: editMode,
                                                    readonly: false,
                                                    required: true,
                                                    value: formData._dataNasabah?.nomorCIF,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_dataNasabah',
                                                            'nomorCIF',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-group-sm">
                                                    {/* <label>&nbsp;</label> */}
                                                    <button
                                                        disabled
                                                        onClick={searchNasabah}
                                                        style={{
                                                            marginTop: 30,
                                                            background: '#E6530D',
                                                        }}
                                                        className="btn btn-sm btn-primary col"
                                                    >
                                                        {loadingGet ? (
                                                            <LoadingIcon />
                                                        ) : (
                                                            <>Cari Nasabah</>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 1,
                                    label: 'Nama',
                                    type: 'text',
                                    placeholder: 'Nama',
                                    disabled: disabledForm || editMode,
                                    value: formData._dataNasabah?.nama,
                                    readonly: false,
                                    onChange: (value) => changeForm('_dataNasabah', 'nama', value),
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'No Telelpon',
                                                    type: 'text',
                                                    placeholder: 'No Telelpon',
                                                    value: formData._dataNasabah?.teleponRumah,
                                                    disabled: disabledForm || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_dataNasabah',
                                                            'teleponRumah',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'No Handphone',
                                                    type: 'text',
                                                    placeholder: 'No Handphone',
                                                    value: formData._dataNasabah?.teleponHP,
                                                    disabled: disabledForm || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_dataNasabah',
                                                            'teleponHP',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    placeholder: 'Nama Ibu Kandung ',
                                    type: 'text',
                                    options: [],
                                    label: 'Nama Ibu Kandung',
                                    value: formData._dataNasabah?.namaIbuKandung,
                                    disabled: disabledForm || editMode,
                                    readonly: false,
                                    onChange: (value) =>
                                        changeForm('_dataNasabah', 'namaIbuKandung', value),
                                },
                                {
                                    rows: 2,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-7">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tempat / Tanggal Lahir',
                                                    type: 'text',
                                                    placeholder: 'Tempat',
                                                    value: formData._dataNasabah?.tempatLahir,
                                                    disabled: disabledForm || editMode,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_dataNasabah',
                                                            'tempatLahir',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                            <div className="col-md-5">
                                                {createForm({
                                                    rows: 1,
                                                    label: <>&nbsp;</>,
                                                    type:
                                                        disabledForm || editMode ? 'text' : 'date',
                                                    placeholder: 'tgl lahir',
                                                    disabled: disabledForm || editMode,
                                                    value: formData._dataNasabah?.tanggalLahir,
                                                    readonly: false,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_dataNasabah',
                                                            'tanggalLahir',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    placeholder: 'Alamat',
                                    type: 'text',
                                    options: [],
                                    label: 'Alamat',
                                    disabled: disabledForm || editMode,
                                    readonly: false,
                                    value: formData._dataNasabah?.alamat,
                                    onChange: (value) =>
                                        changeForm('_dataNasabah', 'alamat', value),
                                },
                            ],
                        },
                        {
                            name: 'Rekening',
                            rows: [
                                {
                                    rows: 1,
                                    label: 'Nomor Rekening Debet',
                                    type: 'number',
                                    options: [],
                                    placeholder: ' Nomor Rekening Debet',
                                    disabled: disabledForm || editMode,
                                    readonly: false,
                                    value: formData._rekening?.nomorRekeningDebet,
                                    onChange: (value) =>
                                        changeForm('_rekening', 'nomorRekeningDebet', value),
                                },
                                {
                                    rows: 1,
                                    label: 'Nama Rekening',
                                    type: 'text',
                                    options: [],
                                    placeholder: ' Nama Rekening',
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._rekening?.namaRekening,
                                    onChange: (value) =>
                                        changeForm('_rekening', 'namaRekening', value),
                                },
                                {
                                    rows: 1,
                                    label: 'Nomor Bilyet',
                                    type: 'number',
                                    options: [],
                                    placeholder: ' Nomor Bilyet',
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._rekening?.nomorBilyet,
                                    onChange: (value) =>
                                        changeForm('_rekening', 'nomorBilyet', value),
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tanggal Buka',
                                                    type: 'date',
                                                    placeholder: 'Tanggal Buka',
                                                    disabled: false || editMode,
                                                    readonly: false,
                                                    value: formData._rekening?.tanggalBuka,
                                                    onChange: (value) =>
                                                        changeDate('tanggalBuka', value),
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tanggal Jatuh Tempo',
                                                    type: 'date',
                                                    placeholder: 'Tanggal Jatuh Tempo',
                                                    disabled: false || editMode,
                                                    readonly: false,
                                                    value: formData._rekening?.tanggalJatuhTempo,
                                                    onChange: (value) =>
                                                        changeDate('tanggalJatuhTempo', value),
                                                })}
                                            </div>
                                            <div className="col-md-12">
                                                {errDate && (
                                                    <span style={{ color: 'red' }}>
                                                        Tanggal buka tidak bisa lebih dari tanggal
                                                        jatuh tempo
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    label: 'Produk Deposito',
                                    type: 'select',
                                    options: store.deposito_products,
                                    key: 'encodedKey',
                                    display: 'name',
                                    // options: [
                                    //     {
                                    //         key: "Nisbah",
                                    //         display:"Nisbah"
                                    //     },

                                    //     {
                                    //         key: "Pajak Bagi Hasil",
                                    //         display:"Pajak Bagi Hasil"
                                    //     },

                                    //     {
                                    //         key: "Nisbah Bagi Hasil",
                                    //         display:"Nisbah Bagi Hasil"
                                    //     }
                                    // ],
                                    placeholder: 'Pilih produk deposito',
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._rekening?.nomorProduk,
                                    onChange: (value) => {
                                        changeProduct(value);
                                    },
                                },
                                {
                                    rows: 2,
                                    label: 'Valuta',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'valuta',
                                    disabled: true || editMode,
                                    readonly: true,
                                    value: formData._rekening?.valuta,
                                    onChange: (value) => changeForm('_rekening', 'valuta', value),
                                },
                                {
                                    rows: 2,
                                    label: 'Nominal Deposito',
                                    type: 'number',
                                    options: [],
                                    placeholder: 'Masukkan nominal deposito',
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._rekening?.nominalDeposito,
                                    onChange: (value) =>
                                        changeForm('_rekening', 'nominalDeposito', value),
                                },
                                {
                                    rows: 2,
                                    label: 'Keterangan',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Keterangan',
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._rekening?.keterangan,
                                    onChange: (value) =>
                                        changeForm('_rekening', 'keterangan', value),
                                },
                            ],
                        },
                        {
                            name: 'Bagi Hasil & Disposisi',
                            rows: [
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Nisbah Counter              ( % )',
                                                    type: 'number',
                                                    placeholder: 'Nisbah',
                                                    value: formData._bagiHasilDisposisi
                                                        ?.nisbahCounter,
                                                    disabled: true,
                                                    readonly: false,
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Nisbah Akhir   ( % )',
                                                    type: 'number',
                                                    placeholder: 'Nisbah',
                                                    value: formData._bagiHasilDisposisi?.nisbah,
                                                    disabled: true,
                                                    readonly: false,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Pajak Bagi Hasil   ( % )',
                                                    type: 'number',
                                                    value: formData._bagiHasilDisposisi
                                                        ?.pajakBagiHasil,
                                                    placeholder: 'Pajak Bagi Hasil',
                                                    disabled: true,
                                                    readonly: false,
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Zakat Bagi Hasil   ( % )',
                                                    type: 'number',
                                                    value: formData._bagiHasilDisposisi
                                                        ?.zakatBagiHasil,
                                                    placeholder: 'Zakat Bagi Hasil',
                                                    disabled: true,
                                                    readonly: false,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },

                                {
                                    // ARO, ARO & Bagi Hasil, Non ARO
                                    // Pindah Buku, Transfer SKN - GL, Transfer RTGS - GL
                                    rows: 2,
                                    label: 'Disposisi Pokok & Bagi Hasil',
                                    type: 'select',
                                    options: [
                                        {
                                            key: 'ARO',
                                            display: 'ARO',
                                        },
                                        {
                                            key: 'ARO & Bagi Hasil',
                                            display: 'ARO & Bagi Hasil',
                                        },
                                        {
                                            key: 'Non ARO',
                                            display: 'Non ARO',
                                        },
                                    ],
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._bagiHasilDisposisi?.disposisiPokok,
                                    onChange: (value) =>
                                        changeForm('_bagiHasilDisposisi', 'disposisiPokok', value),
                                },
                                {
                                    rows: 2,
                                    label: 'Instruksi Disposisi',
                                    type: 'select',
                                    placeholder: 'Select Instruksi Disposisi',
                                    options: [
                                        {
                                            key: 'PINDAH BUKU',
                                            display: 'PINDAH BUKU',
                                        },
                                        {
                                            key: 'TRANSFER SKN - GL',
                                            display: 'TRANSFER SKN - GL',
                                        },
                                        {
                                            key: 'TRANSFER RTGS - GL',
                                            display: 'TRANSFER RTGS - GL',
                                        },
                                    ],
                                    disabled: false || editMode,
                                    readonly: false,
                                    value: formData._bagiHasilDisposisi?.instruksiDisposisi,
                                    onChange: (value) =>
                                        appAction.current.setForm('_bagiHasilDisposisi', {
                                            ...formData._bagiHasilDisposisi,
                                            instruksiDisposisi: value,
                                            nomorRekening: '',
                                            nomorNama: '',
                                        }),
                                },
                                {
                                    rows: 2,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label:
                                                        formData._bagiHasilDisposisi
                                                            ?.instruksiDisposisi === 'Pindah Buku'
                                                            ? 'Nomor & Nama Rekening'
                                                            : 'Kode & Nama GL',
                                                    type:
                                                        formData._bagiHasilDisposisi
                                                            ?.instruksiDisposisi === 'Pindah Buku'
                                                            ? 'number'
                                                            : 'text',
                                                    placeholder:
                                                        formData._bagiHasilDisposisi
                                                            ?.instruksiDisposisi === 'Pindah Buku'
                                                            ? 'No Rekening'
                                                            : 'Kode GL',
                                                    disabled: false || editMode,
                                                    readonly: false,
                                                    value: formData._bagiHasilDisposisi
                                                        ?.nomorRekening,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_bagiHasilDisposisi',
                                                            'nomorRekening',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: <>&nbsp;</>,
                                                    type: 'text',
                                                    placeholder: 'Masukan nama',
                                                    disabled: false || editMode,
                                                    readonly: false,
                                                    value: formData._bagiHasilDisposisi?.nomorNama,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_bagiHasilDisposisi',
                                                            'nomorNama',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                            ],
                        },
                    ]}
                />
            </>
        );
    })
);

export default Step1;
