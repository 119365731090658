import moment from 'moment';
import React from 'react';
import { createForm } from '../../../../../../src/module/createFormList';
import { FormRegistration } from '../../../../controls';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import useSetorTunai from './useSetorTunai';
import useDropdowns from '../../../../../hooks/useDropdowns';

const SetorTunai = () => {

    const { stAction, stState, pdrState, pdrAction } = useSetorTunai();
    const { formData = {}, formEditable = false } = stState;
    const { eSumberBiaya, eJenisIdentitas } = useDropdowns(["eSumberBiaya", "eJenisIdentitas"])
    return (
        <>
            <FormRegistration
                itemsForm={[
                    // Informasi Lain
                    {
                        name: "Informasi Rekening",
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-5">
                                            {
                                                createForm({
                                                    label: 'No Rekening',
                                                    type: 'text',
                                                    placeholder: 'masukan nomor rekening',
                                                    value: stState.formData?.nomor_rekening,
                                                    onChange: (value) => stAction.changeForm(value, "nomor_rekening"),
                                                    required: false,
                                                    disabled: !stState.formEditable,
                                                    onEnter: (v) => stAction.cekNasabah(v),
                                                    onFocusOut: (v) => stAction.cekNasabah(v)
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nama Nasabah',
                                                    type: 'text',
                                                    placeholder: 'Nama Nasabah',
                                                    value: stState.formData?.info_transaksi?.info_rekening?.nama_rekening,
                                                    // onChange: (value) => stAction.changeForm(value, "nama", "info_transaksi", "data_pengirim"),
                                                    disabled: true,
                                                    required: false,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-3">
                                            {
                                                createForm({
                                                    label: 'Valuta Rekening',
                                                    type: 'number',
                                                    placeholder: 'valuta',
                                                    value: stState.formData?.info_transaksi?.info_rekening?.kode_valuta,
                                                    // onChange: (value) => stAction.changeForm(value, "kode_valuta"),
                                                    required: false,
                                                    disabled: true
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Valuta',
                                                    type: 'text',
                                                    placeholder: 'valuta',
                                                    value: stState.formData?.info_transaksi?.info_rekening?.keterangan_valuta,
                                                    // onChange: (value) => stAction.changeForm(value, "nominal"),
                                                    disabled: true,
                                                    required: false,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "text",
                                label: "Produk",
                                // required: true,
                                value: stState.formData?.info_transaksi?.info_rekening?.produk,
                                placeholder: "Produk",
                                disabled: !stState.formEditable || true
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        {/* <div className="col-3">
                                            {
                                                createForm({
                                                    label: 'Cabang Rekening',
                                                    type: 'text',
                                                    placeholder: 'Cabang Rekening',
                                                    value: stState.formData?._dataNasabah?.homePhone,
                                                    onChange: (value) => stAction.changeForm(value, "homePhone", "_dataNasabah"),
                                                    required: false,
                                                    disabled: true
                                                })
                                            }
                                        </div> */}
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nama Cabang',
                                                    type: 'text',
                                                    placeholder: 'Nama Cabang',
                                                    value: stState.formData?.info_transaksi?.info_rekening?.nama_cabang,
                                                    // onChange: (value) => stAction.changeForm(value, "mobilePhone", "_dataNasabah"),
                                                    disabled: true,
                                                    required: false,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                        ]
                    },
                    //Data Pengirim
                    {
                        name: <> <i className="fa fa-angle-double-down"></i> Data pengirim </>,
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className='row mb-3 mt-3'>
                                        <div className='col-4'>
                                            <div className="form-check">
                                                <input className="form-check-input" name='is_wwic' type="radio"
                                                    value={false} checked={!stState.isWic} onChange={(e) => {
                                                        stAction.setIsWic(JSON.parse(e.target.value))
                                                        stAction.changeForm({
                                                            "nomor_nasabah": "",
                                                            "nama": "",
                                                            "alamat": "",
                                                            "jenis_identitas": "",
                                                            "nomor_identitas": "",
                                                            "nomor_tlpn": "",
                                                            "nasabah_wic": ""
                                                        }, "data_pengirim", "info_transaksi")
                                                    }}
                                                />
                                                <label className="form-check-label" for="exampleRadios1">
                                                    Nasabah / WIC
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className="form-check">
                                                <input className="form-check-input" name='is_wwic' type="radio"
                                                    value={true} checked={stState.isWic}
                                                    onChange={(e) => {
                                                        stAction.setIsWic(JSON.parse(e.target.value))
                                                        stAction.changeForm({
                                                            "nomor_nasabah": "",
                                                            "nama": "",
                                                            "alamat": "",
                                                            "jenis_identitas": "",
                                                            "nomor_identitas": "",
                                                            "nomor_tlpn": "",
                                                            "nasabah_wic": ""
                                                        }, "data_pengirim", "info_transaksi")
                                                    }}
                                                />
                                                <label className="form-check-label" for="exampleRadios1">
                                                    WIC Baru
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-2">
                                                <label>Nama</label>
                                                {/* <span className="badge badge-secondary">
                                                    Wajib
                                                </span> */}
                                                <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        type="text"
                                                        // required
                                                        className="form-control form-control-sm col-9"
                                                        placeholder="Masukan Nama"
                                                        readOnly={!stState.isWic || !stState.formEditable}
                                                        onChange={(e) => {
                                                            stAction.changeForm(e.target.value, "nama", "info_transaksi", "data_pengirim")
                                                        }}
                                                        value={
                                                            stState.formData?.info_transaksi?.data_pengirim?.nama}
                                                    // stState.formData?.info_transaksi?.info_rekening?.nama_rekening}
                                                    // disabled={!stState.formEditable}
                                                    />
                                                    {!stState.isWic && <div className="input-group-append col-3 pl-2 pr-0">
                                                        <button onClick={pdrAction.openModal} className="btn btn-outline-danger">
                                                            Cari Nasabah . . .
                                                        </button>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            // {
                            //     rows: 1,
                            //     label: 'Nama',
                            //     type: 'text',
                            //     placeholder: 'Masukan Nama',
                            //     required: false,
                            //     disabled: !stState.formEditable,
                            //     value: stState.formData?.info_transaksi?.data_pengirim?.nama,
                            //     onChange: (value) => stAction.changeForm(value, "nama", "info_transaksi", "data_pengirim"),
                            // },
                            {
                                rows: 1,
                                label: 'Alamat',
                                type: 'text',
                                placeholder: 'Masukan Alamat',
                                required: false,
                                disabled: !stState.isWic || !stState.formEditable,
                                value: stState.formData?.info_transaksi?.data_pengirim?.alamat,
                                // value: stState.formData?.info_transaksi?.info_rekening?.alamat,
                                onChange: (value) => stAction.changeForm(value, "alamat", "info_transaksi", "data_pengirim"),
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col-5">
                                            {
                                                createForm({
                                                    label: 'Jenis Identitas',
                                                    type: 'select',
                                                    placeholder: 'Masukan Jenis Identitas',
                                                    value: stState.formData?.info_transaksi?.data_pengirim?.jenis_identitas,
                                                    onChange: (value) => stAction.changeForm(value, "jenis_identitas", "info_transaksi", "data_pengirim"),
                                                    required: false,
                                                    disabled: !stState.isWic || !stState.formEditable,
                                                    options: eJenisIdentitas,
                                                    key: "reference_code",
                                                    display: "reference_desc",
                                                })
                                            }
                                        </div>
                                        <div className="col-7">
                                            {
                                                createForm({
                                                    label: 'Nomor Identitas',
                                                    type: 'text',
                                                    placeholder: 'Masukan Nomor Identitas',
                                                    value: stState.formData?.info_transaksi?.data_pengirim?.nomor_identitas,
                                                    onChange: (value) => stAction.changeForm(value, "nomor_identitas", "info_transaksi", "data_pengirim"),
                                                    onFocusOut: (val) => stAction.cekIdentitas(val),
                                                    disabled: !stState.isWic || !stState.formEditable,
                                                    required: false,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                createForm({
                                                    label: 'Nomor Telepon',
                                                    type: 'number',
                                                    placeholder: 'Masukan Nomor Telepon',
                                                    value: stState.formData?.info_transaksi?.data_pengirim?.nomor_tlpn,

                                                    onChange: (value) => stAction.changeForm(value, "nomor_tlpn", "info_transaksi", "data_pengirim"),
                                                    required: false,
                                                    disabled: !stState.isWic || !stState.formEditable
                                                })
                                            }
                                        </div>
                                        {/* <div className="col-7">
                                            {
                                                createForm({
                                                    label: 'Nasabah/WIC',
                                                    type: 'text',
                                                    placeholder: 'Masukan Nasabah/WIC',
                                                    value: stState.formData?.info_transaksi?.data_pengirim?.nasabah_wic,
                                                    onChange: (value) => stAction.changeForm(value, "nasabah_wic", "info_transaksi", "data_pengirim"),
                                                    disabled: true,
                                                    required: false,
                                                })
                                            }
                                        </div> */}
                                    </div>
                                )
                            },

                        ]
                    },
                    // End Data Pengirim
                    //
                    {
                        name: "",
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-2">
                                                <label>Valuta</label>
                                                {/* <span className="badge badge-secondary">
                                                    Wajib
                                                </span> */}
                                                <div className="input-group input-group-sm mb-3">
                                                    <select className="form-control form-control-sm col-3"
                                                        placeholder="IDR"
                                                        onChange={(e) => {
                                                            stAction.changeForm(e.target.value, "kode_valuta");
                                                        }}
                                                        value={stState.formData?.kode_valuta}
                                                        disabled={!stState.formEditable}
                                                    >

                                                        <option value="">- Pilih Valuta -</option>
                                                        <option value="IDR">IDR</option>
                                                    </select>
                                                    <div className="input-group-append col pl-0 pr-0">
                                                        <input
                                                            type="text"
                                                            // required
                                                            className="form-control form-control-sm col"
                                                            placeholder="INDONESA RUPIAH"
                                                            value="INDONESIA RUPIAH"
                                                            readOnly
                                                            disabled={!stState.formEditable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col-5">
                                            {
                                                createForm({
                                                    label: 'Nominal',
                                                    type: 'currency',
                                                    placeholder: 'Masukan Nominal',
                                                    value: stState.formData?.nominal,
                                                    onChange: (value) => stAction.changeForm(value, "nominal"),
                                                    // required: false,
                                                    disabled: !stState.formEditable || stState.formData?.info_transaksi?.info_rekening?.jenis_rekening_tabungan === "DEP"
                                                })
                                            }
                                        </div>
                                        <div className="col-7">
                                            {
                                                createForm({
                                                    rows: 1,
                                                    label: 'Sumber Biaya',
                                                    type: 'select',
                                                    placeholder: 'Pilih Sumber Biaya',
                                                    required: false,
                                                    disabled: true,
                                                    options: eSumberBiaya,
                                                    key: "reference_code",
                                                    display: "reference_desc",
                                                    value: stState.formData?.info_transaksi?.sumber_biaya,
                                                    onChange: (value) => stAction.changeForm(value, "sumber_biaya", "info_transaksi"),
                                                })
                                            }
                                            {/* {
                                                createForm({
                                                    label: 'Nominal Ekivalen',
                                                    type: 'text',
                                                    placeholder: 'Masukan Nominal Ekivalen',
                                                    value: stState.formData?.info_transaksi?.nominal_ekuivalen,
                                                    onChange: (value) => stAction.changeForm(value, "nominal_ekuivalen", "info_transaksi"),
                                                    disabled: true,
                                                    required: false,
                                                })
                                            } */}
                                        </div>
                                    </div>
                                )
                            },
                            // {
                            //     rows: 1,
                            //     label: 'Sumber Biaya',
                            //     type: 'select',
                            //     placeholder: 'Pilih Sumber Biaya',
                            //     required: false,
                            //    // disabled: !stState.formEditable,
                            //     disabled: true,
                            //     options: eSumberBiaya,
                            //     key: "reference_code",
                            //     display: "reference_desc",
                            //     value: stState.formData?.info_transaksi?.sumber_biaya,
                            //     onChange: (value) => stAction.changeForm(value, "sumber_biaya", "info_transaksi"),
                            // },
                            // {
                            //     rows: 2,
                            //     type: 'custom',
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-4">
                            //                 {
                            //                     createForm({
                            //                         label: 'Kurs Jual',
                            //                         type: 'number',
                            //                         placeholder: 'Masukan Kurs Jual',
                            //                         value: stState.formData?.info_transaksi?.kurs_jual,
                            //                         onChange: (value) => stAction.changeForm(value, "kurs_jual", "info_transaksi"),
                            //                         required: false,
                            //                         disabled: true
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col-4">
                            //                 {
                            //                     createForm({
                            //                         label: 'Kurs Beli',
                            //                         type: 'number',
                            //                         placeholder: 'Masukan Kurs Beli',
                            //                         value: stState.formData?.info_transaksi?.kurs_beli,
                            //                         onChange: (value) => stAction.changeForm(value, "kurs_beli", "info_transaksi"),
                            //                         disabled: true,
                            //                         required: false,
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col-4">
                            //                 {
                            //                     createForm({
                            //                         label: 'Kurs Buku',
                            //                         type: 'number',
                            //                         placeholder: 'Masukan Kurs Buku',
                            //                         value: stState.formData?.info_transaksi?.kurs_buku,
                            //                         onChange: (value) => stAction.changeForm(value, "kurs_buku", "info_transaksi"),
                            //                         disabled: true,
                            //                         required: false,
                            //                     })
                            //                 }
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            // {
                            //     rows: 2,
                            //     type: 'custom',
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-5">
                            //                 {
                            //                     createForm({
                            //                         label: 'Biaya',
                            //                         type: 'currency',
                            //                         placeholder: 'Masukan Biaya',
                            //                         value: stState.formData?.info_transaksi?.biaya,
                            //                         onChange: (value) => {
                            //                             stAction.changeForm(value, "biaya", "info_transaksi")
                            //                             stAction.changeForm(value, "biaya_ekuivalen", "info_transaksi")
                            //                         },
                            //                         required: false,
                            //                         disabled: true
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col-7">
                            //                 {
                            //                     createForm({
                            //                         label: 'Biaya Ekuivalen',
                            //                         type: 'currency',
                            //                         placeholder: 'Masukan Biaya Ekuivalen',
                            //                         value: parseFloat(
                            //                             stState.formData?.info_transaksi?.biaya_ekuivalen
                            //                         ).toFixed(2), 
                            //                         onChange: (value) => stAction.changeForm(value, "biaya_ekuivalen", "info_transaksi"),
                            //                         disabled: true,
                            //                         required: false,
                            //                     })
                            //                 }
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                createForm({
                                                    label: 'No Referensi',
                                                    type: 'number',
                                                    placeholder: 'Masukan No Referensi',
                                                    value: stState.formData?.nomor_referensi,
                                                    onChange: (value) => stAction.changeForm(value, "nomor_referensi"),
                                                    required: false,
                                                    disabled: !stState.formEditable || true
                                                })
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                createForm({
                                                    label: 'Keterangan',
                                                    type: 'textarea',
                                                    maxLength: 100,
                                                    placeholder: 'Masukan Keterangan',
                                                    value: stState.formData?.keterangan,
                                                    onChange: (value) => stAction.changeForm(value, "keterangan"),
                                                    disabled: !stState.formEditable,
                                                    required: false,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },

                        ]
                    },
                    // 
                ]}
            />
            {/* <div className="bns-form">
                <Accordion defaultActiveKey="0">
                    <div className="row mt-4 mb-2">
                        <Accordion.Toggle as={Link} variant="link" eventKey="0">
                            <div className="col-auto text-primary">
                                <i className="fa fa-angle-double-down"></i> Data
                                Pengirim
                            </div>
                        </Accordion.Toggle>
                        <div className="col">
                            <hr />
                        </div>
                    </div>
                    <Accordion.Collapse eventKey="0">
                        SEKSESS
                    </Accordion.Collapse>
                </Accordion>
            </div> */}
        </>
    );
}

export default SetorTunai;