import clsx from 'clsx';
import { useState, useEffect, useMemo } from 'react';
import { FormControl, Modal, FormLabel } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button, Label } from 'semantic-ui-react';
import { TableGrid } from '../../../../controls';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../../../../app.config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HoldDanaPage = () => {
    let history = useHistory();
    const [rejectModal, setRejectModal] = useState(false);
    const [holdDanaModal, setHoldDanaModal] = useState(false);
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [status, setStatus] = useState('');
    const [dbId, setDbId] = useState('');
    const [noRek, setNoRek] = useState('');
    const [approvedModal, setApprovedModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [detail, setDetail] = useState([]);
    const [jenisProduk, setJenisProduk] = useState('');
    const [dataJenisProduk, setDataJenisProduk] = useState([]);
    const [jenisNasabah, setJenisNasabah] = useState('');
    const [search, setSearch] = useState();
    const [jenisRekening, setJenisRekening] = useState('');
    const [cabangRekening, setCabangRekening] = useState('-');
    const [saldoRekening, setSaldoRekening] = useState(0);
    const [state, setState] = useState('PENDING_APPROVAL');

    useEffect(() => {
        fetch(baseUrl + `/amount/local/blocks?page=${currentPage}&limit=${limit}&state=${state}`)
            .then((response) => response.json())
            .then((data) => setData(data?.list));
    }, [currentPage, limit, status, state]);

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    //Action for reject
    const rejectAction = () => {
        setRejectModal(false);
        setHoldDanaModal(false);

        if (state === 'PENDING_APPROVAL_UNHOLD') {
            return fetch(
                baseUrl + `/amount/local/unblocks/authorization?db_id=${dbId}&status=REJECTED`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id == '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else {
            return fetch(
                baseUrl + `/amount/local/blocks/authorization?db_id=${dbId}&status=REJECTED`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id == '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        }
    };

    //Action for approve transaction from detail modal
    const approvedAction = () => {
        setApprovedModal(false);
        setHoldDanaModal(false);

        if (state === 'PENDING_APPROVAL_UNHOLD') {
            return fetch(
                baseUrl + `/amount/local/unblocks/authorization?db_id=${dbId}&status=APPROVED`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id == '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else {
            return fetch(
                baseUrl + `/amount/local/blocks/authorization?db_id=${dbId}&status=APPROVED`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id == '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error('Something Went Wrong', {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        }
    };

    const handleSearch = () => {
        if (isNaN(search)) {
            const body = {
                filterCriteria: {
                    field: 'nama_nasabah',
                    value: search,
                    operator: 'LIKES',
                },
            };

            return fetch(
                baseUrl +
                    `/amount/local/blocks/search?state=${state}&limit=${limit}&page=${currentPage}`,
                {
                    method: 'POST',
                    body: JSON.stringify(body),
                }
            )
                .then((response) => response.json())
                .then((data) => setData(data?.list));
        } else {
            const body = {
                filterCriteria: {
                    field: 'no_rekening',
                    value: search,
                    operator: 'LIKES',
                },
            };

            return fetch(
                baseUrl +
                    `/amount/local/blocks/search?state=${state}&limit=${limit}&page=${currentPage}`,
                {
                    method: 'POST',
                    body: JSON.stringify(body),
                }
            )
                .then((response) => response.json())
                .then((data) => setData(data?.list));
        }
    };
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/rekening/tabungan/detail/' + noRek, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    setCabangRekening(dataDetail.cabang);
                    setSaldoRekening(dataDetail.saldo);
                }
            });
    }, [noRek]);
    return (
        <>
            <Modal centered show={Boolean(holdDanaModal)} size="lg">
                <Modal.Header onHide={() => setHoldDanaModal(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Otorisasi Hold Dana</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tw-border tw-border-black-40 tw-py-5 tw-px-6 tw-border-solid tw-rounded-lg tw-mb-6">
                        <div className="tw-flex tw-items-center tw-mb-5">
                            <div className="tw-text-black tw-font-bold tw-mr-2 tw-text-16">
                                {detail && detail.nama_nasabah}
                            </div>
                            {detail && detail.state === 'REJECTED' ? (
                                <div className="tw-bg-red tw-text-12 tw-bg-opacity-20 tw-text-red tw-rounded-full tw-px-3">
                                    Ditolak
                                </div>
                            ) : (
                                <div className="tw-bg-green tw-text-12 tw-bg-opacity-20 tw-text-green tw-rounded-full tw-px-3">
                                    Aktif
                                </div>
                            )}
                        </div>
                        <div className="tw-grid tw-grid-cols-8 tw-items-center tw-gap-x-2 tw-gap-y-1 tw-text-black tw-text-12">
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Produk</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">{detail && detail.jenis_produk}</div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Cabang</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">{cabangRekening}</div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Saldo Rek</span>
                                <span>:</span>
                            </span>
                            <div>
                                {new Intl.NumberFormat('id-ID', {
                                    currency: 'IDR',
                                    style: 'currency',
                                }).format(saldoRekening)}
                            </div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>No Rek</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">{detail && detail.no_rekening}</div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Kode Valuta</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">IDR - Rupiah</div>
                        </div>
                    </div>

                    <div className="tw-grid tw-grid-cols-2 tw-gap-x-4">
                        <div>
                            <FormLabel className="tw-font-normal tw-text-12">
                                Nominal Hold
                            </FormLabel>
                            <FormControl
                                size="sm"
                                disabled
                                value={detail && detail.nominal_hold}
                            ></FormControl>
                        </div>
                        <div>
                            <FormLabel className="tw-font-normal tw-text-12">Alasan Hold</FormLabel>
                            <FormControl value={detail && detail.keterangan} size="sm" disabled />
                        </div>
                        <div>
                            <FormLabel className="tw-font-normal tw-text-12 tw-mt-4">
                                Tanggal Hold
                            </FormLabel>
                            <FormControl
                                size="sm"
                                disabled
                                value={detail && detail.tgl_hold}
                            ></FormControl>
                        </div>
                        {/* <div>
                                <FormLabel className="tw-font-normal tw-text-12 tw-mt-4">
                                    Tanggal Kadaluarsa
                                    
                                </FormLabel>
                                <FormControl
                                    placeholder="Tanggal Kadaluarsa"
                                    size="sm"
                                    disabled
                                    value={detail && detail.tgl_kadaluarsa}
                                />
                            </div> */}
                        <div>
                            <FormLabel className="tw-font-normal tw-text-12 tw-mt-4">
                                Blokir Regulator
                            </FormLabel>
                            <FormControl
                                placeholder="Blokir Regulator"
                                size="sm"
                                disabled
                                value={detail && detail.blokir_regulator}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => {
                            setHoldDanaModal(false);
                        }}
                    >
                        Tidak Proses
                    </Button>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        basic
                        color="red"
                        onClick={() => {
                            setRejectModal(true);
                        }}
                    >
                        Tolak
                    </Button>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        basic
                        color="green"
                        onClick={() => {
                            setApprovedModal(true);
                        }}
                    >
                        Setujui
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(rejectModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setRejectModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tolak Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    {state === 'PENDING_APPROVAL_UNHOLD' ? (
                        <p>
                            Yakin menolak otorisasi unhold dana dengan nomor rekening <b>{noRek}</b>{' '}
                            ?
                        </p>
                    ) : (
                        <p>
                            Yakin menolak otorisasi hold dana dengan nomor rekening <b>{noRek}</b> ?
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                        onClick={() => setRejectModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={rejectAction}
                    >
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(approvedModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setApprovedModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Setujui Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    {state === 'PENDING_APPROVAL_UNHOLD' ? (
                        <p>
                            Yakin menyetujui otorisasi unhold dana dengan nomor rekening{' '}
                            <b>{noRek}</b> ?
                        </p>
                    ) : (
                        <p>
                            Yakin menyetujui otorisasi hold dana dengan nomor rekening{' '}
                            <b>{noRek}</b> ?
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setApprovedModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={approvedAction}
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Tabungan & Giro
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">Hold Dana</div>
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Status"
                                as="select"
                                onChange={(e) => setState(e.target.value)}
                            >
                                <option selected value="PENDING_APPROVAL">
                                    Menunggu Otorisasi
                                </option>
                                <option value="PENDING_APPROVAL_UNHOLD">
                                    Menunggu Otorisasi Unhold
                                </option>
                            </FormControl>
                            <form onSubmit={handleSearch} className="tw-w-full mr-4" id="search">
                                <FormControl
                                    id="search"
                                    className="mr-4"
                                    size="sm"
                                    placeholder="Cari no.rekening, nama..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </form>
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                                onClick={() => {
                                    setSearch('');
                                    setJenisNasabah('');
                                    setJenisProduk('');
                                    setJenisRekening('');
                                    // maybe better using ref, but well you can change it to ref if you want
                                    document.getElementById('search').reset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                                onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </div>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Nomor Rekening',
                            field: 'no_rekening',
                            width: '100px',
                        },
                        {
                            name: 'Nama Lengkap',
                            field: 'nama_nasabah',
                            width: '100px',
                        },
                        {
                            name: 'Jenis Produk',
                            field: 'jenis_produk',
                            width: '100px',
                        },
                        {
                            name: 'Nominal Hold',
                            field: 'nominal_hold',
                            width: '100px',
                        },
                        {
                            name: 'Tanggal Hold',
                            field: 'tgl_hold',
                            width: '100px',
                        },
                        {
                            name: 'Tanggal Kadaluarsa',
                            field: 'tgl_kadaluarsa',
                            width: '120px',
                        },
                        {
                            field: 'action',
                        },
                    ]}
                    data={data || []}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'value')
                            return <div className={clsx(className, 'tw-text-right')}>{value}</div>;
                        if (field === 'action')
                            return (
                                <Action
                                    onDetail={() => {
                                        setHoldDanaModal(true);
                                        setDetail(data);
                                        setDbId(data.db_id);
                                        setNoRek(data.no_rekening);
                                    }}
                                    onApproved={() => {
                                        setDbId(data.db_id);
                                        setApprovedModal(true);
                                        setNoRek(data.no_rekening);
                                    }}
                                    onReject={() => {
                                        setRejectModal(true);
                                        setDbId(data.db_id);
                                    }}
                                    key={`${value}-${field}`}
                                />
                            );
                        if (field === 'nominal_hold')
                            return (
                                <div
                                    key={`${value}-${field}`}
                                    className={clsx(className, 'tw-text-right')}
                                >
                                    {new Intl.NumberFormat('id-ID', {
                                        currency: data?.currencyCode || 'IDR',
                                        style: 'currency',
                                    }).format(value)}
                                </div>
                            );
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-end tw-text-black tw-mt-auto"
                ></div>
            </div>
        </>
    );
};

export default HoldDanaPage;

const Action = ({ onDetail, onApproved, onReject }) => {
    const [show, setShow] = useState(false);

    const handleDetail = () => {
        if (onDetail) onDetail();

        setShow(false);
    };

    const handleApproved = () => {
        if (onApproved) onApproved();
        setShow(true);
    };
    const handleReject = () => {
        if (onReject) onReject();
        setShow(true);
    };

    return (
        <div>
            <Button basic color="red" size="mini" onClick={handleReject}>
                Tolak
            </Button>
            <Button basic color="green" className="tw-mr-3" size="mini" onClick={handleApproved}>
                Setujui
            </Button>
            <button type="button" class="btn btn-link" onClick={handleDetail}>
                Lihat Detail
            </button>
        </div>
    );
};
