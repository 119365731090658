import React, { useEffect } from 'react';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormPerorangan from './useFormPerorangan';
import { useParams } from 'react-router';

const DataCCD = () => {
    const { fpAction, fpState } = useFormPerorangan();
    const { formData = {}, formEditable = false } = fpState;
    const paramUrl = useParams();

    useEffect(function () {
        paramUrl.mode === "registration" &&
        fpAction.changeForm("0", "dataStatusKenaPajak", "_dataTambahan")
    }, [paramUrl.mode])

    return (
        <>
            <FormRegistration
                itemsForm={[
                    // Data Tambahan
                    {
                        name: "Data Tambahan",
                        rows: [
                            {
                                rows: 1,
                                type: "text",
                                label: "Keluarga yang dapat dihubungi",
                                placeholder: "Masukkan keluarga yang dapat dihubungi",
                                value: fpState.formData?._dataTambahan?.dataNamaKeluarga,
                                onChange: (value) => fpAction.changeForm(value, "dataNamaKeluarga", "_dataTambahan"),
                                required: false,
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                type: "number",
                                label: "Telepon/Hp Keluarga yg dapat dihubungi",
                                placeholder: "Masukkan Telepon/Hp Keluarga yg dapat dihubungi",
                                value: fpState.formData?._dataTambahan?.dataHomePhone,
                                onChange: (value) => fpAction.changeForm(value, "dataHomePhone", "_dataTambahan"),
                                required: false,
                                disabled: !fpState.formEditable
                            },
                            // {
                            //     rows: 1,
                            //     type: "select",
                            //     label: "Penghasilan utama pertahun",
                            //     placeholder: "Pilih Penghasilan utama pertahun",
                            //     options: fpState.dataDropDown?.R_PENGHASILAN_PERTAHUN,
                            //     key: "reference_code",
                            //     display: "reference_desc",
                            //     value: fpState.formData?._dataTambahan?.dataPenghasilanTahun,
                            //     onChange: (value) => fpAction.changeForm(value, "dataPenghasilanTahun", "_dataTambahan"),
                            //     required: true,
                            //     disabled: !fpState.formEditable
                            // },

                            //HIDE NUNGGU BE CORE
                            {
                                rows: 1,
                                label: 'Penghasilan rata-rata Pertahun',
                                type: 'currency',
                                required: true,
                                placeholder: 'Masukan Penghasilan rata-rata pertahun',
                                value: fpState.formData?.nominal_penghasilan,
                                onChange: (value) => fpAction.changeForm(value, "nominal_penghasilan"),
                                disabled: !fpState.formEditable
                            },
                            //END HIDE
                            {
                                rows: 1,
                                label: 'Jumlah Tanggungan',
                                type: 'number',
                                // required: true,
                                placeholder: 'Masukan Jumlah Tanggungan',
                                value: fpState.formData?._dataTambahan?.jumlahTanggungan,
                                onChange: (value) => fpAction.changeForm(value, "jumlahTanggungan" ,"_dataTambahan"),
                                disabled: !fpState.formEditable
                            },
                            // {
                            //     rows: 1,
                            //     type: 'custom',
                            //     component: (
                            //         <>
                            //             <div className="row">
                            //                 <div className="col-12">
                            //                     {
                            //                         createForm({
                            //                             label: 'Jumlah Tanggungan',
                            //                             type: 'currency',
                            //                             placeholder: "Masukan Jumlah Tanggungan",
                            //                             value: fpState.formData?._dataTambahan?.jumlahTanggungan,
                            //                             onChange: (value) => {
                            //                                 fpAction.changeForm(value, "jumlahTanggungan", "_dataTambahan")
                            //                             },
                            //                             required: true,
                            //                             disabled: !fpState.formEditable,
                            //                         })
                            //                     }
                            //                 </div>
                            //             </div>
                            //         </>
                            //     )
                            // },
                            //HIDE NUNGGU BE CORE
                            {
                                rows: 2,
                                type: "select",
                                label: "Sumber dana",
                                placeholder: "Pilih Sumber dana",
                                // options: fpState.dataDropDown?.R_SUMBER_DANA_REKENING ,
                                options: (fpState.dataDropDown?.R_SUMBER_DANA_REKENING || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?.kode_sumber_dana,
                                onChange: (value) => fpAction.changeForm(value, "kode_sumber_dana"),
                                required: true,
                                disabled: !fpState.formEditable
                            },
                            //END HIDE
                            {
                                rows: 2,
                                type: "select",
                                label: "Tujuan penggunaan dana",
                                placeholder: "Pilih Tujuan penggunaan dana",
                                // options: fpState.dataDropDown?.R_TUJUAN_BUKA_REKENING,
                                options: (fpState.dataDropDown?.R_TUJUAN_BUKA_REKENING || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._dataTambahan?.dataTujuanPenggunaDana,
                                onChange: (value) => fpAction.changeForm(value, "dataTujuanPenggunaDana", "_dataTambahan"),
                                required: true,
                                disabled: !fpState.formEditable
                            },
                            // end rows 1
                            // rows 2
                            {
                                rows: 2,
                                type: "select",
                                label: "Golongan Nasabah",
                                placeholder: "Pilih Golongan Nasabah",
                                value: fpState.formData?._dataTambahan?.dataGolNasabah,
                                options: fpState.dataDropDown?.NONBANK.map(({reference_code,reference_desc})=> ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                onChange: (value) => fpAction.changeForm(value, "dataGolNasabah", '_dataTambahan'),
                                required: true,
                                disabled: !fpState.formEditable
                            },
                            // {
                            //     rows: 2,
                            //     type: "select",
                            //     label: "Golongan Debitur",
                            //     placeholder: "Pilih Golongan Debitur",
                            //     options: fpState.dataDropDown?.R_GOLDEBITUR,
                            //     key: "reference_code",
                            //     display: "reference_desc",
                            //     value: fpState.formData?._dataTambahan?.dataGolDebitur,
                            //     onChange: (value) => fpAction.changeForm(value, "dataGolDebitur","_dataTambahan"),
                            //     required: true,
                            //     disabled: !fpState.formEditable
                            // },
                            // {
                            //     rows: 2,
                            //     type: "select",
                            //     label: "Golongan Pemilik XBRL",
                            //     placeholder: "Pilih Golongan Pemilik XBRL",
                            //     options: fpState.dataDropDown?.R_GOLPEMILIK_XBRL ,
                            //     key: "reference_code",
                            //     display: "reference_desc",
                            //     value: fpState.formData?._dataTambahan?.dataGolPemilikXBRL,
                            //     onChange: (value) => fpAction.changeForm(value, "dataGolPemilikXBRL","_dataTambahan"),
                            //     required: false,
                            //     disabled: !fpState.formEditable
                            // },
                            {
                                rows: 2,
                                type: "checkbox",
                                label: "Tujuan pembukaan rekening hanya untuk penerimaan Gaji",
                                checked: fpState.formData?._dataTambahan?.dataTujuanGaji,
                                value: fpState.formData?._dataTambahan?.dataTujuanGaji,
                                onChange: (value) => fpAction.changeForm(value, "dataTujuanGaji", "_dataTambahan"),
                                // required: true,
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                type: "checkbox",
                                label: "Tujuan pembukaan rekening terkait program pemerintah",
                                checked: fpState.formData?._dataTambahan?.dataTujuanProgramPemerintah,
                                value: fpState.formData?._dataTambahan?.dataTujuanProgramPemerintah,
                                onChange: (value) => fpAction.changeForm(value, "dataTujuanProgramPemerintah", "_dataTambahan"),
                                // required: true,
                                disabled: !fpState.formEditable
                            }
                        ]

                    },
                    // End Data Tambahan
                    // Status Pajak
                    {
                        name: "Status Pajak & SKB Pajak",
                        rows: [
                            // rows 1
                            // {
                            //     rows: 1,
                            //     type: "select",
                            //     label: "Status Kena Pajak",
                            //     placeholder: "Pilih Status Kena Pajak",
                            //     options: fpState.dataDropDown?.R_STATUS_PAJAK,
                            //     key: "reference_code",
                            //     display: "reference_desc",
                            //     value: fpState.formData?._dataTambahan?.dataStatusKenaPajak,
                            //     onChange: (value) => fpAction.changeForm(value, "dataStatusKenaPajak", "_dataTambahan"),
                            //     required: true,
                            //     disabled: !fpState.formEditable
                            // },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        label: 'Status Kena Pajak',
                                                        type: 'select',
                                                        placeholder: "Pilih Status Kena Pajak",
                                                        // options: fpState.dataDropDown?.R_STATUS_PAJAK,
                                                        options: (fpState.dataDropDown?.R_STATUS_PAJAK || []).map(({reference_code, reference_desc}) => ({
                                                            reference_code,
                                                            reference_desc : `${reference_code} - ${reference_desc}`
                                                        })),
                                                        key: "reference_code",
                                                        display: "reference_desc",
                                                        value: fpState.formData?._dataTambahan?.dataStatusKenaPajak,
                                                        onChange: (value) => {
                                                            fpAction.changeForm(value, "dataStatusKenaPajak", "_dataTambahan")
                                                            fpAction.changePajak(value)
                                                            // if (value === "0") {
                                                            //     fpAction.changeForm("KENA PAJAK", "dataKetBebasPajak","_dataTambahan")
                                                            // }
                                                        },
                                                        required: true,
                                                        disabled: !fpState.formEditable,
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <>
                                        <div className="row" hidden={!fpState?.statusPajak?.Aa}>
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        label: 'Keterangan Bebas Pajak',
                                                        type: "text",
                                                        placeholder: "Masukan Keterangan Bebas Kena Pajak",
                                                        value: fpState.formData?._dataTambahan?.dataKetBebasPajak,
                                                        onChange: (value) => fpAction.changeForm(value, "dataKetBebasPajak", "_dataTambahan"),
                                                        required: fpState?.statusPajak?.Aa,
                                                        disabled: !fpState.formEditable,
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <>
                                        <div className="row" hidden={!fpState?.statusPajak?.Ba || fpState.formData?._dataTambahan?.dataStatusKenaPajak === "2" } >
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        label: 'Nomor Surat',
                                                        type: "text",
                                                        placeholder: "Masukkan Nomor Surat",
                                                        value: fpState.formData?._dataTambahan?.dataNomorSurat,
                                                        onChange: (value) => fpAction.changeForm(value, "dataNomorSurat", "_dataTambahan"),
                                                        required: fpState?.statusPajak?.Ba,
                                                        disabled: !fpState.formEditable,
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                            // {
                            //     rows: 2,
                            //     type: "text",
                            //     label: "Nomor Surat",
                            //     placeholder: "Masukkan Nomor Surat",
                            //     value: fpState.formData?._dataTambahan?.dataNomorSurat,
                            //     onChange: (value) => fpAction.changeForm(value, "dataNomorSurat","_dataTambahan"),
                            //     required: false,
                            //     disabled: !fpState.formEditable
                            // },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row" hidden={!fpState?.statusPajak?.Ca}>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    type: "date",
                                                    label: "Tgl Terbit",
                                                    placeholder: "Masukkan Tgl Terbit",
                                                    value: fpState.formData?._dataTambahan?.dataTglTerbit,
                                                    onChange: (value) => fpAction.changeForm(value, "dataTglTerbit", "_dataTambahan"),
                                                    required: fpState?.statusPajak?.Ca,
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    type: "date",
                                                    label: "Tgl Berakhir",
                                                    placeholder: "Masukkan Tgl Berakhir",
                                                    value: fpState.formData?._dataTambahan?.dataTglBerakhir,
                                                    onChange: (value) => fpAction.changeForm(value, "dataTglBerakhir", "_dataTambahan"),
                                                    required: fpState?.statusPajak?.Ca,
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                        ]
                    },
                    // End Status Pajak
                    // Limit Transaksi
                    {
                        name: "Limit Transaksi",
                        rows: [
                            // rows 1
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-8">
                                            {
                                                createForm({
                                                    type: "currency",
                                                    label: "Nominal Setor Tunai",
                                                    required: true,
                                                    placeholder: "Masukkan nominal setor tunai",
                                                    value: fpState.formData?._dataTambahan?.dataSetorTunai,
                                                    onChange: (value) => fpAction.changeForm(value, "dataSetorTunai", "_dataTambahan"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-4">
                                            {
                                                createForm({
                                                    type: "currency",
                                                    label: "Frekuensi",
                                                    required: true,
                                                    placeholder: "Masukkan Frekuensi",
                                                    value: fpState.formData?._dataTambahan?.dataSetorTunaiFrekuensi,
                                                    onChange: (value) => fpAction.changeForm(value, "dataSetorTunaiFrekuensi", "_dataTambahan"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                            {/* <div className="form-group-sm">
                                                <label>&nbsp;</label>
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend  pt-1 pr-1">
                                                        Frekuensi
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-sm"
                                                        placeholder="0"
                                                        onChange={(e) => {
                                                            fpAction.changeForm(e.target.value, "dataSetorTunaiFrekuensi", "_dataTambahan");
                                                        }}
                                                        value={fpState.formData?._dataTambahan?.dataSetorTunaiFrekuensi}
                                                        disabled={!fpState.formEditable}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-8">
                                            {
                                                createForm({
                                                    type: "currency",
                                                    label: "Nominal Setor Non Tunai",
                                                    required: true,
                                                    placeholder: "Masukkan nominal setor Non tunai",
                                                    value: fpState.formData?._dataTambahan?.dataSetorNonTunai,
                                                    onChange: (value) => fpAction.changeForm(value, "dataSetorNonTunai", "_dataTambahan"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-4">
                                            {
                                                createForm({
                                                    type: "currency",
                                                    label: "Frekuensi",
                                                    required: true,
                                                    placeholder: "Masukkan Frekuensi",
                                                    value: fpState.formData?._dataTambahan?.dataSetorNonTunaiFrekuensi,
                                                    onChange: (value) => fpAction.changeForm(value, "dataSetorNonTunaiFrekuensi", "_dataTambahan"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                            {/* <div className="form-group-sm">
                                                <label>&nbsp;</label>
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend  pt-1 pr-1">
                                                        Frekuensi
                                                    </div>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        placeholder="0"
                                                        onChange={(e) => {
                                                            fpAction.changeForm(e.target.value, "dataSetorNonTunaiFrekuensi", "_dataTambahan");
                                                        }}
                                                        value={fpState.formData?._dataTambahan?.dataSetorNonTunaiFrekuensi}
                                                        disabled={!fpState.formEditable}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            },
                            // end rows 1
                            // rows 2

                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-8">
                                            {
                                                createForm({
                                                    type: "currency",
                                                    label: "Nominal Tarik Tunai",
                                                    required: true,
                                                    placeholder: "Masukkan nominal Tarik tunai",
                                                    value: fpState.formData?._dataTambahan?.dataTarikTunai,
                                                    onChange: (value) => fpAction.changeForm(value, "dataTarikTunai", "_dataTambahan"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-4">
                                            {
                                                createForm({
                                                    type: "currency",
                                                    label: "Frekuensi",
                                                    required: true,
                                                    placeholder: "Masukkan Frekuensi",
                                                    value: fpState.formData?._dataTambahan?.dataTarikTunaiFrekuensi,
                                                    onChange: (value) => fpAction.changeForm(value, "dataTarikTunaiFrekuensi", "_dataTambahan"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                            {/* <div className="form-group-sm">
                                                <label>&nbsp;</label>
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend  pt-1 pr-1">
                                                        Frekuensi
                                                    </div>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        placeholder="0"
                                                        onChange={(e) => {
                                                            fpAction.changeForm(e.target.value, "dataTarikTunaiFrekuensi", "_dataTambahan");
                                                        }}
                                                        value={fpState.formData?._dataTambahan?.dataTarikTunaiFrekuensi}
                                                        disabled={!fpState.formEditable}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-8">
                                            {
                                                createForm({
                                                    type: "currency",
                                                    label: "Nominal Tarik Non Tunai",
                                                    required: true,
                                                    placeholder: "Masukkan nominal Tarik Non tunai",
                                                    value: fpState.formData?._dataTambahan?.dataTarikNonTunai,
                                                    onChange: (value) => fpAction.changeForm(value, "dataTarikNonTunai", "_dataTambahan"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-4">
                                            {
                                                createForm({
                                                    type: "currency",
                                                    label: "Frekuensi",
                                                    required: true,
                                                    placeholder: "Masukkan Frekuensi",
                                                    value: fpState.formData?._dataTambahan?.dataTarikNonTunaiFrekuensi,
                                                    onChange: (value) => fpAction.changeForm(value, "dataTarikNonTunaiFrekuensi", "_dataTambahan"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                            {/* <div className="form-group-sm">
                                                <label>&nbsp;</label>
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend  pt-1 pr-1">
                                                        Frekuensi
                                                    </div>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        placeholder="0"
                                                        onChange={(e) => {
                                                            fpAction.changeForm(e.target.value, "dataTarikNonTunaiFrekuensi", "_dataTambahan");
                                                        }}
                                                        value={fpState.formData?._dataTambahan?.dataTarikNonTunaiFrekuensi}
                                                        disabled={!fpState.formEditable}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            },
                            // end rows 2
                        ]
                    },
                    // End limit transaksi
                    // Faktor Resiko
                    {
                        name: "Faktor Resiko",
                        rows: [
                            // rows 1
                            {
                                rows: 1,
                                type: "select",
                                label: "Status Resiko",
                                disabled: true,
                                required: true,
                                value: fpState.formData?._dataTambahan?.dataResiko,
                                onChange: (value) => fpAction.changeForm(value, "dataResiko", "_dataTambahan"),
                                placeholder: "status resiko",
                                options: [
                                    {
                                        key: "L",
                                        display: "LOW"
                                    },{
                                        key: "M",
                                        display: "MEDIUM"
                                    },{
                                        key: "H",
                                        display: "HIGH"
                                    },
                                ]
                                // disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            <div className="alert alert-warning m-2 p-2">
                                                Status Resiko akan di kalkulasi berdasar input pada indikator profile resiko
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            // end rows 1
                            // rows 2
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            <table className="table table-bordered text-center">
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Tingkat Resiko</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Identitas Nasabah</td>
                                                        <td>
                                                            <select
                                                                value={fpState.formData?._dataTambahan?.dataIdentitasNasabah}
                                                                onChange={(e) => {
                                                                    fpAction.setFormResiko(prevState => ({ ...prevState, identitasMasalah: e.target.value }))
                                                                    fpAction.changeForm(e.target.value, "dataIdentitasNasabah", "_dataTambahan")
                                                                }}
                                                                disabled={!fpState.formEditable}
                                                                className="form-control" name="Identitas" id="Identitas">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lokasi Usaha</td>
                                                        <td>
                                                            <select
                                                                value={fpState.formData?._dataTambahan?.dataLokasiUsaha}
                                                                onChange={(e) => {
                                                                    fpAction.setFormResiko(prevState => ({ ...prevState, lokasiUsaha: e.target.value }))
                                                                    fpAction.changeForm(e.target.value, "dataLokasiUsaha", "_dataTambahan")
                                                                }

                                                                }
                                                                disabled={!fpState.formEditable}
                                                                className="form-control" name="Lokasi" id="Lokasi">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Profile Nasabah</td>
                                                        <td>
                                                            <select
                                                                value={fpState.formData?._dataTambahan?.dataProfilNasabah}
                                                                onChange={(e) => {
                                                                    fpAction.setFormResiko(prevState => ({ ...prevState, profileNasabah: e.target.value }))
                                                                    fpAction.changeForm(e.target.value, "dataProfilNasabah", "_dataTambahan")
                                                                }
                                                                }
                                                                disabled={!fpState.formEditable}
                                                                className="form-control" name="Profile" id="Profile">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jumlah Transaksi</td>
                                                        <td>
                                                            <select
                                                                value={fpState.formData?._dataTambahan?.dataJumlahTransaksi}
                                                                onChange={(e) => {
                                                                    fpAction.setFormResiko(prevState => ({ ...prevState, jumlahTrx: e.target.value }))
                                                                    fpAction.changeForm(e.target.value, "dataJumlahTransaksi", "_dataTambahan")
                                                                }
                                                                }
                                                                disabled={!fpState.formEditable}
                                                                className="form-control" name="Transaksi" id="Transaksi">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kegiatan Usaha</td>
                                                        <td>
                                                            <select
                                                                value={fpState.formData?._dataTambahan?.dataKegiatanUsaha}
                                                                onChange={(e) => {
                                                                    fpAction.setFormResiko(prevState => ({ ...prevState, kegiatanUsaha: e.target.value }))
                                                                    fpAction.changeForm(e.target.value, "dataKegiatanUsaha", "_dataTambahan")
                                                                }
                                                                }
                                                                disabled={!fpState.formEditable}
                                                                className="form-control" name="Usaha" id="Usaha">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Struktur Kepemilikan</td>
                                                        <td>
                                                            <select
                                                                value={fpState.formData?._dataTambahan?.dataStrukturKepemilikan}
                                                                onChange={(e) => {
                                                                    fpAction.setFormResiko(prevState => ({ ...prevState, strukturKepemilikan: e.target.value }))
                                                                    fpAction.changeForm(e.target.value, "dataStrukturKepemilikan", "_dataTambahan")
                                                                }
                                                                }
                                                                disabled={!fpState.formEditable}
                                                                className="form-control" name="Kepemilikan" id="Kepemilikan">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Informasi Lainnya</td>
                                                        <td>
                                                            <select
                                                                value={fpState.formData?._dataTambahan?.dataStrukturInformasiLainnya}
                                                                onChange={(e) => {
                                                                    fpAction.setFormResiko(prevState => ({ ...prevState, informasiLainnya: e.target.value }))
                                                                    fpAction.changeForm(e.target.value, "dataStrukturInformasiLainnya", "_dataTambahan")
                                                                }
                                                                }
                                                                disabled={!fpState.formEditable}
                                                                className="form-control" name="Informasi" id="Informasi">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )

                            }
                        ]
                    }
                    // End Faktor Resiko
                ]}

            />
        </>
    );
}

export default DataCCD;