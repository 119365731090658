import React from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormPerorangan from './useFormPerorangan';
import useRelasiAhliWaris from './useRelasiAhliWaris';

const RelasiAhliWaris = () => {
    const { fpAction, fpState } = useFormPerorangan();
    // const { formData = {}, formEditable = false } = fpState;
    const { pkState, pkAction } = useRelasiAhliWaris()
    // console.log(fpState.formData?._relasiNasabah)
    const Hubungan = 
        [
            {
                key: 1,
                display: "Ayah"
            },{
                key: 2,
                display: "Ibu"
            },{
                key: 3,
                display: "Anak"
            },{
                key: 4,
                display: "Adik"
            },{
                key: 5,
                display: "Kakak"
            },{
                key: 6,
                display: "Suami"
            },{
                key: 7,
                display: "Istri"
            },
        ];
    return ( 
        <>
           <div className="bns-form">
                <div className="row mt-4 mb-2">
                    <div className="col-10 text-primary">Relasi Nasabah</div>
                    <div className="col-2">
                    {
                        (fpState.paramUrl?.mode === "registration" || fpState.paramUrl?.mode === "edit")
                                 && (
                        <button
                            className="btn btn-outline-danger"
                            onClick={pkAction.openModalAddRelasiNasbah}
                        >
                            +Tambah Relasi Nasabah
                        </button>
                        )
                    }
                    </div>
                </div>
                <div className="row">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th>Tipe Nasabah</th>
                                <th>Nomor Nasabah</th>
                                {/* <th>Nama</th> */}
                                {/* <th>No NPWP</th> */}
                                <th>Jabatan</th>
                                {/* <th>Jenis Identias</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                                {
                                        fpState.formData?._relasiNasabah?.map((item, key) => {
                                        if (item.action !== 'D') {
                                            return (
                                                <tr key={key}>
                                                    <td>Individu</td>
                                                    <td>{item.RnNomorNasabah}</td>
                                                    {/* <td>{item.RnNamaIdentitas}</td>
                                                    <td>{item.RnNomorNPWP}</td> */}
                                                    {/* <td>{item.RnJabatanAtauPangkat}</td> */}
                                                    <td>
                                                    {
                                                    (fpState.dataDropDown?.R_JABATAN_KORPORAT?.find(
                                                        ({
                                                            reference_code
                                                        })=>reference_code === item.RnJabatanAtauPangkat
                                                    )?.reference_desc || '')
                                                    }
                                                    </td>
                                                    {/* <td>{item.RnJenisIdentitas}</td> */}
                                                    <td>
                                                    {
                                                            fpState.formEditable &&
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="14" cy="8" r="2" fill="#4F4F4F" />
                                                                        <circle cx="8" cy="8" r="2" fill="#4F4F4F" />
                                                                        <circle cx="2" cy="8" r="2" fill="#4F4F4F" />
                                                                    </svg>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => pkAction.openDetil(item)} ><p>Detail Relasi Nasabah</p></Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => pkAction.openDetil(item)} ><p>Ubah Relasi Nasabah</p></Dropdown.Item>
                                                                    <hr/>
                                                                    <Dropdown.Item onClick={() => pkAction.del(key)} ><p className="color-dnger">Hapus Relasi Nasabah</p></Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    }
                                    )
                                }
                        </tbody>
                    </table>
                </div>
                <hr/>
                <div className="row mt-4 mb-2">
                    <div className="col-10 text-primary">Ahli Waris</div>
                    <div className="col-2">
                    {
                        (fpState.paramUrl?.mode === "registration" || fpState.paramUrl?.mode === "edit") && (
                        <button
                            className="btn btn-outline-danger"
                            onClick={pkAction.openModalAdd}
                        >
                            +Tambah Ahli Waris
                        </button>
                        )
                    }
                    </div>
                </div>
                <div className="row">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th>No Urut Prioritas</th>
                                <th>Nama Lengkap</th>
                                <th>Jenis Kelamin</th>
                                <th>Hub. Keluarga</th>
                                <th>Keterangan</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            fpState.formData?._relasiAhliWaris?.map((item, key) => {
                                if (item.action !== 'D') {
                                    return (
                                        <tr key={key}>
                                            <td>{item.AwNoUrutPrioritas}</td>
                                            <td>{item.AwNamaLengakap}</td>
                                            <td>{item.AwJenisKelamin === "P" ? "Pria":"Wanita"}</td>
                                            {/* <td>{item.AwHubunganKeluarga}</td> */}
                                            <td>
                                            {
                                            (Hubungan?.find(
                                                ({
                                                    key
                                                })=>key === item.AwHubunganKeluarga
                                            )?.display || '')
                                            }
                                            </td>
                                            <td>{item.AwKeterangan}</td>
                                            <td>
                                                {
                                                    fpState.formEditable &&
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="14" cy="8" r="2" fill="#4F4F4F" />
                                                                <circle cx="8" cy="8" r="2" fill="#4F4F4F" />
                                                                <circle cx="2" cy="8" r="2" fill="#4F4F4F" />
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => pkAction.openDetilAW(key)} ><p>Detail Ahli Waris</p></Dropdown.Item>
                                                            <Dropdown.Item onClick={() => pkAction.openDetilAW(key, "EDIT")} ><p>Ubah Ahli Waris</p></Dropdown.Item>
                                                            <hr/>
                                                            <Dropdown.Item onClick={() => pkAction.delAW(key)} ><p className="color-dnger">Hapus Ahli Waris</p></Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            }
                            )
                        }
                </tbody>
            </table>
        </div>
    </div>
            {/*Modal Relasi Nasabah */}
            <Modal size="xl" show={pkState.formModalRelasiNasabah} onHide={pkAction.closeModalRelasiNasbah}>
                <Modal.Header>
                    <Modal.Title>
                        Tambah Relasi Nasabah
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col" >
                            {
                                createForm({
                                    type: "select",
                                    label: "Hubungan Dengan Nasabah",
                                    placeholder: "Pilih hubungan dengan nasabah",
                                    required: true,
                                    // options: fpState.dataDropDown?.R_RELASI_NASABAH,
                                    options: (fpState.dataDropDown?.R_RELASI_NASABAH || []).map(({reference_code, reference_desc}) => ({
                                        reference_code,
                                        reference_desc : `${reference_code} - ${reference_desc}`
                                    })),
                                    value: pkState.formRelasiNasabah?.RnHubunganDenganNasabah || '',
                                    onChange: (value) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnHubunganDenganNasabah: value})),
                                    key: "reference_code",
                                    display: "reference_desc",
                                    disabled: fpState.mode ==="DETAIL",
                                })
                            }
                        </div>
                        <div className="col" >
                            {
                                createForm({
                                    type: "select",
                                    label: "Jabatan / Pangkat",
                                    placeholder: "Pilih Jabatan / Pangkat",
                                    // options: fpState.dataDropDown?.R_JABATAN_KORPORAT,
                                    options: (fpState.dataDropDown?.R_JABATAN_KORPORAT || []).map(({reference_code, reference_desc}) => ({
                                        reference_code,
                                        reference_desc : `${reference_code} - ${reference_desc}`
                                    })),
                                    key: "reference_code",
                                    display: "reference_desc",
                                    onChange: (e) => pkAction.setRelasiNasabah(prevState => ({...prevState, RnJabatanAtauPangkat:e})),
                                    value:pkState.formRelasiNasabah.RnJabatanAtauPangkat || '',
                                    disabled: fpState.mode ==="DETAIL",
                                    required: true
                                })
                            }
                        </div>
                    </div>
                    <FormRegistration
                        itemsForm={[
                            // Data Diri
                            {
                                name: "Data Diri",
                                rows: []
                            }
                            // End Data Diri
                        ]}
                    />
                    <div className="row">
                        <div className="col">
                            <div className="form-group form-group-sm col-10">
                                <label>Nomor Nasabah</label>
                                <div className="input-group input-group-sm">
                                    <input
                                        className="form-control form-control-sm"
                                        required = {true}
                                        placeholder="Masukkan nomor nasabah"
                                        value={pkState.formRelasiNasabah.RnNomorNasabah}
                                        onChange={(e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnNomorNasabah: e.target.value }))}
                                    />
                                    <div className="input-group-append ml-2">
                                        <button onClick={pkAction.getCariNasabahButton} className="btn btn-outline-primary" style={{ borderRadius: 5 }}>
                                            Cari Nasabah
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {
                                createForm({
                                    type: "text",
                                    label: "Nama (sesuai Identitas)",
                                    placeholder: "Masukkan Nama (sesuai Identitas)",
                                    value: pkState.formRelasiNasabah.RnNamaIdentitas,
                                    onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnNamaIdentitas: e })),
                                    // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    disabled: true,
                                    required: false
                                })
                            }
                            {
                                createForm({
                                    type: "textarea",
                                    label: "Alamat",
                                    placeholder: "Masukkan Alamat",
                                    value: pkState.formRelasiNasabah.RnAlamat,
                                    onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnAlamat: e })),
                                    // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    disabled: true,
                                    required: false
                                })
                            }
                            <div className="row">
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "RT",
                                            type: "number",
                                            maxLength: 3,
                                            placeholder: "RT",
                                            value: pkState.formRelasiNasabah.RnRT,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnRT: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "RW",
                                            type: "number",
                                            maxLength: 3,
                                            placeholder: "RW",
                                            value: pkState.formRelasiNasabah.RnRW,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnRW: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "Kelurahan",
                                            type: "text",
                                            placeholder: "kelurahan",
                                            value: pkState.formRelasiNasabah.RnKelurahan,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnKelurahan: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "Kecamatan",
                                            type: "text",
                                            placeholder: "Kecamatan",
                                            value: pkState.formRelasiNasabah.RnKecamatan,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnKecamatan: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "Kota/Kab",
                                            type: "text",
                                            placeholder: "Kota/Kab",
                                            value: pkState.formRelasiNasabah.RnKotaKabupaten,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnKotaKabupaten: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "Provinsi",
                                            type: "text",
                                            placeholder: "Provinsi",
                                            value: pkState.formRelasiNasabah.RnProvinsi,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnProvinsi: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                                <div className="col-12">
                                    {
                                        createForm({
                                            label: "Kode Pos",
                                            type: "text",
                                            placeholder: "Kode Pos",
                                            value: pkState.formRelasiNasabah.RnKodePos,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnKodePos: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 pl-2 pr-1">
                                    {
                                        createForm({
                                            label: "Telepon Rumah",
                                            type: "number",
                                            placeholder: "000",
                                            value: pkState.formRelasiNasabah.RnCodeTeleponRumah,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnCodeTeleponRumah: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                                <div className="col-8">
                                    {
                                        createForm({
                                            label: <>&nbsp;</>,
                                            type: "number",
                                            placeholder: "000",
                                            value: pkState.formRelasiNasabah.RnTeleponRumah,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnTeleponRumah: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                            </div>
                            
                            {
                                createForm({
                                    type: "text",
                                    label: "Handphone",
                                    placeholder: "Masukkan Handphone",
                                    value: pkState.formRelasiNasabah.RnHandphone,
                                    onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnHandphone: e })),
                                    // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    disabled: true,
                                    required: false
                                })
                            }
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-3 pl-2 pr-1">
                                    {
                                        createForm({
                                            label: "Fax",
                                            type: "number",
                                            placeholder: "000",
                                            value: pkState.formRelasiNasabah.RnCodeFax,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnCodeFax: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                                <div className="col-9">
                                    {
                                        createForm({
                                            label: <>&nbsp;</>,
                                            type: "number",
                                            placeholder: "000",
                                            value: pkState.formRelasiNasabah.RnFax,
                                            onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnFax: e })),
                                            // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            disabled: true,
                                            required: false
                                        })
                                    }
                                </div>
                            </div>
                            {
                                createForm({
                                    type: "text",
                                    label: "Email",
                                    placeholder: "Masukkan Email",
                                    value: pkState.formRelasiNasabah.RnEmail,
                                    onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnEmail: e })),
                                    // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    disabled: true,
                                    required: false
                                })
                            }
                            <div className="row">
                                {
                                    createForm({
                                        label: "Jenis Identitas",
                                        type: "select",
                                        placeholder: "Pilih Jenis Identitas",
                                        options: fpState.dataDropDown?.eJenisIdentitas,
                                        value: pkState.formRelasiNasabah?.RnJenisIdentitas || '',
                                        onChange: (value) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnJenisIdentitas: value})),
                                        key: "reference_code",
                                        display: "reference_desc",
                                        // disabled: fpState.mode ==="DETAIL",
                                        disabled: true,
                                        required: false
                                    })
                                }
                            </div>
                            <div className="row">
                                {
                                    createForm({
                                        label: "Nomor Identitas",
                                        type: "number",
                                        placeholder: "Masukan Nomor Identitas",
                                        value: pkState.formRelasiNasabah.RnNomorIdentitas,
                                        onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnNomorIdentitas: e })),
                                        // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                        disabled: true,
                                        required: false
                                    })
                                }
                            </div>
                            <div className="row">
                                {
                                    createForm({
                                        label: "NPWP",
                                        type: "number",
                                        placeholder: "NPWP",
                                        value: pkState.formRelasiNasabah.RnNomorNPWP,
                                        onChange: (e) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnNomorNPWP: e })),
                                        // disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                        disabled: true,
                                        required: false
                                    })
                                }
                            </div>
                            {
                                createForm({
                                    type: "select",
                                    label: "Kewarganegaraan",
                                    placeholder: "Pilih Kewarganegaraan",
                                    options: fpState.dataDropDown?.eKewarganegaraan,
                                    value: pkState.formRelasiNasabah?.RnKewarganegaraan || '',
                                    onChange: (value) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnKewarganegaraan: value})),
                                    key: "reference_code",
                                    display: "reference_desc",
                                    // disabled: fpState.mode ==="DETAIL",
                                    disabled: true,
                                })
                            }
                            {
                                createForm({
                                    type: "select",
                                    label: "Negara Asal",
                                    placeholder: "Pilih Negara Asal",
                                    options: fpState.dataDropDown?.R_KODE_NEGARA,
                                    value: pkState.formRelasiNasabah?.RnNegaraAsal || '',
                                    onChange: (value) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnNegaraAsal: value})),
                                    key: "reference_code",
                                    display: "reference_desc",
                                    // disabled: fpState.mode ==="DETAIL",
                                    disabled: true,
                                })
                            }
                            {
                                createForm({
                                    type: "select",
                                    label: "Pekerjaan",
                                    placeholder: "Pilih Pekerjaan",
                                    options: fpState.dataDropDown?.personalOccupation,
                                    value: pkState.formRelasiNasabah?.RnPekerjaan || '',
                                    onChange: (value) => pkAction.setRelasiNasabah(prevState => ({ ...prevState, RnPekerjaan: value})),
                                    key: "reference_code",
                                    display: "reference_desc",
                                    // disabled: fpState.mode ==="DETAIL",
                                    disabled: true,
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                               { 
                                   //pkState.mode !== "DETAIL" &&
                                <button
                                    onClick={pkAction.save}
                                    className="btn btn-sm float-right btn-success ml-2">
                                    Simpan
                                </button>
                               }
                            <button onClick={pkAction.closeModalRelasiNasbah} className="btn btn-sm float-right btn-outline-success">
                                Batal
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* End Modal Relasi Nasabah */}

            {/* Modal Ahli Waris */}
            <Modal size="lg" show={pkState.formModal} onHide={pkAction.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        Tambah Ahli Waris 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {
                                createForm({
                                    type: "number",
                                    label: "No Urut Prioritas",
                                    placeholder: "Masukkan No Urut Prioritas",
                                    value: pkState.formPejabat.AwNoUrutPrioritas,
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, AwNoUrutPrioritas: e })),
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                })
                            }
                            {
                                createForm({
                                    type: "text",
                                    label: "Nama Lengkap",
                                    placeholder: "Masukan Nama Lengkap",
                                    value: pkState.formPejabat.AwNamaLengakap,
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, AwNamaLengakap: e })),
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                })
                            }
                            <div className="row">
                                <div className="col pl-3">
                                    <div className="form-group">
                                        <label
                                            style={{ fontSize: "12px" }}
                                            className={pkState.formPejabat.AwJenisKelamin === "" || pkState.formPejabat.AwJenisKelamin === undefined || pkState.formPejabat.AwJenisKelamin === null ? "text" : ""}
                                        >
                                            Jenis Kelamin
                                            {/* <span
                                                style={{
                                                    padding: '2px 5px',
                                                    background: '#E8E8E8',
                                                    fontSize: '8pt',
                                                    fontWeight: 'normal',
                                                    borderRadius: 3,
                                                }}
                                            >
                                                Wajib
                                            </span> */}
                                        </label>
                                        <br />
                                        <div className="form-check-inline">
                                            <label className="form-check-label">
                                                <input
                                                    type="radio"
                                                    checked={pkState.formPejabat.AwJenisKelamin === "P"}
                                                    className="form-check-input"
                                                    name="AwJenisKelamin"
                                                    onChange={(e) => {
                                                        pkAction.setFormPejabat(prevState => ({ ...prevState, AwJenisKelamin: e.target.value }));
                                                    }}
                                                    value="P"
                                                    disabled={pkState.isNasabah || pkState.mode === "DETAIL"}
                                                />Laki-laki
                                            </label>
                                        </div>
                                        <div className="form-check-inline ml-3">
                                            <label className="form-check-label">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="gender"
                                                    checked={pkState.formPejabat.AwJenisKelamin === "W"}
                                                    onChange={(e) => {
                                                        pkAction.setFormPejabat(prevState => ({ ...prevState, AwJenisKelamin: e.target.value }));
                                                    }}
                                                    value="W"
                                                    disabled={pkState.isNasabah || pkState.mode === "DETAIL"}
                                                />Perempuan
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                createForm({
                                    type: "select",
                                    label: "Hubungan Keluarga",
                                    placeholder: "Hubungan Keluarga",
                                    value: pkState.formPejabat.AwHubunganKeluarga,
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, AwHubunganKeluarga: e })),
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    // options: Hubungan
                                    options: ( Hubungan || []).map(({key, display}) => ({
                                        key,
                                        display : `${key} - ${display}`
                                    })),
                                    // [
                                    //     {
                                    //         key: 1,
                                    //         display: "Ayah"
                                    //     },{
                                    //         key: 2,
                                    //         display: "Ibu"
                                    //     },{
                                    //         key: 3,
                                    //         display: "Anak"
                                    //     },{
                                    //         key: 4,
                                    //         display: "Adik"
                                    //     },{
                                    //         key: 5,
                                    //         display: "Kakak"
                                    //     },{
                                    //         key: 6,
                                    //         display: "Suami"
                                    //     },{
                                    //         key: 7,
                                    //         display: "Istri"
                                    //     },
                                    // ]
                                    
                                })
                            }
                            {
                                createForm({
                                    type: "textarea",
                                    label: "Keterangan",
                                    placeholder: "Masukkan Keterangan/Catatan",
                                    value: pkState.formPejabat.AwKeterangan,
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, AwKeterangan: e })),
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            {
                                pkState.mode !== "DETAIL" &&<button
                                    onClick={pkAction.saveAW}
                                    className="btn btn-sm float-right btn-success ml-2">
                                    Simpan
                                </button>
                            }
                            <button onClick={pkAction.closeModal} className="btn btn-sm float-right btn-outline-success">
                                Batal
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* End Modal Ahli Waris */}

            {/* Cari Nasabah */}
            <Modal size="lg" show={pkState.formModalCari} onHide={pkAction.closeModalCari}>
                <Modal.Header>
                    <Modal.Title>
                        Pencarian Data Nasabah
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">Cari Berdasaarkan : </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            {
                                createForm({
                                    type: "select",
                                    required: false,
                                    placeholder: "Nomor/Nama Nasabah",
                                })
                            }
                        </div>
                        <div className="col-3">
                            {
                                createForm({
                                    type: "select",
                                    required: false,
                                    placeholder: "Jenis Nasabah",
                                })
                            }
                        </div>
                        <div className="col-3">
                            {
                                createForm({
                                    type: "text",
                                    required: false,
                                    placeholder: "Keyword",
                                })
                            }
                        </div>
                        <div className="col-3">
                            <button className="btn btn-sm btn-outline-primary mt-4" 
                                // style={{ borderRadius: 5 }}
                            >
                                Cari Nasabah
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th>Nomor Nasabah</th>
                                    <th>Nama Nasabah</th>
                                    <th>Alamat Nasabah</th>
                                    <th>Jenis Nasabah</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="14" cy="8" r="2" fill="#4F4F4F" />
                                                    <circle cx="8" cy="8" r="2" fill="#4F4F4F" />
                                                    <circle cx="2" cy="8" r="2" fill="#4F4F4F" />
                                                </svg>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1"><p>Pilih Nasabah</p></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-sm float-right btn-success ml-2">
                                Simpan
                            </button>
                            <button onClick={pkAction.closeModalCari} className="btn btn-sm float-right btn-outline-success">
                                Batal
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* End Cari Nasabah */}
        </>
     );
}
 
export default RelasiAhliWaris;