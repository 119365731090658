import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import useDropdowns from '../../../../../hooks/useDropdowns';
import moment from 'moment';
import { usePrintModal } from '../../../../controls/modalPrint';
import useNexusPrint from '../../../../../hooks/useNexusPrint';
import { addCommas } from '../../../../../module';
import useCetak from '../../../../controls/cetak/useCetak';
import useKeycloak from '../../../../../hooks/keycloak.module';

const ttContext = createContext(null)

export const TarikTunaiProvider = ({ children }) => {

    const { doGet, doPost, wait } = useFetch();
    const { cState, cAction } = useCetak()
    const dataDropDown = useDropdowns();
    // const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ keyword: '' })
    const [dataListRekening, setListDataRekening] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [loadingDetil, setLoadingDetil] = useState(true);
    const { keycloak } = useKeycloak()
    const [otorDesc, setOtorDesc] = useState("");
    const [nomorNasabah, setNomorNasabah] = useState(null)
    const [modalSVS, setModalSVS] = useState(false)
    const [dataPrint, setDataPrint] = useState({
        "transaksi": {
            "id_transaksi": "",
            "id_transaksi_core": "",
            "nomor_rekening_debet": "",
            "nomor_rekening_credit": "",
            "nominal": "0.00",
            "nomor_referensi": "",
            "keterangan": "",
            "keterangan_otor": null,
            "kode_valuta": "",
            "kode_transaksi": "",
            "status_otorisasi": "",
            "user_input": "",
            "user_otorisasi": "",
            "tanggal_input": "",
            "tanggal_otor": "",
            "status_reversal": null
        },
        "info_transaksi": {
            "id_info": null,
            "id_transaksi": "",
            "kode_bank_kliring": null,
            "nama_bank_kliring": null,
            "nama_rekening_penerima": null,
            "ats_permintaan": null,
            "keterangan": null,
            "norek_biaya": null,
            "saldo_teller_before": "",
            "saldo_teller_after": null,
            "nominal_ekuivalen": "",
            "biaya": "",
            "biaya_ekuivalen": "",
            "sumber_biaya": "",
            "kurs_jual": "",
            "kurs_beli": "",
            "kurs_buku": "",
            "pengirim_nomor_nasabah": null,
            "pengirim_nama": null,
            "pengirim_alamat": null,
            "pengirim_jenis_identitas": null,
            "pengirim_nomor_identitas": null,
            "pengirim_nomor_tlpn": null,
            "pengirim_nasabah_wic": null,
            "jenis_ttr": null,
            "tutup_kartu_atm": null,
            "norek_pindah_buku": null,
            "nama_rekening": "",
            "kode_valuta": "",
            "keterangan_valuta": "",
            "produk": "",
            "kode_cabang": "",
            "nama_cabang": "",
            "jenis_penduduk": null,
            "jenis_nomor_referensi": null,
            "jenis_nasabah": null,
            "total_denom": null,
            "selisih_saldo": null,
            "jenis_transaksi": null,
            "kode_valuta_teller": null,
            "saldo_rekening": null,
            "nama_rekening_pinbuk": null,
            "cabang_rekening_pinbuk": null
        }
    });

    const { print } = useNexusPrint()

    const [formCetak, setFormCetak] = useState({
        command: "PRINT",
        medianame: "Term Cash Withdrawal",
        formname: "BJBS TARIK TUNAI",
        print: 1,
        history: 0,
        id: null,
        nomorTransaksi: "",
        kodeCabang: "",
        userTeller: "",
        tgl: "",
        userOtorisasi: "",
        kodeCabangDebit: "",
        norekDebit: "",
        kodeTarikan: "",
        jenisMataUang: "",
        nominalDebit: "",
        kodeDebit: "",
        kodeCabangKredit: "",
        norekKreditKasTeller: "",
        nominalKredit: "",
        kodeKredit: "",
        berita: "",
        tanggalTranskasi: "",
        jamTransaksi: ""
    })
    const { mpAction, mpState } = usePrintModal();

    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        openLoading,
        closeConfirmCustom,
        openAlertCustom
    } = modalAction;

    const location = useLocation();

    const [dateNow, setdateNow] = useState({
        "date": moment(Date.now()).format("YYYY-MM-DD"),
    });

    const [jenisRekening, setJenisRekening] = useState(null)
    const [formData, setFormData] = useState({
        // "nomor_rekening": "",
        // "nominal": 0,
        // "nomor_referensi": "",
        // "keterangan": "",
        // "kode_valuta": "",
        // "info_transaksi": {
        //     "saldo_teller":0,
        //     "nominal_ekuivalen": 0,
        //     "biaya": 0,
        //     "biaya_ekuivalen": 0,
        //     "sumber_biaya": "",
        //     "kurs_jual": 1,
        //     "kurs_beli": 1,
        //     "kurs_buku": 1
        // }
        "nomor_rekening": " ",
        "nominal": "0.00",
        "keterangan": " ",
        "kode_valuta": "IDR",
        "info_transaksi": {
            "saldo_teller": 0,
            "nominal_ekuivalen": 0,
            "biaya": 0,
            "biaya_ekuivalen": 0,
            "sumber_biaya": "T",
            "kurs_jual": 1,
            "kurs_beli": 1,
            "kurs_buku": 1,
            "nomor_seri_warkat": "",
            "info_rekening": {
                "nama_rekening": "",
                "kode_valuta": "IDR",
                "keterangan_valuta": "",
                "produk": "",
                "kode_cabang": "",
                "nama_cabang": ""
            }
        }
    });
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams()
    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            setFormEditable(true);
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            getDataDetail();
        } else {
            setFormEditable(false);
            resetForm();

        }
        // } 
        setFormEditable((paramUrl.mode !== "otorisasi"));
        // // // console.log(paramUrl.mode !== "otorisasi")
    }, [paramUrl.mode])


    useEffect(function () {
        // // // console.log(modalSVS)
    }, [modalSVS])

    async function getSaldo() {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/kasteller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userInfo.preferred_username,
                    kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    setFormData(prevState => ({
                        ...prevState,
                        info_transaksi: {
                            ...prevState.info_transaksi,
                            saldo_teller: parseInt(data.saldo)
                        }
                    }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }

    async function cekBilyet(bilyet) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/cek-bg`,
                service: "acc",
                param: {
                    nomor_seri: bilyet,
                    nomor_rekening: formData.nomor_rekening
                }
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {

                if (status === "00") {
                    
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => changeForm("", "nomor_seri_warkat", "info_transaksi") });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => changeForm("", "nomor_seri_warkat", "info_transaksi") });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => changeForm("", "nomor_seri_warkat", "info_transaksi") });
        }
        closeLoading();
    }

    async function cetakFunction() {


        openLoading()
        try {
            const res = await print(formCetak);
            if (res.statusCode === 500) {
                setDataAlert({ show: true, body: res.remark, title: `${res.statusText}`, header: "Error", callBack: () =>   cAction.openCetakTransaksiPasbook(dataPrint?.transaksi?.nomor_rekening_credit) });
            } else {
                setDataAlert({
                    show: true, body: res.remark, title: `${res.statusText}`, header: "Berhasil", callBack: () => {
                        closeConfirm();
                        resetForm();
                        mpAction.closeModal("modalTrx")
                        
                        if (jenisRekening === "T" || jenisRekening === "R") {
                            // if(keycloak?.tokenParsed?.branch_code === "000") 
                                confirmCetakPassbook({ nomor_rekening : dataPrint?.transaksi?.nomor_rekening_debet});
                        }
                    }
                });
            }
        } catch (err) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });

        }
        closeLoading()
    }
    
    function confirmCetakPassbook(data) {
        
        let body = `Apakah akan melakukan cetak transaksi passbook?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => {
                    closeConfirm()
                    cAction.openCetakTransaksiPasbook(data)
                }, className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }


    function resetForm() {
        setJenisRekening(null)
        setFormData({
            "nomor_rekening": "",
            "nominal": "0.00",
            "nomor_referensi": "",
            "keterangan": "",
            "kode_valuta": "IDR",
            "info_transaksi": {
                "saldo_teller": 0,
                "nominal_ekuivalen": 0,
                "biaya": 0,
                "biaya_ekuivalen": 0,
                "sumber_biaya": "T",
                "kurs_jual": 1,
                "kurs_beli": 1,
                "kurs_buku": 1,
                "nomor_seri_warkat": "",
                "info_rekening": {
                    "nama_rekening": "",
                    "kode_valuta": "IDR",
                    "keterangan_valuta": "INDONESIA RUPIAH",
                    "produk": "",
                    "kode_cabang": "",
                    "nama_cabang": ""
                }
            },
        });
        setNomorNasabah(null)
        getSaldo();
    }
    // fungsi post nasabah
    async function doTrx() {

        closeConfirm();
        const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        openLoading();
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: {
                    ...formData,
                    uid:keycloak.uid
                },
                url: "/tariktunai", // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    keycloak.updateUID()
                    if (data.transaksi?.id_transaksi_core !== null) {
                        setDataPrint(data)
                        setFormCetak(c => ({
                            command: "PRINT",
                            medianame: "BJBS",
                            formname: "Validasi Tarikan",
                            print: 1,
                            history: 0,
                            id: data?.info_transaksi?.id_transaksi || "",
                            nomorArsipTarikan: data?.transaksi?.nomor_referensi || "",
                            kodeCabangTarikan: data?.transaksi?.kode_cabang_transaksi || "",
                            userTeller: data?.transaksi?.user_input || "",
                            tglBlnThnSetoran: moment((data?.transaksi?.tanggal_otor_server)).format("DD/MM/YYYY"),
                            userOtorisasi: data?.transaksi?.user_otorisasi || "",
                            kodeCabangKredit: data?.info_transaksi?.kode_cabang_kredit || "",
                            norekKredit: data?.transaksi?.nomor_rekening_credit || "",
                            kodeTarikan: data?.info_rekening?.map_kode_transaksi || "",
                            mataUang: data?.info_transaksi?.kode_valuta || "",
                            nominalKredit: addCommas(parseFloat(data?.transaksi?.nominal || "0").toFixed(2)),
                            kodeKredit: "K",
                            kodeCabangDebit: data?.info_transaksi?.kode_cabang_debet || "",
                            norekDebit: data?.transaksi?.nomor_rekening_debet || "",
                            nominalDebit: addCommas(parseFloat(data?.transaksi?.nominal || "0").toFixed(2)),
                            kodeDebit: "D",
                            namaPemilikNorek: data?.info_transaksi?.nama_rekening || "",
                            berita: data?.transaksi?.keterangan || "",
                            penyetor: data?.info_transaksi?.pengirim_nama || "",
                            tanggalDebit: moment((data?.transaksi?.tanggal_otor_server)).format("DD/MM/YYYY"),
                            waktuDebit: moment((data?.transaksi?.tanggal_otor_server)).format("HH:mm:ss"),
                            kodeTransaksi: "TT"
                        }))
                        mpAction.openModal("modalTrx", function () { 
                            keycloak.updateUID()
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            
                            if (jenisRekening === "T" || jenisRekening === "R") {
                                // if(keycloak?.tokenParsed?.branch_code === "000") 
                                    confirmCetakPassbook({nomor_rekening : data?.transaksi?.nomor_rekening_debet })
                            }
                        
                        })
                    } else setDataAlert({
                        show: true, body: remark, title: `Transaski Berhasil`, header: "Info", callBack: () => {
                            
                            keycloak.updateUID()
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                        }
                    });
                }
                else if(status === "05"){ 
                    resetForm();
                    closeLoading();
                    closeAlert();
                    closeConfirmCustom();
                    closeConfirm();
                    keycloak.updateUID()
                }
                // setDataAlert({
                //     show: true, body: remark, title: ``, header: "Success", callBack: () => {
                //         resetForm();
                //         closeLoading();
                //         closeAlert();
                //         closeConfirmCustom();
                //         closeConfirm();
                //     }
                // });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function confirmTrx() {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            fieldRequired = ["nomor_rekening", "nominal", "nama_rekening"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }


    function changeForm(value, key, subkey = null, grandSubKey = null) {
        value = value.toUpperCase()
        if (grandSubKey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [grandSubKey]: { ...prevState[subkey][grandSubKey], [key]: value } } }))
        else if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    // fungsi cek nasabah 
    async function cekNasabah(nomor_rekening) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/rekening/rek-for-trx/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {

                if (status === "00") 
                    if (data.is_blokir_debet==="T"){
                        setDataAlert({ show: true, body: "Rekening tidak bisa dilakukan penarikan", title: ``, header: "Info", callBack: () => changeForm("", "nomor_rekening") });
                    }else if(data.jenis_rekening_liabilitas==="R"){
                        setDataAlert({ show: true, body: "Rekening Tabungan Rencana tidak bisa dilakukan penarikan", title: ``, header: "Info", callBack: () => changeForm("", "nomor_rekening") });
                    }else if(data.jenis_rekening_liabilitas==="D"){
                        setDataAlert({ show: true, body: "Rekening Deposito tidak bisa dilakukan penarikan", title: ``, header: "Info", callBack: () => changeForm("", "nomor_rekening") });
                    }
                    else{
                    const { nama_rekening = "", jenis_rekening_liabilitas = null,
                        jenis_rekening_transaksi = "", nama_cabang = "", valuta = "", nama_produk = "", nomor_nasabah = null, kode_cabang = "" } = data;
                    let kode_valuta = "";
                    let keterangan_valuta = "";
                    const valutax = valuta.split("-")
                    // // // console.log(valutax)
                    if (valutax.length > 1) {
                        kode_valuta = valutax[0];
                        keterangan_valuta = valutax[1]
                    }
                    setNomorNasabah(nomor_nasabah || null)
                    setJenisRekening(jenis_rekening_liabilitas)
                    setFormData(prevState => ({
                        ...prevState,

                        jenis_rekening_transaksi,
                        info_transaksi: {
                            ...prevState.info_transaksi,
                            info_rekening: {
                                nama_rekening,
                                kode_valuta,
                                keterangan_valuta,
                                produk: nama_produk,
                                kode_cabang,
                                nama_cabang
                            },

                        }
                    }))
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "nomor_rekening") });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "nomor_rekening") });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => changeForm("", "nomor_rekening") });
        }
        closeLoading();
    }


    async function getDataDetail(dbId = location.state?.dbId) {

        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/tariktunai/${dbId}`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    if(paramUrl.mode === "otorisasi") cekNasabah(data.nomor_rekening)
                    setFormData(form => ({
                        "nomor_rekening": data.nomor_rekening,
                        "nominal": parseFloat(data.nominal).toFixed(2),
                        "nomor_referensi": data.nomor_referensi,
                        "keterangan": data.keterangan,
                        "kode_valuta": data.kode_valuta,
                        "info_transaksi": {
                            ...form.info_transaksi,
                            "saldo_teller": parseFloat(data.info_transaksi?.saldo_teller_before).toFixed(2),
                            "nominal_ekuivalen": parseFloat(data.info_transaksi?.nominal_ekuivalen).toFixed(2),
                            "biaya": parseFloat(data.info_transaksi?.biaya).toFixed(2),
                            "biaya_ekuivalen": parseFloat(data.info_transaksi?.biaya_ekuivalen).toFixed(2),
                            "sumber_biaya": parseFloat(data.info_transaksi?.sumber_biaya).toFixed(2),
                            "kurs_jual": parseFloat(data.info_transaksi?.kurs_jual).toFixed(2),
                            "kurs_beli": parseFloat(data.info_transaksi?.kurs_beli).toFixed(2),
                            "kurs_buku": parseFloat(data.info_transaksi?.kurs_buku).toFixed(2),
                            "nomor_seri_warkat": data.info_transaksi?.nomor_seri_warkat,
                            info_rekening: {
                                ...form.info_transaksi.info_rekening,
                                "nama_rekening": data.info_transaksi?.nama_rekening,
                                "kode_valuta": data.info_transaksi?.kode_valuta,
                                "keterangan_valuta": data.info_transaksi?.keterangan_valuta,
                                "produk": data.info_transaksi?.produk,
                                "kode_cabang": data.info_transaksi?.kode_cabang,
                                "nama_cabang": data.info_transaksi?.nama_cabang
                            }
                        }
                    }));
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
        // // // console.log(data)
    }


    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    function openModalSVS() {
        if (nomorNasabah === null) {
            return setDataAlert({ show: true, body: "Tidak ada data rekening terpilih", title: ``, header: "Warning", callBack: () => null });
        } else {
            setModalSVS(true)
        }
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    });
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    function resetFormCetak() {
        setFormCetak({
            command: "PRINT",
            medianame: "Term Cash Withdrawal",
            formname: "Term Cash Withdrawal",
            print: 1,
            history: 0,
            id: null,
            nomorTransaksi: "",
            kodeCabang: "",
            userTeller: "",
            tgl: "",
            userOtorisasi: "",
            kodeCabangDebit: "",
            norekDebit: "",
            kodeTarikan: "",
            jenisMataUang: "",
            nominalDebit: "",
            kodeDebit: "",
            kodeCabangKredit: "",
            norekKreditKasTeller: "",
            nominalKredit: "",
            kodeKredit: "",
            berita: "",
            tanggalTranskasi: "",
            jamTransaksi: ""
        })
    }


    return <ttContext.Provider
        value={{
            ttState: {
                dataListRekening,
                isLoading,
                filter,
                formData,
                formEditable,
                dataDropDown,
                dateNow,
                otorDesc,
                dataPrint,
                nomorNasabah,
                modalSVS,

            },
            ttAction: {
                closeAlert,
                closeConfirm,
                setFilter,
                changeForm,
                confirmTrx,
                cekNasabah,
                setOtorDesc,
                responseOtor,
                validasi,
                cetakFunction,
                setModalSVS,
                openModalSVS,
                resetForm,
                cekBilyet
            }
        }}
    >
        {children}
    </ttContext.Provider>;
}


export default function useTarikTunai() { return useContext(ttContext) };