// import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { TableList } from '../../../../controls';
import useTrxVer, { TrxVerProvider } from './useTrxVerifikasi';
import useKeycloak from '../../../../../hooks/keycloak.module';
import { addCommas } from '../../../../../module';
function TrxVerifikasi() {
    const { verState, verAction } = useTrxVer();
    const { keycloak } = useKeycloak()
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Informasi Transaksi</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Verifikasi Transaksi 
                    </li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12">
                    <div className="card" >
                        <div className="card-header" >
                            <div className="card-title" >
                                VERIFIKASI TRANSAKSI DAN TUTUP CABANG
                            </div>
                            {
                                
                                    <button 
                                        // disabled={verState.infoCloseCabang === 'C'}
                                        onClick={()=>verAction.confirmCloseBranch()}
                                        style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                        className="btn btn-outline-danger float-right"
                                        >
                                        <p>CLOSE BRANCH</p>
                                    </button>
                            }
                             <button 
                                onClick={()=>verAction.reloadPage()}
                                style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                className="btn btn-outline-secondary btn-sm float-right mr-2">
                                <i className="fa fa-undo-alt" > </i> Refresh
                            </button>
                        </div>
                        <div className="card-body" >
                            <TableList
                                withCard={false}
                                minHeight="450px"
                                maxHeight="500px"
                                onChangeShow={verAction.changeShow}
                                onNextPage={verAction.nextPage}
                                onPrevPage={verAction.prevPage}
                                withActions={false}
                                actionItems={[]}
                                selectionMode = {1}
                                fieldSelectionKey = 'id_transaksi'
                                onSelection = {(e, item) => item.status_otorisasi !=="Waiting" && verAction.setViewCheckBox(e)}
                                defaultSelection = {verState.viewCheckBox}
                                columns={[
                                        // {
                                        //     name: 'Id Transaksi',
                                        //     field: 'id_transaksi',
                                        // },
                                        {
                                            name: 'No Referensi',
                                            field: 'nomor_referensi',
                                        },
                                        {
                                            name: 'Jenis Transaksi',
                                            field: 'jenis_transaksi',
                                        },                                       
                                        {
                                            name: 'Nilai Mutasi',
                                            field: 'nilai_mutasi',
                                            onRender: ({nilai_mutasi}) => addCommas(parseFloat(nilai_mutasi || 0).toFixed(2))
                                        },
                                        {
                                            name: 'Status Otorisasi',
                                            field: 'status_otorisasi',
                                        },
                                        {
                                            name: 'User Input',
                                            field: 'user_input',
                                        },
                                        {
                                            name: 'User Otor',
                                            field: 'user_otorisasi',
                                        },
                                        {
                                            name: 'Jam Input',
                                            field: 'jam_input',
                                        },
                                        {
                                            name: 'Jam Otor',
                                            field: 'jam_otorisasi',
                                        },
                                 ]}
                                 data={verState.dataList}
                                 isLoading={verState.isLoading}
                                 filter={false}
                                //  resetFilter={false}
                            />
                        </div>
                        <div className="card-footer">
                            {
                                    <button
                                        disabled={verState.infoCloseCabang === 'C'} 
                                        // disabled={verState.viewCheckBox.length === 0}
                                        onClick={()=>verAction.confirmPost()}
                                        style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                        className="btn btn-outline-info float-right"
                                        >
                                        <p>APPROVE VERIFIKASI</p>
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default () => <TrxVerProvider><TrxVerifikasi/></TrxVerProvider>;