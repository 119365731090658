// import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import React, { memo, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { baseUrl } from '../../../app.config';
import useSidebar from './useSidebar';
import useKeycloak from '../../../hooks/keycloak.module';
import { useLocalStorage } from '../../../module';
import { useModalAlert } from '../../controls/modalAlert';

const SideMenu = memo(
    ({
        children,
        childs,
        href,
        icon,
        iconType,
        hasChild = false,
        badge,
        active,
        onClick = null,
        openBranch = false
    }) => {
        const { sideAction } = useSidebar(); 
        const [status, setStatus] = useLocalStorage("STATUS_BDS", {})  
        const { modalState, modalAction } = useModalAlert();
        const {
            setDataAlert
        } = modalAction;
        const { keycloak } = useKeycloak()

        const cekStatus = function(){
            // console.log(status);
            if(openBranch && keycloak.statusBranch?.status_bds === "C"){
                setDataAlert({ show: true, body: "Menu tidak dapat diakses ketika status cabang TUTUP", header: `Access Denied`, callBack: () => null });
            }
        }
        if (hasChild)
            return (
                <>
                    <div
                        onClick={onClick}
                        className={clsx(
                            'tw-flex tw-items-center tw-px-4 tw-py-2 tw-cursor-pointer tw-select-none',
                            active ? 'text-primary-bds' : 'tw-text-black-80'
                        )}
                    >
                        {iconType === 'css' ? <i className={`nav-icon tw-mr-2 ${icon}`}></i> : icon}
                        <p className="tw-flex tw-items-center tw-justify-between tw-w-full">
                            {children}
                            <i
                                className={clsx(
                                    'fas right',
                                    active ? 'fa-angle-up' : 'fa-angle-right'
                                )}
                            ></i>
                            {badge > 0 && <span className="badge badge-info right">{badge}</span>}
                        </p>
                    </div>
                    <div className="tw-pl-8">
                        <CreateChild childs={childs} active={active} />
                    </div>
                </>
            );

        return (
            <>
                <Link to={ openBranch && keycloak.statusBranch?.status_bds === "C" ? '#!' : href} onClick={cekStatus} className="nav-link">
                    {iconType === 'css' ? <i className={`nav-icon ${icon}`}></i> : icon}
                    <p className="tw-text-black-80">
                        {children}
                        {badge > 0 && <span className="badge badge-info right">{badge}</span>}
                    </p>
                </Link>
            </>
        );
    }
);

function CreateChild({ childs, active }) {
    var location = useLocation();
    const { keycloak } = useKeycloak();
    const { sideAction } = useSidebar();

    // const { data } = useQuery('status-kas-teller', async () => {
    //     const res = await fetch(baseUrl + '/teller/find/teller-1');

    //     const data = await res.json();

    //     return data.list?.db?.state;
    // });

    const data = {};

    const hasRole = useCallback(
        (roles = []) => {
            if (roles.length === 0) return true;
            if((keycloak.tokenParsed.realm_access?.roles || []).length <= 0 || keycloak.tokenParsed.realm_access?.roles === null ){
                return roles.some((role) => role === "");
            } 
            return roles.some((role) => keycloak.hasRealmRole(role));
        },
        [keycloak]
    );

    return (
        <>
            <ul class="nav nav-treeview" style={{ display: active ? 'block' : 'none' }}>
                {childs
                    .filter((v) => {
                        if (data === 'CLOSE' && hasRole(['teller'])) {
                            return (
                                v.type !== 2 &&
                                [
                                    '/kas-teller/tutup-kas',
                                    '/query/gl',
                                    '/query/rekening',
                                    '/informasi-transaksi/informasi-kas-teller',
                                    '/informasi-transaksi/informasi-transaksi-hari-ini',
                                ].includes(v.key)
                            );
                        }

                        return v.type !== 2 && hasRole(v.roles);
                    })
                    .map((v, i) => (
                        <li className="nav-item" key={i}>
                            <SideMenu openBranch={v.openBranch} iconType={v.iconType} icon={v.icon} href={ sideAction.getParam(v.key, v.paramUrl)}>
                                {v.key === location.pathname ? <b> {v.name} </b> : v.name}
                            </SideMenu>
                        </li>
                    ))}
            </ul>
        </>
    );
}
export default SideMenu;
