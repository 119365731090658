import { param } from "jquery";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { useModalAlert } from "../../../controls/modalAlert";

const svsContext = createContext(null);

export const SVSProvider = ({ children, isOtor = false }) => {
  // get id from queryParam
  // const search = useLocation().search;
  // const id = new URLSearchParams(search).get("id");
  const { id } = useParams();

  const { modalState, modalAction } = useModalAlert();
  const {

      closeAlert,
      closeConfirm,
      closeLoading,
      setDataAlert,
      setDataConfirm,
      setLoading,
      closeConfirmCustom,
      openAlertCustom,
      openLoading
  } = modalAction;
  //   Initial data
  const initSvs = {
    svsData: [],
    svsParams: [
      {
        data: {
          nomor_cif: null,
          encoded_key_document: "string",
          filename: "",
          image_tag: "",
          remark: "",
          type: "Nasabah Badan Usaha",
        },
        options: {
          signed_url: "",
          isChoosedFile: false,
          selectedFile: null,
          selectedFileUrl: null,
          originalFile: null,
        },
      },
      {
        data: {
          nomor_cif: null,
          encoded_key_document: "string",
          filename: "",
          image_tag: "",
          remark: "",
          type: "Nasabah Badan Usaha",
        },
        options: {
          signed_url: "",
          isChoosedFile: false,
          selectedFile: null,
          selectedFileUrl: null,
          originalFile: null,
        },
      },
      {
        data: {
          nomor_cif: null,
          encoded_key_document: "string",
          filename: "",
          image_tag: "",
          remark: "",
          type: "Nasabah Badan Usaha",
        },
        options: {
          signed_url: "",
          isChoosedFile: false,
          selectedFile: null,
          selectedFileUrl: null,
          originalFile: null,
        },
      },
      {
        data: {
          nomor_cif: null,
          encoded_key_document: "string",
          filename: "",
          image_tag: "",
          remark: "",
          type: "Nasabah Badan Usaha",
        },
        options: {
          signed_url: "",
          isChoosedFile: false,
          selectedFile: null,
          selectedFileUrl: null,
          originalFile: null,
        },
      },
    ],
    isLoading: false,
    showModalInformation: false,
    infoData: {},
  };
  const initNasabahInfo = {
    nama_rekening: "",
    nomor_rekening: "",
    nomor_cif: "",
    alamat: "",
    jenis_identitas: "",
    status: "",
  };
  const initDataAlert = {
    show: false,
    body: "",
    header: null,
    title: "",
    callBack: () => null,
  };
  const initDataConfirm = {
    show: false,
    body: "",
    title: "",
    header: null,
    footerButton: [],
    closeButton: false,
    witTextArea: false,
  };
  const initLoading = {
    loadingSvsList: false,
    loadingInfoNasabah: false,
    loadingGetSVS: false,
    loadingDeleteSVS: false,
  };
  const initSvsOtorView = {
    nomor_cif: "",
    status: "",
    created_at: "",
  };
  const initDataOtor = {
    kode_entry: "",
    dbId: "",
    callBackSuccess: () => null,
  };

  const { doGet, doPost, wait } = useFetch();
  const [otorMode, setOtorMode] = useState(false);
  const [modalSVS, setModalSVS] = useState(false);
  const [modalCropper, setModalCropper] = useState(false);
  const [nasabahInfo, setNasabahInfo] = useState(initNasabahInfo);
  const [svsOtorView, setSvsOtorView] = useState(initSvsOtorView);
  const [svsData, setSvsData] = useState(initSvs);
  // const [loading, setLoading] = useState(initLoading);
  // const [dataAlert, setDataAlert] = useState(initDataAlert);
  // const [dataConfirm, setDataConfirm] = useState(initDataConfirm);
  const [dataOtor, setDataOtor] = useState(initDataOtor);
  const [otorDesc, setOtorDesc] = useState("");

  // Modal Handler
  // function closeAlert() {
  //   setDataAlert(initDataAlert);
  // }
  // closeConfirm
  // function closeConfirm() {
  //   setDataConfirm(initDataConfirm);
  // }

  function openModalSVS(data, isOtorisasi = false, callBackSuccess = () => null) {
    setSvsOtorView(initSvsOtorView);
    setOtorMode(isOtorisasi);
    if (isOtorisasi) {
      setDataOtor({ ...data, callBackSuccess });
      getOtorView(isOtorisasi ? data.dbId : null, isOtorisasi);
    } else {
      setSvsOtorView(data);
    }
    setModalSVS(true);
  }
  function closeModalSVS() {
    setModalSVS(false);
  }
  function openModalCropper() {}

  async function getOtorView(db_id, isOtorisasi) {
    // setLoadingWarkat(true)
    var url = ``;
    if (isOtorisasi) url = `/nasabah/newsvs/otor-view/${db_id}`;
    try {
      const {
        statusCode,
        status,
        remark,
        statusText,
        data = {},
      } = await doGet({
        url: url,
        service: "cif",
      });

      if (statusCode === 200) {
        if (status === "00") {
          setSvsOtorView(data.newSVS);
        } else
          setDataAlert({
            show: true,
            body: remark,
            title: `Status fetch data is ${statusCode}`,
            callBack: () => null,
          });
      }
    } catch (error) {
      setDataAlert({
        show: true,
        body: error.toString(),
        title: "Terjadi Kesalahan",
        header: "Error",
        callBack: () => null,
      });
    }
    await wait(3000);
    // setLoadingWarkat(false)
  }

  async function getNasabahInfo(no_cif, isOtorisasi) {
    setLoading((prev) => ({ ...prev, loadingInfoNasabah: true }));
    var url = `/nasabah/portofolio/${no_cif}`;
    try {
      const {
        statusCode,
        status,
        remark,
        statusText,
        data = {},
      } = await doGet({
        url: url,
        service: "cif",
      });

      if (statusCode === 200) {
        if (status === "00") {
          setNasabahInfo(data.portfolio);
        } else console.log("Error");
      } else console.log("error");
    } catch (error) {
      console.log("Error");
    }
    await wait(3000);
    setLoading((prev) => ({ ...prev, loadingInfoNasabah: false }));
  }

  async function getSvsList(no_cif, isOtorisasi) {
    setLoading((prev) => ({ ...prev, loadingSvsList: true }));
    var url = `/nasabahget-svs-by-no-cif/${no_cif}`;
    try {
      const {
        statusCode,
        status,
        remark,
        statusText,
        data = {},
      } = await doGet({
        url: url,
        service: "cif",
      });

      if (statusCode === 200) {
        if (status === "00") {
          let list = [];
          data.forEach((element) => {
            list.push({
              nomor_cif: element.nomor_nasabah,
              signed_url: element.url_path,
              remark: element.keterangan,
              image_tag: element.image_tag,
              index: element.id_nasabahimage,
              tgl_input: element.tgl_input,
              tgl_auth: element.tgl_auth,
            });
          });
          setSvsData((prevState) => ({ ...prevState, svsData: list }));
        } else console.log("Error");
      } else console.log("error");
    } catch (error) {
      console.log("Error");
    }
    await wait(3000);
    setLoading((prev) => ({ ...prev, loadingSvsList: false }));
  }

  async function setDeleteSVS(db_id, isOtorisasi) {
    setLoading((prev) => ({ ...prev, loadingDeleteSVS: true }));
    var url = `/nasabah/newsvs/delete/${db_id}`;
    try {
      const {
        statusCode,
        status,
        remark,
        statusText,
        data = {},
      } = await doPost({
        url: url,
        service: "cif",
      });

      if (statusCode === 200) {
        if (status === "00") {
          setDataAlert({
            show: true,
            body: "Hapus data SVS berhasil!, perlu otorisasi untuk melanjutkan",
            header: null,
            title: "",
            callBack: () => {
              closeConfirm();
              closeModalSVS();
            },
          });
        } else
          setDataAlert({
            show: true,
            body: "Error",
            header: null,
            title: "",
            callBack: () => null,
          });
      } else
        setDataAlert({
          show: true,
          body: "Terjadi kesalahan",
          header: null,
          title: "",
          callBack: () => null,
        });
    } catch (error) {
      setDataAlert({
        show: true,
        body: JSON.stringify(error),
        header: "Gagal",
        title: "",
        callBack: () => null,
      });
    }
    await wait(3000);
    setLoading((prev) => ({ ...prev, loadingDeleteSVS: false }));
  }

  // response otorisasi
  function responseOtor(status) {
    let body = `Apakah anda yakin akan ${status} SVS ini ?`;
    setDataConfirm({
      show: true,
      body,
      witTextArea: status === "REJECTED",
      header: "Confirmation",
      title: "",
      closeButton: false,
      footerButton: [
        { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
        { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" },
      ],
    });
  }

  // fetch API response otorisasi
  async function fetchOtorisasi(statusApprove) {
    closeConfirm();
    // setLoading((prevState) => ({
    //   ...prevState,
    //   loadingPost: { show: true, msg: "Please Wait ..." },
    // }));
    openLoading();
    let keterangan = otorDesc;
    if (keterangan === "" || keterangan === null) {
      if (statusApprove === "APPROVED") keterangan = "Approve";
      else keterangan = "Rejected";
    }
    if (dataOtor !== null && dataOtor !== undefined) {
      try {
        const {
          statusCode,
          remark = "",
          data = null,
          status = "01",
          statusText = "",
          message = "",
        } = await doPost({
          url: `/otorisasi/${dataOtor.kode_entry}/${dataOtor.dbId}/${statusApprove}?keterangan=${keterangan}`,
          method: "PUT",
          service: "cif",
          data: { keterangan: otorDesc },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (statusCode === 200) {
          if (status === "00") {
            setDataAlert({
              show: true,
              body: `Proses Otorisasi Berhasil`,
              title: ``,
              header: "Success",
              callBack: () => dataOtor.callBackSuccess(),
            });
          }else {
            setDataAlert({
              show: true,
              body: `${remark}. ${message}`,
              title: `${statusText}`,
              header: "Error",
              callBack: () => null,
            });
          }
        } else {
          setDataAlert({
            show: true,
            body: `${remark}. ${message}`,
            title: `${statusText}`,
            header: "Error",
            callBack: () => null,
          });
        }
      } catch (error) {
        setDataAlert({
          show: true,
          body: error.toString(),
          title: ``,
          header: "Error",
          callBack: () => dataOtor.callBackSuccess(),
        });
      }
    } else {
      setDataAlert({
        show: true,
        body: "Tidak ada data otorisasi terpilih",
        title: `Otorisasi Not Found`,
        header: "Error",
        callBack: () => dataOtor.callBackSuccess(),
      });
    }
    closeLoading()
    // setLoading((prevState) => ({
    //   ...prevState,
    //   loadingPost: { show: false },
    // }));
  }

  const handleFileUpload = async (file, index) => {
    var url = `/upload/image`;

    try {
      const {
        statusCode,
        status,
        data = {},
      } = await doPost({
        type: 1,
        url: url,
        paramImage: "file",
        service: "cif",
        data: { file: file },
        headers: {
          method: "POST",
        },
      });

      if (statusCode === 200) {
        if (status === "00") {
          return data.url;
        } else console.log("error");
      } else console.log("error");
    } catch (error) {
      console.log("error");
    }
  };

  const handleFileChange = async (event, index) => {
    const svsParams = [...svsData.svsParams];
    const file = event.target.files[0];

    if (file) {
      svsParams[index] = {
        data: {
          ...svsParams[index].data,
          filename: event.target.files[0].name,
        },
        options: {
          ...svsParams[index].options,
          isChoosedFile: true,
          originalFile: event.target.files[0],
        },
      };
      setSvsData((prevState) => ({ ...prevState, svsParams: svsParams }));
      setModalCropper(true);
    }
  };

  const handleFileCropChange = async (event, index) => {
    const svsParams = [...svsData.svsParams];
    const file = event;

    if (file) {
      svsParams[index] = {
        data: {
          ...svsParams[index].data,
        },
        options: {
          ...svsParams[index].options,
          selectedFile: file,
          selectedFileUrl: URL.createObjectURL(file),
          signed_url: await handleFileUpload(file),
        },
      };
      setSvsData((prevState) => ({ ...prevState, svsParams: svsParams }));
    }
  };

  const handleFileRemove = (index) => {
    const svsParams = [...svsData.svsParams];

    svsParams[index] = initSvs.svsParams[index];
    // // console.log(svsParams)
    setSvsData((prevState) => ({ ...prevState, svsParams: svsParams }));
  };

  const handleTextChanged = (event, index) => {
    const value = event.target.value;
    const name = event.target.name;
    const svsParams = [...svsData.svsParams];
    svsParams[index] = {
      data: {
        ...svsParams[index].data,
        [name]: value,
      },
      options: {
        ...svsParams[index].options,
      },
    };
    setSvsData((prevState) => ({ ...prevState, svsParams: svsParams }));
  };

  const postSVS = async () => {
    var url = `/nasabah/newsvs`;
    let promises = [];

    for (let i = 0; i < svsData.svsParams.length; i++) {
      if (svsData.svsParams[i].options.selectedFile !== null) {
        let formData = {
          nomor_cif: id,
          image_tag: svsData.svsParams[i].data.image_tag,
          remark: svsData.svsParams[i].data.remark,
          filename: `svs_image_${i}`,
          signed_url: svsData.svsParams[i].options.signed_url,
        };
        promises.push(
          doPost({
            url: url,
            service: "cif",
            data: formData,
            headers: {
              method: "POST",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
        );
      }
    }
    if (promises.length !== 0) {
      Promise.all(promises)
        .then((e) => {
          setDataAlert({
            show: true,
            body: "Data yang diinputkan :" + e.length,
            title: "Berhasil menambahkan SVS, perlu otorisasi untuk melanjutkan",
            header: "Sukses",
            callBack: () => {
              setSvsData((prev) => ({ ...prev, svsParams: initSvs.svsParams }));
              getSvsList(id);
            },
          });
        })
        .catch((error) => {
          // console.log("Error" + error);
        });
    } else {
      setDataAlert({
        show: true,
        body: "Silahkan upload gambar minimal 1",
        header: "Perhatian",
        callBack: () => null,
      });
    }

    // svsData.svsParams.map(async (item, index) => {
    //   if (item.options.selectedFile !== null) {
    //     let formData = {
    //       nomor_cif: id,
    //       image_tag: item.data.image_tag,
    //       remark: item.data.remark,
    //       filename: `svs_image_${index}`,
    //       signed_url: item.options.signed_url,
    //     };

    //     try {
    //       const { statusCode, status, data = {}} = await doPost({
    //         url: url,
    //         service: "cif",
    //         data: formData,
    //         headers: {
    //           method: "POST",
    //           "Content-Type": "application/json",
    //           Accept: "application/json",
    //         },
    //       });

    //       if (statusCode === 200) {
    //         if (status === "00") {
    //           setDataAlert({ show: true, body: "Data yang diinputkan :"+count, title: 'Berhasil menambahkan SVS, perlu otorisasi untuk melanjutkan', header: "Sukses", callBack: () => null });
    //         } else // console.log("error");
    //       } else // console.log("error");
    //     } catch (error) {
    //       // console.log("error");
    //     }
    //   }
    // })
    // // console.log(fetch.then(// console.log()));
    // if(count>0){
    //   setDataAlert({ show: true, body: "Data yang diinputkan :"+count, title: 'Berhasil menambahkan SVS, perlu otorisasi untuk melanjutkan', header: "Sukses", callBack: () => null });
    // }
  };

  useEffect(() => {
    if (!isOtor) {
      getNasabahInfo(id);
      getSvsList(id);
    }
  }, [isOtor]);

  return (
    <svsContext.Provider
      value={{
        svsState: {
          dataAlert : {},
          dataConfirm :{},
          loading : false,
          modalCropper,
          modalSVS,
          nasabahInfo,
          otorMode,
          svsData,
          svsOtorView,
        },
        svsAction: {
          closeAlert,
          closeConfirm,
          closeModalSVS,
          handleFileChange,
          handleFileRemove,
          handleFileCropChange,
          handleTextChanged,
          openModalSVS,
          postSVS,
          responseOtor,
          setDataAlert,
          setDataConfirm,
          setDeleteSVS,
          setModalCropper,
          setModalSVS,
          setOtorDesc,
        },
      }}>
      {children}
    </svsContext.Provider>
  );
};

export default function useSVS() {
  return useContext(svsContext);
}
