import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router';
import moment from 'moment';
import useKeycloak from '../../../../../hooks/keycloak.module';

export const aftContext = createContext({
    aftAction: {},
    aftState: {}
});


export function AftProvider({ children, otorModeP=false }) {
    const { modalAction, modalState } = useModalAlert()
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [dataList, setDataList] = useState([])
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [isLoading, setIsLoading] = useState(false)
    const [loadingDetil, setLoadingDetil] = useState(false)
    const { doGet, doPost, wait } = useFetch()
    const [ filter, setFilter ]= useState({
        nomor_rekening : ""
    });
    const [ otorDesc, setOtorDesc ] = useState("");
    const [ dataOtor, setDataOtor] = useState({})
    const [ modalDetil, setModalDetil] =useState({
        show: false,
        mode:0
    })
    const [otorMode, setOtorMode] = useState(false)
    const [ formData, setFormData] = useState({
        
            "id_register": 0,
            "nomor_rekening_debit": "",
            "nama_rekening_debit": "",
            "nomor_rekening_kredit": "",
            "nama_rekening_kredit": "",
            "periode_proses": "",
            "jarak_proses_periode": "",
            "tgl_awal": "",
            "tgl_akhir": "",
            "jenis_si": "",
            "nominal_si": "",
            "maksimum_gagal_proses": ""
          
    });
    const location = useLocation()
    const { keycloak } = useKeycloak()

    // reset pagination
    function resetPagination(){
        setPagination({ page: 1, show: 15 })
    }
    // getData rekening list
    async function getDataList() {
        setIsLoading(true)
        var url = `/aft/search`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { ...pagination, ...filter },
                service:"acc"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    setDataList(data?.list)
                }
                // else setDataAlert({ show: true, body: remark, titile: "info", header:"info", callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    useEffect(function(){
        if(!otorModeP) getDataList();
    }, [pagination])
    
    // next pagination
    function nextPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    function resetForm(){
        setOtorMode(false)
        setFormData({
            
            "id_register": 0,
            "nomor_rekening_debit": "",
            "nama_rekening_debit": "",
            "nomor_rekening_kredit": "",
            "nama_rekening_kredit": "",
            "periode_proses": "",
            "jarak_proses_periode": "",
            "tgl_awal": "",
            "tgl_akhir": "",
            "jenis_si": "",
            "nominal_si": "",
            "maksimum_gagal_proses": ""
        })
    }

    
    function closeModalDetil() {
        setModalDetil({
            show: false,
            mode:0
        });
    }

    function openModalDetil(mode, data, callBackSuccess = () => null) {
        if(mode === 0) 
        {
            const {
                id_register= 0,
                keterangan= "",
                nama_rekening_debit= "",
                nama_rekening_kredit= "",
                nomor_rekening_debit= "",
                nomor_rekening_kredit= "",
                status= "",
                tgl_kadaluarsa= "",
                tgl_proses= "",
                tgl_proses_berikutnya= "",
                tgl_registrasi= "",
                nominal_si=0
            } = data;
            setFormData(form=>({    
                id_register ,
                nomor_rekening_debit,
                nama_rekening_debit,
                nomor_rekening_kredit,
                nama_rekening_kredit,
                "periode_proses": "",
                "jarak_proses_periode": "",
                tgl_awal: moment(tgl_proses).format("YYYY-MM-DD"),
                "tgl_akhir": moment(tgl_kadaluarsa).format("YYYY-MM-DD"),
                "jenis_si": "",
                "maksimum_gagal_proses": "",
                nominal_si,
                status
            }));
        }
        else if(mode === 1){
            setOtorMode(true);
            setDataOtor({
                ...data, 
                callBackSuccess
            })
            getDetail(data.dbId)
        }

        // console.log(data)
        setModalDetil({
            show: true,
            mode
        });
    }
  

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // getDetail
    async function getDetail(nomor_rekening, rekStatus) {
        setLoadingDetil(true)
        var url = `/aft/nonaktifasi/otor-view/${nomor_rekening}`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                  setFormData(data)
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingDetil(false)
    }

    function resetFilter(){
        setFilter({
            nomor_rekening:""
        });
        resetPagination();
    }

     // response otorisasi
     function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} otorisasi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirmCustom, className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

      // fetch API response otorisasi
      async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        // if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url:  `/otorisasi/${dataOtor.kode_entry}/${dataOtor.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "acc",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            // resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            dataOtor.callBackSuccess()
                            // history.goBack()s
                        }
                    });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        // } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    async function doTrx() {
        
    closeConfirm();
        // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        const {
            id_register,
            nomor_rekening_debit,
            nama_rekening_debit,
            nomor_rekening_kredit,
            nama_rekening_kredit,
            periode_proses,
            jarak_proses_periode,
            tgl_awal,
            tgl_akhir,
            jenis_si,
            maksimum_gagal_proses,
            nominal_si,
        } = formData;
        let body = {
            id_register,
            nomor_rekening_debit,
            nama_rekening_debit,
            nomor_rekening_kredit,
            nama_rekening_kredit,
            periode_proses,
            jarak_proses_periode,
            tgl_awal,
            tgl_akhir,
            jenis_si,
            maksimum_gagal_proses,
            nominal_si,
        }
        let url = "/aft/nonaktifasi";
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: formData,
                url, // ganti sesuai api
                service: "acc" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: "Nonaktifasi AFT (Automatic Funding Transfer) Berhasil. dibutuhkan otorisasi AFT untuk melanjutkan", title: ``, header: "Success", callBack: () => {
                        resetForm();
                        closeConfirm();
                        closeModalDetil();
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }
    
    function confirmTrx() {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }


//Post Auditrail
async function auditTrail() {
    const userInfo = await keycloak.loadUserInfo();
    // console.log(userInfo)
    var url = `/useraudit/user-audit-trail`;
    try {
        const { statusCode, status, remark, statusText, data = {} } = await doPost({
            url: url,
            service: "acc",
            data: { 
                    user_id : userInfo.preferred_username,
                    app_id : "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Get Data List",
                    event_description: "Tabungan & Giro/Data AFT (Automatic Funding Transfer)",
                    info1: "-",
                    info2: "-"
            },
            headers: {
                "x-user-id": userInfo.preferred_username,
                method: "POST"
            }
        });

        if (statusCode === 200) {
            if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
            else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
        } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    } catch (error) {
        setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    }
    
}
    return <aftContext.Provider value={{
        aftAction: {
            nextPage,
            prevPage,
            changeShow,
            setFilter,
            getDataList,
            resetPagination,
            resetFilter,
            setOtorDesc,
            closeModalDetil,
            openModalDetil, 
            confirmTrx,
            responseOtor,
            auditTrail
        },
        aftState: {
            dataList,
            isLoading,
            filter,
            otorDesc,
            modalDetil,
            formData,
            otorMode
        }
    }} >{children}</aftContext.Provider>
}


export default function useDataAft() { return useContext(aftContext) }