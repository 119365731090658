import React, { useState, useEffect } from 'react';
import useFormPerorangan from './useFormPerorangan';
import useFetch from '../../../../../hooks/useFetch';
import { useHistory, useLocation, useParams } from 'react-router';

const useDataRekening = () => {
    const { fpAction, fpState } = useFormPerorangan()
    const [formModal, setFomrModal] = useState(false)
    const [formPejabat, setFormPejabat] = useState({
        "DrNomorRekening": "",
        "DrNamaSandiBank": "",
        "DrMataUang": "IDR - Rupiah",
        "DrKeterangan": "",
        "DrJenisRekening": "",
        "DrNamaBank": "",
        "DrTglPembukaan": "",    
        "DrAction": "U" 
    })
    // DrJenisRekening : Optional[str] = None
    // DrNomorRekening : str
    // DrMataUang : Optional[str] = None
    // DrNamaSandiBank : Optional[str] = None
    // DrNamaBank : Optional[str] = None
    // DrTglPembukaan : Optional[str] = None
    // DrKeterangan : Optional[str] = None
    // DrAction : Optional[str] = None

    const [mode, setMode] = useState("CREATE")
    const { doGet } = useFetch();
    const [dataBank, setDataBank] = useState([]);
    const paramUrl = useParams();
    const location = useLocation();
    // resetForm
    function resetForm(){
        setFormPejabat({
            "DrNomorRekening": "",
            "DrNamaSandiBank": "",
            "DrMataUang": "IDR - Rupiah",
            "DrKeterangan": "",
            "DrJenisRekening": "",
            "DrNamaBank": "",
            "DrTglPembukaan": "",           
        })
    }

    // close Modal
    function closeModal() { 
        resetForm();
        setFomrModal(false) 
    }
    // open Modal Add
    function openModalAdd() {
        setMode("CREATE")
        resetForm(); 
        setFomrModal(true);
    }
    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        if (subkey !== null) setFormPejabat(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormPejabat(prevState => ({ ...prevState, [key]: value }));
    }
    function openDetil(key, m = "DETAIL") {
        // setFormPejabat(prevState => ({ ...prevState, DrAction: "U"}))
        setMode(m)
        let data = { ...fpState.formData?._tambahRekeningLain?.[key], key };
        setFomrModal(true)
        setFormPejabat(data);
        if (!data)
            resetForm()
    }
    // save function
    function save() {
        if (formPejabat?.DrNomorRekening === '' || formPejabat?.DrNomorRekening === undefined || formPejabat?.DrNomorRekening === 0) {
            fpAction.setDataAlert({ show: true, body: "Nomor rekening belum dipilih", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        } 
        else if (formPejabat?.DrNamaSandiBank === '' || formPejabat?.DrNamaSandiBank === undefined || formPejabat?.DrNamaSandiBank === 0) {
            fpAction.setDataAlert({ show: true, body: "Sandi Bank belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        // else if (formPejabat?.DrMataUang === '' || formPejabat?.DrMataUang === undefined || formPejabat?.DrMataUang === 0) {
        //     fpAction.setDataAlert({ show: true, body: "Mata Uang belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        else if (formPejabat?.DrKeterangan === '' || formPejabat?.DrKeterangan === undefined || formPejabat?.DrKeterangan === 0) {
            fpAction.setDataAlert({ show: true, body: "Keterangan belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (formPejabat?.DrJenisRekening === '' || formPejabat?.DrJenisRekening === undefined || formPejabat?.DrJenisRekening === 0) {
            fpAction.setDataAlert({ show: true, body: "Jenis Rekening belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (formPejabat?.DrNamaBank === '' || formPejabat?.DrNamaBank === undefined || formPejabat?.DrNamaBank === 0) {
            fpAction.setDataAlert({ show: true, body: "Nama Bank belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else {
            const currentData = fpState.formData?._tambahRekeningLain;
            if (mode === "CREATE") {
                formPejabat['DrAction']= 'N'
                if (currentData === undefined) {
                    const newData = [formPejabat];
                    fpAction.changeForm(newData, "_tambahRekeningLain");
                } else {
                    const newData = [...currentData, formPejabat];
                    fpAction.changeForm(newData, "_tambahRekeningLain");
                }
            }
            else if (mode === "EDIT") {
                formPejabat['DrAction']= 'U'
                const newDataForm = { ...formPejabat }
                delete newDataForm.key;
                currentData[formPejabat.key] = newDataForm;
                fpAction.changeForm(currentData, "_tambahRekeningLain");
            }
            closeModal()
            // setFormPejabat(prevState => ({ ...prevState, DrAction: "N"}))
        }

    }
    //console.log(formPejabat);
    function del(key) {
        // setFormPejabat(prevState => ({ ...prevState, DrAction: "D"}))
        const currentData = fpState.formData?._tambahRekeningLain;
        currentData[key]['DrAction'] = 'D'
        fpAction.changeForm(currentData, "_tambahRekeningLain");
    }
    useEffect(function (params) {
        if (paramUrl.mode !== "otorisasi") {
            resetForm();
        } else {

        }
        getListBank();
    }, [paramUrl.mode])

    async function getListBank() {
        try {
            const resp = await doGet({
                //url: `/bank-transfer/${paramUrl.type}`,
                url: `/bank-transfer/skn`,
                service: "trx"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {

                if (status === "00") {
                    setDataBank(data.listbank)
                }
                else fpAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else fpAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            fpAction.setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
    }
    return {
        pkState: {
            formModal,
            formPejabat,
            dataBank,
        },
        pkAction: {
            closeModal,
            openModalAdd,
            setFormPejabat,
            save,
            openDetil,
            del,
            changeForm
        }
    };
}

export default useDataRekening;