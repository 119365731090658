import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import useNexusPrint from '../../../hooks/useNexusPrint';
import { Modal } from 'react-bootstrap';
import ModalCetakTransaksiPassbook from './modalCetakTransaksiPassbook';
import { useModalAlert } from '../modalAlert';
import moment from 'moment';
import { uniqueId } from 'lodash-es';
import useKeycloak from '../../../hooks/keycloak.module';
const cetakContext = createContext({ cAction: {}, cState: {} });
export function CetakProvider({ children, fastPrint = false }) {
    const { print } = useNexusPrint()
    const { keycloak } = useKeycloak()

    const {
        doGet, doPost, wait, createLog
    } = useFetch()
    const { modalAction, modalState } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [modalCetakTransaksi, setmodalCetakTransaksi] = useState(false)
    const [modalCetakTransaksiPrint, setmodalCetakTransaksiPrint] = useState(false)
    const [dataCetakTransaksiPrint, setdataCetakTransaksiPrint] = useState({

    });

    const [dataCetakTransaksi, setdataCetakTransakasi] = useState({
        "nomor_rekening": "",
        "id_cetak_awal": 0,
        "id_cetak_akhir": 0,
        "is_cetak_ulang": "F",
        "is_all_data": "T",
        "mulai_baris": 0
    });
    const [dataCetakUlang, setDataCetakUlang] = useState([])

    useEffect(function () {
        setDataCetakUlang([])
    }, [dataCetakTransaksi.is_cetak_ulang])

    // open Cetak trx passbook
    function openCetakTransaksiPasbook(data, isOtorisasi = false, callBackSuccess = () => null) {
        setdataCetakTransaksiPrint({})
        setdataCetakTransakasi({
            "nomor_rekening": "",
            "id_cetak_awal": 0,
            "id_cetak_akhir": 0,
            "is_cetak_ulang": "F",
            "is_all_data": "T",
            "mulai_baris": 0
        });

        // // // console.log(data)
        // setdataCetakTransakasi({
        //     "nama_rekening": "",
        //     "nomor_rekening": "",
        //     "nama_cabang": data.nama_cabang,
        // });
        // setOtorMode(isOtorisasi)
        // if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        getCetakTransaksiPasbook(data)
        setmodalCetakTransaksi(true)
    }
    // close Cetak tx passbook
    function closeCetakTransaksi() {
        setmodalCetakTransaksi(false)
    }

    function openCetakTransakasiPrint() {
        // return // console.log(dataCetakTransaksi)
        if (dataCetakTransaksi.is_cetak_ulang === "T") {
            if (
                dataCetakTransaksi.id_cetak_akhir === null || dataCetakTransaksi.id_cetak_akhir === null ||
                dataCetakTransaksi.id_cetak_akhir === "" || dataCetakTransaksi.id_cetak_akhir === "" ||
                dataCetakTransaksi.id_cetak_akhir === 0 || dataCetakTransaksi.id_cetak_akhir === 0 ||
                dataCetakTransaksi.id_cetak_akhir === undefined || dataCetakTransaksi.id_cetak_akhir === undefined ||
                dataCetakTransaksi.id_cetak_akhir === "0" || dataCetakTransaksi.id_cetak_akhir === "0"
            ) return setDataAlert({ show: true, body: "Pilih id cetak awal dan id cetak akhir dengan benar", header: "INFO", callBack: () => null })

            if (dataCetakUlang.length <= 0) {
                return setDataAlert({ show: true, body: "Tidak ada data yang di print", header: "PERINGATAN", callBack: () => null })
            }
        }

        const dataCetak = [
            {
                TANGGAL: "12/11/2021",
                KODE: "TES",
                NILAI_MUTASI_DEBET: "2000000",
                NILAI_MUTASI_KREDIT: "",
                SALDO: "30400000",
                USER: "ISI",
                BARIS: 1,
            },
            {
                TANGGAL: "12/11/2021",
                KODE: "TES",
                NILAI_MUTASI_KREDIT: "2000000",
                NILAI_MUTASI_DEBET: "",
                SALDO: "30400000",
                USER: "ISI",
                BARIS: 2,
            },
            {
                TANGGAL: "12/11/2021",
                KODE: "TES",
                NILAI_MUTASI_DEBET: "2000000",
                NILAI_MUTASI_KREDIT: "",
                SALDO: "30400000",
                USER: "ISI",
                BARIS: 12,
            },
            {
                TANGGAL: "12/11/2021",
                KODE: "TES",
                NILAI_MUTASI_KREDIT: "2000000",
                NILAI_MUTASI_DEBET: "",
                SALDO: "30400000",
                USER: "ISI",
                BARIS: 13,
            },
        ];

        // let dc = {
        //     command: "PRINT",
        //     medianame: "BJBS",
        //     formname: "Passbook_BJBS",
        //     orientation: "1",
        //     print: 1,
        //     history: 1,
        //     id: uniqueId(),
        // };

        // dataCetak.forEach(({
        //     TANGGAL = "", KODE = "", NILAI_MUTASI_DEBET = "", NILAI_MUTASI_KREDIT = "", SALDO = "", USER = "", BARIS = 1
        // }) => dc = {
        //     ...dc,
        //     [`GEN!PVHVFR${String(BARIS).padStart(2, "0")}`]: TANGGAL,
        //     [`AVH!NA1${String(BARIS).padStart(2, "0")}`]: KODE,
        //     [`GEN!PVHWID${String(BARIS).padStart(2, "0")}`]: NILAI_MUTASI_DEBET,
        //     [`GEN!PVHDEP${String(BARIS).padStart(2, "0")}`]: NILAI_MUTASI_KREDIT,
        //     [`GEN!PVHBAL${String(BARIS).padStart(2, "0")}`]: SALDO,
        //     [`DZX!OPID${String(BARIS).padStart(2, "0")}`]: USER,
        // })

        // print(dc)
        setmodalCetakTransaksi(false)
        postCetakTransaksiPrint()
        // setmodalCetakTransaksiPrint(true)
    }
    async function postCetakTransaksiPrint(d = null) {
        openLoading()
        var url = `/rpc/passbook`;
        let x = d
        // // // console.log(data)
        if (x === null) {
            x = {

                ...dataCetakTransaksi,
                id_cetak_awal: parseInt(dataCetakTransaksi.id_cetak_awal),
                id_cetak_akhir: parseInt(dataCetakTransaksi.id_cetak_akhir),
                mulai_baris: parseInt(dataCetakTransaksi.mulai_baris),
            }
        }
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "rpc",
                data: x,
            });
            if (statusCode === 200) {
                if (status === "00") {
                    // // console.log(data)
                    setdataCetakTransaksiPrint(data)
                    setmodalCetakTransaksiPrint(true)
                    let id_cetak_awal = 0;
                    // let mulai_baris = data.bariscetakterakhir
                    if (dataCetakTransaksi.is_cetak_ulang === "T") {
                        id_cetak_awal = data.idcetakterakhir;
                    }
                    setdataCetakTransakasi(p => ({
                        ...p,
                        id_cetak_awal,
                        // mulai_baris
                    }))
                }
                else setDataAlert({ show: true, body: remark, titile: ``, callBack: () => null });
            }
            else setDataAlert({ show: true, body: remark, header: "PERINGATAN", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        // await wait(3000)
        closeLoading()
    }
    useEffect(function () {
        // console.log(dataCetakTransaksi)
    }, [])
    async function doCetakTrxPassbook() {
        let userInfo = keycloak.loadUserInfo()
        setmodalCetakTransaksiPrint(false)
        openLoading("create url for  PrintServer...");

        let logs = {
            log_name: "Cetak Transaksi Tunda",
            user_login: userInfo.preferred_username,
            action: "Cetak Transaksi Tunda",
            data_transaksi: dataCetakTransaksiPrint.dttrx || []
        }

        let dc = {
            command: "PRINT",
            medianame: "BJBS",
            formname: "Passbook_BJBS",
            orientation: "1",
            print: 1,
            history: 0,
            id: uniqueId(),
        };

        (dataCetakTransaksiPrint.dttrx || []).map(function ({
            TANGGAL = "", KODE = "", NILAI_MUTASI_DEBET = "", NILAI_MUTASI_KREDIT = "", SALDO = "", USER = "", BARIS = 1
        }) {
            dc = {
                ...dc,
                [`GEN!PVHVFR${(BARIS.toString()).padStart(2, "0")}`]: TANGGAL,
                [`AVH!NA1${(BARIS.toString()).padStart(2, "0")}`]: KODE,
                [`GEN!PVHWID${(BARIS.toString()).padStart(2, "0")}`]: NILAI_MUTASI_DEBET,
                [`GEN!PVHDEP${(BARIS.toString()).padStart(2, "0")}`]: NILAI_MUTASI_KREDIT,
                [`GEN!PVHBAL${(BARIS.toString()).padStart(2, "0")}`]: SALDO,
                [`DZX!OPID${(BARIS.toString()).padStart(2, "0")}`]: USER,
                [`NO${(BARIS.toString())}`]: BARIS,
            }
        })

        // closeLoading();
        openLoading("connecting to PrintServer ...")
        logs = {
            ...logs,
            data_to_nexus: dc
        }

        try {
            const dataResponse = await print(dc);
            logs = {
                ...logs,
                url_to_nexus: `GET ${dataResponse.url}`,
                response_nexus: dataResponse.resp
            }
            createLog(logs)
            if (dataResponse.statusCode !== 200 && dataResponse.statusCode !== 201) {
                closeLoading()
                return setDataAlert({ show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Error", callBack: () => null });
            } else {
                closeLoading()
                setDataAlert({
                    show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Berhasil", callBack: () => {
                        if (dataCetakTransaksiPrint.is_need_newpage === "T") {
                            closeAlert();
                            setdataCetakTransakasi(p => ({
                                ...p,
                                mulai_baris: 1
                            }))
                            return setDataConfirm({
                                show: true,
                                body: "Lanjutkan cetak transaksi pasbook ke halaman berikutnya.?",
                                witTextArea: false,
                                header: "Konfirmasi",
                                title: "",
                                closeButton: false,
                                footerButton: [
                                    {
                                        name: "Ya", onClick: () => {
                                            postCetakTransaksiPrint({
                                                ...dataCetakTransaksi,
                                                mulai_baris: 1
                                            });
                                            closeConfirm();
                                        }, className: "btn btn-sm btn-success"
                                    },
                                    { name: "Tidak", onClick: ()=>{
                                        closeConfirm();
                                        openCetakTransaksiPasbook({ nomor_rekening : dataCetakTransaksi.nomor_rekening, nama_rekening: dataCetakTransaksi.nama_rekening })
                                    }, className: "btn btn-sm btn-secondary" }
                                ]
                            })
                            // return setDataConfirm({
                            //     show: true, body: "Lanjutkan cetak transaksi pasbook ke halaman berikutnya.", title: `Next Page`, header: "Info", callBack: () => {
                            //         postCetakTransaksiPrint({
                            //             ...dataCetakTransaksi,
                            //             mulai_baris: 1
                            //         });
                            //         closeAlert();
                            //     }
                            // });
                        } else {
                            setdataCetakTransakasi({
                                "nomor_rekening": "",
                                "id_cetak_awal": 0,
                                "id_cetak_akhir": 0,
                                "is_cetak_ulang": "F",
                                "is_all_data": "T",
                                "mulai_baris": 0
                            });
                            setdataCetakTransaksiPrint({});
                            closeAlert();
                            closeLoading();
                            return setDataAlert({
                                show: true, body: "Cetak transaksi pasbook selesai.", title: ``, header: "Info", callBack: () => null
                            })
                        }
                    }
                });
            }
        } catch (error) {
            closeLoading()
            logs = {
                ...logs,
                url_to_nexus: `GET to Nexus`,
                response_nexus: "Gagal koneksi ke print server. Periksa kembali koneksi print server"
            }
            createLog(logs)
            return setDataAlert({
                show: true, body: "Gagal koneksi ke print server. Periksa kembali koneksi print server", title: ``, header: "Info", callBack: () => {
                    closeAlert();
                    closeLoading();
                    setdataCetakTransakasi({
                        "nomor_rekening": "",
                        "id_cetak_awal": 0,
                        "id_cetak_akhir": 0,
                        "is_cetak_ulang": "F",
                        "is_all_data": "T",
                        "mulai_baris": 0
                    });
                }
            });
        }

        closeLoading()
    }
    async function getCetakTransaksiPasbook({ nomor_rekening, nama_rekening }, isOtorisasi) {
        // setloadingCetakStatement(true)
        openLoading()
        var url = `/rpc/lastline/${nomor_rekening}`;
        try {
            const { statusCode, status, remark, statusText, data = {}, lastline = 0, nomor_buku_terakhir = "" } = await doGet({
                url: url,
                service: "rpc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setdataCetakTransakasi(p => ({
                        ...p,
                        nomor_rekening,
                        nama_rekening,
                        mulai_baris: parseInt(lastline >= 28 ? 0 : lastline) + 1,
                        reg_buku: nomor_buku_terakhir,
                    }))
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        // await wait(3000)
        closeLoading()
    }
    //END CETAK TRANSAKSI

    async function getTrxCetakUlang(nomor_rekening = "", from = "", to = "") {
        if ((
            from === "" ||
            from === null ||
            from === undefined
        ) || (
                to === "" ||
                to === null ||
                to === undefined
            )) setDataAlert({ show: true, body: "Masukan tanggal terlebih dahulu", title: `validasi`, callBack: () => null });
        openLoading()
        var url = `/rpc/reprint`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "rpc",
                data: {
                    "nomor_rekening": nomor_rekening,
                    "start_date": moment(from).format("MM/DD/YYYY"),
                    "end_date": moment(to).format("MM/DD/YYYY"),
                }
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setDataCetakUlang(data)
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        // await wait(3000)
        closeLoading()
    }

    // close Cetak Nama
    function closeCetakTransaksiPrint() {
        setmodalCetakTransaksiPrint(false)
    }

    // fungsi setDataForm
    function changeForm_cetak(value, key, subkey = null) {
        // value = value.toUpperCase()
        if (typeof value === "string") value = value.toUpperCase()
        if (subkey !== null) setdataCetakTransakasi(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setdataCetakTransakasi(prevState => ({ ...prevState, [key]: value }));
    }

    return (
        <cetakContext.Provider value={{
            cAction: {
                closeCetakTransaksi,
                closeCetakTransaksiPrint,
                openCetakTransakasiPrint,
                openCetakTransaksiPasbook,
                doCetakTrxPassbook,
                setDataCetakUlang,
                getTrxCetakUlang,
                changeForm_cetak
            },
            cState: {
                dataCetakTransaksi,
                dataCetakTransaksiPrint,
                modalCetakTransaksi,
                modalCetakTransaksiPrint,
                dataCetakUlang,
                fastPrint
            }
        }}>
            {children}
            <ModalCetakTransaksiPassbook />
        </cetakContext.Provider>
    )
}

export default function useCetak() { return useContext(cetakContext) }