
import React, { memo, useRef, useState } from 'react';
import { useBasePageState } from './basePageContext';
import { AppAction, AppInterfaces } from '../../../app.data';

const BodyPayment_Base = memo(({store}) => {
    const [globalState, dispatch, actions] = useBasePageState();
    const [selectVal, setSelectVal] = useState([]);
    
    const props = store;
    const appAction = useRef(null)
    // console.log(props)

    return(
        <>
            <AppAction ref={appAction} />
            <div className="row">
                {
                    globalState.choice != undefined 
                    && globalState.options != undefined 
                        && globalState
                            .options.find(v=>v.value === parseInt(globalState.choice))?.forms
                            .map((form, key)=>{
                                if(form.type === "select")
                                    return(
                                        <div key={key} className="form-group form-group-sm col-md-6">
                                            <label>{form.label}</label>
                                        <select aria-readonly={form.readOnly} disabled={globalState.isSukses || globalState.isProses} className="form-control" value={form.value} 
                                                // onChange={(e)=>form.onChange(e.target.value)}
                                                onChange={(e)=>form.onChange(e.target.value)}>
                                                <option value="">Select {form.label}</option>
                                                {
                                                   form.options?.map((opt, i)=> <option key={i} value={opt[form.valueKey]}> {opt[form.displayKey]} </option>)
                                                }
                                            </select>
                                        </div>
                                    )
                                if(form.type==="text")
                                    return(
                                        <div className="form-group col-md-6">
                                            <label>{form.label} :</label>
                                            <input disabled={globalState.isSukses || globalState.isProses} placeholder={form.label} value={form.value} 
                                                className="form-control"
                                                onChange={(e)=> form.onChange(e.target.value)}                                                
                                                onKeyDown={(e)=>e.key==="Enter" && form.onEnter?.(e.target.value)} 
                                            />
                                        </div>
                                    )
                            })
                }
                {/* <div className="col"> */}
                    
                {/* </div> */}
            </div>
            {
                globalState.optionSelected != undefined && globalState.optionSelected?.selectNominal && (
                    <div className="row">
                        { props.nominalList.length > 0 && <div className="col-12">
                            Select Nominal
                        </div>}
                            {
                                props.nominalList.map((nom, i)=>(         
                                    <div key={i} className="col-md-3">
                                        <a onClick={()=> {
                                                // console.log(nom)
                                                    if(!(globalState.isSukses || globalState.isProses)){
                                                        globalState.optionSelected !=null && globalState.optionSelected.onSelectNominal != undefined && globalState.optionSelected.onSelectNominal({...(props.nominalList.find(v => v.id === nom.id))})
                                                    }
                                                }} className="link p-2" style={{textDecoration:"none"}} href="#!">
                                            <div style={{
                                                width:"100%",
                                                border:`solid ${ nom.id === (props.nominalSelected != null ? props.nominalSelected.id : "") ? "2px #FF6F00" :  "1px #ced4d9"}`,
                                                minHeight:50,
                                                borderRadius:5,
                                                padding:10
                                            }}>
                                                <span style={{color:"gray"}}>{nom.value.toLocaleString()}</span> <br/>
                                                <span style={{color:"#FF6F00"}}>Rp. {nom.price.toLocaleString()}</span>
                                            </div>
                                        </a>
                                    </div>  
                                ))
                            }
                    </div>
                )
            }
        </>
    )
})

//************************** Context Connector **************************
// const BodyPayment = ContextConnector(POSAppContext, 
//     (vars, actions) => ({
//         idPelanggan: vars.idPelanggan,
//         setOpenPembayaranCard: (payCard) => actions.sendAction("setOpenPembayaranCard", {payCard}),
//         nominalList: vars.nominalList,
//         nominalSelected: vars.nominalSelected,
//         onClickHandler: (type, sub_type, params) => actions.sendAction('onClickHandler', {type, sub_type, params}),
//         setNominalList: (nominalList) => actions.sendAction('setNominalList', {nominalList}),
//         setNominalSelected: (nominalSelected) => actions.sendAction('setNominalSelected', {nominalSelected})
//     }),
//     ["onclickHandler", "setNominalList", "setNominalSelected"]
// )(BodyPayment_Base);

const BodyPayment = AppInterfaces.appLoad(BodyPayment_Base)

export default BodyPayment;