import { createContext, useState, useContext, useEffect } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { useModalAlert } from "../../../../controls/modalAlert";
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from "../../../../../hooks/keycloak.module";

export const notifContext = createContext({
    nfAction: {},
    nfState: {}
});

export function NotifProvider({ children, otorModeP = false }) {
    const { modalAction, modalState } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [dataList, setDataList] = useState([])
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [isLoading, setIsLoading] = useState(false)
    // const [loadingDetil, setLoadingDetil] = useState(false)
    const { doGet, doPost } = useFetch()
    const { keycloak } = useKeycloak()
    // const [ otorDesc, setOtorDesc ] = useState("");
    // const [ dataOtor, setDataOtor] = useState({})
    // const [ modalDetil, setModalDetil ] = useState({
    //     show : false,
    //     mode : 0
    // })
    // const [otorMode, setOtorMode] = useState(false)
    // const [ formEditable, setformEditable ] = useState(false)
    // const [ formData, setFormData] = useState({
    //     "id" : "",
    //     "no_rek" : "",
    //     "nama_sub" : "",
    //     "no_hp":"",
    //     "flag":"",
    //     "min_tran_db":0,
    //     "min_tran_cr":0,
    //     "active":"F",
    //     "cif":""
    // });

    // const location = useLocation()
    // const { keycloak } = useKeycloak()
    const [filter, setFilter] = useState({
        no_rek: ""
    });
    // const [ filterTRDB, setfilterTRDB] = useState(false)
    // const [ filterTRCR, setfilterTRCR] = useState(false)

    function resetFilter() {
        setFilter({
            no_rek: ""
        });
        resetPagination();
    }

    // function CekDb() {

    //     setfilterTRDB(true)  
    //     setfilterTRCR(false)  
    // }

    // function CekCr() {

    //     setfilterTRDB(false)  
    //     setfilterTRCR(true)  
    // }

    // function CekBOTH() {

    //     setfilterTRDB(true)  
    //     setfilterTRCR(true)  
    // }

    // function resetCEK() {

    //     setfilterTRCR(false)
    //     setfilterTRDB(false)
    // }

    // function resetFormDBCR(){   
    //     setFormData( prevState => 
    //         ({
    //         ...prevState,
    //         "min_tran_db":0,
    //         "min_tran_cr":0,
    //     }));
    // }

    useEffect(function () {
        getDataList();
    }, [pagination])

    // reset pagination
    function resetPagination() {
        setPagination({ page: 1, show: 15 })
    }

    // getData rekening list
    async function getDataList() {
        setIsLoading(true)
        var url = `/smsnotif/get_all_sms_notif`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { ...filter, page: pagination.show, show: pagination.page },
                service: "acc"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    // console.log(data)
                    setDataList(data.list)
                }
                // else setDataAlert({ show: true, body: remark, titile: "info", header:"info", callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    useEffect(function () {
        if (!otorModeP) getDataList();
    }, [pagination])

    // next pagination
    function nextPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    function setStatus(status_aktif, id_notif, no_rek) {
        var url = `/smsnotif/ubah_status_aktif_sms_notif`;
        setDataConfirm({
            show: true,
            body: `SMS Notif nomor rekening ${no_rek} akan di${status_aktif === "T" ? "Aktifkan" : "Non-Aktifkan"}. Lanjutkan`,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", 
                    onClick: async () => {
                    openLoading()
                    try {
                        const { statusCode, status, remark, statusText, data = {} } = await doPost({
                            url: url,
                            method: "PUT",
                            service: "acc",
                            data: {
                                status_aktif,
                                id_notif
                            }
                        });
            
                        if (statusCode === 200) {
                            if (status === "00") {
                                setDataAlert({ show: true, body: remark, title: ``, header: "Success", callBack: () => null });
                            }
                            else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
                        } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Info", callBack: () => null });
                    } catch (error) {
                        setDataAlert({ show: true, body: error.toString(), title: '', header: "Error", callBack: () => null });
                    }
                    // await wait(3000)
                    closeLoading()
                }, className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
       
    }

    // function closeModalDetil() {
    //     setModalDetil({
    //         show: false,
    //         mode:0
    //     });
    // }

    // async function openModalDetil(mode, data, callBackSuccess = () => null) {
    //     if(mode === 0) 
    //     {
    //         setformEditable(false)
    //         const {
    //             id = "",
    //             no_rek = "",
    //             nama_sub  = "",
    //             no_hp = "",
    //             flag = "",
    //             min_tran_db = 0,
    //             min_tran_cr = 0,
    //             active = "F",
    //         } = data;
    //         setFormData(form=>({    
    //             id ,
    //             no_rek ,
    //             nama_sub  ,
    //             no_hp ,
    //             flag ,
    //             min_tran_db ,
    //             min_tran_cr ,
    //             active ,
    //         }));
    //     }
    //     else if(mode === 1){
    //         setOtorMode(true);
    //         setDataOtor({
    //             ...data, 
    //             callBackSuccess
    //         })
    //         console.log(dataOtor)
    //         getDetail(data.id)
    //     }else if(mode === 2 ){
    //         setformEditable(true)

    //         const {
    //             id = "",
    //             no_rek = "",
    //             nama_sub  = "",
    //             no_hp = "",
    //             flag = "",
    //             min_tran_db = 0,
    //             min_tran_cr = 0,
    //             active = "F",
    //         } = data;
    //         setFormData(form=>({    
    //             id ,
    //             no_rek ,
    //             nama_sub  ,
    //             no_hp ,
    //             flag ,
    //             min_tran_db ,
    //             min_tran_cr ,
    //             active ,
    //         }));
    //     }


    //     // console.log(data)
    //     setModalDetil({
    //         show: true,
    //         mode
    //     });
    // }

    // getDetail
    //  async function getDetail(id) {
    //     setLoadingDetil(true)
    //     var url = `/smsnotif/get_sms_notif_by_id?id=${id}`;
    //     try {
    //         const { statusCode, status, remark, statusText, data = {} } = await doGet({
    //             url: url,
    //             // param:id,
    //             service: "acc"
    //         });

    //         if (statusCode === 200) {
    //             if (status === "00") {
    //               setFormData(data)
    //             }
    //             else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
    //         } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    //     } catch (error) {
    //         setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    //     }
    //     await wait(3000)
    //     setLoadingDetil(false)
    // }

    // response otorisasi
    // function responseOtor(status) {
    //     let body = `Apakah anda yakin akan ${status} otorisasi ini ?`;
    //     openAlertCustom({
    //         show: true,
    //         body,
    //         witTextArea: status === "REJECTED",
    //         header: "Confirmation",
    //         title: "",
    //         closeButton: false,
    //         footerButton: [
    //             { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
    //             { name: "Tidak", onClick: closeConfirmCustom, className: "btn btn-sm btn-secondary" }
    //         ]
    //     }, "otorisasi")
    // }

    // fetch API response otorisasi
    //   async function fetchOtorisasi(statusApprove) {
    //     const userInfo = await keycloak.loadUserInfo();
    //     openLoading()
    //     closeConfirm();
    //     let keterangan = otorDesc;
    //     if (keterangan === "" || keterangan === null) {
    //         if (statusApprove === "APPROVED") keterangan = "Approve";
    //         else keterangan = "Rejected"
    //     }
    //     // if (location.state !== null && location.state !== undefined) {
    //         try {
    //             const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
    //                 url:  `/otorisasi/${dataOtor.kode_entry}/${dataOtor.dbId}/${statusApprove}?keterangan=${keterangan}`,
    //                 method: "PUT",
    //                 service: "acc",
    //                 data: { keterangan: otorDesc },
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Accept: 'application/json',
    //                     "x-user-id": userInfo.preferred_username
    //                 }
    //             });
    //             if (statusCode === 200) {
    //                 if (status === "00") setDataAlert({
    //                     show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
    //                         // resetForm();
    //                         closeLoading();
    //                         closeAlert();
    //                         closeConfirmCustom();
    //                         closeConfirm();
    //                         dataOtor.callBackSuccess()
    //                         // history.goBack()s
    //                     }
    //                 });
    //             } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
    //         } catch (error) {
    //             setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
    //         }
    //     // } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
    //     closeLoading()
    //     // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    // }

    // function changeForm(value, key, subkey = null) {
    //     // value = value.toUpperCase()
    //     if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
    //     else setFormData(prevState => ({ ...prevState, [key]: value }));
    // }

    // function confirmUbah() {
    //     // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
    //     let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
    //     setDataConfirm({
    //         show: true,
    //         body,
    //         witTextArea: false,
    //         header: "Konfirmasi",
    //         title: "",
    //         closeButton: false,
    //         footerButton: [
    //             { name: "Ya", onClick: () => doEdit(), className: "btn btn-sm btn-success" },
    //             { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
    //         ]
    //     })
    // }

    // async function doEdit() {

    //     closeConfirm();
    //         // console.log(formData);
    //         const userInfo = await keycloak.loadUserInfo();
    //         const {
    //             id ,
    //             no_rek ,
    //             nama_sub  ,
    //             no_hp ,
    //             flag ,
    //             min_tran_db ,
    //             min_tran_cr ,
    //             active ,
    //         } = formData;
    //         let body = {
    //             id ,
    //             no_rek ,
    //             nama_sub  ,
    //             no_hp ,
    //             flag ,
    //             min_tran_db ,
    //             min_tran_cr ,
    //             active ,
    //         }
    //         let url = `/smsnotif/update_sms_notif_by_id?id=${formData.id}`;
    //         openLoading()
    //         try {
    //             const resp = await doPost({
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Accept: 'application/json',
    //                     // "x-user-id": userInfo.preferred_username
    //                 },
    //                 method:"PUT",
    //                 data: formData,
    //                 url, // ganti sesuai api
    //                 service: "acc" //ganti sesuai service yang dipakai di api
    //             });
    //             const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
    //             if (statusCode === 200) {
    //                 if (status === "00") setDataAlert({
    //                     show: true, body: "Ubah Data Registrasi Form Notifikasi SMS Berhasil. dibutuhkan otorisasi untuk melanjutkan", title: ``, header: "Success", callBack: () => {
    //                         // resetForm();
    //                         closeConfirm();
    //                         closeModalDetil();
    //                     }
    //                 });
    //                 else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
    //             } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
    //         } catch (error) {
    //             setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
    //         }
    //         closeLoading()
    //     }


    //Post Auditrail
    async function auditTrail() {
        const userInfo = await keycloak.loadUserInfo();
        // console.log(userInfo)
        var url = `/useraudit/user-audit-trail`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: {
                    user_id: userInfo.preferred_username,
                    app_id: "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Get Data List",
                    event_description: "Tabungan & Giro/Data Registrasi Notifikasi",
                    info1: "-",
                    info2: "-"
                },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

    }
    return <notifContext.Provider value={{
        nfAction: {
            nextPage,
            prevPage,
            changeShow,
            resetPagination,
            getDataList,
            // openModalDetil,
            // closeModalDetil,
            // setOtorDesc,
            // responseOtor,
            // changeForm,
            // confirmUbah,
            // CekDb, CekCr, CekBOTH, 
            // resetCEK,resetFormDBCR,
            resetFilter,
            setFilter,
            auditTrail,
            setStatus
        },
        nfState: {
            dataList,
            isLoading,
            // modalDetil,
            // formData,
            // otorMode,
            // otorDesc,
            // formEditable,
            // filterTRDB,
            // filterTRCR,
            filter
        }
    }} >{children}</notifContext.Provider>
}

export default function useDataNotif() {
    return useContext(notifContext)
}