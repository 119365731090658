
import React, { memo } from 'react';
import BasePageMenu from '../../controls/basePageMenu/basePageMenu';
import bannerWesel from '../../../img/Banner-wesel.png';
import weselPenerimaan from '../../../img/weselPenerimaan.png';
import weselPembayaran from '../../../img/weselPembayaran.png';


const menuWeselPage = memo((props) => {
    return (
        <BasePageMenu 
            icon={bannerWesel}
            title="Layanan Pengiriman"
            layanan={
                [
                    {title: "Penerimaan", link: "/wesel/penerimaan", icon: weselPenerimaan, linkBack: "/wesel", lastTitle: "Wesel"},
                    {title: "Pembayaran", link: "/wesel/pembayaran", icon: weselPembayaran, linkBack: "/wesel", lastTitle: "Wesel"},
                ]
            } />
    )
});

export default menuWeselPage;