import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useDropdowns from '../../../../../hooks/useDropdowns';
import useFetch from '../../../../../hooks/useFetch';
// import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import useAlamat from '../../../../../hooks/useAlamat';
import { useLocalStorage } from '../../../../../module';
import useKeycloak from '../../../../../hooks/keycloak.module';


const fbContext = React.createContext(null)

export const FbProvider = ({ children }) => {

    const [podDate, setPodDate] = useLocalStorage("POD_DATE")
    const { doGet, doGetMultiple, doPost, wait } = useFetch();
    const paramUrl = useParams();
    const [step, setStep] = useState(0);
    const [formEditable, setFormEditable] = useState(true);
    const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false })
    const [locationState, setLocationState] = useState({});
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState({ getData: false, loadingPost: { show: false, msg: "Please Wait ..." } });
    const [otorDesc, setOtorDesc] = useState("")
    const dataDropDown = useDropdowns();
    const { keycloak } = useKeycloak()
    const [dataMarketing, setDataMarketing] = useState([])
    const [formData, setFormData] = useState({
        "emailAddress": "",
        "id": "",
        "notes": "",
        "groupName": "",
        "assignedBranchKey": "",
        "nasabah_prioritas" : "",
        "_identitasNasabah": {
            "nasabahBadanUsaha": "",
            "nasabahAssetPerusahaan": 0,
            "nasabahNomorNpwp": "",
            "nasabahNamaPerusahaan": "",
            "nasabahOmsetPerusahaan": 0,
            "nasabahBidangUsaha": "",
            "nasabahKodeLokasi": "",
            "nasabahKeteranganLokasi": "",
            "nasabahLokasi": "testing",
            "nasabahNomorSiupp": "",
            "nasabahTanggalPengesahanSiupp": "",
            "nasabahNomorSkdp": "",
            "nasabahNomorAktePendirian": "",
            "nasabahTanggalAktePendirian": "",
            "nasabahTempatAktePendirian": "",
            "nasabahSektorEkonomi": "",
            "nasabahStatusKeterkaitan": "",
            "nasabahBankNonBank": "F",
            "nasabahNomorAktePerubahan": "",
            "nasabahTanggalAktePerubahan": "",
            "nasabahTanggalPengesahanSkdp": "",
            "nasabahTanggalKadaluarsaSkdp": "",
            "nasabahNomorSkMenkumham": "",
            "nasabahTanggalSkMenkumham": "",
            "nasabahNomorTDP": "",
            "nasabahPemilik": "",
            "nasabahStrukturModal": "",
            "nasabahAlamat": "",
            "nasabahRw": "",
            "nasabahRt": "",
            "nasabahProvinsi": "",
            "nasabahKotaKabupaten": "",
            "nasabahKecamatan": "",
            "nasabahKelurahan": "",
            "nasabahKodePos": "",
            "nasabahTeleponKantorKodeArea1": "",
            "nasabahTeleponKantorKodeArea2": "",
            "nasabahTeleponKantorKodeArea3": "",
            "nasabahTeleponKantorNomor1": "",
            "nasabahTeleponKantorNomor2": "",
            "nasabahTeleponKantorNomor3": "",
            "nasabahFaxKodeArea": "",
            "nasabahFaxNomor": "",
            "nasabahKodeMarketing": "",
            "nasabahNamaMarketing": "",
            "nasabahNomorInduk": "",
            "nasabahNamaInduk": "",
            "nasabahNasabahInduk": "induk tes",
            "nasabahPicNasabah": "PIC",
            "nasabahBranchCode": "002",
            // "nasabahStatusKeterkaitan": "",
            "nasabahKepemilikanModal": "kepemilikan",
            "nasabahJenisPenduduk": "TES JENIS",
            "nasabahWilayahPemberiReferensi": "TES PEMBERI",
            "nasabahTempatPembuatanSiupp": "siup tes",
            "nasabahTanggalKadaluarsaSiupp": "2021-07-21",
            "nasabahTempatPembuatanSkdp": "skdp tempat tes",
            "nasabahTempatAktePerubahan": "tempat tes",
            "nasabahTanggalExpAktePerubahan": "2021-07-21",
            "nasabahTempatSkMenkumham": "tempat dumy",
            "nasabahTanggalExpSkMenkumham": "2021-07-21",
            "nasabahKegiatanUsaha": "testingdumy",
            "CPName": "",
            "CPNomorHP": "",
        },
        "_pejabatKorporat": [],
        "_cdd": {
            "cddPenghasilanKotorPerTahun": "",
            "cddGolonganNasabah": "",
            "cddSumberDana": "",
            "cddSumberPenghasilan": "",
            "cddTujuanPenggunaanDana": "",
            "cddStatusKenaPajak": "0",
            "cddKeteranganBebasPajak": "KENA PAJAK",
            "cddNomorSurat": "",
            "cddTanggalTerbit": "",
            "cddTanggalBerakhir": "",
            "cddNominalStorTunai": "",
            "cddNominalStorTunaiFre": "",
            "cddNominalStorNonTunai": "",
            "cddNominalStorNonTunaiFre": "",
            "cddNominalTarikTunai": "",
            "cddNominalTarikTunaiFre": "",
            "cddNominalTarikNonTunai": "",
            "cddNominalTarikNonTunaiFre": "",
            "cddStatusResiko": "L",
            "cddIdentitasNasabah": "L", 
            "cddLokasiUsaha": "L", 
            "cddProfilNasabah": "L", 
            "cddJumlahTransaksi": "L", 
            "cddKegiatanUsaha": "L", 
            "cddStrukturKepemilikan": "L", 
            "cddInformasiLainnya": "L" ,
            "cddFaktorResiko": "tes faktor resiko",
            "cddTujuanBukaRekening": "2 -INVESTASI",
        },
        "_relasiNasabah": [],
        // "_beneficiaryOwner": {
        //     "benefNamaPerusahaan": "",
        //     "benefNomorIzinUsaha": "",
        //     "benefBidangUsaha": "",
        //     "benefBentukUsaha": "",
        //     "benefSumberDana": "",
        //     "benefTujuanPenggunaanDana": "",
        //     "benefKeterangan": "",
        //     "benefAlamat": "",
        //     "benefRw": "",
        //     "benefRt": "",
        //     "benefProvinsi": "",
        //     "benefKecamatan": "",
        //     "benefKelurahan": "",
        //     "benefKotaKabupaten": "",
        //     "benefnasabahKodePos": "",
        // },
        "_beneficiaryIndividu": {
            "benefNama": "",//Nama Lengkap
            "benefGender": "",
            "benefBirthPlace": "",//Tempat/Tanggal Lahir
            "benefBirthDate": "",//Tempat/Tanggal Lahir
            "benefAgama": "",//Agaman
            "benefStatusPerkawinan": "",//Status Perkawinan
            "benefHomePhone": "", //Telepon
            "benefMobilePhone": "",//Handphone
            "benefAlamat": "",
            "benefRw": "",
            "benefRt": "",
            "benefProvinsi": "",
            "benefKecamatan": "",
            "benefKelurahan": "",
            "benefKotaKabupaten": "",
            "benefNasabahKodePos": "",
            "benefAlamatDom": "",
            "benefRwDom": "",
            "benefRtDom": "",
            "benefProvinsiDom": "",
            "benefKecamatanDom": "",
            "benefKelurahanDom": "",
            "benefKotaKabupatenDom": "",
            "benefKodePosDom": ""

        },
        "_pihakLawan": {
            "pihakLawanLembagaPemeringkatan": "MIS - MOODY`S INVESTOR SERVICE",
            "pihakLawanRating": "3 - AA2",
            "pihakLawanTanggalPemeringkatan": "2021-07-16",
            "pihakLawanIdGrup": "100002"
        },
        "_alamatAlternatif": {
            "alternatifKodePos": "123",
            "alternatifProvinsi": "JAWA TENGAH",
            "alternatifKotaKabupaten": "KAB. WONOGIRI",
            "alternatifRtRw": "22/33",
            "alternatifKecamatan": "WONOGIRI",
            "alternatifAlamat": "JL. ALTERNATIF",
            "alternatifTelepon": "0838484843442",
            "alternatifKelurahan": "WONOHARJO"
        }
    });

    // alamat 1
    const [districts, setDistricts] = useState([])
    const [villages, setvillages] = useState([])
    const [cities, setCities] = useState([])
    const [postalCodes, setPostalCodes] = useState([])
    const [citiesBenfDom, setCitiesBenfDom] = useState([])
    const [citiesBenf, setCitiesBenf] = useState([])

    const [kepemilikan, setKepemilikan ] = useState(0.00)

    useEffect(function(){
        // console.log(formData._pejabatKorporat)
        let s = (formData._pejabatKorporat || []).filter(({action}) => action !== "D" ).reduce( (a,b)  => parseFloat(a) + parseFloat(b.pejabatPersentaseKepemilikan ) , 0)
        setKepemilikan(parseFloat(s).toFixed(2))
    },[formData])

    const {
        provinces,
        getCities,
        getVillages,
        getPostalsCode,
        getDistrics
    } = useAlamat();

    useEffect(function () {
        setLocationState(location.state);
    }, [location.state])

    // function stepper
    function clickStep(id) { setStep(id); }
    function nextStep() {
        const nasabahNomorNpwp_length = formData?._identitasNasabah?.nasabahNomorNpwp?.length
        if (nasabahNomorNpwp_length !== 15) {
            return setDataAlert({ show: true, body: "Nomor Npwp harus 15 digit", title: `Validasi Nomor Npwp`, header: "Warning", callBack: () => null });
        }

        let dataRequired = [
            "nasabahBadanUsaha", "nasabahAssetPerusahaan", "nasabahNomorNpwp", "groupName",
            "nasabahOmsetPerusahaan", "nasabahTempatAktePendirian",
            // "nasabahBidangUsaha", 
            "nasabahKodeLokasi", "nasabahKodeMarketing",
            "nasabahNomorAktePendirian", "nasabahTanggalAktePendirian",
            "nasabahSektorEkonomi", "nasabahAlamat", "nasabahRw", "nasabahRt", "nasabahProvinsi", "nasabahKotaKabupaten",
            "nasabahKecamatan", "nasabahKelurahan","nasabahBankNonBank","nasabahStatusKeterkaitan"
        ];
        if (step === 0) {
            dataRequired = [
                "nasabahBadanUsaha", "nasabahAssetPerusahaan", "nasabahNomorNpwp", "groupName",
                "nasabahOmsetPerusahaan", "nasabahTempatAktePendirian",
                // "nasabahBidangUsaha", 
                "nasabahKodeLokasi","nasabahKodeMarketing",
                "nasabahNomorAktePendirian", "nasabahTanggalAktePendirian",
                "nasabahSektorEkonomi", "nasabahAlamat", "nasabahRw", "nasabahRt", "nasabahProvinsi", "nasabahKotaKabupaten",
                "nasabahKecamatan", "nasabahKelurahan","nasabahBankNonBank","nasabahStatusKeterkaitan"
            ]
        }
        if (step === 1) {
            dataRequired = [
                "_pejabatKorporat"
            ]

            if( parseFloat(kepemilikan) !== 100.00) {
                return setDataAlert({ show: true, body: "Data total kepemilikan perusahaan harus 100% ", title: `Status kepemilikan masih ${kepemilikan}%`, header: "Warning", callBack: () => null });
            }
        }
        if (step === 2) {
            dataRequired = [
                "cddPenghasilanKotorPerTahun", "cddGolonganNasabah", "cddSumberDana", "cddSumberPenghasilan",
                "cddTujuanPenggunaanDana", "cddStatusKenaPajak",
                // "cddKeteranganBebasPajak", 
                "cddNominalStorTunai",
                "cddNominalStorTunaiFre", "cddNominalStorNonTunai", "cddNominalStorNonTunaiFre", "cddNominalTarikTunai",
                "cddNominalTarikTunaiFre", "cddNominalTarikNonTunai", "cddNominalTarikNonTunaiFre", "cddStatusResiko",
                
                ...(
                    parseInt(formData?._cdd?.cddStatusKenaPajak) === 0 ? [] : 
                    parseInt(formData?._cdd?.cddStatusKenaPajak) === 1 ? ["cddKeteranganBebasPajak","cddNomorSurat","cddTanggalTerbit", "cddTanggalBerakhir"] : 
                    parseInt(formData?._cdd?.cddStatusKenaPajak) === 2 ? ["cddKeteranganBebasPajak"] : []
                ),
            ]
            // // console.log(dataRequired, formData?._cdd?.cddStatusKenaPajak)
        }
        if (paramUrl.mode === "registration" || paramUrl.mode === "edit") {
            if (validasi(dataRequired)) {
                return setDataAlert({ show: true, body: "Periksa kembali form pendaftaran, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
            }
        }
        if (paramUrl.mode === "registration") {
            if (isValid == false) {
                return setDataAlert({ show: true, body: "Nama Perusahaan, Nomor NPWP, Tempat dan Tanggal Beridiri Belum Tervalidasi. Silahkan isi data lalu klik Button Cek Data Nasabah", title: `Data Belum Valid`, header: "Warning", callBack: () => null });
            }
        }
        step !== 4 && setStep(prevStep => prevStep + 1);
    }
    function prevState() { step > 0 && setStep(prevStep => prevStep - 1); }

    // detect mode form
    useEffect(() => {
        setLoading(prevState => ({ ...prevState, loadingPost: { msg: "Getting data. Please Wait ...", show: true } }));
        // if (paramUrl.mode !== "registration") {
        if (paramUrl.mode === "otorisasi" || paramUrl.mode === "edit" || paramUrl.mode === "detil") {
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            if (provinces.length > 0) {
                getDataDetail(location.state?.dbId);
            }
        }else closeLoading()
        // } 
        setFormEditable(paramUrl.mode === "registration" || paramUrl.mode === "edit");
    }, [paramUrl.mode, provinces])

    // useEffect(function(){
    //     // console.log(formData)
    // },[formData])
    // function getData Perorangan
    async function getDataDetail(dbId) {
        // // // console.log(formData)
        let url = `/nasabah-badan-usaha/${dbId}`;
        if (paramUrl.mode === "otorisasi") url = `/nasabah-badan-usaha/local/${dbId}`;

        setLoading(prevState => ({ ...prevState, loadingPost: { msg: "Getting data. Please Wait ...", show: true } }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    // // // console.log(data)
                    const { nasabahBadanUsaha, PejabatKorporat, RelasiNasabah } = data;
                    let NewPejabatKorporat = []
                    let stateBase = {
                        // "emailAddress": nasabahBadanUsaha.emailAddress,
                        // "notes": nasabahBadanUsaha.notes,
                        // "groupName": nasabahBadanUsaha.groupName,
                        // "assignedBranchKey": nasabahBadanUsaha.assignedBranchKey,
                        // "created_at": nasabahBadanUsaha.created_at,
                        // "_informasiLain" :{
                        //     "marketingReferensi": nasabahBadanUsaha.nasabahKodeMarketing
                        // },
                        // "_identitasNasabah" : {
                        //     "nasabahBadanUsaha": nasabahBadanUsaha.nasabahBadanUsaha
                        // },
                    }
                    if (paramUrl.mode === "otorisasi") {
                        // // // console.log(nasabahBadanUsaha)
                        setFormData(prevState => ({
                            ...prevState,
                            "emailAddress": nasabahBadanUsaha.nasabahEmailAddress,
                            "notes": nasabahBadanUsaha.notes,
                            "groupName": nasabahBadanUsaha.groupName ?? "",
                            "nasabah_prioritas": nasabahBadanUsaha.nasabah_prioritas,
                            // "assignedBranchKey": nasabahBadanUsaha.assignedBranchKey,
                            "_identitasNasabah": {
                                "nasabahAssetPerusahaan": nasabahBadanUsaha.nasabahAssetPerusahaan,
                                "nasabahOmsetPerusahaan": nasabahBadanUsaha.nasabahOmsetPerusahaan,
                                "nasabahKodeLokasi": nasabahBadanUsaha.nasabahKodeLokasi,
                                "nasabahKeteranganLokasi": nasabahBadanUsaha.nasabahKeteranganLokasi,
                                "nasabahNamaMarketing": nasabahBadanUsaha.nasabahNamaMarketing,
                                "nasabahNomorInduk": nasabahBadanUsaha.nasabahNomorInduk,
                                "nasabahBadanUsaha": nasabahBadanUsaha.nasabahBadanUsaha,
                                "nasabahNomorNpwp": nasabahBadanUsaha.nasabahNomorNpwp,
                                "nasabahNamaPerusahaan": nasabahBadanUsaha.nasabahNamaPerusahaan,
                                "nasabahBidangUsaha": nasabahBadanUsaha.nasabahBidangUsaha,
                                "nasabahLokasi": nasabahBadanUsaha.nasabahLokasi,
                                "nasabahNomorSiupp": nasabahBadanUsaha.nasabahNomorSiupp,
                                "nasabahTanggalPengesahanSiupp": nasabahBadanUsaha.nasabahTanggalPengesahanSiupp,
                                "nasabahNomorSkdp": nasabahBadanUsaha.nasabahNomorSkdp,
                                "nasabahNomorAktePendirian": nasabahBadanUsaha.nasabahNomorAktePendirian,
                                "nasabahTanggalAktePendirian": nasabahBadanUsaha.nasabahTanggalAktePendirian !== undefined &&
                                    nasabahBadanUsaha.nasabahTanggalAktePendirian !== null &&
                                    nasabahBadanUsaha.nasabahTanggalAktePendirian !== "" ? moment(nasabahBadanUsaha.nasabahTanggalAktePendirian).format("YYYY-MM-DD") : "",
                                "nasabahTempatAktePendirian": nasabahBadanUsaha.nasabahTempatAktePendirian,
                                "nasabahSektorEkonomi": nasabahBadanUsaha.nasabahSektorEkonomi,
                                "nasabahStatusKeterkaitan": nasabahBadanUsaha.nasabahStatusKeterkaitan,
                                "nasabahBankNonBank": nasabahBadanUsaha.nasabahBankNonBank,
                                "nasabahNomorAktePerubahan": nasabahBadanUsaha.nasabahNomorAktePerubahan,
                                "nasabahTanggalAktePerubahan": nasabahBadanUsaha.nasabahTanggalAktePerubahan,
                                "nasabahTanggalPengesahanSkdp": nasabahBadanUsaha.nasabahTanggalPengesahanSkdp,
                                "nasabahTanggalKadaluarsaSkdp": nasabahBadanUsaha.nasabahTanggalKadaluarsaSkdp,
                                "nasabahNomorSkMenkumham": nasabahBadanUsaha.nasabahNomorSkMenkumham,
                                "nasabahTanggalSkMenkumham": nasabahBadanUsaha.nasabahTanggalSkMenkumham,
                                "nasabahNomorTDP": nasabahBadanUsaha.nasabahNomorTDP,
                                "nasabahPemilik": nasabahBadanUsaha.nasabahPemilik,
                                "nasabahStrukturModal": nasabahBadanUsaha.nasabahStrukturModal,
                                "nasabahAlamat": nasabahBadanUsaha.nasabahAlamat,
                                "nasabahRw": nasabahBadanUsaha.nasabahRw,
                                "nasabahRt": nasabahBadanUsaha.nasabahRt,
                                "nasabahProvinsi": nasabahBadanUsaha.nasabahProvinsi,
                                "nasabahKotaKabupaten": nasabahBadanUsaha.nasabahKotaKabupaten,
                                "nasabahKecamatan": nasabahBadanUsaha.nasabahKecamatan,
                                "nasabahKelurahan": nasabahBadanUsaha.nasabahKelurahan,
                                "nasabahKodePos": nasabahBadanUsaha.nasabahKodePos,
                                "nasabahTeleponKantorKodeArea1": nasabahBadanUsaha.nasabahTeleponKantorKodeArea1,
                                "nasabahTeleponKantorKodeArea2": nasabahBadanUsaha.nasabahTeleponKantorKodeArea2,
                                "nasabahTeleponKantorKodeArea3": nasabahBadanUsaha.nasabahTeleponKantorKodeArea3,
                                "nasabahTeleponKantorNomor1": nasabahBadanUsaha.nasabahTeleponKantorNomor1,
                                "nasabahTeleponKantorNomor2": nasabahBadanUsaha.nasabahTeleponKantorNomor2,
                                "nasabahTeleponKantorNomor3": nasabahBadanUsaha.nasabahTeleponKantorNomor3,
                                "nasabahFaxKodeArea": nasabahBadanUsaha.nasabahFaxKodeArea,
                                "nasabahFaxNomor": nasabahBadanUsaha.nasabahFaxNomor,
                                "nasabahKodeMarketing": nasabahBadanUsaha.nasabahKodeMarketing,
                                "nasabahNamaInduk": nasabahBadanUsaha.nasabahNamaInduk,
                                "nasabahNasabahInduk": nasabahBadanUsaha.nasabahNasabahInduk,
                                "nasabahPicNasabah": nasabahBadanUsaha.nasabahPicNasabah,
                                "nasabahBranchCode": nasabahBadanUsaha.nasabahBranchCode,
                                "nasabahStatusKeterkaitan": nasabahBadanUsaha.nasabahStatusKeterkaitan,
                                "nasabahKepemilikanModal": nasabahBadanUsaha.nasabahKepemilikanModal,
                                "nasabahJenisPenduduk": nasabahBadanUsaha.nasabahJenisPenduduk,
                                "nasabahWilayahPemberiReferensi": nasabahBadanUsaha.nasabahWilayahPemberiReferensi,
                                "nasabahTempatPembuatanSiupp": nasabahBadanUsaha.nasabahTempatPembuatanSiupp,
                                "nasabahTanggalKadaluarsaSiupp": nasabahBadanUsaha.nasabahTanggalKadaluarsaSiupp,
                                "nasabahTempatPembuatanSkdp": nasabahBadanUsaha.nasabahTempatPembuatanSkdp,
                                "nasabahTempatAktePerubahan": nasabahBadanUsaha.nasabahTempatAktePerubahan,
                                "nasabahTanggalExpAktePerubahan": nasabahBadanUsaha.nasabahTanggalExpAktePerubahan,
                                "nasabahTempatSkMenkumham": nasabahBadanUsaha.nasabahTempatSkMenkumham,
                                "nasabahTanggalExpSkMenkumham": nasabahBadanUsaha.nasabahTanggalExpSkMenkumham,
                                "nasabahKegiatanUsaha": nasabahBadanUsaha.nasabahKegiatanUsaha,
                                "CPName": nasabahBadanUsaha.CPName,
                                "CPNomorHP": nasabahBadanUsaha.CPNomorHP,
                            },
                            "_pejabatKorporat":  data.PejabatKorporat,
                            // [
                            //     {
                            //         "pejabatKecamatan": nasabahBadanUsaha.pejabatKecamatan,
                            //         "pejabatNomorNasabahPejabat": nasabahBadanUsaha.pejabatNomorNasabahPejabat,
                            //         "pejabatAlamat": nasabahBadanUsaha.pejabatAlamat,
                            //         "pejabatNomorNpwp": nasabahBadanUsaha.pejabatNomorNpwp,
                            //         "pejabatKeterangan": nasabahBadanUsaha.pejabatKeterangan,
                            //         "pejabatNegaraAsal": nasabahBadanUsaha.pejabatNegaraAsal,
                            //         "pejabatKotaKabupaten": nasabahBadanUsaha.pejabatKotaKabupaten,
                            //         "pejabatNomorHandphone": nasabahBadanUsaha.pejabatNomorHandphone,
                            //         "pejabatJabatan": nasabahBadanUsaha.pejabatJabatan,
                            //         "pejabatIDPejabat": nasabahBadanUsaha.pejabatIDPejabat,
                            //         "pejabatNomorIdentitas": nasabahBadanUsaha.pejabatNomorIdentitas,
                            //         "pejabatPekerjaan": nasabahBadanUsaha.pejabatPekerjaan,
                            //         "pejabatTeleponKodeArea": nasabahBadanUsaha.pejabatTeleponKodeArea,
                            //         "pejabatJenisIdentitas": nasabahBadanUsaha.pejabatJenisIdentitas,
                            //         "pejabatNama": nasabahBadanUsaha.pejabatNama,
                            //         "pejabatEmailAddress": nasabahBadanUsaha.pejabatEmailAddress,
                            //         "pejabatTeleponNomor": nasabahBadanUsaha.pejabatTeleponNomor,
                            //         "pejabatKelurahan": nasabahBadanUsaha.pejabatKelurahan,
                            //         "pejabatRw": nasabahBadanUsaha.pejabatRw,
                            //         "pejabatPersentaseKepemilikan": nasabahBadanUsaha.pejabatPersentaseKepemilikan,
                            //         "pejabatProvinsi": nasabahBadanUsaha.pejabatProvinsi,
                            //         "pejabatFaxKodeArea": nasabahBadanUsaha.pejabatFaxKodeArea,
                            //         "pejabatFaxNomor": nasabahBadanUsaha.pejabatFaxNomor,
                            //         "pejabatRt": nasabahBadanUsaha.pejabatRt,
                            //         "pejabatKewarganegaraan": nasabahBadanUsaha.pejabatKewarganegaraan,
                            //         "pejabatKodePos": nasabahBadanUsaha.pejabatKodePos,
                            //         "pejabatJenisAlamat": nasabahBadanUsaha.pejabatJenisAlamat,
                            //         "pejabatGelar": nasabahBadanUsaha.pejabatGelar,
                            //         "pejabatNegaraTerbitIdentitas": nasabahBadanUsaha.pejabatNegaraTerbitIdentitas,
                            //         "pejabatTempatTerbitIdentitas": nasabahBadanUsaha.pejabatTempatTerbitIdentitas,
                            //         "pejabatTipeKontakDomisili": nasabahBadanUsaha.pejabatTipeKontakDomisili,
                            //         "pejabatExtensionKontakPejabat": nasabahBadanUsaha.pejabatExtensionKontakPejabat,
                            //         "pejabatStatusPEP": nasabahBadanUsaha.pejabatStatusPEP,
                            //         "pejabatTipeKontak": nasabahBadanUsaha.pejabatTipeKontak,
                            //         "pejabatTempatLahir": nasabahBadanUsaha.pejabatTempatLahir,
                            //         "pejabatTglLahir": nasabahBadanUsaha.pejabatTglLahir,
                            //         "pejabatJenisKelamin": nasabahBadanUsaha.pejabatJenisKelamin,
                            //         "pejabatAgama": nasabahBadanUsaha.pejabatAgama,
                            //     }
                            // ],
                            "_cdd": {
                                "cddPenghasilanKotorPerTahun": nasabahBadanUsaha.cddPenghasilanKotorPerTahun,
                                "cddGolonganNasabah": nasabahBadanUsaha.cddGolonganNasabah,
                                "cddSumberDana": nasabahBadanUsaha.cddSumberDana,
                                "cddSumberPenghasilan": nasabahBadanUsaha.cddSumberPenghasilan,
                                "cddTujuanPenggunaanDana": nasabahBadanUsaha.cddTujuanPenggunaanDana,
                                "cddStatusKenaPajak": nasabahBadanUsaha.cddStatusKenaPajak,
                                "cddKeteranganBebasPajak": nasabahBadanUsaha.cddKeteranganBebasPajak,
                                "cddNomorSurat": nasabahBadanUsaha.cddNomorSurat,
                                "cddTanggalTerbit": nasabahBadanUsaha.cddTanggalTerbit,
                                "cddTanggalBerakhir": nasabahBadanUsaha.cddTanggalBerakhir,
                                "cddNominalStorTunai": nasabahBadanUsaha.cddNominalStorTunai ?? 0,
                                "cddNominalStorTunaiFre": nasabahBadanUsaha.cddNominalStorTunaiFre ?? 0,
                                "cddNominalStorNonTunai": nasabahBadanUsaha.cddNominalStorNonTunai ?? 0,
                                "cddNominalStorNonTunaiFre": nasabahBadanUsaha.cddNominalStorNonTunaiFre ?? 0,
                                "cddNominalTarikTunai": nasabahBadanUsaha.cddNominalTarikTunai ?? 0,
                                "cddNominalTarikTunaiFre": nasabahBadanUsaha.cddNominalTarikTunaiFre ?? 0,
                                "cddNominalTarikNonTunai": nasabahBadanUsaha.cddNominalTarikNonTunai ?? 0,
                                "cddNominalTarikNonTunai": nasabahBadanUsaha.cddNominalTarikNonTunai ?? 0,
                                "cddNominalTarikNonTunaiFre": nasabahBadanUsaha.cddNominalTarikNonTunaiFre ?? 0,
                                "cddStatusResiko": nasabahBadanUsaha.cddStatusResiko,
                                "cddIdentitasNasabah": nasabahBadanUsaha.cddIdentitasNasabah, 
                                "cddLokasiUsaha": nasabahBadanUsaha.cddLokasiUsaha, 
                                "cddProfilNasabah": nasabahBadanUsaha.cddProfilNasabah, 
                                "cddJumlahTransaksi": nasabahBadanUsaha.cddJumlahTransaksi, 
                                "cddKegiatanUsaha": nasabahBadanUsaha.cddKegiatanUsaha, 
                                "cddStrukturKepemilikan": nasabahBadanUsaha.cddStrukturKepemilikan, 
                                "cddInformasiLainnya": nasabahBadanUsaha.cddInformasiLainnya,
                                "cddFaktorResiko": nasabahBadanUsaha.cddFaktorResiko,
                                "cddTujuanBukaRekening": nasabahBadanUsaha.cddTujuanBukaRekening,
                            },
                            "_relasiNasabah": data._relasiNasabah,
                            //  [
                            //     {
                            //         "IDRelasi": nasabahBadanUsaha.IDRelasi,
                            //         "relasiHubunganDenganNasabah": nasabahBadanUsaha.relasiHubunganDenganNasabah,
                            //         "relasiJabatanPangkat": nasabahBadanUsaha.relasiJabatanPangkat,
                            //         "relasiNomorNasabah": nasabahBadanUsaha.relasiNomorNasabah,
                            //         "relasiNama": nasabahBadanUsaha.relasiNama,
                            //         "relasiAlamat": nasabahBadanUsaha.relasiAlamat,
                            //         "relasiProvinsi": nasabahBadanUsaha.relasiProvinsi,
                            //         "relasiKotaKabupaten": nasabahBadanUsaha.relasiKotaKabupaten,
                            //         "relasiKecamatan": nasabahBadanUsaha.relasiKecamatan,
                            //         "relasiKelurahan": nasabahBadanUsaha.relasiKelurahan,
                            //         "relasiRw": nasabahBadanUsaha.relasiRw,
                            //         "relasiRt": nasabahBadanUsaha.relasiRt,
                            //         "relasiKodePos": nasabahBadanUsaha.relasiKodePos,
                            //         "relasiTeleponRumahKodeArea1": nasabahBadanUsaha.relasiTeleponRumahKodeArea1, 
                            //         "relasiTeleponRumah1": nasabahBadanUsaha.relasiTeleponRumah1,  
                            //         "relasiNomorHandphone": nasabahBadanUsaha.relasiNomorHandphone,
                            //         "relasiFaxKodeArea": nasabahBadanUsaha.relasiFaxKodeArea, 
                            //         "relasiFaxNomor": nasabahBadanUsaha.relasiFaxNomor,  
                            //         "relasiEmailAddress": nasabahBadanUsaha.relasiEmailAddress,
                            //         "relasiJenisIdentitas": nasabahBadanUsaha.relasiJenisIdentitas,
                            //         "relasiNomorIdentitas": nasabahBadanUsaha.relasiNomorIdentitas,
                            //         "relasiNomorNpwp": nasabahBadanUsaha.relasiNomorNpwp,
                            //         "relasiKewarganegaraan": nasabahBadanUsaha.relasiKewarganegaraan,
                            //         "relasiNegaraAasal": nasabahBadanUsaha.relasiNegaraAasal,
                            //         "relasiPekerjaan": nasabahBadanUsaha.relasiPekerjaan, 
                            //     }
                            // ],
                            "_beneficiaryIndividu": {
                                    "benefNama": nasabahBadanUsaha?.benefNama,
                                    "benefBirthPlace": nasabahBadanUsaha?.benefBirthPlace,
                                    "benefGender": nasabahBadanUsaha?.benefGender,
                                    "benefBirthDate": nasabahBadanUsaha?.benefBirthDate,
                                    "benefAgama": nasabahBadanUsaha?.benefAgama,
                                    "benefStatusPerkawinan": nasabahBadanUsaha?.benefStatusPerkawinan,
                                    "benefHomePhone": nasabahBadanUsaha?.benefHomePhone,
                                    "benefMobilePhone": nasabahBadanUsaha?.benefMobilePhone,
                                    "benefAlamat": nasabahBadanUsaha?.benefAlamat,
                                    "benefRw": nasabahBadanUsaha?.benefRw,
                                    "benefRt": nasabahBadanUsaha?.benefRt,
                                    "benefProvinsi": nasabahBadanUsaha?.benefProvinsi,
                                    "benefKecamatan": nasabahBadanUsaha?.benefKecamatan,
                                    "benefKelurahan": nasabahBadanUsaha?.benefKelurahan,
                                    "benefKotaKabupaten": nasabahBadanUsaha?.benefKotaKabupaten,
                                    "benefNasabahKodePos": nasabahBadanUsaha?.benefNasabahKodePos,
                                    "benefAlamatDom": nasabahBadanUsaha?.benefAlamatDom,
                                    "benefRwDom": nasabahBadanUsaha?.benefRwDom,
                                    "benefRtDom": nasabahBadanUsaha?.benefRtDom,
                                    "benefProvinsiDom": nasabahBadanUsaha?.benefProvinsiDom,
                                    "benefKecamatanDom": nasabahBadanUsaha?.benefKecamatanDom,
                                    "benefKelurahanDom": nasabahBadanUsaha?.benefKelurahanDom,
                                    "benefKotaKabupatenDom": nasabahBadanUsaha?.benefKotaKabupatenDom,
                                    "benefKodePosDom": nasabahBadanUsaha?.benefKodePosDom,
                            },
                            // "_beneficiaryOwner": {
                            //     "benefNamaPerusahaan": nasabahBadanUsaha.benefNamaPerusahaan,
                            //     "benefNomorIzinUsaha": nasabahBadanUsaha.benefNomorIzinUsaha,
                            //     "benefBidangUsaha": nasabahBadanUsaha.benefBidangUsaha,
                            //     "benefBentukUsaha": nasabahBadanUsaha.benefBentukUsaha,
                            //     "benefSumberDana": nasabahBadanUsaha.benefSumberDana,
                            //     "benefTujuanPenggunaanDana": nasabahBadanUsaha.benefTujuanPenggunaanDana,
                            //     "benefKeterangan": nasabahBadanUsaha.benefKeterangan,
                            //     "benefAlamat": nasabahBadanUsaha.benefAlamat,
                            //     "benefRw": nasabahBadanUsaha.benefRw,
                            //     "benefRt": nasabahBadanUsaha.benefRt,
                            //     "benefProvinsi": nasabahBadanUsaha.benefProvinsi,
                            //     "benefKecamatan": nasabahBadanUsaha.benefKecamatan,
                            //     "benefKelurahan": nasabahBadanUsaha.benefKelurahan,
                            //     "benefKotaKabupaten": nasabahBadanUsaha.benefKotaKabupaten,
                            //     "benefnasabahKodePos": nasabahBadanUsaha.benefnasabahKodePos,
                            // },
                            "_pihakLawan": {
                                "pihakLawanLembagaPemeringkatan": nasabahBadanUsaha.pihakLawanLembagaPemeringkatan,
                                "pihakLawanRating": nasabahBadanUsaha.pihakLawanRating,
                                "pihakLawanTanggalPemeringkatan": nasabahBadanUsaha.pihakLawanTanggalPemeringkatan,
                                "pihakLawanIdGrup": nasabahBadanUsaha.pihakLawanIdGrup,
                            },
                            "_alamatAlternatif": {
                                "alternatifKodePos": nasabahBadanUsaha.alternatifKodePos,
                                "alternatifProvinsi": nasabahBadanUsaha.alternatifProvinsi,
                                "alternatifKotaKabupaten": nasabahBadanUsaha.alternatifKotaKabupaten,
                                "alternatifRtRw": nasabahBadanUsaha.alternatifRtRw,
                                "alternatifKecamatan": nasabahBadanUsaha.alternatifKecamatan,
                                "alternatifAlamat": nasabahBadanUsaha.alternatifAlamat,
                                "alternatifTelepon": nasabahBadanUsaha.alternatifTelepon,
                                "alternatifKelurahan": nasabahBadanUsaha.alternatifKelurahan,
                            }
                        }));
                        const province_code = nasabahBadanUsaha.nasabahProvinsi
                        // // // console.log(data)
                        await Promise.all([
                            getingCities(province_code),
                            getingCitiesBenef(data.nasabahBadanUsaha?.benefProvinsi),
                            getingCitiesBenfDom(data.nasabahBadanUsaha?.benefProvinsiDom),
                        ])
                    
                    } else {
                        // // // console.log(nasabahBadanUsaha)
                        setFormData(prevState => ({
                            ...prevState,
                            "emailAddress": nasabahBadanUsaha.nasabahEmailAddress,
                            "id": nasabahBadanUsaha.id,
                            "notes": nasabahBadanUsaha.notes,
                            "groupName": nasabahBadanUsaha.groupName,
                            "nasabah_prioritas": nasabahBadanUsaha.nasabah_prioritas,
                            // "assignedBranchKey": nasabahBadanUsaha.assignedBranchKey,
                            "_identitasNasabah": {
                                "nasabahBadanUsaha": nasabahBadanUsaha.nasabahBadanUsaha,
                                "nasabahAssetPerusahaan": nasabahBadanUsaha.nasabahAssetPerusahaan,
                                "nasabahNomorNpwp": nasabahBadanUsaha.nasabahNomorNpwp,
                                "nasabahNamaPerusahaan": nasabahBadanUsaha.nasabahNamaPerusahaan,
                                "nasabahOmsetPerusahaan": nasabahBadanUsaha.nasabahOmsetPerusahaan,
                                "nasabahBidangUsaha": nasabahBadanUsaha.nasabahBidangUsaha,
                                "nasabahKodeLokasi": nasabahBadanUsaha.nasabahKodeLokasi,
                                "nasabahKeteranganLokasi": nasabahBadanUsaha.nasabahKeteranganLokasi,
                                "nasabahLokasi": nasabahBadanUsaha.nasabahLokasi,
                                "nasabahNomorSiupp": nasabahBadanUsaha.nasabahNomorSiupp,
                                "nasabahTanggalPengesahanSiupp": nasabahBadanUsaha.nasabahTanggalPengesahanSiupp,
                                "nasabahNomorSkdp": nasabahBadanUsaha.nasabahNomorSkdp,
                                "nasabahNomorAktePendirian": nasabahBadanUsaha.nasabahNomorAktePendirian,
                                "nasabahTanggalAktePendirian": nasabahBadanUsaha.nasabahTanggalAktePendirian !== undefined &&
                                                                nasabahBadanUsaha.nasabahTanggalAktePendirian !== null &&
                                                                nasabahBadanUsaha.nasabahTanggalAktePendirian !== "" ? moment(nasabahBadanUsaha.nasabahTanggalAktePendirian).format("YYYY-MM-DD") : "",
                                "nasabahTempatAktePendirian": nasabahBadanUsaha.nasabahTempatAktePendirian,
                                "nasabahSektorEkonomi": nasabahBadanUsaha.nasabahSektorEkonomi,
                                "nasabahStatusKeterkaitan": nasabahBadanUsaha.nasabahStatusKeterkaitan,
                                "nasabahBankNonBank": nasabahBadanUsaha.nasabahBankNonBank,
                                "nasabahNomorAktePerubahan": nasabahBadanUsaha.nasabahNomorAktePerubahan,
                                "nasabahTanggalAktePerubahan": nasabahBadanUsaha.nasabahTanggalAktePerubahan,
                                "nasabahTanggalPengesahanSkdp": nasabahBadanUsaha.nasabahTanggalPengesahanSkdp,
                                "nasabahTanggalKadaluarsaSkdp": nasabahBadanUsaha.nasabahTanggalKadaluarsaSkdp,
                                "nasabahNomorSkMenkumham": nasabahBadanUsaha.nasabahNomorSkMenkumham,
                                "nasabahTanggalSkMenkumham": nasabahBadanUsaha.nasabahTanggalSkMenkumham,
                                "nasabahNomorTDP": nasabahBadanUsaha.nasabahNomorTDP,
                                "nasabahPemilik": nasabahBadanUsaha.nasabahPemilik,
                                "nasabahStrukturModal": nasabahBadanUsaha.nasabahStrukturModal,
                                "nasabahAlamat": nasabahBadanUsaha.nasabahAlamat,
                                "nasabahRw": nasabahBadanUsaha.nasabahRw,
                                "nasabahRt": nasabahBadanUsaha.nasabahRt,
                                "nasabahProvinsi": nasabahBadanUsaha.nasabahProvinsi,
                                "nasabahKotaKabupaten": nasabahBadanUsaha.nasabahKotaKabupaten,
                                "nasabahKecamatan": nasabahBadanUsaha.nasabahKecamatan,
                                "nasabahKelurahan": nasabahBadanUsaha.nasabahKelurahan,
                                "nasabahKodePos": nasabahBadanUsaha.nasabahKodePos,
                                "nasabahTeleponKantorKodeArea1": nasabahBadanUsaha.nasabahTeleponKantorKodeArea1,
                                "nasabahTeleponKantorKodeArea2": nasabahBadanUsaha.nasabahTeleponKantorKodeArea2,
                                "nasabahTeleponKantorKodeArea3": nasabahBadanUsaha.nasabahTeleponKantorKodeArea3,
                                "nasabahTeleponKantorNomor1": nasabahBadanUsaha.nasabahTeleponKantorNomor1,
                                "nasabahTeleponKantorNomor2": nasabahBadanUsaha.nasabahTeleponKantorNomor2,
                                "nasabahTeleponKantorNomor3": nasabahBadanUsaha.nasabahTeleponKantorNomor3,
                                "nasabahFaxKodeArea": nasabahBadanUsaha.nasabahFaxKodeArea,
                                "nasabahFaxNomor": nasabahBadanUsaha.nasabahFaxNomor,
                                "nasabahKodeMarketing": nasabahBadanUsaha.nasabahKodeMarketing,
                                "nasabahNamaMarketing": nasabahBadanUsaha.nasabahNamaMarketing,
                                "nasabahNomorInduk": nasabahBadanUsaha.nasabahNomorInduk,
                                "nasabahNamaInduk": nasabahBadanUsaha.nasabahNamaInduk,
                                "nasabahNasabahInduk": nasabahBadanUsaha.nasabahNasabahInduk,
                                "nasabahPicNasabah": nasabahBadanUsaha.nasabahPicNasabah,
                                "nasabahBranchCode": nasabahBadanUsaha.nasabahBranchCode,
                                "nasabahStatusKeterkaitan": nasabahBadanUsaha.nasabahStatusKeterkaitan,
                                "nasabahKepemilikanModal": nasabahBadanUsaha.nasabahKepemilikanModal,
                                "nasabahJenisPenduduk": nasabahBadanUsaha.nasabahJenisPenduduk,
                                "nasabahWilayahPemberiReferensi": nasabahBadanUsaha.nasabahWilayahPemberiReferensi,
                                "nasabahTempatPembuatanSiupp": nasabahBadanUsaha.nasabahTempatPembuatanSiupp,
                                "nasabahTanggalKadaluarsaSiupp": nasabahBadanUsaha.nasabahTanggalKadaluarsaSiupp,
                                "nasabahTempatPembuatanSkdp": nasabahBadanUsaha.nasabahTempatPembuatanSkdp,
                                "nasabahTempatAktePerubahan": nasabahBadanUsaha.nasabahTempatAktePerubahan,
                                "nasabahTanggalExpAktePerubahan": nasabahBadanUsaha.nasabahTanggalExpAktePerubahan,
                                "nasabahTempatSkMenkumham": nasabahBadanUsaha.nasabahTempatSkMenkumham,
                                "nasabahTanggalExpSkMenkumham": nasabahBadanUsaha.nasabahTanggalExpSkMenkumham,
                                "nasabahKegiatanUsaha": nasabahBadanUsaha.nasabahKegiatanUsaha,
                                "CPName": nasabahBadanUsaha.CPName,
                                "CPNomorHP": nasabahBadanUsaha.CPNomorHP,
                            },
                            "_pejabatKorporat":  data._pejabatKorporat,
                            //  [
                            //     {
                            //         "pejabatKecamatan": nasabahBadanUsaha.pejabatKecamatan,
                            //         "pejabatNomorNasabahPejabat": nasabahBadanUsaha.pejabatNomorNasabahPejabat,
                            //         "pejabatAlamat": nasabahBadanUsaha.pejabatAlamat,
                            //         "pejabatNomorNpwp": nasabahBadanUsaha.pejabatNomorNpwp,
                            //         "pejabatKeterangan": nasabahBadanUsaha.pejabatKeterangan,
                            //         "pejabatNegaraAsal": nasabahBadanUsaha.pejabatNegaraAsal,
                            //         "pejabatKotaKabupaten": nasabahBadanUsaha.pejabatKotaKabupaten,
                            //         "pejabatNomorHandphone": nasabahBadanUsaha.pejabatNomorHandphone,
                            //         "pejabatJabatan": nasabahBadanUsaha.pejabatJabatan,
                            //         "pejabatIDPejabat": nasabahBadanUsaha.pejabatIDPejabat,
                            //         "pejabatNomorIdentitas": nasabahBadanUsaha.pejabatNomorIdentitas,
                            //         "pejabatPekerjaan": nasabahBadanUsaha.pejabatPekerjaan,
                            //         "pejabatTeleponKodeArea": nasabahBadanUsaha.pejabatTeleponKodeArea,
                            //         "pejabatJenisIdentitas": nasabahBadanUsaha.pejabatJenisIdentitas,
                            //         "pejabatNama": nasabahBadanUsaha.pejabatNama,
                            //         "pejabatEmailAddress": nasabahBadanUsaha.pejabatEmailAddress,
                            //         "pejabatTeleponNomor": nasabahBadanUsaha.pejabatTeleponNomor,
                            //         "pejabatKelurahan": nasabahBadanUsaha.pejabatKelurahan,
                            //         "pejabatRw": nasabahBadanUsaha.pejabatRw,
                            //         "pejabatPersentaseKepemilikan": nasabahBadanUsaha.pejabatPersentaseKepemilikan,
                            //         "pejabatProvinsi": nasabahBadanUsaha.pejabatProvinsi,
                            //         "pejabatFaxKodeArea": nasabahBadanUsaha.pejabatFaxKodeArea,
                            //         "pejabatFaxNomor": nasabahBadanUsaha.pejabatFaxNomor,
                            //         "pejabatRt": nasabahBadanUsaha.pejabatRt,
                            //         "pejabatKewarganegaraan": nasabahBadanUsaha.pejabatKewarganegaraan,
                            //         "pejabatKodePos": nasabahBadanUsaha.pejabatKodePos,
                            //         "pejabatJenisAlamat": nasabahBadanUsaha.pejabatJenisAlamat,
                            //         "pejabatGelar": nasabahBadanUsaha.pejabatGelar,
                            //         "pejabatNegaraTerbitIdentitas": nasabahBadanUsaha.pejabatNegaraTerbitIdentitas,
                            //         "pejabatTempatTerbitIdentitas": nasabahBadanUsaha.pejabatTempatTerbitIdentitas,
                            //         "pejabatTipeKontakDomisili": nasabahBadanUsaha.pejabatTipeKontakDomisili,
                            //         "pejabatExtensionKontakPejabat": nasabahBadanUsaha.pejabatExtensionKontakPejabat,
                            //         "pejabatStatusPEP": nasabahBadanUsaha.pejabatStatusPEP,
                            //         "pejabatTipeKontak": nasabahBadanUsaha.pejabatTipeKontak,
                            //         "pejabatTempatLahir": nasabahBadanUsaha.pejabatTempatLahir,
                            //         "pejabatTglLahir": nasabahBadanUsaha.pejabatTglLahir,
                            //         "pejabatJenisKelamin": nasabahBadanUsaha.pejabatJenisKelamin,
                            //         "pejabatAgama": nasabahBadanUsaha.pejabatAgama,
                            //     }
                            // ],
                            "_cdd": {
                                "cddPenghasilanKotorPerTahun": nasabahBadanUsaha.cddPenghasilanKotorPerTahun,
                                "cddGolonganNasabah": nasabahBadanUsaha.cddGolonganNasabah,
                                "cddSumberDana": nasabahBadanUsaha.cddSumberDana,
                                "cddSumberPenghasilan": nasabahBadanUsaha.cddSumberPenghasilan,
                                "cddTujuanPenggunaanDana": nasabahBadanUsaha.cddTujuanPenggunaanDana,
                                "cddStatusKenaPajak": nasabahBadanUsaha.cddStatusKenaPajak,
                                "cddKeteranganBebasPajak": nasabahBadanUsaha.cddKeteranganBebasPajak,
                                "cddNomorSurat": nasabahBadanUsaha.cddNomorSurat,
                                "cddTanggalTerbit": nasabahBadanUsaha.cddTanggalTerbit,
                                "cddTanggalBerakhir": nasabahBadanUsaha.cddTanggalBerakhir,
                                "cddNominalStorTunai": nasabahBadanUsaha.cddNominalStorTunai ?? 0,
                                "cddNominalStorTunaiFre": nasabahBadanUsaha.cddNominalStorTunaiFre ?? 0,
                                "cddNominalStorNonTunai": nasabahBadanUsaha.cddNominalStorNonTunai ?? 0,
                                "cddNominalStorNonTunaiFre": nasabahBadanUsaha.cddNominalStorNonTunaiFre ?? 0,
                                "cddNominalTarikTunai": nasabahBadanUsaha.cddNominalTarikTunai ?? 0,
                                "cddNominalTarikTunaiFre": nasabahBadanUsaha.cddNominalTarikTunaiFre ?? 0,
                                "cddNominalTarikNonTunai": nasabahBadanUsaha.cddNominalTarikNonTunai ?? 0,
                                "cddNominalTarikNonTunaiFre": nasabahBadanUsaha.cddNominalTarikNonTunaiFre ?? 0,
                                "cddStatusResiko": nasabahBadanUsaha.cddStatusResiko,
                                "cddIdentitasNasabah": nasabahBadanUsaha.cddIdentitasNasabah, 
                                "cddLokasiUsaha": nasabahBadanUsaha.cddLokasiUsaha, 
                                "cddProfilNasabah": nasabahBadanUsaha.cddProfilNasabah, 
                                "cddJumlahTransaksi": nasabahBadanUsaha.cddJumlahTransaksi, 
                                "cddKegiatanUsaha": nasabahBadanUsaha.cddKegiatanUsaha, 
                                "cddStrukturKepemilikan": nasabahBadanUsaha.cddStrukturKepemilikan, 
                                "cddInformasiLainnya": nasabahBadanUsaha.cddInformasiLainnya,
                                "cddFaktorResiko": nasabahBadanUsaha.cddFaktorResiko,
                                "cddTujuanBukaRekening": nasabahBadanUsaha.cddTujuanBukaRekening,
                            },
                            "_relasiNasabah": data.RelasiNasabah,
                            // [
                            //     {
                            //         "IDRelasi": nasabahBadanUsaha.IDRelasi,
                            //         "relasiHubunganDenganNasabah": nasabahBadanUsaha.relasiHubunganDenganNasabah,
                            //         "relasiJabatanPangkat": nasabahBadanUsaha.relasiJabatanPangkat,
                            //         "relasiNomorNasabah": nasabahBadanUsaha.relasiNomorNasabah,
                            //         "relasiNama": nasabahBadanUsaha.relasiNama,
                            //         "relasiAlamat": nasabahBadanUsaha.relasiAlamat,
                            //         "relasiProvinsi": nasabahBadanUsaha.relasiProvinsi,
                            //         "relasiKotaKabupaten": nasabahBadanUsaha.relasiKotaKabupaten,
                            //         "relasiKecamatan": nasabahBadanUsaha.relasiKecamatan,
                            //         "relasiKelurahan": nasabahBadanUsaha.relasiKelurahan,
                            //         "relasiRw": nasabahBadanUsaha.relasiRw,
                            //         "relasiRt": nasabahBadanUsaha.relasiRt,
                            //         "relasiKodePos": nasabahBadanUsaha.relasiKodePos,
                            //         "relasiTeleponRumahKodeArea1": nasabahBadanUsaha.relasiTeleponRumahKodeArea1, 
                            //         "relasiTeleponRumah1": nasabahBadanUsaha.relasiTeleponRumah1,  
                            //         "relasiNomorHandphone": nasabahBadanUsaha.relasiNomorHandphone,
                            //         "relasiFaxKodeArea": nasabahBadanUsaha.relasiFaxKodeArea, 
                            //         "relasiFaxNomor": nasabahBadanUsaha.relasiFaxNomor,  
                            //         "relasiEmailAddress": nasabahBadanUsaha.relasiEmailAddress,
                            //         "relasiJenisIdentitas": nasabahBadanUsaha.relasiJenisIdentitas,
                            //         "relasiNomorIdentitas": nasabahBadanUsaha.relasiNomorIdentitas,
                            //         "relasiNomorNpwp": nasabahBadanUsaha.relasiNomorNpwp,
                            //         "relasiKewarganegaraan": nasabahBadanUsaha.relasiKewarganegaraan,
                            //         "relasiNegaraAasal": nasabahBadanUsaha.relasiNegaraAasal,
                            //         "relasiPekerjaan": nasabahBadanUsaha.relasiPekerjaan, 
                            //     }
                            // ],
                            "_beneficiaryIndividu": {
                                    "benefNama": data?._beneficiaryIndividu?.benefNama,
                                    "benefGender": data?._beneficiaryIndividu?.benefGender,
                                    "benefBirthPlace": data?._beneficiaryIndividu?.benefBirthPlace,
                                    "benefBirthDate": data?._beneficiaryIndividu?.benefBirthDate,
                                    "benefAgama": data?._beneficiaryIndividu?.benefAgama,
                                    "benefStatusPerkawinan": data?._beneficiaryIndividu?.benefStatusPerkawinan,
                                    "benefHomePhone": data?._beneficiaryIndividu?.benefHomePhone,
                                    "benefMobilePhone": data?._beneficiaryIndividu?.benefMobilePhone,
                                    "benefAlamat": data?._beneficiaryIndividu?.benefAlamat,
                                    "benefRw": data?._beneficiaryIndividu?.benefRw,
                                    "benefRt": data?._beneficiaryIndividu?.benefRt,
                                    "benefProvinsi": data?._beneficiaryIndividu?.benefProvinsi,
                                    "benefKecamatan": data?._beneficiaryIndividu?.benefKecamatan,
                                    "benefKelurahan": data?._beneficiaryIndividu?.benefKelurahan,
                                    "benefKotaKabupaten": data?._beneficiaryIndividu?.benefKotaKabupaten,
                                    "benefNasabahKodePos": data?._beneficiaryIndividu?.benefNasabahKodePos,
                                    "benefAlamatDom": data?._beneficiaryIndividu?.benefAlamatDom,
                                    "benefRwDom": data?._beneficiaryIndividu?.benefRwDom,
                                    "benefRtDom": data?._beneficiaryIndividu?.benefRtDom,
                                    "benefProvinsiDom": data?._beneficiaryIndividu?.benefProvinsiDom,
                                    "benefKecamatanDom": data?._beneficiaryIndividu?.benefKecamatanDom,
                                    "benefKelurahanDom": data?._beneficiaryIndividu?.benefKelurahanDom,
                                    "benefKotaKabupatenDom": data?._beneficiaryIndividu?.benefKotaKabupatenDom,
                                    "benefKodePosDom": data?._beneficiaryIndividu?.benefKodePosDom,
                            },
                            
                            // "_beneficiaryOwner": {
                            //     "benefNamaPerusahaan": nasabahBadanUsaha.benefNamaPerusahaan,
                            //     "benefNomorIzinUsaha": nasabahBadanUsaha.benefNomorIzinUsaha,
                            //     "benefBidangUsaha": nasabahBadanUsaha.benefBidangUsaha,
                            //     "benefBentukUsaha": nasabahBadanUsaha.benefBentukUsaha,
                            //     "benefSumberDana": nasabahBadanUsaha.benefSumberDana,
                            //     "benefTujuanPenggunaanDana": nasabahBadanUsaha.benefTujuanPenggunaanDana,
                            //     "benefKeterangan": nasabahBadanUsaha.benefKeterangan,
                            //     "benefAlamat": nasabahBadanUsaha.benefAlamat,
                            //     "benefRw": nasabahBadanUsaha.benefRw,
                            //     "benefRt": nasabahBadanUsaha.benefRt,
                            //     "benefProvinsi": nasabahBadanUsaha.benefProvinsi,
                            //     "benefKecamatan": nasabahBadanUsaha.benefKecamatan,
                            //     "benefKelurahan": nasabahBadanUsaha.benefKelurahan,
                            //     "benefKotaKabupaten": nasabahBadanUsaha.benefKotaKabupaten,
                            //     "benefnasabahKodePos": nasabahBadanUsaha.benefnasabahKodePos,
                            // },
                            "_pihakLawan": {
                                "pihakLawanLembagaPemeringkatan": nasabahBadanUsaha.pihakLawanLembagaPemeringkatan,
                                "pihakLawanRating": nasabahBadanUsaha.pihakLawanRating,
                                "pihakLawanTanggalPemeringkatan": nasabahBadanUsaha.pihakLawanTanggalPemeringkatan,
                                "pihakLawanIdGrup": nasabahBadanUsaha.pihakLawanIdGrup,
                            },
                            "_alamatAlternatif": {
                                "alternatifKodePos": nasabahBadanUsaha.alternatifKodePos,
                                "alternatifProvinsi": nasabahBadanUsaha.alternatifProvinsi,
                                "alternatifKotaKabupaten": nasabahBadanUsaha.alternatifKotaKabupaten,
                                "alternatifRtRw": nasabahBadanUsaha.alternatifRtRw,
                                "alternatifKecamatan": nasabahBadanUsaha.alternatifKecamatan,
                                "alternatifAlamat": nasabahBadanUsaha.alternatifAlamat,
                                "alternatifTelepon": nasabahBadanUsaha.alternatifTelepon,
                                "alternatifKelurahan": nasabahBadanUsaha.alternatifKelurahan,
                            }
                        }));

                        const province_code = nasabahBadanUsaha.nasabahProvinsi
                        // // // console.log(data)
                        await Promise.all([
                            getingCities(province_code),
                            getingCitiesBenef(data._beneficiaryIndividu?.benefProvinsi),
                            getingCitiesBenfDom(data._beneficiaryIndividu?.benefProvinsiDom),
                        ])
                    
                    }
                    // // // console.log(data)
                    // setFormData(prevState => ({ ...prevState, ...stateBase }));
                    // const province_benefAlamatDom = nasabahBadanUsaha.benefProvinsiDom
                    // const province_code = nasabahBadanUsaha.nasabahProvinsi
                    // // // // console.log(data)
                    // await Promise.all([
                    //     getingCities(province_code),
                    //     getingCitiesBenef(data._beneficiaryIndividu?.benefProvinsi),
                    //     getingCitiesBenfDom(data._beneficiaryIndividu?.benefProvinsiDom),
                    // ])
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        closeLoading()
    }
    // get cities Beneficiary Domisili
    async function getingCitiesBenfDom(province_dom) {
        const data = await getCities(province_dom);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCitiesBenfDom(data);
    }

    // salin data beneficary owner
    async function salinAlamatBen() {
        const provcode = formData._identitasNasabah.nasabahProvinsi
        await (
            Promise.all([
                getingCitiesBenfDom(provcode),

            ])
        )

    changeForm({
        ...formData._beneficiaryIndividu,
        "benefKodePosDom": formData._identitasNasabah.nasabahKodePos,
        "benefAlamatDom": formData._identitasNasabah.nasabahAlamat,
        "benefKelurahanDom": formData._identitasNasabah.nasabahKelurahan,
        "benefProvinsiDom": formData._identitasNasabah.nasabahProvinsi,
        "benefRtDom": formData._identitasNasabah.nasabahRt,
        "benefRwDom": formData._identitasNasabah.nasabahRw,
        "benefKecamatanDom": formData._identitasNasabah.nasabahKecamatan,
        "benefKotaKabupatenDom": formData._identitasNasabah.nasabahKotaKabupaten,
    }, "_beneficiaryIndividu")
}
     //faktor resiko state
     const [formResiko, setFormResiko] = useState({
        identitasMasalah: "L",
        lokasiUsaha: "L",
        profileNasabah: "L",
        jumlahTrx: "L",
        kegiatanUsaha: "L",
        strukturKepemilikan: "L",
        informasiLainnya: "L"
    });

    // fungsiFaktorresiko
    useEffect(function () {
        const keys = Object.keys(formResiko);
        let average = 1;

        // keys.forEach(k => {
        //     total += parseInt(formResiko[k]);
        // });
        let dataResiko = [
            null,
            "L",
            "M",
            "H"
        ];
        let dr = {
            L : 1,
            M : 2,
            H : 3
        }

        average = Math.max.apply(Math, keys.map(x => parseInt(dr[formResiko[x]])))
        changeForm(dataResiko[average], "cddStatusResiko", "_cdd")
    }, [formResiko])

    // close Alert
    function closeAlert() { setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null }) }

    // closeConfirm
    function closeConfirm() { setDataConfirm({ show: false, body: "", header: null, title: "", footerButton: [], closeButton: true, witTextArea: false }) }

    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} nasabah ini ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        setLoading(prevState => ({ ...prevState, loadingPost: { show: true, msg: "Please Wait ..." } }));
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (locationState !== null && locationState !== undefined) {
            try {
                const { statusCode, message = "", remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${locationState.kode_entry}/${locationState.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({ show: true, body: message, title: ``, header: "Success", callBack: () => history.goBack() });
                    else setDataAlert({ show: true, body: remark, title: '', header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });

        setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        if (typeof value === "string") value = value.toUpperCase()
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    // fungsi cek nasabah 
    async function cekNasabah() {
        const bodyData = {
            "identity_number": formData._personalIdData.personalIdNumber,
            "customer_name": formData.firstName,
            "customer_birthdate": formData.birthDate,
            "mother_name": formData._customerData.personalMothersName,
            "nasabahTanggalAktePendirian": moment(formData?._identitasNasabah?.nasabahTanggalAktePendirian).format("DD-MM-YYYY"),
        }
        setLoading(prevState => ({ ...prevState, loading: true }));
        try {
            const resp = await doPost({
                data: bodyData,
                url: "/nasabah/validasi"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            let bodyAlert = "Nasabah belum terdaftar. Proses registrasi bisa dilanjutkan";
            if (status === "Failed") {
                const { cif_number = "", identity_number = "", customer_name = "", customer_birthdate = "", mother_name = "" } = data?.validasiIndividu || {}
                bodyAlert = (
                    <div className="row">
                        <div className="col">
                            <p>Nasabah sudah terdaftar dengan nomor nasabah <b>{cif_number}</b></p>
                            <p>Dengan Data Berikut :</p>
                            <ul>
                                <li> Nama Lengkap : <b>{customer_name}</b></li>
                                <li> Tanggal Lahir : <b> {customer_birthdate !== "" ? moment(customer_birthdate).format("DD MMMM YYYY") : ""} </b></li>
                                <li> Nomor Identitas : <b>{identity_number}</b></li>
                            </ul>
                            <br />
                            <p>Silahkan gunakan nomor nasabah yang sudah terdaftar <b>( {cif_number} )</b></p>
                        </div>
                    </div>
                )
            }
            setDataAlert({
                show: true,
                header: "Konfirmasi",
                body: bodyAlert
            })
        } catch (error) {
            setDataAlert({
                show: true,
                header: "Error",
                body: "Terjadi Kesalahan",

            })
        }
        closeLoading();
    }
    //  fungsi validasi
    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            fieldRequired = ["nasabahBadanUsaha", "nasbahAssetPerusahaan", "nasabahNomorNpwp", "groupName", "nasabahOmsetPerusahaan", 
            // "nasabahBidangUsaha", 
            "nasabahKodeLokasi",
                "nasabahNomorAktePendirian", "nasabahTanggalAktePendirian", "nasabahSektorEkonomi", "nasabahAlamat", "nasabahRw", "nasabahRt", "nasabahProvinsi", "nasabahKotaKabupaten", "nasabahKecamatan", "nasabahKelurahan",
                "cddPenghasilanKotorPerTahun", "cddGolonganNasabah", "cddSumberDana", "cddSumberPenghasilan", "", "cddTujuanPenggunaanDana", "cddStatusKenaPajak", 
                //"cddKeteranganBebasPajak", 
                "cddNominalStorTunai", "nasabahKodeMarketing",
                "cddNominalStorTunaiFre", "cddNominalStorNonTunai", "cddNominalStorNonTunaiFre", "cddNominalTarikTunai", "cddNominalTarikTunaiFre", "cddNominalTarikNonTunai", "cddNominalTarikNonTunaiFre", "_pejabatKorporat"
            ];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if ((formData[key] instanceof Object)) {
                if ((Array.isArray(formData[key])) && (fieldRequired.some(v => v === key))) {
                    // // // console.log(formData[key], key)
                    if (formData[key].length <= 0) errS = true;
                } else {
                    const tKey = Object.keys(formData[key]);
                    tKey.forEach(element => {

                        // // // console.log(key, element)
                        if (fieldRequired.some(v => element === v)) {
                            if (formData[key][element] === "" || formData[key][element] === undefined || formData[key][element] === null) {
                                errS = true;
                                //// // console.log("periksa kembali ", key, " ", element, " ", formData[key][element])
                            } else if (Array.isArray(formData[key][element])) {

                                if (formData[key][element].length <= 0) errS = true;
                            }
                        }
                    });
                }
            } else {

                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || formData[key] === undefined || formData[key] === null) {
                        errS = true;
                        //// // console.log("periksa kembali ", key, " ", formData[key])
                    }
                }
            }
        })
        return errS;
    }

    // close Loading
    function closeLoading() { setLoading(prevState => ({ msg: "Please Wait ...", loading: false })) }

    // confirm Post Nasabah
    function confirmPostNasabah() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan ${formType}, cek kembali form ${formType}. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => paramUrl.mode === "edit" ? updateNasabah() : postNasabah(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // fungsi post nasabah
    async function postNasabah() {
        // // // console.log(formData)
        const userInfo = await keycloak.loadUserInfo();
        const err = validasi();
        if (err) {
            return setDataAlert({ show: true, body: "Periksa kembali form pendaftaran, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        setLoading(prevState => ({ ...prevState, loading: true }));
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                // data: formData,
                data: {
                    ...formData,
                    uid:keycloak.uid,
                },
                url: "/nasabah-badan-usaha"
            });
            // // // console.log(formData)
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Registrasi Nasabah Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => history.goBack() });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
    }

    // update nasabah
    async function updateNasabah() {
        const userInfo = await keycloak.loadUserInfo();
        const err = false;
        if (err) {
            return setDataAlert({ show: true, body: "Periksa kembali form ubah data, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        setLoading(prevState => ({ ...prevState, loading: true }));
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                method: "PUT",
                data: formData,
                url: `/nasabah-badan-usaha?db_id=${locationState.dbId}`
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Ubah Nasabah Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => history.goBack() });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
    }
    //  get data Mekerting
    async function getDataMarketing() {
        try {
            const { data, statusCode, status, remark, statusText } = await doGet({
                // url: `/produk-deposit/${kat}`,
                url: `/account-officer`,
                service: "cif"
            });
            if (statusCode === 200) {
                if (status === "00") setDataMarketing(data?.list)
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data produk`, header: "Error", callBack: () => null });
        }

    }

    // fungsi cek nasabah 
    async function cekNasabahEnter(cif) {
        // setLoading(prevState => ({  msg: "Checking Account No ...", show: true }));
        if(paramUrl.mode === "edit"){
            if(cif === formData.id) return  setDataAlert({ show: true, body: "Nomor nasabah induk tidak boleh sama dengan nomor nasabah utama.", title: ``, header: "Info", callBack: () => changeForm("", "nasabahNomorInduk","_identitasNasabah") })
        }
        changeForm("", "nasabahNamaInduk", "_identitasNasabah");
        setLoading(prevState => ({ ...prevState, loadingPost: { msg: "Checking CIF No. Please Wait ...", show: true } }));
        await wait(500)
        try {
            const resp = await doGet({
                url: `/nasabahumum/${cif}`,
                service: "cif"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    const { personalIdHolderName = "", nama_cabang = "", valuta = "", nama_produk = "" } = data?.nasabahBadanUsaha;
                    changeForm(personalIdHolderName, "nasabahNamaInduk", "_identitasNasabah");
                }
                else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => changeForm("", "nasabahNomorInduk","_identitasNasabah") });
            } else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => changeForm("", "nasabahNomorInduk","_identitasNasabah") });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => changeForm("", "nasabahNomorInduk","_identitasNasabah") });
        }
        closeLoading();
    }

    // get province_code
    async function getProvinceCode(province_name) {
        if (provinces !== null && provinces !== undefined) {
            const data = provinces?.find(v => {
                return v.province_name === province_name
            });
            return data?.province_code || "";
        }
    }
    // get cities 
    async function getingCities(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCities(data);
    }

    // get districts
    async function getingDistrict(city) {
        const data = await getDistrics(city);
        const newData = data.map(v => ({ key: v, display: v }));
        setDistricts(newData);
    }
    // get village
    async function getingVillages(city, subdistrict) {
        const data = await getVillages(city, subdistrict);
        const newData = data.map(v => ({ key: v, display: v }));
        setvillages(newData);
    }
    // get postalcode
    async function getingPostalCodes(city, subdistrict, urban) {
        const data = await getPostalsCode(city, subdistrict, urban);
        const newData = data.map(v => ({ key: v, display: v }));
        setPostalCodes(newData);
    }
    // get cities Beneficiary
    async function getingCitiesBenef(province_benef) {
        const data = await getCities(province_benef);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCitiesBenf(data);
    }
    // get districts Beneficiary
    async function getingDistrictBenef(city) {
        const data = await getDistrics(city);
        const newData = data.map(v => ({ key: v, display: v }));
        setDistricts(newData);
    }
    // get village Beneficiary
    async function getingVillagesBenef(city, subdistrict) {
        const data = await getVillages(city, subdistrict);
        const newData = data.map(v => ({ key: v, display: v }));
        setvillages(newData);
    }
    // get postalcode Beneficiary
    async function getingPostalCodesBenef(city, subdistrict, urban) {
        const data = await getPostalsCode(city, subdistrict, urban);
        const newData = data.map(v => ({ key: v, display: v }));
        setPostalCodes(newData);
    }

    const [statusPajak, setStatusPajak] = useState({
        "Aa": false,
        "Ba": false,
        "Ca": false,

    })

    function changePajak(value) {
        if (value === "0") {
            // // // console.log(value)
            setStatusPajak({
                "Aa": false,
                "Ba": false,
                "Ca": false,
            })

            changeForm({
                ...formData._cdd,
                "cddStatusKenaPajak":"0",
                "cddKeteranganBebasPajak":"KENA PAJAK" ,
                "cddNomorSurat":"" ,
                "cddTanggalTerbit": "",
                "cddTanggalBerakhir":"" ,
            }, "_cdd")

        } else if (value === "1") {
            setStatusPajak({
                "Aa": true,
                "Ba": true,
                "Ca": true,
            })
        } else if (value === "2") {
            setStatusPajak({
                "Aa": true,
                "Ba": true,
                "Ca": false,
            })

            changeForm({
                ...formData._cdd,
                "cddStatusKenaPajak":"2",
                // "cddKeteranganBebasPajak":"" ,
                // "cddNomorSurat":"" ,
                "cddTanggalTerbit": "",
                "cddTanggalBerakhir":"" ,
            }, "_cdd")
        }
    }


    
    function handleShortcut(e) {
        const { key, keyCode } = e;
        if (keyCode === 37) {

            prevState()
        } if (keyCode === 39) {
            nextStep()
            // setUserText(prevUserText => `${prevUserText}${key}`);
        }
    }

    function handleShiftUp(e) {
        const { key, keyCode } = e;
        if (keyCode === 16) {
            
            document.removeEventListener("keyup", handleShortcut)
        }
    }


    function handleShiftDown(e) {
        const { key, keyCode } = e;
        if (keyCode === 16) {
            document.addEventListener("keyup", handleShortcut)
        }
    }

    function resetForm() {
        setFormData({
            "emailAddress": "",
            "id": "",
            "notes": "",
            "groupName": "",
            "assignedBranchKey": "",
            "nasabah_prioritas" : "",
            "_identitasNasabah": {
                "nasabahBadanUsaha": "",
                "nasabahAssetPerusahaan": 0,
                "nasabahNomorNpwp": "",
                "nasabahNamaPerusahaan": "",
                "nasabahOmsetPerusahaan": 0,
                "nasabahBidangUsaha": "",
                "nasabahKodeLokasi": "",
                "nasabahKeteranganLokasi": "",
                "nasabahLokasi": "testing",
                "nasabahNomorSiupp": "",
                "nasabahTanggalPengesahanSiupp": "",
                "nasabahNomorSkdp": "",
                "nasabahNomorAktePendirian": "",
                "nasabahTanggalAktePendirian": "",
                "nasabahTempatAktePendirian": "",
                "nasabahSektorEkonomi": "",
                "nasabahStatusKeterkaitan": "",
                "nasabahBankNonBank": "",
                "nasabahNomorAktePerubahan": "",
                "nasabahTanggalAktePerubahan": "",
                "nasabahTanggalPengesahanSkdp": "",
                "nasabahTanggalKadaluarsaSkdp": "",
                "nasabahNomorSkMenkumham": "",
                "nasabahTanggalSkMenkumham": "",
                "nasabahNomorTDP": "",
                "nasabahPemilik": "",
                "nasabahStrukturModal": "",
                "nasabahAlamat": "",
                "nasabahRw": "",
                "nasabahRt": "",
                "nasabahProvinsi": "",
                "nasabahKotaKabupaten": "",
                "nasabahKecamatan": "",
                "nasabahKelurahan": "",
                "nasabahKodePos": "",
                "nasabahTeleponKantorKodeArea1": "",
                "nasabahTeleponKantorKodeArea2": "",
                "nasabahTeleponKantorKodeArea3": "",
                "nasabahTeleponKantorNomor1": "",
                "nasabahTeleponKantorNomor2": "",
                "nasabahTeleponKantorNomor3": "",
                "nasabahFaxKodeArea": "",
                "nasabahFaxNomor": "",
                "nasabahKodeMarketing": "",
                "nasabahNamaMarketing": "",
                "nasabahNomorInduk": "",
                "nasabahNamaInduk": "",
                "nasabahNasabahInduk": "induk tes",
                "nasabahPicNasabah": "PIC",
                "nasabahBranchCode": "002",
                // "nasabahStatusKeterkaitan": "tes keterkaitan",
                "nasabahKepemilikanModal": "kepemilikan",
                "nasabahJenisPenduduk": "TES JENIS",
                "nasabahWilayahPemberiReferensi": "TES PEMBERI",
                "nasabahTempatPembuatanSiupp": "siup tes",
                "nasabahTanggalKadaluarsaSiupp": "2021-07-21",
                "nasabahTempatPembuatanSkdp": "skdp tempat tes",
                "nasabahTempatAktePerubahan": "tempat tes",
                "nasabahTanggalExpAktePerubahan": "2021-07-21",
                "nasabahTempatSkMenkumham": "tempat dumy",
                "nasabahTanggalExpSkMenkumham": "2021-07-21",
                "nasabahKegiatanUsaha": "testingdumy",
                "CPName": "",
                "CPNomorHP": "",
            },
            "_pejabatKorporat": [],
            "_cdd": {
                "cddPenghasilanKotorPerTahun": "",
                "cddGolonganNasabah": "",
                "cddSumberDana": "",
                "cddSumberPenghasilan": "",
                "cddTujuanPenggunaanDana": "",
                "cddStatusKenaPajak": "0",
                "cddKeteranganBebasPajak": "KENA PAJAK",
                "cddNomorSurat": "",
                "cddTanggalTerbit": "",
                "cddTanggalBerakhir": "",
                "cddNominalStorTunai": "",
                "cddNominalStorTunaiFre": "",
                "cddNominalStorNonTunai": "",
                "cddNominalStorNonTunaiFre": "",
                "cddNominalTarikTunai": "",
                "cddNominalTarikTunaiFre": "",
                "cddNominalTarikNonTunai": "",
                "cddNominalTarikNonTunaiFre": "",
                "cddStatusResiko": "L",
                "cddIdentitasNasabah": "L", 
                "cddLokasiUsaha": "L", 
                "cddProfilNasabah": "L", 
                "cddJumlahTransaksi": "L", 
                "cddKegiatanUsaha": "L", 
                "cddStrukturKepemilikan": "L", 
                "cddInformasiLainnya": "L" ,
                "cddFaktorResiko": "tes faktor resiko",
                "cddTujuanBukaRekening": "2 -INVESTASI",
            },
            "_relasiNasabah": [],
            // "_beneficiaryOwner": {
            //     "benefNamaPerusahaan": "",
            //     "benefNomorIzinUsaha": "",
            //     "benefBidangUsaha": "",
            //     "benefBentukUsaha": "",
            //     "benefSumberDana": "",
            //     "benefTujuanPenggunaanDana": "",
            //     "benefKeterangan": "",
            //     "benefAlamat": "",
            //     "benefRw": "",
            //     "benefRt": "",
            //     "benefProvinsi": "",
            //     "benefKecamatan": "",
            //     "benefKelurahan": "",
            //     "benefKotaKabupaten": "",
            //     "benefnasabahKodePos": "",
            // },
            "_beneficiaryIndividu": {
                "benefNama": "",//Nama Lengkap
                "benefGender": "",
                "benefBirthPlace": "",//Tempat/Tanggal Lahir
                "benefBirthDate": "",//Tempat/Tanggal Lahir
                "benefAgama": "",//Agaman
                "benefStatusPerkawinan": "",//Status Perkawinan
                "benefHomePhone": "", //Telepon
                "benefMobilePhone": "",//Handphone
                "benefAlamat": "",
                "benefRw": "",
                "benefRt": "",
                "benefProvinsi": "",
                "benefKecamatan": "",
                "benefKelurahan": "",
                "benefKotaKabupaten": "",
                "benefNasabahKodePos": "",
                "benefAlamatDom": "",
                "benefRwDom": "",
                "benefRtDom": "",
                "benefProvinsiDom": "",
                "benefKecamatanDom": "",
                "benefKelurahanDom": "",
                "benefKotaKabupatenDom": "",
                "benefKodePosDom": ""
    
            },
            "_pihakLawan": {
                "pihakLawanLembagaPemeringkatan": "MIS - MOODY`S INVESTOR SERVICE",
                "pihakLawanRating": "3 - AA2",
                "pihakLawanTanggalPemeringkatan": "2021-07-16",
                "pihakLawanIdGrup": "100002"
            },
            "_alamatAlternatif": {
                "alternatifKodePos": "123",
                "alternatifProvinsi": "JAWA TENGAH",
                "alternatifKotaKabupaten": "KAB. WONOGIRI",
                "alternatifRtRw": "22/33",
                "alternatifKecamatan": "WONOGIRI",
                "alternatifAlamat": "JL. ALTERNATIF",
                "alternatifTelepon": "0838484843442",
                "alternatifKelurahan": "WONOHARJO"
            }
        })
    }

    function checkHP(value, callback=()=>null) {
        if (value.length  < 10){
            setDataAlert({ show: true, body: `No Handphone minimal 10 digit`, title: `Cek Kembali No HandPhone`, header: "INFO", callBack: () => callback() });
            
        }else{
            changeForm(value, "CPNomorHP", "_identitasNasabah")
        }
    }
    function checkHpBO(value, callback=()=>null) {
        if (value.length  < 10){
            setDataAlert({ show: true, body: `No Handphone minimal 10 digit`, title: `Cek Kembali No HandPhone`, header: "INFO", callBack: () => callback() });
            
        }else{
            changeForm(value, "benefMobilePhone", "_beneficiaryIndividu")
        }
    }
    function cekNPWP(value, callback=()=>null) {
        if (value.length  < 15){
            setDataAlert({ show: true, body: `NPWP minimal 15 digit`, title: `Cek Kembali NPWP`, header: "INFO", callBack: () => callback() });
            
        }else{
            changeForm(value, "nasabahNomorNpwp", "_identitasNasabah")
        }
    }
    // fungsi setDataForm
    function changeMailForm(value, key, subkey = null) {
        if (typeof value === "string") 
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    function resetFormCek() {
        setFormData(p=>({
            ...p,
            "groupName": "",
            "_identitasNasabah": {
                "nasabahBadanUsaha": "",
                "nasabahAssetPerusahaan": 0,
                "nasabahNomorNpwp": "",
                "nasabahNamaPerusahaan": "",
                "nasabahOmsetPerusahaan": 0,
                "nasabahTanggalAktePendirian": "",
                "nasabahTempatAktePendirian": "",
            }
        }))
    }

    const [isValid, setIsValid] = useState(false)

    async function cekNasabahKorporat() {
        if (formData.groupName === ''){
            return setDataAlert({ show: true, body: "Nama Perusahaan belum terisi. Silahkan isi terlebih dahulu lalu klik Button Cek Data Nasabah", title:"Warning", callBack:() => null });
        } 
        if (formData._identitasNasabah.nasabahNomorNpwp === ''){
            return setDataAlert({ show: true, body: "Nomor NPWP belum terisi. Silahkan isi terlebih dahulu lalu klik Button Cek Data Nasabah", title:"Warning", callBack:() => null });
        }
        if (formData._identitasNasabah.nasabahTempatAktePendirian === ''){
            return setDataAlert({ show: true, body: "Tempat Pendirian belum terisi. Silahkan isi terlebih dahulu lalu klik Button Cek Data Nasabah", title:"Warning", callBack:() => null });
        }
        if (formData._identitasNasabah.nasabahTanggalAktePendirian === ''){
            return setDataAlert({ show: true, body: "Tanggal Pendirian belum terisi. Silahkan isi terlebih dahulu lalu klik Button Cek Data Nasabah", title:"Warning", callBack:() => null });
        }

        await setLoading(prevState => ({ ...prevState, loadingPost: { msg: "Please Wait ...", show: true } }));
        const bodyData = {
            "nama_perusahaan": formData.groupName,
            "nomor_npwp": formData._identitasNasabah.nasabahNomorNpwp,
            "akte_pendirian_tanggal": moment(formData._identitasNasabah.nasabahTanggalAktePendirian).format("DD-MM-YYYY"),
            "akte_pendirian_tempat": formData._identitasNasabah.nasabahTempatAktePendirian
        }

        try {

            const userInfo = await keycloak.loadUserInfo();
            const resp = await doPost({
                data: bodyData,
                url: "/nasabah-badan-usaha/validasi",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
            });
            const { statusCode, status, statusText, data, remark } = resp;
            let bodyAlert = `Nasabah belum terdaftar. Proses ${paramUrl.mode === 'registration' ? 'registrasi' : 'ubah'} bisa dilanjutkan`;

            if (status === "Failed") {
                const { nama_perusahaan = "", nomor_npwp = "", akte_pendirian_tanggal = "", akte_pendirian_tempat = ""} = data?.validasiKorporasi || {}
                bodyAlert = (
                    <div className="row">
                        <div className="col">
                            <p>Nasabah Korporat sudah terdaftar</p>
                            <p>Dengan Data Berikut :</p>
                            <ul>
                                <li> Nama Perusahaan : <b>{nama_perusahaan}</b></li>
                                <li> Nomor NPWP : <b> {nomor_npwp } </b></li>
                                <li> Tempat Pendirian : <b>{akte_pendirian_tempat}</b></li>
                                <li> Tanggal Pendirian : <b>{akte_pendirian_tanggal}</b></li>
                            </ul>
                            <br />
                            <p>Silahkan gunakan nomor nasabah yang sudah belum terdaftar</p>
                        </div>
                    </div>
                )
                setIsValid(false)
                resetFormCek()
            } else {
                setIsValid(true)
            }
            setDataAlert({
                show: true,
                header: "Konfirmasi",
                body: bodyAlert,
                title: ""
            })
        } catch (error) {
            setDataAlert({
                show: true,
                header: "Error",
                body: "Terjadi Kesalahan",
                title: ""
            })
        }
        closeLoading();
    }
    
//Post Auditrail
async function auditTrail() {
    const userInfo = await keycloak.loadUserInfo();
    // // console.log(userInfo)
    var url = `/useraudit/user-audit-trail`;
    try {
        const { statusCode, status, remark, statusText, data = {} } = await doPost({
            url: url,
            service: "acc",
            data: { 
                    user_id : userInfo.preferred_username,
                    app_id : "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Registrasi",
                    event_description: "Registrasi Badan Usaha",
                    info1: "-",
                    info2: "-"
            },
            headers: {
                "x-user-id": userInfo.preferred_username,
                method: "POST"
            }
        });

        if (statusCode === 200) {
            if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
            else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
        } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    } catch (error) {
        setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    }
    
}
    return <fbContext.Provider value={{
        fbState: {
            statusPajak,
            paramUrl,
            step,
            formEditable,
            loading,
            dataAlert,
            formData,
            locationState,
            dataConfirm,
            otorDesc,
            dataDropDown,
            provinces,
            districts,
            villages,
            cities,
            postalCodes,
            formResiko,
            dataMarketing,
            citiesBenfDom,
            citiesBenf,
            podDate,
            isValid
        },
        fbAction: {
            changePajak,
            clickStep,
            nextStep,
            prevState,
            closeAlert,
            closeConfirm,
            responseOtor,
            setOtorDesc,
            changeForm,
            cekNasabah,
            validasi,
            confirmPostNasabah,
            getVillages,
            getCities,
            getDistrics,
            getPostalsCode,
            getProvinceCode,
            getingCities,
            getingDistrict,
            getingVillages,
            getingPostalCodes,
            setFormResiko,
            setDataAlert,
            setDataConfirm,
            setLoading,
            getDataMarketing,
            cekNasabahEnter,
            getingCitiesBenef,
            getingDistrictBenef,
            getingVillagesBenef,
            getingPostalCodesBenef,
            handleShiftDown,
            handleShiftUp,
            salinAlamatBen,
            getingCitiesBenfDom,
            resetForm,
            checkHP,
            checkHpBO,
            changeMailForm,
            cekNasabahKorporat,
            auditTrail,
            cekNPWP
        }
    }} >{children}</fbContext.Provider>
}

export default function useFormBadanUsaha() { return useContext(fbContext) };