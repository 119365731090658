import clsx from 'clsx';
import { useState, useEffect, useMemo } from 'react';
import { FormControl, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { TableGrid } from '../../../../controls';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../../../../app.config';
import { IconPrinter } from '../../../../../icon';
import ExportCsv from '../../../../controls/exportCsv/export-csv';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PembukaanDepositoPage = () => {
    let history = useHistory();
    const [rejectModal, setRejectModal] = useState(false);
    const [approvedModal, setApprovedModal] = useState(false);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState();
    const [status, setStatus] = useState('');
    const [dbId, setDbId] = useState(0);
    const [keterangan, setKeterangan] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        if (type === '') {
            fetch(baseUrl + `/deposito/otorisasi/otorisasi?limit=${limit}&page=${currentPage}`)
                .then((response) => response.json())
                .then((data) => setData(data?.list));
        } else {
            fetch(
                baseUrl +
                    `/deposito/otorisasi/otorisasi?limit=${limit}&page=${currentPage}&jenis_nasabah=${type}`
            )
                .then((response) => response.json())
                .then((data) => setData(data?.list));
        }

        const csvData = data?.map((item) => {
            return {
                'Nomor Rekening': item.nomorRekeningDeposito,
                'Nama Lengkap': item.namaLengkap,
                Alamat: item.alamat,
                'Jenis Nasabah': item.tipeAkun === 'CLIENT' ? 'Individu' : 'Badan Usaha',
            };
        });
        setCsvData(csvData);
    }, [currentPage, limit, status, type]);

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    //Action for reject deposito modal
    const rejectedAction = () => {
        setRejectModal(false);

        const body = {
            db_id: dbId,
            status: 'REJECT',
            keterangan: keterangan,
        };
        return fetch(baseUrl + `/deposito/otorisasi/otorisasi/create`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id === '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    //Action for approve deposito modal
    const approvedAction = () => {
        setApprovedModal(false);
        const body = {
            db_id: dbId,
            status: 'APPROVAL',
            keterangan: '',
        };
        return fetch(baseUrl + `/deposito/otorisasi/otorisasi/create`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id == '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    //Search datatable
    const handleSearch = () => {
        fetch(
            baseUrl +
                `/deposito/otorisasi/otorisasi?limit=${limit}&page=${currentPage}&jenis_nasabah=${type}&nama=${search}`
        )
            .then((response) => response.json())
            .then((data) => setData(data?.list));
    };

    return (
        <>
            <Modal centered show={Boolean(approvedModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setApprovedModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Setujui Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <p>
                        Yakin menyetujui otorisasi pembukaan deposito atas nama <b>{name}</b> ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setApprovedModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={approvedAction}
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(rejectModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setRejectModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tolak Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <FormControl
                        as="textarea"
                        placeholder="Masukkan keterangan penolakan"
                        onChange={(e) => setKeterangan(e.target.value)}
                        value={keterangan}
                    />
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={rejectedAction}
                    >
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Deposito
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-grid tw-grid-cols-2 tw-gap-0">
                    <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                        Rekening Deposito
                    </div>
                    <div className="tw-flex tw-text-12 tw-justify-end tw-p-7">
                        <ExportCsv csvData={csvData} fileName="Data Pembukaan Deposito" />
                        <button className="border-0 tw-bg-transparent">
                            <IconPrinter className="tw-mr-2" />
                            <span className="tw-text-primary">Cetak</span>
                        </button>
                    </div>
                </div>
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option selected={!type} disabledf>
                                    Jenis Nasabah
                                </option>
                                <option value="CLIENT">Individu</option>
                                <option value="GROUP">Badan Usaha</option>
                            </FormControl>
                            <form onSubmit={handleSearch} className="tw-w-full mr-4" id="search">
                                <FormControl
                                    className="mr-4"
                                    size="sm"
                                    placeholder="Cari no.rekening, nama..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </form>
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                                onClick={() => {
                                    setSearch('');
                                    setType('');
                                    // maybe better using ref, but well you can change it to ref if you want
                                    document.getElementById('search').reset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                type="submit"
                                form="searchForm"
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                                onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </div>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Nomor Rekening',
                            field: 'nomorRekeningDeposito',
                            width: '150px',
                        },
                        {
                            name: 'Nama Lengkap',
                            field: 'namaLengkap',
                            width: '150px',
                        },
                        {
                            name: 'Alamat',
                            field: 'alamat',
                            width: '320px',
                        },
                        {
                            name: 'Jenis Nasabah',
                            field: 'tipeAkun',
                            width: '100px',
                        },
                        {
                            field: 'action',
                        },
                    ]}
                    data={data || []}
                    onRenderField={(value, field, { className, data }) => {
                        // // console.log(data, "data")
                        if (field === 'value')
                            return <div className={clsx(className, 'tw-text-right')}>{value}</div>;
                        if (field === 'tipeAkun') {
                            return value === 'CLIENT' ? 'Individu' : 'Badan Usaha';
                        }
                        if (field === 'action')
                            return (
                                <Action
                                    onDetail={() => {
                                        history.push({
                                            pathname: '/sup-deposito/detail-deposito',
                                            state: {
                                                data: data.db_id,
                                            },
                                        });
                                    }}
                                    onApproved={() => {
                                        setName(data.namaLengkap);
                                        setDbId(data.db_id);
                                        setApprovedModal(true);
                                    }}
                                    onReject={() => {
                                        setRejectModal(true);
                                        setDbId(data.db_id);
                                        setName(data.namaLengkap);
                                    }}
                                    key={`${value}-${field}`}
                                />
                            );
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-end tw-text-black tw-mt-auto"
                ></div>
            </div>
        </>
    );
};

export default PembukaanDepositoPage;

const Action = ({ onDetail, onApproved, onReject }) => {
    const [show, setShow] = useState(false);

    const handleDetail = () => {
        if (onDetail) onDetail();

        setShow(false);
    };

    const handleApproved = () => {
        if (onApproved) onApproved();
        setShow(false);
    };
    const handleReject = () => {
        if (onReject) onReject();
        setShow(false);
    };

    return (
        <div>
            <Button basic color="red" size="mini" onClick={handleReject}>
                Tolak
            </Button>
            <Button basic color="green" className="tw-mr-3" size="mini" onClick={handleApproved}>
                Setujui
            </Button>
            <button type="button" class="btn btn-link" onClick={handleDetail}>
                Lihat Detail
            </button>
        </div>
    );
};
