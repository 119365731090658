import React, {useEffect} from 'react';
import { useParams } from 'react-router';
import { createForm } from '../../../../../module/createFormList';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import useRaft, { RaftProvider } from './useRaft';

function RaftPage() {
    const { raftAction, raftState } = useRaft();
    const paramUrl = useParams();
    useEffect(function () {
        raftAction.auditTrail()
    }, [])
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Tabungan & Giro</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Form AFT (Automatic Funding Transfer)
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        {/* Card header */}
                        <div className="card-header">
                            <div className="card-title">
                                Form AFT (Automatic Funding Transfer)
                            </div>
                        </div>
                        {/* End Of Card header */}
                        {/* Card Body */}
                        <div className="card-body">
                            <h4 className="text-base">
                                Data Rekening
                            </h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <FormCreated
                                        type="number"
                                        label="Nomor Rekening Debit"
                                        required={true}
                                        placeholder="Enter untuk cari nasabah"
                                        value={raftState.formData.nomor_rekening_debit}
                                        onChange={(e) => raftAction.setFormData(prevState => ({ ...prevState, nomor_rekening_debit: e }))}
                                        onEnter={(e) => raftAction.cekNasabah(e, 1)}
                                        onFocusOut={(e) => raftAction.cekNasabah(e, 1)}
                                        disabled={!raftState.formEditable}
                                    />
                                    <FormCreated
                                        type="text"
                                        label="Nama Rekening Debit"
                                        required={true}
                                        disabled={true}
                                        value={raftState.formData.nama_rekening_debit}
                                    />
                                </div>
                                <div className="col-md-6">

                                    <FormCreated
                                        type="number"
                                        label="Nomor Rekening Kredit"
                                        required={true}
                                        placeholder="Enter untuk cari nasabah"
                                        value={raftState.formData.nomor_rekening_kredit}
                                        disabled={!raftState.formEditable}
                                        onChange={(e) => raftAction.setFormData(prevState => ({ ...prevState, nomor_rekening_kredit: e }))}
                                        onEnter={(e) => raftAction.cekNasabah(e, 2)}
                                        onFocusOut={(e) => raftAction.cekNasabah(e, 2)}
                                    />
                                    <FormCreated
                                        type="text"
                                        label="Nama Rekening Kredit"
                                        required={true}
                                        disabled={true}
                                        value={raftState.formData.nama_rekening_kredit}
                                    />

                                </div>
                            </div>
                            <hr />

                            <h4 className="text-base">
                                Detail Transaksi
                            </h4>

                            <div className="row">
                                <div className="col-md-6">
                                    <FormCreated
                                        type="number"
                                        label="Jarak Periode Proses"
                                        required={true}
                                        value={raftState.formData.jarak_proses_periode}
                                        disabled={!raftState.formEditable}
                                        onChange={(e) => raftAction.setFormData(prevState => ({ ...prevState, jarak_proses_periode: e }))}
                                    />

                                    <FormCreated
                                        type="custom"
                                    >
                                        <div className="row">
                                            <div className="col-6">
                                                <FormCreated
                                                    type="date"
                                                    label="Tgl. Awal"
                                                    required={true}
                                                    value={raftState.formData.tgl_awal}
                                                    disabled={!raftState.formEditable}
                                                    onChange={(e) => raftAction.setFormData(prevState => ({ ...prevState, tgl_awal: e }))}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FormCreated
                                                    type="date"
                                                    label="Tgl. Akhir"
                                                    required={true}
                                                    value={raftState.formData.tgl_akhir}
                                                    disabled={!raftState.formEditable}
                                                    onChange={(e) => raftAction.setFormData(prevState => ({ ...prevState, tgl_akhir: e }))}
                                                />
                                            </div>
                                        </div>
                                    </FormCreated>
                                </div>
                                <div className="col-md-6">
                                    <FormCreated
                                        type="select"
                                        label="Periode Proses"
                                        placeholder="Pilih periode"
                                        required={true}
                                        value={raftState.formData.periode_proses}
                                        disabled={!raftState.formEditable}
                                        onChange={(e) =>{
                                             raftAction.setFormData(prevState => ({ ...prevState, periode_proses: e }))
                                            //  console.log(raftState.ePeriodeSiklus)
                                        }}
                                        options={raftState.ePeriodeSiklus}
                                        display="reference_desc"
                                        keyData="reference_code"

                                    />
                                    <FormCreated
                                        type="currency"
                                        label="Nominal SI"
                                        required={true}
                                        value={raftState.formData.nominal_si}
                                        disabled={!raftState.formEditable}
                                        onChange={(e) => raftAction.setFormData(prevState => ({ ...prevState, nominal_si: e }))}
                                    />
                                    <FormCreated
                                        type="number"
                                        label="Maksimum Gagal Proses"
                                        required={true}
                                        disabled={!raftState.formEditable}
                                        value={raftState.formData.maksimum_gagal_proses}
                                        onChange={(e) => raftAction.setFormData(prevState => ({ ...prevState, maksimum_gagal_proses: e }))}
                                    />

                                </div>
                            </div>

                        </div>
                        {/* End Of Card Body */}
                        {/* Card Footer */}
                        <div className="card-footer">
                            <div className="row">

                                <div className="col">
                                    {
                                        paramUrl.mode === "otorisasi" && (
                                            <>
                                                <button onClick={()=>raftAction.responseOtor("APPROVED")} 
                                                    className="btn btn-success float-right ml-3">
                                                    Approve
                                                </button>
                                                <button onClick={()=>raftAction.responseOtor("REJECTED")}
                                                className="btn btn-danger float-right ml-3">
                                                    Reject
                                                </button>
                                            </>
                                        )
                                    }
                                    {
                                        raftState.formEditable &&
                                        <button onClick={raftAction.confirmTrx} className="btn btn-success float-right ml-3">
                                            Simpan
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* End Card Footer */}
                    </div>
                </div>
            </div>
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => raftAction.setOtorDesc(e.target.value)}
                    value={raftState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )
}

function FormCreated({ children, ...props }) {
    // console.log(props)
    let option = props;
    if (props.type === "custom") option = { ...props, component: children, }
    return <>{
        createForm({ ...option,  key:props.keyData })
    }</>
}

export default () => <RaftProvider><RaftPage /></RaftProvider>