import React from 'react';
import useDeposito from '../useDeposito';
import { Tab, Nav, Modal, Button } from 'react-bootstrap';
import DetilDeposito from '../components/detilDeposito';
import Skeleton from 'react-loading-skeleton';
import { addCommas } from '../../../../../../module';


const ModalDetailDeposito = () => {
    const { rekState, rekAction } = useDeposito();

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalDetailDeposito} onHide={rekAction.closeDetailDeposito} >
                <Modal.Header closeButton>
                    <Modal.Title>Detail Deposito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                        <div className="row">
                            <div className="col-md-8">
                                <div className="box mb-3" style={{ height: 150 }}>
                                    <div className="row">
                                        <div className="col">
                                            <h4>{rekState.loadingDetil ? <Skeleton width={"50%"} height={30} /> : <>{rekState.rekSelected?.nama_rekening}<span className="ml-2 badge badge-pill badge-secondary">{rekState.rekSelected?.status}</span></>}</h4>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-3"> No. Deposito </div>
                                                        <div className="col-9">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nomor_rekening}`} </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3"> No. Bilyet </div>
                                                        <div className="col-9">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nomor_bilyet}`}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3"> No. Akad </div>
                                                        <div className="col-9">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nomor_akad || "-"}`}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3"> Kode Cabang </div>
                                                        <div className="col-9">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nama_cabang}`}</div>
                                                    </div>
                                                </div>

                                    

                                                {/* <div className="col-4">
                                            <div className="row">
                                                <div className="col-3"> Saldo Rek </div>
                                                <div className="col-9">
                                                {rekState.loadingDetil ? <Skeleton height={20} /> : <>: <span style={{float:"right"}}> {rekState.rekSelected?.saldo} </span> </>}
                                                </div>
                                            </div>
                                        </div> */}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="box" style={{ height: 150 }}>
                                    <div className="row">
                                        <div className="col">
                                            <h4>Saldo</h4>
                                            {/* <div style={{position:"absolute", width:"100%", bottom:35, right:20}}> */}
                                                {rekState.loadingDetil ? <Skeleton height={40} /> : <>
                                                    <span className="text-base" style={{ fontWeight:"bolder", fontSize:"20pt" }}>
                                                        {addCommas(parseFloat(rekState.rekSelected?.saldo).toFixed(2))} </span>
                                                </>}
                                            {/* </div> */}
                                            <div className="row">
                                                <div className="col-6">Cadangan Bagi Hasil</div>
                                                <div className="col-6">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.cadangan_bagi_hasil || 0}`}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">Info Accrual Bagi Hasil</div>
                                                <div className="col-6">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.saldo_accrual_bagihasil || 0}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                                <DetilDeposito />


                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button
                                onClick={() => rekAction.closeDetailDeposito()}
                                className="btn btn-outline-success float-right">
                                Tutup
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal >
            {/* end modal utama */}
        </>
        
    );
}

export default ModalDetailDeposito;