import React, { memo,useRef } from 'react';
import tiketLogo from '../../../img/tiket.png'
import {BasePagePay} from '../../controls';
import { AppAction, AppInterfaces } from '../../../app.data';


const nom = [
    {
        id:1,
        providerId:1,
        value:10000,
        price:12000
    }
]

const IconHeader = memo(function(){
    return (
        <>
            <img src={tiketLogo} alt="Logo" width={40} />
        </>
    )
})

const tiketPage_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
        
    return (
        <React.Fragment>
            <AppAction ref={appAction} />
            <BasePagePay
                options = {
                    [
                        {
                            display : "Tiket",
                            value : 2,
                            forms: [{
                                type:"select",
                                name: "Tiketing",
                                label:"Pembayaran Tiket",
                                value:store.formData.providerId,
                                onChange:async (val)=> {
                                    const newNominal = await nom.filter(v => v.providerId === parseInt(val))
                                    appAction.current.setForm("providerId", val)
                                    appAction.current.disp({
                                        type:"setNominalList",
                                        nominalList:newNominal
                                    })
                                },
                                displayKey:"name",
                                valueKey:"id",
                                required:true,
                                readOnly:false,
                                options : [
                                    {
                                        name : "Kereta Api Indonesia",
                                        id : 1,
                                    },
                                    {
                                
                                        name : "City Link",
                                        id : 2,
                                    }
                                ]
                
                            },{
                                type: "text",
                                name: "Tiketing",
                                label: "Kode Pembayaran",
                                value: store.formData.pelanggan,
                                required: true,
                                onChange: (val) => appAction.current.setForm("no_pembayaran", val),
                                readOnly: false,
                                onEnter:() => appAction.current.setOpenPembayaran(true)
                            }],
                            selectNominal: true,
                            onSelectNominal: (val)=>appAction.current.setNominal(val),
                            onPay: () => appAction.current.pay(),
                
                        }
                    ]
                }
                title ={<span className="px-0"><IconHeader/> Pembayaran tiket</span>}
                nominals={nom}
                paymentCardData={{
                    detilBody: [
                        {
                            key: "Jenis Layanan",
                            value: "Pembayaran tiket"
                        },{
                            key: "Kode tiket",
                            value: "123456789"
                        },{
                            key: "ID Pelanggan",
                            value: "80312241741871"
                        },{
                            key: "Nama Pelanggan",
                            value: "Mayuga Wicaksana"
                        },
                    ],
                    detilFooter: [
                        {
                            key: "Harga",
                            value: 235500
                        },
                        {
                            key: "Admin",
                            value: 2500
                        }
                    ],
                    total: 237500
                }}

            />
        </React.Fragment>
    );

});

const tiketPage = AppInterfaces.appLoad(tiketPage_base)
export default tiketPage;