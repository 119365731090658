import React from 'react';
import { Label } from 'semantic-ui-react';
import { withKeycloak } from '@react-keycloak/web';
import { Modal, Form } from 'react-bootstrap';
import { baseUrl } from './../../../../app.config';

class SetorKas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_user: '1',
            keterangan: '',
            no_referensi: '',
            nilai_transaksi: 0,
            nilai_ekuivalen: 1,
            jumlah: 0,
            saldo_kas: 0,
            valuta: 'IDR',
            cd_amount_string: '',
            cd_amount_int: 0,
            showModalConfirm: false,
            showModalSuccess: false,
            showModalError: false,
            arrDenoms: [
                { amount_i: 100000, amount_s: '100.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 75000, amount_s: '75.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 50000, amount_s: '50.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 20000, amount_s: '20.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 10000, amount_s: '10.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 5000, amount_s: '5.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 2000, amount_s: '2.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 1000, amount_s: '1.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 500, amount_s: '500', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 1000, amount_s: '1.000', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 500, amount_s: '500', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 200, amount_s: '200', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 100, amount_s: '100', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 50, amount_s: '50', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 25, amount_s: '25', type: 'Koin', count: 0, amount_m: '' },
            ],
            exceedAmountError: false,
        };
    }

    async componentDidMount() {
        const balance = await this.getSaldo();

        this.setState({ saldo_kas: balance });
    }

    async getSaldo() {
        const res = await fetch(baseUrl + '/teller/single/balance');

        const data = await res.json();

        const balance = data.list?.balance;

        return balance;
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    };

    handleBatal = () => {
        this.setState({
            arrDenoms: [
                { amount_i: 100000, amount_s: '100.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 75000, amount_s: '75.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 50000, amount_s: '50.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 20000, amount_s: '20.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 10000, amount_s: '10.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 5000, amount_s: '5.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 2000, amount_s: '2.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 1000, amount_s: '1.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 500, amount_s: '500', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 1000, amount_s: '1.000', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 500, amount_s: '500', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 200, amount_s: '200', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 100, amount_s: '100', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 50, amount_s: '50', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 25, amount_s: '25', type: 'Koin', count: 0, amount_m: '' },
            ],
            keterangan: '',
            no_referensi: '',
            cd_amount_string: '',
            cd_amount_int: 0,
            exceedAmountError: false,
        });
    };

    calcDenom = (event, idxData) => {
        const { value, name } = event.target;

        var arrDenoms = this.state.arrDenoms;
        var rowData = arrDenoms[idxData];
        rowData.count = value;

        var amount_m_i = rowData.amount_i * value;
        var amount_m_s = '';
        if (value !== 0) amount_m_s = this.formatCurrency(amount_m_i, '');
        rowData.amount_m = amount_m_s;

        amount_m_i = 0;
        amount_m_s = '';
        arrDenoms.forEach((iRow, iNum) => {
            if (iRow.count > 0) amount_m_i += iRow.amount_i * iRow.count;
        });
        amount_m_s = this.formatCurrency(amount_m_i, '');

        if (amount_m_i > this.state.saldo_kas) {
            this.setState({ exceedAmountError: true });
        } else {
            this.setState({ exceedAmountError: false });
        }

        arrDenoms[idxData] = rowData;
        this.setState({ [name]: arrDenoms });
        this.setState({ cd_amount_int: amount_m_i });
        this.setState({ cd_amount_string: amount_m_s });
    };

    formatCurrency(numToFormat, prefix) {
        var separator = '.';
        var number_string = numToFormat.toString();
        var split = number_string.split(',');
        var rem = split[0].length % 3;
        var rupiah = split[0].substr(0, rem);
        var ribuan = split[0].substr(rem).match(/\d{3}/gi);

        if (ribuan) {
            separator = rem ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix === undefined ? rupiah : rupiah ? 'Rp. ' + rupiah : '';
    }

    postSetorKhasanah = async () => {
        if (this.state.cd_amount_int === null || this.state.cd_amount_int <= 0) {
            this.setState({
                showModalWarning: true,
                messageWarning: 'Jumlah Penarikan < 0',
            });
            return false;
        }

        if (this.state.no_referensi === null || this.state.no_referensi == '') {
            this.setState({
                showModalWarning: true,
                messageWarning: 'No. Referensi harus diisi',
            });
            return false;
        }

        let body = {
            id_user: this.props.keycloak.idTokenParsed.preferred_username,
            keterangan: this.state.keterangan,
            no_referensi: this.state.no_referensi,
            valuta: this.state.valuta,
            nilai_ekuivalen: this.state.nilai_ekuivalen,
            jumlah: this.state.cd_amount_int * this.state.nilai_ekuivalen,
            nilai_transaksi: this.state.cd_amount_int,
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };

        fetch(baseUrl + '/teller/transaksi/kas/setor-khasanah/create', requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                if (data.message_id === '00') {
                    const balance = await this.getSaldo();

                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                        saldo_kas: balance,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                    });
                }
            });
    };

    render() {
        return (
            <>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Kas Teller</li>
                        <li className="breadcrumb-item">
                            <a href="#">Setor Kas ke Khasanah</a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h5 className="card-title">SETOR KAS KE KHASANAH</h5>
                            </div>
                            <div className="card-body">
                                <div className="bns-form">
                                    <div className="form-group">
                                        <label htmlFor="">Jumlah Setoran</label>
                                        <Form.Control
                                            value={this.state.cd_amount_string}
                                            name="cd_amount_string"
                                            onChange={(e) => {
                                                this.handleInputChange(e);
                                            }}
                                            readOnly
                                            isInvalid={this.state.exceedAmountError}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Jumlah setoran melebihi jumlah saldo yang ada
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Saldo Kas Teller</label>
                                        <div className="form-row">
                                            <div className="col-2 pr-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value="IDR"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-10 pl-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={new Intl.NumberFormat('id-ID').format(
                                                        this.state.saldo_kas
                                                    )}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">No.Referensi</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={this.state.no_referensi}
                                            name="no_referensi"
                                            onChange={(e) => {
                                                this.handleInputChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div>
                                            <label htmlFor="">Keterangan</label>
                                            <Label className="tw-ml-1" size="mini">
                                                Wajib
                                            </Label>
                                        </div>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={this.state.keterangan}
                                            name="keterangan"
                                            onChange={(e) => {
                                                this.handleInputChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group text-right">
                                        <button
                                            onClick={this.handleBatal}
                                            className="btn btn-outline-secondary"
                                        >
                                            Batal
                                        </button>
                                        <button
                                            disabled={
                                                this.state.keterangan?.length === 0 ||
                                                this.state.exceedAmountError
                                            }
                                            className="btn btn-success ml-3 px-5"
                                            onClick={() => {
                                                this.setState({ showModalConfirm: true });
                                            }}
                                        >
                                            Proses
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-body">
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Informasi Teller</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="bns-form">
                                    <div className="form-group">
                                        <label htmlFor="">Valuta Transaksi</label>
                                        <div className="form-row">
                                            <div className="col-2 pr-0">
                                                <select name="" id="" className="form-control">
                                                    <option value="">IDR</option>
                                                </select>
                                            </div>
                                            <div className="col-10 pl-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value="INDONESIAN RUPIAH"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Posisi Saldo Teller</label>
                                        <div className="form-row">
                                            <div className="col-2 pr-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value="IDR"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-10 pl-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value=""
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="row mt-4 mb-2 px-4 py-2">
                                    <div className="col-auto text-primary">Denominasi</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Nilai Denominasi</th>
                                                <th>Jenis</th>
                                                <th>Jumlah Lembar/Koin</th>
                                                <th>Total Nominal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.arrDenoms.map((d, i) => (
                                                <tr>
                                                    <td>{d.amount_s}</td>
                                                    <td>{d.type}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            name="denom_count"
                                                            value={d.count}
                                                            onChange={(e) => {
                                                                this.calcDenom(e, i);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <span name="denom_sum">{d.amount_m}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.showModalConfirm}
                    onHide={() => this.setState({ showModalConfirm: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Lakukan proses Setor Kas Khasanah?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirm: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.postSetorKhasanah();
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSuccess}
                    onHide={() => this.setState({ showModalSuccess: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Selesai</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Proses Setor Kas Khasanah berhasil dilakukan</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalSuccess: false });
                                this.props.history.push(
                                    '/informasi-transaksi/informasi-transaksi-hari-ini'
                                );
                            }}
                        >
                            Selesai
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalError}
                    onHide={() => this.setState({ showModalError: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageError}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalError: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalWarning}
                    onHide={() => this.setState({ showModalWarning: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageWarning}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalWarning: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default withKeycloak(SetorKas);
