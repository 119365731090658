import React, { memo, useRef } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { Tab, Nav, Modal, Button } from 'react-bootstrap';
import { baseUrl } from './../../../app.config';
import queryString from 'query-string';

class RegistrasiBadanUsaha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            svsData: [],
            svsParams: [
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
            ],
            isLoading: false,
            showModalInformation: false,
            infoData: {},
        };
    }

    componentDidMount() {
        this.getData();
        this.getBadanUsaha();
    }

    handleFileChange = (event, index) => {
        // console.log(event);
        const svsParams = [...this.state.svsParams];
        svsParams[index] = {
            data: {
                ...svsParams[index].data,
                filename:
                    'https://i0.wp.com/bagiinfo.com/wp-content/uploads/2017/01/tanda-tangan-candra.jpg?resize=680%2C498',
            },
            options: {
                ...svsParams[index].options,
                selectedFile: event.target.files[0],
                selectedFileUrl: URL.createObjectURL(event.target.files[0]),
                isChoosedFile: true,
            },
        };
        this.setState((prevState) => ({
            svsParams: svsParams,
        }));
    };

    handleFileRemove = (index) => {
        const svsParams = [...this.state.svsParams];
        svsParams[index] = {
            data: {
                nomor_cif: '',
                encoded_key_document: 'string',
                filename: '',
                image_tag: '',
                remark: '',
                type: 'Nasabah Badan Usaha',
            },
            options: {
                isChoosedFile: false,
                selectedFile: null,
                selectedFileUrl: null,
            },
        };
        this.setState((prevState) => ({
            svsParams: svsParams,
        }));
    };

    handleTextChanged = (event, index) => {
        const value = event.target.value;
        const name = event.target.name;
        const svsParams = [...this.state.svsParams];
        svsParams[index] = {
            data: {
                ...svsParams[index].data,
                [name]: value,
            },
            options: {
                ...svsParams[index].options,
            },
        };
        this.setState((prevState) => ({
            svsParams: svsParams,
        }));
    };

    getData = () => {
        const queryParams = queryString.parse(this.props.location.search);
        let id = queryParams.id;
        let url =
            queryParams.from === 'local'
                ? `/nasabah/svs/local/find-all/${id}`
                : `/nasabah/svs/mambu/find/${id}`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let svsParams = [...this.state.svsParams];
                let dataList = queryParams.from === 'local' ? data.list : data.list?.db?.list;
                // // console.log(dataList);
                if (dataList && dataList.length > 0) {
                    dataList.map(
                        (value, index) =>
                            (svsParams[index] = {
                                data: {
                                    nomor_cif: value.nomor_cif,
                                    encoded_key_document: value.encoded_key_document,
                                    filename: value.filename,
                                    signed_url: value.signed_url,
                                    image_tag: value.image_tag,
                                    remark: value.remark,
                                    type: value.type,
                                },
                                options: {
                                    isChoosedFile: false,
                                    selectedFile: null,
                                    selectedFileUrl: null,
                                },
                            })
                    );
                }
                this.setState({
                    svsData: dataList,
                    svsParams: svsParams,
                });
            });
    };

    getBadanUsaha = () => {
        const queryParams = queryString.parse(this.props.location.search);
        let id = queryParams.id;

        let getUrl =
            queryParams.from === 'local'
                ? '/nasabah-badan-usaha/lokal/nasabah/cari-db-id?db_id=' + id
                : '/nasabah-badan-usaha/find/' + id + '?detailsLevel=FULL';

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + getUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                this.setState({
                    infoData: data.list,
                });
            });
    }

    postSVS = async () => {
        this.state.svsParams.map((item, index) => {
            if (item.options.selectedFile !== null) {
                const queryParams = queryString.parse(this.props.location.search);
                let id = queryParams.id;
                let formData = new FormData();
                formData.append(queryParams.from === 'local' ? 'owner_db_id' : 'nomor_cif', id);
                formData.append('image_tag', item.data.image_tag);
                formData.append('remark', item.data.remark);
                formData.append('file', item.options.selectedFile);
                const requestOptions = {
                    method: 'POST',
                    body: formData,
                };
                let url =
                    queryParams.from === 'local'
                        ? '/nasabah/svs/single-upload'
                        : '/nasabah/svs/single-upload/approved';
                fetch(baseUrl + url, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        // console.log(data);
                        if (data.message_id === '00') {
                            this.setState({
                                showModalInformation: true,
                                message: data.db.status,
                                isLoading: false,
                            });
                        } else {
                            this.setState({
                                showModalInformation: true,
                                message: data.db.status,
                                isLoading: false,
                            });
                        }
                    });
            }
        });
    };

    render() {
        return (
            <>
                <div className="">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mt-2 mb-3">
                            <li className="breadcrumb-item color-primary">Nasabah / CIF</li>
                            <li className="breadcrumb-item">Data Nasabah / CIF</li>
                            <li className="breadcrumb-item">Nasabah Korporat</li>
                            <li className="breadcrumb-item">
                                Informasi Data (SVS) Rekening Nasabah Korporat
                            </li>
                        </ol>
                    </nav>

                    <div className="card">
                        <div className="card-body">
                            <div className="box">
                                <h3>{this.state.infoData?.groupName}</h3>
                                <div className="row">
                                    <div className="col-4">
                                        {/* <div>
                                            <span className="text-grey">No. Rekening</span>
                                            <span className="ml-3 mr-1">:</span>
                                            <span className="text-grey2">
                                                {this.state.infoData?.groupName}
                                            </span>
                                        </div> */}
                                        <div>
                                            <span className="text-grey">No. CIF</span>
                                            <span className="ml-3 mr-1">:</span>
                                            <span className="text-grey2">
                                                {this.state.infoData?.id}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div>
                                            <span className="text-grey">No. NPWP</span>
                                            <span className="ml-3 mr-1">:</span>
                                            <span className="text-grey2">
                                                {
                                                    this.state.infoData?._identitasNasabah
                                                        ?.nasabahNomorNpwp
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className="col-3">
                                        <div>
                                            <span className="text-grey">Telepon Kantor 1</span>
                                            <span className="ml-3 mr-1">:</span>
                                            <span className="text-grey2">
                                                {
                                                    this.state.infoData?._identitasNasabah
                                                        ?.nasabahNomorNpwp
                                                }
                                            </span>
                                        </div>
                                    </div> */}
                                    <div className="col-3">
                                        <div>
                                            <span className="text-grey">Alamat</span>
                                            <span className="ml-3 mr-1">:</span>
                                            <span className="text-grey2">
                                                {
                                                    this.state.infoData?._identitasNasabah
                                                        ?.nasabahAlamat
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body p0">
                            <Tab.Container defaultActiveKey="accountImages">
                                <div className="col-12 p-4">
                                    <Nav fill justify variant="pills" className="svs-nav">
                                        <Nav.Item>
                                            <Nav.Link eventKey="accountImages">
                                                Account Images
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="newImages">New Images</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                        <Nav.Link eventKey="deletedImages">Deleted Images</Nav.Link>
                                    </Nav.Item> */}
                                    </Nav>
                                </div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="accountImages">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Image Tag</th>
                                                    <th>Taq Sequence</th>
                                                    <th>Remark</th>
                                                    <th>Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.svsData && this.state.svsData.length > 0
                                                    ? this.state.svsData.map((data, index) => {
                                                          return (
                                                              <tr
                                                                  key={`listsaccountimages${index}`}
                                                              >
                                                                  <td>{data?.image_tag}</td>
                                                                  <td>{index + 1}</td>
                                                                  <td>{data?.remark}</td>
                                                                  <td>{data?.type}</td>
                                                              </tr>
                                                          );
                                                      })
                                                    : null}
                                            </tbody>
                                        </table>
                                        <div className="p-3">
                                            <div className="row">
                                                {}
                                                {this.state.svsData && this.state.svsData.length > 0
                                                    ? this.state.svsData.map((data, index) => {
                                                          return (
                                                              <div
                                                                  className="col-4"
                                                                  key={`listsaccountimagessign${index}`}
                                                              >
                                                                  <p>
                                                                      Image {index + 1} -{' '}
                                                                      {data?.image_tag}
                                                                  </p>
                                                                  <div className="svs-box">
                                                                      {/* <button className="btn btn-sm btn-outline-secondary px-4 float-right text-danger">
                                                                      Delete
                                                                  </button> */}
                                                                      <div className="mt-5 text-center">
                                                                          <img
                                                                              src={data?.signed_url}
                                                                              height={110}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <p className="border p-2 mt-2 fs-12">
                                                                      {data?.remark}
                                                                  </p>
                                                              </div>
                                                          );
                                                      })
                                                    : null}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="newImages">
                                        <div className="p-4">
                                            <div className="row">
                                                {this.state.svsParams &&
                                                this.state.svsParams.length > 0
                                                    ? this.state.svsParams.map((data, index) => {
                                                          return (
                                                              <div
                                                                  className="col-6"
                                                                  key={`newimagesdata${index}`}
                                                              >
                                                                  <p className="mt-2">
                                                                      Image {index + 1}
                                                                  </p>
                                                                  <div className="row px-3">
                                                                      <div className="col-12 svs-box-new-images">
                                                                          <div className="row">
                                                                              <div className="col-6">
                                                                                  {data.options
                                                                                      .isChoosedFile ? (
                                                                                      <div className="text-center">
                                                                                          <img
                                                                                              className="img-fluid"
                                                                                              src={
                                                                                                  data
                                                                                                      .options
                                                                                                      ?.selectedFileUrl
                                                                                              }
                                                                                          />
                                                                                      </div>
                                                                                  ) : (
                                                                                      <div className="text-center">
                                                                                          <img
                                                                                              className="img-fluid"
                                                                                              src={
                                                                                                  data
                                                                                                      ?.data
                                                                                                      ?.signed_url
                                                                                              }
                                                                                          />
                                                                                      </div>
                                                                                  )}
                                                                              </div>
                                                                              <div className="col-6 svs-border-left">
                                                                                  <div className="form-group mt-2">
                                                                                      <label htmlFor="">
                                                                                          Image Tag
                                                                                      </label>
                                                                                      <select
                                                                                          name="image_tag"
                                                                                          className="form-control"
                                                                                          onChange={(
                                                                                              e
                                                                                          ) =>
                                                                                              this.handleTextChanged(
                                                                                                  e,
                                                                                                  index
                                                                                              )
                                                                                          }
                                                                                          value={
                                                                                              data
                                                                                                  ?.data
                                                                                                  ?.image_tag
                                                                                          }
                                                                                      >
                                                                                          <option value="">
                                                                                              Pilih
                                                                                              Image
                                                                                              Tag
                                                                                          </option>
                                                                                          <option value="sign">
                                                                                              Tanda
                                                                                              Tangan
                                                                                          </option>
                                                                                      </select>
                                                                                  </div>
                                                                                  <div className="form-group">
                                                                                      <label htmlFor="">
                                                                                          Remark
                                                                                      </label>
                                                                                      <input
                                                                                          type="text"
                                                                                          className="form-control"
                                                                                          placeholder="Masukkan remark"
                                                                                          value={
                                                                                              data
                                                                                                  ?.data
                                                                                                  ?.remark
                                                                                          }
                                                                                          name="remark"
                                                                                          onChange={(
                                                                                              e
                                                                                          ) =>
                                                                                              this.handleTextChanged(
                                                                                                  e,
                                                                                                  index
                                                                                              )
                                                                                          }
                                                                                      />
                                                                                  </div>
                                                                                  <div className="form-group">
                                                                                      <div className="row">
                                                                                          <div className="col-7">
                                                                                              <input
                                                                                                  type="file"
                                                                                                  ref={`fileUploader${index}`}
                                                                                                  style={{
                                                                                                      display:
                                                                                                          'none',
                                                                                                  }}
                                                                                                  onChange={(
                                                                                                      e
                                                                                                  ) =>
                                                                                                      this.handleFileChange(
                                                                                                          e,
                                                                                                          index
                                                                                                      )
                                                                                                  }
                                                                                              />
                                                                                              <button
                                                                                                  onClick={() => {
                                                                                                      this.refs[
                                                                                                          'fileUploader' +
                                                                                                              index
                                                                                                      ].click();
                                                                                                  }}
                                                                                                  className="btn btn-orange-primary btn-block"
                                                                                              >
                                                                                                  Pilih
                                                                                                  File
                                                                                              </button>
                                                                                          </div>
                                                                                          <div className="col-5">
                                                                                              <button
                                                                                                  className="btn btn-outline-secondary btn-block"
                                                                                                  onClick={() =>
                                                                                                      this.handleFileRemove(
                                                                                                          index
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  Cancel
                                                                                              </button>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          );
                                                      })
                                                    : null}
                                            </div>
                                            <div className="text-right mt-3">
                                                <button
                                                    onClick={() => {
                                                        this.setState({ isLoading: true });
                                                        this.postSVS();
                                                    }}
                                                    className="btn btn-primary"
                                                    disabled={this.state.isLoading}
                                                >
                                                    {!this.state.isLoading
                                                        ? 'Simpan'
                                                        : 'Mohon Menunggu...'}
                                                </button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="deletedImages">
                                        <div>Deleted images</div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showModalInformation}
                    onHide={() => this.setState({ showModalInformation: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.message}
                        <p>Mohon menunggu untuk proses otorisasi data</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalInformation: false });
                                window.location.reload();
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default RegistrasiBadanUsaha;
