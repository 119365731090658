import { Modal } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { TableList } from '../../../../controls';
import useDataWic, { WicProvider } from './useDataWic';
import { useHistory } from 'react-router-dom';
import useKeycloak from '../../../../../hooks/keycloak.module';

function DataWICpage() {

    const { wicAction, wicState } = useDataWic();
    let history = useHistory();
    const { keycloak } = useKeycloak()
    useEffect(function () {
        wicAction.auditTrail()
    }, [])
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">WIC</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Data WIC
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-12">
                    {/* <div className="card">
                        <div className="card-body"> */}
                    <TableList
                        navtable={"DATA WIC"}
                        onChangeShow={wicAction.changeShow}
                        onNextPage={wicAction.nextPage}
                        onPrevPage={wicAction.prevPage}
                        // data={wicState.dataList}
                        // withActions={true}
                        // filter={true}
                        // formsFilter={[
                        //     {
                        //         type:"text",
                        //         placeholder:"No Rekening",
                        //         onChange:(e) => wicAction.setFilter(prevState => ({ ...prevState, nomor_rekening:e})),
                        //         value:wicState.filter?.nomor_rekening
                        //     }
                        // ]}
                        maxHeight="400px"
                        // onFilter={
                        //     wicAction.resetPagination
                        // }
                        // onResetFilter={wicAction.resetFilter}
                        actionItems={[
                            {
                                name: 'Lihat Detail WIC',
                                withDivider: true,
                                onClick: (e) => {
                                    let state = { dbId: e.nomor_cif }
                                    history.push(`/wic/form/detil`, state);
                                },
                            },
                            ...(keycloak.hasRealmRole("teller") ? [

                                {
                                    name: 'Ubah Data',
                                    withDivider: true,
                                    onClick: (e) => {
                                        let state = { dbId: e.nomor_cif }
                                        history.push(`/wic/form/edit`, state);
                                    },
                                },
                            ] : []),
                            ...(keycloak.hasRealmRole("cs") ? [
                                {
                                    name: 'WIC to Nasabah',
                                    withDivider: true,
                                    onClick: (e) => {
                                        let state = { dbId: e.nomor_cif }
                                        history.push(`/nasabah-cif/form-perorangan/editwic`, state);
                                    },
                                }
                            ] : []),

                        ]}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: 'Nomor CIF',
                                field: 'nomor_cif',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Nama Nasabah',
                                field: 'nama',
                                maxWidth: '150px',
                            },
                            // {
                            //     name: 'Alamat',
                            //     field: 'alamat',
                            //     maxWidth: '250px',
                            // },
                            {
                                name: 'Jenis Nasabah',
                                field: 'jenis_nasabah',
                                maxWidth: '250px',
                            },
                            // {
                            //     name: 'Jenis Identitas',
                            //     field: 'jenis_identitas',
                            //     maxWidth: '150px',
                            // },
                            {
                                name: 'Nomor Identitas',
                                field: 'nomor_identitas',
                                maxWidth: '150px',
                            },
                            // {
                            //     name: 'Profile Resiko',
                            //     field: 'profile_resiko',
                            //     maxWidth: '150px',
                            // },
                            {
                                name: 'Status',
                                field: 'status',
                                maxWidth: '150px',
                            }

                        ]}
                        data={wicState.dataList}
                        isLoading={wicState.isLoading}
                        filter={true}
                        formsFilter={[
                            {
                                type: 'text',
                                placeholder: 'Keyword',
                                disabled: false,
                                readonly: false,
                                value: wicState.filter?.keyword,
                                onChange: (value) => wicAction.setFilter(prevState => ({ ...prevState, keyword: value }))
                            }
                        ]}
                        onFilter={() => wicAction.getDataList()
                        }
                        onResetFilter={() => {
                            wicAction.resetFilter()
                        }}
                        resetFilter={false}
                        submitButton="Submit"

                    />
                    {/* </div>
                    </div> */}
                </div>
            </div>
            {/* modal Alert */}
            <Modal show={wicState.dataAlert.show} //onHide={wicState.closeAlert} 
                backdrop="static"
            >
                {
                    wicState.dataAlert.header !== null && wicState.dataAlert.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {wicState.dataAlert.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <h5 style={{ textAlign: "center", margin: "5px", textTransform: "capitalize" }}>{wicState.dataAlert.title}</h5>
                            <div className="box">
                                <p>
                                    {wicState.dataAlert.body}
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            <button onClick={() => {
                                wicAction.closeAlert();
                                if (wicState.dataAlert.callBack !== null && wicState.dataAlert.callBack !== undefined) wicState.dataAlert.callBack();
                            }
                            } className="btn btn-primary btn-sm">
                                OK
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal alert */}
            {/* modal confirm */}
            <Modal show={wicState.dataConfirm.show}

                backdrop="static"
            >
                {
                    wicState.dataConfirm.header !== null && wicState.dataConfirm.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {wicState.dataConfirm.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {/* <h4 style={{ textAlign: "center" }}>{wicState.dataConfirm.title}</h4> */}

                            {/* <div className="box"> */}
                            <h5>
                                {wicState.dataConfirm.body}
                            </h5>
                            {
                                wicState.dataConfirm.witTextArea && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm">
                                                <label>Keterangan :</label>
                                                <textarea
                                                    className="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {/* </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            {
                                wicState
                                    .dataConfirm
                                    .closeButton && <button onClick={wicState.closeAlert} className="btn btn-secondary btn-sm float-right mr-2">
                                    Close
                                </button>
                            }
                            {
                                wicState
                                    .dataConfirm
                                    .footerButton?.map(
                                        ({
                                            className = "",
                                            onClick = () => null,
                                            style = {},
                                            name = "Ok" },
                                            i) => <button
                                                key={i}
                                                className={`${className} float-right mr-2`}
                                                onClick={onClick}
                                                style={style}
                                            >
                                                {name}
                                            </button>
                                    )
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal confirm */}
        </>
    );
}


export default () => <WicProvider><DataWICpage /></WicProvider>