import clsx from 'clsx';
import { useState, useEffect, useMemo } from 'react';
import { FormControl, Modal, Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { TableGrid } from '../../../../controls';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { baseUrl } from '../../../../../app.config';
import { IconPrinter } from '../../../../../icon';
import ExportCsv from '../../../../controls/exportCsv/export-csv';
import { toast } from 'react-toastify';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistrasi } from '../../../registrasi_deposito/formRegistrasi';

const PencairanDepositoPage = () => {
    let history = useHistory();
    const [rejectModal, setRejectModal] = useState(false);
    const [approvedModal, setApprovedModal] = useState(false);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState();
    const [status, setStatus] = useState('');
    const [userId, setUserId] = useState('');
    const [rekening, setRekening] = useState('');
    const [noRef, setNoRef] = useState('');
    const [nama, setNama] = useState('');
    const [keterangan, setKeterangan] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').toDate());
    const [csvData, setCsvData] = useState([]);
    const [detailModal, setDetailModal] = useState(false);
    const [detail, setDetail] = useState([]);
    const [rekap, setRekap] = useState([]);
    const [cabang, setCabang] = useState([]);
    const [dates, setDates] = useState({
        start: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
    });

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(
                baseUrl +
                    `/deposito/pencairan?page=${currentPage}&limit=${limit}&dateBefore=${dayjs(
                        startDate
                    ).format('DD-MM-YY')}&dateAfter=${dayjs(endDate).format('DD-MM-YY')}`
            );

            const data = await res.json();

            setData(data.list);

            const csvData = data.list?.map((item) => {
                return {
                    Tanggal: 'item.created',
                    // 'Nomor Rekening': item.deposito_id,
                    // Valuta: '',
                    // 'Nama Rekening': item.name,
                    // 'Nilai Pencairan': 'Rp. ' + item.nilai_pencairan.toLocaleString(),
                };
            });

            setCsvData(csvData);
        };

        fetchData();
    }, [currentPage, limit, status, endDate, startDate]);

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    //Action for reject transaction modal
    const rejectDeposito = () => {
        setRejectModal(false);
        setDetailModal(false);
        const body = {
            userId: userId.toString(),
            action: 'REJECT',
            note: keterangan,
            nomorRekening: rekening,
            nomorReferensi: noRef,
        };
        return fetch(baseUrl + `/deposito/pencairan/approval`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id === '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else if (status.message_id === '01') {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    const approvedAction = () => {
        setApprovedModal(false);
        setDetailModal(false);
        const body = {
            userId: userId.toString(),
            action: 'APPROVAL',
            note: keterangan,
            nomorRekening: rekening,
            nomorReferensi: noRef,
        };
        return fetch(baseUrl + `/deposito/pencairan/approval`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id === '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else if (status.message_id === '01') {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    const handleSearch = () => {
        setStartDate(dates.start);
        setEndDate(dates.end);
    };

    const handleSeeDetail = async (nomorRekening = 1) => {
        setDetailModal(true);

        const depositoDetail = await (
            await fetch(baseUrl + `/deposito/find/${nomorRekening}?detailsLevel=FULL`)
        ).json();

        const rekapTransaksi = await (
            await fetch(baseUrl + `/deposito/transaksi/rekap/${nomorRekening}`)
        ).json();

        const rekeningTabungan = await (
            await fetch(
                baseUrl +
                    `/rekening/tabungan/approve/${depositoDetail?.list?._bagiHasilDisposisi?.nomorRekening}/detail`
            )
        ).json();

        const cabang = await (
            await fetch(
                baseUrl +
                    `/rekening/tabungan/kantor/cabang/${rekeningTabungan.list?.assignedBranchKey}/detail`
            )
        ).json();

        setRekap(rekapTransaksi.list);
        setDetail({
            ...depositoDetail?.list,
            _bagiHasilDisposisi: {
                ...depositoDetail?.list?._bagiHasilDisposisi,
                namaRekening: rekeningTabungan?.list?.name,
            },
        });
        setCabang(cabang.list);
    };

    const handleReset = () => {
        setDates({
            start: dayjs().format('YYYY-MM-DD'),
            end: dayjs().format('YYYY-MM-DD'),
        });
        setStartDate(new Date());
        setEndDate(new Date());
    };

    return (
        <>
            <Modal centered size="xl" show={Boolean(detailModal)}>
                <Modal.Header
                    closeButton
                    onHide={() => {
                        setDetailModal(false);
                    }}
                >
                    <Modal.Title>
                        <div className="tw-text-18 tw-font-bold">Detail Pencairan Deposito</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="row"
                        style={{
                            padding: '0px 20px',
                            marginBottom: '30px',
                        }}
                    >
                        <div className="col-md-12">
                            <label
                                style={{
                                    fontSize: '12px',
                                }}
                            >
                                Nomor Deposito
                            </label>
                            <br />
                            <div
                                style={{ gridTemplateColumns: '1fr auto auto' }}
                                className="tw-grid tw-items-start"
                            >
                                <Form.Group className="tw-mr-2" bsPrefix=" ">
                                    <Form.Control
                                        value={detail?.nomorRekeningDeposito}
                                        // onChange={(e) => setNomorDeposito(e.target.value)}
                                        disabled
                                        className="tw-w-full"
                                        size="sm"
                                        type="number"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <FormRegistrasi
                        itemsForm={[
                            {
                                name: 'Info Deposito',
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Nama Nasabah',
                                        type: 'text',
                                        placeholder: 'nama nasabah',
                                        disabled: true,
                                        value: detail?._dataNasabah?.nama || '-',
                                    },
                                    {
                                        rows: 1,
                                        label: 'Cabang Rekening',
                                        type: 'text',
                                        placeholder: 'Cabang Rekening',
                                        disabled: true,
                                        readonly: false,
                                        value: cabang?.id + ' - ' + cabang?.name,
                                        onChange: (value) => null,
                                    },
                                    {
                                        rows: 1,
                                        label: 'Valuta Rekening',
                                        type: 'text',
                                        placeholder: 'Valuta Rekening',
                                        disabled: true,
                                        value: detail?._rekening?.valuta || '-',
                                    },
                                    {
                                        rows: 2,
                                        label: 'Nominal Deposito',
                                        type: 'text',
                                        placeholder: 'Nominal Deposito',
                                        disabled: true,
                                        value:
                                            detail?.balances?.totalBalance.toLocaleString() || '-',
                                    },
                                    {
                                        rows: 2,
                                        type: 'custom',
                                        component: (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        {createForm({
                                                            label: 'Tanggal Buka',
                                                            type: 'date',
                                                            disabled: true,
                                                            value: dayjs(
                                                                detail?._rekening?.tanggalBuka
                                                            ).format('YYYY-MM-DD'),
                                                        })}
                                                    </div>
                                                    <div className="col-md-6">
                                                        {createForm({
                                                            label: 'Jatuh Tempo',
                                                            type: 'date',
                                                            disabled: true,
                                                            value: dayjs(
                                                                detail?._rekening?.tanggalJatuhTempo
                                                            ).format('YYYY-MM-DD'),
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                    },
                                    {
                                        rows: 2,
                                        type: 'custom',
                                        component: (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        {createForm({
                                                            label: 'ARO',
                                                            type: 'date',
                                                            placeholder: 'ARO',
                                                            disabled: true,
                                                            readonly: false,
                                                            onChange: (value) => null,
                                                        })}
                                                    </div>
                                                    <div className="col-md-6">
                                                        {createForm({
                                                            label: 'ARO Ke',
                                                            type: 'text',
                                                            placeholder: 'ARO Ke',
                                                            disabled: true,
                                                            readonly: false,
                                                            onChange: (value) => null,
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                    },
                                ],
                            },
                            {
                                name: 'Perhitungan Pembayaran Ke Nasabah',
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Nominal',
                                        type: 'text',
                                        placeholder: 'Nominal',
                                        disabled: true,
                                        readonly: false,
                                        value: rekap?.nominalPokok?.toLocaleString() || '-',
                                        onChange: (value) => null,
                                    },
                                    {
                                        rows: 1,
                                        label: 'Pinalty Pencairan',
                                        type: 'text',
                                        placeholder: 'Pinalty Pencairan',
                                        disabled: true,
                                        readonly: false,
                                        value: rekap?.pinalti?.toLocaleString() || '-',
                                        onChange: (value) => null,
                                    },
                                    {
                                        rows: 1,
                                        label: 'Cadangan Bagi Hasil Kapilatisir',
                                        type: 'text',
                                        placeholder: 'Cadangan Bagi Hasil Kapilatisir',
                                        disabled: true,
                                        readonly: false,
                                        value: rekap?.cadanganBagiHasil?.toLocaleString() || '-',
                                        onChange: (value) => null,
                                    },
                                    {
                                        rows: 2,
                                        label: 'Total Pemotongan Pajak',
                                        type: 'text',
                                        placeholder: 'Total Pemotongan Pajak',
                                        disabled: true,
                                        readonly: false,
                                        value: rekap?.pajak?.toLocaleString() || '-',
                                    },
                                    {
                                        rows: 2,
                                        label: 'Total Pemotongan Zakat',
                                        type: 'text',
                                        placeholder: 'Total Pemotongan Zakat',
                                        disabled: true,
                                        readonly: false,
                                        value: rekap?.zakat?.toLocaleString() || '-',
                                    },
                                    {
                                        rows: 2,
                                        label: 'Nilai Minimal Yang Harus Dibayarkan',
                                        type: 'text',
                                        placeholder: 'Nilai Minimal Yang Harus Dibayarkan',
                                        disabled: true,
                                        readonly: false,
                                        value:
                                            rekap?.nilaiMinimumDibayarkan?.toLocaleString() || '-',
                                    },
                                ],
                            },
                            {
                                name: 'Disposisi',
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Nilai Yang Dibayarkan Pada Deposan',
                                        type: 'text',
                                        placeholder: 'Nilai Yang Dibayarkan Pada Deposan',
                                        value:
                                            rekap?.nilaiMinimumDibayarkan?.toLocaleString() || '-',
                                        disabled: true,
                                        readonly: false,
                                        onChange: (value) => null,
                                    },

                                    {
                                        rows: 1,
                                        label: 'Disposisi',
                                        type: 'select',
                                        placeholder:
                                            detail?._bagiHasilDisposisi?.instruksiDisposisi ??
                                            'Pilih Disposisi',
                                        disabled: true,
                                        // onChange: (e) => setDisposisi(e),
                                        // value: disposisi,
                                        // options: disposisiOpt?.map(
                                        //     ({ reference_desc, reference_code }) => ({
                                        //         display: reference_desc,
                                        //         // key: `${reference_code} - ${reference_desc}`,
                                        //         key: reference_desc,
                                        //     })
                                        // ),
                                    },
                                    {
                                        rows: 1,
                                        type: 'custom',
                                        component: (
                                            <>
                                                <div className="row" style={{ paddingLeft: 7 }}>
                                                    <div className="col-md-12">
                                                        <label
                                                            style={{
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            Valuta Pencairan
                                                        </label>
                                                        <br />
                                                        <div className="form-group form-group-sm form-inline">
                                                            <input
                                                                disabled={true}
                                                                className="form-control form-control-sm col-md-4 mr-2"
                                                                value={'IDR'}
                                                            />
                                                            <input
                                                                disabled={true}
                                                                className="form-control form-control-sm col mr-2"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                    },
                                    {
                                        rows: 1,
                                        type: 'custom',
                                        component: (
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-x-5 tw-px-2">
                                                <div>
                                                    <Form.Label className="f-12">
                                                        Nomor Rekening
                                                    </Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        disabled
                                                        value={
                                                            detail?._rekening?.nomorRekeningDebet
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <Form.Label className="f-12">
                                                        Nama Rekening / GL
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        size="sm"
                                                        value={
                                                            detail?._bagiHasilDisposisi
                                                                ?.namaRekening
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    },
                                    {
                                        rows: 2,
                                        type: 'custom',
                                        component: (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        {createForm({
                                                            label: 'Kurs Jual',
                                                            type: 'text',
                                                            placeholder: 'Kurs Jual',
                                                            disabled: true,
                                                            value: 1,
                                                        })}
                                                    </div>
                                                    <div className="col-md-6">
                                                        {createForm({
                                                            label: 'Kurs Beli',
                                                            type: 'text',
                                                            placeholder: 'Kurs Beli',
                                                            disabled: true,
                                                            value: 1,
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                    },

                                    {
                                        rows: 2,
                                        label: 'Kurs Buku',
                                        type: 'text',
                                        placeholder: 'Kurs Buku',
                                        disabled: true,
                                        readonly: false,
                                        value: 1,
                                        onChange: (value) => null,
                                    },

                                    {
                                        rows: 2,
                                        label: 'Nilai Ekuivalen',
                                        type: 'text',
                                        placeholder: 'Nilai Ekuivalen',
                                        value: rekap?.nilaiEkuivalen?.toLocaleString() || '-',
                                        disabled: true,
                                    },
                                ],
                            },
                        ]}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                        onClick={() => setDetailModal(false)}
                    >
                        Tidak Proses
                    </Button>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        basic
                        color="red"
                        onClick={() => {
                            setRejectModal(true);
                        }}
                    >
                        Tolak
                    </Button>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        basic
                        color="green"
                        onClick={() => {
                            setApprovedModal(true);
                        }}
                    >
                        Setujui
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(approvedModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setApprovedModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Setujui Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <p>
                        Yakin menyetujui otorisasi pencairan deposito dengan nama rekening{' '}
                        <b>{nama}</b> ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setApprovedModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={approvedAction}
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(rejectModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setRejectModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tolak Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <FormControl
                        as="textarea"
                        placeholder="Masukkan keterangan penolakan"
                        onChange={(e) => setKeterangan(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={rejectDeposito}
                    >
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Deposito
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-grid tw-grid-cols-2 tw-gap-0">
                    <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                        Pencairan Deposito
                    </div>
                    <div className="tw-flex tw-text-12 tw-justify-end tw-p-7">
                        <ExportCsv csvData={csvData} fileName="Data Pencairan Deposito" />
                        <button className="border-0 tw-bg-transparent">
                            <IconPrinter className="tw-mr-5" />
                            <span className="tw-text-primary">Cetak</span>
                        </button>
                    </div>
                </div>
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <input
                                className="tw-mr-4"
                                size="sm"
                                placeholder="dd-mm-yy"
                                type="date"
                                onChange={(e) => setDates({ ...dates, start: e.target.value })}
                                value={dates.start}
                            />
                            <input
                                className="tw-mr-4"
                                size="sm"
                                placeholder="dd-mm-yy"
                                type="date"
                                onChange={(e) => setDates({ ...dates, end: e.target.value })}
                                value={dates.end}
                            />
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                            <Button
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                                onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </div>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Tanggal',
                            field: 'created',
                            width: '130px',
                        },
                        {
                            name: 'Nomor Rekening',
                            field: 'nomorRekening',
                            width: '140px',
                        },
                        {
                            name: 'Valuta',
                            field: 'valuta',
                            width: '100px',
                        },
                        {
                            name: 'Nama Rekening',
                            field: 'namaRekening',
                            width: '140px',
                        },
                        {
                            name: 'Nilai Pencairan',
                            field: 'nilaiPencairan',
                            width: '200px',
                        },
                        {
                            field: 'action',
                        },
                    ]}
                    data={data || []}
                    onRenderField={(value, field, { className, data }) => {
                        // console.log(data?.pencairanDeposito, 'value');
                        if (field === 'created') {
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {
                                        (value = dayjs(data?.pencairanDeposito?.created).format(
                                            'DD-MM-YYYY'
                                        ))
                                    }
                                </div>
                            );
                        }
                        if (field === 'nilaiPencairan') {
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {
                                        (value =
                                            'Rp. ' +
                                            data?.pencairanDeposito?.nilaiPencairan.toLocaleString())
                                    }
                                </div>
                            );
                        }
                        if (field === 'nomorRekening') {
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {(value = data?.pencairanDeposito?.nomorRekening)}
                                </div>
                            );
                        }
                        if (field === 'valuta') {
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {(value = data?.pencairanDeposito?.valuta)}
                                </div>
                            );
                        }
                        if (field === 'namaRekening') {
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {(value = data?.pencairanDeposito?.namaRekening)}
                                </div>
                            );
                        }
                        if (field === 'action')
                            return (
                                <Action
                                    onDetail={() => {
                                        handleSeeDetail(data?.pencairanDeposito?.nomorRekening);
                                    }}
                                    onApproved={() => {
                                        setApprovedModal(true);
                                        setUserId(data?.pencairanDeposito?.id);
                                        setRekening(data?.pencairanDeposito?.nomorRekening);
                                        setNama(data?.pencairanDeposito?.namaRekening);
                                        setNoRef(data?.pencairanDeposito?.nomorReferensi);
                                    }}
                                    onReject={() => {
                                        setRejectModal(true);
                                        setUserId(data?.pencairanDeposito?.id);
                                        setRekening(data?.pencairanDeposito?.nomorRekening);
                                        setNama(data?.pencairanDeposito?.namaRekening);
                                        setNoRef(data?.pencairanDeposito?.nomorReferensi);
                                    }}
                                    key={`${value}-${field}`}
                                />
                            );
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
            </div>
        </>
    );
};

export default PencairanDepositoPage;

const Action = ({ onApproved, onReject, onDetail }) => {
    const [show, setShow] = useState(false);

    const handleApproved = () => {
        if (onApproved) onApproved();

        setShow(false);
    };
    const handleReject = () => {
        if (onReject) onReject();

        setShow(false);
    };

    const handleDetail = () => {
        if (onDetail) onDetail();

        setShow(false);
    };

    return (
        <div>
            <Button basic color="red" size="mini" onClick={handleReject}>
                Tolak
            </Button>
            <Button basic color="green" className="tw-mr-3" size="mini" onClick={handleApproved}>
                Setujui
            </Button>
            <button type="button" class="btn btn-link" onClick={handleDetail}>
                Lihat Detail
            </button>
        </div>
    );
};
