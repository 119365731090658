import React, { memo, useEffect, useRef, useState } from 'react';
import { AppAction, AppInterfaces } from '../../../app.data';
import { createForm } from '../../../module/createFormList';
import { FormRegistrasi } from './formRegistrasi';
import Step1 from './step1';
import Step2 from './step2';
import { Stepper } from '../../controls';
import { useHistory, useLocation } from 'react-router';

export default AppInterfaces.appLoad(memo(function ({store, ...props}) {
    const { formData } = store;
    const [step, setStep] = useState(0);
    const location = useLocation();
    const history = useHistory();
    const appAction = useRef() 
    const [statusDone, setStatusDone] = useState({
        show: false,
        msg:""
    })
    const steps =
        [
            {
                name: 'Data Deposito',
                id: '0',
                whiteSpace: 'nowrap',
                isDone: false,
            },
            {
                name: 'Informasi Lain',
                id: '1',
                whiteSpace: 'nowrap',
                isDone: false,
            },
        ];

    const stepperReff = useRef(null)
    const [err, setErr] = useState({ msg: 'Lengkapi form registrasi', err: true })
    
    const fieldRequired = ["nomorCIF","statusKelengkapan", "statusRestriksi"]

    useEffect(function(){
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function(key){
            if(formData[key] instanceof Object){
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if(fieldRequired.some(v=>element === v)){
                        if(formData[key][element] === "" || !formData[key][element]=== undefined || !formData[key][element]=== null ){
                            errS = true
                        }
                    }
                });
            }else{
                if(fieldRequired.some(v=>v === key)){
                    if(formData[key] === "" || !formData[key]=== undefined || !formData[key]=== null ){
                        errS = true
                    }
                }
            }
        })
        setErr(prevState => ({...prevState, err:errS}))
    }, [formData])
    useEffect(function(){
        appAction.current.doGet({url : "/depositproducts", vari:"deposito_products", param: { 
            detailsLevel:"FULL",
        }})
        appAction
            .current
            .doGet({
                url : `/deposito/lokal/akun/cari-db-id`, 
                vari:"detil_deposito",
                param:{
                    db_id:location.state?.db_id
                }
            }).then(({  status, message_id, list }) => message_id === "00" ? appAction.current.setDefaultForm(list) : history.goBack())
    }, [])

    return (
        <>
            <AppAction ref={appAction} />
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Deposito</a></li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">Ubah Deposito</li>
                </ol>
            </nav>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col p-3">
                            <div style={{width:"200px", margin:"auto"}}>
                                <Stepper 
                                    activeIdx = {step}
                                    manualDone = {false}
                                    // onChange = {(_activeIdx, _prevIdx) => {
                                    //     setStep(_activeIdx)
                                    // }}
                                    ref={stepperReff}
                                    onStepClick = {(_idx) => {
                                        setStep(_idx)
                                    }}
                                    steps={steps}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                   {
                       step === 0 ? <Step1 editMode={true} /> : <Step2 editMode={true} />
                   }

                </div>
                <div className="card-footer">
                    <div className="float-right">
                    <button disabled={step === 0 } onClick={()=>setStep(prevStep => prevStep - 1 )} className="btn btn-sm btn-secondary mr-3">
                            Sebelumnya
                        </button>
                        <button disabled={step === 1} onClick={()=>setStep(prevStep => prevStep + 1)} className="btn btn-sm btn-danger mr-3">
                            Selanjutnya
                        </button>
                        {
                            step === 1 && (
                                <button onClick={
                                   
                                    ()=>appAction.current.setShowModal({
                                        show:true,
                                        size:'sm',
                                        backdrop:'static',
                                        header:<h4>{statusDone.show ? "Selesai" : "Konfirmasi" }</h4>,
                                        body:<>
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                {statusDone.show ? statusDone.msg : " Lakukan proses Registrasi Deposito?" }
                                                </div>
                                            </div>
                                        </>,
                                        footer:(
                                            <>
                                                <button onClick={()=>{
                                                    appAction.current.setCloseModal()
                                                    setStatusDone({
                                                        show:false,
                                                        msg:""
                                                    })
                                                }} className="btn btn-sm btn-secondary">
                                                {statusDone.show ? "Selesai" : "Tidak" }
                                                </button>
                                                {
                                                    !statusDone.show && <button 
                                                        onClick={
                                                            ()=>err.err ? 
                                                            appAction.current.setShowModal({
                                                                show: true,
                                                                size: 'sm',
                                                                backdrop: 'static',
                                                                header: <h4>Warning</h4>,
                                                                body: <>
                                                                    <div className="row">
                                                                        <div className="col-md-12 text-center">
                                                                            {err.msg}
                                                                        </div>
                                                                    </div>
                                                                </>,
                                                                footer: (
                                                                    <>
                                                                        <button onClick={() => {
                                                                            appAction.current.setCloseModal()
                                                                        }} className="btn btn-sm btn-secondary">
                                                                            close
                                                                        </button>
                                                                    </>
                                                                )
                                                            }) : appAction.current.doPut({
                                                                uri:"/deposito/lokal/akun/perbaharui",
                                                                data:store.formData
                                                            }).then(resp => {
                                                                let { status, message_id } = resp
                                                                if(message_id === "00" ) status ="Proses Ubah Data Deposito berhasil dilakukan"
                                                                appAction.current.setCloseModal()
                                                                appAction.current.setShowModal({
                                                                    show:true,
                                                                    size:'sm',
                                                                    backdrop:'static',
                                                                    header:<h4>{statusDone.show ? "Selesai" : "Konfirmasi" }</h4>,
                                                                    body:<>
                                                                        <div className="row">
                                                                            <div className="col-md-12 text-center">
                                                                            {status}
                                                                            </div>
                                                                        </div>
                                                                    </>,
                                                                    footer:(
                                                                        <>
                                                                            <button onClick={()=>{
                                                                                appAction.current.setCloseModal()
                                                                                history.push("/deposito/data")
                                                                            }} className="btn btn-sm btn-secondary">
                                                                                selesai
                                                                            </button>
                                                                        </>
                                                                    )
                                                                })
                                                            })
                                                        }
                                                    className="btn btn-sm btn-primary">
                                                        Ya
                                                    </button>
                                                }
                                            </>
                                        )
                                    })
                                } className="btn btn-sm btn-primary">
                                    Simpan
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}));