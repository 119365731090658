import { FormControl, Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import dayjs from 'dayjs';
import { Breadcrumb, Button, Label } from 'semantic-ui-react';
import { debounce } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { TableGrid } from '../../controls';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { baseUrl } from '../../../app.config';
import Select from 'react-select';

const QueryGLPage = () => {
    const [rekeningGL, setRekeningGL] = useState({});
    const [nomorGL, setNomorGL] = useState('');
    const [error, setError] = useState({});
    const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [lists, setLists] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const pageCount = useMemo(() => {
        if (lists?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [lists, currentPage, limit]);

    const { data: glOptions, isLoading } = useQuery('kode-gl-options', async () => {
        const res = await fetch(baseUrl + '/teller/gl-account/all');

        const data = await res.json();

        return Array.from(data.list || [], ({ kode_gl, name }) => ({
            label: `${kode_gl} - ${name}`,
            value: `${kode_gl} - ${name}`,
        }));
    });

    const getKodeGL = (value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/teller/general-ledger/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list[0];
                    setRekeningGL(dataDetail);
                }
                // else {
                //     setError({ ...error, kode_gl: true });
                // }
            });
    };
    const [fields, setFields] = useState({
        pusatBiaya: '',
        valutaAsal: '',
    });

    // eslint-disable-next-line
    const findGL = useCallback(
        debounce(async (value, callback) => {
            if (!value) {
                setRekeningGL({});
                callback('no value');
                return;
            }

            const res = await fetch(baseUrl + `/teller/general-ledger/${value}`);

            const data = await res.json();

            if (!data.list) {
                setRekeningGL({});
                if (callback) callback('error');
                return;
            }

            setRekeningGL(data?.list[0]);
            if (callback) callback('success');
        }, 500),
        []
    );

    const handleSearchGL = useCallback(
        async (value) => {
            // const { value } = e.target;
            setNomorGL(value);

            await findGL(value, (status) => {
                if (status === 'error' && value > 0)
                    setError({ ...error, rekeningGL: true, notFoundGL: false });
                else setError({ ...error, rekeningGL: false, notFoundGL: false });
            });
        },
        [error, findGL]
    );

    const handleSearchJournal = useMutation(
        async ({ page = currentPage, limitPage = limit }) => {
            const res = await fetch(
                baseUrl +
                    `/journal/entries/?start_date=${startDate}&end_date=${endDate}&gl_account_id=${rekeningGL.kode_gl}&page=${page}&limit=${limitPage}`
            );

            const data = await res.json();

            if (data.message_id === '01') {
                toast.warning(
                    `Tidak ada data yang tersedia, coba gunakan tanggal sebelum ${dayjs(
                        startDate
                    ).format('DD-MM-YYYY')}`,
                    {
                        pauseOnFocusLoss: false,
                        pauseOnHover: false,
                        position: 'bottom-left',
                    }
                );
                setLists([]);
            }

            return data.list;
        },
        {
            onSuccess: (data) => {
                if (!data) return;

                const newLists = Array.from(
                    data || [],
                    ({ bookingDate, entryID, type, amount, glAccount }) => ({
                        tanggalEfektif: dayjs(bookingDate).format('DD-MM-YYYY'),
                        noReferensi: entryID,
                        mutasi: type,
                        nominal: new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: glAccount?.currency?.code ?? 'IDR',
                        }).format(amount),
                    })
                );

                setLists(newLists);
            },
        }
    );

    const handleChangeSelect = (e) => {
        const { value, name } = e.target;
        const newFields = {
            [name]: value,
        };

        setFields(newFields);
    };

    const handleChangeGL = (e) => {
        const value = e.value.split(' - ');
        getKodeGL(value[0]);
        setNomorGL(e ? e.label : '');
    };

    const { data: pusatBiaya } = useQuery('pusatBiaya', async () => {
        const res = await fetch(baseUrl + '/dropdowns?key=PUSATBIAYA');
        const data = await res.json();
        return data.list;
    });

    const handleReset = () => {
        setEndDate(dayjs().format('YYYY-MM-DD'));
        setStartDate(dayjs().format('YYYY-MM-DD'));
        setRekeningGL({
            kode_gl: '',
            cabang_gl: '',
            nama_gl: '',
            valuta_gl: '',
        });
        setNomorGL(null);
        setLists([]);
        setError({});
        setFields({ pusatBiaya: '', valutaAsal: '' });
    };

    return (
        <>
            <Breadcrumb className="tw-mb-5 tw-text-12">
                <Breadcrumb.Section className="text-orange" link>
                    Query
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Query GL</Breadcrumb.Section>
            </Breadcrumb>
            <div style={{ minHeight: '80vh' }} className="tw-bg-white tw-flex tw-flex-col">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Riwayat Transaksi Buku Besar
                </div>
                <div
                    style={{ borderTop: '1px solid #f2f2f2', borderBottom: '1px solid #f2f2f2' }}
                    className="tw-p-6"
                >
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-grid tw-grid-cols-4 tw-gap-x-4 tw-gap-y-6 tw-items-start">
                        <div>
                            <div>
                                <Form.Label className="tw-text-12 tw-font-normal tw-mr-1">
                                    Nomor GL <Label size="mini">Wajib</Label>
                                </Form.Label>
                                <Select
                                    className="tw-mr-4"
                                    size="sm"
                                    value={glOptions?.filter((x) => x.label === nomorGL)}
                                    options={glOptions}
                                    onChange={handleChangeGL}
                                    isLoading={isLoading}
                                    styles={{
                                        control: (base) => ({ ...base, fontSize: 12 }),
                                        menu: (base) => ({ ...base, fontSize: 12 }),
                                    }}
                                />
                            </div>
                            {/* <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Nomor GL"
                                onChange={handleSearchGL}
                                isInvalid={error.rekeningGL || error.notFoundGL}
                                value={nomorGL}
                                type="text"
                            /> */}
                            <FormControl.Feedback type="invalid">
                                {error.notFoundGL
                                    ? 'Field wajib diisi'
                                    : ' Rekening GL tidak dapat ditemukan'}
                            </FormControl.Feedback>
                        </div>
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">Nama</Form.Label>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                disabled
                                placeholder="Otomatis terisi"
                                value={rekeningGL.nama_gl || ''}
                            />
                        </div>
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">
                                Kantor Cabang
                            </Form.Label>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                disabled
                                placeholder="Otomatis terisi"
                                value={rekeningGL.cabang_gl || ''}
                            />
                        </div>
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">
                                Kode Valuta
                            </Form.Label>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                disabled
                                placeholder="Otomatis terisi"
                                value={rekeningGL.valuta_gl || ''}
                            />
                        </div>
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">
                                Valuta Asal
                            </Form.Label>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                as="select"
                                value={fields?.valutaAsal}
                                name="valutaAsal"
                                onChange={handleChangeSelect}
                            >
                                <option valuta="" selected hidden>
                                    Pilih valuta asal
                                </option>
                                <option value="IDR">IDR</option>
                            </FormControl>
                        </div>
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">
                                Pusat Biaya
                            </Form.Label>
                            <Form.Control
                                value={fields?.pusatBiaya?.toUpperCase()}
                                onChange={handleChangeSelect}
                                name="pusatbiaya"
                                size="sm"
                                as="select"
                            >
                                <option value="" selected hidden>
                                    Pilih pusat biaya
                                </option>
                                {pusatBiaya?.map(({ reference_desc, id }) => (
                                    <option key={id} value={reference_desc?.toUpperCase()}>
                                        {reference_desc}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                        {/* <div>
                            <Form.Label className="tw-text-12 tw-font-normal">Produk</Form.Label>
                            <FormControl className="tw-mr-4" size="sm" as="select">
                                <option selected hidden disabled>
                                    Pilih produk
                                </option>
                            </FormControl>
                        </div> */}
                    </div>
                </div>
                <div className="tw-p-6 tw-grid tw-gap-4 tw-grid-cols-4 tw-items-end">
                    <div>
                        <Form.Label className="tw-text-12 tw-font-normal">Tanggal Awal</Form.Label>
                        <FormControl
                            value={startDate}
                            onChange={(e) => {
                                const { value } = e.target;

                                const compareDate =
                                    dayjs(value).toISOString() > dayjs(endDate).toISOString();

                                if (compareDate) {
                                    setEndDate(value);
                                }

                                setStartDate(value);
                            }}
                            type="date"
                            size="sm"
                            max={dayjs().format('YYYY-MM-DD')}
                        />
                    </div>
                    <div>
                        <Form.Label className="tw-text-12 tw-font-normal">Tanggal Akhir</Form.Label>
                        <FormControl
                            value={endDate}
                            onChange={(e) => {
                                setEndDate(e.target.value);
                            }}
                            min={dayjs(startDate).format('YYYY-MM-DD')}
                            type="date"
                            size="sm"
                            max={dayjs().format('YYYY-MM-DD')}
                        />
                    </div>
                    <div className="tw-grid tw-auto-cols-max tw-grid-flow-col tw-gap-3 tw-items-center">
                        <Button
                            onClick={handleReset}
                            className="tw-text-14 tw-py-2"
                            size="mini"
                            basic
                            color="blue"
                        >
                            Reset
                        </Button>
                        <Button
                            className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                            size="mini"
                            color="blue"
                            onClick={() =>
                                handleSearchJournal.mutate({ page: currentPage, limitPage: limit })
                            }
                        >
                            Cari
                        </Button>
                    </div>
                </div>
                <TableGrid
                    isLoading={handleSearchJournal.isLoading}
                    columns={[
                        {
                            name: 'Tanggal Efektif',
                            field: 'tanggalEfektif',
                            width: '120px',
                        },
                        {
                            name: 'No Referensi',
                            field: 'noReferensi',
                            width: '120px',
                        },
                        {
                            name: 'ID Petugas',
                            field: 'idPetugas',
                            width: '120px',
                        },
                        {
                            name: 'Kode Transaksi',
                            field: 'kodeTransaksi',
                            width: '120px',
                        },
                        {
                            name: 'Source',
                            field: 'source',
                            width: '80px',
                        },
                        {
                            name: 'Mutasi',
                            field: 'mutasi',
                            width: '120px',
                        },
                        {
                            name: 'Nominal (Rp)',
                            field: 'nominal',
                            textAlign: 'right',
                            width: '0.6fr',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                        },
                    ]}
                    data={lists}
                    onRenderField={(value, field) => {
                        if (field === 'nominal') {
                            return (
                                <div className="tw-text-right" key={`${value}-${field}`}>
                                    {value}
                                </div>
                            );
                        }
                    }}
                    className="tw-mb-6"
                    config={{
                        height: '40vh',
                        overflowY: 'auto',
                        stickyHead: true,
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-grid tw-grid-cols-3 tw-items-center tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            onChange={(e) => {
                                setLimit(Number(e.target.value));
                                handleSearchJournal.mutate({
                                    page: currentPage,
                                    limitPage: Number(e.target.value),
                                });
                            }}
                            as="select"
                            size="sm"
                            className="tw-border-0 tw-w-max"
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        containerClassName="pagination tw-text-12 tw-m-0 tw-justify-self-center"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-cursor-pointer focus:tw-outline-none tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        initialPage={0}
                        onPageChange={({ selected }) => {
                            setCurrentPage(selected);
                            if (nomorGL)
                                handleSearchJournal.mutate({ page: selected, limitPage: limit });
                        }}
                    />
                    <div className="tw-text-12 tw-text-right">Menampilkan 1 data</div>
                </div>
            </div>
        </>
    );
};

export default QueryGLPage;
