import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode'
import useFetch from './useFetch';
import { baseUrl, baseUrlAuth } from '../app.config';
import keycloak, { keycloakConfig } from '../keycloak.config';
import { useModalAlert } from '../components/controls/modalAlert';
import moment from 'moment';
import * as uuid from 'uuid'

const keycloakContext = createContext({
    keycloak: {
        authenticated: false,
        authServerUrl: "",
        realm: "",
        endpoints: {},
        refreshToken: "",
        refreshTokenParsed: {
        },
        login: () => null,
        logout: () => null,
        idToken: "",
        idTokenParsed: {

        },
        loadUserInfo: () => null,
        hasRealmRole: (role = "") => null,
        hasRoles: (role = []) => null,
        token: "",
        tokenParsed: {},
        sessionId: "",
        subject: "",
        statusBranch: {
            status_bds:"C"
        },
        realmAccess: {
            roles: [

            ]
        },
        setStatusBranch: ()=> null,
        loadUserProfile: () => null,
        userInfo: {
            branch_code: 536,
            sub: "",
            email_verified: false,
            name: "",
            preferred_username: "",
            given_name: "",
            family_name: ""
        },
        access_token_exp: 0,
        access_token_exp_date: null,
        refresh_token_exp: 0,
        refresh_token_exp_date: null,
        refToken: ()=>null,
        updateUID: () => null,
        uid: ""
    }
    , initialized: false
});

const cookie = new Cookies();

export function KeycloakProv({ children }) {
    const [dataToken, setDataToken] = useState({
        refreshToken: "",
        refreshTokenParsed: {},
        idToken: "",
        idTokenParsed: {},
        token: "",
        tokenParsed: {},
        sessionId: ""
    })
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [authenticated, setAuth] = useState(false);
    const [initialized, setInit] = useState(false);
    const { wait, doFetch, createUrlForm, getStatusBranch, doGet} = useFetch();
    const [roles, setRoles] = useState([])
    const [userInfo, setUserInfo] = useState({

    })
    const [tokenExp, setTokenExp] = useState({
        access_token_exp: 0,
        access_token_exp_date: null,
        refresh_token_exp: 0,
        refresh_token_exp_date: null,
    })
    const [statusBranch, setStatusBranch] = useState({});
    let interVal;
    const [uid, setUid] = useState("")
    // function createAutoRefresh(){
    //     interVal =  setInterval(refreshToken, 30)
    // }

    // async function getdatastatus(withLoading = false){
    //     withLoading && openLoading()
    //         try {
    //             let resp = await doGet({
    //                 url: "/verifikasi/info-branch?branch_code",
    //                 service: "trx",
    //                 param: {
    //                     branch_code: dataToken.tokenParsed.branch_code
    //                 }
    //             })
    //             // // console.log(resp)
    //             if (resp.statusCode === 200) {
    //                 if (resp.status === "00") {
    //                     setStatusBranch(resp.data)
    //                 } else setDataAlert({ show: true, body: resp.remark, title: ``, header: "Info", callBack: () => null });
    //             } else setDataAlert({ show: true, body: resp.remark, title: ``, header: "Info", callBack: () => null });
    //         } catch (error) {
    //             setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
    //         }
    //         closeLoading()
    // }

    // useEffect(function(){
    //     if(authenticated) getdatastatus();
    // },[authenticated])

    const [activity, setActivity] = useState(null)
    useEffect(function () {
        checkUserLogin()
    }, [])

    
    function createListenIdle() {
        document.addEventListener("click", function () {
            setActivity(moment(Date.now()))
        })
        document.addEventListener("mouseover", function () {
            setActivity(moment(Date.now()))
        })
        document.addEventListener("keyup", function () {
            setActivity(moment(Date.now()))
        })
        // createAutoRefresh()
    }

    function stopListenIdle() {
        document.removeEventListener("*", () => {

        })
        // clearInterval(interVal);
    }

    function cleanUp() {
        stopListenIdle();
        createListenIdle();
    }

    async function logout() {
        cookie.remove("access_token");
        cookie.remove("expires_in");
        cookie.remove("refresh_expires_in");
        cookie.remove("refresh_token");
        cookie.remove("session_state");
        cookie.remove("scope");
        setAuth(false);
        setDataToken({
            refreshToken: "",
            refreshTokenParsed: "",
            idToken: "",
            idTokenParsed: "",
            token: "",
            tokenParsed: "",
            sessionId: ""
        });
        // window.location.href="/"
        stopListenIdle();
        clearInterval(interVal)
    }


    async function checkUserLogin() {

        setInit(false);
        let {
            access_token,
            expires_in,
            refresh_expires_in,
            refresh_token,
            token_type,
            session_state,
            scope
        } = cookie.getAll();

        if (
            access_token === undefined || access_token === null || access_token === "" ||
            expires_in === undefined || expires_in === null || expires_in === "" ||
            refresh_expires_in === undefined || refresh_expires_in === null || refresh_expires_in === "" ||
            session_state === undefined || session_state === null || session_state === "" ||
            refresh_token === undefined || refresh_token === null || refresh_token === ""
        ) {
            setAuth(false)
            cookie.remove("access_token");
            cookie.remove("expires_in");
            cookie.remove("refresh_expires_in");
            cookie.remove("refresh_token");
            cookie.remove("session_state");
            cookie.remove("scope");
            setAuth(false);
            setDataToken({
                refreshToken: "",
                refreshTokenParsed: "",
                idToken: "",
                idTokenParsed: "",
                token: "",
                tokenParsed: "",
                sessionId: ""
            });
            setUserInfo({})
            stopListenIdle();
        } else {
            setAuth(true)
            setDataToken({
                refreshToken: refresh_token,
                refreshTokenParsed: jwt_decode(refresh_token),
                idToken: access_token,
                idTokenParsed: jwt_decode(access_token),
                token: access_token,
                tokenParsed: jwt_decode(access_token),
                sessionId: session_state
            });
            setTokenExp({
                refresh_token_exp: refresh_expires_in,
                access_token_exp: expires_in,
                access_token_exp_date: moment(Date.now()).add(expires_in, "second"),
                refresh_token_exp_date: moment(Date.now()).add(refresh_expires_in, "second")
            })
            cleanUp()
        }
        setInit(true)
        //  // // console.log(token)
    }
    useEffect(function () {
        let ro = []
        if (dataToken.token !== null && dataToken.token !== undefined && dataToken.token !== "") {
            let tokenParsed = jwt_decode(dataToken.token);
            ro = tokenParsed?.realm_access?.roles || []
            setUserInfo(tokenParsed)
        }
        setRoles(ro);
    }, [dataToken])

    async function login({
        access_token,
        expires_in,
        refresh_expires_in,
        refresh_token,
        token_type,
        session_state,
        scope
    }) {
        if (
            access_token === undefined || access_token === null || access_token === "" ||
            expires_in === undefined || expires_in === null || expires_in === "" ||
            refresh_expires_in === undefined || refresh_expires_in === null || refresh_expires_in === "" ||
            session_state === undefined || session_state === null || session_state === "" ||
            refresh_token === undefined || refresh_token === null || refresh_token === ""
        ) { } else {
            setInit(false)
            cookie.set('session_state', session_state);
            cookie.set('access_token', access_token);
            cookie.set('refresh_token', refresh_token);
            cookie.set('expires_in', expires_in);
            cookie.set('refresh_expires_in', refresh_expires_in);
            cookie.set('token_type', token_type);
            cookie.set('scope', scope);
            // cleanUp();
            setDataToken({
                refreshToken: refresh_token,
                refreshTokenParsed: jwt_decode(refresh_token),
                idToken: access_token,
                idTokenParsed: jwt_decode(access_token),
                token: access_token,
                tokenParsed: jwt_decode(access_token),
                sessionId: session_state
            })
            setTokenExp({
                refresh_token_exp: refresh_expires_in,
                access_token_exp: expires_in,
                access_token_exp_date: moment(Date.now()).add(expires_in, "second"),
                refresh_token_exp_date: moment(Date.now()).add(refresh_expires_in, "second")
            })
            setAuth(true);
            setInit(true);
        }
    }
    useEffect(function () {
        // if (tokenExp.access_token_exp > 0 && authenticated) {
        //     interVal = setInterval(function () {
        //         // if(moment(activity).add(tokenExp.access_token_exp, "second") > moment(tokenExp.access_token_exp_date)){

        //         doRefreshToken()
        //         // }
        //     }, (60) * 1000)
        // } else {
        //     clearInterval(interVal)
        // }
        // return () => clearInterval(interVal)
    }, [tokenExp])

    function hasRealmRole(role = '') {
        // // // console.log
        if (typeof role !== "string") {
            throw "role must be string"
        }
        return roles.some(v => {
            return role === v
        })
    }

    function hasRoles(role = []) {
        if (!Array.isArray(role)) {
            throw "role must be array"
        }
        return role.some(v => hasRealmRole(v))
    }
    function loadUserInfo() {
        return userInfo;
    }
    function loadUserProfile() {
        return userInfo
    }

    useEffect(()=>{
        authenticated && updateUID()
        // setUid(uuid.v1());
    },[authenticated])

    async function doRefreshToken() {
        // // console.log("token refreshed", dataToken.idToken)
        let url = `${baseUrlAuth}/auth/realms/${keycloakConfig.realm}/protocol/openid-connect/token`;

        // let url = `http://172.27.170.45/auth/realms/bds/protocol/openid-connect/token`;
        let acc_token = "";
        try {
            const resp = await doFetch({
                // data: bodyData,s
                url,
                data: createUrlForm({
                    username: "",
                    password: "",
                    grant_type: "refresh_token",
                    client_id: keycloakConfig.clientId,
                    client_secret: "",
                    refresh_token: dataToken.refreshToken
                }),
                token: false,

                type: 2,
                // headers: {
                //     Authorization: `Bearer ${dataToken.token}`
                // }
            });
            // // // console.log(resp)
            const { statusCode, status,
                access_token, expires_in,
                refresh_expires_in, refresh_token,
                session_state, statusText = "", message_id = ""
            } = resp;

            if (statusCode === 200 || statusCode === 201) {
                cookie.set('session_state', session_state);
                cookie.set('access_token', access_token);
                cookie.set('refresh_token', refresh_token);
                cookie.set('expires_in', expires_in);
                cookie.set('refresh_expires_in', refresh_expires_in);
                // cleanUp();
                setDataToken({
                    refreshToken: refresh_token,
                    refreshTokenParsed: jwt_decode(refresh_token),
                    idToken: access_token,
                    idTokenParsed: jwt_decode(access_token),
                    token: access_token,
                    tokenParsed: jwt_decode(access_token),
                    sessionId: session_state
                })
                setTokenExp({
                    refresh_token_exp: refresh_expires_in,
                    access_token_exp: expires_in,
                    access_token_exp_date: moment(Date.now()).add(60, "second"),
                    refresh_token_exp_date: moment(Date.now()).add(refresh_expires_in, "second")
                })
                acc_token = access_token
            } //else if (statusCode === 401) setDataAlert({ show: true, body: "Sesi login habis. Silahkan login kembali", title: ``, header: "Info", callBack: () => logout() })
            // else setDataAlert({ show: true, body: statusText, title: ``, header: "Info", callBack: () => closeAlert() })
        } catch (error) {
            alert(error.toString())
            acc_token = ""
            //setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        return acc_token
    }

    async function getDataToken() {
        // // console.log(moment(activity).add(tokenExp.access_token_exp, "second") > moment(tokenExp.access_token_exp_date) ||
        //     (moment(Date.now()).add(tokenExp.access_token_exp-60) > moment(tokenExp.access_token_exp_date)))
        let token = cookie.get('access_token');
        if (
            moment(activity).add(tokenExp.access_token_exp, "second") > moment(tokenExp.access_token_exp_date) &&
            (moment(Date.now()) > moment(tokenExp.access_token_exp_date).subtract(60, "second") )
            // moment(activity).add(tokenExp.access_token_exp + 60, "second") > moment(tokenExp.access_token_exp_date) &&
            // moment(Date.now()).add(60, "second") > moment(tokenExp.access_token_exp_date)
        ) {
            token = await doRefreshToken();
        }
        else if((moment(Date.now()) < moment(tokenExp.refresh_token_exp_date)) &&  (moment(Date.now()) > moment(tokenExp.access_token_exp_date)) ) {
            token = await doRefreshToken();
        }

        return token
    }

    async function refToken() {

        if (
            // moment(activity).add(tokenExp.refresh_token_exp, "second") > moment(tokenExp.refresh_token_exp_date) &&
            (moment(Date.now()) < moment(tokenExp.refresh_token_exp_date))
            // moment(activity).add(tokenExp.access_token_exp + 60, "second") > moment(tokenExp.access_token_exp_date) &&
            // moment(Date.now()).add(60, "second") > moment(tokenExp.access_token_exp_date)
        ) {
            let token = await doRefreshToken();

            return token;
        } else {
            return false;
        }
    }

    async function updateUID(){
        let IDUnique = `${userInfo?.preferred_username || "NONE"}-${userInfo?.branch_code || "NONE"}-${uuid.v4()}`;
        if( authenticated ){
            let token = await getDataToken();
            try {
                let resp = await doFetch({
                    url:`${baseUrl}/trx/api/v1/uid_trx/get-uid-for-trx`,
                    token: false,
                    method:"GET",
                    data: null,
                    headers:{
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                })
                // console.log(resp)
                if(resp.statusCode === 401 || resp.statusCode === 403){
                    // console.log("go")
                    let refreshed = await refToken();
                    // console.log(refreshed)
                    if(!refreshed){
                        // setDataAlert({ show: true, body: "Maaf sesi login telah habis. silahkan login kembali", titile: `Session habis`, callBack: () => keycloak.logout() });
                        resp = { statusCode: 401, statusText: "Session Habis", remark: "Maaf sesi login telah habis. silahkan login kembali" }
                    }else{
                        resp = await doFetch({
                            url:`${baseUrl}/trx/api/v1/uid_trx/get-uid-for-trx`,
                            token: false,
                            method:"GET",
                            data: null,
                            headers:{
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${refreshed}`
                            }
                        })
                    }
                }
                let {statusCode, statusText, remark, data={}, status} = resp
                if(statusCode === 200){
                    if(status === "00"){
                        IDUnique = data?.uid || "";
                    }
                }else if( statusBranch === 401){

                }
                // if(resp.)
            } catch (error) {
                
            }
        }
        setUid(IDUnique);
        return IDUnique;
    }

    return (
        <keycloakContext.Provider
            value={{
                keycloak: {
                    login,
                    ...tokenExp,
                    refToken,
                    authenticated,
                    authServerUrl: "",
                    realm: "",
                    endpoints: {},
                    logout,
                    hasRealmRole,
                    hasRoles,
                    loadUserProfile,
                    statusBranch,
                    subject: "",
                    setStatusBranch,
                    realmAccess: {
                        roles
                    },
                    userInfo,
                    loadUserInfo,
                    getDataToken,
                    doRefreshToken,
                    updateUID,
                    uid,
                    ...dataToken
                }, initialized
            }}
        >
            {children}
        </keycloakContext.Provider>
    )
}

export default function useKeycloak() {
    return useContext(keycloakContext)
}