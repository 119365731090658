import { Breadcrumb, Button } from 'semantic-ui-react';
import { FormControl, FormLabel, InputGroup } from 'react-bootstrap';
import { FormHeader, TableGrid } from '../../../controls';
import clsx from 'clsx';

const SelsihPembulatanPage = () => {
    return (
        <>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Kas Teller
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Selisih Pembulatan</Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <div className="tw-bg-white tw-rounded-lg">
                    <h1 className="tw-text-18 tw-text-black tw-font-bold border-bottom tw-border-black-10 tw-p-5">
                        REVERSAL OTORISASI
                    </h1>
                    <div className="tw-py-4 tw-px-10">
                        <div className="tw-mb-4">
                            <FormLabel className="tw-font-normal">Valuta Kas</FormLabel>
                            <div style={{ gridTemplateColumns: '70px 1fr' }} className="tw-grid">
                                <FormControl size="sm" as="select">
                                    <option value="IDR">IDR</option>
                                </FormControl>
                                <FormControl value="INDONESIAN RUPIAH" disabled size="sm" />
                            </div>
                        </div>
                        <div className="tw-mb-4">
                            <FormLabel className="tw-font-normal">Saldo Kas</FormLabel>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>IDR</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    className="tw-text-right"
                                    value="7.400.000"
                                    disabled
                                    size="sm"
                                />
                            </InputGroup>
                        </div>
                        <div className="tw-mb-4">
                            <FormLabel className="tw-font-normal">Total Denominasi</FormLabel>
                            <div className="tw-flex tw-items-center">
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>IDR</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        className="tw-text-right"
                                        value="7.400.000"
                                        disabled
                                        size="sm"
                                    />
                                </InputGroup>
                                <Button
                                    className="ml-4 tw-px-9 tw-text-14 tw-py-2"
                                    color="orange"
                                    size="mini"
                                >
                                    Hitung
                                </Button>
                            </div>
                        </div>
                        <div className="tw-mb-4">
                            <FormLabel className="tw-font-normal">Selisih dengan Saldo</FormLabel>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>IDR</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    className="tw-text-right tw-text-red"
                                    value="-7.400.000"
                                    disabled
                                    size="sm"
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className="tw-bg-white tw-row-span-2 tw-rounded-lg">
                    <div className="tw-p-6">
                        <FormHeader>Denominasi</FormHeader>
                    </div>
                    <TableGrid
                        columns={[
                            {
                                name: 'Nilai Denominasi',
                                field: 'nilai_denominasi',
                                textAlign: 'center',
                            },
                            {
                                name: 'Jenis',
                                field: 'jenis',
                                textAlign: 'center',
                            },
                            {
                                name: 'Jumlah Lembar/Koin',
                                field: 'jumlah',
                                textAlign: 'center',
                            },
                            {
                                name: 'Total Nominal',
                                field: 'total_nominal',
                                textAlign: 'center',
                            },
                        ]}
                        data={[
                            {
                                nilai_denominasi: '100.000',
                                jenis: 'Lembar',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '50.000',
                                jenis: 'Lembar',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '25.000',
                                jenis: 'Lembar',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '10.000',
                                jenis: 'Lembar',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '5.000',
                                jenis: 'Lembar',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '2.000',
                                jenis: 'Lembar',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '1.000',
                                jenis: 'Lembar',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '500',
                                jenis: 'Lembar',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '1.000',
                                jenis: 'Koin',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '500',
                                jenis: 'Koin',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '200',
                                jenis: 'Koin',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '100',
                                jenis: 'Koin',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '50',
                                jenis: 'Koin',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '25',
                                jenis: 'Koin',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '10',
                                jenis: 'Koin',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '5',
                                jenis: 'Koin',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                            {
                                nilai_denominasi: '1',
                                jenis: 'Koin',
                                jumlah: '0',
                                total_nominal: '0',
                            },
                        ]}
                        config={{
                            stickyHead: true,
                            overflowY: 'scroll',
                            height: 600,
                        }}
                        onRenderField={(value, field, { className }) => {
                            return (
                                <div
                                    key={`${field}-${value[field]}`}
                                    className={clsx(className, 'tw-text-center tw-font-semibold')}
                                >
                                    {value}
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="tw-bg-white tw-p-6 tw-rounded-lg">
                    <FormHeader>Transaksi Selisih Pembulatan</FormHeader>
                    <div className="tw-p-4">
                        <div className="tw-mb-4">
                            <FormLabel className="tw-font-normal">Jenis Transaksi</FormLabel>
                            <FormControl disabled size="sm" as="select">
                                <option value="IDR">IDR</option>
                            </FormControl>
                        </div>
                        <div className="tw-mb-8">
                            <FormLabel className="tw-font-normal">Nilai Transaksi</FormLabel>
                            <FormControl disabled size="sm" value="IDR" />
                        </div>
                        <div className="tw-flex tw-justify-end">
                            <Button className="tw-mr-3">Batal</Button>
                            <Button className="tw-px-12" color="green">
                                Simpan
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelsihPembulatanPage;
