import React, { memo, useRef } from 'react';
import {BasePagePay} from '../../controls';
import { AppAction, AppInterfaces } from '../../../app.data';

const IconHeader = memo(function(){
    return (
        <>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.7986 3.60625C16.6408 5.3946 11.8493 8.92893 6.65893 9.91036C6.15515 9.99518 5.70209 10.2846 5.39536 10.7174C5.08864 11.1502 4.95223 11.6926 5.01492 12.2303C5.67974 17.3966 8.42261 30.6937 19.3352 35.04C19.7641 35.209 20.2359 35.209 20.6648 35.04C31.5783 30.6937 34.3222 17.3966 34.9851 12.2303C35.0478 11.6926 34.9114 11.1502 34.6046 10.7174C34.2979 10.2846 33.8449 9.99518 33.3411 9.91036C28.1507 8.92893 23.3592 5.3946 21.2014 3.60625C20.8584 3.32144 20.4354 3.16675 20 3.16675C19.5646 3.16675 19.1416 3.32144 18.7986 3.60625Z" fill="#FF6F00"/>
                <path d="M34.9851 12.2303C34.3222 17.3966 31.5783 30.6937 20.6648 35.04C30.4271 22.3876 33.3411 14.5834 20 3.16675C20.4354 3.16675 20.8584 3.32144 21.2014 3.60625C23.3592 5.3946 28.1507 8.92893 33.3411 9.91035C33.8449 9.99518 34.2979 10.2846 34.6046 10.7174C34.9114 11.1502 35.0478 11.6926 34.9851 12.2303Z" fill="#FFA040"/>
                <path d="M24.6079 17.1544H21.8164V14.5594C21.8164 14.4553 21.775 14.3554 21.7014 14.2817C21.6277 14.2081 21.5278 14.1667 21.4237 14.1667H18.5769C18.4727 14.1667 18.3729 14.2081 18.2992 14.2817C18.2256 14.3554 18.1842 14.4553 18.1842 14.5594V17.1544H15.3927C15.2886 17.1544 15.1887 17.1958 15.115 17.2694C15.0414 17.3431 15 17.443 15 17.5471V20.3937C15.0002 20.4978 15.0416 20.5976 15.1152 20.6712C15.1888 20.7448 15.2886 20.7862 15.3927 20.7864H18.1842V23.7741C18.1842 23.8782 18.2256 23.9781 18.2992 24.0518C18.3729 24.1254 18.4727 24.1667 18.5769 24.1667H21.4231C21.5273 24.1667 21.6271 24.1254 21.7008 24.0518C21.7744 23.9781 21.8158 23.8782 21.8158 23.7741V20.7864H24.6073C24.7114 20.7862 24.8112 20.7448 24.8848 20.6712C24.9584 20.5976 24.9998 20.4978 25 20.3937V17.5471C25 17.4431 24.9587 17.3433 24.8852 17.2697C24.8117 17.196 24.7119 17.1546 24.6079 17.1544Z" fill="white"/>
            </svg>

        </>
    )
})

const nom = [
    {
        id:1,
        providerId:1,
        value:10000,
        price:12000
    },{
        id:2,
        providerId:2,
        value:10000,
        price:12500,
    },{
        id:3,
        providerId:1,
        value:50000,
        price:51000,
    },{
        id:4,
        providerId:2,
        value:50000,
        price:53000,
    },{

        id:5,
        providerId:1,
        value:100000,
        price:105000,
    },
]

const AsuransiPage_base = memo(({store, ...props}) => {
    const appAction = useRef(null);

    return (
        <React.Fragment>
            <AppAction ref={appAction} />
            <BasePagePay
                options = {
                    [
                        {
                            display: "",
                            value: 2,
                            forms: [{
                                type:"select",
                                label:"Layanan Asuransi",
                                value:store.formData.providerId,
                                onChange:async (val)=> {
                                    const newNominal = await nom.filter(v => v.providerId === parseInt(val))
                                    appAction.current.setForm("providerId", val)
                                    appAction.current.disp({
                                        type:"setNominalList",
                                        nominalList:newNominal
                                    })
                                },
                                displayKey:"name",
                                valueKey:"id",
                                required:true,
                                readOnly:false,
                                options:[
                                    {
                                        name : "PREMI JIWASRAYA",
                                        id : 1,
                                    },
                                    {
                                
                                        name : "HEKSAINSURANCE",
                                        id : 2,
                                    },
                                    {
                                
                                        name : "PRUDENTAL (PREMI PRATAMA)",
                                        id : 3,
                                    },
                                    {
                                        name : "PRUDENTAL",
                                        id : 4,
                                    }
                                ]
                            },{
                                type: "text",
                                label: "No Pembayaran",
                                name: "ASURANSI",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange: (val) => appAction.current.setForm("no_pembayaran", val),
                                readOnly: false,
                            }],
                            selectNominal: true,
                            onSelectNominal: (val)=>{
                                appAction.current.setOpenPembayaran(true)
                                appAction.current.setNominal(val)
                            },
                            onPay: () => appAction.current.pay(),
                            // setNominal: setNominal,
                        }
                    ]
                }
                title ={<span className="px-0"><IconHeader /> ASURANSI</span>}
                nominals={nom}
                paymentCardData={{
                    detilBody: [
                        {
                            key: "Jenis Layanan",
                            value: "Asuransi-Premi Jiwasraya"
                        },{
                            key: "Nomor Pembayaran",
                            value: "2223-3321-4414-2441"
                        },{
                            key: "Bulan Pembayaran",
                            value: "Maret 2020"
                        },
                    ],
                    detilFooter: [
                        {
                            key: "Jumlah Tagihan",
                            value: 500000
                        },
                        {
                            key: "Admin",
                            value: 2000
                        }
                    ],
                    total: 502000
                }}
            />
        </React.Fragment>
    );
});

const AsuransiPage = AppInterfaces.appLoad(AsuransiPage_base)
export default AsuransiPage;