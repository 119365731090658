import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import useFetch from '../../../../hooks/useFetch'
// import { useKeycloak } from '@react-keycloak/web';
import { useModalAlert } from '../../../controls/modalAlert';
import { useLocation } from 'react-router';
import useKeycloak from '../../../../hooks/keycloak.module';

const revContext = createContext({
   revState:{}, revAction:{} 
});

export const RevProvider = ({ children }) => {

    const { doGet, doPost, wait } = useFetch();
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ tgl_awal : '', tgl_akhir:'' })
    const [dataList, setListDataRekening] = useState([])
   const [isLoading, setIsLoading] = useState(true)
    const [loadingDetil, setLoadingDetil] = useState(true);
    const [rekSelected, setRekSelected] = useState({});
    const { modalState, modalAction } = useModalAlert();
    const location = useLocation();
    const { keycloak } = useKeycloak();

    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    // useEffect GetData
    useEffect(function () {
        getDataList()
    }, [pagination])
    
    // getData rekening list
    async function getDataList() {
        setIsLoading(true)
        var url = `/informasi/transaksi-to-reversal`;
        // var url = `/informasi/transaksi-histori?limit=15&page=1&tgl_awal=01-10-2021&tgl_akhir=15-10-2021`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {limit:pagination.show, page:pagination.page 
                    // tgl_awal:moment(filter.tgl_awal).format("DD-MM-YYYY"),
                    // tgl_akhir:moment(filter.tgl_akhir).format("DD-MM-YYYY"),
                },
                service: "trx"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    // // console.log(data)
                    setListDataRekening(data);
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    function resetFilter() {
        setFilter({
            keyword: ''
        })
    }
    // next pagination
    function nextPage({ page, limit, tgl_awal, tgl_akhir }) {
        // console.log(page)
        setPagination({
            page,
            tgl_awal:tgl_awal,
            tgl_akhir:tgl_akhir,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit, tgl_awal, tgl_akhir }) {
        setPagination({
            page,
            tgl_awal:tgl_awal,
            tgl_akhir:tgl_akhir,
            show: limit
        })
    }
    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // response otorisasi
    function responseFinishTrx(item) {
        let body = `Apakah anda yakin ingin Menyelsaikan Transaksi BDS ini. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => postFinsihTrx(item), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function postFinsihTrx(item) {
        // // // console.log(item)
        openLoading();
        var url = `/verifikasiprocess-trx-pending`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "trx",
                data:{
                    uid: keycloak.uid
                },
                queryParam:{id_transaksi_bds:item, 
                    uid: keycloak.uid},
                method: 'PUT'
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Transaksi ke BDS Berhasil`, title: ``, header: "Success", callBack: () => {
                    closeConfirm();
                    getDataList();
                    // keycloak.updateUID()
                }});
               
                else setDataAlert({ show: true, body: `Belum ada data terpilih, PIlih data terlebih dahulu !`, titile: ``, header:remark, callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            closeConfirm();
        }
        closeLoading();
        closeConfirm();
    }

    return <revContext.Provider
        value={{
            revState: {
                dataList,
                isLoading,
                filter,
                loadingDetil,
                rekSelected,
            },
            revAction: {
                getDataList,
                nextPage,
                prevPage,
                changeShow,
                closeAlert,
                closeConfirm,
                setFilter,
                resetFilter,
                responseFinishTrx
            }
        }}
    >
        {children}
    </revContext.Provider>;
}


export default function useReversal() { return useContext(revContext) };