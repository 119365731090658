import { useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, Label } from 'semantic-ui-react';
import { baseUrl } from '../../../../app.config';
import { AppAction } from '../../../../app.data';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { GetErrorEmptyValue } from '../../../utils';

const BlokirRekeningModal = ({
    show,
    type = 'blokir',
    onHide,
    data,
    accountHolderType,
    onSuccess,
}) => {
    const queryClient = useQueryClient();
    const [confirm, setConfirm] = useState(false);
    const [state, setState] = useState({ notes: '' });
    const [error, setError] = useState({});

    const appAction = useRef(null);

    useEffect(() => {
        if (type === 'buka') setState((prevState) => ({ ...prevState, notes: 'buka blokir' }));
    }, [type]);

    const { data: form, isPreviousData } = useQuery(
        ['rekening-deposito-detail', data?.nomorDeposito, accountHolderType],
        async () => {
            const res = await fetch(
                baseUrl +
                    `/deposito/find/${data?.nomorDeposito}?detailsLevel=FULL&Accountholdertype=${accountHolderType}`
            );

            const resData = await res.json();

            return resData?.list;
        },
        {
            keepPreviousData: true,
        }
    );

    const { data: keteranganOptions } = useQuery('keterangan-options', async () => {
        const res = await fetch(baseUrl + '/dropdowns?key=eJenisBlokir');

        const data = await res.json();

        return data.list;
    });

    const formatNumber = (number) =>
        new Intl.NumberFormat('id-ID', {
            currency: form?._rekening?.valuta ?? 'IDR',
            style: 'currency',
        }).format(number);

    const handleBlokirRekening = useMutation(
        async () => {
            const body = {
                action: type === 'buka' ? 'UNBLOCK' : 'BLOCK',
                notes: state.notes,
            };

            const res = await fetch(baseUrl + `/blokir-rekening/${data?.nomorDeposito}`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const resData = await res.json();

            return resData;
        },
        {
            onSuccess: (data) => {
                setConfirm(false);
                queryClient.refetchQueries({ queryKey: 'rekening-deposito-detail' });

                if (data.message_id === '02') {
                    toast.error(
                        typeof data.status !== 'string' ? JSON.stringify(data.status) : data.status,
                        {
                            position: 'top-center',
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                        }
                    );
                    return;
                }

                if (onSuccess) onSuccess();
            },
        }
    );

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (error[name]) {
            setError({
                ...error,
                [name]: false,
            });
        }

        setState({
            ...state,
            [name]: value,
        });
    };

    return (
        <>
            <AppAction ref={appAction} />
            <Modal centered show={handleBlokirRekening.isSuccess}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Proses {type === 'buka' && 'Buka'} Blokir Rekening Deposito menunggu proses
                        otorisasi
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            handleBlokirRekening.reset();
                            setState({ notes: '' });
                        }}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Selesai
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={confirm}>
                <Modal.Header onHide={() => setConfirm(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Lakukan proses {type === 'buka' && 'Buka'} Blokir Rekening Deposito?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setConfirm(false)}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Tidak
                    </Button>
                    <Button
                        loading={handleBlokirRekening.isLoading}
                        onClick={() => handleBlokirRekening.mutate()}
                        className="tw-w-16"
                        size="mini"
                        color="blue"
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={show} size="lg">
                <Modal.Header
                    onHide={() => {
                        if (onHide) onHide();
                        setState({ notes: '' });
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">
                            {type === 'buka' && 'Buka'} Blokir Rekening Deposito
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tw-border tw-border-black-40 tw-py-5 tw-px-6 tw-border-solid tw-rounded-lg tw-mb-6">
                        <div className="tw-flex tw-items-center tw-mb-5">
                            <div className="tw-text-black tw-font-bold tw-mr-2 tw-text-16">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    data?.namaLengkap
                                )}
                            </div>
                            {isPreviousData ? (
                                <Skeleton width={50} height="100%" />
                            ) : (
                                <>
                                    {form?.statusAkun === 'ACTIVE' && (
                                        <div className="tw-bg-green tw-text-12 tw-bg-opacity-20 tw-text-green tw-rounded-full tw-px-3">
                                            Aktif
                                        </div>
                                    )}
                                    {form?.statusAkun === 'LOCKED' && (
                                        <div className="tw-bg-black-40 tw-text-12 tw-bg-opacity-20 tw-text-black-40 tw-rounded-full tw-px-3">
                                            Locked
                                        </div>
                                    )}
                                </>
                            )}

                            {type === 'tutup' && (
                                <div className="tw-bg-primary tw-ml-2 tw-text-12 tw-bg-opacity-20 tw-text-primary tw-rounded-full tw-px-3">
                                    Proses Tutup Rekening
                                </div>
                            )}
                        </div>
                        <div className="tw-grid tw-grid-cols-6 tw-items-center tw-gap-x-2 tw-gap-y-1 tw-text-black tw-text-12">
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Produk</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    form?._rekening?.produk
                                )}
                            </div>
                            {/* <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Cabang</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">KCP Tembalang</div> */}
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Saldo Rek</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    formatNumber(form?.balances?.availableBalance)
                                )}
                            </div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>No Rek</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    data?.nomorDeposito
                                )}
                            </div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Kode Valuta</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    form?._rekening?.valuta
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <Form.Check
                        className="tw-text-12 tw-font-bold tw-mb-4"
                        label="Dapat Bonus / Bagi Hasil"
                    /> */}
                    <div>
                        <Form.Label className="font-weight-normal f-12">
                            Keterangan Blokir
                            <Label className="ml-2" size="mini">
                                Wajib
                            </Label>
                        </Form.Label>
                        <div className="tw-grid tw-grid-cols-2">
                            <div>
                                <Form.Control
                                    as="select"
                                    size="sm"
                                    placeholder="Keterangan"
                                    value={state.notes}
                                    name="notes"
                                    onChange={handleChange}
                                    isInvalid={error.notes}
                                    disabled={type === 'buka'}
                                >
                                    <option selected disabled hidden value="">
                                        Pilih keterangan
                                    </option>
                                    {keteranganOptions?.map(
                                        ({ reference_code, reference_desc }) => (
                                            <option
                                                key={reference_code}
                                                value={`${reference_code} - ${reference_desc}`}
                                            >
                                                {reference_desc}
                                            </option>
                                        )
                                    )}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Keterangan harus diisi
                                </Form.Control.Feedback>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide} className="tw-mr-2" size="mini">
                        Batal
                    </Button>
                    <Button
                        onClick={() => {
                            const { error, isError } = new GetErrorEmptyValue({
                                notes: state.notes,
                            });

                            setError(error);
                            if (isError) return;

                            setConfirm(true);
                            if (onHide) onHide();
                        }}
                        color={type === 'buka' ? 'green' : 'red'}
                        size="mini"
                        className="tw-font-normal"
                    >
                        {type === 'buka' ? 'Buka Blokir Rekening' : 'Blokir Rekening'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BlokirRekeningModal;
