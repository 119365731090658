import React from 'react';
import { dasboardData } from '../../../../../../appConfig';
import { createForm } from '../../../../../../module/createFormList';
import { TableList } from '../../../../../controls';
import useRekening from '../useRekening';

import { Tab, Nav, Modal, Button } from 'react-bootstrap';
import HistoryTrx from '../components/historyTrx';
import DetilRekening from '../components/detilRekening';
import Skeleton from 'react-loading-skeleton';
import { addCommas } from '../../../../../../module';
import useDropdowns from '../../../../../../hooks/useDropdowns';

const ModalDetailRekening = () => {
    const { rekState, rekAction } = useRekening();
    const { R_RELASI_MULTICIF } = useDropdowns(["R_RELASI_MULTICIF"]);

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalDetailRekening} onHide={rekAction.closeDetailRekening} >
                <Modal.Header closeButton>
                    <Modal.Title>Detail Rekening</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container defaultActiveKey="histTrx">
                        <div className="col-12 p-4">
                            <Nav fill justify variant="pills" className="svs-nav">
                                <Nav.Item>
                                    <Nav.Link eventKey="histTrx">
                                        Riwayat Transaksi
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="detilRek">Detail Rekening</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                        <Nav.Link eventKey="deletedImages">Deleted Images</Nav.Link>
                                    </Nav.Item> */}
                            </Nav>
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="box mb-3" style={{ height: 190 }}>
                                    <div className="row">
                                        <div className="col">
                                            <h4>{rekState.loadingDetil ? <Skeleton width={"50%"} height={30} /> : <>{rekState.rekSelected?.nama_rekening}<span className="ml-2 badge badge-pill badge-secondary">{rekState.rekSelected?.status}</span></>}</h4>

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-4"> Produk </div>
                                                        <div className="col-8">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nama_produk}`} </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4"> No. Rek </div>
                                                        <div className="col-8">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nomor_rekening}`}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4"> No. Regis Buku </div>
                                                        <div className="col-8">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nomor_register_buku || '-'}`}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4"> No. Akad </div>
                                                        <div className="col-8">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nomor_akad || '-'}`}</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-4"> Cabang </div>
                                                        <div className="col-8">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nama_cabang}`} </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4">Kode Valuta </div>
                                                        <div className="col-8">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.valuta}`} </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4">Tanggal Buka Rekening</div>
                                                        <div className="col-8">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.tanggal_buka || '-'}`}</div>
                                                    </div>
                                                    {/* <div className="row">
                                                        <div className="col-6 mt-4">Tipe Join Account&nbsp;&nbsp;&nbsp;:</div>
                                                        <div className="col-6">
                                                            {
                                                                createForm({
                                                                    type: "select",
                                                                    //label: "Tipe Join Account",
                                                                    placeholder: "-",
                                                                    value: rekState.rekSelected?.tipe_join_account,
                                                                    disabled: true,
                                                                    options: R_RELASI_MULTICIF,
                                                                    key: "reference_code",
                                                                    display: "reference_desc",
                                                                })
                                                            } 
                                                        </div>
                                                    </div> */}
                                                </div>

                                                {/* <div className="col-4">
                                            <div className="row">
                                                <div className="col-3"> Saldo Rek </div>
                                                <div className="col-9">
                                                {rekState.loadingDetil ? <Skeleton height={20} /> : <>: <span style={{float:"right"}}> {rekState.rekSelected?.saldo} </span> </>}
                                                </div>
                                            </div>
                                        </div> */}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="box" style={{ height: 190 }}>
                                        <h4 className="text-center">Saldo Efektif </h4>
                                    <div className="text-right pb-2 ">
                                            {/* <div style={{position:"absolute", width:"100%", bottom:35, right:20}}> */}
                                                {rekState.loadingDetil ? <Skeleton height={40} /> : <>
                                                    <span className="text-base" style={{ fontWeight:"bolder", fontSize:"15pt" }}>
                                                        {addCommas( parseFloat(rekState.rekSelected?.saldo_efektif || "0").toFixed(2))}
                                                        </span>
                                                </>}
                                            {/* </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <p>Saldo Blokir : </p>
                                        </div>
                                        <div className="col-7">
                                            {rekState.loadingDetil ? <Skeleton height={40} /> : <>
                                                <span className="text-base  float-right" style={{ fontWeight:"bolder", fontSize:"10pt" }}>
                                                    {addCommas( parseFloat(rekState.rekSelected?.saldo_ditahan || "0").toFixed(2))}</span>
                                            </>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <p>Saldo Minimum : </p>
                                        </div>
                                        <div className="col-7">
                                            {rekState.loadingDetil ? <Skeleton height={40} /> : <>
                                                <span className="text-base  float-right" style={{ fontWeight:"bolder", fontSize:"10pt" }}>
                                                    {addCommas( parseFloat(rekState.rekSelected?.saldo_minimum || "0").toFixed(2))}</span>
                                            </>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <p>Saldo Akhir : </p>
                                        </div>
                                        <div className="col-7">
                                            {rekState.loadingDetil ? <Skeleton height={40} /> : <>
                                                <span className="text-base  float-right" style={{ fontWeight:"bolder", fontSize:"10pt" }}>
                                                    {addCommas( parseFloat(rekState.rekSelected?.saldo || "0").toFixed(2))}</span>
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Tab.Content style={{ padding: 10 }}>
                            <Tab.Pane eventKey="histTrx">
                                <HistoryTrx />
                            </Tab.Pane>
                            <Tab.Pane eventKey="detilRek">
                                <DetilRekening />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button
                                onClick={() => rekAction.closeDetailRekening()}
                                className="btn btn-outline-success float-right">
                                Tutup
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal >
            {/* end modal utama */}
        </>
    );
}

export default ModalDetailRekening;