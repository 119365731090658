import React, { memo, useRef } from 'react';
import './style.css';
import { Modal, Dropdown } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { baseUrl } from '../../../../app.config';
import { slice } from 'lodash';

class DetailNasabahPerorangan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            isStep0Completed: false,
            isStep1Completed: false,
            isStep2Completed: false,
            isStep3Completed: false,
            isStep4Completed: false,
            showModalTambahPejabat: false,
            showModalTambahRelasi: false,
            showModalConfirm: false,
            showModalSuccess: false,
            mode: 'create',
            nama_perusahaan: '',
            email: '',
            telepon_kantor: '',
            keterangan: '',
            search: '',
            datatable: [],
            isSearch: false,
        };
    }

    state = {
        data: [],
        db_id: 0,
        search: '',
        datatable: [],
        isSearch: false,
    };

    setStep = (step) => {
        this.setState({
            step,
        });
    };

    saveStep = () => {
        let isStep0Completed = this.state.isStep0Completed;
        let isStep1Completed = this.state.isStep1Completed;
        let isStep2Completed = this.state.isStep2Completed;
        let isStep3Completed = this.state.isStep3Completed;
        let isStep4Completed = this.state.isStep4Completed;
        let showModalConfirm = false;

        if (this.state.step == 0) {
            isStep0Completed = true;
        }

        if (this.state.step == 1) {
            isStep1Completed = true;
        }

        if (this.state.step == 2) {
            isStep2Completed = true;
        }

        if (this.state.step == 3) {
            isStep3Completed = true;
        }

        if (this.state.step == 4) {
            isStep4Completed = true;
        }

        if (
            isStep0Completed &&
            isStep1Completed &&
            isStep2Completed &&
            isStep3Completed &&
            isStep4Completed
        ) {
            showModalConfirm = true;
        }

        this.setState({
            isStep0Completed: isStep0Completed,
            isStep1Completed: isStep1Completed,
            isStep2Completed: isStep2Completed,
            isStep3Completed: isStep3Completed,
            isStep4Completed: isStep4Completed,
            showModalConfirm: showModalConfirm,
        });
    };

    componentDidMount() {
        this.setState({ db_id: this.props.location.state.data.db_id });
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(
            baseUrl + `/nasabah/perorangan/local/find/${this.props.location.state.data.db_id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => this.setState({ data: data?.list }));
    }

    render() {
        let data = this.state.data;
        // console.log(data, 'data');
        return (
            <div className="">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Nasabah / CIF</li>
                        <li className="breadcrumb-item">
                            <a href="#">Registrasi Nasabah / CIF</a>
                        </li>
                        <li className="breadcrumb-item">Nasabah Perorangan</li>
                    </ol>
                </nav>

                <div className="card">
                    <div className="card-header">
                        <div className="row mx-auto justify-content-center p-2">
                            <div
                                className={
                                    this.state.isStep0Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 0 || this.state.step == 1
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(0)}
                            >
                                <div className="row">
                                    <div className="col p0"></div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep0Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Identias Nasabah
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep1Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 1
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(1)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep1Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        {/* <hr className="line-status" /> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Pekerjaan & Alamat
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className={
                                    this.state.isStep2Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 2 || this.state.step == 3
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(2)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep2Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Data tambahan & CDD
                                    </div>
                                </div>
                            </div> */}
                            {/* <div
                                className={
                                    this.state.isStep3Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 3
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(3)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep3Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Relasi & Ahli Waris
                                    </div>
                                </div>
                            </div> */}
                            {/* <div
                                className={
                                    this.state.isStep4Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 4
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(4)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep4Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0"></div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Beneficiary Owner
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.step == 0 ? (
                            <div>
                                <div className="box">
                                    <div className="row">
                                        <div className="col-4 align-self-center bns-form">
                                            <div className="form-group">
                                                <label>Nomor CIF</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    readOnly
                                                    placeholder="Otomatis terisi dari sistem"
                                                    value={this.state.id}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-8 text-right align-self-end">
                                            <div>
                                                <span className="text-grey">Tgl Buka</span>
                                                <span className="text-grey2 ml-4">:</span>
                                                <span className="text-grey2">03 Maret 2021</span>
                                                <span className="ml-5 text-grey">Kode Cabang</span>
                                                <span className="text-grey2 ml-4">:</span>
                                                <span className="text-grey2">01/23/45</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bns-form">
                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">Identitas</div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label>
                                                Jenis Identitas{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="personalIdType"
                                            >
                                                <option>
                                                    {data && data._personalIdData.personalIdType}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Tanggal Identitas{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Tgl Terbit"
                                                        className="form-control"
                                                        type="date"
                                                        disabled
                                                        value={
                                                            data &&
                                                            data._personalIdData
                                                                .personalIdDateOfIssue
                                                        }
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>/</span>
                                                </div>
                                                <div className="col-5">
                                                    <input
                                                        placeholder="Tgl Kadaluarsa"
                                                        className="form-control"
                                                        type="date"
                                                        disabled
                                                        value={
                                                            data &&
                                                            data._personalIdData
                                                                .personalIdExpireDate
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Nomor Identitas{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nomor Identitas"
                                                className="form-control"
                                                name="personalIdNumber"
                                                readOnly
                                                value={
                                                    data && data._personalIdData.personalIdNumber
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Nomor NPWP{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nomor NPWP"
                                                className="form-control"
                                                name="personalNpwpNumber"
                                                readOnly
                                                value={
                                                    data && data._personalIdData.personalNpwpNumber
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Nama Pemegang Identitas</label>
                                            <input
                                                placeholder="Masukan Nama Pemegang Identitas"
                                                className={`form-control`}
                                                name="personalIdHolderName"
                                                value={
                                                    data &&
                                                    data._personalIdData.personalIdHolderName
                                                }
                                                readOnly
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Tempat diterbitkan KTP</label>
                                            <input
                                                placeholder="Masukan Tempat diterbitkan KTP"
                                                className={`form-control`}
                                                name="personalIdIssuedPlace"
                                                value={
                                                    data &&
                                                    data._personalIdData.personalIdIssuedPlace
                                                }
                                                readOnly
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">Data Diri</div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label>
                                                Nama Depan{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nama"
                                                className="form-control"
                                                name="firstName"
                                                readOnly
                                                value={data && data.firstName}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Nama Belakang{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nama Belakang"
                                                className="form-control"
                                                name="lastName"
                                                readOnly
                                                value={data && data.lastName}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Nama Tengah</label>
                                            <input
                                                placeholder="Masukan Nama Belakang"
                                                className={`form-control`}
                                                name="middleName"
                                                value={data && data.middleName}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Tempat / Tanggal Lahir{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Tempat Lahir"
                                                        className="form-control"
                                                        name="birthPlace"
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>/</span>
                                                </div>
                                                <div className="col-5">
                                                    <input
                                                        placeholder="Tanggal Lahir"
                                                        className="form-control"
                                                        type="date"
                                                        name="birthDate"
                                                        readOnly
                                                        value={data && data.birthDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Nama Ibu Kandung{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nama Ibu Kandung"
                                                className="form-control"
                                                name="personalMothersName"
                                                readOnly
                                                value={
                                                    data && data._customerData.personalMothersName
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <label>Gelar Depan</label>
                                                    <input
                                                        placeholder="Gelar Depan"
                                                        className="form-control"
                                                        value={
                                                            data &&
                                                            data._customerData?.customerPreTitle
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label>Gelar Belakang</label>
                                                    <input
                                                        placeholder="Gelar Belakang"
                                                        className="form-control"
                                                        value={
                                                            data &&
                                                            data._customerData?.customerPostTitle
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Nama Singkat</label>
                                            <input
                                                placeholder="Nama Singkat"
                                                className="form-control"
                                                value={
                                                    data && data._customerData?.customerAliasName
                                                }
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Kewarganegaraan{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select className="form-control" disabled>
                                                <option>
                                                    {data &&
                                                        data._customerData.personalCitizenshipXXX}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Jenis Kelamin{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <br />
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="gender"
                                                        value="MALE"
                                                        checked={data && data.gender === 'MALE'}
                                                        disabled
                                                    />
                                                    Laki-laki
                                                </label>
                                            </div>
                                            <div className="form-check-inline ml-3">
                                                <label className="form-check-label">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="gender"
                                                        value="FEMALE"
                                                        disabled
                                                        checked={data && data.gender === 'FEMALE'}
                                                    />
                                                    Perempuan
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Agama </label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="personalReligion"
                                            >
                                                <option value="">
                                                    {data && data._customerData?.personalReligion}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Status Pernikahan </label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="personalMaritalStatus"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._customerData?.personalMaritalStatus}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Pendidikan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="customerEducation"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._occupationInfo?.customerEducation}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Status Keterkaitan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="customerBankRelation"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._customerData?.customerBankRelation}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Nama Pasangan</label>
                                            <input
                                                placeholder="Masukan Nama Pasangan"
                                                className={`form-control`}
                                                name="customerSpouseName"
                                                value={data && data.customerSpouseName}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Pekerjaan Pasangan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="customerSpouseOccupation"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._customerData
                                                            ?.customerSpouseOccupation}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Penghasilan Utama Pasangan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="customerSpouseMainIncome"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._customerData
                                                            ?.customerSpouseMainIncome}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Penghasilan Lainnya Pasangan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="customerSpouseSecondIncome"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._customerData
                                                            ?.customerSpouseSecondIncome}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">
                                            Informasi Lainnya
                                        </div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label>Alamat Email</label>
                                            <input
                                                placeholder="Masukan Masukkan alamat email"
                                                className="form-control"
                                                name="emailAddress"
                                                readOnly
                                                value={data && data.emailAddress}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Bahasa Pilihan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="preferredLanguage"
                                            >
                                                <option value="">
                                                    {data && data.preferredLanguage}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Nomor Handphone 1{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan nomor handphone"
                                                className={`form-control`}
                                                name="mobilePhone"
                                                value={data && data.mobilePhone}
                                                readOnly
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Nomor Handphone 2</label>
                                            <input
                                                placeholder="Masukan nomor handphone 2"
                                                className={`form-control`}
                                                name="mobilePhone2"
                                                value={data && data.mobilePhone2}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Nomor Telepon Rumah</label>
                                            <input
                                                placeholder="Masukan nomor telepon rumah"
                                                className={`form-control`}
                                                name="homePhone"
                                                value={data && data.homePhone}
                                                readOnly
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Keterangan</label>
                                            <textarea
                                                rows="3"
                                                className="form-control"
                                                placeholder="Bila ada"
                                                name="notes"
                                                readOnly
                                            ></textarea>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Marketing</label>
                                            <input
                                                placeholder="Marketing nasabah"
                                                className="form-control"
                                                name="personalMarketingOfficer"
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Kode Referal</label>
                                            <input
                                                placeholder="Masukan Kode Referal"
                                                className="form-control"
                                                name="personalReferalCode"
                                                value={
                                                    data &&
                                                    data._personalIdData?.personalReferalCode
                                                }
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {this.state.step == 1 ? (
                            <div className="bns-form">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row mt-4 mb-2">
                                            <div className="col-auto text-primary">
                                                Alamat Identitas
                                            </div>
                                            <div className="col">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Alamat{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <textarea
                                                rows="3"
                                                className="form-control"
                                                placeholder="Masukkan alamat"
                                                readOnly
                                                value={data && data._ktpAddress.ktpAddress}
                                            ></textarea>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>
                                                    Provinsi{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select className={`form-control`} readOnly>
                                                    <option>
                                                        {data && data._ktpAddress.ktpProvince}
                                                    </option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Kota/Kab </label>
                                                <select className={`form-control`} readOnly>
                                                    <option>
                                                        {data && data._ktpAddress.ktpCity}
                                                    </option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>
                                                    Kecamatan{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select
                                                    placeholder="Masukkan Kecamatan"
                                                    className={`form-control`}
                                                    readOnly
                                                    name="ktpSubDistrict"
                                                >
                                                    <option value="">
                                                        {data && data._ktpAddress?.ktpSubDistrict}
                                                    </option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>
                                                    Kelurahan{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select
                                                    placeholder="Masukkan Kelurahan"
                                                    className={`form-control`}
                                                    readOnly
                                                >
                                                    <option>
                                                        {data && data._ktpAddress.ktpVillage}
                                                    </option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>
                                                RT / RW{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        placeholder="Masukkan RT/RW"
                                                        className={`form-control`}
                                                        readOnly
                                                        name="ktpRtRw"
                                                        value={data && data._ktpAddress?.ktpRtRw}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Kode Pos</label>
                                            <select
                                                placeholder="Masukkan Kode Pos"
                                                className="form-control"
                                                readOnly
                                            >
                                                <option>
                                                    {data && data._ktpAddress.ktpPostalCode}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Negara</label>
                                            <input
                                                placeholder="Masukkan Negara"
                                                className="form-control"
                                                readOnly
                                                name="ktpCountry"
                                                value={data && data._ktpAddress?.ktpCountry}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row mt-4 mb-2">
                                            <div className="col-auto text-primary">
                                                Alamat Domisili
                                            </div>
                                            <div className="col">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Alamat{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <textarea
                                                rows="3"
                                                className="form-control"
                                                placeholder="Masukkan alamat"
                                                readOnly
                                                value={data && data._mailingAddress?.mailingAddress}
                                            ></textarea>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Provinsi </label>
                                                <select
                                                    className={`form-control`}
                                                    readOnly
                                                    name="mailingAddressProvince"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._mailingAddress
                                                                ?.mailingAddressProvince}
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Kota/Kab </label>
                                                <select
                                                    className={`form-control`}
                                                    readOnly
                                                    name="mailingAddressCity"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._mailingAddress
                                                                ?.mailingAddressCity}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Kecamatan </label>
                                                <select
                                                    placeholder="Masukkan Kecamatan"
                                                    className={`form-control`}
                                                    readOnly
                                                    name="mailingAddressSubDistrict"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._mailingAddress
                                                                ?.mailingAddressSubDistrict}
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Kelurahan </label>
                                                <select
                                                    placeholder="Masukkan Kelurahan"
                                                    className={`form-control`}
                                                    readOnly
                                                    name="mailingAddressVillage"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._mailingAddress
                                                                ?.mailingAddressVillage}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>RT / RW</label>
                                            <div className="row">
                                                <div className="col-5">
                                                    <input
                                                        placeholder="Masukkan RT"
                                                        className="form-control"
                                                        readOnly
                                                        name="mailingAddressRt"
                                                        value={
                                                            data &&
                                                            data._mailingAddress?.mailingAddressRt
                                                        }
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>/</span>
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Masukkan RW"
                                                        className="form-control"
                                                        readOnly
                                                        name="mailingAddressRw"
                                                        value={
                                                            data &&
                                                            data._mailingAddress?.mailingAddressRw
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Kode Pos</label>
                                            <select
                                                placeholder="Masukkan Kode Pos"
                                                className="form-control"
                                                readOnly
                                                name="ktpPostalCode"
                                            >
                                                <option value="">
                                                    {data && data._mailingAddress?.ktpPostalCode}
                                                </option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label>No Handphone</label>
                                            <input
                                                placeholder="Masukkan nomor handphone"
                                                className="form-control"
                                                readOnly
                                                name="mailingRecipientPhoneNumber"
                                                value={data && data.mailingRecipientPhoneNumber}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Data Pekerjaan</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Nama Perusahaan</label>
                                            <input
                                                placeholder="Masukkan nomor handphone"
                                                className="form-control"
                                                readOnly
                                                name="companyName"
                                                value={data && data.companyName}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Alamat Perusahaan</label>
                                            <input
                                                placeholder="Masukkan nomor handphone"
                                                className="form-control"
                                                readOnly
                                                name="companyAddress"
                                                value={data && data.companyAddress}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Jenis Pekerjaan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="personalTypeOfWork"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._occupationInfo?.personalTypeOfWork}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Jabatan Pekerjaan</label>
                                            <select
                                                className={`form-control`}
                                                readOnly
                                                name="personalJobPosition"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._occupationInfo.personalJobPosition}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Penghasilan Bulanan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="personalMonthlyIncome"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._occupationInfo?.personalMonthlyIncome}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Pekerjaan</label>
                                            <select
                                                className="form-control"
                                                readOnly
                                                name="personalOccupation"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._occupationInfo?.personalOccupation}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Provinsi</label>
                                                <select
                                                    className={`form-control`}
                                                    readOnly
                                                    name="companyAddressProvince"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._companyAddress
                                                                ?.companyAddressProvince}
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Kota/Kab </label>
                                                <select
                                                    className={`form-control`}
                                                    readOnly
                                                    name="companyAddressCity"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._companyAddress
                                                                ?.companyAddressCity}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Kecamatan</label>
                                                <select
                                                    placeholder="Masukkan Kecamatan"
                                                    className={`form-control`}
                                                    readOnly
                                                    name="companyAddressSubDistrict"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._companyAddress
                                                                ?.companyAddressSubDistrict}
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Kelurahan</label>
                                                <select
                                                    placeholder="Masukkan Kelurahan"
                                                    className={`form-control`}
                                                    readOnly
                                                    name="companyAddressVillage"
                                                >
                                                    <option value="">
                                                        {data &&
                                                            data._companyAddress
                                                                ?.companyAddressVillage}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>RT / RW</label>
                                            <div className="row">
                                                <div className="col-12">
                                                    <input
                                                        placeholder="Masukkan RT/RW"
                                                        className="form-control"
                                                        readOnly
                                                        name="companyAddressRtRw"
                                                        value={
                                                            data &&
                                                            data._companyAddress?.companyAddressRtRw
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Kode Pos</label>
                                            <select
                                                placeholder="Masukkan Kode Pos"
                                                className="form-control"
                                                readOnly
                                                name="companyAddressPostalCode"
                                            >
                                                <option value="">
                                                    {data &&
                                                        data._companyAddress
                                                            ?.companyAddressPostalCode}
                                                </option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label>No Handphone</label>
                                            <input
                                                placeholder="Masukkan nomor handphone"
                                                className="form-control"
                                                readOnly
                                                name="companyAddressPhoneNumber"
                                                value={data && data.companyAddressPhoneNumber}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {this.state.step == 2 ? (
                            <div className="bns-form">
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Data Tambahan</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Keluarga yang dapat dihubungi</label>
                                        <input
                                            placeholder="Masukkan nama keluarga yang dapat dihubungi"
                                            className="form-control"
                                            value=""
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Golongan Nasabah{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <select
                                            disabled
                                            aria-readonly="false"
                                            className="form-control"
                                        >
                                            <option value="">Pilih jenis identitas</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Telepon/No. Hp Keluarga yang dapat dihubungi{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <input
                                            placeholder="Masukkan telepon/no. Hp Keluarga yang dapat dihubungi"
                                            className="form-control"
                                            value=""
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Tujuan Penggunaan Dana{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <select
                                            disabled
                                            aria-readonly="false"
                                            className="form-control"
                                        >
                                            <option value="">Pilih Tujuan Penggunaan Dana</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Penghasilan Utama Per Tahun{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <select
                                            disabled
                                            aria-readonly="false"
                                            className="form-control"
                                        >
                                            <option value="">
                                                Pilih penghasilan utama per tahun
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Limit Transaksi</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>
                                            Nominal Setor Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    placeholder="Masukkan Nominal Setor Tunai"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label htmlFor="">Frekuensi</label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    placeholder="0"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Nominal Tarik Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    placeholder="Masukkan Nominal Tarik Tunai"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label htmlFor="">Frekuensi</label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    placeholder="0"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Nominal Setor Non Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    placeholder="Masukkan Nominal Setor Non Tunai"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label htmlFor="">Frekuensi</label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    placeholder="0"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Nominal Tarik Non Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    placeholder="Masukkan Nominal Tarik Non Tunai"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label htmlFor="">Frekuensi</label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    placeholder="0"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Faktor Resiko</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>
                                                Faktor Resiko{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                disabled
                                                aria-readonly="false"
                                                className="form-control"
                                            >
                                                <option value="">Pilih Faktor Resiko</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Status Resiko{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Low"
                                                readOnly
                                                className="form-control"
                                                value=""
                                                disabled
                                            />
                                        </div>
                                        <div className="alert alert-warning">
                                            Status Resiko akan dikalkulasi berdasar input pada
                                            daftar indikator resiko disamping
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <table className="table table-bordered text-center">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Tingkat Resiko</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Identitas Masalah</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Lokasi Usaha</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Profil Nasabah</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Jumlah Transaksi</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Kegiatan Usaha</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Struktur Kepemilikan</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Informasi Lainnya</td>
                                                    <td>Low</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {this.state.step == 3 ? (
                            <div className="bns-form">
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label>Hubungan Dengan Nasabah</label>
                                        <select
                                            disabled
                                            aria-readonly="false"
                                            className="form-control"
                                        >
                                            <option value="">Pilih Hubungan Dengan Nasabah</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Jabatan / Pangkat</label>
                                        <select
                                            disabled
                                            aria-readonly="false"
                                            className="form-control"
                                        >
                                            <option value="">Pilih jabatan pekerjaan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Data Diri</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="">Nomor Nasabah</label>
                                            <div className="row">
                                                <div className="col-8">
                                                    <input
                                                        placeholder="Masukkan nomor nasabah"
                                                        className="form-control"
                                                        value=""
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <button className="btn btn-outline-secondary">
                                                        Hapus
                                                    </button>
                                                </div>
                                                <div className="col-3">
                                                    <button className="btn bg-orange-primary text-white btn-block">
                                                        Cari Nomor Nasabah
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Nama (Sesuai Identitas)</label>
                                            <input
                                                placeholder="Nama nasabah sesuai identitas"
                                                className="form-control"
                                                value=""
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Alamat</label>
                                            <textarea
                                                rows="3"
                                                className="form-control"
                                                placeholder="Masukkan alamat"
                                                disabled
                                            ></textarea>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>
                                                    Provinsi{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <input
                                                    placeholder="Masukkan provinsi"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group col-6">
                                                <label>
                                                    Kota/Kab{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <input
                                                    placeholder="Masukkan kota/kabupaten"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>
                                                    Kecamatan{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <input
                                                    placeholder="Masukkan Kecamatan"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group col-6">
                                                <label>
                                                    Kelurahan{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <input
                                                    placeholder="Masukkan Kelurahan"
                                                    className="form-control"
                                                    value=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>RT / RW</label>
                                            <div className="row">
                                                <div className="col-5">
                                                    <input
                                                        placeholder="Masukkan RT"
                                                        className="form-control"
                                                        value=""
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>/</span>
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Masukkan RW"
                                                        className="form-control"
                                                        value=""
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Telepon Rumah 1</label>
                                            <div className="row">
                                                <div className="col-3">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>-</span>
                                                </div>
                                                <div className="col-8">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>No Handphone</label>
                                            <input
                                                placeholder="Masukkan nomor handphone"
                                                className="form-control"
                                                value=""
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Fax</label>
                                            <div className="row">
                                                <div className="col-3">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>-</span>
                                                </div>
                                                <div className="col-8">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                placeholder="Masukkan alamat email"
                                                className="form-control"
                                                value=""
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Jenis Identitas</label>
                                            <select
                                                disabled
                                                aria-readonly="false"
                                                className="form-control"
                                            >
                                                <option value="">Pilih jenis identitas</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Nomor Identitas</label>
                                            <input
                                                placeholder="Masukan Nomor Identitas"
                                                className="form-control"
                                                value=""
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Nomor NPWP</label>
                                            <input
                                                placeholder="Masukan Nomor NPWP"
                                                className="form-control"
                                                value=""
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Kewarganegaraan</label>
                                            <select
                                                disabled
                                                aria-readonly="false"
                                                className="form-control"
                                            >
                                                <option value="">Pilih Kewarganegaraan</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Negara Asal</label>
                                            <select
                                                disabled
                                                aria-readonly="false"
                                                className="form-control"
                                            >
                                                <option value="">Pilih Negara Asal</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Pekerjaan</label>
                                            <select
                                                disabled
                                                aria-readonly="false"
                                                className="form-control"
                                            >
                                                <option value="">Pilih Pekerjaan</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-12 text-right">
                                <button
                                    disabled={this.state.step == 0 ? true : false}
                                    className={
                                        'btn mr-1 ' +
                                        (this.state.step == 0
                                            ? 'btn-secondary'
                                            : 'btn-orange-primary')
                                    }
                                    onClick={() => this.setState({ step: this.state.step - 1 })}
                                >
                                    Sebelumnya
                                </button>
                                <button
                                    disabled={this.state.step == 3 ? true : false}
                                    className={
                                        'btn mr-1 ' +
                                        (this.state.step == 3
                                            ? 'btn-secondary'
                                            : 'btn-orange-primary')
                                    }
                                    onClick={() => this.setState({ step: this.state.step + 1 })}
                                >
                                    Selanjutnya
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.showModalConfirm}
                    onHide={() => this.setState({ showModalConfirm: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Lakukan proses Registrasi Nasabah / CIF - Perorangan?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirm: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                // this.state.mode === 'create' ? this.postGroup() : this.putGroup();
                                // this.putPerorangan();
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSuccess}
                    onHide={() => this.setState({ showModalSuccess: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Selesai</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Proses Registrasi Nasabah / CIF - Perorangan berhasil dilakukan
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalSuccess: false });
                                this.props.history.push('/data_nasabah/cif/approved');
                            }}
                        >
                            Selesai
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalError}
                    onHide={() => this.setState({ showModalError: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageError}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalError: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default DetailNasabahPerorangan;
