import React from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Modal } from 'react-bootstrap';
import { withKeycloak } from '@react-keycloak/web';
import './style.css';
import { baseUrl } from './../../../../app.config';
import { thousandSeparator } from '../../../../utils';
import ModalInfoNegatifNasabah from '../../data_nasabah/info_negatif_modal';

class SetorTunai extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_user: 'teller-1',
            keterangan: null,
            no_referensi: null,
            nilai_transaksi: 0,
            transaction_ch_id: 'null',
            transaction_encoded_key: 'null',
            no_rekening: null,
            valuta: 'IDR',
            nama_nasabah: null,
            cabang_rekening: null,
            kurs_jual: 1,
            kurs_beli: 1,
            kurs_buku: 1,
            nominal: 0,
            nilai_ekuivalen: 0,
            nilai_bulanan: 0,
            biaya: 0,
            biaya_bulanan: 0,
            sumber_biaya: 'tunai',
            showModalConfirm: false,
            showModalBatal: false,
            showModalSuccess: false,
            showModalError: false,
            productName: null,
            showModalWarning: false,
            showModalInfoNegatif: false,
            jenis_nasabah: null,
            nomor_cif: null,
            errors: {
                no_rekening: false,
                nominal: false,
                nomor_cif: false,
            },
            messages: {
                no_rekening: '',
                nominal: '',
                nomor_cif: '',
            },
            isLoading: false,
            formNegatifNasabah: {},
        };
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        this.setState(
            {
                [name]: value,
            },
            () => {
                if (name === 'nomor_cif') {
                    this.checkNomorCif();
                }
            }
        );
    };

    handleFormatNumber = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        const newValue = thousandSeparator.format(value);

        if (newValue !== 'NaN') {
            this.setState({
                [name]: newValue,
            });
        }
    };

    checkNomorCif = async () => {
        const { jenis_nasabah, nomor_cif } = this.state;
        if (jenis_nasabah === null) {
            this.setState({
                showModalWarning: true,
                messageWarning: 'Jenis Nasabah harus di isi',
            });
            return false;
        }
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        let url =
            jenis_nasabah === 'perorangan'
                ? '/nasabah/perorangan/find/'
                : '/nasabah-badan-usaha/find/';
        // alert(baseUrl + url + nomor_cif);
        fetch(baseUrl + url + nomor_cif, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    if (jenis_nasabah === 'perorangan') {
                        this.setState({
                            nama_nasabah: dataDetail.firstName + ' ' + dataDetail.lastName,
                            alamat_nasabah: dataDetail._ktpAddress?.ktpAddress,
                            jenis_identitas: dataDetail._personalIdData?.personalIdType,
                            nomor_identitas: dataDetail._personalIdData?.personalIdNumber,
                            errors: {
                                ...this.state.errors,
                                nomor_cif: false,
                            },
                            messages: {
                                ...this.state.messages,
                                nomor_cif: '',
                            },
                        });
                    } else {
                        this.setState({
                            nama_nasabah: dataDetail.groupName,
                            alamat_nasabah: dataDetail._identitasNasabah?.nasabahAlamat,
                            jenis_identitas: 'NPWP',
                            nomor_identitas: dataDetail._identitasNasabah?.nasabahNomorNpwp,
                            errors: {
                                ...this.state.errors,
                                nomor_cif: false,
                            },
                            messages: {
                                ...this.state.messages,
                                nomor_cif: '',
                            },
                        });
                    }
                } else {
                    if (jenis_nasabah === 'perorangan') {
                        this.setState({
                            nama_nasabah: '',
                            alamat_nasabah: '',
                            jenis_identitas: '',
                            nomor_identitas: '',
                            errors: {
                                ...this.state.errors,
                                nomor_cif: true,
                            },
                            messages: {
                                ...this.state.messages,
                                nomor_cif: 'Nomor Nasabah tidak ditemukan',
                            },
                        });
                    } else {
                        this.setState({
                            nama_nasabah: '',
                            alamat_nasabah: '',
                            jenis_identitas: '',
                            nomor_identitas: '',
                            errors: {
                                ...this.state.errors,
                                nomor_cif: true,
                            },
                            messages: {
                                ...this.state.messages,
                                nomor_cif: 'Nomor Nasabah tidak ditemukan',
                            },
                        });
                    }
                }
            });
    };

    getNomorRekening = (value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/rekening/tabungan/detail/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    this.setState({
                        productName: dataDetail.produk,
                        valuta: dataDetail.valuta,
                        nama_rekening: dataDetail.nama,
                        nama_nasabah: dataDetail.nama,
                        cabang_rekening:
                            dataDetail.cabang === null || dataDetail.cabang === ''
                                ? '-'
                                : dataDetail.cabang,
                        errors: {
                            ...this.state.errors,
                            no_rekening: false,
                        },
                        messages: {
                            ...this.state.messages,
                            no_rekening: '',
                        },
                    });
                } else {
                    this.setState({
                        productName: '',
                        valuta: 'IDR',
                        nama_rekening: '',
                        cabang_rekening: '',
                        errors: {
                            ...this.state.errors,
                            no_rekening: true,
                        },
                        messages: {
                            ...this.state.messages,
                            no_rekening: 'Nomor rekening tidak ditemukan',
                        },
                    });
                }
            });
    };

    handleValidation = () => {
        const { no_rekening, nomor_cif, nominal, errors, messages } = this.state;
        let isValid = true;

        if (!no_rekening) {
            isValid = false;
            errors['no_rekening'] = true;
            messages['no_rekening'] = 'Harus diisi';
        }

        if (!nomor_cif) {
            isValid = false;
            errors['nomor_cif'] = true;
            messages['nomor_cif'] = 'Harus diisi';
        }

        if (!nominal) {
            isValid = false;
            errors['nominal'] = true;
            messages['nominal'] = 'Harus diisi';
        }

        if (nominal === 0 || nominal === '0') {
            isValid = false;
            errors['nominal'] = true;
            messages['nominal'] = 'Harus lebih dari 0';
        }

        this.setState({ errors: errors });
        return isValid;
    };

    handleProcess = () => {
        if (this.handleValidation()) {
            this.setState({ showModalConfirm: true });
        }
    };

    postSetorTunai = async () => {
        this.setState({ isLoading: true });

        if (this.state.no_rekening === null) {
            this.setState({
                showModalConfirm: false,
                showModalWarning: true,
                messageWarning: 'Nomor rekening harus diisi',
                isLoading: false,
            });
            return false;
        }

        if (this.state.nominal === null || this.state.nominal === 0) {
            this.setState({
                showModalConfirm: false,
                showModalWarning: true,
                messageWarning: 'Nominal harus lebih dari 0',
                isLoading: false,
            });
            return false;
        }

        let body = {
            id_user: this.props.keycloak.idTokenParsed.preferred_username,
            keterangan: this.state.keterangan,
            no_referensi: this.state.no_referensi,
            nilai_transaksi: thousandSeparator.unformat(this.state.nominal),
            transaction_ch_id: this.state.transaction_ch_id,
            transaction_encoded_key: this.state.transaction_encoded_key,
            no_rekening: this.state.no_rekening,
            valuta: this.state.valuta,
            nama_nasabah: this.state.nama_nasabah,
            cabang_rekening:
                this.state.cabang_rekening === null || this.state.cabang_rekening === ''
                    ? '-'
                    : this.state.cabang_rekening,
            kurs_jual: thousandSeparator.unformat(this.state.kurs_jual),
            kurs_beli: thousandSeparator.unformat(this.state.kurs_beli),
            kurs_buku: thousandSeparator.unformat(this.state.kurs_buku),
            nominal: thousandSeparator.unformat(this.state.nominal),
            nilai_ekuivalen: thousandSeparator.unformat(this.state.nilai_ekuivalen),
            nilai_bulanan: this.state.nilai_bulanan,
            biaya: thousandSeparator.unformat(this.state.biaya),
            biaya_bulanan: thousandSeparator.unformat(this.state.biaya_bulanan),
            sumber_biaya: this.state.sumber_biaya,
        };
        // console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };
        fetch(baseUrl + '/teller/transaksi/setor/db/create', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                        isLoading: false,
                    });
                }
            });
    };

    getDataNegatif = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/nasabah/informasi/negatif/find/' + this.state.no_rekening, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    let {
                        nasabahAlamat,
                        nasabahRt,
                        nasabahRw,
                        nasabahKelurahan,
                        nasabahKecamatan,
                        nasabahKotaKabupaten,
                        nasabahProvinsi,
                        nasabahKodePos,
                    } = dataDetail._identitasNasabah;
                    this.setState({
                        formNegatifNasabah: {
                            _negatifNasabah: {
                                keterangan: dataDetail._negatifNasabah.negatifKeterangan,
                                status_data: dataDetail._negatifNasabah.negatifStatus,
                                nama_lengkap: dataDetail.groupName,
                                alamat: `${nasabahAlamat}, RT ${nasabahRt} RW ${nasabahRw}, ${nasabahKelurahan}, ${nasabahKecamatan}, ${nasabahKotaKabupaten}, ${nasabahProvinsi}, ${nasabahKodePos}`,
                                id: dataDetail.id,
                                tgl_approve: dataDetail?.negatifTanggalApprove ?? null,
                            },
                        },
                        showModalInfoNegatif: true,
                    });
                } else {
                    this.setState({
                        formNegatifNasabah: {},
                        showModalInfoNegatif: false,
                    });
                }
            });
    };

    resetFormInput = () => {
        this.setState({
            keterangan: '',
            no_referensi: '',
            nilai_transaksi: 0,
            no_rekening: '',
            nama_rekening: '',
            valuta: 'IDR',
            nama_nasabah: '',
            cabang_rekening: '',
            kurs_jual: 1,
            kurs_beli: 1,
            kurs_buku: 1,
            nominal: 0,
            nilai_ekuivalen: 0,
            nilai_bulanan: 0,
            biaya: 0,
            biaya_bulanan: 0,
            sumber_biaya: 'tunai',
            alamat_nasabah: '',
            jenis_identitas: '',
            nomor_identitas: '',
            jenis_nasabah: '',
            nomor_cif: '',
            productName: '',
        });
    };

    render() {
        return (
            <>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Transaksi</li>
                        <li className="breadcrumb-item">
                            <a href="#">Setor Tunai</a>
                        </li>
                    </ol>
                </nav>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">SETOR TUNAI</h5>
                    </div>
                    <div className="card-body">
                        <div className="bns-form">
                            <div className="row mt-4 mb-2">
                                <div className="col-auto text-primary">Informasi Rekening</div>
                                <div className="col">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <div className="form-group">
                                        <label
                                            className={`${
                                                this.state.errors.no_rekening ? 'text-danger' : ''
                                            }`}
                                        >
                                            No. Rekening
                                        </label>
                                        <div class="form-row">
                                            <div class="col-5 pr0">
                                                <input
                                                    type="text"
                                                    className={`form-control ${
                                                        this.state.errors.no_rekening
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    value={this.state.no_rekening}
                                                    name="no_rekening"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorRekening(e.target.value);
                                                    }}
                                                />
                                                <div className="invalid-feedback">
                                                    {this.state.messages.no_rekening}
                                                </div>
                                            </div>
                                            <div class="col-7 pl0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="NAMA NASABAH"
                                                    readOnly
                                                    value={this.state.nama_rekening}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Valuta Rekening</label>
                                        <div class="form-row">
                                            <div class="col-4 pr0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value={this.state.valuta}
                                                    readOnly
                                                />
                                            </div>
                                            <div class="col-8 pl0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value=""
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="">Produk</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={this.state.productName}
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Cabang Rekening</label>
                                        <div class="form-row">
                                            <div class="col-4 pr0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value={this.state.cabang_rekening}
                                                    readOnly
                                                />
                                            </div>
                                            <div class="col-8 pl0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value=""
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bns-form">
                            <Accordion defaultActiveKey="0">
                                <div className="row mt-4 mb-2">
                                    <Accordion.Toggle as={Link} variant="link" eventKey="0">
                                        <div className="col-auto text-primary">
                                            <i className="fa fa-angle-double-down"></i> Data
                                            Pengirim
                                        </div>
                                    </Accordion.Toggle>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <Accordion.Collapse eventKey="0">
                                    <>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <div class="form-row ">
                                                    <div class="col-2">
                                                        <label htmlFor="">Jenis Nasabah</label>
                                                        <select
                                                            name="jenis_nasabah"
                                                            className="form-control"
                                                            value={this.state.jenis_nasabah}
                                                            onChange={(e) => {
                                                                this.handleInputChange(e);
                                                            }}
                                                        >
                                                            <option value="">
                                                                Pilih Jenis Nasabah
                                                            </option>
                                                            <option value="badan_usaha">
                                                                Badan Usaha
                                                            </option>
                                                            <option value="perorangan">
                                                                Perorangan
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-6">
                                                        <label
                                                            className={`${
                                                                this.state.errors.nomor_cif
                                                                    ? 'text-danger'
                                                                    : ''
                                                            }`}
                                                        >
                                                            Nomor Nasabah
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${
                                                                this.state.errors.nomor_cif
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                this.handleInputChange(e);
                                                            }}
                                                            name="nomor_cif"
                                                            value={this.state.nomor_cif}
                                                        />
                                                        <div className="invalid-feedback">
                                                            {this.state.messages.nomor_cif}
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <label htmlFor="">{''}</label>
                                                        <br />
                                                        <button
                                                            className="btn btn-outline-primary mt-2"
                                                            onClick={() => this.checkNomorCif()}
                                                        >
                                                            Cari dari Data Nasabah/WIC
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-8">
                                                <label htmlFor="">Nama</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    readOnly
                                                    value={this.state.nama_nasabah}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-4">
                                                <label htmlFor="">Alamat</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    readOnly
                                                    value={this.state.alamat_nasabah}
                                                />
                                            </div>
                                            <div className="form-group col-4">
                                                <div class="form-row">
                                                    <div class="col-6">
                                                        <label htmlFor="">Jenis Identitas</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            readOnly
                                                            value={this.state.jenis_identitas}
                                                        />
                                                    </div>
                                                    <div class="col-6">
                                                        <label htmlFor="">Nomor Identitas</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            readOnly
                                                            value={this.state.nomor_identitas}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-4">
                                                <div class="form-row">
                                                    <div class="col-6">
                                                        <label htmlFor="">Nomor Telepon</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div class="col-6">
                                                        <label htmlFor="">Nasabah/WIC</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </Accordion.Collapse>
                            </Accordion>
                        </div>
                        <div className="bns-form">
                            <div className="row mt-4 mb-2">
                                <div className="col">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-7">
                                    <label htmlFor="">Valuta Transaksi</label>
                                    <div class="form-row">
                                        <div class="col-2 pr0">
                                            <select name="" id="" className="form-control">
                                                <option value="">IDR</option>
                                            </select>
                                        </div>
                                        <div class="col-10 pl0">
                                            <input
                                                type="text"
                                                class="form-control"
                                                value="INDONESIAN RUPIAH"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-5">
                                    <div class="form-row">
                                        <div class="col-4">
                                            <label htmlFor="">Kurs Jual</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                value={this.state.kurs_jual}
                                                name="kurs_jual"
                                                onChange={(e) => {
                                                    this.handleFormatNumber(e);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div class="col-4">
                                            <label htmlFor="">Kurs Beli</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                value={this.state.kurs_beli}
                                                name="kurs_beli"
                                                onChange={(e) => {
                                                    this.handleFormatNumber(e);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div class="col-4">
                                            <label htmlFor="">Kurs Buku</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                value={this.state.kurs_buku}
                                                name="kurs_buku"
                                                onChange={(e) => {
                                                    this.handleFormatNumber(e);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-3">
                                    <label
                                        className={`${
                                            this.state.errors.nominal ? 'text-danger' : ''
                                        }`}
                                    >
                                        Nominal
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            this.state.errors.nominal ? 'is-invalid' : ''
                                        }`}
                                        value={this.state.nominal}
                                        name="nominal"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            this.handleFormatNumber(e);
                                            // const value = e.target.value.replace('.', '');
                                            const value = e.target.value;
                                            const kurs_buku = this.state.kurs_buku;

                                            this.setState({
                                                nilai_ekuivalen: thousandSeparator.multiply(
                                                    value,
                                                    kurs_buku
                                                ),
                                            });
                                        }}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.messages.nominal}
                                    </div>
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">Nominal Ekuivalen</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={thousandSeparator.format(this.state.nilai_ekuivalen)}
                                        name="nilai_ekuivalen"
                                        readOnly
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">Biaya</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={this.state.biaya}
                                        name="biaya"
                                        onChange={(e) => {
                                            this.handleFormatNumber(e);

                                            // const value = e.target.value.replace('.', '');
                                            const value = e.target.value;
                                            const kurs_buku = this.state.kurs_buku;

                                            this.setState({
                                                biaya_bulanan: thousandSeparator.multiply(
                                                    value,
                                                    kurs_buku
                                                ),
                                            });
                                        }}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">Biaya Ekuivalen</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={thousandSeparator.format(this.state.biaya_bulanan)}
                                        name="biaya_bulanan"
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="">Sumber Biaya</label>
                                    <select
                                        className="form-control"
                                        name="sumber_biaya"
                                        value={this.state.sumber_biaya}
                                        onChange={(e) => {
                                            this.handleInputChange(e);
                                        }}
                                    >
                                        <option value="tunai">Tunai</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">No.Referensi</label>
                                    <input
                                        type="readOnly"
                                        class="form-control"
                                        value={this.state.no_referensi}
                                        name="no_referensi"
                                        onChange={(e) => {
                                            this.handleInputChange(e);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">Keterangan</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={this.state.keterangan}
                                        name="keterangan"
                                        onChange={(e) => {
                                            this.handleInputChange(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        this.getDataNegatif();
                                    }}
                                    disabled={!this.state.nama_rekening}
                                >
                                    Informasi Negatif Nasabah
                                </button>
                            </div>
                            <div className="col-6 text-right">
                                <button
                                    className="btn btn-outline-secondary"
                                    onClick={() => {
                                        this.setState({ showModalBatal: true });
                                    }}
                                >
                                    Batal
                                </button>
                                <button
                                    className="btn btn-primary ml-3 px-5"
                                    onClick={() => {
                                        this.handleProcess();
                                    }}
                                >
                                    Proses
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.showModalConfirm}
                    onHide={() => this.setState({ showModalConfirm: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Lakukan proses Setor Tunai?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirm: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.postSetorTunai();
                            }}
                            disabled={this.state.isLoading}
                        >
                            {this.state.isLoading ? 'Mohon Tunggu...' : 'Ya'}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalBatal}
                    onHide={() => this.setState({ showModalBatal: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Batalkan proses Setor Tunai?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalBatal: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.resetFormInput();
                                this.setState({ showModalBatal: false });
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSuccess}
                    onHide={() => this.setState({ showModalSuccess: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Selesai</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Proses Setor Tunai berhasil dilakukan</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalSuccess: false });
                                this.props.history.push(
                                    '/informasi-transaksi/informasi-transaksi-hari-ini'
                                );
                            }}
                        >
                            Selesai
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalError}
                    onHide={() => this.setState({ showModalError: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageError}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalError: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalWarning}
                    onHide={() => this.setState({ showModalWarning: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageWarning}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalWarning: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
                <ModalInfoNegatifNasabah
                    show={this.state.showModalInfoNegatif}
                    form={this.state.formNegatifNasabah}
                    onHide={() => {
                        this.setState({ showModalInfoNegatif: false });
                    }}
                    type="peragaan"
                    mode="view"
                />
            </>
        );
    }
}

export default withKeycloak(SetorTunai);
