import moment from 'moment';
import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { savePDF } from '@progress/kendo-react-pdf';
import Skeleton from 'react-loading-skeleton';
import useRekening from '../useRekening';
import FpdfStatement from '../components/fpdfStatement';
import { LoadingIcon } from '../../../../../controls';
import ExcelStatement from '../components/excelStatement';
import { htmlToExcel } from '../../../../../../module';
import { useReactToPrint } from 'react-to-print';
import './pdf.css'
import { TabgirTemplate } from '../../../../../PDFTemplate';


const ModalCetakStatement = () => {
    const { rekState, rekAction } = useRekening();
    const { dataCetakStatement = {}, loadingListTrx = false, dataAlert } = rekState;
    const tableRef = useRef(null);
    const refBtnExcel = useRef(null)

    const cetakStatementPDF = async () => {
        if (!rekState.modeReqStatement) {

            try {
                
                let a = await rekAction.getDataListStatement();
                
                if (tableRef.current !== null ) {
                    // handlePrint()
                    // console.log("masuk")
                    savePDF(tableRef.current,
                        {
                            paperSize: 'Letter',
                            // margin: { top: 15, left: 10, right: 10, bottom: 15 },
                            scale: 0.5,
                            fileName: `statement-${dataCetakStatement?.nomor_rekening}-${dataCetakStatement?.nama_rekening}`,
                            repeatHeaders: true,
                            margin: "1cm",
                            forcePageBreak:".page-break",
                            pageTemplate: ({pageNum, totalPages}) => <div
                                className='row'
                                style={{
                                    position: "absolute",
                                    width: "90%",
                                    bottom: "30px",
                                    }}
                            >
                                <div className='col'>
                                    <span className='float-left'> https://bjbsyariah.co.id </span>
                                    <span className='float-right'> halaman {pageNum} dari {totalPages} </span>
                                </div>

                            </div> 
                        },
                        () =>{ 
                            rekAction.closeCetakStatement()
                            rekAction.setLoadingListTrx(false)
                            rekAction.setdataCetakStatement(prev => ({ ...prev, trx: undefined }))
                        }
                    )
                }
            } catch (error) {
                alert(error.toString())
            }
        }else{
            await rekAction.getDataListStatement();

        }
        rekAction.setLoadingListTrx(false)
    }

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: () => `
            @media print {
                @page {
                    -webkit-print-color-adjust: exact; 
                    size: 210mm 187mm;
                }
            }
        `,
        documentTitle: 'Billyet Deposito Berjangka',
        onAfterPrint: () => {
            
        },
        bodyClass: '',
    });

    async function cetakStatementExcel() {
        await rekAction.getDataListStatement();
        if (refBtnExcel !== null) {
            htmlToExcel("table-to-xls", `statement-${dataCetakStatement?.nomor_rekening}-${dataCetakStatement?.nama_rekening}-${moment(Date.now()).format("YYYY-MM-DD")}`, "statement")
        }
    }

    return (
        <>
            {/* modal utama */}
            <Modal size={"s"} show={rekState.modalCetakStatement} onHide={rekAction.closeCetakStatement} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Cetak Statement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box mb-3">
                        <div className="row">
                            <div className="col-12 align-self-center bns-form">
                                <div className="form-group">
                                    <label>Cabang</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        readOnly
                                        placeholder="Otomatis terisi dari sistem"
                                        value={dataCetakStatement?.nama_cabang}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 align-self-center bns-form">
                                <div className="form-group">
                                    <label>Periode</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        // readOnly
                                        placeholder="Otomatis terisi dari sistem"
                                        disabled={loadingListTrx || !rekState.modeReqStatement || rekState.otorMode}
                                        value={dataCetakStatement?.periodeA}
                                        onChange={(e) => rekAction.setdataCetakStatement(prev => ({ ...prev, periodeA: e.target.value }))}
                                    />
                                </div>
                            </div>
                            <div className="col-6 align-self-center bns-form">
                                <div className="form-group">
                                    <label>Periode</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        // readOnly
                                        placeholder="Otomatis terisi dari sistem"
                                        disabled={loadingListTrx || !rekState.modeReqStatement || rekState.otorMode}
                                        onChange={(e) => rekAction.setdataCetakStatement(prev => ({ ...prev, periodeB: e.target.value }))}
                                        value={dataCetakStatement?.periodeB}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 align-self-center bns-form">
                                <div className="form-group">
                                    <label>No. Rekening</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        readOnly
                                        placeholder="Otomatis terisi dari sistem"
                                        value={dataCetakStatement?.nomor_rekening}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 align-self-center bns-form">
                                <div className="form-group">
                                    <label>Nama Rekening</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        readOnly
                                        placeholder="Otomatis terisi dari sistem"
                                        value={dataCetakStatement?.nama_rekening}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button
                                onClick={() => rekAction.closeCetakStatement()}
                                disabled={loadingListTrx}
                                className="btn btn-outline-danger float-right">
                                Batal
                            </button>

                            {
                                rekState.otorMode ? (
                                    <>

                                        <button
                                            onClick={() => rekAction.responseOtor("APPROVED")}
                                            className="btn btn-success mr-2">
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => rekAction.responseOtor("REJECTED")}
                                            className="btn btn-danger mr-2">
                                            Reject
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div style={{ display: "none" }}>
            
            
                                            <ExcelStatement />
                                        </div>
                                        <button
                                            onClick={() => cetakStatementPDF()}
                                            disabled={loadingListTrx || (
                                                dataCetakStatement?.nomor_rekening === "" ||
                                                dataCetakStatement?.nomor_rekening === null ||
                                                dataCetakStatement?.nomor_rekening === undefined 

                                            )}
                                            className="btn btn-success mr-2">
                                            {loadingListTrx ? <LoadingIcon /> : (rekState.modeReqStatement ? "Request Cetak" : "Cetak")}
                                        </button>
            
                                        {/* <button
                                            onClick={() => cetakStatementExcel()}
                                            disabled={loadingListTrx}
                                            className="btn btn-success mr-2">
                                            {loadingListTrx ? <LoadingIcon /> : "Cetak Excel"}
                                        </button> */}
                                    </>
                                ) 
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal utama */}

            {/* start export PDF */}
            {dataCetakStatement.trx &&
                <div className=''
                    style={{ position: 'absolute', left: '-1500px', top: '0', }}
                    >
                    <div ref={tableRef} >
                        <TabgirTemplate dataCetakStatement={dataCetakStatement} />
                    </div>
                </div>
            }
            {/* End export PDF */}
        </>
    );
}

export default ModalCetakStatement;