import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useFetch from '../../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../../../../hooks/keycloak.module';
import { addCommas } from '../../../../../../module';

const useJoinAccount = ({closeModalJoin = () => null}) => {

    const [formModal, setFomrModal] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(false);
    const { doGet, doPost, wait } = useFetch();
    const [pagination, setPagination] = useState({
        page: 1, show: 15
    });
    const [filter, setFilter] = useState({ filterBy: 0, filterType: 0, keyword: "" })
    const [listNasabah, setListNasabah] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [form, setForm] = useState({
        cabang_rekening: "",
        kode_cabang: "",
        kode_produk: "",
        kode_valuta: "",
        nama_cabang: "",
        nama_produk: "",
        nama_rekening: "",
        nomor_rekening: "",
        produk_rekening: "",
        tipe_join_account: null,
        valuta_rekening: "",
        _nasabahjoin: [
            // {
            //     "nomor_nasabah": "string",
            //     "nama_nasabah": "string",
            //     "nama_ibu_kandung": "string",
            //     "tanggal_lahir": "yyyy-mm-dd",
            //     "tempat_lahir": "string",
            //     "alamat": "string",
            //     "nomor_hp": "string"
            //   }
        ]
    });
    const { keycloak } = useKeycloak()


    function resetData() {
        setData({
            cabang_rekening: "",
            kode_cabang: "",
            kode_produk: "",
            kode_valuta: "",
            nama_cabang: "",
            nama_produk: "",
            nama_rekening: "",
            nomor_rekening: "",
            produk_rekening: "",
            tipe_join_account: null,
            valuta_rekening: "",
            _nasabahjoin: [
                // {
                //     "nomor_nasabah": "string",
                //     "nama_nasabah": "string",
                //     "nama_ibu_kandung": "string",
                //     "tanggal_lahir": "yyyy-mm-dd",
                //     "tempat_lahir": "string",
                //     "alamat": "string",
                //     "nomor_hp": "string"
                //   }
            ]
        })
    }

    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [formData, setFormData] = useState({
        cabang_rekening: "",
        saldo: 0,
        kode_valuta: "IDR",
        Status: ""
    })
    const paramUrl = useParams()


    async function getJoinAccount(nomor_rekening, isOtorisasi, mode = "") {

        setIsLoading(true)
        // console.log(isOtorisasi)
        var url = `/joinaccount/${nomor_rekening}`;
        if (isOtorisasi) url = `/joinaccount/otor-view/${nomor_rekening}`
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {

                    if (mode === "detil") {
                        // console.log(data)
                        setForm(p => ({
                            ...p,
                            kode_cabang: data.data_rekening.kode_cabang,
                            kode_produk: data.data_rekening.kode_produk,
                            kode_valuta: data.data_rekening.kode_valuta,
                            nama_cabang: data.data_rekening.nama_cabang,
                            nama_produk: data.data_rekening.nama_produk,
                            nama_rekening: data.data_rekening.nama_rekening,
                            nomor_rekening: data.data_rekening.nomor_rekening,
                            produk_rekening: data.data_rekening.produk_rekening,
                            _nasabahjoin:data.multiciflink
                        }))
                    }else if (isOtorisasi) {
                        // console.log(data)
                        setForm(p => ({
                            ...p,
                            kode_cabang: data.cabang_rekening,
                            kode_produk: "",
                            kode_valuta: data.valuta_rekening,
                            nama_cabang: data.nama_cabang,
                            nama_produk: data.produk_rekening,
                            nama_rekening: data.nama_rekening,
                            nomor_rekening: data.nomor_rekening,
                            produk_rekening: data.produk_rekening,
                            _nasabahjoin:data._nasabahjoin
                        }))
                    }
                    else  setForm(f => ({
                        ...data.data_rekening,
                        _nasabahjoin:data.multiciflink
                    }))
                    // if (isOtorisasi) getJoinAccount(data.nomor_rekening, false, "detil")
                    // setFormHoldDana(data)
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setIsLoading(false)
    }

    function setData(data) {
        setForm(f => ({
            ...f,
            ...data
        }))
    }
    // close Modal
    function closeModal() { setFomrModal(false) }
    // open Modal Add
    function openModalAdd() {
        // resetForm();
        setFomrModal(true);
    }

    function addList(params) {
        setForm(f => ({
            ...form,
            
            _nasabahjoin: [
                ...form._nasabahjoin,
                {...params, tipe_join_account:form.tipe_join_account}
            ]
        }))
    }
    function confirmClose(key) {
        // console.log(form)
        let body = `Hapus Join akun. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", 
                //  onClick: (e, i) =>hapusJoin(i),
                onClick: () => hapusJoin(key), 
                className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
        // const _j = form._nasabahjoin.filter((d, i) => i !== key);
        // setForm(f => ({
        //     ...f,
        //     _nasabahjoin: _j
        // }))
    }
    function hapusJoin(key) {
        closeConfirm();
        const _j = form._nasabahjoin.filter((d, i) => i !== key);
        setForm(f => ({
            ...f,
            _nasabahjoin: _j
        }))
        if (_j) {
            setDataAlert({
                show: true,
                body: "Hapus join account berhasil, silahkan proses untuk melanjutkan",
                title: '',
                header: "Success",
                callBack: () => {
                    closeAlert();

                }
            });
        }
    }
    // tombol cari
    async function searchNasabah() {
        setLoadingSearch(true)
        var url = `/nasabah/search`;
        if (parseInt(filter.filterType) == 1) url = `/nasabah-badan-usaha/search`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                queryParam: { ...pagination, ...filter}

            });

            if (statusCode === 200) {
                if (status === "00") {
                    setListNasabah(data?.listNasabah)
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        setLoadingSearch(false)
    }

    useEffect(function () {
        searchNasabah()
    }, [pagination])

    //  reset
    useEffect(function () {
        setListNasabah([])
    }, [filter])

    // next pagination
    function nextPage({ page, limit }) {
        // console.log(page)
        setPagination({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
    
    function confirmTrx() {
        // console.log(form)
        let body = `Periksa kembali perubahan join account. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // post ambil kas
    async function doTrx() {

        closeConfirm();
        // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();

        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }

        if (formData.saldo !== 0) {
            setDataAlert({ show: true, body: "Saldo lebih dari 0. Tidak dapat tutup kas", title: `Sisa Saldo ${addCommas(parseFloat(formData.saldo).toFixed(2))}`, header: "Info", callBack: () => null });
            return false
        }
        openLoading()
        // const body = {
        //     ...form,
        //     _nasabahjoin: form._nasabahjoin.map(({
        //         nomor_nasabah,
        //         nama_ibu_kandung,
        //         tanggal_lahir,
        //         tempat_lahir,
        //         alamat,
        //         nama_nasabah
        //     }) => ({
        //         "nomor_nasabah": "string",
        //         "nama_nasabah": "string",
        //         "nama_ibu_kandung": "string",
        //         "tanggal_lahir": "yyyy-mm-dd",
        //         "tempat_lahir": "string",
        //         "alamat": "string",
        //         "nomor_hp": "string"
        //     }))
        // }
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username,
                    "x-token": keycloak.idToken
                },
                data: {
                    "nomor_rekening": form.nomor_rekening,
                    "nama_rekening": form.nama_rekening,
                    "produk_rekening": form.nama_produk,
                    "valuta_rekening": form.kode_valuta,
                    "cabang_rekening": form.kode_cabang,
                    "nama_cabang": form.nama_cabang,
                    "tipe_join_account": form.tipe_join_account,
                    "_nasabahjoin": form._nasabahjoin
                },
                url: "/joinaccount", // ganti sesuai api
                service: "acc" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: "Perubahan Join Account Berhasil, butuh otorisasi dari SO untuk melanjutkan", title: ``, header: "Success", callBack: () => {
                        closeConfirm();
                        closeModalJoin();
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }
    function popUpJenis() {
        setDataAlert({
            show: true, body: "Tipe Join account tidak boleh kosong", title: ``, header: "Warning", callBack: () => {
                closeAlert();
            }
        });
    }


    return {
        jState: {
            formModal,
            filter,
            loadingSearch,
            pagination,
            listNasabah,
            form,
            isLoading
        },
        jAction: {
            closeModal,
            openModalAdd,
            setFilter,
            searchNasabah,
            setPagination,
            nextPage,
            prevPage,
            changeShow,
            setData,
            addList,
            hapusJoin,
            confirmTrx,
            confirmClose,
            getJoinAccount,
            resetData,
            popUpJenis
        }
    };
}

export default useJoinAccount;