import React, { memo,useEffect } from 'react';
import penerimaanNegaraLogo from '../../../img/bayardaerah.png'
// import BasePagePay from '../basePagePay';
import { useBasePageState, BasePagePay } from '../../controls';


const nom = [
    {
        id:1,
        providerId:1,
        nominal:10000,
        harga:12000
    }
]

const IconHeader = memo(function(){
    return (
        <>
            <img src={penerimaanNegaraLogo} alt="Logo" width={40} />
        </>
    )
})

const penerimaanNegaraPage = memo((props) => {
    const [basePageState, baseDispatch, basePageAction] = useBasePageState();

    useEffect(()=>{
        basePageAction.setState({nominals:nom})
    },[])

    const setNominal = function(value){
        basePageAction.setForm("nominal", value.harga)
        basePageAction.setOpenPembayaran()
        
    }

    const setProvider = function(value){
        basePageAction.setForm("providerId", value)
        const newNominalList = nom.filter(nom => nom.providerId === parseInt(value)) 
        basePageAction.setNominalList(newNominalList)
    }
    const setPelanggan = function(value){
        basePageAction.setForm("pelanggan", value)
    }

    const bayar = function(){
        basePageAction.bayar()
    }

    const opt = [
        {
            display : "",
            value : 2,
            forms: [{
                type:"select",
                label:"Jenis Pajak",
                value:basePageState.formData.providerId,
                onChange:setProvider,
                displayKey:"name",
                valueKey:"id",
                required:true,
                readOnly:false,
                options : [
                    {
                        name : "Pajak Online",
                        id : 1,
                    },
                    {
                
                        name : "Bea Cukai",
                        id : 2,
                    },
                    {
                
                        name : "Bayar PNBP",
                        id : 3,
                    }
                ]

            },{
                type: "text",
                label: "Kode Billing",
                value:  basePageState.formData.pelanggan,
                required: true,
                onChange: (v)=>{ 
                    setPelanggan(v)
                    basePageAction.setOpenPembayaran()
                 },
                readOnly: false

            }],
            selectNominal: false,
            onPay:bayar

        }
    ]
    
    return (
        <React.Fragment>
            <BasePagePay
                options = {opt}
                title ={<span><IconHeader/> Penerimaan Negara</span>}
                nominals={nom}
                paymentCardData={{
                    detilBody: [
                        {
                            key: "Jenis Layanan",
                            value: "Penerimaan Negara"
                        },{
                            key: "Kode Billing",
                            value: "123456789"
                        },{
                            key: "ID Pelanggan",
                            value: "80312241741871"
                        },{
                            key: "Nama Pelanggan",
                            value: "Mayuga Wicaksana"
                        },
                    ],
                    detilFooter: [
                        {
                            key: "Harga",
                            value: 235500
                        },
                        {
                            key: "Admin",
                            value: 2500
                        }
                    ],
                    total: 237500
                }}
            />
        </React.Fragment>
    );
});

export default penerimaanNegaraPage;