// import { useKeycloak } from '@react-keycloak/web';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
import useKeycloak from '../../../../../hooks/keycloak.module';

const paContext = createContext(null)

export const FdProvider = ({ children }) => {

    const { doGet, doPost } = useFetch();

    const { keycloak } = useKeycloak();
    const [otorDesc, setOtorDesc] = useState("");
    const location = useLocation();
    const history = useHistory();
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        openLoading,
        closeConfirmCustom,
        openAlertCustom
    } = modalAction;

    const [formData,setFormData] = useState({
        "nomor_rekening": "",
        "nominal": "0.00",
        "info_transaksi": {
            "nama_rekening": "",
            "sequence_number": 0,
            "jenis_pembayaran": "",
            "akad": "",
            "prod_pembiayaan": "",
            "margin": 0,
            "pokok": 0
            }
    });

    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            setFormEditable(true);
            getDataOtor();
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
        } else {
            setFormEditable(false);
            resetForm();
        }
        // } 
        setFormEditable((paramUrl.mode !== "otorisasi"));
        // // console.log(paramUrl.mode !== "otorisasi")
    }, [paramUrl.mode])
    
    
    function changeForm(value, key, subkey = null, grandSubKey = null) {
        value = value.toUpperCase()
        if (grandSubKey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [grandSubKey]: { ...prevState[subkey][grandSubKey], [key]: value } } }))
        else if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    // fungsi cek nasabah 
    async function cekNasabah(nomor_rekening) {
        // console.log(paramUrl.mode)
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/rekening/rek-fin-for-trx/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, remark } = resp;

            if (statusCode === 200) {

                if (status === "00"&& remark === "Succes") {

                    setFormData(prevState => ({
                        ...prevState,
                        "info_transaksi": {
                            "nama_rekening": resp.nama_rekening,
                            "sequence_number": resp.angsuran,
                            "akad": resp.akad,
                            "prod_pembiayaan": resp.product_code +" - "+resp.product_name,
                            "margin": resp.margin,
                            "pokok": resp.pokok
                            }
                    }))
                }else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "nomor_rekening") });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "nomor_rekening") });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => changeForm("", "nomor_rekening") });
        }
        closeLoading();
    }

    function validasi(fieldRequired = null) {
        //  field required lists
        if (fieldRequired === null) {
            fieldRequired = [   "nomor_rekening", 
                                "nominal"
                            ];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    function confirmTrx() {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => postPembiayaan(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // fungsi post nasabah
    async function postPembiayaan() {
        // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        const err = validasi();
        if (err) {
            return setDataAlert({ show: true, body: "Sebelum melanjutkan proses pembiayaan, pastikan data yang anda masukan sudah benar dan terisi lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: {...formData, uid : keycloak.uid},
                url: "/financing", // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            // console.log(resp)
            const { statusCode, remark = "", data = " ", status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    const id_transaksi = data.transaksi.id_transaksi;
                    // console.log(id_transaksi);
                    setDataAlert({
                        show: true, body: remark, title: ``, header: "Success", callBack: () => {
                            closeConfirm();
                            resetForm();
                            keycloak.updateUID()
                        }
                    });
                }
                else if(status === "05"){ 
                    closeConfirm();
                    resetForm();
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null }); 
        }
        closeLoading()
    }

    async function getDataOtor(dbId = location.state?.dbId) {

        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/financing/${dbId}`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    // // console.log(data)
                    setFormData(prevState => ({
                        ...prevState,
                        "nomor_rekening": data.nomor_rekening,
                        "nominal": parseFloat(data?.nominal || 0).toFixed(2),
                        "info_transaksi": {
                            "nama_rekening": data.info_transaksi.nama_rekening,
                            "sequence_number": data.info_transaksi.sequence_number,
                            "jenis_pembayaran": data.info_transaksi.jenis_pembayaran,
                            "akad": data.info_transaksi.akad,
                            "prod_pembiayaan": data.info_transaksi.prod_pembiayaan,
                            "margin": data.info_transaksi.margin,
                            "pokok": data.info_transaksi.pokok
                            }
                    }))
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
        // // console.log(data)
    }

    // response otorisasi
    function responseOtor(status) {
        // // console.log("yaa")
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); else setDataAlert({ show: true, body: remark, title: '', header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }



    function resetForm() {
        setFormData({
            "nomor_rekening": "",
            "nominal": "0.00",
            "info_transaksi": {
                "nama_rekening": "",
                "sequence_number": 0,
                "jenis_pembayaran": "",
                "akad": "",
                "prod_pembiayaan": "",
                "margin": 0,
                "pokok": 0
                }
        });
    }

    return <paContext.Provider value={{
        paState: {
            formData,
            formEditable,
            otorDesc
        },
        paAction: {
            cekNasabah,
            changeForm,
            postPembiayaan,
            resetForm,
            confirmTrx,
            responseOtor,
            setOtorDesc
        }
    }} >{children}</paContext.Provider>
}

export default function usePembayaranAngsuran() { return useContext(paContext) };