import React from 'react';
import { Modal } from 'react-bootstrap';
import { addCommas } from '../../../../../module';
import { TableList } from '../../../../controls';
import useAmbilKasLain from './useAmbilKasLain';

const { Title, Header, Body, Footer } = Modal;
const ModalListTeller = () => {
    const { aklAction, aklState } = useAmbilKasLain()
    return (
        <Modal size='xl' show={aklState.showModalTeller} backdrop="static" >
            <Header closeButton onHide={aklAction.closeModalTeller}>
                <Title>List Teller</Title>
            </Header>
            <Body>
                <TableList
                    withCard={false}
                    filter={aklState.showModalTeller}
                    formsFilter={[
                        {
                            type: 'text',
                            placeholder: 'Keyword',
                            disabled: false,
                            readonly: false,
                            onChange: (e) => aklAction.setFilter({ keyword: e }),
                            value: aklAction.filter?.keyword
                        }
                    ]}
                    resetFilter={false}
                    onFilter={() => aklAction.filterTeller()}
                    isLoading={aklState.loadingTeller}
                    data={aklState.listTeller || []}
                    columns={
                        [
                            {
                                name: 'ID User',
                                field: 'id_user',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Nomor Rekening',
                                field: 'nomor_rekening',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Nama Cabang',
                                field: 'nama_cabang',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Kode Cabang',
                                field: 'kode_cabang',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Nama Teller',
                                field: 'nama_user',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Saldo',
                                field: 'saldo',
                                maxWidth: '175px',
                                className:"text-right",
                                onRender: (item) => addCommas(parseFloat(item.saldo).toFixed(2))
                            },
                        ]}
                    pagination={true}
                    withAction={true}
                    actionItems={
                        [
                            {
                                name: 'Pilih Teller',
                                onClick: async (e) => {
                                    aklAction.pilihTeller(e)
                                    // let get = false;
                                    // if (ftAction.pilihNasabah) get = await ftAction?.pilihNasabah(e, pdrState.filter.filterType);
                                    // if (get) pdrAction.closeModal()
                                },
                                className: "text-danger"
                            },
                        ]}
                    onChangeShow={aklAction.changeShow}
                    onNextPage={aklAction.nextPageTeller}
                    onPrevPage={aklAction.prevPageTeller}
                    curPage={aklState.paginationTeller?.page}

                />
            </Body>
            <Footer>
                <div className='row'>
                    <div className='col'>
                        <button onClick={aklAction.closeModalTeller} className='btn btn-sm float-right btn-default'>
                            Close
                        </button>
                    </div>
                </div>
            </Footer>
        </Modal>
    );
}

export default ModalListTeller;