import { useState, useMemo, forwardRef, useRef } from 'react';
import { Button, Breadcrumb } from 'semantic-ui-react';
import { FormControl, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { baseUrl } from '../../../../app.config';
import { FormHeader, TableGrid } from '../../../controls';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';

const InformasiTransaksiHariIniPage = () => {
    const [active, setActive] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [jenisTransaksi, setJenisTransaksi] = useState('');
    const [status, setStatus] = useState('');
    const [printProps, setPrintProps] = useState({});

    const componentToPrintARef = useRef(null);
    const componentToPrintBRef = useRef(null);

    const handlePrintA = useReactToPrint({
        content: () => componentToPrintARef.current,
        onBeforePrint: async () => {
            // actual mock fetch to make sure data is fetched before the print popup showed up
            await fetch(baseUrl + `/otorisasi/transaksi/informasi/detail/${printProps.id}`);
        },
        pageStyle: `
            @media print {
                @page { size: portrait !important; }
            }
        `,
    });

    const handlePrintB = useReactToPrint({
        content: () => componentToPrintBRef.current,
        onBeforePrint: async () => {
            // actual mock fetch to make sure data is fetched before the print popup showed up
            await fetch(baseUrl + `/otorisasi/transaksi/informasi/detail/${printProps.id}`);
        },
        pageStyle: `
            @media print {
                @page { size: portrait !important; }
            }
        `,
    });

    const parseData = (arrayData) => {
        const list = [];
        for (let data of arrayData) {
            const debit = {};
            const kredit = {};

            const formatNumber = (number) =>
                new Intl.NumberFormat('id-ID', {
                    currency: 'IDR',
                }).format(number);

            debit.cabang = data.cabang_debit;
            debit.nama = data.nama_debit;
            debit.no_rekening = data.no_rekening_debit;
            debit.keterangan = data.keterangan;
            debit.valuta = data.valuta_debit;
            debit.mutasi = 'DEBIT';
            debit.nilai_transaksi = formatNumber(data.nilai_transaksi_debit);
            debit.kurs = data.kurs_debit;
            debit.nilai_ekuivalen = formatNumber(data.nilai_ekuivalen_debit);

            kredit.cabang = data.cabang_kredit;
            kredit.nama = data.nama_kredit;
            kredit.no_rekening = data.no_rekening_kredit;
            kredit.keterangan = data.keterangan;
            kredit.valuta = data.valuta_kredit;
            kredit.mutasi = 'KREDIT';
            kredit.nilai_transaksi = formatNumber(data.nilai_transaksi_kredit);
            kredit.kurs = data.kurs_kredit;
            kredit.nilai_ekuivalen = formatNumber(data.nilai_ekuivalen_kredit);

            list.push(debit, kredit);
        }

        return list;
    };

    const { data, isFetchedAfterMount } = useQuery(
        ['info-transaksi-hari-ini', limit, currentPage, status, jenisTransaksi],
        async () => {
            const filter = [];
            if (status) filter.push(`status=${status}`);
            if (jenisTransaksi) filter.push(`jenis_transaksi=${jenisTransaksi}`);

            if (filter.length > 0) filter[0] = `&${filter[0]}`;

            const res = await fetch(
                baseUrl +
                    `/otorisasi/transaksi/informasi/all?page=${currentPage}&limit=${limit}${filter.join(
                        '&'
                    )}`
            );

            const data = await res.json();

            return Array.from(data.list ?? [], ({ nilai_transaksi, ...otherProps }) => ({
                ...otherProps,
                nilai_transaksi: nilai_transaksi
                    ? new Intl.NumberFormat('id-ID').format(nilai_transaksi)
                    : '-',
            }));
        }
    );

    const { data: activeData } = useQuery(['detail-transaksi', active], async () => {
        if (active) {
            const res = await fetch(baseUrl + `/otorisasi/transaksi/informasi/detail/${active}`);

            const data = await res.json();

            return parseData(data.list);
        }

        return null;
    });

    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    return (
        <>
            <div style={{ display: 'none' }}>
                <ComponentToPrintA ref={componentToPrintARef} state={printProps} />
                <ComponentToPrintB ref={componentToPrintBRef} state={printProps} />
            </div>
            <Modal centered size="xl" show={Boolean(active)}>
                <Modal.Header onHide={() => setActive('')} closeButton className="tw-border-0">
                    <FormHeader className="tw-w-full">Detail Transaksi</FormHeader>
                </Modal.Header>
                <Modal.Body className="tw-px-0">
                    <TableGrid
                        config={{
                            stickyHead: true,
                            height: '50vh',
                            overflowY: 'auto',
                        }}
                        className="tw-overflow-x-auto"
                        columns={[
                            {
                                name: 'Cabang',
                                field: 'cabang',
                                width: '40px',
                            },
                            {
                                name: 'Nama',
                                field: 'nama',
                                width: '0.5fr',
                            },
                            {
                                name: 'Rekening',
                                field: 'no_rekening',
                                width: '80px',
                            },
                            {
                                name: 'Keterangan',
                                field: 'keterangan',
                                width: '1fr',
                            },
                            {
                                name: 'Kode Valuta',
                                field: 'valuta',
                                width: '60px',
                            },
                            {
                                name: 'Mutasi',
                                field: 'mutasi',
                                width: '60px',
                            },
                            {
                                name: 'Nilai',
                                field: 'nilai_transaksi',
                                width: '0.3fr',
                            },
                            {
                                name: 'Kurs',
                                field: 'kurs',
                                width: '40px',
                            },
                            {
                                name: 'Nilai Ekuivalen',
                                field: 'nilai_ekuivalen',
                                width: '0.3fr',
                            },
                        ]}
                        data={activeData}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setActive()} size="mini">
                        Tutup
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Informasi Transaksi
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">
                    Informasi Transaksi Hari Ini
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Informasi Transaksi Hari Ini
                </div>
                <p className="tw-text-12 tw-px-7">Cari data berdasarkan :</p>
                <div className="tw-grid tw-grid-cols-6 tw-px-7 tw-mb-6 tw-gap-4">
                    <FormControl
                        value={jenisTransaksi}
                        onChange={(e) => setJenisTransaksi(e.target.value)}
                        as="select"
                        size="sm"
                    >
                        <option hidden disabled selected value="">
                            Pilih Jenis Transaksi
                        </option>
                        <option value="Setor Tunai">Setor Tunai</option>
                        <option value="Tarik Tunai">Tarik Tunai</option>
                        <option value="Setor Deposito">Setor Deposito</option>
                        <option value="Transaksi Umum">Transaksi Umum</option>
                        <option value="Transaksi Umum Adjustment">Transaksi Umum Adjustment</option>
                        <option value="Pindah Buku">Pindah Buku</option>
                        <option value="SKN Tunai">SKN Tunai</option>
                        <option value="RTGS Tunai">RTGS Tunai</option>
                        <option value="SKN Debit">SKN Debit</option>
                        <option value="RTGS Debit">RTGS Debit</option>
                        <option value="Rek to Rek">Rek to Rek</option>
                        <option value="GL to Rek">GL to Rek</option>
                        <option value="Rek to GL">Rek to GL</option>
                        <option value="GL to GL">GL to GL</option>
                        <option value="Setor Kas Khasanah">Setor Kas Khasanah</option>
                        <option value="Ambil Kas Khasanah">Ambil Kas Khasanah</option>
                    </FormControl>
                    <FormControl
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        as="select"
                        size="sm"
                    >
                        <option hidden disabled selected value="">
                            Pilih Status
                        </option>
                        <option value="APPROVED">Approved</option>
                        <option value="REJECTED">Rejected</option>
                        <option value="PENDING">Pending</option>
                    </FormControl>
                    <Button
                        onClick={() => {
                            setStatus('');
                            setJenisTransaksi('');
                        }}
                        basic
                        color="blue"
                        size="mini"
                    >
                        Reset
                    </Button>
                </div>
                <TableGrid
                    active={(data) => data.id === active}
                    columns={[
                        {
                            name: 'Jam Input',
                            field: 'created_at',
                            width: '100px',
                        },
                        {
                            name: 'User',
                            field: 'id_user',
                            width: '0.5fr',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                        },
                        {
                            name: 'No. Referensi',
                            field: 'no_referensi',
                            width: '0.5fr',
                        },
                        {
                            name: 'Jenis Transaksi',
                            field: 'type',
                            width: '0.5fr',
                        },
                        {
                            name: 'Nilai Transaksi',
                            field: 'nilai_transaksi',
                            width: '0.5fr',
                        },
                        {
                            name: 'Status Otorisasi',
                            field: 'status_otorisasi',
                            width: '120px',
                        },
                        {
                            name: '',
                            field: 'action',
                            width: '0.7fr',
                        },
                    ]}
                    data={data}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'created_at')
                            return (
                                <div key={`${value}-${field}`} className={clsx(className)}>
                                    {dayjs(value).format('HH:mm:ss')}
                                </div>
                            );

                        if (field === 'action')
                            return (
                                <div
                                    key={`${value}-${field}`}
                                    className="tw-flex tw-items-center tw-gap-6"
                                >
                                    <div
                                        onClick={() => setActive(data.id)}
                                        role="button"
                                        className={clsx(
                                            className,
                                            'tw-text-complimentary tw-cursor-pointer'
                                        )}
                                    >
                                        Lihat Detail
                                    </div>
                                    {[
                                        'Tarik Tunai',
                                        'Setor Tunai',
                                        'Pindah Buku',
                                        'SKN Debit',
                                        'SKN Tunai',
                                        'RTGS Debit',
                                        'RTGS Tunai',
                                    ].includes(data.type) ? (
                                        <div
                                            onClick={() => {
                                                setPrintProps(data);
                                                // run the print after printProps state updated
                                                setTimeout(() => {
                                                    if (
                                                        ['Tarik Tunai', 'Setor Tunai'].includes(
                                                            data.type
                                                        )
                                                    )
                                                        handlePrintA();
                                                    else handlePrintB();
                                                }, 100);
                                            }}
                                            role="button"
                                            key={`${value}-${field}`}
                                            className={clsx(
                                                className,
                                                'tw-text-primary tw-cursor-pointer'
                                            )}
                                        >
                                            Print Detail
                                        </div>
                                    ) : null}
                                </div>
                            );
                    }}
                    config={{
                        stickyHead: true,
                        height: '60vh',
                        overflowY: 'auto',
                    }}
                    isLoading={!isFetchedAfterMount}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-grid tw-grid-cols-3 tw-items-center tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            onChange={(e) => setLimit(e.target.value)}
                            as="select"
                            size="sm"
                            className="tw-border-0 tw-w-max"
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        containerClassName="pagination tw-text-12 tw-m-0 tw-justify-self-center"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-cursor-pointer focus:tw-outline-none tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        initialPage={0}
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12 tw-text-right">Menampilkan {data?.length} data</div>
                </div>
            </div>
        </>
    );
};

export default InformasiTransaksiHariIniPage;

const ComponentToPrintA = forwardRef(({ state }, ref) => {
    const { data } = useQuery(['detail-transaksi-print', state.id], async () => {
        const res = await fetch(baseUrl + `/otorisasi/transaksi/informasi/detail/${state.id}`);

        const data = await res.json();

        return data?.list[0];
    });

    return (
        <div className="tw-m-3" ref={ref}>
            <div className="tw-mb-3">Transaction ID : {state.no_referensi}</div>
            <TableGrid
                columns={[
                    {
                        name: 'Tanggal Transaksi',
                        field: 'tanggalTransaksi',
                    },
                    {
                        name: 'Waktu Transaksi',
                        field: 'waktuTransaksi',
                    },
                    {
                        name: 'Nomor Rekening',
                        field: 'nomorRekening',
                    },
                    {
                        name: 'Nama Rekening',
                        field: 'namaRekening',
                    },
                    {
                        name: 'Jenis Transaksi',
                        field: 'jenisTransaksi',
                    },
                    {
                        name: 'Nominal Transaksi',
                        field: 'nominalTransaksi',
                    },
                ]}
                data={[
                    {
                        tanggalTransaksi: dayjs(state.created_at).format('DD/MM/YYYY'),
                        waktuTransaksi: dayjs(state.created_at).format('HH:mm:ss'),
                        nomorRekening:
                            state.type === 'Tarik Tunai'
                                ? data?.no_rekening_debit
                                : data?.no_rekening_kredit,
                        namaRekening:
                            state.type === 'Tarik Tunai' ? data?.nama_debit : data?.nama_kredit,
                        jenisTransaksi: state.type,
                        nominalTransaksi: state.nilai_transaksi,
                    },
                ]}
                config={{
                    headerBackground: 'transparent',
                }}
            />
        </div>
    );
});

const ComponentToPrintB = forwardRef(({ state }, ref) => {
    const { data } = useQuery(['detail-transaksi-print', state.id], async () => {
        const res = await fetch(baseUrl + `/otorisasi/transaksi/informasi/detail/${state.id}`);

        const data = await res.json();

        return data?.list[0];
    });

    return (
        <div className="tw-m-3" ref={ref}>
            <div className="tw-mb-3">Transaction ID : {state.no_referensi}</div>
            <TableGrid
                columns={[
                    {
                        name: 'Tanggal Transaksi',
                        field: 'tanggalTransaksi',
                    },
                    {
                        name: 'Waktu Transaksi',
                        field: 'waktuTransaksi',
                    },
                    {
                        name: 'Nomor Rekening Sumber',
                        field: 'nomorRekeningSumber',
                    },
                    {
                        name: 'Nama Rekening Sumber',
                        field: 'namaRekeningSumber',
                    },
                    {
                        name: 'Nomor Rekening Tujuan',
                        field: 'nomorRekeningTujuan',
                    },
                    {
                        name: 'Nama Rekening Tujuan',
                        field: 'namaRekeningTujuan',
                    },
                    {
                        name: 'Jenis Transaksi',
                        field: 'jenisTransaksi',
                    },
                    {
                        name: 'Nominal Transaksi',
                        field: 'nominalTransaksi',
                    },
                ]}
                data={[
                    {
                        tanggalTransaksi: dayjs(state.created_at).format('DD/MM/YYYY'),
                        waktuTransaksi: dayjs(state.created_at).format('HH:mm:ss'),
                        nomorRekeningSumber: data?.no_rekening_debit,
                        namaRekeningSumber: data?.nama_debit,
                        nomorRekeningTujuan: data?.no_rekening_kredit,
                        namaRekeningTujuan: data?.nama_kredit,
                        jenisTransaksi: state.type,
                        nominalTransaksi: state.nilai_transaksi,
                    },
                ]}
                config={{
                    headerBackground: 'transparent',
                }}
            />
        </div>
    );
});
