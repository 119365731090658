import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import useSVS from "../useSvs";
import moment from "moment";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ModalSVS = () => {
  const { svsState, svsAction } = useSVS();

  const [degree, setDegree] = useState(0);
  const [zoom, setZoom] = useState(1);

  const rotate = () => {
    setDegree(degree + 90);
    if (degree >= 270) {
      setDegree(0);
    }
    // renderTransform();
  };

  const zoomIn = () => {
    setZoom(zoom + 0.1);
    if (zoom >= 2) {
      setZoom(2);
    }
    // renderTransform();
  };

  const zoomOut = () => {
    setZoom(zoom - 0.1);
    if (zoom <= 0.5) {
      setZoom(0.5);
    }
    // renderTransform();
  };

  useEffect(() => {
    setDegree(0)
  }, [svsState.modalSVS])

  // const renderTransform = () => {
  //   return `rotate(${degree}deg) scale(${zoom})`;
  // };

  // const renderCloseButton = () => {
  //   if (svsState.otorMode) {
  //     return (
  //       <>
  //         <button
  //           className="btn btn-success mr-3"
  //           onClick={() =>
  //             svsAction.responseOtor(
  //               svsState.svsOtorView.status === "DELETE" ? "DELETED" : "APPROVED"
  //             )
  //           }>
  //           {svsState.svsOtorView.status === "DELETE" ? "Delete" : "Approve"}
  //         </button>
  //         <button
  //           className="btn btn-danger mr-3"
  //           onClick={() => svsAction.responseOtor("REJECTED")}>
  //           Reject
  //         </button>
  //         <button
  //           className="btn btn-outline-success"
  //           onClick={() => {
  //             svsAction.closeModalSVS();
  //           }}>
  //           Tutup
  //         </button>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         {svsState.nasabahInfo.cust?.jenis_nasabah !== "I" ? (
  //           <button
  //             className="btn btn-outline-danger mr-3"
  //             onClick={() =>
  //               svsAction.setDataConfirm({
  //                 show: true,
  //                 body: "Yakin ingin hapus data SVS ini?",
  //                 witTextArea: false,
  //                 header: "Konfirmasi",
  //                 title: null,
  //                 closeButton: false,
  //                 footerButton: [
  //                   {
  //                     name: "Ya",
  //                     onClick: () => svsAction.setDeleteSVS(svsState.svsOtorView.db_id),
  //                     className: "btn btn-sm btn-success",
  //                   },
  //                   {
  //                     name: "Tidak",
  //                     onClick: () => svsAction.closeConfirm(),
  //                     className: "btn btn-sm btn-secondary",
  //                   },
  //                 ],
  //               })
  //             }>
  //             Hapus
  //           </button>
  //         ) : null}
  //         <button className="btn btn-link mr-3" onClick={() => svsAction.closeModalSVS()}>
  //           Tutup
  //         </button>
  //       </>
  //     );
  //   }
  // };

  return (
    <>
      {/* modal utama */}
      <Modal size="md" show={svsState.modalSVS} onHide={svsAction.closeModalSVS}>
        <Modal.Header closeButton>
          <Modal.Title>{svsState.otorMode ? "Otorisasi SVS" : "Detail SVS"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2rem" }}>
          <table className="table table-borderless">
            {svsState.otorMode ? (
              <>
                <tr>
                  <td>No. CIF</td>
                  <td>: {svsState.svsOtorView.nomor_cif}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    : <b>{svsState.svsOtorView.status}</b>
                  </td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td>: {moment(svsState.svsOtorView.created_at).format("DD-MM-YYYY HH:mm")}</td>
                </tr>
              </>
            ) : null}
            <tr>
              <td>Image Tag</td>
              <td>: {svsState.svsOtorView.image_tag}</td>
            </tr>
            <tr>
              <td>Remark</td>
              <td>: {svsState.svsOtorView.remark}</td>
            </tr>
          </table>
          <div
            style={{
              border: "1px solid rgb(0 0 0 / 11%)",
              borderRadius: "5px",
              backgroundColor: "#eeee",
              overflow: "scroll",
            }}>
            <img
              src={svsState.svsOtorView.signed_url}
              style={{
                width: "100%",
                aspectRatio: '1/1',
                objectFit: "contain",
                transform: `rotate(${degree}deg) scale(${zoom})`,
              }}
            />
            {/* <TransformWrapper>
              <TransformComponent wrapperStyle={{ width:'100%',minHeight:'300px' }}>
                
              </TransformComponent>
            </TransformWrapper> */}
          </div>
          <div className="d-flex mt-3 justify-content-center">
            <button className="btn">
              <i className="fas fa-plus fa-lg" onClick={() => zoomIn()}></i>
            </button>
            <button className="btn">
              <i className="fas fa-undo-alt fa-flip-horizontal fa-lg" onClick={() => rotate()}></i>
            </button>
            <button className="btn">
              <i className="fas fa-minus fa-lg" onClick={() => zoomOut()}></i>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end align-items-center">
            {
              svsState.otorMode ? (
                <>
                  <button
                    className="btn btn-success mr-3"
                    onClick={() =>
                      svsAction.responseOtor(
                        svsState.svsOtorView.status === "DELETE" ? "DELETED" : "APPROVED"
                      )
                    }>
                    {svsState.svsOtorView.status === "DELETE" ? "Delete" : "Approve"}
                  </button>
                  <button
                    className="btn btn-danger mr-3"
                    onClick={() => svsAction.responseOtor("REJECTED")}>
                    Reject
                  </button>
                  <button
                    className="btn btn-outline-success"
                    onClick={() => {
                      svsAction.closeModalSVS();
                    }}>
                    Tutup
                  </button>
                </>
              ) : (
                <>
                  {svsState.nasabahInfo.cust?.jenis_nasabah !== "I" ? (
                    <button
                      className="btn btn-outline-danger mr-3"
                      onClick={() =>
                        svsAction.setDataConfirm({
                          show: true,
                          body: "Yakin ingin hapus data SVS ini?",
                          witTextArea: false,
                          header: "Konfirmasi",
                          title: null,
                          closeButton: false,
                          footerButton: [
                            {
                              name: "Ya",
                              onClick: () => svsAction.setDeleteSVS(svsState.svsOtorView.db_id),
                              className: "btn btn-sm btn-success",
                            },
                            {
                              name: "Tidak",
                              onClick: () => svsAction.closeConfirm(),
                              className: "btn btn-sm btn-secondary",
                            },
                          ],
                        })
                      }>
                      Hapus
                    </button>
                  ) : null}
                  <button className="btn btn-link mr-3" onClick={() => svsAction.closeModalSVS()}>
                    Tutup
                  </button>
                </>
              )
            }

          </div>
        </Modal.Footer>
      </Modal>
      {/* end modal utama */}
    </>
  );
};

export default ModalSVS;
