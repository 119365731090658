import moment from 'moment';
import React from 'react';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormPerorangan from './useFormPerorangan';

const BeneficiaryOwner = () => {
    const { fpAction, fpState } = useFormPerorangan();
    const { formData = {}, formEditable = false } = fpState;
    return ( 
        <>
         <div className="box">
                <div className="row">
                    <div className="col-4 align-self-center bns-form">
                        <div className="form-group">
                            <label>Jenis Beneficiary Owner</label>
                            <input
                                className="form-control"
                                type="text"
                                readOnly
                                placeholder="Individu"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FormRegistration
                 itemsForm={[
                    // Beneficiary
                    {
                        name: "Data Beneficiary",
                        rows: [
                            {
                                rows: 1,
                                label: 'Nama Lengkap',
                                type: 'text',
                                placeholder: 'Nama Lengkap',
                                value: fpState.formData?._beneficiaryIndividu?.benefNama,
                                onChange: (value) => fpAction.changeForm(value, "benefNama","_beneficiaryIndividu"),
                                required: false,
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tempat/Tanggal Lahir',
                                                    type: 'text',
                                                    placeholder:"Masukkan tempat lahir",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefBirthPlace,
                                                    onChange: (value) => fpAction.changeForm(value, "benefBirthPlace","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: <>&nbsp;</>,
                                                    type: 'date',
                                                    value: formData.birthDate,
                                                    value: fpState.formData?._beneficiaryIndividu?.benefBirthDate,
                                                    onChange: (value) => fpAction.changeForm(value, "benefBirthDate","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                //no field
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col pl-3">
                                            <div className="form-group">
                                                <label style={{fontSize:"12px"}}>Jenis Kelamin</label>
                                                <br />
                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input 
                                                            type="radio" 
                                                            checked={formData?._beneficiaryIndividu?.benefGender === "P"} 
                                                            className="form-check-input" 
                                                            name="benefGender" 
                                                            onChange={(e) => {
                                                                fpAction.changeForm(e.target.value, "benefGender","_beneficiaryIndividu");
                                                            }}
                                                            value="P"
                                                            disabled={!fpState.formEditable}
                                                        />Laki-laki
                                                    </label>
                                                </div>
                                                <div className="form-check-inline ml-3">
                                                    <label className="form-check-label">
                                                        <input 
                                                            type="radio" 
                                                            className="form-check-input" 
                                                            name="benefGender" 
                                                            checked={formData?._beneficiaryIndividu?.benefGender === "W"} 
                                                            onChange={(e) => {
                                                                fpAction.changeForm(e.target.value, "benefGender","_beneficiaryIndividu");
                                                            }}
                                                            value="W"
                                                            disabled={!fpState.formEditable}
                                                        />Perempuan
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: 'Agama',
                                type: 'select',
                                placeholder: 'Pilih Agama',
                                // options: fpState.dataDropDown?.eAgama,
                                options: (fpState.dataDropDown?.eAgama || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._beneficiaryIndividu?.benefAgama,
                                onChange: (value) => fpAction.changeForm(value, "benefAgama","_beneficiaryIndividu"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Status Perkawinan',
                                type: 'select',
                                placeholder: 'Pilih Status Perkawinan',
                                // options: fpState.dataDropDown?.eStatusPerkawinan,
                                options: (fpState.dataDropDown?.eStatusPerkawinan || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._beneficiaryIndividu?.benefStatusPerkawinan,
                                onChange: (value) => fpAction.changeForm(value, "benefStatusPerkawinan","_beneficiaryIndividu"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        {/* <div className="col-3">
                                            {
                                                createForm({
                                                    rows: 1,
                                                    label: "Telepon",
                                                    type: "number",
                                                    placeholder: "000",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefHomePhone,
                                                    onChange: (value) => fpAction.changeForm(value, "benefHomePhone","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div> */}
                                        <div className="col-12">
                                            {
                                                createForm({
                                                    rows: 1,
                                                    // label: <> &nbsp; </>,
                                                    label: "Telepon",
                                                    type: "number",
                                                    placeholder: "000",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefHomePhone,
                                                    onChange: (value) => fpAction.changeForm(value, "benefHomePhone","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: "No Handphone",
                                type: "number",
                                placeholder: "000",
                                required: false,
                                maxLength:14,
                                value: fpState.formData?._beneficiaryIndividu?.benefMobilePhone,
                                onChange: (value) => fpAction.changeForm(value, "benefMobilePhone","_beneficiaryIndividu"),
                                onEnter:(value)=> fpAction.checkHP1(value,fpAction.changeForm("", "benefMobilePhone","_beneficiaryIndividu")),
                                onFocusOut:(value)=> fpAction.checkHP1(value,fpAction.changeForm("", "benefMobilePhone","_beneficiaryIndividu")),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Alamat',
                                type: 'textarea',
                                rowsText: 4,
                                placeholder: 'Masukkan Alamat',
                                value: fpState.formData?._beneficiaryIndividu?.benefAlamat,
                                onChange: (value) => fpAction.changeForm(value, "benefAlamat","_beneficiaryIndividu"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Provinsi",
                                                    type: "select",
                                                    required: false,
                                                    // options: fpState.provinces,
                                                    options: (fpState.provinces || []).map(({province_code, province_name}) => ({
                                                        province_code,
                                                        province_name : `${province_code} - ${province_name}`
                                                    })),
                                                    key: "province_code",
                                                    display: "province_name",
                                                    placeholder: "Masukkan Provinsi",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefProvinsi,
                                                    onChange: (value) => {
                                                        const data = fpState.provinces?.find(v => v.province_name === value);
                                                        fpAction.getingCitiesBenfIdentitas(value, fpAction.changeForm("", "benefKotaKabupaten", "_beneficiaryIndividu"))
                                                        fpAction.changeForm(value, "benefProvinsi", "_beneficiaryIndividu")
                                                    },
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kota/Kab",
                                                    type: "select",
                                                    required: false,
                                                    // options: fpState.citiesBenfIdentitas,
                                                    options: (fpState.citiesBenfIdentitas || []).map(({city_code, city_name}) => ({
                                                        city_code,
                                                        city_name : `${city_code} - ${city_name}`
                                                    })),
                                                    key: "city_code",
                                                    display: "city_name",
                                                    placeholder: "Masukkan Kab/Kota",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefKotaKabupaten,
                                                    onChange: (value) => {
                                                        // fpAction.getingDistrictBenfIdentitas(value)
                                                        fpAction.changeForm(value, "benefKotaKabupaten", "_beneficiaryIndividu")
                                                    },
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kecamatan",
                                                    type: "text",
                                                    required: false,
                                                    //options: fpState.districtsBenfIdentitas,
                                                    placeholder: "Masukkan Kecamatan",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefKecamatan,
                                                    //onChange: (value) => {
                                                    //    fpAction.getingVillagesBenfIdentitas(
                                                    //        fpState.formData?._beneficiaryIndividu?.benefKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                                    //        value 
                                                    //    )
                                                    //    fpAction.changeForm(value, "benefKecamatan", "_beneficiaryIndividu")
                                                    //},
                                                    onChange: (value) => fpAction.changeForm(value, "benefKecamatan","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kelurahan",
                                                    type: "text",
                                                    //options: fpState.villagesBenfIdentitas,
                                                    required: false,
                                                    placeholder: "Masukkan Kelurahan",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefKelurahan,
                                                    //onChange: (value) => {
                                                    //    fpAction.getingPostalCodesBenfIdentitas(
                                                    //        fpState.formData?._beneficiaryIndividu?.benefKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                                    //        fpState.formData?._beneficiaryIndividu?.benefKecamatan, // ambil dari data Kota/Kab sebelumnya.
                                                    //        value
                                                    //    )
                                                    //    fpAction.changeForm(value, "benefKelurahan", "_beneficiaryIndividu")
                                                    //},
                                                    onChange: (value) => fpAction.changeForm(value, "benefKelurahan","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RT",
                                                    maxLength: 3,
                                                    type: "text",
                                                    required: false,
                                                    placeholder: "RT",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefRt,
                                                    onChange: (value) => fpAction.changeForm(value, "benefRt","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RW",
                                                    maxLength: 3,
                                                    type: "text",
                                                    required: false,
                                                    placeholder: "RW",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefRw,
                                                    onChange: (value) => fpAction.changeForm(value, "benefRw","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Kode Pos',
                                type: 'number',
                                maxLength:5,
                                placeholder: 'Pilih Kode Pos',
                                //options: fpState.postalCodesBenfIdentitas,
                                value: fpState.formData?._beneficiaryIndividu?.benefNasabahKodePos,
                                onChange: (value) => fpAction.changeForm(value.toString().replace(" ",""), "benefNasabahKodePos","_beneficiaryIndividu"),
                                onEnter:(value)=> fpAction.kodePos4(value,fpAction.changeForm("", "benefNasabahKodePos","_beneficiaryIndividu")),
                                onFocusOut:(value)=> fpAction.kodePos4(value,fpAction.changeForm("", "benefNasabahKodePos","_beneficiaryIndividu")),
                                disabled: !fpState.formEditable
                            },
                        ]
                    },
                    // End Beneficiary
                    {
                        name: "Alamat Domisili Beneficiary",
                        toolbar: { name: "Salin dari alamat identitas", onClick: () => fpAction.salinAlamatBen() },
                        rows: [
                            {
                                rows: 1,
                                label: 'Alamat',
                                type: 'textarea',
                                rowsText: 4,
                                placeholder: 'Masukkan Alamat',
                                value: fpState.formData?._beneficiaryIndividu?.benefAlamatDom,
                                onChange: (value) => fpAction.changeForm(value, "benefAlamatDom","_beneficiaryIndividu"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Provinsi",
                                                    type: "select",
                                                    required: false,
                                                    // options: fpState.provinces,
                                                    options: (fpState.provinces || []).map(({province_code, province_name}) => ({
                                                        province_code,
                                                        province_name : `${province_code} - ${province_name}`
                                                    })),
                                                    key: "province_code",
                                                    display: "province_name",
                                                    placeholder: "Masukkan Provinsi",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefProvinsiDom,
                                                    onChange: (value) => {
                                                        const data = fpState.provinces?.find(v => v.province_name === value);
                                                        fpAction.getingCitiesBenfDom(value, fpAction.changeForm("", "benefKotaKabupatenDom", "_beneficiaryIndividu"))
                                                        fpAction.changeForm(value, "benefProvinsiDom", "_beneficiaryIndividu")
                                                    },
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kota/Kab",
                                                    type: "select",
                                                    required: false,
                                                    // options: fpState.citiesBenfDom,
                                                    options: (fpState.citiesBenfDom || []).map(({city_code, city_name}) => ({
                                                        city_code,
                                                        city_name : `${city_code} - ${city_name}`
                                                    })),
                                                    key: "city_code",
                                                    display: "city_name",
                                                    placeholder: "Masukkan Kab/Kota",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefKotaKabupatenDom,
                                                    onChange: (value) => {
                                                        // fpAction.getingDistrictBenfDom(value)
                                                        fpAction.changeForm(value, "benefKotaKabupatenDom", "_beneficiaryIndividu")
                                                    },
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kecamatan",
                                                    type: "text",
                                                    required: false,
                                                    //options: fpState.districtsBenfDom,
                                                    placeholder: "Masukkan Kecamatan",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefKecamatanDom,
                                                    //onChange: (value) => {
                                                    //    fpAction.getingVillagesBenfDom(
                                                    //        fpState.formData?._beneficiaryIndividu?.benefKotaKabupatenDom, // ambil dari data Kota/Kab sebelumnya
                                                    //        value 
                                                    //    )
                                                    //    fpAction.changeForm(value, "benefKecamatanDom", "_beneficiaryIndividu")
                                                    //},
                                                    onChange: (value) => fpAction.changeForm(value, "benefKecamatanDom","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kelurahan",
                                                    type: "text",
                                                    //options: fpState.villagesBenfDom,
                                                    required: false,
                                                    placeholder: "Masukkan Kelurahan",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefKelurahanDom,
                                                    //onChange: (value) => {
                                                    //    fpAction.getingPostalCodesBenfDom(
                                                    //        fpState.formData?._beneficiaryIndividu?.benefKotaKabupatenDom, // ambil dari data Kota/Kab sebelumnya
                                                    //        fpState.formData?._beneficiaryIndividu?.benefKecamatanDom, // ambil dari data Kota/Kab sebelumnya.
                                                    //        value
                                                    //    )
                                                    //   fpAction.changeForm(value, "benefKelurahanDom", "_beneficiaryIndividu")
                                                    //},
                                                    onChange: (value) => fpAction.changeForm(value, "benefKelurahanDom","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RT",
                                                    maxLength: 3,
                                                    type: "text",
                                                    required: false,
                                                    placeholder: "RT",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefRtDom,
                                                    onChange: (value) => fpAction.changeForm(value, "benefRtDom","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RW",
                                                    maxLength: 3,
                                                    type: "text",
                                                    required: false,
                                                    placeholder: "RW",
                                                    value: fpState.formData?._beneficiaryIndividu?.benefRwDom,
                                                    onChange: (value) => fpAction.changeForm(value, "benefRwDom","_beneficiaryIndividu"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Kode Pos',
                                type: 'number',
                                maxLength:5,
                                placeholder: 'Pilih Kode Pos',
                                //options: fpState.postalCodesBenfDom,
                                value: fpState.formData?._beneficiaryIndividu?.benefKodePosDom,
                                onChange: (value) => fpAction.changeForm(value.toString().replace(" ",""), "benefKodePosDom","_beneficiaryIndividu"),
                                onEnter:(value)=> fpAction.kodePos5(value,fpAction.changeForm("", "benefKodePosDom","_beneficiaryIndividu")),
                                onFocusOut:(value)=> fpAction.kodePos5(value,fpAction.changeForm("", "benefKodePosDom","_beneficiaryIndividu")),
                                disabled: !fpState.formEditable
                            },
                        ]
                    }
                ]}
            />
        </>
     );
}
 
export default BeneficiaryOwner;