import moment from 'moment';
import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from 'react-bootstrap';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormBadanUsaha from './useFormBadanUsaha';
import usePejabatKorporate from './usePejabatKorporat';

const PejabatKorporat = () => {
    const { fbAction, fbState } = useFormBadanUsaha();
    const { formData = {}, formEditable = false } = fbState;
    const { pkState, pkAction } = usePejabatKorporate()
    
 
    return (
        <>
            <div className="pb-4">
           {/* { (fbState.paramUrl?.mode === "registration" || fbState.paramUrl?.mode === "edit") &&  (
               
               <div className="body-pejabatKorporat">
                <p className="global-text">Cari data berdasarkan :</p>
                <div className="row">
                    <div className="col-2">
                        <div className="form-group">
                            <select name="" id="" className="form-control">
                                <option value="">Jenis Nasabah</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <input

                                type="text"
                                className="form-control"
                                placeholder="Nomor Nasabah atau Nama"
                            />
                        </div>
                    </div>
                    <div className="col-1">
                        <button
                            className="btn btn-outline-primary"
                        >
                            Reset
                        </button>
                    </div>

                    <div className="col-3 p0">
                        <button className="btn btn-primary btn-block">Cari Nasabah</button>
                    </div>
                    <div className="col-3">
                        <button onClick={pkAction.openModalAdd} className="btn btn-outline-primary btn-block">
                            Tambah Pejabat Korporat
                        </button>
                    </div>
                    </div>
                    <p className="text-center"><i>Minimal satu data telah ditambahkan...!!</i></p>
                </div>
            )
           } */}

            { (fbState.paramUrl?.mode === "registration" || fbState.paramUrl?.mode === "edit") &&  (
                <div className="row">
                    <div className="col-9">
                    </div>
                    <div className="col-3">
                        <button onClick={pkAction.openModalAdd} className="btn btn-outline-primary btn-block float-right">
                            Tambah Pejabat Korporat
                        </button>
                    </div>
                </div>
            )}
                <p className="text-primary">Pejabat Korporat</p>
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th>ID Pejabat</th>
                                    <th>Nama</th>
                                    <th>Nomor Identitas</th>
                                    <th>Kepemilikan (%)</th>
                                    <th>Jabatan</th>
                                    <th>Jenis Identitas</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fbState.formData?._pejabatKorporat?.map((item, key) => {
                                        if (item.action !== 'D') {
                                            return (
                                                <tr key={key}>
                                                    <td>{item.pejabatIDPejabat}</td>
                                                    <td>{item.pejabatNama}</td>
                                                    <td>{item.pejabatNomorIdentitas}</td>
                                                    <td>{item.pejabatPersentaseKepemilikan}</td>
                                                    <td >{
                                                        (fbState.dataDropDown?.R_JABATAN_KORPORAT?.find(
                                                            ({
                                                                reference_code
                                                            })=>reference_code === item.pejabatJabatan
                                                        )?.reference_desc || '')
                                                        }
                                                    </td>
                                                    <td>{
                                                        (fbState.dataDropDown?.eJenisIdentitas?.find(
                                                            ({
                                                                reference_code
                                                            })=>reference_code === item.pejabatJenisIdentitas
                                                        )?.reference_desc || '')
                                                        }</td>
                                                    <td>
                                                    {
                                                        fbState.formEditable && 
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="14" cy="8" r="2" fill="#4F4F4F" />
                                                                    <circle cx="8" cy="8" r="2" fill="#4F4F4F" />
                                                                    <circle cx="2" cy="8" r="2" fill="#4F4F4F" />
                                                                </svg>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => pkAction.openDetil(key)} ><p>Detail Pejabat Korporat</p></Dropdown.Item>
                                                                <Dropdown.Item onClick={() => pkAction.openDetil(key, "EDIT")} ><p>Ubah Pejabat Korporat</p></Dropdown.Item>
                                                                <hr/>
                                                                <Dropdown.Item onClick={() => pkAction.del(key)} ><p className="color-dnger">Hapus Pejabat Korporat</p></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal size="xl" show={pkState.formModal} onHide={pkAction.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Form Pejabat Korporat
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col" >
                            {
                                createForm({
                                    type: "select",
                                    label: "Jabatan / Pangkat",
                                    placeholder: "Pilih Jabatan / Pangkat",
                                    options: (fbState.dataDropDown?.R_JABATAN_KORPORAT || []).map(({reference_code, reference_desc}) => ({
                                        reference_code,
                                        reference_desc : `${reference_code} - ${reference_desc}`
                                    })),
                                    key: "reference_code",
                                    display: "reference_desc",
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({...prevState, pejabatJabatan:e})),
                                    value:pkState.formPejabat.pejabatJabatan,
                                    disabled: pkState.mode ==="DETAIL",
                                    required: true
                                })
                            }
                        </div>
                    </div>
                    <FormRegistration
                        itemsForm={[
                            {
                                name: "Data Diri",
                                rows: []
                            }
                        ]}
                    />
                    {
                        createForm({
                            type: "checkbox",
                            label: <strong>Sudah Menjadi Nasabah</strong>,
                            onChange: (e) => pkAction.cekNasabah(e),
                            checked: pkState.isNasabah,
                            disabled: pkState.mode == "DETAIL"
                        })
                    }
                    <div className="row">
                        <div className="col">
                            <div className="form-group form-group-sm col-10">
                                <label>Nomor Nasabah</label>
                                <div className="input-group input-group-sm">
                                    <input
                                        className="form-control form-control-sm"
                                        placeholder="Masukkan nomor nasabah"
                                        value={pkState.formPejabat.pejabatNomorNasabahPejabat}
                                        onChange={(e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatNomorNasabahPejabat: e.target.value }))}
                                        disabled={pkState.mode === "DETAIL" || !pkState.isNasabah}
                                    />
                                    <div className="input-group-append ml-2">
                                        <button onClick={pkAction.getCariNasabah} disabled={!pkState.isNasabah} className={`btn btn-outline-${pkState.isNasabah ? "primary" : "secondary"}`} style={{ borderRadius: 5 }}>
                                            Cari Nasabah
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {
                                createForm({
                                    type: "text",
                                    label: "Nama (sesuai Identitas)",
                                    placeholder: "Masukkan Nama (sesuai Identitas)",
                                    value: pkState.formPejabat.pejabatNama,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatNama: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatNama")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    required: true
                                })
                            }
                            <div className="row">
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: 'Tempat/Tanggal Lahir',
                                            type: 'text',
                                            placeholder: "Masukkan tempat lahir",
                                            value: pkState.formPejabat.pejabatTempatLahir,
                                            // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatTempatLahir: e })),
                                            onChange: (value) => {
                                                pkAction.changeForm(value, "pejabatTempatLahir")
                                            },
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            required: true
                                        })
                                    }
                                </div>
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: <>&nbsp;</>,
                                            type: 'date',
                                            value: pkState.formPejabat.pejabatTglLahir,
                                            onChange: (e) => {
                                                var now = moment(Date.now());
                                                if(now > moment(e)) pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatTglLahir: e }))
                                            },
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            required: true
                                        })
                                    }
                                </div>
                            </div> 
                            {
                                createForm({
                                    type: "textarea",
                                    label: "Alamat",
                                    placeholder: "Masukkan Alamat",
                                    value: pkState.formPejabat.pejabatAlamat,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatAlamat: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatAlamat")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                })
                            }
                            <div className="row">
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "Provinsi",
                                            type: "select",
                                            required: false,
                                            placeholder: "Masukan Provinsi",
                                            options: (pkState.provinces|| []).map(({province_code, province_name}) => ({
                                                province_code,
                                                province_name : `${province_code} - ${province_name}`
                                            })),
                                            key: "province_code",
                                            display: "province_name",
                                            value: pkState.formPejabat.pejabatProvinsi || '',
                                            onChange: (value) => {
                                                const data = pkState.provinces?.find(v => v.province_name === value);
                                                pkAction.getingCitiesPejabat(value, pkAction.changeForm("", "pejabatKotaKabupaten"))
                                                pkAction.changeForm(value, "pejabatProvinsi")
                                            },
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                        })
                                    }
                                </div>
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "Kota/Kab",
                                            type: "select",
                                            options: (pkState.cities || []).map(({city_code, city_name}) => ({
                                                city_code,
                                                city_name : `${city_code} - ${city_name}`
                                            })),
                                            required: false,
                                            key: "city_code",
                                            display: "city_name",
                                            placeholder: "Masukan Kota/Kab",
                                            value: pkState.formPejabat.pejabatKotaKabupaten || '',
                                            onChange: (value) => {
                                                // pkAction.getingDistrictPejabat(value)
                                                pkAction.changeForm(value, "pejabatKotaKabupaten")
                                            },
                                            //onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatKotaKabupaten: e })),
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                        })
                                    }
                                </div>                               
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "Kecamatan",
                                            type: "text",
                                            required: false,
                                            placeholder: "Masukan Kecamatan",
                                            //options: pkState.districts,
                                            value: pkState.formPejabat.pejabatKecamatan || '',
                                            //onChange: (value) => {
                                            //    pkAction.getingVillagesPejabat(
                                            //        pkState.formPejabat?.pejabatKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                            //        value
                                            //    )
                                            //    pkAction.changeForm(value, "pejabatKecamatan")
                                            //},
                                            // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatKecamatan: e })),
                                            onChange: (value) => {
                                                pkAction.changeForm(value, "pejabatKecamatan")
                                            },
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                        })
                                    }
                                </div>
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: "Kelurahan",
                                            type: "text",
                                            required: false,
                                            //options: pkState.villages,
                                            placeholder: "Masukan kelurahan",
                                            value: pkState.formPejabat.pejabatKelurahan || '',
                                            //onChange: (value) => {
                                            //    pkAction.getingPostalCodesPejabat(
                                            //        pkState.formPejabat?.pejabatKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                            //        pkState.formPejabat?.pejabatKecamatan, // ambil dari data Kota/Kab sebelumnya.
                                            //        value
                                            //    )
                                            //    pkAction.changeForm(value, "pejabatKelurahan")
                                            //},
                                            // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatKelurahan: e })),
                                            onChange: (value) => {
                                                pkAction.changeForm(value, "pejabatKelurahan")
                                            },
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    {
                                        createForm({
                                            type:"number",
                                            label:"RT/RW",
                                            maxLength: 3,
                                            placeholder: "RT",
                                            value: pkState.formPejabat.pejabatRt,
                                            // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatRt: e })),
                                            onChange: (value) => {
                                                pkAction.changeForm(value, "pejabatRt")
                                            },
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                        })
                                    }
                                </div>
                                <div className="col-6">
                                    {
                                        createForm({
                                            label: <>&nbsp;</>,
                                            type: "number",
                                            maxLength: 3,
                                            required: false,
                                            placeholder: "RW",
                                            value: pkState.formPejabat.pejabatRw,
                                            // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatRw: e })),
                                            onChange: (value) => {
                                                pkAction.changeForm(value, "pejabatRw")
                                            },
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                        })
                                    }
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-12">
                                    {
                                        createForm({
                                            label: "Kode Pos",
                                            type: 'number',
                                            maxLength:5,
                                            required: false,
                                            placeholder: "Masukan kode pos",
                                            //options: pkState.postalCodes,
                                            value: pkState.formPejabat.pejabatKodePos,
                                            onChange: (value) => pkAction.changeForm(value, "pejabatKodePos"),
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        createForm({
                                            label: "Telepon Rumah",
                                            type: "number",
                                            maxLength: 18,
                                            placeholder: "000",
                                            value: pkState.formPejabat.pejabatTeleponNomor,
                                            // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatTeleponNomor: e })),
                                            onChange: (value) => {
                                                pkAction.changeForm(value, "pejabatTeleponNomor")
                                            },
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            required: false
                                        })
                                    }
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-4 pl-2 pr-1">
                                    {
                                        createForm({
                                            label: "Telepon Rumah",
                                            type: "number",
                                            placeholder: "000",
                                            value: pkState.formPejabat.pejabatTeleponKodeArea,
                                            onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatTeleponKodeArea: e })),
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            required: false
                                        })
                                    }
                                </div>
                                <div className="col-8">
                                    {
                                        createForm({
                                            label: <>&nbsp;</>,
                                            type: "number",
                                            placeholder: "000",
                                            value: pkState.formPejabat.pejabatTeleponNomor,
                                            onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatTeleponNomor: e })),
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            required: false
                                        })
                                    }
                                </div>
                            </div> */}
                            {
                                createForm({
                                    type: "number",
                                    label: "Handphone",
                                    placeholder: "Masukkan Handphone",
                                    maxLength: 14,
                                    required:true,
                                    value: pkState.formPejabat.pejabatNomorHandphone,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatNomorHandphone: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatNomorHandphone")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    onEnter:(value)=> pkAction.checkHpKorporat(value, pkAction.changeForm("", "pejabatNomorHandphone")),
                                    onFocusOut:(value)=> pkAction.checkHpKorporat(value, pkAction.changeForm("", "pejabatNomorHandphone")),
                                })
                            }
                            <div className="row">
                                <div className="col-12">
                                {
                                        createForm({
                                            label: "Fax",
                                            type: "number",
                                            placeholder: "000",
                                            value: pkState.formPejabat.pejabatFaxNomor,
                                            // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatFaxNomor: e })),
                                            onChange: (value) => {
                                                pkAction.changeForm(value, "pejabatFaxNomor")
                                            },
                                            disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                            required: false
                                        })
                                    }
                                </div>
                            </div>
                            {
                                createForm({
                                    type: "text",
                                    label: "Email",
                                    placeholder: "Masukkan Email",
                                    value: pkState.formPejabat.pejabatEmailAddress,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatEmailAddress: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatEmailAddress")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL"
                                })
                            }
                        </div>
                        <div className="col-6">
                            {
                                createForm({
                                    type: "select",
                                    label: "Jenis Identitas",
                                    placeholder: "Masukkan Jenis Identitas",
                                    options:( fbState.dataDropDown?.eJenisIdentitas || []).map(({reference_code, reference_desc}) => ({
                                        reference_code,
                                        reference_desc : `${reference_code} - ${reference_desc}`
                                    })),
                                    key: "reference_code",
                                    display: "reference_desc",
                                    value: pkState.formPejabat.pejabatJenisIdentitas,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatJenisIdentitas: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatJenisIdentitas")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    required: true
                                })
                            }
                            {
                                createForm({
                                    type: pkState.formPejabat.pejabatJenisIdentitas === '3' ? 'text' : 'number',
                                    label: "Nomor Identitas",
                                    placeholder: "Masukkan Nomor Identitas",
                                    value: pkState.formPejabat.pejabatNomorIdentitas,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatNomorIdentitas: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatNomorIdentitas")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    required: true,
                                    maxLength:16
                                })
                            }
                            {
                                createForm({
                                    type: "text",
                                    label: "Nomor NPWP",
                                    required: false,
                                    placeholder: "Masukkan Nomor NPWP",
                                    value: pkState.formPejabat.pejabatNomorNpwp,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatNomorNpwp: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatNomorNpwp")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    maxLength:15
                                })
                            }
                            <div className="row">
                                <div className="col pl-3">
                                    <div className="form-group">
                                        <label
                                            style={{ fontSize: "12px" }}
                                            className={pkState.formPejabat.pejabatJenisKelamin === "" || pkState.formPejabat.pejabatJenisKelamin === undefined || pkState.formPejabat.pejabatJenisKelamin === null ? "text-danger" : ""}
                                        >
                                            Jenis Kelamin
                                            <span
                                                style={{
                                                    padding: '2px 5px',
                                                    background: '#E8E8E8',
                                                    fontSize: '8pt',
                                                    fontWeight: 'normal',
                                                    borderRadius: 3,
                                                }}
                                            >
                                                Wajib
                                            </span>
                                        </label>
                                        <br />
                                        <div className="form-check-inline">
                                            <label className="form-check-label">
                                                <input
                                                    type="radio"
                                                    checked={pkState.formPejabat.pejabatJenisKelamin === "P"}
                                                    className="form-check-input"
                                                    name="pejabatJenisKelamin"
                                                    onChange={(e) => {
                                                        pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatJenisKelamin: e.target.value }));
                                                    }}
                                                    // onChange= {(e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatJenisKelamin: e }))}
                                                    value="P"
                                                    disabled={pkState.isNasabah || pkState.mode === "DETAIL"}
                                                />Laki-laki
                                            </label>
                                        </div>
                                        <div className="form-check-inline ml-3">
                                            <label className="form-check-label">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="gender"
                                                    checked={pkState.formPejabat.pejabatJenisKelamin === "W"}
                                                    onChange={(e) => {
                                                        pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatJenisKelamin: e.target.value }));
                                                    }}
                                                    // onChange= {(e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatJenisKelamin: e }))}
                                                    value="W"
                                                    disabled={pkState.isNasabah || pkState.mode === "DETAIL"}
                                                />Perempuan
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                createForm({
                                    type: "select",
                                    label: "Agama",
                                    placeholder: "Pilih Agama",
                                    value: pkState.formPejabat.pejabatAgama,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatAgama: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatAgama")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    options: (fbState.dataDropDown?.eAgama || []).map(({reference_code, reference_desc}) => ({
                                        reference_code,
                                        reference_desc : `${reference_code} - ${reference_desc}`
                                    })),
                                    key: "reference_code",
                                    display: "reference_desc",
                                })
                            }
                            {
                                createForm({
                                    type: "select",
                                    label: "Kewaganegaraan",
                                    placeholder: "Masukkan Kewaganegaraan",
                                    value: pkState.formPejabat.pejabatKewarganegaraan,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatKewarganegaraan: e, ...(e === "1" ? { pejabatNegaraAsal: "ID" } : {}) })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatKewarganegaraan")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    options: (fbState.dataDropDown?.eKewarganegaraan || []).map(({reference_code, reference_desc}) => ({
                                        reference_code,
                                        reference_desc : `${reference_code} - ${reference_desc}`
                                    })),
                                    key: "reference_code",
                                    display: "reference_desc",
                                })
                            }
                            {
                                createForm({
                                    type: "currency",
                                    required: true,
                                    label: "Presentase Kepemilikan",
                                    placeholder: "Masukkan Presentase Kepemilikan",
                                    value: pkState.formPejabat.pejabatPersentaseKepemilikan,
                                    onChange: (e) => {
                                        if (e <= 100) pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatPersentaseKepemilikan: e }))
                                    },
                                    disabled: pkState.mode === "DETAIL" || pkState.disabledPrecent
                                })
                            }
                            {
                                createForm({
                                    type: "select",
                                    label: "Negara Asal",
                                    placeholder: "Masukkan Negara Asal",
                                    value: pkState.formPejabat.pejabatNegaraAsal,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatNegaraAsal: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatNegaraAsal")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL" || pkState.formPejabat.pejabatKewarganegaraan === "1",
                                    options: (fbState.dataDropDown?.R_KODE_NEGARA || []).map(({reference_code, reference_desc}) => ({
                                        reference_code,
                                        reference_desc : `${reference_code} - ${reference_desc}`
                                    })),
                                    key: "reference_code",
                                    display: "reference_desc",
                                })
                            }
                            {
                                createForm({
                                    type: "select",
                                    label: "Pekerjaan",
                                    placeholder: "Masukkan Pekerjaan",
                                    value: pkState.formPejabat.pejabatPekerjaan,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatPekerjaan: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatPekerjaan")
                                    },
                                    disabled: pkState.isNasabah || pkState.mode === "DETAIL",
                                    options: (fbState.dataDropDown?.R_PEKERJAAN || []).map(({reference_code, reference_desc}) => ({
                                        reference_code,
                                        reference_desc : `${reference_code} - ${reference_desc}`
                                    })),
                                    key: "reference_code",
                                    display: "reference_desc",
                                })
                            }
                            {
                                createForm({
                                    type: "textarea",
                                    label: "Keterangan",
                                    placeholder: "Bila ada",
                                    value: pkState.formPejabat.pejabatKeterangan,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, pejabatKeterangan: e })),
                                    onChange: (value) => {
                                        pkAction.changeForm(value, "pejabatKeterangan")
                                    },
                                    disabled: pkState.mode === "DETAIL"
                                })
                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            {
                                pkState.mode !== "DETAIL" &&<button
                                    onClick={pkAction.save}
                                    className="btn btn-sm float-right btn-success ml-2">
                                    Simpan
                                </button>
                            }
                            <button onClick={pkAction.closeModal} className="btn btn-sm float-right btn-outline-secondary">
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
//coba
export default PejabatKorporat;