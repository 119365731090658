import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DateInput, FormRegistration, TableList } from '../../controls';
import useCetak from './useCetak';
import ModalCetakTransaksiPrint from "./printCetakTransaksi"
import { useLocalStorage } from '../../../module';
const ModalCetakTransaksiPassbook = () => {
    const { cState, cAction } = useCetak();
    const { formData = {}, formEditable = false, dataCetakTransaksi = {} } = cState;
    const [podDate, setPodDate] = useLocalStorage("POD_DATE")
    const [filterState, setFilterState] = useState({
        from: podDate, to: podDate
    });

    useEffect(function () {
        setFilterState({
            from: podDate, to: podDate
        })
    }, [cState.dataCetakTransaksi?.is_cetak_ulang])
    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={cState.modalCetakTransaksi} onHide={cAction.closeCetakTransaksi}  backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Cetak Transaksi Tabungan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormRegistration
                        itemsForm={[
                            {
                                name: "Info Rekening",
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Nomor Rekening',
                                        type: 'number',
                                        placeholder: 'Masukan nama',
                                        required: false,
                                        readOnly: true,
                                        value: cState.dataCetakTransaksi?.nomor_rekening,
                                        onChange: (value) => cAction.changeForm_cetak(value, "nomor_rekening"),
                                        // disabled: !cState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        label: 'Nama Rekening',
                                        type: 'text',
                                        placeholder: 'Masukan nama',
                                        required: false,
                                        readOnly: true,
                                        value: cState.dataCetakTransaksi?.nama_rekening,
                                        onChange: (value) => cAction.changeForm_cetak(value, "nama_rekening"),
                                        // disabled: !cState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        label: 'ID Cetak Awal',
                                        type: 'number',
                                        // placeholder: 'Masukan nama',
                                        required: false,
                                        readOnly: cState.dataCetakTransaksi?.is_cetak_ulang !== "T",
                                        value: cState.dataCetakTransaksi?.id_cetak_awal,
                                        onChange: (value) => cAction.changeForm_cetak(value, "id_cetak_awal"),
                                        // disabled: !cState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        label: 'ID Cetak Akhir ',
                                        type: 'number',
                                        // placeholder: 'Masukan nama',
                                        required: false,
                                        readOnly: cState.dataCetakTransaksi?.is_cetak_ulang !== "T",
                                        value: cState.dataCetakTransaksi?.id_cetak_akhir,
                                        onChange: (value) => cAction.changeForm_cetak(value, "id_cetak_akhir"),
                                        // disabled: !cState.formEditable
                                    },
                                    {
                                        rows: 2,
                                        label: 'No. Register Buku',
                                        type: 'number',
                                        // placeholder: 'Masukan nama',
                                        required: false,
                                        readOnly: true,
                                        value: cState.dataCetakTransaksi?.reg_buku,
                                        // onChange: (value) => cAction.changeForm_cetak(value, "mulai_baris"),
                                        // disabled: !cState.formEditable
                                    },
                                    {
                                        rows: 2,
                                        label: 'Mulai Baris',
                                        type: 'number',
                                        // placeholder: 'Masukan nama',
                                        // required: false,
                                        // readOnly: cState.dataCetakTransaksi?.is_cetak_ulang !== "T",
                                        value: cState.dataCetakTransaksi?.mulai_baris,
                                        onChange: (value) => {
                                            if(parseInt(value) < 1) cAction.changeForm_cetak(1, "mulai_baris")
                                            else if(parseInt(value) > 32 ) cAction.changeForm_cetak(32, "mulai_baris")
                                            else cAction.changeForm_cetak(value, "mulai_baris")
                                        },
                                        max:28,
                                        min: 1
                                        // disabled: !cState.formEditable
                                    },
                                    {
                                        rows: 2,
                                        label: 'Cetak Ulang ',
                                        type: 'checkbox',
                                        placeholder: 'T/F',
                                        required: false,
                                        // readOnly:true,
                                        value: cState.dataCetakTransaksi?.is_cetak_ulang === "T",
                                        checked: cState.dataCetakTransaksi?.is_cetak_ulang === "T",
                                        onChange: (value) => cAction.changeForm_cetak(value ? "T" : "F", "is_cetak_ulang"),
                                        disabled: cState.fastPrint
                                    },
                                    {
                                        rows: 2,
                                        label: 'All Data ',
                                        type: 'checkbox',
                                        placeholder: 'T/F',
                                        required: false,
                                        // readOnly:true,
                                        value: cState.dataCetakTransaksi?.is_all_data === "T",
                                        checked: cState.dataCetakTransaksi?.is_all_data === "T",
                                        onChange: (value) => cAction.changeForm_cetak(value ? "T" : "F", "is_all_data"),
                                        disabled: cState.fastPrint
                                        // disabled: !cState.formEditable
                                    }
                                ]
                            }
                        ]}
                    />
                    {
                        cState.dataCetakTransaksi?.is_cetak_ulang === "T" && (

                            <div className='row'>
                                <div className='col'>
                                    <div className="row">
                                        <div className="col-4 align-self-center bns-form">
                                            <div className="form-group">
                                                <label>Tanggal Awal</label>
                                                <DateInput
                                                    className="form-control"
                                                    type="date"
                                                    placeholder="Tanggal Awal"
                                                    value={filterState.from}
                                                    onChange={(value) => {
                                                        setFilterState(prevState => ({ ...prevState, from: value }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4 align-self-center bns-form">
                                            <div className="form-group">
                                                <label>Tanggal Akhir</label>
                                                <DateInput
                                                    className="form-control"
                                                    type="date"
                                                    placeholder="Tanggal Akhir"
                                                    value={filterState.to}
                                                    onChange={(value) => {
                                                        setFilterState(prevState => ({ ...prevState, to: value }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4 align-self-center bns-form">
                                            <div className="form-group pt-4 mt-1">
                                                <button

                                                    onClick={() => cAction.getTrxCetakUlang(cState.dataCetakTransaksi?.nomor_rekening, filterState.from, filterState.to)}
                                                    className="btn btn-outline-success">
                                                    Cari
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <TableList
                                        data={cState.dataCetakUlang || []}
                                        minHeight="250px"
                                        maxHeight="450px"
                                        pagination={false}
                                        withCard={false}
                                        columns={[

                                            {
                                                field: "ID",
                                                name: "ID Cetak"
                                            },
                                            // {
                                            //     field: "BARIS",
                                            //     name: "BARIS KE"
                                            // },

                                            {
                                                field: "TANGGAL",
                                                name: "Tanggal"
                                            }
                                            ,
                                            {
                                                field: "KODE",
                                                name: "Tx"
                                            }
                                            ,
                                            {
                                                field: "NILAI_MUTASI_DEBET",
                                                name: "Mutasi Debit"
                                            }
                                            ,
                                            {
                                                field: "NILAI_MUTASI_KREDIT",
                                                name: "Mutasi Kredit"
                                            }
                                            ,
                                            {
                                                field: "SALDO",
                                                name: "Saldo"
                                            }
                                            ,
                                            {
                                                field: "USER",
                                                name: "OprId"
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button
                                onClick={() => cAction.closeCetakTransaksi()}
                                className="btn btn-outline-danger float-right">
                                Batal
                            </button>
                            <button
                                onClick={() => cAction.openCetakTransakasiPrint()}
                                className="btn btn-outline-success mr-2">
                                Data Cetak
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <ModalCetakTransaksiPrint />
        </>
    );
}

export default ModalCetakTransaksiPassbook;