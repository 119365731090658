import React, { memo, useCallback, useState, forwardRef } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { AppInterfaces } from '../../../app.data';
import { createForm } from '../../../module/createFormList';
import { baseUrl } from '../../../app.config';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';

const ComponentToPrint = forwardRef(({ form, date }, ref) => {
    const formatNumber = (number) =>
        new Intl.NumberFormat('id-ID', {
            currency: form?._rekening?.valuta || 'IDR',
            style: 'currency',
        }).format(number);

    return (
        <div ref={ref} style={{ width: '210mm' }} className="tw-text-12 tw-p-6">
            <div className="tw-text-center tw-font-bold tw-mb-6">
                PEMBAYARAN BAGI HASIL DEPOSITO
            </div>
            <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                <div className="tw-whitespace-pre">
                    Nama&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;:{' '}
                    {form?._dataNasabah?.nama}
                </div>
                <div>Tanggal&emsp;&emsp;: {dayjs(date).format('DD/M/YYYY')}</div>
            </div>
            <div className="tw-mb-3">
                No. Rekening&emsp;&emsp;&emsp;&emsp;&emsp;: {form?.nomorRekeningDeposito}
            </div>
            <div className="tw-mb-3">
                Alamat&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&nbsp;:{' '}
                {form?._dataNasabah?.alamat}
            </div>
            <div className="tw-mb-3">
                TELAH KAMI KREDIT REKENING SAUDARA DENGAN TANGGAL {dayjs(date).format('DD/M/YYYY')}{' '}
                UNTUK PEMBAYARAN BAGI HASIL DEPOSITO ATAS BILYET DEPOSITO PERIODE BULAN :{' '}
                {dayjs(date).format('DD/M/YYYY')}
            </div>
            <div className="tw-mb-3">
                BAGI HASIL BRUTO&emsp;&emsp;: {formatNumber(form?.accruedAmounts?.interestAccrued)}
            </div>
            <div className="tw-mb-3">
                PAJAK BAGI HASIL&emsp;&emsp;&nbsp;:{' '}
                {formatNumber(form?._bagiHasilDisposisi?.pajakBagiHasil)}
            </div>
            <div className="tw-mb-3">
                ZAKAT BAGI HASIL&emsp;&emsp;:{' '}
                {formatNumber(form?._bagiHasilDisposisi?.zakatBagiHasil)}
            </div>
            <div className="tw-mb-3">
                BAGI HASIL DIBAYAR&emsp;: {formatNumber(form?.accruedAmounts?.interestAccrued)}
            </div>
            <div>ADVIS KREDIT</div>
        </div>
    );
});

const NotaPembayaran = AppInterfaces.appLoad(
    memo(({ show, onHide }) => {
        const [confirm, setConfirm] = useState(false);
        const [form, setForm] = useState({});
        const [error, setError] = useState({});
        const [noRekening, setNoRekening] = useState('');
        const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
        const [componentToPrintRef, setComponentToPrintRef] = useState(null);
        const [success, setSuccess] = useState(false);

        const handlePrint = useReactToPrint({
            content: () => componentToPrintRef,
            pageStyle: () => `
                @media print {
                    @page {
                        size: 210mm 187mm;
                    }
                }
            `,
            documentTitle: 'Billyet Deposito Berjangka',
            onAfterPrint: () => {
                setForm({});
                setNoRekening('');
                setConfirm(false);
                setSuccess(true);
            },
            bodyClass: 'tw-flex tw-mx-auto tw-w-max tw-items-center',
        });

        // eslint-disable-next-line
        const findDeposito = useCallback(
            debounce(async (value, callback) => {
                const res = await fetch(
                    baseUrl + `/deposito/${value}?detailsLevel=FULL&accountHolderType=CLIENT`
                );
                const resGroup = await fetch(
                    baseUrl + `/deposito/${value}?detailsLevel=FULL&accountHolderType=GROUP`
                );

                const data = await res.json();
                const dataGroup = await resGroup.json();

                if (!data.list && !dataGroup.list) {
                    setForm({});
                    if (callback) callback('error');
                    return;
                }

                let dataForm;

                if (res.ok) dataForm = data;
                else if (resGroup.ok) dataForm = dataGroup;

                setForm(dataForm?.list);
                if (callback) callback('success');
                return;
            }, 500),
            []
        );

        const handleSearchDeposito = useCallback(
            async (e) => {
                const { value } = e.target;
                await findDeposito(value, (status) => {
                    if (status === 'error' && value > 0)
                        setError({ ...error, rekeningDeposito: true });
                    else setError({ ...error, rekeningDeposito: false });
                });

                setNoRekening(value);
            },
            [error, findDeposito]
        );

        return (
            <>
                <div style={{ display: 'none' }}>
                    <ComponentToPrint ref={setComponentToPrintRef} form={form} date={date} />
                </div>
                <Modal centered show={success}>
                    <Modal.Header>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Proses Cetak Nota Pembayaran Bagi Hasil berhasil dilakukan
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setSuccess(false)}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Selesai
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={confirm}>
                    <Modal.Header onHide={() => setConfirm(false)} closeButton>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Lakukan proses Nota Pembayaran Bagi Hasil?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setConfirm(false)}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Tidak
                        </Button>
                        <Button onClick={handlePrint} className="tw-w-16" size="mini" color="blue">
                            Ya
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={show}>
                    <Modal.Header closeButton onHide={onHide}>
                        <Modal.Title>
                            <div className="tw-text-18 tw-font-bold">
                                Nota Pembayaran Bagi Hasil
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-4">
                                {createForm({
                                    type: 'text',
                                    label: 'Cabang',
                                    placeholder: 'Kode cabang',
                                    disabled: true,
                                })}
                            </div>
                            <div className="col-md-8">
                                {createForm({
                                    type: 'text',
                                    label: <>&nbsp;</>,
                                    placeholder: 'Nama cabang',
                                    disabled: true,
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {createForm({
                                    type: 'date',
                                    label: 'Tanggal',
                                    placeholder: 'Tanggal',
                                    value: date,
                                    onChange: (val) => setDate(val),
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Group className="col-md col-lg col-sm-12">
                                    <Form.Label style={{ fontSize: '10pt' }}>
                                        Nomor Rekening Deposito
                                    </Form.Label>
                                    <Form.Control
                                        value={noRekening}
                                        onChange={(e) => handleSearchDeposito(e)}
                                        placeholder="Nomor Rekening Deposito"
                                        size="sm"
                                        isInvalid={error.rekeningDeposito}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Rekening tidak dapat ditemukan
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide} className="tw-font-normal tw-py-2 tw-mr-2">
                            Batal
                        </Button>
                        <Button
                            onClick={() => {
                                setConfirm(true);
                                if (onHide) onHide();
                            }}
                            className="tw-font-normal tw-py-2"
                            color="blue"
                        >
                            Cetak
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);

export default NotaPembayaran;
