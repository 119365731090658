import React, { memo, useRef } from 'react';
import {BasePagePay} from '../../controls';
import { AppAction, AppInterfaces } from '../../../app.data';

const IconHeader = memo(function(){
    return (
        <>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6667 22.0833L9.2101 23.5843C8.45276 23.7368 7.81593 23.0095 8.06707 22.2789L13.6787 5.95411C13.9075 5.28851 14.4693 4.79217 15.1581 4.64717L23.7238 2.84387C24.4631 2.68823 25.1013 3.37713 24.8898 4.10242L21.1905 16.7857L29.8746 15.8716C30.7903 15.7752 31.3386 16.8628 30.7166 17.5416L12.2252 37.7237C11.8946 38.0845 11.3649 38.1824 10.9272 37.9636C10.407 37.7035 10.1806 37.0823 10.4114 36.5485L16.6667 22.0833Z" fill="#FF6F00"/>
                <path d="M21.4881 16.5659L29.9018 15.6703C30.814 15.5732 31.3646 16.6531 30.7503 17.3344L20.0818 29.1667C17.4516 14.139 18.0824 9.80076 20.0392 3.67216L23.9139 2.84728C24.6525 2.69003 25.2922 3.37688 25.083 4.10249L21.4881 16.5659Z" fill="#FFA040"/>
            </svg>
        </>
    )
})


const nom = [
    {
        id: 1,
        providerId:1,
        value: 20000,
        price: 22500
    },{
        id: 2,
        providerId:1,
        value: 50000,
        price: 50500
    },{
        id: 3,
        providerId:1,
        value: 100000,
        price: 99500
    },{
        id: 4,
        providerId:1,
        value: 150000,
        price: 148500
    },{
        id: 5,
        providerId:1,
        value: 200000,
        price: 190500
    },{
        id: 6,
        providerId:1,
        value: 250000,
        price: 23550
    },{
        id: 7,
        providerId:1,
        value: 300000,
        price: 280500
    },{
        id: 8,
        providerId:1,
        value: 400000,
        price: 380500
    },{
        id: 9,
        providerId:1,
        value: 500000,
        price: 475000
    },
]

const ListrikPage_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
    
    return (
        <React.Fragment>
            <AppAction ref={appAction} />
            <BasePagePay
                options = {
                    [
                        {
                            display: "Token Listrik",
                            value: 1,
                            forms: [{
                                type: "text",
                                name: "Token Listrik",
                                label: "ID Pelanggan",
                                value: store.formData.providerId,
                                required: true,
                                onChange: (val) => appAction.current.setForm("no_pelanggan", val),
                                readOnly: false,
                                onEnter:() => {
                                    appAction.current.disp({
                                        type:"setNominalList",
                                        nominalList:nom
                                    })
                                }
                            }],
                            selectNominal: true,
                            onSelectNominal: (val)=> {
                                appAction.current.setNominal(val)
                                appAction.current.setOpenPembayaran(true)
                            },
                            onPay: () => appAction.current.pay(),
                        },
                        {
                            display: "Tagihan Listrik",
                            value: 2,
                            forms: [
                                {
                                    type: "text",
                                    name: "Tagihan Listrik",
                                    label: "ID Pelanggan / No Meter",
                                    value: store.formData.pelanggan,
                                    required: true,
                                    onChange: (val) => appAction.current.setForm("no_pelanggan", val),
                                    readOnly: false,
                                    onEnter:() => appAction.current.setOpenPembayaran(true)
                                }],
                            selectNominal: false,
                            onPay: () => appAction.current.pay(),
                        },
                        {
                            display: "Listrik Non Taglis",
                            value: 3,
                            forms: [
                            {
                                type: "text",
                                name: "Listrik Non Taglis",
                                label: "No Registrasi",
                                value: store.formData.pelanggan,
                                required: true,
                                onChange: (val) => appAction.current.setForm("no_pelanggan", val),
                                readOnly: false,
                                onEnter:() => appAction.current.setOpenPembayaran(true)
                            }],
                            selectNominal: false,
                            onPay: () => appAction.current.pay(),
                        }
                    ]
                }
                title ={<span className="px-0"><IconHeader /> Listrik</span>}
                nominals={nom}
                paymentCardData={{
                    detilBody: [
                        {
                            key: "Jenis Layanan",
                            value: "TRF BANK"
                        },
                        {
                            key: "Nomor Pelanggan",
                            value: "089123123"
                        },
                        {
                            key: "Nama Pelanggan",
                            value: "Isnan Nazzarun"
                        },
                    ],
                    detilFooter: [
                        {
                            key: "Harga",
                            value: 120000
                        },
                        {
                            key: "Admin",
                            value: 2000
                        }
                    ],
                    total: 14000
                }}
            />
        </React.Fragment>
    );
});

const ListrikPage = AppInterfaces.appLoad(ListrikPage_base)

export default ListrikPage;