import React from "react";
import useDeposito from "../useDeposito";
import { Modal } from 'react-bootstrap';
import Skeleton from "react-loading-skeleton";
import BukaBlokirDeposito from "../components/bukaBlokir";
import { addCommas } from "../../../../../../module";

const ModalBukaBlokir = () => {
    const { rekState, rekAction } = useDeposito();

    return (
        <>
         {/* modal utama */}
         <Modal size={"xl"} show={rekState.modalBukaBlokir} onHide={rekAction.closeBukaBlokir} >
                <Modal.Header closeButton>
                    <Modal.Title>Buka Blokir Deposito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                        <div className="row">
                            <div className="col-md-8">
                                <div className="box mb-3" style={{ height: 150 }}>
                                    <div className="row">
                                        <div className="col">
                                            <h4>{rekState.loadingDetil ? <Skeleton width={"50%"} height={30} /> : <>{rekState.rekSelected?.nama_lengkap}<span className="ml-2 badge badge-pill badge-secondary">{rekState.rekSelected?.status}</span></>}</h4>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-3"> No. Deposito </div>
                                                        <div className="col-9">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nomor_nasabah}`} </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3"> No. Bilyet </div>
                                                        <div className="col-9">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nomor_bilyet}`}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3"> Kode Cabang </div>
                                                        <div className="col-9">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.nama_cabang}`}</div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="box" style={{ height: 150 }}>
                                    <div className="row">
                                        <div className="col">
                                            <h4>Saldo</h4>
                                                {rekState.loadingDetil ? <Skeleton height={40} /> : <>
                                                    <span className="text-base" style={{ fontWeight:"bolder", fontSize:"20pt" }}>
                                                        {addCommas(parseFloat(rekState.rekSelected?.saldo))} </span>
                                                </>}
                                            <div className="row">
                                                <div className="col-6">Cadangan Bagi Hasil</div>
                                                <div className="col-6">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.cadangan_bagi_hasil || 0}`}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">Info Accrual Bagi Hasil</div>
                                                <div className="col-6">{rekState.loadingDetil ? <Skeleton height={20} /> : `: ${rekState.rekSelected?.saldo_accrual_bagihasil || 0}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BukaBlokirDeposito />
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button
                                onClick={() => rekAction.confirmBukaBlokir()}
                                className="btn btn-outline-info float-right">
                                Buka Blokir Deposito
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal >
            {/* end modal utama */}
        </>
    );
}

export default ModalBukaBlokir;