import React, { memo, useRef } from 'react';
import { useForm } from "react-hook-form";
import { AppAction, AppInterfaces } from "../../../app.data";
import { BasePageKiriman } from "../../controls";

let bsu = {
    total: 35125050,
    tunai: 26430000,
    nontunai: 8672500,
    panjar: 0
}

const SidebarRight_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
    const { register, handleSubmit, setValue } = useForm();

    const onSubmit = data => {
        // console.log(data);
        bsu.total += Number(data.bsu.panjar); bsu.tunai += Number(data.bsu.panjar); setValue("bsuPanjar", ""); OkProses();}

    const prosesPanjar = () => appAction.current.setShowModal({
        show: true,
        size: "sm",
        backdrop: "static",
        header: <b>Panjar</b>,
        body: <BasePageKiriman register={register} options={
            [
                {
                    type: "bsu",
                    forms: [
                        {type: "text", name:"loket", required: true, value: "35.125.050", label: "BSU Loket", disable: true, style: "text-right", placeholder: "0"},
                        {type: "text", name:"panjar", required: true, label: "BSU Panjar", disable: false, style: "text-right", placeholder: "0", },
                    ],
                },
            ]
        } />,
        footer: <form onSubmit={handleSubmit(onSubmit)}><button type="submit" className="btn btn-primary btn-sm px-4">PANJAR</button></form>
    });
    

    const OkProses = () => {
        appAction.current.setShowModal({
            show: true,
            size: "sm",
            backdrop: "static",
            body: <p className="text-center">Panjar Berhasil<br/>
                BSU Loket menjadi<br/>
                Rp <b>{bsu.total.toLocaleString()}</b> </p>,
            footer: <button className="btn btn-primary btn-sm px-4" onClick={()=>appAction.current.setCloseModal()}>OK</button>
        });
    }

    return (
        <div className="bg-fff py-2" style={{ minHeight: "94vh" }}>
            <AppAction ref={appAction} />
            <div className="bg-gray-2 card mx-2">
                <div className="card-dashboard row p-0 m-0 b-border-gray align-content-center f-14">
                    <div className="col px-3 text-left ">
                        <i className="text-primary-light fas fa-exclamation-circle"></i>
                        &nbsp;&nbsp;Informasi Teller
                    </div>
                </div>
                <table className="mb-2">
                    <tbody>
                        <tr className="text-left">
                            <td className="px-3 f-14" colSpan="2">Mayuga Wicaksana</td>
                        </tr>
                        <tr className="text-left">
                            <td className="px-3 f-14 pb-3" colSpan="2">ID : 100009</td>
                        </tr>
                        <tr className="text-left">
                            <td className="px-3 f-14">Kantor</td>
                            <td>: Kp. Bandung</td>
                        </tr>
                        <tr className="text-left">
                            <td className="px-3 f-14">Loket&nbsp;|&nbsp;Nosi</td>
                            <td>: 09&nbsp;|&nbsp;000142</td>
                        </tr>
                        <tr className="text-left">
                            <td className="px-3 f-14">Noppen</td>
                            <td>: 4000</td>
                        </tr>
                        <tr className="text-left">
                            <td className="px-3 f-14">No Backsheet  </td>
                            <td>: 4000-09/21/1401</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="card m-2 text-dark">
                <table className="my-2">
                    <tbody>
                        <tr className="text-left">
                            <td className="px-3" colSpan="2"><h5>BSU Loket</h5></td>
                        </tr>
                        <tr className="text-left">
                            <td className="px-3 pb-3" colSpan="2">
                                <div className="p-2 alert-warning bordered" style={{border: "1px solid #004bbc", borderRadius: "10px"}}>
                                    <span className="text-dark"> BSU Total</span><br />
                                    <h5 className="text-center">RP {bsu.total.toLocaleString()}</h5>
                                </div>
                            </td>
                        </tr>
                        <tr className="">
                            <td className="px-3">Tunai</td>
                            <td>: Rp {bsu.tunai.toLocaleString()}</td>
                        </tr>
                        <tr className="text-left b-border-gray">
                            <td className="px-3 pb-3 f-14 " >Non Tunai</td>
                            <td>: Rp {bsu.nontunai.toLocaleString()}</td>
                        </tr>
                        <tr className="text-center">
                            <td className="px-3 py-2" colSpan="2"><button className="btn btn-primary form-control" onClick={()=>prosesPanjar()}>PANJAR</button></td>
                        </tr>
                        <tr className="text-center">
                            <td className="px-3 pb-2" colSpan="2"><button className="btn btn-outline-primary form-control">RINCIAN BSU LOKET</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
})

const SidebarRight = AppInterfaces.appLoad(SidebarRight_base)
export default SidebarRight;