import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { forwardRef, memo, useCallback, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'semantic-ui-react';
import { baseUrl } from '../../../app.config';
import { AppInterfaces } from '../../../app.data';
import { createForm } from '../../../module/createFormList';
import { amountToWord } from '../../../utils';

const ComponentToPrint = forwardRef(({ form, date }, ref) => {
    const formatNumber = (number) =>
        new Intl.NumberFormat('id-ID', {
            currency: 'IDR',
            style: 'currency',
        }).format(number);

    return (
        <div style={{ width: '210mm' }} ref={ref} className="tw-text-12 tw-p-6">
            <div className="tw-font-bold tw-mb-6">PT. BANK NET SYARIAH</div>
            <div className="tw-font-bold tw-mb-6 tw-text-center">BUKTI PERPANJANGAN DEPOSITO</div>
            <div className="tw-grid tw-grid-cols-3 tw-gap-x-4 tw-gap-y-6">
                <div className="tw-col-span-2 tw-uppercase">
                    NAMA & ALAMAT <br />
                    <strong>{form?._dataNasabah?.nama}</strong> <br />
                    <strong>{form?._dataNasabah?.alamat}</strong>
                </div>
                <div>{dayjs(date).format('DD-MMM-YYYY')}</div>
                <div>
                    TANGGAL JATUH TEMPO <br />
                    <strong>
                        {dayjs(form?._rekening?.tanggalJatuhTempo).format('DD-MMM-YYYY')}
                    </strong>
                </div>
                <div>
                    TANGGAL JATUH TEMPO BARU <br />
                    <strong>
                        {dayjs(form?._rekening?.tanggalJatuhTempo)
                            .add(1, 'year')
                            .format('DD-MMM-YYYY')}
                    </strong>
                </div>
                <div>
                    NOMINAL <br />
                    <strong>{formatNumber(form?.balances?.totalBalance)}</strong>
                </div>
                <div className="tw-col-span-3 tw-uppercase">
                    NOMINAL DALAM HURUF <br />
                    <strong>{amountToWord(form?.balances?.totalBalance)}</strong>
                </div>
                <div>
                    PAJAK BAGI HASIL <br />
                    <strong>{form?._bagiHasilDisposisi?.pajakBagiHasil ?? 0} %</strong>
                </div>
                <div>
                    ZAKAT BAGI HASIL <br />
                    <strong>{form?._bagiHasilDisposisi?.zakatBagiHasil ?? 0} %</strong>
                </div>
                <div>
                    NISBAH BAGI HASIL <br />
                    <strong>{form?._bagiHasilDisposisi?.nisbah ?? 0} %</strong>
                </div>
            </div>
        </div>
    );
});

const CetakAdvice = AppInterfaces.appLoad(
    memo(({ show = false, onHide }) => {
        const [confirm, setConfirm] = useState(false);
        const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
        const [form, setForm] = useState({});
        const [error, setError] = useState({});
        const [success, setSuccess] = useState(false);
        const [noRekening, setNoRekening] = useState('');
        const [componentToPrintRef, setComponentToPrintRef] = useState(null);

        const handlePrint = useReactToPrint({
            content: () => componentToPrintRef,
            pageStyle: () => `
                @media print {
                    @page {
                        size: 210mm 187mm;
                    }
                }
            `,
            documentTitle: 'Billyet Deposito Berjangka',
            onAfterPrint: () => {
                setForm({});
                setNoRekening('');
                setConfirm(false);
                setSuccess(true);
            },
            bodyClass: 'tw-flex tw-mx-auto tw-w-max tw-items-center',
        });
        // eslint-disable-next-line
        const findDeposito = useCallback(
            debounce(async (value, callback) => {
                const res = await fetch(
                    baseUrl + `/deposito/${value}?detailsLevel=FULL&accountHolderType=CLIENT`
                );
                const resGroup = await fetch(
                    baseUrl + `/deposito/${value}?detailsLevel=FULL&accountHolderType=GROUP`
                );

                const data = await res.json();
                const dataGroup = await resGroup.json();

                if (!data.list && !dataGroup.list) {
                    setForm({});
                    if (callback) callback('error');
                    return;
                }

                let dataForm;

                if (res.ok) dataForm = data;
                else if (resGroup.ok) dataForm = dataGroup;

                setForm(dataForm?.list);
                if (callback) callback('success');
                return;
            }, 500),
            []
        );

        const handleSearchDeposito = useCallback(
            async (e) => {
                const { value } = e.target;
                await findDeposito(value, (status) => {
                    if (status === 'error' && value > 0)
                        setError({ ...error, rekeningDeposito: true });
                    else setError({ ...error, rekeningDeposito: false });
                });

                setNoRekening(value);
            },
            [error, findDeposito]
        );

        return (
            <>
                <div style={{ display: 'none' }}>
                    <ComponentToPrint form={form} date={date} ref={setComponentToPrintRef} />
                </div>
                <Modal centered show={success}>
                    <Modal.Header>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Proses Cetak Advise Perpanjangan Deposito berhasil dilakukan
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setSuccess(false)}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Selesai
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={confirm}>
                    <Modal.Header onHide={() => setConfirm(false)} closeButton>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Lakukan proses Cetak Advise Perpanjangan Deposito?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setConfirm(false)}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Tidak
                        </Button>
                        <Button onClick={handlePrint} className="tw-w-16" size="mini" color="blue">
                            Ya
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={show}>
                    <Modal.Header closeButton onHide={onHide}>
                        <Modal.Title>
                            <div className="tw-text-18 tw-font-bold">
                                Cetak Advise Perpanjangan Deposito
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                {createForm({
                                    type: 'text',
                                    label: 'Cabang',
                                    placeholder: 'Kode cabang / Nama cabang',
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {createForm({
                                    type: 'date',
                                    label: 'Tanggal Perpanjangan',
                                    placeholder: 'Tanggal Perpanjangan',
                                    value: date,
                                    onChange: (value) => setDate(value),
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Group className="col-md col-lg col-sm-12">
                                    <Form.Label style={{ fontSize: '10pt' }}>
                                        Nomor Rekening Deposito
                                    </Form.Label>
                                    <Form.Control
                                        value={noRekening}
                                        onChange={(e) => handleSearchDeposito(e)}
                                        placeholder="Nomor Rekening Deposito"
                                        size="sm"
                                        isInvalid={error.rekeningDeposito}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Rekening tidak dapat ditemukan
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide} className="tw-font-normal tw-py-2 tw-mr-2">
                            Batal
                        </Button>
                        <Button
                            onClick={() => {
                                setConfirm(true);
                                if (onHide) onHide();
                            }}
                            className="tw-font-normal tw-py-2"
                            color="blue"
                        >
                            Cetak
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);

export default CetakAdvice;
