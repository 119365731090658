import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { TableList } from "../../../../controls";
import useDataBuku, { BukuProvider } from './useDataBuku';
import ModalDetilTabungan from './modal/detilTabMode';
// import { useKeycloak } from '@react-keycloak/web';
import ModalCetakNama from '../../tabungan-giro/data_rekening/modal/modalCetakNamaPassbook';
import ModalCetakTransaksi from '../../tabungan-giro/data_rekening/modal/modalCetakTransaksiPassbook';
import useCetak, { CetakProvider } from '../../../../controls/cetak/useCetak';
import useKeycloak from '../../../../../hooks/keycloak.module';
function DataBukuTabunganPage() {
    const {
        nfAction,
        nfState
    } = useDataBuku();
    const { keycloak } = useKeycloak()
    let history = useHistory();
    const { cAction, cState } = useCetak()
    useEffect(function(){
        nfAction.getListKodeCabang()
    }, [])
      useEffect(function () {
        nfAction.auditTrail()
    }, [])
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3" >
                <ol className="breadcrumb" >
                    <li className="breadcrumb-item" >
                        <a className="text-priamry-bds" href="#">Tabungan & Giro</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Data Registrasi Buku Tabungan
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-12">
                    <div className="card" >
                        <div className="card-body" >
                            <TableList
                                curPage={nfState.pagination?.page}
                                minHeight="450px"
                                withCard={false}
                                onNextPage={nfAction.nextPage}
                                onPrevPage={nfAction.prevPage}
                                onChangeShow={nfAction.changeShow}
                                isLoading={nfState.isLoading}
                                data={nfState.dataList}
                                withActions={true}
                                actionItems={
                                    [
                                        {
                                            name: 'Lihat Detail Buku Tabungan',
                                            withDivider: true,
                                            onClick: (e) => {
                                                let state = { dbId: e.nomor_rekening }
                                                history.push(`/tabungan-giro/form-tabungan/detil`, state);
                                            },
                                        },
                                        // {
                                        //     name: "Lihat Detail Buku Tabungan",
                                        //     onClick:(item)=>nfAction.openModalDetil(0, item)
                                        // },
                                        // {
                                        //     name: "Ubah Data Buku Tabungan",
                                        //     onClick:(item)=>nfAction.openModalDetil(2, item)
                                        // }
                                        {
                                            name: 'Cetak Header Buku Tabungan',
                                            // onRender: (item) => item.kode_cabang === keycloak?.tokenParsed?.branch_code,
                                            withDivider: true,
                                            onClick: (e) => {
                                                nfAction.openCetakNamaPasbook(e)
                                            },
                                        },
                                        {
                                            name: 'Cetak Transaksi Tunda',
                                            // onRender: () => keycloak?.tokenParsed?.branch_code === "000",
                                            withDivider: true,
                                            onClick: (e) => {
                                                cAction.openCetakTransaksiPasbook(e)
                                            },
                                        },
                                    ]
                                }
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: 'Nomor Rekening',
                                        field: 'nomor_rekening',
                                        maxWidth: '200px',
                                    },
                                    {
                                        name: 'Nama',
                                        field: 'nama_rekening',
                                        maxWidth: '150px',
                                    },
                                    {
                                        name: 'Cabang Rekening',
                                        field: 'kode_cabang',
                                        maxWidth: '200px',
                                    },
                                    {
                                        name: 'Cabang User',
                                        field: 'nama_cabang',
                                        maxWidth: '200px',
                                    },
                                    {
                                        name: 'Nomor Buku Aktif',
                                        field: 'nomorregisterbuku_aktif',
                                        maxWidth: '300px',
                                    },
                                    {
                                        name: 'Nomor Buku Terakhir',
                                        field: 'nomor_buku_terakhir',
                                        maxWidth: '300px',
                                    },
                                ]}
                                // data={nfState.dataList}
                                // isLoading={nfState.isLoading}
                                filter={true}
                                formsFilter={[
                                    {
                                        type: 'select',
                                        options: (nfState.listKodeCabang || []).map( ({kode_cabang = "", nama_cabang = ""}, i) => ({
                                            key:kode_cabang,
                                            display:`${kode_cabang} - ${nama_cabang}`
                                        })),
                                        placeholder: 'Kode Cabang',
                                        disabled: false,
                                        readonly: false,
                                        value: nfState?.filter?.kode_cabang,
                                        onChange: (kode_cabang) => nfAction.setFilter(f => ({ ...f, kode_cabang })),
                                    },
                                    {
                                        type: 'select',
                                        options: [
                                            {
                                                key: 'I',
                                                display: 'Perorangan',
                                            },
                                            {
                                                key: 'K',
                                                display: 'Badan Usaha',
                                            },
                                        ],
                                        placeholder: 'Jenis Nasabah',
                                        disabled: false,
                                        readonly: false,
                                        value: nfState?.filter?.jenis_nasabah,
                                        onChange: (jenis_nasabah) => nfAction.setFilter(f => ({ ...f, jenis_nasabah })),
                                    },
                                    {
                                        type: 'select',
                                        options: [
                                            {
                                                key: 1,
                                                display: "Aktif"
                                            },

                                            {
                                                key: 3,
                                                display: "Ditutup"
                                            },


                                            {
                                                key: 2,
                                                display: "Dormant"
                                            },


                                            {
                                                key: 7,
                                                display: "Pasif"
                                            },
                                        ],
                                        placeholder: 'Pilih Status',
                                        disabled: false,
                                        readonly: false,
                                        value: nfState?.filter?.status_rekening,
                                        onChange: (status_rekening) => nfAction.setFilter(f => ({ ...f, status_rekening })),
                                    },
                                    // {
                                    //     type: 'text',
                                    //     placeholder: 'Kode Cabang',
                                    //     disabled: false,
                                    //     readonly: false,
                                    //     onChange: (value) => null,
                                    // },
                                    {
                                        type: 'text',
                                        options: [],
                                        placeholder: 'Keyword',
                                        disabled: false,
                                        readonly: false,
                                        value: nfState?.filter?.keywords,
                                        onChange: (keywords) => nfAction.setFilter(f => ({ ...f, keywords })),
                                    },
                                ]}
                                onFilter={() => nfAction.resetPagination()
                                }
                                onResetFilter={() => {
                                    nfAction.resetFilter()
                                }}
                                resetFilter={true}
                                submitButton="Submit"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalDetilTabungan />
            {/* <ModalCetakNama /> */}
            {/* <ModalCetakTransaksi /> */}
        </>
    )
}

export default () => (
    <CetakProvider>
        <BukuProvider>
            <DataBukuTabunganPage />
        </BukuProvider>
    </CetakProvider>
)