import React from 'react';
import './style.css';
import { Breadcrumb } from 'semantic-ui-react';
import { CardMenuRegistrasi } from '../../controls';

const RegistrasiWicPage = () => {
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Section className="text-primary-bds" link>
                    WIC
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Registrasi WIC</Breadcrumb.Section>
            </Breadcrumb>
            <div
                style={{ paddingBottom: 200 }}
                className="bg-white px-4 pt-4 mt-4 rounded-lg shadow-sm"
            >
                <h1 className="f-18 text-gray-2 text-center mb-5">Pilih Jenis WIC</h1>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 24 }}>
                    <CardMenuRegistrasi
                        heading="Perorangan"
                        description="Ini diisi dengan deskripsi singkat mengenari profil WIC perorangan"
                        href="/wic/form/registration"
                        icon={
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <g opacity="0.66" filter="url(#filter0_f)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 61.4258C16.3125 61.4258 12.375 61.4258 12.375 57.4883C12.375 53.5508 16.3125 41.7383 36 41.7383C55.6875 41.7383 59.625 53.5508 59.625 57.4883C59.625 61.4258 55.6875 61.4258 55.6875 61.4258H16.3125Z" fill="#004bbc" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M36 37.8008C39.1329 37.8008 42.1374 36.5563 44.3527 34.341C46.568 32.1257 47.8125 29.1212 47.8125 25.9883C47.8125 22.8554 46.568 19.8509 44.3527 17.6356C42.1374 15.4203 39.1329 14.1758 36 14.1758C32.8671 14.1758 29.8626 15.4203 27.6473 17.6356C25.432 19.8509 24.1875 22.8554 24.1875 25.9883C24.1875 29.1212 25.432 32.1257 27.6473 34.341C29.8626 36.5563 32.8671 37.8008 36 37.8008Z" fill="#004bbc" />
                                    </g>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 57.8242C16.3125 57.8242 12.375 57.8242 12.375 53.8867C12.375 49.9492 16.3125 38.1367 36 38.1367C55.6875 38.1367 59.625 49.9492 59.625 53.8867C59.625 57.8242 55.6875 57.8242 55.6875 57.8242H16.3125Z" fill="#004bbc" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M36 34.1992C39.1329 34.1992 42.1374 32.9547 44.3527 30.7394C46.568 28.5241 47.8125 25.5196 47.8125 22.3867C47.8125 19.2538 46.568 16.2493 44.3527 14.034C42.1374 11.8187 39.1329 10.5742 36 10.5742C32.8671 10.5742 29.8626 11.8187 27.6473 14.034C25.432 16.2493 24.1875 19.2538 24.1875 22.3867C24.1875 25.5196 25.432 28.5241 27.6473 30.7394C29.8626 32.9547 32.8671 34.1992 36 34.1992Z" fill="#004bbc" />
                                </g>
                                <defs>
                                    <filter id="filter0_f" x="-9.9" y="-8.09922" width="91.8" height="91.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur stdDeviation="7.2" result="effect1_foregroundBlur" />
                                    </filter>
                                    <clipPath id="clip0">
                                        <rect width="72" height="72" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        }
                    />
                    {/* <CardMenuRegistrasi
                        heading="Badan Usaha"
                        description="Ini diisi dengan deskripsi singkat mengenari profil WIC badan usaha"
                        href="/wic/registrasi-badan-usaha"
                        icon={
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <g opacity="0.66" clip-path="url(#clip1)" filter="url(#filter0_f)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.89966 52.2861C9.89966 53.6064 10.4152 54.8726 11.333 55.8062C12.2508 56.7397 13.4955 57.2642 14.7934 57.2642H57.2059C58.5038 57.2642 59.7486 56.7397 60.6663 55.8062C61.5841 54.8726 62.0997 53.6064 62.0997 52.2861V33.5352L36.4205 40.4946C36.1448 40.5695 35.8545 40.5695 35.5788 40.4946L9.89966 33.5352V52.2861Z" fill="#004bbc" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.89966 25.7348C9.89966 24.4145 10.4152 23.1483 11.333 22.2147C12.2508 21.2811 13.4955 20.7566 14.7934 20.7566H57.2059C58.5038 20.7566 59.7486 21.2811 60.6663 22.2147C61.5841 23.1483 62.0997 24.4145 62.0997 25.7348V30.3279L37.259 37.065C36.4338 37.2885 35.5655 37.2885 34.7403 37.065L9.89966 30.3279V25.7348ZM26.2122 19.0973C26.2122 17.777 26.7277 16.5108 27.6455 15.5772C28.5633 14.6436 29.808 14.1191 31.1059 14.1191H40.8934C42.1913 14.1191 43.4361 14.6436 44.3538 15.5772C45.2716 16.5108 45.7872 17.777 45.7872 19.0973V20.7566H42.5247V19.0973C42.5247 18.6572 42.3528 18.2351 42.0469 17.9239C41.741 17.6127 41.326 17.4379 40.8934 17.4379H31.1059C30.6733 17.4379 30.2584 17.6127 29.9524 17.9239C29.6465 18.2351 29.4747 18.6572 29.4747 19.0973V20.7566H26.2122V19.0973Z" fill="#004bbc" />
                                    </g>
                                    <g clip-path="url(#clip2)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.89966 49.5849C9.89966 50.9052 10.4152 52.1714 11.333 53.105C12.2508 54.0386 13.4955 54.563 14.7934 54.563H57.2059C58.5038 54.563 59.7486 54.0386 60.6663 53.105C61.5841 52.1714 62.0997 50.9052 62.0997 49.5849V30.834L36.4205 37.7934C36.1448 37.8683 35.8545 37.8683 35.5788 37.7934L9.89966 30.834V49.5849Z" fill="#004bbc" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.89966 23.0336C9.89966 21.7133 10.4152 20.4471 11.333 19.5135C12.2508 18.5799 13.4955 18.0555 14.7934 18.0555H57.2059C58.5038 18.0555 59.7486 18.5799 60.6663 19.5135C61.5841 20.4471 62.0997 21.7133 62.0997 23.0336V27.6267L37.259 34.3638C36.4338 34.5873 35.5655 34.5873 34.7403 34.3638L9.89966 27.6267V23.0336ZM26.2122 16.3961C26.2122 15.0758 26.7277 13.8096 27.6455 12.876C28.5633 11.9424 29.808 11.418 31.1059 11.418H40.8934C42.1913 11.418 43.4361 11.9424 44.3538 12.876C45.2716 13.8096 45.7872 15.0758 45.7872 16.3961V18.0555H42.5247V16.3961C42.5247 15.956 42.3528 15.5339 42.0469 15.2227C41.741 14.9115 41.326 14.7367 40.8934 14.7367H31.1059C30.6733 14.7367 30.2584 14.9115 29.9524 15.2227C29.6465 15.5339 29.4747 15.956 29.4747 16.3961V18.0555H26.2122V16.3961Z" fill="#004bbc" />
                                    </g>
                                </g>
                                <defs>
                                    <filter id="filter0_f" x="-4.49998" y="-3.59922" width="81" height="81.9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur stdDeviation="7.2" result="effect1_foregroundBlur" />
                                    </filter>
                                    <clipPath id="clip0">
                                        <rect width="72" height="72" fill="white" />
                                    </clipPath>
                                    <clipPath id="clip1">
                                        <rect width="52.2" height="53.1" fill="white" transform="translate(9.90002 10.8008)" />
                                    </clipPath>
                                    <clipPath id="clip2">
                                        <rect width="52.2" height="53.1" fill="white" transform="translate(9.90002 8.09961)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        }
                    /> */}
                </div>
            </div>
        </>
    );
};

export default RegistrasiWicPage;