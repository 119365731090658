import { createContext, useState, useContext, useEffect } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { useModalAlert } from "../../../../controls/modalAlert";
// import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router';
import useNexusPrint from '../../../../../hooks/useNexusPrint';
import moment from 'moment';
import { uniqueId } from 'lodash';
import useKeycloak from "../../../../../hooks/keycloak.module";
import ModalCetakNama from '../../tabungan-giro/data_rekening/modal/modalCetakNamaPassbook';

export const BukuTabunganContext = createContext({
    nfAction: {},
    nfState: {}
});

export function BukuProvider({ children, otorModeP = false }) {
    const { modalAction, modalState } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const [dataCetakUlang, setDataCetakUlang] = useState([])
    //loading state
    const [loadingCetakStatement, setloadingCetakStatement] = useState(false)

    //Modal State
    const [modalCetakNamaPrint, setmodalCetakNamaPrint] = useState(false)
    const [modalCetakNama, setmodalCetakNama] = useState(false)
    const { print } = useNexusPrint();

    // Data State
    const [dataOtor, setDataOtoe] = useState({
        kode_entry: "",
        dbId: "",
        callBackSuccess: () => null
    });
    const [dataList, setDataList] = useState([])
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ keywords: '', jenis_nasabah: "", status_rekening: "" })
    const [isLoading, setIsLoading] = useState(false)
    const [loadingDetil, setLoadingDetil] = useState(false)
    const { doGet, wait, doPost } = useFetch()
    const [otorDesc, setOtorDesc] = useState("");
    const [modalDetil, setModalDetil] = useState({
        show: false,
        mode: 0
    })
    const [otorMode, setOtorMode] = useState(false)
    const [formEditable, setformEditable] = useState(false)
    const [formData, setFormData] = useState({
        "id": "",
        "no_rek": "",
        "nama_sub": "",
        "no_hp": "",
        "flag": "",
        "min_tran_db": 0,
        "min_tran_cr": 0,
        "active": "F",
        "cif": ""
    });
    const [dataCetakNama, setdataCetakNama] = useState({
        "nama_rekening": "",
        "nama_cabang": "",
        "nomor_rekening": "",
    });
    const [dataCetakNamaPrint, setdataCetakNamaPrint] = useState({});


    const location = useLocation()
    const { keycloak } = useKeycloak()

    useEffect(function () {
        getDataList();
    }, [pagination])

    // reset pagination
    function resetPagination() {
        setPagination({ page: 1, show: 15 })
    }

    // getData rekening list
    async function getDataList() {
        setIsLoading(true)
        var url = `/bukutabungan/get_buku_tabungan`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { ...pagination, ...filter, limit: pagination.show },
                service: "acc"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    // console.log(data)
                    setDataList(data)
                }
                // else setDataAlert({ show: true, body: remark, titile: "info", header:"info", callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }


    // open Cetak Nama
    function openCetakNamaPasbook(data, isOtorisasi = false, callBackSuccess = () => null) {
        // // console.log(data)
        setdataCetakNama({
            "nama_rekening": "",
            "nomor_rekening": "",
            "nama_cabang": data.nama_cabang,
            "alamat": "",
            "Kode Pos": "",
        });
        setOtorMode(isOtorisasi)
        if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        getCetakNamaPasbook(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
        setmodalCetakNama(true)
    }
    // close Cetak Nama
    function closeCetakNama() {
        setmodalCetakNama(false)
    }

    // open Cetak Nama Print
    function openCetakNamaPrint(idRekening) {
        // setmodalCetakNama(false)
        // setdataCetakNamaPrint({});
        getCetakNamaPrint(idRekening, 200)
        // setmodalCetakNamaPrint(true)
    }
    // close Cetak Nama
    function closeCetakNamaPrint() {
        setmodalCetakNamaPrint(false)
    }



    //START CETAK NAMA
    //getCetakStatement
    async function getCetakNamaPasbook(nomor_rekening, isOtorisasi, dbId) {
        // console.log("CETAK NAMA")
        setloadingCetakStatement(true)
        var url = `/bukutabungan/get_buku_tabungan_by_nomor_rekening`;
        if (isOtorisasi) url = `/bukutabungan/request-cetak-nama-passbook-by-db-id`
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: isOtorisasi ? "acc" : "acc",
                param: {
                    [isOtorisasi ? "db_id" : "nomor_rekening"]: nomor_rekening
                }
            });

            if (statusCode === 200) {
                if (status === "00") {

                    if (isOtorisasi) {
                        let {
                            buku_ke,
                            cabang_rekening,
                            cabang_user,
                            keterangan,
                            key_string,
                            kode_cabang,
                            nama_nasabah,
                            nomor_rekening,
                            regis,
                            register_buku_lama,
                            tgl_buku_rekening,
                            user_input,
                        } = data
                        setdataCetakNama({
                            nomor_register_buku_lama: register_buku_lama,
                            nomorregisterbuku_aktif: regis,
                            nomor_rekening: nomor_rekening,
                            nama_rekening: nama_nasabah,
                            kode_cabang: kode_cabang,
                            nama_cabang: cabang_user,
                            alamat: keterangan
                        })
                    }
                    else
                        setdataCetakNama(data)
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setloadingCetakStatement(false)
    }

    async function postCetakPassbook(nomor_rekening) {
        var url = `/bukutabungan/cetak-nama-passbook`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                queryParam: {
                    nomor_rekening
                }
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setDataAlert({
                        show: true, body: remark, title: `Berhasil`, header: "Berhasil", callBack: () => {
                            closeCetakNamaPrint()
                            closeLoading()
                            closeCetakNama()
                        }
                    });
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
    }


    async function getCetakNamaPrint(nomor_rekening, rekStatus) {
        setLoadingDetil(true)
        var url = `/bukutabungan/get_buku_tabungan_by_nomor_rekening`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc",
                param: {
                    nomor_rekening: nomor_rekening
                }
            });

            if (statusCode === 200) {
                if (status === "00") {
                    // console.log(data)
                    setdataCetakNamaPrint({
                        ...data,
                        // status: rekStatus 
                    })
                    try {
                        openLoading()
                        const res = await print({
                            command: "PRINT",
                            formname: "PassbookCover_BJBS",
                            medianame: "BJBS",
                            id: `${uniqueId()}${data.nomor_rekening}`,
                            print: 1,
                            history: 0,
                            namaCover: data.nama_rekening || "",
                            alamat1: data.alamat || "", //nm jalan
                            alamatRTRW: `RT/RW ${(data.alamat_rtrw || "")}`,
                            keluarahanKecamatanKabupaten: `KEL. ${data.kelurahan || ""} KEC. ${data.kecamatan || ""}, ${data.kota_kabupaten || ""}`, // kterngan
                            kodePos: data.kode_pos || "", // kab. pos
                            norekeningCover: data.nomor_rekening || "",
                            Cabang: data.nama_cabang || "",
                            TanggalCover: moment(Date.now()).format("DD/MM/YYYY"),
                            kodeCabang: dataCetakNama.kode_cabang || "",
                            mataUang: "IDR",
                        });
                        if (res.statusCode === 500) {
                            setDataAlert({ show: true, body: res.remark, title: `${res.statusText}`, header: "Error", callBack: () => null });
                        } else {
                            setDataAlert({
                                show: true, body: res.remark, title: `${res.statusText}`, header: "Berhasil", callBack: () => {
                                    postCetakPassbook(data.nomor_rekening)
                                    flaggingCetakHeader()
                                }
                            });
                        }
                    } catch (err) {
                        setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });

                    }
                    closeLoading()
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingDetil(false)
    }

    async function doRequestCetak() {
        openLoading("Requesting, please wait ...")

        try {
            const resp = await doPost({
                method: "POST",
                data: {
                    "nomor_rekening": dataCetakNama?.nomor_rekening,
                    "nama_nasabah": dataCetakNama?.nama_rekening,
                    "cabang_rekening": dataCetakNama?.kode_cabang,
                    "register_buku_lama": dataCetakNama?.nomor_register_buku_lama,
                    "regis": dataCetakNama?.nomorregisterbuku_aktif,
                    "buku_ke": dataCetakNama?.nomor_buku_terakhir,
                    "tgl_buku_rekening": dataCetakNama?.tanggal_input,
                    "keterangan": dataCetakNama?.alamat,
                    "cabang_user": dataCetakNama?.nama_cabang
                },
                url: "/bukutabungan/request-cetak-nama-passbook", // ganti sesuai api
                service: "acc" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: "Request cetak header passbook telah dikirim. Butuh otorisasi untuk melanjutkan cetak.", title: ``, header: "Success", callBack: () => {
                        // resetForm();
                        closeConfirm();
                        closeCetakNama();
                    }
                });
                else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    async function flaggingCetakHeader(){
        try {
            const resp = await doPost({
                method: "POST",
                data: {
                    "nomor_rekening": dataCetakNama?.nomor_rekening,
                    "is_boleh_cetak": "F",
                },
                url: "/bukutabungan/flaging-is-boleh-cetak", // ganti sesuai api
                service: "acc" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {}
                // setDataAlert({
                //     show: true, body: "Cetak header passbook selesai.", title: ``, header: "Success", callBack: () => {
                //         // resetForm();
                //         closeConfirm();
                //         // closeCetakNama();
                //     }
                // });
                else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
    }
    function confirmRequestCetak() {
        let body = "Pastikan rekening yang terpilih benar. Lanjutkan?"
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doRequestCetak(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    useEffect(function () {
        if (!otorModeP) getDataList();
    }, [pagination])

    function resetFilter() {
        setFilter({
            keywords: '', jenis_nasabah: "", status: ""
        })
    }
    // next pagination
    function nextPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
    function closeModalDetil() {
        setModalDetil({
            show: false,
            mode: 0
        });
    }
    async function openModalDetil(mode, data, callBackSuccess = () => null) {
        if (mode === 0) {
            setformEditable(false)
            const {
                nomor_rekening = "",
                nama_rekening = "",
                kode_cabang = "",
                nomorregisterbuku_aktif = "",
                regis = "",
                nomor_buku_terakhir = 0,
                tgl_buku_rekening = "",
                keterangan = "",
                nama_cabang = ""
            } = data;
            setFormData(form => ({
                nomor_rekening,
                nama_rekening,
                kode_cabang,
                nomorregisterbuku_aktif,
                regis,
                nomor_buku_terakhir,
                tgl_buku_rekening,
                keterangan,
                nama_cabang
            }));
        }
        else if (mode === 1) {
            setOtorMode(true);
            // setDataOtor({
            //     ...data, 
            //     callBackSuccess
            // })
            console.log(dataOtor)
            getDetail(data.id)
        } else if (mode === 2) {
            setformEditable(true)

            const {
                nomor_rekening = "",
                nama_nasabah = "",
                cabang_rekening = "",
                register_buku_lama = "",
                regis = "",
                buku_ke = 0,
                tgl_buku_rekening = "",
                keterangan = "",
                cabang_user = ""
            } = data;
            setFormData(form => ({
                nomor_rekening,
                nama_nasabah,
                cabang_rekening,
                register_buku_lama,
                regis,
                buku_ke,
                tgl_buku_rekening,
                keterangan,
                cabang_user
            }));
        }


        // console.log(data)
        setModalDetil({
            show: true,
            mode
        });
    }

    // getDetail
    async function getDetail(nomor_rekening) {
        setLoadingDetil(true)
        // var url = `/smsnotif/get_sms_notif_by_id?id=${id}`;
        var url = `/bukutabungan/get_buku_tabungan_by_nomor_rekening?nomor_rekening=${nomor_rekening}`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                // param:id,
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setFormData(data)
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingDetil(false)
    }

    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} otorisasi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirmCustom, className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        // if (location.state !== null && location.state !== undefined) {
        try {
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                url: `/otorisasi/${dataOtor.kode_entry}/${dataOtor.dbId}/${statusApprove}?keterangan=${keterangan}`,
                method: "PUT",
                service: "acc",
                data: { keterangan: otorDesc },
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                }
            });
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                        // resetForm();
                        closeLoading();
                        closeAlert();
                        closeConfirmCustom();
                        closeConfirm();
                        dataOtor.callBackSuccess()
                        // history.goBack()s
                    }
                });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        // } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    function changeForm(value, key, subkey = null) {
        // value = value.toUpperCase()
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    function confirmUbah() {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan ubah data buku rekening, cek kembali form buku rekening. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doEdit(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    // fungsi setDataForm
    const [listKodeCabang, setListKodeCabang] = useState([]);
    async function getListKodeCabang() {
        try {
            let resp = await doGet({
                url: `/rpc/list-kode-cabang`,
                service: "rpc",
            });
            const { statusCode, remark = "", data = [], status = "01", statusText = "", message = "" } = resp
            // console.log(resp)
            if (statusCode === 200) {
                if (status === "00") setListKodeCabang(data)
                else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => dataOtor.callBackSuccess() });
            } else setDataAlert({ show: true, body: `${remark}. ${message}`, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => dataOtor.callBackSuccess() });
        }
    }

    async function doEdit() {

        closeConfirm();
        // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        const {
            nomor_rekening,
            nama_nasabah,
            cabang_rekening,
            register_buku_lama,
            regis,
            buku_ke,
            tgl_buku_rekening,
            keterangan,
            cabang_user
        } = formData;
        let body = {
            nomor_rekening,
            nama_nasabah,
            cabang_rekening,
            register_buku_lama,
            regis,
            buku_ke,
            tgl_buku_rekening,
            keterangan,
            cabang_user
        }
        var url = `/bukutabungan/update_buku_tabungan_by_nomor_rekening?nomor_rekening=${formData.nomor_rekening}`;
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    // "x-user-id": userInfo.preferred_username
                },
                method: "PUT",
                data: formData,
                url, // ganti sesuai api
                service: "acc" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: "Ubah Data Registrasi Buku Rekening Berhasil. dibutuhkan otorisasi untuk melanjutkan", title: ``, header: "Success", callBack: () => {
                        // resetForm();
                        closeConfirm();
                        closeModalDetil();
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    //Post Auditrail
    async function auditTrail() {
        const userInfo = await keycloak.loadUserInfo();
        // console.log(userInfo)
        var url = `/useraudit/user-audit-trail`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: {
                    user_id: userInfo.preferred_username,
                    app_id: "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Get Data List",
                    event_description: "Tabungan & Giro/Data Buku Tabungan",
                    info1: "-",
                    info2: "-"
                },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

    }
    return <BukuTabunganContext.Provider value={{
        nfAction: {
            nextPage,
            prevPage,
            changeShow,
            resetPagination,
            getDataList,
            openModalDetil,
            closeModalDetil,
            setOtorDesc,
            responseOtor,
            changeForm,
            confirmUbah,
            resetFilter,
            setFilter,
            openCetakNamaPasbook,
            closeCetakNama,
            closeCetakNamaPrint,
            openCetakNamaPrint,
            getListKodeCabang,
            auditTrail,
            confirmRequestCetak
        },
        nfState: {
            dataList,
            isLoading,
            modalDetil,
            formData,
            otorMode,
            otorDesc,
            formEditable,
            filter,
            dataOtor,
            dataCetakNama,
            dataCetakNamaPrint,
            modalCetakNama,
            modalCetakNamaPrint,
            listKodeCabang,
            loadingCetakStatement
        }
    }} >

        <ModalCetakNama />
        {children}</BukuTabunganContext.Provider>
}

export default function useDataBuku() {
    return useContext(BukuTabunganContext)
}