import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { usePrintModal } from './useModalPrint';
import { useReactToPrint } from 'react-to-print';
export default function ({
    title,
    closeBtn = true,
    footer = true,
    header = true,
    // refPrint = null,
    modalSize = "md",
    id = "modal",
    data = {},
    dataLabel = [],
    callBackClose = () => null,
    footerComponent,
    children

}) {
    const {
        mpState,
        mpAction
    } = usePrintModal()
    const cetakFunction = useReactToPrint({
        content: () => refP?.current,
        bodyClass: "p-0 m-0"
    });

    const refP = useRef(null)
    const [dataField, setDatField] = useState([]);
    const [dataX, setDataX] = useState([]);

    useEffect(function(){
        setDatField(dataLabel);
        setDataX(data)
    },[dataField, data])
    return (
        <Modal backdrop="static" size={modalSize} show={mpState.modalDetil[id]} onHide={() => mpAction.closeModal(id)}>
            {
                header && <Modal.Header closeButton >
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
            }

            <ModalBody>
                <div className="" ref={refP} style={{ width: "100%" }}>
                    {
                        (dataField || [])?.map((label, i) =>
                            <div className="row mt-1" key={i}>
                                <div className="col-6 text-right">{label.name} </div>
                                <div className="col-6 text-left">{  typeof label.onRender === "function" ? label.onRender(dataX) : dataX[label.fieldName]} </div>
                            </div>
                        )
                    }
                    {
                        children
                    }
                </div>
            </ModalBody>
            {
                footer && (
                    <Modal.Footer>
                        <div className="row">
                            <div className="col">
                                <button
                                    onClick={() => cetakFunction()}
                                    className="btn btn-sm btn-primary ml-3 float-right">
                                    <i className="fa fa-print"></i> Cetak
                                </button>
                                {closeBtn && <button onClick={() => {
                                    mpAction.closeModal(id)
                                    mpState.modalDetil?.callBack?.()
                                    callBackClose()
                                }} className="btn btn-sm btn-outline-secondary float-right ml-3">
                                    Tutup
                                </button>}
                            </div>
                        </div>
                    </Modal.Footer>
                )
            }
            {
                !footer && footerComponent
            }
        </Modal>
    )

}
export { PrintModalProvider } from './useModalPrint';
export { usePrintModal };