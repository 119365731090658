import React from 'react';
import { useHistory, useParams } from 'react-router';
import { addCommas, removeNonNumeric } from '../../../../../module';
import { TableList } from '../../../../controls';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import useAmbilKas, { AKProvider } from './useAmbilKas';

const AmbilKasPage = function () {
    const { akState, akAction } = useAmbilKas();
    const { mode } = useParams();
    const history = useHistory();
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Kas Teller</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Permintaan Kas  Khasanah
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-md-6">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title text-base">
                                        Informasi Teller
                                    </div>
                                </div>
                                <div className="card-body">
                                    {
                                        mode === "otorisasi" && (
                                            <div className="box m-3">
                                                <h5>Nomor rekening : {akState.formData.nomor_rekening}</h5>
                                            </div>
                                        )
                                    }
                                    <div className="form-group">
                                        <label>Valuta</label>
                                        <div className="input-group">
                                            <div className="input-group-append col-3">
                                                <input type="text" className="form-control" disabled value={akState.formData?.kode_valuta} />
                                            </div>
                                            <input type="text" readOnly className="form-control col" value="INDONESIAN RUPIAH" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Total Saldo</label>
                                        <div className="input-group">
                                            <div className="input-group-append col-3">
                                                <input type="text" className="form-control" disabled value="IDR" />
                                            </div>
                                            <input type="text" className="form-control col"
                                                readOnly
                                                value={addCommas( parseFloat(akState.formData?.info_transaksi?.saldo_teller_before || 0).toFixed(2))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        PERMINTAAN KAS KHASANAH
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label>Jumlah Pengambilan</label>
                                        <input type="text"
                                            value={addCommas( parseFloat(akState.formData?.nominal || 0).toFixed(2))}
                                            readOnly className="form-control col" />
                                    </div>

                                    <div className="form-group">
                                        <label>Total Saldo Setelah Pengambilan</label>
                                        <div className="input-group Row">
                                            <div className="input-group-append col-3">
                                                <input type="text"
                                                    className="form-control" disabled value={akState.formData?.kode_valuta} />
                                            </div>
                                            <input type="text" readOnly className="form-control col"
                                                value={addCommas( parseFloat(akState.formData?.info_transaksi?.saldo_teller_after || 0).toFixed(2))}

                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>No Referensi</label>
                                        <input type="text" className="form-control col"
                                            onChange={(e) => akAction.changeForm(e.target.value, "nomor_referensi")}
                                            value={akState.formData?.nomor_referensi}
                                            readOnly={!akState.formEditable || true}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea 
                                            maxLength={100} type="text" className="form-control col"
                                            onChange={(e) => akAction.changeForm(e.target.value, "keterangan")}
                                            value={akState.formData?.keterangan}
                                            readOnly={!akState.formEditable}
                                        />
                                    </div>

                                </div>
                                <div className="card-footer">
                                    {
                                        akState.formEditable ? <div className="row">
                                            <div className="col-12">
                                                <button disabled={akState.err} className="btn btn-success btn-sm float-right ml-2" onClick={akAction.confirmTrx}>
                                                    Proses
                                                </button>
                                                {/* <button className="btn btn-outline-secondary btn-sm float-right ml-2" onClick={() => history.goBack()}>
                                                    Batal
                                                </button> */}
                                            </div>
                                        </div> : (
                                            mode === "otorisasi" ? (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button  className="btn btn-primary btn-sm float-right ml-2" onClick={()=>akAction.responseOtor("APPROVED")}>
                                                            Approve
                                                        </button>
                                                        <button className="btn btn-danger btn-sm float-right ml-2" onClick={()=>akAction.responseOtor("REJECTED")}>
                                                            Reject
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button className="btn btn-outline-secondary btn-sm float-right ml-2" onClick={() => history.goBack()}>
                                                            Back
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title text-base">
                                Denominasi
                            </div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                pagination={false}
                                filter={false}
                                withActions={false}
                                maxHeight="1500px"
                                columns={[
                                    {
                                        name: 'Nilai Denominasi',
                                        field: 'nilai_denominasi',
                                    },
                                    {
                                        name: 'Jenis',
                                        field: 'jenis',
                                    },
                                    {
                                        name: 'Jumlah Lembar',
                                        field: 'jumlah_lembar_koin',
                                        maxWidth: "100px",
                                        onRender: (item, key) => (
                                            <input
                                                type="text"
                                                value={ addCommas( item.jumlah_lembar_koin)}
                                                readOnly={!akState.formEditable}
                                                className="form-control form-control-sm"
                                                onChange={(e) => akAction.changeJumlahLembar( removeNonNumeric( e.target.value), key)}
                                                onBlur={(e) => {
                                                    let { value } = e.target
                                                    if(value === "" || value === null || value === undefined){
                                                        return  akAction.changeJumlahLembar( removeNonNumeric(0), key)
                                                    }
                                                }}
                                            />
                                        )
                                    },
                                    {
                                        name: 'Total Nominal',
                                        field: 'total',
                                        className: "text-right",
                                        onRender: (item) => <> {addCommas( parseFloat(item.nilai_denominasi * item.jumlah_lembar_koin || 0).toFixed(2))}, -</>
                                    },
                                ]}
                                data={
                                    akState.formData?.denominasi || []
                                }

                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => akAction.setOtorDesc(e.target.value)}
                    value={akState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )
}

export default function () {
    return <AKProvider>
        <AmbilKasPage />
    </AKProvider>
}