import dayjs from 'dayjs';
import React, { memo, useState, useCallback } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { Button } from 'semantic-ui-react';
import { debounce } from 'lodash-es';
import { baseUrl } from '../../../app.config';
import { AppInterfaces } from '../../../app.data';
import { createForm } from '../../../module/createFormList';
import { FormRegistrasi } from '../registrasi_deposito/formRegistrasi';
import { thousandSeparator } from '../../../utils';

const PencairanDeposito = AppInterfaces.appLoad(
    memo(({ show, onHide }) => {
        const [confirm, setConfirm] = useState(false);
        const [nomorDeposito, setNomorDeposito] = useState('');
        const [rekeningGL, setRekeningGL] = useState({});
        const [biaya, setBiaya] = useState(0);
        const [nomorRekening, setNomorRekening] = useState('');
        const [form, setForm] = useState({});
        const [error, setError] = useState({});
        const [disposisi, setDisposisi] = useState('');
        const [rekap, setRekap] = useState([]);
        const [cabang, setCabang] = useState([]);
        const [prosesPencairan, setProsesPencairan] = useState({
            showModalProsesGagal: false,
            messageProses: '',
            showModalProsesSukses: false,
        });

        const { data: disposisiOpt } = useQuery('disposisiOpt', async () => {
            const res = await fetch(baseUrl + '/dropdowns?key=eDisposisiBagiHasil');

            const data = await res.json();

            return data?.list;
        });

        const handlePencairanDeposito = useMutation(
            async () => {
                const res = await fetch(baseUrl + '/deposito/pencairan/create', {
                    method: 'POST',
                    body: JSON.stringify({
                        userId: '12345',
                        nomorRekening: nomorDeposito,
                        disposisiPokok: disposisi.toLowerCase(),
                        valuta: 'IDR',
                        nomorGl: form?._bagiHasilDisposisi?.nomorRekening,
                        biaya: biaya,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await res.json();
                if (data.message_id === '00') {
                    setProsesPencairan({
                        showModalProsesGagal: false,
                        messageProses: '',
                        showModalProsesSukses: true,
                    });
                    return data;
                } else {
                    setProsesPencairan({
                        showModalProsesGagal: true,
                        messageProses: data.status,
                        showModalProsesSukses: false,
                    });
                    handlePencairanDeposito.isSuccess = false;
                }
            },
            {
                onSuccess: () => {
                    setConfirm(false);
                    setForm({});
                    setNomorDeposito('');
                    handleReset();
                },
            }
        );

        const handleSearchNasabah = async () => {
            const res = await fetch(baseUrl + `/deposito/transaksi/rekap/${nomorDeposito}`);
            const respon = await res.json();
            setRekap(respon.list);

            const get = await fetch(
                baseUrl + `/rekening/tabungan/kantor/cabang/8a8e8fab786e635c0178863b7911431e/detail`
            );
            const resp = await get.json();
            setCabang(resp.list);

            const depClient = await fetch(
                baseUrl +
                    `/deposito/find/${nomorDeposito}?accountHolderType=CLIENT&detailsLevel=FULL`
            );

            const depGroup = await fetch(
                baseUrl +
                    `/deposito/find/${nomorDeposito}?accountHolderType=GROUP&detailsLevel=FULL`
            );

            const dataDepClient = await depClient.json();
            const dataDepGroup = await depGroup.json();

            let dataForm;

            if (!dataDepClient.list && !dataDepGroup.list) {
                setForm({});
                setError({
                    ...error,
                    nasabah: true,
                });
                return;
            }

            if (dataDepClient.list) {
                dataForm = dataDepClient;
            } else {
                dataForm = dataDepGroup;
            }

            const resRekeningTabungan = await fetch(
                baseUrl +
                    `/rekening/tabungan/approve/${dataForm?.list?._bagiHasilDisposisi?.nomorRekening}/detail`
            );
            const rekeningTabungan = await resRekeningTabungan.json();

            setForm({
                ...dataForm?.list,
                _bagiHasilDisposisi: {
                    ...dataForm?.list?._bagiHasilDisposisi,
                    namaRekening: rekeningTabungan?.list?.name,
                },
            });
            setError({
                ...error,
                nasabah: false,
            });
            return;
        };

        // eslint-disable-next-line
        const findGL = useCallback(
            debounce(async (value, callback) => {
                if (!value) {
                    setRekeningGL({});
                    callback('no value');
                    return;
                }

                const res = await fetch(baseUrl + `/teller/general-ledger/${value}`);

                const data = await res.json();

                if (!data.list) {
                    setRekeningGL({});
                    if (callback) callback('error');
                    return;
                }

                setRekeningGL(data?.list[0]);
                if (callback) callback('success');
            }, 500),
            []
        );

        const handleSearchGL = useCallback(
            async (e) => {
                const { value } = e.target;
                await findGL(value, (status) => {
                    if (status === 'error' && value > 0) setError({ ...error, rekeningGL: true });
                    else setError({ ...error, rekeningGL: false });
                });
            },
            [error, findGL]
        );

        const handleReset = async () => {
            setForm({});
            setNomorDeposito('');
            setBiaya(0);
            setRekap([]);
            setCabang([]);
        };

        const getNomorRekening = async (value) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };
            fetch(baseUrl + '/rekening/tabungan/detail/' + value, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        let dataDetail = data.list;
                        setForm({
                            _bagiHasilDisposisi: {
                                ...form._bagiHasilDisposisi,
                                namaRekening: dataDetail.nama,
                                nomorRekening: value,
                            },
                        });
                    } else {
                        setForm({
                            _bagiHasilDisposisi: {
                                ...form._bagiHasilDisposisi,
                                namaRekening: '',
                                nomorRekening: value,
                            },
                        });
                    }
                });
        };

        return (
            <>
                <Modal centered show={prosesPencairan.showModalProsesSukses}>
                    <Modal.Header>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Proses Pencairan Deposito berhasil dilakukan
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => {
                                handlePencairanDeposito.reset();
                                setProsesPencairan({
                                    showModalProsesSukses: false,
                                });
                            }}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Selesai
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={prosesPencairan.showModalProsesGagal}>
                    <Modal.Header>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            {prosesPencairan.messageProses}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() =>
                                setProsesPencairan({
                                    showModalProsesGagal: false,
                                })
                            }
                            className="text-white mr-2"
                            size="mini"
                        >
                            Tutup
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={confirm}>
                    <Modal.Header onHide={() => setConfirm(false)} closeButton>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Lakukan proses Pencairan Deposito?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setConfirm(false)}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Tidak
                        </Button>
                        <Button
                            loading={handlePencairanDeposito.isLoading}
                            onClick={() => handlePencairanDeposito.mutate()}
                            className="tw-w-16"
                            size="mini"
                            color="blue"
                        >
                            Ya
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered size="xl" show={show}>
                    <Modal.Header
                        closeButton
                        onHide={() => {
                            handleReset();
                            if (onHide) onHide();
                        }}
                    >
                        <Modal.Title>
                            <div className="tw-text-18 tw-font-bold">Pencairan Deposito</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            className="row"
                            style={{
                                padding: '0px 20px',
                                marginBottom: '30px',
                            }}
                        >
                            <div className="col-md-12">
                                <label
                                    style={{
                                        fontSize: '12px',
                                    }}
                                >
                                    Nomor Deposito
                                </label>
                                <br />
                                <div
                                    style={{ gridTemplateColumns: '1fr auto auto' }}
                                    className="tw-grid tw-items-start"
                                >
                                    <Form.Group className="tw-mr-2" bsPrefix=" ">
                                        <Form.Control
                                            value={nomorDeposito}
                                            onChange={(e) => setNomorDeposito(e.target.value)}
                                            isInvalid={error.nasabah}
                                            className="tw-w-full"
                                            size="sm"
                                            type="text"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Rekening tidak dapat ditemukan
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <button
                                        onClick={() => {
                                            setNomorDeposito('');
                                            setForm({});
                                        }}
                                        className="btn btn-outline-secondary btn-sm mr-2"
                                    >
                                        Hapus
                                    </button>
                                    <button
                                        onClick={handleSearchNasabah}
                                        className="btn btn-sm"
                                        style={{ background: '#E6530D', color: 'white' }}
                                    >
                                        Cari Nomor Nasabah
                                    </button>
                                </div>
                            </div>
                        </div>
                        <FormRegistrasi
                            itemsForm={[
                                {
                                    name: 'Info Deposito',
                                    rows: [
                                        {
                                            rows: 1,
                                            label: 'Nama Nasabah',
                                            type: 'text',
                                            placeholder: 'nama nasabah',
                                            disabled: true,
                                            readonly: false,
                                            onChange: (value) => null,
                                            value: form?._dataNasabah?.nama || '',
                                        },
                                        {
                                            rows: 1,
                                            label: 'Cabang Rekening',
                                            type: 'text',
                                            placeholder: 'Cabang Rekening',
                                            disabled: true,
                                            readonly: false,
                                            value: `${cabang?.id ?? ''} - ${cabang?.name ?? ''}`,
                                            onChange: (value) => null,
                                        },
                                        {
                                            rows: 1,
                                            label: 'Valuta Rekening',
                                            type: 'text',
                                            placeholder: 'Valuta Rekening',
                                            disabled: true,
                                            readonly: false,
                                            onChange: (value) => null,
                                            value: form?._rekening?.valuta || '',
                                        },
                                        {
                                            rows: 2,
                                            label: 'Nominal Deposito',
                                            type: 'text',
                                            placeholder: 'Nominal Deposito',
                                            disabled: true,
                                            readonly: false,
                                            onChange: (value) => null,
                                            value: thousandSeparator.formatView(
                                                rekap?.nominalPokok || 0,
                                                { preventNegative: true }
                                            ),
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {createForm({
                                                                label: 'Tanggal Buka',
                                                                type: 'date',
                                                                disabled: true,
                                                                readonly: false,
                                                                onChange: (value) => null,
                                                                value: form?._rekening?.tanggalBuka,
                                                            })}
                                                        </div>
                                                        <div className="col-md-6">
                                                            {createForm({
                                                                label: 'Jatuh Tempo',
                                                                type: 'date',
                                                                disabled: true,
                                                                readonly: false,
                                                                onChange: (value) => null,
                                                                value: form?._rekening
                                                                    ?.tanggalJatuhTempo,
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {createForm({
                                                                label: 'ARO',
                                                                type: 'date',
                                                                placeholder: 'ARO',
                                                                disabled: true,
                                                                readonly: false,
                                                                onChange: (value) => null,
                                                            })}
                                                        </div>
                                                        <div className="col-md-6">
                                                            {createForm({
                                                                label: 'ARO Ke',
                                                                type: 'text',
                                                                placeholder: 'ARO Ke',
                                                                disabled: true,
                                                                readonly: false,
                                                                onChange: (value) => null,
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        },
                                    ],
                                },
                                {
                                    name: 'Perhitungan Pembayaran Ke Nasabah',
                                    rows: [
                                        {
                                            rows: 1,
                                            label: 'Nominal',
                                            type: 'text',
                                            placeholder: 'Nominal',
                                            disabled: true,
                                            readonly: false,
                                            value: thousandSeparator.formatView(
                                                rekap?.nominalPokok || 0,
                                                { preventNegative: true }
                                            ),
                                            onChange: (value) => null,
                                        },
                                        {
                                            rows: 1,
                                            label: 'Pinalty Pencairan',
                                            type: 'text',
                                            placeholder: 'Pinalty Pencairan',
                                            disabled: true,
                                            readonly: false,
                                            value: thousandSeparator.formatView(
                                                rekap?.pinalti || 0,
                                                { preventNegative: true }
                                            ),
                                            onChange: (value) => null,
                                        },
                                        {
                                            rows: 1,
                                            label: 'Cadangan Bagi Hasil Kapilatisir',
                                            type: 'text',
                                            placeholder: 'Cadangan Bagi Hasil Kapilatisir',
                                            disabled: true,
                                            readonly: false,
                                            value: thousandSeparator.formatView(
                                                rekap?.cadanganBagiHasil || 0,
                                                { preventNegative: true }
                                            ),
                                            onChange: (value) => null,
                                        },
                                        {
                                            rows: 2,
                                            label: 'Total Pemotongan Pajak',
                                            type: 'text',
                                            placeholder: 'Total Pemotongan Pajak',
                                            disabled: true,
                                            readonly: false,
                                            value: thousandSeparator.formatView(rekap?.pajak || 0, {
                                                preventNegative: true,
                                            }),
                                            onChange: (value) => null,
                                        },
                                        {
                                            rows: 2,
                                            label: 'Total Pemotongan Zakat',
                                            type: 'text',
                                            placeholder: 'Total Pemotongan Zakat',
                                            disabled: true,
                                            readonly: false,
                                            value: thousandSeparator.formatView(rekap?.zakat || 0, {
                                                preventNegative: true,
                                            }),
                                            onChange: (value) => null,
                                        },
                                        {
                                            rows: 2,
                                            label: 'Nilai Minimal Yang Harus Dibayarkan',
                                            type: 'text',
                                            placeholder: 'Nilai Minimal Yang Harus Dibayarkan',
                                            disabled: true,
                                            readonly: false,
                                            value: thousandSeparator.formatView(
                                                rekap?.nilaiMinimumDibayarkan || 0,
                                                { preventNegative: true }
                                            ),
                                            onChange: (value) => null,
                                        },
                                        // {
                                        //     rows: 2,
                                        //     label: 'Info Saldo Acrual Bagi Hasil',
                                        //     type: 'text',
                                        //     placeholder: 'Info Saldo Acrual Bagi Hasil',
                                        //     value: thousandSeparator.formatView(
                                        //         rekap?.saldoAcrual || 0,
                                        //         { preventNegative: true }
                                        //     ),
                                        //     disabled: true,
                                        //     readonly: false,
                                        //     onChange: (value) => null,
                                        // },
                                    ],
                                },
                                {
                                    name: 'Disposisi',
                                    rows: [
                                        {
                                            rows: 1,
                                            label: 'Nilai Yang Dibayarkan Pada Deposan',
                                            type: 'text',
                                            placeholder: 'Nilai Yang Dibayarkan Pada Deposan',
                                            value: thousandSeparator.formatView(
                                                rekap?.nilaiMinimumDibayarkan || 0,
                                                { preventNegative: true }
                                            ),
                                            disabled: true,
                                            readonly: false,
                                            onChange: (value) => null,
                                        },

                                        {
                                            rows: 1,
                                            label: 'Disposisi',
                                            type: 'select',
                                            placeholder: 'Pilih Disposisi',
                                            readonly: false,
                                            onChange: (e) => setDisposisi(e),
                                            value: form?._bagiHasilDisposisi?.instruksiDisposisi,
                                            options: disposisiOpt?.map(
                                                ({ reference_desc, reference_code }) => ({
                                                    display: reference_desc,
                                                    // key: `${reference_code} - ${reference_desc}`,
                                                    key: reference_desc,
                                                })
                                            ),
                                        },
                                        {
                                            rows: 1,
                                            type: 'custom',
                                            component: (
                                                <>
                                                    <div className="row" style={{ paddingLeft: 7 }}>
                                                        <div className="col-md-12 mb-3">
                                                            <label
                                                                style={{
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                Valuta Pencairan
                                                            </label>
                                                            <br />
                                                            <Form.Control
                                                                value={form?._rekening?.valuta}
                                                                size="sm"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        },
                                        {
                                            rows: 1,
                                            type: 'custom',
                                            component: (
                                                <div className="tw-grid tw-grid-cols-2 tw-gap-x-5 tw-px-2">
                                                    <div>
                                                        <Form.Label className="f-12">
                                                            Nomor Rekening
                                                        </Form.Label>
                                                        <Form.Control
                                                            size="sm"
                                                            // placeholder="1050023"
                                                            type="text"
                                                            // onChange={handleSearchGL}
                                                            // isInvalid={error.rekeningGL}
                                                            // onChange={(e) =>
                                                            //     setNomorRekening(e.target.value)
                                                            // }
                                                            onChange={(e) => {
                                                                getNomorRekening(e.target.value);
                                                            }}
                                                            value={
                                                                form?._bagiHasilDisposisi
                                                                    ?.nomorRekening ?? ''
                                                            }
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid">
                                                            Rekening GL tidak dapat ditemukan
                                                        </Form.Control.Feedback> */}
                                                    </div>
                                                    <div>
                                                        <Form.Label className="f-12">
                                                            Nama Rekening / GL
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            size="sm"
                                                            value={
                                                                form?._bagiHasilDisposisi
                                                                    ?.namaRekening
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            ),
                                        },
                                        {
                                            rows: 2,
                                            type: 'custom',
                                            component: (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {createForm({
                                                                label: 'Kurs Jual',
                                                                type: 'text',
                                                                placeholder: 'Kurs Jual',
                                                                disabled: true,
                                                                readonly: false,
                                                                value: 1,
                                                                onChange: (value) => null,
                                                            })}
                                                        </div>
                                                        <div className="col-md-6">
                                                            {createForm({
                                                                label: 'Kurs Beli',
                                                                type: 'text',
                                                                placeholder: 'Kurs Beli',
                                                                disabled: true,
                                                                readonly: false,
                                                                value: 1,
                                                                onChange: (value) => null,
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        },

                                        {
                                            rows: 2,
                                            label: 'Kurs Buku',
                                            type: 'text',
                                            placeholder: 'Kurs Buku',
                                            disabled: true,
                                            readonly: false,
                                            value: 1,
                                            onChange: (value) => null,
                                        },

                                        {
                                            rows: 2,
                                            label: 'Nilai Ekuivalen',
                                            type: 'text',
                                            placeholder: 'Nilai Ekuivalen',
                                            value: thousandSeparator.formatView(
                                                rekap?.nilaiEkuivalen || 0,
                                                { preventNegative: true }
                                            ),
                                            disabled: true,
                                            readonly: false,
                                            onChange: (value) => null,
                                        },

                                        // {
                                        //     rows: 2,
                                        //     label: 'Biaya',
                                        //     type: 'text',
                                        //     // placeholder: 'Biaya',
                                        //     disabled: false,
                                        //     readonly: false,
                                        //     value: biaya,
                                        //     onChange: (e) => {
                                        //         const newValue = thousandSeparator.format(
                                        //             e,
                                        //             { preventNegative: true }
                                        //         )
                                        //         setBiaya(newValue)
                                        //     },
                                        // },
                                    ],
                                },
                            ]}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => {
                                handleReset();
                                if (onHide) onHide();
                            }}
                            className="tw-font-normal tw-py-2 tw-mr-2"
                        >
                            Batal
                        </Button>
                        <Button
                            onClick={() => {
                                setConfirm(true);
                                if (onHide) onHide();
                            }}
                            className="tw-font-normal tw-py-2"
                            color="blue"
                        >
                            Proses
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);

export default PencairanDeposito;
