import React from 'react';

const pages = (namePage) =>
    React.lazy(() =>
        import('../components/pages')
            .then((m) => ({ default: m[namePage] }))
            .catch((err) => err)
    );

export const routes = [
    {
        key: '/',
        icon: 'fa fa-tachometer-alt',
        type: 1,
        iconType: 'css',
        name: 'Dashboard',
        component: pages('DasboardPage'),
        auth: true,
    },
    {
        key: '/branch-monitor',
        icon: 'fa fa-desktop',
        type: 1,
        iconType: 'css',
        auth: true,
        name: 'Branch Monitoring',
        component: pages('BranchMonitor'),
        roles: ['DATACENTRE'],
    },
    {
        key: 'cs',
        type: 0,
        name: '',
        auth: true,
        roles: [
            'cs',
            'spv cs',
            'spv teller',
            'back office',
            'spv treasury ops',
            'dept head operation',
            'group head operation',
            'direktur dan presdir',
            'oprasional IT',
            'teller',
            'MANAGER OPERASIONAL',
            'inquiry', ""
        ],
        childs: [
            {
                key: '/nasabah',
                icon: 'fa fa-user',
                type: 1,
                iconType: 'css',
                name: 'Nasabah / CIF',
                roles: ['cs', 'spv treasury ops', 'oprasional IT', 'teller', "", 
                'spv cs',
                'spv teller',],
                childs: [
                    {
                        key: '/nasabah-cif/register',
                        icon: '',
                        type: 1,
                        iconType: 'css',
                        name: 'Registrasi Nasabah',
                        component: pages('NasabahCIFRegistrasi'),
                        roles: ['cs', 'oprasional IT'],
                    },
                    {
                        key: '/data_nasabah/cif',
                        type: 1,
                        iconType: 'css',
                        name: 'Data Nasabah',
                        component: pages('NasabahPage'),
                        roles: ['cs', 'cssupervisor', 'spv treasury ops', 'oprasional IT', 'teller',"", 
                        'spv cs',
                        'spv teller', ],
                    },
                    // {
                    //     key: '/data_nasabah/cif/approved',
                    //     type: 1,
                    //     iconType: 'css',
                    //     name: 'Info Daftar Hitam Nasabah',
                    //     component: pages('NasabahApprovedPage'),
                    //     roles: ['cs', 'cssupervisor', 'spv treasury ops'],
                    // },
                    // {
                    //     key: '/data_nasabah/cif/approved',
                    //     type: 1,
                    //     iconType: 'css',
                    //     name: 'Info Daftar Hitam Nasabah',
                    //     component: pages('DaftarHitam'),
                    //     roles: ['cs', 'cssupervisor', 'spv treasury ops','oprasional IT'],
                    // },
                    {
                        key: '/nasabah-cif/register/perorangan',
                        icon: '',
                        type: 2,
                        iconType: 'css',
                        name: 'Registrasi Nasabah / CIF',
                        component: pages('NasabahCIFRegistrasiPerorangan'),
                        roles: ['cs', 'oprasional IT'],
                    },
                    {
                        key: '/wic/registrasi/perorangan',
                        icon: '',
                        type: 2,
                        iconType: 'css',
                        name: 'Registrasi WIC',
                        component: pages('WicRegistrasiPerorangan'),
                        roles: ['cs', 'oprasional IT'],
                    },
                    {
                        key: '/nasabah-cif/register/badan-usaha',
                        icon: '',
                        type: 2,
                        iconType: 'css',
                        name: 'Registrasi Nasabah / CIF Badan Usaha',
                        component: pages('NasabahCIFRegistrasiBadanUsaha'),
                        roles: ['cs', 'oprasional IT'],
                    },
                    {
                        key: '/nasabah-cif/data/svs_rekening_nasabah_korporat',
                        icon: '',
                        type: 2,
                        iconType: 'css',
                        name: 'svs rekening nasabah korporat',
                        component: pages('SvsRekeningNasabahKorporat'),
                        roles: ['cs', 'oprasional IT', 'teller'],
                    },
                    {
                        key: '/nasabah-cif/data/svs/:id',
                        name: 'SVS Nasabah',
                        iconType: 'css',
                        component: pages('SvsNasabah'),
                        roles: ['cs', 'oprasional IT', 'teller', 
                        'spv cs',
                        'spv teller', ],
                        icon: '',
                        type: 2
                    }, {
                        key: '/nasabah-cif/data/portofolio',
                        name: 'SVS Nasabah',
                        iconType: 'css',
                        component: pages('PortofolioNasabah'),
                        roles: ['cs', 'oprasional IT', 'teller', "", 
                        'spv cs',
                        'spv teller',],
                        icon: '',
                        type: 2
                    }, {
                        key: '/nasabah-cif/form-perorangan/:mode',
                        name: 'Form Registrasi Nasabah Peorangan',
                        iconType: 'css',
                        component: pages("FormNasabahPerorangan"),
                        icon: '',
                        type: 2
                    }, {
                        key: '/nasabah-cif/form-badan-usaha/:mode',
                        name: 'Form Registrasi Nasabah Badan Usaha',
                        iconType: 'css',
                        component: pages("FormNasabahBadanUsaha"),
                        icon: '',
                        type: 2
                    },

                ],
            },
            {
                key: '/tabungan-giro',
                icon: 'fa fa-wallet',
                type: 1,
                iconType: 'css',
                name: 'Tabungan & Giro',
                roles: [
                    'cs',
                    'cssupervisor',
                    'spv cs',
                    'back office',
                    'spv treasury ops',
                    'dept head operation',
                    'group head operation',
                    'direktur dan presdir',
                    'oprasional IT',
                    'teller',
                    'MANAGER OPERASIONAL',
                    'inquiry', 
                    ""
                ],
                childs: [
                    {
                        key: '/tabungan-giro/registrasi',
                        type: 1,
                        iconType: 'css',
                        name: 'Registrasi Rekening',
                        component: pages('RegistrasiDeposito'),
                        roles: ['cs', 'oprasional IT'],
                    },
                    {
                        key: '/tabungan-giro/data-rekening',
                        type: 1,
                        iconType: 'css',
                        name: 'Data Rekening',
                        component: pages('DataRekeningPage'),
                        roles: [
                            'cs',
                            'cssupervisor',
                            'spv cs',
                            'back office',
                            'spv treasury ops',
                            'dept head operation',
                            'group head operation',
                            'direktur dan presdir',
                            'oprasional IT',
                            'teller',
                            'MANAGER OPERASIONAL',
                            'inquiry', 
                            ""
                        ],
                    },
                    {
                        key: '/tabungan-giro/data-aft',
                        type: 1,
                        iconType: 'css',
                        name: 'Data AFT',
                        component: pages('DataAFT'),
                        roles: [
                            'cs',
                            'oprasional IT'
                        ],
                    },
                    {
                        key: '/tabungan-giro/form-aft/registration',
                        type: 1,
                        iconType: 'css',
                        name: 'Registrasi AFT',
                        roles: [
                            'cs',
                            'oprasional IT'
                        ],
                    },
                    {
                        key: '/tabungan-giro/registrasi/tabungan',
                        type: 2,
                        name: 'Registrasi Tabungan',
                        component: pages('RegistrasiTabunganDeposito'),
                        roles: ['cs', 'oprasional IT'],
                    },
                    {
                        key: '/tabungan-giro/registrasi/tabungan-giro',
                        type: 2,
                        name: 'Registrasi Tabungan Giro',
                        component: pages('RegistrasiTabunganDepositoGiro'),
                        roles: ['cs', 'oprasional IT'],
                    },
                    {
                        key: '/tabungan-giro/form-tabungan-rencana/:mode',
                        name: 'Form Registrasi Tabungan Rencana',
                        iconType: 'css',
                        component: pages("FormTabunganRencana"),
                        icon: '',
                        type: 2
                    },
                    {
                        key: '/tabungan-giro/form/:type/:mode',
                        name: 'Form Registrasi Tabungan',
                        iconType: 'css',
                        component: pages("FormTabungan"),
                        icon: '',
                        type: 2
                    },
                    {
                        key: '/tabungan-giro/form-aft/:mode',
                        type: 2,
                        iconType: 'css',
                        name: 'Registrasi AFT',
                        component: pages('RegistrasiAFT'),
                        roles: [
                            'cs',
                            'cssupervisor',
                            'spv cs',
                            'oprasional IT',
                            'MANAGER OPERASIONAL'
                        ],
                    },
                    {
                        key: '/tabungan-giro/form-notif/registration',
                        type: 1,
                        iconType: 'css',
                        name: 'Registrasi Notif',
                        roles: [
                            'cs',
                            'oprasional IT'
                        ],
                    },
                    {
                        key: '/tabungan-giro/form-notif/:mode',
                        type: 2,
                        iconType: 'css',
                        name: 'Registrasi Notif',
                        component: pages('RegistrasiNotif'),
                        roles: [
                            'cs',
                            'cssupervisor',
                            'spv cs',
                            'oprasional IT',
                            'MANAGER OPERASIONAL'
                        ],
                    },
                    {
                        key: '/tabungan-giro/data-notif',
                        type: 1,
                        iconType: 'css',
                        name: 'Data Notif',
                        component: pages('DataNotifPage'),
                        roles: [
                            'cs',
                            'oprasional IT'
                        ],
                    },
                    {
                        key: '/tabungan-giro/form-tabungan/registration',
                        type: 1,
                        iconType: 'css',
                        name: 'Registrasi Buku Tabungan',
                        roles: [
                            'cs',
                            'oprasional IT'
                        ],
                    },
                    {
                        key: '/tabungan-giro/data-buku-tabungan',
                        type: 1,
                        iconType: 'css',
                        name: 'Data Buku Tabungan',
                        component: pages('DataBukuTabunganPage'),
                        roles: [
                            'cs',
                            'teller',
                            'oprasional IT'
                        ],
                    },
                    {
                        key: '/tabungan-giro/form-tabungan/:mode',
                        type: 2,
                        iconType: 'css',
                        name: 'Registrasi Buku Tabungan',
                        component: pages('RegistrasiTabungan'),
                        roles: [
                            'cs',
                            'cssupervisor',
                            'spv cs',
                            'oprasional IT',
                            'MANAGER OPERASIONAL'
                        ],
                    },
                ],
            },
            {
                key: '/deposito',
                icon: 'fa fa-wallet',
                type: 1,
                iconType: 'css',
                name: 'Deposito',
                roles: [
                    'cs',
                    'cssupervisor',
                    'spv cs',
                    'back office',
                    'spv treasury ops',
                    'dept head operation',
                    'group head operation',
                    'direktur dan presdir',
                    'oprasional IT',
                    'MANAGER OPERASIONAL'
                ],
                childs: [
                    {
                        key: '/deposito/form-deposito/:mode',
                        type: 1,
                        iconType: 'css',
                        name: 'Registrasi Deposito',
                        component: pages('FormRegisDeposito'),
                        roles: ['cs', 'oprasional IT'],
                        paramUrl: { mode: "registration" }
                    },
                    {
                        key: '/deposito/data',
                        type: 1,
                        iconType: 'css',
                        name: 'Data Deposito',
                        component: pages('NewDataDepositoPage'),
                        roles: ['cs', 'spv cs', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },
                    {
                        key: '/deposito/registrasi/tabungan',
                        type: 2,
                        name: 'Registrasi Tabungan',
                        component: pages('RegistrasiTabunganDeposito'),
                        roles: ['cs', 'oprasional IT'],
                    }, {
                        key: '/deposito/formDeposito/:mode',
                        type: 2,
                        iconType: 'css',
                        name: 'Registrasi Deposito',
                        component: pages('FormRegisDeposito'),
                        roles: [
                            'cs',
                            'cssupervisor',
                            'spv cs',
                            'back office',
                            'spv treasury ops',
                            'dept head operation',
                            'group head operation',
                            'direktur dan presdir',
                            'oprasional IT',
                            'MANAGER OPERASIONAL'
                        ],
                        paramUrl: { mode: "registration" }
                    },
                    // {
                    //     key: '/deposito/form-deposito/:mode',
                    //     name: 'Form Registrasi Deposito',
                    //     iconType:'css',
                    //     component: pages("FormRegisDeposito"),
                    //     icon:'',
                    //     type:2
                    // },

                    // {
                    //     key: '/deposito/data/approved',
                    //     type: 1,
                    //     iconType: 'css',
                    //     name: 'Data Deposito (Approved)',
                    //     roles: [
                    //         'cs',
                    //         'cssupervisor',
                    //         'spv cs',
                    //         'back office',
                    //         'spv treasury ops',
                    //         'dept head operation',
                    //         'group head operation',
                    //         'direktur dan presdir',
                    //     ],
                    //     component: pages('DepositoApprovedPage'),
                    // },
                    {
                        key: '/deposito/data/detail',
                        type: 2,
                        iconType: 'css',
                        name: 'Rekening Deposito',
                        component: pages('DetailDepositoPage'),
                        roles: ['cs', 'cssupervisor', 'oprasional IT'],
                    },
                    {
                        key: '/deposito/data/edit',
                        type: 2,
                        iconType: 'css',
                        name: 'Data Deposito',
                        component: pages('EditDepositoPage'),
                        roles: ['cs', 'cssupervisor', 'oprasional IT'],
                    },
                    {
                        key: '/deposito/data/svs',
                        type: 2,
                        iconType: 'css',
                        name: 'Data Deposito',
                        component: pages('SVSDepositoPage'),
                        roles: ['cs', 'cssupervisor', 'oprasional IT', 'teller'],
                    },
                    {
                        key: '/deposito/detil',
                        type: 2,
                        iconType: 'css',
                        name: 'Data Deposito',
                        component: pages('InformasiDeposito'),
                        roles: ['cs', 'cssupervisor', 'oprasional IT'],
                    },
                    {
                        key: '/deposito/edit',
                        type: 2,
                        iconType: 'css',
                        name: 'Data Deposito',
                        component: pages('EditDeposito'),
                        roles: ['cs', 'cssupervisor', 'oprasional IT'],
                    },
                ]
            },

        ],
    },


    // teller
    {
        key: 'teller',
        type: 0,
        name: '',
        auth: true,
        roles: ['teller', 'back office', 'human capital', 'treasury ops', 'oprasional IT', 'spv teller', 'spv cs', 'cs'],
        childs: [
            // {
            //     key: '/transaksi-umum',
            //     icon: 'fa fa-wallet',
            //     type: 1,
            //     iconType: 'css',
            //     name: 'Deposito',
            //     roles: [],
            //     childs: [

            //     ],
            // },
            {
                key: '/wic',
                icon: 'fa fa-user',
                type: 1,
                iconType: 'css',
                name: 'WIC',
                roles: ['teller','cs','spv cs'],
                childs: [
                    {
                        key: '/wic/form/',
                        type: 1,
                        iconType: 'css',
                        name: 'Registrasi WIC',
                        component: pages('RegistrasiWicPage'),
                        iconType: 'css',
                        roles: ['teller', 'oprasional IT'],
                    },
                    {
                        key: '/wic/registrasi-badan-usaha',
                        type: 1,
                        iconType: 'css',
                        name: 'Data WIC',
                        component: pages('DataWICpage'),
                        roles: ['teller', 'oprasional IT','cs','spv cs'],
                    },
                    // {
                    //     key: '/tabungan-giro/rekening',
                    //     type: 1,
                    //     iconType: 'css',
                    //     name: 'Data Rekening (Approved)',
                    //     component: pages('RekeningPage'),
                    //     roles: ['cs'],
                    // },

                    {
                        key: '/wic/form/:mode',
                        type: 2,
                        iconType: 'css',
                        name: 'Registrasi WIC',
                        component: pages('FormPeroranganWic'),
                        iconType: 'css',
                        roles: ['teller', 'spv cs', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },

                ],
            },
            {
                key: '/transaksi-nasabah',
                icon: 'fa fa-wallet',
                type: 1,
                iconType: 'css',
                name: 'Transaksi Nasabah',
                roles: ['teller', 'oprasional IT'],
                childs: [
                    {
                        key: '/teller/transaksi-nasabah/setor-tunai',
                        type: 1,
                        iconType: 'css',
                        openBranch: true,
                        name: 'Setor Tunai',
                        roles: ['teller', 'oprasional IT'],
                    },
                    {
                        key: '/teller/transaksi-nasabah/tarik-tunai',
                        type: 1,
                        iconType: 'css',
                        name: 'Tarik Tunai',
                        openBranch: true,
                        roles: ['teller', 'oprasional IT'],
                    },
                    {
                        key: '/teller/transaksi-nasabah/setor-tunai/:mode?',
                        type: 2,
                        iconType: 'css',
                        name: 'Setor Tunai',
                        openBranch: true,
                        component: pages('NewSetorTunai'),
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                        paramUrl: { mode: "setor-tunai" }
                    },
                    {
                        key: '/teller/transaksi-nasabah/tarik-tunai/:mode?',
                        type: 2,
                        iconType: 'css',
                        name: 'Tarik Tunai',
                        openBranch: true,
                        component: pages('NewTarikTunai'),
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },
                    //belum ada API
                    {
                        key: '/teller/transaksi-nasabah/pembayaran-angsuran',
                        type: 1,
                        iconType: 'css',
                        name: 'Pembayaran Angsuran',
                        openBranch: true,
                        roles: ['teller', 'oprasional IT'],
                    },
                    {
                        key: '/teller/transaksi-nasabah/pembayaran-angsuran/:mode?',
                        type: 2,
                        iconType: 'css',
                        name: 'Pembayaran Angsuran',
                        openBranch: true,
                        component: pages('NewPembayaranAngsuran'),
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },
                ],
            },
            {
                key: '/teller/transfer',
                icon: 'fa fa-user',
                type: 1,
                iconType: 'css',
                name: 'Transfer',
                roles: ['teller', 'oprasional IT'],
                childs: [
                    // {
                    //     key: '/teller/transfer/pindah-buku',
                    //     type: 1,
                    //     iconType: 'css',
                    //     name: 'Pindah Buku',
                    //     component: pages('PindahBuku'),
                    //     roles: ['teller'],
                    // },
                    {
                        key: '/teller/transfer/skn/tunai',
                        type: 1,
                        iconType: 'css',
                        openBranch: true,
                        name: 'Transfer SKN Tunai',
                        roles: ['teller', 'oprasional IT'],
                    },
                    // {
                    //     key: '/teller/transfer/skn/debit',
                    //     type: 1,
                    //     iconType: 'css',
                    //     name: 'Transfer SKN Debit Rekening',
                    //     roles: ['teller'],
                    // },
                    {
                        key: '/teller/transfer/rtgs/tunai',
                        type: 1,
                        iconType: 'css',
                        openBranch: true,
                        name: 'Transfer RTGS Tunai',
                        roles: ['teller', 'oprasional IT'],
                    },
                    // {
                    //     key: '/teller/transfer/rtgs/debit',
                    //     type: 1,
                    //     iconType: 'css',
                    //     name: 'Transfer RTGS Debit Rekening',
                    //     roles: ['teller'],
                    // },

                    // BIFAST
                    {
                        key: '/teller/transfer/bifast',
                        iconType: 'css',
                        openBranch: true,
                        name: 'Transfer BI Fast',
                        roles: ['teller', 'oprasional IT'],
                    },
                    {
                        key: '/teller/transfer/bifast/:mode?',
                        type: 2,
                        iconType: 'css',
                        openBranch: true,
                        name: 'Transfer BIFAST',
                        component: pages('TFBiFastPage'),
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },
                    {
                        key: '/teller/transfer/:type/:jenistf/:mode?',
                        type: 2,
                        iconType: 'css',
                        openBranch: true,
                        name: 'Transfer RTGS Debit Rekening',
                        component: pages('FormTransfer'),
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },
                ],
            },

            {
                key: '/teller/kas-teller',
                icon: 'fa fa-user',
                type: 1,
                iconType: 'css',
                name: 'Kas Teller',
                roles: ['teller'],
                childs: [
                    {
                        key: '/teller/kas-teller/ambil-kas',
                        type: 1,
                        iconType: 'css',
                        name: 'Permintaan Kas',
                        openBranch: true,
                        roles: ['teller', 'oprasional IT'],
                    },
                    {
                        key: '/teller/kas-teller/setor-kas',
                        type: 1,
                        iconType: 'css',
                        name: 'Penyerahan Kas',
                        openBranch: true,
                        roles: ['teller', 'oprasional IT'],
                    },
                    {
                        key: '/teller/kas-teller/ambil-kas-lain',
                        type: 1,
                        iconType: 'css',
                        openBranch: true,
                        name: 'Permintaan Kas Teller lain',
                        roles: ['teller', 'oprasional IT'],
                    },
                    {
                        key: '/antarcabang',
                        type: 1,
                        iconType: 'css',
                        // icon: 'fa fa-wallet',
                        name: 'Persetujuan Pindah Kas',
                        openBranch: true,
                        component: pages('OtorTeller'),
                        roles: [
                            'teller'],
                    },
                    {
                        key: '/teller/kas-teller/tutup-kas',
                        type: 1,
                        iconType: 'css',
                        name: 'Tutup Kas Teller',
                        openBranch: true,
                        roles: ['teller', 'oprasional IT'],
                    },
                    {
                        key: '/teller/kas-teller/kas_atm',
                        type: 1,
                        iconType: 'css',
                        name: 'Transaksi ATM',
                        openBranch: true,
                        roles: ['teller', 'oprasional IT'],
                    },
                    // {
                    //     key: '/teller/kas-teller/selisih-kas',
                    //     type: 1,
                    //     iconType: 'css',
                    //     name: 'Selisih Kas Teller',
                    //     roles: ['teller', 'oprasional IT'],
                    // }, 
                    {
                        key: '/teller/kas-teller/kas_atm/:mode?',
                        type: 2,
                        iconType: 'css',
                        name: 'Kas In / Out ATM',
                        openBranch: true,
                        component: pages('KasATM'),
                        roles: ['teller', 'oprasional IT','spv teller'],
                    },
                    {
                        key: '/teller/kas-teller/setor-kas/:mode?',
                        type: 2,
                        iconType: 'css',
                        name: 'Setor Kas',
                        component: pages('NewSetorKas'),
                        openBranch: true,
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },
                    {
                        key: '/teller/kas-teller/ambil-kas/:mode?',
                        type: 2,
                        iconType: 'css',
                        name: 'Ambil Kas',
                        openBranch: true,
                        component: pages('NewAmbilKas'),
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },
                    {
                        key: '/teller/kas-teller/tutup-kas/:mode?',
                        type: 2,
                        iconType: 'css',
                        name: 'Tutup Kas',
                        openBranch: true,
                        component: pages('NewTutupKas'),
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],

                    },
                    {
                        key: '/teller/kas-teller/ambil-kas-lain/:mode?',
                        type: 2,
                        iconType: 'css',
                        name: 'Ambil Kas',
                        openBranch: true,
                        component: pages('NewAmbilKasLain'),
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },
                    {
                        key: '/teller/kas-teller/selisih-kas/:mode?',
                        type: 2,
                        iconType: 'css',
                        openBranch: true,
                        name: 'Selisih Kas',
                        component: pages('SelisihKas'),
                        roles: ['teller', 'spv teller', 'oprasional IT',
                            'MANAGER OPERASIONAL'],
                    },

                ],
            },
            {
                key: '/teller/reversal-transaksi',
                type: 1,
                icon: 'fa fa-user',
                iconType: 'css',
                name: 'Reversal Transaksi',
                openBranch: true,
                component: pages('ReversalTransaksiPage', 'oprasional IT'),
                roles: ['teller', 'back office'],
            },
            {
                key: '/teller/tutup-rekening',
                icon: 'fa fa-user',
                type: 1,
                iconType: 'css',
                name: 'Transaksi Tutup Rekening',
                openBranch: true,
                roles: ['teller', 'oprasional IT'],
                auth: true,
            },
            {
                key: '/teller/tutup-rekening/:mode?',
                openBranch: true,
                icon: 'fa fa-user',
                type: 2,
                iconType: 'css',
                name: 'Tutup Rekening',
                component: pages('TutupRekening'),
                roles: ['teller', 'oprasional IT', 'spv teller',
                    'MANAGER OPERASIONAL'],
                auth: true,
            },
            {
                key: '/teller/cetak-denominasi',
                icon: 'fa fa-user',
                type: 1,
                iconType: 'css',
                name: 'Cetak Denominasi',
                component: pages('CetakDenom'),
                roles: ['teller', 'oprasional IT'],
                auth: true,
            },
            {
                key: '/informasi-transaksi',
                icon: 'fa fa-user',
                type: 1,
                iconType: 'css',
                name: 'Informasi Transaksi',
                roles: ['teller', 'oprasional IT', 'spv teller',
                    'MANAGER OPERASIONAL'],
                childs: [
                    {
                        key: '/informasi-transaksi/informasi-kas-teller',
                        type: 1,
                        iconType: 'css',
                        name: 'Informasi Kas Teller',
                        component: pages('InformasiKasTeller'),
                        roles: ['teller', 'oprasional IT'],
                    },

                    {
                        key: '/informasi-transaksi/informasi-atm',
                        type: 1,
                        iconType: 'css',
                        name: 'Informasi Kas ATM',
                        roles: ['teller', 'oprasional IT'],
                        auth: true,
                        component: pages("IAtmPage")
                    },
                    {
                        key: '/informasi-transaksi/informasi-transaksi-hari-ini',
                        type: 1,
                        iconType: 'css',
                        name: 'Informasi Transaksi Hari Ini',
                        component: pages('TrxToday'),
                        roles: ['teller', 'tellersupervisor', 'oprasional IT', 'spv teller',
                            'MANAGER OPERASIONAL'],
                    },
                    {
                        key: '/informasi-transaksi/informasi-transaksi-histori',
                        type: 1,
                        iconType: 'css',
                        name: 'Informasi Transaksi Histori',
                        component: pages('TrxHistori'),
                        roles: ['teller', 'tellersupervisor', 'oprasional IT',
                            // 'spv teller',
                        ],
                    },
                ],
            },
        ],
    },

    {
        key: '/laporan',
        icon: 'fas fa-file-alt',
        type: 1,
        iconType: 'css',
        auth: true,
        name: 'Laporan',
        roles: [
            'cs',
            'cssupervisor',
            'spv cs',
            'back office',
            'spv treasury ops',
            'dept head operation',
            'group head operation',
            'direktur dan presdir',
            'spv teller',
            'teller',
            'MANAGER OPERASIONAL', 
            ""
        ],
        childs: [
            {
                key: '/laporan/list',
                type: 1,
                iconType: 'css',
                name: 'List Laporan',
                component: pages('Laporan'),
                roles: ['cs', 'spv cs', 'spv teller', 'teller',
                    'MANAGER OPERASIONAL'],
                // paramUrl: { mode: "registration" }
            },
            {
                key: '/laporan/hist-rek-koran',
                type: 1,
                iconType: 'css',
                name: 'History Rekening Koran',
                roles: [
                    'cs', 
                    "teller",
                    // 'spv cs', 'spv teller',
                    'MANAGER OPERASIONAL', 
                    ""
                ],
            },
            {
                key: '/laporan/hist-rek-koran/:mode?',
                type: 2,
                iconType: 'css',
                name: 'History Rekening Koran',
                component: pages('HistTrxKoran'),
                roles: [
                    'cs', 
                    "teller",
                    'MANAGER OPERASIONAL', 'spv cs', 'spv teller',""
                ],
            }
        ]
    },
    {
        key: '/verifikasi/tutupcabang',
        // icon: 'fas fa-file-alt',
        type: 0,
        // iconType: 'css',
        auth: true,
        name: '',
        roles: [
            'cssupervisor',
            'spv cs',
            'back office',
            'spv treasury ops',
            'dept head operation',
            'group head operation',
            'direktur dan presdir',
            'spv teller',
            'MANAGER OPERASIONAL',
            'spv teller',
            'spv cs',
            
            // 'MANAGER OPERASIONAL'
        ],
        childs: [
            {
                key: '/verifikasi/tutupcabang/informasi-transaksi-verifikasi',
                type: 1,
                iconType: 'css',
                icon: 'fa fa-wallet',
                name: 'Verifikasi dan Tutup Cabang',
                component: pages('TrxVerifikasi'),
                roles: [
                    'cssupervisor', 
                    'tellersupervisor', 
                    'oprasional IT',
                    'MANAGER OPERASIONAL',
                    'spv teller',
                    'spv cs',
                ],
            },
        ]
    },
    // {
    //     key: '/otorteller',
    //     // icon: 'fas fa-file-alt',
    //     type: 0,
    //     // iconType: 'css',
    //     auth: true,
    //     name: '',
    //     roles: [
    //         'cssupervisor',
    //         'spv cs',
    //         // 'back office',
    //         // 'spv treasury ops',
    //         // 'dept head operation',
    //         // 'group head operation',
    //         // 'direktur dan presdir',
    //         'spv teller',
    //         'cs',
    //         'teller',
    //     ],
    //     childs: [
    //         {
    //             key: '/antarcabang',
    //             type: 1,
    //             iconType: 'css',
    //             icon: 'fa fa-wallet',
    //             name: 'Persetujuan Pindah Kas',
    //             component: pages('OtorTeller'),
    //             roles: [
    //             'teller'],
    //         },
    //     ]
    // },
    //{
    // {
    //     key: '/auditrail',
    //     icon: 'fa fa-table',
    //     type: 1,
    //     iconType: 'css',
    //     name: 'Auditrail',
    //     component: pages('AuditrailPage'),
    //     auth: true,
    //     roles: ['cssupervisor'],
    // },
    // tellerspv
    {
        key: 'tellersupervisor',
        type: 0,
        name: '',
        auth: true,
        roles: [
            'tellersupervisor',
            'spv teller',
            'spv human capital',
            'spv finance and accounting',
            'spv treasury ops',
            'dept head operation',
            'group head operation',
            'direktur dan presdir',
            'oprasional IT',
            'MANAGER OPERASIONAL'
        ],
        childs: [
            // {
            //     key: '/informasi-transaksi-supervisor',
            //     icon: 'fa fa-user',
            //     type: 1,
            //     iconType: 'css',
            //     name: 'Otorisasi Transaksi',
            //     roles: [
            //         'tellersupervisor',
            //         'spv teller',
            //         'spv human capital',
            //         'spv finance and accounting',
            //         'spv treasury ops',
            //         'dept head operation',
            //         'group head operation',
            //         'direktur dan presdir',
            //     ],
            //     childs: [
            //         {
            //             key: '/informasi-transaksi/otorisasi-transaksi',
            //             type: 1,
            //             iconType: 'css',
            //             name: 'Otorisasi Transaksi',
            //             component: pages('OtorisasiTransaksiPage'),
            //             roles: [
            //                 'tellersupervisor',
            //                 'spv teller',
            //                 'spv human capital',
            //                 'spv finance and accounting',
            //                 'spv treasury ops',
            //                 'dept head operation',
            //                 'group head operation',
            //                 'direktur dan presdir',
            //             ],
            //         },
            //         {
            //             key: '/informasi-transaksi/riwayat-otorisasi-transasksi',
            //             type: 1,
            //             iconType: 'css',
            //             name: 'Riwayat Otorisasi Transaksi',
            //             component: pages('RiwayatOtorisasiTransaksi'),
            //             roles: ['spv teller'],
            //         },
            //         {
            //             key: '/informasi-transaksi/reversal-transaksi',
            //             type: 1,
            //             iconType: 'css',
            //             name: 'Reversal Transaksi',
            //             component: pages('OtorisasiReversalTransaksiPage'),
            //             roles: ['spv teller'],
            //         },
            //     ],
            // },
            // {
            //     key: '/informasi-teller',
            //     icon: 'fa fa-user',
            //     type: 1,
            //     iconType: 'css',
            //     name: 'Otorisasi Teller',
            //     component: pages('InformasiTellerPage'),
            //     roles: ['spv teller'],
            //     childs: [
            //         {
            //             key: '/informasi-teller/informasi-kas-teller',
            //             type: 2,
            //             iconType: 'css',
            //             component: pages('DetailTellerPage'),
            //             roles: ['spv teller'],
            //         },
            //     ],
            // },
            // {
            //     key: '/peragaan-berankas',
            //     icon: 'fa fa-user',
            //     type: 1,
            //     iconType: 'css',
            //     name: 'Peragaan Berankas',
            //     component: pages('PeragaanBrankasPage'),
            //     roles: ['spv teller'],
            // },
            {
                key: '/supervisor-teller/informasi-brankas',
                icon: 'fa fa-user',
                type: 1,
                iconType: 'css',
                name: 'Informasi Brankas',
                component: pages('InformasiBerankas'),
                roles: ['spv teller', 'oprasional IT',
                    'MANAGER OPERASIONAL'],
            },
            {
                key: '/supervisor-teller/informasi-teller',
                icon: 'fa fa-user',
                type: 1,
                iconType: 'css',
                name: 'Informasi Teller',
                component: pages('InformasiTeller'),
                roles: ['spv teller', 'oprasional IT',
                    'MANAGER OPERASIONAL'],
            },
            {
                key: '/supervisor-teller/informasi-teller/:user_id/:kode_valuta/:kode_cabang',
                icon: 'fa fa-user',
                type: 2,
                iconType: 'css',
                name: 'informasi Kas Teller',
                component: pages('InformasiDetail'),
                roles: ['spv teller', 'oprasional IT',
                    'MANAGER OPERASIONAL'],
            },

            // {
            //     key: '/sup-tabungan-giro-penutupan',
            //     icon: 'fa fa-user',
            //     type: 1,
            //     iconType: 'css',
            //     name: 'Otorisasi Penutupan',
            //     roles: [
            //         'tellersupervisor',
            //         'spv teller',
            //         'spv treasury',
            //         'dept head operation',
            //         'group head operation',
            //         'direktur dan presdir',
            //     ],
            //     childs: [
            //         {
            //             key: '/sup-tabungan-giro/penutupan-rekening',
            //             type: 1,
            //             iconType: 'css',
            //             name: 'Penutupan Rekening',
            //             component: pages('PenutupanRekeningPage'),
            //             roles: [
            //                 'tellersupervisor',
            //                 'spv teller',
            //                 'spv treasury',
            //                 'dept head operation',
            //                 'group head operation',
            //                 'direktur dan presdir',
            //             ],
            //         },
            //     ],
            // },
        ],
    },

    {
        key: '/informasi-atm',
        icon: 'fa fa-wallet',
        type: 1,
        iconType: 'css',
        name: 'Informasi ATM',
        roles: ['spv cs', 'spv teller', 'oprasional IT',
            'MANAGER OPERASIONAL'],
        auth: true,
        component: pages("IAtmPage")
    },
    {
        key: 'queryall',
        type: 0,
        name: '',
        auth: true,
        roles: [
            'cs',
            'spv cs',
            'teller',
            'spv teller',
            'back office',
            'spv treasury',
            'dept head operation',
            'group head operation',
            'direktur dan presdir',
            'human capital',
            'spv human capital',
            'treasury ops',
            'spv treasury ops',
            'oprasional IT',
            'MANAGER OPERASIONAL'
        ],
        // childs: [
        //     {
        //         key: '/query',
        //         icon: 'fa fa-wallet',
        //         type: 1,
        //         iconType: 'css',
        //         name: 'Query',
        //         childs: [
        //             {
        //                 key: '/query/gl',
        //                 type: 1,
        //                 iconType: 'css',
        //                 name: 'Query GL',
        //                 component: pages('QueryGLPage'),
        //             },
        //             {
        //                 key: '/query/rekening',
        //                 type: 1,
        //                 iconType: 'css',
        //                 name: 'Query Rekening',
        //                 component: pages('QueryRekeningPage'),
        //                 roles: [
        //                     'cs',
        //                     'spv cs',
        //                     'teller',
        //                     'spv teller',
        //                     'back office',
        //                     'spv treasury',
        //                     'dept head operation',
        //                     'group head operation',
        //                     'direktur dan presdir',
        //                     'spv treasury ops',
        //                 ],
        //             },
        //         ],
        //     },
        // ],
    },

    {
        key: '/otorisasi/cs',
        icon: 'fa fa-wallet',
        type: 1,
        iconType: 'css',
        name: 'Otorisasi Data Entry',
        roles: ['spv cs', 'spv teller', 'oprasional IT',
            'MANAGER OPERASIONAL'],
        auth: true,
    },
    {
        key: '/otorisasi/teller',
        icon: 'fa fa-wallet',
        type: 1,
        iconType: 'css',
        name: 'Otorisasi Teller',
        roles: ['spv teller', 'spv cs', 'oprasional IT',
            'MANAGER OPERASIONAL'],
        auth: true,
    },
    {
        key: '/otorisasi/:role',
        icon: 'fa fa-wallet',
        type: 2,
        iconType: 'css',
        name: 'Otorisasi Data Entry',
        component: pages('OtorisasiDataEnter'),
        roles: ['cssupervisor', 'spv cs', 'spv teller', 'oprasional IT',
            'MANAGER OPERASIONAL'],
        auth: true,
    }
];

// tidak dipakai, tapi bisi butuh, jadi tidak di hapus
const routes_old = [
    {
        path: '/login',
        component: pages('Login'),
        auth: false,
    },
    {
        path: '/',
        component: pages('DasboardPage'),
        auth: true,
    },
    {
        path: '/backsheet',
        component: pages('Backsheet'),
        auth: true,
    },
    {
        path: '/posPay',
        component: pages('menuPayPage'),
        auth: true,
    },
    {
        path: '/posPay/Listrik',
        component: pages('ListrikPage'),
        auth: true,
    },
    {
        path: '/posPay/pdam',
        component: pages('PdamPage'),
        auth: true,
    },
    {
        path: '/posPay/telco',
        component: pages('TelcoPage'),
        auth: true,
    },
    {
        path: '/posPay/tv-cable',
        component: pages('Tvkabel'),
        auth: true,
    },
    {
        path: '/posPay/penerima-daerah',
        component: pages('PenerimaanDaerah'),
        auth: true,
    },
    {
        path: '/posPay/deposit',
        component: pages('DepositPage'),
        auth: true,
    },
    {
        path: '/posPay/asuransi',
        component: pages('AsuransiPage'),
        auth: true,
    },
    {
        path: '/posPay/gas',
        component: pages('GasPay'),
        auth: true,
    },
    {
        path: '/posPay/olshop',
        component: pages('OnlineShopPage'),
        auth: true,
    },
    {
        path: '/posPay/mfinance',
        component: pages('multiFinancetPage'),
        auth: true,
    },
    {
        path: '/posPay/tiketing',
        component: pages('tiketPage'),
        auth: true,
    },
    {
        path: '/posPay/zakat',
        component: pages('ZakatPage'),
        auth: true,
    },
    {
        path: '/iPos',
        component: pages('menuIposPage'),
        auth: true,
    },
    {
        path: '/iPos/kiriman-pos',
        component: pages('KirimanPos'),
        auth: true,
    },
    {
        path: '/wesel',
        component: pages('menuWeselPage'),
        auth: true,
    },
    {
        path: '/wesel/penerimaan',
        component: pages('Penerimaan'),
        auth: true,
    },
    {
        path: '/wesel/pembayaran',
        component: pages('Pembayaran'),
        auth: true,
    },
    {
        path: '/loket-kiriman',
        component: pages('RitelLoketPage'),
        auth: true,
    },
    {
        path: '/home',
        component: pages('Home'),
        auth: true,
    },
];
