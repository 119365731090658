import React from "react";
import { createForm } from "../../../../../../module/createFormList";
import { Modal } from "react-bootstrap";
import ModalConfirmCustom from "../../../../../controls/modalAlert/modalConfirmCustomChild";
import useDataBuku from "../useDataBuku";
import { FormRegistration } from "../../../../../controls";

const ModalDetilTabungan = () => {
    const { nfAction, nfState } = useDataBuku()
    return(
    <>
        <Modal size="lg" show={nfState.modalDetil.show}  backdrop="static" onHide= { nfAction.closeModalDetil }>
            <Modal.Header closeButton>
            {
                !nfState.formEditable &&
                <Modal.Title>
                    Detil
                </Modal.Title>
            }
                {
                            nfState.formEditable &&
                            <Modal.Title>
                                Ubah Data Registrasi Buku Rekening
                            </Modal.Title>
                }
            </Modal.Header>
            <Modal.Body>
                <FormRegistration 
                    itemsForm={[
                        {
                            name:"Data Registrasi Buku Rekening",
                            rows: [
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col">
                                                {
                                                    createForm({
                                                        label   : 'No Rekening',
                                                        type    : 'number',
                                                        required: true,
                                                        value   : nfState.formData?.nomor_rekening,
                                                        onChange:(value) => nfAction.changeForm(value,'nomor_rekening'),
                                                        disabled: !nfState.formEditable,
                                                    })  
                                                }
                                            </div>
                                            <div className="col">
                                                {
                                                    createForm({
                                                        label   : 'Nama Rekening',
                                                        type    : 'text',
                                                        required: true,
                                                        value   : nfState.formData?.nama_rekening,
                                                        onChange:(value) => nfAction.changeForm(value,'nama_nasabah'),
                                                        disabled: !nfState.formEditable,
                                                        readOnly:true
                                                    })  
                                                }
                                            </div>
                                        </div>
                                    )
                                },
                                {
                                    rows    : 1,
                                    label   : 'Cabang Rekening',
                                    type    : 'text',
                                    required: true,
                                    value   : nfState.formData?.kode_cabang,
                                    onChange:(value) => nfAction.changeForm(value,'cabang_rekening'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                                {
                                    rows    : 1,
                                    label   : 'Register Buku Lama',
                                    type    : 'text',
                                    required: true,
                                    value   : nfState.formData?.nomorregisterbuku_aktif,
                                    onChange:(value) => nfAction.changeForm(value,'register_buku_lama'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                                {
                                    rows    : 1,
                                    label   : 'Register',
                                    type    : 'text',
                                    required: true,
                                    value   : nfState.formData?.regis,
                                    onChange:(value) => nfAction.changeForm(value,'regis'),
                                    disabled: !nfState.formEditable,
                                },
                                {
                                    rows    : 2,
                                    label   : 'Buku Ke',
                                    type    : 'number',
                                    required: true,
                                    value   : nfState.formData?.nomor_buku_terakhir,
                                    onChange:(value) => nfAction.changeForm(value,'buku_ke'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                                {
                                    rows    : 2,
                                    label   : 'Tanggal',
                                    type    : 'date',
                                    required: true,
                                    value   : nfState.formData?.tgl_buku_rekening,
                                    onChange:(value) => nfAction.changeForm(value,'tgl_buku_rekening'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                                {
                                    rows    : 2,
                                    label   : 'Keterangan',
                                    type    : 'select',
                                    required: true,
                                    value   : nfState.formData?.keterangan,
                                    onChange:(value) => nfAction.changeForm(value,'keterangan'),
                                    disabled: !nfState.formEditable,
                                    options : [
                                                {
                                                    key: "1",
                                                    display: "1 - BARU"
                                                },{
                                                    key: "2",
                                                    display: "2 - HABIS BUKU"
                                                },{
                                                    key: "3",
                                                    display: "3 - RUSAK"
                                                },{
                                                    key: "4",
                                                    display: "4 - HILANG"
                                                },{
                                                    key: "5",
                                                    display: "5 - LAIN-LAIN"
                                                }
                                            ]
                                },
                                {
                                    rows    : 2,
                                    label   : 'Cabang User',
                                    type    : 'text',
                                    required: true,
                                    value   : nfState.formData?.nama_cabang,
                                    onChange:(value) => nfAction.changeForm(value,'nama_cabang'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                            ]
                        }
                    ]}
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col">
                        <button onClick={nfAction.closeModalDetil } className="btn btn-outline-secondary float-right ml-2">
                            Close
                        </button>
                        {
                            nfState.formEditable &&
                            <button onClick={nfAction.confirmUbah } className="btn btn-outline-success float-right ml-2">
                                Save
                            </button>
                        }
                        {
                            nfState.otorMode ? (
                                    <>
                                        <button  className="btn btn-primary float-right ml-2" onClick={()=>nfAction.responseOtor("APPROVED")}>
                                            Approve
                                        </button>
                                        <button className="btn btn-danger float-right ml-2" onClick={()=>nfAction.responseOtor("REJECTED")}>
                                            Reject
                                        </button>
                                    </>
                            ) : (
                                <></>
                            )
                            
                        }
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
        <ModalConfirmCustom id="otorisasi">
            <textarea className="form-control"
                onChange={(e) => nfAction.setOtorDesc(e.target.value)}
                value={nfState.otorDesc}
            />
        </ModalConfirmCustom>
    </>
    )
}
function FormCreated({ children, ...props }) {

    let option = props;
    if (props.type === "custom") option = { ...props, component: children }
    return <>{
        createForm({ ...option })
    }</>
}


export default ModalDetilTabungan;