import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ModalSvs = ({ nomor_nasabah = null, showModal = false, onHide = () => null }) => {
    const { doGet, wait } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        openLoading,
        closeConfirmCustom,
        openAlertCustom
    } = modalAction;


    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(function () {
        setShow(showModal)
        if (nomor_nasabah !== null && showModal) getSvsList()
    }, [showModal])


    async function getSvsList() {
        setLoading(true)
        var url = `/nasabahget-svs-by-no-cif/${nomor_nasabah}`;
        try {
            const {
                statusCode,
                status,
                remark,
                statusText,
                data = {},
            } = await doGet({
                url: url,
                service: "cif",
            });

            if (statusCode === 200) {
                if (status === "00") {
                    // console.log(data)
                    let list = []
                        data.forEach(element => {
                            list.push({
                            nomor_cif: element.nomor_nasabah,
                            signed_url: element.url_path,
                            remark: element.keterangan,
                            image_tag: element.image_tag,
                            index: element.id_nasabahimage,
                            tgl_input: element.tgl_input,
                            tgl_auth: element.tgl_auth,
                            })
                        });
                    setData(list);
                } //else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        await wait(3000);
        setLoading(false);
    }

    return (
        <Modal show={show} size="xl" onHide={() => {
            onHide()
            setShow(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Data Signature ({ nomor_nasabah})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {
                        (loading ? [1, 2, 3, 4] : data).map(({
                            image_tag = "",
                            signed_url = null,
                            remark = ""
                        }, key) =>
                            <div key={key} className="col-lg-6 mb-3"
                            // key={index}
                            >
                                <div className="row px-3">
                                    {loading ? <Skeleton height={30} width="50%" count={1} /> :<h4 className="mt-2">Image {key + 1} ({image_tag})</h4>}
                                    <div className="col-12">
                                        <div className="row p-0 svs-box-new-images">
                                            <div className="col-md-12 pt-3 pb-3">
                                                {
                                                    loading ? <Skeleton height={150} count={1} /> : (signed_url ? (
                                                        <div
                                                            style={{
                                                            border: "1px solid rgb(0 0 0 / 11%)",
                                                            borderRadius: "5px",
                                                            backgroundColor: "#eeee",
                                                            overflow: "hidden",
                                                            }}>
                                                            <TransformWrapper>
                                                            <TransformComponent wrapperStyle={{ width:'100%',minHeight:'207px' }}>
                                                                <img
                                                                src={signed_url}
                                                                style={{
                                                                    width: "100%",
                                                                    aspectRatio: '1/1',
                                                                    objectFit: "contain",
                                                                }}
                                                                />
                                                            </TransformComponent>
                                                            </TransformWrapper>
                                                        </div>
                                                        // <img
                                                        //     style={{ width: "100%", height: "207px", objectFit: "contain", border: "none" }}
                                                        //     src={signed_url}
                                                        // />
                                                    ) : null)}
                                                <div className="mt-2 text-center pt-2 pb-2"
                                                    style={{
                                                        border: " 0.5pt solid #CED4DA",
                                                        borderRadius: "5px"
                                                    }}
                                                >
                                                   { loading ? <Skeleton height={30} width="50%" count={1} /> : <h3>{ remark}</h3>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        !loading && data.length <= 0 && <>
                            <div className="row">
                                <div className="col-12 text-center p-3">
                                    <h4 className="text-center">
                                    Nasabah belum memiliki data grafis tanda tangan
                                    </h4>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col">
                        <button
                            onClick={() => {
                                onHide()
                                setShow(false)
                            }}
                            className="btn btn-outline-secondary btn-sm">
                            Close
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalSvs;