import React, { memo, useRef } from 'react';
import {TableList } from '../../../controls';

class TarikanKliring extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  render() {
      return(
        <>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mt-2 mb-3">
              <li className="breadcrumb-item color-primary">Kliring</li>
              <li className="breadcrumb-item"><a href="#">Tarikan Kliring</a></li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-white">
                  <h5 className="card-title">TARIKAN KLIRING</h5>
                </div>
                <div className="card-body">
                  <div className="bns-form">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-row">
                          <div className="form-group col-7">
                            <label htmlFor="">No. Rekening</label>
                            <input type="text" className="form-control"/>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-3">
                            <label htmlFor="">Jenis Warkat</label>
                            <select className="form-control">
                              <option value="">Cek</option>
                            </select>
                          </div>
                          <div className="form-group col-4">
                            <label htmlFor="">No. Warkat</label>
                            <input type="text" className="form-control"/>
                          </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Nama Lengkap</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                        <div className="form-row">
                          <div className="col-5">
                            <div className="form-group">
                              <label htmlFor="">Valuta Rekening</label>
                              <div className="form-row">
                                <div className="col-3 pr-0">
                                  <input type="text" className="form-control" value="IDR" readOnly/>
                                </div>
                                <div className="col-9 pl-0">
                                  <input type="text" className="form-control" value="INDONESIAN RUPIAH" readOnly/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-1"></div>
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="">Cabang Rekening</label>
                              <div className="form-row">
                                <div className="col-3 pr-0">
                                  <input type="text" className="form-control" value="000" readOnly/>
                                </div>
                                <div className="col-9 pl-0">
                                  <input type="text" className="form-control" value="KCP KOTA BANDUNG" readOnly/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-5">
                            <div className="form-group">
                              <label htmlFor="">Nilai Transaksi (Rp)</label>
                              <input type="text" className="form-control"/>
                            </div>
                          </div>
                          <div className="col-1"></div>
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="">Biaya (Rp)</label>
                              <input type="text" className="form-control"/>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-4">
                            <div className="form-group">
                              <label htmlFor="">Sumber Biaya</label>
                              <select className="form-control">
                                <option value="">Tunai</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="form-group">
                              <label htmlFor="">Keterangan</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer bg-white">
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 text-right">
                      <button className="btn btn-outline-secondary">Batal</button>
                      <button className="btn btn-primary ml-3 px-5">Proses</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
  }
}

export default TarikanKliring;