import React from 'react';
import useBifast, { BiProvider } from './useBifast';
import moment from 'moment';
import DataPengirim from './dataPengirim';
import ModalDataNasabah from './modal/modalDataNasabah';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import { useParams } from 'react-router';
import { createForm } from '../../../../../module/createFormList';
import useDropdowns from '../../../../../hooks/useDropdowns';

function BifastPage() {
    const { eJenisPendudukTransfer, eJenisAplikasi, eSumberBiaya } = useDropdowns(["eJenisPendudukTransfer", "eJenisAplikasi", "eSumberBiaya"])
    const { biAction, biState } = useBifast()
    const { mode } = useParams()
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-2 mb-3">
                    <li className="breadcrumb-item color-primary">Transaksi Nasabah</li>
                    <li className="breadcrumb-item">
                        <a href="#">Transfer BI FAST</a>
                    </li>
                </ol>
            </nav>

            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        TRANSFER BI FAST
                    </div>
                </div>
                <div className='card-body'>
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="form-group col-6">
                                    <label> Tanggal Efektif</label>
                                    <input
                                        disabled
                                        type="text"
                                        value={moment(Date.now()).format("DD MMMM YYYY")}
                                        className="form-control form-contol-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" >


                        {/* parents col 6 1 */}
                        <div className="col-md-6" >
                            <h4 className="text-base">Tujuan Transfer</h4>
                            <div className="row">
                                <div className="col-12" style={{ padding: "0px 15px" }}>
                                    {
                                        createForm({
                                            type: "select",
                                            label: "Kode Bank",
                                            placeholder: "Pilih Bank",
                                            options: biState.dataBank?.map(v => ({ value: v.kode_member, label: `${v.kode_member} - ${v.nama_member}` })),
                                            key: "value",
                                            display: "label",
                                            value: biState.infoTrx?.kode_bank_kliring,
                                            disabled: !biState.formEditable,
                                            onChange: (d) => {
                                                var kodeBank = d;
                                                var namaBank = biState.dataBank.find(({ kode_member }) => kode_member === d)?.nama_member;
                                                biAction.setInfoTrx(infoT => ({
                                                    ...infoT,
                                                    kode_bank_kliring: kodeBank,
                                                    nama_bank_kliring: namaBank
                                                }))

                                            },
                                            required: true

                                        })
                                    }
                                </div>
                            </div>
                            {/* <div className='row mb-3 mt-2'>
                                <div className='col-4 ml-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" name='rek_proxy' type="radio" 
                                            disabled={!biState.formEditable}
                                
                                        />
                                        <label className="form-check-label" for="exampleRadios1">
                                            Nomor Rekening
                                        </label>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-check">
                                        <input className="form-check-input" name='rek_proxy' type="radio" 
                                            disabled={!biState.formEditable}
                                        
                                        />
                                        <label className="form-check-label" for="exampleRadios1">
                                            Proxy
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-6">
                                    {
                                        createForm({
                                            type: "number",
                                            label: "No. Rekening Penerima",
                                            disabled: !biState.formEditable,
                                            placeholder: "No Rek",
                                            value: biState?.formData?.nomor_rekening,
                                            onChange: (e) => {
                                                biAction.changeForm(e, "nomor_rekening")
                                                biAction.setNoRek(e)
                                            },
                                            required: true
                                            // onEnter: (e) => biState.cekNasabah(e)
                                        })
                                    }
                                </div>
                                <div className="col-6">
                                    {
                                        createForm({
                                            type: "text",
                                            label: <>&nbsp;</>,
                                            disabled: true,
                                            placeholder: "Nama Penerima",
                                            value: biState?.infoTrx?.nama_rekening_penerima,
                                            onChange: (e) => biAction.setInfoTrx(p => ({ ...p, nama_rekening_penerima: e.toUpperCase() })),
                                            required: true
                                        })
                                    }
                                </div>
                                {/* <div className='col-3' >
                                    {
                                        createForm({
                                            type: "custom",
                                            component: (
                                                <div className="input-group-append pt-4">
                                                    <button 
                                                    onClick={() => biAction.cekNoRekening()}
                                                    disabled={mode === "otorisasi"} 
                                                    className="btn btn-outline-success">
                                                        Cek Data Rekening
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div> */}
                            </div>


                            {/* <div className="row">
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "Atas Permintaan",
                                            disabled: !biState.formEditable,
                                            value: biState?.infoTrx?.ats_permintaan,
                                            onChange: (ats_permintaan) => biAction.setInfoTrx(p => ({ ...p, ats_permintaan: ats_permintaan.toUpperCase() }))
                                        })
                                    }
                                </div>
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "select",
                                            label: "Jenis Penduduk",
                                            placeholder: "Pilih jenis penduduk",
                                            options: eJenisPendudukTransfer,
                                            key: "reference_code",
                                            display: "reference_desc",
                                            disabled: !biState.formEditable,
                                            value: biState?.infoTrx?.jenis_penduduk,
                                            onChange: (jenis_penduduk) => biAction.setInfoTrx(p => ({ ...p, jenis_penduduk }))
                                        })
                                    }
                                </div>
                            </div> */}


                            {/* <div className="row">
                                <div className=" col-12">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "No. Referensi",
                                            disabled: !biState.formEditable || true,
                                            placeholder: "No Rek",
                                            value: biState?.formData?.nomor_referensi,
                                            onChange: (nomor_referensi) => biAction.changeForm(nomor_referensi, "nomor_referensi")
                                        })
                                    }
                                </div>
                            </div> */}


                            <div className="row">
                                <div className=" col-12">
                                    {
                                        createForm({
                                            type: "currency",
                                            label: "Nilai Transaksi",
                                            disabled: !biState.formEditable,
                                            value: biState?.formData?.nominal,
                                            onChange: (nominal) => biAction.changeForm(nominal, "nominal"),
                                            onFocusOut:(nominal)=> biAction.checkTransaksi(nominal, biAction.changeForm("", "nominal")),
                                            onEnter:(nominal)=> biAction.checkTransaksi(nominal, biAction.changeForm("", "nominal")),
                                            required: true
                                        })
                                    }
                                </div>
                                {/* <div className=" col-6">
                                    {
                                        createForm({
                                            type: "select",
                                            label: "Sumber Dana",
                                            placeholder: "Pilih sumber dana",
                                            options: eSumberBiaya,
                                            key: "reference_code",
                                            display: "reference_desc",
                                            disabled: true,
                                            value: biState?.infoTrx?.sumber_biaya,
                                            onChange: (sumber_biaya) => biAction.setInfoTrx(p => ({ ...p, sumber_biaya }))
                                        })
                                    }
                                </div> */}
                            </div>


                            <div className="row">
                                <div className=" col-12">
                                    {
                                        createForm({
                                            className: "input-bg-grey",
                                            type: "currency",
                                            label: "Biaya",
                                            disabled: !biState.formEditable,
                                            value: biState?.infoTrx?.biaya,
                                            onChange: (biaya) => biAction.setInfoTrx(p => ({ ...p, biaya }))
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" style={{ padding: "0px 15px" }}>
                                    {
                                        createForm({
                                            label: 'Tujuan Transaksi',
                                            type: "select",
                                            placeholder: "Pilih Tujuan Transaksi",
                                            options: (biState.dataDropDown?.BIF_PURPOSE_CODE || []).map(({reference_code, reference_desc}) => ({
                                                reference_code,
                                                reference_desc : `${reference_code} - ${reference_desc}`
                                            })),
                                            key: "reference_code",
                                            display: "reference_desc",
                                            value: biState.infoTrx?.tujuan_transaksi,
                                            disabled: !biState.formEditable,
                                            onChange: (value) => {
                                                biAction.setInfoTrx(infoT => ({
                                                    ...infoT,
                                                    tujuan_transaksi: value,
                                                }))

                                            },
                                            required: true

                                        })
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className=" col">
                                    {
                                        createForm({
                                            type: "textarea",
                                            label: "Keterangan",
                                            maxLength: 100,
                                            disabled: !biState.formEditable,
                                            value: biState?.formData?.keterangan,
                                            onChange: (keterangan) => biAction.changeForm(keterangan, "keterangan")

                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6" >
                            <DataPengirim />
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    {
                        mode === "otorisasi" && (
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        onClick={() => biAction.responseOtor("APPROVED")}
                                        className="float-right btn btn-success btn-sm ml-3">
                                        Approve
                                    </button>


                                    <button
                                        onClick={() => biAction.responseOtor("REJECTED")}
                                        className="float-right btn btn-danger btn-sm ml-3">
                                        Reject
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    {
                        biState.formEditable && <div className="row">
                            <div className="col-12">
                                <button onClick={biAction.confirmTrx} hidden={biState.isCekRek} className="float-right btn btn-success btn-sm ml-3">
                                    Process
                                </button>

                                <button
                                    className="float-right btn btn-success btn-sm ml-3"
                                    onClick={() => biAction.cekNoRekening()}
                                    hidden={!biState.isCekRek}
                                    disabled={mode === "otorisasi"}>
                                    Validasi Rekening 
                                </button>


                                <button onClick={biAction.resetForm} className="float-right btn btn-outline-secondary btn-sm ml-3">
                                    Reset 
                                </button>
                            </div>

                        </div>
                    }
                </div>
            </div>
            <ModalDataNasabah />
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => biAction.setOtorDesc(e.target.value)}
                    value={biState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )
}

export default () => <BiProvider><BifastPage /></BiProvider>