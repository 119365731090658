import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { dasboardData } from '../../../../../../appConfig';
import { addCommas } from '../../../../../../module';
import { createForm } from '../../../../../../module/createFormList';
import { TableList } from '../../../../../controls';
import useRekening from '../useRekening';


const ModalBlokirRekening = () => {
    const { rekState, rekAction } = useRekening();

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalBlokirRekening} onHide={rekAction.closeBlokRek} >
                <Modal.Header closeButton>
                    <Modal.Title>Blokir Rekening</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box mb-3">
                        <div className="row">
                            <div className="col">
                                <h4>{rekState.loadingBlok ? <Skeleton width={"50%"} height={30} /> : <>{rekState.dataBlokRekening?.nama_rekening}</>}</h4>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Produk </div>
                                            <div className="col-8">{rekState.loadingBlok ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataBlokRekening?.produk_rekening : rekState.dataBlokRekening?.nama_produk}`}</div>
                                        </div><div className="row">
                                            <div className="col-4"> No. Rek </div>
                                            <div className="col-8">{rekState.loadingBlok ? <Skeleton height={20} /> : `: ${rekState.dataBlokRekening?.nomor_rekening}`} </div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Cabang </div>
                                            <div className="col-8">{rekState.loadingBlok ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataBlokRekening?.cabang_rekening : rekState.dataBlokRekening?.cabang}`}</div>
                                        </div><div className="row">
                                            <div className="col-4">Kode Valuta </div>
                                            <div className="col-8">{rekState.loadingBlok ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataBlokRekening?.valuta_rekening : rekState.dataBlokRekening?.valuta}`}</div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Saldo Rek </div>
                                            <div className="col-8">
                                                {
                                                    rekState.loadingBlok ?
                                                        <Skeleton height={20} /> :
                                                        <> : <span className="float-right">{rekState.otorMode ? addCommas(parseFloat(rekState.dataBlokRekening?.saldo_rekening).toFixed(2)) : addCommas(parseFloat(rekState.dataBlokRekening?.saldo).toFixed(2))} </span></>
                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="row">
                    <div className="col">
                    {
                            createForm({
                            label:"Keterangan Blokir",
                            type:"text",
                            required: true,
                            placeholder: "Keterangan...",
                            value: rekState.otorMode ? rekState.dataBlokRekening?.alasan_blokir : rekState.formBlokRekening?.alasan_blokir,
                            disabled: rekState.otorMode,
                            onChange: (e) => rekAction.setformBlokRekening(prevState => ({ ...prevState, alasan_blokir: e }))
                        })
                    }
                    </div>
                    <div className="col">
                    {
                            createForm({
                            label:"Jenis Blokir",
                            type:"select",
                            required: true,
                            placeholder: "Masukan Jenis Blokir",
                            value:rekState.otorMode ? rekState.dataBlokRekening?.jenis_blokir : rekState.formBlokRekening?.jenis_blokir,
                            onChange: (e) => rekAction.setformBlokRekening(prevState => ({ ...prevState, jenis_blokir: e })),
                            disabled: rekState.otorMode,
                            options: [
                                        {
                                            key: "blokir_debet",
                                            display: "Blokir Debet"
                                        },{
                                            key: "blokir_credit",
                                            display: "Blokir Credit"
                                        },{
                                            key: "blokir_debet_credit",
                                            display: "Blokir Debet Credit"
                                        },
                            ]
                        })
                    }
                    </div>
                </div>
                <Modal.Footer>       
                {/* <div>                       
                    <button 
                        onClick={ () => rekState.blokRekModalMode ? rekAction.toggleModalMode() : rekAction.registerBlok()} 
                        className="btn btn-outline-info float-right ml-4">
                        Blokir Rekening
                    </button>
                    <button 
                        onClick={ () => rekState.blokRekModalMode ? rekAction.toggleModalMode() : rekAction.closeBlokRek()} 
                        className="btn btn-outline-success float-right">
                        Batal
                    </button>
                </div> */}
                <div className="row">
                        {
                            !rekState.otorMode ? 
                            <div className="col">
                                {   
                                <button
                                    // disabled={rekAction.}
                                    onClick={()=> rekAction.confirmRegisBlok()}
                                    className="btn btn-outline-info float-right ml-4">
                                    Blokir Rekening
                                </button>
                                }
                                <button
                                    onClick={() => rekState.blokRekModalMode ? rekAction.toggleModalMode() : rekAction.closeBlokRek()}
                                    className="btn btn-outline-success float-right">
                                    Batal
                                </button>
                            </div> : (
                                <div className="col">
                                    <button
                                        onClick={() => rekAction.closeBlokRek()}
                                        className="btn btn-outline-success float-right">
                                        Tutup
                                    </button>

                                    <button
                                        onClick={() => rekAction.responseOtor("APPROVED")}
                                        className="btn btn-success mr-2">
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => rekAction.responseOtor("REJECTED")}
                                        className="btn btn-danger mr-2">
                                        Reject
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal utama */}
        </>
    );
}

export default ModalBlokirRekening;