import React, { useRef } from 'react';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistration } from '../../../../../controls';
import useDeposito from '../useDeposito';
import Select from 'react-select';
import moment from 'moment';
import { addCommas, terbilang } from '../../../../../../module';

const CetakPrint = () => {
    const { rekState, rekAction } = useDeposito();
    const { rekSelected = {}, formEditable = false } = rekState;
    const refCetak = useRef(null)
    return (
        <>
            {/* <div className="box mb-3"> */}
            {/* <div className="row" ref={refCetak}> */}
            <div className="col">
                <h4>Cabang : <>{rekState.rekSelected?.nama_cabang}</></h4>
                <div className='row mb-5'>
                    <div className='col-12'>
                        <div className='row mb-2'>
                            <div className='col-2'>
                                Nomor Bilyet
                            </div>
                            <div className='col-2'>
                                Nomor Rekening
                            </div>
                            <div className='col-3'>
                                Nama Rekening
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>

                        <div className='row'>
                            <div className='col-2 mb-2'>
                                {rekState.rekSelected?.nomor_bilyet}
                            </div>
                            <div className='col-2'>
                                {rekState.rekSelected?.nomor_rekening}
                            </div>
                            <div className='col-3'>
                                {rekState.rekSelected?.nama_rekening}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='row mb-5'>
                    <div className='col-12'>
                        <div className='row mb-2'>
                            <div className='col-6'>
                                Nomor Rek. Jatuh Tempo
                            </div>
                            <div className='col-6'>
                                Nomor Rek. Pencairan
                            </div>
                            {/* <div className='col-3'>
                                Bagi Hasil
                            </div> */}
                        </div>
                    </div>
                    <div className='col-12'>

                        <div className='row'>
                            <div className='col-6 mb-3'>
                                {rekState.rekSelected?.rekening_disposisi} ({rekState.rekSelected?.penerima_transfer_disposisi})
                            </div>
                            <div className='col-6'>
                                {rekState.rekSelected?.nomor_rekening} ({rekState.rekSelected?.nama_rekening_bagihasil})
                            </div>
                            {/* <div className='col-3'>
                                {rekState.rekSelected?.nomor_rekening_bagihasil}
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <div className="row">
                            <div className="col-2">
                                {/* <div className="row"> */}
                                Tanggal Buka
                                {/* <div className="col-8"> {rekState.rekSelected?.nama_cabang}</div> */}
                                {/* </div> */}
                            </div>

                            <div className="col-2">
                                {/* <div className="row"> */}
                                Tanggal Jatuh Tempo
                                {/* </div> */}
                            </div>

                            <div className="col-2">
                                {/* <div className="row"> */}
                                Jangka Waktu
                                {/* </div> */}
                            </div>

                            <div className="col-2">
                                {/* <div className="row"> */}
                                Bagi Hasil
                                {/* </div> */}
                            </div>

                            <div className="col-4">
                                {/* <div className="row"> */}
                                Jumlah
                                {/* </div> */}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-2">{moment(rekState.rekSelected?.tanggal_buka, "YYYY/MM/DD").format("DD-MM-YYYY")}
                            </div>

                            <div className="col-2"> {moment(rekState.rekSelected?.tanggal_jatuh_tempo_berikutnya, "YYYY/MM/DD").format("DD-MM-YYYY")}
                            </div>

                            <div className="col-2">{rekState.rekSelected?.masa_perjanjian || ""} {rekState.rekSelected?.periode_perjanjian === "H" ? "HARI" : (rekState.rekSelected?.periode_perjanjian === "B" ? "BULAN" : "")}
                            </div>

                            <div className="col-2">
                               <div>Nisbah Bank { parseFloat(100 - (rekState.rekSelected?.nisbah_spesial+rekState.rekSelected?.nisbah_dasar) || 0 ).toFixed(2) } %</div>
                               <div>Nisbah Investor {parseFloat((rekState.rekSelected?.nisbah_spesial+rekState.rekSelected?.nisbah_dasar) || 0).toFixed(2)} %</div>
                            </div>

                            <div className="col-4">
                                Rp. {addCommas(parseFloat(rekState.rekSelected?.saldo_deposito || 0).toFixed(2))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <p>Terbilang</p>
                    </div>
                    <div className="col-12">
                        <p>
                            {
                                terbilang(rekState.rekSelected?.saldo_deposito)+" RUPIAH"
                            }
                        </p>
                    </div>
                </div>

                <div className='row' style={{
                    marginTop: 45
                }}>
                    <div className='col'>
                        <h5 style={{ textAlign: "center" }}>Pejabat 1</h5>
                        <div style={{
                            borderTop: "1px solid black",
                            width: "50%",
                            margin: "auto",
                            marginTop: 45
                        }} className='text-center pt-0'>
                            {rekState.pejabat.pejabat1}
                        </div>
                    </div>
                    <div className='col'>
                        <h5 style={{ textAlign: "center" }}>Pejabat 2</h5>
                        <div style={{
                            borderTop: "1px solid black",
                            width: "50%",
                            margin: "auto",
                            marginTop: 45
                        }} className='text-center pt-0'>
                            {rekState.pejabat.pejabat2}
                        </div>
                    </div>
                </div>

                

            </div>
            {/* </div> */}
            {/* </div> */}
        </>
    );
}

export default CetakPrint;