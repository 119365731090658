import { capitalize } from 'lodash';
import React from 'react';
import { useParams } from 'react-router';
import useTutupRekening, { TutupProvider } from './useTutupRekening';
import ModalDataNasabah from './modal/modalDataNasabah';
import { createForm } from '../../../../module/createFormList';
import moment from 'moment';
import Select from 'react-select';
import useDropdowns from '../../../../hooks/useDropdowns';
import ModalConfirmCustom from '../../../controls/modalAlert/modalConfirmCustomChild';
import { addCommas } from '../../../../module';

function TutupRekening() {
    const { trState, trAction } = useTutupRekening();
    const { mode, jenistf, type } = useParams()
    const { eJTTutupRekening, FTBoolean } = useDropdowns(["eJTTutupRekening", "FTBoolean"])

    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-2 mb-3">
                    <li className="breadcrumb-item color-primary">Transaksi Tutup Rekening</li>
                    <li className="breadcrumb-item">
                        <a href="#">Transaksi Tutup Rekening</a>
                    </li>
                    {/* <li className="breadcrumb-item">{capitalize(trState.paramUrl?.mode)}</li> */}
                </ol>
            </nav>
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        TRANSAKSI TUTUP REKENING
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="form-group form-group-sm col-6">
                                    <label> Saldo Teller</label>
                                    <input
                                        disabled
                                        type="text"
                                        value={addCommas(parseFloat(trState.formData?.info_transaksi?.saldo_teller).toFixed(2))}
                                        className="form-control form-contol-sm" />
                                </div>
                                <div className="form-group form-group-sm col-6">
                                    <label> Kode Valuta Teller</label>
                                    <input
                                        disabled
                                        type="text"
                                        value={trState.formData?.info_transaksi?.kode_valuta_teller}
                                        className="form-control form-contol-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" >


                        {/* parents col 6 1 */}
                        <div className="col-md-6" >


                            <h4 className="text-base">Info Rekening</h4>
                            <div className="row">
                                <div className="col-4">
                                    {
                                        createForm({
                                            type: "number",
                                            label: "No. Rekening",
                                            disabled: !trState.formEditable,
                                            placeholder: "No Rek",
                                            value: trState?.formData?.nomor_rekening,
                                            onChange: (e) => trAction.changeForm(e, "nomor_rekening"),
                                            onEnter: (e) => trAction.cekNasabah(e),
                                            onFocusOut: (e) => trAction.cekNasabah(e)
                                        })
                                    }
                                </div>
                                <div className="col">
                                    {
                                        createForm({
                                            type: "text",
                                            label: <>&nbsp;</>,
                                            disabled: !trState.formEditable || true,
                                            placeholder: "Nama Rekening",
                                            value: trState?.formData?.info_transaksi?.nama_rekening,
                                            // onChange: (e) =>  trAction.setInfoTrx(p => ({ ...p, nama_rekening_penerima: e.toUpperCase() })),
                                        })
                                    }
                                </div>
                            </div>


                            <div className="row">
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "Valuta Rekening",
                                            disabled: !trState.formEditable || true,
                                            value: `${trState?.formData?.info_transaksi?.kode_valuta_rekening} - ${trState?.formData?.info_transaksi?.nama_valuta_rekening}`,
                                            // onChange: (ats_permintaan) => trAction.setInfoTrx(p => ({ ...p, ats_permintaan: ats_permintaan.toUpperCase() }))
                                        })
                                    }
                                </div>
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "Cabang Rekening",
                                            disabled: !trState.formEditable || true,
                                            value: `${trState?.formData?.info_transaksi?.kode_cabang_rekening} - ${trState?.formData?.info_transaksi?.nama_cabang_rekening}`,
                                            // onChange: (ats_permintaan) => trAction.setInfoTrx(p => ({ ...p, ats_permintaan: ats_permintaan.toUpperCase() }))
                                        })
                                    }
                                </div>
                            </div>


                            <div className="row">
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "Saldo Rekening",
                                            disabled: !trState.formEditable || true,
                                            placeholder: "Saldo",
                                            value: addCommas(parseFloat(trState?.formData?.info_transaksi?.saldo_rekening || "0").toFixed(2))
                                        })
                                    }
                                </div>
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "Produk",
                                            disabled: !trState.formEditable || true,
                                            placeholder: "Produk",
                                            value: trState?.formData?.info_transaksi?.produk,
                                            // onChange: (nomor_referensi) => trAction.changeForm(nomor_referensi, "nomor_referensi")
                                        })
                                    }
                                </div>
                            </div>

                            

                            <div className="row">
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "currency",
                                            label: "Saldo Accrual Bagi Hasil",
                                            disabled: !trState.formEditable || true,
                                            placeholder: "Saldo accrual bagi hasil",
                                            value: addCommas(parseFloat(trState?.formData?.info_transaksi?.saldo_accrual_bagihasil || "0").toFixed(2))
                                        })
                                    }
                                </div>
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "currency",
                                            label: "Pajak Bagi Hasil",
                                            disabled: !trState.formEditable || true,
                                            placeholder: "Pajak Bagi Hasil",
                                            value:  addCommas(parseFloat(trState?.formData?.info_transaksi?.pajak_bagi_hasil || "0").toFixed(2))
                                            // onChange: (nomor_referensi) => trAction.changeForm(nomor_referensi, "nomor_referensi")
                                        })
                                    }
                                </div>
                            </div>


                            <div className="row">
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "Jenis Tutup Rekening",
                                            // disabled: !trState.formEditable,
                                            // options: eJTTutupRekening,
                                            key: "reference_code",
                                            placeholder: "Jenis tutup rekening",
                                            display: "reference_desc",
                                            value: "TUNAI",//trState?.formData?.info_transaksi?.jenis_ttr,
                                            onChange: (e) => trAction.changeForm(e, "jenis_ttr", "info_transaksi"),
                                            disabled: true,
                                        })
                                    }
                                </div>
                                <div className=" col-6">
                                    {
                                        createForm({
                                            type: "select",
                                            label: "Tutup Kartu ATM",
                                            disabled: !trState.formEditable,
                                            options: FTBoolean,
                                            key: "reference_code",
                                            placeholder: "pilih tutup atm",
                                            display: "reference_desc",
                                            value: trState?.formData?.info_transaksi?.tutup_kartu_atm,
                                            onChange: (e) => trAction.changeForm(e, "tutup_kartu_atm", "info_transaksi")
                                        })
                                    }
                                </div>
                            </div>
                            {
                                trState?.formData?.info_transaksi?.jenis_ttr === "P" && (
                                    <>
                                        <div className="row">
                                            <div className="col-4">
                                                {
                                                    createForm({
                                                        type: "number",
                                                        label: "No. Rekening Pindah Buku",
                                                        disabled: !trState.formEditable,
                                                        placeholder: "No Rek",
                                                        value: trState?.formData?.info_transaksi?.norek_pinbuk,
                                                        onChange: (e) => trAction.changeForm(e, "norek_pinbuk", "info_transaksi"),
                                                        onEnter: (e) => trAction.cekNasabah(e, 1)
                                                    })
                                                }
                                            </div>
                                            <div className="col">
                                                {
                                                    createForm({
                                                        type: "text",
                                                        label: <>&nbsp;</>,
                                                        disabled: !trState.formEditable || true,
                                                        placeholder: "Nama Rekening Pindah Buku",
                                                        value: trState?.formData?.info_transaksi?.nama_rekening_pinbuk,
                                                        // onChange: (e) =>  trAction.setInfoTrx(p => ({ ...p, nama_rekening_penerima: e.toUpperCase() })),
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }




                            <div className="row">
                                <div className=" col-4">
                                    {
                                        createForm({
                                            type: "text",
                                            label: "Valuta Transaksi",
                                            disabled: !trState.formEditable || true,
                                            value: trState?.formData?.kode_valuta,
                                        })
                                    }
                                </div>
                                <div className=" col">
                                    {
                                        createForm({
                                            type: "text",
                                            label: <>&nbsp;</>,
                                            disabled: true,
                                            value: trState?.formData?.info_transaksi?.keterangan_valuta,
                                            // onChange: (biaya_ekuivalen) => trAction.setInfoTrx(p => ({ ...p, biaya_ekuivalen }))
                                        })
                                    }
                                </div>
                            </div>


                        </div>
                        {/* end parents col 6 1 */}

                        <div className="col-md-6">
                            <h4 className="text-base">Info Transaksi</h4>
                            {/* <div className="row">
                                <div className="col">
                                    {
                                        createForm(
                                            {
                                                type: "text",
                                                label: "Kurs Jual",
                                                disabled: !trState.formEditable || true,
                                                value: trState?.formData?.info_transaksi?.kurs_jual,
                                            }
                                        )
                                    }
                                </div>
                                <div className="col">
                                    {
                                        createForm(
                                            {
                                                type: "text",
                                                label: "Kurs Beli",
                                                disabled: !trState.formEditable || true,
                                                value: trState?.formData?.info_transaksi?.kurs_beli,
                                            }
                                        )
                                    }
                                </div>
                                <div className="col">
                                    {
                                        createForm(
                                            {
                                                type: "text",
                                                label: "Kurs Buku",
                                                disabled: true,
                                                value: trState?.formData?.info_transaksi?.kurs_buku,
                                            }
                                        )
                                    }
                                </div>
                            </div> */}

                            {/* <div className="row">
                                <div className="col-12">
                                    {
                                        createForm(
                                            {
                                                type: "text",
                                                label: "Nilai Ekuivalen",
                                                disabled: true,
                                                value: addCommas( parseFloat(trState?.formData?.info_transaksi?.nominal_ekuivalen).toFixed(2)),
                                            }
                                        )
                                    }
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-12">
                                    {
                                        createForm(
                                            {
                                                // className:"input-bg-grey",
                                                type: "currency",
                                                label: "Biaya Penutupan",
                                                disabled: !trState.formEditable || true,
                                                value: addCommas(trState?.formData?.info_transaksi?.biaya),
                                                onChange: (e) => trAction.changeForm(e, "biaya", "info_transaksi"),
                                            }
                                        )
                                    }
                                </div>
                                {/* <div className="col">
                                    {
                                        createForm(
                                            {
                                                type: "text",
                                                label: "Biaya Ekuivalen",
                                                disabled: true,
                                                value: addCommas(parseFloat(trState?.formData?.info_transaksi?.biaya_ekuivalen).toFixed(2)),
                                            }
                                        )
                                    }
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        createForm(
                                            {
                                                type: "text",
                                                label: "Jumlah Diberikan",
                                                disabled: true,
                                                value: addCommas(parseFloat(trState?.formData?.nominal).toFixed(2))
                                            }
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        createForm(
                                            {
                                                type: "text",
                                                label: "No Referensi",
                                                disabled: true,
                                                value: trState?.formData?.no_referensi,
                                            }
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        createForm(
                                            {
                                                type: "textarea",
                                                label: "Keterangan",

                                                maxLength: 100,
                                                disabled: !trState.formEditable,
                                                value: trState?.formData?.keterangan,
                                                onChange: (e) => trAction.changeForm(e, "keterangan")
                                            }
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    {
                        mode === "otorisasi" && (
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        onClick={() => trAction.responseOtor("APPROVED")}
                                        className="float-right btn btn-success btn-sm ml-3">
                                        Approve
                                    </button>


                                    <button
                                        onClick={() => trAction.responseOtor("REJECTED")}
                                        className="float-right btn btn-danger btn-sm ml-3">
                                        Reject
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    {
                        trState.formEditable && <div className="row">
                            <div className="col-12">
                                <button onClick={trAction.confirmTrx} className="float-right btn btn-success btn-sm ml-3">
                                    Process
                                </button>


                                <button onClick={trAction.resetForm} className="float-right btn btn-outline-secondary btn-sm ml-3">
                                    Reset
                                </button>
                            </div>

                        </div>
                    }
                </div>
            </div>
            <ModalDataNasabah />
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => trAction.setOtorDesc(e.target.value)}
                    value={trState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )
}
export default () => <TutupProvider><TutupRekening /></TutupProvider>;