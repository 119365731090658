import { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button } from 'semantic-ui-react';
import { baseUrl } from '../../../app.config';
import { AppAction } from '../../../app.data';
import Skeleton from 'react-loading-skeleton';

const TutupRekening = ({ show, type = 'tutup', onHide, data, accountHolderType, onSuccess }) => {
    const queryClient = useQueryClient();
    const [confirm, setConfirm] = useState(false);

    const appAction = useRef(null);

    const { data: form, isPreviousData } = useQuery(
        ['rekening-deposito-detail', data?.nomorDeposito, accountHolderType, data],
        async () => {
            const res = await fetch(
                baseUrl +
                    `/deposito/find/${data?.nomorDeposito}?detailsLevel=FULL&Accountholdertype=${accountHolderType}`
            );

            const resData = await res.json();

            return resData?.list;
        },
        {
            keepPreviousData: true,
        }
    );

    const formatNumber = (number) =>
        new Intl.NumberFormat('id-ID', {
            currency: form?._rekening?.valuta ?? 'IDR',
            style: 'currency',
        }).format(number);

    const handleTutupRekening = useMutation(
        async () => {
            const body = {
                action: form?.statusAkun === 'APPROVED' ? 'CLOSE_WITHDRAW' : 'CLOSE',
                notes: '-',
            };

            if (type === 'batal') {
                const res = await fetch(
                    baseUrl + `/deposito/buka-kembali-rekening/${data?.nomorDeposito}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                const resData = await res.json();

                return resData;
            }

            if (type === 'tutup') {
                const endpoint =
                    form?.statusAkun === 'APPROVED' ? 'blokir-rekening' : 'tutup-rekening';

                const res = await fetch(baseUrl + `/deposito/${endpoint}/${data?.nomorDeposito}`, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const resData = await res.json();

                return resData;
            }
        },
        {
            onSuccess: () => {
                setConfirm(false);
                queryClient.refetchQueries({ queryKey: 'rekening-deposito-detail' });
                if (onSuccess) onSuccess();
            },
        }
    );

    return (
        <>
            <AppAction ref={appAction} />
            <Modal centered show={handleTutupRekening.isSuccess}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Proses Tutup Rekening Deposito berhasil dilakukan
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => handleTutupRekening.reset()}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Selesai
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={confirm}>
                <Modal.Header onHide={() => setConfirm(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Lakukan proses Tutup Rekening Deposito?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setConfirm(false)}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Tidak
                    </Button>
                    <Button
                        loading={handleTutupRekening.isLoading}
                        onClick={() => handleTutupRekening.mutate()}
                        className="tw-w-16"
                        size="mini"
                        color="blue"
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={show} size="lg">
                <Modal.Header onHide={onHide} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">
                            {type === 'batal' && 'Batalkan'} Tutup Rekening Deposito
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tw-border tw-border-black-40 tw-py-5 tw-px-6 tw-border-solid tw-rounded-lg tw-mb-6">
                        <div className="tw-flex tw-items-center tw-mb-5">
                            <div className="tw-text-black tw-font-bold tw-mr-2 tw-text-16">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    data?.namaLengkap
                                )}
                            </div>
                            {isPreviousData ? (
                                <Skeleton width={50} height="100%" />
                            ) : (
                                <>
                                    {form?.statusAkun === 'ACTIVE' && (
                                        <div className="tw-bg-green tw-text-12 tw-bg-opacity-20 tw-text-green tw-rounded-full tw-px-3">
                                            Aktif
                                        </div>
                                    )}
                                    {form?.statusAkun === 'LOCKED' && (
                                        <div className="tw-bg-black-40 tw-text-12 tw-bg-opacity-20 tw-text-black-40 tw-rounded-full tw-px-3">
                                            Locked
                                        </div>
                                    )}
                                </>
                            )}

                            {type === 'tutup' && (
                                <div className="tw-bg-primary tw-ml-2 tw-text-12 tw-bg-opacity-20 tw-text-primary tw-rounded-full tw-px-3">
                                    Proses Tutup Rekening
                                </div>
                            )}
                        </div>
                        <div className="tw-grid tw-grid-cols-6 tw-items-center tw-gap-x-2 tw-gap-y-1 tw-text-black tw-text-12">
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Produk</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    form?._rekening?.produk
                                )}
                            </div>
                            {/* <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Cabang</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">KCP Tembalang</div> */}
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Saldo Rek</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    formatNumber(form?.balances?.availableBalance)
                                )}
                            </div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>No Rek</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    data?.nomorDeposito
                                )}
                            </div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Kode Valuta</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">
                                {isPreviousData ? (
                                    <Skeleton width={100} height="100%" />
                                ) : (
                                    form?._rekening?.valuta
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide} className="tw-mr-2" size="mini">
                        Batal
                    </Button>
                    <Button
                        onClick={() => {
                            setConfirm(true);
                            if (onHide) onHide();
                        }}
                        color={type === 'batal' ? 'blue' : 'red'}
                        size="mini"
                        className="tw-font-normal"
                    >
                        {type === 'batal' ? 'Batalkan Tutup Rekening' : 'Tutup Rekening'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TutupRekening;
