import React, { memo, useRef } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { Tab, Nav, ButtonGroup, Button } from 'react-bootstrap';
import { baseUrl } from './../../../../../app.config';

class SVSDeposito extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.location.state,
            svsData: [],
            svsParams: [
                {
                    data: {
                        nomor_cif: this.props.location.state.db_id,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: this.props.location.state.db_id,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: this.props.location.state.db_id,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: this.props.location.state.db_id,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
            ],
        };
    }

    componentDidMount() {
        // // console.log(this.props.location.state);
        this.getData();
    }

    handleFileChange = (event, index) => {
        // console.log(event);
        const svsParams = [...this.state.svsParams];
        svsParams[index] = {
            data: {
                ...svsParams[index].data,
                filename:
                    'https://i0.wp.com/bagiinfo.com/wp-content/uploads/2017/01/tanda-tangan-candra.jpg?resize=680%2C498',
            },
            options: {
                ...svsParams[index].options,
                selectedFile: event.target.files[0],
                selectedFileUrl: URL.createObjectURL(event.target.files[0]),
                isChoosedFile: true,
            },
        };
        this.setState((prevState) => ({
            svsParams: svsParams,
        }));
    };

    handleFileRemove = (index) => {
        const svsParams = [...this.state.svsParams];
        svsParams[index] = {
            data: {
                nomor_cif: this.props.location.state.CIF,
                encoded_key_document: 'string',
                filename: '',
                image_tag: '',
                remark: '',
                type: 'Nasabah Badan Usaha',
            },
            options: {
                isChoosedFile: false,
                selectedFile: null,
                selectedFileUrl: null,
            },
        };
        this.setState((prevState) => ({
            svsParams: svsParams,
        }));
    };

    handleTextChanged = (event, index) => {
        const value = event.target.value;
        const name = event.target.name;
        const svsParams = [...this.state.svsParams];
        svsParams[index] = {
            data: {
                ...svsParams[index].data,
                [name]: value,
            },
            options: {
                ...svsParams[index].options,
            },
        };
        this.setState((prevState) => ({
            svsParams: svsParams,
        }));
    };

    getData = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(
            baseUrl + `/nasabah/svs/local/find-all/${this.props?.location?.state?.db_id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                let dataList = data.list;
                this.setState({ svsData: dataList });
            });
    };

    postSVS = async () => {
        this.state.svsParams.map((item, index) => {
            let formData = new FormData();
            formData.append('owner_db_id', this.props.location.state.db_id);
            formData.append('image_tag', item.data.image_tag);
            formData.append('remark', item.data.remark);
            formData.append('file', item.options.selectedFile);
            const requestOptions = {
                method: 'POST',
                body: formData,
            };
            fetch(baseUrl + '/nasabah/svs/single-upload', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        alert(data.db.status);
                        window.location.reload();
                    } else {
                        alert(data.db.status);
                    }
                });
        });
    };

    render() {
        // console.log(this.state.svsData, 'svs data');
        return (
            <div className="">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Deposito Approved</li>
                        <li className="breadcrumb-item">
                            Informasi Data (SVS) Rekening Nasabah Korporat
                        </li>
                    </ol>
                </nav>

                <div className="card">
                    <div className="card-body">
                        <div className="box">
                            <h3>{this.state.data?.namaLengkap}</h3>
                            <div className="row">
                                <div className="col-4">
                                    <div>
                                        <span className="text-grey">No. CIF</span>
                                        <span className="ml-3 mr-1">:</span>
                                        <span className="text-grey2">
                                            {this.state.data?.nomorCIF}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <span className="text-grey">NPWP</span>
                                        <span className="ml-3 mr-1">:</span>
                                        <span className="text-grey2">-</span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div>
                                        <span className="text-grey">Alamat</span>
                                        <span className="ml-3 mr-1">:</span>
                                        <span className="text-grey2">
                                            {this.state.data?.alamat?.alamat}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body p0">
                        <Tab.Container defaultActiveKey="accountImages">
                            <div className="col-12 p-4">
                                <Nav fill justify variant="pills" className="svs-nav">
                                    <Nav.Item>
                                        <Nav.Link eventKey="accountImages">Account Images</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <Tab.Content>
                                <Tab.Pane eventKey="accountImages">
                                    <table className="table"></table>
                                    <div className="p-3">
                                        <div className="row">
                                            {this.state.svsData && this.state.svsData.length > 0
                                                ? this.state.svsData.map((data, index) => {
                                                      // console.log(data, 'map');
                                                      return (
                                                          <div
                                                              className="col-4"
                                                              key={`listsaccountimagessign${index}`}
                                                          >
                                                              <p>
                                                                  Image {index + 1}
                                                                  {/* {data} */}
                                                              </p>
                                                              <div className="svs-box">
                                                                  <button className="btn btn-sm btn-outline-secondary px-4 float-right text-danger">
                                                                      Delete
                                                                  </button>
                                                                  <div className="mt-5 text-center">
                                                                      <img
                                                                          src={data.signed_url}
                                                                          height={110}
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <p className="border p-2 mt-2 fs-12">
                                                                  {data?.remark}
                                                              </p>
                                                          </div>
                                                      );
                                                  })
                                                : null}
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="newImages">
                                    <div className="p-4">
                                        <div className="row">
                                            {this.state.svsParams && this.state.svsParams.length > 0
                                                ? this.state.svsParams.map((data, index) => {
                                                      return (
                                                          <div
                                                              className="col-6"
                                                              key={`newimagesdata${index}`}
                                                          >
                                                              <p className="mt-2">
                                                                  Image {index + 1}
                                                              </p>
                                                              <div className="row px-3">
                                                                  <div className="col-12 svs-box-new-images">
                                                                      <div className="row">
                                                                          <div className="col-6">
                                                                              {data.options
                                                                                  .isChoosedFile ? (
                                                                                  <div className="text-center">
                                                                                      <img
                                                                                          className="img-fluid"
                                                                                          src={
                                                                                              data
                                                                                                  .options
                                                                                                  ?.selectedFileUrl
                                                                                          }
                                                                                      />
                                                                                  </div>
                                                                              ) : (
                                                                                  <div className="text-center">
                                                                                      <img
                                                                                          className="img-fluid"
                                                                                          src={data}
                                                                                      />
                                                                                  </div>
                                                                              )}
                                                                          </div>
                                                                          <div className="col-6 svs-border-left">
                                                                              <div className="form-group mt-2">
                                                                                  <label htmlFor="">
                                                                                      Image Tag
                                                                                  </label>
                                                                                  <select
                                                                                      name="image_tag"
                                                                                      className="form-control"
                                                                                      onChange={(
                                                                                          e
                                                                                      ) =>
                                                                                          this.handleTextChanged(
                                                                                              e,
                                                                                              index
                                                                                          )
                                                                                      }
                                                                                      value={data}
                                                                                  >
                                                                                      <option value="">
                                                                                          Pilih
                                                                                          Image Tag
                                                                                      </option>
                                                                                      <option value="sign">
                                                                                          Tanda
                                                                                          Tangan
                                                                                      </option>
                                                                                  </select>
                                                                              </div>
                                                                              <div className="form-group">
                                                                                  <label htmlFor="">
                                                                                      Remark
                                                                                  </label>
                                                                                  <input
                                                                                      type="text"
                                                                                      className="form-control"
                                                                                      placeholder="Masukkan remark"
                                                                                      value={
                                                                                          data?.data
                                                                                              ?.remark
                                                                                      }
                                                                                      name="remark"
                                                                                      onChange={(
                                                                                          e
                                                                                      ) =>
                                                                                          this.handleTextChanged(
                                                                                              e,
                                                                                              index
                                                                                          )
                                                                                      }
                                                                                  />
                                                                              </div>
                                                                              <div className="form-group">
                                                                                  <div className="row">
                                                                                      <div className="col-7">
                                                                                          <input
                                                                                              type="file"
                                                                                              ref={`fileUploader${index}`}
                                                                                              style={{
                                                                                                  display:
                                                                                                      'none',
                                                                                              }}
                                                                                              onChange={(
                                                                                                  e
                                                                                              ) =>
                                                                                                  this.handleFileChange(
                                                                                                      e,
                                                                                                      index
                                                                                                  )
                                                                                              }
                                                                                          />
                                                                                          <button
                                                                                              onClick={() => {
                                                                                                  this.refs[
                                                                                                      'fileUploader' +
                                                                                                          index
                                                                                                  ].click();
                                                                                              }}
                                                                                              className="btn btn-orange-primary btn-block"
                                                                                          >
                                                                                              Pilih
                                                                                              File
                                                                                          </button>
                                                                                      </div>
                                                                                      <div className="col-5">
                                                                                          <button
                                                                                              className="btn btn-outline-secondary btn-block"
                                                                                              onClick={() =>
                                                                                                  this.handleFileRemove(
                                                                                                      index
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              Cancel
                                                                                          </button>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      );
                                                  })
                                                : null}
                                        </div>
                                        <div className="text-right mt-3">
                                            <button
                                                onClick={() => this.postSVS()}
                                                className="btn btn-primary"
                                            >
                                                Simpan
                                            </button>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="deletedImages">
                                    <div>Deleted images</div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        );
    }
}

export default SVSDeposito;
