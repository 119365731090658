import React, { createContext, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import useFetch from '../../../../../hooks/useFetch';
import { addCommas } from '../../../../../module';
import { TableList } from '../../../../controls';
import { useModalAlert } from '../../../../controls/modalAlert';



 const ModalDetilTrxContext = createContext({
    mtAction: {},
    mtState: {}
})

export const ModalDetilTrxProv = ({ children }) => {

    const { doGet } = useFetch()

    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const [dataModal, setDataModal] = useState({
        show: false
    });
    const [data, setData] = useState([])

    const [ isLoading, setIsLoading] = useState(true)

    function closeModal() {
        setData([])
        setDataModal(m => ({
            ...m,
            show: false
        }));
    }

    function openModal(id_transaksi_core ) {
        getDetail(id_transaksi_core )
        setDataModal(m => ({
            ...m,
            show: true
        }));
    }


    
    // getData rekening list
    async function getDetail(id_transaksi_core = 0 ) {
        if(id_transaksi_core === null || id_transaksi_core === "" || id_transaksi_core === undefined || isNaN(id_transaksi_core)) id_transaksi_core = 0;
        setIsLoading(true)
        var url = `/informasi/transaksi-detil-by-id_transaksi`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { id_transaksi_core   },
                service: "trx"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    setData(data)
                    // setSaldoKas(data?.saldo_kas_teller)
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }



    return (
        <ModalDetilTrxContext.Provider value={{
            mtAction: {
                openModal,
                closeModal
            },
            mtState: {}
        }}>
            {children}

            <Modal size="xl" show={dataModal.show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Detail Transaksi
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <TableList
                        withCard={false}
                        minHeight="450px"
                        maxHeight="500px"
                        // onChangeShow={ktAction.changeShow}
                        // onNextPage={ktAction.nextPage}
                        // onPrevPage={ktAction.prevPage}
                        withActions={false}
                        columns={[
                            {
                                name: 'Cabang',
                                field: 'kode_cabang',
                            },
                            {
                                name: 'Rekening',
                                field: 'nomor_rekening',
                            },
                            {
                                name: 'Nama',
                                field: 'nama_rekening',
                            },
                            {
                                name: 'Kode Valuta',
                                field: 'kode_valuta',
                                width: '40px',
                            },
                            {
                                name: 'Jenis Mutasi',
                                field: 'jenis_mutasi',
                            },
                            {
                                name: 'Nilai',
                                field: 'nilai_mutasi',
                                className:"text-right",
                                onRender: (item)=> addCommas(item.nilai_mutasi)
                            },
                            // {
                            //     name: 'Kurs',
                            //     field: 'nilai_kurs',
                            // },
                            // {
                            //     name: 'Nilai Ekuivalen',
                            //     field: 'nilai_ekuivalen',
                            //     className:"text-right",
                            //     onRender: (item)=> addCommas(item.nilai_ekuivalen)
                            // },
                            {
                                name: 'Keterangan',
                                field: 'keterangan',
                            },
                        ]}
                        data={data}
                        // data={ktState.dataList}
                        isLoading={isLoading}
                        pagination={false}
                        filter={false}
                        resetFilter={false}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            <button onClick={closeModal} className="float-right btn btn-outline-secondary btn-sm">
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </ModalDetilTrxContext.Provider>
    );
}

export default function useModalDetilTrx() {
    return useContext(ModalDetilTrxContext)
};