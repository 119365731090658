import moment from 'moment';
import React from 'react';
import { forwardRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { addCommas } from '../../../../../module';
import useTrxToday from './useTrxToday';
import logo from './logo.png'

const PrintArea = forwardRef(({ }, ref) => {
    const { todayAction, todayState } = useTrxToday()

    function sortingDenom(dataDenom = [], namaDenom) {
        return dataDenom.filter(({ jenis = "" }) => (
            jenis.toUpperCase() === namaDenom.toUpperCase()
        )).sort(function (a, b) {
            return b.nilai_denominasi - a.nilai_denominasi;
        })
    }

    // async function()
    return (
        < div className="print-hide"
        >

            <div className="row" ref={ref}>
                <div className="col-12">
                    {/* <div className="card">
                        <div className="card-body"> */}
                    <div id="printArea">
                        {/* <img src={logo} style={{
                            width: 65,
                            position: "absolute",
                            top: 0,
                            left: 10
                        }} /> */}
                        <h4 className="text-center"><u>BUKTI SERAH TERIMA KAS</u></h4>
                        <div className="row">
                            <div className="col-3"> Tanggal Penyerahan </div>
                            <div className="col"> : {moment(todayState.dataPrint?.transaksi?.tanggal_otor).format("DD-MM-YYYY")} </div>
                        </div>
                        <div className="row">
                            <div className="col-3"> No Arsip </div>
                            <div className="col"> : {todayState.dataPrint?.transaksi?.nomor_referensi}
                                {/* {cdState.formData?.user_id}  */}
                            </div>
                        </div>
                        {
                            todayState.dataPrint.transaksi?.kode_transaksi === "ATM01" || todayState.dataPrint.transaksi?.kode_transaksi === "ATM02" ? (
                                <>
                                    <div className="row">
                                        <div className="col-3"> Rekening Pengirim </div>
                                        <div className="col"> : {
                                            `${todayState?.dataPrint?.transaksi?.nomor_rekening_debet} - ${todayState?.dataPrint?.info_transaksi?.jenis_transaksi === "CIA" ? todayState?.dataPrint?.info_transaksi?.nama_rekening : todayState?.dataPrint.info_transaksi?.nama_rekening_kredit}`
                                        }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3"> Rekening Penerima </div>
                                        <div className="col"> : {
                                                `${todayState?.dataPrint.transaksi?.nomor_rekening_credit} - ${todayState?.dataPrint?.info_transaksi?.jenis_transaksi === "COA" ? todayState?.dataPrint?.info_transaksi?.nama_rekening : todayState?.dataPrint?.info_transaksi?.nama_rekening_kredit}`
                                            }
                                        </div>
                                    </div>

                                </>
                            ) : (
                                <>
                                    <div className="row">
                                        <div className="col-3"> Diserahkan Oleh </div>
                                        <div className="col"> : {
                                            (todayState.dataPrint?.transaksi?.kode_transaksi === "SKK") ?
                                                ` ${todayState.dataPrint?.transaksi?.user_input} - ${todayState.dataPrint?.info_transaksi?.namaTeller}` :
                                                (todayState.dataPrint?.transaksi?.kode_transaksi === "AKT") ?
                                                    ` ${todayState.dataPrint?.info_transaksi?.user_id_teller_pengirim} - ${todayState.dataPrint?.info_transaksi?.namaTellerPengirim}` :
                                                    (todayState.dataPrint?.transaksi?.kode_transaksi === "AKK") ?
                                                        ` ${todayState.dataPrint?.transaksi?.user_otorisasi} - ${todayState.dataPrint?.info_transaksi?.nama_user_otor} ` : ""
                                        }
                                            {/* {` ${todayState.dataPrint?.transaksi?.kode_transaksi === "AKT" ?
                                    "- " + todayState.dataPrint?.info_transaksi?.user_id_teller_pengirim + "( " + todayState.dataPrint?.info_transaksi?.namaTeller + " )" :
                                    ""
                                    }
                                                        `
                                }  */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3"> Diterima Oleh </div>
                                        <div className="col"> : {
                                                (todayState.dataPrint?.transaksi?.kode_transaksi === "AKK") ?
                                                    ` ${todayState.dataPrint?.transaksi?.user_input} - ${todayState.dataPrint?.info_transaksi?.namaTeller}` :
                                                    (todayState.dataPrint?.transaksi?.kode_transaksi === "AKT") ?
                                                        ` ${todayState.dataPrint?.transaksi?.user_input} - ${todayState.dataPrint?.info_transaksi?.namaTellerPenerima}` :
                                                        (todayState.dataPrint?.transaksi?.kode_transaksi === "SKK") ?
                                                            ` ${todayState.dataPrint?.transaksi?.user_otorisasi} - ${todayState.dataPrint?.info_transaksi?.nama_user_otor} ` : ""


                                            }
                                            {/* {
                                todayState.dataPrint?.transaksi?.nomor_rekening_credit}
                                {`
                                                            ${todayState.dataPrint?.transaksi?.kode_transaksi === "AKT" ?
                                        "-  " + todayState.dataPrint?.transaksi?.user_input :
                                        "("+todayState.dataPrint?.transaksi?.user_input
                                    }
                                                        `
                                }   */}
                                        </div>
                                    </div>

                                </>
                            )
                        }

                        <div className="row">
                            <div className="col-3"> Valuta </div>
                            <div className="col"> : {todayState.dataPrint?.transaksi?.kode_valuta}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3"> Total Nominal </div>
                            <div className="col"> : {addCommas(parseFloat(todayState.dataPrint?.transaksi?.nominal).toFixed(2))}
                            </div>
                        </div>
                        {
                            // todayState.dataPrint?.transaksi?.kode_transaksi !== "AKT" && 
                            <div className="table-responsive mt-3">
                                <table className="table table-bordered table-sm" >
                                    <thead>
                                        <tr>
                                            <th colSpan={3} className="text-center">
                                                RINCIAN NOMINAL KAS
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">
                                                PECAHAN
                                            </th>

                                            <th className="text-center">
                                                JUMLAH
                                            </th>

                                            <th className="text-center">
                                                DENOM * JUMLAH
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th colSpan={3} style={{
                                                fontWeight: "bold",
                                                fontSize: "12pt"

                                            }}>
                                                Uang Kertas
                                            </th>
                                        </tr>
                                        {
                                            (sortingDenom(todayState.dataDenom || [], "lembar") || []).map(({
                                                jenis = "",
                                                jumlah_lembar_koin = 0,
                                                nilai_denominasi = 5,
                                                total_nominal = 0
                                            }, i) =>
                                                <tr key={jenis + i}>
                                                    <td className="text-right" >
                                                        {nilai_denominasi}
                                                    </td>
                                                    <td className="text-right">
                                                        {
                                                            // (
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                            // ) ? 
                                                            jumlah_lembar_koin
                                                            // : ""

                                                        }
                                                    </td>
                                                    <td className="text-right">
                                                        {
                                                            // (
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                            // ) ? 
                                                            addCommas((parseFloat(nilai_denominasi) * parseFloat(jumlah_lembar_koin)).toFixed(2))
                                                            // : ""
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        <tr>
                                            <td colSpan={2} className="text-right" style={{
                                                fontWeight: "bold",
                                                fontSize: "12pt"
                                            }}>
                                                Jumlah
                                            </td>
                                            <td className="text-right" style={{
                                                fontWeight: "bold",
                                                fontSize: "12pt"
                                            }}>
                                                {
                                                    // (
                                                    //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                    //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                    // ) ? 
                                                    addCommas(parseFloat(
                                                        (sortingDenom(todayState.dataDenom || [], "lembar") || []).reduce((a, b) => {
                                                            // // console.log(a, b.total_nominal)
                                                            return parseFloat(a) + (parseFloat(b.nilai_denominasi) * parseFloat(b.jumlah_lembar_koin))
                                                        }, 0)
                                                    ).toFixed(2)) //: ""
                                                }
                                            </td>
                                        </tr>

                                        <tr>
                                            <th colSpan={3} style={{
                                                fontWeight: "bold",
                                                fontSize: "12pt"

                                            }}>
                                                Uang Logam
                                            </th>
                                        </tr>
                                        {
                                            (sortingDenom(todayState.dataDenom || [], "koin") || []).map(({
                                                jenis = "",
                                                jumlah_lembar_koin = 0,
                                                nilai_denominasi = 5,
                                                total_nominal = 0
                                            }, i) =>
                                                <tr key={jenis + i}>
                                                    <td className="text-right" >
                                                        {nilai_denominasi}
                                                    </td>
                                                    <td className="text-right">
                                                        {
                                                            // (
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                            // ) ? 
                                                            jumlah_lembar_koin //: ""

                                                        }
                                                    </td>
                                                    <td className="text-right">
                                                        {
                                                            // (
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                            // ) ? 
                                                            addCommas((parseFloat(nilai_denominasi) * parseFloat(jumlah_lembar_koin)).toFixed(2)) //: ""
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        <tr>
                                            <td colSpan={2} className="text-right" style={{
                                                fontWeight: "bold",
                                                fontSize: "12pt"
                                            }}>
                                                Jumlah
                                            </td>
                                            <td className="text-right" style={{
                                                fontWeight: "bold",
                                                fontSize: "12pt"
                                            }}>
                                                {
                                                    // (
                                                    //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                    //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                    // ) ? 
                                                    addCommas(parseFloat(
                                                        (sortingDenom(todayState.dataDenom || [], "koin") || []).reduce((a, b) => {
                                                            // // // console.log(a, b.total_nominal)
                                                            return parseFloat(a) + (parseFloat(b.nilai_denominasi) * parseFloat(b.jumlah_lembar_koin))
                                                        }, 0)
                                                    ).toFixed(2)) // : ""
                                                }
                                            </td>
                                        </tr>

                                        <tr>
                                            <th colSpan={3} style={{
                                                fontWeight: "bold",
                                                fontSize: "12pt"

                                            }}>
                                                Uang Lusuh
                                            </th>
                                        </tr>
                                        {
                                            (sortingDenom(todayState.dataDenom || [], "lembar (utle)") || []).map(({
                                                jenis = "",
                                                jumlah_lembar_koin = 0,
                                                nilai_denominasi = 5,
                                                total_nominal = 0
                                            }, i) =>
                                                <tr key={jenis + i}>
                                                    <td className="text-right" >
                                                        {nilai_denominasi}
                                                    </td>
                                                    <td className="text-right">
                                                        {
                                                            // (
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                            // ) ? 
                                                            jumlah_lembar_koin
                                                            //: ""

                                                        }
                                                    </td>
                                                    <td className="text-right">
                                                        {
                                                            // (
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                            //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                            // ) ? 
                                                            addCommas((parseFloat(nilai_denominasi) * parseFloat(jumlah_lembar_koin)).toFixed(2)) //: ""
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        <tr>
                                            <td colSpan={2} className="text-right" style={{
                                                fontWeight: "bold",
                                                fontSize: "12pt"
                                            }}>
                                                Jumlah
                                            </td>
                                            <td className="text-right" style={{
                                                fontWeight: "bold",
                                                fontSize: "12pt"
                                            }}>
                                                {
                                                    // (
                                                    //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                    //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                    // ) ? 
                                                    addCommas(parseFloat(
                                                        (sortingDenom(todayState.dataDenom || [], "lembar (utle)") || []).reduce((a, b) => {
                                                            // // console.log(a, b.total_nominal)
                                                            return parseFloat(a) + (parseFloat(b.nilai_denominasi) * parseFloat(b.jumlah_lembar_koin))
                                                        }, 0)
                                                    ).toFixed(2)) //: ""
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}
                                                style={{
                                                    fontSize: "16pt",
                                                    textAlign: "center"
                                                }}
                                            >
                                                TOTAL

                                            </td>
                                            <td style={{
                                                fontSize: "16pt",
                                                textAlign: "right"
                                            }}>
                                                {
                                                    // (
                                                    //     todayState.dataPrint?.transaksi?.kode_transaksi === "AKK" ||
                                                    //     todayState.dataPrint?.transaksi?.kode_transaksi === "SKK"
                                                    // ) ? 
                                                    addCommas(parseFloat(
                                                        ((todayState.dataDenom || [])).reduce((a, b) => {
                                                            // // console.log(a, b.total_nominal)
                                                            return parseFloat(a) + (parseFloat(b.nilai_denominasi) * parseFloat(b.jumlah_lembar_koin))
                                                        }, 0)
                                                    ).toFixed(2)) //: ""
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className='row mt-3'>
                            <div className='col-12 text-center'>
                                {moment(todayState.dataPrint?.transaksi?.tanggal_otor).format("DD-MM-YYYY")}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-6">

                                <div style={{
                                    borderTop: "1px solid black",
                                    margin: "auto",
                                    marginTop: 90,
                                    width: "60%",
                                    textAlign: "center",
                                    paddingTop: 0
                                }}>
                                    {
                                        (todayState.dataPrint?.transaksi?.kode_transaksi === "SKK") ?
                                            ` ${todayState.dataPrint?.transaksi?.user_input} - ${todayState.dataPrint?.info_transaksi?.namaTeller}` :
                                            (todayState.dataPrint?.transaksi?.kode_transaksi === "AKT") ?
                                                ` ${todayState.dataPrint?.info_transaksi?.user_id_teller_pengirim} - ${todayState.dataPrint?.info_transaksi?.namaTellerPengirim}` :
                                                (todayState.dataPrint?.transaksi?.kode_transaksi === "AKK") ?
                                                    `${todayState.dataPrint?.transaksi?.user_otorisasi} - ${todayState.dataPrint?.info_transaksi?.nama_user_otor} ` : ""
                                    }
                                </div>
                            </div>
                            <div className="col-6">
                                <div style={{
                                    borderTop: "1px solid black",
                                    margin: "auto",
                                    textAlign: "center",
                                    marginTop: 90,
                                    paddingTop: 0,
                                    width: "60%"
                                }}>
                                    {
                                        (todayState.dataPrint?.transaksi?.kode_transaksi === "AKK") ?
                                            ` ${todayState.dataPrint?.transaksi?.user_input} - ${todayState.dataPrint?.info_transaksi?.namaTeller}` :
                                            (todayState.dataPrint?.transaksi?.kode_transaksi === "AKT") ?
                                                ` ${todayState.dataPrint?.transaksi?.user_input} - ${todayState.dataPrint?.info_transaksi?.namaTellerPenerima}` :
                                                (todayState.dataPrint?.transaksi?.kode_transaksi === "SKK") ?
                                                    `${todayState.dataPrint?.transaksi?.user_otorisasi} - ${todayState.dataPrint?.info_transaksi?.nama_user_otor} ` : ""

                                    }
                                    {/* {todayState.dataPrint?.transaksi?.nomor_rekening_credit} {`
                                                            ${todayState.dataPrint?.transaksi?.kode_transaksi === "AKT" ?
                                            "-  " + todayState.dataPrint?.transaksi?.user_input + " - "+todayState.dataPrint?.info_transaksi?.namaTeller :
                                            ""
                                        }
                                                        `
                                    } */}
                                </div>
                            </div>
                        </div>
                        {/* <TableList
                                    withCard={false}
                                    pagination={false}
                                    filter={false}
                                    withActions={false}
                                    maxHeight="1500px"
                                    columns={[
                                        {
                                            name: 'Nilai Denominasi',
                                            field: 'nilai_denominasi',
                                            className: "text-right",
                                            onRender: (item) => addCommas(item.nilai_denominasi)
                                        },
                                        {
                                            name: 'Jenis',
                                            field: 'jenis',
                                        },
                                        {
                                            name: 'Jumlah Lembar',
                                            field: 'jumlah_lembar_koin',
                                            className: 'text-right',
                                            maxWidth: "100px",
                                            onRender: (item, key) => (
                                                addCommas(item.jumlah_lembar_koin)
                                                // <input
                                                //     type="text"
                                                //     value={addCommas(item.jumlah_lembar_koin)}
                                                //     readOnly={!cdState.formEditable}
                                                //     className="form-control form-control-sm"
                                                //     onChange={(e) => cdAction.changeJumlahLembar(removeNonNumeric(e.target.value), key)}
                                                // />
                                            )
                                        },
                                        {
                                            name: 'Total Nominal',
                                            field: 'total_nominal',
                                            className: "text-right",
                                            onRender: (item) => <> {addCommas(item?.total_nominal || "0")}, -</>
                                        },
                                    ]}
                                    data={
                                        [
                                                   
                                            ...( sortingDenom( cdState.formData?.denominasi || [], "lembar") || []),
                
                                            ...( sortingDenom( cdState.formData?.denominasi || [], "koin") || []),
                                            
                                            ...( sortingDenom( cdState.formData?.denominasi || [], "lembar (utle)") || []),
                                        ] 
                                    }

                                /> */}
                    </div>
                    {/* <div className="card-footer">
                                <div className="row">
                                    <div className="col-12">
                                        <button className="btn btn-primary float-right">
                                            <i className="fa fa-print"></i> Cetak
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                </div>
                {/* </div>
                </div> */}
            </div>
        </div >
    );
})

export default PrintArea;