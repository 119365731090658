import React, { memo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { AppAction, AppInterfaces } from '../../../app.data';

const BasePageMenu_base = memo(({store, ...props}) => {
    const appAction = useRef(null);

    return(
        <>
            <AppAction ref={appAction} />
            <div className="col-lg-12 p-0">
                <img src={props.icon} alt="Logo" style={{width:"100%"}}  />
            </div>
            <div className="col-12">
                <div className="card" style={{borderRadius:"8px",position:"absolute",top:"-40px", width:"97%"}}>
                    <div className="card-body">
                        <h5 className="mb-3">{props.title}</h5>
                        <div class="row">
                            <div className={props.rightComponent ? "col-9" : "col-12"}>
                                <div className={`row row-cols-md-4 ${props.rightComponent ? "row-cols-lg-5" : "row-cols-lg-6"}`}>
                                    {props.layanan.map((data, id) => data.type ? (
                                        <div key={"la"+id}  className="col mb-1 p-2 align-self-center">
                                            <div className="card card-body font-smaller pointer" onClick={data.trigger} style={{borderRadius:"8px", height:"80px", cursor:"pointer"}}>
                                                <div className="row align-items-stretch pointer">
                                                    <div className="col-lg-3 col-md-4 col-1 align-self-center px-2">
                                                        <img src={data.icon} alt="Logo" className="icon-logo" width={40} />
                                                    </div>
                                                    <div className="col-lg-9 col-md-7 px-1 m-0 col-11 align-self-center">
                                                        <label className="pointer" style={{ fontSize: "13px",}}>{data.title}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={"la"+id}  className="col mb-1 p-2 align-self-center" style={{  }}>
                                            <Link to={data.link} className="card card-body font-smaller " onClick={()=>appAction.current.disp({type: "setHeader", header: data})} style={{borderRadius:"8px", height:"80px", textDecoration: "none", color: "black"}}>
                                                <div className="row align-items-stretch">
                                                    <div className="col-lg-3 col-md-4 col-1 align-self-center px-2">
                                                        <img src={data.icon} alt="Logo" className="icon-logo" width={40} />
                                                    </div>
                                                    <div className="col-lg-9 col-md-7 px-1 m-0 col-11 align-self-center">
                                                        <label className="pointer" style={{ fontSize: "13px",}}>{data.title}</label>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>)
                                    )}
                                </div>
                            </div>
                            {props.rightComponent && <div className="col-3 " >
                                <div className="ml-3 ml-md-2 px-5 px-md-4 align-items-center h-100" style={{ borderLeft: "2px solid #000" }}>
                                    {props.rightComponent.map((data,id)=>{
                                        return(
                                            <div key={"ra"+id}>
                                                    <div className="card" style={{ borderRadius: "8px" }}>
                                                    <div className="card-body font-smaller">
                                                        <img src={data.icon} alt="Logo" width={40} className="mr-2" />
                                                        <label className="float-right m-0" style={{ fontSize: "10pt", width: "50%" }}> Bayar Sekaligus</label>
                                                    </div>
                                                </div>
                                                <p className="mt-3 text-center"><b>Bayar banyak tagihan untuk satu pelanggan</b></p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

const BasePageMenu = AppInterfaces.appLoad(BasePageMenu_base)

export default BasePageMenu;
