// import { useKeycloak } from "@react-keycloak/web";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import useFetch from "../../../../../hooks/useFetch";
import { useModalAlert } from "../../../../controls/modalAlert";
import useKeycloak from "../../../../../hooks/keycloak.module";
const regNotifContext = createContext({
    rgAction : {},
    rgState: {}
})

export function RegNotifProvider({ children }) {
    
    const { doGet , doPost } = useFetch();
    const { keycloak } = useKeycloak();
    const paramUrl = useParams();
    const history = useHistory();
    const location = useLocation();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [ otorDesc, setOtorDesc ] = useState("");
    const [ dataOtor, setDataOtor ] = useState({
        kode_entry:"",
        dbId:"",
        callBackSuccess:()=>null
    });
    const [ formEditable, setFormEditable ] = useState(false);
    const [ formData, setFormData] = useState({
            "id" : "1",
            "no_rek" : "",
            "nama_sub" : "",
            "no_hp":"",
            "flag":"",
            "min_tran_db":0,
            "min_tran_cr":0,
            "active":"F",
            "cif":""
    });

    const [ filterTRDB, setfilterTRDB] = useState(false)
    const [ filterTRCR, setfilterTRCR] = useState(false)

    function CekDb() {
        
        setfilterTRDB(true)  
        setfilterTRCR(false)  
    }

    function CekCr() {

        setfilterTRDB(false)  
        setfilterTRCR(true)  
    }

    function CekBOTH() {

        setfilterTRDB(true)  
        setfilterTRCR(true)  
    }

    function resetCEK() {
        
        setfilterTRCR(false)
        setfilterTRDB(false)
    }

    function resetFormDBCR(){   
        setFormData( prevState => 
            ({
            ...prevState,
            "min_tran_db":0,
            "min_tran_cr":0,
        }));
    }

    function resetForm(){   
        setFormData({
            "id" : "1",
            "no_rek" : "",
            "nama_sub" : "",
            "no_hp":"",
            "flag":"",
            "min_tran_db":0,
            "min_tran_cr":0,
            "active":"F",
            "cif":""
        })
    }

    useEffect(function(){
        if(paramUrl.mode === "registration") {
            setFormEditable(true)
            resetForm()
        } 
        else{
            if (location.state === undefined ) return setDataAlert({ show: true, body:'Tidak ada data terpilih' , title: '', header: "Error", callBack: () => history.goBack() });
            else {
                const { dbId = null,  kode_entry = ""} = location.state
                getDetil(dbId);
            }
        }
    },[paramUrl.mode])

    async function getDetil(pathParam){
        openLoading()

        try {
            let url = `/smsnotif/get_sms_notif_by_id/${pathParam}`;
            if (paramUrl.mode === "otorisasi") url = `/smsnotif/get_sms_notif_by_db_id?db_id=${pathParam}`;
            if (paramUrl.mode === "detil" || paramUrl.mode === "edit" ) url =`/smsnotif/get_sms_notif_by_id?id=${pathParam}`;
            setLoading(prevState => ({ ...prevState, loading: true }));
            const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url, service:"acc" });
            if (statusCode === 200) {
                if (status === "00") {
                    if (data !== null) {
                        if (paramUrl.mode === "otorisasi"){
                            // // console.log(data)
                            setFormData(p =>({
                                ...p,
                                "id" : data?.SMSNotif?.id_notif,
                                "no_rek" : data?.SMSNotif?.no_rek,
                                "nama_sub" : data?.SMSNotif?.nama_sub,
                                "no_hp":data?.SMSNotif?.no_hp,
                                "flag":data?.SMSNotif?.flag,
                                "min_tran_db":data?.SMSNotif?.min_tran_db,
                                "min_tran_cr":data?.SMSNotif?.min_tran_cr,
                                "active":data?.SMSNotif?.active,
                                "cif":data?.SMSNotif?.cif
                            }) )
                        }else{
                            if(paramUrl.mode === "edit"){
                                setFormEditable(true)
                            }
                            if (data.cr_min > 0 && data.db_min > 0 ) {
                                var flag = "BOTH-Hanya Transaksi Debit dan Kredit";
                            }else if (data.db_min > 0) {
                                var flag = "DB-Hanya Transaksi Debit";
                            }else if (data.cr_min > 0) {
                                var flag = "CR-Hanya Transaksi Kredit";
                            }
                            setFormData(p =>({
                                ...p,
                                "id" : data.id_notif,
                                "no_rek" : data.account_no,
                                "nama_sub" : data.name,
                                "no_hp":data.ch_sms,
                                "flag":flag,
                                "min_tran_db":data.db_min,
                                "min_tran_cr":data.cr_min,
                                "active":data.is_active,
                                "cif":data.cif
                            }) )
                        }
                    }

                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => history.goBack() });
            } else setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => history.goBack() });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => history.goBack() });
        }
        closeLoading();
    }

    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} nasabah ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: ()=> closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
       openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (dataOtor !== null && dataOtor !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.kode_entry}/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "acc",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({ show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => history.goBack() });
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });

        closeLoading()
        closeConfirmCustom();

    }

     // fungsi setDataForm
     function changeForm(value, key, subkey = null) {
        // value = value.toUpperCase()
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    function confirmRegis() {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => PostRegisNotif(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function PostRegisNotif() {    
        closeConfirm();
            // console.log(formData);
            const userInfo = await keycloak.loadUserInfo();
            let url = "/smsnotif/register_sms_notif";
            openLoading()
            try {
                const resp = await doPost({
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        // "x-user-id": userInfo.preferred_username
                    },
                    data: formData,
                    url, // ganti sesuai api
                    service: "acc" //ganti sesuai service yang dipakai di api
                });
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: "Registrasi Form Notifikasi SMS Berhasil. dibutuhkan otorisasi untuk melanjutkan", title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeConfirm();
                        }
                    });
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => resetForm() });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
            closeLoading()
    }

    function confirmUbah() {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => PutNotif(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function PutNotif() {    
        closeConfirm();
            // console.log(formData);
            const userInfo = await keycloak.loadUserInfo();
            let url = `/smsnotif/update_sms_notif_by_id?id_notif=${formData.id}`;
            
            openLoading()
            try {
                const resp = await doPost({
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        // "x-user-id": userInfo.preferred_username
                    },
                    data: formData,
                    url, // ganti sesuai api
                    service: "acc", //ganti sesuai service yang dipakai di api
                    method:"PUT"
                });
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: "Ubah Data Registrasi Form Notifikasi SMS Berhasil. dibutuhkan otorisasi untuk melanjutkan", title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeConfirm();
                            history.goBack();
                        }
                    });
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
            closeLoading()
    }

    
    async function cekNasabah(nomor_rekening, _type = 0) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/tabungan-giro/rek-for-trx/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {

                if (status === "00") {
                    const { nomor_rekening="",nama_lengkap="",nomor_nasabah="",telepon_hp_nomor="" } = data;
                    setFormData(f =>({
                        ...f,
                        no_rek:nomor_rekening,
                        nama_sub :nama_lengkap,
                        no_hp:telepon_hp_nomor,
                        cif:nomor_nasabah,
                    }))
                    
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }
//Post Auditrail
async function auditTrail() {
    const userInfo = await keycloak.loadUserInfo();
    // console.log(userInfo)
    var url = `/useraudit/user-audit-trail`;
    try {
        const { statusCode, status, remark, statusText, data = {} } = await doPost({
            url: url,
            service: "acc",
            data: { 
                    user_id : userInfo.preferred_username,
                    app_id : "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Registrasi",
                    event_description: "Tabungan & Giro/Registrasi Notifikasi",
                    info1: "-",
                    info2: "-"
            },
            headers: {
                "x-user-id": userInfo.preferred_username,
                method: "POST"
            }
        });

        if (statusCode === 200) {
            if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
            else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
        } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    } catch (error) {
        setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    }
    
}
    return <regNotifContext.Provider value={{
        rgAction : {
            changeForm,
            resetForm,
            responseOtor,
            PostRegisNotif,
            CekDb, CekCr, CekBOTH, 
            resetCEK,resetFormDBCR,
            setOtorDesc,
            cekNasabah,
            confirmUbah,
            confirmRegis,
            auditTrail
        },
        rgState : {
            formData,
            formEditable,
            filterTRDB,
            filterTRCR
        }
    }}>{children}</regNotifContext.Provider>
}

export default function useRegisNotif() { return useContext(regNotifContext) }