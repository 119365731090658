// import { useKeycloak } from "@react-keycloak/web";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import useFetch from "../../../../../hooks/useFetch";
import { useModalAlert } from "../../../../controls/modalAlert";
import useKeycloak from "../../../../../hooks/keycloak.module"
import { param } from "jquery";
const trxVerContext = createContext({
    verState: {}, verAction: {}
});

export const TrxVerProvider = ({ children }) => {
    const { doGet, doPost, getStatusBranch } = useFetch();
    const { keycloak } = useKeycloak();
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [dataList, setListData] = useState([])
    const [filter, setFilter] = useState({ keyword: '' })
    const [isLoading, setIsLoading] = useState(true)
    const { modalState, modalAction } = useModalAlert();
    const {

        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const { history } = useHistory();

    useEffect(function () {
        getDataList()
        // closeBranch()
        infoClose()
    }, [pagination])

    // useEffect(function () {
    //     closeBranch()
    // }, [])


    // "id_transaksi": "3107",
    // "id_transaksi_core": "76443540",
    // "jam_input": "00:00:00",
    // "user_input": "s1276",
    // "user_otor": "SYSTEM",
    // "keterangan": "",
    // "nomor_referensi": "s127620220107151119",
    // "kode_transaksi": "TT",
    // "jenis_transaksi": "Tarik Tunai",
    // "nilai_mutasi": "567800.0000",
    // "status_otorisasi": "Approve",
    // "status_reversal": "",
    // "is_boleh_cetak": null,
    // "is_verifikasi": null

    // getData rekening list
    async function getDataList() {
        // setIsLoading(true)
        openLoading()
        var url = `/verifikasi/list-verifikasi`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { show: pagination.show, page: pagination.page },
                service: "trx"

            });
            if (data === null ) {setListData([]);}
            if (statusCode === 200) {
                if (status === "00") {
                    setListData(data);
                }
                else if (remark !== "Success") setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        // setIsLoading(false)
        closeLoading()
    }

    function resetFilter() {
        setFilter({
            keyword: ''
        })
    }

    function nextPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    function confirmPost() {
        let body = `Apakah anda yakin ingin MemVerifikasi Transaksi ini. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => postListData(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    const [viewCheckBox, setViewCheckBox] = useState([])

    async function postListData() {
        // // console.log(dataList.status_otorisasi)
        // cekCloseBranch()
        
        if (viewCheckBox === null || viewCheckBox.length <= 0 ) setDataAlert({ show: true, body: `Belum ada data terpilih, PIlih data terlebih dahulu !`, title: ``, header: "Success", callBack: () => {
            // closeConfirm();
            setViewCheckBox([]);
            
            return setDataAlert({ show: true, body: `Belum ada data terpilih, PIlih data terlebih dahulu !`, titile: ``, header:`INFO`, callBack: () => null });
             
        }});
        // const userInfo = await keycloak.loadUserInfo();
        // setIsLoading(true)
        openLoading()
        var url = `/verifikasi/flag-verikasi-trx-to-core`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "trx",
                queryParam:{flag:"T"},
                data: viewCheckBox,
                // headers: {
                //     "x-user-id": userInfo.preferred_username,
                // },
                method: "PUT"
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Verifikasi Transaksi Berhasil`, title: ``, header: "Success", callBack: () => {
                    closeConfirm();
                    setViewCheckBox([]);
                    getDataList();
                }});
                else setDataAlert({ show: true, body: `Belum ada data terpilih, PIlih data terlebih dahulu !`, titile: ``, header:remark, callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    const [dataCloseBranch, setCloseBranch] = useState();

    function confirmCloseBranch() {
        let body = `Apakah anda yakin ingin MemVerifikasi Transaksi ini. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => closeBranch(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function getdatastatus(withLoading = false){
        let data = await getStatusBranch(withLoading);
        keycloak.setStatusBranch(data)
    }

    //Close Branch
    async function closeBranch() {
        setIsLoading(true)
        closeConfirm()
        const userInfo = await keycloak.loadUserInfo();
        if(keycloak.statusBranch.status_bds === "C"){
            setIsLoading(false)
            return setDataAlert({ show: true, body: "Status Cabang Saat Ini Sudah Close", titile: "", callBack: () => { closeConfirm(); reloadPage(); getdatastatus() } });
        }
        var url = `/verifikasi/verifkasi-close-branch`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "trx"
            });
            let { bds_data_otor_entry= 0, bds_data_otor_transaksi=0, core_data_otor_entry = 0, core_data_otor_transaksi=0, data_otor_verifikasi=[]  } = data;
            // let bds_data_otor_entry = data?.bds_data_otor_entry || 0;
            // let jumTransOtor = data?.Jumlah_Data_Transaksi_Belum_Otorisai || 0;
            // let jumVeriOtor = data?.Jumlah_Data_Transaksi_Belum_Verifikasi || 0;
            const styleObj = {fontSize: 11}
            let bodyAlert = (
                <div className="row">
                    <div className="col">
                        <p>Sebelum Menutup Cabang {userInfo.branch_code}, Harap Menyelesaikan Proses
                        Dengan Data Berikut :</p>
                        <ul>
                            <li> Data BDS Input Belum Otorisasi : <b>{bds_data_otor_entry}</b></li>
                            <li> Data BDS Transaksi Belum Otorisasi : <b> {bds_data_otor_transaksi} </b></li>
                            <li> Data CORE Input Belum Otorisasi : <b>{core_data_otor_entry}</b></li>
                            <li> Data CORE Transaksi Belum Otorisasi : <b> {core_data_otor_transaksi} </b></li>
                            {data_otor_verifikasi.length > 0 && <ul>
                                <strong>Data Verifikasi :</strong>
                                {
                                    data_otor_verifikasi.map(({channelname, count}, i) =>
                                        <li key={i}> <b>{channelname} : {count}</b></li>
                                    )
                                }
                            </ul>}
                        </ul>
                        <br />
                        <p>Silahkan Lakukan Proses Otorisasi dan Verifikasi</p>
                        <p style={styleObj}>Noted : Status <b>Waiting</b> tidak dapat di <b>Verifikasi</b>, Silahkan lakukan <b>Proses Otorisasi</b> terlebih dahulu !</p>
                    </div>
                </div>
            )
            if (statusCode === 200) {
                if (status === "00") {
                    setCloseBranch(data);
                    setDataAlert({ show: true, body: remark, titile: '', header:"Success", callBack: () => { closeConfirm(); reloadPage(); getStatusBranch(); } });
                }
                else if (status === "02") setDataAlert({ show: true, body: bodyAlert , header:"Informasi", titile: `Status fetch data is ${statusCode}`, callBack: () => null })
                else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    
    // //Close Branch
    // async function cekCloseBranch() {
    //     setIsLoading(true)
    //     var url = `/verifikasi/verifkasi-close-branch`;
    //     try {
    //         const { statusCode, status, remark, statusText, data = {} } = await doGet({
    //             url: url,
    //             service: "trx"

    //         });

    //         if (statusCode === 200) {
    //             if (status === "00") {
    //                 setCloseBranch(data);
    //             }
    //             else if (remark !== "Success"){};
    //         } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    //     } catch (error) {
    //         setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    //     }

    //     setIsLoading(false)
    // }

    //CEK verifikasi/info-branch?branch_code
    const [infoCloseCabang, setinfoCloseCabang] = useState();
    async function infoClose() {
        const userInfo = await keycloak.loadUserInfo();
        // console.log(userInfo.branch_code);
        setIsLoading(true)
        var url = `/verifikasi/info-branch?branch_code`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "trx",
                param: { branch_code: userInfo.branch_code},
                // branch_code?branch_code=536
            });

            if (statusCode === 200) {
                if (status === "00") {
                    // // console.log(data);
                    setinfoCloseCabang(data.status_bds);
                }
                else if (remark !== "Success"){};
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    function reloadPage() {
        setViewCheckBox([]);
        getDataList();
    }
    
    return <trxVerContext.Provider
        value={{
            verAction: {
                resetFilter,
                nextPage,
                prevPage,
                changeShow,
                setFilter,
                setViewCheckBox,
                postListData,
                getDataList,
                confirmPost,
                closeBranch,
                confirmCloseBranch,
                reloadPage
            },
            verState: {
                pagination,
                dataList,
                filter,
                viewCheckBox,
                dataCloseBranch,
                infoCloseCabang
            }
        }}
    >{children}</trxVerContext.Provider>;
}
export default function useTrxVer() { return useContext(trxVerContext) };