import { Breadcrumb } from 'semantic-ui-react';
import { RegistrasiRekeningCard } from './card';
import { CardMenuRegistrasi } from '../../controls';

const RegistrasiRekeningPage = () => {
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Section className="text-primary-bds" link>
                    Tabungan & Giro
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Registrasi Rekening</Breadcrumb.Section>
            </Breadcrumb>
            <div
                style={{ paddingBottom: 200 }}
                className="bg-white px-4 pt-4 mt-4 rounded-lg shadow-sm"
            >
                <h1 className="f-18 text-gray-2 text-center mb-5">Pilih Jenis Rekening</h1>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 24 }}>
                    <CardMenuRegistrasi
                        heading="Tabungan"
                        // description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quae pariatur alias sint eius consequatur minima officia cum provident, molestiae, repellat molestias quam. Labore, minima expedita unde laborum deserunt ut!"
                        href="/tabungan-giro/form/tabungan/registration"
                        // /tabungan-giro/form-registrasi/:type/:mode
                        // - typenya ada 3 (tabungan, giro, giro-escrow)
                        // - mode (otorisasi, edit, registration, detail)
                        icon={
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                <g opacity="0.66" filter="url(#filter0_f)">
                                <path d="M12 22.6667C12 21.429 12.5795 20.242 13.6109 19.3668C14.6424 18.4917 16.0413 18 17.5 18H50.5C51.9587 18 53.3576 18.4917 54.3891 19.3668C55.4205 20.242 56 21.429 56 22.6667V25H12V22.6667Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 32.0001V46.2858C12 47.8014 12.5795 49.2548 13.6109 50.3264C14.6424 51.3981 16.0413 52.0001 17.5 52.0001H50.5C51.9587 52.0001 53.3576 51.3981 54.3891 50.3264C55.4205 49.2548 56 47.8014 56 46.2858V32.0001H12ZM20.25 37.7144C19.5207 37.7144 18.8212 38.0154 18.3055 38.5512C17.7897 39.0871 17.5 39.8138 17.5 40.5716V43.4287C17.5 44.1865 17.7897 44.9132 18.3055 45.449C18.8212 45.9848 19.5207 46.2858 20.25 46.2858H23C23.7293 46.2858 24.4288 45.9848 24.9445 45.449C25.4603 44.9132 25.75 44.1865 25.75 43.4287V40.5716C25.75 39.8138 25.4603 39.0871 24.9445 38.5512C24.4288 38.0154 23.7293 37.7144 23 37.7144H20.25Z" fill="#004bbc"/>
                                <path d="M51 39C46.5819 39 43 42.5819 43 47C43 51.4181 46.5819 55 51 55C55.4181 55 59 51.4181 59 47C59 42.5819 55.4181 39 51 39ZM54.6923 48.2308H51C50.8368 48.2308 50.6803 48.1659 50.5649 48.0505C50.4495 47.9351 50.3846 47.7786 50.3846 47.6154V42.0769C50.3846 41.9137 50.4495 41.7572 50.5649 41.6418C50.6803 41.5264 50.8368 41.4615 51 41.4615C51.1632 41.4615 51.3197 41.5264 51.4351 41.6418C51.5506 41.7572 51.6154 41.9137 51.6154 42.0769V47H54.6923C54.8555 47 55.012 47.0648 55.1275 47.1802C55.2429 47.2956 55.3077 47.4522 55.3077 47.6154C55.3077 47.7786 55.2429 47.9351 55.1275 48.0505C55.012 48.1659 54.8555 48.2308 54.6923 48.2308Z" fill="#004bbc"/>
                                </g>
                                <path d="M12 22.6667C12 21.429 12.5795 20.242 13.6109 19.3668C14.6424 18.4917 16.0413 18 17.5 18H50.5C51.9587 18 53.3576 18.4917 54.3891 19.3668C55.4205 20.242 56 21.429 56 22.6667V25H12V22.6667Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 32.0001V46.2858C12 47.8014 12.5795 49.2548 13.6109 50.3264C14.6424 51.3981 16.0413 52.0001 17.5 52.0001H50.5C51.9587 52.0001 53.3576 51.3981 54.3891 50.3264C55.4205 49.2548 56 47.8014 56 46.2858V32.0001H12ZM20.25 37.7144C19.5207 37.7144 18.8212 38.0154 18.3055 38.5512C17.7897 39.0871 17.5 39.8138 17.5 40.5716V43.4287C17.5 44.1865 17.7897 44.9132 18.3055 45.449C18.8212 45.9848 19.5207 46.2858 20.25 46.2858H23C23.7293 46.2858 24.4288 45.9848 24.9445 45.449C25.4603 44.9132 25.75 44.1865 25.75 43.4287V40.5716C25.75 39.8138 25.4603 39.0871 24.9445 38.5512C24.4288 38.0154 23.7293 37.7144 23 37.7144H20.25Z" fill="#004bbc"/>
                                </g>
                                <defs>
                                <filter id="filter0_f" x="-2" y="4" width="75" height="65" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="7" result="effect1_foregroundBlur"/>
                                </filter>
                                <clipPath id="clip0">
                                <rect width="72" height="72" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        }
                    />
                    <CardMenuRegistrasi
                        heading="Giro"
                        // description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quae pariatur alias sint eius consequatur minima officia cum provident, molestiae, repellat molestias quam. Labore, minima expedita unde laborum deserunt ut!"
                        href="/tabungan-giro/form/giro/registration"
                        icon={
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                <g opacity="0.66" filter="url(#filter0_f)">
                                <path d="M12.998 22.6667C12.998 21.429 13.5775 20.242 14.609 19.3668C15.6404 18.4917 17.0394 18 18.498 18H51.498C52.9567 18 54.3557 18.4917 55.3871 19.3668C56.4186 20.242 56.998 21.429 56.998 22.6667V25H12.998V22.6667Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.998 32.0001V46.2858C12.998 47.8014 13.5775 49.2548 14.609 50.3264C15.6404 51.3981 17.0394 52.0001 18.498 52.0001H51.498C52.9567 52.0001 54.3557 51.3981 55.3871 50.3264C56.4186 49.2548 56.998 47.8014 56.998 46.2858V32.0001H12.998ZM21.248 37.7144C20.5187 37.7144 19.8192 38.0154 19.3035 38.5512C18.7878 39.0871 18.498 39.8138 18.498 40.5716V43.4287C18.498 44.1865 18.7878 44.9132 19.3035 45.449C19.8192 45.9848 20.5187 46.2858 21.248 46.2858H23.998C24.7274 46.2858 25.4269 45.9848 25.9426 45.449C26.4583 44.9132 26.748 44.1865 26.748 43.4287V40.5716C26.748 39.8138 26.4583 39.0871 25.9426 38.5512C25.4269 38.0154 24.7274 37.7144 23.998 37.7144H21.248Z" fill="#004bbc"/>
                                <path d="M54.1507 31.5664C51.5549 30.811 48.7257 30.811 46.1299 31.5664C45.6944 31.6924 45.4864 32.1982 45.7207 32.5978L46.7843 34.5019C48.6337 33.766 51.3768 33.6561 53.4961 34.5022L54.5599 32.5978C54.7941 32.1982 54.5862 31.6925 54.1507 31.5664Z" fill="#004bbc"/>
                                <path d="M46.736 44.1874V45.9488H47.5712C48.2757 45.9488 48.6195 45.659 48.6195 45.0823C48.6195 44.5028 48.2757 44.1874 47.5655 44.1874H46.736Z" fill="#004bbc"/>
                                <path d="M52.8354 45.5426C52.2473 45.5426 51.9178 46.0397 51.9178 46.8181C51.9178 47.5965 52.253 48.1079 52.8354 48.1079C53.4292 48.1079 53.753 47.5795 53.753 46.8181C53.753 46.0624 53.4348 45.5426 52.8354 45.5426Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.1414 38.1234C44.9925 38.1234 41 43.7935 41 48.6712C41 54.344 46.2176 54.9998 50.1414 54.9998C54.0652 54.9998 59.2828 54.3441 59.2828 48.6712C59.2828 43.7935 55.2903 38.1234 50.1414 38.1234ZM46.736 48.9999H45.5059V43.1818H47.8013C49.1223 43.1818 49.8865 43.9261 49.8865 45.0823C49.8865 45.8778 49.5201 46.4545 48.8581 46.7386L50.0939 48.9999H48.736L47.6337 46.9374H46.736V48.9999ZM51.9434 50.6363H50.7331V44.6363H51.9263V45.3693H51.9803C52.1422 45.0141 52.4917 44.5795 53.2218 44.5795C54.1792 44.5795 54.9888 45.3238 54.9888 46.8238C54.9888 48.284 54.2132 49.071 53.2189 49.071C52.5144 49.071 52.1479 48.6647 51.9803 48.3011H51.9434V50.6363Z" fill="#004bbc"/>
                                <path d="M46.8687 36.0388C48.2315 35.0689 52.091 35.1076 53.4067 36.0345C54.0202 36.4672 54.3101 37.124 54.3042 37.7762C53.0406 37.1068 51.6385 36.7171 50.1418 36.7171C48.9129 36.7171 47.3296 37.0609 45.9794 37.7762C45.9733 37.0993 46.2844 36.4513 46.8687 36.0388Z" fill="#004bbc"/>
                                </g>
                                <path d="M12.998 22.6667C12.998 21.429 13.5775 20.242 14.609 19.3668C15.6404 18.4917 17.0394 18 18.498 18H51.498C52.9567 18 54.3557 18.4917 55.3871 19.3668C56.4186 20.242 56.998 21.429 56.998 22.6667V25H12.998V22.6667Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.998 32.0001V46.2858C12.998 47.8014 13.5775 49.2548 14.609 50.3264C15.6404 51.3981 17.0394 52.0001 18.498 52.0001H51.498C52.9567 52.0001 54.3557 51.3981 55.3871 50.3264C56.4186 49.2548 56.998 47.8014 56.998 46.2858V32.0001H12.998ZM21.248 37.7144C20.5187 37.7144 19.8192 38.0154 19.3035 38.5512C18.7878 39.0871 18.498 39.8138 18.498 40.5716V43.4287C18.498 44.1865 18.7878 44.9132 19.3035 45.449C19.8192 45.9848 20.5187 46.2858 21.248 46.2858H23.998C24.7274 46.2858 25.4269 45.9848 25.9426 45.449C26.4583 44.9132 26.748 44.1865 26.748 43.4287V40.5716C26.748 39.8138 26.4583 39.0871 25.9426 38.5512C25.4269 38.0154 24.7274 37.7144 23.998 37.7144H21.248Z" fill="#004bbc"/>
                                <path d="M54.1507 31.5664C51.5549 30.811 48.7257 30.811 46.1299 31.5664C45.6944 31.6924 45.4864 32.1982 45.7207 32.5978L46.7843 34.5019C48.6337 33.766 51.3768 33.6561 53.4961 34.5022L54.5599 32.5978C54.7941 32.1982 54.5862 31.6925 54.1507 31.5664Z" fill="#00bbef"/>
                                <path d="M46.736 44.1874V45.9488H47.5712C48.2757 45.9488 48.6195 45.659 48.6195 45.0823C48.6195 44.5028 48.2757 44.1874 47.5655 44.1874H46.736Z" fill="#00bbef"/>
                                <path d="M52.8354 45.5426C52.2473 45.5426 51.9178 46.0397 51.9178 46.8181C51.9178 47.5965 52.253 48.1079 52.8354 48.1079C53.4292 48.1079 53.753 47.5795 53.753 46.8181C53.753 46.0624 53.4348 45.5426 52.8354 45.5426Z" fill="#00bbef"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.1414 38.1234C44.9925 38.1234 41 43.7935 41 48.6712C41 54.344 46.2176 54.9998 50.1414 54.9998C54.0652 54.9998 59.2828 54.3441 59.2828 48.6712C59.2828 43.7935 55.2903 38.1234 50.1414 38.1234ZM46.736 48.9999H45.5059V43.1818H47.8013C49.1223 43.1818 49.8865 43.9261 49.8865 45.0823C49.8865 45.8778 49.5201 46.4545 48.8581 46.7386L50.0939 48.9999H48.736L47.6337 46.9374H46.736V48.9999ZM51.9434 50.6363H50.7331V44.6363H51.9263V45.3693H51.9803C52.1422 45.0141 52.4917 44.5795 53.2218 44.5795C54.1792 44.5795 54.9888 45.3238 54.9888 46.8238C54.9888 48.284 54.2132 49.071 53.2189 49.071C52.5144 49.071 52.1479 48.6647 51.9803 48.3011H51.9434V50.6363Z" fill="#00bbef"/>
                                <path d="M46.8687 36.0388C48.2315 35.0689 52.091 35.1076 53.4067 36.0345C54.0202 36.4672 54.3101 37.124 54.3042 37.7762C53.0406 37.1068 51.6385 36.7171 50.1418 36.7171C48.9129 36.7171 47.3296 37.0609 45.9794 37.7762C45.9733 37.0993 46.2844 36.4513 46.8687 36.0388Z" fill="#00bbef"/>
                                </g>
                                <defs>
                                <filter id="filter0_f" x="-1.00195" y="4" width="74.2848" height="64.9998" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="7" result="effect1_foregroundBlur"/>
                                </filter>
                                <clipPath id="clip0">
                                <rect width="72" height="72" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>                                             
                        }
                    />
                    <CardMenuRegistrasi
                        heading="Tabungan Rencana"
                        // description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quae pariatur alias sint eius consequatur minima officia cum provident, molestiae, repellat molestias quam. Labore, minima expedita unde laborum deserunt ut!"
                        href="/tabungan-giro/form-tabungan-rencana/registration"
                        icon={
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                <g opacity="0.66" filter="url(#filter0_f)">
                                <path d="M12 22.6667C12 21.429 12.5795 20.242 13.6109 19.3668C14.6424 18.4917 16.0413 18 17.5 18H50.5C51.9587 18 53.3576 18.4917 54.3891 19.3668C55.4205 20.242 56 21.429 56 22.6667V25H12V22.6667Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 32.0001V46.2858C12 47.8014 12.5795 49.2548 13.6109 50.3264C14.6424 51.3981 16.0413 52.0001 17.5 52.0001H50.5C51.9587 52.0001 53.3576 51.3981 54.3891 50.3264C55.4205 49.2548 56 47.8014 56 46.2858V32.0001H12ZM20.25 37.7144C19.5207 37.7144 18.8212 38.0154 18.3055 38.5512C17.7897 39.0871 17.5 39.8138 17.5 40.5716V43.4287C17.5 44.1865 17.7897 44.9132 18.3055 45.449C18.8212 45.9848 19.5207 46.2858 20.25 46.2858H23C23.7293 46.2858 24.4288 45.9848 24.9445 45.449C25.4603 44.9132 25.75 44.1865 25.75 43.4287V40.5716C25.75 39.8138 25.4603 39.0871 24.9445 38.5512C24.4288 38.0154 23.7293 37.7144 23 37.7144H20.25Z" fill="#004bbc"/>
                                <path d="M51 39C46.5819 39 43 42.5819 43 47C43 51.4181 46.5819 55 51 55C55.4181 55 59 51.4181 59 47C59 42.5819 55.4181 39 51 39ZM54.6923 48.2308H51C50.8368 48.2308 50.6803 48.1659 50.5649 48.0505C50.4495 47.9351 50.3846 47.7786 50.3846 47.6154V42.0769C50.3846 41.9137 50.4495 41.7572 50.5649 41.6418C50.6803 41.5264 50.8368 41.4615 51 41.4615C51.1632 41.4615 51.3197 41.5264 51.4351 41.6418C51.5506 41.7572 51.6154 41.9137 51.6154 42.0769V47H54.6923C54.8555 47 55.012 47.0648 55.1275 47.1802C55.2429 47.2956 55.3077 47.4522 55.3077 47.6154C55.3077 47.7786 55.2429 47.9351 55.1275 48.0505C55.012 48.1659 54.8555 48.2308 54.6923 48.2308Z" fill="#004bbc"/>
                                </g>
                                <path d="M12 22.6667C12 21.429 12.5795 20.242 13.6109 19.3668C14.6424 18.4917 16.0413 18 17.5 18H50.5C51.9587 18 53.3576 18.4917 54.3891 19.3668C55.4205 20.242 56 21.429 56 22.6667V25H12V22.6667Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 32.0001V46.2858C12 47.8014 12.5795 49.2548 13.6109 50.3264C14.6424 51.3981 16.0413 52.0001 17.5 52.0001H50.5C51.9587 52.0001 53.3576 51.3981 54.3891 50.3264C55.4205 49.2548 56 47.8014 56 46.2858V32.0001H12ZM20.25 37.7144C19.5207 37.7144 18.8212 38.0154 18.3055 38.5512C17.7897 39.0871 17.5 39.8138 17.5 40.5716V43.4287C17.5 44.1865 17.7897 44.9132 18.3055 45.449C18.8212 45.9848 19.5207 46.2858 20.25 46.2858H23C23.7293 46.2858 24.4288 45.9848 24.9445 45.449C25.4603 44.9132 25.75 44.1865 25.75 43.4287V40.5716C25.75 39.8138 25.4603 39.0871 24.9445 38.5512C24.4288 38.0154 23.7293 37.7144 23 37.7144H20.25Z" fill="#004bbc"/>
                                <path d="M51 39C46.5819 39 43 42.5819 43 47C43 51.4181 46.5819 55 51 55C55.4181 55 59 51.4181 59 47C59 42.5819 55.4181 39 51 39ZM54.6923 48.2308H51C50.8368 48.2308 50.6803 48.1659 50.5649 48.0505C50.4495 47.9351 50.3846 47.7786 50.3846 47.6154V42.0769C50.3846 41.9137 50.4495 41.7572 50.5649 41.6418C50.6803 41.5264 50.8368 41.4615 51 41.4615C51.1632 41.4615 51.3197 41.5264 51.4351 41.6418C51.5506 41.7572 51.6154 41.9137 51.6154 42.0769V47H54.6923C54.8555 47 55.012 47.0648 55.1275 47.1802C55.2429 47.2956 55.3077 47.4522 55.3077 47.6154C55.3077 47.7786 55.2429 47.9351 55.1275 48.0505C55.012 48.1659 54.8555 48.2308 54.6923 48.2308Z" fill="#00bbef"/>
                                </g>
                                <defs>
                                <filter id="filter0_f" x="-2" y="4" width="75" height="65" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="7" result="effect1_foregroundBlur"/>
                                </filter>
                                <clipPath id="clip0">
                                <rect width="72" height="72" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>                            
                        }
                    />
                    <CardMenuRegistrasi
                        heading="Giro Escrow"
                        // description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quae pariatur alias sint eius consequatur minima officia cum provident, molestiae, repellat molestias quam. Labore, minima expedita unde laborum deserunt ut!"
                        href="/tabungan-giro/form/giro-escrow/registration"
                        icon={
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                <g opacity="0.66" filter="url(#filter0_f)">
                                <path d="M12.998 22.6667C12.998 21.429 13.5775 20.242 14.609 19.3668C15.6404 18.4917 17.0394 18 18.498 18H51.498C52.9567 18 54.3557 18.4917 55.3871 19.3668C56.4186 20.242 56.998 21.429 56.998 22.6667V25H12.998V22.6667Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.998 32.0001V46.2858C12.998 47.8014 13.5775 49.2548 14.609 50.3264C15.6404 51.3981 17.0394 52.0001 18.498 52.0001H51.498C52.9567 52.0001 54.3557 51.3981 55.3871 50.3264C56.4186 49.2548 56.998 47.8014 56.998 46.2858V32.0001H12.998ZM21.248 37.7144C20.5187 37.7144 19.8192 38.0154 19.3035 38.5512C18.7878 39.0871 18.498 39.8138 18.498 40.5716V43.4287C18.498 44.1865 18.7878 44.9132 19.3035 45.449C19.8192 45.9848 20.5187 46.2858 21.248 46.2858H23.998C24.7274 46.2858 25.4269 45.9848 25.9426 45.449C26.4583 44.9132 26.748 44.1865 26.748 43.4287V40.5716C26.748 39.8138 26.4583 39.0871 25.9426 38.5512C25.4269 38.0154 24.7274 37.7144 23.998 37.7144H21.248Z" fill="#004bbc"/>
                                <path d="M54.1507 31.5664C51.5549 30.811 48.7257 30.811 46.1299 31.5664C45.6944 31.6924 45.4864 32.1982 45.7207 32.5978L46.7843 34.5019C48.6337 33.766 51.3768 33.6561 53.4961 34.5022L54.5599 32.5978C54.7941 32.1982 54.5862 31.6925 54.1507 31.5664Z" fill="#004bbc"/>
                                <path d="M46.736 44.1874V45.9488H47.5712C48.2757 45.9488 48.6195 45.659 48.6195 45.0823C48.6195 44.5028 48.2757 44.1874 47.5655 44.1874H46.736Z" fill="#004bbc"/>
                                <path d="M52.8354 45.5426C52.2473 45.5426 51.9178 46.0397 51.9178 46.8181C51.9178 47.5965 52.253 48.1079 52.8354 48.1079C53.4292 48.1079 53.753 47.5795 53.753 46.8181C53.753 46.0624 53.4348 45.5426 52.8354 45.5426Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.1414 38.1234C44.9925 38.1234 41 43.7935 41 48.6712C41 54.344 46.2176 54.9998 50.1414 54.9998C54.0652 54.9998 59.2828 54.3441 59.2828 48.6712C59.2828 43.7935 55.2903 38.1234 50.1414 38.1234ZM46.736 48.9999H45.5059V43.1818H47.8013C49.1223 43.1818 49.8865 43.9261 49.8865 45.0823C49.8865 45.8778 49.5201 46.4545 48.8581 46.7386L50.0939 48.9999H48.736L47.6337 46.9374H46.736V48.9999ZM51.9434 50.6363H50.7331V44.6363H51.9263V45.3693H51.9803C52.1422 45.0141 52.4917 44.5795 53.2218 44.5795C54.1792 44.5795 54.9888 45.3238 54.9888 46.8238C54.9888 48.284 54.2132 49.071 53.2189 49.071C52.5144 49.071 52.1479 48.6647 51.9803 48.3011H51.9434V50.6363Z" fill="#004bbc"/>
                                <path d="M46.8687 36.0388C48.2315 35.0689 52.091 35.1076 53.4067 36.0345C54.0202 36.4672 54.3101 37.124 54.3042 37.7762C53.0406 37.1068 51.6385 36.7171 50.1418 36.7171C48.9129 36.7171 47.3296 37.0609 45.9794 37.7762C45.9733 37.0993 46.2844 36.4513 46.8687 36.0388Z" fill="#004bbc"/>
                                </g>
                                <path d="M12.998 22.6667C12.998 21.429 13.5775 20.242 14.609 19.3668C15.6404 18.4917 17.0394 18 18.498 18H51.498C52.9567 18 54.3557 18.4917 55.3871 19.3668C56.4186 20.242 56.998 21.429 56.998 22.6667V25H12.998V22.6667Z" fill="#004bbc"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.998 32.0001V46.2858C12.998 47.8014 13.5775 49.2548 14.609 50.3264C15.6404 51.3981 17.0394 52.0001 18.498 52.0001H51.498C52.9567 52.0001 54.3557 51.3981 55.3871 50.3264C56.4186 49.2548 56.998 47.8014 56.998 46.2858V32.0001H12.998ZM21.248 37.7144C20.5187 37.7144 19.8192 38.0154 19.3035 38.5512C18.7878 39.0871 18.498 39.8138 18.498 40.5716V43.4287C18.498 44.1865 18.7878 44.9132 19.3035 45.449C19.8192 45.9848 20.5187 46.2858 21.248 46.2858H23.998C24.7274 46.2858 25.4269 45.9848 25.9426 45.449C26.4583 44.9132 26.748 44.1865 26.748 43.4287V40.5716C26.748 39.8138 26.4583 39.0871 25.9426 38.5512C25.4269 38.0154 24.7274 37.7144 23.998 37.7144H21.248Z" fill="#004bbc"/>
                                <path d="M54.1507 31.5664C51.5549 30.811 48.7257 30.811 46.1299 31.5664C45.6944 31.6924 45.4864 32.1982 45.7207 32.5978L46.7843 34.5019C48.6337 33.766 51.3768 33.6561 53.4961 34.5022L54.5599 32.5978C54.7941 32.1982 54.5862 31.6925 54.1507 31.5664Z" fill="#00bbef"/>
                                <path d="M46.736 44.1874V45.9488H47.5712C48.2757 45.9488 48.6195 45.659 48.6195 45.0823C48.6195 44.5028 48.2757 44.1874 47.5655 44.1874H46.736Z" fill="#00bbef"/>
                                <path d="M52.8354 45.5426C52.2473 45.5426 51.9178 46.0397 51.9178 46.8181C51.9178 47.5965 52.253 48.1079 52.8354 48.1079C53.4292 48.1079 53.753 47.5795 53.753 46.8181C53.753 46.0624 53.4348 45.5426 52.8354 45.5426Z" fill="#00bbef"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.1414 38.1234C44.9925 38.1234 41 43.7935 41 48.6712C41 54.344 46.2176 54.9998 50.1414 54.9998C54.0652 54.9998 59.2828 54.3441 59.2828 48.6712C59.2828 43.7935 55.2903 38.1234 50.1414 38.1234ZM46.736 48.9999H45.5059V43.1818H47.8013C49.1223 43.1818 49.8865 43.9261 49.8865 45.0823C49.8865 45.8778 49.5201 46.4545 48.8581 46.7386L50.0939 48.9999H48.736L47.6337 46.9374H46.736V48.9999ZM51.9434 50.6363H50.7331V44.6363H51.9263V45.3693H51.9803C52.1422 45.0141 52.4917 44.5795 53.2218 44.5795C54.1792 44.5795 54.9888 45.3238 54.9888 46.8238C54.9888 48.284 54.2132 49.071 53.2189 49.071C52.5144 49.071 52.1479 48.6647 51.9803 48.3011H51.9434V50.6363Z" fill="#00bbef"/>
                                <path d="M46.8687 36.0388C48.2315 35.0689 52.091 35.1076 53.4067 36.0345C54.0202 36.4672 54.3101 37.124 54.3042 37.7762C53.0406 37.1068 51.6385 36.7171 50.1418 36.7171C48.9129 36.7171 47.3296 37.0609 45.9794 37.7762C45.9733 37.0993 46.2844 36.4513 46.8687 36.0388Z" fill="#00bbef"/>
                                </g>
                                <defs>
                                <filter id="filter0_f" x="-1.00195" y="4" width="74.2848" height="64.9998" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="7" result="effect1_foregroundBlur"/>
                                </filter>
                                <clipPath id="clip0">
                                <rect width="72" height="72" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>                          
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default RegistrasiRekeningPage;



// import React, { memo, useEffect, useRef, useState } from 'react';
// import { useHistory } from 'react-router';
// import { AppAction, AppInterfaces } from '../../../app.data';
// import { Stepper } from '../../controls';
// import Step1 from './step1';
// import Step2 from './step2';




// export default AppInterfaces.appLoad(memo(function ({ store, ...props }) {
//     const { formData } = store;
//     const stepperReff = useRef(null)
//     const appAction = useRef(null)
//     const history = useHistory();
//     const [step, setStep] = useState(0);
//     const [statusDone, setStatusDone] = useState({
//         show: false,
//         msg: ""
//     })
//     useEffect(() => {
//         resetForm()
//     }, [])
//     const [err, setErr] = useState({ msg: 'Lengkapi form registrasi', err: true })
//     const [errDataDeposito, setErrDataDeposito] = useState({ msg: 'Lengkapi form data deposito', err: true })
    
//     const fieldRequired = ["nomorCIF", "statusRestriksi"]
//     const fieldRequiredNumber = ["limitNominalSetorTunai","frekuensiSetorTunai", "limitNominalSetorNonTunai", "frekuensiSetorNonTunai"]
//     useEffect(function(){
//         const keys = Object.keys(formData)
//         var errS = false;
//         var errSDD = false;
//         keys.forEach(function(key){
//             if(formData[key] instanceof Object){
//                 const tKey = Object.keys(formData[key]);
//                 tKey.forEach(element => {
//                     if(fieldRequired.some(v=>element === v)){
//                         if(formData[key][element] === "" || !formData[key][element]=== undefined || !formData[key][element]=== null ){
//                             errS = true
//                         }
//                     }
//                     if(fieldRequiredNumber.some(v=>element === v)){
//                         if(formData[key][element] === 0 || !formData[key][element]=== undefined || !formData[key][element]=== null ){
//                             errS = true
//                         }
//                     }
//                 });
//             }else{
//                 if(fieldRequired.some(v=>v === key)){
//                     if(formData[key] === "" || !formData[key]=== undefined || !formData[key]=== null ){
//                         errS = true
//                     }
//                 }
//             }
//         })
//         setErr(prevState => ({...prevState, err:errS}))
//         setErrDataDeposito(prevState => ({ ...prevState, errDataDeposito: errSDD }))
//     }, [formData])

//     const resetForm = () => appAction.current.setDefaultForm({
//         "nomorRekeningDeposito": "",
//         "kunciRekeningDeposito": "",
//         "name": "",
//         "kunciCabang": "",
//         "_dataNasabah": {
//             "nomorCIF": "",
//             "nama": "",
//             "teleponRumah": "",
//             "teleponHP": "",
//             "namaIbuKandung": "",
//             "tempatLahir": "",
//             "tanggalLahir": "",
//             "alamat": "",
//             "jenisIdentias": "",
//             "nomorIdentitas": "",
//             "nomorKunciAkunNasabah": ""
//         },
//         "_rekening": {
//             "nomorRekeningDebet": "",
//             "namaRekening": "",
//             "nomorBilyet": "",
//             "tanggalBuka": "",
//             "tanggalJatuhTempo": "",
//             "tanggalBagiHasil": "",
//             "produk": "",
//             "valuta": "IDR",
//             "nominalDeposito": "",
//             "keterangan": "",
//             "nomorProduk": "",
//             "tipeAkun": "",
//             "tipeAkunNasabah": "CLIENT",
//             "sudahDisetor": false,
//             "sedangDicairkan": false,
//             "limitNominalSetorTunai": 0,
//             "frekuensiSetorTunai": 0,
//             "limitNominalSetorNonTunai": 0,
//             "frekuensiSetorNonTunai": 0,
//         },
//         "_bagiHasilDisposisi": {
//             "nisbah": 0,
//             "zakatBagiHasil": 0,
//             "pajakBagiHasil": 0,
//             "disposisiPokok": "",
//             "instruksiDisposisi": "",
//             "nomorRekening": "",
//             "namaRekening": "",
//             "nomorNama": ""
//         },
//         "_informasiLain": {
//             "statusRestriksi": "Tidak",
//             "tujuanBukaRekening": "",
//             "sumberDana": "",
//             "statusKelengkapan": "",
//             "marketing": "",
//         },
//         "_otherInformation": {
//             "fiturTambahan": ""
//         },
//         "_asuransi": {
//             "tanggalJatuhTempoAsuransi": "",
//             "rencanaAsuransiUtama": "",
//             "rencanaAsuransiTambahan": ""
//         },
//         "_alamatAlternatif": {
//             "alamat": "",
//             "provinsi": "",
//             "kotaKabupaten": "",
//             "kecamatan": "",
//             "kelurahan": "",
//             "rt": "",
//             "rw": "",
//             "kodePos": "",
//             "telepon": ""
//         },
//     })
//     return (
//         <>
//             <AppAction ref={appAction} />
//             <nav aria-label="breadcrumb" className="mb-3">
//                 <ol className="breadcrumb">
//                     <li className="breadcrumb-item"><a href="#">Deposito</a></li>
//                     <li className="breadcrumb-item active" aria-current="page">Registrasi Deposito</li>
//                 </ol>
//             </nav>
//             <div className="card">
//                 <div className="card-header">
//                     <div className="row">
//                         <div className="col p-3">
//                             <div style={{ width: "200px", margin: "auto" }}>
//                                 <Stepper
//                                     activeIdx={step}
//                                     manualDone={false}
//                                     // onChange = {(_activeIdx, _prevIdx) => {
//                                     //     setStep(_activeIdx)
//                                     // }}
//                                     ref={stepperReff}
//                                     onStepClick={(_idx) => {
//                                         const {nomorCIF} = formData._dataNasabah
//                                         const {tanggalBuka} = formData._rekening
//                                         const {disposisiPokok} = formData._bagiHasilDisposisi
//                                         nomorCIF === '' || tanggalBuka === '' || disposisiPokok === '' ?
//                                         // console.log('') : setStep(_idx)
//                                     }}
//                                     steps={[
//                                         {
//                                             name: 'Data Deposito',
//                                             id: '0',
//                                             whiteSpace: 'nowrap',
//                                             isDone: false,
//                                         },
//                                         {
//                                             name: 'Informasi Lain',
//                                             id: '1',
//                                             whiteSpace: 'nowrap',
//                                             isDone: false,
//                                         },
//                                     ]}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="card-body">
//                     {
//                         step === 0 ? <Step1 mode={0} /> : <Step2 mode={0} />
//                     }

//                 </div>
//                 <div className="card-footer">
//                     <button className="btn btn-sm btn-outline-danger" onClick={resetForm}>
//                         Reset
//                     </button>
//                     <div className="float-right">
//                         <button disabled={step === 0} onClick={() => setStep(prevStep => prevStep - 1)} className="btn btn-sm btn-secondary mr-3">
//                             Sebelumnya
//                         </button>
//                         <button 
//                             disabled={step === 1} 
//                             onClick={() => {
//                                 const {nomorCIF} = formData._dataNasabah
//                                 const {tanggalBuka} = formData._rekening
//                                 const {disposisiPokok} = formData._bagiHasilDisposisi
//                                 nomorCIF === '' || tanggalBuka === '' || disposisiPokok === '' ?
//                                 appAction.current.setShowModal({
//                                     show: true,
//                                     size: 'sm',
//                                     backdrop: 'static',
//                                     header: <h4>Warning</h4>,
//                                     body: <>
//                                         <div className="row">
//                                             <div className="col-md-12 text-center">
//                                                 {errDataDeposito.msg}
//                                             </div>
//                                         </div>
//                                     </>,
//                                     footer: (
//                                         <>
//                                             <button onClick={() => {
//                                                 appAction.current.setCloseModal()
//                                             }} className="btn btn-sm btn-secondary">
//                                                 close
//                                             </button>
//                                         </>
//                                     )
//                                 }) :
//                                 setStep(prevStep => prevStep + 1)
//                             }}
//                             className="btn btn-sm btn-danger mr-3"
//                         >
//                             Selanjutnya
//                         </button>
//                         {
//                             step === 1 && (
//                                 <button onClick={

//                                     () => appAction.current.setShowModal({
//                                         show: true,
//                                         size: 'sm',
//                                         backdrop: 'static',
//                                         header: <h4>{statusDone.show ? "Selesai" : "Konfirmasi"}</h4>,
//                                         body: <>
//                                             <div className="row">
//                                                 <div className="col-md-12 text-center">
//                                                     {statusDone.show ? statusDone.msg : " Lakukan proses Registrasi Deposito?"}
//                                                 </div>
//                                             </div>
//                                         </>,
//                                         footer: (
//                                             <>
//                                                 <button onClick={() => {
//                                                     appAction.current.setCloseModal()
//                                                     setStatusDone({
//                                                         show: false,
//                                                         msg: ""
//                                                     })
//                                                 }} className="btn btn-sm btn-secondary">
//                                                     {statusDone.show ? "Selesai" : "Tidak"}
//                                                 </button>
//                                                 {
//                                                     !statusDone.show && <button
//                                                         onClick={
//                                                             () => err.err ? 
//                                                             appAction.current.setShowModal({
//                                                                 show: true,
//                                                                 size: 'sm',
//                                                                 backdrop: 'static',
//                                                                 header: <h4>Warning</h4>,
//                                                                 body: <>
//                                                                     <div className="row">
//                                                                         <div className="col-md-12 text-center">
//                                                                             {err.msg}
//                                                                         </div>
//                                                                     </div>
//                                                                 </>,
//                                                                 footer: (
//                                                                     <>
//                                                                         <button onClick={() => {
//                                                                             appAction.current.setCloseModal()
//                                                                         }} className="btn btn-sm btn-secondary">
//                                                                             close
//                                                                         </button>
//                                                                     </>
//                                                                 )
//                                                             }) : appAction.current.doPost({
//                                                                 uri: "/deposito/lokal/akun",
//                                                                 data: store.formData
//                                                             }).then(resp => {
//                                                                 let { status, message_id } = resp
//                                                                 if (message_id === "00") status = "Proses Registrasi Deposito berhasil dilakukan"
//                                                                 appAction.current.setCloseModal()
//                                                                 appAction.current.setShowModal({
//                                                                     show: true,
//                                                                     size: 'sm',
//                                                                     backdrop: 'static',
//                                                                     header: <h4>{statusDone.show ? "Selesai" : "Konfirmasi"}</h4>,
//                                                                     body: <>
//                                                                         <div className="row">
//                                                                             <div className="col-md-12 text-center">
//                                                                                 {status}
//                                                                             </div>
//                                                                         </div>
//                                                                     </>,
//                                                                     footer: (
//                                                                         <>
//                                                                             <button onClick={() => {
//                                                                                 appAction.current.setCloseModal()
//                                                                                 message_id === "00" && history.push("/deposito/data")
//                                                                             }} className="btn btn-sm btn-secondary">
//                                                                                 selesai
//                                                                             </button>
//                                                                         </>
//                                                                     )
//                                                                 })
//                                                             })
//                                                         }
//                                                         className="btn btn-sm btn-primary">
//                                                         Ya
//                                                     </button>
//                                                 }
//                                             </>
//                                         )
//                                     })
//                                 } className="btn btn-sm btn-primary">
//                                     Simpan
//                                 </button>
//                             )
//                         }
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }));