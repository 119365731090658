import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import { useHistory, useLocation, useParams } from 'react-router';
import useDropdowns from '../../../../../hooks/useDropdowns';
import { Pagination } from 'react-bootstrap';
import useKeycloak from '../../../../../hooks/keycloak.module';

const tabContext = createContext({
    tabAction: {},
    tabState: {}
})


export function TabProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const { keycloak } = useKeycloak()
    const { doPost, doGet } = useFetch();
    const [formEditable, setFormEditable] = useState(false); 
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    
    const [dataOtor, setDataOtor] = useState({
        kode_entry:"",
        dbId:"",
        callBackSuccess:()=>null
    });

    const location = useLocation();
    const { ePeriodeSiklus } = useDropdowns(["ePeriodeSiklus"])

    const [formData, setFormData] = useState({
        "nomor_rekening": "",
        "nama_nasabah": "",
        "cabang_rekening": "",
        "register_buku_lama": "",
        "regis": "",
        "buku_ke": 0,
        "tgl_buku_rekening": null,
        "keterangan": "",
        "cabang_user": ""
    })
    function resetForm(){
        setFormData({
            "nomor_rekening": "",
            "nama_nasabah": "",
            "cabang_rekening": "",
            "register_buku_lama": "",
            "regis": "",
            "buku_ke": 0,
            "tgl_buku_rekening": null,
            "keterangan": "",
            "cabang_user": ""
        })
    }

    const paramUrl = useParams();
    const history = useHistory();
    const [otorDesc, setOtorDesc] = useState("")
    useEffect(function(){
        if(paramUrl.mode === "registration") {
            setFormEditable(true)
            resetForm()
        } 
        else{
            if (location.state === undefined ) return setDataAlert({ show: true, body:'Tidak ada data terpilih' , title: '', header: "Error", callBack: () => history.goBack() });
            else {
                const { dbId = null,  kode_entry = ""} = location.state
                getDetil(dbId);
            }
        }
    },[paramUrl.mode])

    async function getDetil(pathParam){
        openLoading()
        try {
            let url = `/bukutabungan/get_buku_tabungan_by_nomor_rekening?nomor_rekening=${pathParam}`;
            if (paramUrl.mode === "otorisasi") url = `/bukutabungan/get_buku_tabungan_by_db_id?db_id=${pathParam}`;
            if (paramUrl.mode === "detil" || paramUrl.mode === "edit" ) url =`/bukutabungan/get_buku_tabungan_by_nomor_rekening?nomor_rekening=${pathParam}`;
            setLoading(prevState => ({ ...prevState, loading: true }));
            const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url, service:"acc" });
            if (statusCode === 200) {
                if (status === "00") {
                    if (data !== null) {
                        // setFormData(data)
                        setFormData(p =>({
                            ...p,
                            "nomor_rekening": data?.nomor_rekening,
                            "nama_nasabah": data?.nama_rekening,
                            "cabang_rekening": data?.kode_cabang,
                            "register_buku_lama": data?.nomor_register_buku_lama,
                            "regis": data?.nomorregisterbuku_aktif,
                            "buku_ke": data?.nomor_buku_terakhir,
                            "tgl_buku_rekening": data?.tanggal_input,
                            "keterangan": data?.alasan_cetak,
                            "cabang_user": data?.nama_cabang
                        }))
                        // // console.log(data)
                        if (paramUrl.mode === "otorisasi"){
                            setFormData(p =>({
                                ...p,
                                "nomor_rekening": data?.RegBukTab?.nomor_rekening,
                                "nama_nasabah": data?.RegBukTab?.nama_nasabah,
                                "cabang_rekening": data?.RegBukTab?.cabang_rekening,
                                "register_buku_lama": data?.RegBukTab?.register_buku_lama,
                                "regis": data?.RegBukTab?.regis,
                                "buku_ke": data?.RegBukTab?.buku_ke,
                                "tgl_buku_rekening": data?.RegBukTab?.tgl_buku_rekening,
                                "keterangan": data?.RegBukTab?.keterangan,
                                "cabang_user": data?.RegBukTab?.kode_cabang
                            }))
                        }else{
                            if(paramUrl.mode === "edit"){
                                setFormData(p =>({
                                    ...p,
                                    "nomor_rekening": data?.nomor_rekening,
                                    "nama_nasabah": data?.nama_rekening,
                                    "cabang_rekening": data?.nama_cabang,
                                    "register_buku_lama": data?.nomor_register_buku,
                                    "regis": data?.nomor_register_buku,
                                    "buku_ke": data?.nomor_buku_terakhir,
                                    "tgl_buku_rekening": data?.tanggal_input,
                                    "keterangan": data?.alasan_cetak,
                                    "cabang_user": data?.kode_cabang
                                }))
                            }
                        }
                        
                    }

                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => history.goBack() });
            } else setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => history.goBack() });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => history.goBack() });
        }
        closeLoading();
    }
    async function doTrx() {
        
    closeConfirm();
        // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        const err = validasi();
        if (err) {
            closeConfirm()
            return setDataAlert({ show: true, body: "Periksa kembali form pendaftaran, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        let url = "/bukutabungan/register_buku_tabungan";
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: formData,
                url, // ganti sesuai api
                service: "acc" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: "Registrasi Buku Tabungan Berhasil. dibutuhkan otorisasi Buku Tabungan untuk melanjutkan", title: ``, header: "Success", callBack: () => {
                        resetForm();
                        closeConfirm();
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }
    
    function confirmTrx() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan registrasi, cek kembali form registrasi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    const [dataRegis, setDataRegis] = useState(); 
    async function cekNasabah(nomor_rekening) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/bukutabungan/get_buku_tabungan_by_nomor_rekening?nomor_rekening=${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {
                //// console.log(data)
                if (status === "00") {
                    const { nomor_rekening ="", nama_rekening="", kode_cabang="", nomorregisterbuku_aktif= "", nomor_buku_terakhir="", nama_cabang="", tanggal_input="", nomor_register_buku=""} = data;
                    setFormData(prevState=>({
                        ...prevState,
                            nomor_rekening: nomor_rekening,
                            nama_nasabah: nama_rekening,
                            cabang_rekening: kode_cabang,
                            buku_ke: nomor_buku_terakhir,
                            cabang_user: nama_cabang,
                            tgl_buku_rekening: tanggal_input,
                            register_buku_lama: nomor_register_buku,
                            regBukuAktif: nomorregisterbuku_aktif
                    }));
                    setDataRegis(nomorregisterbuku_aktif)
                }

                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }
    async function cekNomorBuku(noreg_buku_aktif, value) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/bukutabungan/get-norek-buku-aktif?noreg_buku_aktif=${noreg_buku_aktif}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    const { nomor_register_buku= ""} = data;
                        setDataAlert({ show: true, body: `Maaf nomor buku regis sudah ada atau sudah aktif`, title: `Nomor regis buku tidak boleh sama : ${nomor_register_buku}`, header: "Warning", callBack: () => setFormData(prevState => ({ ...prevState, regis: "" }))});
                }
                
            } 
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }
    // async function cekNomorBuku(value, callback=()=>null) {
       
    //     if (value === dataRegis ){
    //         setDataAlert({ show: true, body: `Maaf nomor buku regis sudah ada atau sudah aktif`, title: `Nomor regis buku tidak boleh sama : ${dataRegis}`, header: "Warning", callBack: () => setFormData(prevState => ({ ...prevState, regis: "" }))});
    //     }
                      
    // }

    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} Buka Tabungan ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: ()=> closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
       openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (dataOtor !== null && dataOtor !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.kode_entry}/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "acc",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({ show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => history.goBack() });
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });

        closeLoading()
    }

    function validasi(fieldRequired = null) {
        //  field required lists
        if (fieldRequired === null) {
            fieldRequired = [
                "nomor_rekening",
                "regis",
                "keterangan",
            ];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }
//Post Auditrail
async function auditTrail() {
    const userInfo = await keycloak.loadUserInfo();
    // console.log(userInfo)
    var url = `/useraudit/user-audit-trail`;
    try {
        const { statusCode, status, remark, statusText, data = {} } = await doPost({
            url: url,
            service: "acc",
            data: { 
                    user_id : userInfo.preferred_username,
                    app_id : "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Registrasi",
                    event_description: "Tabungan & Giro/Registrasi Buku Tabungan",
                    info1: "-",
                    info2: "-"
            },
            headers: {
                "x-user-id": userInfo.preferred_username,
                method: "POST"
            }
        });

        if (statusCode === 200) {
            if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
            else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
        } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    } catch (error) {
        setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    }
    
}
    return <tabContext.Provider value={{
        tabAction: {
            confirmTrx,
            setFormData,
            cekNasabah,
            setOtorDesc,
            responseOtor,
            cekNomorBuku,
            auditTrail
        },
        tabState: {
            formData,
            formEditable,
            otorDesc,
            ePeriodeSiklus

        }
    }} >{children}</tabContext.Provider>
}

export default function useTabungan() { return useContext(tabContext) }