import React, { createContext } from 'react';
import { addCommas } from '../../../module';
import { TableList } from '../../controls';
import ModalDetil from './modalDetil';
import useInfoAtm, { IAtmProvider } from './useInfoAtm';

function IAtmPage() {
    const { iAtmAction, iAtmState } = useInfoAtm()

    return <>
        <nav aria-label="breadcrumb" className="mb-3">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <a className="text-primary-bds" href="#">
                    Informasi ATM
                    </a>
                </li>
                {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                <li className="breadcrumb-item active" aria-current="page">
                   
                </li>
            </ol>
        </nav>

        <div className='card'>
            <div className='card-header'>
                <div className='card-title'>
                    <h6>List ATM</h6>
                </div>
            </div>

            <div className='card-body'>
                <TableList
                    isLoading={iAtmState.loadingGet}
                    withCard={false}
                    pagination={false}
                    actionItems={[
                        {
                            name:"Lihat Detail",
                            onClick:iAtmAction.openDetail
                        }
                    ]}
                    data={ iAtmState.dataList || []  }
                    columns={[
                        {
                            name:"Nama Rekening",
                            field: "nama_rekening"
                        },
                        {
                            name:"Nomor Rekening",
                            field: "nomor_rekening"
                        },
                        {
                            name:"Valuta",
                            field: "kode_valuta",
                            onRender: ({kode_valuta, nama_valuta}) => `${kode_valuta} ${nama_valuta}`
                        },
                        {
                            name:"Saldo",
                            className:"text-right",
                            field: "saldo",
                            onRender: ({saldo}) => addCommas(parseFloat(saldo || 0).toFixed(2))
                        },
                        {
                            name:"Status",
                            field: "status",
                            onRender: ({status_rekening}) => parseInt(status_rekening) === 1 ? <> Aktif </> : <> Tutup </>
                        },
                    ]}
                />
            </div>
        </div>
        <ModalDetil />
    </>
}

export default () => <IAtmProvider> <IAtmPage /> </IAtmProvider>