import fetchApi from './fetchApi';
import useLocalStorage from './useLocalStorage';
import terbilang from './terbilang';
export {
    fetchApi,
    useLocalStorage,
    terbilang
}

export { default as htmlToExcel } from './htmlToExcel'

export const addCommas = num => {
    if (num === null || num === undefined) return "";
    const splitted = num.toString().split(".")
    let maskingNumber = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let afterComa = '';
    if (splitted.length > 1) {
        afterComa = '.' + splitted[1];
    }
    return maskingNumber + afterComa;
};
export const removeNonNumeric = num => num.toString().replace(/[^0-9 .]/g, "");

