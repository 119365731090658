import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { addCommas } from '../../../../../../module';
import { createForm } from '../../../../../../module/createFormList';
import ModalConfirmCustom from '../../../../../controls/modalAlert/modalConfirmCustomChild';
import useDataAft from '../useDataAft';

const ModalDetilAFT = () => {
    const { aftAction, aftState } = useDataAft()
    return ( 
        <>
        <Modal size="xl" show = {aftState.modalDetil.show} backdrop="static" onHide = { aftAction.closeModalDetil }>
            <Modal.Header closeButton>
                <Modal.Title>
                    Detil
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col">
                    <h4 className="text-base">
                                Data Rekening
                            </h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <FormCreated
                                        type="text"
                                        label="Nomor Rekening Debit"
                                        required={true}
                                        placeholder="Enter untuk cari nasabah"
                                        value={aftState.formData.nomor_rekening_debit}
                                        onChange={(e) => aftAction.setFormData(prevState => ({ ...prevState, nomor_rekening_debit: e }))}
                                        onEnter={(e) => aftAction.cekNasabah(e, 1)}
                                        disabled={true}
                                    />
                                    <FormCreated
                                        type="text"
                                        label="Nama Rekening Debit"
                                        required={true}
                                        disabled={true}
                                        value={aftState.formData.nama_rekening_debit}
                                    />
                                    
                                    <FormCreated 
                                        type="text"
                                        label="Status"
                                        value={aftState.formData.status}
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-md-6">

                                    <FormCreated
                                        type="text"
                                        label="Nomor Rekening Kredit"
                                        required={true}
                                        placeholder="Enter untuk cari nasabah"
                                        value={aftState.formData.nomor_rekening_kredit}
                                        disabled={true}
                                        onChange={(e) => aftAction.setFormData(prevState => ({ ...prevState, nomor_rekening_kredit: e }))}
                                        onEnter={(e) => aftAction.cekNasabah(e, 2)}
                                    />
                                    <FormCreated
                                        type="text"
                                        label="Nama Rekening Kredit"
                                        required={true}
                                        disabled={true}
                                        value={aftState.formData.nama_rekening_kredit}
                                    />

                                </div>
                            </div>
                            <hr />

                            <h4 className="text-base">
                                Detail Transaksi
                            </h4>

                            <div className="row">
                                <div className="col-md-6">

                                    <FormCreated
                                        type="custom"
                                    >
                                         <div className="row">
                                            <div className="col-6">
                                                <FormCreated
                                                    type="date"
                                                    label="Tgl. Awal"
                                                    required={true}
                                                    value={aftState.formData.tgl_awal}
                                                    disabled={!aftState.formEditable}
                                                    onChange={(e) => aftAction.setFormData(prevState => ({ ...prevState, tgl_awal: e }))}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FormCreated
                                                    type="date"
                                                    label="Tgl. Akhir"
                                                    required={true}
                                                    value={aftState.formData.tgl_akhir}
                                                    disabled={!aftState.formEditable}
                                                    onChange={(e) => aftAction.setFormData(prevState => ({ ...prevState, tgl_akhir: e }))}
                                                />
                                            </div>
                                        </div>
                                    </FormCreated>
                                </div>
                                <div className="col-md-6">
                                    <FormCreated
                                        type="number"
                                        label="Nominal SI"
                                        required={true}
                                        value={ addCommas( parseFloat( aftState.formData.nominal_si).toFixed(2))}
                                        disabled={true}
                                        onChange={(e) => aftAction.setFormData(prevState => ({ ...prevState, nominal_si: e }))}
                                    />

                                </div>
                            </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <dov className="col">
                        <button onClick={aftAction.closeModalDetil } className="btn btn-outline-secondary float-right ml-2">
                            Close
                        </button>
                        
                        {
                            aftState.otorMode ? (
                                    <>
                                        <button  className="btn btn-primary float-right ml-2" onClick={()=>aftAction.responseOtor("APPROVED")}>
                                            Approve
                                        </button>
                                        <button className="btn btn-danger float-right ml-2" onClick={()=>aftAction.responseOtor("REJECTED")}>
                                            Reject
                                        </button>
                                    </>
                            ) : (
                                aftState.formData.status === "Aktif" && <button onClick={aftAction.confirmTrx} className="btn btn-primary float-right ml-2">
                                    Non Aktifkan
                                </button>
                            )
                            
                        }
                    </dov>
                </div>
            </Modal.Footer>
        </Modal>
        <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => aftAction.setOtorDesc(e.target.value)}
                    value={aftState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
     );
}


function FormCreated({ children, ...props }) {

    let option = props;
    if (props.type === "custom") option = { ...props, component: children }
    return <>{
        createForm({ ...option })
    }</>
}
 
export default ModalDetilAFT;