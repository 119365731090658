import React, { memo, useRef } from 'react';
import { baseUrl } from './../../../app.config';
import { Modal, Dropdown } from 'react-bootstrap';
import './style.css';
import queryString from 'query-string';
import { withRouter } from "react-router-dom";

class RegistrasiPerorangan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataValidasi:{},
            dataMessage:"",
            dataPerorangan:"",
            dataPeroranganMessage:"",
            step: 0,
            isStep0Completed: false,
            isStep1Completed: false,
            isStep2Completed: false,
            isStep3Completed: false,
            isStep4Completed: false,
            isStep5Completed: false,
            mode: 'create',
            showModalConfirm: false,
            showModalSuccess: false,
            showModalError: false,
            showModalCekTerdaftar: false,
            showModalCekTidakTerdaftar: false,
            encodedKey: "8a8e869678ce17110178ce3be6cb005a",
            id: null,
            state: "ACTIVE",
            firstName: null,
            lastName: "EOD",
            homePhone: null,
            // middleName: null,
            mobilePhone: null,
            mobilePhone2: null,
            emailAddress: null,
            preferredLanguage: "ENGLISH",
            birthDate: null,
            gender: null,
            notes: null,
            assignedBranchKey: "8a8e8fab786e635c0178863b7911431e",
            _personalIdData: {
                personalIdDateOfIssue: null,
                urlImageSelfie: "URL Selfi",
                personalMarketingOfficer: null,
                personalReferralCode: "ReffCode",
                personalIdExpireDate: null,
                personalIdNumber: null,
                personalNpwpNumber: null,
                personalIdType: null,
                urlImageKtp: "URL_KTP",
                personalIdHolderName: null,
                personalIdIssuedPlace: "JAKARTA",
            },
            _mailingAddress: {
                mailingAddressPostalCode: null,
                mailingRecipientPhoneNumber: "0878121212121",
                mailingAddress: null,
                mailingAddressVillage: null,
                mailingAddressProvince: null,
                mailingAddressRtRw: null,
                mailingAddressSubDistrict: null,
            },
            _occupationInfo: {
                personalOccupation: null,
                companyName: null,
                personalMonthlyIncome: "Lebih dari Rp50JT",
                personalTypeOfWork: null,
                personalJobPosition: null,
            },
            _customerData: {
                birthPlace: null,
                personalCitizenship: null,
                personalReligion: "Islam",
                personalMothersName: null,
                personalMaritalStatus: null,
                personalScoringCust: "9",
                customerEducation: null,
                customerAliasName: null,
                customerPreTitle: null,
                customerSpouseSecondIncome: "string",
                customerBankRelation:"string",
                customerSpouseName: "string",
                customerSpouseOccupation: "string",
                customerPostTitle: null,
                customerSpouseMainIncome: "string",
            },
            _ktpAddress: {
                ktpRtRw: null,
                ktpSubDistrict: null,
                ktpVillage: null,
                ktpAddress: null,
                ktpCity: null,
                ktpProvince: null,
                ktpPostalCode: null,
                ktpCountry: null, 
            },
            _companyAddress: {
                companyAddressCity: null,
                companyAddressRtRw: null,
                companyAddressVillage: null,
                companyAddress: null,
                companyAddressSubDistrict: null,
                companyAddressPostalCode: null,
                companyAddressProvince: null,
                companyAddressPhoneNumber: null,
            },
            _fields: {
                eJenisIdentitas: [],
                R_KODE_NEGARA:[],
                R_JABATAN_KORPORAT: [],
                R_PEKERJAAN: [],
                eKewarganegaraan: [],
                ePendidikan: [],
                eAgama: [],
                eStatusPerkawinan: [],
                eStatusKeterkaitan: [],
                ePendapatanPerBulan: [],
                preferredLanguage: [],
                eJenisPenduduk:[],
                R_SEKTOR_EKONOMI:[],
                personalOccupation:[],
                eBadanHukum:[],
                R_BIDANG_USAHA:[],
                _ktpAddress: {
                    provinces: [],
                    cities: [],
                    subdistricts: [],
                    urbans: [],
                    postalscode: [],
                },
                _mailingAddress: {
                    provinces: [],
                    cities: [],
                    subdistricts: [],
                    urbans: [],
                    postalscode: [],
                },
                _companyAddress: {
                    provinces: [],
                    cities: [],
                    subdistricts: [],
                    urbans: [],
                    postalscode: [],
                },
            },
            errors: {
                firstName: null,
                lastName: null,
                birthDate: null,
                gender: null,
                emailAddress: null,
                mobilePhone: null,
                ktpAddress: null,
                ktpRt: null,
                ktpRw: null,
                ktpVillage: null,
                ktpSubDistrict: null,
                ktpCity: null,
                ktpProvince: null,
                birthPlace: null,
                personalMothersName: null,
                personalIdType: null,
                personalIdNumber: null,
            },
        };
    }

    componentDidMount() {
        this.getDropdowns('eJenisIdentitas');
        this.getDropdowns('R_PEKERJAAN');
        this.getDropdowns('R_JABATAN_KORPORAT');
        this.getDropdowns('eKewarganegaraan');
        this.getDropdowns('ePendidikan');
        this.getDropdowns('eAgama');
        this.getDropdowns('eStatusPerkawinan');
        this.getDropdowns('eStatusKeterkaitan');
        this.getDropdowns('ePendapatanPerBulan');
        this.getDropdowns('preferredLanguage');
        this.getDropdowns('R_KODE_NEGARA');
        this.getDropdowns('eJenisPenduduk');
        this.getDropdowns('R_SEKTOR_EKONOMI');
        this.getDropdowns('personalOccupation');
        this.getDropdowns('eBadanHukum');
        this.getDropdowns('R_BIDANG_USAHA');
        this.getProvinces();
        this.getData();
    }

    getDropdowns = (key) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/dropdowns?key=' + key, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [key]: data.data?.listDropdown||[],
                    },
                }));
            });
    };

    getProvinces = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/locations/provinces', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        _ktpAddress: {
                            provinces: data.data?.listProvinces||[],
                        },
                        _mailingAddress: {
                            provinces: data.data?.listProvinces||[],
                        },
                        _companyAddress: {
                            provinces: data.data?.listProvinces||[],
                        },
                    },
                }));
            });
    };

    getCities = (field, province) => {
        // // console.log(field);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/locations/cities?province_code='+ province, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // // console.log(data);
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [field]: {
                            ...prevState._fields[field],
                            cities: data.data?.listCities||[],
                        },
                    },
                }));
            });
    };

    getSubDistricts = (field, city) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/locations/subdistrict?city='+ city, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [field]: {
                            ...prevState._fields[field],
                            subdistricts: data.data?.listSubdistricts||[],
                        },
                    },
                }));
            });
    };

    getUrbans = (field, subdistrict, city) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(
            baseUrl + `/locations/urban?city=${city}&subdistrict=${subdistrict}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                // // console.log(data);
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [field]: {
                            ...prevState._fields[field],
                            urbans: data.data?.listUrbans||[],
                        },
                    },
                }));
            });
    };

    getPostalsCode = (field, city, subdistrict, urban) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(
            baseUrl +
                `/locations/postal-code?city=${city}&subdistrict=${subdistrict}&urban=${urban}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState((prevState) => ({
                    _fields: {
                        ...prevState._fields,
                        [field]: {
                            ...prevState._fields[field],
                            postalscode: data.data?.listUrbans||[],
                        },
                    },
                }));
            });
    };

    getData = () => {
        const queryParams = queryString.parse(this.props.location.search);
        if (queryParams.mode === 'info' || queryParams.mode === 'update') {
            let url = `/nasabah/local/${queryParams.db_id}`
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };

            fetch(baseUrl + url, requestOptions)
                .then((response) => response.json())
                .then((resp) => {
                    let dataDetail = resp.status === "00" ? resp.data.nasabahBadanUsaha : {} ;
                    // console.log(dataDetail);
                    this.setState({
                        ...dataDetail,
                        isStep0Completed: queryParams.mode === 'info' ? true : false,
                        isStep1Completed: queryParams.mode === 'info' ? true : false,
                        isStep2Completed: queryParams.mode === 'info' ? true : false,
                        isStep3Completed: queryParams.mode === 'info' ? true : false,
                        isStep4Completed: queryParams.mode === 'info' ? true : false,
                        isStep5Completed: queryParams.mode === 'info' ? true : false,
                        mode: queryParams.mode,
                        encodedKey: dataDetail?.encodedKey,
                        id: dataDetail?.id,
                        state: dataDetail?.state,
                        firstName: dataDetail?.firstName,
                        lastName: dataDetail?.lastName,
                        homePhone: dataDetail?.homePhone,
                        mobilePhone: dataDetail?.mobilePhone,
                        mobilePhone2: dataDetail?.mobilePhone2,
                        emailAddress: dataDetail?.emailAddress,
                        preferredLanguage: dataDetail?.preferredLanguage,
                        birthDate: dataDetail?.birthDate,
                        gender: dataDetail?.gender,
                        notes: dataDetail?.notes,
                        assignedBranchKey: dataDetail?.assignedBranchKey,
                        _personalIdData: {
                            birthDate: dataDetail?.birthDate,
                            personalIdDateOfIssue: dataDetail.personalIdDateOfIssue,
                            urlImageSelfie: dataDetail.urlImageSelfie,
                            personalMarketingOfficer: dataDetail.personalMarketingOfficer,
                            personalReferralCode: dataDetail.personalReferralCode,
                            personalIdExpireDate: dataDetail.personalIdExpireDate,
                            personalIdNumber: dataDetail.personalIdNumber,
                            personalNpwpNumber: dataDetail.personalNpwpNumber,
                            personalIdType: dataDetail.personalIdType,
                            urlImageKtp: dataDetail.urlImageKtp,
                            personalIdHolderName: dataDetail.personalIdHolderName,
                            personalIdIssuedPlace: dataDetail.personalIdIssuedPlace,
                        },
                        _customerData: {
                            birthPlace: dataDetail?.birthPlace,
                            personalMothersName: dataDetail?.personalMothersName,
                            customerPreTitle: dataDetail?.customerPreTitle,
                            customerPostTitle: dataDetail?.customerPostTitle,
                            personalMaritalStatus: dataDetail?.personalMaritalStatus == "Menikah" ? "MARRIED" : 
                                (dataDetail?.personalMaritalStatus == "Belum Menikah" ? "SINGLE" : "UNKNOWN"),
                            customerAliasName: dataDetail?.customerAliasName,
                            personalCitizenship: dataDetail?.personalCitizenship,
                            customerAliasName2: dataDetail?.customerAliasName2,
                            customerAliasName3: dataDetail?.customerAliasName3,
                            customerAliasName4: dataDetail?.customerAliasName4,
                            country: dataDetail?.ktpCountry.toUpperCase(),
                            personalNpwpNumber: dataDetail.personalNpwpNumber,
                        },
                        _occupationInfo: {
                            personalOccupation: dataDetail?.personalOccupation,
                            personalJobPosition: dataDetail?.personalJobPosition,
                            personalTypeOfWork: dataDetail?.personalTypeOfWork,
                            companyName: dataDetail?.companyName,
                        },
                        _companyAddress: {
                            companyAddressPhoneNumber: dataDetail?.companyAddressPhoneNumber,
                            companyAddressPhoneNumber2: dataDetail?.companyAddressPhoneNumber2,
                            companyAddressProvince: dataDetail?.companyAddressProvince,
                            companyAddressCity: dataDetail.companyAddressCity,
                            "companyAddressRtRw": dataDetail?.companyAddressRtRw,
                            "companyAddressVillage": dataDetail.companyAddressVillage,
                            "companyAddress": dataDetail?.companyAddress,
                            "companyAddressSubDistrict": dataDetail?.companyAddressSubDistrict,
                            "companyAddressPostalCode": dataDetail?.companyAddressPostalCode,
                        },
                        _ktpAddress:{
                            "ktpRtRw": dataDetail.ktpRtRw,
                            "ktpSubDistrict": dataDetail?.ktpSubDistrict,
                            "ktpVillage": dataDetail?.ktpVillage,
                            "ktpAddress": dataDetail?.ktpAddress,
                            "ktpCity": dataDetail?.ktpCity,
                            "ktpProvince": dataDetail?.ktpProvince,
                            "ktpPostalCode": dataDetail?.ktpPostalCode,
                            "ktpCountry": dataDetail?.ktpCountry,
                        },
                        _mailingAddress: {
                            "mailingAddressPostalCode": dataDetail?.mailingAddressPostalCode,
                            "mailingRecipientPhoneNumber": dataDetail?.mailingRecipientPhoneNumber,
                            "mailingAddress": dataDetail?.mailingAddress,
                            "mailingAddressCity": dataDetail?.mailingAddressCity,
                            "mailingAddressVillage": dataDetail?.mailingAddressVillage,
                            "mailingAddressProvince": dataDetail?.mailingAddressProvince,
                            "mailingAddressRtRw": dataDetail?.mailingAddressRtRw,
                            "mailingAddressSubDistrict": dataDetail?.mailingAddressSubDistrict,
                        }
                        
                    });
                });
        }
    };

    handleInputChange = (event, isObject = false, key = null) => {
        const value = event.target.value;
        const name = event.target.name;
        if (isObject) {
            this.setState((prevState) => ({
                [key]: {
                    ...prevState[key],
                    [name]: value,
                },
            }));
        } else {
            this.setState({
                [name]: value,
            });
        }
    };

    setStep = (step) => {
        this.setState({
            step,
        });
    };

    validate = () => {
        const {
            firstName,
            lastName,
            birthDate,
            gender,
            emailAddress,
            mobilePhone,
            _personalIdData,
            _ktpAddress,
            _customerData,
        } = this.state;

        // initial errors
        let errors = {
            firstName: false,
            lastName: false,
            birthDate: false,
            gender: false,
            emailAddress: false,
            mobilePhone: false,
            ktpAddress: false,
            ktpRtRw: false,
            ktpVillage: false,
            ktpSubDistrict: false,
            ktpCity: false,
            ktpProvince: false,
            birthPlace: false,
            personalMothersName: false,
            personalIdType: false,
            personalIdNumber: false,
        };

        let isStep0Completed = this.state.isStep0Completed;
        let isStep1Completed = this.state.isStep1Completed;
        let isStep2Completed = this.state.isStep2Completed;
        let isStep3Completed = this.state.isStep3Completed;
        let isStep4Completed = this.state.isStep4Completed;
        let isStep5Completed = this.state.isStep5Completed;

        // validate step 0
        if (this.state.step === 0) {
            if (firstName === '' || firstName === null || firstName === 'NULL') {
                errors = { ...errors, firstName: true };
            }
            if (lastName === '' || lastName === null || lastName === 'NULL') {
                errors = { ...errors, lastName: true };
            }
            if (birthDate === '' || birthDate === null || birthDate === 'NULL') {
                errors = { ...errors, birthDate: true };
            }
            if (gender === '' || gender === null || gender === 'null') {
                errors = { ...errors, gender: true };
            }
            if (emailAddress === '' || emailAddress === null || emailAddress === 'NULL') {
                errors = { ...errors, emailAddress: true };
            }
            if (mobilePhone === '' || mobilePhone === null || mobilePhone === 'NULL') {
                errors = { ...errors, mobilePhone: true };
            }
            if (
                _customerData.birthPlace === '' ||
                _customerData.birthPlace === null ||
                _customerData.birthPlace === 'NULL'
            ) {
                errors = { ...errors, birthPlace: true };
            }
            if (
                _customerData.personalMothersName === '' ||
                _customerData.personalMothersName === null ||
                _customerData.personalMothersName === 'NULL'
            ) {
                errors = { ...errors, personalMothersName: true };
            }
            if (
                _personalIdData.personalIdType === '' ||
                _personalIdData.personalIdType === null ||
                _personalIdData.personalIdType === 'NULL'
            ) {
                errors = { ...errors, personalIdType: true };
            }
            if (
                _personalIdData.personalIdNumber === '' ||
                _personalIdData.personalIdNumber === null ||
                _personalIdData.personalIdNumber === 'NULL'
            ) {
                errors = { ...errors, personalIdNumber: true };
            }
            if (
                !errors.firstName &&
                !errors.lastName &&
                !errors.birthDate &&
                !errors.gender &&
                !errors.emailAddress &&
                !errors.mobilePhone &&
                !errors.personalMothersName &&
                !errors.personalIdType &&
                !errors.personalIdNumber &&
                !errors.birthPlace
            ) {
                isStep0Completed = true;
            }

            this.setState({
                errors: errors,
                isStep0Completed: isStep0Completed,
                step: this.state.step + 1,
            });
        }

        // validate step 1
        if (this.state.step === 1) {
            isStep1Completed = true;
            this.setState({
                isStep1Completed: isStep1Completed,
            });
        }
        if (this.state.step === 1) {
            if (
                _ktpAddress.ktpAddress === '' ||
                _ktpAddress.ktpAddress === null ||
                _ktpAddress.ktpAddress === 'NULL'
            ) {
                errors = { ...errors, ktpAddress: true };
            }
            if (
                _ktpAddress.ktpRtRw === '' ||
                _ktpAddress.ktpRtRw === null ||
                _ktpAddress.ktpRtRw === 'NULL'
            ) {
                errors = { ...errors, ktpRtRw: true };
            }
            if (
                _ktpAddress.ktpSubDistrict === '' ||
                _ktpAddress.ktpSubDistrict === null ||
                _ktpAddress.ktpSubDistrict === 'NULL'
            ) {
                errors = { ...errors, ktpSubDistrict: true };
            }
            if (
                _ktpAddress.ktpVillage === '' ||
                _ktpAddress.ktpVillage === null ||
                _ktpAddress.ktpVillage === 'NULL'
            ) {
                errors = { ...errors, ktpVillage: true };
            }
            if (
                _ktpAddress.ktpCity === '' ||
                _ktpAddress.ktpCity === null ||
                _ktpAddress.ktpCity === 'NULL'
            ) {
                errors = { ...errors, ktpCity: true };
            }
            if (
                _ktpAddress.ktpProvince === '' ||
                _ktpAddress.ktpProvince === null ||
                _ktpAddress.ktpProvince === 'NULL'
            ) {
                errors = { ...errors, ktpProvince: true };
            }

            if (
                !errors.ktpAddress &&
                !errors.ktpRtRw &&
                !errors.ktpVillage &&
                !errors.ktpSubDistrict &&
                !errors.ktpCity &&
                !errors.ktpProvince
            ) {
                isStep1Completed = true;
            }

            this.setState({
                errors: errors,
                isStep1Completed: isStep1Completed,
            });
        }

        // validate step 2
        if (this.state.step === 2) {
            isStep2Completed = true;
            this.setState({
                isStep2Completed: isStep2Completed,
            });
        }

        // validate step 3
        if (this.state.step === 3) {
            isStep3Completed = true;
            this.setState({
                isStep3Completed: isStep3Completed,
            });
        }

        // validate step 4
        if (this.state.step === 4) {
            isStep4Completed = true;
            this.setState({
                isStep4Completed: isStep4Completed,
            });
        }

        // validate step 5
        if (this.state.step === 5) {
            isStep5Completed = true;
            this.setState({
                isStep5Completed: isStep5Completed,
            });
        }
    };

    saveStep = () => {
        // this.validate();

        // const {
        //     isStep0Completed,
        //     isStep1Completed,
        //     isStep2Completed,
        //     isStep3Completed,
        //     isStep4Completed,
        //     isStep5Completed,
        // } = this.state;

        // let showModalConfirm = false;
        // if (isStep0Completed && isStep1Completed) {
        //     showModalConfirm = true;
        // }
        this.setState({
            showModalConfirm : true,
        });
    };

    resetStep = () => {
        if (window.confirm('Apakah Anda yakin akan reset tahap ini?')) {
            this.getData();
        }
    };

    putPerorangan = async () => {
        let body = {
            encodedKey: this.state.encodedKey,
            id: this.state.id,
            state: this.state.state,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            homePhone: this.state.homePhone,
            mobilePhone: this.state.mobilePhone,
            mobilePhone2: this.state.mobilePhone2,
            emailAddress: this.state.emailAddress,
            preferredLanguage: this.state.preferredLanguage,
            birthDate: this.state.birthDate,
            gender: this.state.gender,
            notes: this.state.notes,
            assignedBranchKey: this.state.assignedBranchKey,
            _personalIdData: this.state._personalIdData,
            _mailingAddress: this.state._mailingAddress,
            _occupationInfo: this.state._occupationInfo,
            _customerData: this.state._customerData,
            _ktpAddress: this.state._ktpAddress,
            _companyAddress: this.state._companyAddress,
        };
        // console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'x-user-id' : '1',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };
        // fetch(baseUrl + '/nasabah/perorangan?nomor_cif=' + this.state.id, requestOptions)
        fetch(baseUrl + '/nasabah', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                this.setState({
                    dataPerorangan:data.data?.status||[],
                    dataPeroranganMessage:data.data?.remark||[],
                })
                if (data.status === '00') {
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                    });
                }
            });
    };

    postCekData = async () => {
        let body = {
            identity_number : this.state.personalIdNumber,
            customer_name : this.state.firstName,
            customer_birthdate : this.state.birthDate,
            mother_name : this.state.personalMothersName
            
            // identity_number : "3174025906700004",
            // customer_name : "ERNINGSIH",
            // customer_birthdate : "19/06/1970",
            // mother_name : "KURNIASIH"
        };
        // console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };
        fetch(baseUrl + '/nasabah/validasi', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                this.setState({
                    dataValidasi:data.data?.status||[],
                    dataMessage:data.data?.remark||[],
                })
                if (data.status === '00') {
                    this.setState({
                        showModalCekTidakTerdaftar: true,
                    });
                } else {
                    this.setState({
                        showModalCekTerdaftar: true,
                    });
                }
            });
    };

    render() {
        return (
            <div className="">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Nasabah / CIF</li>
                        {this.state.mode != 'create' && 
                            <li className="breadcrumb-item">
                                <a className="text-primary-bds" href="#" onClick={()=> this.props.history.push('/data_nasabah/cif')}>Data Nasabah</a>
                            </li>
                        }
                        <li className="breadcrumb-item">
                            <a href="#">
                                {this.state.mode === 'create'
                                    ? 'Registrasi'
                                    : this.state.mode === 'update'
                                    ? 'Ubah'
                                    : 'Lihat'}{' '}
                                Nasabah Perorangan
                            </a>
                        </li>
                    </ol>
                </nav>

                <div className="card">
                    <div className="card-header">
                        <div className="row mx-auto justify-content-center p-2">
                            <div
                                className={
                                    this.state.isStep0Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step === 0 ||
                                          this.state.step === 1 ||
                                          this.state.step === 2 ||
                                          this.state.step === 3 ||
                                          this.state.step === 4 ||
                                          this.state.step === 5 
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(0)}
                            >
                                <div className="row">
                                    <div className="col p0"></div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep0Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Identias Nasabah
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep1Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step === 1 ||
                                          this.state.step === 2 ||
                                          this.state.step === 3 ||
                                          this.state.step === 4 ||
                                          this.state.step === 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(1)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep1Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Pekerjaan & Alamat
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep2Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step === 2 ||
                                          this.state.step === 3 ||
                                          this.state.step === 4 ||
                                          this.state.step === 5 
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(2)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep2Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Data tambahan & CDD
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep3Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step === 3 ||
                                          this.state.step === 4 ||
                                          this.state.step === 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(3)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep3Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Data Rekening
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep4Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step === 4 ||
                                          this.state.step === 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(4)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep4Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Relasi & Ahli Waris
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.isStep5Completed
                                        ? 'col-2 pointer step-complete'
                                        : this.state.step == 5
                                        ? 'col-2 pointer step-active'
                                        : 'col-2 pointer'
                                }
                                onClick={() => this.setStep(5)}
                            >
                                <div className="row">
                                    <div className="col p0">
                                        <hr className="line-status" />
                                    </div>
                                    <div className="col-auto">
                                        <span className="round-tab">
                                            {this.state.isStep5Completed ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className="col p0"></div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center wizard-text">
                                        Beneficiary Owner
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.step == 0 ? (
                            <div>
                                <div className="box">
                                    <div className="row">
                                        <div className="col-4 align-self-center bns-form">
                                            <div className="form-group">
                                                <label>Nomor CIF</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    readOnly
                                                    placeholder="Otomatis terisi dari sistem"
                                                    value={this.state.id}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4 align-self-center bns-form">
                                            <div className="form-group">
                                                <label>Kode Referal</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    readOnly
                                                    placeholder="Otomatis terisi dari sistem"
                                                    value={this.state.id}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4 text-right align-self-end">
                                            <div>
                                                <span className="text-grey">Tgl Buka</span>
                                                <span className="text-grey2 ml-4">:</span>
                                                <span className="text-grey2">03 Maret 2021</span>
                                                <span className="ml-5 text-grey">Kode Cabang</span>
                                                <span className="text-grey2 ml-4">:</span>
                                                <span className="text-grey2">01/23/45</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bns-form">
                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">Identitas</div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label
                                                className={
                                                    this.state.errors.personalIdType
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Jenis Identitas{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select
                                                className={`form-control ${
                                                    this.state.errors.personalIdType
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_personalIdData'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="personalIdType"
                                                value={this.state._personalIdData?.personalIdType}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih jenis identitas
                                                </option>
                                                {this.state._fields.eJenisIdentitas &&
                                                this.state._fields.eJenisIdentitas.length > 0
                                                    ? this.state._fields.eJenisIdentitas.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Tanggal Identitas{' '}
                                                {/* <span className="badge badge-secondary">Wajib</span> */}
                                            </label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Tgl Terbit"
                                                        className="form-control"
                                                        type="date"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_personalIdData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="personalIdDateOfIssue"
                                                        value={
                                                            this.state._personalIdData
                                                                ?.personalIdDateOfIssue
                                                        }
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>/</span>
                                                </div>
                                                <div className="col-5">
                                                    <input
                                                        placeholder="Tgl Kadaluarsa"
                                                        className="form-control"
                                                        type="date"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_personalIdData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="personalIdExpireDate"
                                                        value={
                                                            this.state._personalIdData
                                                                ?.personalIdExpireDate
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label
                                                className={
                                                    this.state.errors.personalIdNumber
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Nomor Identitas{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nomor Identitas"
                                                className={`form-control ${
                                                    this.state.errors.personalIdNumber
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                name="personalIdNumber"
                                                value={this.state._personalIdData?.personalIdNumber}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_personalIdData'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        {/* <div className="form-group col-md-6">
                                            <label
                                                className={
                                                    this.state.errors.personalNpwpNumber
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Nomor NPWP{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nomor NPWP"
                                                className={`form-control ${
                                                    this.state.errors.personalNpwpNumber
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                name="personalNpwpNumber"
                                                value={
                                                    this.state._personalIdData?.personalNpwpNumber
                                                }
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_personalIdData'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div> */}
                                        <div className="form-group col-md-6">
                                            <label>Tempat / Tanggal Lahir</label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Tempat Lahir"
                                                        className="form-control"
                                                        type="input"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_customerData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="birthPlace"
                                                        value={
                                                            this.state._customerData
                                                                ?.birthPlace
                                                        }
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>/</span>
                                                </div>
                                                <div className="col-5">
                                                    <input
                                                        placeholder="Tgl Lahir"
                                                        className="form-control"
                                                        type="date"
                                                        onChange={(e) =>
                                                            this.handleInputChange(e)
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="birthDate"
                                                        value={
                                                            this.state._personalIdData
                                                                ?.birthDate
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Nama (Sesuai Identitas)</label>
                                            <input
                                                placeholder="Masukan Nama (Sesuai Identitas)"
                                                className={`form-control`}
                                                name="personalIdHolderName"
                                                value={
                                                    this.state._personalIdData
                                                        ?.personalIdHolderName
                                                }
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_personalIdData'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        {this.state.mode === "create" &&
                                            <div className="form-group col-md-6">
                                                <div className="row">
                                                    <div className="form-group col-9">    
                                                    </div>
                                                    <div className="form-group col-3 mt-4">  
                                                        <button 
                                                            onClick={() =>
                                                            this.postCekData() 
                                                        }
                                                        type="button" className="btn btn-success">CEK DATA NASABAH</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">Data Diri</div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label
                                                className={
                                                    this.state.errors.firstName ? 'text-danger' : ''
                                                }
                                            >
                                                Nama Lengkap{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nama"
                                                className={`form-control ${
                                                    this.state.errors.firstName ? 'is-invalid' : ''
                                                }`}
                                                name="firstName"
                                                value={this.state.firstName}
                                                onChange={(e) => this.handleInputChange(e)}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                            <input
                                                placeholder="Masukan Nama Belakang"
                                                className={`form-control ${
                                                    this.state.errors.lastName ? 'is-invalid' : ''
                                                }`}
                                                name="lastName"
                                                value={this.state.firstName}
                                                // value={"Lastname"}
                                                onChange={(e) => this.handleInputChange(e)}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                hidden
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        {/* <div className="form-group col-md-6">
                                            <label
                                                className={
                                                    this.state.errors.lastName ? 'text-danger' : ''
                                                }
                                            >
                                                Nama Belakang{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nama Belakang"
                                                className={`form-control ${
                                                    this.state.errors.lastName ? 'is-invalid' : ''
                                                }`}
                                                name="lastName"
                                                value={this.state.lastName}
                                                onChange={(e) => this.handleInputChange(e)}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>Nama Tengah</label>
                                            <input
                                                placeholder="Masukan Nama Belakang"
                                                className={`form-control`}
                                                name="middleName"
                                                value={this.state.middleName}
                                                onChange={(e) => this.handleInputChange(e)}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label
                                                className={
                                                    this.state.errors.birthDate ||
                                                    this.state.errors.birthPlace
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Tempat / Tanggal Lahir{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Tempat Lahir"
                                                        className={`form-control ${
                                                            this.state.errors.birthPlace
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        name="birthPlace"
                                                        value={this.state._customerData?.birthPlace}
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_customerData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div>
                                                <div className="col-1">
                                                    <span>/</span>
                                                </div>
                                                <div className="col-5">
                                                    <input
                                                        placeholder="Tanggal Lahir"
                                                        className={`form-control ${
                                                            this.state.errors.birthDate
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        type="date"
                                                        name="birthDate"
                                                        value={this.state.birthDate}
                                                        onChange={(e) => this.handleInputChange(e)}
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>
                                                Nama Tanpa Singkatan{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nama Tanpa Singkatan"
                                                className="form-control"
                                                value=""
                                            />
                                        </div> */}
                                        <div className="form-group col-md-6">
                                            <label
                                                className={`${
                                                    this.state.errors.personalMothersName
                                                        ? 'text-danger'
                                                        : ''
                                                }`}
                                            >
                                                Nama Ibu Kandung{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Nama Ibu Kandung"
                                                className={`form-control ${
                                                    this.state.errors.personalMothersName
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                name="personalMothersName"
                                                value={
                                                    this.state._customerData?.personalMothersName
                                                }
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <label>Gelar Depan</label>
                                                    <input
                                                        placeholder="Gelar Depan"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_customerData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="customerPreTitle"
                                                        value={
                                                            this.state._customerData
                                                                ?.customerPreTitle
                                                        }
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label>Gelar Belakang</label>
                                                    <input
                                                        placeholder="Gelar Belakang"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_customerData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="customerPostTitle"
                                                        value={
                                                            this.state._customerData
                                                                ?.customerPostTitle
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Status Pernikahan </label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="personalMaritalStatus"
                                                value={
                                                    this.state._customerData?.personalMaritalStatus
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Status Pernikahan
                                                </option>
                                                {this.state._fields.eStatusPerkawinan &&
                                                this.state._fields.eStatusPerkawinan.length > 0
                                                    ? this.state._fields.eStatusPerkawinan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                        //   data.reference_code +
                                                                        //   ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        {/* <div className="form-group col-md-6">
                                            <label>
                                                Status Perkawinan{' '}
                                            </label>
                                            <select
                                                aria-readonly="false"
                                                className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            >
                                                <option value="">Pilih Status Perkawinan</option>
                                            </select>
                                        </div> */}
                                        <div className="form-group col-md-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <label>Nama Alias 1</label>
                                                    <input
                                                        placeholder="customerAliasName"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_customerData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="customerAliasName"
                                                        value={
                                                            this.state._customerData
                                                                ?.customerAliasName
                                                        }
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label>Nama Alias 2</label>
                                                    <input
                                                        placeholder="customerAliasName"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_customerData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="customerAliasName2"
                                                        value={
                                                            this.state._customerData
                                                                ?.customerAliasName2
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Kewarganegaraan </label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="personalCitizenship"
                                                value={
                                                    this.state._customerData?.personalCitizenship
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Kewarganegaraan
                                                </option>
                                                {this.state._fields.eKewarganegaraan &&
                                                this.state._fields.eKewarganegaraan.length > 0
                                                    ? this.state._fields.eKewarganegaraan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                        //   data.reference_code +
                                                                        //   ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <label>Nama Alias 3</label>
                                                    <input
                                                        placeholder="Gelar Depan"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_customerData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="customerAliasName3"
                                                        value={
                                                            this.state._customerData
                                                                ?.customerAliasName3
                                                        }
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label>Nama Alias 4</label>
                                                    <input
                                                        placeholder="Gelar Belakang"
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_customerData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="customerAliasName4"
                                                        value={
                                                            this.state._customerData
                                                                ?.customerAliasName4
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="form-group col-md-6">
                                            <label>Nama Singkat</label>
                                            <input
                                                placeholder="Nama Singkat"
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="customerAliasName"
                                                value={this.state._customerData?.customerAliasName}
                                            />
                                        </div> */}
                                        <div className="form-group col-md-6">
                                            <label>Negara Asal </label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="country"
                                                value={
                                                    this.state._customerData?.country
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih negara asal
                                                </option>
                                                {this.state._fields.R_KODE_NEGARA &&
                                                this.state._fields.R_KODE_NEGARA.length > 0
                                                    ? this.state._fields.R_KODE_NEGARA.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                        //   data.reference_code +
                                                                        //   ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>
                                                Jenis Kelamin{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <br />
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="gender"
                                                        value="MALE"
                                                        checked={
                                                            this.state.gender === 'MALE'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => this.handleInputChange(e)}
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    Laki-laki
                                                </label>
                                            </div>
                                            <div className="form-check-inline ml-3">
                                                <label className="form-check-label">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        name="gender"
                                                        value="FEMALE"
                                                        checked={
                                                            this.state.gender === 'FEMALE'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => this.handleInputChange(e)}
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    Perempuan
                                                </label>
                                            </div>
                                        </div>
                                        {/* <div className="form-group col-md-6">
                                            <label>Agama </label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="personalReligion"
                                                value={this.state._customerData?.personalReligion}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Agama
                                                </option>
                                                {this.state._fields.eAgama &&
                                                this.state._fields.eAgama.length > 0
                                                    ? this.state._fields.eAgama.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                       
                                        {/* <div className="form-group col-md-6">
                                            <label>Pendidikan</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="customerEducation"
                                                value={this.state._customerData?.customerEducation}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Pendidikan
                                                </option>
                                                {this.state._fields.ePendidikan &&
                                                this.state._fields.ePendidikan.length > 0
                                                    ? this.state._fields.ePendidikan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>Status Keterkaitan</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="customerBankRelation"
                                                value={
                                                    this.state._customerData?.customerBankRelation
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Status Keterkaitan
                                                </option>
                                                {this.state._fields.eStatusKeterkaitan &&
                                                this.state._fields.eStatusKeterkaitan.length > 0
                                                    ? this.state._fields.eStatusKeterkaitan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>Nama Pasangan</label>
                                            <input
                                                placeholder="Masukan Nama Pasangan"
                                                className={`form-control`}
                                                name="customerSpouseName"
                                                value={this.state._customerData.customerSpouseName}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>Pekerjaan Pasangan</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="customerSpouseOccupation"
                                                value={
                                                    this.state._customerData
                                                        ?.customerSpouseOccupation
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Pekerjaan Pasangan
                                                </option>
                                                {this.state._fields.R_PEKERJAAN &&
                                                this.state._fields.R_PEKERJAAN.length > 0
                                                    ? this.state._fields.R_PEKERJAAN.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>Penghasilan Utama Pasangan</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="customerSpouseMainIncome"
                                                value={
                                                    this.state._customerData
                                                        ?.customerSpouseMainIncome
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Penghasilan Utama Pasangan
                                                </option>
                                                {this.state._fields.ePendapatanPerBulan &&
                                                this.state._fields.ePendapatanPerBulan.length > 0
                                                    ? this.state._fields.ePendapatanPerBulan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>Penghasilan Lainnya Pasangan</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="customerSpouseSecondIncome"
                                                value={
                                                    this.state._customerData
                                                        ?.customerSpouseSecondIncome
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Penghasilan Lainnya Pasangan
                                                </option>
                                                {this.state._fields.ePendapatanPerBulan &&
                                                this.state._fields.ePendapatanPerBulan.length > 0
                                                    ? this.state._fields.ePendapatanPerBulan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                    </div>

                                    <div className="row mt-4 mb-2">
                                        <div className="col-auto text-primary">
                                            Informasi Lainnya
                                        </div>
                                        <div className="col">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label
                                                className={`${
                                                    this.state.errors.emailAddress
                                                        ? 'text-danger'
                                                        : ''
                                                }`}
                                            >
                                                Alamat Email{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukan Masukkan alamat email"
                                                className={`form-control ${
                                                    this.state.errors.emailAddress
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                name="emailAddress"
                                                value={this.state.emailAddress}
                                                onChange={(e) => this.handleInputChange(e)}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6 mt-2">
                                            <label> 
                                            </label>
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" value={this.state.nasabah_prioritas} />
                                                <label className="form-check-label" htmlFor="exampleCheck1">Nasabah Prioritas</label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Pendidikan</label>
                                            <select
                                                className="form-control"
                                                // onChange={(e) => this.handleInputChange(e)}
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_customerData')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="customerEducation"
                                                value={this.state.customerEducation}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Pendidikan terakhir
                                                </option>
                                                {this.state._fields.ePendidikan &&
                                                this.state._fields.ePendidikan.length > 0
                                                    ? this.state._fields.ePendidikan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={data.reference_desc}
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>NPWP</label>
                                            <div className="row ml-0">
                                                <div className="form-check col-md-3">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1NPWP" />
                                                    <label className="form-check-label" htmlFor="exampleCheck1NPWP">Memiliki NPWP</label>
                                                </div>
                                                <div className="form-group col-md-9">
                                                    {/* <label
                                                        className={
                                                            this.state.errors.personalNpwpNumber
                                                                ? 'text-danger'
                                                                : ''
                                                        }
                                                    >
                                                        Nomor NPWP{' '}
                                                        <span className="badge badge-secondary">Wajib</span>
                                                    </label> */}
                                                    <input
                                                        placeholder="Masukan Nomor NPWP"
                                                        className={`form-control ${
                                                            this.state.errors.personalNpwpNumber
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        name="personalNpwpNumber"
                                                        value={
                                                            this.state._personalIdData?.personalNpwpNumber
                                                        }
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_personalIdData'
                                                            )
                                                        }
                                                        readOnly={this.state.mode === 'info' ? true : false}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Status Keterkaitan</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) => this.handleInputChange(e)}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="customerBankRelation"
                                                value={this.state.customerBankRelation}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Status Keterkaitan dengan Bank
                                                </option>
                                                {this.state._fields.eStatusKeterkaitan &&
                                                this.state._fields.eStatusKeterkaitan.length > 0
                                                    ? this.state._fields.eStatusKeterkaitan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={data.reference_desc}
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Referal Nasabah</label>
                                            <input
                                                placeholder="Masukan Referal Nasabah"
                                                className={`form-control ${
                                                    this.state.errors.personalReferralCode
                                                        ? 'is-invalid'
                                                        : ''
                                                }`}
                                                name="personalReferralCode"
                                                value={this.state.personalReferralCode}
                                                onChange={(e) => this.handleInputChange(e)}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                        </div>
                                        {/* <div className="form-group col-md-6">
                                            <label>PIC Nasabah</label>
                                            <input
                                                placeholder="Bila ada"
                                                className="form-control"
                                                value=""
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>Nomor KITAS</label>
                                            <input
                                                placeholder="Nomor KITAS / Surat Izin Tinggal Sementara"
                                                className="form-control"
                                                value=""
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>Wilayah Referensi</label>
                                            <select
                                                aria-readonly="false"
                                                className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            >
                                                <option value="">Pilih Wilayah Referensi</option>
                                            </select>
                                        </div> */}
                                        <div className="form-group col-md-6">
                                            <label>Jenis Penduduk</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) => this.handleInputChange(e)}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="populationType"
                                                value={this.state.populationType}
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Jenis Penduduk
                                                </option>
                                                {this.state._fields.eJenisPenduduk &&
                                                this.state._fields.eJenisPenduduk.length > 0
                                                    ? this.state._fields.eJenisPenduduk.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={data.reference_desc}
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Marketing</label>
                                            <div className="row">
                                                <div className="col-4">
                                                    <input
                                                        placeholder="Kode Marketing"
                                                        className="form-control"
                                                        name="personalMarketingOfficer"
                                                        value={
                                                            this.state._personalIdData
                                                                ?.personalMarketingOfficer
                                                        }
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_personalIdData'
                                                            )
                                                        }
                                                        disabled={
                                                            this.state.mode == 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="col-8">
                                                    <input
                                                        placeholder="Nama Marketing"
                                                        className="form-control"
                                                        name="personalMarketingOfficer"
                                                        value={
                                                            this.state._personalIdData
                                                                ?.personalMarketingOfficer
                                                        }
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_personalIdData'
                                                            )
                                                        }
                                                        readOnly
                                                    />  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Keterangan</label>
                                            <textarea
                                                rows="3"
                                                className="form-control"
                                                placeholder="Bila ada"
                                                name="notes"
                                                value={this.state.notes}
                                                onChange={(e) => this.handleInputChange(e)}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            ></textarea>
                                        </div>
                                        {/* <div className="form-group col-md-6">
                                            <label>Status Keterkaitan</label>
                                            <select
                                                aria-readonly="false"
                                                className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            >
                                                <option value="">
                                                    Pilih status keterkaitan dengan bank
                                                </option>
                                            </select>
                                        </div> */}
                                       
                                        {/* <div className="form-group col-md-6">
                                            <label>Kode Referal</label>
                                            <input
                                                placeholder="Masukan Kode Referal"
                                                className="form-control"
                                                name="personalReferalCode"
                                                value={
                                                    this.state._personalIdData?.personalReferalCode
                                                }
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_personalIdData'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                        </div> */}
                                        {/* <div className="form-group col-md-6">
                                            <label>Jenis Penduduk</label>
                                            <select
                                                aria-readonly="false"
                                                className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            >
                                                <option value="">Pilih jenis penduduk</option>
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {this.state.step == 1 ? (
                            <div className="bns-form">
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Data Pekerjaan</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Pekerjaan</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="personalOccupation"
                                                value={
                                                    this.state._occupationInfo?.personalOccupation
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Pekerjaan
                                                </option>
                                                {this.state._fields.personalOccupation &&
                                                this.state._fields.personalOccupation.length > 0
                                                    ? this.state._fields.personalOccupation.map(
                                                        (data, index) => {
                                                            return (
                                                                <option
                                                                    disabled={
                                                                        this.state.mode == 'info'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={
                                                                        // data.reference_code +
                                                                        // ' - ' +
                                                                        data.reference_desc
                                                                    }
                                                                >
                                                                    {data.reference_desc}
                                                                </option>
                                                            );
                                                        }
                                                    )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Jabatan Pekerjaan</label>
                                            <input
                                                placeholder="Masukkan Jabatan Pekerjaan"
                                                className="form-control"
                                                onChange={(e) => {
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    );
                                                }}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="personalJobPosition"
                                                value={this.state._occupationInfo?.personalJobPosition}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Sektor Ekonomi</label>
                                            <select
                                                className={`form-control`}
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="personalTypeOfWork"
                                                value={
                                                    this.state._occupationInfo?.personalTypeOfWork
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Sektor Ekonomi
                                                </option>
                                                {this.state._fields.R_SEKTOR_EKONOMI &&
                                                this.state._fields.R_SEKTOR_EKONOMI.length > 0
                                                    ? this.state._fields.R_SEKTOR_EKONOMI.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                        //   data.reference_code +
                                                                        //   ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        {/* <div className="form-group">
                                            <label>Jenis Pekerjaan</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="personalTypeOfWork"
                                                value={
                                                    this.state._occupationInfo?.personalTypeOfWork
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Jenis Pekerjaan
                                                </option>
                                                {this.state._fields.R_PEKERJAAN &&
                                                this.state._fields.R_PEKERJAAN.length > 0
                                                    ? this.state._fields.R_PEKERJAAN.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label>Penghasilan Bulanan</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="personalMonthlyIncome"
                                                value={
                                                    this.state._occupationInfo
                                                        ?.personalMonthlyIncome
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Penghasilan Bulanan
                                                </option>
                                                {this.state._fields.ePendapatanPerBulan &&
                                                this.state._fields.ePendapatanPerBulan.length > 0
                                                    ? this.state._fields.ePendapatanPerBulan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode == 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div> */}
                                        <div className="form-group">
                                            <label>Telepon 1</label>
                                            <div className="row">
                                                {/* <div className="col-2">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                    />
                                                </div> */}
                                                <div className="col-10">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_companyAddress'
                                                            );
                                                        }}
                                                        readOnly={this.state.mode === 'info' ? true : false}
                                                        name="companyAddressPhoneNumber"
                                                        value={this.state._companyAddress?.companyAddressPhoneNumber}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Telepon 2</label>
                                            <div className="row">
                                                {/* <div className="col-2">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                    />
                                                </div> */}
                                                <div className="col-10">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_companyAddress'
                                                            );
                                                        }}
                                                        readOnly={this.state.mode === 'info' ? true : false}
                                                        name="companyAddressPhoneNumber2"
                                                        value={this.state._companyAddress?.companyAddressPhoneNumber2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1PEP" />
                                                <label className="form-check-label" htmlFor="exampleCheck1PEP">Status PEP</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Detail PEP</label>
                                            <select
                                                className={`form-control`}
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        'dataPekerjaan'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="detailPEP"
                                                value={
                                                    this.state.dataPekerjaan?.detailPEP
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih detail PEP
                                                </option>
                                                {this.state._fields.dataPekerjaan &&
                                                this.state._fields.dataPekerjaan.length > 0
                                                    ? this.state._fields.dataPekerjaan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="keterkaitanPEP" />
                                                <label className="form-check-label" htmlFor="keterkaitanPEP">Status Keterkaitan PEP</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Detail Keterkaitan PEP</label>
                                            <select
                                                className={`form-control`}
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        'dataPerkejaan'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="keterkaitanPEP"
                                                value={
                                                    this.state.dataPerkerjaan?.keterkaitanPEP
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih detail Keterkaitan PEP
                                                </option>
                                                {this.state._fields.dataPekerjaan &&
                                                this.state._fields.dataPekerjaan.length > 0
                                                    ? this.state._fields.dataPekerjaan.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Nama Perusahaan</label>
                                            <input
                                                placeholder="Masukkan nama perusahaan"
                                                className="form-control"
                                                onChange={(e) => {
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    );
                                                }}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="companyName"
                                                value={this.state._occupationInfo?.companyName}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Badan Hukum</label>
                                            <select
                                                className={`form-control`}
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="legalEntity"
                                                value={
                                                    this.state._occupationInfo?.legalEntity
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Badan Hukum Perusahaan
                                                </option>
                                                {this.state._fields.eBadanHukum &&
                                                this.state._fields.eBadanHukum.length > 0
                                                    ? this.state._fields.eBadanHukum.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                        //   data.reference_code +
                                                                        //   ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Alamat</label>
                                            <textarea
                                                rows="5"
                                                className="form-control"
                                                placeholder="Masukan Alamat"
                                                name="companyAddress"
                                                value={this.state.companyAddress}
                                                onChange={(e) => this.handleInputChange(
                                                    e,
                                                    true,
                                                    '_companyAddress'
                                                )}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            ></textarea>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Provinsi</label>
                                                <select
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _companyAddress: {
                                                                ...this.state._companyAddress,
                                                                companyAddressCity: null,
                                                                companyAddressSubDistrict: null,
                                                                companyAddressVillage: null,
                                                                companyAddressPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _companyAddress: {
                                                                    ...this.state._fields
                                                                        ._companyAddress,
                                                                    cities: [],
                                                                    subdistricts: [],
                                                                    urbans: [],
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_companyAddress'
                                                        );
                                                        this.getCities(
                                                            '_companyAddress',
                                                            e.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="companyAddressProvince"
                                                    value={
                                                        this.state._companyAddress
                                                            ?.companyAddressProvince
                                                    }
                                                >
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={
                                                            this.state._companyAddress
                                                                ?.companyAddressProvince
                                                        }
                                                    >
                                                        {this.state._companyAddress
                                                            ?.companyAddressProvince === null ||
                                                        this.state._companyAddress
                                                            ?.companyAddressProvince === ''
                                                            ? 'Pilih Provinsi'
                                                            : this.state._companyAddress
                                                                  ?.companyAddressProvince}
                                                    </option>
                                                    {this.state._fields._companyAddress.provinces &&
                                                    this.state._fields._companyAddress.provinces
                                                        .length > 0
                                                        ? this.state._fields._companyAddress.provinces.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data.province_code}
                                                                      >
                                                                          {data.province_name}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Kota/Kab </label>
                                                <select
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _companyAddress: {
                                                                ...this.state._companyAddress,
                                                                companyAddressSubDistrict: null,
                                                                companyAddressVillage: null,
                                                                companyAddressPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _companyAddress: {
                                                                    ...this.state._fields
                                                                        ._companyAddress,
                                                                    subdistricts: [],
                                                                    urbans: [],
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_companyAddress'
                                                        );
                                                        this.getSubDistricts(
                                                            '_companyAddress',
                                                            e.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="companyAddressCity"
                                                    value={
                                                        this.state._companyAddress
                                                            ?.companyAddressCity
                                                    }
                                                >
                                                    {this.state.mode === 'create' ? (
                                                        <option value="">
                                                            Pilih Kabupaten/Kota
                                                        </option>
                                                    ) : (
                                                        <option
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                this.state._companyAddress
                                                                    ?.companyAddressCity
                                                            }
                                                        >
                                                            {this.state._companyAddress
                                                                ?.companyAddressCity === null ||
                                                            this.state._companyAddress
                                                                ?.companyAddressCity === ''
                                                                ? 'Pilih Kabupaten/Kota'
                                                                : this.state._companyAddress
                                                                      ?.companyAddressCity}
                                                        </option>
                                                    )}
                                                    {this.state._fields._companyAddress.cities &&
                                                    this.state._fields._companyAddress.cities
                                                        .length > 0
                                                        ? this.state._fields._companyAddress.cities.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data}
                                                                      >
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Kecamatan</label>
                                                <select
                                                    placeholder="Masukkan Kecamatan"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _companyAddress: {
                                                                ...this.state._companyAddress,
                                                                companyAddressVillage: null,
                                                                companyAddressPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _companyAddress: {
                                                                    ...this.state._fields
                                                                        ._companyAddress,
                                                                    urbans: [],
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_companyAddress'
                                                        );
                                                        this.getUrbans(
                                                            '_companyAddress',
                                                            e.target.value,
                                                            this.state._companyAddress
                                                                .companyAddressCity
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="companyAddressSubDistrict"
                                                    value={
                                                        this.state._companyAddress
                                                            ?.companyAddressSubDistrict
                                                    }
                                                >
                                                    {this.state.mode === 'create' ? (
                                                        <option value="">Pilih Kecamatan</option>
                                                    ) : (
                                                        <option
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                this.state._companyAddress
                                                                    ?.companyAddressSubDistrict
                                                            }
                                                        >
                                                            {this.state._companyAddress
                                                                ?.companyAddressSubDistrict ===
                                                                null ||
                                                            this.state._companyAddress
                                                                ?.companyAddressSubDistrict === ''
                                                                ? 'Pilih Kecamatan'
                                                                : this.state._companyAddress
                                                                      ?.companyAddressSubDistrict}
                                                        </option>
                                                    )}
                                                    {this.state._fields._companyAddress
                                                        .subdistricts &&
                                                    this.state._fields._companyAddress.subdistricts
                                                        .length > 0
                                                        ? this.state._fields._companyAddress.subdistricts.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data}
                                                                      >
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Kelurahan</label>
                                                <select
                                                    placeholder="Masukkan Kelurahan"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _companyAddress: {
                                                                ...this.state._companyAddress,
                                                                companyAddressPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _companyAddress: {
                                                                    ...this.state._fields
                                                                        ._companyAddress,
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_companyAddress'
                                                        );
                                                        this.getPostalsCode(
                                                            '_companyAddress',
                                                            this.state._companyAddress
                                                                .companyAddressCity,
                                                            this.state._companyAddress
                                                                .companyAddressSubDistrict,
                                                            e.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="companyAddressVillage"
                                                    value={
                                                        this.state._companyAddress
                                                            ?.companyAddressVillage
                                                    }
                                                >
                                                    {this.state.mode === 'create' ? (
                                                        <option value="">Pilih Kelurahan</option>
                                                    ) : (
                                                        <option
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                this.state._companyAddress
                                                                    ?.companyAddressVillage
                                                            }
                                                        >
                                                            {this.state._companyAddress
                                                                ?.companyAddressVillage === null ||
                                                            this.state._companyAddress
                                                                ?.companyAddressVillage === ''
                                                                ? 'Pilih Kelurahan'
                                                                : this.state._companyAddress
                                                                      ?.companyAddressVillage}
                                                        </option>
                                                    )}
                                                    {this.state._fields._companyAddress.urbans &&
                                                    this.state._fields._companyAddress.urbans
                                                        .length > 0
                                                        ? this.state._fields._companyAddress.urbans.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data}
                                                                      >
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>RT / RW</label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Masukkan RT"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_companyAddress'
                                                            );
                                                        }}
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="companyAddressRtRw"
                                                        value={
                                                            this.state._companyAddress
                                                                ?.companyAddressRtRw
                                                        }
                                                    />
                                                </div>
                                                {/* <div className="col-6">
                                                    <input
                                                        placeholder="Masukkan RW"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_companyAddress'
                                                            );
                                                        }}
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="companyAddressRtRw"
                                                        value={
                                                            this.state._companyAddress
                                                                ?.companyAddressRtRw
                                                        }
                                                    />
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Kode Pos</label>
                                            <select
                                                placeholder="Masukkan Kode Pos"
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_companyAddress'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="companyAddressPostalCode"
                                                value={
                                                    this.state._companyAddress
                                                        ?.companyAddressPostalCode
                                                }
                                            >
                                                {this.state.mode === 'create' ? (
                                                    <option value="">Pilih Kode Pos</option>
                                                ) : (
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={
                                                            this.state._companyAddress
                                                                ?.companyAddressPostalCode
                                                        }
                                                    >
                                                        {this.state._companyAddress
                                                            ?.companyAddressPostalCode === null ||
                                                        this.state._companyAddress
                                                            ?.companyAddressPostalCode === ''
                                                            ? 'Pilih Kecamatan'
                                                            : this.state._companyAddress
                                                                  ?.companyAddressPostalCode}
                                                    </option>
                                                )}
                                                {this.state._fields._companyAddress.postalscode &&
                                                this.state._fields._companyAddress.postalscode
                                                    .length > 0
                                                    ? this.state._fields._companyAddress.postalscode.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option value={data}>
                                                                      {data}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
{/* 
                                        <div className="form-group">
                                            <label>No Handphone</label>
                                            <input
                                                placeholder="Masukkan nomor handphone"
                                                className="form-control"
                                                onChange={(e) => {
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_companyAddress'
                                                    );
                                                }}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="companyAddressPhoneNumber"
                                                value={
                                                    this.state._companyAddress
                                                        ?.companyAddressPhoneNumber
                                                }
                                            />
                                        </div> */}
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Kegiatan Usaha</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                    <div className="form-group">
                                            <label>Kegiatan Usaha</label>
                                            <select
                                                className={`form-control`}
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="Businessactivities"
                                                value={
                                                    this.state._occupationInfo?.Businessactivities
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Kegiatan Usaha
                                                </option>
                                                {this.state._fields.Businessactivities &&
                                                this.state._fields.Businessactivities.length > 0
                                                    ? this.state._fields.Businessactivities.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Bidang Usaha</label>
                                            <select
                                                className={`form-control`}
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_occupationInfo'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="bidangUsaha"
                                                value={
                                                    this.state._occupationInfo?.bidangUsaha
                                                }
                                            >
                                                <option
                                                    value=""
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                >
                                                    Pilih Bidang Usaha
                                                </option>
                                                {this.state._fields.R_BIDANG_USAHA &&
                                                this.state._fields.R_BIDANG_USAHA.length > 0
                                                    ? this.state._fields.R_BIDANG_USAHA.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option
                                                                      disabled={
                                                                          this.state.mode === 'info'
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      value={
                                                                          data.reference_code +
                                                                          ' - ' +
                                                                          data.reference_desc
                                                                      }
                                                                  >
                                                                      {data.reference_desc}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                                <label>Lokasi Usaha</label>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <input
                                                            placeholder="Kode Lokasi"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                this.handleInputChange(
                                                                    e,
                                                                    true,
                                                                    '_companyAddress'
                                                                );
                                                            }}
                                                            readOnly={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="lokasiUsaha"
                                                            value={
                                                                this.state._companyAddress
                                                                    ?.lokasiUsaha
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <input
                                                            placeholder="Keterangan Lokasi"
                                                            className="form-control"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row mt-4 mb-2">
                                            <div className="col-auto text-primary">
                                                Alamat Identitas
                                            </div>
                                            <div className="col">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                className={
                                                    this.state.errors.ktpAddress
                                                        ? 'text-danger'
                                                        : ''
                                                }
                                            >
                                                Alamat{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <textarea
                                                rows="3"
                                                className={`form-control ${
                                                    this.state.errors.ktpAddress ? 'is-invalid' : ''
                                                }`}
                                                placeholder="Masukkan alamat"
                                                onChange={(e) => {
                                                    this.handleInputChange(e, true, '_ktpAddress');
                                                }}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="ktpAddress"
                                                value={this.state._ktpAddress?.ktpAddress}
                                            ></textarea>
                                            <div className="invalid-feedback">
                                                Bidang ini wajib diisi.
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label
                                                    className={`${
                                                        this.state.errors.ktpProvince
                                                            ? 'text-danger'
                                                            : ''
                                                    }`}
                                                >
                                                    Provinsi{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select
                                                    className={`form-control ${
                                                        this.state.errors.ktpProvince
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _ktpAddress: {
                                                                ...this.state._ktpAddress,
                                                                ktpCity: null,
                                                                ktpSubDistrict: null,
                                                                ktpVillage: null,
                                                                ktpPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _ktpAddress: {
                                                                    ...this.state._fields
                                                                        ._ktpAddress,
                                                                    cities: [],
                                                                    subdistricts: [],
                                                                    urbans: [],
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_ktpAddress'
                                                        );
                                                        this.getCities(
                                                            '_ktpAddress',
                                                            e.target.value
                                                        );
                                                    }}
                                                    disabled={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="ktpProvince"
                                                    value={this.state._ktpAddress?.ktpProvince}
                                                >
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={this.state._ktpAddress?.ktpProvince}
                                                    >
                                                        {!this.state._ktpAddress?.ktpProvince
                                                            ? 'Pilih Provinsi'
                                                            : this.state._ktpAddress?.ktpProvince}
                                                    </option>
                                                    {this.state._fields._ktpAddress.provinces &&
                                                    this.state._fields._ktpAddress.provinces
                                                        .length > 0
                                                        ? this.state._fields._ktpAddress.provinces.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data.province_code}
                                                                      >
                                                                          {data.province_name}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <label
                                                    className={`${
                                                        this.state.errors.ktpCity
                                                            ? 'text-danger'
                                                            : ''
                                                    }`}
                                                >
                                                    Kota/Kab{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select
                                                    className={`form-control ${
                                                        this.state.errors.ktpCity
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _ktpAddress: {
                                                                ...this.state._ktpAddress,
                                                                ktpSubDistrict: null,
                                                                ktpVillage: null,
                                                                ktpPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _ktpAddress: {
                                                                    ...this.state._fields
                                                                        ._ktpAddress,
                                                                    subdistricts: [],
                                                                    urbans: [],
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_ktpAddress'
                                                        );
                                                        this.getSubDistricts(
                                                            '_ktpAddress',
                                                            e.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="ktpCity"
                                                    value={this.state._ktpAddress?.ktpCity}
                                                >
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={this.state._ktpAddress?.ktpCity}
                                                    >
                                                        {this.state._ktpAddress?.ktpCity === null ||
                                                        this.state._ktpAddress?.ktpCity === ''
                                                            ? 'Pilih Kabupaten / Kota'
                                                            : this.state._ktpAddress?.ktpCity}
                                                    </option>
                                                    {this.state._fields._ktpAddress.cities &&
                                                    this.state._fields._ktpAddress.cities.length > 0
                                                        ? this.state._fields._ktpAddress.cities.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data}
                                                                      >
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label
                                                    className={`${
                                                        this.state.errors.ktpSubDistrict
                                                            ? 'text-danger'
                                                            : ''
                                                    }`}
                                                >
                                                    Kecamatan{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select
                                                    placeholder="Masukkan Kecamatan"
                                                    className={`form-control ${
                                                        this.state.errors.ktpSubDistrict
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _ktpAddress: {
                                                                ...this.state._ktpAddress,
                                                                ktpVillage: null,
                                                                ktpPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _ktpAddress: {
                                                                    ...this.state._fields
                                                                        ._ktpAddress,
                                                                    urbans: [],
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_ktpAddress'
                                                        );
                                                        this.getUrbans(
                                                            '_ktpAddress',
                                                            e.target.value,
                                                            this.state._ktpAddress?.ktpCity
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="ktpSubDistrict"
                                                    value={this.state._ktpAddress?.ktpSubDistrict}
                                                >
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={
                                                            this.state._ktpAddress?.ktpSubDistrict
                                                        }
                                                    >
                                                        {this.state._ktpAddress?.ktpSubDistrict ===
                                                            null ||
                                                        this.state._ktpAddress?.ktpSubDistrict ===
                                                            ''
                                                            ? 'Pilih Kecamatan'
                                                            : this.state._ktpAddress
                                                                  ?.ktpSubDistrict}
                                                    </option>
                                                    {this.state._fields._ktpAddress.subdistricts &&
                                                    this.state._fields._ktpAddress.subdistricts
                                                        .length > 0
                                                        ? this.state._fields._ktpAddress.subdistricts.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data}
                                                                      >
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <label
                                                    className={`${
                                                        this.state.errors.ktpVillage
                                                            ? 'text-danger'
                                                            : ''
                                                    }`}
                                                >
                                                    Kelurahan{' '}
                                                    <span className="badge badge-secondary">
                                                        Wajib
                                                    </span>
                                                </label>
                                                <select
                                                    placeholder="Masukkan Kelurahan"
                                                    className={`form-control ${
                                                        this.state.errors.ktpVillage
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _ktpAddress: {
                                                                ...this.state._ktpAddress,
                                                                ktpPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _ktpAddress: {
                                                                    ...this.state._fields
                                                                        ._ktpAddress,
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_ktpAddress'
                                                        );
                                                        this.getPostalsCode(
                                                            '_ktpAddress',
                                                            this.state._ktpAddress.ktpCity,
                                                            this.state._ktpAddress.ktpSubDistrict,
                                                            e.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="ktpVillage"
                                                    value={this.state._ktpAddress?.ktpVillage}
                                                >
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={this.state._ktpAddress?.ktpVillage}
                                                    >
                                                        {this.state._ktpAddress?.ktpVillage ===
                                                            null ||
                                                        this.state._ktpAddress?.ktpVillage === ''
                                                            ? 'Pilih Kelurahan'
                                                            : this.state._ktpAddress?.ktpVillage}
                                                    </option>
                                                    {this.state._fields._ktpAddress.urbans &&
                                                    this.state._fields._ktpAddress.urbans.length > 0
                                                        ? this.state._fields._ktpAddress.urbans.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data}
                                                                      >
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label
                                                className={`${
                                                    this.state.errors.ktpRtRw ? 'text-danger' : ''
                                                }`}
                                            >
                                                RT / RW{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        type="text"
                                                        placeholder="Masukkan RT"
                                                        className={`form-control ${
                                                            this.state.errors.ktpRtRw
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_ktpAddress'
                                                            );
                                                        }}
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="ktpRtRw"
                                                        value={this.state._ktpAddress?.ktpRtRw}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div>
                                                {/* <div className="col-6">
                                                    <input
                                                        type="text"
                                                        placeholder="Masukkan RW"
                                                        className={`form-control ${
                                                            this.state.errors.ktpRtRw
                                                                ? 'is-invalid'
                                                                : ''
                                                        }`}
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_ktpAddress'
                                                            );
                                                        }}
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="ktpRtRw"
                                                        value={this.state._ktpAddress?.ktpRtRw}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Bidang ini wajib diisi.
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Kode Pos</label>
                                            <select
                                                placeholder="Masukkan Kode Pos"
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(e, true, '_ktpAddress')
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="ktpPostalCode"
                                                value={this.state._ktpAddress?.ktpPostalCode}
                                            >
                                                {this.state.mode === 'create' ? (
                                                    <option value="">Pilih Kode Pos</option>
                                                ) : (
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={
                                                            this.state._ktpAddress?.ktpPostalCode
                                                        }
                                                    >
                                                        {this.state._ktpAddress.ktpPostalCode}
                                                    </option>
                                                )}
                                                {this.state._fields._ktpAddress.postalscode &&
                                                this.state._fields._ktpAddress.postalscode.length >
                                                    0
                                                    ? this.state._fields._ktpAddress.postalscode.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option value={data}>
                                                                      {data}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Telepon Rumah 1</label>
                                            <div className="row">
                                                {/* <div className="col-2">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                        readOnly={this.state.mode === 'info' ? true : false}
                                                        name="homePhone"
                                                        value={this.state._companyAddress?.homePhone}
                                                    />
                                                </div> */}
                                                <div className="col-12">
                                                    <input
                                                         placeholder="000"
                                                         className="form-control"
                                                         onChange={(e) => {
                                                             this.handleInputChange(e);
                                                         }}
                                                         readOnly={this.state.mode === 'info' ? true : false}
                                                         name="homePhone"
                                                         value={this.state.homePhone}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Telepon Rumah 2</label>
                                            <div className="row">
                                                {/* <div className="col-2">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                    />
                                                </div> */}
                                                <div className="col-12">
                                                    <input
                                                         placeholder="000"
                                                         className="form-control"
                                                         onChange={(e) => {
                                                             this.handleInputChange(e);
                                                         }}
                                                         readOnly={this.state.mode === 'info' ? true : false}
                                                         name="mobilePhone"
                                                         value={this.state.mobilePhone}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Fax</label>
                                            <div className="row">
                                                <div className="col-2">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        readOnly={this.state.mode === 'info' ? true : false}
                                                    />
                                                </div>
                                                <div className="col-10">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        readOnly={this.state.mode === 'info' ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>No Handphone</label>
                                            <input
                                                placeholder="Masukkan nomor handphone"
                                                className="form-control"
                                                onChange={(e) => {
                                                    this.handleInputChange(e);
                                                }}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="mobilePhone2"
                                                value={
                                                    this.state.mobilePhone2
                                                }
                                            />
                                        </div>
                                        {/* <div className="form-group">
                                            <label>Negara</label>
                                            <input
                                                placeholder="Masukkan Negara"
                                                className="form-control"
                                                onChange={(e) => {
                                                    this.handleInputChange(e, true, '_ktpAddress');
                                                }}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="ktpCountry"
                                                value={this.state._ktpAddress?.ktpCountry}
                                            />
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label>Telepon Rumah 1</label>
                                            <div className="row">
                                                <div className="col-3">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>-</span>
                                                </div>
                                                <div className="col-8">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Telepon Rumah 2</label>
                                            <div className="row">
                                                <div className="col-3">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>-</span>
                                                </div>
                                                <div className="col-8">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label>Fax</label>
                                            <div className="row">
                                                <div className="col-3">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>-</span>
                                                </div>
                                                <div className="col-8">
                                                    <input
                                                        placeholder="000"
                                                        className="form-control"
                                                        value=""
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-6">
                                        <div className="row mt-4 mb-2">
                                            <div className="col-auto text-primary">
                                                Alamat Domisili
                                            </div>
                                            <div className="col">
                                                <hr />
                                            </div>
                                            {/* <div className="col-auto">
                                                <button className="btn btn-outline-danger btn-sm">
                                                    Salin dari Alamat Identitas
                                                </button>
                                            </div> */}
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Alamat{' '}
                                                {/* <span className="badge badge-secondary">Wajib</span> */}
                                            </label>
                                            <textarea
                                                rows="3"
                                                className="form-control"
                                                placeholder="Masukkan alamat"
                                                onChange={(e) => {
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_mailingAddress'
                                                    );
                                                }}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="mailingAddress"
                                                value={this.state._mailingAddress?.mailingAddress}
                                            ></textarea>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Provinsi</label>
                                                <select
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _mailingAddress: {
                                                                ...this.state._mailingAddress,
                                                                mailingAddressCity: null,
                                                                mailingAddressSubDistrict: null,
                                                                mailingAddressVillage: null,
                                                                mailingAddressPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _mailingAddress: {
                                                                    ...this.state._fields
                                                                        ._mailingAddress,
                                                                    cities: [],
                                                                    subdistricts: [],
                                                                    urbans: [],
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_mailingAddress'
                                                        );
                                                        this.getCities(
                                                            '_mailingAddress',
                                                            e.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="mailingAddressProvince"
                                                    value={
                                                        this.state._mailingAddress
                                                            ?.mailingAddressProvince
                                                    }
                                                >
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={
                                                            this.state._mailingAddress
                                                                ?.mailingAddressProvince
                                                        }
                                                    >
                                                        {this.state._mailingAddress
                                                            ?.mailingAddressProvince === null ||
                                                        this.state._mailingAddress
                                                            ?.mailingAddressProvince === ''
                                                            ? 'Pilih Provinsi'
                                                            : this.state._mailingAddress
                                                                  ?.mailingAddressProvince}
                                                    </option>
                                                    {this.state._fields._mailingAddress.provinces &&
                                                    this.state._fields._mailingAddress.provinces
                                                        .length > 0
                                                        ? this.state._fields._mailingAddress.provinces.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data.province_code}
                                                                      >
                                                                          {data.province_name}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Kota/Kab </label>
                                                <select
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _mailingAddress: {
                                                                ...this.state._mailingAddress,
                                                                mailingAddressSubDistrict: null,
                                                                mailingAddressVillage: null,
                                                                mailingAddressPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _mailingAddress: {
                                                                    ...this.state._fields
                                                                        ._mailingAddress,
                                                                    subdistricts: [],
                                                                    urbans: [],
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_mailingAddress'
                                                        );
                                                        this.getSubDistricts(
                                                            '_mailingAddress',
                                                            e.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="mailingAddressCity"
                                                    value={
                                                        this.state._mailingAddress
                                                            ?.mailingAddressCity
                                                    }
                                                >
                                                    {this.state.mode === 'create' ? (
                                                        <option value="">
                                                            Pilih Kabupaten/Kota
                                                        </option>
                                                    ) : (
                                                        <option
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                this.state._mailingAddress
                                                                    ?.mailingAddressCity
                                                            }
                                                        >
                                                            {this.state._mailingAddress
                                                                ?.mailingAddressCity === null ||
                                                            this.state._mailingAddress
                                                                ?.mailingAddressCity === ''
                                                                ? 'Pilih Kabupaten / Kota'
                                                                : this.state._mailingAddress
                                                                      ?.mailingAddressCity}
                                                        </option>
                                                    )}
                                                    {this.state._fields._mailingAddress.cities &&
                                                    this.state._fields._mailingAddress.cities
                                                        .length > 0
                                                        ? this.state._fields._mailingAddress.cities.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data}
                                                                      >
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Kecamatan</label>
                                                <select
                                                    placeholder="Masukkan Kecamatan"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _mailingAddress: {
                                                                ...this.state._mailingAddress,
                                                                mailingAddressVillage: null,
                                                                mailingAddressPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _mailingAddress: {
                                                                    ...this.state._fields
                                                                        ._mailingAddress,
                                                                    urbans: [],
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_mailingAddress'
                                                        );
                                                        this.getUrbans(
                                                            '_mailingAddress',
                                                            e.target.value,
                                                            this.state._mailingAddress
                                                                ?.mailingAddressCity
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="mailingAddressSubDistrict"
                                                    value={
                                                        this.state._mailingAddress
                                                            ?.mailingAddressSubDistrict
                                                    }
                                                >
                                                    {this.state.mode === 'create' ? (
                                                        <option value="">Pilih Kecamatan</option>
                                                    ) : (
                                                        <option
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                this.state._mailingAddress
                                                                    ?.mailingAddressSubDistrict
                                                            }
                                                        >
                                                            {this.state._mailingAddress
                                                                ?.mailingAddressSubDistrict ===
                                                                null ||
                                                            this.state._mailingAddress
                                                                ?.mailingAddressSubDistrict === ''
                                                                ? 'Pilih Kecamatan'
                                                                : this.state._mailingAddress
                                                                      ?.mailingAddressSubDistrict}
                                                        </option>
                                                    )}
                                                    {this.state._fields._mailingAddress
                                                        .subdistricts &&
                                                    this.state._fields._mailingAddress.subdistricts
                                                        .length > 0
                                                        ? this.state._fields._mailingAddress.subdistricts.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data}
                                                                      >
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Kelurahan</label>
                                                <select
                                                    placeholder="Masukkan Kelurahan"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            _mailingAddress: {
                                                                ...this.state._mailingAddress,
                                                                mailingAddressPostalCode: null,
                                                            },
                                                            _fields: {
                                                                ...this.state._fields,
                                                                _mailingAddress: {
                                                                    ...this.state._fields
                                                                        ._mailingAddress,
                                                                    postalscode: [],
                                                                },
                                                            },
                                                        });
                                                        this.handleInputChange(
                                                            e,
                                                            true,
                                                            '_mailingAddress'
                                                        );
                                                        this.getPostalsCode(
                                                            '_mailingAddress',
                                                            this.state._mailingAddress
                                                                .mailingAddressCity,
                                                            this.state._mailingAddress
                                                                .mailingAddressSubDistrict,
                                                            e.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        this.state.mode === 'info' ? true : false
                                                    }
                                                    name="mailingAddressVillage"
                                                    value={
                                                        this.state._mailingAddress
                                                            ?.mailingAddressVillage
                                                    }
                                                >
                                                    {this.state.mode === 'create' ? (
                                                        <option value="">Pilih Kelurahan</option>
                                                    ) : (
                                                        <option
                                                            disabled={
                                                                this.state.mode === 'info'
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                this.state._mailingAddress
                                                                    ?.mailingAddressVillage
                                                            }
                                                        >
                                                            {this.state._mailingAddress
                                                                ?.mailingAddressVillage === null ||
                                                            this.state._mailingAddress
                                                                ?.mailingAddressVillage === ''
                                                                ? 'Pilih Kelurahan'
                                                                : this.state._mailingAddress
                                                                      ?.mailingAddressVillage}
                                                        </option>
                                                    )}
                                                    {this.state._fields._mailingAddress.urbans &&
                                                    this.state._fields._mailingAddress.urbans
                                                        .length > 0
                                                        ? this.state._fields._mailingAddress.urbans.map(
                                                              (data, index) => {
                                                                  return (
                                                                      <option
                                                                          disabled={
                                                                              this.state.mode ===
                                                                              'info'
                                                                                  ? true
                                                                                  : false
                                                                          }
                                                                          value={data}
                                                                      >
                                                                          {data}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                                {/* <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>RT / RW</label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Masukkan RT"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_mailingAddress'
                                                            );
                                                        }}
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="mailingAddressRtRw"
                                                        value={
                                                            this.state._mailingAddress
                                                                ?.mailingAddressRtRw
                                                        }
                                                    />
                                                </div>
                                                {/* <div className="col-6">
                                                    <input
                                                        placeholder="Masukkan RW"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_mailingAddress'
                                                            );
                                                        }}
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="mailingAddressRw"
                                                        value={
                                                            this.state._mailingAddress
                                                                ?.mailingAddressRw
                                                        }
                                                    />
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Kode Pos</label>
                                            <select
                                                placeholder="Masukkan Kode Pos"
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_mailingAddress'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="mailingAddressPostalCode"
                                                value={
                                                    this.state._mailingAddress
                                                        ?.mailingAddressPostalCode
                                                }
                                            >
                                                {this.state.mode === 'create' ? (
                                                    <option value="">Pilih Kode Pos</option>
                                                ) : (
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={
                                                            this.state._mailingAddress
                                                                ?.mailingAddressPostalCode
                                                        }
                                                    >
                                                        {this.state._mailingAddress
                                                            ?.mailingAddressPostalCode === null ||
                                                        this.state._mailingAddress
                                                            ?.mailingAddressPostalCode === ''
                                                            ? 'Pilih Kodepos'
                                                            : this.state._mailingAddress
                                                                  ?.mailingAddressPostalCode}
                                                    </option>
                                                )}
                                                {this.state._fields._mailingAddress.postalscode &&
                                                this.state._fields._mailingAddress.postalscode
                                                    .length > 0
                                                    ? this.state._fields._mailingAddress.postalscode.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option value={data}>
                                                                      {data}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Status Tempat Tinggal</label>
                                            <select
                                                placeholder="Masukkan Kode Pos"
                                                className="form-control"
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_mailingAddress'
                                                    )
                                                }
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="statusTempatTinggal"
                                                value={
                                                    this.state._mailingAddress
                                                        ?.statusTempatTinggal
                                                }
                                            >
                                                {this.state.mode === 'create' ? (
                                                    <option value="">Pilih Status Tempat Tinggal</option>
                                                ) : (
                                                    <option
                                                        disabled={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        value={
                                                            this.state._mailingAddress
                                                                ?.mailingAddressPostalCode
                                                        }
                                                    >
                                                        {this.state._mailingAddress
                                                            ?.mailingAddressPostalCode === null ||
                                                        this.state._mailingAddress
                                                            ?.mailingAddressPostalCode === ''
                                                            ? 'Pilih Kodepos'
                                                            : this.state._mailingAddress
                                                                  ?.mailingAddressPostalCode}
                                                    </option>
                                                )}
                                                {this.state._fields._mailingAddress.postalscode &&
                                                this.state._fields._mailingAddress.postalscode
                                                    .length > 0
                                                    ? this.state._fields._mailingAddress.postalscode.map(
                                                          (data, index) => {
                                                              return (
                                                                  <option value={data}>
                                                                      {data}
                                                                  </option>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Lama Menempati Tempat Tinggal</label>
                                            <div className="row">
                                                <form className="form-inline">
                                                    <div className="form-group mb-2">
                                                        <input type="number" className="form-control" placeholder="0"
                                                            readOnly={this.state.mode === 'info' ? true : false}
                                                        />
                                                        <label>- Bulan</label>
                                                    </div>
                                                    <div className="form-group mb-2">
                                                        <input type="number" className="form-control" placeholder="0"
                                                            readOnly={this.state.mode === 'info' ? true : false}
                                                        />
                                                        <label>- Tahun</label>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
{/* 
                                        <div className="form-group">
                                            <label>No Handphone</label>
                                            <input
                                                placeholder="Masukkan nomor handphone"
                                                className="form-control"
                                                onChange={(e) => {
                                                    this.handleInputChange(
                                                        e,
                                                        true,
                                                        '_mailingAddress'
                                                    );
                                                }}
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                name="mailingRecipientPhoneNumber"
                                                value={
                                                    this.state._mailingAddress
                                                        ?.mailingRecipientPhoneNumber
                                                }
                                            />
                                        </div> */}

                                        {/* <div className="form-group">
                                            <label>Status Tempat Tinggal</label>
                                            <select
                                                aria-readonly="false"
                                                className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            >
                                                <option value="">
                                                    Pilih status tempat tinggal
                                                </option>
                                            </select>
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label>Lama Menempati Tempat Tinggal</label>
                                            <div className="row">
                                                <div className="form-group col-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="0"
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-2">
                                                    <label htmlFor="">Tahun</label>
                                                </div>
                                                <div className="form-group col-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="0"
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-2">
                                                    <label htmlFor="">Bulan</label>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Alamat Luar Negeri</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label>Alamat Luar Negeri</label>
                                        <textarea
                                            rows="5"
                                            className="form-control"
                                            placeholder="Masukan Alamat Luar Negeri"
                                            name="AlamatLuarNegeri"
                                            value={this.state.AlamatLuarNegeri}
                                            onChange={(e) => this.handleInputChange(e)}
                                            readOnly={this.state.mode === 'info' ? true : false}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {this.state.step == 2 ? (
                            <div className="bns-form">
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Data Tambahan</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Keluarga yang dapat dihubungi</label>
                                            <input
                                                placeholder="Masukkan nama keluarga yang dapat dihubungi"
                                                className="form-control"
                                                value=""
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Telepon/No. Hp Keluarga yang dapat dihubungi{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Masukkan telepon/no. Hp Keluarga yang dapat dihubungi"
                                                className="form-control"
                                                value=""
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Penghasilan Utama Per Tahun{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select aria-readonly="false" className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}>
                                                <option value="">
                                                    Pilih penghasilan utama per tahun
                                                </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Tujuan Penggunaan Dana{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select aria-readonly="false" className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}>
                                                <option value="">Pilih Tujuan Penggunaan Dana</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>
                                                Golongan Nasabah{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select aria-readonly="false" className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}>
                                                <option value="">Pilih jenis identitas</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Golongan Debitur{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select aria-readonly="false" className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}>
                                                <option value="">Pilih jenis identitas</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Golongan Pemilik XBRL{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select aria-readonly="false" className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}>
                                                <option value="">Pilih jenis identitas</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label> 
                                            </label>
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="Check1"
                                                    disabled={this.state.mode === 'info' ? true : false} />
                                                <label className="form-check-label" htmlFor="Check1">Tujuan pembukaan rekening hanya untuk penerimaan haji</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label> 
                                            </label>
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="Check2"
                                                    disabled={this.state.mode === 'info' ? true : false} />
                                                <label className="form-check-label" htmlFor="Check2">Tujuan pembukaan rekening terkait program pemerintah</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Status Pajak & SKB Pajak </div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>
                                                Status Kena Pajak{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select aria-readonly="false" className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}>
                                                <option value="">Pilih Status Kena Pajak</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Keterangan Bebas Pajak{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select aria-readonly="false" className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}>
                                                <option value="">Ket Bebas Pajak</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Nomor Surat</label>
                                            <input
                                                placeholder="Masukkan Nomor Surat"
                                                className="form-control"
                                                value=""
                                                readOnly={this.state.mode === 'info' ? true : false}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-6">
                                                    <label>
                                                        Tgl. Terbit
                                                    </label>
                                                    <input
                                                        placeholder="Tgl Terbit"
                                                        className="form-control"
                                                        type="date"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_personalIdData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="personalIdDateOfIssue"
                                                        value={
                                                            this.state._personalIdData
                                                                ?.personalIdDateOfIssue
                                                        }
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label>
                                                        Tgl. Berakhir
                                                    </label>
                                                    <input
                                                        placeholder="Tgl Kadaluarsa"
                                                        className="form-control"
                                                        type="date"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_personalIdData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="personalIdExpireDate"
                                                        value={
                                                            this.state._personalIdData
                                                                ?.personalIdExpireDate
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Limit Transaksi</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>
                                            Nominal Setor Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    placeholder="Masukkan Nominal Setor Tunai"
                                                    className="form-control"
                                                    value=""
                                                    readOnly={this.state.mode === 'info' ? true : false}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label htmlFor="">Frekuensi</label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    placeholder="0"
                                                    className="form-control"
                                                    value=""
                                                    readOnly={this.state.mode === 'info' ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Nominal Tarik Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    placeholder="Masukkan Nominal Tarik Tunai"
                                                    className="form-control"
                                                    value=""
                                                    readOnly={this.state.mode === 'info' ? true : false}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label htmlFor="">Frekuensi</label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    placeholder="0"
                                                    className="form-control"
                                                    value=""
                                                    readOnly={this.state.mode === 'info' ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Nominal Setor Non Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    placeholder="Masukkan Nominal Setor Non Tunai"
                                                    className="form-control"
                                                    value=""
                                                    readOnly={this.state.mode === 'info' ? true : false}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label htmlFor="">Frekuensi</label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    placeholder="0"
                                                    className="form-control"
                                                    value=""
                                                    readOnly={this.state.mode === 'info' ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Nominal Tarik Non Tunai{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-7">
                                                <input
                                                    placeholder="Masukkan Nominal Tarik Non Tunai"
                                                    className="form-control"
                                                    value=""
                                                    readOnly={this.state.mode === 'info' ? true : false}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label htmlFor="">Frekuensi</label>
                                            </div>
                                            <div className="col-3">
                                                <input
                                                    placeholder="0"
                                                    className="form-control"
                                                    value=""
                                                    readOnly={this.state.mode === 'info' ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Faktor Resiko</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>
                                                Faktor Resiko{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <select aria-readonly="false" className="form-control"
                                                readOnly={this.state.mode === 'info' ? true : false}
                                                >
                                                <option value="">Pilih Faktor Resiko</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Status Resiko{' '}
                                                <span className="badge badge-secondary">Wajib</span>
                                            </label>
                                            <input
                                                placeholder="Low"
                                                readOnly
                                                className="form-control"
                                                value=""
                                            />
                                        </div>
                                        <div className="alert alert-warning">
                                            Status Resiko akan dikalkulasi berdasar input pada
                                            daftar indikator resiko disamping
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <table className="table table-bordered text-center">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Tingkat Resiko</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Identitas Masalah</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Lokasi Usaha</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Profil Nasabah</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Jumlah Transaksi</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Kegiatan Usaha</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Struktur Kepemilikan</td>
                                                    <td>Low</td>
                                                </tr>
                                                <tr>
                                                    <td>Informasi Lainnya</td>
                                                    <td>Low</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {this.state.step == 3 ? (  
                            <div className="bns-form">
                                <div className="row mt-4 mb-2">
                                    <div className="col-10 text-primary">Data Diri</div>
                                    <div className="col-2">
                                        {this.state.mode !== 'info' && 
                                            <button
                                                className="btn btn-outline-danger"
                                                onClick={() => this.addAccount()}
                                            >
                                                +Tambah Rekening
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>No</th>
                                                <th>Jenis</th>
                                                <th>Nama</th>
                                                <th>No Rekening</th>
                                                <th>Valuta</th>
                                                <th>Sandi Bank</th>
                                                <th>Tgl.Pembukaan</th>
                                                <th>Keterangan</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}

                        {this.state.step == 4 ? (
                        <div className="bns-form">
                            <div className="row mt-4 mb-2">
                                <div className="col-10 text-primary">Relasi Nasabah</div>
                                <div className="col-2">
                                    {this.state.mode !== 'info' && 
                                        <button
                                            className="btn btn-outline-danger"
                                            onClick={() => this.addAccount()}
                                        >
                                            +Tambah Relasi Nasabah
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Tipe Nasabah</th>
                                            <th>Nomor Nasabah</th>
                                            <th>Nama</th>
                                            <th>No Rekening</th>
                                            <th>Jabatan</th>
                                            <th>Alamat</th>
                                            <th>Jenis Identias</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mt-4 mb-2">
                                <div className="col-10 text-primary">Ahli Waris</div>
                                <div className="col-2">
                                    {this.state.mode !== 'info' && 
                                        <button
                                            className="btn btn-outline-danger"
                                            onClick={() => this.addAccount()}
                                        >
                                            +Tambah Ahli Waris
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>No Urut Prioritas</th>
                                            <th>Nama Lengkap</th>
                                            <th>Jenis Kelamin</th>
                                            <th>Hub. Keluarga</th>
                                            <th>Keterangan</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        ) : null}

                        { this.state.step == 5 ? (
                            <div className="bns-form">
                                 <div className="form-group col-6">
                                    <label>Jenis Beneficiary Owner</label>
                                    <select aria-readonly="false" className="form-control"
                                        readOnly={this.state.mode === 'info' ? true : false}
                                    >
                                        <option value="">Individu</option>
                                    </select>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Data Beneficiary</div>
                                    <div className="col"><hr/></div>
                                </div>
                                <div className="row">
                                <div className="col-5">
                                    <div className="form-group">
                                        <label>Nama Lengkap</label>
                                        <input placeholder="Nama lengkap nasabah" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    <div className="form-group">
                                    <label>Tempat / Tanggal Lahir</label>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        placeholder="Tempat Lahir"
                                                        className="form-control"
                                                        type="input"
                                                        readOnly={this.state.mode === 'info' ? true : false}
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>/</span>
                                                </div>
                                                <div className="col-5">
                                                    <input
                                                        placeholder="Tgl Kadaluarsa"
                                                        className="form-control"
                                                        type="date"
                                                        onChange={(e) =>
                                                            this.handleInputChange(
                                                                e,
                                                                true,
                                                                '_personalIdData'
                                                            )
                                                        }
                                                        readOnly={
                                                            this.state.mode === 'info'
                                                                ? true
                                                                : false
                                                        }
                                                        name="personalIdExpireDate"
                                                        value={
                                                            this.state._personalIdData
                                                                ?.personalIdExpireDate
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Jenis Kelamin</label>
                                        <br/>
                                        <div className="form-check-inline">
                                            <label className="form-check-label">
                                            <input type="radio" className="form-check-input" name="optradio" 
                                                disabled={this.state.mode === 'info' ? true : false} />Laki-laki
                                            </label>
                                        </div>
                                        <div className="form-check-inline ml-3">
                                            <label className="form-check-label">
                                            <input type="radio" className="form-check-input" name="optradio" 
                                                disabled={this.state.mode === 'info' ? true : false} />Perempuan
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Agama</label>
                                        <select aria-readonly="false" className="form-control"
                                            readOnly={this.state.mode === 'info' ? true : false}>
                                            <option value="">Pilih Agama</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Status Perkawinan</label>
                                        <select aria-readonly="false" className="form-control"
                                            readOnly={this.state.mode === 'info' ? true : false}>
                                            <option value="">Pilih Status Perkawinan</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Telepon</label>
                                        <div className="row">
                                            <div className="col-3">
                                            <input placeholder="000" className="form-control" value=""
                                                readOnly={this.state.mode === 'info' ? true : false}/>
                                            </div>
                                            <div className="col-1"><span>-</span></div>
                                            <div className="col-8">
                                            <input placeholder="000" className="form-control" value=""
                                                readOnly={this.state.mode === 'info' ? true : false}/>
                                            </div>
                                    </div>
                                    </div>
                                    <div className="form-group">
                                        <label>No Handphone</label>
                                        <input placeholder="Masukkan nomor handphone" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Alamat Identitas</label>
                                        <textarea rows="3" className="form-control" placeholder="Masukkan alamat"
                                            readOnly={this.state.mode === 'info' ? true : false}></textarea>
                                    </div>
                                    <div className="row">
                                    <div className="form-group col-6">
                                        <label>Provinsi <span className="badge badge-secondary">Wajib</span></label>
                                        <input placeholder="Masukkan provinsi" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Kota/Kab <span className="badge badge-secondary">Wajib</span></label>
                                        <input placeholder="Masukkan kota/kabupaten" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="form-group col-6">
                                        <label>Kecamatan <span className="badge badge-secondary">Wajib</span></label>
                                        <input placeholder="Masukkan Kecamatan" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Kelurahan <span className="badge badge-secondary">Wajib</span></label>
                                        <input placeholder="Masukkan Kelurahan" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    </div>
                                    <div className="form-group">
                                    <label>RT / RW</label>
                                    <div className="row">
                                        <div className="col-5">
                                        <input placeholder="Masukkan RT" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                        </div>
                                        <div className="col-1"><span>/</span></div>
                                        <div className="col-6">
                                        <input placeholder="Masukkan RW" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="form-group">
                                    <label>Kode Pos</label>
                                    <input placeholder="Masukkan Kode Pos" className="form-control" value=""
                                        readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                <div className="col-auto text-primary">Alamat Domisili Beneficiary</div>
                                <div className="col"><hr/></div>
                                <div className="col-auto"><button className="btn btn-outline-danger btn-sm"
                                    disabled={this.state.mode === 'info' ? true : false}>Salin dari Alamat Identitas</button></div>
                                </div>
                                <div className="row">
                                <div className="col-5">
                                    <div className="form-group">
                                    <label>Alamat Domisili</label>
                                    <textarea rows="3" className="form-control" placeholder="Masukkan alamat"
                                        readOnly={this.state.mode === 'info' ? true : false}></textarea>
                                    </div>
                                    <div className="row">
                                    <div className="form-group col-6">
                                        <label>Provinsi</label>
                                        <input placeholder="Masukkan provinsi" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Kota/Kab</label>
                                        <input placeholder="Masukkan kota/kabupaten" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="form-group col-6">
                                        <label>Kecamatan</label>
                                        <input placeholder="Masukkan Kecamatan" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Kelurahan</label>
                                        <input placeholder="Masukkan Kelurahan" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    </div>
                                    <div className="form-group">
                                    <label>RT / RW</label>
                                    <div className="row">
                                        <div className="col-5">
                                        <input placeholder="Masukkan RT" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                        </div>
                                        <div className="col-1"><span>/</span></div>
                                        <div className="col-6">
                                        <input placeholder="Masukkan RW" className="form-control" value=""
                                            readOnly={this.state.mode === 'info' ? true : false}/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-6">
                                    <div className="form-group">
                                    <label>Kode Pos</label>
                                    <input placeholder="Masukkan Kode Pos" className="form-control" value=""
                                        readOnly={this.state.mode === 'info' ? true : false}/>
                                    </div>
                                    {/* <div className="form-group">
                                    <label>Penghasilan</label>
                                    <div className="row">
                                        <div className="col-8">
                                        <input placeholder="Masukkan nominal penghasilan" className="form-control" value=""/>
                                        </div>
                                        <div className="col-4">
                                        <input type="text" className="form-control" readOnly/>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="form-group">
                                    <label>Tujuan Penggunaan Dana</label>
                                    <div className="row">
                                        <div className="col-8">
                                        <input placeholder="Masukkan Tujuan Penggunaan Dana" className="form-control" value=""/>
                                        </div>
                                        <div className="col-4">
                                        <input type="text" className="form-control" readOnly/>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="form-group">
                                    <label>Sumber Dana</label>
                                    <div className="row">
                                        <div className="col-8">
                                        <input placeholder="Masukkan sumber dana" className="form-control" value=""/>
                                        </div>
                                        <div className="col-4">
                                        <input type="text" className="form-control" readOnly/>
                                        </div>
                                    </div>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                        ) : null }
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-2">
                                {this.state.mode != 'info' &&
                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={() => this.resetStep()}
                                    >
                                        Reset
                                    </button>
                                }
                            </div>
                            <div className="col-10 text-right">
                                <button
                                    disabled={this.state.step == 0 ? true : false}
                                    className={
                                        'btn mr-1 ' +
                                        (this.state.step == 0
                                            ? 'btn-secondary'
                                            : 'btn-orange-primary')
                                    }
                                    onClick={() => this.setState({ step: this.state.step - 1 })}
                                >
                                    Sebelumnya
                                </button>
                                <button
                                    disabled={this.state.step == 5 ? true : false}
                                    className={
                                        'btn mr-1 ' +
                                        (this.state.step === 5
                                            ? 'btn-secondary'
                                            : 'btn-orange-primary')
                                    }
                                    onClick={() => this.setState({ step: this.state.step + 1 })}
                                >
                                    Selanjutnya
                                </button>
                                {this.state.mode !== 'info' ? (
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => this.saveStep()}
                                    >
                                        Simpan
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                
                <Modal
                    show={this.state.showModalCekTerdaftar}
                    onHide={() => this.setState({ showModalCekTerdaftar: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.dataMessage}</p>
                        <p>Dengan data sebagai berikut :</p>
                        <ul>
                            <li>{this.state.dataValidasi?.customer_name}</li>
                            <li>{this.state.dataValidasi?.customer_birthdate}</li>
                            <li>{this.state.dataValidasi?.identity_number}</li>
                        </ul>
                        <p>Silahkan gunakan nomor nasabah yang sudah terdaftar({this.state.dataValidasi?.nomor_nasabah})</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalCekTerdaftar: false })}
                        >
                            tutup
                        </button>
                    </Modal.Footer>
                </Modal>   

                <Modal
                    show={this.state.showModalCekTidakTerdaftar}
                    onHide={() => this.setState({ showModalCekTidakTerdaftar: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Nasabah Belum Terdaftar.Proses Registrasi bisa dilanjutkan</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalCekTidakTerdaftar: false })}
                        >
                            lanjut
                        </button>
                    </Modal.Footer>
                </Modal>                   

                <Modal
                    show={this.state.showModalConfirm}
                    onHide={() => this.setState({ showModalConfirm: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Lakukan proses Registrasi Nasabah ?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirm: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                // this.state.mode === 'create' ? tCekData() : this.putGroup();
                                this.putPerorangan();
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSuccess}
                    onHide={() => this.setState({ showModalSuccess: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Selesai</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Proses Registrasi Nasabah berhasil dilakukan
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalSuccess: false });
                                this.props.history.push('/data_nasabah/cif');
                            }}
                        >
                            Selesai
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalError}
                    onHide={() => this.setState({ showModalError: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.messageError}</p>
                        <li>{this.state.dataPerorangan?.status}</li>
                        <li>{this.state.dataPeroranganMessage?.remark} </li>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalError: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withRouter(RegistrasiPerorangan);
