import React from 'react';
import { Modal } from 'react-bootstrap';
import { withKeycloak } from '@react-keycloak/web';
import { baseUrl } from './../../../../app.config';
import './style.css';
import { thousandSeparator } from '../../../../utils';
import queryString from 'query-string';
import ModalInfoNegatifNasabah from '../../data_nasabah/info_negatif_modal';

class TarikTunai extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_user: 'teller-1',
            keterangan: null,
            no_referensi: null,
            nilai_transaksi: 0,
            transaction_ch_id: 'null',
            transaction_encoded_key: 'null',
            no_rekening: null,
            valuta: 'IDR',
            nama_nasabah: null,
            cabang_rekening: null,
            kurs_jual: 1,
            kurs_beli: 1,
            kurs_buku: 1,
            nominal: 0,
            nilai_ekuivalen: 0,
            nilai_bulanan: 0,
            biaya: 0,
            biaya_bulanan: 0,
            sumber_biaya: 'tunai',
            showModalConfirm: false,
            showModalSuccess: false,
            showModalError: false,
            showModalWarning: false,
            showModalBatal: false,
            showModalBatalInfo: false,
            productName: null,
            showModalSvs: false,
            showModalInfoNegatif: false,
            nomor_cif: '',
            errors: {
                no_rekening: false,
            },
            messages: {
                no_rekening: '',
            },
            isLoading: false,
            svsData: [],
            svsParams: [
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
            ],
            formNegatifNasabah: {},
        };
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value,
        });
    };

    handleFormatNumber = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        const newValue = thousandSeparator.format(value);

        if (newValue !== 'NaN') {
            this.setState({
                [name]: newValue,
            });
        }
    };

    resetFormInput = () => {
        this.setState({
            no_rekening: '',
            nama_rekening: '',
            valuta: 'IDR',
            productName: '',
            cabang_rekening: '',
            kurs_jual: 1,
            kurs_beli: 1,
            kurs_buku: 1,
            nominal: 0,
            nilai_ekuivalen: 0,
            biaya: 0,
            biaya_bulanan: 0,
            sumber_biaya: 'tunai',
            no_referensi: '',
            keterangan: '',
            showModalBatal: false,
            showModalBatalInfo: true,
        });
    };

    getNomorRekening = (value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/rekening/tabungan/detail/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    this.setState({
                        productName: dataDetail.produk,
                        valuta: dataDetail.valuta,
                        nama_rekening: dataDetail.nama,
                        nama_nasabah: dataDetail.nama,
                        nomor_cif: dataDetail.detail_nasabah.nomor_cif,
                        cabang_rekening:
                            dataDetail.cabang === null || dataDetail.cabang === ''
                                ? '-'
                                : dataDetail.cabang,
                    });
                } else {
                    this.setState({
                        productName: '',
                        valuta: 'IDR',
                        nama_rekening: '',
                        cabang_rekening: '',
                        errors: {
                            ...this.state.error,
                            no_rekening: true,
                        },
                        messages: {
                            ...this.state.error,
                            no_rekening: 'Nomor rekening tidak ditemukan',
                        },
                    });
                }
            });
    };

    postTarikTunai = async () => {
        this.setState({ isLoading: true });

        if (this.state.no_rekening === null) {
            this.setState({
                showModalWarning: true,
                messageWarning: 'Nomor rekening harus diisi',
                isLoading: false,
            });
            return false;
        }

        let body = {
            id_user: this.props.keycloak.idTokenParsed.preferred_username,
            keterangan: this.state.keterangan,
            no_referensi: this.state.no_referensi,
            transaction_ch_id: this.state.transaction_ch_id,
            transaction_encoded_key: this.state.transaction_encoded_key,
            no_rekening: this.state.no_rekening,
            valuta: this.state.valuta,
            nama_nasabah: this.state.nama_nasabah,
            cabang_rekening:
                this.state.cabang_rekening === null || this.state.cabang_rekening === ''
                    ? '-'
                    : this.state.cabang_rekening,
            kurs_jual: thousandSeparator.unformat(this.state.kurs_jual),
            kurs_beli: thousandSeparator.unformat(this.state.kurs_beli),
            kurs_buku: thousandSeparator.unformat(this.state.kurs_buku),
            nominal: thousandSeparator.unformat(this.state.nominal),
            nilai_ekuivalen: thousandSeparator.unformat(this.state.nilai_ekuivalen),
            nilai_bulanan: this.state.nilai_bulanan,
            biaya: thousandSeparator.unformat(this.state.biaya),
            biaya_bulanan: thousandSeparator.unformat(this.state.biaya_bulanan),
            sumber_biaya: this.state.sumber_biaya,
        };
        // console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };
        fetch(baseUrl + '/teller/transaksi/tarik/db/create', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                        isLoading: false,
                    });
                }
            });
    };

    getDataNegatif = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/nasabah/informasi/negatif/find/' + this.state.no_rekening, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    let {
                        nasabahAlamat,
                        nasabahRt,
                        nasabahRw,
                        nasabahKelurahan,
                        nasabahKecamatan,
                        nasabahKotaKabupaten,
                        nasabahProvinsi,
                        nasabahKodePos,
                    } = dataDetail._identitasNasabah;
                    this.setState({
                        formNegatifNasabah: {
                            _negatifNasabah: {
                                keterangan: dataDetail._negatifNasabah.negatifKeterangan,
                                status_data: dataDetail._negatifNasabah.negatifStatus,
                                nama_lengkap: dataDetail.groupName,
                                alamat: `${nasabahAlamat}, RT ${nasabahRt} RW ${nasabahRw}, ${nasabahKelurahan}, ${nasabahKecamatan}, ${nasabahKotaKabupaten}, ${nasabahProvinsi}, ${nasabahKodePos}`,
                                id: dataDetail.id,
                                tgl_approve: dataDetail?.negatifTanggalApprove ?? null,
                            },
                        },
                        showModalInfoNegatif: true,
                    });
                } else {
                    this.setState({
                        formNegatifNasabah: {},
                        showModalInfoNegatif: false,
                    });
                }
            });
    };

    getData = () => {
        const queryParams = queryString.parse(this.props.location.search);
        let id = this.state.nomor_cif;
        let url =
            queryParams.from === 'local'
                ? `/nasabah/svs/local/find-all/${id}`
                : `/nasabah/svs/mambu/find/${id}`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let svsParams = [...this.state.svsParams];
                // console.log(data);
                let dataList = queryParams.from === 'local' ? data.list : data.list?.db?.list;
                if (dataList && dataList.length > 0) {
                    dataList.map(
                        (value, index) =>
                            (svsParams[index] = {
                                data: {
                                    nomor_cif: value.nomor_cif,
                                    encoded_key_document: value.encoded_key_document,
                                    filename: value.filename,
                                    signed_url: value.signed_url,
                                    image_tag: value.image_tag,
                                    remark: value.remark,
                                    type: value.type,
                                },
                                options: {
                                    isChoosedFile: false,
                                    selectedFile: null,
                                    selectedFileUrl: null,
                                },
                            })
                    );
                }
                this.setState({
                    svsData: dataList,
                    svsParams: svsParams,
                });
            });
    };

    render() {
        return (
            <>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Transaksi</li>
                        <li className="breadcrumb-item">
                            <a href="#">Tarik Tunai</a>
                        </li>
                    </ol>
                </nav>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">TARIK TUNAI</h5>
                    </div>
                    <div className="card-body">
                        <div className="bns-form">
                            <div className="row mt-4 mb-2">
                                <div className="col-auto text-primary">Informasi Kas Teller</div>
                                <div className="col">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="">Saldo Kas</label>
                                    <input type="number" class="form-control" readOnly />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="">Valuta Rekening</label>
                                    <div class="form-row">
                                        <div class="col-2 pr0">
                                            <input
                                                type="text"
                                                class="form-control"
                                                value="IDR"
                                                readOnly
                                            />
                                        </div>
                                        <div class="col-10 pl0">
                                            <input
                                                type="text"
                                                class="form-control"
                                                value="INDONESIAN RUPIAH"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bns-form">
                            <div className="row mt-4 mb-2">
                                <div className="col-auto text-primary">Informasi Rekening</div>
                                <div className="col">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <div className="form-group">
                                        <label
                                            className={`${
                                                this.state.errors.no_rekening ? 'text-danger' : ''
                                            }`}
                                        >
                                            No. Rekening
                                        </label>
                                        <div class="form-row">
                                            <div class="col-5 pr0">
                                                <input
                                                    type="text"
                                                    className={`form-control ${
                                                        this.state.errors.no_rekening
                                                            ? 'is-invalid'
                                                            : ''
                                                    }`}
                                                    value={this.state.no_rekening}
                                                    name="no_rekening"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorRekening(e.target.value);
                                                    }}
                                                />
                                                <div className="invalid-feedback">
                                                    {this.state.messages.no_rekening}
                                                </div>
                                            </div>
                                            <div class="col-7 pl0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="NAMA NASABAH"
                                                    readOnly
                                                    value={this.state.nama_rekening}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Valuta Rekening</label>
                                        <div class="form-row">
                                            <div class="col-4 pr0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value={this.state.valuta}
                                                    readOnly
                                                />
                                            </div>
                                            <div class="col-8 pl0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value="INDONESIA RUPIAH"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="">Produk</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={this.state.productName}
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Cabang Rekening</label>
                                        <div class="form-row">
                                            <div class="col-4 pr0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value={this.state.cabang_rekening}
                                                    readOnly
                                                />
                                            </div>
                                            <div class="col-8 pl0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value=""
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <button
                                        className="btn btn-block btn-outline-secondary"
                                        onClick={() => {
                                            this.getData();
                                            this.setState({ showModalSvs: true });
                                        }}
                                        disabled={!this.state.nama_rekening}
                                    >
                                        Lihat Data Grafis/Tanda Tangan
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="bns-form">
                            <div className="row mt-4 mb-2">
                                <div className="col">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-7">
                                    <label htmlFor="">Valuta Transaksi</label>
                                    <div class="form-row">
                                        <div class="col-2 pr0">
                                            <select name="" id="" className="form-control">
                                                <option value="">IDR</option>
                                            </select>
                                        </div>
                                        <div class="col-10 pl0">
                                            <input
                                                type="text"
                                                class="form-control"
                                                value="INDONESIAN RUPIAH"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-5">
                                    <div class="form-row">
                                        <div class="col-4">
                                            <label htmlFor="">Kurs Jual</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                value={this.state.kurs_jual}
                                                name="kurs_jual"
                                                onChange={(e) => {
                                                    this.handleFormatNumber(e);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div class="col-4">
                                            <label htmlFor="">Kurs Beli</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                value={this.state.kurs_beli}
                                                name="kurs_beli"
                                                onChange={(e) => {
                                                    this.handleFormatNumber(e);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div class="col-4">
                                            <label htmlFor="">Kurs Buku</label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                value={this.state.kurs_buku}
                                                name="kurs_buku"
                                                onChange={(e) => {
                                                    this.handleInputChange(e);
                                                }}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-3">
                                    <label htmlFor="">Nominal</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={this.state.nominal}
                                        name="nominal"
                                        onChange={(e) => {
                                            this.handleFormatNumber(e);
                                            const value = e.target.value;
                                            const kurs_buku = this.state.kurs_buku;
                                            const valueint = thousandSeparator.unformat(value);

                                            // this.setState({
                                            //     nilai_ekuivalen:
                                            //     valueint * kurs_buku
                                            // });

                                            this.setState({
                                                nilai_ekuivalen: thousandSeparator.multiply(
                                                    value,
                                                    kurs_buku
                                                ),
                                            });
                                        }}
                                        // onBlur={(e) => {}}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">Nominal Ekuivalen</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={thousandSeparator.format(this.state.nilai_ekuivalen)}
                                        name="nilai_ekuivalen"
                                        readOnly
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">Biaya</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={this.state.biaya}
                                        name="biaya"
                                        onChange={(e) => {
                                            this.handleFormatNumber(e);

                                            const value = e.target.value;
                                            const kurs_buku = this.state.kurs_buku;
                                            const valueint = thousandSeparator.unformat(value);

                                            // this.setState({
                                            //     biaya_bulanan: valueint * kurs_buku,
                                            // });

                                            this.setState({
                                                biaya_bulanan: thousandSeparator.multiply(
                                                    value,
                                                    kurs_buku
                                                ),
                                            });
                                        }}
                                        // onBlur={(e) => {}}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">Biaya Ekuivalen</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={thousandSeparator.format(this.state.biaya_bulanan)}
                                        name="biaya_bulanan"
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="">Sumber Biaya</label>
                                    <select
                                        className="form-control"
                                        name="sumber_biaya"
                                        value={this.state.sumber_biaya}
                                        onChange={(e) => {
                                            this.handleInputChange(e);
                                        }}
                                    >
                                        <option value="tunai">Tunai</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">No.Referensi</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={this.state.no_referensi}
                                        name="no_referensi"
                                        onChange={(e) => {
                                            this.handleInputChange(e);
                                        }}
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label htmlFor="">Keterangan</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        value={this.state.keterangan}
                                        name="keterangan"
                                        onChange={(e) => {
                                            this.handleInputChange(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        this.getDataNegatif();
                                    }}
                                    disabled={!this.state.nama_rekening}
                                >
                                    Informasi Negatif Nasabah
                                </button>
                            </div>
                            <div className="col-6 text-right">
                                <button
                                    className="btn btn-outline-secondary"
                                    onClick={() => {
                                        this.setState({ showModalBatal: true });
                                    }}
                                >
                                    Batal
                                </button>
                                <button
                                    className="btn btn-primary ml-3 px-5"
                                    onClick={() => {
                                        this.setState({ showModalConfirm: true });
                                    }}
                                >
                                    Proses
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.showModalConfirm}
                    onHide={() => this.setState({ showModalConfirm: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Lakukan proses Tarik Tunai?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalConfirm: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.postTarikTunai();
                            }}
                            disabled={this.state.isLoading}
                        >
                            {this.state.isLoading ? 'Mohon Tunggu...' : 'Ya'}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSuccess}
                    onHide={() => this.setState({ showModalSuccess: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Selesai</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Proses Tarik Tunai berhasil dilakukan</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalSuccess: false });
                                this.props.history.push(
                                    '/informasi-transaksi/informasi-transaksi-hari-ini'
                                );
                            }}
                        >
                            Selesai
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalError}
                    onHide={() => this.setState({ showModalError: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageError}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalError: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalWarning}
                    onHide={() => this.setState({ showModalWarning: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageWarning}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalWarning: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalBatal}
                    onHide={() => this.setState({ showModalBatal: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Batalkan proses input transaksi ?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalBatal: false })}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.resetFormInput();
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalBatalInfo}
                    onHide={() => this.setState({ showModalBatalInfo: false })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Input Transaksi Dibatalkan {this.state.showModalBatalInfo}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.messageError}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.setState({ showModalBatalInfo: false });
                            }}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModalSvs}
                    onHide={() => this.setState({ showModalSvs: false })}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Data Grafis / Tanda Tangan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-body p0">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Image Tag</th>
                                            <th>Taq Sequence</th>
                                            <th>Remark</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.svsData && this.state.svsData.length > 0
                                            ? this.state.svsData.map((data, index) => {
                                                  return (
                                                      <tr key={`listsaccountimages${index}`}>
                                                          <td>{data?.image_tag}</td>
                                                          <td>{index + 1}</td>
                                                          <td>{data?.remark}</td>
                                                          <td>{data?.type}</td>
                                                      </tr>
                                                  );
                                              })
                                            : null}
                                    </tbody>
                                </table>
                                <div className="p-3">
                                    <div className="row">
                                        {}
                                        {this.state.svsData && this.state.svsData.length > 0
                                            ? this.state.svsData.map((data, index) => {
                                                  return (
                                                      <div
                                                          className="col-4"
                                                          key={`listsaccountimagessign${index}`}
                                                      >
                                                          <p>
                                                              Image {index + 1} - {data?.image_tag}
                                                          </p>
                                                          <div className="svs-box">
                                                              <div className="mt-5 text-center">
                                                                  <img
                                                                      src={data?.signed_url}
                                                                      height={110}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <p className="border p-2 mt-2 fs-12">
                                                              {data?.remark}
                                                          </p>
                                                      </div>
                                                  );
                                              })
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({ showModalSvs: false })}
                        >
                            Tutup
                        </button>
                    </Modal.Footer>
                </Modal>
                <ModalInfoNegatifNasabah
                    show={this.state.showModalInfoNegatif}
                    form={this.state.formNegatifNasabah}
                    onHide={() => {
                        this.setState({ showModalInfoNegatif: false });
                    }}
                    type="peragaan"
                    mode="view"
                />
            </>
        );
    }
}

export default withKeycloak(TarikTunai);
