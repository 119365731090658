import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../../../hooks/keycloak.module';

export const wicContext = createContext({
    wicAction: {},
    wicState: {}
});


export function WicProvider({ children }) {
    const [dataList, setDataList] = useState([])
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [isLoading, setIsLoading] = useState(false)
    const { doPost } = useFetch()
    const { keycloak } = useKeycloak()
    const [filter, setFilter] = useState({ keyword: "" });
    const [dataAlert, setDataAlert] = useState({
        show: false,
        body: "",
        header: null,
        title: "",
        callBack: () => null,
      });
    const [dataConfirm, setDataConfirm] = useState({
    show: false,
    body: "",
    title: "",
    header: null,
    footerButton: [],
    closeButton: false,
    witTextArea: false,
    });

    // useEffect GetData
    useEffect(
        function () {
        getDataList();
        },
        [pagination]
    );

    // closeConfirm
    function closeConfirm() {
        setDataConfirm({
        show: false,
        body: "",
        header: null,
        title: "",
        footerButton: [],
        closeButton: true,
        witTextArea: false,
        });
    }
     // getData rekening list
    async function getDataList() {
        setIsLoading(true);
        var url = `/wic/search`;
        // // console.log(filter.keyword)
        try {
        const {
            statusCode,
            status,
            remark,
            statusText,
            data = {},
        } = await doPost({
            url: url,
            queryParam: { name:filter.keyword, ...pagination },
        });
        if (statusCode === 200) {
            if (status === "00") {
                setDataList(data?.listwic);
            } else
            setDataAlert({
                show: true,
                body: remark,
                titile: `Status fetch data is ${statusCode}`,
                callBack: () => null,
            });
        } else
            setDataAlert({
            show: true,
            body: remark,
            title: `${JSON.stringify(statusText)}`,
            header: "Error",
            callBack: () => null,
            });
        } catch (error) {
        setDataAlert({
            show: true,
            body: error.toString(),
            title: "Terjadi Kesalahan",
            header: "Error",
            callBack: () => null,
        });
        }

        setIsLoading(false);
    }

    function resetFilter() {
        setFilter({
          keyword: "",
        });
      }
      // next pagination
      function nextPage({ page, limit }) {
        // // console.log(page)
        setPagination({
          page,
          show: limit,
        });
      }
    
      // prev pagination
      function prevPage({ page, limit }) {
        setPagination({
          page,
          show: limit,
        });
      }
      // change show
      function changeShow({ page, limit }) {
        setPagination({
          page,
          show: limit,
        });
      }
     // close Alert
    function closeAlert() {
        setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null });
    }
//Post Auditrail
async function auditTrail() {
  const userInfo = await keycloak.loadUserInfo();
  // console.log(userInfo)
  var url = `/useraudit/user-audit-trail`;
  try {
      const { statusCode, status, remark, statusText, data = {} } = await doPost({
          url: url,
          service: "acc",
          data: { 
                  user_id : userInfo.preferred_username,
                  app_id : "BDS",
                  terminal_ip: window.location.hostname,
                  operation_code: "Get Data List",
                  event_description: "WIC/Data WIC",
                  info1: "-",
                  info2: "-"
          },
          headers: {
              "x-user-id": userInfo.preferred_username,
              method: "POST"
          }
      });

      if (statusCode === 200) {
          if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
          else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
      } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
  } catch (error) {
      setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
  }
  
}
    return <wicContext.Provider value={{
        wicAction: {
            nextPage,
            prevPage,
            changeShow,
            setFilter,
            getDataList,
            resetFilter,
            closeAlert,
            closeConfirm,
            auditTrail
        },
        wicState: {
            dataList,
            isLoading,
            filter,
            dataAlert,
            dataConfirm
        }
    }} >{children}</wicContext.Provider>
}


export default function useDataAft() { return useContext(wicContext) }