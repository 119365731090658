import moment from 'moment';
// import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormPerorangan from './useFormPerorangan';
import Select from 'react-select';
import { useParams } from 'react-router';
import useKeycloak from '../../../../../hooks/keycloak.module';

const IdentitasNasabah = () => {

    const paramUrlM = useParams();
    const { fpAction, fpState } = useFormPerorangan();
    const { formData = {}, formEditable = false } = fpState;
    const { keycloak } = useKeycloak();
    useEffect(function(){
        fpAction.getDataMarketing()
    },[])

    const { mode } = useParams()

    return (
        <>
            <div className="pb-3">
                <div className="box">
                    <div className="row">
                        <div className="col-3 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nomor CIF</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="Otomatis terisi dari sistem"
                                    value={formData?.id}
                                />
                            </div>
                        </div>
                        <div className="col-3 align-self-center bns-form">
                            <div className="form-group">
                                <label>Kode Referal</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="Otomatis terisi dari sistem"
                                />
                            </div>
                        </div>
                        <div className="col-3 align-self-center bns-form">
                            <div>
                                <span className="ml-2 text-black">Tgl. Buka</span>
                                <span className="text-black ml-4">: {' '}</span>
                                {/* <span className="text-grey2">{moment().format("DD MMMM YYYY")}</span> */}
                                <span className="text-grey2">{moment(fpState.paramUrl?.mode != "registration" ? fpState.formData?.tanggal_buka:fpState.podDate).format("DD MMMM YYYY")}</span>            
                            </div>
                        </div>
                        <div className="col-3 align-self-center bns-form">
                            <div>
                                <span className="ml-2 text-black">Kode Cabang</span>
                                <span className="text-black ml-4">: {' '}</span>
                                <span className="text-grey2">{keycloak?.tokenParsed?.branch_code}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bns-form"> */}
            <FormRegistration
                itemsForm={[
                    // Identitas
                    {
                        name: "Identitas",
                        rows: [
                            {
                                rows: 1,
                                label: 'Jenis Identitas',
                                type: 'select', // select, date, number, text, checkbox, custom
                                placeholder: 'Pilih Jenis Identitas',
                                required: true,
                                // options: fpState.dataDropDown?.eJenisIdentitas,
                                options: (fpState.dataDropDown?.eJenisIdentitas || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._personalIdData?.personalIdType,
                                disabled: !fpState.formEditable,
                                onChange: (value) => {
                                    fpAction.changeForm(value, "personalIdType", "_personalIdData")
                                    // fpAction.changeForm("", "personalIdNumber", "_personalIdData")
                                    if(value === "1" ){
                                        fpAction.changeForm("1", "personalCitizenship", "_customerData")
                                        fpAction.changeForm("ID", "ktpCountry" ,"_ktpAddress")
                                    }
                                }
                            },
                            {
                                rows: 1,
                                label: 'Nomor Identitas',
                                type:fpState.formData?._personalIdData?.personalIdType === '3' ? 'text' : 'number',
                                placeholder: 'Masukan Nomor Identitas',
                                maxLength:16,
                                required: true,
                                value: fpState.formData?._personalIdData?.personalIdNumber,
                                onChange: (value) => fpAction.changeForm(value, "personalIdNumber", "_personalIdData"),
                                disabled: !fpState.formEditable,
                                onFocusOut: (value) => fpAction.cekKtp(value)
                            },
                            {
                                rows: 2,
                                label: 'Nama (Sesuai Identitas)',
                                type: 'text',
                                placeholder: 'Masukan Nama (Sesuai Identitas)',
                                required: true,
                                value: fpState.formData?._personalIdData?.personalIdHolderName,
                                onChange: (value) => fpAction.changeForm(value, "personalIdHolderName", "_personalIdData"),
                                //onChange: (value) => fpAction.changeForm(value, "firstName"),
                                // value: `${formData?.firstName || ""} ${formData?.middleName || ""} ${formData?.lastName || ""}`,
                                disabled: !fpState.formEditable
                            },
                            // {
                            //     rows: 2,
                            //     type: 'custom',
                            //     component: (
                            //         <div className="row">
                            //             <div className="col">
                            //                 {
                            //                     createForm({
                            //                         label: 'Tgl Terbit Identitas',
                            //                         type: 'date',
                            //                         value: fpState.formData?._personalIdData?.personalIdDateOfIssue || '',
                            //                         onChange: (value) => fpAction.changeForm(value, "personalIdDateOfIssue", "_personalIdData"),
                            //                         disabled: !fpState.formEditable,
                            //                         required: true
                            //                         // placeholder: 'Masukan Nama (Sesuai Identitas)',
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col">
                            //                 {
                            //                     createForm({
                            //                         label: 'Tgl Berakhir Identitas',
                            //                         type: 'date',
                            //                         value: fpState.formData?._personalIdData?.personalIdExpireDate || '',
                            //                         onChange: (value) => fpAction.changeForm(value, "personalIdExpireDate", "_personalIdData"),
                            //                         disabled: !fpState.formEditable,
                            //                         required: true
                            //                         // placeholder: 'Masukan Nama (Sesuai Identitas)',
                            //                     })
                            //                 }
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tempat/Tanggal Lahir',
                                                    type: 'text',
                                                    placeholder: "Masukkan tempat lahir",
                                                    value: fpState.formData?._customerData?.birthPlace,
                                                    onChange: (value) => fpAction.changeForm(value, "birthPlace", "_customerData"),
                                                    disabled: !fpState.formEditable,
                                                    required: true
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: <>&nbsp;</>,
                                                    type: 'date',
                                                    value: fpState.formData?.birthDate || '',
                                                    onChange: (value) => {
                                                        var now = moment(
                                                            moment(Date.now()).format("YYYY-MM-DD")
                                                        );
                                                        if (now >= moment(value)) fpAction.changeForm(value, "birthDate")
                                                    },
                                                    //fpAction.changeForm(value, "birthDate"),
                                                    disabled: !fpState.formEditable,
                                                    required: true
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            ...( mode === "registration" || mode === "edit"? [{
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-md-12 ml-2 pr-4">
                                            <button disabled={fpState.paramUrl?.mode === "otorisasi"} onClick={fpAction.cekNasabah} className="btn btn-sm btn-success float-right">
                                                Cek Data Nasabah
                                            </button>
                                        </div>
                                    </div>
                                )
                            }] : [])
                        ]
                    },
                    // End Identitas
                    // Data Diri
                    {
                        name: "Data Diri",
                        rows: [
                            {
                                rows: 1,
                                label: 'Nama Lengkap',
                                type: 'text',
                                placeholder: 'Masukan Nama Lengkap',
                                value: fpState.formData?.firstName,
                                onChange: (value) => fpAction.changeForm(value, "firstName"),
                                required: true,
                                // value: `${formData?.firstName || ""} ${formData?.middleName || ""} ${formData?.lastName || ""}`,
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Nama Singkat',
                                type: 'text',
                                placeholder: 'Masukan Nama Singkat',
                                value: fpState.formData?.nama_singkat,
                                onChange: (value) => fpAction.changeForm(value, "nama_singkat"),
                                required: false,
                                // value: `${formData?.firstName || ""} ${formData?.middleName || ""} ${formData?.lastName || ""}`,
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Gelar Depan',
                                                    type: 'text',
                                                    placeholder: 'Gelar Depan',
                                                    value: fpState.formData?._customerData?.customerPreTitle,
                                                    onChange: (value) => fpAction.changeForm(value, "customerPreTitle", "_customerData"),
                                                    disabled: !fpState.formEditable
                                                    // required: true
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Gelar Belakang',
                                                    type: 'text',
                                                    placeholder: 'Gelar Belakang',
                                                    value: fpState.formData?._customerData?.customerPostTitle,
                                                    onChange: (value) => fpAction.changeForm(value, "customerPostTitle", "_customerData"),
                                                    disabled: !fpState.formEditable
                                                    // required: true
                                                })
                                            }
                                        </div>
                                    </div>)
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nama Alias 1',
                                                    type: 'text',
                                                    placeholder: 'Nama Alias 1',
                                                    value: fpState.formData?._customerData?.customerAliasName,
                                                    onChange: (value) => fpAction.changeForm(value, "customerAliasName", "_customerData"),
                                                    disabled: !fpState.formEditable
                                                    // required: true
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nama Alias 2',
                                                    type: 'text',
                                                    placeholder: 'Nama Alias 2',
                                                    value: fpState.formData?._customerData?.customerAliasName2,
                                                    onChange: (value) => fpAction.changeForm(value, "customerAliasName2", "_customerData"),
                                                    disabled: !fpState.formEditable
                                                    //no field
                                                    // required: true
                                                })
                                            }
                                        </div>
                                    </div>)
                            },

                            // {
                            //     rows: 1,
                            //     type: 'custom',
                            //     component: (
                            //         <div className="row">
                            //             <div className="col">
                            //                 {
                            //                     createForm({
                            //                         label: 'Nama Alias 3',
                            //                         type: 'text',
                            //                         placeholder: 'Nama Alias 3',
                            //                         disabled: !fpState.formEditable
                            //                         // required: true
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col">
                            //                 {
                            //                     createForm({
                            //                         label: 'Nama Alias 4',
                            //                         type: 'text',
                            //                         placeholder: 'Nama Alias 4',
                            //                         disabled: !fpState.formEditable
                            //                         // required: true
                            //                     })
                            //                 }
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col pl-3">
                                            <div className="form-group">
                                                <label 
                                                    style={{ fontSize: "12px" }}
                                                    // className="text-danger"
                                                    className={formData?.gender === "" || formData?.gender === undefined|| formData?.gender === null ? "text-danger" : ""}
                                                >
                                                    Jenis Kelamin
                                                    <span
                                                        style={{
                                                            padding: '2px 5px',
                                                            background: '#E8E8E8',
                                                            fontSize: '8pt',
                                                            fontWeight: 'normal',
                                                            borderRadius: 3,
                                                        }}
                                                    >
                                                        Wajib
                                                    </span>
                                                </label>
                                                <br />
                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input
                                                            type="radio"
                                                            checked={formData?.gender === "MALE"}
                                                            className="form-check-input"
                                                            name="gender"
                                                            onChange={(e) => {
                                                                fpAction.changeForm(e.target.value, "gender");
                                                            }}
                                                            value="MALE"
                                                            disabled={!fpState.formEditable}
                                                        />Laki-laki
                                                    </label>
                                                </div>
                                                <div className="form-check-inline ml-3">
                                                    <label className="form-check-label">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="gender"
                                                            checked={formData?.gender === "FEMALE"}
                                                            onChange={(e) => {
                                                                fpAction.changeForm(e.target.value, "gender");
                                                            }}
                                                            value="FEMALE"
                                                            disabled={!fpState.formEditable}
                                                        />Perempuan
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },

                            {
                                rows: 2,
                                label: 'Nama Ibu Kandung',
                                type: 'text',
                                placeholder: 'Masukan Nama Ibu Kandung',
                                value: fpState.formData?._customerData?.personalMothersName,
                                onChange: (value) => fpAction.changeForm(value, "personalMothersName", "_customerData"),
                                required: true,
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Nama Ayah ',
                                type: 'text',
                                placeholder: 'Masukan Nama ayah',
                                value: fpState.formData?._customerData?.personalFathersName,
                                onChange: (value) => fpAction.changeForm(value, "personalFathersName", "_customerData"),
                                required: true,
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Status Pernikahan',
                                type: 'select',
                                placeholder: 'Pilih Status Pernikahan',
                                // options: fpState.dataDropDown?.eStatusPerkawinan,
                                options: (fpState.dataDropDown?.eStatusPerkawinan || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._customerData?.personalMaritalStatus,
                                onChange: (value) => fpAction.changeForm(value, "personalMaritalStatus", "_customerData"),
                                disabled: !fpState.formEditable,
                                required: true
                            },
                            {
                                rows: 2,
                                label: 'Kewarganegaraan',
                                type: 'select',
                                placeholder: 'Pilih Kewarganegaraan',
                                // options: fpState.dataDropDown?.eKewarganegaraan,
                                options: (fpState.dataDropDown?.eKewarganegaraan || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._customerData?.personalCitizenship,
                                onChange: (value) => fpAction.changeForm(value, "personalCitizenship", "_customerData"),
                                disabled: !fpState.formEditable,
                                required: true
                            },
                            {
                                rows: 2,
                                label: 'Resident',
                                type: 'select',
                                placeholder: 'Pilih Resident',
                                options: (fpState.dataDropDown?.R_KODE_NEGARA || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData._ktpAddress?.ktpCountry || '',
                                onChange: (value) => fpAction.changeForm(value, "ktpCountry" ,"_ktpAddress"),
                                disabled: !fpState.formEditable,
                                required: true
                            },
                            {
                                rows: 2,
                                label: 'Agama',
                                type: 'select',
                                placeholder: 'Pilih Agama',
                                // options: fpState.dataDropDown?.eAgama,
                                options: (fpState.dataDropDown?.eAgama || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._customerData?.personalReligion,
                                onChange: (value) => fpAction.changeForm(value, "personalReligion","_customerData"),
                                disabled: !fpState.formEditable
                            },
                        ]
                    },
                    // End Data Diri
                    // Informasi Lainnya
                    {
                        name: "Informasi Lainnya",
                        rows: [
                            {
                                rows: 1,
                                label: 'Alamat Email',
                                type: 'text',
                                placeholder: 'Masukan Alamat Email',
                                value: fpState.formData?.emailAddress,
                                onChange: (value) => fpAction.changeMailForm(value, "emailAddress"),
                                required: false,
                                disabled: !fpState.formEditable
                            },

                            {
                                rows: 1,
                                label: 'Pendidikan Terakhir',
                                type: 'select',
                                placeholder: 'Pilih Pendidikan Terakhir',
                                // options: fpState.dataDropDown?.ePendidikan,
                                options: (fpState.dataDropDown?.ePendidikan || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._customerData?.customerEducation,
                                onChange: (value) => fpAction.changeForm(value, "customerEducation", "_customerData"),
                                disabled: !fpState.formEditable
                            },

                            {
                                rows: 1,
                                label: 'Status Keterkaitan',
                                type: 'select',
                                required: true,
                                placeholder: 'Pilih Status Keterkaitan dengan Bank',
                                options: (fpState.dataDropDown?.eStatusKeterkaitan || [])
                                    .map(({reference_code, reference_desc}) => ({
                                        reference_code,
                                        reference_desc : `${reference_code} - ${reference_desc}`
                                    })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._customerData?.customerBankRelation,
                                onChange: (value) => fpAction.changeForm(value, "customerBankRelation", "_customerData"),
                                disabled: !fpState.formEditable
                            },

                            {
                                rows: 1,
                                label: 'Jenis Penduduk',
                                type: 'select',
                                placeholder: 'Pilih Jenis Penduduk',
                                // options: fpState.dataDropDown?.eJenisPenduduk,
                                options: (fpState.dataDropDown?.eJenisPenduduk || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                required: true,
                                value: fpState.formData?._personalIdData?.populationType,
                                onChange: (value) => fpAction.changeForm(value, "populationType", "_personalIdData"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Keterangan',
                                type: 'textarea',
                                placeholder: 'Bila ada',
                                value: fpState.formData?.notes,
                                onChange: (value) => fpAction.changeForm(value, "notes"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Nasabah Prioritas',
                                type: 'checkbox',
                                className: "pt-4",
                                checked:fpState.formData?.nasabah_prioritas === "T",
                                value: fpState.formData?.nasabah_prioritas === "T",
                                //no field belum keluar otor
                                onChange: (value) => {
                                    fpAction.changeForm(value ? "T" : "F", "nasabah_prioritas")
                                }, 
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        label: 'Memiliki NPWP',
                                                        type: 'checkbox',
                                                        className: "pt-2",
                                                        checked:fpState?.cekKondisi?.memiliki_npwp,
                                                        value: fpState?.cekKondisi?.memiliki_npwp,
                                                        onChange: (value) => {
                                                            fpAction.changeKondisi(value, "memiliki_npwp")
                                                            // if(fpState.formData?._personalIdData?.personalNpwpNumber !== null){
                                                            //     fpAction.changeKondisi(true, "memiliki_npwp")
                                                            // }
                                                        },
                                                        disabled: !fpState.formEditable,
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="row" hidden={!fpState?.cekKondisi?.memiliki_npwp && fpState.formEditable}>
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        type: 'number',
                                                        placeholder: 'Masukan Nomor NPWP',
                                                        value: fpState.formData?._personalIdData?.personalNpwpNumber,
                                                        maxLength:15,
                                                        onChange: (value) => fpAction.changeForm(value.toString().replace(" ",""), "personalNpwpNumber", "_personalIdData"),
                                                        required: fpState?.cekKondisi?.memiliki_npwp,
                                                        disabled: !fpState.formEditable
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },

                            {
                                rows: 2,
                                label: 'Referal Nasabah',
                                type: 'text',
                                placeholder: 'Masukan Referal Nasabah',
                                value: fpState.formData?._personalIdData?.personalReferralCode,
                                onChange: (value) => fpAction.changeForm(value, "personalReferralCode" ,"_personalIdData"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                type: "select",
                                label: "Marketing Inisiasi",
                                required: true,
                                placeholder: "Pilih marketing inisiasi",
                                disabled: !fpState.formEditable || mode === "edit",
                                key:"kode_ao",
                                display:"nama_ao",
                                // options:fpState.dataMarketing,
                                options: (fpState.dataMarketing || []).map(({kode_ao, nama_ao}) => ({
                                    kode_ao,
                                    nama_ao : `${kode_ao} - ${nama_ao}`
                                })),
                                value:fpState.formData?._personalIdData?.personalMarketingOfficer,
                                onChange:(e) =>fpAction.changeForm(e, "personalMarketingOfficer", "_personalIdData")
                            },
                            // {
                            //     rows: 2,
                            //     type: 'custom',
                            //     component: (
                            //         <>
                            //             <div className="row">
                            //             <div className="col-12 pl-3 pr-3">
                            //                 <div className="form-group form-group-sm">
                            //                     <label>Marketing </label>
                            //                     <Select
                            //                         placeholder="Pilih marketing inisiasi"
                            //                         onChange={(e) =>{
                            //                             fpAction.changeForm(e.value,"personalMarketingOfficer","_personalIdData")
                            //                         }}
                            //                         value={ 
                            //                             fpState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao })).find(c => c.value === fpState.formData?._personalIdData?.personalMarketingOfficer)
                                                       
                            //                         }
                            //                         isDisabled={!fpState.formEditable || paramUrlM.mode === "edit"}
                            //                         // className="form-control form-control-sm"
                            //                         // classNamePrefix="form-control form-control-sm"
                            //                         options={fpState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao }))}
                            //                     />
                            //                 </div>
                            //             </div>
                            //         </div>
                            //         </>
                            //     )
                            // },
                            // {
                            //     rows: 2,
                            //     label: 'Jumlah Tanggungan',
                            //     type: 'number',
                            //     placeholder: 'Masukan Jumlah Tanggungan',
                            //     value: fpState.formData?._dataTambahan?.jumlahTanggungan,
                            //     onChange: (value) => fpAction.changeForm(value, "jumlahTanggungan" ,"_dataTambahan"),
                            //     disabled: !fpState.formEditable
                            // },
                        ]
                    }
                ]}
            />
            {/* <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Jenis Identitas</label>
                            <select className="form-control">
                                <option>
                                    Pilih Jenis Identitas
                                </option>
                            </select>
                        </div>

                    </div>
                </div> */}
            {/* </div> */}
        </>
    );
}

export default IdentitasNasabah;