import React from "react";
import { createForm } from "../../../../../../module/createFormList";
import { Modal } from "react-bootstrap";
import ModalConfirmCustom from "../../../../../controls/modalAlert/modalConfirmCustomChild";
import useDataNotif from "../useDataNotif";
import { FormRegistration } from "../../../../../controls";

const ModalDetilNotif = () => {
    const { nfAction, nfState } = useDataNotif()
    return(
    <>
        {/* <Modal size="lg" show={nfState.modalDetil.show}  backdrop="static" onHide= { nfAction.closeModalDetil }>
            <Modal.Header closeButton>
            {
                !nfState.formEditable &&
                <Modal.Title>
                    Detil
                </Modal.Title>
            }
                {
                            nfState.formEditable &&
                            <Modal.Title>
                                Ubah Data Registrasi 
                            </Modal.Title>
                }
            </Modal.Header>
            <Modal.Body>
                <FormRegistration 
                    totalRows={1}
                    itemsForm={[
                        {
                            name:"Regitrasi SMS Notifikasi",
                            rows: [
                                {
                                    rows    : 1,
                                    label   : 'ID',
                                    type    : 'text',
                                    required: true,
                                    value   : nfState.formData?.id,
                                    onChange:(value) => nfAction.changeForm(value,'id'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                                {
                                    rows    : 1,
                                    label   : 'No Rekening',
                                    type    : 'number',
                                    required: true,
                                    value   : nfState.formData?.no_rek,
                                    onChange:(value) => nfAction.changeForm(value,'no_rek'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                                {
                                    rows    : 1,
                                    label   : 'Nomor Nasabah',
                                    type    : 'text',
                                    required: true,
                                    value   : nfState.formData?.cif,
                                    onChange:(value) => nfAction.changeForm(value,'cif'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                                {
                                    rows    : 1,
                                    label   : 'Nama Subcriber',
                                    type    : 'text',
                                    required: true,
                                    value   : nfState.formData?.nama_sub,
                                    onChange:(value) => nfAction.changeForm(value,'nama_sub'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                                {
                                    rows    : 1,
                                    label   : 'No HandPhone',
                                    maxLength : 16,
                                    type    : 'number',
                                    required: true,
                                    value   : nfState.formData?.no_hp,
                                    onChange:(value) => nfAction.changeForm(value,'no_hp'),
                                    disabled: !nfState.formEditable,
                                    readOnly:true
                                },
                                {
                                    rows    : 1,
                                    label   : 'Filter Transaksi',
                                    placeholder : " Pilih Transaksi ",
                                    type    : 'select',
                                    required: true,
                                    value   : nfState.formData?.flag,
                                    onChange: (value) => {
                                        nfAction.changeForm(value,'flag')
                                        if ( value == "DB-Hanya Transaksi Debit" ) {
                                            nfAction.resetFormDBCR()
                                            nfAction.CekDb()
                                        }else if ( value == "CR-Hanya Transaksi Kredit" ) {
                                            nfAction.resetFormDBCR()
                                            nfAction.CekCr()
                                        }else if (value == "BOTH-Hanya Transaksi Debit dan Kredit") {
                                            nfAction.resetFormDBCR()
                                            nfAction.CekBOTH()
                                        }else if (value == ""){
                                            nfAction.resetFormDBCR()
                                            nfAction.resetCEK()
                                        }
                                    },   
                                    options : [
                                        {
                                            key     : "DB-Hanya Transaksi Debit",
                                            display : "Hanya Transaksi Debit"
                                        },{
                                            key     : "CR-Hanya Transaksi Kredit",
                                            display : "Hanya Transaksi Kredit"
                                        },{
                                            key     : "BOTH-Hanya Transaksi Debit dan Kredit",
                                            display : "Hanya Transaksi Debit dan Kredit"
                                        },
                                    ],
                                    disabled: !nfState.formEditable
                                },
                                {
                                    rows    : 1,
                                    label   : 'Min Transaksi Db.',
                                    type    : 'currency',
                                    required: nfState.filterTRDB,
                                    value   : nfState.formData?.min_tran_db,
                                    onChange:(value) => nfAction.changeForm( parseFloat(value),'min_tran_db'),
                                    disabled: !nfState.filterTRDB || !nfState.formEditable
                                },
                                {
                                    rows    : 1,
                                    label   : 'Min Transakasi Cr.',
                                    type    : 'currency',
                                    required: nfState.filterTRCR,
                                    value   : nfState.formData?.min_tran_cr,
                                    onChange:(value) => nfAction.changeForm(parseFloat(value),'min_tran_cr'),
                                    disabled: !nfState.filterTRCR || !nfState.formEditable
                                },
                                {
                                    rows    : 1,
                                    label   : 'Active',
                                    type: 'checkbox',
                                    className: "pt-2",
                                    checked:nfState.formData?.active === "T",
                                    //no field belum keluar otor
                                    onChange: (value) => {
                                        nfAction.changeForm(value ? "T" : "F", "active")
                                    }, 
                                    disabled: !nfState.formEditable
                                }
                            ]
                        }
                    ]}
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col">
                        <button onClick={nfAction.closeModalDetil } className="btn btn-outline-secondary float-right ml-2">
                            Close
                        </button>
                        {
                            nfState.formEditable &&
                            <button onClick={nfAction.confirmUbah } className="btn btn-outline-success float-right ml-2">
                                Save
                            </button>
                        }
                        {
                            nfState.otorMode ? (
                                    <>
                                        <button  className="btn btn-primary float-right ml-2" onClick={()=>nfAction.responseOtor("APPROVED")}>
                                            Approve
                                        </button>
                                        <button className="btn btn-danger float-right ml-2" onClick={()=>nfAction.responseOtor("REJECTED")}>
                                            Reject
                                        </button>
                                    </>
                            ) : (
                                <></>
                            )
                            
                        }
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
        <ModalConfirmCustom id="otorisasi">
            <textarea className="form-control"
                onChange={(e) => nfAction.setOtorDesc(e.target.value)}
                value={nfState.otorDesc}
            />
        </ModalConfirmCustom> */}
    </>
    )
}
// function FormCreated({ children, ...props }) {

//     let option = props;
//     if (props.type === "custom") option = { ...props, component: children }
//     return <>{
//         createForm({ ...option })
//     }</>
// }


export default ModalDetilNotif;