import React from 'react';
import useDeposito from '../useDeposito';
import { Tab, Nav, Modal, Button } from 'react-bootstrap';
import DetilBilyetDeposito from '../components/detilBilyetDeposito';
import Skeleton from 'react-loading-skeleton';


const BilyetDeposito = () => {
    const { rekState, rekAction } = useDeposito();
    const { rekSelected = {}, formEditable = false } = rekState;

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalCetakBilyet} onHide={rekAction.closeCetakBilyet} >
                <Modal.Header closeButton>
                    <Modal.Title>Cetak Bilyet Deposito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DetilBilyetDeposito />
                    {
                        rekState.rekSelected?.is_sudah_disetor !== "T" && (
                            <div className='row'>
                                <div className='col'>
                                    <div className='alert alert-info p-2 text-center'>
                                        Mohon maaf deposito belum dilakukan setoran awal, Silahkan lakukan setoran awal deposito
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    {/* uncomment jika naek prod */}
                    {/* <div className="row">
                        <div className="col">
                            <button
                                onClick={() => rekAction.closeCetakBilyet()}
                                className="btn btn-outline-danger float-right ml-2">
                                Batal
                            </button>
                            <button
                                onClick={() => rekAction.openCetakBilyetPrint(rekSelected?.nomor_rekening)}
                                disabled={rekState.rekSelected?.is_sudah_disetor !== "T"}
                                className="btn btn-outline-success float-right">
                                Cetak
                            </button>
                        </div>
                    </div> */}
                    {/* uncomment sampau sini */}
                    {/* comment jika naek prod */}
                    {
                        !rekState.isOtor ? (
                            <div className="row">
                                <div className="col">
                                    <button
                                        onClick={() => rekAction.closeCetakBilyet()}
                                        className="btn btn-outline-danger float-right ml-2">
                                        Batal
                                    </button>
                                    {rekState.rekSelected?.is_boleh_cetak_bilyet === "T" ? <button
                                        onClick={() => rekAction.openCetakBilyetPrint(rekSelected?.nomor_rekening)}
                                        // disabled={rekState.rekSelected?.is_sudah_disetor !== "T"}
                                        className="btn btn-outline-success float-right">
                                        Cetak
                                    </button> : <button
                                        onClick={() => rekAction.confirmRCB()}
                                        disabled={rekState.rekSelected?.is_sudah_disetor !== "T"}
                                        className="btn btn-outline-success float-right">
                                        Request Cetak
                                    </button>}

                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="row">
                                    <div className="col">
                                        <button
                                            onClick={() => rekAction.responseOtor("APPROVED")}
                                            className="btn btn-outline-success float-right">
                                            Approve
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button
                                            onClick={() => rekAction.responseOtor("REJECTED")}
                                            className="btn btn-outline-danger float-right">
                                            Reject
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button
                                            onClick={() => rekAction.closeCetakBilyet()}
                                            className="btn btn-link float-right">
                                            Tutup
                                        </button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {/* comment smpai sini */}

                </Modal.Footer>
            </Modal >
            {/* end modal utama */}
        </>

    );
}

export default BilyetDeposito;