import React, { useRef } from 'react';
import useDeposito from '../useDeposito';
import { Tab, Nav, Modal, Button } from 'react-bootstrap';
import DataPrint from '../components/dataPrint';
import Skeleton from 'react-loading-skeleton';
import ReactToPrint from 'react-to-print';


const CetakBilyetPrint = () => {
    const { rekState, rekAction } = useDeposito();
    const refCetak = useRef(null);
    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalCetakBilyetPrint} onHide={rekAction.closeCetakBilyet} >
                <Modal.Header closeButton>
                    <Modal.Title>Print Bilyet Deposito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box mb-3">
                        <div className="row" ref={refCetak}>
                            <DataPrint />
                            {/* <div className="col">
                                <h4>Cabang : <>{rekState.rekSelected?.nama_cabang}</></h4>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Tanggal Buka </div>
                                            <div className="col-8"> {rekState.rekSelected?.nama_cabang}</div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Tanggal Jatuh Tempo </div>
                                            <div className="col-8">{rekState.rekSelected?.nama_cabang}</div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Jangka Waktu </div>
                                            <div className="col-8">{rekState.rekSelected?.nama_cabang}</div>
                                        </div>
                                    </div>
                                </div>


                            </div> */}
                        </div>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            {/* <ReactToPrint
                                trigger={() => <button
                                    // onClick={() => rekAction.closeCetakBilyet()}
                                    className="btn btn-success float-right ml-3">
                                    Cetak
                                </button>}
                                content={() => refCetak.current}
                            /> */}

                            <button
                                onClick={() => rekAction.doCetak()}
                                className="btn btn-success float-right ml-3">
                                Cetak
                            </button>
                            <button
                                onClick={() => rekAction.closeCetakBilyet()}
                                className="btn btn-outline-danger float-right">
                                Batal
                            </button>

                        </div>
                    </div>
                </Modal.Footer>
            </Modal >
            {/* end modal utama */}
        </>

    );
}

export default CetakBilyetPrint;