import { memo } from 'react';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';

const TableGrid = memo(
    ({
        columns = [],
        data = [],
        className,
        onRenderField,
        isLoading = false,
        active,
        onRowClick,
        config = {
            overflowY: 'unset',
            overflowX: 'unset',
            maxHeight: 'auto',
            stickyHead: false,
            height: 'auto',
            width: 'auto',
            maxWidth: 'auto',
            tableMinWidth: 'auto',
            headerBackground: '#fafafa',
        },
        onRenderColumnField,
    }) => {
        const columnGridTemplate = columns
            .map(({ width }) => {
                if (!width) return 'minmax(auto, 1fr)';
                return width;
            })
            .join(' ');

        const {
            maxHeight,
            maxWidth,
            height,
            width,
            overflowY,
            tableMinWidth,
            overflowX,
            headerBackground,
        } = config;

        return (
            <div
                style={{ overflowY, maxHeight, maxWidth, height, width, overflowX }}
                className={className}
            >
                <div
                    style={{
                        gridTemplateColumns: columnGridTemplate,
                        background: headerBackground ?? '#fafafa',
                        minWidth: tableMinWidth,
                    }}
                    className={clsx('tw-grid tw-px-6 tw-gap-6 tw-text-12 tw-items-center', {
                        'tw-sticky tw-top-0': config.stickyHead,
                    })}
                >
                    {columns?.map(({ name, field, textAlign = 'left' }) => {
                        const ctx = {};
                        ctx.className = `tw-py-4 tw-text-black tw-font-semibold tw-text-${textAlign}`;

                        const renderColumnField = onRenderColumnField
                            ? onRenderColumnField(name, field, ctx)
                            : undefined;

                        if (renderColumnField) return renderColumnField;

                        return (
                            <div className={ctx.className} key={field}>
                                {name}
                            </div>
                        );
                    })}
                </div>
                <div>
                    {isLoading && <Skeleton height={48} count={15} />}
                    {!isLoading &&
                        data?.map((value, i) => {
                            const rowContext = {};
                            rowContext.className =
                                'tw-grid tw-px-6 tw-gap-6 tw-text-12 tw-items-center';
                            rowContext.index = i;

                            const isActive = active ? active(value, rowContext) : undefined;

                            const handleRowClick = () => {
                                if (onRowClick) onRowClick(value, i);
                            };

                            return (
                                <div
                                    onClick={handleRowClick}
                                    style={{
                                        gridTemplateColumns: columnGridTemplate,
                                        minWidth: tableMinWidth,
                                    }}
                                    className={clsx(rowContext.className, {
                                        'tw-bg-black-10': isActive,
                                        'tw-cursor-pointer': onRowClick,
                                    })}
                                    key={`table-grid-row-body${i}`}
                                >
                                    {columns.map(({ field }) => {
                                        const className = 'tw-py-4 tw-text-black';
                                        const ctx = {};
                                        ctx.className = className;
                                        ctx.data = value;
                                        ctx.index = i;
                                        const renderField = onRenderField
                                            ? onRenderField(value[field], field, ctx)
                                            : undefined;

                                        if (!renderField)
                                            return (
                                                <div
                                                    className={ctx.className}
                                                    key={`${field}-${value[field]}`}
                                                >
                                                    {value[field]}
                                                </div>
                                            );

                                        return renderField;
                                    })}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
);

export default TableGrid;
