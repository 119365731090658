import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router';
import { FrameRegistration, NewLoading } from '../../../../controls';
import BeneficiaryOwner from './beneficiaryOwner';
import DataCCD from './dataCCD';
import DataRekening from './dataRekening';
import IdentitasNasabah from './identitasNasabah';
import PekerjaanAlamat from './pekerjaanAlamat';
import RelasiAhliWaris from './relasiAhliWaris';
import useFormPerorangan, { FpProvider } from './useFormPerorangan';


function FpIndex() {
    const { fpAction, fpState } = useFormPerorangan();
    const { mode } = useParams()
    const history = useHistory()
  useEffect(function () {
    fpAction.auditTrail()
}, [])
    

    useEffect(function(){
        document.addEventListener("keydown", fpAction.handleShiftDown)
        document.addEventListener("keyup", fpAction.handleShiftUp)
        return function(){
            document.removeEventListener("keydown", fpAction.handleShiftDown)
            document.removeEventListener("keyup", fpAction.handleShiftUp)
        }
    },[])
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-2 mb-3">
                    <li className="breadcrumb-item color-primary">Nasabah / CIF</li>
                    <li className="breadcrumb-item">
                        <a href="#">Nasabah Perorangan</a>
                    </li>
                    <li className="breadcrumb-item">{capitalize(fpState.paramUrl?.mode)}</li>
                </ol>
            </nav>
            <FrameRegistration
                manualDone={(mode !== "registration")}
                steps={[
                    {
                        name: 'Identitas Nasabah',
                        id: '0',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },
                    {
                        name: 'Pekerjaan & Alamat',
                        id: '1',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },
                    {
                        name: 'Data tambahan & CDD',
                        id: '2',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },

                    {
                        name: 'Data Rekening',
                        id: '3',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },
                    {
                        name: 'Relasi & Ahli Waris',
                        id: '4',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },
                    {
                        name: 'Beneficiary Owner',
                        id: '5',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },
                ]}
                activeStep={fpState.step}
                onStepClick={(_idx)=> { if(mode !== "registration" || mode !== "edit" ) {fpAction.clickStep(_idx)}}}
            >
                <div id="step" dataStep={0}>
                    <IdentitasNasabah />
                </div>
                <div id="step" dataStep={1}>
                    <PekerjaanAlamat />
                </div>
                <div id="step" dataStep={2}>
                    <DataCCD />
                </div>
                <div id="step" dataStep={3}>
                    <DataRekening />
                </div>
                <div id="step" dataStep={4}>
                    <RelasiAhliWaris />
                </div>
                <div id="step" dataStep={5}>
                    <BeneficiaryOwner />
                </div>
                <div id="footer">
                    <div className="row">
                        <div className="col">
                            {
                                mode === "otorisasi" && (
                                    <button onClick={()=>history.goBack()} className="btn btn-sm btn-outline-primary">
                                        Back
                                    </button>
                                )
                            }
                            {
                                fpState.formEditable &&
                                // fpState.step === 1 &&
                                <button 
                                onClick={fpAction.resetForm}
                                className="btn btn-outline-danger">
                                    Reset
                                </button>
                            }
                            <button
                                onClick={fpAction.nextStep}
                                className={`
                                    btn btn-${fpState.step === 5 ? "secondary" :
                                        "orange-primary"
                                    } mr-2 float-right
                                `}
                            >
                                Selanjutnya
                            </button>


                            <button
                                onClick={fpAction.prevState}
                                className={`
                                    btn btn-${fpState.step === 0 ? "secondary" :
                                        "orange-primary"
                                    } mr-2 float-right
                                `}
                            >
                                Sebelumnya
                            </button>

                            {
                                fpState.formEditable &&
                                fpState.step === 5 &&
                                <button onClick={fpAction.confirmPostNasabah} className="btn btn-primary float-right mr-2">
                                    Simpan
                                </button>
                            }

                            {
                                fpState.paramUrl?.mode === "otorisasi" && (
                                    <div style={{
                                        width: "fit-content",
                                        float: "right",
                                        borderRight: "1px sold gray",
                                        margin: "0px 5px"
                                    }}>
                                        <button
                                            onClick={() => fpAction.responseOtor("APPROVED")}
                                            className="btn btn-success mr-2">
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => fpAction.responseOtor("REJECTED")}
                                            className="btn btn-danger mr-2">
                                            Reject
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </FrameRegistration>

            {/* modal Alert */}
            <Modal show={fpState.dataAlert.show} //onHide={fpAction.closeAlert} 
                backdrop="static"
            >
                {
                    fpState.dataAlert.header !== null && fpState.dataAlert.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {fpState.dataAlert.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <h5 style={{ textAlign: "center", margin: "5px", textTransform: "capitalize" }}>{fpState.dataAlert.title}</h5>
                            <div className="box">
                                <p>
                                    {fpState.dataAlert.body}
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            <button onClick={() => {
                                fpAction.closeAlert();
                                if (fpState.dataAlert.callBack !== null && fpState.dataAlert.callBack !== undefined) fpState.dataAlert.callBack();
                            }
                            } className="btn btn-primary btn-sm">
                                OK
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal alert */}
            {/* modal confirm */}
            <Modal show={fpState.dataConfirm.show}

                backdrop="static"
            >
                {
                    fpState.dataConfirm.header !== null && fpState.dataConfirm.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                            {fpState.dataConfirm.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {/* <h4 style={{ textAlign: "center" }}>{fpState.dataConfirm.title}</h4> */}

                            {/* <div className="box"> */}
                            <h5>
                                {fpState.dataConfirm.body}
                            </h5>
                            {
                                fpState.dataConfirm.witTextArea && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm">
                                                <label>Keterangan :</label>
                                                <textarea
                                                    className="form-control form-control-sm"
                                                    value={fpState.otorDesc}
                                                    onChange={(e) => fpAction.setOtorDesc(e.target.v)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {/* </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            {
                                fpState
                                    .dataConfirm
                                    .closeButton && <button onClick={fpAction.closeAlert} className="btn btn-secondary btn-sm float-right mr-2">
                                    Close
                                </button>
                            }
                            {
                                fpState
                                    .dataConfirm
                                    .footerButton?.map(
                                        ({
                                            className = "",
                                            onClick = () => null,
                                            style = {},
                                            name = "Ok" },
                                            i) => <button
                                                key={i}
                                                className={`${className} float-right mr-2`}
                                                onClick={onClick}
                                                style={style}
                                            >
                                                {name}
                                            </button>
                                    )
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal confirm */}
            {/* mdoal loading */}
            <Modal show={fpState.loading.loadingPost?.show}
                backdrop="static"
            >
                <Modal.Body>
                    <div style={{
                        margin: "auto",
                        width: "fit-content",
                        padding: "5% 0%",
                        textAlign: "center",

                    }}>
                        <NewLoading width="100px" />
                        <h5 className="text-base">{fpState.loading.loadingPost?.msg}</h5>
                    </div>
                </Modal.Body>
            </Modal>
            {/*  */}
        </>
    )
}

export default () => <FpProvider><FpIndex/></FpProvider>