import React, { memo, useRef, useState } from 'react';

export default memo(function({
    children,
    items,
    style,
    dropType =""
}){
    const [show, setShow] = useState("")
    // console.log(props)
    // dropleft, dropright
    const refDrop= useRef(null)
    return(
        <div className="btn-group">

            <div class={`btn-group ${dropType}`} role="group">

                <button style={style} onClick={() => setShow(prevState => prevState === "show" ? "" : "show")} >
                    {children}
                </button>
                <div className={`dropdown-menu ${show}`}>
                    {
                        items?.map(({withDivider = false, onClick=()=>null, name="" }, i) =>(
                            <>
                                {
                                    withDivider && (<div class="dropdown-divider"></div>)
                                }
                                <button class="dropdown-item" onClick={()=>{
                                    setShow("")
                                    return onClick()
                                }} type="button">{name}</button>
                            </>
                        ))
                    }
                </div>
            </div>
        </div>
    )
});