import { Breadcrumb, Button, Label } from 'semantic-ui-react';
import { FormControl, FormLabel, InputGroup, Modal, Form } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useState, useCallback } from 'react';
import { debounce } from 'lodash-es';
import { getErrorEmptyValue, thousandSeparator } from '../../../../utils';
import { FormHeader } from '../../../controls';
import { baseUrl } from '../../../../app.config';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../../hooks/keycloak.module';
import queryString from 'query-string';

const SetorDepositoPage = () => {
    const history = useHistory();

    const { keycloak } = useKeycloak();

    const [error, setError] = useState({});
    const [rekeningDeposito, setRekeningDeposito] = useState({});
    const [rekeningSumber, setRekeningSumber] = useState({
        no_rekening_debit: null,
    });
    const [form, setForm] = useState({
        kurs_buku: 1,
        kurs_beli: 1,
        kurs_jual: 1,
        valuta: 'IDR',
        biaya: '',
        biaya_ekuivalen: '',
        nilai_transaksi: '',
        nilai_ekuivalen: '',
    });

    const { data: dropdownJenisAplikasi } = useQuery('dropdown-jenis-aplikasi', async () => {
        const res = await fetch(baseUrl + '/dropdowns?key=eJenisAplikasi');

        const data = await res.json();

        return data.list;
    });

    const { data: dropdownSumberBiaya } = useQuery('dropdown-sumber-biaya', async () => {
        const res = await fetch(baseUrl + '/dropdowns?key=eSumberDana');

        const data = await res.json();

        return data.list;
    });

    // eslint-disable-next-line
    const findDeposito = useCallback(
        debounce(async (value, callback) => {
            const res = await fetch(
                baseUrl + `/deposito/find/${value}?detailsLevel=FULL&accountHolderType=CLIENT`
            );
            const resGroup = await fetch(
                baseUrl + `/deposito/find/${value}?detailsLevel=FULL&accountHolderType=GROUP`
            );

            const data = await res.json();
            const dataGroup = await resGroup.json();

            // if (!data.list && !dataGroup.list) {
            //     setRekeningDeposito({});
            //     if (callback) callback('error');
            //     return;
            // }

            let dataForm;

            if (data.message_id === '00') dataForm = data;
            else if (dataGroup.message_id === '00') dataForm = dataGroup;

            if (dataForm?.list?.statusAkun !== 'APPROVED') {
                setRekeningDeposito({})
                setForm({
                    kurs_buku: 1,
                    kurs_beli: 1,
                    kurs_jual: 1,
                    valuta: 'IDR',
                    biaya: '',
                    nilai_transaksi: '',
                    nilai_ekuivalen: '',
                    no_referensi: '',
                });
                setRekeningSumber({
                    no_rekening_debit: '',
                });
                setRekeningSumber({});

                if (callback) callback('not approved');
                return;
            } else {
                const resNasabahDetail = await fetch(baseUrl + `/rekening/tabungan/detail/${value}`);
                const nasabahDetail = await resNasabahDetail.json();
                
                setRekeningDeposito({
                    ...dataForm?.list,
                    namaProduk: nasabahDetail.list?.produk,
                    cabang: nasabahDetail.list?.cabang,
                    nama: nasabahDetail.list?.nama,
                    valuta_rekening: nasabahDetail.list?.valuta,
                });

                let newNilaiTransaksi = thousandSeparator.format(
                    dataForm?.list?._rekening?.nominalDeposito,
                    { preventNegative: true }
                );
                setForm({
                    ...form,
                    nilai_transaksi: newNilaiTransaksi,
                    nilai_ekuivalen: thousandSeparator.multiply(newNilaiTransaksi, form.kurs_jual),
                    no_referensi: dataForm?.list?._rekening?.nomorBilyet,
                });

                setRekeningSumber({
                    no_rekening_debit: dataForm?.list?._rekening?.nomorRekeningDebet,
                });
                await findRekeningSumber(dataForm?.list?._rekening?.nomorRekeningDebet, (status) => {
                    if (status === 'error' && value > 0) setError({ ...error, rekeningSumber: true });
                    else setError({ ...error, rekeningSumber: false, rekeningSumberEmpty: false });
                });

                if (callback) callback('success');
                return;
            }

        }, 500),
        []
    );

    const handleSearchDeposito = useCallback(
        async (e) => {
            const { value } = e.target;

            if (error.rekeningDepositoEmpty) setError({ ...error, rekeningDepositoEmpty: false });

            await findDeposito(value, (status) => {
                if (status === 'error' && value > 0) {
                    setError({ ...error, rekeningDeposito: true });
                }
                else if (status !== 'success')
                    setError({ ...error, statusNotApproved: true, rekeningDeposito: true });
                else
                    setError({
                        ...error,
                        rekeningDeposito: false,
                        statusNotActive: false,
                        rekeningDepositoEmpty: false,
                        statusNotApproved: false
                    });
            });
        },
        [error, findDeposito]
    );

    // eslint-disable-next-line
    const findRekeningSumber = useCallback(
        debounce(async (value, callback) => {
            if (!value) {
                setRekeningSumber({});
                callback('no value');
                return;
            }

            const res = await fetch(baseUrl + `/rekening/tabungan/detail/${value}`);

            const data = await res.json();

            if (!data.list) {
                setRekeningSumber({});
                if (callback) callback('error');
                return;
            }

            const { produk, valuta, cabang, nama } = data.list;

            setRekeningSumber({
                nama_debit: nama,
                valuta_debit: valuta,
                cabang_debit: cabang,
                no_rekening_debit: value,
                produk,
            });
            if (callback) callback('success');
        }, 500),
        []
    );

    const handleSearchSumber = useCallback(
        async (e) => {
            const { value } = e.target;

            if (error.rekeningSumberEmpty) setError({ ...error, rekeningSumberEmpty: false });

            await findRekeningSumber(value, (status) => {
                if (status === 'error' && value > 0) setError({ ...error, rekeningSumber: true });
                else setError({ ...error, rekeningSumber: false, rekeningSumberEmpty: false });
            });
        },
        [error, findRekeningSumber]
    );

    const handleChangeForm = (e) => {
        const { value, name } = e.target;

        if (error[name]) setError({ ...error, [name]: false });

        if (['nilai_transaksi', 'biaya'].includes(name)) {
            const newValue = thousandSeparator.format(value, { preventNegative: true });

            if (newValue === 'NaN') return;

            if (name === 'nilai_transaksi')
                setForm({
                    ...form,
                    nilai_transaksi: newValue,
                    nilai_ekuivalen: thousandSeparator.multiply(newValue, form.kurs_jual),
                });
            else if (name === 'biaya')
                setForm({
                    ...form,
                    biaya: newValue,
                    biaya_ekuivalen: thousandSeparator.multiply(newValue, form.kurs_jual),
                });

            return;
        }

        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = useMutation(async () => {
        const { err, isAnyEmptyError } = getErrorEmptyValue({
            nilai_transaksi: form.nilai_transaksi,
            // jenis_referensi: form.jenis_referensi,
            // no_referensi: form.no_referensi,
            rekeningDepositoEmpty: rekeningDeposito.nomorRekeningDeposito,
            rekeningSumberEmpty: rekeningSumber.no_rekening_debit,
        });

        if (isAnyEmptyError) {
            setError(err);
            throw new Error('There is empty field');
        }

        const body = {
            ...form,
            ...rekeningSumber,
            nilai_transaksi: thousandSeparator.unformat(form.nilai_transaksi),
            nilai_ekuivalen: thousandSeparator.unformat(form.nilai_ekuivalen),
            biaya: thousandSeparator.unformat(form.biaya),
            biaya_ekuivalen: thousandSeparator.unformat(form.biaya_ekuivalen),
            id_user: keycloak.idTokenParsed.preferred_username,
            nama_nasabah: rekeningDeposito.namaRekening ?? rekeningDeposito.nama,
            cabang_rekening: rekeningDeposito.cabang,
            produk: rekeningDeposito.namaProduk,
            no_rekening: rekeningDeposito.nomorRekeningDeposito,
            valuta_rekening: rekeningDeposito.valuta_rekening,
            tgl_jatuh_tempo: rekeningDeposito?._rekening?.tanggalJatuhTempo,
            transaction_ch_id: '2129847', // nanti dikasih dari BNS
            transaction_encoded_key: '12390', // nanti dikasih dari BNS
        };

        // console.log(JSON.stringify(body));

        const { isAnyEmptyError: isError } = getErrorEmptyValue({
            no_rekening: body.no_rekening,
            no_rekening_debit: body.no_rekening_debit,
        });

        if (isError) throw new Error('Rekening Deposito or Rekening Sumber are not found');

        const res = await fetch(baseUrl + '/deposito/lokal/setor', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        const data = await res.json();

        if (data.message_id === '01') {
            toast.warning(data.status, {
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                position: 'top-right',
            });

            throw new Error(JSON.stringify(data.status));
        }

        if (data.message_id === '02') {
            toast.error('Something went wrong', {
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                position: 'top-right',
            });

            throw new Error(JSON.stringify(data.status));
        }

        return data;
    });

    const handleReset = () => {
        setForm({
            kurs_buku: 1,
            kurs_beli: 1,
            kurs_jual: 1,
            valuta: 'IDR',
            biaya: '',
            biaya_ekuivalen: '',
            nilai_transaksi: '',
            nilai_ekuivalen: '',
        });
        setRekeningSumber({});
        setRekeningDeposito({});
        handleSubmit.reset();
        document.getElementById('searchDeposito').value = '';
        document.getElementById('searchGL').value = '';
        setShowModalBatal(false);

        //history.push('/informasi-transaksi/informasi-transaksi-hari-ini');
    };

    const { search } = useLocation();
    const { from } = queryString.parse(search);

    const [svsParams, setSvsParams] = useState([
        {
            data: {
                nomor_cif: null,
                encoded_key_document: 'string',
                filename: '',
                image_tag: '',
                remark: '',
                type: 'Nasabah Badan Usaha',
            },
            options: {
                isChoosedFile: false,
                selectedFile: null,
                selectedFileUrl: null,
            },
        },
        {
            data: {
                nomor_cif: null,
                encoded_key_document: 'string',
                filename: '',
                image_tag: '',
                remark: '',
                type: 'Nasabah Badan Usaha',
            },
            options: {
                isChoosedFile: false,
                selectedFile: null,
                selectedFileUrl: null,
            },
        },
        {
            data: {
                nomor_cif: null,
                encoded_key_document: 'string',
                filename: '',
                image_tag: '',
                remark: '',
                type: 'Nasabah Badan Usaha',
            },
            options: {
                isChoosedFile: false,
                selectedFile: null,
                selectedFileUrl: null,
            },
        },
        {
            data: {
                nomor_cif: null,
                encoded_key_document: 'string',
                filename: '',
                image_tag: '',
                remark: '',
                type: 'Nasabah Badan Usaha',
            },
            options: {
                isChoosedFile: false,
                selectedFile: null,
                selectedFileUrl: null,
            },
        },
    ]);
    const [svsData, setSvsData] = useState([]);

    const getData = () => {
        let id = rekeningDeposito?._dataNasabah?.nomorCIF;
        let url =
            from === 'local'
                ? `/nasabah/svs/local/find-all/${id}`
                : `/nasabah/svs/mambu/find/${id}`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                //let svsParams = [...svsParams];
                let dataList = from === 'local' ? data.list : data.list?.db?.list;
                if (dataList && dataList.length > 0) {
                    dataList.map(
                        (value, index) =>
                            (svsParams[index] = {
                                data: {
                                    nomor_cif: value.nomor_cif,
                                    encoded_key_document: value.encoded_key_document,
                                    filename: value.filename,
                                    signed_url: value.signed_url,
                                    image_tag: value.image_tag,
                                    remark: value.remark,
                                    type: value.type,
                                },
                                options: {
                                    isChoosedFile: false,
                                    selectedFile: null,
                                    selectedFileUrl: null,
                                },
                            })
                    );
                }
                setSvsParams(svsParams);
                setSvsData(dataList);
            });
    };

    const [showModalSvs, setShowModalSvs] = useState(false);
    const [showModalBatal, setShowModalBatal] = useState(false);

    return (
        <>
            <Modal
                show={handleSubmit.isSuccess}
                onHide={() => {
                    handleSubmit.reset();
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Setor transaksi berhasil dilakukan
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleReset}
                        className="btn btn-primary"
                        size="mini"
                        color="blue"
                    >
                        Selesai
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalBatal} onHide={() => setShowModalBatal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Konfirmasi</Modal.Title>
                </Modal.Header>
                <Modal.Body>Batalkan proses Setor Deposito?</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowModalBatal(false)}>
                        Tidak
                    </button>
                    <button className="btn btn-primary" onClick={handleReset}>
                        Ya
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalSvs} onHide={() => setShowModalSvs(false)} centered>
                <Modal.Header>
                    <Modal.Title>Data Grafis / Tanda Tangan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-body p0">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Image Tag</th>
                                        <th>Taq Sequence</th>
                                        <th>Remark</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {svsData && svsData.length > 0
                                        ? svsData.map((data, index) => {
                                              return (
                                                  <tr key={`listsaccountimages${index}`}>
                                                      <td>{data?.image_tag}</td>
                                                      <td>{index + 1}</td>
                                                      <td>{data?.remark}</td>
                                                      <td>{data?.type}</td>
                                                  </tr>
                                              );
                                          })
                                        : null}
                                </tbody>
                            </table>
                            <div className="p-3">
                                <div className="row">
                                    {}
                                    {svsData && svsData.length > 0
                                        ? svsData.map((data, index) => {
                                              return (
                                                  <div
                                                      className="col-4"
                                                      key={`listsaccountimagessign${index}`}
                                                  >
                                                      <p>
                                                          Image {index + 1} - {data?.image_tag}
                                                      </p>
                                                      <div className="svs-box">
                                                          <div className="mt-5 text-center">
                                                              <img
                                                                  src={data?.signed_url}
                                                                  height={110}
                                                              />
                                                          </div>
                                                      </div>
                                                      <p className="border p-2 mt-2 fs-12">
                                                          {data?.remark}
                                                      </p>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowModalSvs(false)}>
                        Tutup
                    </button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-primary-bds" link>
                    Deposito
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Registrasi Deposito</Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <div className="tw-bg-white tw-rounded-lg tw-py-4 tw-px-10 tw-shadow-lg tw-col-start-2">
                    <FormHeader className="tw-mb-4">Rekening Deposito</FormHeader>
                    <div>
                        <div className="tw-mb-4">
                            <div>
                                <FormLabel className="tw-font-normal tw-mr-1">
                                    No Rekening Deposito
                                </FormLabel>
                                <Label size="mini">Wajib</Label>
                            </div>
                            <FormControl
                                onChange={handleSearchDeposito}
                                placeholder="Masukkan nomor rekening deposito"
                                size="sm"
                                id="searchDeposito"
                                isInvalid={
                                    error.rekeningDeposito ||
                                    error.statusNotActive ||
                                    error.rekeningDepositoEmpty
                                }
                                type="text"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            <FormControl.Feedback type="invalid">
                                status rekening bukan Approved
                                {/* {error.statusNotApproved ??
                                    'status rekening bukan Approved'
                                } */}
                                {/* {error.statusNotApproved
                                    ? 'Status rekening tidak aktif'
                                    : error.rekeningDepositoEmpty
                                    ? 'Rekening deposito wajib diisi'
                                    : 'Rekening deposito tidak dapat ditemukan'} */}
                            </FormControl.Feedback>
                        </div>
                        <div className="tw-mb-4">
                            <FormLabel className="tw-font-normal">Nama Nasabah</FormLabel>
                            <FormControl
                                value={rekeningDeposito?._dataNasabah?.nama || ''}
                                disabled
                                size="sm"
                                placeholder="NAMA NASABAH"
                            />
                        </div>
                        <div className="tw-mb-4">
                            <div className="tw-flex tw-items-center">
                                <div className="tw-mr-6">
                                    <FormLabel className="tw-font-normal">
                                        Valuta Rekening
                                    </FormLabel>
                                    <FormControl
                                        value={rekeningDeposito?._rekening?.valuta || ''}
                                        disabled
                                        size="sm"
                                        placeholder="IDR"
                                    />
                                </div>
                                <div className="tw-w-full">
                                    <FormLabel className="tw-font-normal">
                                        Cabang Rekening
                                    </FormLabel>
                                    <InputGroup size="sm">
                                        {/* <InputGroup.Prepend>
                                            <InputGroup.Text>IDR</InputGroup.Text>
                                        </InputGroup.Prepend> */}
                                        <FormControl
                                            disabled
                                            size="sm"
                                            placeholder="KCP KOTA BANDUNG"
                                            value={rekeningDeposito?.cabang || ''}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tw-mb-6">
                            <FormLabel className="tw-font-normal">Produk</FormLabel>
                            <FormControl
                                value={rekeningDeposito?._rekening?.produk || ''}
                                placeholder="Nama Produk"
                                disabled
                                size="sm"
                            />
                        </div>
                        <div className="tw-mb-6">
                            <div className="tw-flex tw-items-center">
                                <div className="tw-mr-6">
                                    <FormLabel className="tw-font-normal">Tanggal Buka</FormLabel>
                                    <FormControl
                                        value={
                                            rekeningDeposito?._rekening?.tanggalBuka
                                                ? dayjs(
                                                      rekeningDeposito?._rekening?.tanggalBuka
                                                  ).format('YYYY-MM-DD')
                                                : ''
                                        }
                                        disabled
                                        size="sm"
                                        type="date"
                                    />
                                </div>
                                <div className="tw-mr-6">
                                    <FormLabel className="tw-font-normal">
                                        Tanggal Jatuh Tempo
                                    </FormLabel>
                                    <FormControl
                                        value={rekeningDeposito?._rekening?.tanggalJatuhTempo || ''}
                                        disabled
                                        size="sm"
                                        type="date"
                                    />
                                </div>
                            </div>
                        </div>
                        <Button
                            size="small"
                            basic
                            color="grey"
                            className="tw-w-full tw-mb-6"
                            onClick={() => {
                                getData();
                                setShowModalSvs(true);
                            }}
                            disabled={!rekeningDeposito?._dataNasabah}
                        >
                            Lihat Data Grafis/Tanda Tangan
                        </Button>
                    </div>
                </div>
                <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-py-4 tw-px-10 tw-row-start-1">
                    <FormHeader className="tw-mb-4">Rekening Sumber</FormHeader>
                    <div>
                        <div className="tw-mb-4">
                            <div>
                                <FormLabel className="tw-font-normal tw-mr-1">
                                    No Rekening Sumber
                                </FormLabel>
                                <Label size="mini">Wajib</Label>
                            </div>
                            <FormControl
                                id="searchGL"
                                isInvalid={error.rekeningSumber || error.rekeningSumberEmpty}
                                onChange={handleSearchSumber}
                                placeholder="Masukkan no rekening sumber"
                                size="sm"
                                type="text"
                                value={rekeningSumber?.no_rekening_debit}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            <FormControl.Feedback type="invalid">
                                {error.rekeningSumberEmpty
                                    ? 'Rekening sumber wajib diisi'
                                    : 'Rekening sumber tidak dapat ditemukan'}
                            </FormControl.Feedback>
                        </div>
                        <div className="tw-mb-4">
                            <FormLabel className="tw-font-normal">Nama Rekening</FormLabel>
                            <FormControl
                                value={rekeningSumber?.nama_debit || ''}
                                disabled
                                size="sm"
                                placeholder="Nama rekening"
                            />
                        </div>
                        <div className="tw-mb-4">
                            <div className="tw-flex tw-items-center">
                                <div className="tw-mr-6">
                                    <FormLabel className="tw-font-normal">
                                        Valuta Rekening
                                    </FormLabel>
                                    <FormControl
                                        value={rekeningSumber?.valuta_debit || ''}
                                        disabled
                                        size="sm"
                                        placeholder="IDR"
                                    />
                                </div>
                                <div className="tw-w-full">
                                    <FormLabel className="tw-font-normal">
                                        Cabang Rekening
                                    </FormLabel>
                                    <InputGroup size="sm">
                                        {/* <InputGroup.Prepend>
                                            <InputGroup.Text>IDR</InputGroup.Text>
                                        </InputGroup.Prepend> */}
                                        <FormControl
                                            disabled
                                            size="sm"
                                            placeholder="KCP KOTA BANDUNG"
                                            value={rekeningSumber?.cabang_debit || ''}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tw-mb-4">
                            <FormLabel className="tw-font-normal">Produk</FormLabel>
                            <FormControl
                                value={rekeningSumber?.produk || ''}
                                disabled
                                size="sm"
                                placeholder="Nama produk"
                            />
                        </div>
                    </div>
                </div>
                <div className="tw-bg-white tw-col-span-2 tw-py-4 tw-px-10 tw-rounded-lg tw-shadow-lg">
                    <div className="tw-grid tw-grid-cols-12 tw-gap-x-6 tw-gap-5 tw-mb-8">
                        <div className="tw-col-span-3">
                            <div>
                                <FormLabel className="tw-font-normal tw-mr-1">
                                    Jenis No. Referensi
                                </FormLabel>
                                {/* <Label size="mini">Wajib</Label> */}
                            </div>
                            <FormControl
                                name="jenis_referensi"
                                onChange={handleChangeForm}
                                size="sm"
                                as="select"
                                value={form.jenis_referensi || ''}
                                // isInvalid={error.jenis_referensi}
                            >
                                <option value="" hidden disabled selected>
                                    Pilih Jenis Referensi
                                </option>
                                {dropdownJenisAplikasi?.map(
                                    ({ reference_desc, id, reference_code }) => (
                                        <option
                                            key={id}
                                            value={`${reference_code} - ${reference_desc}`}
                                        >
                                            {reference_desc}
                                        </option>
                                    )
                                )}
                            </FormControl>
                            <Form.Control.Feedback type="invalid">
                                Jenis referensi wajib diisi
                            </Form.Control.Feedback>
                        </div>
                        <div className="tw-col-span-3">
                            <div>
                                <FormLabel className="tw-font-normal tw-mr-1">
                                    No. Referensi/Warkat
                                </FormLabel>
                                {/* <Label size="mini">Wajib</Label> */}
                            </div>
                            <FormControl
                                value={form.no_referensi || ''}
                                onChange={handleChangeForm}
                                name="no_referensi"
                                size="sm"
                                // isInvalid={error.no_referensi}
                            />
                            <Form.Control.Feedback type="invalid">
                                No referensi wajib diisi
                            </Form.Control.Feedback>
                        </div>
                        <div className="tw-col-span-6">
                            <FormLabel className="tw-font-normal">Valuta Transaksi</FormLabel>
                            <div style={{ gridTemplateColumns: '100px 1fr' }} className="tw-grid">
                                <FormControl
                                    onChange={handleChangeForm}
                                    name="valuta"
                                    size="sm"
                                    as="select"
                                    value={form.valuta || ''}
                                >
                                    <option value="" selected hidden disabled>
                                        Valuta
                                    </option>
                                    <option value="IDR">IDR</option>
                                </FormControl>
                                <FormControl value="INDONESIAN RUPIAH" disabled size="sm" />
                            </div>
                        </div>
                        <div className="tw-col-span-3">
                            <div>
                                <FormLabel className="tw-font-normal tw-mr-1">
                                    Nilai Transaksi
                                </FormLabel>
                                <Label size="mini">Wajib</Label>
                            </div>
                            <FormControl
                                onChange={handleChangeForm}
                                name="nilai_transaksi"
                                size="sm"
                                value={form.nilai_transaksi || ''}
                                isInvalid={error.nilai_transaksi}
                            />
                            <Form.Control.Feedback type="invalid">
                                Nilai transaksi wajib diisi
                            </Form.Control.Feedback>
                        </div>
                        <div className="tw-col-span-2">
                            <FormLabel className="tw-font-normal">Kurs Buku</FormLabel>
                            <FormControl
                                onChange={handleChangeForm}
                                name="kurs_buku"
                                size="sm"
                                placeholder="0,00"
                                value={form.kurs_buku || ''}
                                disabled
                            />
                        </div>
                        <div className="tw-col-span-2">
                            <FormLabel className="tw-font-normal">Kurs Beli</FormLabel>
                            <FormControl
                                onChange={handleChangeForm}
                                name="kurs_beli"
                                size="sm"
                                placeholder="0,00"
                                value={form.kurs_beli || ''}
                                disabled
                            />
                        </div>
                        <div className="tw-col-span-2">
                            <FormLabel className="tw-font-normal">Kurs Jual</FormLabel>
                            <FormControl
                                onChange={handleChangeForm}
                                name="kurs_jual"
                                size="sm"
                                placeholder="0,00"
                                value={form.kurs_jual || ''}
                                disabled
                            />
                        </div>
                        <div className="tw-col-span-3">
                            <FormLabel className="tw-font-normal">Nilai ekuivalen</FormLabel>
                            <FormControl
                                onChange={handleChangeForm}
                                name="nilai_ekuivalen"
                                size="sm"
                                value={form.nilai_ekuivalen || ''}
                                placeholder="0,00"
                                disabled
                            />
                        </div>
                        <div className="tw-col-span-3">
                            <div>
                                <FormLabel className="tw-font-normal tw-mr-1">Biaya (Rp)</FormLabel>
                            </div>
                            <FormControl
                                value={form.biaya || ''}
                                onChange={handleChangeForm}
                                name="biaya"
                                size="sm"
                                isInvalid={error.biaya}
                            />
                        </div>
                        <div className="tw-col-span-3">
                            <FormLabel className="tw-font-normal">Biaya ekuivalen</FormLabel>
                            <FormControl
                                name="biaya_ekuivalen"
                                onChange={handleChangeForm}
                                size="sm"
                                placeholder="0,00"
                                value={form.biaya_ekuivalen || ''}
                                disabled
                            />
                        </div>
                        <div className="tw-col-span-3">
                            <div>
                                <FormLabel className="tw-font-normal tw-mr-1">
                                    Sumber Biaya
                                </FormLabel>
                            </div>
                            <FormControl
                                onChange={handleChangeForm}
                                name="sumber_biaya"
                                size="sm"
                                as="select"
                                value={form.sumber_biaya || ''}
                                isInvalid={error.sumber_biaya}
                            >
                                <option value="" hidden selected disabled>
                                    Pilih Sumber Biaya
                                </option>
                                {dropdownSumberBiaya?.map(
                                    ({ reference_desc, id, reference_code }) => (
                                        <option
                                            key={id}
                                            value={`${reference_code} - ${reference_desc}`}
                                        >
                                            {reference_desc}
                                        </option>
                                    )
                                )}
                            </FormControl>
                        </div>
                        <div className="tw-col-span-3">
                            <div>
                                <FormLabel name="keterangan" className="tw-font-normal tw-mr-1">
                                    Keterangan
                                </FormLabel>
                            </div>
                            <FormControl
                                value={form.keterangan || ''}
                                name="keterangan"
                                onChange={handleChangeForm}
                                size="sm"
                                placeholder="keterangan penyetoran deposito"
                                as="textarea"
                                rows={5}
                                isInvalid={error.keterangan}
                            />
                        </div>
                    </div>
                    <div className="tw-flex tw-justify-end">
                        <Button onClick={() => setShowModalBatal(true)} className="tw-mr-3">
                            Batal
                        </Button>
                        <Button
                            loading={handleSubmit.isLoading}
                            onClick={() => handleSubmit.mutate()}
                            className="tw-w-64"
                            color="blue"
                        >
                            Proses
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SetorDepositoPage;
