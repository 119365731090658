// import { useKeycloak } from '@react-keycloak/web';
import React, { createContext, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { addCommas } from '../../../../../module';
import { TableList } from '../../../../controls';
import { useModalAlert } from '../../../../controls/modalAlert';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import useKeycloak from '../../../../../hooks/keycloak.module';

const ModalDetilRevContext = createContext({
    mtAction: {},
    mtState: {}
})

export const ModalDetilRevProv = ({ children }) => {

    const { doGet, doPost, wait } = useFetch()
    const [otorDesc, setOtorDesc] = useState("");
    const [otorMode, setOtorMode] = useState(false);
    const [dataOtor, setDataOtor] = useState({})
    const { keycloak } = useKeycloak();
    const location = useLocation();
    const [dataReserve, setDataReserve] = useState(
        {
            "id_transaksi": 0,
            "kode_transaksi": ""
        }
    );

    const [respReserve, setRespReserve] = useState([])

    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const [dataModal, setDataModal] = useState({
        show: false
    });
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    function closeModal() {
        setData([])
        setDataModal(m => ({
            ...m,
            show: false
        }));
    }

    function openModal(id_transaksi_core, isOtor = false, callBack = () => null) {
        // // console.log(id_transaksi_core)
        setOtorMode(isOtor)
        if (isOtor) setDataOtor(o => ({ ...id_transaksi_core, callBack }))
        getDetail(isOtor ? id_transaksi_core.id_transaksi_core : id_transaksi_core, isOtor)
        setDataModal(m => ({
            ...m,
            show: true
        }));
    }

    // getData rekening list
    async function getDetail(id_transaksi_core = 0) {
        if (id_transaksi_core === null || id_transaksi_core === "" || id_transaksi_core === undefined || isNaN(id_transaksi_core)) id_transaksi_core = 0;
        // if(otorMode) id_transaksi_core = id_transaksi_core.dbId

        setIsLoading(true)
        var url = `/informasi/transaksi-detil-by-id_transaksi`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { id_transaksi_core },
                service: "trx"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    // // // console.log(data)s
                    setData(data)
                    // setSaldoKas(data?.saldo_kas_teller)
                }
                else setDataAlert({ show: true, body: remark, titile: ``, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Info", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    function confirmReverse() {
        // // // console.log("tes")

        if (dataReserve.kode_transaksi === "SKN" || dataReserve.kode_transaksi === "RTGS") {
            // return setDataAlert({
            //     show: true, body: `Transaksi ${dataReserve.kode_transaksi} tidak bisa dilakukan reversal melalui aplikasi BDS. Silahkan menghubungi kantor pusat untuk melakukan proses Reversal`, title: ``, header: "Info", callBack: () => null
            // })
            return setDataAlert({
                show: true, 
                    body: `Proses reversal tidak bisa dilakukan mohon konfirmasi
                    bagian settlement untuk melakukan proses pembatalan ${dataReserve.kode_transaksi}`, 
                    title: ``, header: "Info", callBack: () => null
            })
        }
        let body = `Apakah anda yakin melanjutkan reverse transaksi ini. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => postReverse(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // get List Transaksi Cetak Statement
    async function postReverse() {
        // // // console.log(dataReserve);
        setLoading(true);
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: `/reversal`,
                service: "trx",
                data: { id_transaksi: dataReserve.id_transaksi, uid: keycloak.uid },
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: remark, title: ``, header: "Success", callBack: () => {
                        keycloak.updateUID()
                        closeLoading();
                        closeAlert();
                        closeConfirmCustom();
                        closeConfirm();
                        setRespReserve(data)
                        closeModal();
                    }
                });
                else if (status === "05") {
                    closeLoading();
                    closeAlert();
                    closeConfirmCustom();
                    closeConfirm();
                    setRespReserve(data)
                    closeModal();
                }
                else setDataAlert({
                    show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => {
                        closeLoading();
                        closeAlert();
                        closeConfirmCustom();
                        closeConfirm();
                        setRespReserve(data)
                        closeModal();
                    }
                });
            }
            else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({
                show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => {
                    closeLoading();
                    closeAlert();
                    closeConfirmCustom();
                    closeConfirm();
                    setRespReserve(data)
                    closeModal();
                }
            });
        }
        setLoading(false)
    }


    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (dataOtor !== null && dataOtor !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${dataOtor.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            // resetForm();
                            dataOtor.callBack?.()
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            closeModal();
                            setOtorDesc("")
                            // history.goBack()s
                        }
                    }); else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    return (
        <ModalDetilRevContext.Provider value={{
            mtAction: {
                openModal,
                closeModal,
                setOtorDesc,
                responseOtor,
                postReverse,
                confirmReverse,
                setDataReserve
            },
            mtState: {
                otorDesc,
                dataReserve

            }
        }}>
            {children}

            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => setOtorDesc(e.target.value)}
                    value={otorDesc}
                />
            </ModalConfirmCustom>
            <Modal size="xl" show={dataModal.show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Detail Reverse Transaksi
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <TableList
                        withCard={false}
                        minHeight="450px"
                        maxHeight="500px"
                        // onChangeShow={ktAction.changeShow}
                        // onNextPage={ktAction.nextPage}
                        // onPrevPage={ktAction.prevPage}
                        withActions={false}
                        columns={[
                            {
                                name: 'Cabang',
                                field: 'kode_cabang',
                            },
                            {
                                name: 'Rekening',
                                field: 'nomor_rekening',
                            },
                            {
                                name: 'Nama',
                                field: 'nama_rekening',
                            },
                            {
                                name: 'Kode Valuta',
                                field: 'kode_valuta',
                                width: '40px',
                            },
                            {
                                name: 'Jenis Mutasi',
                                field: 'jenis_mutasi',
                            },
                            {
                                name: 'Nilai',
                                field: 'nilai_mutasi',
                                className: "text-right",
                                onRender: (item) => addCommas(item.nilai_mutasi)
                            },
                            {
                                name: 'Kurs',
                                field: 'nilai_kurs',
                            },
                            {
                                name: 'Nilai Ekuivalen',
                                field: 'nilai_ekuivalen',
                                className: "text-right",
                                onRender: (item) => addCommas(item.nilai_ekuivalen)
                            },
                            {
                                name: 'Keterangan',
                                field: 'keterangan',
                            },
                        ]}
                        data={data}
                        // data={ktState.dataList}
                        isLoading={isLoading}
                        pagination={false}
                        filter={false}
                        resetFilter={false}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            {!otorMode ? <button onClick={confirmReverse} className="btn btn-outline-danger btn-sm mr-2">
                                Reverse
                            </button> : (
                                <>
                                    <button className="btn btn-primary btn-sm float-right ml-2" onClick={() => responseOtor("APPROVED")}>
                                        Approve
                                    </button>
                                    <button className="btn btn-danger btn-sm float-right ml-2" onClick={() => responseOtor("REJECTED")}>
                                        Reject
                                    </button>
                                </>
                            )}
                            <button onClick={closeModal} className="float-right btn btn-outline-secondary btn-sm">
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </ModalDetilRevContext.Provider>
    );
}

export default function useModalDetilRev() {
    return useContext(ModalDetilRevContext)
};