import React, { memo } from 'react';
import { dasboardData } from '../../../appConfig.js';
import { SidebarRight } from '../../layouts/sidebar';


class Backsheet extends React.PureComponent {
    render () {
        return(
            <div className="row p-0 m-0">
            <div className="w-mainlanding" >                
                <div className="card-carousel">
                    <div className="row align-content-center card-dashboard">
                        <div className="col">
                            <h4 className="text-gray-3 px-3">Dasboard <span className="f-14 text-orange-primary">
                                &nbsp;&nbsp;Kamis, 14 Januari 2021 | 12:46:02
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div className="px-3">                    
                        <div className="row">
                            {
                                dasboardData.map((val, id) => {
                                    return(
                                    <div key={id} className="col-4">
                                        <div className="card card-body ">
                                            <div className="row align-content-center">
                                                <div className="col-7">
                                                    <h4 className="text-gray-1">{val.value}</h4>
                                                    <span className="text-gray-3">{val.label}</span>
                                                    </div>
                                                <div className="col-5 text-center"><img src={val.icon}  className="card-icon-dasboard" alt={val.label} /></div>
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col-12 pt-2">
                                <div className="card m-0 card-body">
                                    <h4 className="text-gray-1">Backsheet Laporan Harian</h4>
                                    <span className="text-gray-3">Tanggal Transaksi : 14 Januari 2020</span>
                                    <FilterBar/>
                                    <Table/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-sidebar-right text-white">
                <SidebarRight />
            </div>
        </div>
        )
    }
}

const Table = memo(function(props){
    return(
        <>
            <table className="table mt-4">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    </tr>
                    <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    </tr>
                    <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
})

const FilterBar = memo(function(props){
    return(
        <>
            <div className="row mt-4">
                <div className="col-3">
                    <select className="form-control" id="exampleFormControlSelect1">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    </select>
                </div>
                <div className="col-3">
                    <select className="form-control" id="exampleFormControlSelect1">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    </select>
                </div>
                <div className="col-3">
                    <input type="text" className="form-control" id="search1" placeholder="search"/>
                </div>
                <div className="col-3">
                    <button type="button" className="btn btn-success btn-sm">Terapkan</button> 
                    |
                    <button type="button" className="btn btn-danger btn-sm"><IconPrint/>Cetak</button>
                </div>
            </div>
        </>
    )
})

const IconPrint = memo(()=>(
    <>
        <svg width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.125 5.25H4.96875C4.24769 5.25 3.55617 5.53644 3.0463 6.0463C2.53644 6.55617 2.25 7.24769 2.25 7.96875V15.375C2.25 16.0712 2.52656 16.7389 3.01884 17.2312C3.51113 17.7234 4.17881 18 4.875 18H5.25V19.86C5.25 20.3613 5.44912 20.842 5.80357 21.1964C6.15801 21.5509 6.63874 21.75 7.14 21.75H16.86C17.3613 21.75 17.842 21.5509 18.1964 21.1964C18.5509 20.842 18.75 20.3613 18.75 19.86V18H19.125C19.8212 18 20.4889 17.7234 20.9812 17.2312C21.4734 16.7389 21.75 16.0712 21.75 15.375V7.875C21.75 7.17881 21.4734 6.51113 20.9812 6.01884C20.4889 5.52656 19.8212 5.25 19.125 5.25ZM17.25 19.86C17.2496 19.9633 17.2084 20.0623 17.1354 20.1354C17.0623 20.2084 16.9633 20.2496 16.86 20.25H7.14C7.03668 20.2496 6.9377 20.2084 6.86464 20.1354C6.79158 20.0623 6.75037 19.9633 6.75 19.86V12.39C6.75037 12.2867 6.79158 12.1877 6.86464 12.1146C6.9377 12.0416 7.03668 12.0004 7.14 12H16.86C16.9633 12.0004 17.0623 12.0416 17.1354 12.1146C17.2084 12.1877 17.2496 12.2867 17.25 12.39V19.86ZM18.4688 9.74625C18.2393 9.76469 18.0097 9.7123 17.811 9.59615C17.6122 9.48 17.4539 9.30568 17.3573 9.09671C17.2608 8.88774 17.2307 8.65418 17.2711 8.42756C17.3114 8.20093 17.4204 7.99214 17.5831 7.82937C17.7459 7.6666 17.9547 7.55768 18.1813 7.5173C18.4079 7.47693 18.6415 7.50705 18.8505 7.6036C19.0594 7.70014 19.2338 7.85847 19.3499 8.05721C19.4661 8.25596 19.5184 8.48555 19.5 8.715C19.4786 8.98136 19.3631 9.23141 19.1741 9.42036C18.9852 9.60932 18.7351 9.72484 18.4688 9.74625Z" fill="#ffffff"/>
            <path d="M16.1252 2.25H7.87516C7.24422 2.25094 6.63466 2.47873 6.15774 2.8918C5.68082 3.30487 5.36834 3.87566 5.27734 4.5H18.723C18.632 3.87566 18.3195 3.30487 17.8426 2.8918C17.3656 2.47873 16.7561 2.25094 16.1252 2.25Z" fill="#ffffff"/>
        </svg>
    </>
))

export default Backsheet;