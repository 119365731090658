import React, { useState, memo, useEffect } from 'react';
import { AppInterfaces } from '../../../app.data';
import RiwayatTransaksi from './riwayatTransaksi';
import { RegistrasiTabunganDeposito } from '..';

export default AppInterfaces.appLoad(
    memo(({store, ...props}) => {
        const [tab, setTab] = useState(1);

        return (<>
            <div className="row m-0 p-2 rounded card-header" >
                <div className={`col-md-6 pointer p-2 f-16 text-center rounded ${tab === 1 && "bg-base-1 text-white"}`}
                    onClick={()=>setTab(1)}>
                    Riwayat Transakai
                </div>
                <div className={`col-md-6 pointer p-2 f-16 text-center rounded ${tab === 2 && "bg-base-1 text-white"}`}
                    onClick={()=>setTab(2)}>
                    Detail Rekening
                </div>
            </div>

            <div className="my-2 card">
                <div className="p-3 pt-4 row">
                    <div className="col-md-7 offset-1">
                        <div className="box py-3" style={{minHeight: '7.7rem'}}>
                            <h5>Muhammad Jundi Amir <span className="badge badge-success">Aktif</span></h5> 
                            <table>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <td>Produk</td>
                                        <td>: Tabungan Mudharabah</td>
                                        <td>Cabang</td>
                                        <td>Kc Jatinegara Jkt</td>
                                    </tr>
                                    <tr>
                                        <td>No. Rek</td>
                                        <td>: 0100008123</td>
                                        <td>Kode Valuta</td>
                                        <td>: IDR - Rupiah</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-3 ">
                        <div className="box py-3" style={{minHeight: '7.7rem'}}>
                            <h6> Saldo</h6>
                            <h3 className="text-danger">
                                600,000,000.00
                            </h3>
                        </div>
                    </div>
                    {
                        tab === 1 ? 
                            <RiwayatTransaksi /> 
                        : 
                            <div className="col-md-12 pt-4">
                                {/* <DetailTransaksi /> */}
                                <h4>Detail Rekening</h4>
                                <RegistrasiTabunganDeposito type="view" />
                            </div>
                    }                 
                    <div className="col-md-12 border-top pt-3">
                        <button className="btn btn-outline-danger mx-4 px-4 font-weight-bold">Tutup</button>
                    </div>
                </div>
            </div>
        
        </>)
    })
)