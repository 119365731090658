import React, { useState, useEffect, memo } from 'react';
import moment from 'moment';
import logo from './Logo-sayap.png';
import './pdf.css'
import { addCommas } from '../../../module';
import useKeycloak from '../../../hooks/keycloak.module';


const TabgirTemplate = memo(({ dataCetakStatement }) => {
    const {
        keycloak: {
            statusBranch: {
                kode_cabang = "", nama_cabang = ""
            } = {},
            userInfo: {
                name, preferred_username,
            }
        }
    } = useKeycloak()
    return (
        <>
            <table style={{
            }} className='table table-sm table-striped table-borderless'>
                <thead>
                    <tr>
                        <th style={{
                            width: "25px", background: "none", color: "white"
                        }}
                        ></th>
                        <th style={{
                            width: "100px", background: "none", color: "white"
                        }}
                        ></th>
                        <th
                            style={{
                                width: "150px", background: "none", color: "white"
                            }}
                        ></th>
                        <th
                            style={{
                                width: "65px", background: "none", color: "white"
                            }}
                        ></th>
                        <th
                            style={{
                                width: "150px", background: "none", color: "white"
                            }}
                        ></th>
                        <th style={{
                            width: "170px", background: "none", color: "white"
                        }}
                            className='text-right'></th>
                        <th style={{
                            width: "40px", background: "none", color: "white"
                        }}
                        ></th>
                        <th style={{
                            width: "170px", background: "none", color: "white"
                        }}
                            className='text-right'></th>
                        <th style={{
                            width: "70px", background: "none", color: "white"
                        }}
                        ></th>
                        <th style={{
                            width: "70px", background: "none", color: "white"
                        }}
                        ></th>
                    </tr>
                    <tr>
                        <th style={{ background: "none" }} colSpan={5}>
                            <img style={{ width: 150, marginBottom: 20, background: "none" }} src={logo} />
                        </th>
                        <th style={{ background: "none" }} colSpan={5}></th>
                    </tr>

                    <tr>

                        <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">Nomor Rekening <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0"> {dataCetakStatement?.nomor_rekening ?? "-"}</th>

                        {/* <th style={{ background: "none", }} className="py-0 my-0" ></th> */}
                        <th style={{ background: "none", paddingLeft: 65, verticalAlign: "top" }} colSpan={1} className="py-0 my-0">Tanggal Cetak <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={4} className="py-0 my-0">{moment(Date.now()).format("DD/MM/YYYY")}</th>
                    </tr>
                    <tr>
                        <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">Nama Nasabah <span className='float-right' >:</span>  </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0">  {dataCetakStatement?.nama_rekening ?? "-"}</th>

                        {/* <th style={{ background: "none" }} className="py-0 my-0"></th> */}

                        <th style={{ background: "none", paddingLeft: 65, verticalAlign: "top" }} colSpan={1} className="py-0 my-0">Periode <span className='float-right' >:</span></th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={4} className="py-0 my-0">{`${moment(dataCetakStatement?.periodeA).format("DD/MM/YYYY")} - ${moment(dataCetakStatement?.periodeB).format("DD/MM/YYYY")}`}</th>

                    </tr>
                    <tr>

                    <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">NPWP <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0"> {dataCetakStatement?.trx?.npwp ?? "-"}</th>

                        {/* <th style={{ background: "none" }} colSpan={2} className="py-0 my-0">Mata Uang</th>
                        <th style={{ background: "none" }} colSpan={2} className="py-0 my-0">: {dataCetakStatement?.valuta}</th> */}
                        

                        <th style={{ background: "none", paddingLeft: 65, verticalAlign: "top" }} colSpan={1} className="py-0 my-0">Nama Kantor <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={4} className="py-0 my-0"> {`${kode_cabang ?? ""} - ${nama_cabang ?? ""}`}</th>
                    </tr>
                    <tr>
                        

                    <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">Alamat <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0"> {dataCetakStatement?.alamat ?? "-"}</th>
{/*  */}

                        <th style={{ background: "none", paddingLeft: 65, verticalAlign: "top" }} colSpan={1} className="py-0 my-0">User Cetak <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={4} className="py-0 my-0"> {`${preferred_username ?? ""} - ${name ?? ""}`}</th>

                    </tr>
                    <tr>

                        <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">Nama Produk <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0"> {dataCetakStatement?.nama_produk ?? "-"}</th>

                    </tr>
                    <tr>

                        <th style={{ height: 50, background: "none" }} colSpan="10" className="text-center"></th>
                    </tr>
                    <tr>
                        <th style={{

                            background: "none",
                            width: "25px"
                        }}></th>
                        <th colSpan={9} className="text-center" style={{
                            fontSize: "15pt",
                            background: "none"
                        }}>REKENING KORAN</th>
                    </tr>
                    <tr>
                        <th style={{
                            width: "25px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                        }}
                        >No</th>
                        <th style={{
                            width: "100px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                        }}
                        >Tgl. Transaksi</th>
                        <th
                            style={{
                                width: "150px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                            }}
                        >No. Arsip</th>
                        <th
                            style={{
                                width: "65px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                            }}
                        >Kode</th>
                        <th
                            style={{
                                width: "150px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                            }}
                        >Keterangan</th>
                        <th style={{
                            width: "170px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                        }}
                            className='text-right pr-4'>Jumlah Mutasi (Rp)</th>
                        <th style={{
                            width: "35px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                        }}
                        >D/K</th>
                        <th style={{
                            width: "170px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                        }}
                            className='text-right  pr-4'>Saldo (Rp)</th>
                        <th style={{
                            width: "70px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                        }}
                        >UserId</th>
                        <th style={{
                            width: "70px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", background: "#4472c4", color: "white"
                        }}
                        >SPV</th>
                    </tr>
                </thead>
                <tbody
                    className='watermark'
                >
                    <tr>
                        <td colSpan={7}>

                            SALDO AWAL
                        </td>
                        <td className="text-right   pr-4">
                            {addCommas(parseFloat(dataCetakStatement?.trx?.saldoAwal || 0).toFixed(2))}
                        </td>

                        <td colSpan={2}>

                        </td>
                    </tr>
                    {
                        (dataCetakStatement?.trx?.list || []).map(({
                            Keterangan,
                            keterangan,
                            jenis_mutasi,
                            jenis_transaksi,
                            nilai_mutasi,
                            nomor_referensi,
                            saldo_akhir,
                            tanggal_input,
                            tanggal_transaksi,
                            user_input,
                            user_otorisasi,
                        }, i) => (
                            <tr key={i}>
                                <td
                                >{i + 1}</td>
                                <td
                                >{moment(tanggal_transaksi).format("DD/MM/YYYY")}</td>
                                <td
                                    style={{
                                        overflowWrap: "break-word"
                                    }}
                                >{nomor_referensi}</td>
                                <td
                                >{jenis_transaksi}</td>
                                <td
                                    style={{
                                        overflowWrap: "break-word"
                                    }}
                                >{Keterangan ?? keterangan}</td>
                                <td
                                    className='text-right  pr-4'>{addCommas( parseFloat(nilai_mutasi || 0).toFixed(2))}</td>
                                <td
                                >{jenis_mutasi.charAt(0)}</td>
                                <td
                                    className='text-right  pr-4'>{addCommas( parseFloat(saldo_akhir || 0 ).toFixed(2))}</td>
                                <td
                                >{user_input}</td>
                                <td
                                >{user_otorisasi}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {/* <TableList
                        withActions={false}
                        withCard={false}
                        maxHeight= ''
                        minHeight= ""
                        columns={[
                            {
                                name: 'Tanggal',
                                field: 'tanggal_transaksi',
                                maxWidth: '15px',
                                style: {fontSize: "9px", padding: "1px"}
                            },{
                                name: 'Kode Transaksi',
                                field: 'jenis_transaksi',
                                maxWidth: '15px',
                                style: {fontSize: "9px", padding: "1px"}
                            },{
                                name: 'Uraian',
                                field: 'Keterangan',
                                minWidth: '150px',
                                style: {fontSize: "9px", padding: "1px",  wordBreak: "break-word"}
                            },{
                                name: 'Debet',
                                field: 'jenis_mutasi',
                                maxWidth: '25px',
                                style: {fontSize: "9px", padding: "1px", textAlign: 'right'},
                                onRender: (item) => item.jenis_mutasi === "DEBIT" ? addCommas(parseFloat(item.nilai_mutasi).toFixed(2)) : ''
                            },{
                                name: 'Kredit',
                                field: 'jenis_mutasi',
                                maxWidth: '25px',
                                style: {fontSize: "9px", padding: "1px", textAlign: 'right'},
                                onRender: (item) => item.jenis_mutasi === "KREDIT" ? addCommas(parseFloat(item.nilai_mutasi).toFixed(2)) : ''
                            },{
                                name: 'Saldo',
                                field: 'saldo_akhir',
                                maxWidth: '25px',
                                style: {fontSize: "9px", padding: "1px",  textAlign: 'right'},
                                onRender: (item) =>  item.saldo_akhir !== null ? addCommas(parseFloat( item.saldo_akhir).toFixed(2) ) : ''
                            },
                        ]}
                        data={dataCetakStatement?.trx?.list}
                        filter={false}
                        pagination={false}
                    /> */}
            {/* </div>
            </div> */}
            <div className='page-break'>

                <table style={{ width: "100%", masginBottom: "10px" }}>
                    <thead>

                        <tr>
                            <th style={{
                                width: "25px", background: "none", color: "white"
                            }}
                            ></th>
                            <th style={{
                                width: "100px", background: "none", color: "white"
                            }}
                            ></th>
                            <th
                                style={{
                                    width: "150px", background: "none", color: "white"
                                }}
                            ></th>
                            <th
                                style={{
                                    width: "65px", background: "none", color: "white"
                                }}
                            ></th>
                            <th
                                style={{
                                    width: "150px", background: "none", color: "white"
                                }}
                            ></th>
                            <th style={{
                                width: "170px", background: "none", color: "white"
                            }}
                                className='text-right'></th>
                            <th style={{
                                width: "40px", background: "none", color: "white"
                            }}
                            ></th>
                            <th style={{
                                width: "170px", background: "none", color: "white"
                            }}
                                className='text-right'></th>
                            <th style={{
                                width: "70px", background: "none", color: "white"
                            }}
                            ></th>
                            <th style={{
                                width: "70px", background: "none", color: "white"
                            }}
                            ></th>
                        </tr>

                        <tr>
                            <th style={{ background: "none" }} colSpan={5}>
                                <img style={{ width: 150, marginBottom: 20, background: "none" }} src={logo} />
                            </th>
                            <th style={{ background: "none" }} colSpan={5}></th>
                        </tr>
                        <tr>

                        <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">Nomor Rekening <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0"> {dataCetakStatement?.nomor_rekening ?? "-"}</th>

                        {/* <th style={{ background: "none", }} className="py-0 my-0" ></th> */}
                        <th style={{ background: "none", paddingLeft: 65, verticalAlign: "top" }} colSpan={1} className="py-0 my-0">Tanggal Cetak <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={4} className="py-0 my-0">{moment(Date.now()).format("DD/MM/YYYY")}</th>
                    </tr>
                    <tr>
                        <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">Nama Nasabah <span className='float-right' >:</span>  </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0">  {dataCetakStatement?.nama_rekening ?? "-"}</th>

                        {/* <th style={{ background: "none" }} className="py-0 my-0"></th> */}

                        <th style={{ background: "none", paddingLeft: 65, verticalAlign: "top" }} colSpan={1} className="py-0 my-0">Periode <span className='float-right' >:</span></th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={4} className="py-0 my-0">{`${moment(dataCetakStatement?.periodeA).format("DD/MM/YYYY")} - ${moment(dataCetakStatement?.periodeB).format("DD/MM/YYYY")}`}</th>

                    </tr>
                    <tr>

                    <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">NPWP <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0"> {dataCetakStatement?.trx?.npwp ?? "-"}</th>

                        {/* <th style={{ background: "none" }} colSpan={2} className="py-0 my-0">Mata Uang</th>
                        <th style={{ background: "none" }} colSpan={2} className="py-0 my-0">: {dataCetakStatement?.valuta}</th> */}
                        

                        <th style={{ background: "none", paddingLeft: 65, verticalAlign: "top" }} colSpan={1} className="py-0 my-0">Nama Kantor <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={4} className="py-0 my-0"> {`${kode_cabang ?? ""} - ${nama_cabang ?? ""}`}</th>
                    </tr>
                    <tr>
                        

                    <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">Alamat <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0"> {dataCetakStatement?.alamat ?? "-"}</th>
{/*  */}

                        <th style={{ background: "none", paddingLeft: 65, verticalAlign: "top" }} colSpan={1} className="py-0 my-0">User Cetak <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={4} className="py-0 my-0"> {`${preferred_username ?? ""} - ${name ?? ""}`}</th>

                    </tr>
                    <tr>

                        <th style={{ background: "none", verticalAlign: "top" }} colSpan={2} className="py-0 my-0">Nama Produk <span className='float-right' >:</span> </th>
                        <th style={{ background: "none", verticalAlign: "top"  }} colSpan={3} className="py-0 my-0"> {dataCetakStatement?.nama_produk ?? "-"}</th>

                    </tr>
                        <tr>

                            <th style={{ height: 100, background: "none" }} colSpan="10" className="text-center"></th>
                        </tr>
                        <tr>
                            <th style={{

                                background: "none",
                                width: "25px"
                            }}></th>
                            <th colSpan={9} className="text-center" style={{
                                fontSize: "15pt",
                                background: "none"
                            }}>REKENING KORAN</th>
                        </tr>
                    </thead>
                </table>

                <table className="mt-3 f-10" style={{ width: "100%", fontSize: "10pt" }}>
                    <tbody>
                        <tr style={{ fontSize: "10pt" }}>
                            <td width="65%"></td>
                            <td>SALDO AWAL</td>
                            {/* <td>Rp.</td> */}
                            <td className="" colSpan={2}>
                                <span className='float-left'>Rp.</span>
                                <span className='float-right'> {addCommas(parseFloat(dataCetakStatement?.trx?.saldoAwal || 0).toFixed(2))} </span>
                            </td>
                        </tr>
                        <tr style={{ fontSize: "10pt" }}>
                            <td width="65%"></td>
                            <td className="">MUTASI DB</td>
                            {/* <td>Rp.</td> */}
                            <td className="" colSpan={2}>
                                <span className='float-left'>Rp.</span>
                                <span className='float-right'>
                                    {addCommas(parseFloat(dataCetakStatement?.trx?.debit || 0).toFixed(2))}
                                </span>
                            </td>
                        </tr>
                        <tr style={{ fontSize: "10pt" }}>
                            <td width="65%"></td>
                            <td className="">MUTASI KR</td>
                            {/* <td>Rp.</td> */}
                            <td className="" colSpan={2}>
                                <span className='float-left'>Rp.</span>
                                <span className='float-right'>

                                    {addCommas(parseFloat(dataCetakStatement?.trx?.kredit || 0).toFixed(2))}
                                </span>
                            </td>
                        </tr>
                        <tr style={{ fontSize: "10pt" }}>
                            <td width="65%"></td>
                            <td className="">SALDO AKHIR</td>
                            {/* <td>Rp.</td> */}
                            <td className="" colSpan={2}>
                                <span className='float-left'>Rp.</span>
                                <span className='float-right'>

                                    {addCommas(parseFloat(dataCetakStatement?.trx?.saldoAkhir || 0).toFixed(2))}
                                </span>
                            </td>
                        </tr>
                        <tr style={{ fontSize: "10pt" }}>
                            <td width="65%"></td>
                            <td colSpan="3" className="text-center pt-4" style={{ fontSize: "12pt" }}>
                                . . . . . . . . . . , . . . . . . . . . . . . . . . . . . . . . . . . . . .
                            </td>
                        </tr>
                        <tr style={{ fontSize: "10pt" }}>
                            <td width="65%"></td>
                            <td colSpan="3" className="text-center pt-4">
                                PEJABAT BANK YANG BERWENANG
                            </td>
                        </tr>
                        <tr style={{ fontSize: "10pt" }}>
                            <td colSpan="3" height={100} className="text-center"></td>
                        </tr>
                        <tr style={{ fontSize: "10pt" }}>
                            <td width="65%"></td>
                            <td colSpan="3" className="text-center" style={{ fontSize: "12pt" }}>
                                (  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . )
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
})

export default TabgirTemplate;