import React from 'react';
import { Stepper } from '..';

export default function({
    steps = [],
    activeStep =0,
    manualDone = false,
    onStepChange = () => null,
    onStepClick = () => null,
    children
}){

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row mx-auto justify-content-center p-2">
                        <div className="row">
                            <div className="col">
                                <div style={{ width: "fit-content", margin: "auto", padding: "15px 10px" }}>
                                    <Stepper
                                        manualDone={manualDone}
                                        steps={steps}
                                        activeIdx={activeStep}
                                        onChange={(_activeIdx, _prevIdx) => {
                                            onStepChange(_activeIdx, _prevIdx)
                                        }}
                                        onStepClick={(_idx) => {
                                            onStepClick(_idx)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {
                        React.Children.map(children, (child, i) =>{
                            if(child.props.id === "step" && child.props.dataStep === activeStep) return React.cloneElement(child)
                            // console.log(manualActive === null ? keySelected === child.props.id : manualActive === child.props.id)
                            //  return (
                            //     manualActive === null ? 
                            //         keySelected === child.props.id : 
                            //             manualActive === child.props.id) && 
                            //     <Typography className={classes.typography} key={i} index={i}>
                            //         {
                            //             React.cloneElement(child, {
                            //                 active: manualActive === null ? keySelected : manualActive,
                            //             })
                            //         }
                            //     </Typography>
                            }
                        
                            // </Wrapper>
                        )
                    }
                </div>
                <div className="card-footer">
                    {
                        React.Children.map(children, (child, i) => child.props.id === "footer" && React.cloneElement(child))
                    }
                </div>
            </div>

        </>
    )
}