import React, { memo, useRef } from 'react';
import {TableList } from '../../../controls';

class TolakanTarikanKliring extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  render() {
    return(
      <>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mt-2 mb-3">
            <li className="breadcrumb-item color-primary">Kliring</li>
            <li className="breadcrumb-item"><a href="#">Tolakan Tarikan Kliring</a></li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header bg-white">
                <h5 className="card-title">Tolakan Tarikan Kliring</h5>
              </div>
              <div className="card-body">
                <div className="bns-form">
                  <div class="form-check mt-1 mb-4">
                    <input className="form-check-input" type="checkbox" />
                    <label className="form-check-label">Set Data Berdasarkan Transaksi Tarikan Kliring</label>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="text-primary mt-2 mb-4">Info Transaksi Tarikan Kliring</div>
                      <div className="form-row">
                        <div className="form-group col-4">
                          <label htmlFor="">Tanggal Tarikan</label>
                          <select className="form-control">
                            <option value="">15-04-2015</option>
                          </select>
                        </div>
                        <div className="form-group col-8">
                          <label htmlFor="">&nbsp;</label>
                          <button className="btn btn-outline-secondary btn-block">Cari Transaksi Tarikan Kliring</button>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-12">
                          <label htmlFor="">No. Rekening</label>
                          <input type="text" className="form-control" readOnly/>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-4">
                          <label htmlFor="">Jenis Warkat</label>
                          <select className="form-control" readOnly>
                            <option value="">Cek</option>
                          </select>
                        </div>
                        <div className="form-group col-8">
                          <label htmlFor="">No. Warkat</label>
                          <input type="text" className="form-control" readOnly/>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">No. Rekening</label>
                        <div className="form-row">
                          <div className="col-4 pr-0">
                          <input type="text" className="form-control" readOnly/>
                          </div>
                          <div className="col-8 pl-0">
                            <input type="text" className="form-control" placeholder="NAMA NASABAH" readOnly/>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                          <label htmlFor="">Nominal</label>
                          <input type="text" className="form-control" readOnly/>
                        </div>
                    </div>

                    <div className="col-6">
                      <div className="text-primary mt-2 mb-4">Tolakan Tarikan Kliring</div>
                      <div className="form-group">
                        <label htmlFor="">No. Rekening</label>
                        <div className="form-row">
                          <div className="col-4 pr-0">
                          <input type="text" className="form-control"/>
                          </div>
                          <div className="col-8 pl-0">
                            <input type="text" className="form-control" placeholder="NAMA NASABAH" readOnly/>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-4">
                          <label htmlFor="">Jenis Warkat</label>
                          <select className="form-control" readOnly>
                            <option value="">Cek</option>
                          </select>
                        </div>
                        <div className="form-group col-8">
                          <label htmlFor="">No. Warkat</label>
                          <input type="text" className="form-control" readOnly/>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">Valuta Rekening</label>
                            <div className="form-row">
                              <div className="col-3 pr-0">
                                <input type="text" className="form-control" value="IDR" readOnly/>
                              </div>
                              <div className="col-9 pl-0">
                                <input type="text" className="form-control" value="INDONESIAN RUPIAH" readOnly/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">Cabang Rekening</label>
                            <div className="form-row">
                              <div className="col-3 pr-0">
                                <input type="text" className="form-control" value="000" readOnly/>
                              </div>
                              <div className="col-9 pl-0">
                                <input type="text" className="form-control" value="KCP KOTA BANDUNG" readOnly/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Nilai Transaksi</label>
                        <input type="text" className="form-control"/>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-6">
                          <label htmlFor="">Biaya (Rp)</label>
                          <input type="text" className="form-control"/>
                        </div>
                        <div className="form-group col-6">
                          <label htmlFor="">Sumber Biaya</label>
                          <select name="" id="" className="form-control">
                            <option value="">Tunai</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Sandi Tolakan</label>
                        <div className="form-row">
                          <div className="form-group col-4 pr-0">
                            <select name="" id="" className="form-control"></select>
                          </div>
                          <div className="form-group col-8 pl-0">
                            <input type="text" className="form-control" readOnly/>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Kode Bank Kliring</label>
                        <div className="form-row">
                          <div className="form-group col-3 pr-0">
                            <select name="" id="" className="form-control">
                              <option value="">4501231</option>
                            </select>
                          </div>
                          <div className="form-group col-6 pl-0">
                            <input type="text" className="form-control" readOnly/>
                          </div>
                          <div className="form-group col-3">
                            <button className="btn btn-outline-secondary">Cari Bank Kliring</button>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-12">
                          <label htmlFor="">Nama Penarik</label>
                          <input type="text" className="form-control"/>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-12">
                          <label htmlFor="">Keterangan</label>
                          <textarea rows="1" className="form-control">TOLAKAN TARIKAN KLIRING</textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <div className="row">
                  <div className="col-6"></div>
                  <div className="col-6 text-right">
                    <button className="btn btn-outline-secondary">Batal</button>
                    <button className="btn btn-primary ml-3 px-5">Proses</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default TolakanTarikanKliring;