import React, { useEffect, memo, useRef, useState } from 'react';
import { baseUrl } from './../../../app.config';
import { Modal, Dropdown } from 'react-bootstrap';
import './style.css';
import queryString from 'query-string';
import { Breadcrumb } from 'semantic-ui-react';
import useDropdowns from '../../../hooks/useDropdowns'

const RegistrasiWicPerorangan = () => {
    const [step, setStep] = useState({
        step0: {
            completed : false
        },
        step1: {
            completed : false
        },
        step_active: 0
    });

    const dropdowns = useDropdowns();

    const [errors, setErrors] = useState({
        pi_jenis_identitas : true
    })

    const [form, setForm] = useState({
        user_id: '',
        state: '',
        pi_jenis_identitas: '',
        pi_tgl_terbit_identitas: '',
        pi_tgl_berakhir_identitas: '',
        pi_nomor_identitas: '',
        pi_nomor_npwp: '',
        pi_nama_sesuai_identitas: '',
        pi_jenis_penduduk: '',
        ni_nama_tanpa_singkatan: '',
        ni_tanggal_lahir: '',
        ni_jenis_kelamin: '',
        ni_alamat_email: '',
        ni_tlp_nomor: '',
        ni_no_hp: '',
        cd_agama: '',
        cd_status_perkawinan: '',
        cd_kewarganegaraan: '',
        cd_negara_asal: '',
        cd_gelar_depan: '',
        cd_gelar_belakang: '',
        cd_nama_singkat: '',
        cd_tempat_lahir: '',
        cd_nama_ibu_kandung: '',
        ka_alamat: '',
        ka_provinsi: '',
        ka_kota_kabupaten: '',
        ka_kecamatan: '',
        ka_kelurahan: '',
        ka_rt: '',
        ka_rw: '',
        ka_kode_pos: '',
        ma_alamat: '',
        ma_provinsi: '',
        ma_kota_kabupaten: '',
        ma_kecamatan: '',
        ma_kelurahan: '',
        ma_rt: '',
        ma_rw: '',
        ma_kode_pos: '',
        oi_pekerjaan: '',
        oi_jabatan_pekerjaan: '',
        oi_nama_perusahaan: '',
        oi_badan_hukum: '',
        oi_badan_usaha: '',
        ca_tlp_nomor: '',
        ca_fax_nomor: '',
        ca_alamat: '',
        ca_provinsi: '',
        ca_kota_kab: '',
        ca_kecamatan: '',
        ca_kelurahan: '',
        ca_rt: '',
        ca_rw: '',
        ca_kode_pos: '',
    })

    const [modal, setModal] = useState({
        konfRegistrasiWic: {
            isShow: false
        }
    })

    const handleChangeForm = (e) => {
        const { value, name } = e.target;
        // console.log(name, value)
        setForm({
            ...form,
            [name]: value
        });
    };

    const posWicPerorangan = async () => {
        let body = form
        // console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-user-id' : 'IBOPR',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };
        fetch(baseUrl + '/wic', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data)
            });
    };
    
    return (
        <div className="">
            <Modal
                show={modal.konfRegistrasiWic.isShow}
                onHide={() => {
                    setModal({
                        ...modal,
                        konfRegistrasiWic: {
                            isShow: false
                        }
                    })
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Konfirmasi</Modal.Title>
                </Modal.Header>
                <Modal.Body>Lakukan Proses Registrasi WIC Perorangan ?</Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => {
                            setModal({
                                ...modal,
                                konfRegistrasiWic: {
                                    isShow: false
                                }
                            })
                        }}
                    >
                        Tidak
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            posWicPerorangan();
                            setModal({
                                ...modal,
                                konfRegistrasiWic: {
                                    isShow: false
                                }
                            })
                        }}
                    >
                        Ya
                    </button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb>
                <Breadcrumb.Section className="text-primary-bds" link>
                    WIC
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-primary-bds" link>
                    Registrasi WIC
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Perorangan</Breadcrumb.Section>
            </Breadcrumb>
            <br />
            <br />
            <div 
                className="card"
            >
                <div className="card-header">
                    <div className="row mx-auto justify-content-center p-2">
                        <div
                            className={
                                step.step0.completed
                                    ? 'col-2 pointer step-completed'
                                    : step.step_active === 0
                                    ? 'col-2 pointer step-active'
                                    : 'col-2 pointer'
                            }
                            onClick={() => setStep((prevState) => ({ ...prevState, step_active: 0 }))}
                        >
                            <div className="row">
                                <div className="col p0"></div>
                                <div className="col-auto">
                                    <span className="round-tab">
                                        {step.step0.completed ??
                                            <i className="fa fa-check"></i>
                                        }
                                    </span>
                                </div>
                                <div className="col p0">
                                    <hr className="line-status" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center wizard-text">
                                    Data Pribadi
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                step.step0.completed
                                    ? 'col-2 pointer step-completed'
                                    : step.step_active === 1
                                    ? 'col-2 pointer step-active'
                                    : 'col-2 pointer'
                            }
                            onClick={() => setStep((prevState) => ({ ...prevState, step_active: 1 }))}
                        >
                            <div className="row">
                                <div className="col p0">
                                    <hr className="line-status" />
                                </div>
                                <div className="col-auto">
                                    <span className="round-tab">
                                        {step.step1.completed ??
                                            <i className="fa fa-check"></i>
                                        }
                                    </span>
                                </div>
                                <div className="col p0">
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center wizard-text">
                                    Data Pekerjaan
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {step.step_active == 0 ? (
                        <div>
                            <div className="box">
                                <div className="row">
                                    <div className="col-4 align-self-center bns-form">
                                        <div className="form-group">
                                            <label>Nomor CIF</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                readOnly
                                                placeholder="Otomatis terisi dari sistem"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bns-form">
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Identitas</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>
                                            Jenis Identitas{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <select
                                            className={`form-control ${errors.pi_jenis_identitas ?? 'is-invalid'}`}
                                            name="pi_jenis_identitas"
                                            onChange={handleChangeForm}
                                            value={form.pi_jenis_identitas}
                                        >
                                            <option
                                                value=""
                                            >
                                                Pilih jenis identitas
                                            </option>
                                            {
                                                dropdowns?.eJenisIdentitas?.map(
                                                    (data, index) => {
                                                        return (
                                                            <option
                                                                value={
                                                                    data.reference_desc
                                                                }
                                                            >
                                                                {data.reference_desc}
                                                            </option>
                                                        )
                                                    }
                                                )
                                            }
                                        </select>
                                        <div className="invalid-feedback">
                                            Bidang ini wajib diisi.
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Tanggal Identitas
                                        </label>
                                        <div className="row">
                                            <div className="col-6">
                                                <input
                                                    placeholder="Tgl Terbit"
                                                    className="form-control"
                                                    type="date"
                                                    name="pi_tgl_terbit_identitas"
                                                    onChange={handleChangeForm}
                                                    value={form.pi_tgl_terbit_identitas}
                                                />
                                            </div>
                                            <div className="col-1">
                                                <span>/</span>
                                            </div>
                                            <div className="col-5">
                                                <input
                                                    placeholder="Tgl Kadaluarsa"
                                                    className="form-control"
                                                    type="date"
                                                    name="pi_tgl_berakhir_identitas"
                                                    onChange={handleChangeForm}
                                                    value={form.pi_tgl_berakhir_identitas}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Nomor Identitas{' '}
                                            <span className="badge badge-secondary">Wajib</span>
                                        </label>
                                        <input
                                            placeholder="Masukan Nomor Identitas"
                                            className="form-control"
                                            name="pi_nomor_identitas"
                                            onChange={handleChangeForm}
                                            value={form.pi_nomor_identitas}
                                        />
                                        <div className="invalid-feedback">
                                            Bidang ini wajib diisi.
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Nomor NPWP</label>
                                        <input
                                            placeholder="Masukan Nomor NPWP"
                                            className="form-control"
                                            name="pi_nomor_npwp"
                                            onChange={handleChangeForm}
                                            value={form.pi_nomor_npwp}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Data Diri</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label>
                                                    Nama (Sesuai Identitas){' '}
                                                    <span className="badge badge-secondary">Wajib</span>
                                                </label>
                                                <input
                                                    placeholder="Masukan Nama"
                                                    className="form-control"
                                                    name="pi_nama_sesuai_identitas"
                                                    onChange={handleChangeForm}
                                                    value={form.pi_nama_sesuai_identitas}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>
                                                    Nama Tanpa Singkatan
                                                </label>
                                                <input
                                                    placeholder="Masukan Nama"
                                                    className="form-control"
                                                    name="ni_nama_tanpa_singkatan"
                                                    onChange={handleChangeForm}
                                                    value={form.ni_nama_tanpa_singkatan}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Gelar Depan</label>
                                                        <input
                                                            placeholder="Contoh : Drs."
                                                            className="form-control"
                                                            name="cd_gelar_depan"
                                                            onChange={handleChangeForm}
                                                            value={form.cd_gelar_depan}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Gelar Belakang</label>
                                                        <input
                                                            placeholder="Contoh : S.H."
                                                            className="form-control"
                                                            name="cd_gelar_belakang"
                                                            onChange={handleChangeForm}
                                                            value={form.cd_gelar_belakang}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>
                                                    Nama Singkat
                                                </label>
                                                <input
                                                    placeholder="Masukan Nama"
                                                    className="form-control"
                                                    name="cd_nama_singkat"
                                                    onChange={handleChangeForm}
                                                    value={form.cd_nama_singkat}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>
                                                    Tempat / Tanggal Lahir{' '}
                                                    <span className="badge badge-secondary">Wajib</span>
                                                </label>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <input
                                                            placeholder="Masukan Tempat Lahir"
                                                            className="form-control"
                                                            name="cd_tempat_lahir"
                                                            onChange={handleChangeForm}
                                                            value={form.cd_tempat_lahir}
                                                        />
                                                    </div>
                                                    <div className="col-1">
                                                        <span>/</span>
                                                    </div>
                                                    <div className="col-5">
                                                        <input
                                                            placeholder="Tgl Lahir"
                                                            className="form-control"
                                                            type="date"
                                                            name="ni_tanggal_lahir"
                                                            onChange={handleChangeForm}
                                                            value={form.ni_tanggal_lahir}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>
                                                    Nama Ibu Kandung{' '}
                                                    <span className="badge badge-secondary">Wajib</span>
                                                </label>
                                                <input
                                                    placeholder="Masukan Nama Ibu Kandung"
                                                    className="form-control"
                                                    name="cd_nama_ibu_kandung"
                                                    onChange={handleChangeForm}
                                                    value={form.cd_nama_ibu_kandung}
                                                />
                                                <div className="invalid-feedback">
                                                    Bidang ini wajib diisi.
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>
                                                    Jenis Kelamin{' '}
                                                    <span className="badge badge-secondary">Wajib</span>
                                                </label>
                                                <br />
                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="ni_jenis_kelamin"
                                                            value="P"
                                                            onChange={handleChangeForm}
                                                            // value={form.ni_jenis_kelamin}
                                                            checked={
                                                                form.ni_jenis_kelamin === 'P'
                                                                ? true
                                                                : false
                                                            }
                                                        />
                                                        Laki-laki
                                                    </label>
                                                </div>
                                                <div className="form-check-inline ml-3">
                                                    <label className="form-check-label">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="ni_jenis_kelamin"
                                                            value="W"
                                                            onChange={handleChangeForm}
                                                            checked={
                                                                form.ni_jenis_kelamin === 'W'
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        Perempuan
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label>
                                                    Alamat Email
                                                </label>
                                                <input
                                                    placeholder="Masukan Masukkan alamat email"
                                                    className="form-control"
                                                    name="ni_alamat_email"
                                                    onChange={handleChangeForm}
                                                    value={form.ni_alamat_email}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Agama</label>
                                                <select
                                                    className="form-control"
                                                    name="cd_agama"
                                                    onChange={handleChangeForm}
                                                    value={form.cd_agama}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                        Pilih Agama
                                                    </option>
                                                    {
                                                        dropdowns?.eAgama?.map(
                                                            (data, index) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            data.reference_desc
                                                                        }
                                                                    >
                                                                        {data.reference_desc}
                                                                    </option>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Status Perkawinan </label>
                                                <select
                                                    className="form-control"
                                                    name="cd_status_perkawinan"
                                                    onChange={handleChangeForm}
                                                    value={form.cd_status_perkawinan}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                        Pilih Status Perkawinan
                                                    </option>
                                                    {
                                                        dropdowns?.eStatusPerkawinan?.map(
                                                            (data, index) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            data.reference_desc
                                                                        }
                                                                    >
                                                                        {data.reference_desc}
                                                                    </option>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Kewarganegaraan </label>
                                                <select
                                                    className="form-control"
                                                    name="cd_kewarganegaraan"
                                                    onChange={handleChangeForm}
                                                    value={form.cd_kewarganegaraan}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                        Pilih Kewarganegaraan
                                                    </option>
                                                    {
                                                        dropdowns?.eKewarganegaraan?.map(
                                                            (data, index) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            data.reference_desc
                                                                        }
                                                                    >
                                                                        {data.reference_desc}
                                                                    </option>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Negara Asal </label>
                                                <select
                                                    className="form-control"
                                                    name="cd_negara_asal"
                                                    onChange={handleChangeForm}
                                                    value={form.cd_negara_asal}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                        Pilih negara asal
                                                    </option>
                                                    {
                                                        dropdowns?.R_KODE_NEGARA?.map(
                                                            (data, index) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            data.reference_desc
                                                                        }
                                                                    >
                                                                        {data.reference_desc}
                                                                    </option>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Jenis Penduduk</label>
                                                <select
                                                    className="form-control"
                                                    name="pi_jenis_penduduk"
                                                    onChange={handleChangeForm}
                                                    value={form.pi_jenis_penduduk}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                        Pilih Jenis Penduduk
                                                    </option>
                                                    {
                                                        dropdowns?.eJenisPenduduk?.map(
                                                            (data, index) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            data.reference_desc
                                                                        }
                                                                    >
                                                                        {data.reference_desc}
                                                                    </option>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Alamat Identitas</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label>Alamat</label>
                                                <textarea
                                                    rows="3"
                                                    className="form-control"
                                                    placeholder="Masukan Alamat"
                                                    name="ka_alamat"
                                                    onChange={handleChangeForm}
                                                    value={form.ka_alamat}
                                                ></textarea>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>
                                                            Provinsi{' '}
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan provinsi"
                                                            className="form-control"
                                                            name="ka_provinsi"
                                                            onChange={handleChangeForm}
                                                            value={form.ka_provinsi}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label>
                                                            Kota / Kab
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan nama kota / kabupaten"
                                                            className="form-control"
                                                            name="ka_kota_kabupaten"
                                                            onChange={handleChangeForm}
                                                            value={form.ka_kota_kabupaten}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>
                                                            Kecamatan
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan nama kecamatan"
                                                            className="form-control"
                                                            name="ka_kecamatan"
                                                            onChange={handleChangeForm}
                                                            value={form.ka_kecamatan}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label>
                                                            Kelurahan
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan nama kelurahan"
                                                            className="form-control"
                                                            name="ka_kelurahan"
                                                            onChange={handleChangeForm}
                                                            value={form.ka_kelurahan}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>
                                                        RT / RW
                                                    </label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <input
                                                                placeholder="RT"
                                                                className="form-control"
                                                                name="ka_rt"
                                                                onChange={handleChangeForm}
                                                                value={form.ka_rt}
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <span>/</span>
                                                        </div>
                                                        <div className="col-5">
                                                            <input
                                                                placeholder="RW"
                                                                className="form-control"
                                                                name="ka_rw"
                                                                onChange={handleChangeForm}
                                                                value={form.ka_rw}
                                                            />
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label>Kode Pos</label>
                                                <input
                                                    placeholder="000"
                                                    className="form-control"
                                                    name="ka_kode_pos"
                                                    onChange={handleChangeForm}
                                                    value={form.ka_kode_pos}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>
                                                    Telepon Rumah 1
                                                </label>
                                                <div className="row">
                                                    {/* <div className="col-3">
                                                        <input
                                                            placeholder="000"
                                                            className={`form-control ${this.state.errors.firstName ? 'is-invalid' : ''
                                                                }`}
                                                            name="ni_tlp_nomor"
                                                            value={this.state.firstName}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                            readOnly={this.state.mode === 'info' ? true : false}
                                                        />
                                                    </div>
                                                    <div className="col-1">
                                                        <span>-</span>
                                                    </div> */}
                                                    <div className="col-12">
                                                        <input
                                                            placeholder="000"
                                                            className="form-control"
                                                            name="ni_tlp_nomor"
                                                            onChange={handleChangeForm}
                                                            value={form.ni_tlp_nomor}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>No. Handphone</label>
                                                <input
                                                    placeholder="000"
                                                    className="form-control"
                                                    name="ni_no_hp"
                                                    onChange={handleChangeForm}
                                                    value={form.ni_no_hp}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Alamat Domisili</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label>Alamat</label>
                                                <textarea
                                                    rows="3"
                                                    className="form-control"
                                                    placeholder="Masukan Alamat"
                                                    name="ma_alamat"
                                                    onChange={handleChangeForm}
                                                    value={form.ma_alamat}
                                                ></textarea>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>
                                                            Provinsi
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan provinsi"
                                                            className="form-control"
                                                            name="ma_provinsi"
                                                            onChange={handleChangeForm}
                                                            value={form.ma_provinsi}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label>
                                                            Kota / Kab
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan nama kota / kabupaten"
                                                            className="form-control"
                                                            name="ma_kota_kabupaten"
                                                            onChange={handleChangeForm}
                                                            value={form.ma_kota_kabupaten}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>
                                                            Kecamatan
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan kecamatan"
                                                            className="form-control"
                                                            name="ma_kecamatan"
                                                            onChange={handleChangeForm}
                                                            value={form.ma_kecamatan}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label>
                                                            Kelurahan
                                                        </label>
                                                        <input
                                                            placeholder="Masukkan kelurahan"
                                                            className="form-control"
                                                            name="ma_kelurahan"
                                                            onChange={handleChangeForm}
                                                            value={form.ma_kelurahan}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                RT / RW
                                            </label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        placeholder="RT"
                                                        className="form-control"
                                                        name="ma_rt"
                                                        onChange={handleChangeForm}
                                                        value={form.ma_rt}
                                                    />
                                                </div>
                                                <div className="col-1">
                                                    <span>/</span>
                                                </div>
                                                <div className="col-5">
                                                    <input
                                                        placeholder="RW"
                                                        className="form-control"
                                                        name="ma_rw"
                                                        onChange={handleChangeForm}
                                                        value={form.ma_rw}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label>Kode Pos</label>
                                                <input
                                                    placeholder="000"
                                                    className="form-control"
                                                    name="ma_kode_pos"
                                                    onChange={handleChangeForm}
                                                    value={form.ma_kode_pos}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {step.step_active == 1 ? (
                        <div className="bns-form">
                            <div className="row mt-4 mb-2">
                                <div className="col-auto text-primary">Data Pekerjaan</div>
                                <div className="col">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Pekerjaan</label>
                                        <select
                                            className="form-control"
                                            name="oi_pekerjaan"
                                            onChange={handleChangeForm}
                                            value={form.oi_pekerjaan}
                                        >
                                            <option
                                                value=""
                                            >
                                                Pilih Pekerjaan
                                            </option>
                                            {
                                                dropdowns?.R_PEKERJAAN?.map(
                                                    (data, index) => {
                                                        return (
                                                            <option
                                                                value={
                                                                    data.reference_desc
                                                                }
                                                            >
                                                                {data.reference_desc}
                                                            </option>
                                                        )
                                                    }
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Jabatan Pekerjaan</label>
                                        <input
                                            placeholder="Masukkan Jabatan Pekerjaan"
                                            className="form-control"
                                            name="oi_jabatan_pekerjaan"
                                            onChange={handleChangeForm}
                                            value={form.oi_jabatan_pekerjaan}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Nama Perusahaan</label>
                                        <input
                                            placeholder="Masukkan nama perusahaan"
                                            className="form-control"
                                            name="oi_nama_perusahaan"
                                            onChange={handleChangeForm}
                                            value={form.oi_nama_perusahaan}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Badan Hukum</label>
                                        <select
                                            className="form-control"
                                            name="oi_badan_hukum"
                                            onChange={handleChangeForm}
                                            value={form.oi_badan_hukum}
                                        >
                                            <option
                                                value=""
                                            >
                                                Pilih Badan Hukum
                                            </option>
                                            {
                                                dropdowns?.eBadanHukum?.map(
                                                    (data, index) => {
                                                        return (
                                                            <option
                                                                value={
                                                                    data.reference_desc
                                                                }
                                                            >
                                                                {data.reference_desc}
                                                            </option>
                                                        )
                                                    }
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Badan Usaha</label>
                                        <select
                                            className="form-control"
                                            name="oi_badan_usaha"
                                            onChange={handleChangeForm}
                                            value={form.oi_badan_usaha}
                                        >
                                        <option
                                            value=""
                                        >
                                            Pilih Badan Usaha
                                        </option>
                                            {
                                                dropdowns?.R_BIDANG_USAHA?.map(
                                                    (data, index) => {
                                                        return (
                                                            <option
                                                                value={
                                                                    data.reference_desc
                                                                }
                                                            >
                                                                {data.reference_desc}
                                                            </option>
                                                        )
                                                    }
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Telepon</label>
                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    placeholder="000"
                                                    className="form-control"
                                                    name="ca_tlp_nomor"
                                                    onChange={handleChangeForm}
                                                    value={form.ca_tlp_nomor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Fax</label>
                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    placeholder="000"
                                                    className="form-control"
                                                    name="ca_fax_nomor"
                                                    onChange={handleChangeForm}
                                                    value={form.ca_fax_nomor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Alamat</label>
                                        <textarea
                                            rows="3"
                                            className="form-control"
                                            placeholder="Masukan Alamat"
                                            name="ca_alamat"
                                            onChange={handleChangeForm}
                                            value={form.ca_alamat}
                                        ></textarea>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label>Provinsi</label>
                                            <div className="row">
                                                <div className="col-12">
                                                    <input
                                                        placeholder="Masukkan Provinsi"
                                                        className="form-control"
                                                        name="ca_provinsi"
                                                        onChange={handleChangeForm}
                                                        value={form.ca_provinsi}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-6">
                                            <label>Kota/Kab </label>
                                            <div className="row">
                                                <div className="col-12">
                                                    <input
                                                        placeholder="Masukkan nama Kota / Kabupaten"
                                                        className="form-control"
                                                        name="ca_kota_kab"
                                                        onChange={handleChangeForm}
                                                        value={form.ca_kota_kab}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label>Kecamatan</label>
                                            <div className="row">
                                                <div className="col-12">
                                                    <input
                                                        placeholder="Masukkan nama Kecamatan"
                                                        className="form-control"
                                                        name="ca_kecamatan"
                                                        onChange={handleChangeForm}
                                                        value={form.ca_kecamatan}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-6">
                                            <label>Kelurahan</label>
                                            <div className="row">
                                                <div className="col-12">
                                                    <input
                                                        placeholder="Masukkan Kelurahan"
                                                        className="form-control"
                                                        name="ca_kelurahan"
                                                        onChange={handleChangeForm}
                                                        value={form.ca_kelurahan}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>RT / RW</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <input
                                                    placeholder="RT"
                                                    className="form-control"
                                                    name="ca_rt"
                                                    onChange={handleChangeForm}
                                                    value={form.ca_rt}
                                                />
                                            </div>
                                            <div className="col-1"> - </div>
                                            <div className="col-5">
                                                <input
                                                    placeholder="RW"
                                                    className="form-control"
                                                    name="ca_rw"
                                                    onChange={handleChangeForm}
                                                    value={form.ca_rw}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Kode Pos</label>
                                        <input
                                            placeholder="000"
                                            className="form-control"
                                            name="ca_kode_pos"
                                            onChange={handleChangeForm}
                                            value={form.ca_kode_pos}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-2">
                            <button
                                className="btn btn-outline-danger"
                                onClick={() => setStep((prevState) => ({ ...prevState, step_active: 0 }))}
                            >
                                Reset
                            </button>
                        </div>
                        <div className="col-10 text-right">
                            <button
                                disabled={step.step_active == 0 ? true : false}
                                className={
                                    'btn mr-1 ' +
                                    (step.step_active == 0
                                        ? 'btn-secondary'
                                        : 'btn-orange-primary')
                                }
                                onClick={() => setStep((prevState) => ({ ...prevState, step_active: (step.step_active - 1) }))}
                            >
                                Sebelumnya
                            </button>
                            <button
                                disabled={step.step_active == 1 ? true : false}
                                className={
                                    'btn mr-1 ' +
                                    (step.step_active === 1
                                        ? 'btn-secondary'
                                        : 'btn-orange-primary')
                                }
                                onClick={() => setStep((prevState) => ({ ...prevState, step_active: (step.step_active + 1) }))}
                            >
                                Selanjutnya
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setModal({
                                        ...modal,
                                        konfRegistrasiWic: {
                                            isShow: true
                                        }
                                    })
                                }}
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrasiWicPerorangan;
