import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useRekening from "../useRekening";
import { createForm } from "../../../../../../module/createFormList";
import { TableList } from "../../../../../controls";
import { numberToCurrency } from "../../../../../../utils/formatNumber";

function AmbilWarkat() {
  const { rekState, rekAction } = useRekening();
  const [jenisWarkat, setJenisWarkat] = useState("C");

  useEffect(() => {
    // rekAction.setFormWarkat((prevState) => ({ ...prevState, jenis_warkat: "C" }));
    if (rekState.modalWarkatList === true) {
      if (rekState.warkatDataModalMode) {
        rekAction.getWarkatList(jenisWarkat, 4);
      } else {
        rekAction.getWarkatList(jenisWarkat, 5);
      }
    }
  }, [rekState.modalWarkatList]);

  return (
    <>
      <Modal size={"lg"} show={rekState.modalWarkatList} onHide={rekAction.closeWarkatList}>
        <Modal.Header closeButton>
          <Modal.Title>Cari Data Warkat</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2rem" }}>
          <div className="row mt-2">
            <div className="col-6">
              {createForm({
                type: "select",
                label: "Jenis Warkat",
                value: jenisWarkat,
                disabled: rekState.otorMode,
                options: rekState.eJenisWarkat,
                key: "reference_code",
                display: "reference_desc",
                onChange: (e) => setJenisWarkat(e),
              })}
            </div>
            <div className="col d-flex align-items-center">
              <button
                className="btn btn-sm btn-success mt-3"
                onClick={() => {
                  if (rekState.warkatDataModalMode) {
                    rekAction.getWarkatList(jenisWarkat, 4);
                  } else {
                    rekAction.getWarkatList(jenisWarkat, 5);
                  }
                }}>
                Filter
              </button>
            </div>
          </div>
          <TableList
            withCard={false}
            isLoading={rekState.loadingWarkat || false}
            data={rekState.listDataWarkat.list || []}
            columns={[
              {
                name: "Kode Barang",
                field: "kd_barang",
                // onRender: (item) => {
                //   return item.nominal_hold?.toLocaleString() + " ";
                // },
              },
              {
                name: "No. Seri Awal",
                field: "no_barang",
                // onRender: (item) => {
                //   return moment(item.tanggal_hold, "YYYY/MM/DD").format("DD-MM-YYYY");
                // },
              },
              {
                name: "Jumlah Lembar",
                field: "jns_barang",
                // onRender: (item) => {
                //   return moment(item.tanggal_hold, "YYYY/MM/DD").format("DD-MM-YYYY");
                // },
              },
              {
                name: "Biaya",
                field: "harga_jual",
                onRender: (item) => {
                  return numberToCurrency(item.harga_jual);
                },
              },
              {
                name: "",
                field: "",
                onRender: (e) => {
                  return (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        let tmp,
                          biaya = [];
                        tmp = rekState.formWarkat.listForm;
                        biaya = rekState.formWarkat.listBiaya;

                        if (
                          tmp.find((x) => x.id_barang === e.id_barang) === undefined ? true : false
                        ) {
                          if (rekState.warkatBukuModalMode) {
                            tmp = [
                              {
                                id_barang: e.id_barang,
                                kode_warkat: e.kd_barang,
                                nomor_seri_awal: e.no_barang,
                                nomor_seri_akhir:
                                  parseInt(e.no_barang) + (parseInt(e.jns_barang) - 1),
                                jumlah_lembar: e.jns_barang,
                                jenis_warkat: jenisWarkat,
                                jenis_materai: "G",
                                fee:e.fee,
                                harga_jual: e.harga_jual,
                                materai_tempel: e.materai_tempel,
                                materai_giling: e.materai_giling,
                                biaya: parseFloat(e.harga_jual || 0) + parseFloat(e.materai_giling || 0) + parseFloat(e.fee || 0),
                              },
                            ];
                            rekAction.setFormWarkat((prevState) => ({
                              ...prevState,
                              total_biaya: 0,
                            }));
                          } else if (rekState.warkatDataModalMode) {
                            tmp.push({
                              id_barang: e.id_barang,
                              kode_warkat: e.kd_barang,
                              nomor_seri_awal: e.no_barang,
                              nomor_seri_akhir:
                                parseInt(e.no_barang) + (parseInt(e.jns_barang) - 1),
                              jumlah_lembar: e.jns_barang,
                              jenis_warkat: jenisWarkat,
                              jenis_materai: "G",
                              fee:parseFloat(e.fee),
                              harga_jual: parseFloat(e.harga_jual),
                              materai_tempel: parseFloat(e.materai_tempel),
                              materai_giling: parseFloat(e.materai_giling),
                              biaya: parseFloat(e.harga_jual || 0) + parseFloat(e.materai_tempel || 0) + parseFloat(e.fee || 0),
                              biaya_materai: parseFloat(e.materai_giling),
                            });
                          }
                        }

                        rekAction.updateHoldWarkat(e.id_barang, "1");
                        rekAction.setFormWarkat((prev) => ({
                          ...prev,
                          listForm: tmp,
                          total_jual: tmp.reduce((a, b) => a + b.harga_jual, 0),
                          total_materai: tmp.reduce((a, b) => a + b.biaya_materai, 0),
                          total_fee: tmp.reduce((a, b) => a + b.fee, 0),
                          total_biaya: tmp.reduce((a, b) => a + b.biaya, 0),
                        }));
                        rekAction.closeWarkatList();
                      }}>
                      Pilih
                    </button>
                  );
                },
              },
            ]}
            pagination={false}
            filter={false}
            withAction={false}
            // actionItems={[
            //   {
            //     name: "Pilih Warkat",
            //     onClick: (e) => {
            //       rekAction.setFormWarkat((prev) => ({
            //         ...prev,
            //         kode_warkat: e.kd_barang,
            //         nomor_seri_awal: e.no_barang,
            //         nomor_seri_akhir: e.no_barang + e.jns_barang,
            //         jumlah_lembar: e.jns_barang,
            //         id_barang: e.id_barang,
            //         listBiaya: {
            //           fee: e.fee,
            //           materai_lembar: e.materai_lembar,
            //           materai_giling: e.materai_giling,
            //         },
            //       }));
            //       rekAction.closeWarkatList();
            //     },
            //   },
            // ]}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <div className="d-flex justify-content-end align-items-center">{renderCloseButton()}</div> */}
        </Modal.Footer>
      </Modal>
      {/* end modal utama */}
    </>
  );
}

export default AmbilWarkat;
