import React, { memo, useRef} from 'react';
import pospay from "../../../icon/pos/pospay.png";
import pospayActive from "../../../icon/pos/pospay-active.png";
import giropos from "../../../icon/pos/giropos.png";
import wesel from "../../../icon/pos/wesel.png";
import weselActive from "../../../img/weselActive.png";
import ipos from "../../../img/ipos.png";
import iposActive from "../../../img/iposActive.png";
import home from "../../../img/home.png";
import homeActive from "../../../img/homeActive.png";

import { Link, useLocation } from 'react-router-dom';
import  { AppAction, AppInterfaces } from '../../../app.data';

const siderbar = [
    {title: "Administrasi Loket", link: "/", icon: home, iconActive: homeActive},
    {title: "Giro Pos", link: "", icon: giropos, iconActive: giropos},
    {title: "Pospay", link: "/posPay", icon: pospay, iconActive: pospayActive},
    {title: "Wesel", link: "/wesel", icon: wesel, iconActive: weselActive},
    {title: "IPOS", link: "/iPos", icon: ipos, iconActive: iposActive},
]

const SidebarLeft_base = memo(({store, ...props}) => {
    const location = useLocation();
    const appAction = useRef(null);
    
    return(
        <div className="card-carousel bg-fff" style={{ minHeight: "94vh"}}>
            <AppAction ref={appAction} />
            <table className="px-0 w-100">
                <tbody>
                    {siderbar.map((data, id) => (<>
                        <tr key={id} className={`text-center ${id === 0 && "card-dashboard b-border-gray"}`}>
                            <Link to={data.link} onClick={() => {appAction.current.disp({type: "setHeader", header: data}); appAction.current.disp({type: "setPelanggan", pelanggan: {}});}}>
                                <td className={`${(id === 0) ? "pt-3 pb-2" : "py-2"}`}><img className="pointer" src={ location.pathname.includes(data.link)  ? data.iconActive : data.icon} alt={data.title} /></td>
                            </Link> 
                        </tr>
                    </>))}
                </tbody>
            </table>
        </div>
    )
})

const SidebarLeft = AppInterfaces.appLoad(SidebarLeft_base); 
export default SidebarLeft;