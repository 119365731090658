import React , { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DateInput, FormRegistration, TableList } from '../../../../../controls';
import useRekening from '../useRekening';
import ModalCetakTransaksiPrint from './printCetakTransaksi';
import useDataBuku from '../../data-buku-tabungan/useDataBuku';
const ModalCetakTransaksiPassbook = () => {
    const { nfState, nfAction } = useDataBuku();
    const { formData = {}, formEditable = false, dataCetakTransaksi = {} } = nfState;
    const [ filterState, setFilterState ] = useState({
        from: "", to: ""
    });

    useEffect(function(){
        setFilterState({
            from: "", to: ""
        })
    },[nfState.dataCetakTransaksi?.is_cetak_ulang])
    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={nfState.modalCetakTransaksi} onHide={nfAction.closeCetakTransaksi} >
                <Modal.Header closeButton>
                    <Modal.Title>Cetak Transaksi Tabungan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormRegistration
                        itemsForm={[
                            {
                                name: "Info Rekening",
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Nama',
                                        type: 'number',
                                        placeholder: 'Masukan nama',
                                        required: false,
                                        readOnly: true,
                                        value: nfState.dataCetakTransaksi?.nomor_rekening,
                                        onChange: (value) => nfAction.changeForm_cetak(value, "nomor_rekening"),
                                        // disabled: !nfState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        label: 'ID Cetak Awal',
                                        type: 'number',
                                        // placeholder: 'Masukan nama',
                                        required: false,
                                        readOnly: nfState.dataCetakTransaksi?.is_cetak_ulang !== "T",
                                        value: nfState.dataCetakTransaksi?.id_cetak_awal,
                                        onChange: (value) => nfAction.changeForm_cetak(value, "id_cetak_awal"),
                                        // disabled: !nfState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        label: 'ID Cetak Akhir ',
                                        type: 'number',
                                        // placeholder: 'Masukan nama',
                                        required: false,
                                        readOnly: nfState.dataCetakTransaksi?.is_cetak_ulang !== "T",
                                        value: nfState.dataCetakTransaksi?.id_cetak_akhir,
                                        onChange: (value) => nfAction.changeForm_cetak(value, "id_cetak_akhir"),
                                        // disabled: !nfState.formEditable
                                    },
                                    {
                                        rows: 2,
                                        label: 'No. Register Buku',
                                        type: 'number',
                                        // placeholder: 'Masukan nama',
                                        required: false,
                                        readOnly:true,
                                        value: nfState.dataCetakTransaksi?.reg_buku,
                                        // onChange: (value) => nfAction.changeForm_cetak(value, "mulai_baris"),
                                        // disabled: !nfState.formEditable
                                    },
                                    {
                                        rows: 2,
                                        label: 'Mulai Baris',
                                        type: 'number',
                                        // placeholder: 'Masukan nama',
                                        // required: false,
                                        // readOnly: nfState.dataCetakTransaksi?.is_cetak_ulang !== "T",
                                        value: nfState.dataCetakTransaksi?.mulai_baris,
                                        onChange: (value) => nfAction.changeForm_cetak(value, "mulai_baris"),
                                        // disabled: !nfState.formEditable
                                    },
                                    {
                                        rows: 2,
                                        label: 'Cetak Ulang ',
                                        type: 'checkbox',
                                        placeholder: 'T/F',
                                        required: false,
                                        // readOnly:true,
                                        value: nfState.dataCetakTransaksi?.is_cetak_ulang === "T",
                                        checked: nfState.dataCetakTransaksi?.is_cetak_ulang === "T",
                                        onChange: (value) => nfAction.changeForm_cetak(value ? "T" : "F", "is_cetak_ulang"),
                                        // disabled: !nfState.formEditable
                                    },
                                    {
                                        rows: 2,
                                        label: 'All Data ',
                                        type: 'checkbox',
                                        placeholder: 'T/F',
                                        required: false,
                                        // readOnly:true,
                                        value: nfState.dataCetakTransaksi?.is_all_data === "T",
                                        checked: nfState.dataCetakTransaksi?.is_all_data === "T",
                                        onChange: (value) => nfAction.changeForm_cetak(value ? "T" : "F", "is_all_data"),
                                        // disabled: !nfState.formEditable
                                    }
                                ]
                            }
                        ]}
                    />
                    {
                        nfState.dataCetakTransaksi?.is_cetak_ulang === "T" && (

                            <div className='row'>
                                <div className='col'>
                                    <div className="row">
                                        <div className="col-4 align-self-center bns-form">
                                            <div className="form-group">
                                                <label>Tanggal Awal</label>
                                                <DateInput
                                                    className="form-control"
                                                    type="date"
                                                    placeholder="Tanggal Awal"
                                                    value={filterState.from}
                                                    onChange={(value) => {
                                                        setFilterState(prevState => ({ ...prevState, from: value }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4 align-self-center bns-form">
                                            <div className="form-group">
                                                <label>Tanggal Akhir</label>
                                                <DateInput
                                                    className="form-control"
                                                    type="date"
                                                    placeholder="Tanggal Akhir"
                                                    value={filterState.to}
                                                    onChange={(value) => {
                                                        setFilterState(prevState => ({ ...prevState, to: value }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4 align-self-center bns-form">
                                            <div className="form-group pt-4 mt-1">
                                                <button

                                                onClick={() => nfAction.getTrxCetakUlang( nfState.dataCetakTransaksi?.nomor_rekening, filterState.from, filterState.to)}
                                                className="btn btn-outline-success">
                                                    Cari
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <TableList
                                        data={nfState.dataCetakUlang || []}
                                        minHeight="250px"
                                        maxHeight="450px"
                                        pagination={false}
                                        withCard={false}
                                        columns={[

                                            {
                                                field: "ID",
                                                name: "ID Cetak"
                                            },
                                            // {
                                            //     field: "BARIS",
                                            //     name: "BARIS KE"
                                            // },

                                            {
                                                field: "TANGGAL",
                                                name: "Tanggal"
                                            }
                                            ,
                                            {
                                                field: "KODE",
                                                name: "Tx"
                                            }
                                            ,
                                            {
                                                field: "NILAI_MUTASI_DEBET",
                                                name: "Mutasi Debit"
                                            }
                                            ,
                                            {
                                                field: "NILAI_MUTASI_KREDIT",
                                                name: "Mutasi Kredit"
                                            }
                                            ,
                                            {
                                                field: "SALDO",
                                                name: "Saldo"
                                            }
                                            ,
                                            {
                                                field: "USER",
                                                name: "OprId"
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button
                                onClick={() => nfAction.closeCetakTransaksi()}
                                className="btn btn-outline-danger float-right">
                                Batal
                            </button>
                            <button
                                onClick={() => nfAction.openCetakTransakasiPrint()}
                                className="btn btn-outline-success mr-2">
                                Data Cetak
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <ModalCetakTransaksiPrint />
        </>
    );
}

export default ModalCetakTransaksiPassbook;