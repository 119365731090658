import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DateInput, SelectSearch } from '..';
import { AppAction, AppInterfaces } from '../../../app.data';
import { useOutside } from '../../../hooks';
import { IconPrinter } from '../../../icon';
import './style.css';

export default AppInterfaces.appLoad(
    memo(function ({
        columns = [],
        data = [],
        filter,
        resetFilter = true,
        formsFilter = [],
        submitButton = 'Submit',
        penCarian = 'Submit',
        onFilter = () => null,
        withCard = true,
        withActions = true,
        actionItems = [],
        customToolbar = null,
        selectionMode = 0,
        onResetFilter = () => null,
        pagination = true,
        onChangeShow = () => null,
        onNextPage = () => null,
        onPrevPage = () => null,
        store,
        navtable = null,
        navicon1 = null,
        navicon2 = null,
        iconprinter,
        iconcetak,
        isLoading = false,
        showChoices = [15, 25, 50],
        defaultShow = 15,
        maxHeight = '350px',
        minHeight = '300px',
        curPage = 1,
        onSelection = (keys) => null,
        fieldSelectionKey = null,
        defaultSelection = [],
        ...props
    }) {
        // const { currentPage, showingPage } = store;
        // console.log(curPage)

        var loadingGet = isLoading;
        const appAction = useRef(null);

        const [pagingDetil, setPagination] = useState({
            currentPage: 1, showingPage: 15
        });

        const [selection, setSelection] = useState([]);

        useLayoutEffect(function () {
            setSelection(defaultSelection)
        }, []);


        function changeSelection(select, key, data) {
            let newData = [];
            if (select) {
                newData = [...(defaultSelection || []), key]
                setSelection(prevState => newData)
            } else {
                newData = (defaultSelection || []).filter(v => v !== key)
                setSelection(prevState => newData)
            }
            onSelection(newData, data)
        }

        // useEffect(function(){
        //     onSelection(selection)
        // },[selection])

        useEffect(function () {
            setPagination(p => ({
                ...p,
                currentPage: curPage
            }))
        }, [curPage])
        const { currentPage, showingPage } = pagingDetil;
        const next = () => {
            const detil = {
                page: currentPage + 1,
                offset: (currentPage + 1) * showingPage,
                limit: showingPage,
            };
            // console.log(detil)
            if ((data.length > 0) && (data.length >= showingPage)) {
                setPagination({
                    currentPage: detil.page,
                    showingPage: detil.limit
                });
                onNextPage(detil);
            }
        };

        useEffect(function () {
            setPagination(prevState => ({
                currentPage: 1,
                showingPage: defaultShow
            }))
        }, [defaultShow]);

        const prev = () => {
            const detil = {
                page: currentPage - 1,
                offset: (currentPage - 1) * showingPage,
                limit: showingPage,
            };
            setPagination({
                currentPage: detil.page,
                showingPage: detil.limit
            });
            // appAction.current.prevPage();
            onNextPage(detil);
        };

        const show = (showingPage) => {
            let detil = {
                page: 1,
                offset: (currentPage - 1) * showingPage,
                limit: showingPage,
            };
            if (showingPage === '') {
                detil = {
                    page: currentPage - 1,
                    offset: (currentPage - 1) * showingPage,
                };
            }
            setPagination({
                currentPage: detil.page,
                showingPage: detil.limit
            });
            // appAction.current.resetPagination(showingPage);
            onChangeShow(detil);
        };
        const colsArray = [];
        for (var i = 0; i < 5; i++) {
            colsArray.push(i)
        }
        const [contextMenu, setContextMenu] = useState({
            show: false,
            x: 0,
            y: 0,
            listMenu: [],
            indexSelected: null
        });

        const [triggerRef, setTriggerRef] = useState(null);
        const [boxRef, setBoxRef] = useState(null);
        useOutside(boxRef, triggerRef, () => {
            setContextMenu(p => ({
                ...p,
                show: false,
                indexSelected: null
            }))
        })
        return (
            <>
                <AppAction ref={appAction} />
                {/* <div className="card">
                    <div className="card-body"> */}
                <div class={`dropdown-menu context-menu-animation ${contextMenu.show ? "show" : "hide"}`} style={{
                    // display: contextMenu.show ? "block" : "none",
                    left: contextMenu.x,
                    top: contextMenu.y,
                    position: "fixed",
                    zIndex: 999,
                    overflow: "hidden"
                    // transition:"all 2s linear"
                }}
                    ref={(r) => setBoxRef(r)}
                >
                    {(contextMenu.listMenu || [])?.map((item, i) => {
                        let enabled = false;

                        if (item.enabled === undefined) {
                            enabled = true;
                        } else {
                            enabled = item.enabled(item.data);
                        }

                        if (!enabled) return null;

                        return (
                            <>
                                {item.withDivider && <div className="dropdown-divider"></div>}
                                <button
                                    className={`dropdown-item ${props.className}`}
                                    onClick={() => {
                                        setContextMenu(p => ({
                                            ...p,
                                            show: false
                                        }))
                                        return item.onClick();
                                    }}
                                    type="button"
                                >
                                    {item.name}
                                </button>
                            </>
                        );
                    })}
                </div>
                {/* </div>
                </div> */}
                <div className={withCard ? 'card pb-2' : ''}>
                    <div className={withCard ? 'card-body' : ''}>
                        {/* Filter */}
                        {filter && (
                            <>
                                {
                                    navtable !== null && navicon1 !== null && navicon2 !== null && <div className="row">
                                        <div className="col-10 mb-4">
                                            {navtable}
                                        </div>
                                        <div className="col-1 mb-4">
                                            <button className="btn btn-sm mr-2">
                                                {navicon1}
                                            </button>
                                            <span></span>
                                        </div>
                                        <div className="col-1 mb-4">
                                            <button className="btn btn-sm mr-2">
                                                {navicon2}
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <span>Cari data berdasarkan :</span>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    {formsFilter?.map((ff, key) => {
                                        if (ff.type === 'text')
                                            return (
                                                <div className="col-md col-lg col-sm-12">
                                                    <div className="form-group form-group-sm">
                                                        <input
                                                            className="form-control form-control-sm"
                                                            value={ff.value}
                                                            onChange={(e) =>
                                                                ff.onChange?.(e.target.value)
                                                            }
                                                            disabled={ff.disabled}
                                                            readOnly={ff.readOnly}
                                                            placeholder={ff.placeholder}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        if (ff.type === 'select')
                                            return (
                                                <div className="col-md col-lg col-sm-12">
                                                    <div className="form-group form-group-sm">
                                                        <SelectSearch
                                                            className="form-control form-control-sm"
                                                            value={ff.value}
                                                            onChange={(e) =>
                                                                ff.onChange?.(e.value)
                                                            }
                                                            disabled={ff.disabled}
                                                            readOnly={ff.readOnly}
                                                            placeholder={ff.placeholder}
                                                            options={ff.options}
                                                            display={"display"}
                                                            keyValue={"key"}
                                                            size="sm"
                                                        />
                                                        {/* <option value="">{ff.placeholder}</option>
                                                            {ff.options?.map((opt, i) => (
                                                                <option value={opt.key}>
                                                                    {opt.display}
                                                                </option>
                                                            ))}
                                                        </select> */}
                                                    </div>
                                                </div>
                                            );
                                        if (ff.type === 'date')
                                            return (
                                                <div className="col-md col-lg col-sm-12">
                                                    <div className="form-group form-group-sm">
                                                        <DateInput
                                                            type="date"
                                                            className="form-control form-control-sm"
                                                            value={ff.value}
                                                            onChange={(e) =>
                                                                ff.onChange?.(e)
                                                            }
                                                            disabled={ff.disabled}
                                                            readOnly={ff.readOnly}
                                                            placeholder={ff.placeholder}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        if (ff.type === 'custom')
                                            return (
                                                <div className="col-md col-lg col-sm-6">
                                                    {ff.component}
                                                </div>
                                            );
                                    })}

                                    <div className={`col-md`}>
                                        {resetFilter && (
                                            <button onClick={() => onResetFilter()} className="btn btn-sm mr-2">
                                                <p style={{ color: '#16D090' }}><strong>Reset</strong></p>
                                            </button>
                                        )}
                                        <button
                                            className="btn btn-sm"
                                            style={{ backgroundColor: '#16D090' }}
                                            onClick={() => onFilter?.()}
                                        >
                                            <p className="text-white"><strong>{submitButton}</strong> </p>
                                        </button>


                                        <button
                                            className="btn btn-sm"
                                            onClick={() => onFilter?.()}
                                        >
                                            {iconprinter}
                                        </button>


                                        <button
                                            className="btn btn-sm"
                                            onClick={() => onFilter?.()}
                                        >
                                            {iconcetak}
                                        </button>
                                    </div>

                                    {customToolbar && (
                                        <div className="col-md col-lg col-sm-12">
                                            {customToolbar}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="row mt-2">
                            <div className="col-md-12">
                                <div
                                    className="table-responsive"
                                    style={{
                                        maxHeight,
                                        minHeight,
                                        borderBottom: '0.5pt grey solid',
                                    }}
                                >
                                    {/* {loadingGet ? (
                                        <Loading />
                                    ) : ( */}
                                    <table className="table tableFixHead table-hover">
                                        <thead
                                            style={{
                                                background: '#FAFAFA',
                                            }}
                                        >
                                            <tr>
                                                {selectionMode != 0 && (
                                                    <th>
                                                        {selectionMode == 2 && (
                                                            <input type="checkbox" />
                                                        )}
                                                    </th>
                                                )}
                                                {columns?.map(({
                                                    style = {},
                                                    className = "",
                                                    maxWidth = null,
                                                    minWidth = null,
                                                    ...head
                                                }, i) => (
                                                    <th style={{ ...style, maxWidth, minWidth }} key={i}>
                                                        <div style={{ minWidth: "max-content" }}>

                                                            {head.name}
                                                        </div>
                                                    </th>
                                                ))}
                                                {withActions && <th></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadingGet ? (
                                                (colsArray).map((v, key) => (
                                                    <tr key={key}
                                                        onContextMenu={(e) => {
                                                            e.preventDefault(e)
                                                        }}
                                                    >
                                                        {
                                                            columns?.map((col, j) => (
                                                                <td>
                                                                    <Skeleton key={j} height={30} count={1} />
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                )
                                                )

                                            ) :
                                                data?.map((item, key) => (
                                                    <tr
                                                        onContextMenu={(e) => {
                                                            // let listMenu = actionItems
                                                            //     .filter(a => (a.onRender !== undefined &&
                                                            //         a.onRender !== null &&
                                                            //         typeof a.onRender === "function")
                                                            //         ? (a.onRender(item)) : true)
                                                            //     .map(
                                                            //         (act) => ({
                                                            //             ...act,
                                                            //             onClick: () =>
                                                            //                 act.onClick(item, key),
                                                            //             data: item,
                                                            //         })
                                                            //     )
                                                            // if (withActions && listMenu.length > 0) {
                                                            //     e.preventDefault();
                                                            //     setContextMenu(xc => ({
                                                            //         ...xc,
                                                            //         show: true,
                                                            //         x: e.pageX,
                                                            //         y: e.pageY,
                                                            //         listMenu,
                                                            //         indexSelected:key
                                                            //     }))
                                                            // }
                                                        }}
                                                        style={{
                                                            ...(
                                                                selectionMode !== 0 ? ({
                                                                    cursor: "pointer"
                                                                }) : {}
                                                            ),
                                                            ...(
                                                                (key === contextMenu.indexSelected) || (
                                                                    (fieldSelectionKey === null ?
                                                                        defaultSelection.some(k => k === key) :
                                                                        defaultSelection.some(k => k === item[fieldSelectionKey]))
                                                                ) ? {
                                                                    background: "#e8e8e8"
                                                                } : {})
                                                        }}
                                                        onClick={() => {
                                                            if (selectionMode !== 0) {
                                                                changeSelection(!(
                                                                    fieldSelectionKey === null ?
                                                                        defaultSelection.some(k => k === key) :
                                                                        defaultSelection.some(k => k === item[fieldSelectionKey])
                                                                ), fieldSelectionKey === null ? key : item[fieldSelectionKey], item)
                                                            }
                                                        }}
                                                        key={key}>
                                                        {selectionMode != 0 && (
                                                            <td >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        fieldSelectionKey === null ?
                                                                            defaultSelection.some(k => k === key) :
                                                                            defaultSelection.some(k => k === item[fieldSelectionKey])
                                                                    }
                                                                    onChange={(e) => changeSelection(e.target.checked, fieldSelectionKey === null ? key : item[fieldSelectionKey], item)}
                                                                />
                                                            </td>
                                                        )}
                                                        {columns?.map(({
                                                            style = {},
                                                            className = "",
                                                            maxWidth = null,
                                                            minWidth = null,
                                                            onStyling = () => ({}),
                                                            ...col }, j) => {
                                                            var dataView = item[col.field];

                                                            if (
                                                                col.onRender !== undefined &&
                                                                col.onRender !== null
                                                            ) {
                                                                dataView = col.onRender(item, key);
                                                            }
                                                            function getStyling() {
                                                                let sty = onStyling(item)
                                                                if (typeof sty !== "object") throw "onStyling must be return an object";
                                                                return {
                                                                    ...sty,
                                                                    ...style
                                                                }
                                                            }
                                                            return (
                                                                <td style={{ ...getStyling(), maxWidth, minWidth }} className={className}>
                                                                    {col.field === '#' || col.field === 'no'
                                                                        ? (currentPage - 1) *
                                                                        showingPage +
                                                                        (key + 1)
                                                                        : dataView}
                                                                </td>
                                                            );
                                                        })}
                                                        {withActions && actionItems
                                                            .filter(a => (a.onRender !== undefined &&
                                                                a.onRender !== null &&
                                                                typeof a.onRender === "function")
                                                                ? (a.onRender(item)) : true).length > 0 && (
                                                                <td>
                                                                    <DropDown
                                                                        items={actionItems
                                                                            .filter(a => (a.onRender !== undefined &&
                                                                                a.onRender !== null &&
                                                                                typeof a.onRender === "function")
                                                                                ? (a.onRender(item)) : true)
                                                                            .map(
                                                                                (act) => ({
                                                                                    ...act,
                                                                                    onClick: () =>
                                                                                        act.onClick(item, key),
                                                                                    data: item,
                                                                                })
                                                                            )}
                                                                    />
                                                                </td>
                                                            )}
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                        {pagination && (
                            <div className="row mt-3">
                                <div className="col-md-12 text-center">
                                    <div className="float-left form-inline">
                                        <span>Baris Per-halaman </span>
                                        <select
                                            value={showingPage}
                                            onChange={(e) => show(e.target.value)}
                                            className="form-control form-control-sm"
                                            style={{ border: 'none' }}
                                        >
                                            <option value={15}>15</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </div>
                                    <div className="float-right">
                                        <span>
                                            Menampilkan{' '}
                                            {` ${(currentPage - 1) * showingPage + 1} - ${(currentPage - 1) * showingPage +
                                                (data || []).length
                                                }`}{' '}
                                            data
                                        </span>
                                    </div>
                                    <div className="mx-auto" style={{ width: '200px' }}>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination pagination-sm">
                                                <li className="page-item">
                                                    <button
                                                        onClick={prev}
                                                        disabled={currentPage === 1}
                                                        className="page-link"
                                                        href="#"
                                                    >
                                                        Previous
                                                    </button>
                                                </li>
                                                {/* <li className="page-item"><button className="page-link" href="#">1</button></li> */}
                                                <li className="page-item">
                                                    <button className="page-link" href="#">
                                                        {currentPage}
                                                    </button>
                                                </li>
                                                {/* <li className="page-item"><button className="page-link" href="#">3</button></li> */}
                                                <li className="page-item">
                                                    <button
                                                        onClick={next}
                                                        className="page-link"
                                                        href="#"
                                                    >
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* </div>
            </div> */}
            </>
        );
    })
);
const DropDown = memo(function (props) {
    const [show, setShow] = useState('');
    // console.log(props)
    const reffDrop = useRef(null)
    const onclickAnother = function (e) {
        if (reffDrop.current && !reffDrop.current.contains(e.target)) {
            setShow('')
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', onclickAnother);
        return () => {
            document.removeEventListener('mouseup', onclickAnother);
        }
    }, [])
    return (
        <div ref={reffDrop} className="btn-group">
            <div className="btn-group dropleft" role="group">
                <button
                    style={{
                        border: 'none',
                        background: 'none',
                    }}
                    onClick={() => setShow((prevState) => (prevState === 'show' ? '' : 'show'))}
                >
                    <i
                        className="fa fa-ellipsis-v"
                        style={{
                            transform: 'rotate(90deg)',
                        }}
                    ></i>
                </button>
                <div className={`dropdown-menu  context-menu-animation ${show}`}>
                    {props.items?.map((item, i) => {
                        let enabled = false;

                        if (item.enabled === undefined) {
                            enabled = true;
                        } else {
                            enabled = item.enabled(item.data);
                        }

                        if (!enabled) return null;

                        return (
                            <>
                                {item.withDivider && <div className="dropdown-divider"></div>}
                                <button
                                    className={`dropdown-item ${props.className}`}
                                    onClick={() => {
                                        setShow('');
                                        return item.onClick();
                                    }}
                                    type="button"
                                >
                                    {item.name}
                                </button>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});

function Loading() {
    return (
        <>
            <main className="text-white">
                <div
                    className="card card-body d-border-active text-center bg-orange-1"
                    style={{
                        width: '30%',
                        margin: 'auto',
                        marginTop: '30px',
                    }}
                >
                    <div className="text-center w-100 f-30">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="f-14 text-white mt-3">Please wait a few moments...</p>
                    </div>
                </div>
            </main>
        </>
    );
}
