import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { addCommas } from '../../../../../module';
import { NewLoading, TableList } from '../../../../controls';
import useKasTeller, { KasTellerProvider } from './useKasTeller';

function KasTeller() {
    const { ktState, ktAction } = useKasTeller()
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Informasi Transaksi</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Informasi Kas Teller
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="car-body text-center m-4">
                            {ktState.isLoading ? <Skeleton height="30px" width="60%"/> :<h3>Total Saldo Kas Teller : <span className="text-base">RP. {addCommas( parseFloat(ktState.saldoKas || 0).toFixed(2))} </span></h3>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                INFORMASI KAS TELLER
                            </div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                minHeight="450px"
                                maxHeight="500px"
                                onChangeShow={ktAction.changeShow}
                                onNextPage={ktAction.nextPage}
                                onPrevPage={ktAction.prevPage}
                                withActions={false}
                                columns={[
                                    {
                                        name: 'Jam Input',
                                        field: 'jam_input',
                                        onRender: ({ jam_input }) => moment(jam_input).format("HH:mm:ss")
                                    },
                                    {
                                        name: 'Debit/Kredit',
                                        field: 'jenis_mutasi',
                                        onRender: (item) => item.jenis_mutasi === "C" ? "Kredit" : "Debit"
                                    },
                                    {
                                        name: 'Nilai Mutasi',
                                        field: 'nilai_mutasi',
                                        className:"text-right",
                                        onRender: (item) => addCommas(parseFloat(item.nilai_mutasi)?.toFixed(2))
                                    },
                                    {
                                        name: 'Referensi',
                                        field: 'nomor_referensi',
                                        width: '40px',
                                    },
                                    {
                                        name: 'Status Proses Transaksi BDS',
                                        field: 'sts_validasi_trx',  
                                        // className:"text-right",
                                    },
                                    {
                                        name: 'Status Webhook Bifast',
                                        field: 'sts_webhook_bifast', 
                                    },
                                    {
                                        name: 'Status Otorisasi',
                                        field: 'status_otorisasi',
                                    },
                                    {
                                        name: 'User ',
                                        field: 'user_input',
                                    },   {
                                        name: 'User Otorisasi',
                                        field: 'user_otor',
                                    },
                                    {
                                        name: 'Keterangan',
                                        field: 'keterangan',
                                    },
                                ]}
                                data={ktState.dataList}
                                isLoading={ktState.isLoading}
                                filter={false}
                                resetFilter={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default () => <KasTellerProvider><KasTeller /></KasTellerProvider>;