// import { useKeycloak } from '@react-keycloak/web';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useDropdowns from '../../../../../hooks/useDropdowns';
import useFetch from '../../../../../hooks/useFetch';
import moment from 'moment';
import useJoinAccount from './modal/useJoinAccount';
import useNexusPrint from '../../../../../hooks/useNexusPrint';
import { uniqueId } from 'lodash';
import { useModalAlert } from '../../../../controls/modalAlert';
import { addCommas } from '../../../../../module';
import useNotif from './useNotif';
// import { useHistory } from 'react-router';
import useKeycloak from '../../../../../hooks/keycloak.module';
import { useLocalStorage } from '../../../../../module';

const rekContext = createContext(null)

export const RekeningProvider = ({ children, isOtor = false }) => {
    const { jAction, jState } = useJoinAccount({ closeModalJoin: closeHoldDana })
    const [modeEditHold, setModeEditHold] = useState(false)
    const [modeReqStatement, setReqStatement] = useState(true)
    const [podDate, setPodDate] = useLocalStorage("POD_DATE")
    // const history = useHistory();
    // Intial State
    const initDataWarkat = {
        nama_rekening: "",
        nomor_rekening: "",
        status_rekening: null,
        list: [
        ]
    }
    const initFormWarkat = {
        listForm: [],
        total_biaya: 0,
    }
    const initDataDetailWarkat = {
        list: [],
        selected: {
            id_bukuwarkat: 0,
            jenis_warkat: "",
            jumlah_lembar: 20,
            kode_warkat: "",
            lembar_terpakai: 2,
            nomor_seri_akhir: 20,
            nomor_seri_awal: 1,
            status_buku: "",
            user_input: "",
            user_otorisasi: "",
        }
    }
    const initDataWarkatList = {
        selected: {
            id_barang: '',
            kd_branch: '',
            jns_barang: '',
            barang: '',
            qty: '',
            kd_barang: '',
            no_barang: '',
            harga_jual: '',
            fee: '',
            materai_tempel: '',
            materai_giling: ''
        },
        list: []
    }

    // Modal State
    //const [modalCetakNamaPrint, setmodalCetakNamaPrint] = useState(false)
    //const [modalCetakTransaksiPrint, setmodalCetakTransaksiPrint] = useState(false)
    //const [modalCetakTransaksi, setmodalCetakTransaksi] = useState(false)
    //const [modalCetakNama, setmodalCetakNama] = useState(false)
    const [modalCetakStatement, setmodalCetakStatement] = useState(false)
    const [modalTutupAccount, setmodalTutupAccount] = useState(false)
    const [modalBatalTutupAccount, setmodalBatalTutupAccount] = useState(false)
    const [modalJoinAccount, setModalJoinAccount] = useState(false)
    const [modalHoldDana, setModalHoldDana] = useState(false)
    const [modalBlokirRekening, setModalBlokRek] = useState(false)
    const [modalWarkat, setModalWarkat] = useState(false)
    const [modalWarkatList, setModalWarkatList] = useState(false)
    const [modalBukaRekening, setModalBukaRek] = useState(false)
    const [modalAktifasiDormant, setmodalAktifasiDor] = useState(false)
    const [modalDetailRekening, setModalDetailRekening] = useState(false)
    const [modalDetailRencana, setModalDetailRencana] = useState(false)

    // Modal Mode
    const [holdModalMode, setHoldModalMode] = useState(false)
    const [warkatBukuModalMode, setWarkatBukuModalMode] = useState(false)
    const [warkatDataModalMode, setWarkatDataModalMode] = useState(false)
    const [warkatLihatModalMode, setWarkatLihatModalMode] = useState(false)
    const [warkatAktivasiModalMode, setWarkatAktivasiModalMode] = useState(false)
    const [warkatEditModalMode, setWarkatEditModalMode] = useState(false)
    const [blokRekModalMode, setBlokRekMode] = useState(false)
    const [bukaRekModalMode, setBukaRekMode] = useState(false)
    const [aktifasiDormantModalMode, setAktifasiDormant] = useState(false)

    // Function State
    const { doGet, doPost, wait, cekUserLevel } = useFetch();
    const { keycloak } = useKeycloak()
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ keyword: '', jenis_nasabah: "", status_rekening: "" })
    // const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    // const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false });
    const [rekSelected, setRekSelected] = useState({});
    const [filterHist, setFilterHist] = useState({ date_start: moment(Date.now()).format("YYYY-MM-DD"), date_end: moment(Date.now()).format("YYYY-MM-DD") });
    const [dataHist, setDataHist] = useState([])
    const [paginationHist, setPaginationHist] = useState({
        page: 1,
        show: 15
    });

    // Loading State
    const [isLoading, setIsLoading] = useState(true);
    const [loadingDetil, setLoadingDetil] = useState(true);
    const [loadingHist, setLoadingHist] = useState(false);
    const [loadingHold, setLoadingHold] = useState(false)
    const [loadingBlok, setLoadingBlok] = useState(false)
    const [loadingBuka, setLoadingBuka] = useState(false)
    const [loadingWarkat, setLoadingWarkat] = useState(false)
    const [loadingTutupAccount, setloadingTutupAccount] = useState(false)
    const [loadingBatalTutupAccount, setloadingBatalTutupAccount] = useState(false)
    const [loadingCetakStatement, setloadingCetakStatement] = useState(false)
    const [loadingListTrx, setLoadingListTrx] = useState(false)
    const [loadingAktDor, setLoadingAktDor] = useState(false)
    // const [loading, setLoading] = useState({ show: false, msg: "Please Wait ..." });
    const [otorMode, setOtorMode] = useState(false)
    const [actionMode, setActionMode] = useState("")
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction

    const { print } = useNexusPrint();

    // Data State
    const [dataOtor, setDataOtoe] = useState({
        kode_entry: "",
        dbId: "",
        callBackSuccess: () => null
    });
    // useEffect(function(){
    //     setFilterHist({

    //     })
    // },[])
    const [dataListRekening, setListDataRekening] = useState([])
    const [dataHoldDana, setdataHoldDana] = useState({
        "nama_rekening": "",
        "nama_produk": "",
        "cabang": "",
        "saldo": "",
        "nomor_rekening": "",
        "valuta": "",
        "listhold": [
        ]
    });
    const [dataDetailWarkat, setDataDetailWarkat] = useState(initDataDetailWarkat);
    const [dataWarkat, setdataWarkat] = useState(initDataWarkat);
    const [listDataWarkat, setListDataWarkat] = useState(initDataWarkatList)
    const [dataBlokRekening, setdataBlokRekening] = useState({
        "nomor_rekening": "",
        "nama_rekening": "",
        "produk_rekening": "",
        "valuta_rekening": "",
        "cabang_rekening": "",
        "saldo_rekening": "",
        "tanggal_kadaluarsa": "",
        "alasan_blokir": "",
        "jenis_blokir": "",
        "listblok": [
        ]
    });
    function resetBlokRekening() {
        setdataBlokRekening({
            "nomor_rekening": "",
            "nama_rekening": "",
            "produk_rekening": "",
            "valuta_rekening": "",
            "cabang_rekening": "",
            "saldo_rekening": "",
            "tanggal_kadaluarsa": "",
            "alasan_blokir": "",
            "jenis_blokir": "",
            "listblok": [
            ]
        });
    }
    const [dataBukaRekening, setdataBukaRekening] = useState({

    });
    const [dataAktifasiDormant, setdataAktifasiDormant] = useState({
        "nomor_rekening": "",
        "nama_rekening": "",
        "produk_rekening": "",
        "valuta_rekening": "",
        "cabang_rekening": "",
        "saldo_rekening": "",
        "tanggal_kadaluarsa": "",
        "alasan_blokir": "",
        "listdor": [
        ]
    });
    const [dataTutupAccount, setdataTutupAccount] = useState({
        "nama_rekening": "",
        "nama_produk": "",
        "cabang": "",
        "saldo": 0,
        "nomor_rekening": "",
        "valuta": "",
        "saldo_ditahan": 0,
        "is_blokir": "T",
        "listinfo": [

        ]
    });
    const [dataBatalTutupAccount, setdataBatalTutupAccount] = useState({
        "nama_rekening": "",
        "nama_produk": "",
        "cabang": "",
        "saldo": 0,
        "nomor_rekening": "",
        "valuta": "",
        "saldo_ditahan": 0,
        "is_blokir": "T",
        "listinfo": [

        ]
    });
    const [dataCetakStatement, setdataCetakStatement] = useState({
        "nama_rekening": "",
        "nama_cabang": "",
        "nomor_rekening": "",
        "periodeA": moment(podDate).format("YYYY-MM-DD"),
        "periodeB": moment(podDate).format("YYYY-MM-DD"),
    });
    // const [dataCetakNama, setdataCetakNama] = useState({
    //     "nama_rekening": "",
    //     "nama_cabang": "",
    //     "nomor_rekening": "",
    // });
    // const [dataCetakNamaPrint, setdataCetakNamaPrint] = useState({});
    // const [dataCetakTransaksiPrint, setdataCetakTransaksiPrint] = useState({
    //     "nomor_rekening": "",
    //     "id_cetak_awal": 0,
    //     "id_cetak_akhir": 0,
    //     "is_cetak_ulang": "F",
    //     "is_all_data": "T",
    // });

    // const [dataCetakTransaksi, setdataCetakTransakasi] = useState({
    //     "nomor_rekening": "",
    //     "id_cetak_awal": 0,
    //     "id_cetak_akhir": 0,
    //     "is_cetak_ulang": "F",
    //     "is_all_data": "T",
    //     "mulai_baris": 0
    // });


    // Form State
    const [formHoldDana, setFormHoldDana] = useState({
        nominal_hold: "",
        alasan_hold: "",
        tanggal_hold: "",
        tanggal_kadaluarsa: "",
        blokir_regulator: "",
        nomor_referensi: ""
    });
    // Form UNhOLD
    // const [formHoldDanaU, setFormHoldDanaU] = useState({
    //     nominal_hold: "",
    //     alasan_hold: "",
    //     tanggal_hold: "",
    //     tanggal_kadaluarsa: "",
    //     blokir_regulator: ""
    // });

    function resetFormHold() {
        setUnholdMode(false)
        setModeEditHold(false)
        setFormHoldDana({
            nominal_hold: "",
            alasan_hold: "",
            tanggal_hold: "",
            tanggal_kadaluarsa: "",
            blokir_regulator: "",
            nomor_referensi: ""
        })
    }
    const [formWarkat, setFormWarkat] = useState(initFormWarkat)
    const [formBlokRekening, setformBlokRekening] = useState({
        alasan_blokir: null,
        jenis_blokir: null,
    });

    // otordesc
    const [otorDesc, setOtorDesc] = useState("")

    // dropdown
    const { eBlokirRegulator } = useDropdowns(["eBlokirRegulator"])
    const { eJenisWarkat } = useDropdowns(["eJenisWarkat"])
    const { eStatusWarkat } = useDropdowns(["eStatusWarkat"])
    const { eJenisMaterai } = useDropdowns(["eJenisMaterai"])

    const [unholdMode, setUnholdMode] = useState(false)
    // const [dataCetakUlang, setDataCetakUlang] = useState([])
    // close Loading
    // function closeLoading() { setLoading(prevState => ({ msg: "Please Wait ...", show: false })) }

    // // openLoading
    // function openLoading(msg = "Please Wait ...") { setLoading(prevState => ({ msg, show: true })) }

    useFetch(function () {
        setOtorMode(isOtor);
    }, [isOtor])
    // useEffect GetData
    useEffect(function () {
        !isOtor && getDataList()
    }, [pagination])

    // open modal detail rekening
    async function openDetailRekening(dataRekening = {}) {
        // // // // console.log(dataRekening)

        const { izinKan, remark: rem } = await cekUserLevel(dataRekening.nomor_rekening, closeDetailRekening)
        // console.log(izinKan)
        if (!izinKan) {
            return closeDetailRekening()
        }
        setRekSelected({});
        setModalDetailRekening(true)
        getDetail(dataRekening.nomor_rekening, dataRekening.status);
        resetFilterHist();
        getPOD();
        setDataHist([]);
        resetPaginationHist();
    }
    // close modal detail rekening
    function closeDetailRekening() {
        setModalDetailRekening(false)
        // setHoldModalMode(false)
    }
    // open modal detail rekening rencana
    async function openDetailRencana(dataRekening = {}) {
        // // // // console.log(dataRekening)
        const { izinKan, remark: rem } = await cekUserLevel(dataRekening.nomor_rekening, closeDetailRencana)
        // console.log(izinKan)
        if (!izinKan) {
            return closeDetailRencana()
        }
        setRekSelected({});
        setModalDetailRencana(true)
        getDetail(dataRekening.nomor_rekening, dataRekening.status);
        resetFilterHist();
        getPOD();
        setDataHist([]);
        resetPaginationHist();
    }
    // close modal detail rekening rencana
    function closeDetailRencana() {
        setModalDetailRencana(false)
        // setHoldModalMode(false)
    }

    // open modal tutup account
    function openTutupAccount(data, isOtorisasi = false, callBackSuccess = () => null) {
        // // // // console.log(data)
        setdataTutupAccount(
            {
                "nama_rekening": "",
                "nama_produk": "",
                "cabang": "",
                "saldo": 0,
                "nomor_rekening": "",
                "valuta": "",
                "saldo_ditahan": 0,
                "is_blokir": "",
                "listinfo": [
                    " "
                ]
            }
        );
        setOtorMode(isOtorisasi)
        if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        getTutupAccount(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
        setmodalTutupAccount(true)
    }
    // close modal tutup account
    function closeTutupAccount() {
        setmodalTutupAccount(false)
    }

    // open modal Batal tutup account
    function openBatalTutupAccount(data, isOtorisasi = false, callBackSuccess = () => null) {
        // // // // console.log(data)
        setdataBatalTutupAccount(
            {
                "nama_rekening": "",
                "nama_produk": "",
                "cabang": "",
                "saldo": 0,
                "nomor_rekening": "",
                "valuta": "",
                "saldo_ditahan": 0,
                "is_blokir": "",
                "listinfo": [
                    " "
                ]
            }
        );
        setOtorMode(isOtorisasi)
        if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        getBatalTutupAccount(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
        setmodalBatalTutupAccount(true)
    }
    // close modal Batal tutup account
    function closeBatalTutupAccount() {
        setmodalBatalTutupAccount(false)
    }

    // open Cetak Statement
    async function openCetakStatement(data, isOtorisasi = false, callBackSuccess = () => null) {
        // // // // console.log(data)
        openLoading()
        const { izinKan, remark: rem } = await cekUserLevel(data.nomor_rekening, closeCetakStatement)
        // console.log(izinKan)
        if (!izinKan) {
            return closeCetakStatement()
        }
        setdataCetakStatement({
            "nama_rekening": "",
            "nomor_rekening": "",
            "nama_cabang": data.nama_cabang,
            "periodeA": moment(podDate).format("YYYY-MM-DD"),
            "periodeB": moment(podDate).format("YYYY-MM-DD"),
        });
        setOtorMode(isOtorisasi)
        if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        getCetakStatement(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
        setmodalCetakStatement(true)
        closeLoading()
    }
    // close Cetak Statement
    function closeCetakStatement() {
        setdataCetakStatement({
            "periodeA": moment(podDate).format("YYYY-MM-DD"),
            "periodeB": moment(podDate).format("YYYY-MM-DD"),
        });
        setmodalCetakStatement(false);
    }
    // get List Transaksi Cetak Statement
    async function getDataListStatement() {
        setLoadingListTrx(true);
        let url = "/tabungan-giro/hist-cetak-statement/update-flag";
        if (modeReqStatement) url = "/tabungan-giro/request-cetak-statement"
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url,
                method: !modeReqStatement ? "PUT" : "POST",
                service: "acc",
                ...(!modeReqStatement ? {
                    queryParam: {
                        db_id: dataCetakStatement?.db_id,
                        is_boleh_cetak: "F"
                        // date_start: dataCetakStatement?.periodeA,
                        // date_end: dataCetakStatement?.periodeB,
                        // nomor_rekening: dataCetakStatement?.nomor_rekening
                    }
                } : {
                    data: {
                        "date_start": dataCetakStatement?.periodeA,
                        "date_end": dataCetakStatement?.periodeB,
                        "nomor_rekening": dataCetakStatement?.nomor_rekening,
                        "nama_rekening": dataCetakStatement.nama_rekening,
                        "cabang_rekening": dataCetakStatement.nama_cabang
                    }
                }),

            });

            // // // console.log(resp)
            if (statusCode === 200) {
                if (status === "00") {
                    if (modeReqStatement) {
                        setDataAlert({
                            show: true,
                            body: "Request berhasil, butuh otorisasi dari supervisor untuk melanjutkan cetak",
                            title: "Request cetak statement berhasil",
                            callBack: () => {
                                closeCetakStatement()
                                closeAlert();

                            }
                        });
                    } else {

                        return true;
                        // setdataCetakStatement({
                        //     ...dataCetakStatement,
                        //     trx: {
                        //         list: data.listtrx,
                        //         debit: data.total_mutasi_debet,
                        //         kredit: data.total_mutasi_kredit,
                        //         saldoAwal: data.saldo_awal,
                        //         saldoAkhir: data.saldo_akhir,
                        //         npwp: data.npwp
                        //     }
                        // })

                    }
                    await wait(3000);
                }
                else {
                    setLoadingListTrx(false)

                    if (!modeReqStatement) return false
                    setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
                }
            }
            else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        setLoadingListTrx(false)
    }

    // // open Cetak Nama
    // function openCetakNamaPasbook(data, isOtorisasi = false, callBackSuccess = () => null) {
    //     // // // // console.log(data)
    //     setdataCetakNama({
    //         "nama_rekening": "",
    //         "nomor_rekening": "",
    //         "nama_cabang": data.nama_cabang,
    //         "alamat": "",
    //         "Kode Pos": "",
    //     });
    //     setOtorMode(isOtorisasi)
    //     if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
    //     getCetakNamaPasbook(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
    //     setmodalCetakNama(true)
    // }
    // // close Cetak Nama
    // function closeCetakNama() {
    //     setmodalCetakNama(false)
    // }

    // // open Cetak Nama Print
    // function openCetakNamaPrint(idRekening) {
    //     // setmodalCetakNama(false)
    //     // setdataCetakNamaPrint({});
    //     getCetakNamaPrint(idRekening, 200)
    //     // setmodalCetakNamaPrint(true)
    // }
    // // close Cetak Nama
    // function closeCetakNamaPrint() {
    //     setmodalCetakNamaPrint(false)
    // }

    // // close Cetak Nama
    // function closeCetakTransaksiPrint() {
    //     setmodalCetakTransaksiPrint(false)
    // }

    // // open Cetak Nama
    // function openCetakTransaksiPasbook(data, isOtorisasi = false, callBackSuccess = () => null) {
    //     // // // // console.log(data)
    //     // setdataCetakTransakasi({
    //     //     "nama_rekening": "",
    //     //     "nomor_rekening": "",
    //     //     "nama_cabang": data.nama_cabang,
    //     // });
    //     setOtorMode(isOtorisasi)
    //     if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
    //     getCetakTransaksiPasbook(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
    //     setmodalCetakTransaksi(true)
    // }
    // // close Cetak Nama
    // function closeCetakTransaksi() {
    //     setmodalCetakTransaksi(false)
    // }

    // open modal hold dana
    function openHoldDana(data, isOtorisasi = false, callBackSuccess = () => null) {
        // // // // console.log(data)
        setdataHoldDana({
            "nama_rekening": "",
            "nama_produk": "",
            "cabang": "",
            "saldo": "",
            "nomor_rekening": "",
            "valuta": "",
            "listhold": [
            ]
        });
        setOtorMode(isOtorisasi)
        if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        getHoldDana(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
        setModalHoldDana(true)
    }
    //open modal Join Account
    function openJoinAccount(data, isOtorisasi = false, callBackSuccess = () => null) {
        // // // // console.log(data)
        setdataHoldDana({
            "nama_rekening": "",
            "nama_produk": "",
            "cabang": "",
            "saldo": "",
            "nomor_rekening": "",
            "valuta": "",
            "listhold": [
            ]
        });
        setOtorMode(isOtorisasi)
        if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        jAction.getJoinAccount(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi, "JA")

        // getHoldDana(data.nomor_rekening, false, "JA")
        setModalJoinAccount(true)
        setActionMode("JA")
    }
    // close modal hold dana
    function closeHoldDana() {
        setModalHoldDana(false)
        setHoldModalMode(false)
        setModalJoinAccount(false)
    }

    // open modal blokrek
    function openBlokRek(data, isOtorisasi = false, callBackSuccess = () => null) {
        //// // console.log(data)

        setOtorMode(isOtorisasi)
        if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        getBlokRekening(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
        setModalBlokRek(true)
        if (!isOtorisasi) setdataBlokRekening(p => ({
            ...p,
            "alasan_blokir": "",
            "jenis_blokir": "",
        }));
    }
    // close modal blokrek
    function closeBlokRek() {
        setModalBlokRek(false)
        setBlokRekMode(false)
    }

    // open modal pemeliharaan warkat
    function openWarkat(data, isOtorisasi = false, callBackSuccess = () => null) {
        let type = ""
        setWFilter({ keyword: "" })
        setdataWarkat({
            nama_rekening: data.nama_nasabah,
            nomor_rekening: data.nomor_rekening,
            status_rekening: null,
            list: [
            ]
        });
        setOtorMode(isOtorisasi)
        if (isOtorisasi) {
            setDataOtoe({ ...data, callBackSuccess })
            if (data.kode_entry === "CQBG001") {
                type = "buku"
            } else if (data.kode_entry === "CQBG002") {
                type = "aktivasi"
            } else if (data.kode_entry === "CQBG004") {
                type = "edit"
            } else if (data.kode_entry === "CQBG005") {
                type = "counter"
            }
        }
        getWarkat(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi, type)
        setModalWarkat(true)
    }
    // close modal warkat
    function closeWarkat() {
        setModalWarkat(false)
        toggleModalWarkat()
        formWarkat.listForm?.map((e) => {
            updateHoldWarkat(e.id_barang, "2")
        })
    }

    function closeWarkatList() {
        setModalWarkatList(false)
    }
    // open modal bukarek
    function openBukaRek(data, isOtorisasi = false, callBackSuccess = () => null) {
        // // console.log(data)
        setdataBukaRekening({
            "cabang_rekening": data.kode_cabang,
            "jenis_blokir": data.jenis_blokir,
        });
        setOtorMode(isOtorisasi)
        if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        getBukaRekening(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
        setModalBukaRek(true)
    }
    // close modal bukarek
    function closeBukaRek() {
        setModalBukaRek(false)
        setBukaRekMode(false)
    }

    // Open modal aktifasi dormant
    function openAktifasiDormant(data, isOtorisasi = false, callBackSuccess = () => null) {
        // // // // console.log(data)
        setdataAktifasiDormant({
            "nama_rekening": "",
            "nama_produk": "",
            "cabang": "",
            "saldo": "",
            "nomor_rekening": "",
            "valuta": "",
            "listblok": [
            ]
        });
        setOtorMode(isOtorisasi)
        if (isOtorisasi) setDataOtoe({ ...data, callBackSuccess })
        getAktifasiDormant(isOtorisasi ? data.dbId : data.nomor_rekening, isOtorisasi)
        setmodalAktifasiDor(true)
    }
    // close modal blokrek
    function closeAktifasiDormant() {
        setmodalAktifasiDor(false)
        setAktifasiDormant(false)
    }



    // toggle modal mode { false : data, true : register}
    function toggleModalMode() {
        setHoldModalMode(prevState => (!prevState))
        setBlokRekMode(prevState => (!prevState))
        setBukaRekMode(prevState => (!prevState))
        setAktifasiDormant(prevState => (!prevState))
    }

    function toggleModalWarkat(mode) {
        if (mode === 'buku') {
            setWarkatBukuModalMode(prevState => (!prevState))
        } else if (mode === 'data') {
            setWarkatDataModalMode(prevState => (!prevState))
        } else if (mode === 'lihat') {
            setWarkatLihatModalMode(prevState => (!prevState))
        } else if (mode === 'aktivasi') {
            setWarkatAktivasiModalMode(prevState => (!prevState))
        } else if (mode === 'edit') {
            setWarkatLihatModalMode(prevState => (!prevState))
            setWarkatEditModalMode(prevState => (!prevState))
        }
        else {
            setWarkatBukuModalMode(false)
            setWarkatDataModalMode(false)
            setWarkatLihatModalMode(false)
            setWarkatAktivasiModalMode(false)
            setWarkatEditModalMode(false)
        }
    }

    // close Alert
    // function closeAlert() { setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null }) }

    // // closeConfirm
    // function closeConfirm() { setDataConfirm({ show: false, body: "", header: null, title: "", footerButton: [], closeButton: true, witTextArea: false }) }


    // getData rekening list
    async function getDataList() {
        setIsLoading(true)
        var url = `/tabungan-giro/search`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { ...pagination, ...filter },
                service: "acc"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    // // // console.log(data.list)
                    setListDataRekening(data?.list)
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    function resetFilter() {
        setFilter({
            keyword: '', jenis_nasabah: "", status: ""
        })
    }
    // next pagination
    function nextPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    useEffect(function () {
        // console.log(rekSelected)
    }, [rekSelected])
    //data instansi
    const [ dataInstansi, setDataInstansi] = useState([])
    useEffect(function () {
        (async ()=>{

            try {
                const { data = [], statusCode, status, remark, statusText } = await doGet({
                    url: `/rekening/list-instansi`,
                    service: "acc"
                });
                if (statusCode === 200) {
                    if (status === "00") setDataInstansi(data)
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data instansi`, header: "Error", callBack: () => null });
            }

        })()
    }, [])
    // getDetail
    async function getDetail(nomor_rekening, rekStatus) {
        setLoadingDetil(true)
        var url = `/tabungan-giro/${nomor_rekening}`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setRekSelected({ ...data, status: rekStatus })
                    setformBlokRekening({ alasan_blokir: data.keterangan_blokir, jenis_blokir: data.jenis_blokir })
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingDetil(false)
    }

    // get data history
    async function getHistory() {
        // // // console.log(filterHist)
        setLoadingHist(true)
        var url = `/tabungan-giro/trx-history`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                queryParam: {
                    ...filterHist,
                    ...paginationHist,
                    nomor_rekening: rekSelected.nomor_rekening
                }
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setDataHist(data.list)
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            }
            // else if(statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingHist(false)
    }

    // getHoldDana
    async function getHoldDana(nomor_rekening, isOtorisasi, mode = "", editMode = false) {
        setModeEditHold(editMode)
        setLoadingHold(true)
        var url = `/hold-saldo/list/${nomor_rekening}`;
        if (isOtorisasi) url = `/hold-saldo/otor-view/${nomor_rekening}`;
        if (editMode) url = `/hold-saldo/detail/${nomor_rekening}`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc"
            });

            // // // console.log(data)

            if (statusCode === 200) {
                if (status === "00") {

                    if (editMode) setFormHoldDana(f => ({ ...data, blokir_regulator: data.is_regulatory }))
                    else {
                        if (mode === "JA") jAction.setData({
                            "nomor_rekening": data.nomor_rekening,
                            "nama_rekening": data.nama_rekening,
                            "produk_rekening": data.nama_produk,
                            "valuta_rekening": data.valuta,
                            "cabang_rekening": data.cabang,
                        })
                        // if(isOtorisasi) getHoldDana(data.nomor_rekening, false, "", false)
                        setdataHoldDana(data)
                    }
                    // setFormHoldDana(data)
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingHold(false)
    }

    const [wFilter, setWFilter] = useState({
        keyword: ""
    })
    async function getWarkat(nomor_rekening, isOtorisasi = false, type) {
        setLoadingWarkat(true)
        var url = `/bukuwarkat/${nomor_rekening}`;
        if (isOtorisasi) url = `/bukuwarkat/otor-view/${nomor_rekening}`
        if (isOtorisasi && type === "edit") url = `/bukuwarkat/ubah-status-warkat/otor-view/${nomor_rekening}`
        if (isOtorisasi && type === "counter") url = `/bukuwarkat/otor-view/warkat-counter/${nomor_rekening}`

        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc",
                ...(isOtorisasi ? {} : { param: wFilter })
            });

            if (statusCode === 200) {
                if (status === "00") {
                    if (isOtorisasi) {
                        setdataWarkat(prev => ({ ...prev, nama_rekening: data.nama_rekening, nomor_rekening: data.nomor_rekening }))
                        if (type === 'buku') {
                            setDataDetailWarkat(prev => ({ ...prev, selected: data }))
                            toggleModalWarkat('buku')
                        } if (type === 'aktivasi') {
                            setDataDetailWarkat(prev => ({ ...prev, selected: data }))
                            toggleModalWarkat('aktivasi')
                        } if (type === 'edit') {
                            setDataDetailWarkat(prev => ({ ...prev, selected: data }))
                            toggleModalWarkat('lihat')
                            toggleModalWarkat('edit')
                        } if (type === 'counter') {
                            setDataDetailWarkat(prev => ({ ...prev, selected: data }))
                            toggleModalWarkat('data')
                        }
                    }
                    setdataWarkat(prev => ({ ...prev, list: data.list }))
                } else if (status === "01") {
                    setDataAlert({ show: true, body: "Data kosong", title: null, header: 'Perhatian', callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            }
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => closeWarkat() });
        }
        await wait(3000)
        setLoadingWarkat(false)
    }

    async function getWarkatList(params, type = null) {
        setLoadingWarkat(true)
        let tipe_barang = params === 'C' ? 'tipe_barang=1' : params === 'B' ? 'tipe_barang=2' : ''
        let jenis_barang = type !== null ? `&jns_barang=${type}` : ``
        var url = `/bukuwarkat/list-warkat-bjbs-vms-cr?${tipe_barang}${jenis_barang}`;
        setListDataWarkat(prev => ({ ...prev, list: initDataWarkatList.list }))
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setListDataWarkat(prev => ({ ...prev, list: data }))
                } else if (status === "01") {
                    setDataAlert({ show: true, body: "Data kosong", title: null, header: 'Perhatian', callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            }
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => closeWarkat() });
        }
        await wait(3000)
        setLoadingWarkat(false)
    }

    async function getDetailWarkat(id_bukuwarkat, isOtorisasi) {
        setLoadingWarkat(true)
        var url = `/bukuwarkat/informasi-detail-warkat/${id_bukuwarkat}`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setDataDetailWarkat(prev => ({ ...prev, list: data }))
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingWarkat(false)
    }

    async function getInfoWarkat(type) {
        let param = `jenis_warkat=${formWarkat.jenis_warkat}&kode_warkat=${formWarkat.kode_warkat}&nomor_seri_awal=${formWarkat.nomor_seri_awal}`
        var url = `/bukuwarkat/info-stok/?${param}`;
        let validate = false

        if (type === "buku") {
            if (formWarkat.kode_warkat && formWarkat.jenis_warkat && formWarkat.nomor_seri_awal && formWarkat.jenis_materai) {
                validate = true
            }
        } else if (type === "data") {
            if (formWarkat.kode_warkat && formWarkat.jenis_warkat && formWarkat.nomor_seri_awal && formWarkat.jenis_materai && formWarkat.jumlah_lembar) {
                validate = true
            }
        }

        if (validate) {
            try {
                const { statusCode, status, remark, statusText, data = {} } = await doGet({
                    url: url,
                    service: "acc",
                });

                if (statusCode === 200) {
                    if (data !== null) {
                        if (type === 'buku') {
                            setFormWarkat(prev => ({ ...prev, nomor_seri_akhir: data.nomor_seri_akhir, jumlah_lembar: data.jumlah_lembar, list: data.list_biaya, total_biaya: data.total_biaya }))
                        } else if ('data') {
                            setFormWarkat(prev => ({ ...prev, nomor_seri_akhir: data.nomor_seri_akhir, list: data.list_biaya, total_biaya: data.total_biaya }))
                        }
                    }
                    else setDataAlert({ show: true, body: "Data warkat tidak ditemukan", title: null, header: "Perhatian", callBack: () => null });
                } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            }
        } else {
            setDataAlert({ show: true, body: "Silahkan lengkapi form", header: "Data Tidak Valid", callBack: () => null });
        }
        await wait(3000)
        setLoadingWarkat(false)
    }

    async function aktivasiWarkat() {
        const userInfo = await keycloak.loadUserInfo();
        // // // console.log(userInfo)
        var url = `/bukuwarkat/aktivasi`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: { ...dataDetailWarkat.selected, nama_rekening: dataWarkat.nama_rekening, nomor_rekening: dataWarkat.nomor_rekening },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Aktivasi Warkat Berhasil, Data perlu dilakukan otorisasi`, title: ``, header: "Success", callBack: () => toggleModalWarkat('aktivasi') });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
    }

    async function tambahWarkat(param) {
        const userInfo = await keycloak.loadUserInfo();
        const check = formWarkat.listForm
        if (check.length === 0 || check.some(e => Object.keys(e).map(function (key) {
            return e[key];
        }).includes('') || Object.keys(e).map(function (key) {
            return e[key];
        }).includes(0))) {
            setDataAlert({ show: true, body: 'Lengkapi form terlebih dahulu', header: "Perhatian", callBack: () => null });
        }
        else {
            var url = `/bukuwarkat/tambah`;
            try {
                const { statusCode, status, remark, statusText, data = {} } = await doPost({
                    url: url,
                    service: "acc",
                    data: {
                        id_barang: formWarkat.listForm[0].id_barang,
                        nama_rekening: dataWarkat.nama_rekening,
                        nomor_rekening: dataWarkat.nomor_rekening,
                        jenis_warkat: formWarkat.listForm[0].jenis_warkat,
                        jumlah_lembar: parseInt(formWarkat.listForm[0].jumlah_lembar),
                        flag_lembar_buku: param === "buku" ? "B" : param === "data" ? "L" : null,
                        kode_warkat: formWarkat.listForm[0].kode_warkat,
                        jenis_materai: formWarkat.listForm[0].jenis_materai,
                        nomor_seri_awal: parseInt(formWarkat.listForm[0].nomor_seri_awal),
                        nomor_seri_akhir: parseInt(formWarkat.listForm[0].nomor_seri_akhir),
                        status_warkat: 'W',
                        harga_beli: parseInt(formWarkat.listForm[0].harga_jual),
                        harga_materai: formWarkat.listForm[0].jenis_materai === "G" ? parseInt(formWarkat.listForm[0].materai_giling) : parseInt(formWarkat.listForm[0].materai_tempel),
                        nominal_fee: parseInt(formWarkat.listForm[0].fee)
                    },
                    headers: {
                        "x-user-id": userInfo.preferred_username,
                        method: "POST"
                    }
                });

                if (statusCode === 200) {
                    if (status === "00") setDataAlert({ show: true, body: `Tambah Warkat berhasil, Data perlu dilakukan otorisasi`, title: ``, header: "Success", callBack: () => toggleModalWarkat() });
                    else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
                } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            }
        }
    }

    async function tambahWarkatCounter(param) {
        const userInfo = await keycloak.loadUserInfo();
        const check = formWarkat.listForm
        if (check.length === 0 || check.some(e => Object.keys(e).map(function (key) {
            return e[key];
        }).includes('') || Object.keys(e).map(function (key) {
            return e[key];
        }).includes(0))) {
            setDataAlert({ show: true, body: 'Lengkapi form terlebih dahulu', header: "Perhatian", callBack: () => null });
        }
        else {
            let warkat_counter = []
            formWarkat.listForm.map((e, i) => {
                warkat_counter.push(
                    {
                        id_bukuwarkat: 0,
                        jenis_warkat: e.jenis_warkat,
                        jumlah_lembar: parseInt(e.jumlah_lembar),
                        kode_warkat: e.kode_warkat,
                        nomor_seri_awal: parseInt(e.nomor_seri_awal),
                        nomor_seri_akhir: parseInt(e.nomor_seri_akhir),
                        status_warkat: 'W',
                        flag_lembar_buku: "B",
                        jenis_materai: e.jenis_materai,
                        id_barang: e.id_barang,
                    }
                )
            })
            var url = `/bukuwarkat/tambah-warkat-counter`;
            try {
                const { statusCode, status, remark, statusText, data = {} } = await doPost({
                    url: url,
                    service: "acc",
                    data: {
                        nama_rekening: dataWarkat.nama_rekening,
                        nomor_rekening: dataWarkat.nomor_rekening,
                        nama_produk: "",
                        harga_beli: formWarkat.total_jual,
                        harga_materai: formWarkat.total_materai,
                        nominal_fee: formWarkat.total_fee,
                        data_warkat_counter: warkat_counter
                    },
                    headers: {
                        "x-user-id": userInfo.preferred_username,
                        method: "POST"
                    }
                });

                if (statusCode === 200) {
                    if (status === "00") setDataAlert({ show: true, body: `Tambah Warkat Counter berhasil, Data perlu dilakukan otorisasi`, title: ``, header: "Success", callBack: () => toggleModalWarkat() });
                    else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
                } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            }
        }
    }

    async function ubahWarkat() {
        const userInfo = await keycloak.loadUserInfo();
        var url = `/bukuwarkat/ubah-status-warkat`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                method: "POST",
                data: {
                    nama_rekening: dataWarkat.nama_rekening,
                    nomor_rekening: dataWarkat.nomor_rekening,
                    kode_warkat: dataDetailWarkat.selected.kode_warkat,
                    id_bukuwarkat: dataDetailWarkat.selected.id_bukuwarkat,
                    jenis_warkat: dataDetailWarkat.selected.jenis_warkat,
                    nomor_seri: dataDetailWarkat.selected.nomor_seri,
                    status_warkat: dataDetailWarkat.selected.status_warkat_kode,
                },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Ubah Warkat berhasil, Data perlu dilakukan otorisasi`, title: ``, header: "Success", callBack: () => toggleModalWarkat() });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
    }

    async function updateHoldWarkat(id_barang, sts) {
        const userInfo = await keycloak.loadUserInfo();
        var url = `/bukuwarkat/update-hold-warkat?id_barang=${id_barang}&sts=${sts}`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                method: "POST",
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {

            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
    }

    // getBlokRek
    async function getBlokRekening(nomor_rekening, isOtorisasi, dbId) {
        setLoadingBlok(true)
        var url = `/hold-saldo/list/${nomor_rekening}`;
        if (isOtorisasi) url = `/blokir/otor-view/${nomor_rekening}`
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc"
            });
            // // // console.log(data);
            if (statusCode === 200) {
                if (status === "00") {
                    setdataBlokRekening(data)
                    // setFormHoldDana(data)
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingBlok(false)
    }
    // getBukaRek
    async function getBukaRekening(nomor_rekening, isOtorisasi, dbId) {
        setLoadingBuka(true)
        var url = `/tabungan-giro/${nomor_rekening}`;
        if (isOtorisasi) url = `/blokir/otor-view/${nomor_rekening}`
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: isOtorisasi ? "acc" : "cif"
            });
            // // // console.log(data);
            if (statusCode === 200) {
                if (status === "00") {
                    setdataBukaRekening(p => ({ ...p, ...data }))

                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingBuka(false)
    }

    //START TUTUP ACCOUNT
    // getTutupAccount
    async function getTutupAccount(nomor_rekening, isOtorisasi, dbId) {
        setloadingTutupAccount(true)
        // /acc/api/v1/tabungan-giro/info-for-close/{nomor_rekening}
        var url = `/tabungan-giro/info-for-close/${nomor_rekening}`;
        if (isOtorisasi) url = `/tabungan-giro/tutup-valid/otor-view/${nomor_rekening}`
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: isOtorisasi ? "acc" : "cif",
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    if (isOtorisasi) setdataTutupAccount(p => ({
                        ...data,
                        saldo: data.saldo_rekening
                    }))
                    else setdataTutupAccount(data)
                    // // // // console.log(data);
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setloadingTutupAccount(false)
    }

    // Post tutup account Valid
    async function tutuAccountValid() {
        // // // // console.log(dataTutupAccount)
        setLoading(l => ({ ...l, show: true }))
        const userInfo = await keycloak.loadUserInfo();

        var url = `/tabungan-giro/tutup-valid`;
        let body = {
            "nomor_rekening": dataTutupAccount.nomor_rekening,
            "nama_rekening": dataTutupAccount.nama_rekening,
            "produk_rekening": dataTutupAccount.nama_produk,
            "valuta_rekening": dataTutupAccount.valuta,
            "cabang_rekening": dataTutupAccount.cabang,
            "saldo_rekening": dataTutupAccount.saldo
        }
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                },
                data: body,
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: `Proses Tutup Account sedang di proses. silahkan melakukan proses otorisasi dan dilanjutkan  proses di teller. `, title: ``, header: "Success", callBack: () => {
                        closeConfirm()
                        closeTutupAccount()
                    }
                });
                else setDataAlert({
                    show: true, body: remark, title: `Info`, callBack: () => {
                        closeTutupAccount()
                    }
                });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        // await wait(3000)
        setLoading(l => ({ ...l, show: false }))
    }

    function confirmTutupAccount() {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Apakah anda yakin melanjutkan tutup account ini. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => tutuAccountValid(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    //END TUTUP ACCOUNT

    //START BATAL TUTUP ACCOUNT
    // getBatalTutupAccount
    async function getBatalTutupAccount(nomor_rekening, isOtorisasi, dbId) {
        setLoadingBuka(true)
        // /acc/api/v1/tabungan-giro/info-batal-tutup/{nomor_rekening}
        var url = `/tabungan-giro/info-for-close/${nomor_rekening}`;
        if (isOtorisasi) url = `/tabungan-giro/tutup-valid/otor-view/${nomor_rekening}`
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: isOtorisasi ? "acc" : "cif",
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    if (isOtorisasi) setdataBatalTutupAccount(p => ({
                        ...data,
                        saldo: data.saldo_rekening
                    }))
                    else setdataBatalTutupAccount(data)
                    // setdataBatalTutupAccount(data)
                    // // // console.log(data);
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingBuka(false)
    }

    // Post batalTutuAccountValid
    async function batalTutuAccountValid() {
        closeConfirm()
        openLoading();
        const userInfo = await keycloak.loadUserInfo();
        var url = `/tabungan-giro/batal-tutup`;
        // // // console.log(dataBatalTutupAccount)

        let body = {
            "nomor_rekening": dataBatalTutupAccount.nomor_rekening,
            "nama_rekening": dataBatalTutupAccount.nama_rekening,
            "produk_rekening": dataBatalTutupAccount.nama_produk,
            "valuta_rekening": dataBatalTutupAccount.valuta,
            "cabang_rekening": dataBatalTutupAccount.cabang,
            "saldo_rekening": dataBatalTutupAccount.saldo
        }
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: body,
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: `Proses Batal Tutup Account Berhasil`, title: ``, header: "Success", callBack: () => {
                        closeBatalTutupAccount()
                        closeConfirm()
                    }
                });
                else setDataAlert({
                    show: true, body: remark, title: ``, header: "Info", callBack: () => {
                        closeBatalTutupAccount()
                        closeConfirm()
                    }
                });
            } else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => closeConfirm() });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        // await wait(3000)
        closeLoading()
    }

    function confirmBatalTutupAccount() {
        let body = `Apakah anda yakin melanjutkan batal tutup account ini. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => batalTutuAccountValid(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    //END BATAL TUTUP ACCOUNT

    //START CETAK STATEMENT
    //getCetakStatement
    async function getCetakStatement(nomor_rekening, isOtorisasi = false, dbId) {
        setloadingCetakStatement(true)

        setReqStatement(true)
        setOtorMode(isOtorisasi)
        var url = `/tabungan-giro/detail-cetak-statement/${nomor_rekening}`;
        if (isOtorisasi) url = `/tabungan-giro/request-cetak-statement/${nomor_rekening}`
        try {
            const { statusCode, status, remark, statusText, data = {}, data_statement } = await doGet({
                url: url,
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    // // console.log(data)
                    setReqStatement(false)
                    if (isOtorisasi) {
                        setdataCetakStatement({
                            ...data,
                            periodeA: data.date_start,
                            periodeB: data.date_end,
                            nama_cabang: data.cabang_rekening
                        })
                    } else {
                        await getDetailStatement(data.nomor_rekening)
                        setdataCetakStatement(p => ({
                            ...p,
                            ...data,
                            trx: {
                                list: data_statement.listtrx,
                                debit: data_statement.total_mutasi_debet,
                                kredit: data_statement.total_mutasi_kredit,
                                saldoAwal: data_statement.saldo_awal,
                                saldoAkhir: data_statement.saldo_akhir,
                                npwp: data_statement.npwp
                            },
                            periodeA: data.date_start,
                            periodeB: data.date_end,
                            nama_cabang: data.cabang_rekening,
                            user_input: data.user_input,
                            user_otor: data.user_otor
                        }));
                    }
                    // setdataCetakStatement({ ...dataCetakStatement, ...data })
                }
                else {
                    if (!isOtorisasi) {
                        if (status === "02") {
                            setReqStatement(true)
                            getDetailStatement(nomor_rekening)
                        }
                    }
                    else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
                }
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        setloadingCetakStatement(false)
    }

    async function getDetailStatement(nomor_rekening) {
        setloadingCetakStatement(true)
        var url = `/tabungan-giro/${nomor_rekening}`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    // // // // console.log(data)
                    setdataCetakStatement(p => ({ ...p, ...dataCetakStatement, ...data }))
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        // await wait(3000)
        setloadingCetakStatement(false)
    }
    //END CETAK STATEMENT

    // //START CETAK NAMA
    // //getCetakStatement
    // async function getCetakNamaPasbook(nomor_rekening, isOtorisasi, dbId) {
    //     setloadingCetakStatement(true)
    //     var url = `/bukutabungan/get_buku_tabungan_by_nomor_rekening`;
    //     if (isOtorisasi) url = `/blokir/otor-view/${nomor_rekening}`
    //     try {
    //         const { statusCode, status, remark, statusText, data = {} } = await doGet({
    //             url: url,
    //             service: isOtorisasi ? "acc" : "acc",
    //             param: {
    //                 nomor_rekening: nomor_rekening
    //             }
    //         });

    //         if (statusCode === 200) {
    //             if (status === "00") {
    //                 // // // // console.log(data)
    //                 setdataCetakNama(data)
    //             }
    //             else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
    //         } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    //     } catch (error) {
    //         setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    //     }
    //     await wait(3000)
    //     setloadingCetakStatement(false)
    // }

    // async function postCetakPassbook(nomor_rekening) {
    //     var url = `/bukutabungan/cetak-nama-passbook`;
    //     try {
    //         const { statusCode, status, remark, statusText, data = {} } = await doPost({
    //             url: url,
    //             service: "acc",
    //             queryParam: {
    //                 nomor_rekening
    //             }
    //         });

    //         if (statusCode === 200) {
    //             if (status === "00") {
    //                 setDataAlert({
    //                     show: true, body: remark, title: `Berhasil`, header: "Berhasil", callBack: () => {
    //                         closeCetakNamaPrint()
    //                         closeLoading()
    //                         closeCetakNama()
    //                     }
    //                 });
    //             }
    //             else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
    //         } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    //     } catch (error) {
    //         setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    //     }
    // }
    // async function getCetakNamaPrint(nomor_rekening, rekStatus) {
    //     setLoadingDetil(true)
    //     var url = `/tabungan-giro/${nomor_rekening}`;
    //     try {
    //         const { statusCode, status, remark, statusText, data = {} } = await doGet({
    //             url: url,
    //             service: "cif"
    //         });

    //         if (statusCode === 200) {
    //             if (status === "00") {
    //                 // // // console.log(data)
    //                 setdataCetakNamaPrint({
    //                     ...data,
    //                     // status: rekStatus 
    //                 })
    //                 try {
    //                     openLoading()
    //                     const res = await print({
    //                         command: "PRINT",
    //                         formname: "PassbookCover_BJBS",
    //                         medianame: "BJBS",
    //                         id: `${uniqueId()}${data.nomor_rekening}`,
    //                         print: 1,
    //                         history: 1,
    //                         namaCover: data.nama_lengkap,
    //                         alamat1: data.alamat.substring(0, 30), //nm jalan
    //                         alamat2: data.alamat.substring(31, 62), // kterngan
    //                         alamat3: data.alamat.substring(63, 94), // kab. pos
    //                         norekeningCover: data.nomor_rekening,
    //                         Cabang: data.nama_cabang,
    //                         TanggalCover: moment(Date.now()).format("DD/MM/YYYY")
    //                     });
    //                     if (res.statusCode === 500) {
    //                         setDataAlert({ show: true, body: res.remark, title: `${res.statusText}`, header: "Error", callBack: () => null });
    //                     } else {
    //                         setDataAlert({
    //                             show: true, body: res.remark, title: `${res.statusText}`, header: "Berhasil", callBack: () => {
    //                                 postCetakPassbook(data.nomor_rekening)
    //                             }
    //                         });
    //                     }
    //                 } catch (err) {
    //                     setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });

    //                 }
    //                 closeLoading()
    //             }
    //             else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
    //         } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    //     } catch (error) {
    //         setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    //     }
    //     await wait(3000)
    //     setLoadingDetil(false)
    // }

    // // open Cetak Nama Print
    // function openCetakTransakasiPrint() {
    //     const dataCetak = [
    //         {
    //             TANGGAL: "12/11/2021",
    //             KODE: "TES",
    //             NILAI_MUTASI_DEBET: "2000000",
    //             NILAI_MUTASI_KREDIT: "",
    //             SALDO: "30400000",
    //             USER: "ISI",
    //             BARIS: 1,
    //         },
    //         {
    //             TANGGAL: "12/11/2021",
    //             KODE: "TES",
    //             NILAI_MUTASI_KREDIT: "2000000",
    //             NILAI_MUTASI_DEBET: "",
    //             SALDO: "30400000",
    //             USER: "ISI",
    //             BARIS: 2,
    //         },
    //         {
    //             TANGGAL: "12/11/2021",
    //             KODE: "TES",
    //             NILAI_MUTASI_DEBET: "2000000",
    //             NILAI_MUTASI_KREDIT: "",
    //             SALDO: "30400000",
    //             USER: "ISI",
    //             BARIS: 12,
    //         },
    //         {
    //             TANGGAL: "12/11/2021",
    //             KODE: "TES",
    //             NILAI_MUTASI_KREDIT: "2000000",
    //             NILAI_MUTASI_DEBET: "",
    //             SALDO: "30400000",
    //             USER: "ISI",
    //             BARIS: 13,
    //         },
    //     ];

    //     // let dc = {
    //     //     command: "PRINT",
    //     //     medianame: "BJBS",
    //     //     formname: "Passbook_BJBS",
    //     //     orientation: "1",
    //     //     print: 1,
    //     //     history: 1,
    //     //     id: uniqueId(),
    //     // };

    //     // dataCetak.forEach(({
    //     //     TANGGAL = "", KODE = "", NILAI_MUTASI_DEBET = "", NILAI_MUTASI_KREDIT = "", SALDO = "", USER = "", BARIS = 1
    //     // }) => dc = {
    //     //     ...dc,
    //     //     [`GEN!PVHVFR${String(BARIS).padStart(2, "0")}`]: TANGGAL,
    //     //     [`AVH!NA1${String(BARIS).padStart(2, "0")}`]: KODE,
    //     //     [`GEN!PVHWID${String(BARIS).padStart(2, "0")}`]: NILAI_MUTASI_DEBET,
    //     //     [`GEN!PVHDEP${String(BARIS).padStart(2, "0")}`]: NILAI_MUTASI_KREDIT,
    //     //     [`GEN!PVHBAL${String(BARIS).padStart(2, "0")}`]: SALDO,
    //     //     [`DZX!OPID${String(BARIS).padStart(2, "0")}`]: USER,
    //     // })

    //     // print(dc)
    //     setmodalCetakTransaksi(false)
    //     postCetakTransaksiPrint()
    //     // setmodalCetakTransaksiPrint(true)
    // }

    // async function postCetakTransaksiPrint(d = null) {
    //     openLoading()
    //     var url = `/rpc/passbook`;
    //     let x = d
    //     // // // // console.log(data)
    //     if (x === null) {
    //         x = {

    //             ...dataCetakTransaksi,
    //             id_cetak_awal: parseInt(dataCetakTransaksi.id_cetak_awal),
    //             id_cetak_akhir: parseInt(dataCetakTransaksi.id_cetak_akhir),
    //             mulai_baris: parseInt(dataCetakTransaksi.mulai_baris),
    //         }
    //     }
    //     try {
    //         const { statusCode, status, remark, statusText, data = {} } = await doPost({
    //             url: url,
    //             service: "rpc",
    //             data: x,
    //         });
    //         if (statusCode === 200) {
    //             if (status === "00") {
    //                 // // // console.log(data)
    //                 setdataCetakTransaksiPrint(data)
    //                 setmodalCetakTransaksiPrint(true)
    //             }
    //             else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
    //         }
    //         else setDataAlert({ show: true, body: remark, header: "PERINGATAN", callBack: () => null });
    //     } catch (error) {
    //         setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    //     }
    //     await wait(3000)
    //     closeLoading()
    // }


    // async function doCetakTrxPassbook() {
    //     setmodalCetakTransaksiPrint(false)
    //     openLoading();

    //     let dc = {
    //         command: "PRINT",
    //         medianame: "BJBS",
    //         formname: "Passbook_BJBS",
    //         orientation: "1",
    //         print: 1,
    //         history: 1,
    //         id: uniqueId(),
    //     };

    //     (dataCetakTransaksiPrint.dttrx || []).forEach(({
    //         TANGGAL = "", KODE = "", NILAI_MUTASI_DEBET = "", NILAI_MUTASI_KREDIT = "", SALDO = "", USER = "", BARIS = 1
    //     }) => dc = {
    //         ...dc,
    //         [`GEN!PVHVFR${String(BARIS).padStart(2, "0")}`]: TANGGAL,
    //         [`AVH!NA1${String(BARIS).padStart(2, "0")}`]: KODE,
    //         [`GEN!PVHWID${String(BARIS).padStart(2, "0")}`]: NILAI_MUTASI_DEBET,
    //         [`GEN!PVHDEP${String(BARIS).padStart(2, "0")}`]: NILAI_MUTASI_KREDIT,
    //         [`GEN!PVHBAL${String(BARIS).padStart(2, "0")}`]: SALDO,
    //         [`DZX!OPID${String(BARIS).padStart(2, "0")}`]: USER,
    //     })

    //     try {
    //         const dataResponse = await print(dc);
    //         if (dataResponse.statusCode === 500) {
    //             closeLoading()
    //             return setDataAlert({ show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Error", callBack: () => null });
    //         } else {
    //             closeLoading()
    //             setDataAlert({
    //                 show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Berhasil", callBack: () => {
    //                     if (dataCetakTransaksiPrint.is_need_newpage === "T") {
    //                         closeAlert();
    //                         setdataCetakTransakasi(p => ({
    //                             ...p,
    //                             mulai_baris: 1
    //                         }))
    //                         return setDataAlert({
    //                             show: true, body: "Lanjutkan cetak transaksi pasbook ke halaman berikutnya.", title: `Next Page`, header: "Info", callBack: () => {
    //                                 postCetakTransaksiPrint({
    //                                     ...dataCetakTransaksi,
    //                                     mulai_baris: 1
    //                                 });
    //                                 closeAlert();
    //                             }
    //                         });
    //                     } else {
    //                         setdataCetakTransakasi({
    //                             "nomor_rekening": "",
    //                             "id_cetak_awal": 0,
    //                             "id_cetak_akhir": 0,
    //                             "is_cetak_ulang": "F",
    //                             "is_all_data": "T",
    //                             "mulai_baris": 0
    //                         });
    //                         setdataCetakTransaksiPrint({});
    //                         closeAlert();
    //                         closeLoading();
    //                         return setDataAlert({
    //                             show: true, body: "Cetak transaksi pasbook selesai.", title: ``, header: "Info", callBack: () => null
    //                         })
    //                     }
    //                 }
    //             });
    //         }
    //     } catch (error) {
    //         closeLoading()
    //         return setDataAlert({
    //             show: true, body: "Gagal koneksi ke print server. Periksa kembali koneksi print server", title: ``, header: "Info", callBack: () => {
    //                 closeAlert();
    //                 closeLoading();
    //                 setdataCetakTransakasi({
    //                     "nomor_rekening": "",
    //                     "id_cetak_awal": 0,
    //                     "id_cetak_akhir": 0,
    //                     "is_cetak_ulang": "F",
    //                     "is_all_data": "T",
    //                     "mulai_baris": 0
    //                 });
    //             }
    //         });
    //     }
    // }


    // //START CETAK TRANSAKSI
    // //getCetakStatement
    // async function getCetakTransaksiPasbook(nomor_rekening, isOtorisasi) {
    //     setloadingCetakStatement(true)
    //     var url = `/rpc/lastline/${nomor_rekening}`;
    //     try {
    //         const { statusCode, status, remark, statusText, data = {}, lastline = 0, nomor_buku_terakhir = "" } = await doGet({
    //             url: url,
    //             service: "rpc"
    //         });

    //         if (statusCode === 200) {
    //             // // console.log(data)
    //             if (status === "00") {
    //                 setdataCetakTransakasi(p => ({
    //                     ...p,
    //                     nomor_rekening,
    //                     mulai_baris: parseInt(lastline >= 28 ? 0 : lastline) + 1,
    //                     reg_buku: nomor_buku_terakhir
    //                 }))
    //             }
    //             else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
    //         } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    //     } catch (error) {
    //         setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    //     }
    //     // await wait(3000)
    //     setloadingCetakStatement(false)
    // }
    // //END CETAK TRANSAKSI


    // async function getTrxCetakUlang(nomor_rekening = "", from = "", to = "") {
    //     if ((
    //         from === "" ||
    //         from === null ||
    //         from === undefined
    //     ) || (
    //             to === "" ||
    //             to === null ||
    //             to === undefined
    //         )) setDataAlert({ show: true, body: "Masukan tanggal terlebih dahulu", title: `validasi`, callBack: () => null });
    //     openLoading()
    //     var url = `/rpc/reprint`;
    //     try {
    //         const { statusCode, status, remark, statusText, data = {} } = await doPost({
    //             url: url,
    //             service: "rpc",
    //             data: {
    //                 "nomor_rekening": nomor_rekening,
    //                 "start_date": moment(from).format("MM/DD/YYYY"),
    //                 "end_date": moment(to).format("MM/DD/YYYY"),
    //             }
    //         });

    //         if (statusCode === 200) {
    //             if (status === "00") {
    //                 setDataCetakUlang(data)
    //             }
    //             else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
    //         } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    //     } catch (error) {
    //         setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    //     }
    //     // await wait(3000)
    //     closeLoading()
    // }
    // getaktifasidormant
    async function getAktifasiDormant(nomor_rekening, isOtorisasi, dbId) {
        //// // // console.log(nomor_rekening, isOtorisasi)
        setLoadingAktDor(true)
        var url = `/hold-saldo/list/${nomor_rekening}`;
        if (isOtorisasi) url = `/dormant/otor-view/${nomor_rekening}`
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "acc"
            });

            // // // // console.log(data)

            if (statusCode === 200) {
                if (status === "00") {
                    if (isOtorisasi) {
                        getAktifasiDormant(data.nomor_rekening, false)
                    } else {
                        setdataAktifasiDormant(data)
                    }

                    // setFormHoldDana(data)
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingAktDor(false)
    }
    //get tanggal POD
    async function getPOD() {
        let resp = await doGet({
            url: "/parameterglobal/pg-by-code",
            service: "trx",
            param: {
                kode_pg: "POD"
            }
        })
        // console.log(resp)
        if (resp.statusCode === 200) {
            if (resp.message_id === "00") {
                setPodDate(resp.data?.nilai_parameter_tanggal || "")
            }
        }
    }

    // reset filter hist
    function resetFilterHist() {
        let kurangiTujuhHari = moment(podDate).subtract(7, 'days');
        setFilterHist({
            date_start: moment(kurangiTujuhHari).format("YYYY-MM-DD"), date_end: moment(podDate).format("YYYY-MM-DD")
        })
    }

    // reset pagination hist
    function resetPaginationHist() {
        setPaginationHist({ page: 1, show: 15 })
    }


    // reset pagination hist
    function resetPagination() {
        setPagination({ page: 1, show: 15 })
    }


    // next paginationhist
    function nextPageHist({ page, limit }) {
        setPaginationHist({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPageHist({ page, limit }) {
        setPaginationHist({
            page,
            show: limit
        })
    }

    // change show
    function changeShowHist({ page, limit }) {
        setPaginationHist({
            page,
            show: limit
        })
    }

    // useEffectChange
    useEffect(function () {
        if (!isOtor && rekSelected.nomor_rekening !== null && rekSelected.nomor_rekening !== undefined && rekSelected.nomor_rekening !== "") getHistory()
    }, [paginationHist, rekSelected.nomor_rekening])

    // confirm Hold
    function confirmHold(status) {
        let body = `Apakah anda yakin akan ${unholdMode ? "unhold" : "Hold"} Dana nasabah ini. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => unholdMode ? doUnhold() : registerHold(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    // function register hold
    async function registerHold() {

        closeConfirm();
        const userInfo = await keycloak.loadUserInfo();
        if (
            (formHoldDana.nominal_hold === "" || formHoldDana.nominal_hold === null) ||
            (formHoldDana.tanggal_hold === "" || formHoldDana.tanggal_hold === null) ||
            (formHoldDana.tanggal_kadaluarsa === "" || formHoldDana.tanggal_kadaluarsa === null) ||
            (formHoldDana.alasan_hold === "" || formHoldDana.alasan_hold === null) ||
            (formHoldDana.blokir_regulator === "" || formHoldDana.blokir_regulator === null)
            // (formHoldDana.nomor_referensi === "" || formHoldDana.nomor_referensi === null)
        ) {
            return setDataAlert({ show: true, body: "Tolong lengkapi form", title: `Form belum lengkap`, header: "Warning", callBack: () => null });
        }
        openLoading()
        var url = `/hold-saldo`;
        // if(modeEditHold) url = 
        // {
        //     "nomor_rekening": "string",
        //     "nama_rekening": "string",
        //     "produk_rekening": "string",
        //     "valuta_rekening": "string",
        //     "cabang_rekening": "string",
        //     "saldo_rekening": 0,
        //     "nominal_hold": 0,
        //     "tanggal_hold": "string",
        //     "tanggal_kadaluarsa": "string",
        //     "blokir_regulator": "string",
        //     "alasan_hold": "string",
        //     "id_hold_core": 0
        //   }
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: {
                    ...formHoldDana,
                    nomor_rekening: dataHoldDana.nomor_rekening,
                    nama_rekening: dataHoldDana.nama_rekening,
                    produk_rekening: dataHoldDana.nama_produk,
                    valuta_rekening: dataHoldDana.valuta,
                    cabang_rekening: dataHoldDana.cabang,
                    saldo_rekening: dataHoldDana.saldo,
                    ...(modeEditHold ? { id_hold_core: formHoldDana.id_hold_dana } : {})
                },
                method: modeEditHold ? "PUT" : "POST",
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: `Proses ${modeEditHold ? "Ubah" : "Registrasi"} Hold Dana Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => {
                        // jAction.resetData();
                        // setFormHoldDanaU(formHoldDana);
                        resetFormHold();
                        toggleModalMode();
                        getHoldDana(dataHoldDana.nomor_rekening)
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    // useEffect(() => {
    //     // // console.log(formBlokRekening)

    // }, [formBlokRekening])
    // function register blok
    function confirmRegisBlok() {
        let body = `Apakah anda yakin akan blokir rekening nasabah ini ?`;
        setDataConfirm({
            show: true,
            body,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => registerBlok(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    async function registerBlok() {
        closeConfirm();
        const userInfo = await keycloak.loadUserInfo();
        if (
            (formBlokRekening.alasan_blokir === "" || formBlokRekening.alasan_blokir === null) ||
            (formBlokRekening.jenis_blokir === "" || formBlokRekening.jenis_blokir === null || formBlokRekening.jenis_blokir === undefined)
        ) {
            return setDataAlert({ show: true, body: "Tolong lengkapi form", title: `Form belum lengkap`, header: "Warning", callBack: () => null });
        }
        openLoading()
        var url = `/blokir`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: {
                    ...formBlokRekening,
                    nomor_rekening: dataBlokRekening.nomor_rekening,
                    nama_rekening: dataBlokRekening.nama_rekening,
                    produk_rekening: dataBlokRekening.nama_produk,
                    valuta_rekening: dataBlokRekening.valuta,
                    cabang_rekening: dataBlokRekening.cabang,
                    saldo_rekening: dataBlokRekening.saldo,
                },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: `Proses Blokir Rekening Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => {
                        closeBlokRek()
                        setdataBlokRekening(p => ({
                            ...p,
                            "alasan_blokir": "",
                            "jenis_blokir": "",
                        }));
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        closeLoading()
    }
    // function unholdDana
    async function doUnhold() {

        closeConfirm();

        const userInfo = await keycloak.loadUserInfo();

        openLoading()
        var url = `/hold-saldo/unhold`;
        // // // console.log(formHoldDana);
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data:
                {
                    ...formHoldDana,
                    id_hold_core: formHoldDana.id_hold_dana,
                    nomor_rekening: formHoldDana.nomor_rekening,
                    nama_rekening: formHoldDana.nama_rekening,
                    produk_rekening: formHoldDana.nama_produk,
                    valuta_rekening: formHoldDana.valuta,
                    cabang_rekening: formHoldDana.cabang,
                    saldo_rekening: formHoldDana.saldo,
                    nominal_hold: formHoldDana.nominal_hold,
                    tanggal_hold: formHoldDana.tanggal_hold,
                    tanggal_kadaluarsa: formHoldDana.tanggal_kadaluarsa,
                    alasan_hold: formHoldDana.alasan_hold,
                    blokir_regulator: formHoldDana.blokir_regulator,
                },
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            });
            // // // console.log(data);
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: `Proses Unhold Dana Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => {
                        resetFormHold();
                        toggleModalMode();
                        getHoldDana(dataHoldDana.nomor_rekening)
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        closeLoading()
    }


    function unHoldDana(id) {
        toggleModalMode()
        setUnholdMode(true)
        getHoldDana(id, false, "", true)
    }
    function confirmRegisBukaBlok() {
        let body = `Apakah anda yakin akan buka blokir nasabah ini ?`;
        setDataConfirm({
            show: true,
            body,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => registerBuka(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // function register buka
    async function registerBuka() {
        closeConfirm()
        const userInfo = await keycloak.loadUserInfo();
        // if(
        //     (formBlokRekening.alasan_blokir === "" || formBlokRekening.alasan_blokir === null)
        // ){
        //     return setDataAlert({ show: true, body: "Tolong lengkapi form", title: `Form belum lengkap`, header: "Warning", callBack: () => null });
        // }
        // // console.log(dataBukaRekening)
        openLoading()
        var url = `/blokir/buka`;
        let x = {
            ...dataBukaRekening,
            nomor_rekening: dataBukaRekening.nomor_rekening,
            nama_rekening: dataBukaRekening.nama_rekening,
            produk_rekening: dataBukaRekening.nama_produk,
            valuta_rekening: dataBukaRekening.valuta,
            // cabang_rekening: dataBukaRekening.kode_cabang,
            saldo_rekening: dataBukaRekening.saldo,
            alasan_blokir: dataBukaRekening.keterangan_blokir,
            jenis_blokir: dataBukaRekening.jenis_blokir,
        }

        // // console.log(x)
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: x,
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });
            // // // console.log(data)

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Buka Rekening Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => closeBukaRek() });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });

            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        closeLoading()
    }


    function confirmRegisDormant() {
        let body = `Apakah anda yakin akan aktivasi dormant nasabah ini ?`;
        setDataConfirm({
            show: true,
            body,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => registerAktifasiDormant(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    // function aktifasi dormant
    async function registerAktifasiDormant() {
        closeConfirm()
        const userInfo = await keycloak.loadUserInfo();
        // if(
        //     (formBlokRekening.alasan_blokir === "" || formBlokRekening.alasan_blokir === null)
        // ){
        //     return setDataAlert({ show: true, body: "Tolong lengkapi form", title: `Form belum lengkap`, header: "Warning", callBack: () => null });
        // }
        openLoading()
        var url = `/dormant`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: {
                    ...formBlokRekening,
                    nomor_rekening: dataAktifasiDormant.nomor_rekening,
                    nama_rekening: dataAktifasiDormant.nama_rekening,
                    produk_rekening: dataAktifasiDormant.nama_produk,
                    valuta_rekening: dataAktifasiDormant.valuta,
                    cabang_rekening: dataAktifasiDormant.cabang,
                    saldo_rekening: dataAktifasiDormant.saldo,
                },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Aktivasi Dormant Rekening Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => closeAktifasiDormant() });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });

        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        closeLoading()
    }
    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} nasabah ini ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // useFetch(function(){
    //     // // console.log(dataCetakTransaksi)
    // }, [dataCetakTransaksi])

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        openLoading();
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (dataOtor !== null && dataOtor !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "", message = "" } = await doPost({
                    url: `/otorisasi/${dataOtor.kode_entry}/${dataOtor.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "acc",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({ show: true, body: remark, title: ``, header: "Success", callBack: () => dataOtor.callBackSuccess() });
                    else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => dataOtor.callBackSuccess() });
                } else setDataAlert({ show: true, body: `${remark}. ${message}`, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => dataOtor.callBackSuccess() });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => dataOtor.callBackSuccess() });

        closeLoading()
    }

    //Pilih NASABAH JOIN ACCOUNT
    const [formData, setFormData] = useState({
        "encodedKey": "",
        "state": "",
        "accountHolderType": "", //
        "namaRekening": "",
        "namaNasabah": "",
        "accountHolderKey": "", //
        "productTypeKey": "", //
        "productType": "", //
        "nomorCIF": "",
        "nomorRekening": "", //
        "produkTabungan": "",
        "keterangan": "",
        "saldo": 0,
        "valuta": "",
        "rejectionNote": "", //
        "_informasiLain": {
            "nisbahCounter": "0",//nisbah spesial
            "nisbahZakat": "0",//zakat bagi hasil
            "nisbahPajak": "0",//pajak bagi hasil
            "nisbahAkhir": "0",//nisbah produk
            "qqRekening": "0",
            "qqName": "0",
            "biayaBiaya": "",
            "sumberDana": "",
            "tncVersion": "", //
            "tujuanPembukaanRekening": "",
            "kelengkapanData": "", //
            "marketingReferensi": "",//marketing inisiasi
            "marketingPertama": "", //
            "marketingSaatIni": "",//Marketing Maintenance
            "rencanaAsuransiUtama": "", //
            "tanggalJatuhTempo": "", //
            "rencanaAsuransiTambahan": "" //
        },
        "_dataNasabah": {
            "homePhone": "homePhone",
            "mobilePhone": "mobilePhone",
            "nama_ibu_kandung": "", //
            "tanggalLahir": "", //
            "tempatLahir": "", //
            "alamat": "" // 
        },

    });

    async function pilihNasabah(dataNasabah, tipe) {
        setLoading(prevState => ({ ...prevState, loading: true }));
        let url = `/nasabah/${dataNasabah.nomor_cif}`;
        if (parseInt(tipe) === 1) url = `/nasabah-badan-usaha/${dataNasabah.nomor_cif}`;

        try {
            const resp = await doGet({
                url,
                service: "cif"
            });
            const { status, statusText, data, statusCode, remark } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    const { nasabahBadanUsaha } = data
                    let homePhone = nasabahBadanUsaha?.homePhone;
                    let mobilePhone = nasabahBadanUsaha?.mobilePhone;
                    let alamat = dataNasabah.alamat.toUpperCase()

                    if (tipe === 1) {
                        homePhone = nasabahBadanUsaha?.nasabahTeleponKantorKodeArea1 + nasabahBadanUsaha?.nasabahTeleponKantorNomor1;
                        mobilePhone = "";
                    }
                    if (actionMode === "JA") jAction.addList({
                        "nomor_nasabah": dataNasabah.nomor_cif,
                        "nama_nasabah": dataNasabah.nama.toUpperCase(),
                        "nama_ibu_kandung": nasabahBadanUsaha.personalMothersName,
                        "tanggal_lahir": nasabahBadanUsaha.birthDate,
                        "tempat_lahir": nasabahBadanUsaha.birthPlace,
                        "alamat": alamat
                    })
                    else setFormData(prevState => ({
                        ...prevState,
                        namaNasabah: dataNasabah.nama.toUpperCase(),
                        namaRekening: dataNasabah.nama.toUpperCase(),
                        nomorCIF: dataNasabah.nomor_cif,
                        _dataNasabah: {
                            ...prevState._dataNasabah,
                            homePhone,
                            mobilePhone,
                            alamat
                        }
                    }));
                    return true;
                } else {
                    setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
                    setLoading(prevState => ({ ...prevState, loading: false }))
                    return false;
                }
            } else {
                setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
                setLoading(prevState => ({ ...prevState, loading: false }))
                return false;
            }
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            setLoading(prevState => ({ ...prevState, loading: false }))
            return false;
        }

    }

    // // fungsi setDataForm
    // function changeForm_cetak(value, key, subkey = null) {
    //     // value = value.toUpperCase()
    //     if (typeof value === "string") value = value.toUpperCase()
    //     if (subkey !== null) setdataCetakTransakasi(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
    //     else setdataCetakTransakasi(prevState => ({ ...prevState, [key]: value }));
    // }

    function editHoldDana(id) {
        setUnholdMode(false)
        toggleModalMode()
        getHoldDana(id, false, "", true)
    }


    const [listKodeCabang, setListKodeCabang] = useState([]);

    async function getListKodeCabang() {
        try {
            let resp = await doGet({
                url: `/rpc/list-kode-cabang`,
                service: "rpc",
            });
            const { statusCode, remark = "", data = [], status = "01", statusText = "", message = "" } = resp
            // // console.log(resp)
            if (statusCode === 200) {
                if (status === "00") setListKodeCabang(data)
                else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => dataOtor.callBackSuccess() });
            } else setDataAlert({ show: true, body: `${remark}. ${message}`, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => dataOtor.callBackSuccess() });
        }
    }


    //Post Auditrail
    async function auditTrail() {
        const userInfo = await keycloak.loadUserInfo();
        // console.log(userInfo)
        var url = `/useraudit/user-audit-trail`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: {
                    user_id: userInfo.preferred_username,
                    app_id: "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Get Data List",
                    event_description: "Tabungan & Giro/Data Rekening",
                    info1: "-",
                    info2: "-"
                },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

    }

    //Cek Nomor Referensi
    async function cekNoReferensi(nomor_rekening, nomor_referensi) {
        openLoading("Checking nomor referensi...")
        try {
            const resp = await doGet({
                url: `/hold-saldo/validasi-nomor-referensi-holddana?nomor_rekening=${nomor_rekening}&nomor_referensi=${nomor_referensi}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode !== 200) {
                if (status !== "00") {
                    setDataAlert({ show: true, body: remark, title: `Nomor referensi tidak boleh sama`, header: "Warning", callBack: () => setFormData(prevState => ({ ...prevState, nomor_referensi: "" })) });
                }

            }
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }

    const [ dataTag, setDataTag] = useState([])
    useEffect(function () {
        (async ()=>{

            try {
                const { data = [], statusCode, status, remark, statusText } = await doGet({
                    url: `/rekening/list-tag-rekening`,
                    service: "acc"
                });
                if (statusCode === 200) {
                    if (status === "00") setDataTag(data ||[])
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data produk`, header: "Error", callBack: () => null });
            }
    
        })()
    }, [])

    const [modalKartuVirtual, setModalKartuVirtual] = useState(false);

    function confirmKartuVirtual(noReKartu) {
        let body = `Apakah anda yakin melanjutkan Request No. Kartu Virtual. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => postKartuVirtual(noReKartu), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    function openModalKartuVirtual(nomorRekening){
        setModalKartuVirtual(true)
        getDetail(nomorRekening.nomor_rekening)
    }

    function closeModalKartuVirtual(){
        setModalKartuVirtual(false)
    }
    
    async function postKartuVirtual(noReKartu) {
        closeConfirm()
        closeModalKartuVirtual()
        setLoadingHist(true)
        var url = `/card/req-virtual-card`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                // queryParam: {
                //     ...filterHist,
                //     ...paginationHist,
                //     nomor_rekening: rekSelected.nomor_rekening
                // },
                headers: {
                    method: "POST"
                },
                data : {
                    "trx_date_time" : moment(Date.now()).format("YYYYMMDDHHmmss"),
                    "account_no" : noReKartu
                }
            });

            if (statusCode === 200) {
                if (status === "00") {
                    // setDataHist(data.list)
                    setDataAlert({ show: true, body: `No Kartu Virtual : ${data.card_no}`, title: remark, callBack: () => null });
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            }
            // else if(statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoadingHist(false)
    }

    return <rekContext.Provider
        value={{
            rekState: {
                aktifasiDormantModalMode,
                blokRekModalMode,
                bukaRekModalMode,
                dataAktifasiDormant,
                // dataAlert,
                dataBatalTutupAccount,
                dataBlokRekening,
                dataBukaRekening,
                // dataCetakNama,
                // dataCetakNamaPrint,
                dataCetakStatement,
                // dataCetakTransaksi,
                // dataCetakTransaksiPrint,
                // dataConfirm,
                dataDetailWarkat,
                dataHist,
                dataHoldDana,
                dataListRekening,
                dataOtor,
                dataTutupAccount,
                dataWarkat,
                eBlokirRegulator,
                eJenisMaterai,
                eJenisWarkat,
                eStatusWarkat,
                filter,
                filterHist,
                formData,
                formHoldDana,
                formWarkat,
                holdModalMode,
                initFormWarkat,
                isLoading,
                jState,
                // loading,
                loadingAktDor,
                loadingBatalTutupAccount,
                loadingBlok,
                loadingBuka,
                loadingCetakStatement,
                loadingDetil,
                loadingHist,
                loadingHold,
                loadingListTrx,
                loadingTutupAccount,
                loadingWarkat,
                modalAktifasiDormant,
                modalBatalTutupAccount,
                modalBlokirRekening,
                modalBukaRekening,
                // modalCetakNama,
                // modalCetakNamaPrint,
                modalCetakStatement,
                // modalCetakTransaksi,
                // modalCetakTransaksiPrint,
                modalDetailRekening,
                modalDetailRencana,
                modalHoldDana,
                modalJoinAccount,
                modalTutupAccount,
                modalWarkat,
                modalWarkatList,
                otorDesc,
                otorMode,
                paginationHist,
                rekSelected,
                warkatAktivasiModalMode,
                warkatBukuModalMode,
                warkatDataModalMode,
                warkatEditModalMode,
                warkatLihatModalMode,
                listDataWarkat,
                //dataCetakTransaksiPrint,
                pagination,
                unholdMode,
                modeEditHold,
                //dataOtor,
                // dataCetakUlang,
                formBlokRekening,
                modeReqStatement,
                listKodeCabang,
                wFilter,
                dataTag,
                modalKartuVirtual,
                dataInstansi
            },
            rekAction: {
                aktivasiWarkat,
                // changeForm_cetak,
                changeShow,
                changeShowHist,
                closeAktifasiDormant,
                closeAlert,
                closeBatalTutupAccount,
                closeBlokRek,
                closeBukaRek,
                // closeCetakNama,
                // closeCetakNamaPrint,
                closeCetakStatement,
                // closeCetakTransaksi,
                // closeCetakTransaksiPrint,
                closeConfirm,
                closeDetailRekening,
                closeDetailRencana,
                closeHoldDana,
                closeTutupAccount,
                closeWarkat,
                closeWarkatList,
                confirmBatalTutupAccount,
                confirmTutupAccount,
                getBlokRekening,
                getDataList,
                getDataListStatement,
                getWarkatList,
                getDetailWarkat,
                getHistory,
                //getHistory,
                getHoldDana,
                getInfoWarkat,
                getWarkat,
                jAction,
                nextPage,
                nextPageHist,
                openAktifasiDormant,
                openBatalTutupAccount,
                openBlokRek,
                openBukaRek,
                // openCetakNamaPasbook,
                // openCetakNamaPrint,
                openCetakStatement,
                // openCetakTransakasiPrint,
                // openCetakTransaksiPasbook,
                openDetailRekening,
                openDetailRencana,
                openHoldDana,
                openJoinAccount,
                openTutupAccount,
                openWarkat,
                pilihNasabah,
                prevPage,
                prevPageHist,
                registerAktifasiDormant,
                registerBlok,
                registerBuka,
                registerHold,
                unHoldDana,
                resetFilter,
                resetFilterHist,
                responseOtor,
                setDataAlert,
                setdataCetakStatement,
                setDataDetailWarkat,
                setFilter,
                setFilterHist,
                setformBlokRekening,
                setFormHoldDana,
                resetFormHold,
                setFormWarkat,
                setOtorDesc,
                tambahWarkat,
                tambahWarkatCounter,
                toggleModalMode,
                toggleModalWarkat,
                tutuAccountValid,
                ubahWarkat,
                resetPagination,
                confirmRegisDormant,
                confirmHold,
                editHoldDana,
                setModalWarkatList,
                setLoadingListTrx,
                //doCetakTrxPassbook,
                // setDataCetakUlang,
                // getTrxCetakUlang,
                confirmRegisBlok,
                confirmRegisBukaBlok,
                resetBlokRekening,
                getListKodeCabang,
                setListDataWarkat,
                auditTrail,
                podDate,
                getPOD,
                updateHoldWarkat,
                cekNoReferensi,
                setWFilter,
                getWarkat,
                openModalKartuVirtual,
                closeModalKartuVirtual,
                confirmKartuVirtual

            }, jState, jAction
        }}
    >
        {children}
    </rekContext.Provider>;
}


export default function useRekening() { return useContext(rekContext) };
