import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AppContext } from '../../../../app.data';
import useFetch from '../../../../hooks/useFetch';
import useRekening from '../../cs/tabungan-giro/data_rekening/useRekening';
import useDeposito from '../../cs/deposito/data_deposito/useDeposito';
import useSVS from '../../nasabah_cif/svs/useSvs';
// import { useKeycloak } from '@react-keycloak/web';
import { useModalAlert } from '../../../controls/modalAlert';
import useDataAft from '../../cs/tabungan-giro/data-aft/useDataAft';
import useModalDetilRev from '../../teller/reversal_transaksi/component/detailReversal';
import useRegitrasiTeller from '../../supervisor_teller/informasi_teller/useInformasiTeller';
import useKeycloak from '../../../../hooks/keycloak.module';
import useDataBuku from '../../cs/tabungan-giro/data-buku-tabungan/useDataBuku';
const otorContext = createContext(null)

export const OtorProvider = ({children}) => {
    // const {s} = useContext(AppContext);
    const history = useHistory();
    const { doGet } = useFetch();
    const [dataOtor, setDataOtor] = useState([]);
    const [loadingGetData, setLoadingGetData] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setShow] = useState(15);
    const [kodeEntries, setKodeEntries] = useState([])
    const [ pagination, setPagination] = useState({
        page:1,
        limit: 15
    });
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [ filter, setFilter ] = useState({
        kode_entry : ""
    });
    const paramUrl = useParams()
    const { mtState, mtAction } = useModalDetilRev()
    // const
    const { rekState, rekAction } = useRekening();
    const { aftAction, aftState } = useDataAft();
    const {rekState:depoState,rekAction:depoAction} = useDeposito()
    const {svsAction,svsState} = useSVS()
    const { rtState, rtAction } = useRegitrasiTeller();
    const { nfState, nfAction } = useDataBuku()

    //
    const { keycloak } = useKeycloak()
    // function get DataList
    async function getData() {
        setLoadingGetData(true)
        
        setDataOtor([])
        let url = "";
        let service = "";
        if(paramUrl.role === "cs"){ 
            url =  "/otorisasi"
            service = "cif"
        }else if(paramUrl.role === "teller"){ 
            url =  "/otorisasi"
            service = "trx"
        }else return setDataAlert({ show: true, body: "Tidak dapat akses halaman.", title: `Not Authorize`, header: "Info", callBack: () => history.push("/") });
        const resp = await doGet({
            url,
            service,
            param: {
                ...filter,
                ...pagination
            }
        });

        const { status, data, remark } = resp;
        if (status === "00") {
            setDataOtor(data?.listOtorisasi || [])
            // console.log(data);
        }
        setLoadingGetData(false)
    }

    // get options entryCode
    async function getEntriesCode(){
        const resp = await doGet({
            url: "/otorisasi/kode-entri",
            service:"cif"
        });

        const { status, data, remark } = resp;
        if (status === "00") {
            setKodeEntries(data?.listOtorisasi || [])
        }
        // console.log("mas")
        setLoadingGetData(false)
    }

    function changeFilter(key = "", val = "" ){
        setDataOtor([]);
        setFilter(prevState => ({
            ...prevState,
            [key] : val
        }));
    }

    function refresh(){
        setFilter({kode_entry:""})
        setPagination({ page: 1, limit: 15})
    }

    // pagination function
    function nextPage(e) {setPagination({ page: e.page, limit: e.limit}) }
    function prevPage(e) { setPagination({ page: e.page, limit: e.limit}) }
    function changeShow(e) { setPagination({ page: e.page, limit: e.limit}) }

    // fungsi button process
    function process(dbId, kode_entry, item){
        const state = { dbId, kode_entry }
        // // // console.log(state)
        if(kode_entry === "CIF001" || kode_entry === "CIF002" || kode_entry === "CIF003")return history.push(`/nasabah-cif/form-perorangan/otorisasi`, state);
        else if(kode_entry === "CIF010" || kode_entry === "CIF012") return history.push(`/nasabah-cif/form-badan-usaha/otorisasi`, state);
        else if(kode_entry === "TAB001") return history.push("/tabungan-giro/form/tabungan/otorisasi", state);
        else if(kode_entry === "TAB002") return history.push("/tabungan-giro/form/tabungan/otorisasi", state);
        else if(kode_entry === "GIRO001" || kode_entry === "GIRO002" ) return history.push("/tabungan-giro/form/giro/otorisasi", state);
        else if(kode_entry === "ESCROW001" || kode_entry === "ESCROW002") return history.push("/tabungan-giro/form/giro-escrow/otorisasi", state);
        else if(kode_entry === "TABREN001") return history.push("/tabungan-giro/form-tabungan-rencana/otorisasi", state);
        else if(kode_entry === "TABREN002") return history.push("/tabungan-giro/form-tabungan-rencana/otorisasi", state);
        else if(kode_entry === "DEP001") return history.push("/deposito/formDeposito/otorisasi", state);
        else if(kode_entry === "DEP003") return history.push("/deposito/formDeposito/otorisasi", state);
        else if(kode_entry === "WIC001") return history.push("/wic/form/otorisasi", state);
        else if(kode_entry === "WIC002") return history.push("/wic/form/otorisasi", state);
        else if(kode_entry === "RBT001") return history.push("/tabungan-giro/form-tabungan/otorisasi", state);
        else if(kode_entry === "SMS001") return history.push("/tabungan-giro/form-notif/otorisasi", state);
        else if(kode_entry === "SMS002") return history.push("/tabungan-giro/form-notif/otorisasi", state);
        else if(kode_entry === "HIRT001") return history.push("/laporan/hist-rek-koran/otorisasi", state);
        else if(kode_entry === "ATM01" || kode_entry === "ATM02") return history.push("/teller/kas-teller/kas_atm/otorisasi", state);
        else if(kode_entry === "SVS002") return svsAction.openModalSVS(state, true, ()=> {
            svsAction.closeModalSVS();
            getData();
        });
        else if(kode_entry === "SVS012") return svsAction.openModalSVS(state, true, ()=> {
            svsAction.closeModalSVS();
            getData();
        });
        else if(kode_entry === "HOLD001") return rekAction.openHoldDana(state, true, ()=> {
            rekAction.closeHoldDana();
            getData();
        });
        else if(kode_entry === "HOLD002") return rekAction.openHoldDana(state, true, ()=> {
            rekAction.closeHoldDana();
            getData();
        });
        else if(kode_entry === "HOLD003") return rekAction.openHoldDana(state, true, ()=> {
            rekAction.closeHoldDana();
            getData();
        });
        else if(kode_entry === "BLOCK001") return rekAction.openBlokRek(state, true, ()=> {
            rekAction.closeBlokRek();
            getData();
        });
        else if(kode_entry === "BLOCK002") return rekAction.openBukaRek(state, true, ()=> {
            rekAction.closeBukaRek();
            getData();
        });
        else if(kode_entry === "DRM001") return rekAction.openAktifasiDormant(state, true, ()=> {
            rekAction.closeAktifasiDormant();
            getData();
        });
        else if(kode_entry === "CQBG001") return rekAction.openWarkat(state, true, ()=> {
            rekAction.closeWarkat();
            getData();
        });
        else if(kode_entry === "CQBG002") return rekAction.openWarkat(state, true, ()=> {
            rekAction.closeWarkat();
            getData();
        });
        else if(kode_entry === "CQBG004") return rekAction.openWarkat(state, true, ()=> {
            rekAction.closeWarkat();
            getData();
        });
        else if(kode_entry === "CQBG005") return rekAction.openWarkat(state, true, ()=> {
            rekAction.closeWarkat();
            getData();
        });
        else if(kode_entry === "DEP002") return depoAction.openUbahNisbahDeposito(1 ,state, ()=> {
            depoAction.closeUbahNisbahDeposito();
            getData();
        });
        // else if(kode_entry === "BLOKD001") return depoAction.openBlokir(state, ()=> {
        //     depoAction.tutupBlokir();
        //     getData();
        // });
        else if(kode_entry === "AFT001") return history.push("/tabungan-giro/form-aft/otorisasi", state);
        else if(kode_entry === "AFT002") return aftAction.openModalDetil( 1, state, ()=> {
            aftAction.closeModalDetil();
            getData();
        });
        else if(kode_entry === "JOIN001") return rekAction.openJoinAccount(state, true, ()=> {
            rekAction.closeHoldDana();
            getData();
        });
        else if(kode_entry === "TAB003") return rekAction. openTutupAccount(state, true, ()=> {
            rekAction.closeTutupAccount();
            getData();
        });
        else if(kode_entry === "TAB004") return rekAction. openBatalTutupAccount(state, true, ()=> {
            rekAction.closeBatalTutupAccount();
            getData();
        }); 
        else if(kode_entry === "TRL01") return rtAction. openregistrasiTeller(state, true, ()=> {
            rtAction.closeregisTeller();
            // getDataOtor();
            getData();
        });

        // trx otorisasi
        else if(kode_entry === "SKN") return history.push("/teller/transfer/skn/tunai/otorisasi", state)
        else if(kode_entry === "RTGS") return history.push("/teller/transfer/rtgs/tunai/otorisasi", state)
        else if(kode_entry === "ST" || kode_entry === "STG" ) return history.push("/teller/transaksi-nasabah/setor-tunai/otorisasi", state)
        else if(kode_entry === "TT" || kode_entry === "TTG" ) return history.push("/teller/transaksi-nasabah/tarik-tunai/otorisasi", state)
        else if(kode_entry === "AKK") return history.push("/teller/kas-teller/ambil-kas/otorisasi", state)
        else if(kode_entry === "SKK") return history.push("/teller/kas-teller/setor-kas/otorisasi", state)
        else if(kode_entry === "AKT") return history.push("/teller/kas-teller/ambil-kas-lain/otorisasi", state)
        else if(kode_entry === "SKT") return history.push("/teller/kas-teller/selisih-kas/otorisasi", state)
        else if(kode_entry === "SLT") return history.push("/teller/kas-teller/selisih-kas/otorisasi", state)
        else if(kode_entry === "TTR") return history.push("/teller/tutup-rekening/otorisasi", state)
        else if(kode_entry === "FIP") return history.push("/teller/transaksi-nasabah/pembayaran-angsuran/otorisasi", state)
        else if(kode_entry === "FTRF") return history.push("/teller/transfer/bifast/otorisasi", state)
        else if(kode_entry === "RV"){ 
            // // // console.log(item)
            return mtAction.openModal({ dbId, kode_entry, id_transaksi_core : parseInt(item.id_transaksi_core) }, true, () =>{
                
                getData();
            } )
        }
        else if(kode_entry === "STAT001"){ 
            // // // console.log(item)
            return rekAction.openCetakStatement({ dbId, kode_entry }, true, () =>{
                rekAction.closeCetakStatement()
                getData();
            } )
        }
        else if(kode_entry === "RCNP001") return nfAction.openCetakNamaPasbook(state, true, ()=> {
            nfAction.closeCetakNama();
            getData();
        });
        else if(kode_entry === "RCB001") return depoAction.getDetailOtorRCB(state, ()=> {
            // rekAction.closeCetakNama();
            depoAction. closeCetakBilyet();
			depoAction. closeCetakBilyetUlang()
            getData();
        });
        else return false;
    }

    // useEffect gettingData list
    useEffect(() => {
        setDataOtor([])
        getData();
    }, [pagination,paramUrl.role ])

    return <otorContext.Provider value={{
        otorState: {
            dataOtor,
            loadingGetData,
            pagination,
            filter,
            kodeEntries
        },
        otorAction: {
            getData,
            nextPage,
            prevPage,
            nextPage,
            changeShow,
            process,
            changeFilter,
            getData,
            getEntriesCode,
            refresh
        }
    }}>{children}</otorContext.Provider>
}

export default function useOtorisasi() { return useContext(otorContext) };