import clsx from 'clsx';
import { useState, useEffect, useMemo } from 'react';
import { FormCheck, FormControl, Modal, FormLabel } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button, Label } from 'semantic-ui-react';
import { TableGrid } from '../../../../controls';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../../../../app.config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BlokirRekeningPage = () => {
    let history = useHistory();
    const [blokirRekeningModal, setBlokirRekeningModal] = useState(false);
    const [detail, setDetail] = useState([]);
    const [rejectModal, setRejectModal] = useState(false);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [jenisNasabah, setJenisNasabah] = useState('');
    const [search, setSearch] = useState('');
    const [searchParam, setSearchParam] = useState('');
    const [jenisProduk, setJenisProduk] = useState('');
    const [dataJenisProduk, setDataJenisProduk] = useState([]);
    const [jenisRekening, setJenisRekening] = useState('');
    const [approvedModal, setApprovedModal] = useState(false);
    const [noRek, setNoRek] = useState('');
    const [status, setStatus] = useState('');
    const [keterangan, setKeterangan] = useState('');
    const [name, setName] = useState('');
    const [state, setState] = useState('');

    useEffect(() => {
        fetch(
            baseUrl +
                `/blokir-rekening?page=${currentPage}&limit=${limit}&accountHolderType=${jenisNasabah}&accountProduct=${jenisProduk}&search=${searchParam}`
        )
            .then((response) => response.json())
            .then((data) => setData(data?.list));

        fetch(baseUrl + `/depositproducts/all`)
            .then((response) => response.json())
            .then((data) => setDataJenisProduk(data?.list));
    }, [currentPage, limit, status, jenisNasabah, jenisProduk, searchParam]);

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    const handleSearch = (e) => {
        e.preventDefault();

        setSearchParam(search);
    };

    const rejectAction = () => {
        setBlokirRekeningModal(false);
        setRejectModal(false);
        const body = {
            action: 'REJECT',
            notes: keterangan,
        };

        return fetch(baseUrl + `/blokir-rekening/${noRek}`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id === '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    const approvedAction = () => {
        setBlokirRekeningModal(false);
        setApprovedModal(false);
        const body = {
            action: 'APPROVAL',
        };

        return fetch(baseUrl + `/blokir-rekening/${noRek}`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id === '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    return (
        <>
            <Modal centered show={Boolean(approvedModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setApprovedModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Setujui Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    {state === 'PENDING UNBLOCK' ? (
                        <p>
                            Yakin menyetujui otorisasi buka blokir rekening <b>{name}</b> ?
                        </p>
                    ) : (
                        <p>
                            Yakin menyetujui otorisasi blokir rekening <b>{name}</b> ?
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setApprovedModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={approvedAction}
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(rejectModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setRejectModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tolak Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <FormControl
                        as="textarea"
                        placeholder="Masukkan keterangan penolakan"
                        onChange={(e) => setKeterangan(e.target.value)}
                        value={keterangan}
                    />
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                        onClick={() => setRejectModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={rejectAction}
                    >
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(blokirRekeningModal)} size="lg">
                <Modal.Header onHide={() => setBlokirRekeningModal(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Otorisasi Blokir Rekening</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tw-border tw-border-black-40 tw-py-5 tw-px-6 tw-border-solid tw-rounded-lg tw-mb-6">
                        <div className="tw-flex tw-items-center tw-mb-5">
                            <div className="tw-text-black tw-font-bold tw-mr-2 tw-text-16">
                                {detail && detail.namaLengkap}
                            </div>
                            <div className="tw-bg-green tw-text-12 tw-bg-opacity-20 tw-text-green tw-rounded-full tw-px-3">
                                Aktif
                            </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-8 tw-items-center tw-gap-x-2 tw-gap-y-1 tw-text-black tw-text-12">
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Produk</span>
                                <span>:</span>
                            </span>
                            <div
                                className="tw-col-span-2"
                                style={{
                                    width: '15em',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {detail && detail.nameProduct}
                            </div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Cabang</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">-</div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Saldo Rek</span>
                                <span>:</span>
                            </span>
                            <div>
                                {new Intl.NumberFormat('id-ID', {
                                    currency: (detail && detail.valuta) || 'IDR',
                                    style: 'currency',
                                }).format(detail && detail.saldo)}
                            </div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>No Rek</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">{detail && detail.nomorRekening}</div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Kode Valuta</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">IDR</div>
                        </div>
                    </div>
                    <FormCheck
                        className="tw-text-12 tw-font-bold tw-mb-5"
                        label="Dapat Bonus / Bagi Hasil"
                    />
                    <div className="tw-grid tw-grid-cols-2 tw-gap-x-4">
                        {/* <div>
                                <FormLabel className="tw-font-normal tw-text-12">
                                    Jenis Blokir
                                    <Label className="tw-ml-2" size="mini">
                                        Wajib
                                    </Label>
                                </FormLabel>
                                <FormControl size="sm" as="select">
                                    <option value="" selected disabled hidden>
                                        Pilih Jenis Blokir
                                    </option>
                                </FormControl>
                            </div> */}
                        <div>
                            <FormLabel className="tw-font-normal tw-text-12">
                                Keterangan
                                <Label className="tw-ml-2" size="mini">
                                    Wajib
                                </Label>
                            </FormLabel>
                            <FormControl
                                placeholder="Keterangan..."
                                size="sm"
                                as="textarea"
                                rows={1}
                                disabled
                                value={detail && detail.keterangan}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setBlokirRekeningModal(false)}
                    >
                        Tidak Proses
                    </Button>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        basic
                        color="red"
                        onClick={() => {
                            setRejectModal(true);
                        }}
                    >
                        Tolak
                    </Button>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        basic
                        color="green"
                        onClick={() => {
                            setApprovedModal(true);
                        }}
                    >
                        Setujui
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Tabungan & Giro
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Blokir Rekening</Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">Blokir Rekening</div>
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                onChange={(e) => setJenisNasabah(e.target.value)}
                            >
                                <option disabled selected={!jenisNasabah}>
                                    Pilih Jenis Nasabah
                                </option>
                                <option value="CLIENT">Perorangan</option>
                                <option value="GROUP">Badan Usaha</option>
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Rekening"
                                as="select"
                                onChange={(e) => setJenisRekening(e.target.value)}
                            >
                                <option disabled selected={!jenisRekening}>
                                    Jenis Rekening
                                </option>
                                <option value="tabungan">Rekening Tabungan</option>
                                <option value="deposito">Rekening Deposito</option>
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                onChange={(e) => setJenisProduk(e.target.value)}
                            >
                                <option disabled selected={!jenisProduk}>
                                    Jenis Produk
                                </option>
                                {dataJenisProduk &&
                                    dataJenisProduk.map((item) => {
                                        return <option value={item.encodedKey}>{item.name}</option>;
                                    })}
                            </FormControl>
                            <form onSubmit={handleSearch} className="tw-w-full mr-4" id="search">
                                <FormControl
                                    id="search"
                                    className="mr-4"
                                    size="sm"
                                    placeholder="Cari no.rekening, nama..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </form>
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                                onClick={() => {
                                    setSearch('');
                                    setJenisNasabah('');
                                    setJenisProduk('');
                                    setJenisRekening('');
                                    setSearchParam('');
                                    // maybe better using ref, but well you can change it to ref if you want
                                    document.getElementById('search').reset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                                onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </div>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Nomor Rekening',
                            field: 'nomorRekening',
                        },
                        {
                            name: 'Nama Lengkap',
                            field: 'namaLengkap',
                        },
                        {
                            name: 'Jenis Produk',
                            field: 'nameProduct',
                        },
                        {
                            name: 'Jenis Rekening',
                            field: 'jenisRekening',
                        },
                        {
                            name: 'Status',
                            field: 'status',
                        },
                        {
                            name: 'Keterangan Blokir',
                            field: 'keterangan',
                        },
                        {
                            field: 'action',
                            width: '250px',
                        },
                    ]}
                    data={data || []}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'value')
                            return <div className={clsx(className, 'tw-text-right')}>{value}</div>;
                        if (field === 'action')
                            return (
                                <Action
                                    onDetail={() => {
                                        setBlokirRekeningModal(true);
                                        setDetail(data);
                                        setNoRek(data.nomorRekening);
                                    }}
                                    onApproved={() => {
                                        setApprovedModal(true);
                                        setNoRek(data.nomorRekening);
                                        setName(data.namaLengkap);
                                        setState(data.status);
                                    }}
                                    onReject={() => {
                                        setRejectModal(true);
                                        setNoRek(data.nomorRekening);
                                        setName(data.namaLengkap);
                                        setState(data.status);
                                    }}
                                    key={`${value}-${field}`}
                                />
                            );
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-end tw-text-black tw-mt-auto"
                ></div>
            </div>
        </>
    );
};

export default BlokirRekeningPage;

const Action = ({ onDetail, onApproved, onReject }) => {
    const [show, setShow] = useState(false);

    const handleDetail = () => {
        if (onDetail) onDetail();

        setShow(false);
    };

    const handleApproved = () => {
        if (onApproved) onApproved();
        setShow(false);
    };
    const handleReject = () => {
        if (onReject) onReject();
        setShow(false);
    };

    return (
        <div>
            <Button basic color="red" size="mini" onClick={handleReject}>
                Tolak
            </Button>
            <Button basic color="green" className="tw-mr-3" size="mini" onClick={handleApproved}>
                Setujui
            </Button>
            <button type="button" class="btn btn-link" onClick={handleDetail}>
                Lihat Detail
            </button>
        </div>
    );
};
