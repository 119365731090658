import React, { createContext, useCallback, useContext, useReducer } from 'react';

const initialState = {
    choice: 1,
    optionSelected: {},
    providerSelected: null,
    nominalList: [],
    nominalSelected:{},
    noInput: "",
    isProsess : false,
    isSukses : false,
    options : [],
    nominals : [],
    openPembayaranCard: false,
    paymentCardData:null,
    formData:{}
}


const reducers = (state={}, action = {}) => {
    const { payload } = action;
    switch(action.type){
        case "setState" :
            return {
                ...state,
                ...payload
            }
        case "setForm" :
            return{
                ...state,
                formData: { ...state.formData, [payload.key]: payload.value}
            }
        case "setNominalList" :
            return{
                ...state,
                nominalList:payload
                }
        default : 
            return state
    }
}

const basePageContext = createContext(null);
const BasePageProvider = ({children}) => {
    const [ state, dispatch ] = useReducer(reducers, initialState);
    const actions = {
        setState: (state) => {
            return dispatch({
                type:"setState",
                payload: state
            })
        },
        setTipe: function(value){
            const selected = state.options.find( v => v.value === value)
            actions.setState({
                optionSelected: selected,
                choice: selected.value
            });
            actions.reset()
        },
        selectNominal: function(value){
            actions.setState({ nominalSelected: value})
        },
        togglePembayaranCard: function(){
            const newOpen = !state.openPembayaranCard;
            actions.setState({
                openPembayaranCard: newOpen
            })
        },
        setOpenPembayaran: function(){
            actions.setState({
                openPembayaranCard: true
            })
        },
        changeInput: function(str){
            actions.setState({
                noInput: str
            })
        },
        reset: function(){
            actions.setState({
                setOpenPembayaran: false,
                nominalSelected: null,
                nominalList: [],
                noInput: "",
                isSukses: false,
                isProsess: false
            })
        },
        setProsess: function(isProsess){
            actions.setState({isProsess})
        },
        setSukses: function(isSukses){
            actions.setState({isSukses})
        },
        bayar: function(){
            actions.setState({isProsess: true})
            setTimeout(()=>{
                actions.setState({isProsess: false, isSukses:true})
            },100)
        },
        setForm: function(key, value){
            return dispatch({
                type:"setForm",
                payload: {key, value}
            })
        },
        setNominalList: function(value){
            return dispatch({
                type:"setNominalList",
                payload: value
            })
        }
    }

    return ( 
        <>
            <basePageContext.Provider value={[state, dispatch, actions]}>
                {children}
            </basePageContext.Provider>
        </>
     );
}


export const useBasePageState = () => useContext(basePageContext);
export default BasePageProvider;