import React from 'react';
import { Modal } from 'react-bootstrap';
import useFormTransfer from '../useFormTransfer';
import { TableList } from '../../../../../controls';

const ModalDataNasabah = () => {

    const { pdrState, pdrAction, ftAction } = useFormTransfer();
    return (
        <>

            {/* form relasi nasbaah */}
            <Modal size="xl" show={pdrState.modalNasabah} onHide={pdrAction.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <strong>Pencarian Data Nasabah</strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pb-4">
                        {/* <p className="global-text">Cari data berdasarkan :</p> */}
                        <div className="row">
                            {/* 
                            <div className="col-2">
                                <div className="form-group">
                                    <select name="" id="" value={pdrState.filter.filterBy} onChange={(e) => pdrAction.setFilter(prevState => ({ ...prevState, filterBy: e.target.value }))} className="form-control">
                                        <option value={0}>Nama Nasabah</option>
                                        <option value={1}>Nomor CIF</option>
                                    </select>
                                </div>
                            </div>
                             */}
                            {/* <div className="col">
                                <div className="form-group">
                                    <select name="" id="" value={pdrState.filter.filterType} onChange={(e) => pdrAction.setFilter(prevState => ({ ...prevState, filterType: e.target.value }))} className="form-control">
                                        <option value={0}>Perorangan</option>
                                        <option value={1}>Badan Usaha</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input
                                        value={pdrState.filter.keyword}
                                        onChange={(e) => pdrAction.setFilter(prevState => ({ ...prevState, keyword: e.target.value }))}
                                        type="text"
                                        className="form-control"
                                        placeholder="Nama Nasabah"
                                    />
                                </div>
                            </div>
                            <div className="col-1 p0">
                                <button onClick={pdrAction.searchNasabah} className="btn btn-primary btn-block">Cari</button>
                            </div> */}
                            {/* <div className="col-5 p0">
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TableList
                                    withCard={false}
                                    isLoading={pdrState.loadingSearch}
                                    data={pdrState.listNasabah}
                                    columns={[
                                        {
                                            name: 'Nomor CIF',
                                            field: 'nomor_cif',
                                            maxWidth: '150px',
                                        },
                                        {
                                            name: 'Nama CIF',
                                            field: 'nama',
                                            maxWidth: '150px',
                                        },
                                        {
                                            name: 'Kode Cabang',
                                            field: 'kode_cabang_input',
                                            maxWidth: '75px',
                                        },
                                        {
                                            name: 'Alamat',
                                            field: 'alamat',
                                            maxWidth: '150px',
                                        },
                                        {
                                            name: 'Jenis Nasabah',
                                            field: 'jenis_nasabah',
                                            maxWidth: '75px',
                                        },
                                    ]}
                                    pagination={true}
                                    withAction={true}
                                    actionItems={[
                                        {
                                            name: 'Pilih Nasabah',
                                            onClick: async (e) => {
                                                let get = false;
                                                if(ftAction.pilihNasabah) get = await ftAction?.pilihNasabah(e, pdrState.filter.jenis_nasabah);
                                                if (get) pdrAction.closeModal()
                                            },
                                            className: "text-danger"
                                        },
                                    ]}
                                    onChangeShow={pdrAction.changeShow}
                                    onNextPage={pdrAction.nextPage}
                                    onPrevPage={pdrAction.prevPage}
                                    filter={true}
                                    formsFilter={[
                                        {
                                            type: 'select',
                                            options: (pdrState.listKodeCabang || []).map( ({kode_cabang = "", nama_cabang = ""}, i) => ({
                                                key:kode_cabang,
                                                display:`${kode_cabang} - ${nama_cabang}`
                                            })),
                                            placeholder: 'Kode Cabang',
                                            disabled: false,
                                            readonly: false,
                                            value: pdrState?.filter?.kode_cabang,
                                            onChange: (kode_cabang) => pdrAction.setFilter(f => ({ ...f, kode_cabang })),
                                        },
                                        // {
                                        //     type: 'select',
                                        //     options: [
                                        //         {
                                        //             key: "0",
                                        //             display: 'Perorangan',
                                        //         },
                                        //         // {
                                        //         //     key: "1",
                                        //         //     display: 'Badan Usaha',
                                        //         // },
                                        //     ],
                                        //     placeholder: 'Jenis Nasabah',
                                        //     disabled: false,
                                        //     readonly: false,
                                        //     value: pdrState?.filter?.jenis_nasabah,
                                        //     onChange: (jenis_nasabah) => pdrAction.setFilter(f => ({ ...f, jenis_nasabah })),
                                        // },
                                        {
                                            type: 'text',
                                            options: [],
                                            placeholder: 'Keyword',
                                            disabled: false,
                                            readonly: false,
                                            value: pdrState?.filter?.keyword,
                                            onChange: (keyword) => pdrAction.setFilter(f => ({ ...f, keyword })),
                                        },
                                    ]}
                                    onFilter={() => pdrAction.resetPagination()}
                                    onResetFilter={() => {pdrAction.resetFilter()}}
                                    resetFilter={true}
                                    submitButton="Submit"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button onClick={pdrAction.closeModal} className="btn btn-sm float-right mr-2 btn-outline-secondary">
                                Batal
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* form cari nasabah */}
        </>
    );
}

export default ModalDataNasabah;