import React, { memo, useRef } from 'react';
import { AppAction, AppInterfaces } from '../../../app.data';

export default AppInterfaces.appLoad(memo(function({store, methods, dispatch}){
    const { alert } = store;

    const appAction = useRef(null);
    return(
        <>
            <AppAction ref={appAction} />
            {
                alert.show && <div className="row">
                    <div className="col">
                        <div className={`alert alert-${alert.tipe === "success" ? "primary" : alert.tipe === "warning"? "warning" : "danger" }`}>
                            {alert.message} 
                            <button 
                                onClick={()=>appAction.current.closeAlert()}
                                className="float-right" style={{ background:"none", border:"none", color:"white"}}
                            >x</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}))