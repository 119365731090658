import React, { useState, useCallback, useEffect, memo, useRef } from 'react';
import clsx from 'clsx';
import { FormCheck, FormControl } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { TableGrid } from '../../../controls';
import ActionRiwayat from './action';
import { useHistory } from "react-router-dom";
import { baseUrl } from '../../../../app.config';
import { AppInterfaces, AppAction } from '../../../../app.data';

export default AppInterfaces.appLoad(
    memo(function ({ store, methods, dispatch }) {

    const appAction = useRef(null);
    const [paramFetch, setParamFetch] = useState({});

    const [teller, setTeller] = useState({
        name: 0,
        balance: 0,
        branch_name: '',
        branch_code: ''
    });

    const [data, setData] = useState([]);

    let history = useHistory();

    const getTeller = async () => { 
        const res = await fetch(baseUrl + '/teller/find/teller-1');
        const data = await res.json();
        // console.log(data);
        const balance = new Intl.NumberFormat('id-ID').format(data.list?.mambu?.gl?.balance);

        setTeller({ 
            balance,
            name: data.list?.db?.name,
            branch_code: data.list?.mambu?.branch?.id,
            branch_name: data.list?.mambu?.branch?.name,
        });
    }

    const getTransaksi = async () => { 
        const res = await fetch(baseUrl + '/otorisasi/transaksi/informasi/kas/teller-1?page=0&limit=15');
        const data = await res.json();
        // console.log(data);
        setData(data.list);
    }

    useEffect(async () => {
        getTeller();
        getTransaksi();
    }, []);

    return(
        <>
            <AppAction ref={appAction} />
            <React.Fragment>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Informasi Teller
                </Breadcrumb.Section>
                
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Informasi Kas Teller</Breadcrumb.Section>
            </Breadcrumb>

            <div className="tw-bg-white tw-rounded-lg">
            <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
            <div className="tw-flex tw-items-center tw-mb-5">
                                <div className="tw-text-black tw-mr-2 tw-text-16">
                                {teller.name}
                                </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-6 tw-items-center tw-gap-x-2 tw-gap-y-1 tw-text-black tw-text-12">
                                {/* <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                    <span>NIP</span>
                                    <span>:</span>
                                </span> */}
                                {/* <div className="tw-col-span-5">00000001231</div> */}
                                
                                <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                    <span>Kantor Cabang</span>
                                    <span>:</span>
                                </span>
                                <div className="tw-col-span-5">{teller.branch_name}</div>
                            </div>
                </div>
            </div>

            <div className="tw-bg-white tw-rounded-lg tw-mt-10 tw-text-center ">
            <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold  tw-inline-block">
                    Total Saldo Kas Teller :
            </div>
            <div className="text-primary tw-text-18 tw-font-bold tw-inline-block">
                    Rp. {teller.balance}
            </div>
            </div>
            <div className="tw-bg-white tw-rounded-lg tw-mt-10">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Informasi Kas Teller
                </div>

                <TableGrid
                    onRenderColumnField={(_value, field) => {
                        if (field === 'checked') return <FormCheck />;
                    }}
                    columns={[
                        {
                            name: 'Jam Input',
                            field: 'no_referensi',
                        },
                        {
                            name: 'Debit/Kredit',
                            field: 'tipe_mutasi',
                        },
                        {
                            name: 'Nilai Mutasi',
                            field: 'nilai_transaksi',
                        },
                        {
                            name: 'Referensi',
                            field: 'no_referensi',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                        },
                        {
                            name: 'Status Otorisasi',
                            field: 'status_otorisasi',
                        },
                    ]}
                    data={data}
                    onRenderField={(value, field, { className }) => {
                        if (field === 'checked') return <FormCheck checked={value} />;

                        if (field === 'value')
                            return <div className={clsx(className, 'tw-text-right')}>{value}</div>;
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl className="tw-border-0" as="select" size="sm">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                    />
                    <div className="tw-text-12">Menampilkan 1-3 of 30 data</div>
                </div>
            </div>
        </React.Fragment>
        </>
    )

    })
);