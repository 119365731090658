import React, { memo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { useMutation } from 'react-query';
import { AppInterfaces } from '../../../app.data';
import { TableList } from '../../controls';

const ReversePencairan = AppInterfaces.appLoad(
    memo(({ show, onHide }) => {
        const [confirm, setConfirm] = useState(false);
        const handleReversePencairan = useMutation();

        return (
            <>
                <Modal centered show={handleReversePencairan.isSuccess}>
                    <Modal.Header>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Proses Reverse Pencairan Deposito berhasil dilakukan
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => handleReversePencairan.reset()}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Selesai
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={confirm}>
                    <Modal.Header onHide={() => setConfirm(false)} closeButton>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Lakukan proses Reverse Pencairan Deposito?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setConfirm(false)}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Tidak
                        </Button>
                        <Button
                            loading={handleReversePencairan.isLoading}
                            onClick={() => handleReversePencairan.mutate()}
                            className="tw-w-16"
                            size="mini"
                            color="blue"
                        >
                            Ya
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={show} size="xl">
                    <Modal.Header onHide={onHide} closeButton>
                        <Modal.Title>
                            <div className="tw-text-18 tw-font-bold">
                                Reverse Pencairan Deposito
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TableList
                            columns={[
                                {
                                    name: 'Tanggal',
                                    field: 'tanggal',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Nomor Rekening',
                                    field: 'no_rek',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Valuta',
                                    field: 'valuta',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Nama Rekening',
                                    field: 'name',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Nilai Pencairan',
                                    field: 'nilai_pencairan',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Status Otorisasi',
                                    field: 'status_otorisasi',
                                    maxWidth: '50px',
                                },
                            ]}
                            selectionMode={2}
                            pagination={false}
                            withCard={false}
                            withActions={false}
                            filter={true}
                            submitButton="Lihat Daftar Transaksi"
                            data={[
                                {
                                    tanggal: '18 Feb 2020',
                                    no_rek: '12312312312',
                                    valuta: 'RP',
                                    name: 'Adam Irsyad Suhaifi',
                                    nilai_pencairan: '35.000',
                                    status_otorisasi: 'Sudah Otorisasi',
                                },
                            ]}
                            formsFilter={[
                                {
                                    type: 'date',
                                    placeholder: 'from',
                                    disabled: false,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                                {
                                    type: 'date',
                                    placeholder: 'to',
                                    disabled: false,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                            ]}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide} className="tw-font-normal tw-py-2 tw-mr-2">
                            Batal
                        </Button>
                        <Button
                            onClick={() => {
                                setConfirm(true);
                                if (onHide) onHide();
                            }}
                            className="tw-font-normal tw-py-2"
                            color="blue"
                        >
                            Proses Reversal
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);

export default ReversePencairan;
