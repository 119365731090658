
import { createAuthProvider } from '@react-keycloak/core'
import React, { createContext, useContext, useState } from 'react';
import { baseUrlAuth } from '../../../app.config';
import { keycloakConfig } from '../../../keycloak.config';
import { useModalAlert } from '../../controls/modalAlert';
import useFetch from '../../../hooks/useFetch';
import Cookies from 'universal-cookie';
import { } from 'keycloak-js';
import useKeycloak from '../../../hooks/keycloak.module';
const loginContext = createContext({
    loginAction: {},
    loginState: {}
})

export function LoginProvider({ children }) {

    const { keycloak } = useKeycloak()
    const { doFetch } = useFetch()
    const { modalState, modalAction } = useModalAlert();

    const [formData, setFormData] = useState({
        username: "",
        password: "",
        grant_type: "password",
        client_id: keycloakConfig.clientId,
        client_secret: ""
    })

    function createUrlForm(data) {
        var formBody = [];
        for (var property in data) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        return formBody
    }
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    async function doLogin() {
        if(
            formData.password === ""||
            formData.password === undefined||
            formData.password === null||
            formData.username === ""||
            formData.username === undefined||
            formData.username === null
        ) return setDataAlert({ show: true, body: "Form belum lengkap. Isi username dan password terlebih dahulu", title: ``, header: "Info", callBack: () => closeAlert() })

        openLoading()
        // let url = `http://172.27.170.45/auth/realms/bds/protocol/openid-connect/token`;
        let url =  `${baseUrlAuth}/auth/realms/${keycloakConfig.realm}/protocol/openid-connect/token`

        try {
            const resp = await doFetch({
                // data: bodyData,s
                url,
                data: createUrlForm(formData),
                token: false,
                type: 2,
                isLogin:true
            });
            const { statusCode, status,
                access_token, expires_in,
                refresh_expires_in, refresh_token,
                session_state, statusText = "", message_id = "", remark = undefined, message= undefined,
                error_description = "Username atau Password salah", error= "invalid_grant"
            } = resp;

            const msg = JSON.parse(message || "{}");

            if (statusCode === 200 || statusCode === 201) {
                keycloak.login({
                    access_token, expires_in:expires_in,
                    refresh_expires_in, refresh_token,
                    session_state,
                })
            } else if(statusCode === 404) setDataAlert({ show: true, body: error, title: ``, header: `Error ${statusCode}`, callBack: () => closeAlert() })
                else if(statusCode === 500) setDataAlert({ show: true, body: remark || msg?.message, title: ``, header: `Info`, callBack: () => closeAlert() })
            else setDataAlert({ show: true, body: error_description, title: error, header: "Info", callBack: () => closeAlert() })
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }
    const [showPwd, setShowPwd] = useState(false)
   
    return <loginContext.Provider value={{
        loginAction: {
            doLogin,
            setFormData,
            setShowPwd
        },
        loginState: {
            formData,
            showPwd
        }
    }}>{children}</loginContext.Provider>
}

export default function useLogin() {
    return useContext(loginContext)
}