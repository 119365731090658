import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
import useModalNasabah from './useModalNasabah';
// import { useKeycloak } from '@react-keycloak/web';
import { usePrintModal } from '../../../../controls/modalPrint';
import useNexusPrint from '../../../../../hooks/useNexusPrint';
import moment from 'moment';
import { addCommas, removeNonNumeric } from '../../../../../module';
import useKeycloak from '../../../../../hooks/keycloak.module';
import useDropdowns from '../../../../../hooks/useDropdowns';
import { thousandSeparator } from '../../../../utils';

const bifastContext = createContext({
    biAction: {},
    biState: {}
});

export function BiProvider({ children }) {
    const { doGet, doPost, wait } = useFetch();
    const dataDropDown = useDropdowns(["BIF_PURPOSE_CODE"]);
    const [dataBank, setDataBank] = useState([]);
    const [formData, setFormData] = useState({
        "nomor_rekening": "",
        "nominal": 0,
        "nomor_referensi": "",
        "keterangan": "",
        "kode_valuta": "",
    });
    const [infoTrx, setInfoTrx] = useState({
        "kode_bank_kliring": "",
        "nama_bank_kliring": "",
        "nama_rekening_penerima": "",
        "ats_permintaan": "",
        "jenis_penduduk": "",
        "jenis_nomor_referensi": "",
        "sumber_biaya": "T",
        "keterangan": "",
        "nominal_ekuivalen": 0,
        "biaya": 0,
        "biaya_ekuivalen": 0,
        "norek_biaya": "",
        "kurs_jual": 1,
        "kurs_beli": 1,
        "kurs_buku": 1,
        "tujuan_transaksi" : ""
    });
    const [dataPengirim, setDataPengirim] = useState({
        "jenis_nasabah": "",
        "nomor_nasabah": "",
        "nama": "",
        "alamat": "",
        "jenis_identitas": "",
        "nomor_identitas": "",
        "nomor_tlpn": "",
        "nasabah_wic": ""
    });
    const { modalState, modalAction } = useModalAlert();
    const [isWic, setIsWic] = useState(true);
    const { keycloak } = useKeycloak()
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams();
    const location = useLocation();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const modalNasabah = useModalNasabah();

    const { pdrState, pdrAction } = modalNasabah;
    function resetForm() {
        setFormData({
            "nomor_rekening": "",
            "nominal": 0,
            "nomor_referensi": "",
            "keterangan": "",
            "kode_valuta": "IDR",

        });
        setInfoTrx({
            "kode_bank_kliring": "",
            "nama_bank_kliring": "",
            "nama_rekening_penerima": "",
            "ats_permintaan": "",
            "jenis_penduduk": "",
            "jenis_nomor_referensi": "",
            "sumber_biaya": "T",
            "keterangan": "",
            "nominal_ekuivalen": 0,
            "biaya": 0,
            "biaya_ekuivalen": 0,
            "norek_biaya": "",
            "kurs_jual": 1,
            "kurs_beli": 1,
            "kurs_buku": 1,
            "tujuan_transaksi":""
        });
        setDataPengirim({
            "jenis_nasabah": "",
            "nomor_nasabah": "",
            "nama": "",
            "alamat": "",
            "jenis_identitas": "",
            "nomor_identitas": "",
            "nomor_tlpn": "",
            "nasabah_wic": ""
        });
        getBiaya();
        getMaxBifast();
    }

    async function getBiaya() {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                param: {
                    kode_pg: "ADM_BIFAST"
                },
                url: `/parameterglobal/pg-by-code`,
                service: "trx"
            });
            const { statusCode, status, statusText, data, remark, message_id } = resp;

            if (statusCode === 200) {
                if (message_id === "00") {
                    // const { nama_rekening = "", nama_cabang = "", valuta = "", nama_produk = "" } = data;
                    // console.log(data.nilai_parameter_number)
                    setInfoTrx(s => ({
                        ...s,
                        biaya: parseInt(data?.nilai_parameter_number || 0)
                    }))
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }
    async function getMaxBifast() {
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                param: {
                    kode_pg: "MAX_BIFAST"
                },
                url: `/parameterglobal/pg-by-code`,
                service: "trx"
            });
            const { statusCode, status, statusText, data, remark, message_id } = resp;

            if (statusCode === 200) {
                if (message_id === "00") {
                    setInfoTrx(s => ({
                        ...s,
                        max_biaya: parseInt(data?.nilai_parameter_number || 0)
                    }))
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }
    // function getData Perorangan
    // async function getDataDetail(dbId) {

    //     let url = "/rekening/local";
    //     setLoading(prevState => ({ ...prevState, loading: true }));
    //     const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/trf${paramUrl.type}${paramUrl.jenistf}/${dbId}`, service: "trx" });
    //     if (statusCode === 200) {
    //         if (status === "00") {
    //             if (data !== null) {
    //                 setFormData({
    //                     nominal: parseFloat(data.nominal).toFixed(2),
    //                     nomor_rekening: data.info_transaksi?.nomor_rekening_penerima,
    //                     kode_valuta: data.kode_valuta,
    //                     nomor_referensi: data.nomor_referensi,
    //                     keterangan: data.keterangan
    //                 });
    //                 const { info_transaksi = {} } = data;
    //                 setInfoTrx({
    //                     "kode_bank_kliring": info_transaksi.kode_bank_kliring,
    //                     "nama_bank_kliring": info_transaksi.nama_bank_kliring,
    //                     "nama_rekening_penerima": info_transaksi.nama_rekening_penerima,
    //                     "ats_permintaan": info_transaksi.ats_permintaan,
    //                     "jenis_penduduk": info_transaksi.jenis_penduduk,
    //                     "jenis_nomor_referensi": info_transaksi.jenis_nomor_referensi,
    //                     "sumber_biaya": info_transaksi.sumber_biaya,
    //                     "keterangan": info_transaksi.keterangan,
    //                     "nominal_ekuivalen": parseFloat(info_transaksi.nominal_ekuivalen).toFixed(2),
    //                     "biaya": parseFloat(info_transaksi.biaya).toFixed(2),
    //                     "biaya_ekuivalen": parseFloat(info_transaksi.biaya).toFixed(2),
    //                     "norek_biaya": parseFloat(info_transaksi.norek_biaya).toFixed(2),
    //                     "kurs_jual": parseFloat(info_transaksi.kurs_jual).toFixed(2),
    //                     "kurs_beli": parseFloat(info_transaksi.kurs_beli).toFixed(2),
    //                     "kurs_buku": parseFloat(info_transaksi.kurs_buku).toFixed(2),
    //                     "tujuan_transaksi":info_transaksi.purpose_code
    //                 });
    //                 if (info_transaksi.pengirim_nomor_nasabah === "" ||
    //                     info_transaksi.pengirim_nomor_nasabah === null ||
    //                     info_transaksi.pengirim_nomor_nasabah === undefined) {
    //                     setIsWic(false)
    //                 }
    //                 setDataPengirim(prev => ({
    //                     ...prev,
    //                     "nomor_nasabah": info_transaksi.pengirim_nomor_nasabah,
    //                     "nama": info_transaksi.pengirim_nama,
    //                     "alamat": info_transaksi.pengirim_alamat,
    //                     "jenis_identitas": info_transaksi.pengirim_jenis_identitas,
    //                     "nomor_identitas": info_transaksi.pengirim_nomor_identitas,
    //                     "nomor_tlpn": info_transaksi.pengirim_nomor_tlpn,
    //                     "nasabah_wic": info_transaksi.pengirim_nasabah_wic
    //                 }));
    //             }
    //         }
    //         else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
    //     } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    //     setLoading(prevState => ({ ...prevState, loading: false }))
    //     // // // // console.log(data)
    // }

    async function getDataDetailOtor(dbId) {

        let url = "/rekening/local";
        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/trfbifast/otor-view/${dbId}`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    setFormData({
                        nominal: parseFloat(data.nominal).toFixed(2),
                        nomor_rekening: data.nomor_rekening,
                        kode_valuta: data.kode_valuta,
                        nomor_referensi: data.nomor_referensi,
                        keterangan: data.keterangan
                    });
                    const { info_transaksi = {} } = data;
                    setInfoTrx({
                        "kode_bank_kliring": info_transaksi.kode_bank_kliring,
                        "nama_bank_kliring": info_transaksi.nama_bank_kliring,
                        "nama_rekening_penerima": info_transaksi.nama_rekening_penerima,
                        "ats_permintaan": info_transaksi.ats_permintaan,
                        "jenis_penduduk": info_transaksi.jenis_penduduk,
                        "jenis_nomor_referensi": info_transaksi.jenis_nomor_referensi,
                        "sumber_biaya": info_transaksi.sumber_biaya,
                        "keterangan": info_transaksi.keterangan,
                        "nominal_ekuivalen": parseFloat(info_transaksi.nominal_ekuivalen).toFixed(2),
                        "biaya": parseFloat(info_transaksi.biaya).toFixed(2),
                        "biaya_ekuivalen": parseFloat(info_transaksi.biaya).toFixed(2),
                        "norek_biaya": parseFloat(info_transaksi.norek_biaya).toFixed(2),
                        "kurs_jual": parseFloat(info_transaksi.kurs_jual).toFixed(2),
                        "kurs_beli": parseFloat(info_transaksi.kurs_beli).toFixed(2),
                        "kurs_buku": parseFloat(info_transaksi.kurs_buku).toFixed(2),
                        "tujuan_transaksi":info_transaksi.purpose_code
                    });
                    if (info_transaksi.pengirim_nomor_nasabah === "" ||
                        info_transaksi.pengirim_nomor_nasabah === null ||
                        info_transaksi.pengirim_nomor_nasabah === undefined) {
                        setIsWic(false)
                    }
                    setDataPengirim(prev => ({
                        ...prev,
                        "nomor_nasabah": info_transaksi.pengirim_nomor_nasabah,
                        "nama": info_transaksi.pengirim_nama,
                        "alamat": info_transaksi.pengirim_alamat,
                        "jenis_identitas": info_transaksi.pengirim_jenis_identitas,
                        "nomor_identitas": info_transaksi.pengirim_nomor_identitas,
                        "nomor_tlpn": info_transaksi.pengirim_nomor_tlpn,
                        "nasabah_wic": info_transaksi.pengirim_nasabah_wic
                    }));
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
    }

    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            // getDataDetail(location.state?.dbId);
            getDataDetailOtor(location.state?.dbId);
        }
        // } 
        setFormEditable(paramUrl.mode !== "otorisasi");
    }, [paramUrl.mode])

    async function getListBank() {
        try {
            const resp = await doGet({
                url: `/bank-transfer/bifast/members`,
                service: "trx"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    setDataBank(data)
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
    }

    useEffect(function () {
        getListBank()
        getBiaya()
        getMaxBifast()
    }, [])
    const [otorDesc, setOtorDesc] = useState("")

    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }


    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }
    useEffect(function () {
        if (paramUrl.mode !== "otorisasi") {
            setDataPengirim({
                "jenis_nasabah": "",
                "nomor_nasabah": "",
                "nama": "",
                "alamat": "",
                "jenis_identitas": "",
                "nomor_identitas": "",
                "nomor_tlpn": "",
                "nasabah_wic": ""
            });
        }
    }, [isWic])
    function changeForm(value, key, subkey = null, grandSubKey = null) {
        value = value.toUpperCase()
        if (grandSubKey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [grandSubKey]: { ...prevState[subkey][grandSubKey], [key]: value } } }))
        else if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }
    async function cekIdentitas(noId) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/nasabah/cek_cif_by_no_identitas/${noId}`,
                service: "cif"
            });
            const { statusCode, status, statusText, data = [], remark } = resp;
            // // // console.log(resp)

            if (statusCode === 200) {

                if (status === "00") {

                }
                else if (status === "01") {
                    let s = `Nomor Identitas sudah terdaftar sebagai nasabah / wic.`;
                    let r = ` Nama : ${data.length > 0 ? data[0]?.nama_nasabah : ""}. Nomor Nasabah : `;

                    data.forEach((v, i) => r = `${r} ${v.nomor_nasabah} ${i + 1 >= data.length ? "." : " ,"}`)
                    let f = <>
                        {s} <br />
                        {r}
                    </>
                    setDataAlert({ show: true, body: f, title: `${statusText}`, header: "Error", callBack: () => null });
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }
    function checkHP(value, callback = () => null) {
        if (value.length < 10) {
            setDataAlert({ show: true, body: `No Handphone minimal 10 digit`, title: `Cek Kembali No HandPhone`, header: "Warning", callBack: () => callback() });

        } else {
            setDataPengirim(p => ({ ...p, nomor_tlpn: value }))
        }
    }

    // pilih nasabah
    async function pilihNasabah(dataNasabah, tipe) {
        setLoading(prevState => ({ ...prevState, loading: true }));
        // // // // console.log((parseInt(filter) === 1))
        let url = `/nasabah/${dataNasabah.nomor_cif}`;
        if (parseInt(tipe) === 1) url = `/nasabah-badan-usaha/${dataNasabah.nomor_cif}`;

        try {
            const resp = await doGet({
                url,
                service: "cif"
            });
            const { status, statusText, data, statusCode, remark } = resp;
            // // console.log(pdrState?.filter.filterType)
            if (statusCode === 200) {
                if (status === "00") {
                    if (pdrState?.filter?.filterType === "1") {
                        const {
                            nasabahNamaInduk,
                            nasabahNomorInduk,
                            nasabahProvinsi,
                            nasabahRw,
                            nasabahRt,
                            nasabahKecamatan,
                            nasabahAlamat,
                            nasabahKelurahan,
                            nasabahKotaKabupaten,
                            nasabahKodePos,
                            nasabahTeleponKantorKodeArea1,
                            nasabahTeleponKantorNomor1,
                            groupName,
                            nasabahNomorAktePendirian,
                            personalIdType

                        } = data.nasabahBadanUsaha
                        // // console.log(groupName)
                        setDataPengirim(p => ({
                            ...p,
                            jenis_nasabah: "BADAN USAHA",
                            nama: groupName,
                            alamat: `${nasabahAlamat || ""}`,
                            // alamat: `${nasabahAlamat || ""} RT${nasabahRt || ""}/RW${nasabahRw || ""}
                            //             ${nasabahKelurahan || ""}, 
                            //             ${nasabahKecamatan || ""}, 
                            //             ${nasabahKotaKabupaten || ""}, 
                            //             ${nasabahProvinsi || ""}
                            //             ${nasabahKodePos || ""}`,
                            jenis_identitas: "AKTA",
                            nomor_identitas: nasabahNomorAktePendirian,
                            nomor_tlpn: `${nasabahTeleponKantorKodeArea1} ${nasabahTeleponKantorNomor1}`,
                        }));
                    }
                    else {
                        const {
                            firstName = "",
                            mailingAddressPostalCode,
                            mailingAddress,
                            mailingAddressCity,
                            mailingAddressVillage,
                            mailingAddressProvince,
                            mailingAddressSubDistrict,
                            personalIdNumber,
                            mobilePhone,
                            personalIdHolderName,
                            personalIdType,
                            id
                        } = data.nasabahBadanUsaha;

                        setDataPengirim(p => ({
                            ...p,
                            jenis_nasabah: "PERORANGAN",
                            nomor_nasabah: id,
                            nama: personalIdHolderName,
                            alamat: `${mailingAddress || ""}`,
                            //alamat: `${mailingAddress || ""} ${mailingAddressVillage || ""}, ${mailingAddressSubDistrict || ""}, ${mailingAddressCity || ""}, ${mailingAddressPostalCode || ""} ${mailingAddressProvince || ""} `,
                            jenis_identitas: personalIdType,
                            nomor_identitas: personalIdNumber,
                            nomor_tlpn: mobilePhone,
                        }));

                    }

                    // // Sesuaikan fieldnya disini juga
                    // const { nasabahBadanUsaha } = data
                    // let homePhone = nasabahBadanUsaha?.homePhone;
                    // let mobilePhone = nasabahBadanUsaha?.mobilePhone;
                    // let alamat = dataNasabah.alamat.toUpperCase();
                    // let nomor_identitas = nasabahBadanUsaha.personalIdNumber;
                    // let nomor_nasabah = dataNasabah.nomor_cif
                    // let jenis_identitas = "KTP";


                    // if (parseInt(tipe) === 1) {
                    //     homePhone = nasabahBadanUsaha?.nasabahTeleponKantorKodeArea1 + nasabahBadanUsaha?.nasabahTeleponKantorNomor1;
                    //     mobilePhone = "";
                    //     nomor_identitas = nasabahBadanUsaha?.nasabahNomorAktePendirian;
                    //     jenis_identitas = "AKTA"
                    //     // // // console.log("badan usaha")
                    // }

                    // setFormData(prevState => ({
                    //     ...prevState,
                    //     info_transaksi: {
                    //         ...prevState.info_transaksi,
                    //         data_pengirim: {
                    //             ...prevState.info_transaksi.data_pengirim,
                    //             nomor_nasabah,
                    //             nama: dataNasabah.nama,
                    //             alamat,
                    //             jenis_identitas,
                    //             nomor_identitas,
                    //             nomor_tlpn: homePhone,
                    //         }
                    //     }

                    // }));
                    return true;
                } else {
                    setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
                    setLoading(prevState => ({ ...prevState, loading: false }))
                    return false;
                }
            } else {
                setDataAlert({ show: true, body: remark, title: `${JSON.ify(statusText)}`, header: "Error", callBack: () => null });
                setLoading(prevState => ({ ...prevState, loading: false }))
                return false;
            }
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            setLoading(prevState => ({ ...prevState, loading: false }))
            return false;
        }

    }

    const [isCekRek, setIsCekRek] = useState(true);
    const [noRek, setNoRek] = useState();
    const [refNumber, setRefNumber] = useState(""); 
    async function cekNoRekening() {
        // console.log(noRek)
        openLoading("Checking Account No ...")
        // setIsCekRek(false);
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: `/trfbifast/create-tx-draft`,
                service: "trx",
                headers: {
                    method: "POST"
                },

                data: {
                    "nomor_rekening": noRek,
                    // "nominal": formData?.nominal,
                    "nominal": parseFloat(removeNonNumeric(formData?.nominal)),                 
                    "product_code": infoTrx?.kode_bank_kliring,
                    "term_date_time": moment(Date.now()).format("YYYYMMDDHHmmss"),
                    "purpose_code":infoTrx?.tujuan_transaksi,
                    "debitor_name":dataPengirim?.nama
                }
            });

            if (statusCode === 200) {
                if (status === "00") {
                    // txdraft_ref_number
                    setInfoTrx(prev => ({
                        ...prev,
                        "nama_rekening_penerima": data.credit_account_name,
                    }));
                    setRefNumber(data.txdraft_ref_number);
                    setIsCekRek(false);
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }

    function confirmTrx() {
        let body = `Apakah anda yakin melanjutkan Transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => postTrfBifast(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function postTrfBifast() {
        closeConfirm()
        // console.log(dataPengirim)
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: `/trfbifast/trf-bifast`,
                service: "trx",
                headers: {
                    method: "POST"
                },
                data: {
                    "uid": keycloak.uid,
                    "nomor_rekening": formData?.nomor_rekening,
                    // "nominal": formData?.nominal,
                    "nominal": parseFloat(removeNonNumeric(formData?.nominal)),
                    "keterangan": formData?.keterangan,
                    "info_transaksi": {
                        "txdraft_ref_number": refNumber,
                        "kode_bank": infoTrx?.kode_bank_kliring,
                        "biaya": parseInt(infoTrx?.biaya),
                        "nama_rekening_penerima": infoTrx?.nama_rekening_penerima,
                        "purpose_code":infoTrx?.tujuan_transaksi,
                        "data_pengirim": dataPengirim,
                    }
                }
            });

            if (statusCode === 200) {
                if (status === "00") {

                    setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
                    resetForm();
                    keycloak.updateUID()
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }
    function checkTransaksi(value, callback=()=>null) {
        if ( parseFloat(formData.nominal)  > parseFloat(infoTrx?.max_biaya)){
            setDataAlert({ show: true, body: `transaksi bifast maksimal Rp. ${addCommas(parseFloat(infoTrx?.max_biaya).toFixed(2))}`, header: "INFO", callBack: () => callback() });
            
        }
        else{
            changeForm(value, "nominal")
        }
    }
    return (
        <bifastContext.Provider value={{
            biAction: {
                setIsWic,
                responseOtor,
                closeAlert,
                closeConfirm,
                changeForm,
                setInfoTrx,
                setDataPengirim,
                pilihNasabah,
                cekIdentitas,
                checkHP,
                cekNoRekening,
                setNoRek,
                resetForm,
                confirmTrx,
                checkTransaksi
            },
            biState: {
                formData,
                formEditable,
                infoTrx,
                dataPengirim,
                dataBank,
                otorDesc,
                isWic,
                isCekRek,
                dataDropDown
            },
            ...modalNasabah
        }}>{children}</bifastContext.Provider>
    )
}

export default () => useContext(bifastContext)