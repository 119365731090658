import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import { useHistory, useLocation, useParams } from 'react-router';
import useDropdowns from '../../../../../hooks/useDropdowns';
import useKeycloak from '../../../../../hooks/keycloak.module';

const raftContext = createContext({
    raftAction: {},
    raftState: {}
})


export function RaftProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const { keycloak } = useKeycloak()
    const { doPost, doGet } = useFetch();
    const [formEditable, setFormEditable] = useState(false);
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    
    const [dataOtor, setDataOtor] = useState({
        kode_entry:"",
        dbId:"",
        callBackSuccess:()=>null
    });

    const location = useLocation();
    const { ePeriodeSiklus } = useDropdowns(["ePeriodeSiklus"])

    const [formData, setFormData] = useState({
        "state": "",
        "nomor_rekening_debit": "",
        "nama_rekening_debit": "",
        "nomor_rekening_kredit": "",
        "nama_rekening_kredit": "",
        "periode_proses": "",
        "jarak_proses_periode": "",
        "tgl_awal": "",
        "tgl_akhir": "",
        "jenis_si": "",
        "nominal_si": "",
        "maksimum_gagal_proses": 0,
        "akumulasi_proses_gagal": true,
        "proses_hanya_saat_tanggal_proses": true
    })
    // fungsi post nasabah

    function resetForm(){
        setFormData({
            "state": "",
            "nomor_rekening_debit": "",
            "nama_rekening_debit": "",
            "nomor_rekening_kredit": "",
            "nama_rekening_kredit": "",
            "periode_proses": "",
            "jarak_proses_periode": "",
            "tgl_awal": "",
            "tgl_akhir": "",
            "jenis_si": "",
            "nominal_si": "",
            "maksimum_gagal_proses": 0,
            "akumulasi_proses_gagal": true,
            "proses_hanya_saat_tanggal_proses": true
        })
    }

    const paramUrl = useParams();
    const history = useHistory();
    // const location = useLocation();
    const [otorDesc, setOtorDesc] = useState("")
    useEffect(function(){
        if(paramUrl.mode === "registration") setFormEditable(true)
        else if(paramUrl.mode === "otorisasi"){
            if (location.state === undefined ) return setDataAlert({ show: true, body:'Tidak ada data terpilih' , title: '', header: "Error", callBack: () => history.goBack() });
            else {
                const { dbId = null,  kode_entry = ""} = location.state
                getDetil(dbId);
            }
        }else {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => history.push("/") });
        }
    },[])

    async function getDetil(pathParam){
        openLoading()

        try {
            let url = `/nasabah/${pathParam}`;
            if (paramUrl.mode === "otorisasi") url = `/aft/otor-view/${pathParam}`;

            setLoading(prevState => ({ ...prevState, loading: true }));
            const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url, service:"acc" });
            if (statusCode === 200) {
                if (status === "00") {
                    if (data !== null) {
                        setFormData(data)
                    }

                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => history.goBack() });
            } else setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => history.goBack() });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => history.goBack() });
        }
        closeLoading();
    }
    async function doTrx() {
        
    closeConfirm();
        // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        const err = validasi();
        if (err) {
            return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        let url = "/aft";
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: formData,
                url, // ganti sesuai api
                service: "acc" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: "Registrasi Form AFT (Automatic Funding Transfer) Berhasil. dibutuhkan otorisasi AFT untuk melanjutkan", title: ``, header: "Success", callBack: () => {
                        resetForm();
                        closeConfirm();
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }
    
    function confirmTrx() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function cekNasabah(nomor_rekening, tipe = 1) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/tabungan-giro/rek-for-trx/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {

                if (status === "00") {
                    const { nama_rekening ="", nama_cabang="",valuta="", nama_produk="" } = data;
                    setFormData(prevState=>({
                        ...prevState,
                        ...(tipe === 1 ? { nama_rekening_debit : nama_rekening } : 
                                tipe === 2 ? { nama_rekening_kredit : nama_rekening } :
                                {}
                            )
                    }));
                    
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }

    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} nasabah ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: ()=> closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
       openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (dataOtor !== null && dataOtor !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.kode_entry}/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "acc",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({ show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {dataOtor.callBackSuccess(); history.goBack();} });
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });

        closeLoading()
    }

    function validasi(fieldRequired = null) {
        //  field required lists
        if (fieldRequired === null) {
            fieldRequired = [
                "nomor_rekening_debit",
                // "nama_rekening_debit",
                "nomor_rekening_kredit",
                // "nama_rekening_kredit",
                "periode_proses",
                "jarak_proses_periode",
                "tgl_awal",
                "tgl_akhir",
                // "jenis_si",
                "nominal_si",
            ];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }
//Post Auditrail
async function auditTrail() {
    const userInfo = await keycloak.loadUserInfo();
    // console.log(userInfo)
    var url = `/useraudit/user-audit-trail`;
    try {
        const { statusCode, status, remark, statusText, data = {} } = await doPost({
            url: url,
            service: "acc",
            data: { 
                    user_id : userInfo.preferred_username,
                    app_id : "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Registrasi",
                    event_description: "Tabungan & Giro/Registrasi AFT",
                    info1: "-",
                    info2: "-"
            },
            headers: {
                "x-user-id": userInfo.preferred_username,
                method: "POST"
            }
        });

        if (statusCode === 200) {
            if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
            else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
        } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    } catch (error) {
        setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    }
    
}
    return <raftContext.Provider value={{
        raftAction: {
            confirmTrx,
            setFormData,
            cekNasabah,
            confirmTrx,
            setOtorDesc,
            responseOtor,
            auditTrail
        },
        raftState: {
            formData,
            formEditable,
            otorDesc,
            ePeriodeSiklus

        }
    }} >{children}</raftContext.Provider>
}

export default function useRaft() { return useContext(raftContext) }