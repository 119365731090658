import React, { memo, useRef } from 'react';
import { useLocation } from 'react-router';
import { AppAction, AppInterfaces } from '../../../../../../app.data';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistrasi } from './formRegistrasi';
import moment from 'moment';

const Step1 = AppInterfaces.appLoad(
    memo(({ store, mode, ...props }) => {
        // const {formData} = store
        const appAction = useRef(null);
        const { detil_saldo, detil_blokir, formData } = store;
        const location = useLocation();
        const dropdownDetil = [
            {
                key: 'eDisposisiDeposito',
                param: 'disposisi_deposito',
            },
            {
                key: 'eDisposisiBagiHasil',
                param: 'disposisi_bg_hasil',
            },
        ];

        // console.log(formData, 'form data');
        return (
            <>
                <AppAction ref={appAction} />
                <div
                    className="row"
                    style={{
                        marginBottom: 30,
                    }}
                >
                    <div
                        className="col-md-7"
                        style={{
                            border: '1pt solid #d2d4d7',
                            margin: '0px 10px',
                            borderRadius: '6px',
                            padding: '15px',
                            paddingTop: '20px',
                            marginBottom: '30px',
                        }}
                    >
                        <h6>{formData.dataNasabah?.nama}</h6>
                        <div className="row">
                            <div className="col">
                                <table>
                                    <tr>
                                        <td>No Deposito</td>
                                        <td> : {formData?.nomorRekeningDeposito} </td>
                                    </tr>
                                    <tr>
                                        <td>No Billyet</td>
                                        <td>: {formData._rekening?.nomorBilyet}</td>
                                    </tr>
                                    <tr>
                                        <td>Kode Cabang</td>
                                        <td>: {formData.kunciCabang}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-md"
                        style={{
                            border: '1pt solid #d2d4d7',
                            margin: '0px 10px',
                            borderRadius: '6px',
                            padding: '15px',
                            paddingTop: '20px',
                            marginBottom: '30px',
                        }}
                    >
                        <span>Saldo</span>
                        <h5 style={{ color: '#E6530D' }}>
                            Rp. {(formData?.balances?.availableBalance || 0).toLocaleString()}
                        </h5>
                        <table>
                            <tr>
                                <td>Cadangan Bagi hasil</td>
                                <td> : 0 </td>
                            </tr>
                            <tr>
                                <td>Info Accrual Bagi Hasil</td>
                                <td>
                                    :{' '}
                                    {(
                                        formData?.accruedAmounts?.interestAccrued || 0
                                    ).toLocaleString()}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <FormRegistrasi
                    itemsForm={[
                        {
                            name: 'Data Nasabah',
                            rows: [
                                {
                                    rows: 1,
                                    label: 'Nomor CIF',
                                    type: 'text',
                                    placeholder: 'CIF',
                                    disabled: true,
                                    readonly: false,
                                    value: formData.dataNasabah?.nomorCIF,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 1,
                                    label: 'Nama',
                                    type: 'text',
                                    placeholder: 'Nama',
                                    disabled: true,
                                    readonly: false,
                                    value: formData.dataNasabah?.nama,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'No Telelpon',
                                                    type: 'text',
                                                    placeholder: 'No Telelpon',
                                                    disabled: true,
                                                    readonly: false,
                                                    value: formData.dataNasabah?.teleponRumah,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'No Handphone',
                                                    type: 'text',
                                                    placeholder: 'No Handphone',
                                                    disabled: true,
                                                    readonly: false,
                                                    value: formData.dataNasabah?.teleponHP,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    placeholder: 'Nama Ibu Kandung ',
                                    type: 'text',
                                    options: [],
                                    label: 'Nama Ibu Kandung',
                                    disabled: true,
                                    readonly: false,
                                    value: formData.dataNasabah?.namaIbuKandung,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-7">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tempat / Tanggal Lahir',
                                                    type: 'text',
                                                    placeholder: 'Tempat',
                                                    value: formData.dataNasabah?.tempatLahir,
                                                    disabled: true,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                            <div className="col-md-5">
                                                {createForm({
                                                    rows: 1,
                                                    label: <>&nbsp;</>,
                                                    type: 'text',
                                                    placeholder: 'tgl lahir',
                                                    disabled: true,
                                                    value: formData.dataNasabah?.tanggalLahir,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    placeholder: 'Alamat',
                                    type: 'text',
                                    options: [],
                                    label: 'Alamat',
                                    disabled: true,
                                    value: formData.dataNasabah?.alamat,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                            ],
                        },
                        {
                            name: 'Data Rekening',
                            rows: [
                                {
                                    rows: 1,
                                    label: 'Nomor Rekening Deposito',
                                    type: 'text',
                                    options: [],
                                    placeholder: ' Nomor Rekening Deposito',
                                    disabled: true,
                                    readonly: false,
                                    value: formData.nomorRekeningDeposito,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 1,
                                    label: 'User Update Terakhir',
                                    type: 'text',
                                    value: '-',
                                    placeholder: 'User Update Terakhir',
                                    disabled: true,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-4">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tgl Buka',
                                                    type: 'text',
                                                    placeholder: 'Tanggal Buka',
                                                    disabled: true,
                                                    value:
                                                        formData._rekening &&
                                                        moment(
                                                            formData._rekening?.tanggalBuka
                                                        ).format('DD-MM-YYYY'),
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                            <div className="col-md-4 pt-4">
                                                {createForm({
                                                    rows: 1,
                                                    label: (
                                                        <span style={{ fontSize: '9pt' }}>
                                                            Sudah Disetor
                                                        </span>
                                                    ),
                                                    type: 'checkbox',
                                                    disabled: true,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                            <div className="col-md-4 pt-4">
                                                {createForm({
                                                    rows: 1,
                                                    label: (
                                                        <span style={{ fontSize: '9pt' }}>
                                                            Sedang dicairkan
                                                        </span>
                                                    ),
                                                    type: 'checkbox',
                                                    disabled: true,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tgl Jatuh Tempo Terakhir',
                                                    type: 'text',
                                                    placeholder: 'Tanggal Jatuh Tempo Terakhir',
                                                    disabled: true,
                                                    value:
                                                        formData._rekening?.tanggalJatuhTempo &&
                                                        moment(
                                                            formData._rekening?.tanggalJatuhTempo
                                                        ).format('DD-MM-YYYY'),
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tgl Jatuh Tempo Berikutnya',
                                                    type: 'text',
                                                    value:
                                                        formData._rekening
                                                            ?.tanggalJatuhTempoBerikutnya &&
                                                        moment(
                                                            formData._rekening
                                                                ?.tanggalJatuhTempoBerikutnya
                                                        ).format('DD-MM-YYYY'),
                                                    placeholder: 'Tanggal Jatuh Tempo Berikutnya',
                                                    disabled: true,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tgl Bagi Hasil Terakhir',
                                                    type: 'text',
                                                    placeholder: 'Tgl Bagi Hasil Terakhir',
                                                    value:
                                                        formData._rekening?.tanggalBagiHasil &&
                                                        moment(
                                                            formData._rekening?.tanggalBagiHasil
                                                        ).format('DD-MM-YYYY'),
                                                    disabled: true,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tgl Bagi Hasil Berikutnya',
                                                    type: 'text',
                                                    value:
                                                        formData._rekening
                                                            ?.tanggalBagiHasilBerikutnya &&
                                                        moment(
                                                            formData._rekening
                                                                ?.tanggalBagiHasilBerikutnya
                                                        ).format('DD-MM-YYYY'),
                                                    placeholder: 'Tgl Bagi Hasil Berkutnya',
                                                    disabled: true,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    label: 'Produk Deposito',
                                    type: 'select',
                                    options: store.deposito_products,
                                    key: 'encodedKey',
                                    display: 'name',
                                    // options: [
                                    //     {
                                    //         key: "Nisbah",
                                    //         display:"Nisbah"
                                    //     },

                                    //     {
                                    //         key: "Pajak Bagi Hasil",
                                    //         display:"Pajak Bagi Hasil"
                                    //     },

                                    //     {
                                    //         key: "Nisbah Bagi Hasil",
                                    //         display:"Nisbah Bagi Hasil"
                                    //     }
                                    // ],
                                    placeholder: 'Pilih produk deposito',
                                    disabled: true,
                                    readonly: false,
                                    value: formData._rekening?.nomorProduk,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    label: 'Valuta',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'valuta',
                                    value: formData._rekening?.valuta,
                                    disabled: true,
                                    readonly: true,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    label: 'Blockir (Yes/Tidak)',
                                    type: 'checkbox',
                                    checked:
                                        formData._blokir !== undefined && formData._blokir !== null,
                                    disabled: true,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Tgl Blokir',
                                                    type: 'text',
                                                    value:
                                                        formData._blokir?.tglBlokir &&
                                                        moment(formData._blokir?.tglBlokir).format(
                                                            'DD-MM-YYYY'
                                                        ),
                                                    placeholder: 'Tgl Blokir',
                                                    disabled: true,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'User Blokir',
                                                    type: 'text',
                                                    value: formData._blokir?.userBlokir,
                                                    placeholder: 'User Blokir',
                                                    disabled: true,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    label: 'Sistem Blokir',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Sistem Blokir',
                                    value: formData._blokir?.sistemBlokir,
                                    disabled: true,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    label: 'Keterangan Blokir',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Keterangan Blokir',
                                    value: formData._blokir?.keterangan,
                                    disabled: true,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                            ],
                        },
                        {
                            name: 'Bagi Hasil & Disposisi',
                            rows: [
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Nisbah Counter              ( % )',
                                                    type: 'number',
                                                    placeholder: 'Nisbah',
                                                    value: formData._bagiHasilDisposisi
                                                        ?.nisbahCounter,
                                                    disabled: true,
                                                    readonly: false,
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Nisbah Akhir   ( % )',
                                                    type: 'number',
                                                    placeholder: 'Nisbah',
                                                    value: formData._bagiHasilDisposisi?.nisbah,
                                                    disabled: true,
                                                    readonly: false,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 1,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Pajak Bagi Hasil   ( % )',
                                                    type: 'number',
                                                    value: formData._bagiHasilDisposisi
                                                        ?.pajakBagiHasil,
                                                    placeholder: 'Pajak Bagi Hasil',
                                                    disabled: true,
                                                    readonly: false,
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Zakat Bagi Hasil   ( % )',
                                                    type: 'number',
                                                    value: formData._bagiHasilDisposisi
                                                        ?.zakatBagiHasil,
                                                    placeholder: 'Zakat Bagi Hasil',
                                                    disabled: true,
                                                    readonly: false,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    rows: 2,
                                    label: 'Disposisi Pokok',
                                    type: 'select',
                                    placeholder: 'Disposisi Pokok',
                                    options: store.disposisi_deposito,
                                    key: 'reference_code',
                                    display: 'reference_desc',
                                    disabled: true,
                                    readonly: false,
                                    value: formData._bagiHasilDisposisi?.instruksiDisposisi,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    label: 'Disposisi Bagi Hasil',
                                    type: 'select',
                                    options: store.disposisi_deposito,
                                    key: 'reference_code',
                                    display: 'reference_desc',
                                    placeholder: 'Pindah Buku',
                                    disabled: true,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: ' Nomor & Nama Rekening',
                                                    type: 'text',
                                                    placeholder: 'No Rekening',
                                                    disabled: true,
                                                    readonly: false,
                                                    value: formData._bagiHasilDisposisi
                                                        ?.nomorRekening,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                            <div className="col-md-6">
                                                {createForm({
                                                    rows: 1,
                                                    label: <>&nbsp;</>,
                                                    type: 'text',
                                                    placeholder: 'Masukan nama',
                                                    disabled: true,
                                                    readonly: false,
                                                    value: formData._bagiHasilDisposisi?.nomorNama,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                            ],
                        },
                    ]}
                />
            </>
        );
    })
);

export default Step1;
