import React from 'react';
import { useParams } from 'react-router';
import usePembayaranAngsuran, { FdProvider } from './usePembayaranAngsuran';
import { FormRegistration, FrameRegistration } from '../../../../controls';
import DataAngsuran from './formangsuran';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';

function PAindex() {
    const { paState, paAction } = usePembayaranAngsuran();
    const { formData = {}, formEditable = false } = paState;
    const { mode } = useParams()
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-2 mb-3">
                    <li className="breadcrumb-item color-primary">Transaksi Nasabah </li>
                    <li className="breadcrumb-item">
                        <a href="#">Pembayaran Angsuran</a>
                    </li>
                </ol>
            </nav>
            <FrameRegistration>
                <div id="step" dataStep={0}>
                    <DataAngsuran/>
                </div>
                <div id="footer">
                    <div className="row">
                        <div className="col">
                            {
                                paState.formEditable && 
                                    <button 
                                        onClick={paAction.confirmTrx} 
                                        disabled={paState.formData?.info_transaksi?.jenis_pembayaran === "" || paState.formData?.info_transaksi?.jenis_pembayaran === null || paState.formData?.info_transaksi?.jenis_pembayaran === undefined }
                                        className="btn btn-primary float-right mr-2">
                                        Simpan
                                    </button>
                            }
                            {
                                paState.formEditable &&
                                    <button 
                                        onClick={paAction.resetForm} 
                                        className="btn btn-outline-danger float-right mr-2">
                                        Reset
                                    </button>
                            }
                            { 
                                mode === "otorisasi" && ( 
                                    <div style={{
                                        width: "fit-content",
                                        float: "right",
                                        borderRight: "1px sold gray",
                                        margin: "0px 5px"
                                    }}>
                                        <button
                                            onClick={() => paAction.responseOtor("APPROVED")}
                                            className="btn btn-success mr-2">
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => paAction.responseOtor("REJECTED")}
                                            className="btn btn-danger mr-2">
                                            Reject
                                        </button>
                                    </div>
                                 ) 
                            } 
                        </div>
                    </div>
                </div>
            </FrameRegistration>
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => paAction.setOtorDesc(e.target.value)}
                    value={paState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )
}
export default () => <FdProvider><PAindex /></FdProvider>