import React, { memo, useEffect, useRef, useState } from 'react';
import { AppInterfaces, AppAction } from '../../../../app.data';
import { useHistory } from 'react-router-dom';
import { TableList } from '../../../controls';
import { Modal, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../app.config';

export default AppInterfaces.appLoad(
    memo(function ({ store, methods, dispatch }) {
        const appAction = useRef(null);
        const { data = [] } = store;
        const [message, setMessage] = useState(null);
        const [mode, setMode] = useState('create');
        const [idHeader, setIdHeader] = useState(null);
        const [showModalHapus, setShowModalHapus] = useState(false);
        const [indexHapus, setIndexHapus] = useState(null);
        const [filter, setFilter] = useState({
            search: '',
            type: null,
        });

        let today = new Date();

        const [headerBodyParam, setHeaderBodyParam] = useState({
            id_user: 'teller-1',
            keterangan: null,
            no_referensi: null,
            nilai_transaksi: 0,
            nilai_ekuivalen: 0,
            cabang: '1001',
            cabangName: 'Head Office',
            tgl_input: today.toISOString().substr(0, 10),
        });

        const [modal, setModal] = useState({
            inputHeader: false,
            confirm: false,
            success: false,
            error: false,
            warning: false,
        });
        const [branch, setBranch] = useState({
            id: '1001',
            name: 'Head Office',
        });

        useEffect(
            function () {
                appAction.current.doGet({
                    url: '/transaksi-umum/header',
                    vari: 'data',
                    param: {
                        page: 0,
                        limit: 15,
                    },
                });
                getBranch();
            },
            []
        );

        const getBranch = () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };
            fetch(
                baseUrl +
                    `/rekening/tabungan/kantor/cabang/8a8e8fab786e635c0178863b7911431e/detail`,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.message_id === '00') {
                        setBranch(data.list);
                        setHeaderBodyParam({
                            ...headerBodyParam,
                            cabang: data.list.id,
                            cabangName: data.list.name,
                        });
                    } else {
                        setBranch({
                            encodedKey: '8a8e8fab786e635c0178863b7911431e',
                            id: '1001',
                            created_at: '2021-03-31T12:49:42+07:00',
                            lastModifiedDate: '2021-03-31T12:49:42+07:00',
                            name: 'Head Office',
                            state: 'ACTIVE',
                            phoneNumber: '+62 21 39708008 ',
                            emailAddress: 'info@banknetsyariah.co.id',
                            notes: '',
                            branchHolidays: [],
                            addresses: [
                                {
                                    encodedKey: '8a8e8fab786e635c0178863b7911431f',
                                    parentKey: '8a8e8fab786e635c0178863b7911431e',
                                    line1: 'Gedung Millennium Centennial Center, Lt 7, Jl. Jenderal Sudirman Kav. 25, , Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta, 12920, Indonesia',
                                    line2: '',
                                    city: 'Kota Jakarta Selatan',
                                    region: 'Jakarta',
                                    postcode: '12920',
                                    country: 'Indonesia',
                                    indexInList: -1,
                                },
                            ],
                        });
                        setHeaderBodyParam({
                            ...headerBodyParam,
                            cabang: '1001',
                            cabangName: 'Head Office',
                        });
                    }
                });
        };

        const filterData = (value = null) => {
            if (value !== '') {
                appAction.current.doGet({
                    url: `/transaksi-umum/header/search`,
                    vari: 'data',
                    param: {
                        page: 0,
                        limit: 15,
                        jenis_transaksi: value ? value : filter.type,
                        search_text: filter.search,
                    },
                });
            }
        };

        const getHeader = () => {
            appAction.current.doGet({
                url: '/transaksi-umum/header',
                vari: 'data',
                param: {
                    page: 0,
                    limit: 15,
                },
            });
        };

        const postHeader = () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify(headerBodyParam),
            };
            fetch(baseUrl + '/transaksi-umum/header/create', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // // console.log(data);
                    if (data.message_id === '00') {
                        setModal({ ...modal, confirm: false, inputHeader: false, success: true });
                        setMessage(data.status);
                        setHeaderBodyParam({
                            id_user: 'teller-1',
                            keterangan: null,
                            no_referensi: null,
                            nilai_transaksi: 0,
                            nilai_ekuivalen: 0,
                            cabang: '1001',
                            cabangName: 'Head Office',
                            tgl_input: today.toISOString().substr(0, 10),
                        });
                    } else {
                        setModal({ ...modal, confirm: false, inputHeader: false, error: true });
                        setMessage(data.status);
                    }
                });
        };

        const putHeader = () => {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify(headerBodyParam),
            };
            fetch(baseUrl + '/transaksi-umum/header/' + idHeader, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // // console.log(data);
                    if (data.message_id === '00') {
                        setModal({ ...modal, confirm: false, inputHeader: false, success: true });
                        setMessage(data.status);
                        setHeaderBodyParam({
                            id_user: 'teller-1',
                            keterangan: null,
                            no_referensi: null,
                            nilai_transaksi: 0,
                            nilai_ekuivalen: 0,
                            cabang: '1001',
                            cabangName: 'Head Office',
                            tgl_input: today.toISOString().substr(0, 10),
                        });
                    } else {
                        setModal({ ...modal, confirm: false, inputHeader: false, error: true });
                        setMessage(data.status);
                    }
                });
        };

        const deleteHeader = (id) => {
            
                const requestOptions = {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    },
                };
                fetch(baseUrl + '/transaksi-umum/header/' + id, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        // // console.log(data);
                        if (data.message_id === '00') {
                            setModal({ ...modal, confirm: false, inputHeader: false, success: true });
                            setMessage(data.status);
                            // getHeader()
                        } else {
                            setModal({ ...modal, confirm: false, inputHeader: false, error: true });
                            setMessage(data.status);
                        }
                    });
            
        };

        let history = useHistory();

        return (
            <>
                <AppAction ref={appAction} />
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Transaksi Umum</li>
                        <li className="breadcrumb-item">
                            <a href="#">Daftar Header Transaksi Umum</a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h5 className="card-title">Daftar Header Transaksi Umum</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8">
                                        <p>Cari Berdasarkan:</p>
                                        <div className="form-row">
                                            <div className="form-group col-4">
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        setFilter({
                                                            ...filter,
                                                            type: e.target.value,
                                                        });
                                                        filterData(e.target.value);
                                                    }}
                                                >
                                                    <option value="">Status</option>
                                                    <option value="OPEN">Open</option>
                                                    <option value="CLOSED">Close</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-4">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cari transaksi umum..."
                                                    onChange={(e) =>
                                                        setFilter({
                                                            ...filter,
                                                            search: e.target.value,
                                                        })
                                                    }
                                                    name="searchText"
                                                    value={filter.search}
                                                />
                                            </div>
                                            <div className="form-group col-3">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => filterData()}
                                                >
                                                    Cari
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right">
                                        <button
                                            onClick={() => {
                                                setMode('create');
                                                setModal({ ...modal, inputHeader: true });
                                                setHeaderBodyParam({
                                                    id_user: 'teller-1',
                                                    keterangan: null,
                                                    no_referensi: null,
                                                    nilai_transaksi: 0,
                                                    nilai_ekuivalen: 0,
                                                    cabang: '1001',
                                                    cabangName: 'Head Office',
                                                    tgl_input: today.toISOString().substr(0, 10),
                                                });
                                            }}
                                            className="btn btn-orange-primary mt-4"
                                        >
                                            Buat Header Transaksi
                                        </button>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <TableList
                                            onChangeShow={(detil) => {
                                                // console.log(detil);
                                                appAction.current.doGet({
                                                    url: '/transaksi-umum/header',
                                                    vari: 'data',
                                                    withAlert: false,
                                                    param: {
                                                        limit: detil.limit,
                                                        page: detil.page,
                                                    },
                                                });
                                            }}
                                            onNextPage={(detil) => {
                                                // console.log(detil);
                                                appAction.current.doGet({
                                                    url: '/transaksi-umum/header',
                                                    vari: 'data',
                                                    withAlert: false,
                                                    param: {
                                                        ...detil,
                                                        page: detil.page - 1,
                                                    },
                                                });
                                            }}
                                            onPrevPage={(detil) => {
                                                // console.log(detil);
                                                appAction.current.doGet({
                                                    url: '/transaksi-umum/header',
                                                    vari: 'data',
                                                    withAlert: false,
                                                    param: {
                                                        ...detil,
                                                        page: detil.page - 1,
                                                    },
                                                });
                                            }}
                                            withCard={false}
                                            columns={[
                                                {
                                                    name: '#',
                                                    field: '#',
                                                    maxWidth: '50px',
                                                },
                                                {
                                                    name: 'Tanggal Input',
                                                    field: 'created_at',
                                                    maxWidth: '50px',
                                                    onRender: function (item) {
                                                        return item?.created_at?.substr(0, 10);
                                                    },
                                                },
                                                {
                                                    name: 'Nomor Referensi',
                                                    field: 'no_referensi',
                                                    maxWidth: '50px',
                                                },
                                                {
                                                    name: 'Status',
                                                    field: 'field4',
                                                    maxWidth: '50px',
                                                    onRender: function (item) {
                                                        return (
                                                            <span className="badge badge-success">
                                                                {item?.status}
                                                            </span>
                                                        );
                                                    },
                                                },
                                                {
                                                    name: 'Balance Status',
                                                    field: 'balance_status',
                                                    maxWidth: '50px',
                                                },
                                                {
                                                    name: 'Deskripsi',
                                                    field: 'keterangan',
                                                    maxWidth: '50px',
                                                },
                                            ]}
                                            data={data}
                                            filter={false}
                                            formsFilter={[
                                                {
                                                    type: 'select',
                                                    options: [
                                                        {
                                                            key: 'OPEN',
                                                            display: 'OPEN',
                                                        },
                                                        {
                                                            key: 'CLOSE',
                                                            display: 'CLOSE',
                                                        },
                                                    ],
                                                    placeholder: 'Status',
                                                    disabled: false,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                },
                                                {
                                                    type: 'text',
                                                    options: [],
                                                    placeholder: 'Cari transaksi umum...',
                                                    disabled: false,
                                                    readonly: false,
                                                },
                                            ]}
                                            onFilter={(e) => null}
                                            resetFilter={false}
                                            submitButton="Cari"
                                            actionItems={[
                                                {
                                                    name: 'Lihat Detail',
                                                    onClick: (e) => {
                                                        history.push(
                                                            `/teller/transaksi-umum/daftar-transaksi-umum?id=${e.id}`
                                                        );
                                                    },
                                                },
                                                {
                                                    name: 'Edit Data',
                                                    onClick: (e) => {
                                                        // console.log(e);
                                                        setIdHeader(e.id);
                                                        setMode('edit');
                                                        setModal({
                                                            ...modal,
                                                            inputHeader: true,
                                                        });
                                                        setHeaderBodyParam({
                                                            id_user: 'teller-1',
                                                            keterangan: e.keterangan,
                                                            no_referensi: e.no_referensi,
                                                            nilai_transaksi: 0,
                                                            nilai_ekuivalen: 0,
                                                            cabang: '1001',
                                                            tgl_input: e.created_at?.substr(0, 10),
                                                        });
                                                    },
                                                },
                                                {
                                                    name: 'Hapus',
                                                    onClick: (e) => {
                                                        setShowModalHapus(true);
                                                        setIndexHapus(e.id);
                                                        // deleteHeader(e.id);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={modal.inputHeader}
                    onHide={() => {
                        setModal({ ...modal, inputHeader: false });
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{mode === 'create' ? 'Input' : 'Edit'} Header</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bns-form">
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label htmlFor="">Tanggal Input</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        readOnly
                                        value={headerBodyParam.tgl_input}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="">Tanggal Efektif</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        readOnly
                                        value={headerBodyParam.tgl_input}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Cabang</label>
                                <div className="form-row">
                                    <div className="col-3 pr0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={headerBodyParam.cabang}
                                            onChange={(e) =>
                                                setHeaderBodyParam({
                                                    ...headerBodyParam,
                                                    cabang: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-9 pl0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={headerBodyParam.cabangName}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Deskripsi</label>
                                <textarea
                                    className="form-control"
                                    rows="1"
                                    value={headerBodyParam.keterangan}
                                    onChange={(e) =>
                                        setHeaderBodyParam({
                                            ...headerBodyParam,
                                            keterangan: e.target.value,
                                        })
                                    }
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">No. Referensi</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={headerBodyParam.no_referensi}
                                    onChange={(e) =>
                                        setHeaderBodyParam({
                                            ...headerBodyParam,
                                            no_referensi: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                                setModal({ ...modal, inputHeader: false });
                            }}
                        >
                            Batal
                        </button>
                        <button
                            className="btn btn-primary ml-3"
                            onClick={() => setModal({ ...modal, confirm: true })}
                        >
                            Simpan Header
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={modal.confirm}
                    onHide={() => {
                        setModal({ ...modal, confirm: false });
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Apakah Anda yakin akan menyimpan header?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setModal({ ...modal, confirm: false })}
                        >
                            Batal
                        </button>
                        <button
                            className="btn btn-primary ml-3"
                            onClick={() => (mode === 'create' ? postHeader() : putHeader())}
                        >
                            Simpan
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={modal.success}
                    onHide={() => {
                        setModal({ ...modal, success: false });
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                                setModal({ ...modal, success: false });
                                getHeader();
                                // window.location.reload();
                            }}
                        >
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={modal.warning}
                    onHide={() => {
                        setModal({ ...modal, warning: false });
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Peringatan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setModal({ ...modal, warning: false })}
                        >
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={modal.error}
                    onHide={() => {
                        setModal({ ...modal, error: false });
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Opps, Terjadi kesalahan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setModal({ ...modal, error: false })}
                        >
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showModalHapus} onHide={() => setShowModalHapus(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Apakah Anda yakin akan menghapus data ini?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setShowModalHapus(false)}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                deleteHeader(indexHapus);
                                setShowModalHapus(false);
                                setIndexHapus(null);
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);
