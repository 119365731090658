import React, { useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import useFormPerorangan from './useFormPerorangan';
import useDropdowns from '../../../../../hooks/useDropdowns';
import moment from 'moment';
import useAlamat from '../../../../../hooks/useAlamat';

const useRelasiAhliWaris = () => {
    const { fpAction, fpState } = useFormPerorangan()
    const [formModal, setFormModal] = useState(false)
    const [formModalRelasiNasabah, setFormModalRelasiNasabah] = useState(false)
    const [formRelasiNasabah, setRelasiNasabah] = useState({
       "RnHubunganDenganNasabah" : "",
       "RnJabatanAtauPangkat" : "",
       "RnNomorNasabah" : "",
       "RnNamaIdentitas" : "",
       "RnAlamat" : "",
       "RnRT" : "",
       "RnRW" : "",
       "RnKelurahan" : "",
       "RnKecamatan" : "",
       "RnKotaKabupaten" : "",
       "RnProvinsi" : "",
       "RnKodePos" : "",
       "RnCodeTeleponRumah" : "",
       "RnTeleponRumah" : "",
       "RnHandphone" : "",
       "RnCodeFax" : "",
       "RnFax" : "",
       "RnEmail" : "",
       "RnJenisIdentitas" : "",
       "RnNomorIdentitas" : "",
       "RnNomorNPWP" : "",
       "RnKewarganegaraan" : "",
       "RnNegaraAsal" : "",
       "RnPekerjaan" : ""
        })
    const [formPejabat, setFormPejabat] = useState({
       "AwNoUrutPrioritas" : "",
       "AwNamaLengakap" : "",
       "AwJenisKelamin" : "",
       "AwHubunganKeluarga" : "",
       "AwKeterangan" : ""
        })
    const [isNasabah, setIsNasabah] = useState(false)
    const [mode, setMode] = useState("CREATE")
    const { doGet } = useFetch();

     // alamat 1
     const [districts, setDistricts] = useState([])
     const [villages, setvillages] = useState([])
     const [cities, setCities] = useState([])
     const [postalCodes, setPostalCodes] = useState([])
 
     const {
     provinces,
     getCities,
     getVillages,
     getPostalsCode,
     getDistrics
     } = useAlamat();

    // resetFormRelasiNasabah
    function resetFormRelasiNasabah() {
        setRelasiNasabah({
            "RnHubunganDenganNasabah" : "",
            "RnJabatanAtauPangkat" : "",
            "RnNomorNasabah" : "",
            "RnNamaIdentitas" : "",
            "RnAlamat" : "",
            "RnRT" : "",
            "RnRW" : "",
            "RnKelurahan" : "",
            "RnKecamatan" : "",
            "RnKotaKabupaten" : "",
            "RnProvinsi" : "",
            "RnKodePos" : "",
            "RnTeleponRumah" : "",
            "RnHandphone" : "",
            "RnFax" : "",
            "RnEmail" : "",
            "RnJenisIdentitas" : "",
            "RnNomorIdentitas" : "",
            "RnNomorNPWP" : "",
            "RnKewarganegaraan" : "",
            "RnNegaraAsal" : "",
            "RnPekerjaan" : ""
        })
    }
    function closeModalRelasiNasbah() {
        resetFormRelasiNasabah();
        setFormModalRelasiNasabah(false)
    }
    function openModalAddRelasiNasbah() {
        setMode("CREATE")
        resetFormRelasiNasabah();
        setFormModalRelasiNasabah(true);
    }
    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        if (subkey !== null) setRelasiNasabah(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setRelasiNasabah(prevState => ({ ...prevState, [key]: value }));
    }
    function openDetil(key, m = "DETAIL") {
        setMode(m)
        // // console.log(key.RnNomorNasabah)
        let data = { ...fpState.formData?._relasiNasabah?.[key], key };
        getCariNasabah(key.RnNomorNasabah);
        setFormModalRelasiNasabah(true)
        setRelasiNasabah(key);
        // if (!data)
        // resetFormRelasiNasabah()
    }
    function cekNasabah(e) {
        resetFormRelasiNasabah(prevState => ({
            ...prevState,
            RnNomorNasabah: ""
        }))
        setIsNasabah(e)
    } 
    function save() {
        // if (formRelasiNasabah?.RnHubunganDenganNasabah === '' || formRelasiNasabah?.RnHubunganDenganNasabah === undefined || formRelasiNasabah?.RnHubunganDenganNasabah === 0) {
        //     fpAction.setDataAlert({ show: true, body: "Hubungan Nasabah Belum di pilih", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // } 
        // else if (formRelasiNasabah?.RnJabatanAtauPangkat === '' || formRelasiNasabah?.RnJabatanAtauPangkat === undefined || formRelasiNasabah?.RnJabatanAtauPangkat === 0) {
        //     fpAction.setDataAlert({ show: true, body: "Jabatan atau Pangkat belum pilih", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        // else if (formRelasiNasabah?.RnNamaIdentitas === '' || formRelasiNasabah?.RnNamaIdentitas === undefined || formRelasiNasabah?.RnNamaIdentitas === 0) {
        //     fpAction.setDataAlert({ show: true, body: "Nama (sesuai identitas belum diisi) belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        if (formRelasiNasabah?.RnNomorNasabah === '' || formRelasiNasabah?.RnNomorNasabah === undefined || formRelasiNasabah?.RnNomorNasabah === 0) {
            fpAction.setDataAlert({ show: true, body: "Nomor Nasabah belum di isi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else {
            const currentData = fpState.formData?._relasiNasabah;
            if (mode === "CREATE") {
                formRelasiNasabah['action']= 'N'
                if (currentData === undefined) {
                    const newData = [formRelasiNasabah];
                    fpAction.changeForm(newData, "_relasiNasabah");
                }else {
                    const newData = [...currentData, formRelasiNasabah];
                    fpAction.changeForm(newData, "_relasiNasabah");
                }
            }
            else if (mode === "EDIT") {
                formRelasiNasabah['action']= 'U'
                const newDataForm = { ...formRelasiNasabah }
                delete newDataForm.key;
                currentData[formRelasiNasabah.key] = newDataForm;
                fpAction.changeForm(currentData, "_relasiNasabah");
            }
            closeModalRelasiNasbah()
        }
    }
    // // console.log(formRelasiNasabah);

    function del(key) {
        const currentData = fpState.formData?._relasiNasabah;
        currentData[key]['action'] = 'D'
        fpAction.changeForm(currentData, "_relasiNasabah");
    }
    // function carinasabah
    async function getCariNasabah( i = null) {
        let cif = formRelasiNasabah.RnNomorNasabah;
        if(i !== null ) cif = i
        fpAction.setLoading(prevState => ({ ...prevState, loading: true }));
        try {
            const resp = await doGet({
                url: `/nasabah/${cif}`,
                service: "cif"
            });
            // // console.log(formRelasiNasabah);
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    const {nasabahBadanUsaha} = data;
                    setRelasiNasabah(prevState => ({
                        ...prevState,
                        // "RnHubunganDenganNasabah": nasabahBadanUsaha?.RnHubunganDenganNasabah?.toUpperCase() || '',
                        // "RnJabatanAtauPangkat": nasabahBadanUsaha?.RnJabatanAtauPangkat?.toUpperCase() || '',
                        "RnNamaIdentitas": nasabahBadanUsaha?.personalIdHolderName?.toUpperCase() || '',
                        "RnAlamat": nasabahBadanUsaha?.ktpAddress?.toUpperCase() || '',
                        "RnRT": nasabahBadanUsaha?.ktpRt?.toUpperCase() || '',
                        "RnRw": nasabahBadanUsaha?.ktpRw?.toUpperCase() || '',
                        "RnKelurahan": nasabahBadanUsaha?.ktpVillage?.toUpperCase() || '',
                        "RnKecamatan": nasabahBadanUsaha?.ktpSubDistrict?.toUpperCase() || '',
                        "RnKotaKabupaten": nasabahBadanUsaha?.ktpCity?.toUpperCase() || '',
                        "RnKodePos": nasabahBadanUsaha?.ktpPostalCode?.toUpperCase() || '',
                        "RnCodeTeleponRumah": nasabahBadanUsaha?.RnCodeTeleponRumah?.toUpperCase() || '',
                        "RnTeleponRumah": nasabahBadanUsaha?.RnTeleponRumah?.toUpperCase() || '',
                        "RnHandphone": nasabahBadanUsaha?.homePhone?.toUpperCase() || '',
                        "RnCodeFax": nasabahBadanUsaha?.RnCodeFax?.toUpperCase() || '',
                        "RnFax": nasabahBadanUsaha?.RnFax?.toUpperCase() || '',
                        "RnEmail": nasabahBadanUsaha?.emailAddress?.toUpperCase() || '',
                        "RnJenisIdentitas": nasabahBadanUsaha?.personalIdType?.toUpperCase() || '',
                        "RnNomorIdentitas": nasabahBadanUsaha?.personalIdNumber?.toUpperCase() || '',
                        "RnNomorNPWP": nasabahBadanUsaha?.personalNpwpNumber?.toUpperCase() || '',
                        "RnKewarganegaraan": nasabahBadanUsaha?.personalCitizenship?.toUpperCase() || '',
                        "RnNegaraAsal": nasabahBadanUsaha?.ktpCountry?.toUpperCase() || '',
                        "RnPekerjaan": nasabahBadanUsaha?.personalOccupation?.toUpperCase() || '',
                        "RnProvinsi": nasabahBadanUsaha?.ktpProvince?.toUpperCase() || '',
                    }));
                }
                else fpAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else fpAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            fpAction.setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        } 
        fpAction.setLoading(prevState => ({ ...prevState, loading: false }))
    }

      // function carinasabah
      async function getCariNasabahButton( ) {
        let cif = formRelasiNasabah.RnNomorNasabah;
        // if(i !== null ) cif = i
        fpAction.setLoading(prevState => ({ ...prevState, loading: true }));
        try {
            const resp = await doGet({
                url: `/nasabah/${cif}`,
                service: "cif"
            });
            // // console.log(formRelasiNasabah);
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    const {nasabahBadanUsaha} = data;
                    setRelasiNasabah(prevState => ({
                        ...prevState,
                        // "RnHubunganDenganNasabah": nasabahBadanUsaha?.RnHubunganDenganNasabah?.toUpperCase() || '',
                        // "RnJabatanAtauPangkat": nasabahBadanUsaha?.RnJabatanAtauPangkat?.toUpperCase() || '',
                        "RnNamaIdentitas": nasabahBadanUsaha?.personalIdHolderName?.toUpperCase() || '',
                        "RnAlamat": nasabahBadanUsaha?.ktpAddress?.toUpperCase() || '',
                        "RnRT": nasabahBadanUsaha?.ktpRt?.toUpperCase() || '',
                        "RnRw": nasabahBadanUsaha?.ktpRw?.toUpperCase() || '',
                        "RnKelurahan": nasabahBadanUsaha?.ktpVillage?.toUpperCase() || '',
                        "RnKecamatan": nasabahBadanUsaha?.ktpSubDistrict?.toUpperCase() || '',
                        "RnKotaKabupaten": nasabahBadanUsaha?.ktpCity?.toUpperCase() || '',
                        "RnKodePos": nasabahBadanUsaha?.ktpPostalCode?.toUpperCase() || '',
                        "RnCodeTeleponRumah": nasabahBadanUsaha?.RnCodeTeleponRumah?.toUpperCase() || '',
                        "RnTeleponRumah": nasabahBadanUsaha?.RnTeleponRumah?.toUpperCase() || '',
                        "RnHandphone": nasabahBadanUsaha?.homePhone?.toUpperCase() || '',
                        "RnCodeFax": nasabahBadanUsaha?.RnCodeFax?.toUpperCase() || '',
                        "RnFax": nasabahBadanUsaha?.RnFax?.toUpperCase() || '',
                        "RnEmail": nasabahBadanUsaha?.emailAddress?.toUpperCase() || '',
                        "RnJenisIdentitas": nasabahBadanUsaha?.personalIdType?.toUpperCase() || '',
                        "RnNomorIdentitas": nasabahBadanUsaha?.personalIdNumber?.toUpperCase() || '',
                        "RnNomorNPWP": nasabahBadanUsaha?.personalNpwpNumber?.toUpperCase() || '',
                        "RnKewarganegaraan": nasabahBadanUsaha?.personalCitizenship?.toUpperCase() || '',
                        "RnNegaraAsal": nasabahBadanUsaha?.ktpCountry?.toUpperCase() || '',
                        "RnPekerjaan": nasabahBadanUsaha?.personalOccupation?.toUpperCase() || '',
                        "RnProvinsi": nasabahBadanUsaha?.ktpProvince?.toUpperCase() || '',
                    }));
                }
                else fpAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else fpAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            fpAction.setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        } 
        fpAction.setLoading(prevState => ({ ...prevState, loading: false }))
    }

    // resetForm
    function resetForm() {
        setFormPejabat({
            "AwNoUrutPrioritas" : "",
            "AwNamaLengakap" : "",
            "AwJenisKelamin" : "",
            "AwHubunganKeluarga" : "",
            "AwKeterangan" : ""
        })
    }

    // close Modal
    function closeModal() {
        resetForm();
        setFormModal(false)
    }
    // open Modal Add
    function openModalAdd() {
        setMode("CREATE")
        resetForm();
        setFormModal(true);
    } 
     // fungsi setDataForm
     function changeForm(value, key, subkey = null) {
        if (subkey !== null) setFormPejabat(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormPejabat(prevState => ({ ...prevState, [key]: value }));
    }
    function openDetilAW(key, m = "DETAIL") {
        setMode(m)
        let data = { ...fpState.formData?._relasiAhliWaris?.[key], key };
        setFormModal(true)
        setFormPejabat(data);
        if (!data)
            resetForm()
    }
     // save function

     function saveAW() {
       if ((formPejabat?.AwJenisKelamin === '' || formPejabat?.AwJenisKelamin === undefined) || formPejabat?.AwJenisKelamin === 0) {
            fpAction.setDataAlert({ show: true, body: "Jenis Kelamin belum di isi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else {
            const currentData = fpState.formData?._relasiAhliWaris;
            if (mode === "CREATE") {
                formPejabat['action']= 'N'
                if (currentData === undefined) {
                    const newData = [formPejabat];
                    fpAction.changeForm(newData, "_relasiAhliWaris");
                } else {
                    const newData = [...currentData, formPejabat];
                    fpAction.changeForm(newData, "_relasiAhliWaris");
                }
            }
            else if (mode === "EDIT") {
                formPejabat['action']= 'U'
                const newDataForm = { ...formPejabat }
                delete newDataForm.key;
                currentData[formPejabat.key] = newDataForm;
                fpAction.changeForm(currentData, "_relasiAhliWaris");
            }
            // // console.log(formPejabat);
            closeModal()
        }
    }
    //// console.log(formPejabat);
    function delAW(key) {
        const currentData = fpState.formData?._relasiAhliWaris;
        currentData[key]['action'] = 'D'
        fpAction.changeForm(currentData, "_relasiAhliWaris");
    }
    // get province_code
    async function getProvinceCode(province_name) {
        if (provinces !== null && provinces !== undefined) {
            const data = provinces?.find(v => {
                return v.province_name === province_name
            });
            return data?.province_code || "";
        }
    }
    // get cities Relasi Nasabah
    async function getingCitiesRelasi(province_code) {
        const data = await getCities(province_code);
        const newData = data.map(v => ({ key: v, display: v }));
        setCities(newData);
    }
    // get districts Relasi Nasabah
    async function getingDistrictRelasi(city) {
        const data = await getDistrics(city);
        const newData = data.map(v => ({ key: v, display: v }));
        setDistricts(newData);
    }
    // get village Relasi Nasabah
    async function getingVillagesRelasi(city, subdistrict) {
        const data = await getVillages(city, subdistrict);
        const newData = data.map(v => ({ key: v, display: v }));
        setvillages(newData);
    }
    // get postalcode Relasi Nasabah
    async function getingPostalCodesRelasi(city, subdistrict, urban) {
        const data = await getPostalsCode(city, subdistrict, urban);
        const newData = data.map(v => ({ key: v, display: v }));
        setPostalCodes(newData);
    }
    return {
        pkState: {
            formPejabat,
            isNasabah,
            formModal,
            formRelasiNasabah,
            formModalRelasiNasabah,
            mode,
            provinces,
            districts,
            villages,
            cities,
            postalCodes

        },
        pkAction: {
            closeModal,
            openModalAdd,
            setFormPejabat,
            setRelasiNasabah,
            cekNasabah,
            save,
            openDetil,
            del,
            changeForm,
            saveAW,
            openDetilAW,
            delAW,
            changeForm,
            openModalAddRelasiNasbah,
            closeModalRelasiNasbah,
            getCariNasabah,
            getCariNasabahButton,
            getProvinceCode,
            getingCitiesRelasi,
            getingDistrictRelasi,
            getingVillagesRelasi,
            getingPostalCodesRelasi,
        }
    };
}

export default useRelasiAhliWaris;