export const amountToWord = (number) => {
    var NS = [
        { value: 1000000000000000, str: 'Kuadriliun' },
        { value: 1000000000000, str: 'Triliun' },
        { value: 1000000000, str: 'Miliar' },
        { value: 1000000, str: 'Juta' },
        { value: 1000, str: 'Ribu' },
        { value: 100, str: 'Ratus' },
        { value: 90, str: 'Sembilan Puluh' },
        { value: 80, str: 'Delapan Puluh' },
        { value: 70, str: 'Tujuh Puluh' },
        { value: 60, str: 'Enam Puluh' },
        { value: 50, str: 'Lima Puluh' },
        { value: 40, str: 'Empat Puluh' },
        { value: 30, str: 'Tiga Puluh' },
        { value: 20, str: 'Dua Puluh' },
        { value: 19, str: 'Sembilan Belas' },
        { value: 18, str: 'Delapan Belas' },
        { value: 17, str: 'Tujuh Belas' },
        { value: 16, str: 'Enam Belas' },
        { value: 15, str: 'Lima Belas' },
        { value: 14, str: 'Empat Belas' },
        { value: 13, str: 'Tiga Belas' },
        { value: 12, str: 'Dua Belas' },
        { value: 11, str: 'Sebelas' },
        { value: 10, str: 'Sepuluh' },
        { value: 9, str: 'Sembilan' },
        { value: 8, str: 'Delapan' },
        { value: 7, str: 'Tujuh' },
        { value: 6, str: 'Enam' },
        { value: 5, str: 'Lima' },
        { value: 4, str: 'Empat' },
        { value: 3, str: 'Tiga' },
        { value: 2, str: 'Dua' },
        { value: 1, str: 'Satu' },
    ];

    var result = '';

    for (var n of NS) {
        if (number >= n.value) {
            if (number <= 99) {
                result += n.str;
                number -= n.value;
                if (number > 0) result += ' ';
            } else {
                var t = Math.floor(number / n.value);
                var d = number % n.value;

                if (d > 0) {
                    if (t === 1 && number < 2000 && number >= 1000)
                        return 'Seribu' + ' ' + amountToWord(d); // eslint-disable-line

                    if (t === 1 && number < 200 && number >= 100)
                        return 'Seratus' + ' ' + amountToWord(d); // eslint-disable-line

                    return amountToWord(t) + ' ' + n.str + ' ' + amountToWord(d);
                } else {
                    if (t === 1 && number < 2000 && number >= 1000) return 'Seribu';

                    if (t === 1 && number < 200 && number >= 100) return 'Seratus';

                    return amountToWord(t) + ' ' + n.str;
                }
            }
        }
    }

    return result;
};
