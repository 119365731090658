import React from 'react';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistration } from '../../../../../controls';
import useDeposito from '../useDeposito';
import Select from 'react-select';
import { addCommas } from '../../../../../../module';


const DetilDeposito = () => {
    const { rekState, rekAction } = useDeposito();
    const { rekSelected = {}, formEditable = false } = rekState;

    return ( 
        <>
        <div className="pb-4">
                <div className="box">
                    <div className="row">
                        <div className="col-6 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nomor Rekening Deposito</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="Otomatis terisi dari sistem"
                                    value={rekSelected?.nomor_rekening}
                                />
                            </div>
                        </div>
                        <div className="col-6 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nilai Deposito</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="Otomatis terisi dari sistem"
                                    value={ addCommas ( parseFloat(rekSelected?.saldo_deposito || 0).toFixed(2)) }
                                    disabled={!rekState.formEditable || rekState.paramUrl?.mode === "edit"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 align-self-center bns-form">
                        </div>
                        <div className="col-8 text-right align-self-end">
                            <div>
                                <span className="ml-5 text-grey">Kode Cabang</span>
                                <span className="text-grey2 ml-4">: </span>
                                <span className="text-grey2">{rekSelected?.nama_cabang}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FormRegistration
                itemsForm={[
                    // Data deposito
                    {
                        name: "Data Nasabah",
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-2">
                                                <label>Nomor CIF</label>
                                                <span className="badge badge-secondary">
                                                    Wajib
                                                </span>
                                                <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        type="number"
                                                        required
                                                        className="form-control form-control-sm col-9"
                                                        placeholder="Masukan nomor CIF"
                                                        value = {rekState.rekSelected?.nomor_nasabah}
                                                        disabled={true}
                                                    />
                                                    <div className="input-group-append col-3 pl-2 pr-0">
                                                        <button disabled={true} className="btn btn-outline-danger">
                                                            Cari Nasabah . . . 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: 'Nama Lengkap',
                                type: 'number',
                                placeholder: 'Nama Lengkap',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.nama_lengkap,
                                disabled: true
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col-4">
                                        {
                                            createForm({
                                                label: 'No Telepon',
                                                type: 'number',
                                                placeholder: 'masukan nomor telepon',
                                                required: false,
                                                readOnly:true,
                                                value: rekState.rekSelected?.telepon_rumah_nomor,
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'No Handphone',
                                                type: 'number',
                                                placeholder: 'masukan handphone',
                                                readOnly:true,
                                                required: false,
                                                value: rekState.rekSelected?.telepon_hp_nomor,
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col-4">
                                        {
                                            createForm({
                                                label: 'Tempat / Tanggal Lahir',
                                                type: 'text',
                                                placeholder: 'Masukan tempat',
                                                value: rekState.rekSelected?.tempat_lahir,
                                                required: false,
                                                disabled: true
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: <>&nbsp;</>,
                                                type: 'text',
                                                placeholder: 'Tanggal lahir',
                                                value: rekState.rekSelected?.tanggal_lahir,
                                                disabled: true,
                                                required: false
                                            })
                                        }
                                    </div>
                                </div>)
                            },
                            {
                                rows: 2,
                                label: 'Alamat',
                                type: 'textarea',
                                placeholder: 'Masukan alamat',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.alamat,
                                // onChange: (value) => fdAction.changeForm(value, "alamat", "_dataNasabah"),
                                disabled: !rekState.formEditable
                            },
                    ]},
                    // End Data deposito
                    // rekening
                    {
                        name: "Data Rekening",
                        rows: [
                            {
                            rows: 1,
                                label: 'Nama Rekening',
                                type: 'number',
                                placeholder: 'Nama Rekening',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.nama_rekening,
                                disabled: true
                            },
                            {
                            rows: 1,
                                label: 'Nomor Bilyet',
                                type: 'number',
                                placeholder: 'Nama Bilyet',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.nomor_bilyet,
                                disabled: true
                            },
                            {
                                rows: 1,
                                type: 'number',
                                label: 'Nomor Rekening Deposito',
                                placeholder: 'Masukan nomor rekening Deposito',
                                required: false,
                                value: rekState.rekSelected?.nomor_rekening,
                                // onChange: (value) => fdAction.changeForm(value, "nomor_rekening", "_dataRekening"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Tanggal Buka',
                                                type: 'date',
                                                required: false,
                                                value: rekState.rekSelected?.tanggal_buka,
                                                // onChange: (value) => fdAction.changeForm(value, "tanggalBuka", "_informasiLain"),
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Tanggal Jatuh Tempo',
                                                type: 'date',
                                                required: false,
                                                readOnly:true,
                                                value: rekState.rekSelected?.tanggal_jatuh_tempo_berikutnya,
                                                // onChange: (value) => fdAction.changeForm(value, "tanggalJatuhTempo", "_informasiLain"),
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                </div>   
                                )
                            },
                            {
                                rows: 2,
                                label: 'Produk Deposito',
                                type: 'text',
                                required: false,
                                value: rekState.rekSelected?.nama_produk,
                                // onChange: (value) => fdAction.changeForm(value, "produkTabungan"),
                                disabled: !rekState.formEditable || true
                            },
                            {
                                rows: 2,
                                label: 'Valuta',
                                type: 'text',
                                placeholder: 'Otomatis terisi',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.valuta,
                                // onChange: (value) => fdAction.changeForm(value, "valuta"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Nominal Deposito',
                                type: 'text',
                                placeholder: 'Nominal Deposito',
                                required: false,
                                readOnly:true,
                                value: addCommas( parseFloat( rekState.rekSelected?.saldo_deposito || 0).toFixed(2)),
                                // onChange: (value) => fdAction.changeForm(value, "valuta"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Keterangan',
                                type: 'text',
                                placeholder: 'Keterangan',
                                required: false,
                                readOnly:true,
                                value: rekState.rekSelected?.keterangan,
                                // onChange: (value) => fdAction.changeForm(value, "valuta"),
                                disabled: !rekState.formEditable
                            },
                            
                    ]},
                    // End Rekening
                    // bagi hasil&desposisi
                    {
                        name: "Bagi Hasil & Disposisi",
                        rows: [
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Nisbah Spesial',
                                                type: 'number',
                                                required: false,
                                                placeholder: '%',
                                                value: rekState.rekSelected?.nisbah_spesial || 0,
                                                // onChange: (value) => fdAction.changeForm(value, "nisbahCounter", "_informasiLain"),
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Nisbah Berdasarkan Produk',
                                                type: 'number',
                                                required: false,
                                                placeholder: '%',
                                                readOnly:true,
                                                value: rekState.rekSelected?.nisbah_dasar || 0,
                                                // onChange: (value) => fdAction.changeForm(value, "nisbahZakat", "_informasiLain"),
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Pajak Bagi Hasil',
                                                type: 'number',
                                                required: false,
                                                placeholder: '%',
                                                value: rekState.rekSelected?.tarif_pajak || 0,
                                                // onChange: (value) => fdAction.changeForm(value, "nisbahCounter", "_informasiLain"),
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        {
                                            createForm({
                                                label: 'Zakat Bagi Hasil',
                                                type: 'number',
                                                required: false,
                                                placeholder: '%',
                                                readOnly:true,
                                                value: rekState.rekSelected?.persentase_zakat_bagi_hasil || 0,
                                                // onChange: (value) => fdAction.changeForm(value, "nisbahZakat", "_informasiLain"),
                                                disabled: !rekState.formEditable
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            {
                                rows: 1,
                                label: 'Nisbah total',
                                type: 'currency',
                                placeholder: 'nisbah total',
                                required: false,
                                value: rekState.rekSelected?.nisbah_bagi_hasil,
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Intruksi Bagi Hasil',
                                type: 'select',
                                placeholder: 'Pilih Intruksi Bagi Hasil',
                                options: rekState.dataDropDown?.eDisposisiBagiHasil,
                                key: "reference_code",
                                display: "reference_desc",
                                required: false,
                                value: rekState.rekSelected?.disposisi_bagi_hasil,
                                // onChange: (value) => fdAction.changeForm(value, "instruksi_bagi_hasil","_rekeningDeposito"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col-7">
                                        {
                                            createForm({
                                                label: 'Rekening Penerima Bagi Hasil',
                                                type: 'text',
                                                required: true,
                                                placeholder: 'No. Rekening',
                                                value: rekState.rekSelected?.rekening_disposisi,
                                                value: rekState.rekSelected?.nomor_rekening_bagihasil,
                                                disabled: true,
                                            })
                                        }
                                    </div>
                                    <div className="col-5">
                                        {
                                            createForm({
                                                label: <>&nbsp;</>,
                                                type: 'text',
                                                required: false,
                                                placeholder: 'Masukan nama',
                                                readOnly: true,
                                                value: rekState.rekSelected?.nama_rekening_bagihasil,
                                                disabled: true
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Intruksi Jatuh Tempo',
                                type: 'select',
                                placeholder: 'Pilih Intruksi Jatuh Tempo',
                                options: rekState.dataDropDown?.eDisposisiDeposito,
                                key: "reference_code",
                                display: "reference_desc",
                                required: false,
                                value: rekState.rekSelected?.disposisi_nominal,
                                // onChange: (value) => fdAction.changeForm(value, "instruksi_jatuh_tempo","_rekeningDeposito"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                <div className="row">
                                    <div className="col-7">
                                        {
                                            createForm({
                                                label: 'Nomor & Nama Rekening Pencairan',
                                                type: 'number',
                                                required: false,
                                                placeholder: 'No. Rekening',

                                                
                                                value: rekState.rekSelected?.rekening_disposisi,
                                                // onChange: (value) => fdAction.changeForm(value, "no_rekening_pencairan","_rekeningDeposito"),
                                                disabled: true
                                            })
                                        }
                                    </div>
                                    <div className="col-5">
                                        {
                                            createForm({
                                                label: <>&nbsp;</>,
                                                type: 'text',
                                                required: false,
                                                placeholder: 'Masukan nama',

                                                
                                                value: rekState.rekSelected?.penerima_transfer_disposisi,
                                                // onChange: (value) => fdAction.changeForm(value, "nama_rekening_pencairan","_rekeningDeposito"),
                                                disabled: true
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                    ]},
                    // End bagi hasil&desposisi
                    // Informasi Lain
                    {
                        name: "Informasi Lain",
                        rows: [
                            {
                                rows: 1,
                                type: "select",
                                label: "Tujuan Pembukaan Rekening",
                                required: true,
                                placeholder: "Pilih tujuan pembukaan rekening",
                                options: rekState.dataDropDown?.R_TUJUAN_BUKA_REKENING,
                                key: "reference_code",
                                display: "reference_desc",
                                value: rekState.rekSelected?.kode_tujuan_rekening,
                                // onChange: (value) => fdAction.changeForm(value, "tujuanPembukaanRekening", "_informasiLain"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 1,
                                type: "select",
                                label: "Sumber Dana",
                                required: true,
                                placeholder: "Pilih sumber dana",
                                options: rekState.dataDropDown?.R_SUMBER_PENGHASILAN,
                                key: "reference_code",
                                display: "reference_desc",
                                value: rekState.rekSelected?.kode_sumber_dana,
                                // onChange: (value) => fdAction.changeForm(value, "sumberDana", "_informasiLain"),
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 1,
                                type: "select",
                                label: "Data Instansi",
                                required: false,
                                placeholder: "Pilih data instansi",
                                options: (rekState.dataInstansi || []).map(ins => ({
                                    ...ins,
                                    nama_instansi: `${ins.kode_instansi} - ${ins.nama_instansi}`
                                })),
                                key: "kode_instansi",
                                display: "nama_instansi",
                                value: rekState.rekSelected?.code_instansi,
                                disabled: !rekState.formEditable
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12 pl-3 pr-3">
                                            <div className="form-group form-group-sm">
                                                <label>Marketing inisiasi </label>
                                                <Select
                                                     placeholder="Pilih marketing inisiasi"
                                                    value={
                                                        rekState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao })).find(c => c.value === rekState.rekSelected?.kode_marketing_pertama)
                                                        }
                                                    isDisabled={!rekState.formEditable}
                                                    options={rekState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao }))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12 pl-3 pr-3">
                                            <div className="form-group form-group-sm">
                                                <label>Marketing Maintenance </label>
                                                <Select
                                                    placeholder="Pilih marketing Maintenance"value={ 
                                                        rekState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao })).find(c => c.value === rekState.rekSelected?.kode_marketing_current) 
                                                    }
                                                    isDisabled={!rekState.formEditable }
                                                    options={rekState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao }))}
                                                    // placeholder="Pilih marketing Maintenance"
                                                    // value={ rekState.rekSelected?.kode_marketing_current }
                                                    // isDisabled={!rekState.formEditable}
                                                    // options={rekState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao }))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                    ]},
                ]}
            />
        </>
     );
}
 
export default DetilDeposito;