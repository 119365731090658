import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { NewLoading, TableList } from '../../../controls';
import useInfoDetailsTell, { InfoDetailsTellProvider } from './useTellerDetail';
import useInfoTeller, { InfoTellerProvider } from '../informasi_teller/useInformasiTeller';
import { Tab, Nav, Button } from 'react-bootstrap';
import { addCommas } from '../../../../module';

function InfoDetailsTell() {
    const { tdState, tdAction } = useInfoDetailsTell()
    const { itState, itAction } = useInfoTeller()
    return (
        <>
           <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Informasi Teller</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        <a className="text-primary-bds" href="#">Data Teller</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Informasi Kas Teller
                    </li>
                </ol>
            </nav>
            <div className="row mb-1">
                <div className="col-md-12">
                    <div className="card">
                        <div className="car-body m-4">
                            {/* <h1 className="mb-4">{tdState.dataDetail?.user_id}</h1> */}
                            <h1 className="mb-4">{tdState.dataDetail?.nama_user}</h1>
                            <p>Kode Rekening : {tdState.dataDetail?.nomor_rekening}</p>
                            <p>Kantor Cabang :  {tdState.dataDetail?.nama_cabang}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="car-body text-center m-4">
                            {tdState.isLoading ? <Skeleton height="30px" width="60%"/> :<h3>Total Saldo Kas Teller : <span className="text-base">RP. {addCommas(parseFloat(tdState.dataDetail?.saldo)?.toFixed(2))} </span></h3>}                                                                                                                         
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                               <h1><strong>INFORMASI KAS TELLER</strong></h1> 
                            </div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                minHeight="450px"
                                maxHeight="500px"
                                onChangeShow={tdAction.changeShow}
                                onNextPage={tdAction.nextPage}
                                onPrevPage={tdAction.prevPage}
                                withActions={false}
                                columns={[
                                    {
                                        name: 'Jam Input',
                                        field: 'jam_input',
                                    },
                                    {
                                        name: 'Jenis Transaksi',
                                        field: 'jenis_transaksi',
                                        //onRender: (item) => item.jenis_mutasi === "C" ? "Kredit" : "Debit"
                                    },
                                    {
                                        name: 'Nilai Mutasi',
                                        field: 'nilai_mutasi',
                                        className:"text-right",
                                        onRender: (item) => addCommas(parseFloat(item.nilai_mutasi)?.toFixed(2))
                                    },
                                    {
                                        name: 'Referensi',
                                        field: 'nomor_referensi',
                                        width: '40px',
                                    },
                                    {
                                        name: 'Keterangan',
                                        field: 'keterangan',
                                    },
                                    {
                                        name: 'Status Otorisasi',
                                        field: 'status_otorisasi',
                                    },
                                    {
                                        name: 'User Otor',
                                        field: 'user_otor',
                                    },
                                ]}
                                data={tdState.List}
                                isLoading={tdState.isLoading}
                                filter={false}
                                resetFilter={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default () => <InfoDetailsTellProvider><InfoDetailsTell/></InfoDetailsTellProvider>;