import React, { memo, useRef } from 'react'
import {BasePagePay} from '../../controls';
import { AppAction, AppInterfaces } from '../../../app.data';

const nom = [
    {
        id:1,
        providerId:1,
        value:10000,
        price:12000
    },{
        id:2,
        providerId:2,
        value:10000,
        price:12500,
    },{
        id:3,
        providerId:1,
        value:50000,
        price:51000,
    },{
        id:4,
        providerId:2,
        value:50000,
        price:53000,
    },{

        id:5,
        providerId:1,
        value:100000,
        price:105000,
    },
]

const Icon = memo(function(){
    return (
        <>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 33.711H32C33.1046 33.711 34 32.8156 34 31.711V14.711C34 13.6065 33.1046 12.711 32 12.711H14.5L28 11.711V8.00157C28 6.63196 26.6544 5.66756 25.3574 6.10762L8 11.9967C6.49952 12.4987 6 13.211 6 15.211V31.711C6 32.8156 6.89543 33.711 8 33.711ZM13 20.211C12.4477 20.211 12 20.6587 12 21.211C12 21.7633 12.4477 22.211 13 22.211H27C27.5523 22.211 28 21.7633 28 21.211C28 20.6587 27.5523 20.211 27 20.211H13Z" fill="#FF6F00"/>
        </svg>

        </>
    )
})

const DepositPage_base =memo(({store, ...props}) => {
    const appAction = useRef(null);
    
    return ( 
        <React.Fragment>
            <AppAction ref={appAction} />
            <BasePagePay
                options = {
                    [
                        {
                            display: "Deposit",
                            value: 2,
                            forms: [{
                                type:"select",
                                label:"Provider",
                                value:store.formData.providerId,
                                onChange:async (val)=> {
                                    const newNominal = await nom.filter(v => v.providerId === parseInt(val))
                                    appAction.current.setForm("providerId", val)
                                    appAction.current.disp({
                                        type:"setNominalList",
                                        nominalList:newNominal
                                    })
                                },
                                displayKey:"name",
                                valueKey:"id",
                                required:true,
                                readOnly:false,
                                options:[
                                    {
                                        name : "GoPay",
                                        id : 1,
                                    },
                                    {
                                
                                        name : "Shopee Pay",
                                        id : 2,
                                    }]
                            },{
                                type: "text",
                                label: "No Pelanggan",
                                name: "Deposit",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange: (val) => appAction.current.setForm("no_pelanggan", val),
                                readOnly: false,
                                onEnter:() => appAction.current.setOpenPembayaran(true)
                            }],
                            selectNominal: true,
                            onSelectNominal: (val)=>appAction.current.setNominal(val),
                            onPay: () => appAction.current.pay(),
                            // setNominal: setNominal,
                        }
                    ]
                }
                title ={<span className="px-0"><Icon /> Deposit </span>}
                nominals={nom}
                paymentCardData={{
                    detilBody: [
                        {
                            key: "Jenis Layanan",
                            value: "TRF BANK"
                        },
                        {
                            key: "Nomor Pelanggan",
                            value: "089123123"
                        },
                        {
                            key: "Nama Pelanggan",
                            value: "Isnan Nazzarun"
                        },
                    ],
                    detilFooter: [
                        {
                            key: "Harga",
                            value: 120000
                        },
                        {
                            key: "Admin",
                            value: 2000
                        }
                    ],
                    total: 14000
                }}
            />
        </React.Fragment>
     );
})

const DepositPage = AppInterfaces.appLoad(DepositPage_base)
export default DepositPage;