import React, { memo, useRef } from 'react';
import {TableList } from '../../../controls';

class SetoranKliring extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  render() {
      return(
        <>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mt-2 mb-3">
              <li className="breadcrumb-item color-primary">Kliring</li>
              <li className="breadcrumb-item"><a href="#">Setoran Kliring</a></li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-white">
                  <h5 className="card-title">Setoran Kliring</h5>
                </div>
                <div className="card-body">
                  <div className="bns-form">
                    <div className="row">
                      <div className="col-6">
                        <div className="text-primary mt-2 mb-4">Rekening Kredit</div>
                        <div className="form-group">
                          <label htmlFor="">No. Rekening</label>
                          <div className="form-row">
                            <div className="col-5 pr-0">
                            <input type="text" className="form-control"/>
                            </div>
                            <div className="col-7 pl-0">
                              <input type="text" className="form-control" placeholder="NAMA NASABAH" readOnly/>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="">Valuta Rekening</label>
                              <div className="form-row">
                                <div className="col-3 pr-0">
                                  <input type="text" className="form-control" value="IDR" readOnly/>
                                </div>
                                <div className="col-9 pl-0">
                                  <input type="text" className="form-control" value="INDONESIAN RUPIAH" readOnly/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="">Cabang Rekening</label>
                              <div className="form-row">
                                <div className="col-3 pr-0">
                                  <input type="text" className="form-control" value="000" readOnly/>
                                </div>
                                <div className="col-9 pl-0">
                                  <input type="text" className="form-control" value="KCP KOTA BANDUNG" readOnly/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label htmlFor="">Biaya Rek. Luar Kota</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                          <div className="form-group col-6"></div>
                        </div>
                        <div className="form-group text-center mt-4">
                          <button className="btn btn-secondary">Informasi Negatif Nasabah</button>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-primary mt-2 mb-4">Sumber Kliring</div>
                        <div className="form-row">
                          <div className="form-group col-7">
                            <label htmlFor="">Tanggal Efektif</label>
                            <select name="" id="" className="form-control">
                              <option value="">08-04-2015</option>
                            </select>
                          </div>
                          <div className="form-group col-5">
                            <label htmlFor="">Kurs Jual</label>
                            <input type="text" className="form-control"/>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label htmlFor="">Nilai Transaksi</label>
                            <input type="text" className="form-control"/>
                          </div>
                          <div className="form-group col-6">
                            <label htmlFor="">Nilai ekuivalen</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label htmlFor="">Total Biaya (Rp)</label>
                            <input type="text" className="form-control"/>
                          </div>
                          <div className="form-group col-6">
                            <label htmlFor="">Total Biaya ekuivalen</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-7">
                            <label htmlFor="">Sumber Biaya</label>
                            <select name="" id="" className="form-control">
                              <option value="">Tunai</option>
                            </select>
                          </div>
                          <div className="form-group col-5">
                            <label htmlFor="">No. Referensi</label>
                            <input type="text" className="form-control"/>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-12">
                            <label htmlFor="">Keterangan</label>
                            <textarea rows="3" className="form-control"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 text-right">
                      <button className="btn btn-outline-secondary">Batal</button>
                      <button className="btn btn-primary ml-3 px-5">Proses</button>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <div className="text-primary mt-2 mb-4">Daftar Warkat</div>
                      <TableList
                        columns={[
                            {
                                name: "#",
                                field: "#",
                                maxWidth: "50px"
                            },
                            {
                                name: "Sandi Bank Tertagih",
                                field: "no_nasabah",
                                maxWidth: "50px"
                            },
                            {
                                name: "Nama Bank",
                                field: "nama_lengkap",
                                maxWidth: "50px"
                            },
                            {
                                name: "Nomor Warkat",
                                field: "address",
                                maxWidth: "50px"
                            },
                            {
                                name: "Jenis Warkat",
                                field: "gender",
                                maxWidth: "50px"
                            },
                            {
                                name: "Nomor Rekening Warkat",
                                field: "jenis_identitas",
                                maxWidth: "50px"
                            },
                            {
                                name: "Nominal",
                                field: "nomor_identitas",
                                maxWidth: "50px"
                            },
                            {
                                name: "Intercity/Non-Intercity",
                                field: "nomor_identitas",
                                maxWidth: "50px"
                            },
                            {
                                name: "Biaya",
                                field: "nomor_identitas",
                                maxWidth: "50px"
                            },
                        ]}

                        data={[]}

                        filter={true}
                        formsFilter = {[
                            {
                                type:"select",
                                options:[],
                                placeholder:"Jenis Nasabah",
                                disabled:false,
                                readonly:false,
                                onChange:(value)=>null
                            },{
                                type:"text",
                                placeholder:"Kode Cabang",
                                disabled:false,
                                readonly:false,
                                onChange:(value)=>null
                            },{
                                type:"text",
                                placeholder:"Jenis Nasabah",
                                disabled:false,
                                readonly:false,
                                onChange:(value)=>null
                            },{
                                type:"text",
                                options:[],
                                placeholder:"search",
                                disabled:false,
                                readonly:false,
                                onChange:(value)=>null
                            }
                        ]}
                        onFilter={(e)=>null}
                        resetFilter={true}
                    />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
      )
  }
}

export default SetoranKliring;