import React from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormPerorangan from './useFormPerorangan';
import useDataRekening from './useDataRekening';

const DataRekening = () => {
    const { fpAction, fpState } = useFormPerorangan();
    // const { formData = {}, formEditable = false } = fbState;
    const { pkState, pkAction } = useDataRekening()
    const {listDropdown} = ({

        "listDropdown": [
          {
            "id": "6229",
            "key": "eJenisRekening",
            "reference_code": "T",
            "reference_desc": "Tabungan"
          },
        //   {
        //     "id": "6230",
        //     "key": "eJenisRekening",
        //     "reference_code": "TR",
        //     "reference_desc": "Tabungan Rencana"
        //   },
        //   {
        //       "id": "6231",
        //       "key": "eJenisRekening",
        //       "reference_code": "G",
        //       "reference_desc": "Giro"
        //   },
        //   {
        //       "id": "6232",
        //       "key": "eJenisRekening",
        //       "reference_code": "GE",
        //       "reference_desc": "Giro Escrow"
        //   },
          {
              "id": "6233",
              "key": "eJenisRekening",
              "reference_code": "D",
              "reference_desc": "Deposito"
          }
        ]
      
    })
    return ( 
        <>
            <div className="bns-form">
                <div className="row mt-4 mb-2">
                    <div className="col-10 text-primary"> Rekening Bank Lain</div>
                    <div className="col-2">
                    {
                        (fpState.paramUrl?.mode === "registration" || fpState.paramUrl?.mode === "edit") && (
                        <button onClick={pkAction.openModalAdd} className="btn btn-outline-primary btn-block">
                            +Tambah Rekening
                        </button>
                        )
                    }
                    </div>
                </div>
                <div className="row">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th>Jenis</th>
                                <th>Nama</th>
                                <th>No Rekening</th>
                                <th>Valuta</th>
                                <th>Sandi Bank</th>
                                <th>Tgl.Pembukaan</th>
                                <th>Keterangan</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                fpState.formData?._tambahRekeningLain?.map((item, key) => {
                                if (item.DrAction !== 'D') {
                                    return (
                                        <tr key={key}>
                                            {/* <td>{item.DrAction}</td> */}
                                            <td>{item.DrJenisRekening}</td>
                                            <td>{item.DrNamaBank}</td>
                                            <td>{item.DrNomorRekening}</td>
                                            <td>{item.DrMataUang}</td>
                                            <td>{item.DrNamaSandiBank}</td>
                                            <td>{item.DrTglPembukaan}</td>
                                            <td>{item.DrKeterangan}</td>
                                            <td>
                                                {
                                                    fpState.formEditable && 
                                                
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="14" cy="8" r="2" fill="#4F4F4F" />
                                                                <circle cx="8" cy="8" r="2" fill="#4F4F4F" />
                                                                <circle cx="2" cy="8" r="2" fill="#4F4F4F" />
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => pkAction.openDetil(key)} ><p>Detail Ahli Waris</p></Dropdown.Item>
                                                            <Dropdown.Item onClick={() => pkAction.openDetil(key, "EDIT")} ><p>Ubah Ahli Waris</p></Dropdown.Item>
                                                            <hr/>
                                                            <Dropdown.Item onClick={() => pkAction.del(key)} ><p className="color-dnger">Hapus Ahli Waris</p></Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            }
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal size="lg" show={pkState.formModal} onHide={pkAction.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        Tambah Rekening Bank Lain 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <FormRegistration
                        itemsForm={[
                            // Data Diri
                            {
                                name: "Data Diri",
                                rows: []
                            }
                            // End Data Diri
                        ]}
                    /> */}
                    <div className="row">
                        <div className="col-6">
                            {
                                createForm({
                                    type: "number",
                                    label: "No Rekening",
                                    placeholder: "Masukkan No Rekening",
                                    value: pkState.formPejabat.DrNomorRekening,
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, DrNomorRekening: e })),
                                    disabled: pkState.mode === "DETAIL",
                                    required: true
                                })
                            }
                            {
                                createForm({
                                    type: "select",
                                    label: "Sandi Bank",
                                    placeholder: "Pilih Sandi Bank",
                                    options: pkState.dataBank?.map(v => ({ value: v.bic, label: `${v.bic} - ${v.nama}` })),
                                    key: "value",
                                    display: "label",
                                    value: pkState.formPejabat.DrNamaSandiBank,
                                    disabled: pkState.mode === "DETAIL",
                                    onChange: (d) => {
                                        var kodeBank = d;
                                        var namaBank = pkState.dataBank.find(({bic}) => bic === d)?.nama;
                                        pkAction.setFormPejabat(infoT => ({
                                            ...infoT,
                                            kode_bank_kliring:kodeBank,
                                            DrNamaSandiBank:namaBank
                                        }))
                                        pkAction.setFormPejabat(prevState => ({ ...prevState, DrNamaSandiBank: d }))
                                        pkAction.setFormPejabat(prevState => ({ ...prevState, DrNamaBank: namaBank }))
                                    }, 
                                    required: true
                                })
                            }
                            {
                                createForm({
                                    type: "text",
                                    label: "Mata Uang",
                                    placeholder: "IDR - Rupiah",
                                    value: pkState.formPejabat.DrMataUang,
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, DrMataUang: e })),
                                    required: false,
                                    disabled: true
                                })
                            }
                            {
                                createForm({
                                    type: "textarea",
                                    label: "Keterangan",
                                    placeholder: "Masukkan Keterangan/Catatan",
                                    value: pkState.formPejabat.DrKeterangan,
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, DrKeterangan: e })),
                                    disabled: pkState.mode === "DETAIL",
                                    required: true
                                })
                            }
                        </div>
                        <div className="col-6">
                            {
                                createForm({
                                    type: "select",
                                    label: "Jenis Rekening",
                                    options: listDropdown,
                                    placeholder: "Pilih Jenis Rekening",
                                    key: "reference_code",
                                    display: "reference_desc",
                                    value: pkState.formPejabat.DrJenisRekening,
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, DrJenisRekening: e })),
                                    disabled: pkState.mode === "DETAIL",
                                    required: true
                                })
                            }
                            {
                                createForm({
                                    type: "text",
                                    label: "Nama Bank",
                                    placeholder: "Masukkan Nama Bank",
                                    value: pkState.formPejabat.DrNamaBank,
                                    // onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, DrNamaBank: e })),
                                    disabled: true,
                                    required: true
                                })
                            }
                            {
                                createForm({
                                    type: "date",
                                    label: "Tgl Pembukaan",
                                    placeholder: "Masukkan Tgl Pembukaan",
                                    value: pkState.formPejabat.DrTglPembukaan,
                                    onChange: (e) => pkAction.setFormPejabat(prevState => ({ ...prevState, DrTglPembukaan: e })),
                                    disabled: pkState.mode === "DETAIL",
                                    required: false
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button  onClick={pkAction.save} className="btn btn-sm float-right btn-success ml-2">
                                Simpan
                            </button>
                            <button onClick={pkAction.closeModal} className="btn btn-sm float-right btn-outline-success">
                                Batal
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
     );
}
 
export default DataRekening;