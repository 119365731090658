import React from 'react';
import useLocalStorage from '../module/useLocalStorage';
import useKeycloak from './keycloak.module';
import useFetch from './useFetch';
import moment from 'moment';

const useNexusPrint = () => {
    const printS = "http://localhost:998"
    const [ printServer, setPrintServer ] = useLocalStorage("printServer");
    const { createLog } = useFetch()
    const { keycloak } = useKeycloak();

    
    async function print(param, callBack=()=>null) {

        let date = new Date();
        let userInfo = keycloak.loadUserInfo()
        let log = { 
            log_name : "Connecting to printserver",
            data_to_printserver : param,
            user_login : userInfo.preferred_username,
            tanggal : moment(date).format("YYYY-MM-DD HH:mm:ss")
        }
        let uri = ""
        if (param != null) {
            var keys = Object.keys(param);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + param[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }

        // // console.log( `${printServer}/${uri}`)
        log = {
            ...log,
            url_print_server : `${printServer || printS}${uri}`
        }
        try {

            let resp = await fetchApi({ url: `${printServer || printS}${uri}` });

            log = { 
                ...log,
                response_printserver : {
                    statusCode: 200,
                    statusText: "Ok",
                }
            }
            await createLog(log)
            return {
                statusCode: 200,
                statusText: "Berhasil",
                remark: "Berhasil koneksi ke print server. printing ...",
                url:  `${printServer || printS}${uri}`,
                resp: {
                    status_text : "Ok",
                    status_code : 200
                }
            }
        } catch (error) {
            log = { 
                ...log,
                response_printserver : {
                    statusCode: 404,
                    statusText: "",
                }
            }
            await createLog(log)
            return {
                statusCode: 500,
                statusText: "Koneksi error",
                remark: "Periksa kembali koneksi print server",
                resp: {
                    status_text : "",
                    status_code : 404
                },
                error: error.toString(),
                url:  `${printServer || printS}${uri}`,
            }
        }
    }
    async function fetchApi(
        {
            url = "/",
        }
    ) {

        var data = {};
        await fetch(`${url}`, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return data
        
    }
    return {
        print
    };
}

export default useNexusPrint;