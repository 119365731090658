import React, { useEffect } from 'react';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormBadanUsaha from './useFormBadanUsaha';
import moment from 'moment';
import { useParams } from 'react-router';

const DataCCD = () => {
    const { fbState, fbAction } = useFormBadanUsaha();

    const paramUrl = useParams();

    useEffect(function () {
        paramUrl.mode === "registration" &&
        fbAction.changeForm("0", "cddStatusKenaPajak","_cdd")
    }, [paramUrl.mode])

    return (
        <>
            <FormRegistration
                itemsForm={[
                    // Data Tambahan
                    {
                        name: "Data Tambahan",
                        rows: [
                            {
                                rows: 1,
                                type: "select",
                                label: "Penghasilan Kotor Per tahun",
                                placeholder: "Pilih Penghasilan utama per tahun",
                                required: true,
                                options: (fbState.dataDropDown?.R_PENGHASILAN_PERTAHUN || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                value: fbState.formData?._cdd?.cddPenghasilanKotorPerTahun,
                                onChange: (value) => fbAction.changeForm(value, "cddPenghasilanKotorPerTahun", "_cdd"),
                                key: "reference_code",
                                display: "reference_desc",
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                type: "select",
                                label: "Golongan Nasabah",
                                placeholder: "Pilih Golongan Nasabah (BI)",
                                required: true,
                                options: fbState.dataDropDown?.GOL_PIHAK_LAWAN.map(({reference_code,reference_desc})=> ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                value: fbState.formData?._cdd?.cddGolonganNasabah,
                                onChange: (value) => fbAction.changeForm(value, "cddGolonganNasabah", "_cdd"),
                                key: "reference_code",
                                display: "reference_desc",
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                type: "select",
                                label: "Sumber Dana",
                                placeholder: "Pilih Sumber Dana",
                                required: true,
                                options: (fbState.dataDropDown?.R_SUMBER_DANA_CIF_KORPORAT || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                value: fbState.formData?._cdd?.cddSumberDana,
                                onChange: (value) => fbAction.changeForm(value, "cddSumberDana", "_cdd"),
                                key: "reference_code",
                                display: "reference_desc",
                                disabled: !fbState.formEditable 
                            },
                            // end rows 1
                            // rows 2
                            {
                                rows: 2,
                                type: "select",
                                label: "Sumber Penghasilan",
                                placeholder: "Masukan Sumber Penghasilan",
                                required: true,
                                options: (fbState.dataDropDown?.R_SUMBER_PENGHASILAN || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                value: fbState.formData?._cdd?.cddSumberPenghasilan,
                                onChange: (value) => fbAction.changeForm(value, "cddSumberPenghasilan", "_cdd"),
                                key: "reference_code",
                                display: "reference_desc",
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                type: "select",
                                label: "Tujuan Penggunaan Dana",
                                placeholder: "Pilih Tujuan Penggunaan Dana",
                                required: true,
                                options: (fbState.dataDropDown?.R_TUJUAN_DANA_CIF_KORPORAT || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                value: fbState.formData?._cdd?.cddTujuanPenggunaanDana,
                                onChange: (value) => fbAction.changeForm(value, "cddTujuanPenggunaanDana", "_cdd"),
                                key: "reference_code",
                                display: "reference_desc",
                                disabled: !fbState.formEditable
                            }
                        ]

                    },
                    // End Data Tambahan
                    // Status Pajak
                    {
                        name: "Status Pajak & SKB Pajak",
                        rows: [
                            // rows 1
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        label: 'Status Kena Pajak',
                                                        type: 'select',
                                                        placeholder: "Pilih Status Kena Pajak",
                                                        options: (fbState.dataDropDown?.R_STATUS_PAJAK).map(({reference_code,reference_desc }) => ({
                                                            reference_desc:`${reference_code} - ${reference_desc}`,
                                                            reference_code
                                                        })),
                                                        key: "reference_code",
                                                        display: "reference_desc",
                                                        value: fbState.formData?._cdd?.cddStatusKenaPajak,
                                                        onChange: (value) => {
                                                            fbAction.changeForm(value, "cddStatusKenaPajak","_cdd")
                                                            fbAction.changePajak(value)
                                                            if (value === "0"){
                                                                fbAction.changeForm("KENA PAJAK", "cddKeteranganBebasPajak","_cdd")
                                                            }
                                                        },                                                            
                                                        required: true,
                                                        disabled: !fbState.formEditable,
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                                // rows: 1,
                                // type: "select",
                                // label: "Status Kena Pajak",
                                // required: true,
                                // placeholder: "Pilih Status Kena Pajak",
                                // options: fbState.dataDropDown?.R_STATUS_PAJAK,
                                // value: fbState.formData?._cdd?.cddStatusKenaPajak,
                                // onChange: (value) => fbAction.changeForm(value, "cddStatusKenaPajak", "_cdd"),
                                // key: "reference_code",
                                // display: "reference_desc",
                                // disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <>
                                        <div className="row" hidden={!fbState?.statusPajak?.Aa }>
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        label: 'Keterangan Bebas Pajak',
                                                        type: "text",
                                                        placeholder: "Masukan keterangan bebas Pajak",
                                                        value: fbState.formData?._cdd?.cddKeteranganBebasPajak,
                                                        onChange: (value) => fbAction.changeForm(value, "cddKeteranganBebasPajak","_cdd"),
                                                        required: fbState?.statusPajak?.Aa,
                                                        disabled: !fbState.formEditable,
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                                // rows: 1,
                                // type: "text",
                                // label: "Keterangan Bebas Pajak",
                                // placeholder: "Masukan Keterangan Bebas Pajak",
                                // required: true,
                                // value: fbState.formData?._cdd?.cddKeteranganBebasPajak,
                                // onChange: (value) => fbAction.changeForm(value, "cddKeteranganBebasPajak","_cdd"),
                                // disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <>
                                        <div className="row" hidden={!fbState?.statusPajak?.Ba || fbState.formData?._cdd?.cddStatusKenaPajak === "2"} >
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        label: 'Nomor Surat',
                                                        type: "text",
                                                        placeholder: "Masukkan Nomor Surat",
                                                        value: fbState.formData?._cdd?.cddNomorSurat,
                                                        onChange: (value) => fbAction.changeForm(value, "cddNomorSurat","_cdd"),
                                                        required: fbState?.statusPajak?.Ba,
                                                        disabled: !fbState.formEditable,
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                                // rows: 2,
                                // type: "text",
                                // label: "Nomor Surat",
                                // placeholder: "Masukkan Nomor Surat",
                                // required: false,
                                // value: fbState.formData?._cdd?.cddNomorSurat,
                                // onChange: (value) => fbAction.changeForm(value, "cddNomorSurat","_cdd"),
                                // disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row" hidden={!fbState?.statusPajak?.Ca}>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    type: "date",
                                                    label: "Tgl Terbit",
                                                    placeholder: "Masukkan Tgl Terbit",
                                                    value: fbState.formData?._cdd?.cddTanggalTerbit,
                                                    onChange: (value) => fbAction.changeForm(value, "cddTanggalTerbit","_cdd"),
                                                    required: fbState?.statusPajak?.Ca,
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    type: "date",
                                                    label: "Tgl Berakhir",
                                                    placeholder: "Masukkan Tgl Berakhir",
                                                    value: fbState.formData?._cdd?.cddTanggalBerakhir,
                                                    onChange: (value) => fbAction.changeForm(value, "cddTanggalBerakhir","_cdd"),
                                                    required: fbState?.statusPajak?.Ca,
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )

                                // rows: 2,
                                // type: "custom",
                                // component: (
                                //     <div className="row">
                                //         <div className="col-6">
                                //             {
                                //                 createForm({
                                //                     type: "date",
                                //                     label: "Tgl Terbit",
                                //                     placeholder: "Masukkan Tgl Terbit",
                                //                     required: false,
                                //                     value: fbState.formData?._cdd?.cddTanggalTerbit,
                                //                     onChange: (value) =>{ 
                                //                         var now = moment(Date.now());
                                //                         if(now>moment(value)) fbAction.changeForm(value, "cddTanggalTerbit","_cdd")
                                //                     },
                                //                     disabled: !fbState.formEditable
                                //                 })
                                //             }
                                //         </div>
                                //         <div className="col-6">
                                //             {
                                //                 createForm({
                                //                     type: "date",
                                //                     label: "Tgl Berakhir",
                                //                     placeholder: "Masukkan Tgl Berakhir",
                                //                     required: false,
                                //                     value: fbState.formData?._cdd?.cddTanggalBerakhir,
                                //                     onChange: (value) =>{ 
                                //                         var now = moment(Date.now());
                                //                         if(now<moment(value)) fbAction.changeForm(value, "cddTanggalBerakhir","_cdd")
                                //                     },
                                //                     disabled: !fbState.formEditable
                                //                 })
                                //             }
                                //         </div>
                                //     </div>
                                // )
                            },
                        ]
                    },
                    // End Status Pajak
                    // Limit Transaksi
                    {
                        name:"Limit Transaksi",
                        rows: [
                            // rows 1
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-8">
                                            {
                                                createForm({
                                                    type:"currency",
                                                    label:"Nominal Setor Tunai",
                                                    required:true,
                                                    placeholder: "Masukkan nominal setor tunai",
                                                    value: fbState.formData?._cdd?.cddNominalStorTunai,
                                                    onChange: (value) => fbAction.changeForm(value, "cddNominalStorTunai","_cdd"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-4">
                                            {/* <div className="form-group-sm">
                                                <label>&nbsp;</label>
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend  pt-1 pr-1">
                                                        Frekuensi
                                                    </div>
                                                    <input 
                                                        className="form-control form-control-sm"
                                                        placeholder="0"
                                                        required="true"
                                                        type="number"
                                                        onChange={(e) => {
                                                        fbAction.changeForm(e.target.value,"cddNominalStorTunaiFre","_cdd");
                                                            }}
                                                        value = {fbState.formData?._cdd?.cddNominalStorTunaiFre}
                                                        disabled={!fbState.formEditable} 
                                                    />
                                                </div>
                                            </div> */}
                                            {
                                                createForm({
                                                    type:"number",
                                                    label:"Frekuensi",
                                                    required:true,
                                                    placeholder: "0",
                                                    value: fbState.formData?._cdd?.cddNominalStorTunaiFre,
                                                    onChange: (value) => fbAction.changeForm(value,"cddNominalStorTunaiFre","_cdd"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-8">
                                            {
                                                createForm({
                                                    type:"currency",
                                                    label:"Nominal Setor Non Tunai",
                                                    required:true,
                                                    placeholder: "Masukkan nominal setor Non tunai",
                                                    value: fbState.formData?._cdd?.cddNominalStorNonTunai,
                                                    onChange: (value) => fbAction.changeForm(value, "cddNominalStorNonTunai","_cdd"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-4">
                                            {/* <div className="form-group-sm">
                                                <label>&nbsp;</label>
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend  pt-1 pr-1">
                                                        Frekuensi
                                                    </div>
                                                    <input 
                                                        className="form-control form-control-sm"
                                                        placeholder="0" 
                                                        type="number"
                                                        onChange={(e) => {
                                                        fbAction.changeForm(e.target.value,"cddNominalStorNonTunaiFre","_cdd");
                                                            }}
                                                        value = {fbState.formData?._cdd?.cddNominalStorNonTunaiFre}
                                                        disabled={!fbState.formEditable} 
                                                    />
                                                </div>
                                            </div> */}
                                            {
                                                createForm({
                                                    type:"number",
                                                    label:"Frekuensi",
                                                    required:true,
                                                    placeholder: "0",
                                                    value: fbState.formData?._cdd?.cddNominalStorNonTunaiFre,
                                                    onChange: (value) => fbAction.changeForm(value,"cddNominalStorNonTunaiFre","_cdd"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            // end rows 1
                            // rows 2
                            
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-8">
                                            {
                                                createForm({
                                                    type:"currency",
                                                    label:"Nominal Tarik Tunai",
                                                    required:true,
                                                    placeholder: "Masukkan nominal tarik tunai",
                                                    value: fbState.formData?._cdd?.cddNominalTarikTunai,
                                                    onChange: (value) => fbAction.changeForm(value, "cddNominalTarikTunai","_cdd"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-4">
                                            {/* <div className="form-group-sm">
                                                <label>&nbsp;</label>
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend  pt-1 pr-1">
                                                        Frekuensi
                                                    </div>
                                                    <input 
                                                        className="form-control form-control-sm"
                                                        placeholder="0"
                                                        type="number" 
                                                        onChange={(e) => {
                                                        fbAction.changeForm(e.target.value,"cddNominalTarikTunaiFre","_cdd");
                                                            }}
                                                        value = {fbState.formData?._cdd?.cddNominalTarikTunaiFre}
                                                        disabled={!fbState.formEditable} 
                                                    />
                                                </div>
                                            </div> */}
                                            {
                                                createForm({
                                                    type:"number",
                                                    label:"Frekuensi",
                                                    required:true,
                                                    placeholder: "0",
                                                    value: fbState.formData?._cdd?.cddNominalTarikTunaiFre,
                                                    onChange: (value) => fbAction.changeForm(value,"cddNominalTarikTunaiFre","_cdd"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-8">
                                            {
                                                createForm({
                                                    type:"currency",
                                                    label:"Nominal tarik Non Tunai",
                                                    required:true,
                                                    placeholder: "Masukkan nominal tarik Non tunai",
                                                    value: fbState.formData?._cdd?.cddNominalTarikNonTunai,
                                                    onChange: (value) => fbAction.changeForm(value, "cddNominalTarikNonTunai","_cdd"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-4">
                                            {/* <div className="form-group-sm">
                                                <label>&nbsp;</label>
                                                <div className="input-group input-group-sm">
                                                    <div class="input-group-prepend  pt-1 pr-1">
                                                        Frekuensi
                                                    </div>
                                                    <input 
                                                        className="form-control form-control-sm"
                                                        placeholder="0" 
                                                        type="number"
                                                        onChange={(e) => {
                                                        fbAction.changeForm(e.target.value,"cddNominalTarikNonTunaiFre","_cdd");
                                                            }}
                                                        value = {fbState.formData?._cdd?.cddNominalTarikNonTunaiFre}
                                                        disabled={!fbState.formEditable} 
                                                    />
                                                </div>
                                            </div> */}
                                            {
                                                createForm({
                                                    type:"number",
                                                    label:"Frekuensi",
                                                    required:true,
                                                    placeholder: "0",
                                                    value: fbState.formData?._cdd?.cddNominalTarikNonTunaiFre,
                                                    onChange: (value) => fbAction.changeForm(value,"cddNominalTarikNonTunaiFre","_cdd"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            // end rows 2
                        ]
                    },
                    // End limit transaksi
                    // Faktor Resiko
                    {
                        name:"Faktor Resiko",
                        rows:[
                            // rows 1
                            {
                                rows: 1,
                                type: "select",
                                label: "Status Resiko",
                                disabled: true,
                                required: true,
                                value: fbState.formData?._cdd?.cddStatusResiko,
                                onChange: (value) => fbAction.changeForm(value, "cddStatusResiko","_cdd"),
                                placeholder: "status resiko",
                                options: [
                                    {
                                        key: "L",
                                        display: "L - LOW"
                                    },{
                                        key: "M",
                                        display: "M - MEDIUM"
                                    },{
                                        key: "H",
                                        display: "H - HIGH"
                                    },
                                ]
                                // disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component:(
                                    <div className="row">
                                        <div className="col">
                                            <div className="alert alert-warning m-2 p-2">
                                                Status Resiko akan di kalkulasi berdasar input pada indikator profile resiko
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            // end rows 1
                            // rows 2
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            <table className="table table-bordered text-center">
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Tingkat Resiko</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                    <td>Identitas Masalah</td>
                                                        <td>
                                                            <select 
                                                            value={fbState.formData?._cdd?.cddIdentitasNasabah} 
                                                                onChange={(e)=>{
                                                                    fbAction.setFormResiko(prevState => ({...prevState,identitasMasalah:e.target.value }))
                                                                    fbAction.changeForm(e.target.value, "cddIdentitasNasabah","_cdd")
                                                                }} 
                                                                disabled={!fbState.formEditable}
                                                                className="form-control" name="Identitas" id="Identitas">
                                                                    <option value={'L'}>Low</option>
                                                                    <option value={'M'}>Medium</option>
                                                                    <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lokasi Usaha</td>
                                                        <td>
                                                            <select 
                                                            value={fbState.formData?._cdd?.cddLokasiUsaha} 
                                                                onChange={(e)=>{
                                                                    fbAction.setFormResiko(prevState => ({...prevState,identitasMasalah:e.target.value }))
                                                                    fbAction.changeForm(e.target.value, "cddLokasiUsaha","_cdd")
                                                                }} 
                                                            disabled={!fbState.formEditable}
                                                            className="form-control" name="Lokasi" id="Lokasi">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Profile Nasabah</td>
                                                        <td>
                                                            <select 
                                                             value={fbState.formData?._cdd?.cddProfilNasabah} 
                                                                onChange={(e)=>{
                                                                    fbAction.setFormResiko(prevState => ({...prevState,identitasMasalah:e.target.value }))
                                                                    fbAction.changeForm(e.target.value, "cddProfilNasabah","_cdd")
                                                                }}
                                                            disabled={!fbState.formEditable}
                                                            className="form-control" name="Profile" id="Profile">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jumlah Transaksi</td>
                                                        <td>
                                                            <select 
                                                             value={fbState.formData?._cdd?.cddJumlahTransaksi} 
                                                                onChange={(e)=>{
                                                                    fbAction.setFormResiko(prevState => ({...prevState,identitasMasalah:e.target.value }))
                                                                    fbAction.changeForm(e.target.value, "cddJumlahTransaksi","_cdd")
                                                                }}
                                                            disabled={!fbState.formEditable}
                                                            className="form-control" name="Transaksi" id="Transaksi">
                                                                <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kegiatan Usaha</td>
                                                        <td>
                                                            <select 
                                                            value={fbState.formData?._cdd?.cddKegiatanUsaha} 
                                                                onChange={(e)=>{
                                                                    fbAction.setFormResiko(prevState => ({...prevState,identitasMasalah:e.target.value }))
                                                                    fbAction.changeForm(e.target.value, "cddKegiatanUsaha","_cdd")
                                                                }}
                                                            disabled={!fbState.formEditable}
                                                            className="form-control" name="Usaha" id="Usaha">
                                                                 <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Struktur Kepemilikan</td>
                                                        <td>
                                                            <select 
                                                            value={fbState.formData?._cdd?.cddStrukturKepemilikan} 
                                                                onChange={(e)=>{
                                                                    fbAction.setFormResiko(prevState => ({...prevState,identitasMasalah:e.target.value }))
                                                                    fbAction.changeForm(e.target.value, "cddStrukturKepemilikan","_cdd")
                                                                }}
                                                            disabled={!fbState.formEditable}
                                                            className="form-control" name="Kepemilikan" id="Kepemilikan">
                                                                 <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Informasi Lainnya</td>
                                                        <td>
                                                            <select 
                                                            value={fbState.formData?._cdd?.cddInformasiLainnya} 
                                                                onChange={(e)=>{
                                                                    fbAction.setFormResiko(prevState => ({...prevState,identitasMasalah:e.target.value }))
                                                                    fbAction.changeForm(e.target.value, "cddInformasiLainnya","_cdd")
                                                                }}
                                                            disabled={!fbState.formEditable}
                                                            className="form-control" name="Informasi" id="Informasi">
                                                                 <option value={'L'}>Low</option>
                                                                <option value={'M'}>Medium</option>
                                                                <option value={'H'}>High</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )

                            }
                        ]
                    }
                    // End Faktor Resiko
                ]}

            />
        </>
    );
}

export default DataCCD;