import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Col, Form, FormControl, InputGroup, Row, Modal } from 'react-bootstrap';
import { Breadcrumb, Button, Label, Message } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import queryString from 'query-string';
import { Gap, Stepper, FormHeader, TableGrid, ActionMore } from '../../../../controls';
import { baseUrl } from '../../../../../app.config';
import { getErrorEmptyValue, joinDefinedString } from '../../../../../utils';
import SpecimenPejabatModal from './specimen-pejabat-modal';
import HakAksesDataModal from './hak-akses-data-modal';
import SummaryCard from './summary-card';

const DetailTabunganPage = () => {
    const history = useHistory();
    const { search } = useLocation();

    const { type, id, cif } = queryString.parse(search);
    const [activeIdx, setActiveIdx] = useState(0);
    const [tipeSpecimenPejabat, setTipeSpecimenPejabat] = useState('registrasi'); // registrasi | edit
    const [specimenPejabatState, setSpecimenPejabatState] = useState([]);
    const [tipeRelasiNasabah, setTipeRelasiNasabah] = useState('tambah'); // tambah | edit
    const [relasiNasabahState, setRelasiNasabahState] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [data, setData] = useState([]);

    const [error, setError] = useState({});
    const [modal, setModal] = useState({ specimenPejabat: false, hakAksesData: false });
    const [nasabah, setNasabah] = useState({
        nama: '',
        homePhone: '',
        mobilePhone: '',
        motherName: '',
        birthPlace: '',
        birthDate: '',
        address: '',
        firstName: '',
        middleName: '',
        lastName: '',
    });
    const [form, setForm] = useState({
        nomorCIF: '',
        accountHolderKey: '',
        accountHolderType: '',
        namaRekening: '',
        valuta: 'IDR',
        produkGiro: '',
        notes: '',
        productTypeKey: '',
        _rekeningTabungan: {
            dapatBonusBagiHasil: false,
            blokirDebet: false,
            cekNota: false,
            dormant: false,
            biayaAdministrasi: false,
            biayaATM: false,
            blokirKredit: false,
            statusPassbook: false,
            biayaRekeningDormant: false,
            biayaSaldoMinimum: false,
        },
        _informasiLain: {
            nisbahAkhir: Number(0),
            nisbahCounter: Number(0),
            nisbahZakat: Number(0),
            nisbahPajak: Number(0),
            sumberDana: '',
            qqName: '',
            biayaBiaya: '',
            tncVersion: '',
            tujuanPembukaanRekening: '',
            kelengkapanData: '',
            marketingReferensi: '',
            marketingPertama: '',
            marketingSaatIni: '',
        },
        _informasiLainKorporat: {
            isSmsBanking: 'N',
            isMobileBanking: 'N',
            isInternetBanking: 'N',
            infoLimitNominalSetorNontunai: 'string',
            infoLimitFrekuensiSetorTunai: 'string',
            infoLimitFrekuensiSetorNontunai: 'string',
            infoLimitNominalSetorTunai: 'string',
        },
        _asuransi: {
            tanggalJatuhTempoAsuransi: '',
            rencanaAsuransiUtama: '',
            rencanaAsuransiTambahan: '',
        },
        _alamatAlternatif: {
            alamat: '',
            provinsi: '',
            kotaKabupaten: '',
            kecamatan: '',
            kelurahan: '',
            rt: '',
            rw: '',
            telepon: '',
            kodePos: '',
        },
        _specimenPejabat: [],
        _hakAksesRekening: [],
        _dataNasabah: {
            telpPerusahaan: '',
            tanggalBerdiri: '',
            alamatPerusahaan: '',
            npwp: '',
        },
        _blokirRekening: {
            alasan: '',
            status: '',
        },
    });

    useEffect(() => {
        fetch(baseUrl + `/rekening/tabungan/pending/${id}/detail`)
            .then((response) => response.json())
            .then((data) => data.list?.DataRekeningTabungan)
            .then(async (data) => {
                const res = await fetch(
                    baseUrl + `/rekening/tabungan/kantor/cabang/${data.assignedBranchKey}/detail`
                );

                const cabang = await res.json();

                setForm({
                    nomorRekening: data.nomorRekening,
                    assignedBranchKey: data.assignedBranchKey,
                    cabang: cabang.list?.name,
                    nomorCIF: data.nomorCIF,
                    accountHolderKey: data.accountHolderKey,
                    accountHolderType: data.accountHolderType,
                    namaRekening: data.namaRekening,
                    status: data.status,
                    notes: data.keterangan,
                    productTypeKey: data.productTypeKey,
                    produkGiro: data.produkGiro,
                    valuta: data.valuta,
                    _dataNasabah: data._dataNasabah || {},
                    _alamatAlternatif: {
                        ...data._alamatAlternatif,
                        telepon1: data._alamatAlternatif?.telepon?.split('-')[0],
                        telepon2: data._alamatAlternatif?.telepon?.split('-')[1],
                    },
                    _hakAksesRekening: data._hakAksesRekening ? [data._hakAksesRekening] : [],
                    _asuransi: data._asuransi || {},
                    _informasiLain: data._informasiLain,
                    _rekeningTabungan: data._rekening,
                    _specimenPejabat: data._specimenPejabat,
                    _informasiLainKorporat: data._informasiLainKorporat || {},
                    _blokirRekening: data._blokirRekening || {
                        alasan: '',
                        status: '',
                    },
                });
            });
    }, [id]);

    useEffect(() => {
        if (type === 'CLIENT') {
            fetch(baseUrl + `/nasabah/perorangan/find/${cif}`)
                .then((response) => response.json())
                .then((data) => {
                    const { firstName, lastName, middleName } = data.list;

                    setNasabah({
                        homePhone: data?.list.homePhone,
                        mobilePhone: data?.list.mobilePhone,
                        nama: joinDefinedString(firstName, middleName, lastName).separateWith(' '),
                        birthPlace: data?.list._customerData.birthPlace,
                        birthDate: data?.list.birthDate,
                        motherName: data?.list._customerData?.personalMothersName,
                        address: data?.list._ktpAddress.ktpAddress,
                    });
                });
        } else if (type === 'GROUP') {
            fetch(baseUrl + `/nasabah-badan-usaha/find/${cif}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data?.list) {
                        const { groupName, _identitasNasabah } = data.list;
                        const {
                            nasabahAlamat,
                            nasabahKotaKabupaten,
                            nasabahProvinsi,
                            nasabahKodePos,
                            nasabahTeleponKantorNomor1 = '',
                            nasabahTanggalAktePendirian = '',
                            nasabahNomorNpwp,
                        } = _identitasNasabah;

                        const address = joinDefinedString(
                            nasabahAlamat,
                            nasabahKotaKabupaten,
                            nasabahProvinsi,
                            nasabahKodePos
                        ).separateWith(', ');

                        setNasabah({
                            nama: groupName,
                            address,
                            namaIbuKandung: '',
                            nomorHP: '',
                            homePhone: nasabahTeleponKantorNomor1,
                            tanggalLahir: nasabahTanggalAktePendirian,
                            tempatLahir: '',
                            npwp: nasabahNomorNpwp,
                        });
                    }
                });
        }
    }, [cif, type]);

    // console.log(nasabah, 'nasabah');
    const handleNext = () => {
        if (activeIdx < 4) setActiveIdx((idx) => idx + 1);
    };

    const handlePrevious = () => {
        if (activeIdx > 0) setActiveIdx((idx) => idx - 1);
    };

    // console.log(form, 'foorm');
    return (
        <>
            <Modal centered>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Proses registrasi rekening tabungan berhasil dilakukan
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        // onClick={() => handleRegister.reset()}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Selesai
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={confirm}>
                <Modal.Header onHide={() => setConfirm(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Lanjutkan proses registrasi rekening tabungan?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setConfirm(false)}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Tidak
                    </Button>
                    <Button
                        // loading={handleRegister.isLoading}
                        // onClick={() => handleRegister.mutate()}
                        className="tw-w-16"
                        size="mini"
                        color="blue"
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <HakAksesDataModal
                show={modal.hakAksesData}
                onHide={() => setModal({ ...modal, hakAksesData: false })}
                type={tipeRelasiNasabah}
                initialState={relasiNasabahState[0]}
                // onSubmit={handleSubmitHakAkses}
            />
            <SpecimenPejabatModal
                type={tipeSpecimenPejabat}
                show={modal.specimenPejabat}
                onHide={() => {
                    setModal({ ...modal, specimenPejabat: false });
                }}
                initialState={specimenPejabatState[0]}
                // onSubmit={handleSubmitSpecimenPejabat}
            />
            <Breadcrumb className="mb-5">
                <Breadcrumb.Section className="text-primary-1">Tabungan & Giro</Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Registrasi Rekening</Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Tabungan</Breadcrumb.Section>
            </Breadcrumb>
            <div className="bg-white rounded-lg">
                <div style={{ height: 73 }} className="d-flex justify-content-center bg-gray-7">
                    <Stepper
                        style={{ marginTop: -12 }}
                        steps={[
                            {
                                name: 'Data Rekening',
                                id: '0',
                                whiteSpace: 'nowrap',
                            },
                            {
                                name: 'Informasi Lain',
                                id: '1',
                                whiteSpace: 'nowrap',
                            },
                            {
                                name: 'Specimen Pejabat',
                                id: '2',
                                whiteSpace: 'nowrap',
                            },
                            {
                                name: 'Hak Akses Rekening',
                                id: '3',
                                whiteSpace: 'nowrap',
                            },
                        ]}
                        activeIdx={activeIdx}
                        onStepClick={(idx) => {
                            setActiveIdx(idx);
                        }}
                    />
                </div>
                {[0, 1].includes(activeIdx) && (
                    <div className="px-5 py-4">
                        {activeIdx === 0 && (
                            <>
                                <SummaryCard
                                    namaLengkap={form.namaRekening}
                                    valuta={form.valuta}
                                    nameProduct={form.produkGiro}
                                    status={form.status?.replaceAll('_', ' ')}
                                    saldo={nasabah.saldo || 0}
                                    nomorRekening={form?.nomorRekening ?? '-'}
                                    cabang={form?.cabang ?? '-'}
                                />
                                <FormHeader className="tw-my-4">Data Nasabah</FormHeader>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Nomor CIF
                                            <Label className="ml-2" size="mini">
                                                Wajib
                                            </Label>
                                        </Form.Label>
                                        <Form
                                            noValidate
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 140px',
                                                gap: 18,
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <Form.Group bsPrefix=" ">
                                                <Form.Control
                                                    value={form.nomorCIF}
                                                    size="sm"
                                                    placeholder="Masukkan nomor CIF"
                                                    disabled
                                                />
                                            </Form.Group>
                                            <Button
                                                type="submit"
                                                disabled
                                                size="mini"
                                                color="orange"
                                            >
                                                Cari Nasabah
                                            </Button>
                                        </Form>
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            {type === 'GROUP' ? 'Nomor NPWP' : 'Nama Ibu Kandung'}
                                        </Form.Label>
                                        <Form.Control
                                            disabled
                                            size="sm"
                                            value={
                                                type === 'GROUP' ? nasabah.npwp : nasabah.motherName
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Nama
                                        </Form.Label>
                                        <Form.Control
                                            disabled
                                            size="sm"
                                            placeholder="Nama Rekening"
                                            value={nasabah.nama}
                                        />
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            {type === 'GROUP'
                                                ? 'Tanggal Pendirian'
                                                : 'Tempat / Tanggal Lahir'}
                                        </Form.Label>{' '}
                                        {type === 'GROUP' ? (
                                            <Form.Control
                                                value={nasabah.tanggalLahir}
                                                disabled
                                                size="sm"
                                                type="date"
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '2fr 24px 1fr',
                                                }}
                                            >
                                                <Form.Control
                                                    disabled
                                                    size="sm"
                                                    placeholder="Tempat Lahir"
                                                    value={nasabah.birthPlace}
                                                />
                                                <div
                                                    style={{ lineHeight: '' }}
                                                    className="f-18 text-center"
                                                >
                                                    /
                                                </div>
                                                <Form.Control
                                                    placeholder="Tanggal Lahir"
                                                    value={nasabah.birthDate}
                                                    disabled
                                                    size="sm"
                                                    type="date"
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label className="font-weight-normal f-12">
                                                    No. Telepon
                                                </Form.Label>
                                                <Form.Control
                                                    value={nasabah.homePhone}
                                                    disabled
                                                    size="sm"
                                                    placeholder="No Telepon"
                                                />
                                            </Col>
                                            {type === 'CLIENT' ? (
                                                <Col>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        No. Handphone
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        size="sm"
                                                        placeholder="No Handphone"
                                                        value={nasabah.mobilePhone}
                                                    />
                                                </Col>
                                            ) : null}
                                        </Row>
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Alamat
                                        </Form.Label>
                                        <Form.Control
                                            value={nasabah.address}
                                            disabled
                                            size="sm"
                                            placeholder="Alamat"
                                        />
                                    </Col>
                                </Row>
                                <FormHeader className="mb-4">Rekening</FormHeader>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Produk Giro
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control
                                                name="produkGiro"
                                                size="sm"
                                                as="select"
                                                disabled
                                            >
                                                <option>{form.produkGiro}</option>
                                            </Form.Control>
                                            <Form.Control.Feedback size="sm" type="invalid">
                                                Produk giro wajib diisi
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Check
                                                className="tw-font-bold tw-text-12"
                                                style={{ marginTop: 30 }}
                                                type="checkbox"
                                                label="Dapat Bonus / Bagi Hasil"
                                                disabled
                                                value={form._rekeningTabungan.dapatBonusBagiHasil}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Nama Rekening
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                size="sm"
                                                placeholder="Masukan nama untuk rekening"
                                                name="namaRekening"
                                                value={form.namaRekening}
                                            />
                                            <Form.Control.Feedback size="sm" type="invalid">
                                                Nama rekening wajib diisi
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Nisbah
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            disabled
                                                            size="sm"
                                                            placeholder="0"
                                                            name="nisbahCounter"
                                                            value={
                                                                form._informasiLain.nisbahCounter
                                                            }
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Zakat Bagi Hasil
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            disabled
                                                            name="zakatBagiHasil"
                                                            size="sm"
                                                            placeholder="0"
                                                            value={form._informasiLain.nisbahZakat}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Valuta
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                size="sm"
                                                placeholder="Terisi otomatis"
                                                value={form.valuta}
                                            />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Pajak Bagi Hasil
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            value={form._informasiLain.nisbahPajak}
                                                            disabled
                                                            size="sm"
                                                            placeholder="0"
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Nisbah Akhir
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            disabled
                                                            size="sm"
                                                            placeholder="0"
                                                            name="nisbahAkhir"
                                                            value={form._informasiLain.nisbahAkhir}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <div className="tw-mb-5">
                                                <Form.Label className="font-weight-normal f-12">
                                                    Keterangan
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Contoh: Tabungan Penerima"
                                                    name="notes"
                                                    value={form.notes}
                                                    disabled
                                                />
                                            </div>
                                            <div>
                                                {/* <Form.Label className="font-weight-normal f-12">
                                                    Alamat Statement / Passbook
                                                </Form.Label>
                                                <Form.Control size="sm" disabled as="select">
                                                    <option selected hidden disabled>
                                                        Pilih alamant statement / passbook
                                                    </option>
                                                </Form.Control> */}
                                            </div>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Blokir Debet"
                                                    checked={form._rekeningTabungan?.blokirDebet}
                                                    name="blokirDebet"
                                                    disabled
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Blokir Kredit"
                                                    checked={form._rekeningTabungan?.blokirKredit}
                                                    name="blokirKredit"
                                                    disabled
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Cek Nota"
                                                    checked={form._rekeningTabungan?.cekNota}
                                                    name="cekNota"
                                                    disabled
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Status Passbook"
                                                    checked={form._rekeningTabungan?.statusPassbook}
                                                    name="statusPassbook"
                                                    disabled
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Dormant"
                                                    checked={form._rekeningTabungan?.dormant}
                                                    name="dormant"
                                                    disabled
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya Rekening Dormant"
                                                    checked={
                                                        form._rekeningTabungan.biayaRekeningDormant
                                                    }
                                                    name="biayaRekeningDormant"
                                                    disabled
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya Administrasi"
                                                    checked={
                                                        form._rekeningTabungan.biayaAdministrasi
                                                    }
                                                    name="biayaAdministrasi"
                                                    disabled
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya Saldo Minimum"
                                                    checked={
                                                        form._rekeningTabungan.biayaSaldoMinimum
                                                    }
                                                    name="biayaSaldoMinimum"
                                                    disabled
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya ATM"
                                                    checked={form._rekeningTabungan.biayaATM}
                                                    name="biayaATM"
                                                    disabled
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    <div
                                        style={{ gap: '0 111px' }}
                                        className="tw-grid tw-grid-cols-2 tw-grid-rows-3"
                                    >
                                        <div className="tw-grid tw-gap-5 tw-row-span-3">
                                            <div>
                                                <Form.Label className="font-weight-normal f-12">
                                                    QQ Name
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Nama Alias"
                                                    disabled
                                                    name="qqName"
                                                    value={form._informasiLain.qqName}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Biaya-Biaya
                                                </Form.Label>
                                                <Form.Control
                                                    disabled
                                                    size="sm"
                                                    placeholder="Biaya-Biaya"
                                                    name="biayaBiaya"
                                                    value={form._informasiLain.biayaBiaya}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label className="font-weight-normal f-12">
                                                    TnC{' '}
                                                    {form.accountHolderType === 'CLIENT' && (
                                                        <Label className="ml-2" size="mini">
                                                            Wajib
                                                        </Label>
                                                    )}
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Versi TnC"
                                                    disabled
                                                    name="tncVersion"
                                                    value={form._informasiLain.tncVersion}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Tnc version wajib diisi
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="tw-grid tw-grid-cols-2 tw-row-span-2 tw-gap-y-5 tw-gap-x-7 tw-px-2">
                                            <div className="tw-grid tw-grid-cols-2">
                                                <Form.Label className="font-weight-normal f-12 tw-col-span-2">
                                                    Internet Banking
                                                </Form.Label>
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="Yes"
                                                    disabled
                                                    value="Y"
                                                    name="isInternetBanking"
                                                    // checked={
                                                    //     form._informasiLainKorporat
                                                    //         .isInternetBanking === 'Y'
                                                    // }
                                                />
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="No"
                                                    disabled
                                                    value="N"
                                                    name="isInternetBanking"
                                                    // checked={
                                                    //     form._informasiLainKorporat
                                                    //         .isInternetBanking === 'N'
                                                    // }
                                                />
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2">
                                                <Form.Label className="font-weight-normal f-12 tw-col-span-2">
                                                    SMS Banking
                                                </Form.Label>
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="Yes"
                                                    disabled
                                                    value="Y"
                                                    name="isSmsBanking"
                                                    // checked={
                                                    //     form._informasiLainKorporat.isSmsBanking ===
                                                    //     'Y'
                                                    // }
                                                />
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="No"
                                                    disabled
                                                    value="N"
                                                    name="isSmsBanking"
                                                    // checked={
                                                    //     form._informasiLainKorporat.isSmsBanking ===
                                                    //     'N'
                                                    // }
                                                />
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2">
                                                <Form.Label className="font-weight-normal f-12 tw-col-span-2">
                                                    Mobile Banking
                                                </Form.Label>
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="Yes"
                                                    disabled
                                                    value="Y"
                                                    name="isMobileBanking"
                                                    // checked={
                                                    //     form._informasiLainKorporat
                                                    //         .isMobileBanking === 'Y'
                                                    // }
                                                />
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="No"
                                                    disabled
                                                    value="N"
                                                    name="isMobileBanking"
                                                    // checked={
                                                    //     form._informasiLainKorporat
                                                    //         .isMobileBanking === 'N'
                                                    // }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form>{' '}
                            </>
                        )}
                        {activeIdx === 1 && (
                            <>
                                <FormHeader className="mb-4">Informasi Lain</FormHeader>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Sumber Dana
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control
                                                name="sumberDana"
                                                size="sm"
                                                as="select"
                                                disabled
                                            >
                                                <option value="" selected hidden>
                                                    {form._informasiLain.sumberDana}
                                                </option>
                                            </Form.Control>
                                            <Form.Control.Feedback size="sm" type="invalid">
                                                Sumber dana wajib diisi
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Marketing Referensi
                                            </Form.Label>
                                            <div className="tw-grid tw-grid-cols-3 tw-gap-x-5">
                                                <Form.Control
                                                    value={form._informasiLain.marketingReferensi}
                                                    name="marketingReferensi"
                                                    size="sm"
                                                    disabled
                                                    className="tw-col-span-2"
                                                />
                                                <Form.Control
                                                    value={form._informasiLain.marketingReferensi}
                                                    name="marketingReferensi"
                                                    size="sm"
                                                    disabled
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Tujuan Pembukaan Rekening
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control
                                                name="tujuanPembukaanRekening"
                                                disabled
                                                size="sm"
                                                as="select"
                                            >
                                                <option value="" selected hidden>
                                                    {form._informasiLain.tujuanPembukaanRekening}
                                                </option>
                                            </Form.Control>
                                            <Form.Control.Feedback size="sm" type="invalid">
                                                Tujuan pembukaan rekening wajib diisi
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Marketing Pertama
                                            </Form.Label>
                                            <div className="tw-grid tw-grid-cols-3 tw-gap-5">
                                                <Form.Control
                                                    value={form._informasiLain.marketingPertama}
                                                    name="marketingPertama"
                                                    size="sm"
                                                    disabled
                                                    className="tw-col-span-2"
                                                />
                                                <Form.Control
                                                    value={form._informasiLain.marketingPertama}
                                                    name="marketingPertama"
                                                    size="sm"
                                                    disabled
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Kelengkapan Data
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control
                                                name="kelengkapanData"
                                                disabled
                                                size="sm"
                                                as="select"
                                            >
                                                <option value="" selected hidden>
                                                    {form._informasiLain.kelengkapanData}
                                                </option>
                                            </Form.Control>
                                            <Form.Control.Feedback size="sm" type="invalid">
                                                Kelengkapan data wajib diisi
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Marketing Saat ini
                                            </Form.Label>
                                            <div className="tw-grid tw-grid-cols-3 tw-gap-x-5">
                                                <Form.Control
                                                    value={form._informasiLain.marketingSaatIni}
                                                    name="marketingSaatIni"
                                                    size="sm"
                                                    disabled
                                                    className="tw-col-span-2"
                                                />
                                                <Form.Control
                                                    value={form._informasiLain.marketingSaatIni}
                                                    name="marketingSaatIni"
                                                    size="sm"
                                                    disabled
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    {/* <FormHeader className="mb-4">Asuransi</FormHeader>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Rencana Asuransi Utama
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                as="select"
                                                disabled
                                                name="rencanaAsuransiUtama"
                                            >
                                                <option value="" selected hidden>
                                                    {form._asuransi?.rencanaAsuransiUtama}
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Rencana Asuransi Tambahan
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                as="select"
                                                disabled
                                                name="rencanaAsuransiTambahan"
                                            >
                                                <option value="" selected hidden>
                                                    {form._asuransi?.rencanaAsuransiTambahan}
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Tanggal Jatuh Tempo
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                size="sm"
                                                type="date"
                                                name="tanggalJatuhTempoAsuransi"
                                                value={form._asuransi?.tanggalJatuhTempoAsuransi}
                                            />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}></Form.Group>
                                    </Form.Row> */}
                                    <FormHeader className="mb-4">Alamat Alternatif</FormHeader>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Alamat
                                            </Form.Label>
                                            <Form.Control
                                                style={{ height: 96 }}
                                                as="textarea"
                                                size="sm"
                                                placeholder="Masukkan alamat"
                                                disabled
                                                name="alamat"
                                                value={form._alamatAlternatif.alamat}
                                            />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Provinsi
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        name="provinsi"
                                                        size="sm"
                                                        as="select"
                                                    >
                                                        <option value="" selected hidden>
                                                            {form._alamatAlternatif.provinsi}
                                                        </option>
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kota / Kab
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        name="kotaKabupaten"
                                                        size="sm"
                                                        as="select"
                                                    >
                                                        <option value="" selected hidden>
                                                            {form._alamatAlternatif.kotaKabupaten}
                                                        </option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kecamatan
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="kecamatan"
                                                        disabled
                                                        size="sm"
                                                        as="select"
                                                    >
                                                        <option value="" selected hidden disabled>
                                                            {form._alamatAlternatif.kecamatan}
                                                        </option>
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kelurahan
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="kelurahan"
                                                        disabled
                                                        size="sm"
                                                        as="select"
                                                    >
                                                        <option value="" selected hidden disabled>
                                                            {form._alamatAlternatif.kelurahan}
                                                        </option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                RT / RW
                                            </Form.Label>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Control
                                                        disabled
                                                        name="rt"
                                                        placeholder="RT"
                                                        size="sm"
                                                        value={form._alamatAlternatif.rt}
                                                    />
                                                </Form.Group>
                                                <span className="f-18 mx-3 mt-1">/</span>
                                                <Form.Group as={Col}>
                                                    <Form.Control
                                                        disabled
                                                        name="rw"
                                                        placeholder="RW"
                                                        size="sm"
                                                        value={form._alamatAlternatif.rw}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Kode Pos
                                            </Form.Label>
                                            <Form.Control
                                                name="kodePos"
                                                disabled
                                                size="sm"
                                                as="select"
                                            >
                                                <option value="" selected hidden disabled>
                                                    {form._alamatAlternatif.kodePos}
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}></Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Telepon Rumah
                                            </Form.Label>
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '70px 24px 1fr',
                                                    alignItems: 'center',
                                                    justifyItems: 'center',
                                                }}
                                            >
                                                <Form.Control
                                                    value={form._alamatAlternatif.telepon}
                                                    name="telepon1"
                                                    placeholder="000"
                                                    size="sm"
                                                    disabled
                                                    maxLength={3}
                                                />
                                                <span className="f-18">-</span>
                                                <Form.Control
                                                    value={form._alamatAlternatif.telepon}
                                                    name="telepon2"
                                                    placeholder="000"
                                                    size="sm"
                                                    disabled
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                            </>
                        )}
                    </div>
                )}
                {activeIdx === 2 && (
                    <div className="py-4">
                        <div className="px-5 tw-mb-3">
                            <div className="tw-flex tw-items-center tw-justify-between">
                                <div className="tw-text-primary tw-font-bold tw-text-16">
                                    Data Specimen Pejabat
                                </div>
                                <Button
                                    onClick={() => {
                                        setModal({ ...modal, specimenPejabat: true });
                                        setTipeSpecimenPejabat('registrasi');
                                    }}
                                    color="orange"
                                    size="mini"
                                    disabled
                                >
                                    Tambah Specimen Pejabat
                                </Button>
                            </div>
                        </div>
                        <TableGrid
                            columns={[
                                {
                                    name: 'Nama Lengkap',
                                    field: 'namaLengkap',
                                },
                                {
                                    name: 'Jabatan',
                                    field: 'jabatan',
                                },
                                {
                                    name: 'Keterangan',
                                    field: 'keterangan',
                                },
                                {
                                    name: '',
                                    field: 'action',
                                    width: '50px',
                                },
                            ]}
                            data={form._specimenPejabat}
                            config={{
                                height: '60vh',
                                overflowY: 'auto',
                                stickyHead: true,
                            }}
                        />
                    </div>
                )}
                {activeIdx === 3 && (
                    <>
                        <div className="py-4">
                            <div className="px-5 tw-mb-3">
                                <div className="tw-flex tw-items-center tw-justify-between">
                                    <div className="tw-text-primary tw-font-bold tw-text-16">
                                        Data Hak Akses Rekening
                                    </div>
                                    <Button
                                        onClick={() => {
                                            setModal({ ...modal, hakAksesData: true });
                                            setTipeRelasiNasabah('tambah');
                                            setRelasiNasabahState([]);
                                        }}
                                        color="orange"
                                        size="mini"
                                        disabled
                                    >
                                        Tambah Relasi Nasabah
                                    </Button>
                                </div>
                            </div>
                            <TableGrid
                                columns={[
                                    {
                                        name: 'Nama Lengkap',
                                        field: 'namaLengkap',
                                    },
                                    {
                                        name: 'Tanggal Lahir',
                                        field: 'tanggalLahir',
                                    },
                                    {
                                        name: 'Nama Ibu Kandung',
                                        field: 'namaIbuKandung',
                                    },
                                    {
                                        name: 'Identitas',
                                        field: 'identitas',
                                    },
                                    {
                                        name: 'No. Identitas',
                                        field: 'noIdentitas',
                                    },
                                    {
                                        name: 'Nomor Telepon',
                                        field: 'noTelpon',
                                    },
                                    {
                                        name: 'Keterangan',
                                        field: 'keterangan',
                                    },
                                    {
                                        name: '',
                                        field: 'action',
                                        width: '50px',
                                    },
                                ]}
                                config={{
                                    height: '50vh',
                                    overflowY: 'auto',
                                    stickyHead: true,
                                }}
                                data={form._hakAksesRekening}
                            />
                        </div>
                        <div className="tw-px-20 tw-mb-6">
                            <Message className="text-center" warning>
                                Sebelum melanjutkan proses registrasi, pastikan data yang Anda
                                masukan sudah benar.
                            </Message>
                        </div>
                    </>
                )}

                <div className="py-4 px-5 border-top border-gray d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                        <Button onClick={() => history.goBack()} color="blue" size="mini">
                            Batal
                        </Button>
                        <div className="d-flex align-items-center justify-content-between">
                            <Button
                                onClick={handlePrevious}
                                className={clsx('mr-2 tw-transition-none', {
                                    'tw-bg-black-70': activeIdx === 0,
                                })}
                                disabled={activeIdx === 0}
                                size="mini"
                                color="orange"
                            >
                                Sebelumnya
                            </Button>
                            <Button
                                disabled={activeIdx === 3}
                                onClick={handleNext}
                                color="orange"
                                size="mini"
                                className={clsx('tw-transition-none', {
                                    'mr-2 tw-bg-black-70': activeIdx === 3,
                                })}
                            >
                                Selanjutnya
                            </Button>
                            {(activeIdx === 3 || type === 'edit') && type !== 'view' && (
                                <Button onClick={() => setConfirm(true)} color="blue" size="mini">
                                    Simpan
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailTabunganPage;
