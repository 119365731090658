import React, { memo, useEffect, useRef, useState } from 'react';
import { AppAction, AppInterfaces } from '../../../app.data';
import { TableList } from '../../controls';
import { useHistory } from 'react-router-dom';
import ModalInfoNegatifNasabah from './info_negatif_modal';
import { baseUrl } from '../../../app.config';

export default AppInterfaces.appLoad(
    memo(function ({ store, methods, dispatch }) {
        const appAction = useRef(null);
        const baseUrl = '/nasabah';
        const { data = [] } = store;
        const [currentType, setCurrentType] = useState('GROUP');
        const [currentTypeSearch, setCurrentTypeSearch] = useState('name');
        const [currentSearchKey, setCurrentSearchKey] = useState('');
        const [paramFetch, setParamFetch] = useState({
            sortingCriteria: {
                field: 'creationDate',
                order: 'DESC',
            },
            filterCriteria: [
                {
                    field: 'groupName',
                    value: '',
                    operator: 'EQUALS',
                },
            ],
        });

        const [tipeInfoNegatif, setTipeInfoNegatif] = useState('peragaan');
        const [form, setForm] = useState({});
        const [modal, setModal] = useState({
            peragaan: false,
            registrasi: false,
        });
        const [modeInfoNegatif, setModeInfoNegatif] = useState('default');

        const setFilter = function (param, data) {
            setParamFetch((prevState) => ({
                ...prevState,
                [param]: data,
            }));
        };

        useEffect(
            function () {
                getDataHandler();
            },
            [currentType]
        );

        let history = useHistory();

        const getDataHandler = (page, limit) => {
            // Sample
            setTimeout(() => {
                appAction.current.disp({
                    type: 'fetchSuccess',
                    key: 'data',
                    list: [
                        {
                            nomor_cif: 1221111, 
                            nama: "Gustavo",
                            status_cif: 'Perpanjangan',
                            nomor_identitas: 323332111,
                            nomor_npwp: "NP122111",
                            gelar: "S1",
                            tgl_lahir: "22-01-1994",
                            dhib: "T",
                            dhn: "T#2",
                            no_dhn: "2/05/14",
                            batas_sanksi: "15-05-2015",
                        }
                    ],
                });
            }, 500);
            // Request GET
            appAction.current.doGet({
                url: baseUrl,
                vari: 'data',
                withAlert: false,
                param: { 
                    page: page || 0, 
                    limit: limit || 15,
                    type: currentType,
                },
            });
        }

        return (
            <>
                <AppAction ref={appAction} />
                <ModalInfoNegatifNasabah
                    show={modal.peragaan || modal.registrasi}
                    form={{
                        _negatifNasabah: {
                            ...form.info_negatif,
                            nama_lengkap: form.nama,
                            alamat: form.alamat,
                            id: form.nomor_cif,
                        },
                    }}
                    onHide={() => {
                        setModal({ ...modal, peragaan: false, registrasi: false });
                    }}
                    type={tipeInfoNegatif}
                    onSuccess={() => {
                        appAction.current.doGet({
                            url: '/nasabah',
                            vari: 'data',
                            param: {
                                page: 0,
                                limit: 15,
                                ...paramFetch,
                            },
                        });
                        setForm({});
                    }}
                    isApproved
                />
                <nav aria-label="breadcrumb" className="mb-3">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a className="text-primary-bds" href="#">Nasabah</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Informasi Daftar Hitam Nasabah
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-md-12">
                        <TableList
                            navicon1 = 
                            {
                                <svg width="66" height="24" viewBox="0 0 66 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0L10.9395 0C11.3373 8.49561e-05 11.7188 0.158176 12 0.4395L17.5605 6C17.8418 6.28124 17.9999 6.66271 18 7.0605V21C18 21.7956 17.6839 22.5587 17.1213 23.1213C16.5587 23.6839 15.7956 24 15 24H3C2.20435 24 1.44129 23.6839 0.87868 23.1213C0.31607 22.5587 0 21.7956 0 21V3ZM10.5 6L11.25 2.25L15.75 6.75L12 7.5C11.6022 7.5 11.2206 7.34196 10.9393 7.06066C10.658 6.77936 10.5 6.39782 10.5 6ZM1.5 13.5V15H4.5V18H1.5V19.5H4.5V22.5H6V19.5H10.5V22.5H12V19.5H16.5V18H12V15H16.5V13.5H1.5ZM6 18V15H10.5V18H6Z" fill="#28A745"/>
                                    <path d="M30.0568 17H35.392V16.0625H31.1136V13.0966H35.0511V12.1591H31.1136V9.21023H35.3239V8.27273H30.0568V17ZM37.8423 10.4545H36.6832L38.7628 13.7273L36.6832 17H37.8423L39.4105 14.4602L40.9787 17H42.1378L40.0241 13.7273L42.1378 10.4545H40.9787L39.4105 13.1307L37.8423 10.4545ZM43.5728 19.4545H44.5785V15.9943H44.6637C44.8853 16.3523 45.3114 17.1364 46.5728 17.1364C48.2092 17.1364 49.3512 15.8239 49.3512 13.7443C49.3512 11.6818 48.2092 10.3693 46.5558 10.3693C45.2773 10.3693 44.8853 11.1534 44.6637 11.4943H44.5444V10.4545H43.5728V19.4545ZM44.5614 13.7273C44.5614 12.2614 45.2092 11.2727 46.4364 11.2727C47.7148 11.2727 48.3455 12.3466 48.3455 13.7273C48.3455 15.125 47.6978 16.233 46.4364 16.233C45.2262 16.233 44.5614 15.2102 44.5614 13.7273ZM53.5444 17.1364C55.3171 17.1364 56.5103 15.7898 56.5103 13.7614C56.5103 11.7159 55.3171 10.3693 53.5444 10.3693C51.7717 10.3693 50.5785 11.7159 50.5785 13.7614C50.5785 15.7898 51.7717 17.1364 53.5444 17.1364ZM53.5444 16.233C52.1978 16.233 51.5842 15.0739 51.5842 13.7614C51.5842 12.4489 52.1978 11.2727 53.5444 11.2727C54.891 11.2727 55.5046 12.4489 55.5046 13.7614C55.5046 15.0739 54.891 16.233 53.5444 16.233ZM58.0455 17H59.0511V12.858C59.0511 11.9716 59.75 11.3239 60.7045 11.3239C60.973 11.3239 61.25 11.375 61.3182 11.392V10.3693C61.2031 10.3608 60.9389 10.3523 60.7898 10.3523C60.0057 10.3523 59.3239 10.7955 59.0852 11.4432H59.017V10.4545H58.0455V17ZM65.767 10.4545H64.3693V8.88636H63.3636V10.4545H62.375V11.3068H63.3636V15.3977C63.3636 16.5398 64.2841 17.0852 65.1364 17.0852C65.5114 17.0852 65.75 17.017 65.8864 16.9659L65.6818 16.0625C65.5966 16.0795 65.4602 16.1136 65.2386 16.1136C64.7955 16.1136 64.3693 15.9773 64.3693 15.125V11.3068H65.767V10.4545Z" fill="#28A745"/>
                                </svg>
                            } 
                            onChangeShow={(detil) => {
                                getDataHandler(detil.page, detil.limit)
                                // appAction.current.doGet({
                                //     url: '/nasabah',
                                //     vari: 'data',
                                //     param: {
                                //         page: detil.page,
                                //         limit: detil.limit,
                                //         type: currentType,
                                //     },
                                // });
                            }}
                            onNextPage={(detil) => {
                                getDataHandler(detil.page, detil.limit)
                                // appAction.current.doGet({
                                //     url: '/nasabah',
                                //     vari: 'data',
                                //     param: {
                                //         ...detil,
                                //         page: detil.page - 1,
                                //         ...paramFetch,
                                //         type: currentType,
                                //     },
                                // });
                            }}
                            onPrevPage={(detil) => {
                                getDataHandler(detil.page, detil.limit)
                                // appAction.current.doGet({
                                //     url: '/nasabah',
                                //     vari: 'data',
                                //     param: {
                                //         ...detil,
                                //         page: detil.page - 1,
                                //         ...paramFetch,
                                //         type: currentType,
                                //     },
                                // });
                            }}
                            withActions={false}
                            actionItems={[
                                {
                                    name: 'Lihat Detail Nasabah',
                                    onClick: (e) => {
                                        e.jenis_nasabah === 'Nasabah Korporat'
                                            ? history.push(
                                                `/nasabah-cif/register/badan-usaha?mode=info&db_id=${e.nomor_cif}&from=mambu`
                                            )
                                            : history.push(
                                                `/nasabah-cif/register/perorangan?mode=info&db_id=${e.nomor_cif}&from=mambu`
                                            );
                                    },
                                },
                                {
                                    name: 'Ubah Data Nasabah',
                                    onClick: (e) => {
                                        e.jenis_nasabah === 'Nasabah Korporat'
                                            ? history.push(
                                                `/nasabah-cif/register/badan-usaha?mode=update&db_id=${e.nomor_cif}&from=mambu`
                                            )
                                            : history.push(
                                                `/nasabah-cif/register/perorangan?mode=update&db_id=${e.nomor_cif}&from=mambu`
                                            );
                                    },
                                },
                                {
                                    name: 'Registrasi Info Negatif Nasabah',
                                    onClick: (e) => {
                                        setModal({ ...modal, registrasi: true });
                                        setForm(e);
                                        setTipeInfoNegatif('registrasi');
                                    },
                                    enabled: (e) => e?.info_negatif === null,
                                },
                                {
                                    name: 'Info Negatif Nasabah',
                                    onClick: (e) => {
                                        setModal({ ...modal, peragaan: true });
                                        setForm(e);
                                        setTipeInfoNegatif('peragaan');
                                    },
                                    enabled: (e) => e?.info_negatif !== null,
                                },
                                {
                                    name: 'Signature Verification System',
                                    onClick: (e) => {
                                        // console.log(e);
                                        history.push(
                                            `/nasabah-cif/data/svs_rekening_nasabah_korporat?id=${e.nomor_cif}&from=approved`
                                        );
                                    },
                                    enabled: (e) => e.jenis_nasabah === 'Nasabah Korporat',
                                },
                            ]}
                            columns={[
                                // {
                                //     name: '#',
                                //     field: '#',
                                //     maxWidth: '50px',
                                // },
                                {
                                    name: 'Nomor Nasabah',
                                    field: 'nomor_cif',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Nama Lengkap',
                                    field: 'nama',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Status CIF',
                                    field: 'status_cif',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Gelar',
                                    field: 'gelar',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'No. Identitas',
                                    field: 'nomor_identitas',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'NPWP',
                                    field: 'nomor_npwp',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Tgl. Lahir',
                                    field: 'tgl_lahir',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'DHIB',
                                    field: 'dhib',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'DHN',
                                    field: 'dhn',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'No DHN',
                                    field: 'no_dhn',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Profil Resiko',
                                    field: 'profile_resiko',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Batas Sanksi',
                                    field: 'batas_sanksi',
                                    maxWidth: '50px',
                                },
                                // {
                                //     name: 'Keterangan Negatif',
                                //     field: 'info_negatif',
                                //     maxWidth: '50px',
                                //     onRender: function (item) {
                                //         return item?.info_negatif?.status_data === 'NOTACTIVE' ||
                                //             !item?.info_negatif
                                //             ? '-'
                                //             : item?.info_negatif?.status_data;
                                //     },
                                // },
                            ]}
                            data={data}
                            filter={true}
                            formsFilter={[
                                {
                                    type: 'select',
                                    value: currentType,
                                    options: [
                                        // {
                                        //     key: 'CLIENT',
                                        //     display: 'Perorangan',
                                        // },
                                        // {
                                        //     key: 'GROUP',
                                        //     display: 'Badan Usaha',
                                        // },
                                    ],
                                    placeholder: 'Status CIF',
                                    disabled: false,
                                    readonly: false,
                                    onChange: (value) => {
                                        if (value !== 'Pilih Jenis Nasabah') {
                                            setCurrentType(value);
                                            // appAction.current.doGet({
                                            //     url: '/nasabah',
                                            //     vari: 'data',
                                            //     param: {
                                            //         page: 0,
                                            //         limit: 15,
                                            //         type: value,
                                            //     },
                                            // });
                                        }
                                    },
                                },
                                {
                                    type: 'select',
                                    value: currentTypeSearch,
                                    options: [
                                        // {
                                        //     key: 'name',
                                        //     display: 'Nama',
                                        // },
                                        // {
                                        //     key: 'CIF',
                                        //     display: 'Nomor CIF',
                                        // },
                                        // {
                                        //     key: 'idCard',
                                        //     display: 'Nomor Identitas',
                                        // },
                                    ],
                                    placeholder: 'DHIB',
                                    disabled: false,
                                    readonly: false,
                                    onChange: (value) => {
                                        if (value !== 'Pilih Tipe Pencarian') {
                                            setCurrentTypeSearch(value);
                                            // appAction.current.doGet({
                                            //     url: 'baseUrl',
                                            //     vari: 'data',
                                            //     param: {
                                            //         page: 0,
                                            //         limit: 15,
                                            //         type: value,
                                            //     },
                                            // });
                                        }
                                    },
                                },
                                {
                                    type: 'select',
                                    value: currentTypeSearch,
                                    options: [],
                                    placeholder: 'DHN',
                                    disabled: false,
                                    readonly: false,
                                    onChange: (value) => {
                                        if (value !== 'Pilih Tipe Pencarian') {
                                            setCurrentTypeSearch(value);
                                            // appAction.current.doGet({
                                            //     url: 'baseUrl',
                                            //     vari: 'data',
                                            //     param: {
                                            //         page: 0,
                                            //         limit: 15,
                                            //         type: value,
                                            //     },
                                            // });
                                        }
                                    },
                                },
                                {
                                    type: 'select',
                                    value: currentTypeSearch,
                                    options: [],
                                    placeholder: 'DHN EXP',
                                    disabled: false,
                                    readonly: false,
                                    onChange: (value) => {
                                        if (value !== 'Pilih Tipe Pencarian') {
                                            setCurrentTypeSearch(value);
                                            // appAction.current.doGet({
                                            //     url: 'baseUrl',
                                            //     vari: 'data',
                                            //     param: {
                                            //         page: 0,
                                            //         limit: 15,
                                            //         type: value,
                                            //     },
                                            // });
                                        }
                                    },
                                },
                                {
                                    type: 'text',
                                    options: [],
                                    placeholder: 'No Identitas atau Nama Nasabah',
                                    disabled: false,
                                    readonly: false,
                                    // value: paramFetch.filterCriteria[0].value,
                                    value: currentSearchKey,
                                    onChange: (value) => {
                                        setCurrentSearchKey(value);
                                    },
                                    // setParamFetch((prevState) => ({
                                    //     ...prevState,
                                    //     sortingCriteria: {
                                    //         field: 'creationDate',
                                    //         order: 'DESC',
                                    //     },
                                    //     filterCriteria: [
                                    //         {
                                    //             field: 'groupName',
                                    //             value: value,
                                    //             operator: 'EQUALS',
                                    //         },
                                    //     ],
                                    // })),
                                },
                            ]}
                            onFilter={() => { 
                                getDataHandler()
                                // appAction.current.doGet({
                                //     url: '/nasabah/filter',
                                //     vari: 'data',
                                //     param: {
                                //         page: 0,
                                //         limit: 15,
                                //         type: currentType,
                                //         searchBy: currentTypeSearch,
                                //         search: currentSearchKey,
                                //     },
                                // });

                                // appAction.current
                                //     .doPost({
                                //         uri:
                                //             '/nasabah-badan-usaha/filter?paginationDetails=OFF&detailsLevel=BASIC',
                                //         data: paramFetch,
                                //     })
                                //     .then((resp) => {
                                //         // // console.log(resp);
                                //         const { message_id, list } = resp;
                                //         message_id === '00' &&
                                //             appAction.current.disp({
                                //                 type: 'fetchSuccess',
                                //                 list,
                                //                 key: 'data',
                                //             });
                                //     });
                            }}
                            resetFilter={true}
                            onResetFilter={() => {
                                setCurrentType('GROUP');
                                setCurrentTypeSearch('name');
                                setCurrentSearchKey('');
                                getDataHandler();

                                // appAction.current.doGet({
                                //     url: '/nasabah',
                                //     vari: 'data',
                                //     param: {
                                //         page: 0,
                                //         limit: 15,
                                //         type: currentType,
                                //     },
                                // });
                            }}
                            submitButton="Cari"
                        />
                    </div>
                </div>
            </>
        );
    })
);
