import moment from 'moment';
import React, { useEffect } from 'react';
import { AppInterfaces } from '../../../../app.data';
import { TableList } from '../../../controls';
import { useParams } from 'react-router';
import { RekeningProvider } from '../../cs/tabungan-giro/data_rekening/useRekening';
import { SVSProvider } from '../../nasabah_cif/svs/useSvs';
import { RekeningProvider as DepositoProvider } from '../../cs/deposito/data_deposito/useDeposito';
import useOtorisasi, { OtorProvider } from './useOtorisasi';

import ModalAlertRekening from '../../cs/tabungan-giro/data_rekening/components/modalAlert';
import ModalAlertDeposito from '../../cs/deposito/data_deposito/components/modalAlert';
import ModalOtorSVS from '../../nasabah_cif/svs/modal/modalSVS';
import ModalAlertSVS from '../../nasabah_cif/svs/modal/modalAlert';
import ModalBlokirRekening from '../../cs/tabungan-giro/data_rekening/modal/modalBlokirRekening';
import ModalHoldDana from '../../cs/tabungan-giro/data_rekening/modal/modalHoldDana';
import ModalBukaRekening from '../../cs/tabungan-giro/data_rekening/modal/modalBukaRekening';
import ModalAktifasiDormant from '../../cs/tabungan-giro/data_rekening/modal/modalAktifasiDormant';
import ModalWarkat from '../../cs/tabungan-giro/data_rekening/modal/modalWarkat'
import ModalTutupAccount from '../../cs/tabungan-giro/data_rekening/modal/modalTutupAccount'
import ModalBatalTutupAccount from '../../cs/tabungan-giro/data_rekening/modal/modalBatalTutupAccount'
import ModalDetilAFT from '../../cs/tabungan-giro/data-aft/modal/detilModal';
import ModalUbahNisbah from '../../cs/deposito/data_deposito/modal/modalUbahNisbah';
import ModalBlokir from '../../cs/deposito/data_deposito/modal/modalBlokirDeposito';
import { AftProvider } from '../../cs/tabungan-giro/data-aft/useDataAft';
import ModalJoinAccount from '../../cs/tabungan-giro/data_rekening/modal/modalJoinAccount';
import { addCommas } from '../../../../module';
import { ModalDetilRevProv } from '../../teller/reversal_transaksi/component/detailReversal';
import ModalUseregitrasiTeller from '../../supervisor_teller/informasi_teller/modal/modalRegistrasi';
import { InfoTellerProvider } from '../../supervisor_teller/informasi_teller/useInformasiTeller';
import ModalCetakStatement from '../../cs/tabungan-giro/data_rekening/modal/modalCetakStatement';
import { BukuProvider } from '../../cs/tabungan-giro/data-buku-tabungan/useDataBuku';

function OtorPage(props) {
    const { otorState, otorAction } = useOtorisasi();
    const paramUrl = useParams();
    const column = {
        teller: [
            {
                name: "No. Ref",
                field: 'nomor_referensi'
            },
            {
                name: 'Kode Transaksi',
                field: 'kode_transaksi',
                minWidth: '100px',
            },

            {
                name: 'Rek. Kredit',
                field: 'nomor_rekening_credit',
                minWidth: '100px',
            },
            {
                name: 'Rek. Debit',
                field: 'nomor_rekening_debet',
                minWidth: '100px',
            },
            {
                name: 'Nominal',
                field: 'nominal',
                className: "text-right",
                onRender: (item) => addCommas(parseFloat(item?.nominal || 0).toFixed(2)),
                minWidth: '150px',
            },
            {
                name: 'User Input',
                field: 'user_input',
                minWidth: '100px',
            },
            {
                name: 'Tgl Valuta',
                field: 'tgl_input',
                minWidth: '150px',
                onRender: (item) => moment(item.tgl_input).format("DD-MM-YYYY HH:MM")
            },
            {
                name: 'Keterangan',
                field: 'keterangan',
                minWidth: '100px',
            },
            {
                name: '',
                field: 'act',
                minWidth: '50px',
                className: "text-right pr-0",
                onRender: (item) => {
                    // // console.log(item.status_otorisasi)
                    return ((item.status_otorisasi === "W" || item.status_otorisasi === null) ? <>
                        <button
                            disabled={item.kode_transaksi === "AKT" && item.is_otor_teller === null}
                            onClick={() => otorAction.process(item.id_transaksi, item.kode_transaksi, item)}
                            style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                            className="btn btn-sm btn-outline-success"
                        >
                            <b>Process</b>
                        </button>
                    </> : <> </>)
                }
            },
        ],
        cs: [
            {
                name: 'ID Otorisasi',
                field: 'id_otorisasi',
                minWidth: '100px',
            },
            {
                name: 'Kode Entry',
                field: 'kode_entri',
                minWidth: '100px',
            },
            {
                name: 'Nama Entry',
                field: 'nama_entri',
                minWidth: '150px',
            },
            {
                name: 'User Input',
                field: 'user_input',
                minWidth: '100px',
            },
            {
                name: 'Tgl Valuta',
                field: 'tgl_input_server',
                minWidth: '150px',
                onRender: (item) => moment(item.tgl_input).format("DD-MM-YYYY HH:MM")
            },
            {
                name: 'Kode Cabang',
                field: 'kode_cabang',
                minWidth: '120px',
            },
            {
                name: 'Keterangan',
                field: 'keterangan',
                minWidth: '100px',
            },
            {
                name: '',
                field: 'act',
                minWidth: '50px',
                className: "text-right pr-0",
                onRender: (item) => {
                    return ((item.status_otorisasi === "W" || item.status_otorisasi === null) ? <>
                        <button
                            onClick={() => otorAction.process(item.key_string, item.kode_entri, item)}
                            style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                            className="btn btn-sm btn-outline-success"
                        >
                            <b>Process</b>
                        </button>
                    </> : <> </>)
                }
            },
        ]
    }

    useEffect(function () {
        otorAction?.getEntriesCode?.()
    }, [])
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#"></a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Otorisasi Data Entry
                    </li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12">
                    {/* Card */}
                    <div className="card">
                        {/* Card Header */}
                        <div className="card-header" style={{ borderBottom: "1px solid #dee2e6" }}>
                            <div className="row">
                                <div className="col-6 pl-4 pt-2">
                                    <h3 className="title">
                                        Data Otorisasi Entry Data
                                    </h3>
                                </div>
                                <div className="col-6 text-right">
                                    <button
                                        onClick={otorAction.refresh}
                                        className="btn btn-outline-secondary btn-sm float-right">
                                        <i className="fa fa-undo-alt" > </i> Refresh
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* End Card Header */}
                        {/* Card body */}
                        <div className="card-body">
                            <TableList
                                curPage={otorState.pagination.page}
                                defaultShow={otorState.pagination.limit}
                                withCard={false}
                                isLoading={otorState.loadingGetData}
                                data={
                                    otorState.dataOtor
                                }
                                columns={
                                    column[paramUrl.role]
                                }
                                withActions={false}
                                filter={paramUrl.role === "cs"}
                                resetFilter={false}
                                submitButton="Lihat Data"
                                formsFilter={[
                                    {
                                        type: 'select',
                                        options: otorState.kodeEntries.map(({ nama_entri, kode_entri }) => ({ display: nama_entri, key: kode_entri })),
                                        placeholder: 'All data',
                                        disabled: false,
                                        readonly: false,
                                        value: otorState.kode_entry,
                                        onChange: (e) => otorAction.changeFilter("kode_entry", e)
                                    }
                                    // {
                                    //     type: 'select',
                                    //     options: [
                                    //     ],
                                    //     placeholder: 'Pilih Nama Entry',
                                    //     disabled: false,
                                    //     readonly: false,
                                    // },
                                ]}
                                onFilter={() => otorAction.getData()}
                                onChangeShow={(e) => otorAction.changeShow(e)}
                                onNextPage={(e) => otorAction.nextPage(e)}
                                onPrevPage={(e) => otorAction.prevPage(e)}
                            />
                        </div>
                        {/* End Card Body */}
                    </div>
                    {/* End Card */}
                </div>
            </div>
            <ModalHoldDana />
            <ModalBlokirRekening />
            {/* <ModalAlertRekening /> */}
            <ModalAlertDeposito />
            <ModalAlertSVS />
            <ModalOtorSVS />
            <ModalBukaRekening />
            <ModalAktifasiDormant />
            <ModalUbahNisbah />
            <ModalBlokir />
            <ModalWarkat />
            <ModalDetilAFT />
            <ModalJoinAccount />
            <ModalTutupAccount />
            <ModalBatalTutupAccount />
            <ModalUseregitrasiTeller/>
            <ModalCetakStatement />
        </>
    )
}

export default () => (
    <ModalDetilRevProv>
        <BukuProvider otorModeP={true}>
            
        <RekeningProvider isOtor={true}>
            <SVSProvider isOtor={true}>
                <DepositoProvider isOtor={true}>
                    <AftProvider otorModeP={true}>
                        <InfoTellerProvider otorModeR={true}>
                            <OtorProvider>
                                <OtorPage />
                            </OtorProvider>
                        </InfoTellerProvider>
                    </AftProvider>
                </DepositoProvider>
            </SVSProvider>
        </RekeningProvider>

        </BukuProvider>
    </ModalDetilRevProv>
)