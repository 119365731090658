import React, { useContext } from 'react';
import { baseUrl, apiV1 } from '../app.config';
import { AppContext } from '../app.data';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from './keycloak.module';
import { useModalAlert } from '../components/controls/modalAlert';
import { useLocalStorage } from '../module';
import moment from 'moment';


const useFetch = () => {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { keycloak } = useKeycloak();
    // // // // console.log(keycloak)
    const [lastActivity, setLastActivity] = useLocalStorage("lastActivity")
    async function wait(n) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), n);
        });
    }
    const doGet = async function ({
        param = {},
        url,
        service = 'cif',
        token = true
    }) {

        let uri = url


        if (param != null) {
            var keys = Object.keys(param);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + param[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }

        try {

            let resp = await fetchApi({ url: `/${service + apiV1 + uri}`, service, token });
            return resp;
        } catch (error) {
            return {
                statusCode: 500,
                statusText: error.toString(),
                remark: error.toString()
            }
        }
    }

    const doGetNoBase = async function ({
        param = {},
        url,
        service = 'cif',
        token = true
    }) {

        let uri = url


        if (param != null) {
            var keys = Object.keys(param);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + param[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }

        try {

            let resp = await fetchApi({ url: `${uri}`, service, token, withBaseUrl:false });
            return resp;
        } catch (error) {
            return {
                statusCode: 500,
                statusText: error.toString(),
                remark: error.toString()
            }
        }
    }


    function createUrlForm(data) {
        var formBody = [];
        for (var property in data) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        return formBody
    }
    async function doGetMultiple(options = []) {
        const response = await Promise.all(options.map(v => doGet(v)));
        var resp = {

        }
        options.map(({ responseVar }, i) => {
            resp = {
                ...resp,
                [responseVar]: response[i]
            }
        });
        return resp;
    }
    async function doPostMultiple(options = []) {
        const response = await Promise.all(options.map(v => doPost(v)));
        var resp = {

        }
        options.map(({ responseVar }, i) => {
            resp = {
                ...resp,
                [responseVar]: response[i]
            }
        });
        return resp;
    }

    async function doPost({
        url = "/",
        data = {},
        type = 0,
        paramImage = '',
        method = 'POST',
        service = 'cif',
        token = true,
        headers = null,
        queryParam = null,
        withBaseUrl = true
    }, callback = () => null) {
        var opts = {
            ...(data === null ? {} : { body: type === 0 ? JSON.stringify(data) : data }),
            method,
        };
        if (headers !== null) opts = {
            ...(data === null ? {} :
                {
                    body: JSON.stringify(data)
                }
            ), headers,
            method
        };

        if (type == 1) {
            var keys = Object.keys(data);
            var form = new FormData();

            keys.forEach((val, key) => {
                if (val === 'img' && val === 'image' && val === paramImage) {
                    form.append(val, data[val], data[val].name);
                } else {
                    form.append(val, data[val]);
                }
                // // // // // // console.log(form.values())
            });
            opts = {
                body: form,
                method,
                headers: {}
            };
        }
        if (type == 2) {
            opts = {
                body: JSON.stringify(data),
                method,
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            };
        }


        let uri = url


        if (queryParam != null) {
            var keys = Object.keys(queryParam);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + queryParam[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }
        const resp = await fetchApi({ url: `/${service + apiV1 + uri}`, opts, token, withBaseUrl });
        // .then(resp => {
        const { statusCode, status, message_id, list } = resp;
        callback();
        return resp;
    }

    async function doFetch({
        url = "/",
        data = null,
        type = 0,
        paramImage = '',
        method = 'POST',
        token = true,
        headers = null,
        queryParam = null,
        isLogin = false
    }, callback = () => null) {
        var opts = {
            ...(data === null && method !== "GET" ? {} : { body: type === 0 ? JSON.stringify(data) : data }),
            method,
        };
        if (headers !== null) opts = {
            ...(data === null && method !== "GET" ? {} :
                {
                    body: JSON.stringify(data)
                }
            ), headers,
            method
        };

        if (type == 1) {
            var keys = Object.keys(data);
            var form = new FormData();

            keys.forEach((val, key) => {
                if (val === 'img' && val === 'image' && val === paramImage) {
                    form.append(val, data[val], data[val].name);
                } else {
                    form.append(val, data[val]);
                }
                // // // // // // console.log(form.values())
            });
            opts = {
                body: form,
                method,
                headers: {}
            };
        }
        if (type == 2) {
            opts = {
                body: data,
                method,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // Accept: 'application/x-www-form-urlencoded',
                    ...(headers !== null ? headers : {})

                }
            };
        }
        if (type == 3) {
            opts = {
                body: data,
                method,
                mode: "no-cors",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // Accept: 'application/x-www-form-urlencoded',
                    ...(headers !== null ? headers : {})

                }
            };
        }

        let uri = url
        if(method === "GET") {
            opts = {
                headers
            }
        }


        if (queryParam != null) {
            var keys = Object.keys(queryParam);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + queryParam[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }
        // // // // console.log(opts)
        const resp = await fetchApi({ url: uri, opts, token, withBaseUrl: false, isLogin });
        // .then(resp => {
        const { statusCode, status, message_id, list } = resp;
        callback();
        return resp;
    }

    async function fetchApi(
        {
            url = "/",
            service = "base",
            opts = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
            token = true,
            typeResponse = 'json',
            withBaseUrl = true,
            isLogin = false
        }
    ) {

        var data = {};
        let headers = opts.headers
        if (token) {
            let acc_token = await keycloak.getDataToken?.();
            // // // console.log(acc_token)
            if (headers === undefined || headers === null) {
                opts = {
                    ...opts,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${acc_token}`
                    }
                }
            } else {
                opts = {
                    ...opts,
                    headers: {
                        ...headers,
                        Authorization: `Bearer ${acc_token}`
                    }
                }
            }
        }
        try {
            // // // // console.log(opts)

            // const controller = new AbortController();
            // const id = setTimeout(() => function(){ 
            //     controller.abort()
            //     setDataAlert({ show: true, body: "Request Timeout. Koneksi tidak stabil", titile: ``, callBack: () => null });
            //     return { statusCode: 408, statusText: "Request Timeout. Koneksi tidak stabil", remark:"Request Timeout. Koneksi tidak stabil" }
            // }, 60000);
            var fetchResponse = await fetch(`${(withBaseUrl ? baseUrl : "") + url}`, opts);
            // if(moment(lastActivity).add(5, "minutes") < moment(Date.now())){
            //     setLastActivity(undefined)
            //     setDataAlert({ show: true, body: "Maaf sesi login telah habis. silahkan login kembali", titile: `Session habis`, callBack: () =>  keycloak.logout() });
            //     return { statusCode : 401, statusText: "Session Habis", remark:"Maaf sesi login telah habis. silahkan login kembali"  }
            // }else{
            //     setLastActivity((Date.now()))
            // }
            // clearTimeout(id)
            
            // console.log(fetchResponse)
            if(token){

                if ((fetchResponse.status === 401 || fetchResponse.status === 403) && !isLogin) {
    
                    let refreshed = await keycloak.refToken();
                    // console.log(refreshed)
                    if(!refreshed){
                        setDataAlert({ show: true, body: "Maaf sesi login telah habis. silahkan login kembali", titile: `Session habis`, callBack: () => keycloak.logout() });
                        return { statusCode: 401, statusText: "Session Habis", remark: "Maaf sesi login telah habis. silahkan login kembali" }
                    }
                    fetchResponse = await fetch(`${(withBaseUrl ? baseUrl : "") + url}`, {
                        ...opts,
                        headers: {
                            ...headers,
                            Authorization: `Bearer ${refreshed}`
                        }
                    });
    
                }
            }
            if(fetchResponse.ok || (fetchResponse.status === 400))
            {
                if (fetchResponse.status !== 200) {
                    data = await fetchResponse.json();
                } else {
                    if (typeResponse == 'blob') {
                        data = await fetchResponse.blob();
                        // // // // // // console.log("res")
                    } else {
                        // fetchResponse.json
                        data = await fetchResponse.json();
                    }
                }
                // // // // // // console.log(data)
    
                if (fetchResponse.status === 400) return { ...data, statusCode: 200, statusText: "" }
    
                return { ...data, statusCode: fetchResponse.status, statusText: fetchResponse.statusText }
            }else{
                // console.log()
                return { 
                    data : null, 
                    ...fetchResponse,
                    statusCode: fetchResponse.status, 
                    remark: `${fetchResponse.statusText}`,
                    statusText: `Error ${fetchResponse.status}`
                }
            }
        } catch (err) {
            console.log(err, err.toString())
            return { statusCode: 500, statusText: "Failed to fetch", remark: err.toString() }
        }
    }

    async function cekUserLevel(nomor_rekening, callBack = () => null) {
        let izinKan = false;
        let rem = "";
        try {
            const resp = await doGet({
                url: `/rekening/level-rekening-vs-user/${nomor_rekening}`,
                service: "acc"
            })
            const { statusCode, status, remark, statusText, data = {} } = resp;
            rem = remark;
            if (statusCode === 200) {
                if (status === "00") {
                    izinKan = data.status_diizinkan || false;
                }
                else setDataAlert({ show: true, body: remark, header: `Info`, callBack });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Info", callBack });
        } catch (error) {
            rem = error.toString()
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Info", callBack });
        }
        return { izinKan, remark: rem };
    }

    async function getStatusBranch(withLoading = false) {
        let data = {}
        // // console.log(keycloak)
        if (keycloak.authenticated) {
            withLoading && openLoading()
            try {
                let resp = await doGet({
                    url: "/verifikasi/info-branch",
                    service: "trx",
                    param: {
                        branch_code: keycloak.tokenParsed.branch_code
                    }
                })
                // // // console.log(resp)
                if (resp.statusCode === 200) {
                    if (resp.status === "00") {
                        data = resp.data
                    } else setDataAlert({ show: true, body: resp.remark, title: ``, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: resp.remark, title: ``, header: "Info", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
            }
            closeLoading()
        }
        return data;
    }


    async function createLog(data={}){

        try {
            let resp = await doPost({
                data,
                service:"acc",
                url:"/userauditprint/audit-trail-print"
            })
        } catch (error) {
            console.log(`Error on created log . ${error.toString()}`)
        }
    }

    return {
        doGet,
        doPost,
        doGetMultiple,
        doPostMultiple,
        wait,
        cekUserLevel,
        doFetch,
        createUrlForm,
        getStatusBranch, 
        createLog,
        doGetNoBase
    };
}

export default useFetch;
