import React, { memo, useEffect, useRef } from 'react';
import { AppAction, AppInterfaces } from '../../../../app.data';
import { createForm } from '../../../../module/createFormList';
import { FormRegistrasi } from './formRegistrasi';
import moment from 'moment';

const Step2 = AppInterfaces.appLoad(
    memo(({ store, ...props }) => {
        const { formData } = store;
        const appAction = useRef(null);

        const dropdownDetil = [
            {
                key: 'eStatusRestriksi',
                param: 'status_restriksi',
            },
            {
                key: 'R_TUJUAN_BUKA_REKENING',
                param: 'tujuan_buka',
            },
            {
                key: 'R_SUMBER_DANA_REKENING',
                param: 'sumber_dana',
            },
            {
                key: 'eStatusKelengkapan',
                param: 'status_kelengkapan',
            },
        ];

        useEffect(function () {
            dropdownDetil.forEach(({ key, param }) => {
                appAction.current.doGet({
                    url: '/dropdowns',
                    vari: param,
                    param: {
                        key,
                    },
                });
            });
        }, []);
        // console.log(formData._informasiLain?.statusRestriksi, 'tes');
        return (
            <>
                <AppAction ref={appAction} />
                <FormRegistrasi
                    itemsForm={[
                        {
                            name: 'Informasi Lain',
                            rows: [
                                {
                                    rows: 1,
                                    required: true,
                                    label: 'Status Restriksi',
                                    type: 'select',
                                    placeholder: formData._informasiLain?.statusRestriksi,
                                    options: store.disposisi_deposito,
                                    key: 'reference_code',
                                    display: 'reference_desc',
                                    value: formData._informasiLain?.statusRestriksi,
                                    disabled: true,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 1,
                                    label: 'Tujuan Pembukaan Rekening',
                                    type: 'select',
                                    value: formData._informasiLain?.tujuanBukaRekening,
                                    placeholder: 'Pilih Tujuan Pembukaan Rekening',
                                    options: store.tujuan_buka,
                                    key: 'reference_code',
                                    display: 'reference_desc',
                                    disabled: true,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 1,
                                    label: 'Sumber Dana',
                                    type: 'select',
                                    placeholder: 'Pilih Sumber Dana',
                                    options: store.sumber_dana,
                                    key: 'reference_code',
                                    display: 'reference_desc',
                                    value: formData._informasiLain?.sumberDana,
                                    disabled: true,
                                    readonly: false,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    placeholder: 'Pilih status kelengkapan ',
                                    type: 'select',
                                    options: store.status_kelengkapan,
                                    key: 'reference_code',
                                    display: 'reference_desc',
                                    required: true,
                                    label: 'Status Kelengkapan',
                                    disabled: true,
                                    readonly: false,
                                    value: formData._informasiLain?.statusKelengkapan,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-8">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Marketing',
                                                    type: 'text',
                                                    placeholder: 'Marketing',
                                                    disabled: true,
                                                    readonly: false,
                                                    value: formData._informasiLain?.marketing,
                                                    onChange: (value) => null,
                                                })}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-group-sm">
                                                    {/* <label>&nbsp;</label> */}
                                                    <button
                                                        style={{ marginTop: 30 }}
                                                        className="btn btn-sm btn-primary col"
                                                    >
                                                        &nbsp;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                            ],
                        },
                        {
                            name: 'Asuransi',
                            rows: [
                                {
                                    rows: 1,
                                    label: 'Rencana Asuransi Utama',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Rencana Asuransi Utama',
                                    disabled: true,
                                    readonly: false,
                                    value: formData._asuransi?.rencanaAsuransiUtama,
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 1,
                                    label: 'Tanggal Jatuh Tempo',
                                    type: 'text',
                                    options: [],
                                    placeholder: ' Tanggal Jatuh Tempo',
                                    disabled: true,
                                    readonly: false,
                                    value:
                                        formData._asuransi?.tanggalJatuhTempoAsuransi &&
                                        moment(
                                            formData._asuransi?.tanggalJatuhTempoAsuransi
                                        ).format('DD-MM-YYYY'),
                                    onChange: (value) => null,
                                },
                                {
                                    rows: 2,
                                    label: 'Rencana Asuransi Tambahan',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Rencana Asuransi Tambahan',
                                    disabled: true,
                                    readonly: false,
                                    value: formData._asuransi?.rencanaAsuransiTambahan,
                                    onChange: (value) => null,
                                },
                            ],
                        },
                        // {
                        //     name: "Alamat Alternatif",
                        //     rows: [
                        //         {
                        //             rows: 1,
                        //             label: "Alamat",
                        //             type: "textarea",
                        //             options: [],
                        //             placeholder: "Alamat",
                        //             disabled: true,
                        //             readonly: false,
                        //             value: formData._alamatAlternatif?.alamat,
                        //             onChange: (value) => null

                        //         }, {
                        //             rows: 1,
                        //             type: "custom",
                        //             component: (
                        //                 <div className="row">
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Provinsi",
                        //                             type: "text",
                        //                             placeholder: "Provinsi",
                        //                             disabled: true,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.provinsi,
                        //                             onChange: (value) => null
                        //                         })}
                        //                     </div>
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Kabupaten",
                        //                             type: "text",
                        //                             placeholder: "Kabupaten",
                        //                             disabled: true,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.kotaKabupaten,
                        //                             onChange: (value) => null
                        //                         })}
                        //                     </div>
                        //                 </div>
                        //             )
                        //         }, {
                        //             rows: 1,
                        //             type: "custom",
                        //             component: (
                        //                 <div className="row">
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Kecamatan",
                        //                             type: "text",
                        //                             placeholder: "Kecamatan",
                        //                             disabled: true,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.kecamatan,
                        //                             onChange: (value) => null
                        //                         })}
                        //                     </div>
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Kelurahan",
                        //                             type: "text",
                        //                             placeholder: "Kelurahan",
                        //                             disabled: true,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.kelurahan,
                        //                             onChange: (value) => null
                        //                         })}
                        //                     </div>
                        //                 </div>
                        //             )
                        //         },  {
                        //             rows: 2,
                        //             type: "custom",
                        //             component: (
                        //                 <div className="row">
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "RT / RW",
                        //                             type: "text",
                        //                             placeholder: "RT",
                        //                             disabled: true,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.rt,
                        //                             onChange: (value) => null
                        //                         })}
                        //                     </div>
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: <>&nbsp;</>,
                        //                             type: "text",
                        //                             placeholder: "RW",
                        //                             disabled: true,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.rw,
                        //                             onChange: (value) => null
                        //                         })}
                        //                     </div>
                        //                 </div>
                        //             )
                        //         },{
                        //             rows: 2,
                        //             type: "custom",
                        //             component: (
                        //                 <div className="row">
                        //                     <div className="col-md-12">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Kode Pos",
                        //                             type: "text",
                        //                             placeholder: "Kode Pos",
                        //                             disabled: true,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.kodePos,
                        //                         })}
                        //                     </div>
                        //                     {/* <div className="col-md-4">
                        //                         <div className="form-group form-group-sm">
                        //                             <button style={{ marginTop: 30, background:"#E6530D"}} className="btn btn-sm btn-primary col">
                        //                              Cari Kode Pos
                        //                             </button>
                        //                         </div>
                        //                     </div> */}
                        //                 </div>
                        //             )
                        //         },{
                        //             rows: 1,
                        //             label: "Telepon Rumah",
                        //             type: "text",
                        //             options: [],
                        //             placeholder: "Telepon Rumah",
                        //             disabled: true,
                        //             readonly: false,
                        //             value: formData._alamatAlternatif?.telepon,
                        //             onChange: (value) => null

                        //         },

                        //     ]
                        // }
                    ]}
                />
            </>
        );
    })
);

export default Step2;
