import clsx from 'clsx';

const StatusBadge = ({ children, color = 'green' }) => {
    return (
        <div
            className={clsx(
                'tw-capitalize tw-text-12 tw-bg-opacity-20 tw-rounded-full tw-px-3 tw-w-max',
                {
                    'tw-bg-green tw-text-green': color === 'green',
                    'tw-bg-red tw-text-red': color === 'red',
                    'tw-bg-black-60 tw-text-black-60': color === 'grey',
                    'tw-bg-primary tw-text-primary': color === 'orange',
                }
            )}
        >
            {children.toLowerCase()}
        </div>
    );
};

export default StatusBadge;
