import React, { memo, useRef } from 'react';
import {BasePagePay} from '../../controls';
import { AppAction, AppInterfaces } from '../../../app.data';

const nom = [
    
]

const Icon = memo(function(){
    return (
        <>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7914 5H16.3539C16.492 5 16.6245 5.05487 16.7222 5.15255C16.8198 5.25022 16.8747 5.3827 16.8747 5.52083C16.8747 5.7971 16.9844 6.06205 17.1798 6.2574C17.3751 6.45275 17.6401 6.5625 17.9164 6.5625H22.083C22.3593 6.5625 22.6243 6.45275 22.8196 6.2574C23.015 6.06205 23.1247 5.7971 23.1247 5.52083C23.1247 5.3827 23.1796 5.25022 23.2772 5.15255C23.3749 5.05487 23.5074 5 23.6455 5H25.208C26.9339 5 28.333 6.39911 28.333 8.125V14.2218C24.6604 14.3238 21.0187 15.7735 18.2218 18.5704C17.2023 19.5899 17.2023 21.2435 18.2218 22.263C18.7471 22.7883 19.4407 23.0429 20.1292 23.0269C20.1132 23.7154 20.3678 24.409 20.8931 24.9343C21.4184 25.4596 22.112 25.7142 22.8005 25.6982C22.7845 26.3867 23.0391 27.0803 23.5644 27.6056C24.5839 28.6251 26.2376 28.6251 27.2571 27.6056C27.562 27.3006 27.9383 27.1088 28.333 27.03V32.2917C28.333 34.0176 26.9339 35.4167 25.208 35.4167H14.7914C13.0655 35.4167 11.6664 34.0176 11.6664 32.2917V8.125C11.6664 6.39911 13.0655 5 14.7914 5Z" fill="#FFA040"/>
            <path d="M12.9164 8.125C12.9164 7.08947 13.7558 6.25 14.7914 6.25H15.7438C15.8555 6.58268 16.0431 6.88848 16.2959 7.14129C16.7257 7.57106 17.3086 7.8125 17.9164 7.8125H22.083C22.6908 7.8125 23.2737 7.57106 23.7035 7.14129C23.9563 6.88848 24.1439 6.58268 24.2556 6.25H25.208C26.2436 6.25 27.083 7.08947 27.083 8.125V14.3088C27.9132 14.2161 28.7497 14.1928 29.583 14.2391V8.125C29.583 5.70875 27.6243 3.75 25.208 3.75H14.7914C12.3751 3.75 10.4164 5.70875 10.4164 8.125V32.2917C10.4164 34.7079 12.3751 36.6667 14.7914 36.6667H25.208C27.6243 36.6667 29.583 34.7079 29.583 32.2917V27.1589C28.8128 26.8296 27.8842 26.9785 27.2571 27.6056C27.2009 27.6618 27.1428 27.7148 27.083 27.7648V32.2917C27.083 33.3272 26.2436 34.1667 25.208 34.1667H14.7914C13.7558 34.1667 12.9164 33.3272 12.9164 32.2917V8.125Z" fill="#FF6F00"/>
            <path d="M24.7431 25.0913C24.3745 25.46 24.3745 26.0584 24.7431 26.427C25.1117 26.7956 25.7101 26.7956 26.0787 26.427C27.552 24.9538 29.9481 24.9538 31.4213 26.427C31.79 26.7956 32.3883 26.7956 32.757 26.427C33.1256 26.0584 33.1256 25.46 32.757 25.0913C30.5478 22.8822 26.9523 22.8822 24.7431 25.0913Z" fill="#FF6F00"/>
            <path d="M22.0718 22.4201C21.7032 22.7887 21.7032 23.3871 22.0718 23.7557C22.4404 24.1243 23.0388 24.1243 23.4075 23.7557C26.3539 20.8093 31.1462 20.8093 34.0926 23.7557C34.4613 24.1243 35.0596 24.1243 35.4283 23.7557C35.7969 23.3871 35.7969 22.7887 35.4283 22.4201C31.7459 18.7377 25.7542 18.7377 22.0718 22.4201Z" fill="#FF6F00"/>
            <path d="M19.4005 19.7488C19.0319 20.1174 19.0319 20.7158 19.4005 21.0844C19.7692 21.453 20.3675 21.453 20.7362 21.0844C25.1558 16.6648 32.3443 16.6648 36.7639 21.0844C37.1326 21.453 37.7309 21.453 38.0996 21.0844C38.4682 20.7158 38.4682 20.1174 38.0996 19.7488C32.944 14.5932 24.5548 14.5945 19.4005 19.7488Z" fill="#FF6F00"/>
        </svg>
        </>
    )
})

const Tvkabel_base =memo(({store, ...props}) => {
    const appAction = useRef(null);
    
    return (
        <React.Fragment>
            <AppAction ref={appAction} />
            <BasePagePay
                options = {
                    [
                        {
                            display : "TVKabel",
                            value : 1,
                            forms : [{
                                type:"select",
                                name: "TVKabel",
                                label:"Penyedia Layanan",
                                value:store.formData.providerId,
                                onChange:(val)=> appAction.current.setForm("providerId", val),
                                displayKey:"name",
                                valueKey:"id",
                                required:true,
                                readOnly:false,
                                options:[
                                    {
                                        name : "MNC Play",
                                        id : 1,
                                    },
                                    {
                                        name : "Big TV",
                                        id : 2,
                                    },
                                    {
                                        name : "K-Vision",
                                        id : 3,
                                    },
                                    {
                                        name : "Transvison",
                                        id : 4,
                                    }]
                            },{
                                type  : "text",
                                label : "No Pembayaran",
                                name: "TVKabel",
                                value :  store.formData.pelanggan,
                                required: true,
                                onChange: (val) => appAction.current.setForm("no_pembayaran", val),
                                readOnly: false,
                                onEnter:() => appAction.current.setOpenPembayaran(true)
                            }],
                            selectNominal: false,
                            onPay: () => appAction.current.pay(),
                        }
                    ]
                }
                title ={<span className="px-0"><Icon /> TV Kabel</span>}
                nominals={nom}
                paymentCardData={{
                    detilBody: [
                        {
                            key: "Jenis Layanan",
                            value: "TRF BANK"
                        },
                        {
                            key: "Nomor Pelanggan",
                            value: "089123123"
                        },
                        {
                            key: "Nama Pelanggan",
                            value: "Isnan Nazzarun"
                        },
                    ],
                    detilFooter: [
                        {
                            key: "Harga",
                            value: 120000
                        },
                        {
                            key: "Admin",
                            value: 2000
                        }
                    ],
                    total: 122000
                }}
            />
        </React.Fragment>
    );
})

const Tvkabel = AppInterfaces.appLoad(Tvkabel_base)

export default Tvkabel;