import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import useRekening from '../useRekening';
import useDataBuku from '../../data-buku-tabungan/useDataBuku';
import ReactToPrint from 'react-to-print';

const PrintNama = () => {
    const { nfState, nfAction } = useDataBuku();
    const { formData = {}, formEditable = false, dataCetakNamaPrint={} } = nfState;
    const refCetak =  useRef(null);
    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={nfState.modalCetakNamaPrint} onHide={nfAction.closeCetakNamaPrint} >
                <Modal.Header closeButton>
                    <Modal.Title>Cetak Nama Pasbook</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <>
                    <div className="row" ref={refCetak}>
                        <div className="col">
                            <h4>Cabang : <>{nfState.dataCetakNamaPrint?.nama_cabang}</></h4>
                                
                            <div className="row">
                                <div className="col-4">
                                    Nomer Rekening 
                                </div>

                                <div className="col-4">
                                    Nama Rekening 
                                </div>

                                <div className="col-4">
                                    Alamat 
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-4">
                                    <div className="row">
                                        <div className="col-12"> {dataCetakNamaPrint?.nomor_rekening} </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="row">
                                        <div className="col-12"> {dataCetakNamaPrint?.nama_rekening} </div> 
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="row">
                                        <div className="col-12"> {dataCetakNamaPrint?.alamat} </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row mt-4">
                                <div className="col-12">
                                    <p>Terbilang</p>
                                </div>
                                <div className="col-12">
                                    <p>Seratus Juta Rupiah</p>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </>
                </Modal.Body>
                <Modal.Footer>
                <div className="row">
                    <div className="col">
                        {/* <button
                            onClick={() => nfAction.closeCetakNamaPrint()}
                            className="btn btn-outline-danger float-right">
                            Batal
                        </button> */}

                        <ReactToPrint
                            trigger={() => <button
                                // onClick={() => nfAction.closeCetakBilyet()}
                                className="btn btn-success float-right ml-3">
                                Cetak
                            </button>}
                            content={() => refCetak.current}
                        />
                    </div>
                </div>
                </Modal.Footer>
            </Modal>
            {/* end modal utama */}
        </>
    );
}

export default PrintNama;