import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { NewLoading, TableList } from '../../../controls';
import ModalConfirmCustom from '../../../controls/modalAlert/modalConfirmCustomChild';
import useModalDetilRev, { ModalDetilRevProv } from '../reversal_transaksi/component/detailReversal';
import useReversal, { RevProvider } from './useReversal';

const dummy = [
    {
        'nomor_rekening' : '09181',
        'tgl_transaksi' : 'tgl_transaksi',
        'jam_input' : 'jam_input',
        'user' : 'user',
        'keterangan' : 'keterangan',
        'nomor_referensi' : 'nomor_referensi'
    }
];
function TrxHistori() {
    const { revState, revAction } = useReversal()
    const { mtAction, mtState } = useModalDetilRev();

    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Reversal Transaksi</a>
                    </li>
                </ol>
            </nav>

           
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                REVERSE TRANSAKSI 
                            </div>
                        </div>
                        <div className="title">
                        <div className="mb-2 mt-2 ml-2">
                            <div className="row">
                                {/* <div className="col-4 align-self-center bns-form">
                                    <div className="form-group">
                                    <label>Tanggal Transaksi</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            placeholder="Tanggal Transaksi"
                                            value={revState.filter.tgl_awal}
                                            onChange={(value) => {
                                                revAction.setFilter(prevState => ({ ...prevState, tgl_awal: value.target.value }));
                                            }}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="col-4 align-self-center bns-form">
                                    <div className="form-group">
                                    <label>Tanggal Akhir</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            placeholder="Tanggal Akhir"
                                            value={revState.filter.tgl_akhir}
                                            onChange={(value) => {
                                                revAction.setFilter(prevState => ({ ...prevState, tgl_akhir: value.target.value }));
                                            }}
                                            
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="col-4 align-self-center bns-form">
                                    <div className="form-group pt-4 mt-1">
                                        <button onClick={revAction.getDataList} className="btn btn-outline-success">
                                            Cari
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                minHeight="450px"
                                maxHeight="500px"
                                onChangeShow={revAction.changeShow}
                                onNextPage={revAction.nextPage}
                                onPrevPage={revAction.prevPage}
                                withActions={true}
                                actionItems={[
                                    {
                                        name:"lihat Detail",
                                        onRender: (item) =>
                                        item.id_transaksi_core !== null 
                                        ,
                                        onClick: (item) => {
                                            mtAction.openModal(parseInt(item.id_transaksi_core))
                                            mtAction.setDataReserve({id_transaksi: parseInt(item.id_transaksi), kode_transaksi: item.kode_transaksi})
                                        },
                                    },
                                    {
                                        name: 'Selesaikan Transaksi BDS',
                                        onRender: (item) => item.sts_validasi_trx !== `True`,
                                        onClick: (item) => {
                                            revAction.responseFinishTrx(parseInt(item.id_transaksi))
                                        },
                                    },
                                ]}
                                columns={[
                                    {
                                        name: 'Jam Input',
                                        field: 'jam_input',
                                    },
                                    {
                                        name: 'Kode Transaksi',
                                        field: 'kode_transaksi',
                                    },
                                    {
                                        name: 'Jenis Transaksi',
                                        field: 'jenis_transaksi',  
                                        className:"text-right",
                                    },
                                    {
                                        name: 'Referensi',
                                        field: 'nomor_referensi',
                                    },
                                    {
                                        name: 'Status Proses Transaksi BDS',
                                        field: 'sts_validasi_trx',  
                                        // className:"text-right",
                                    },
                                    {
                                        name: 'Status Otorisasi',
                                        field: 'status_otorisasi',
                                    },
                                    {
                                        name: 'User',
                                        field: 'user_input',
                                    },  
                                    {
                                        name: 'User Otor',
                                        field: 'user_otor',
                                    },
                                    {
                                        name: 'Keterangan',
                                        field: 'keterangan',
                                    },
                                ]}
                                data={revState.dataList}
                                isLoading={revState.isLoading}
                                filter={false}
                                resetFilter={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => revAction.setOtorDesc(e.target.value)}
                    value={revState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )
}
export default () => <ModalDetilRevProv><RevProvider><TrxHistori /></RevProvider></ModalDetilRevProv>;