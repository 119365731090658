import { filter } from 'lodash-es';
import React, { useContext, useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { useHistory } from 'react-router';
import useFetch from '../../../hooks/useFetch';
import { useModalAlert } from '../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../hooks/keycloak.module';


const dashContext = React.createContext(null);
export const DashProvider = ({ children }) => {

    const { doGet, doPost, wait } = useFetch();
    const history = useHistory();
    const [dashshow, setDashShow] = useState(1);
    const { modalState, modalAction } = useModalAlert();

    const [loadingList, setLoadingList] = useState(true);
    const [loadingCard, setLoadingCard] = useState(true);
    const [dataCard, setDataCard] = useState({})
    const [cardSelected, setCardSelected] = useState({
        count: 0,
        title: ""
    });
    const [loadingChartcs, setLoadingChartcs] = useState(true)
    const [dataChartCif, setDataChartCif] = useState();
    const [loadingTasedo, setLoadingTasedo] = useState(false)
    const [dataTasedo, setDataTasedo] = useState({
        count: 0,
        list : [],
        title:""
    })
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;


    function TableShow(e, icon) {
        // console.log(e, icon)
        setDashShow(e)
        setCardSelected(p => ({
            ...p,
            icon
        }))
        // getDataList();
    }

    const [dataList, setDataList] = useState([]);
    const { keycloak } = useKeycloak();

    async function getChartCif() {
        setLoadingChartcs(true)
        let url = "/dsb/cs/grouping";
        if (cekRoles(["spv cs", "cssupervisor"])) {
            url = "/dsb/csspv/grouping";
        }
        try {
            const resp = await doGet({
                // data: bodyData,
                url,
                service: "rpc",
            });
            const { statusCode, status, statusText, data, remark, message_id, } = resp;
            // // console.log(resp)
            if (statusCode === 200) {
                if (message_id === "00") setDataChartCif(data);
                else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
            } else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        setLoadingChartcs(false)
    }
    async function getDataCard() {
        setLoadingCard(true)
        let url = "/dsb/count-userinput";
        if (cekRoles(["spv cs", "cssupervisor"])) {
            url = "/dsb/count";
        }
        try {
            const resp = await doGet({
                // data: bodyData,
                url,
                service: "rpc",
                param: {
                    kode_cabang: keycloak?.tokenParsed?.branch_code
                }
            });
            const { statusCode, status, statusText, data, remark, message_id, } = resp;

            if (statusCode === 200) {
                if (message_id === "00") setDataCard(data);
                else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
            } else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        setLoadingCard(false)
    }

    async function getTasedo() {
        setLoadingTasedo(true)

        try {

            const resp = await doGet({
                url: "/tasedo/akan-jatuh-tempo/detail",
                service: "rpc",
            })
            if (resp.statusCode === 200)
                if (resp.message_id === "00") {
                    setDataTasedo(resp.data)
                }
                else setDataAlert({ show: true, body: resp.remark, title: ``, header: "Info", callBack: () => null });
            else setDataAlert({ show: true, body: resp.remark, title: ``, header: "Info", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: `Get Tasedo dashboard`, header: "Error", callBack: () => null });
        }
        await wait(2000)
        setLoadingTasedo(false)
    }

    function cekRoles(roles) {
        return roles.some((role) => keycloak.hasRealmRole(role))
    }
    async function getDataList() {
        // setLoading()
        setDataList([])
        setLoadingList(true)
        setCardSelected({ count: 0, title: "" })
        let url = null;
        let service = null;
        let param = {}
        if (dashshow === 1) {
            url = `/dsb/nasabahbaru-userinput`;
            if (cekRoles(["spv cs", "cssupervisor"])) {
                url = `/dsb/nasabahbaru`;
            }
            service = "rpc";
            param = {
                kode_cabang: keycloak?.tokenParsed?.branch_code
            }
        } else if (dashshow === 2) {
            // url = "/2"
            // service = "trx"
        } else if (dashshow === 3) {
            url = "/dsb/wicbaru-userinput";
            if (cekRoles(["spv cs", "cssupervisor"])) {
                url = `/dsb/wicbaru`;
            }
            service = "rpc";
            param = {
                kode_cabang: keycloak?.tokenParsed?.branch_code
            }
        } else if (dashshow === 4) {
            // url = "/4"
            // service = "trx"
        } else if (dashshow === 5) {
            url = "/dsb/rekeningbaru-userinput";
            if (cekRoles(["spv cs", "cssupervisor"])) {
                url = `/dsb/rekeningbaru`;
            }
            service = "rpc";
            param = {
                kode_cabang: keycloak?.tokenParsed?.branch_code
            }
        } else if (dashshow === 6) {
            // url = "/6"
            // service = "trx"
        }
        if (url !== null) {
            try {
                const resp = await doGet({
                    // data: bodyData,
                    url,
                    service,
                    param
                });
                const { statusCode, status, statusText, data, remark, message_id, } = resp;

                if (statusCode === 200) {
                    if (message_id === "00") {
                        setCardSelected(p => ({
                            ...p,
                            count: data.count,
                            title: data.title
                        }))
                        setDataList(data.list)
                    }
                    else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
                } else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
            }
        }
        setLoadingList(false)
    }


    const [dataListTrx, setDataListTrx] = useState([])
    const [dataCountTrx, setDataCountTrx] = useState({

    })
    const [trxShow, setTrxShow] = useState(1);
    const [loadingCountTrx, setLoadingCountTrx] = useState(true);
    const [loadingListTrx, setLoadingListTrx] = useState(true)
    const [cardTrxSelected, setCardTrxSelected] = useState({
        count: 0, title: ""
    })


    const [loadingChartTrx, setLoadingChartTrx] = useState(true)
    const [dataChartTrx, setDataChartTrx] = useState()

    async function getDataChartTrx() {
        setLoadingChartTrx(true)
        let url = "/dsb/tlr/grouping";
        if (cekRoles(["spv teller", "tellersupervisor"])) {
            url = "/dsb/tlrspv/grouping";
        }
        try {
            const resp = await doGet({
                // data: bodyData,
                url,
                service: "rpc",
            });
            const { statusCode, status, statusText, data, remark, message_id, } = resp;
            // // console.log(resp)
            if (statusCode === 200) {
                if (message_id === "00") setDataChartTrx(data);
                else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
            } else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        setLoadingChartTrx(false)
    }

    function changeCardTrx(x) {
        setTrxShow(x)
    }
    async function getDataDashboardtrx() {
        // setLoading()
        setDataListTrx([])
        setLoadingListTrx(true)
        setCardTrxSelected({ count: 0, title: "" })
        let url = null;
        let service = null;
        let param = {}
        if (trxShow === 1) {
            url = `/dsb/tlr/trx-hari-ini`;
            if (cekRoles(["spv teller", "tellersupervisor"])) {
                url = `/dsb/tlrspv/trx-hari-ini`;
            }
            service = "rpc";
        } else if (trxShow === 2) {
            url = `/dsb/tlr/trx-al-ui`;
            if (cekRoles(["spv teller", "tellersupervisor"])) {
                url = `/dsb/tlrspv/trx-al`;
            }
            service = "rpc";
        } else if (trxShow === 3) {
            url = `/dsb/tlr/trx-bl-ui`;
            if (cekRoles(["spv teller", "tellersupervisor"])) {
                url = `/dsb/tlrspv/trx-bl`;
            }
            service = "rpc";
        } else if (trxShow === 4) {
            url = `/dsb/tlr/trx-rv-ui`;
            if (cekRoles(["spv teller", "tellersupervisor"])) {
                url = `/dsb/tlrspv/trx-rv`;
            }
            service = "rpc";
        }

        function getTitle(n) {
            switch (n) {
                case 1:
                    return "Jumlah Transaksi";
                case 2:
                    return "Transaksi Atas Limit";
                case 3:
                    return "Transaksi Bawah Limit";
                case 4:
                    return "Transaksi Reversal";
                default:
                    return "";
            }
        }
        if (url !== null) {
            try {
                const resp = await doGet({
                    // data: bodyData,
                    url,
                    service,
                    param
                });
                const { statusCode, status, statusText, data, remark, message_id, } = resp;

                if (statusCode === 200) {
                    if (message_id === "00") {
                        setCardTrxSelected(p => ({
                            ...p,
                            count: data.count,
                            title: getTitle(trxShow)
                        }))
                        setDataListTrx(data.list)
                    }
                    else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
                } else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
            }
        }
        setLoadingListTrx(false)
    }

    async function getCountTrx() {
        setLoadingCountTrx(true)
        let url = "/dsb/tlr/count";
        if (cekRoles(["spv teller", "tellersupervisor"])) {
            url = "/dsb/tlrspv/count";
        }
        try {
            const resp = await doGet({
                // data: bodyData,
                url,
                service: "rpc",
            });
            const { statusCode, status, statusText, data, remark, message_id, } = resp;
            if (statusCode === 200) {
                if (message_id === "00") setDataCountTrx(data);
                else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
            } else setDataAlert({ show: true, body: status + ". " + remark, title: ``, header: "Info", callBack: () => closeAlert() })
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        setLoadingCountTrx(false)
    }

    //Post Auditrail
    async function auditTrail() {
        const userInfo = await keycloak.loadUserInfo();
        // // console.log(userInfo)
        var url = `/useraudit/user-audit-trail`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: {
                    user_id: userInfo.preferred_username,
                    app_id: "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Get Data List",
                    event_description: "Dashboard",
                    info1: "-",
                    info2: "-"
                },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

    }

    return <dashContext.Provider value={{
        dbAction: {
            setDashShow,
            TableShow,
            cekRoles,
            getCountTrx,
            getDataCard,
            getDataList,
            getDataDashboardtrx,
            changeCardTrx,
            getChartCif,
            getDataChartTrx,
            auditTrail,
            getTasedo
        },
        dbState: {
            dashshow,
            dataList,
            loadingCard,
            loadingList,
            dataCard,
            cardSelected,
            dataCountTrx,
            dataListTrx,
            loadingCountTrx,
            loadingListTrx,
            trxShow,
            cardTrxSelected,
            dataChartCif,
            loadingChartcs,
            dataChartTrx,
            loadingChartTrx,
            dataTasedo,
            loadingTasedo

        }
    }}>{children}</dashContext.Provider>
}
export default function useDash() {
    return useContext(dashContext)
};