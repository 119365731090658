import { Modal, FormControl } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { ActionMore, TableGrid } from '../../../controls';
import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../../../app.config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import clsx from 'clsx';
import ModalInfoNegatifNasabah from './info-negatif/info_negatif_modal';
import { joinDefinedString } from '../../../../utils';

const SupNasabahPage = () => {
    let history = useHistory();

    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [idNasabah, setIdNasabah] = useState(0);
    const [rejectedModal, setRejectedModal] = useState(false);
    const [approvedModal, setApprovedModal] = useState(false);
    const [infoNegatifModal, setInfoNegatifModal] = useState(false);
    const [tipeInfoNegatifModal, setTipeInfoNegatifModal] = useState('peragaan');
    const [detail, setDetail] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [name, setName] = useState('');
    const [stateStatus, setStateStatus] = useState('PENDING_APPROVAL');
    const [cif, setCif] = useState('');
    const [jenisNasabah, setJenisNasabah] = useState('GROUP');
    const [status, setStatus] = useState('');
    const [data, setData] = useState([]);
    const [groupId, setGroupId] = useState('');
    const [negatifStatus, setNegatifStatus] = useState('');
    const [negatifKeterangan, setNegatifKeterangan] = useState('');
    const [idNegatif, setIdNegatif] = useState('');
    const [f, reFetch] = useState(false);

    useEffect(() => {
        if (stateStatus === 'PENDING_APPROVAL_EDITED_SVS') {
            fetch(
                baseUrl +
                    `/nasabah/svs/list-pending-approval-edited?limit=${limit}&page=${currentPage}`
            )
                .then((response) => response.json())
                .then((data) => setData(data?.list));
        } else if (stateStatus === 'PENDING_APPROVAL_INFO_NEGATIF') {
            fetch(
                baseUrl +
                    `/nasabah/informasi/negatif/status/all?status=PENDING&limit=${limit}&page=${currentPage}`
            )
                .then((response) => response.json())
                .then((data) => setData(data?.list));
        } else {
            fetch(
                baseUrl +
                    `/nasabah/authorization?limit=${limit}&page=${currentPage}&type=${jenisNasabah}&state=${stateStatus}`
            )
                .then((response) => response.json())
                .then((data) => setData(data?.list));
        }
    }, [currentPage, limit, status, jenisNasabah, stateStatus]);

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    const handleSearch = () => {
        const body = {
            filterCriteria: {
                field: 'nama',
                value: search,
                operator: 'LIKES',
            },
        };

        return fetch(
            baseUrl +
                `/nasabah/search?limit=${limit}&page=${currentPage}&type=${jenisNasabah}&state=${stateStatus}`,
            {
                method: 'POST',
                body: JSON.stringify(body),
            }
        )
            .then((response) => response.json())
            .then((data) => setData(data?.list));
    };

    const rejectAction = () => {
        setRejectedModal(false);
        if (stateStatus === 'PENDING_APPROVAL_EDITED' && jenisNasabah === 'GROUP') {
            return fetch(
                baseUrl + `/nasabah-badan-usaha/otorisasi-after-edit/${idNasabah}?status=REJECTED`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id == '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else if (stateStatus === 'PENDING_APPROVAL_EDITED' && jenisNasabah === 'CLIENT') {
            return fetch(
                baseUrl + `/nasabah/perorangan/otorisasi-after-edit/${idNasabah}?status=REJECTED`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id === '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else if (stateStatus === 'PENDING_APPROVAL_EDITED_SVS') {
            return fetch(
                baseUrl + `/nasabah/svs/after-otorisasi-approved/${cif}?status=REJECTED&type=GROUP`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id === '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else if (stateStatus === 'PENDING_APPROVAL_INFO_NEGATIF') {
            const body = {
                negatif_status: negatifStatus,
                negatif_keterangan: negatifKeterangan,
            };
            return fetch(baseUrl + `/nasabah/informasi/negatif/approved/${groupId}?status=REJECT`, {
                method: 'POST',
                body: JSON.stringify(body),
            })
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id === '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else {
            return fetch(baseUrl + `/nasabah-badan-usaha/otorisasi/${idNasabah}?status=REJECTED`, {
                method: 'PATCH',
            })
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id === '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        }
    };

    const approvedAction = () => {
        setApprovedModal(false);
        if (stateStatus === 'PENDING_APPROVAL_EDITED' && jenisNasabah === 'GROUP') {
            return fetch(
                baseUrl + `/nasabah-badan-usaha/otorisasi-after-edit/${idNasabah}?status=APPROVED`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id === '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else if (stateStatus === 'PENDING_APPROVAL_EDITED' && jenisNasabah === 'CLIENT') {
            return fetch(
                baseUrl + `/nasabah/perorangan/otorisasi-after-edit/${idNasabah}?status=APPROVED`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id === '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else if (stateStatus === 'PENDING_APPROVAL_EDITED_SVS') {
            return fetch(
                baseUrl + `/nasabah/svs/after-otorisasi-approved/${cif}?status=APPROVED&type=GROUP`,
                {
                    method: 'PATCH',
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id === '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else if (stateStatus === 'PENDING_APPROVAL_INFO_NEGATIF') {
            const body = {
                negatif_status: negatifStatus,
                negatif_keterangan: negatifKeterangan,
            };
            return fetch(
                baseUrl + `/nasabah/informasi/negatif/approved/${groupId}?status=APPROVE`,
                {
                    method: 'POST',
                    body: JSON.stringify(body),
                }
            )
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id === '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        } else {
            return fetch(baseUrl + `/nasabah-badan-usaha/otorisasi/${idNasabah}?status=APPROVED`, {
                method: 'PATCH',
            })
                .then((response) => response.json())
                .then((status) => {
                    if (status.message_id === '00') {
                        toast.success(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    } else if (status.message_id === '01') {
                        toast.error(status.status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(status.list.message, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: true,
                            autoClose: 3000,
                        });
                        setStatus(status);
                    }
                });
        }
    };

    // console.log(detail, 'detail');

    return (
        <>
            <ModalInfoNegatifNasabah
                onHide={() => {
                    setInfoNegatifModal(false);
                }}
                show={infoNegatifModal}
                type={tipeInfoNegatifModal}
                form={detail}
            />
            <Modal centered show={Boolean(approvedModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setApprovedModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Setujui Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <p>
                        Yakin menyetujui otorisasi nasabah <b>{name}</b> ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setApprovedModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={approvedAction}
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(rejectedModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setRejectedModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tolak Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <p>
                        Yakin ingin menolak otorisasi nasabah <b>{name}</b> ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setRejectedModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={rejectAction}
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Nasabah / CIF
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Data Nasabah / CIF
                </div>
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                onChange={(e) => setJenisNasabah(e.target.value)}
                            >
                                <option disabled selected={!jenisNasabah}>
                                    Pilih Jenis Nasabah
                                </option>
                                {stateStatus === 'PENDING_APPROVAL_EDITED_SVS' ? (
                                    <option selected value="GROUP">
                                        Badan Usaha
                                    </option>
                                ) : (
                                    <>
                                        <option value="CLIENT">Perorangan</option>
                                        <option selected value="GROUP">
                                            Badan Usaha
                                        </option>
                                    </>
                                )}
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                onChange={(e) => setStateStatus(e.target.value)}
                            >
                                <option disabled selected={!stateStatus}>
                                    Pilih Status
                                </option>
                                <option selected value="PENDING_APPROVAL">
                                    Menunggu Otorisasi
                                </option>
                                <option value="PENDING_APPROVAL_EDITED">
                                    Menunggu Otorisasi (Edited)
                                </option>
                                <option value="PENDING_APPROVAL_EDITED_SVS">
                                    Menunggu Otorisasi (Edited) SVS
                                </option>
                                <option value="PENDING_APPROVAL_INFO_NEGATIF">
                                    Menunggu Otorisasi (Edited) Info Negatif
                                </option>
                            </FormControl>
                            <form onSubmit={handleSearch} className="tw-w-full mr-4" id="search">
                                <FormControl
                                    id="search"
                                    className="mr-4"
                                    size="sm"
                                    placeholder="Cari no.rekening, nama..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </form>
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                                onClick={() => {
                                    setSearch('');
                                    setJenisNasabah('');
                                    setStateStatus('');
                                    // maybe better using ref, but well you can change it to ref if you want
                                    document.getElementById('search').reset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                // form="searchForm"
                                type="submit"
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                                onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </div>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Nomor Nasabah',
                            field: 'id',
                            width: '100px',
                        },
                        {
                            name: 'Nama Lengkap',
                            field: 'name',
                            width: 'minmax(100px, 0.6fr)',
                        },
                        {
                            name: 'Alamat',
                            field: 'address',
                            width: 'minmax(200px, 1fr)',
                        },
                        {
                            name: 'Jenis Nasabah',
                            field: 'jenis_nasabah',
                            width: '100px',
                        },
                        {
                            name: 'Jenis Identitas',
                            field: 'jenis_identitas',
                            width: '90px',
                        },
                        {
                            name: 'Nomor Identitas',
                            field: 'nomor_identitas',
                            width: '110px',
                        },
                        {
                            name: 'Profile Resiko',
                            field: 'profile_resiko',
                            width: '100px',
                        },
                        {
                            name: '',
                            field: 'tolak_setujui',
                            width: '200px',
                        },
                        {
                            field: 'action',
                            width: '80px',
                        },
                    ]}
                    data={data || []}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'status') {
                            return (value =
                                data.state === 'PENDING_APPROVAL'
                                    ? 'Menunggu Otorisasi'
                                    : data.state === 'PENDING_APPROVAL_EDITED'
                                    ? 'Menunggu Otorisasi (Edited)'
                                    : data.state === 'APPROVED'
                                    ? 'Aktif'
                                    : data.state);
                        }

                        if (field === 'address') {
                            if (jenisNasabah === 'CLIENT') {
                                return (value = data?._ktpAddress?.ktpAddress);
                            } else if (jenisNasabah === 'GROUP') {
                                return (value = data?._identitasNasabah?.nasabahAlamat);
                            } else if (stateStatus === 'PENDING_APPROVAL_EDITED_SVS') {
                                return (value = data?.alamat);
                            }
                        }
                        if (field === 'name') {
                            if (
                                jenisNasabah === 'CLIENT' &&
                                stateStatus !== 'PENDING_APPROVAL_EDITED_SVS' &&
                                stateStatus !== 'PENDING_APPROVAL_INFO_NEGATIF'
                            ) {
                                const { firstName, middleName, lastName } = data;
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {
                                            (value = joinDefinedString(
                                                firstName,
                                                lastName,
                                                middleName
                                            ).value())
                                        }
                                    </div>
                                );
                            } else if (
                                jenisNasabah === 'GROUP' &&
                                stateStatus !== 'PENDING_APPROVAL_EDITED_SVS' &&
                                stateStatus !== 'PENDING_APPROVAL_INFO_NEGATIF'
                            ) {
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {(value = data?.groupName)}
                                    </div>
                                );
                            } else if (
                                stateStatus === 'PENDING_APPROVAL_EDITED_SVS' ||
                                stateStatus === 'PENDING_APPROVAL_INFO_NEGATIF'
                            ) {
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {(value = data?.name)}
                                    </div>
                                );
                            }
                        }

                        if (field === 'jenis_nasabah') {
                            if (jenisNasabah === 'CLIENT') {
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {(value = 'Perorangan')}
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {(value = 'Badan Usaha')}
                                    </div>
                                );
                            }
                        }

                        if (field === 'jenis_identitas') {
                            if (jenisNasabah === 'CLIENT') {
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {(value = data?._personalIdData?.personalIdType)}
                                    </div>
                                );
                            } else if (jenisNasabah === 'GROUP') {
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {(value = 'NPWP')}
                                    </div>
                                );
                            }
                        }

                        if (field === 'nomor_identitas') {
                            if (jenisNasabah === 'CLIENT') {
                                return (value = data?._personalIdData?.personalIdNumber);
                            } else if (jenisNasabah === 'GROUP') {
                                return (value = data?._identitasNasabah?.nasabahNomorNpwp);
                            }
                        }

                        if (field === 'profile_resiko') {
                            if (
                                jenisNasabah === 'CLIENT' &&
                                stateStatus !== 'PENDING_APPROVAL_EDITED_SVS' &&
                                stateStatus !== 'PENDING_APPROVAL_INFO_NEGATIF'
                            ) {
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {(value = '-')}
                                    </div>
                                );
                            } else if (
                                jenisNasabah === 'GROUP' &&
                                stateStatus !== 'PENDING_APPROVAL_EDITED_SVS' &&
                                stateStatus !== 'PENDING_APPROVAL_INFO_NEGATIF'
                            ) {
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {(value = data?._cdd?.cddStatusResiko)}
                                    </div>
                                );
                            } else if (
                                stateStatus === 'PENDING_APPROVAL_EDITED_SVS' &&
                                jenisNasabah === 'GROUP' &&
                                jenisNasabah === 'CLIENT'
                            ) {
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        {(value = data?.profile_resiko)}
                                    </div>
                                );
                            }
                        }

                        if (field === 'action')
                            return (
                                <ActionMore
                                    actions={[
                                        {
                                            label: 'Lihat Detail Nasabah',
                                            color: 'complimentary',

                                            onClick: () => {
                                                history.push({
                                                    pathname:
                                                        jenisNasabah === 'CLIENT'
                                                            ? '/sup-nasabah-cif/detail-perorangan'
                                                            : '/sup-nasabah-cif/detail-nasabah',
                                                    state: {
                                                        data: data,
                                                    },
                                                });
                                            },
                                            hidden:
                                                stateStatus === 'PENDING_APPROVAL_EDITED_SVS' ||
                                                stateStatus === 'PENDING_APPROVAL_INFO_NEGATIF',
                                        },
                                        {
                                            label: 'Peragaan Info Negatif',
                                            onClick: () => {
                                                setInfoNegatifModal(true);
                                                setTipeInfoNegatifModal('peragaan');
                                                setDetail(data);
                                                setIdNegatif(data.id);
                                            },
                                            hidden:
                                                data._negatifNasabah === null ||
                                                stateStatus === 'PENDING_APPROVAL_EDITED_SVS',
                                        },
                                        {
                                            label: 'Signature Verification System',
                                            onClick: () => {
                                                history.push({
                                                    pathname:
                                                        stateStatus ===
                                                        'PENDING_APPROVAL_EDITED_SVS'
                                                            ? '/sup-nasabah-cif/svs-detail'
                                                            : '/sup-nasabah-cif/svs',
                                                    state: {
                                                        db_id: data?.db_id,
                                                        data:
                                                            stateStatus ===
                                                            'PENDING_APPROVAL_EDITED_SVS'
                                                                ? data?._svs
                                                                : '',
                                                        detail: data,
                                                    },
                                                });
                                            },
                                            hidden: stateStatus === 'PENDING_APPROVAL_INFO_NEGATIF',
                                        },
                                    ]}
                                />
                            );

                        if (field === 'tolak_setujui') {
                            return (
                                <div
                                    key={`${value}-${field}`}
                                    className={clsx(
                                        className,
                                        'tw-grid tw-gap-2 tw-auto-cols-max tw-grid-flow-col'
                                    )}
                                >
                                    <Button
                                        className="tw-text-12 tw-py-2 tw-justify-center"
                                        size="mini"
                                        basic
                                        color="red"
                                        onClick={() => {
                                            setRejectedModal(true);
                                            setIdNasabah(data.db_id);
                                            {
                                                stateStatus === 'PENDING_APPROVAL_EDITED_SVS' &&
                                                    setCif(data?.nomor_cif);
                                            }
                                            {
                                                stateStatus === 'PENDING_APPROVAL_INFO_NEGATIF' &&
                                                    setGroupId(data?.nomor_cif);
                                                setNegatifStatus(data?.status_data);
                                                setNegatifKeterangan(data?.keterangan);
                                                setName(data.nama_lengkap);
                                            }
                                            setName(
                                                jenisNasabah === 'CLIENT'
                                                    ? data.firstName +
                                                          ' ' +
                                                          data.middleName +
                                                          ' ' +
                                                          data.lastName
                                                    : data.groupName
                                            );
                                        }}
                                    >
                                        Tolak
                                    </Button>
                                    <Button
                                        className="tw-text-12 tw-py-2 tw-justify-center"
                                        size="mini"
                                        basic
                                        color="green"
                                        onClick={() => {
                                            setApprovedModal(true);
                                            setIdNasabah(data.db_id);
                                            {
                                                stateStatus === 'PENDING_APPROVAL_EDITED_SVS' &&
                                                    setCif(data?.nomor_cif);
                                            }
                                            {
                                                stateStatus === 'PENDING_APPROVAL_INFO_NEGATIF' &&
                                                    setGroupId(data?.nomor_cif);
                                                setNegatifStatus(data?.status_data);
                                                setNegatifKeterangan(data?.keterangan);
                                                setName(data?.nama_lengkap);
                                            }
                                            setName(
                                                jenisNasabah === 'CLIENT'
                                                    ? data.firstName +
                                                          ' ' +
                                                          data.middleName +
                                                          ' ' +
                                                          data.lastName
                                                    : data.groupName
                                            );
                                        }}
                                    >
                                        Setujui
                                    </Button>
                                </div>
                            );
                        }

                        if (field === 'name')
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {value === 'firstName'}
                                </div>
                            );
                    }}
                    config={{
                        height: '60vh',
                        overflowY: 'auto',
                        stickyHead: true,
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
            </div>
        </>
    );
};

export default SupNasabahPage;
