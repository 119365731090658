import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { listPelanggan } from '../../../appConfig';


class RitelLoketPage extends React.PureComponent {
    render() {
        return (<>
            <div className="row px-3 w-100 align-content-center card-dashboard">
                <div className="col">
                    <h4 className="text-gray-1 px-3">
                        <Link to="/" className="border-0">
                            <i className="fas fa-arrow-left"></i>
                        </Link>
                    &nbsp;LOKET KIRIMAN POS
                    </h4>
                </div>
            </div>
            <div className="row px-3 mx-0">
                <div className="col">
                    <LoketKirimanPos />
                </div>
            </div>
        </>);
    }
}

class LoketKirimanPos extends React.PureComponent{
    constructor(props){
        super();
        this.state = {
            // Alert
            wAlert: false,
            cAlert: false,
            tAlert: "",
            msgAlert: "",

            idPelanggan: "",
            produk: "",
            berat: "",
            pembagi: 6000,
            // volumetrik: 
            p: "", l: "", t: "", vol: ""
        }
    }

    // Search ID Pelanggan
    handleKeyDown = (type, e) => {
        if (e.key === 'Enter') { 
            let s = this.state;
            if(type === "idPelanggan"){
                if(s.idPelanggan === ""){
                    this.setState({...s, wAlert: true, msgAlert: `Silahkan masukkan Id Pelanggan`})
                }else{
                    let pelanggan = listPelanggan.find(d => d.id === s.idPelanggan);
                    if(pelanggan === undefined){
                        this.setState({...s, wAlert: true, msgAlert: `IDPEL yang Anda masukkan salah, mohon teliti kembali`})
                    }else{
                        this.setState({...s, statusTransaksi: "pembayaran", detailTransaksi: pelanggan})
                    }
                }
            }else if(type === "berat" && s.berat > 2000){
                this.setState({...s, wAlert: true, msgAlert: ` Berat melebihi 2000 gram harus mengisi Volume Metrik`})
            }

        }
    }

    // ALert
    handleCloseAlert = (e) => {
        if(e === "warning"){
            this.setState({...this.state, wAlert: false, msgAlert: ""})
        }else{
            this.setState({...this.state,  cAlert: false, msgAlert: ""})
        }
    }
    handleOnClick = () => {
        let s = this.state;
        if(s.tAlert === "yesBayar"){
            this.setState({...s, statusTransaksi: "berhasil", cAlert: false, msgAlert: ""})
        }else if(s.tAlert === "yesKeluar"){
            this.setState({...s, statusTransaksi: "", cAlert: false, msgAlert: ""})
        }
    }
    handleOnChange = (type, e) =>{
        let s = this.state;
        switch (type) {
            case "berat":this.setState({berat: e})                
                break;   
            case "p":
                if(s.l !== "" && s.t !== ""){
                    this.setState({...s, p: e, vol: (s.l * s.t * e)/s.pembagi })               
                }else{
                    this.setState({...s, p: e})               
                }
                break;  
            case "l":                 
                if(s.p !== "" && s.t !== ""){
                        this.setState({...s, l: e, vol: (s.p * s.t * e)/s.pembagi })               
                }else{
                    this.setState({...s, l: e})               
                }
                break;  
            case "t": 
                if(s.p !== "" && s.l !== ""){
                    this.setState({...s, t: e, vol: (s.p * s.l * e)/s.pembagi })               
                }else{
                    this.setState({...s, t: e})               
                }
                break; 
            case "produk": 
                if(e === "paket ln"){
                    let vol = (s.p !== "" && s.l !== "" && s.t !== "") ?  (s.p * s.l * s.t)/5000 : ""
                    this.setState({...s, produk: e, vol, pembagi: 5000 })
                }else{              
                    let vol = (s.p !== "" && s.l !== "" && s.t !== "") ?  (s.p * s.l * s.t)/6000 : ""
                    this.setState({produk: e, vol, pembagi: 6000})   
                }
                break; 
            default: 
                break;
        }
    }

    render(){
        let s = this.state;
        return(
            <div className="card card-body p-2">
                <AlertWarning show={s.wAlert} handleClose={()=>this.handleCloseAlert("warning")} msgAlert={s.msgAlert}/>
                    <AlertConfirm show={s.cAlert} handleClose={(e)=>this.handleCloseAlert(e)} 
                        handleYes={()=>this.handleOnClick()} msgAlert={s.msgAlert}/>
                    
                <div className="row p-0 m-0">
                    <div className="col-4 ">
                        <table className="table table-sm table-borderless">
                            <tr>
                                <td className="f-14 py-0">No pelanggan</td>
                                <td className="f-14 py-0">
                                    <input className="form-control form-control-sm"
                                        value={s.idPelanggan}
                                        onFocus={(e)=>e.target.select()} 
                                        onKeyDown={(e)=>this.handleKeyDown("idPelanggan", e)}
                                        onChange={(e) => this.setState({idPelanggan: e.target.value})} />
                                    </td>
                            </tr>
                            <tr>
                                <td className="f-14 py-0" style={{width: "27%"}}>No. Resi</td>
                                <td className="f-14 py-0">
                                    <div className="row align-items-center">
                                        <div className="col-5 pr-0">
                                            <input className="form-control form-control-sm px-0" value="40000TL00000023" />
                                        </div>
                                        <div className="col-2 px-1"><button className="">GEN</button></div>
                                        <div className="col-5 pl-0">
                                            <select className="form-control form-control-sm px-0 text-uppercase" id="exampleFormControlSelect1">
                                                <option value="0" className="text-uppercase">0 | Serbaguna</option>
                                                <option value="1" className="text-uppercase">1 | TUNAI</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="f-14 py-0">Tujuan</td>
                                <td className="f-14 py-0"><input className="form-control form-control-sm" /></td>
                            </tr>
                            <tr>
                                <td className="f-14 py-0">Berat Aktual</td>
                                <td className="f-14 py-0">
                                    <input className="form-control form-control-sm" 
                                        value={s.berat}
                                        onFocus={(e)=>e.target.select()} 
                                        onKeyDown={(e)=>this.handleKeyDown("berat", e)}
                                        onChange={(e) => this.handleOnChange("berat", e.target.value.replace(/[^0-9]/g, ''))}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="f-14 py-0">Jenis Kiriman</td>
                                <td className="f-14 py-0">
                                    <select className="form-control form-control-sm text-uppercase" id="exampleFormControlSelect1"
                                        onChange={(e) => this.handleOnChange("produk", e.target.value)}>
                                        <option className="text-uppercase" selected={s.produk === "" ? true : false}>Pilihan Produk</option>
                                        <option className="text-uppercase" selected={s.produk === "express" ? true : false} value="express">0 | express</option>
                                        <option className="text-uppercase" selected={s.produk === "pkh" ? true : false} value="pkh">1 | pkh</option>
                                        <option className="text-uppercase" selected={s.produk === "paket biasa" ? true : false} value="paket biasa">2 | PAKET Biasa</option>
                                        <option className="text-uppercase" selected={s.produk === "ems dokumen" ? true : false} value="ems dokumen">3 | EMS Dokumen</option>
                                        <option className="text-uppercase" selected={s.produk === "ems non dokumen" ? true : false} value="ems non dokumen">4 | EMS Non Dokumen</option>
                                        <option className="text-uppercase" selected={s.produk === "paket ln" ? true : false} value="paket ln">5 | PAKETLN</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="f-14 py-0">Nilai Barang</td>
                                <td className="f-14 py-0"><input className="form-control form-control-sm" placeholder="Dalam Rupiah" /></td>
                            </tr>
                            <tr>
                                <td className="f-14 py-1" colSpan="2">
                                    <table className="table table-sm table-borderless">
                                        <tr className="border">
                                            <td className="border">Kode</td>
                                            <td className="border">Jenis layanan Tersedia</td>
                                            <td className="border">Tarif</td>
                                            <td className="border">SWP</td>
                                        </tr>
                                        <tr className="border-left border-right">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr className="border-left border-right">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr className="border-left border-right border-bottom">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td className="f-14 pb-2 text-primary text-bold" colSpan="2">*Pilih Layanan, denan cara klik kode Produk</td>
                            </tr>
                            <tr>
                                <td className="f-14 py-0">Kategori</td>
                                <td className="f-14 py-0">
                                    <select className="form-control form-control-sm text-uppercase" id="exampleFormControlSelect1">
                                        <option className="text-uppercase">1 | Individu</option>
                                        <option className="text-uppercase">2 | Perusahaan</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="f-14 py-0">Isi Kiriman</td>
                                <td className="f-14 py-0"><input className="form-control form-control-sm" /></td>
                            </tr>
                            <tr className={s.produk === "paket ln" ? "" : "d-none" }>
                                <td className="f-14 py-0" colSpan="2">
                                    <div className="row align-items-center">
                                        <div className="col-4 border-0 px-0 text-right">Barcode International </div>
                                        <div className="col-8"><input className="form-control form-control-sm" /></div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-8">
                        <div className="row py-0 align-items-center">
                            <div className="col-5">
                                <div className="form-row py-2">
                                    <div className="col-2"> Ext Id</div>
                                    <div className="col"> <input className="form-control form-control-sm" /></div>
                                </div>
                                <fieldset className="border p-2">
                                    <legend className="f-18">Volumetrik</legend>
                                    <table className="table table-borderless table-sm">
                                        <tr className="text-center">
                                            <td className="f-14 py-0" colSpan="2">
                                                <div className="row align-items-center">
                                                    <div className="col-2 border-0 px-0 text-right">P </div>
                                                    <div className="col-2 pl-1 pr-0">
                                                        <input className="w-100" value={s.p}
                                                            onFocus={(e)=>e.target.select()}
                                                            onChange={(e) => this.handleOnChange("p", e.target.value.replace(/[^0-9]/g, ''))} />
                                                    </div>
                                                    <div className="col-2 border-0 px-0 text-right">x L </div>
                                                    <div className="col-2 pl-1 pr-0">
                                                        <input className="w-100" value={s.l}
                                                            onFocus={(e)=>e.target.select()} 
                                                            onChange={(e) => this.handleOnChange("l", e.target.value.replace(/[^0-9]/g, ''))} />
                                                    </div>
                                                    <div className="col-2 border-0 px-0 text-right">x T </div>
                                                    <div className="col-2 pl-1">
                                                        <input className="w-100" value={s.t}
                                                            onFocus={(e)=>e.target.select()} 
                                                            onChange={(e) => this.handleOnChange("t", e.target.value.replace(/[^0-9]/g, ''))} />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="text-center">
                                            <td className="f-14 py-1 b-border-gray" colSpan="3"></td>
                                        </tr>
                                        <tr className="text-center">
                                            <td className="f-14 pb-1 pt-0" colSpan="3">{s.pembagi}</td>
                                        </tr>
                                        <tr>
                                            <td className="f-14 py-0">Berat volume</td>
                                            <td colSpan="2" className="f-14 py-0">
                                                <input className="form-control form-control-sm" value={s.vol} />
                                            </td>
                                        </tr>
                                        <tr className="align-items-center">
                                            <td className="f-14">Berat</td>
                                            <td className="f-14 py-0">
                                                <input className="form-control form-control-sm" value={s.vol}/> 
                                            </td>
                                            <td className="f-14">(Gr)</td>
                                        </tr>
                                    </table>
                                </fieldset>
                            </div>
                            <div className="col-7">
                                <h5 className="text-uppercase text-white bg-dark py-0">Data Penerima</h5>
                                <table className="table table-sm table-borderless">
                                    <tr>
                                        <td colSpan="2" className="text-right f-14 py-0">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    Penerimaan Kolektif
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Nama Perusahaan</td>
                                        <td className="f-14 py-0"><input className="form-control form-control-sm"/></td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Nama*</td>
                                        <td className="f-14 py-0"><input className="form-control form-control-sm" placeholder="masukkan nama penerima" /></td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Alamat*</td>
                                        <td className="f-14 py-0"><input className="form-control form-control-sm" placeholder="masukkan alamat penerima" /></td>
                                    </tr>
                                    <tr>
                                        <td className="f-12 py-0" style={{width:"30%"}}>Hp*</td>
                                        <td className="f-14 py-0">
                                            <div className="row align-items-center">
                                                <div className="col-5 pr-0"><input className="form-control form-control-sm" /></div>
                                                <div className="col-2 border-0 pr-1 text-right">Email</div>
                                                <div className="col-5 pl-0"><input className="form-control form-control-sm" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Kota</td>
                                        <td className="f-14 py-0">
                                            <div className="row align-items-center">
                                                <div className="col-4 pr-0"><input className="form-control form-control-sm" /></div>
                                                <div className="col-3 border-0 pr-1 text-right">Kodepos</div>
                                                <div className="col-5 pl-0"><input className="form-control form-control-sm" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Propinsi</td>
                                        <td className="f-14 py-0">
                                            <div className="row align-items-center">
                                                <div className="col-4 pr-0"><input className="form-control form-control-sm" /></div>
                                                <div className="col-2 border-0 px-1 text-right">Negara</div>
                                                <div className="col-6 pl-0"><input className="form-control form-control-sm" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="row py-0 align-items-center">
                            <div className="col-5">
                                <fieldset className="border p-2">
                                    <legend className="f-18">Bea</legend>
                                    <table className="table table-borderless table-sm">
                                        <tr>
                                            <td className="f-14 py-0">Bea Kirim</td>
                                            <td className="f-14 py-0"><input className="form-control form-control-sm"/></td>
                                        </tr>
                                        <tr>
                                            <td className="f-14 py-0">Diskon (-)</td>
                                            <td className="f-14 py-0"><input className="form-control form-control-sm"/></td>
                                        </tr>
                                        <tr>
                                            <td className="f-14 py-0">PPN </td>
                                            <td className="f-14 py-0"><input className="form-control form-control-sm"/></td>
                                        </tr>
                                        <tr>
                                            <td className="f-14 py-0">HTNB</td>
                                            <td className="f-14 py-0"><input className="form-control form-control-sm"/></td>
                                        </tr>
                                        <tr>
                                            <td className="f-14 py-0">Jumlah</td>
                                            <td className="f-14 py-0"><input className="form-control form-control-sm"/></td>
                                        </tr>
                                    </table>
                                </fieldset>
                            </div>
                            <div className="col-7">
                                <h5 className="text-uppercase text-white bg-dark py-0">Data Pengirim</h5>
                                <table className="table table-sm table-borderless">
                                    <tr>
                                        <td colSpan="2" className="text-right f-14 py-0">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    Pengiriman Kolektif
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Nama Perusahaan</td>
                                        <td className="f-14 py-0"><input className="form-control form-control-sm"/></td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Nama*</td>
                                        <td className="f-14 py-0"><input className="form-control form-control-sm" placeholder="masukkan nama Pengirim" /></td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Alamat*</td>
                                        <td className="f-14 py-0"><input className="form-control form-control-sm" placeholder="masukkan alamat Pengirim" /></td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0" style={{width:"30%"}}>Hp*</td>
                                        <td className="f-14 py-0">
                                            <div className="row align-items-center">
                                                <div className="col-5 pr-0"><input className="form-control form-control-sm" /></div>
                                                <div className="col-2 border-0 pr-1 text-right">Email</div>
                                                <div className="col-5 pl-0"><input className="form-control form-control-sm" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Kota</td>
                                        <td className="f-14 py-0">
                                            <div className="row align-items-center">
                                                <div className="col-4 pr-0"><input className="form-control form-control-sm" /></div>
                                                <div className="col-3 border-0 pr-0 text-right">Kodepos</div>
                                                <div className="col-5 pl-0"><input className="form-control form-control-sm" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="f-14 py-0">Propinsi</td>
                                        <td className="f-14 py-0">
                                            <div className="row align-items-center">
                                                <div className="col-4 pr-0"><input className="form-control form-control-sm" /></div>
                                                <div className="col-2 border-0 px-1 text-right">Negara</div>
                                                <div className="col-6 pl-0"><input className="form-control form-control-sm" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <table className="table table-sm table-borderless py-0">
                        <tr>
                            <td className="f-14 py-0"style={{width:"10%"}} >Tarif [$]</td>
                            <td className="f-14 py-0"><input style={{width: "100px"}}/></td>
                            <td className="f-14 py-0">Jumlah</td>
                            <td className="f-14 py-0"><input style={{width: "100px"}}/></td>
                            <td className="f-14 py-0">BSU Loket</td>
                            <td className="f-14 py-0"><input style={{width: "100px"}}/></td>
                            <td className="f-14 py-0">Kolektif(Rp)</td>
                            <td className="f-14 py-0"><input style={{width: "100px"}}/></td>
                            <td className="f-14 py-0">Batal</td>
                            <td className="f-14 py-0"><input style={{width: "100px"}}/></td>
                            <td className="f-14 py-0">BSU Batal</td>
                            <td className="f-14 py-0"><input style={{width: "100px"}}/></td>
                        </tr>
                    </table>
                </div>
                <div className="row p-0 m-0 align-items-center">
                    <div className="col-12 text-center">
                        <button className="px-3">Proses</button>
                        <button className="px-3">Reset</button>
                        <button className="px-3">Bayar</button>
                    </div>

                </div>
            </div>
        )
    }
}

function AlertWarning(props) {
    return (<>
        <Modal show={props.show} onHide={()=>props.handleClose()}>
            <Modal.Header closeButton className="py-1 border-0">
            </Modal.Header>
            <Modal.Body className="text-center text-danger">
                <i className="fas fa-exclamation-triangle fa-3x"></i>
                <h5 className="py-3">{props.msgAlert}</h5>
            </Modal.Body>
        </Modal>
    </>);
}

function AlertConfirm(props) {
    return (<>    
        <Modal show={props.show} onHide={()=>props.handleClose()}>
            <Modal.Body className="text-center pt-5 border">
                <h5 className="py-3">{props.msgAlert}</h5>
            </Modal.Body>
            <Modal.Footer className="py-1 border-0">
                <Button variant="secondary" onClick={()=>props.handleClose("confirm")}>
                    Close
                </Button>
                <Button variant="primary" onClick={()=>props.handleYes()}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default RitelLoketPage;