import clsx from 'clsx';
import { FormControl, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useState, useMemo, useEffect } from 'react';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { TableGrid } from '../../../../controls';
import { baseUrl } from '../../../../../app.config';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OtorisasiTransaksiPage = () => {
    const [refuseModal, setRefuseModal] = useState(false);
    const [approvedModal, setApprovedModal] = useState(false);
    const [noRef, setNoRef] = useState('');
    const [detailModal, setDetailModal] = useState(false);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState();
    const [status, setStatus] = useState('');
    const [id, setId] = useState(0);
    const [keterangan, setKeterangan] = useState('');
    const [activeData, setActiveData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    //Load datatable
    useEffect(() => {
        fetch(baseUrl + `/transaksi/reversal/pending/all?limit=${limit}&page=${currentPage}`)
            .then((response) => response.json())
            .then((data) => setData(data?.list));
    }, [currentPage, limit, status]);

    //Parse data detail modal
    const parseData = (arrayData) => {
        debugger;
        const list = [];
        let changeToArray = [];
        changeToArray.push(arrayData);
        debugger;
        for (let data of changeToArray) {
            const debit = {};
            const kredit = {};

            const formatNumber = (number) =>
                new Intl.NumberFormat('id-ID', {
                    currency: 'IDR',
                }).format(number);

            debit.cabang = data.cabang_debit ? data.cabang_debit : '-';
            debit.nama = data.nama_debit ? data.nama_debit : '-';
            debit.no_rekening = data.no_rekening_debit ? data.no_rekening_debit : '-';
            debit.keterangan = data.keterangan ? data.keterangan : '-';
            debit.valuta = data.valuta_debit ? data.valuta_debit : '-';
            debit.mutasi = 'DEBIT';
            debit.nilai_transaksi = data.nilai_transaksi_debit
                ? formatNumber(data.nilai_transaksi_debit)
                : '-';
            debit.kurs = data.kurs_debit ? data.kurs_debit : '-';
            debit.nilai_ekuivalen = data.nilai_ekuivalen_debit
                ? formatNumber(data.nilai_ekuivalen_debit)
                : '-';

            kredit.cabang = data.cabang_kredit ? data.cabang_kredit : '-';
            kredit.nama = data.nama_kredit ? data.nama_kredit : '-';
            kredit.no_rekening = data.no_rekening_kredit ? data.no_rekening_kredit : '';
            kredit.keterangan = data.keterangan ? data.keterangan : '';
            kredit.valuta = data.valuta_kredit ? data.valuta_kredit : '';
            kredit.mutasi = 'KREDIT';
            kredit.nilai_transaksi = data.nilai_transaksi_kredit
                ? formatNumber(data.nilai_transaksi_kredit)
                : '-';
            kredit.kurs = data.kurs_kredit ? data.kurs_kredit : '-';
            kredit.nilai_ekuivalen = data.nilai_ekuivalen_kredit
                ? formatNumber(data.nilai_ekuivalen_kredit)
                : '-';

            list.push(debit, kredit);
        }
        setActiveData(list);
        return list;
    };

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    //Action for refuse transaction modal
    const refuseTransaction = () => {
        setRefuseModal(false);
        setDetailModal(false);

        return fetch(baseUrl + `/transaksi/reversal/reject/${id}`, {
            method: 'POST',
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id == '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    //Action for approve transaction from detail modal
    const approvedAction = () => {
        setApprovedModal(false);
        setDetailModal(false);

        return fetch(baseUrl + `/transaksi/reversal/approve/${id}`, {
            method: 'POST',
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id == '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    return (
        <>
            <Modal centered show={Boolean(approvedModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setApprovedModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Setujui Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <p>
                        Yakin menyetujui otorisasi reversal dengan nomor referensi <b>{noRef}</b> ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setApprovedModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={approvedAction}
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(refuseModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setRefuseModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tolak Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <p>
                        Yakin menolak otorisasi reversal dengan nomor referensi <b>{noRef}</b> ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setRefuseModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={refuseTransaction}
                    >
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" show={Boolean(detailModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setDetailModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Detail Transaksi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <div className="tw-bg-white tw-rounded-lg">
                        <TableGrid
                            columns={[
                                {
                                    name: 'Cabang',
                                    field: 'cabang',
                                    width: '100px',
                                },
                                {
                                    name: 'Nama',
                                    field: 'nama',
                                    width: '100px',
                                },
                                {
                                    name: 'Rekening',
                                    field: 'no_rekening',
                                    width: '100px',
                                },
                                {
                                    name: 'Keterangan',
                                    field: 'keterangan',
                                    width: '100px',
                                },
                                {
                                    name: 'Valuta',
                                    field: 'valuta',
                                    width: '100px',
                                },
                                {
                                    name: 'Mutasi',
                                    field: 'mutasi',
                                    width: '100px',
                                },
                                {
                                    name: 'Nilai',
                                    field: 'nilai_transaksi',
                                    width: '100px',
                                },
                                {
                                    name: 'Kurs',
                                    field: 'kurs',
                                    width: '100px',
                                },
                                {
                                    name: 'Nilai Ekuivalen',
                                    field: 'nilai_ekuivalen',
                                    width: '100px',
                                },
                            ]}
                            data={activeData || []}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                        onClick={() => setDetailModal(false)}
                    >
                        Tidak Proses
                    </Button>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        basic
                        color="red"
                        onClick={() => {
                            setRefuseModal(true);
                        }}
                    >
                        Tolak
                    </Button>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        basic
                        color="green"
                        onClick={() => {
                            setApprovedModal(true);
                        }}
                    >
                        Setujui
                    </Button>
                </Modal.Footer>
            </Modal>

            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Informasi Transaksi
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Reversal Transaksi</Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Reversal Transaksi
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Waktu Transaksi',
                            field: 'created_at',
                            width: '100px',
                        },
                        {
                            name: 'Teller',
                            field: 'id_user',
                            width: '100px',
                        },
                        {
                            name: 'No. Referensi',
                            field: 'no_referensi',
                            width: '100px',
                        },
                        {
                            name: 'Jenis Transaksi',
                            field: 'type',
                            width: '100px',
                        },
                        {
                            name: 'Nilai Transaksi (Rp)',
                            field: 'nilai_transaksi',
                            width: '110px',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                            width: '1fr',
                        },

                        {
                            field: 'action',
                            width: '280px',
                        },
                    ]}
                    config={{
                        tableMinWidth: '1024px',
                        overflowX: 'auto',
                        height: '70vh',
                        overflowY: 'auto',
                        stickyHead: true,
                    }}
                    data={Array.from(data ?? [], ({ nilai_transaksi, ...otherProps }) => ({
                        ...otherProps,
                        nilai_transaksi: nilai_transaksi
                            ? new Intl.NumberFormat('id-ID').format(nilai_transaksi)
                            : '-',
                    }))}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'created_at')
                            return (
                                <div key={`${value}-${field}`} className={clsx(className)}>
                                    {dayjs(value).format('HH:mm:ss')}
                                </div>
                            );

                        if (field === 'action')
                            return (
                                <Action
                                    onDetail={() => {
                                        setId(data.id);
                                        setDetailModal(true);
                                        setNoRef(data.no_referensi);
                                        fetch(
                                            baseUrl + `/transaksi/reversal/pending/find/${data.id}`
                                        )
                                            .then((response) => response.json())
                                            .then((data) => parseData(data?.list));
                                    }}
                                    onApproved={() => {
                                        setId(data.id);
                                        setNoRef(data.no_referensi);
                                        setApprovedModal(true);
                                    }}
                                    onRefuse={() => {
                                        setRefuseModal(true);
                                        setId(data.id);
                                    }}
                                    key={`${value}-${field}`}
                                />
                            );
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
            </div>
        </>
    );
};

export default OtorisasiTransaksiPage;

const Action = ({ onDetail, onApproved, onRefuse }) => {
    const [show, setShow] = useState(false);

    const handleDetail = () => {
        if (onDetail) onDetail();

        setShow(false);
    };

    const handleApproved = () => {
        if (onApproved) onApproved();
        setShow(false);
    };
    const handleRefuse = () => {
        if (onRefuse) onRefuse();

        setShow(false);
    };

    return (
        <div>
            <Button basic color="red" size="mini" onClick={handleRefuse}>
                Tolak
            </Button>
            <Button basic color="green" className="tw-mr-3" size="mini" onClick={handleApproved}>
                Setujui
            </Button>
            <button type="button" class="btn btn-link" onClick={handleDetail}>
                Lihat Detail
            </button>
        </div>
    );
};
