import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { dasboardData } from '../../../../../../appConfig';
import { createForm } from '../../../../../../module/createFormList';
import { TableList } from '../../../../../controls';
import { FormRegistration } from '../../../../../controls';
import useRekening from '../useRekening';
import { addCommas } from '../../../../../../module';

const ModalBukaRekening = () => {
    const { rekState, rekAction } = useRekening();

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalBukaRekening} onHide={rekAction.closeBukaRek} >
                <Modal.Header closeButton>
                    <Modal.Title>Buka Blokir Rekening</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box mb-3">
                        <div className="row">
                            <div className="col">
                                <h4>{rekState.loadingBuka ? <Skeleton width={"50%"} height={30} /> : <>{rekState.dataBukaRekening?.nama_rekening}</>}</h4>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Produk </div>
                                            <div className="col-8">{rekState.loadingBuka ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataBukaRekening?.produk_rekening : rekState.dataBukaRekening?.nama_produk}`}</div>
                                        </div><div className="row">
                                            <div className="col-4"> No. Rek </div>
                                            <div className="col-8">{rekState.loadingBuka ? <Skeleton height={20} /> : `: ${rekState.dataBukaRekening?.nomor_rekening}`} </div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Cabang </div>
                                            <div className="col-8">{rekState.loadingBuka ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataBukaRekening?.cabang_rekening : rekState.dataBukaRekening?.nama_cabang}`}</div>
                                        </div><div className="row">
                                            <div className="col-4">Kode Valuta </div>
                                            <div className="col-8">{rekState.loadingBuka ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataBukaRekening?.valuta_rekening : rekState.dataBukaRekening?.valuta}`}</div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4"> Saldo Rek </div>
                                            <div className="col-8">
                                                {
                                                    rekState.loadingBuka ?
                                                        <Skeleton height={20} /> :
                                                        <> : <span className="float-right">{rekState.otorMode ? addCommas( parseFloat(rekState.dataBukaRekening?.saldo_rekening || "0").toFixed(2)) : addCommas( parseFloat(rekState.dataBukaRekening?.saldo || "0").toFixed(2))} </span></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="row">
                    <div className="col">
                    {
                            createForm({
                            label:"Keterangan Blokir",
                            type:"text",
                            required: true,
                            disabled: true,
                            readOnly: true,
                            placeholder: "Keterangan...",
                            value:  rekState.dataBukaRekening?.keterangan_blokir || rekState.dataBukaRekening?.alasan_blokir,
                            disabled: rekState.otorMode,
                            onChange: (e) => rekAction.setformBlokRekening(prevState => ({ ...prevState, alasan_blokir: e }))
                        })
                    }
                    </div>
                    <div className="col">
                    {
                            createForm({
                            label:"Jenis Blokir",
                            type:"select",
                            required: true,
                            placeholder: "Masukan jenis rekening",
                            value: rekState.dataBukaRekening?.jenis_blokir || rekState.dataBukaRekening?.jenis_blokir,
                            onChange: (e) => rekAction.setformBlokRekening(prevState => ({ ...prevState, jenis_blokir: e })),
                            disabled: true,
                            //disabled: rekState.otorMode,
                            options: [
                                        {
                                            key: "blokir_debet",
                                            display: "Blokir Debet"
                                        },{
                                            key: "blokir_credit",
                                            display: "Blokir Credit"
                                        },{
                                            key: "blokir_debet_credit",
                                            display: "Blokir Debet Credit"
                                        },
                            ]
                        })
                    }
                    </div>
                </div>
                <Modal.Footer>
                <div className="row">
                        {
                            !rekState.otorMode ? 
                            <div className="col">
                                { 
                                <button 
                                     onClick={()=> rekAction.confirmRegisBukaBlok()}
                                    className="btn btn-outline-info float-right ml-4">
                                    Buka Rekening
                                </button>
                                }
                                <button
                                    onClick={() => rekState.bukaRekModalMode ? rekAction.toggleModalMode() : rekAction.closeBukaRek()}
                                    className="btn btn-outline-success float-right">
                                    Batal
                                </button>
                            </div> : (
                                <div className="col">
                                    <button
                                        onClick={() => rekAction.closeBukaRek()}
                                        className="btn btn-outline-success float-right">
                                        Tutup
                                    </button>

                                    <button
                                        onClick={() => rekAction.responseOtor("APPROVED")}
                                        className="btn btn-success mr-2">
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => rekAction.responseOtor("REJECTED")}
                                        className="btn btn-danger mr-2">
                                        Reject
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal utama */}
        </>
    );
}

export default ModalBukaRekening;