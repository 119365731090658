export const getErrorEmptyValue = (obj = {}) => {
    let err = {};
    Object.keys(obj).forEach((key) => {
        if (!obj[key]) err[key] = true;
    });

    const isAnyEmptyError = Object.keys(err).some((key) => err[key]);

    return { err, isAnyEmptyError };
};

export class GetErrorEmptyValue {
    constructor(obj = {}) {
        let err = {};
        Object.keys(obj).forEach((key) => {
            if (!obj[key]) err[key] = true;
        });

        this.error = err;
    }

    get isError() {
        return Object.values(this.error).some((val) => val);
    }
}

export const isAnyEmptyError = (err = {}) => Object.keys(err).some((key) => err[key]);
