import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useDropdowns from '../../../../../hooks/useDropdowns';
import useFetch from '../../../../../hooks/useFetch';
// import { useKeycloak } from '@react-keycloak/web';
import useAlamat from '../../../../../hooks/useAlamat';
import { useLocalStorage } from '../../../../../module';
import { useModalAlert } from '../../../../controls/modalAlert';
import useKeycloak from '../../../../../hooks/keycloak.module';

const fpContext = React.createContext(null);
export const FpProvider = ({ children }) => {

    const [podDate, setPodDate] = useLocalStorage("POD_DATE")
    const { doGet, doGetMultiple, doPost } = useFetch();
    const paramUrl = useParams();
    const [step, setStep] = useState(0);
    const [formEditable, setFormEditable] = useState(true);
    const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false })
    const [locationState, setLocationState] = useState({});
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState({ getData: false, loadingPost: { show: false, msg: "Please Wait ..." } });
    const [otorDesc, setOtorDesc] = useState("");
    const [isWIC, setIsWIC] = useState('T');
    const dataDropDown = useDropdowns();
    const { keycloak } = useKeycloak()
    const [cekKondisi, setCekKondisi] = useState({
        "memiliki_npwp": false
    })
    const { modalState, modalAction } = useModalAlert();
    const [statusPEP, setStatusPEP] = useState({
        // "statusPep": false
    })

    const [curNIK, setcurNIK] = useState(null)

    const [keterkaitanPEP, setKeterkaitanPEP] = useState({
        // "detailPep": false
    })

    const [formData, setFormData] = useState(
        {
            "encodedKey": "8a8e869678ce17110178ce3be6cb005a",
            "id": "", //Nomor CIF 
            "state": "",
            "firstName": "",//Nama Lengkap
            "middleName": "",
            "lastName": " ",
            "preferredLanguage": "ENGLISH",
            "notes": "",//Keterangan
            "gender": "MALE", //Jenis Kelamin
            "homePhone": "",//Telepon Rumah 1
            "homeFax": "",//fax ALAMAT IDENTITAS
            "mobilePhone": "",//No Handphone
            "mobilePhone2": "",
            "emailAddress": "",//Alamat Email
            "birthDate": "",//birthDate
            "assignedBranchKey": "8a8e8fab786e635c0178863b7911431e",
            "nasabah_prioritas": "F",
            "nama_singkat": "",
            "nominal_penghasilan": 0,
            "kode_sumber_dana": "",
            "_personalIdData": {
                // "personalIdDateOfIssue": moment(Date.now()).format("YYYY-MM-DD"),
                "urlImageSelfie": "",
                "personalMarketingOfficer": "",//Marketing
                "personalReferralCode": "",//Referal Nasabah
                // "personalIdExpireDate": moment(Date.now()).format("YYYY-MM-DD"),
                "personalIdNumber": "",//Nomor Identitas
                "personalNpwpNumber": "",//NPWP
                "personalIdType": "",//Jenis Identitas
                "populationType": "",//Jenis Penduduk
                "urlImageKtp": "",
                "personalIdHolderName": "",//Nama (Sesuai Identitas)
                "personalIdIssuedPlace": "",
                "nasabah_prioritas": "F",
            },
            "_mailingAddress": {
                "mailingAddressPostalCode": "",//kode pos
                "mailingRecipientPhoneNumber": "",
                "mailingAddress": "",//alamat
                "mailingAddressCity": "", //kota
                "mailingAddressVillage": "",//kelurahan
                "mailingAddressProvince": "",//pro
                "mailingAddressRtRw": "",
                "mailingAddressRt": "",//rt
                "mailingAddressRw": "",//rw
                "mailingAddressSubDistrict": "",//kecmatan
                "lamaBulanTinggal": null,//lama tinggal
                "lamaTahunTinggal": null,// lama tinggal
                "AlamatLuarNegeri": "",// Alamat Luar Negeri
            },
            "_occupationInfo": {
                "personalOccupation": "",//Pekerjaan
                "companyName": "",//Nama Perusahaan
                "personalMonthlyIncome": "",
                "personalTypeOfWork": "",//Sektor Ekonomi
                "personalJobPosition": "",//Jabatan Pekerjaan
                "legalEntity": "", //Badan Hukum
                "detailKeterkaitanPep": "",
                "statusKeterkaitanPep": "",
                "statusPep": "",
                "detailPep": "",
                "kode_sektor_ekonomi": "",// Sekktor Ekonomi
                "lokasi_usaha": ""
            },
            "_customerData": {
                "birthPlace": "",//Tempat/Tanggal Lahir
                "personalCitizenship": "",//Kewarganegaraan
                "personalCity": "",//baru
                "personalReligion": "",//Personal Religion
                "personalMothersName": "",//Ibu Kandung
                "personalFathersName": "",
                "personalMaritalStatus": "",//Status Pernikahan
                "personalScoringCust": "",
                "customerEducation": "",//Pendidikan Terakhir
                "customerAliasName": "",//Nama Alias 1
                "customerPreTitle": "",//Gelar Depan
                "customerSpouseSecondIncome": "",
                "customerBankRelation": "",//Status Keterkaitan
                "customerSpouseName": "",
                "customerSpouseOccupation": "",
                "customerPostTitle": "",//Gelar Belakang
                "customerSpouseMainIncome": "",
                "customerAliasName2": ""//Alias 2
            },
            "_companyAddress": {
                "companyAddressCity": "",//Kota/Kab
                "companyAddressRt": "",//rt
                "companyAddressRw": "",//rw
                "companyAddressVillage": "",//Kelurahan
                "companyAddress": "",//Alamat
                "companyAddressSubDistrict": "",//Kecamatan
                "companyAddressPostalCode": "",//kode pos
                "companyAddressProvince": "",//Provinsi
                "companyAddressPhoneNumber": "",//Telepon
                "companyAddressFaxNumber": ""//Fax  companyAddressFaxNumber
            },
            "_ktpAddress": {
                "ktpRt": "",//rt
                "ktpRw": "",//rw
                "ktpSubDistrict": "",//kecamatan
                "ktpVillage": "",//kelurahan
                "ktpAddress": "",//alamat
                "ktpCity": "",//kota
                "ktpProvince": "",//Pro
                "ktpPostalCode": "",//Kode pos
                "ktpCountry": ""//Negara Asal
            },
            "_relasiNasabah": [],
            "_relasiAhliWaris": [],
            "_tambahRekeningLain": [],
            "_beneficiaryOwner": {
                "benefKecamatan": "",
                "benefNamaPerusahaan": "",
                "benefRw": "",
                "benefKodePos": "",
                "benefProvinsi": "",
                "benefNomorIzinUsaha": "",
                "benefBentukUsaha": "",
                "benefBidangUsaha": "",
                "benefRt": "",
                "benefKeterangan": "",
                "benefSumberDana": "",
                "benefAlamat": "",
                "benefKelurahan": "",
                "benefKotaKabupaten": "",
                "benefTujuanPenggunaanDana": ""
            },
            "_beneficiaryIndividu": {
                "benefNama": "",//Nama Lengkap
                "benefBirthPlace": "",//Tempat/Tanggal Lahir
                "benefBirthDate": "",//Tempat/Tanggal Lahir
                "benefAgama": "",//Agaman
                "benefStatusPerkawinan": "",//Status Perkawinan
                "benefHomePhone": "", //Telepon
                "benefMobilePhone": "",//Handphone
                "benefAlamat": "",
                "benefRw": "",
                "benefRt": "",
                "benefProvinsi": "",
                "benefKecamatan": "",
                "benefKelurahan": "",
                "benefKotaKabupaten": "",
                "benefNasabahKodePos": "",
                "benefAlamatDom": "",
                "benefRwDom": "",
                "benefRtDom": "",
                "benefProvinsiDom": "",
                "benefKecamatanDom": "",
                "benefKelurahanDom": "",
                "benefKotaKabupatenDom": "",
                "benefKodePosDom": ""
            },
            "_dataTambahan": {
                "dataNamaKeluarga": "",//NAMA Keluarga yang dapat dihubungi
                "dataHomePhone": "",//Telepon/Hp Keluarga yg dapat dihubungi
                "dataPenghasilanTahun": "",//Penghasilan utama pertahun
                "dataTujuanPenggunaDana": "",//Tujuan penggunaan dana
                "dataGolNasabah": "",//Golongan Nasabah
                "dataGolDebitur": "",
                "dataGolPemilikXBRL": "",
                "dataTujuanGaji": "",//Tujuan pembukaan rekening hanya untuk penerimaan gaji
                "dataTujuanProgramPemerintah": "",//Tujuan pembukaan rekening terkait program pemerintah
                "dataStatusKenaPajak": "",//Status Kena Pajak
                "dataKetBebasPajak": "",//Keterangan Bebas Pajak
                "dataNomorSurat": "",//Nomor Surat
                "dataTglTerbit": "",//tgl terbit
                "dataTglBerakhir": "",//tgl berakhir
                "dataSetorTunai": 0,//Nominal Setor Tunai
                "dataSetorTunaiFrekuensi": 0,//Frekuensi
                "dataSetorNonTunai": 0,//Nominal Setor NON Tunai
                "dataSetorNonTunaiFrekuensi": 0,//frekuensi
                "dataTarikTunai": 0,//Nominal Tarik Tunai
                "dataTarikTunaiFrekuensi": 0,//fre
                "dataTarikNonTunai": 0,//Nominal Tarik NOn Tunai
                "dataTarikNonTunaiFrekuensi": 0,//fre
                "dataResiko": "L",//Status Resiko
                "dataStatusResiko": "L", //
                "dataIdentitasNasabah": "L", //
                "dataLokasiUsaha": "L", //
                "dataProfilNasabah": "L", //
                "dataJumlahTransaksi": "L", //
                "dataKegiatanUsaha": "L",
                "dataStrukturKepemilikan": "L",
                "dataStrukturInformasiLainnya": "L",//
                "status_tempat_tinggal": "",//Status Tempat Tinggal
                "jumlahTanggungan": 0
            }
        }
    );
    // console.log(formData);

    // alamat 1
    const [districts, setDistricts] = useState([])
    const [villages, setvillages] = useState([])
    const [cities, setCities] = useState([])
    const [postalCodes, setPostalCodes] = useState([])

    const [districtsKtp, setDistrictsKtp] = useState([])
    const [villagesKtp, setvillagesKtp] = useState([])
    const [citiesKtp, setCitiesKtp] = useState([])
    const [postalCodesKtp, setPostalCodesKtp] = useState([])

    const [districtsDom, setDistrictsDom] = useState([])
    const [villagesDom, setvillagesDom] = useState([])
    const [citiesDom, setCitiesDom] = useState([])
    const [postalCodesDom, setPostalCodesDom] = useState([])

    const [districtsBenfIdentitas, setDistrictsBenfIdentitas] = useState([])
    const [villagesBenfIdentitas, setvillagesBenfIdentitas] = useState([])
    const [citiesBenfIdentitas, setCitiesBenfIdentitas] = useState([])
    const [postalCodesBenfIdentitas, setPostalCodesBenfIdentitas] = useState([])

    const [districtsBenfDom, setDistrictsBenfDom] = useState([])
    const [villagesBenfDom, setvillagesBenfDom] = useState([])
    const [citiesBenfDom, setCitiesBenfDom] = useState([])
    const [postalCodesBenfDom, setPostalCodesBenfDom] = useState([])
    const [dataMarketing, setDataMarketing] = useState([])

    const {
        provinces,
        getCities,
        getVillages,
        getPostalsCode,
        getDistrics,
        getProvinces
    } = useAlamat();

    const [isValid, setIsValid] = useState(false)

    useEffect(function () {
        setLocationState(location.state);
    }, [location.state])

    // function stepper
    function clickStep(id) { setStep(id); }
    function prevState() {
        // // // // console.log("prev")
        step > 0 && setStep(prevStep => prevStep - 1);
    }
    function nextStep() {
        // // // // console.log("next") 
        const nik_length = formData?._personalIdData?.personalIdNumber?.length
        if (formData?._personalIdData?.personalIdType === '1' && nik_length !== 16) {
            return setDataAlert({ show: true, body: "NIK KTP Harus 16 Digit. Silahkan cek kembali", title: `Data Belum Valid`, header: "Warning", callBack: () => null });
        }
        let dataRequired = [
            "personalIdType",
            "personalIdNumber",
            "personalIdHolderName",
            "firstName",
            "personalMothersName",
            "personalFathersName",
            "birthDate",
            "gender",
            "birthPlace",
            "personalCitizenship",
            "personalMaritalStatus",
            "populationType",
            "personalMarketingOfficer",
            "ktpCountry",
            "customerBankRelation",
            "personalMarketingOfficer"
        ];
        if (step === 1) {
            dataRequired = [
                "mailingAddress",
                "mobilePhone",
                "mailingAddressCity",
                "mailingAddressVillage",
                "mailingAddressProvince",
                "mailingAddressSubDistrict",
                "mailingAddressRt",
                "mailingAddressRw",
                "ktpSubDistrict",
                "ktpVillage",
                "ktpAddress",
                "ktpCity",
                "ktpProvince",
                "ktpRt",
                "ktpRw",
                "ktpPostalCode",
                "companyAddress",
                "companyAddressCity",
                "companyAddressVillage",
                "companyAddress",
                "companyAddressSubDistrict",
                "companyAddressProvince",
                "companyAddressRtRw",
                "companyAddressRt",
                "companyAddressRw",
                "companyAddressPostalCode",
                "lamaTahunTinggal",
                "lamaBulanTinggal",
                "personalOccupation",
                "companyName",
                "kode_sektor_ekonomi"
            ]
        }
        if (step === 2) {
            dataRequired = [
                // "dataPenghasilanTahun",
                // "jumlahTanggungan",
                "dataTujuanPenggunaDana",
                ...(
                    formData?._dataTambahan?.dataStatusKenaPajak === "0" ? [] : 
                    formData?._dataTambahan?.dataStatusKenaPajak === "1" ? ["dataKetBebasPajak","dataNomorSurat","dataTglTerbit", "dataTglBerakhir"] : 
                    formData?._dataTambahan?.dataStatusKenaPajak === "2" ? ["dataKetBebasPajak"] : []
                ),
                "dataGolNasabah",
                // "dataTujuanProgramPemerintah",
                "dataStatusKenaPajak",
                // "dataGolDebitur",
                // "dataStatusKenaPajak",
                // "dataKetBebasPajak",
                "dataSetorTunai",
                "dataSetorTunaiFrekuensi",
                "dataSetorNonTunai",
                "dataSetorNonTunaiFrekuensi",
                "dataTarikTunai",
                "dataTarikTunaiFrekuensi",
                "dataTarikNonTunai",
                "dataTarikNonTunaiFrekuensi",
                "dataResiko",
                "nominal_penghasilan",
                "kode_sumber_dana",
            ]
        }
        // if (step === 3){
        //     dataRequired = [
        //         "dataPenghasilanTahun",
        //         "dataTujuanPenggunaDana",
        //         "dataGolNasabah",
        //         "dataStatusKenaPajak",
        //         "dataKetBebasPajak",
        //         "dataNomorSurat",
        //         "dataTglTerbit",
        //         "dataTglBerakhir"
        //     ]
        // }
        // if (step === 4) {
        //     dataRequired = [
        //         "_relasiNasabah",
        //     ]
        // }


        if (paramUrl.mode === "registration" || paramUrl.mode === "edit") {
            if (isValid == false) {
                if (paramUrl.mode === "edit") {
                    if (formData._personalIdData?.personalIdNumber === curNIK) {
                        return setIsValid(true)
                        // return false;
                    }
                }
                return setDataAlert({ show: true, body: "Nama (Sesuai Identitas), Nomor Identitas dan Tanggal Lahir Belum Tervalidasi. Silahkan isi data lalu klik Button Cek Data Nasabah", title: `Data Belum Valid`, header: "Warning", callBack: () => null });
            }
            if (cekKondisi?.memiliki_npwp && formData?._personalIdData?.personalNpwpNumber?.length !== 15) {
                return setDataAlert({ show: true, body: "Nomor Npwp harus 15 digit", title: `Validasi Nomor Npwp`, header: "Warning", callBack: () => null });
            }
            if (validasi(dataRequired)) {
                return setDataAlert({ show: true, body: "Periksa kembali form pendaftaran, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
            }
        }
        step !== 5 && setStep(prevStep => prevStep + 1);
    }

    function handleShortcut(e) {
        const { key, keyCode } = e;
        if (keyCode === 37) {

            prevState()
        } if (keyCode === 39) {
            nextStep()
            // setUserText(prevUserText => `${prevUserText}${key}`);
        }
    }

    function handleShiftUp(e) {
        const { key, keyCode } = e;
        if (keyCode === 16) {

            document.removeEventListener("keyup", handleShortcut)
        }
    }


    function handleShiftDown(e) {
        const { key, keyCode } = e;
        if (keyCode === 16) {
            document.addEventListener("keyup", handleShortcut)
        }
    }
    useEffect(function(){

        getDataMarketing()
    },[])
    useEffect(() => {
        if (paramUrl.mode === "otorisasi" || paramUrl.mode === "edit" || paramUrl.mode === "detil" || paramUrl.mode === "editwic") {
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            setIsValid(true)
            if (provinces.length > 0) {
                getDataPerorangan(location.state?.dbId);
            }
        } else setLoading(false)
        // } 
        setFormEditable(paramUrl.mode === "registration" || paramUrl.mode === "edit" || paramUrl.mode === "editwic");
    }, [paramUrl.mode, provinces])


    // useEffect(function(){
    //     // console.log(formData)
    // },[formData])
    // function getData Perorangan
    async function getDataPerorangan(dbId) {
        try {
            let url = `/nasabah/${dbId}`;
            if (paramUrl.mode === "otorisasi") url = `/nasabah/local/${dbId}`;

            setLoading(prevState => ({ ...prevState, loading: true }));
            const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url });
            if (statusCode === 200) {
                if (status === "00") {
                    if (data !== null) {
                        // // console.log(data.nasabahBadanUsaha.jumlahTanggungan)
                        const { nasabahBadanUsaha } = data;
                        let stateBase = {
                            // dataConverter.nasabahIndividu(nasabahBadanUsaha)
                        }

                        if (paramUrl.mode === "otorisasi") {
                            resetForm()
                            setCekKondisi({
                                memiliki_npwp: nasabahBadanUsaha.personalNpwpNumber !== "" && nasabahBadanUsaha.personalNpwpNumber !== null && nasabahBadanUsaha.personalNpwpNumber !== undefined
                            })
                            // // // // console.log(nasabahBadanUsaha)
                            setFormData(prevState => ({
                                ...prevState,
                                // "encodedKey": nasabahBadanUsaha.,
                                "id": nasabahBadanUsaha.id,
                                "state": nasabahBadanUsaha.state ?? "",
                                "firstName": nasabahBadanUsaha.firstName ?? "",
                                "middleName": nasabahBadanUsaha.middleName ?? "",
                                "lastName": nasabahBadanUsaha.lastName,
                                "preferredLanguage": nasabahBadanUsaha.preferredLanguage,
                                "notes": nasabahBadanUsaha.notes,
                                "gender": nasabahBadanUsaha.gender,
                                "homeFax": nasabahBadanUsaha.homeFax,
                                "homePhone": nasabahBadanUsaha.homePhone,
                                "mobilePhone": nasabahBadanUsaha.mobilePhone,
                                "mobilePhone2": nasabahBadanUsaha.mobilePhone2,
                                "emailAddress": nasabahBadanUsaha.emailAddress,
                                "birthDate": nasabahBadanUsaha.birthDate,
                                "assignedBranchKey": nasabahBadanUsaha.assignedBranchKey,
                                "nasabah_prioritas": nasabahBadanUsaha?.nasabah_prioritas,
                                "nama_singkat": nasabahBadanUsaha?.nama_singkat,
                                "nominal_penghasilan": nasabahBadanUsaha?.nominal_penghasilan || 0,
                                "kode_sumber_dana":nasabahBadanUsaha?.kode_sumber_dana,
                                "tanggal_buka": nasabahBadanUsaha?.tanggal_buka,
                                "_personalIdData": {
                                    "urlImageSelfie": nasabahBadanUsaha.urlImageSelfie,
                                    "personalMarketingOfficer": nasabahBadanUsaha.personalMarketingOfficer,
                                    "personalReferralCode": nasabahBadanUsaha.personalReferralCode,
                                    "personalIdNumber": nasabahBadanUsaha.personalIdNumber,
                                    "personalNpwpNumber": nasabahBadanUsaha.personalNpwpNumber,
                                    "personalIdType": nasabahBadanUsaha.personalIdType,
                                    "populationType": nasabahBadanUsaha.populationType,
                                    "urlImageKtp": nasabahBadanUsaha.urlImageKtp,
                                    "personalIdHolderName": nasabahBadanUsaha.personalIdHolderName,
                                    "personalIdIssuedPlace": nasabahBadanUsaha.personalIdIssuedPlace,
                                    "nasabah_prioritas": nasabahBadanUsaha.nasabah_prioritas,
                                },
                                "_mailingAddress": {
                                    "mailingAddressPostalCode": nasabahBadanUsaha.mailingAddressPostalCode,
                                    "mailingRecipientPhoneNumber": nasabahBadanUsaha.mailingRecipientPhoneNumber,
                                    "mailingAddress": nasabahBadanUsaha.mailingAddress,
                                    "mailingAddressCity": nasabahBadanUsaha.mailingAddressCity,
                                    "mailingAddressVillage": nasabahBadanUsaha.mailingAddressVillage,
                                    "mailingAddressProvince": nasabahBadanUsaha.mailingAddressProvince,
                                    "mailingAddressRtRw": nasabahBadanUsaha.mailingAddressRtRw,
                                    "mailingAddressRt": nasabahBadanUsaha.mailingAddressRt,
                                    "mailingAddressRw": nasabahBadanUsaha.mailingAddressRw,
                                    "mailingAddressSubDistrict": nasabahBadanUsaha.mailingAddressSubDistrict,
                                    "lamaBulanTinggal": nasabahBadanUsaha.lamaBulanTinggal,
                                    "lamaTahunTinggal": nasabahBadanUsaha.lamaTahunTinggal,
                                    "AlamatLuarNegeri": nasabahBadanUsaha.AlamatLuarNegeri,
                                },
                                "_occupationInfo": {
                                    "personalOccupation": nasabahBadanUsaha.personalOccupation,
                                    "companyName": nasabahBadanUsaha.companyName,
                                    "personalMonthlyIncome": nasabahBadanUsaha.personalMonthlyIncome,
                                    "personalTypeOfWork": nasabahBadanUsaha.personalTypeOfWork,
                                    "personalJobPosition": nasabahBadanUsaha.personalJobPosition,
                                    "legalEntity": nasabahBadanUsaha.legalEntity,
                                    "detailKeterkaitanPep": nasabahBadanUsaha.detailKeterkaitanPep,
                                    "statusKeterkaitanPep": nasabahBadanUsaha.statusKeterkaitanPep,
                                    "statusPep": nasabahBadanUsaha.statusPep,
                                    "detailPep": nasabahBadanUsaha.detailPep,
                                    "kode_sektor_ekonomi": nasabahBadanUsaha.kode_sektor_ekonomi,
                                },
                                "_customerData": {
                                    "birthPlace": nasabahBadanUsaha.birthPlace,
                                    "personalCitizenship": nasabahBadanUsaha.personalCitizenship,
                                    "personalCity": nasabahBadanUsaha.personalCity,
                                    "personalReligion": nasabahBadanUsaha.personalReligion,
                                    "personalMothersName": nasabahBadanUsaha.personalMothersName,
                                    "personalFathersName": nasabahBadanUsaha.personalFathersName,
                                    "personalMaritalStatus": nasabahBadanUsaha.personalMaritalStatus,
                                    "personalScoringCust": nasabahBadanUsaha.personalScoringCust,
                                    "customerEducation": nasabahBadanUsaha.customerEducation,
                                    "customerAliasName": nasabahBadanUsaha.customerAliasName,
                                    "customerPreTitle": nasabahBadanUsaha.customerPreTitle,
                                    "customerSpouseSecondIncome": nasabahBadanUsaha.customerSpouseSecondIncome,
                                    "customerBankRelation": nasabahBadanUsaha.customerBankRelation,
                                    "customerSpouseName": nasabahBadanUsaha.customerSpouseName,
                                    "customerSpouseOccupation": nasabahBadanUsaha.customerSpouseOccupation,
                                    "customerPostTitle": nasabahBadanUsaha.customerPostTitle,
                                    "customerSpouseMainIncome": nasabahBadanUsaha.customerSpouseMainIncome,
                                    "customerAliasName2": nasabahBadanUsaha.customerAliasName2,
                                },
                                "_companyAddress": {
                                    "companyAddressCity": nasabahBadanUsaha.companyAddressCity,
                                    "companyAddressRt": nasabahBadanUsaha.companyAddressRt,
                                    "companyAddressRw": nasabahBadanUsaha.companyAddressRw,
                                    "companyAddressVillage": nasabahBadanUsaha.companyAddressVillage,
                                    "companyAddress": nasabahBadanUsaha.companyAddress,
                                    "companyAddressSubDistrict": nasabahBadanUsaha.companyAddressSubDistrict,
                                    "companyAddressPostalCode": nasabahBadanUsaha.companyAddressPostalCode,
                                    "companyAddressProvince": nasabahBadanUsaha.companyAddressProvince,
                                    "companyAddressPhoneNumber": nasabahBadanUsaha.companyAddressPhoneNumber,
                                    "companyAddressFaxNumber": nasabahBadanUsaha.companyAddressFaxNumber,
                                },
                                "_ktpAddress": {
                                    "ktpRt": nasabahBadanUsaha.ktpRt,
                                    "ktpRw": nasabahBadanUsaha.ktpRw,
                                    "ktpSubDistrict": nasabahBadanUsaha.ktpSubDistrict,
                                    "ktpVillage": nasabahBadanUsaha.ktpVillage,
                                    "ktpAddress": nasabahBadanUsaha.ktpAddress,
                                    "ktpCity": nasabahBadanUsaha.ktpCity,
                                    "ktpProvince": nasabahBadanUsaha.ktpProvince,
                                    "ktpPostalCode": nasabahBadanUsaha.ktpPostalCode,
                                    "ktpCountry": nasabahBadanUsaha.ktpCountry,
                                },
                                "_relasiAhliWaris": nasabahBadanUsaha._relasiAhliWaris,
                                "_relasiNasabah": nasabahBadanUsaha._relasiNasabah,
                                "_tambahRekeningLain": nasabahBadanUsaha._tambahRekeningLain,
                                "_beneficiaryOwner": {
                                    "benefKecamatan": nasabahBadanUsaha.benefKecamatan,
                                    "benefNamaPerusahaan": nasabahBadanUsaha.benefNamaPerusahaan,
                                    "benefRw": nasabahBadanUsaha.benefRw,
                                    "benefKodePos": nasabahBadanUsaha.benefKodePos,
                                    "benefProvinsi": nasabahBadanUsaha.benefProvinsi,
                                    "benefNomorIzinUsaha": nasabahBadanUsaha.benefNomorIzinUsaha,
                                    "benefBentukUsaha": nasabahBadanUsaha.benefBentukUsaha,
                                    "benefBidangUsaha": nasabahBadanUsaha.benefBidangUsaha,
                                    "benefRt": nasabahBadanUsaha.benefRt,
                                    "benefKeterangan": nasabahBadanUsaha.benefKeterangan,
                                    "benefSumberDana": nasabahBadanUsaha.benefSumberDana,
                                    "benefAlamat": nasabahBadanUsaha.benefAlamat,
                                    "benefKelurahan": nasabahBadanUsaha.benefKelurahan,
                                    "benefKotaKabupaten": nasabahBadanUsaha.benefKotaKabupaten,
                                    "benefTujuanPenggunaanDana": nasabahBadanUsaha.benefTujuanPenggunaanDana,
                                },
                                "_beneficiaryIndividu": {
                                    "benefNama": nasabahBadanUsaha.benefNama,
                                    "benefGender": nasabahBadanUsaha?.benefGender,
                                    "benefBirthPlace": nasabahBadanUsaha.benefBirthPlace,
                                    "benefBirthDate": nasabahBadanUsaha.benefBirthDate,
                                    "benefAgama": nasabahBadanUsaha.benefAgama,
                                    "benefStatusPerkawinan": nasabahBadanUsaha.benefStatusPerkawinan,
                                    "benefHomePhone": nasabahBadanUsaha.benefHomePhone,
                                    "benefMobilePhone": nasabahBadanUsaha.benefMobilePhone,
                                    "benefAlamat": nasabahBadanUsaha.benefAlamat,
                                    "benefRw": nasabahBadanUsaha.benefRw,
                                    "benefRt": nasabahBadanUsaha.benefRt,
                                    "benefProvinsi": nasabahBadanUsaha.benefProvinsi,
                                    "benefKecamatan": nasabahBadanUsaha.benefKecamatan,
                                    "benefKelurahan": nasabahBadanUsaha.benefKelurahan,
                                    "benefKotaKabupaten": nasabahBadanUsaha.benefKotaKabupaten,
                                    "benefNasabahKodePos": nasabahBadanUsaha.benefNasabahKodePos,
                                    "benefAlamatDom": nasabahBadanUsaha.benefAlamatDom,
                                    "benefRwDom": nasabahBadanUsaha.benefRwDom,
                                    "benefRtDom": nasabahBadanUsaha.benefRtDom,
                                    "benefProvinsiDom": nasabahBadanUsaha.benefProvinsiDom,
                                    "benefKecamatanDom": nasabahBadanUsaha.benefKecamatanDom,
                                    "benefKelurahanDom": nasabahBadanUsaha.benefKelurahanDom,
                                    "benefKotaKabupatenDom": nasabahBadanUsaha.benefKotaKabupatenDom,
                                    "benefKodePosDom": nasabahBadanUsaha.benefKodePosDom,
                                    "benefGender": nasabahBadanUsaha.benefGender
                                },
                                "_dataTambahan": {
                                    "dataNamaKeluarga": nasabahBadanUsaha.dataNamaKeluarga,
                                    "dataHomePhone": nasabahBadanUsaha.dataHomePhone,
                                    // "dataPenghasilanTahun": nasabahBadanUsaha.dataPenghasilanTahun,
                                    "dataPenghasilanTahun": "",
                                    "dataTujuanPenggunaDana": nasabahBadanUsaha.dataTujuanPenggunaDana,
                                    "dataGolNasabah": nasabahBadanUsaha.dataGolNasabah,
                                    "dataGolDebitur": nasabahBadanUsaha.dataGolDebitur,
                                    "dataGolPemilikXBRL": nasabahBadanUsaha.dataGolPemilikXBRL,
                                    "dataTujuanGaji": nasabahBadanUsaha.dataTujuanGaji,
                                    "dataTujuanProgramPemerintah": nasabahBadanUsaha.dataTujuanProgramPemerintah,
                                    "dataStatusKenaPajak": nasabahBadanUsaha.dataStatusKenaPajak,
                                    "dataKetBebasPajak": nasabahBadanUsaha.dataKetBebasPajak,
                                    "dataNomorSurat": nasabahBadanUsaha.dataNomorSurat,
                                    "dataTglTerbit": nasabahBadanUsaha.dataTglTerbit,
                                    "dataTglBerakhir": nasabahBadanUsaha.dataTglBerakhir,
                                    "dataSetorTunai": nasabahBadanUsaha.dataSetorTunai,
                                    "dataSetorTunaiFrekuensi": nasabahBadanUsaha.dataSetorTunaiFrekuensi,
                                    "dataSetorNonTunai": nasabahBadanUsaha.dataSetorNonTunai,
                                    "dataSetorNonTunaiFrekuensi": nasabahBadanUsaha.dataSetorNonTunaiFrekuensi,
                                    "dataTarikTunai": nasabahBadanUsaha.dataTarikTunai,
                                    "dataTarikTunaiFrekuensi": nasabahBadanUsaha.dataTarikTunaiFrekuensi,
                                    "dataTarikNonTunai": nasabahBadanUsaha.dataTarikNonTunai,
                                    "dataTarikNonTunaiFrekuensi": nasabahBadanUsaha.dataTarikNonTunaiFrekuensi,
                                    "dataResiko": nasabahBadanUsaha.dataResiko,
                                    "dataStatusResiko": nasabahBadanUsaha.dataStatusResiko,
                                    "dataIdentitasNasabah": nasabahBadanUsaha.dataIdentitasNasabah,
                                    "dataLokasiUsaha": nasabahBadanUsaha.dataLokasiUsaha,
                                    "dataProfilNasabah": nasabahBadanUsaha.dataProfilNasabah,
                                    "dataJumlahTransaksi": nasabahBadanUsaha.dataJumlahTransaksi,
                                    "dataKegiatanUsaha": nasabahBadanUsaha.dataKegiatanUsaha,
                                    "dataStrukturKepemilikan": nasabahBadanUsaha.dataStrukturKepemilikan,
                                    "dataStrukturInformasiLainnya": nasabahBadanUsaha.dataStrukturInformasiLainnya,
                                    "status_tempat_tinggal": nasabahBadanUsaha.status_tempat_tinggal,
                                    "jumlahTanggungan": nasabahBadanUsaha.jumlahTanggungan,
                                }
                            }));
                            changePajak(nasabahBadanUsaha?.dataStatusKenaPajak, {
                                "dataNamaKeluarga": nasabahBadanUsaha?.dataNamaKeluarga,
                                "dataHomePhone": nasabahBadanUsaha?.dataHomePhone,
                                // "dataPenghasilanTahun": nasabahBadanUsaha?.dataPenghasilanTahun,
                                "dataPenghasilanTahun": "",
                                "dataTujuanPenggunaDana": nasabahBadanUsaha?.dataTujuanPenggunaDana,
                                "dataGolNasabah": nasabahBadanUsaha?.dataGolNasabah,
                                "dataGolDebitur": nasabahBadanUsaha?.dataGolDebitur,
                                "dataGolPemilikXBRL": nasabahBadanUsaha?.dataGolPemilikXBRL,
                                "dataTujuanGaji": nasabahBadanUsaha?.dataTujuanGaji,
                                "dataTujuanProgramPemerintah": nasabahBadanUsaha?.dataTujuanProgramPemerintah,
                                "dataStatusKenaPajak": nasabahBadanUsaha?.dataStatusKenaPajak,
                                "dataKetBebasPajak": nasabahBadanUsaha?.dataKetBebasPajak,
                                "dataNomorSurat": nasabahBadanUsaha?.dataNomorSurat,
                                "dataTglTerbit": nasabahBadanUsaha?.dataTglTerbit,
                                "dataTglBerakhir": nasabahBadanUsaha?.dataTglBerakhir,
                                "dataSetorTunai": nasabahBadanUsaha?.dataSetorTunai ?? 0,
                                "dataSetorTunaiFrekuensi": nasabahBadanUsaha?.dataSetorTunaiFrekuensi ?? 0,
                                "dataSetorNonTunai": nasabahBadanUsaha?.dataSetorNonTunai ?? 0,
                                "dataSetorNonTunaiFrekuensi": nasabahBadanUsaha?.dataSetorNonTunaiFrekuensi ?? 0,
                                "dataTarikTunai": nasabahBadanUsaha?.dataTarikTunai ?? 0,
                                "dataTarikTunaiFrekuensi": nasabahBadanUsaha?.dataTarikTunaiFrekuensi ?? 0,
                                "dataTarikNonTunai": nasabahBadanUsaha?.dataTarikNonTunai ?? 0,
                                "dataTarikNonTunaiFrekuensi": nasabahBadanUsaha?.dataTarikNonTunaiFrekuensi ?? 0,
                                "dataResiko": nasabahBadanUsaha?.dataResiko,
                                "dataStatusResiko": nasabahBadanUsaha?.dataStatusResiko,
                                "dataIdentitasNasabah": nasabahBadanUsaha?.dataIdentitasNasabah,
                                "dataLokasiUsaha": nasabahBadanUsaha?.dataLokasiUsaha,
                                "dataProfilNasabah": nasabahBadanUsaha?.dataProfilNasabah,
                                "dataJumlahTransaksi": nasabahBadanUsaha?.dataJumlahTransaksi,
                                "dataKegiatanUsaha": nasabahBadanUsaha?.dataKegiatanUsaha,
                                "dataStrukturKepemilikan": nasabahBadanUsaha?.dataStrukturKepemilikan,
                                "dataStrukturInformasiLainnya": nasabahBadanUsaha?.dataStrukturInformasiLainnya,
                                "status_tempat_tinggal": nasabahBadanUsaha?.status_tempat_tinggal,
                            })

                            setFormData(prevState => ({ ...prevState, 
                                "_dataTambahan": {
                                    ...prevState._dataTambahan,
                                    "jumlahTanggungan": nasabahBadanUsaha?.jumlahTanggungan,
                                },
                                ...stateBase }));
                                
                            const province_code = nasabahBadanUsaha.companyAddressProvince
                            const province_codedom = nasabahBadanUsaha.mailingAddressProvince
                            const province_codektp = nasabahBadanUsaha.ktpProvince
                            const province_benefAlamat = nasabahBadanUsaha.benefProvinsi
                            const province_benefAlamatDom = nasabahBadanUsaha.benefProvinsiDom

                            await Promise.all([
                                getingCities(province_code),
                                getingCitiesDom(province_codedom),
                                getingCitiesKtp(province_codektp),
                                getingCitiesBenfIdentitas(province_benefAlamat),
                                getingCitiesBenfDom(province_benefAlamatDom),
                            ])

                        } else {

                            setCekKondisi({
                                memiliki_npwp: nasabahBadanUsaha.personalNpwpNumber !== "" && nasabahBadanUsaha.personalNpwpNumber !== null && nasabahBadanUsaha.personalNpwpNumber !== undefined
                            })

                            setcurNIK(nasabahBadanUsaha?.personalIdNumber);
                            setFormData(prevState => ({
                                ...prevState,
                                // "encodedKey": nasabahBadanUsaha.,
                                "id": nasabahBadanUsaha?.id ?? "",
                                "state": nasabahBadanUsaha?.state ?? "",
                                "firstName": nasabahBadanUsaha?.firstName ?? "",
                                "middleName": nasabahBadanUsaha?.middleName ?? "",
                                "lastName": nasabahBadanUsaha?.lastName ?? "",
                                "preferredLanguage": nasabahBadanUsaha?.preferredLanguage ?? "",
                                "notes": nasabahBadanUsaha?.notes ?? "",
                                "gender": nasabahBadanUsaha?.gender ?? "",
                                "homePhone": nasabahBadanUsaha?.homePhone ?? "",
                                "homeFax": nasabahBadanUsaha?.homeFax ?? "",
                                "mobilePhone": nasabahBadanUsaha?.mobilePhone ?? "",
                                "mobilePhone2": nasabahBadanUsaha?.mobilePhone2 ?? "",
                                "emailAddress": nasabahBadanUsaha?.emailAddress ?? "",
                                "birthDate": nasabahBadanUsaha?.birthDate,
                                "assignedBranchKey": nasabahBadanUsaha?.assignedBranchKey,
                                "nasabah_prioritas": nasabahBadanUsaha?.nasabah_prioritas,
                                "nama_singkat": nasabahBadanUsaha?.nama_singkat,
                                "nominal_penghasilan": nasabahBadanUsaha?.nominal_penghasilan || 0,
                                "kode_sumber_dana":nasabahBadanUsaha?.kode_sumber_dana,
                                "tanggal_buka": nasabahBadanUsaha?.tanggal_buka,
                                "_personalIdData": {
                                    "urlImageSelfie": nasabahBadanUsaha?.urlImageSelfie,
                                    "personalMarketingOfficer": nasabahBadanUsaha?.personalMarketingOfficer,
                                    "personalReferralCode": nasabahBadanUsaha?.personalReferralCode,
                                    "personalIdNumber": nasabahBadanUsaha?.personalIdNumber,
                                    "personalNpwpNumber": nasabahBadanUsaha?.personalNpwpNumber,
                                    "personalIdType": nasabahBadanUsaha?.personalIdType,
                                    "populationType": nasabahBadanUsaha?.populationType,
                                    "urlImageKtp": nasabahBadanUsaha?.urlImageKtp,
                                    "personalIdHolderName": nasabahBadanUsaha?.personalIdHolderName,
                                    "personalIdIssuedPlace": nasabahBadanUsaha?.personalIdIssuedPlace,
                                    "nasabah_prioritas": nasabahBadanUsaha?.nasabah_prioritas,
                                },
                                "_mailingAddress": {
                                    "mailingAddressPostalCode": nasabahBadanUsaha?.mailingAddressPostalCode,
                                    "mailingRecipientPhoneNumber": nasabahBadanUsaha?.mailingRecipientPhoneNumber,
                                    "mailingAddress": nasabahBadanUsaha?.mailingAddress,
                                    "mailingAddressCity": nasabahBadanUsaha?.mailingAddressCity,
                                    "mailingAddressVillage": nasabahBadanUsaha?.mailingAddressVillage,
                                    "mailingAddressProvince": nasabahBadanUsaha?.mailingAddressProvince,
                                    "mailingAddressRtRw": nasabahBadanUsaha?.mailingAddressRtRw,
                                    "mailingAddressRt": nasabahBadanUsaha?.mailingAddressRt,
                                    "mailingAddressRw": nasabahBadanUsaha?.mailingAddressRw,
                                    "mailingAddressSubDistrict": nasabahBadanUsaha?.mailingAddressSubDistrict,
                                    "lamaBulanTinggal": nasabahBadanUsaha?.lamaBulanTinggal,
                                    "lamaTahunTinggal": nasabahBadanUsaha?.lamaTahunTinggal,
                                    "AlamatLuarNegeri": nasabahBadanUsaha?.AlamatLuarNegeri,
                                },
                                "_occupationInfo": {
                                    "personalOccupation": nasabahBadanUsaha?.personalOccupation,
                                    "companyName": nasabahBadanUsaha?.companyName,
                                    "personalMonthlyIncome": nasabahBadanUsaha?.personalMonthlyIncome,
                                    "personalTypeOfWork": nasabahBadanUsaha?.personalTypeOfWork,
                                    "personalJobPosition": nasabahBadanUsaha?.personalJobPosition,
                                    "legalEntity": nasabahBadanUsaha?.legalEntity,
                                    "detailKeterkaitanPep": nasabahBadanUsaha?.keterkaitanPEP,
                                    "statusKeterkaitanPep": nasabahBadanUsaha?.statusKeterkaitanPep,
                                    "statusPep": nasabahBadanUsaha?.statusPep,
                                    "detailPep": nasabahBadanUsaha?.detailPEP,
                                    "kode_sektor_ekonomi": nasabahBadanUsaha?.kode_sektor_ekonomi,
                                    "lokasi_usaha": nasabahBadanUsaha?.lokasi_usaha
                                },
                                "_customerData": {
                                    "birthPlace": nasabahBadanUsaha?.birthPlace,
                                    "personalCitizenship": nasabahBadanUsaha?.personalCitizenship,
                                    "personalCity": nasabahBadanUsaha?.personalCity,
                                    "personalReligion": nasabahBadanUsaha?.personalReligion,
                                    "personalMothersName": nasabahBadanUsaha?.personalMothersName,
                                    "personalFathersName": nasabahBadanUsaha?.personalFathersName,
                                    "personalMaritalStatus": nasabahBadanUsaha?.personalMaritalStatus,
                                    "personalScoringCust": nasabahBadanUsaha?.personalScoringCust,
                                    "customerEducation": nasabahBadanUsaha?.customerEducation,
                                    "customerAliasName": nasabahBadanUsaha?.customerAliasName,
                                    "customerPreTitle": nasabahBadanUsaha?.customerPreTitle,
                                    "customerSpouseSecondIncome": nasabahBadanUsaha?.customerSpouseSecondIncome,
                                    "customerBankRelation": nasabahBadanUsaha?.customerBankRelation,
                                    "customerSpouseName": nasabahBadanUsaha?.customerSpouseName,
                                    "customerSpouseOccupation": nasabahBadanUsaha?.customerSpouseOccupation,
                                    "customerPostTitle": nasabahBadanUsaha?.customerPostTitle,
                                    "customerSpouseMainIncome": nasabahBadanUsaha?.customerSpouseMainIncome,
                                    "customerAliasName2": nasabahBadanUsaha?.customerAliasName2,
                                },
                                "_companyAddress": {
                                    "companyAddressCity": nasabahBadanUsaha?.companyAddressCity,
                                    "companyAddressRt": nasabahBadanUsaha?.companyAddressRt,
                                    "companyAddressRw": nasabahBadanUsaha?.companyAddressRw,
                                    "companyAddressVillage": nasabahBadanUsaha?.companyAddressVillage,
                                    "companyAddress": nasabahBadanUsaha?.companyAddress,
                                    "companyAddressSubDistrict": nasabahBadanUsaha?.companyAddressSubDistrict,
                                    "companyAddressPostalCode": nasabahBadanUsaha?.companyAddressPostalCode,
                                    "companyAddressProvince": nasabahBadanUsaha?.companyAddressProvince,
                                    "companyAddressPhoneNumber": nasabahBadanUsaha?.companyAddressPhoneNumber,
                                    "companyAddressFaxNumber": nasabahBadanUsaha?.companyAddressFaxNumber,
                                },
                                "_ktpAddress": {
                                    "ktpRt": nasabahBadanUsaha?.ktpRt,
                                    "ktpRw": nasabahBadanUsaha?.ktpRw,
                                    "ktpSubDistrict": nasabahBadanUsaha?.ktpSubDistrict,
                                    "ktpVillage": nasabahBadanUsaha?.ktpVillage,
                                    "ktpAddress": nasabahBadanUsaha?.ktpAddress,
                                    "ktpCity": nasabahBadanUsaha?.ktpCity,
                                    "ktpProvince": nasabahBadanUsaha?.ktpProvince,
                                    "ktpPostalCode": nasabahBadanUsaha?.ktpPostalCode,
                                    "ktpCountry": nasabahBadanUsaha?.ktpCountry,
                                },
                                "_relasiAhliWaris": nasabahBadanUsaha._relasiAhliWaris,
                                "_relasiNasabah": nasabahBadanUsaha._relasiNasabah,
                                "_tambahRekeningLain": nasabahBadanUsaha._tambahRekeningLain,
                                "_beneficiaryOwner": {
                                    "benefKecamatan": nasabahBadanUsaha?.benefKecamatan,
                                    "benefNamaPerusahaan": nasabahBadanUsaha?.benefNamaPerusahaan,
                                    "benefRw": nasabahBadanUsaha?.benefRw,
                                    "benefKodePos": nasabahBadanUsaha?.benefKodePos,
                                    "benefProvinsi": nasabahBadanUsaha?.benefProvinsi,
                                    "benefNomorIzinUsaha": nasabahBadanUsaha?.benefNomorIzinUsaha,
                                    "benefBentukUsaha": nasabahBadanUsaha?.benefBentukUsaha,
                                    "benefBidangUsaha": nasabahBadanUsaha?.benefBidangUsaha,
                                    "benefRt": nasabahBadanUsaha?.benefRt,
                                    "benefKeterangan": nasabahBadanUsaha?.benefKeterangan,
                                    "benefSumberDana": nasabahBadanUsaha?.benefSumberDana,
                                    "benefAlamat": nasabahBadanUsaha?.benefAlamat,
                                    "benefKelurahan": nasabahBadanUsaha?.benefKelurahan,
                                    "benefKotaKabupaten": nasabahBadanUsaha?.benefKotaKabupaten,
                                    "benefTujuanPenggunaanDana": nasabahBadanUsaha?.benefTujuanPenggunaanDana,
                                },
                                "_beneficiaryIndividu": {
                                    "benefNama": nasabahBadanUsaha?._beneficiaryIndividu?.benefNama,
                                    "benefGender": nasabahBadanUsaha?._beneficiaryIndividu?.benefGender,
                                    "benefBirthPlace": nasabahBadanUsaha?._beneficiaryIndividu?.benefBirthPlace,
                                    "benefBirthDate": nasabahBadanUsaha?._beneficiaryIndividu?.benefBirthDate,
                                    "benefAgama": nasabahBadanUsaha?._beneficiaryIndividu?.benefAgama,
                                    "benefStatusPerkawinan": nasabahBadanUsaha?._beneficiaryIndividu?.benefStatusPerkawinan,
                                    "benefHomePhone": nasabahBadanUsaha?._beneficiaryIndividu?.benefHomePhone,
                                    "benefMobilePhone": nasabahBadanUsaha?._beneficiaryIndividu?.benefMobilePhone,
                                    "benefAlamat": nasabahBadanUsaha?._beneficiaryIndividu?.benefAlamat,
                                    "benefRw": nasabahBadanUsaha?._beneficiaryIndividu?.benefRw,
                                    "benefRt": nasabahBadanUsaha?._beneficiaryIndividu?.benefRt,
                                    "benefProvinsi": nasabahBadanUsaha?._beneficiaryIndividu?.benefProvinsi,
                                    "benefKecamatan": nasabahBadanUsaha?._beneficiaryIndividu?.benefKecamatan,
                                    "benefKelurahan": nasabahBadanUsaha?._beneficiaryIndividu?.benefKelurahan,
                                    "benefKotaKabupaten": nasabahBadanUsaha?._beneficiaryIndividu?.benefKotaKabupaten,
                                    "benefNasabahKodePos": nasabahBadanUsaha?._beneficiaryIndividu?.benefNasabahKodePos,
                                    "benefAlamatDom": nasabahBadanUsaha?._beneficiaryIndividu?.benefAlamatDom,
                                    "benefRwDom": nasabahBadanUsaha?._beneficiaryIndividu?.benefRwDom,
                                    "benefRtDom": nasabahBadanUsaha?._beneficiaryIndividu?.benefRtDom,
                                    "benefProvinsiDom": nasabahBadanUsaha?._beneficiaryIndividu?.benefProvinsiDom,
                                    "benefKecamatanDom": nasabahBadanUsaha?._beneficiaryIndividu?.benefKecamatanDom,
                                    "benefKelurahanDom": nasabahBadanUsaha?._beneficiaryIndividu?.benefKelurahanDom,
                                    "benefKotaKabupatenDom": nasabahBadanUsaha?._beneficiaryIndividu?.benefKotaKabupatenDom,
                                    "benefKodePosDom": nasabahBadanUsaha?._beneficiaryIndividu?.benefKodePosDom,
                                },
                            }));

                            changePajak(nasabahBadanUsaha?.dataStatusKenaPajak, {
                                "dataNamaKeluarga": nasabahBadanUsaha?.dataNamaKeluarga,
                                "dataHomePhone": nasabahBadanUsaha?.dataHomePhone,
                                // "dataPenghasilanTahun": nasabahBadanUsaha?.dataPenghasilanTahun,
                                "dataPenghasilanTahun": "",
                                "dataTujuanPenggunaDana": nasabahBadanUsaha?.dataTujuanPenggunaDana,
                                "dataGolNasabah": nasabahBadanUsaha?.dataGolNasabah,
                                "dataGolDebitur": nasabahBadanUsaha?.dataGolDebitur,
                                "dataGolPemilikXBRL": nasabahBadanUsaha?.dataGolPemilikXBRL,
                                "dataTujuanGaji": nasabahBadanUsaha?.dataTujuanGaji,
                                "dataTujuanProgramPemerintah": nasabahBadanUsaha?.dataTujuanProgramPemerintah,
                                "dataStatusKenaPajak": nasabahBadanUsaha?.dataStatusKenaPajak,
                                "dataKetBebasPajak": nasabahBadanUsaha?.dataKetBebasPajak,
                                "dataNomorSurat": nasabahBadanUsaha?.dataNomorSurat,
                                "dataTglTerbit": nasabahBadanUsaha?.dataTglTerbit,
                                "dataTglBerakhir": nasabahBadanUsaha?.dataTglBerakhir,
                                "dataSetorTunai": nasabahBadanUsaha?.dataSetorTunai,
                                "dataSetorTunaiFrekuensi": nasabahBadanUsaha?.dataSetorTunaiFrekuensi,
                                "dataSetorNonTunai": nasabahBadanUsaha?.dataSetorNonTunai,
                                "dataSetorNonTunaiFrekuensi": nasabahBadanUsaha?.dataSetorNonTunaiFrekuensi,
                                "dataTarikTunai": nasabahBadanUsaha?.dataTarikTunai,
                                "dataTarikTunaiFrekuensi": nasabahBadanUsaha?.dataTarikTunaiFrekuensi,
                                "dataTarikNonTunai": nasabahBadanUsaha?.dataTarikNonTunai,
                                "dataTarikNonTunaiFrekuensi": nasabahBadanUsaha?.dataTarikNonTunaiFrekuensi,
                                "dataResiko": nasabahBadanUsaha?.dataResiko,
                                "dataStatusResiko": nasabahBadanUsaha?.dataStatusResiko,
                                "dataIdentitasNasabah": nasabahBadanUsaha?.dataIdentitasNasabah,
                                "dataLokasiUsaha": nasabahBadanUsaha?.dataLokasiUsaha,
                                "dataProfilNasabah": nasabahBadanUsaha?.dataProfilNasabah,
                                "dataJumlahTransaksi": nasabahBadanUsaha?.dataJumlahTransaksi,
                                "dataKegiatanUsaha": nasabahBadanUsaha?.dataKegiatanUsaha,
                                "dataStrukturKepemilikan": nasabahBadanUsaha?.dataStrukturKepemilikan,
                                "dataStrukturInformasiLainnya": nasabahBadanUsaha?.dataStrukturInformasiLainnya,
                                "status_tempat_tinggal": nasabahBadanUsaha?.status_tempat_tinggal,
                            })

                            setFormData(prevState => ({ ...prevState, 
                                "_dataTambahan": {
                                    ...prevState._dataTambahan,
                                    "jumlahTanggungan": nasabahBadanUsaha?.jumlahTanggungan,
                                },
                                ...stateBase }));

                            const province_code = nasabahBadanUsaha.companyAddressProvince
                            const province_codedom = nasabahBadanUsaha.mailingAddressProvince
                            const province_codektp = nasabahBadanUsaha.ktpProvince
                            const province_benefAlamat = nasabahBadanUsaha?._beneficiaryIndividu?.benefProvinsi
                            const province_benefAlamatDom = nasabahBadanUsaha?._beneficiaryIndividu?.benefProvinsiDom

                            await Promise.all([
                                getingCities(province_code),
                                getingCitiesDom(province_codedom),
                                getingCitiesKtp(province_codektp),
                                getingCitiesBenfIdentitas(province_benefAlamat),
                                getingCitiesBenfDom(province_benefAlamatDom),
                            ])

                        }
                        // const keys_formData = Object.keys(formData);
                        // keys_formData?.forEach(kFormData => {
                        //     if ((formData[kFormData] instanceof Object) && !Array.isArray(formData[kFormData])) {


                        //         const tKey = Object.keys(formData[kFormData])
                        //         let dataChild = {}
                        //         tKey.forEach(k => {

                        //             let kD = nasabahBadanUsaha[k];
                        //             if (paramUrl.mode === "edit" || paramUrl.mode === "detail") {
                        //                 if (
                        //                     (k === "birthDate") 
                        //                     // ||
                        //                     // (k === "personalIdDateOfIssue") ||
                        //                     // (k === "personalIdExpireDate")
                        //                 ) {
                        //                     kD = moment(nasabahBadanUsaha[k], "DD-MM-YYYY").format("YYYY-MM-DD");
                        //                 }
                        //             }
                        //             dataChild = {
                        //                 ...dataChild,
                        //                 [k]: kD
                        //             }
                        //         })
                        //         stateBase = {
                        //             ...stateBase,
                        //             [kFormData]: dataChild
                        //         }
                        //     } else if (nasabahBadanUsaha[kFormData] !== undefined) {
                        //         let data = nasabahBadanUsaha[kFormData]
                        //         if (paramUrl.mode === "edit" || paramUrl.mode === "detail") {
                        //             if (
                        //                 (kFormData === "birthDate") 
                        //                 // ||
                        //                 // (kFormData === "personalIdDateOfIssue") ||
                        //                 // (kFormData === "personalIdExpireDate")
                        //             ) {
                        //                 data = moment(nasabahBadanUsaha[kFormData], "DD-MM-YYYY").format("YYYY-MM-DD");
                        //                 // // // // console.log(nasabahBadanUsaha[kFormData], data)
                        //             }
                        //         }
                        //         stateBase = { ...stateBase, [kFormData]: data }
                        //     }
                        // });
                        // // // // console.log(data)
                        // setFormData(prevState => ({ ...prevState, ...stateBase }));
                        // const province_code = nasabahBadanUsaha.companyAddressProvince
                        // const province_codedom = nasabahBadanUsaha.mailingAddressProvince
                        // const province_codektp = nasabahBadanUsaha.ktpProvince
                        // const province_benefAlamat = nasabahBadanUsaha?._beneficiaryIndividu?.benefProvinsi
                        // const province_benefAlamatDom = nasabahBadanUsaha?._beneficiaryIndividu?.benefProvinsiDom
                        // // // // // console.log(nasabahBadanUsaha._beneficiaryIndividu.benefProvinsi)
                        // await Promise.all([
                        //     getingCities(province_code),
                        //     // getingDistrict(nasabahBadanUsaha.companyAddressCity),
                        //     // getingVillages(nasabahBadanUsaha.companyAddressCity, nasabahBadanUsaha.companyAddressSubDistrict),
                        //     // getingPostalCodes(nasabahBadanUsaha.companyAddressCity, nasabahBadanUsaha.companyAddressSubDistrict, nasabahBadanUsaha.companyAddressVillage),
                        //     getingCitiesDom(province_codedom),
                        //     // getingDistrictDom(nasabahBadanUsaha.mailingAddressCity),
                        //     // getingVillagesDom(nasabahBadanUsaha.mailingAddressCity, nasabahBadanUsaha.mailingAddressSubDistrict),
                        //     // getingPostalCodesDom(nasabahBadanUsaha.mailingAddressCity, nasabahBadanUsaha.mailingAddressSubDistrict, nasabahBadanUsaha.mailingAddressVillage),
                        //     getingCitiesKtp(province_codektp),
                        //     // getingDistrictKtp(nasabahBadanUsaha.ktpCity),
                        //     // getingVillagesKtp(nasabahBadanUsaha.ktpCity, nasabahBadanUsaha.ktpSubDistrict),
                        //     // getingPostalCodesKtp(nasabahBadanUsaha.ktpCity, nasabahBadanUsaha.ktpSubDistrict, nasabahBadanUsaha.ktpVillage),
                        //     getingCitiesBenfIdentitas(province_benefAlamat),
                        //     // getingDistrictBenfIdentitas(nasabahBadanUsaha.benefKotaKabupaten),
                        //     // getingVillagesBenfIdentitas(nasabahBadanUsaha.benefKotaKabupaten, nasabahBadanUsaha.benefKecamatan),
                        //     // getingPostalCodesBenfIdentitas(nasabahBadanUsaha.benefKotaKabupaten, nasabahBadanUsaha.benefKecamatan, nasabahBadanUsaha.benefKelurahan),
                        //     getingCitiesBenfDom(province_benefAlamatDom),
                        //     // getingDistrictBenfDom(nasabahBadanUsaha.benefKotaKabupatenDom),
                        //     // getingVillagesBenfDom(nasabahBadanUsaha.benefKotaKabupatenDom, nasabahBadanUsaha.benefKecamatanDom),
                        //     // getingPostalCodesBenfDom(nasabahBadanUsaha.benefKotaKabupatenDom, nasabahBadanUsaha.benefKecamatanDom, nasabahBadanUsaha.benefKelurahanDom),
                        // ])
                    };

                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => history.goBack() });
            } else setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => history.goBack() });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => history.goBack() });
        }
        setLoading(prevState => ({ ...prevState, loading: false }))
    }

    // close Alert
    function closeAlert() { setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null }) }

    // closeConfirm
    function closeConfirm() { setDataConfirm({ show: false, body: "", header: null, title: "", footerButton: [], closeButton: true, witTextArea: false }) }

    // close Loading
    function closeLoading() { setLoading(prevState => ({ getData: false, loadingPost: { msg: "Please Wait ...", show: false } })) }
    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} nasabah ini ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // fetch API response 
    async function fetchOtorisasi(statusApprove) {
        setLoading(prevState => ({ ...prevState, loadingPost: { show: true, msg: "Please Wait ..." } }));
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (locationState !== null && locationState !== undefined) {
            try {
                const { statusCode, message = "", remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${locationState.kode_entry}/${locationState.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                });
                if (statusCode === 200) {
                    let ketSuccess = data?.nomor_nasabah || "";
                    if (status === "00") setDataAlert({ show: true, body: `${locationState.kode_entry === 'CIF001' ? "Registrasi":"Update"} nasabah berhasil, nomor nasabah: ${ketSuccess}`, title: ``, header: "Success", callBack: () => history.goBack() });
                    else setDataAlert({ show: true, body: remark, title: '', header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });

        setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        if (typeof value === "string") value = value.toUpperCase()
        if (subkey !== null) {
            setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }));
            if (key === "personalIdNumber" || key === "personalIdHolderName" || key === "birthDate") {
                setIsValid(false)
            }
        }
        else {
            setFormData(prevState => ({ ...prevState, [key]: value }));
            if (key === "personalIdNumber" || key === "personalIdHolderName" || key === "birthDate") {
                setIsValid(false)
            }
        }
    }

    function changeKondisi(value, key, subkey = null) {
        if (subkey !== null) {
            setCekKondisi(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }));
        }
        else {
            setCekKondisi(prevState => ({ ...prevState, [key]: value }));
        }
        if (key == 'memiliki_npwp' && !value) {
            changeForm("", "personalNpwpNumber", "_personalIdData")
        }
    }

    function changeStatusPEP(value, key, subkey = null) {
        if (subkey !== null) {
            setStatusPEP(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }));
        }
        else {
            setStatusPEP(prevState => ({ ...prevState, [key]: value }));
        }
        if (key == 'statusPep' && !value) {
            changeForm("", "statusKeterkaitanPep", "_occupationInfo")
        }
    }

    function changeKeterkaitanPEP(value, key, subkey = null) {
        if (subkey !== null) {
            setKeterkaitanPEP(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }));
        }
        else {
            setKeterkaitanPEP(prevState => ({ ...prevState, [key]: value }));
        }
        if (key == 'detailPep' && !value) {
            changeForm("", "detailKeterkaitanPep", "_occupationInfo")
        }
    }

    // fungsi cek nasabah 
    async function cekNasabah() {
        if (formData._personalIdData.personalIdNumber === '') {
            return setDataAlert({ show: true, body: "Nomor Identitas belum terisi. Silahkan isi terlebih dahulu lalu klik Button Cek Data Nasabah", title: `Data Validasi belum terisi`, header: "Warning", callBack: () => null });
        }
        if (formData?._personalIdData?.personalIdHolderName === '') {
            return setDataAlert({ show: true, body: "Nama (Sesuai Identitas) belum terisi. Silahkan isi terlebih dahulu lalu klik Button Cek Data Nasabah", title: `Data Validasi belum terisi`, header: "Warning", callBack: () => null });
        }
        if (formData.birthDate === '' || formData.birthDate === null) {
            return setDataAlert({ show: true, body: "Tanggal Lahir belum terisi. Silahkan isi terlebih dahulu lalu klik Button Cek Data Nasabah", title: `Data Validasi belum terisi`, header: "Warning", callBack: () => null });
        }

        await setLoading(prevState => ({ ...prevState, loadingPost: { msg: "Please Wait ...", show: true } }));
        const bodyData = {
            "identity_number": formData._personalIdData.personalIdNumber,
            "customer_name": formData?._personalIdData?.personalIdHolderName,
            "customer_birthdate": moment(formData.birthDate).format("DD-MM-YYYY"),
            // "mother_name": formData._customerData.personalMothersName
        }
        try {

            const userInfo = await keycloak.loadUserInfo();
            const resp = await doPost({
                data: bodyData,
                url: "/nasabah/validasi",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
            });
            const { statusCode, status, statusText, data, remark } = resp;
            let bodyAlert = `Nasabah belum terdaftar. Proses ${paramUrl.mode === 'registration' ? 'registrasi' : 'ubah'} bisa dilanjutkan`;

            if (status === "Failed") {
                const { cif_number = "", identity_number = "", customer_name = "", customer_birthdate = "", mother_name = "" } = data?.validasiIndividu || {}
                bodyAlert = (
                    <div className="row">
                        <div className="col">
                            <p>Nasabah sudah terdaftar dengan nomor nasabah <b>{cif_number}</b></p>
                            <p>Dengan Data Berikut :</p>
                            <ul>
                                <li> Nama Lengkap : <b>{customer_name}</b></li>
                                <li> Tanggal Lahir : <b> {customer_birthdate !== "" ? customer_birthdate : ""} </b></li>
                                <li> Nomor Identitas : <b>{identity_number}</b></li>
                            </ul>
                            <br />
                            <p>Silahkan gunakan nomor nasabah yang sudah terdaftar <b>({cif_number})</b></p>
                        </div>
                    </div>
                )
                setIsValid(false)
            } else {
                setIsValid(true)
            }
            setDataAlert({
                show: true,
                header: "Konfirmasi",
                body: bodyAlert,
                title: ""
            })
        } catch (error) {
            setDataAlert({
                show: true,
                header: "Error",
                body: "Terjadi Kesalahan",
                title: ""

            })
        }
        closeLoading();
    }
    //  fungsi validasi
    function validasi(fieldRequired = []) {

        //  field required lists
        if (fieldRequired.length === 0)
            fieldRequired = [
                "firstName", "personalIdType", "personalIdNumber",
                // "personalIdDateOfIssue",
                // "personalIdExpireDate",  
                "personalMarketingOfficer",
                "birthDate", "birthPlace", "personalMothersName", "personalFathersName",
                "personalMaritalStatus", "personalCitizenship", "companyAddress", "ktpAddress",
                "ktpProvince", "ktpCity", "ktpSubDistrict", "ktpVillage", "pejabatJabatan",
                "lamaTahunTinggal", "lamaBulanTinggal"
                // "_relasiNasabah"
            ];

        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                if ((Array.isArray(formData[key])) && (fieldRequired.some(v => v === key))) {
                    // // // console.log(formData[key], key)
                    if (formData[key].length <= 0) errS = true;
                } else {
                    const tKey = Object.keys(formData[key]);
                    tKey.forEach(element => {
                        if (fieldRequired.some(v => element === v)) {
                            if (formData[key][element] === "" || formData[key][element] === undefined || formData[key][element] === null) {
                                errS = true
                                // // // console.log("periksa kembali ", key, " ", element, " ", formData[key][element])
                            } else if (Array.isArray(formData[key][element])) {

                                if (formData[key][element].length <= 0) errS = true;
                            }
                        }
                    });
                }
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || formData[key] === undefined || formData[key] === null) {
                        errS = true
                        // // // console.log("periksa kembali", key, " ", formData[key])
                    }
                }
            }
        })

        return errS;
    }

    // confirm Post Nasabah
    function confirmPostNasabah() {
        let formType = paramUrl.mode === "edit" || paramUrl.mode === "editwic" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan ${formType}, cek kembali form ${formType}. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => paramUrl.mode === "edit" || paramUrl.mode === "editwic" ? updateNasabah() : postNasabah(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // fungsi post nasabah
    async function postNasabah() {
        // // // console.log(formData)
        const userInfo = await keycloak.loadUserInfo();
        const err = validasi();
        if (err) {
            closeConfirm()
            return setDataAlert({ show: true, body: "Periksa kembali form pendaftaran, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        setLoading(prevState => ({ ...prevState, loadingPost: { msg: "Please Wait ...", show: true } }));
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                // data: formData,
                data: {
                    ...formData,
                    uid:keycloak.uid,
                },
                url: "/nasabah"
            });
            // // // // console.log(data);
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Registrasi Nasabah Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => history.goBack() });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }

    // fungsi put nasabah
    async function updateNasabah() {
        // //  // console.log(formData)
        
        const err = validasi();
        if (err) {
            closeConfirm()
            return setDataAlert({ show: true, body: "Periksa kembali form ubah Data, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        setLoading(prevState => ({ ...prevState, loadingPost: { msg: "Please Wait ...", show: true } }));
        let url = `/nasabah?db_id=${locationState.dbId}`;
        if ( paramUrl.mode === "editwic" ) url = `/nasabah/ubah-wic-to-nasabah?db_id=${locationState.dbId}`;
        try {
            const userInfo = await keycloak.loadUserInfo();
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: formData,
                url,
                method: "PUT"
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Ubah Nasabah Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => history.goBack() });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }

    // get province_code
    async function getProvinceCode(province_name) {
        if (provinces !== null && provinces !== undefined) {
            const dataP = provinces?.find(v => v.province_name === province_name);
            return dataP?.province_code || "";
        }
    }


    // get cities 1
    async function getingCities(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCities(data);
    }

    // // get districts 1
    // async function getingDistrict(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistricts(newData);
    // }
    // // get village 1
    // async function getingVillages(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillages(newData);
    // }
    // // get postalcode 1
    // async function getingPostalCodes(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodes(newData);
    // }

    // get cities KTP
    async function getingCitiesKtp(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCitiesKtp(data);
    }

    // // get districts KTP
    // async function getingDistrictKtp(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistrictsKtp(newData);
    // }
    // // get village KTP
    // async function getingVillagesKtp(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillagesKtp(newData);
    // }
    // // get postalcode KTP
    // async function getingPostalCodesKtp(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodesKtp(newData);
    // }

    // get cities Domisili
    async function getingCitiesDom(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCitiesDom(data);
    }

    // // get districts Domisili
    // async function getingDistrictDom(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistrictsDom(newData);
    // }
    // // get village Domisili
    // async function getingVillagesDom(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillagesDom(newData);
    // }
    // // get postalcode Domisili
    // async function getingPostalCodesDom(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodesDom(newData);
    // }

    // get cities Beneficiary Identitas
    async function getingCitiesBenfIdentitas(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCitiesBenfIdentitas(data);
    }

    // // get districts Beneficiary Identitas
    // async function getingDistrictBenfIdentitas(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistrictsBenfIdentitas(newData);
    // }
    // // get village Beneficiary Identitas
    // async function getingVillagesBenfIdentitas(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillagesBenfIdentitas(newData);
    // }
    // // get postalcode Beneficiary Identitas
    // async function getingPostalCodesBenfIdentitas(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodesBenfIdentitas(newData);
    // }

    // get cities Beneficiary Domisili
    async function getingCitiesBenfDom(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCitiesBenfDom(data);
    }

    // // get districts Beneficiary Domisili
    // async function getingDistrictBenfDom(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistrictsBenfDom(newData);
    // }
    // // get village Beneficiary Domisili
    // async function getingVillagesBenfDom(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillagesBenfDom(newData);
    // }
    // // get postalcode Beneficiary Domisili
    // async function getingPostalCodesBenfDom(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodesBenfDom(newData);
    // }

    // salin data alamat domisili
    async function salinDataAlamatDomisili() {
        // if(formData._ktpAddress.ktpProvince !=)
        const provcode = formData._ktpAddress.ktpProvince
        await (
            Promise.all([
                getingCitiesDom(provcode),
                // getingDistrictDom(formData._ktpAddress.ktpCity),
                // getingVillagesDom(formData._ktpAddress.ktpCity, formData._ktpAddress.ktpSubDistrict),

                // getingPostalCodesDom(formData._ktpAddress.ktpCity, formData._ktpAddress.ktpSubDistrict, formData._ktpAddress.ktpVillage)
            ])
        )

        changeForm({
            ...formData._ktpAddress,
            "mailingAddressPostalCode": formData._ktpAddress.ktpPostalCode,
            "mailingAddress": formData._ktpAddress.ktpAddress,
            "mailingAddressVillage": formData._ktpAddress.ktpVillage,
            "mailingAddressProvince": formData._ktpAddress.ktpProvince,
            "mailingAddressRt": formData._ktpAddress.ktpRt,
            "mailingAddressRw": formData._ktpAddress.ktpRw,
            "mailingAddressSubDistrict": formData._ktpAddress.ktpSubDistrict,
            "mailingAddressCity": formData._ktpAddress.ktpCity,
        }, "_mailingAddress")

    }

    // salin data beneficary owner
    async function salinAlamatBen() {
        const provcode = formData._ktpAddress.ktpProvince
        await (
            Promise.all([
                getingCitiesBenfDom(provcode),
                // getingDistrictBenfDom(formData._ktpAddress.ktpCity),
                // getingVillagesBenfDom(formData._ktpAddress.ktpCity, formData._ktpAddress.ktpSubDistrict),
                // getingPostalCodesBenfDom(formData._ktpAddress.ktpCity, formData._ktpAddress.ktpSubDistrict, formData._ktpAddress.ktpVillage)

            ])
        )

        changeForm({
            ...formData._beneficiaryIndividu,
            "benefKodePosDom": formData._ktpAddress.ktpPostalCode,
            "benefAlamatDom": formData._ktpAddress.ktpAddress,
            "benefKelurahanDom": formData._ktpAddress.ktpVillage,
            "benefProvinsiDom": formData._ktpAddress.ktpProvince,
            "benefRtDom": formData._ktpAddress.ktpRt,
            "benefRwDom": formData._ktpAddress.ktpRw,
            "benefKecamatanDom": formData._ktpAddress.ktpSubDistrict,
            "benefKotaKabupatenDom": formData._ktpAddress.ktpCity,
        }, "_beneficiaryIndividu")
    }

    //faktor resiko state
    const [formResiko, setFormResiko] = useState({
        identitasMasalah: "L",
        lokasiUsaha: "L",
        profileNasabah: "L",
        jumlahTrx: "L",
        kegiatanUsaha: "L",
        strukturKepemilikan: "L",
        informasiLainnya: "L"
    });

    // fungsiFaktorresiko
    useEffect(function () {
        const keys = Object.keys(formResiko);
        let average = 1;

        // keys.forEach(k => {
        //     total += parseInt(formResiko[k]);
        // });
        let dataResiko = [
            null,
            "L",
            "M",
            "H"
        ];
        let dr = {
            L: 1,
            M: 2,
            H: 3
        }

        average = Math.max.apply(Math, keys.map(x => parseInt(dr[formResiko[x]])))
        changeForm(dataResiko[average], "dataResiko", "_dataTambahan")
    }, [formResiko])


    //  get data Mekerting
    async function getDataMarketing() {
        try {
            const { data, statusCode, status, remark, statusText } = await doGet({
                // url: `/produk-deposit/${kat}`,
                url: `/account-officer`,
                service: "cif"
            });
            if (statusCode === 200) {
                if (status === "00") setDataMarketing(data?.list)
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data produk`, header: "Error", callBack: () => null });
        }

    }

    const [statusPajak, setStatusPajak] = useState({
        "Aa": false,
        "Ba": false,
        "Ca": false,

    })

    function changePajak(value, dataTambahan = null) {
        if (dataTambahan === null) {
            dataTambahan = formData._dataTambahan
        }
        if (value === "0") {
            // // // // console.log(value)

            setStatusPajak({
                "Aa": false,
                "Ba": false,
                "Ca": false,
            })


            changeForm({
                ...dataTambahan,
                "dataStatusKenaPajak": "0",
                "dataKetBebasPajak": "",
                "dataNomorSurat": "",
                "dataTglTerbit": "",
                "dataTglBerakhir": "",
            }, "_dataTambahan")

        } else if (value === "1") {
            setStatusPajak({
                "Aa": true,
                "Ba": true,
                "Ca": true,
            })

            // changeForm({
            //     ...formData._dataTambahan,
            //     "dataStatusKenaPajak":"1",
            //     // "dataKetBebasPajak":"" ,
            //     // "dataNomorSurat":"" ,
            //     // "dataTglTerbit": "",
            //     // "dataTglBerakhir":"" ,
            // }, "_dataTambahan")

        } else if (value === "2") {
            setStatusPajak({
                "Aa": true,
                "Ba": true,
                "Ca": false,
            })

            changeForm({
                ...dataTambahan,
                "dataStatusKenaPajak": "2",
                // "dataKetBebasPajak":"" ,
                // "dataNomorSurat":"" ,
                "dataTglTerbit": "",
                "dataTglBerakhir": "",
            }, "_dataTambahan")
        }
    }


    function resetForm() {
        setFormData(
            {
                "encodedKey": "8a8e869678ce17110178ce3be6cb005a",
                "id": "", //Nomor CIF 
                "state": "",
                "firstName": "",//Nama Lengkap
                "middleName": "",
                "lastName": " ",
                "preferredLanguage": "ENGLISH",
                "notes": "",//Keterangan
                "gender": "MALE", //Jenis Kelamin
                "homePhone": "",//Telepon Rumah 1
                "homeFax": "",//fax ALAMAT IDENTITAS
                "mobilePhone": "",//No Handphone
                "mobilePhone2": "",
                "emailAddress": "",//Alamat Email
                "birthDate": "",//birthDate
                "assignedBranchKey": "8a8e8fab786e635c0178863b7911431e",
                "nasabah_prioritas": "F",
                "nominal_penghasilan" : 0,
                "_personalIdData": {
                    // "personalIdDateOfIssue": moment(Date.now()).format("YYYY-MM-DD"),
                    "urlImageSelfie": "",
                    "personalMarketingOfficer": "",//Marketing
                    "personalReferralCode": "",//Referal Nasabah
                    // "personalIdExpireDate": moment(Date.now()).format("YYYY-MM-DD"),
                    "personalIdNumber": "",//Nomor Identitas
                    "personalNpwpNumber": "",//NPWP
                    "personalIdType": "",//Jenis Identitas
                    "populationType": "",//Jenis Penduduk
                    "urlImageKtp": "",
                    "personalIdHolderName": "",//Nama (Sesuai Identitas)
                    "personalIdIssuedPlace": "",
                    "nasabah_prioritas": "T",
                },
                "_mailingAddress": {
                    "mailingAddressPostalCode": "",//kode pos
                    "mailingRecipientPhoneNumber": "",
                    "mailingAddress": "",//alamat
                    "mailingAddressCity": "", //kota
                    "mailingAddressVillage": "",//kelurahan
                    "mailingAddressProvince": "",//pro
                    "mailingAddressRtRw": "",
                    "mailingAddressRt": "",//rt
                    "mailingAddressRw": "",//rw
                    "mailingAddressSubDistrict": "",//kecmatan
                    "lamaBulanTinggal": "",//lama tinggal
                    "lamaTahunTinggal": "",// lama tinggal
                    "AlamatLuarNegeri": "",// Alamat Luar Negeri
                },
                "_occupationInfo": {
                    "personalOccupation": "",//Pekerjaan
                    "companyName": "",//Nama Perusahaan
                    "personalMonthlyIncome": "",
                    "personalTypeOfWork": "",//Sektor Ekonomi
                    "personalJobPosition": "",//Jabatan Pekerjaan
                    "legalEntity": "", //Badan Hukum
                    "detailKeterkaitanPep": "",
                    "statusKeterkaitanPep": "",
                    "statusPep": "",
                    "detailPep": "",
                    "kode_sektor_ekonomi": "",// Sekktor Ekonomi
                    "lokasi_usaha": ""
                },
                "_customerData": {
                    "birthPlace": "",//Tempat/Tanggal Lahir
                    "personalCitizenship": "",//Kewarganegaraan
                    "personalCity": "",//baru
                    "personalReligion": "",//Personal Religion
                    "personalMothersName": "",//Ibu Kandung
                    "personalMaritalStatus": "",//Status Pernikahan
                    "personalScoringCust": "",
                    "customerEducation": "",//Pendidikan Terakhir
                    "customerAliasName": "",//Nama Alias 1
                    "customerPreTitle": "",//Gelar Depan
                    "customerSpouseSecondIncome": "",
                    "customerBankRelation": "",//Status Keterkaitan
                    "customerSpouseName": "",
                    "customerSpouseOccupation": "",
                    "customerPostTitle": "",//Gelar Belakang
                    "customerSpouseMainIncome": "",
                    "customerAliasName2": ""//Alias 2
                },
                "_companyAddress": {
                    "companyAddressCity": "",//Kota/Kab
                    "companyAddressRt": "",//rt
                    "companyAddressRw": "",//rw
                    "companyAddressVillage": "",//Kelurahan
                    "companyAddress": "",//Alamat
                    "companyAddressSubDistrict": "",//Kecamatan
                    "companyAddressPostalCode": "",//kode pos
                    "companyAddressProvince": "",//Provinsi
                    "companyAddressPhoneNumber": "",//Telepon
                    "companyAddressFaxNumber": ""//Fax  companyAddressFaxNumber
                },
                "_ktpAddress": {
                    "ktpRt": "",//rt
                    "ktpRw": "",//rw
                    "ktpSubDistrict": "",//kecamatan
                    "ktpVillage": "",//kelurahan
                    "ktpAddress": "",//alamat
                    "ktpCity": "",//kota
                    "ktpProvince": "",//Pro
                    "ktpPostalCode": "",//Kode pos
                    "ktpCountry": ""//Negara Asal
                },
                "_relasiNasabah": [],
                "_relasiAhliWaris": [],
                "_tambahRekeningLain": [],
                "_beneficiaryOwner": {
                    "benefKecamatan": "",
                    "benefNamaPerusahaan": "",
                    "benefRw": "",
                    "benefKodePos": "",
                    "benefProvinsi": "",
                    "benefNomorIzinUsaha": "",
                    "benefBentukUsaha": "",
                    "benefBidangUsaha": "",
                    "benefRt": "",
                    "benefKeterangan": "",
                    "benefSumberDana": "",
                    "benefAlamat": "",
                    "benefKelurahan": "",
                    "benefKotaKabupaten": "",
                    "benefTujuanPenggunaanDana": ""
                },
                "_beneficiaryIndividu": {
                    "benefNama": "",//Nama Lengkap
                    "benefBirthPlace": "",//Tempat/Tanggal Lahir
                    "benefBirthDate": "",//Tempat/Tanggal Lahir
                    "benefAgama": "",//Agaman
                    "benefStatusPerkawinan": "",//Status Perkawinan
                    "benefHomePhone": "", //Telepon
                    "benefMobilePhone": "",//Handphone
                    "benefAlamat": "",
                    "benefRw": "",
                    "benefRt": "",
                    "benefProvinsi": "",
                    "benefKecamatan": "",
                    "benefKelurahan": "",
                    "benefKotaKabupaten": "",
                    "benefNasabahKodePos": "",
                    "benefAlamatDom": "",
                    "benefRwDom": "",
                    "benefRtDom": "",
                    "benefProvinsiDom": "",
                    "benefKecamatanDom": "",
                    "benefKelurahanDom": "",
                    "benefKotaKabupatenDom": "",
                    "benefKodePosDom": ""
                },
                "_dataTambahan": {
                    "dataNamaKeluarga": "",//NAMA Keluarga yang dapat dihubungi
                    "dataHomePhone": "",//Telepon/Hp Keluarga yg dapat dihubungi
                    "dataPenghasilanTahun": "",//Penghasilan utama pertahun
                    "dataTujuanPenggunaDana": "",//Tujuan penggunaan dana
                    "dataGolNasabah": "",//Golongan Nasabah
                    "dataGolDebitur": "",
                    "dataGolPemilikXBRL": "",
                    "dataTujuanGaji": "",//Tujuan pembukaan rekening hanya untuk penerimaan gaji
                    "dataTujuanProgramPemerintah": "",//Tujuan pembukaan rekening terkait program pemerintah
                    "dataStatusKenaPajak": "",//Status Kena Pajak
                    "dataKetBebasPajak": "",//Keterangan Bebas Pajak
                    "dataNomorSurat": "",//Nomor Surat
                    "dataTglTerbit": "",//tgl terbit
                    "dataTglBerakhir": "",//tgl berakhir
                    "dataSetorTunai": 0,//Nominal Setor Tunai
                    "dataSetorTunaiFrekuensi": 0,//Frekuensi
                    "dataSetorNonTunai": 0,//Nominal Setor NON Tunai
                    "dataSetorNonTunaiFrekuensi": 0,//frekuensi
                    "dataTarikTunai": 0,//Nominal Tarik Tunai
                    "dataTarikTunaiFrekuensi": 0,//fre
                    "dataTarikNonTunai": 0,//Nominal Tarik NOn Tunai
                    "dataTarikNonTunaiFrekuensi": 0,//fre
                    "dataResiko": "L",//Status Resiko
                    "dataStatusResiko": "L", //
                    "dataIdentitasNasabah": "L", //
                    "dataLokasiUsaha": "L", //
                    "dataProfilNasabah": "L", //
                    "dataJumlahTransaksi": "L", //
                    "dataKegiatanUsaha": "L",
                    "dataStrukturKepemilikan": "L",
                    "dataStrukturInformasiLainnya": "L",//
                    "status_tempat_tinggal": "",//Status Tempat Tinggal
                    "jumlahTanggungan": 0
                }
            }
        )
    }

    function checkHP(value, callback = () => null) {
        if (value.length < 10) {
            setDataAlert({ show: true, body: `No Handphone minimal 10 digit`, title: `Cek Kembali No HandPhone`, header: "INFO", callBack: () => callback() });
        } else {
            changeForm(value, "mobilePhone")
        }
    }

    function checkHP1(value, callback = () => null) {
        if (value.length < 10) {
            setDataAlert({ show: true, body: `No Handphone minimal 10 digit`, title: `Cek Kembali No HandPhone`, header: "INFO", callBack: () => callback() });
        } else {
            changeForm(value, "benefMobilePhone", "_beneficiaryIndividu")
        }
    }
    function checkNamaPerusahaan(value, callback = () => null) {
        if (value.length > 32) {
            setDataAlert({ show: true, body: `Nama Perusahaan maksimal 32 karakter`, title: `Cek Kembali Nama Perusahaan`, header: "INFO", callBack: () => callback() });
        } else {
            changeForm(value, "companyName", "_occupationInfo")
        }
    }

    // fungsi setDataForm
    function changeMailForm(value, key, subkey = null) {
        if (typeof value === "string")
            if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
            else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    //Post Auditrail
    async function auditTrail() {
        const userInfo = await keycloak.loadUserInfo();
        // // // console.log(userInfo)
        var url = `/useraudit/user-audit-trail`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: {
                    user_id: userInfo.preferred_username,
                    app_id: "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Registrasi",
                    event_description: "Registrasi Nasabah Perorangan",
                    info1: "-",
                    info2: "-"
                },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

    }

    
    async function cekKtp(no_ktp) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        modalAction.openLoading("Checking Identity No ...")
        try {
            const nik_length = formData?._personalIdData?.personalIdNumber?.length
            const nik_write = formData?._personalIdData?.personalIdNumber
            let text = nik_write;
            let result = text.substring(13, 16);
            let resultA = text.substring(0, 2);
            if (resultA !== "11" && resultA !== "12" && resultA !== "13" && resultA !== "14" && resultA !== "15" && resultA !== "16" && resultA !== "17" && resultA !== "18" && resultA !== "19" && resultA !== "21" && resultA !== "31" && resultA !== "32" && resultA !== "33" && resultA !== "34" && resultA !== "35" && 
                resultA !== "36" && resultA !== "51" && resultA !== "52" && resultA !== "53" && resultA !== "61" && resultA !== "62" && resultA !== "63" && resultA !== "64" && resultA !== "65" && resultA !== "71" && resultA !== "72" && resultA !== "73" && resultA !== "74" && resultA !== "75" && resultA !== "76" && resultA !== "81" && resultA !== "82" && resultA !== "91" && resultA !== "92"){
                return setDataAlert({ show: true, body: "NIK tidak valid, Silahkan cek kembali", title: `Data Belum Valid`, header: "Warning", callBack: () => {
                    modalAction.closeLoading();
                    changeForm("", "personalIdNumber", "_personalIdData");
                }});
            }
            if (result === "000") {
                return setDataAlert({ show: true, body: "3 digit terakhir tidak boleh '000', Silahkan cek kembali", title: `Data Belum Valid`, header: "Warning", callBack: () => {
                    modalAction.closeLoading();
                    changeForm("", "personalIdNumber", "_personalIdData");
                }});
            }
            if (formData?._personalIdData?.personalIdType === '1' && nik_length !== 16) {
                return setDataAlert({ show: true, body: "NIK KTP Harus 16 Digit. Silahkan cek kembali", title: `Data Belum Valid`, header: "Warning", callBack: () => {
                    modalAction.closeLoading();
                    changeForm("", "personalIdNumber", "_personalIdData");
                }});
            }
            const resp = await doGet({
                // data: bodyData,
                url: `/nasabah/validasi-nik/${no_ktp}`,
                service: "cif"
            });
           
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {

                if (status === "00") {
                }
                else setDataAlert({ show: true, body: remark, header: "INFO", callBack: () => changeForm("", "personalIdNumber", "_personalIdData")});
            } else setDataAlert({ show: true, body: remark, header: "Error", callBack: () => changeForm("", "personalIdNumber", "_personalIdData")});
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => changeForm("", "personalIdNumber", "_personalIdData")});
        }
        modalAction.closeLoading();
    }
    function kodePos1(value, callback = () => null) {
        if (value.length < 5) {
            setDataAlert({ show: true, body: `Kode Pos minimal 5 digit`, title: `Cek Kembali Kode Pos`, header: "INFO", callBack: () => callback() });
        } else {
            changeForm(value, "companyAddressPostalCode", "_companyAddress")
        }
    }
    function kodePos2(value, callback = () => null) {
        if (value.length < 5) {
            setDataAlert({ show: true, body: `Kode Pos minimal 5 digit`, title: `Cek Kembali Kode Pos`, header: "INFO", callBack: () => callback() });
        } else {
            changeForm(value, "ktpPostalCode", "_ktpAddress")
        }
    }
    function kodePos3(value, callback = () => null) {
        if (value.length < 5) {
            setDataAlert({ show: true, body: `Kode Pos minimal 5 digit`, title: `Cek Kembali Kode Pos`, header: "INFO", callBack: () => callback() });
        } else {
            changeForm(value, "mailingAddressPostalCode","_mailingAddress")
        }
    }
    function kodePos4(value, callback = () => null) {
        if (value.length < 5) {
            setDataAlert({ show: true, body: `Kode Pos minimal 5 digit`, title: `Cek Kembali Kode Pos`, header: "INFO", callBack: () => callback() });
        } else {
            changeForm(value, "benefNasabahKodePos","_beneficiaryIndividu")
        }
    }
    function kodePos5(value, callback = () => null) {
        if (value.length < 5) {
            setDataAlert({ show: true, body: `Kode Pos minimal 5 digit`, title: `Cek Kembali Kode Pos`, header: "INFO", callBack: () => callback() });
        } else {
            changeForm(value, "benefKodePosDom","_beneficiaryIndividu")
        }
    }
    return <fpContext.Provider value={{
        fpState: {
            dataMarketing,
            statusPajak,
            paramUrl,
            step,
            formEditable,
            loading,
            dataAlert,
            formData,
            locationState,
            dataConfirm,
            otorDesc,
            dataDropDown,
            provinces,
            districts,
            villages,
            postalCodes,
            cities,
            districtsKtp,
            villagesKtp,
            postalCodesKtp,
            citiesKtp,
            districtsDom,
            villagesDom,
            postalCodesDom,
            citiesDom,
            districtsBenfIdentitas,
            villagesBenfIdentitas,
            postalCodesBenfIdentitas,
            citiesBenfIdentitas,
            districtsBenfDom,
            villagesBenfDom,
            postalCodesBenfDom,
            citiesBenfDom,
            isValid,
            cekKondisi,
            formResiko,
            dataMarketing,
            statusPEP,
            keterkaitanPEP,
            podDate
        },
        fpAction: {
            clickStep,
            cekKtp,
            nextStep,
            prevState,
            closeAlert,
            closeConfirm,
            responseOtor,
            setOtorDesc,
            changeForm,
            cekNasabah,
            validasi,
            confirmPostNasabah,
            getingCities,
            // getingDistrict,
            // getingPostalCodes,
            // getingVillages,
            getingCitiesKtp,
            // getingDistrictKtp,
            // getingPostalCodesKtp,
            // getingVillagesKtp,
            getingCitiesDom,
            // getingDistrictDom,
            // getingPostalCodesDom,
            // getingVillagesDom,
            getingCitiesBenfIdentitas,
            // getingDistrictBenfIdentitas,
            // getingPostalCodesBenfIdentitas,
            // getingVillagesBenfIdentitas,
            getingCitiesBenfDom,
            // getingDistrictBenfDom,
            // getingPostalCodesBenfDom,
            // getingVillagesBenfDom,
            changeKondisi,
            salinDataAlamatDomisili,
            setFormResiko,
            getDataMarketing,
            salinAlamatBen,
            changeStatusPEP,
            changeKeterkaitanPEP,
            changePajak,
            setDataAlert,
            setLoading,
            handleShortcut,
            handleShiftUp,
            handleShiftDown,
            resetForm,
            checkHP,
            changeMailForm,
            checkHP1,
            auditTrail,
            checkNamaPerusahaan,
            kodePos1,
            kodePos2,
            kodePos3,
            kodePos4,
            kodePos5,
            setIsWIC
        }
    }}>
        {children}
    </fpContext.Provider>
}

export default function useFormPerorangan() {
    return useContext(fpContext)
};