import { Button, Label } from 'semantic-ui-react';
import { Modal, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { baseUrl } from '../../../../../app.config';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const ModalInfoNegatifNasabah = ({ onHide, show, form }) => {
    const [error, setError] = useState({});

    const handleHide = () => {
        if (onHide) onHide();
        setError({});
    };

    // console.log(form, 'modal');
    return (
        <>
            <Modal centered show={show}>
                <Modal.Header className="tw-px-6 tw-py-5" onHide={handleHide} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Info Negatif Nasabah</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <Form id="registerForm">
                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-my-4">
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Nama Lengkap
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    id="nama_lengkap"
                                    size="sm"
                                    placeholder="Masukkan nama lengkap"
                                    value={form?.nama_lengkap}
                                />
                            </Form.Group>
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Jenis Nasabah
                                </Form.Label>
                                <Form.Control
                                    id="jenis_nasabah"
                                    as="select"
                                    size="sm"
                                    placeholder="Pilih jenis nasabah"
                                    disabled
                                    value="GROUP"
                                >
                                    <option value="" selected hidden disabled>
                                        Pilih Jenis Nasabah
                                    </option>
                                    <option value="CLIENT">Individu</option>
                                    <option value="GROUP">Badan Usaha</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Alamat
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                disabled
                                id="alamat"
                                rows={3}
                                as="textarea"
                                size="sm"
                                placeholder="Masukkan alamat"
                                value={form?.alamat}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Keterangan
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                id="keterangan"
                                size="sm"
                                placeholder="Masukkan keterangan"
                                disabled
                                value={form?.keterangan}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">Status Data</Form.Label>
                            <Form.Control
                                id="status_data"
                                as="select"
                                size="sm"
                                placeholder="Aktif"
                                disabled
                            >
                                <option value="" hidden disabled selected>
                                    {form?.status_data}
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    User Input
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    value={form?.user_input}
                                    id="user_input"
                                    size="sm"
                                    placeholder="BCADEV"
                                />
                            </Form.Group>
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Tanggal Input
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    defaultValue={
                                        form?.tgl_input
                                            ? dayjs(form?.tgl_input).format('YYYY-MM-DD')
                                            : undefined
                                    }
                                    id="tgl_input"
                                    size="sm"
                                    type="date"
                                />
                            </Form.Group>
                        </div>
                        {/* {(type === 'peragaan' || type === 'hapus') && (
                            <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                                <Form.Group bsPrefix=" ">
                                    <Form.Label className="font-weight-normal f-12">
                                        User Approve
                                    </Form.Label>
                                    <Form.Control
                                        // defaultValue={form?._negatifNasabah?.user_approve}
                                        id="user_approve"
                                        disabled
                                        size="sm"
                                        placeholder="BCADEV"
                                    />
                                </Form.Group>
                                <Form.Group bsPrefix=" ">
                                    <Form.Label className="font-weight-normal f-12">
                                        Tanggal Approve
                                    </Form.Label>
                                    <Form.Control
                                        // defaultValue={
                                        //     form?._negatifNasabah?.tgl_approve
                                        //         ? dayjs(form?._negatifNasabah?.tgl_approve).format(
                                        //               'YYYY-MM-DD'
                                        //           )
                                        //         : undefined
                                        // }
                                        id="tgl_approve"
                                        disabled
                                        size="sm"
                                        type="date"
                                    />
                                </Form.Group>
                            </div>
                        )}
                        {(type === 'peragaan' || type === 'hapus') && (
                            <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                                <Form.Group bsPrefix=" ">
                                    <Form.Label className="font-weight-normal f-12">
                                        User Delete
                                    </Form.Label>
                                    <Form.Control
                                        id="user_delete"
                                        disabled
                                        size="sm"
                                        placeholder="BCADEV"
                                        // defaultValue={form?._negatifNasabah?.user_delete}
                                    />
                                </Form.Group>
                                <Form.Group bsPrefix=" ">
                                    <Form.Label className="font-weight-normal f-12">
                                        Tanggal Delete
                                    </Form.Label>
                                    <Form.Control
                                        id="tgl_delete"
                                        // defaultValue={
                                        //     form?._negatifNasabah?.tgl_delete
                                        //         ? dayjs(form?._negatifNasabah?.tgl_delete).format(
                                        //               'YYYY-MM-DD'
                                        //           )
                                        //         : undefined
                                        // }
                                        disabled
                                        size="sm"
                                        type="date"
                                    />
                                </Form.Group>
                            </div>
                        )} */}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button onClick={handleHide} className="text-white mr-2" size="mini">
                        Tutup
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalInfoNegatifNasabah;
