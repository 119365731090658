import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import useNexusPrint from '../../../../../hooks/useNexusPrint';
import { usePrintModal } from '../../../../controls/modalPrint';
// import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import { addCommas } from '../../../../../module';
import { useModalAlert } from '../../../../controls/modalAlert';
import PrintArea from './printArea';
import { useReactToPrint } from 'react-to-print';
import useKeycloak from '../../../../../hooks/keycloak.module';

const trxTodayContext = createContext({
    todayState: {}, todayAction: {}
});

export const TrxTodayProvider = ({ children }) => {
    const refCetak = useRef();
    const cetakWithoutNexus = useReactToPrint({
        content: () => refCetak?.current,
        bodyClass: "p-0 m-0"
    });
    function sortingDenom(dataDenom = [], namaDenom) {
        return dataDenom.filter(({ jenis = "" }) => (
            jenis.toUpperCase() === namaDenom.toUpperCase()
        )).sort(function (a, b) {
            return b.nilai_denominasi - a.nilai_denominasi;
        })
    }

    const { mpAction, mpState } = usePrintModal()

    const { doGet, doPost, wait } = useFetch();
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ keyword: '' })
    const [dataList, setListDataRekening] = useState([])
    // const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    // const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false });
    const [isLoading, setIsLoading] = useState(true)
    const [loadingDetil, setLoadingDetil] = useState(true);
    const [rekSelected, setRekSelected] = useState({});
    const [withNexus, setWithNexus] = useState(true)
    const { modalState, modalAction } = useModalAlert();
    const {

        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const [dataPrint, setDataPrint] = useState({
        "transaksi": {
            "id_transaksi": "",
            "id_transaksi_core": "",
            "nomor_rekening_debet": "",
            "nomor_rekening_credit": "",
            "nominal": "",
            "nomor_referensi": "",
            "keterangan": "",
            "keterangan_otor": null,
            "kode_valuta": "",
            "kode_transaksi": "",
            "status_otorisasi": "",
            "user_input": "",
            "user_otorisasi": "",
            "tanggal_input": "",
            "tanggal_otor": "",
            "status_reversal": null
        },
        "info_transaksi": {
            "id_info": null,
            "id_transaksi": "",
            "kode_bank_kliring": null,
            "nama_bank_kliring": null,
            "nama_rekening_penerima": null,
            "ats_permintaan": null,
            "keterangan": null,
            "norek_biaya": null,
            "saldo_teller_before": "",
            "saldo_teller_after": null,
            "nominal_ekuivalen": "",
            "biaya": "",
            "biaya_ekuivalen": "",
            "sumber_biaya": "",
            "kurs_jual": "",
            "kurs_beli": "",
            "kurs_buku": "",
            "pengirim_nomor_nasabah": null,
            "pengirim_nama": null,
            "pengirim_alamat": null,
            "pengirim_jenis_identitas": null,
            "pengirim_nomor_identitas": null,
            "pengirim_nomor_tlpn": null,
            "pengirim_nasabah_wic": null,
            "jenis_ttr": null,
            "tutup_kartu_atm": null,
            "norek_pindah_buku": null,
            "nama_rekening": "",
            "kode_valuta": "",
            "keterangan_valuta": "",
            "produk": "",
            "kode_cabang": "",
            "nama_cabang": "",
            "jenis_penduduk": null,
            "jenis_nomor_referensi": null,
            "jenis_nasabah": null,
            "total_denom": null,
            "selisih_saldo": null,
            "jenis_transaksi": null,
            "kode_valuta_teller": null,
            "saldo_rekening": null,
            "nama_rekening_pinbuk": null,
            "cabang_rekening_pinbuk": null
        }
    });

    const { print } = useNexusPrint()
    const [formCetak, setFormCetak] = useState({
        command: "PRINT",
        medianame: "Term Cash Deposit",
        formname: "BJBS VAL SETORAN",
        print: 1,
        history: 0,
        id: null,
        nomorTransaksi: "",
        kodeCabang: "",
        userTeller: "",
        tgl: "",
        userOtorisasi: "",
        kodeCabangDebit: "",
        norekDebit: "",
        kodeTarikan: "",
        jenisMataUang: "",
        nominalDebit: "",
        kodeDebit: "",
        kodeCabangKredit: "",
        norekKreditKasTeller: "",
        nominalKredit: "",
        kodeKredit: "",
        berita: "",
        tanggalTranskasi: "",
        jamTransaksi: ""
    })
    const [dataDenom, setDataDenom] = useState([])
    const [dataValidasi, setDataValidasi] = useState({
        data: {},
        field: []
    })
    const { keycloak } = useKeycloak()
    // const [saldoKas, setSaldoKas] = useState(0)
    useEffect(function () {
        getDataList()
    }, [pagination])

    // close Alert
    // function closeAlert() { setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null }) }

    // // closeConfirm
    // function closeConfirm() { setDataConfirm({ show: false, body: "", header: null, title: "", footerButton: [], closeButton: true, witTextArea: false }) }

    // getData rekening list
    async function getDataList() {
        setIsLoading(true)
        var url = `/informasi/transaksi-hari-ini`;
        if (keycloak.hasRealmRole("spv teller")) url = `/informasi/transaksi-hari-ini-by-kode-cabang`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { page: pagination.page, limit: pagination.show },
                service: "trx"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    // // // // // // // console.log(data)
                    setListDataRekening(data);
                    // setSaldoKas(data?.saldo_kas_teller)
                }
                else if (remark !== "Success") setDataAlert({ show: true, body: remark, titile: ``, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: '', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    async function cekWebhookBifast(item){

        setIsLoading(true)
        var url = `/informasi/sts-validasi-trx-bifast`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "trx",
                method: "PUT",
                queryParam: {
                    id_transaksi : item.id_transaksi
                }

            });

            if (statusCode === 200) {
                if (status === "00") {
                    getDataList()
                }
                else if (remark !== "Success") setDataAlert({ show: true, body: remark, titile: ``, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: '', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    function resetFilter() {
        setFilter({
            keyword: ''
        })
    }
    // next pagination
    function nextPage({ page, limit }) {
        // // // // // // console.log(page)
        setPagination({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    useEffect(() => {
    }, [dataPrint])

    useEffect(function* () {
        // // // // // console.log(dataValidasi)
    }, [dataValidasi])

    async function getTeller(id_user = null, kode_cabang = null) {
        const userInfo = await keycloak.loadUserInfo();
        if (id_user === null) id_user = userInfo.preferred_username;
        if (kode_cabang === null) kode_cabang = userInfo.branch_code;
        // // console.log(userInfo)
        openLoading()
        var url = `/kasteller/get-data-info-teller`;
        try {
            const resp = await doGet({
                url: url,
                param: {
                    id_user,
                    kode_cabang
                },
                service: "trx"
            });
            // console.log(resp)
            const { statusCode, status, remark, statusText, data = {} } = resp
            if (statusCode === 200) {
                if (status === "00") {

                    closeLoading()
                    return {
                        ...data,
                        isErr: false
                    }
                }
                else return {
                    remark,
                    isErr: true,
                    data
                }
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: '', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }
    async function cetakValidasi(item) {

        // // // // // // // console.log(formData);
        // const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }

        openLoading()
        setDataValidasi({
            data: {},
            field: []
        })
        setFormCetak({})
        try {
            const resp = await doGet({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    // "x-user-id": userInfo.preferred_username
                },
                // data: formData,
                url: `/informasi/cetak-ulang-validasi/${item.id_transaksi}`, // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            let { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    let txCode = data?.kode_transaksi;
                    if (data?.kode_transaksi === "RV") {
                        txCode = data?.info_transaksi?.kode_trx_rv || "";
                    }
                    // // // // // // console.log(data)
                    // // console.log(txCode)
                    let cetakNexus = true;
                    let dataVal = {
                        data: {
                            kode_transaksi: data?.kode_transaksi,
                            tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                            noref: data?.nomor_referensi,
                            userInput: data?.user_input,
                            userOtor: data?.user_otorisasi,
                            kodecabang: data?.kode_cabang_transaksi,
                            nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                            keterangan: data?.keterangan,
                            norek: data?.nomor_rekening_credit,
                            narek: data?.info_transaksi?.nama_rekening,
                            rektel: data?.nomor_rekening_debet,
                            space: ""
                        },
                        field: [
                            {
                                name: "Kode Transaksi",
                                fieldName: "kode_transaksi"
                            },
                            {
                                name: "Waktu Transaksi",
                                fieldName: "tanggal_input"
                            },
                            {
                                name: "No. Referensi",
                                fieldName: "noref"
                            },
                            {
                                name: "User Input",
                                fieldName: "userInput",
                            },
                            {
                                name: "User Otor",
                                fieldName: "userOtor",
                            },
                            {
                                name: "Kode Cabang",
                                fieldName: "kodecabang",
                            },
                            {
                                name: "",
                                fieldName: "space"
                            },
                            {
                                name: "Rekening Teller",
                                fieldName: "rektel"
                            },
                            {
                                name: "Nominal",
                                fieldName: "nominal",
                                className: "text-right"
                            },
                            {
                                name: "Biaya",
                                fieldName: "biaya",
                                // className: "text-right"
                            },
                            {
                                name: "Keterangan",
                                fieldName: "keterangan",
                                // className: "text-right"
                            },
                            {
                                name: "",
                                fieldName: "space"
                            },
                            {
                                name: "Nomor Rekening Tujuan",
                                fieldName: "norek"
                            },
                            {
                                name: "Nama Rekening Tujuan",
                                fieldName: "narek"
                            },

                        ]
                    }
                    let dataCetak = {
                        command: "PRINT",
                        medianame: "BJBS",
                        formname: "Validasi Setoran",
                        print: 1,
                        history: 0,
                        nomorArsipSetoran: "",
                        id: data?.info_transaksi?.id_transaksi,
                        nomorTransaksi: data?.nomor_referensi,
                        kodeCabangSetoran: data?.kode_cabang_transaksi,
                        userTeller: data?.user_input,
                        tglBlnThnSetoran: moment((data?.tanggal_otor)).format("DD/MM/YYYY"),
                        userOtorisasi: data?.user_otorisasi,
                        kodeCabangDebit: data?.kode_cabang_transaksi,
                        kodeKredit: "",
                        norekDebit: data?.nomor_rekening_credit,
                        kodeSetoran: "",
                        jenisMataUang: data?.info_transaksi?.kode_valuta,
                        nominalDebit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                        kodeDebit: "",
                        nominalKredit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                        kodeCabangKredit: data?.kode_cabang_transaksi,
                        // norekKreditKasTeller: data?.nomor_rekening_credit,
                        norekDebitKasTeller: data?.nomor_rekening_debet,
                        berita: data?.keterangan,
                        tanggalTranskasi: moment((data?.tanggal_otor)).format("DD/MM/YYYY"),
                        jamTransaksi: moment((data?.tanggal_otor)).format("HH:mm:ss"),
                        kodeTransaksi: data?.kode_transaksi,
                    }
                    if (txCode === "ATM01" || txCode === "ATM02") {
                        cetakNexus = false;
                        setDataDenom(data.denominasi)
                        const tellerPenerima = await getTeller(data?.user_input, data?.kode_cabang_transaksi)
                        if (tellerPenerima.isErr) {

                            closeLoading()
                            return setDataAlert({
                                show: true, body: (
                                    <>
                                        <p>{tellerPenerima.remark}</p>
                                        <p>{JSON.stringify(tellerPenerima.data)}</p>
                                    </>
                                ), title: `Failed Get user_id ${data?.user_input}, kode cabang ${data?.kode_cabang_transaksi}`, header: "Error", callBack: () => null
                            });
                        }
                        data = { ...data, info_transaksi: { ...data.info_transaksi, namaTellerPenerima: tellerPenerima?.nama_user || "", } }

                        const tellerPengirim = await getTeller(data?.info_transaksi?.user_id_teller_pengirim, data?.info_transaksi?.kode_cabang_tlr_db)
                        if (tellerPengirim.isErr) {
                            return setDataAlert({
                                show: true, body: (
                                    <>
                                        <p>{tellerPengirim.remark}</p>
                                        <p>{JSON.stringify(tellerPengirim.data)}</p>
                                    </>
                                ), title: `Failed Get user_id ${data?.user_input}, kode cabang ${data?.kode_cabang_transaksi}`, header: "Error", callBack: () => null
                            });
                        }
                        data = { ...data, info_transaksi: { ...data.info_transaksi, namaTellerPengirim: tellerPengirim?.nama_user || "", } }

                        // getDenom();
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                pengirim: `${data?.nomor_rekening_debet} - ${ data?.info_transaksi?.jenis_transaksi === "CIA" ? data?.info_transaksi?.nama_rekening : data.info_transaksi?.nama_rekening_kredit}`,//(${ data?.nomor_rekening_credit})`,
                                penerima: `${data.nomor_rekening_credit} - ${data?.info_transaksi?.jenis_transaksi === "COA" ? data?.info_transaksi?.nama_rekening : data.info_transaksi?.nama_rekening_kredit}`
                                ,// (${data?.nomor_rekening_debet})`,
                                kode_valuta: data?.kode_valuta,
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Rekening Pengirim",
                                    fieldName: "pengirim"
                                },

                                {
                                    name: "Rekening Penerima",
                                    fieldName: "penerima"
                                },
                                {
                                    name: "Kode Valuta",
                                    fieldName: "kode_valuta",
                                    // className: "text-right"
                                },
                                {
                                    name: "Nominal",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                            ]
                        }
                    }
                    if (txCode === "ST" || txCode === "STG") {
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                norek: data?.nomor_rekening_credit,
                                narek: data?.info_transaksi?.nama_rekening,
                                rektel: data?.nomor_rekening_debet,
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Rekening Teller",
                                    fieldName: "rektel"
                                },
                                {
                                    name: "Nominal",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                                {
                                    name: "Biaya",
                                    fieldName: "biaya",
                                    // className: "text-right"
                                },
                                {
                                    name: "Keterangan",
                                    fieldName: "keterangan",
                                    // className: "text-right"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Nomor Rekening Tujuan",
                                    fieldName: "norek"
                                },
                                {
                                    name: "Nama Rekening Tujuan",
                                    fieldName: "narek"
                                },

                            ]
                        }

                        dataCetak = {

                            command: "PRINT",
                            medianame: "BJBS",
                            formname: "Validasi Setoran",
                            print: 1,
                            history: 0,
                            id: data?.info_transaksi?.id_transaksi || "",
                            nomorArsipSetoran: data?.nomor_referensi || "",
                            kodeTransaksi: data?.kode_transaksi || "",
                            nomorTransaksi: data?.nomor_referensi || "", //b
                            kodeCabangSetoran: data?.kode_cabang_transaksi || "",
                            userTeller: data?.user_input || "",
                            tglBlnThnSetoran: moment((data?.tanggal_input_server || "")).format("DD/MM/YYYY"),
                            userOtorisasi: data?.user_otorisasi || "",
                            kodeCabangDebit: data?.info_transaksi?.kode_cabang_debet || "",
                            kodeKredit: "K",
                            norekKredit: data?.nomor_rekening_credit || "",
                            norekDebit: data?.nomor_rekening_debet || "",
                            kodeSetoran: data?.info_transaksi?.map_kode_transaksi || "",
                            mataUang: data?.info_transaksi?.kode_valuta || "",
                            nominalDebit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            kodeDebit: "D",
                            nominalKredit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            kodeCabangKredit: data?.info_transaksi?.kode_cabang_kredit || "",
                            namaPemilikNorek: data?.info_transaksi?.nama_rekening || "",
                            norekDebitKasTeller: data?.nomor_rekening_debet || "",
                            berita: data?.keterangan || "",
                            tanggalDebit: moment((data?.tanggal_otor)).format("DD/MM/YYYY") || "",
                            waktuDebit: moment((data?.tanggal_otor)).format("HH:mm:ss") || "",
                            penyetor: data?.info_transaksi?.pengirim_nama || ""
                        }

                    } else if (txCode === "TT" || txCode === "TTG" || txCode === "TTR") {
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                rektel: data?.nomor_rekening_credit,
                                narek: data?.info_transaksi?.nama_rekening,
                                norek: data?.nomor_rekening_debet,
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Rekening Teller",
                                    fieldName: "rektel"
                                },
                                {
                                    name: "Nominal",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                                {
                                    name: "Biaya",
                                    fieldName: "biaya",
                                    // className: "text-right"
                                },
                                {
                                    name: "Keterangan",
                                    fieldName: "keterangan",
                                    // className: "text-right"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Nomor Rekening",
                                    fieldName: "norek"
                                },
                                {
                                    name: "Nama Rekening",
                                    fieldName: "narek"
                                },

                            ]
                        }
                        dataCetak = {

                            command: "PRINT",
                            medianame: "BJBS",
                            formname: "Validasi Tarikan",
                            print: 1,
                            history: 0,
                            nomorArsipTarikan: data?.nomor_referensi || "",
                            id: data?.info_transaksi?.id_transaksi || "",
                            nomorTransaksi: data?.nomor_referensi || "",
                            kodeCabangTarikan: data?.kode_cabang_transaksi || "",
                            userTeller: data?.user_input || "",
                            tglBlnThnSetoran: moment((data?.tanggal_otor_server || "")).format("DD/MM/YYYY"),
                            userOtorisasi: data?.user_otorisasi || "",
                            kodeCabangDebit: data?.info_transaksi?.kode_cabang_debet || "",
                            kodeKredit: "K",
                            norekDebit: data?.nomor_rekening_debet || "",
                            kodeTarikan: data?.info_transaksi?.map_kode_transaksi || "",
                            kodeTransaksi: data?.kode_transaksi || "",
                            mataUang: data?.info_transaksi?.kode_valuta || "",
                            nominalDebit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            kodeDebit: "D",
                            norekKredit: data?.nomor_rekening_credit || "",
                            nominalKredit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            kodeCabangKredit: data?.info_transaksi?.kode_cabang_kredit || "",
                            norekKreditKasTeller: data?.nomor_rekening_credit || "",
                            berita: data?.keterangan || "",
                            tanggalDebit: moment((data?.tanggal_otor_server || "")).format("DD/MM/YYYY"),
                            waktuDebit: moment((data?.tanggal_otor_server || "")).format("HH:mm:ss"),
                            namaPemilikNorek: data?.info_transaksi?.nama_rekening || "",
                            penyetor: data?.transaksi?.pengirim_nama || ""
                        }

                    } else if (txCode === "RTGS") {
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                norek: data?.info_transaksi?.nomor_rekening_penerima,
                                narek: data?.info_transaksi?.nama_rekening_penerima,
                                rektel: data?.nomor_rekening_debet,
                                kodeBank: data?.info_transaksi?.kode_bank_kliring || "",
                                namaBank: data?.info_transaksi?.nama_bank_kliring || "",
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Rekening Teller",
                                    fieldName: "rektel"
                                },
                                {
                                    name: "Nominal",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                                {
                                    name: "Biaya",
                                    fieldName: "biaya",
                                    // className: "text-right"
                                },
                                {
                                    name: "Keterangan",
                                    fieldName: "keterangan",
                                    // className: "text-right"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },

                                {
                                    name: "Kode Bank",
                                    fieldName: "kodeBank"
                                },

                                {
                                    name: "Nama Bank",
                                    fieldName: "namaBank"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Nomor Rekening Tujuan",
                                    fieldName: "norek"
                                },
                                {
                                    name: "Nama Rekening Tujuan",
                                    fieldName: "narek"
                                },

                            ]
                        }
                        dataCetak = {
                            command: "PRINT",
                            medianame: "BJBS",
                            formname: "Validasi RTGS-KLIRING",
                            print: 1,
                            history: 0,
                            // baris 1
                            nomorArsip: data?.nomor_referensi, // new
                            kodeCabang: data?.kode_cabang_transaksi || "",
                            UserTeller: data?.user_input || "",
                            tglTransaksi: moment(data?.tanggal_otor_server).format("DD/MM/YYYY"),
                            userOtoSPV: data?.user_otorisasi || "",
                            // baris 2
                            kodeCabangKredit: data?.info_transaksi?.kode_cabang_kredit || "",
                            norekIAKredit: data?.nomor_rekening_credit || "",
                            kodeSetoran: 196,
                            nominalKredit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            kredit: "K",
                            //  baris 3
                            kodeCabangDebit: data?.info_transaksi?.kode_cabang_debet || "",
                            noRekIADebit: data?.nomor_rekening_debet || "",
                            nominalDebit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            // norekIAKredit: "", //new
                            kodeDebit: "D",
                            // baris 4
                            namaRekeningKredit: data?.info_transaksi?.nama_rekening_kredit || "", // development on BE
                            // kodeTransaksiKirimUang: 296,
                            keteranganTransaksi: `KU-${data.id_transaksi} SETOR TRF:${data?.info_transaksi?.kode_cabang_debet}->${data?.info_transaksi?.kode_cabang_kredit}`,
                            validasiTransfer: "",
                            berita: data?.keterangan || "",
                            tglTransaksi: moment(data?.tanggal_otor_server).format("DD/MM/YYYY"),
                            jamTransaksi: moment(data?.tanggal_otor_server).format("HH:mm:ss"),
                            // baris 5
                            kodeCabangKreditBiaya: data?.info_transaksi?.kode_cabang_kredit_biaya || "",
                            noRekJasaLayananTransfer: data?.info_transaksi?.norek_kredit_biaya, //new
                            kodeTransaksiSetoran: 296, //new
                            biayaJasaKirim: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                            kodeKredit: "K",
                            // baris 6
                            kodeCabangDebitBiaya: data?.info_transaksi?.kode_cabang_debet_biaya || "", //new
                            noRekKasTeller: data?.info_transaksi?.norek_debet_biaya,
                            biayaJasaKliring: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                            kodeDebit: "D",
                            // baris 7
                            namaRek: data?.info_transaksi?.nama_rekening_kredit_biaya || "",
                            keteranganBiaya: `KU-${data.id_transaksi} BIA TRF:${data?.info_transaksi?.kode_cabang_debet_biaya}->${data?.info_transaksi?.kode_cabang_kredit_biaya}`,
                            // keteranganBiaya: "BIA",
                            berita: data?.keterangan || "",
                            tglTransaksiBiaya: moment(data?.tanggal_otor_server).format("DD/MM/YYYY"),
                            jamTransaksiBiaya: moment(data?.tanggal_otor_server).format("HH:mm:ss"),


                        }
                    } else if (txCode === "SKN") {
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                norek: data?.info_transaksi?.nomor_rekening_penerima,
                                narek: data?.info_transaksi?.nama_rekening_penerima,
                                rektel: data?.nomor_rekening_debet,
                                kodeBank: data?.info_transaksi?.kode_bank_kliring || "",
                                namaBank: data?.info_transaksi?.nama_bank_kliring || "",
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Rekening Teller",
                                    fieldName: "rektel"
                                },
                                {
                                    name: "Nominal",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                                {
                                    name: "Biaya",
                                    fieldName: "biaya",
                                    // className: "text-right"
                                },
                                {
                                    name: "Keterangan",
                                    fieldName: "keterangan",
                                    // className: "text-right"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },

                                {
                                    name: "Kode Bank",
                                    fieldName: "kodeBank"
                                },

                                {
                                    name: "Nama Bank",
                                    fieldName: "namaBank"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Nomor Rekening Tujuan",
                                    fieldName: "norek"
                                },
                                {
                                    name: "Nama Rekening Tujuan",
                                    fieldName: "narek"
                                },

                            ]
                        }

                        dataCetak = {
                            command: "PRINT",
                            medianame: "BJBS",
                            formname: "Validasi RTGS-KLIRING",
                            print: 1,
                            history: 0,
                            // baris 1
                            nomorArsip: data?.nomor_referensi, // new
                            kodeCabang: data?.kode_cabang_transaksi || "",
                            UserTeller: data?.user_input || "",
                            tglTransaksi: moment(data?.tanggal_otor_server || "").format("DD/MM/YYYY"),
                            userOtoSPV: data?.user_otorisasi || "",
                            // baris 2
                            kodeCabangKredit: data?.info_transaksi?.kode_cabang_kredit || "",
                            norekIAKredit: data?.nomor_rekening_credit || "",
                            kodeSetoran: 196,
                            nominalKredit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            kredit: "K",
                            //  baris 3
                            kodeCabangDebit: data?.info_transaksi?.kode_cabang_debet || "",
                            noRekIADebit: data?.nomor_rekening_debet || "",
                            nominalDebit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            // norekIAKredit: "", //new
                            kodeDebit: "D",
                            // baris 4
                            namaRekeningKredit: data?.info_transaksi?.nama_rekening_kredit || "", // development on BE
                            // kodeTransaksiKirimUang: 296,
                            keteranganTransaksi: `KU-${data.id_transaksi} SETOR TRF:${data?.info_transaksi?.kode_cabang_debet}->${data?.info_transaksi?.kode_cabang_kredit}`,
                            validasiTransfer: "",
                            berita: data?.keterangan || "",
                            tglTransaksi: moment(data?.tanggal_otor_server || "").format("DD/MM/YYYY"),
                            jamTransaksi: moment(data?.tanggal_otor_server || "").format("HH:mm:ss"),
                            // baris 5
                            kodeCabangKreditBiaya: data?.info_transaksi?.kode_cabang_kredit_biaya || "",
                            noRekJasaLayananTransfer: data?.info_transaksi?.norek_kredit_biaya, //new
                            kodeTransaksiSetoran: 296, //new
                            biayaJasaKirim: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                            kodeKredit: "K",
                            // baris 6
                            kodeCabangDebitBiaya: data?.info_transaksi?.kode_cabang_debet_biaya || "", //new
                            noRekKasTeller: data?.info_transaksi?.norek_debet_biaya,
                            biayaJasaKliring: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                            kodeDebit: "D",
                            // baris 7
                            namaRek: data?.info_transaksi?.nama_rekening_kredit_biaya || "",
                            keteranganBiaya: `KU-${data.id_transaksi} BIA TRF:${data?.info_transaksi?.kode_cabang_debet_biaya}->${data?.info_transaksi?.kode_cabang_kredit_biaya}`,
                            // keteranganBiaya: "BIA",
                            berita: data?.keterangan || "",
                            tglTransaksiBiaya: moment(data?.tanggal_otor_server || "").format("DD/MM/YYYY"),
                            jamTransaksiBiaya: moment(data?.tanggal_otor_server || "").format("HH:mm:ss"),

                        }


                    } else if (txCode === "AKT") {
                        cetakNexus = false;
                        setDataDenom(data.denominasi)
                        const tellerPenerima = await getTeller(data?.user_input, data?.kode_cabang_transaksi)
                        if (tellerPenerima.isErr) {

                            closeLoading()
                            return setDataAlert({
                                show: true, body: (
                                    <>
                                        <p>{tellerPenerima.remark}</p>
                                        <p>{JSON.stringify(tellerPenerima.data)}</p>
                                    </>
                                ), title: `Failed Get user_id ${data?.user_input}, kode cabang ${data?.kode_cabang_transaksi}`, header: "Error", callBack: () => null
                            });
                        }
                        data = { ...data, info_transaksi: { ...data.info_transaksi, namaTellerPenerima: tellerPenerima?.nama_user || "", } }

                        const tellerPengirim = await getTeller(data?.info_transaksi?.user_id_teller_pengirim, data?.info_transaksi?.kode_cabang_tlr_db)
                        if (tellerPengirim.isErr) {
                            return setDataAlert({
                                show: true, body: (
                                    <>
                                        <p>{tellerPengirim.remark}</p>
                                        <p>{JSON.stringify(tellerPengirim.data)}</p>
                                    </>
                                ), title: `Failed Get user_id ${data?.user_input}, kode cabang ${data?.kode_cabang_transaksi}`, header: "Error", callBack: () => null
                            });
                        }
                        data = { ...data, info_transaksi: { ...data.info_transaksi, namaTellerPengirim: tellerPengirim?.nama_user || "", } }

                        // getDenom();
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                norek: `${data?.user_input} - ${data?.info_transaksi?.namaTellerPenerima} `,//(${ data?.nomor_rekening_credit})`,
                                rektel: `${data?.info_transaksi?.user_id_teller_pengirim} - ${data?.info_transaksi?.namaTellerPengirim}`,// (${data?.nomor_rekening_debet})`,
                                kode_valuta: data?.kode_valuta,
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Diserahkan Oleh",
                                    fieldName: "rektel"
                                },

                                {
                                    name: "Diterima Oleh",
                                    fieldName: "norek"
                                },
                                {
                                    name: "Kode Valuta",
                                    fieldName: "kode_valuta",
                                    // className: "text-right"
                                },
                                {
                                    name: "Nominal",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                            ]
                        }
                    } else if (txCode === "AKK") {
                        // getDenom();

                        setDataDenom(data.denominasi)
                        const teller = await getTeller(data?.user_input, data?.kode_cabang_transaksi)
                        if (teller.isErr) {

                            closeLoading()
                            return setDataAlert({
                                show: true, body: (
                                    <>
                                        <p>{teller.remark}</p>
                                        <p>{JSON.stringify(teller.data)}</p>
                                    </>
                                ), title: `Failed Get user_id ${data?.user_input}, kode cabang ${data?.kode_cabang_transaksi}`, header: "Error", callBack: () => null
                            });
                        }
                        data = { ...data, info_transaksi: { ...data.info_transaksi, namaTeller: teller.nama_user, } }
                        cetakNexus = false;
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                namaTeller: teller.nama_user,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                norek: data?.nomor_rekening_credit,
                                narek: data?.info_transaksi?.nama_rekening,
                                rektel: data?.nomor_rekening_debet,
                                kode_valuta: data?.kode_valuta,
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Rekening Teller",
                                    fieldName: "rektel"
                                },
                                {
                                    name: "Kode Valuta",
                                    fieldName: "kode_valuta"
                                },
                                {
                                    name: "Nominal",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Nomor Rekening Tujuan",
                                    fieldName: "norek"
                                },

                            ]
                        }


                    } else if (txCode === "SKK") {
                        // getDenom();

                        setDataDenom(data.denominasi)
                        const teller = await getTeller(data?.user_input, data?.kode_cabang_transaksi)
                        if (teller.isErr) {
                            closeLoading()
                            return setDataAlert({
                                show: true, body: (
                                    <>
                                        <p>{teller.remark}</p>
                                        <p>{JSON.stringify(teller.data)}</p>
                                    </>
                                ), title: `Failed Get user_id ${data?.user_input}, kode cabang ${data?.kode_cabang_transaksi}`, header: "Error", callBack: () => null
                            });
                        }
                        data = { ...data, info_transaksi: { ...data.info_transaksi, namaTeller: teller.nama_user, } }

                        cetakNexus = false;
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                norek: data?.nomor_rekening_credit,
                                narek: data?.info_transaksi?.nama_rekening,
                                rektel: data?.nomor_rekening_debet,
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Rekening Teller",
                                    fieldName: "rektel"
                                },
                                {
                                    name: "Nominal",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                                {
                                    name: "Biaya",
                                    fieldName: "biaya",
                                    // className: "text-right"
                                },
                                {
                                    name: "Keterangan",
                                    fieldName: "keterangan",
                                    // className: "text-right"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Nomor Rekening Tujuan",
                                    fieldName: "norek"
                                },

                            ]
                        }
                    } else if (txCode === "FIP") {
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                norek: data?.nomor_rekening_credit,
                                narek: data?.info_transaksi?.nama_rekening,
                                rektel: data?.nomor_rekening_debet,
                                jenis_pembayaran: data?.info_transaksi?.jenis_pembayaran,
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Rekening Teller",
                                    fieldName: "rektel"
                                },
                                {
                                    name: "Nominal Angsuran",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                                {
                                    name: "Jenis Pembayaran",
                                    fieldName: "jenis_pembayaran",
                                    onRender: (item) => {
                                        switch (item.jenis_pembayaran) {
                                            case "P": return "POKOK"
                                            case "M": return "MARGIN"
                                            case "A": return "ANGSURAN"
                                        }
                                    }
                                    // className: "text-right"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Nomor Rekening Pembiayaan",
                                    fieldName: "norek"
                                },
                                {
                                    name: "Nama Rekening Pembiayaan",
                                    fieldName: "narek"
                                },

                            ]
                        }

                        dataCetak = {

                            command: "PRINT",
                            medianame: "BJBS",
                            formname: "Validasi Setoran",
                            print: 1,
                            history: 0,
                            id: data?.info_transaksi?.id_transaksi || "",
                            nomorArsipSetoran: data?.nomor_referensi || "",
                            kodeTransaksi: data?.kode_transaksi || "",
                            nomorTransaksi: data?.nomor_referensi || "", //b
                            kodeCabangSetoran: data?.kode_cabang_transaksi || "",
                            userTeller: data?.user_input || "",
                            tglBlnThnSetoran: moment((data?.tanggal_input_server || "")).format("DD/MM/YYYY"),
                            userOtorisasi: data?.user_otorisasi || "",
                            kodeCabangDebit: data?.info_transaksi?.kode_cabang_debet || "",
                            kodeKredit: "K",
                            norekKredit: data?.nomor_rekening_credit || "",
                            norekDebit: data?.nomor_rekening_debet || "",
                            kodeSetoran: data?.info_transaksi?.map_kode_transaksi || "",
                            mataUang: data?.info_transaksi?.kode_valuta || "",
                            nominalDebit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            kodeDebit: "D",
                            nominalKredit: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            kodeCabangKredit: data?.info_transaksi?.kode_cabang_kredit || "",
                            namaPemilikNorek: data?.info_transaksi?.nama_rekening || "",
                            norekDebitKasTeller: data?.nomor_rekening_debet || "",
                            berita: data?.keterangan || "",
                            tanggalDebit: moment((data?.tanggal_otor_server)).format("DD/MM/YYYY") || "",
                            waktuDebit: moment((data?.tanggal_otor_server)).format("HH:mm:ss") || "",
                            penyetor: data?.info_transaksi?.pengirim_nama || ""
                        }
                    } else if (txCode === "FTRF"){
                        dataVal = {
                            data: {
                                kode_transaksi: data?.kode_transaksi,
                                tanggal_input: moment(data?.tanggal_otor).format("DD/MM/YYYY HH:mm:ss"),
                                noref: data?.nomor_referensi,
                                userInput: data?.user_input,
                                userOtor: data?.user_otorisasi,
                                kodecabang: data?.kode_cabang_transaksi,
                                nominal: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                                biaya: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                                keterangan: data?.keterangan,
                                norek: data?.nomor_rekening_credit,
                                narek: data?.info_transaksi?.nama_rekening_penerima,
                                rektel: data?.nomor_rekening_debet,
                                kodeBank: data?.info_transaksi?.kode_bank_kliring || "",
                                namaBank: data?.info_transaksi?.nama_bank_kliring || "",
                                space: ""
                            },
                            field: [
                                {
                                    name: "Kode Transaksi",
                                    fieldName: "kode_transaksi"
                                },
                                {
                                    name: "Waktu Transaksi",
                                    fieldName: "tanggal_input"
                                },
                                {
                                    name: "No. Referensi",
                                    fieldName: "noref"
                                },
                                {
                                    name: "User Input",
                                    fieldName: "userInput",
                                },
                                {
                                    name: "User Otor",
                                    fieldName: "userOtor",
                                },
                                {
                                    name: "Kode Cabang",
                                    fieldName: "kodecabang",
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Rekening Teller",
                                    fieldName: "rektel"
                                },
                                {
                                    name: "Nominal",
                                    fieldName: "nominal",
                                    className: "text-right"
                                },
                                {
                                    name: "Biaya",
                                    fieldName: "biaya",
                                    // className: "text-right"
                                },
                                {
                                    name: "Keterangan",
                                    fieldName: "keterangan",
                                    // className: "text-right"
                                },
                                {
                                    name: "",
                                    fieldName: "space"
                                },

                                {
                                    name: "Kode Bank",
                                    fieldName: "kodeBank"
                                },

                                // {
                                //     name: "Nama Bank",
                                //     fieldName: "namaBank"
                                // },
                                {
                                    name: "",
                                    fieldName: "space"
                                },
                                {
                                    name: "Nomor Rekening Tujuan",
                                    fieldName: "norek"
                                },
                                {
                                    name: "Nama Rekening Tujuan",
                                    fieldName: "narek"
                                },

                            ]
                        }
                        dataCetak = {
                            command: "PRINT",
                            medianame: "BJBS",
                            formname: "Validasi BI-FAST",
                            print: 1,
                            history: 0,
                            // baris 1
                            nomorArsipBifast: data?.nomor_referensi, // new
                            kodeCabangBifast: data?.kode_cabang_transaksi || "",
                            UserTellerBifast: data?.user_input || "",
                            tglTransaksiBifast: moment(data?.tanggal_otor_server).format("DD/MM/YYYY"),
                            userOtoSPVBifast: data?.user_otorisasi || "",
                            // baris 2
                            kodeCabangKreditBifast: data?.info_transaksi?.kode_cabang_kredit || "",
                            norekIAKreditBifast: data?.nomor_rekening_credit || "",
                            kodeSetoranBifast: 196,
                            nominalKreditBifast: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            kreditBifast: "K",
                            //  baris 3
                            kodeCabangDebitBifast: data?.info_transaksi?.kode_cabang_debet || "",
                            noRekIADebitBifast: data?.nomor_rekening_debet || "",
                            nominalDebitBifast: addCommas(parseFloat(data?.nominal || "0").toFixed(2)),
                            // norekIAKredit: "", //new
                            kodeDebitBifast: "D",
                            // baris 4
                            namaRekeningKreditBifast: data?.info_transaksi?.nama_rekening_kredit || "", // development on BE
                            // kodeTransaksiKirimUang: 296,
                            keteranganTransaksiBifast: `KU-${data.id_transaksi} SETOR TRF:${data?.info_transaksi?.kode_cabang_debet  || ""}->${data?.info_transaksi?.kode_cabang_kredit  || ""} Transfer BI Fast`,
                            validasiTransferBifast: "",
                            beritaBifast: data?.keterangan || "",
                            tglTransaksiBifast: moment(data?.tanggal_otor_server).format("DD/MM/YYYY"),
                            jamTransaksiBifast: moment(data?.tanggal_otor_server).format("HH:mm:ss"),
                            // baris 5
                            kodeCabangKreditBiayaBifast: data?.info_transaksi?.kode_cabang_kredit_biaya || "",
                            noRekJasaLayananTransferBifast: data?.info_transaksi?.norek_kredit_biaya  || "", //new
                            kodeTransaksiSetoranBifast: 296, //new
                            biayaJasaKirimBifast: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                            kodeKreditBifast: "K",
                            // baris 6
                            kodeCabangDebitBiayaBifast: data?.info_transaksi?.kode_cabang_debet_biaya || "", //new
                            noRekKasTellerBifast: data?.info_transaksi?.norek_debet_biaya  || "",
                            biayaJasaKliringBifast: addCommas(parseFloat(data?.info_transaksi?.biaya || "0").toFixed(2)),
                            kodeDebitBifast: "D",
                            // baris 7
                            namaRekBifast: data?.info_transaksi?.nama_rekening_kredit_biaya || "",
                            keteranganBiayaBifast: `KU-${data.id_transaksi || ""} BIA TRF:${data?.info_transaksi?.kode_cabang_debet_biaya  || ""}->${data?.info_transaksi?.kode_cabang_kredit_biaya  || ""} Transfer BI Fast`,
                            // keteranganBiaya: "BIA",
                            beritaBifast: data?.keterangan || "",
                            tglTransaksiBiayaBifast: moment(data?.tanggal_otor_server).format("DD/MM/YYYY"),
                            jamTransaksiBiayaBifast: moment(data?.tanggal_otor_server).format("HH:mm:ss"),


                        }
                    }


                    // // // console.log(dataCetak)

                    await setDataValidasi(dataVal)
                    // }
                    setDataPrint(p => ({
                        transaksi: data,
                        info_transaksi: data.info_transaksi
                    }))

                    setFormCetak(dataCetak)
                    await wait(500)
                    setWithNexus(cetakNexus)
                    mpAction.openModal("modalTrx", function () {
                        // resetForm();
                        // closeLoading();
                        closeAlert();
                        mpAction.closeModal();

                        // closeConfirmCustom();
                        // closeConfirm();
                    })



                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
        // mpAction.openModal("modalTrx", function () {
        //     closeAlert();
        // })

    }
    useEffect(function () {

    }, [])
    async function getDenom() {
        const userInfo = await keycloak.loadUserInfo();
        // openLoading()
        var url = `/brangkas`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userInfo.preferred_username,
                    kode_valuta: "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    const denominasi = ([
                        ...(sortingDenom(data.InfoDenominasiBrangkas || [], "lembar") || []),

                        ...(sortingDenom(data.InfoDenominasiBrangkas || [], "koin") || []),

                        ...(sortingDenom(data.InfoDenominasiBrangkas || [], "lembar (utle)") || []),
                    ]).map(({
                        nilai_denominasi = 0, jumlah_lembar_koin = 0, jenis = ""
                    }) => ({
                        nilai_denominasi,
                        jenis,
                        jumlah_lembar_koin: 0
                    }))

                    setDataDenom(denominasi);
                    // // // // // // console.log(data)
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    // setFormData(prevState => ({
                    //     ...prevState,
                    //     info_transaksi: {
                    //         saldo_teller_after: parseInt(data.saldo),
                    //         saldo_teller_before: parseInt(data.saldo)
                    //     }
                    // }))
                }
                //else setDataAlert({ show: true, body: remark, titile: ``, callBack: () => null });
            } //else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            //setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        // wait(3000);
        // closeLoading()
    }

    async function cetakFunction() {

        openLoading()
        if (keycloak.hasRealmRole("teller")) {

            if (withNexus) {
                try {
                    const dataResponse = await print(formCetak);
                    if (dataResponse.statusCode === 500) {
                        closeLoading()
                        return setDataAlert({ show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Error", callBack: () => null });
                    } else {
                        closeLoading()
                        setDataAlert({
                            show: true, body: dataResponse.remark, title: `${dataResponse.statusText}`, header: "Berhasil", callBack: () => {
                                closeConfirm();
                                mpAction.closeModal("modalTrx")
                                getDataList()
                                closeLoading();
                            }
                        });
                    }
                } catch (error) {
                    closeLoading()
                    return setDataAlert({
                        show: true, body: "Gagal koneksi ke print server. Periksa kembali koneksi print server", title: ``, header: "Info", callBack: () => {
                            closeAlert();
                            mpAction.closeModal();
                            getDataList()
                            closeLoading()
                        }
                    });
                }
            } else {
                await cetakWithoutNexus()
                closeLoading()
                mpAction.closeModal("modalTrx")
                getDataList()
            }
        }
        try {

            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                method: "PUT",
                queryParam: { flag: keycloak.hasRealmRole("spv teller") },
                data: {},
                url: `/informasi/flag-cetak-validasi/${dataPrint.transaksi?.id_transaksi}`, // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    // if(keycloak.hasRealmRole("spv teller")) {
                    // // // // // // // console.log("hahahah")
                    keycloak.hasRealmRole("spv teller") && setDataAlert({
                        show: true, body: remark, title: ``, header: "Success", callBack: () => {
                            closeAlert();
                            mpAction.closeModal()
                            getDataList();
                        }
                    });

                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            // // // // // console.log(error)
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }

        closeLoading()
    }

    // response otorisasi
    function responseSelesai(item) {
        let body = `Apakah anda yakin ingin Menyelsaikan Transaksi BDS ini. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => postFinsihTrx(item), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function postFinsihTrx(item) {
        // // // console.log(item)
        openLoading();
        var url = `/verifikasiprocess-trx-pending`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "trx",
                queryParam: { id_transaksi_bds: item },
                method: 'PUT'
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: `Proses Transaksi ke BDS Berhasil`, title: ``, header: "Success", callBack: () => {
                        closeConfirm();
                        getDataList();
                    }
                });
                else setDataAlert({ show: true, body: remark, titile: ``, header: 'Info', callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: ``, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            closeConfirm();
        }
        closeLoading();
        closeConfirm();
    }

    useEffect(function () {
        // // // // // console.log(formCetak)
    }, [formCetak])
    return <trxTodayContext.Provider
        value={{
            todayState: {
                dataList,
                // dataAlert,
                // dataConfirm,
                isLoading,
                filter,
                loadingDetil,
                rekSelected,
                formCetak,
                dataPrint,
                dataValidasi,
                withNexus,
                dataDenom
            },
            todayAction: {
                getDataList,
                nextPage,
                prevPage,
                changeShow,
                closeAlert,
                closeConfirm,
                setFilter,
                resetFilter,
                cetakValidasi,
                cetakFunction,
                responseSelesai,
                cekWebhookBifast
            },
            mpAction, mpState
        }}
    >
        <PrintArea
            ref={refCetak}
        // todayState={{
        //     dataDenom
        // }} 
        />
        {children}
    </trxTodayContext.Provider>;
}


export default function useTrxToday() { return useContext(trxTodayContext) };