import { memo, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import styles from './stepper.module.css';
import { useState } from 'react';

const Stepper = memo(
    forwardRef(
        (
            {
                steps = [
                    {
                        name: 'Data Rekening',
                        id: '0',
                        whiteSpace: 'nowrap',
                        isDone: false,
                    },
                    {
                        name: 'Informasi Lain',
                        id: '1',
                        whiteSpace: 'nowrap',
                        isDone: false,
                    },
                    {
                        name: 'Specimen Pejabat',
                        id: '2',
                        whiteSpace: 'nowrap',
                        isDone: false,
                    },
                    {
                        name: 'Hak Akses Rekening',
                        id: '3',
                        whiteSpace: 'nowrap',
                        isDone: false,
                    },
                ],
                activeIdx = 0,
                className,
                style,
                manualDone = false,
                onChange = (_activeIdx, _prevIdx) => {},
                onStepClick = (_idx) => {},
            },
            ref
        ) => {
            const [state, setState] = useState(steps);
            const handleChange = useRef((_activeIdx, _prevIdx) => {});
            if (onChange) handleChange.current = onChange;

            useEffect(() => {
                handleChange.current(activeIdx, activeIdx - 1);
            }, [activeIdx]);

            useImperativeHandle(ref, () => ({
                setDone(index = 0) {
                    if (manualDone) {
                        const newSteps = [...state];
                        newSteps[index].isDone = true;

                        setState(newSteps);
                    }
                },
            }));

            if (manualDone)
                return (
                    <div style={style} className={clsx('d-flex align-items-center', className)}>
                        {state.map(({ name, id, whiteSpace = 'nowrap', isDone }, i) => (
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => onStepClick(i)}
                                key={`steps-${id}`}
                                className={clsx(
                                    'd-flex flex-column align-items-center',
                                    styles['step'],
                                    {
                                        [styles.done]: isDone,
                                        [styles.active]: i === activeIdx,
                                        [styles.passed]: i < activeIdx && !isDone,
                                    }
                                )}
                            >
                                <div
                                    style={{ height: 24, width: 24 }}
                                    className={clsx(
                                        'rounded-circle mb-2 relative',
                                        styles['step-circle'],
                                        {
                                            [styles.done]: isDone,
                                            [styles.active]: i === activeIdx,
                                            [styles.passed]: i < activeIdx && !isDone,
                                        }
                                    )}
                                ></div>
                                <div style={{ whiteSpace }} className={styles.text}>
                                    {name}
                                </div>
                            </div>
                        ))}
                    </div>
                );

            return (
                <div style={style} className={clsx('d-flex align-items-center', className)}>
                    {state.map(({ name, id, whiteSpace = 'nowrap' }, i) => (
                        <div
                            key={`steps-${id}`}
                            className={clsx(
                                'd-flex flex-column align-items-center tw-cursor-pointer',
                                styles['step'],
                                {
                                    [styles.done]: i < activeIdx,
                                    [styles.active]: i === activeIdx,
                                }
                            )}
                            onClick={() => onStepClick(i)}
                        >
                            <div
                                style={{ height: 24, width: 24 }}
                                className={clsx(
                                    'rounded-circle mb-2 relative',
                                    styles['step-circle'],
                                    {
                                        [styles.done]: i < activeIdx,
                                        [styles.active]: i === activeIdx,
                                    }
                                )}
                            ></div>
                            <div style={{ whiteSpace }} className={styles.text}>
                                {name}
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
    )
);

export default Stepper;
