import React, { memo, useRef } from 'react';
import { Link } from 'react-router-dom';

class AmbilKas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return(
      <>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mt-2 mb-3">
            <li className="breadcrumb-item color-primary">Kas Teller</li>
            <li className="breadcrumb-item"><a href="#">Ambil Kas dari Teller Lain</a></li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-6">
            <div className="card">
              <div className="card-header bg-white">
                <h5 className="card-title">TRANSAKSI  AMBIL KAS DARI TELLER LAIN</h5>
              </div>
              <div className="card-body">
                <div className="bns-form">
                  <div className="form-group">
                    <label htmlFor="">Valuta Transaksi</label>
                    <div className="form-row">
                      <div className="col-2 pr-0">
                        <select name="" id="" className="form-control">
                          <option value="">IDR</option>
                        </select>
                      </div>
                      <div className="col-10 pl-0">
                        <input type="text" className="form-control" value="INDONESIAN RUPIAH" readOnly/>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Saldo Teller Pengirim</label>
                    <div className="form-row">
                      <div className="col-2 pr-0">
                        <input type="text" className="form-control" value="IDR" readOnly/>
                      </div>
                      <div className="col-10 pl-0">
                        <input type="text" className="form-control" value="7.400.000,00" readOnly/>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">User ID Teller Pengirim</label>
                    <input type="text" className="form-control" readOnly/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Nominal</label>
                    <input type="text" className="form-control" value="140938"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Nomor Aplikasi</label>
                    <input type="text" className="form-control" value="Terima 141332"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Keterangan</label>
                    <input type="text" className="form-control" value="Terima 141332"/>
                  </div>
                  <div className="form-group text-right">
                    <button className="btn btn-outline-secondary">Batal</button>
                    <button className="btn btn-success ml-3 px-5">Proses</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default AmbilKas;