import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { dasboardData } from '../../../../../../appConfig';
import { createForm } from '../../../../../../module/createFormList';
import { TableList } from '../../../../../controls';
import useRekening from '../useRekening';
import { addCommas } from '../../../../../../module';


const ModalHoldDana = () => {
    const { rekState, rekAction } = useRekening();

    return (
        <>
            {/* modal utama */}
            <Modal size={"xl"} show={rekState.modalHoldDana} onHide={rekAction.closeHoldDana} >
                <Modal.Header closeButton>
                    <Modal.Title>
                    { rekState.unholdMode || rekState.dataOtor?.kode_entry === "HOLD003" ?  "Unhold Dana Rekening" : " Hold Dana Rekening"} 
                        
                    </Modal.Title>
                </Modal.Header>
            <Modal.Body>{
                 rekState.dataOtor?.kode_entry !== "HOLD003" && <div className="box mb-3">
                    <div className="row">
                        <div className="col">
                            <h4>{rekState.loadingHold ? <Skeleton width={"50%"} height={30} /> : <>{rekState.dataHoldDana?.nama_rekening}</>}</h4>

                            <div className="row">
                                <div className="col-4">
                                    <div className="row">
                                        <div className="col-4"> Produk </div>
                                        <div className="col-8">{rekState.loadingHold ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataHoldDana?.produk_rekening : rekState.dataHoldDana?.nama_produk}`}  </div>
                                    </div><div className="row">
                                        <div className="col-4"> No. Rek </div>
                                        <div className="col-8">{rekState.loadingHold ? <Skeleton height={20} /> : `: ${rekState.dataHoldDana?.nomor_rekening}`}   </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="row">
                                        <div className="col-4"> Cabang </div>
                                        <div className="col-8">{rekState.loadingHold ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataHoldDana?.cabang_rekening : rekState.dataHoldDana?.cabang}`}   </div>
                                    </div><div className="row">
                                        <div className="col-4">Kode Valuta </div>
                                        <div className="col-8">{rekState.loadingHold ? <Skeleton height={20} /> : `: ${rekState.otorMode ? rekState.dataHoldDana?.valuta_rekening : rekState.dataHoldDana?.valuta}`}  </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="row">
                                        <div className="col-4"> Saldo Rek </div>
                                        <div className="col-8">
                                            {
                                                rekState.loadingHold ?
                                                    <Skeleton height={20} /> :
                                                    <> :  <span className="float-right">{rekState.otorMode ? addCommas(parseFloat(rekState.dataHoldDana?.saldo_rekening || "0").toFixed(2)) : addCommas(parseFloat(rekState.dataHoldDana?.saldo || "0").toFixed(2))} </span></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>}
                    {
                        rekState.otorMode ? <RegisterDanaHold /> : (rekState.holdModalMode ? <RegisterDanaHold /> : <DanaHold />)
                    }

                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        {
                            !rekState.otorMode ? <div className="col">
                                {
                                    rekState.holdModalMode && (rekState.unholdMode ? (
                                        <button onClick={() => {
                                            rekAction.confirmHold();
                                        }}
                                            className="btn btn-danger float-right ml-3">Unhold</button>
                                    ) : <button onClick={() => {
                                        rekAction.confirmHold();
                                    }}
                                        className="btn btn-success float-right ml-3">
                                        {rekState.modeEditHold ? <>Update Hold Dana </> : <>Registrasi Hold Dana </>}                                    </button>)
                                    //    { rekState.modeUnHold ?  <>Un Hold Dana </> : <>Registrasi Hold Dana </>}                                    </button>
                                }
                                <button
                                    onClick={() => rekState.holdModalMode ? rekAction.toggleModalMode() : rekAction.closeHoldDana()}
                                    className="btn btn-outline-success float-right">
                                    Tutup
                                </button>
                            </div> : (
                                <div className="col">
                                    <button
                                        onClick={() => rekAction.closeHoldDana()}
                                        className="btn btn-outline-success float-right">
                                        Tutup
                                    </button>

                                    <button
                                        onClick={() => rekAction.responseOtor("APPROVED")}
                                        className="btn btn-success mr-2">
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => rekAction.responseOtor("REJECTED")}
                                        className="btn btn-danger mr-2">
                                        Reject
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal utama */}
        </>
    );
}

function DanaHold() {

    const { rekState, rekAction } = useRekening();
    return (
        <>
            {!rekState.otorMode && <div className="row">
                <div className="col">
                    <span className="float-left text-primary" style={{
                        position: "absolute",
                        bottom: "2px",
                        fontWeight: "bold"
                    }}>   Data Hold Dana Rekening 
                    </span>
                    <button className="btn btn-outline-orange-primary float-right" onClick={() => {
                        rekAction.resetFormHold();
                        rekAction.toggleModalMode();
                    }}>Registrasi Hold Dana</button>
                </div>
            </div>}
            <div className="row">
                <div className="col">
                    <TableList
                        withCard={false}
                        isLoading={rekState.loadingHold}
                        data={rekState.dataHoldDana?.listhold || []}
                        columns={[
                            {
                                name: 'Nominal',
                                field: 'nominal_hold',
                                maxWidth: '150px',
                                type: 'currency',
                                className: "text-right",
                                onRender: (item) => {
                                    return addCommas(parseFloat(item.nominal_hold || "0").toFixed(2)) + " "
                                }
                            },
                            {
                                name: 'Nomor Referensi',
                                field: 'nomor_referensi',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Tgl, Hold',
                                field: 'tanggal_hold',
                                maxWidth: '150px',
                                onRender: (item) => {
                                    return moment(item.tanggal_hold, "YYYY/MM/DD").format("DD-MM-YYYY")
                                }
                            },
                            {
                                name: 'Tgl. Kadaluarsa',
                                field: 'tanggal_kadaluarsa',
                                maxWidth: '150px',
                                onRender: (item) => {
                                    return moment(item.tanggal_kadaluarsa, "YYYY/MM/DD").format("DD-MM-YYYY")
                                }
                            },
                            {
                                name: 'Status',
                                field: 'status',
                                maxWidth: '75px',
                            },
                            {
                                name: 'Sistem Pemblokiran',
                                field: 'sistem_hold',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Blokir Regulator',
                                field: 'is_regulatory',
                                maxWidth: '150px',
                                onRender: (item) => {
                                    if (item.is_regulatory === "F") return "false"
                                    else return "true"
                                }
                            },
                        ]}
                        pagination={false}
                        filter={false}
                        withAction={true}
                        actionItems={[
                            // {
                            //     name: 'Ubah Hold Dana',
                            //     onClick: (e) => {
                            //         rekAction.editHoldDana(e.id_hold_dana)
                            //     },
                            //     onRender: ({status}) => {
                            //         return status !== "NON AKTIF"
                            //      },
                            //     className: "text-danger"
                            // },
                            {
                                name: 'Hapus Hold Dana',
                                onRender: ({status}) => {
                                    return status !== "NON AKTIF"
                                 },
                                onClick: (e) => {
                                    //  rekAction.editUnHoldDana(e.id_hold_dana)
                                    rekAction.unHoldDana(e.id_hold_dana)
                                },
                                className: "text-danger"
                            },

                        ]}
                    />
                </div>
            </div>
        </>
    )
}

function RegisterDanaHold() {

    const { rekState, rekAction } = useRekening();
    return (
        <>
            <div className="row mt-3">
                <div className="col-6">
                    {
                        createForm({
                            type: "currency",
                            required: true,
                            placeholder: "Rp",
                            label: "Nominal Hold",
                            value: rekState.otorMode ? rekState.dataHoldDana?.nominal_hold : rekState.formHoldDana.nominal_hold,
                            disabled: rekState.otorMode  || rekState.unholdMode ,
                            onChange: (e) => rekAction.setFormHoldDana(prevState => ({ ...prevState, nominal_hold: e }))
                        })
                    }
                    {
                        createForm({
                            type: "date",
                            required: true,
                            label: "Tanggal Hold",
                            value: rekState.otorMode ? rekState.dataHoldDana?.tanggal_hold : rekState.formHoldDana.tanggal_hold,
                            disabled: rekState.otorMode || rekState.unholdMode  || rekState.modeEditHold,
                            onChange: (e) => rekAction.setFormHoldDana(prevState => ({ ...prevState, tanggal_hold: e }))
                        })
                    }
                    {
                        createForm({
                            type: "select",
                            required: true,
                            placeholder: "Pilih Blokir Regulator",
                            label: "Blokir Regulator",
                            value: rekState.otorMode ? rekState.dataHoldDana?.blokir_regulator : rekState.formHoldDana.blokir_regulator,
                            disabled: rekState.otorMode || rekState.unholdMode,
                            options: rekState.eBlokirRegulator,
                            key: "reference_code",
                            display: "reference_desc",
                            onChange: (e) => rekAction.setFormHoldDana(prevState => ({ ...prevState, blokir_regulator: e }))
                        })
                    }
                </div>
                <div className="col-6">
                    {
                        createForm({
                            type: "text",
                            required: true,
                            placeholder: "Masukkan Alasan Hold",
                            label: "Alasan Hold",
                            value: rekState.otorMode ? rekState.dataHoldDana?.alasan_hold : rekState.formHoldDana.alasan_hold,
                            disabled: rekState.otorMode || rekState.unholdMode,
                            onChange: (e) => rekAction.setFormHoldDana(prevState => ({ ...prevState, alasan_hold: e }))
                        })
                    }
                    {
                        createForm({
                            type: "date",
                            required: true,
                            label: "Tanggal Kadaluarsa",
                            value: rekState.otorMode ? rekState.dataHoldDana?.tanggal_kadaluarsa : rekState.formHoldDana.tanggal_kadaluarsa,
                            disabled: rekState.otorMode || rekState.modeEditHold,
                            onChange: (e) => rekAction.setFormHoldDana(prevState => ({ ...prevState, tanggal_kadaluarsa: e }))
                        })
                    }
                    {
                        createForm({
                            type: "text",
                            // required: true,
                            label: "Nomor Reference",
                            placeholder: "Masukkan Nomor Reference",
                            value: rekState.otorMode ? rekState.dataHoldDana?.nomor_referensi : rekState.formHoldDana.nomor_referensi,
                            disabled: rekState.otorMode || rekState.modeEditHold,
                            onChange: (e) => rekAction.setFormHoldDana(prevState => ({ ...prevState, nomor_referensi: e })),
                            onEnter: (v) => rekAction.cekNoReferensi(v),
                            onFocusOut: (v) => rekAction.cekNoReferensi(v)
                        })
                    }
                    {
                         rekState.dataOtor?.kode_entry === "HOLD003" && createForm({
                            type: "number",
                            required: true,
                            label: "Nomor Rekening",
                            value: rekState.dataHoldDana?.nomor_rekening,
                            disabled:true,
                        })
                    }
                </div>
            </div>
        </>
    )
}
export default ModalHoldDana;