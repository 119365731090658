import React, { memo, useRef } from 'react';
import {TableList } from '../../../controls';

class TolakanSetoranKliring extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  render() {
      return(
        <>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mt-2 mb-3">
              <li className="breadcrumb-item color-primary">Kliring</li>
              <li className="breadcrumb-item"><a href="#">Tolak Setoran Kliring</a></li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-white">
                  <h5 className="card-title">TOLAK SETORAN NOTA DEBIT</h5>
                </div>
                <div className="card-body">
                  <div className="bns-form">
                    <div className="form-row">
                      <div className="form-group col-4">
                        <label htmlFor="">No. Batch</label>
                        <input type="text" className="form-control" readOnly value="BO.2014.097.1482.0"/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <div className="text-primary mt-2 mb-4">Sumber Kliring</div>
                        <div className="form-row">
                          <div className="form-group col-7">
                            <label htmlFor="">Tanggal Efektif</label>
                            <select className="form-control">
                              <option value="">08-04-2015</option>
                            </select>
                          </div>
                          <div className="form-group col-5"></div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="">Sandi Bank Tertagih</label>
                          <div className="form-row">
                            <div className="col-5 pr-0">
                              <select className="form-control">
                                <option value="">4501231</option>
                              </select>
                            </div>
                            <div className="col-7 pl-0">
                              <input type="text" className="form-control" value="PT. BANK SYARIAH MANDIRI" readOnly/>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-5">
                            <label htmlFor="">Nomor Warkat</label>
                            <select className="form-control">
                              <option value="">451103</option>
                            </select>
                          </div>
                          <div className="form-group col-7">
                            <label htmlFor="">Nomor Rekening Warkat</label>
                            <select className="form-control">
                              <option value="">01200385777</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-7">
                            <label htmlFor="">Nama Pemilik Nota</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                          <div className="form-group col-5">
                            <label htmlFor="">Kurs Jual</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                        </div>
                        <div className="form-row mt-3">
                          <div className="form-group col-6">
                            <label htmlFor="">Nilai Transaksi</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                          <div className="form-group col-6">
                            <label htmlFor="">Nilai ekuivalen</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label htmlFor="">Biaya (Rp)</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                          <div className="form-group col-6">
                            <label htmlFor="">Biaya ekuivalen</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label htmlFor="">Sumber Biaya</label>
                            <select className="form-control">
                              <option value="">Tunai</option>
                            </select>
                          </div>
                          <div className="form-group col-6">
                            <label htmlFor="">No. Aplikasi</label>
                            <input type="text" className="form-control" readOnly/>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-12">
                            <label htmlFor="">Keterangan</label>
                            <textarea rows="1" className="form-control">TOLAKAN SETORAN NOTA DEBIT</textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-1"></div>
                      <div className="col-6">
                        <div className="text-primary mt-2 mb-4">Rekening Kredit</div>

                        <div className="form-group">
                          <label htmlFor="">No. Rekening</label>
                          <div className="form-row">
                            <div className="col-5 pr-0">
                            <input type="text" className="form-control"/>
                            </div>
                            <div className="col-7 pl-0">
                              <input type="text" className="form-control" placeholder="NAMA NASABAH" readOnly/>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="">Valuta Rekening</label>
                              <div className="form-row">
                                <div className="col-3 pr-0">
                                  <input type="text" className="form-control" value="IDR" readOnly/>
                                </div>
                                <div className="col-9 pl-0">
                                  <input type="text" className="form-control" value="INDONESIAN RUPIAH" readOnly/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="">Cabang Rekening</label>
                              <div className="form-row">
                                <div className="col-3 pr-0">
                                  <input type="text" className="form-control" value="000" readOnly/>
                                </div>
                                <div className="col-9 pl-0">
                                  <input type="text" className="form-control" value="KCP KOTA BANDUNG" readOnly/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mt-5 text-right">
                          <button className="btn btn-outline-secondary">Batal</button>
                          <button className="btn btn-primary ml-3 px-5">Proses</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
  }
}

export default TolakanSetoranKliring;