import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useDropdowns from '../../../../../../hooks/useDropdowns';
import useFetch from '../../../../../../../src/hooks/useFetch';
// import { useKeycloak } from '@react-keycloak/web';
import useAlamat from '../../../../../../hooks/useAlamat';
import useKeycloak from '../../../../../../hooks/keycloak.module';

const pwicContext = React.createContext(null);
export const WicProvider = ({ children }) => {

    const { doGet, doGetMultiple, doPost } = useFetch();
    const paramUrl = useParams();
    const [step, setStep] = useState(0);
    const [formEditable, setFormEditable] = useState(true);
    const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false });
    const [locationState, setLocationState] = useState({});
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState({loading: true, getData: false, loadingPost: { show: false, msg: "Please Wait ..." } });
    const [otorDesc, setOtorDesc] = useState("");
    const dataDropDown = useDropdowns();
    const { keycloak } = useKeycloak()
    const [isValid, setIsValid] = useState(false)
    const [isLoading, setIsLoading ] = useState(false)

    const {
        provinces,
        getCities,
        getVillages,
        getPostalsCode,
        getDistrics,
        getProvinces
    } = useAlamat();
    // // console.log(provinces)

    const [districts, setDistricts] = useState([])
    const [villages, setvillages] = useState([])
    const [cities, setCities] = useState([])
    const [postalCodes, setPostalCodes] = useState([])

    const [districtsKtp, setDistrictsKtp] = useState([])
    const [villagesKtp, setvillagesKtp] = useState([])
    const [citiesKtp, setCitiesKtp] = useState([])
    const [postalCodesKtp, setPostalCodesKtp] = useState([])

    const [districtsDom, setDistrictsDom] = useState([])
    const [villagesDom, setvillagesDom] = useState([])
    const [citiesDom, setCitiesDom] = useState([])
    const [postalCodesDom, setPostalCodesDom] = useState([])

    const [formData, setFormData] = useState(
        {
            "encodedKey": "",
            "id": "",
            "state": "",
            "firstName": "",//Nama Tanpa Singkatan
            "middleName": "",
            "lastName": "",
            "preferredLanguage": "",
            "notes": "",
            "gender": "",// Jenis Kelamin
            "homePhone": "", // No tlp Rumah 1
            "mobilePhone": "",//NO Hp
            "mobilePhone2": "",
            "emailAddress": "",// Alamat Email
            "birthDate": "",// Tgl LAhir
            "assignedBranchKey": "",
            "_personalIdData": {
              "personalIdDateOfIssue": moment(Date.now()).format("YYYY-MM-DD"),// Tgl terbit 
              "urlImageSelfie": "",
              "personalMarketingOfficer": "",
              "personalReferralCode": "",
              "personalIdExpireDate": moment(Date.now()).format("YYYY-MM-DD"),// Tgl Berakhir
              "personalIdNumber": "",//Nomor Identitas
              "personalNpwpNumber": "",//NPWP
              "personalIdType": "",// Jenis Identitas
              "populationType": "",
              "urlImageKtp": "",
              "personalIdHolderName": "",//Nama Sesuai Identitas
              "personalIdIssuedPlace": ""
            },
            "_mailingAddress": {
              "mailingAddressPostalCode": "",
              "mailingRecipientPhoneNumber": "",//TIDAK DIGUNAKAN
              "mailingAddress": "",
              "mailingAddressCity": "",
              "mailingAddressVillage": "",
              "mailingAddressProvince": "",
              "mailingAddressRt": "",
              "mailingAddressRw": "",
              "mailingAddressSubDistrict": ""
            },
            "_occupationInfo": {
              "personalOccupation": "",//Pekerjaan
              "companyName": "",//Nama Perusahaan
              "personalMonthlyIncome": "",
              "personalTypeOfWork": "",//Badan Hukum
              "personalJobPosition": "",//Jabatan Perusahaan
              "legalEntity": "" //Badan Hukum
            },
            "_customerData": {
              "birthPlace": "",// Temapat Lahir
              "personalCitizenship": "", // Jenis Penduduk
              "personalCity": "",
              "personalReligion": "",// Agama 
              "personalMothersName": "",// Ibu Kandung
              "personalMaritalStatus": "",// Status Perkawinan
              "personalScoringCust": "",
              "customerEducation": "",
              "customerAliasName": "",// Nama Singkat
              "customerPreTitle": "",// Gelar Depan
              "customerSpouseSecondIncome": "",
              "customerBankRelation": "",
              "customerSpouseName": "",
              "customerSpouseOccupation": "",
              "customerPostTitle": "",// Gelar Belakang
              "customerSpouseMainIncome": ""
            },
            "_companyAddress": {
              "companyAddressCity": "",
              "companyAddressRt": "",
              "companyAddressRw": "",
              "companyAddressVillage": "",
              "companyAddress": "",
              "companyAddressSubDistrict": "",
              "companyAddressPostalCode": "",
              "companyAddressProvince": "",
              "companyAddressPhoneNumber": "",
              "companyAddressFaxNumber": ""
            },
            "_ktpAddress": {
              "ktpRt": "",
              "ktpRw": "",
              "ktpSubDistrict": "",
              "ktpVillage": "",
              "ktpAddress": "",
              "ktpCity": "",
              "ktpProvince": "",
              "ktpPostalCode": "",
              "ktpCountry": ""
            }
          }
    );

    useEffect(function () {
        setLocationState(location.state);
    }, [location.state])

    // function stepper
    function clickStep(id) { setStep(id); }
    function prevState() { step > 0 && setStep(prevStep => prevStep - 1); }
    function nextStep() {
        const nik_length = formData?._personalIdData?.personalIdNumber?.length
        if (nik_length !== 16) {
            return setDataAlert({ show: true, body: "NIK KTP Harus 16 Digit. Silahkan cek kembali", title: `Data Belum Valid`, header: "Warning", callBack: () => null });
        }
        let dataRequired = [
            "firstName",//Nama Tanpa Singkatan
            "middleName",
            "lastName",
            "preferredLanguage",
            "notes",
            "gender",// Jenis Kelamin
            "homePhone", // No tlp Rumah 1
            "mobilePhone",//NO Hp
            "mobilePhone2",
            "emailAddress",// Alamat Email
            "birthDate",// Tgl LAhir
            "assignedBranchKey",

            "personalIdDateOfIssue",// Tgl terbit 
            "urlImageSelfie",
            "personalMarketingOfficer",
            "personalReferralCode",
            "personalIdExpireDate",// Tgl Berakhir
            "personalIdNumber",//Nomor Identitas
            "personalNpwpNumber",//NPWP
            "personalIdType",// Jenis Identitas
            "populationType",//Jenis Penduduk
            "urlImageKtp",
            "personalIdHolderName",//Nama Sesuai Identitas
            "personalIdIssuedPlace",
        
            "mailingAddressPostalCode",
            "mailingRecipientPhoneNumber",//TIDAK DIGUNAKAN
            "mailingAddress",
            "mailingAddressCity",
            "mailingAddressVillage",
            "mailingAddressProvince",
            "mailingAddressRt",
            "mailingAddressRw",
            "mailingAddressSubDistrict",

            "birthPlace",// Temapat Lahir
            "personalCitizenship", // Negara Asal
            "personalCity",
            "personalReligion",// Agama 
            "personalMothersName",// Ibu Kandung
            "personalMaritalStatus",// Status Perkawinan
            "personalScoringCust",
            "customerEducation",
            "customerAliasName",// Nama Singkat
            "customerPreTitle",// Gelar Depan
            "customerSpouseSecondIncome",
            "customerBankRelation",
            "customerSpouseName",
            "customerSpouseOccupation",
            "customerPostTitle",// Gelar Belakang
            "customerSpouseMainIncome",

            "ktpRt",
            "ktpRw",
            "ktpSubDistrict",
            "ktpVillage",
            "ktpAddress",
            "ktpCity",
            "ktpProvince",
            "ktpPostalCode",
            "ktpCountry"


        ];
        if (step === 1) {
            dataRequired = [

                "personalOccupation",//Pekerjaan
                "companyName",//Nama Perusahaan
                "personalMonthlyIncome",
                "personalTypeOfWork",//Badan Hukum
                "personalJobPosition",//Jabatan Perusahaan
                "legalEntity", //Badan Hukum

                "companyAddressCity",
                "companyAddressRt",
                "companyAddressRw",
                "companyAddressVillage",
                "companyAddress",
                "companyAddressSubDistrict",
                "companyAddressPostalCode",
                "companyAddressProvince",
                "companyAddressPhoneNumber",
                "companyAddressFaxNumber",
            ]
        }

        step !== 2 && setStep(prevStep => prevStep + 1);
    }

    useEffect(() => {
        if (paramUrl.mode === "otorisasi" || paramUrl.mode === "edit" || paramUrl.mode === "detil") {
            // // // console.log(location.state)
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            if(provinces.length >0 )
            {
                getDataPerorangan(location.state?.dbId);
            }
            // // // console.log(location.state?.dbId)
        }else setLoading(false)
        // } 
        setFormEditable(paramUrl.mode === "registration" || paramUrl.mode === "edit");
    }, [paramUrl.mode, provinces])
    
    // useEffect(function(){
    //     // // console.log(formData)
    // },[formData])
    // function getData Perorangan
    async function getDataPerorangan(dbId) {
        try {
            let url = `/wic/${dbId}`;
            if (paramUrl.mode === "otorisasi") url = `/wic/local/${dbId}`;

            setIsLoading(true);
            let { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url, service : "cif" });
            if (statusCode === 200) {
                if (status === "00") {
                    if (data !== null) {
                            const {nasabahBadanUsaha} = data;
                        // const { nasabahBadanUsaha } = data;
                        // let stateBase = {
                        //     // dataConverter.nasabahIndividu(nasabahBadanUsaha)
                        // }
                        // const keys_formData = Object.keys(formData);
                        if (paramUrl.mode === "otorisasi") {
                            // // console.log(data)
                            setFormData(prevState => ({
                            ...prevState,
                                "id": nasabahBadanUsaha.id,
                                "firstName": nasabahBadanUsaha.firstName,//Nama Tanpa Singkatan
                                "gender": nasabahBadanUsaha.gender,// Jenis Kelamin
                                "homePhone": nasabahBadanUsaha.homePhone, // No tlp Rumah 1
                                "mobilePhone": nasabahBadanUsaha.mobilePhone,//NO Hp
                                "emailAddress": nasabahBadanUsaha.emailAddress,// Alamat Email
                                "birthDate": nasabahBadanUsaha.birthDate,// Tgl LAhir
                                "_personalIdData": {
                                    "personalIdDateOfIssue": nasabahBadanUsaha.personalIdDateOfIssue,// Tgl terbit 
                                    "personalIdExpireDate": nasabahBadanUsaha.personalIdExpireDate,// Tgl Berakhir
                                    "personalIdNumber": nasabahBadanUsaha.personalIdNumber,//Nomor Identitas
                                    "personalNpwpNumber": nasabahBadanUsaha.personalNpwpNumber,//NPWP
                                    "personalIdType": nasabahBadanUsaha.personalIdType,// Jenis Identitas
                                    "populationType": nasabahBadanUsaha.populationType,
                                    // "urlImageKtp": data.,
                                    "personalIdHolderName": nasabahBadanUsaha.personalIdHolderName,//Nama Sesuai Identitas
                                    "personalIdIssuedPlace": nasabahBadanUsaha.personalIdIssuedPlace,
                                },
                                "_mailingAddress": {
                                    "mailingAddressPostalCode": nasabahBadanUsaha.mailingAddressPostalCode,
                                    "mailingRecipientPhoneNumber": nasabahBadanUsaha.mailingRecipientPhoneNumber,//TIDAK DIGUNAKAN
                                    "mailingAddress": nasabahBadanUsaha.mailingAddress,
                                    "mailingAddressCity": nasabahBadanUsaha.mailingAddressCity,
                                    "mailingAddressVillage": nasabahBadanUsaha.mailingAddressVillage,
                                    "mailingAddressProvince": nasabahBadanUsaha.mailingAddressProvince,
                                    "mailingAddressRt": nasabahBadanUsaha.mailingAddressRt,
                                    "mailingAddressRw": nasabahBadanUsaha.mailingAddressRw,
                                    "mailingAddressSubDistrict": nasabahBadanUsaha.mailingAddressSubDistrict,
                                },
                                "_occupationInfo": {
                                    "personalOccupation": nasabahBadanUsaha.personalOccupation,//Pekerjaan
                                    "companyName": nasabahBadanUsaha.companyName,//Nama Perusahaan
                                    "personalMonthlyIncome": nasabahBadanUsaha.personalMonthlyIncome,
                                    "personalTypeOfWork": nasabahBadanUsaha.personalTypeOfWork,//Badan Hukum
                                    "personalJobPosition": nasabahBadanUsaha.personalJobPosition,//Jabatan Perusahaan
                                    "legalEntity": nasabahBadanUsaha.legalEntity //Badan Hukum
                                },
                                "_customerData": {
                                    "birthPlace": nasabahBadanUsaha.birthPlace,// Temapat Lahir
                                    "personalCitizenship": nasabahBadanUsaha.personalCitizenship, // Jenis Penduduk
                                    "personalCity": nasabahBadanUsaha.personalCity,
                                    "personalReligion": nasabahBadanUsaha.personalReligion,// Agama 
                                    "personalMothersName": nasabahBadanUsaha.personalMothersName,// Ibu Kandung
                                    "personalMaritalStatus": nasabahBadanUsaha.personalMaritalStatus,// Status Perkawinan
                                    "personalScoringCust": nasabahBadanUsaha.personalScoringCust,
                                    "customerEducation": nasabahBadanUsaha.customerEducation,
                                    "customerAliasName": nasabahBadanUsaha.customerAliasName,// Nama Singkat
                                    "customerPreTitle": nasabahBadanUsaha.customerPreTitle,// Gelar Depan
                                    "customerSpouseSecondIncome": nasabahBadanUsaha.customerSpouseSecondIncome,
                                    "customerBankRelation": nasabahBadanUsaha.customerBankRelation,
                                    "customerSpouseName": nasabahBadanUsaha.customerSpouseName,
                                    "customerSpouseOccupation": nasabahBadanUsaha.customerSpouseOccupation,
                                    "customerPostTitle": nasabahBadanUsaha.customerPostTitle,// Gelar Belakang
                                    "customerSpouseMainIncome": nasabahBadanUsaha.customerSpouseMainIncome,
                                },
                                "_companyAddress": {
                                    "companyAddressCity": nasabahBadanUsaha.companyAddressCity,
                                    "companyAddressRt": nasabahBadanUsaha.companyAddressRt,
                                    "companyAddressRw": nasabahBadanUsaha.companyAddressRw,
                                    "companyAddressVillage": nasabahBadanUsaha.companyAddressVillage,
                                    "companyAddress": nasabahBadanUsaha.companyAddress,
                                    "companyAddressSubDistrict": nasabahBadanUsaha.companyAddressSubDistrict,
                                    "companyAddressPostalCode": nasabahBadanUsaha.companyAddressPostalCode,
                                    "companyAddressProvince": nasabahBadanUsaha.companyAddressProvince,
                                    "companyAddressPhoneNumber": nasabahBadanUsaha.companyAddressPhoneNumber,
                                    "companyAddressFaxNumber": nasabahBadanUsaha.companyAddressFaxNumber
                                },
                                "_ktpAddress": {
                                    "ktpRt": nasabahBadanUsaha.ktpRt,
                                    "ktpRw": nasabahBadanUsaha.ktpRw,
                                    "ktpSubDistrict": nasabahBadanUsaha.ktpSubDistrict,
                                    "ktpVillage": nasabahBadanUsaha.ktpVillage,
                                    "ktpAddress": nasabahBadanUsaha.ktpAddress,
                                    "ktpCity": nasabahBadanUsaha.ktpCity,
                                    "ktpProvince": nasabahBadanUsaha.ktpProvince,
                                    "ktpPostalCode": nasabahBadanUsaha.ktpPostalCode,
                                    "ktpCountry": nasabahBadanUsaha.ktpCountry
                                }
                            }));
                        
                        } else {
                            // console.log(nasabahBadanUsaha)
                            setFormData(prevState => ({
                                ...prevState,
                                "id": nasabahBadanUsaha.id,
                                "firstName": nasabahBadanUsaha.firstName,//Nama Tanpa Singkatan
                                "gender": nasabahBadanUsaha.gender,// Jenis Kelamin
                                "homePhone": nasabahBadanUsaha.homePhone, // No tlp Rumah 1
                                "mobilePhone": nasabahBadanUsaha.mobilePhone,//NO Hp
                                "emailAddress": nasabahBadanUsaha.emailAddress,// Alamat Email
                                "birthDate": nasabahBadanUsaha.birthDate,// Tgl LAhir
                                // "birthDate": moment(Date.now()).format("YYYY-MM-DD"),// Tgl LAhir
                                "_personalIdData": {
                                //     "personalIdDateOfIssue": nasabahBadanUsaha.personalIdDateOfIssue,// Tgl terbit 
                                //     "personalIdExpireDate": nasabahBadanUsaha.personalIdExpireDate,// Tgl Berakhir
                                    "personalIdDateOfIssue": moment(Date.now()).format("YYYY-MM-DD"),// Tgl terbit 
                                    "personalIdExpireDate": moment(Date.now()).format("YYYY-MM-DD"),// Tgl Berakhir
                                    "personalIdNumber": nasabahBadanUsaha.personalIdNumber,//Nomor Identitas
                                    "personalNpwpNumber": nasabahBadanUsaha.personalNpwpNumber,//NPWP
                                    "personalIdType": nasabahBadanUsaha.personalIdType,// Jenis Identitas
                                    "populationType": nasabahBadanUsaha.populationType,
                                    // "urlImageKtp": data.,
                                    "personalIdHolderName": nasabahBadanUsaha.personalIdHolderName,//Nama Sesuai Identitas
                                    "personalIdIssuedPlace": nasabahBadanUsaha.personalIdIssuedPlace,
                                },
                                "_mailingAddress": {
                                    "mailingAddressPostalCode": nasabahBadanUsaha.mailingAddressPostalCode,
                                    "mailingRecipientPhoneNumber": nasabahBadanUsaha.mailingRecipientPhoneNumber,//TIDAK DIGUNAKAN
                                    "mailingAddress": nasabahBadanUsaha.mailingAddress,
                                    "mailingAddressCity": nasabahBadanUsaha.mailingAddressCity,
                                    "mailingAddressVillage": nasabahBadanUsaha.mailingAddressVillage,
                                    "mailingAddressProvince": nasabahBadanUsaha.mailingAddressProvince,
                                    "mailingAddressRt": nasabahBadanUsaha.mailingAddressRt,
                                    "mailingAddressRw": nasabahBadanUsaha.mailingAddressRw,
                                    "mailingAddressSubDistrict": nasabahBadanUsaha.mailingAddressSubDistrict,
                                    "mailingAddressPostalCode" : nasabahBadanUsaha.mailingAddressPostalCode
                                },
                                "_occupationInfo": {
                                    "personalOccupation": nasabahBadanUsaha.personalOccupation,//Pekerjaan
                                    "companyName": nasabahBadanUsaha.companyName,//Nama Perusahaan
                                    "personalMonthlyIncome": nasabahBadanUsaha.personalMonthlyIncome,
                                    "personalTypeOfWork": nasabahBadanUsaha.personalTypeOfWork,//Badan Usaha
                                    "personalJobPosition": nasabahBadanUsaha.personalJobPosition,//Jabatan Perusahaan
                                    "legalEntity": nasabahBadanUsaha.legalEntity //Badan Hukum
                                },
                                "_customerData": {
                                    "birthPlace": nasabahBadanUsaha.birthPlace,// Temapat Lahir
                                    "personalCitizenship": nasabahBadanUsaha.personalCitizenship, // Jenis Penduduk
                                    "personalCity": nasabahBadanUsaha.personalCity,
                                    "personalReligion": nasabahBadanUsaha.personalReligion,// Agama 
                                    "personalMothersName": nasabahBadanUsaha.personalMothersName,// Ibu Kandung
                                    "personalMaritalStatus": nasabahBadanUsaha.personalMaritalStatus,// Status Perkawinan
                                    "personalScoringCust": nasabahBadanUsaha.personalScoringCust,
                                    "customerEducation": nasabahBadanUsaha.customerEducation,
                                    "customerAliasName": nasabahBadanUsaha.customerAliasName,// Nama Singkat
                                    "customerPreTitle": nasabahBadanUsaha.customerPreTitle,// Gelar Depan
                                    "customerSpouseSecondIncome": nasabahBadanUsaha.customerSpouseSecondIncome,
                                    "customerBankRelation": nasabahBadanUsaha.customerBankRelation,
                                    "customerSpouseName": nasabahBadanUsaha.customerSpouseName,
                                    "customerSpouseOccupation": nasabahBadanUsaha.customerSpouseOccupation,
                                    "customerPostTitle": nasabahBadanUsaha.customerPostTitle,// Gelar Belakang
                                    "customerSpouseMainIncome": nasabahBadanUsaha.customerSpouseMainIncome,
                                },
                                "_companyAddress": {
                                    "companyAddressCity": nasabahBadanUsaha.companyAddressCity,
                                    "companyAddressRt": nasabahBadanUsaha.companyAddressRt,
                                    "companyAddressRw": nasabahBadanUsaha.companyAddressRw,
                                    "companyAddressVillage": nasabahBadanUsaha.companyAddressVillage,
                                    "companyAddress": nasabahBadanUsaha.companyAddress,
                                    "companyAddressSubDistrict": nasabahBadanUsaha.companyAddressSubDistrict,
                                    "companyAddressPostalCode": nasabahBadanUsaha.companyAddressPostalCode,
                                    "companyAddressProvince": nasabahBadanUsaha.companyAddressProvince,
                                    "companyAddressPhoneNumber": nasabahBadanUsaha.companyAddressPhoneNumber,
                                    "companyAddressFaxNumber": nasabahBadanUsaha.companyAddressFaxNumber
                                },
                                "_ktpAddress": {
                                    "ktpRt": nasabahBadanUsaha.ktpRt,
                                    "ktpRw": nasabahBadanUsaha.ktpRw,
                                    "ktpSubDistrict": nasabahBadanUsaha.ktpSubDistrict,
                                    "ktpVillage": nasabahBadanUsaha.ktpVillage,
                                    "ktpAddress": nasabahBadanUsaha.ktpAddress,
                                    "ktpCity": nasabahBadanUsaha.ktpCity,
                                    "ktpProvince": nasabahBadanUsaha.ktpProvince,
                                    "ktpPostalCode": nasabahBadanUsaha.ktpPostalCode,
                                    "ktpCountry": nasabahBadanUsaha.ktpCountry
                                }
                                }));

                        }
                        const province_codektp = nasabahBadanUsaha.ktpProvince
                        // // // console.log(province_codektp)
                        const province_codedom = nasabahBadanUsaha.mailingAddressProvince
                        const province_code = nasabahBadanUsaha.companyAddressProvince
                        // // console.log(province_codedom, province_code, province_codektp)
                        await Promise.all([
                            getingCitiesKtp(province_codektp),
                            // getingDistrictKtp(nasabahBadanUsaha.ktpCity),
                            // getingVillagesKtp(nasabahBadanUsaha.ktpCity, nasabahBadanUsaha.ktpSubDistrict),
                            // getingPostalCodesKtp(nasabahBadanUsaha.ktpCity, nasabahBadanUsaha.ktpSubDistrict, nasabahBadanUsaha.ktpVillage),
                            getingCitiesDom(province_codedom),
                            // getingDistrictDom(nasabahBadanUsaha.mailingAddressCity),
                            // getingVillagesDom(nasabahBadanUsaha.mailingAddressCity, nasabahBadanUsaha.mailingAddressSubDistrict),
                            // getingPostalCodesDom(nasabahBadanUsaha.mailingAddressCity, nasabahBadanUsaha.mailingAddressSubDistrict, nasabahBadanUsaha.mailingAddressVillage),
                            getingCities(province_code),
                            // getingDistrict(nasabahBadanUsaha.companyAddressCity),
                            // getingVillages(nasabahBadanUsaha.companyAddressCity, nasabahBadanUsaha.companyAddressSubDistrict),
                            // getingPostalCodes(nasabahBadanUsaha.companyAddressCity, nasabahBadanUsaha.companyAddressSubDistrict, nasabahBadanUsaha.companyAddressVillage),
                        ])
                    }

                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => history.goBack() });
            } else setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => history.goBack() });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => history.goBack() });
        }
        setIsLoading(false)
    }
    
    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} nasabah ini ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // fetch API response 
    async function fetchOtorisasi(statusApprove) {
        setLoading(prevState => ({ ...prevState, loadingPost: { show: true, msg: "Please Wait ..." } }));
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (locationState !== null && locationState !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${locationState.kode_entry}/${locationState.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({ show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => history.goBack() });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Info", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Info", callBack: () => null });

        setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        if(typeof value === "string") value = value.toUpperCase()

        if (subkey !== null) {
            setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }));
            if (key === "personalIdNumber" || key === "personalIdHolderName" || key === "birthDate") {
                setIsValid(false)
            }
        }
        else {
            setFormData(prevState => ({ ...prevState, [key]: value }));
            if (key === "personalIdNumber" || key === "personalIdHolderName" || key === "birthDate") {
                setIsValid(false)
            }
        }
    }

    //  fungsi validasi
    function validasi(fieldRequired = []) {

        //  field required lists
        if (fieldRequired.length === 0)
            fieldRequired = [
                "personalIdType", "personalIdNumber", "birthPlace", "birthDate",
                "personalMothersName", "gender"
            ];

        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    // close Alert
    function closeAlert() { setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null }) }

    // closeConfirm
    function closeConfirm() { setDataConfirm({ show: false, body: "", header: null, title: "", footerButton: [], closeButton: true, witTextArea: false }) }
    
    // close Loading
    function closeLoading() { setLoading(prevState => ({ getData: false, loadingPost: { msg: "Please Wait ...", show: false } })) }

    // get province_code
    async function getProvinceCode(province_name) {
        if (provinces !== null && provinces !== undefined) {
            const dataP = provinces?.find(v => v.province_name === province_name);
            // // console.log(province_name, dataP, provinces)
            return dataP?.province_code || "";
        }
    }

    // get cities KTP
    async function getingCitiesKtp(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        // // // console.log(newData)
        setCitiesKtp(data);
    }

    // // get districts KTP
    // async function getingDistrictKtp(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistrictsKtp(newData);
    // }
    // // get village KTP
    // async function getingVillagesKtp(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillagesKtp(newData);
    // }
    // // get postalcode KTP
    // async function getingPostalCodesKtp(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodesKtp(newData);
    // }

    // get cities Domisili
    async function getingCitiesDom(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCitiesDom(data);
    }

    // // get districts Domisili
    // async function getingDistrictDom(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistrictsDom(newData);
    // }
    // // get village Domisili
    // async function getingVillagesDom(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillagesDom(newData);
    // }
    // // get postalcode Domisili
    // async function getingPostalCodesDom(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodesDom(newData);
    // }

    // get cities 1
    async function getingCities(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCities(data);
    }

    // // get districts 1
    // async function getingDistrict(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistricts(newData);
    // }
    // // get village 1
    // async function getingVillages(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillages(newData);
    // }
    // // get postalcode 1
    // async function getingPostalCodes(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodes(newData);
    // }

     // salin data alamat domisili
     async function salinDataAlamatDomisili() {
        // if(formData._ktpAddress.ktpProvince !=)
        const provcode = formData._ktpAddress.ktpProvince
        await (
            Promise.all([
                getingCitiesDom(provcode),
                // getingDistrictDom(formData._ktpAddress.ktpCity),
                // getingVillagesDom(formData._ktpAddress.ktpCity, formData._ktpAddress.ktpSubDistrict),
                // getingPostalCodesDom(formData._ktpAddress.ktpCity, formData._ktpAddress.ktpSubDistrict, formData._ktpAddress.ktpVillage)
            ])
        )

        changeForm({
            ...formData._ktpAddress,
            "mailingAddressPostalCode": formData._ktpAddress.ktpPostalCode,
            "mailingAddress": formData._ktpAddress.ktpAddress,
            "mailingAddressVillage": formData._ktpAddress.ktpVillage,
            "mailingAddressProvince": formData._ktpAddress.ktpProvince,
            "mailingAddressRt": formData._ktpAddress.ktpRt,
            "mailingAddressRw": formData._ktpAddress.ktpRw,
            "mailingAddressSubDistrict": formData._ktpAddress.ktpSubDistrict,
            "mailingAddressCity": formData._ktpAddress.ktpCity,
        }, "_mailingAddress")

    }

    // confirm Post Nasabah
    function confirmPostNasabah() {
        // // // console.log(paramUrl.mode)
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan ${formType}, cek kembali form ${formType}. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => paramUrl.mode === "edit" ? updateNasabah() : postNasabah(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

     // fungsi post nasabah
     async function postNasabah() {
        //  // console.log(formData)
        const userInfo = await keycloak.loadUserInfo();
        const err = validasi();
        if (err) {
            closeConfirm()
            return setDataAlert({ show: true, body: "Periksa kembali form pendaftaran, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        setLoading(prevState => ({ ...prevState, loadingPost: { msg: "Please Wait ...", show: true } }));
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: formData,
                url: "/wic"
            });
            // // // console.log(data);
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Registrasi Nasabah Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => history.goBack() });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body:error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }

    // fungsi put nasabah
    async function updateNasabah() {
        // console.log("ubah")
        // const err = validasi();
        // if (err) {
        //     closeConfirm()
        //     return setDataAlert({ show: true, body: "Periksa kembali form ubah Data, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        // setLoading(prevState => ({ ...prevState, loadingPost: { msg: "Please Wait ...", show: true } }));
        try {
            const userInfo = await keycloak.loadUserInfo();
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: formData,
                url: `/wic`,
                method: "PUT"
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Ubah Nasabah Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => history.goBack() });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }

    
    function handleShortcut(e) {
        const { key, keyCode } = e;
        if (keyCode === 37) {

            prevState()
        } if (keyCode === 39) {
            nextStep()
            // setUserText(prevUserText => `${prevUserText}${key}`);
        }
    }

    function handleShiftUp(e) {
        const { key, keyCode } = e;
        if (keyCode === 16) {
            
            document.removeEventListener("keyup", handleShortcut)
        }
    }


    function handleShiftDown(e) {
        const { key, keyCode } = e;
        if (keyCode === 16) {
            document.addEventListener("keyup", handleShortcut)
        }
    }

    function resetForm() {
        setFormData({
            "encodedKey": "",
            "id": "",
            "state": "",
            "firstName": "",//Nama Tanpa Singkatan
            "middleName": "",
            "lastName": "",
            "preferredLanguage": "",
            "notes": "",
            "gender": "",// Jenis Kelamin
            "homePhone": "", // No tlp Rumah 1
            "mobilePhone": "",//NO Hp
            "mobilePhone2": "",
            "emailAddress": "",// Alamat Email
            "birthDate": "",// Tgl LAhir
            "assignedBranchKey": "",
            "_personalIdData": {
              "personalIdDateOfIssue": moment(Date.now()).format("YYYY-MM-DD"),// Tgl terbit 
              "urlImageSelfie": "",
              "personalMarketingOfficer": "",
              "personalReferralCode": "",
              "personalIdExpireDate": moment(Date.now()).format("YYYY-MM-DD"),// Tgl Berakhir
              "personalIdNumber": "",//Nomor Identitas
              "personalNpwpNumber": "",//NPWP
              "personalIdType": "",// Jenis Identitas
              "populationType": "",
              "urlImageKtp": "",
              "personalIdHolderName": "",//Nama Sesuai Identitas
              "personalIdIssuedPlace": ""
            },
            "_mailingAddress": {
              "mailingAddressPostalCode": "",
              "mailingRecipientPhoneNumber": "",//TIDAK DIGUNAKAN
              "mailingAddress": "",
              "mailingAddressCity": "",
              "mailingAddressVillage": "",
              "mailingAddressProvince": "",
              "mailingAddressRt": "",
              "mailingAddressRw": "",
              "mailingAddressSubDistrict": ""
            },
            "_occupationInfo": {
              "personalOccupation": "",//Pekerjaan
              "companyName": "",//Nama Perusahaan
              "personalMonthlyIncome": "",
              "personalTypeOfWork": "",//Badan Hukum
              "personalJobPosition": "",//Jabatan Perusahaan
              "legalEntity": "" //Badan Hukum
            },
            "_customerData": {
              "birthPlace": "",// Temapat Lahir
              "personalCitizenship": "", // Jenis Penduduk
              "personalCity": "",
              "personalReligion": "",// Agama 
              "personalMothersName": "",// Ibu Kandung
              "personalMaritalStatus": "",// Status Perkawinan
              "personalScoringCust": "",
              "customerEducation": "",
              "customerAliasName": "",// Nama Singkat
              "customerPreTitle": "",// Gelar Depan
              "customerSpouseSecondIncome": "",
              "customerBankRelation": "",
              "customerSpouseName": "",
              "customerSpouseOccupation": "",
              "customerPostTitle": "",// Gelar Belakang
              "customerSpouseMainIncome": ""
            },
            "_companyAddress": {
              "companyAddressCity": "",
              "companyAddressRt": "",
              "companyAddressRw": "",
              "companyAddressVillage": "",
              "companyAddress": "",
              "companyAddressSubDistrict": "",
              "companyAddressPostalCode": "",
              "companyAddressProvince": "",
              "companyAddressPhoneNumber": "",
              "companyAddressFaxNumber": ""
            },
            "_ktpAddress": {
              "ktpRt": "",
              "ktpRw": "",
              "ktpSubDistrict": "",
              "ktpVillage": "",
              "ktpAddress": "",
              "ktpCity": "",
              "ktpProvince": "",
              "ktpPostalCode": "",
              "ktpCountry": ""
            }
        })
    }

    function checkHpWic(value, callback=()=>null) {
        if (value.length  < 10){
            setDataAlert({ show: true, body: `No Handphone minimal 10 digit`, title: `Cek Kembali No HandPhone`, header: "Warning", callBack: () => callback() });
        }else{
            changeForm(value, "mobilePhone")
        }
    }
    // fungsi setDataForm
    function changeMailForm(value, key, subkey = null) {
        if (typeof value === "string") 
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }
//Post Auditrail
async function auditTrail() {
    const userInfo = await keycloak.loadUserInfo();
    // console.log(userInfo)
    var url = `/useraudit/user-audit-trail`;
    try {
        const { statusCode, status, remark, statusText, data = {} } = await doPost({
            url: url,
            service: "acc",
            data: { 
                    user_id : userInfo.preferred_username,
                    app_id : "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Registrasi",
                    event_description: "WIC/Registrasi WIC",
                    info1: "-",
                    info2: "-"
            },
            headers: {
                "x-user-id": userInfo.preferred_username,
                method: "POST"
            }
        });

        if (statusCode === 200) {
            if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
            else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
        } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
    } catch (error) {
        setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
    }
    
}
    return <pwicContext.Provider value={{
        fpwState: {
            paramUrl,
            step,
            formEditable,
            dataAlert,
            dataConfirm,
            loading,
            dataDropDown,
            formData,
            isValid,
            provinces,
            districtsKtp,
            villagesKtp,
            postalCodesKtp,
            citiesKtp,
            districtsDom,
            villagesDom,
            postalCodesDom,
            citiesDom,
            districts,
            villages,
            postalCodes,
            cities,
            isLoading
        },
        fpwAction: {
            changeForm,
            clickStep,
            prevState,
            nextStep,
            closeAlert,
            closeConfirm,
            getingCitiesKtp,
            // getingDistrictKtp,
            // getingPostalCodesKtp,
            // getingVillagesKtp,
            getingCitiesDom,
            // getingDistrictDom,
            // getingPostalCodesDom,
            // getingVillagesDom,
            getingCities,
            // getingDistrict,
            // getingPostalCodes,
            // getingVillages,
            validasi,
            confirmPostNasabah,
            responseOtor,
            setOtorDesc,
            salinDataAlamatDomisili,
            getProvinceCode,
            handleShiftUp,
            handleShiftDown,
            resetForm,
            changeMailForm,
            checkHpWic,
            auditTrail
        }
    }}>
        {children}
    </pwicContext.Provider>
}

export default function useFormPerorangan() {
    return useContext(pwicContext)
};