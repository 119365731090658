import clsx from 'clsx';
import { FormControl } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { ActionMore, TableGrid } from '../../controls';
import { useState, useMemo } from 'react';
import { baseUrl } from '../../../app.config';
import TutupRekening from './tutupRekening';
import BlokirRekeningModal from './blokirRekening';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { IconMoney, IconPrint } from './icon';
import CetakAdvice from './cetakAdvice';
import CetakBillyet from './cetakBilyet';
import NotaPembayaran from './notaPembayaran';
import PencairanDeposito from './pencairanDeposito';
import { PerubahanNisbah } from './perubahanNisbah';
import ReversePencairan from './reversePencairan';

const DepositoPage = () => {
    let history = useHistory();
    const queryClient = useQueryClient();

    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [jenisNasabah, setJenisNasabah] = useState('');
    const [status, setStatus] = useState('');
    const [dataModal, setDataModal] = useState({});
    const [tipeTutupRekening, setTipeTutupRekening] = useState('tutup');
    const [tipeBlokirRekening, setTipeBlokirRekening] = useState('blokir');

    const [modal, setModal] = useState({
        tutupRekeningDeposito: false,
        blokirRekeningDeposito: false,
        cetakAdvice: false,
        cetakBillyet: false,
        notaPembayaran: false,
        pencairanDeposito: false,
        perubahanNisbah: false,
        reversePencairan: false,
    });

    const { data, isFetchedAfterMount } = useQuery(
        ['data-deposito-approved', jenisNasabah, currentPage, search, status, limit],
        async () => {
            const res = await fetch(
                baseUrl +
                    `/search/rekening?search=${search}&page=${currentPage}&limit=${limit}&jenis_tabungan=FIXED_DEPOSIT&status=${status}&jenis_nasabah=${jenisNasabah}`
            );

            const data = await res.json();

            return data.list;
        },
        {
            keepPreviousData: true,
        }
    );

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    const handleSearch = (e) => {
        e.preventDefault();

        const { elements } = e.target;

        setSearch(elements.search.value);
    };

    return (
        <>
            <CetakAdvice
                show={modal.cetakAdvice}
                onHide={() => setModal({ ...modal, cetakAdvice: false })}
            />
            <CetakBillyet
                show={modal.cetakBillyet}
                onHide={() => setModal({ ...modal, cetakBillyet: false })}
            />
            <NotaPembayaran
                show={modal.notaPembayaran}
                onHide={() => setModal({ ...modal, notaPembayaran: false })}
            />
            <PencairanDeposito
                show={modal.pencairanDeposito}
                onHide={() => setModal({ ...modal, pencairanDeposito: false })}
            />
            <PerubahanNisbah
                show={modal.perubahanNisbah}
                onHide={() => setModal({ ...modal, perubahanNisbah: false })}
            />
            <ReversePencairan
                show={modal.reversePencairan}
                onHide={() => setModal({ ...modal, reversePencairan: false })}
            />
            <TutupRekening
                data={dataModal}
                accountHolderType={jenisNasabah}
                onHide={() => setModal({ ...modal, tutupRekeningDeposito: false })}
                show={modal.tutupRekeningDeposito}
                type={tipeTutupRekening}
                onSuccess={() => {
                    queryClient.refetchQueries({ queryKey: 'data-deposito-approved' });
                }}
            />
            <BlokirRekeningModal
                accountHolderType={jenisNasabah}
                onHide={() => setModal({ ...modal, blokirRekeningDeposito: false })}
                show={modal.blokirRekeningDeposito}
                type={tipeBlokirRekening}
                data={dataModal}
                onSuccess={() => {
                    queryClient.refetchQueries({ queryKey: 'data-deposito-approved' });
                }}
            />
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Deposito
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">
                    Data Deposito Approved
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-grid tw-grid-cols-2 tw-gap-0">
                    <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                        Data Deposito Approved
                    </div>
                    <div className="tw-flex tw-text-12 tw-justify-end tw-p-7">
                        <ActionMore
                            className="tw-mr-3"
                            actions={[
                                {
                                    label: 'Pencairan Deposito',
                                    onClick: () => setModal({ ...modal, pencairanDeposito: true }),
                                },
                            ]}
                        >
                            <div className="border-0 tw-bg-transparent">
                                <IconMoney className="tw-mr-3" />
                                <span className="tw-text-complimentary"> Pencairan</span>
                            </div>
                        </ActionMore>
                        <ActionMore
                            actions={[
                                {
                                    label: 'Cetak Billyet Deposito',
                                    onClick: () => setModal({ ...modal, cetakBillyet: true }),
                                },
                                {
                                    label: 'Cetak Advice Perpanjangan Deposito',
                                    onClick: () => setModal({ ...modal, cetakAdvice: true }),
                                },
                            ]}
                        >
                            <div className="border-0 tw-bg-transparent">
                                <IconPrint className="tw-mr-4" />
                                <span className="tw-text-primary"> Cetak</span>
                            </div>
                        </ActionMore>
                    </div>
                </div>
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                onChange={(e) => setJenisNasabah(e.target.value)}
                            >
                                <option hidden disabled selected>
                                    Pilih Jenis Nasabah
                                </option>
                                <option value="CLIENT">Perorangan</option>
                                <option value="GROUP">Badan Usaha</option>
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Status"
                                as="select"
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option hidden disabled selected>
                                    Pilih Status
                                </option>
                                <option value="PENDING_APPROVAL">Pending Approval</option>
                                <option value="APPROVED">Approved</option>
                                <option value="ACTIVE">Active</option>
                                <option value="ACTIVE_IN_ARREARS">Active In Arrears</option>
                                <option value="MATURED">Mature</option>
                                <option value="LOCKED">Locked</option>
                                <option value="DORMANT">Dormant</option>
                                <option value="CLOSED">Closed</option>
                                <option value="CLOSED_WRITTEN_OFF">Closed Written Off</option>
                                <option value="WITHDRAWN">Withdrawn</option>
                                <option value="CLOSED_REJECTED">Closed Rejected</option>
                            </FormControl>

                            <form
                                onSubmit={handleSearch}
                                className="tw-w-full mr-4"
                                id="searchForm"
                            >
                                <FormControl
                                    id="search"
                                    className="mr-4"
                                    size="sm"
                                    placeholder="Cari nomor rekening"
                                />
                            </form>
                            <Button
                                type="button"
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                                onClick={() => {
                                    setSearch('');
                                    setJenisNasabah('');
                                    // maybe better using ref, but well you can change it to ref if you want
                                    document.getElementById('searchForm').reset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                form="searchForm"
                                type="submit"
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                            >
                                Cari
                            </Button>
                            {/* <button >
                        <IconMoney className="tw-mr-3"/>
                        <span className="tw-text-primary"> Pencairan</span>
                    </button>
                        <button className="border-0 tw-bg-transparent">
                            <IconPrint className="tw-mr-4" />
                            <span className="tw-text-primary"> Cetak</span>
                        </button> */}
                        </div>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'CIF',
                            field: 'nomorCIF',
                        },
                        {
                            name: 'Nomor Deposito',
                            field: 'nomorDeposito',
                        },
                        {
                            name: 'Jenis Nasabah',
                            field: 'jenisNasabah',
                        },
                        {
                            name: 'Status',
                            field: 'status',
                        },
                        {
                            name: 'Nama Lengkap',
                            field: 'namaLengkap',
                        },
                        {
                            name: 'Alamat',
                            field: 'alamat',
                            width: '200px',
                        },
                        {
                            name: 'Nomor Identitas',
                            field: 'nomorIdentitas',
                        },
                        {
                            name: 'Saldo (Rp.)',
                            field: 'saldo',
                        },
                        {
                            field: 'action',
                            width: '60px',
                        },
                    ]}
                    data={data || []}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'alamat') {
                            return (value = data.alamat.alamat);
                        }
                        if (field === 'action')
                            return (
                                <ActionMore
                                    actions={[
                                        {
                                            label: 'Lihat Informasi Deposito',
                                            onClick: (e) => {
                                                history.push('/deposito/data/detail', data);
                                            },
                                        },
                                        {
                                            label: 'Ubah Data Deposito',
                                            onClick: (e) => {
                                                history.push('/deposito/data/edit', data);
                                            },
                                        },
                                        {
                                            label: 'Signature Verification System',
                                            onClick: (e) => {
                                                history.push('/deposito/data/svs', data);
                                            },
                                        },
                                        {
                                            label: 'Blokir Rekening Deposito',
                                            divider: true,
                                            onClick: () => {
                                                setModal({
                                                    ...modal,
                                                    blokirRekeningDeposito: true,
                                                });
                                                setTipeBlokirRekening('blokir');
                                                setDataModal(data);
                                            },
                                            hidden: data.status !== 'ACTIVE',
                                        },
                                        {
                                            label: 'Buka Blokir Rekening Deposito',
                                            color: 'primary',
                                            divider: true,
                                            onClick: () => {
                                                setModal({
                                                    ...modal,
                                                    blokirRekeningDeposito: true,
                                                });
                                                setTipeBlokirRekening('buka');
                                                setDataModal(data);
                                            },
                                            hidden: data.status !== 'LOCKED',
                                        },
                                        {
                                            label: 'Tutup Rekening Deposito',
                                            color: 'red',
                                            onClick: () => {
                                                setModal({ ...modal, tutupRekeningDeposito: true });
                                                setTipeTutupRekening('tutup');
                                                setDataModal(data);
                                            },
                                            hidden:
                                                data.status !== 'LOCKED' &&
                                                data.status !== 'APPROVED',
                                        },
                                        {
                                            label: 'Batalkan Tutup Rekening Deposito',
                                            color: 'green',
                                            onClick: () => {
                                                setModal({ ...modal, tutupRekeningDeposito: true });
                                                setTipeTutupRekening('batal');
                                                setDataModal(data);
                                            },
                                            hidden:
                                                data.status !== 'CLOSED' &&
                                                data.status !== 'WITHDRAWN',
                                        },
                                    ]}
                                />
                            );

                        if (field === 'saldo')
                            return (
                                <div
                                    key={`${value}-${field}`}
                                    className={clsx(className, 'tw-text-right')}
                                >
                                    {new Intl.NumberFormat('id-ID', {
                                        currency: data?.currencyCode || 'IDR',
                                        style: 'currency',
                                    }).format(value)}
                                </div>
                            );
                    }}
                    config={{
                        height: '60vh',
                        stickyHead: true,
                        overflowY: 'auto',
                    }}
                    isLoading={!isFetchedAfterMount}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
            </div>
        </>
    );
};

export default DepositoPage;
