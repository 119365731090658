import clsx from 'clsx';
import { FormControl } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { TableGrid } from '../../../controls';
import { baseUrl } from '../../../../app.config';
import Skeleton from 'react-loading-skeleton';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../../hooks/keycloak.module';

const InformasiKasTellerPage = () => {
    const { keycloak } = useKeycloak();

    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const { data, isFetchedAfterMount } = useQuery(
        ['info-kas-teller', limit, currentPage],
        async () => {
            const res = await fetch(
                baseUrl +
                    `/otorisasi/transaksi/informasi/kas/${keycloak.idTokenParsed.preferred_username}?page=${currentPage}&limit=${limit}`
            );

            const data = await res.json();

            return data.list ?? [];
        }
    );

    const { data: saldoKasTeller, isFetchedAfterMount: isSaldoFetched } = useQuery(
        'saldo-kas-teller',
        async () => {
            const res = await fetch(baseUrl + '/teller/single/balance');

            const data = await res.json();

            return data.list?.balance;
        }
    );

    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    return (
        <>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Informasi Transaksi
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Informasi Kas Teller</Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-py-6 text-center tw-text-black tw-text-18 tw-bg-white tw-rounded-lg tw-font-bold tw-mb-4">
                Total Saldo Kas Teller :{' '}
                <span className="tw-text-primary tw-ml-2">
                    {!isSaldoFetched ? (
                        <Skeleton width={250} />
                    ) : (
                        new Intl.NumberFormat('id-ID', {
                            currency: 'IDR',
                            style: 'currency',
                        }).format(saldoKasTeller)
                    )}
                </span>
            </div>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    INFORMASI KAS TELLER
                </div>
                <TableGrid
                    isLoading={!isFetchedAfterMount}
                    columns={[
                        {
                            name: 'Jam Input',
                            field: 'created_at',
                            width: '100px',
                        },
                        {
                            name: 'Debit/Kredit',
                            field: 'tipe_mutasi',
                            width: '100px',
                        },
                        {
                            name: 'Nilai Mutasi',
                            field: 'nilai_transaksi',
                            textAlign: 'right',
                            width: '225px',
                        },
                        {
                            name: 'Referensi',
                            field: 'no_referensi',
                            width: '100px',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                            width: '1fr',
                        },
                        {
                            name: 'Status Otorisasi',
                            field: 'status_otorisasi',
                            width: '120px',
                        },
                    ]}
                    data={data}
                    onRenderField={(value, field, { className }) => {
                        if (field === 'nilai_transaksi')
                            return (
                                <div
                                    key={`${value}-${field}`}
                                    className={clsx(className, 'tw-text-right')}
                                >
                                    {new Intl.NumberFormat('id-ID', {
                                        currency: 'IDR',
                                        style: 'currency',
                                    }).format(value)}
                                </div>
                            );

                        if (field === 'created_at')
                            return (
                                <div key={`${value}-${field}`} className={clsx(className)}>
                                    {dayjs(value).format('HH:mm:ss')}
                                </div>
                            );

                        if (field === 'status_otorisasi')
                            return (
                                <div
                                    key={`${value}-${field}`}
                                    className={clsx(className, 'tw-capitalize')}
                                >
                                    {value.toLowerCase()}
                                </div>
                            );
                    }}
                    config={{
                        stickyHead: true,
                        height: '60vh',
                        overflowY: 'auto',
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-grid tw-grid-cols-3 tw-items-center tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            onChange={(e) => setLimit(e.target.value)}
                            as="select"
                            size="sm"
                            className="tw-border-0 tw-w-max"
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        containerClassName="pagination tw-text-12 tw-m-0 tw-justify-self-center"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-cursor-pointer focus:tw-outline-none tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        initialPage={0}
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12 tw-text-right">Menampilkan {data?.length} data</div>
                </div>
            </div>
        </>
    );
};

export default InformasiKasTellerPage;
