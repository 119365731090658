import moment from 'moment';
// import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistration, LoadingIcon, NewLoading, TableList } from '../../../../../controls';
import useRegistrasiTabungan from './useRegistrasiTabungan';
import useDataRekening from './useDataRekening';
import { useParams } from 'react-router-dom'
import Select from 'react-select';
import useKeycloak from '../../../../../../hooks/keycloak.module';
import { useLocalStorage } from '../../../../../../module';

const DataRekening = () => {

    const [podDate, setPodDate] = useLocalStorage("POD_DATE")
    const { ftbAction, ftbState } = useRegistrasiTabungan();
    const { formData = {}, formEditable = false } = ftbState;
    const { pdrAction, pdrState } = useDataRekening()
    const { keycloak } = useKeycloak();
    const paramUrl = useParams();
    const minNisbah = -ftbState.formData?._informasiLain?.nisbahAkhir;
    const maxNisbah = 100 - ftbState.formData?._informasiLain?.nisbahAkhir;
    // useEffect(function () {
    //     ftbAction.auditTrail()
    // }, [])
    return (
        <>
            <div className="pb-4">
                <div className="box">
                    <div className="row">
                        <div className="col-4 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nomor Rekening</label>
                                <div class="input-group">

                                    <input
                                        className="form-control"
                                        type="text"
                                        readOnly
                                        placeholder="Otomatis terisi dari sistem"
                                        value={formData?.nomorRekening}
                                    />
                                    {
                                        paramUrl.mode === "registration" && <div class="input-group-append ml-3">
                                            <button className={`btn btn-${formData?.nomorRekening !== null &&
                                                formData?.nomorRekening !== "" &&
                                                formData?.nomorRekening !== undefined ? "default" : "primary"}`}
                                                onClick={formData?.nomorRekening !== null &&
                                                    formData?.nomorRekening !== "" &&
                                                    formData?.nomorRekening !== undefined ? ftbAction.resetNomorCantik : ftbAction.openModalNC}>
                                                {
                                                    formData?.nomorRekening !== null &&
                                                        formData?.nomorRekening !== "" &&
                                                        formData?.nomorRekening !== undefined ? "Reset" : "Request Nomor Cantik"
                                                }
                                            </button>
                                        </div>

                                    }
                                </div>
                                {paramUrl.mode === "registration" && <span style={{ fontSize: "9pt", color: "#007bff" }}>*Kosongkan jika rekening generate otomatis</span>}
                            </div>
                        </div>
                        <div className="col-4 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nomor Akad</label>
                                <div class="input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        readOnly
                                        placeholder="Otomatis terisi dari sistem"
                                        value={formData?.nomor_akad}
                                    />
                                </div>
                                {paramUrl.mode === "registration" && <span style={{ fontSize: "9pt", color: "#007bff" }}>*Nomor Akad akan di generate otomatis</span>}
                            </div>
                        </div>
                        <div className="col-4 text-right align-self-end">
                            <div>
                                <span className="text-grey">Tgl Buka</span>
                                <span className="text-grey2 ml-4">:</span>
                                {/* <span className="text-grey2">{moment(formData.created_at).format("DD MMMM YYYY")}</span> */}
                                <span className="text-grey2"> {moment(ftbState.podDate).format("DD MMMM YYYY")}</span>
                                <span className="ml-5 text-grey">Kode Cabang</span>
                                <span className="text-grey2 ml-4">:</span>
                                <span className="text-grey2">{keycloak?.tokenParsed?.branch_code}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FormRegistration
                itemsForm={[
                    // Data Nasabah
                    {
                        name: "Data Nasabah",
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-2">
                                                <label>Nomor CIF</label>
                                                <span className="badge badge-secondary">
                                                    Wajib
                                                </span>
                                                <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        type="text"
                                                        // required
                                                        className="form-control form-control-sm col-9"
                                                        placeholder="Masukan nomor CIF"
                                                        readOnly
                                                        onChange={(e) => {
                                                            ftbAction.changeForm(e.target.value, "nomorCIF");
                                                        }}
                                                        value={ftbState.formData?.nomorCIF}
                                                        disabled={!ftbState.formEditable}
                                                    />
                                                    <div className="input-group-append col-3 pl-2 pr-0">

                                                        <button disabled={ftbState.paramUrl?.mode === "otorisasi" || ftbState.paramUrl?.mode === "edit"} onClick={pdrAction.openModalAdd} className="btn btn-outline-danger">
                                                            Cari Nasabah . . .
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: 'Nama',
                                type: 'text',
                                placeholder: 'Masukan nama',
                                value: ftbState.formData?.namaNasabah,
                                onChange: (value) => ftbAction.changeForm(value, "namaNasabah"),
                                required: false,
                                disabled: true
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col-4">
                                            {
                                                createForm({
                                                    label: 'No Telepon',
                                                    type: 'number',
                                                    placeholder: 'masukan nomor telepon',
                                                    value: ftbState.formData?._dataNasabah?.homePhone,
                                                    onChange: (value) => ftbAction.changeForm(value, "homePhone", "_dataNasabah"),
                                                    required: false,
                                                    disabled: true
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'No Handphone',
                                                    type: 'number',
                                                    placeholder: 'masukan no handphone',
                                                    value: ftbState.formData?._dataNasabah?.mobilePhone,
                                                    onChange: (value) => ftbAction.changeForm(value, "mobilePhone", "_dataNasabah"),
                                                    disabled: true,
                                                    required: false,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            // {
                            //     rows: 2,
                            //     label: 'Nama Ibu Kandung',
                            //     type: 'text',
                            //     placeholder: 'Masukan nama ibu kandung',
                            //     disabled: true,
                            //     required: false,
                            // },
                            // {
                            //     rows: 2,
                            //     type: 'custom',
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-4">
                            //                 {
                            //                     createForm({
                            //                         label: 'Tempat / Tanggal Lahir',
                            //                         type: 'text',
                            //                         placeholder: 'Masukan tempat',
                            //                         required: false,
                            //                         disabled: true
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col">
                            //                 {
                            //                     createForm({
                            //                         label: <>&nbsp;</>,
                            //                         type: 'date',
                            //                         placeholder: 'Tanggal lahir',
                            //                         disabled: true,
                            //                         required: false
                            //                     })
                            //                 }
                            //             </div>
                            //         </div>)
                            // },

                            {
                                rows: 2,
                                label: 'Alamat',
                                type: 'textarea',
                                placeholder: 'Masukan alamat',
                                value: ftbState.formData?._dataNasabah?.alamat,
                                onChange: (value) => ftbAction.changeForm(value, "alamat", "_dataNasabah"),
                                required: false,
                                disabled: true,
                            },
                        ]
                    },
                    // End Data Nasabah
                    // rekening
                    {
                        name: "Rekening",
                        rows: [
                            {
                                rows: 1,
                                label: 'Produk',
                                type: 'select',
                                placeholder: 'Pilih produk',
                                required: true,
                                options: (ftbState.dataProduk || []).map(({
                                    nama_produk, kode_produk
                                }) => ({
                                    kode_produk,
                                    nama_produk: `${kode_produk} - ${nama_produk} `
                                })),
                                display: "nama_produk",
                                key: "kode_produk",
                                value: ftbState.formData?.produkTabungan,
                                onChange: (value) => {
                                    ftbAction.changeProduct(value)
                                },
                                disabled: (paramUrl.mode === "registration" && (
                                    formData?.nomorRekening !== "" &&
                                    formData?.nomorRekening !== null &&
                                    formData?.nomorRekening !== undefined
                                )) || (!ftbState.formEditable || paramUrl.mode === "edit")
                            },
                            ...(ftbState.formData?.produkTabungan === "0256" ? [{
                                rows: 1,
                                label: 'Tanggal Jatuh Tempo Tasedo',
                                type: 'date',
                                placeholder: 'tanggal jatuh tempo',
                                onChange: (v) => {
                                    ftbAction.changeForm(v, "tgl_jatuh_tempo_tasedo")
                                },
                                value: ftbState.formData?.tgl_jatuh_tempo_tasedo,
                                required: true,
                                disabled: !ftbState.formEditable,

                            }] : []),
                            {
                                rows: 1,
                                label: 'Nama Rekening',
                                type: 'text',
                                placeholder: 'Masukan nama rekening',
                                value: ftbState.formData?.namaRekening,
                                onChange: (value) => ftbAction.changeForm(value, "namaRekening"),
                                required: true,
                                disabled: !ftbState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Valuta',
                                type: 'text',
                                placeholder: 'IDR - Rupiah',
                                value: ftbState.formData?.valuta,
                                onChange: (value) => ftbAction.changeForm(value, "valuta"),
                                required: false,
                                disabled: true
                            },
                            {
                                rows: 1,
                                label: 'Keterangan',
                                type: 'text',
                                placeholder: 'contoh: Tabungan pertama',
                                value: ftbState.formData?.keterangan,
                                onChange: (value) => ftbAction.changeForm(value, "keterangan"),
                                required: false,
                                disabled: !ftbState.formEditable
                            },
                            // {
                            //     rows: 1,
                            //     label: 'Alamat Stantement/Pasbook',
                            //     type: 'select',
                            //     placeholder: 'Pilih Alamat Stantement/Pasbook',
                            //     required: true,
                            //     disabled: !ftbState.formEditable
                            // },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col pt-4 mt-2">
                                            {
                                                createForm({
                                                    label: <strong>Dapat Bonus / Bagi Hasil</strong>,
                                                    type: "checkbox",
                                                    checked: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                    value: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                    onChange: (value) => ftbAction.changeForm(value, "dapatBonusBagiHasil", "_rekeningTabungan"),
                                                    disabled: !ftbState.formEditable || true
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nisbah Spesial',
                                                    type: 'float',
                                                    placeholder: '%',
                                                    value: ftbState.formData?._informasiLain?.nisbahCounter,
                                                    // onFocusOut: value => ftbAction.changeForm(parseFloat(value).toFixed(2), "nisbahCounter", "_informasiLain"),
                                                    onChange: (value) => {
                                                        if (value > maxNisbah) {
                                                            ftbAction.alertNisbah('max');
                                                        } else if (value < minNisbah) {
                                                            ftbAction.alertNisbah('min');
                                                        }
                                                        ftbAction.changeForm(value, "nisbahCounter", "_informasiLain")
                                                    },
                                                    required: false,
                                                    disabled: !ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil || !ftbState.formEditable,
                                                    onFocusOut: (value) => value === "" ? ftbAction.changeForm(0, "nisbahCounter", "_informasiLain") : ftbAction.changeForm(parseFloat(value).toFixed(2), "nisbahCounter", "_informasiLain"),
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nisbah Berdasarkan Produk',
                                                    type: 'currency',
                                                    placeholder: '%',
                                                    readOnly: true,
                                                    value: ftbState.formData?._informasiLain?.nisbahAkhir,
                                                    onChange: (value) => ftbAction.changeForm(value, "nisbahAkhir", "_informasiLain"),
                                                    required: false,
                                                    disabled: !ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil || !ftbState.formEditable,
                                                    onFocusOut: (value) => value === "" && ftbAction.changeForm(0, "nisbahAkhir", "_informasiLain")
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Pajak Bagi Hasil',
                                                    type: 'currency',
                                                    placeholder: '%',
                                                    value: ftbState.formData?._informasiLain?.nisbahPajak,
                                                    onChange: (value) => ftbAction.changeForm(value, "nisbahPajak", "_informasiLain"),
                                                    required: false,
                                                    disabled: !ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil || !ftbState.formEditable,
                                                    onFocusOut: (value) => value === "" && ftbAction.changeForm(0, "nisbahPajak", "_informasiLain"),
                                                    readOnly: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {/* <div className="row">
                                                <div className="col-8"> */}
                                            {
                                                createForm({
                                                    label: 'Zakat Bagi Hasil',
                                                    type: 'currency',
                                                    placeholder: '%',
                                                    value: ftbState.formData?._informasiLain?.nisbahZakat || 0,
                                                    onChange: (value) => ftbAction.changeForm(value, "nisbahZakat", "_informasiLain"),
                                                    required: false,
                                                    disabled: !ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil || !ftbState.formEditable,
                                                    onFocusOut: (value) => value === "" && ftbAction.changeForm(0, "nisbahZakat", "_informasiLain"),
                                                    readOnly: ftbState.formData?._rekeningTabungan?.dapatBonusBagiHasil,
                                                })
                                            }
                                            {/* </div>
                                                <div className="col-4 mt-4">
                                                    <div className="mt-2">
                                                        {
                                                            createForm({
                                                                label: "Kena Zakat",
                                                                type: "checkbox",
                                                                checked: ftbState.formData?._rekeningTabungan?.kenaZakat,
                                                                value: ftbState.formData?._rekeningTabungan?.kenaZakat,
                                                                onChange: (value) => {
                                                                    ftbAction.changeForm(value, "kenaZakat", "_rekeningTabungan")
                                                                    if(value === true){
                                                                        ftbAction.changeForm("2.5", "nisbahZakat", "_informasiLain")
                                                                    }else{
                                                                        ftbAction.changeForm("0", "nisbahZakat", "_informasiLain")
                                                                    }
                                                                },  
                                                                disabled: !ftbState.formEditable 
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                label: "Kena Zakat",
                                type: "checkbox",
                                checked: ftbState.formData?._rekeningTabungan?.kenaZakat,
                                value: ftbState.formData?._rekeningTabungan?.kenaZakat,
                                onChange: (value) => {
                                    ftbAction.changeForm(value, "kenaZakat", "_rekeningTabungan")
                                    if (value === true) {
                                        ftbAction.changeForm("2.5", "nisbahZakat", "_informasiLain")
                                    } else {
                                        ftbAction.changeForm("0", "nisbahZakat", "_informasiLain")
                                    }
                                },
                                disabled: !ftbState.formEditable
                            },
                            {
                                rows: 2,
                                label: "Kena Biaya admin",
                                type: "checkbox",
                                checked: ftbState.formData?._rekeningTabungan?.biayaAdministrasi,
                                value: ftbState.formData?._rekeningTabungan?.kenaZakat,
                                onChange: (value) => {
                                    // let v = value ? "T" : "F"
                                    ftbAction.changeForm(value, "biayaAdministrasi", "_rekeningTabungan")
                                    // if(value === true){
                                    //     ftbAction.changeForm("2.5", "nisbahZakat", "_informasiLain")
                                    // }else{
                                    //     ftbAction.changeForm("0", "nisbahZakat", "_informasiLain")
                                    // }
                                },
                                disabled: !ftbState.formEditable
                            },
                            {
                                rows: 2,
                                label: "Kena Biaya ATM",
                                type: "checkbox",
                                checked: ftbState.formData?._rekeningTabungan?.biayaATM,
                                value: ftbState.formData?._rekeningTabungan?.kenaZakat,
                                onChange: (value) => {
                                    // let v = value ? "T" : "F"
                                    ftbAction.changeForm(value, "biayaATM", "_rekeningTabungan")
                                    // if(value === true){
                                    //     ftbAction.changeForm("2.5", "nisbahZakat", "_informasiLain")
                                    // }else{
                                    //     ftbAction.changeForm("0", "nisbahZakat", "_informasiLain")
                                    // }
                                },
                                disabled: !ftbState.formEditable
                            }
                        ]
                    },
                    // End Rekening
                    {
                        name: "Informasi Lain",
                        rows: [
                            {
                                rows: 1,
                                type: "select",
                                label: "Sumber Dana",
                                required: true,
                                placeholder: "Pilih sumber dana",
                                options: (ftbState.dataDropDown?.R_SUMBER_PENGHASILAN || []).map(({ reference_code, reference_desc }) => ({
                                    reference_code,
                                    reference_desc: `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: ftbState.formData?._informasiLain?.sumberDana,
                                onChange: (value) => ftbAction.changeForm(value, "sumberDana", "_informasiLain"),
                                disabled: !ftbState.formEditable
                            },
                            {
                                rows: 1,
                                type: "select",
                                label: "Tujuan Pembukaan Rekening",
                                required: true,
                                placeholder: "Pilih tujuan pembukaan rekening",
                                options:( ftbState.dataDropDown?.R_TUJUAN_BUKA_REKENING|| []).map(({ reference_code, reference_desc }) => ({
                                    reference_code,
                                    reference_desc: `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: ftbState.formData?._informasiLain?.tujuanPembukaanRekening,
                                onChange: (value) => ftbAction.changeForm(value, "tujuanPembukaanRekening", "_informasiLain"),
                                disabled: !ftbState.formEditable
                            },
                            ...(
                                paramUrl.type === "tabungan" ? [{
                                    rows: 1,
                                    type: "select",
                                    label: "Kode Tag",
                                    required: false,
                                    placeholder: "Pilih Kode Tag",
                                    options: (ftbState.dataTag || []).map(itm => ({
                                        ...itm,
                                        nama_tag: `${itm.kode_tag} - ${itm.nama_tag}`
                                    })),
                                    key: "kode_tag",
                                    display: "nama_tag",
                                    value: ftbState.formData?._informasiLain?.kode_tag,
                                    onChange: (value) => ftbAction.changeForm(value, "kode_tag", "_informasiLain"),
                                    disabled: !ftbState.formEditable
                                }] : []),
                            {
                                rows: 2,
                                type: "select",
                                label: "Marketing Inisiasi",
                                required: true,
                                placeholder: "Pilih marketing inisiasi",
                                disabled: !ftbState.formEditable,
                                key: "kode_ao",
                                display: "nama_ao",
                                options: (ftbState.dataMarketing || []).map(({ kode_ao, nama_ao }) => ({
                                    kode_ao,
                                    nama_ao: `${kode_ao} - ${nama_ao}`
                                })),
                                value: ftbState.formData?._informasiLain?.marketingPertama,
                                onChange: (e) => ftbAction.changeForm(e, "marketingPertama", "_informasiLain")
                            },
                            {
                                rows: 2,
                                type: "select",
                                label: "Marketing Maintenance",
                                required: true,
                                placeholder: "Pilih marketing maintenance",
                                disabled: !ftbState.formEditable,
                                options: (ftbState.dataMarketing || []).map(({ kode_ao, nama_ao }) => ({
                                    kode_ao,
                                    nama_ao: `${kode_ao} - ${nama_ao}`
                                })),
                                key: "kode_ao",
                                display: "nama_ao",
                                value: ftbState.formData?._informasiLain?.marketingSaatIni,
                                onChange: (e) => ftbAction.changeForm(e, "marketingSaatIni", "_informasiLain")
                            },
                            ...(
                                paramUrl.type === "tabungan" ? [{
                                    rows: 2,
                                    type: "select",
                                    label: "Data Instansi",
                                    required: false,
                                    placeholder: "Pilih Data Instansi",
                                    options: (ftbState.dataInstansi || []).map(ins => ({
                                        ...ins,
                                        nama_instansi: `${ins.kode_instansi} - ${ins.nama_instansi}`
                                    })),
                                    key: "kode_instansi",
                                    display: "nama_instansi",
                                    value: ftbState.formData?.code_instansi,
                                    onChange: (value) => ftbAction.changeForm(value, "code_instansi"),
                                    disabled: !ftbState.formEditable
                                }] : []),
                                ...(
                                paramUrl.type === "giro" ? [{
                                    rows: 1,
                                    type: "select",
                                    label: "Data Instansi",
                                    required: false,
                                    placeholder: "Pilih Data Instansi",
                                    options: (ftbState.dataInstansi || []).map(ins => ({
                                        ...ins,
                                        nama_instansi: `${ins.kode_instansi} - ${ins.nama_instansi}`
                                    })),
                                    key: "kode_instansi",
                                    display: "nama_instansi",
                                    value: ftbState.formData?.code_instansi,
                                    onChange: (value) => ftbAction.changeForm(value, "code_instansi"),
                                    disabled: !ftbState.formEditable
                                }] : []),
                        ]
                    },
                ]}
            />
            {/* form relasi nasbaah */}
            <Modal size="xl" show={pdrState.formModal} onHide={pdrAction.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <strong>Pencarian Data Nasabah</strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pb-4">
                        {/* <p className="global-text">Cari data berdasarkan :</p> */}
                        <div className="row">
                            {/* 
                            <div className="col-2">
                                <div className="form-group">
                                    <select name="" id="" value={pdrState.filter.filterBy} onChange={(e) => pdrAction.setFilter(prevState => ({ ...prevState, filterBy: e.target.value }))} className="form-control">
                                        <option value={0}>Nama Nasabah</option>
                                        <option value={1}>Nomor CIF</option>
                                    </select>
                                </div>
                            </div>
                             */}

                            {/* <div className="col">
                                <div className="form-group">
                                    <select name="" id="" value={pdrState.filter.filterType} onChange={(e) => pdrAction.setFilter(prevState => ({ ...prevState, filterType: e.target.value }))} className="form-control">
                                        <option value={0}>Perorangan</option>
                                        <option value={1}>Badan Usaha</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input
                                        value={pdrState.filter.keyword}
                                        onChange={(e) => pdrAction.setFilter(prevState => ({ ...prevState, keyword: e.target.value }))}
                                        type="text"
                                        className="form-control"
                                        placeholder="Nama Nasabah"
                                    />
                                </div>
                            </div>
                            <div className="col-1 p0">
                                <button onClick={pdrAction.searchNasabah} className="btn btn-primary btn-block">Cari</button>
                            </div> */}
                            {/* <div className="col-5 p0">
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TableList
                                    withCard={false}
                                    isLoading={pdrState.loadingSearch}
                                    data={pdrState.listNasabah}
                                    columns={[
                                        {
                                            name: 'Nomor CIF',
                                            field: 'nomor_cif',
                                            maxWidth: '150px',
                                        },
                                        {
                                            name: 'Nama CIF',
                                            field: 'nama',
                                            maxWidth: '150px',
                                        },
                                        {
                                            name: 'Kode Cabang',
                                            field: 'kode_cabang_input',
                                            maxWidth: '75px',
                                        },
                                        {
                                            name: 'Alamat',
                                            field: 'alamat',
                                            maxWidth: '150px',
                                        },
                                        {
                                            name: 'Jenis Nasabah',
                                            field: 'jenis_nasabah',
                                            maxWidth: '75px',
                                        },
                                    ]}
                                    pagination={true}
                                    withAction={true}
                                    actionItems={[
                                        {
                                            name: 'Pilih Nasabah',
                                            onClick: async (e) => {
                                                const get = await ftbAction.pilihNasabah(e, pdrState.filter.jenis_nasabah);
                                                if (get) pdrAction.closeModal()
                                            },
                                            className: "text-danger"
                                        },
                                    ]}
                                    onChangeShow={pdrAction.changeShow}
                                    onNextPage={pdrAction.nextPage}
                                    onPrevPage={pdrAction.prevPage}
                                    // filter={false}
                                    filter={true}
                                    formsFilter={[
                                        {
                                            type: 'select',
                                            options: (pdrState.listKodeCabang || []).map(({ kode_cabang = "", nama_cabang = "" }, i) => ({
                                                key: kode_cabang,
                                                display: `${kode_cabang} - ${nama_cabang}`
                                            })),
                                            placeholder: 'Kode Cabang',
                                            disabled: false,
                                            readonly: false,
                                            value: pdrState?.filter?.kode_cabang,
                                            onChange: (kode_cabang) => pdrAction.setFilter(f => ({ ...f, kode_cabang })),
                                        },
                                        {
                                            type: 'select',
                                            options: [
                                                {
                                                    key: "0",
                                                    display: 'Perorangan',
                                                },
                                                {
                                                    key: "1",
                                                    display: 'Badan Usaha',
                                                },
                                            ],
                                            placeholder: 'Jenis Nasabah',
                                            disabled: false,
                                            readonly: false,
                                            value: pdrState?.filter?.jenis_nasabah,
                                            onChange: (jenis_nasabah) => pdrAction.setFilter(f => ({ ...f, jenis_nasabah })),
                                        },
                                        {
                                            type: 'text',
                                            options: [],
                                            placeholder: 'Keyword',
                                            disabled: false,
                                            readonly: false,
                                            value: pdrState?.filter?.keyword,
                                            onChange: (keyword) => pdrAction.setFilter(f => ({ ...f, keyword })),
                                        },
                                    ]}
                                    onFilter={() => pdrAction.resetPagination()}
                                    onResetFilter={() => { pdrAction.resetFilter() }}
                                    resetFilter={true}
                                    submitButton="Submit"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button onClick={pdrAction.closeModal} className="btn btn-sm float-right mr-2 btn-outline-secondary">
                                Batal
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* form cari nasabah */}

        </>
    );
}

export default DataRekening;