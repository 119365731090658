// import { useKeycloak } from "@react-keycloak/web";
import React,{ createContext, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import useFetch from "../../../../../hooks/useFetch";
import { useModalAlert } from "../../../../controls/modalAlert";
import useAmbilKasLain from "../../kas_teller/ambil_kas_teller_lain/useAmbilKasLain";
import useKeycloak from "../../../../../hooks/keycloak.module";
const otoTellerContext = createContext({
    otState: {}, otAction : {}
});

export const OTProvider = ({ children }) => {
    const  history  = useHistory();
    const { aklState, aklAction } = useAmbilKasLain();
    const { doGet, doPost } = useFetch();
    const { keycloak } = useKeycloak();
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [dataList, setListData] = useState([]);
    const [filter, setFilter] = useState({ keyword: '' });
    const [isLoading, setIsLoading] = useState(true);
    const { modalState, modalAction } = useModalAlert();
    const {

        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    useEffect(function () {
        getDataList()
    }, [pagination])

    // getData list
    async function getDataList() {
        const userInfo = await keycloak.loadUserInfo();
        // // console.log(userInfo)
        // setIsLoading(true)
        openLoading()
        var url = `/otorisasi/get-otor-teller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param : {user_id_tlr_db:userInfo.preferred_username},
                service: "trx"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    // // console.log(data)
                    setListData(data);
                }
                else if (remark !== "Success") setDataAlert({ show: true, body:"Belum ada Permintaan Kas Teller Lain", titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        // setIsLoading(false)
        closeLoading()
    }

    function resetFilter() {
        setFilter({
            keyword: ''
        })
    }

    function nextPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    function confirmPost(idTR,flagT) {
        let body = `Apakah anda yakin ingin MemVerifikasi Transaksi ini. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => postListData(idTR,flagT), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    const [idTR, setidTR] = useState()

    async function postListData(idTR,flagT) {
        // const userInfo = await keycloak.loadUserInfo();
        // setIsLoading(true)
        openLoading()
        var url = `/otorisasi/otor-akt-teller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "trx",
                queryParam:{id_transaksi:idTR,flag:flagT},
                // data: viewCheckBox,
                method: "PUT"
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Verifikasi Transaksi Berhasil`, title: ``, header: "Success", callBack: () => {
                    closeConfirm();
                    setidTR();
                    getDataList();
                    history.goBack();
                }});
                else setDataAlert({ show: true, body: `Belum ada data terpilih, PIlih data terlebih dahulu !`, titile: `Status fetch data is ${statusCode}`, header:remark, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function refresh(){
        // openLoading()
        setFilter({kode_entry:""})
        setPagination({ page: 1, limit: 15})
        // closeLoading()
    }

    // fungsi button process
    function process(dbId, kode_entry, item){
        const state = { dbId, kode_entry }
        // // console.log(history)
        if(kode_entry === "AKT") return history.push("/teller/kas-teller/ambil-kas-lain/otorisasi", state)
        else return false;
    }
    return <otoTellerContext.Provider
        value={{
            otAction:{
                resetFilter,
                nextPage,
                prevPage,
                changeShow,
                setFilter,
                getDataList,
                confirmPost,
                refresh,
                process
            },
            otState:{
                dataList,
            }
        }}
    
    >{children}</otoTellerContext.Provider>
}
export default function useOtorTeller() {
    return useContext(otoTellerContext)    
};

// "id_transaksi": 18,
// "saldo_tlr_db": 1000,
// "nama_tlr_db": "ALI",
// "kode_cabang_tlr_db": "532",
// "nama_cabang_tlr_db": "BANDUNG",
// "tanggal_input": "12/11/2021",
// "nomor_rekening_debet": "TL01063",
// "nomor_referensi": "s127620220114140711",
// "id_transaksi_core": null,
// "nomor_rekening_credit": "TL01063",
// "nominal": 1000,
// "jenis_transaksi": "AKT",
// "kode_transaksi": "Ambil Transaksi Teller Lain",
// "kode_cabang_transaksi": "532",
// "is_boleh_cetak": null,
// "is_verifikasi": null,
// "is_otor_teller": "T"