
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from '../route/routeConfig';
import { SetupPage, NewLoginPage, Login } from '../components/pages';
import useKeycloak from '../hooks/keycloak.module';

// const autRoutes = routes.filter(v => v.auth);
const autRoutes = routes.filter((v) => v.auth);
const AuthRouter = () => {
    const { keycloak } = useKeycloak();

    const isAuthorized = (roles = []) => {
        if (roles.length === 0) return keycloak.authenticated;
        if((keycloak.tokenParsed.realm_access?.roles || []).length <= 0 || keycloak.tokenParsed.realm_access?.roles === null ){
            return roles.some((role) => role === "");
        } 
        return roles.some((role) => keycloak.hasRealmRole(role)) && keycloak.authenticated;
    };

    function createChildPath(childs) {
        var child = [];
        childs.forEach((v) => {
            if (Array.isArray(v.childs)) {
                child = [...v.childs, ...child];
            }
        });
        return [...childs, ...child];
    }

    function createPath(routes) {
        var child = [];
        const parents = routes.map((route, key) => {
            if (Array.isArray(route.childs)) {
                child = [...createChildPath(route.childs), ...child];
            }
            return route;
        });

        return [...parents, ...child]
            .filter((v) => v.component != null)
            .map((route, key) => (
                <Route
                    exact={true}
                    key={route.key}
                    path={route.key}
                    render={(props) =>
                        isAuthorized(route.roles) ? (
                            <route.component {...props} />
                        ) : (
                            <div>Anda tidak memiliki akses ke halaman ini</div>
                        )
                    }
                />
            ));
    }

    // createPath(autRoutes)

    return (
        <>
            <>
                {/* <Suspense fallback={<Loading/>}> */}
                <Switch>
                    {
                        keycloak.authenticated ? (
                            <Redirect from='/login' to="/" />
                        ) : (
                            <Route
                                exact={true}
                                key={"/login"}
                                path="/login"
                                render={
                                    (props) => <NewLoginPage {...props} />
                                } />
                        )
                    }
                    {keycloak.authenticated && createPath(autRoutes)}
                    {keycloak.authenticated ? <Route
                        exact={true}
                        key={"/setup"}
                        path="/setup"
                        render={
                            (props) => <SetupPage {...props} />
                        } /> : <Redirect from='/' to="/login" />}

                </Switch>
                {/* </Suspense> */}
            </>
        </>
    );
};

export default AuthRouter;
