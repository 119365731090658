import React from 'react';
import {useParams } from 'react-router';
import useDropdowns from '../../../../../hooks/useDropdowns';
import { createForm } from '../../../../../module/createFormList';
import useBifast from './useBifast';

const DataPengirim = () => {

    const { biState, biAction, pdrState, pdrAction } = useBifast();
    const { eJenisIdentitas } = useDropdowns(["eJenisIdentitas"])
    const paramUrl = useParams();
    return (
        <>
            <h4 className="text-base">Data Pengirim</h4>
            <div className='row mb-3'>
                <div className='col-4'>
                    <div className="form-check">
                        <input className="form-check-input" name='is_wwic' type="radio" disabled={paramUrl.mode === "otorisasi"}
                            value={true} checked={biState.isWic} onChange={(e) => {
                                // console.log(e.target.value)
                                biAction.setIsWic(JSON.parse(e.target.value))
                            }
                            } />
                        <label className="form-check-label" for="exampleRadios1">
                            Nasabah / WIC
                        </label>
                    </div>
                </div>
                <div className='col-4'>
                    <div className="form-check">
                        <input className="form-check-input" name='is_wwic' type="radio" disabled={paramUrl.mode === "otorisasi"}
                        value={false} checked={!biState.isWic}
                            onChange={(e) => {
                                // console.log(e.target.value)
                                biAction.setIsWic(JSON.parse(e.target.value))
                            }
                            } />
                        <label className="form-check-label" for="exampleRadios1">
                            WIC Baru
                        </label>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* <div className="form-group col-6">
                    {
                        createForm({
                            type: "select",
                            label: "Jenis Nasabah",
                            placeholder: "pilih jenis nasabah",
                            options: [
                                {
                                    key:"PERORANGAN",
                                    display:"PERORANGAN"
                                },
                                {
                                    key:"BADAN USAHA",
                                    display:"BADAN USAHA"
                                }
                            ],
                            onChange: (jenis_nasabah)=> biAction.setDataPengirim(p=>({...p, jenis_nasabah:jenis_nasabah.toUpperCase()})),
                            value:biState.dataPengirim?.jenis_nasabah,
                            disabled:!biState.formEditable
                        })
                    }
                </div> */}


                {biState.isWic && <>
                    <div className="col-9">
                        {
                            createForm({
                                type: "number",
                                label: "Nomor Nasabah",
                                required: true,
                                // onChange: (nomor_nasabah)=> biAction.setDataPengirim(p=>({...p, nomor_nasabah:nomor_nasabah.toUpperCase()})),
                                value: biState.dataPengirim?.nomor_nasabah,
                                // onEnter:(val) => biAction.getPengirim(val),
                                // onFocusOut:(val) => biAction.getPengirim(val),
                                disabled: !biState.formEditable || true
                            })
                        }
                    </div>
                    <div className='col-3'>
                        {
                            createForm({
                                type: "custom",
                                component: (
                                    <div className="input-group-append pt-4">
                                        <button onClick={pdrAction.openModal} disabled={paramUrl.mode === "otorisasi"} className="btn btn-outline-danger">
                                            Cari Nasabah
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
                }
                {/* <div className="col-12 pl-3 pr-3">
                    <button className="btn btn-outline-danger btn-sm col">
                        Cari Dari WIC
                    </button>
                </div> */}
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    {
                        createForm({
                            type: "text",
                            label: "Nama",
                            disabled: biState.isWic || !biState.formEditable,
                            onChange: (v) => biAction.setDataPengirim(p => ({ ...p, nama: v.toUpperCase() })),
                            value: biState.dataPengirim?.nama
                        })
                    }
                </div>
                <div className="col-12">
                    {
                        createForm({
                            type: "text",
                            label: "Alamat",
                            disabled: biState.isWic || !biState.formEditable,
                            onChange: (v) => biAction.setDataPengirim(p => ({ ...p, alamat: v.toUpperCase() })),
                            value: biState.dataPengirim?.alamat
                        })
                    }
                </div>
                <div className="col-6">
                    {
                        createForm({
                            type: "select",
                            label: "Jenis Identitas",
                            disabled: biState.isWic || !biState.formEditable,
                            value: biState.dataPengirim?.jenis_identitas,
                            onChange: (v) => biAction.setDataPengirim(p => ({ ...p, jenis_identitas: v.toUpperCase() })),
                            options: eJenisIdentitas,
                            key: "reference_code",
                            display: "reference_desc",
                        })
                    }
                </div>
                <div className="col-6">
                    {
                        createForm({
                            type: "number",
                            label: "Nomor Indentitas",
                            maxLength:16,
                            disabled: biState.isWic || !biState.formEditable,
                            onChange: (v) => biAction.setDataPengirim(p => ({ ...p, nomor_identitas: v.toUpperCase() })),
                            onFocusOut: (val) => biAction.cekIdentitas(val),
                            value: biState.dataPengirim?.nomor_identitas
                        })
                    }
                </div>
                <div className="col-12">
                    {
                        createForm({
                            type: "number",
                            label: "Nomor Telepon",
                            maxLength: 14,
                            value: biState.dataPengirim?.nomor_tlpn,
                            onChange: (v) => biAction.setDataPengirim(p=>({...p,nomor_tlpn:v})),

                            onEnter: (value) => biAction.checkHP(value, biAction.setDataPengirim(p=>({...p,nomor_tlpn:""}))),

                            onFocusOut: (value) => biAction.checkHP(value, biAction.setDataPengirim(p=>({...p,nomor_tlpn:""}))),
                            disabled: biState.isWic || !biState.formEditable,
                        })
                    }
                </div>
                {/* <div className="col-6">
                    {
                        createForm({
                            type: "text",
                            label: "Nasabah/WIC",
                            disabled: true,
                            value:biState.dataPengirim?.nasabah_wic
                        })
                    }
                </div> */}
            </div>
        </>
    );
}

export default DataPengirim;