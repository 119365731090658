import moment from 'moment';
// import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormBadanUsaha from './useFormBadanUsaha';
import Select from 'react-select';
import { useParams } from 'react-router';
import useKeycloak from '../../../../../hooks/keycloak.module';

const IdentitasNasabah = () => {

    const paramUrlM = useParams();
    const { fbAction, fbState } = useFormBadanUsaha();
    const { formData = {}, formEditable = false } = fbState;
    const { keycloak } = useKeycloak();
    const { mode } = useParams()
    useEffect(function(){
        fbAction.getDataMarketing()
    },[])
    return (
        <>
            <div className="pb-4">
                <div className="box">
                    <div className="row">
                        <div className="col-3 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nomor CIF</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="Otomatis terisi dari sistem"
                                    value={formData?.id}
                                />
                            </div>
                        </div>
                        <div className="col-3 align-self-center bns-form">
                            <div className="form-group">
                                <label>Kode Referal</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="Otomatis terisi dari sistem"
                                />
                            </div>
                        </div>
                        <div className="col-3 align-self-center bns-form">
                            <div>
                                <span className="ml-2 text-black">Tgl. Buka</span>
                                <span className="text-black ml-4">: {' '}</span>
                                {/* <span className="text-grey2">{moment(formData.created_at).format("DD MMMM YYYY")}</span> */}
                                <span className="text-grey2">{moment(fbState.podDate).format("DD MMMM YYYY")}</span>
                            </div>
                        </div>
                        <div className="col-3 align-self-center bns-form">
                            <div>
                                <span className="ml-2 text-black">Kode Cabang</span>
                                <span className="text-black ml-4">: {' '}</span>
                                <span className="text-grey2">{keycloak?.tokenParsed?.branch_code}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bns-form"> */}
            <FormRegistration
                itemsForm={[
                    // Data Nasabah Usaha
                    {
                        name: "Data Nasabah Badan Usaha",
                        rows: [
                            {
                                rows: 1,
                                label: 'Badan Usaha',
                                type: 'select',
                                placeholder: 'Pilih Badan Usaha',
                                required: true,
                                options: (fbState.dataDropDown?.R_BADAN_HUKUM_KORPORAT || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fbState.formData?._identitasNasabah?.nasabahBadanUsaha || '',
                                onChange: (value) => fbAction.changeForm(value, "nasabahBadanUsaha", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            },
                            {
                                //belum ada field api nya
                                rows: 1,
                                label: 'Asset perusahaan',
                                type: 'currency',
                                placeholder: 'Rp. 0',
                                required: true,
                                value: fbState.formData?._identitasNasabah?.nasabahAssetPerusahaan || '',
                                onChange: (value) => fbAction.changeForm(value, "nasabahAssetPerusahaan", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            },
                           
                            {
                                rows: 1,
                                label: 'Nomor NPWP',
                                type: 'number',
                                placeholder: 'Masukan Nomor NPWP',
                                required: true,
                                maxLength:15,
                                value: fbState.formData?._identitasNasabah?.nasabahNomorNpwp || '',
                                onChange: (value) => fbAction.changeForm(value.toString().replace(" ",""), "nasabahNomorNpwp", "_identitasNasabah"),
                                onEnter:(value)=> fbAction.cekNPWP(value,fbAction.changeForm("", "nasabahNomorNpwp", "_identitasNasabah")),
                                onFocusOut:(value)=> fbAction.cekNPWP(value,fbAction.changeForm("", "nasabahNomorNpwp", "_identitasNasabah")),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Nama Perusahaan',
                                type: 'text',
                                placeholder: 'Masukan Nama Perusahaan',
                                required: true,
                                value: fbState.formData?.groupName || '',
                                onChange: (value) => fbAction.changeForm(value, "groupName"),
                                disabled: !fbState.formEditable
                            },

                            {
                                rows: 2,
                                label: 'Omset perusahaan / Tahun',
                                type: 'currency',
                                placeholder: 'Rp. 0',
                                required: true,
                                value: fbState.formData?._identitasNasabah?.nasabahOmsetPerusahaan || '',
                                onChange: (value) => fbAction.changeForm(value, "nasabahOmsetPerusahaan", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows:2,
                                type:'custom',
                                component:(
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tempat pendirian',
                                                    type: 'text',
                                                    placeholder: 'Masukan Tempat pendirian',
                                                    value: fbState.formData?._identitasNasabah?.nasabahTempatAktePendirian,
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahTempatAktePendirian", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable,
                                                    required: true

                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tgl. Pendirian',
                                                    type: 'date',
                                                    placeholder: 'Tgl. Pendirian',
                                                    value: fbState.formData?._identitasNasabah?.nasabahTanggalAktePendirian || '',
                                                    onChange: (value) => {
                                                        var now = moment(Date.now());
                                                        if (now > moment(value)) fbAction.changeForm(value, "nasabahTanggalAktePendirian", "_identitasNasabah")
                                                    },
                                                    disabled: !fbState.formEditable,
                                                    required: true
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            ...( mode === "registration" ? [{
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-md-12 ml-2 pr-4">
                                            <button disabled={fbState.paramUrl?.mode === "otorisasi"} 
                                                onClick={fbAction.cekNasabahKorporat} 
                                                className="btn btn-sm btn-success float-right">
                                                Cek Data Nasabah
                                            </button>
                                        </div>
                                    </div>
                                )
                            }] : [])
                        ]
                    },
                    // End Data Nasabah Usaha
                    // Data Perusahaan
                    {
                        name: "Data Perusahaan",
                        rows: [
                            // {
                            //     rows: 1,
                            //     label: 'Bidang Usaha',
                            //     type: 'select',
                            //     required: true,
                            //     options: fbState.dataDropDown?.R_BIDANG_USAHA,
                            //     value: fbState.formData?._identitasNasabah?.nasabahBidangUsaha || '',
                            //     onChange: (value) => fbAction.changeForm(value, "nasabahBidangUsaha", "_identitasNasabah"),
                            //     key: "reference_code",
                            //     display: "reference_desc",
                            //     placeholder: 'Pilih Bidang Usaha',
                            //     disabled: !fbState.formEditable
                            // },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                //belum ada
                                                createForm({
                                                    label: 'Lokasi Usaha',
                                                    type: 'select',
                                                    placeholder: 'Kode Lokasi',
                                                    required: true,
                                                    options: (fbState.dataDropDown?.R_LOKASI_USAHA || []).map(({reference_code, reference_desc}) => ({
                                                        reference_code,
                                                        reference_desc : `${reference_code} - ${reference_desc}`
                                                    })),
                                                    value: fbState.formData?._identitasNasabah?.nasabahKodeLokasi || '',
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahKodeLokasi", "_identitasNasabah"),
                                                    key: "reference_code",
                                                    display: "reference_desc",
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        {/* <div className="col">
                                            {
                                                createForm({
                                                    label: <>&nbsp;</>,
                                                    type: 'text',
                                                    placeholder: 'Keterangan Lokasi',
                                                    value: fbState.formData?._identitasNasabah?.nasabahKeteranganLokasi,
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahKeteranganLokasi", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable,
                                                    required: true
                                                })
                                            }
                                        </div> */}
                                    </div>)
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nomor SIUPP',
                                                    type: 'text',
                                                    placeholder: 'Masukan Nomor SIUPP',
                                                    value: fbState.formData?._identitasNasabah?.nasabahNomorSiupp || '',
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahNomorSiupp", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable
                                                    // required: true
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tgl. Pengesahan SIUPP',
                                                    type: 'date',
                                                    placeholder: 'Tgl. Pengesahan SIUPP',
                                                    value: fbState.formData?._identitasNasabah?.nasabahTanggalPengesahanSiupp || '',
                                                    onChange: (value) => {
                                                        var now = moment(Date.now());
                                                        // console.log(now, moment(value), now<moment(value))
                                                        if (now > moment(value)) fbAction.changeForm(value, "nasabahTanggalPengesahanSiupp", "_identitasNasabah")
                                                    },
                                                    disabled: !fbState.formEditable
                                                    // required: true
                                                })
                                            }
                                        </div>
                                    </div>)
                            },
                            {
                                rows: 1,
                                label: 'Nomor SKDP',
                                type: 'text',
                                placeholder: 'Masukan Nomor skdp',
                                value: fbState.formData?._identitasNasabah?.nasabahNomorSkdp,
                                onChange: (value) => fbAction.changeForm(value, "nasabahNomorSkdp", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'No Akta Pendirian',
                                                    type: 'number',
                                                    placeholder: 'Masukan nomor akte pendirian',
                                                    value: fbState.formData?._identitasNasabah?.nasabahNomorAktePendirian || '',
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahNomorAktePendirian", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable,
                                                    required: true
                                                })
                                            }
                                        </div>
                                        {/* <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tgl. Pendirian',
                                                    type: 'date',
                                                    placeholder: 'Tgl. Pendirian',
                                                    value: fbState.formData?._identitasNasabah?.nasabahTanggalAktePendirian || '',
                                                    onChange: (value) => {
                                                        var now = moment(Date.now());
                                                        if (now > moment(value)) fbAction.changeForm(value, "nasabahTanggalAktePendirian", "_identitasNasabah")
                                                    },
                                                    disabled: !fbState.formEditable,
                                                    required: true
                                                })
                                            }
                                        </div> */}
                                    </div>
                                )
                            },

                            // {
                            //     rows: 1,
                            //     label: 'Tempat pendirian',
                            //     type: 'alphabet',
                            //     placeholder: 'Masukan Tempat pendirian',
                            //     value: fbState.formData?._identitasNasabah?.nasabahTempatAktePendirian,
                            //     onChange: (value) => fbAction.changeForm(value, "nasabahTempatAktePendirian", "_identitasNasabah"),
                            //     disabled: !fbState.formEditable
                            // },
                            {
                                rows: 2,
                                label: 'Bidang Usaha',
                                placeholder: 'Pilih Bidang Usaha',
                                type: 'select',
                                required: true,
                                options: (fbState.dataDropDown?.R_SEKTOR_EKONOMI || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                value: fbState.formData?._identitasNasabah?.nasabahSektorEkonomi || '',
                                onChange: (value) => fbAction.changeForm(value, "nasabahSektorEkonomi", "_identitasNasabah"),
                                key: "reference_code",
                                display: "reference_desc",
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col-7">
                                            {
                                                createForm({
                                                    label: 'Status Keterkaitan',
                                                    type: 'select',
                                                    required: true,
                                                    placeholder: 'Pilih Status Keterkaitan',
                                                    options: (fbState.dataDropDown?.eStatusKeterkaitan || []).map(({reference_code, reference_desc}) => ({
                                                        reference_code,
                                                        reference_desc : `${reference_code} - ${reference_desc}`
                                                    })),
                                                    value: fbState.formData?._identitasNasabah?.nasabahStatusKeterkaitan,
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahStatusKeterkaitan", "_identitasNasabah"),
                                                    key: "reference_code",
                                                    display: "reference_desc",
                                                    disabled: !fbState.formEditable,
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            <div className="form-group form-group-sm">
                                                <label
                                                 style={{ fontSize: "12px" }}
                                                    // className="text-danger"
                                                    className={formData?._identitasNasabah?.nasabahBankNonBank === "" || formData?._identitasNasabah?.nasabahBankNonBank === undefined|| formData?._identitasNasabah?.nasabahBankNonBank === null ? "text-danger" : ""}
                                                >Bank / Non-Bank
                                                <span
                                                    style={{
                                                        padding: '2px 5px',
                                                        background: '#E8E8E8',
                                                        fontSize: '8pt',
                                                        fontWeight: 'normal',
                                                        borderRadius: 3,
                                                    }}
                                                >
                                                    Wajib
                                                </span>
                                                </label>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-check-inline">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    required={true}
                                                                    checked={formData?._identitasNasabah?.nasabahBankNonBank === "T"}
                                                                    className="form-check-input"
                                                                    name="nasabahBankNonBank"
                                                                    // onChange={(e) => console.log(e.target.value)}
                                                                    onChange={(e) => {
                                                                        fbAction.changeForm(e.target.value, "nasabahBankNonBank", "_identitasNasabah");
                                                                    }}
                                                                    // value = {fbState.formData?._identitasNasabah?.nasabahnasabahBankNonBank}
                                                                    disabled={!fbState.formEditable}
                                                                    value="T"
                                                                />Bank
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-check-inline">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"

                                                                    className="form-check-input"
                                                                    name="nasabahBankNonBank"
                                                                    required={true}
                                                                    checked={formData?._identitasNasabah?.nasabahBankNonBank === "F"}
                                                                    // onChange={(e) => console.log(e.target.value)}
                                                                    onChange={(e) => {
                                                                        fbAction.changeForm(e.target.value, "nasabahBankNonBank", "_identitasNasabah");
                                                                    }}
                                                                    // value = {fbState.formData?._identitasNasabah?.nasabahnasabahBankNonBank}
                                                                    disabled={!fbState.formEditable}
                                                                    value="F"
                                                                />Non Bank
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'No Akta Perubahan',
                                                    type: 'number',
                                                    placeholder: 'No Akta Perubahan',
                                                    value: fbState.formData?._identitasNasabah?.nasabahNomorAktePerubahan,
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahNomorAktePerubahan", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable,
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tgl. Pengesahan Akta Perubahan',
                                                    type: 'date',
                                                    placeholder: 'Tgl. Pengesahan Akta Perubahan',
                                                    value: fbState.formData?._identitasNasabah?.nasabahTanggalAktePerubahan,
                                                    onChange: (value) => {
                                                        var now = moment(Date.now());
                                                        if (now > moment(value)) fbAction.changeForm(value, "nasabahTanggalAktePerubahan", "_identitasNasabah")
                                                    },
                                                    disabled: !fbState.formEditable,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tgl. Pengesahan SKDP',
                                                    type: 'date',
                                                    placeholder: 'Tgl. Pengesahan SKDP',
                                                    value: fbState.formData?._identitasNasabah?.nasabahTanggalPengesahanSkdp,
                                                    onChange: (value) => {
                                                        var now = moment(
                                                            moment(Date.now()).format("YYYY-MM-DD")
                                                        );
                                                        if (now >= moment(value)) fbAction.changeForm(value, "nasabahTanggalPengesahanSkdp", "_identitasNasabah")
                                                    },
                                                    disabled: !fbState.formEditable,
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tgl. Kadaluarsa SKDP',
                                                    type: 'date',
                                                    placeholder: 'Tgl. Kadaluarsa SKDP',
                                                    value: fbState.formData?._identitasNasabah?.nasabahTanggalKadaluarsaSkdp,
                                                    onChange: (value) => {
                                                        var now = moment(
                                                            moment(Date.now()).format("YYYY-MM-DD")
                                                        );
                                                        if (now <= moment(value)) fbAction.changeForm(value, "nasabahTanggalKadaluarsaSkdp", "_identitasNasabah")
                                                    },
                                                    disabled: !fbState.formEditable,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'No SK MENKUMHAM',
                                                    type: 'text',
                                                    placeholder: 'No SK MENKUMHAM',
                                                    maxLength:30,
                                                    required: false,
                                                    value: fbState.formData?._identitasNasabah?.nasabahNomorSkMenkumham,
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahNomorSkMenkumham", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable,
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tgl. Pengesahan SK MENKUMHAM',
                                                    type: 'date',
                                                    required: false,
                                                    placeholder: 'Tgl. Pengesahan SK MENKUMHAM',
                                                    value: fbState.formData?._identitasNasabah?.nasabahTanggalSkMenkumham,
                                                    onChange: (value) => {
                                                        var now = moment(Date.now());
                                                        if (now > moment(value)) fbAction.changeForm(value, "nasabahTanggalSkMenkumham", "_identitasNasabah")
                                                    },
                                                    disabled: !fbState.formEditable,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Nomor TDP',
                                type: 'text',
                                maxLength:30,
                                placeholder: 'Masukan Nomor TDP',
                                value: fbState.formData?._identitasNasabah?.nasabahNomorTDP,
                                onChange: (value) => fbAction.changeForm(value, "nasabahNomorTDP", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            }
                        ]
                    },
                    // End Data Perusahaan
                    // Data Pemilik
                    {
                        name: "Data Pemilik",
                        rows: [
                            {
                                rows: 1,
                                label: 'Pemilik',
                                type: 'select',
                                placeholder: 'Pilih jenis Pemilik',
                                value: fbState.formData?._identitasNasabah?.nasabahPemilik,
                                onChange: (value) => fbAction.changeForm(value, "nasabahPemilik", "_identitasNasabah"),
                                disabled: !fbState.formEditable,
                                options: [
                                    {
                                        key: "10",
                                        display : "10 - Penduduk - Pemerintah Pusat"
                                    },
                                    {
                                        key: "49",
                                        display : "49 - Penduduk - Lainnya"
                                    },
                                    {
                                        key: "50",
                                        display : "50 - Bukan Penduduk"
                                    },
                                ]
                            },

                            {
                                rows: 2,
                                label: 'Kepemilikan Modal',
                                type: 'select',
                                placeholder: 'Pilih Kepemilikan Modal',
                                options: (fbState.dataDropDown?.eStrukturModal || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                value: fbState.formData?._identitasNasabah?.nasabahStrukturModal,
                                onChange: (value) => fbAction.changeForm(value, "nasabahStrukturModal", "_identitasNasabah"),
                                key: "reference_code",
                                display: "reference_desc",
                                disabled: !fbState.formEditable
                            },
                        ]
                    },
                    // End Data Pemilik
                    // Data Alamat & Kontak
                    {
                        name: "Data Alamat & Kontak",
                        rows: [
                            {
                                rows: 1,
                                label: 'Alamat',
                                type: 'textarea',
                                required: true,
                                rowsText: 4,
                                placeholder: 'Masukkan Alamat',
                                value: fbState.formData?._identitasNasabah?.nasabahAlamat || '',
                                onChange: (value) => fbAction.changeForm(value, "nasabahAlamat", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RT",
                                                    maxLength: 3,
                                                    type: "number",
                                                    required: true,
                                                    placeholder: "RT",
                                                    value: fbState.formData?._identitasNasabah?.nasabahRt || '',
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahRt", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RW",
                                                    maxLength: 3,
                                                    type: "number",
                                                    required: true,
                                                    placeholder: "RW",
                                                    value: fbState.formData?._identitasNasabah?.nasabahRw || '',
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahRw", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Provinsi",
                                                    type: "select",
                                                    required: true,
                                                    placeholder: "Masukkan Provinsi",
                                                    options: (fbState.provinces || []).map(({province_code, province_name}) => ({
                                                        province_code,
                                                        province_name : `${province_code} - ${province_name}`
                                                    })),
                                                    display: "province_name",
                                                    key: "province_code",
                                                    value: fbState.formData?._identitasNasabah?.nasabahProvinsi || '',
                                                    onChange: (value) => {
                                                        // const data = fbState.provinces?.find(v => v.province_name === value);
                                                        fbAction.getingCities(value, fbAction.changeForm("", "nasabahKotaKabupaten", "_identitasNasabah"))
                                                        fbAction.changeForm(value, "nasabahProvinsi", "_identitasNasabah")
                                                    },
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kota/Kab",
                                                    type: "select",
                                                    options: (fbState.cities || []).map(({city_code, city_name}) => ({
                                                        city_code,
                                                        city_name : `${city_code} - ${city_name}`
                                                    })),
                                                    display: "city_name",
                                                    key: "city_code",
                                                    required: true,
                                                    placeholder: "Masukkan Kab/Kota",
                                                    value: fbState.formData?._identitasNasabah?.nasabahKotaKabupaten || '',
                                                    onChange: (value) => {
                                                        // fbAction.getingDistrict(value)
                                                        fbAction.changeForm(value, "nasabahKotaKabupaten", "_identitasNasabah")
                                                    },
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kecamatan",
                                                    type: "text",
                                                    required: true,
                                                    placeholder: "Masukkan Kecamatan",
                                                    //options: fbState.districts,
                                                    value: fbState.formData?._identitasNasabah?.nasabahKecamatan || '',
                                                    //onChange: (value) => {
                                                    //    fbAction.getingVillages(
                                                    //        fbState.formData?._identitasNasabah?.nasabahKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                                    //        value
                                                    //    )
                                                    //    fbAction.changeForm(value, "nasabahKecamatan", "_identitasNasabah")
                                                    //},
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahKecamatan", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kelurahan",
                                                    type: "text",
                                                    required: true,
                                                    //options: fbState.villages,
                                                    placeholder: "Masukkan Kelurahan",
                                                    value: fbState.formData?._identitasNasabah?.nasabahKelurahan || '',
                                                    //onChange: (value) => {
                                                    //    fbAction.getingPostalCodes(
                                                    //        fbState.formData?._identitasNasabah?.nasabahKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                                    //        fbState.formData?._identitasNasabah?.nasabahKecamatan, // ambil dari data Kota/Kab sebelumnya.
                                                    //        value
                                                    //    )
                                                    //    fbAction.changeForm(value, "nasabahKelurahan", "_identitasNasabah")
                                                    //},
                                                    onChange: (value) => fbAction.changeForm(value, "nasabahKelurahan", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },

                            {
                                rows: 1,
                                label: 'Kode Pos',
                                type: 'number',
                                maxLength:5,
                                placeholder: 'Masukan Kode Pos',
                                //options: fbState.postalCodes,
                                value: fbState.formData?._identitasNasabah?.nasabahKodePos,
                                onChange: (value) => fbAction.changeForm(value, "nasabahKodePos", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Telepon Kantor 1',
                                type: 'number',
                                maxLength: 18,
                                placeholder: '0000',
                                value: fbState.formData?._identitasNasabah?.nasabahTeleponKantorNomor1,
                                onChange: (value) => fbAction.changeForm(value, "nasabahTeleponKantorNomor1", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Telepon Kantor 2',
                                type: 'number',
                                maxLength: 18,
                                placeholder: '0000',
                                value: fbState.formData?._identitasNasabah?.nasabahTeleponKantorNomor2,
                                onChange: (value) => fbAction.changeForm(value, "nasabahTeleponKantorNomor2", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Fax',
                                type: 'number',
                                maxLength: 20,
                                placeholder: '0000',
                                value: fbState.formData?._identitasNasabah?.nasabahFaxNomor,
                                onChange: (value) => fbAction.changeForm(value, "nasabahFaxNomor", "_identitasNasabah"),
                                disabled: !fbState.formEditable
                            },
                            //Belum Ada Field di BE kalo udah ada hapus commentnya ya
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Nama Contact Person',
                                                    type: 'text',
                                                    placeholder: 'Nama Contact Person',
                                                    required: false,
                                                    value: fbState.formData?._identitasNasabah?.CPName,
                                                    onChange: (value) => fbAction.changeForm(value, "CPName", "_identitasNasabah"),
                                                    disabled: !fbState.formEditable,
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'No HandPhone',
                                                    type: 'number',
                                                    required: false,
                                                    maxLength:14,
                                                    placeholder: 'Masukan No HP',
                                                    value: fbState.formData?._identitasNasabah?.CPNomorHP,
                                                    onChange: (value) => fbAction.changeForm(value, "CPNomorHP", "_identitasNasabah"),
                                                    onEnter:(value)=> fbAction.checkHP(value, fbAction.changeForm("", "CPNomorHP", "_identitasNasabah")),
                                                    onFocusOut:(value)=> fbAction.checkHP(value, fbAction.changeForm("", "CPNomorHP", "_identitasNasabah")),
                                                    disabled: !fbState.formEditable,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            // {
                            //     rows: 2,
                            //     type: "custom",
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-md-12">
                            //                 <div className="form-group form-group-sm  pl-2 pr-2">
                            //                     <label style={{ fontSize: "12px" }}>Telepon Kantor 2</label>
                            //                     {/* <div className="col-md-3"> */}
                            //                     <div className="row">
                            //                         <div className="col-3">
                            //                             <input
                            //                                 type = "number"
                            //                                 placeholder="000"
                            //                                 className="form-control form-control-sm"
                            //                                 onChange={(e) => {
                            //                                     fbAction.changeForm(e.target.value, "nasabahTeleponKantorKodeArea2", "_identitasNasabah");
                            //                                 }}
                            //                                 value={fbState.formData?._identitasNasabah?.nasabahTeleponKantorKodeArea2}
                            //                                 disabled={!fbState.formEditable}
                            //                             />
                            //                         </div>
                            //                         <div className="col-9">
                            //                             <input
                            //                                 type = "number"
                            //                                 placeholder="000"
                            //                                 className="form-control form-control-sm"
                            //                                 onChange={(e) => {
                            //                                     fbAction.changeForm(e.target.value, "nasabahTeleponKantorNomor2", "_identitasNasabah");
                            //                                 }}
                            //                                 value={fbState.formData?._identitasNasabah?.nasabahTeleponKantorNomor2}
                            //                                 disabled={!fbState.formEditable}
                            //                             />
                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            // {
                            //     rows: 2,
                            //     type: "custom",
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-md-12">
                            //                 <div className="form-group form-group-sm  pl-2 pr-2">
                            //                     <label style={{ fontSize: "12px" }}>Telepon Kantor 3</label>
                            //                     {/* <div className="col-md-3"> */}
                            //                     <div className="row">
                            //                         <div className="col-3">
                            //                             <input
                            //                                 type = "number"
                            //                                 placeholder="000"
                            //                                 className="form-control form-control-sm"
                            //                                 onChange={(e) => {
                            //                                     fbAction.changeForm(e.target.value, "nasabahTeleponKantorKodeArea3", "_identitasNasabah");
                            //                                 }}
                            //                                 value={fbState.formData?._identitasNasabah?.nasabahTeleponKantorKodeArea3}
                            //                                 disabled={!fbState.formEditable}
                            //                             />
                            //                         </div>
                            //                         <div className="col-9">
                            //                             <input
                            //                                 type = "number"
                            //                                 placeholder="000"
                            //                                 className="form-control form-control-sm"
                            //                                 onChange={(e) => {
                            //                                     fbAction.changeForm(e.target.value, "nasabahTeleponKantorNomor3", "_identitasNasabah");
                            //                                 }}
                            //                                 value={fbState.formData?._identitasNasabah?.nasabahTeleponKantorNomor3}
                            //                                 disabled={!fbState.formEditable}
                            //                             />
                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            // {
                            //     rows: 2,
                            //     type: "custom",
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-md-12">
                            //                 <div className="form-group form-group-sm  pl-2 pr-2">
                            //                     <label style={{ fontSize: "12px" }}>Fax</label>
                            //                     {/* <div className="col-md-3"> */}
                            //                     <div className="row">
                            //                         <div className="col-3">
                            //                             <input
                            //                                 type = "number"
                            //                                 placeholder="000"
                            //                                 className="form-control form-control-sm"
                            //                                 onChange={(e) => {
                            //                                     fbAction.changeForm(e.target.value, "nasabahFaxKodeArea", "_identitasNasabah");
                            //                                 }}
                            //                                 value={fbState.formData?._identitasNasabah?.nasabahFaxKodeArea}
                            //                                 disabled={!fbState.formEditable}
                            //                             />
                            //                         </div>
                            //                         <div className="col-9">
                            //                             <input
                            //                                 type = "number"
                            //                                 placeholder="000"
                            //                                 className="form-control form-control-sm"
                            //                                 onChange={(e) => {
                            //                                     fbAction.changeForm(e.target.value, "nasabahFaxNomor", "_identitasNasabah");
                            //                                 }}
                            //                                 value={fbState.formData?._identitasNasabah?.nasabahFaxNomor}
                            //                                 disabled={!fbState.formEditable}
                            //                             />
                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            {
                                rows: 2,
                                label: "Email",
                                type: "text",
                                required: false,
                                placeholder: "Masukkan alamat email",
                                value: fbState.formData?.emailAddress,
                                onChange: (value) => fbAction.changeMailForm(value, "emailAddress"),
                                disabled: !fbState.formEditable
                            },

                        ]
                    }
                    // End Data Alamat & Kontak
                ]}
            />

            <div className="row">
                <div className="col-md-6">
                    <FormRegistration
                        totalRows={1}
                        itemsForm={[
                            {
                                name: "Informasi Lainnya",
                                rows: [
                                    {
                                        rows: 1,
                                        label: <strong>Nasabah Prioritas</strong>,
                                        type: "checkbox",
                                        checked:fbState.formData?.nasabah_prioritas,
                                        value: fbState.formData?.nasabah_prioritas,
                                        onChange: (value) => fbAction.changeForm(value, "nasabah_prioritas"),
                                        disabled: !fbState.formEditable
                                    },
                                    {
                                        rows : 1,
                                        type: "select",
                                        label: "Marketing",
                                        required: true,
                                        placeholder: "Pilih marketing inisiasi",
                                        disabled:!fbState.formEditable || mode === "edit",
                                        key:"kode_ao",
                                        display:"nama_ao",
                                        options:(fbState.dataMarketing || []).map(({kode_ao, nama_ao}) => ({
                                            kode_ao,
                                            nama_ao : `${kode_ao} - ${nama_ao}`
                                        })),
                                        value:fbState.formData?._identitasNasabah?.nasabahKodeMarketing,
                                        onChange:(e) =>fbAction.changeForm(e,"nasabahKodeMarketing","_identitasNasabah")

                                    },
                                    // {
                                    //     rows: 1,
                                    //     type: 'custom',
                                    //     component: (
                                    //         <div className="row">
                                    //             <div className="col-12 pl-3 pr-3">
                                    //                 <div className="form-group form-group-sm">
                                    //                     <label>Marketing </label>
                                    //                     <Select
                                    //                         placeholder="Pilih marketing inisiasi"
                                    //                         onChange={(e) => {
                                    //                             fbAction.changeForm(e.value,"nasabahKodeMarketing","_identitasNasabah");
                                    //                             // const namaMarketing = fbState.dataMarketing?.find(v => v.kode_ao === e.value)?.nama_ao
                                    //                             // fbAction.changeForm(namaMarketing,"nasabahNamaMarketing","_identitasNasabah");

                                    //                         }}
                                    //                         value={
                                    //                             fbState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao })).find(c => c.value === fbState.formData?._identitasNasabah?.nasabahKodeMarketing)
                                    //                         }
                                    //                         isDisabled={!fbState.formEditable || mode === "edit"}
                                    //                         options={fbState.dataMarketing?.map(v => ({ value: v.kode_ao, label: v.nama_ao }))}
                                    //                     />
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //         // <div className="row">
                                    //         //     <div className="col-12">
                                    //         //         <div className="form-group form-group-sm">
                                    //         //             <label>Marketing</label>
                                    //         //             <div className="input-group input-group-sm mb-3">
                                    //         //                 <input
                                    //         //                     type="text"
                                    //         //                     className="form-control form-control-sm col-5"
                                    //         //                     placeholder="Kode Marketing"
                                    //         //                     onChange={(e) => {
                                    //         //                     fbAction.changeForm(e.target.value,"nasabahKodeMarketing","_identitasNasabah");
                                    //         //                         }}
                                    //         //                     value = {fbState.formData?._identitasNasabah?.nasabahKodeMarketing}
                                    //         //                     disabled={!fbState.formEditable}
                                    //         //                 />
                                    //         //                 <div className="input-group-append col-7">
                                    //         //                     <input
                                    //         //                         type="text"
                                    //         //                         disabled={true}
                                    //         //                         className="form-control form-control-sm"
                                    //         //                         placeholder="Nama Marketing"
                                    //         //                         onChange={(e) => {
                                    //         //                         fbAction.changeForm(e.target.value,"nasabahNamaMarketing","_identitasNasabah");
                                    //         //                             }}
                                    //         //                         value = {fbState.formData?._identitasNasabah?.nasabahNamaMarketing}
                                    //         //                         disabled={!fbState.formEditable}
                                    //         //                     />
                                    //         //                 </div>
                                    //         //             </div>
                                    //         //         </div>
                                    //         //     </div>
                                    //         // </div>
                                    //     )
                                    // },
                                    {
                                        rows: 1,
                                        type: "textarea",
                                        disabled: !fbState.formEditable,
                                        label: "Keterangan",
                                        required: false,
                                        value: fbState.formData?.notes,
                                        onChange: (value) => fbAction.changeForm(value, "notes"),
                                        placeholder: "Bila Ada",
                                        rowsText: 4
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
                <div className="col-md-6">
                    <FormRegistration
                        totalRows={1}
                        itemsForm={[
                            {
                                name: "Hirarki Nasabah",
                                rows: [
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            label: 'Nasabah Induk',
                                                            type: 'number',
                                                            required: false,
                                                            placeholder: 'Nomor Induk',
                                                            value: fbState.formData?._identitasNasabah?.nasabahNomorInduk,
                                                            onChange: (value) => fbAction.changeForm(value, "nasabahNomorInduk","_identitasNasabah"),
                                                            disabled: !fbState.formEditable,
                                                            onEnter:(value)=> fbAction.cekNasabahEnter(value),
                                                            onFocusOut:(value)=> fbAction.cekNasabahEnter(value),
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            label: <>&nbsp;</>,
                                                            type: 'text',
                                                            required: false,
                                                            placeholder: 'Masukan nama',
                                                            readOnly: true,
                                                            value: fbState.formData?._identitasNasabah?.nasabahNamaInduk,
                                                            onChange: (value) => fbAction.changeForm(value, "nasabahNamaInduk","_identitasNasabah"),
                                                            disabled: !fbState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            // <div className="row">
                                            //     <div className="col-12">
                                            //         <div className="form-group form-group-sm">
                                            //             <label>nasabah induk</label>
                                            //             <div className="input-group input-group-sm mb-3 pr-0">
                                            //                 <input
                                            //                     type="text"
                                            //                     className="form-control form-control-sm col-5"
                                            //                     placeholder="Nomor Induk"
                                            //                     onChange={(e) => {
                                            //                         fbAction.changeForm(e.target.value, "nasabahNomorInduk", "_identitasNasabah");
                                            //                     }}
                                            //                     value={fbState.formData?._identitasNasabah?.nasabahNomorInduk}
                                            //                     disabled={!fbState.formEditable}
                                            //                 />
                                            //                 <div className="input-group-append col-7 pl-0">
                                            //                     <input
                                            //                         type="text"
                                            //                         disabled={true}
                                            //                         className="form-control form-control-sm"
                                            //                         placeholder="Nama Nasabah"
                                            //                         onChange={(e) => {
                                            //                             fbAction.changeForm(e.target.value, "nasabahNamaInduk", "_identitasNasabah");
                                            //                         }}
                                            //                         value={fbState.formData?._identitasNasabah?.nasabahNamaInduk}
                                            //                         disabled={!fbState.formEditable}
                                            //                     />
                                            //                 </div>
                                            //             </div>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                        )
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
            </div>
        </>
    );
}

export default IdentitasNasabah;