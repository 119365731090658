import React, { useEffect, useLayoutEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Skeleton from 'react-loading-skeleton';


export default function ({
    id = "chart",
    data = [],
    colors = [],
    fieldValue = "value",
    fieldLabel = "label",
    height = "200px",
    isLoading = false
}) {

    let dataSource = data.map((d, i) => ({
        ...d,
        color: colors[i]
    }));


    useEffect(() => {
        if (!isLoading) {
            am4core.useTheme(am4themes_animated);
            let chart = am4core.create(id, am4charts.PieChart);


            // Add and configure Series
            let pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = fieldValue;
            pieSeries.dataFields.category = fieldLabel;

            // Let's cut a hole in our Pie chart the size of 30% the radius
            chart.innerRadius = am4core.percent(30);

            // Put a thick white border around each Slice
            pieSeries.slices.template.stroke = am4core.color("#dbdde0");
            pieSeries.slices.template.strokeWidth = 2;
            pieSeries.slices.template.strokeOpacity = 0.5;
            pieSeries.slices.template.cursorOverStyle = [
                {
                    "property": "cursor",
                    "value": "pointer"
                }
            ];

            pieSeries.alignLabels = false;
            pieSeries.labels.template.text = "{value.percent.formatNumber('#.00')}%"
            // pieSeries.labels.template.bent = true;
            pieSeries.labels.template.radius = 3;
            pieSeries.labels.template.padding(0, 0, 0, 0);

            let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter);
            shadow.opacity = 0;

            let hoverState = pieSeries.slices.template.states.getKey("hover");
            let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter);
            hoverShadow.opacity = 0.7;
            hoverShadow.blur = 5;
            pieSeries.slices.template.propertyFields.fill = "color";
            chart.data = dataSource;

            return () => {
                chart.dispose();
            };
        }
    }, [isLoading]);
    return (
        <>
            {isLoading ? <Skeleton
                circle={true} height={200 / 1.5} width={200 / 1.5} style={{ marginBottom: 5, margin: "auto", marginTop: 200 * 0.1 }} /> : <div
                style={{
                    height
                }}
                id={id}
            />
            }
        </>
    )
}