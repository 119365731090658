// import clsx from 'clsx';
// import React, { memo, useMemo, useState } from 'react';
// import { FormControl } from 'react-bootstrap';
// import ReactPaginate from 'react-paginate';
// import { useQuery } from 'react-query';
// import { useHistory, useLocation } from 'react-router';
// import { Button } from 'semantic-ui-react';
// import { baseUrl } from '../../../app.config';
// import { AppInterfaces } from '../../../app.data';
// import { IconExcel, IconPrinter } from '../../../icon';
// import { joinDefinedString } from '../../../utils/joinDefinedString';
// import { ActionMore, TableGrid } from '../../controls';
// import BlokirRekening from './blokir_rekening';
// import CetakStatementModal from './cetak_statement_modal';
// import HoldDanaModal from './hold_dana';
// import TutupRekeningModal from './tutup_rekening';

import React, { memo, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { AppAction, AppInterfaces } from '../../../app.data';
import { Dropdown, TableList } from '../../controls';
// import BlokirRekeningModal from './blokirRekening';
// import CetakAdvice from './cetakAdvice';
// import CetakBillyet from './cetakBilyet';
// import { IconMoney, IconPrint } from './icon';
// import NotaPembayaran from './notaPembayaran';
// import PencairanDeposito from './pencairanDeposito';
// import { PerubahanNisbah } from './perubahanNisbah';
// import ReversePencairan from './reversePencairan';
// import TutupRekening from './tutupRekening';

const dummy = [
    {
        'nomorCIF' : '0000123',
        'nomorDeposito' : 'vschshahsa', 
        'status' : 'Aktif',
        'namaLengkap' : 'Solipsim',
        'jenisIdentitas' : 'fsa',
        'nomorIdentitas' :'fa',

    },{
        'nomorCIF' : '0000124',
        'nomorDeposito' : 'vschshahsa', 
        'status' : 'Aktif',
        'namaLengkap' : 'Solipsim',
        'jenisIdentitas' : 'fsa',
        'nomorIdentitas' :'fa',

    },{
        'nomorCIF' : '0000125',
        'nomorDeposito' : 'vschshahsa', 
        'status' : 'Aktif',
        'namaLengkap' : 'Solipsim',
        'jenisIdentitas' : 'fsa',
        'nomorIdentitas' :'fa',

    },{
        'nomorCIF' : '000016',
        'nomorDeposito' : 'vschshahsa', 
        'status' : 'Aktif',
        'namaLengkap' : 'Solipsim',
        'jenisIdentitas' : 'fsa',
        'nomorIdentitas' :'fa',

    }, {
        'nomorCIF' : '000017',
        'nomorDeposito' : 'vschshahsa', 
        'status' : 'Aktif',
        'namaLengkap' : 'Solipsim',
        'jenisIdentitas' : 'fsa',
        'nomorIdentitas' :'fa',

    }
];
export default AppInterfaces.appLoad(
    memo(function ({ store, methods, dispatch }) {
        const appAction = useRef(null);
        // const baseUrl = '/deposito/lokal/semua-akun';
        const baseUrl = '/deposito/nasabah-deposito';
        const { data_deposito = [] } = store;
        const history = useHistory();

        const [paramFetch, setParamFetch] = useState({
            accountHolderType: 'CLIENT',
        });

        const setFilter = function (param, data) {
            setParamFetch((prevState) => ({
                ...prevState,
                [param]: data,
            }));
        };

        // useEffect(function () {
        //     appAction.current.doGet({
        //         url: baseUrl,
        //         vari: 'data_deposito',
        //         param: {
        //             page: 0,
        //             limit: 15,
        //             detailsLevel: 'FULL',
        //             ...paramFetch,
        //         },
        //     });
        // }, []);

        const [dataTutupRekening, setDataTutupRekening] = useState({});
        const [tipeTutupRekening, setTipeTutupRekening] = useState('tutup');
        const [tipeBlokirRekening, setTipeBlokirRekening] = useState('blokir');

        const [modal, setModal] = useState({
            cetakAdvice: false,
            cetakBillyet: false,
            notaPembayaran: false,
            pencairanDeposito: false,
            perubahanNisbah: false,
            reversePencairan: false,
            tutupRekeningDeposito: false,
            blokirRekeningDeposito: false,
        });

        return (
            <>
                {/* <CetakAdvice
                    show={modal.cetakAdvice}
                    onHide={() => setModal({ ...modal, cetakAdvice: false })}
                />
                <CetakBillyet
                    show={modal.cetakBillyet}
                    onHide={() => setModal({ ...modal, cetakBillyet: false })}
                />
                <NotaPembayaran
                    show={modal.notaPembayaran}
                    onHide={() => setModal({ ...modal, notaPembayaran: false })}
                />
                <PencairanDeposito
                    show={modal.pencairanDeposito}
                    onHide={() => setModal({ ...modal, pencairanDeposito: false })}
                />
                <PerubahanNisbah
                    show={modal.perubahanNisbah}
                    onHide={() => setModal({ ...modal, perubahanNisbah: false })}
                />
                <ReversePencairan
                    show={modal.reversePencairan}
                    onHide={() => setModal({ ...modal, reversePencairan: false })}
                />
                <TutupRekening
                    type={tipeTutupRekening}
                    show={modal.tutupRekeningDeposito}
                    onHide={() => {
                        setModal({ ...modal, tutupRekeningDeposito: false });
                    }}
                    data={dataTutupRekening}
                    limit={store?.showingPage}
                    accountHolderType={paramFetch?.accountHolderType}
                />
                <BlokirRekeningModal
                    show={modal.blokirRekeningDeposito}
                    data={dataTutupRekening}
                    type={tipeBlokirRekening}
                    onHide={() => setModal({ ...modal, blokirRekeningDeposito: false })}
                    accountHolderType={paramFetch?.accountHolderType}
                    onSuccess={() => {
                        appAction.current.doGet({
                            url: baseUrl,
                            vari: 'data_deposito',
                            param: {
                                page: 0,
                                limit: 15,
                                detailsLevel: 'FULL',
                                ...paramFetch,
                            },
                        });
                    }}
                /> */}
                <AppAction ref={appAction} />
                <nav aria-label="breadcrumb" className="mb-3">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a className="text-primary-bds" href="#">WIC</a>
                        </li>
                        {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                        <li className="breadcrumb-item active" aria-current="page">
                            Data WIC
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-md-12">
                        <TableList
                            onChangeShow={(detil) => {
                                appAction.current.doGet({
                                    url: baseUrl,
                                    vari: 'data_deposito',
                                    param: { ...detil, page: detil.page - 1, ...paramFetch },
                                });
                            }}
                            onNextPage={(detil) => {
                                appAction.current.doGet({
                                    url: baseUrl,
                                    vari: 'data_deposito',
                                    param: { ...detil, page: detil.page - 1, ...paramFetch },
                                });
                            }}
                            onPrevPage={(detil) => {
                                appAction.current.doGet({
                                    url: baseUrl,
                                    vari: 'data_deposito',
                                    param: { ...detil, page: detil.page - 1, ...paramFetch },
                                });
                            }}
                            withActions={true}
                            actionItems={[
                                {
                                    name: 'Lihat Informasi Deposito',
                                    onClick: (e) => {
                                        history.push('/deposito/detil', e);
                                    },
                                },
                                {
                                    name: 'Ubah Data Deposito',
                                    withDivider: true,
                                    onClick: (e) => {
                                        history.push('/deposito/edit', e);
                                    },
                                },
                                {
                                    name: 'Signature Verification System',
                                    withDivider: true,
                                    onClick: (e) => {
                                        history.push(
                                            '/nasabah-cif/data/svs_rekening_nasabah_korporat',
                                            e
                                        );
                                    },
                                },
                                // {
                                //     name: 'Blokir Rekening Deposito',
                                //     withDivider: true,
                                //     onClick: (e) => {
                                //         setModal({ ...modal, blokirRekeningDeposito: true });
                                //         setDataTutupRekening(e);
                                //         setTipeBlokirRekening('blokir');
                                //     },
                                //     enabled: (e) => e.status === 'ACTIVE',
                                // },
                                // {
                                //     name: 'Buka Blokir Rekening Deposito',
                                //     withDivider: true,
                                //     onClick: (e) => {
                                //         setModal({ ...modal, blokirRekeningDeposito: true });
                                //         setDataTutupRekening(e);
                                //         setTipeBlokirRekening('buka');
                                //     },
                                //     enabled: (e) => e.status === 'LOCKED',
                                // },
                                // {
                                //     name: 'Tutup Rekening Deposito',
                                //     withDivider: true,
                                //     onClick: (e) => {
                                //         setModal({ ...modal, tutupRekeningDeposito: true });
                                //         setDataTutupRekening(e);
                                //         setTipeTutupRekening('tutup');
                                //     },
                                //     enabled: (e) =>
                                //         e.status === 'APPROVAL' || e.status === 'ACTIVE',
                                // },
                                // {
                                //     name: 'Batalkan Tutup Rekening Deposito',
                                //     withDivider: true,
                                //     onClick: (e) => {
                                //         setModal({ ...modal, tutupRekeningDeposito: true });
                                //         setDataTutupRekening(e);
                                //         setTipeTutupRekening('batal');
                                //     },
                                //     enabled: (e) =>
                                //         e.status === 'CLOSE' || e.status === 'CLOSE_WITHDRAW',
                                // },
                            ]}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'CIF',
                                    field: 'nomorCIF',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Jenis Nasabah',
                                    field: '_',
                                    maxWidth: '50px',
                                    onRender: (item) =>
                                        paramFetch.accountHolderType === 'CLIENT'
                                            ? 'Nasabah Individu'
                                            : 'Nasabah Korporat',
                                },
                                {
                                    name: 'Nomor Deposito',
                                    field: 'nomorDeposito',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Status',
                                    field: 'status',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Nama Lengkap',
                                    field: 'namaLengkap',
                                    maxWidth: '50px',
                                    onRender: (item) => item.namaLengkap,
                                    // ...( paramFetch.accountHolderType === "CLIENT" ? {
                                    // onRender: function(item){
                                    //     return item.namaDepan+" "+item.namaBelakang
                                    // }}:{})
                                },
                                {
                                    name: 'Alamat',
                                    field: 'address',
                                    maxWidth: '50px',
                                    onRender: function (item) {
                                        return (
                                            (item.alamat?.alamat || '') +
                                            ', ' +
                                            (item.alamat?.alamat || '') +
                                            ' kab. ' +
                                            (item.alamat?.kotaKabupaten || '') +
                                            ' ' +
                                            (item.alamat?.provinsi || '') +
                                            ' ' +
                                            (item.alamat?.kodepos || '')
                                        );
                                    },
                                    // ...(
                                    //     paramFetch.accountHolderType === "GROUP" ? {
                                    //     onRender: function(item){
                                    //         return item.alamat?.alamat+", "
                                    //                 +item.alamat?.alamat+" kab. "
                                    //                 +item.alamat?.kotaKabupaten+" "
                                    //                 +item.alamat?.provinsi+" "
                                    //                 +item.alamat?.kodepos
                                    //     }}:{}
                                    // )
                                },
                                {
                                    name: 'Jenis Identitas',
                                    field: 'jenisIdentitas',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'Nomor Identitas',
                                    field: 'nomorIdentitas',
                                    maxWidth: '50px',
                                },
                            ]}
                            data={dummy}
                            filter={true}
                            formsFilter={[
                                {
                                    type: 'select',
                                    options: [
                                        {
                                            key: 'CLIENT',
                                            display: 'Perorangan',
                                        },
                                        {
                                            key: 'GROUP',
                                            display: 'Badan Usaha',
                                        },
                                    ],
                                    placeholder: 'Jenis Nasabah',
                                    value: paramFetch.accountHolderType,
                                    disabled: false,
                                    readonly: false,
                                    onChange: (value) =>
                                        setParamFetch((prevState) => ({
                                            ...prevState,
                                            accountHolderType: value,
                                        })),
                                },
                                {
                                    type: 'text',
                                    placeholder: 'Cari ...',
                                    disabled: false,
                                    readonly: false,
                                    value: paramFetch.search,
                                    onChange: (value) =>
                                        setParamFetch((prevState) => ({
                                            ...prevState,
                                            search: value,
                                        })),
                                },
                            ]}
                            onFilter={() =>
                                appAction.current.doGet({
                                    url: baseUrl,
                                    vari: 'data_deposito',
                                    param: {
                                        page: 0,
                                        limit: store.showingPage,
                                        ...paramFetch,
                                    },
                                })
                            }
                            onResetFilter={() => {
                                setParamFetch({
                                    search: '',
                                    accountHolderType: 'CLIENT',
                                });
                                appAction.current.doGet({
                                    url: baseUrl,
                                    vari: 'data_deposito',
                                    param: {
                                        page: 0,
                                        limit: store.showingPage,
                                        ...{
                                            search: '',
                                            accountHolderType: 'CLIENT',
                                        },
                                    },
                                });
                            }}
                            resetFilter={true}
                            submitButton="Cari"
                        />
                    </div>
                </div>
            </>
        );
    })
);
