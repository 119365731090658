import React from 'react';
import { LoginProvider } from './useLogin';
import logo from './logo-isi-putih.png';
import loginBG from './Aksen.png';
import vektor from './Vector.png';
import useLogin from './useLogin';
import { keycloakConfig } from '../../../keycloak.config';
import { baseUrlAuth } from '../../../app.config';
import aksen from './Aksen.png'
import bgIsi from './logo-only-isi.png'

function LoginPage() {

    const {
        loginAction,
        loginState
    } = useLogin();
    const s = {}
    return <>
        {/* <form  method='POST' action={`${baseUrlAuth}/auth/realms/${keycloakConfig.realm}/protocol/openid-connect/token`} encType="application/x-www-form-urlencoded"> */}

        <div className="bg-gray-6">
            <main>
                <div className="row p-0 m-0 bg-fff">
                    <div
                        className="col-8 p-0 m-0"
                        style={{
                            // backgroundImage: `url(${loginBG})`,
                            background: ` url(${bgIsi}) 40%, linear-gradient(45deg, rgba(0,187,239,1) 0%, rgba(0,75,188,1) 60%)`,
                            // backgroundImage: `url(${bgIsi}) `,
                            backgroundSize: 'cover',
                            height: '100vh',
                        }}
                    >
                        <div className="">
                            <div className="card-logo text-right">
                                <div className="row mx-0 align-content-center">
                                    <div className="col-1 px-5 py-4">
                                        <div className="card-logo text-right">

                                            <img src={logo} style={{ margin: "auto", width:250 }} />
                                        </div>
                                    </div>
                                    <div className="col-2 px-0 py-0 offset-9"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row p-0 m-0 align-items-center">
                            <div className="col-8 pb-10 px-5 text-right" style={{ marginTop: 220 }}>
                                {/* <img src={logo} style={{margin:"auto"}}/> */}
                                {/* <h1 style={{  color: 'white', fontWeight: 600 }}>Branch Distribution System</h1> */}
                                {/* <p style={{ color: 'white', fontSize: 18 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nec eu vitae sit et, tempus nulla et.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-4" 
                    // style={{
                    //     background:`url(${aksen})`
                    // }}
                    >
                        <div className="p-2 card-logo text-right">
                            {/* Pengaturan <i className="fas fa-cog"></i> */}
                        </div>
                        <div className="row p-0 m-0 align-items-center card-form-login">
                            <div className="col pb-10 px-5 text-left ">
                                <div className="h4 py-0">Login</div>
                                <div className="h4 text-orange-primary">
                                    <h5>
                                        Selamat datang, selalu berikan pelayanan terbaik Anda.
                                    </h5>
                                </div>
                                <hr />
                                <div className="form-group">
                                    <label for="idPengguna">Usename</label>
                                    {/* <input hidden value={"password"} name="grant_type" />
                                    <input hidden value={keycloakConfig.clientId} name="client_id" />
                                    <input hidden value={""} name="client_secret" /> */}
                                    <input
                                        type="text"
                                        placeholder="Input username"
                                        className={`form-control ${s.idErr ? 'is-invalid' : ''
                                            }`}
                                        // ref="idPengguna"
                                        id="idPengguna"
                                        name='username'
                                        // value="ritelloket"
                                        onChange={(e) => loginAction.setFormData(p => ({
                                            ...p,
                                            username: e.target.value
                                        }))}
                                        value={loginState.formData?.username}
                                        onKeyUp={(e) => e.keyCode === 13 && loginAction.doLogin()}
                                        aria-describedby="validationId"
                                        required
                                    />
                                    <div id="validationId" className="invalid-feedback">
                                        Silahkan masukkan ID pengguna Anda
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label for="password">Password</label>
                                    <div className="input-group">
                                        <input
                                            placeholder="Input password"
                                            type={loginState.showPwd ? 'text' : 'password'}
                                            className={`form-control border-right-0 ${s.pwdErr ? 'border-danger' : ''
                                                }`}
                                            // value="123456"
                                            // ref="password"
                                            onKeyUp={(e) => e.keyCode === 13 && loginAction.doLogin()}
                                            onChange={(e) => loginAction.setFormData(p => ({
                                                ...p,
                                                password: e.target.value
                                            }))}
                                            value={loginState.formData?.password}
                                            id="password"
                                            name="password"
                                            aria-describedby="validationpwd"
                                            required
                                        />
                                        <div className="input-group-append">
                                            <span
                                                className={`form-control border-left-0 border-rad-left-0 ${s.pwdErr ? 'border-danger' : ''
                                                    }`}
                                                onMouseDown={() => loginAction.setShowPwd(true)}
                                                onMouseUp={() => loginAction.setShowPwd(false)}
                                            >
                                                <div className="pointer btn-input-eye">
                                                    <i className="fa fa-eye"></i>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    {/* {s.pwdErr ? (
                                        <small className="text-danger">
                                            Silahkan masukkan password Anda
                                        </small>
                                    ) : (
                                        ''
                                    )} */}
                                </div>
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary form-control"
                                        onClick={() => loginAction.doLogin()}
                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="f-14 text-gray-3 text-center">Versi 1.0.0</div> */}
                    </div>
                </div>
            </main>
        </div>

        {/* </form> */}
    </>
}

export default () => <LoginProvider>
    <LoginPage />
</LoginProvider>