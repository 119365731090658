import React from 'react';
import './style.css'
import moment from 'moment'

export default () => {

    const dateNow = new Date()
    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <div className="card p-4" style={{minHeight:350}}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Hallo,</h4>
                                    <h5>Mayuga Wicaksana</h5>
                                    <p style={{color:"#E6530D"}}>Berikan pelayanan terbaik untuk nasabah Bank Net Syariah</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mx-auto text-center saldo" >
                                        Saldo Kas <br/>
                                        <span>Rp. 100.0000</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="card p-4"  style={{minHeight:350}}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h4>Dashboard</h4>
                                </div>
                                <div className="col">
                                    <span>Update : {moment(dateNow).format("DD MMMM YYYY HH:mm")}</span>
                                </div>
                                <div className="col">
                                    <div className="form-group form-inline">
                                        <label className="mr-2">
                                            Periode :
                                        </label>
                                        <select className="form-control col">
                                            <option>1 Hari Terakhir</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">

                                </div>
                                
                                <div className="col-md-6">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}