import { FormControl } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { IconExcel } from '../../../icon';
import { TableGrid } from '../../controls';

const InfoDaftarHitamIndividualPage = () => {
    return (
        <>
            <Breadcrumb className="tw-mb-5 tw-text-12">
                <Breadcrumb.Section className="text-orange" link>
                    Nasabah / CIF
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">
                    Informasi Daftar Hitam Individual
                </Breadcrumb.Section>
            </Breadcrumb>
            <div style={{ minHeight: '80vh' }} className="tw-bg-white tw-flex tw-flex-col">
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Status Data"
                                as="select"
                            >
                                <option value="">Status CIF</option>
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="DHIB"
                                as="select"
                            >
                                <option value="">DHIB</option>
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="DHN"
                                as="select"
                            >
                                <option value="">DHN</option>
                            </FormControl>
                            <FormControl
                                className="mr-4"
                                size="sm"
                                placeholder="Cari no.rekening, nama..."
                            />
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                            >
                                Reset
                            </Button>
                            <Button
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                            >
                                Cari
                            </Button>
                        </div>
                        <div>
                            <label className="tw-flex tw-items-center tw-relative tw-cursor-pointer">
                                <IconExcel className="tw-mr-2.5" />
                                <input
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="tw-z-0 tw-absolute tw-top-0 tw-left-0 tw-opacity-0 tw-w-0 tw-h-0"
                                    type="file"
                                />
                                <span className="tw-text-12" style={{ color: '#28A745' }}>
                                    Export
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Nomor Nasabah',
                            field: 'no_nasabah',
                        },
                        {
                            name: 'Nama Lengkap',
                            field: 'nama_lengkap',
                        },
                        {
                            name: 'Status CIF',
                            field: 'cif_status',
                        },
                        {
                            name: 'Gelar',
                            field: 'gelar',
                            width: '40px',
                        },
                        {
                            name: 'No. Identitas',
                            field: 'no_identitas',
                        },
                        {
                            name: 'NPWP',
                            field: 'npwp',
                        },
                        {
                            name: 'Tanggal Lahir',
                            field: 'tanggal_lahir',
                        },
                        {
                            name: 'DHIB',
                            field: 'dhib',
                            width: '40px',
                        },
                        {
                            name: 'DHN',
                            field: 'dhn',
                            width: '40px',
                        },
                        {
                            name: 'No. DHN',
                            field: 'no_dhn',
                            width: '60px',
                        },
                        {
                            name: 'Batas Saksi',
                            field: 'batas_saksi',
                        },
                    ]}
                    data={[
                        {
                            no_nasabah: '1029379814',
                            nama_lengkap: 'Edi Supardi',
                            cif_status: 'Perpanjangan',
                            gelar: 'S1',
                            no_identitas: '32377097890',
                            npwp: '012973891263',
                            tanggal_lahir: '12 Februari 1992',
                            dhib: 'T',
                            dhn: 'T #2',
                            no_dhn: '12/4/2020',
                            batas_saksi: '15-05-2015',
                        },
                        {
                            no_nasabah: '1029379814',
                            nama_lengkap: 'Edi Supardi',
                            cif_status: 'Perpanjangan',
                            gelar: 'S1',
                            no_identitas: '32377097890',
                            npwp: '012973891263',
                            tanggal_lahir: '12 Februari 1992',
                            dhib: 'T',
                            dhn: 'T #2',
                            no_dhn: '12/4/2020',
                            batas_saksi: '15-05-2015',
                        },
                        {
                            no_nasabah: '1029379814',
                            nama_lengkap: 'Edi Supardi',
                            cif_status: 'Perpanjangan',
                            gelar: 'S1',
                            no_identitas: '32377097890',
                            npwp: '012973891263',
                            tanggal_lahir: '12 Februari 1992',
                            dhib: 'T',
                            dhn: 'T #2',
                            no_dhn: '12/4/2020',
                            batas_saksi: '15-05-2015',
                        },
                    ]}
                    className="tw-mb-6"
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl as="select" size="sm">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={5}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName="pagination tw-text-12"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                    />
                    <div className="tw-text-12">Menampilkan 1-3 of 30 data</div>
                </div>
            </div>
        </>
    );
};

export default InfoDaftarHitamIndividualPage;
