import React from 'react';
import { TableList } from '../../../../controls';
import useTutupKas, { TKProvider } from './useTutupKas';
import { addCommas, removeNonNumeric } from "../../../../../module"

const TutupKasPage = function () {
    const { tkState, tkAction } = useTutupKas();
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Kas Teller</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Tutup KAS Khasanah
                    </li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-12">
                    <div className="card" style={{margin:"auto", width:"60%"}}>
                        <div className="card-header">
                            <div className="card-title">
                                TUTUP KAS
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>Cabang Rekening</label>
                                <input type="text"
                                    value={tkState.formData?.cabang_rekening}
                                    readOnly className="form-control col" />
                            </div>

                            <div className="form-group">
                                <label>Saldo Kas Teller</label>
                                <div className="input-group Row">
                                    <div className="input-group-append col-3">
                                        <input type="text"
                                            className="form-control" disabled value={tkState.formData?.kode_valuta} />
                                    </div>
                                    <input type="text" readOnly className="form-control col"
                                        value={addCommas( parseFloat(tkState.formData?.saldo || 0).toFixed(2))}

                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Status</label>
                                <input type="text" className="form-control col"
                                    onChange={(e) => tkAction.changeForm(e.target.value, "nomor_referensi")}
                                    value={tkState.formData?.status}
                                    readOnly
                                />
                            </div>

                        </div>
                        <div className="card-footer">
                            <button className="btn btn-success btn-sm float-right ml-2" onClick={tkAction.confirmTrx}>
                                Proses
                            </button>
                            {/* <button className="btn btn-outline-secondary btn-sm float-right ml-2">
                                Batal
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default function () {
    return <TKProvider>
        <TutupKasPage />
    </TKProvider>
}