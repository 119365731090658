import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../../hooks/keycloak.module';

const infoBerankasContext = createContext({
   ibState:{}, ibAction:{} 
});

export const InfoBerankasProvider = ({ children }) => {

    const { doGet, doPost, wait } = useFetch();
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ 
        // tgl_awal:moment(Date.now()).format("YYYY-MM-DD"),
        // tgl_akhir:moment(Date.now()).format("YYYY-MM-DD"),
        start_date:moment(Date.now()).format("YYYY-MM-DD"),
        end_date:moment(Date.now()).format("YYYY-MM-DD"),
     })
    const [dataBerangkas, setDataBerangkas] = useState([])
    const [denomList, setDenomList] = useState([])
    const [dataTRX, setListDataTRX] = useState([])
    const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false });
    const [isLoading, setIsLoading] = useState(true)
    const [loadingRiwayatTRX, setLoadingRiwayatTRX] = useState(true);
    const [rekSelected, setRekSelected] = useState({});
    const [saldoKas, setSaldoKas] = useState(0)
    const { keycloak } = useKeycloak();

    // useEffect GetData
    useEffect(function () {
        getRiwayatTransaksi()
    }, [pagination])
    
    
    useEffect(function(){
        getDataList()
    },[])

    // close Alert
    function closeAlert() { setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null }) }

    // closeConfirm
    function closeConfirm() { setDataConfirm({ show: false, body: "", header: null, title: "", footerButton: [], closeButton: true, witTextArea: false }) }

    // getData rekening list
    async function getDataList() {
        setIsLoading(true)
        var url = `/brangkas`;
        try {
            const { statusCode, status, remark, statusText, InfoDenominasiBrangkas = [], RekeningKas = {},  data = {} } = await doGet({
                url: url,
                param: {
                    // kode_cabang: "001"
                    // start_date:moment(filter.start_date).format("DD-MM-YYYY"),
                    // end_date:moment(filter.end_date).format("DD-MM-YYYY"),
                    //page:pagination.page, limit:pagination.show 
                },
                service: "trx"

            });
            // // console.log(data)

            if (statusCode === 200) {
                if (status === "00") {
                    // // console.log(data?.InfoDenominasiBrangkas);
                    setDataBerangkas(data?.RekeningKas);
                    setDenomList(data?.InfoDenominasiBrangkas);

                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(600)
        setIsLoading(false)
    }
    async function getRiwayatTransaksi() {
        const user_info = await keycloak.loadUserProfile();
        // // console.log(user_info)
        setLoadingRiwayatTRX(true)
        var url = `/brangkas/histtransaksi`;
        //`/brangkas/histtransaksi?kode_cabang=001&start_date=21-10-2021&end_date=25-10-2021`
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    page: pagination.page,
                    limit: pagination.show,
                    // kode_cabang : "001",
                    // limit:pagination.show, page:pagination.page, 
                    // tgl_awal:moment(filter.tgl_awal).format("DD-MM-YYYY"),
                    // tgl_akhir:moment(filter.tgl_akhir).format("DD-MM-YYYY"),
                    start_date:moment(filter.start_date).format("DD-MM-YYYY"),
                    end_date:moment(filter.end_date).format("DD-MM-YYYY"),
                },
                service: "trx"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    setListDataTRX(data);
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setLoadingRiwayatTRX(false)
    }
    function resetFilter() {
        setFilter({
            keyword: ''
        })
    }
    // next pagination
    function nextPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    return <infoBerankasContext.Provider
        value={{
            ibState: {
                dataBerangkas,
                dataAlert,
                dataConfirm,
                isLoading,
                filter,
                loadingRiwayatTRX,
                rekSelected,
                saldoKas,
                denomList,
                dataTRX
            },
            ibAction: {
                getDataList,
                nextPage,
                prevPage,
                changeShow,
                closeAlert,
                closeConfirm,
                setFilter,
                resetFilter,
                getRiwayatTransaksi,
            }
        }}
    >
        {children}
    </infoBerankasContext.Provider>;
}


export default function useInfoBerankas() { return useContext(infoBerankasContext) };