import React from 'react';

const useSidebar = () => {

    function getParam(url = null, param = {}) {
        if (url !== null) {
            var keysParam = Object.keys(param || {});
            let newUrl = url;
            (keysParam || []).forEach(keyUrl => {
                newUrl = newUrl.replace(`:${keyUrl}`, param[keyUrl]);
            });
            return newUrl;
        }
    }
    return {
        sideState: {},
        sideAction: {
            getParam
        }
    };
}

export default useSidebar;