import React from 'react';
import useHrk, { HrkProvider } from './useHistKoran';
import { DateInput, LoadingIcon, NewLoading, TableList, DateRangeInput } from '../../controls'
import Skeleton from 'react-loading-skeleton';
import { addCommas } from '../../../module';
import { useParams } from 'react-router';

function HrkPage() {
    const {
        hrkAction, hrkState
    } = useHrk();

    const { mode } = useParams();

    const loadingField = [
        {
            name: <Skeleton height={20} count={1} />,
            field: 'kode_report',
        },
        {
            name: <Skeleton height={20} count={1} />,
            field: 'nama_report',
            // maxWidth: '250px',
        },
        {
            name: <Skeleton height={20} count={1} />,
            field: 'nama_report',
            // maxWidth: '250px',
        },
        {
            name: <Skeleton height={20} count={1} />,
            field: 'nama_report',
            // maxWidth: '250px',
        },
        {
            name: <Skeleton height={20} count={1} />,
            field: 'nama_report',
            // maxWidth: '250px',
        },
        {
            name: <Skeleton height={20} count={1} />,
            field: 'nama_report',
            // maxWidth: '250px',
        },
    ]
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">History Rekening Koran</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    {/* <li className="breadcrumb-item active" aria-current="page">
                        Ambil Kas ke Khasanah
                    </li> */}
                </ol>
            </nav>

            <div className="card">
                <div className="card-header bg-white">
                    <h5>Histori Rekening Koran</h5>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group form-group-sm'>
                                <label> Nomor Rekening </label>
                                <input disabled={mode === "otorisasi"} onBlur={(e) => e.target.value && hrkAction.cekNasabah(e.target.value)}
                                    onChange={(e) => {
                                        hrkAction.setFilter(val => ({
                                            ...val,
                                            nomor_rekening: e.target.value,
                                        }))
                                    }}
                                    value={
                                        hrkState.filter.nomor_rekening
                                    }
                                    className='form-control form-control-sm' />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='form-group form-group-sm'>
                                {/* { */}
                                <div style={{
                                    paddingTop: 30
                                }}>
                                    {
                                        hrkState.loadingNamaRek ? <> <NewLoading width='30px' />  <span style={{ color: "#83070d", marginLeft: 5 }}> Checking account no ... </span> </> :
                                            <> <span style={{ color: "#83070d", marginLeft: 5 }}> {hrkState.namaRek} </span> </>
                                    }
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group form-group-sm'>
                                <label> Periode Tanggal </label>
                                <DateRangeInput size='s'
                                    dateFormat='dd-MM-yyyy'
                                    onChange={({
                                        startDate,
                                        endDate
                                    }) => {
                                        hrkAction.setFilter(val => ({
                                            ...val,
                                            tgl_awal: startDate,
                                            tgl_akhir: endDate
                                        }))
                                    }}
                                    disabled={mode === "otorisasi" || hrkState.isApproved}
                                    value={
                                        {
                                            startDate: hrkState.filter.tgl_awal,
                                            endDate: hrkState.filter.tgl_akhir,
                                        }
                                    }
                                />
                                {/* <div className='input-group input-group-sm'>
                                    <DateInput className='form-control' />
                                    <input type='date' className='form-control form-control-sm' />
                                    <div className='input-group-append input-group-sm'>
                                        <span class="input-group-text input-group-text-sm">
                                            s/d
                                        </span>
                                    </div>
                                    <input type='date' className='form-control form-control-sm' />
                                    <div className='input-group-append input-group-sm'>
                                        <button className='btn btn-primary btn-sm'>
                                            Find
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className='col-md-1 col-sm-6'>
                            {/* <div className='form-group'> */}
                            {/* <label>&nbsp;</label> */}
                            {mode !== "otorisasi" && !hrkState.isApproved && <button onClick={() => hrkAction.getData()} className='btn btn-primary btn-sm col' style={{ marginTop: "26px" }}>
                                Find
                            </button>}
                            {/* </div> */}
                        </div>
                        {
                            (hrkState.loadingGetData || hrkState.field.length > 0) && 
                            <div className='col-md'>
                                {mode !== "otorisasi" && <button onClick={() => !hrkState.isApproved ? hrkAction.confirmReqCetak() : hrkAction.confirmCetakData()} className='btn btn-success btn-sm float-right' style={{ marginTop: "26px" }}>
                                    <i className='fa fa-print' ></i> {!hrkState.isApproved ? "Request" : ""} Cetak
                                </button>}
                            </div>
                        }
                    </div>
                    {
                        (hrkState.loadingGetData || hrkState.field.length > 0) && (
                            <TableList
                                withCard={false}
                                onChangeShow={hrkAction.changeShow}
                                onNextPage={hrkAction.nextPage}
                                onPrevPage={hrkAction.prevPage}
                                withActions={false}
                                columns={hrkState.loadingGetData ? loadingField :
                                    (hrkState.field || []).map(({ name, field }) => ({
                                        name,
                                        field,
                                        onStyling: (item) => typeof item[field] === "number" ? { textAlign: "right" } : {},
                                        onRender: (item) => {


                                            if (typeof item[field] === "number") {
                                                return addCommas(parseFloat(
                                                    (item[field] || 0)
                                                ).toFixed(2))
                                            } else return item[field]
                                        }
                                    })
                                    )
                                }
                                data={hrkState.listData}
                                isLoading={hrkState.loadingGetData}
                                pagination={false}
                                filter={false}
                                // formsFilter={[
                                //     {
                                //         type: 'select',
                                //         options: [
                                //             { key: "nama_report", display: "Nama Laporan" },
                                //             { key: "kode_report", display: "Kode Laporan" }
                                //         ],
                                //         value: lapState.jenisLaporan,
                                //         placeholder: 'Jenis Laporan',
                                //         onChange: (val) => {
                                //             lapAction.setKeyLaporan("")
                                //             lapAction.setJenisLaporan(val)
                                //         }
                                //     },
                                //     {
                                //         type: 'text',
                                //         placeholder: 'Cari Keyword ',
                                //         disabled: false,
                                //         readonly: false,
                                //         value: lapState.keyLaporan,
                                //         onChange: (val) => lapAction.setKeyLaporan(val)
                                //     },
                                // ]}
                                // onFilter={() => lapAction.getDataLaporan()}
                                // onResetFilter={() => lapAction.resetFilter()}
                                resetFilter={false}
                                submitButton="FIND"

                            />
                        )
                    }
                    {
                        (hrkState.loadingGetData || hrkState.summaryField.length > 0) && (
                            <div className='row mt-4'>
                                <div className='col-12' style={{
                                    border: "1px solid #bfbdbd",
                                    padding: 15,
                                    borderRadius: 10
                                }}>
                                    {
                                        hrkState.loadingGetData ? ([1, 2, 3]).map((v, i) => (

                                            <div className='row ' key={i}>
                                                <div className='col-2'>
                                                    <Skeleton height={30} count={1} />
                                                </div>
                                                <div className='col-3'>
                                                    <Skeleton height={20} count={1} />
                                                </div>
                                            </div>
                                        )) : (hrkState.summaryField || []).map(({ name, field }, k) => (
                                            <div className='row ' key={k}>
                                                <div className='col-2'>
                                                    {name}
                                                </div>
                                                <div className='col-3'>
                                                    <input type='text' onChange={() => null} value={addCommas(parseFloat(
                                                        (hrkState.summaryData?.[field] || "0")
                                                    ).toFixed(2))} className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        )
                    }

                </div>
                {
                    mode === "otorisasi" && (
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col'>
                                    <button
                                        onClick={() => hrkAction.responseOtor("APPROVED")}
                                        className="btn btn-success mr-2 float-lg-right">
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => hrkAction.responseOtor("REJECTED")}
                                        className="btn btn-danger mr-2 float-right">
                                        Reject
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}
export default function () {

    return <HrkProvider>
        <HrkPage />
    </HrkProvider>
};