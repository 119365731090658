import moment from 'moment';
import React from 'react';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistration } from '../../../../../controls';
import useFormRegisGiroEscrow from './useFormRegisTabunganRencana';

const InformasiLain = () => {

    const { ftbAction, ftbState } = useFormRegisGiroEscrow();
    const { formData = {}, formEditable = false } = ftbState;
    return (
        <>
            <FormRegistration
                itemsForm={[
                    // Informasi Lain
                    {
                        name: "Informasi Lain",
                        rows: [
                            {
                                rows: 1,
                                type: "select",
                                label: "Sumber Dana",
                                required: true,
                                placeholder: "Pilih sumber dana",
                                disabled: !ftbState.formEditable
                            },
                            {
                                rows: 1,
                                type: "select",
                                label: "Tujuan Pembukaan Rekening",
                                required: true,
                                placeholder: "Pilih tujuan pembukaan rekening",
                                disabled: !ftbState.formEditable
                            },
                            {
                                rows: 2,
                                type: "select",
                                label: "Marketing Inisiasi",
                                required: true,
                                placeholder: "Pilih marketing inisiasi",
                                disabled: !ftbState.formEditable
                            },
                            {
                                rows: 2,
                                type: "select",
                                label: "Marketing Maintenance",
                                required: true,
                                placeholder: "Pilih marketing maintenance",
                                disabled: !ftbState.formEditable
                            },
                        ]
                    },
                    // End Informasi Lain
                    // Asuransi
                    // {
                    //     name: "Asuransi",
                    //     rows: [
                    //         {
                    //             rows: 1,
                    //             label: 'Rencana Asuransi Utama',
                    //             type: 'select',
                    //             placeholder: 'Pilih rencana asuransi',
                    //             required: false,
                    //             disabled: !ftbState.formEditable
                    //         },
                    //         {
                    //             rows: 1,
                    //             label: 'Tanggal Jatuh Tempo',
                    //             type: 'date',
                    //             placeholder: 'Pilih rencana asuransi',
                    //             required: false,
                    //             disabled: !ftbState.formEditable
                    //         },
                    //         {
                    //             rows: 2,
                    //             label: 'Rencana Asuransi tambahan',
                    //             type: 'select',
                    //             placeholder: 'Pilih rencana asuransi',
                    //             required: false,
                    //             disabled: !ftbState.formEditable
                    //         },
                            
                    // ]},
                    // End Asuransi
                ]}
            />
        </>
    );
}

export default InformasiLain;