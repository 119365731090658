import React, { memo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { AppAction, AppInterfaces } from '../../../app.data';

import logo from "../../../img/logo.png";
import NavUser from "./navUser";

const Navbar_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
    const history = useHistory();

    const setBackPage = (data) => {
        history.push(data.linkBack);
        appAction.current.disp({type: "setPelanggan", pelanggan: {}});
        appAction.current.disp({type: "setHeader", header: {title: data.lastTitle}});
    }
    return (
        <nav className="p-0 navbar navbar-expand-lg navbar-light bg-light card-logo">
            <AppAction ref={appAction} />
            <div className="p-0 collapse navbar-collapse" id="navbarTogglerDemo01">
                <ul className="p-0 nav navbar-nav  mt-lg-0">
                    <li className="nav-item">
                        <div className="card-logo text-right">
                            <img src={logo} height="100%" alt="logo" className="click-pointer" />
                        </div>
                    </li>
                </ul>
                {store.header.title && <div className="nav-item text-right pt-2">
                        <div className="nav-link card-logo py-auto">
                            <h5>
                                {store.header.linkBack && <><i className="fas fa-arrow-left pointer" onClick={()=>setBackPage(store.header)}></i>&nbsp;<img src={store.header.icon} alt="icon" height={30} />&nbsp;</>}
                                {store.header.title}
                            </h5>
                        </div>   
                    </div>
                }                    
                <ul className="nav navbar-nav ml-auto mr-4"> 
                DFDFD  
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                        <h5><i className="far fa-bell fa-lg mt-1" aria-hidden="true"> </i></h5>
                        <div style={{position: 'absolute', bottom: 20, right: 0}}>
                            <span className="badge bg-success">             
                                <span className="badge bg-success">5</span> 
                            </span> 
                        </div>
                        </a>   
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                        <h5 className="text-black"><i className="fas fa-cog fa-lg ml-2" aria-hidden="true"></i></h5>                             
                        </a>
                    </li> */}
                    <li className="nav-item dropdown bord">
                        <a className="nav-link" data-toggle="dropdown" href="!#" role="button" aria-haspopup="true" aria-expanded="false">
                        <span className="f-14">[Nama Petugas Loket]</span>&nbsp;<NavUser/>                               
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
})

const Navbar = AppInterfaces.appLoad(Navbar_base)

export default Navbar;