import { addCommas, removeNonNumeric } from "./index";
import DatePicker from 'react-datepicker'
import moment from "moment";
import { DateInput, SelectSearch } from "../components/controls";

export const createForm = (form) => {

    // const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");


    if (form.type === "currency")
        return (
            <div className="col-md col-lg col-sm-12">
                <div className="form-group form-group-sm">
                    <label
                        className={form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? "text-danger" : ""}
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {form.label}
                        {form.required && (
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                        )}
                    </label>
                    <input
                        className={`form-control form-control-sm ${form.className || ""} ${form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? 'is-invalid' : ''
                            }`}
                        value={addCommas(form.value)}
                        onChange={(e) => parseInt(e.target.value) === 0 ? form.onChange?.("") : form.onChange?.(removeNonNumeric(e.target.value))}
                        disabled={form.disabled}
                        readOnly={form.readOnly}
                        placeholder={form.placeholder}
                        required={form.required}
                        maxLength={form.maxLength}
                        max={form.max}
                        onBlur={
                            (e) => {
                                if (form.onFocusOut !== null && form.onFocusOut !== undefined) {
                                    // e.target.value !== null && e.target.value !== "" && e.target.value !== undefined &&
                                     form.onFocusOut?.(e.target.value)
                                }
                            }
                        }
                        onKeyUp={(e) => {
                            if (form.onEnter !== null && form.onEnter !== undefined) {
                                if (e.keyCode === 13) {
                                    form.onEnter(removeNonNumeric(e.target.value));
                                }
                            }
                        }}
                    />
                    {form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value && (
                        <span style={{ color: 'red' }}>Field is required</span>
                    )}
                </div>
            </div>
        )
    if (form.type === 'text')
        return (
            <div className="col-md col-lg col-sm-12">
                <div className="form-group form-group-sm">
                    <label
                        className={form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? "text-danger" : ""}
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {form.label}
                        {form.required && (
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                        )}
                    </label>
                    <input
                        className={`form-control form-control-sm ${form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? 'is-invalid' : ''
                            }`}
                        value={form.value}
                        maxLength={form.maxLength}
                        max={form.max}
                        onChange={(e) => form.onChange?.(e.target.value.replace(/['"]/g, ""))}
                        disabled={form.disabled}
                        readOnly={form.readOnly}
                        placeholder={form.placeholder}
                        required={form.required}
                        onBlur={
                            (e) => {
                                if (form.onFocusOut !== null && form.onFocusOut !== undefined) {
                                    e.target.value !== "" && e.target.value !== null && e.target.value !== undefined && form.onFocusOut?.(e.target.value)
                                }
                            }
                        }
                        onKeyUp={(e) => {
                            if (form.onEnter !== null && form.onEnter !== undefined) {
                                if (e.keyCode === 13) {
                                    form.onEnter(e.target.value);
                                }
                            }
                        }}
                    />
                    {form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value && (
                        <span style={{ color: 'red' }}>Field is required</span>
                    )}
                </div>
            </div>
        );
    if (form.type === 'alphabet')
        return (
            <div className="col-md col-lg col-sm-12">
                <div className="form-group form-group-sm">
                    <label
                        className={form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? "text-danger" : ""}
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {form.label}
                        {form.required && (
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                        )}
                    </label>
                    <input
                        className={`form-control form-control-sm ${form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? 'is-invalid' : ''
                            }`}
                        value={form.value}
                        maxLength={form.maxLength}
                        max={form.max}
                        onChange={(e) => form.onChange?.(e.target.value.replace(/[0-9 \-]/g, ''))}
                        disabled={form.disabled}
                        readOnly={form.readOnly}
                        placeholder={form.placeholder}
                        required={form.required}
                        onBlur={
                            (e) => {
                                if (form.onFocusOut !== null && form.onFocusOut !== undefined) {
                                    e.target.value !== "" && e.target.value !== null && e.target.value !== undefined && form.onFocusOut?.(e.target.value)
                                }
                            }
                        }
                        onKeyUp={(e) => {
                            if (form.onEnter !== null && form.onEnter !== undefined) {
                                if (e.keyCode === 13) {
                                    form.onEnter(e.target.value);
                                }
                            }
                        }}
                    />
                    {form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value && (
                        <span style={{ color: 'red' }}>Field is required</span>
                    )}
                </div>
            </div>
        );
    if (form.type === 'number')
        return (
            <div className="col-md col-lg col-sm-12">
                <div className="form-group form-group-sm">
                    <label
                        className={form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? "text-danger" : ""}
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {form.label}
                        {form.required && (
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                        )}
                    </label>
                    <input

                        className={`form-control form-control-sm ${form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? 'is-invalid' : ''
                            }`}
                        value={form.value}
                        onChange={(e) => form.onChange?.(e.target.value.replace(/[^0-9 \-]/g, ''))}
                        disabled={form.disabled}
                        readOnly={form.readOnly}
                        placeholder={form.placeholder}
                        required={form.required}
                        
                        maxLength={form.maxLength}
                        max={form.max}
                        onKeyUp={(e) => {
                            if (form.onEnter !== null && form.onEnter !== undefined) {
                                if (e.keyCode === 13) {
                                    form.onEnter(e.target.value);
                                }
                            }
                        }}
                        onBlur={
                            (e) => {
                                if (form.onFocusOut !== null && form.onFocusOut !== undefined) {
                                    e.target.value !== null && e.target.value !== "" && e.target.value !== undefined && form.onFocusOut?.(e.target.value)
                                }
                            }
                        }
                    />
                    {form.required && ((form.value === '' || form.value === null || form.value === undefined) || form.value === 0) && !form.value && (
                        <span style={{ color: 'red' }}>Field is required</span>
                    )}
                </div>
            </div>
        );
    if (form.type === 'float')
        return (
            <div className="col-md col-lg col-sm-12">
                <div className="form-group form-group-sm">
                    <label
                        className={form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? "text-danger" : ""}
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {form.label}
                        {form.required && (
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                        )}
                    </label>
                    <input
                        type="number"
                        step="0.1"
                        className={`form-control form-control-sm ${form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? 'is-invalid' : ''
                            }`}
                        value={form.value !== "" && form.value !== null && form.value !== undefined ? addCommas(form.value) : ""}
                        onBlurCapture={e => e.target.value = parseFloat(e.target.value).toFixed(2)} 
                        onChange={(e) => form.onChange?.(e.target.value)}
                        disabled={form.disabled}
                        readOnly={form.readOnly}
                        placeholder={form.placeholder}
                        maxLength={form.maxLength}
                        max={form.max}
                        required={form.required}
                        onKeyUp={(e) => {
                            if (form.onEnter !== null && form.onEnter !== undefined) {
                                if (e.keyCode === 13) {
                                    form.onEnter(e.target.value);
                                }
                            }
                        }}
                        onBlur={
                            (e) => {
                                if (form.onFocusOut !== null && form.onFocusOut !== undefined) {
                                    e.target.value !== null && e.target.value !== "" && e.target.value !== undefined && form.onFocusOut?.(e.target.value)
                                }
                            }
                        }
                    />
                    {form.required && ((form.value === '' || form.value === null || form.value === undefined) || form.value === 0) && !form.value && (
                        <span style={{ color: 'red' }}>Field is required</span>
                    )}
                </div>
            </div>
        );
    if (form.type === 'select')
        return (
            <div className="col-md col-lg col-sm-12">
                <div className="form-group form-group-sm">
                    <label
                        className={form.required && ((form.value === '' || form.value === null || form.value === undefined) || form.value === undefined) && !form.value ? "text-danger" : ""}
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {form.label}
                        {form.required && (
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                        )}
                    </label>
                    <SelectSearch

                        className={`form-control form-control-sm ${form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? 'is-invalid' : ''
                            }`}
                            size="sm"
                        value={form.value}
                        onChange={(e) => form.onChange?.(e.value)}
                        disabled={form.disabled}
                        readOnly={form.readOnly}
                        placeholder={form.placeholder}
                        required={form.required}
                        onBlur={
                            (e) => {
                                if (form.onFocusOut !== null && form.onFocusOut !== undefined) {
                                    e.target.value !== null && e.target.value !== "" && e.target.value !== undefined && form.onFocusOut?.(e.target.value)
                                }
                            }
                        }
                        options={form.options}
                        keyValue={form.key}
                        display={form.display}
                        // placeholder={form.placeholder}
                    >
                        {/* {form.placeholder && form.placeholder !== '' && (
                            <option value="">{form.placeholder}</option>
                        )}
                        {form.options?.map((opt, i) => (
                            <option value={opt[form.key ? form.key : 'key']}>
                                {opt[form.display ? form.display : 'display']}
                            </option>
                        ))} */}
                    </SelectSearch>
                    {form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value && (
                        <span style={{ color: 'red' }}>Field is required</span>
                    )}
                </div>
            </div>
        );
    if (form.type === 'textarea')
        return (
            <div className="col-md col-lg col-sm-12">
                <div className="form-group form-group-sm">
                    <label
                        className={form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? "text-danger" : ""}
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {form.label}
                        {form.required && (
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                        )}
                    </label>
                    <textarea
                        onBlur={
                            (e) => {
                                if (form.onFocusOut !== null && form.onFocusOut !== undefined) {
                                    e.target.value !== null && e.target.value !== "" && e.target.value !== undefined && form.onFocusOut?.(e.target.value)
                                }
                            }
                        }
                        className={`form-control form-control-sm ${form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? 'is-invalid' : ''
                            }`}
                        value={form.value}
                        onChange={(e) => form.onChange?.(e.target.value.replace(/['"]/g, ""))}
                        disabled={form.disabled}
                        readOnly={form.readonly}
                        placeholder={form.placeholder}
                        rows={form.rowsText ? form.rowsText : 3}
                        required={form.required}
                        maxLength={form.maxLength || 200}
                    />
                    {form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value && (
                        <span style={{ color: 'red' }}>Field is required</span>
                    )}
                </div>
            </div>
        );
    if (form.type === 'checkbox')
        return (
            <div className={"col-md col-lg col-sm-12 pl-4 ml-2 " + form.className}>
                <div
                    className="form-group form-group-sm"
                    style={{
                        paddingRight: 10,
                    }}
                >
                    {/* <label style={{
                        fontSize: "12px"
                    }}>{form.label}</label> */}
                    <input
                        type="checkbox"
                        checked={form.checked}
                        className="form-check-input"
                        value={form.value}
                        onChange={(e) => form.onChange?.(e.target.checked)}
                        disabled={form.disabled}
                        readOnly={form.readonly}
                        placeholder={form.placeholder}
                        rows={form.rows ? form.rows : 3}
                        required={form.required}
                    />
                    <label
                        className={"form-check-label" + (form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? "text-danger" : "")}>
                        {form.label}
                        {form.required && (
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                        )}
                    </label>
                </div>
            </div>
        );

    if (form.type === 'custom') return form.component;

    if (form.type === 'date')
        return (
            <div className="col-md col-lg col-sm-12">
                <div className="form-group form-group-sm">
                    <label
                        className={form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? "text-danger" : ""}
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {form.label}
                        {form.required && (
                            <span
                                style={{
                                    padding: '2px 5px',
                                    background: '#E8E8E8',
                                    fontSize: '8pt',
                                    fontWeight: 'normal',
                                    borderRadius: 3,
                                }}
                            >
                                Wajib
                            </span>
                        )}
                    </label>
                    {/* <DatePicker className="form-control form-control-sm" /> */}
                    <DateInput

                        className={`form-control form-control-sm ${form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value ? 'is-invalid' : ''
                            }`}
                        value={form.value}
                        
                        // selected={
                            
                        //     (form.value !== undefined && form.value !== null )&& form.value ? new Date(form.value):""
                        // }
                        // value={form.value}
                        type="date"
                        onChange={(e) =>{
                            form.onChange?.(moment(e).format("YYYY-MM-DD"))
                        }}
                        disabled={form.disabled}
                        readOnly={form.readOnly}
                        placeholder={form.placeholder}
                        required={form.required}
                        
                        onBlur={
                            (e)=> {
                                if(form.onFocusOut !== null && form.onFocusOut !== undefined)
                                {
                                    e.target.value !== null && e.target.value !== "" && e.target.value !== undefined && form.onFocusOut?.(e.target.value)
                                }
                            }
                        }
                    />
                    {form.required && (form.value === '' || form.value === null || form.value === undefined) && !form.value && (
                        <span style={{ color: 'red' }}>Field is required</span>
                    )}
                </div>
            </div>
        );
};
