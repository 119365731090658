import React, { memo, useRef } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { Tab, Nav, ButtonGroup, Button } from 'react-bootstrap';
import { baseUrl } from '../../../../../app.config';

class SVSDeposito extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.location.state,
            svsData: this.props.location.state.data,
            detail: this.props.location.state.detail,
            svsParams: [
                {
                    data: {
                        nomor_cif: this.props.location.state.db_id,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: this.props.location.state.db_id,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: this.props.location.state.db_id,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: this.props.location.state.db_id,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
            ],
        };
    }

    render() {
        // console.log(this.state.detail, 'svs data');
        return (
            <div className="">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Nasabah CIF</li>
                        <li className="breadcrumb-item">
                            Informasi Data (SVS) Rekening Nasabah Korporat
                        </li>
                    </ol>
                </nav>

                <div className="card">
                    <div className="card-body">
                        <div className="box">
                            <h3>{this.state.data?.namaLengkap}</h3>
                            <div className="row">
                                <div className="col-4">
                                    <div>
                                        <span className="text-grey">No. CIF</span>
                                        <span className="ml-3 mr-1">:</span>
                                        <span className="text-grey2">
                                            {this.state.detail?.nomor_cif}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <span className="text-grey">NPWP</span>
                                        <span className="ml-3 mr-1">:</span>
                                        <span className="text-grey2">
                                            {this.state.detail?.nomor_identitas}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div>
                                        <span className="text-grey">Alamat</span>
                                        <span className="ml-3 mr-1">:</span>
                                        <span className="text-grey2">
                                            {this.state.detail?.alamat}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body p0">
                        <Tab.Container defaultActiveKey="accountImages">
                            <div className="col-12 p-4">
                                <Nav fill justify variant="pills" className="svs-nav">
                                    <Nav.Item>
                                        <Nav.Link eventKey="accountImages">Account Images</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <Tab.Content>
                                <Tab.Pane eventKey="accountImages">
                                    <table className="table"></table>
                                    <div className="p-3">
                                        <div className="row">
                                            {this.state.svsData?.length > 0
                                                ? this.state.svsData.map((data, index) => {
                                                      // console.log(data, 'map');
                                                      return (
                                                          <div
                                                              className="col-4"
                                                              key={`listsaccountimagessign${index}`}
                                                          >
                                                              <p>
                                                                  Image {index + 1}
                                                                  {/* {data} */}
                                                              </p>
                                                              <div className="svs-box">
                                                                  {/* <button className="btn btn-sm btn-outline-secondary px-4 float-right text-danger">
                                                                      Delete
                                                                  </button> */}
                                                                  <div className="mt-5 text-center">
                                                                      <img
                                                                          src={data.signed_url}
                                                                          height={110}
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <p className="border p-2 mt-2 fs-12">
                                                                  {data?.remark}
                                                              </p>
                                                          </div>
                                                      );
                                                  })
                                                : null}
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="newImages">
                                    <div className="p-4">
                                        <div className="row">
                                            {this.state.svsParams && this.state.svsParams.length > 0
                                                ? this.state.svsParams.map((data, index) => {
                                                      return (
                                                          <div
                                                              className="col-6"
                                                              key={`newimagesdata${index}`}
                                                          >
                                                              <p className="mt-2">
                                                                  Image {index + 1}
                                                              </p>
                                                              <div className="row px-3">
                                                                  <div className="col-12 svs-box-new-images">
                                                                      <div className="row">
                                                                          <div className="col-6">
                                                                              {data.options
                                                                                  .isChoosedFile ? (
                                                                                  <div className="text-center">
                                                                                      <img
                                                                                          className="img-fluid"
                                                                                          src={
                                                                                              data
                                                                                                  .options
                                                                                                  ?.selectedFileUrl
                                                                                          }
                                                                                      />
                                                                                  </div>
                                                                              ) : (
                                                                                  <div className="text-center">
                                                                                      <img
                                                                                          className="img-fluid"
                                                                                          src={data}
                                                                                      />
                                                                                  </div>
                                                                              )}
                                                                          </div>
                                                                          <div className="col-6 svs-border-left">
                                                                              <div className="form-group mt-2">
                                                                                  <label htmlFor="">
                                                                                      Image Tag
                                                                                  </label>
                                                                                  <select
                                                                                      name="image_tag"
                                                                                      className="form-control"
                                                                                      onChange={(
                                                                                          e
                                                                                      ) =>
                                                                                          this.handleTextChanged(
                                                                                              e,
                                                                                              index
                                                                                          )
                                                                                      }
                                                                                      value={data}
                                                                                  >
                                                                                      <option value="">
                                                                                          Pilih
                                                                                          Image Tag
                                                                                      </option>
                                                                                      <option value="sign">
                                                                                          Tanda
                                                                                          Tangan
                                                                                      </option>
                                                                                  </select>
                                                                              </div>
                                                                              <div className="form-group">
                                                                                  <label htmlFor="">
                                                                                      Remark
                                                                                  </label>
                                                                                  <input
                                                                                      type="text"
                                                                                      className="form-control"
                                                                                      placeholder="Masukkan remark"
                                                                                      value={
                                                                                          data?.data
                                                                                              ?.remark
                                                                                      }
                                                                                      name="remark"
                                                                                      onChange={(
                                                                                          e
                                                                                      ) =>
                                                                                          this.handleTextChanged(
                                                                                              e,
                                                                                              index
                                                                                          )
                                                                                      }
                                                                                  />
                                                                              </div>
                                                                              <div className="form-group">
                                                                                  <div className="row">
                                                                                      <div className="col-7">
                                                                                          <input
                                                                                              type="file"
                                                                                              ref={`fileUploader${index}`}
                                                                                              style={{
                                                                                                  display:
                                                                                                      'none',
                                                                                              }}
                                                                                              onChange={(
                                                                                                  e
                                                                                              ) =>
                                                                                                  this.handleFileChange(
                                                                                                      e,
                                                                                                      index
                                                                                                  )
                                                                                              }
                                                                                          />
                                                                                          <button
                                                                                              onClick={() => {
                                                                                                  this.refs[
                                                                                                      'fileUploader' +
                                                                                                          index
                                                                                                  ].click();
                                                                                              }}
                                                                                              className="btn btn-orange-primary btn-block"
                                                                                          >
                                                                                              Pilih
                                                                                              File
                                                                                          </button>
                                                                                      </div>
                                                                                      <div className="col-5">
                                                                                          <button
                                                                                              className="btn btn-outline-secondary btn-block"
                                                                                              onClick={() =>
                                                                                                  this.handleFileRemove(
                                                                                                      index
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              Cancel
                                                                                          </button>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      );
                                                  })
                                                : null}
                                        </div>
                                        <div className="text-right mt-3">
                                            <button
                                                onClick={() => this.postSVS()}
                                                className="btn btn-primary"
                                            >
                                                Simpan
                                            </button>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="deletedImages">
                                    <div>Deleted images</div>
                                </Tab.Pane> */}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        );
    }
}

export default SVSDeposito;
