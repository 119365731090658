
import React, { createContext, useContext, useState } from 'react';

const modalPrintContext = createContext({
    mpState: {},
    mpAction: {}
})

export const PrintModalProvider = function ({
    children
}) {

    const [ modalDetil, setModal ] = useState({
        modal:false, callBack: ()=>null
    })

    function openModal(id ="modalPrint",  callBack= ()=> null){
        setModal( p=> ({
            ... p,
            [id] : true,
            callBack
        }));
    }

    function  closeModal(id = null) {

        if( id === null) setModal({ modal:false });
        else setModal(p=>({ ...p, [id]:false, callBack:()=>null}))
    }

    return <modalPrintContext.Provider value={
        {
            mpState: {
                modalDetil
            },
            mpAction: {
                closeModal,
                openModal
            }
        }
    }>
        {children}
    </modalPrintContext.Provider>
};

export const usePrintModal = () => useContext(modalPrintContext);