import { useEffect, useMemo, useState } from 'react';
import { FormControl, Form, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { TableGrid } from '../../controls';
import { baseUrl } from '../../../app.config';

const QueryRekeningPage = () => {
    const [nomorRekening, setNomorRekening] = useState('');
    const [formData, setFormData] = useState({});
    const [trxData, setTrxData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [messageError, setMessageError] = useState('');
    const [showModalError, setShowModalError] = useState(false);

    const [messageWarning, setMessageWarning] = useState('');
    const [showModalWarning, setShowModalWarning] = useState(false);

    const handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        if (name == 'nomor_rekening') {
            setNomorRekening(value);
        } else if (name == 'tanggal_awal') {
            setStartDate(value);
        } else if (name == 'tanggal_akhir') {
            setEndDate(value);
        }
    };

    const doSearch = () => {
        setIsLoading(true);
        handleTransactionList();
    };

    const handleTransactionList = async () => {
        if (nomorRekening != '') {
            let s = '';
            if (startDate != '' && endDate != '') {
                s = `&startDate=${startDate}&endDate=${endDate}&orderType=asc`;
            }

            const getTrxRekening = await fetch(
                baseUrl +
                    `/rekening/tabungan/query/transaction/${nomorRekening}?page=${currentPage}&limit=${limit}${s}`
            );
            const respTrxRekening = await getTrxRekening.json();

            if (respTrxRekening.message_id == '00') {
                setTrxData(respTrxRekening.list);
            } else {
                setMessageError(respTrxRekening.status);
                setShowModalError(true);
            }
        } else {
            setMessageError('Pastikan Nomor Rekening sudah diisi.');
            setShowModalError(true);
        }
        setIsLoading(false);
    };

    const maxPage = useMemo(() => {
        if (trxData?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, trxData, limit]);

    const handleResetForm = () => {
        setNomorRekening('');
        setFormData({
            namaLengkap: '',
            kantorCabang: '',
            alamat: '',
            valutaRekening: '',
            nameProduct: '',
        });
        setTrxData([]);
        setStartDate('');
        setEndDate('');
    };

    useEffect(async () => {
        if (nomorRekening.length > 10) {
            const getRekening = await fetch(
                baseUrl + `/rekening/tabungan/query/find/${nomorRekening}`
            );
            const respRekening = await getRekening.json();

            if (respRekening.message_id == '00') {
                setFormData(respRekening.list);
            } else {
                setMessageError(respRekening.status);
                setShowModalError(true);
            }
        }
    }, [nomorRekening]);

    useEffect(() => {
        if (nomorRekening != '') {
            doSearch();
        }
    }, [currentPage]);

    return (
        <>
            <Breadcrumb className="tw-mb-5 tw-text-12">
                <Breadcrumb.Section className="text-orange" link>
                    Query
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Query Rekening</Breadcrumb.Section>
            </Breadcrumb>
            <div style={{ minHeight: '80vh' }} className="tw-bg-white tw-flex tw-flex-col">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Riwayat Transaksi Buku Besar
                </div>
                <div
                    style={{ borderTop: '1px solid #f2f2f2', borderBottom: '1px solid #f2f2f2' }}
                    className="tw-p-6"
                >
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-grid tw-grid-cols-4 tw-gap-x-4 tw-gap-y-6 tw-items-start">
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">
                                Nomor Rekening
                            </Form.Label>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Nomor Rekening"
                                name="nomor_rekening"
                                value={nomorRekening}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">Nama</Form.Label>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                disabled
                                placeholder="Otomatis terisi"
                                value={formData?.namaLengkap}
                            />
                        </div>
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">
                                Kantor Cabang
                            </Form.Label>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                disabled
                                placeholder="Otomatis terisi"
                                value={formData?.kantorCabang}
                            />
                        </div>
                        <div
                            style={{ gridTemplateRows: 'auto 1fr' }}
                            className="tw-row-span-2 tw-grid tw-h-full"
                        >
                            <Form.Label className="tw-text-12 tw-font-normal">Alamat</Form.Label>
                            <FormControl
                                className="tw-mr-4 tw-h-full"
                                size="sm"
                                disabled
                                placeholder="Otomatis terisi"
                                as="textarea"
                                value={formData?.alamat}
                            />
                        </div>
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">
                                Kode Valuta
                            </Form.Label>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                disabled
                                placeholder="Otomatis terisi"
                                value={formData?.valutaRekening}
                            />
                        </div>
                        <div>
                            <Form.Label className="tw-text-12 tw-font-normal">Produk</Form.Label>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                disabled
                                placeholder="Otomatis terisi"
                                value={formData?.nameProduct}
                            />
                        </div>
                    </div>
                </div>
                <div className="tw-p-6 tw-grid tw-gap-4 tw-grid-cols-4">
                    <FormControl
                        type="date"
                        size="sm"
                        onChange={handleInputChange}
                        name="tanggal_awal"
                        value={startDate}
                    />
                    <FormControl
                        type="date"
                        size="sm"
                        onChange={handleInputChange}
                        name="tanggal_akhir"
                        value={endDate}
                    />
                    <div className="tw-grid tw-auto-cols-max tw-grid-flow-col tw-gap-3 tw-items-center">
                        <Button
                            className="tw-text-14 tw-py-2"
                            size="mini"
                            basic
                            color="blue"
                            onClick={handleResetForm}
                        >
                            Reset
                        </Button>
                        <Button
                            className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                            size="mini"
                            color="blue"
                            onClick={() => {
                                if (currentPage == 0) {
                                    doSearch();
                                } else {
                                    setCurrentPage(0);
                                }
                            }}
                        >
                            Cari
                        </Button>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Tanggal Awal',
                            field: 'tanggalAwal',
                            width: '100px',
                        },
                        {
                            name: 'Tanggal Akhir',
                            field: 'tanggalAkhir',
                            width: '100px',
                        },
                        {
                            name: 'No Referensi',
                            field: 'noReferensi',
                            width: '90px',
                        },
                        {
                            name: 'ID Petugas',
                            field: 'idPetugas',
                            width: '110px',
                        },
                        {
                            name: 'Kode Transaksi',
                            field: 'kodeTransaksi',
                            width: '110px',
                        },
                        {
                            name: 'Source',
                            field: 'source',
                            width: '60px',
                        },
                        {
                            name: 'Mutasi',
                            field: 'mutasi',
                            width: '80px',
                        },
                        {
                            name: 'Saldo Ledger (Rp)',
                            field: 'nominal',
                            textAlign: 'right',
                            width: '100px',
                            // width: '0.6fr',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                        },
                    ]}
                    data={trxData}
                    onRenderField={(value, field) => {
                        if (field === 'nominal') {
                            return (
                                <div className="tw-text-right" key={`${value}-${field}`}>
                                    {new Intl.NumberFormat('id-ID').format(value)}
                                </div>
                            );
                        }
                    }}
                    className="tw-mb-6"
                    config={{
                        height: '40vh',
                        overflowY: 'auto',
                        stickyHead: true,
                    }}
                    isLoading={isLoading}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-grid tw-grid-cols-3 tw-items-center tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            as="select"
                            size="sm"
                            className="tw-border-0 tw-w-max"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        containerClassName="pagination tw-text-12 tw-m-0 tw-justify-self-center"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-cursor-pointer focus:tw-outline-none tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                        forcePage={currentPage}
                        initialPage={0}
                    />
                    <div className="tw-text-12 tw-text-right">
                        Menampilkan {trxData?.length} data
                    </div>
                </div>
            </div>
            <Modal show={showModalError} onHide={() => setShowModalError(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                </Modal.Header>
                <Modal.Body>{messageError}</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => setShowModalError(false)}>
                        Tutup
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalWarning} onHide={() => setShowModalWarning(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Informasi</Modal.Title>
                </Modal.Header>
                <Modal.Body>{messageWarning}</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => setShowModalWarning(false)}>
                        Tutup
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default QueryRekeningPage;
