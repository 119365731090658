import React, { memo } from 'react';
import { Nav } from '../navbar';
import { Sidebar } from '../sidebar';

const LayoutExt = memo(({ children }) => {

    return (
        <>
            <div className="hold-transition sidebar-mini layout-fixed">
                <div class="wrapper">
                    <Nav />
                    <Sidebar />

                    <div class="content-wrapper">
                        <div class="content-header" style={{overflowY: "scroll",height: "90vh"}}>
                            <div class="container-fluid" >
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {children} */}
        </>
    )

})

export default LayoutExt;