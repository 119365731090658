import React, { memo, useRef } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import IconPerorangan from '../../../img/perorangan.png';
import IconBadanUsaha from '../../../img/badanusaha.png';

class RegistrasiBadanUsaha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item text-primary-bds">Nasabah</li>
                        <li className="breadcrumb-item">
                            <a href="#">Registrasi Nasabah</a>
                        </li>
                    </ol>
                </nav>

                <div className="card">
                    <div className="card-body" style={{ paddingBottom: 64 }}>
                        <h1 className="f-18 text-gray-2 text-center mb-5">Pilih Jenis Nasabah</h1>
                        <div className="row">
                            <div className="col-6">
                                <Link to={'/nasabah-cif/form-perorangan/registration'}>
                                    <div className="box-portal text-center mx-5 p-4">
                                        {/* <img
                                            height={60}
                                            width={60}
                                            src={IconPerorangan}
                                            alt=""
                                            className="mt-2"
                                        /> */}
                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                            <g opacity="0.66" filter="url(#filter0_f)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 61.4252C16.3125 61.4252 12.375 61.4252 12.375 57.4877C12.375 53.5502 16.3125 41.7377 36 41.7377C55.6875 41.7377 59.625 53.5502 59.625 57.4877C59.625 61.4252 55.6875 61.4252 55.6875 61.4252H16.3125Z" fill="#004bbc"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M36 37.8002C39.1329 37.8002 42.1374 36.5557 44.3527 34.3404C46.568 32.1251 47.8125 29.1206 47.8125 25.9877C47.8125 22.8548 46.568 19.8503 44.3527 17.635C42.1374 15.4197 39.1329 14.1752 36 14.1752C32.8671 14.1752 29.8626 15.4197 27.6473 17.635C25.432 19.8503 24.1875 22.8548 24.1875 25.9877C24.1875 29.1206 25.432 32.1251 27.6473 34.3404C29.8626 36.5557 32.8671 37.8002 36 37.8002Z" fill="#004bbc"/>
                                            </g>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 57.8248C16.3125 57.8248 12.375 57.8248 12.375 53.8873C12.375 49.9498 16.3125 38.1373 36 38.1373C55.6875 38.1373 59.625 49.9498 59.625 53.8873C59.625 57.8248 55.6875 57.8248 55.6875 57.8248H16.3125Z" fill="#004bbc"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M36 34.1998C39.1329 34.1998 42.1374 32.9553 44.3527 30.74C46.568 28.5247 47.8125 25.5202 47.8125 22.3873C47.8125 19.2544 46.568 16.2499 44.3527 14.0346C42.1374 11.8193 39.1329 10.5748 36 10.5748C32.8671 10.5748 29.8626 11.8193 27.6473 14.0346C25.432 16.2499 24.1875 19.2544 24.1875 22.3873C24.1875 25.5202 25.432 28.5247 27.6473 30.74C29.8626 32.9553 32.8671 34.1998 36 34.1998Z" fill="#004bbc"/>
                                            </g>
                                            <defs>
                                            <filter id="filter0_f" x="-9.9" y="-8.1" width="91.8" height="91.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                            <feGaussianBlur stdDeviation="7.2" result="effect1_foregroundBlur"/>
                                            </filter>
                                            <clipPath id="clip0">
                                            <rect width="72" height="72" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>

                                        <p className="f-14 font-weight-bold text-center mb-1 mt-3">
                                            Perorangan
                                        </p>
                                        <p>
                                            Ini diisi dengan deskripsi singkat mengenari profil
                                            nasabah perorangan
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to={'/nasabah-cif/form-badan-usaha/registration'}>
                                    <div className="box-portal text-center mx-5 p-4">
                                        {/* <img
                                            height={60}
                                            width={60}
                                            src={IconBadanUsaha}
                                            alt=""
                                            className="mt-2"
                                        /> */}
                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                            <g opacity="0.66" clip-path="url(#clip1)" filter="url(#filter0_f)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.8996 52.2849C9.8996 53.6052 10.4152 54.8714 11.3329 55.805C12.2507 56.7386 13.4954 57.263 14.7933 57.263H57.2058C58.5037 57.263 59.7485 56.7386 60.6663 55.805C61.584 54.8714 62.0996 53.6052 62.0996 52.2849V33.534L36.4205 40.4934C36.1447 40.5683 35.8545 40.5683 35.5787 40.4934L9.8996 33.534V52.2849Z" fill="#004bbc"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.8996 25.7346C9.8996 24.4143 10.4152 23.1481 11.3329 22.2145C12.2507 21.2809 13.4954 20.7564 14.7933 20.7564H57.2058C58.5037 20.7564 59.7485 21.2809 60.6663 22.2145C61.584 23.1481 62.0996 24.4143 62.0996 25.7346V30.3277L37.2589 37.0648C36.4338 37.2883 35.5654 37.2883 34.7403 37.0648L9.8996 30.3277V25.7346ZM26.2121 19.0971C26.2121 17.7768 26.7277 16.5106 27.6454 15.577C28.5632 14.6434 29.8079 14.1189 31.1058 14.1189H40.8933C42.1912 14.1189 43.436 14.6434 44.3538 15.577C45.2715 16.5106 45.7871 17.7768 45.7871 19.0971V20.7564H42.5246V19.0971C42.5246 18.657 42.3527 18.2349 42.0468 17.9237C41.7409 17.6125 41.326 17.4377 40.8933 17.4377H31.1058C30.6732 17.4377 30.2583 17.6125 29.9524 17.9237C29.6465 18.2349 29.4746 18.657 29.4746 19.0971V20.7564H26.2121V19.0971Z" fill="#004bbc"/>
                                            </g>
                                            <g clip-path="url(#clip2)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.8996 49.5851C9.8996 50.9054 10.4152 52.1716 11.3329 53.1052C12.2507 54.0388 13.4954 54.5632 14.7933 54.5632H57.2058C58.5037 54.5632 59.7485 54.0388 60.6663 53.1052C61.584 52.1716 62.0996 50.9054 62.0996 49.5851V30.8342L36.4205 37.7936C36.1447 37.8685 35.8545 37.8685 35.5787 37.7936L9.8996 30.8342V49.5851Z" fill="#004bbc"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.8996 23.0348C9.8996 21.7145 10.4152 20.4483 11.3329 19.5147C12.2507 18.5811 13.4954 18.0566 14.7933 18.0566H57.2058C58.5037 18.0566 59.7485 18.5811 60.6663 19.5147C61.584 20.4483 62.0996 21.7145 62.0996 23.0348V27.6279L37.2589 34.365C36.4338 34.5885 35.5654 34.5885 34.7403 34.365L9.8996 27.6279V23.0348ZM26.2121 16.3973C26.2121 15.077 26.7277 13.8108 27.6454 12.8772C28.5632 11.9436 29.8079 11.4191 31.1058 11.4191H40.8933C42.1912 11.4191 43.436 11.9436 44.3538 12.8772C45.2715 13.8108 45.7871 15.077 45.7871 16.3973V18.0566H42.5246V16.3973C42.5246 15.9572 42.3527 15.5351 42.0468 15.2239C41.7409 14.9127 41.326 14.7379 40.8933 14.7379H31.1058C30.6732 14.7379 30.2583 14.9127 29.9524 15.2239C29.6465 15.5351 29.4746 15.9572 29.4746 16.3973V18.0566H26.2121V16.3973Z" fill="#004bbc"/>
                                            </g>
                                            </g>
                                            <defs>
                                            <filter id="filter0_f" x="-4.50001" y="-3.6" width="81" height="81.9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                            <feGaussianBlur stdDeviation="7.2" result="effect1_foregroundBlur"/>
                                            </filter>
                                            <clipPath id="clip0">
                                            <rect width="72" height="72" fill="white"/>
                                            </clipPath>
                                            <clipPath id="clip1">
                                            <rect width="52.2" height="53.1" fill="white" transform="translate(9.89999 10.8)"/>
                                            </clipPath>
                                            <clipPath id="clip2">
                                            <rect width="52.2" height="53.1" fill="white" transform="translate(9.89999 8.1)"/>
                                            </clipPath>
                                            </defs>
                                        </svg>

                                        <p className="f-14 font-weight-bold text-center mb-1 mt-3">
                                            Badan Usaha
                                        </p>
                                        <p>
                                            Ini diisi dengan deskripsi singkat mengenari profil
                                            nasabah badan usaha
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegistrasiBadanUsaha;
