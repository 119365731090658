import React, { memo } from 'react';
import BasePageMenu from '../../controls/basePageMenu/basePageMenu';
import bannerPaylogo from '../../../img/Banner.png'
import financelogo from '../../../img/angsuran.png'
import plnLogo from '../../../img/pln.png'
import tvLogo from '../../../img/tvkabel.png'
import pdamLogo from '../../../img/pdam.png'
import telcoLogo from '../../../img/telco.png'
import tiketLogo from '../../../img/tiket.png'
import bayardaerahLogo from '../../../img/bayardaerah.png'
import walletLogo from '../../../img/wallet.png'
import asuransiLogo from '../../../img/asuransibayar.png'
import bankLogo from '../../../img/perbankan.png'
import zakatLogo from '../../../img/zakat.png'
import bayarLogo from '../../../img/bayarjamak.png'
import shopLogo from '../../../img/ecommerce.png'
import gasLogo from '../../../img/gas.png'
import { Link } from 'react-router-dom';

const menuPayPage = memo((props) => {
    return (
        <BasePageMenu 
            icon={bannerPaylogo}
            title="Layanan POSPAY"
            layanan={
                [
                    {title: "Listrik", link: "/posPay/Listrik", icon: plnLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Pajak & MPN G3", link: "#!", icon: tvLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Telco", link: "/posPay/telco", icon: telcoLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "PDAM", link: "/posPay/pdam", icon: pdamLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "TV Kabel", link: "/posPay/tv-cable", icon: tvLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Penerima Daerah", link: "/posPay/penerima-daerah", icon: bayardaerahLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Asuransi", link: "/posPay/asuransi", icon: asuransiLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Deposit", link: "/posPay/deposit", icon: walletLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Gas", link: "/posPay/gas", icon: gasLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Online Shop", link: "/posPay/olshop", icon: shopLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Multi Finance", link: "/posPay/mfinance", icon: financelogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Tiketing", link: "/posPay/tiketing", icon: tiketLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Bank Channel", link: "#!", icon: bankLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                    {title: "Zakat", link: "/posPay/zakat", icon: zakatLogo, linkBack: "/posPay", lastTitle: "Pospay"},
                ]
            }
            rightComponent={
                [
                    {name: "Bayar Sekaligus", link: "#!", icon: bayarLogo, note: "Bayar banyak tagihan untuk satu pelanggan"},
                ]
            } />
    )
});

// const menuPayPage = memo((props) => {
//     return (
//         <>
//             <div className="col-lg-12 p-0">
//                 <img src={bannerPaylogo} alt="Logo" style={{ width: "100%" }} />
//             </div>
//             <div className="col-lg-12 mx-auto">
//                 <div className="card" style={{ borderRadius: "8px", position: "absolute", top: "-40px" }}>
//                     <div className="card-body">
//                         <h5 className="mb-4">Layanan POSPAY</h5>

//                         <div className="row">
//                             <div className="col-lg-9 pr-0 mr-4" style={{ borderRight: "2px solid #000" }}>

//                                 <div className="row mr-0">
//                                     <Link to="/posPay/Listrik" style={{ textDecoration: "none", color: "black" }} className="col-lg-2 m-2 p-0">
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={plnLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label style={{ fontSize: "10pt" }}>  Listrik</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link to="#!" className="col-lg-2 m-2 p-0" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={tvLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label className="float-right m-0" style={{ fontSize: "10pt", width: "50%" }}>  Pajak & MPN G3</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/telco" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={telcoLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label style={{ fontSize: "10pt" }}>  Telco </label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/pdam" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={pdamLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label style={{ fontSize: "10pt" }}>  PDAM </label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/tv-cable" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={tvLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label style={{ fontSize: "10pt" }}>TV Kabel</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                 </div>

//                                 <div className="row mr-0">
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/penerima-daerah" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={bayardaerahLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label className="float-right m-0" style={{ fontSize: "10pt", width: "50%" }}>  Penerima Daerah</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/asuransi" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={asuransiLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label style={{ fontSize: "10pt" }}>  Asuransi</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/deposit" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={walletLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label style={{ fontSize: "10pt" }}>  Deposit</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/gas" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={gasLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label style={{ fontSize: "10pt" }}>  Gas</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/olshop" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={shopLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label className="float-right m-0" style={{ fontSize: "10pt", width: "50%" }}>  Online Shop</label>

//                                             </div>
//                                         </div>
//                                     </Link>
//                                 </div>

//                                 <div className="row mr-0">
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/mfinance" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={financelogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label className="float-right m-0" style={{ fontSize: "10pt", width: "50%" }}>  Multi Finance</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/tiketing" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={tiketLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label style={{ fontSize: "10pt" }}>  Tiketing</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="#!" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={bankLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label className="float-right m-0" style={{ fontSize: "10pt", width: "50%" }}>Bank Channel</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <Link className="col-lg-2 m-2 p-0" to="/posPay/zakat" style={{ textDecoration: "none", color: "black" }}>
//                                         <div className="card" style={{ borderRadius: "8px" }}>
//                                             <div className="card-body font-smaller">
//                                                 <img src={zakatLogo} alt="Logo" width={40} className="mr-2" />
//                                                 <label style={{ fontSize: "10pt" }}> Zakat</label>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                 </div>

//                             </div>
//                             <div className="col-lg-2 mt-2 ml-5 p-0" to="#!" style={{ textDecoration: "none", color: "black" }}>
//                                 <div className="card" style={{ borderRadius: "8px" }}>
//                                     <div className="card-body font-smaller">
//                                         <img src={bayarLogo} alt="Logo" width={40} className="mr-2" />
//                                         <label className="float-right m-0" style={{ fontSize: "10pt", width: "50%" }}> Bayar Sekaligus</label>
//                                     </div>
//                                 </div>
//                                 <p className="mt-3"><b>Bayar banyak tagihan untuk satu pelanggan</b></p>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </>

//     )
// })

export default menuPayPage;