import React from "react";
import { TableGrid, TableList } from "../../../controls";
import useSvs from "./useSvs";

const AccountImages = () => {
  const { svsAction, svsState } = useSvs();
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <TableList
            withCard={false}
            pagination={false}
            data={svsState.svsData.svsData}
            columns={[
              {
                name: "Image",
                field: "signed_url",
                maxWidth: "70px",
                onRender: (item) => {
                  return (
                    <img src={item.signed_url} style={{ maxWidth: "50px", borderRadius: "5px" }} />
                  );
                },
              },
              {
                name: "Image Tag",
                field: "image_tag",
                maxWidth: "50px",
              },

              {
                name: "Remarks",
                field: "remark",
                maxWidth: "50px",
              },

              // {
              //   name: "Status",
              //   field: "status",
              //   maxWidth: "50px",
              //   onRender: (item) => {
              //     if (item.status === "A") {
              //       return "Aktif"
              //     } else if (item.status === "R") {
              //       return "Rejected"
              //     } else if (item.status === "PENDING_APPROVAL") {
              //       return "Pending Approval"
              //     } else if (item.status === "DELETE") {
              //       return "Pending Delete"
              //     }
              //   },
              // },
            ]}
            withAction={true}
            actionItems={[
              {
                name: "Lihat Detail",
                right:true,
                onClick: (e) => {
                  svsAction.openModalSVS(e);
                },
              },
            ]}
          />
        </div>
      </div>
      {/* <div className="row mt-4">
        <div className="col-md-4">
          <p>Image 1</p>
          <div className="svs-box">
            <div className="mt-5 text-center">
              <img height={110} />
            </div>
          </div>
          <p className="border p-2 mt-2 fs-12">RONNY INDRAWANTO TANJUNG</p>
        </div>
        <div className="col-md-4">
          <p>Image 2</p>
          <div className="svs-box">
            <div className="mt-5 text-center">
              <img height={110} />
            </div>
          </div>
          <p className="border p-2 mt-2 fs-12">RONNY INDRAWANTO TANJUNG</p>
        </div>
        <div className="col-md-4">
          <p>Image 3</p>
          <div className="svs-box">
            <div className="mt-5 text-center">
              <img height={110} />
            </div>
          </div>
          <p className="border p-2 mt-2 fs-12">RONNY INDRAWANTO TANJUNG</p>
        </div>
      </div> */}
    </div>
  );
};

export default AccountImages;
