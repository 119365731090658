import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useDropdowns from '../../../../../../hooks/useDropdowns';
import useMarketing from '../../../../../../hooks/useMarketing';
import useFetch from '../../../../../../hooks/useFetch';
// import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import useAlamat from '../../../../../../hooks/useAlamat';
import { useLocalStorage } from '../../../../../../module';
import { ModalNomorCantik } from '../../../../../controls';
import useKeycloak from '../../../../../../hooks/keycloak.module';


const ftbContext = createContext(null)

export const FtbProvider = ({ children }) => {

    const [podDate, setPodDate] = useLocalStorage("POD_DATE")
    const { doGet, doGetMultiple, doPost } = useFetch();
    const paramUrl = useParams();
    const [step, setStep] = useState(0);
    const [formEditable, setFormEditable] = useState(true);
    const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false })
    const [locationState, setLocationState] = useState({});
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState({ getData: false, loadingPost: { show: false, msg: "Please Wait ..." } });
    const [otorDesc, setOtorDesc] = useState("")
    const dataDropDown = useDropdowns();
    const { keycloak } = useKeycloak()
    const [formData, setFormData] = useState({
        "encodedKey": "",
        "state": "",
        "accountHolderType": "",
        "namaRekening": "",
        "namaNasabah": "",
        "accountHolderKey": "",
        "productTypeKey": "",
        "productType": "RENCANA",
        "nomorCIF": "",
        "nomorRekening": "",
        "produkTabungan": "",
        "keterangan": "",
        "saldo": 0,
        "valuta": "IDR",
        "rejectionNote": "",
        "_informasiLain": {
            "nisbahCounter": "0",//nisbah spesial
            "nisbahZakat": "0",//zakat bagi hasil
            "nisbahPajak": "0",//pajak bagi hasil
            "nisbahAkhir": "0",//nisbah produk
            "qqRekening": "0",
            "qqName": "0",
            "biayaBiaya": "",
            "sumberDana": "",
            "tncVersion": "",
            "tujuanPembukaanRekening": "",
            "kelengkapanData": "",
            "marketingReferensi": "",//marketing inisiasi
            "marketingSaatIni": "",//Marketing Maintenance
            "marketingPertama": "",
            "rencanaAsuransiUtama": "",
            "tanggalJatuhTempo": "",
            "rencanaAsuransiTambahan": "",
            // "kode_tag" : ""
        },
        "_dataNasabah": {
            "homePhone": "",
            "mobilePhone": "",
            "nama_ibu_kandung": "",
            "tanggalLahir": "",
            "tempatLahir": "",
            "alamat": ""
        },
        "_rekeningRencana": {
            "jangka_waktu": "",
            "tanggal_autodebet": "",
            "target_saldo": "",
            "no_rekening_pencairan": "",
            "nama_rekening_pencairan": "",
            "no_rekening_autodebet": "",
            "nama_rekening_autodebet": "",
            "nominal_debet": "",
        }


    });
    //faktor resiko state
    // const [ formResiko, setFormResiko ] = useState({
    //     identitasMasalah : 1,
    //     lokasiUsaha: 1,
    //     profileNasabah: 1,
    //     jumlahTrx:1,
    //     kegiatanUsaha:1,
    //     strukturKepemilikan:1,
    //     informasiLainnya:1
    // });

    // fungsiFaktorresiko
    // useEffect(function(){
    //     const keys = Object.keys(formResiko);
    //     let average = 1;
    //     let total = 0;
    //     keys.forEach( k => {
    //         total += parseInt(formResiko[k]);
    //     });
    //     let dataResiko = [
    //         null,
    //         "LOW",
    //         "MEDIUM",
    //         "HIGH"
    //     ];

    //     average = (total/keys.length).toFixed(0);
    //     console.log(dataResiko[average])
    //     changeForm(dataResiko[average], "cddStatusResiko", "_cdd")
    // }, [formResiko])

    // alamat 1
    const [dataProduk, setDataProduk] = useState([])
    const [districts, setDistricts] = useState([])
    const [villages, setvillages] = useState([])
    const [cities, setCities] = useState([])
    const [postalCodes, setPostalCodes] = useState([])
    const [dataMarketing, setDataMarketing] = useState([])

    const {
        provinces,
        getCities,
        getVillages,
        getPostalsCode,
        getDistrics
    } = useAlamat();

    useEffect(function () {
        setLocationState(location.state);
    }, [location.state])

    const [ dataTag, setDataTag] = useState([])
    useEffect(function () {
        (async ()=>{

            try {
                const { data = [], statusCode, status, remark, statusText } = await doGet({
                    url: `/rekening/list-tag-rekening`,
                    service: "acc"
                });
                if (statusCode === 200) {
                    if (status === "00") setDataTag(data ||[])
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data produk`, header: "Error", callBack: () => null });
            }
    
        })()
    }, [])
    // function stepper
    function clickStep(id) { setStep(id); }
    function nextStep() {
        let dataRequired = [
            // "nasabahBadanUsaha", "nasabahAssetPerusahaan", "nasabahNomorNpwp", "groupName",
            // "nasabahOmsetPerusahaan", "nasabahBidangUsaha", "nasabahKodeLokasi",
            // "nasabahKeteranganLokasi", "nasabahNomorAktePendirian", "nasabahTanggalAktePendirian",
            // "nasabahSektorEkonomi", "nasabahAlamat", "nasabahRw", "nasabahRt", "nasabahProvinsi", "nasabahKotaKabupaten",
            // "nasabahKecamatan", "nasabahKelurahan"
        ];
        // if (step === 2) {
        //     dataRequired = [
        //         "cddPenghasilanKotorPerTahun", "cddGolonganNasabah", "cddSumberDana", "cddSumberPenghasilan",
        //         "cddTujuanPenggunaanDana", "cddStatusKenaPajak", "cddKeteranganBebasPajak", "cddNominalStorTunai",
        //         "cddNominalStorTunaiFre", "cddNominalStorNonTunai", "cddNominalStorNonTunaiFre", "cddNominalTarikTunai",
        //         "cddNominalTarikTunaiFre", "cddNominalTarikNonTunai", "cddNominalTarikNonTunaiFre", "cddStatusResiko"
        //     ]
        // }
        if (paramUrl.mode === "registration") {
            if (validasi(dataRequired)) {
                return setDataAlert({ show: true, body: "Periksa kembali form pendaftaran, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
            }
        }
        step !== 0 && setStep(prevStep => prevStep + 1);
    }
    function prevState() { step > 0 && setStep(prevStep => prevStep - 0); }

    // detect mode form
    useEffect(() => {
        // if (paramUrl.mode !== "registration") {
        if (paramUrl.mode === "otorisasi" || paramUrl.mode === "edit" || paramUrl.mode === "detil") {
            // console.log(location.state)
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });

            getDataDetail(location.state?.dbId);
        }
        // } 
        setFormEditable(paramUrl.mode === "registration" || paramUrl.mode === "edit");
        // let kat = paramUrl.type;
        // if (kat === "tabungan-rencana") kat = "rencana";
        // changeForm( "rencana".toUpperCase(),"productType")
    }, [paramUrl.mode])


    useEffect(function(){
        
        getDataProduct();
        getDataMarketing();
    },[])
    // function getData Perorangan
    async function getDataDetail(dbId) {
        let url = "/rekening/local";
        if (paramUrl.mode === "edit" || paramUrl.mode === "detil") url = "/tabungan-giro"
        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `${url}/${dbId}`, service: "acc" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    // console.log(data);
                    let stateBase = {
                        // dataConverter.nasabahIndividu(nasabahBadanUsaha)
                    }
                    if (paramUrl.mode === "otorisasi") {
                        setFormData(prevState => ({
                            ...prevState,
                            "encodedKey": data.encodedKey,
                            "state": data.state,
                            "accountHolderType": data.accountHolderType, //
                            "namaRekening": data.namaRekening, //
                            "namaNasabah": data.namaNasabah, //
                            "accountHolderKey": data.accountHolderKey,
                            "productTypeKey": data.productTypeKey, //
                            "productType": data.productType,
                            "nomorCIF": data.nomorCIF,
                            "nomorRekening": data.nomorRekening,
                            "produkTabungan": data.produkTabungan,
                            "keterangan": data.keterangan,
                            "saldo": data.saldo,
                            "valuta": data.valuta,
                            "rejectionNote": data.rejectionNote,
                            "nomor_akad":data.nomor_akad,
                            "_informasiLain": {
                                "nisbahCounter": data.nisbahCounter,//nisbah spesial
                                "nisbahZakat": data.nisbahZakat,//zakat bagi hasil
                                "nisbahPajak": data.nisbahPajak,//pajak bagi hasil
                                "nisbahAkhir": data.nisbahAkhir,//nisbah produk
                                "qqRekening": data.qqRekening,
                                "qqName": data.qqName,
                                "biayaBiaya": data.biayaBiaya,
                                "sumberDana": data.sumberDana,
                                "tncVersion": data.tncVersion, //
                                "tujuanPembukaanRekening": data.tujuanPembukaanRekening,
                                "kelengkapanData": data.kelengkapanData, //
                                "marketingReferensi": data.marketingReferensi,
                                "marketingPertama": data.marketingPertama, //marketing inisiasi
                                "marketingSaatIni": data.marketingSaatIni,//Marketing Maintenance
                                "rencanaAsuransiUtama": data.rencanaAsuransiUtama, //
                                "tanggalJatuhTempo": data.tanggalJatuhTempo, //
                                "rencanaAsuransiTambahan": data.rencanaAsuransiTambahan, //
                                
                                // "kode_tag" : data.kode_tag
                            },
                            "_dataNasabah": {
                                "homePhone": data.homePhone,
                                "mobilePhone": data.mobilePhone,
                                "nama_ibu_kandung": data.nama_ibu_kandung, //
                                "tanggalLahir": data.tanggalLahir, //
                                "tempatLahir": data.tempatLahir, //
                                "alamat": data.alamat // 
                            },
                            "_rekeningRencana": {
                                "jangka_waktu": data.jangka_waktu,
                                "tanggal_autodebet": data.tanggal_autodebet,
                                "target_saldo": data.target_saldo,
                                "no_rekening_pencairan": data.no_rekening_pencairan,
                                "nama_rekening_pencairan": data.nama_rekening_pencairan,
                                "no_rekening_autodebet": data.no_rekening_autodebet,
                                "nama_rekening_autodebet": data.nama_rekening_autodebet,
                                "nominal_debet": data.nominal_debet,
                            },
                            "_rekeningTabungan": {
                                "dapatBonusBagiHasil": data.dapatBonusBagiHasil
                            }
                        }));

                    } else {
                        // console.log(data)
                        setFormData(prevState => ({
                            ...prevState,
                            encodedKey: data.encodedKey,
                            state: data.state,
                            accountHolderType: data.accountHolderType,
                            accountHolderKey: data.accountHolderKey,
                            // productTypeKey: data.productTypeKey,
                            // productType: data.productType,
                            saldo: data.saldo,
                            rejectionNote: data.rejectionNote,
                            nomorRekening: data.nomor_rekening,
                            nomorCIF: data.nomor_nasabah,
                            namaNasabah: data.nama_lengkap,
                            produkTabungan: data.kode_produk,
                            namaRekening: data.nama_rekening,
                            valuta: data.kode_valuta,
                            keterangan: data.keterangan,
                            nomor_akad:data.nomor_akad,
                            _dataNasabah: {
                                ...prevState._dataNasabah,
                                ...stateBase._dataNasabah,
                                homePhone: data.telepon_rumah_nomor,
                                mobilePhone: data.telepon_hp_nomor,
                                alamat: data.alamat,
                                nama_ibu_kandung: data.nama_ibu_kandung,
                                tanggalLahir: data.tanggalLahir,
                                tempatLahir: data.tempatLahir,
                            },
                            _informasiLain: {
                                ...prevState._informasiLain,
                                ...stateBase._informasiLain,
                                nisbahCounter: data.nisbah_spesial,
                                nisbahZakat: data.persentase_zakat_bagi_hasil,
                                nisbahPajak: data.tarif_pajak,
                                nisbahAkhir: data.nisbah_dasar,
                                marketingSaatIni: data?.kode_marketing_current || "",
                                marketingPertama: data?.kode_marketing_pertama || "",
                                sumberDana: data?.kode_sumber_dana || "",
                                tujuanPembukaanRekening: data?.kode_tujuan_rekening || "",
                                qqRekening: data.qqRekening,
                                qqName: data.qqName,
                                biayaBiaya: data.biayaBiaya,
                                tncVersion: data.tncVersion,
                                marketingReferensi: data.marketingReferensi,
                                rencanaAsuransiUtama: data.rencanaAsuransiUtama,
                                tanggalJatuhTempo: data.tanggalJatuhTempo,
                                rencanaAsuransiTambahan: data.rencanaAsuransiTambahan,
                                
                                // "kode_tag" : data.kode_tag
                            },
                            _rekeningRencana: {
                                ...prevState._rekeningRencana,
                                ...stateBase._rekeningRencana,
                                jangka_waktu: data.jangka_waktu,
                                tanggal_autodebet: data.tanggal_setoran_rutin,
                                target_saldo: data.target_nominal,
                                no_rekening_pencairan: data.nomor_rekening_pencairan,
                                nama_rekening_pencairan: data.nama_rekening_pencairan,
                                no_rekening_autodebet: data.nomor_rekening_induk,
                                nama_rekening_autodebet: data.nama_rekening_autodebet,
                                nominal_debet: data.nominal_debet || 0,
                            },
                            _rekeningTabungan: {
                                ...prevState._rekeningTabungan,
                                ...stateBase._rekeningTabungan,
                                dapatBonusBagiHasil: data.is_dapat_bagi_hasil === "T"
                            },
                            ...stateBase
                        }));
                    }
                    //         const keys_formData = Object.keys(formData);
                    //         keys_formData?.forEach(kFormData => {
                    //             if ((formData[kFormData] instanceof Object) && !Array.isArray(formData[kFormData])) {
                    //                 const tKey = Object.keys(formData[kFormData])
                    //                 let dataChild = {}
                    //                 tKey.forEach(k => {
                    //                     dataChild = {
                    //                         ...dataChild,
                    //                         [k]: data[k]
                    //                     }
                    //                 })
                    //                 stateBase = {
                    //                     ...stateBase,
                    //                     [kFormData]: dataChild
                    //                 }
                    //             } else if (data[kFormData] !== undefined) {
                    //                 stateBase = { ...stateBase, [kFormData]: data[kFormData] }
                    //             }
                    //         });
                    //         setFormData(prevState => ({ ...prevState, ...stateBase }));
                    //     }
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
        // console.log(data)
    }
    // close Alert
    function closeAlert() { setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null }) }

    // closeConfirm
    function closeConfirm() { setDataConfirm({ show: false, body: "", header: null, title: "", footerButton: [], closeButton: true, witTextArea: false }) }

    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} nasabah ini ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        setLoading(prevState => ({ ...prevState, loadingPost: { show: true, msg: "Please Wait ..." } }));
        // closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (locationState !== null && locationState !== undefined) {
            try {
                const { statusCode, remark = "", data = {}, status = "01", statusText = "", message = "" } = await doPost({
                    url: `/otorisasi/${locationState.kode_entry}/${locationState.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "acc",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                });
                // console.log(data)
                let msg = `Proses Otorisasi Berhasil.`;
                if (statusCode === 200) {
                    if (statusApprove === "APPROVED") msg = `Proses Otorisasi Berhasil. Nomor Rekening :  ${data?.nomor_rekening || formData.nomorRekening}`
                    if (status === "00") setDataAlert({ show: true, body: msg, title: ``, header: "Success", callBack: () => history.goBack() });
                    else setDataAlert({ show: true, body: `${remark}. ${message}`, title: ``, header: "Info", callBack: () => history.goBack() });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });

        setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        if (typeof value === "string") value = value.toUpperCase()
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }


    // get province_code
    async function getProvinceCode(province_name) {
        if (provinces !== null && provinces !== undefined) {
            const data = provinces?.find(v => {
                return v.province_name === province_name
            });
            return data?.province_code || "";
        }
    }
    // fungsi cek nasabah 
    async function cekNasabah(nomor_rekening) {

        setLoading(prevState => ({ ...prevState, loadingPost: { show: true, msg: "Please Wait ..." } }));
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/tabungan-giro/rek-for-trx/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {

                if (status === "00") {
                    const { nama_rekening = "", nama_cabang = "", valuta = "", nama_produk = "" } = data;

                    changeForm(nama_rekening, "nama_bagi_hasil", "_data")
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }

        setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }
    // fungsi cek nasabah rekekning pencarian
    async function cekNasabahRekPencarian(nomor_rekening) {
        setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/tabungan-giro/rek-for-trx/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {
                if (status === "00") changeForm(data.nama_lengkap, "nama_rekening_pencairan", "_rekeningRencana");
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "no_rekening_pencairan", "_rekeningRencana") });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "no_rekening_pencairan", "_rekeningRencana") });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => changeForm("", "no_rekening_pencairan", "_rekeningRencana") });
        }
        closeLoading();
    }
    // fungsi cek nasabah rekekning auto debet
    async function cekNasabahRekAutoDebet(nomor_rekening) {
        setLoading(prevState => ({ ...prevState, loadingPost: { show: true, msg: "Checking Account no ..." } }));
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/tabungan-giro/rek-for-trx/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {
                if (status === "00") changeForm(data.nama_lengkap, "nama_rekening_autodebet", "_rekeningRencana");
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "no_rekening_autodebet", "_rekeningRencana") });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => changeForm("", "no_rekening_autodebet", "_rekeningRencana") });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => changeForm("", "no_rekening_autodebet", "_rekeningRencana") });
        }

        setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }
    //  fungsi validasi
    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            fieldRequired = ["nomorCIF", "nama_produk", "nama_rekening", "sumberDana", "tujuanPembukaanRekening", "marketingPertama", "marketingSaatIni"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    // get province_code
    async function getProvinceCode(province_name) {
        if (provinces !== null && provinces !== undefined) {
            const data = provinces?.find(v => {
                return v.province_name === province_name
            });
            return data?.province_code || "";
        }
    }

    // close Loading
    function closeLoading() { setLoading(prevState => ({ msg: "Please Wait ...", loading: false })) }

    // confirm Post Nasabah
    function confirmPostNasabah() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "registrasi rekening tabungan";
        let body = `Lanjutkan proses ${formType}?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => paramUrl.mode === "edit" ? updateNasabah() : postNasabah(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    // fungsi post nasabah
    async function postNasabah() {
        const userInfo = await keycloak.loadUserInfo();
        const err = validasi();
        if (err) {
            return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar dan terisi lengkap.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        setLoading(prevState => ({ ...prevState, loadingPost: { show: true, msg: "Please Wait ..." } }));
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: {
                    ...formData,
                    uid:keycloak.uid,
                },
                url: "/rekening-rencana",
                service: "acc"
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Proses Registrasi Rencana Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => history.goBack() });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    // update nasabah
    async function updateNasabah() {
        const userInfo = await keycloak.loadUserInfo();
        const err = validasi();
        // console.log(err)
        if (err) {
            return setDataAlert({ show: true, body: "Periksa kembali form ubah data, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        setLoading(prevState => ({ ...prevState, loadingPost: { show: true, msg: "Please Wait ..." } }));

        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                method: "PUT",
                data: formData,
                url: `/rekening-rencana/ubah`,
                service: "acc"
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: true, body: `Ubah Nasabah Berhasil, Perlu Otorisasi Untuk Melanjutkan`, title: ``, header: "Success", callBack: () => history.goBack() });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));

    }
    //  get data Mekerting
    async function getDataMarketing() {

        try {
            const { data, statusCode, status, remark, statusText } = await doGet({
                url: `/account-officer`,
                service: "cif"
            });
            if (statusCode === 200) {
                if (status === "00") setDataMarketing(data?.list)
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data produk`, header: "Error", callBack: () => null });
        }

    }


    // get cities 1
    async function getingCities(province_code) {
        const data = await getCities(province_code);
        const newData = data.map(v => ({ key: v, display: v }));
        setCities(newData);
    }

    // get districts 1
    async function getingDistrict(city) {
        const data = await getDistrics(city);
        const newData = data.map(v => ({ key: v, display: v }));
        setDistricts(newData);
    }
    // get village 1
    async function getingVillages(city, subdistrict) {
        const data = await getVillages(city, subdistrict);
        const newData = data.map(v => ({ key: v, display: v }));
        setvillages(newData);
    }
    // get postalcode 1
    async function getingPostalCodes(city, subdistrict, urban) {
        const data = await getPostalsCode(city, subdistrict, urban);
        const newData = data.map(v => ({ key: v, display: v }));
        setPostalCodes(newData);
    }

    //  get data produk
    async function getDataProduct() {
        // let kat = paramUrl.type;
        // if (kat === "rencana") kat = "rencana";

        try {
            const { data, statusCode, status, remark, statusText } = await doGet({
                url: `/produk-deposit/rencana`,
                service: "cif"
            });
            if (statusCode === 200) {
                if (status === "00") setDataProduk(data?.list)
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data produk`, header: "Error", callBack: () => null });
        }

    }
    // pilih nasabah
    async function pilihNasabah(dataNasabah, tipe) {
        setLoading(prevState => ({ ...prevState, loadingPost: { show: true, msg: "Please Wait ..." } }));
        let url = `/nasabah/${dataNasabah.nomor_cif}`;
        if (parseInt(tipe) === 1) url = `/nasabah-badan-usaha/${dataNasabah.nomor_cif}`;

        try {
            const resp = await doGet({
                url,
                service: "cif"
            });
            const { status, statusText, data, statusCode, remark } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    const { nasabahBadanUsaha } = data
                    let homePhone = nasabahBadanUsaha?.homePhone;
                    let mobilePhone = nasabahBadanUsaha?.mobilePhone;
                    let alamat = dataNasabah.alamat.toUpperCase()

                    if (tipe === 1) {
                        homePhone = nasabahBadanUsaha?.nasabahTeleponKantorKodeArea1 + nasabahBadanUsaha?.nasabahTeleponKantorNomor1;
                        mobilePhone = "";
                    }
                    setFormData(prevState => ({
                        ...prevState,
                        namaNasabah: dataNasabah.nama.toUpperCase(),
                        namaRekening: dataNasabah.nama.toUpperCase(),
                        nomorCIF: dataNasabah.nomor_cif,
                        _dataNasabah: {
                            ...prevState._dataNasabah,
                            homePhone,
                            mobilePhone,
                            alamat
                        }
                    }));
                    setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
                    return true;
                } else {
                    setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
                    setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
                    return false;
                }
            } else {
                setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });

                setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
                return false;
            }
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });

            setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
            return false;
        }

    }
    //change product
    function changeProduct(value) {
        // changeForm(value, "produkTabungan")
        const productDetil = dataProduk.find(v => v.kode_produk === value);

        // if(value !== "" && value !==null)
        // console.log(productDetil);
        changeForm(productDetil?.is_dapat_bagi_hasil === "T", "dapatBonusBagiHasil", "_rekeningTabungan")
        setFormData(prevState => ({
            ...prevState,
            produkTabungan: value,
            valuta: productDetil?.kode_valuta || "",
            _rekeningTabungan: {
                ...prevState._rekeningTabungan,
                dapatBonusBagiHasil: productDetil?.is_dapat_bagi_hasil === "T" || false
            },
            _informasiLain: {
                ...prevState._informasiLain,
                nisbahZakat: productDetil?.presentase_zakat_bagi_hasil || 0,
                nisbahPajak: productDetil?.tarif_pajak || 0,
                nisbahAkhir: productDetil?.nisbah_bonus_dasar,

            }

        }))
    }

    function resetForm() {
        setFormData({
            "encodedKey": "",
            "state": "",
            "accountHolderType": "",
            "namaRekening": "",
            "namaNasabah": "",
            "accountHolderKey": "",
            "productTypeKey": "",
            "productType": "RENCANA",
            "nomorCIF": "",
            "nomorRekening": "",
            "produkTabungan": "",
            "keterangan": "",
            "saldo": 0,
            "valuta": "IDR",
            "rejectionNote": "",
            "_informasiLain": {
                "nisbahCounter": "0",//nisbah spesial
                "nisbahZakat": "0",//zakat bagi hasil
                "nisbahPajak": "0",//pajak bagi hasil
                "nisbahAkhir": "0",//nisbah produk
                "qqRekening": "0",
                "qqName": "0",
                "biayaBiaya": "",
                "sumberDana": "",
                "tncVersion": "",
                "tujuanPembukaanRekening": "",
                "kelengkapanData": "",
                "marketingReferensi": "",//marketing inisiasi
                "marketingSaatIni": "",//Marketing Maintenance
                "marketingPertama": "",
                "rencanaAsuransiUtama": "",
                "tanggalJatuhTempo": "",
                "rencanaAsuransiTambahan": ""
            },
            "_dataNasabah": {
                "homePhone": "",
                "mobilePhone": "",
                "nama_ibu_kandung": "",
                "tanggalLahir": "",
                "tempatLahir": "",
                "alamat": ""
            },
            "_rekeningRencana": {
                "jangka_waktu": "",
                "tanggal_autodebet": "",
                "target_saldo": "",
                "no_rekening_pencairan": "",
                "nama_rekening_pencairan": "",
                "no_rekening_autodebet": "",
                "nama_rekening_autodebet": "",
                "nominal_debet": "",
            }
        })
    }
    //Post Auditrail
    async function auditTrail() {
        const userInfo = await keycloak.loadUserInfo();
        var url = `/useraudit/user-audit-trail`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doPost({
                url: url,
                service: "acc",
                data: {
                    user_id: userInfo.preferred_username,
                    app_id: "BDS",
                    terminal_ip: window.location.hostname,
                    operation_code: "Registrasi",
                    event_description: "Tabungan & Giro/Registrasi Rekening/Tabungan Rencana",
                    info1: "-",
                    info2: "-"
                },
                headers: {
                    "x-user-id": userInfo.preferred_username,
                    method: "POST"
                }
            });

            if (statusCode === 200) {
                if (status === "00") setDataAlert({ show: false, body: `Proses Audit Berhasil`, title: ``, header: "Success", callBack: () => null });
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: false, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: false, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

    }

    const [showModalNC, setShowModalNC] = useState(false);
    function openModalNC(){
        setShowModalNC(true)
    }
    function closeModalNC(){
        setShowModalNC(false)
    }
    function pilihNomorCantik(item) {
        changeProduct(item.kode_produk)
        setFormData(v => ({
            ...v,
            nomorRekening: item.nomor
        }));
        closeModalNC()
    }
    
    function resetNomorCantik(){
        changeProduct("")
        setFormData(v => ({
            ...v,
            nomorRekening: ""
        }));
    }
    return <ftbContext.Provider value={{
        ftbState: {
            paramUrl,
            step,
            formEditable,
            loading,
            dataAlert,
            formData,
            locationState,
            dataConfirm,
            otorDesc,
            dataDropDown,
            provinces,
            districts,
            villages,
            cities,
            postalCodes,
            // formResiko,
            dataProduk,
            dataMarketing,
            podDate,
            dataTag
        },
        ftbAction: {
            clickStep,
            nextStep,
            prevState,
            closeAlert,
            closeConfirm,
            responseOtor,
            setOtorDesc,
            changeForm,
            cekNasabah,
            validasi,
            confirmPostNasabah,
            getVillages,
            getCities,
            getDistrics,
            getPostalsCode,
            getProvinceCode,
            getingCities,
            getingDistrict,
            getingVillages,
            getingPostalCodes,
            // setFormResiko,
            setDataAlert,
            setLoading,
            setDataConfirm,
            pilihNasabah,
            changeProduct,
            cekNasabahRekPencarian,
            cekNasabahRekAutoDebet,
            resetForm,
            auditTrail,
            openModalNC,
            resetNomorCantik
        }
    }} >{children}
        <ModalNomorCantik

            show={showModalNC}
            onChoice={pilihNomorCantik}
            onHide={closeModalNC}
            products={dataProduk}
        />
    </ftbContext.Provider>
}

export default function useFormRegisTabunganRencana() { return useContext(ftbContext) };