import React, { memo } from 'react';
import { AlertBar } from '../controls';
import AutoSwitchLayout from './autoSwitch/autoSwitch';

const Layouts = memo(({children}) => {
    return (
        <>
            <AutoSwitchLayout>
                <AlertBar />
                {children}
            </AutoSwitchLayout>
        </>
    );
})

export default Layouts;