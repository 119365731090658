import React, { memo, useEffect, useRef, useState, forwardRef } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { AppAction, AppInterfaces } from '../../../../app.data';
import { useHistory, useLocation } from 'react-router-dom';
import { TableList, TableGrid } from '../../../controls';
import { Modal, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../app.config';
import queryString from 'query-string';
import Select from 'react-select';
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs';
import { thousandSeparator } from '../../../../utils';

const ComponentToPrint = forwardRef(({ data, header }, ref) => {
    const { keycloak } = useKeycloak();

    return (
        <div className="tw-m-4" ref={ref}>
            <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mb-4">
                <div className="tw-col-span-2">
                    TANGGAL CETAK {dayjs().format('DD/MM/YYYY HH:mm:ss')}
                </div>
                <div>BANK NET SYARIAH INDONESIA</div>
                <div>CABANG: {header?.cabang}</div>
                <div>CABANG : {header?.cabang} </div>
                <div>JL. JENDRAL SUDIRMAN</div>
                <div>ID PENGGUNA : {keycloak.idTokenParsed.preferred_username}</div>
                <div className="tw-col-span-2">
                    TANGGAL POSTING {dayjs().format('DD/MM/YYYY HH:mm:ss')}
                </div>
                <div>MATA UANG : IDR</div>
                <div>INDONESIA RUPIAH</div>
            </div>
            <TableGrid
                columns={[
                    {
                        name: 'Nomor Rekening',
                        field: 'nomorRekening',
                    },
                    {
                        name: 'Tanggal Efektif',
                        field: 'tanggalEfektif',
                    },
                    {
                        name: 'Pusat Biaya',
                        field: 'pusatBiaya',
                    },
                    {
                        name: 'Kode Prod.',
                        field: 'kodeProd',
                    },
                    {
                        name: 'Valuta Asal',
                        field: 'valutaAsal',
                    },
                    {
                        name: 'Deskripsi Rekening',
                        field: 'description',
                    },
                    {
                        name: 'Nomor Referensi',
                        field: 'noReferensi',
                    },
                    {
                        name: 'Nominal Debet',
                        field: 'nominalDebet',
                    },
                    {
                        name: 'Nominal Kredit',
                        field: 'nominalKredit',
                    },
                    {
                        name: 'Uraian Transaksi',
                        field: 'notes',
                    },
                ]}
                data={Array.from(
                    data || [],
                    ({ no_rekening, deskripsi, nominal_debit, nominal_kredit, nama_nasabah }) => ({
                        nomorRekening: no_rekening,
                        notes: deskripsi,
                        nominalKredit: new Intl.NumberFormat('id-ID').format(nominal_kredit),
                        nominalDebet: new Intl.NumberFormat('id-ID').format(nominal_debit),
                        tanggalEfektif: dayjs().format('DD-MM-YYYY'),
                        description: nama_nasabah,
                        noReferensi: header?.no_referensi,
                    })
                )}
            />
            <div className="tw-mt-4">
                <div className="tw-mb-3">
                    Total Debit:{' '}
                    {new Intl.NumberFormat('id-ID').format(
                        data?.reduce((prev, curr) => prev + curr?.nominal_debit ?? 0, 0)
                    )}
                </div>
                <div>
                    Total Kredit:{' '}
                    {new Intl.NumberFormat('id-ID').format(
                        data?.reduce((prev, curr) => prev + curr?.nominal_kredit ?? 0, 0)
                    )}
                </div>
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-mt-6">
                <div>
                    <div className="tw-mb-9">Petugas Jurnal</div>
                    <div>(.......................................)</div>
                </div>
                <div>
                    <div className="tw-mb-9">Kepala Seksi</div>
                    <div>(.......................................)</div>
                </div>
            </div>
        </div>
    );
});

export default AppInterfaces.appLoad(
    memo(function ({ store, methods, dispatch }) {
        const appAction = useRef(null);
        const { data = [] } = store;
        const [transaksiHeader, setTransaksiHeader] = useState(null);
        const [isLoadingGLLists, setIsLoadingGLLists] = useState(true);
        const [message, setMessage] = useState(null);
        const [editId, setEditId] = useState(null);
        const [filter, setFilter] = useState({
            search: '',
            type: null,
        });
        const location = useLocation();
        const queryParams = queryString.parse(location.search);
        let today = new Date();
        const [modal, setModal] = useState({
            buatTransaksiRekening: false,
            buatTransaksiGL: false,
            confirm: false,
            success: false,
            error: false,
            warning: false,
        });
        const [mode, setMode] = useState({
            transaksiRekening: 'create',
            transaksiGL: 'create',
            header: 'process',
        });
        const [rekeningBodyParam, setRekeningBodyParam] = useState({
            no_rekening: null,
            nama_nasabah: null,
            cabang_rekening: '1001',
            cabangName_rekening: 'Head Office',
            valuta: 'IDR',
            jenis_mutasi: 'kredit',
            kurs: 1,
            nominal: 0,
            keterangan: null,
            header_id: queryParams.id,
            tgl_efektif: today.toISOString().substr(0, 10),
        });
        const [glBodyParam, setGlBodyParam] = useState({
            kode_gl: null,
            nama_nasabah: null,
            cabang_rekening: '1001',
            cabangName_rekening: 'Head Office',
            valuta: null,
            jenis_mutasi: 'kredit',
            nominal: 0,
            keterangan: null,
            header_id: queryParams.id,
            tgl_efektif: today.toISOString().substr(0, 10),
            pusat_biaya: null,
            no_referensi: null,
        });
        const [error, setError] = useState({
            nomor_rekening: false,
            kode_gl: false,
        });
        const [glOptions, setGlOptions] = useState([]);
        const [showModalHapus, setShowModalHapus] = useState(false);
        const [indexHapus, setIndexHapus] = useState(null);
        const [dropdowns, setDropdowns] = useState({
            PUSATBIAYA: [],
        });
        const componentRef = useRef(null);
        let history = useHistory();

        useEffect(function () {
            appAction.current
                .doGet({
                    url: `/transaksi-umum/list/${queryParams.id}`,
                    vari: 'data',
                    param: {
                        page: 0,
                        limit: 15,
                    },
                })
                .then((resp) => {
                    // console.log(resp);
                    let created_at = resp.transaksi_header.created_at;
                    setTransaksiHeader(resp.transaksi_header);
                    setRekeningBodyParam({
                        ...rekeningBodyParam,
                        tgl_efektif: created_at?.substr(0, 10),
                    });
                    setGlBodyParam({
                        ...glBodyParam,
                        tgl_efektif: created_at?.substr(0, 10),
                    });
                });

            getListKodeGL();
            getListDropdowns('PUSATBIAYA');
        }, []);

        const handleFormatNumber = (event, isObject, objectKey) => {
            const value = event.target.value;
            const name = event.target.name;

            const newValue = thousandSeparator.format(value);

            if (newValue !== 'NaN') {
                if (objectKey === 'rekening') {
                    setRekeningBodyParam({
                        ...rekeningBodyParam,
                        [name]: newValue,
                    });
                } else {
                    setGlBodyParam({
                        ...glBodyParam,
                        [name]: newValue,
                    });
                }
            }
        };

        const getData = () => {
            appAction.current
                .doGet({
                    url: `/transaksi-umum/list/${queryParams.id}`,
                    vari: 'data',
                    param: {
                        page: 0,
                        limit: 15,
                    },
                })
                .then((resp) => {
                    setTransaksiHeader(resp.transaksi_header);
                });
        };

        const filterData = (value = null) => {
            if (value !== '') {
                appAction.current.doGet({
                    url: `/transaksi-umum/search/${queryParams.id}`,
                    vari: 'data',
                    param: {
                        page: 0,
                        limit: 15,
                        jenis_transaksi: value ? value : filter.type,
                        search_text: filter.search,
                    },
                });
            }
        };

        const getListKodeGL = () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };
            fetch(baseUrl + '/teller/gl-account/all', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        let dataDetail = data.list;
                        let newGlOptions = [];
                        dataDetail.map((data, index) => {
                            newGlOptions.push({
                                label: data.kode_gl + ' - ' + data.name,
                                value: data.kode_gl + ' - ' + data.name,
                            });
                        });
                        // console.log(newGlOptions);
                        setGlOptions(newGlOptions);
                        setIsLoadingGLLists(false);
                    } else {
                        setError({ ...error, nomor_rekening: true });
                    }
                });
        };

        const getNomorRekening = (value) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };
            fetch(baseUrl + '/rekening/tabungan/detail/' + value, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        let dataDetail = data.list;
                        setError({ ...error, nomor_rekening: false });
                        setRekeningBodyParam({
                            ...rekeningBodyParam,
                            no_rekening: value,
                            nama_nasabah: dataDetail.nama,
                            produk_rekening: dataDetail.produk,
                            valuta: dataDetail.valuta,
                            // cabang_rekening:
                            //     dataDetail.cabang === null || dataDetail.cabang === ''
                            //         ? '1001'
                            //         : dataDetail.cabang,
                        });
                    } else {
                        setError({ ...error, nomor_rekening: true });
                    }
                });
        };

        const getKodeGL = (value) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };
            fetch(baseUrl + '/teller/general-ledger/' + value, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        let dataDetail = data.list[0];
                        setError({ ...error, kode_gl: false });
                        setGlBodyParam({
                            ...glBodyParam,
                            kode_gl: value,
                            nama_nasabah: dataDetail.nama_gl,
                            valuta: dataDetail.valuta_gl,
                            // cabang_rekening:
                            //     dataDetail.cabang_gl === null || dataDetail.cabang_gl === ''
                            //         ? '1001'
                            //         : dataDetail.cabang_gl,
                        });
                    } else {
                        setError({ ...error, kode_gl: true });
                    }
                });
        };

        const postRekening = () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify({
                    no_rekening: rekeningBodyParam.no_rekening,
                    nama_nasabah: rekeningBodyParam.nama_nasabah,
                    cabang_rekening: rekeningBodyParam.cabang_rekening,
                    valuta: rekeningBodyParam.valuta,
                    jenis_mutasi: rekeningBodyParam.jenis_mutasi,
                    kurs: rekeningBodyParam.kurs,
                    nominal: thousandSeparator.unformat(rekeningBodyParam.nominal),
                    keterangan: rekeningBodyParam.keterangan,
                    header_id: rekeningBodyParam.header_id,
                    tgl_efektif: rekeningBodyParam.tgl_efektif,
                }),
            };
            fetch(baseUrl + '/transaksi-umum/rek/create', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        setModal({
                            ...modal,
                            confirm: false,
                            buatTransaksiRekening: false,
                            success: true,
                        });
                        setMessage(data.status);
                        setRekeningBodyParam({
                            no_rekening: null,
                            nama_nasabah: null,
                            cabang_rekening: '1001',
                            valuta: 'IDR',
                            jenis_mutasi: 'kredit',
                            kurs: 1,
                            nominal: 0,
                            keterangan: null,
                            header_id: queryParams.id,
                            tgl_efektif: today.toISOString().substr(0, 10),
                        });
                        getData();
                    } else {
                        setModal({
                            ...modal,
                            confirm: false,
                            buatTransaksiRekening: false,
                            error: true,
                        });
                        setMessage(data.status);
                    }
                });
        };

        const putRekening = () => {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify({
                    no_rekening: rekeningBodyParam.no_rekening,
                    nama_nasabah: rekeningBodyParam.nama_nasabah,
                    cabang_rekening: rekeningBodyParam.cabang_rekening,
                    valuta: rekeningBodyParam.valuta,
                    jenis_mutasi: rekeningBodyParam.jenis_mutasi,
                    kurs: rekeningBodyParam.kurs,
                    nominal: thousandSeparator.unformat(rekeningBodyParam.nominal),
                    keterangan: rekeningBodyParam.keterangan,
                    header_id: rekeningBodyParam.header_id,
                    tgl_efektif: rekeningBodyParam.tgl_efektif,
                }),
            };
            fetch(baseUrl + '/transaksi-umum/rek/' + editId, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        setModal({
                            ...modal,
                            confirm: false,
                            buatTransaksiRekening: false,
                        });
                        setMessage(data.status);
                        setRekeningBodyParam({
                            no_rekening: null,
                            nama_nasabah: null,
                            cabang_rekening: '1001',
                            valuta: 'IDR',
                            jenis_mutasi: 'kredit',
                            kurs: 1,
                            nominal: 0,
                            keterangan: null,
                            header_id: queryParams.id,
                            tgl_efektif: today.toISOString().substr(0, 10),
                        });
                        getData();
                    } else {
                        setModal({
                            ...modal,
                            confirm: false,
                            buatTransaksiRekening: false,
                            error: true,
                        });
                        setMessage(data.status);
                    }
                });
        };

        const postGL = () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify({
                    kode_gl: glBodyParam.kode_gl,
                    nama_nasabah: glBodyParam.nama_nasabah,
                    cabang_rekening: glBodyParam.cabang_rekening,
                    cabangName_rekening: glBodyParam.cabangName_rekening,
                    valuta: glBodyParam.valuta,
                    jenis_mutasi: glBodyParam.jenis_mutasi,
                    nominal: thousandSeparator.unformat(glBodyParam.nominal),
                    keterangan: glBodyParam.keterangan,
                    header_id: glBodyParam.header_id,
                    tgl_efektif: glBodyParam.tgl_efektif,
                    pusat_biaya: glBodyParam.pusat_biaya,
                    no_referensi: glBodyParam.no_referensi,
                }),
            };
            fetch(baseUrl + '/transaksi-umum/gl/create', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        setModal({
                            ...modal,
                            confirm: false,
                            buatTransaksiGL: false,
                        });
                        setMessage(data.status);
                        setGlBodyParam({
                            kode_gl: null,
                            nama_nasabah: null,
                            cabang_rekening: null,
                            valuta: null,
                            jenis_mutasi: 'kredit',
                            nominal: 0,
                            keterangan: null,
                            header_id: queryParams.id,
                            tgl_efektif: today.toISOString().substr(0, 10),
                            pusat_biaya: null,
                            no_referensi: null,
                        });
                        getData();
                    } else {
                        setModal({
                            ...modal,
                            confirm: false,
                            buatTransaksiGL: false,
                            error: true,
                        });
                        setMessage(data.status);
                    }
                });
        };

        const putGL = () => {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify({
                    kode_gl: glBodyParam.kode_gl,
                    nama_nasabah: glBodyParam.nama_nasabah,
                    cabang_rekening: glBodyParam.cabang_rekening,
                    cabangName_rekening: glBodyParam.cabangName_rekening,
                    valuta: glBodyParam.valuta,
                    jenis_mutasi: glBodyParam.jenis_mutasi,
                    nominal: thousandSeparator.unformat(glBodyParam.nominal),
                    keterangan: glBodyParam.keterangan,
                    header_id: glBodyParam.header_id,
                    tgl_efektif: glBodyParam.tgl_efektif,
                    pusat_biaya: glBodyParam.pusat_biaya,
                    no_referensi: glBodyParam.no_referensi,
                }),
            };
            fetch(baseUrl + '/transaksi-umum/gl/' + editId, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        setModal({
                            ...modal,
                            confirm: false,
                            buatTransaksiGL: false,
                            success: true,
                        });
                        setMessage(data.status);
                        setGlBodyParam({
                            kode_gl: null,
                            nama_nasabah: null,
                            cabang_rekening: null,
                            valuta: null,
                            jenis_mutasi: 'kredit',
                            nominal: 0,
                            keterangan: null,
                            header_id: queryParams.id,
                            tgl_efektif: today.toISOString().substr(0, 10),
                            pusat_biaya: null,
                            no_referensi: null,
                        });
                        getData();
                    } else {
                        setModal({
                            ...modal,
                            confirm: false,
                            buatTransaksiGL: false,
                            error: true,
                        });
                        setMessage(data.status);
                    }
                });
        };

        const patchHeader = () => {
            const requestOptions = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify({
                    status: 'CLOSED',
                }),
            };
            // // console.log(baseUrl + '/transaksi-umum/header/update' + queryParams.id);
            fetch(baseUrl + '/transaksi-umum/header/update/' + queryParams.id, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        // setModal({ ...modal, confirm: false, inputHeader: false, success: true });
                        // setMessage(data.status);
                        history.push(`/teller/transaksi-umum`);
                    } else {
                        setModal({ ...modal, confirm: false, inputHeader: false, error: true });
                        setMessage(data.status);
                    }
                });
        };

        const deleteData = (id) => {
            // // console.log(id)
            const requestOptions = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };
            fetch(baseUrl + '/transaksi-umum/transaksi/' + id, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        setModal({ ...modal, confirm: false, inputHeader: false });
                        setMessage(data.status);
                        getData();
                    } else {
                        setModal({ ...modal, confirm: false, inputHeader: false, error: true });
                        setMessage(data.status);
                    }
                });
        };

        const handlePrint = useReactToPrint({
            content: () => componentRef.current,
            documentTitle: 'Cetak Statement',
            onAfterPrint: () => {},
        });

        const getListDropdowns = (key) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            };
            fetch(baseUrl + '/dropdowns?key=' + key, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    setDropdowns({
                        ...dropdowns,
                        [key]: data.list,
                    });
                });
        };

        return (
            <>
                <div style={{ display: 'none' }}>
                    <ComponentToPrint
                        ref={componentRef}
                        data={data}
                        header={transaksiHeader ?? {}}
                    />
                </div>
                <AppAction ref={appAction} />
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Transaksi Umum</li>
                        <li className="breadcrumb-item">
                            <a href="#">Daftar Transaksi Umum</a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-white">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="mt-1">DAFTAR TRANSAKSI UMUM</h5>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            onClick={handlePrint}
                                            className="btn btn-orange-primary ml-3 px-5 btn-sm"
                                        >
                                            Cetak
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-primary">Header Transaksi</div>
                                        <div className="form-row mt-3">
                                            <div className="form-group col-3">
                                                <label>Kode Cabang</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={transaksiHeader?.cabang}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Tanggal Input</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={transaksiHeader?.created_at?.substr(
                                                        0,
                                                        10
                                                    )}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Tanggal Efektif</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={transaksiHeader?.created_at?.substr(
                                                        0,
                                                        10
                                                    )}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Deskripsi</label>
                                                <textarea
                                                    className="form-control"
                                                    value={transaksiHeader?.keterangan}
                                                    readOnly
                                                ></textarea>
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Balance Status</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={transaksiHeader?.balance_status}
                                                    readOnly
                                                    disabled
                                                />
                                                {/* <select className="form-control" readonly disabled>
                                                    <option>
                                                        {transaksiHeader?.balance_status}
                                                    </option>
                                                </select> */}
                                            </div>
                                            <div className="form-group col-3">
                                                <label>No. Referensi</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={transaksiHeader?.no_referensi}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Total Kredit</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={new Intl.NumberFormat('id-ID').format(
                                                        data?.reduce(
                                                            (prev, curr) =>
                                                                prev + curr?.nominal_kredit ?? 0,
                                                            0
                                                        )
                                                    )}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group col-3">
                                                <label>Total Debit</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={new Intl.NumberFormat('id-ID').format(
                                                        data?.reduce(
                                                            (prev, curr) =>
                                                                prev + curr?.nominal_debit ?? 0,
                                                            0
                                                        )
                                                    )}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-7">
                                        <p>Cari Berdasarkan:</p>
                                        <div className="form-row">
                                            <div className="form-group col-4">
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        setFilter({
                                                            ...filter,
                                                            type: e.target.value,
                                                        });
                                                        filterData(e.target.value);
                                                    }}
                                                >
                                                    <option value="">Jenis Transaksi</option>
                                                    <option value="Rekening">Rekening</option>
                                                    <option value="GL">GL</option>
                                                </select>
                                            </div>
                                            {/* <div className="form-group col-3">
                                                <select className="form-control">
                                                    <option>Status</option>
                                                </select>
                                            </div> */}
                                            <div className="form-group col-4">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cari transaksi umum..."
                                                    onChange={(e) =>
                                                        setFilter({
                                                            ...filter,
                                                            search: e.target.value,
                                                        })
                                                    }
                                                    name="searchText"
                                                    value={filter.search}
                                                />
                                            </div>
                                            <div className="form-group col-3">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => filterData()}
                                                >
                                                    Cari
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5 text-right mt-3">
                                        <br />
                                        <button
                                            className="btn btn-orange-primary"
                                            onClick={() => {
                                                setMode({
                                                    ...mode,
                                                    transaksiRekening: 'create',
                                                });
                                                setModal({ ...modal, buatTransaksiRekening: true });
                                                setRekeningBodyParam({
                                                    no_rekening: null,
                                                    nama_nasabah: null,
                                                    cabang_rekening: '1001',
                                                    cabangName_rekening: 'Head Office',
                                                    valuta: 'IDR',
                                                    jenis_mutasi: 'kredit',
                                                    kurs: 1,
                                                    nominal: 0,
                                                    keterangan: null,
                                                    header_id: queryParams.id,
                                                    tgl_efektif: today.toISOString().substr(0, 10),
                                                });
                                            }}
                                        >
                                            Buat Transaksi Rekening
                                        </button>
                                        <button
                                            className="btn btn-orange-primary ml-2"
                                            onClick={() => {
                                                setMode({
                                                    ...mode,
                                                    transaksiGL: 'create',
                                                });
                                                setModal({ ...modal, buatTransaksiGL: true });
                                                setGlBodyParam({
                                                    kode_gl: null,
                                                    nama_nasabah: null,
                                                    cabang_rekening: '1001',
                                                    cabangName_rekening: 'Head Office',
                                                    valuta: null,
                                                    jenis_mutasi: 'kredit',
                                                    nominal: 0,
                                                    keterangan: null,
                                                    header_id: queryParams.id,
                                                    tgl_efektif: today.toISOString().substr(0, 10),
                                                    pusat_biaya: null,
                                                    no_referensi: null,
                                                });
                                            }}
                                        >
                                            Buat Transaksi GL
                                        </button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <TableList
                                            onChangeShow={(detil) => {
                                                // console.log(detil);
                                                appAction.current.doGet({
                                                    url: `/transaksi-umum/list/${queryParams.id}`,
                                                    vari: 'data',
                                                    withAlert: false,
                                                    param: {
                                                        limit: detil.limit,
                                                        page: detil.page,
                                                    },
                                                });
                                            }}
                                            onNextPage={(detil) => {
                                                // console.log(detil);
                                                appAction.current.doGet({
                                                    url: `/transaksi-umum/list/${queryParams.id}`,
                                                    vari: 'data',
                                                    withAlert: false,
                                                    param: {
                                                        ...detil,
                                                        page: detil.page - 1,
                                                    },
                                                });
                                            }}
                                            onPrevPage={(detil) => {
                                                // console.log(detil);
                                                appAction.current.doGet({
                                                    url: `/transaksi-umum/list/${queryParams.id}`,
                                                    vari: 'data',
                                                    withAlert: false,
                                                    param: {
                                                        ...detil,
                                                        page: detil.page - 1,
                                                    },
                                                });
                                            }}
                                            withCard={false}
                                            columns={[
                                                {
                                                    name: '#',
                                                    field: '#',
                                                    maxWidth: '50px',
                                                },
                                                {
                                                    name: 'Tanggal Input',
                                                    field: 'created_at',
                                                    maxWidth: '50px',
                                                    onRender: function (item) {
                                                        return transaksiHeader?.created_at?.substr(
                                                            0,
                                                            10
                                                        );
                                                    },
                                                },
                                                {
                                                    name: 'Tanggal Efektif',
                                                    field: 'created_at',
                                                    maxWidth: '50px',
                                                    onRender: function (item) {
                                                        return transaksiHeader?.created_at?.substr(
                                                            0,
                                                            10
                                                        );
                                                    },
                                                },
                                                {
                                                    name: 'Nomor Rekening',
                                                    field: 'no_rekening',
                                                    maxWidth: '50px',
                                                },
                                                {
                                                    name: 'Nama Nasabah',
                                                    field: 'nama_nasabah',
                                                    maxWidth: '50px',
                                                },
                                                {
                                                    name: 'Deskripsi',
                                                    field: 'deskripsi',
                                                    maxWidth: '50px',
                                                },
                                                {
                                                    name: 'Jenis Transaksi',
                                                    field: 'jenis_transaksi',
                                                    maxWidth: '50px',
                                                },
                                                {
                                                    name: 'Nominal Debit',
                                                    field: 'nominal_debit',
                                                    maxWidth: '50px',
                                                    onRender: (value) => {
                                                        // console.log(value);
                                                        return new Intl.NumberFormat('id-ID', {
                                                            currency: data?.currencyCode || 'IDR',
                                                            style: 'currency',
                                                        }).format(value.nominal_debit);
                                                    },
                                                },
                                                {
                                                    name: 'Nominal Kredit',
                                                    field: 'nominal_kredit',
                                                    maxWidth: '50px',
                                                    onRender: (value) => {
                                                        // console.log(value);
                                                        return new Intl.NumberFormat('id-ID', {
                                                            currency: data?.currencyCode || 'IDR',
                                                            style: 'currency',
                                                        }).format(value.nominal_kredit);
                                                    },
                                                },
                                            ]}
                                            data={data}
                                            filter={false}
                                            formsFilter={[
                                                {
                                                    type: 'text',
                                                    placeholder: 'Kode Cabang',
                                                    disabled: false,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                },
                                                {
                                                    type: 'text',
                                                    placeholder: 'No. Journal',
                                                    disabled: false,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                },
                                                {
                                                    type: 'date',
                                                    placeholder: 'Tanggal Histori Awal',
                                                    disabled: false,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                },
                                                {
                                                    type: 'date',
                                                    placeholder: 'Tanggal Histori Akhir',
                                                    disabled: false,
                                                    readonly: false,
                                                    onChange: (value) => null,
                                                },
                                            ]}
                                            onFilter={(e) => null}
                                            resetFilter={false}
                                            submitButton="Cari"
                                            actionItems={[
                                                {
                                                    name: 'Edit',
                                                    onClick: (e) => {
                                                        // console.log(e);
                                                        setEditId(e.id);
                                                        if (e.jenis_transaksi === 'Rekening') {
                                                            setMode({
                                                                ...mode,
                                                                transaksiRekening: 'edit',
                                                            });
                                                            setModal({
                                                                buatTransaksiRekening: true,
                                                            });
                                                            setRekeningBodyParam({
                                                                no_rekening: e.no_rekening,
                                                                nama_nasabah: e.nama_nasabah,
                                                                jenis_mutasi:
                                                                    e.nominal_debit === 0
                                                                        ? 'kredit'
                                                                        : 'debit',
                                                                nominal:
                                                                    e.nominal_debit === 0
                                                                        ? e.nominal_kredit
                                                                        : e.nominal_debit,
                                                                keterangan: e.deskripsi,
                                                                tgl_efektif:
                                                                    transaksiHeader?.created_at?.substr(
                                                                        0,
                                                                        10
                                                                    ),
                                                                header_id: queryParams.id,
                                                            });
                                                        } else {
                                                            setMode({
                                                                ...mode,
                                                                transaksiGL: 'edit',
                                                            });
                                                            setModal({
                                                                buatTransaksiGL: true,
                                                            });
                                                            setGlBodyParam({
                                                                kode_gl: e.no_rekening,
                                                                nama_nasabah: e.nama_nasabah,
                                                                jenis_mutasi:
                                                                    e.nominal_debit === 0
                                                                        ? 'kredit'
                                                                        : 'debit',
                                                                nominal:
                                                                    e.nominal_debit === 0
                                                                        ? e.nominal_kredit
                                                                        : e.nominal_debit,
                                                                keterangan: e.deskripsi,
                                                                tgl_efektif:
                                                                    transaksiHeader?.created_at?.substr(
                                                                        0,
                                                                        10
                                                                    ),
                                                                header_id: queryParams.id,
                                                            });
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Hapus',
                                                    onClick: (e) => {
                                                        // console.log(e);
                                                        setShowModalHapus(true);
                                                        setIndexHapus(e.id);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-6"></div>
                                    <div className="col-6 text-right">
                                        {/* <button className="btn btn-outline-info">Simpan</button> */}
                                        {transaksiHeader?.status === 'OPEN' ? (
                                            <button
                                                className="btn btn-primary ml-3 px-5"
                                                onClick={() => {
                                                    setModal({ ...modal, confirm: true });
                                                    setMode({
                                                        ...mode,
                                                        header: 'process',
                                                    });
                                                }}
                                            >
                                                Proses
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={modal.buatTransaksiRekening}
                    onHide={() => {
                        setModal({ ...modal, buatTransaksiRekening: false });
                    }}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {mode.transaksiRekening === 'create' ? 'Input' : 'Edit'}
                            Transaksi Rekening
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bns-form">
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="">Tanggal Efektif</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        readOnly
                                        value={rekeningBodyParam.tgl_efektif}
                                    />
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="">Nomor Jurnal</label>
                                <input type="text" className="form-control" value={''} />
                            </div> */}
                            <div className="form-group">
                                <label className={error.nomor_rekening ? 'text-danger' : ''}>
                                    No. Rekening
                                </label>
                                <div className="form-row">
                                    <div className="col-5 pr0">
                                        <input
                                            type="text"
                                            className={`form-control ${
                                                error.nomor_rekening ? 'is-invalid' : ''
                                            }`}
                                            value={rekeningBodyParam.no_rekening}
                                            onChange={(e) => {
                                                setRekeningBodyParam({
                                                    ...rekeningBodyParam,
                                                    no_rekening: e.target.value,
                                                });
                                                getNomorRekening(e.target.value);
                                            }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <div className="invalid-feedback">
                                            Nomor rekening tidak ditemukan.
                                        </div>
                                    </div>
                                    <div className="col-7 pl0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={rekeningBodyParam.nama_nasabah}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Produk</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={rekeningBodyParam.produk_rekening}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Cabang</label>
                                <div className="form-row">
                                    <div className="col-3 pr0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={rekeningBodyParam.cabang_rekening}
                                            onChange={(e) =>
                                                setRekeningBodyParam({
                                                    ...rekeningBodyParam,
                                                    cabang_rekening: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-9 pl0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={rekeningBodyParam.cabangName_rekening}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Valuta</label>
                                <div className="form-row">
                                    <div className="col-3 pr0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={rekeningBodyParam.valuta}
                                            onChange={(e) =>
                                                setRekeningBodyParam({
                                                    ...rekeningBodyParam,
                                                    valuta: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-9 pl0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value="INDONESIAN RUPIAH"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-row">
                                    <div className="form-group col-4">
                                        <label htmlFor="">Kode Kurs</label>
                                        <input type="text" className="form-control" value="" />
                                    </div>
                                    <div className="form-group col-4">
                                        <label htmlFor="">Nilai Kurs</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value="0"
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group col-4">
                                        <label htmlFor="">{'Jenis Mutasi'}</label>
                                        <select
                                            className="form-control"
                                            value={rekeningBodyParam.jenis_mutasi}
                                            onChange={(e) =>
                                                setRekeningBodyParam({
                                                    ...rekeningBodyParam,
                                                    jenis_mutasi: e.target.value,
                                                })
                                            }
                                        >
                                            <option value="kredit">Kredit</option>
                                            <option value="debit">Debit</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Nominal Transaksi</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={rekeningBodyParam.nominal}
                                    name="nominal"
                                    onChange={(e) => {
                                        handleFormatNumber(e, true, 'rekening');
                                    }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Keterangan</label>
                                <textarea
                                    className="form-control"
                                    rows="1"
                                    value={rekeningBodyParam.keterangan}
                                    onChange={(e) =>
                                        setRekeningBodyParam({
                                            ...rekeningBodyParam,
                                            keterangan: e.target.value,
                                        })
                                    }
                                ></textarea>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setModal({ ...modal, buatTransaksiRekening: false })}
                        >
                            Batal
                        </button>
                        <button
                            className="btn btn-primary ml-3"
                            onClick={() => {
                                // setModal({ ...modal, buatTransaksiRekening: false });
                                mode.transaksiRekening === 'create'
                                    ? postRekening()
                                    : putRekening();
                            }}
                        >
                            Simpan Transaksi
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={modal.buatTransaksiGL}
                    onHide={() => {
                        setModal({ ...modal, buatTransaksiGL: false });
                    }}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {mode.transaksiGL === 'create' ? 'Input' : 'Edit'} Transaksi GL
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bns-form">
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="">Tanggal Efektif</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        readOnly
                                        value={glBodyParam.tgl_efektif}
                                    />
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="">Nomor Jurnal</label>
                                <input type="text" className="form-control" value={''} />
                            </div> */}
                            {/* <div className="form-group">
                                <label htmlFor="">TX Serial No</label>
                                <input type="text" className="form-control" value={''} />
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="" className={error.kode_gl ? 'text-danger' : ''}>
                                    Kode GL
                                </label>
                                {/* <div className="form-row">
                                    <div className="col-5 pr0">
                                        <input
                                            type="text"
                                            className={`form-control ${
                                                error.kode_gl ? 'is-invalid' : ''
                                            }`}
                                            value={glBodyParam.kode_gl}
                                            onChange={(e) => {
                                                setGlBodyParam({
                                                    ...glBodyParam,
                                                    kode_gl: e.target.value,
                                                });
                                                getKodeGL(e.target.value);
                                            }}
                                        />
                                        <div className="invalid-feedback">
                                            Kode GL tidak ditemukan.
                                        </div>
                                    </div>
                                    <div className="col-7 pl0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={glBodyParam.nama_nasabah}
                                            readOnly
                                        />
                                    </div>
                                </div> */}
                                <Select
                                    isLoading={isLoadingGLLists}
                                    options={glOptions}
                                    onChange={(e) => {
                                        var value = e.value.split(' - ');
                                        getKodeGL(value[0]);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Cabang</label>
                                <div className="form-row">
                                    <div className="col-3 pr0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={glBodyParam.cabang_rekening}
                                            onChange={(e) => {
                                                setGlBodyParam({
                                                    ...glBodyParam,
                                                    cabang_rekening: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-9 pl0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={glBodyParam.cabangName_rekening}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Valuta</label>
                                <div className="form-row">
                                    <div className="col-3 pr0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={glBodyParam.valuta}
                                            onChange={(e) => {
                                                setGlBodyParam({
                                                    ...glBodyParam,
                                                    valuta: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-9 pl0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value="INDONESIAN RUPIAH"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Jenis Mutasi</label>
                                <select
                                    className="form-control"
                                    value={glBodyParam.jenis_mutasi}
                                    onChange={(e) =>
                                        setGlBodyParam({
                                            ...glBodyParam,
                                            jenis_mutasi: e.target.value,
                                        })
                                    }
                                >
                                    <option value="kredit">Kredit</option>
                                    <option value="debit">Debit</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Nominal Transaksi</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={glBodyParam.nominal}
                                    name="nominal"
                                    onChange={(e) => {
                                        handleFormatNumber(e, true, 'gl');
                                    }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Keterangan</label>
                                <textarea
                                    className="form-control"
                                    rows="1"
                                    value={glBodyParam.keterangan}
                                    onChange={(e) => {
                                        setGlBodyParam({
                                            ...glBodyParam,
                                            keterangan: e.target.value,
                                        });
                                    }}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Pusat Biaya</label>
                                <select
                                    className={`form-control`}
                                    name="pusat_biaya"
                                    value={glBodyParam.pusat_biaya}
                                    onChange={(e) => {
                                        setGlBodyParam({
                                            ...glBodyParam,
                                            pusat_biaya: e.target.value,
                                        });
                                    }}
                                >
                                    <option value="">Pilih Pusat Biaya</option>
                                    {dropdowns.PUSATBIAYA && dropdowns.PUSATBIAYA.length > 0
                                        ? dropdowns.PUSATBIAYA.map((data, index) => {
                                              return (
                                                  <option
                                                      value={
                                                          data.reference_code +
                                                          ' - ' +
                                                          data.reference_desc
                                                      }
                                                  >
                                                      {data.reference_code} - {data.reference_desc}
                                                  </option>
                                              );
                                          })
                                        : null}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Nomor Referensi</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="no_referensi"
                                    value={glBodyParam.no_referensi}
                                    onChange={(e) => {
                                        setGlBodyParam({
                                            ...glBodyParam,
                                            no_referensi: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setModal({ ...modal, buatTransaksiGL: false })}
                        >
                            Batal
                        </button>
                        <button
                            className="btn btn-primary ml-3"
                            onClick={() => {
                                // setModal({ ...modal, buatTransaksiGL: false });
                                mode.transaksiGL === 'create' ? postGL() : putGL();
                            }}
                        >
                            Simpan Transaksi
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={modal.confirm}
                    onHide={() => {
                        setModal({ ...modal, confirm: false });
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Apakah Anda yakin akan melakukan proses data?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setModal({ ...modal, confirm: false })}
                        >
                            Batal
                        </button>
                        <button className="btn btn-primary ml-3" onClick={() => patchHeader()}>
                            Proses
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={modal.success}
                    onHide={() => {
                        setModal({ ...modal, success: false });
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                                setModal({ ...modal, success: false });
                                // getData();
                            }}
                        >
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={modal.warning}
                    onHide={() => {
                        setModal({ ...modal, warning: false });
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Peringatan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setModal({ ...modal, warning: false })}
                        >
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={modal.error}
                    onHide={() => {
                        setModal({ ...modal, error: false });
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Opps, Terjadi kesalahan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => setModal({ ...modal, error: false })}
                        >
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showModalHapus} onHide={() => setShowModalHapus(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Apakah Anda yakin akan menghapus data ini?</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setShowModalHapus(false)}
                        >
                            Tidak
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                deleteData(indexHapus);
                                setShowModalHapus(false);
                                setIndexHapus(null);
                            }}
                        >
                            Ya
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);
