import React from 'react';
import { useHistory, useParams } from 'react-router';
import { TableList } from '../../../../controls';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import useSelisih, { SelisihProvider } from './useSelisih';
import useDropdowns from '../../../../../hooks/useDropdowns'
import { addCommas, removeNonNumeric } from '../../../../../module';

const SelisihPage = function () {
    const { sState, sAction } = useSelisih();
    const { mode } = useParams();
    const history = useHistory();
    const { JT_SELISIH_PEMBULATAN = [] } = useDropdowns(["JT_SELISIH_PEMBULATAN"]);
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Kas Teller</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Selisih Kas Teller
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-md-6">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title text-base">
                                        Informasi Teller
                                    </div>
                                </div>
                                <div className="card-body">
                                    {
                                        mode === "otorisasi" && (
                                            <div className="box m-3">
                                                <h5>Nomor Referensi : {sState.formData.nomor_referensi}</h5>
                                            </div>
                                        )
                                    }
                                    <div className="form-group">
                                        <label>Valuta</label>
                                        <div className="input-group">
                                            <div className="input-group-append col-3">
                                                <input type="text" className="form-control" disabled value={sState.formData?.kode_valuta} />
                                            </div>
                                            <input type="text" readOnly className="form-control col" value="INDONESIAN RUPIAH" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Saldo Kas</label>
                                        <div className="input-group">
                                            <div className="input-group-append col-3">
                                                <input type="text" className="form-control" disabled value="IDR" />
                                            </div>
                                            <input type="text" className="form-control"
                                                readOnly
                                                value={
                                                    addCommas( parseFloat(sState.formData?.saldo_teller_before || 0).toFixed(2))
                                                    // parseInt(sState.formData?.saldo_teller_before).toLocaleString()+'.00'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Total Denominasi</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control"
                                                readOnly
                                                value={
                                                    addCommas( parseFloat(sState.formData?.total_denom || 0).toFixed(2))
                                                    // parseInt(sState.formData?.total_denom).toLocaleString()+'.00'
                                            }
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Selisih Dengan Saldo</label>
                                        <div className="input-group">
                                            <div className="input-group-append col-3">
                                                <input type="text" className="form-control" disabled value="IDR" />
                                            </div>
                                            <input type="text" className="form-control"
                                                readOnly
                                                value={
                                                    addCommas( parseFloat(sState.formData?.selisih_saldo || 0).toFixed(2))
                                                    // parseInt(sState.formData?.selisih_saldo).toLocaleString()+'.00'
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        Transaksi Selisih Pembulatan
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label>Jenis Transasksi</label>
                                        <select className="form-control" value={sState.formData?.jenis_transaksi} readOnly>
                                            <option value="">Pilih Jenis Transaksi</option>
                                            {
                                                JT_SELISIH_PEMBULATAN.map(({reference_code, reference_desc}, i)=> (
                                                    <option value={reference_code}>
                                                        {reference_desc}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Nilai Transaksi</label>
                                        <div className="input-group">
                                            <input type="text" readOnly className="form-control"
                                                value={
                                                    addCommas( parseFloat(sState.formData?.nominal || 0).toFixed(2))
                                                    // parseInt(sState.formData?.nominal).toLocaleString()+'.00'
                                                }

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="card-footer">
                                    {
                                        sState.formEditable ? <div className="row">
                                            <div className="col-12">
                                                <button disabled={sState.err} className="btn btn-success btn-sm float-right ml-2" onClick={sAction.confirmTrx}>
                                                    Proses
                                                </button>
                                                {/* <button className="btn btn-outline-secondary btn-sm float-right ml-2" onClick={() => history.goBack()}>
                                                    Batal
                                                </button> */}
                                            </div>
                                        </div> : (
                                            mode === "otorisasi" ? (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button  className="btn btn-primary btn-sm float-right ml-2" onClick={()=>sAction.responseOtor("APPROVED")}>
                                                            Approve
                                                        </button>
                                                        <button className="btn btn-danger btn-sm float-right ml-2" onClick={()=>sAction.responseOtor("REJECTED")}>
                                                            Reject
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button className="btn btn-outline-secondary btn-sm float-right ml-2" onClick={() => history.goBack()}>
                                                            Back
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title text-base">
                                Denominasi
                            </div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                pagination={false}
                                filter={false}
                                withActions={false}
                                maxHeight="1500px"
                                columns={[
                                    {
                                        name: 'Nilai Denominasi',
                                        field: 'nilai_denominasi',
                                    },
                                    {
                                        name: 'Jenis',
                                        field: 'jenis',
                                    },
                                    {
                                        name: 'Jumlah Lembar',
                                        field: 'jumlah_lembar_koin',
                                        maxWidth: "100px",
                                        onRender: (item, key) => (
                                            <input
                                                type="text"
                                                value={ addCommas( item.jumlah_lembar_koin)}
                                                readOnly={!sState.formEditable}
                                                className="form-control form-control-sm"
                                                onChange={(e) => sAction.changeJumlahLembar( removeNonNumeric( e.target.value), key)}
                                            />
                                        )
                                    },
                                    {
                                        name: 'Total Nominal',
                                        field: 'total',
                                        className: "text-right",
                                        onRender: (item) => <> {addCommas( parseFloat(item.nilai_denominasi * item.jumlah_lembar_koin || 0 ).toFixed(2))}, -</>
                                    },
                                ]}
                                data={
                                    sState.formData?.denominasi || []
                                }

                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => sAction.setOtorDesc(e.target.value)}
                    value={sState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )
}

export default function () {
    return <SelisihProvider>
        <SelisihPage />
    </SelisihProvider>
}