import { Breadcrumb, Button, Label } from 'semantic-ui-react';
import { FormControl, Modal, Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { TableGrid } from '../../controls';
import { useState, useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePopper } from 'react-popper';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useOutside } from '../../../hooks';
import { IconMeetBalls } from '../../../icon';
import { getErrorEmptyValue, joinDefinedString } from '../../../utils';
import clsx from 'clsx';
import { baseUrl } from '../../../app.config';

const InfoNegatifNasabahPage = () => {
    const queryClient = useQueryClient();

    const [errorFindNasabah, setErrorFindNasabah] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [demonstrateModal, setDemonstrateModal] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [mutationState, setMutationState] = useState({
        nomor_cif: '',
        nama_lengkap: '',
        jenis_nasabah: '',
        alamat: '',
        id: '',
    });
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [error, setError] = useState({});
    const [search, setSearch] = useState('');
    const [statusData, setStatusData] = useState();

    const { data, isPreviousData } = useQuery(
        ['info-negatif-nasabah', limit, currentPage, search, statusData],
        () => {
            let searchType = 'search-name';
            const isCif = !isNaN(Number(search));

            if (isCif) searchType = 'search-cif';

            if (search)
                return fetch(
                    baseUrl +
                        `/nasabah/informasi/negatif/${search}/${searchType}?limit=${limit}&page=${currentPage}`
                ).then((res) => res.json());

            if (statusData)
                return fetch(
                    baseUrl +
                        `/nasabah/informasi/negatif/status/all?limit=${limit}&page=${currentPage}&status=${statusData}`
                ).then((res) => res.json());

            return fetch(
                baseUrl + `/nasabah/informasi/negatif?limit=${limit}&page=${currentPage}`
            ).then((res) => res.json());
        },
        {
            keepPreviousData: true,
        }
    );

    const maxPage = useMemo(() => {
        if (data?.list?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data?.list, limit]);

    // eslint-disable-next-line
    const findNasabah = useCallback(
        debounce(async (nomor_cif, callback) => {
            const res = await fetch(baseUrl + `/nasabah/perorangan/${nomor_cif}`);
            const resGroup = await fetch(
                baseUrl + `/nasabah-badan-usaha/${nomor_cif || ' '}?detailsLevel=FULL`
            );

            const data = await res.json();
            const dataGroup = await resGroup.json();

            if (!res.ok && !dataGroup.list) {
                setMutationState({
                    ...mutationState,
                    nama_lengkap: '',
                    jenis_nasabah: '',
                    alamat: '',
                    nomor_cif,
                });

                callback('error');
            }

            if (data?.list) {
                const { firstName, lastName, middleName, _ktpAddress } = data.list;
                const { ktpAddress, ktpCity, ktpProvince, ktpPostalCode } = _ktpAddress;
                setMutationState({
                    ...mutationState,
                    nama_lengkap: joinDefinedString(firstName, lastName, middleName).value(),
                    jenis_nasabah: 'individu',
                    alamat: joinDefinedString(
                        ktpAddress,
                        ktpCity,
                        ktpProvince,
                        ktpPostalCode
                    ).separateWith(', '),
                    nomor_cif,
                });

                callback('success');
                return;
            }

            if (dataGroup?.list) {
                const { groupName, _identitasNasabah } = dataGroup.list;
                const {
                    nasabahAlamat,
                    nasabahKotaKabupaten,
                    nasabahProvinsi,
                    nasabahKodePos,
                } = _identitasNasabah;
                setMutationState({
                    ...mutationState,
                    nama_lengkap: groupName,
                    jenis_nasabah: 'group',
                    alamat: joinDefinedString(
                        nasabahAlamat,
                        nasabahKotaKabupaten,
                        nasabahProvinsi,
                        nasabahKodePos
                    ).separateWith(', '),
                    nomor_cif,
                });

                callback('success');
                return;
            }
        }, 500),
        [mutationState]
    );

    const handleSearchNasabah = useCallback(
        async (e) => {
            const { value } = e.target;
            await findNasabah(value, (status) => {
                if (status === 'error') setErrorFindNasabah(true);
                else setErrorFindNasabah(false);
            });

            return;
        },
        [findNasabah]
    );

    const handleCreate = useMutation(
        (e) => {
            e.preventDefault();

            const {
                nomor_cif,
                nama_lengkap,
                jenis_nasabah,
                alamat,
                keterangan,
                status_data,
                user_input,
                tgl_input,
            } = e.target.elements;

            const body = {
                nomor_cif: nomor_cif.value,
                nama_lengkap: nama_lengkap.value,
                jenis_nasabah: jenis_nasabah.value,
                alamat: alamat.value,
                keterangan: keterangan.value,
                status_data: status_data.value,
                user_input: user_input.value,
                tgl_input: tgl_input.value,
            };

            if (!keterangan.value || !nomor_cif.value) {
                setError(
                    getErrorEmptyValue({
                        keterangan: keterangan.value,
                        nomor_cif: nomor_cif.value,
                    })
                );
                throw new Error('some field is required!');
            }

            if (errorFindNasabah) throw new Error('not found');

            return fetch(baseUrl + '/nasabah/informasi/negatif/create', {
                method: 'POST',
                body: JSON.stringify(body),
            })
                .then((res) => res.json())
                .catch((e) => console.error(e));
        },
        {
            onSuccess: () => {
                setRegisterModal(false);
                setMutationState({
                    ...mutationState,
                    nama_lengkap: '',
                    jenis_nasabah: '',
                    alamat: '',
                    nomor_cif: '',
                });
                queryClient.refetchQueries({ queryKey: 'info-negatif-nasabah' });
            },
        }
    );

    const handlePeragaan = useMutation(
        (e) => {
            e.preventDefault();

            const {
                nomor_cif,
                nama_lengkap,
                jenis_nasabah,
                alamat,
                keterangan,
                status_data,
                user_input,
                tgl_input,
                user_approve,
                tgl_approve,
                user_delete = {},
                tgl_delete = {},
            } = e.target.elements;

            const body = {
                nomor_cif: nomor_cif.value,
                nama_lengkap: nama_lengkap.value,
                jenis_nasabah: jenis_nasabah.value,
                alamat: alamat.value,
                keterangan: keterangan.value,
                status_data: status_data.value,
                user_input: user_input.value,
                tgl_input: tgl_input.value,
                user_approve: user_approve.value,
                tgl_approve: tgl_approve.value,
                user_delete: user_delete.value,
                tgl_delete: tgl_delete.value,
            };

            if (!keterangan.value) throw new Error('Keterangan field is required!');

            if (demonstrateModal === 'hapus') {
                setMutationState({ ...mutationState, body });
                setDemonstrateModal(false);
                setConfirm(true);
                return;
            }

            return fetch(baseUrl + `/nasabah/informasi/negatif/${mutationState.id}/peragaan`, {
                method: 'PUT',
                body: JSON.stringify(body),
            });
        },
        {
            onSuccess: (data) => {
                if (!data) return;
                setDemonstrateModal(false);
                setMutationState({
                    ...mutationState,
                    nama_lengkap: '',
                    jenis_nasabah: '',
                    alamat: '',
                    id: '',
                    nomor_cif: '',
                });
                queryClient.refetchQueries({ queryKey: 'info-negatif-nasabah' });
            },
        }
    );

    const handleDelete = useMutation(
        () => {
            return fetch(baseUrl + `/nasabah/informasi/negatif/${mutationState.id}/hapus`, {
                method: 'PUT',
                body: JSON.stringify({ ...mutationState, status_data: 'NOTACTIVE' }),
            });
        },
        {
            onSuccess: () => {
                setConfirm(false);
                setMutationState({
                    ...mutationState,
                    nama_lengkap: '',
                    jenis_nasabah: '',
                    alamat: '',
                    id: '',
                    nomor_cif: '',
                });
                queryClient.refetchQueries({ queryKey: 'info-negatif-nasabah' });
            },
        }
    );

    const handleSearch = (e) => {
        e.preventDefault();
        const { search } = e.target.elements;
        if (search.value) setSearch(search.value);
    };

    const handleChangeStatusData = (e) => {
        setStatusData(e.target.value);
    };

    return (
        <>
            <Modal centered show={handleDelete.isSuccess}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Proses Hapus Info Negatif Nasabah berhasil dilakukan
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => handleDelete.reset()}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Selesai
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={confirm}>
                <Modal.Header onHide={() => setConfirm(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Lakukan proses Hapus Info Negatif Nasabah?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setConfirm(false)}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Tidak
                    </Button>
                    <Button
                        loading={handleDelete.isLoading}
                        onClick={() => handleDelete.mutate()}
                        className="tw-w-16"
                        size="mini"
                        color="blue"
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(demonstrateModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setDemonstrateModal(false);
                        handlePeragaan.reset();
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">
                            {demonstrateModal === 'hapus' || !demonstrateModal
                                ? 'Hapus Info Negatif Nasabah'
                                : 'Peragaan Info Negatif Nasabah'}
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <Form onSubmit={(e) => handlePeragaan.mutate(e)} id="formPeragaan">
                        <Form.Group bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Nomor Nasabah
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <div className="tw-grid tw-grid-cols-4 tw-gap-x-2">
                                <Form.Control
                                    className="tw-col-span-3"
                                    size="sm"
                                    id="nomor_cif"
                                    placeholder="Masukkan nomor nasabah"
                                    disabled
                                    defaultValue={mutationState.nomor_cif}
                                />
                                <Form.Control size="sm" disabled />
                            </div>
                        </Form.Group>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-my-4">
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Nama Lengkap
                                </Form.Label>
                                <Form.Control
                                    id="nama_lengkap"
                                    size="sm"
                                    placeholder="Masukkan nama lengkap"
                                    defaultValue={mutationState.nama_lengkap}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Jenis Nasabah
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    size="sm"
                                    placeholder="Pilih jenis nasabah"
                                    id="jenis_nasabah"
                                    defaultValue={mutationState.jenis_nasabah}
                                    disabled
                                >
                                    <option value="" selected hidden disabled>
                                        Pilih jenis nasabah
                                    </option>
                                    <option value="individu">Individu</option>
                                    <option value="group">Badan Usaha</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Alamat
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                rows={3}
                                as="textarea"
                                size="sm"
                                placeholder="Masukkan alamat"
                                id="alamat"
                                disabled
                                defaultValue={mutationState.alamat}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Keterangan
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                id="keterangan"
                                size="sm"
                                placeholder="Masukkan keterangan"
                                isInvalid={handlePeragaan.isError}
                                onChange={() => {
                                    if (handlePeragaan.isError) handlePeragaan.reset();
                                }}
                                defaultValue={mutationState.keterangan}
                            />
                            <Form.Control.Feedback type="invalid">
                                Keterangan wajib diisi!
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">Status Data</Form.Label>
                            <Form.Control
                                id="status_data"
                                as="select"
                                size="sm"
                                placeholder="Aktif"
                                defaultValue={mutationState.status_data}
                                disabled={demonstrateModal === 'hapus'}
                                value={demonstrateModal === 'hapus' ? 'NOTACTIVE' : undefined}
                            >
                                <option value="ACTIVE">Aktif</option>
                                <option value="NOTACTIVE">Non Aktif</option>
                            </Form.Control>
                        </Form.Group>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    User Input
                                </Form.Label>
                                <Form.Control
                                    id="user_input"
                                    disabled
                                    size="sm"
                                    placeholder="BCADEV"
                                    defaultValue={mutationState.user_input}
                                />
                            </Form.Group>
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Tanggal Input
                                </Form.Label>
                                <Form.Control
                                    defaultValue={dayjs(
                                        mutationState.tgl_input || undefined
                                    ).format('YYYY-MM-DD')}
                                    id="tgl_input"
                                    disabled
                                    size="sm"
                                    type="date"
                                />
                            </Form.Group>
                        </div>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    User Approve
                                </Form.Label>
                                <Form.Control
                                    defaultValue={mutationState.user_approve}
                                    id="user_approve"
                                    disabled
                                    size="sm"
                                    placeholder="BCADEV"
                                />
                            </Form.Group>
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Tanggal Approve
                                </Form.Label>
                                <Form.Control
                                    defaultValue={dayjs(mutationState.tgl_approve || '').format(
                                        'YYYY-MM-DD'
                                    )}
                                    id="tgl_approve"
                                    disabled
                                    size="sm"
                                    type="date"
                                />
                            </Form.Group>
                        </div>
                        {(demonstrateModal === 'hapus' || !demonstrateModal) && (
                            <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                                <Form.Group bsPrefix=" ">
                                    <Form.Label className="font-weight-normal f-12">
                                        User Delete
                                    </Form.Label>
                                    <Form.Control
                                        id="user_delete"
                                        disabled
                                        size="sm"
                                        placeholder="BCADEV"
                                        defaultValue={mutationState.defaultValue}
                                    />
                                </Form.Group>
                                <Form.Group bsPrefix=" ">
                                    <Form.Label className="font-weight-normal f-12">
                                        Tanggal Delete
                                    </Form.Label>
                                    <Form.Control
                                        id="tgl_delete"
                                        defaultValue={dayjs(mutationState.tgl_delete || '').format(
                                            'YYYY-MM-DD'
                                        )}
                                        disabled
                                        size="sm"
                                        type="date"
                                    />
                                </Form.Group>
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        onClick={() => {
                            setDemonstrateModal(false);
                            handlePeragaan.reset();
                        }}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Batal
                    </Button>
                    <Button
                        type="submit"
                        loading={handlePeragaan.isLoading}
                        form="formPeragaan"
                        color="blue"
                        size="mini"
                    >
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={registerModal}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setRegisterModal(false);
                        setError({});
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Registrasi Info Negatif Nasabah</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <Form id="registerForm" onSubmit={(e) => handleCreate.mutate(e)}>
                        <Form.Group bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Nomor Nasabah
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                id="nomor_cif"
                                size="sm"
                                placeholder="Masukkan nomor nasabah"
                                onChange={(e) => {
                                    handleSearchNasabah(e);
                                    if (error.nomor_cif)
                                        setError({
                                            ...error,
                                            nomor_cif: false,
                                        });
                                }}
                                isInvalid={
                                    (errorFindNasabah && mutationState.nomor_cif.length > 0) ||
                                    error.nomor_cif
                                }
                                isValid={!errorFindNasabah && mutationState.nomor_cif.length > 0}
                            />
                            <Form.Control.Feedback type="invalid">
                                {error.nomor_cif
                                    ? 'Nomor CIF wajib diisi!'
                                    : 'Nasabah tidak dapat ditemukan'}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-my-4">
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Nama Lengkap
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    id="nama_lengkap"
                                    size="sm"
                                    placeholder="Masukkan nama lengkap"
                                    value={mutationState.nama_lengkap}
                                />
                            </Form.Group>
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Jenis Nasabah
                                </Form.Label>
                                <Form.Control
                                    id="jenis_nasabah"
                                    as="select"
                                    size="sm"
                                    placeholder="Pilih jenis nasabah"
                                    disabled
                                    value={mutationState.jenis_nasabah}
                                >
                                    <option value="" selected hidden disabled>
                                        Pilih Jenis Nasabah
                                    </option>
                                    <option value="individu">Individu</option>
                                    <option value="group">Badan Usaha</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Alamat
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                disabled
                                id="alamat"
                                rows={3}
                                as="textarea"
                                size="sm"
                                placeholder="Masukkan alamat"
                                value={mutationState.alamat}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Keterangan
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                id="keterangan"
                                size="sm"
                                placeholder="Masukkan keterangan"
                                isInvalid={error.keterangan}
                                onChange={() => {
                                    if (error.keterangan)
                                        setError({
                                            ...error,
                                            keterangan: false,
                                        });
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Keterangan wajib diisi!
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">Status Data</Form.Label>
                            <Form.Control
                                id="status_data"
                                as="select"
                                size="sm"
                                placeholder="Aktif"
                            >
                                <option value="" hidden disabled selected>
                                    Pilih Status Data
                                </option>
                                <option value="ACTIVE">Aktif</option>
                                <option value="NOTACTIVE">Non Aktif</option>
                            </Form.Control>
                        </Form.Group>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    User Input
                                </Form.Label>
                                <Form.Control id="user_input" size="sm" placeholder="BCADEV" />
                            </Form.Group>
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Tanggal Input
                                </Form.Label>
                                <Form.Control
                                    defaultValue={dayjs().format('YYYY-MM-DD')}
                                    id="tgl_input"
                                    size="sm"
                                    type="date"
                                />
                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        onClick={() => {
                            setRegisterModal(false);
                            setError({});
                        }}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Batal
                    </Button>
                    <Button
                        loading={handleCreate.isLoading}
                        type="submit"
                        form="registerForm"
                        color="blue"
                        size="mini"
                    >
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-12">
                <Breadcrumb.Section className="text-orange" link>
                    Nasabah / CIF
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">
                    Informasi Negatif Nasabah
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-flex tw-flex-col">
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex w-75 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Status Data"
                                as="select"
                                onChange={handleChangeStatusData}
                            >
                                <option disabled hidden selected={!statusData} value="">
                                    Status Data
                                </option>
                                <option value="ACTIVE">Aktif</option>
                                <option value="NOTACTIVE">Non Aktif</option>
                            </FormControl>
                            <form
                                onSubmit={handleSearch}
                                className="tw-w-full mr-4"
                                id="searchForm"
                            >
                                <FormControl
                                    size="sm"
                                    placeholder="Cari no.rekening, nama..."
                                    id="search"
                                />
                            </form>
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                                onClick={() => {
                                    setSearch('');
                                    setStatusData();
                                    // maybe better using ref, but well you can change it to ref if you want
                                    document.getElementById('searchForm').reset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                type="submit"
                                form="searchForm"
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                            >
                                Cari
                            </Button>
                        </div>
                        <Button
                            className="tw-text-14 tw-py-2 tw-font-normal"
                            size="mini"
                            color="orange"
                            onClick={() => {
                                setRegisterModal(true);
                                setMutationState({
                                    nomor_cif: '',
                                    nama_lengkap: '',
                                    jenis_nasabah: '',
                                    alamat: '',
                                    id: '',
                                });
                            }}
                        >
                            Registrasi Info Negatif
                        </Button>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Nomor Nasabah',
                            field: 'nomor_cif',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                            width: '2fr',
                        },
                        {
                            name: 'Nama Lengkap',
                            field: 'nama_lengkap',
                            width: '2fr',
                        },
                        {
                            name: 'Jenis Nasabah',
                            field: 'jenis_nasabah',
                        },
                        {
                            name: 'User Input',
                            field: 'user_input',
                        },
                        {
                            name: 'Tanggal Input',
                            field: 'tgl_input',
                        },
                        {
                            name: 'User Approve',
                            field: 'user_approve',
                        },
                        {
                            name: '',
                            field: 'action',
                            width: '40px',
                        },
                    ]}
                    data={data?.list || []}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'action')
                            // Component More ada di paling bawah page ini
                            return (
                                <More
                                    onDelete={() => {
                                        setDemonstrateModal('hapus');
                                        setMutationState(data);
                                    }}
                                    onDemonstrate={() => {
                                        setDemonstrateModal(true);
                                        setMutationState(data);
                                    }}
                                    key={`${value}-${field}`}
                                />
                            );

                        if (field === 'tgl_input')
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {dayjs(value).format('DD-MM-YYYY')}
                                </div>
                            );

                        if (field === 'jenis_nasabah')
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {value === 'group' ? 'Badan Usaha' : 'Individu'}
                                </div>
                            );

                        if (!value)
                            return (
                                <div
                                    key={`${value}-${field}`}
                                    className={clsx(className, 'tw-text-center')}
                                >
                                    -
                                </div>
                            );
                    }}
                    isLoading={isPreviousData}
                    config={{
                        overflowY: 'auto',
                        height: '60vh',
                        stickyHead: true,
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-grid tw-grid-cols-3 tw-items-center tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            onChange={(e) => setLimit(e.target.value)}
                            as="select"
                            size="sm"
                            className="tw-border-0 tw-w-max"
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        containerClassName="pagination tw-text-12 tw-m-0 tw-justify-self-center"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-cursor-pointer focus:tw-outline-none tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        initialPage={0}
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12 tw-text-right">
                        Menampilkan {data?.list?.length} data
                    </div>
                </div>
            </div>
        </>
    );
};

export default InfoNegatifNasabahPage;

const More = ({ onDelete, onDemonstrate }) => {
    const [show, setShow] = useState(false);
    const [boxRef, setBoxRef] = useState(null);
    const [triggerRef, setTriggerRef] = useState(null);

    const { styles, attributes } = usePopper(triggerRef, boxRef, {
        placement: 'bottom-end',
    });

    useOutside(boxRef, triggerRef, () => {
        setShow(false);
    });

    const handleDelete = () => {
        if (onDelete) onDelete();

        setShow(false);
    };

    const handleDemonstrate = () => {
        if (onDemonstrate) onDemonstrate();

        setShow(false);
    };

    return (
        <div>
            <button
                ref={(ref) => setTriggerRef(ref)}
                className="tw-border-none tw-bg-transparent hover:tw-bg-black-10 tw-rounded-full tw-h-8 tw-w-8 focus:tw-outline-none"
                onClick={() => setShow(!show)}
            >
                <IconMeetBalls />
            </button>
            {show && (
                <div
                    style={styles.popper}
                    ref={(ref) => setBoxRef(ref)}
                    className="tw-bg-white tw-flex tw-flex-col tw-rounded-lg tw-shadow-lg tw-overflow-hidden"
                    {...attributes}
                >
                    <div
                        role="button"
                        onClick={handleDemonstrate}
                        style={{ borderBottom: '1px solid #f2f2f2' }}
                        className="tw-px-4 tw-py-2 tw-cursor-pointer tw-font-sans tw-bg-transparent tw-text-12 focus:tw-outline-none hover:tw-bg-black-10"
                    >
                        Peragaan Info Negatif
                    </div>
                    <div
                        role="button"
                        onClick={handleDelete}
                        className="tw-px-4 tw-py-2 tw-cursor-pointer tw-font-sans tw-border-0 tw-bg-transparent tw-text-12 tw-text-red focus:tw-outline-none hover:tw-bg-black-10"
                    >
                        Hapus Info Negatif
                    </div>
                </div>
            )}
        </div>
    );
};
