import React, { memo } from 'react';
import { createForm } from '../../../module/createFormList';

export const FormRegistrasi = memo(function({
    itemsForm,
    size = "sm"
}) {
    return(
        <div className="mt-5">
            {
                itemsForm?.map((item, i) => {
                    return (
                        <div key={i} style={{
                            
                            marginBottom:"20px"
                        }}>
                            <div className="row" style={{
                                borderTop: "1pt solid #d2d4d7",
                                // margin: "0px 5px",
                            }}>
                                <div className="col">
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "-15px",
                                            background: "white",
                                            padding: "0px 10px",
                                            left: "0px",
                                            color: "#004bbc",
                                            fontWeight: "bold",
                                            fontSize:"12pt"
                                        }}
                                    >{item.name}</span>

                                    {
                                        (item.toolbar !== null && item.toolbar !== undefined) && (
                                            <div style={{
                                                position: "absolute",
                                                top: "-15px",
                                                padding: "0px 10px",
                                                background:"white",
                                                right: "0px",
                                            }}>
                                            <button 
                                                onClick={item.toolbar?.onClick} 
                                                className="btn btn-sm btn-outline-primary"
                                                style={{
                                                    color: "#004bbc",
                                                    background: "white",
                                                    borderRadius:"7px"
                                                }}
                                            >
                                                {item.toolbar?.name}
                                            
                                            </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row mt-3" style={{
                                // marginLeft: "5px"
                            }}>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                item
                                                    .rows?.filter(v => v.rows === 1)
                                                    .map((form, j) => createForm(form))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                item
                                                    .rows?.filter(v => v.rows === 2)
                                                    .map((form, j) => createForm(form))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
    
})