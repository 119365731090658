import React from 'react';
import { Modal } from 'react-bootstrap';
import { withKeycloak } from '@react-keycloak/web';
import { baseUrl } from './../../../../app.config';
import * as Helpers from './../../../../utils/Helpers';

class AmbilKas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_user: '1',
            reffno: '',
            currency_code: 'IDR',
            eq_value: 1,
            wd_amount_s: '',
            wd_amount_i: 0,
            description: '',
            saldo_kas: 0,
            arrDenoms: [
                { amount_i: 100000, amount_s: '100.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 75000, amount_s: '75.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 50000, amount_s: '50.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 20000, amount_s: '20.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 10000, amount_s: '10.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 5000, amount_s: '5.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 2000, amount_s: '2.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 1000, amount_s: '1.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 1000, amount_s: '1.000', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 500, amount_s: '500', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 500, amount_s: '500', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 200, amount_s: '200', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 100, amount_s: '100', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 50, amount_s: '50', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 25, amount_s: '25', type: 'Koin', count: 0, amount_m: '' },
            ],
        };
    }

    async componentDidMount() {
        const balance = await this.getSaldo();

        this.setState({ saldo_kas: balance });
    }

    async getSaldo() {
        const res = await fetch(baseUrl + '/teller/single/balance');

        const data = await res.json();

        const balance = new Intl.NumberFormat('id-ID').format(data.list?.balance);

        return balance;
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        this.setState({ [name]: value });
    };

    resetValues = () => {
        this.setState({
            wd_amount_s: '',
            reffno: '',
            description: '',
            arrDenoms: [
                { amount_i: 100000, amount_s: '100.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 75000, amount_s: '75.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 50000, amount_s: '50.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 20000, amount_s: '20.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 10000, amount_s: '10.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 5000, amount_s: '5.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 2000, amount_s: '2.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 1000, amount_s: '1.000', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 1000, amount_s: '1.000', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 500, amount_s: '500', type: 'Lembar', count: 0, amount_m: '' },
                { amount_i: 500, amount_s: '500', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 200, amount_s: '200', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 100, amount_s: '100', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 50, amount_s: '50', type: 'Koin', count: 0, amount_m: '' },
                { amount_i: 25, amount_s: '25', type: 'Koin', count: 0, amount_m: '' },
            ],
        });
    };

    calcDenom = (event, idxData) => {
        const value = event.target.value;
        const name = event.target.name;

        var arrDenoms = this.state.arrDenoms;
        var rowData = arrDenoms[idxData];
        rowData.count = value;

        var amount_m_i = rowData.amount_i * value;
        var amount_m_s = '';
        if (value != 0) amount_m_s = Helpers.formatCurrency(amount_m_i, '');
        rowData.amount_m = amount_m_s;

        amount_m_i = 0;
        amount_m_s = '';
        arrDenoms.forEach((iRow, iNum) => {
            if (iRow.count > 0) amount_m_i += iRow.amount_i * iRow.count;
        });
        amount_m_s = Helpers.formatCurrency(amount_m_i, '');

        arrDenoms[idxData] = rowData;
        this.setState({ [name]: arrDenoms });
        this.setState({ ['wd_amount_i']: amount_m_i });
        this.setState({ ['wd_amount_s']: amount_m_s });
    };

    postAmbilKas = async () => {
        if (this.state.wd_amount_i === null || this.state.wd_amount_i <= 0) {
            this.setState({
                showModalWarning: true,
                messageWarning: 'Jumlah Penarikan < 0',
            });
            return false;
        }

        // if (this.state.reffno === null || this.state.reffno == '') {
        //     this.setState({
        //         showModalWarning: true,
        //         messageWarning: 'No. Referensi harus diisi',
        //     });
        //     return false;
        // }

        let body = {
            id_user: this.props.keycloak.idTokenParsed.preferred_username,
            no_referensi: this.state.reffno,
            valuta: this.state.currency_code,
            nilai_ekuivalen: this.state.eq_value,
            nilai_transaksi: this.state.wd_amount_i,
            jumlah: this.state.wd_amount_i * this.state.eq_value,
            keterangan: this.state.description,
        };
        // console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };

        fetch(baseUrl + '/teller/transaksi/kas/ambil-khasanah/create', requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    const balance = await this.getSaldo();
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                        saldo_kas: balance,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                    });
                }
            });
    };

    render() {
        return (
            <>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Kas Teller</li>
                        <li className="breadcrumb-item">
                            <a href="#">Ambil Kas dari Khasanah</a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h5 className="card-title">TRANSAKSI AMBIL KAS DARI KHASANAH</h5>
                            </div>
                            <div className="card-body">
                                <div className="bns-form">
                                    <div className="form-group">
                                        <label htmlFor="">Jumlah Pengambilan</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={this.state.wd_amount_s}
                                            name="wd_amount_s"
                                            onChange={(e) => {
                                                this.handleInputChange(e);
                                            }}
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Saldo Kas Teller</label>
                                        <div className="form-row">
                                            <div className="col-2 pr-0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    value={this.state.currency_code}
                                                    name="currency_code"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-10 pl-0">
                                                <input
                                                    class="form-control"
                                                    value={this.state.saldo_kas}
                                                    name="saldo_kas"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">No. Referensi</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={this.state.reffno}
                                            name="reffno"
                                            onChange={(e) => {
                                                this.handleInputChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Keterangan</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={this.state.description}
                                            name="description"
                                            onChange={(e) => {
                                                this.handleInputChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group text-right">
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                this.resetValues();
                                            }}
                                        >
                                            Batal
                                        </button>
                                        <button
                                            className="btn btn-success ml-3 px-5"
                                            onClick={() => {
                                                this.setState({ showModalConfirm: true });
                                            }}
                                            disabled={this.state.wd_amount_s === ''}
                                            // disabled={
                                            //     this.state.wd_amount_s === '' ||
                                            //     this.state.reffno === '' ||
                                            //     this.state.description === ''
                                            // }
                                        >
                                            Proses
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal
                            show={this.state.showModalConfirm}
                            onHide={() => this.setState({ showModalConfirm: false })}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Konfirmasi</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Lakukan proses Ambil Kas?</Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => this.setState({ showModalConfirm: false })}
                                >
                                    Tidak
                                </button>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.postAmbilKas();
                                    }}
                                >
                                    Ya
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showModalSuccess}
                            onHide={() => this.setState({ showModalSuccess: false })}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Selesai</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Proses Ambil Kas berhasil dilakukan</Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({ showModalSuccess: false });
                                        this.props.history.push(
                                            '/informasi-transaksi/informasi-transaksi-hari-ini'
                                        );
                                    }}
                                >
                                    Selesai
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showModalError}
                            onHide={() => this.setState({ showModalError: false })}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{this.state.messageError}</Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({ showModalError: false });
                                    }}
                                >
                                    Tutup
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showModalWarning}
                            onHide={() => this.setState({ showModalWarning: false })}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Informasi</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{this.state.messageWarning}</Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({ showModalWarning: false });
                                    }}
                                >
                                    Tutup
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <div className="card mt-3">
                            <div className="card-body">
                                <div className="row mt-4 mb-2">
                                    <div className="col-auto text-primary">Informasi Teller</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="bns-form">
                                    <div className="form-group">
                                        <label htmlFor="">Valuta Transaksi</label>
                                        <div className="form-row">
                                            <div className="col-2 pr-0">
                                                <select name="" id="" className="form-control">
                                                    <option value="">IDR</option>
                                                </select>
                                            </div>
                                            <div className="col-10 pl-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value="INDONESIAN RUPIAH"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Posisi Saldo Teller</label>
                                        <div className="form-row">
                                            <div className="col-2 pr-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value="IDR"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-10 pl-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value=""
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="row mt-4 mb-2 px-4 py-2">
                                    <div className="col-auto text-primary">Denominasi</div>
                                    <div className="col">
                                        <hr />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Nilai Denominasi</th>
                                                <th>Jenis</th>
                                                <th>Jumlah Lembar/Koin</th>
                                                <th>Total Nominal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.arrDenoms.map((d, i) => (
                                                <tr>
                                                    <td>{d.amount_s}</td>
                                                    <td>{d.type}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            name="denom_count"
                                                            value={d.count}
                                                            onChange={(e) => {
                                                                this.calcDenom(e, i);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <span name="denom_sum">{d.amount_m}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withKeycloak(AmbilKas);
