import moment from 'moment';
import React, { useEffect } from 'react';
import { TableList } from '../../../../controls';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import ModalDetil from '../../../teller/reversal_transaksi/modal/modalDetil';
import ModalDetilAFT from './modal/detilModal';
import useDataAft, { AftProvider } from './useDataAft';


function DataAftPage() {

    const { aftAction, aftState } = useDataAft();
    useEffect(function () {
        aftAction.auditTrail()
    }, [])
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Tabungan & Giro</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Data AFT (Automatic Funding Transfer)
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                onNextPage={aftAction.nextPage}
                                onPrevPage={aftAction.prevPage}
                                onprevPage={aftAction.prevPage}
                                onChangeShow={aftAction.changeShow}
                                isLoading={aftState.isLoading}
                                data={aftState.dataList}
                                withActions={true}
                                actionItems={
                                    [
                                        {
                                            name:"Detil",
                                            onClick:(item)=>aftAction.openModalDetil(0, item)
                                        }
                                    ]
                                }
                                filter={true}
                                formsFilter={[
                                    {
                                        type:"text",
                                        placeholder:"No Rekening",
                                        onChange:(e) => aftAction.setFilter(prevState => ({ ...prevState, nomor_rekening:e})),
                                        value:aftState.filter?.nomor_rekening
                                    }
                                ]}
                                maxHeight="400px"
                                onFilter={
                                    aftAction.resetPagination
                                }
                                onResetFilter={aftAction.resetFilter}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: 'Rekening Debit',
                                        field: 'nomor_rekening_debit',
                                        maxWidth: '150px',
                                    },
                                    {
                                        name: '',
                                        field: 'nama_rekening_debit',
                                        maxWidth: '150px',
                                    },
                                    {
                                        name: 'Rekening Kredit',
                                        field: 'nomor_rekening_kredit',
                                        maxWidth: '250px',
                                    },
                                    {
                                        name: '',
                                        field: 'nama_rekening_kredit',
                                        maxWidth: '250px',
                                    },
                                    // {
                                    //     name: 'Tgl. Registrasi',
                                    //     field: 'tgl_registrasi',
                                    //     maxWidth: '150px',
                                    //     onRender:(item) => moment(item.tgl_registrasi, "YYYY/MM/DD").format("DD-MM-YYYY")
                                    // },
                                    {
                                        name: 'Tgl. Proses',
                                        field: 'tgl_proses',
                                        maxWidth: '150px',
                                        onRender:(item) => moment(item.tgl_proses).format("DD-MM-YYYY ")
                                    },
                                    // {
                                    //     name: 'Tgl. Proses Berikutnya',
                                    //     field: 'tgl_proses_berikutnya',
                                    //     maxWidth: '150px',
                                    //     onRender:(item) => moment(item.tgl_proses_berikutnya, "YYYY/MM/DD").format("DD-MM-YYYY")
                                    // },
                                    // {
                                    //     name: 'Tgl. Kadaluarsa',
                                    //     field: 'tgl_kadaluarsa',
                                    //     maxWidth: '150px',
                                    //     onRender:(item) => moment(item.tgl_kadaluarsa).format("DD-MM-YYYY HH:MM:SS")
                                    // },
                                    {
                                        name: 'Keterangan',
                                        field: 'keterangan',
                                        maxWidth: '150px',
                                    },
                                    {
                                        name: 'Status',
                                        field: 'status',
                                        maxWidth: '150px',
                                    }

                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalDetilAFT/>
           
        </>
    );
}


export default () => <AftProvider><DataAftPage /></AftProvider>