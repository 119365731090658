import React from "react";
import { Modal } from "react-bootstrap";
import { createForm } from "../../../../../../module/createFormList";
import { FormRegistration } from "../../../../../controls";
import useDeposito from "../useDeposito";

const UbahNisbah = () => {
  const { rekState, rekAction } = useDeposito();
  // const nilaiNisbah = rekState.formData?.nisbah_spesial_max;
  const maxNisbah = 100 - rekState.formData?.nisbah_dasar;
  const minNisbah = -rekState.formData?.nisbah_dasar;

  return (
    <>
      {/* modal utama */}
      <Modal size={"xl"} show={rekState.modalUbahNisbah.show} onHide={rekAction.closeUbahNisbahDeposito}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah Nisbah</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pb-4">
            <div className="box">
              <div className="row">
                <div className="col-4 align-self-center bns-form">
                  <div className="form-group">
                    <label>Nomor Rekening Deposito</label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      placeholder="Otomatis terisi dari sistem"
                      value={rekState?.formData?.nomor_rekening}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h4 className="text-base">Data Nasabah</h4>
              <div className="row">
                <div className="col-md-6">
                  <FormCreated
                    type="text"
                    label="Nama Rekening"
                    required={false}
                    disabled={true}
                    value={rekState.formData.nama_rekening}
                  />
                  <FormCreated
                    type="text"
                    label="Nomor Bilyet"
                    required={false}
                    disabled={true}
                    value={rekState.formData.nomor_bilyet}
                  />
                </div>
                <div className="col-md-6">
                  <FormCreated type="custom">
                    <div className="row">
                      <div className="col-6">
                        <FormCreated
                          type="float"
                          label="Nisbah Spesial"
                          required={false}
                          value={rekState.formData?.nisbah_spesial}
                          onFocusOut={
                            (value) => {
                              if (value > maxNisbah){
                                rekAction.alertNisbah('max');
                              } else if(value < minNisbah){
                                rekAction.alertNisbah('min');
                              }
                              
                              var valueAkhir1 = parseFloat( rekState.otorMode ? rekState.formData?.nisbah_produk : rekState.formData?.nisbah_dasar);
                              var valueAkhir = parseFloat(value) + parseFloat(valueAkhir1);
                              rekAction.changeForm(parseFloat(value).toFixed(2), "nisbah_spesial")
                              rekAction.changeForm(parseFloat(valueAkhir).toFixed(2) , "nisbah_bagi_hasil")
                            }
                          }
                          onChange= {(value) =>{
                                if (value > maxNisbah){
                                  rekAction.alertNisbah('max');
                                } else if(value < minNisbah){
                                  rekAction.alertNisbah('min');
                                }
                                
                                var valueAkhir1 = parseFloat( rekState.otorMode ? rekState.formData?.nisbah_produk : rekState.formData?.nisbah_dasar);
                                var valueAkhir = parseFloat(value) + parseFloat(valueAkhir1);
                                rekAction.changeForm(parseFloat(value), "nisbah_spesial")
                                rekAction.changeForm(parseFloat(valueAkhir).toFixed(2) , "nisbah_bagi_hasil")
                            }}
                          disabled={rekState.otorMode}
                        />
                      </div>
                      <div className="col-6">
                        <FormCreated
                          type="text"
                          label="Nisbah Berdasarkan Produk"
                          required={false}
                          value={
                            rekState.otorMode
                              ? rekState.formData?.nisbah_produk
                              : rekState.formData?.nisbah_dasar
                          }
                          disabled={!rekState.formEditable}
                        />
                      </div>
                    </div>
                  </FormCreated>
                  <FormCreated type="custom">
                    <div className="row">
                      {/* <div className="col-6"></div> */}
                      <div className="col-6">
                        <FormCreated
                          type="float"
                          label="Nisbah Akhir"
                          required={false}
                          value={ 
                            // parseFloat(rekState.formData?.nisbah_akhir)
                            rekState.otorMode
                            ? parseFloat(rekState.formData?.nisbah_akhir)
                            : parseFloat(rekState.formData?.nisbah_bagi_hasil)
                          }
                          disabled={!rekState.formEditable}
                        />
                      </div>
                    </div>
                  </FormCreated>
                  <FormCreated type="custom">
                    <div className="row">
                      <div className="col-6">
                        <FormCreated
                          type="text"
                          label="Pajak Bagi Hasil"
                          required={false}
                          value={
                            rekState.otorMode
                              ? rekState.formData?.pajak_baghas
                              : rekState.formData?.tarif_pajak
                          }
                          disabled={!rekState.formEditable}
                        />
                      </div>
                      <div className="col-6">
                        <FormCreated
                          type="text"
                          label="Zakat Bagi Hasil"
                          required={false}
                          value={
                            rekState.otorMode
                              ? rekState.formData?.zakat_baghas
                              : rekState.formData?.persentase_zakat_bagi_hasil
                          }
                          disabled={!rekState.formEditable}
                        />
                      </div>
                    </div>
                  </FormCreated>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {rekState.otorMode ? (
            <div className="row">
              <div className="col">
                <button
                  onClick={() => rekAction.responseOtor("APPROVED")}
                  className="btn btn-outline-success float-right">
                  Approve
                </button>
              </div>
              <div className="col">
                <button
                  onClick={() => rekAction.responseOtor("REJECTED")}
                  className="btn btn-outline-danger float-right">
                  Reject
                </button>
              </div>
              <div className="col">
                <button
                  onClick={() => rekAction.closeUbahNisbahDeposito()}
                  className="btn btn-link float-right">
                  Tutup
                </button>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col">
                <button
                  onClick={() => rekAction.closeUbahNisbahDeposito()}
                  className="btn btn-outline-danger float-right">
                  Batal
                </button>
              </div>
              <div className="col">
                <button
                  onClick={() =>
                    rekAction.setDataConfirm(
                      {
                        show: true,
                        body: "Apakah anda yakin ingin ubah nisbah?",
                        header: "Konfirmasi",
                        footerButton: [
                          { name: "Ya", onClick: () => rekAction.ubahNisbah(), className: "btn btn-sm btn-success" },
                          { name: "Tidak", onClick: ()=> rekAction.closeConfirm(), className: "btn btn-sm btn-secondary" },
                        ],
                        closeButton: false,
                        witTextArea: false,
                      }
                    )
                  }
                  className="btn btn-outline-success float-right">
                  Simpan
                </button>
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
      {/* end modal utama */}
    </>
  );
};

function FormCreated({ children, ...props }) {
  let option = props;
  if (props.type === "custom") option = { ...props, component: children };
  return <>{createForm({ ...option })}</>;
}
export default UbahNisbah;
