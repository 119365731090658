import React from 'react';
import { Modal } from 'react-bootstrap';
import { baseUrl } from './../../../../app.config';
import * as Helpers from './../../../../utils/Helpers';

class TutupKas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_user: 'teller-1',
            account_no: '',
            branch_code: '',
            branch_name: '',
            currency_code: 'IDR',
            exc_rate: 1,
            balance: 0,
            is_open: '',
        };
    }

    async componentDidMount() {
        // const balance = await this.getSaldo();
        // this.setState({ balance });

        this.getTeller();
    }

    getTeller = async () => {
        const res = await fetch(baseUrl + '/teller/find/teller-1');
        const data = await res.json();
        // // console.log(data.list?.db?.state);
        const balance = new Intl.NumberFormat('id-ID').format(data.list?.mambu?.gl?.balance);

        this.setState({
            balance,
            branch_code: data.list?.mambu?.branch?.id,
            branch_name: data.list?.mambu?.branch?.name,
            is_open: data.list?.db?.state,
        });
    };

    async getSaldo() {
        const res = await fetch(baseUrl + '/teller/single/balance');

        const data = await res.json();

        const balance = new Intl.NumberFormat('id-ID').format(data.list?.balance);

        return balance;
    }

    handleInputChange = (event) => {
        var value = event.target.value;
        const name = event.target.name;

        if (name === 'balance') {
            value = Helpers.formatCurrency(value, '');
        }

        this.setState({ [name]: value });
    };

    postTutupKas = async () => {
        // if (this.state.account_no === null || this.state.account_no == '') {
        //     this.setState({
        //         showModalWarning: true,
        //         messageWarning: 'No. Rekening harus diisi',
        //     });
        //     return false;
        // }

        // if (this.state.balance === null || this.state.balance <= 0) {
        //     this.setState({
        //         showModalWarning: true,
        //         messageWarning: 'Saldo < 0',
        //     });
        //     return false;
        // }

        // let body = {
        //     id_user: this.state.id_user,
        //     no_rekening: this.state.account_no,
        //     cabang_rekening: this.state.branch_code,
        //     valuta: this.state.currency_code,
        //     saldo: Helpers.currencyToInt(this.state.balance),
        //     is_open: this.state.is_open,
        // };
        // // console.log(body);
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         accept: 'application/json',
        //     },
        //     body: JSON.stringify(body),
        // };
        // fetch(baseUrl + '/teller/transaksi/tutup-kas/create', requestOptions)
        //     .then((response) => response.json())
        //     .then((data) => {
        //         // console.log(data);
        //         if (data.message_id === '00') {
        //             this.setState({
        //                 showModalConfirm: false,
        //                 showModalSuccess: true,
        //             });
        //         } else {
        //             this.setState({
        //                 showModalConfirm: false,
        //                 showModalError: true,
        //                 messageError: data.status,
        //             });
        //         }
        //     });

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/teller/state?user_id=teller-1&state=CLOSE', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                    });
                    window.location.reload();
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                    });
                }
            });
    };

    render() {
        return (
            <>
                <div className="row h-100 align-items-center">
                    <div className="col d-flex justify-content-center">
                        <div className="card card-block w-50">
                            <div className="card-header bg-white">
                                <h5 className="card-title">Tutup Kas Teller</h5>
                            </div>
                            <div className="card-body">
                                <div className="bns-form">
                                    {/* <div className="form-group">
                                        <label htmlFor="">No. Rekening</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.account_no}
                                            name="account_no"
                                            onChange={(e) => {
                                                this.handleInputChange(e);
                                            }}
                                        />
                                    </div> */}
                                    <div className="form-group">
                                        <label htmlFor="">Cabang Rekening</label>
                                        <div className="form-row">
                                            <div className="col-3 pr0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.branch_code}
                                                    name="branch_code"
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                    }}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-9 pl0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.branch_name}
                                                    name="branch_name"
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                    }}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Saldo</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.balance}
                                            name="balance"
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Status</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            value={this.state.is_open}
                                        />
                                        {/* <select name="" id="" className="form-control"></select> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-12 text-right">
                                        {/* <button className="btn btn-outline-secondary">Batal</button> */}
                                        <button
                                            className="btn btn-success ml-3 px-3"
                                            onClick={() => {
                                                this.setState({ showModalConfirm: true });
                                            }}
                                            disabled={this.state.is_open === 'CLOSE' ? true : false}
                                        >
                                            Tutup Kas Teller
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal
                            show={this.state.showModalConfirm}
                            onHide={() => this.setState({ showModalConfirm: false })}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Konfirmasi</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Lakukan proses Tutup Kas?</Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => this.setState({ showModalConfirm: false })}
                                >
                                    Tidak
                                </button>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.postTutupKas();
                                    }}
                                >
                                    Ya
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showModalSuccess}
                            onHide={() => this.setState({ showModalSuccess: false })}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Selesai</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Proses Tutup Kas berhasil dilakukan</Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({ showModalSuccess: false });
                                        this.getTeller();
                                        // this.props.history.push(
                                        //     '/informasi-transaksi/informasi-transaksi-hari-ini'
                                        // );
                                    }}
                                >
                                    Selesai
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showModalError}
                            onHide={() => this.setState({ showModalError: false })}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{this.state.messageError}</Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({ showModalError: false });
                                    }}
                                >
                                    Tutup
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showModalWarning}
                            onHide={() => this.setState({ showModalWarning: false })}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Informasi</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{this.state.messageWarning}</Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({ showModalWarning: false });
                                    }}
                                >
                                    Tutup
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </>
        );
    }
}

export default TutupKas;
