import clsx from 'clsx';
import { useState } from 'react';
import { Col, Form, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Breadcrumb, Button, Label, Message } from 'semantic-ui-react';
import { Gap, Stepper, FormHeader, ActionMore } from '../../controls';

const EditRegistrasiTabunganPage = () => {
    const { id } = useParams();
    // console.log(id); // sementara, nanti jangan lupa didelete
    const [activeIdx, setActiveIdx] = useState(0);
    const [specimenPejabatModal, setSpecimenPejabatModal] = useState(false);
    const [relasiNasabahModal, setRelasiNasabahModal] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const handleNext = () => {
        if (activeIdx < 4) setActiveIdx((idx) => idx + 1);
    };

    const handlePrevious = () => {
        if (activeIdx > 0) setActiveIdx((idx) => idx - 1);
    };

    return (
        <>
            <Modal centered show={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Proses registrasi rekening tabungan berhasil dilakukan
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="text-white mr-2" size="mini">
                        Selesai
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={confirm}>
                <Modal.Header onHide={() => setConfirm(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Simpan perubahan data rekening tabungan?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="text-white mr-2" size="mini">
                        Tidak
                    </Button>
                    <Button className="tw-w-16" size="mini" color="blue">
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" centered show={relasiNasabahModal}>
                <Modal.Header
                    onHide={() => setRelasiNasabahModal(false)}
                    style={{ padding: '16px 32px' }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tambah Relasi Nasabah</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '16px 32px' }}>
                    <FormHeader className="mb-4">Data Diri</FormHeader>
                    <Form className="tw-grid tw-grid-cols-2 tw-gap-x-12 tw-gap-y-4">
                        <Form.Group bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Nama (Sesuai Identitas)
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control size="sm" placeholder="Nama nasabah sesuai identitas" />
                        </Form.Group>
                        <Form.Group bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Tanggal Lahir
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control type="date" size="sm" />
                        </Form.Group>
                        <Form.Group bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Jenis Identitas
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control as="select" size="sm" placeholder="Pilih jenis identitas">
                                <option value="1">Project Manager</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Handphone
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control size="sm" placeholder="Masukkan nomor handphone" />
                        </Form.Group>
                        <Form.Group bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Nomor Identitas
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control size="sm" placeholder="Masukkan nomor identitas" />
                        </Form.Group>
                        <Form.Group bsPrefix=" " className="tw-row-span-2">
                            <Form.Label className="font-weight-normal f-12">
                                Keterangan
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                size="sm"
                                rows={3}
                                placeholder="Nama pejabat"
                            />
                        </Form.Group>
                        <Form.Group bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Nama Ibu Kandung
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control size="sm" placeholder="Masukkan nama ibu kandung" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="mr-2 text-white" size="mini">
                        Batal
                    </Button>
                    <Button color="blue" size="mini">
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" centered show={specimenPejabatModal}>
                <Modal.Header
                    onHide={() => setSpecimenPejabatModal(false)}
                    style={{ padding: '16px 32px' }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tambah Hak Akses Rekening</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '16px 32px' }}>
                    <FormHeader className="mb-4">Data Diri</FormHeader>
                    <Form className="tw-grid tw-grid-cols-2 tw-gap-x-12">
                        <Form.Group>
                            <Form.Label className="font-weight-normal f-12">
                                Nama
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control size="sm" placeholder="Nama pejabat" />
                        </Form.Group>
                        <Form.Group className="tw-row-span-2">
                            <Form.Label className="font-weight-normal f-12">
                                Keterangan
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                size="sm"
                                rows={3}
                                placeholder="Nama pejabat"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="font-weight-normal f-12">
                                Jabatan
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control as="select" size="sm" placeholder="Pilih Jabatan">
                                <option value="1">Project Manager</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="mr-2 text-white" size="mini">
                        Batal
                    </Button>
                    <Button color="blue" size="mini">
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="mb-5">
                <Breadcrumb.Section className="text-primary-1">Tabungan & Giro</Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Data Rekening</Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">Ubah Data</Breadcrumb.Section>
            </Breadcrumb>
            <div className="bg-white rounded-lg">
                <div style={{ height: 73 }} className="d-flex justify-content-center bg-gray-7">
                    <Stepper
                        style={{ marginTop: -12 }}
                        steps={[
                            {
                                name: 'Data Rekening',
                                id: '0',
                                whiteSpace: 'nowrap',
                                isDone: true,
                            },
                            {
                                name: 'Informasi Lain',
                                id: '1',
                                whiteSpace: 'nowrap',
                                isDone: true,
                            },
                            {
                                name: 'Specimen Pejabat',
                                id: '2',
                                whiteSpace: 'nowrap',
                                isDone: true,
                            },
                            {
                                name: 'Hak Akses Rekening',
                                id: '3',
                                whiteSpace: 'nowrap',
                                isDone: true,
                            },
                        ]}
                        activeIdx={activeIdx}
                        onStepClick={(idx) => setActiveIdx(idx)}
                        manualDone
                    />
                </div>
                {[0, 1].includes(activeIdx) && (
                    <div className="px-5 py-4">
                        {activeIdx === 0 && (
                            <>
                                <div className="p-4 border border-gray rounded-lg">
                                    <div className="d-flex align-items-end justify-content-between">
                                        <div className="d-flex flex-column w-25">
                                            <span className="f-12 mb-2">Nomor Rekening</span>
                                            <FormControl
                                                size="sm"
                                                disabled
                                                placeholder="Terisi otomatis dari sistem"
                                            />
                                        </div>
                                        <div className="d-flex align-items-center f-12">
                                            <span className="text-black-70 mr-1">Tgl. Buka :</span>
                                            <span className="mr-4">03 Maret 2021</span>
                                            <span className="text-black-70 mr-1">
                                                Kode Cabang :
                                            </span>
                                            <span>0000</span>
                                        </div>
                                    </div>
                                </div>
                                <FormHeader className="mb-4">Data Nasabah</FormHeader>
                                <Row className="mb-3">
                                    <Col>
                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 140px',
                                                gap: 18,
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <div>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Nomor CIF
                                                    <Label className="ml-2" size="mini">
                                                        Wajib
                                                    </Label>
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Masukkan nomor CIF"
                                                />
                                            </div>
                                            <Button size="mini" color="orange">
                                                Cari Nasabah
                                            </Button>
                                        </div>
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Nama Ibu Kandung
                                        </Form.Label>
                                        <Form.Control disabled size="sm" placeholder="Soimah" />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Nama
                                        </Form.Label>
                                        <Form.Control
                                            disabled
                                            size="sm"
                                            placeholder="Muhammad Jundi Amir"
                                        />
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Tempat / Tanggal Lahir
                                        </Form.Label>
                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: '2fr 24px 1fr',
                                            }}
                                        >
                                            <Form.Control
                                                disabled
                                                size="sm"
                                                placeholder="Bandung"
                                            />
                                            <div
                                                style={{ lineHeight: '' }}
                                                className="f-18 text-center"
                                            >
                                                /
                                            </div>
                                            <Form.Control disabled size="sm" type="date" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label className="font-weight-normal f-12">
                                                    No. Telepon
                                                </Form.Label>
                                                <Form.Control disabled size="sm" placeholder="-" />
                                            </Col>
                                            <Col>
                                                <Form.Label className="font-weight-normal f-12">
                                                    No. Handphone
                                                </Form.Label>
                                                <Form.Control
                                                    disabled
                                                    size="sm"
                                                    placeholder="08987xxxxx"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Alamat
                                        </Form.Label>
                                        <Form.Control disabled size="sm" placeholder="Bandung" />
                                    </Col>
                                </Row>
                                <FormHeader className="mb-4">Rekening</FormHeader>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Produk Giro
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control as="select">
                                                <option>1</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Check
                                                className="tw-font-bold tw-text-12"
                                                style={{ marginTop: 30 }}
                                                type="checkbox"
                                                label="Dapat Bonus / Bagi Hasil"
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Nama Rekening
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                placeholder="Masukan nama untuk rekening"
                                            />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Nisbah
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control size="sm" placeholder="0" />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Zakat Bagi Hasil
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control size="sm" placeholder="0" />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Valuta
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                size="sm"
                                                placeholder="Terisi otomatis"
                                            />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Pajak Bagi Hasil
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control size="sm" placeholder="0" />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Nisbah Berdasar Produk
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control size="sm" placeholder="0" />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Keterangan
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                placeholder="Contoh: Tabungan Penerima"
                                            />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Blokir Debet"
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Blokir Kredit"
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Cek Nota"
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Status Passbook"
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Dormant"
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya Rekening Dormant"
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya Administrasi"
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya Saldo Minimum"
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya ATM"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                </Form>{' '}
                            </>
                        )}
                        {activeIdx === 1 && (
                            <>
                                <FormHeader className="mb-4">Informasi Lain</FormHeader>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Sumber Dana
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control size="sm" as="select">
                                                <option>1</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Marketing Referensi
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control size="sm" as="select">
                                                <option>1</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Tujuan Pembukaan Rekening
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control size="sm" as="select">
                                                <option>1</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Marketing Pertama
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control size="sm" as="select">
                                                <option>1</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Kelengkapan Data
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control size="sm" as="select">
                                                <option>1</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Marketing Saat ini
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control size="sm" as="select">
                                                <option>1</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <FormHeader className="mb-4">Asuransi</FormHeader>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Rencana Asuransi Utama
                                            </Form.Label>
                                            <Form.Control size="sm" as="select">
                                                <option>1</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Rencana Asuransi Tambahan
                                            </Form.Label>
                                            <Form.Control size="sm" as="select">
                                                <option>1</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Tanggal Jatuh Tempo
                                            </Form.Label>
                                            <Form.Control size="sm" type="date" />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}></Form.Group>
                                    </Form.Row>
                                    <FormHeader className="mb-4">Alamat Alternatif</FormHeader>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Alamat
                                            </Form.Label>
                                            <Form.Control
                                                style={{ height: 96 }}
                                                as="textarea"
                                                size="sm"
                                                placeholder="Masukkan alamat"
                                            />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kelurahan
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder="Masukkan nama kelurahan"
                                                        size="sm"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kecamatan
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder="Masukkan nama kecamatan"
                                                        size="sm"
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kota / Kab
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder="Masukkan nama kota/kabupaten"
                                                        size="sm"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Provinsi
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder="Masukkan nama provinsi"
                                                        size="sm"
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                RT / RW
                                            </Form.Label>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Control placeholder="RT" size="sm" />
                                                </Form.Group>
                                                <span className="f-18 mx-3 mt-1">/</span>
                                                <Form.Group as={Col}>
                                                    <Form.Control placeholder="RW" size="sm" />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Kode Pos
                                            </Form.Label>
                                            <Form.Control
                                                placeholder="Pilih kode pos"
                                                as="select"
                                                size="sm"
                                            >
                                                <option value="1">1</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}></Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Telepon Rumah
                                            </Form.Label>
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '70px 24px 1fr',
                                                    alignItems: 'center',
                                                    justifyItems: 'center',
                                                }}
                                            >
                                                <Form.Control placeholder="000" size="sm" />
                                                <span className="f-18">-</span>
                                                <Form.Control placeholder="000" size="sm" />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                            </>
                        )}
                    </div>
                )}
                {activeIdx === 2 && (
                    <div className="py-4">
                        <div className="px-5 tw-mb-3">
                            <div className="tw-flex tw-items-center tw-justify-between">
                                <div className="tw-text-primary tw-font-bold tw-text-16">
                                    Data Specimen Pejabat
                                </div>
                                <Button
                                    onClick={() => setSpecimenPejabatModal(true)}
                                    color="orange"
                                    size="mini"
                                >
                                    Tambah Specimen Pejabat
                                </Button>
                            </div>
                        </div>
                        <div style={{ minHeight: 350 }}>
                            <div className="tw-grid tw-grid-cols-4 px-5 tw-py-4 tw-bg-white-10 tw-text-12 tw-font-semibold tw-gap-x-4">
                                <div>Nama Lengkap</div>
                                <div>Jabatan</div>
                                <div>Keterangan</div>
                                <div></div>
                            </div>
                            <div className="tw-grid tw-grid-cols-4 px-5 tw-py-4 tw-text-12 tw-gap-x-4">
                                <div>Tatang Supardi</div>
                                <div>Project Manager</div>
                                <div>
                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eius
                                    suscipit deserunt perspiciatis voluptatem aut fuga expedita quae
                                    maxime, soluta voluptatum, eos magni rem voluptates mollitia
                                    modi corporis! Aliquid, nulla fugiat!
                                </div>
                                <div className="tw-justify-self-end">
                                    <ActionMore />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {activeIdx === 3 && (
                    <>
                        <div className="py-4">
                            <div className="px-5 tw-mb-3">
                                <div className="tw-flex tw-items-center tw-justify-between">
                                    <div className="tw-text-primary tw-font-bold tw-text-16">
                                        Data Hak Akses Rekening
                                    </div>
                                    <Button
                                        onClick={() => setRelasiNasabahModal(true)}
                                        color="orange"
                                        size="mini"
                                    >
                                        Tambah Relasi Nasabah
                                    </Button>
                                </div>
                            </div>
                            <div style={{ minHeight: 350 }}>
                                <div className="tw-grid tw-grid-cols-7 px-5 tw-py-4 tw-bg-white-10 tw-text-12 tw-font-semibold tw-gap-x-4">
                                    <div>Nama Lengkap</div>
                                    <div>Tempat Tanggal Lahir</div>
                                    <div>Nama Ibu Kandung</div>
                                    <div>Identitas</div>
                                    <div>No. Identitas</div>
                                    <div>No. Telepon</div>
                                    <div>Keterangan</div>
                                </div>
                                <div className="tw-grid tw-grid-cols-7 px-5 tw-py-4 tw-text-12 tw-gap-x-4">
                                    <div>Tatang Supardi</div>
                                    <div>03-03-1993</div>
                                    <div>Euis</div>
                                    <div>KTP</div>
                                    <div>327238192837</div>
                                    <div>08769912847</div>
                                    <div className="tw-justify-self-end">
                                        <ActionMore />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tw-px-20 tw-mb-6">
                            <Message className="text-center" warning>
                                Sebelum melanjutkan proses registrasi, pastikan data yang Anda
                                masukan sudah benar.
                            </Message>
                        </div>
                    </>
                )}

                <div className="py-4 px-5 border-top border-gray d-flex align-items-center justify-content-between">
                    <Button className="tw-text-white" size="mini">
                        Batal
                    </Button>
                    <div className="d-flex align-items-center justify-content-between">
                        <Button
                            onClick={handlePrevious}
                            className={clsx('mr-2 tw-transition-none', {
                                'tw-bg-black-40': activeIdx === 0,
                            })}
                            disabled={activeIdx === 0}
                            size="mini"
                            color="orange"
                        >
                            Sebelumnya
                        </Button>
                        <Button
                            disabled={activeIdx === 3}
                            onClick={handleNext}
                            color="orange"
                            size="mini"
                            className={clsx('mr-2 tw-transition-none', {
                                'tw-bg-black-40': activeIdx === 3,
                            })}
                        >
                            Selanjutnya
                        </Button>
                        <Button onClick={() => setConfirm(true)} color="blue" size="mini">
                            Simpan
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditRegistrasiTabunganPage;
