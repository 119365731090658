import clsx from 'clsx';
import { FormCheck, FormControl, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useState, useEffect, useMemo } from 'react';
import { Breadcrumb, Button, Label } from 'semantic-ui-react';
import { TableGrid } from '../../../../controls';
import { baseUrl } from '../../../../../app.config';
import dayjs from 'dayjs';

const RiwayatOtorisasiTransaksi = () => {
    const [detailModal, setDetailModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [idTransaction, setIdTransaction] = useState('');

    useEffect(() => {
        fetch(baseUrl + `/otorisasi/transaksi/all?limit=${limit}&page=${currentPage}`)
            .then((response) => response.json())
            .then((data) => setData(data?.list));
    }, [currentPage, limit]);

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    //Parse data detail modal
    const parseData = (arrayData) => {
        const list = [];

        for (let data of arrayData) {
            const debit = {};
            const kredit = {};

            const formatNumber = (number) =>
                new Intl.NumberFormat('id-ID', {
                    currency: 'IDR',
                }).format(number);

            debit.cabang = data.cabang_debit ? data.cabang_debit : '-';
            debit.nama = data.nama_debit ? data.nama_debit : '-';
            debit.no_rekening = data.no_rekening_debit ? data.no_rekening_debit : '-';
            debit.keterangan = data.keterangan ? data.keterangan : '-';
            debit.valuta = data.valuta_debit ? data.valuta_debit : '-';
            debit.mutasi = 'DEBIT';
            debit.nilai_transaksi = data.nilai_transaksi_debit
                ? formatNumber(data.nilai_transaksi_debit)
                : '-';
            debit.kurs = data.kurs_debit ? data.kurs_debit : '-';
            debit.nilai_ekuivalen = data.nilai_ekuivalen_debit
                ? formatNumber(data.nilai_ekuivalen_debit)
                : '-';

            kredit.cabang = data.cabang_kredit ? data.cabang_kredit : '-';
            kredit.nama = data.nama_kredit ? data.nama_kredit : '-';
            kredit.no_rekening = data.no_rekening_kredit ? data.no_rekening_kredit : '';
            kredit.keterangan = data.keterangan ? data.keterangan : '';
            kredit.valuta = data.valuta_kredit ? data.valuta_kredit : '';
            kredit.mutasi = 'KREDIT';
            kredit.nilai_transaksi = data.nilai_transaksi_kredit
                ? formatNumber(data.nilai_transaksi_kredit)
                : '-';
            kredit.kurs = data.kurs_kredit ? data.kurs_kredit : '-';
            kredit.nilai_ekuivalen = data.nilai_ekuivalen_kredit
                ? formatNumber(data.nilai_ekuivalen_kredit)
                : '-';

            list.push(debit, kredit);
        }
        setActiveData(list);
        return list;
    };

    return (
        <>
            <Modal size="xl" show={Boolean(detailModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setDetailModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Detail Transaksi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <div className="tw-bg-white tw-rounded-lg">
                        <TableGrid
                            columns={[
                                {
                                    name: 'Cabang',
                                    field: 'cabang',
                                    width: '100px',
                                },
                                {
                                    name: 'Nama',
                                    field: 'nama',
                                    width: '100px',
                                },
                                {
                                    name: 'Rekening',
                                    field: 'no_rekening',
                                    width: '100px',
                                },
                                {
                                    name: 'Keterangan',
                                    field: 'keterangan',
                                    width: '100px',
                                },
                                {
                                    name: 'Valuta',
                                    field: 'valuta',
                                    width: '100px',
                                },
                                {
                                    name: 'Mutasi',
                                    field: 'mutasi',
                                    width: '100px',
                                },
                                {
                                    name: 'Nilai',
                                    field: 'nilai_transaksi',
                                    width: '100px',
                                },
                                {
                                    name: 'Kurs',
                                    field: 'kurs',
                                    width: '100px',
                                },
                                {
                                    name: 'Nilai Ekuivalen',
                                    field: 'nilai_ekuivalen',
                                    width: '100px',
                                },
                            ]}
                            data={activeData || []}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                        onClick={() => setDetailModal(false)}
                    >
                        Tutup
                    </Button>
                </Modal.Footer>
            </Modal>

            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Informasi Transaksi
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">
                    Riwayat Otorisasi Transaksi
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Data Riwayat Otorisasi Transaksi
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Jam Input',
                            field: 'created_at',
                        },
                        {
                            name: 'User',
                            field: 'id_user',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                        },
                        {
                            name: 'No. Referensi',
                            field: 'no_referensi',
                        },
                        {
                            name: 'Status Otorisasi',
                            field: 'status_otorisasi',
                        },
                        {
                            field: 'action',
                        },
                    ]}
                    data={data || []}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'created_at')
                            return (
                                <div key={`${value}-${field}`} className={clsx(className)}>
                                    {dayjs(value).format('HH:mm:ss')}
                                </div>
                            );
                        if (field === 'value')
                            return <div className={clsx(className, 'tw-text-right')}>{value}</div>;
                        if (field === 'action')
                            return (
                                <Action
                                    onDetail={() => {
                                        setIdTransaction(data.id);
                                        setDetailModal(true);

                                        fetch(baseUrl + `/otorisasi/transaksi/detail/${data.id}`)
                                            .then((response) => response.json())
                                            .then((data) => parseData(data?.list));
                                    }}
                                />
                            );
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
            </div>
        </>
    );
};

export default RiwayatOtorisasiTransaksi;

const Action = ({ onDetail, onApproved, onRefuse }) => {
    const [show, setShow] = useState(false);

    const handleDetail = () => {
        if (onDetail) onDetail();

        setShow(false);
    };

    return (
        <div>
            <button type="button" class="btn btn-link" onClick={handleDetail}>
                Lihat Detail
            </button>
        </div>
    );
};
