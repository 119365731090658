import React, { useEffect, useState } from 'react';
import useFetch from '../../../../../../hooks/useFetch';
import useFormRegisTabunganRencana from './useFormRegisTabunganRencana';
import { useModalAlert } from '../../../../../controls/modalAlert';

const useDataRekenings = () => {
    const {ftbAction, ftbState} = useFormRegisTabunganRencana()
    const [formModal, setFomrModal] = useState(false)
    const [formRelasi, setFormRelasi] = useState({
        "relasiHubunganDenganNasabah": ""
        
    });
    const [loadingSearch, setLoadingSearch ] = useState(false);
    const { doGet, doPost, wait }  = useFetch();
    const [ pagination, setPagination ] = useState({
        page:1, show: 15
    });
    const [filter, setFilter] = useState({ filterBy: 0, filterType: 0, keyword:"" })
    const [listNasabah, setListNasabah] = useState([])

    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction

    // resetForm
    function resetForm(){
        setFomrModal({
            "relasiHubunganDenganNasabah": "" 
        })
    }

    // close Modal
    function closeModal() { setFomrModal(false) }
    // open Modal Add
    function openModalAdd() {
        // resetForm(); 
        setFomrModal(true);
    }
     // tombol cari
     async function searchNasabah(){
        setLoadingSearch(true)
        var url = `/nasabah/search`;
        if(parseInt(filter.jenis_nasabah) == 1) url = `/nasabah-badan-usaha/search`;
        try {
            const {statusCode, status, remark, statusText, data = {}} = await doPost({
                url: url,
                queryParam: { ... pagination, ...filter,  status_data:"A"}
               
            });

            if(statusCode === 200){
                if(status === "00"){
                    setListNasabah(data?.listNasabah)
                }
                else ftbAction?.setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else ftbAction?.setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            ftbAction?.setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan' , header: "Error", callBack: () => null });
        }
        wait(3000);
        setLoadingSearch(false) 
    }
    useEffect(function(){
        searchNasabah()
    }, [pagination])

     //  reset
     useEffect(function(){
        setListNasabah([])
        getListKodeCabang()
    },[filter])


    // next pagination
    function nextPage({page, limit}){
        setPagination({
            page,
            show:limit
        })
    }

    // prev pagination
    function prevPage({page, limit}){
        setPagination({
            page,
            show:limit
        })
    }
// change show
    function changeShow({page, limit}){
        setPagination({
            page,
            show:limit
        })
    }

    const [listKodeCabang, setListKodeCabang] = useState([]);

    async function getListKodeCabang(){
        try {
            let resp = await doGet({
                url: `/rpc/list-kode-cabang`,
                service: "rpc",
            });
            const { statusCode, remark = "", data = [], status = "01", statusText = "", message = "" } = resp
            // // console.log(resp)
            if (statusCode === 200) {
                if (status === "00") setListKodeCabang(data)
                else setDataAlert({ show: true, body: remark, title: ``, header: "Info", callBack: () => null });
            } else setDataAlert({ show: true, body: `${remark}. ${message}`, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
    }

     // reset pagination
     function resetPagination(){
        setPagination({ page: 1, show: 15 })
    }

    function resetFilter() {
        setFilter({
            keyword: '', jenis_nasabah: "", kode_cabang: ""
        })
    }

    return {
        pdrState: {
            formModal,
            filter,
            loadingSearch,
            pagination,
            listNasabah,
            listKodeCabang
        },
        pdrAction: {
            closeModal,
            openModalAdd,
            setFilter,
            searchNasabah,
            setPagination, 
            nextPage,
            prevPage,
            changeShow,
            resetPagination,
            resetFilter
        }
    };
}

export default useDataRekenings;