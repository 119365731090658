import React, { memo, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Button } from 'semantic-ui-react';

import dayjs from 'dayjs';
import { baseUrl } from '../../../app.config';
import { AppInterfaces } from '../../../app.data';
import { TableGrid } from '../../controls';
import UploadFile from './uploadFile';
import { useKeycloak } from '@react-keycloak/web';
import { thousandSeparator } from '../../../utils';

export default AppInterfaces.appLoad(
    memo(function () {
        const [file, setFile] = useState();
        const [data, setData] = useState({});
        const [valid, setValid] = useState(true);
        const [inValid, setInValid] = useState(false);
        const [confirm, setConfirm] = useState(false);
        const [noRef, setNoRef] = useState('');
        const { keycloak } = useKeycloak();

        const handleProcessDocument = useMutation(async () => {
            const body = new FormData();

            body.append('file', file);

            const res = await fetch(baseUrl + '/teller/upload/gl', {
                method: 'POST',
                body,
            });

            const data = await res.json();

            setData(data.list);
        });

        const handleChange = (e) => {
            if (e.target.name === 'valid') {
                setValid(!valid);
                setInValid(!inValid);
            } else {
                setInValid(!inValid);
                setValid(!valid);
            }
        };

        const handleSubmit = useMutation(
            async () => {
                const body = {
                    id_user: keycloak.idTokenParsed.preferred_username,
                    keterangan: '',
                    no_referensi: noRef,
                    nilai_transaksi: 0,
                    nilai_ekuivalen: 0,
                    jumlah_record_valid: data?.summary?.jumlah_record_valid,
                    jumlah_record_invalid: data?.summary?.jumlah_record_invalid,
                    total_debit: thousandSeparator.unformat(data?.summary?.nominal_total_debit),
                    total_kredit: thousandSeparator.unformat(data?.summary?.nominal_total_kredit),
                    valid_transactions: data?.summary?.valid_transactions,
                };

                const res = await fetch(baseUrl + `/teller/upload/gl/process`, {
                    method: 'POST',
                    body: JSON.stringify(body),
                });

                const status = await res.json();

                if (status.message_id !== '00' || !res.ok) {
                    throw new Error('There are invalid data');
                }
            },
            {
                onError: () => {
                    setConfirm(false);
                },
                onSuccess: () => {
                    setConfirm(false);
                },
            }
        );

        return (
            <>
                <Modal centered show={confirm}>
                    <Modal.Header onHide={() => setConfirm(false)} closeButton>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Lanjutkan proses Upload Transaksi GL?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => {
                                setConfirm(false);
                            }}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Tidak
                        </Button>
                        <Button
                            onClick={() => {
                                handleSubmit.mutate();
                            }}
                            loading={handleSubmit.isLoading}
                            className="text-white mr-2"
                            size="mini"
                            color="blue"
                        >
                            Ya
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={handleSubmit.isSuccess}>
                    <Modal.Header onHide={() => handleSubmit.reset()} closeButton>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">
                                Upload Transaksi GL Berhasil
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Proses upload transaksi GL berhasil dilakukan
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => {
                                handleSubmit.reset();
                                setData({});
                                setFile();
                            }}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Selesai
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={handleSubmit.isError}>
                    <Modal.Header onHide={() => handleSubmit.reset()} closeButton>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Upload Transaksi GL Gagal</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Terdapat {data?.summary?.invalid_transactions?.length} data tidak valid
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => {
                                handleSubmit.reset();
                            }}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Tutup
                        </Button>
                    </Modal.Footer>
                </Modal>
                <nav aria-label="breadcrumb" className="mb-3">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="#">Upload Transaksi</a>
                        </li>
                        {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                        <li className="breadcrumb-item active" aria-current="page">
                            Upload Transaksi GL
                        </li>
                    </ol>
                </nav>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header" style={{ background: 'white' }}>
                                <h4>Import Data</h4>
                            </div>
                            <div className="card-body">
                                <div
                                    style={{ gridTemplateColumns: '1fr auto' }}
                                    className="tw-grid mb-2"
                                >
                                    <UploadFile
                                        onChange={(file) => setFile(file)}
                                        btnName="Cari Dokumen"
                                    />
                                    <Button
                                        loading={handleProcessDocument.isLoading}
                                        onClick={() => handleProcessDocument.mutate()}
                                        color="orange"
                                        size="mini"
                                    >
                                        Proses Baca Document
                                    </Button>
                                </div>

                                <div
                                    className="row"
                                    style={{
                                        borderBottom: '1px solid #E9ECEF',
                                        padding: '10px 0',
                                    }}
                                >
                                    <div className="col-md text-center" style={{ paddingTop: 25 }}>
                                        <span
                                            style={{
                                                verticalAlign: 'middle',
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                                color: '#E6530D',
                                            }}
                                        >
                                            Jumlah Record
                                        </span>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <Form.Label className="tw-text-12 tw-font-normal">
                                                Jumlah Record Valid
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                value={data?.summary?.jumlah_record_valid || ''}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <Form.Label className="tw-text-12 tw-font-normal">
                                                Jumlah Record Tidak Valid
                                            </Form.Label>
                                            <Form.Control
                                                value={data?.summary?.jumlah_record_invalid || ''}
                                                size="sm"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <Form.Label className="tw-text-12 tw-font-normal">
                                                Total Record
                                            </Form.Label>
                                            <Form.Control
                                                value={data?.summary?.jumlah_record || ''}
                                                size="sm"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="row"
                                    style={{
                                        borderBottom: '1px solid #E9ECEF',
                                        padding: '10px 0',
                                    }}
                                >
                                    <div className="col-md text-center" style={{ paddingTop: 25 }}>
                                        <span
                                            style={{
                                                verticalAlign: 'middle',
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                                color: '#E6530D',
                                            }}
                                        >
                                            Nominal Debit
                                        </span>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <Form.Label className="tw-text-12 tw-font-normal">
                                                Total Debit
                                            </Form.Label>
                                            <Form.Control
                                                value={
                                                    data?.summary?.nominal_total_debit
                                                        ? new Intl.NumberFormat('id-ID').format(
                                                              data?.summary?.nominal_total_debit
                                                          )
                                                        : ''
                                                }
                                                size="sm"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md text-center" style={{ paddingTop: 25 }}>
                                        <span
                                            style={{
                                                verticalAlign: 'middle',
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                                color: '#E6530D',
                                            }}
                                        >
                                            Nominal Kredit
                                        </span>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <Form.Label className="tw-text-12 tw-font-normal">
                                                Total Kredit
                                            </Form.Label>
                                            <Form.Control
                                                value={
                                                    data?.summary?.nominal_total_kredit
                                                        ? new Intl.NumberFormat('id-ID').format(
                                                              data?.summary?.nominal_total_kredit
                                                          )
                                                        : ''
                                                }
                                                size="sm"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card tw-text-12">
                            <div className="card-header" style={{ background: 'white' }}>
                                <h4>Daftar Transaksi GL</h4>
                            </div>
                            <div>
                                <div className="tw-flex tw-justify-between tw-items-center tw-p-5">
                                    <div className="float-left">
                                        Tanggal Transaksi : <b> {dayjs().format('DD-MM-YYYY')} </b>
                                    </div>
                                    <div className="tw-flex tw-items-center tw-justify-center tw-gap-4">
                                        <div className="form-group form-group-sm mr-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="valid"
                                                checked={valid}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label">Data Valid</label>
                                        </div>
                                        <div className="form-group form-group-sm">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="invalid"
                                                checked={inValid}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label">
                                                Data Tidak Valid
                                            </label>
                                        </div>
                                    </div>
                                    <div className="tw-flex tw-items-center">
                                        <div className="tw-mr-2 tw-whitespace-nowrap">
                                            No.Referensi :{' '}
                                        </div>
                                        <Form.Control
                                            placeholder="Masukkan No. Referensi"
                                            size="sm"
                                            onChange={(e) => setNoRef(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <TableGrid
                                    columns={[
                                        {
                                            name: 'Kode GL Debit',
                                            field: 'kode_gl_debit',
                                        },
                                        {
                                            name: 'Pusat Biaya',
                                            field: 'pusat_biaya_debit',
                                        },
                                        {
                                            name: 'Valuta',
                                            field: 'valuta_debit',
                                        },
                                        {
                                            name: 'Nominal Debit',
                                            field: 'nominal_debit',
                                        },
                                        {
                                            name: 'Jenis Mutasi',
                                            field: 'jenis_mutasi_debit',
                                        },
                                        {
                                            name: 'Keterangan',
                                            field: 'keterangan_debit',
                                        },
                                        {
                                            name: 'Kode GL Kredit',
                                            field: 'kode_gl_kredit',
                                        },
                                        {
                                            name: 'Pusat Biaya',
                                            field: 'pusat_biaya_kredit',
                                        },
                                        {
                                            name: 'Valuta',
                                            field: 'valuta_kredit',
                                        },
                                        {
                                            name: 'Nominal Kredit',
                                            field: 'nominal_kredit',
                                        },
                                        {
                                            name: 'Jenis Mutasi',
                                            field: 'jenis_mutasi_kredit',
                                        },
                                        {
                                            name: 'Keterangan',
                                            field: 'keterangan_kredit',
                                        },
                                    ]}
                                    config={{
                                        height: '40vh',
                                        stickyHead: true,
                                        overflowY: 'auto',
                                    }}
                                    data={
                                        valid === true
                                            ? Array.from(
                                                  data?.summary?.valid_transactions ?? [],
                                                  ({ debit, kredit }) => ({
                                                      kode_gl_debit: debit.kode_gl,
                                                      pusat_biaya_debit: debit.pusat_biaya,
                                                      valuta_debit: debit.valuta,
                                                      nominal_debit: debit.nominal
                                                          ? new Intl.NumberFormat('id-ID').format(
                                                                debit.nominal
                                                            )
                                                          : '',
                                                      jenis_mutasi_debit: 'Debit',
                                                      keterangan_debit: debit.keterangan,
                                                      kode_gl_kredit: kredit.kode_gl,
                                                      pusat_biaya_kredit: kredit.pusat_biaya,
                                                      valuta_kredit: kredit.valuta,
                                                      nominal_kredit: kredit.nominal
                                                          ? new Intl.NumberFormat('id-ID').format(
                                                                kredit.nominal
                                                            )
                                                          : '',
                                                      jenis_mutasi_kredit: 'Kredit',
                                                      keterangan_kredit: kredit.keterangan,
                                                  })
                                              )
                                            : inValid === true
                                            ? Array.from(
                                                  data?.summary?.invalid_transactions ?? [],
                                                  ({ debit, kredit }) => ({
                                                      kode_gl_debit: debit.kode_gl,
                                                      pusat_biaya_debit: debit.pusat_biaya,
                                                      valuta_debit: debit.valuta,
                                                      nominal_debit: debit.nominal
                                                          ? new Intl.NumberFormat('id-ID').format(
                                                                debit.nominal
                                                            )
                                                          : '',
                                                      jenis_mutasi_debit: 'Debit',
                                                      keterangan_debit: debit.keterangan,
                                                      kode_gl_kredit: kredit.kode_gl,
                                                      pusat_biaya_kredit: kredit.pusat_biaya,
                                                      valuta_kredit: kredit.valuta,
                                                      nominal_kredit: kredit.nominal
                                                          ? new Intl.NumberFormat('id-ID').format(
                                                                kredit.nominal
                                                            )
                                                          : '',
                                                      jenis_mutasi_kredit: 'Kredit',
                                                      keterangan_kredit: kredit.keterangan,
                                                  })
                                              )
                                            : ''
                                    }
                                />
                                <div className="tw-flex tw-justify-end tw-p-5">
                                    <Button
                                        color="blue"
                                        size="small"
                                        onClick={() => setConfirm(true)}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    })
);
