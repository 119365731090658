// import { useKeycloak } from '@react-keycloak/web';
import React, { memo, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import useKeycloak from '../../../hooks/keycloak.module';
import { TableList } from '../../controls';
import CifDashboard from './cifdashboard';
import TrxDashboard from './trxDashboard';
import useDashboard, { DashProvider } from './useDashboard';

const Dasboard = memo(({ store, ...props }) => {
  const { dbAction, dbState } = useDashboard();
  useEffect(function () {
    dbAction.auditTrail()
}, [])

  const { keycloak } = useKeycloak()
  const { doRefreshToken } = keycloak
  return (
    <>
      
      <div className="row">
        <p className="text-primary-light mt-2 ml-3 mb-3 fs-12">
          {/* Data diperbarui 30 juni 2021 12:00:01 */}
           {/* <button onClick={()=>{
            // console.log("re")
            doRefreshToken()
            }}>Refresh TOken</button> */}
        </p>
      </div>
      {dbAction.cekRoles(["cs", "spv cs"]) && <CifDashboard />}
      {dbAction.cekRoles(["teller", "spv teller"]) && <TrxDashboard />}
    </>
  );
});

export default () => <DashProvider><Dasboard /></DashProvider>
