import React, { memo, useRef } from 'react';
import {BasePagePay} from '../../controls';
import { AppAction, AppInterfaces } from '../../../app.data';

const IconHeader = memo(function(){
    return (
        <>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2395 26.4763C8.69403 19.3915 14.9862 12.9848 18.8334 7.81492C19.2456 7.26106 20.0863 7.24951 20.5196 7.78695C24.6441 12.9022 31.6932 18.9088 29.6298 26.4763C27.1367 35.6191 13.0096 35.6191 10.2395 26.4763Z" fill="#FF6F00"/>
                <path d="M29.6298 26.4761C28.3455 31.1861 23.9737 33.4697 19.6577 33.327C24.5833 29.9999 25.9056 21.9237 24.1667 12.0833C26.6568 15.4891 30.8334 19.5833 29.6298 26.4761Z" fill="#FFA040"/>
            </svg>

        </>
    )
})

const nom = [
    {
        id:1,
        providerId:1,
        value:10000,
        price:12000
    },{
        id:2,
        providerId:2,
        value:10000,
        price:12500,
    },{
        id:3,
        providerId:1,
        value:50000,
        price:51000,
    },{
        id:4,
        providerId:2,
        value:50000,
        price:53000,
    },{

        id:5,
        providerId:1,
        value:100000,
        price:105000,
    },
]

const PdamPage_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
    
    return (
        <React.Fragment>
            <AppAction ref={appAction} />
            <BasePagePay
                options = {
                    [
                        {
                            display: "",
                            value: 2,
                            forms: [{
                                type:"select",
                                name: "PDAM",
                                label:"Wilayah",
                                value:store.formData.providerId,
                                onChange:async (val)=> {
                                    const newNominal = await nom.filter(v => v.providerId === parseInt(val))
                                    appAction.current.setForm("providerId", val)
                                    appAction.current.disp({
                                        type:"setNominalList",
                                        nominalList:newNominal
                                    })
                                },
                                displayKey:"name",
                                valueKey:"id",
                                required:true,
                                readOnly:false,
                                options:[
                                    {
                                        name : "Kota Banda Aceh",
                                        id : 1,
                                    },
                                    {
                                
                                        name : "Kota Bandung",
                                        id : 2,
                                    },{
                                
                                        name : "Kab. Bandung",
                                        id : 3,
                                    },{
                                
                                        name : "Kab. Bandung Barat",
                                        id : 4,
                                    }
                                ]
                            },{
                                type: "text",
                                label: "No Pelanggan",
                                name: "PDAM",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange: (val) => appAction.current.setForm("no_tagihan", val),
                                readOnly: false,
                            }],
                            selectNominal: true,
                            onSelectNominal: (val)=>{
                                appAction.current.setOpenPembayaran(true)
                                appAction.current.setNominal(val)
                            },
                            onPay: () => appAction.current.pay(),
                        }
                    ]
                }
                title ={<span className="px-0"><IconHeader /> PDAM</span>}
                nominals={nom}
                paymentCardData={{
                    detilBody: [
                        {
                            key: "Jenis Layanan",
                            value: "Tagihan PDAM"
                        },{
                            key: "Wilayah",
                            value: "Kota Bandung"
                        },{
                            key: "ID Pelanggan",
                            value: "80312241741871"
                        },{
                            key: "Nama Pelanggan",
                            value: "Mayuga Wicaksana"
                        },{
                            key: "Lembar Tagihan",
                            value: "1 Lembar"
                        },{
                            key: "Bulan/Tahun",
                            value: "Jan/2021"
                        },
                    ],
                    detilFooter: [
                        {
                            key: "Harga",
                            value: 235500
                        },
                        {
                            key: "Admin",
                            value: 2500
                        }
                    ],
                    total: 237500
                }}
            />
        </React.Fragment>
    );
});

const PdamPage = AppInterfaces.appLoad(PdamPage_base)
export default PdamPage;