import React, { createContext, useContext } from 'react';


const bmContext = createContext({
    bmState : {},
    bmAction : {}
})
export const BMProvider = ({children}) => {
    return ( <bmContext.Provider value ={{
        bmState : {},
        bmAction : {}
    }}>
        {children}
    </bmContext.Provider> );
}
 
export default function useBranchMonitor(){
    return useContext(BMProvider)
};