import React, { memo } from 'react';
import { AppInterfaces } from '../../../app.data';
import { TableList } from '../../controls';
import UploadFile from './uploadFile'

export default AppInterfaces.appLoad(memo(function () {

    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Upload Transaksi</a></li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">Upload Transaksi GL &amp; Rekening</li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header" style={{ background: "white" }}>
                            <h4>Import Data</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-5">

                                    <label style={{paddingLeft:15}}>Dokumen Transaksi GL</label>
                                    <div className="row mb-2">
                                        <div className="col-md-12">
                                            <UploadFile btnName="Cari Dokumen" />
                                        </div>
                                        <div className="col-md-6" style={{ paddingLeft: 15 }}>
                                            <button className="btn btn-sm btn-primary">Proses Baca Document</button>
                                        </div>
                                    </div>

                                    <div className="row" style={{
                                        borderBottom: "1px solid #E9ECEF",
                                        paddingTop: 10
                                    }}>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label style={{ fontWeight: "normal", fontSize: "9pt" }}>Total Debit</label>
                                                <input disabled={true} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label style={{ fontWeight: "normal", fontSize: "9pt" }}>Total Record Tidak Valid</label>
                                                <input disabled={true} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" style={{
                                        borderBottom: "1px solid #E9ECEF",
                                        paddingTop: 10
                                    }}>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label style={{ fontWeight: "normal", fontSize: "9pt" }}>Total Kredit</label>
                                                <input disabled={true} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label style={{ fontWeight: "normal", fontSize: "9pt" }}>Total Record</label>
                                                <input disabled={true} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7" style={{paddingLeft:25}}>
                                    <label style={{paddingLeft:15}}>Dokumen Transaksi Rekening</label>
                                    <div className="row mb-2">
                                        <div className="col-md-8">
                                            <UploadFile btnName="Cari Dokumen" />
                                        </div>
                                        <div className="col-md-6" style={{ paddingLeft: 15 }}>
                                            <button className="btn btn-sm btn-primary">Proses Baca Document</button>
                                        </div>
                                    </div>
                                    <div className="row" style={{
                                        borderBottom: "1px solid #E9ECEF",
                                        paddingTop: 10
                                    }}>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label style={{ fontWeight: "normal", fontSize: "9pt" }}>Total Debit</label>
                                                <input disabled={true} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label style={{ fontWeight: "normal", fontSize: "9pt" }}>Record Tidak Valid</label>
                                                <input disabled={true} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label style={{ fontWeight: "normal", fontSize: "9pt" }}>No. Referensi</label>
                                                <input disabled={true} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{
                                        borderBottom: "1px solid #E9ECEF",
                                        paddingTop: 10
                                    }}>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label style={{ fontWeight: "normal", fontSize: "9pt" }}>Total Kredit</label>
                                                <input disabled={true} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label style={{ fontWeight: "normal", fontSize: "9pt" }}>Total Record</label>
                                                <input disabled={true} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header" style={{ background: "white" }}>
                            <h4>Daftar Transaksi GL</h4>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col-md-4">
                                    <div className="float-left">Tanggal Transaksi : <b> 14-01-2021 </b></div>
                                </div>
                                <div className="col-md-4 text-center form-inline">
                                    <div className="form-group form-group-sm mr-3">
                                        <input name="test" className="form-check-input" type="radio" />
                                        <label className="form-check-label">Data Valid</label>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <input name="test" className="form-check-input" type="radio" />
                                        <label className="form-check-label">Data Tidak Valid</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="float-right">No.Referensi : <b> RF1234128 </b></div>
                                </div>
                            </div>
                            <TableList
                                columns={[
                                    {
                                        name: "No.",
                                        field: "#",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Tgl. Transaksi",
                                        field: "tgl_transaksi",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Tgl. Valuta",
                                        field: "tgl_valuta",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Kode Area",
                                        field: "kode_area",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Kode Cabang",
                                        field: "kode_cabang",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Kode GL",
                                        field: "kode_gl",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Kode Valuta",
                                        field: "kode_valuta",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "No Referensi",
                                        field: "no_ref",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Jenis Mutasi",
                                        field: "jenis_mutasi",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Keterangan",
                                        field: "ket",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Nominal Transaksi",
                                        field: "nominal_trx",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Nominal Ekuivalen",
                                        field: "nominal_ekv",
                                        maxWidth: "50px"
                                    },
                                ]}
                                pagination={false}
                                withCard={false}
                                withActions={false}
                                filter={false}
                                submitButton="Lihat Daftar Transaksi"
                                data={
                                    [
                                    ]
                                }
                                formsFilter={[
                                    {
                                        type: "date",
                                        placeholder: "from",
                                        disabled: false,
                                        readonly: false,
                                        onChange: (value) => null
                                    }, {
                                        type: "date",
                                        placeholder: "to",
                                        disabled: false,
                                        readonly: false,
                                        onChange: (value) => null
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header" style={{ background: "white" }}>
                            <h4>Daftar Transaksi Rekening</h4>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col-md-4">
                                    <div className="float-left">Tanggal Transaksi : <b> 14-01-2021 </b></div>
                                </div>
                                <div className="col-md-4 text-center form-inline">
                                    <div className="form-group form-group-sm mr-3">
                                        <input name="test" className="form-check-input" type="radio" />
                                        <label className="form-check-label">Data Valid</label>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <input name="test" className="form-check-input" type="radio" />
                                        <label className="form-check-label">Data Tidak Valid</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="float-right">No.Referensi : <b> RF1234128 </b></div>
                                </div>
                            </div>
                            <TableList
                                columns={[
                                    {
                                        name: "#",
                                        field: "#",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Kode Transaksi",
                                        field: "kode_transaksi",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Nomor Rekening",
                                        field: "no_rek",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Nominal",
                                        field: "nominal",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Sign",
                                        field: "sign",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Keterangan",
                                        field: "keterangan",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Status Data",
                                        field: "status",
                                        maxWidth: "50px"
                                    },
                                    {
                                        name: "Keterangan Validasi",
                                        field: "ket_val",
                                        maxWidth: "50px"
                                    },
                                ]}
                                pagination={false}
                                withCard={false}
                                withActions={false}
                                filter={false}
                                submitButton="Lihat Daftar Transaksi"
                                data={
                                    [
                                        {
                                            kode_transaksi: "182020",
                                            no_rek: "12312312312",
                                            nominal: "Rp. 20.000",
                                            sign: "BCAOPR",
                                            keterangan: "SETORAN TUNAI",
                                            status: "Selesai",
                                            ket_val: "Validasi"
                                        }
                                    ]
                                }
                                formsFilter={[
                                    {
                                        type: "date",
                                        placeholder: "from",
                                        disabled: false,
                                        readonly: false,
                                        onChange: (value) => null
                                    }, {
                                        type: "date",
                                        placeholder: "to",
                                        disabled: false,
                                        readonly: false,
                                        onChange: (value) => null
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}))