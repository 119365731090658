class ThousandSeparator {
    thousandSeparatorChecker = (value) => {
        const newValue = String(value).replace(/\./g, '');

        return [!isNaN(newValue), Number(newValue)];
    };

    format(value, opt = {}) {
        let [isNumber, newValue] = this.thousandSeparatorChecker(value);

        if (!isNumber) return 'NaN';

        if (opt.preventNegative && newValue < 0) {
            newValue = newValue * -1;
        }

        return new Intl.NumberFormat('id-ID').format(newValue);
    }

    formatView(value, opt = {}) {
        let [isNumber, newValue] = this.thousandSeparatorChecker(value);

        if (!isNumber) return 'NaN';

        if (opt.preventNegative && newValue < 0) {
            newValue = value * -1;
        } else {
            newValue = value
        }

        return new Intl.NumberFormat('id-ID').format(newValue);
    }

    formatInput(value, opt = {}) {
        var num_parts = value.toString().split(",");
        num_parts[0] = num_parts[0].replace(/\./g, '');
        num_parts[0] = num_parts[0].replace(/[^\d.-]/g, '');
        num_parts[0] = Intl.NumberFormat('id-ID').format(num_parts[0])
        if (num_parts[1] !== undefined) {
            num_parts[1] = num_parts[1].replace(/[^0-9.]/g,"");
            num_parts.length = 2;
            return num_parts.join(",",num_parts[0],num_parts[1])
        } else {
            return num_parts[0]
        }
    }

    unformat(value) {
        const [isNumber, newValue] = this.thousandSeparatorChecker(value);

        if (!isNumber) return 'NaN';

        return Number(newValue);
    }

    add(...args) {
        const isAnyNaN = args.every((val) => isNaN(val));

        if (isAnyNaN) return 'NaN';

        const newValue = args.reduce((prev, curr) => this.unformat(prev) + this.unformat(curr), 0);

        return new Intl.NumberFormat('id-ID').format(newValue);
    }

    subtract(...args) {
        const isAnyNaN = args.every((val) => isNaN(val));

        if (isAnyNaN) return 'NaN';

        const newValue = args.reduce((prev, curr) => this.unformat(prev) - this.unformat(curr), 0);

        return new Intl.NumberFormat('id-ID').format(newValue);
    }

    multiply(...args) {
        const isAnyNaN = args.every((val) => isNaN(val));

        if (isAnyNaN) return 'NaN';

        const newValue = args.reduce((prev, curr) => this.unformat(prev) * this.unformat(curr), 1);

        return new Intl.NumberFormat('id-ID').format(newValue);
    }

    divide(...args) {
        const isAnyNaN = args.every((val) => isNaN(val));

        if (isAnyNaN) return 'NaN';

        const newValue = args.reduce((prev, curr) => this.unformat(prev) / this.unformat(curr), 1);

        return new Intl.NumberFormat('id-ID').format(newValue);
    }
}

export const thousandSeparator = new ThousandSeparator();
