import clsx from 'clsx';
import { useState, forwardRef } from 'react';
import { usePopper } from 'react-popper';
import { useOutside } from '../../../hooks';
import { IconMeetBalls, IconArrowRight } from '../../../icon';
import stylesModules from './action-more.module.css';

const ActionMore = ({
    actions = [
        {
            label: 'Hapus',
            color: 'red',
            onClick: () => {},
            divider: false,
            hidden: false,
            children: [
                {
                    label: 'Children',
                    onClick: () => {},
                    divider: false,
                    hidden: false,
                    color: 'red',
                },
            ],
        },
    ],
    className,
    config = {
        minWidth: 'auto',
    },
    children,
}) => {
    const [show, setShow] = useState(false);
    const [boxRef, setBoxRef] = useState(null);
    const [triggerRef, setTriggerRef] = useState(null);

    const { styles, attributes } = usePopper(triggerRef, boxRef, {
        placement: 'bottom-end',
    });

    useOutside(boxRef, triggerRef, () => {
        setShow(false);
    });

    return (
        <div className={className}>
            <button
                ref={(ref) => setTriggerRef(ref)}
                className={clsx('tw-border-none tw-bg-transparent focus:tw-outline-none', {
                    'hover:tw-bg-black-10 tw-rounded-full tw-h-8 tw-w-8': !children,
                })}
                onClick={() => setShow(!show)}
            >
                {children ?? <IconMeetBalls />}
            </button>
            {show && (
                <div
                    style={{ ...styles.popper, minWidth: config?.minWidth }}
                    ref={(ref) => setBoxRef(ref)}
                    className="tw-bg-white tw-flex tw-flex-col tw-rounded-lg tw-shadow-lg tw-font-normal"
                    {...attributes}
                >
                    {actions?.map(({ label, onClick, divider, color, hidden, children }, i) => {
                        if (hidden) return null;

                        return (
                            <ActionMoreItem
                                onClick={() => {
                                    if (onClick && !children) onClick();
                                    if (!children) setShow(false);
                                }}
                                label={label}
                                divider={divider}
                                color={color}
                                key={`${label}-${i}`}
                                children={children}
                                setParentShow={setShow}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ActionMore;

const ActionMoreChild = forwardRef(({ actions = [], parentRef, setShow, setParentShow }, ref) => {
    const { styles, attributes } = usePopper(parentRef, ref, {
        placement: 'right-start',
    });

    useOutside(
        ref,
        parentRef,
        () => {
            setShow(false);
        },
        'mousemove'
    );

    return (
        <>
            <div
                style={styles.popper}
                className="tw-bg-white tw-flex tw-flex-col tw-rounded-lg tw-shadow-lg tw-overflow-hidden tw-z-50"
                {...attributes}
            >
                {actions?.map(({ label, onClick, divider, color, hidden }, i) => {
                    if (hidden) return null;

                    return (
                        <div
                            key={`${label}-${i}`}
                            role="button"
                            onClick={() => {
                                if (onClick) onClick();
                                setShow(false);
                                if (setParentShow) setParentShow(false);
                            }}
                            className={clsx(
                                'tw-px-4 tw-py-2 tw-cursor-pointer tw-font-sans tw-border-0 tw-bg-transparent tw-text-12 focus:tw-outline-none hover:tw-bg-black-10',
                                {
                                    [stylesModules.divider]: divider,
                                    'tw-text-primary': color === 'primary',
                                    'tw-text-red': color === 'red',
                                    'tw-text-green': color === 'green',
                                    'tw-text-black': !color || color === 'default',
                                    'tw-text-complimentary': color === 'complimentary',
                                }
                            )}
                        >
                            {label}
                        </div>
                    );
                })}
            </div>
        </>
    );
});

const ActionMoreItem = ({ label, onClick, divider, color, children, setParentShow }) => {
    const [parentRef, setParentRef] = useState(null);
    const [show, setShow] = useState(false);
    const [boxRef, setBoxRef] = useState(null);

    useOutside(boxRef, parentRef, () => {
        setShow(false);
    });

    return (
        <>
            <div
                onMouseOver={() => {
                    setShow(true);
                }}
                ref={setParentRef}
                role="button"
                onClick={onClick}
                className={clsx(
                    'tw-px-4 tw-py-2 tw-cursor-pointer tw-font-sans tw-border-0 tw-bg-transparent tw-text-12 focus:tw-outline-none hover:tw-bg-black-10',
                    {
                        [stylesModules.divider]: divider,
                        'tw-text-primary': color === 'primary',
                        'tw-text-red': color === 'red',
                        'tw-text-green': color === 'green',
                        'tw-text-black': !color || color === 'default',
                        'tw-flex tw-justify-between tw-items-center': children,
                        'tw-text-complimentary': color === 'complimentary',
                    }
                )}
            >
                {label}
                {children && <IconArrowRight className="tw-ml-2" />}
            </div>
            {show && (
                <div ref={setBoxRef}>
                    <ActionMoreChild
                        ref={boxRef}
                        setShow={setShow}
                        actions={children}
                        parentRef={parentRef}
                        setParentShow={setParentShow}
                    />
                </div>
            )}
        </>
    );
};
