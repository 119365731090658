import React from "react";
import { Nav, Tab } from "react-bootstrap";
import useSvs, {SVSProvider} from "./useSvs";
import "../style.css";
import AccountImages from "./accountImages";
import NewImages from "./newImages";
import Skeleton from "react-loading-skeleton";
import ModalSVS from './modal/modalSVS'
import ModalAlert from './modal/modalAlert';
import useKeycloak from "../../../../hooks/keycloak.module"
function DataSVSPage() {
  const { svsAction, svsState } = useSvs();
  const { keycloak } = useKeycloak()

  return (
    <>
      <div className="">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mt-2 mb-3">
            <li className="breadcrumb-item color-primary">Nasabah CIF</li>
            <li className="breadcrumb-item">Data Nasabah</li>
            <li className="breadcrumb-item">Nasabah</li>
            <li className="breadcrumb-item">
              Informasi Data (SVS) Rekening Nasabah
            </li>
          </ol>
        </nav>

        <div className="card">
          <div className="card-body">
            <div className="border rounded p-4">
              <h3>{svsState.loading.loadingInfoNasabah ? <Skeleton width={"50%"} height={30} /> : svsState.nasabahInfo.cust?.nama_nasabah}</h3>
              <div className="row">
                <div className="col-3">
                  <div>
                    <span className="text-grey">No. CIF</span>
                    <span className="ml-3 mr-1">:</span>
                    <span className="text-grey2">{svsState.loading.loadingInfoNasabah ? <Skeleton width={"50%"} height={30} /> : svsState.nasabahInfo.cust?.nomor_nasabah}</span>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div>
                    <span className="text-grey">No. SIUPP</span>
                    <span className="ml-3 mr-1">:</span>
                    <span className="text-grey2"></span>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <span className="text-grey">Telp Kantor 1 </span>
                    <span className="ml-3 mr-1">:</span>
                    <span className="text-grey2"></span>
                  </div>
                </div> */}
                {/* <div className="col-3"> 
                  <div>
                    <span className="text-grey">Alamat</span>
                    <span className="ml-3 mr-1">:</span>
                    <span className="text-grey2"></span>
                  </div>
                </div> */}
              </div>

              {/* <div className="row">
                <div className="col-3">
                  <div>
                    <span className="text-grey">No. CIF</span>
                    <span className="ml-3 mr-1">:</span>
                    <span className="text-grey2"></span>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <span className="text-grey"></span>
                    <span className="ml-3 mr-1"></span>
                    <span className="text-grey2"></span>
                  </div>
                </div>

                <div className="col-3">
                  <div>
                    <span className="text-grey">Telp Kantor 2 </span>
                    <span className="ml-3 mr-1">:</span>
                    <span className="text-grey2"></span>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <span className="text-grey"></span>
                    <span className="ml-3 mr-1"></span>
                    <span className="text-grey2"></span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Tab.Container defaultActiveKey="accountImages">
                <Nav fill justify variant="pills" className="svs-nav" >
                  <Nav.Item>
                    <Nav.Link eventKey="accountImages">Account Images</Nav.Link>
                  </Nav.Item>
                  {
                   keycloak.hasRealmRole("cs") &&  <Nav.Item>
                      <Nav.Link eventKey="newImages">New Images</Nav.Link>
                    </Nav.Item>
                  }
                </Nav>
                <div className="mt-5">
                  <Tab.Content>
                      <Tab.Pane eventKey="accountImages">
                        <AccountImages />
                      </Tab.Pane>
                      <Tab.Pane eventKey="newImages">
                        <NewImages />
                      </Tab.Pane>
                  </Tab.Content>
                </div>
            </Tab.Container>
          </div>
        </div>
      </div>
      <ModalAlert />
      <ModalSVS />
    </>
  );
}

export default () => <SVSProvider><DataSVSPage /></SVSProvider>;
