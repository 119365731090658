// import { useKeycloak } from '@react-keycloak/web';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { baseUrl } from '../../../app.config';
import { routes } from '../../../route/routeConfig';
import { Logo } from './logo';
import SideMenu from './sideMenu';
import useSidebar from './useSidebar';
import useKeycloak from '../../../hooks/keycloak.module';
import { useLocalStorage } from '../../../module';
import logoIsiBlack from './logo/logo-black-isi.png'
export default memo(() => {
    const location = useLocation();
    const [keyOpened, setKeyOpened] = useState({});
    const { keycloak } = useKeycloak();
    const { sideAction } = useSidebar()
    const [status, setStatus] = useLocalStorage("STATUS_BDS", {})

    // const { data } = useQuery('status-kas-teller', async () => {
    //     const res = await fetch(baseUrl + '/teller/find/teller-1');

    //     const data = await res.json();

    //     return data.list?.db?.state;
    // });
    const data = {};

    const hasRole = useCallback(
        (roles = []) => {
            // console.log(roles)
            if (roles.length === 0) return true;
            if((keycloak.tokenParsed.realm_access?.roles || []).length <= 0 || keycloak.tokenParsed.realm_access?.roles === null ){
                return roles.some((role) => role === "");
            } 
            return roles.some((role) => keycloak.hasRealmRole(role));
        },
        [keycloak]
    );

    useEffect(()=> {
        sideAction.getParam()
    },[])

    // useEffect(() => {
    //     setKeyOpened(routes.findIndex((v) => v.path === location.pathname));
    // }, [location.pathname]);

    function toggleChild(key) {
        setKeyOpened({
            ...keyOpened,
            [key]: !keyOpened[key],
        });
    }

    function createMenu(options, key, parentKey = '') {
        return !options.hasChild ? (
            <li className="nav-item">
                <SideMenu
                    iconType={options.iconType}
                    icon={options.icon}
                    href={ sideAction.getParam(options.key, options.paramUrl)}
                    key={key}
                    paramUrl={options.paramUrl}
                    // active={ keyOpened }
                >
                    {options.childs?.some((v) => v.key === location.pathname) ||
                    options.key === location.pathname ? (
                        <b> {options.name} </b>
                    ) : (
                        options.name
                    )}
                </SideMenu>
            </li>
        ) : (
            <li
                className={`nav-item has-treeview ${
                    options.childs?.some((v, j) => v.key === keyOpened) ? 'menu-open' : ''
                }`}
            >
                <SideMenu
                    iconType={options.iconType}
                    icon={options.icon}
                    href={sideAction.getParam(options.key, options.paramUrl)}
                    active={keyOpened[sideAction.getParam(options.key, options.paramUrl)]}
                    hasChild={true}
                    childs={options.childs}
                    key={key}
                    onClick={() => {
                        toggleChild(sideAction.getParam(options.key, options.paramUrl));
                    }}
                    paramUrl={options.paramUrl}
                >
                    {options.childs?.some((v) => sideAction.getParam(v.key, v.paramUrl) === location.pathname) ? (
                        <b>{options.name}</b>
                    ) : (
                        options.name
                    )}
                </SideMenu>
            </li>
        );
    }

    return (
        <>
            <aside className="main-sidebar light-primary elevation-1">
                <Link
                    to="/"
                    className="brand-link text-center"
                    style={{ background: '#ffffff', padding: '4px 0.5rem' }}
                >
                    {/* <Logo style={{ opacity: 0.8 }} /> */}
                    {/* <span className="brand-text font-weight-light">AdminLTE 3</span> */}
                    <img src={logoIsiBlack} className="" style={{ width:200, marginBottom:25 }} />
                </Link>
                <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                >
                    {routes
                        .filter((v) => v.type !== 2 && hasRole(v.roles))
                        .map((nav, key) =>{
                            
                            return hasRole(nav.roles) || nav.key === '/' ? (
                                nav.type === 0 ? (
                                    <>
                                        <li
                                            key={key}
                                            style={{ height: 0, padding: 0 }}
                                            className="nav-header"
                                        >
                                            {nav.name}
                                        </li>
                                        {nav.childs
                                            ?.filter((v) => {
                                                if (hasRole(['cs']) && data === 'CLOSE') {
                                                    return (
                                                        v.type !== 2 &&
                                                        ![
                                                            '/transaksi-nasabah',
                                                            '/teller/transaksi-umum',
                                                        ].includes(v.key) &&
                                                        hasRole(v.roles)
                                                    );
                                                }

                                                if (hasRole(['teller']) && data === 'CLOSE') {
                                                    return (
                                                        v.type !== 2 &&
                                                        [
                                                            '/teller/kas-teller',
                                                            '/informasi-transaksi',
                                                            '/query',
                                                        ].includes(v.key) &&
                                                        hasRole(v.roles)
                                                    );
                                                }

                                                return v.type !== 2 && hasRole(v.roles);
                                            })
                                            .map((c, i) =>{
                                                return createMenu(
                                                    {
                                                        ...c,
                                                        hasChild:
                                                            Array.isArray(c.childs) &&
                                                            c.childs.some((c) => c.type !== 2),
                                                    },
                                                    key.toString() + i.toString(),
                                                    key
                                                )
                                            })}
                                    </>
                                ) : (
                                    createMenu(
                                        {
                                            ...nav,
                                            hasChild: Array.isArray(nav.childs)
                                                ? nav.childs.some((c) => c.type !== 2)
                                                : false,
                                        },
                                        key
                                    )
                                )
                            ) : null
                        })}
                </ul>
            </aside>
        </>
    );
});
