import React, { useState, useEffect, memo } from 'react';
import moment from 'moment';
import useRekening from '../useRekening';
import { TableList } from '../../../../../controls';
import { addCommas } from '../../../../../../module';

const ExcelStatement = memo((props) => {
    const { rekState, rekAction } = useRekening();
    const { dataCetakStatement = {} } = rekState;

    return (
        <>
            <table className="w-100 f-10" id="table-to-xls" style={{ display: "none" }}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td width="12%" className="py-0 my-0">Cabang</td>
                        <td className="py-0 my-0">: {dataCetakStatement?.nama_cabang}</td>
                        <td width="10%" className="py-0 my-0">Tanggal Cetak</td>
                        <td className="py-0 my-0">: {moment(Date.now()).format("YYYY-MM-DD")}</td>
                    </tr>
                    <tr>
                        <td className="py-0 my-0">Nomor Rekening</td>
                        <td className="py-0 my-0">: {dataCetakStatement?.nomor_rekening}</td>
                        <td className="py-0 my-0">Nama</td>
                        <td className="py-0 my-0">: {dataCetakStatement?.nama_lengkap}</td>
                    </tr>
                    <tr>
                        <td className="py-0 my-0">Mata Uang</td>
                        <td className="py-0 my-0">: {dataCetakStatement?.valuta}</td>
                        <td className="py-0 my-0">Alamat</td>
                        <td rowSpan={2} valign="top" className="py-0 my-0">: {dataCetakStatement?.alamat}</td>
                    </tr>
                    <tr>
                        <td className="py-0 my-0">NPWP</td>
                        <td className="py-0 my-0">: {dataCetakStatement?.trx?.npwp}</td>
                    </tr>
                    <tr>
                        <td width='20%' className="py-0 my-0">Periode</td>
                        <td className="py-0 my-0">: {dataCetakStatement?.periodeA} s/d {dataCetakStatement?.periodeB}</td>
                        <td className="py-0 my-0">Nama Produk</td>
                        <td className="py-0 my-0">: {dataCetakStatement?.nama_produk}</td>
                    </tr>

                    <tr>
                        <td width='20%' className="py-0 my-0"></td>
                        <td className="py-0 my-0"></td>
                        <td className="py-0 my-0"></td>
                        <td className="py-0 my-0"></td>
                    </tr>

                    <tr>
                        <td width='20%' className="py-0 my-0"></td>
                        <td className="py-0 my-0"></td>
                        <td className="py-0 my-0"></td>
                        <td className="py-0 my-0"></td>
                    </tr>
                </tbody>

                <thead>
                    <tr>
                        <th>Tanggal</th>
                        <th>Kode Transaksi</th>
                        <th>Uraian</th>
                        <th>Debet</th>
                        <th>Kredit</th>
                        <th>Saldo</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (dataCetakStatement?.trx?.list || []).map((d, k) => (
                            <tr>
                                <td>{d.tanggal_transaksi}</td>
                                <td>{d.jenis_transaksi}</td>
                                <td>{d.Keterangan}</td>
                                <td>{d.jenis_mutasi === "DEBIT" ? addCommas(parseFloat(d.nilai_mutasi || 0).toFixed(2)) : ''}</td>
                                <td>{d.jenis_mutasi === "KREDIT" ? addCommas(parseFloat(d.nilai_mutasi || 0).toFixed(2)) : ''}</td>
                                <td>{addCommas(parseFloat(d.saldo_akhir).toFixed(2))}</td>
                            </tr>
                        ))
                    }
                </tbody>

                <thead>

                    <tr>
                        <th></th>
                    </tr>

                    <tr>
                        <th></th>
                    </tr>

                    <tr>
                        <th></th>
                    </tr>

                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td>SALDO AWAL</td>
                        <td className="text-right pr-1">
                            {addCommas(parseFloat(dataCetakStatement?.trx?.saldoAwal || 0).toFixed(2))}
                        </td>
                    </tr>
                    <tr>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td className="">MUTASI DB</td>
                        <td className="text-right pr-1">
                            {addCommas(parseFloat(dataCetakStatement?.trx?.debit || 0).toFixed(2))}
                        </td>
                    </tr>
                    <tr>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td className="">MUTASI KR</td>
                        <td className="text-right pr-1">
                            {addCommas((dataCetakStatement?.trx?.kredit || 0).toFixed(2))}
                        </td>
                    </tr>
                    <tr>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td className="">SALDO AKHIR</td>
                        <td className="text-right pr-1">
                            {addCommas(parseFloat(dataCetakStatement?.trx?.saldoAkhir || 0).toFixed(2))}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="6" className="text-center"></td>
                    </tr>
                    <tr>
                        <td colSpan="6" className="text-center" style={{borderBottom:"1px solid black"}}></td>
                    </tr>
                    <tr>
                        <td colSpan="6" className="text-center"></td>
                    </tr>
                    <tr>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td colSpan="2" className="text-center pt-4" style={{
                            textAlign:"center"
                        }}>
                            ....................., .....................
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" className="text-center"></td>
                    </tr>
                    <tr>
                        <td colSpan="3" className="text-center"></td>
                    </tr>
                    <tr>
                        <td colSpan="3" className="text-center"></td>
                    </tr>
                    <tr>
                        <td colSpan="3" className="text-center"></td>
                    </tr>
                    <tr>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td width="70%"></td>
                        <td colSpan="2" className="text-center">
                            ( ________________________________________ )
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
})

export default ExcelStatement;