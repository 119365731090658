export default function(n){
    let hasil = terbilang(n)
    if(hasil === "" || hasil === " ") return "NOL"
    return hasil
}

function terbilang(number = 0) {
    number = parseInt(number)
    let angka = [
            "", "satu", 
            "dua", "tiga", 
            "empat", "lima", 
            "enam", "tujuh", 
            "delapan", "sembilan", 
            "sepuluh", "sebelas"
        ];


    let temp = "";

    if (number < 12) {
        temp = " " + angka[number];
    } else if (number < 20) {
        temp = terbilang(number - 10) + " belas";
    } else if (number < 100) {
        temp = terbilang(number / 10) + " puluh" + terbilang(number % 10);
    } else if (number < 200) {
        temp = " seratus" + terbilang(number - 100);
    } else if (number < 1000) {
        temp = terbilang(number / 100) + " ratus" + terbilang(number % 100);
    } else if (number < 2000) {
        temp = " seribu" + terbilang(number - 1000);
    } else if (number < 1000000) {
        temp = terbilang(number / 1000) + " ribu" + terbilang(number % 1000);
    } else if (number < 1000000000) {
        temp = terbilang(number / 1000000) + " juta" + terbilang(number % 1000000);
    } else if(number < 1000000000000 ){
        temp = terbilang(number / 1000000000) + " milyar" + terbilang(number % 1000000000);
    }
    return temp.toUpperCase()
}