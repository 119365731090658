import React, { useRef, memo, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { AppAction, AppInterfaces } from '../../../app.data.js';

const AlertWarning_Base = memo(({store, ...props}) => {
    const appAction = useRef(null);

    useEffect(() => {}, [appAction]);
    
    return (<>
        <AppAction ref={appAction} />
        <Modal 
            show={store.modal.show}
            onHide={()=>appAction.current.setCloseModal()} 
            backdrop={store.modal.backdrop}
            animation={false}
            size={store.modal.size}
            centered 
            >
                {store.modal.closeButton ? <></> : <Modal.Header closeButton>{store.modal.header}</Modal.Header>}
                {store.modal.body && <Modal.Body>{store.modal.body}</Modal.Body>}
                {store.modal.footer&&<Modal.Footer>{store.modal.footer}</Modal.Footer>}
        </Modal>
    </>);
})

const AlertConfirm = memo((props) => {
    return (<>    
        <Modal 
            show={props.show}
            onHide={()=>props.onHide()} 
            backdrop={props.backdrop}
            animation={true}
            size={props.size}
            centered 
            >
                <Modal.Header closeButton>{props.header}</Modal.Header>
                <Modal.Body>{props.body}</Modal.Body>
                <Modal.Footer>{props.footer}</Modal.Footer>
        </Modal>
    </>);
})



const AlertWarning = AppInterfaces.appLoad(AlertWarning_Base)

export {AlertConfirm, AlertWarning};