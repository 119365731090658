
import transaksi from "./icon/pos/summary transaks.png";
import bsu from "./icon/pos/summary bsu.png";
import saldo from "./icon/pos/summary saldo.png";
import img1 from "./img/Illustrasi.png";
import img2 from "./img/Illustrasi-1.png";

function SetElementHeightWeb(windowSize) {
    // console.log(windowSize,  $('html').height())
    // var cardCarousel = $('html').height() - 40.2;
    //     $('.card-carousel').css('height', cardCarousel);
    // var cardFormLogin = $('html').height() - 92;
    //     $('.card-form-login').css('height', cardFormLogin);
    // var cardBooksheet = $('html').height() - 249;
    //     $('.card-booksheet').css('height', cardBooksheet);
        
}

const init = {
    "USERAPI" : "https://my-json-server.typicode.com/016apih/test01/user",

}

function SendAPI (type, params) {
    console.log(type, params)
    // fetch(`${type} `)
    //     .then((response) => response.json())
    //     .then((json) => console.log(json));
}

const carousel = [
    {
        img: img1,
        title: "“To be the best choice for national logistics and financial services”"
    },
    {
        img: img2,
        title: "“Resources are Limited, Creatifity is Unlimited. ”"
    },
    {
        img: img1,
        title: "“To be the best choice for national logistics and financial services”"
    },
]

const dasboardData = [
    {
        label: "Transaksi",
        value: 18,
        icon: transaksi
    },
    {
        label: "BSU",
        value: "4,245,000",
        icon: bsu
    },
    {
        label: "Total Saldo",
        value: "54,123,000",
        icon: saldo
    }
]
// LISTRIK
const tokenData = [
    {
        id: 1,
        val: 20000,
        price: 22500
    },{
        id: 2,
        val: 50000,
        price: 50500
    },{
        id: 3,
        val: 100000,
        price: 99500
    },{
        id: 4,
        val: 150000,
        price: 148500
    },{
        id: 5,
        val: 200000,
        price: 190500
    },{
        id: 6,
        val: 250000,
        price: 23550
    },{
        id: 7,
        val: 300000,
        price: 280500
    },{
        id: 8,
        val: 400000,
        price: 380500
    },{
        id: 9,
        val: 500000,
        price: 475000
    },
]
const listPelanggan = [
    {
        id: "123",
        name: "Mayuga Wicaksana",
        phone: "081313131313",
        address: "Surapati Core C7, Bandung, Jawa Barat",
        tarif: "B2/7700 VA",
    },
    {
        id: "234",
        name: "Mayuga ",
        phone: "081313131313",
        address: "Bandung, Jawa Barat",
        tarif: "B2/7700 VA",
    },
    {
        id: "80312241741872",
        phone: "081313131313",
        address: "Surapati Core C7, Bandung, Jawa Barat",
        name: "Mayuga",
        tarif: "B2/700 VA",
    },
    {
        id: "80312241741872",
        phone: "081313131313",
        address: "Surapati Core C7, Bandung, Jawa Barat",
        name: "Wicaksana",
        tarif: "B2/7700 VA",
    },
]
const postPaid = [
    {
        id: "123",
        nama: "Wicaksana",
        tagihan: "1 Lembar",
        bulan: "OKT17",
        total: 208700
    },
    {
        id: "234",
        nama: "Mayuga Wicaksana",
        tagihan: "25 Lembar",
        bulan: "OKT17",
        total: 445380
    }
]
const nonTaglist = [
    {
        id: "123",
        nama: "Mayuga",
        jenis: "Migrasi Prabayar",
        total: 100100
    },
    {
        id: "234",
        nama: "Mayuga Wicaksana",
        jenis: "Migrasi Prabayar",
        total: 44380
    }
]
let detailTransaksi = {
    layanan: "",
    idPelanggan: "",
    nama: "",
    tarif: "",
    nominal: "",
    harga: "",
    adm: "",
    total: "",
    tagihan: "",
    bulan: "",
    jenis: "",
}

// Penerimaan Daerah
const listDaerah = ["ACEH", "BALI", "BANGKABELITUNG", "BANTEN", "BENGKULU", "DI YOGYAKARTA"];
const etilang = [
    {
        noDocument: 8,
        id: "123",
        nama: "Mayuga",
        alamat: "Bandung",
        kendaraan: "sepeda Motor",
        periode: 201911,
        pasal: "285(1) yo 106(3)",
        noPolisi: "B 3783",
        bBukti: "STNK",
        uDenda: 5000,
        uPerkara: 5000,
        uOngkor: 0
    }
]

// CARD PEMBAYARAN
const paymentCardData = {
    detilBody: [
        {
            key: "Jenis Layanan",
            value: "Pembayaran tiket"
        },{
            key: "Kode tiket",
            value: "123456789"
        },{
            key: "ID Pelanggan",
            value: "80312241741871"
        },{
            key: "Nama Pelanggan",
            value: "Mayuga Wicaksana"
        },
    ],
    detilFooter: [
        {
            key: "Harga",
            value: 235500
        },
        {
            key: "Admin",
            value: 2500
        }
    ],
    total: 237500
}

function ConvetToRupiah(data) {
    if(data === ""){
        return data
    }else{
        let reverse = data.toString().split('').reverse().join(''),
        ribuan 	= reverse.match(/\d{1,3}/g);
        ribuan	= ribuan.join('.').split('').reverse().join('');
        return ribuan
    }
}

function GetPayCardData(data) {
    let payCard = { 
        detilBody: [
            {
                key: "Jenis Layanan",
                value: data.name,
            },
            ...data.inputArr,
            {
                key: "ID Pelanggan",
                value: data.pelanggan.id
            },{
                key: "Nama Pelanggan",
                value: data.pelanggan.nama
            },
            ...data.selectVal
        ],
        detilFooter : [
            {
                key: "Harga",
                value: 235500
            },
            {
                key: "Admin",
                value: 2500
            }
        ],
        total : 237500
    }
    return payCard;    
}

export { init, SendAPI, SetElementHeightWeb,
    carousel,
    dasboardData,
    // TOKEN LISTRIK
        tokenData, listPelanggan, detailTransaksi, postPaid, nonTaglist,
    // PENERIMAAN DAERAH
        listDaerah, etilang,
    // CARD PEMBAYARAN
        paymentCardData,
    // FUNCTION
        ConvetToRupiah,
        GetPayCardData,
}