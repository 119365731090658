class JoinDefinedString {
    constructor(...args) {
        this.$values = args.filter((arg) => Boolean(arg) && arg !== 'NULL');
    }

    separateWith(separator) {
        return this.$values.join(separator);
    }

    value() {
        return this.$values.join(' ');
    }
}

export function joinDefinedString(...args) {
    return new JoinDefinedString(...args);
}
