import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { useLocalStorage } from '../../../module';
import { useModalAlert } from '../../controls/modalAlert';
import moment from 'moment';
import CetakArea from './cetakArea';
import { savePDF } from '@progress/kendo-react-pdf';
import useKeycloak from '../../../hooks/keycloak.module';
import { useHistory, useLocation, useParams } from 'react-router';
import { TabgirTemplate } from '../../PDFTemplate'

const hrkContext = createContext({
    hrkAction: {},
    hrkState: {}
})

const TemplateMapping = {
    tabgir : TabgirTemplate
}

function RenderTemplatePDF({keyTemplate, data}){

   
    let Rendered = TemplateMapping[keyTemplate]
    // console.log(Rendered, keyTemplate)
    if(Rendered) return <Rendered dataCetakStatement={data} />
    else return <></>
}

export function HrkProvider({ children }) {
    const [templateCetak, setTemplateCetak] = useState('tabgir')
    const [dataStatementCetakNew, setDataStatementCetakNew] = useState({
        periodeA:"",
        periodeB:"",
        nama_cabang:"",
        nomor_rekening:"",
        nama_produk:"",
        alamat:"",
        trx:{
            npwp:"",
            saldoAwal:"0",
            debit:"0",
            kredit:"0",
            saldoAkhir:"0",
            list:[],
            saldo_blokir:"0"
        }
    })
    const { modalState, modalAction } = useModalAlert();
    const [podDate] = useLocalStorage("POD_DATE", "")
    const [namaRek, setNamaRek] = useState("")
    const [loadingNamaRek, setLoadingNamaRek] = useState(false)
    const [filter, setFilter] = useState({
        nomor_rekening: "",
        tgl_awal: moment(new Date()).format("DD-MM-YYYY"),
        tgl_akhir: moment(new Date()).format("DD-MM-YYYY")
    });

    const [pagination, setPagination] = useState({
        limit: 15,
        page: 1
    });

    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        openLoading,
        closeConfirmCustom,
        openAlertCustom
    } = modalAction;
    const [field, setField] = useState([]);
    const [listData, setListData] = useState([]);
    const [summaryData, setSummaryData] = useState({});
    const [summaryField, setSummaryField] = useState([]);
    const { keycloak } = useKeycloak()
    const { doGet, doPost, doGetNoBase, wait } = useFetch();
    const [rekIsValid, setRekIsValid] = useState(false);
    const [dataStatement, setDataStatement] = useState({});
    const [namaCabang, setNamaCabang] = useState("")
    const { mode } = useParams();

    const history = useHistory()
    const location = useLocation();
    async function cekNasabah(nomor_rekening) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        setRekIsValid(false)
        setLoadingNamaRek(true)
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/tabungan-giro/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {

                if (status === "00") {
                    const { nama_rekening = "", nama_cabang = "", valuta = "", nama_produk = "" } = data;
                    setRekIsValid(true)
                    setNamaRek(nama_rekening);
                    setNamaCabang(nama_cabang);
                }
                else setDataAlert({
                    show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => {
                        setFilter((w) => ({ ...w, nomor_rekening: "" }))
                        setNamaRek("")
                        resetForm()
                    }
                });
            } else setDataAlert({
                show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => {
                    setFilter((w) => ({ ...w, nomor_rekening: "" }))
                    setNamaRek("")
                }
            });
        } catch (error) {
            setDataAlert({
                show: true, body: error.toString(), title: ``, header: "Error", callBack: () => {
                    setFilter((w) => ({ ...w, nomor_rekening: "" }))
                    setNamaRek("")
                }
            });
        }
        setLoadingNamaRek(false)
    }

    

    const [isApproved, setIsApproved] = useState(false);


    const [loadingGetData, setLoadingGetData] = useState(false)

    async function getData(getFromFilter = true, { nomor_rekening, tgl_awal, tgl_akhir } = {}) {
        let f = filter;
        if (getFromFilter) {
            if (
                filter.nomor_rekening === "" ||
                filter.nomor_rekening === undefined ||
                filter.nomor_rekening === null
            ) return setDataAlert({
                show: true, body: "Nomor rekening harus terisi", title: ``, header: "Info", callBack: () => null
            })
            let thnAwal = moment(filter.tgl_awal, "DD-MM-YYYY").format("YYYY");
            let thnAkhir = moment(filter.tgl_akhir, "DD-MM-YYYY").format("YYYY");
            if (parseInt(thnAwal) >= 2022 || parseInt(thnAkhir) >= 2022) {
                return setDataAlert({
                    show: true, body: `Data untuk tahun 2022 dan Seterusnya tidak tersedia`, title: ``, header: "Info", callBack: () => null
                })
            }
            if (!rekIsValid) {
                return setDataAlert({
                    show: true, body: "Nomor rekening tidak valid. mohon tunggu pengecekan nomor rekening.", title: ``, header: "Info", callBack: () => null
                })
            }
        } else {
            f = {
                nomor_rekening,
                tgl_akhir,
                tgl_awal
            }
        }
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        setLoadingGetData(true)
        // openLoading()
        try {
            // const resp = await doGetNoBase({
            //     // data: bodyData,
            //     url: `http://172.27.170.50/olibs/api/v1/mutasi`,
            const resp = await doGet({
                // data: bodyData,
                url: `/mutasi`,
                service: "olibs",
                withBaseUrl: false,
                param: {
                    ...pagination,
                    ...f
                }
            });
            const { statusCode, status, statusText, remark, headerFields = [], summaryFields = [], summaryData = {}, listData = [] } = resp;
            // // console.log(resp)
            if (statusCode === 200) {

                if (status === "00") {
                    setListData(listData)
                    setSummaryData(summaryData)
                    setSummaryField(summaryFields)
                    setField(headerFields)
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        // closeLoading()
        setLoadingGetData(false)
    }


    const resetForm = () => {
        setDataStatementCetakNew({
            periodeA:"",
            periodeB:"",
            nama_cabang:"",
            nomor_rekening:"",
            nama_produk:"",
            alamat:"",
            trx:{
                npwp:"",
                saldoAwal:"0",
                debit:"0",
                kredit:"0",
                saldoAkhir:"0",
                list:[],
                saldo_blokir:"0"
            }
        })
        setIsApproved(false);
        setSummaryData([]);
        setSummaryField([]);
        setField([])
        setNamaRek("");
        setNamaCabang("");
        setFilter({
            nomor_rekening: "",
            tgl_awal: moment(new Date()).format("DD-MM-YYYY"),
            tgl_akhir: moment(new Date()).format("DD-MM-YYYY")
        })
    }
    // next pagination
    function nextPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    const tableRef = useRef(null)
    function doCetakData() {
        savePDF(tableRef.current,
            {
                paperSize: 'Letter',
                // margin: { top: 15, left: 10, right: 10, bottom: 15 },
                scale: 0.5,
                fileName: `statement-${filter.nomor_rekening}-${namaRek}-${moment(new Date()).format("DD-MM-YYYY_HH-mm-SS")}`,
             repeatHeaders: true,
                margin: "1cm",
                forcePageBreak: ".page-break",
                pageTemplate: ({ pageNum, totalPages }) => <div
                    className='row'
                    style={{
                        position: "absolute",
                        width: "90%",
                        bottom: "30px",
                    }}
                >
                    <div className='col'>
                        <span className='float-left'> https://bjbsyariah.co.id </span>
                        <span className='float-right'> halaman {pageNum} dari {totalPages} </span>
                    </div>

                </div>
            },
            () => {
                flaging();
            }
        )
    }
    async function confirmCetakData() {
        openLoading("Retreiving data. please wait ...")
        // return doCetakData();
        await wait(500)

        try {
            // const resp = await doGetNoBase({
            //     // data: bodyData,
            //     url: `http://172.27.170.50/olibs/api/v1/statement`,
            const resp = await doGet({
                // data: bodyData,
                url: `/statement`,
                service: "olibs",
                withBaseUrl: false,
                param: {
                    // ...pagination,
                    ...filter
                }
            });
            const { templatename, statusCode, status, statusText, remark, headerFields = [], summaryFields = [], summaryData = {}, listData = [], data={} } = resp;
            // console.log(resp)
            if (statusCode === 200) {

                if (status === "00") {
                    setTemplateCetak(templatename);
                    setDataStatementCetakNew({
                        periodeA:moment( filter.tgl_awal, "DD-MM-YYYY" ).format("YYYY-MM-DD"),
                        periodeB:moment( filter.tgl_akhir,"DD-MM-YYYY" ).format("YYYY-MM-DD"),
                        nama_cabang:data.nama_kantor,
                        nomor_rekening:data.nomor_rekening,
                        nama_produk:data.nama_produk,
                        nama_rekening:data.nama_rekening,
                        alamat:data.alamat,
                        trx:{
                            npwp:data.npwp,
                            saldoAwal:data.saldo_awal,
                            debit:data.total_mutasi_debet,
                            kredit:data.total_mutasi_kredit,
                            saldoAkhir:data.saldo_akhir,
                            list:data.listtrx,
                            saldo_blokir:data.saldoBlokir
                        }
                    })
                    // setDataStatement({
                    //     listData: listData,
                    //     summaryData: summaryData,
                    //     summaryField: summaryFields,
                    //     field: headerFields
                    // })
                    // setListData(listData)
                    // setSummaryData(summaryData)
                    // setSummaryField(summaryFields)
                    // setField(headerFields)  
                    setDataAlert({
                        show: true, body: "Data siap dicetak ... ", title: `Success`, header: "", callBack: () => doCetakData()
                    })
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()

    }

    useEffect(function () {
        if (
            filter.nomor_rekening !== "" &&
            filter.nomor_rekening !== undefined &&
            filter.nomor_rekening !== null
        ) getData()
    }, [pagination])

    async function cekOtor() {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        // setRekIsValid(false)
        openLoading("Cek status otorisasi")
        setLoadingNamaRek(true)
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/historykoran/detail-cetak-history-rekening-koran/${filter.nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {

                if (status === "00") {
                    setFilter({
                        tgl_akhir: data.date_end,
                        tgl_awal: data.date_start,
                        nomor_rekening: data.nomor_rekening
                    });
                    setRekIsValid(true);

                    setIsApproved(true)
                    setNamaRek(data.nama_rekening);
                    getData(false, {
                        tgl_akhir: data.date_end,
                        tgl_awal: data.date_start,
                        nomor_rekening: data.nomor_rekening
                    });
                } else if (status === "03") {
                    setIsApproved(false)
                }
                // else setDataAlert({
                //     show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => {
                //         setFilter((w) => ({ ...w, nomor_rekening: "" }))
                //         setNamaRek("")
                //     }
                // });
            } else setDataAlert({
                show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => {
                    setFilter((w) => ({ ...w, nomor_rekening: "" }))
                    setNamaRek("")
                }
            });
        } catch (error) {
            setDataAlert({
                show: true, body: error.toString(), title: ``, header: "Error", callBack: () => {
                    setFilter((w) => ({ ...w, nomor_rekening: "" }))
                    setNamaRek("")
                }
            });
        }
        closeLoading();
    }

    function confirmReqCetak() {
        let body = `Apakah anda yakin melanjutkan request cetak history rekening koran. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => reqCetak(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirm(), className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    async function reqCetak() {
        openLoading("Requesting data. please wait ...")
        closeConfirm();
        try {

            const body = {
                "date_start": (filter.tgl_awal),
                "date_end": (filter.tgl_akhir),
                "nomor_rekening": filter.nomor_rekening,
                "nama_rekening": namaRek,
                "cabang_rekening": namaCabang
            }
            const resp = await doPost({
                // data: bodyData,
                data: body,
                url: `/historykoran/request-cetak-history-koran`,
                service: "acc",
            });
            const { statusCode, status, statusText, remark, data } = resp;
            // // console.log(resp)
            if (statusCode === 200) {

                if (status === "00") {
                    setDataAlert({
                        show: true, body: "Request cetak berhasil dikirim. Perlu otorisasi untuk melanjutkan.", title: `Success`, header: "", callBack: () => resetForm()
                    })
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    useEffect(function () {
        if (mode === "otorisasi") {
            getDetilOtor()
        }
    }, [mode])
    async function getDetilOtor() {
        openLoading("Retrieving data. please wait ...")
        closeConfirm();
        try {

            const resp = await doGet({
                // data: bodyData,
                url: `/historykoran/request-cetak-history-koran/${location.state?.dbId}`,
                service: "acc",
            });
            const { statusCode, status, statusText, remark, data } = resp;
            if (statusCode === 200) {

                if (status === "00") {
                    setFilter({
                        tgl_akhir: data.date_end,
                        tgl_awal: data.date_start,
                        nomor_rekening: data.nomor_rekening
                    });
                    setRekIsValid();
                    setNamaRek(data.nama_rekening);
                    getData(false, {
                        tgl_akhir: data.date_end,
                        tgl_awal: data.date_start,
                        nomor_rekening: data.nomor_rekening
                    });
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status === "REJECTED" ? "Reject" : "Approve"} request cetak untuk ${filter.nomor_rekening}  ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    const [otorDesc, setOtorDesc] = useState("")

    async function fetchOtorisasi(statusApprove) {
        openLoading("please wait ...")
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, message = "", remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state?.kode_entry}/${location.state?.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "acc",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }
                });
                if (statusCode === 200) {
                    setDataAlert({ show: true, body: remark, title: '', header: "Info", callBack: () => history.goBack() });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });

        closeLoading()
    }

    async function flaging() {
        try {

            const resp = await doPost({
                // data: bodyData,
                queryParam: {
                    nomor_rekening: filter.nomor_rekening,
                    is_boleh_cetak: "F"
                },
                method: "PUT",
                url: `/historykoran/hist-cetak-rekening-koran/update-flag`,
                service: "acc",
            });
            const { statusCode, status, statusText, remark, data } = resp;
            if (statusCode === 200) {

                if (status === "00") {

                    setDataAlert({ show: true, body: remark, title: ``, header: "Success", callBack: () => resetForm() })
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
    }

    useEffect(function () {
        rekIsValid && cekOtor()
    }, [rekIsValid])
    return (
        <hrkContext.Provider value={{
            hrkAction: {
                cekNasabah,
                setFilter,
                getData,
                nextPage, prevPage, changeShow,
                confirmCetakData, confirmReqCetak,
                responseOtor
            },
            hrkState: {
                namaRek,
                filter,
                loadingNamaRek,
                pagination,
                summaryField,
                listData,
                field,
                summaryData,
                loadingGetData,
                isApproved,

                dataCetakStatement: {
                    nomor_rekening: filter.nomor_rekening,
                    nama_lengkap: namaRek,
                    valuta: "IDR",
                    periodeA: filter.tgl_awal,
                    periodeB: filter.tgl_akhir,
                    userCetak: `${keycloak.tokenParsed.name} ( ${keycloak.tokenParsed.preferred_username} )`,
                    ...dataStatement
                    // listData,
                    // field,
                    // summaryData,
                    // summaryField
                }

            }
        }}>
            <div style={{ position: 'fixed', left: '-1500px', top: '0' }}>
                <div ref={tableRef}>
                    {/* <CetakArea /> */}
                    <RenderTemplatePDF data={dataStatementCetakNew} keyTemplate={templateCetak} />

                </div>
            </div>
            {/* <ModalAler */}
            {children}
        </hrkContext.Provider>
    )
}

export default function useHrk() {
    return useContext(hrkContext)
}