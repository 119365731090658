import React, { memo, useRef } from 'react';
import {TableList } from '../../../controls';
import { Modal, Dropdown } from 'react-bootstrap';

class TolakanTarikanKliring extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModalCariTransaksiTarikanKliring: false,
    };
  }

  render() {
    return(
      <>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mt-2 mb-3">
            <li className="breadcrumb-item color-primary">Kliring</li>
            <li className="breadcrumb-item"><a href="#">Reversal Tarikan Kliring</a></li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header bg-white">
                <h5 className="card-title">REVERSAL TARIKAN KLIRING</h5>
              </div>
              <div className="card-body">
                <div className="bns-form">
                  <div className="row">
                    <div className="col-6">
                      <div className="text-primary mt-2 mb-4">Info Transaksi Tarikan Kliring</div>
                      <div className="form-row">
                        <div className="form-group col-4">
                          <label htmlFor="">Tanggal Tarikan</label>
                          <select className="form-control" readOnly>
                            <option value="">15-04-2015</option>
                          </select>
                        </div>
                        <div className="form-group col-8">
                          <label htmlFor="">&nbsp;</label>
                          <button onClick={() => this.setState({showModalCariTransaksiTarikanKliring: true})} className="btn btn-outline-secondary btn-block">Cari Transaksi Tarikan Kliring</button>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-12">
                          <label htmlFor="">No. Rekening</label>
                          <input type="text" className="form-control" readOnly/>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-4">
                          <label htmlFor="">Jenis Warkat</label>
                          <select className="form-control" readOnly>
                            <option value="">Cek</option>
                          </select>
                        </div>
                        <div className="form-group col-8">
                          <label htmlFor="">No. Warkat</label>
                          <input type="text" className="form-control" readOnly/>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">No. Rekening</label>
                        <div className="form-row">
                          <div className="col-4 pr-0">
                          <input type="text" className="form-control" readOnly/>
                          </div>
                          <div className="col-8 pl-0">
                            <input type="text" className="form-control" placeholder="NAMA NASABAH" readOnly/>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                          <label htmlFor="">Nominal</label>
                          <input type="text" className="form-control" readOnly/>
                        </div>
                    </div>

                    <div className="col-6">
                      <div className="text-primary mt-2 mb-4">Tolakan Tarikan Kliring</div>
                      <div className="form-group">
                        <label htmlFor="">No. Rekening</label>
                        <div className="form-row">
                          <div className="col-4 pr-0">
                          <input type="text" className="form-control"/>
                          </div>
                          <div className="col-8 pl-0">
                            <input type="text" className="form-control" placeholder="NAMA NASABAH" readOnly/>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-4">
                          <label htmlFor="">Jenis Warkat</label>
                          <select className="form-control" readOnly>
                            <option value="">Cek</option>
                          </select>
                        </div>
                        <div className="form-group col-8">
                          <label htmlFor="">No. Warkat</label>
                          <input type="text" className="form-control" readOnly/>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">Valuta Rekening</label>
                            <div className="form-row">
                              <div className="col-3 pr-0">
                                <input type="text" className="form-control" value="IDR" readOnly/>
                              </div>
                              <div className="col-9 pl-0">
                                <input type="text" className="form-control" value="INDONESIAN RUPIAH" readOnly/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">Cabang Rekening</label>
                            <div className="form-row">
                              <div className="col-3 pr-0">
                                <input type="text" className="form-control" value="000" readOnly/>
                              </div>
                              <div className="col-9 pl-0">
                                <input type="text" className="form-control" value="KCP KOTA BANDUNG" readOnly/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Nilai Transaksi</label>
                        <input type="text" className="form-control"/>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-12">
                          <label htmlFor="">Keterangan</label>
                          <textarea rows="1" className="form-control">TOLAKAN TARIKAN KLIRING</textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <div className="row">
                  <div className="col-6"></div>
                  <div className="col-6 text-right">
                    <button className="btn btn-outline-secondary">Batal</button>
                    <button className="btn btn-primary ml-3 px-5">Proses</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal 
          show={this.state.showModalCariTransaksiTarikanKliring} 
          onHide={() => this.setState({showModalCariTransaksiTarikanKliring: false})}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>TRANSAKSI TARIKAN KLIRING</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p0">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th>Tgl. Transaksi</th>
                  <th>Jenis Warkat</th>
                  <th>No. Warkat</th>
                  <th>No. Rekening</th>
                  <th>Nama Lengkap</th>
                  <th>Nilai Transaksi</th>
                  <th>Valuta Transaksi</th>
                  <th>Cabang</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>12-04-2015</td>
                  <td>Cek</td>
                  <td>12023131234</td>
                  <td>12132732189032</td>
                  <td>MOH ALI PAHLEVY</td>
                  <td>2.000.000,00</td>
                  <td>IDR - INDONESIAN RUPIAH</td>
                  <td>000 - KC JAKARTA PUSAT</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => this.setState({showModalCariTransaksiTarikanKliring: false})} className="btn btn-secondary">Batal</button>
            <button className="btn btn-orange-primary">Pilih Transaksi</button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default TolakanTarikanKliring;