import React, { memo, useRef } from 'react';
import {TableList } from '../../../controls';
import { Modal, Dropdown } from 'react-bootstrap';

class DaftarTransaksiGL extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          field1: "1234567890",
          field2: "RACHMA HADIYANTI",
          field3: "1,000,000",
          field4: "10,000,000",
          field5: "Lorem Ipsum dolores sit amet",
          field6: "000",
          field7: "IDR",
          field8: "14.100",
        },
        {
          field1: "1234567890",
          field2: "RACHMA HADIYANTI",
          field3: "1,000,000",
          field4: "10,000,000",
          field5: "Lorem Ipsum dolores sit amet",
          field6: "000",
          field7: "IDR",
          field8: "14.100",
        },
        {
          field1: "1234567890",
          field2: "RACHMA HADIYANTI",
          field3: "1,000,000",
          field4: "10,000,000",
          field5: "Lorem Ipsum dolores sit amet",
          field6: "000",
          field7: "IDR",
          field8: "14.100",
        },
      ],
      showModalInputTransaksi: false,
    };
  }

  render() {
      return(
        <>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mt-2 mb-3">
              <li className="breadcrumb-item color-primary">Transaksi Umum</li>
              <li className="breadcrumb-item"><a href="#">Daftar Transaksi General Ledger</a></li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-white">
                  <h5 className="card-title">DAFTAR TRANSAKSI GENERAL LEDGER</h5>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <button onClick={() => this.setState({showModalInputTransaksi: true})} className="btn btn-orange-primary">Tambah Transaksi</button>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <TableList
                        columns={[
                            {
                                name: "#",
                                field: "#",
                                maxWidth: "50px"
                            },
                            {
                                name: "No. Rekening",
                                field: "field1",
                                maxWidth: "50px"
                            },
                            {
                                name: "Nama Nasabah",
                                field: "field2",
                                maxWidth: "50px"
                            },
                            {
                                name: "Amount Debit",
                                field: "field3",
                                maxWidth: "50px"
                            },
                            {
                                name: "Amount Credit",
                                field: "field4",
                                maxWidth: "50px"
                            },
                            {
                                name: "Description",
                                field: "field5",
                                maxWidth: "50px"
                            },
                            {
                                name: "Cabang",
                                field: "field6",
                                maxWidth: "50px"
                            },
                            {
                                name: "Kurs",
                                field: "field7",
                                maxWidth: "50px"
                            },
                            {
                                name: "Exc. Rate",
                                field: "field8",
                                maxWidth: "50px"
                            },
                        ]}

                        data={this.state.data}

                        filter={true}
                        formsFilter = {[
                          {
                              type:"select",
                              placeholder:"Nama Nasabah",
                              disabled:false,
                              readonly:false,
                              onChange:(value)=>null
                          },{
                              type:"text",
                              placeholder:"Masukkan kata kunci",
                              disabled:false,
                              readonly:false,
                              onChange:(value)=>null
                          },
                        ]}
                        onFilter={(e)=>null}
                        resetFilter={false}
                        submitButton="Cari Data"
                    />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Modal show={this.state.showModalInputTransaksi} onHide={() => this.setState({showModalInputTransaksi: false})} centered>
            <Modal.Header closeButton>
              <Modal.Title>Input Transaksi GL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="bns-form">
                <div className="form-row">
                  <div className="form-group col-6">
                    <label htmlFor="">Serial Transaksi</label>
                    <input type="text" className="form-control" readOnly/>
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="">Nomor Jurnal</label>
                    <input type="text" className="form-control" readOnly/>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Valuta</label>
                  <div className="form-row">
                    <div className="col-3 pr0">
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-9 pl0">
                      <input type="text" className="form-control" readOnly/>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="form-group">
                  <label htmlFor="">No. Rekening</label>
                  <div className="form-row">
                    <div className="col-5 pr0">
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-7 pl0">
                      <input type="text" className="form-control" placeholder="NAMA NASABAH" readOnly/>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Cabang</label>
                  <div className="form-row">
                    <div className="col-3 pr0">
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-9 pl0">
                      <input type="text" className="form-control" readOnly/>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Kode RC</label>
                  <div className="form-row">
                    <div className="col-3 pr0">
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-9 pl0">
                      <input type="text" className="form-control" readOnly/>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Tipe Transaksi</label>
                  <select className="form-control">
                    <option value=""></option>
                  </select>
                </div>
                <div className="form-row">
                  <div className="form-group col-8">
                    <label htmlFor="">Keterangan</label>
                    <textarea className="form-control" rows="1"></textarea>
                  </div>
                  <div className="form-group col-4">
                    <label htmlFor="">&nbsp;</label><br/>
                    <button className="btn btn-outline-secondary">Copy from Journal</button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-outline-secondary" onClick={() => this.setState({showModalInputTransaksi: false})}>
                Batal
              </button>
              <button className="btn btn-primary ml-3" onClick={() => this.setState({showModalInputTransaksi: false})}>
                Simpan Transaksi
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )
  }
}

export default DaftarTransaksiGL;