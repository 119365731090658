import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormRegistration } from '../../../../controls';
import useInfoTeller, { InfoTellerProvider } from '../useInformasiTeller';
import { createForm } from '../../../../../module/createFormList';

const ModalRegisTeller = () => {
    const { rtState, rtAction, itState, itAction } = useInfoTeller();
    // // console.log(rtState)
    const { formData = {}, formEditable = false } = rtState;

    return (
        <>
            <Modal size={"s"} show={rtState.modalregitrasiTeller} onHide={rtAction.closeregisTeller} >
                <Modal.Header closeButton>
                    <Modal.Title>Registrasi Teller</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormRegistration
                        totalRows={1}
                        itemsForm={[
                            {
                                name:"Regestrasi",
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Nama Teller',
                                        type: 'text',
                                        placeholder: 'Masukan Nama Teller',
                                        required: true,
                                        value: rtState.formData?.name,
                                        // onChange: (value) => rtAction.changeForm((value || "").toUpperCase(), "name"),
                                        onChange: (value) => rtAction.changeForm(value, "name"),
                                        disabled: rtState.otorMode
                                    },
                                    {
                                        rows: 1,
                                        label: 'User ID Teller',
                                        type: 'text',
                                        maxLength:5,
                                        placeholder: 'Masukan User ID Teller',
                                        required: true,
                                        value: rtState.formData?.id_user,
                                        onChange: (value) => rtAction.changeForm(value, "id_user"),
                                        disabled: rtState.otorMode
                                    },
                                ]
                            }
                        ]}
                    
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col">
                            <button onClick={rtAction.closeregisTeller} className="btn btn-sm float-right mr-2 btn-outline-secondary">
                                Batal
                            </button>
                            {
                                !rtState.otorMode ? <>
                                  {
                                      <button
                                          onClick={() => rtAction.postTeller()}
                                          className="btn btn-sm btn-success mr-2">
                                          Create Teller 
                                      </button>
                                  }
                                </> : ( <>

                                    <button
                                        onClick={() => rtAction.responseOtor("APPROVED")}
                                        className="btn btn-sm btn-success mr-2">
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => rtAction.responseOtor("REJECTED")}
                                        className="btn btn-sm btn-danger mr-2">
                                        Reject
                                    </button>
                                </>
                                )
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalRegisTeller;