import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useFetch from '../../../../hooks/useFetch';
import { useModalAlert } from '../../../controls/modalAlert';
import useModalNasabah from './useModalNasabah';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../../hooks/keycloak.module';

const TutupRekeningContext = createContext({
    trState: {},
    trAction: {}
})

export const TutupProvider = ({ children }) => {

    const { doGet, doPost, wait } = useFetch();
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ keyword: '' });
    const [dataListRekening, setListDataRekening] = useState([]);
    const [produk, setProduk] = useState("")
    const { mode } = useParams();
    // const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    // const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false });
    const [isLoading, setIsLoading] = useState(true)
    const [dataCetak, setDataCetak] = useState({});
    const [dataBank, setDataBank] = useState([]);
    const [formData, setFormData] = useState({
        "nomor_rekening": "",
        "nominal": 0,
        "keterangan": "",
        "kode_valuta": "IDR",
        "info_transaksi": {
            "saldo_teller": 0,
            "kode_valuta_teller": "IDR",
            "nama_rekening": "",
            "kode_valuta_rekening": "IDR",
            "nama_valuta_rekening": "INDONESIA RUPIAH",
            "kode_cabang_rekening": "",
            "nama_cabang_rekening": "",
            "saldo_rekening": 0,
            "produk": "",
            "jenis_ttr": "T",
            "tutup_kartu_atm": "",
            "norek_pinbuk": "",
            "nama_rekening_pinbuk": "",
            "cabang_rekening_pinbuk": "",
            "keterangan_valuta": "INDONESIA RUPIAH",
            "kurs_jual": 1,
            "kurs_beli": 1,
            "kurs_buku": 1,
            "nominal_ekuivalen": 0,
            "biaya": 0,
            "biaya_ekuivalen": 0,
            "saldo" : 0, 
            "saldo_accrual_bagihasil" : 0, 
            "pajak_bagi_hasil" : 0
        }
    });
    const [otorDesc, setOtorDesc] = useState("")

    const modalNasabah = useModalNasabah()
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const { keycloak } = useKeycloak()
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams();
    const location = useLocation();


    useEffect(() => {
        // if (paramUrl.mode === "otorisasi") {
        //     if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
        //     getDataDetail(location.state?.dbId);
        // }
        // } 
        setFormEditable(paramUrl.mode !== "otorisasi");
        getUser();
        if (paramUrl.mode !== "otorisasi") getSaldo();
    }, [paramUrl.mode])
    // useEffect GetData
    useEffect(function () {
        // getDataList()
    }, [pagination])

    async function getUser() {

        const data = await keycloak.loadUserProfile();
    }
    // get Saldo teller
    async function getSaldo() {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/kasteller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userInfo.preferred_username,
                    kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setFormData(f => ({
                        ...f,
                        info_transaksi: {
                            ...f.info_transaksi,
                            kode_valuta_teller: data.kode_valuta,
                            saldo_teller: parseFloat(data.saldo).toFixed(2)
                        }
                    }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }

    function changeForm(value, key, subkey = null, grandSubKey = null) {
        value = value.toUpperCase()
        if (grandSubKey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [grandSubKey]: { ...prevState[subkey][grandSubKey], [key]: value } } }))
        else if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    async function getListBank() {
        try {
            const resp = await doGet({
                url: `/bank-transfer/${paramUrl.type}`,
                service: "trx"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {

                if (status === "00") {
                    setDataBank(data.listbank)
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
    }

    async function cekNasabah(nomor_rekening, _type = 0) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/tabungan-giro/rek-for-ttr/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {

                if (status === "00") {
                    const { 
                        nama_rekening = "", 
                        nama_cabang = "", 
                        valuta = "", 
                        nama_produk = "", 
                        saldo = 0, 
                        biaya_penutupan_rekening = 0, 
                        saldo_accrual_bagihasil = 0, 
                        pajak_bagi_hasil = 0
                    } = data;
                    const valutas = valuta.split("-")

                    let nominal = ((parseFloat(saldo) +parseFloat(saldo_accrual_bagihasil))  - parseFloat(biaya_penutupan_rekening) - parseFloat(pajak_bagi_hasil) ).toFixed(2)
               
                    if (data.jenis_rekening_liabilitas === "R") {
                        closeLoading()
                        return setDataAlert({
                            show: true, body: "Rekening rencana tidak bisa melakukan transaksi tutup rekening.", title: `Rekening Rencana`, header: "Info", callBack: () => setFormData(p => ({
                                ...p,
                                nomor_rekening: ""
                            }))
                        });
                    }
                    if (_type === 1) setFormData(f => ({
                        ...f,
                        info_transaksi: {
                            ...f.info_transaksi,
                            nama_rekening_pinbuk: nama_rekening,
                        }
                    }))
                    else setFormData(f => ({
                        ...f,
                        nominal,
                        info_transaksi: {
                            ...f.info_transaksi,
                            nama_rekening,
                            nama_cabang_rekening: nama_cabang,
                            biaya: parseFloat(biaya_penutupan_rekening).toFixed(2),
                            saldo_rekening: parseFloat(saldo).toFixed(2),
                            kode_valuta_rekening: valutas.length >= 1 ? valutas[0] : "",
                            nama_valuta_rekening: valutas.length > 1 ? valutas[1] : "",
                            produk: nama_produk,
                            saldo_accrual_bagihasil, 
                            pajak_bagi_hasil 
                        }
                    }))
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }

    async function getPengirim() {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account ...")
        // if (dataPengirim.jenis_nasabah === "" || dataPengirim.jenis_nasabah === null || dataPengirim.jenis_nasabah === undefined) {
        //     setDataAlert({ show: true, body: "Jenis nasabah belum terpilih", title: ``, header: "INFO", callBack: () => null });
        // } else {

        //     let url = `/nasabah/${nomor_nasabah}`;
        //     if (dataPengirim.jenis_nasabah === "BADAN USAHA") {
        //         url = `/nasabah-badan-usaha/${nomor_nasabah}`
        //     }
        //     try {
        //         const resp = await doGet({
        //             // data: bodyData,
        //             url,
        //             service: "cif"
        //         });
        //         const { statusCode, status, statusText, data, remark } = resp;

        //         if (statusCode === 200) {

        //             if (status === "00") {

        //                 if (dataPengirim.jenis_nasabah === "BADAN USAHA") {
        //                     const {
        //                         nasabahNamaInduk,
        //                         nasabahNomorInduk,
        //                         nasabahProvinsi,
        //                         nasabahRw,
        //                         nasabahRt,
        //                         nasabahKecamatan,
        //                         nasabahAlamat,
        //                         nasabahKelurahan,
        //                         nasabahKotaKabupaten,
        //                         nasabahKodePos,
        //                         nasabahTeleponKantorKodeArea1,
        //                         nasabahTeleponKantorNomor1

        //                     } = data.nasabahBadanUsaha

        //                     setDataPengirim(p => ({
        //                         ...p,
        //                         nama: nasabahNamaInduk,
        //                         alamat: `${nasabahAlamat} RT${nasabahRt}/RW${nasabahRw}
        //                                     ${nasabahKelurahan}, 
        //                                     ${nasabahKecamatan}, 
        //                                     ${nasabahKotaKabupaten}, 
        //                                     ${nasabahProvinsi}
        //                                     ${nasabahKodePos}`,
        //                         jenis_identitas: "KTP",
        //                         nomor_identitas: nasabahNomorInduk,
        //                         nomor_tlpn: `${nasabahTeleponKantorKodeArea1} ${nasabahTeleponKantorNomor1}`,
        //                     }));
        //                 }
        //                 else {
        //                     const {
        //                         firstName = "",
        //                         mailingAddressPostalCode,
        //                         mailingAddress,
        //                         mailingAddressCity,
        //                         mailingAddressVillage,
        //                         mailingAddressProvince,
        //                         mailingAddressSubDistrict,
        //                         personalIdNumber,
        //                         mobilePhone
        //                     } = data.nasabahBadanUsaha;

        //                     setDataPengirim(p => ({
        //                         ...p,
        //                         nama: firstName,
        //                         alamat: `${mailingAddress} ${mailingAddressVillage}, ${mailingAddressSubDistrict}, ${mailingAddressCity}, ${mailingAddressPostalCode} ${mailingAddressProvince} `,
        //                         jenis_identitas: "KTP",
        //                         nomor_identitas: personalIdNumber,
        //                         nomor_tlpn: mobilePhone,
        //                     }));

        //                 }

        //             }
        //             else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        //         } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        //     } catch (error) {
        //         setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        //     }
        // }
        closeLoading();
    }

    useEffect(function () {
        if (paramUrl.mode === "otorisasi") {
            if (location.state?.dbId || null) getDetilOtor()
            else setDataAlert({ show: true, body: "Tidak ada data terpilih", title: "Tidak bisa akses", header: "Info", callBack: () => history.push("/") });
        }
    }, [paramUrl.mode, location.state])

    useEffect(function () {
        //// console.log(formData)
    }, [formData])

    async function getDetilOtor() {
        setLoading(true)
        var url = `/tutuprekening/${location.state.dbId}`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                service: "trx",
            });

            if (statusCode === 200) {
                if (status === "00") {
                    // cekNasabah(data.nomor_rekening)
                    setFormData(p => ({
                        ...p,
                        ...data,
                        "nomor_rekening": data?.nomor_rekening,
                        info_transaksi: {
                            ...p.info_transaksi,
                            ...data.info_transaksi,
                            saldo_teller: data.info_transaksi?.saldo_teller_before,
                            nama_rekening: data?.info_transaksi?.nama_rekening,
                            biaya: parseFloat(data?.info_transaksi?.biaya).toFixed(2),
                        }
                    }))

                    //    setFormData({
                    //     "nomor_rekening": data?.nomor_rekening,
                    //     "nominal": data?.nominal,
                    //     "keterangan": data?.keterangan,
                    //     "kode_valuta": data?.kode_valuta,
                    //     "nomor_referensi" : data?.nomor_referensi,
                    //     "info_transaksi": 
                    //     {
                    //         "saldo_teller": data?.info_transaksi?.saldo_teller_before,
                    //         "kode_valuta_teller": data?.info_transaksi?.kode_valuta_teller,
                    //         "nama_rekening": data?.info_transaksi?.nama_rekening,
                    //         "kode_valuta_rekening": data?.info_transaksi?.keterangan_valuta,
                    //         "nama_valuta_rekening": data?.info_transaksi?.nama_valuta_rekening,
                    //         "kode_cabang_rekening": data?.info_transaksi?.keterangan_valuta,
                    //         "nama_cabang_rekening": data?.info_transaksi?.nama_cabang_rekening,
                    //         "saldo_rekening": data?.info_transaksi?.saldo_rekening,
                    //         "produk": data?.info_transaksi?.produk,
                    //         "jenis_ttr": data?.info_transaksi?.jenis_ttr,
                    //         "tutup_kartu_atm": data?.info_transaksi?.tutup_kartu_atm,
                    //         "norek_pinbuk": data?.info_transaksi?.norek_pinbuk,
                    //         "nama_rekening_pinbuk": data?.info_transaksi?.nama_rekening_pinbuk,
                    //         "cabang_rekening_pinbuk": data?.info_transaksi?.cabang_rekening_pinbuk,
                    //         "keterangan_valuta": data?.info_transaksi?.keterangan_valuta,
                    //         "kurs_jual": data?.info_transaksi?.kurs_jual,
                    //         "kurs_beli": data?.info_transaksi?.kurs_beli,
                    //         "kurs_buku": data?.info_transaksi?.kurs_buku,
                    //         "nominal_ekuivalen": data?.info_transaksi?.nominal_ekuivalen,
                    //         "biaya": data?.info_transaksi?.biaya,
                    //         "biaya_ekuivalen": data?.info_transaksi?.biaya_ekuivalen
                    //     }
                    //    })
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        await wait(3000)
        setLoading(false)
    }


    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();

        openLoading()
        closeConfirmCustom("otorisasi");
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); setDataAlert({ show: true, body: remark, title: '', header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }





    function resetForm() {
        setFormData({
            "nomor_rekening": "",
            "nominal": 0,
            "keterangan": "",
            "kode_valuta": "IDR",
            "info_transaksi": {
                "saldo_teller": 0,
                "kode_valuta_teller": "",
                "nama_rekening": "",
                "kode_valuta_rekening": "IDR",
                "nama_valuta_rekening": "INDONESIA RUPIAH",
                "kode_cabang_rekening": "",
                "nama_cabang_rekening": "",
                "saldo_rekening": 0,
                "produk": "",
                "jenis_ttr": "T",
                "tutup_kartu_atm": "",
                "norek_pinbuk": "",
                "nama_rekening_pinbuk": "",
                "cabang_rekening_pinbuk": "",
                "keterangan_valuta": "INDONESIA RUPIAH",
                "kurs_jual": 1,
                "kurs_beli": 1,
                "kurs_buku": 1,
                "nominal_ekuivalen": 0,
                "biaya": 0,
                "biaya_ekuivalen": 0
            }

        });
    }

    // function getData Perorangan
    async function getDataDetail(dbId) {

        let url = "/rekening/local";
        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/tutuprekening/${dbId}`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    const keys_formData = Object.keys(formData);
                    let stateBase = {}
                    keys_formData?.forEach(kFormData => {
                        if ((formData[kFormData] instanceof Object) && !Array.isArray(formData[kFormData])) {
                            const tKey = Object.keys(formData[kFormData])
                            let dataChild = {}
                            tKey.forEach(k => {

                                let kD = data[k];
                                dataChild = {
                                    ...dataChild,
                                    [k]: kD
                                }
                            })
                            stateBase = {
                                ...stateBase,
                                [kFormData]: dataChild
                            }
                        } else if (data[kFormData] !== undefined) {
                            let data = data[kFormData]

                            stateBase = { ...stateBase, [kFormData]: data }
                        }
                    });
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
        // //// console.log(data)
    }

    // useEffect(function () {
    //     setFormData(pr => ({
    //         ...pr,
    //         nominal: (pr.info_transaksi.saldo_rekening + pr.info_transaksi.saldo_accrual_bagihasil) - (pr.info_transaksi.biaya - pr.info_transaksi.pajak_bagi_hasil)
    //     }))
    // }, [formData.info_transaksi.biaya, formData.info_transaksi.saldo_accrual_bagihasil,formData.info_transaksi.biaya, formData.info_transaksi.pajak_bagi_hasil ])


    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    useEffect(function (params) {
        if (paramUrl.mode !== "otorisasi") {
            resetForm();
        } else {

        }
        // getListBank();
    }, [paramUrl.mode])
    // fungsi post nasabah
    async function doTrx() {

        closeConfirm();
        // //// console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        let url = `/tutuprekening`;
        let bodyData = {
            ...formData,
            uid: keycloak.uid
        }
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: bodyData,
                url, // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: remark, title: ``, header: "Success", callBack: () => {
                        closeConfirm();
                        resetForm();
                        getSaldo();

                        keycloak.updateUID();
                    }
                });

                else if (status === "05") {
                    closeConfirm();
                    resetForm();
                    getSaldo();
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            // fieldRequired = ["nomorCIF", "nama_produk", "nama_rekening"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    // pilih nasabah
    async function pilihNasabah(dataNasabah, tipe) {
        setLoading(prevState => ({ ...prevState, loading: true }));
        let url = `/nasabah/${dataNasabah.nomor_cif}`;
        if (parseInt(tipe) === 1) url = `/nasabah-badan-usaha/${dataNasabah.nomor_cif}`;

        try {
            const resp = await doGet({
                url,
                service: "cif"
            });
            const { status, statusText, data, statusCode, remark } = resp;
            if (statusCode === 200) {
                if (status === "00") {

                    // Sesuaikan fieldnya disini juga
                    const { nasabahBadanUsaha } = data
                    let homePhone = nasabahBadanUsaha?.homePhone;
                    let mobilePhone = nasabahBadanUsaha?.mobilePhone;
                    let alamat = dataNasabah.alamat.toUpperCase();
                    let nomor_identitas = nasabahBadanUsaha.personalIdNumber;
                    let nomor_nasabah = dataNasabah.nomor_cif


                    if (tipe === 1) {
                        homePhone = nasabahBadanUsaha?.nasabahTeleponKantorKodeArea1 + nasabahBadanUsaha?.nasabahTeleponKantorNomor1;
                        mobilePhone = "";
                    }

                    setFormData(prevState => ({
                        ...prevState,
                        info_transaksi: {
                            ...prevState.info_transaksi,
                            data_pengirim: {
                                ...prevState.info_transaksi.data_pengirim,
                                nomor_nasabah,
                                nama: dataNasabah.nama,
                                alamat,
                                jenis_identitas: "KTP",
                                nomor_identitas,
                                nomor_tlpn: homePhone,
                            }
                        }
                    }));
                    return true;
                } else {
                    setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
                    setLoading(prevState => ({ ...prevState, loading: false }))
                    return false;
                }
            } else {
                setDataAlert({ show: true, body: remark, title: `${JSON.ify(statusText)}`, header: "Error", callBack: () => null });
                setLoading(prevState => ({ ...prevState, loading: false }))
                return false;
            }
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            setLoading(prevState => ({ ...prevState, loading: false }))
            return false;
        }

    }
    function confirmTrx() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    return <TutupRekeningContext.Provider
        value={{
            trState: {
                isLoading,
                filter,
                formData,
                formEditable,
                produk,
                dataBank,
                otorDesc
            },
            trAction: {
                closeAlert,
                closeConfirm,
                changeForm,
                pilihNasabah,
                confirmTrx,
                setProduk,
                resetForm,
                cekNasabah,
                getPengirim,
                responseOtor,
                setOtorDesc
            },
            ...modalNasabah
        }}
    >
        {children}
    </TutupRekeningContext.Provider>;
}


export default function useTutupRekening() { return useContext(TutupRekeningContext) };