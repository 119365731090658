import React, { memo, useRef } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { baseUrl } from './../../../../app.config';
import { thousandSeparator } from '../../../../utils';
import { withKeycloak } from '@react-keycloak/web';
import queryString from 'query-string';

class PendebitanPengkreditan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_user: 1,
            keterangan: null,
            no_referensi: null,
            nilai_transaksi: 0,
            nilai_ekuivalen: 0,
            valuta: 'IDR',
            kurs_jual: 1,
            kurs_beli: 1,
            kurs_buku: 1,
            nama_debit: '',
            nama_kredit: null,
            cabang_rekening_debit: null,
            cabang_rekening_kredit: null,
            cabang_gl_debit: null,
            cabang_gl_kredit: null,
            no_rekening_debit: null,
            no_rekening_kredit: null,
            option: '1',
            productName: null,
            jenis_nasabah: null,
            nomor_cif_debit: '',
            no_gl_debit: null,
            no_gl_kredit: null,
            nama_gl_debit: null,
            nama_gl_kredit: null,
            valuta_debit: null,
            valuta_kredit: null,
            valuta_rekening_debit: null,
            valuta_rekening_kredit: null,
            showModalConfirm: false,
            showModalBatal: false,
            showModalBatalInfo: false,
            showModalSuccess: false,
            showModalError: false,
            showModalSvs: false,
            showModalWarning: false,
            notFoundRekKredit: false,
            notFoundRekDebit: false,
            notFoundGLKredit: false,
            notFoundGLDebit: false,
            svsData: [],
            svsParams: [
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
                {
                    data: {
                        nomor_cif: null,
                        encoded_key_document: 'string',
                        filename: '',
                        image_tag: '',
                        remark: '',
                        type: 'Nasabah Badan Usaha',
                    },
                    options: {
                        isChoosedFile: false,
                        selectedFile: null,
                        selectedFileUrl: null,
                    },
                },
            ],
        };
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value,
        });
    };

    handleFormatNumber = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        // const newValue = thousandSeparator.format(value);
        const newValue = thousandSeparator.formatInput(value);

        if (newValue !== 'NaN') {
            this.setState({
                [name]: newValue,
            });
        }
    };

    handleChange(event) {
        this.setState({ option: event.target.value });
    }

    hitungEkuivalen = (value) => {
        if (this.state.valuta_debit != this.state.valuta_kredit) {
            if (this.state.valuta_debit != 'IDR') {
                this.setState({
                    nilai_ekuivalen: value * this.state.kurs_jual,
                });
            } else {
                this.setState({
                    nilai_ekuivalen: value * this.state.kurs_beli,
                });
            }
        } else {
            this.setState({
                nilai_ekuivalen: value * this.state.kurs_buku,
            });
        }
    };

    resetFormInput = () => {
        this.setState({
            keterangan: '',
            no_referensi: '',
            nilai_transaksi: 0,
            nilai_ekuivalen: 0,
            valuta: 'IDR',
            kurs_jual: 1,
            kurs_beli: 1,
            kurs_buku: 1,
            nama_debit: '',
            nama_kredit: '',
            cabang_rekening_debit: '',
            cabang_rekening_kredit: '',
            cabang_gl_debit: '',
            cabang_gl_kredit: '',
            no_rekening_debit: '',
            no_rekening_kredit: '',
            option: '1',
            productName: '',
            jenis_nasabah: '',
            nomor_cif_debit: '',
            no_gl_debit: '',
            no_gl_kredit: '',
            nama_gl_debit: '',
            nama_gl_kredit: '',
            valuta_debit: '',
            valuta_kredit: '',
            valuta_rekening_debit: '',
            valuta_rekening_kredit: '',
            showModalBatal: false,
            showModalBatalInfo: true,
        });
    };

    getNomorRekeningDebet = (value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/rekening/tabungan/detail/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    this.setState({
                        product_name_debet: dataDetail.produk,
                        valuta_debit: dataDetail.valuta,
                        valuta_rekening_debit: dataDetail.valuta,
                        nama_debit: dataDetail.nama,
                        cabang_rekening_debit: dataDetail.cabang,
                        nomor_cif_debit: dataDetail.detail_nasabah.nomor_cif,
                        notFoundRekDebit: false,
                    });
                } else {
                    this.setState({
                        product_name_debet: '',
                        valuta_debit: '',
                        valuta_rekening_debit: '',
                        nama_debit: '',
                        cabang_rekening_debit: '',
                        nomor_cif_debit: '',
                        notFoundRekDebit: true,
                    });
                }
            });
    };

    getNomorRekeningKredit = (value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/rekening/tabungan/detail/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list;
                    this.setState({
                        product_name_kredit: dataDetail.produk,
                        valuta_kredit: dataDetail.valuta,
                        valuta_rekening_kredit: dataDetail.valuta,
                        nama_kredit: dataDetail.nama,
                        cabang_rekening_kredit: dataDetail.cabang,
                        notFoundRekKredit: true,
                    });
                } else {
                    this.setState({ notFoundRekKredit: true });
                }
            });
    };

    getNomorGlDebet = (value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/teller/general-ledger/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list[0];
                    this.setState({
                        valuta_gl_debit: dataDetail.valuta_gl,
                        nama_gl_debit: dataDetail.nama_gl,
                        cabang_gl_debit: dataDetail.cabang_gl,
                        valuta_debit: dataDetail.valuta_gl,
                        notFoundGLDebit: false,
                    });
                } else {
                    this.setState({ notFoundGLDebit: true });
                }
            });
    };

    getNomorGlKredit = (value) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + '/teller/general-ledger/' + value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    let dataDetail = data.list[0];
                    // console.log(dataDetail);
                    this.setState({
                        valuta_gl_kredit: dataDetail.valuta_gl,
                        nama_gl_kredit: dataDetail.nama_gl,
                        cabang_gl_kredit: dataDetail.cabang_gl,
                        valuta_kredit: dataDetail.valuta_gl,
                        notFoundGLKredit: false,
                    });
                } else {
                    this.setState({ notFoundGLKredit: true });
                }
            });
    };

    postTransaksiUmum = async () => {
        let body = {
            id_user: this.props.keycloak.idTokenParsed.preferred_username,
            keterangan: this.state.keterangan,
            no_referensi: this.state.no_referensi,
            nilai_transaksi: thousandSeparator.unformat(this.state.nilai_transaksi),
            nilai_ekuivalen: thousandSeparator.unformat(this.state.nilai_transaksi),
            valuta: this.state.valuta,
            kurs_jual: thousandSeparator.unformat(this.state.kurs_jual),
            kurs_beli: thousandSeparator.unformat(this.state.kurs_beli),
            kurs_buku: thousandSeparator.unformat(this.state.kurs_buku),
        };

        if (this.state.option === '1') {
            body['no_rekening_debit'] = this.state.no_rekening_debit;
            body['no_gl_kredit'] = this.state.no_gl_kredit;
            body['nama_debit'] = this.state.nama_debit;
            body['nama_kredit'] = this.state.nama_gl_kredit;
            body['cabang_rekening_debit'] = this.state.cabang_rekening_debit;
            body['cabang_rekening_kredit'] = this.state.cabang_gl_kredit;

            if (this.state.no_rekening_debit === null) {
                this.setState({
                    showModalWarning: true,
                    messageWarning: 'Nomor rekening debit harus diisi',
                });
                return false;
            }

            if (this.state.no_gl_kredit === null) {
                this.setState({
                    showModalWarning: true,
                    messageWarning: 'Kode GL kredit harus diisi',
                });
                return false;
            }
        }

        if (this.state.option === '2') {
            body['no_rekening_debit'] = this.state.no_rekening_debit;
            body['no_rekening_kredit'] = this.state.no_rekening_kredit;
            body['nama_debit'] = this.state.nama_debit;
            body['nama_kredit'] = this.state.nama_kredit;
            body['cabang_rekening_debit'] = this.state.cabang_rekening_debit;
            body['cabang_rekening_kredit'] = this.state.cabang_rekening_kredit;

            if (this.state.no_rekening_debit === null) {
                this.setState({
                    showModalWarning: true,
                    messageWarning: 'Nomor rekening debit harus diisi',
                });
                return false;
            }

            if (this.state.no_rekening_kredit === null) {
                this.setState({
                    showModalWarning: true,
                    messageWarning: 'Nomor rekening kredit harus diisi',
                });
                return false;
            }
        }

        if (this.state.option === '3') {
            body['no_gl_debit'] = this.state.no_gl_debit;
            body['no_rekening_kredit'] = this.state.no_rekening_kredit;
            body['nama_debit'] = this.state.nama_gl_debit;
            body['nama_kredit'] = this.state.nama_kredit;
            body['cabang_rekening_debit'] = this.state.cabang_gl_debit;
            body['cabang_rekening_kredit'] = this.state.cabang_rekening_kredit;

            if (this.state.no_gl_debit === null) {
                this.setState({
                    showModalWarning: true,
                    messageWarning: 'Kode GL debit harus diisi',
                });
                return false;
            }

            if (this.state.no_rekening_kredit === null) {
                this.setState({
                    showModalWarning: true,
                    messageWarning: 'Nomor rekening kredit harus diisi',
                });
                return false;
            }
        }

        if (this.state.option === '4') {
            body['no_gl_debit'] = this.state.no_gl_debit;
            body['no_gl_kredit'] = this.state.no_gl_kredit;
            body['nama_debit'] = this.state.nama_gl_debit;
            body['nama_kredit'] = this.state.nama_gl_kredit;
            body['cabang_rekening_debit'] = this.state.cabang_gl_debit;
            body['cabang_rekening_kredit'] = this.state.cabang_gl_kredit;

            if (this.state.no_gl_debit === null) {
                this.setState({
                    showModalWarning: true,
                    messageWarning: 'Kode GL debit harus diisi',
                });
                return false;
            }

            if (this.state.no_gl_kredit === null) {
                this.setState({
                    showModalWarning: true,
                    messageWarning: 'Kode GL kredit harus diisi',
                });
                return false;
            }
        }

        // console.log(body);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(body),
        };

        var urlPost = '/teller/transaksi/debit/rek-gl/create';
        if (this.state.option === '1') {
            urlPost = '/teller/transaksi/debit/rek-gl/create';
        }

        if (this.state.option === '2') {
            urlPost = '/teller/transaksi/debit/rek-rek/create';
        }

        if (this.state.option === '3') {
            urlPost = '/teller/transaksi/debit/gl-rek/create';
        }

        if (this.state.option === '4') {
            urlPost = '/teller/transaksi/debit/gl-gl/create';
        }
        // console.log(this.state.option);
        // console.log('urlPost = ' + urlPost);

        fetch(baseUrl + urlPost, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    this.setState({
                        showModalConfirm: false,
                        showModalSuccess: true,
                    });
                } else {
                    this.setState({
                        showModalConfirm: false,
                        showModalError: true,
                        messageError: data.status,
                    });
                }
            });
    };

    getData = () => {
        const queryParams = queryString.parse(this.props.location.search);
        let id = this.state.nomor_cif_debit;
        let url =
            queryParams.from === 'local'
                ? `/nasabah/svs/local/find-all/${id}`
                : `/nasabah/svs/mambu/find/${id}`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        };
        fetch(baseUrl + url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let svsParams = [...this.state.svsParams];
                let dataList = queryParams.from === 'local' ? data.list : data.list?.db?.list;
                if (dataList && dataList.length > 0) {
                    dataList.map(
                        (value, index) =>
                            (svsParams[index] = {
                                data: {
                                    nomor_cif: value.nomor_cif,
                                    encoded_key_document: value.encoded_key_document,
                                    filename: value.filename,
                                    signed_url: value.signed_url,
                                    image_tag: value.image_tag,
                                    remark: value.remark,
                                    type: value.type,
                                },
                                options: {
                                    isChoosedFile: false,
                                    selectedFile: null,
                                    selectedFileUrl: null,
                                },
                            })
                    );
                }
                this.setState({
                    svsData: dataList,
                    svsParams: svsParams,
                });
            });
    };

    render() {
        return (
            <>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-2 mb-3">
                        <li className="breadcrumb-item color-primary">Transaksi</li>
                        <li className="breadcrumb-item">
                            <a href="#">Debit Rekening - Kredit GL</a>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="bns-form">
                                    <div className="row">
                                        <div className="col-3">
                                            {this.state.option == 1 ? (
                                                <>
                                                    <p className="fs-18">
                                                        DEBIT{' '}
                                                        <span className="text-success">
                                                            REKENING
                                                        </span>
                                                    </p>
                                                    <p className="fs-18">
                                                        KREDIT{' '}
                                                        <span className="text-danger fs-18">
                                                            GL
                                                        </span>
                                                    </p>
                                                </>
                                            ) : null}
                                            {this.state.option == 2 ? (
                                                <>
                                                    <p className="fs-18">
                                                        DEBIT{' '}
                                                        <span className="text-success">
                                                            REKENING
                                                        </span>
                                                    </p>
                                                    <p className="fs-18">
                                                        KREDIT{' '}
                                                        <span className="text-danger fs-18">
                                                            REKENING
                                                        </span>
                                                    </p>
                                                </>
                                            ) : null}
                                            {this.state.option == 3 ? (
                                                <>
                                                    <p className="fs-18">
                                                        DEBIT{' '}
                                                        <span className="text-success">GL</span>
                                                    </p>
                                                    <p className="fs-18">
                                                        KREDIT{' '}
                                                        <span className="text-danger fs-18">
                                                            REKENING
                                                        </span>
                                                    </p>
                                                </>
                                            ) : null}
                                            {this.state.option == 4 ? (
                                                <>
                                                    <p className="fs-18">
                                                        DEBIT{' '}
                                                        <span className="text-success">GL</span>
                                                    </p>
                                                    <p className="fs-18">
                                                        KREDIT{' '}
                                                        <span className="text-danger fs-18">
                                                            GL
                                                        </span>
                                                    </p>
                                                </>
                                            ) : null}
                                        </div>
                                        <div className="col-9">
                                            <div className="row">
                                                <div className="col-4 form-group">
                                                    <label htmlFor="">No. Referensi</label>
                                                    {/* <input type="text" className="form-control"/> */}
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        value={this.state.no_referensi}
                                                        name="no_referensi"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-8 form-group">
                                                    <label htmlFor="">Valuta Transaksi</label>
                                                    <div className="form-row">
                                                        <div className="col-2 pr-0">
                                                            {/* <select name="" id="" className="form-control">
                                  <option value="">IDR</option>
                                </select> */}
                                                            <select
                                                                name="valuta"
                                                                className="form-control"
                                                                value={this.state.valuta}
                                                                onChange={(e) => {
                                                                    this.handleInputChange(e);
                                                                }}
                                                            >
                                                                <option value="IDR">IDR</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-10 pl-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value="INDONESIAN RUPIAH"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 form-group">
                                                    <label htmlFor="">Nilai Transaksi</label>
                                                    {/* <input type="text" class="form-control" /> */}
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        value={this.state.nilai_transaksi}
                                                        name="nilai_transaksi"
                                                        onChange={(e) => {
                                                            this.handleFormatNumber(e);
                                                        }}
                                                        // onKeyPress={(event) => {
                                                        //     if (!/[0-9]/.test(event.key)) {
                                                        //         event.preventDefault();
                                                        //     }
                                                        // }}
                                                    />
                                                </div>
                                                <div className="col-8 form-group">
                                                    <div class="form-row">
                                                        <div class="col-4">
                                                            <label htmlFor="">Kurs Buku</label>
                                                            {/* <input type="text" class="form-control" value="14.132" readOnly /> */}
                                                            <input
                                                                type="number"
                                                                class="form-control"
                                                                readOnly
                                                                value={this.state.kurs_buku}
                                                            />
                                                        </div>
                                                        <div class="col-4">
                                                            <label htmlFor="">Kurs Jual</label>
                                                            {/* <input type="text" class="form-control" value="14.132" /> */}
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                value={this.state.kurs_jual}
                                                                name="kurs_jual"
                                                                onChange={(e) => {
                                                                    this.handleFormatNumber(e);
                                                                }}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div class="col-4">
                                                            <label htmlFor="">Kurs Beli</label>
                                                            {/* <input type="text" class="form-control" value="15.132" /> */}
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                value={this.state.kurs_beli}
                                                                name="kurs_beli"
                                                                onChange={(e) => {
                                                                    this.handleFormatNumber(e);
                                                                }}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 form-group">
                                                    <label htmlFor="">Nilai ekuivalen</label>
                                                    {/* <input type="text" class="form-control" readOnly /> */}
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        readOnly
                                                        value={this.state.nilai_transaksi}
                                                        onChange={(e) => {
                                                            this.handleFormatNumber(e);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-8 form-group">
                                                    <label htmlFor="">Keterangan</label>
                                                    {/* <textarea name="" className="form-control" rows="1"></textarea> */}
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        value={this.state.keterangan}
                                                        name="keterangan"
                                                        onChange={(e) => {
                                                            this.handleInputChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <div className="form-row mt-1">
                                            <div className="col-4 mt-1">
                                                <span htmlFor="">DEBIT/KREDIT</span>
                                            </div>
                                            <div className="form-group col-8">
                                                <select
                                                    onChange={(e) => this.handleChange(e)}
                                                    value={this.state.option}
                                                    className="form-control form-control-sm"
                                                >
                                                    <option value="1">REKENING - GL</option>
                                                    <option value="2">REKENING - REKENING</option>
                                                    <option value="3">GL - REKENING</option>
                                                    <option value="4">GL - GL</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 text-right">
                                        {/* <button className="btn btn-outline-secondary">Batal</button> */}
                                        {/* <button className="btn btn-warning ml-3 px-5">Proses</button> */}
                                        <div className="col-6 text-right">
                                            <button
                                                className="btn btn-outline-secondary"
                                                onClick={() => {
                                                    this.setState({ showModalBatal: true });
                                                }}
                                            >
                                                Batal
                                            </button>
                                            <button
                                                className="btn btn-primary ml-3 px-5"
                                                onClick={() => {
                                                    this.setState({ showModalConfirm: true });
                                                }}
                                            >
                                                Proses
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal
                                show={this.state.showModalConfirm}
                                onHide={() => this.setState({ showModalConfirm: false })}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Konfirmasi</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Lakukan proses TU (Pendebitan / Pengkreditan) ?
                                </Modal.Body>
                                <Modal.Footer>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => this.setState({ showModalConfirm: false })}
                                    >
                                        Tidak
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.postTransaksiUmum();
                                        }}
                                    >
                                        Ya
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={this.state.showModalSuccess}
                                onHide={() => this.setState({ showModalSuccess: false })}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Selesai</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Proses Transaksi Umum berhasil dilakukan</Modal.Body>
                                <Modal.Footer>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.setState({ showModalSuccess: false });
                                            this.props.history.push(
                                                '/informasi-transaksi/informasi-transaksi-hari-ini'
                                            );
                                        }}
                                    >
                                        Selesai
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={this.state.showModalBatal}
                                onHide={() => this.setState({ showModalBatal: false })}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Konfirmasi</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Batalkan proses input transaksi ?</Modal.Body>
                                <Modal.Footer>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => this.setState({ showModalBatal: false })}
                                    >
                                        Tidak
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.resetFormInput();
                                        }}
                                    >
                                        Ya
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={this.state.showModalBatalInfo}
                                onHide={() => this.setState({ showModalBatalInfo: false })}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Input Transaksi Dibatalkan {this.state.showModalBatalInfo}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{this.state.messageError}</Modal.Body>
                                <Modal.Footer>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.setState({ showModalBatalInfo: false });
                                        }}
                                    >
                                        Tutup
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={this.state.showModalError}
                                onHide={() => this.setState({ showModalError: false })}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Opps.. Sedang Terjadi Kesalahan {this.state.showModalError}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{this.state.messageError}</Modal.Body>
                                <Modal.Footer>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.setState({ showModalError: false });
                                        }}
                                    >
                                        Tutup
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={this.state.showModalWarning}
                                onHide={() => this.setState({ showModalWarning: false })}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Informasi</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{this.state.messageWarning}</Modal.Body>
                                <Modal.Footer>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.setState({ showModalWarning: false });
                                        }}
                                    >
                                        Tutup
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={this.state.showModalSvs}
                                onHide={() => this.setState({ showModalSvs: false })}
                                centered
                            >
                                <Modal.Header>
                                    <Modal.Title>Data Grafis / Tanda Tangan</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="card">
                                        <div className="card-body p0">
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Image Tag</th>
                                                        <th>Taq Sequence</th>
                                                        <th>Remark</th>
                                                        <th>Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.svsData &&
                                                    this.state.svsData.length > 0
                                                        ? this.state.svsData.map((data, index) => {
                                                              return (
                                                                  <tr
                                                                      key={`listsaccountimages${index}`}
                                                                  >
                                                                      <td>{data?.image_tag}</td>
                                                                      <td>{index + 1}</td>
                                                                      <td>{data?.remark}</td>
                                                                      <td>{data?.type}</td>
                                                                  </tr>
                                                              );
                                                          })
                                                        : null}
                                                </tbody>
                                            </table>
                                            <div className="p-3">
                                                <div className="row">
                                                    {}
                                                    {this.state.svsData &&
                                                    this.state.svsData.length > 0
                                                        ? this.state.svsData.map((data, index) => {
                                                              return (
                                                                  <div
                                                                      className="col-4"
                                                                      key={`listsaccountimagessign${index}`}
                                                                  >
                                                                      <p>
                                                                          Image {index + 1} -{' '}
                                                                          {data?.image_tag}
                                                                      </p>
                                                                      <div className="svs-box">
                                                                          <div className="mt-5 text-center">
                                                                              <img
                                                                                  src={
                                                                                      data?.signed_url
                                                                                  }
                                                                                  height={110}
                                                                              />
                                                                          </div>
                                                                      </div>
                                                                      <p className="border p-2 mt-2 fs-12">
                                                                          {data?.remark}
                                                                      </p>
                                                                  </div>
                                                              );
                                                          })
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => this.setState({ showModalSvs: false })}
                                    >
                                        Tutup
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                {this.state.option == 1 ? (
                    <div className="row">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <hr />
                                        </div>
                                        <div className="col-auto text-success">Rekening Debit</div>
                                    </div>
                                    <div className="bns-form">
                                        <div className="form-group">
                                            <label htmlFor="">No. Rekening</label>
                                            <div class="input-group">
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorRekeningDebet(e.target.value);
                                                    }}
                                                    name="no_rekening_debit"
                                                    value={this.state.no_rekening_debit}
                                                    isInvalid={this.state.notFoundRekDebit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    No rekening tidak dapat ditemukan
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                class="form-control"
                                                readOnly
                                                value={this.state.nama_debit}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="col-2">
                                                    <label htmlFor="">Valuta</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        value={this.state.valuta_rekening_debit}
                                                    />
                                                </div>
                                                <div className="col-10">
                                                    <label htmlFor="">Cabang Rekening</label>
                                                    <div className="form-row">
                                                        <div className="col-2 pr-0">
                                                            {/* <input type="text" className="form-control" value="000" readOnly/> */}
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                readOnly
                                                                value={
                                                                    this.state.cabang_rekening_debit
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-10 pl-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value="KCP KOTA BANDUNG"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <label htmlFor="">Produk</label> */}
                                            {/* <input type="text" className="form-control" readOnly /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.product_name_debet}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-block btn-outline-secondary"
                                                onClick={() => {
                                                    this.getData();
                                                    this.setState({ showModalSvs: true });
                                                }}
                                                disabled={this.state.nama_debit === ''}
                                            >
                                                Lihat Data Grafis/Tanda Tangan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <hr />
                                        </div>
                                        <div className="col-auto text-primary">GL Kredit</div>
                                    </div>
                                    <div className="bns-form">
                                        <div className="form-group">
                                            <label htmlFor="">Kode GL</label>
                                            <div class="input-group">
                                                {/* <input type="text" class="form-control" placeholder="" />
                            <div class="input-group-prepend">
                              <div class="input-group-text"><img src={arrowToLeft}/></div>
                            </div> */}
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorGlKredit(e.target.value);
                                                    }}
                                                    name="no_gl_kredit"
                                                    value={this.state.no_gl_kredit}
                                                    isInvalid={this.state.notFoundGLKredit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Kode GL tidak dapat ditemukan
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <input type="text" className="form-control" readOnly /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.nama_gl_kredit}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Cabang Rekening</label>
                                            <div className="form-row">
                                                <div className="col-2 pr-0">
                                                    {/* <input type="text" className="form-control"/> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        value={this.state.cabang_gl_kredit}
                                                    />
                                                </div>
                                                <div className="col-10 pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.option == 2 ? (
                    <div className="row">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <hr />
                                        </div>
                                        <div className="col-auto text-success">Rekening Debit</div>
                                    </div>
                                    <div className="bns-form">
                                        <div className="form-group">
                                            <label htmlFor="">No. Rekening</label>
                                            <div class="input-group">
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorRekeningDebet(e.target.value);
                                                    }}
                                                    name="no_rekening_debit"
                                                    value={this.state.no_rekening_debit}
                                                    isInvalid={this.state.notFoundRekDebit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    No rekening tidak dapat ditemukan
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.nama_debit}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="col-2">
                                                    <label htmlFor="">Valuta</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        value={this.state.valuta_rekening_debit}
                                                    />
                                                </div>
                                                <div className="col-10">
                                                    <label htmlFor="">Cabang Rekening</label>
                                                    <div className="form-row">
                                                        <div className="col-2 pr-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                readOnly
                                                                value={
                                                                    this.state.cabang_rekening_debit
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-10 pl-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value="KCP KOTA BANDUNG"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.product_name_debet}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-block btn-outline-secondary"
                                                onClick={() => {
                                                    this.getData();
                                                    this.setState({ showModalSvs: true });
                                                }}
                                                disabled={this.state.nama_debit === ''}
                                            >
                                                Lihat Data Grafis/Tanda Tangan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <hr />
                                        </div>
                                        <div className="col-auto text-primary">Rekening Kredit</div>
                                    </div>
                                    <div className="bns-form">
                                        <div className="form-group">
                                            <label htmlFor="">No. Rekening</label>
                                            <div class="input-group">
                                                {/* <input type="text" class="form-control" id="inlineFormInputGroupUsername" placeholder="" />
                            <div class="input-group-prepend">
                              <div class="input-group-text"><img src={arrowToLeft}/></div>
                            </div> */}
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorRekeningKredit(e.target.value);
                                                    }}
                                                    name="no_rekening_kredit"
                                                    value={this.state.no_rekening_kredit}
                                                    isInvalid={this.state.notFoundRekKredit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    No rekening tidak dapat ditemukan
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <label htmlFor="">Nama Nasabah</label> */}
                                            {/* <input type="text" className="form-control" readOnly /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.nama_kredit}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="col-3">
                                                    {/* <label htmlFor="">Valuta Rekening</label> */}
                                                    {/* <input type="text" className="form-control" value="IDR" readOnly /> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        value={this.state.valuta_rekening_kredit}
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <label htmlFor="">Cabang Rekening</label>
                                                    <div className="form-row">
                                                        <div className="col-2 pr-0">
                                                            {/* <input type="text" className="form-control" value="000" readOnly/> */}
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                readOnly
                                                                value={
                                                                    this.state
                                                                        .cabang_rekening_kredit
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-10 pl-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value="KCP KOTA BANDUNG"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <label htmlFor="">Produk</label> */}
                                            {/* <input type="text" className="form-control" readOnly /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.product_name_kredit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.option == 3 ? (
                    <div className="row">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <hr />
                                        </div>
                                        <div className="col-auto text-success">GL Debit</div>
                                    </div>
                                    <div className="bns-form">
                                        <div className="form-group">
                                            <label htmlFor="">Kode GL</label>
                                            <div class="input-group">
                                                {/* <input type="text" class="form-control" placeholder="" />
                            <div class="input-group-prepend">
                              <div class="input-group-text"><img src={arrowToLeft}/></div>
                            </div> */}
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorGlDebet(e.target.value);
                                                    }}
                                                    name="no_gl_debit"
                                                    value={this.state.no_gl_debit}
                                                    isInvalid={this.state.notFoundGLDebit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Kode GL tidak dapat ditemukan
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <input type="text" className="form-control" readOnly /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.nama_gl_debit}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Cabang Rekening</label>
                                            <div className="form-row">
                                                <div className="col-2 pr-0">
                                                    {/* <input type="text" className="form-control"/> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        value={this.state.cabang_gl_debit}
                                                    />
                                                </div>
                                                <div className="col-10 pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <hr />
                                        </div>
                                        <div className="col-auto text-primary">Rekening Kredit</div>
                                    </div>
                                    <div className="bns-form">
                                        <div className="form-group">
                                            <label htmlFor="">No. Rekening</label>
                                            <div class="input-group">
                                                {/* <input type="text" class="form-control" id="inlineFormInputGroupUsername" placeholder="" />
                            <div class="input-group-prepend">
                              <div class="input-group-text"><img src={arrowToLeft}/></div>
                            </div> */}
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorRekeningKredit(e.target.value);
                                                    }}
                                                    name="no_rekening_kredit"
                                                    value={this.state.no_rekening_kredit}
                                                    isInvalid={this.state.notFoundRekKredit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    No rekening tidak dapat ditemukan
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <label htmlFor="">Nama Nasabah</label> */}
                                            {/* <input type="text" className="form-control" readOnly /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.nama_kredit}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="col-3">
                                                    {/* <label htmlFor="">Valuta Rekening</label> */}
                                                    {/* <input type="text" className="form-control" value="IDR" readOnly /> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        value={this.state.valuta_rekening_kredit}
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <label htmlFor="">Cabang Rekening</label>
                                                    <div className="form-row">
                                                        <div className="col-2 pr-0">
                                                            {/* <input type="text" className="form-control" value="000" readOnly/> */}
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                readOnly
                                                                value={
                                                                    this.state
                                                                        .cabang_rekening_kredit
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-10 pl-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value="KCP KOTA BANDUNG"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <label htmlFor="">Produk</label> */}
                                            {/* <input type="text" className="form-control" readOnly /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.product_name_kredit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.state.option == 4 ? (
                    <div className="row">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <hr />
                                        </div>
                                        <div className="col-auto text-success">GL Debit</div>
                                    </div>
                                    <div className="bns-form">
                                        <div className="form-group">
                                            <label htmlFor="">Kode GL</label>
                                            <div class="input-group">
                                                {/* <input type="text" class="form-control" placeholder="" />
                            <div class="input-group-prepend">
                              <div class="input-group-text"><img src={arrowToLeft}/></div>
                            </div> */}
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorGlDebet(e.target.value);
                                                    }}
                                                    name="no_gl_debit"
                                                    value={this.state.no_gl_debit}
                                                    isInvalid={this.state.notFoundGLDebit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Kode GL tidak dapat ditemukan
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <input type="text" className="form-control" readOnly /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.nama_gl_debit}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Cabang Rekening</label>
                                            <div className="form-row">
                                                <div className="col-2 pr-0">
                                                    {/* <input type="text" className="form-control"/> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        value={this.state.cabang_gl_debit}
                                                    />
                                                </div>
                                                <div className="col-10 pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <hr />
                                        </div>
                                        <div className="col-auto text-primary">GL Kredit</div>
                                    </div>
                                    <div className="bns-form">
                                        <div className="form-group">
                                            <label htmlFor="">Kode GL</label>
                                            <div class="input-group">
                                                {/* <input type="text" class="form-control" placeholder="" />
                            <div class="input-group-prepend">
                              <div class="input-group-text"><img src={arrowToLeft}/></div>
                            </div> */}
                                                <Form.Control
                                                    onChange={(e) => {
                                                        this.handleInputChange(e);
                                                        this.getNomorGlKredit(e.target.value);
                                                    }}
                                                    name="no_gl_kredit"
                                                    value={this.state.no_gl_kredit}
                                                    isInvalid={this.state.notFoundGLKredit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Kode GL tidak dapat ditemukan
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <input type="text" className="form-control" readOnly /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.nama_gl_kredit}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Cabang Rekening</label>
                                            <div className="form-row">
                                                <div className="col-2 pr-0">
                                                    {/* <input type="text" className="form-control"/> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        value={this.state.cabang_gl_kredit}
                                                    />
                                                </div>
                                                <div className="col-10 pl-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

export default withKeycloak(PendebitanPengkreditan);
