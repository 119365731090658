import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { addCommas, removeNonNumeric } from '../../../../module';
import { TableList } from '../../../controls';
import ModalConfirmCustom from '../../../controls/modalAlert/modalConfirmCustomChild';
import PrintArea from './component/printArea';
import useCetakDenom, { CDProvider } from './useCetakDenom';

const CDPage = function () {
    const { cdState, cdAction } = useCetakDenom();
    const { mode } = useParams();
    const history = useHistory();
    const refPrint = useRef();
    const printF = useReactToPrint({
        content: ()=> refPrint?.current,
    })

    function sortingDenom(dataDenom = [], namaDenom){
        return dataDenom.filter(({jenis = ""}) => (
            jenis.toUpperCase() === namaDenom.toUpperCase()
        )).sort(function(a,b){
            return b.nilai_denominasi - a.nilai_denominasi ;
        })
    }
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Cetak Denominasi</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    {/* <li className="breadcrumb-item active" aria-current="page">
                        Ambil Kas ke Khasanah
                    </li> */}
                </ol>
            </nav>

            <div className="row">
                <div className="col-md-12">

                    <div style={{ width: "70%", margin: "auto" }}>
                        <PrintArea ref={refPrint} />
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title text-base">
                                            Informasi Teller
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>User ID Teller</label>
                                            <div className="input-group">
                                                <input type="text" readOnly className="form-control col"  value={cdState.formData?.user_id}  />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Valuta</label>
                                            <div className="input-group">
                                                <div className="input-group-append col-3">
                                                    <input type="text" className="form-control" disabled value={cdState.formData?.kode_valuta} />
                                                </div>
                                                <input type="text" readOnly className="form-control col"  value={cdState.formData?.keterangan_valuta} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Total Saldo</label>
                                            <div className="input-group">
                                                <div className="input-group-append col-3">
                                                    <input type="text" className="form-control" disabled value="IDR" />
                                                </div>
                                                <input type="text" className="form-control col"
                                                    readOnly
                                                    value={
                                                        // parseInt(cdState.formData?.total_saldo).toLocaleString()+'.00'
                                                        addCommas( parseFloat(cdState.formData?.total_saldo || 0).toFixed(2))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title text-base">
                                            Denominasi
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <TableList
                                            withCard={false}
                                            pagination={false}
                                            filter={false}
                                            withActions={false}
                                            maxHeight="1500px"
                                            columns={[
                                                {
                                                    name: 'Nilai Denominasi',
                                                    field: 'nilai_denominasi',
                                                    className: "text-right",
                                                    maxWidth:"100px",
                                                    onRender: (item) => addCommas(item.nilai_denominasi)
                                                },
                                                {
                                                    name: 'Jenis',
                                                    field: 'jenis',
                                                },
                                                {
                                                    name: 'Jumlah Lembar',
                                                    field: 'jumlah_lembar_koin',
                                                    className: 'text-right',
                                                    maxWidth: "100px",
                                                    onRender: (item, key) => (
                                                        addCommas(item.jumlah_lembar_koin)
                                                        // <input
                                                        //     type="text"
                                                        //     value={addCommas(item.jumlah_lembar_koin)}
                                                        //     readOnly={!cdState.formEditable}
                                                        //     className="form-control form-control-sm"
                                                        //     onChange={(e) => cdAction.changeJumlahLembar(removeNonNumeric(e.target.value), key)}
                                                        // />
                                                    )
                                                },
                                                {
                                                    name: 'Total Nominal',
                                                    field: 'total_nominal',
                                                    className: "text-right",
                                                    onRender: (item) => <> {addCommas(item?.total_nominal || "0")}, -</>
                                                },
                                            ]}
                                            data={
                                                [
                                                   
                                                    ...( sortingDenom( cdState.formData?.denominasi || [], "lembar") || []),
                        
                                                    ...( sortingDenom( cdState.formData?.denominasi || [], "koin") || []),
                                                    
                                                    ...( sortingDenom( cdState.formData?.denominasi || [], "lembar (utle)") || []),
                                                ] 
                                            }

                                        />
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-12">
                                                <button className="btn btn-primary float-right" onClick={printF}>
                                                    <i className="fa fa-print"></i> Cetak
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => cdAction.setOtorDesc(e.target.value)}
                    value={cdState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )
}

export default function () {
    return <CDProvider>
        <CDPage />
    </CDProvider>
}