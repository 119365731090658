import React from 'react';
import clsx from 'clsx';
import { FormCheck, FormControl } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { TableGrid } from '../../../controls';



const PeragaanBrankasPage = () => {

    return(
        <React.Fragment>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Peragaan Brankas
                </Breadcrumb.Section>
                
            </Breadcrumb>

            <div className="tw-bg-white tw-rounded-lg">
            <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
            <div className="tw-flex tw-items-center tw-mb-5">
                                <div className="tw-text-black tw-mr-2 tw-text-16">
                                Rekening Brankas
                                </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-6 tw-items-center tw-gap-x-2 tw-gap-y-1 tw-text-black tw-text-12">
                                <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                    <span>Nomor Rekening</span>
                                    <span>:</span>
                                </span>
                                <div className="tw-col-span-5">00000001231</div>
                                
                                <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                    <span>Kantor Cabang</span>
                                    <span>:</span>
                                </span>
                                <div className="tw-col-span-5">KC Bandung Soekarno - Hatta JKT</div>
                                
                                
                            </div>
                </div>
            </div>

            <div className="tw-bg-white tw-rounded-lg tw-mt-10 tw-text-center ">
            <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold  tw-inline-block">
                    Total Saldo Khanasah :
            </div>
            <div className="text-primary tw-text-18 tw-font-bold tw-inline-block">
                    Rp 123,000,000.05
            </div>
            </div>
            <div className="tw-bg-white tw-rounded-lg tw-mt-10">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Riwayat Transaksi
                </div>
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                        <input
                                className="tw-mr-4"
                                size="sm"
                                placeholder="dd-mm-yy"
                                type="date"
                                // onChange={(e) => setStartDate(dayjs(e.target.value).format('DD-MM-YY'))}
                            >
                                
                            </input>
                            
                            <input
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                type="date"
                                // onChange={(e) => setEndDate(dayjs(e.target.value).format('DD-MM-YY'))}
                            >
                               
                            </input>
                             <form
                                // onSubmit={handleSearch}
                                className="tw-w-full mr-4"
                                id="search"
                            > 
                           <FormControl
                                id="search"
                                className="mr-4"
                                size="sm"
                                placeholder="Cari no.rekening, nama..."
                                // onChange={(e) => setSearch(e.target.value)}
                            /> 
                            </form>
                           
                            <Button
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                                // onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </div>

                    </div>
                </div>

                <TableGrid
                    onRenderColumnField={(_value, field) => {
                        if (field === 'checked') return <FormCheck />;
                    }}
                    columns={[
                        {
                            name: 'Tanggal',
                            field: 'tanggal',
                        },
                        {
                            name: 'Kode',
                            field: 'kode',
                        },
                        {
                            name: 'No. Rekening',
                            field: 'no_rek',
                        },
                        {
                            name: 'Nama',
                            field: 'name',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                        },
                        {
                            name: 'Mutasi',
                            field: 'mutasi',
                        },
                        {
                            name: 'Nominal (Rp)',
                            field: 'nominal',
                        },
                        {
                            name: 'Status',
                            field: 'status',
                        },
                    ]}
                    data={[
                        {
                            tanggal: '05-06-21',
                            kode: '10',
                            no_rek: '432512623',
                            name: 'Petugas 1',
                            keterangan: 'Penyetoran 141616-IBOPR',
                            mutasi: 'Debit',
                            nominal: '25.000.000',
                            status: 'Belum Otorisasi'

                        },
                        {
                            tanggal: '05-06-21',
                            kode: '11',
                            no_rek: '432512623',
                            name: 'Petugas 2',
                            keterangan: 'Penyetoran 141616-IBOPR',
                            mutasi: 'Kredit',
                            nominal: '25.000.000',
                            status: 'Sudah Otorisasi'

                        },
                       
                    ]}
                    onRenderField={(value, field, { className }) => {
                        if (field === 'checked') return <FormCheck checked={value} />;

                        if (field === 'value')
                            return <div className={clsx(className, 'tw-text-right')}>{value}</div>;
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl className="tw-border-0" as="select" size="sm">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                    />
                    <div className="tw-text-12">Menampilkan 1-3 of 30 data</div>
                </div>
            </div>
        </React.Fragment>
    )

}

export default PeragaanBrankasPage;