import React, { memo, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { WalkInCostumer, BasePagePengirimPenerima, BasePageKiriman, Pin } from '../../controls';
import { AppAction, AppInterfaces } from "../../../app.data";

const DetailPenerima_base = memo(({store, ...props}) => {
    return (<>
        <div className="row mb-3">
            <div className="col-6">
                <div className="row g-3 mx-2 mb-2">
                    <label htmlFor="nama" className="form-label">Nama<span className="text-danger"> *</span></label>
                    <input type="text" name={`${props.type}.nama`} ref={props.register({ required: true})} id="nama" className={`form-control`} placeholder="Masukkan Nama" aria-label="Last name"
                        disabled={store.isSukses} onFocus={(e)=>e.target.select()}/>
                </div>
                <div className="row g-3 mx-2 mb-2">
                    <label htmlFor="hubungan" className="form-label">Hubungan<span className="text-danger"> *</span></label>
                    <select name={`${props.type}.hubungan`} ref={props.register({ required: true})} id="hubungan" className={`form-control form-select}`} aria-label="Default select example" disabled={store.isSukses}>
                        <option value="" selected>Pilih Jenis Identitas</option>
                        <option value="1">KTP</option>
                        <option value="2">SIM</option>
                        <option value="3">Passport</option>
                    </select>
                </div>
                <div className="row g-3 mx-2 mb-2">
                    <label htmlFor="jenisID" className="form-label">Jenis Identitas<span className="text-danger"> *</span></label>
                    <select name={`${props.type}.jenisID`} ref={props.register({ required: true})} id="jenisID" className={`form-control form-select}`} aria-label="Default select example" disabled={store.isSukses}>
                        <option value="" selected>Pilih Jenis Identitas</option>
                        <option value="1">KTP</option>
                        <option value="2">SIM</option>
                        <option value="3">Passport</option>
                    </select>
                </div>
                <div className="row g-3 mx-2 mb-2">
                    <label htmlFor="noId" className="form-label">No Identitas<span className="text-danger"> *</span></label>
                    <input type="text" name={`${props.type}.noID`} ref={props.register({ required: true})} id="noId" className={`form-control`} placeholder="No Identitas" aria-label="Last name" 
                        disabled={store.isSukses} onFocus={(e)=>e.target.select()} />
                </div>
            </div>
            <div className="col-6">
                <div className="row g-3 mx-2 mb-2">
                    <label htmlFor="alamat" className="form-label">Alamat</label>
                    <textarea className="form-control" name={`${props.type}.alamat`} ref={props.register} id="alamat" rows="3" 
                        placeholder="Masukan Alamat" disabled={store.isSukses}></textarea>
                </div>
                <div className="row g-3 mx-2 mb-2">
                    <label htmlFor="kota" className="form-label">Kota<span className="text-danger"> *</span></label>
                    <input type="text" name={`${props.type}.kota`} ref={props.register({ required: true})} id="kota" className={`form-control`} placeholder="Masukan kota" aria-label="Last name" 
                        disabled={store.isSukses} onFocus={(e)=>e.target.select()} />
                </div>
                <div className="row g-3 mx-2 mb-2">
                <label htmlFor="kodepos" className="form-label">Kode Pos<span className="text-danger"> *</span></label>
                <input type="text" name={`${props.type}.kodepos`} ref={props.register({ required: true})} id="kodepos" className={`form-control`} placeholder="Masukan kota" aria-label="Last name" 
                    disabled={store.isSukses} onFocus={(e)=>e.target.select()} />
                </div>
                <div className="row g-3 mx-2 mb-2"></div>
            </div> 
        </div>
        <div className={`p-3 text-center rounded ${store.isSukses ? "alert-success" : "alert-warning"}`}>
            {store.isSukses ? "Registrasi WIC Pelanggan baru berhasil" : <><i className="fas fa-exclamation-circle"></i> &nbsp;Pastikan data penerima yang Anda masukan sudah benar</>}
        </div>
    </>)
})

const DetailPenerima = AppInterfaces.appLoad(DetailPenerima_base)


const Pembayaran_base = memo(({store, ...props})=> {
    const appAction = useRef(null);
    const [tab, setTab] = useState("ntp");
    const { register, handleSubmit, setValue } = useForm();
    
    const reset = () => {
        setValue('pengirim', {nama:  "", noHp: "", email: "", alamat: "", negara: "", provinsi: "", kota: "", kodePos: ""},)
        setValue("penerima", {nama:  "", noHp: "", email: "", alamat: "", negara: "", provinsi: "", kota: "", kodePos: "",} )
        
    }

    const prosesPembayaran = (data) =>{ 
        // console.log("data pembayaran", data)
        appAction.current.setShowModal({
            show: true,
            size: "sm",
            backdrop: "static",
            body: <div className="text-center">Apakah data sudah benar?</div>,
            footer: <>
                <button className="btn btn-outline-secondary btn-sm px-3" onClick={()=>appAction.current.setCloseModal()}>TIDAK</button>
                <button className="btn btn-primary btn-sm px-4" onClick={()=>OkProses()}>OK</button>
            </>
        })
    };

    const OkProses = () => appAction.current.setShowModal({
        show: true,
        size: "md",
        backdrop: "static",
        body: <div className="text-center">Demi keamanan saat pencairan memasukkan PIN maksimum 6 digit angka.<br /><Pin value="" onComplete={()=>ulangiPin()} /></div>,
    });

    const ulangiPin = () => appAction.current.setShowModal({
        show: true,
        size: "md",
        backdrop: "static",
        body: <div className="text-center">Ulangi Pin.<br /><Pin onComplete={()=>pencairan()} reset={true} /></div>,
    });

    const pencairan = () => appAction.current.setShowModal({
        show: true,
        size: "sm",
        backdrop: "static",
        body: <div className="text-center">Lakukan Pencairan ?</div>,
        footer: <>
            <button className="btn btn-outline-secondary btn-sm px-3" onClick={()=>appAction.current.setCloseModal()}>TIDAK</button>
            <button className="btn btn-primary btn-sm px-4" onClick={()=>tampilkanData()}>IYA</button>
        </>
    });

    const tampilkanData = () => appAction.current.setShowModal({
        show: true,
        size: "xl",
        backdrop: "static",
        header: <div className="text-center text-bold">Data Penerima</div>,
        body: <DetailPenerima register={register} type="detailPenerima" />,
        footer: <form onSubmit={handleSubmit(OkCairkan)}><button className="btn btn-primary btn-sm px-4" type="submit">PROSES</button></form>
    });

    const cancel = () => {
        setValue('detailPenerima', {nama: "", hubungan: "", jenisID: "", noID: "", alamat: "", kota: "", kodepos: ""})
    };

    const OkCairkan = (data) => {
        // console.log("data Penerima", data)
        appAction.current.setShowModal({
            show: true,
            size: "sm",
            backdrop: "static",
            body: <div className="text-center">Pencairan wesel berhasil dilakukan,<br/>
            resi akan otomatis tercetak</div>,
            footer: <>
                <button className="btn btn-outline-secondary btn-sm px-3" onClick={()=>{cancel(); appAction.current.setCloseModal()}}>TIDAK</button>
                <button className="btn btn-primary btn-sm px-4" onClick={()=>appAction.current.setCloseModal()}>TUTUP</button>
            </>
        })
    };

    return(
        <div className="p-3" >
            <AppAction ref={appAction} />
            <WalkInCostumer />
            <div className="row">
                <div className="col-12 pt-2">
                    <div className="card card-body px-3 py-0">
                        <div className="row">
                            <div className="col-6 px-4 pt-4">
                                <h5>Jenis Inputan</h5>
                                <div className="row mb-2">
                                    <div className={`col-md-2 pointer `} onClick={()=>setTab("ntp")} >
                                        <i className={`far fa-dot-circle ${tab === "ntp" && "text-orange-primary"}`}></i>
                                        &nbsp;&nbsp;&nbsp; NTP
                                    </div>
                                    <div className={`col-md-2 pointer `} onClick={()=>setTab("pin")} >
                                        <i className={`far fa-dot-circle ${tab === "pin" && "text-orange-primary"}`}></i>
                                        &nbsp;&nbsp;&nbsp; PIN
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label for="inputPassword" class="col-sm-3 col-form-label">{tab === "ntp" ? "NTP" : "PIN"}</label>
                                    <div class="col-sm-3 px-1">
                                        <input type="text" class="form-control" id="inputPassword" />
                                    </div>
                                    <div class="col-sm-1 px-1">
                                        <input type="text" class="form-control" id="inputPassword" />
                                    </div>
                                    <div class="col-sm-1 px-1">
                                        <input type="text" class="form-control" id="inputPassword" />
                                    </div>
                                    <div class="col-sm-4 pl-1">
                                        <input type="text" class="form-control" id="inputPassword" />
                                    </div>
                                </div>
                                <BasePageKiriman register={register} id="wesel" options={
                                    [
                                        {
                                            title: <>Data Kiriman</>,
                                            type: "dataKiriman",
                                            forms: [
                                                {type: "text", name:"noref", required: true, label: "No. Referensi", disable: true, style: "text-left", placeholder: "Masukan no. referensi", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"pertanyaan", required: true, label: "Pertanyaan", disable: true, style: "text-left", placeholder: "Pertanyaan untuk penerima", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"jawaban", required: true, label: "Jawaban", disable: true, style: "text-left", placeholder: "Jawaban pertanyaan penerima", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"bsu", required: true, label: "BSU (Rp)", disable: true, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                            ],
                                        },
                                    ]
                                } />
                            </div>
                            <div className="col-6 border-left px-4 pt-4">
                                <BasePagePengirimPenerima register={register} id="wesel" type="pengirim" disable={store.isSukses}/>
                                <BasePagePengirimPenerima register={register} id="wesel" type="penerima" disable={store.isSukses}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card card-body px-3">
                        <span className="clearfix">
                            <button className="btn btn-outline-primary px-5 mx-1 float-right">BAYAR</button>
                            <form onSubmit={handleSubmit(prosesPembayaran)}>
                                <button className="btn btn-primary px-5 mx-1 float-right" type="submit">PROSES</button>
                            </form>
                            <button className="btn  px-5 mx-1 float-right" onClick={()=>reset()}>RESET</button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
})

const Pembayaran = AppInterfaces.appLoad(Pembayaran_base)
export default Pembayaran;