import moment from 'moment';
import React from 'react';
import { addCommas } from '../../../../../../module';
import { TableList } from '../../../../../controls';
import useRekening from '../useRekening';

const HistoryTrx = () => {
    const { rekState, rekAction } = useRekening()
    return (
        <div className="row">
            <div className="col">
                <h3>Data Riwayat Transaksi</h3>
                <div className="mt-3">

                    <TableList
                        columns={[
                            {
                                name: 'Tanggal Transaksi',
                                field: 'tanggal_transaksi',
                                maxWidth: '150px',  
                                onRender: (item) => {
                                    return moment(item.tanggal_transaksi, "YYYY/MM/DD").format("DD-MM-YYYY")
                                }
                            },
                            {
                                name: 'No Referensi',
                                field: 'nomor_referensi',
                                maxWidth: '250px',
                            },
                            {
                                name: 'Jenis Transaksi',
                                field: 'jenis_transaksi',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Nilai Mutasi',
                                field: 'nilai_mutasi',
                                maxWidth: '250px',
                                className: "text-right",
                                onRender: (item) => {
                                    return addCommas( parseFloat( item.nilai_mutasi).toFixed(2))+" "
                                }
                            },
                            {
                                name: 'Debit/Kredit',
                                field: 'jenis_mutasi',
                                maxWidth: '100px',
                            },
                            {
                                name: 'Keterangan',
                                field: 'keterangan',
                                maxWidth: '150px',
                            }

                        ]}
                        data={rekState.dataHist}
                        isLoading={rekState.loadingHist}
                        filter={true}
                        formsFilter={[
                            {
                                type: 'date',
                                placeholder: 'Tangal Awal',
                                disabled: false,
                                readonly: false,
                                onChange: (e) => rekAction.setFilterHist(prevState =>  ({ ...prevState, date_start : e})),
                                value: rekState.filterHist?.date_start
                            },
                            {
                                type: 'date',
                                placeholder: 'Tangal Akhir',
                                disabled: false,
                                readonly: false,
                                onChange: (e) => rekAction.setFilterHist(prevState =>  ({ ...prevState, date_end : e})),
                                value: rekState.filterHist?.date_end
                            },
                        ]}
                        onFilter={() => rekAction.getHistory() }
                        resetFilter={false}
                        submitButton="submit"
                        withActions={false}
                        withCard={false}
                        pagination={true}
                        onChangeShow={rekAction.changeShowHist}
                        onNextPage={rekAction.nextPageHist}
                        onPrevPage={rekAction.prevPageHist}
                        
                    />
                </div>
            </div>
        </div>
    );
}

export default HistoryTrx;