import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import './upload.css';

const UploadFile = ({ btnName = 'Pilih File', onChange = (File) => null, ...props }) => {
    const [fileName, setFileName] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [file, setFile] = useState(null);
    const [renderFile, setRenderFile] = useState(false);

    const change = (e) => {
        const file = e.target.files[0];
        const nameFile = e.target.files[0].name;
        if (props.validasiExt !== undefined) {
            const arrEx = nameFile.split('.');
            if (arrEx.length > 0) {
                if (
                    !props.validasiExt
                        .split(',')
                        .some((val) => val.toLowerCase() === arrEx[arrEx.length - 1].toLowerCase())
                ) {
                    e.target.value = '';
                    setErrMsg(`must be ${props.validasiExt}`);
                    setTimeout(() => setErrMsg(''), 3000);
                    return false;
                }
            } else {
                e.target.value = '';
                setErrMsg(`must be ${props.validasiExt}`);
                setTimeout(() => setErrMsg(''), 3000);
                return false;
            }
        }
        setFileName(nameFile);
        setFile(file);
        setRenderFile(true);
        onChange(file);
    };
    return (
        <>
            <div className="col form-inline">
                <div
                    data-text={fileName === '' ? 'Choose File' : fileName}
                    className="wrapper-upload form-control form-control-sm col"
                >
                    <input
                        accept=".xlsx"
                        onChange={change}
                        type="file"
                        className="upload"
                        id="upload"
                    />
                </div>
                <Button
                    as="label"
                    htmlFor="upload"
                    basic
                    size="mini"
                    color="orange"
                    className="tw-ml-2"
                >
                    {btnName}
                </Button>
            </div>
        </>
    );
};

export default UploadFile;
