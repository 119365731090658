import React, { memo, useRef } from 'react';
import {TableList } from '../../../controls';
import { Link } from 'react-router-dom';
import { Accordion, Table } from 'react-bootstrap';
import "bootstrap/js/src/collapse.js";

class SetoranKliring extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  render() {
      return(
        <>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mt-2 mb-3">
              <li className="breadcrumb-item color-primary">Kliring</li>
              <li className="breadcrumb-item"><a href="#">Pemeliharaan Titipan Kliring</a></li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-white">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="card-title mt-2">DAFTAR TITIPAN KLIRING</h5>
                    </div>
                    <div className="col-6 text-right">
                      <div className="form-row mt-1">
                        <div className="col-2"></div>
                        <div className="form-group col-6">
                          <input type="text" className="form-control form-control-sm" placeholder="Tanggal Input"/>
                        </div>
                        <div className="form-group col-4">
                          <button className="btn btn-sm btn-primary btn-block">Tampilkan Data</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p0">
                  <Table striped bordered hover>
                    <thead className="thead-light">
                      <tr>
                        <th>No. Rekening Kredit</th>
                        <th>Nama  Rekening</th>
                        <th>No. Referensi</th>
                        <th>Tanggal Input</th>
                        <th>Tanggal Efektif</th>
                        <th>Keterangan</th>
                        <th>Valuta</th>
                        <th>Cabang</th>
                        <th>Inputer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        data-toggle="collapse"
                        data-target=".multi-collapse1"
                        aria-controls="multiCollapseExample1"
                      >
                        <td>0010004846</td>
                        <td>MOH ALI</td>
                        <td>RF22340GG</td>
                        <td>07-04-2015</td>
                        <td>08-04-2015</td>
                        <td>TITIPAN KLIRING</td>
                        <td>IDR</td>
                        <td>000</td>
                        <td>BCAOPR</td>
                      </tr>
                      <tr class="collapse multi-collapse1" id="multiCollapseExample1">
                        <td colSpan="9">
                          <p className="text-primary">Detail Warkat</p>
                          <div className="row">
                            <div className="col-4">
                              <p>
                                <span>Kode Bank</span> <span>:</span> <span>4940043</span>
                              </p>
                              <p>
                                <span>Nama Bank</span> <span>:</span> <span>PT. BANK RAKYAT INDONESIA</span>
                              </p>
                            </div>
                            <div className="col-3">
                              <p>
                                <span>No. Warkat</span> <span>:</span> <span>2222</span>
                              </p>
                              <p>
                                <span>Jenis Warkat</span> <span>:</span> <span>Cheque</span>
                              </p>
                            </div>
                            <div className="col-4">
                              <p>
                                <span>No. Rekening Warkat</span> <span>:</span> <span>121100983940</span>
                              </p>
                              <p>
                                <span>Nominal</span> <span>:</span> <span>2.000.000,00</span>
                              </p>
                            </div>
                            <div className="col-1">
                              <button className="btn btn-sm btn-success btn-block">Setor</button>
                              <button className="btn btn-sm btn-danger btn-block">Hapus</button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        data-toggle="collapse"
                        data-target=".multi-collapse2"
                        aria-controls="multiCollapseExample2"
                      >
                        <td>0010004846</td>
                        <td>MOH ALI</td>
                        <td>RF22340GG</td>
                        <td>07-04-2015</td>
                        <td>08-04-2015</td>
                        <td>TITIPAN KLIRING</td>
                        <td>IDR</td>
                        <td>000</td>
                        <td>BCAOPR</td>
                      </tr>
                      <tr class="collapse multi-collapse2" id="multiCollapseExample2">
                        <td colSpan="9">
                          <p className="text-primary">Detail Warkat</p>
                          <div className="row">
                            <div className="col-4">
                              <p>
                                <span>Kode Bank</span> <span>:</span> <span>4940043</span>
                              </p>
                              <p>
                                <span>Nama Bank</span> <span>:</span> <span>PT. BANK RAKYAT INDONESIA</span>
                              </p>
                            </div>
                            <div className="col-3">
                              <p>
                                <span>No. Warkat</span> <span>:</span> <span>2222</span>
                              </p>
                              <p>
                                <span>Jenis Warkat</span> <span>:</span> <span>Cheque</span>
                              </p>
                            </div>
                            <div className="col-4">
                              <p>
                                <span>No. Rekening Warkat</span> <span>:</span> <span>121100983940</span>
                              </p>
                              <p>
                                <span>Nominal</span> <span>:</span> <span>2.000.000,00</span>
                              </p>
                            </div>
                            <div className="col-1">
                              <button className="btn btn-sm btn-success btn-block">Setor</button>
                              <button className="btn btn-sm btn-danger btn-block">Hapus</button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </>
      )
  }
}

export default SetoranKliring;