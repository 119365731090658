import React, { memo, useCallback, useState, forwardRef } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { debounce } from 'lodash-es';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { AppInterfaces } from '../../../app.data';
import { createForm } from '../../../module/createFormList';
import { baseUrl } from '../../../app.config';
import { amountToWord, getErrorEmptyValue } from '../../../utils';
import { numberToCurrency } from '../../../utils/formatNumber';
import LogoAladdin from '../../../img/logo-aladin.png';

const ComponentToPrint = forwardRef(({ form }, ref) => {
    const formatNumber = (number) =>
        new Intl.NumberFormat('id-ID', {
            currency: form?._rekening?.valuta || 'IDR',
            style: 'currency',
        }).format(number);

    return (
        <div ref={ref} style={{ width: '210mm', display: 'block' }} className="tw-p-6 tw-text-12">
            <div className="tw-flex tw-justify-between tw-items-center tw-font-bold">
                <img style={{ width: 120 }} alt="logo aladin" src={LogoAladdin} />
                <span className="tw-text-16">Bilyet Deposito Berjangka</span>
            </div>
            <div className="tw-text-right tw-my-4 tw-font-bold">NO. BD. 0005</div>
            <div className="tw-grid tw-grid-cols-4 tw-gap-x-4 tw-gap-y-6 tw-items-center">
                <div className="tw-col-span-2 tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Nomor</span>
                    <span>{form?.nomorDeposito}</span>
                </div>
                <div className="tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Cabang</span>
                    <span>{form?.cabangRekening}</span>
                </div>
                <div className="tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Tanggal</span>
                    <span>{dayjs().format('DD/MM/YYYY')}</span>
                </div>
                <div className="tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Tanggal Valuta</span>
                    <span>{dayjs().format('DD/MM/YYYY')}</span>
                </div>
                <div className="tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Jatuh Tempo</span>
                    <span>
                        {form?.tanggalJatuhTempo
                            ? dayjs(form?.tanggalJatuhTempo).format('DD/MM/YYYY')
                            : '-'}
                    </span>
                </div>
                <div className="tw-col-span-2 tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Jumlah</span>
                    <span>{formatNumber(form?.totalSaldo || 0)}</span>
                </div>
                <div className="tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Jenis</span>
                    <span>{form?.tipeAkunNasabah}</span>
                </div>
                <div className="tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Nomor Nasabah</span>
                    <span>{form?.nomorCIF}</span>
                </div>
                <div className="tw-col-span-2 tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Bagi Hasil</span>
                    <span>{form?.bagiHasil + ' %'}</span>
                </div>
                <div className="tw-flex tw-items-center">
                    <span className="tw-font-bold tw-mr-3">Alamat</span>
                    <span>{form?.alamat}</span>
                </div>
                <div className="tw-font-bold f-10">
                    KAMI TELAH MENERIMA DEPOSITO DARI ANDA DENGAN PERINCIAN TERTERA DISINI SEBAGAI
                    PENEGASAN. HARAP MENGEMBALIKAN TEMBUSAN NOTA INI SETELAH DITANDA TANGAN
                    SEPERLUNYA.
                </div>
            </div>
            <div className="tw-mt-4 f-10 tw-text-right tw-font-bold">
                PT. BANK NET INDONESIA SYARIAH
            </div>
            <div
                style={{ borderTop: '1px solid black', width: 'max-content' }}
                className="tw-mt-20 f-10 tw-font-bold tw-ml-auto"
            >
                TANDA TANGAN PEJABAT BANK
            </div>
        </div>
    );
});

const CetakBillyet = AppInterfaces.appLoad(
    memo(({ show = false, onHide }) => {
        const [confirm, setConfirm] = useState(false);
        const [form, setForm] = useState({});
        const [error, setError] = useState({});
        const [success, setSuccess] = useState(false);
        const [noRekening, setNoRekening] = useState(null);
        const [componentToPrintRef, setComponentToPrintRef] = useState(null);

        const handlePrint = useReactToPrint({
            content: () => componentToPrintRef,
            pageStyle: () => `
                @media print {
                    @page {
                        size: 210mm 187mm;
                    }
                }
            `,
            documentTitle: 'Billyet Deposito Berjangka',
            onAfterPrint: () => {
                setForm({});
                setNoRekening('');
                setConfirm(false);
                setSuccess(true);
            },
            bodyClass: 'tw-flex tw-mx-auto tw-w-max tw-items-center',
        });

        // eslint-disable-next-line
        const findDeposito = useCallback(
            debounce(async (value, callback) => {
                const res = await fetch(baseUrl + `/deposito/transaksi/rekap/${value}`);
                const data = await res.json();

                const resDetail = await fetch(
                    baseUrl + `/deposito/find/${value}?detailsLevel=FULL&accountHolderType=CLIENT`
                );
                const resDetailGroup = await fetch(
                    baseUrl + `/deposito/find/${value}?detailsLevel=FULL&accountHolderType=GROUP`
                );

                const dataDetail = await resDetail.json();
                const dataDetailGroup = await resDetailGroup.json();

                if (data.message_id !== '00') {
                    setForm({});
                    if (callback) callback('error');
                    return;
                }

                let dataForm;
                let dataCabang;

                if (res.ok) dataForm = data;

                if (dataDetail.message_id === '00') {
                    const resDetailCabang = await fetch(
                        baseUrl +
                            `/rekening/tabungan/kantor/cabang/${dataDetail.list?.kunciCabang}/detail`
                    );
                    const dataDetailCabang = await resDetailCabang.json();
                    if (dataDetailCabang.message_id === '00') dataCabang = dataDetailCabang.list;

                    // dataForm.list.namaRekening = dataDetail.list?._rekening?.namaRekening;
                    dataForm.list.alamat = dataDetail.list?._dataNasabah?.alamat;
                    dataForm.list.namaRekening = dataDetail.list?.name;
                    dataForm.list.nomorBilyet = dataDetail.list?._rekening?.nomorBilyet;
                    dataForm.list.produk = dataDetail.list?._rekening?.produk;
                    dataForm.list.nomorDeposito = value;
                    dataForm.list.tanggalJatuhTempo = dataDetail.list?._rekening?.tanggalJatuhTempo;
                    dataForm.list.tanggalBuka = dataDetail.list?._rekening?.tanggalBuka;
                    dataForm.list.tipeAkunNasabah = dataDetail.list?._rekening?.tipeAkunNasabah;
                    dataForm.list.nomorCIF = dataDetail.list?._dataNasabah?.nomorCIF;
                    dataForm.list.bagiHasil = dataDetail.list?._otherInformation?.nisbahAkhir;
                    dataForm.list.zakat = dataDetail.list?._otherInformation?.nisbahZakat;
                    dataForm.list.pajak = dataDetail.list?._otherInformation?.nisbahPajak;
                    dataForm.list.totalSaldo = dataDetail.list?.balances?.availableBalance;
                    dataForm.list.cabangRekening = dataCabang?.id + ' - ' + dataCabang?.name;
                    dataForm.list.jangkaWaktu = dataDetail.list?._rekening?.tenor;
                }

                if (dataDetailGroup.message_id === '00') {
                    const resDetailCabang = await fetch(
                        baseUrl +
                            `/rekening/tabungan/kantor/cabang/${dataDetailGroup.list?.kunciCabang}/detail`
                    );
                    const dataDetailCabang = await resDetailCabang.json();
                    if (dataDetailCabang.message_id === '00') dataCabang = dataDetailCabang.list;

                    // dataForm.list.namaRekening = dataDetailGroup.list?._rekening?.namaRekening;
                    dataForm.list.alamat = dataDetailGroup.list?._dataNasabah?.alamat;
                    dataForm.list.namaRekening = dataDetailGroup.list?.name;
                    dataForm.list.nomorBilyet = dataDetailGroup.list?._rekening?.nomorBilyet;
                    dataForm.list.produk = dataDetailGroup.list?._rekening?.produk;
                    dataForm.list.nomorDeposito = value;
                    dataForm.list.tanggalJatuhTempo =
                        dataDetailGroup.list?._rekening?.tanggalJatuhTempo;
                    dataForm.list.tanggalBuka = dataDetailGroup.list?._rekening?.tanggalBuka;
                    dataForm.list.tipeAkunNasabah =
                        dataDetailGroup.list?._rekening?.tipeAkunNasabah;
                    dataForm.list.nomorCIF = dataDetailGroup.list?._dataNasabah?.nomorCIF;
                    dataForm.list.bagiHasil = dataDetailGroup.list?._otherInformation?.nisbahAkhir;
                    dataForm.list.zakat = dataDetailGroup.list?._otherInformation?.nisbahZakat;
                    dataForm.list.pajak = dataDetailGroup.list?._otherInformation?.nisbahPajak;
                    dataForm.list.totalSaldo = dataDetailGroup.list?.balances?.availableBalance;
                    dataForm.list.cabangRekening = dataCabang?.id + ' - ' + dataCabang?.name;
                    dataForm.list.jangkaWaktu = dataDetailGroup.list?._rekening?.tenor;
                }

                setForm(dataForm?.list);
                if (callback) callback('success');
                return;
            }, 500),
            []
        );

        const handleSearchDeposito = useCallback(
            async (e) => {
                const { value } = e.target;

                const isNumber = !isNaN(value);

                if (!isNumber) return;

                await findDeposito(value, (status) => {
                    if (status === 'error' && value > 0)
                        setError({ ...error, rekeningDeposito: true, noRekening: false });
                    else setError({ ...error, rekeningDeposito: false, noRekening: false });
                });
                setNoRekening(value);
            },
            [error, findDeposito]
        );

        return (
            <>
                <div hidden>
                    <ComponentToPrint form={form} ref={setComponentToPrintRef} />
                </div>

                <Modal centered show={success}>
                    <Modal.Header>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Proses Cetak Bilyet Deposito berhasil dilakukan
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setSuccess(false)}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Selesai
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={confirm}>
                    <Modal.Header onHide={() => setConfirm(false)} closeButton>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Lakukan proses Cetak Bilyet Deposito?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setConfirm(false)}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Tidak
                        </Button>
                        <Button onClick={handlePrint} className="tw-w-16" size="mini" color="blue">
                            Ya
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="xl" centered show={show}>
                    <Modal.Header closeButton onHide={onHide}>
                        <Modal.Title>
                            <div className="tw-text-18 tw-font-bold">Cetak Bilyet Deposito</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="col-md col-lg col-sm-12">
                                            <Form.Label style={{ fontSize: '12px' }}>
                                                Nomor Rekening Deposito
                                            </Form.Label>
                                            <Form.Control
                                                value={noRekening}
                                                onChange={(e) => handleSearchDeposito(e)}
                                                placeholder="Nomor Rekening Deposito"
                                                size="sm"
                                                isInvalid={
                                                    error.rekeningDeposito || error.noRekening
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {error.noRekening && !noRekening
                                                    ? 'Field wajib diisi'
                                                    : 'Rekening tidak dapat ditemukan'}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {createForm({
                                            type: 'text',
                                            label: 'Nomor Bilyet',
                                            placeholder: 'Nomor Bilyet',
                                            disabled: true,
                                            value: form?.nomorBilyet || '',
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {createForm({
                                            type: 'date',
                                            label: 'Tanggal Jatuh Tempo',
                                            placeholder: 'Tanggal Jatuh Tempo',
                                            disabled: true,
                                            value: form?.tanggalJatuhTempo
                                                ? dayjs(form?.tanggalJatuhTempo).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : '',
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {createForm({
                                            type: 'text',
                                            label: 'Informasi Produk',
                                            placeholder: 'Informasi Produk',
                                            disabled: true,
                                            value: form?.produk || '',
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {createForm({
                                            type: 'text',
                                            label: 'Alamat',
                                            placeholder: 'Alamat',
                                            disabled: true,
                                            value: form?.alamat || '',
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {createForm({
                                            type: 'date',
                                            label: 'Tanggal Buka Deposito',
                                            placeholder: 'Tanggal Buka Deposito',
                                            disabled: true,
                                            value:
                                                dayjs(form?.tanggalBuka).format('YYYY-MM-DD') || '',
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {createForm({
                                            type: 'text',
                                            label: 'Amount In Word',
                                            placeholder: 'Amount In Word',
                                            disabled: true,
                                            value: amountToWord(form?.totalSaldo) || '-',
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div class="col-md-12">
                                        {createForm({
                                            type: 'text',
                                            label: 'Nama Rekening Deposito',
                                            placeholder: 'Nama Rekening Deposito',
                                            disabled: true,
                                            value: form?.namaRekening || '',
                                        })}
                                        {/* {createForm({
                                            type: 'text',
                                            label: 'Nominal Deposito',
                                            placeholder: 'Rp 1.0000.0000,00',
                                            disabled: true,
                                            value: numberToCurrency(form?.totalSaldo) || '',
                                        })} */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-12">
                                        {createForm({
                                            type: 'date',
                                            label: 'Tanggal Cetak Bilyet',
                                            placeholder: 'Tanggal Cetak Bilyet',
                                            disabled: true,
                                            value: dayjs().format('YYYY-MM-DD'),
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-12">
                                        {createForm({
                                            type: 'text',
                                            label: 'Informasi Cabang',
                                            placeholder: 'Informasi Cabang',
                                            disabled: true,
                                            value: form?.cabangRekening || '',
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {createForm({
                                            type: 'text',
                                            label: 'Jangka Waktu',
                                            placeholder: 'Jangka Waktu',
                                            disabled: true,
                                            value: form?.jangkaWaktu || '',
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-12">
                                        {createForm({
                                            type: 'text',
                                            label: 'Nominal Deposito',
                                            placeholder: 'Nominal Deposito',
                                            disabled: true,
                                            value: numberToCurrency(form?.totalSaldo || 0),
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        {createForm({
                                            type: 'text',
                                            label: 'Nisbah',
                                            placeholder: 'Nisbah',
                                            disabled: true,
                                            value: form?.bagiHasil || 0 + ' %' || '',
                                        })}
                                    </div>
                                    <div className="col-md-6">
                                        {createForm({
                                            type: 'text',
                                            label: 'Zakat Bagi Hasil',
                                            placeholder: 'Zakat Bagi Hasil',
                                            disabled: true,
                                            value: form?.zakat || 0 + ' %' || '',
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        {createForm({
                                            type: 'text',
                                            label: 'Pajak Bagi Hasil',
                                            placeholder: 'Pajak Bagi Hasil',
                                            disabled: true,
                                            value: form?.pajak || 0 + ' %' || '',
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-12">
                                        {createForm({
                                            type: 'select',
                                            label: 'Jenis Printer',
                                            placeholder: 'Pilih Jenis Printer',
                                            options: [],
                                            disabled: true,
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide} className="tw-font-normal tw-py-2 tw-mr-2">
                            Batal
                        </Button>
                        <Button
                            onClick={() => {
                                const { isAnyEmptyError, err } = getErrorEmptyValue({ noRekening });

                                if (isAnyEmptyError || error.rekeningDeposito) {
                                    setError({ ...error, ...err });
                                    return;
                                }

                                setConfirm(true);
                                if (onHide) onHide();
                            }}
                            className="tw-font-normal tw-py-2"
                            color="blue"
                        >
                            Cetak
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);

export default CetakBillyet;
