import moment from 'moment';
import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { addCommas } from '../../../../../module';
import { NewLoading, PrintModal, TableList } from '../../../../controls';
import { usePrintModal } from '../../../../controls/modalPrint';
import useModalDetilTrx, { ModalDetilTrxProv } from '../component/modalDetilTrx';
import useTrxToday, { TrxTodayProvider } from './useTrxToday';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../../../../../hooks/keycloak.module';
import PrintArea from './printArea'
import { useReactToPrint } from 'react-to-print';

function KasTeller() {
    const { todayState, todayAction } = useTrxToday()
    const { mtAction, mtState } = useModalDetilTrx();
    const { mpAction, mpState } = usePrintModal()
    const { keycloak } = useKeycloak();
    const refCetak = useRef(null)

    // const cetakWithoutNexus = useReactToPrint({
    //     content: () => refCetak?.current,
    //     bodyClass: "p-0 m-0"
    // });

    // // // console.log(todayState.dataValidasi)
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Informasi Transaksi</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Transaksi Hari Ini
                    </li>
                </ol>
            </nav>


            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                INFORMASI TRANSAKSI HARI INI 
                            </div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                minHeight="450px"
                                maxHeight="500px"
                                onChangeShow={todayAction.changeShow}
                                onNextPage={todayAction.nextPage}
                                onPrevPage={todayAction.prevPage}
                                withActions={true}
                                actionItems={[
                                    {
                                        name: 'Lihat Detail',
                                        onRender: (item) =>
                                            item.id_transaksi_core !== null  || item.kode_transaksi === "FTRF"
                                        ,
                                        onClick: (item) => {
                                            mtAction.openModal(parseInt(item.id_transaksi_core))
                                        },
                                    },
                                    {
                                        name: 'Cetak Validasi',
                                        onRender: (item) =>
                                            ( /*item.is_boleh_cetak === "T"*/ true || keycloak.hasRealmRole("spv teller")) &&
                                            (item.id_transaksi_core !== null || item.kode_transaksi === "FTRF") && 
                                            (
                                                [
                                                    "ST", "STG", "TTR", "TT", "TTG",
                                                    "RTGS", "SKN", "AKT", "AKK", "SKK", "FIP",
                                                    //tambahan baru rabu
                                                    "RV", "ATM01", "ATM02", "FTRF"
                                                ].some( k => k === item.kode_transaksi) 
                                            ) && (item.kode_transaksi === "FTRF" ? (item.sts_webhook_bifast === "S") : true)
                                        ,
                                        onClick: (item) => {
                                            todayAction.cetakValidasi(item)
                                        },
                                    },
                                    {
                                        name: "Cek Validasi Webhook BIFAST",
                                        onRender: (item) => item.kode_transaksi === "FTRF" &&( item.sts_webhook_bifast === "I" || item.sts_webhook_bifast === null),
                                        onClick: (item)=> todayAction.cekWebhookBifast(item)

                                    },
                                    {
                                        name: 'Selesaikan Transaksi BDS',
                                        onRender: (item) => item.sts_validasi_trx !== `True`,
                                        onClick: (item) => {
                                            todayAction.responseSelesai(parseInt(item.id_transaksi))
                                        },
                                    },
                                ]
                                }
                                columns={[
                                    {
                                        name: 'Jam Input',
                                        field: 'jam_input',
                                        onRender: ({ jam_input }) => moment(jam_input).format("HH:mm:ss")
                                    },
                                    {
                                        name: 'Kode Transaksi',
                                        field: 'kode_transaksi',
                                    },
                                    {
                                        name: 'Jenis Transaksi',
                                        field: 'jenis_transaksi',
                                    },
                                    {
                                        name: 'Nilai Mutasi',
                                        field: 'nilai_mutasi',
                                        className: "text-right",
                                        onRender: (item) => addCommas(parseFloat(item.nilai_mutasi).toFixed(2))
                                    },
                                    {
                                        name: 'Referensi',
                                        field: 'nomor_referensi',
                                        width: '40px',
                                    },
                                    {
                                        name: 'Status Proses Transaksi BDS',
                                        field: 'sts_validasi_trx',  
                                        // className:"text-right",
                                    },
                                    {
                                        name: 'Status Webhook Bifast',
                                        field: 'sts_webhook_bifast_desc', 
                                    },
                                    {
                                        name: 'Status Otorisasi',
                                        field: 'status_otorisasi',
                                    },
                                    {
                                        name: 'Status Reversal',
                                        field: 'is_reversal',
                                        onRender: ({ is_reversal = "" }) => (is_reversal === "T").toString().toUpperCase()
                                    },
                                    {
                                        name: 'User',
                                        field: 'user_input',
                                    },
                                    {
                                        name: 'User Otor',
                                        field: 'user_otor',
                                    },
                                    {
                                        name: 'Keterangan',
                                        field: 'keterangan',
                                    },
                                ]}
                                data={todayState.dataList}
                                isLoading={todayState.isLoading}
                                filter={false}
                                resetFilter={false}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* <PrintArea ref={refCetak} /> */}


            <PrintModal
                title="Cetak Validasi"
                modalSize="md"
                id="modalTrx"
                footer={false}
                data={todayState.dataValidasi?.data}
                dataLabel={todayState.dataValidasi?.field}
                footerComponent={
                    <Modal.Footer>
                        <div className="row">
                            <div className="col">
                                <button
                                    onClick={() => //keycloak.hasRealmRole("spv teller") ?
                                        todayAction.cetakFunction() //: (
                                        // todayState.withNexus ?
                                        // todayAction.cetakFunction() :
                                        // cetakWithoutNexus()
                                        // )
                                    }
                                    className="btn btn-sm btn-primary ml-3 float-right">

                                    {
                                        keycloak.hasRealmRole("spv teller") ?
                                            <>
                                                Izinkan Cetak
                                            </>
                                            : <> <i className="fa fa-print"></i> Cetak </>
                                    }
                                </button>
                                {<button onClick={() => {
                                    mpAction.closeModal("modalTrx")
                                    mpState.modalDetil?.callBack?.()
                                    // callBackClose()
                                }} className="btn btn-sm btn-outline-secondary float-right ml-3">
                                    Tutup
                                </button>}
                            </div>
                        </div>
                    </Modal.Footer>
                }
            >
            </PrintModal>
        </>
    )
}
export default () => <ModalDetilTrxProv> <TrxTodayProvider><KasTeller /></TrxTodayProvider> </ModalDetilTrxProv>;