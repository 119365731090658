import React from 'react';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistration } from '../../../../../controls';
import useDeposito from '../useDeposito';
import Select from 'react-select';
import { addCommas } from '../../../../../../module';


const BukaBlokirDeposito = () => {
    const { rekState, rekAction } = useDeposito();
    const { rekSelected = {}, dataBlokir = false } = rekState;

    return ( 
        <>
        <div className="pb-4">
                <div className="box">
                    <div className="row">
                        <div className="col-6 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nomor Rekening Deposito</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="Otomatis terisi dari sistem"
                                    value={rekSelected?.nomor_rekening}
                                />
                            </div>
                        </div>
                        <div className="col-6 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nilai Deposito</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="Otomatis terisi dari sistem"
                                    value={ addCommas ( parseFloat(rekSelected?.saldo_deposito || 0).toFixed(2)) }
                                    disabled={!rekState.formEditable || rekState.paramUrl?.mode === "edit"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 align-self-center bns-form">
                        </div>
                        <div className="col-8 text-right align-self-end">
                            <div>
                                <span className="ml-5 text-grey">Kode Cabang</span>
                                <span className="text-grey2 ml-4">: </span>
                                <span className="text-grey2">{rekSelected?.nama_cabang}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FormRegistration
                itemsForm={[
                    // Data deposito
                    {
                        rows: [
                            {
                                rows: 1,
                                label: 'Keterangan Blokir',
                                type: 'text',
                                placeholder: 'Masukan Keterangan Blokir',
                                required: true,
                                readOnly:true,
                                value: rekSelected?.keterangan_blokir,
                                // onChange: (value) =>{
                                //     rekAction.changeFormBukaBlokir(value, "alasan_blokir")
                                //     rekAction.changeFormBukaBlokir(rekSelected?.nomor_rekening, "nomor_rekening")
                                //     rekAction.changeFormBukaBlokir(rekSelected?.nama_rekening, "nama_rekening")
                                //     rekAction.changeFormBukaBlokir(rekSelected?.nama_produk, "produk_rekening")
                                //     rekAction.changeFormBukaBlokir(rekSelected?.valuta, "valuta_rekening")
                                //     rekAction.changeFormBukaBlokir(rekSelected?.nama_cabang, "cabang_rekening")
                                //     rekAction.changeFormBukaBlokir(rekSelected?.saldo_deposito, "saldo_rekening")
                                //     rekAction.changeFormBukaBlokir(rekSelected?.tanggal_jatuh_tempo_terakhir, "tanggal_kadaluarsa")

                                // }, 
                                disabled: true
                            },
                            {
                                rows: 2,
                                label: 'Jenis Blokir',
                                type: 'select',
                                placeholder: 'Pilih Jenis Blokir',
                                required: true,
                                readOnly:true,
                                value: rekSelected?.jenis_blokir || '',
                                onChange: (value) => rekAction.changeFormBlokir(value, "jenis_blokir"),
                                disabled: true,
                                options: [
                                            {
                                                key: "blokir_debet",
                                                display: "Blokir Debet"
                                            },{
                                                key: "blokir_credit",
                                                display: "Blokir Credit"
                                            },{
                                                key: "blokir_debet_credit",
                                                display: "Blokir Debet Credit"
                                            },
                                        ]
                            },
                    ]},
                ]}
            />
        </>
     );
}
 
export default BukaBlokirDeposito;