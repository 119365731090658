import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "react-bootstrap";

export default function App(props) {
  const initScale = { unit: "%" };
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState(initScale);
  const [completedCrop, setCompletedCrop] = useState(null);

  const canvasToURL = (canvas, mode) => {
    // console.log(mode)
    if (mode === "nocrop") {
      onTrigger(canvas, mode);
    } else {
      const dataURL = canvas.toDataURL("image/jpeg", 1.0);
      onTrigger(urltoFile(dataURL, "svs_" + new Date().getTime() + ".jpeg"),'crop');
    }
    // // console.log(dataURL);

    // return
  };
  const urltoFile = (url, filename) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: "image/jpeg" });
      });
  };

  const onTrigger = (data, mode) => {
    if (mode === "nocrop") {
      props.callBack({ data: data, mode: "nocrop" });
    }else{
      props.callBack({ data: data, mode: "crop" });
    }
    // event.preventDefault();
  };

  const onSelectFile = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    onSelectFile(props.data);
  }, [props.show]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <Modal size="md" show={props.show} onHide={props.hide}>
      <Modal.Header closeButton>
        <Modal.Title>Crop Gambar</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "2rem" }}>
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
        <div style={{ display: `none` }}>
          <hr />
          <h2 className="mb-3">Preview</h2>
          <div className="d-flex w-100 justify-content-center">
            <canvas
              className="border"
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </div>
        </div>
        <button
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          className="btn btn-success btn-block mt-3"
          // disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() => {
            if (!completedCrop?.width || !completedCrop?.height) {
              canvasToURL(props.data, "nocrop");
            } else {
              canvasToURL(previewCanvasRef.current, "crop");
            }
            setUpImg();
            props.hide();
          }}>
          Simpan
        </button>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
    // <div
    //   className={`modal fade bd-example-modal-lg ${props.id}`}
    //   tabIndex="-1"
    //   role="dialog"
    //   aria-labelledby="myLargeModalLabel"
    //   aria-hidden="true">
    //   <div className="modal-dialog">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title" id="exampleModalLongTitle">
    //           Crop Image
    //         </h5>
    //         <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
    //           <span aria-hidden="true">&times;</span>
    //         </button>
    //       </div>
    //       <div className="modal-body">
    //         <div className="App">
    //           <div>
    //             <input type="file" accept="image/*" className="form-control mb-3" onChange={onSelectFile} />
    //           </div>
    //           <ReactCrop
    //             src={upImg}
    //             onImageLoaded={onLoad}
    //             crop={crop}
    //             onChange={(c) => setCrop(c)}
    //             onComplete={(c) => setCompletedCrop(c)}
    //           />
    //           <div style={{ display: `none` }}>
    //             <hr />
    //             <h2 className="mb-3">Preview</h2>
    //             <div className="d-flex w-100 justify-content-center">
    //               <canvas
    //                 className="border"
    //                 ref={previewCanvasRef}
    //                 // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
    //                 style={{
    //                   width: Math.round(completedCrop?.width ?? 0),
    //                   height: Math.round(completedCrop?.height ?? 0),
    //                 }}
    //               />
    //             </div>
    //           </div>
    //           <button
    //             type="button"
    //             data-bs-dismiss="modal"
    //             aria-label="Close"
    //             className="btn btn-success btn-block mt-3"
    //             disabled={!completedCrop?.width || !completedCrop?.height}
    //             onClick={() => {
    //               // canvasToURL(previewCanvasRef.current)
    //               canvasToURL(previewCanvasRef.current);
    //               setUpImg();
    //               // // console.log(completedCrop);
    //               // // console.log(previewCanvasRef.current);
    //             }}>
    //             Save
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
