import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { FormRegistration } from "../../../../controls";
import useRegisNotif, { RegNotifProvider } from "./useRegisNotif";
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';

function RegisNotif() {

    const { rgAction, rgState } = useRegisNotif();
    const { formData = {}, formEditable = false } = rgState;
    const paramUrl = useParams();
    let history = useHistory();
    useEffect(function () {
        rgAction.auditTrail()
    }, [])
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Tabungan & Giro</a>
                    </li>
                    {
                        paramUrl.mode === "otorisasi" && (
                            <li className="breadcrumb-item active" aria-current="page">
                                Form Otorisasi Notifikasi
                            </li>
                        )
                    }
                    {
                        paramUrl.mode === "registration" && (
                            <li className="breadcrumb-item active" aria-current="page">
                                Form Registrasi Notifikasi
                            </li>
                        )
                    }
                    {
                        paramUrl.mode === "edit" && (
                            <li className="breadcrumb-item active" aria-current="page">
                                Form Edit Notifikasi
                            </li>
                        )
                    }
                </ol>
            </nav>

            <div className="row" >

                <div className="col" >
                    <div className="card" >
                        <div className="card-header" >
                            {
                                paramUrl.mode === "otorisasi" && (
                                    <div className="card-title" >
                                        Form Otorisasi Notifikasi
                                    </div>
                                )
                            }
                            {
                                paramUrl.mode === "registration" && (
                                    <div className="card-title" >
                                        Form Registrasi Notifikasi
                                    </div>
                                )
                            }
                            {
                                paramUrl.mode === "edit" && (
                                    <div className="card-title" >
                                        Form Edit Notifikasi
                                    </div>
                                )
                            }
                        </div>
                        <div className="card-body" >
                            <FormRegistration
                                totalRows={2}
                                itemsForm={[
                                    {
                                        name: "Regitrasi SMS Notifikasi",
                                        rows: [
                                            {
                                                rows: 1,
                                                label: 'No Rekening',
                                                type: 'number',
                                                required: true,
                                                value: rgState.formData?.no_rek,
                                                onChange: (value) => rgAction.changeForm(value, 'no_rek'),
                                                onEnter: (e) => rgAction.cekNasabah(e),
                                                onFocusOut: (e) => rgAction.cekNasabah(e),
                                                disabled: !rgState.formEditable
                                            },
                                            {
                                                rows: 1,
                                                label: 'Nomor Nasabah',
                                                type: 'text',
                                                // required: true,
                                                value: rgState.formData?.cif,
                                                onChange: (value) => rgAction.changeForm(value, 'cif'),
                                                disabled: !rgState.formEditable,
                                                readOnly: true
                                            },
                                            {
                                                rows: 1,
                                                label: 'Nama Nasabah',
                                                type: 'text',
                                                // required: true,
                                                value: rgState.formData?.nama_sub,
                                                onChange: (value) => rgAction.changeForm(value, 'nama_sub'),
                                                disabled: !rgState.formEditable,
                                                readOnly: true
                                            },
                                            {
                                                rows: 1,
                                                label: 'No HandPhone',
                                                maxLength: 16,
                                                type: 'number',
                                                // required: true,
                                                value: rgState.formData?.no_hp,
                                                onChange: (value) => rgAction.changeForm(value, 'no_hp'),
                                                disabled: !rgState.formEditable,
                                                readOnly: true
                                            },
                                            {
                                                rows: 2,
                                                label: 'Filter Transaksi',
                                                placeholder: " Pilih Transaksi ",
                                                type: 'select',
                                                required: true,
                                                value: rgState.formData?.flag,
                                                onChange: (value) => {
                                                    rgAction.changeForm(value, 'flag')

                                                    if (value == "DB-Hanya Transaksi Debit") {
                                                        rgAction.resetFormDBCR()
                                                        rgAction.CekDb()
                                                    } else if (value == "CR-Hanya Transaksi Kredit") {
                                                        rgAction.resetFormDBCR()
                                                        rgAction.CekCr()
                                                    } else if (value == "BOTH-Hanya Transaksi Debit dan Kredit") {
                                                        rgAction.resetFormDBCR()
                                                        rgAction.CekBOTH()
                                                    } else if (value == "") {
                                                        rgAction.resetFormDBCR()
                                                        rgAction.resetCEK()
                                                    }
                                                },
                                                options: [
                                                    {
                                                        key: "DB-Hanya Transaksi Debit",
                                                        display: "Hanya Transaksi Debit"
                                                    }, {
                                                        key: "CR-Hanya Transaksi Kredit",
                                                        display: "Hanya Transaksi Kredit"
                                                    }, {
                                                        key: "BOTH-Hanya Transaksi Debit dan Kredit",
                                                        display: "Hanya Transaksi Debit dan Kredit"
                                                    },
                                                ],
                                                disabled: !rgState.formEditable
                                            },
                                            {
                                                rows: 2,
                                                label: 'Min Transaksi Db.',
                                                type: 'currency',
                                                required: rgState.filterTRDB,
                                                value: rgState.formData?.min_tran_db,
                                                onChange: (value) => rgAction.changeForm(parseFloat(value), 'min_tran_db'),
                                                disabled: !rgState.filterTRDB
                                            },
                                            {
                                                rows: 2,
                                                label: 'Min Transaksi Cr.',
                                                type: 'currency',
                                                required: rgState.filterTRCR,
                                                value: rgState.formData?.min_tran_cr,
                                                onChange: (value) => rgAction.changeForm(parseFloat(value), 'min_tran_cr'),
                                                disabled: !rgState.filterTRCR
                                            },
                                            {
                                                rows: 2,
                                                label: 'Active',
                                                type: 'checkbox',
                                                className: "pt-2",
                                                checked: rgState.formData?.active === "T",
                                                //no field belum keluar otor
                                                onChange: (value) => {
                                                    rgAction.changeForm(value ? "T" : "F", "active")
                                                },
                                                disabled: !rgState.formEditable
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                        <div className="card-footer" >
                            <div className="row">
                                <div className="col">
                                    <button onClick={() => history.goBack()} className="btn btn-sm float-right btn-outline-secondary ml-3">
                                        close
                                    </button>
                                    {
                                        paramUrl.mode === "otorisasi" && (
                                            <>
                                                <button
                                                    onClick={() => rgAction.responseOtor("APPROVED")}
                                                    className="btn btn-success btn-sm float-right ml-3">
                                                    Approve
                                                </button>
                                                <button
                                                    onClick={() => rgAction.responseOtor("REJECTED")}
                                                    className="btn btn-danger btn-sm float-right ml-3">
                                                    Reject
                                                </button>
                                            </>
                                        )
                                    }
                                    {
                                        paramUrl.mode === "registration" && (
                                            <button
                                                onClick={rgAction.confirmRegis}
                                                className="btn btn-success btn-sm float-right ml-3">
                                                Simpan
                                            </button>
                                        )
                                    }
                                    {
                                        paramUrl.mode === "edit" && (
                                            <button
                                                onClick={rgAction.confirmUbah}
                                                className="btn btn-success btn-sm float-right ml-3">
                                                Simpan Edit
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => rgAction.setOtorDesc(e.target.value)}
                    value={rgState.otorDesc}
                />
            </ModalConfirmCustom>
        </>
    )

}
export default () => <RegNotifProvider><RegisNotif /></RegNotifProvider>