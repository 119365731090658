import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
import useModalNasabah from './useModalNasabah';
// import { useKeycloak } from '@react-keycloak/web';
import { usePrintModal } from '../../../../controls/modalPrint';
import useNexusPrint from '../../../../../hooks/useNexusPrint';
import moment from 'moment';
import { addCommas } from '../../../../../module';
import useKeycloak from '../../../../../hooks/keycloak.module';
const FormTranfersContext = createContext({
    ftState: {},
    ftAction: {}
})

export const FTProvider = ({ children }) => {

    const { doGet, doPost, wait } = useFetch();
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ keyword: '' });
    const [dataListRekening, setListDataRekening] = useState([]);
    const [produk, setProduk] = useState("")
    // const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    // const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false });
    const [isLoading, setIsLoading] = useState(true)
    const [dataCetak, setDataCetak] = useState({});
    const [dataBank, setDataBank] = useState([]);
    const [formData, setFormData] = useState({
        "nomor_rekening": "",
        "nominal": 0,
        "nomor_referensi": "",
        "keterangan": "",
        "kode_valuta": "",
    });
    const [otorDesc, setOtorDesc] = useState("")
    const [infoTrx, setInfoTrx] = useState({
        "kode_bank_kliring": "",
        "nama_bank_kliring": "",
        "nama_rekening_penerima": "",
        "ats_permintaan": "",
        "jenis_penduduk": "",
        "jenis_nomor_referensi": "",
        "sumber_biaya": "T",
        "keterangan": "",
        "nominal_ekuivalen": 0,
        "biaya": 0,
        "biaya_ekuivalen": 0,
        "norek_biaya": "",
        "kurs_jual": 1,
        "kurs_beli": 1,
        "kurs_buku": 1,
    });
    // const [infoRek, setInfoRek] = useState({
    //     "kode_bank_kliring": "",
    //     "nama_bank_kliring": "",
    //     "nama_rekening_penerima": "",
    //     "ats_permintaan": "",
    //     "jenis_penduduk": "",
    //     "jenis_nomor_referensi": "",
    //     "sumber_biaya": "",
    //     "keterangan": "",
    //     "nominal_ekuivalen": 0,
    //     "biaya": 0,
    //     "biaya_ekuivalen": 0,
    //     "norek_biaya": "",
    //     "kurs_jual": 0,
    //     "kurs_beli": 0,
    //     "kurs_buku": 0,
    // });
    const [dataPengirim, setDataPengirim] = useState({
        "jenis_nasabah": "",
        "nomor_nasabah": "",
        "nama": "",
        "alamat": "",
        "jenis_identitas": "",
        "nomor_identitas": "",
        "nomor_tlpn": "",
        "nasabah_wic": ""
    });
    // const [loadingDetil, setLoadingDetil] = useState(true);
    // const [rekSelected, setRekSelected] = useState({});

    const modalNasabah = useModalNasabah()
    const { pdrState, pdrAction } = modalNasabah;
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const [isWic, setIsWic] = useState(true);

    const [dataPrint, setDataPrint] = useState({
        "transaksi": {
            "id_transaksi": "",
            "id_transaksi_core": "",
            "nomor_rekening_debet": "",
            "nomor_rekening_credit": "",
            "nominal": "",
            "nomor_referensi": "",
            "keterangan": "",
            "keterangan_otor": null,
            "kode_valuta": "",
            "kode_transaksi": "",
            "status_otorisasi": "",
            "user_input": "",
            "user_otorisasi": "",
            "tanggal_input": "",
            "tanggal_otor": "",
            "status_reversal": null
        },
        "info_transaksi": {
            "id_info": null,
            "id_transaksi": "",
            "kode_bank_kliring": null,
            "nama_bank_kliring": null,
            "nama_rekening_penerima": null,
            "ats_permintaan": null,
            "keterangan": null,
            "norek_biaya": null,
            "saldo_teller_before": "",
            "saldo_teller_after": null,
            "nominal_ekuivalen": "",
            "biaya": "",
            "biaya_ekuivalen": "",
            "sumber_biaya": "",
            "kurs_jual": "",
            "kurs_beli": "",
            "kurs_buku": "",
            "pengirim_nomor_nasabah": null,
            "pengirim_nama": null,
            "pengirim_alamat": null,
            "pengirim_jenis_identitas": null,
            "pengirim_nomor_identitas": null,
            "pengirim_nomor_tlpn": null,
            "pengirim_nasabah_wic": null,
            "jenis_ttr": null,
            "tutup_kartu_atm": null,
            "norek_pindah_buku": null,
            "nama_rekening": "",
            "kode_valuta": "",
            "keterangan_valuta": "",
            "produk": "",
            "kode_cabang": "",
            "nama_cabang": "",
            "jenis_penduduk": null,
            "jenis_nomor_referensi": null,
            "jenis_nasabah": "",
            "total_denom": null,
            "selisih_saldo": null,
            "jenis_transaksi": null,
            "kode_valuta_teller": null,
            "saldo_rekening": null,
            "nama_rekening_pinbuk": null,
            "cabang_rekening_pinbuk": null
        }
    })
    const { mpAction, mpState } = usePrintModal();

    const { keycloak } = useKeycloak()
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams();
    const location = useLocation();


    useEffect(function () {
        getBiaya()
    }, [paramUrl.type])

    async function getBiaya() {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                param: {
                    kode_grup_biaya: (paramUrl.type === "skn") ? "SKN_NASABAH" : (paramUrl.type === "rtgs") ? "RTGS_NASABAH" : ""
                },
                url: `/parameterglobal/by-code`,
                service: "trx"
            });
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {

                if (status === "00") {
                    const { nama_rekening = "", nama_cabang = "", valuta = "", nama_produk = "" } = data;
                    // // // console.log(data)
                    setInfoTrx(s => ({
                        ...s, biaya: (data?.data?.nominal_biaya || 0)
                    }))
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }


    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            getDataDetail(location.state?.dbId);
        }
        // } 
        setFormEditable(paramUrl.mode !== "otorisasi");
    }, [paramUrl.mode])
    // useEffect GetData
    useEffect(function () {
        // getDataList()
    }, [pagination])

    function changeForm(value, key, subkey = null, grandSubKey = null) {
        value = value.toUpperCase()
        if (grandSubKey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [grandSubKey]: { ...prevState[subkey][grandSubKey], [key]: value } } }))
        else if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    async function getListBank() {
        try {
            const resp = await doGet({
                url: `/bank-transfer/${paramUrl.type}`,
                service: "trx"
            });
            const { statusCode, status, statusText, data, remark } = resp;
            if (statusCode === 200) {

                if (status === "00") {
                    setDataBank(data.listbank)
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
    }

    const { print } = useNexusPrint()

    const [formCetak, setFormCetak] = useState({
        command: "PRINT",
        medianame: "TRANSFER",
        formname: "BJBS TRANSFER KLIRING",
        print: 1,
        history: 0,
        id: null,
        nomorTransaksi: "",
        kodeCabang: "",
        UserTeller: "",
        userOtorisasi: "",
        kodeCabangDebit: "",
        norekDebit: "",
        kodeTarikan: "",
        jenisMataUang: "",
        nominalDebit: "",
        kodeDebit: "",
        kodeCabangKredit: "",
        norekKreditKasTeller: "",
        norekKredit: "",
        nominalKredit: "",
        kodeKredit: "",
        berita: "",
        tglTransaksi: "",
        jamTransaksi: "",
        namaRek: "",
        keteranganTransaksi: "",
        ketTRF: "",
        rekPerantaraKredit: "",
        kodeSetoranKredit: "",
        biayaJasaKirim: "",
        rekKasTeller: "",
        biayaDebit: "",
        bia: "",
        kodeTRF: "",
        UserTellerNoUrutTransaksi: "",
        kodeSetoranTransfer: "",
        kredit: "",
        otomatisNomorCN: ""


    });


    async function cetakFunction() {

        openLoading()
        try {
            const res = await print(formCetak);
            if (res.statusCode === 500) {
                setDataAlert({ show: true, body: res.remark, title: `${res.statusText}`, header: "Error", callBack: () => null });
            } else {
                setDataAlert({ show: true, body: res.remark, title: `${res.statusText}`, header: "Berhasil", callBack: () => null });
            }
        } catch (err) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });

        }
        closeLoading()
    }

    async function cekNasabah(nomor_rekening) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/tabungan-giro/rek-for-trx/${nomor_rekening}`,
                service: "acc"
            });
            const { statusCode, status, statusText, data, remark } = resp;

            if (statusCode === 200) {

                if (status === "00") {
                    const { nama_rekening = "", nama_cabang = "", valuta = "", nama_produk = "" } = data;
                    setInfoTrx(s => ({
                        ...s, nama_rekening_penerima: nama_rekening
                    }))
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }

    async function getPengirim(nomor_nasabah) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account ...")
        if (dataPengirim.jenis_nasabah === "" || dataPengirim.jenis_nasabah === null || dataPengirim.jenis_nasabah === undefined) {
            setDataAlert({ show: true, body: "Jenis nasabah belum terpilih", title: ``, header: "INFO", callBack: () => null });
        } else {

            let url = `/nasabah/${nomor_nasabah}`;
            if (dataPengirim.jenis_nasabah === "BADAN USAHA") {
                url = `/nasabah-badan-usaha/${nomor_nasabah}`
            }
            try {
                const resp = await doGet({
                    // data: bodyData,
                    url,
                    service: "cif"
                });
                const { statusCode, status, statusText, data, remark } = resp;

                if (statusCode === 200) {

                    if (status === "00") {

                        if (dataPengirim.jenis_nasabah === "BADAN USAHA") {
                            const {
                                nasabahNamaInduk,
                                nasabahNomorInduk,
                                nasabahProvinsi,
                                nasabahRw,
                                nasabahRt,
                                nasabahKecamatan,
                                nasabahAlamat,
                                nasabahKelurahan,
                                nasabahKotaKabupaten,
                                nasabahKodePos,
                                nasabahTeleponKantorKodeArea1,
                                nasabahTeleponKantorNomor1,
                                groupName,
                                nasabahNomorAktePendirian

                            } = data.nasabahBadanUsaha

                            setDataPengirim(p => ({
                                ...p,
                                nama: groupName,
                                alamat: `${nasabahAlamat || ""} RT${nasabahRt || ""}/RW${nasabahRw || ""}
                                            ${nasabahKelurahan || ""}, 
                                            ${nasabahKecamatan || ""}, 
                                            ${nasabahKotaKabupaten || ""}, 
                                            ${nasabahProvinsi || ""}
                                            ${nasabahKodePos || ""}`,
                                jenis_identitas: "AKTA",
                                nomor_identitas: nasabahNomorAktePendirian,
                                nomor_tlpn: `${nasabahTeleponKantorKodeArea1} ${nasabahTeleponKantorNomor1}`,
                            }));
                        }
                        else {
                            const {
                                firstName = "",
                                mailingAddressPostalCode,
                                mailingAddress,
                                mailingAddressCity,
                                mailingAddressVillage,
                                mailingAddressProvince,
                                mailingAddressSubDistrict,
                                personalIdNumber,
                                mobilePhone,
                                personalIdHolderName
                            } = data.nasabahBadanUsaha;

                            setDataPengirim(p => ({
                                ...p,
                                nama: personalIdHolderName,
                                alamat: `${mailingAddress || ""} ${mailingAddressVillage || ""}, ${mailingAddressSubDistrict || ""}, ${mailingAddressCity || ""}, ${mailingAddressPostalCode || ""} ${mailingAddressProvince || ""} `,
                                jenis_identitas: "KTP",
                                nomor_identitas: personalIdNumber,
                                nomor_tlpn: mobilePhone,
                            }));

                        }

                    }
                    else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        }
        closeLoading();
    }


    function resetForm() {
        setFormData({
            "nomor_rekening": "",
            "nominal": 0,
            "nomor_referensi": "",
            "keterangan": "",
            "kode_valuta": "IDR",

        });
        setInfoTrx({
            "kode_bank_kliring": "",
            "nama_bank_kliring": "",
            "nama_rekening_penerima": "",
            "ats_permintaan": "",
            "jenis_penduduk": "",
            "jenis_nomor_referensi": "",
            "sumber_biaya": "T",
            "keterangan": "",
            "nominal_ekuivalen": 0,
            "biaya": 0,
            "biaya_ekuivalen": 0,
            "norek_biaya": "",
            "kurs_jual": 1,
            "kurs_beli": 1,
            "kurs_buku": 1,
        });
        setDataPengirim({
            "jenis_nasabah": "",
            "nomor_nasabah": "",
            "nama": "",
            "alamat": "",
            "jenis_identitas": "",
            "nomor_identitas": "",
            "nomor_tlpn": "",
            "nasabah_wic": ""
        });
        getBiaya()
    }

    // function getData Perorangan
    async function getDataDetail(dbId) {

        let url = "/rekening/local";
        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01", data = null, status = "", statusText = "" } = await doGet({ url: `/trf${paramUrl.type}${paramUrl.jenistf}/${dbId}`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    setFormData({
                        nominal: parseFloat(data.nominal).toFixed(2),
                        nomor_rekening: data.info_transaksi?.nomor_rekening_penerima,
                        kode_valuta: data.kode_valuta,
                        nomor_referensi: data.nomor_referensi,
                        keterangan: data.keterangan
                    });
                    const { info_transaksi = {} } = data;
                    setInfoTrx({
                        "kode_bank_kliring": info_transaksi.kode_bank_kliring,
                        "nama_bank_kliring": info_transaksi.nama_bank_kliring,
                        "nama_rekening_penerima": info_transaksi.nama_rekening_penerima,
                        "ats_permintaan": info_transaksi.ats_permintaan,
                        "jenis_penduduk": info_transaksi.jenis_penduduk,
                        "jenis_nomor_referensi": info_transaksi.jenis_nomor_referensi,
                        "sumber_biaya": info_transaksi.sumber_biaya,
                        "keterangan": info_transaksi.keterangan,
                        "nominal_ekuivalen": parseFloat(info_transaksi.nominal_ekuivalen).toFixed(2),
                        "biaya": parseFloat(info_transaksi.biaya).toFixed(2),
                        "biaya_ekuivalen": parseFloat(info_transaksi.biaya).toFixed(2),
                        "norek_biaya": parseFloat(info_transaksi.norek_biaya).toFixed(2),
                        "kurs_jual": parseFloat(info_transaksi.kurs_jual).toFixed(2),
                        "kurs_beli": parseFloat(info_transaksi.kurs_beli).toFixed(2),
                        "kurs_buku": parseFloat(info_transaksi.kurs_buku).toFixed(2),
                    });
                    if (info_transaksi.pengirim_nomor_nasabah === "" ||
                        info_transaksi.pengirim_nomor_nasabah === null ||
                        info_transaksi.pengirim_nomor_nasabah === undefined) {
                        setIsWic(false)
                    }
                    setDataPengirim(prev => ({
                        ...prev,
                        "nomor_nasabah": info_transaksi.pengirim_nomor_nasabah,
                        "nama": info_transaksi.pengirim_nama,
                        "alamat": info_transaksi.pengirim_alamat,
                        "jenis_identitas": info_transaksi.pengirim_jenis_identitas,
                        "nomor_identitas": info_transaksi.pengirim_nomor_identitas,
                        "nomor_tlpn": info_transaksi.pengirim_nomor_tlpn,
                        "nasabah_wic": info_transaksi.pengirim_nasabah_wic
                    }));
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
        // // // // console.log(data)
    }


    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }


    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Info", callBack: () => null });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    useEffect(function (params) {
        if (paramUrl.mode !== "otorisasi") {
            resetForm();
            getBiaya()
        } else {

        }
        getListBank();
    }, [paramUrl.mode])

    useEffect(function () {
        if (paramUrl.mode !== "otorisasi") {
            setDataPengirim({
                "jenis_nasabah": "",
                "nomor_nasabah": "",
                "nama": "",
                "alamat": "",
                "jenis_identitas": "",
                "nomor_identitas": "",
                "nomor_tlpn": "",
                "nasabah_wic": ""
            });
        }
    }, [isWic])


    async function getValidasiTF(kode_pg) {
        let resp = await doGet({
            url: "/parameterglobal/pg-by-code",
            service: "trx",
            param: {
                kode_pg
            }
        })
        // // // console.log(resp)
        if (resp.statusCode === 200) {
            if (resp.message_id === "00") {
                return resp.data?.nilai_parameter_number || 0
            }
        }
        return 0;
    }

    async function getTimeRTGS() {
        let resp = await doGet({
            url: "/parameterglobal/pg-by-code",
            service: "trx",
            param: {
                kode_pg: "RTGS_JAM_TRF"
            }
        })
        // // // console.log(resp)
        if (resp.statusCode === 200) {
            if (resp.message_id === "00") {
                return resp.data?.nilai_parameter_string || 0
            }
        }
        return false;
    }

    useEffect(function(){
        if(paramUrl. mode !== "otorisasi")
        {

            resetForm()
        }
    },[paramUrl.type])
    // fungsi post nasabah
    async function doTrx() {

        closeConfirm();
        // // // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }

        if (paramUrl.type === "skn") {
            const n_max = await getValidasiTF("MAX_SKN")
            const n_min = await getValidasiTF("MIN_SKN")
            if (parseFloat(formData.nominal) > parseFloat(n_max) || parseFloat(formData.nominal) < parseFloat(n_min)) // n = 100000
                return setDataAlert({
                    show: true,
                    body: `Nominal transaksi SKN maksimal Rp. ${addCommas(parseFloat(n_max).toFixed(2))} dan minimal  ${addCommas(parseFloat(n_min).toFixed(2))}`,
                    title: `Limit Transaski`,
                    header: "Info",
                    callBack: () => null
                });
        } else if (paramUrl.type === "rtgs") {

            const n_min = await getValidasiTF("MIN_RTGS");
            const n_max = await getValidasiTF("MAX_RTGS");
            const time = await getTimeRTGS();
            if (time) {
                let dateNow = moment(new Date);
                let timeRTGS = moment(`${time}`, 'HH:mm')
                if (dateNow >= timeRTGS) {
                    return setDataAlert({
                        show: true,
                        body: `Transaksi RTGS Hanya maksimal jam ${time}`,
                        title: `Limit Transaksi`,
                        header: "Info",
                        callBack: () => null
                    });
                }
                // console.log(dateNow);
                // console.log(moment(`${time}`, 'HH:mm'));
            }
            if (parseFloat(formData.nominal) < parseFloat(n_min) || parseFloat(formData.nominal) > parseFloat(n_max))
                return setDataAlert({
                    show: true,
                    body: `Nominal transaksi RTGS minimal Rp. ${addCommas(parseFloat(n_min).toFixed(2))} dan maximal RP. ${addCommas(parseFloat(n_max).toFixed(2))}`,
                    title: `Limit Transaksi`,
                    header: "Info",
                    callBack: () => null
                });
        }
        let url = `/trf${paramUrl.type}${paramUrl.jenistf}`;
        // // console.log(dataPengirim);
        let bodyData = {
            ...formData,
            uid: keycloak.uid,
            nominal: parseFloat(formData.nominal),
            info_transaksi: {
                ...infoTrx,
                data_pengirim: dataPengirim
            }
        }
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },

                data: bodyData,
                url, // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    setDataAlert({

                        show: true, body: remark, title: ``, header: "Success", callBack: () => {
                            closeConfirm();
                            resetForm();

                            keycloak.updateUID()
                            setFormCetak(c => ({
                                ...c,
                                id: data?.info_transaksi?.id_transaksi,
                                nomorTransaksi: data?.transaksi?.nomor_referensi,
                                kodeCabang: data?.transaksi?.kode_cabang_transaksi + "-" + data?.info_transaksi?.nama_cabang,
                                userTeller: data?.transaksi?.user_input,
                                tgl: moment((data?.transaksi?.tanggal_input)).format("DD-MM-YYYY"),
                                userOtorisasi: "",
                                kodeCabangDebit: data?.transaksi?.kode_cabang_transaksi + "-" + data?.info_transaksi?.nama_cabang,
                                norekDebit: data?.transaksi?.nomor_rekening_debet,
                                kodeTarikan: "",
                                jenisMataUang: data?.info_transaksi?.kode_valuta + " - " + data?.info_transaksi?.keterangan_valuta,
                                nominalDebit: addCommas(parseFloat(data?.transaksi?.nominal || "0").toFixed(2)),
                                kodeDebit: "",
                                kodeCabangKredit: data?.transaksi?.kode_cabang_transaksi + "-" + data?.info_transaksi?.nama_cabang,
                                norekKreditKasTeller: "",
                                nominalKredit: addCommas(parseFloat(data?.transaksi?.nominal || "0").toFixed(2)),
                                kodeKredit: "",
                                berita: data?.transaksi?.keterangan,
                                tglTransaksi: moment((data?.transaksi?.tanggal_input)).format("DD-MM-YYYY"),
                                jamTransaksi: moment((data?.transaksi?.tanggal_input)).format("HH:mm:ss"),
                                namaRek: ""
                            }))

                            setDataPrint(data)
                            // mpAction.openModal("modalTrx", function () {
                            //     closeConfirm();
                            //     resetForm()
                            // })
                        }
                    });
                }
                else if (status === "05") {
                    closeConfirm();
                    resetForm()
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            // fieldRequired = ["nomorCIF", "nama_produk", "nama_rekening"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    // pilih nasabah
    async function pilihNasabah(dataNasabah, tipe) {
        setLoading(prevState => ({ ...prevState, loading: true }));
        // // // // console.log((parseInt(filter) === 1))
        let url = `/nasabah/${dataNasabah.nomor_cif}`;
        if (parseInt(tipe) === 1) url = `/nasabah-badan-usaha/${dataNasabah.nomor_cif}`;

        try {
            const resp = await doGet({
                url,
                service: "cif"
            });
            const { status, statusText, data, statusCode, remark } = resp;
            // // console.log(pdrState?.filter.filterType)
            if (statusCode === 200) {
                if (status === "00") {
                    if (pdrState?.filter?.filterType === "1") {
                        const {
                            nasabahNamaInduk,
                            nasabahNomorInduk,
                            nasabahProvinsi,
                            nasabahRw,
                            nasabahRt,
                            nasabahKecamatan,
                            nasabahAlamat,
                            nasabahKelurahan,
                            nasabahKotaKabupaten,
                            nasabahKodePos,
                            nasabahTeleponKantorKodeArea1,
                            nasabahTeleponKantorNomor1,
                            groupName,
                            nasabahNomorAktePendirian,
                            personalIdType

                        } = data.nasabahBadanUsaha
                        // // console.log(groupName)
                        setDataPengirim(p => ({
                            ...p,
                            jenis_nasabah: "BADAN USAHA",
                            nama: groupName,
                            alamat: `${nasabahAlamat || ""}`,
                            // alamat: `${nasabahAlamat || ""} RT${nasabahRt || ""}/RW${nasabahRw || ""}
                            //             ${nasabahKelurahan || ""}, 
                            //             ${nasabahKecamatan || ""}, 
                            //             ${nasabahKotaKabupaten || ""}, 
                            //             ${nasabahProvinsi || ""}
                            //             ${nasabahKodePos || ""}`,
                            jenis_identitas: "AKTA",
                            nomor_identitas: nasabahNomorAktePendirian,
                            nomor_tlpn: `${nasabahTeleponKantorKodeArea1} ${nasabahTeleponKantorNomor1}`,
                        }));
                    }
                    else {
                        const {
                            firstName = "",
                            mailingAddressPostalCode,
                            mailingAddress,
                            mailingAddressCity,
                            mailingAddressVillage,
                            mailingAddressProvince,
                            mailingAddressSubDistrict,
                            personalIdNumber,
                            mobilePhone,
                            personalIdHolderName,
                            personalIdType,
                            ktpAddress,
                            homePhone,
                            id
                        } = data.nasabahBadanUsaha;

                        setDataPengirim(p => ({
                            ...p,
                            jenis_nasabah: "PERORANGAN",
                            nomor_nasabah: id,
                            nama: personalIdHolderName,
                            alamat: `${ktpAddress || ""}`,
                            //alamat: `${mailingAddress || ""} ${mailingAddressVillage || ""}, ${mailingAddressSubDistrict || ""}, ${mailingAddressCity || ""}, ${mailingAddressPostalCode || ""} ${mailingAddressProvince || ""} `,
                            jenis_identitas: personalIdType,
                            nomor_identitas: personalIdNumber,
                            nomor_tlpn: homePhone,
                        }));

                    }

                    // // Sesuaikan fieldnya disini juga
                    // const { nasabahBadanUsaha } = data
                    // let homePhone = nasabahBadanUsaha?.homePhone;
                    // let mobilePhone = nasabahBadanUsaha?.mobilePhone;
                    // let alamat = dataNasabah.alamat.toUpperCase();
                    // let nomor_identitas = nasabahBadanUsaha.personalIdNumber;
                    // let nomor_nasabah = dataNasabah.nomor_cif
                    // let jenis_identitas = "KTP";


                    // if (parseInt(tipe) === 1) {
                    //     homePhone = nasabahBadanUsaha?.nasabahTeleponKantorKodeArea1 + nasabahBadanUsaha?.nasabahTeleponKantorNomor1;
                    //     mobilePhone = "";
                    //     nomor_identitas = nasabahBadanUsaha?.nasabahNomorAktePendirian;
                    //     jenis_identitas = "AKTA"
                    //     // // // console.log("badan usaha")
                    // }

                    // setFormData(prevState => ({
                    //     ...prevState,
                    //     info_transaksi: {
                    //         ...prevState.info_transaksi,
                    //         data_pengirim: {
                    //             ...prevState.info_transaksi.data_pengirim,
                    //             nomor_nasabah,
                    //             nama: dataNasabah.nama,
                    //             alamat,
                    //             jenis_identitas,
                    //             nomor_identitas,
                    //             nomor_tlpn: homePhone,
                    //         }
                    //     }

                    // }));
                    return true;
                } else {
                    setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
                    setLoading(prevState => ({ ...prevState, loading: false }))
                    return false;
                }
            } else {
                setDataAlert({ show: true, body: remark, title: `${JSON.ify(statusText)}`, header: "Error", callBack: () => null });
                setLoading(prevState => ({ ...prevState, loading: false }))
                return false;
            }
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
            setLoading(prevState => ({ ...prevState, loading: false }))
            return false;
        }

    }
    function confirmTrx() {

        if ((isWic) && (dataPengirim?.nomor_nasabah === "" || dataPengirim?.nomor_nasabah === null || dataPengirim?.nomor_nasabah === undefined)) {
            setDataAlert({ show: true, body: "Pilih Nasabah terlebih dahulu", title: `Nasabah`, header: "Info", callBack: () => null });
        } else {

            let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
            let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
            setDataConfirm({
                show: true,
                body,
                witTextArea: false,
                header: "Konfirmasi",
                title: "",
                closeButton: false,
                footerButton: [
                    { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                    { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
                ]
            })
        }
    }

    async function cekIdentitas(noId) {
        // setLoading(prevState => ({ msg: "Checking Account No ...", show: true }));
        openLoading("Checking Account No ...")
        try {
            const resp = await doGet({
                // data: bodyData,
                url: `/nasabah/cek_cif_by_no_identitas/${noId}`,
                service: "cif"
            });
            const { statusCode, status, statusText, data = [], remark } = resp;
            // // // console.log(resp)

            if (statusCode === 200) {

                if (status === "00") {

                }
                else if (status === "01") {
                    let s = `Nomor Identitas sudah terdaftar sebagai nasabah / wic.`;
                    let r = ` Nama : ${data.length > 0 ? data[0]?.nama_nasabah : ""}. Nomor Nasabah : `;

                    data.forEach((v, i) => r = `${r} ${v.nomor_nasabah} ${i + 1 >= data.length ? "." : " ,"}`)
                    let f = <>
                        {s} <br />
                        {r}
                    </>
                    setDataAlert({ show: true, body: f, title: `${statusText}`, header: "Error", callBack: () => null });
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading();
    }
    function checkHP(value, callback = () => null) {
        if (value.length < 10) {
            setDataAlert({ show: true, body: `No Handphone minimal 10 digit`, title: `Cek Kembali No HandPhone`, header: "Warning", callBack: () => callback() });

        } else {
            setDataPengirim(p => ({ ...p, nomor_tlpn: value }))
        }
    }
    return <FormTranfersContext.Provider
        value={{
            ftState: {
                isLoading,
                filter,
                formData,
                formEditable,
                produk,
                infoTrx,
                dataPengirim,
                dataBank,
                otorDesc,
                dataPrint,
                isWic
            },
            ftAction: {
                closeAlert,
                closeConfirm,
                changeForm,
                pilihNasabah,
                confirmTrx,
                setProduk,
                resetForm,
                cekNasabah,
                setInfoTrx,
                setDataPengirim,
                getPengirim,
                responseOtor,
                setOtorDesc,
                cetakFunction,
                setIsWic,
                cekIdentitas,
                checkHP
            },
            ...modalNasabah
        }}
    >
        {children}
    </FormTranfersContext.Provider>;
}


export default function useFormTransfer() { return useContext(FormTranfersContext) };