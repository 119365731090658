import React, { useState } from 'react';
import useDropdowns from '../../../../../hooks/useDropdowns';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormPerorangan from './useFormPerorangan';

const PekerjaanAlamat = () => {
    const { fpState, fpAction } = useFormPerorangan()
    const { R_KETERKAITAN_PEP, R_JABATAN_PEP } = useDropdowns(["R_KETERKAITAN_PEP","R_JABATAN_PEP"]);
    
    return (
        <>
            <FormRegistration
                itemsForm={[
                    // Data Pekerjaan
                    {
                        name: "Data Pekerjaan",
                        rows: [
                            // rows 1
                            {
                                rows: 1,
                                label: 'Pekerjaan',
                                type: 'select',
                                placeholder: 'Pilih Pekerjaan',
                                options: fpState.dataDropDown?.personalOccupation?.map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._occupationInfo?.personalOccupation,
                                onChange: (value) => fpAction.changeForm(value, "personalOccupation", "_occupationInfo"),
                                disabled: !fpState.formEditable,
                                required: true
                            },
                            {
                                rows: 1,
                                label: "Jabatan Pekerjaan",
                                type: "text",
                                placeholder: "Masukkan Jabatan",
                                value: fpState.formData?._occupationInfo?.personalJobPosition,
                                onChange: (value) => fpAction.changeForm(value, "personalJobPosition", "_occupationInfo"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Bidang Usaha',
                                type: 'select',
                                required: true,
                                placeholder: 'Pilih Bidang Usaha',
                                options: (fpState.dataDropDown?.R_SEKTOR_EKONOMI || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                // dicarikan fildnyaa
                                value: fpState.formData?._occupationInfo?.kode_sektor_ekonomi,
                                onChange: (value) => fpAction.changeForm(value, "kode_sektor_ekonomi", "_occupationInfo"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        {/* <div className="col-3">
                                            {
                                                createForm({
                                                    rows: 1,
                                                    label: "Telepon 1",
                                                    type: "number",
                                                    placeholder: "000",
                                                    // value: fpState.formData?._companyAddress?.companyAddressPhoneNumber,
                                                    // onChange: (value) => fpAction.changeForm(value, "companyAddressPhoneNumber", "_companyAddress"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div> */}
                                        <div className="col-12">
                                            {
                                                createForm({
                                                    rows: 1,
                                                    label: "Telepon",
                                                    type: "number",
                                                    placeholder: "000",
                                                    value: fpState.formData?._companyAddress?.companyAddressPhoneNumber,
                                                    onChange: (value) => fpAction.changeForm(value, "companyAddressPhoneNumber", "_companyAddress"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },

                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        label: 'Status PEP',
                                                        type: 'checkbox',
                                                        className: "pt-2",
                                                        checked:fpState?.formData?._occupationInfo?.statusPep === "T",
                                                        value: fpState?.formData?._occupationInfo?.statusPep === "T",
                                                        onChange: (value) => {
                                                            fpAction.changeForm(value ? "T" : "F", "statusPep", "_occupationInfo")
                                                        },
                                                        disabled: !fpState.formEditable,
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="row" hidden={fpState?.formData?._occupationInfo?.statusPep !== "T"}>
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        type: 'select',
                                                        placeholder: 'Pilih PEP',
                                                        options: R_JABATAN_PEP,
                                                        key: "reference_code",
                                                        display: "reference_desc",
                                                        value: fpState.formData?._occupationInfo?.detailPep,
                                                        onChange: (value) => fpAction.changeForm(value, "detailPep", "_occupationInfo"),
                                                        required: fpState?.statusPEP?.statusPep,
                                                        disabled: !fpState.formEditable
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },

                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        label: 'Status Keterkaitan PEP',
                                                        type: 'checkbox',
                                                        className: "pt-2",
                                                        checked: fpState?.formData?._occupationInfo?.statusKeterkaitanPep ==="T",
                                                        value: fpState?.formData?._occupationInfo?.statusKeterkaitanPep ==="T",
                                                        onChange: (value) => {
                                                            fpAction.changeForm(value ? "T" : "F", "statusKeterkaitanPep", "_occupationInfo")
                                                        },
                                                        disabled: !fpState.formEditable,
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="row" hidden={fpState?.formData?._occupationInfo?.statusKeterkaitanPep !== "T"}>
                                            <div className="col-12">
                                                {
                                                    createForm({
                                                        type: 'select',
                                                        placeholder: 'Pilih Keterkaitan PEP',
                                                        options: R_KETERKAITAN_PEP,
                                                        key: "reference_code",
                                                        display: "reference_desc",
                                                        value: fpState.formData?._occupationInfo?.detailKeterkaitanPep,
                                                        onChange: (value) => fpAction.changeForm(value, "detailKeterkaitanPep", "_occupationInfo"),
                                                        required: fpState?.keterkaitanPEP?.statusKeterkaitanPep,
                                                        disabled: !fpState.formEditable
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                    
                            // {
                            //     rows: 1,
                            //     label: 'Status Ketertaitan PEP',
                            //     type: 'checkbox',
                            //     disabled: !fpState.formEditable
                            //     // className: "pt-4"
                            //     //no field
                            // },
                            // {
                            //     rows: 1,
                            //     label: 'Detail Ketertaitan PEP',
                            //     type: 'select',
                            //     placeholder: 'Pilih Ketertaitan PEP',
                            //     disabled: !fpState.formEditable,
                            //     required:true
                            //     //no field
                            // },
                            // ends rows 1
                            // rows 2

                            {
                                rows: 2,
                                label: 'Nama Perusahaan',
                                type: 'text',
                                required: true,
                                // maxLength:32,
                                placeholder: 'Masukan nama perusahaan',
                                value: fpState.formData?._occupationInfo?.companyName,
                                onChange: (value) => fpAction.changeForm(value, "companyName", "_occupationInfo"),
                                onEnter:(value)=> fpAction.checkNamaPerusahaan(value,fpAction.changeForm("", "companyName", "_occupationInfo")),
                                onFocusOut:(value)=> fpAction.checkNamaPerusahaan(value,fpAction.changeForm("", "companyName", "_occupationInfo")),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Badan Hukum',
                                type: 'select',
                                placeholder: 'Pilih Badan hukum',
                                options: (fpState.dataDropDown?.eBadanHukum || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpState.formData?._occupationInfo?.legalEntity,
                                onChange: (value) => fpAction.changeForm(value, "legalEntity", "_occupationInfo"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Alamat',
                                type: 'textarea',
                                rowsText: 4,
                                placeholder: 'Masukkan Alamat',
                                required: true,
                                value: fpState.formData?._companyAddress?.companyAddress,
                                onChange: (value) => fpAction.changeForm(value, "companyAddress", "_companyAddress"),
                                disabled: !fpState.formEditable
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Provinsi",
                                                    type: "select",
                                                    required: true,
                                                    placeholder: "Masukkan Provinsi",
                                                    // options: fpState.provinces,
                                                    options: (fpState.provinces || []).map(({province_code, province_name}) => ({
                                                        province_code,
                                                        province_name : `${province_code} - ${province_name}`
                                                    })),
                                                    key: "province_code",
                                                    display: "province_name",
                                                    value: fpState.formData?._companyAddress?.companyAddressProvince,
                                                    onChange: (value) => {
                                                        const data = fpState.provinces?.find(v => v.province_name === value);
                                                        fpAction.getingCities(value, fpAction.changeForm("", "companyAddressCity", "_companyAddress"))
                                                        fpAction.changeForm(value, "companyAddressProvince", "_companyAddress")
                                                    },
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kota/Kab",
                                                    type: "select",
                                                    // options: fpState.cities,
                                                    options: (fpState.cities || []).map(({city_code, city_name}) => ({
                                                        city_code,
                                                        city_name : `${city_code} - ${city_name}`
                                                    })),
                                                    required: true,
                                                    key: "city_code",
                                                    display: "city_name",
                                                    placeholder: "Masukkan Kab/Kota",
                                                    value: fpState.formData?._companyAddress?.companyAddressCity,
                                                    onChange: (value) => {
                                                        // fpAction.getingDistrict(value)
                                                        fpAction.changeForm(value, "companyAddressCity", "_companyAddress")
                                                    },
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kecamatan",
                                                    type: "text",
                                                    required: true,
                                                    placeholder: "Masukkan Kecamatan",
                                                    //options: fpState.districts,
                                                    value: fpState.formData?._companyAddress?.companyAddressSubDistrict,
                                                    //onChange: (value) => {
                                                    //    fpAction.getingVillages(
                                                    //        fpState.formData?._companyAddress?.companyAddressCity, // ambil dari data Kota/Kab sebelumnya
                                                    //        value 
                                                    //    )
                                                    //    fpAction.changeForm(value, "companyAddressSubDistrict", "_companyAddress")
                                                    //},
                                                    onChange: (value) => fpAction.changeForm(value, "companyAddressSubDistrict", "_companyAddress"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kelurahan",
                                                    type: "text",
                                                    //options: fpState.villages,
                                                    required: true,
                                                    placeholder: "Masukkan Kelurahan",
                                                    value: fpState.formData?._companyAddress?.companyAddressVillage,
                                                    //onChange: (value) => {
                                                    //    fpAction.getingPostalCodes(
                                                    //        fpState.formData?._companyAddress?.companyAddressCity, // ambil dari data Kota/Kab sebelumnya
                                                    //        fpState.formData?._companyAddress?.companyAddressSubDistrict, // ambil dari data Kota/Kab sebelumnya.
                                                    //        value
                                                    //    )
                                                    //    fpAction.changeForm(value, "companyAddressVillage", "_companyAddress")
                                                    //},
                                                    onChange: (value) => fpAction.changeForm(value, "companyAddressVillage", "_companyAddress"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RT",
                                                    maxLength: 3,
                                                    type: "text",
                                                    required: true,
                                                    placeholder: "RT",
                                                    value: fpState.formData?._companyAddress?.companyAddressRt,
                                                    onChange: (value) => fpAction.changeForm(value, "companyAddressRt", "_companyAddress"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RW",
                                                    maxLength: 3,
                                                    type: "text",
                                                    required: true,
                                                    placeholder: "RW",
                                                    value: fpState.formData?._companyAddress?.companyAddressRw,
                                                    onChange: (value) => fpAction.changeForm(value, "companyAddressRw", "_companyAddress"),
                                                    disabled: !fpState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Kode Pos',
                                type: 'number',
                                required: true,
                                placeholder: 'Pilih Kode Pos',
                                maxLength:5,
                                //options: fpState.postalCodes,
                                value: fpState.formData?._companyAddress?.companyAddressPostalCode,
                                onChange: (value) => fpAction.changeForm(value.toString().replace(" ",""), "companyAddressPostalCode", "_companyAddress"),
                                onEnter:(value)=> fpAction.kodePos1(value,fpAction.changeForm("", "companyAddressPostalCode", "_companyAddress")),
                                onFocusOut:(value)=> fpAction.kodePos1(value,fpAction.changeForm("", "companyAddressPostalCode", "_companyAddress")),
                                disabled: !fpState.formEditable
                            },

                        ]
                    },
                    // End of Data Pekerjaan
                    // Kegiatan Usaha

                    {
                        name: "Kegiatan Usaha",
                        rows: [
                            // rows 1
                            // {
                            //     rows: 1,
                            //     type: "select",
                            //     label: "Bidang Usaha",
                            //     placeholder: "Pilih Bidang Usaha",
                            //     options: fpState.dataDropDown?.R_BIDANG_USAHA,
                            //     key: "reference_code",
                            //     display: "reference_desc",
                            //     //no field
                            //     value: fpState.formData?._occupationInfo?.personalTypeOfWork,
                            //     onChange: (value) => fpAction.changeForm(value, "personalTypeOfWork", "_occupationInfo"),
                            //     disabled: !fpState.formEditable
                            // },
                            // end rows 1
                            // rows 2
                            {
                                rows: 1,
                                type: "select",
                                label: "Lokasi Usaha",
                                placeholder: "Pilih Lokasi Usaha",
                                options: fpState.dataDropDown?.R_LOKASI_USAHA.map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                // lagi di cari fieldnyaa
                                value: fpState.formData?._occupationInfo?.lokasi_usaha,
                                onChange: (value) => fpAction.changeForm(value, "lokasi_usaha", "_occupationInfo"),
                                disabled: !fpState.formEditable
                            }
                        ]
                    }
                ]}
            />
            <div className="row">
                <div className="col-md-6 mt-0">
                    <FormRegistration
                        totalRows={1}
                        itemsForm={[
                            {
                                name: "Alamat Identitas",
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Alamat',
                                        type: 'textarea',
                                        rowsText: 4,
                                        required: true,
                                        placeholder: 'Masukkan Alamat',
                                        value: fpState.formData?._ktpAddress?.ktpAddress,
                                        onChange: (value) => fpAction.changeForm(value, "ktpAddress", "_ktpAddress"),
                                        disabled: !fpState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Provinsi",
                                                            type: "select",
                                                            required: true,
                                                            placeholder: "Masukkan Provinsi ",
                                                            // options: fpState.provinces,
                                                            options: (fpState.provinces || []).map(({province_code, province_name}) => ({
                                                                province_code,
                                                                province_name : `${province_code} - ${province_name}`
                                                            })),
                                                            key: "province_code",
                                                            display: "province_name",
                                                            value: fpState.formData?._ktpAddress?.ktpProvince,
                                                            onChange: (value) => {
                                                                const data = fpState.provinces?.find(v => v.province_name === value);
                                                                fpAction.getingCitiesKtp(value,  fpAction.changeForm("", "ktpCity", "_ktpAddress"))
                                                                fpAction.changeForm(value, "ktpProvince", "_ktpAddress")
                                                            },
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kota/Kab",
                                                            type: "select",
                                                            required: true,
                                                            key: "city_code",
                                                            display: "city_name",
                                                            placeholder: "Masukkan Kab/Kota",
                                                            // options: fpState.citiesKtp,
                                                            options: (fpState.citiesKtp || []).map(({city_code, city_name}) => ({
                                                                city_code,
                                                                city_name : `${city_code} - ${city_name}`
                                                            })),
                                                            value: fpState.formData?._ktpAddress?.ktpCity,
                                                            onChange: (value) => {
                                                                // fpAction.getingDistrictKtp(value)
                                                                fpAction.changeForm(value, "ktpCity", "_ktpAddress")
                                                            },
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kecamatan",
                                                            type: "text",
                                                            required: true,
                                                            placeholder: "Masukkan Kecamatan",
                                                            //options: fpState.districtsKtp,
                                                            value: fpState.formData?._ktpAddress?.ktpSubDistrict,
                                                            //onChange: (value) => {
                                                            //    fpAction.getingVillagesKtp(
                                                            //        fpState.formData?._ktpAddress?.ktpCity, // ambil dari data Kota/Kab sebelumnya
                                                            //        value 
                                                            //    )
                                                            //    fpAction.changeForm(value, "ktpSubDistrict", "_ktpAddress")
                                                            //},
                                                            onChange: (value) => fpAction.changeForm(value, "ktpSubDistrict", "_ktpAddress"),
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kelurahan",
                                                            type: "text",
                                                            required: true,
                                                            placeholder: "Masukkan Kelurahan",
                                                            //options: fpState.villagesKtp,
                                                            value: fpState.formData?._ktpAddress?.ktpVillage,
                                                            //onChange: (value) => {
                                                            //    fpAction.getingPostalCodesKtp(
                                                            //        fpState.formData?._ktpAddress?.ktpCity, // ambil dari data Kota/Kab sebelumnya
                                                            //        fpState.formData?._ktpAddress?.ktpSubDistrict, // ambil dari data Kota/Kab sebelumnya.
                                                            //        value
                                                            //    )
                                                            //    fpAction.changeForm(value, "ktpVillage", "_ktpAddress")
                                                            //},
                                                            onChange: (value) => fpAction.changeForm(value, "ktpVillage", "_ktpAddress"),
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "RT",
                                                            maxLength: 3,
                                                            type: "text",
                                                            required: true,
                                                            placeholder: "RT",
                                                            value: fpState.formData?._ktpAddress?.ktpRt,
                                                            onChange: (value) => fpAction.changeForm(value, "ktpRt", "_ktpAddress"),
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "RW",
                                                            maxLength: 3,
                                                            type: "text",
                                                            required: true,
                                                            placeholder: "RW",
                                                            value: fpState.formData?._ktpAddress?.ktpRw,
                                                            onChange: (value) => fpAction.changeForm(value, "ktpRw", "_ktpAddress"),
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        label: 'Kode Pos',
                                        type: 'number',
                                        maxLength:5,
                                        placeholder: 'Pilih Kode Pos',
                                        required: true,
                                        //options: fpState.postalCodesKtp,
                                        value: fpState.formData?._ktpAddress?.ktpPostalCode,
                                        onChange: (value) => fpAction.changeForm(value.toString().replace(" ",""), "ktpPostalCode", "_ktpAddress"),
                                        onEnter:(value)=> fpAction.kodePos2(value,fpAction.changeForm("", "ktpPostalCode", "_ktpAddress")),
                                        onFocusOut:(value)=> fpAction.kodePos2(value,fpAction.changeForm("", "ktpPostalCode", "_ktpAddress")),
                                        disabled: !fpState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group form-group-sm  pl-2 pr-2">
                                                        <label style={{ fontSize: "12px" }}>Telepon Rumah 1</label>
                                                        {/* <div className="col-md-3"> */}
                                                        <div className="row">
                                                            {/* <div className="col-3">
                                                                <input
                                                                    placeholder="000"
                                                                    className="form-control form-control-sm"
                                                                    disabled={!fpState.formEditable}
                                                                />
                                                            </div> */}
                                                            <div className="col-12">
                                                                <input
                                                                    className="form-control form-control-sm"
                                                                    onChange={(e) => {
                                                                        fpAction.changeForm(e.target.value,"homePhone");
                                                                    }}
                                                                    value = {fpState.formData?.homePhone}
                                                                    disabled={!fpState.formEditable}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    },
                                    // {
                                    //     rows: 1,
                                    //     type: "custom",
                                    //     component: (
                                    //         <div className="row">
                                    //             <div className="col-md-12">
                                    //                 <div className="form-group form-group-sm  pl-2 pr-2">
                                    //                     <label style={{ fontSize: "12px" }}>Telepon Rumah 2</label>
                                    //                     {/* <div className="col-md-3"> */}
                                    //                     <div className="row">
                                    //                         {/* <div className="col-3">
                                    //                             <input
                                    //                                 placeholder="000"
                                    //                                 className="form-control form-control-sm"
                                    //                                 disabled={!fpState.formEditable}
                                    //                             />
                                    //                         </div> */}
                                    //                         <div className="col-12">
                                    //                             <input
                                    //                                 placeholder="000"
                                    //                                 className="form-control form-control-sm"
                                    //                                 disabled={!fpState.formEditable}
                                    //                             />
                                    //                         </div>
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //     )
                                    // },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                {/* <div className="col-3">
                                                    {
                                                        createForm({
                                                            rows: 1,
                                                            label: "Fax",
                                                            type: "number",
                                                            placeholder: "000",
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div> */}
                                                <div className="col-12">
                                                    {
                                                        createForm({
                                                            rows: 1,
                                                            label: "Fax",
                                                            // label: <> &nbsp; </>,
                                                            type: "number",
                                                            placeholder: "00",
                                                            value: fpState.formData?.homeFax,
                                                            onChange: (value) => fpAction.changeForm(value,"homeFax"),
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        label: "No Handphone",
                                        type: "number",
                                        placeholder: "000",
                                        required: true,
                                        maxLength:14,
                                        value: fpState.formData?.mobilePhone,
                                        onChange: (value) => fpAction.changeForm(value, "mobilePhone"),
                                        onEnter:(value)=> fpAction.checkHP(value,fpAction.changeForm("", "mobilePhone")),
                                        onFocusOut:(value)=> fpAction.checkHP(value,fpAction.changeForm("", "mobilePhone")),
                                        disabled: !fpState.formEditable
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
                <div className="col-md-6">
                    <FormRegistration
                        totalRows={1}
                        itemsForm={[
                            {
                                name: "Alamat Domisili",
                                toolbar: { name: "Salin dari alamat identitas", onClick: () => fpAction.salinDataAlamatDomisili() },
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Alamat',
                                        type: 'textarea',
                                        rowsText: 4,
                                        required: true,
                                        placeholder: 'Masukkan Alamat',
                                        value: fpState.formData?._mailingAddress?.mailingAddress,
                                        onChange: (value) => fpAction.changeForm(value, "mailingAddress","_mailingAddress"),
                                        disabled: !fpState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Provinsi",
                                                            type: "select",
                                                            required: true,
                                                            // options: fpState.provinces,
                                                            options: (fpState.provinces || []).map(({province_code, province_name}) => ({
                                                                province_code,
                                                                province_name : `${province_code} - ${province_name}`
                                                            })),
                                                            key: "province_code",
                                                            display: "province_name",
                                                            placeholder: "Masukkan Provinsi",
                                                            value: fpState.formData?._mailingAddress?.mailingAddressProvince,
                                                            onChange: (value) => {
                                                                const data = fpState.provinces?.find(v => v.province_name === value);
                                                                fpAction.getingCitiesDom(value, fpAction.changeForm("", "mailingAddressCity", "_mailingAddress"))
                                                                fpAction.changeForm(value, "mailingAddressProvince", "_mailingAddress")
                                                            },
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kota/Kab",
                                                            type: "select",
                                                            required: true,
                                                            // options: fpState.citiesDom,
                                                            options: (fpState.citiesDom || []).map(({city_code, city_name}) => ({
                                                                city_code,
                                                                city_name : `${city_code} - ${city_name}`
                                                            })),
                                                            key: "city_code",
                                                            display: "city_name",
                                                            placeholder: "Masukkan Kab/Kota",
                                                            value: fpState.formData?._mailingAddress?.mailingAddressCity || '',
                                                            onChange: (value) => {
                                                                // fpAction.getingDistrictDom(value)
                                                                fpAction.changeForm(value, "mailingAddressCity", "_mailingAddress")
                                                            },
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kecamatan",
                                                            type: "text",
                                                            required: true,
                                                            //options: fpState.districtsDom,
                                                            placeholder: "Masukkan Kecamatan",
                                                            value: fpState.formData?._mailingAddress?.mailingAddressSubDistrict,
                                                            //onChange: (value) => {
                                                            //    fpAction.getingVillagesDom(
                                                            //        fpState.formData?._mailingAddress?.mailingAddressCity, // ambil dari data Kota/Kab sebelumnya
                                                            //        value 
                                                            //    )
                                                            //    fpAction.changeForm(value, "mailingAddressSubDistrict", "_mailingAddress")
                                                            //},
                                                            onChange: (value) => fpAction.changeForm(value, "mailingAddressSubDistrict", "_mailingAddress"),
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kelurahan",
                                                            type: "text",
                                                            //options: fpState.villagesDom,
                                                            required: true,
                                                            placeholder: "Masukkan Kelurahan",
                                                            value: fpState.formData?._mailingAddress?.mailingAddressVillage,
                                                            //onChange: (value) => {
                                                            //    fpAction.getingPostalCodesDom(
                                                            //        fpState.formData?._mailingAddress?.mailingAddressCity, // ambil dari data Kota/Kab sebelumnya
                                                            //        fpState.formData?._mailingAddress?.mailingAddressSubDistrict, // ambil dari data Kota/Kab sebelumnya.
                                                            //        value
                                                            //    )
                                                            //    fpAction.changeForm(value, "mailingAddressVillage", "_mailingAddress")
                                                            //},
                                                            onChange: (value) => fpAction.changeForm(value, "mailingAddressVillage", "_mailingAddress"),
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "RT",
                                                            maxLength: 3,
                                                            type: "text",
                                                            required: true,
                                                            placeholder: "RT",
                                                            value: fpState.formData?._mailingAddress?.mailingAddressRt,
                                                            onChange: (value) => fpAction.changeForm(value, "mailingAddressRt","_mailingAddress"),
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "RW",
                                                            maxLength: 3,
                                                            type: "text",
                                                            required: true,
                                                            placeholder: "RW",
                                                            value: fpState.formData?._mailingAddress?.mailingAddressRw,
                                                            onChange: (value) => fpAction.changeForm(value, "mailingAddressRw","_mailingAddress"),
                                                            disabled: !fpState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        label: 'Kode Pos',
                                        type: 'number',
                                        maxLength:5,
                                        placeholder: 'Pilih Kode Pos',
                                        required: true,
                                        //options: fpState.postalCodesDom,
                                        value: fpState.formData?._mailingAddress?.mailingAddressPostalCode,
                                        onChange: (value) => fpAction.changeForm(value.toString().replace(" ",""), "mailingAddressPostalCode","_mailingAddress"),
                                        onEnter:(value)=> fpAction.kodePos3(value,fpAction.changeForm("", "mailingAddressPostalCode","_mailingAddress")),
                                        onFocusOut:(value)=> fpAction.kodePos3(value,fpAction.changeForm("", "mailingAddressPostalCode","_mailingAddress")),
                                        disabled: !fpState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        label: "Status Tempat Tinggal",
                                        type: "select",
                                        placeholder: "Pilih Status Tempat Tinggal",
                                        required: false,
                                        // options: fpState.dataDropDown?.R_STATUS_RUMAH,
                                        options: (fpState.dataDropDown?.R_STATUS_RUMAH || []).map(({reference_code, reference_desc}) => ({
                                            reference_code,
                                            reference_desc : `${reference_code} - ${reference_desc}`
                                        })),
                                        key: "reference_code",
                                        display: "reference_desc",
                                        // dicarikan fildnyaa
                                        value: fpState.formData?._dataTambahan?.status_tempat_tinggal,
                                        onChange: (value) => fpAction.changeForm(value, "status_tempat_tinggal","_dataTambahan"),
                                        disabled: !fpState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (

                                                <div className="row">
                                                    {/* <label>Lama Menempati Tempat Tinggal</label> */}
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                rows: 2,
                                                                label: "Lama Tinggal Bulan",
                                                                type: "number",
                                                                required: true,
                                                                placeholder: "",
                                                                value: fpState.formData?._mailingAddress?.lamaBulanTinggal,
                                                                onChange: (value) => fpAction.changeForm(value, "lamaBulanTinggal","_mailingAddress"),
                                                                disabled: !fpState.formEditable
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        {
                                                            createForm({
                                                                rows: 2,
                                                                label: "Lama Tinggal Tahun",
                                                                type: "number",
                                                                required: true,
                                                                placeholder: "",
                                                                value: fpState.formData?._mailingAddress?.lamaTahunTinggal,
                                                                onChange: (value) => fpAction.changeForm(value, "lamaTahunTinggal","_mailingAddress"),
                                                                disabled: !fpState.formEditable
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                            // <div className="row">
                                            //     <div className="col-md-12">
                                            //         <div className="form-group form-group-sm  pl-2 pr-2">
                                            //             <label style={{ fontSize: "12px" }}>Lama Menempati Tempat Tinggal</label>
                                            //             <span
                                            //                 style={{
                                            //                     padding: '2px 5px',
                                            //                     background: '#E8E8E8',
                                            //                     fontSize: '8pt',
                                            //                     fontWeight: 'normal',
                                            //                     borderRadius: 3,
                                            //                 }}
                                            //             >
                                            //                 Wajib
                                            //             </span>
                                            //             <div className="row">
                                            //                 <div className="input-group col-6">
                                                                
                                            //                     <input
                                            //                         placeholder="0"
                                            //                         type="number"
                                            //                         className="form-control form-control-sm"
                                            //                         disabled={!fpState.formEditable}
                                            //                         value =  {fpState.formData?._mailingAddress?.lamaBulanTinggal}
                                            //                         required={true}
                                            //                         onChange={(e) =>{
                                            //                             fpAction.changeForm(e.target.value,"lamaBulanTinggal","_mailingAddress")
                                            //                         }}
                                            //                     />
                                            //                      <div class="input-group-prepend pt-1 pl-1">
                                            //                         <span> Tahun </span>
                                            //                     </div>
                                            //                 </div>
                                            //                 <div className="input-group col-6">
                                            //                     <input
                                            //                         type="number"
                                            //                         placeholder="0"
                                            //                         required={true}
                                            //                         className="form-control form-control-sm"
                                            //                         disabled={!fpState.formEditable}
                                            //                         value =  {fpState.formData?._mailingAddress?.lamaTahunTinggal}
                                            //                         onChange={(e) =>{
                                            //                             fpAction.changeForm(e.target.value,"lamaTahunTinggal","_mailingAddress")
                                            //                         }}
                                            //                     />
                                                                
                                            //                     <div class="input-group-prepend  pt-1 pl-1">
                                            //                     <span> Bulan </span>
                                            //                     </div>
                                            //                 </div>
                                            //             </div>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                        )
                                    },
                                ]
                            }
                        ]}
                    />
                </div>
            </div>

            <FormRegistration 
                itemsForm ={[
                    {
                        name:"Alamat Luar Negeri",
                        rows:[
                            {
                                rows: 1,
                                type: "textarea",
                                label: "Alamat Luar Negeri",
                                placeholder: "Masukkan Alamat luar negeri",
                                required: false,
                                value: fpState.formData?._mailingAddress?.AlamatLuarNegeri,
                                onChange: (value) => fpAction.changeForm(value, "AlamatLuarNegeri","_mailingAddress"),
                                disabled: !fpState.formEditable
                                //no field
                            }
                        ]
                    }
                ]}
            />
        </>
    );
}

export default PekerjaanAlamat;