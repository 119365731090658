import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from '../route/routeConfig';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from '../hooks/keycloak.module';

const autRoutes = routes.filter((v) => !v.auth);

const UnAuthRouter = () => {
    const { keycloak } = useKeycloak()
    return (
        <>
            <>
                {/* <Suspense fallback={<Loading/>}> */}
                <Switch>
                    {autRoutes.map((r, key) => (
                        <Route
                            exact={true}
                            key={key}
                            path={r.path}
                            render={(props) => <r.component {...props} />}
                        />
                    ))}
                    {
                        keycloak.authenticated ? 
                        <Redirect from="/login" to="/" /> :
                        <Redirect from="/" to="/login"/>
                    }
                </Switch>
                {/* </Suspense> */}
            </>
        </>
    );
};

export default UnAuthRouter;
