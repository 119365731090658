import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { baseUrl } from '../../../app.config';

const SpecimenPejabatModal = ({ show, onHide, onSubmit, type = 'registrasi', initialState }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState({});

    useEffect(() => {
        fetch(baseUrl + `/nasabah/svs/mambu/find/${initialState}?type=GROUP`)
            .then((response) => response.json())
            .then((data) => setData(data?.list?.db?.list));
    }, [initialState]);

    const handleHide = () => {
        setError({});

        if (onHide) onHide();
    };

    return (
        <Modal size="xl" centered show={show}>
            <Modal.Header onHide={handleHide} style={{ padding: '16px 32px' }} closeButton>
                <Modal.Title>
                    <h5 className="tw-text-18 tw-font-bold">Data SVS</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '16px 32px' }}>
                <div className="card">
                    <div className="card-body p0">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th>Image Tag</th>
                                    <th>Taq Sequence</th>
                                    <th>Remark</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.length > 0
                                    ? data.map((data, index) => {
                                          return (
                                              <tr key={`listsaccountimages${index}`}>
                                                  <td>{data?.image_tag}</td>
                                                  <td>{index + 1}</td>
                                                  <td>{data?.remark}</td>
                                                  <td>{data?.type}</td>
                                              </tr>
                                          );
                                      })
                                    : null}
                            </tbody>
                        </table>
                        <div className="p-3">
                            <div className="row">
                                {}
                                {data?.length > 0
                                    ? data.map((data, index) => {
                                          return (
                                              <div
                                                  className="col-4"
                                                  key={`listsaccountimagessign${index}`}
                                              >
                                                  <p>
                                                      Image {index + 1} - {data?.image_tag}
                                                  </p>
                                                  <div className="svs-box">
                                                      <div className="mt-5 text-center">
                                                          <img
                                                              src={data?.signed_url}
                                                              height={110}
                                                          />
                                                      </div>
                                                  </div>
                                                  <p className="border p-2 mt-2 fs-12">
                                                      {data?.remark}
                                                  </p>
                                              </div>
                                          );
                                      })
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleHide} className="mr-2 text-white" size="mini">
                    Batal
                </Button>
                {/* <Button onClick={handleSubmit} color="blue" size="mini">
                    Simpan
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default SpecimenPejabatModal;
