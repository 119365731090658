import Keycloak from 'keycloak-js';

export const keycloakConfig = {
    realm: process.env.REACT_APP_KEYCLOAK_REALM_CODE || 'bds',
    url: process.env.REACT_APP_KEYCLOAK_BASE_URL || 'https://sso-unife-bns.ihsansolusi.co.id/auth/',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_CODE || 'unifeui',
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;

