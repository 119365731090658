import moment from 'moment';
import React, { useEffect } from 'react';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistration } from '../../../../../controls';
import usePeroranganWic from './useFormPerorangan_wic';

const DataPribadi = () => {
    
    const { fpwAction, fpwState } = usePeroranganWic();
    const { formData = {}, formEditable = false } = fpwState;
    
    return (
        <>
            <div className="pb-3">
                <div className="box">
                    <div className="row">
                        <div className="col-3 align-self-center bns-form">
                            <div className="form-group">
                                <label>Nomor CIF</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="Otomatis terisi dari sistem"
                                    value={formData?.id}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FormRegistration
                isLoading={fpwState.isLoading}
                itemsForm={[
                   // Identitas
                   {
                        name: "Identitas",
                        rows: 
                        [
                            {
                                rows: 1,
                                label: 'Jenis Identitas ',
                                type: 'select', // select, date, number, text, checkbox, custom
                                placeholder: 'Pilih Jenis Identitas',
                                required: true,
                                options: fpwState.dataDropDown?.eJenisIdentitas,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpwState.formData?._personalIdData?.personalIdType,
                                onChange: (value) =>{
                                    fpwAction.changeForm(value,"personalIdType","_personalIdData")
                                    if(value === "1" ){
                                        fpwAction.changeForm("1", "personalCitizenship", "_customerData")
                                        fpwAction.changeForm("ID", "ktpCountry","_ktpAddress")
                                    }
                                }, 
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Nomor Identitas ',
                                type: 'number',
                                placeholder: 'Masukan Nomor Identitas',
                                required: true,
                                maxLength:16,
                                value: fpwState.formData?._personalIdData?.personalIdNumber,
                                onChange: (value) => fpwAction.changeForm(value,"personalIdNumber","_personalIdData"),
                                disabled: !fpwState.formEditable
                            },
                            // {
                            //     rows: 2,
                            //     type: 'custom',
                            //     component: (
                            //         <div className="row">
                            //             <div className="col">
                            //                 {
                            //                     createForm({
                            //                         label: 'Tgl Terbit Identitas',
                            //                         type: 'date',
                            //                         value: fpwState.formData?._personalIdData?.personalIdDateOfIssue || '',
                            //                         onChange: (value) => fpwAction.changeForm(value,"personalIdDateOfIssue","_personalIdData"),
                            //                         disabled: !fpwState.formEditable,
                            //                         required: false
                            //                         // placeholder: 'Masukan Nama (Sesuai Identitas)',
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col">
                            //                 {
                            //                     createForm({
                            //                         label: 'Tgl Berakhir Identitas',
                            //                         type: 'date',
                            //                         value: fpwState.formData?._personalIdData?.personalIdExpireDate || '',
                            //                         onChange: (value) => fpwAction.changeForm(value,"personalIdExpireDate","_personalIdData"),
                            //                         disabled: !fpwState.formEditable,
                            //                         required: false
                            //                         // placeholder: 'Masukan Nama (Sesuai Identitas)',
                            //                     })
                            //                 }
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            {
                                rows: 2,
                                label: 'Nomor NPWP',
                                type: 'number',
                                placeholder: 'Masukan Nomor NPWP',
                                required: false,
                                maxLength:15,
                                value: fpwState.formData?._personalIdData?.personalNpwpNumber,
                                onChange: (value) => fpwAction.changeForm(value, "personalNpwpNumber","_personalIdData"),
                                disabled: !fpwState.formEditable
                            }
                        ]
                    },
                // End Identitas
                //Datadiri
                    {
                        name : "Data Diri",
                        rows : 
                        [
                            {
                                rows: 1,
                                label: 'Nama (Sesuai Identitas) ',
                                type: 'text',
                                placeholder: 'Msaukan Nama',
                                required: true,
                                value: fpwState.formData?._personalIdData?.personalIdHolderName,
                                onChange: (value) => fpwAction.changeForm(value,"personalIdHolderName","_personalIdData"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Nama Tanpa Singkatan ',
                                type: 'text',
                                placeholder: 'Masukan Nama Tanpa Singkatan ',
                                required: true,
                                value: fpwState.formData?.firstName,
                                onChange: (value) => fpwAction.changeForm(value,"firstName"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Gelar Depan',
                                                    type: 'text',
                                                    placeholder: 'Gelar Depan',
                                                    value: fpwState.formData?._customerData?.customerPreTitle,
                                                    onChange: (value) => fpwAction.changeForm(value,"customerPreTitle","_customerData"),
                                                    disabled: !fpwState.formEditable
                                                    // required: true
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Gelar Belakang',
                                                    type: 'text',
                                                    placeholder: 'Gelar Belakang',
                                                    value: fpwState.formData?._customerData?.customerPostTitle,
                                                    onChange: (value) => fpwAction.changeForm(value,"customerPostTitle","_customerData"),
                                                    disabled: !fpwState.formEditable
                                                    // required: true
                                                })
                                            }
                                        </div>
                                    </div>)
                            },
                            {
                                rows: 1,
                                label: 'Nama Singkat ',
                                type: 'text',
                                placeholder: 'Masukan Nama Singkat ',
                                required: false,
                                value: fpwState.formData?._customerData?.customerAliasName,
                                onChange: (value) => fpwAction.changeForm(value,"customerAliasName","_customerData"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tempat/Tanggal Lahir',
                                                    type: 'text',
                                                    placeholder: "Masukkan tempat lahir",
                                                    value: fpwState.formData?._customerData?.birthPlace,
                                                    onChange: (value) => fpwAction.changeForm(value,"birthPlace","_customerData"),
                                                    disabled: !fpwState.formEditable,
                                                    required: true
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: <>&nbsp;</>,
                                                    type: 'date',
                                                    value: fpwState.formData?.birthDate || '',
                                                    onChange: (value) => fpwAction.changeForm(value, "birthDate"),
                                                    disabled: !fpwState.formEditable,
                                                    required: false
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: 'Nama Ibu Kandung ',
                                type: 'text',
                                placeholder: 'Masukan Nama Ibu Kandung ',
                                required: true,
                                value: fpwState.formData?._customerData?.personalMothersName,
                                onChange: (value) => fpwAction.changeForm(value,"personalMothersName","_customerData"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col pl-3">
                                            <div className="form-group">
                                                <label 
                                                    style={{ fontSize: "12px" }}
                                                    className={formData?.gender === "" || formData?.gender === undefined|| formData?.gender === null ? "text-danger" : ""}
                                                >
                                                    Jenis Kelamin
                                                    <span
                                                        style={{
                                                            padding: '2px 5px',
                                                            background: '#E8E8E8',
                                                            fontSize: '8pt',
                                                            fontWeight: 'normal',
                                                            borderRadius: 3,
                                                        }}
                                                    >
                                                        Wajib
                                                    </span>
                                                </label>
                                                <br />
                                                <div className="form-check-inline">
                                                    <label className="form-check-label"
                                                        style={{
                                                            fontSize: '10pt',
                                                            fontWeight: 'normal',
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            checked={formData?.gender === "MALE"}
                                                            className="form-check-input"
                                                            name="gender"
                                                            onChange={(e) => {
                                                                fpwAction.changeForm(e.target.value, "gender");
                                                            }}
                                                            value="MALE"
                                                            disabled={!fpwState.formEditable}
                                                        />Laki-laki
                                                    </label>
                                                </div>
                                                <div className="form-check-inline ml-3">
                                                    <label className="form-check-label"
                                                        style={{
                                                            fontSize: '10pt',
                                                            fontWeight: 'normal',
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="gender"
                                                            checked={formData?.gender === "FEMALE"}
                                                            onChange={(e) => {
                                                                fpwAction.changeForm(e.target.value, "gender");
                                                            }}
                                                            value="FEMALE"
                                                            disabled={!fpwState.formEditable}
                                                        />Perempuan
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Alamat Email ',
                                type: 'text',
                                placeholder: 'Masukan Alamat Email ',
                                required: false,
                                value: fpwState.formData?.emailAddress,
                                onChange: (value) => fpwAction.changeMailForm(value,"emailAddress"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Agama ',
                                type: 'select', 
                                placeholder: 'Pilih Agama',
                                required: false,
                                options: fpwState.dataDropDown?.eAgama,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpwState.formData?._customerData?.personalReligion,
                                onChange: (value) => fpwAction.changeForm(value,"personalReligion","_customerData"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Status Perkawinan ',
                                type: 'select', 
                                placeholder: 'Pilih Status Perkawinan',
                                required: true,
                                options: fpwState.dataDropDown?.eStatusPerkawinan,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpwState.formData?._customerData?.personalMaritalStatus,
                                onChange: (value) => fpwAction.changeForm(value,"personalMaritalStatus","_customerData"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Kewarganegaraan ',
                                type: 'select', 
                                placeholder: 'Pilih Kewarganegaraan',
                                required: true,
                                options: fpwState.dataDropDown?.eKewarganegaraan,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpwState.formData?._customerData?.personalCitizenship,
                                onChange: (value) => fpwAction.changeForm(value,"personalCitizenship","_customerData"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Negara Asal ',
                                type: 'select', 
                                placeholder: 'Pilih Negara Asal',
                                required: true,
                                options: fpwState.dataDropDown?.R_KODE_NEGARA,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpwState.formData?._ktpAddress?.ktpCountry,
                                onChange: (value) => fpwAction.changeForm(value,"ktpCountry","_ktpAddress"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Jenis Penduduk ',
                                type: 'select', 
                                placeholder: 'Pilih Jenis Penduduk',
                                required: true,
                                options: fpwState.dataDropDown?.eJenisPenduduk,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpwState.formData?._personalIdData?.populationType,
                                onChange: (value) => fpwAction.changeForm(value,"populationType","_personalIdData"),
                                disabled: !fpwState.formEditable
                            },
                        ]
                    }
                //End Datadiri
                ]}
            />
             <div className="row">
                <div className="col-md-6 mt-0">
                    <FormRegistration
                    
                isLoading={fpwState.isLoading}
                        totalRows={1}
                        itemsForm={[
                            {
                                name: "Alamat Identitas",
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Alamat',
                                        type: 'textarea',
                                        rowsText: 4,
                                        required: true,
                                        placeholder: 'Masukkan Alamat',
                                        value: fpwState.formData?._ktpAddress?.ktpAddress,
                                        onChange: (value) => fpwAction.changeForm(value, "ktpAddress","_ktpAddress"),
                                        disabled: !fpwState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Provinsi",
                                                            type: "select",
                                                            required: true,
                                                            placeholder: "Masukkan Provinsi ",
                                                            options: fpwState.provinces,
                                                            key: "province_code",
                                                            display: "province_name",
                                                            value: fpwState.formData?._ktpAddress?.ktpProvince,
                                                            onChange: (value) => {
                                                                const data = fpwState.provinces?.find(v => v.province_name === value);
                                                                fpwAction.getingCitiesKtp(value,  fpwAction.changeForm("","ktpCity","_ktpAddress"))
                                                                fpwAction.changeForm(value, "ktpProvince","_ktpAddress")
                                                            },
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kota/Kab",
                                                            type: "select",
                                                            required: true,
                                                            key: "city_code",
                                                            display: "city_name",
                                                            placeholder: "Masukkan Kab/Kota",
                                                            options: fpwState.citiesKtp,
                                                            value: fpwState.formData?._ktpAddress?.ktpCity,
                                                            onChange: (value) => {
                                                                // fpwAction.getingDistrictKtp(value)
                                                                fpwAction.changeForm(value,"ktpCity","_ktpAddress")
                                                            },
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kecamatan",
                                                            type: "text",
                                                            required: true,
                                                            placeholder: "Masukkan Kecamatan",
                                                            //options: fpwState.districtsKtp,
                                                            value: fpwState.formData?._ktpAddress?.ktpSubDistrict,
                                                            //onChange: (value) => {
                                                            //    fpwAction.getingVillagesKtp(
                                                            //        fpwState.formData?._ktpAddress?.ktpCity, // ambil dari data Kota/Kab sebelumnya
                                                            //        value 
                                                            //    )
                                                            //    fpwAction.changeForm(value, "ktpSubDistrict","_ktpAddress")
                                                            //},
                                                            onChange: (value) => fpwAction.changeForm(value, "ktpSubDistrict","_ktpAddress"),
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kelurahan",
                                                            type: "text",
                                                            required: true,
                                                            placeholder: "Masukkan Kelurahan",
                                                            //options: fpwState.villagesKtp,
                                                            value: fpwState.formData?._ktpAddress?.ktpVillage,
                                                            //onChange: (value) => {
                                                            //    fpwAction.getingPostalCodesKtp(
                                                            //        fpwState.formData?._ktpAddress?.ktpCity, // ambil dari data Kota/Kab sebelumnya
                                                            //        fpwState.formData?._ktpAddress?.ktpSubDistrict, // ambil dari data Kota/Kab sebelumnya.
                                                            //        value
                                                            //    )
                                                            //    fpwAction.changeForm(value, "ktpVillage","_ktpAddress")
                                                            //},
                                                            onChange: (value) => fpwAction.changeForm(value, "ktpVillage","_ktpAddress"),
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "RT ",
                                                            maxLength: 3,
                                                            type: "number",
                                                            required: true,
                                                            placeholder: "Masukan Nomer RT",
                                                            value: fpwState.formData?._ktpAddress?.ktpRt,
                                                            onChange: (value) => fpwAction.changeForm(value, "ktpRt","_ktpAddress"),
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "RW",
                                                            maxLength: 3,
                                                            type: "number",
                                                            required: true,
                                                            placeholder: "Masukan Nomer RW",
                                                            value: fpwState.formData?._ktpAddress?.ktpRw,
                                                            onChange: (value) => fpwAction.changeForm(value, "ktpRw","_ktpAddress"),
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        label: 'Kode Pos',
                                        type: 'number',
                                        maxLength:5,
                                        placeholder: 'Pilih Kode Pos',
                                        required: true,
                                        //options: fpwState.postalCodesKtp,
                                        value: fpwState.formData?._ktpAddress?.ktpPostalCode,
                                        onChange: (value) => fpwAction.changeForm(value, "ktpPostalCode","_ktpAddress"),
                                        disabled: !fpwState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        label: "No Telepon Rumah 1",
                                        type: "number",
                                        placeholder: "000",
                                        value: fpwState.formData?.homePhone,
                                        onChange: (value) => fpwAction.changeForm(value, "homePhone"),
                                        disabled: !fpwState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        label: "No Handphone",
                                        type: "number",
                                        placeholder: "000",
                                        maxLength:14,
                                        value: fpwState.formData?.mobilePhone,
                                        onChange: (value) => fpwAction.changeForm(value, "mobilePhone"),
                                        onEnter:(value)=> fpwAction.checkHpWic(value, fpwAction.changeForm("", "mobilePhone")),
                                        onFocusOut:(value)=> fpwAction.checkHpWic(value, fpwAction.changeForm("", "mobilePhone")),
                                        disabled: !fpwState.formEditable,
                                        required: true,
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
                <div className="col-md-6">
                    <FormRegistration
                    
                    isLoading={fpwState.isLoading}
                        totalRows={1}
                        itemsForm={[
                            {
                                name: "Alamat Domisili",
                                toolbar: { name: "Salin dari alamat identitas", onClick: () => fpwAction.salinDataAlamatDomisili() },
                                rows: [
                                    {
                                        rows: 1,
                                        label: 'Alamat',
                                        type: 'textarea',
                                        rowsText: 4,
                                        required: true,
                                        placeholder: 'Masukkan Alamat',
                                        value: fpwState.formData?._mailingAddress?.mailingAddress,
                                        onChange: (value) => fpwAction.changeForm(value, "mailingAddress","_mailingAddress"),
                                        disabled: !fpwState.formEditable
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Provinsi",
                                                            type: "select",
                                                            required: true,
                                                            options: fpwState.provinces,
                                                            key: "province_code",
                                                            display: "province_name",
                                                            placeholder: "Masukkan Provinsi",
                                                            value: fpwState.formData?._mailingAddress?.mailingAddressProvince,
                                                            onChange: (value) => {
                                                                const data = fpwState.provinces?.find(v => v.province_name === value);
                                                                fpwAction.getingCitiesDom(value, fpwAction.changeForm("", "mailingAddressCity","_mailingAddress"))
                                                                fpwAction.changeForm(value, "mailingAddressProvince","_mailingAddress")
                                                            },
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kota/Kab",
                                                            type: "select",
                                                            required: true,
                                                            options: fpwState.citiesDom,
                                                            key: "city_code",
                                                            display: "city_name",
                                                            placeholder: "Masukkan Kab/Kota",
                                                            value: fpwState.formData?._mailingAddress?.mailingAddressCity,
                                                            onChange: (value) => {
                                                                // fpwAction.getingDistrictDom(value)
                                                                fpwAction.changeForm(value, "mailingAddressCity","_mailingAddress")
                                                            },
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kecamatan",
                                                            type: "text",
                                                            required: true,
                                                            //options: fpwState.districtsDom,
                                                            placeholder: "Masukkan Kecamatan",
                                                            value: fpwState.formData?._mailingAddress?.mailingAddressSubDistrict,
                                                            //onChange: (value) => {
                                                            //    fpwAction.getingVillagesDom(
                                                            //        fpwState.formData?._mailingAddress?.mailingAddressCity, // ambil dari data Kota/Kab sebelumnya
                                                            //        value 
                                                            //    )
                                                            //    fpwAction.changeForm(value, "mailingAddressSubDistrict","_mailingAddress")
                                                            //},
                                                            onChange: (value) => fpwAction.changeForm(value,"mailingAddressSubDistrict","_mailingAddress"),
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "Kelurahan",
                                                            type: "text",
                                                            //options: fpwState.villagesDom,
                                                            required: true,
                                                            placeholder: "Masukkan Kelurahan",
                                                            value: fpwState.formData?._mailingAddress?.mailingAddressVillage,
                                                            //onChange: (value) => {
                                                            //    fpwAction.getingPostalCodesDom(
                                                            //        fpwState.formData?._mailingAddress?.mailingAddressCity, // ambil dari data Kota/Kab sebelumnya
                                                            //        fpwState.formData?._mailingAddress?.mailingAddressSubDistrict, // ambil dari data Kota/Kab sebelumnya.
                                                            //        value
                                                            //    )
                                                            //    fpwAction.changeForm(value, "mailingAddressVillage","_mailingAddress")
                                                            //},
                                                            onChange: (value) => fpwAction.changeForm(value,"mailingAddressVillage","_mailingAddress"),
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "RT",
                                                            type: "text",
                                                            maxLength: 3,
                                                            required: true,
                                                            placeholder: "Masukan Nomer RT",
                                                            value: fpwState.formData?._mailingAddress?.mailingAddressRt,
                                                            onChange: (value) => fpwAction.changeForm(value, "mailingAddressRt","_mailingAddress"),
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    {
                                                        createForm({
                                                            rows: 2,
                                                            label: "RW",
                                                            maxLength: 3,
                                                            type: "number",
                                                            required: true,
                                                            placeholder: "Masukan Nomer RW",
                                                            value: fpwState.formData?._mailingAddress?.mailingAddressRw,
                                                            onChange: (value) => fpwAction.changeForm(value, "mailingAddressRw","_mailingAddress"),
                                                            disabled: !fpwState.formEditable
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        rows: 1,
                                        label: 'Kode Pos',
                                        type: 'number',
                                        maxLength:5,
                                        placeholder: 'Pilih Kode Pos',
                                        required: true,
                                        //options: fpwState.postalCodesDom,
                                        value: fpwState.formData?._mailingAddress?.mailingAddressPostalCode,
                                        onChange: (value) => fpwAction.changeForm(value,"mailingAddressPostalCode","_mailingAddress"),
                                        disabled: !fpwState.formEditable
                                    },
                                ]
                            }
                        ]}
                    />
                </div>
            </div>
        </>
    );
}

export default DataPribadi;