import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';

const rekContext = createContext(null)

export const RekeningProvider = ({ children }) => {

    const { doGet, doPost, wait } = useFetch();
    const [pagination, setPagination] = useState({ page: 1, show: 15 });
    const [filter, setFilter] = useState({ keyword: '' })
    const [dataListRekening, setListDataRekening] = useState([])
    const [dataAlert, setDataAlert] = useState({ show: false, body: "", header: null, title: "", callBack: () => null });
    const [dataConfirm, setDataConfirm] = useState({ show: false, body: "", title: "", header: null, footerButton: [], closeButton: false, witTextArea: false });
    const [isLoading, setIsLoading] = useState(true)
    const [loadingDetil, setLoadingDetil] = useState(true);
    const [rekSelected, setRekSelected] = useState({});

    // useEffect GetData
    useEffect(function () {
        getDataList()
    }, [pagination])

    // close Alert
    function closeAlert() { setDataAlert({ show: false, body: "", header: null, title: "", callBack: () => null }) }

    // closeConfirm
    function closeConfirm() { setDataConfirm({ show: false, body: "", header: null, title: "", footerButton: [], closeButton: true, witTextArea: false }) }

    // getData rekening list
    async function getDataList() {
        setIsLoading(true)
        var url = `/nasabah/search`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { ...pagination, name: filter.keyword }

            });

            if (statusCode === 200) {
                if (status === "00") {
                    setListDataRekening(data?.list)
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }

        setIsLoading(false)
    }

    function resetFilter() {
        setFilter({
            keyword: ''
        })
    }
    // next pagination
    function nextPage({ page, limit }) {
        // console.log(page)
        setPagination({
            page,
            show: limit
        })
    }

    // prev pagination
    function prevPage({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }
    // change show
    function changeShow({ page, limit }) {
        setPagination({
            page,
            show: limit
        })
    }

    return <rekContext.Provider
        value={{
            rekState: {
                dataListRekening,
                dataAlert,
                dataConfirm,
                isLoading,
                filter,
                loadingDetil,
                rekSelected
            },
            rekAction: {
                getDataList,
                nextPage,
                prevPage,
                changeShow,
                closeAlert,
                closeConfirm,
                setFilter,
                resetFilter,
            }
        }}
    >
        {children}
    </rekContext.Provider>;
}


export default function useDeposito() { return useContext(rekContext) };