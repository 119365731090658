import React, { createContext, useContext, useState } from 'react';
import { useModalAlert } from '../components/controls/modalAlert';
import ModalConfirmCustom from '../components/controls/modalAlert/modalConfirmCustomChild';
import useFetch from './useFetch';
// import { useKeycloak } from '@react-keycloak/web';
import useKeycloak from './keycloak.module';
import { useHistory, useLocation, useParams } from 'react-router';

const respOtorContext = createContext({

})
export const RespOtorProvider = function ({
    children
}) {


    const { keycloak } = useKeycloak()

    const { modalState, modalAction } = useModalAlert();
    const [withTxt, setWithTxt] = useState(false)
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        openLoading,
        closeConfirmCustom,
        openAlertCustom
    } = modalAction;
    const [otorDesc, setOtorDesc] = useState("")
    const [formData, setFormData] = useState({
        username:"",
        passsword:""
    })
    const { doGet, doPost, wait } = useFetch();

    const location = useLocation();


    // response otorisasi
    function responseOtor(options = {
        statusApprove : "REJECTED",
        service : "",
        url : "",
        method: "PUT"
    }, callBack) {
        setWithTxt(options.statusApprove === "REJECTED")
        let body = `${options.statusApprove}. Lanjutkan ?`;
        openAlertCustom({
            show: true,
            body,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(options, callBack), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "respOtor")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi({
        statusApprove,
        service,
        method
    }, callBack = () => null) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirm();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }else if(statusApprove === "APPROVED") keterangan = "Approve"
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method, //: "PUT",
                    service, //: "trx",
                    data: { 
                        keterangan, 
                    //    ...formData 
                    },
                    queryParam: {
                        keterangan,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            callBack?.()
                        }
                    });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    return (
        <respOtorContext.Provider value={{
            responseOtor
        }} >
            <ModalConfirmCustom id="respOtor">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label>Username</label>
                            <input className="form-control" placeholder="Username" />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input className="form-control" type="password" placeholder="password"/>
                        </div>
                    </div>
                </div>

            </ModalConfirmCustom>
            {children}
        </respOtorContext.Provider>
    )
}


export default function useRespOtor({
    service = "",
}) {
    const {responseOtor} = useContext(respOtorContext)

    function respOtor({
        apiService =undefined, 
        apiUrl = undefined, 
        statusApprove = "REJECTED",
        method = "PUT"

    }, callBack = () => null){
        responseOtor({
            statusApprove,
            service : apiService || service,
            url : apiUrl, // || url,
            method
        }, callBack)
    }

    return {
        respOtor
    }
}