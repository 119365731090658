import React, { useState, useEffect, memo } from 'react';
import { TableList } from '../../controls';

const RiwayatTransaksi = memo((props) => {
    const [data, setData] = useState({onload: false});

    useEffect(() => {
        getDataHandler({})
    }, []);

    const getDataHandler = ({page=0, limit= 15 }) => {
        setData({onload: true})
        setTimeout(() => {
            setData({onload: false})
        }, 500);
    }

    return (
        <div className="col-md-12 pt-4">
            <h4>Data Riwayat Transaksi</h4>
            <TableList
                maxHeight={'calc(100vh - 37.5rem)'}
                minHeight={'calc(100vh - 38rem)'}
                withCard={false}
                isLoading={data.isLoading}
                onChangeShow={(detil) => getDataHandler(detil)}
                onNextPage={(detil) => getDataHandler(detil)}
                onPrevPage={(detil) => getDataHandler(detil)}
                withActions={false}
                columns={[
                    {
                        name: 'Tanggal Transaksi',
                        field: 'tgl_transaksi',
                        maxWidth: '50px',
                    },
                    {
                        name: 'No Referensi',
                        field: 'no_referensi',
                        maxWidth: '50px',
                    },
                    {
                        name: 'Jenis Transaksi',
                        field: 'jenis_transaksi',
                        maxWidth: '50px',
                    },
                    {
                        name: 'Nilai Mutasi',
                        className: "text-right",
                        field: 'nilai_mutasi',
                        maxWidth: '50px',
                    },
                    {
                        name: 'Debit/Kredit',
                        field: 'debit_kredit',
                        maxWidth: '50px',
                    },
                    {
                        name: 'Keterangan',
                        field: 'keterangan',
                        maxWidth: '50px',
                    },
                ]}
                data={[
                    {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                    // {tgl_transaksi: "24-04-2015", no_referensi: "RF1121111", jenis_transaksi: "Pindah Buku", nilai_mutasi: '1,000,000', debit_kredit: "DEBIT", keterangan: "-"},
                ]}
                filter={true}
                formsFilter={[
                    {
                        type: 'select',
                        options: [],
                        placeholder: 'Tanggal Awal',
                        value: "",
                        disabled: false,
                        readonly: false,
                        onChange: (val) =>  console.log(val),
                    },{
                        type: 'select',
                        options: [],
                        placeholder: 'Tanggal Akhir',
                        value: "",
                        disabled: false,
                        readonly: false,
                        onChange: (val) =>  console.log(val),
                    },
                ]}
                onFilter={() =>  console.log("filter")}
                resetFilter={false}
                submitButton="Lihat Data"
            />
        </div>
    )
})

export default RiwayatTransaksi;