import moment from 'moment';
import React from 'react';
import { createForm } from '../../../../../../src/module/createFormList';
import { FormRegistration } from '../../../../controls';
import { Link } from 'react-router-dom';
import useTarikTunai from './useTarikTunai';
import { addCommas } from '../../../../../../src/module';

const TarikTunai = () => {

    const { ttAction, ttState } = useTarikTunai();
    const { formData = {}, formEditable = false } = ttState;
    return (
        <>
            <FormRegistration
                itemsForm={[
                    // Informasi Lain
                    {
                        name: "Informasi Kas Teller",
                        rows: [
                            {
                                rows: 1,
                                type: "number",
                                label: "Saldo Kas",
                                required: true,
                                placeholder: "0.00",
                                disabled: !ttState.formEditable || true,
                                value: addCommas(parseFloat(ttState.formData?.info_transaksi?.saldo_teller).toFixed(2)),
                                onChange: (value) => ttAction.changeForm(value, "saldo_teller", "info_transaksi"),
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-0">
                                                <label>Valuta Rekening</label>
                                                {/* <span className="badge badge-secondary">
                                                    Wajib
                                                </span> */}
                                               <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        type="text"
                                                        // required
                                                        className="form-control form-control-sm col-2"
                                                        placeholder="IDR"
                                                        value={ttState.formData?.kode_valuta}
                                                        readOnly
                                                        disabled={!ttState.formEditable}
                                                    />
                                                    <div className="input-group-append col pl-0 pr-0">
                                                        <input
                                                            type="text"
                                                            // required
                                                            className="form-control form-control-sm col"
                                                            placeholder="INDONESIAN RUPIAH"
                                                            value={"INDONESIA RUPIAH"}
                                                            readOnly
                                                            disabled={!ttState.formEditable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                        ]
                    },
                    //Data Pengirim
                    {
                        name: "Informasi Rekening",
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                    <div className="col-6">
                                        {
                                            createForm({
                                                label: 'Nomor Rekening',
                                                type: 'text',
                                                required: true,
                                                placeholder: 'No. Rekening',
                                                value: ttState.formData?.nomor_rekening,
                                                onChange: (value) => ttAction.changeForm(value, "nomor_rekening"),
                                                disabled: !ttState.formEditable,
                                                onEnter:(value)=> ttAction.cekNasabah(value),
                                                onFocusOut:(v) => ttAction.cekNasabah(v)
                                            })
                                        }
                                    </div>
                                    <div className="col-6">
                                        {
                                            createForm({
                                                label: <>&nbsp;</>,
                                                type: 'text',
                                                required: false,
                                                placeholder: 'Masukan nama',
                                                readOnly: true,
                                                value: ttState.formData?.info_transaksi?.info_rekening?.nama_rekening,
                                                // onChange: (value) => ttAction.changeForm(value, "nama_rekening_pencairan","_rekeningDeposito"),
                                                disabled: !ttState.formEditable
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-0">
                                                <label>Valuta Rekening</label>
                                                {/* <span className="badge badge-secondary">
                                                    Wajib
                                                </span> */}
                                                <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        type="text"
                                                        // required
                                                        className="form-control form-control-sm col-2"
                                                        placeholder="IDR"
                                                        value={ttState.formData?.info_transaksi?.info_rekening?.kode_valuta}
                                                        disabled={!ttState.formEditable || true}
                                                    />
                                                    <div className="input-group-append col pl-0 pr-0">
                                                        <input
                                                            type="text"
                                                            // required
                                                            className="form-control form-control-sm col"
                                                            placeholder="INDONESIAN RUPIAH"
                                                            value={ttState.formData?.info_transaksi?.info_rekening?.keterangan_valuta}
                                                            readOnly
                                                            disabled={!ttState.formEditable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                //Belum ada field di API
                                rows: 2,
                                label: 'Produk',
                                type: 'text',
                                placeholder: 'Masukan Produk',
                                required: false,
                                disabled: true,
                                value: ttState.formData?.info_transaksi?.info_rekening?.produk,
                                // onChange: (value) => ttAction.changeForm(value, "Produk", "info_transaksi", "data_pengirim"),
                            },
                            {
                                //Belum ada field di API
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-0">
                                                <label>Cabang Rekening</label>
                                                {/* <span className="badge badge-secondary">
                                                    Wajib
                                                </span> */}
                                                <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        type="text"
                                                        // required
                                                        className="form-control form-control-sm col-3"
                                                        // placeholder="IDR"
                                                        readOnly
                                                        // onChange={(e) => {
                                                        //     ttAction.changeForm(e.target.value, "nomorCIF");
                                                        // }}
                                                        // value={ttState.formData?._rekeningDeposito?.kode}
                                                        value = {ttState.formData?.info_transaksi?.info_rekening?.kode_cabang}
                                                        disabled={!ttState.formEditable}
                                                    />
                                                    <div className="input-group-append col pl-0 pr-0">
                                                        <input
                                                            type="text"
                                                            // required
                                                            className="form-control form-control-sm col"
                                                            // placeholder="KCP BAndung"
                                                            readOnly
                                                            // onChange={(e) => {
                                                            //     ttAction.changeForm(e.target.value, "nomorCIF");
                                                            // }}
                                                            value={ttState.formData?.info_transaksi?.info_rekening?.nama_cabang}
                                                            disabled={!ttState.formEditable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },{
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm pl-0">
                                                <div className="input-group input-group-sm mb-3">
                                                    <button onClick={ttAction.openModalSVS} className="btn btn-outline-danger">
                                                        Lihat Data Grafis / Tanda Tangan
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            
                    ]},
                    // End Data Pengirim
                     //
                     {
                        name: "",
                        rows: [
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    label: 'Tanggal Efektif',
                                                    type: 'date',
                                                    // placeholder: 'Masukan Nominal',
                                                    value: ttState.dateNow?.date,
                                                    // onChange: (value) => ttAction.changeForm(value, "homePhone", "_dataNasabah"),
                                                    required: false,
                                                    disabled: true
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group form-group-sm pl-0">
                                                <label>Valuta Transaksi</label>
                                                {/* <span className="badge badge-secondary">
                                                    Wajib
                                                </span> */}
                                                <div className="input-group input-group-sm mb-3">
                                                    <select className="form-control form-control-sm col-3"
                                                        placeholder="IDR"
                                                        onChange={(e) => {
                                                            ttAction.changeForm(e.target.value, "kode_valuta");
                                                        }}
                                                        value={ttState.formData?.kode_valuta}
                                                        disabled={!ttState.formEditable || true}
                                                    >

                                                        <option value="">- Pilih Valuta -</option>
                                                        <option value="IDR">IDR</option>
                                                    </select>
                                                    <div className="input-group-append col pl-0 pr-0">
                                                        <input
                                                            type="text"
                                                            // required
                                                            className="form-control form-control-sm col"
                                                            placeholder="INDONESIAN RUPIAH"
                                                            value="INDONESIAN RUPIAH"
                                                            readOnly
                                                            // onChange={(e) => {
                                                            //     ttAction.changeForm(e.target.value, "nomorCIF");
                                                            // }}
                                                            // value={ttState.formData?.nomorCIF}
                                                            disabled={!ttState.formEditable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    label: 'Nominal',
                                                    type: 'currency',
                                                    placeholder: 'Masukan Nominal',
                                                    value: ttState.formData?.nominal,
                                                    onChange: (value) => ttAction.changeForm(value, "nominal"),
                                                    required: false,
                                                    disabled: !ttState.formEditable 
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    rows: 1,
                                                    label: 'Sumber Biaya',
                                                    type: 'select',
                                                    placeholder: 'Pilih Sumber Biaya',
                                                    options: ttState.dataDropDown?.eSumberBiaya,
                                                    key: "reference_code",
                                                    display: "reference_desc",
                                                    required: false,
                                                    disabled : true,
                                                    value: ttState.formData?.info_transaksi?.sumber_biaya,
                                                    onChange: (value) => ttAction.changeForm(value, "sumber_biaya", "info_transaksi"),
                                                })
                                            }
                                            {/* {
                                                createForm({
                                                    label: 'Nominal Ekuivalen',
                                                    type: 'currency',
                                                    placeholder: 'Masukan Nilai Bulan',
                                                    value: ttState.formData?.info_transaksi?.nominal_ekuivalen,
                                                    onChange: (value) => ttAction.changeForm(value, "nominal_ekuivalen", "info_transaksi"),
                                                    disabled: !ttState.formEditable ,
                                                    required: false,
                                                })
                                            } */}
                                        </div>
                                    </div>
                                )
                            },
                            // {
                            //     rows: 1,
                            //     label: 'Sumber Biaya',
                            //     type: 'select',
                            //     placeholder: 'Pilih Sumber Biaya',
                            //     options: ttState.dataDropDown?.eSumberBiaya,
                            //     key: "reference_code",
                            //     display: "reference_desc",
                            //     required: false,
                            //     disabled : true,
                            //     // disabled: !ttState.formEditable,
                            //     value: ttState.formData?.info_transaksi?.sumber_biaya,
                            //     onChange: (value) => ttAction.changeForm(value, "sumber_biaya", "info_transaksi"),
                            // },
                            
                            {
                                rows: 1,
                                label: 'Cek / Bilyet Giro',
                                type: 'text',
                                placeholder: 'Masukkan Cek / Bilyet Giro',
                                required: false,
                                disabled: !ttState.formEditable || (
                                    ttState.formData?.nomor_rekening === "" ||
                                    ttState.formData?.nomor_rekening === undefined || 
                                    ttState.formData?.nomor_rekening === null 
                                ),
                                value: ttState.formData?.info_transaksi?.nomor_seri_warkat,
                                onChange: (value) => ttAction.changeForm(value, "nomor_seri_warkat", "info_transaksi"),
                                onEnter: (value) => {
                                    if(value !== null && value !== "" && value !== undefined){
                                        ttAction.cekBilyet(value)
                                    }
                                },
                                onFocusOut: (value) => {
                                    if(value !== null && value !== "" && value !== undefined){
                                        ttAction.cekBilyet(value)
                                    }
                                }
                            },
                            // {
                            //     rows: 2,
                            //     type: 'custom',
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-4">
                            //                 {
                            //                     createForm({
                            //                         label: 'Kurs Jual',
                            //                         type: 'number',
                            //                         placeholder: 'Masukan Kurs Jual',
                            //                         value: ttState.formData?.info_transaksi?.kurs_jual,
                            //                         onChange: (value) => ttAction.changeForm(value, "kurs_jual","info_transaksi"),
                            //                         required: false,
                            //                         disabled: true
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col-4">
                            //                 {
                            //                     createForm({
                            //                         label: 'Kurs Beli',
                            //                         type: 'number',
                            //                         placeholder: 'Masukan Kurs Beli',
                            //                         value: ttState.formData?.info_transaksi?.kurs_beli,
                            //                         onChange: (value) => ttAction.changeForm(value, "kurs_beli","info_transaksi"),
                            //                         disabled: true,
                            //                         required: false,
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col-4">
                            //                 {
                            //                     createForm({
                            //                         label: 'Kurs Buku',
                            //                         type: 'number',
                            //                         placeholder: 'Masukan Kurs Buku',
                            //                         value: ttState.formData?.info_transaksi?.kurs_buku,
                            //                         onChange: (value) => ttAction.changeForm(value, "kurs_buku","info_transaksi"),
                            //                         disabled: true,
                            //                         required: false,
                            //                     })
                            //                 }
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            // {
                            //     rows: 2,
                            //     type: 'custom',
                            //     component: (
                            //         <div className="row">
                            //             <div className="col-5">
                            //                 {
                            //                     createForm({
                            //                         label: 'Biaya',
                            //                         type: 'currency',
                            //                         placeholder: 'Masukan Biaya',
                            //                         value: ttState.formData?.info_transaksi?.biaya,
                            //                         onChange: (value) =>{ 
                            //                             ttAction.changeForm(value, "biaya", "info_transaksi")
                            //                             ttAction.changeForm(value, "biaya_ekuivalen", "info_transaksi")
                            //                         },
                            //                         required: false,
                            //                         disabled: true
                            //                     })
                            //                 }
                            //             </div>
                            //             <div className="col-7">
                            //                 {
                            //                     createForm({
                            //                         label: 'Biaya Bulanan',
                            //                         type: 'currency',
                            //                         placeholder: 'Masukan Biaya Bulanan',
                            //                         value:parseFloat(
                            //                             ttState.formData?.info_transaksi?.biaya_ekuivalen
                            //                         ).toFixed(2), 
                            //                         onChange: (value) => ttAction.changeForm(value, "biaya_ekuivalen", "info_transaksi"),
                            //                         disabled: true,
                            //                         required: false,
                            //                     })
                            //                 }
                            //             </div>
                            //         </div>
                            //     )
                            // },
                            {
                                rows: 2,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col-5">
                                            {
                                                createForm({
                                                    label: 'No Referensi',
                                                    type: 'text',
                                                    placeholder: 'Masukan No Referensi',
                                                    value: ttState.formData?.nomor_referensi,
                                                    onChange: (value) => ttAction.changeForm(value, "nomor_referensi"),
                                                    required: false,
                                                    disabled: !ttState.formEditable || true
                                                })
                                            }
                                        </div>
                                        <div className="col-7">
                                            {
                                                createForm({
                                                    label: 'Keterangan',
                                                    maxLength: 100,
                                                    type: 'textarea',
                                                    placeholder: 'Masukan Keterangan',
                                                    value: ttState.formData?.keterangan,
                                                    onChange: (value) => ttAction.changeForm(value, "keterangan"),
                                                    disabled: !ttState.formEditable,
                                                    required: false,
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            
                    ]},
                    // 
                ]}
            />
            {/* <div className="bns-form">
                <Accordion defaultActiveKey="0">
                    <div className="row mt-4 mb-2">
                        <Accordion.Toggle as={Link} variant="link" eventKey="0">
                            <div className="col-auto text-primary">
                                <i className="fa fa-angle-double-down"></i> Data
                                Pengirim
                            </div>
                        </Accordion.Toggle>
                        <div className="col">
                            <hr />
                        </div>
                    </div>
                    <Accordion.Collapse eventKey="0">
                        SEKSESS
                    </Accordion.Collapse>
                </Accordion>
            </div> */}
        </>
    );
}

export default TarikTunai;