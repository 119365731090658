import React, { memo} from 'react';
import { AppInterfaces } from '../../../app.data';

const BasePageKiriman_base = memo(({store, ...props }) => {
    return(<>
        {props.options && props.options.length >= 1 && props.options.map((option, id) => (<>
            <h5>{option.title && option.title}</h5>
            <div className="row align-items-center pb-2">
                {option.forms.map((form, id) => (<>
                    <div className={`${props.id === "wesel" ? "col-3" : "col-5"} pr-0`}>
                        <span className="f-14">{form.label}</span>
                    </div>
                    <div key={id} className={`${props.id === "wesel" ? "col-9" : "col-7"} py-1`}>
                        {form.type === "text" ?
                            (<input className={`form-control form-control-sm ${form.style}`} disabled={form.disable} placeholder={form.placeholder} 
                                name={`${option.type}.${form.name}`}
                                ref={props.register({ required: !form.disable })}
                                value={form.value}
                                onFocus={(e)=>e.target.select()}/>)
                        :(<select className="form-control form-control-sm" value={form.value} 
                            name={`${option.type}.${form.name}`}
                            ref={props.register({ required: !form.disable })}>
                            <option value="">Select {form.label}</option>
                            {
                            form.options?.map((opt, i)=> <option key={i} value={opt.id}> {opt.name} </option>)
                            }
                        </select>)
                        }                    
                    </div>
                </>))}
            </div>
        </>))}
    </>)
})
const BasePageKiriman = AppInterfaces.appLoad(BasePageKiriman_base)

export default BasePageKiriman;