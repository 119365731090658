import React, { memo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Button } from 'semantic-ui-react';
import { AppInterfaces } from '../../../app.data';
import { createForm } from '../../../module/createFormList';

export const PerubahanNisbah = AppInterfaces.appLoad(
    memo(function ({ show, onHide }) {
        const [confirm, setConfirm] = useState(false);
        const handleCetakPerubahanNisbah = useMutation();

        return (
            <>
                <Modal centered show={handleCetakPerubahanNisbah.isSuccess}>
                    <Modal.Header>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Proses Cetak Perubahan Nisbah Depostio berhasil dilakukan
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => handleCetakPerubahanNisbah.reset()}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Selesai
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={confirm}>
                    <Modal.Header onHide={() => setConfirm(false)} closeButton>
                        <Modal.Title>
                            <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="tw-text-f16 tw-py-8 tw-text-center">
                            Lakukan proses Cetak Perubahan Nisbah Depostio?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setConfirm(false)}
                            className="text-white mr-2"
                            size="mini"
                        >
                            Tidak
                        </Button>
                        <Button
                            loading={handleCetakPerubahanNisbah.isLoading}
                            onClick={() => handleCetakPerubahanNisbah.mutate()}
                            className="tw-w-16"
                            size="mini"
                            color="blue"
                        >
                            Ya
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={show}>
                    <Modal.Header closeButton onHide={onHide}>
                        <Modal.Title>
                            <div className="tw-text-18 tw-font-bold">
                                Cetak Perubahan Nisbah Depostio
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                {createForm({
                                    type: 'text',
                                    label: 'Nomor Rekening Deposto',
                                    placeholder: 'Nomor Rekening Deposto',
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                {createForm({
                                    type: 'date',
                                    label: 'Tanggal Ubah',
                                    placeholder: 'Nomor Rekening Deposto',
                                })}
                            </div>
                            <span
                                style={{
                                    position: 'absolute',
                                    left: '48%',
                                    top: '115px',
                                }}
                            >
                                s/d
                            </span>
                            <div className="col-md-6">
                                {createForm({
                                    type: 'date',
                                    label: <>&nbsp;</>,
                                    placeholder: 'Nomor Rekening Deposto',
                                })}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide} className="tw-font-normal tw-py-2 tw-mr-2">
                            Batal
                        </Button>
                        <Button
                            onClick={() => {
                                setConfirm(true);
                                if (onHide) onHide();
                            }}
                            className="tw-font-normal tw-py-2"
                            color="blue"
                        >
                            Cetak
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);
