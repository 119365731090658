import { ReactKeycloakProvider as KeycloakProvider } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import './App.css';
import './print.css';
import 'semantic-ui-offline/semantic.min.css';
import { AppInterfaces } from './app.data';
import { BasePageProvider, AlertWarning, LoadingPage, NewLoading, ModalAlert } from './components/controls';
import { AppProvider } from './app.data';
import keycloak from './keycloak.config';
import Layouts from './components/layouts';
import { UnAuthRouter, AuthRouter } from './keycloak';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { ModalAlertProvider } from './components/controls/modalAlert/';
import { PrintModalProvider } from './components/controls/modalPrint';
import { RespOtorProvider } from './hooks/useRespOtor';
import { useLocalStorage } from './module';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { baseUrlAuth } from './app.config';
import useKeycloak, { KeycloakProv } from './hooks/keycloak.module';
const MainPage = () => {
    const { keycloak, initialized } = useKeycloak();

    const [lastActivity, setLastActivity] = useLocalStorage("lastActivity")
    const history = useHistory()
    const location = useLocation()
    useEffect(function(){
        if(keycloak.authenticated){
            keycloak.updateUID();
        }
    },[location])
    useEffect(() => {
       
        if (keycloak && initialized) {
            // history.push("/")
            
            // keycloak.onTokenExpired = () => keycloak.refreshToken(300);
            // keycloak.loadUserInfo().then(userInfo => {
            //     // history.push("/")
            // });
            
            // keycloak.onAuthSuccess = () => {
            //     setLastActivity((Date.now()))
            //     // history.push("/")
            // }
            // if(!(moment(lastActivity).add(5, "minutes") < moment(Date.now()))){
            //     setLastActivity((Date.now()))
            // }
            // if(lastActivity === undefined || lastActivity === null) setLastActivity((Date.now()))
        }
        return () => {
            // removeEventListener('*')
            // if (keycloak) keycloak.onTokenExpired = () => keycloak.refreshToken(300);
        };
    }, [initialized]);
    if (!initialized)
        return (
            <div style={{
                margin: "auto",
                width: "fit-content",
                paddingTop: "20%",
                textAlign: "center"
            }}>
                <NewLoading width="100px" />
                <h5 className="text-base">Initializing User</h5>
            </div>
        )
    return (
        // <BrowserRouter>
        <RespOtorProvider>
            <Suspense fallback={<div style={{
                margin: "auto",
                width: "fit-content",
                paddingTop: "20%",
                textAlign: "center"
            }}>
                <NewLoading width="100px" />
                <h5 className="text-base">Please wait..</h5>
            </div>}>
                <Layouts>
                    <div className="App">
                        {
                             <AuthRouter />
                            // keycloak.authenticated ? <AuthRouter /> :
                            // <UnAuthRouter />
                        }
                    </div>
                </Layouts>
            </Suspense>
            <ToastContainer />
        </RespOtorProvider>
        // </BrowserRouter>
    )
};

const Main = AppInterfaces.appLoad(MainPage);
const queryClient = new QueryClient();

function App() {
    // const { keycloak, initialized } = useKeycloak();
    // const handleOnEvent = async (event,error) => {
    //     if(event === 'onTokenExpired'){
    //         alert("maaf sesi habis")
    //         keycloak.logout(); 
    //         // keycloak.updateToken(300).then(
    //         //     (response) => {
    //         //        //I want to update my existing Token
    //         //      alert("response: ", response )
    //         //     })
    //         //     .catch(error => {
    //         //         console.log("error: ", error)
    //         //     })
    //     }
    // }

    return (

        <BrowserRouter>
            {/* <KeycloakProvider
                authClient={keycloak}
                autoRefreshToken={true}
                LoadingComponent={
                    <div style={{
                        margin: "auto",
                        width: "fit-content",
                        paddingTop: "20%",
                        textAlign: "center"
                    }}>
                        <NewLoading width="100px" />
                        <h5 className="text-base">Initializing User</h5>
                    </div>
                }
                initOptions={{ 
                    onLoad:"check-sso", 
                    pkceMethod: 'S256' ,
                }}
             onEvent={handleOnEvent}
            > */}
                <KeycloakProv>

                    <ModalAlertProvider>
                        <PrintModalProvider>
                            <QueryClientProvider client={queryClient}>
                                <AppProvider>
                                    {/* <RespOtorProvider> */}
                                    <BasePageProvider>
                                        {/* <Prov2> */}
                                        <AlertWarning />
                                        <ModalAlert />
                                        <Main />
                                        {/* </Prov2> */}
                                    </BasePageProvider>
                                    {/* </RespOtorProvider> */}
                                </AppProvider>
                            </QueryClientProvider>
                        </PrintModalProvider>
                    </ModalAlertProvider>
                </KeycloakProv>
            {/* </KeycloakProvider> */}
        </BrowserRouter>
    );
}

export default App;
