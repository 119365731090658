import React, { memo } from 'react';
import { createForm } from '../../../../module/createFormList';

export const FormRegistrasi = memo(function({
    itemsForm
}) {
    return(
        <>
            {
                itemsForm?.map((item, i) => {
                    return (
                        <div key={i} style={{
                            
                            marginBottom:"20px"
                        }}>
                            <div className="row" style={{
                                borderTop: "1pt solid #d2d4d7",
                                margin: "0px 10px",
                            }}>
                                <div className="col">
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "-15px",
                                            background: "white",
                                            padding: "0px 10px",
                                            left: "0px",
                                            color: "#E6530D",
                                            fontWeight: "bold",
                                        }}
                                    >{item.name}</span>
                                </div>
                            </div>
                            <div className="row mt-3" style={{
                                marginLeft: "5px"
                            }}>
                                <div className="col-md-6 pr-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                item
                                                    .rows?.filter(v => v.rows === 1)
                                                    .map((form, j) => createForm(form))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pr-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                item
                                                    .rows?.filter(v => v.rows === 2)
                                                    .map((form, j) => createForm(form))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
    
})