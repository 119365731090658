import { Button, Label } from 'semantic-ui-react';
import { Modal, Form } from 'react-bootstrap';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
// import { useKeycloak } from '@react-keycloak/web';
import { GetErrorEmptyValue, joinDefinedString } from '../../../utils';
import { baseUrl } from '../../../app.config';
import useKeycloak from '../../../hooks/keycloak.module';

const ModalInfoNegatifNasabah = ({
    show,
    onHide,
    form,
    onSuccess,
    type = 'registrasi',
    mode = 'default',
    isApproved = false,
}) => {
    const [error, setError] = useState({});
    const [confirm, setConfirm] = useState(false);

    const { keycloak } = useKeycloak();

    const alamat = useMemo(() => {
        return joinDefinedString(
            form?._identitasNasabah?.nasabahAlamat,
            form?._identitasNasabah?.nasabahKotaKabupaten,
            form?._identitasNasabah?.nasabahProvinsi
        ).separateWith(', ');
    }, [form]);

    const handleHide = () => {
        if (onHide) onHide();
        setError({});
    };

    const handleCreate = useMutation(
        (e) => {
            e.preventDefault();

            const {
                nama_lengkap,
                jenis_nasabah,
                alamat,
                keterangan,
                status_data,
                user_input,
                tgl_input,
            } = e.target.elements;

            const body = {
                nomor_cif: form?._negatifNasabah?.id || null,
                nama_lengkap: nama_lengkap.value,
                jenis_nasabah: jenis_nasabah.value,
                alamat: alamat.value,
                keterangan: keterangan.value,
                status_data: status_data.value,
                user_input: user_input.value,
                tgl_input: tgl_input.value,
                id_nasabah: form?.db_id || null,
            };

            const { error, isError } = new GetErrorEmptyValue({
                keterangan: keterangan.value,
            });

            setError(error);

            if (isError) throw new Error('field keterangan harus diisi');

            return fetch(baseUrl + '/nasabah/informasi/negatif/create', {
                method: 'POST',
                body: JSON.stringify(body),
            })
                .then((res) => res.json())
                .catch((e) =>  console.error(e));
        },
        {
            onSuccess: () => {
                if (onSuccess) onSuccess();
                handleHide();
            },
        }
    );

    const handlePeragaan = useMutation(
        (e) => {
            e.preventDefault();

            const {
                nama_lengkap,
                jenis_nasabah,
                alamat,
                keterangan,
                status_data,
                user_input,
                // tgl_input,
                user_approve,
                // tgl_approve,
                user_delete,
                // tgl_delete,
            } = e.target.elements;

            const body = {
                id_nasabah: form?.db_id || null,
                nomor_cif: form?._negatifNasabah?.id || null,
                nama_lengkap: nama_lengkap.value,
                jenis_nasabah: jenis_nasabah.value,
                alamat: alamat.value,
                keterangan: keterangan.value,
                status_data: status_data.value,
                user_input: user_input.value,
                tgl_input: null,
                user_approve: user_approve.value,
                tgl_approve: null,
                user_delete: user_delete.value,
                tgl_delete: null,
            };

            const { error, isError } = new GetErrorEmptyValue({
                keterangan: keterangan.value,
            });

            setError(error);

            if (isError) throw new Error('field keterangan harus diisi');

            if (isApproved) {
                return fetch(
                    baseUrl + `/nasabah/informasi/negatif/approved/${form?._negatifNasabah?.id}`,
                    {
                        method: 'PUT',
                        body: JSON.stringify(body),
                    }
                ).then((res) => res.json());
            }

            return fetch(
                baseUrl + `/nasabah/informasi/negatif/peragaan/${form?._negatifNasabah?.id}`,
                {
                    method: 'PUT',
                    body: JSON.stringify(body),
                }
            ).then((res) => res.json());
        },
        {
            onSuccess: (data) => {
                if (data.message_id === '02') {
                    handleHide();
                    toast.error(
                        typeof data.status === 'string' ? data.status : JSON.stringify(data.status),
                        {
                            position: 'top-center',
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                        }
                    );
                    return;
                }

                if (onSuccess) onSuccess();
                handleHide();
            },
        }
    );

    const handleDelete = useMutation(
        () => {
            return fetch(
                baseUrl + `/nasabah/informasi/negatif/hapus/${form?._negatifNasabah?.id}`,
                {
                    method: 'PUT',
                    body: JSON.stringify({ ...form?._negatifNasabah, status_data: 'NOTACTIVE' }),
                }
            );
        },
        {
            onSuccess: () => {
                setConfirm(false);
                if (onSuccess) onSuccess();
            },
        }
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (type === 'registrasi') await handleCreate.mutateAsync(e);
            else if (type === 'peragaan') await handlePeragaan.mutateAsync(e);
            else {
                const { keterangan } = e.target.elements;

                const { error, isError } = new GetErrorEmptyValue({
                    keterangan: keterangan.value,
                });

                setError(error);

                if (isError) throw new Error('field keterangan harus diisi');

                setConfirm(true);
                handleHide();
            }
        } catch (e) {
            // console.error(e);
        }
    };

    return (
        <>
            <Modal centered show={handleDelete.isSuccess}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Proses Hapus Info Negatif Nasabah berhasil dilakukan
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => handleDelete.reset()}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Selesai
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={confirm}>
                <Modal.Header onHide={() => setConfirm(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Lakukan proses Hapus Info Negatif Nasabah?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setConfirm(false)}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Tidak
                    </Button>
                    <Button
                        loading={handleDelete.isLoading}
                        onClick={() => handleDelete.mutate()}
                        className="tw-w-16"
                        size="mini"
                        color="blue"
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={show}>
                <Modal.Header className="tw-px-6 tw-py-5" onHide={handleHide} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">
                            {type === 'registrasi'
                                ? 'Registrasi'
                                : type === 'peragaan'
                                ? ''
                                : 'Hapus'}{' '}
                            Info Negatif Nasabah
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <Form.Group className="mb-3" bsPrefix=" ">
                        <Form.Label className="font-weight-normal f-12">Nomor Nasabah</Form.Label>
                        <Form.Control
                            id="keterangan"
                            disabled
                            size="sm"
                            defaultValue={form?._negatifNasabah?.id || '-'}
                        />
                    </Form.Group>
                    <Form id="registerForm" onSubmit={handleSubmit}>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-my-4">
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Nama Lengkap
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    id="nama_lengkap"
                                    size="sm"
                                    placeholder="Masukkan nama lengkap"
                                    value={form?._negatifNasabah?.nama_lengkap ?? form?.groupName}
                                />
                            </Form.Group>
                            <Form.Group bsPrefix=" ">
                                <Form.Label className="font-weight-normal f-12">
                                    Jenis Nasabah
                                </Form.Label>
                                <Form.Control
                                    id="jenis_nasabah"
                                    as="select"
                                    size="sm"
                                    placeholder="Pilih jenis nasabah"
                                    disabled
                                    value="GROUP"
                                >
                                    <option value="" selected hidden disabled>
                                        Pilih Jenis Nasabah
                                    </option>
                                    <option value="CLIENT">Individu</option>
                                    <option value="GROUP">Badan Usaha</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Alamat
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                disabled
                                id="alamat"
                                rows={3}
                                as="textarea"
                                size="sm"
                                placeholder="Masukkan alamat"
                                value={form?._negatifNasabah?.alamat ?? alamat}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">
                                Keterangan
                                <Label className="ml-2" size="mini">
                                    Wajib
                                </Label>
                            </Form.Label>
                            <Form.Control
                                id="keterangan"
                                size="sm"
                                placeholder="Masukkan keterangan"
                                isInvalid={error.keterangan}
                                disabled={type === 'hapus' || mode === 'view'}
                                defaultValue={form?._negatifNasabah?.keterangan ?? ''}
                                onChange={() => {
                                    if (error.keterangan)
                                        setError({
                                            ...error,
                                            keterangan: false,
                                        });
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Keterangan wajib diisi!
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" bsPrefix=" ">
                            <Form.Label className="font-weight-normal f-12">Status Data</Form.Label>
                            <Form.Control
                                id="status_data"
                                as="select"
                                size="sm"
                                placeholder="Aktif"
                                disabled={type === 'hapus' || mode === 'view'}
                                defaultValue={form?._negatifNasabah?.status_data}
                                value={type === 'hapus' ? 'NOTACTIVE' : undefined}
                            >
                                <option value="" hidden disabled selected>
                                    Pilih Status Data
                                </option>
                                <option value="ACTIVE">Aktif</option>
                                <option value="NOTACTIVE">Non Aktif</option>
                            </Form.Control>
                        </Form.Group>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                            <Form.Group bsPrefix=" " hidden={mode === 'view'}>
                                <Form.Label className="font-weight-normal f-12">
                                    User Input
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    id="user_input"
                                    size="sm"
                                    placeholder="BCADEV"
                                    defaultValue={keycloak.idTokenParsed.preferred_username}
                                />
                            </Form.Group>
                            <Form.Group bsPrefix=" " hidden={mode === 'view'}>
                                <Form.Label className="font-weight-normal f-12">
                                    Tanggal Input
                                </Form.Label>
                                <Form.Control
                                    disabled={type !== 'registrasi'}
                                    defaultValue={
                                        form?._negatifNasabah?.tgl_input
                                            ? dayjs(form?._negatifNasabah?.tgl_input).format(
                                                  'YYYY-MM-DD'
                                              )
                                            : undefined
                                    }
                                    id="tgl_input"
                                    size="sm"
                                    type="date"
                                />
                            </Form.Group>
                        </div>
                        {(type === 'registrasi' || type === 'peragaan' || type === 'hapus') && (
                            <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                                <Form.Group bsPrefix=" " hidden={mode === 'view'}>
                                    <Form.Label className="font-weight-normal f-12">
                                        User Approve
                                    </Form.Label>
                                    <Form.Control
                                        defaultValue={form?._negatifNasabah?.user_approve}
                                        id="user_approve"
                                        disabled
                                        size="sm"
                                        placeholder="BCADEV"
                                    />
                                </Form.Group>
                                <Form.Group bsPrefix=" ">
                                    <Form.Label className="font-weight-normal f-12">
                                        Tanggal Approve
                                    </Form.Label>
                                    <Form.Control
                                        defaultValue={
                                            form?._negatifNasabah?.tgl_approve
                                                ? dayjs(form?._negatifNasabah?.tgl_approve).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : undefined
                                        }
                                        id="tgl_approve"
                                        disabled
                                        size="sm"
                                        type="date"
                                    />
                                </Form.Group>
                            </div>
                        )}
                        {(type === 'peragaan' || type === 'hapus') && (
                            <div className="tw-grid tw-grid-cols-2 tw-gap-x-4 mb-4">
                                <Form.Group bsPrefix=" " hidden={mode === 'view'}>
                                    <Form.Label className="font-weight-normal f-12">
                                        User Delete
                                    </Form.Label>
                                    <Form.Control
                                        id="user_delete"
                                        disabled
                                        size="sm"
                                        placeholder="BCADEV"
                                        defaultValue={form?._negatifNasabah?.user_delete}
                                    />
                                </Form.Group>
                                <Form.Group bsPrefix=" " hidden={mode === 'view'}>
                                    <Form.Label className="font-weight-normal f-12">
                                        Tanggal Delete
                                    </Form.Label>
                                    <Form.Control
                                        id="tgl_delete"
                                        defaultValue={
                                            form?._negatifNasabah?.tgl_delete
                                                ? dayjs(form?._negatifNasabah?.tgl_delete).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : undefined
                                        }
                                        disabled
                                        size="sm"
                                        type="date"
                                    />
                                </Form.Group>
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button onClick={handleHide} className="text-white mr-2" size="mini">
                        {mode === 'view' ? 'Tutup' : 'Batal'}
                    </Button>
                    {mode !== 'view' && (
                        <Button
                            loading={handleCreate.isLoading || handlePeragaan.isLoading}
                            type="submit"
                            form="registerForm"
                            color="blue"
                            size="mini"
                        >
                            Simpan
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalInfoNegatifNasabah;
