import React, { useState } from 'react';
import { createForm } from '../../../../../../module/createFormList';
import { FormRegistration } from '../../../../../controls';
import usePeroranganWic from './useFormPerorangan_wic';

const DataPekerjaan = () => {
    const { fpwState, fpwAction } = usePeroranganWic()
    return (
        <>
            <FormRegistration
            
                isLoading={fpwState.isLoading}
                itemsForm={[
                     // Data Pekerjaan
                     {
                        name: "Data Pekerjaan",
                        rows: [
                            {
                                rows: 1,
                                label: 'Pekerjaan ',
                                type: 'select', // select, date, number, text, checkbox, custom
                                placeholder: 'Pilih Pekerjaan',
                                required: false,
                                options: fpwState.dataDropDown?.R_PEKERJAAN,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpwState.formData?._occupationInfo?.personalOccupation,
                                onChange: (value) => fpwAction.changeForm(value,"personalOccupation","_occupationInfo"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                label: "Jabatan Pekerjaan",
                                type: "text",
                                placeholder: "Masukkan Jabatan",
                                required: false,
                                value: fpwState.formData?._occupationInfo?.personalJobPosition,
                                onChange: (value) => fpwAction.changeForm(value,"personalJobPosition","_occupationInfo"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                label: "Nama Perusahaan",
                                type: "text",
                                placeholder: "Masukkan Nama Perusahaan",
                                required: false,
                                value: fpwState.formData?._occupationInfo?.companyName,
                                onChange: (value) => fpwAction.changeForm(value,"companyName","_occupationInfo"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Badan Hukum',
                                type: 'select',
                                placeholder: 'Pilih Badan hukum',
                                options: fpwState.dataDropDown?.eBadanHukum,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpwState.formData?._occupationInfo?.legalEntity,
                                onChange: (value) => fpwAction.changeForm(value,"legalEntity","_occupationInfo"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Badan Usaha',
                                type: 'select',
                                placeholder: 'Pilih Badan Usaha',
                                options: fpwState.dataDropDown?.R_BIDANG_USAHA,
                                key: "reference_code",
                                display: "reference_desc",
                                value: fpwState.formData?._occupationInfo?.personalTypeOfWork,
                                onChange: (value) => fpwAction.changeForm(value, "personalTypeOfWork","_occupationInfo"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                label: "No Telepon",
                                type: "number",
                                placeholder: "000",
                                value: fpwState.formData?._companyAddress?.companyAddressPhoneNumber,
                                onChange: (value) => fpwAction.changeForm(value, "companyAddressPhoneNumber","_companyAddress"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 1,
                                label: "Fax",
                                type: "number",
                                placeholder: "000",
                                value: fpwState.formData?._companyAddress?.companyAddressFaxNumber,
                                onChange: (value) => fpwAction.changeForm(value, "companyAddressFaxNumber","_companyAddress"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Alamat',
                                type: 'textarea',
                                rowsText: 4,
                                placeholder: 'Masukkan Alamat',
                                required: false,
                                value: fpwState.formData?._companyAddress?.companyAddress,
                                onChange: (value) => fpwAction.changeForm(value, "companyAddress","_companyAddress"),
                                disabled: !fpwState.formEditable
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Provinsi",
                                                    type: "select",
                                                    required: false,
                                                    placeholder: "Masukkan Provinsi",
                                                    options: fpwState.provinces,
                                                    key: "province_code",
                                                    display: "province_name",
                                                    value: fpwState.formData?._companyAddress?.companyAddressProvince || "",
                                                    onChange: (value) => {
                                                        const data = fpwState.provinces?.find(v => v.province_name === value);
                                                        fpwAction.getingCities(value, fpwAction.changeForm("", "companyAddressCity","_companyAddress"))
                                                        fpwAction.changeForm(value, "companyAddressProvince","_companyAddress")
                                                    },
                                                    disabled: !fpwState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kota/Kab",
                                                    type: "select",
                                                    options: fpwState.cities,
                                                    required: false,
                                                    key: "city_code",
                                                    display: "city_name",
                                                    placeholder: "Masukkan Kab/Kota",
                                                    value: fpwState.formData?._companyAddress?.companyAddressCity || "",
                                                    onChange: (value) => {
                                                        // fpwAction.getingDistrict(value)
                                                        fpwAction.changeForm(value, "companyAddressCity","_companyAddress")
                                                    },
                                                    disabled: !fpwState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kecamatan",
                                                    type: "text",
                                                    required: false,
                                                    placeholder: "Masukkan Kecamatan",
                                                    //options: fpwState.districts,
                                                    value: fpwState.formData?._companyAddress?.companyAddressSubDistrict || "",
                                                    //onChange: (value) => {
                                                    //    fpwAction.getingVillages(
                                                    //        fpwState.formData?._companyAddress?.companyAddressCity, // ambil dari data Kota/Kab sebelumnya
                                                    //        value 
                                                    //    )
                                                    //    fpwAction.changeForm(value, "companyAddressSubDistrict","_companyAddress")
                                                    //},
                                                    onChange: (value) => fpwAction.changeForm(value, "companyAddressSubDistrict","_companyAddress"),
                                                    disabled: !fpwState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kelurahan",
                                                    type: "text",
                                                    //options: fpwState.villages,
                                                    required: false,
                                                    placeholder: "Masukkan Kelurahan",
                                                    value: fpwState.formData?._companyAddress?.companyAddressVillage || "",
                                                    //onChange: (value) => {
                                                    //    fpwAction.getingPostalCodes(
                                                    //        fpwState.formData?._companyAddress?.companyAddressCity, // ambil dari data Kota/Kab sebelumnya
                                                    //        fpwState.formData?._companyAddress?.companyAddressSubDistrict, // ambil dari data Kota/Kab sebelumnya.
                                                    //        value
                                                    //    )
                                                    //    fpwAction.changeForm(value, "companyAddressVillage","_companyAddress")
                                                    //},
                                                    onChange: (value) => fpwAction.changeForm(value, "companyAddressVillage","_companyAddress"),
                                                    disabled: !fpwState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RT",
                                                    maxLength: 3,
                                                    type: "text",
                                                    required: false,
                                                    placeholder: "Masukan No RT",
                                                    value: fpwState.formData?._companyAddress?.companyAddressRt,
                                                    onChange: (value) => fpwAction.changeForm(value, "companyAddressRt","_companyAddress"),
                                                    disabled: !fpwState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RW",
                                                    maxLength: 3,
                                                    type: "text",
                                                    required: false,
                                                    placeholder: "Masukan No RW",
                                                    value: fpwState.formData._companyAddress?.companyAddressRw,
                                                    onChange: (value) => fpwAction.changeForm(value, "companyAddressRw","_companyAddress"),
                                                    disabled: !fpwState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Kode Pos',
                                type: 'number',
                                maxLength:5,
                                required: false,
                                placeholder: 'Pilih Kode Pos',
                                //options: fpwState.postalCodes,
                                value: fpwState.formData?._companyAddress?.companyAddressPostalCode || "",
                                onChange: (value) => fpwAction.changeForm(value, "companyAddressPostalCode","_companyAddress"),
                                disabled: !fpwState.formEditable
                            },

                        ]
                    },
                    // End of Data Pekerjaan
                ]}
            />
        </>
    );
}

export default DataPekerjaan;