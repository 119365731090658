import React from 'react';
import './index.css'

export default function({
    color1="#004bbc", color2="#004bbc", color3="#004bbc", width="100%"
}){

    return(
        <>
            <svg 
                // width="300px"
                style={{
                    width
                }}
                id="exNVEmpdfQ11" 
                xmlns="http://www.w3.org/2000/svg" 
                xlink="http://www.w3.org/1999/xlink" 
                viewBox="30 30 120 120" 
                shape-rendering="geometricPrecision" 
                text-rendering="geometricPrecision"
            >
                {/* {`<style></style>`} */}
                <g id="exNVEmpdfQ12_tr" transform="translate(70.140034,70.713532) rotate(35.230599)">
                    <path 
                        id="exNVEmpdfQ12" 
                        d="M23.900353,11.953352L23.900353,9.116011C22.256208,8.60069,21.217538,8.455838,20.694718,7.343551L20.694718,7.342673C20.169906,6.226875,20.794303,5.469255,21.538201,4.088334L19.262688,2.082355C17.70817,2.73375,16.83979,3.289454,15.57108,2.825928L15.570084,2.825928C14.306353,2.364158,14.141042,1.442373,13.559467,0L10.340886,0C9.761302,1.43535,9.594996,2.36328,8.330269,2.825928L8.329273,2.825928C7.06355,3.289454,6.206125,2.739895,4.637664,2.082355L2.362152,4.088334C3.104058,5.464866,3.731443,6.225119,3.205635,7.342673C2.680823,8.458471,1.628212,8.605079,0,9.116011L0,11.953352C1.625224,12.462528,2.680823,12.610892,3.205635,13.725812C3.733434,14.851267,3.092108,15.627322,2.362152,16.980151L4.637664,18.987008C6.193179,18.334735,7.061558,17.779909,8.329273,18.243435L8.330269,18.243435C9.594996,18.705205,9.760307,19.629623,10.340886,21.069363L13.559467,21.069363C14.13905,19.633135,14.306353,18.707838,15.578051,18.240801L15.579047,18.240801C16.835807,17.780786,17.690244,18.329468,19.261693,18.987885L21.537205,16.981029C20.796294,15.608886,20.167914,14.845122,20.692726,13.727568C21.217538,12.611769,22.274133,12.463406,23.900353,11.953352ZM11.950176,14.046242C9.750348,14.046242,7.966784,12.473941,7.966784,10.534681C7.966784,8.595422,9.750348,7.023121,11.950176,7.023121C14.150005,7.023121,15.933568,8.595422,15.933568,10.534681C15.933568,12.473941,14.150005,14.046242,11.950176,14.046242Z" 
                        transform="scale(3.138029,3.559671) translate(-11.950175,-10.534681)" 
                        fill={color1} stroke="none" stroke-width="1" stroke-miterlimit="1"
                    />
                </g>
                <g id="exNVEmpdfQ13_tr" transform="translate(124.516737,103.203635) rotate(-574.986555)">
                    <path id="exNVEmpdfQ13" d="M19.376856,9.835665L19.376856,7.500994C18.04389,7.076969,17.201803,6.957779,16.777935,6.042548L16.777935,6.041826C16.352451,5.123705,16.858672,4.500307,17.461776,3.364034L15.616938,1.71344C14.356636,2.249432,13.652609,2.706686,12.624022,2.325279L12.623214,2.325279C11.598663,1.945318,11.46464,1.186838,10.993136,0L8.38372,0C7.91383,1.18106,7.779,1.944595,6.753642,2.325279L6.752834,2.325279C5.726668,2.706686,5.031524,2.254488,3.759917,1.71344L1.91508,3.364034C2.516569,4.496696,3.025212,5.122261,2.598921,6.041826C2.173437,6.959946,1.320049,7.080581,0,7.500994L0,9.835665C1.317626,10.254634,2.173437,10.376713,2.598921,11.294111C3.026826,12.220177,2.506881,12.858745,1.91508,13.971903L3.759917,15.62322C5.021028,15.086505,5.725053,14.629974,6.752834,15.01138L6.753642,15.01138C7.779,15.391342,7.913024,16.151987,8.38372,17.336659L10.993136,17.336659C11.463025,16.154877,11.598663,15.393508,12.629673,15.009213L12.630481,15.009213C13.64938,14.630695,14.342102,15.082171,15.616131,15.623941L17.460969,13.972625C16.860286,12.843575,16.350836,12.215121,16.77632,11.295556C17.201803,10.377435,18.058422,10.255356,19.376856,9.835665ZM9.688428,11.557773C7.90495,11.557773,6.458952,10.264025,6.458952,8.668329C6.458952,7.072634,7.90495,5.778886,9.688428,5.778886C11.471906,5.778886,12.917903,7.072634,12.917903,8.668329C12.917903,10.264025,11.471906,11.557773,9.688428,11.557773Z" 
                        transform="scale(2.580398,2.884062) translate(-9.299757,-8.668329)" 
                        fill={color2} 
                        stroke="none" 
                        stroke-width="1" 
                        stroke-miterlimit="1"
                    />
                </g>
                <g id="exNVEmpdfQ14_tr" transform="translate(124.891629,65.719688) rotate(64.18868)">
                    <path 
                        id="exNVEmpdfQ14" 
                        d="M9.688428,4.917833L9.688428,3.750497C9.021945,3.538485,8.600901,3.47889,8.388967,3.021274L8.388967,3.020913C8.176225,2.561853,8.429336,2.250154,8.730888,1.682017L7.808469,0.85672C7.178318,1.124716,6.826304,1.353343,6.312011,1.16264L6.311607,1.16264C5.799331,0.972659,5.73232,0.593419,5.496568,0L4.19186,0C3.956915,0.59053,3.8895,0.972298,3.376821,1.16264L3.376417,1.16264C2.863334,1.353343,2.515762,1.127244,1.879958,0.85672L0.95754,1.682017C1.258284,2.248348,1.512606,2.561131,1.29946,3.020913C1.086718,3.479973,0.660024,3.540291,0,3.750497L0,4.917833C0.658813,5.127317,1.086718,5.188357,1.29946,5.647056C1.513413,6.110089,1.25344,6.429373,0.95754,6.985952L1.879958,7.81161C2.510514,7.543253,2.862526,7.314987,3.376417,7.50569L3.376821,7.50569C3.8895,7.695671,3.956512,8.075994,4.19186,8.66833L5.496568,8.66833C5.731512,8.077439,5.799331,7.696754,6.314836,7.504607L6.31524,7.504607C6.82469,7.315348,7.171051,7.541086,7.808065,7.811971L8.730484,6.986313C8.430143,6.421788,8.175418,6.107561,8.38816,5.647778C8.600901,5.188718,9.029211,5.127678,9.688428,4.917833ZM4.844214,5.778887C3.952475,5.778887,3.229476,5.132013,3.229476,4.334165C3.229476,3.536317,3.952475,2.889443,4.844214,2.889443C5.735953,2.889443,6.458951,3.536317,6.458951,4.334165C6.458951,5.132013,5.735953,5.778887,4.844214,5.778887Z" 
                        transform="scale(2.580398,2.884062) translate(-4.844214,-4.334165)" 
                        fill={color3} 
                        stroke="none" 
                        stroke-width="1" 
                        stroke-miterlimit="1"
                    />
                </g>
            </svg>
        </>
    )
}