import React from 'react';
import { Modal } from 'react-bootstrap';
import { NewLoading } from '../../../../../controls';
import useRekening from '../useRekening';

const ModalAlertRekening = () => {
    const { rekState, rekAction } = useRekening();
    return (
        <>
            {/* mdoal loading */}
            <Modal show={rekState.loading.show}
                backdrop="static"
            >
                <Modal.Body>
                    <div style={{
                        margin: "auto",
                        width: "fit-content",
                        padding: "5% 0%",
                        textAlign: "center",

                    }}>
                        <NewLoading width="100px" />
                        <h5 className="text-base">{rekState.loading.msg}</h5>
                    </div>
                </Modal.Body>
            </Modal>
            {/*  */}

            {/* modal Alert */}
            <Modal show={rekState.dataAlert.show} //onHide={rekState.closeAlert} 
                backdrop="static"
            >
                {
                    rekState.dataAlert.header !== null && rekState.dataAlert.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {rekState.dataAlert.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <h5 style={{ textAlign: "center", margin: "5px", textTransform: "capitalize" }}>{rekState.dataAlert.title}</h5>
                            <div className="box">
                                <p>
                                    {rekState.dataAlert.body}
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            <button onClick={() => {
                                rekAction.closeAlert();
                                if (rekState.dataAlert.callBack !== null && rekState.dataAlert.callBack !== undefined) rekState.dataAlert.callBack();
                            }
                            } className="btn btn-primary btn-sm">
                                OK
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal alert */}


            {/* modal confirm */}
            <Modal show={rekState.dataConfirm.show}

                backdrop="static"
            >
                {
                    rekState.dataConfirm.header !== null && rekState.dataConfirm.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {rekState.dataConfirm.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {/* <h4 style={{ textAlign: "center" }}>{rekState.dataConfirm.title}</h4> */}

                            {/* <div className="box"> */}
                            <h5>
                                {rekState.dataConfirm.body}
                            </h5>
                            {
                                rekState.dataConfirm.witTextArea && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm">
                                                <label>Keterangan :</label>
                                                <textarea
                                                    className="form-control form-control-sm"
                                                    onChange={(e)=>rekAction.setOtorDesc(e.target.value)}
                                                    value={rekState.otorDesc}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {/* </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            {
                                rekState
                                    .dataConfirm
                                    .closeButton && <button onClick={rekState.closeAlert} className="btn btn-secondary btn-sm float-right mr-2">
                                    Close
                                </button>
                            }
                            {
                                rekState
                                    .dataConfirm
                                    .footerButton?.map(
                                        ({
                                            className = "",
                                            onClick = () => null,
                                            style = {},
                                            name = "Ok" },
                                            i) => <button
                                                key={i}
                                                className={`${className} float-right mr-2`}
                                                onClick={onClick}
                                                style={style}
                                            >
                                                {name}
                                            </button>
                                    )
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal confirm */}

        </>
    );
}

export default ModalAlertRekening;