import React from 'react';
import { Modal } from 'react-bootstrap';
import useModalAlert from './useModalAlert';

const ModalConfirmCustom = ({ children, id="show" }) => {
    const { modalAction, modalState } = useModalAlert()

    return (
        <>
            {/* modal confirm */}
            <Modal show={modalState.confirmCustom[id] || false}

                backdrop="static"
            >
                {
                    modalState.confirmCustom.header !== null && modalState.confirmCustom.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {modalState.confirmCustom.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {/* <h4 style={{ textAlign: "center" }}>{modalState.confirmCustom.title}</h4> */}

                            {/* <div className="box"> */}
                            <h5>
                                {modalState.confirmCustom.body}
                            </h5>
                            {/* </div> */}
                            {children}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            {
                                modalState
                                    .confirmCustom
                                    .closeButton && <button onClick={modalAction.closeAlert} className="btn btn-secondary btn-sm float-right mr-2">
                                    Close
                                </button>
                            }
                            {
                                modalState
                                    .confirmCustom
                                    .footerButton?.map(
                                        ({
                                            className = "",
                                            onClick = () => null,
                                            style = {},
                                            name = "Ok" },
                                            i) => <button
                                                key={i}
                                                className={`${className} float-right mr-2`}
                                                onClick={onClick}
                                                style={style}
                                            >
                                                {name}
                                            </button>
                                    )
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal confirm */}
        </>
    );
}

export default ModalConfirmCustom;