import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import { addCommas } from '../../../../../module';
import useKeycloak from '../../../../../hooks/keycloak.module';

const tkContext = createContext({
    tkState: {}, tkAction: {}
});

export function TKProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const { doGet, doPost, wait } = useFetch();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [formData, setFormData] = useState({
        cabang_rekening : "",
        saldo: 0,
        kode_valuta: "IDR",
        Status:""
    })


    const { keycloak } = useKeycloak()
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams()
    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });

            // getDataPerorangan(location.state?.dbId);
        }
        // } 
        setFormEditable(paramUrl.mode !== "otorisasi");
    }, [paramUrl.mode])

    useEffect(() => {
        getDetail();
    },[])

    function changeJumlahLembar(jumlah_lembar_koin, key) {

        if (isNaN(jumlah_lembar_koin)) return false;
        let nominal = 0;
        const de = formData.denominasi
        const denominasi = de.map((d, i) => {

            if (key === i) {
                nominal += (parseInt(jumlah_lembar_koin) * d.nilai_denominasi)
                return {
                    ...d,
                    jumlah_lembar_koin,
                }

            } else {
                nominal += (d.jumlah_lembar_koin * d.nilai_denominasi)
                return d
            }
        });

        if (isNaN(nominal)) {
            nominal = 0
        }

        setFormData(prevState => ({
            ...prevState,
            nominal,
            denominasi
        }));
    }

    // post ambil kas
    async function doTrx() {
        
    closeConfirm();
        // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();

        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        const dataSaldo = parseFloat(formData.saldo)
        if (dataSaldo !== 0.00){
            setDataAlert({ show: true, body: "Saldo lebih dari 0. Tidak dapat tutup kas", title: `Sisa Saldo ${addCommas(parseFloat(formData.saldo).toFixed(2))}`, header: "Info", callBack: () => null });
            return false
        }
        openLoading()
        try {
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username,
                    "x-token" : keycloak.idToken
                },
                // data: formData,
                url: "/kasteller/tutup", // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") setDataAlert({
                    show: true, body: remark, title: ``, header: "Success", callBack: () => {
                        closeConfirm();
                        history.goBack()
                    }
                });
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            // fieldRequired = ["nomorCIF", "nama_produk", "nama_rekening"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }


    async function getDetail() {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/kasteller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userInfo.preferred_username,
                    kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    setFormData(prevState => ({
                        // saldo: parseInt(data.saldo),
                        saldo: parseFloat(data?.saldo || "0").toFixed(2),
                        cabang_rekening: data.kode_cabang,
                        kode_valuta: data.kode_valuta,
                        status: data.status_buka
                    }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if(statusCode !== 401)  setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }
    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        value = value.toUpperCase()
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    function confirmTrx() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Tutup kas teller. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }


    return (
        <tkContext.Provider value={{
            tkState: {
                formData,
                formEditable,

            },
            tkAction: {
                changeForm,
                changeJumlahLembar,
                confirmTrx

            }
        }}>
            {children}
        </tkContext.Provider>
    )
}

export default function useTutupKas() { return useContext(tkContext) }