import React, { memo,useRef } from 'react';
import {BasePagePay} from '../../controls';
import { AppAction, AppInterfaces } from '../../../app.data';

const IconHeader = memo(function(){
    return (
        <>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3472 34.9999H10.1677C9.15516 34.9999 8.33334 34.186 8.33334 33.1831V14.1553H8.97138C9.02339 14.1587 9.07887 14.1622 9.13089 14.1622H14.1554C15.5806 14.1622 16.7388 13.015 16.7388 11.6034V6.62665C16.7388 6.56139 16.7353 6.49613 16.7284 6.43431V5.83325H29.8323C30.8449 5.83325 31.6667 6.64725 31.6667 7.65016V26.1362C30.4983 25.0563 28.9504 24.3742 27.2456 24.3104C27.4892 24.1583 27.6512 23.8895 27.6512 23.5833C27.6512 23.1093 27.2629 22.7247 26.7843 22.7247H13.9578C13.4792 22.7247 13.0909 23.1093 13.0909 23.5833C13.0909 24.0573 13.4792 24.442 13.9578 24.442H25.6098C23.7209 24.823 22.1111 25.9742 21.1305 27.5487C20.9824 27.2854 20.6985 27.1072 20.3728 27.1072H13.9578C13.4792 27.1072 13.0909 27.4919 13.0909 27.9659C13.0909 28.4398 13.4792 28.8245 13.9578 28.8245H20.3693C20.419 28.8245 20.4678 28.8204 20.5154 28.8124C20.2543 29.5308 20.112 30.3048 20.112 31.111C20.112 32.5558 20.5689 33.8968 21.3472 34.9999ZM26.7843 18.0708H13.9578C13.4792 18.0708 13.0909 18.4554 13.0909 18.9294C13.0909 19.4034 13.4792 19.7881 13.9578 19.7881H26.7843C27.2629 19.7881 27.6512 19.4034 27.6512 18.9294C27.6512 18.4554 27.2629 18.0708 26.7843 18.0708Z" fill="#FF6F00"/>
                <path d="M9.13089 13.1319C8.44084 13.1319 8.09755 12.3076 8.58301 11.8268L14.3774 6.08752C14.5369 5.92953 14.7276 5.86084 14.9183 5.86084C15.3136 5.86084 15.695 6.16652 15.695 6.62676V11.6207C15.6881 12.4519 15.0084 13.125 14.1693 13.1319H9.13089Z" fill="#FFA040"/>
                <path d="M26.2976 28.1252C26.2976 27.3208 25.6525 26.6667 24.86 26.6667C24.0675 26.6667 23.4224 27.3208 23.4224 28.1252C23.4224 28.9302 24.0675 29.585 24.86 29.585C25.6525 29.585 26.2976 28.9302 26.2976 28.1252Z" fill="#FFA040"/>
                <path d="M27.8691 33.5416C27.8691 34.346 28.5142 35.0001 29.3067 35.0001C30.0992 35.0001 30.7442 34.346 30.7442 33.5416C30.7442 32.7366 30.0992 32.0818 29.3067 32.0818C28.5142 32.0818 27.8691 32.7367 27.8691 33.5416Z" fill="#FFA040"/>
                <path d="M25.1805 34.3178L30.5164 28.9021C30.939 28.4731 30.939 27.7779 30.5164 27.349C30.0937 26.92 29.4088 26.92 28.9861 27.349L23.6503 32.7647C23.2277 33.1936 23.2277 33.8889 23.6503 34.3178C24.0736 34.7461 24.7554 34.7479 25.1805 34.3178Z" fill="#FFA040"/>
            </svg>

        </>
    )
})

const nom = [
    {
        id:1,
        providerId:1,
        value:10000,
        price:12000
    },{
        id:2,
        providerId:2,
        value:10000,
        price:12500,
    },{
        id:3,
        providerId:1,
        value:50000,
        price:51000,
    },{
        id:4,
        providerId:2,
        value:50000,
        price:53000,
    },{

        id:5,
        providerId:1,
        value:100000,
        price:105000,
    },
]

const PenerimaanDaerah_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
    
    return (
        <React.Fragment>
            <AppAction ref={appAction} />
            <BasePagePay
                options = {
                    [       
                        {
                            display: "",
                            value: 1,
                            forms: [{
                                type:"select",
                                name: "PENERIMAAN DAERAH",
                                label:"Pilih Daerah",
                                value:store.formData.providerId,
                                onChange:(val) => appAction.current.setForm("daerah", val),
                                displayKey:"name",
                                valueKey:"id",
                                required:true,
                                readOnly:false,
                                options:[
                                    {
                                        name : "ACEH",
                                        id : 1,
                                    },{
                                
                                        name : "BALI",
                                        id : 2,
                                    },{
                                
                                        name : "BANGKA BELITUNG",
                                        id : 3,
                                    },{
                                        name : "BANTEN",
                                        id : 4,
                                    },{
                                        name : "BENGKULU",
                                        id : 5,
                                    },{
                                        name : "DI YOGYAKARTA",
                                        id : 6,
                                    },{
                                        name : "DKI JAKARTA",
                                        id : 7,
                                    }
                                ]
                            },
                            {
                                type:"select",
                                name: "PENERIMAAN DAERAH",
                                label:"Pilih Kategori",
                                value:store.formData.providerId,
                                onChange:(val) => appAction.current.setForm("kategori", val),
                                displayKey:"name",
                                valueKey:"id",
                                required:true,
                                readOnly:false,
                                options:[
                                    {
                                        name : "ETILANG BEKASI",
                                        id : 1,
                                    },{
                                
                                        name : "ETILANG DEPOK",
                                        id : 2,
                                    },{
                                
                                        name : "E-TILANG KOTA JAKARTA UTARA",
                                        id : 3,
                                    },{
                                        name : "ETILANG TANGERANG",
                                        id : 4,
                                    },{
                                        name : "PAJAK DKI JAKARTA",
                                        id : 5,
                                    },{
                                        name : "SKPD DKI JAKARTA",
                                        id : 6,
                                    },{
                                        name : "SSPD-BPHTB DKI JAKARTA",
                                        id : 7,
                                    }
                                ]
                            },
                            {
                                type: "text",
                                name: "PENERIMAAN DAERAH",
                                label: "Nomor Tilang",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange:(val) => appAction.current.setForm("no_tilang", val),
                                readOnly: false
                            },
                            {
                                type: "text",
                                name: "PENERIMAAN DAERAH",
                                label: "Nama Penerima",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange:(val) => appAction.current.setForm("nama", val),
                                readOnly: false
                            },
                            {
                                type: "text",
                                name: "PENERIMAAN DAERAH",
                                label: "No. HP Penerima",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange:(val) => appAction.current.setForm("no_hp", val),
                                readOnly: false
                            },
                            {
                                type: "text",
                                name: "PENERIMAAN DAERAH",
                                label: "Alamat Penerima",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange:(val) => appAction.current.setForm("alamat", val),
                                readOnly: false
                            },
                            {
                                type: "text",
                                name: "PENERIMAAN DAERAH",
                                label: "Kota Penerima",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange:(val) => appAction.current.setForm("kota", val),
                                readOnly: false
                            },
                            {
                                type: "text",
                                name: "PENERIMAAN DAERAH",
                                label: "Kode Pos",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange:(val) => appAction.current.setForm("kode_pos", val),
                                readOnly: false,
                                onEnter:() => appAction.current.setOpenPembayaran(true)
                            }
                        ],
                            selectNominal: false,
                            onPay: () => appAction.current.pay(),
                            // setNominal: setNominal,
                        }
                    ]
                }
                title ={<span className="px-0"><IconHeader /> PENERIMAAN DAERAH</span>}
                nominals={nom}
                paymentCardData={{
                    detilBody: [
                        {
                            key: "No. Urut Document",
                            value: "10"
                        },{
                            key: "ID Tilang",
                            value: "E6780160"
                        },{
                            key: "Nama Pelanggar",
                            value: "Koesni"
                        },{
                            key: "Alamat",
                            value: "JKT UTARA"
                        },{
                            key: "Jenis Kendaraan",
                            value: "SPDMTR"
                        },{
                            key: "Peiode",
                            value: "201911"
                        },{
                            key: "Pasal Pelanggaran",
                            value: "235(1) yo 106(3)"
                        },{
                            key: "NO. Polisi",
                            value: "B 3759 QE"
                        },{
                            key: "Barang Bukti",
                            value: "STNK"
                        },{
                            key: "Denda Tilang",
                            value: "50.000"
                        },{
                            key: "Uang Perkara",
                            value: "0"
                        },{
                            key: "Ongkos Kirim BB",
                            value: "17.000"
                        },
                    ],
                    detilFooter: [
                        {
                            key: "Jumlah Tagihan",
                            value: 67000
                        },
                        {
                            key: "Admin Pos",
                            value: 5000
                        }
                    ],
                    total: 72000
                }}
            />
        </React.Fragment>
    );
});

const PenerimaanDaerah = AppInterfaces.appLoad(PenerimaanDaerah_base)
export default PenerimaanDaerah;