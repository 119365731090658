import React, { memo, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AppAction, AppInterfaces } from "../../../app.data";

const pelanggan = [
    {id: 1, noBarcode: "09831281214", layanan: "SURAT KILAT KHUSUS", wktPengiriman: "16-02-2021 16.02", dikirimDari: "BANDUNG", tujuan: "SUKABUMI", kodepos: "43111", berat: "4", tarif: "7.000,00"},
]

const PembatalanKiriman_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
    const [selected, setSelected] = useState(null);
    
    const prosesBatal = () => {
        appAction.current.setShowModal({
            show: true,
            size: "sm",
            backdrop: "static",
            body: <div className="text-center">Benar kiriman ini akan dibatalkan ?</div>,
            footer: <>
                <button className="btn btn-outline-secondary btn-sm px-3" onClick={()=>appAction.current.setCloseModal()}>TIDAK</button>
                <button className="btn btn-primary btn-sm px-4" onClick={()=>OKBatal()}>OK</button>
            </>
        })
    }
    const OKBatal = () => {
        appAction.current.setShowModal({
            show: true,
            size: "sm",
            backdrop: "static",
            body: <div className="text-center">Kiriman telah dibatalkan</div>,
            footer: <>
                <button className="btn btn-primary btn-sm px-4" onClick={()=>appAction.current.setCloseModal()}>OK</button>
            </>
        })
    }
    return(
        <Modal
            {...props}
            dialogClassName="w-100"
            aria-labelledby="example-custom-modal-styling-title"
            centered
            size="xl"
            >
            <AppAction ref={appAction} />
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Pembatalan Pengiriman
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3 row">
                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">No. Barcode</label>
                    <div className="col-sm-6 px-0">
                        <input type="text" className="form-control" placeholder="Masukan no barcode kiriman yang akan dibatalkan" aria-label="Masukan no barcode kiriman yang akan dibatalkan" aria-describedby="basic-addon2" 
                            />
                    </div>
                    <div className="col-sm-4"><button className="btn bg-orange-primary text-white">Tampilkan Data</button></div>
                </div>
                {/* <div className="row"> */}
                    <h5>Detail Kiriman</h5>
                    <table className="table table-borderless table-md table-responsive-md ">
                        <thead className="b-border-gray bg-gray-1">
                            <th>#</th>
                            <th>No. Barcode</th>
                            <th>Layanan</th>
                            <th>Waktu Pengiriman</th>
                            <th>Dikirim Dari</th>
                            <th>Tujuan</th>
                            <th>Kode Pos / Negara</th>
                            <th>Berat (gr)</th>
                            <th>Bea tarif</th>
                        </thead>
                        <tbody style={{backgroundColor: "#F5F6F8"}}>
                            {pelanggan.map((data, id) => (
                                <tr key={id} className={`pointer b-border-gray ${selected === id && "bg-gray-univ"}`} onClick={()=>setSelected(id)}>
                                    <td>{id+1}</td>
                                    <td>{data.noBarcode}</td>
                                    <td>{data.layanan}</td>
                                    <td>{data.wktPengiriman}</td>
                                    <td>{data.dikirimDari}</td>
                                    <td>{data.tujuan}</td>
                                    <td>{data.kodepos}</td>
                                    <td>{data.berat}</td>
                                    <td>{data.tarif}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <td colSpan="7"></td>
                            <th>TOTAL</th>
                            <th>{pelanggan[0].tarif}</th>
                        </tfoot>
                    </table>
                {/* </div> */}
            </Modal.Body>
            <Modal.Footer className="py-3">
                <Button variant="light"  onClick={props.onHide} className="px-5">RESET</Button>
                <Button variant="primary" className="px-4" onClick={()=>prosesBatal()}>PROSES BATAL</Button>
            </Modal.Footer>
        </Modal>
    )
})

const PembatalanKiriman = AppInterfaces.appLoad(PembatalanKiriman_base)
export default PembatalanKiriman;