import React, { memo } from 'react';
import { Navbar } from '../navbar';
import { SidebarLeft } from '../sidebar';


const LayoutMinimalis = memo(({children})=> {

    return(
        <div className="bg-gray-univ" >
            <Navbar />
            <div className="row p-0 m-0 ">
                <div className="w-sidebar-left">
                    <SidebarLeft />
                </div>
                <div className="w-mainpage basePage">
                    {children}
                </div>
            </div>
        </div>
    )
})
export default LayoutMinimalis