import { Modal, Form } from 'react-bootstrap';
import { FormHeader } from '../../controls';
import { Button } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { baseUrl } from '../../../app.config';

const HakAksesDataModal = ({ show, onHide, onSubmit, type = 'tambah', initialState }) => {
    const [state, setState] = useState({
        namaLengkap: '',
        identitas: '',
        noIdentitas: '',
        noTelpon: '',
        keterangan: '',
    });
    const [error, setError] = useState({});

    useEffect(() => {
        if (type === 'edit') setState(initialState);
    }, [type, initialState]);

    const { data: jenisIdentitas } = useQuery('jenis-identitas-options', async () => {
        const res = await fetch(baseUrl + '/dropdowns?key=eJenisIdentitas');

        const data = await res.json();

        return data?.list || [];
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        const newState = {
            ...state,
            [name]: value?.toUpperCase(),
        };

        const isNumber = !isNaN(Number(value));

        if (['noIdentitas', 'noTelpon'].includes(name)) {
            if (!isNumber) {
                newState[name] = state[name];
            }
        }

        if (error[name]) setError({ ...error, [name]: false });

        setState(newState);
    };

    const handleSubmit = () => {
        if (onSubmit) onSubmit(state);
        setState({
            namaLengkap: '',
            identitas: '',
            noIdentitas: '',
            noTelpon: '',
            keterangan: '',
        });
        if (onHide) onHide();
    };

    const handleHide = () => {
        setState({
            namaLengkap: '',
            identitas: '',
            noIdentitas: '',
            noTelpon: '',
            keterangan: '',
        });
        setError({});
        if (onHide) onHide();
    };

    return (
        <Modal size="xl" centered show={show}>
            <Modal.Header onHide={handleHide} style={{ padding: '16px 32px' }} closeButton>
                <Modal.Title>
                    <h5 className="tw-text-18 tw-font-bold">
                        {type === 'tambah' ? 'Tambah' : 'Ubah'} Hak Akses Rekening
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '16px 32px' }}>
                <FormHeader className="mb-4">Data Diri</FormHeader>
                <Form className="tw-grid tw-grid-cols-2 tw-gap-x-12 tw-gap-y-4">
                    <Form.Group bsPrefix=" ">
                        <Form.Label className="font-weight-normal f-12">
                            Nama (Sesuai Identitas)
                        </Form.Label>
                        <Form.Control
                            name="namaLengkap"
                            value={state?.namaLengkap}
                            onChange={handleChange}
                            size="sm"
                            placeholder="Nama nasabah sesuai identitas"
                        />
                    </Form.Group>
                    <Form.Group bsPrefix=" ">
                        <Form.Label className="font-weight-normal f-12">Jenis Identitas</Form.Label>
                        <Form.Control
                            name="identitas"
                            value={state?.identitas}
                            onChange={handleChange}
                            as="select"
                            size="sm"
                        >
                            <option selected hidden disabled value="">
                                Pilih Jenis Identitas
                            </option>
                            {jenisIdentitas?.map(({ reference_desc, reference_code }) => (
                                <option
                                    key={reference_code}
                                    value={`${reference_code} - ${reference_desc}`}
                                >
                                    {reference_desc}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group bsPrefix=" ">
                        <Form.Label className="font-weight-normal f-12">Handphone</Form.Label>
                        <Form.Control
                            name="noTelpon"
                            value={state?.noTelpon}
                            onChange={handleChange}
                            size="sm"
                            placeholder="Masukkan nomor handphone"
                        />
                    </Form.Group>
                    <Form.Group bsPrefix=" ">
                        <Form.Label className="font-weight-normal f-12">Nomor Identitas</Form.Label>
                        <Form.Control
                            name="noIdentitas"
                            value={state?.noIdentitas}
                            onChange={handleChange}
                            size="sm"
                            placeholder="Masukkan nomor identitas"
                        />
                    </Form.Group>
                    <Form.Group bsPrefix=" " className="tw-row-span-2">
                        <Form.Label className="font-weight-normal f-12">Keterangan</Form.Label>
                        <Form.Control
                            name="keterangan"
                            value={state?.keterangan}
                            onChange={handleChange}
                            as="textarea"
                            size="sm"
                            rows={3}
                            placeholder="Keterangan"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleHide} className="mr-2 text-white" size="mini">
                    Batal
                </Button>
                <Button onClick={handleSubmit} color="blue" size="mini">
                    Simpan
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default HakAksesDataModal;
