import { capitalize } from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import SetorTunai from './formsetortunai';
import { FrameRegistration, NewLoading, PrintModal } from '../../../../controls';
import useSetorTunai, { SetorTunaiProvider } from './useSetorTunai';
import ModalDataNasabah from './modal/modalDataNasabah';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import { addCommas } from '../../../../../module';
import moment from 'moment';
import { usePrintModal } from '../../../../controls/modalPrint';
import useCetak, { CetakProvider } from '../../../../controls/cetak/useCetak';


function NewSetorTunai() {
    const { stState, stAction } = useSetorTunai();
    const { mode = "setor-tunai" } = useParams()
    const { mpAction, mpState } = usePrintModal()

    function getLastUrl() {
        // if(mode === undefined || mode === null) mode = ""
        const types = mode.split("-");
        if (types.length <= 1) return mode;
        else return types.join(' ')
    }
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-2 mb-3">
                    <li className="breadcrumb-item color-primary">Transaksi Nasabah</li>
                    <li className="breadcrumb-item">
                        <a href="#">Setor Tunai</a>
                    </li>
                    <li className="breadcrumb-item">{capitalize(getLastUrl())}</li>
                </ol>
            </nav>
            <FrameRegistration>
                <div id="step" dataStep={0}>
                    <SetorTunai />
                </div>
                <div id="footer">
                    <div className="row">
                        <div className="col">
                            {
                                stState.formEditable &&
                                <button
                                    onClick={stAction.resetForm}
                                    className="btn btn-outline-danger">
                                    Reset
                                </button>
                            }
                            {
                                mode === "otorisasi" && (
                                    <div style={{
                                        width: "fit-content",
                                        float: "right",
                                        borderRight: "1px sold gray",
                                        margin: "0px 5px"
                                    }}>
                                        <button
                                            onClick={() => stAction.responseOtor("APPROVED")}
                                            className="btn btn-success mr-2">
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => stAction.responseOtor("REJECTED")}
                                            className="btn btn-danger mr-2">
                                            Reject
                                        </button>
                                    </div>
                                )
                            }
                            {
                                stState.formEditable &&
                                <button disabled={stAction.validasi()} onClick={stAction.confirmTrx} className="btn btn-primary float-right mr-2">
                                    Simpan
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </FrameRegistration>
            <ModalDataNasabah />
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => stAction.setOtorDesc(e.target.value)}
                    value={stState.otorDesc}
                />
            </ModalConfirmCustom>
            <PrintModal
                title="Transaksi Berhasil"
                modalSize="md"
                id="modalTrx"
                footer={false}
                data={{
                    kode_transaksi: stState.dataPrint?.transaksi?.kode_transaksi,
                    tanggal_input: moment(stState.dataPrint?.transaksi.tanggal_otor).format("DD-MM-YYYY HH:mm:ss"),
                    noref: stState.dataPrint?.transaksi?.nomor_referensi,
                    userInput: stState.dataPrint?.transaksi?.user_input,
                    userOtor: stState.dataPrint?.transaksi?.user_otorisasi,
                    kodecabang: stState.dataPrint?.transaksi?.kode_cabang_transaksi,
                    nominal: addCommas((stState.dataPrint?.transaksi?.nominal || "0")),
                    biaya: addCommas((stState.dataPrint?.info_transaksi?.biaya || "0")),
                    keterangan: stState.dataPrint?.transaksi?.keterangan,
                    norek: stState.dataPrint?.transaksi?.nomor_rekening_credit,
                    narek: stState.dataPrint?.info_transaksi?.nama_rekening,
                    rektel: stState.dataPrint?.transaksi?.nomor_rekening_debet,
                    space: "",
                    space1: "",
                    space2: "",
                    space3: "",
                    space4: "",
                    space5: "",
                }}

                footerComponent={
                    <Modal.Footer>
                        <div className="row">
                            <div className="col">
                                <button
                                    onClick={() => stAction.cetakFunction()}
                                    className="btn btn-sm btn-primary ml-3 float-right">
                                    <i className="fa fa-print"></i> Cetak
                                </button>
                                {<button onClick={() => {
                                    mpAction.closeModal("modalTrx")
                                    mpState.modalDetil?.callBack?.()
                                    // callBackClose()
                                }} className="btn btn-sm btn-outline-secondary float-right ml-3">
                                    Tutup
                                </button>}
                            </div>
                        </div>
                    </Modal.Footer>
                }
                dataLabel={[
                    {
                        name: "Kode Transaksi",
                        fieldName: "kode_transaksi"
                    },
                    {
                        name: "Waktu Transaksi",
                        fieldName: "tanggal_input"
                    },
                    {
                        name: "No. Referensi",
                        fieldName: "noref"
                    },
                    {
                        name: "User Input",
                        fieldName: "userInput",
                    },
                    {
                        name: "User Otor",
                        fieldName: "userOtor",
                    },
                    {
                        name: "Kode Cabang",
                        fieldName: "kodecabang",
                    },
                    {
                        name: "",
                        fieldName: "space",
                    },
                    {
                        name: "",
                        fieldName: "space1",
                    },
                    {
                        name: "",
                        fieldName: "space2",
                    },
                    {
                        name: "Rekening Teller",
                        fieldName: "rektel"
                    },
                    {
                        name: "Nominal",
                        fieldName: "nominal",
                        className: "text-right"
                    },
                    {
                        name: "Biaya",
                        fieldName: "biaya",
                        // className: "text-right"
                    },
                    {
                        name: "Keterangan",
                        fieldName: "keterangan",
                        // className: "text-right"
                    },
                    {
                        name: "",
                        fieldName: "space3",
                    },
                    {
                        name: "",
                        fieldName: "space4",
                    },
                    {
                        name: "",
                        fieldName: "space5",
                    },
                    {
                        name: "Nomor Rekening Tujuan",
                        fieldName: "norek"
                    },
                    {
                        name: "Nama Rekening Tujuan",
                        fieldName: "narek"
                    },
                ]}
            />
        </>
    )
}
export default () => (
    <CetakProvider fastPrint={true}>
        <SetorTunaiProvider>
            <NewSetorTunai />
        </SetorTunaiProvider>
    </CetakProvider>
)