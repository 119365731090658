import React from 'react';
import DatePicker from 'react-datepicker'
import moment from "moment";


const DateInput = ({
    value = "",
    className = "",
    onChange = () => null,
    disabled = false,
    readOnly = false,
}) => {

    function validDate(d) {
        var timestamp = Date.parse(d)

        return !isNaN(timestamp)
        // if (Object.prototype.toString.call(d) === "[object Date]") {
        //     // it is a date
        //     if (isNaN(d.getTime())) {  // d.valueOf() could also work
        //         return false
        //     }
        //     else {
        //         return true
        //     }
        // }
        // else {
        //     return false
        // }
    }

    // console.log(validDate(value), value)
    return (
        <DatePicker

            className={className}
            selected={
                (value !== undefined && value !== null
                    && validDate(value)
                ) && value ? new Date(value) : ""
            }
            type="date"
            dateFormat="dd/MM/yyyy"
            disabledKeyboardNavigation
            onKeyDown={e => {
                
                // console.log(e.keyCode)
                let val = e.target.value.replace(/[^0-9 \-]/g, '')
                if (e.keyCode !== 8 && e.keyCode !== 46) {
                    val = e.target.value.replace(/[^0-9 / \-]/g, '')
                    
                    if(val.length <= 10){
                        if (val.length === 2 || val.length === 5) {
                            val = val + "/"
                            // console.log(val)
                            // console.log(val.length)
                            e.target.value = val

                        }
                    }
                }
            }}

            value={
                (value !== undefined && value !== null
                    && validDate(value)
                ) && value ? new Date(value) : ""
            }

            // onKeyUp={() =>null}
            onChange={(e) => {
                //validDate(e) ? 
                onChange?.(moment(e).format("YYYY-MM-DD")) // : onChange?.("")
            }}
            disabled={disabled}
            readOnly={readOnly}
            placeholderText={"DD/MM/YYYY"}
        // onBlur={
        //     (e) => {
        //         if (onFocusOut !== null && onFocusOut !== undefined) {
        //             onFocusOut?.(e.target.value)
        //         }
        //     }
        // }
        />
    );
}

export default DateInput;