import React from 'react';
import { useHistory, useParams } from 'react-router';
import { PrintModal, TableList } from '../../../../controls';
import ModalConfirmCustom from '../../../../controls/modalAlert/modalConfirmCustomChild';
import useAmbilKasLain, { AKLProvider } from './useAmbilKasLain';
// import { useKeycloak } from '@react-keycloak/web';
import { addCommas, removeNonNumeric } from "../../../../../module"
import moment from 'moment';
import { usePrintModal } from '../../../../controls/modalPrint';
import { Modal } from 'react-bootstrap';
import useKeycloak from '../../../../../hooks/keycloak.module';

const AmbilKasLainPage = function () {
    const { aklState, aklAction } = useAmbilKasLain();
    const { mode } = useParams();
    const history = useHistory();
    const { keycloak } = useKeycloak()
    
    const { mpAction, mpState } = usePrintModal()

    // const addCommas = num => num.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // const removeNonNumeric = num => num.toString().replace(/[^0-9 .]/g, "");
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a className="text-primary-bds" href="#">Kas Teller</a>
                    </li>
                    {/* <li className="breadcrumb-item"><a href="#">CIF</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">
                        Permintaan Kas dari Teller Lain
                    </li>
                </ol>
            </nav>

            <div className="row">
                <div className="col-md-6">

                    <div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title text-base">
                                            Informasi Teller
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {/* {
                                            mode === "otorisasi" && (
                                                <div className="box m-3">
                                                    <h5>Nomor rekening : {aklState.formData.nomor_rekening}</h5>
                                                </div>
                                            )
                                        } */}
                                        <div className="form-group">
                                            <label>User ID Teller Pengirim</label>
                                            <div className="input-group">
                                                <input type="text"
                                                    value={aklState.formData?.info_tlr_debet?.user_id}
                                                    // onChange={(e) => aklAction.setFormData(form => ({
                                                    //     ...form,
                                                    //     user_id_teller_pengirim: e.target.value
                                                    // }), "user_id_teller_pengirim")}
                                                    readOnly={!aklState.formEditable || true} className="form-control"
                                                    // onKeyUp={(e) => {
                                                    //     if (e.keyCode === 13) {
                                                    //         aklAction.getTeller(e.target.value);
                                                    //         // aklAction.getTellerInfo(e.target.value);
                                                    //     }
                                                    // }}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" 
                                                    disabled={!aklState.formEditable}
                                                    onClick={() =>{
                                                        aklAction.openModalTeller()
                                                        // aklAction.getTellerInfo((aklState.formData?.user_id_teller_pengirim))
                                                            }
                                                        }>
                                                        Cari Teller
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Valuta</label>
                                            <div className="input-group">
                                                <div className="input-group-append col-3">
                                                    <select className="form-control"
                                                        readOnly={!aklState.formEditable}
                                                        onChange={(e) => aklAction.changeForm((e.target.value), "kode_valuta")}
                                                        value={aklState.formData?.kode_valuta}
                                                    >
                                                        <option value=''>Pilih Valuta</option>
                                                        <option value='IDR'>IDR</option>
                                                    </select>
                                                    {/* <input type="text" className="form-control" disabled value={aklState.formData?.kode_valuta} /> */}
                                                </div>
                                                <input type="text" readOnly className="form-control col" value="INDONESIAN RUPIAH" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Total Saldo</label>
                                            <div className="input-group">
                                                <div className="input-group-append col-3">
                                                    <input type="text" className="form-control" disabled value="IDR" />
                                                </div>
                                                <input type="text" className="form-control col"
                                                    readOnly
                                                    value={addCommas( parseFloat(aklState.formData?.info_tlr_debet?.saldo || 0).toFixed(2))}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Nama Teller Pengirim</label>
                                            <input type="text" className="form-control col"
                                                onChange={(e) => aklAction.changeForm(e.target.value, "nama_user_pengirim")}
                                                value={aklState.formData?.info_tlr_debet?.nama_teller}
                                                readOnly={!aklState.formEditable || true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Cabang</label>
                                            <div className="input-group">
                                                <div className="input-group-append col-3">
                                                    <input type="text" className="form-control" disabled 
                                                        value={aklState.formData?.info_tlr_debet?.kode_cabang_tlr}
                                                        onChange={(e) => aklAction.changeForm(e.target.value, "kode_cabang_pengirim")}
                                                    />
                                                </div>
                                                <input type="text" className="form-control col"
                                                    readOnly
                                                    value={aklState.formData?.info_tlr_debet?.nama_cabang_tlr}
                                                    onChange={(e) => aklAction.changeForm(e.target.value, "nama_cabang_pengirim")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">
                                            PERMINTAAN KAS TELLER LAIN
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {/* <div className="form-group">
                                            <label>Jumlah Pengambilan</label>
                                            <input type="text"
                                                value={parseInt(aklState.formData?.nominal).toLocaleString()addCommas( parseFloat(aklState.saldoKas || 0).toFixed(2))}
                                                readOnly className="form-control col" />
                                        </div> */}

                                        <div className="form-group">
                                            <label>Jumlah Pengambilan</label>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <input type="text"

                                                        className="form-control" disabled value={aklState.formData?.kode_valuta} />
                                                </div>
                                                <input type="text" className="form-control"
                                                    // readOnly
                                                    value={
                                                        // aklState.formEditable ? aklState.formData?.nominal: 
                                                        addCommas(aklState.formData?.nominal)
                                                    }
                                                    onChange={(e) => {
                                                        const val = removeNonNumeric(e.target.value)
                                                        if (!isNaN(parseInt(val))) aklAction.changeForm(val, "nominal")
                                                        else if (isNaN(parseInt(e.target.value))) aklAction.changeForm("", "nominal")
                                                    }}
                                                    readOnly={true}
                                                // value={parseInt(aklState.formData?.info_transaksi?.saldo_teller_after).toLocaleString()addCommas( parseFloat(aklState.saldoKas || 0).toFixed(2))}

                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>No Referensi</label>
                                            <input type="text" className="form-control col"
                                                onChange={(e) => aklAction.changeForm(e.target.value, "nomor_referensi")}
                                                value={aklState.formData?.nomor_referensi}
                                                readOnly={!aklState.formEditable || true}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>Nama Teller Penerima</label>
                                            <input type="text" className="form-control col"
                                                onChange={(e) => aklAction.changeForm(e.target.value, "nama_user_penerima")}
                                                value={aklState.formData?.nama_user_penerima}
                                                readOnly={!aklState.formEditable || true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Cabang</label>
                                            <div className="input-group">
                                                <div className="input-group-append col-3">
                                                    <input type="text" className="form-control" disabled 
                                                       onChange={(e) => aklAction.changeForm(e.target.value, "kode_cabang_penerima")}
                                                       value={aklState.formData?.kode_cabang_penerima}
                                                       readOnly={!aklState.formEditable}
                                                    />
                                                </div>
                                                <input type="text" className="form-control col"
                                                    readOnly
                                                    value={aklState.formData?.nama_cabang_penerima}
                                                    onChange={(e) => aklAction.changeForm(e.target.value, "nama_cabang_penerima")}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Keterangan</label>
                                            <textarea type="text" className="form-control col"
                                                
                                                maxLength={100} onChange={(e) => aklAction.changeForm(e.target.value, "keterangan")}
                                                value={aklState.formData?.keterangan}
                                                readOnly={!aklState.formEditable}
                                            />
                                        </div>

                                    </div>
                                        {  mode === "otorisasi" && keycloak.hasRealmRole("teller")?
                                            <div className="card-footer">
                                                <button
                                                    onClick={() => aklAction.responseOtorTeller(true)}
                                                    style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                                    className="btn btn-sm btn-outline-success float-right ml-2"
                                                >
                                                    <b>Approve</b>
                                                </button>
                                                <button
                                                    onClick={() => aklAction.responseOtorTeller(false)}
                                                    style={{ borderRadius: 10, paddingLeft: 15, paddingRight: 15 }}
                                                    className="btn btn-sm btn-outline-danger float-right ml-2"
                                                >
                                                    <b>Reject</b>
                                                </button>
                                            </div>
                                            :
                                            <div className="card-footer">
                                                    
                                                {
                                                    aklState.formEditable ? <div className="row">
                                                        <div className="col-12">
                                                            <button disabled={aklState.err} className="btn btn-success btn-sm float-right ml-2" onClick={aklAction.confirmTrx}>
                                                                Proses
                                                            </button>
                                                            <button className="btn btn-outline-secondary btn-sm float-right ml-2" onClick={() => history.goBack()}>
                                                                Batal
                                                            </button>
                                                        </div>
                                                    </div> : (
                                                        mode === "otorisasi" ? (
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <button 
                                                                    disabled={aklState.formData?.is_otor_teller === "F"}
                                                                    className="btn btn-primary btn-sm float-right ml-2" onClick={() => aklAction.responseOtor("APPROVED")}>
                                                                        Approve
                                                                    </button>
                                                                    <button className="btn btn-danger btn-sm float-right ml-2" onClick={() => aklAction.responseOtor("REJECTED")}>
                                                                        Reject
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <button className="btn btn-outline-secondary btn-sm float-right ml-2" onClick={() => history.goBack()}>
                                                                        Back
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        }
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title text-base">
                                Denominasi
                            </div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                pagination={false}
                                filter={false}
                                withActions={false}
                                maxHeight="1500px"
                                columns={[
                                    {
                                        name: 'Nilai Denominasi',
                                        field: 'nilai_denominasi',
                                    },
                                    {
                                        name: 'Jenis',
                                        field: 'jenis',
                                    },
                                    {
                                        name: 'Jumlah Lembar',
                                        field: 'jumlah_lembar_koin',
                                        maxWidth: "100px",
                                        onRender: (item, key) => (
                                            <input
                                                type="text"
                                                value={item.jumlah_lembar_koin}
                                                readOnly={!aklState.formEditable}
                                                className="form-control form-control-sm"
                                                onChange={(e) => aklAction.changeJumlahLembar(e.target.value, key)}
                                                onBlur={(e) => {
                                                    let { value } = e.target
                                                    if(value === "" || value === null || value === undefined){
                                                        return  aklAction.changeJumlahLembar( removeNonNumeric(0), key)
                                                    }
                                                }}
                                            />
                                        )
                                    },
                                    {
                                        name: 'Total Nominal',
                                        field: 'total',
                                        className: "text-right",
                                        onRender: (item) => <> {addCommas( parseFloat(item.nilai_denominasi * item.jumlah_lembar_koin || 0).toFixed(2))}, -</>
                                    },
                                ]}
                                data={
                                    aklState.formData?.denominasi || []
                                }

                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmCustom id="otorisasi">
                <textarea className="form-control"
                    onChange={(e) => aklAction.setOtorDesc(e.target.value)}
                    value={aklState.otorDesc}
                />
            </ModalConfirmCustom>
            <PrintModal
                title="Transaksi Berhasil"
                modalSize="md"
                id="modalTrx"
                data={{
                    kode_transaksi: aklState.dataPrint?.transaksi?.kode_transaksi,
                    tanggal_input: moment(aklState.dataPrint?.transaksi?.tanggal_input).format("DD-MM-YYYY HH:mm:ss"),
                    noref: aklState.dataPrint?.transaksi?.nomor_referensi,
                    norek: aklState.dataPrint?.transaksi?.nomor_rekening_debet,
                    norekkredit: aklState.dataPrint?.transaksi?.nomor_rekening_credit,
                    nominal: addCommas((aklState.dataPrint?.transaksi?.nominal || "0")),
                    userInput: aklState.dataPrint?.transaksi?.user_input
                }}
                footerComponent={
                    <Modal.Footer>
                        <div className="row">
                            <div className="col">
                                <button
                                    // onClick={() => ak.cetakFunction()}
                                    className="btn btn-sm btn-primary ml-3 float-right">
                                    <i className="fa fa-print"></i> Cetak
                                </button>
                                {<button onClick={() => {
                                    mpAction.closeModal("modalTrx")
                                    mpState.modalDetil?.callBack?.()
                                    // callBackClose()
                                }} className="btn btn-sm btn-outline-secondary float-right ml-3">
                                    Tutup
                                </button>}
                            </div>
                        </div>
                    </Modal.Footer>
                }
                footer={false}
                dataLabel={[
                    {
                        name: "Kode Transaksi",
                        fieldName: "kode_transaksi"
                    },
                    {
                        name: "Waktu Transaksi",
                        fieldName: "tanggal_input"
                    },
                    {
                        name: "No. Referensi",
                        fieldName: "noref"
                    },
                    {
                        name: "Nomor Rekening Debit",
                        fieldName: "norek"
                    },
                    {
                        name: "Nomor Rekening Kredit",
                        fieldName: "norekkredit",
                    },
                    {
                        name: "Nominal",
                        fieldName: "nominal",
                        className: "text-right"
                    },
                    {
                        name: "User Input",
                        fieldName: "userInput",
                    }

                ]}
            />
        </>
    )
}

export default function () {
    return <AKLProvider>
        <AmbilKasLainPage />
    </AKLProvider>
}