import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { Col, Form, FormControl, InputGroup, Row, Modal } from 'react-bootstrap';
import { Breadcrumb, Button, Label, Message } from 'semantic-ui-react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { Gap, Stepper, FormHeader, TableGrid, ActionMore } from '../../controls';
import { baseUrl } from '../../../app.config';
import { getErrorEmptyValue, joinDefinedString } from '../../../utils';
import SpecimenPejabatModal from './specimen-pejabat-modal';
import HakAksesDataModal from './hak-akses-data-modal';
import SummaryCard from './summary-card';
import { toast } from 'react-toastify';
import { thousandSeparator } from '../../../utils';

const RegistrasiTabunganRencana = () => {
    const history = useHistory();
    const { search } = useLocation();

    const { type, id, mode } = queryString.parse(search);

    const [activeIdx, setActiveIdx] = useState(0);
    const [tipeSpecimenPejabat, setTipeSpecimenPejabat] = useState('registrasi'); // registrasi | edit
    const [specimenPejabatState, setSpecimenPejabatState] = useState([]);
    const [tipeRelasiNasabah, setTipeRelasiNasabah] = useState('tambah'); // tambah | edit
    const [relasiNasabahState, setRelasiNasabahState] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [showModalCariNasabah, setShowModalCariNasabah] = useState(false);
    const [nomorCif, setNomorCif] = useState('');
    const [nomorCifSelect, setNomorCifSelect] = useState('');
    const [nasabah, setNasabah] = useState({
        nama: '',
        nomorTelepon: '',
        nomorHP: '',
        namaIbuKandung: '',
        tempatLahir: '',
        tanggalLahir: '',
        alamat: '',
        specimenPejabat: [],
    });
    const [form, setForm] = useState({
        nomorCIF: '',
        accountHolderKey: '',
        accountHolderType: '',
        namaRekening: '',
        valuta: 'IDR',
        produkGiro: '',
        notes: '',
        saldo: '',
        nomorRekening: '',
        productTypeKey: '',
        assignedBranchKey: '',
        _rekeningTabungan: {
            dapatBonusBagiHasil: false,
            blokirDebet: false,
            cekNota: false,
            dormant: false,
            biayaAdministrasi: false,
            biayaATM: false,
            blokirKredit: false,
            statusPassbook: false,
            biayaRekeningDormant: false,
            biayaSaldoMinimum: false,
        },
        _informasiLain: {
            nisbahAkhir: Number(0),
            nisbahCounter: Number(0),
            nisbahZakat: Number(0),
            nisbahPajak: Number(0),
            sumberDana: '',
            qqName: '',
            biayaBiaya: '',
            tncVersion: '',
            tujuanPembukaanRekening: '',
            kelengkapanData: '',
            marketingReferensi: '',
            marketingPertama: '',
            marketingSaatIni: '',
        },
        _informasiLainKorporat: {
            isSmsBanking: 'N',
            isMobileBanking: 'N',
            isInternetBanking: 'N',
            infoLimitNominalSetorNontunai: '',
            infoLimitFrekuensiSetorTunai: '',
            infoLimitFrekuensiSetorNontunai: '',
            infoLimitNominalSetorTunai: '',
        },
        _asuransi: {
            tanggalJatuhTempoAsuransi: '',
            rencanaAsuransiUtama: '',
            rencanaAsuransiTambahan: '',
        },
        _specimenPejabat: [],
        _hakAksesRekening: [],
        _dataNasabah: {
            telpPerusahaan: '',
            tanggalBerdiri: '',
            alamatPerusahaan: '',
            npwp: '',
        },
        _blokirRekening: {
            alasan: '',
            status: '',
        },
    });
    const [error, setError] = useState({});
    const [modal, setModal] = useState({ specimenPejabat: false, hakAksesData: false });
    const [isLoading, setLoading] = useState(true);
    const [showModalHapus, setShowModalHapus] = useState(false);
    const [indexType, setIndexType] = useState('');
    const [indexHapus, setIndexHapus] = useState(null);

    const findNasabahApproved = async (encodedKey, type) => {
        if (type === 'CLIENT') {
            const res = await fetch(baseUrl + `/nasabah/perorangan/find/${encodedKey}`);

            const data = await res.json();

            return data.list;
        }

        if (type === 'GROUP') {
            const res = await fetch(
                baseUrl + '/nasabah-badan-usaha/filter?paginationDetails=OFF&detailsLevel=FULL',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        filterCriteria: [
                            {
                                field: 'encodedKey',
                                value: encodedKey,
                                operator: 'EQUALS',
                            },
                        ],
                    }),
                }
            );

            const data = await res.json();

            if (data.list) return data.list[0];
        }

        return {};
    };

    const findProductName = async (encodedKey) => {
        const res = await fetch(baseUrl + '/rekening/tabungan/produk?limit=10&page=0');

        const data = await res.json();

        return data.list?.find((value) => value.encodedKey === encodedKey)?.name;
    };

    const findBranch = async (assignedBranchKey) => {
        const res = await fetch(
            baseUrl + `/rekening/tabungan/kantor/cabang/${assignedBranchKey}/detail`
        );

        const data = await res.json();

        return data.list;
    };

    useQuery(
        ['data-detail', type, id],
        async () => {
            if (!type) return undefined;

            if (mode === 'approved') {
                const res = await fetch(baseUrl + `/rekening/tabungan/approve/${id}/detail`);

                const data = await res.json();

                return data.list;
            }

            const res = await fetch(baseUrl + `/rekening/tabungan/pending/${id}/detail`);

            const data = await res.json();

            return data?.list?.DataRekeningTabungan;
        },
        {
            onSuccess: async (data) => {
                if (!data) return;

                if (mode === 'approved') {
                    const {
                        accountHolderKey,
                        accountHolderType,
                        accountState,
                        name,
                        balances,
                        productTypeKey,
                        notes,
                        _otherInformation,
                        _otherInformationCorporate,
                        currencyCode,
                        id: nomorRekening,
                        _hakAksesRekening,
                        _rekening,
                        assignedBranchKey,
                    } = data;

                    const {
                        firstName,
                        lastName,
                        middleName,
                        _ktpAddress,
                        homePhone,
                        mobilePhone,
                        birthDate,
                        _customerData,
                        id,
                        groupName,
                        _identitasNasabah,
                        _pejabatKorporat,
                    } = await findNasabahApproved(accountHolderKey, accountHolderType);

                    const produkGiro = await findProductName(productTypeKey);

                    const cabang = await findBranch(assignedBranchKey);

                    setNasabah({
                        nama:
                            joinDefinedString(firstName, middleName, lastName).separateWith(' ') ||
                            groupName,
                        alamat:
                            accountHolderType === 'GROUP'
                                ? joinDefinedString(
                                      _identitasNasabah?.nasabahAlamat,
                                      _identitasNasabah?.nasabahKotaKabupaten,
                                      _identitasNasabah?.nasabahProvinsi,
                                      _identitasNasabah?.nasabahKodePos
                                  ).separateWith(', ')
                                : joinDefinedString(
                                      _ktpAddress?.ktpAddress,
                                      _ktpAddress?.ktpCity,
                                      _ktpAddress?.ktpProvince,
                                      _ktpAddress?.ktpPostalCode
                                  ).separateWith(', '),
                        namaIbuKandung: _customerData?.personalMothersName,
                        nomorHP: mobilePhone,
                        nomorTelepon:
                            homePhone ||
                            _identitasNasabah?.nasabahTeleponKantorNomor1 ||
                            mobilePhone,
                        tanggalLahir:
                            accountHolderType === 'GROUP'
                                ? _identitasNasabah?.nasabahTanggalAktePendirian
                                : birthDate,
                        tempatLahir: _customerData?.birthPlace,
                        saldo: balances?.availableBalance,
                        npwp: _identitasNasabah?.nasabahNomorNpwp,
                        cabang,
                        specimenPejabat: _pejabatKorporat ?? [],
                    });
                    setNomorCif(id);

                    setForm({
                        ...form,
                        accountHolderKey,
                        accountHolderType,
                        status: accountState,
                        namaRekening: name,
                        id: nomorRekening,
                        nomorRekening,
                        nomorCIF: id,
                        productTypeKey,
                        produkGiro,
                        notes,
                        valuta: currencyCode,
                        _rekeningTabungan: {
                            dapatBonusBagiHasil: _rekening?.rekeningDapatBonusBaghas === 'true',
                            biayaATM: _rekening?.rekeningBiayaAtm === 'true',
                            biayaAdministrasi: _rekening?.rekeningBiayaAdministrasi === 'true',
                            biayaRekeningDormant: _rekening?.rekeningBiayaDormant === 'true',
                            biayaSaldoMinimum: _rekening?.rekeningBiayaMinimum === 'true',
                            blokirDebet: _rekening?.rekeningBlokirDebet === 'true',
                            blokirKredit: _rekening?.rekeningBlokirKredit === 'true',
                            cekNota: _rekening?.rekeningCetakNota === 'true',
                            dormant: _rekening?.rekeningDorman === 'true',
                            statusPassbook: _rekening?.rekeningStatusPassbook === 'true',
                        },
                        _informasiLain: {
                            ...form._informasiLain,
                            ..._otherInformation,
                            sumberDana: _otherInformation?.sourceOfFund,
                            tujuanPembukaanRekening: _otherInformation?.purpose,
                            kelengkapanData: 'dummy',
                        },
                        _informasiLainKorporat: {
                            isInternetBanking: _otherInformationCorporate?.isInternetbanking,
                            isMobileBanking: _otherInformationCorporate?.isMobileBanking,
                            isSmsBanking: _otherInformationCorporate?.isSmsBanking,
                            infoLimitNominalSetorNontunai: 'string',
                            infoLimitFrekuensiSetorTunai: 'string',
                            infoLimitFrekuensiSetorNontunai: 'string',
                            infoLimitNominalSetorTunai: 'string',
                        },
                        _hakAksesRekening: _hakAksesRekening
                            ? [
                                  {
                                      namaLengkap: _hakAksesRekening?.hakAksesNama,
                                      namaIbuKandung: _hakAksesRekening?.hakAksesNamaIbuKandung,
                                      keterangan: _hakAksesRekening?.hakAksesKeterangan,
                                      noIdentitas: _hakAksesRekening?.hakAksesNomorIdentitas,
                                      tanggalLahir: _hakAksesRekening?.hakAksesTanggalLahir,
                                      identitas: _hakAksesRekening?.hakAksesJenisIdentitas,
                                      noTelpon: _hakAksesRekening?.hakAksesHandphone,
                                  },
                              ]
                            : [],
                    });
                    setLoading(false);
                    return;
                }

                setNomorCif(data.nomorCIF);
                await handleSearchNasabah.mutateAsync({ e: undefined, id: data.nomorCIF });

                setForm({
                    nomorCIF: data.nomorCIF,
                    accountHolderKey: data.accountHolderKey,
                    accountHolderType: data.accountHolderType,
                    namaRekening: data.namaRekening,
                    status: data.status,
                    notes: data.keterangan,
                    productTypeKey: data.productTypeKey,
                    produkGiro: data.produkGiro,
                    saldo: data.saldo,
                    nomorRekening: data.nomorRekening,
                    valuta: data.valuta,
                    assignedBranchKey: data.assignedBranchKey,
                    _dataNasabah: data._dataNasabah || {},
                    _hakAksesRekening: data._hakAksesRekening ? [data._hakAksesRekening] : [],
                    _asuransi: data._asuransi || {},
                    _informasiLain: data._informasiLain,
                    _rekeningTabungan: data._rekening,
                    _specimenPejabat: data._specimenPejabat,
                    _informasiLainKorporat: data._informasiLainKorporat || {},
                    _blokirRekening: data._blokirRekening || {
                        alasan: '',
                        status: '',
                    },
                });
                setLoading(false);
            },
            refetchOnWindowFocus: false,
        }
    );

    const { data: sumberDana } = useQuery('sumberDanaRekening', async () => {
        const res = await fetch(baseUrl + '/dropdowns?key=R_SUMBER_DANA_REKENING');

        const data = await res.json();

        return data.list;
    });

    const { data: tujuanBukaRekening } = useQuery('R_TUJUAN_BUKA_REKENING', async () => {
        const res = await fetch(baseUrl + '/dropdowns?key=R_TUJUAN_BUKA_REKENING');

        const data = await res.json();

        return data.list;
    });

    const { data: kelengkapanData } = useQuery('eStatusKelengkapan', async () => {
        const res = await fetch(baseUrl + '/dropdowns?key=eStatusKelengkapan');

        const data = await res.json();

        return data.list;
    });

    const { data: provinces } = useQuery('provinces', async () => {
        const res = await fetch(baseUrl + '/locations/provinces');

        const data = await res.json();

        return data.list;
    });

    const { data: giroProducts } = useQuery(['giro-products', form.accountHolderType], async () => {
        if (!form.accountHolderType) return [];

        const res = await fetch(baseUrl + '/rekening/tabungan/produk?limit=10&offset=0');

        const data = await res.json();

        if (form.accountHolderType === 'CLIENT') {
            const products = data.list?.filter(({ category }) => category === 'PERSONAL_DEPOSIT');
            return products;
        }

        const products = data.list?.filter(({ category }) => category === 'BUSINESS_DEPOSIT');
        return products;
    });

    const handleNext = () => {
        if (type !== 'view') {
            // const errSlide1 = {
            //     nomor_cif: nomorCif,
            //     productTypeKey: form.productTypeKey,
            //     namaRekening: form.namaRekening,
            //     infoLimitNominalSetorTunai: form._informasiLainKorporat.infoLimitNominalSetorTunai,
            //     infoLimitNominalSetorNontunai:
            //         form._informasiLainKorporat.infoLimitNominalSetorNontunai,
            //     infoLimitFrekuensiSetorTunai:
            //         form._informasiLainKorporat.infoLimitFrekuensiSetorTunai,
            //     infoLimitFrekuensiSetorNontunai:
            //         form._informasiLainKorporat.infoLimitFrekuensiSetorNontunai,
            // };

            // if (form.accountHolderType === 'CLIENT')
            //     errSlide1.tncVersion = form._informasiLain.tncVersion;

            // const { isAnyEmptyError, err } = getErrorEmptyValue(errSlide1);

            // const { isAnyEmptyError: isAnyErrSlide2, err: errSlide2 } = getErrorEmptyValue({
            //     sumberDana: form._informasiLain.sumberDana,
            //     tujuanPembukaanRekening: form._informasiLain.tujuanPembukaanRekening,
            //     kelengkapanData: form._informasiLain.kelengkapanData,
            // });

            // if (activeIdx === 0) {
            //     setError(err);
            // } else if (activeIdx === 1) {
            //     setError({
            //         ...err,
            //         ...errSlide2,
            //     });
            // }

            // if (isAnyEmptyError && activeIdx === 0) return;

            // if (isAnyErrSlide2 && activeIdx === 1) return;
        }

        if (activeIdx < 4) setActiveIdx((idx) => idx + 1);
    };

    const handlePrevious = () => {
        if (activeIdx > 0) setActiveIdx((idx) => idx - 1);
    };

    const handleReset = () => {
        setError({});

        if (activeIdx === 0) {
            setNomorCif('');
            setNasabah({
                nama: '',
                nomorTelepon: '',
                nomorHP: '',
                namaIbuKandung: '',
                tempatLahir: '',
                tanggalLahir: '',
                alamat: '',
            });

            setForm({
                ...form,
                nomorCIF: '',
                accountHolderKey: '',
                accountHolderType: '',
                namaRekening: '',
                produkGiro: '',
                productTypeKey: '',
                valuta: 'IDR',
                notes: '',
                _rekeningTabungan: {
                    dapatBonusBagiHasil: false,
                    blokirDebet: false,
                    cekNota: false,
                    dormant: false,
                    biayaAdministrasi: false,
                    biayaATM: false,
                    blokirKredit: false,
                    statusPassbook: false,
                    biayaRekeningDormant: false,
                    biayaSaldoMinimum: false,
                },
                _informasiLainKorporat: {
                    isInternetBanking: 'N',
                    isMobileBanking: 'N',
                    isSmsBanking: 'N',
                },
                _informasiLain: {
                    ...form._informasiLain,
                    tncVersion: '',
                    qqName: '',
                    biayaBiaya: '',
                    nisbahAkhir: Number(0),
                    nisbahCounter: Number(0),
                    nisbahZakat: Number(0),
                    nisbahPajak: Number(0),
                },
            });

            return;
        }

        if (activeIdx === 1) {
            setForm({
                ...form,
                _informasiLain: {
                    ...form._informasiLain,
                    sumberDana: '',
                    tujuanPembukaanRekening: '',
                    kelengkapanData: '',
                    marketingReferensi: '',
                    marketingPertama: '',
                    marketingSaatIni: '',
                },
                _asuransi: {
                    tanggalJatuhTempoAsuransi: '',
                    rencanaAsuransiUtama: '',
                    rencanaAsuransiTambahan: '',
                },
            });

            return;
        }

        if (activeIdx === 2) {
            setForm({
                ...form,
                _specimenPejabat: [],
            });

            return;
        }

        if (activeIdx === 3) {
            setForm({
                ...form,
                _hakAksesRekening: [],
            });

            return;
        }
    };

    const handleFormatNumber = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        const newValue = thousandSeparator.format(value);

        if (newValue !== 'NaN') {
            this.setState({
                [name]: newValue,
            });
        }
    };

    const handleChangeRekeningTabungan = (e) => {
        const { value, name, type, checked } = e.target;

        if (error[name]) setError({ ...error, [name]: false });

        if (type === 'checkbox') {
            setForm({
                ...form,
                _rekeningTabungan: {
                    ...form._rekeningTabungan,
                    [name]: checked,
                },
            });

            return;
        }

        const newForm = {
            ...form,
            _rekeningTabungan: {
                ...form._rekeningTabungan,
                [name]: value?.toUpperCase(),
            },
        };

        setForm(newForm);
    };

    const handleChangeInformasiLainKorporat = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            _informasiLainKorporat: {
                ...form._informasiLainKorporat,
                [name]: value?.toUpperCase(),
            },
        });
    };

    const handleChangeInformasiLain = (e) => {
        const { value, name } = e.target;

        if (error[name]) setError({ ...error, [name]: false });

        const newForm = {
            ...form,
            _informasiLain: {
                ...form._informasiLain,
                [name]: value?.toUpperCase(),
            },
        };

        const isNumber = !isNaN(value);

        if (['nisbahCounter', 'nisbahZakat', 'nisbahAkhir'].includes(name)) {
            if (!isNumber) {
                newForm._informasiLain[name] = form._informasiLain[name];
            } else if (
                (value[value.length - 1] === '.' &&
                    !form._informasiLain[name].toString().includes('.')) ||
                value[value.length - 1] === '0'
            ) {
                newForm._informasiLain[name] = value;
            } else if (value > 100) {
                newForm._informasiLain[name] = 100;
            } else {
                newForm._informasiLain[name] = Number(value);
            }
        }

        setForm(newForm);
    };

    // const handleChangeAsuransi = (e) => {
    //     const { value, name } = e.target;

    //     setForm({
    //         ...form,
    //         _asuransi: {
    //             ...form._asuransi,
    //             [name]: value,
    //         },
    //     });
    // };

    const handleChangeRootForm = (e) => {
        const { value, name } = e.target;

        if (error[name]) setError({ ...error, [name]: false });

        setForm({ ...form, [name]: value?.toUpperCase() });
    };

    const handleSearchNasabah = useMutation(
        async ({ e, id }) => {
            e?.preventDefault();
            const res = await fetch(baseUrl + `/nasabah/perorangan/find/${id}`);
            const resGroup = await fetch(baseUrl + `/nasabah-badan-usaha/find/${id}`);

            const data = await res.json();
            const dataGroup = await resGroup.json();

            if (
                (data.message_id === '01' && dataGroup.message_id === '01') ||
                (data?.response_code === '404' && dataGroup?.response_code === '404')
            ) {
                setError({ ...error, nomor_cif: true });
                throw new Error('Nomor CIF not found');
            }

            if (data?.list) {
                const {
                    firstName,
                    lastName,
                    middleName,
                    _ktpAddress,
                    homePhone,
                    mobilePhone,
                    encodedKey,
                    birthDate,
                    _customerData,
                } = data.list;
                const { ktpAddress, ktpCity, ktpProvince, ktpPostalCode } = _ktpAddress;

                const nama = joinDefinedString(firstName, middleName, lastName).separateWith(' ');

                setNasabah({
                    nama,
                    alamat: joinDefinedString(
                        ktpAddress,
                        ktpCity,
                        ktpProvince,
                        ktpPostalCode
                    ).separateWith(', '),
                    namaIbuKandung: _customerData?.personalMothersName,
                    nomorHP: mobilePhone,
                    nomorTelepon: homePhone || mobilePhone,
                    tanggalLahir: birthDate,
                    tempatLahir: _customerData?.birthPlace,
                });

                if (!type)
                    setForm({
                        ...form,
                        nomorCIF: nomorCif,
                        accountHolderKey: encodedKey,
                        accountHolderType: 'CLIENT',
                        namaRekening: nama.toUpperCase(),
                    });

                return data?.list;
            }

            if (dataGroup?.list) {
                const { groupName, _identitasNasabah, encodedKey, _pejabatKorporat } =
                    dataGroup.list;
                const {
                    nasabahAlamat,
                    nasabahKotaKabupaten,
                    nasabahProvinsi,
                    nasabahKodePos,
                    nasabahTeleponKantorNomor1 = '',
                    nasabahTanggalAktePendirian = '',
                    nasabahNomorNpwp,
                } = _identitasNasabah;

                setNasabah({
                    nama: groupName,
                    alamat: joinDefinedString(
                        nasabahAlamat,
                        nasabahKotaKabupaten,
                        nasabahProvinsi,
                        nasabahKodePos
                    ).separateWith(', '),
                    namaIbuKandung: '',
                    nomorHP: '',
                    nomorTelepon: nasabahTeleponKantorNomor1,
                    tanggalLahir: nasabahTanggalAktePendirian,
                    tempatLahir: '',
                    npwp: nasabahNomorNpwp,
                    specimenPejabat: _pejabatKorporat ?? [],
                });

                if (!type)
                    setForm({
                        ...form,
                        nomorCIF: nomorCif,
                        accountHolderKey: encodedKey,
                        accountHolderType: 'GROUP',
                        namaRekening: groupName.toUpperCase(),
                    });

                return dataGroup?.list;
            }
        },
        {
            onError: () => {
                setNasabah({
                    nama: '',
                    nomorTelepon: '',
                    nomorHP: '',
                    namaIbuKandung: '',
                    tempatLahir: '',
                    tanggalLahir: '',
                    alamat: '',
                });
                setForm({
                    ...form,
                    nomorCIF: '',
                });
            },
        }
    );

    const handleRegister = useMutation(
        async () => {
            const body = { ...form };
            if (form.accountHolderType === 'CLIENT') {
                body._dataNasabah = {};
            } else if (form.accountHolderType === 'GROUP') {
                body._dataNasabah = {
                    telpPerusahaan: nasabah.nomorTelepon,
                    tanggalBerdiri: nasabah.tanggalLahir,
                    alamatPerusahaan: nasabah.alamat,
                    npwp: nasabah.npwp,
                };
            }

            body._hakAksesRekening =
                form._hakAksesRekening.length > 0 ? form._hakAksesRekening[0] : {};
            delete body._asuransi;

            body._informasiLain.nisbahCounter = Number(body._informasiLain.nisbahCounter);
            body._informasiLain.nisbahZakat = Number(body._informasiLain.nisbahZakat);

            if (type === 'edit') {
                const pathname =
                    mode === 'approved'
                        ? `/rekening/tabungan/approve?nomor_rekening=${form.id}`
                        : `/rekening/tabungan/pending?nomor_cif=${nomorCif}&nama_rekening=${form.namaRekening}`;

                const res = await fetch(baseUrl + pathname, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: JSON.stringify(body),
                });

                const data = await res.json();

                if (data.message_id === '02') {
                    toast.error('Something went wrong', {
                        position: 'top-right',
                        pauseOnHover: false,
                        pauseOnFocusLoss: false,
                    });

                    throw new Error('Something went wrong');
                }

                return data.list;
            }

            //Registrasi nomor cif baru (dania)
            body.nomorCIF = nomorCifSelect;
            const res = await fetch(baseUrl + '/rekening/tabungan/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(body),
            });

            const data = await res.json();

            if (data.message_id === '02') {
                toast.error('Something went wrong', {
                    position: 'top-right',
                    pauseOnHover: false,
                    pauseOnFocusLoss: false,
                });

                throw new Error('Something went wrong');
            }
        },
        {
            onSuccess: () => {
                setConfirm(false);
                if (mode === 'approved') history.replace('/tabungan-giro/rekening/');
                else history.replace('/tabungan-giro/rekening/pending');
            },
        }
    );

    const handleSubmitSpecimenPejabat = (value) => {
        const newForm = { ...form, _specimenPejabat: [...form._specimenPejabat] };
        if (tipeSpecimenPejabat === 'registrasi') {
            newForm._specimenPejabat.push(value);
        }

        if (tipeSpecimenPejabat === 'edit') {
            newForm._specimenPejabat[specimenPejabatState[1]] = value;
        }

        setForm(newForm);
    };

    const handleSubmitHakAkses = (value) => {
        const newForm = { ...form, _hakAksesRekening: [...form._hakAksesRekening] };
        if (tipeRelasiNasabah === 'tambah') {
            newForm._hakAksesRekening.push(value);
        }

        if (tipeRelasiNasabah === 'edit') {
            newForm._hakAksesRekening[relasiNasabahState[1]] = value;
        }

        setForm(newForm);
    };

    const [listNasabahSearch, setListSearchNasabah] = useState([]);
    const [jenisPencarian, setJenisPencarian] = useState('name');
    const [typeNasabah, setTypeNasabah] = useState('CLIENT');
    const [keyword, setKeyword] = useState('');

    const handleSearch = async () => {
        //http://localhost:8401/api/v1/nasabah/filter?searchBy=name&search=wadi&type=CLIENT
        const jenis_pencarian = jenisPencarian !== '' ? '&searchBy=' + jenisPencarian : '';
        const type_nasabah = typeNasabah !== '' ? '&type=' + typeNasabah : '';
        const pathname = `/nasabah/filter?search=${keyword}${jenis_pencarian}${type_nasabah}`;
        const res = await fetch(baseUrl + pathname, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        const data = await res.json();
        setListSearchNasabah(data?.list);
    };

    return (
        <>
            <Modal centered show={handleRegister.isSuccess}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Selesai</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Proses {type === 'edit' ? 'ubah' : 'registrasi'} rekening tabungan berhasil
                        dilakukan
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => handleRegister.reset()}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Selesai
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={confirm}>
                <Modal.Header onHide={() => setConfirm(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Konfirmasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="tw-text-f16 tw-py-8 tw-text-center">
                        Lanjutkan proses {type === 'edit' ? 'ubah' : 'registrasi'} rekening
                        tabungan?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setConfirm(false)}
                        className="text-white mr-2"
                        size="mini"
                    >
                        Tidak
                    </Button>
                    <Button
                        loading={handleRegister.isLoading}
                        onClick={() => handleRegister.mutate()}
                        className="tw-w-16"
                        size="mini"
                        color="red"
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalHapus} onHide={() => setShowModalHapus(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Konfirmasi</Modal.Title>
                </Modal.Header>
                <Modal.Body>Apakah Anda yakin akan menghapus data ini?</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowModalHapus(false)}>
                        Tidak
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            if (indexType === 'specimen') {
                                const newForm = {
                                    ...form,
                                    _specimenPejabat: [...form._specimenPejabat],
                                };
                                newForm._specimenPejabat.splice(indexHapus, 1);
                                setForm(newForm);
                            } else {
                                const newForm = {
                                    ...form,
                                    _hakAksesRekening: [...form._hakAksesRekening],
                                };
                                newForm._hakAksesRekening.splice(indexHapus, 1);
                                setForm(newForm);
                            }
                            setShowModalHapus(false);
                            setIndexType('');
                            setIndexHapus(null);
                        }}
                    >
                        Ya
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showModalCariNasabah}
                onHide={() => setShowModalCariNasabah(false)}
                // backdrop="static"
                // keyboard={false}
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Pencarian Data Nasabah</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-8">
                            <p>Cari Berdasarkan:</p>
                            <div className="form-row">
                                <div className="form-group col-3">
                                    <select
                                        className="form-control"
                                        onChange={(e) => setJenisPencarian(e.target.value)}
                                        value={jenisPencarian}
                                    >
                                        <option hidden disabled selected value="">
                                            Jenis Pencarian
                                        </option>
                                        <option value="CIF">Nomor CIF</option>
                                        <option value="name">Nama Nasabah</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <select
                                        className="form-control"
                                        onChange={(e) => setTypeNasabah(e.target.value)}
                                        value={typeNasabah}
                                    >
                                        <option hidden disabled selected value="">
                                            Type Nasabah
                                        </option>
                                        <option value="CLIENT">Perorangan</option>
                                        <option value="GROUP">Badan Usaha</option>
                                    </select>
                                </div>
                                <div className="form-group col-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Keyword"
                                        onChange={(e) => setKeyword(e.target.value)}
                                        value={keyword}
                                    />
                                </div>
                                <div className="form-group col-2">
                                    <button className="btn btn-primary" onClick={handleSearch}>
                                        Cari
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TableGrid
                        onRenderField={(value, field, { className, data }) => {
                            if (field === 'action')
                                return (
                                    <div key={`${value}-${field}`} className={className}>
                                        <ActionMore
                                            config={{
                                                minWidth: '120px',
                                            }}
                                            actions={[
                                                {
                                                    label: 'Pilih Nasabah',
                                                    onClick: () => {
                                                        setNomorCif(data?.nomor_cif);
                                                        setForm({
                                                            ...form,
                                                            nomorCIF: data?.nomor_cif,
                                                        });
                                                        setNomorCifSelect(data?.nomor_cif);
                                                        setShowModalCariNasabah(false);
                                                        handleSearchNasabah.mutate({
                                                            id: data?.nomor_cif,
                                                        });
                                                    },
                                                },
                                            ]}
                                        ></ActionMore>
                                    </div>
                                );
                        }}
                        columns={[
                            {
                                name: 'Nomor CIF',
                                field: 'nomor_cif',
                            },
                            {
                                name: 'Nama CIF',
                                field: 'nama',
                            },
                            {
                                name: 'Alamat',
                                field: 'alamat',
                            },
                            {
                                name: 'Jenis Nasabah',
                                field: 'jenis_nasabah',
                            },
                            {
                                name: '',
                                field: 'action',
                                width: '60px',
                            },
                        ]}
                        data={listNasabahSearch}
                        // isLoading={!isFetchedAfterMount}
                        config={{
                            height: '55vh',
                            overflowY: 'auto',
                            stickyHead: true,
                            //tableMinWidth: '300px',
                        }}
                    ></TableGrid>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModalCariNasabah(false)}>Batal</Button>
                </Modal.Footer>
            </Modal>
            <HakAksesDataModal
                show={modal.hakAksesData}
                onHide={() => setModal({ ...modal, hakAksesData: false })}
                type={tipeRelasiNasabah}
                initialState={relasiNasabahState[0]}
                onSubmit={handleSubmitHakAkses}
            />
            <SpecimenPejabatModal
                type={tipeSpecimenPejabat}
                show={modal.specimenPejabat}
                onHide={() => {
                    setModal({ ...modal, specimenPejabat: false });
                }}
                initialState={type === 'view' ? nomorCif : nomorCifSelect}
                onSubmit={handleSubmitSpecimenPejabat}
            />
            <Breadcrumb className="mb-5">
                <Breadcrumb.Section className="text-primary-1">Tabungan & Giro</Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">
                    {!type ? 'Registrasi Rekening' : 'Data Rekening'}{' '}
                </Breadcrumb.Section>
                <Breadcrumb.Divider className="mx-2" />
                <Breadcrumb.Section className="text-gray">
                    {type === 'view'
                        ? 'Detail Rekening'
                        : type === 'edit'
                        ? 'Ubah Data'
                        : 'Tabungan Rencana'}{' '}
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="bg-white tw-rounded-lg tw-shadow-md">
                <div
                    style={{ height: 73 }}
                    className="d-flex justify-content-center tw-bg-white-10"
                >
                    <Stepper
                        style={{ marginTop: -12 }}
                        steps={[
                            {
                                name: 'Data Rekening',
                                id: '0',
                                whiteSpace: 'nowrap',
                                isDone: type === 'view' || type === 'edit',
                            },
                            {
                                name: 'Informasi Lain',
                                id: '1',
                                whiteSpace: 'nowrap',
                                isDone: type === 'view' || type === 'edit',
                            },
                            // {
                            //     name: 'Specimen Pejabat',
                            //     id: '2',
                            //     whiteSpace: 'nowrap',
                            //     isDone: type === 'view' || type === 'edit',
                            // },
                            // {
                            //     name: 'Hak Akses Rekening',
                            //     id: '3',
                            //     whiteSpace: 'nowrap',
                            //     isDone: type === 'view' || type === 'edit',
                            // },
                        ]}
                        manualDone={type === 'view' || type === 'edit'}
                        activeIdx={activeIdx}
                        onStepClick={(idx) => {
                            if (type !== 'view') {
                                const errSlide1 = {
                                    nomor_cif: nomorCif,
                                    productTypeKey: form.productTypeKey,
                                    namaRekening: form.namaRekening,
                                };

                                if (form.accountHolderType === 'CLIENT')
                                    errSlide1.tncVersion = form._informasiLain.tncVersion;

                                const { isAnyEmptyError, err } = getErrorEmptyValue(errSlide1);

                                const { isAnyEmptyError: isAnyErrSlide2, err: errSlide2 } =
                                    getErrorEmptyValue({
                                        sumberDana: form._informasiLain.sumberDana,
                                        tujuanPembukaanRekening:
                                            form._informasiLain.tujuanPembukaanRekening,
                                        kelengkapanData: form._informasiLain.kelengkapanData,
                                    });

                                if (activeIdx === 0) {
                                    setError(err);
                                } else if (activeIdx === 1) {
                                    setError({
                                        ...err,
                                        ...errSlide2,
                                    });
                                }

                                if (activeIdx < idx) {
                                    if (isAnyEmptyError && activeIdx === 0) return;
                                    if (isAnyErrSlide2 && activeIdx === 1) return;
                                }
                            }

                            setActiveIdx(idx);
                        }}
                    />
                </div>
                {[0, 1].includes(activeIdx) && (
                    <div className="px-5 py-4">
                        {activeIdx === 0 && (
                            <>
                                {type === 'view' ? (
                                    <SummaryCard
                                        namaLengkap={form.namaRekening}
                                        valuta={form.valuta}
                                        nameProduct={form.produkGiro}
                                        status={form.status}
                                        isLoading={isLoading}
                                        saldo={form.saldo || 0}
                                        nomorRekening={form.nomorRekening || '-'}
                                        cabang={`${nasabah?.cabang?.id ?? ''} - ${
                                            nasabah?.cabang?.name ?? ''
                                        }`.trim()}
                                    />
                                ) : (
                                    <div className="p-4 border border-gray rounded-lg">
                                        <div className="d-flex align-items-end justify-content-between">
                                            <div className="d-flex flex-column w-25">
                                                <span className="f-12 mb-2">Nomor Rekening</span>
                                                <FormControl
                                                    size="sm"
                                                    disabled
                                                    placeholder="Terisi otomatis dari sistem"
                                                />
                                            </div>
                                            <div className="d-flex align-items-center f-12">
                                                <span className="text-black-70 mr-1">
                                                    Tgl. Buka :
                                                </span>
                                                <span className="mr-4">
                                                    {dayjs().format('DD MMMM YYYY')}
                                                </span>
                                                <span className="text-black-70 mr-1">
                                                    Kode Cabang :
                                                </span>
                                                <span>0000</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <FormHeader className="tw-my-4">Data Nasabah</FormHeader>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Nomor CIF
                                            <Label className="ml-2" size="mini">
                                                Wajib
                                            </Label>
                                        </Form.Label>
                                        <Form
                                            onSubmit={(e) =>
                                                // handleSearchNasabah.mutate({ e, id: nomorCif })
                                                handleSearchNasabah.mutate({ e, id: form.nomorCIF })
                                            }
                                            noValidate
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 140px',
                                                gap: 18,
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <Form.Group bsPrefix=" ">
                                                <Form.Control
                                                    onChange={(e) => {
                                                        // if (error.nomor_cif) {
                                                        //     setError({
                                                        //         ...error,
                                                        //         nomor_cif: false,
                                                        //     });
                                                        // }
                                                        setNomorCif(e.target.value);
                                                        setForm({
                                                            ...form,
                                                            nomorCIF: e.target.value,
                                                        });
                                                    }}
                                                    size="sm"
                                                    placeholder="Masukkan nomor CIF"
                                                    value={nomorCif}
                                                    //value={form.nomorCIF}
                                                    required
                                                    // isInvalid={error.nomor_cif}
                                                    // disabled={type === 'view' || type === 'edit'}
                                                    disabled="true"
                                                />
                                                {error.nomor_cif && !nomorCif && (
                                                    <Form.Control.Feedback size="sm" type="invalid">
                                                        Field Required!
                                                    </Form.Control.Feedback>
                                                )}
                                                {/* {error.nomor_cif && nomorCif && (
                                                    <Form.Control.Feedback size="sm" type="invalid">
                                                        Nasabah tidak ditemukan!
                                                    </Form.Control.Feedback>
                                                )} */}
                                            </Form.Group>
                                            <Button
                                                type="submit"
                                                //loading={handleSearchNasabah.isLoading}
                                                size="mini"
                                                color="red"
                                                disabled={type === 'view' || type === 'edit'}
                                                onClick={() => setShowModalCariNasabah(true)}
                                            >
                                                Cari Nasabah
                                            </Button>
                                        </Form>
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            {form.accountHolderType === 'GROUP'
                                                ? 'Nomor NPWP'
                                                : 'Nama Ibu Kandung'}
                                        </Form.Label>
                                        <Form.Control
                                            value={
                                                form.accountHolderType === 'GROUP'
                                                    ? nasabah.npwp
                                                    : nasabah.namaIbuKandung
                                            }
                                            disabled
                                            size="sm"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Nama
                                        </Form.Label>
                                        <Form.Control disabled size="sm" value={nasabah.nama} />
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            {form.accountHolderType === 'GROUP'
                                                ? 'Tanggal Pendirian'
                                                : 'Tempat / Tanggal Lahir'}
                                        </Form.Label>
                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns:
                                                    form.accountHolderType === 'GROUP'
                                                        ? '1fr'
                                                        : '2fr 24px 1fr',
                                            }}
                                        >
                                            {(form.accountHolderType === 'CLIENT' ||
                                                form.accountHolderType === '') && (
                                                <>
                                                    <Form.Control
                                                        disabled
                                                        size="sm"
                                                        value={nasabah.tempatLahir}
                                                    />
                                                    <div
                                                        style={{ lineHeight: '' }}
                                                        className="f-18 text-center"
                                                    >
                                                        /
                                                    </div>
                                                </>
                                            )}

                                            <Form.Control
                                                value={nasabah.tanggalLahir}
                                                disabled
                                                size="sm"
                                                type="date"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label className="font-weight-normal f-12">
                                                    No. Telepon
                                                </Form.Label>
                                                <Form.Control
                                                    value={nasabah.nomorTelepon}
                                                    disabled
                                                    size="sm"
                                                />
                                            </Col>
                                            {/* {form.accountHolderKey === 'CLIENT' && ( */}
                                                <Col>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        No. Handphone
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        size="sm"
                                                        value={nasabah.nomorHP}
                                                    />
                                                </Col>
                                            {/* )} */}
                                        </Row>
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Form.Label className="font-weight-normal f-12">
                                            Alamat
                                        </Form.Label>
                                        <Form.Control value={nasabah.alamat} disabled size="sm" />
                                    </Col>
                                </Row>
                                <FormHeader className="mb-4">Rekening</FormHeader>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Produk Tabungan
                                                {/* <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label> */}
                                            </Form.Label>
                                            <Form.Control
                                                name="productTypeKey"
                                                onChange={(e) => {
                                                    const { value, name } = e.target;

                                                    if (error[name])
                                                        setError({ ...error, [name]: false });

                                                    const produk = giroProducts.find(
                                                        ({ encodedKey }) => encodedKey === value
                                                    );

                                                    const {
                                                        feesSettings: { fees },
                                                    } = produk;

                                                    const nisbahZakat = fees.find(
                                                        ({ name }) => name === 'ZAKAT'
                                                    );
                                                    const nisbahPajak = fees.find(
                                                        ({ name }) => name === 'PAJAK'
                                                    );

                                                    const nisbahCounter = fees.find(
                                                        ({ name }) => name === 'Nisbah Nasabah'
                                                    );

                                                    const nisbahAll = fees.filter(({ name }) =>
                                                        name?.toLowerCase().includes('nisbah')
                                                    );

                                                    setForm({
                                                        ...form,
                                                        produkGiro: produk.name,
                                                        productTypeKey: produk.encodedKey,
                                                        _informasiLain: {
                                                            ...form._informasiLain,
                                                            nisbahZakat: nisbahZakat.amount,
                                                            nisbahPajak: nisbahPajak.amount,
                                                            nisbahCounter: nisbahCounter.amount,
                                                            nisbahAkhir: 0,
                                                        },
                                                    });
                                                }}
                                                size="sm"
                                                as="select"
                                                value={form.productTypeKey}
                                                // isInvalid={error?.productTypeKey}
                                                disabled={type === 'view'}
                                            >
                                                <option value="" selected disabled hidden>
                                                    Pilih produk tabungan
                                                </option>
                                                {giroProducts?.map(({ encodedKey, name }) => (
                                                    <option
                                                        onClick={() => {}}
                                                        key={encodedKey}
                                                        value={encodedKey}
                                                    >
                                                        {name?.toUpperCase()}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            {/* <Form.Control.Feedback size="sm" type="invalid">
                                                Produk tabungan wajib diisi
                                            </Form.Control.Feedback> */}
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12 mb-4"
                                                    type="checkbox"
                                                    label="Dapat Bonus/ Bagi Hasil"
                                                    checked={form._rekeningTabungan?.blokirDebet}
                                                    name="dapatbagi"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Nisbah Counter
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            value={
                                                                form._informasiLain?.nisbahCounter
                                                            }
                                                            onChange={handleChangeInformasiLain}
                                                            size="sm"
                                                            placeholder="0"
                                                            name="nisbahCounter"
                                                            disabled
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Zakat Bagi Hasil
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            value={form._informasiLain?.nisbahZakat}
                                                            name="nisbahZakat"
                                                            size="sm"
                                                            placeholder="0"
                                                            onChange={handleChangeInformasiLain}
                                                            disabled
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Nama Rekening
                                                {/* <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label> */}
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                placeholder="Masukan nama untuk rekening"
                                                name="namaRekening"
                                                value={form.namaRekening}
                                                onChange={handleChangeRootForm}
                                                // isInvalid={error?.namaRekening}
                                                disabled
                                            />
                                            {/* <Form.Control.Feedback size="sm" type="invalid">
                                                Nama rekening wajib diisi
                                            </Form.Control.Feedback> */}
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Pajak Bagi Hasil
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            value={form._informasiLain?.nisbahPajak}
                                                            disabled
                                                            size="sm"
                                                            placeholder="0"
                                                            name="nisbahPajak"
                                                            onChange={handleChangeInformasiLain}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Nisbah Akhir
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            disabled
                                                            size="sm"
                                                            placeholder="0"
                                                            name="nisbahAkhir"
                                                            value={form._informasiLain?.nisbahAkhir}
                                                            onChange={handleChangeInformasiLain}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Nomor Rekening Bagi Hasil
                                                    </Form.Label>
                                                    <Form
                                                        onSubmit={(e) =>
                                                            // handleSearchNasabah.mutate({ e, id: nomorCif })
                                                            handleSearchNasabah.mutate({ e, id: form.nomorCIF })
                                                        }
                                                        noValidate
                                                        style={{
                                                            display: 'grid',
                                                            gridTemplateColumns: '1fr 140px',
                                                            gap: 18,
                                                            alignItems: 'flex-start',
                                                        }}
                                                    >
                                                        <Form.Group bsPrefix=" ">
                                                            <Form.Control
                                                                onChange={(e) => {
                                                                    setNomorCif(e.target.value);
                                                                    setForm({
                                                                        ...form,
                                                                        nomorCIF: e.target.value,
                                                                    });
                                                                }}
                                                                size="sm"
                                                                placeholder="Masukkan Nomor Rekening Bagi Hasil"
                                                                value={nomorCif}
                                                                //value={form.nomorCIF}
                                                                // isInvalid={error.nomor_cif}
                                                                // disabled={type === 'view' || type === 'edit'}
                                                                disabled="true"
                                                            />
                                                        </Form.Group>
                                                        <Button
                                                            type="submit"
                                                            size="mini"
                                                            color="red"
                                                            disabled={type === 'view' || type === 'edit'}
                                                            onClick={() => setShowModalCariNasabah(true)}
                                                        >
                                                            Cari Nasabah
                                                        </Button>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col}>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Nama Pemilik Rekening Bagi Hasil
                                                </Form.Label>
                                                <Form.Control
                                                    disabled
                                                    size="sm"
                                                    placeholder="Nama Pemilik Rekening Bagi Hasil"
                                                    value=""
                                                />
                                                </Form.Group>
                                            </Row>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Valuta
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                size="sm"
                                                placeholder="Terisi otomatis"
                                                value={form.valuta}
                                            />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}></Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <div className="tw-mb-5">
                                                <Form.Label className="font-weight-normal f-12">
                                                    Keterangan
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Contoh: Tabungan Penerima"
                                                    name="notes"
                                                    value={form.notes}
                                                    onChange={handleChangeRootForm}
                                                    disabled={type === 'view'}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Alamat Statement / Passbook
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    disabled={type === 'view'}
                                                    as="select"
                                                >
                                                    <option selected hidden disabled>
                                                        Pilih alamant statement / passbook
                                                    </option>
                                                </Form.Control>
                                            </div>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Blokir Debet"
                                                    checked={form._rekeningTabungan?.blokirDebet}
                                                    name="blokirDebet"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Blokir Kredit"
                                                    checked={form._rekeningTabungan?.blokirKredit}
                                                    name="blokirKredit"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Cek Nota"
                                                    checked={form._rekeningTabungan?.cekNota}
                                                    name="cekNota"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Status Passbook"
                                                    checked={form._rekeningTabungan?.statusPassbook}
                                                    name="statusPassbook"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Dormant"
                                                    checked={form._rekeningTabungan?.dormant}
                                                    name="dormant"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya Rekening Dormant"
                                                    checked={
                                                        form._rekeningTabungan?.biayaRekeningDormant
                                                    }
                                                    name="biayaRekeningDormant"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya Administrasi"
                                                    checked={
                                                        form._rekeningTabungan?.biayaAdministrasi
                                                    }
                                                    name="biayaAdministrasi"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya Saldo Minimum"
                                                    checked={
                                                        form._rekeningTabungan?.biayaSaldoMinimum
                                                    }
                                                    name="biayaSaldoMinimum"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                                <Form.Check
                                                    className="tw-font-bold tw-text-12"
                                                    type="checkbox"
                                                    label="Biaya ATM"
                                                    checked={form._rekeningTabungan?.biayaATM}
                                                    name="biayaATM"
                                                    onChange={handleChangeRekeningTabungan}
                                                    disabled={type === 'view'}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    <div
                                        style={{ gap: '0 111px' }}
                                        className="tw-grid tw-grid-cols-2 tw-grid-rows-2"
                                    >
                                        <div className="tw-grid tw-gap-5 tw-row-span-3">
                                            {/* <div>
                                                <Form.Label className="font-weight-normal f-12">
                                                    QQ Name
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Nama Alias"
                                                    disabled={type === 'view'}
                                                    onChange={handleChangeInformasiLain}
                                                    name="qqName"
                                                    value={form._informasiLain?.qqName}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Biaya-Biaya
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Biaya-Biaya"
                                                    disabled={type === 'view'}
                                                    onChange={handleChangeInformasiLain}
                                                    name="biayaBiaya"
                                                    value={form._informasiLain?.biayaBiaya}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label className="font-weight-normal f-12">
                                                    TnC{' '}
                                                    {form.accountHolderType === 'CLIENT' && (
                                                        <Label className="ml-2" size="mini">
                                                            Wajib
                                                        </Label>
                                                    )}
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Versi TnC"
                                                    disabled={type === 'view'}
                                                    name="tncVersion"
                                                    onChange={handleChangeInformasiLain}
                                                    value={form._informasiLain?.tncVersion}
                                                    isInvalid={error.tncVersion}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Tnc version wajib diisi
                                                </Form.Control.Feedback>
                                                <Gap width={111} />
                                            </div> */}
                                        </div>
                                        {/* <div className="tw-grid tw-grid-cols-2 tw-row-span-2 tw-gap-y-5 tw-gap-x-7 tw-px-2">
                                            <div className="tw-grid tw-grid-cols-2">
                                                <Form.Label className="font-weight-normal f-12 tw-col-span-2">
                                                    Internet Banking
                                                </Form.Label>
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="Yes"
                                                    disabled={type === 'view'}
                                                    value="Y"
                                                    name="isInternetBanking"
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    checked={
                                                        form._informasiLainKorporat
                                                            ?.isInternetBanking === 'Y'
                                                    }
                                                />
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="No"
                                                    disabled={type === 'view'}
                                                    value="N"
                                                    name="isInternetBanking"
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    checked={
                                                        form._informasiLainKorporat
                                                            ?.isInternetBanking === 'N'
                                                    }
                                                />
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2">
                                                <Form.Label className="font-weight-normal f-12 tw-col-span-2">
                                                    SMS Banking
                                                </Form.Label>
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="Yes"
                                                    disabled={type === 'view'}
                                                    value="Y"
                                                    name="isSmsBanking"
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    checked={
                                                        form._informasiLainKorporat
                                                            ?.isSmsBanking === 'Y'
                                                    }
                                                />
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="No"
                                                    disabled={type === 'view'}
                                                    value="N"
                                                    name="isSmsBanking"
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    checked={
                                                        form._informasiLainKorporat
                                                            ?.isSmsBanking === 'N'
                                                    }
                                                />
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2">
                                                <Form.Label className="font-weight-normal f-12 tw-col-span-2">
                                                    Mobile Banking
                                                </Form.Label>
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="Yes"
                                                    disabled={type === 'view'}
                                                    value="Y"
                                                    name="isMobileBanking"
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    checked={
                                                        form._informasiLainKorporat
                                                            ?.isMobileBanking === 'Y'
                                                    }
                                                />
                                                <Form.Check
                                                    className="tw-text-12"
                                                    type="radio"
                                                    label="No"
                                                    disabled={type === 'view'}
                                                    value="N"
                                                    name="isMobileBanking"
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    checked={
                                                        form._informasiLainKorporat
                                                            ?.isMobileBanking === 'N'
                                                    }
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                </Form>
                                <Gap width={111} />
                                {/* <FormHeader className="tw-my-4">Limit Transaksi</FormHeader>
                                <Row className="mb-4">
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Limit Nominal Setor Tunai
                                                    <Label className="ml-2" size="mini">
                                                        Wajib
                                                    </Label>
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Limit Nominal Setor Tunai"
                                                    disabled={type === 'view'}
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    name="infoLimitNominalSetorTunai"
                                                    isInvalid={error?.infoLimitNominalSetorTunai}
                                                    value={
                                                        form._informasiLainKorporat
                                                            ?.infoLimitNominalSetorTunai
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Limit Nominal Setor Tunai wajib diisi
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Limit Frekuensi Setor Tunai
                                                    <Label className="ml-2" size="mini">
                                                        Wajib
                                                    </Label>
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Limit Frekuensi Setor Tunai"
                                                    disabled={type === 'view'}
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    name="infoLimitFrekuensiSetorTunai"
                                                    isInvalid={error?.infoLimitFrekuensiSetorTunai}
                                                    value={
                                                        form._informasiLainKorporat
                                                            ?.infoLimitFrekuensiSetorTunai
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Limit Frekuensi Setor Tunai wajib diisi
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Limit Nominal Setor Non Tunai
                                                    <Label className="ml-2" size="mini">
                                                        Wajib
                                                    </Label>
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Limit Nominal Setor Non Tunai"
                                                    disabled={type === 'view'}
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    name="infoLimitNominalSetorNontunai"
                                                    isInvalid={error?.infoLimitNominalSetorNontunai}
                                                    value={
                                                        form._informasiLainKorporat
                                                            ?.infoLimitNominalSetorNontunai
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Limit Nominal Setor Non Tunai wajib diisi
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Gap width={111} />
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Limit Frekuensi Non Tunai
                                                    <Label className="ml-2" size="mini">
                                                        Wajib
                                                    </Label>
                                                </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    placeholder="Limit Frekuensi Setor Non Tunai"
                                                    disabled={type === 'view'}
                                                    onChange={handleChangeInformasiLainKorporat}
                                                    name="infoLimitFrekuensiSetorNontunai"
                                                    isInvalid={
                                                        error?.infoLimitFrekuensiSetorNontunai
                                                    }
                                                    value={
                                                        form._informasiLainKorporat
                                                            ?.infoLimitFrekuensiSetorNontunai
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Limit Frekuensi Non Tunai wajib diisi
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row> */}
                                <Form></Form>
                            </>
                        )}
                        {activeIdx === 1 && (
                            <>
                                <FormHeader className="mb-4">Informasi Lain</FormHeader>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Sumber Dana
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control
                                                value={form._informasiLain?.sumberDana?.toUpperCase()}
                                                onChange={handleChangeInformasiLain}
                                                name="sumberDana"
                                                size="sm"
                                                as="select"
                                                isInvalid={error?.sumberDana}
                                                disabled={type === 'view'}
                                            >
                                                <option value="" selected disabled hidden>
                                                    Pilih Sumber Dana
                                                </option>
                                                {sumberDana?.map(({ reference_desc, id }) => (
                                                    <option
                                                        key={id}
                                                        value={reference_desc?.toUpperCase()}
                                                    >
                                                        {reference_desc}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Control.Feedback size="sm" type="invalid">
                                                Sumber dana wajib diisi
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Marketing Referensi
                                            </Form.Label>
                                            <div className="tw-grid tw-grid-cols-3 tw-gap-x-5">
                                                <Form.Control
                                                    value={form._informasiLain.marketingReferensi}
                                                    onChange={handleChangeInformasiLain}
                                                    name="marketingReferensi"
                                                    size="sm"
                                                    disabled={type === 'view'}
                                                    className="tw-col-span-2"
                                                />
                                                {/* <Form.Control
                                                    value={form._informasiLain.marketingReferensi}
                                                    onChange={handleChangeInformasiLain}
                                                    name="marketingReferensi"
                                                    size="sm"
                                                    disabled
                                                /> */}
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Tujuan Pembukaan Rekening
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control
                                                value={form._informasiLain.tujuanPembukaanRekening?.toUpperCase()}
                                                onChange={handleChangeInformasiLain}
                                                name="tujuanPembukaanRekening"
                                                isInvalid={error?.tujuanPembukaanRekening}
                                                size="sm"
                                                as="select"
                                                disabled={type === 'view'}
                                            >
                                                <option value="" selected disabled hidden>
                                                    Pilih Tujuan Pembukaan Rekening
                                                </option>
                                                {tujuanBukaRekening?.map(
                                                    ({ reference_desc, id }) => (
                                                        <option
                                                            key={id}
                                                            value={reference_desc.toUpperCase()}
                                                        >
                                                            {reference_desc}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Control>
                                            <Form.Control.Feedback size="sm" type="invalid">
                                                Tujuan pembukaan rekening wajib diisi
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Marketing Pertama
                                            </Form.Label>
                                            <div className="tw-grid tw-grid-cols-3 tw-gap-5">
                                                <Form.Control
                                                    value={form._informasiLain.marketingPertama}
                                                    onChange={handleChangeInformasiLain}
                                                    name="marketingPertama"
                                                    size="sm"
                                                    disabled={type === 'view'}
                                                    className="tw-col-span-2"
                                                />
                                                {/* <Form.Control
                                                    value={form._informasiLain.marketingPertama}
                                                    onChange={handleChangeInformasiLain}
                                                    name="marketingPertama"
                                                    size="sm"
                                                    disabled
                                                /> */}
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Kelengkapan Data
                                                <Label className="ml-2" size="mini">
                                                    Wajib
                                                </Label>
                                            </Form.Label>
                                            <Form.Control
                                                value={form._informasiLain.kelengkapanData}
                                                onChange={handleChangeInformasiLain}
                                                name="kelengkapanData"
                                                isInvalid={error?.kelengkapanData}
                                                size="sm"
                                                as="select"
                                                disabled={type === 'view'}
                                            >
                                                <option value="" selected disabled hidden>
                                                    Pilih Kelengkapan Data
                                                </option>
                                                {kelengkapanData?.map(({ reference_desc, id }) => (
                                                    <option
                                                        key={id}
                                                        value={reference_desc.toUpperCase()}
                                                    >
                                                        {reference_desc?.toUpperCase()}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Control.Feedback size="sm" type="invalid">
                                                Kelengkapan data wajib diisi
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Marketing Saat ini
                                            </Form.Label>
                                            <div className="tw-grid tw-grid-cols-3 tw-gap-x-5">
                                                <Form.Control
                                                    value={form._informasiLain.marketingSaatIni}
                                                    onChange={handleChangeInformasiLain}
                                                    name="marketingSaatIni"
                                                    size="sm"
                                                    disabled={type === 'view'}
                                                    className="tw-col-span-2"
                                                />
                                                {/* <Form.Control
                                                    value={form._informasiLain.marketingSaatIni}
                                                    onChange={handleChangeInformasiLain}
                                                    name="marketingSaatIni"
                                                    size="sm"
                                                    disabled
                                                /> */}
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    <FormHeader className="mb-4">Asuransi</FormHeader>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Rencana Asuransi Utama
                                            </Form.Label>
                                            <Form.Control
                                                value={form._asuransi.rencanaAsuransiUtama}
                                                size="sm"
                                                as="select"
                                                // onChange={handleChangeAsuransi}
                                                name="rencanaAsuransiUtama"
                                                disabled={type === 'view'}
                                            >
                                                <option value="" selected hidden disabled>
                                                    Pilih Rencana Asuransi Utama
                                                </option>
                                                <option value="dummy">Dummy</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Rencana Asuransi Tambahan
                                            </Form.Label>
                                            <Form.Control
                                                value={form._asuransi.rencanaAsuransiTambahan}
                                                size="sm"
                                                as="select"
                                                // onChange={handleChangeAsuransi}
                                                name="rencanaAsuransiTambahan"
                                                disabled={type === 'view'}
                                            >
                                                <option value="" selected hidden disabled>
                                                    Pilih Rencana Asuransi Tambahan
                                                </option>
                                                <option value="dummy">Dummy</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                Tanggal Jatuh Tempo
                                            </Form.Label>
                                            <Form.Control
                                                value={form._asuransi.tanggalJatuhTempoAsuransi}
                                                size="sm"
                                                type="date"
                                                name="tanggalJatuhTempoAsuransi"
                                                // onChange={handleChangeAsuransi}
                                                disabled={type === 'view'}
                                            />
                                        </Form.Group>
                                        <Gap width={111} />
                                        <Form.Group as={Col}></Form.Group>
                                    </Form.Row>
                                    <FormHeader className="mb-4">Alamat Alternatif</FormHeader>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label className="font-weight-normal f-12">
                                                    Alamat
                                                </Form.Label>
                                                <Form.Control
                                                    value={form._asuransi.rencanaAsuransiUtama}
                                                    size="sm"
                                                    as="text-area"
                                                    // onChange={handleChangeAsuransi}
                                                    name="rencanaAsuransiUtama"
                                                    disabled={type === 'view'}
                                                >
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        size="sm"
                                                        placeholder="Masukan Kelurahan"
                                                        name="nisbahAkhir"
                                                        // value={form._informasiLain?.nisbahAkhir}
                                                        onChange={handleChangeInformasiLain}
                                                    />
                                                </InputGroup>
                                                </Form.Control>
                                            </Form.Group>
                                            <Gap width={111} />
                                            <Form.Group as={Col}>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kelurahan
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            // value={form._informasiLain?.nisbahPajak}
                                                            size="sm"
                                                            placeholder="Masukan Kelurahan"
                                                            name="nisbahPajak"
                                                            onChange={handleChangeInformasiLain}
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kecamatan
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            size="sm"
                                                            placeholder="Masukan Kecamatan"
                                                            name="nisbahAkhir"
                                                            // value={form._informasiLain?.nisbahAkhir}
                                                            onChange={handleChangeInformasiLain}
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label className="font-weight-normal f-12">
                                                    RT/RW
                                                </Form.Label>
                                                <Row>
                                                    <Form.Group as={Col}>
                                                        <InputGroup size="sm">
                                                            <Form.Control
                                                                // value={form._informasiLain?.nisbahPajak}
                                                                size="sm"
                                                                placeholder="Masukan Kelurahan"
                                                                name="nisbahPajak"
                                                                onChange={handleChangeInformasiLain}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>/
                                                    <Form.Group as={Col}>
                                                        <InputGroup size="sm">
                                                            <Form.Control
                                                                size="sm"
                                                                placeholder="Masukan Kelurahan"
                                                                name="nisbahAkhir"
                                                                // value={form._informasiLain?.nisbahAkhir}
                                                                onChange={handleChangeInformasiLain}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Row>
                                            </Form.Group>
                                            <Gap width={111} />
                                            <Form.Group as={Col}>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kota/Kab
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            // value={form._informasiLain?.nisbahPajak}
                                                            size="sm"
                                                            placeholder="Masukan Kota/Kab"
                                                            name="nisbahPajak"
                                                            onChange={handleChangeInformasiLain}
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Provinsi
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            size="sm"
                                                            placeholder="Masukan Provinsi"
                                                            name="nisbahAkhir"
                                                            // value={form._informasiLain?.nisbahAkhir}
                                                            onChange={handleChangeInformasiLain}
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                
                                            </Form.Group>
                                            <Gap width={111} />
                                            <Form.Group as={Col}>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="font-weight-normal f-12">
                                                        Kode POS
                                                    </Form.Label>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            size="sm"
                                                            placeholder="Masukan Kode POS"
                                                            name="nisbahAkhir"
                                                            // value={form._informasiLain?.nisbahAkhir}
                                                            onChange={handleChangeInformasiLain}
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                
                                            </Form.Group>
                                            <Gap width={111} />
                                            <Form.Group as={Col}>
                                            <Form.Label className="font-weight-normal f-12">
                                                    Telepon Rumah 
                                                </Form.Label>
                                                <Row>
                                                    <Form.Group as={Col}>
                                                        <InputGroup size="sm">
                                                            <Form.Control
                                                                // value={form._informasiLain?.nisbahPajak}
                                                                size="sm"
                                                                placeholder="000"
                                                                name="nisbahPajak"
                                                                onChange={handleChangeInformasiLain}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>-
                                                    <Form.Group as={Col}>
                                                        <InputGroup size="sm">
                                                            <Form.Control
                                                                size="sm"
                                                                placeholder="000"
                                                                name="nisbahAkhir"
                                                                // value={form._informasiLain?.nisbahAkhir}
                                                                onChange={handleChangeInformasiLain}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Row>
                                            </Form.Group>
                                        </Form.Row>
                                </Form>
                            </>
                        )}
                    </div>
                )}
                {activeIdx === 2 && (
                    <div className="py-4">
                        <div className="px-5 tw-mb-3">
                            <div className="tw-flex tw-items-center tw-justify-between">
                                <div className="tw-text-primary tw-font-bold tw-text-16">
                                    Data Specimen Pejabat
                                </div>
                                <Button
                                    onClick={() => {
                                        setModal({ ...modal, specimenPejabat: true });
                                        setTipeSpecimenPejabat('registrasi');
                                    }}
                                    color="red"
                                    size="mini"
                                >
                                    Lihat SVS
                                </Button>
                            </div>
                        </div>
                        <TableGrid
                            columns={[
                                {
                                    name: 'Nama Lengkap',
                                    field: 'pejabatNama',
                                },
                                {
                                    name: 'Jabatan',
                                    field: 'pejabatJabatan',
                                },
                                {
                                    name: 'Keterangan',
                                    field: 'pejabatKeterangan',
                                },
                                {
                                    name: '',
                                    field: 'action',
                                    width: '50px',
                                },
                            ]}
                            data={nasabah.specimenPejabat}
                            config={{
                                height: '60vh',
                                overflowY: 'auto',
                                stickyHead: true,
                            }}
                        />
                    </div>
                )}
                {activeIdx === 3 && (
                    <>
                        <div className="py-4">
                            <div className="px-5 tw-mb-3">
                                <div className="tw-flex tw-items-center tw-justify-between">
                                    <div className="tw-text-primary tw-font-bold tw-text-16">
                                        Data Hak Akses Rekening
                                    </div>
                                    <Button
                                        onClick={() => {
                                            setModal({ ...modal, hakAksesData: true });
                                            setTipeRelasiNasabah('tambah');
                                            setRelasiNasabahState([]);
                                        }}
                                        color="red"
                                        size="mini"
                                        disabled={
                                            type === 'view' || form._hakAksesRekening.length > 0
                                        }
                                    >
                                        Tambah Hak Akses Rekening
                                    </Button>
                                </div>
                            </div>
                            <TableGrid
                                columns={[
                                    {
                                        name: 'Nama Lengkap',
                                        field: 'namaLengkap',
                                    },
                                    {
                                        name: 'Identitas',
                                        field: 'identitas',
                                    },
                                    {
                                        name: 'No. Identitas',
                                        field: 'noIdentitas',
                                    },
                                    {
                                        name: 'Nomor Telepon',
                                        field: 'noTelpon',
                                    },
                                    {
                                        name: 'Keterangan',
                                        field: 'keterangan',
                                    },
                                    {
                                        name: '',
                                        field: 'action',
                                        width: '50px',
                                    },
                                ]}
                                data={form._hakAksesRekening}
                                config={{
                                    height: '50vh',
                                    overflowY: 'auto',
                                    stickyHead: true,
                                }}
                                onRenderField={(value, field, { data, className, index }) => {
                                    if (field === 'action' && type !== 'view') {
                                        return (
                                            <div key={`${value}-${field}`} className={className}>
                                                <ActionMore
                                                    actions={[
                                                        {
                                                            label: 'Ubah Data Hak Akses',
                                                            divider: true,
                                                            onClick: () => {
                                                                setModal({
                                                                    ...modal,
                                                                    hakAksesData: true,
                                                                });
                                                                setTipeRelasiNasabah('edit');
                                                                setRelasiNasabahState([
                                                                    data,
                                                                    index,
                                                                ]);
                                                            },
                                                        },
                                                        {
                                                            label: 'Hapus Data Hak Akses',
                                                            color: 'red',
                                                            onClick: () => {
                                                                setShowModalHapus(true);
                                                                setIndexType('akses');
                                                                setIndexHapus(index);
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        );
                                    }
                                }}
                            />
                        </div>
                        <div className="tw-px-20 tw-mb-6">
                            <Message className="text-center" warning>
                                Sebelum melanjutkan proses registrasi, pastikan data yang Anda
                                masukan sudah benar.
                            </Message>
                        </div>
                    </>
                )}

                <div className="py-4 px-5 border-top border-gray d-flex align-items-center justify-content-between">
                    {['view', 'edit'].includes(type) ? (
                        <Button onClick={() => history.goBack()} color="red" size="mini">
                            Batal
                        </Button>
                    ) : (
                        <Button onClick={handleReset} color="red" basic size="mini">
                            Reset
                        </Button>
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                        <Button
                            onClick={handlePrevious}
                            className={clsx('mr-2 tw-transition-none', {
                                'tw-bg-black-70': activeIdx === 0,
                            })}
                            disabled={activeIdx === 0}
                            size="mini"
                            color="red"
                        >
                            Sebelumnya
                        </Button>
                        <Button
                            disabled={activeIdx === 3}
                            onClick={handleNext}
                            color="red"
                            size="mini"
                            className={clsx('tw-transition-none', {
                                'mr-2 tw-bg-black-70': activeIdx === 3,
                            })}
                        >
                            Selanjutnya
                        </Button>
                        {(activeIdx === 3 || type === 'edit') && type !== 'view' && (
                            <Button
                                onClick={() => {
                                    if (type === 'edit') {
                                        const errSlide2 = {
                                            sumberDana: form._informasiLain.sumberDana,
                                            tujuanPembukaanRekening:
                                                form._informasiLain.tujuanPembukaanRekening,
                                            kelengkapanData: form._informasiLain.kelengkapanData,
                                        };

                                        const { err, isAnyEmptyError } =
                                            getErrorEmptyValue(errSlide2);

                                        if (isAnyEmptyError) {
                                            setError({ ...error, ...err });
                                            setActiveIdx(1);
                                            return;
                                        }
                                    }

                                    setConfirm(true);
                                }}
                                color="red"
                                size="mini"
                            >
                                Simpan
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegistrasiTabunganRencana;
