import clsx from 'clsx';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormCheck, FormControl, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { TableGrid } from '../../../../controls';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../../../../app.config';

const PembukaanRekeningPage = () => {
    let history = useHistory();
    const [refuseModal, setRefuseModal] = useState(false);
    const [tutupRekeningModal, setTutupRekeningModal] = useState(false);
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState({});
    const [dataJenisProduk, setDataJenisProduk] = useState([]);
    const [jenisProduk, setJenisProduk] = useState('');
    const [jenisNasabah, setJenisNasabah] = useState('');
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [bodyParams, setBodyParams] = useState({
        status_otorisasi: '',
        keterangan_tolak: '',
    });
    const handleRefuse = () => {
        setRefuseModal(true);
    };
    const [modal, setModal] = useState({
        confirm: false,
        success: false,
        error: false,
    });
    const [message, setMessage] = useState({
        confirm: null,
        success: null,
        error: null,
    });
    const [jenisTransaksi, setJenisTransaksi] = useState('Tutup - Tunai');

    useEffect(async () => {
        getData();
    }, [jenisTransaksi, jenisNasabah, jenisProduk, limit, currentPage]);

    const getData = async () => {
        const res = await fetch(
            baseUrl +
                `/teller/tutup-rekening/all?limit=${limit}&page=${currentPage}&jenis_transaksi=${jenisTransaksi}`
        );
        const data = await res.json();
        setData(data.list);

        const resp = await fetch(baseUrl + `/rekening/tabungan/produk?limit=10&page=0`);
        const respon = await resp.json();
        setDataJenisProduk(respon.list);
    };

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    const handleSearch = () => {
        if (isNaN(search)) {
            const body = {
                filterCriteria: {
                    field: 'nama_lengkap',
                    value: search,
                    operator: 'EQUALS',
                },
            };

            return fetch(baseUrl + `/teller/tutup-rekening/search`, {
                method: 'POST',
                body: JSON.stringify(body),
            })
                .then((response) => response.json())
                .then((data) => setData(data?.list));
        } else {
            const body = {
                filterCriteria: {
                    field: 'no_rekening',
                    value: search,
                    operator: 'EQUALS',
                },
            };

            return fetch(baseUrl + `/teller/tutup-rekening/search`, {
                method: 'POST',
                body: JSON.stringify(body),
            })
                .then((response) => response.json())
                .then((data) => setData(data?.list));
        }
    };

    const handleChange = (e) => {
        const body = {
            filterCriteria: {
                field: e.target.name,
                value: e.target.value,
                operator: 'LIKES',
            },
        };

        return fetch(baseUrl + `/teller/tutup-rekening/search`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => setData(data?.list));
    };

    const rejectData = () => {
        let value = currentData;
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify({
                status_otorisasi: 'REJECTED',
                keterangan_tolak: bodyParams.keterangan_tolak,
            }),
        };
        fetch(baseUrl + '/teller/tutup-rekening/otorisasi/' + value.id, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    setModal({
                        confirm: false,
                        success: true,
                    });
                    setRefuseModal(false);
                    setMessage({
                        ...message,
                        success: data.status,
                    });
                } else {
                    setModal({
                        confirm: false,
                        error: true,
                    });
                    setRefuseModal(false);
                    setMessage({
                        ...message,
                        error: data.status,
                    });
                }
            });
    };

    const approveData = () => {
        let value = currentData;
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify({
                status_otorisasi: 'APPROVED',
                keterangan_tolak: '',
            }),
        };
        fetch(baseUrl + '/teller/tutup-rekening/otorisasi/' + value.id, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.message_id === '00') {
                    setModal({
                        confirm: false,
                        success: true,
                    });
                    setTutupRekeningModal(false);
                    setMessage({
                        ...message,
                        success: data.status,
                    });
                    getData();
                } else {
                    setModal({
                        confirm: false,
                        error: true,
                    });
                    setMessage({
                        ...message,
                        error: data.status,
                    });
                }
            });
    };

    // console.log(jenisNasabah, ' jenis produk');

    return (
        <>
            <Modal centered show={Boolean(tutupRekeningModal)} size="lg">
                <Modal.Header onHide={() => setTutupRekeningModal(false)} closeButton>
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Otorisasi Tutup Rekening</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tw-border tw-border-black-40 tw-py-5 tw-px-6 tw-border-solid tw-rounded-lg tw-mb-6">
                        <div className="tw-flex tw-items-center tw-mb-5">
                            <div className="tw-text-black tw-font-bold tw-mr-2 tw-text-16">
                                {currentData.nama_lengkap}
                            </div>
                            <div className="tw-bg-green tw-text-12 tw-bg-opacity-20 tw-text-green tw-rounded-full tw-px-3">
                                Aktif
                            </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-8 tw-items-center tw-gap-x-2 tw-gap-y-1 tw-text-black tw-text-12">
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Produk</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">{currentData.jenis_produk}</div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Cabang</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2"></div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Saldo Rek</span>
                                <span>:</span>
                            </span>
                            <div>Rp.</div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>No Rek</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">{currentData.no_rekening}</div>
                            <span className="tw-text-black-70 tw-flex tw-items-center tw-justify-between">
                                <span>Kode Valuta</span>
                                <span>:</span>
                            </span>
                            <div className="tw-col-span-2">IDR - Rupiah</div>
                        </div>
                    </div>
                    <div className="tw-float-right">
                        {/* <FormCheck
                            checked
                            className="tw-text-12 tw-font-bold tw-mb-5"
                            label="Tutup Kartu ATM"
                        /> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                    >
                        Tidak Proses
                    </Button>
                    <Button className="tw-text-12 tw-py-2 tw-mr-3" size="mini" basic color="red">
                        Tolak
                    </Button>
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        basic
                        color="green"
                        onClick={() => setModal({ ...modal, confirm: true })}
                    >
                        Setujui
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(refuseModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setRefuseModal(false);
                        // handleRefuse.reset()
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tolak Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <FormControl
                        as="textarea"
                        placeholder="Masukkan keterangan penolakan"
                        onChange={(e) =>
                            setBodyParams({ ...bodyParams, keterangan_tolak: e.target.value })
                        }
                    />
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={() => rejectData()}
                    >
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Tabungan & Giro
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Penutupan Rekening
                </div>
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                name="jenis_nasabah"
                                onChange={(e) => handleChange(e)}
                            >
                                <option disabled selected={!jenisNasabah}>
                                    Pilih Jenis Nasabah
                                </option>
                                <option value="CLIENT">Perorangan</option>
                                <option value="GROUP">Badan Usaha</option>
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                onChange={(e) => {
                                    setJenisTransaksi(e.target.value);
                                }}
                                value={jenisTransaksi}
                            >
                                <option disabled value="" selected={!jenisTransaksi}>
                                    Jenis Transaksi
                                </option>
                                <option value="Tutup - Tunai">Tunai</option>
                                <option value="Tutup - Pindah Buku">Pindah Buku</option>
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                name="jenis_produk"
                                as="select"
                                onChange={(e) => handleChange(e)}
                            >
                                <option disabled selected={!jenisProduk}>
                                    Jenis Produk
                                </option>
                                {dataJenisProduk &&
                                    dataJenisProduk.map((item) => {
                                        return (
                                            <option value={item.name} key={item.name}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                            </FormControl>
                            <form onSubmit={handleSearch} className="tw-w-full mr-4" id="search">
                                <FormControl
                                    id="search"
                                    className="mr-4"
                                    size="sm"
                                    placeholder="Cari no.rekening, nama..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </form>
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                                onClick={() => {
                                    setSearch('');
                                    setJenisNasabah('');
                                    setJenisProduk('');
                                    setJenisTransaksi('');
                                    // maybe better using ref, but well you can change it to ref if you want
                                    document.getElementById('search').reset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                                onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </div>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Nomor Rekening',
                            field: 'no_rekening',
                            width: '100px',
                        },
                        {
                            name: 'Nama Lengkap',
                            field: 'nama_lengkap',
                            width: '100px',
                        },
                        {
                            name: 'Alamat',
                            field: 'alamat',
                            width: '300px',
                        },
                        {
                            name: 'Jenis Produk',
                            field: 'jenis_produk',
                            width: '100px',
                        },
                        {
                            field: 'action',
                        },
                    ]}
                    data={data}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'value')
                            return <div className={clsx(className, 'tw-text-right')}>{value}</div>;
                        if (field === 'action')
                            return (
                                <Action
                                    onDetail={() => {
                                        setTutupRekeningModal(true);
                                        setCurrentData(data);
                                    }}
                                    onApproved={() => {
                                        setCurrentData(data);
                                        setModal({ ...modal, confirm: true });
                                    }}
                                    onRefuse={() => {
                                        setCurrentData(data);
                                        setRefuseModal(true);
                                    }}
                                    key={`${value}-${field}`}
                                />
                            );
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-end tw-text-black tw-mt-auto"
                ></div>
            </div>
            <Modal
                show={modal.confirm}
                onHide={() => {
                    setModal({ ...modal, confirm: false });
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Konfirmasi</Modal.Title>
                </Modal.Header>
                <Modal.Body>Apakah Anda yakin akan melakukan proses data?</Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => setModal({ ...modal, confirm: false })}
                    >
                        Batal
                    </button>
                    <button className="btn btn-primary ml-3" onClick={() => approveData()}>
                        Proses
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={modal.success}
                onHide={() => {
                    setModal({ ...modal, success: false });
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Informasi</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message.success}</Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => {
                            setModal({ ...modal, success: false });
                            getData();
                        }}
                    >
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={modal.warning}
                onHide={() => {
                    setModal({ ...modal, warning: false });
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Peringatan</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => setModal({ ...modal, warning: false })}
                    >
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={modal.error}
                onHide={() => {
                    setModal({ ...modal, error: false });
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Opps, Terjadi kesalahan</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => setModal({ ...modal, error: false })}
                    >
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PembukaanRekeningPage;

const Action = ({ onDetail, onApproved, onRefuse }) => {
    const [show, setShow] = useState(false);

    const handleDetail = () => {
        if (onDetail) onDetail();

        setShow(false);
    };

    const handleApproved = () => {
        if (onApproved) onApproved();
    };
    const handleRefuse = () => {
        if (onRefuse) onRefuse();

        setShow(false);
    };

    return (
        <div>
            <Button basic color="red" size="mini" onClick={handleRefuse}>
                Tolak
            </Button>
            <Button basic color="green" className="tw-mr-3" size="mini" onClick={handleApproved}>
                Setujui
            </Button>
            <button type="button" class="btn btn-link" onClick={handleDetail}>
                Lihat Detail
            </button>
        </div>
    );
};
