import moment from 'moment';
import React from 'react';
import { createForm } from '../../../../../module/createFormList';
import { FormRegistration } from '../../../../controls';
import useFormBadanUsaha from './useFormBadanUsaha';

const BeneficiaryOwner = () => {
    const { fbAction, fbState } = useFormBadanUsaha();
    const { formData = {}, formEditable = false } = fbState;

    return ( 
        <>
            {/* <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label>Jenis Beneficiary Owner</label>
                        <select className="form-control">
                            <option>
                                Korporat / Badan Usaha
                            </option>
                            <option disabled={true} value="perorangan">
                                Perorangan
                            </option>
                        </select>
                    </div>
                </div>
            </div> */}
            <div className="box">
                <div className="row">
                    <div className="col-4 align-self-center bns-form">
                        <div className="form-group">
                            <label>Jenis Beneficiary Owner</label>
                            <input
                                className="form-control"
                                type="text"
                                readOnly
                                placeholder="Individu"
                            />
                        </div>
                    </div>
                </div>
            </div>
            
            <FormRegistration
                itemsForm={[
 // Beneficiary
 {
                        name: "Data Beneficiary",
                        rows: [
                            {
                                rows: 1,
                                label: 'Nama Lengkap',
                                type: 'text',
                                placeholder: 'Nama Lengkap',
                                value: fbState.formData?._beneficiaryIndividu?.benefNama,
                                onChange: (value) => fbAction.changeForm(value, "benefNama","_beneficiaryIndividu"),
                                required: false,
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                type: 'custom',
                                component: (
                                    <div className="row">
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: 'Tempat/Tanggal Lahir',
                                                    type: 'text',
                                                    placeholder:"Masukkan tempat lahir",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefBirthPlace,
                                                    onChange: (value) => fbAction.changeForm(value, "benefBirthPlace","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col">
                                            {
                                                createForm({
                                                    label: <>&nbsp;</>,
                                                    type: 'date',
                                                    value: formData.birthDate,
                                                    value: fbState.formData?._beneficiaryIndividu?.benefBirthDate,
                                                    onChange: (value) => fbAction.changeForm(value, "benefBirthDate","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                //no field
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col pl-3">
                                            <div className="form-group">
                                                <label style={{fontSize:"12px"}}>Jenis Kelamin</label>
                                                <br />
                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input 
                                                            type="radio" 
                                                            checked={formData?._beneficiaryIndividu?.benefGender === "P"} 
                                                            className="form-check-input" 
                                                            name="benefGender" 
                                                            onChange={(e) => {
                                                                fbAction.changeForm(e.target.value, "benefGender","_beneficiaryIndividu");
                                                            }}
                                                            value="P"
                                                            disabled={!fbState.formEditable}
                                                        />Laki-laki
                                                    </label>
                                                </div>
                                                <div className="form-check-inline ml-3">
                                                    <label className="form-check-label">
                                                        <input 
                                                            type="radio" 
                                                            className="form-check-input" 
                                                            name="benefGender" 
                                                            checked={formData?._beneficiaryIndividu?.benefGender === "W"} 
                                                            onChange={(e) => {
                                                                fbAction.changeForm(e.target.value, "benefGender","_beneficiaryIndividu");
                                                            }}
                                                            value="W"
                                                            disabled={!fbState.formEditable}
                                                        />Perempuan
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: 'Agama',
                                type: 'select',
                                placeholder: 'Pilih Agama',
                                options: (fbState.dataDropDown?.eAgama || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fbState.formData?._beneficiaryIndividu?.benefAgama,
                                onChange: (value) => fbAction.changeForm(value, "benefAgama","_beneficiaryIndividu"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                label: 'Status Perkawinan',
                                type: 'select',
                                placeholder: 'Pilih Status Perkawinan',
                                options: (fbState.dataDropDown?.eStatusPerkawinan || []).map(({reference_code, reference_desc}) => ({
                                    reference_code,
                                    reference_desc : `${reference_code} - ${reference_desc}`
                                })),
                                key: "reference_code",
                                display: "reference_desc",
                                value: fbState.formData?._beneficiaryIndividu?.benefStatusPerkawinan,
                                onChange: (value) => fbAction.changeForm(value, "benefStatusPerkawinan","_beneficiaryIndividu"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        {/* <div className="col-3">
                                            {
                                                createForm({
                                                    rows: 1,
                                                    label: "Telepon",
                                                    type: "number",
                                                    placeholder: "000",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefHomePhone,
                                                    onChange: (value) => fbAction.changeForm(value, "benefHomePhone","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div> */}
                                        <div className="col-12">
                                            {
                                                createForm({
                                                    rows: 1,
                                                    // label: <> &nbsp; </>,
                                                    label: "Telepon",
                                                    type: "number",
                                                    maxLength: 18,
                                                    placeholder: "000",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefHomePhone,
                                                    onChange: (value) => fbAction.changeForm(value, "benefHomePhone","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                label: "No Handphone",
                                type: "number",
                                placeholder: "000",
                                maxLength: 14,
                                required: false,
                                value: fbState.formData?._beneficiaryIndividu?.benefMobilePhone,
                                onChange: (value) => fbAction.changeForm(value, "benefMobilePhone","_beneficiaryIndividu"),
                                onEnter:(value)=> fbAction.checkHpBO(value, fbAction.changeForm("", "benefMobilePhone", "_beneficiaryIndividu")),
                                onFocusOut:(value)=> fbAction.checkHpBO(value, fbAction.changeForm("", "benefMobilePhone", "_beneficiaryIndividu")),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                label: 'Alamat',
                                type: 'textarea',
                                rowsText: 4,
                                placeholder: 'Masukkan Alamat',
                                value: fbState.formData?._beneficiaryIndividu?.benefAlamat,
                                onChange: (value) => fbAction.changeForm(value, "benefAlamat","_beneficiaryIndividu"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Provinsi",
                                                    type: "select",
                                                    required: false,
                                                    options: (fbState.provinces || []).map(({province_code, province_name}) => ({
                                                        province_code,
                                                        province_name : `${province_code} - ${province_name}`
                                                    })),
                                                    key: "province_code",
                                                    display: "province_name",
                                                    placeholder: "Masukkan Provinsi",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefProvinsi || '',
                                                    onChange: (value) => {
                                                        //const data = fbState.provinces?.find(v => v.province_name === value);
                                                        fbAction.getingCitiesBenef(value, fbAction.changeForm("", "benefKotaKabupaten", "_beneficiaryIndividu"))
                                                        fbAction.changeForm(value, "benefProvinsi", "_beneficiaryIndividu")
                                                    },
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kota/Kab",
                                                    type: "select",
                                                    required: false,
                                                    options: (fbState.citiesBenf || []).map(({city_code, city_name}) => ({
                                                        city_code,
                                                        city_name : `${city_code} - ${city_name}`
                                                    })),
                                                    key: "city_code",
                                                    display: "city_name",
                                                    placeholder: "Masukkan Kab/Kota",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefKotaKabupaten || '',
                                                    onChange: (value) => {
                                                        // fbAction.getingDistrictBenfIdentitas(value)
                                                        fbAction.changeForm(value, "benefKotaKabupaten", "_beneficiaryIndividu")
                                                    },
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kecamatan",
                                                    type: "text",
                                                    required: false,
                                                    //options: fbState.districtsBenfIdentitas,
                                                    placeholder: "Masukkan Kecamatan",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefKecamatan,
                                                    //onChange: (value) => {
                                                    //    fbAction.getingVillagesBenfIdentitas(
                                                    //        fbState.formData?._beneficiaryIndividu?.benefKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                                    //        value 
                                                    //    )
                                                    //    fbAction.changeForm(value, "benefKecamatan", "_beneficiaryIndividu")
                                                    //},
                                                    onChange: (value) => fbAction.changeForm(value, "benefKecamatan","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kelurahan",
                                                    type: "text",
                                                    //options: fbState.villagesBenfIdentitas,
                                                    required: false,
                                                    placeholder: "Masukkan Kelurahan",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefKelurahan,
                                                    //onChange: (value) => {
                                                    //    fbAction.getingPostalCodesBenfIdentitas(
                                                    //        fbState.formData?._beneficiaryIndividu?.benefKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                                                    //        fbState.formData?._beneficiaryIndividu?.benefKecamatan, // ambil dari data Kota/Kab sebelumnya.
                                                    //        value
                                                    //    )
                                                    //    fbAction.changeForm(value, "benefKelurahan", "_beneficiaryIndividu")
                                                    //},
                                                    onChange: (value) => fbAction.changeForm(value, "benefKelurahan","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RT",
                                                    type: "text",
                                                    maxLength: 3,
                                                    required: false,
                                                    placeholder: "RT",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefRt,
                                                    onChange: (value) => fbAction.changeForm(value, "benefRt","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RW",
                                                    type: "text",
                                                    maxLength: 3,
                                                    required: false,
                                                    placeholder: "RW",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefRw,
                                                    onChange: (value) => fbAction.changeForm(value, "benefRw","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Kode Pos',
                                type: 'number',
                                maxLength:5,
                                placeholder: 'Pilih Kode Pos',
                                //options: fbState.postalCodesBenfIdentitas,
                                value: fbState.formData?._beneficiaryIndividu?.benefNasabahKodePos,
                                onChange: (value) => fbAction.changeForm(value, "benefNasabahKodePos","_beneficiaryIndividu"),
                                disabled: !fbState.formEditable
                            },
                        ]
                    },
                    // End Beneficiary
                    {
                        name: "Alamat Domisili Beneficiary",
                        toolbar: { name: "Salin dari alamat identitas", onClick: () => fbAction.salinAlamatBen() },
                        rows: [
                            {
                                rows: 1,
                                label: 'Alamat',
                                type: 'textarea',
                                rowsText: 4,
                                placeholder: 'Masukkan Alamat',
                                value: fbState.formData?._beneficiaryIndividu?.benefAlamatDom,
                                onChange: (value) => fbAction.changeForm(value, "benefAlamatDom","_beneficiaryIndividu"),
                                disabled: !fbState.formEditable
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Provinsi",
                                                    type: "select",
                                                    required: false,
                                                    options: (fbState.provinces || []).map(({province_code, province_name}) => ({
                                                        province_code,
                                                        province_name : `${province_code} - ${province_name}`
                                                    })),
                                                    key: "province_code",
                                                    display: "province_name",
                                                    placeholder: "Masukkan Provinsi",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefProvinsiDom,
                                                    onChange: (value) => {
                                                        //const data = fbState.provinces?.find(v => v.province_name === value);
                                                        fbAction.getingCitiesBenfDom(value, fbAction.changeForm("", "benefKotaKabupatenDom", "_beneficiaryIndividu"))
                                                        fbAction.changeForm(value, "benefProvinsiDom", "_beneficiaryIndividu")
                                                    },
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kota/Kab",
                                                    type: "select",
                                                    required: false,
                                                    options: (fbState.citiesBenfDom || []).map(({city_code, city_name}) => ({
                                                        city_code,
                                                        city_name : `${city_code} - ${city_name}`
                                                    })),
                                                    key: "city_code",
                                                    display: "city_name",
                                                    placeholder: "Masukkan Kab/Kota",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefKotaKabupatenDom,
                                                    onChange: (value) => {
                                                        // fbAction.getingDistrictBenfDom(value)
                                                        fbAction.changeForm(value, "benefKotaKabupatenDom", "_beneficiaryIndividu")
                                                    },
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kecamatan",
                                                    type: "text",
                                                    required: false,
                                                    //options: fbState.districtsBenfDom,
                                                    placeholder: "Masukkan Kecamatan",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefKecamatanDom,
                                                    //onChange: (value) => {
                                                    //    fbAction.getingVillagesBenfDom(
                                                    //        fbState.formData?._beneficiaryIndividu?.benefKotaKabupatenDom, // ambil dari data Kota/Kab sebelumnya
                                                    //        value 
                                                    //    )
                                                    //    fbAction.changeForm(value, "benefKecamatanDom", "_beneficiaryIndividu")
                                                    //},
                                                    onChange: (value) => fbAction.changeForm(value, "benefKecamatanDom","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "Kelurahan",
                                                    type: "text",
                                                    //options: fbState.villagesBenfDom,
                                                    required: false,
                                                    placeholder: "Masukkan Kelurahan",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefKelurahanDom,
                                                    //onChange: (value) => {
                                                    //    fbAction.getingPostalCodesBenfDom(
                                                    //        fbState.formData?._beneficiaryIndividu?.benefKotaKabupatenDom, // ambil dari data Kota/Kab sebelumnya
                                                    //        fbState.formData?._beneficiaryIndividu?.benefKecamatanDom, // ambil dari data Kota/Kab sebelumnya.
                                                    //        value
                                                    //    )
                                                    //   fbAction.changeForm(value, "benefKelurahanDom", "_beneficiaryIndividu")
                                                    //},
                                                    onChange: (value) => fbAction.changeForm(value, "benefKelurahanDom","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 1,
                                type: "custom",
                                component: (
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RT",
                                                    type: "text",
                                                    maxLength: 3,
                                                    required: false,
                                                    placeholder: "RT",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefRtDom,
                                                    onChange: (value) => fbAction.changeForm(value, "benefRtDom","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                createForm({
                                                    rows: 2,
                                                    label: "RW",
                                                    type: "text",
                                                    maxLength: 3,
                                                    required: false,
                                                    placeholder: "RW",
                                                    value: fbState.formData?._beneficiaryIndividu?.benefRwDom,
                                                    onChange: (value) => fbAction.changeForm(value, "benefRwDom","_beneficiaryIndividu"),
                                                    disabled: !fbState.formEditable
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            {
                                rows: 2,
                                label: 'Kode Pos',
                                type: 'number',
                                maxLength:5,
                                placeholder: 'Pilih Kode Pos',
                                //options: fbState.postalCodesBenfDom,
                                value: fbState.formData?._beneficiaryIndividu?.benefKodePosDom,
                                onChange: (value) => fbAction.changeForm(value, "benefKodePosDom","_beneficiaryIndividu"),
                                disabled: !fbState.formEditable
                            },
                        ]
                    }



                //     // Data Beneficiary
                //     {
                //         name: "Data Beneficiary",
                //         rows: [
                //             {
                //                 rows: 1,
                //                 type: "text",
                //                 label: "Nama Perusahaan",
                //                 placeholder: "Masukan Nama Perusahaan",
                //                 required: false,
                //                 value: fbState.formData?._beneficiaryOwner?.benefNamaPerusahaan,
                //                 onChange: (value) => fbAction.changeForm(value, "benefNamaPerusahaan", "_beneficiaryOwner"),
                //                 disabled: !fbState.formEditable
                //             },
                //             {
                //                 rows: 1,
                //                 type: "number",
                //                 label: "Nomor Izin Usaha",
                //                 placeholder: "Masukan Nomor Izin Usaha",
                //                 required: false,
                //                 value: fbState.formData?._beneficiaryOwner?.benefNomorIzinUsaha,
                //                 onChange: (value) => fbAction.changeForm(value, "benefNomorIzinUsaha", "_beneficiaryOwner"),
                //                 disabled: !fbState.formEditable
                //             },
                //             {
                //                 rows: 1,
                //                 type: "select",
                //                 label: "Bidang Usaha",
                //                 placeholder: "Pilih Bidang usaha",
                //                 required: false,
                //                 options: fbState.dataDropDown?.R_BIDANG_USAHA,
                //                 value: fbState.formData?._beneficiaryOwner?.benefBidangUsaha,
                //                 onChange: (value) => fbAction.changeForm(value, "benefBidangUsaha", "_beneficiaryOwner"),
                //                 key: "reference_code",
                //                 display: "reference_desc",
                //                 disabled: !fbState.formEditable
                //             },
                //             {
                //                 rows: 1,
                //                 type: "textarea",
                //                 label: "Keterangan",
                //                 placeholder: "Bila ada",
                //                 required: false,
                //                 value: fbState.formData?._beneficiaryOwner?.benefKeterangan,
                //                 onChange: (value) => fbAction.changeForm(value, "benefKeterangan", "_beneficiaryOwner"),
                //                 disabled: !fbState.formEditable
                //             },
                //             // end rows 1
                //             // rows 2
                //             {
                //                 rows: 2,
                //                 type: "text",
                //                 label: "Bentuk Usaha",
                //                 placeholder: "Pilih Bentuk Usaha",
                //                 required: false,
                //                 value: fbState.formData?._beneficiaryOwner?.benefBentukUsaha,
                //                 onChange: (value) => fbAction.changeForm(value, "benefBentukUsaha", "_beneficiaryOwner"),
                //                 disabled: !fbState.formEditable
                //             },
                //             {
                //                 rows: 2,
                //                 type: "select",
                //                 label: "Sumber Dana",
                //                 placeholder: "Pilih Sumber Dana",
                //                 required: false,
                //                 options: fbState.dataDropDown?.R_SUMBER_DANA_CIF_KORPORAT,
                //                 value: fbState.formData?._beneficiaryOwner?.benefSumberDana,
                //                 onChange: (value) => fbAction.changeForm(value, "benefSumberDana", "_beneficiaryOwner"),
                //                 key: "reference_code",
                //                 display: "reference_desc",
                //                 disabled: !fbState.formEditable
                //             },
                //             {
                //                 rows: 2,
                //                 type: "select",
                //                 label: "Tujuan Penggunaan Dana",
                //                 placeholder: "Pilih Tujuan Penggunaan Dana",
                //                 required: false,
                //                 options: fbState.dataDropDown?.R_TUJUAN_DANA_CIF_KORPORAT,
                //                 value: fbState.formData?._beneficiaryOwner?.benefTujuanPenggunaanDana,
                //                 onChange: (value) => fbAction.changeForm(value, "benefTujuanPenggunaanDana", "_beneficiaryOwner"),
                //                 key: "reference_code",
                //                 display: "reference_desc",
                //                 disabled: !fbState.formEditable
                //             }
                //         ]

                //     },
                //     // End Data Beneficiary
                //     // Alamat Beneficiary
                //     {
                //         name: "Alamat Beneficiary",
                //         rows: [
                //             // rows 1
                //             {
                //                 rows: 1,
                //                 type: "textarea",
                //                 label: "Alamat",
                //                 placeholder: "Masukan Alamat",
                //                 required: false,
                //                 value: fbState.formData?._beneficiaryOwner?.benefAlamat,
                //                 onChange: (value) => fbAction.changeForm(value, "benefAlamat", "_beneficiaryOwner"),
                //                 disabled: !fbState.formEditable
                //             },
                //             {
                //                 rows: 1,
                //                 type: "custom",
                //                 component: (
                //                     <div className="row">
                //                         <div className="col-6">
                //                         {
                //                             createForm({
                //                                 rows: 1,
                //                                 type: "select",
                //                                 label: "Province",
                //                                 required: false,
                //                                 placeholder: "Pilih Province",
                //                                 options: fbState.provinces,
                //                                 key:"province_code",
                //                                 display:"province_name",
                //                                 value: fbState.formData?._beneficiaryOwner?.benefProvinsi || '',
                //                                 onChange: (value) => {
                //                                     // const data = fbState.provinces?.find(v => v.province_name === value);
                //                                     fbAction.getingCitiesBenef(value)
                //                                     fbAction.changeForm(value, "benefProvinsi", "_beneficiaryOwner")
                //                                 },
                //                                 disabled: !fbState.formEditable
                //                             })
                //                         }
                //                         </div>
                //                         <div className="col-6">
                //                         {
                //                             createForm({
                //                                 rows: 1,
                //                                 label: "Kota/Kab",
                //                                 type: "select",
                //                                 required: false,
                //                                 options: fbState.cities,
                //                                 placeholder: "Pilih Kota/Kabupaten",
                //                                 key:"city_code",
                //                                 display:"city_name",
                //                                 value: fbState.formData?._beneficiaryOwner?.benefKotaKabupaten || '',
                //                                 onChange: (value) => {
                //                                     // fbAction.getingDistrictBenef(value)
                //                                     fbAction.changeForm(value, "benefKotaKabupaten", "_beneficiaryOwner")
                //                                 },
                //                                 disabled: !fbState.formEditable
                //                             })
                //                         }
                //                         </div>
                //                     </div>

                //                 )
                //             },
                //             {
                //             rows: 2,
                //                 type: "custom",
                //                 component: (
                //                     <div className="row">
                //                         <div className="col-6">
                //                             {
                //                                 createForm({
                //                                     rows: 2,
                //                                     type: "text",
                //                                     label: "Kecamatan",
                //                                     placeholder: "Pilih Kecamatan",
                //                                     required: false,
                //                                     //options: fbState.districts,
                //                                     value: fbState.formData?._beneficiaryOwner?.benefKecamatan || '',
                //                                     //onChange: (value) => {
                //                                     //    fbAction.getingVillagesBenef(
                //                                     //        fbState.formData?._beneficiaryOwner?.benefKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                //                                     //        value 
                //                                     //    )
                //                                     //    fbAction.changeForm(value, "benefKecamatan", "_beneficiaryOwner")
                //                                     //},
                //                                     onChange: (value) => fbAction.changeForm(value, "benefKecamatan", "_beneficiaryOwner"),
                //                                     disabled: !fbState.formEditable
                //                                 })
                //                             }
                //                         </div>
                //                         <div className="col-6">
                //                             {
                //                                 createForm({
                //                                     rows: 2,
                //                                     type: "text",
                //                                     label: "Kelurahan",
                //                                     placeholder: "Pilih Kelurahan",
                //                                     required: false,
                //                                     //options: fbState.villages,
                //                                     value: fbState.formData?._beneficiaryOwner?.benefKelurahan || '',
                //                                     //onChange: (value) => {
                //                                     //    fbAction.getingPostalCodesBenef(
                //                                     //        fbState.formData?._beneficiaryOwner?.benefKotaKabupaten, // ambil dari data Kota/Kab sebelumnya
                //                                     //        fbState.formData?._beneficiaryOwner?.benefKecamatan, // ambil dari data Kota/Kab sebelumnya.
                //                                     //        value
                //                                     //    )
                //                                     //    fbAction.changeForm(value, "benefKelurahan", "_beneficiaryOwner")
                //                                     //},
                //                                     onChange: (value) => fbAction.changeForm(value, "benefKelurahan", "_beneficiaryOwner"),
                //                                     disabled: !fbState.formEditable
                //                                 })
                //                             }
                //                         </div>
                //                     </div>
                //                 )
                //             },
                //             {
                //                 rows: 2,
                //                 type: "custom",
                //                 component: (
                //                     <div className="row">
                //                         <div className="col-6">
                //                             {
                //                                 createForm({
                //                                     rows: 2,
                //                                     label: "RT",
                //                                     type: "text",
                //                                     required: false,
                //                                     value: fbState.formData?._beneficiaryOwner?.benefRt,
                //                                     onChange: (value) => fbAction.changeForm(value, "benefRt", "_beneficiaryOwner"),
                //                                     placeholder: "RT",
                //                                     disabled: !fbState.formEditable
                //                                 })
                //                             }
                //                         </div>
                //                         <div className="col-6">
                //                             {
                //                                 createForm({
                //                                     rows: 2,
                //                                     label: "RW",
                //                                     type: "text",
                //                                     required: false,
                //                                     value: fbState.formData?._beneficiaryOwner?.benefRw,
                //                                     onChange: (value) => fbAction.changeForm(value, "benefRw", "_beneficiaryOwner"),
                //                                     placeholder: "RW",
                //                                     disabled: !fbState.formEditable
                //                                 })
                //                             }
                //                         </div>
                //                     </div>
                //                 )
                //             },
                //             {
                //                 rows: 2,
                //                 type: "text",
                //                 label: "Kode Pos",
                //                 placeholder: "Pilih Kode Pos",
                //                 required: false,
                //                 //options: fbState.postalCodes,
                //                 value: fbState.formData?._beneficiaryOwner?.nasabahKodePos,
                //                 onChange: (value) => fbAction.changeForm(value, "nasabahKodePos", "_beneficiaryOwner"),
                //                 disabled: !fbState.formEditable
                //             },
                //         ]
                //     },
                //     // End Alamat Beneficiary
                ]}

            />
        </>
     );
}
 
export default BeneficiaryOwner;