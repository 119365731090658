import React, { memo, useRef } from 'react';
import { useForm } from "react-hook-form";
import {WalkInCostumer, BasePagePengirimPenerima, BasePageKiriman, Pin} from '../../controls';
import { AppAction, AppInterfaces } from "../../../app.data";

const Penerimaan_base = memo(({store, ...props})=> {
    const appAction = useRef(null);
    const { register, handleSubmit, setValue } = useForm();

    const reset = () => {
        appAction.current.disp({type: "setPelanggan", pelanggan: {}});
        setValue('pengirim', {nama:  "", noHp: "", email: "", alamat: "", negara: "", provinsi: "", kota: "", kodePos: ""});
        setValue("penerima", {nama:  "", noHp: "", email: "", alamat: "", negara: "", provinsi: "", kota: "", kodePos: ""} );
        setValue("dataKiriman", {jenisKiriman: "", jenisLayanan: "", bsu: "", noref: "", pertanyaan: "", jawaban: ""});
        setValue("bea", {beaPokok: "", beaTambahan: "", ppn: "", total: "", bsubea: ""});
    }

    const prosesPenerimaan = (data) => {
        // console.log("Data Penerimaan", data)
        if(store.pelanggan.nama){
            appAction.current.setShowModal({
                show: true,
                size: "sm",
                backdrop: "static",
                body: <div className="text-center">Apakah data sudah benar?</div>,
                footer: <>
                    <button className="btn btn-outline-secondary btn-sm px-3" onClick={()=>appAction.current.setCloseModal()}>TIDAK</button>
                    <button className="btn btn-primary btn-sm px-4" onClick={()=>OkProses()}>OK</button>
                </>
            })
        }else{
            appAction.current.setShowModal({
                show: true,
                size: "sm",
                backdrop: "static",
                body: <p className="text-center">Silahkan masukkan data Pelanggan </p>,
                footer: <button className="btn btn-primary btn-sm px-4" onClick={()=>appAction.current.setCloseModal()}>OK</button>
            });
        }
    };

    const OkProses = () => appAction.current.setShowModal({
        show: true,
        size: "md",
        backdrop: "static",
        body: <div className="text-center">Demi keamanan saat pencairan memasukkan PIN maksimum 6 digit angka.<br /><Pin value="" onComplete={()=>ulangiPin()} /></div>,
    });

    const ulangiPin = () => appAction.current.setShowModal({
        show: true,
        size: "md",
        backdrop: "static",
        body: <div className="text-center">Ulangi Pin.<br /><Pin onComplete={()=>transaksiBerhasil()} reset={true} /></div>,
    });

    const transaksiBerhasil = () => appAction.current.setShowModal({
        show: true,
        size: "sm",
        backdrop: "static",
        body: <p className="text-center">Transaksi dengan<br/>
            No. transaksi : 129879416423121<br/>
            Barcode : 8312731831<br/><br/>  
            telah diproses </p>,
        footer: <button className="btn btn-primary btn-sm px-4" onClick={()=>appAction.current.setCloseModal()}>OK</button>
    });

    return(
        <div className="p-3" >
            <AppAction ref={appAction} />
            <WalkInCostumer />
            <div className="row">
                <div className="col-12 pt-2">
                    <div className="card card-body px-3 py-0">
                        <div className="row">
                            <div className="col-6 px-4 pt-4">
                                <BasePageKiriman register={register} id="wesel" options={
                                    [
                                        {
                                            title: <>Data Kiriman</>,
                                            type: "dataKiriman",
                                            forms: [
                                                {type: "select", name:"jenisKiriman", required: true, label: "Jenis", disable: false, style: "text-left", options: [
                                                    {id: 1, name: "One"},{id: 2, name: "Two"},{id: 3, name: "Three", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                ]},
                                                {type: "select", name:"jenisLayanan", required: true, label: "Layanan", disable: false, style: "text-left", options: [
                                                    {id: 1, name: "One"},{id: 2, name: "Two"},{id: 3, name: "Three", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                ]},
                                                {type: "text", name:"bsu", required: true, label: "BSU (Rp)", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"noref", required: true, label: "No. Referensi", disable: false, style: "text-left", placeholder: "Masukan no. referensi", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"pertanyaan", required: true, label: "Pertanyaan", disable: false, style: "text-left", placeholder: "Pertanyaan untuk penerima", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"jawaban", required: true, label: "Jawaban", disable: false, style: "text-left", placeholder: "Jawaban pertanyaan penerima", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                            ],
                                        },
                                        {
                                            title: "Bea",
                                            type: "bea",
                                            forms: [
                                                {type: "text", name:"beaPokok", required: true, label: "Bea Pokok (Rp)", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"beaTambahan", required: true, label: "Bea Tambahan (Rp)", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"ppn", required: true, label: "Ppn", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"total", required: true, label: "Total Bea", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)},
                                                {type: "text", name:"bsubea", required: true, label: "BSU + Bea", disable: false, style: "text-right", placeholder: "0", onChange: (val) => appAction.current.setForm("no_pembayaran", val)}
                                            ],
                                        },
                                    ]
                                } />
                            </div>
                            <div className="col-6 border-left px-4 pt-4">
                                <BasePagePengirimPenerima register={register} id="wesel" type="pengirim" disable={store.isSukses}/>
                                <BasePagePengirimPenerima register={register} id="wesel" type="penerima" disable={store.isSukses}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card card-body px-3">
                        <span className="clearfix">
                            <button className="btn btn-outline-primary px-5 mx-1 float-right">BAYAR</button>
                            <form onSubmit={handleSubmit(prosesPenerimaan)}>
                                <button className="btn btn-primary px-5 mx-1 float-right" type="submit">PROSES</button>
                            </form>
                            <button className="btn  px-5 mx-1 float-right" onClick={()=>reset()}>RESET</button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
})

const Penerimaan = AppInterfaces.appLoad(Penerimaan_base)
export default Penerimaan;