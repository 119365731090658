import React, { useEffect, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import useFormBadanUsaha from './useFormBadanUsaha';
import moment from 'moment';
import useAlamat from '../../../../../hooks/useAlamat';

const usePejabatKorporate = () => {
    const { fbAction, fbState } = useFormBadanUsaha()
    const [formModal, setFormModal] = useState(false)
    const [formPejabat, setFormPejabat] = useState({
        "pejabatKecamatan": "",
        "pejabatNomorNasabahPejabat": "",
        "pejabatAlamat": "",
        "pejabatNomorNpwp": "",
        "pejabatKeterangan": "",
        "pejabatNegaraAsal": "",
        "pejabatKotaKabupaten": "",
        "pejabatNomorHandphone": "",
        "pejabatJabatan": "",
        "pejabatIDPejabat": 0,
        "pejabatNomorIdentitas": "",
        "pejabatPekerjaan": "",
        "pejabatTeleponKodeArea": "",
        "pejabatJenisIdentitas": "",
        "pejabatNama": "",
        "pejabatEmailAddress": "",
        "pejabatTeleponNomor": "",
        "pejabatKelurahan": "",
        "pejabatRw": "",
        "pejabatPersentaseKepemilikan": "",
        "pejabatProvinsi": "",
        "pejabatFaxKodeArea": "",
        "pejabatFaxNomor": "",
        "pejabatRt": "",
        "pejabatKewarganegaraan": "",
        "pejabatKodePos": "",
        "pejabatJenisAlamat": "",
        "pejabatGelar": "",
        "pejabatNegaraTerbitIdentitas": "",
        "pejabatTempatTerbitIdentitas": "",
        "pejabatTipeKontakDomisili": "",
        "pejabatExtensionKontakPejabat": "",
        "pejabatStatusPEP": "",
        "pejabatTipeKontak": "",
        "pejabatTempatLahir": "",
        "pejabatTglLahir": "",
        "pejabatJenisKelamin": "",
        "pejabatAgama": ""
    })
    const [isNasabah, setIsNasabah] = useState(false)
    const [mode, setMode] = useState("CREATE")
    const { doGet } = useFetch();
    // alamat 1
    const [districts, setDistricts] = useState([])
    const [villages, setvillages] = useState([])
    const [cities, setCities] = useState([])
    const [postalCodes, setPostalCodes] = useState([])
    const {
        provinces,
        getCities,
        getVillages,
        getPostalsCode,
        getDistrics
    } = useAlamat();

    // resetForm
    function resetForm() {
        setFormPejabat({
            "pejabatKecamatan": "",
            "pejabatNomorNasabahPejabat": "",
            "pejabatAlamat": "",
            "pejabatNomorNpwp": "",
            "pejabatKeterangan": "",
            "pejabatNegaraAsal": "",
            "pejabatKotaKabupaten": "",
            "pejabatNomorHandphone": "",
            "pejabatJabatan": "",
            "pejabatIDPejabat": 0,
            "pejabatNomorIdentitas": "",
            "pejabatPekerjaan": "",
            "pejabatTeleponKodeArea": "",
            "pejabatJenisIdentitas": "",
            "pejabatNama": "",
            "pejabatEmailAddress": "",
            "pejabatTeleponNomor": "",
            "pejabatKelurahan": "",
            "pejabatRw": "",
            "pejabatPersentaseKepemilikan": "",
            "pejabatProvinsi": "",
            "pejabatFaxKodeArea": "",
            "pejabatFaxNomor": "",
            "pejabatRt": "",
            "pejabatKewarganegaraan": "",
            "pejabatKodePos": "",
            "pejabatJenisAlamat": "",
            "pejabatGelar": "",
            "pejabatNegaraTerbitIdentitas": "",
            "pejabatTempatTerbitIdentitas": "",
            "pejabatTipeKontakDomisili": "",
            "pejabatExtensionKontakPejabat": "",
            "pejabatStatusPEP": "",
            "pejabatTipeKontak": "",
            "pejabatTempatLahir": "",
            "pejabatTglLahir": "",
            "pejabatJenisKelamin": "",
            "pejabatAgama": ""
        })
    }
    // close Modal
    function closeModal() {
        resetForm();
        setFormModal(false)
    }
    // open Modal Add
    function openModalAdd() {
        setMode("CREATE")
        resetForm();
        setFormModal(true);
    }
    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        if (typeof value === "string") value = value.toUpperCase()
        if (subkey !== null) setFormPejabat(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormPejabat(prevState => ({ ...prevState, [key]: value }));
    }
    function openDetil(key, m = "DETAIL") {
        setMode(m)
        let data = { ...fbState.formData?._pejabatKorporat?.[key], key };
        setFormModal(true)
        setFormPejabat(data);
        if (!data)
            resetForm()
    }

    function cekNasabah(e) {
        setFormPejabat(prevState => ({
            ...prevState,
            pejabatKecamatan: "",
            pejabatNomorNasabahPejabat: "",
            pejabatAlamat: "",
            pejabatNomorNpwp: "",
            pejabatKeterangan: "",
            pejabatNegaraAsal: "",
            pejabatKotaKabupaten: "",
            pejabatNomorHandphone: "",
            pejabatIDPejabat: 0,
            pejabatNomorIdentitas: "",
            pejabatPekerjaan: "",
            pejabatTeleponKodeArea: "",
            pejabatJenisIdentitas: "",
            pejabatNama: "",
            pejabatEmailAddress: "",
            pejabatTeleponNomor: "",
            pejabatKelurahan: "",
            pejabatRw: "",
            pejabatPersentaseKepemilikan: "",
            pejabatProvinsi: "",
            pejabatFaxKodeArea: "",
            pejabatFaxNomor: "",
            pejabatRt: "",
            pejabatKewarganegaraan: "",
            pejabatKodePos: "",
            pejabatJenisAlamat: "",
            pejabatGelar: "",
            pejabatNegaraTerbitIdentitas: "",
            pejabatTempatTerbitIdentitas: "",
            pejabatTipeKontakDomisili: "",
            pejabatExtensionKontakPejabat: "",
            pejabatStatusPEP: "",
            pejabatTipeKontak: "",
            pejabatTempatLahir: "",
            pejabatTglLahir: "",
            pejabatJenisKelamin: "",
            pejabatAgama: "",
            
        }))
        setIsNasabah(e)
    }

    const [disabledPrecent, setDisabledPrecent ] = useState(false)
    useEffect(function(){
        const desc = (fbState.dataDropDown?.R_JABATAN_KORPORAT || [])
            .find( v => v.reference_code === formPejabat.pejabatJabatan)?.reference_desc || ""
        // console.log(desc)
        if(desc !== null && desc !== "" && desc !== undefined){
            let prefix = desc.split(" - ")
            // console.log(prefix[0])
            if(prefix[0] === "BUKAN PEMILIK"){
                setDisabledPrecent(true)
                setFormPejabat(prevState => ({ ...prevState, pejabatPersentaseKepemilikan: 0 }))
            }else{
                setDisabledPrecent(false)
                setFormPejabat(prevState => ({ ...prevState, pejabatPersentaseKepemilikan: "" }))
            }
        }else{
            setDisabledPrecent(false)
            setFormPejabat(prevState => ({ ...prevState, pejabatPersentaseKepemilikan: "" }))
        }
    },[ formPejabat.pejabatJabatan])

    // save function
    function save() {
        // formPejabat.pejabatNomorNasabahPejabat
        // if (
        //     formPejabat?.pejabatNomorNasabahPejabat === '' ||
        //     formPejabat?.pejabatNomorNasabahPejabat === undefined ||
        //     formPejabat?.pejabatNomorNasabahPejabat === null
        // ) {
        //     fbAction.setDataAlert({ show: true, body: "Nomor Nasabah harus diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        // else 
        if (
            formPejabat?.pejabatJabatan === '' ||
            formPejabat?.pejabatJabatan === undefined ||
            formPejabat?.pejabatJabatan === null
        ) {
            fbAction.setDataAlert({ show: true, body: "Jabatan belum dipilih", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (
            formPejabat?.pejabatNama === '' ||
            formPejabat?.pejabatNama === undefined ||
            formPejabat?.pejabatNama === null
        ) {
            fbAction.setDataAlert({ show: true, body: "Nama (sesuai identitas belum diisi) belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (
            formPejabat?.pejabatTempatLahir === '' ||
            formPejabat?.pejabatTempatLahir === undefined ||
            formPejabat?.pejabatTempatLahir === null
        ) {
            fbAction.setDataAlert({ show: true, body: "Tempat Lahir belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (
            formPejabat?.pejabatTglLahir === '' ||
            formPejabat?.pejabatTglLahir === undefined ||
            formPejabat?.pejabatTglLahir === null
        ) {
            fbAction.setDataAlert({ show: true, body: "Tanggal Lahir belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (
            formPejabat?.pejabatJenisIdentitas === '' ||
            formPejabat?.pejabatJenisIdentitas === undefined ||
            formPejabat?.pejabatJenisIdentitas === null
        ) {
            fbAction.setDataAlert({ show: true, body: "Jenis Identitas belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (
            formPejabat?.pejabatNomorIdentitas === '' ||
            formPejabat?.pejabatNomorIdentitas?.length !== 16 ||
            formPejabat?.pejabatNomorIdentitas === null
        ) {
            fbAction.setDataAlert({ show: true, body: "Nomor Identitas Harus 16 Digit", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (
            formPejabat?.pejabatJenisKelamin === '' ||
            formPejabat?.pejabatJenisKelamin === undefined ||
            formPejabat?.pejabatJenisKelamin === null
        ) {
            fbAction.setDataAlert({ show: true, body: "Jenis Kelamin belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (
            formPejabat?.pejabatPersentaseKepemilikan === '' ||
            formPejabat?.pejabatPersentaseKepemilikan === undefined ||
            (formPejabat?.pejabatPersentaseKepemilikan === null)
        ) {
            fbAction.setDataAlert({ show: true, body: "Presentase kepemilikan belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else if (
            formPejabat?.pejabatNomorHandphone === '' ||
            formPejabat?.pejabatNomorHandphone === undefined ||
            formPejabat?.pejabatNomorHandphone === null
        ) {
            fbAction.setDataAlert({ show: true, body: "No HandPhone belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        }
        else {
            const currentData = fbState.formData?._pejabatKorporat;
            if (mode === "CREATE") {
                formPejabat['action'] = 'C'
                if (currentData === undefined) {
                    const newData = [formPejabat];
                    fbAction.changeForm(newData, "_pejabatKorporat");
                } else {
                    const newData = [...currentData, formPejabat];
                    fbAction.changeForm(newData, "_pejabatKorporat");
                }
            }
            else if (mode === "EDIT") {
                formPejabat['action'] = 'U'
                const newDataForm = { ...formPejabat }
                delete newDataForm.key;
                currentData[formPejabat.key] = newDataForm;
                fbAction.changeForm(currentData, "_pejabatKorporat");
            }
            closeModal()
        }
    }
    //// console.log(formPejabat);
    function del(key) {
        const currentData = fbState.formData?._pejabatKorporat;
        currentData[key]['action'] = 'D'
        fbAction.changeForm(currentData, "_pejabatKorporat");
        // formPejabat['action']= 'D'
        // const newData = currentData.filter((v, k) => k !== key);
        // fbAction.changeForm(newData, "_pejabatKorporat");
    }

    // function carinasabah
    async function getCariNasabah() {
        const cif = formPejabat.pejabatNomorNasabahPejabat;
        fbAction.setLoading(prevState => ({ ...prevState, loading: true }));
        try {
            const resp = await doGet({
                url: `/nasabah/${cif}`,
                service: "cif"
            });
            //// console.log(formPejabat);
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {
                    const { nasabahBadanUsaha } = data;
                    setFormPejabat(prevState => ({
                        ...prevState,
                        "pejabatKecamatan": nasabahBadanUsaha?.ktpSubDistrict?.toUpperCase() || '',
                        "pejabatAlamat": nasabahBadanUsaha?.ktpAddress?.toUpperCase() || '',
                        "pejabatNomorNpwp": nasabahBadanUsaha?.personalNpwpNumber?.toUpperCase() || '',
                        "pejabatNegaraAsal": nasabahBadanUsaha?.ktpCountry?.toUpperCase() || '',
                        "pejabatKotaKabupaten": nasabahBadanUsaha?.ktpCity?.toUpperCase() || '',
                        "pejabatNomorHandphone": nasabahBadanUsaha?.mobilePhone?.toUpperCase() || '',
                        "pejabatNomorIdentitas": nasabahBadanUsaha?.personalIdNumber || '',
                        "pejabatPekerjaan": nasabahBadanUsaha?.personalOccupation?.toUpperCase() || '',
                        "pejabatJenisIdentitas": nasabahBadanUsaha?.personalIdType?.toUpperCase() || '',
                        "pejabatNama": nasabahBadanUsaha?.personalIdHolderName?.toUpperCase() || '',
                        "pejabatEmailAddress": nasabahBadanUsaha?.emailAddress?.toUpperCase() || '',
                        "pejabatTeleponNomor": nasabahBadanUsaha?.homePhone || '',
                        "pejabatTeleponKodeArea": nasabahBadanUsaha?.pejabatTeleponKodeArea || '',
                        "pejabatKelurahan": nasabahBadanUsaha?.ktpVillage?.toUpperCase() || '',
                        "pejabatRw": nasabahBadanUsaha?.ktpRw?.toUpperCase() || '',
                        "pejabatProvinsi": nasabahBadanUsaha?.ktpProvince?.toUpperCase() || '',
                        "pejabatRt": nasabahBadanUsaha?.ktpRt?.toUpperCase() || '',
                        "pejabatKewarganegaraan": nasabahBadanUsaha?.personalCitizenship?.toUpperCase() || '',
                        "pejabatKodePos": nasabahBadanUsaha?.ktpPostalCode?.toUpperCase() || '',
                        "pejabatNegaraTerbitIdentitas": nasabahBadanUsaha?.personalIdDateOfIssue?.toUpperCase() || '',
                        "pejabatFaxNomor": nasabahBadanUsaha?.pejabatFaxNomor?.toUpperCase() || '',
                        "pejabatFaxKodeArea": nasabahBadanUsaha?.pejabatFaxKodeArea?.toUpperCase() || '',
                        "pejabatTempatLahir": nasabahBadanUsaha?.birthPlace?.toUpperCase() || '',
                        "pejabatTglLahir": moment(nasabahBadanUsaha?.birthDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                        "pejabatJenisKelamin": nasabahBadanUsaha?.gender?.toUpperCase() || '',
                        "pejabatAgama": nasabahBadanUsaha?.personalReligion?.toUpperCase() || '',
                        pejabatPersentaseKepemilikan: disabledPrecent ? 0 : ""
                    }));
                }
                else fbAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else fbAction.setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            fbAction.setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
        }
        fbAction.setLoading(prevState => ({ ...prevState, loading: false }))
    }
    // get province_code
    async function getProvinceCode(province_name) {
        if (provinces !== null && provinces !== undefined) {
            const data = provinces?.find(v => {
                return v.province_name === province_name
            });
            return data?.province_code || "";
        }
    }
    // get cities pejabat korporat
    async function getingCitiesPejabat(province_code) {
        const data = await getCities(province_code);
        // const newData = data.map(v => ({ key: v, display: v }));
        setCities(data);
    }
    // // get districts pejabat korporat
    // async function getingDistrictPejabat(city) {
    //     const data = await getDistrics(city);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setDistricts(newData);
    // }
    // // get village pejabat korporat
    // async function getingVillagesPejabat(city, subdistrict) {
    //     const data = await getVillages(city, subdistrict);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setvillages(newData);
    // }
    // // get postalcode pejabat korporat
    // async function getingPostalCodesPejabat(city, subdistrict, urban) {
    //     const data = await getPostalsCode(city, subdistrict, urban);
    //     const newData = data.map(v => ({ key: v, display: v }));
    //     setPostalCodes(newData);
    // }
    //validation
    function checkHpKorporat(value, callback = () => null) {
        if (value.length < 10) {
            fbAction.setDataAlert({ show: true, body: `No Handphone minimal 10 digit`, title: `Cek Kembali No HandPhone`, header: "Warning", callBack: () => callback() });
        } else {
            changeForm(value, "pejabatNomorHandphone")
        }
    }
    return {
        pkState: {
            formPejabat,
            isNasabah,
            formModal,
            mode,
            provinces,
            districts,
            villages,
            cities,
            postalCodes,
            disabledPrecent
        },
        pkAction: {
            closeModal,
            openModalAdd,
            setFormPejabat,
            cekNasabah,
            save,
            openDetil,
            del,
            getCariNasabah,
            getCities,
            getVillages,
            getPostalsCode,
            getDistrics,
            getProvinceCode,
            getingCitiesPejabat,
            // getingDistrictPejabat,
            // getingVillagesPejabat,
            // getingPostalCodesPejabat,
            changeForm,
            checkHpKorporat
        }
    };
}

export default usePejabatKorporate;