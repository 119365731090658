import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FrameRegistration, NewLoading } from '../../../../controls';
import BeneficiaryOwner from './beneficiaryOwner';
import DataCCD from './dataCCD';
import DataRekening from './relasiNasabah';
import IdentitasNasabah from './identitasNasabah';
import PejabatKorporat from './pejabatKorporat';
import useFormBadanUsaha, { FbProvider } from './useFormBadanUsaha';
import RelasiNasabah from './relasiNasabah';
import { useHistory, useParams } from 'react-router';


function FbIndex() {

    const { fbAction, fbState } = useFormBadanUsaha();
    const { mode } = useParams()
    const history = useHistory()
    useEffect(function () {
        fbAction.auditTrail()
    }, [])
    
    useEffect(function(){
        document.addEventListener("keydown", fbAction.handleShiftDown)
        document.addEventListener("keyup", fbAction.handleShiftUp)
        return function(){
            document.removeEventListener("keydown", fbAction.handleShiftDown)
            document.removeEventListener("keyup", fbAction.handleShiftUp)
        }
    },[])
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-2 mb-3">
                    <li className="breadcrumb-item color-primary">Nasabah</li>
                    <li className="breadcrumb-item">
                        <a href="#">Registrasi Nasabah</a>
                    </li>
                    <li className="breadcrumb-item">{capitalize(fbState.paramUrl?.mode)}</li>
                </ol>
            </nav>
            <FrameRegistration
                manualDone={(mode !== "registration")}
                steps={[
                    {
                        name: 'Identitas Nasabah',
                        id: '0',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },
                    {
                        name: 'Pejabat Korporat',
                        id: '1',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },
                    {
                        name: 'CDD',
                        id: '2',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },

                    {
                        name: 'Relasi Nasabah',
                        id: '3',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },
                    {
                        name: 'Beneficiary Owner',
                        id: '4',
                        whiteSpace: 'nowrap',
                        isDone: (mode !== "registration"),
                    },
                ]}
                activeStep={fbState.step}
                onStepClick={(e) => { if (mode !== "registration") { fbAction.clickStep(e) } }}
            >
                <div id="step" dataStep={0}>
                    <IdentitasNasabah />
                </div>
                <div id="step" dataStep={1}>
                    <PejabatKorporat />
                </div>
                <div id="step" dataStep={2}>
                    <DataCCD />
                </div>
                <div id="step" dataStep={3}>
                    <RelasiNasabah />
                </div>
                {/* <div id="step" dataStep={4}>
                    <RelasiAhliWaris />
                </div> */}
                <div id="step" dataStep={4}>
                    <BeneficiaryOwner />
                </div>
                <div id="footer">
                    <div className="row">
                        <div className="col">
                            {
                                mode === "otorisasi" && (
                                    <button onClick={() => history.goBack()} className="btn btn-sm btn-outline-primary">
                                        Back
                                    </button>
                                )
                            }
                            {
                                fbState.formEditable &&
                                <button className="btn btn-outline-danger"
                                    onClick={fbAction.resetForm}
                                >
                                    Reset
                                </button>
                            }
                            <button
                                onClick={fbAction.nextStep}
                                className={`
                                    btn btn-${fbState.step === 4 ? "secondary" :
                                        "orange-primary"
                                    } mr-2 float-right
                                `}
                            >
                                Selanjutnya
                            </button>


                            <button
                                onClick={fbAction.prevState}
                                className={`
                                    btn btn-${fbState.step === 0 ? "secondary" :
                                        "orange-primary"
                                    } mr-2 float-right
                                `}
                            >
                                Sebelumnya
                            </button>

                            {
                                fbState.formEditable &&
                                fbState.step === 4 &&
                                <button onClick={fbAction.confirmPostNasabah} className="btn btn-primary float-right mr-2">
                                    Simpan
                                </button>
                            }

                            {
                                fbState.paramUrl?.mode === "otorisasi" && (
                                    <div style={{
                                        width: "fit-content",
                                        float: "right",
                                        borderRight: "1px sold gray",
                                        margin: "0px 5px"
                                    }}>
                                        <button
                                            onClick={() => fbAction.responseOtor("APPROVED")}
                                            className="btn btn-success mr-2">
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => fbAction.responseOtor("REJECTED")}
                                            className="btn btn-danger mr-2">
                                            Reject
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </FrameRegistration>

            {/* modal Alert */}
            <Modal show={fbState.dataAlert.show} //onHide={fbAction.closeAlert} 
                backdrop="static"
            >
                {
                    fbState.dataAlert.header !== null && fbState.dataAlert.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {fbState.dataAlert.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <h5 style={{ textAlign: "center", margin: "5px", textTransform: "capitalize" }}>{fbState.dataAlert.title}</h5>
                            <div className="box">
                                <p>
                                    {fbState.dataAlert.body}
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            <button onClick={() => {
                                fbAction.closeAlert();
                                if (fbState.dataAlert.callBack !== null && fbState.dataAlert.callBack !== undefined) fbState.dataAlert.callBack();
                            }
                            } className="btn btn-primary btn-sm">
                                OK
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal alert */}
            {/* modal confirm */}
            <Modal show={fbState.dataConfirm.show}

                backdrop="static"
            >
                {
                    fbState.dataConfirm.header !== null && fbState.dataConfirm.header !== "" && (
                        <Modal.Header>
                            <Modal.Title>
                                {fbState.dataConfirm.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {/* <h4 style={{ textAlign: "center" }}>{fbState.dataConfirm.title}</h4> */}

                            {/* <div className="box"> */}
                            <h5>
                                {fbState.dataConfirm.body}
                            </h5>
                            {
                                fbState.dataConfirm.witTextArea && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group form-group-sm">
                                                <label>Keterangan :</label>
                                                <textarea
                                                    className="form-control form-control-sm"
                                                    value={fbState.otorDesc}
                                                    onChange={(e) => fbAction.setOtorDesc(e.target.v)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {/* </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            {
                                fbState
                                    .dataConfirm
                                    .closeButton && <button onClick={fbAction.closeAlert} className="btn btn-secondary btn-sm float-right mr-2">
                                    Close
                                </button>
                            }
                            {
                                fbState
                                    .dataConfirm
                                    .footerButton?.map(
                                        ({
                                            className = "",
                                            onClick = () => null,
                                            style = {},
                                            name = "Ok" },
                                            i) => <button
                                                key={i}
                                                className={`${className} float-right mr-2`}
                                                onClick={onClick}
                                                style={style}
                                            >
                                                {name}
                                            </button>
                                    )
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal confirm */}
            {/* mdoal loading */}
            <Modal show={fbState.loading.loadingPost?.show}
                backdrop="static"
            >
                <Modal.Body>
                    <div style={{
                        margin: "auto",
                        width: "fit-content",
                        padding: "5% 0%",
                        textAlign: "center",

                    }}>
                        <NewLoading width="100px" />
                        <h5 className="text-base">{fbState.loading?.loadingPost?.msg}</h5>
                    </div>
                </Modal.Body>
            </Modal>
            {/*  */}
        </>
    )
}
export default () => <FbProvider><FbIndex /></FbProvider>