import React from 'react';
import { Modal } from 'react-bootstrap';
import useRekening from '../useRekening';
import useDataBuku from '../../data-buku-tabungan/useDataBuku';
import ModalCetakNamaPrint from './printCetaNama';
import { LoadingIcon } from '../../../../../controls';
import Skeleton from 'react-loading-skeleton';
const ModalCetakNamaPassbook = () => {
    const { nfState, nfAction } = useDataBuku();
    const { formData = {}, formEditable = false, dataCetakNama = {}, loadingListTrx = false, otorMode = false } = nfState;
    return (
        <>
            {/* modal utama */}
            <Modal size={"s"} show={nfState.modalCetakNama} onHide={nfAction.closeCetakNama} >
                <Modal.Header closeButton>
                    <Modal.Title>Cetak Nama Pasbook</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box mb-3">
                        <div className="row">
                            <div className="col-12 align-self-center bns-form">
                                {
                                    nfState.loadingCetakStatement ? <Skeleton className="mt-3" width={"100%"} height={30} /> : (

                                        <div className="form-group">
                                            <label>Nomor Register Buku</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                readOnly
                                                placeholder="Otomatis terisi dari sistem"
                                                value={dataCetakNama?.nomor_register_buku_lama}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 align-self-center bns-form">
                                {
                                    nfState.loadingCetakStatement ? <Skeleton className="mt-3" width={"100%"} height={30} /> : (

                                        <div className="form-group">
                                            <label>Nomor Register Aktif</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                readOnly
                                                placeholder="Otomatis terisi dari sistem"
                                                value={dataCetakNama?.nomorregisterbuku_aktif}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 align-self-center bns-form">
                                {
                                    nfState.loadingCetakStatement ? <Skeleton className="mt-3" width={"100%"} height={30} /> : (

                                        <div className="form-group">
                                            <label>Nomor Rekening</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                readOnly
                                                placeholder="Otomatis terisi dari sistem"
                                                value={dataCetakNama?.nomor_rekening}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 align-self-center bns-form">
                                {
                                    nfState.loadingCetakStatement ? <Skeleton className="mt-3" width={"100%"} height={30} /> : (

                                        <div className="form-group">
                                            <label>Nama Rekening</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                readOnly
                                                placeholder="Otomatis terisi dari sistem"
                                                value={dataCetakNama?.nama_rekening}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 align-self-center bns-form">
                                {
                                    nfState.loadingCetakStatement ? <Skeleton className="mt-3" width={"100%"} height={30} /> : (

                                        <div className="form-group">
                                            <label>Kode Cabang</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                readOnly
                                                placeholder="Otomatis terisi dari sistem"
                                                value={dataCetakNama?.kode_cabang}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-9 align-self-center bns-form">
                                {
                                    nfState.loadingCetakStatement ? <Skeleton className="mt-3" width={"100%"} height={30} /> : (

                                        <div className="form-group">
                                            <label>Nama Cabang</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                readOnly
                                                placeholder="Otomatis terisi dari sistem"
                                                value={dataCetakNama?.nama_cabang}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 align-self-center bns-form">
                                {
                                    nfState.loadingCetakStatement ? <Skeleton width={"100%"} height={90} className="mt-3" />: (

                                        <div className="form-group">
                                            <label>Alamat</label>
                                            <textarea
                                                className="form-control"
                                                // type="text"
                                                style={{
                                                    resize: "none"
                                                }}
                                                readOnly
                                                placeholder="Otomatis terisi dari sistem"
                                                value={dataCetakNama?.alamat}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className='col'>
                            <button
                                onClick={() => nfAction.closeCetakNama()}
                                className="btn btn-outline-danger float-right ml-2">
                                Batal
                            </button>
                            {
                                nfState.loadingCetakStatement ? <></> : (
                                    otorMode ? (
                                        <>
                                            <button className="btn btn-primary float-right ml-2" onClick={() => nfAction.responseOtor("APPROVED")}>
                                                Approve
                                            </button>
                                            <button className="btn btn-danger float-right ml-2" onClick={() => nfAction.responseOtor("REJECTED")}>
                                                Reject
                                            </button>
                                        </>
                                    ) : (
                                        dataCetakNama?.is_boleh_cetak === "T" ? <button
                                            onClick={() => nfAction.openCetakNamaPrint(dataCetakNama?.nomor_rekening)}
                                            className="btn btn-outline-success mr-2">
                                            Cetak
                                        </button> : <button
                                            onClick={() => nfAction.confirmRequestCetak()}
                                            className="btn btn-outline-success mr-2">
                                            Request Cetak
                                        </button>
                                    )
                                )
                            }

                        </div>
                        {/* <div className="col">
                        <button
                            onClick={() => nfAction.closeCetakNama()}
                            className="btn btn-outline-danger float-right">
                            Batal
                        </button>
                        {
                            nfState.otorMode ? (
                                <>
                                    <button
                                        onClick={() => nfAction.responseOtor("APPROVED")}
                                        className="btn btn-success mr-2">
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => nfAction.responseOtor("REJECTED")}
                                        className="btn btn-danger mr-2">
                                        Reject
                                    </button>
                                </>
                            ):(
                                <>
                                    <button
                                        onClick={() => nfAction.openCetakNamaPrint(dataCetakNama?.nomor_rekening)}
                                        className="btn btn-outline-success mr-2">
                                        {loadingListTrx ? <LoadingIcon /> : (nfState.modeReqStatement ? "Request Cetak" : "Cetak")}
                                    </button>
                                </>
                            )
                        }
                    </div> */}
                    </div>
                </Modal.Footer>
            </Modal>
            <ModalCetakNamaPrint />
            {/* end modal utama */}
        </>
    );
}

export default ModalCetakNamaPassbook;