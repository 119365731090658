import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
// import { useKeycloak } from '@react-keycloak/web';
import { useModalAlert } from '../modalAlert';
import { createForm } from '../../../module/createFormList';
import { TableList } from '..';
import useFetch from '../../../hooks/useFetch';
import useKeycloak from '../../../hooks/keycloak.module';

export default function ({
    show = false,
    onChoice = () => null,
    onHide = () => null,
    products = [],
    product_code = "",
}) {
    const { keycloak } = useKeycloak()
    
        const [dataList, setDataList] = useState([])
        const [formData, setDataForm] = useState({
            kode_cabang: keycloak?.tokenParsed?.branch_code,
            norek_request: "",
            kode_produk: "",
            jumlah: ""
        })

    useEffect(function () {
        if (show) {
            setDataForm({
                kode_cabang: keycloak?.tokenParsed?.branch_code,
                norek_request: "",
                kode_produk: "",
                jumlah: ""
            });
            setDataList([])
        }
    }, [show])
    const { doGet } = useFetch()
    async function getList() {

        if (

            formData.jumlah === "" || formData.jumlah === undefined || formData.jumlah === null ||
            formData.norek_request === "" || formData.norek_request === undefined || formData.norek_request === null ||
            formData.kode_produk === "" || formData.kode_produk === undefined || formData.kode_produk === null
        ) return setDataAlert({ show: true, body: `Lengkapi form terlebih dahulu`, title: `form belum lengkap`, callBack: () => null });
        openLoading()
        var url = `/rekening/cetak-daftar-rekening`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: { ...formData },
                service: "acc"

            });

            if (statusCode === 200) {
                if (status === "00") {
                    // console.log(data)
                    setDataList(data)
                }
                else setDataAlert({ show: true, body: remark, title: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        closeLoading()
    }
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    useEffect(function(){
        setDataForm( v => ({
            ...v,
            kode_produk: product_code
        }))
    }, [product_code])
    return (
        <Modal show={show} size="md" backdrop="static">
            <Modal.Header onHide={onHide} closeButton>
                <Modal.Title>
                    Request nomor cantik rekening
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-12'>
                        {
                            createForm({
                                type: "text",
                                label: "Kode Cabang",
                                value: formData.kode_cabang,
                                required: true,
                                disabled: true
                            })
                        }
                        {
                            createForm({
                                label: "Request Nomor Rekening",
                                type: "number",
                                required: true,
                                maxLength: 6,
                                value: formData.norek_request,
                                onChange: (norek_request) => setDataForm(f => ({
                                    ...f, norek_request
                                })),
                            })
                        }
                        {
                            createForm({
                                type: "select",
                                label: "Kode Produk",
                                options: products,
                                value: formData.kode_produk,
                                required: true,
                                placeholder: "Pilih Porduk",
                                disabled: product_code !== "",
                                display: "nama_produk",
                                key: "kode_produk",
                                onChange: (kode_produk) => setDataForm(f => ({
                                    ...f, kode_produk
                                })),
                            })
                        }
                        {
                            createForm({
                                type: "number",
                                maxLength: 2,
                                required: true,
                                label: "Jumlah",
                                value: formData.jumlah,
                                onChange: (jumlah) => setDataForm(f => ({
                                    ...f, jumlah
                                })),
                            })
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 pl-3'>
                        <button onClick={getList} className='btn btn-sm btn-primary'>
                            Send Request
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <TableList
                            data={(dataList || []).map((nomor) => ({ nomor: nomor }))}
                            filter={false}
                            pagination={false}
                            withCard={false}

                            columns={[{
                                name: "Nomor Rekening",
                                field: "nomor"
                            }]}
                            withActions={true}
                            actionItems={[{
                                name: "Pilih Rekening",
                                onClick: (item)=> onChoice({ nomor: item.nomor, kode_produk: formData.kode_produk })
                            }]}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className='btn btn-sm btn-default' onClick={onHide}>Close</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}