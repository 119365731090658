import React, { memo, useEffect, useRef } from 'react';
import { AppAction, AppInterfaces } from '../../../../app.data';
import { createForm } from '../../../../module/createFormList';
import { FormRegistrasi } from './formRegistrasi';

const Step2 = AppInterfaces.appLoad(
    memo(({ editMode = false, store, ...props }) => {
        const appAction = useRef();
        const { formData, provinces, cities, districts, villages, postals } = store;
        function changeForm(key, subkey, value) {
            appAction.current.setForm(key, {
                ...formData[key],
                [subkey]: value,
            });
        }
        const dropdownDetil = [
            {
                key: 'eStatusRestriksi',
                param: 'status_restriksi',
            },
            {
                key: 'R_TUJUAN_BUKA_REKENING',
                param: 'tujuan_buka',
            },
            {
                key: 'R_SUMBER_DANA_REKENING',
                param: 'sumber_dana',
            },
            {
                key: 'eStatusKelengkapan',
                param: 'status_kelengkapan',
            },
        ];

        // useEffect(function () {
        //     dropdownDetil.forEach(({ key, param }) => {
        //         appAction.current.doGet({
        //             url: '/dropdowns',
        //             vari: param,
        //             param: {
        //                 key,
        //             },
        //         });
        //     });

        //     appAction.current
        //         .doGet({
        //             url: '/locations/provinces',
        //             vari: 'provinces',
        //         })
        //         .then((resp) => {
        //             if (editMode) {
        //                 const { list } = resp;
        //                 var code = list?.find(
        //                     (v) => v.province_name === formData._alamatAlternatif?.provinsi
        //                 )?.province_code;
        //                 appAction.current.doGet({
        //                     url: '/locations/cities',
        //                     vari: 'cities',
        //                     param: {
        //                         province_code: code,
        //                     },
        //                 });

        //                 appAction.current.doGet({
        //                     url: '/locations/sub-districts',
        //                     vari: 'districts',
        //                     param: {
        //                         city: formData._alamatAlternatif?.kotaKabupaten,
        //                     },
        //                 });

        //                 appAction.current.doGet({
        //                     url: '/locations/urbans',
        //                     vari: 'villages',
        //                     param: {
        //                         sub_district: formData._alamatAlternatif?.kecamatan,
        //                     },
        //                 });

        //                 appAction.current.doGet({
        //                     url: '/locations/postals-code',
        //                     vari: 'postals',
        //                     param: {
        //                         city: formData._alamatAlternatif?.kotaKabupaten,
        //                         sub_district: formData._alamatAlternatif?.kecamatan,
        //                         urban: formData._alamatAlternatif?.kelurahan,
        //                     },
        //                 });
        //             }
        //         });
        // }, []);

        return (
            <>
                <AppAction ref={appAction} />
                <FormRegistrasi
                    itemsForm={[
                        {
                            name: 'Informasi Lain',
                            rows: [
                                {
                                    rows: 1,
                                    required: true,
                                    label: 'Status Restriksi',
                                    type: 'select',
                                    disabled: false,
                                    readonly: false,
                                    options: [
                                        {
                                            key: 'Tidak',
                                            display: 'Tidak',
                                        },
                                        {
                                            key: 'Ya',
                                            display: 'Ya',
                                        },
                                    ],
                                    value: formData._informasiLain?.statusRestriksi,
                                    onChange: (value) =>
                                        changeForm('_informasiLain', 'statusRestriksi', value),
                                },
                                {
                                    rows: 1,
                                    label: 'Tujuan Pembukaan Rekening',
                                    type: 'select',
                                    // placeholder: "Pilih Tujuan Pembukaan Rekening",
                                    options: store.tujuan_buka,
                                    key: 'reference_code',
                                    display: 'reference_desc',
                                    disabled: false,
                                    readonly: false,
                                    value: formData._informasiLain?.tujuanBukaRekening,
                                    onChange: (value) =>
                                        changeForm('_informasiLain', 'tujuanBukaRekening', value),
                                },
                                {
                                    rows: 1,
                                    label: 'Sumber Dana',
                                    type: 'select',
                                    // placeholder: "Pilih Sumber Dana",
                                    options: store.sumber_dana,
                                    key: 'reference_code',
                                    display: 'reference_desc',
                                    disabled: false,
                                    readonly: false,
                                    value: formData._informasiLain?.sumberDana,
                                    onChange: (value) =>
                                        changeForm('_informasiLain', 'sumberDana', value),
                                },
                                {
                                    rows: 2,
                                    // placeholder: "Pilih status kelengkapan ",
                                    type: 'select',
                                    options: store.status_kelengkapan,
                                    key: 'reference_code',
                                    display: 'reference_desc',
                                    required: true,
                                    label: 'Status Kelengkapan',
                                    disabled: false,
                                    readonly: false,
                                    value: formData._informasiLain?.statusKelengkapan,
                                    onChange: (value) =>
                                        changeForm('_informasiLain', 'statusKelengkapan', value),
                                },
                                {
                                    rows: 2,
                                    type: 'custom',
                                    component: (
                                        <div className="row">
                                            <div className="col-md-8">
                                                {createForm({
                                                    rows: 1,
                                                    label: 'Marketing',
                                                    type: 'text',
                                                    placeholder: 'Marketing',
                                                    disabled: false,
                                                    readonly: false,
                                                    value: formData._informasiLain?.marketing,
                                                    onChange: (value) =>
                                                        changeForm(
                                                            '_informasiLain',
                                                            'marketing',
                                                            value
                                                        ),
                                                })}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-group-sm">
                                                    {/* <label>&nbsp;</label> */}
                                                    <button
                                                        style={{ marginTop: 30 }}
                                                        className="btn btn-sm btn-primary col"
                                                    >
                                                        &nbsp;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                            ],
                        },
                        {
                            name: 'Asuransi',
                            rows: [
                                {
                                    rows: 1,
                                    label: 'Rencana Asuransi Utama',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Rencana Asuransi Utama',
                                    disabled: false,
                                    readonly: false,
                                    value: formData._asuransi?.rencanaAsuransiUtama,
                                    onChange: (value) =>
                                        changeForm('_asuransi', 'rencanaAsuransiUtama', value),
                                },
                                {
                                    rows: 1,
                                    label: 'Tanggal Jatuh Tempo',
                                    type: 'date',
                                    options: [],
                                    placeholder: ' Tanggal Jatuh Tempo',
                                    disabled: false,
                                    readonly: false,
                                    value: formData._asuransi?.tanggalJatuhTempoAsuransi,
                                    onChange: (value) =>
                                        changeForm('_asuransi', 'tanggalJatuhTempoAsuransi', value),
                                },
                                {
                                    rows: 2,
                                    label: 'Rencana Asuransi Tambahan',
                                    type: 'text',
                                    options: [],
                                    placeholder: 'Rencana Asuransi Tambahan',
                                    disabled: false,
                                    readonly: false,
                                    value: formData._asuransi?.rencanaAsuransiTambahan,
                                    onChange: (value) =>
                                        changeForm('_asuransi', 'rencanaAsuransiTambahan', value),
                                },
                            ],
                        },
                        // {
                        //     name: "Alamat Alternatif",
                        //     rows: [
                        //         {
                        //             rows: 1,
                        //             label: "Alamat",
                        //             type: "textarea",
                        //             options: [],
                        //             placeholder: "Alamat",
                        //             disabled: false,
                        //             readonly: false,
                        //             value: formData._alamatAlternatif?.alamat,
                        //             onChange: (value) => changeForm("_alamatAlternatif", "alamat", value)

                        //         }, {
                        //             rows: 1,
                        //             type: "custom",
                        //             component: (
                        //                 <div className="row">
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Provinsi",
                        //                             type: "select",
                        //                             placeholder: "Provinsi",
                        //                             options: provinces,
                        //                             key: "province_name",
                        //                             display: "province_name",
                        //                             disabled: false,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.provinsi,
                        //                             onChange: (value) =>{
                        //                                 changeForm("_alamatAlternatif", "provinsi", value)
                        //                                 var code = provinces.find(v=>v.province_name === value)?.province_code
                        //                                 appAction
                        //                                 .current
                        //                                 .doGet({
                        //                                     url: "/locations/cities",
                        //                                     vari: "cities",
                        //                                     param: {
                        //                                         province_code: code
                        //                                     }
                        //                                 })
                        //                             }
                        //                         })}
                        //                     </div>
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Kabupaten",
                        //                             type: "select",
                        //                             placeholder: "Kabupaten",
                        //                             disabled: false,
                        //                             readonly: false,
                        //                             options: cities?.map(v => ({ key:v, display:v })) ,
                        //                             value: formData._alamatAlternatif?.kotaKabupaten,
                        //                             onChange: (value) => {
                        //                                 changeForm("_alamatAlternatif", "kotaKabupaten", value)
                        //                                 appAction
                        //                                 .current
                        //                                 .doGet({
                        //                                     url: "/locations/sub-districts",
                        //                                     vari: "districts",
                        //                                     param: {
                        //                                         city: value
                        //                                     }
                        //                                 })
                        //                             }
                        //                         })}
                        //                     </div>
                        //                 </div>
                        //             )
                        //         },
                        //         {
                        //             rows: 1,
                        //             type: "custom",
                        //             component: (
                        //                 <div className="row">
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Kecamatan",
                        //                             type: "select",
                        //                             placeholder: "Kecamatan",
                        //                             options: districts?.map(v => ({ key:v, display:v })) ,
                        //                             disabled: false,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.kecamatan,
                        //                             onChange: (value) => {
                        //                                 changeForm("_alamatAlternatif", "kecamatan", value)
                        //                                 appAction
                        //                                 .current
                        //                                 .doGet({
                        //                                     url: "/locations/urbans",
                        //                                     vari: "villages",
                        //                                     param: {
                        //                                         sub_district: value
                        //                                     }
                        //                                 })
                        //                             }
                        //                         })}
                        //                     </div>
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Kelurahan",
                        //                             type: "select",
                        //                             placeholder: "Kelurahan",
                        //                             options: villages?.map(v => ({ key:v, display:v })) ,
                        //                             disabled: false,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.kelurahan,
                        //                             onChange: (value) => {
                        //                                 changeForm("_alamatAlternatif", "kelurahan", value)
                        //                                 appAction
                        //                                 .current
                        //                                 .doGet({
                        //                                     url: "/locations/postals-code",
                        //                                     vari: "postals",
                        //                                     param: {
                        //                                         city:formData._alamatAlternatif?.kotaKabupaten,
                        //                                         sub_district: formData._alamatAlternatif?.kecamatan,
                        //                                         urban:value
                        //                                     }
                        //                                 })
                        //                             }
                        //                         })}
                        //                     </div>
                        //                 </div>
                        //             )
                        //         },
                        //         {
                        //             rows: 2,
                        //             type: "custom",
                        //             component: (
                        //                 <div className="row">
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "RT / RW",
                        //                             type: "number",
                        //                             placeholder: "RT",
                        //                             disabled: false,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.rt,
                        //                             onChange: (value) => changeForm("_alamatAlternatif", "rt", value)
                        //                         })}
                        //                     </div>
                        //                     <div className="col-md-6">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: <>&nbsp;</>,
                        //                             type: "number",
                        //                             placeholder: "RW",
                        //                             disabled: false,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.rw,
                        //                             onChange: (value) => changeForm("_alamatAlternatif", "rw", value)
                        //                         })}
                        //                     </div>
                        //                 </div>
                        //             )
                        //         }, {
                        //             rows: 2,
                        //             type: "custom",
                        //             component: (
                        //                 <div className="row">
                        //                     <div className="col-md-12">
                        //                         {createForm({
                        //                             rows: 1,
                        //                             label: "Kode Pos",
                        //                             type: "select",
                        //                             options: postals?.map(v=>({ key:v, display:v })),
                        //                             placeholder: "Kode Pos",
                        //                             disabled: false,
                        //                             readonly: false,
                        //                             value: formData._alamatAlternatif?.kodePos,
                        //                             onChange: (value) => changeForm("_alamatAlternatif", "kodePos", value)
                        //                         })}
                        //                     </div>
                        //                     {/* <div className="col-md-4">
                        //                     <div className="form-group form-group-sm">
                        //                         <button style={{ marginTop: 30, background:"#E6530D"}} className="btn btn-sm btn-primary col">
                        //                          Cari Kode Pos
                        //                         </button>
                        //                     </div>
                        //                 </div> */}
                        //                 </div>
                        //             )
                        //         }, {
                        //             rows: 2,
                        //             label: "Telepon Rumah",
                        //             type: "text",
                        //             options: [],
                        //             placeholder: "Telepon Rumah",
                        //             disabled: false,
                        //             readonly: false,
                        //             value: formData._alamatAlternatif?.telepon,
                        //             onChange: (value) => changeForm("_alamatAlternatif", "telepon", value)

                        //         },

                        //     ]
                        // }
                    ]}
                />
                <div className="row">
                    <div
                        className="col-md-12 text-center"
                        style={{ paddingLeft: 100, paddingRight: 100 }}
                    >
                        <div
                            style={{
                                background: '#FFF3CD',
                                width: '100%',
                                padding: 10,
                            }}
                        >
                            Sebelum melanjutkan proses registrasi, pastikan data yang Anda masukan
                            sudah benar.
                        </div>
                    </div>
                </div>
            </>
        );
    })
);

export default Step2;
