import React from 'react';
import { Modal } from 'react-bootstrap';
import { addCommas } from '../../../../../module';
import { TableList } from '../../../../controls';
import useKasAtm from './useKasAtm';

const { Title, Header, Body, Footer } = Modal;
const ModalListTeller = () => {
    const { atmActions, atmState } = useKasAtm()
    return (
        <Modal size='xl' show={atmState.showModalTeller} backdrop="static" >
            <Header closeButton onHide={atmActions.closeModalTeller}>
                <Title>List ATM</Title>
            </Header>
            <Body>
                <TableList
                    withCard={false}
                    filter={false}
                    formsFilter={[
                        {
                            type: 'text',
                            placeholder: 'Keyword',
                            disabled: false,
                            readonly: false,
                            onChange: (e) => atmActions.setFilter({ keyword: e }),
                            value: atmActions.filter?.keyword
                        }
                    ]}
                    resetFilter={false}
                    onFilter={() => atmActions.filterTeller()}
                    isLoading={atmState.loadingTeller}
                    data={atmState.listTeller || []}
                    columns={
                        [
                            {
                                name: 'Nama Rekening',
                                field: 'nama_rekening',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Nomor Rekening',
                                field: 'nomor_rekening',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Nama Cabang',
                                field: 'nama_cabang',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Kode Cabang',
                                field: 'kode_cabang',
                                maxWidth: '150px',
                            },
                            
                            {
                                name: 'Saldo',
                                field: 'saldo',
                                maxWidth: '175px',
                                className:"text-right",
                                onRender: (item) => addCommas(parseFloat(item.saldo).toFixed(2))
                            },
                        ]}
                    pagination={false}
                    withAction={true}
                    actionItems={
                        [
                            {
                                name: 'Pilih ATM',
                                onClick: async (e) => {
                                    atmActions.pilihTeller(e)
                                    // let get = false;
                                    // if (ftAction.pilihNasabah) get = await ftAction?.pilihNasabah(e, pdrState.filter.filterType);
                                    // if (get) pdrAction.closeModal()
                                },
                                className: "text-danger"
                            },
                        ]}
                    onChangeShow={atmActions.changeShow}
                    onNextPage={atmActions.nextPageTeller}
                    onPrevPage={atmActions.prevPageTeller}
                    curPage={atmState.paginationTeller?.page}

                />
            </Body>
            <Footer>
                <div className='row'>
                    <div className='col'>
                        <button onClick={atmActions.closeModalTeller} className='btn btn-sm float-right btn-default'>
                            Close
                        </button>
                    </div>
                </div>
            </Footer>
        </Modal>
    );
}

export default ModalListTeller;