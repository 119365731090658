import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useFetch from '../../../../../hooks/useFetch';
import { useModalAlert } from '../../../../controls/modalAlert';
// import { useKeycloak } from '@react-keycloak/web';
import { usePrintModal } from '../../../../controls/modalPrint';
import ModalListTeller from './modalTListTeller';
import useKeycloak from '../../../../../hooks/keycloak.module';
const aklContext = createContext({
    aklState: {}, aklAction: {}
});

export function AKLProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const { doGet, doPost, wait } = useFetch();
    const location = useLocation();

    const [err, setErr] = useState(true);
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [formData, setFormData] = useState({
        "nominal": 0,
        "user_id_teller_pengirim": "",
        "kode_valuta" : "IDR",
        "nomor_referensi": "",
        "keterangan": "",
        "info_tlr_debet": {
            "user_id": "",
            "kode_valuta": "IDR",
            "nama_valuta": "",
            "kode_valuta_saldo": "",
            "saldo": 0,
            "nama_teller": "",
            "kode_cabang_tlr": "",
            "nama_cabang_tlr": ""
        },
        "denominasi": [
        ]
    })


    const { keycloak } = useKeycloak()
    const history = useHistory()
    const [formEditable, setFormEditable] = useState(true)
    const paramUrl = useParams()
    const [otorDesc, setOtorDesc] = useState("")

    const [dataPrint, setDataPrint] = useState({
        "transaksi": {
            "id_transaksi": "",
            "id_transaksi_core": "",
            "nomor_rekening_debet": "",
            "nomor_rekening_credit": "",
            "nominal": "",
            "nomor_referensi": "",
            "keterangan": "",
            "keterangan_otor": null,
            "kode_valuta": "",
            "kode_transaksi": "",
            "status_otorisasi": "",
            "user_input": "",
            "user_otorisasi": "",
            "tanggal_input": "",
            "tanggal_otor": "",
            "status_reversal": null
        },
        "info_transaksi": {
            "id_info": null,
            "id_transaksi": "",
            "kode_bank_kliring": null,
            "nama_bank_kliring": null,
            "nama_rekening_penerima": null,
            "ats_permintaan": null,
            "keterangan": null,
            "norek_biaya": null,
            "saldo_teller_before": "",
            "saldo_teller_after": null,
            "nominal_ekuivalen": "",
            "biaya": "",
            "biaya_ekuivalen": "",
            "sumber_biaya": "",
            "kurs_jual": "",
            "kurs_beli": "",
            "kurs_buku": "",
            "pengirim_nomor_nasabah": null,
            "pengirim_nama": null,
            "pengirim_alamat": null,
            "pengirim_jenis_identitas": null,
            "pengirim_nomor_identitas": null,
            "pengirim_nomor_tlpn": null,
            "pengirim_nasabah_wic": null,
            "jenis_ttr": null,
            "tutup_kartu_atm": null,
            "norek_pindah_buku": null,
            "nama_rekening": "",
            "kode_valuta": "",
            "keterangan_valuta": "",
            "produk": "",
            "kode_cabang": "",
            "nama_cabang": "",
            "jenis_penduduk": null,
            "jenis_nomor_referensi": null,
            "jenis_nasabah": null,
            "total_denom": null,
            "selisih_saldo": null,
            "jenis_transaksi": null,
            "kode_valuta_teller": null,
            "saldo_rekening": null,
            "nama_rekening_pinbuk": null,
            "cabang_rekening_pinbuk": null
        }
    })
    const { mpAction, mpState } = usePrintModal();
    async function getUserInfo() {
        // let data = await keycloak.loadUserInfo();
        // // // console.log(data.preferred_username)
        // setFormData(f => ({
        //     ...f,
        //     user_id_teller_pengirim: data.preferred_username
        // }));
    }
    useEffect(() => {
        if (paramUrl.mode === "otorisasi") {
            if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            // if (location.state === null || location.state === undefined) return setDataAlert({ show: true, body: "Tidak ada data nasabah terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => history.push("/") });
            getDataDetail()
            // getDataPerorangan(location.state?.dbId);
        } else {
            // getUserInfo();
            // getSaldo();
            getDenom()
            getPenerima();
        }
        // } 
        setFormEditable(paramUrl.mode !== "otorisasi");
    }, [paramUrl.mode])

    function sortingDenom(dataDenom = [], namaDenom) {
        return dataDenom.filter(({ jenis = "" }) => (
            jenis.toUpperCase() === namaDenom.toUpperCase()
        )).sort(function (a, b) {
            return b.nilai_denominasi - a.nilai_denominasi;
        })
    }

    // useEffect(function(){
    //     let saldo = parseInt(formData.info_transaksi?.saldo_teller_before || 0) + parseInt(formData?.nominal || 0) || 0;
    //     changeForm(saldo, "saldo_teller_after", "info_transaksi")
    // },[formData])

    useEffect(function () {
        if (formData.nominal > 0) setErr(false)
        else {
            setErr(true)
            //setDataAlert({ show: true, body: `Jumlah saldo ${formData.info_transaksi.saldo_teller_before.toLocaleString()}, Jumlah amnil kas tidak bisa kurang dari sama dengan 0`, title: `Setoran masih 0`, header: "Info", callBack: () => null });
        }
    }, [formData.nominal])

    async function getPenerima(id_user = null, kode_cabang = null ) {
        const userInfo = await keycloak.loadUserInfo();
        if(id_user === null ) id_user = userInfo.preferred_username;
        if(kode_cabang === null ) kode_cabang = userInfo.branch_code;
        // // console.log(userInfo)
        openLoading()
        var url = `/kasteller/get-data-info-teller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    id_user,
                    kode_cabang
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    setFormData(p => ({
                        ...p,
                        nama_user_penerima:data?.nama_user,
                        kode_cabang_penerima:data?.kode_cabang,
                        nama_cabang_penerima:data?.nama_cabang,
                }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }

    async function getTeller(userName) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/kasteller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userName,
                    kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    setFormData(prevState => ({
                        ...prevState,
                        // nama_user_pengirim:data?.nama_user,
                        // kode_cabang_pengirim:data?.kode_cabang,
                        // nama_cabang_pengirim:data?.nama_cabang,
                        info_transaksi: {
                            saldo_teller_after: parseFloat(data?.saldo || "0").toFixed(2),
                            saldo_teller_before: parseFloat(data?.saldo || "0").toFixed(2),
                        }
                    }))
                    getTellerInfo(userName)
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }

    async function getTellerInfo(userName) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/kasteller/get-data-info-teller`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    id_user: userName,
                    // kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    setFormData(prevState => ({
                        ...prevState,
                        nama_user_pengirim: data?.nama_user,
                        kode_cabang_pengirim: data?.kode_cabang,
                        nama_cabang_pengirim: data?.nama_cabang,
                    }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }

    function changeJumlahLembar(jumlah_lembar_koin, key) {

        if (isNaN(jumlah_lembar_koin)) return false;
        let nominal = 0;
        const de = formData.denominasi
        const denominasi = de.map((d, i) => {

            if (key === i) {
                nominal += (parseInt(jumlah_lembar_koin) * d.nilai_denominasi)
                return {
                    ...d,
                    jumlah_lembar_koin,
                }

            } else {
                nominal += (d.jumlah_lembar_koin * d.nilai_denominasi)
                return d
            }
        });

        if (isNaN(nominal)) {
            nominal = 0
        }
        // let saldo_teller_after = parseFloat(formData.info_transaksi.saldo_teller_before) + parseFloat(nominal);
        if (nominal > 0) setErr(false)
        else {
            setErr(true)
            // setDataAlert({ show: true, body: `Jumlah saldo ${formData.info_transaksi.saldo_teller_before.toLocaleString()}, Jumlah amnil kas tidak bisa kurang dari sama dengan 0`, title: `Setoran masih 0`, header: "Info", callBack: () => null });
        }
        setFormData(prevState => ({
            ...prevState,
            nominal,
            denominasi
        }));
    }

    async function getDenom() {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        var url = `/brangkas`;
        try {
            const { statusCode, status, remark, statusText, data = {} } = await doGet({
                url: url,
                param: {
                    user_id: userInfo.preferred_username,
                    kode_valuta: formData.kode_valuta || "IDR"
                },
                service: "trx"
            });

            if (statusCode === 200) {
                if (status === "00") {
                    const denominasi = ([
                        ...(sortingDenom(data.InfoDenominasiBrangkas || [], "lembar") || []),

                        ...(sortingDenom(data.InfoDenominasiBrangkas || [], "koin") || []),

                        ...(sortingDenom(data.InfoDenominasiBrangkas || [], "lembar (utle)") || []),
                    ]).map(({
                        nilai_denominasi = 0, jumlah_lembar_koin = 0, jenis = ""
                    }) => ({
                        nilai_denominasi,
                        jenis,
                        jumlah_lembar_koin: 0
                    }))

                    setFormData(p => ({
                        ...p,
                        // nama_user_penerima:data?.RekeningKas?.nama_user,
                        // kode_cabang_penerima:data?.RekeningKas?.kode_cabang,
                        // nama_cabang_penerima:data?.RekeningKas?.nama_cabang,
                        denominasi
                    }))
                    // // // // console.log(data)
                    //    changeForm(parseInt(data.saldo), "saldo_teller_before", "info_transaksi")
                    // setFormData(prevState => ({
                    //     ...prevState,
                    //     info_transaksi: {
                    //         saldo_teller_after: parseInt(data.saldo),
                    //         saldo_teller_before: parseInt(data.saldo)
                    //     }
                    // }))
                }
                else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}`, callBack: () => null });
            } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: 'Terjadi Kesalahan', header: "Error", callBack: () => null });
        }
        wait(3000);
        closeLoading()
    }

    // post ambil kas
    async function doTrx() {

        closeConfirm();
        // // // console.log(formData);
        const userInfo = await keycloak.loadUserInfo();
        // const err = validasi();
        // if (err) {
        //     return setDataAlert({ show: true, body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
        // }
        openLoading()
        try {
            const body = {
                "user_id_teller_pengirim": formData.user_id_teller_pengirim,
                "nominal": parseInt(formData.nominal),
                "keterangan": formData.keterangan,
                "kode_valuta": formData.kode_valuta,
                "denominasi": formData.denominasi
            }
            const resp = await doPost({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    "x-user-id": userInfo.preferred_username
                },
                data: { ...formData, uid: keycloak.uid},
                url: "/ambilkastlrlain", // ganti sesuai api
                service: "trx" //ganti sesuai service yang dipakai di api
            });
            const { statusCode, remark = "", data = null, status = "01", statusText = "" } = resp;
            if (statusCode === 200) {
                if (status === "00") {

                    setDataPrint(data)
                    // mpAction.openModal("modalTrx", function () {
                    //     closeConfirm();
                    //     resetForm()
                    // })
                    setDataAlert({
                        show: true, body: "Transaksi berhasil. Transaksi perlu diotorisasi oleh Supervisor dan disetujui Teller Pengirim", title: ``, header: "Success", callBack: () => {
                            closeConfirm();
                            keycloak.updateUID()
                            resetForm();
                        }
                    });
                }
                else if(status === "05"){ 
                    closeConfirm();
                    resetForm();
                }
                else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
        } catch (error) {
            setDataAlert({ show: true, body: error.toString(), title: ``, header: "Error", callBack: () => null });
        }
        closeLoading()
    }

    useEffect(function () {
        // // // console.log(dataPrint)
    }, [dataPrint])
    function resetForm() {
        setFormData({
            "nominal": 0,
            "user_id_teller_pengirim": "",
            "kode_valuta" : "IDR",
            "nomor_referensi": "",
            "keterangan": "",
            "info_tlr_debet": {
                "user_id": "",
                "kode_valuta": "IDR",
                "nama_valuta": "",
                "kode_valuta_saldo": "",
                "saldo": 0,
                "nama_teller": "",
                "kode_cabang_tlr": "",
                "nama_cabang_tlr": ""
            },
            "denominasi": [
            ]
        });

        getDenom()
    }

    function validasi(fieldRequired = null) {

        //  field required lists
        if (fieldRequired === null) {
            // fieldRequired = ["nomorCIF", "nama_produk", "nama_rekening"];
        }
        const keys = Object.keys(formData)
        var errS = false;
        keys.forEach(function (key) {
            if (formData[key] instanceof Object) {
                const tKey = Object.keys(formData[key]);
                tKey.forEach(element => {
                    if (fieldRequired.some(v => element === v)) {
                        if (formData[key][element] === "" || !formData[key][element] === undefined || !formData[key][element] === null) {
                            errS = true
                        }
                    }
                });
            } else {
                if (fieldRequired.some(v => v === key)) {
                    if (formData[key] === "" || !formData[key] === undefined || !formData[key] === null) {
                        errS = true
                    }

                }
            }
        })

        return errS;
    }

    async function getDataDetail(dbId = location.state?.dbId) {

        let url = "/rekening/local";
        setLoading(prevState => ({ ...prevState, loading: true }));
        const { statusCode, remark = "01",
            data = null, status = "", statusText = "" } = await doGet({ url: `/ambilkastlrlain/${dbId}`, service: "trx" });
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    // // console.log(data)
                    setFormData(prevState => ({
                        ...prevState,
                        info_tlr_debet: {
                            ...prevState.info_tlr_debet,
                            user_id:data.info_transaksi?.user_id_tlr_db,
                            nama_cabang_tlr:data.info_transaksi?.nama_cabang_tlr_db,
                            kode_cabang_tlr:data.info_transaksi?.kode_cabang_tlr_db,
                            nama_teller:data.info_transaksi?.nama_tlr_db,
                            kode_valuta:data.info_transaksi?.kode_valuta_tlr_db,
                            kode_valuta_saldo:data.info_transaksi?.kode_valuta_saldo_tlr_db,
                            saldo:data.info_transaksi?.saldo_tlr_db
                        },
                        // user_id_teller_pengirim: data.info_transaksi?.user_id_teller_pengirim,
                        "nominal": parseInt(data.nominal),
                        "nomor_referensi": data.nomor_referensi,
                        "keterangan": data.keterangan,
                        "kode_valuta": data.kode_valuta,
                        "denominasi": data.denominasi,
                        "is_otor_teller":data.is_otor_teller
                    }));

                    getPenerima(data.user_input, data.kode_cabang_transaksi)

                    // if (data.info_transaksi?.user_id_teller_pengirim !== null && data.info_transaksi?.user_id_teller_pengirim !== undefined && data.info_transaksi?.user_id_teller_pengirim !== "") getTeller(data.info_transaksi?.user_id_teller_pengirim)
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoading(prevState => ({ ...prevState, loading: false }))
        // // // console.log(data)
    }

    // fungsi setDataForm
    function changeForm(value, key, subkey = null) {
        if (typeof value === "string") value = value.toUpperCase()
        if (subkey !== null) setFormData(prevState => ({ ...prevState, [subkey]: { ...prevState[subkey], [key]: value } }))
        else setFormData(prevState => ({ ...prevState, [key]: value }));
    }

    function confirmTrx() {
        let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan transaksi, cek kembali form transaksi. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Konfirmasi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => doTrx(), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }


    // response otorisasi
    function responseOtor(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchOtorisasi(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchOtorisasi(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        let keterangan = otorDesc;
        if (keterangan === "" || keterangan === null) {
            if (statusApprove === "APPROVED") keterangan = "Approve";
            else keterangan = "Rejected"
        }
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/${location.state.dbId}/${statusApprove}?keterangan=${keterangan}`,
                    method: "PUT",
                    service: "trx",
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); else setDataAlert({
                        show: true, body: remark, title: '', header: "Info", callBack: () => {

                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()

                        }
                    });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }

    const [listTeller, setListTeller] = useState([]);
    const [loadingTeller, setLoadingTeller] = useState(true);
    const [paginationTeller, setPaginationTeller] = useState({
        page: 1,
        show: 15
    });
    const [filter, setFilter] = useState({
        keyword: ""
    });
    const [showModalTeller, setShowModalTeller] = useState(false);



    function nextPageTeller({page}) {
        // console.log(page)
        setPaginationTeller(p => ({
            ...p,
            page
        }))
    }
    function prevPageTeller({page}) {
        // console.log(page)
        setPaginationTeller(p => ({
            ...p,
            page
        }))
    }
    function changeShow({limit}) {
        setPaginationTeller(p => ({
            ...p,
            show:limit
        }))
    }

    async function getListTeller() {
        setLoadingTeller(true)
        let resp = await doGet({
            url: `/kasteller/get-list-info-teller`,
            service: "trx",
            param: {
                keywords: filter.keyword,
                limit: paginationTeller.show,
                page: paginationTeller.page
            }
        });

        const { statusCode, remark = "01", data = [], status = "", statusText = "" } = resp
        // // console.log(resp)
        if (statusCode === 200) {
            if (status === "00") {
                if (data !== null) {
                    setListTeller(data)
                }
            }
            else setDataAlert({ show: true, body: remark, titile: `Status fetch data is ${statusCode}` });
        } else if (statusCode !== 401) setDataAlert({ show: true, body: remark, title: `${JSON.stringify(statusText)}`, header: "Error", callBack: () => null });
        setLoadingTeller(false)
    }

    function openModalTeller(){
        setShowModalTeller(true);
        setPaginationTeller({
            page:1,
            show:15
        });
        setFilter({
            keyword:""
        })
    }

    function closeModalTeller(){
        setShowModalTeller(false)
    }

    useEffect(function(){
        if(showModalTeller) getListTeller();
    },[showModalTeller, paginationTeller])

    function filterTeller(){
        setPaginationTeller({
            page:1,
            show:15
        })
    }
    async function pilihTeller(tlr) {
        // // console.log(tlr.id_user)
        const userInfo = await keycloak.loadUserInfo();
        // // console.log(userInfo.preferred_username)
        if(tlr.id_user === userInfo.preferred_username.toUpperCase()){
            setDataAlert({ show: true, body: "Mohon Maaf tidak dapat memilih User Sendiri",header:"Informasi" , callBack: () => {
                closeConfirm();
                resetForm();
            }});
        }
            
        setFormData(prevState => ({
            ...prevState,
            // nama_user_pengirim:data?.nama_user,
            // kode_cabang_pengirim:data?.kode_cabang,
            // nama_cabang_pengirim:data?.nama_cabang,
            "info_tlr_debet": {
                "user_id": tlr.id_user,
                "kode_valuta": "IDR",
                "nama_valuta": "INDONESIA RUPIAH",
                "kode_valuta_saldo": "IDR",
                "saldo": parseFloat(tlr?.saldo || "0").toFixed(2),
                "nama_teller": tlr?.nama_user,
                "kode_cabang_tlr": tlr?.kode_cabang,
                "nama_cabang_tlr":tlr?.nama_cabang
            }
            // info_transaksi: {
            //     saldo_teller_after: parseFloat(tlr?.saldo || "0").toFixed(2),
            //     saldo_teller_before: parseFloat(tlr?.saldo || "0").toFixed(2),
            // },
            // nama_user_pengirim: tlr?.nama_user,
            // kode_cabang_pengirim: tlr?.kode_cabang,
            // nama_cabang_pengirim: tlr?.nama_cabang,
            // user_id_teller_pengirim:tlr.id_user
        }))

        closeModalTeller()

    }


      // response otorisasi
      function responseOtorTeller(status) {
        let body = `Apakah anda yakin akan ${status} transaksi ini ?`;
        openAlertCustom({
            show: true,
            body,
            witTextArea: status === "REJECTED",
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Ya", onClick: () => fetchKasTeller(status), className: "btn btn-sm btn-success" },
                { name: "Tidak", onClick: () => closeConfirmCustom("otorisasi"), className: "btn btn-sm btn-secondary" }
            ]
        }, "otorisasi")
    }

    // fetch API response otorisasi
    async function fetchKasTeller(statusApprove) {
        const userInfo = await keycloak.loadUserInfo();
        openLoading()
        closeConfirmCustom();
        if (location.state !== null && location.state !== undefined) {
            try {
                const { statusCode, remark = "", data = null, status = "01", statusText = "" } = await doPost({
                    url: `/otorisasi/otor-akt-teller`,
                    method: "PUT",
                    service: "trx",
                    queryParam:{id_transaksi:(location.state.dbId),flag:statusApprove},
                    data: { keterangan: otorDesc },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "x-user-id": userInfo.preferred_username
                    }
                });
                if (statusCode === 200) {
                    if (status === "00") setDataAlert({
                        show: true, body: `Proses Otorisasi Berhasil`, title: ``, header: "Success", callBack: () => {
                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()
                        }
                    }); else setDataAlert({
                        show: true, body: remark, title: '', header: "Info", callBack: () => {

                            resetForm();
                            closeLoading();
                            closeAlert();
                            closeConfirmCustom();
                            closeConfirm();
                            history.goBack()

                        }
                    });
                } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
            } catch (error) {
                setDataAlert({ show: true, body: "Terjadi Kesalahan", title: ``, header: "Error", callBack: () => null });
            }
        } else setDataAlert({ show: true, body: "Tidak ada data otorisasi terpilih", title: `Otorisasi Not Found`, header: "Error", callBack: () => null });
        closeLoading()
        // setLoading(prevState => ({ ...prevState, loadingPost: { show: false, msg: "Please Wait ..." } }));
    }
    return (
        <aklContext.Provider value={{
            aklState: {
                formData,
                formEditable,
                err,
                otorDesc,
                dataPrint,
                listTeller,
                loadingTeller,
                filter,
                paginationTeller,
                showModalTeller

            },
            aklAction: {
                changeForm,
                changeJumlahLembar,
                confirmTrx,
                responseOtor,
                setOtorDesc,
                getTeller,
                setFormData,
                getTellerInfo,
                openModalTeller,
                nextPageTeller,
                changeShow,
                closeModalTeller,
                prevPageTeller,
                setFilter,
                setPaginationTeller,
                getListTeller,
                pilihTeller,
                filterTeller,
                responseOtorTeller

            }
        }}>
            {children}
            <ModalListTeller />
        </aklContext.Provider>
    )
}

export default function useAmbilKasLain() { return useContext(aklContext) }