// import { useKeycloak } from '@react-keycloak/web';
import React, { memo, useRef, useState } from 'react';
import { useOutside } from '../../../hooks';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useModalAlert } from "../../controls/modalAlert";
import useFetch from '../../../hooks/useFetch';
import { keycloakConfig } from '../../../keycloak.config';
import useKeycloak from '../../../hooks/keycloak.module';

const ImgUserDummy = () => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="3"
            y="3"
            width="38"
            height="38"
        >
            <circle cx="22.0002" cy="22" r="18.1818" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.0003 14.7272C19.9912 14.7272 18.3639 16.3545 18.3639 18.3636C18.3639 20.3727 19.9912 22 22.0003 22C24.0094 22 25.6366 20.3727 25.6366 18.3636C25.6366 16.3545 24.0094 14.7272 22.0003 14.7272ZM23.8184 18.3636C23.8184 17.3636 23.0003 16.5454 22.0003 16.5454C21.0003 16.5454 20.1821 17.3636 20.1821 18.3636C20.1821 19.3636 21.0003 20.1818 22.0003 20.1818C23.0003 20.1818 23.8184 19.3636 23.8184 18.3636ZM27.4548 27.4545C27.273 26.8091 24.4548 25.6363 22.0003 25.6363C19.5548 25.6363 16.7548 26.8 16.5457 27.4545H27.4548ZM14.7275 27.4545C14.7275 25.0363 19.573 23.8181 22.0003 23.8181C24.4275 23.8181 29.273 25.0363 29.273 27.4545V29.2727H14.7275V27.4545Z"
                fill="black"
                fill-opacity="0.54"
            />
        </g>
        <circle cx="22" cy="22" r="20.75" stroke="#DFE0EB" stroke-width="1.5" />
    </svg>
);

export default memo(() => {
    const [show, setShow] = useState(false);
    const triggerRef = useRef(null);
    const elemRef = useRef(null);
    const history = useHistory();

    useOutside(elemRef.current, triggerRef.current, () => {
        setShow(false);
    });

    const { keycloak } = useKeycloak();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction
    const { wait, doGet } = useFetch()

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    return (
        <>
            <button
                className="nav-link pr-0 pt-0 pb-0 focus:tw-outline-none"
                onClick={() => setShow((prevState) => !prevState)}
                data-toggle="dropdown"
                href="#"
                style={{
                    borderLeft: '1pt solid #bcbdc0',
                    paddingLeft: '20px',
                    marginLeft: '20px',
                    background: 'transparent',
                    border: 'none',
                }}
                ref={triggerRef}
            >
                <b> {keycloak?.idTokenParsed?.name} / {keycloak?.tokenParsed?.branch_code || "000"}</b> <ImgUserDummy />
            </button>
            <div
                ref={elemRef}
                className={`dropdown-menu dropdown-menu-lg dropdown-menu-right ${show ? 'show' : ''
                    }`}
            >
                <Link
                    to="/setup"
                    className="dropdown-item"
                >
                    Setting
                </Link>
                <button
                    onClick={async () => {

                        // await history.push("/");
                        openLoading("clearing route ... ");
                        await wait(250);
                        openLoading("logging out process ... ");
                        await wait(250);

                        let urlLogout = `${keycloakConfig.url}realms/${keycloak.realm}/protocol/openid-connect/logout?client_id=${keycloak.clientId}&refresh_token=${keycloak.refreshToken}`;

                        // try {

                        //     let form = new FormData()
                        //     form.append("client_id", keycloak.clientId)
                        //     form.append("refresh_token", keycloak.refreshToken)
                        //     let opts = {
                        //         method: "POST",
                        //         mode: 'no-cors',
                        //         body: new URLSearchParams({
                        //             client_id: keycloak.clientId,
                        //             refresh_token: keycloak.refreshToken,

                        //         }),
                        //         headers: {
                        //             "Content-Type": "application/x-www-form-urlencoded",

                        //             Authorization: `Bearer ${keycloak.idToken}`
                        //         },

                        //     }
                        //     let resp = await fetch(urlLogout, opts)
                        //     // resp = await resp
                        //     // window.location.href=urlLogout
                        //     console.log(resp)

                        // } catch (error) {
                        //     console.log(error.toString())
                        // }
                        closeLoading()
                        keycloak.logout({
                            redirectUri: window.location.origin
                        });
                    }}
                    className="dropdown-item"
                >
                    Logout
                </button>

            </div>
        </>
    );
});
