import React, { useState, useCallback, useEffect, memo, useRef } from 'react';
import clsx from 'clsx';
import { FormCheck, FormControl, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { TableGrid } from '../../../controls';
import ActionRiwayat from './action';
import { useHistory } from "react-router-dom";
import { baseUrl } from '../../../../app.config';
import { AppInterfaces, AppAction } from '../../../../app.data';

export default AppInterfaces.appLoad(
    memo(function ({ store, methods, dispatch }) {
    const appAction = useRef(null);
    const { data = [] } = store;
    const [paramFetch, setParamFetch] = useState({});
    const [userId, setUserId] = useState('teller-1');
    const [modal, setModal] = useState({
        confirm: false,
        success: false,
        error: false
    });
    const [message, setMessage] = useState({
        confirm: null,
        success: null,
        error: null
    });

    let history = useHistory();

    useEffect(
        function () {
            getData();
        },
        [paramFetch]
    );

    const getData = () => {
        appAction.current.doGet({
            url: '/teller/all',
            vari: 'data',
            param: {
                page: 0,
                limit: 15,
                ...paramFetch,
            },
        });
    }

    const postState = () => {
        const requestOptions = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify({
                    user_id: userId,
                    state: 'OPEN'
                }),
            };
            fetch(baseUrl + '/teller/state', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (data.message_id === '00') {
                        setModal({
                            confirm: false,
                            success: true,
                        });
                    } else {
                        setModal({
                            confirm: false,
                            error: true,
                        });
                        setMessage({
                            ...message,
                            error: data.status,
                        })
                    }
                });
    }

    return (
        <>
            <AppAction ref={appAction} />
            <React.Fragment>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Informasi Teller
                </Breadcrumb.Section>
            </Breadcrumb>
           
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Data Teller
                </div>
                <div className="tw-p-6">
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Pilih Kantor Cabang"
                                as="select"
                            >
                                <option value="">Pilih Kantor Cabang</option>
                            </FormControl>
                            <FormControl
                                className="mr-4"
                                size="sm"
                                placeholder="Cari NIP, nama ..."
                            />
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                            >
                                Reset
                            </Button>
                            <Button
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                            >
                                Cari
                            </Button>
                        </div>
                    </div>
                </div>
                <TableGrid
                    onRenderColumnField={(_value, field) => {
                        if (field === 'checked') return <FormCheck />;
                    }}
                    columns={[
                        {
                            name: 'Nama Teller',
                            field: 'name',
                        },
                        {
                            name: 'Kantor Cabang',
                            field: 'branch_id',
                        },
                        {
                            name: 'Saldo Kas Teller (Rp)',
                            field: 'balance',
                        },
                        {
                            name: 'Status',
                            field: 'state',
                        },
                        {
                            field: 'action'
                        }
                    ]}
                    data={data.db}
                    onRenderField={(value, field, { className, data }) => {
                        if (field === 'checked') return <FormCheck checked={value} />;

                        if (field === 'balance') {
                            return (value = new Intl.NumberFormat('id-ID').format(data.gl.balance));
                        }

                        if (field === 'value')
                            return <div className={clsx(className, 'tw-text-right')}>{value}</div>;

                        if (field === 'state') {
                            return (
                                <>
                                    <div>
                                        {value}
                                        {value === 'CLOSE' ? (
                                            <button
                                                onClick={() => setModal({ confirm: true })}
                                                className="btn btn-sm btn-success ml-2"
                                            >
                                                Open
                                            </button>
                                        ) : null}
                                    </div>
                                </>
                            );
                        }

                        if (field === 'action')
                        return (
                            <>
                                <Action
                                    onDetail={() => {
                                        history.push('/informasi-teller/informasi-kas-teller');
                                        // setMutationState(data);
                                    }}
                                    // key={`${value}-${field}`}
                                />
                            </>
                        );
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl className="tw-border-0" as="select" size="sm">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                    />
                    <div className="tw-text-12">Menampilkan 1-3 of 30 data</div>
                </div>
            </div>

            <Modal
                show={modal.confirm}
                onHide={() => setModal({ ...modal, confirm: false })}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Konfirmasi</Modal.Title>
                </Modal.Header>
                <Modal.Body>Lakukan proses Buka Kas?</Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setModal({ ...modal, confirm: false })}
                    >
                        Tidak
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            postState();
                        }}
                    >
                        Ya
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={modal.success}
                onHide={() => setModal({ ...modal, success: false })}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Selesai</Modal.Title>
                </Modal.Header>
                <Modal.Body>Proses Tutup Kas berhasil dilakukan</Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setModal({ ...modal, success: false });
                            getData();
                        }}
                    >
                        Selesai
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={modal.error}
                onHide={() => setModal({ ...modal, error: false })}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Opps.. Sedang Terjadi Kesalahan</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message.error}</Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setModal({ ...modal, error: false });
                        }}
                    >
                        Tutup
                    </button>
                </Modal.Footer>
            </Modal>
            
        </React.Fragment>
        </>
    );
    })
);


const Action = ({ onDetail }) => {
    const [show, setShow] = useState(false);

    const handleDetail = () => {
        if (onDetail) onDetail();

        setShow(false);
    };

    return (
        <div>
            <button 
                type="button" 
                class="btn btn-link"
                onClick={handleDetail}>
                    Lihat Info Kas Teller
            </button>
        </div>
    );
};