import React, { memo, useRef } from 'react';
import {BasePagePay} from '../../controls';
import { AppAction, AppInterfaces } from '../../../app.data';

const IconHeader = memo(function(){
    return (
        <>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.2493 13.1853C31.2402 13.0632 31.138 12.9688 31.0157 12.9688H26.7969V11.7969C26.7969 8.04898 23.7477 5 20 5C16.2524 5 13.2032 8.04898 13.2032 11.7969V12.9688H8.9844C8.86206 12.9688 8.75987 13.0632 8.75073 13.1853L7.11011 34.7478C7.10519 34.8127 7.12745 34.877 7.17198 34.9248C7.21628 34.9728 7.27862 35 7.34378 35H32.6563C32.7214 35 32.7838 34.9728 32.8281 34.925C32.8724 34.8772 32.8949 34.813 32.89 34.748L31.2493 13.1853ZM15.0782 11.7969C15.0782 9.08305 17.286 6.875 20 6.875C22.7141 6.875 24.9219 9.08305 24.9219 11.7969V12.9688H15.0782V11.7969ZM14.1407 19.5312C12.8481 19.5312 11.7969 18.4798 11.7969 17.1875C11.7969 16.2521 12.2657 15.4093 13.2032 15.0395V17.1875C13.2032 17.7045 13.6239 18.125 14.1407 18.125C14.6575 18.125 15.0782 17.7045 15.0782 17.1875V15.0409C16.0157 15.4107 16.4844 16.2598 16.4844 17.1875C16.4844 18.4798 15.4332 19.5312 14.1407 19.5312ZM25.8594 19.5312C24.5668 19.5312 23.5157 18.4798 23.5157 17.1875C23.5157 16.2521 23.9844 15.4093 24.9219 15.0395V17.1875C24.9219 17.7045 25.3426 18.125 25.8594 18.125C26.3762 18.125 26.7969 17.7045 26.7969 17.1875V15.0409C27.7344 15.4107 28.2032 16.2598 28.2032 17.1875C28.2032 18.4798 27.152 19.5312 25.8594 19.5312Z" fill="#FF6F00"/>
                <path d="M31.0156 12.9688C31.1379 12.9688 31.2401 13.0632 31.2492 13.1853L32.8899 34.748C32.8948 34.813 32.8723 34.8772 32.828 34.925C32.7837 34.9728 32.7214 35 32.6562 35H19.5833L25.8593 19.5312C27.1519 19.5312 28.2031 18.4798 28.2031 17.1875C28.2031 16.2598 27.7343 15.4107 26.7968 15.0409V12.9688H31.0156Z" fill="#FFA040"/>
            </svg>

        </>
    )
})

const nom = [
    {
        id:1,
        providerId:1,
        value:10000,
        price:12000
    },{
        id:2,
        providerId:2,
        value:10000,
        price:12500,
    },{
        id:3,
        providerId:1,
        value:50000,
        price:51000,
    },{
        id:4,
        providerId:2,
        value:50000,
        price:53000,
    },{

        id:5,
        providerId:1,
        value:100000,
        price:105000,
    },
]

const OnlineShopPage_base = memo(({store, ...props}) => {
    const appAction = useRef(null);
    
    return (
        <React.Fragment>
            <AppAction ref={appAction} />
            <BasePagePay
                options = {
                    [
       
                        {
                            display: "",
                            value: 2,
                            forms: [{
                                type:"select",
                                label:"Online Shop",
                                value:store.formData.providerId,
                                onChange:async (val)=> {
                                    const newNominal = await nom.filter(v => v.providerId === parseInt(val))
                                    appAction.current.setForm("providerId", val)
                                    appAction.current.disp({
                                        type:"setNominalList",
                                        nominalList:newNominal
                                    })
                                },
                                displayKey:"name",
                                valueKey:"id",
                                required:true,
                                readOnly:false,
                                options:[
                                    {
                                        name : "Tokopedia",
                                        id : 1,
                                    },
                                    {
                                
                                        name : "Buka Lapak",
                                        id : 2,
                                    },
                                    {
                                
                                        name : "Aku Laku",
                                        id : 3,
                                    }
                                ]
                            },{
                                type: "text",
                                name: "Online Shop",
                                label: "Nomor Virtual Account",
                                value:  store.formData.pelanggan,
                                required: true,
                                onChange: (val) => appAction.current.setForm("no_pembayaran", val),
                                readOnly: false,
                                onEnter:() => appAction.current.setOpenPembayaran(true)
                            }],
                            selectNominal: true,
                            onSelectNominal: (val)=>appAction.current.setNominal(val),
                            onPay: () => appAction.current.pay(),
                            // setNominal: setNominal,
                        }
                    ]
                }
                title ={<span className="px-0"><IconHeader /> Online Shop</span>}
                nominals={nom}
                paymentCardData={{
                    detilBody: [
                        {
                            key: "Jenis Layanan",
                            value: "Belanja Online - Tokopedia"
                        },{
                            key: "Nomor Virtual Account",
                            value: "211241242245566"
                        },
                    ],
                    detilFooter: [
                        {
                            key: "Harga",
                            value: 200000
                        },
                        {
                            key: "Admin",
                            value: 2200
                        }
                    ],
                    total: 202200
                }}
            />
        </React.Fragment>
    );
});

const OnlineShopPage = AppInterfaces.appLoad(OnlineShopPage_base)
export default OnlineShopPage;