import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { UserMenu } from '.';
import useKeycloak from '../../../hooks/keycloak.module';
import useFetch from '../../../hooks/useFetch';
import { useLocalStorage } from '../../../module';



const NavRight = memo(() => {
    return (
        <>
            <ul className="navbar-nav ml-auto">
                {/* <li className="nav-item dropdown">
                    <DropDownMenu
                        title={(
                                <>
                                    <i class="far fa-bell" style={{fontSize:"20pt"}}></i>
                                    <span class="badge badge-danger navbar-badge">3</span>
                                </>
                            )
                        }
                    >
                        <a href="#" className="dropdown-item">
                            <div className="media">
                                <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                                <div className="media-body">
                                    <h3 className="dropdown-item-title">
                                        Brad Diesel
                                    <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                                    </h3>
                                    <p className="text-sm">Call me whenever you can...</p>
                                    <p className="text-sm text-muted"><i class="far fa-clock mr-1"></i> 5 Hours Ago</p>
                                </div>
                            </div>
                        </a>
                    </DropDownMenu>
                </li> */}
                <li className="nav-item dropdown">
                    <UserMenu />
                </li>
            </ul>
        </>
    )
})


const DropDownMenu = memo(({
    title,
    children
}) => {
    const [show, setShow] = useState(false);
    return (
        <>
            <a class="nav-link" onClick={()=> setShow(prevState => !prevState)} data-toggle="dropdown" href="#">
               {title}
            </a>
            <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-right ${show ? "show" : "" }`}>
                {children}
            </div>
        </>
    )
})

export default memo(() => {
    const [podDate, setPodDate ] = useLocalStorage("POD_DATE")
    const { doGet, getStatusBranch } = useFetch();
    const {keycloak} = useKeycloak()
    async function getPOD(){
        let resp = await doGet({
            url:"/parameterglobal/pg-by-code",
            service:"trx",
            param:{
                kode_pg : "POD"
            }
        })
        // console.log(resp)
        if(resp.statusCode === 200) {
            if(resp.message_id === "00"){
                setPodDate(resp.data?.nilai_parameter_tanggal || "")
            }
        }
    }
    
    // const [status, setStatus] = useLocalStorage("STATUS_BDS", {})
    async function getdatastatus(withLoading = false){
        let data = await getStatusBranch(withLoading);
        keycloak.setStatusBranch(data)
    }

    useEffect(function(){
        getdatastatus()
        // console.log(keycloak.statusBranch)
    },[])
    useEffect(function(){
        getPOD();
        // getdatastatus()
    },[])
    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light elevation-1">
                <ul className="navbar-nav">
                    <li className="nav-item d-none d-sm-inline-block">
                        <span className="nav-link">
                            <strong>  
                                <h4>{
                                    moment(podDate).format("dddd, DD MMMM YYYY")    
                                }</h4>
                            </strong>
                        </span>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block" style={{
                        borderLeft:"1px solid #c9c9c9"
                    }}>
                        <span className="nav-link">
                            <strong>  
                                <h5>
                                    STATUS CABANG : {
                                        keycloak.statusBranch?.status_bds === "C" ? <span className='text-danger'>TUTUP</span> :
                                        keycloak.statusBranch?.status_bds === "O" ? <span className='text-info'>AKTIF</span> : ""
                                    }
                                    {/* <button onClick={()=> getdatastatus(true)} className='btn btn-sm btn-default ml-2'>Cek Status</button> */}
                                </h5> 
                            </strong>
                        </span>
                    </li>
                </ul>
                <NavRight />
            </nav>
        </>
    )
})
