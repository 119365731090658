import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import useDropdowns from "../../../../../hooks/useDropdowns";
import useFetch from "../../../../../hooks/useFetch";
// import { useKeycloak } from '@react-keycloak/web';
import moment from "moment";
import useAlamat from "../../../../../hooks/useAlamat";
import { useLocalStorage } from "../../../../../module";
import { ModalNomorCantik } from "../../../../controls";
import useKeycloak from "../../../../../hooks/keycloak.module";

const fdContext = createContext(null);

export const FdProvider = ({ children }) => {
   const { doGet, doGetMultiple, doPost } = useFetch();
   const [podDate, setPodDate] = useLocalStorage("POD_DATE");
   const paramUrl = useParams();
   const [step, setStep] = useState(0);
   const [formEditable, setFormEditable] = useState(true);
   const [dataAlert, setDataAlert] = useState({
      show: false,
      body: "",
      header: null,
      title: "",
      callBack: () => null,
   });
   const [dataConfirm, setDataConfirm] = useState({
      show: false,
      body: "",
      title: "",
      header: null,
      footerButton: [],
      closeButton: false,
      witTextArea: false,
   });
   const [locationState, setLocationState] = useState({});
   const history = useHistory();
   const location = useLocation();
   const [loading, setLoading] = useState({
      getData: false,
      loadingPost: { show: false, msg: "Please Wait ..." },
   });
   const [otorDesc, setOtorDesc] = useState("");
   const dataDropDown = useDropdowns();
   const { keycloak } = useKeycloak();
   const [formData, setFormData] = useState({
      encodedKey: "",
      state: "",
      accountHolderType: "",
      accountHolderKey: "",
      productTypeKey: "",
      productType: "DEPOSITO",
      nomorCIF: "",
      namaNasabah: "",
      namaRekening: "",
      nomorRekening: "",
      produkTabungan: "",
      keterangan: "",
      saldo: 0,
      valuta: "IDR",
      rejectionNote: "",
      code_instansi: "",
      _informasiLain: {
         nisbahCounter: "0", //nisbah spesial
         nisbahZakat: "0", //zakat bagi hasil
         nisbahPajak: "0", //pajak bagi hasil
         nisbahAkhir: "0", //nisbah produk
         qqRekening: "",
         qqName: "",
         biayaBiaya: "",
         sumberDana: "",
         tncVersion: "",
         tujuanPembukaanRekening: "",
         kelengkapanData: "",
         marketingReferensi: "",
         marketingPertama: "", //marketing inisiasi
         marketingSaatIni: "", //Marketing Maintenance
         rencanaAsuransiUtama: "",
         tanggalJatuhTempo: "",
         rencanaAsuransiTambahan: "",
         statusRestriksi: "",
         statusKelengkapan: "",
         // "is_kena_pajak": "T",
         tanggalBuka: podDate,
         income_smoothing: "F",
      },
      _alamatAlternatif: {
         kodePos: "",
         provinsi: "",
         kotaKabupaten: "",
         rt: "",
         rw: "",
         kecamatan: "",
         alamat: "",
         kodeTeleponRumah: "",
         telepon: "",
         kelurahan: "",
      },
      _dataNasabah: {
         homePhone: "",
         mobilePhone: "",
         nama_ibu_kandung: "",
         tanggalLahir: "",
         tempatLahir: "",
         alamat: "",
      },
      _rekeningDeposito: {
         nomor_bilyet: "",
         nominal_deposito: "",
         no_rekening_pencairan: "",
         nama_rekening_pencairan: "",
         instruksi_jatuh_tempo: "",
         instruksi_bagi_hasil: "",
         jangka_waktu: "",
         tanggal_jatuh_tempo: "",
         tanggal_buka: podDate,
      },
   });

   const [isPrioritas, setIsprioritas] = useState("F")

   //faktor resiko state
   //     identitasMasalah : 1,
   //     lokasiUsaha: 1,
   //     profileNasabah: 1,
   //     jumlahTrx:1,
   //     kegiatanUsaha:1,
   //     strukturKepemilikan:1,
   //     informasiLainnya:1
   // });

   // fungsiFaktorresiko
   // useEffect(function(){
   //     const keys = Object.keys(formResiko);
   //     let average = 1;
   //     let total = 0;
   //     keys.forEach( k => {
   //         total += parseInt(formResiko[k]);
   //     });
   //     let dataResiko = [
   //         null,
   //         "LOW",
   //         "MEDIUM",
   //         "HIGH"
   //     ];

   //     average = (total/keys.length).toFixed(0);
   //     // console.log(dataResiko[average])
   //     changeForm(dataResiko[average], "cddStatusResiko", "_cdd")
   // }, [formResiko])

   // alamat 1
   const [districts, setDistricts] = useState([]);
   const [villages, setvillages] = useState([]);
   const [cities, setCities] = useState([]);
   const [postalCodes, setPostalCodes] = useState([]);
   const [dataProduk, setDataProduk] = useState([]);
   const [dataMarketing, setDataMarketing] = useState([]);
   const { provinces, getCities, getVillages, getPostalsCode, getDistrics } =
      useAlamat();

   useEffect(
      function () {
         setLocationState(location.state);
      },
      [location.state]
   );

   //use event
   useEffect(
      function () {
         if (paramUrl.mode === "registration") resetForm();
      },
      [paramUrl.mode]
   );

   //reset form
   function resetForm() {
      setFormData({
         encodedKey: "",
         state: "",
         accountHolderType: "",
         accountHolderKey: "",
         productTypeKey: "",
         productType: "DEPOSITO",
         nomorCIF: "",
         namaNasabah: "",
         namaRekening: "",
         nomorRekening: "",
         produkTabungan: "",
         keterangan: "",
         saldo: 0,
         valuta: "IDR",
         rejectionNote: "",
         code_instansi: "",
         _informasiLain: {
            nisbahCounter: "0", //nisbah spesial
            nisbahZakat: "0", //zakat bagi hasil
            nisbahPajak: "0", //pajak bagi hasil
            nisbahAkhir: "0", //nisbah produk
            qqRekening: "",
            qqName: "",
            biayaBiaya: "",
            sumberDana: "",
            tncVersion: "",
            tujuanPembukaanRekening: "",
            kelengkapanData: "",
            marketingReferensi: "", //marketing inisiasi
            marketingPertama: "",
            marketingSaatIni: "", //Marketing Maintenance
            rencanaAsuransiUtama: "",
            tanggalJatuhTempo: "",
            rencanaAsuransiTambahan: "",
            statusRestriksi: "",
            statusKelengkapan: "",
            tanggalBuka: podDate,
            income_smoothing: "F",
         },
         _alamatAlternatif: {
            kodePos: "",
            provinsi: "",
            kotaKabupaten: "",
            rt: "",
            rw: "",
            kecamatan: "",
            alamat: "",
            kodeTeleponRumah: "",
            telepon: "",
            kelurahan: "",
         },
         _dataNasabah: {
            homePhone: "",
            mobilePhone: "",
            nama_ibu_kandung: "",
            tanggalLahir: "",
            tempatLahir: "",
            alamat: "",
         },
         _rekeningDeposito: {
            nomor_bilyet: "",
            nominal_deposito: "",
            no_rekening_pencairan: "",
            nama_rekening_pencairan: "",
            instruksi_jatuh_tempo: "",
            instruksi_bagi_hasil: "",
            jangka_waktu: "",
            tanggal_jatuh_tempo: "",
            tanggal_buka: podDate,
         },
      });
   }

   //data instansi
   const [ dataInstansi, setDataInstansi] = useState([])
   useEffect(function () {
       (async ()=>{

           try {
               const { data = [], statusCode, status, remark, statusText } = await doGet({
                   url: `/rekening/list-instansi`,
                   service: "acc"
               });
               if (statusCode === 200) {
                   if (status === "00") setDataInstansi(data)
                   else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
               } else setDataAlert({ show: true, body: remark, title: `${statusText}`, header: "Error", callBack: () => null });
           } catch (error) {
               setDataAlert({ show: true, body: "Terjadi Kesalahan", title: `Get data instansi`, header: "Error", callBack: () => null });
           }
   
       })()
   }, [])

   // function stepper
   function clickStep(id) {
      setStep(id);
   }
   function nextStep() {
      let dataRequired = [
         // "nasabahBadanUsaha", "nasabahAssetPerusahaan", "nasabahNomorNpwp", "groupName",
         // "nasabahOmsetPerusahaan", "nasabahBidangUsaha", "nasabahKodeLokasi",
         // "nasabahKeteranganLokasi", "nasabahNomorAktePendirian", "nasabahTanggalAktePendirian",
         // "nasabahSektorEkonomi", "nasabahAlamat", "nasabahRw", "nasabahRt", "nasabahProvinsi", "nasabahKotaKabupaten",
         // "nasabahKecamatan", "nasabahKelurahan"
      ];
      // if (step === 2) {
      //     dataRequired = [
      //         "cddPenghasilanKotorPerTahun", "cddGolonganNasabah", "cddSumberDana", "cddSumberPenghasilan",
      //         "cddTujuanPenggunaanDana", "cddStatusKenaPajak", "cddKeteranganBebasPajak", "cddNominalStorTunai",
      //         "cddNominalStorTunaiFre", "cddNominalStorNonTunai", "cddNominalStorNonTunaiFre", "cddNominalTarikTunai",
      //         "cddNominalTarikTunaiFre", "cddNominalTarikNonTunai", "cddNominalTarikNonTunaiFre", "cddStatusResiko"
      //     ]
      // }
      // if (paramUrl.mode === "registration") {
      //     if (validasi(dataRequired)) {
      //         return setDataAlert({ show: true, body: "Periksa kembali form pendaftaran, form belum lengkap", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
      //     }
      // }
      step !== 0 && setStep((prevStep) => prevStep + 1);
   }
   function prevState() {
      step > 0 && setStep((prevStep) => prevStep - 0);
   }

   // detect mode form
   useEffect(() => {
      // if (paramUrl.mode !== "registration") {
      if (
         paramUrl.mode === "otorisasi" ||
         paramUrl.mode === "edit" ||
         paramUrl.mode === "detil"
      ) {
         // console.log(location.state)
         if (location.state === null || location.state === undefined)
            return setDataAlert({
               show: true,
               body: "Tidak ada data nasabah terpilih",
               title: `Otorisasi Not Found`,
               header: "Error",
               callBack: () => history.push("/"),
            });

         getDataDetail(location.state?.dbId);
      }
      // }
      setFormEditable(
         paramUrl.mode === "registration" || paramUrl.mode === "edit"
      );
   }, [paramUrl.mode]);

   useEffect(() => {
      getDataProduct();
      getDataMarketing();
   }, []);
   // function getData Perorangan
   async function getDataDetail(dbId) {
      setLoading((prevState) => ({ ...prevState, loading: true }));
      let url = `/deposito/${dbId}`;
      if (paramUrl.mode === "otorisasi") url = `/deposito/otor-view/${dbId}`;
      const {
         statusCode,
         remark = "01",
         data = null,
         status = "",
         statusText = "",
      } = await doGet({ url, service: "acc" });
      if (statusCode === 200) {
         if (status === "00") {
            if (data !== null) {
               // console.log(data);
               const nasabahBadanUsaha = data;
               let stateBase = {
                  // "emailAddress": nasabahBadanUsaha.emailAddress,
                  // "notes": nasabahBadanUsaha.notes,
                  // "groupName": nasabahBadanUsaha.groupName,
                  // "assignedBranchKey": nasabahBadanUsaha.assignedBranchKey,
               };
               // const { _alamatAlternatif, _cdd, _beneficiaryOwner, _identitasNasabah, _pihakLawan } = formData;
               // let dataAlamat = {}
               // let dataCdd = {}
               // let dataBenfOwner = {}
               // let dataAlamat = {}
               // let dataAlamat = {}
               // const keys_alamatAlternatif = Object.keys(_alamatAlternatif);
               // const keys_cdd = Object.keys(_cdd);
               // const keys_benefOwner = Object.keys(_beneficiaryOwner);
               // const keys_identitas = Object.keys(_identitasNasabah);
               // const keys_pihakLawan = Object.keys(_pihakLawan);
               // keys_alamatAlternatif?.forEach( v => {
               //     dataAlamat = {...dataAlamat, [v] : nasabahBadanUsaha[v] }
               // });
               // const keys_formData = Object.keys(formData);
               if (paramUrl.mode === "otorisasi") {
                  // console.log(data);
                  setFormData((prevState) => ({
                     ...prevState,
                     nomor_akad: data.nomor_akad,
                     nomorCIF: data.nomorCIF,
                     namaRekening: data.namaRekening,
                     namaNasabah: data.namaNasabah,
                     produkTabungan: data.produkTabungan,
                     nomorRekening: data.nomorRekening,
                     keterangan: data.keterangan,
                     code_instansi: data.code_instansi,
                     _dataNasabah: {
                        ...prevState._dataNasabah,
                        ...stateBase._dataNasabah,
                        homePhone: data.homePhone,
                        mobilePhone: data.mobilePhone,
                        alamat: data.alamat,
                     },
                     _rekeningDeposito: {
                        ...prevState._rekeningDeposito,
                        ...stateBase._rekeningDeposito,
                        nomor_bilyet: data?.nomor_bilyet,
                        nominal_deposito: parseFloat(
                           data?.nominal_deposito || "0"
                        ).toFixed(2),
                        tanggal_buka: data.tanggal_buka,
                        tanggal_jatuh_tempo: data.tanggal_jatuh_tempo,
                        instruksi_bagi_hasil: data.instruksi_bagi_hasil,
                        instruksi_jatuh_tempo: data.instruksi_jatuh_tempo,
                        no_rekening_pencairan: data.no_rekening_pencairan,
                        nama_rekening_pencairan: data.nama_rekening_pencairan,
                     },
                     _informasiLain: {
                        ...prevState._informasiLain,
                        ...stateBase._informasiLain,
                        nisbahCounter: data.nisbahCounter,
                        nisbahAkhir: data.nisbahAkhir,
                        nisbahPajak: data.nisbahPajak,
                        nisbahZakat: data.nisbahZakat,
                        tujuanPembukaanRekening: data.tujuanPembukaanRekening,
                        sumberDana: data.sumberDana,
                        marketingReferensi: data.marketingReferensi,
                        marketingSaatIni: data.marketingSaatIni,
                        marketingPertama: data.marketingPertama,
                        qqRekening: data.qqRekening,
                        qqName: data.qqName,
                        income_smoothing: data.income_smoothing,
                     },
                     ...stateBase,
                  }));
                  // keys_formData?.forEach(kFormData => {
                  //     if ((formData[kFormData] instanceof Object) && !Array.isArray(formData[kFormData])) {
                  //         const tKey = Object.keys(formData[kFormData])
                  //         let dataChild = {}
                  //         tKey.forEach(k => {
                  //             dataChild = {
                  //                 ...dataChild,
                  //                 [k]: nasabahBadanUsaha[k]
                  //             }
                  //         })
                  //         stateBase = {
                  //             ...stateBase,
                  //             [kFormData]: dataChild
                  //         }
                  //     } else if (nasabahBadanUsaha[kFormData] !== undefined) {
                  //         let data = nasabahBadanUsaha[kFormData]
                  //         if (paramUrl.mode === "edit" || paramUrl.mode === "detail") {
                  //             if (
                  //                 (kFormData === "birthDate") ||
                  //                 (kFormData === "personalIdDateOfIssue") ||
                  //                 (kFormData === "personalIdExpireDate")
                  //             ) {
                  //                 data = moment(nasabahBadanUsaha[kFormData], "DD-MM-YYYY").format("YYYY-MM-DD");
                  //                 console.log(nasabahBadanUsaha[kFormData], data)
                  //             }
                  //         }
                  //         stateBase = { ...stateBase, [kFormData]: data }
                  //     }
                  // });
               } else {
                  // console.log(data)
                  setFormData((prevState) => ({
                     ...prevState,
                     nomor_akad: data.nomor_akad,
                     nomorCIF: data.nomor_nasabah,
                     namaRekening: data.nama_rekening,
                     namaNasabah: data.nama_lengkap,
                     produkTabungan: data.kode_produk,
                     nomorRekening: data.nomor_rekening,
                     keterangan: data.keterangan,
                     code_instansi: data.code_instansi,
                     _dataNasabah: {
                        ...prevState._dataNasabah,
                        ...stateBase._dataNasabah,
                        homePhone: data.telepon_rumah_nomor,
                        mobilePhone: data.telepon_hp_nomor,
                        alamat:
                           (data.alamat || "") +
                           " " +
                           (data.alamat_rumah_provinsi || "") +
                           " " +
                           (data.alamat_rumah_kota_kabupaten || " ") +
                           " " +
                           (data.alamat_rumah_kecamatan || "") +
                           " " +
                           (data.alamat_rumah_kelurahan || "") +
                           " " +
                           "RT/RW:" +
                           (data.alamat_rumah_rt || "") +
                           " " +
                           (data.alamat_rumah_rw || "") +
                           "/" +
                           (data.alamat_rumah_kode_pos || ""),
                     },
                     _rekeningDeposito: {
                        ...prevState._rekeningDeposito,
                        ...stateBase._rekeningDeposito,
                        nomor_bilyet: data?.nomor_bilyet,
                        nominal_deposito: parseFloat(
                           data?.saldo || "0"
                        ).toFixed(2),
                        instruksi_jatuh_tempo: data.disposisi_nominal,
                        no_rekening_pencairan: data.rekening_disposisi,
                        nama_rekening_pencairan:
                           data.penerima_transfer_disposisi,
                        instruksi_bagi_hasil: data.disposisi_bagi_hasil,
                        tanggal_buka: moment(
                           data.tanggal_buka,
                           "YYYY/MM/DD"
                        ).format("YYYY-MM-DD"),
                        tanggal_jatuh_tempo: moment(
                           data.tanggal_jatuh_tempo_berikutnya,
                           "YYYY/MM/DD"
                        ).format("YYYY-MM-DD"),
                     },
                     _informasiLain: {
                        ...prevState._informasiLain,
                        ...stateBase._informasiLain,
                        nisbahCounter: data.nisbah_spesial,
                        nisbahZakat: data.persentase_zakat_bagi_hasil,
                        nisbahPajak: data.tarif_pajak,
                        nisbahAkhir: data.nisbah_dasar,
                        tujuanPembukaanRekening: data.kode_tujuan_rekening,
                        sumberDana: data.kode_sumber_dana,
                        marketingReferensi: data.marketingReferensi,
                        marketingPertama: data.kode_marketing_pertama,
                        marketingSaatIni: data.kode_marketing_current,
                        qqRekening: data.nomor_rekening_bagihasil,
                        qqName: data.nama_rekening_bagihasil,
                        income_smoothing: data.income_smoothing,
                     },
                     ...stateBase,
                  }));
                  // const province_code = await getProvinceCode(nasabahBadanUsaha.nasabahProvinsi)
               }

               // await Promise.all([
               //     getingCities(province_code),
               //     getingDistrict(nasabahBadanUsaha.nasabahKotaKabupaten),
               //     getingVillages(nasabahBadanUsaha.nasabahKotaKabupaten, nasabahBadanUsaha.nasabahKecamatan),
               //     getingPostalCodes(nasabahBadanUsaha.nasabahKotaKabupaten, nasabahBadanUsaha.nasabahKecamatan, nasabahBadanUsaha.nasabahKotaKabupaten),
               // ])
            }
         } else
            setDataAlert({
               show: true,
               body: remark,
               titile: `Status fetch data is ${statusCode}`,
            });
      } else if (statusCode !== 401)
         setDataAlert({
            show: true,
            body: remark,
            title: `${JSON.stringify(statusText)}`,
            header: "Error",
            callBack: () => null,
         });
      setLoading((prevState) => ({ ...prevState, loading: false }));
      // console.log(data)
   }

   // useEffect(function(){
   //     console.log(formData)
   // },[formData])

   // close Alert
   function closeAlert() {
      setDataAlert({
         show: false,
         body: "",
         header: null,
         title: "",
         callBack: () => null,
      });
   }

   // closeConfirm
   function closeConfirm() {
      setDataConfirm({
         show: false,
         body: "",
         header: null,
         title: "",
         footerButton: [],
         closeButton: true,
         witTextArea: false,
      });
   }

   // response otorisasi
   function responseOtor(status) {
      let body = `Apakah anda yakin akan ${status} nasabah ini ?`;
      setDataConfirm({
         show: true,
         body,
         witTextArea: status === "REJECTED",
         header: "Confirmation",
         title: "",
         closeButton: false,
         footerButton: [
            {
               name: "Ya",
               onClick: () => fetchOtorisasi(status),
               className: "btn btn-sm btn-success",
            },
            {
               name: "Tidak",
               onClick: closeConfirm,
               className: "btn btn-sm btn-secondary",
            },
         ],
      });
   }

   // fetch API response otorisasi
   async function fetchOtorisasi(statusApprove) {
      setLoading((prevState) => ({
         ...prevState,
         loadingPost: { show: true, msg: "Please Wait ..." },
      }));
      closeConfirm();
      let keterangan = otorDesc;
      if (keterangan === "" || keterangan === null) {
         if (statusApprove === "APPROVED") keterangan = "Approve";
         else keterangan = "Rejected";
      }
      if (locationState !== null && locationState !== undefined) {
         try {
            const {
               statusCode,
               remark = "",
               data = null,
               status = "01",
               statusText = "",
            } = await doPost({
               url: `/otorisasi/${locationState.kode_entry}/${locationState.dbId}/${statusApprove}?keterangan=${keterangan}`,
               service: "acc",
               method: "PUT",
               data: { keterangan: otorDesc },
               headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
               },
            });
            if (statusCode === 200) {
               let ketSuccess = data?.nomor_rekening || formData.nomorRekening;
               if (status === "00")
                  setDataAlert({
                     show: true,
                     body: `Proses Otorisasi Berhasil, nomor rekening: ${ketSuccess}`,
                     title: ``,
                     header: "Success",
                     callBack: () => history.goBack(),
                  });
               else
                  setDataAlert({
                     show: true,
                     body: remark,
                     title: `${statusText}`,
                     header: "Info",
                     callBack: () => null,
                  });
            } else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: `${statusText}`,
                  header: "Error",
                  callBack: () => null,
               });
         } catch (error) {
            setDataAlert({
               show: true,
               body: error.toString(),
               title: ``,
               header: "Error",
               callBack: () => null,
            });
         }
      } else
         setDataAlert({
            show: true,
            body: "Tidak ada data otorisasi terpilih",
            title: `Otorisasi Not Found`,
            header: "Error",
            callBack: () => null,
         });

      setLoading((prevState) => ({
         ...prevState,
         loadingPost: { show: false, msg: "Please Wait ..." },
      }));
   }

   // fungsi setDataForm
   function changeForm(value, key, subkey = null) {
      if (key !== "nisbahCounter") {
         value = value.toUpperCase();
      }

      if (subkey !== null)
         setFormData((prevState) => ({
            ...prevState,
            [subkey]: { ...prevState[subkey], [key]: value },
         }));
      else setFormData((prevState) => ({ ...prevState, [key]: value }));
   }

   // get province_code
   async function getProvinceCode(province_name) {
      if (provinces !== null && provinces !== undefined) {
         const data = provinces?.find((v) => {
            return v.province_name === province_name;
         });
         return data?.province_code || "";
      }
   }
   // fungsi cek nasabah
   async function cekNasabah(nomor_rekening) {
      setLoading((prevState) => ({
         msg: "Checking Account No ...",
         show: true,
      }));
      try {
         const resp = await doGet({
            // data: bodyData,
            url: `/rekening/rek-for-trx/${nomor_rekening}`,
            service: "acc",
         });
         const { statusCode, status, statusText, data, remark } = resp;

         if (statusCode === 200) {
            if (status === "00")
               changeForm(
                  data.nama_rekening,
                  "nama_rekening_pencairan",
                  "_rekeningDeposito"
               );
            else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: `${statusText}`,
                  header: "Error",
                  callBack: () =>
                     changeForm(
                        "",
                        "no_rekening_pencairan",
                        "_rekeningDeposito"
                     ),
               });
         } else
            setDataAlert({
               show: true,
               body: remark,
               title: `${statusText}`,
               header: "Error",
               callBack: () =>
                  changeForm("", "no_rekening_pencairan", "_rekeningDeposito"),
            });
      } catch (error) {
         setDataAlert({
            show: true,
            body: "Terjadi Kesalahan",
            title: ``,
            header: "Error",
            callBack: () => null,
         });
      }
      closeLoading();
   }
   // fungsi cek nasabah
   async function cekNasabahPindah(nomor_rekening) {
      setLoading((prevState) => ({
         msg: "Checking Account No ...",
         show: true,
      }));
      try {
         const resp = await doGet({
            // data: bodyData,
            url: `/rekening/rek-for-trx/${nomor_rekening}`,
            service: "acc",
         });
         const { statusCode, status, statusText, data, remark } = resp;

         if (statusCode === 200) {
            if (status === "00")
               changeForm(data.nama_rekening, "qqName", "_informasiLain");
            else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: `${statusText}`,
                  header: "Error",
                  callBack: () =>
                     changeForm("", "qqRekening", "_informasiLain"),
               });
         } else
            setDataAlert({
               show: true,
               body: remark,
               title: `${statusText}`,
               header: "Error",
               callBack: () => changeForm("", "qqRekening", "_informasiLain"),
            });
      } catch (error) {
         setDataAlert({
            show: true,
            body: "Terjadi Kesalahan",
            title: ``,
            header: "Error",
            callBack: () => null,
         });
      }
      closeLoading();
   }
   //  fungsi validasi
   function validasi(fieldRequired = null) {
      //  field required lists
      if (fieldRequired === null) {
         fieldRequired = [
            "namaNasabah",
            "nomorCIF",
            "nomor_bilyet",
            "nominal_deposito",
            "no_rekening_pencairan",
            "tujuanPembukaanRekening",
            "sumberDana",
            "nama_rekening_pencairan",
            "instruksi_bagi_hasil",
            "instruksi_jatuh_tempo",
            "marketingPertama",
            "marketingSaatIni",
            "qqRekening",
            "qqName",
            "no_rekening_pencairan",
            "nama_rekening_pencairan",
         ];
      }
      const keys = Object.keys(formData);
      var errS = false;
      keys.forEach(function (key) {
         if (formData[key] instanceof Object) {
            const tKey = Object.keys(formData[key]);
            tKey.forEach((element) => {
               if (fieldRequired.some((v) => element === v)) {
                  if (
                     formData[key][element] === "" ||
                     formData[key][element] === undefined ||
                     formData[key][element] === null
                  ) {
                     errS = true;
                  }
               }
            });
         } else {
            if (fieldRequired.some((v) => v === key)) {
               if (
                  formData[key] === "" ||
                  formData[key] === undefined ||
                  formData[key] === null
               ) {
                  errS = true;
               }
            }
         }
      });

      return errS;
   }

   // get province_code
   async function getProvinceCode(province_name) {
      if (provinces !== null && provinces !== undefined) {
         const data = provinces?.find((v) => {
            return v.province_name === province_name;
         });
         return data?.province_code || "";
      }
   }

   // close Loading
   function closeLoading() {
      setLoading((prevState) => ({ msg: "Please Wait ...", loading: false }));
   }

   // confirm Post Nasabah
   function confirmPostNasabah() {
      let formType =
         paramUrl.mode === "edit" ? "ubah data" : "Registrasi Deposito";
      let body = `Lakukan Proses ${formType}?`;
      setDataConfirm({
         show: true,
         body,
         witTextArea: false,
         header: "Konfirmasi",
         title: "",
         closeButton: false,
         footerButton: [
            {
               name: "Ya",
               onClick: () =>
                  paramUrl.mode === "edit" ? updateNasabah() : postNasabah(),
               className: "btn btn-sm btn-success",
            },
            {
               name: "Tidak",
               onClick: closeConfirm,
               className: "btn btn-sm btn-secondary",
            },
         ],
      });
   }

   // fungsi post nasabah
   async function postNasabah() {
      const userInfo = await keycloak.loadUserInfo();
      const err = validasi();
      if (err) {
         return setDataAlert({
            show: true,
            body: "Sebelum melanjutkan proses registrasi, pastikan data yang anda masukan sudah benar.",
            title: `Form Belum Lengkap`,
            header: "Warning",
            callBack: () => null,
         });
      }
      setLoading((prevState) => ({ ...prevState, loading: true }));
      try {
         const resp = await doPost({
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               "x-user-id": userInfo.preferred_username,
            },
            // data: formData,
            data: {
               ...formData,
               uid: keycloak.uid,
            },
            url: "/deposito",
            service: "acc",
         });
         // console.log(formData);
         const {
            statusCode,
            remark = "",
            data = null,
            status = "01",
            statusText = "",
         } = resp;
         if (statusCode === 200) {
            if (status === "00")
               setDataAlert({
                  show: true,
                  body: `Proses Registrasi Deposito Berhasil, Perlu Otorisasi Untuk Melanjutkan`,
                  title: ``,
                  header: "Success",
                  callBack: () => history.push("/deposito/data"),
               });
            else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: `${statusText}`,
                  header: "Error",
                  callBack: () => null,
               });
         } else
            setDataAlert({
               show: true,
               body: remark,
               title: `${statusText}`,
               header: "Error",
               callBack: () => null,
            });
      } catch (error) {
         setDataAlert({
            show: true,
            body: "Terjadi Kesalahan",
            title: ``,
            header: "Error",
            callBack: () => null,
         });
      }
   }

   // update nasabah
   async function updateNasabah() {
      // const userInfo = await keycloak.loadUserInfo();
      const err = validasi();
      if (err) {
         return setDataAlert({
            show: true,
            body: "Periksa kembali form ubah data, form belum lengkap",
            title: `Form Belum Lengkap`,
            header: "Warning",
            callBack: () => null,
         });
      }
      // if (formData?.namaNasabah === '' || formData?.namaNasabah === undefined || formData?.namaNasabah === null) {
      //     setDataAlert({ show: true, body: "Nama nasabah belum di isi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
      // }else if (formData?.nomorCIF === '' || formData?.nomorCIF === undefined || formData?.nomorCIF === null) {
      //     setDataAlert({ show: true, body: "Nomor CIF belum diisi", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
      // }else if (formData?._informasiLain?.sumberDana === '' || formData?._informasiLain?.sumberDana === undefined || formData?._informasiLain?.sumberDana === null) {
      //     setDataAlert({ show: true, body: "Sumber Dana belum dipilih", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
      // }else if (formData?._informasiLain?.marketingPertama === '' || formData?._informasiLain?.marketingPertama === undefined || formData?._informasiLain?.marketingPertama === null) {
      //     setDataAlert({ show: true, body: "Marketing Pertama belum dipilih", title: `Form Belum Lengkap`, header: "Warning", callBack: () => null });
      // }
      setLoading((prevState) => ({ ...prevState, loading: true }));
      try {
         const userInfo = await keycloak.loadUserInfo();
         const resp = await doPost({
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               "x-user-id": userInfo.preferred_username,
            },
            method: "PUT",
            data: formData,
            url: `/deposito?db_id=${locationState.dbId}&cif_no=${formData.nomorCIF}`,
            service: "acc",
         });
         const {
            statusCode,
            remark = "",
            data = null,
            status = "01",
            statusText = "",
         } = resp;
         if (statusCode === 200) {
            if (status === "00")
               setDataAlert({
                  show: true,
                  // body: `Ubah Nasabah Berhasil, Perlu Otorisasi Untuk Melanjutkan`,
                  body: remark,
                  title: ``,
                  header: "Success",
                  callBack: () => history.goBack(),
               });
            else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: `${statusText}`,
                  header: "Error",
                  callBack: () => null,
               });
         } else
            setDataAlert({
               show: true,
               body: remark,
               title: `${statusText}`,
               header: "Error",
               callBack: () => null,
            });
      } catch (error) {
         setDataAlert({
            show: true,
            body: error.toString(),
            title: ``,
            header: "Error",
            callBack: () => null,
         });
      }
   }

   //  get data produk
   async function getDataProduct() {
      let kat = paramUrl.type;

      try {
         const { data, statusCode, status, remark, statusText } = await doGet({
            url: `/produk-deposit/deposito`,
            service: "cif",
         });
         if (statusCode === 200) {
            if (status === "00") setDataProduk(data?.list);
            else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: `${statusText}`,
                  header: "Error",
                  callBack: () => null,
               });
         } else
            setDataAlert({
               show: true,
               body: remark,
               title: `${statusText}`,
               header: "Error",
               callBack: () => null,
            });
      } catch (error) {
         setDataAlert({
            show: true,
            body: "Terjadi Kesalahan",
            title: `Get data produk`,
            header: "Error",
            callBack: () => null,
         });
      }
   }
   //  get data Mekerting
   async function getDataMarketing() {
      // let kat = paramUrl.type;
      // if (kat === "giro-escrow") kat = "escrow";

      try {
         const { data, statusCode, status, remark, statusText } = await doGet({
            // url: `/produk-deposit/${kat}`,
            url: `/account-officer`,
            service: "cif",
         });
         if (statusCode === 200) {
            if (status === "00") setDataMarketing(data?.list);
            else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: `${statusText}`,
                  header: "Error",
                  callBack: () => null,
               });
         } else
            setDataAlert({
               show: true,
               body: remark,
               title: `${statusText}`,
               header: "Error",
               callBack: () => null,
            });
      } catch (error) {
         setDataAlert({
            show: true,
            body: "Terjadi Kesalahan",
            title: `Get data produk`,
            header: "Error",
            callBack: () => null,
         });
      }
   }

   // get cities 1
   async function getingCities(province_code) {
      const data = await getCities(province_code);
      const newData = data.map((v) => ({ key: v, display: v }));
      setCities(newData);
   }

   // get districts 1
   async function getingDistrict(city) {
      const data = await getDistrics(city);
      const newData = data.map((v) => ({ key: v, display: v }));
      setDistricts(newData);
   }
   // get village 1
   async function getingVillages(city, subdistrict) {
      const data = await getVillages(city, subdistrict);
      const newData = data.map((v) => ({ key: v, display: v }));
      setvillages(newData);
   }
   // get postalcode 1
   async function getingPostalCodes(city, subdistrict, urban) {
      const data = await getPostalsCode(city, subdistrict, urban);
      const newData = data.map((v) => ({ key: v, display: v }));
      setPostalCodes(newData);
   }
   // pilih nasabah
   async function pilihNasabah(dataNasabah, tipe) {
      setLoading((prevState) => ({ ...prevState, loading: true }));
      let url = `/nasabah/${dataNasabah.nomor_cif}`;
      if (parseInt(tipe) === 1)
         url = `/nasabah-badan-usaha/${dataNasabah.nomor_cif}`;

      try {
         const resp = await doGet({
            url,
            service: "cif",
         });
         const { status, statusText, data, statusCode, remark } = resp;
         if (statusCode === 200) {
            // console.log(data)
            if (status === "00") {
               const { nasabahBadanUsaha } = data;
               let homePhone = nasabahBadanUsaha?.homePhone;
               let mobilePhone = nasabahBadanUsaha?.mobilePhone;
               let alamat = dataNasabah.alamat.toUpperCase();
               setIsprioritas(nasabahBadanUsaha.nasabah_prioritas)
               if (tipe === 1) {
                  homePhone =
                     nasabahBadanUsaha?.nasabahTeleponKantorKodeArea1 +
                     nasabahBadanUsaha?.nasabahTeleponKantorNomor1;
                  mobilePhone = "";
               }
               setFormData((prevState) => ({
                  ...prevState,
                  namaNasabah: dataNasabah.nama.toUpperCase(),
                  namaRekening: dataNasabah.nama.toUpperCase(),
                  nomorCIF: dataNasabah.nomor_cif,
                  _dataNasabah: {
                     ...prevState._dataNasabah,
                     homePhone,
                     mobilePhone,
                     alamat,
                  },
               }));
               return true;
            } else {
               setDataAlert({
                  show: true,
                  body: remark,
                  titile: `Status fetch data is ${statusCode}`,
                  callBack: () => null,
               });
               setLoading((prevState) => ({ ...prevState, loading: false }));
               return false;
            }
         } else {
            setDataAlert({
               show: true,
               body: remark,
               title: `${JSON.stringify(statusText)}`,
               header: "Error",
               callBack: () => null,
            });
            setLoading((prevState) => ({ ...prevState, loading: false }));
            return false;
         }
      } catch (error) {
         setDataAlert({
            show: true,
            body: error.toString(),
            title: "Terjadi Kesalahan",
            header: "Error",
            callBack: () => null,
         });
         setLoading((prevState) => ({ ...prevState, loading: false }));
         return false;
      }
   }

   function changeDate({
      from = null,
      masa_perjanjian = null,
      key = null,
      subkey = null,
      toKey = null,
      toSubkey = null,
   }) {
      if (key !== null) changeForm(from, key, subkey);
      if (from === null)
         from = moment(formData?._rekeningDeposito?.tanggal_buka || Date.now());
      else from = moment(from);
      if (masa_perjanjian === null) {
         const productDetil = dataProduk.find(
            (v) => v.kode_produk === formData.produkTabungan || ""
         );
         masa_perjanjian = productDetil?.masa_perjanjian || 0;
      }
      const to = from.add("month", masa_perjanjian).format("YYYY-MM-DD");
      if (toKey !== null) changeForm(to, toKey, toSubkey);
      return to;
   }

   function changeDays({
      from = null,
      masa_perjanjian = null,
      key = null,
      subkey = null,
      toKey = null,
      toSubkey = null,
   }) {
      if (key !== null) changeForm(from, key, subkey);
      if (from === null)
         from = moment(formData?._rekeningDeposito?.tanggal_buka || Date.now());
      else from = moment(from);
      if (masa_perjanjian === null) {
         const productDetil = dataProduk.find(
            (v) => v.kode_produk === formData.produkTabungan || ""
         );
         masa_perjanjian = productDetil?.masa_perjanjian || 0;
      }
      const to = from.add("day", masa_perjanjian).format("YYYY-MM-DD");
      if (toKey !== null) changeForm(to, toKey, toSubkey);
      return to;
   }

   //change product
   async function changeProduct(value) {
      // changeForm(value, "produkTabungan")
      const productDetil = dataProduk.find((v) => v.kode_produk === value);

      // if(value !== "" && value !==null)
      // console.log(productDetil);
      if (productDetil.periode_perjanjian === "B") {
         const { masa_perjanjian } = productDetil;
         const dateNow = moment(Date.now());
         const tanggal_jatuh_tempo = changeDate({ masa_perjanjian });
         // await cekTanggalJatuhTempo(tanggal_jatuh_tempo)
         // fdState.formData?._informasiLain?.tanggalJatuhTempo
         setFormData((prevState) => ({
            ...prevState,
            produkTabungan: value,
            valuta: productDetil?.kode_valuta || "",
            // _rekeningTabungan: {
            //     ...prevState._rekeningTabungan,
            //     dapatBonusBagiHasil: productDetil?.is_dapat_bagi_hasil === "T" || false
            // },
            _rekeningDeposito: {
               ...prevState._rekeningDeposito,
               tanggal_jatuh_tempo,
            },
            _informasiLain: {
               ...prevState._informasiLain,
               nisbahZakat: productDetil?.presentase_zakat_bagi_hasil || 0,
               nisbahPajak: productDetil?.tarif_pajak || 0,
               nisbahAkhir: productDetil?.nisbah_bonus_dasar || 0,
               nisbahInfo: productDetil?.nisbah_spesial_max || 0,
            },
         }));
      } else if (productDetil.periode_perjanjian === "H") {
         const { masa_perjanjian } = productDetil;
         const dateNow = moment(Date.now());
         const tanggal_jatuh_tempo = changeDays({ masa_perjanjian });
         // await cekTanggalJatuhTempo(tanggal_jatuh_tempo)
         // fdState.formData?._informasiLain?.tanggalJatuhTempo
         setFormData((prevState) => ({
            ...prevState,
            produkTabungan: value,
            valuta: productDetil?.kode_valuta || "",
            // _rekeningTabungan: {
            //     ...prevState._rekeningTabungan,
            //     dapatBonusBagiHasil: productDetil?.is_dapat_bagi_hasil === "T" || false
            // },
            _rekeningDeposito: {
               ...prevState._rekeningDeposito,
               tanggal_jatuh_tempo,
            },
            _informasiLain: {
               ...prevState._informasiLain,
               nisbahZakat: productDetil?.presentase_zakat_bagi_hasil || 0,
               nisbahPajak: productDetil?.tarif_pajak || 0,
               nisbahAkhir: productDetil?.nisbah_bonus_dasar || 0,
               nisbahInfo: productDetil?.nisbah_spesial_max || 0,
            },
         }));
      }
   }
   async function cekNisbah() {
      setLoading((prevState) => ({
         msg: "Checking Nisbah Spesial ...",
         show: true,
      }));
      const nisbahMsg = formData?._informasiLain?.nisbahInfo;
      setDataAlert({
         show: true,
         body: "Maaf nilai nisbah melebihi dari nilai yang di tentukan...",
         title: `Nisbah spesial tidak valid max ${nisbahMsg}`,
         header: "Info",
         callBack: () => changeForm("0", "nisbahCounter", "_informasiLain"),
      });
      closeLoading();
   }

   async function alertNisbah(param) {
      setLoading((prevState) => ({
         msg: "Checking Nisbah Spesial ...",
         show: true,
      }));
      const min = -formData?._informasiLain.nisbahAkhir;
      const max = 100 - formData?._informasiLain.nisbahAkhir;

      if (param === "min") {
         setDataAlert({
            show: true,
            body: "Maaf nilai nisbah kurang dari nilai yang di tentukan...",
            title: `Nisbah spesial tidak valid min ${min}`,
            header: "Info",
            callBack: () => changeForm("0", "nisbahCounter", "_informasiLain"),
         });
      } else {
         setDataAlert({
            show: true,
            body: "Maaf nilai nisbah melebihi dari nilai yang di tentukan...",
            title: `Nisbah spesial tidak valid max ${max}`,
            header: "Info",
            callBack: () => changeForm("0", "nisbahCounter", "_informasiLain"),
         });
      }
      closeLoading();
   }
   async function cekNomorBilyet(nomor_bilyet) {
      try {
         const resp = await doGet({
            // data: bodyData,
            url: `/deposito/validasi-deposito-account?nomor_bilyet=${nomor_bilyet}`,
            service: "acc",
         });
         const { statusCode, status, statusText, data, remark } = resp;
         if (statusCode === 200) {
            if (status === "00") {
               setDataAlert({
                  show: true,
                  body: remark,
                  title: "",
                  header: "Success",
                  callBack: () => null,
               });
            } else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: ``,
                  header: "Info",
                  callBack: () =>
                     changeForm("", "nomor_bilyet", "_rekeningDeposito"),
               });
         } else
            setDataAlert({
               show: true,
               body: remark,
               title: "",
               header: "Info",
               callBack: () =>
                  changeForm("", "nomor_bilyet", "_rekeningDeposito"),
            });
      } catch (error) {
         setDataAlert({
            show: true,
            body: "Terjadi Kesalahan",
            title: ``,
            header: "Error",
            callBack: () => null,
         });
      }
      closeLoading();
   }
   // fungsi Hit API untuk get / validasi tanggal jatuh tempo
   async function cekTanggalJatuhTempo(tgl_jt) {
      setLoading((prevState) => ({
         msg: "Checking date ...",
         show: true,
      }));
      const url = `/deposito/get-maturity-non-holiday`;
      try {
         const resp = await doGet({
            // data: bodyData,
            param:{
               tgl_jt,
            },
            url,
            service: "acc",

         });
         const { statusCode, status, statusText, data, remark } = resp;
         if (statusCode === 200) {
            if (status === "00") {
               let tanggal_jatuh_tempo = data?.next_maturity_date || "";
               setFormData((prevState) => ({
                  ...prevState,
                  _rekeningDeposito: {
                     ...prevState._rekeningDeposito,
                     tanggal_jatuh_tempo,
                  },
               }));
            } else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: ``,
                  header: "Info",
                  callBack: () => null,
               });
         } else
            setDataAlert({
               show: true,
               body: remark,
               title: "",
               header: "Info",
               callBack: () => null,
            });
      } catch (error) {
         setDataAlert({
            show: true,
            body: error.toString(),
            title: ``,
            header: "Error",
            callBack: () => null,
         });
      }
      closeLoading();
   }

   //Post Auditrail
   async function auditTrail() {
      const userInfo = await keycloak.loadUserInfo();
      // console.log(userInfo)
      var url = `/useraudit/user-audit-trail`;
      try {
         const {
            statusCode,
            status,
            remark,
            statusText,
            data = {},
         } = await doPost({
            url: url,
            service: "acc",
            data: {
               user_id: userInfo.preferred_username,
               app_id: "BDS",
               terminal_ip: window.location.hostname,
               operation_code: "Registrasi",
               event_description: "Desposito/Registrasi Deposito",
               info1: "-",
               info2: "-",
            },
            headers: {
               "x-user-id": userInfo.preferred_username,
               method: "POST",
            },
         });

         if (statusCode === 200) {
            if (status === "00")
               setDataAlert({
                  show: false,
                  body: `Proses Audit Berhasil`,
                  title: ``,
                  header: "Success",
                  callBack: () => null,
               });
            else
               setDataAlert({
                  show: true,
                  body: remark,
                  title: `Status fetch data is ${statusCode}`,
                  callBack: () => null,
               });
         } else if (statusCode !== 401)
            setDataAlert({
               show: false,
               body: remark,
               title: `${JSON.stringify(statusText)}`,
               header: "Error",
               callBack: () => null,
            });
      } catch (error) {
         setDataAlert({
            show: false,
            body: error.toString(),
            title: "Terjadi Kesalahan",
            header: "Error",
            callBack: () => null,
         });
      }
   }
   const [showModalNC, setShowModalNC] = useState(false);
   function openModalNC() {
      setShowModalNC(true);
   }
   function closeModalNC() {
      setShowModalNC(false);
   }
   function pilihNomorCantik(item) {
      changeProduct(item.kode_produk);
      setFormData((v) => ({
         ...v,
         nomorRekening: item.nomor,
      }));
      closeModalNC();
   }

   function resetNomorCantik() {
      changeProduct("");
      setFormData((v) => ({
         ...v,
         nomorRekening: "",
      }));
   }

   return (
      <fdContext.Provider
         value={{
            fdState: {
               paramUrl,
               step,
               formEditable,
               loading,
               dataAlert,
               formData,
               locationState,
               dataConfirm,
               otorDesc,
               dataDropDown,
               provinces,
               districts,
               villages,
               cities,
               postalCodes,
               // formResiko,
               dataProduk,
               dataMarketing,
               isPrioritas,
               dataInstansi
            },
            fdAction: {
               clickStep,
               nextStep,
               prevState,
               closeAlert,
               closeConfirm,
               responseOtor,
               setOtorDesc,
               changeForm,
               cekNasabah,
               cekNasabahPindah,
               cekNisbah,
               validasi,
               confirmPostNasabah,
               getVillages,
               getCities,
               getDistrics,
               getPostalsCode,
               getProvinceCode,
               getingCities,
               getingDistrict,
               getingVillages,
               getingPostalCodes,
               // setFormResiko,
               setDataAlert,
               setLoading,
               setDataConfirm,
               pilihNasabah,
               changeProduct,
               changeDate,
               changeDays,
               resetForm,
               cekNomorBilyet,
               auditTrail,
               openModalNC,
               resetNomorCantik,
               alertNisbah,
            },
         }}
      >
         {children}
         <ModalNomorCantik
            show={showModalNC}
            onChoice={pilihNomorCantik}
            onHide={closeModalNC}
            products={dataProduk}
         />
      </fdContext.Provider>
   );
};

export default function useFormDeposito() {
   return useContext(fdContext);
}
