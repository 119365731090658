import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { LoadingIcon } from "../../../../../controls";
import loadingIcon from "../../../../../controls/loadingIcon";
import useLaporan from "../useLaporan";
import { DateInput } from "../../../../../controls/index";

const CetakLaporan = ({ show, setClose, kodeCetak }) => {
  const { lapState, lapAction } = useLaporan();
  const {
    modalCetakLaporan,
    listKodeCabang,
    dataCetakLaporan = {},
    loadingCetakLaporan = false,
    kode_cabang
  } = lapState;

  function renderListCabang() {
    const { kode_cabang } = lapState;
    let list = listKodeCabang;
    if (kode_cabang !== "000") {
      list = listKodeCabang.filter((e) => e.kode_cabang === kode_cabang);
    }
    return list.map((opt, i) => (
      <option value={opt.kode_cabang} key={"kc" + i}>
        {`${opt.kode_cabang} - ${opt.nama_cabang}`}
      </option>
    ));
  }

  return (
    <>
      {/* modal utama */}
      <Modal size={"lg"} show={modalCetakLaporan} onHide={() => lapAction.closeCetakLaporan()}>
        <Modal.Header closeButton>
          <Modal.Title>Cetak Laporan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2">
            <div className="col-12 mb-2">Cabang</div>
            <div className="col-lg-6">
              <div className="form-group">
                <select
                  className={`form-control ${
                    dataCetakLaporan?.branch_code === "" ? "is-invalid" : ""
                  }`}
                  disabled={kode_cabang !== "000"}
                  value={dataCetakLaporan?.branch_code}
                  onChange={(e) =>
                    lapAction.setDataCetakLaporan((prevState) => ({
                      ...prevState,
                      branch_code: e.target.value,
                    }))
                  }
                  placeholder="Pilih Kantor Cabang">
                  <option value="">Pilih Kantor Cabang</option>
                  {renderListCabang()}
                </select>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              {lapState.kode_cabang === "000" ? (
                <div className="form-group">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="semuaCabangC"
                      checked={dataCetakLaporan?.branch_code === "ALL"}
                      onChange={(e) =>
                        lapAction.setDataCetakLaporan((prev) => ({
                          ...prev,
                          branch_code: prev.branch_code === "ALL" ? "" : "ALL",
                        }))
                      }
                    />
                    <label className="form-check-label text-bold" htmlFor="semuaCabangC">
                      Semua Cabang
                    </label>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2">Rentang Waktu</div>
            <div className="col-6 d-flex justify-content-center">
              <div className="form-group">
                <label style={{position:'relative'}}>
                  <DateInput
                    className={`form-control ${
                      dataCetakLaporan?.start_date === "" ? "is-invalid" : ""
                    } `}
                    value={dataCetakLaporan?.start_date}
                    type="date"
                    onChange={(e) =>
                      lapAction.setDataCetakLaporan((prevState) => ({
                        ...prevState,
                        start_date: e,
                      }))
                    }
                  />
                  <i
                    className="far fa-calendar"
                    style={{
                      position: "absolute",
                      right: "1rem",
                      zIndex: 1,
                      top: "0.7rem",
                    }}></i>
                </label>
              </div>
              <div className="p-2">s/d</div>
              <div className="form-group">
                <label style={{position:'relative'}}>
                  <DateInput
                    className={`form-control ${
                      dataCetakLaporan?.end_date === "" ? "is-invalid" : ""
                    } `}
                    value={dataCetakLaporan?.end_date}
                    type="date"
                    onChange={(e) =>
                      lapAction.setDataCetakLaporan((prevState) => ({
                        ...prevState,
                        end_date: e,
                      }))
                    }
                  />
                  <i
                    className="far fa-calendar"
                    style={{
                      position: "absolute",
                      right: "1rem",
                      zIndex: 1,
                      top: "0.7rem",
                    }}></i>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                disabled={
                  Object.keys(dataCetakLaporan).map(function(key) {
                    return dataCetakLaporan[key];
                }).filter((d) => d === "").length > 0 ||
                  loadingCetakLaporan
                }
                onClick={() => lapAction.cetakLaporan()}
                className="btn btn-success float-right">
                {loadingCetakLaporan ? <LoadingIcon /> : "Cetak"}
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => lapAction.closeCetakLaporan()}
                disabled={loadingCetakLaporan}
                className="btn btn-outline-danger float-right">
                Batal
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* end modal utama */}
    </>
  );
};

export default CetakLaporan;
