import clsx from 'clsx';
import { useState, useEffect, useMemo } from 'react';
import { FormControl, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Breadcrumb, Button } from 'semantic-ui-react';
import { TableGrid } from '../../../../controls';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../../../../app.config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PembukaanRekeningPage = () => {
    let history = useHistory();
    const [rejectModal, setRejectModal] = useState(false);
    const [approvedModal, setApprovedModal] = useState(false);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [status, setStatus] = useState('');
    const [dataJenisProduk, setDataJenisProduk] = useState([]);
    const [jenisRekening, setJenisRekening] = useState('');
    const [jenisNasabah, setJenisNasabah] = useState('CLIENT');
    const [jenisProduk, setJenisProduk] = useState('');
    const [cif, setCif] = useState('');
    const [namaRekening, setNamaRekening] = useState('');
    const [accountHolderType, setAccountHolderType] = useState('');
    const [keterangan, setKeterangan] = useState('');

    useEffect(() => {
        fetch(
            baseUrl +
                `/rekening/tabungan/pending?offset=${currentPage}&limit=${limit}&jenis_nasabah=${jenisNasabah}&jenis_rekening=${jenisRekening}&jenis_produk=${jenisProduk}`
        )
            .then((response) => response.json())
            .then((data) => setData(data?.list));

        fetch(baseUrl + `/rekening/tabungan/produk?limit=10&page=0`)
            .then((response) => response.json())
            .then((data) => setDataJenisProduk(data?.list));
    }, [currentPage, limit, status, jenisNasabah, jenisProduk, jenisRekening]);

    const handleSearch = () => {
        fetch(
            baseUrl +
                `/rekening/tabungan/pending?offset=${currentPage}&limit=${limit}&jenis_nasabah=${jenisNasabah}&jenis_rekening=${jenisRekening}&jenis_produk=${jenisProduk}&search=${search}`
        )
            .then((response) => response.json())
            .then((data) => setData(data?.list));
    };

    //Pagination
    const maxPage = useMemo(() => {
        if (data?.length < limit) return currentPage + 1;

        return currentPage + 2;
    }, [currentPage, data, limit]);

    //Action for approve transaction from detail modal
    const approvedAction = () => {
        setApprovedModal(false);

        const body = {
            nomorCIF: cif,
            accountHolderType: accountHolderType,
            namaRekening: namaRekening,
            status: 'APPROVE',
            keterangan: '',
        };
        return fetch(baseUrl + `/rekening/tabungan/approve`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id == '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else if (status.message_id === '01') {
                    toast.error('Something Went Wrong', {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    const rejectAction = () => {
        setRejectModal(false);

        const body = {
            nomorCIF: cif,
            accountHolderType: accountHolderType,
            namaRekening: namaRekening,
            status: 'REJECT',
            keterangan: keterangan,
        };
        return fetch(baseUrl + `/rekening/tabungan/approve`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.message_id == '00') {
                    toast.success(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                } else if (status.message_id === '01') {
                    toast.error('Something Went Wrong', {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                } else {
                    toast.error(status.status, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: true,
                        autoClose: 3000,
                    });
                    setStatus(status);
                }
            });
    };

    // console.log(jenisProduk, 'produk');
    return (
        <>
            <Modal centered show={Boolean(rejectModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setRejectModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Tolak Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <FormControl
                        as="textarea"
                        placeholder="Masukkan keterangan penolakan"
                        onChange={(e) => setKeterangan(e.target.value)}
                        value={keterangan}
                    />
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        color="#CACCCF"
                        onClick={() => setRejectModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={rejectAction}
                    >
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={Boolean(approvedModal)}>
                <Modal.Header
                    className="tw-px-6 tw-py-5"
                    onHide={() => {
                        setApprovedModal(false);
                    }}
                    closeButton
                >
                    <Modal.Title>
                        <h5 className="tw-text-18 tw-font-bold">Setujui Otorisasi</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tw-px-6 tw-py-5">
                    <p>
                        Yakin menyetujui otorisasi hold dana dengan nomor CIF <b>{cif}</b> ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="tw-px-6 tw-py-5">
                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        emphasis
                        onClick={() => setApprovedModal(false)}
                    >
                        Batal
                    </Button>

                    <Button
                        className="tw-text-12 tw-py-2 tw-mr-3"
                        size="mini"
                        color="blue"
                        onClick={approvedAction}
                    >
                        Ya
                    </Button>
                </Modal.Footer>
            </Modal>
            <Breadcrumb className="tw-mb-5 tw-text-16">
                <Breadcrumb.Section className="text-orange" link>
                    Tabungan & Giro
                </Breadcrumb.Section>
            </Breadcrumb>
            <div className="tw-bg-white tw-rounded-lg">
                <div className="tw-p-7 tw-text-black tw-text-18 tw-font-bold">
                    Rekening Tabungan
                </div>
                <div className="tw-p-6">
                    <div className="tw-text-12 tw-text-black tw-mb-4">Cari data berdasarkan:</div>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-5/6 tw-items-center">
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                onChange={(e) => setJenisNasabah(e.target.value)}
                            >
                                <option disabled>Pilih Jenis Nasabah</option>
                                <option selected value="CLIENT">
                                    Perorangan
                                </option>
                                <option value="GROUP">Badan Usaha</option>
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Rekening"
                                as="select"
                                onChange={(e) => setJenisRekening(e.target.value)}
                            >
                                <option disabled selected={!jenisRekening}>
                                    Jenis Rekening
                                </option>
                                <option value="REGULAR_SAVINGS">Regular Savings</option>
                                <option value="CURRENT_ACCOUNT">Current Account</option>
                                <option value="SAVINGS_PLAN">Savings Plan</option>
                                <option value="INVESTOR_ACCOUNT">Investor Account</option>
                            </FormControl>
                            <FormControl
                                className="tw-mr-4"
                                size="sm"
                                placeholder="Jenis Nasabah"
                                as="select"
                                onChange={(e) => setJenisProduk(e.target.value)}
                            >
                                <option disabled selected={!jenisProduk}>
                                    Jenis Produk
                                </option>
                                {dataJenisProduk &&
                                    dataJenisProduk.map((item) => {
                                        return (
                                            <option value={item.encodedKey} key={item.encodedKey}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                            </FormControl>
                            <form onSubmit={handleSearch} className="tw-w-full mr-4" id="search">
                                <FormControl
                                    id="search"
                                    className="mr-4"
                                    size="sm"
                                    placeholder="Cari no.rekening, nama..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </form>
                            <Button
                                className="tw-mr-4 tw-text-14 tw-py-2"
                                size="mini"
                                basic
                                color="blue"
                                onClick={() => {
                                    setSearch('');
                                    setJenisNasabah('');
                                    setJenisProduk('');
                                    setJenisRekening('');
                                    // maybe better using ref, but well you can change it to ref if you want
                                    document.getElementById('search').reset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                className="tw-text-14 tw-px-12 tw-py-2 tw-font-normal"
                                size="mini"
                                color="blue"
                                onClick={handleSearch}
                            >
                                Cari
                            </Button>
                        </div>
                    </div>
                </div>
                <TableGrid
                    columns={[
                        {
                            name: 'Nomor Rekening',
                            field: 'rekening',
                            width: '100px',
                        },
                        {
                            name: 'Nama Lengkap',
                            field: 'nama',
                            width: '100px',
                        },
                        {
                            name: 'Alamat',
                            field: 'alamat',
                            width: '300px',
                        },
                        {
                            name: 'Jenis Produk',
                            field: 'nameProduct',
                            width: '100px',
                        },
                        {
                            name: 'Keterangan',
                            field: 'keterangan',
                        },

                        {
                            field: 'action',
                        },
                    ]}
                    data={data || []}
                    onRenderField={(value, field, { className, data }) => {
                        // console.log(data, 'data');
                        if (field === 'value')
                            return <div className={clsx(className, 'tw-text-right')}>{value}</div>;
                        if (field === 'action')
                            return (
                                <Action
                                    onDetail={() => {
                                        history.push(
                                            `/sup-tabungan-giro/detail-rekening?id=${data?.db_id}&type=${data.jenisNasabah}&cif=${data.nomorCIF}`
                                        );
                                    }}
                                    onApproved={() => {
                                        setApprovedModal(true);
                                        setCif(data.nomorCIF);
                                        setAccountHolderType(data.jenisNasabah);
                                        setNamaRekening(data.namaRekening);
                                        // setNoRek(data._rekeningTabungan.nomorRekening);
                                    }}
                                    onReject={() => {
                                        setRejectModal(true);
                                        setCif(data.nomorCIF);
                                        setAccountHolderType(data.jenisNasabah);
                                        setNamaRekening(data.namaRekening);
                                    }}
                                    key={`${value}-${field}`}
                                />
                            );
                        if (field === 'rekening') {
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {
                                        (value =
                                            data && data.nomorRekening === null
                                                ? '-'
                                                : data && data.nomorRekening)
                                    }
                                </div>
                            );
                        }
                        if (field === 'nama') {
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {(value = data && data.namaLengkap)}
                                </div>
                            );
                        }
                        if (field === 'alamat') {
                            return (
                                <div key={`${value}-${field}`} className={className}>
                                    {(value = data && data.alamat.alamat)}
                                </div>
                            );
                        }
                    }}
                />
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-between tw-text-black tw-mt-auto"
                >
                    <div className="tw-text-12 tw-flex tw-items-center tw-whitespace-nowrap">
                        <div className="tw-mr-4">Baris per-halaman</div>
                        <FormControl
                            className="tw-border-0"
                            as="select"
                            size="sm"
                            onChange={(e) => setLimit(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </FormControl>
                    </div>
                    <ReactPaginate
                        previousLabel="Sebelumnya"
                        nextLabel="Selanjutnya"
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={maxPage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        containerClassName="pagination tw-text-12 tw-mb-0"
                        activeClassName="tw-bg-primary tw-text-white tw-border-0"
                        pageLinkClassName="tw-px-2 tw-border tw-border-black"
                        pageClassName="border tw-py-1 tw-"
                        nextClassName="tw-px-2 border tw-py-1 tw-rounded-tr-md tw-rounded-br-md"
                        previousClassName="tw-px-2 border tw-py-1 tw-rounded-tl-md tw-rounded-bl-md"
                        nextLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        previousLinkClassName="focus:tw-outline-none tw-cursor-pointer"
                        onPageChange={({ selected }) => setCurrentPage(selected)}
                    />
                    <div className="tw-text-12">Menampilkan {data?.length} data</div>
                </div>
                <div
                    style={{ borderTop: '1px solid #f2f2f2' }}
                    className="tw-p-6 tw-flex tw-items-center tw-justify-end tw-text-black tw-mt-auto"
                ></div>
            </div>
        </>
    );
};

export default PembukaanRekeningPage;

const Action = ({ onDetail, onApproved, onReject }) => {
    const [show, setShow] = useState(false);

    const handleDetail = () => {
        if (onDetail) onDetail();

        setShow(false);
    };

    const handleApproved = () => {
        if (onApproved) onApproved();
    };
    const handleReject = () => {
        if (onReject) onReject();

        setShow(false);
    };

    return (
        <div>
            <Button basic color="red" size="mini" onClick={handleReject}>
                Tolak
            </Button>
            <Button basic color="green" className="tw-mr-3" size="mini" onClick={handleApproved}>
                Setujui
            </Button>
            <button type="button" class="btn btn-link" onClick={handleDetail}>
                Lihat Detail
            </button>
        </div>
    );
};
